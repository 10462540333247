import { showErrorMessage } from 'helpers';
import { useSelector } from 'react-redux';
import { setMlsAccessValue } from 'store/actions/mlsAccess';
import { hasMlsAccessSelector } from 'store/selectors/mlsAccess';

export const useNoMlsAccessHandler = (effectFn: EffectFn) => {
  const hasMlsAccess = useSelector(hasMlsAccessSelector);

  const wrappedEffect: EffectFn = (cfg, options, cb) => {
    return effectFn(cfg, { ...options, showError: false }, (err, data, dispatch) => {
      if (!err) {
        dispatch(setMlsAccessValue(true));
        return;
      }

      if (err?.response?.data?.code === 'NO_MLS_ACCESS') {
        dispatch(setMlsAccessValue(false));
        return;
      }

      showErrorMessage(err);

      cb && cb(err, data, dispatch);
    });
  };

  return {
    hasMlsAccess,
    wrappedEffect,
  };
};

type EffectFn = (cfg: any | undefined, options: any | undefined, cb: any) => void;
