import React from 'react';
import { TitleValueTable } from 'components';
import moment from 'moment';
import { formatPrice, getDateTime, getLocaleDate, limitTextLength, seperateWords } from 'helpers';

import styles from './styles.module.scss';
import { formatCurrency } from 'pages/Workshop/Transactions/components/TransactionsAndProjectsTable/helper';

export const Highlights = ({ data }) => {
  const {
    PurchasePrice,
    DownPayment,
    EarnestMoneyDeposit,
    ClosingDate,
    FinancingType,
    Contingencies,
    OfferExpiryEndDate,
    OfferExpiryIndefinitely,
    Notes,
    TermiteInspection,
    TermiteInspectionResponsibility,
    HomeWarranty,
    HomeWarrantyCost,
    HomeWarrantyResponsibility,
    Occupancy,
    OccupancyDays,
    Appliances,
  } = data;

  const highlightValues: any = [
    {
      title: 'Offer Price',
      value: PurchasePrice ? `$${formatPrice(PurchasePrice)}` : '-',
    },
    {
      title: 'Down Payment',
      value: DownPayment ? `$${formatPrice(DownPayment)}` : '-',
    },
    {
      title: 'Earnest Money Deposit',
      value: EarnestMoneyDeposit ? `$${formatPrice(EarnestMoneyDeposit)}` : '-',
    },
    {
      title: 'Closing Date',
      value: ClosingDate ? moment(getLocaleDate(ClosingDate)).format('MM/DD/YYYY') : '-',
    },
    {
      title: 'Financing Type',
      value: FinancingType ? seperateWords(FinancingType) : '-',
    },
    {
      title: 'Contingencies',
      value: Contingencies ? (
        <>
          <p>
            Inspection
            {Contingencies?.Inspection?.days
              ? ` (${Contingencies?.Inspection?.days}d)`
              : ' (Waived)'}{' '}
          </p>
          <p>
            Appraisal
            {Contingencies?.Appraisal?.days ? ` (${Contingencies?.Appraisal?.days}d)` : ' (Waived)'}
          </p>
          <p>
            Financing
            {Contingencies?.Financing?.days ? ` (${Contingencies?.Financing?.days}d)` : ' (Waived)'}
          </p>
        </>
      ) : (
        '-'
      ),
    },
    {
      title: 'Termite Inspection',
      value:
        TermiteInspection && TermiteInspectionResponsibility ? (
          <span>
            Yes <br />
            {`(Responsible Party: ${TermiteInspectionResponsibility})`}
          </span>
        ) : (
          'No'
        ),
    },
    {
      title: 'Home Warranty',
      value:
        HomeWarranty && HomeWarrantyCost !== null ? (
          <span>
            Yes <br />
            {`(${formatCurrency(
              HomeWarrantyCost,
            )} / Responsible Party: ${HomeWarrantyResponsibility})`}
          </span>
        ) : (
          'No'
        ),
    },
    {
      title: 'Appliances',
      value: Appliances ? Appliances : '-',
    },
    {
      title: 'Post-Closing Occupancy/SIP',
      value:
        Occupancy && OccupancyDays !== null ? (
          <span>
            Yes <br /> {`(${OccupancyDays} Days)`}{' '}
          </span>
        ) : (
          'No'
        ),
    },
    {
      title: 'Offer Expiration',
      value: OfferExpiryIndefinitely
        ? 'None'
        : OfferExpiryEndDate
        ? getDateTime(OfferExpiryEndDate)
        : '-',
    },
    {
      title: 'Notes',
      value: Notes ?? '-',
    },
  ];

  return (
    <div className={styles.highlights}>
      <h3 className={styles.title}>Highlights</h3>
      <div className={styles.content}>
        <TitleValueTable items={highlightValues} itemsClassName={styles.valueItemWidth} />
      </div>
    </div>
  );
};
