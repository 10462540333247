/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import { Formik } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getAllPartnerTags, getCategories } from 'api/admin';
import { AddPhoto, Button, Locations, PageNotFound, Phone, Select, Wrapper } from 'components';
import { Select as AntdSelect, Option } from 'components-antd';
import CheckboxComponent from 'components/Form/Checkbox';
import Input from 'components/Form/Input';
import Textarea from 'components/Form/Textarea';
import { CheckBox } from 'components/Icons';
import { TitleWithButtons } from 'components/SettingsGroup';
import { useBackPage, useRequest } from 'hooks';
import InputList from 'pages/Dashboard/components/InputList';
import { ERROR, PENDING } from 'settings/constants/apiState';
import { routes } from 'settings/navigation/routes';
import {
  createPartnerEffect,
  requestGetPartnerByIdEffect,
  updatePartnerEffect,
} from 'store/effects/adminPanel';
import {
  getLoadingPartnerData,
  getPartnerEditSelector,
  getPartnerSelector,
} from 'store/selectors/adminPanel';
import { getCategoriesOptions, getPartnerTagOptions } from './helpers';
import { ValidationSchema } from './validation';

import { getAllAgents } from 'api/admin/users';
import { AreasOfOperationFieldInput } from 'pages/OnBoarding/components/Forms/PartnerOnboardingForms/AreasServedForm/AreasOfOperationFieldInput';
import locationStyles from 'pages/OnBoarding/components/Forms/PartnerOnboardingForms/AreasServedForm/styles.module.scss';
import {
  onAddressChange,
  onAreasOfOperationChange,
  onCategoriesChange,
  onLogoChange,
  onPartnerTagDeselect,
  onPartnerTagSelect,
  onRecommenderDeselect,
  onRecommenderSelect,
} from 'utils';
import styles from './styles.module.scss';

const PartnerForm = ({ uploadEdit = false, selectedPartner, onEdit }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const partner = useSelector(getPartnerSelector);
  const partnerData = useSelector(getPartnerEditSelector);

  const [{ loading, data: categoriesOptions }] = useRequest({
    request: getCategories,
    format: getCategoriesOptions,
    params: {
      active: true,
    },
  });

  const [{ loading: partnerTagLoading, data: partnerTags }] = useRequest({
    request: getAllPartnerTags,
    format: getPartnerTagOptions,
    params: {
      active: true,
    },
  });

  const [{ loading: getAllAgentsLoading, data: agents }] = useRequest({
    request: getAllAgents,
  });

  const agentsOptions = useMemo(() => {
    return agents?.map((agent) => ({
      value: agent.UserId,
      name: agent.Name,
      label: agent.Name,
    }));
  }, [agents]);

  const optionsWithInactive = useMemo(
    () =>
      categoriesOptions && partnerData?.PartnerCategoryIds
        ? categoriesOptions.concat(
            (partnerData?.PartnerCategoryIds || [])
              .filter(({ value }) => !categoriesOptions.find(({ value: v }) => v === value))
              .map((option) => ({ ...option, disabled: true })),
          )
        : categoriesOptions,
    [categoriesOptions, partnerData],
  );

  const { state } = useSelector(getLoadingPartnerData);
  const [onBack] = useBackPage({ defaultBackUrl: routes.dashboardServiceDirectoryPartners });

  useEffect(() => {
    if (id) {
      dispatch(requestGetPartnerByIdEffect({ id }));
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const [phoneCountry, setPhoneCountry] = useState();

  const validationSchema = useMemo(() => ValidationSchema(phoneCountry, !!id), [phoneCountry, id]);

  const isPending = state === PENDING;
  const isNotFound = state === ERROR && partner?.meta?.status === 404;

  const modifyPhoneFormat = (data) => {
    const phones = data.ContactUser.Phones || [];
    data.ContactUser.Phones = phones.map((phone, index) => ({
      PhoneNumber: phone,
      PhoneType: 'Mobile',
      IsPrimary: !index,
    }));
  };

  const onSubmit = useCallback(
    (values) => {
      if (id) {
        dispatch(
          updatePartnerEffect({ values, id }, (err) => {
            if (!err) {
              history.push(routes.dashboardServiceDirectoryPartners);
            }
          }),
        );
      } else {
        modifyPhoneFormat(values);
        dispatch(
          createPartnerEffect(values, (err) => {
            if (!err) {
              history.push(routes.dashboardServiceDirectoryPartners);
            }
          }),
        );
      }
    },
    [dispatch, id, history],
  );

  const getPartnerInitialValues = () => {
    if (!partnerData) return PartnerForm.initialValues;
    const { Radius, AreasOfOperation, ...rest } = partnerData;

    return {
      ...rest,
      locations: [{ AreasOfOperation: '', Radius: '' }],
      ...(AreasOfOperation?.length > 0 && {
        locations: AreasOfOperation.map((area) => {
          const { Radius: areasRadius, ...rest } = area;
          return {
            AreasOfOperation: rest,
            Radius: areasRadius || Radius || '',
          };
        }),
      }),
    };
  };

  const getInitialValues = () => {
    if (uploadEdit) return selectedPartner;
    return id ? getPartnerInitialValues() || PartnerForm.initialValues : PartnerForm.initialValues;
  };

  const getPhoneNumber = (values) => {
    if (uploadEdit) return values.PhoneNumber;
    return id ? values?.PhoneNumber : '';
  };

  const getPhoneList = (values) => {
    if (uploadEdit) return values.ContactUser.Phones || [];
    return id ? values?.ContactUser?.Phones : [];
  };
  const formikRef = useRef();
  return (
    <>
      {isNotFound ? (
        <PageNotFound btnLabel="Back to Partners" onClick={onBack} />
      ) : (
        <Wrapper isPending={isPending} className={styles.pageWrapper}>
          <Formik
            initialValues={getInitialValues()}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={(f) => (formikRef.current = f)}
          >
            {({ values, handleSubmit, dirty, handleChange, touched, errors, setFieldValue }) => (
              <form
                testid="partner_edit"
                onSubmit={
                  uploadEdit
                    ? (e) => {
                        e.preventDefault();
                        onEdit(values);
                      }
                    : handleSubmit
                }
              >
                <TitleWithButtons className={styles.title} title="Partner">
                  {dirty ? (
                    <Button
                      testid="save_button"
                      type="submit"
                      className={styles.saveBtn}
                      title="Save"
                      isPending={isPending}
                    />
                  ) : (
                    <Button
                      testid="save_button"
                      type="button"
                      className={styles.saveBtn}
                      title="Save"
                      disabled
                    />
                  )}
                </TitleWithButtons>
                <div className={styles.formsWrapper}>
                  <div className={styles.wrapper}>
                    <AddPhoto
                      className={styles.updateAvatar}
                      avatarClassName={styles.avatar}
                      onChange={(image, file) => onLogoChange(image, file, setFieldValue)}
                      value={id ? partner?.data?.LogoUrl : ''}
                      binary
                      placeholder={values.BusinessName?.substring(0, 1).toUpperCase()}
                    >
                      <span>Update</span>
                    </AddPhoto>
                    <div className={styles.flexWrapper}>
                      <CheckboxComponent
                        id="IsActive"
                        label="Active"
                        checked={values.IsActive}
                        onChange={handleChange}
                        disabled={isPending}
                        direction={CheckboxComponent.DIRECTION_RIGHT}
                        name="IsActive"
                        labelTextClassName={styles.checkboxLabel}
                        className={styles.checkbox}
                        testid="active_checkbox"
                      />
                      <CheckboxComponent
                        id="IsRecommendedByMosaik"
                        label="Recommended by Mosaik"
                        checked={values.IsRecommendedByMosaik}
                        onChange={handleChange}
                        disabled={isPending}
                        direction={CheckboxComponent.DIRECTION_RIGHT}
                        name="IsRecommendedByMosaik"
                        labelTextClassName={styles.checkboxLabel}
                        className={styles.checkbox}
                        testid="recommend_checkbox"
                      />
                      <CheckboxComponent
                        id="DirectoryPreference"
                        label="Directory Preference"
                        checked={values.DirectoryPreference}
                        onChange={handleChange}
                        disabled={isPending}
                        direction={CheckboxComponent.DIRECTION_RIGHT}
                        name="DirectoryPreference"
                        labelTextClassName={styles.checkboxLabel}
                        className={styles.checkbox}
                        testid="recommend_checkbox"
                      />
                      {!id && (
                        <CheckboxComponent
                          id="IsInviteLaterOrNow"
                          label="Send Invite Email Now"
                          checked={values.IsInviteLaterOrNow}
                          onChange={handleChange}
                          disabled={isPending}
                          direction={CheckboxComponent.DIRECTION_RIGHT}
                          name="IsInviteLaterOrNow"
                          labelTextClassName={styles.checkboxLabel}
                          className={styles.checkbox}
                          testid="recommend_checkbox"
                        />
                      )}
                    </div>
                    <div className={styles.inputBlock}>
                      <label htmlFor="BusinessName" className={styles.editLabel}>
                        Business Name
                      </label>
                      <div className={styles.rightBlock}>
                        <Input
                          id="BusinessName"
                          className={styles.input}
                          type="text"
                          name="BusinessName"
                          error={touched.BusinessName ? errors.BusinessName : ''}
                          value={values.BusinessName}
                          onChange={handleChange}
                          disabled={isPending}
                          testid="business_name_input"
                        />
                      </div>
                    </div>
                    <div className={styles.inputBlock}>
                      <label htmlFor="AdditionalBusinessName" className={styles.editLabel}>
                        Additional Name
                      </label>
                      <div className={styles.rightBlock}>
                        <Input
                          id="AdditionalBusinessName"
                          className={styles.input}
                          type="text"
                          name="AdditionalBusinessName"
                          error={
                            touched.AdditionalBusinessName ? errors.AdditionalBusinessName : ''
                          }
                          value={values.AdditionalBusinessName}
                          onChange={handleChange}
                          disabled={isPending}
                          testid="additional_name_input"
                        />
                      </div>
                    </div>
                    <div className={styles.inputBlock}>
                      <label htmlFor="PartnerCategoryIds" className={styles.editLabel}>
                        Categories
                      </label>
                      <div className={styles.rightBlock}>
                        <Select
                          id="PartnerCategoryIds"
                          name="PartnerCategoryIds"
                          search
                          options={
                            loading || !optionsWithInactive
                              ? [
                                  {
                                    name: 'Loading...',
                                    value: '',
                                  },
                                ]
                              : optionsWithInactive
                          }
                          multiple
                          value={values.PartnerCategoryIds}
                          onSelect={(e, value) => onCategoriesChange(e, value, setFieldValue)}
                          disabled={isPending}
                          className={{ wrapper: styles.selectWrapper }}
                          error={touched.PartnerCategoryIds ? errors.PartnerCategoryIds : ''}
                          testid="category_select"
                          closeOnSelect={false}
                        />
                      </div>
                    </div>
                    <div className={styles.inputBlock}>
                      <label htmlFor="PartnerServices" className={styles.editLabel}>
                        Services
                      </label>
                      <div className={styles.rightBlock}>
                        <AntdSelect
                          large
                          showArrow={true}
                          mode="tags"
                          getPopupContainer={(triggerNode) => triggerNode}
                          menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                          placeholder="Select Services"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                          }
                          onSelect={(value) =>
                            onPartnerTagSelect(value, values, setFieldValue, partnerTags)
                          }
                          onDeselect={(value) => onPartnerTagDeselect(value, values, setFieldValue)}
                          value={values.PartnerServices}
                          id="PartnerServices"
                          name="PartnerServices"
                          className={styles.antdSelect}
                          disabled={!partnerTags}
                        >
                          {partnerTags ? (
                            partnerTags.map((el, i) => (
                              <Option value={el.value} key={`tags-${el.value}`}>
                                {el.name}
                              </Option>
                            ))
                          ) : (
                            <Option value={''} key={`tags-${0}`}>
                              Loading...
                            </Option>
                          )}
                        </AntdSelect>
                      </div>
                    </div>
                    <div className={styles.inputBlock}>
                      <label htmlFor="Email" className={styles.editLabel}>
                        Business Email
                      </label>
                      <div className={styles.rightBlock}>
                        <Input
                          id="Email"
                          className={styles.input}
                          type="text"
                          name="Email"
                          error={touched.Email ? errors.Email : ''}
                          value={values.Email}
                          onChange={handleChange}
                          disabled={isPending}
                          testid="business_email_input"
                        />
                      </div>
                    </div>
                    {id && (
                      <div className={styles.inputBlock}>
                        <label htmlFor="ContactUser.Email" className={styles.editLabel}>
                          Email
                        </label>
                        <div className={styles.rightBlock}>
                          <Input
                            id="ContactUser.Email"
                            className={styles.input}
                            type="text"
                            name="ContactUser.Email"
                            error={touched.ContactUser?.Email ? errors.ContactUser?.Email : ''}
                            value={values.ContactUser?.Email}
                            onChange={handleChange}
                            disabled={isPending}
                            testid="user_email_input"
                          />
                        </div>
                      </div>
                    )}
                    <Phone
                      id="PhoneNumber"
                      name="PhoneNumber"
                      label="Phone Number"
                      value={getPhoneNumber(values)}
                      onChange={(event, value, country) => {
                        setPhoneCountry(country);
                        setFieldValue('PhoneNumber', value);
                      }}
                      error={touched.PhoneNumber ? errors.PhoneNumber : ''}
                      disabled={isPending}
                      className={classNames(styles.inputBlock, styles.phone)}
                      inputClassName={styles.input}
                      errorClassName={styles.phonesError}
                      options={{
                        containerClass: styles.rightBlock,
                      }}
                      testid="phone"
                    />
                    <div className={styles.inputBlock}>
                      <label htmlFor="Website" className={styles.editLabel}>
                        Website
                      </label>
                      <div className={styles.rightBlock}>
                        <Input
                          id="Website"
                          className={styles.input}
                          type="text"
                          name="Website"
                          error={touched.Website ? errors.Website : ''}
                          value={values.Website}
                          onChange={handleChange}
                          disabled={isPending}
                          testid="website_input"
                        />
                      </div>
                    </div>
                    <div className={styles.inputBlock}>
                      <label htmlFor="Description" className={styles.label}>
                        Description
                      </label>
                      <div className={styles.rightBlock}>
                        <Textarea
                          id="Description"
                          className={styles.input}
                          type="text"
                          name="Description"
                          error={touched.Description ? errors.Description : ''}
                          value={values.Description}
                          onChange={handleChange}
                          disabled={isPending}
                          testid="description_input"
                        />
                      </div>
                    </div>
                    <Locations
                      multiple={false}
                      label="Address"
                      types={['address']}
                      variant={Locations.LIGHT}
                      onResult={(result) => onAddressChange(result, setFieldValue)}
                      value={values.Address ? [values.Address] : []}
                      error={touched.Address ? errors.Address : ''}
                      className={styles.inputBlock}
                      searchWrapperClassName={styles.searchWrapper}
                      labelClassName={styles.editLabel}
                      blockClassName={styles.rightBlock}
                      valuesWrapperClassName={styles.valuesWrapper}
                      activeInputClassName={styles.activeInput}
                      errorClassName={styles.locationError}
                      testid="address"
                      showPrefixIcon={true}
                    />
                    <div className={styles.inputBlock}>
                      <label htmlFor="SuiteUnit" className={styles.editLabel}>
                        Suite / Unit
                      </label>
                      <div className={styles.rightBlock}>
                        <Input
                          id="SuiteUnit"
                          className={styles.input}
                          type="text"
                          placeholder="eg. 1053"
                          name="SuiteUnit"
                          error={touched.SuiteUnit ? errors.SuiteUnit : ''}
                          value={values.SuiteUnit}
                          onChange={handleChange}
                          disabled={isPending}
                          testid="suite_unit_input"
                        />
                      </div>
                    </div>
                    <div className={styles.inputBlock}>
                      <label htmlFor="AreasOfOperation" className={styles.editLabel}>
                        Geographic Areas Served
                      </label>
                      <div className={styles.rightBlock}>
                        <div className={locationStyles.stageWrap}>
                          <AreasOfOperationFieldInput
                            name="locations"
                            values={values}
                            touched={touched}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            onAreasOfOperationChange={onAreasOfOperationChange}
                            isPending={isPending}
                            showLabels={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputBlock}>
                      <label htmlFor="RecommendedByAgentsId" className={styles.editLabel}>
                        Recommended By
                      </label>
                      <div className={styles.rightBlock}>
                        <AntdSelect
                          large
                          showArrow={true}
                          showSearch
                          mode="tags"
                          getPopupContainer={(triggerNode) => triggerNode}
                          placeholder="Select Recommended By"
                          filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                          }
                          onSelect={(e, value) =>
                            onRecommenderSelect(
                              e,
                              value,
                              setFieldValue,
                              values.RecommendedByAgentsId,
                            )
                          }
                          onDeselect={(e, value) =>
                            onRecommenderDeselect(
                              e,
                              value,
                              setFieldValue,
                              values.RecommendedByAgentsId,
                            )
                          }
                          value={values.RecommendedByAgentsId}
                          id="RecommendedByAgentsId"
                          name="RecommendedByAgentsId"
                          className={styles.antdSelectAgent}
                          options={agentsOptions}
                        />
                      </div>
                    </div>
                  </div>
                  {!id ? (
                    <div className={classNames(styles.wrapper, styles.rightBlock)}>
                      <h2 className={styles.subTitle}>Contact User</h2>
                      <div className={styles.inputBlock}>
                        <label htmlFor="ContactUser.FirstName" className={styles.editLabel}>
                          First Name
                        </label>
                        <div className={styles.rightBlock}>
                          <Input
                            id="ContactUser.FirstName"
                            className={styles.input}
                            type="text"
                            name="ContactUser.FirstName"
                            error={
                              touched.ContactUser?.FirstName ? errors.ContactUser?.FirstName : ''
                            }
                            value={values.ContactUser?.FirstName}
                            onChange={handleChange}
                            disabled={isPending}
                            testid="first_name_input"
                          />
                        </div>
                      </div>
                      <div className={styles.inputBlock}>
                        <label htmlFor="ContactUser.LastName" className={styles.editLabel}>
                          Last Name
                        </label>
                        <div className={styles.rightBlock}>
                          <Input
                            id="ContactUser.LastName"
                            className={styles.input}
                            type="text"
                            name="ContactUser.LastName"
                            error={
                              touched.ContactUser?.LastName ? errors.ContactUser?.LastName : ''
                            }
                            value={values.ContactUser?.LastName}
                            onChange={handleChange}
                            disabled={isPending}
                            testid="last_name_input"
                          />
                        </div>
                      </div>
                      <div className={styles.inputBlock}>
                        <label htmlFor="ContactUser.Email" className={styles.editLabel}>
                          Email
                        </label>
                        <div className={styles.rightBlock}>
                          <Input
                            id="ContactUser.Email"
                            className={styles.input}
                            type="text"
                            name="ContactUser.Email"
                            error={touched.ContactUser?.Email ? errors.ContactUser?.Email : ''}
                            value={values.ContactUser?.Email}
                            onChange={handleChange}
                            disabled={isPending}
                            testid="user_email_input"
                          />
                        </div>
                      </div>
                      <div className={styles.inputBlock}>
                        <span className={styles.editLabel}>Phones</span>
                        <div className={styles.rightBlock}>
                          <InputList
                            isPhone
                            disabled={isPending}
                            formik={formikRef?.current}
                            name="ContactUser.Phones"
                            list={getPhoneList(values)}
                            addBtnTitle="Add phone"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </form>
            )}
          </Formik>
        </Wrapper>
      )}
    </>
  );
};

PartnerForm.initialValues = {
  BusinessName: '',
  AdditionalBusinessName: '',
  Email: '',
  PhoneNumber: '',
  Address: '',
  SuiteUnit: '',
  Description: '',
  Website: '',
  LogoUrl: '',
  IsRecommendedByMosaik: false,
  IsActive: false,
  PartnerCategoryIds: [],
  locations: [
    {
      AreasOfOperation: '',
      Radius: '',
    },
  ],
  ContactUser: {
    FirstName: '',
    LastName: '',
    Email: '',
    Phones: [],
  },
  PartnerServices: [],
  IsInviteLaterOrNow: false,
  RecommendedByAgentsId: [],
};

export default PartnerForm;
