import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import { LocationService } from 'services';
import BaseNotification from '../../../BaseNotification';
import { link } from 'settings/navigation/link';

const locationSrv = new LocationService();

const CollaborateDocument = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const location = useLocation();
  const history = useHistory();

  locationSrv.setLocation(location);

  const {
    SenderFirstName,
    SenderLastName,
    EditorName,
    SignatoryName,
    ClientAvatarUrl,
    Link,
    FormProcessPublicId: formProcessPublicId,
    FormDocumentPublicId: formDocumentPublicId,
  } = Payload || {};

  const Name = `${SenderFirstName || EditorName || SignatoryName} ${SenderLastName}`;

  const onClickHandler = () => {
    if (formProcessPublicId && formDocumentPublicId) {
      history.replace(
        link.toWorkshopFormProcessDocument({ formDocumentPublicId, formProcessPublicId }),
      );
    } else {
      window.location.replace(Link);
    }
  };

  return (
    <div className={classNames(className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        groupType={groupType}
        avatarUrl={ClientAvatarUrl}
        avatarPlaceholder={convertNameToAvatarPlaceholder(Name)}
        severityLevel={SeverityLevel}
        onClick={onClickHandler}
      />
    </div>
  );
};

CollaborateDocument.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    SenderFirstName: PropTypes.string,
    SenderLastName: PropTypes.string,
    ClientAvatarUrl: PropTypes.string,
    Link: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

CollaborateDocument.defaultProps = {
  className: '',
};

export default CollaborateDocument;
