import { FORM_STATUS_TYPE } from 'app-constants';
import { IDLE } from 'settings/constants/apiState';
import { FormBuilderState, FormBuilderType, SmartPreForm } from 'types';

export const formBuilderInitialData: FormBuilderType = {
  folderList: {
    folders: [],
    state: IDLE,
  },
  formList: {
    refresh: 1,
    total: 0,
    forms: [],
    state: IDLE,
    search: {
      page: 1,
      pageSize: 10,
    },
    errors: [],
  },
  meta: {
    state: IDLE,
    tags: [],
    categories: [],
    smartFormCategories: [],
  },
  quoteBuilder: {
    state: IDLE,
    folderName: '',
    formName: '',
    isFavorite: false,
    questions: [],
    responses: [],
    tags: [],
    categories: [],
    disabled: false,
    status: FORM_STATUS_TYPE.Draft,
  },
  smartBuilder: {
    state: IDLE,
    folderName: '',
    formName: '',
    isFavorite: false,
    questions: [],
    answers: {},
    preForm: {} as SmartPreForm,
    tags: [],
    users: [],
    disabled: false,
    status: FORM_STATUS_TYPE.Draft,
    link: '',
    formCategoryTags: [],
  },
};

export const initialData: FormBuilderState = {
  state: IDLE,
  data: formBuilderInitialData,
};
