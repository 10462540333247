import React from 'react';
import { useSelector } from 'react-redux';

import { getUserRolesMapSelector } from 'store/selectors/user';

import BuyersMatch from './BuyersMatch';
import AgentsMatch from './AgentsMatch';

const Match = () => {
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);

  if (isAgent) {
    return <AgentsMatch />;
  }

  if (isClient) {
    return <BuyersMatch />;
  }

  return null;
};

export default Match;
