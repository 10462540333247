import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { setSearchDrawerCommuteIdEffect } from 'store/effects';
import Icon from 'pages/Properties/SearchResults/Icons';
import styles from './styles.module.scss';

const AddCommute = ({ className, isAnother }) => {
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(setSearchDrawerCommuteIdEffect('new'));
  };

  return (
    <div
      onClick={onClickHandler}
      testid="add_commute"
      className={classNames(styles.addCommute, className)}
    >
      <Icon className={styles.icon} variant={Icon.ADD} />
      <span className={styles.text}>Add {isAnother ? 'Another ' : ''}</span>
    </div>
  );
};

AddCommute.propTypes = {
  className: PropTypes.string,
};

AddCommute.defaultProps = {
  className: '',
};

export default AddCommute;
