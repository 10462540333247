import React, { memo } from 'react';

const Filter = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.2256 1.99592C0.942715 1.67263 1.1723 1.16667 1.60188 1.16667H14.3981C14.8277 1.16667 15.0573 1.67263 14.7744 1.99592L9.66667 7.83333V12.5243C9.66667 12.7137 9.55967 12.8868 9.39027 12.9715L7.05694 14.1382C6.72449 14.3044 6.33333 14.0627 6.33333 13.691V7.83333L1.2256 1.99592Z"
          stroke="#303030"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default memo(Filter);
