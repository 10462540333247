import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetShowingAppointmentById,
  requestUpdateShowingAppointment,
  requestFillShowingAppointment,
  requestGetShowingAppointmentApprovalByUserId,
  requestGetAllShowingAppointment,
  setShowingAppointmentStatusAction,
} from 'store/actions/showingAppointment';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetAllShowingAppointment as any]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.value', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestGetShowingAppointmentById as any]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.value', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestGetShowingAppointmentApprovalByUserId as any]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.value', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestUpdateShowingAppointment as any]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.value', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [setShowingAppointmentStatusAction as any]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data.value', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestFillShowingAppointment as any]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [appLogoutAction as any]: () => initialData,
  },
  initialData,
);
