import {
  AdminFriendlySubscriptionPlan,
  adminPanelCreateNewSubscriptionCustomer,
  adminPanelGetSubscriptionCustomer,
  adminPanelTogglePlanLevel,
} from 'api/subscription';
import { Button, Divider, Input, Link, Radio } from 'components-antd';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import React, { useEffect, useState } from 'react';

interface SubscriptionCustomerDataProps {
  userId: number;
}

const createStripeCustomerLink = (customerId: string) => {
  return `https://dashboard.stripe.com/customers/${customerId}`;
};

export const SubscriptionCustomerData: React.FC<SubscriptionCustomerDataProps> = (props) => {
  const [subscriptionCustomer, setSubscriptionCustomer] = useState<any>(null);
  const [enteredExternalCustomerId, setEnteredExternalCustomerId] = useState<string>('');
  const [selectedNewPlan, setSelectedNewPlan] = useState<AdminFriendlySubscriptionPlan | null>(
    null,
  );
  useEffect(() => {
    const runEffect = async () => {
      const response = await adminPanelGetSubscriptionCustomer(props.userId);
      setSubscriptionCustomer(response.data.result);
    };

    runEffect();
  }, []);

  useEffect(() => {
    if (
      subscriptionCustomer?.InternalPlanName &&
      [
        AdminFriendlySubscriptionPlan.Complimentary,
        AdminFriendlySubscriptionPlan.Paid,
        AdminFriendlySubscriptionPlan.Standard,
      ].includes(subscriptionCustomer?.InternalPlanName)
    ) {
      setSelectedNewPlan(subscriptionCustomer.InternalPlanName);
    }
  }, [subscriptionCustomer]);

  const handleExternalCustomerIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredExternalCustomerId(e.target.value);
  };

  const handleCreateSubscriptionCustomerClick = async () => {
    try {
      const response = await adminPanelCreateNewSubscriptionCustomer(
        props.userId,
        enteredExternalCustomerId,
      );

      if (response.data.success) {
        showSuccessMessage('Subscription customer created successfully');
        setSubscriptionCustomer(response.data.result);
      } else {
        showErrorMessage("Can't create a new subscription customer. Please reach out to dev team");
      }
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  const handleSwitchPlanLevelClick = async () => {
    if (!selectedNewPlan) {
      return;
    }

    const response = await adminPanelTogglePlanLevel(props.userId, selectedNewPlan);

    if (response.data.success) {
      showSuccessMessage('Plan is switched successfully');
      setSubscriptionCustomer(response.data.result);
    } else {
      showErrorMessage(
        "Can't switch subscription plan from admin-panel. Try to manager customer subscription from Stripe dashboard",
      );
    }
  };

  const renderAdminFriendlyPlanName = (plan: AdminFriendlySubscriptionPlan) => {
    switch (plan) {
      case AdminFriendlySubscriptionPlan.InImplementation:
        return 'In Implementation';
      case AdminFriendlySubscriptionPlan.Complimentary:
        return 'Complimentary';
      case AdminFriendlySubscriptionPlan.Paid:
        return 'Paid';
      default:
        return 'Unknown';
    }
  };

  return (
    <div>
      <Divider>Subscription & Plan</Divider>
      {subscriptionCustomer ? (
        <div>
          <div>Subscription Customer Id: {subscriptionCustomer.ExternalCustomerId}</div>
          <div>
            Stripe Subscription Status:{' '}
            {subscriptionCustomer.SubscriptionStatus ?? 'No active subscription'}
          </div>
          <Divider />
          <div>
            Plan Level:{' '}
            <strong>
              {renderAdminFriendlyPlanName(subscriptionCustomer.AdminFriendlySubscriptionPlan)}
            </strong>
          </div>
          {subscriptionCustomer.IsAllowedToSwitchSubscriptionPlan && (
            <div>
              <Radio.Group
                value={selectedNewPlan}
                onChange={(e) => setSelectedNewPlan(e.target.value)}
              >
                <Radio value={AdminFriendlySubscriptionPlan.Complimentary}>Complimentary</Radio>
                <Radio value={AdminFriendlySubscriptionPlan.Paid}>
                  Legacy Paid (requires payment by agent on first login)
                </Radio>
                <Radio value={AdminFriendlySubscriptionPlan.Standard}>
                  Standard Paid (requires payment by agent on first login)
                </Radio>
              </Radio.Group>
              <Button
                type="primary"
                onClick={handleSwitchPlanLevelClick}
                disabled={!selectedNewPlan}
              >
                Switch plan level
              </Button>
            </div>
          )}
          <Divider />
          <Link
            href={createStripeCustomerLink(subscriptionCustomer.ExternalCustomerId)}
            target="_blank"
          >
            Manage customer in Stripe
          </Link>
          {}
        </div>
      ) : (
        <div>
          <div>No subscription customer found</div>
          <div>
            Subscription Customer entity is required for proper operation of the app. Please create
            a new one (with &quot;Create&quot; button click) or link an existing one by specifying
            Stripe customer ID into the input field below
          </div>
          <div style={{ display: 'flex' }}>
            <Input
              value={enteredExternalCustomerId}
              onChange={handleExternalCustomerIdChange}
              placeholder='Enter Stripe customer ID (ex. "cus_XXX")'
            ></Input>
            <Button type="primary" onClick={handleCreateSubscriptionCustomerClick}>
              {enteredExternalCustomerId ? 'Link existing' : 'Create new'} subscription customer
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
