import { Row, Col } from 'components-antd';
import { AreaGraphTemplate } from '../../Graphs/AreaGraphTemplate';
import { sampleData } from '../../../helper';

import styles from '../styles.module.scss';

export const Clients = () => {
  return (
    <div className={styles.mainContentWrapper}>
      <Row gutter={[24, 24]}>
        <Col
          span={8}
          xs={{ span: 24, order: 1 }}
          sm={{ span: 12, order: 1 }}
          lg={{ span: 8, order: 1 }}
          className="gutter-row"
        >
          <AreaGraphTemplate
            title={'Lead Sources'}
            color={'#928CDA'}
            stats={sampleData}
            showAmount={false}
          />
        </Col>
        <Col
          span={8}
          xs={{ span: 24, order: 2 }}
          sm={{ span: 12, order: 2 }}
          lg={{ span: 8, order: 2 }}
          className="gutter-row"
        >
          <AreaGraphTemplate
            title={'Active Clients'}
            color={'#4673D1'}
            stats={sampleData}
            showAmount={false}
          />
        </Col>
        <Col
          span={8}
          xs={{ span: 24, order: 3 }}
          sm={{ span: 12, order: 3 }}
          lg={{ span: 8, order: 3 }}
          className="gutter-row"
        >
          <AreaGraphTemplate
            title={'Portfolio Clients'}
            color={'#04A451'}
            stats={sampleData}
            showAmount={false}
          />
        </Col>

        <Col
          span={8}
          xs={{ span: 24, order: 4 }}
          sm={{ span: 12, order: 4 }}
          lg={{ span: 8, order: 4 }}
          className="gutter-row"
        >
          <AreaGraphTemplate
            title={'Portfolio Volume'}
            color={'#FB913A'}
            stats={sampleData}
            showAmount={false}
          />
        </Col>
        <Col
          span={8}
          xs={{ span: 24, order: 5 }}
          sm={{ span: 12, order: 5 }}
          lg={{ span: 8, order: 5 }}
          className="gutter-row"
        >
          <AreaGraphTemplate
            title={'Portfolio Value'}
            color={'#FF576D'}
            stats={sampleData}
            showRequests={false}
          />
        </Col>
      </Row>
    </div>
  );
};
