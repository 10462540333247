import React from 'react';
import PropTypes from 'prop-types';

const SearchCriteria = ({ className }) => (
  <div className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.50108 5H18.5011C18.6451 5.05051 18.7756 5.13331 18.8827 5.24206C18.9898 5.35082 19.0706 5.48264 19.1188 5.62742C19.1671 5.77221 19.1816 5.92612 19.1612 6.07737C19.1407 6.22861 19.086 6.37318 19.0011 6.5L14.0011 12V19L10.0011 16V12L5.00108 6.5C4.91617 6.37318 4.86142 6.22861 4.84101 6.07737C4.8206 5.92612 4.83509 5.77221 4.88335 5.62742C4.93161 5.48264 5.01237 5.35082 5.11945 5.24206C5.22652 5.13331 5.35707 5.05051 5.50108 5Z"
        stroke="#303030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

SearchCriteria.propTypes = {
  className: PropTypes.string,
};

SearchCriteria.defaultProps = {
  className: '',
};

export default SearchCriteria;
