import Api from 'store/effects/core/api';
import { postListingFilter } from 'api/ai';
import { requestPostListingFiltersAction } from 'store/actions/ai';

export const requestGetListingFilterEffect = (cfg, options = {}, cb) => {
  const requestParams = { action: requestPostListingFiltersAction, method: postListingFilter };

  let sendRequest = Api.execBase(requestParams);

  return sendRequest(cfg, {}, cb);
};
