import classNames from 'classnames';
import { Dropdown, Menu, MenuItem, Popover, Radio } from 'components-antd';
import { useMemo, useState } from 'react';

import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getDynamicFormEditorConfigSelector } from 'store/selectors/requestFormProcess';
import { Check } from 'components/Icons';
import { updateDynamicFormQuestionEffect } from 'store/effects/formProcess';

export const RolesPopover = ({ field, color, selectedFieldIndex, fieldActionProps }) => {
  const name = field.customRole?.roleName;
  const dispatch = useDispatch();

  const { formRoles } = useSelector(getDynamicFormEditorConfigSelector);

  const roleId = field.customRole?.roleId;

  const onSelectRole = (roleItem) => {
    const newField = { ...field };

    dispatch(
      updateDynamicFormQuestionEffect({
        ...newField,
        index: selectedFieldIndex,
        newRole: roleItem.roleId,
        ...fieldActionProps,
      }),
    );
  };

  const menu = (
    <Radio.Group className={styles.optionsItems}>
      <Menu>
        {formRoles.map((item, idx) => (
          <MenuItem key={idx} onClick={() => onSelectRole(item)}>
            <Radio value={item.roleId} key={idx}>
              <div className={styles.option}>
                <span
                  style={{
                    backgroundColor: item.color?.border,
                  }}
                  className={styles.userColorBox}
                />
                <span className={styles.optionValue}>{item.roleName}</span>
              </div>
              {roleId === item.roleId && <Check className={styles.checkIcon} />}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.rolesDropdownWrapper)}>
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        trigger={['click']}
        overlayClassName={styles.rolesPopover}
      >
        <div className={styles.roleInformation}>
          <span
            style={{
              backgroundColor: color?.border,
            }}
            className={styles.userColorBox}
          />
          <span className={styles.userTitle}>{name}</span>
        </div>
      </Dropdown>
    </div>
  );
};
