import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const MenuListItems = ({ items }) => (
  <>
    {items.length > 0 &&
      items.map((item) => {
        const ItemIcon = item.icon;

        return (
          <div className={styles.menuListItem} onClick={item.onClick} key={item.id}>
            <ItemIcon className={styles.menuListIcon} />
            <span>{item.name}</span>
          </div>
        );
      })}
  </>
);

MenuListItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      onClick: PropTypes.func,
    }).isRequired,
  ),
};

export default MenuListItems;
