import { useState } from 'react';
import Space from 'antd/lib/space';
import { useDispatch, useSelector } from 'react-redux';

import { isEmail } from 'utils';
import { FORM_USER_ROLE, incorrectEmail } from 'app-constants';
import { OptionType, Row, Select, Text } from 'components-antd';
import { AgentsType, ClientsType, FormUserRoleType } from 'types';
import { updatePreFormResponseEffect } from 'store/effects/formProcess';
import { validationToast } from 'pages/FormProcess/helper';
import {
  getPreFormSelector,
  getFormMetaSelector,
  getSmartFormDataSelector,
} from 'store/selectors/requestFormProcess';
import { caseInsensitiveFilter } from 'helpers';

import styles from './styles.module.scss';
import { Navigation } from '../components';

export const FillForm = ({ onContinue }) => {
  const dispatch = useDispatch();

  const { clients, agents } = useSelector(getFormMetaSelector);
  const { editors, allowedEditors } = useSelector(getPreFormSelector);
  const { editorsPresent, editorsRoles } = useSelector(getSmartFormDataSelector);

  const [buyer, setBuyer] = useState<string[]>(
    (editors?.client?.map((client) => client.UserId || client.Email) as string[]) || [],
  );

  const handleChange = (options: string[], role: string) => {
    dispatch(
      updatePreFormResponseEffect({
        editors: {
          ...editors,
          [role.toLowerCase()]: options?.map((option) => ({
            FormRole: role,
            ...(isNaN(Number(option)) ? { Email: option } : { UserId: option }),
          })),
        },
      }),
    );
  };

  const handleFillerChange = (
    role: FormUserRoleType,
    options: string[],
    selectedOptions: string[],
    setSelectedOptions: (options: string[]) => void,
    fillers?: AgentsType | ClientsType,
  ) => {
    const lastOption = options[options.length - 1];
    if (isNaN(Number(lastOption))) {
      if (!isEmail(lastOption)) return validationToast(incorrectEmail);
    } else if (!fillers?.some((filler) => String(filler.Id) === lastOption))
      return validationToast(incorrectEmail);

    setSelectedOptions([lastOption]);
    handleChange([lastOption], role);
  };

  const getFillerOptions = (fillers?: AgentsType | ClientsType): OptionType[] =>
    fillers?.map((filler) => ({
      label: `${filler.FirstName} ${filler.LastName}`,
      value: String(filler.Id),
    })) || [];

  const renderFillerRow = (
    title: string,
    role: FormUserRoleType,
    selectedOptions: string[],
    setSelectedOptions: (options: string[]) => void,
    fillers?: AgentsType | ClientsType,
    disabled?: boolean,
  ) => (
    <>
      <Text className={styles.label} disabled={!disabled}>
        {title}
      </Text>
      <Select
        disabled={!disabled}
        value={selectedOptions}
        onChange={(options) =>
          handleFillerChange(role, options, selectedOptions, setSelectedOptions, fillers)
        }
        large
        mode="tags"
        placeholder={
          disabled ? 'Enter name or email address...' : `${role} has already edited the form`
        }
        bordered={false}
        className={styles.listBoxInput}
        options={getFillerOptions(fillers)}
        filterOption={(inputValue, option) =>
          caseInsensitiveFilter(inputValue, option?.props?.label)
        }
      />
    </>
  );

  const clientConditions = !allowedEditors || allowedEditors.includes(FORM_USER_ROLE.Client);

  return (
    <>
      {editorsPresent ? (
        <>
          <Text className={styles.questionHeader}>Who will be filling out the form?</Text>
          <div className={styles.pageContent}>
            <div className={styles.fieldWrapper}>
              {renderFillerRow(
                'Buyer',
                FORM_USER_ROLE.Client,
                buyer,
                setBuyer,
                clients,
                clientConditions,
              )}
            </div>

            <Navigation onContinue={onContinue} />
          </div>
        </>
      ) : (
        <Text className={styles.questionHeader}>No Editors available for this form</Text>
      )}
    </>
  );
};
