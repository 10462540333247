import { routes } from 'settings/navigation/routes';
import { CLIENT } from 'settings/constants/roles';

import { Vault } from 'pages';
import { VaultPropertyPage } from 'pages/Vault/VaultPropertyPage';
import { PreApprovals } from 'pages/Vault/PreApprovals';
import { Archives } from 'pages/Vault/Archives';

export default [
  {
    path: routes.vault,
    component: Vault,
    exact: true,
    roles: [CLIENT],
  },
  {
    path: routes.vaultPropertyPage,
    component: VaultPropertyPage,
    exact: true,
    roles: [CLIENT],
  },
  {
    path: routes.vaultPreApprovals,
    component: PreApprovals,
    exact: true,
    roles: [CLIENT],
  },
  {
    path: routes.vaultArchives,
    component: Archives,
    exact: true,
    roles: [CLIENT],
  },
];
