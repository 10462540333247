export const prepareOptions = (participantList, seller, creator, filtered = false) => {
  const list: any = [];
  participantList?.forEach((p) => {
    if (filtered && (p.Id === creator?.Id || p.Id === seller?.Id)) return;

    // const index = details?.findIndex((f) => f?.UserId === p.Id);

    list.push({
      label: `${p.FirstName} ${p.LastName}`,
      value: p.Email,
    });
  });

  return list;
};
