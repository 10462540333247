import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ transactionCreate }) => transactionCreate;

export const getTransactionPreFormSelector = createSelector(localState, (transactionCreate) => ({
  isIdle: transactionCreate.state === IDLE,
  isPending: transactionCreate.state === PENDING,
  isData: !!transactionCreate?.data,
  preForm: transactionCreate?.data || {},
}));
