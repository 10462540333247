import { useEffect, useMemo } from 'react';
import { useRequest } from 'hooks';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ApiRequestError, Wrapper } from 'components';

import CircularProgressBarWithText from './CircularProgressBarWithText';
import { getClimateCheckDetail } from 'api/listingDetail';
import { getListingLocationData } from 'store/selectors/listingDetail';
import styles from './styles.module.scss';

const ClimateData = (props) => {
  const { latitude: lat, longitude: long } = useSelector(getListingLocationData);
  useEffect(() => {
    sendRequest();
  }, []);

  const [{ loading, error, data }, , sendRequest] = useRequest({
    request: getClimateCheckDetail,
    autoSend: false,
    params: {
      lat,
      long,
    },
  });

  const { range, storm, heat, flood, fire, drought } = useMemo(() => {
    const riskScores = [
      data?.storm_score,
      data?.heat_score,
      data?.flood_score,
      data?.drought_score,
      data?.fire_score,
    ];
    const climateRiskScores = {
      storm: {
        score: data?.storm_score,
        risk: data?.storm_score_risk,
      },
      heat: {
        score: data?.heat_score,
        risk: data?.heat_score_risk,
      },
      flood: {
        score: data?.flood_score,
        risk: data?.flood_score_risk,
      },
      drought: {
        score: data?.drought_score,
        risk: data?.drought_score_risk,
      },
      fire: {
        score: data?.fire_score,
        risk: data?.fire_score_risk,
      },
      range: {
        score: Math.max(...riskScores),
        risk: `${Math.min(...riskScores)}-${Math.max(...riskScores)}`,
      },
    };

    return climateRiskScores;
  }, [data]);

  return (
    <Wrapper isPending={loading}>
      <div testid="climate_check_block" className={styles.climateCheck}>
        {error ? (
          <ApiRequestError title={error?.message} />
        ) : (
          <>
            <CircularProgressBarWithText
              blockType="Range"
              percentage={range.score}
              percentageText={range.risk}
              title="Risk Range"
              showBorder={false}
              size="120"
            />
            <CircularProgressBarWithText
              blockType="Storm"
              percentage={storm.score}
              title="Storm Risk"
              description={storm.risk}
            />
            <CircularProgressBarWithText
              blockType="Fire"
              percentage={fire.score}
              title="Fire Risk"
              description={fire.risk}
            />
            <CircularProgressBarWithText
              blockType="Heat"
              percentage={heat.score}
              title="Heat Risk"
              description={heat.risk}
            />
            <CircularProgressBarWithText
              blockType="Flood"
              percentage={flood.score}
              title="Flood Risk"
              description={flood.risk}
            />
            <CircularProgressBarWithText
              blockType="Drought"
              percentage={drought.score}
              title="Drought Risk"
              description={drought.risk}
            />
          </>
        )}
      </div>
      <div className={styles.disclaimerTextWrapper}>
        <span>
          ClimateCheck ratings reflect hazard risk at a property relative to the rest of the
          contiguous United States. Ratings are based on projected 2050 risk and the change from
          historical risk.
          <br />A rating of 1 represents the lowest risk; 100 is the highest. See{' '}
          <a href="https://climatecheck.com/our-methodologies">
            ClimateCheck methodology for details
          </a>
          .
        </span>
      </div>
    </Wrapper>
  );
};

ClimateData.propTypes = {
  data: PropTypes.shape({
    long: PropTypes.string.isRequired,
    lat: PropTypes.string.isRequired,
  }),
};

export default ClimateData;
