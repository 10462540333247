import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Question } from 'pages/RequestQuote/components';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Continue } from '../../Continue';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { useMemo } from 'react';
import { WorkshopType } from 'app-constants';

const CancellationComplete = (props) => {
  const { className, onSubmit } = props;
  const isProject = useIsProjectRoute();

  const cancellationType = useMemo(() => {
    if (isProject) return WorkshopType.Project.toLowerCase();
    return WorkshopType.Transaction.toLowerCase();
  }, [isProject]);

  return (
    <div testid="transaction_complete" className={classNames(styles.container, className)}>
      <Question className={styles.question}>This {cancellationType} is cancelled.</Question>
      <p className={styles.content}>
        You can revisit the {cancellationType} room anytime via your archive.
      </p>
      <ButtonsContainer>
        <Continue onClick={onSubmit} variant="done" />
      </ButtonsContainer>
    </div>
  );
};

CancellationComplete.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};

CancellationComplete.defaultProps = {
  className: '',
  onSubmit: () => {},
};

export default CancellationComplete;
