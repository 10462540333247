import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ArrowLeft } from 'components/Icons';

import NotFound from './not-found.png';
import styles from './styles.module.scss';
import classNames from 'classnames';

const PageNotFound = ({
  btnLabel,
  backUrl,
  info,
  title,
  onClick,
  showInfo,
  imageClassName,
  showButton,
}) => (
  <div className={styles.container}>
    <div className={classNames(styles.image, imageClassName)}>
      <img src={NotFound} alt="not found" />
    </div>
    <div className={styles.title}>{title}</div>
    {showInfo && <div className={styles.info}>{info}</div>}
    {showButton && (
      <Link onClick={onClick} className={styles.back} to={backUrl}>
        <ArrowLeft className={styles.arrow} />
        <span>{btnLabel}</span>
      </Link>
    )}
  </div>
);

PageNotFound.propTypes = {
  backUrl: PropTypes.string,
  info: PropTypes.string,
  title: PropTypes.string,
  btnLabel: PropTypes.string,
  onClick: PropTypes.func,
  showButton: PropTypes.bool,
  showInfo: PropTypes.bool,
  classNames: PropTypes.string,
};

PageNotFound.defaultProps = {
  btnLabel: 'Back',
  title: 'Oops… lost that one.',
  info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elita',
  backUrl: 'null',
  onClick: () => {},
  showButton: true,
  showInfo: true,
  imageClassName: '',
};

export default PageNotFound;
