import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Header from 'pages/Dashboard/components/Header';
import Icons from 'pages/Dashboard/components/Icons';
import Filters from 'pages/Dashboard/Users/FilterForm';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Wrapper as PendingWrapper, ConfirmationDialog } from 'components';
import Grid from 'pages/Dashboard/components/Grid';
import {
  activateUserEffect,
  deactivateUserEffect,
  getAllUsersDashboardEffect,
} from 'store/effects/adminPanel/users';
import {
  getUsersDashboardSelector,
  getUsersDataDashboardSelector,
} from 'store/selectors/adminPanel';
import ViewUser from './View';
import { gridColumns } from './config';

import styles from './styles.module.scss';

const UsersOverview = (props) => {
  const dispatch = useDispatch();
  const users = useSelector(getUsersDataDashboardSelector);
  const { isIdle, isPending } = useSelector(getUsersDashboardSelector);
  const { className } = props;

  const [previewUserId, setPreviewUserId] = useState(null);
  const [userToSuspend, setUserToSuspend] = useState(null);

  const onPreviewModalClose = useCallback(() => setPreviewUserId(null), [setPreviewUserId]);
  const onRowClick = useCallback((e, { Id }) => setPreviewUserId(Id), [setPreviewUserId]);

  const onCloseSuspendUserModal = useCallback(() => setUserToSuspend(null), [setUserToSuspend]);
  const onConfirmSuspendUser = useCallback(() => {
    if (!userToSuspend) return;

    const effect = !userToSuspend.Deactivated ? deactivateUserEffect : activateUserEffect;

    dispatch(
      effect({ id: userToSuspend.Id }, {}, (err) => {
        if (!err) {
          setUserToSuspend(null);
        }
      }),
    );
  }, [dispatch, userToSuspend, setUserToSuspend]);

  const renderActions = useCallback(
    (Id, data) => (
      <>
        <div
          className={!data.Deactivated ? styles.deactivateButton : styles.activateButton}
          onClick={(e) => {
            e.stopPropagation();
            setUserToSuspend(data);
          }}
        >
          <Icons
            className={styles.buttonIcon}
            variant={!data.Deactivated ? Icons.DEACTIVATE : Icons.ACTIVATE}
          />
        </div>
      </>
    ),
    [],
  );

  useEffect(() => {
    if (isIdle) {
      dispatch(getAllUsersDashboardEffect({}));
    } else {
      dispatch(getAllUsersDashboardEffect({}, { silent: true }));
    }
  }, []); // eslint-disable-line

  const suspendUserContent = useMemo(
    () =>
      userToSuspend ? (
        <div>
          Are you sure that you want to&nbsp;
          <b>{!userToSuspend.Deactivated ? 'deactivate' : 'activate'}</b>
          &nbsp;user&nbsp;
          <b>
            {userToSuspend.FirstName}
            &nbsp;
            {userToSuspend.LastName}
          </b>
          ?
        </div>
      ) : null,
    [userToSuspend],
  );

  return (
    <PendingWrapper className={classNames(styles.wrapper, className)} isPending={isPending}>
      <Header title="Users" />
      <Filters />
      <Grid
        items={users || []}
        columns={gridColumns}
        keyProp="Id"
        onRowClick={onRowClick}
        renderActions={renderActions}
      />
      <ConfirmationDialog
        onReject={onCloseSuspendUserModal}
        onConfirm={onConfirmSuspendUser}
        isOpen={!!userToSuspend}
      >
        {suspendUserContent}
      </ConfirmationDialog>
      <Modal
        isOpen={!!previewUserId}
        onClose={onPreviewModalClose}
        innerHolderClassName={styles.modalInnerHolder}
        contentClassName={styles.modalContent}
      >
        {!!previewUserId && <ViewUser id={previewUserId} onClose={onPreviewModalClose} />}
      </Modal>
    </PendingWrapper>
  );
};

UsersOverview.propTypes = {
  className: PropTypes.string,
};

UsersOverview.defaultProps = {
  className: '',
};

export default UsersOverview;
