import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { PENDING } from 'settings/constants/apiState';
import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';

import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';
import { Answers } from 'components';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';
import { Title, Back, Wrapper, NextButton } from '../..';

const StageInterior = ({ onNext, onPrev, stageIndex }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);

  const propName = OnBoardingWizard.propNames.interiorStylesPrefs;
  const storedInteriorStyles = getWizardStageValue({
    obj: onBoarding,
    stageIndex,
    propName,
    signUpRole,
  });
  const [stageValue, setStageValue] = useState(storedInteriorStyles || []);

  const onSave = () => {
    const cfg = {
      stageIndex,
      DefaultPropertySearchPreferences: {
        [propName]: stageValue,
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, {}, (err) => {
        if (!err) {
          onNext();
        }
      }),
    );
  };

  const onAnswer = (val) => {
    setStageValue(val);
  };

  const isPending = onBoarding.state === PENDING;
  const disabled = !Object.keys(stageValue).length;

  return (
    <Wrapper testid="interior_stage">
      <Back testid="interior_back" onBack={onPrev} />
      <Title>What interior styles do you prefer?</Title>
      <Answers
        unselectable
        multiple
        cases={StageInterior.answerCases}
        onChange={onAnswer}
        value={stageValue}
        testid="interior_answers"
      />
      <NextButton
        testid="interior_next"
        onClick={onSave}
        isPending={isPending}
        disabled={disabled}
        iconArrow
      />
    </Wrapper>
  );
};

StageInterior.answerCases = [
  { id: 'Modern', label: 'Modern' },
  { id: 'Loft', label: 'Loft' },
  { id: 'Traditional', label: 'Traditional' },
  { id: 'NotImportant', label: 'Not Important' },
];

StageInterior.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  stageIndex: PropTypes.number,
};

StageInterior.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  stageIndex: undefined,
};

export default StageInterior;
