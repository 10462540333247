import Api from 'store/effects/core/api';
import {
  openSearchResultsFilterDrawerAction,
  requestSaveSearchAction,
  requestSaveSearchInstanceAction,
  openSavedSearchesDrawerAction,
  sortSearchResultsAction,
  setSearchDrawerCommuteIdAction,
  openAddEditSchoolDrawerAction,
  saveAddEditSchoolDataAction,
  showWishlistTabAction,
  saveAddEditSchoolRawDataAction,
} from 'store/actions/searchResults';
import { saveSearch, saveSearchInstance } from 'api/searchResults';
import { getFirstImage } from 'helpers';
import { getState } from 'store';

export const openSearchResultsFilterDrawerEffect = (cfg) => (dispatch) =>
  dispatch(openSearchResultsFilterDrawerAction(cfg));

export const saveAddEditSchoolDataEffect = (cfg) => (dispatch) =>
  dispatch(saveAddEditSchoolDataAction(cfg));

export const saveAddEditSchoolRawDataEffect = (cfg) => (dispatch) =>
  dispatch(saveAddEditSchoolRawDataAction(cfg));

export const openAddEditSchoolDrawerEffect = (cfg) => (dispatch) =>
  dispatch(openAddEditSchoolDrawerAction(cfg));

export const showWishListEffect = (cfg) => (dispatch) => dispatch(showWishlistTabAction(cfg));

export const openSavedSearchesDrawerEffect = (cfg) => (dispatch) =>
  dispatch(openSavedSearchesDrawerAction(cfg));

export const saveSearchInstanceEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: requestSaveSearchInstanceAction,
    method: saveSearchInstance,
  });
  const { search, searchResults } = getState();

  const config = {
    Name: cfg.name,
    ContextKey: cfg.contextKey,
    Status: cfg.status,
    DefaultPropertySearchPreferences: cfg?.DefaultPropertySearchPreferences,
    InvitedBy: cfg.agentId,
    PhotoUrls:
      searchResults?.results?.data?.Properties?.slice(0, 5)
        .map((property) => {
          const { Media, PhotoUrls } = property;
          const firstImage = getFirstImage(Media);
          const ImageToSend = firstImage ? firstImage : PhotoUrls?.[0];
          return ImageToSend;
        })
        .filter((url) => !!url) ?? [],
    TotalPropertyCount: searchResults?.results?.data?.TotalCount?.value ?? 0,
    ...(cfg?.ClientIds?.length ? { ClientIds: cfg?.ClientIds } : {}),
  };

  return sendRequest(config, options, cb);
};

export const saveSearchEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({ action: requestSaveSearchAction, method: saveSearch });
  const { search, searchResults } = getState();

  const config = {
    Name: cfg.name,
    SearchQuery: search?.search,
    PhotoUrls:
      searchResults?.results?.data?.Properties?.slice(0, 5)
        .map((property) => {
          const { Media, PhotoUrls } = property;
          const firstImage = getFirstImage(Media);
          return firstImage ? firstImage : PhotoUrls?.[0];
        })
        .filter((url) => !!url) ?? [],
    TotalPropertyCount: searchResults?.results?.data?.TotalCount?.value ?? 0,
  };

  return sendRequest(config, options, cb);
};

export const sortSearchResultsEffect = (cfg) => (dispatch) =>
  dispatch(sortSearchResultsAction(cfg));

export const setSearchDrawerCommuteIdEffect = (cfg) => (dispatch) =>
  dispatch(setSearchDrawerCommuteIdAction(cfg));
