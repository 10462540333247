import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import Icon from 'pages/Properties/Feed/Icons';

import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';

const Back = (props) => {
  const { className, label } = props;
  const history = useHistory();

  const onBack = () => {
    if (history.action === 'POP') {
      history.push(routes.feed);
    } else {
      history.goBack();
    }
  };

  return (
    <div onClick={onBack} className={classNames(styles.back, className)}>
      <Icon className={styles.arrowIcon} variant={Icon.ARROW_RIGHT} />
      <span testid="back_button" className={styles.text}>
        {label}
      </span>
    </div>
  );
};

Back.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

Back.defaultProps = {
  className: '',
  label: 'Back to Feed',
};

export default Back;
