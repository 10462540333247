export const agentBreakdownData = [
  {
    avatarUrl: null,
    firstName: 'Ashley',
    lastName: 'Jones',
    amount: 250000,
    count: 5,
    percentage: 10,
  },
  {
    avatarUrl: null,
    firstName: 'Betsy',
    lastName: 'Larson',
    amount: 1500000,
    count: 6,
    percentage: 30,
  },
  {
    avatarUrl: null,
    firstName: 'Ava',
    lastName: 'Williams',
    amount: 50000,
    count: 6,
    percentage: 5,
  },
  {
    avatarUrl: null,
    firstName: 'Billie',
    lastName: 'Hobbs',
    amount: 112000,
    count: 6,
    percentage: 5,
  },
  {
    avatarUrl: null,
    firstName: 'Ashley',
    lastName: 'Smith',
    amount: 250000,
    count: 5,
    percentage: 10,
  },
  {
    avatarUrl: null,
    firstName: 'Rebecca',
    lastName: 'Torres',
    amount: 5000000,
    count: 10,
    percentage: 50,
  },
  {
    avatarUrl: null,
    firstName: 'Ashley',
    lastName: 'Jones',
    amount: 250000,
    count: 5,
    percentage: 10,
  },
  {
    avatarUrl: null,
    firstName: 'Betsy',
    lastName: 'Larson',
    amount: 1500000,
    count: 6,
    percentage: 30,
  },
  {
    avatarUrl: null,
    firstName: 'Ava',
    lastName: 'Williams',
    amount: 50000,
    count: 6,
    percentage: 5,
  },
  {
    avatarUrl: null,
    firstName: 'Billie',
    lastName: 'Hobbs',
    amount: 112000,
    count: 6,
    percentage: 5,
  },
  {
    avatarUrl: null,
    firstName: 'Ashley',
    lastName: 'Smith',
    amount: 250000,
    count: 5,
    percentage: 10,
  },
  {
    avatarUrl: null,
    firstName: 'Rebecca',
    lastName: 'Torres',
    amount: 5000000,
    count: 10,
    percentage: 50,
  },
];
