import { TextArea } from 'components-antd/Input';

import styles from './styles.module.scss';
import { Input } from '../../../../../../../components';

export const Note = ({ data, counterOfferFormData, setCounterOfferFormData }) => {
  const { Note } = data;

  const onChangeValue = (val, fieldName) => {
    setCounterOfferFormData({
      ...counterOfferFormData,
      [fieldName]: val,
    });
  };

  return (
    <div className={styles.note}>
      <h4 className={styles.title}>Note</h4>
      <div className={styles.inputBlock}>
        <Input
          className={styles.input}
          placeholder="Enter a note"
          inputHolderClassName={styles.inputHolder}
          variant={Input.LIGHT}
          onChange={(e) => onChangeValue(e.target.value, 'Notes')}
        />
      </div>
    </div>
  );
};
