import { FormContainer } from 'pages/OnboardingV2Agent/components';
import React, { useEffect, useState } from 'react';

import parentStyles from 'pages/SignupV2/components/PersonalDetails/styles.module.scss';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getUserDataSelector, getUserEmail } from 'store/selectors/user';
import { sendVerificationEmail } from 'api/user';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import { EmailVerificationData } from 'pages/OnBoarding/components/Forms/VerifyEmail';
import moment from 'moment';
import classNames from 'classnames';

type Props = {
  onNext: () => void;
};

export const VerifyEmail: React.FC<Props> = ({ onNext }) => {
  const user = useSelector(getUserDataSelector);
  const userEmail = useSelector(getUserEmail);
  const [emailVerificationData, setEmailVerificationData] = useState<
    undefined | null | EmailVerificationData
  >(undefined);
  const [remainingSecondsToResendLink, setRemainingSecondsToResendLink] = useState<number | null>(
    null,
  );

  const onSkip = () => onNext();
  const handleResendClick = async () => {
    try {
      const response = await sendVerificationEmail({
        email: user.Email,
      });
      setEmailVerificationData(response.data);
      showSuccessMessage('Email verification link sent');
    } catch (err) {
      showErrorMessage('Error occured while sending email verification link');
    }
  };

  // Update timer with remaining seconds for next attempt
  useEffect(() => {
    const interval = setInterval(() => {
      const remainingSeconds = moment(emailVerificationData?.AllowLinkResendTimestamp).diff(
        moment(),
        'seconds',
      );
      setRemainingSecondsToResendLink(remainingSeconds);

      if (remainingSeconds < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [emailVerificationData]);

  const getResendText = () => {
    if (remainingSecondsToResendLink === null) {
      return null;
    }

    if (remainingSecondsToResendLink > 0) {
      return <span>Resend it in {remainingSecondsToResendLink} seconds </span>;
    }
    if (remainingSecondsToResendLink <= 0) {
      return (
        <span>
          <a
            onClick={() => {
              handleResendClick();
            }}
            href="#"
            className={styles.resend}
          >
            Resend
          </a>
        </span>
      );
    }
  };

  if (user?.IsEmailVerified) onNext();

  return (
    <FormContainer>
      <div className={classNames(parentStyles.formContainer, styles.extraPadding)}>
        <div className={styles.headerTag}>
          <span>Thank you!</span> Your payment was successful.
        </div>
        <div className={styles.heading}>Please validate your email.</div>
        <div className={styles.info}>
          We just sent a validation link via email to <span>{userEmail}</span>. If you can please
          take a few seconds to click that link, it will speed things up later.
        </div>
        <div className={styles.info}>
          Didn&apos;t receive it? <span className={styles.actionBtn}>{getResendText()}</span>
        </div>
        <div className={styles.btnContainer}>
          <div className={styles.skip} onClick={onSkip}>
            Skip
          </div>
        </div>
      </div>
    </FormContainer>
  );
};
