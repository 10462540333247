import React from 'react';

import styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';

type Props = {
  title: string;
  subTitle?: string;
  link?: string;
};

export const FormHeader: React.FC<Props> = ({ title, subTitle, link }) => {
  const history = useHistory();

  const redirectToLogin = () => {
    history.push('/login');
  };
  return (
    <div className={styles.headerContainer}>
      <div className={styles.header}>{title}</div>
      <div className={styles.subHeader}>
        {subTitle && <div>{subTitle}</div>}
        {link && (
          <div className={styles.link} onClick={redirectToLogin}>
            {link}
          </div>
        )}
      </div>
    </div>
  );
};
