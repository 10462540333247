import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { DateRangePicker } from 'components/Form/DateRangePicker';
import { Moment } from 'moment';
import { FilterDrawer, FilterSection } from 'pages/Workshop/common';
import { GroupCheckBoxesSection } from 'pages/Workshop/common/GroupCheckBoxesSection';
import { MultiSelectSection } from 'pages/Workshop/common/MultiSelectSection';
import { LocationService } from 'services';
import { getAllTransactionsSelector } from 'store/selectors/transactions';
import { isEqual } from 'lodash-es';
import { getAllTransactionParticipantsSelector } from 'store/selectors/transaction';
import { getTransactionTaskCategoriesSelector } from 'store/selectors/transactionTaskCategories';

let defaultFilters = {};

export const FilterSections = ({ onDone, onReset, setIsFilterApplied }) => {
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const { categories } = useSelector(getTransactionTaskCategoriesSelector);
  const participants = useSelector(getAllTransactionParticipantsSelector);
  const participantsWithFullAccess = participants?.filter((item) => item?.TransactionAccess) || [];
  const transactionsData = useSelector(getAllTransactionsSelector);
  const [initialCache] = useState(transactionsData);
  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
  const [userIds, setUserIds] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [createdFrom, setCreatedFrom] = useState<Moment>();
  const [createdTo, setCreatedTo] = useState<Moment>();
  const [editedFrom, setEditedFrom] = useState<Moment>();
  const [editedTo, setEditedTo] = useState<Moment>();
  const [cachedCategories, setCachedCategories] = useState<any[]>([]);
  const [resetDates, setResetDates] = useState(false);
  const [previousFilters, setPreviousFilters] = useState({});

  const getFilters = () => ({
    userIds,
    tags,
    createdFrom,
    createdTo,
    editedFrom,
    editedTo,
  });

  useEffect(() => {
    defaultFilters = getFilters();
    setPreviousFilters(defaultFilters);
  }, []);

  useEffect(() => {
    if (!cachedCategories.length && categories?.length && !isEqual(categories, initialCache)) {
      setCachedCategories(categories);
    }
  }, [categories]);

  useEffect(() => {
    setIsFiltersApplied(
      Boolean(userIds.length || tags.length || createdFrom || createdTo || editedFrom || editedTo),
    );
  }, [userIds, tags, createdFrom, createdTo, editedFrom, editedTo]);

  useEffect(() => {
    if (isFiltersApplied) {
      setIsFilterApplied(true);
    } else {
      setIsFilterApplied(false);
    }
  }, [isFiltersApplied]);

  const resetHandler = () => {
    setIsFilterApplied(false);
    setUserIds([]);
    setTags([]);
    setCreatedFrom(undefined);
    setCreatedTo(undefined);
    setEditedFrom(undefined);
    setEditedTo(undefined);
    setResetDates(true);
    onReset();
    setPreviousFilters(defaultFilters);
  };

  const submitHandler = () => {
    onDone({
      userIds,
      tags,
      createdFrom,
      createdTo,
      editedFrom,
      editedTo,
    });
    if (isFiltersApplied) {
      setIsFilterApplied(true);
    } else {
      setIsFilterApplied(false);
    }
    setPreviousFilters(getFilters());
  };

  const discardHandler = () => {
    if (JSON.stringify(previousFilters) === JSON.stringify(getFilters())) {
      return;
    }

    const { userIds, tags, createdFrom, createdTo, editedFrom, editedTo } = previousFilters as any;

    if (userIds) setUserIds(userIds);
    if (createdFrom) setCreatedFrom(createdFrom);
    if (createdTo) setCreatedTo(createdTo);
    if (editedFrom) setEditedFrom(editedFrom);
    if (editedTo) setEditedTo(editedTo);
    if (tags) setTags(tags);
  };

  return (
    <FilterDrawer
      className={styles.filterBtn}
      isFiltersApplied={isFiltersApplied}
      onDone={submitHandler}
      onReset={resetHandler}
      onDiscard={discardHandler}
    >
      <div className={styles.filterSections}>
        <FilterSection title="Owner" count={userIds?.length}>
          <GroupCheckBoxesSection
            title={''}
            entityList={participantsWithFullAccess?.map(({ Id, FirstName, LastName }) => ({
              value: Id,
              name: `${FirstName} ${LastName}`,
            }))}
            entityValues={userIds}
            setEntity={setUserIds}
          />
        </FilterSection>
        <FilterSection title="Tags" count={tags?.length}>
          <MultiSelectSection
            placeholder={`Search by Tags...`}
            entityList={cachedCategories?.map((c) => ({
              value: c.Id,
              label: c.Name,
            }))}
            entity={tags}
            setEntity={setTags}
          />
        </FilterSection>
        <FilterSection title="Date Created">
          <div className={styles.datePickerContainer}>
            <DateRangePicker
              span={13}
              title={''}
              onDatesChange={(from, to) => {
                resetDates && setResetDates(false);
                setCreatedFrom(from);
                setCreatedTo(to);
              }}
              fromDateValue={createdFrom}
              toDateValue={createdTo}
              dateRangePickerWrapperClass={styles.dateRangePicker}
              resetDates={resetDates}
            />
          </div>
        </FilterSection>
        <FilterSection title="Last Edited">
          <div className={styles.datePickerContainer}>
            <DateRangePicker
              span={13}
              title={''}
              onDatesChange={(from, to) => {
                resetDates && setResetDates(false);
                setEditedFrom(from);
                setEditedTo(to);
              }}
              fromDateValue={editedFrom}
              toDateValue={editedTo}
              dateRangePickerWrapperClass={styles.dateRangePicker}
              resetDates={resetDates}
            />
          </div>
        </FilterSection>
      </div>
    </FilterDrawer>
  );
};
