import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import Item from '../../../Item';
import { ProfileIcons } from '../../../Icons';
import { AGENT_CONNECTION_STATUSES } from 'settings/constants/common';
import Input from 'components/Form/Input';
import { showErrorMessage } from 'helpers/errors';
import { updateClientDataEffect } from 'store/effects';
import { showSuccessMessage } from 'helpers';

import styles from './styles.module.scss';

export const Email = ({ contact, isExpanded }) => {
  const [emailEdit, setEmailEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(contact.Email);
  const isPendingClient = contact?.Client?.InvitationStatus === AGENT_CONNECTION_STATUSES.PENDING;
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    const emailValidator =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailValidator.test(email.toLowerCase());
  };

  const onSubmit = () => {
    if (contact.Email !== email) {
      if (validateEmail(email)) {
        setLoading(true);
        dispatch(
          updateClientDataEffect({ clientId: contact.Id, email }, (err) => {
            if (!err) {
              showSuccessMessage('Client email updated');
            }
            setLoading(false);
            setEmailEdit(false);
          }),
        );
      } else {
        showErrorMessage('Invalid email');
      }
    }
  };

  const getEmailValue = () => {
    const emailValue = (
      <a className={styles.email} href={`mailto:${contact.Email}`}>
        {contact.Email}
      </a>
    );

    if (emailEdit) {
      return (
        <Input
          id="email"
          className={styles.editEmail}
          inputHolderClassName={styles.inputHolder}
          type="text"
          value={email}
          onChange={(e, val) => setEmail(val)}
          onBlur={onSubmit}
          icon={
            <div className={styles.close} onClick={() => setEmailEdit(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M17 17L7 7" stroke="#747475" strokeWidth="2" strokeLinecap="round" />
                <path d="M7 17L17 7" stroke="#747475" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </div>
          }
          disabled={loading}
        />
      );
    }

    return isPendingClient ? (
      <div className={styles.emailContainer}>
        <div className={styles.editIcon} onClick={() => setEmailEdit(true)}>
          <ProfileIcons name={ProfileIcons.EDIT} />
        </div>
        {emailValue}
      </div>
    ) : (
      emailValue
    );
  };

  return (
    <Item
      className={classNames(styles.emailItem, { [styles.expanded]: isExpanded })}
      testid="email"
      label="Email"
      value={getEmailValue()}
    />
  );
};
