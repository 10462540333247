import classnames from 'classnames';
import TextArea from 'antd/lib/input/TextArea';

import { InputLabel } from 'components';
import { TaskEditableFormValuesKeys, TaskEditableFormValuesType } from 'types/transactionTasks';

import styles from './styles.module.scss';

interface DescriptionProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  isViewMode?: boolean;
  showOptional?: boolean | null;
  addDescription?: boolean | null;
  inputWrapperClassName?: string;
}

export const Description = ({
  values,
  onChangeField,
  isViewMode,
  showOptional,
  addDescription,
  inputWrapperClassName,
}: DescriptionProps) => {
  return (
    <div className={styles.description}>
      {addDescription || values?.Description ? (
        <div className={styles.spacing}>
          <InputLabel label="Description" className={styles.label} showOptional={showOptional} />
          <TextArea
            placeholder="Add Description"
            className={classnames(styles.textarea, inputWrapperClassName)}
            autoSize
            value={values?.Description}
            onChange={(e) => onChangeField(e.target.value, 'Description')}
            maxLength={2500}
            disabled={isViewMode}
          ></TextArea>
        </div>
      ) : null}
    </div>
  );
};
