import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { getTeamMemberTasks } from 'api/teamList';
import { ProfileIcons } from '../../Icons';
import { mapTasksStatuses, tasksStatusesIds } from 'settings/constants/transactionTasks';
import { isToday, isTomorrow, isYesterday, isBeforeToday } from 'helpers/date';
import { getSimpleDate } from 'helpers';
import { setProfileExpandedSection } from 'store/effects/app';
import { setTransactionTaskParamsEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';

export const Tasks = () => {
  const [taskList, setTaskList]: any = useState([]);
  const details = useSelector(getAgentDetailsSelector);
  const contact = details?.data || {};
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchTeamMemberTasks = async () => {
    const tasks = await getTeamMemberTasks(null, { agentId: contact?.Id });
    setTaskList(tasks?.data || []);
  };

  useEffect(() => {
    fetchTeamMemberTasks();
  }, []);

  const getStatusClassName = useCallback((val) => {
    switch (val) {
      case tasksStatusesIds.new:
        return styles.new;
      case tasksStatusesIds.inProgress:
        return styles.inProgress;
      case tasksStatusesIds.stuck:
        return styles.stuck;
      case tasksStatusesIds.overdue:
        return styles.overdue;
      default:
        return '';
    }
  }, []);

  const getDateClassName = useCallback((val) => {
    return isYesterday(val) || isToday(val) || isTomorrow(val) ? styles.alert : '';
  }, []);

  const renderDate = (value) => {
    if (isYesterday(value)) {
      return 'Yesterday';
    }

    if (isToday(value)) {
      return 'Today';
    }

    if (isTomorrow(value)) {
      return 'Tomorrow';
    }

    return getSimpleDate(value, 'M/D');
  };

  const redirectTo = (transactionId, taskId) => {
    dispatch(setProfileExpandedSection(false));
    dispatch(setTransactionTaskParamsEffect({ taskId, transactionId, isTaskDetail: true }));
    history.push(`/workshop/transactions/${transactionId}/tasks`);
  };

  return (
    <div className={styles.tasks}>
      {taskList.map((task, index) => (
        <div className={styles.task} key={index}>
          <div className={styles.details}>
            <div className={styles.icon}>
              <ProfileIcons name={ProfileIcons.TASK} />
            </div>
            <div
              className={styles.titleAddress}
              onClick={() => redirectTo(task.TransactionId, task.Id)}
            >
              <h4>{task.Title}</h4>
              <p>{task?.Property?.Address?.Line1}</p>
            </div>
          </div>
          <div className={styles.dueStatus}>
            <p className={classNames(styles.due, getDateClassName(task.DueDate))}>
              Due: {renderDate(task.DueDate)}
            </p>
            <div className={classNames(styles.status, getStatusClassName(task.Status))}>
              {mapTasksStatuses[task.Status]}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

Tasks.propTypes = {};

Tasks.defaultProps = {};
