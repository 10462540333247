import { Input } from 'antd';
import { Button } from 'components-antd';
import React from 'react';
import { reasonsList } from '.';
import styles from '../DowngradeModal/styles.module.scss';
import classNames from 'classnames';

type StepProps = {
  setCurrentStep: (step: number) => void;
  handleNextStep: () => void;
  selectedReason: number;
  additionalInput: string | null;
  setAdditionalInput: (input: string) => void;
  closeModal: () => void;
};

export const StepTwo: React.FC<StepProps> = ({
  setCurrentStep,
  handleNextStep,
  selectedReason,
  additionalInput,
  setAdditionalInput,
  closeModal,
}) => {
  return (
    <div className={styles.content}>
      <div className={styles.header} style={{ marginTop: '11px' }}>
        What made you decide to cancel?
      </div>
      <div className={styles.reasonContainer}>
        <div className={styles.reasonBtn} onClick={() => setCurrentStep(3)}>
          {selectedReason >= 0 ? reasonsList[selectedReason]?.title : 'Select a Reason...'}
        </div>
      </div>

      {selectedReason >= 0 && reasonsList[selectedReason]?.additional && (
        <div className={styles.reasonContainer}>
          <div className={styles.additionalInput}>{reasonsList[selectedReason]?.additional}</div>
          <Input
            type="text"
            placeholder="Please share some additional details..."
            value={additionalInput || ''}
            onChange={(event) => setAdditionalInput(event.target.value)}
            className={classNames(styles.input, styles.additionalInput)}
          />
        </div>
      )}

      <div className={styles.btnContainer}>
        <Button variant="secondary" className={styles.cancelBtn} onClick={closeModal}>
          Never Mind
        </Button>
        <Button
          variant="primary"
          className={styles.confirmBtn}
          onClick={handleNextStep}
          disabled={selectedReason < 0}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
};
