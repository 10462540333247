import { FormDocumentQuestionType, SignatureFormRole } from 'types';

export const PDF_FIELD_TYPE = {
  PDFButton: 'PDFButton',
  PDFCheckBox: 'PDFCheckBox',
  PDFDropdown: 'PDFDropdown',
  PDFOptionList: 'PDFOptionList',
  PDFRadioGroup: 'PDFRadioGroup',
  PDFSignature: 'PDFSignature',
  PDFTextField: 'PDFTextField',
} as const;

export const ALLOWED_PDF_FIELDS = [
  PDF_FIELD_TYPE.PDFCheckBox,
  PDF_FIELD_TYPE.PDFTextField,
  PDF_FIELD_TYPE.PDFSignature,
  PDF_FIELD_TYPE.PDFRadioGroup,
];

export const SIGNATURE_FIELD_COLORS = [
  {
    background: 'rgba(70, 115, 209, 0.1)',
    border: '#4673D1',
  },
  {
    background: 'rgba(255, 242, 243, 1)',
    border: '#FF576D',
  },
  {
    background: 'rgba(81, 191, 225, 0.1)',
    border: '#51BFE1',
  },
  {
    background: 'rgba(34, 70, 170, 0.1)',
    border: '#2246AA',
  },
  {
    background: 'rgba(146, 140, 218, 0.1)',
    border: '#928CDA',
  },
  {
    background: 'rgba(251, 145, 58, 0.1)',
    border: '#FB913A',
  },
  {
    background: 'rgba(4, 164, 81, 0.1)',
    border: '#04A451',
  },
  {
    background: 'rgba(135, 82, 60, 0.1)',
    border: '#87523C',
  },
  {
    background: 'rgba(158, 0, 38, 0.1)',
    border: '#9E0026',
  },
  {
    background: 'rgba(177, 76, 153, 0.1)',
    border: '#B14C99',
  },
  {
    background: 'rgba(215, 83, 143, 0.1)',
    border: '#D7538F',
  },
  {
    background: 'rgba(0, 114, 117, 0.1)',
    border: '#007275',
  },
];

export const getSignatoryColor = (index: number) => {
  const mod = index % SIGNATURE_FIELD_COLORS.length;

  return SIGNATURE_FIELD_COLORS[mod];
};

export const getDynamicFormColor = (
  allFormRoles: SignatureFormRole[],
  question: FormDocumentQuestionType,
  flip?: boolean,
) => {
  let color:
    | {
        background: string;
        border: string;
      }
    | undefined;

  const roleIndex = allFormRoles?.findIndex(
    (role) => role.roleId === question?.CustomPermissions?.[0],
  );

  if (roleIndex > -1) {
    color = getSignatoryColor(roleIndex);
  } else {
    color = SIGNATURE_FIELD_COLORS?.[0];
  }

  if (flip) {
    return {
      background: color.border,
      border: color.background,
    };
  }

  return color;
};
