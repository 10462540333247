import {
  IsProperty,
  PropertyAddress,
  IsClient,
  ClientName,
} from 'pages/RequestQuote/components/PreForm/Questions';
import { AGENT, CLIENT } from './roles';

export const preFormQuestionsIds = {
  isProperty: 'isProperty',
  property: 'property',
  isClient: 'isClient',
  client: 'client',
};

export const preFormQuestions = (userRole) => {
  switch (userRole) {
    case AGENT: {
      return [
        { id: preFormQuestionsIds.isProperty, Component: IsProperty },
        { id: preFormQuestionsIds.property, Component: PropertyAddress },
        { id: preFormQuestionsIds.isClient, Component: IsClient },
        { id: preFormQuestionsIds.client, Component: ClientName },
      ];
    }
    case CLIENT: {
      return [
        { id: preFormQuestionsIds.isProperty, Component: IsProperty },
        { id: preFormQuestionsIds.property, Component: PropertyAddress },
      ];
    }
    default:
      return [];
  }
};
