import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableSubscriptionPlansSelector } from 'store/selectors/subscription';
import { Button } from 'components-antd';
import Card from 'antd/lib/card';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import {
  cancelCurrentSubscription,
  initSubscriptionCheckout,
  renewCurrentSubscription,
  updateCurrentSubscription,
} from 'api/subscription';
import { getActiveSubscriptionData } from 'store/selectors/user';
import { requestGetAvailableSubscriptionPlansEffect } from 'store/effects/subscription';

export const ChangePlan: React.FC = () => {
  const dispatch = useDispatch();

  const activeSubscriptionData = useSelector(getActiveSubscriptionData);
  const activePlanLevel = activeSubscriptionData?.isSubscriptionActive
    ? activeSubscriptionData?.planLevel
    : null;
  const availablePlans = useSelector(getAvailableSubscriptionPlansSelector);

  useEffect(() => {
    dispatch(requestGetAvailableSubscriptionPlansEffect());
  }, []);

  const handleSubscribeClick = async (planId: string) => {
    const response = await initSubscriptionCheckout({
      planId,
    });

    if (!response.data.success || !response.data.result) {
      showErrorMessage('Error occurred');
    } else {
      window.location.href = response.data.result.RedirectUrl;
    }
  };

  const handleCancelSubscriptionClick = async (planId: string) => {
    const response = await cancelCurrentSubscription();
  };

  const handleRenewSubscriptionClick = async (planId: string) => {
    const response = await renewCurrentSubscription();
  };

  const handleUpgradeSubscriptionClick = async (newPlanId: string) => {
    const response = await updateCurrentSubscription({
      newPlanId,
    });
  };

  if (!availablePlans) return null;

  return (
    <div>
      {availablePlans.map((plan) => (
        <Card key={plan.ExternalId}>
          <h2>{plan.Name}</h2>
          <div>
            {plan.Price.Amount} {plan.Price.Currency.toUpperCase()} /{' '}
            {plan.Price.Period.IntervalCount}&nbsp;
            {plan.Price.Period.Interval}
          </div>
          {!activePlanLevel && (
            <Button onClick={() => handleSubscribeClick(plan.ExternalId)}>Subscribe</Button>
          )}
          {activePlanLevel && activePlanLevel !== plan.Level && (
            <Button onClick={() => handleUpgradeSubscriptionClick(plan.ExternalId)}>Change</Button>
          )}
          {activePlanLevel === plan.Level && !activeSubscriptionData?.willBeCanceledTimestamp && (
            <Button onClick={() => handleCancelSubscriptionClick(plan.ExternalId)}>Cancel</Button>
          )}
          {activePlanLevel === plan.Level && activeSubscriptionData?.willBeCanceledTimestamp && (
            <Button onClick={() => handleRenewSubscriptionClick(plan.ExternalId)}>Renew</Button>
          )}
        </Card>
      ))}
    </div>
  );
};
