import { forwardRef, useMemo, FC } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LISTED_STATUS, transactionStatusesOptions } from 'settings/constants/transaction';
import { transactionRoles } from 'settings/constants/roles';

import styles from './styles.module.scss';

const List: FC<any> = forwardRef((props, ref: any) => {
  const { className, onChange, open, options } = props;

  if (!open) return null;

  return (
    <div testid="statuses_list" ref={ref} className={classNames(styles.listWrapper, className)}>
      <ul>
        {options.map((status) => (
          <li onClick={() => onChange(status)} key={status?.name} className={styles.item}>
            {status?.label}
          </li>
        ))}
      </ul>
    </div>
  );
});

// List.propTypes = {
//   className: PropTypes.string,
//   onChange: PropTypes.func,
//   open: PropTypes.bool,
//   representingRoles: PropTypes.arrayOf(PropTypes.string),
//   skipListed: PropTypes.bool,
// };

// List.defaultProps = {
//   className: '',
//   onChange: () => {},
//   open: false,
//   representingRoles: [],
//   skipListed: false,
// };

export default List;
