import { validate } from 'uuid';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';

import { getTransactionTaskDocuments } from 'api/transactions';

export type PDFCacheType = {
  documentBuffer?: ArrayBuffer;
  pdfDoc?: PDFDocument;
  url?: string;
  totalPages?: number;
};

const MAX_CACHE_FILES = 2;

export class PDFCache {
  static instance;

  private PDFCache: PDFCacheType[] = [];

  constructor() {
    if (!PDFCache.instance) {
      PDFCache.instance = this;
    }

    return PDFCache.instance;
  }

  private removeCache() {
    if (this.PDFCache.length >= MAX_CACHE_FILES) {
      this.PDFCache.shift();
    }
  }

  private async setDocuments(url, params?) {
    let buffer: ArrayBuffer | undefined;

    try {
      if (validate(url)) {
        const config = {
          DocumentVaultUUID: url,
          ...(params ? { params: params } : {}),
          responseType: 'arraybuffer',
        };

        const response = await getTransactionTaskDocuments(config);
        buffer = response?.data;
      } else {
        const response = await axios.get(url, {
          responseType: 'arraybuffer',
        });

        buffer = response.data;
      }
    } catch (e) {
      buffer = undefined;
    }

    if (buffer) {
      this.removeCache();

      const documentBuffer = buffer;
      const pdfDoc = buffer ? await PDFDocument.load(buffer) : undefined;

      this.PDFCache.push({
        documentBuffer,
        pdfDoc,
        url,
        totalPages: pdfDoc ? pdfDoc.getPageCount() : 0,
      });
    }
  }

  loadDocuments(url, params?) {
    const index = this.PDFCache.findIndex((cache) => cache.url === url);

    if (index === -1) {
      return this.setDocuments(url, params);
    }
  }

  getDocument(url) {
    const index = this.PDFCache.findIndex((cache) => cache.url === url);

    if (index > -1) {
      return this.PDFCache[index];
    } else {
      return {} as PDFCacheType;
    }
  }
}

export const PdfCache = new PDFCache();
