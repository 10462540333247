export const SOCKET_STATE = {
  CONNECTED: 'connected',
  ERROR: 'error',
  DISCONNECTED: 'disconnected',
  IDLE: 'idle',
};

export const SOCKET_EMIT_TYPES = {
  SEND_MESSAGE: 'sendMessage',
  NEW_MESSAGE: 'newMessage',
  GET_MESSAGES_FOR_THREAD: 'getMessagesForThread',
  MESSAGE_EDITED: 'messageEdited',
  MESSAGE_DELETED: 'messageDeleted',
  MESSAGE_REMOVED: 'messageRemoved',
  EDIT_MESSAGE: 'editMessage',
  DELETE_MESSAGE: 'deleteMessage',

  INIT_THREADS: 'initialThreads',
  NEW_THREAD: 'newThread',
  MARK_THREAD_AS_READ: 'markThreadAsRead',
  ARCHIVE_THREAD: 'archiveThread',
  THREAD_UPDATED: 'threadUpdated',
  CREATE_THREAD: 'createThread',
  UPDATE_THREAD: 'updateThread',
  REMOVED_FROM_THREAD: 'removedFromThread',

  THREAD_OPENED: 'threadOpened',
  THREADS_CLOSED: 'threadsClosed',
  USER_OFFLINE: 'userOffline',

  INITIAL_NOTIFICATIONS: 'initialNotifications',
  NEW_NOTIFICATION: 'newNotification',
  MESSAGE_READ_RECEIPT: 'messageReadReceipt',
  PARTICIPANT_ONLINE_STATUS: 'participantOnlineStatus',
  KIT_CONFIGURED: 'kitConfigured',

  MARK_MSG_AS_READ: 'markMsgAsRead',
};

export const SOCKET_THREAD_TYPES = {
  LISTING: 'Listing',
  TRANSACTION: 'Transaction',
  OFFER: 'Offer',
  PERSONAL: 'Client', // TODO fix it some day with the BE
  QUOTE: 'Quote',
  TRANSACTION_TASK: 'TransactionTask',
  CHAT: 'Chat',
};

export const SOCKET_MESSAGE_TYPES = {
  PROPERTY_COMMENT: 'PropertyComment',
  QOUTE_MESSAGE: 'QuoteMessage',
  CHAT_MESSAGE: 'ChatMessage',
};
