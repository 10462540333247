import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Subtitle = (props) => {
  const { className, children, noMargin } = props;

  if (!children) return null;

  return (
    <div
      testid="subtitle"
      className={classNames(styles.subtitle, className, noMargin && styles.subtitle_noMargin)}
    >
      {children}
    </div>
  );
};

Subtitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  noMargin: PropTypes.bool,
};

Subtitle.defaultProps = {
  className: '',
  children: null,
  noMargin: false,
};

export default Subtitle;
