import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { appOpenModalEffect } from 'store/effects/app';
import { getModalSelector } from 'store/selectors/app';
import { Answers, Modal } from 'components';
import NextButton from 'pages/OnBoardingWizard/components/NextButton';

import styles from './styles.module.scss';

const StylesModal = ({ className, onNext, value }) => {
  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();
  const [style, setStyle] = useState(value || null);

  useEffect(() => setStyle(value || []), [value]);

  if (modal.id !== StylesModal.id) {
    return null;
  }

  const onClose = () => {
    onNext(style);
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  const onNextHandler = () => {
    onClose();
  };

  return (
    <Modal
      testid="styles_modal"
      className={classNames(className)}
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      closeClassName={styles.close}
      innerHolderClassName={styles.innerHolder}
    >
      <h2 className={styles.title}>Which interior styles do you like? </h2>
      <Answers
        multiple
        unselectable
        className={styles.answersWrapper}
        caseClassName={styles.option}
        cases={StylesModal.answerCases}
        onChange={(val) => setStyle(val)}
        value={style}
        testid="interior_answers"
        variant={Answers.CHECKBOX}
      />
      <NextButton testid="styles_continue" onClick={onNextHandler} className={styles.next} />
    </Modal>
  );
};

StylesModal.id = 'interiorStylesModal';
StylesModal.answerCases = [
  { id: 'Traditional', label: 'Traditional', value: 'Traditional' },
  { id: 'Transitional', label: 'Transitional', value: 'Transitional' },
  { id: 'Loft', label: 'Loft', value: 'Loft' },
  { id: 'Modern', label: 'Modern', value: 'Modern' },
  { id: 'Rustic', label: 'Rustic', value: 'Rustic' },
  { id: 'Industrial', label: 'Industrial', value: 'Industrial' },
  { id: 'Bohemian', label: 'Bohemian', value: 'Bohemian' },
  { id: 'ModernFarmhouse', label: 'Modern Farmhouse', value: 'ModernFarmhouse' },
  { id: 'Mediterranean', label: 'Mediterranean', value: 'Mediterranean' },
  { id: 'Spanish', label: 'Spanish', value: 'Spanish' },
  { id: 'MidcenturyModern', label: 'Midcentury Modern', value: 'MidcenturyModern' },
];

StylesModal.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
};

StylesModal.defaultProps = {
  className: '',
  onNext: () => {},
  value: [],
};

export default StylesModal;
