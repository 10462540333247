import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from 'components';
import { Close } from 'components/Icons';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';

import styles from './styles.module.scss';

const BlockValues = (props) => {
  const { className, participants, onDelete, valueClassName, testid } = props;

  return (
    <div testid={testid} className={classNames(styles.values, className)}>
      {participants.map((participant) => (
        <div
          testid="participant"
          key={participant.id}
          className={classNames(styles.value, valueClassName)}
        >
          <div className={styles.inner}>
            <Avatar
              avatarClassName={styles.avatar}
              src={participant?.avatarUrl}
              placeholder={convertNameToAvatarPlaceholder(
                `${participant?.firstName} ${participant?.lastName}`,
              )}
            />
            <div testid="participant_name" className={styles.name}>
              {participant.name}
            </div>
          </div>
          {participants?.length > 1 && (
            <Close onClick={() => onDelete(participant.id)} className={styles.close} />
          )}
        </div>
      ))}
    </div>
  );
};

BlockValues.propTypes = {
  className: PropTypes.string,
  valueClassName: PropTypes.string,
  testid: PropTypes.string,
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  onDelete: PropTypes.func,
};

BlockValues.defaultProps = {
  className: '',
  valueClassName: '',
  testid: undefined,
  onDelete: () => {},
};

export default BlockValues;
