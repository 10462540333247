import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { getAvailableDayAndTime, getBookedDayAndTime } from './helper';

const localState = ({ showingAppointment }) => showingAppointment;

export const getShowingDetails = createSelector(localState, (currentState) => {
  const showingDetails = currentState?.showingDetails?.data;
  return {
    ...showingDetails,
    AgentBrokerage: showingDetails?.Agent?.Brokerage,
    isIdle: currentState?.state === IDLE,
    isPending: currentState?.state === PENDING,
    isData: !!currentState?.showingDetails,
  };
});

export const getShowingAvailability = createSelector(localState, (currentState) => {
  const availability = currentState?.showingAvailability?.data?.availability;
  const booked = currentState?.showingAvailability?.data?.booked;
  const dayTimeAvailable = getAvailableDayAndTime(availability);
  const dayTimeBooked = getBookedDayAndTime(booked);
  return {
    dayTimeAvailable,
    dayTimeBooked,
    isIdle: currentState?.state === IDLE,
    isPending: currentState?.state === PENDING,
    isData: !!currentState?.showingAvailability,
  };
});

export const getShowingAppointmentDetails = createSelector(localState, (currentState) => {
  const showingAppointment = currentState?.showingAppointment?.data;
  return {
    ...showingAppointment,
    isIdle: currentState?.state === IDLE,
    isPending: currentState?.state === PENDING,
    isData: !!currentState?.showingAppointment,
  };
});
