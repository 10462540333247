import React from 'react';

const SandClock = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="#F4F6FB" />
    <g opacity="0.5">
      <path
        d="M28 32C28 28 22 28 22 18H42C42 28 36 28 36 32C36 36 42 36 42 46H22C22 36 28 36 28 32Z"
        stroke="#252D44"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 18H44"
        stroke="#252D44"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 46H44"
        stroke="#252D44"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M32 36C31 36 28 39 28 42H36C36 39 33 36 32 36Z" fill="#252D44" />
      <path d="M32 28C31.5 28 30 27 30 26H34C34 27 32.5 28 32 28Z" fill="#252D44" />
    </g>
  </svg>
);

export default SandClock;
