import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import classNames from 'classnames';

import { ArrowDownFull } from 'components/Icons';
import { Icons } from 'pages/Quotes/Icons';
import styles from './styles.module.scss';

interface DataType {
  id: number;
  key: React.Key;
  client: string;
  transaction: string;
  status: string;
  tasks: string;
  totalTasks?: number;
  tasksAmount: number;
  closingDate: string;
  data: any;
  overdueTasksCount: number;
  imageUrl: string;
  imagePlaceholder: string;
  address: string;
  count: number;
  title: string;
  name: string;
  newCount: number;
  archiveCount: number;
  icon: string;
}

export function tableColumns(userRole: string): ColumnsType<DataType> {
  const columns: ColumnsType<DataType> = [
    {
      title: 'Status',
      key: 'name',
      dataIndex: 'name',
      render: (_, row) => {
        return (
          <div className={styles.status}>
            <Icons variant={row?.icon} className={styles.icon} />
            <div className={styles.info}>
              <span className={styles.line1}>{row?.name}</span>
            </div>
          </div>
        );
      },
      sorter: (a, b) => a.name?.localeCompare(b.name),
    },
    {
      title: 'Quotes',
      key: 'count',
      dataIndex: 'count',
      sorter: (a, b) => a.count - b.count,
      render: (_, row) => {
        return (
          <div>
            <span>
              {row?.count}{' '}
              {row?.newCount > 0 && (
                <span className={styles.newCount}>{`(${row?.newCount} new)`}</span>
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Archive',
      key: 'archiveCount',
      dataIndex: 'archiveCount',
      sorter: (a, b) => a.archiveCount - b.archiveCount,
      render: (archiveCount) => <span>{archiveCount}</span>,
    },
  ];

  return columns;
}
