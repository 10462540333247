import React, { memo, useCallback } from 'react';
import { prefsIds } from 'settings/constants/preferences';
import PropTypes from 'prop-types';

const Icon = memo(({ className, variant }) => {
  const getIcon = useCallback(() => {
    switch (variant) {
      case Icon.ELEVATOR:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 29V11H7V29"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 5C2 3.89543 2.89543 3 4 3H28C29.1046 3 30 3.89543 30 5V27C30 28.1046 29.1046 29 28 29H4C2.89543 29 2 28.1046 2 27V5Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.5 16.5L25 15L26.5 16.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.5 21.5L25 23L26.5 21.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 11V28.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.GYM:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11 14H21" stroke="#AAABAB" strokeWidth="2" />
            <path d="M11 18H21" stroke="#AAABAB" strokeWidth="2" />
            <path
              d="M26 10.5V9C26 8.44772 25.5523 8 25 8H22C21.4477 8 21 8.44772 21 9V23C21 23.5523 21.4477 24 22 24H25C25.5523 24 26 23.5523 26 23V21.5M26 10.5H29C29.5523 10.5 30 10.9477 30 11.5V20.5C30 21.0523 29.5523 21.5 29 21.5H26M26 10.5V21.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 10.5V9C6 8.44772 6.44772 8 7 8H10C10.5523 8 11 8.44772 11 9V23C11 23.5523 10.5523 24 10 24H7C6.44772 24 6 23.5523 6 23V21.5M6 10.5H3C2.44772 10.5 2 10.9477 2 11.5V20.5C2 21.0523 2.44772 21.5 3 21.5H6M6 10.5V21.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.ROOFTOP:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.5 16C3.5 16 4 21.5 4 27"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M9.99982 27L10 22H4"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M28.5 16C28.5 16 28 21.5 28 27"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M22.0002 27L22 22H28"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 11H5V9L16 4L27 9V11H16ZM16 11V27"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5 18H20.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.DOORMAN:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 19L5.65836 22.1708C4.64201 22.679 4 23.7178 4 24.8541V29H9H15M20 19L26.3416 22.1708C27.358 22.679 28 23.7178 28 24.8541V29H23H15M15 29V20.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.8619 10.4996C21.9524 10.9828 22 11.4847 22 11.9996C22 15.8656 19.3137 18.9996 16 18.9996C12.6863 18.9996 10 15.8656 10 11.9996C10 11.4144 10.0615 10.846 10.1775 10.3027"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <path
              d="M10 4.89424C10 4.38529 10.3825 3.95679 10.8887 3.90423C14.7913 3.49903 17.2952 3.49775 21.1138 3.90232C21.6189 3.95583 22 4.38388 22 4.89177V10.0006C17.3174 9.36523 14.6753 9.35314 10 10.0006V4.89424Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M10 10C13.5 13.5 18.5 13.5 22 10" stroke="#AAABAB" strokeWidth="2" />
            <path
              d="M9 26V29"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23 26V29"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.DRY_CLEANING:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99988 21.9999H2.83929C1.80875 21.9999 1.45186 20.6297 2.35147 20.127L15.0242 13.0451C15.6306 12.7063 16.3692 12.7063 16.9755 13.0451L29.6483 20.127C30.5479 20.6297 30.191 21.9999 29.1605 21.9999H23.9999"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              x="8"
              y="19"
              width="16"
              height="11"
              rx="1"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.4248 5.46102C13.694 5.01063 14.0766 4.6387 14.5344 4.38237C14.9922 4.12604 15.5092 3.99426 16.0339 4.00019C16.5585 4.00612 17.0725 4.14955 17.5244 4.41617C17.9763 4.68278 18.3504 5.06326 18.6093 5.51962C18.8682 5.97598 19.0029 6.49227 18.9999 7.01695C18.997 7.54164 18.8564 8.05637 18.5924 8.50978C18.3283 8.96318 17.95 9.33941 17.4951 9.6009C17.0402 9.86239 16.5247 10 16 10V12"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.ON_SITE_MANAGEMENT:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 18.5L6.56344 22.1472C5.59966 22.6729 5 23.6831 5 24.7809V29H27V24.7809C27 23.6831 26.4003 22.6729 25.4366 22.1472L19 18.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 28V25C10 23.8954 10.8954 23 12 23H20C21.1046 23 22 23.8954 22 25V28"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M10.0608 11C10.0207 11.3266 10 11.6605 10 12C10 15.866 12.6863 19 16 19C19.3137 19 22 15.866 22 12C22 11.6605 21.9793 11.3266 21.9392 11"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <path d="M11 23V20" stroke="#AAABAB" strokeWidth="2" />
            <path d="M21 23V20" stroke="#AAABAB" strokeWidth="2" />
            <path
              d="M16.0001 3C12.844 3 10.2455 5.39831 9.91143 8.47995C9.88308 8.7414 9.841 9.00234 9.77192 9.25503C9.67736 9.60098 9.45425 9.89169 9.19691 10.1415L9.11303 10.2229C9.07014 10.2645 9.03248 10.3129 9.01676 10.3706C8.93262 10.6793 9.16383 11 9.5 11H22.5C22.8362 11 23.0674 10.6793 22.9832 10.3705C22.9675 10.3129 22.9299 10.2645 22.887 10.2229L22.803 10.1413C22.5459 9.89164 22.323 9.60109 22.2284 9.25537C22.1593 9.00257 22.1172 8.74151 22.0888 8.47995C21.7547 5.39831 19.1562 3 16.0001 3Z"
              stroke="#AAABAB"
              strokeWidth="2"
            />
          </svg>
        );
      case Icon.PACKAGE_RECEIVING:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 26H5V13H19M19 26V13M19 26H27V13H19"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 13L1 8H15L19 13L23 8H31L27 13"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.DOG_RUN:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 26.9998C3 26.9998 4.6 25.3998 5 24.9998C5.3426 24.6572 5.2157 17.9764 7.03206 12.9998M19.5 30.4998C19.5 30.4998 17.5 26.9998 17.5 25.4999C17.5 23.9999 18.5 21.4998 19.5 20.4998C20.5 19.4998 27 19.9998 28 18.4998C28.6202 17.5696 30.75 12.9998 30 11.4998C29.5 10.4999 24.1667 10.3332 21.5 10.4999C20.6047 7.3662 16.7435 6.23656 12.5 7.46976"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M5.5 22C9 25 13 25.5 17 25.5" stroke="#AAABAB" strokeWidth="2" />
            <path
              d="M11.4405 15.1207C13.1156 14.4545 12.8226 6.21346 11.4593 5.41252C10.0961 4.61158 5.24787 8.18158 4.936 9.64881C4.62413 11.116 10.4016 15.5339 11.4405 15.1207Z"
              stroke="#AAABAB"
              strokeWidth="2"
            />
          </svg>
        );
      case Icon.GATED_COMMUNITY:
        return (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 19V40"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 18L3 40"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 18V40"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 13V40"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 11L22 40"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26 11L26 40"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M31 13V40"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M36 18V40"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M41 19V40"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M45 18V40"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 19C13.25 19 15.75 11 22 11"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M41 19C34.75 19 32.25 11 26 11"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path d="M3 40L22 40" stroke="#515151" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M3 18L7 18" stroke="#515151" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M41 18L45 18" stroke="#515151" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M26 40L45 40" stroke="#515151" strokeWidth="2.5" strokeLinecap="round" />
            <circle cx="43" cy="12.5" r="2.5" stroke="#515151" strokeWidth="2.5" />
            <circle cx="5" cy="12.5" r="2.5" stroke="#515151" strokeWidth="2.5" />
          </svg>
        );
      case Icon.COMMUNITY_POOL:
        return (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 37.2117C4.92661 37.8953 5.57957 40 9.6 40C14.4 40 14.4 37 19.2 37C24 37 24 40 28.8 40C33.6 40 33.6 37 38.4 37C42.4204 37 43.0734 39.1047 46 39.7883"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M2 30.2117C4.92661 30.8953 5.57957 33 9.6 33C14.4 33 14.4 30 19.2 30C24 30 24 33 28.8 33C33.6 33 33.6 30 38.4 30C42.4204 30 43.0734 32.1047 46 32.7883"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <circle cx="38" cy="17.2812" r="4.5" stroke="#515151" strokeWidth="2.5" />
            <path
              d="M31 20.9286L32 26.1905C31.4667 26.5952 30.4 27 28.8 27C24.8 27 24.4 23.7619 19.2 23.7619C14.4 23.7619 12.8 27 10.4 27L22 18.9048L19.2 15.2619L11.1743 16.8237C9.87152 17.0772 8.6151 16.2099 8.3903 14.9019C8.17309 13.638 9.00215 12.4312 10.2598 12.1805L20.0815 10.2229C20.7748 10.0848 21.4895 10.3228 21.9614 10.8491L31 20.9286Z"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.PLAYGROUND:
        return (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 44V5.5C4 4.11929 5.11929 3 6.5 3V3C7.88071 3 9 4.11929 9 5.5V10"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M9 10L26.9719 40.5523C28.2285 42.6884 30.5217 44 33 44V44"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M22 10L39.9719 40.5523C41.2285 42.6884 43.5217 44 46 44V44"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path d="M4 10L22 10" stroke="#515151" strokeWidth="2.5" strokeLinecap="round" />
            <path
              d="M17 10V5.5C17 4.11929 18.1193 3 19.5 3V3C20.8807 3 22 4.11929 22 5.5V10"
              stroke="#515151"
              strokeWidth="2.5"
            />
            <path d="M17 24L17 44" stroke="#515151" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M4 40H17" stroke="#515151" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M4 34H17" stroke="#515151" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M4 28H17" stroke="#515151" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M4 22H16" stroke="#515151" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M4 16H12" stroke="#515151" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M33 44H46" stroke="#515151" strokeWidth="2.5" strokeLinecap="round" />
          </svg>
        );
      default:
        return null;
    }
  }, [variant]);

  return <div className={className}>{getIcon()}</div>;
});

Icon.ELEVATOR = prefsIds.elevator;
Icon.GYM = prefsIds.gym;
Icon.PACKAGE_RECEIVING = prefsIds.packageReceiving;
Icon.ROOFTOP = prefsIds.rooftop;
Icon.DOORMAN = prefsIds.doorman;
Icon.DOG_RUN = prefsIds.dogRun;
Icon.DRY_CLEANING = prefsIds.dryCleaning;
Icon.ON_SITE_MANAGEMENT = prefsIds.onSiteManagement;
Icon.GATED_COMMUNITY = prefsIds.gatedCommunity;
Icon.COMMUNITY_POOL = prefsIds.communityPool;
Icon.PLAYGROUND = prefsIds.playground;

Icon.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  variant: undefined,
};

export default Icon;
