import { getLocationContext, getPlaceComponent } from 'helpers/locations';
import { LOCALITY, POSTAL_CODE, ROUTE, STATE, STREET_NUMBER } from 'settings/constants/locations';
import { Avatar } from 'components-antd';
import { Locations } from 'components';
import styles from './styles.module.scss';
import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { addClientAddress } from 'api/transactions';
import { useParams } from 'react-router-dom';

type ClientAddressContainerPropsType = {
  ClientId: number;
  FName: string;
  LName: string | undefined;
  AvatarUrl: string | undefined | null;
  ClientAddress: any;
  Update: boolean;
  setCount: Function;
  latestClientAddressAdder: Function;
};

export const ClientAddressContainer = (props: ClientAddressContainerPropsType) => {
  const [primary, setPrimary] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);
  const [address, setAddress] = useState<any>(props.ClientAddress);
  const [addressParsed, setAddressParsed] = useState<any>(undefined);
  const params: { id?: string } = useParams();

  const formatAddress = (address) =>
    `${address.Line1}, ${address.City}, ${address.State} ${address.Zip}`;

  const updateClientAddress = () => {
    let parsed = addressParsed;
    if (!parsed) {
      parsed = {
        City: address.City,
        State: address.State,
        Zip: address.Zip,
        Line1: address.Line1,
        ProviderPlaceId: address.ProviderPlaceId,
        PlaceName: address?.placeName ? address?.placeName : undefined,
        Street: address?.Street ? address?.Street : undefined,
      };
    }
    const requestObj = {
      id: params?.id,
      address: formatAddress(address),
      placeId: address.ProviderPlaceId,
      clientsStatus: [{ id: props.ClientId, isPrimary: primary }],
      addressParsed: parsed,
    };
    props.latestClientAddressAdder(props.ClientId, formatAddress(address));
    addClientAddress(requestObj);
  };

  useEffect(() => {
    if (props.Update && !updated) {
      props.setCount();
      setUpdated(true);
      updateClientAddress();
    }
  }, [props.Update]);

  const onAddressChange = (result, preparedData) => {
    const location = result?.result?.[0];

    if (location) {
      const updatedCity = getLocationContext(location, LOCALITY);
      const updatedState = getLocationContext(location, STATE);
      const updatedZip = getLocationContext(location, POSTAL_CODE);
      const street = getPlaceComponent(location, ROUTE);
      const streetNumber = getLocationContext(location, STREET_NUMBER);
      const newAddress = {
        City: updatedCity,
        State: updatedState,
        Zip: updatedZip,
        Line1: [streetNumber, street?.name].filter((i) => !!i).join(' '),
        ProviderPlaceId: location.place_id,
        PlaceName: result?.placeName,
        Street: preparedData?.length && preparedData[0]?.Street ? preparedData[0].Street : '',
      };
      setAddress(newAddress);
      setAddressParsed(newAddress);
    }
  };

  return (
    <div className={styles.addressContainer}>
      <div className={styles.addressContainerHeader}>
        <Avatar
          className={''}
          size={'default'}
          src={props.AvatarUrl}
          alt={props.FName}
          shape={'circle'}
        >
          {props.FName[0] + (props.LName ? props.LName[0] : '')}
        </Avatar>
        <div className={styles.addressContainerHeaderName}>
          {props.FName + (props.LName ? ' ' + props.LName : '')}
        </div>
      </div>
      <div className={styles.addressContainerLocation}>
        <Locations
          name="listingAddress"
          onResult={(r, preparedData) => {
            onAddressChange(r, preparedData);
          }}
          placeholder="Enter Address"
          activeInputClassName={styles.locationInput}
          testid="listing_address"
          value={address ? [address] : []}
          valuesWrapperClassName={styles.valuesWrapper}
          multiple={false}
          showPrefixIcon={true}
          activeInputIconClassName={styles.locationsIcon}
          showAsterisk={true}
          variant={Locations.LIGHT}
        />
      </div>
      <Checkbox
        type="checkbox"
        name="PrimaryAddressCheckbox"
        className={'mosaikCheckbox'}
        onChange={(e) => {
          setPrimary(!primary);
        }}
        checked={primary}
      >
        Set as primary address
      </Checkbox>
    </div>
  );
};
