import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UsersMessage, Avatar } from 'components';
import { ArrowRight, ListingHome } from 'components/Icons';

import styles from './styles.module.scss';
import { requestGetServicesCategoriesEffect } from 'store/effects/servicesCategories';
import { useDispatch, useSelector } from 'react-redux';
import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import { convertNameToAvatarPlaceholder } from 'helpers';

const ThreadMessages = (props) => {
  const {
    topicName,
    unreadNumber,
    thread,
    archived,
    isThirdPartyThread,
    thirdPartyCategory,
    ...rest
  } = props;
  const categories = useSelector(getAllServicesCategoriesList);
  const categoryData = (categories || []).find((c) => c?.Title === thirdPartyCategory);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!categoryData) {
      dispatch(requestGetServicesCategoriesEffect());
    }
  }, [dispatch, thirdPartyCategory]);

  return !isThirdPartyThread ? (
    <UsersMessage namesTitle={thread.Participants.length > 1} thread={thread} {...rest} />
  ) : (
    <div
      testid="thread_item"
      className={classNames(styles.item, { [styles.unread]: !!unreadNumber })}
      {...rest}
    >
      <div className={styles.itemImage}>
        <Avatar
          src={categoryData?.Icon}
          avatarClassName={styles.itemImageAvatar}
          placeholder={convertNameToAvatarPlaceholder(thirdPartyCategory)}
        />
      </div>
      <div className={styles.itemContent}>
        <div className={styles.itemContentTitle}>
          <div className={styles.itemContentTitleName} testid="item_title">
            {thirdPartyCategory}
          </div>
        </div>
        <div testid="item_subtitle" className={styles.itemContentText}>
          <span>{`${thread.length} ${thread.length > 1 ? 'quotes' : 'quote'}`}</span>
          {!!unreadNumber && (
            <span className={styles.itemContentTextUnread}>{`, ${unreadNumber} unread`}</span>
          )}
        </div>
      </div>
      <div className={styles.itemRight}>
        <ArrowRight className={styles.itemRightIcon} />
      </div>
    </div>
  );
};

ThreadMessages.propTypes = {
  topicName: PropTypes.string,
  thread: PropTypes.oneOfType([
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.number.isRequired,
      }),
    ),
  ]).isRequired,
  unreadNumber: PropTypes.number.isRequired,
  archived: PropTypes.bool,
  isThirdPartyThread: PropTypes.bool,
  thirdPartyCategory: PropTypes.string,
};

ThreadMessages.defaultProps = {
  archived: false,
  topicName: '',
  isThirdPartyThread: false,
  thirdPartyCategory: '',
};

export default ThreadMessages;
