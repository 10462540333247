import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { Input } from 'components';
import { Question } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import Answers from '../../Answers';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Continue } from '../../Continue';

import styles from './styles.module.scss';

const YearBuilt = (props) => {
  const { className, onNext, currentQuestionId } = props;
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const yearBuilt = preForm?.[transactionPreFormQuestionsIds.yearBuilt];
  const dispatch = useDispatch();

  const onNextHandler = (event) => {
    if (currentQuestionId === transactionPreFormQuestionsIds.yearBuilt) {
      onNext(event);
    }
  };

  const onAnswerHandler = (year) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.yearBuilt]: year,
      }),
    );
  };

  return (
    <div testid="what_property_type" className={classNames(styles.container, className)}>
      <Question>What year was it built?</Question>
      <AnswersContainer>
        <Answers className={styles.answer}>
          <Input
            isNumberFormat
            thousandSeparator={false}
            variant={Input.LIGHT_ROUNDED}
            placeholder="Year (i.e. 2010)"
            onChange={(e, val) => onAnswerHandler(val)}
            value={yearBuilt}
            testid="year_input"
            minNumber={0}
            maxNumber={new Date().getFullYear()}
            className={styles.input}
          />
        </Answers>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onNextHandler} />
      </ButtonsContainer>
    </div>
  );
};

YearBuilt.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  currentQuestionId: PropTypes.string,
};

YearBuilt.defaultProps = {
  className: '',
  onNext: () => {},
  currentQuestionId: '',
};

export default YearBuilt;
