import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, ConfirmationWithReasonModal, Menu, Popover, Row, Select } from 'components-antd';
import moment from 'moment';
import classNames from 'classnames';

import { announcementType } from 'settings/constants/offer';
import { Icons } from '../../TransactionShowings/Icons';
import { ArchiveOffers } from '../ArchiveOffers';
import { MegaPhoneModal } from '../MegaPhoneModal';
import { Highlights } from './components/Highlights';
import { Documents } from './components/Documents';
import { updateOfferIterationEffect } from 'store/effects';
import { offerDetails } from 'api/offers';
import { HistoryLogs } from 'components/HistoryLogs';
import { getTimeZone, limitTextLength } from 'helpers';
import { OfferStatus } from 'types';
import { FormattedPhone } from 'components';

import styles from './styles.module.scss';
import { Check } from 'components/Icons';

interface TransactionAcceptedOfferProps {
  transactionId: number;
  Offer?: any;
  isData?: boolean;
  setIsAcceptedOffer?: any;
}

export const TransactionAcceptedOffer = ({
  transactionId,
  Offer,
  isData,
  setIsAcceptedOffer,
}: TransactionAcceptedOfferProps) => {
  const [megPhoneModal, setMegaPhoneModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [ShowChangeModal, setShowChangeModal] = useState(false);
  const [Reason, setConfirmationReason] = useState();
  const dispatch = useDispatch();
  const [offerIterations, setOfferIterations]: any = useState([]);
  const [offerIteration, setOfferIteration]: any = useState(undefined);
  const [offerData, setOfferData]: any = useState(undefined);
  const [offerStatus, setOfferStatus] = useState(null);

  const fetchOfferDetails = useCallback(async (UUID) => {
    setIsLoading(true);
    const { data } = await offerDetails({ UUID });
    setOfferIterations(data?.value?.OfferIteration);
    setOfferStatus(offerIteration?.IterationStatus);
    setOfferData(data?.value);
    setIsLoading(false);
  }, []);
  const fetchAcceptedOffer = () => {
    setOfferIteration(offerIterations?.[offerIterations.length - 1]);
  };
  const verify = (iteration) => {
    return iteration?.UUID === offerIteration?.UUID;
  };
  useEffect(() => {
    fetchOfferDetails(Offer?.UUID);
    setOfferIteration({ ...Offer?.OfferIteration?.[0], BuyingAgentName: Offer.BuyingAgentName });
  }, []);

  const handlePreFormUpdate = (offerId, offerIterationStatus) => {
    dispatch(
      updateOfferIterationEffect(
        {
          OfferIterationId: offerId,
          OfferStatus: offerIterationStatus,
          Reason: Reason,
          TransactionId: transactionId,
        },
        {},
        (err, res) => {
          if (res?.data?.statusCode == 200 && res?.data?.value) {
            setIsAcceptedOffer(false);
          }
        },
      ),
    );
  };
  const renderiteration = (iteration, index) => {
    if (!iteration?.CounterParentId) {
      return (
        <span
          onClick={() => {
            setOfferIteration(iteration);
          }}
          className={classNames(styles.iterations, {
            [styles.activeIteration]: verify(iteration),
          })}
        >
          Initial Offer {iteration.IterationCount < offerIterations.length - 1 ? ' > ' : ''}
        </span>
      );
    }
    let counterType = `Counter #${iteration?.IterationCount} (${
      iteration.PerformedBy.Email
        ? iteration?.PerformedBy?.Email === offerIteration?.BuyingAgentEmail
          ? 'Inbound'
          : 'Outbound'
        : 'Inbound'
    })`;

    if (index !== offerIterations.length - 1) {
      counterType = counterType + ' > ';
    }
    return (
      <span
        onClick={() => {
          setOfferIteration(iteration);
        }}
        className={classNames(styles.iterations, { [styles.activeIteration]: verify(iteration) })}
      >
        {counterType}
      </span>
    );
  };
  const handleMenuClick = (value: string) => {
    if (value === OfferStatus.Cancelled) {
      setShowChangeModal(true);
    }
  };

  const renderActions = () => {
    return (
      <div className={styles.actions}>
        <>
          {offerIteration?.IterationStatus === OfferStatus.Accepted ? (
            <>
              <Select
                getPopupContainer={(node) => node}
                defaultValue={offerIteration?.IterationStatus}
                className={styles.offerSwitcher}
                popupClassName={styles.offerSwitcherDropdown}
                onChange={handleMenuClick}
                dropdownIconColor="#04a451"
                placement="bottomRight"
                menuItemSelectedIcon={<Check className={styles.selectedIcon} />}
                options={[
                  {
                    label: (
                      <div className={styles.optionItem}>
                        <span className={classNames(styles.accepted, styles.offerLabel)}>
                          {OfferStatus.Accepted}
                        </span>
                      </div>
                    ),
                    value: OfferStatus.Accepted,
                  },
                  {
                    label: (
                      <div className={styles.optionItem}>
                        <span className={classNames(styles.canceled, styles.offerLabel)}>
                          {OfferStatus.Cancelled}
                        </span>
                      </div>
                    ),
                    value: OfferStatus.Cancelled,
                  },
                ]}
              >
                <button className={styles.Accepted}> Accepted</button>
              </Select>
            </>
          ) : (
            <button className={styles.JumpToFirst} onClick={fetchAcceptedOffer}>
              Jump to first
            </button>
          )}
          <Icons
            variant={Icons.MEGA_PHONE}
            className={styles.actionBtn}
            onClick={() => setMegaPhoneModal(true)}
          />
        </>
      </div>
    );
  };

  const renderSubmittedOn = (iteration) => {
    const date =
      iteration?.IterationStatus === 'Accepted' ? iteration?.AcceptedOn : iteration?.CreatedDate;
    const status = iteration?.IterationStatus === 'Accepted' ? 'Accepted Date:' : 'Submitted Date';
    return (
      <>
        <p className={styles.subheadings}>{status}</p>
        <p>{moment(date).format('MM/DD/YYYY')}</p>
        <p>{`${moment(date).format('LT')} (${getTimeZone()})`}</p>
      </>
    );
  };

  const renderSubmittedBy = (iteration, offer) => {
    return (
      <>
        {iteration?.SubmittedBy ? (
          <>
            <p>
              {limitTextLength(
                iteration?.SubmittedBy?.FirstName + ' ' + iteration?.SubmittedBy?.LastName,
                20,
              )}
            </p>
            <p>Mosaik Brokerage Inc</p>
            <p>
              <FormattedPhone>{iteration?.SubmittedBy?.Phones?.[0]?.PhoneNumber}</FormattedPhone>
            </p>

            <div className={styles.ellipsedContainer}>
              <a>{iteration?.SubmittedBy?.Email}</a>
            </div>
          </>
        ) : (
          <>
            <p>{limitTextLength(offer?.BuyingAgentName, 20)}</p>
            <p>{limitTextLength(offer?.BuyingAgentBrokerage, 20)}</p>
            <p>
              <FormattedPhone>{offer?.BuyingAgentPhone}</FormattedPhone>
            </p>
            <div className={styles.ellipsedContainer}>
              <a>{offer?.BuyingAgentEmail}</a>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {!ShowChangeModal && offerIteration && (
        <div className={styles.offers}>
          <p>
            {offerIterations?.length ? [...offerIterations].reverse().map(renderiteration) : <></>}
          </p>
          <div className={styles.titleArea}>
            <h2 className={styles.pageTitle}>
              {offerIteration?.IterationCount > 0 ? `Counter Offer #` : `Offer #`}
              {offerData?.OfferCount
                ? `${offerData?.OfferCount} ${
                    offerIteration?.IterationCount > 0 ? `- ${offerIteration?.IterationCount}` : ''
                  }`
                : ''}
            </h2>
            {renderActions()}
          </div>
          <Row>
            <Col span={10}>
              <Row>
                <Col span={12}>
                  <div className={styles.subHeadingArea}>{renderSubmittedOn(offerIteration)}</div>
                </Col>
                <Col span={12}>
                  <div className={styles.subHeadingArea}>
                    <p className={styles.subheadings}>Submitted By:</p>
                    {renderSubmittedBy(offerIteration, offerData)}
                  </div>
                </Col>
              </Row>
              <Documents iterationData={offerIteration} offerData={offerData} />
              {Offer?.Id && (
                <HistoryLogs
                  query={{ OfferId: +Offer?.Id }}
                  className={styles.acceptedOfferHistory}
                  BuyingAgentName={`${offerData?.BuyingAgentName} ${offerData?.BuyingAgentBrokerage}`}
                />
              )}
            </Col>
            <Col span={2}></Col>
            <Col span={10}>
              <Highlights data={{ ...offerIteration, OfferCount: offerData?.OfferCount }} />
            </Col>
          </Row>
        </div>
      )}
      <ConfirmationWithReasonModal
        open={ShowChangeModal}
        title={<span>Are you sure you want to change status to Cancel?</span>}
        confirmText={<span></span>}
        okText={'Change'}
        onCancel={() => setShowChangeModal(false)}
        placeholder={`Add a note......`}
        note="Note"
        showReason={true}
        setReason={setConfirmationReason}
        onOk={() => handlePreFormUpdate(offerIteration?.UUID.toString(), 'Cancelled')}
        cancelText={'Cancel'}
      />
      <MegaPhoneModal
        isOpen={megPhoneModal}
        onClose={() => setMegaPhoneModal(false)}
        type={announcementType.PUBLIC}
        offerLinkId={Offer?.OfferLinkId}
      />
    </>
  );
};
