import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { LocationService } from 'services';
import { getUserRoleSelector } from 'store/selectors/user';
import { quotesStatusesMap, quoteRequestStatus } from 'settings/constants/quotes';
import { useHistory } from 'react-router-dom';
import { link } from 'settings/navigation/link';
import { resumeDraftQuoteEffect, setPreFormQuestionsEffect } from 'store/effects/quotes';
import { extractDraftQuoteId } from 'helpers';
import { preFormQuestionsIds } from 'settings/constants/preForm';
import QuoteDetails from '../../../../QuoteDetails';
import { PartnerProfile } from 'pages/ServicesCategoryPartner/components/PartnerProfile';
import { THIRD_PARTY } from 'settings/constants/roles';

import styles from './styles.module.scss';
import { QuoteDetailModal } from 'pages/Quotes/QuoteDetailModal';

const locationSrv = new LocationService();

const Details = (props) => {
  const { Id, Category, Partners, Client, Property, status, className, Partner, onClose } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const userRole = useSelector(getUserRoleSelector);
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();
  useEffect(() => {
    if (query?.quoteId === Id) {
      userRole === THIRD_PARTY ? setShowQuoteModal(true) : setShowRequestModal(true);
      history.replace(locationSrv.setQuery({ ...query, quoteId: undefined }));
    }
  }, [query?.quoteId]); // eslint-disable-line

  useEffect(() => {
    if (
      userRole !== THIRD_PARTY &&
      status === quotesStatusesMap(userRole)[quoteRequestStatus.Draft]
    ) {
      onResume();
    }
    if (Id) {
      setShowQuoteModal(true);
      setShowRequestModal(true);
    }

    return () => {
      setShowQuoteModal(false);
      setShowRequestModal(false);
    };
  }, [Id]);
  const onResume = () => {
    dispatch(
      resumeDraftQuoteEffect({ id: extractDraftQuoteId(Id) }, {}, (err) => {
        if (!err) {
          dispatch(
            setPreFormQuestionsEffect({
              [preFormQuestionsIds.isProperty]: !!Property?.Address,
              [preFormQuestionsIds.isClient]: !!Client?.Id,
              [preFormQuestionsIds.property]: Property
                ? {
                    address: {
                      ...Property?.Address,
                      PlaceName: Property?.Address?.Line1,
                    },
                  }
                : null,
              [preFormQuestionsIds.client]: Client,
            }),
          );
          history.push(
            link.toServicesCategoryPartnerQuote({
              categoryId: Category?.Id,
              partnerId: Partners.map((partner) => partner?.Id).join(','),
            }),
          );
        }
      }),
    );
  };

  const getButton = () => {
    if (userRole === THIRD_PARTY) {
      return (
        <>
          <QuoteDetailModal
            partnerId={null}
            requestQuoteId={Id}
            setModalVisible={setShowRequestModal}
            onCancel={() => {
              onClose();
              setShowRequestModal(false);
            }}
            open={showRequestModal}
            destroyOnClose={true}
          />
        </>
      );
    }

    if (status !== quotesStatusesMap(userRole)[quoteRequestStatus.Draft]) {
      return (
        <>
          <QuoteDetailModal
            partnerId={Partner?.Id}
            requestQuoteId={Id}
            setModalVisible={setShowRequestModal}
            onCancel={() => {
              onClose();
              setShowRequestModal(false);
            }}
            open={showRequestModal}
            destroyOnClose={true}
          />
        </>
      );
    }

    return null;
  };

  return (
    <div testid="action_button" className={classNames(styles.details, className)}>
      {getButton()}
    </div>
  );
};

Details.propTypes = {
  Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  Partners: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Category: PropTypes.shape({
        Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
  ),
  Category: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
  Client: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  Property: PropTypes.shape({
    Address: PropTypes.shape({
      Line1: PropTypes.string,
      onClose: PropTypes.func,
    }),
  }),
};

Details.defaultProps = {
  className: '',
  Partners: [],
  Client: null,
  Property: null,
  onClose: () => {},
};

export default Details;
