import { apiServer } from 'settings/web-services/api';

export function getAllFormTemplates(cfg) {
  return apiServer.get('/transaction/templates/form-templates', { params: cfg });
}

export function getFormTemplateById({ id }) {
  return apiServer.get(`/transaction/templates/form-templates/${id}`);
}

export function deleteFormTemplate({ id }) {
  return apiServer.delete(`/transaction/templates/form-templates/${id}`);
}

export function postFormTemplate(cfg) {
  return apiServer.post('/transaction/templates/form-templates', cfg);
}

export function putFormTemplate(cfg, { id }) {
  return apiServer.put(`/transaction/templates/form-templates/${id}`, cfg);
}

export const beginSigningFormTask = (cfg, { taskId }) => {
  return apiServer.patch(`/transaction/formTask/${taskId}`, cfg);
};
