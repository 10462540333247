import { routes } from 'settings/navigation/routes';
import { Workshop } from 'pages';
import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';

export default [
  {
    path: routes.tasks,
    component: Workshop,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
];
