/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toUpper } from 'lodash-es';

import Icon from 'pages/Properties/Feed/Icons';
import { ItemsWrapper, ItemWrapper, Placeholder } from '../components';

import styles from './styles.module.scss';

const Commutes = (props) => {
  const { className, commutes, isPending } = props;

  if (!Object.keys(commutes).length) return null;

  return Object.entries(commutes).map(([commuteName, propCommutes], i) => (
    <ItemsWrapper
      testid="commutes"
      key={i}
      title={`Commute to ${commuteName}`}
      className={classNames(styles.commutes, className)}
    >
      {propCommutes.map((commutesList, index) => {
        if (!commutesList) {
          return (
            <ItemWrapper key={index}>
              <Placeholder />
            </ItemWrapper>
          );
        }
        return (
          <ItemWrapper key={index} isPending={isPending}>
            {commutesList.map((commute, j) => {
              if (!commute?.Times || !Object.keys(commute?.Times || {}).length) {
                return <Placeholder key={j} />;
              }
              return (
                <Fragment key={j}>
                  {Object.entries(commute?.Times || {}).map(([key, value], k) => (
                    <div className={styles.commute} key={k}>
                      <Icon className={styles.icon} variant={Icon[toUpper(key)]} />
                      <span testid="commute_time">{`${value} min`}</span>
                    </div>
                  ))}
                </Fragment>
              );
            })}
          </ItemWrapper>
        );
      })}
    </ItemsWrapper>
  ));
};

Commutes.propTypes = {
  className: PropTypes.string,
  commutes: PropTypes.shape({}).isRequired,
  isPending: PropTypes.bool,
};

Commutes.defaultProps = {
  className: '',
  isPending: false,
};

export default Commutes;
