import { createAction } from 'redux-actions';

export const getFormCommentUsersAction = createAction('GET/FORM_COMMENT_USERS');

export const getFormCommentsAction = createAction('GET/FORM_COMMENTS');

export const addFormCommentAction = createAction('POST/FORM_COMMENT');

export const editFormCommentAction = createAction('PATCH/FORM_COMMENT');

export const deleteFormCommentAction = createAction('DELETE/FORM_COMMENT');

export const toggleResolveThreadAction = createAction('PATCH/FORM_COMMENT_THREAD_RESOLVE');

export const resetFormCommentStateAction = createAction('RESET/FORM_COMMENT_STATE');
