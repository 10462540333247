import { createAction } from 'redux-actions';

export const appLogoutAction = createAction('APP/LOGOUT');
export const appInitAction = createAction('APP/INIT');
export const appLoginAction = createAction('REQUEST/POST_LOGIN');
export const appModalOpenAction = createAction('APP/MODAL_OPEN');
export const appManageClientDrawerAction = createAction('APP/MANAGE_CLIENT_DRAWER');
export const appSetClientDrawerActiveTab = createAction('APP/SET_CLIENT_DRAWER_ACTIVE_TAB');

export const appSetInviteClientValuesAction = createAction('APP/SET_INVITE_CLIENT_VALUES');
export const appSetInviteTeamMemberValuesAction = createAction('APP/SET_INVITE_TEAM_MEMBER_VALUES');
export const appSetActiveClientTransactionInstanceAction = createAction(
  'APP/SET_ACTIVE_CLIENT_TRANSACTION_INSTANCE',
);
export const appSetActiveClientPreApproval = createAction('APP/SET_ACTIVE_CLIENT_PRE_APPROVAL');
export const appSetActiveClientSearchInstanceAction = createAction(
  'APP/SET_ACTIVE_CLIENT_SEARCH_INSTANCE',
);
export const appDrawerSetClientIdAction = createAction('APP/DRAWER_SET_CLIENT_ID');
export const appDrawerSetAgentIdAction = createAction('APP/DRAWER_SET_AGENT_ID');
export const appDrawerSetClientIdAndInstancesAction = createAction(
  'APP/DRAWER_SET_CLIENT_ID_AND_INSTANCES',
);
export const appSetClientsSearchValueAction = createAction('APP/SET_CLIENTS_SEARCH_VALUE');
export const appSetShowInviteClientAction = createAction('APP/SET_SHOW_INVITE_CLIENT');
export const setShowInviteCustomLocationAction = createAction(
  'APP/SET_SHOW_CUSTOM_LOCATION_INVITE_CLIENT',
);
export const appSetShowInviteTeamMemberAction = createAction('APP/SET_SHOW_INVITE_TEAM_MEMBER');
export const appSetShowAssignConnectionAction = createAction('APP/SET_SHOW_ASSIGN_CONNECTION');
export const appResetClientDriverAction = createAction('APP/RESET_CLIENT_DRIVER');
export const appSetInvitedClientDataAction = createAction('APP/SET_INVITED_CLIENT_DATA');
export const appChangeChatAdviceStatusAction = createAction('APP/CHANGE_CHAT_ADVICE_STATUS');

export const requestGetNotificationMessagesAction = createAction(
  'REQUEST/GET_NOTIFICATIONS_MESSAGES',
);
export const requestPostSendInvite = createAction('REQUEST/POST_SEND_INVITE');
export const appSetShowCriteriaModalInviteClientAction = createAction(
  'APP/CRITERIA_SECTION_INVITE_CLIENT',
);
export const appSetShowProfileExpandedSectionAction = createAction('APP/PROFILE_EXPANDED_SECTION');

export const appSetShowEmailVerificationPopupAction = createAction(
  'APP/SET_SHOW_EMAIL_VERIFICATION_POPUP',
);
export const appSetConnectionTypeAction = createAction('APP/SET_CONNECTION_TYPE');
export const appSetInviteClientAddressesAction = createAction('APP/SET_INVITE_CLIENT_ADDRESSES');
export const appResetInviteClientAddressesAction = createAction(
  'APP/RESET_INVITE_CLIENT_ADDRESSES',
);
export const appSetLastPropertyHeight = createAction('APP/SET_LAST_PROPERTY_HEIGHT');
export const requestNullAction = createAction('REQUEST/NULL');
