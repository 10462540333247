import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';

const SignUpLink = ({ className }) => (
  <div className={classNames(styles.signupText, className)}>
    <span>Don&apos;t have an account?</span>
    <Link testid="sign_up" className={styles.signupLink} to={routes.signUp}>
      Sign up
    </Link>
  </div>
);

SignUpLink.propTypes = {
  className: PropTypes.string,
};

SignUpLink.defaultProps = {
  className: '',
};

export default SignUpLink;
