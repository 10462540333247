import { createAction } from 'redux-actions';

//showingAggregate
export const requestGetShowingAggregateByUserId = createAction(
  'REQUEST/GET_SHOWING_AGGREGATE_BY_USER_ID',
);
export const requestGetShowingArchivedByUserId = createAction(
  'REQUEST/GET_SHOWING_ARCHIVED_BY_USER_ID',
);
export const setShowingsArchiveAction = createAction('SET_SHOWINGS_ARCHIVE');
export const setShowingsArchiveIdAction = createAction('SET_SHOWINGS_ARCHIVE_ID');
export const setShowingsFiltersAppliedAction = createAction('SET_SHOWINGS_FILTER_APPLIED');
export const resetShowingAggregateAction = createAction('RESET_SHOWINGS_AGGREGATE');
export const setShowingsFilterAction = createAction('SET_SHOWINGS_FILTER');
export const setListingsFilterAction = createAction('SET_LISTINGS_FILTER');
