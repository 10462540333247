import { useEffect } from 'react';
import classNames from 'classnames';

import { Wrapper as PendingWrapper } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAgentClientsGroupByNameSelector,
  getAgentClientsSelector,
  getComparesSelector,
} from 'store/selectors/feed';
import { PENDING } from 'settings/constants/apiState';
import { getAgentClientsWithFavoritesCountEffect } from 'store/effects';
import PropertiesWrapper from 'pages/Properties/Feed/components/PropertiesWrapper';
import BuyersList from './BuyersList';
import { getUserRolesMapSelector } from 'store/selectors/user';

import styles from './styles.module.scss';

const ClientFavorites = () => {
  const dispatch = useDispatch();
  const clientFavorites = useSelector(getAgentClientsSelector);
  const groupedBuyersList = useSelector(getAgentClientsGroupByNameSelector);
  const compares = useSelector(getComparesSelector);
  const { isAgent } = useSelector(getUserRolesMapSelector);

  useEffect(() => {
    if (isAgent) dispatch(getAgentClientsWithFavoritesCountEffect({}));
  }, []);

  const isPending = clientFavorites.state === PENDING;

  return (
    <PropertiesWrapper
      className={classNames(styles.scrollWrapper, { [styles.isCompares]: !!compares.data.length })}
    >
      <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
        <BuyersList groupedList={groupedBuyersList} />
      </PendingWrapper>
    </PropertiesWrapper>
  );
};

export default ClientFavorites;
