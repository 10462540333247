import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { appManageClientDrawerAction } from 'store/actions/app';
import { setProfileExpandedSection } from 'store/effects/app';
import { Close, Expand } from 'components/Icons';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { getUserId } from 'store/selectors/user';

import styles from './styles.module.scss';

const Actions = ({ className, isAgent }) => {
  const userId = useSelector(getUserId);
  const details = useSelector(getAgentDetailsSelector);
  const contact = details?.data || {};
  const dispatch = useDispatch();

  const handleClose = () => dispatch(appManageClientDrawerAction(false));

  const handleExpand = () => {
    dispatch(setProfileExpandedSection(true));
    dispatch(appManageClientDrawerAction(false));
  };

  const validateAgentExpand = () => !!(contact.Id === userId || contact.IsManagedByCurrentUser);

  const renderExpandIcon = () => {
    if (isAgent && !validateAgentExpand()) return;

    return (
      <Expand
        testid="main_button"
        className={styles.expandClientDrawerIcon}
        onClick={handleExpand}
      />
    );
  };

  return (
    <div className={classNames(styles.actions, className)}>
      {renderExpandIcon()}
      <Close
        testid="main_button"
        className={styles.closeClientDrawerIcon}
        type="circle"
        onClick={handleClose}
      />
    </div>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  isAgent: PropTypes.bool,
};

Actions.defaultProps = {
  className: '',
  isAgent: false,
};

export default Actions;
