import { createSelector } from 'reselect';
import { get } from 'lodash-es';

const localState = ({ adminPanel }) => adminPanel;

const serviceDirectoryState = createSelector(
  localState,
  ({ serviceDirectory }) => serviceDirectory,
);

const partnersState = createSelector(serviceDirectoryState, ({ partners }) => partners);

const partnerState = createSelector(serviceDirectoryState, ({ partner }) => partner);

export const getPartnersSelector = createSelector(partnersState, (state) => state);

export const getPartnerSelector = createSelector(partnerState, (state) => state);

export const getPartnerEditSelector = createSelector(getPartnerSelector, (partner) => {
  if (!partner?.data) return null;

  const address = get(partner.data, 'Address');
  const line1 = get(address, 'Line1', '');
  const line2 = get(address, 'Line2', '');
  const city = get(address, 'City', '');
  const state = get(address, 'State', '');
  const zip = get(address, 'Zip', '');

  const addressInfo = line1
    ? {
        Address: {
          Line1: line1,
          Line2: line2,
          State: state,
          City: city,
          Zip: zip,
          PlaceName: [line1, city, state].filter((i) => !!i).join(', '),
          ProviderPlaceId: line1, // Just set to the address line1, because we do not save ProviderPlaceId for Brokerage Address
        },
      }
    : {};

  return {
    BusinessName: get(partner.data, 'BusinessName'),
    AdditionalBusinessName: get(partner.data, 'AdditionalBusinessName'),
    Email: get(partner.data, 'Email'),
    PhoneNumber: get(partner.data, 'PhoneNumber'),
    AreasOfOperation: get(partner.data, 'AreasOfOperation'),
    Radius: get(partner.data, 'Radius'),
    ...addressInfo,
    SuiteUnit: get(partner.data, 'SuiteUnit'),
    Description: get(partner.data, 'Description'),
    Website: get(partner.data, 'Website'),
    LogoUrl: get(partner.data, 'LogoUrl'),
    IsRecommendedByMosaik: get(partner.data, 'IsRecommendedByMosaik'),
    IsActive: get(partner.data, 'IsActive'),
    PartnerCategoryIds: get(partner.data, 'PartnerCategories', []).map(({ Id, Title }) => ({
      value: Id,
      name: Title,
    })),
    ContactUser: {
      FirstName: get(partner.data, 'ThirdParty.User.FirstName', ''),
      LastName: get(partner.data, 'ThirdParty.User.LastName', ''),
      Email: get(partner.data, 'ThirdParty.User.Email', ''),
      Phones: get(partner.data, 'ThirdParty.User.Phones', ''),
    },
    PartnerServices: get(partner.data, 'PartnerServices', []),
    RecommendedByAgentsId: get(partner.data, 'RecommendedByAgentsId', []),
    DirectoryPreference: get(partner.data, 'DirectoryPreference'),
  };
});

export const getPartnerViewSelector = createSelector(getPartnerSelector, (partner) => {
  const address = get(partner.data, 'Address', {});
  const line1 = get(address, 'Line1', '');
  const line2 = get(address, 'Line2', '');
  const city = get(address, 'City', '');
  const state = get(address, 'State', '');
  const zip = get(address, 'Zip', '');
  const suiteUnit = get(partner.data, 'SuiteUnit', '');

  const addressInfo = line1
    ? {
        Address: [line1, suiteUnit, line2, city, state, zip].filter((v) => !!v).join(', '),
      }
    : {};

  const contactUser = get(partner.data, 'ThirdParty.User', '');

  const contactUserInfo = contactUser
    ? {
        FirstName: get(contactUser, 'FirstName', ''),
        LastName: get(contactUser, 'LastName', ''),
        Email: get(contactUser, 'Email', ''),
        Phones: get(contactUser, 'Phones', ''),
      }
    : null;

  return {
    BusinessName: get(partner.data, 'BusinessName', ''),
    AdditionalBusinessName: get(partner.data, 'AdditionalBusinessName', ''),
    Email: get(partner.data, 'Email', ''),
    PhoneNumber: get(partner.data, 'PhoneNumber', ''),
    ...addressInfo,
    Description: get(partner.data, 'Description', ''),
    Website: get(partner.data, 'Website', ''),
    LogoUrl: get(partner.data, 'LogoUrl', ''),
    IsRecommendedByMosaik: get(partner.data, 'IsRecommendedByMosaik', ''),
    RecommendingAgents: (get(partner.data, 'RecommendingAgents', []) || []).map(
      ({ Email, FirstName, LastName }) => `${FirstName} ${LastName} (${Email})`,
    ),
    IsActive: get(partner.data, 'IsActive', ''),
    Radius: get(partner.data, 'Radius', ''),
    PartnerCategories: (get(partner.data, 'PartnerCategories', []) || []).map(({ Title }) => Title),
    AreasOfOperation: (get(partner.data, 'AreasOfOperation', []) || []).map(
      ({ PlaceName }) => PlaceName,
    ),
    PartnerServices: get(partner.data, 'PartnerServices', []),
    PartnerTags: get(partner.data, 'PartnerTags'),
    ...(contactUserInfo ? { ContactUser: contactUserInfo } : {}),
  };
});

export const getLoadingPartnerData = createSelector(partnerState, ({ state, data, meta }) => ({
  state,
  dataId: `${get(data, 'Id')}`,
  metaId: `${get(meta, 'id')}`,
}));

export const getPartnersSearchSelector = createSelector(partnersState, ({ search }) => search);

export const getPartnersSortSelector = createSelector(partnersState, ({ sort }) => sort);
