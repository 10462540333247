import React, { memo, useMemo, useState } from 'react';
import classNames from 'classnames';

interface FavoriteProps extends React.HTMLAttributes<HTMLDivElement> {
  favorite?: Boolean;
  stroke?: string;
}

const Favorites = ({ favorite, className, stroke = '#ADADAD', ...props }: FavoriteProps) =>
  useMemo(
    () => (
      <div {...props} className={classNames(className, 'prevent-user-select')}>
        {favorite ? (
          <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0007 14.792L4.85741 17.4962L5.83991 11.7687L1.67908 7.71283L7.42908 6.87783L10.0007 1.667L12.5724 6.87783L18.3224 7.71283L14.1616 11.7687L15.1441 17.4962L10.0007 14.792Z"
              fill="#E66F3E"
              stroke="#E66F3E"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0007 14.792L4.85741 17.4962L5.83991 11.7687L1.67908 7.71284L7.42908 6.87784L10.0007 1.667L12.5724 6.87784L18.3224 7.71284L14.1616 11.7687L15.1441 17.4962L10.0007 14.792Z"
              stroke={stroke}
              strokeWidth={stroke === '#ADADAD' ? '2' : '2.75'}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    ),
    [favorite],
  );

export default Favorites;
