import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { Checkbox } from 'components';
import { postFormTemplateEffect, putFormTemplateEffect } from 'store/effects/template';
import { getUserRoleSelector } from 'store/selectors/user';
import { TaskTemplate } from 'types/transactionTemplate';
import { TaskEditableFormValuesType } from 'types/transactionTasks';

import styles from './styles.module.scss';

interface FooterProps {
  values: TaskTemplate;
  changedValues: TaskEditableFormValuesType;
  isTemplate: boolean;
  isAssignTask: boolean;
  isViewMode?: boolean;
  isTransactionTask: boolean;
  isNew: boolean;
  templateId: number;
  setNewFormValues: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
  assignTask: (...args: any[]) => any;
  isAggregatedTasks: boolean;
  onSubmit: (data) => void;
}

export const Footer = ({
  isTransactionTask = false,
  isNew = false,
  isTemplate = false,
  isAssignTask = false,
  templateId,
  values,
  changedValues,
  setNewFormValues = () => {},
  onClose = () => {},
  assignTask = () => {},
  isAggregatedTasks = false,
  isViewMode,
  onSubmit,
}: FooterProps) => {
  const userRole = useSelector(getUserRoleSelector);
  const [isPending, setIsPending] = useState(false);
  // const isNewTask = isNew && !isTemplate;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isDisabled = () => {
    if (values?.Initial === 'MD' && !values?.MilestonePublicId) return true;
    return (
      !(
        (values?.Title?.length || values?.Name?.length) &&
        (isTemplate ? values?.AssigneeRoles?.length : values?.AssigneeList?.length) &&
        (values?.DueDate || values?.DueDate === 0)
      ) ||
      isPending ||
      isViewMode
    );
  };

  const parseControlOperatorOffset = (cpo: string) => {
    if (cpo) {
      //Split array on + or -
      let strArray = cpo.split(/[+-]/);
      if (strArray.length !== 2 || isNaN(Number(strArray[1]))) return undefined;
      let operator = cpo.includes('+') ? '+' : '-';
      return {
        initial: strArray[0],
        operator,
        dateOffset: Number(strArray[1]),
      };
    }
  };

  const onCloseModal = () => {
    setNewFormValues({});
    onClose();
  };

  const onSaveTemplate = () => {
    setIsPending(true);
    const effect = values?.Id ? putFormTemplateEffect : postFormTemplateEffect;
    const body = {
      ...values,
    };

    dispatch(
      effect(body, { id: templateId }, (err) => {
        if (!err) {
          onCloseModal();
        }
        setIsPending(false);
      }),
    );
  };

  const onSave = () => onSubmit(values);

  const toggleSaveAsTemplate = (val) => {
    setNewFormValues({ ...values, SaveAsTemplate: val });
    if (values) values.SaveAsTemplate = val;
  };

  const saveAsTemplate = () => (
    <div className={styles.saveAsTemplate}>
      <Checkbox
        className={styles.checkbox}
        checkmarkClassName={styles.checkmark}
        checked={values?.SaveAsTemplate}
        onChange={(e) => toggleSaveAsTemplate(e?.target?.checked)}
      />
      <p onClick={() => toggleSaveAsTemplate(!values?.SaveAsTemplate)}>Save as template</p>
    </div>
  );

  return (
    <div className={styles.footer}>
      {!isTransactionTask ? (
        <>
          <div className={classNames(styles.saveBtnDiv, styles.createBtnDiv)}>
            <button
              className={classNames(styles.saveBtn, styles.createBtn, {
                [styles.saveBtnDisabled]: isDisabled(),
              })}
              onClick={onSave}
              disabled={isDisabled()}
            >
              <span>{isNew ? 'Create' : 'Save'}</span>
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};
