import { createSelector } from 'reselect';
import { get } from 'lodash-es';

const localState = ({ relatedEntities }) => get(relatedEntities, 'entities');

const localCompleteListState = ({ relatedEntities }) =>
  get(relatedEntities, 'entitiesCompleteList');

const localContactState = ({ relatedEntities }) => get(relatedEntities, 'contacts');

const localRelatedEntitiesHttp = ({ relatedEntities }) => get(relatedEntities, 'state');

export const getRelatedEntitiesState = createSelector(localState, (entities) => entities);

export const getRelatedEntitiesCompleteListState = createSelector(
  localCompleteListState,
  (entities) => entities,
);

export const getRelatedEntitiesHttpState = createSelector(
  localRelatedEntitiesHttp,
  (state) => state,
);

export const getRelatedContactState = createSelector(localContactState, (contacts) => contacts);

export const getRelatedContactsSelector = createSelector(localState, (entities) => {
  return entities.filter((entity) => entity.Type === 'Contact');
});

export const getRelatedPropertyTransactionsSelector = createSelector(localState, (entities) => {
  return entities?.filter((entity) => entity.Type === 'Property');
});
