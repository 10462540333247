import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const Resend = memo(({ className }) => {
  const icon = useMemo(
    () => (
      <div className={className}>
        <svg
          width="13"
          height="16"
          viewBox="0 0 13 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.0201 9.03009C0.922234 10.1718 1.18343 11.3156 1.7672 12.3017C2.35097 13.2878 3.22816 14.0669 4.27624 14.5302C5.32432 14.9935 6.49094 15.1179 7.61314 14.886C8.73535 14.6541 9.75709 14.0774 10.5356 13.2366C11.3142 12.3958 11.8107 11.3328 11.9558 10.1961C12.1008 9.05936 11.8872 7.90573 11.3448 6.89633C10.8024 5.88692 9.95819 5.07215 8.9302 4.56582C7 3.61512 4 4 4 4M4 4L7 1M4 4L7 6.89633"
            stroke="#163C5A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  );

  return icon;
});

Resend.propTypes = {
  className: PropTypes.string,
};

Resend.defaultProps = {
  className: '',
};

export default Resend;
