import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { cloneDeep, get } from 'lodash-es';

import {
  requestGetClientFavoritePropsAction,
  requestSendSharePropsToRecipientsAction,
  resetClientFavoritePropsAction,
  resetPropertiesWholeTabsAction,
} from 'store/actions/feed';
import { requestToggleFavoriteListingAction } from 'store/actions/listingDetail';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetClientFavoritePropsAction]: (state, { payload }) => {
      const data =
        payload?.options && payload?.options?.add
          ? [...state.data, ...get(payload, 'data.result', [])]
          : get(payload, 'data.result', initialData.data);
      return {
        state: payload.state,
        data: (data || []).filter((d) => !!d),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestToggleFavoriteListingAction]: (state, { payload }) => {
      const id = get(payload, 'meta.id');
      const isFavorite = get(payload, 'options.IsFavorite');
      let newData = cloneDeep(state?.data) || [];
      if (!isFavorite) {
        newData = newData.filter((item) => item?.Id !== id && item.IsFavorite);
      }
      return {
        ...state,
        data: newData,
      };
    },
    [requestSendSharePropsToRecipientsAction]: (state, { payload }) => {
      const updatedData = cloneDeep(state?.data);
      if (payload.state === 'ready' && payload?.meta && updatedData?.length) {
        const { ClientIds, MLSIds } = payload.meta;
        const clients = payload?.options?.clients?.data
          .filter((client) => ClientIds.includes(client.Id))
          ?.map((data) => ({ FirstName: data?.FirstName, LastName: data?.LastName, Id: data?.Id }));

        MLSIds.forEach((mlsId) => {
          const mlsIndex = updatedData?.findIndex((mls) => mls.Id === mlsId);

          if (mlsIndex !== -1) {
            updatedData[mlsIndex].RecommendedTo = clients;
          }
        });

        return {
          ...state,
          data: updatedData,
        };
      } else return state;
    },
    [resetClientFavoritePropsAction]: () => cloneDeep(initialData),
    [resetPropertiesWholeTabsAction]: () => cloneDeep(initialData),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
