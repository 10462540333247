import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import {
  getIsNotificationsDrawerOpenSelector,
  geNotificationsDrawerParamsSelector,
} from 'store/selectors/drawers/notifications';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { getNotificationEnableStatus } from 'store/selectors/user';

import { Drawer } from 'components';
import { Header, Notifications, UserNotificationsHeader } from './components';
import SocketConnectionWrapper from '../components/SocketConnectionWrapper';
import { EnableNotification } from './components/EnableNotification';

import styles from './styles.module.scss';

const NotificationsDrawer = ({ className }) => {
  const dispatch = useDispatch();
  const drawerOpened = useSelector(getIsNotificationsDrawerOpenSelector);
  const drawerParams = useSelector(geNotificationsDrawerParamsSelector);
  const notificationsEnable = useSelector(getNotificationEnableStatus);

  const onClose = () => {
    dispatch(openNotificationsDrawerEffect({ open: false }));
  };

  const getHeader = () => {
    if (drawerParams?.currentNotificationsUser) {
      return <UserNotificationsHeader onClose={onClose} />;
    }
    return <Header onClose={onClose} />;
  };

  return (
    <Drawer
      testid="notifications_drawer"
      onClose={onClose}
      className={classNames(styles.drawer, className)}
      isOpen={drawerOpened}
      header={getHeader()}
    >
      <SocketConnectionWrapper>
        <div className={styles.drawerInner}>
          <div className={styles.scrollWrapper}>
            {notificationsEnable ? <Notifications /> : <EnableNotification />}
          </div>
        </div>
      </SocketConnectionWrapper>
    </Drawer>
  );
};

NotificationsDrawer.propTypes = {
  className: PropTypes.string,
};

NotificationsDrawer.defaultProps = {
  className: '',
};

export default NotificationsDrawer;
