import { useEffect, useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import { Document } from 'react-pdf';
import axios from 'axios';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { v4 as uuidv4 } from 'uuid';
import { DndProvider } from 'react-dnd-multi-backend';

import { getPDFEditorPagesAndFields, updatePDFEditorDocument, getFieldDefaultSize } from 'utils';
import { PDFEditorFieldType, PDFEditorFieldAttribute, PDFEditorPagesType } from 'types';
import { Spinner } from 'components';
import { PageWrapper } from 'components-antd';
import { PDFEditorLeftSidebar, PDFEditorPage, PDFEditorRightSidebar } from './components';
import { PDFEditorTopBar } from './components/PDFEditorTopBar';
import { PDF_FIELD_TYPE } from 'app-constants';

import styles from './styles.module.scss';

interface PDFEditorProps {
  link: string;
  updateDocument?: (files: File) => void;
  documentName?: string;
  handleExit: () => void;
}

export const PDFEditor = ({ link, updateDocument, documentName, handleExit }: PDFEditorProps) => {
  const [PDF, setPDF] = useState<PDFDocument>();
  const [highlighted, setHighlighted] = useState<string>();
  const [disabled, setDisabled] = useState(false);

  const [pages, setPages] = useState<PDFEditorPagesType>({});
  const [allFields, setAllFields] = useState<PDFEditorFieldType[]>([]);

  const [scale, setScale] = useState(1.5);
  const [isLoading, setLoading] = useState(true);

  const pageNumbers = Object.keys(pages);

  const extractPDFData = (pdfDoc: PDFDocument) => {
    const [pdfPages, pdfFields] = getPDFEditorPagesAndFields(pdfDoc);
    setHighlighted(undefined);
    setPDF(pdfDoc);
    setPages(pdfPages);
    setAllFields(pdfFields);
  };

  const fetchPDFPages = async () => {
    if (link) {
      const data = await axios.get(link, {
        responseType: 'arraybuffer',
      });

      const pdfDoc = await PDFDocument.load(data.data);

      if (pdfDoc) {
        extractPDFData(pdfDoc);
        setTimeout(() => setLoading(false), 0);
      }
    }
  };

  useEffect(() => {
    fetchPDFPages();
  }, []);

  const handleFieldAdd = ({ x, y, pageIndex, item }) => {
    const { type, groupName } = item;
    const fieldName = `${type}-${uuidv4()}`;
    const widgetId = 0;
    const fieldId = uuidv4();

    const size = getFieldDefaultSize(type);

    const fieldHeight = size.height / scale;
    const fieldWidth = size.width / scale;

    const newField = {
      id: fieldId,
      name: fieldName,
      isNew: true,
      isUpdated: false,
      groupName: groupName ?? uuidv4(),
      isNewGroup: true,
      attributes: {
        height: fieldHeight,
        width: fieldWidth,
        x,
        y,
        updatedY: pages[pageIndex].height - fieldHeight - y,
      },
      page: pageIndex,
      widgetId,
      type,
      value: uuidv4(),
    } as PDFEditorFieldType;

    if (type === PDF_FIELD_TYPE.PDFRadioGroup && groupName) {
      const existingGroup = allFields.filter((f) => f.groupName === groupName);
      if (existingGroup?.length) {
        const f = existingGroup[0];
        newField.name = f.name;
        newField.widgetId = existingGroup.length;
        newField.isNewGroup = f.isNewGroup;
      }
    }

    const fields = [...allFields, newField];

    setAllFields(fields);
    setHighlighted(fieldId);
  };

  const handleFieldUpdate = (fieldId: string, fieldConfig: Partial<PDFEditorFieldType>) => {
    const field = allFields.find((f) => f.id === fieldId);
    let updatedFields: PDFEditorFieldType[] = [];
    if (field) {
      const { name, ...newConfig } = fieldConfig;

      if (field.type === PDF_FIELD_TYPE.PDFRadioGroup && name) {
        updatedFields = allFields.map((f) => {
          if (field.groupName === f.groupName) {
            if (name) {
              f.name = name;
            }
            if (newConfig && f.id === fieldId) {
              return {
                ...f,
                ...newConfig,
              };
            }
          }

          return f;
        });
      } else {
        updatedFields = allFields.map((f) => {
          if (field.id === f.id) {
            return {
              ...f,
              ...fieldConfig,
            };
          }

          return f;
        });
      }
      setAllFields(updatedFields);
    }
    setDisabled(false);
  };

  const handleFieldAttributeUpdate = (
    fieldId: string,
    attribute: Partial<PDFEditorFieldAttribute>,
  ) => {
    const fields = allFields.map((f) => {
      if (f.id === fieldId) {
        f.isUpdated = true;
        f.attributes = {
          ...f.attributes,
          ...attribute,
        };
        f.attributes.updatedY = pages[f.page].height - f.attributes.height - f.attributes.y;
      }

      return f;
    });
    setAllFields(fields);
    setDisabled(false);
  };

  const handleFieldDelete = (fieldId: string) => {
    const field = allFields.find((f) => f.id === fieldId);

    if (field) {
      const fields = allFields
        .map((f) => {
          if (f.id === fieldId) {
            if (f.isNew) {
              return null;
            } else {
              f.isDeleted = true;
            }
          }
          return f;
        })
        .filter((el) => el) as PDFEditorFieldType[];

      setAllFields(fields);
      if (field.type === PDF_FIELD_TYPE.PDFRadioGroup) {
        const nextMember = fields.find((f) => !f.isDeleted && f.groupName === field.groupName);
        if (nextMember) {
          setHighlighted(nextMember.id);
        } else {
          setHighlighted(undefined);
        }
      } else {
        setHighlighted(undefined);
      }
    }
    setDisabled(false);
  };

  const handleDocumentChange = async (save = false) => {
    if (PDF) {
      setLoading(true);
      const updatedDocument = await updatePDFEditorDocument(PDF, allFields, documentName, save);
      if (updatedDocument) {
        if (save) {
          const linkSource = `data:application/pdf;base64,${updatedDocument}`;
          extractPDFData(PDF);
          const link = document.createElement('a');
          document.body.appendChild(link);
          link.href = linkSource;
          link.download = documentName ?? 'modified-document.pdf';
          link.click();
        } else if (updateDocument) {
          updateDocument(updatedDocument as File);
        }
      }
      setTimeout(() => setLoading(false), 0);
    }
  };

  const getHighlightedField = () =>
    highlighted ? allFields.find((f) => f.id === highlighted) : undefined;

  return pageNumbers.length && allFields.length ? (
    <DndProvider options={HTML5toTouch}>
      <PageWrapper
        SiderComponent={() => <PDFEditorLeftSidebar />}
        RightSiderComponent={() => (
          <PDFEditorRightSidebar
            field={getHighlightedField()}
            allFields={allFields}
            onFieldUpdate={handleFieldUpdate}
            onFieldAttributeUpdate={handleFieldAttributeUpdate}
            onFieldDelete={handleFieldDelete}
            setHighlighted={setHighlighted}
          />
        )}
        mainPageContentStyle={styles.questionContainer}
        footerStyles={styles.footerStyles}
        ToolBar={
          <PDFEditorTopBar
            handleExit={handleExit}
            scale={scale}
            setScale={setScale}
            updateDocument={handleDocumentChange}
            disabled={disabled}
            setDisabled={setDisabled}
          />
        }
      >
        {!isLoading ? (
          <Document file={link} className={styles.pdfDocument}>
            {pageNumbers.map((pageIndex) => (
              <PDFEditorPage
                disabled={disabled}
                key={`Editor-Page-${pageIndex}`}
                pages={pages}
                allFields={allFields.filter((f) => f.page === pageIndex)}
                pageIndex={pageIndex}
                scale={scale}
                handleFieldUpdate={handleFieldAttributeUpdate}
                handleFieldAdd={handleFieldAdd}
                highlighted={getHighlightedField()}
                setHighlighted={setHighlighted}
              />
            ))}
          </Document>
        ) : (
          <Spinner />
        )}
      </PageWrapper>
    </DndProvider>
  ) : (
    <Spinner />
  );
};
