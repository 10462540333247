import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get } from 'lodash-es';

import {
  requestGetSearchThreadMessagesByStringAction,
  searchMessagesClearDataAction,
} from 'store/actions/searchMessages';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetSearchThreadMessagesByStringAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
      meta: payload?.meta || state.meta,
    }),
    [searchMessagesClearDataAction]: () => initialData,
  },
  initialData,
);
