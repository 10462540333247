import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { FormWrapper, FormGroup, FormTitle, Input, Button } from 'components';
import { routes } from 'settings/navigation/routes';
import { forgotPasswordEffect } from 'store/effects/loginGroup';
import { PENDING } from 'settings/constants/apiState';
import { getForgotPasswordSelector } from 'store/selectors/loginGroup';

import GoBack from '../GoBack';
import { ValidationSchema } from './validation';

import styles from './styles.module.scss';

const Form = () => {
  const forgotPassword = useSelector(getForgotPasswordSelector);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: { ...Form.initialValues },
    validationSchema: ValidationSchema,
    onSubmit(values) {
      dispatch(
        forgotPasswordEffect(values, {}, (err) => {
          if (!err) {
            formik.resetForm();
          }
        }),
      );
    },
  });

  const isPending = forgotPassword.state === PENDING;

  return (
    <FormWrapper alignCenter={false} className={styles.forgotPassword}>
      <FormGroup className={styles.formGroup} testid="forgot_password">
        <h2 className={styles.title}>Forgot password</h2>
        <form onSubmit={formik.handleSubmit}>
          <Input
            type="text"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="Enter your email"
            label="Email"
            error={formik.touched.email ? formik.errors.email : ''}
            disabled={isPending}
            testid="email"
            variant={Input.LIGHT}
            replacePlaceholderWithLabelWhenHasValue
          />
          <p className={styles.description}>
            If you have an account, we&apos;ll send you an email with directions on resetting your
            password.
          </p>
          <div className={styles.buttons}>
            <Button
              testid="request_reset"
              type="submit"
              className={styles.submit}
              title="Request Reset"
              isPending={isPending}
            />
            {/* 
          <GoBack className={styles.goBack} to={routes.login} text="Back to Login" />
          */}
          </div>
        </form>
      </FormGroup>
    </FormWrapper>
  );
};

Form.initialValues = {
  email: '',
};

export default Form;
