import Api from 'store/effects/core/api';
import { requestGetSearchThreadMessagesByStringAction } from 'store/actions/searchMessages';
import { getSearchThreadsAndMessagesByString } from 'api/searchMessages';

export const requestGetSearchThreadMessagesByStringEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetSearchThreadMessagesByStringAction,
    method: getSearchThreadsAndMessagesByString,
  });

  return sendRequest(cfg, options, cb);
};
