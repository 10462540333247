import { createAction } from 'redux-actions';

export const sortTaskTemplatesAction = createAction('DASHBOARD/SORT_TASK_TEMPLATES');
export const searchTaskTemplatesAction = createAction('DASHBOARD/SEARCH_TASK_TEMPLATES');
export const requestGetTaskTemplatesDashboardAction = createAction(
  'REQUEST/GET_TASK_TEMPLATES_DASHBOARD',
);
export const requestGetTaskTemplateDashboardAction = createAction(
  'REQUEST/GET_TASK_TEMPLATE_DASHBOARD',
);
export const requestPutTaskTemplateDashboardAction = createAction(
  'REQUEST/PUT_TASK_TEMPLATE_DASHBOARD',
);
export const requestPostTaskTemplateDashboardAction = createAction(
  'REQUEST/POST_TASK_TEMPLATE_DASHBOARD',
);
export const requestDeleteTaskTemplateDashboardAction = createAction(
  'REQUEST/DELETE_TASK_TEMPLATE_DASHBOARD',
);
