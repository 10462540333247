/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ItemsWrapper, ItemWrapper } from '../components';

import styles from './styles.module.scss';

const Images = (props) => {
  const { className, images } = props;

  if (!images) return null;

  return (
    <ItemsWrapper className={classNames(styles.images, className)}>
      {images.map((image, index) => (
        <ItemWrapper key={index}>
          <img testid="image" className={styles.image} src={image} alt="" />
        </ItemWrapper>
      ))}
    </ItemsWrapper>
  );
};

Images.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Images.defaultProps = {
  className: '',
};

export default Images;
