import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useWizardRole } from 'hooks';

import { routes } from 'settings/navigation/routes';
import { mapRoles } from 'settings/constants/roles';
import { link } from 'settings/navigation/link';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';

import { markWizardFinishedEffect } from '../../store/effects';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { Header } from 'pages/OnBoarding/components/Header';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingSkipButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingSkipButton';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import StagesControls from 'components/Stages/Controls';

import styles from './styles.module.scss';

const Start = () => {
  useWizardRole();
  const history = useHistory();
  const dispatch = useDispatch();
  const signUpRole = useSelector(getSignUpRoleSelector);

  const onStart = () => {
    history.push(link.toOnBoardingWizard(mapRoles[signUpRole]));
  };

  const onSkip = () => {
    dispatch(
      markWizardFinishedEffect({
        isWizardSkipped: true,
      }),
    );
    history.push(routes.feed);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <Header />
      <OnBoardingWrapper>
        <StagesControls className={styles.controls} variant="lightFull" onPrev={goBack} />
        <OnBoardingContainer testid="start_wizard" className={styles.container}>
          <p className={styles.text}>
            We just have a few more questions about what you&apos;re looking for.
          </p>
          <p className={styles.text}>
            We only use your answers to personalize your experience. It won&apos;t limit or hide any
            homes you might like.
          </p>
          <ButtonsContainer>
            <OnboardingContinueButton testid="get_started" type="button" onClick={onStart} />
            <OnboardingSkipButton
              testid="skip"
              type="button"
              onClick={onSkip}
              title="Skip for now"
            />
          </ButtonsContainer>
        </OnBoardingContainer>
      </OnBoardingWrapper>
    </>
  );
};

export default Start;
