import { handleActions } from 'redux-actions';

import { openPreApprovalLettersDrawerAction } from 'store/actions/clientSearches';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';

const initialData = {
  open: false,
};

export default handleActions(
  {
    [openPreApprovalLettersDrawerAction]: (state, { payload }) => ({
      ...state,
      open: payload.open || initialData.open,
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
