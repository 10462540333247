import { createSelector } from 'reselect';

import { PENDING, IDLE } from 'settings/constants/apiState';

const localState = ({ schedule }) => schedule;

export const getLoadingScheduleSelector = createSelector(localState, ({ state }) => ({
  state,
  isIdle: state === IDLE,
  isPending: state === PENDING,
}));

export const getAgentScheduleSelector = createSelector(localState, ({ agentData }) => agentData);

export const getTeamScheduleSelector = createSelector(localState, ({ teamData }) => teamData);
