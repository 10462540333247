import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetTaskTemplatesAction,
  setTaskTemplatesQueryAction,
} from 'store/actions/templates';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  query: '',
};

export default handleActions(
  {
    [requestGetTaskTemplatesAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [setTaskTemplatesQueryAction]: (state, { payload }) => ({
      ...state,
      query: payload || initialData.query,
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
