import { PDFDocument, PDFField } from 'pdf-lib';
import { useCallback, useEffect, useRef } from 'react';

export const useFieldsHighlighter = (pdfDoc: PDFDocument | null) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.setAttribute('width', window.getComputedStyle(canvas, null).getPropertyValue('width'));
      canvas.setAttribute(
        'height',
        window.getComputedStyle(canvas, null).getPropertyValue('height'),
      );
    }
  });

  const highlightField = useCallback(
    (field: PDFField) => {
      const canvas = canvasRef.current;
      if (!canvas || !pdfDoc) return null;

      // For now working with first page only - check for multiple-page docs
      const firstPage = pdfDoc.getPage(0);

      const scale = canvas.width / firstPage.getWidth();

      const fieldRect = field.acroField.getWidgets()[0].getRectangle();

      const x = fieldRect.x * scale;
      const y = (firstPage.getHeight() - fieldRect.y - fieldRect.height) * scale;
      const width = fieldRect.width * scale;
      const height = fieldRect.height * scale;

      const canvasContext = canvas.getContext('2d');
      if (!canvasContext) return null;

      canvasContext.lineWidth = 3;
      canvasContext.strokeStyle = '#ff9900';
      canvasContext.strokeRect(x, y, width, height);
      canvasContext.fill();
    },
    [pdfDoc],
  );

  const clearFieldHighlight = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return null;

    const canvasContext = canvas.getContext('2d');
    (canvasContext as any).reset();
  }, []);

  const canvas = (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        background: '#f5f5dc33',
      }}
    ></canvas>
  );

  return {
    canvas,
    highlightField,
    clearFieldHighlight,
  };
};
