import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';
import {
  setTransactionFiltersAppliedEffect,
  setProjectsFilterEffect,
} from 'store/effects/transactions/all';
import { getTransactionsIsArchiveSelector } from 'store/selectors/transactions';
import { TEAM_OWNER, TEAM_ADMIN, projectsFilters } from 'settings/constants/roles';

import styles from './styles.module.scss';

export const ProjectsFilter = (props) => {
  const { agentRole } = props ?? {};
  const [isFiltersApplied, setIsFilterApplied] = useState(false);
  const [value, setValue] = useState(projectsFilters.allProjects);
  const dispatch = useDispatch();
  const isArchive = useSelector(getTransactionsIsArchiveSelector);

  useEffect(() => {
    // Reset Filters whenever archive button is clicked.
    setValue(projectsFilters.allProjects);
    dispatch(setProjectsFilterEffect(projectsFilters.allProjects));
    setIsFilterApplied(false);
    dispatch(setTransactionFiltersAppliedEffect(false));
  }, [isArchive]);

  const onChange = (e) => {
    const val = e.target.value;
    const isFiltersApplied = val !== projectsFilters.allProjects;

    setIsFilterApplied(isFiltersApplied);
    dispatch(setProjectsFilterEffect(val));
    setValue(val);
  };

  // Hide Team Transactions option for Team members.
  const teamAdminAndOwnerOption = [TEAM_OWNER, TEAM_ADMIN].includes(agentRole)
    ? [projectsFilters.teamProjects]
    : [];

  const dropdownFields = [
    projectsFilters.myProjects,
    ...teamAdminAndOwnerOption,
    projectsFilters.externalProjects,
    projectsFilters.allProjects,
  ];

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => (
          <MenuItem key={idx}>
            <Radio value={item} key={idx}>
              <div className={styles.option}>
                <span className={styles.optionValue}>{item}</span>
              </div>
              {value === item && <Check className={styles.checkIcon} />}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div
      className={classNames(
        styles.filterWrap,
        {
          [styles.filtersAppliedWrapper]: isFiltersApplied,
        },
        props.className,
      )}
    >
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.selectedValue}>{value}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
        </a>
      </Dropdown>
    </div>
  );
};
