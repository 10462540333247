import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getListingDetailMapData } from 'store/selectors/listingDetail';
import { Map } from 'components';

const MapModal = () => {
  const { zoom, center, markers } = useSelector(getListingDetailMapData);

  return (
    <div className={styles.mapModal}>
      <div className={styles.bottomBar}>
        <Map
          markersMap={markers}
          className={styles.map}
          controlsClassName={styles.mapControls}
          zoom={zoom}
          center={center}
          controls={{
            zoom: true,
          }}
        />
      </div>
    </div>
  );
};

export default MapModal;
