import { useEffect, useMemo, useState } from 'react';
import { TabPane, Tabs } from 'components-antd';

import styles from './styles.module.scss';
import { SplitTabTypes, SplitTypes } from '../../TransactionFinancials';
import { SplitCard } from './components';
import { getSplitNames } from 'api/financials';

function sortSplitData(data) {
  const splitTypeOrder = {
    Agent_Split: 1,
    Team_Split: 2,
    Brokerage_Split: 3,
  };

  return data.sort((a, b) => {
    const splitTypeA = a.SplitType?.SplitType;
    const splitTypeB = b.SplitType?.SplitType;

    return (splitTypeOrder[splitTypeA] || 0) - (splitTypeOrder[splitTypeB] || 0);
  });
}

export const Splits = (props) => {
  const [tabKey, setTabKey] = useState(props?.splitTabType === SplitTabTypes?.LISTING ? '1' : '2');
  const [autocompleteSplitNames, setAutocompleteSplitNames] = useState<any>(null);

  const fetchAutocompleteSplitNames = async () => {
    let autocompleteSplitNames = await getSplitNames();
    setAutocompleteSplitNames(autocompleteSplitNames?.SplitTitles);
  };

  useEffect(() => {
    fetchAutocompleteSplitNames();
  }, []);

  let transactionSplitsData =
    props?.financials &&
    props?.financials?.TransactionSplit &&
    sortSplitData(props?.financials?.TransactionSplit);

  const generateTransactionSplitsData = (SplitSide) => {
    const splitConfigs = [
      { SplitTitle: 'Agent Split', SplitType: SplitTypes.AGENT },
      { SplitTitle: 'Team Split', SplitType: SplitTypes.TEAM },
      { SplitTitle: 'Brokerage Split', SplitType: SplitTypes.BROKERAGE },
      { SplitTitle: 'Client Split', SplitType: SplitTypes.CLIENT },
    ];

    return splitConfigs.map((config, idx) => ({
      Id: idx + 1,
      PropertyTransactionId: props?.transactionId,
      IsSplitTypePercentage: true,
      SplitPercentage: null,
      SplitSide: SplitSide,
      SplitType: {
        SplitTitle: config.SplitTitle,
        SplitType: config.SplitType,
      },
      SplitValue: null,
    }));
  };

  if (!transactionSplitsData?.length) {
    // If the transaction splits data is null, generate it.
    transactionSplitsData = [
      ...generateTransactionSplitsData('Listing'),
      ...generateTransactionSplitsData('Purchase'),
    ];
  }

  let _transactionCommission =
    props?.financials &&
    props?.financials?.TransactionCommission &&
    props?.financials?.TransactionCommission;
  let listingData =
    transactionSplitsData &&
    transactionSplitsData.filter((data) => {
      return data.SplitSide === 'Listing';
    });

  let buyerData =
    transactionSplitsData &&
    transactionSplitsData.filter((data) => {
      return data.SplitSide === 'Purchase';
    });

  const isNotDualTransaction = useMemo(() => {
    // Checking if the transaction is a buyer or seller and *NOT* dual.
    return props?.representingRoles?.length < 2;
  }, [props?.representingRoles]);

  const getComparePrice = (data) => {
    let _comparePrice = 0;
    let price = props && props?.financials && props?.financials?.PropertyTransactionKeyDatesPrices;
    const selectedPrice =
      price?.ClosePrice ?? price?.UnderContractPrice ?? price?.ListingPrice ?? 0;
    if (data?.IsFeeTypePercentage) {
      _comparePrice = (data?.Value / 100) * selectedPrice;
    } else {
      _comparePrice = data?.Value;
    }

    return _comparePrice;
  };

  const TABS_ITEMS = [
    {
      key: '1',
      label: 'List-Side',
      children: (
        <SplitCard
          data={
            listingData?.length ? listingData : generateTransactionSplitsData(SplitTabTypes.LISTING)
          }
          fetchFinancials={props?.fetchFinancials}
          getSplitList={props?.getSplitList}
          comparePrice={getComparePrice(_transactionCommission?.SellerCommissionFees)}
          isTransactionAdminOrOwner={props?.isTransactionAdminOrOwner}
          splitType={SplitTabTypes.LISTING}
          autocompleteSplitNames={autocompleteSplitNames}
        />
      ),
    },
    {
      key: '2',
      label: 'Buy-Side',
      children: (
        <SplitCard
          data={
            buyerData?.length ? buyerData : generateTransactionSplitsData(SplitTabTypes.PURCHASE)
          }
          fetchFinancials={props?.fetchFinancials}
          getSplitList={props?.getSplitList}
          comparePrice={getComparePrice(_transactionCommission?.BuyerCommissionFees)}
          isTransactionAdminOrOwner={props?.isTransactionAdminOrOwner}
          splitType={SplitTabTypes.PURCHASE}
          autocompleteSplitNames={autocompleteSplitNames}
        />
      ),
    },
  ];

  const handleTabChange = (key) => {
    setTabKey(key);
    if (key === '1') {
      props?.getSplitList(listingData);
      props?.setSplitTabType(SplitTabTypes.LISTING);
    } else {
      props?.getSplitList(buyerData);
      props?.setSplitTabType(SplitTabTypes.PURCHASE);
    }
  };

  return (
    <>
      {isNotDualTransaction ? (
        <>
          <p className={styles.notDualTransTitle}>
            {props?.isBuyerTransaction ? 'Buy' : 'List'}-Side
          </p>
          <SplitCard
            data={props?.isBuyerTransaction ? buyerData : listingData}
            fetchFinancials={props?.fetchFinancials}
            getSplitList={props?.getSplitList}
            comparePrice={getComparePrice(
              props?.isBuyerTransaction
                ? _transactionCommission?.BuyerCommissionFees
                : _transactionCommission?.SellerCommissionFees,
            )}
            isTransactionAdminOrOwner={props?.isTransactionAdminOrOwner}
            splitType={props?.isBuyerTransaction ? SplitTabTypes.PURCHASE : SplitTabTypes.LISTING}
            autocompleteSplitNames={autocompleteSplitNames}
          />
        </>
      ) : (
        <Tabs activeKey={tabKey} onChange={handleTabChange} defaultActiveKey={'1'}>
          {TABS_ITEMS.map(({ key, children, label }) => (
            <TabPane
              tab={
                <div className={styles.tabRow}>
                  <span>{label}</span>
                </div>
              }
              key={key}
            >
              {children}
            </TabPane>
          ))}
        </Tabs>
      )}
    </>
  );
};
