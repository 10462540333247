/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ItemsWrapper, ItemWrapper, Value } from '../components';

import styles from './styles.module.scss';

const Addresses = (props) => {
  const { className, addresses } = props;

  if (!addresses) return null;

  return (
    <ItemsWrapper className={classNames(styles.addresses, className)}>
      {addresses.map(({ Line1, City, State }, index) => (
        <ItemWrapper key={index}>
          <Value testid="address_1" className={styles.line1} value={Line1} />
          <Value testid="address_2" className={styles.city} value={`${City}, `} postfix={State} />
        </ItemWrapper>
      ))}
    </ItemsWrapper>
  );
};

Addresses.propTypes = {
  className: PropTypes.string,
  addresses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Addresses.defaultProps = {
  className: '',
};

export default Addresses;
