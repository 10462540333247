import { GetAvailableSubscriptionPlansResponse } from 'api/subscription';
import { calculateStandardPrice } from 'pages/Paywall/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestGetAvailableSubscriptionPlansEffect,
  requestGetImplementationPlansEffect,
  requestGetCouponEffect,
  requestSubscribeStandardEffect,
} from 'store/effects/subscription';
import {
  getAvailablePlansSelector,
  getCurrentPlanLevelSelector,
  getImplementationPlanSelector,
  getStandardCouponSelector,
  getStandardSubscriptionSelector,
} from 'store/selectors/subscriptions';
import { debounce } from 'lodash';

export function useSubscription(props?: { requestedSeatCount?: number }) {
  const { requestedSeatCount = 2 } = props || {};
  const dispatch = useDispatch();
  const availablePlans = useSelector(getAvailablePlansSelector);
  const availablePlansLoading = availablePlans?.state === 'pending';
  const currentPlan = useSelector(getCurrentPlanLevelSelector);
  const implementationPlan = useSelector(getImplementationPlanSelector);
  const implementationPlanLoading = implementationPlan?.state === 'pending';
  // Test Coupon JOCl9Gdp
  const coupon = useSelector(getStandardCouponSelector);
  const couponLoading = coupon?.state === 'pending';
  const [couponError, setCouponError] = useState('');

  const standardSubscriptionState = useSelector(getStandardSubscriptionSelector);
  const checkoutLoading = standardSubscriptionState?.state === 'pending';

  const standardPlans: GetAvailableSubscriptionPlansResponse[] = useMemo(
    () =>
      availablePlans?.data?.length
        ? availablePlans?.data?.filter(({ Plan }) => Plan === 'standard')
        : null,
    [availablePlans],
  );

  const standardPlan: GetAvailableSubscriptionPlansResponse | null = useMemo(() => {
    if (!standardPlans?.length) return null;
    const nickname = requestedSeatCount >= 3 ? 'team' : 'solo';
    return standardPlans.find((plan) =>
      plan.Prices.some((price) => price.Nickname === nickname),
    ) as GetAvailableSubscriptionPlansResponse;
  }, [requestedSeatCount, standardPlans]);

  const subscriptionPrice = useMemo(() => {
    if (standardPlans?.length) {
      return calculateStandardPrice(requestedSeatCount, standardPlans);
    }
    return 0;
  }, [standardPlans, requestedSeatCount]);

  const implementationPrice = useMemo(() => {
    if (!implementationPlan?.data?.Price) {
      return 0;
    }
    const originalAmount = implementationPlan.data.Price.Amount;
    const percentOff = coupon?.data?.valid && coupon.data.percent_off ? coupon.data.percent_off : 0;
    const amountOff =
      coupon?.data?.valid && coupon.data.amount_off ? coupon.data.amount_off / 100 : 0;

    let finalAmount;
    if (percentOff > 0) {
      finalAmount = originalAmount - (originalAmount * percentOff) / 100;
    } else if (amountOff > 0) {
      finalAmount = originalAmount - amountOff;
    } else {
      finalAmount = originalAmount;
    }

    return finalAmount > 0 ? finalAmount : 0;
  }, [implementationPlan, coupon?.data]);

  const getCoupon = useCallback(
    debounce(async (coupon: string) => {
      const response: any = await dispatch(requestGetCouponEffect({ coupon }));
      if (response.data.result === null && coupon) {
        setCouponError('Invalid Promo Code');
      } else {
        setCouponError('');
      }
    }, 1000),
    [],
  );

  const subscribeStandard = useCallback(
    async (
      paymentMethodId: string,
      planId: string,
      requestedSeatsCount: number,
      activationCoupon?: string,
      cb?: (error?: Error) => void,
    ) =>
      dispatch(
        requestSubscribeStandardEffect(
          {
            paymentMethodId,
            planId,
            requestedSeatsCount,
            activationCoupon,
          },
          cb,
        ),
      ),
    [],
  );

  useEffect(() => {
    dispatch(requestGetAvailableSubscriptionPlansEffect());
    dispatch(requestGetImplementationPlansEffect());
  }, []);

  return {
    getCoupon,
    subscribeStandard,
    availablePlansLoading,
    checkoutLoading,
    couponLoading,
    implementationPlanLoading,
    implementationPrice,
    subscriptionPrice,
    currentPlan,
    availablePlans,
    standardPlan,
    couponError,
  };
}
