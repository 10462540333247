import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { appOpenModalEffect } from 'store/effects/app';
import { getModalSelector } from 'store/selectors/app';
import { Button, Input, Modal } from 'components';

import styles from './styles.module.scss';
import { Plus } from 'components/Icons';
import { map } from 'lodash-es';

const WishlistModal = ({ className, onNext, title }) => {
  const defaultValue = { 0: '', 1: '', 2: '' };
  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();
  const [values, setValues] = useState(defaultValue);

  useEffect(() => {
    if (!modal.open) {
      setValues(defaultValue);
    }
  }, [modal.open]);

  if (modal.id !== WishlistModal.id) {
    return null;
  }

  const onClose = () => {
    setValues(defaultValue);
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  const onSave = () => {
    onNext(values);
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  const length = Object.keys(values)?.length;

  return (
    <Modal
      className={classNames(className)}
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      innerHolderClassName={styles.innerHolder}
    >
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.wrapper}>
        {map(Object.entries(values), ([id, value]) => (
          <Input
            key={id}
            id={id}
            value={value}
            variant={Input.LIGHT_ROUND}
            placeholder="Add a feature here..."
            className={styles.field}
            onChange={(e) => {
              setValues((prev) => ({ ...prev, [id]: e.target.value }));
            }}
          />
        ))}

        {length < 5 && (
          <div
            className={styles.addContainer}
            onClick={() => {
              setValues((prev) => ({ ...prev, [length]: '' }));
            }}
          >
            <div className={styles.add}>
              <Plus color="#747475" />
            </div>
            <p className={styles.label}>Add Something Else</p>
          </div>
        )}

        <div className={styles.bottonContainer}>
          <Button onClick={onClose} className={styles.cancelButton} title="Cancel" />
          <Button onClick={onSave} className={styles.saveButton} title="Save" />
        </div>
      </div>
    </Modal>
  );
};

WishlistModal.id = 'wishlistPrefsModal';

WishlistModal.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
};

WishlistModal.defaultProps = {
  className: '',
  onNext: () => {},
  value: [],
};

export default WishlistModal;
