import { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { CollapsibleTable, WorkshopTableWrapper } from 'pages/Workshop/common';
import { projectTableColumns } from '../tableColumns';

import { LISTED_STATUS, transactionStatuses } from 'settings/constants/transaction';
import { projectsFilters } from 'settings/constants/roles';
import { link } from 'settings/navigation/link';
import { showErrorMessage } from 'helpers';
import { getUserId } from 'store/selectors/user';
import { useSelector } from 'react-redux';
import { validateMembership } from 'utils/workshopHelper';

import styles from './../styles.module.scss';

export const ProjectsTables = ({
  isFiltersApplied,
  isArchive,
  transactions,
  projectFilter,
  userRole,
}) => {
  const [filteredTransactions, setFilteredTransactions] = useState<any>([]);
  const history = useHistory();
  const userId = useSelector(getUserId);

  function applyProjectsFilter(transactions) {
    switch (projectFilter) {
      case projectsFilters.allProjects:
        return transactions;
      case projectsFilters.myProjects:
        return transactions.filter((item) => item.isMyTransaction);
      case projectsFilters.teamProjects:
        return transactions.filter((item) => item.isTeamTransaction && !item.isMyTransaction);
      case projectsFilters.externalProjects:
        return transactions.filter((item) => !item.isTeamTransaction && !item.isMyTransaction);
      default:
        return transactions;
    }
  }

  useEffect(() => {
    if (isArchive) {
      const archievedTransactions = transactions.filter((item) =>
        [transactionStatuses.Closed, transactionStatuses.Canceled, LISTED_STATUS.Expired].includes(
          item.status,
        ),
      );
      setFilteredTransactions(applyProjectsFilter(archievedTransactions));
    } else {
      const activeTransactions = transactions.filter(
        (item) =>
          ![
            transactionStatuses.Closed,
            transactionStatuses.Canceled,
            LISTED_STATUS.Expired,
          ].includes(item.status),
      );
      setFilteredTransactions(applyProjectsFilter(activeTransactions));
    }
  }, [transactions, isArchive, projectFilter]);

  function atRisk() {
    return filteredTransactions.filter((item) => item.status === transactionStatuses.AtRisk);
  }

  function inProgressProjects() {
    return filteredTransactions.filter((item) => item.status === transactionStatuses.InProgress);
  }

  function upcomingProjects() {
    return filteredTransactions.filter((item) => item.status === transactionStatuses.Upcoming);
  }

  function onHoldProjects() {
    return filteredTransactions.filter((item) => item.status === transactionStatuses.OnHold);
  }

  function closedTransactionsTableData() {
    return filteredTransactions.filter((item) => item.status === transactionStatuses.Closed);
  }

  function cancelledTransactionsTableData() {
    return filteredTransactions.filter((item) => item.status === transactionStatuses.Canceled);
  }

  function expiredTransactionsTableData() {
    return filteredTransactions.filter((item) => item.status === LISTED_STATUS.Expired);
  }

  const tableProps = {
    columns: projectTableColumns(isArchive, userRole),
    onRow: (record) => {
      return {
        onClick: (event) => {
          if (validateMembership(record, userId)) {
            history.push(link.toProjectOverview(record?.id));
          }
        },
      };
    },
    tableClassName: styles.transactionsTable,
  };

  return (
    <Fragment>
      {isFiltersApplied ? (
        <WorkshopTableWrapper data={[transactions]} emptyText="No Projects.">
          <CollapsibleTable
            title={`${transactions.length} Projects`}
            dataSource={transactions}
            disableCollapse
            {...tableProps}
          />
        </WorkshopTableWrapper>
      ) : isArchive ? (
        <WorkshopTableWrapper
          data={[closedTransactionsTableData(), cancelledTransactionsTableData()]}
          emptyText="No Projects."
        >
          <CollapsibleTable
            title="Closed"
            defaultCollapse
            dataSource={closedTransactionsTableData()}
            {...tableProps}
          />
          <CollapsibleTable
            title="Cancelled"
            defaultCollapse
            dataSource={cancelledTransactionsTableData()}
            {...tableProps}
          />
          <CollapsibleTable
            title="Expired"
            defaultCollapse
            dataSource={expiredTransactionsTableData()}
            {...tableProps}
          />
        </WorkshopTableWrapper>
      ) : (
        <WorkshopTableWrapper
          data={[atRisk(), inProgressProjects(), upcomingProjects(), onHoldProjects()]}
          emptyText="No Projects."
        >
          <CollapsibleTable title="At Risk" dataSource={atRisk()} {...tableProps} />
          <CollapsibleTable title="In Progress" dataSource={inProgressProjects()} {...tableProps} />
          <CollapsibleTable
            title="Upcoming"
            dataSource={upcomingProjects()}
            {...tableProps}
            defaultCollapse
          />
          <CollapsibleTable
            title="On Hold"
            dataSource={onHoldProjects()}
            {...tableProps}
            defaultCollapse
          />
        </WorkshopTableWrapper>
      )}
    </Fragment>
  );
};
