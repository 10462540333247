import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Grid } from 'antd';

import { AuditLogAvatar, AuditLogItemContent } from 'components-antd/AuditLogCard';

import styles from './styles.module.scss';

interface IAuditLogItem extends UserInfo {
  timestamp: string;
  logItemData: any;
  timelineItemWrapperClassName?: any;
  timelineTimeLabelClassName?: any;
  timelineContentClassName?: any;
  avatarWrapperClassName?: any;
  IsQuoteDrawer?: boolean;
  showActivityTag?: boolean;
  taskCategories?: any[];
}

interface UserInfo {
  name: string;
  imgUrl?: string;
}

const { useBreakpoint } = Grid;

export const AuditLogItem: React.FC<IAuditLogItem> = ({
  timestamp,
  imgUrl,
  name,
  logItemData,
  timelineItemWrapperClassName,
  timelineTimeLabelClassName,
  timelineContentClassName,
  avatarWrapperClassName,
  IsQuoteDrawer,
  showActivityTag,
  taskCategories,
}) => {
  const { lg } = useBreakpoint();
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={classNames(
        styles.timelineItemWrapper,
        timelineItemWrapperClassName,
        expanded ? styles.alignitemsStart : styles.alignitemsCenter,
      )}
    >
      <div className={styles.timelineItemTime}>
        <p className={classNames(styles.timelineTimeLabel, timelineTimeLabelClassName)}>
          {moment(timestamp).format('h:mm A')}
        </p>
      </div>
      <>
        {lg && (
          <>
            <div className={classNames(styles.avatarWrapper, avatarWrapperClassName)}>
              <AuditLogAvatar firstLastName={name} imgUrl={imgUrl} />
            </div>
          </>
        )}
        {!lg && <AuditLogAvatar firstLastName={name} imgUrl={imgUrl} />}
      </>

      <div className={classNames(styles.timelineContent, timelineContentClassName)}>
        <AuditLogItemContent
          expanded={expanded}
          setExpanded={setExpanded}
          itemContent={logItemData}
          IsQuoteDrawer={IsQuoteDrawer}
          showActivityTag={showActivityTag}
          taskCategories={taskCategories}
        />
      </div>
    </div>
  );
};
