import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { intersection } from 'lodash-es';
import classNames from 'classnames';
import { Badge, HamburgerDrawer, HeaderAvatar } from 'components-antd';
import { Messages, Notifications, Clients } from 'components/Icons';
import { getUserRolesSelector } from 'store/selectors/user';
import { getUnreadThreadsCount } from 'store/selectors/sockets/threads';
import { openMessagesDrawerAction } from 'store/actions/drawers/messages';
import { appManageClientDrawerAction } from 'store/actions/app';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { getIsUnreadNotificationsSelector } from 'store/selectors/sockets/notifications';
import { getHeaderConfig } from 'settings/navigation/config/components/header';
import { AGENT } from 'settings/constants/roles';
import styles from './styles.module.scss';
import { VisitorNavLinks } from '../VisitorNavLinks';

interface HeaderActionProps {
  absoluteHeader?: boolean;
}

export const HeaderActions = ({ absoluteHeader }: HeaderActionProps) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const roles = useSelector(getUserRolesSelector);
  const unreadThreadsCount = useSelector(getUnreadThreadsCount);
  const isUnreadNotifications = useSelector(getIsUnreadNotificationsSelector);

  const [hideActions, setHideActions] = useState(false);
  const [hideAvatar, setHideAvatar] = useState(false);
  const [minimizeAvatar, setMinimizeAvatar] = useState(false);
  const [visitor, setVisitor] = useState(false);

  useEffect(() => {
    const config = getHeaderConfig(pathname);
    if (config) {
      setHideActions(!!config.hideActions);
      setHideAvatar(!!config.hideAvatar);
      setMinimizeAvatar(!!config.minimizeAvatar);
      setVisitor(!!config.visitor);
    } else {
      if (hideActions) {
        setHideActions(false);
      }
      if (hideAvatar) {
        setHideAvatar(false);
      }
      if (minimizeAvatar) {
        setMinimizeAvatar(false);
      }
      if (visitor) {
        setVisitor(false);
      }
    }
  }, [pathname]);

  const showClients = useMemo(() => !!intersection([AGENT], roles).length, [roles]);

  const handleMessagesOpen = useCallback(
    () => dispatch(openMessagesDrawerAction(true)),
    [dispatch],
  );
  const handleClientsClick = useCallback(
    () => dispatch(appManageClientDrawerAction(true)),
    [dispatch],
  );
  const handleNotificationsClick = useCallback(() => {
    dispatch(openNotificationsDrawerEffect({ open: true }));
  }, [dispatch]);

  return (
    <div
      data-testid="header-actions"
      className={classNames(styles.wrapper, { [styles.absoluteWrapper]: absoluteHeader })}
    >
      {visitor && hideActions ? (
        <div className={styles.visitorNavActions}>
          <VisitorNavLinks />
        </div>
      ) : (
        <></>
      )}

      {!hideActions && (
        <>
          {showClients && (
            <span
              testid="actions_clients"
              className={classNames(styles.iconElements, styles.actionElements)}
              onClick={handleClientsClick}
            >
              <Clients color="header" className={classNames(styles.icon, styles.clientsIcon)} />
            </span>
          )}
          <span
            testid="actions_messages"
            className={classNames(styles.iconElements, styles.actionElements)}
            onClick={handleMessagesOpen}
          >
            <Badge count={unreadThreadsCount}>
              <Messages className={(styles.icon, styles.messagesIcon)} />
            </Badge>
          </span>
          <span
            testid="actions_notifications"
            className={classNames(styles.iconElements, styles.actionElements)}
            onClick={handleNotificationsClick}
          >
            <Badge dot={isUnreadNotifications}>
              <Notifications className={classNames(styles.icon, styles.notificationIcon)} />
            </Badge>
          </span>
        </>
      )}
      {!hideAvatar && (
        <HeaderAvatar
          avatarNameStyles={styles.headerAvatarName}
          popover={true}
          className={styles.headerAvatar}
          minimizeAvatar={minimizeAvatar}
        />
      )}

      <HamburgerDrawer
        visitor={visitor}
        hideActions={hideActions}
        className={styles.iconElements}
      />
    </div>
  );
};
