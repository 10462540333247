import { MapDrawControls } from 'pages/Properties/components/MapDrawControls/MapDrawControls';
import React from 'react';
import { useSelector } from 'react-redux';
import { MAP_VIEWS } from 'settings/constants/mapView';
import {
  cleanSearchDrawnPolygonAction,
  setSearchDrawnPolygonAction,
  toggleSearchMapDrawingModeAction,
} from 'store/actions/searchResults';
import { getSearchResultsViewTypeSelector } from 'store/selectors/searchResults';
import {
  getSearchDrawnPolygonSelector,
  getSearchIsMapDrawingModeSelector,
} from 'store/selectors/search';

interface IMapDrawControlsSearchWrapper {
  polygonLocations: any;
  setPolygonLocations: any;
  polygonsUpdateFn?: any;
}

export const MapDrawControlsSearchWrapper: React.FC<IMapDrawControlsSearchWrapper> = ({
  polygonLocations,
  setPolygonLocations,
  polygonsUpdateFn,
}) => {
  const viewType = useSelector(getSearchResultsViewTypeSelector);

  if (viewType.view === MAP_VIEWS.LIST) {
    return null;
  }

  return (
    <MapDrawControls
      getDrawnPolygonSelectorFn={getSearchDrawnPolygonSelector}
      getIsMapDrawingModeSelectorFn={getSearchIsMapDrawingModeSelector}
      setDrawnPolygonDispatchFn={setSearchDrawnPolygonAction}
      toggleMapDrawingModeDispatchFn={toggleSearchMapDrawingModeAction}
      cleanDrawnPolygonDispatchFn={cleanSearchDrawnPolygonAction}
      multipleDraw={false}
      polygonLocations={polygonLocations}
      setPolygonLocations={setPolygonLocations}
      polygonsUpdateFn={polygonsUpdateFn}
    />
  );
};
