import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const NoResults = ({ className, text }) => (
  <div className={classNames(styles.wrapper, className)}>
    <div testid="no_results" className={styles.text}>
      {text}
    </div>
  </div>
);

NoResults.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

NoResults.defaultProps = {
  className: '',
  text: 'No Results',
};

export default NoResults;
