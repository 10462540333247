import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components';

import styles from './styles.module.scss';

const Inner = (props) => {
  const { className, onChooseFile, value, onChange, id } = props;
  const { multiple } = props;
  const fileInputRef = useRef();
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    if (!value?.length && fileInputRef?.current) {
      fileInputRef.current.value = null;
    }
  }, [value?.length]);
  return (
    <div className={classNames(styles.inner, className)}>
      <div>
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.834 33.0002C10.5465 33.0002 8.35269 32.131 6.73519 30.5838C5.11769 29.0366 4.20898 26.9382 4.20898 24.7502C4.20898 22.5622 5.11769 20.4637 6.73519 18.9166C8.35269 17.3694 10.5465 16.5002 12.834 16.5002V16.5002C13.3742 14.0934 14.9547 11.9782 17.2277 10.6202C18.3532 9.94772 19.6148 9.48137 20.9406 9.24774C22.2663 9.0141 23.6302 9.01777 24.9544 9.25853C26.2785 9.49929 27.537 9.97242 28.658 10.6509C29.779 11.3294 30.7405 12.2 31.4877 13.2129C32.2349 14.2258 32.7531 15.3613 33.0126 16.5545C33.2722 17.7476 33.2682 18.9751 33.0007 20.1669H34.834C36.5358 20.1669 38.1679 20.8429 39.3713 22.0463C40.5746 23.2496 41.2507 24.8817 41.2507 26.5835C41.2507 28.2853 40.5746 29.9174 39.3713 31.1208C38.1679 32.3241 36.5358 33.0002 34.834 33.0002H33.0007"
            stroke="#ADADAD"
            strokeWidth="2.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 27.5L22 22L27.5 27.5"
            stroke="#ADADAD"
            strokeWidth="2.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 22V38.5"
            stroke="#ADADAD"
            strokeWidth="2.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={classNames(styles.text, styles.main)}>Drag and drop files here</div>
      <div className={classNames(styles.text)}>or</div>
      <Button onClick={handleButtonClick} className={styles.chooseFileBtn} title="Choose File" />
      <input
        ref={fileInputRef}
        multiple={multiple}
        type="file"
        onChange={onChange}
        className={styles.nativeFileInput}
        id={id}
        testid="attachment_input"
      />
    </div>
  );
};

Inner.propTypes = {
  className: PropTypes.string,
  onChooseFile: PropTypes.func,
};

Inner.defaultProps = {
  className: '',
  onChooseFile: () => {},
  onChange: () => {},
};

export default Inner;
