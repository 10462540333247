import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Archive as ArchiveIcon } from 'components/Icons';
import styles from './styles.module.scss';

const Archive = (props) => {
  const { className, onClick, label } = props;

  return (
    <div onClick={onClick} className={classNames(styles.archive, className)}>
      <ArchiveIcon className={styles.icon} />
      <span>{label}</span>
    </div>
  );
};

Archive.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

Archive.defaultProps = {
  className: '',
  onClick: () => {},
  label: 'Archive',
};

export default Archive;
