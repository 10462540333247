import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { mapNotificationsTitles } from 'settings/constants/notifications';
import styles from './styles.module.scss';

const ItemTitle = (props) => {
  const { className, variant } = props;

  const { Title, Subtitle } = mapNotificationsTitles[variant] || {};

  return (
    <div className={classNames(styles.itemTitle, className)}>
      <div className={styles.title}>{Title}</div>
      <div className={styles.subtitle}>{Subtitle}</div>
    </div>
  );
};

ItemTitle.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
};

ItemTitle.defaultProps = {
  className: '',
  variant: '',
};

export default ItemTitle;
