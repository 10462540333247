import { CardActions, TourSort, TourStatus, TeamFilter } from 'app-constants/enums/tours';
import { convertNameToAvatarPlaceholder, copyToClipBoardAsync, showSuccessMessage } from 'helpers';
import moment from 'moment';
import {
  getGroupedToursByClient,
  getGroupedToursByDate,
  getGroupedToursByAgent,
} from 'store/selectors/tour';
import { Tour } from 'types/tours';

export const getAgentName = (tour: Tour) => {
  if (!tour) {
    return '';
  }
  const agents: string[] = [];
  (tour.TourAssignee || []).forEach((assignee) => {
    agents.push(`${assignee.Agent.FirstName} ${assignee.Agent.LastName}`);
  });

  return agents.join(', ');
};
export const getClientName = (tour: Tour) => {
  if (!tour) {
    return '';
  }
  return `${tour.Creator.FirstName} ${tour.Creator.LastName}`;
};
export const getFormattedDateTime = (date, format) => {
  if (!date) {
    return '';
  }
  return moment(date).format(format);
};
export const getTimeDifference = (date) => {
  const duration = moment.duration(moment().diff(moment(date)));

  const months = Math.floor(duration.asMonths());
  duration.subtract(months, 'months');

  const weeks = Math.floor(duration.asWeeks());
  duration.subtract(weeks, 'weeks');

  const days = Math.floor(duration.asDays());
  duration.subtract(days, 'days');

  const hours = Math.floor(duration.asHours());
  duration.subtract(hours, 'hours');

  let displayText = '';
  if (months > 0) {
    displayText += `${months} month${months !== 1 ? 's ' : ' '}`;
  } else if (weeks > 0) {
    displayText += `${weeks} week${weeks !== 1 ? 's ' : ' '}`;
  } else if (days > 0) {
    displayText += `${days} day${days !== 1 ? 's ' : ' '}`;
  } else if (hours > 0) {
    displayText += `${hours} hour${hours !== 1 ? 's' : ' '}`;
  } else {
    displayText += duration.humanize();
  }
  return displayText ? displayText.trim() : '0 hour';
};
export const getHeaderText = (tours: Tour[], sort) => {
  switch (sort) {
    case TourSort.ClientName:
      return `${tours[0].Creator.FirstName} ${tours[0].Creator.LastName}`;
    case TourSort.AgentName: {
      const agentId = tours[0].AgentId;
      const tourAssignee = tours[0].TourAssignee.find((i) => i.AssigneeId === agentId);
      return tourAssignee && `${tourAssignee.Agent.FirstName} ${tourAssignee.Agent.LastName}`;
    }
    case TourSort.Date:
      return getFormattedDate(tours[0].ScheduleDateTime);
  }
};

export const getUser = (tours: Tour[], sort) => {
  switch (sort) {
    case TourSort.ClientName:
      return tours[0].Creator;

    case TourSort.AgentName: {
      const agentId = tours[0].AgentId;
      const tourAssignee = tours[0].TourAssignee.find((i) => i.AssigneeId === agentId);
      return tourAssignee && tourAssignee.Agent;
    }
  }
};
export const getTime = (date) => {
  return (
    date &&
    new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  );
};
export const getFormattedDate = (date) => {
  if (!date) {
    return 'Unscheduled';
  }
  return new Date(date).toLocaleDateString('en-us', {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  });
};

export const fetchTours = (sort: TourSort, filter: TourStatus, teamFilter: TeamFilter) => {
  switch (sort) {
    case TourSort.ClientName:
      return getGroupedToursByClient(filter);
    case TourSort.AgentName:
      return getGroupedToursByAgent(filter, teamFilter);
    case TourSort.Date:
      return getGroupedToursByDate(filter);
  }
};

export const getNotesCount = (tour: Tour) => {
  return tour.ClientNotes && tour.PrivateNotes ? 2 : tour.ClientNotes || tour.PrivateNotes ? 1 : 0;
};
export const copyTextToClipBoard = async (tours: Tour[], type: CardActions) => {
  let text;
  switch (type) {
    case CardActions.CopyAddress:
      text = tours
        .map((tour) => {
          return `${tour.PropertyAddress.Line1} ${tour.PropertyAddress.Line2 || ''} ${
            tour.PropertyAddress.City || ''
          } ${tour.PropertyAddress.State || ''}${tour.PropertyAddress.Zip || ''}`;
        })
        .join(', ');
      break;
    case CardActions.CopyMLS:
      text = tours.map((tour) => tour.PropertyId).join(', ');
      break;
  }
  await copyToClipBoardAsync(text);
  showSuccessMessage('Copied');
};

export const getBrowserTimezoneName = () => {
  const datetime = new Date(moment.tz(moment.tz.guess()).toISOString());
  const regex = /\(([^)]+)\)/;
  const match = regex.exec(datetime.toString()) || '';
  return `${match[1]}  (${moment().tz(moment.tz.guess()).format('z')})`;
};
