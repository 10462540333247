import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';
import {
  getTransactionEffect,
  updateTransactionParticipantsEffect,
} from 'store/effects/transactions';

import Icon from 'pages/Workshop/Transactions/TransactionOverview/Icons';
import { RemoveFile } from 'components/Icons';
import { Input } from 'components';
import { Button } from 'components-antd';

import styles from './styles.module.scss';
import transactionViewStyles from './../TransactionView/styles.module.scss';

export const NamesArray = ({ label, keyToUpdate, namesArray, isDisableField, setEditId }) => {
  const params: { id?: string } = useParams();
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [isEdit, setIsEdit] = useState(false);
  const [arrayInputFields, setArrayInputFields] = useState(['']);

  useEffect(() => {
    setArrayInputFields(namesArray && namesArray?.length ? namesArray : ['']);
  }, [namesArray]);

  const handleChange = (index, event) => {
    const newArray = [...arrayInputFields];
    newArray[index] = event.target.value;
    setArrayInputFields(newArray);
  };

  const handleRemoveField = (index) => {
    const newArray = [...arrayInputFields];
    newArray.splice(index, 1);
    setArrayInputFields(newArray);
  };

  const handleAddField = () => {
    setArrayInputFields([...arrayInputFields, '']);
  };

  const onEdit = () => {
    if (!fullAccess || isDisableField(keyToUpdate)) return null;
    setIsEdit(true);
    setEditId(keyToUpdate);
  };

  const updateTransaction = () => {
    setIsPending(true);

    dispatch(
      updateTransactionParticipantsEffect(
        {
          id: transaction?.Id,
          [keyToUpdate]: arrayInputFields.filter((item) => item.trim() !== ''),
        },
        (err) => {
          if (!err) {
            setIsEdit(false);
            dispatch(getTransactionEffect({ id: params?.id }, { silent: true }));
            setEditId(null);
          }
          setIsPending(false);
        },
      ),
    );
  };

  const onCancel = () => {
    setArrayInputFields(namesArray ?? ['']);
    setIsEdit(false);
    setEditId(null);
  };

  return (
    <>
      {!isEdit ? (
        <div
          className={classNames(
            styles.clientNames,
            { [transactionViewStyles.fullAccess]: fullAccess },
            { [transactionViewStyles.disableField]: !!isDisableField(keyToUpdate) },
          )}
          onClick={onEdit}
        >
          <div>
            <div>{label}:</div>
            <div className={styles.names}>
              {namesArray &&
                namesArray.map((item, i) => (
                  <div key={i} className={styles.value}>
                    {item}
                  </div>
                ))}
            </div>
          </div>
          {fullAccess && !isDisableField(keyToUpdate) && (
            <Icon testid="edit_icon" className={styles.editIcon} variant={Icon.EDIT} />
          )}
        </div>
      ) : (
        <div className={transactionViewStyles.editWrapper}>
          <div className={styles.clientNameContainer}>
            <div className={styles.header}>
              <span>{label}:</span>
              <Icon variant={Icon.PLUS_BG} onClick={() => handleAddField()} />
            </div>
            {arrayInputFields &&
              arrayInputFields.map((value, index) => (
                <div key={index} className={styles.clientNameInput}>
                  <Input
                    placeholder="Names (e.g. John, Doe)"
                    variant="lightRound"
                    value={value}
                    onChange={(e) => handleChange(index, e)}
                    disabled={isPending}
                  />
                  <RemoveFile
                    className={styles.removeClientName}
                    onClick={() => handleRemoveField(index)}
                  />
                </div>
              ))}
          </div>
          <div className={transactionViewStyles.buttons}>
            <Button
              variant="secondary"
              size="large"
              disabled={isPending}
              onClick={updateTransaction}
              loading={isPending}
            >
              Update
            </Button>
            <Button variant="hollow-bordered" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default NamesArray;
