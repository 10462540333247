export enum TemplateTriggerType {
  Property_Attribute_Property_Type = 'Property_Attribute_Property_Type',
  Property_Attribute_Year_Built = 'Property_Attribute_Year_Built',
  Property_Attribute_City = 'Property_Attribute_City',
  Property_Attribute_County = 'Property_Attribute_County',
  Property_Attribute_State = 'Property_Attribute_State',
  Property_Attribute_Stories = 'Property_Attribute_Stories',

  Transaction_Attribute_Status_Changes = 'Transaction_Attribute_Status_Changes',
  Transaction_Attribute_Financing_Type = 'Transaction_Attribute_Financing_Type',

  Task_Assigned = 'Task_Assigned',
  Task_Stuck = 'Task_Stuck',
  Task_Overdue = 'Task_Overdue',
  Task_Complete = 'Task_Complete',

  Form_Assigned = 'Form_Assigned',
  Form_Removed = 'Form_Removed',
  Form_Complete = 'Form_Complete',

  Milestone_Added = 'Milestone_Added',
  Milestone_Complete = 'Milestone_Complete',
  Milestone_Overdue = 'Milestone_Overdue',
  Milestone_Removed = 'Milestone_Removed',

  Days_Contract_Date = 'Days_Contract_Date',
  Days_Transaction_Creation_Date = 'Days_Transaction_Creation_Date',
}

export enum TriggerComparisonType {
  EQUAL = '=',
  LESSER = '<',
  GREATER = '>',
  LESSER_EQUAL = '<=',
  GREATER_EQUAL = '>=',
  NOT_EQUAL = '!=',
  NOT_GREATER_EQUAL = '>/=',
  NOT_LESSER_EQUAL = '</= ',
}

export enum TriggerOperatorAfterType {
  AND = 'AND',
  OR = 'OR',
}

export interface TemplateTrigger {
  Id?: number;
  TemplateRuleId?: number;

  Type: TemplateTriggerType;
  EntityId?: string;
  Value?: string[];
  Comparison?: TriggerComparisonType;
  Order?: number;
  Precedence?: number;
  OperatorAfter?: TriggerOperatorAfterType;
}
