import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Phone } from 'components';
import { useFormik } from 'formik';
import { onBoardingSignUpEffect } from 'store/effects/onBoarding';

import { PENDING } from 'settings/constants/apiState';

import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { CLIENT, THIRD_PARTY } from 'settings/constants/roles';

import { getUserDataSelector, isPartnerSelector } from 'store/selectors/user';

import { ValidationSchemaThirdPartyPartner } from '../../AboutForm/validation';

import { Col, Row } from 'components-antd';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnBoardingContainer } from '../../../OnBoardingContainer';
import { OnBoardingWrapper } from '../../../OnBoardingWrapper';

import { OnboardingContinueButton } from '../../agent/components/OnboardingContinueButton';
import styles from './styles.module.scss';
import React from 'react';

const initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  bio: '',
  email: '',
};

type Props = {
  onNext: () => {};
  stageIndex?: number;
  Controls: React.ElementType;
};

export const PartnerAboutForm: React.FC<Props> = ({ onNext, stageIndex, Controls }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const { onBoarding, signUpRole } = useSelector(getOnBoardingData);
  const isPartner = useSelector(isPartnerSelector);

  const onSubmit = (values) => {
    dispatch(
      onBoardingSignUpEffect({ ...values, stageIndex }, { isCommonInfo: true }, (err) => {
        if (!err) {
          onNext();
        }
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      firstName: (user?.FirstName as string) || initialValues.firstName,
      lastName: (user?.LastName as string) || initialValues.lastName,
      phone: (user?.Phones?.[0]?.PhoneNumber as string) || initialValues.phone,
      bio: (user?.[signUpRole]?.Bio as string) || initialValues.bio,
      email: (user?.Email as string) || initialValues.email,
    },
    validationSchema: ValidationSchemaThirdPartyPartner(),
    validateOnChange: true,
    onSubmit,
  });

  const isPending = onBoarding.state === PENDING;

  return (
    <OnBoardingWrapper className={styles.stageWrap}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question className={styles.partnerQuestion}>
          <span>Please confirm your contact information</span>
        </Question>
        <AnswersContainer data-testid="about_yourself">
          <form onSubmit={formik.handleSubmit}>
            <Row className={classNames(styles.row, styles.partnerRow)} gutter={[16, 12]}>
              <Col xs={24} md={24} className={styles.textCol}>
                <span className={classNames(styles.text, styles.partnerHeading)}>My name is</span>
              </Col>
              <Col xs={24} md={24}>
                <Row gutter={[16, 0]}>
                  <Col xs={24} md={12}>
                    <Input
                      className={classNames(styles.formInput, styles.partnerFormInput)}
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      placeholder="First Name"
                      error={formik.touched.firstName ? formik.errors.firstName : ''}
                      disabled={isPending}
                      testid="first_name"
                      variant={Input.LIGHT}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Input
                      className={classNames(styles.formInput, styles.partnerFormInput)}
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      placeholder="Last Name"
                      error={formik.touched.lastName ? formik.errors.lastName : ''}
                      disabled={isPending}
                      testid="last_name"
                      variant={Input.LIGHT}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className={classNames(styles.row, styles.partnerRow)} gutter={[16, 12]}>
              <Col xs={24} md={24} className={styles.textCol}>
                {(signUpRole === THIRD_PARTY || signUpRole === CLIENT) && (
                  <span className={classNames(styles.text, styles.partnerHeading)}>
                    My email address is
                  </span>
                )}
              </Col>
              <Col xs={24}>
                <div className={styles.emailRow}>
                  <Input
                    className={classNames(styles.formInput, styles.partnerFormInput)}
                    type="text"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    placeholder="example@domain.com"
                    error={formik.touched.email ? formik.errors.email : ''}
                    disabled={isPending}
                    testid="email"
                    variant={Input.LIGHT}
                  />
                </div>
              </Col>
            </Row>
            <Row className={classNames(styles.row, styles.partnerRow)} gutter={[16, 12]}>
              <Col xs={24} md={isPartner ? 24 : 11} className={styles.textCol}>
                {(signUpRole === THIRD_PARTY || signUpRole === CLIENT) && (
                  <span className={classNames(styles.text, styles.partnerHeading)}>
                    My phone number is
                  </span>
                )}
              </Col>
              <Col xs={24} md={24}>
                <Phone
                  className={classNames(
                    styles.formInput,
                    styles.partnerFormInput,
                    styles.partnerPhoneWrapper,
                  )}
                  inputClassName={styles.partnerPhoneWrapper}
                  name="phone"
                  onChange={(event, value, country) => {
                    formik.setFieldValue('phone', value);
                  }}
                  error={formik.errors.phone}
                  disabled={isPending}
                  variant={Phone.LIGHT}
                  value={formik.values.phone}
                  testid="phone"
                  placeholder="(---) --- ----"
                />
              </Col>
            </Row>
            <Row>
              <div className={styles.sideNote}>
                <span>
                  This is the contact information we will use to send you any notifications you opt
                  in to.
                </span>
              </div>
            </Row>
          </form>
        </AnswersContainer>
        <ButtonsContainer buttonsContainerClassName={classNames(styles.partnerbtnContainer)}>
          <OnboardingContinueButton
            htmlType="submit"
            testid="next"
            isPending={isPending}
            className={classNames(styles.submit, {
              [styles.active]: formik.isValid,
            })}
            disabled={!formik.isValid}
            onClick={() => formik.submitForm()}
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};
