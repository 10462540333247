import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Checkbox } from 'components';
import { updateNotificationsSettingsEffect } from 'store/effects';

const DailySummary = (props) => {
  const { className, labelClassName, labelTextClassName, value } = props;
  const { groupType, type, variant } = props;

  const dispatch = useDispatch();

  const [dailySummaryCheckedValue, setDailySummaryCheckedValue] = useState(value);
  const [isPending, setIsPending] = useState(false);

  const onChangeDailySummaryValue = (e, val, isChecked) => {
    setIsPending(true);
    const config = { groupType, type, variant, value: { DailySummary: isChecked } };
    dispatch(
      updateNotificationsSettingsEffect(config, {}, (err) => {
        if (!err) {
          setDailySummaryCheckedValue(isChecked);
        }
        setIsPending(false);
      }),
    );
  };

  return (
    <Checkbox
      className={className}
      labelClassName={labelClassName}
      label="Daily summary"
      labelTextClassName={labelTextClassName}
      checked={dailySummaryCheckedValue}
      onChange={onChangeDailySummaryValue}
      isPending={isPending}
      testid="daily_summary"
    />
  );
};

DailySummary.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  labelTextClassName: PropTypes.string,
  value: PropTypes.bool,
  groupType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};

DailySummary.defaultProps = {
  className: '',
  labelClassName: '',
  labelTextClassName: '',
  value: false,
};

export default DailySummary;
