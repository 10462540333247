import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import stylesForm from '../LoginGroup/Container/styles.module.scss';

const FormWrapper = ({ className, children, alignCenter }) => (
  <div
    className={classNames(styles.formWrapper, stylesForm.formWrapper, className, {
      [styles.alignCenter]: alignCenter,
    })}
  >
    {children}
  </div>
);

FormWrapper.propTypes = {
  className: PropTypes.string,
  alignCenter: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FormWrapper.defaultProps = {
  className: '',
  alignCenter: true,
};

export default FormWrapper;
