import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Back } from 'components';

import { useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getPreFormSelector } from 'store/selectors/requestQuote';
import { preFormQuestionsIds } from 'settings/constants/preForm';
import { QuoteModal } from 'pages/RequestQuote/components';
import Content from './Content';
import UserInfo from './UserInfo';

import styles from './styles.module.scss';

const FavoritesModal = (props) => {
  const { className, isOpen, onClose } = props;
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const [client, setClient] = useState(null);
  const preForm = useSelector(getPreFormSelector);

  useEffect(() => {
    if (!isOpen) {
      setClient(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (preForm[preFormQuestionsIds.property]) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preForm[preFormQuestionsIds.property]]);

  if (!isOpen) return null;

  const onBuyerClick = (id, firstName, lastName) => {
    setClient({ id, firstName, lastName });
  };

  const getTitle = () => {
    if (client?.id) {
      return <Back text=" " className={styles.back} onBack={() => setClient(null)} />;
    }
    if (isAgent) {
      return 'Client Favorites';
    }

    return 'My Favorites';
  };

  return (
    <QuoteModal
      testid="favorites_modal"
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      title={getTitle()}
      header={client?.id && <UserInfo clientId={client?.id} />}
    >
      <Content onBuyerClick={onBuyerClick} client={client} />
    </QuoteModal>
  );
};

FavoritesModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

FavoritesModal.defaultProps = {
  className: '',
  isOpen: false,
};

export default FavoritesModal;
