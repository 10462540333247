import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { getUserAgentsSelector, getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { Participants, SendMessageForm, Wrapper as PendingWrapper } from 'components';
import {
  editMessageEffect,
  sendNewMessageEffect,
  socketsGetMessagesByThreadIdEffect,
} from 'store/effects/sockets/messages';
import { createNewThreadEffect } from 'store/effects/sockets/threads';
import { SOCKET_THREAD_TYPES, SOCKET_MESSAGE_TYPES } from 'settings/constants/sockets';

import { changeMessagesDrawerTypeAction } from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';

import { getIfExistThread } from 'helpers/threads';
import { getThreadsListSelector } from 'store/selectors/sockets/threads';
import { getMessagesDrawerParams } from 'store/selectors/drawers/messages';
import PaddingWrapper from '../../PaddingWrapper';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { requestGetTeamListEffect } from 'store/effects';

import styles from './styles.module.scss';
import { requestGetUserParticipantsListEffect } from 'store/effects/drawers/addParticipants';

const NewMessageContent = ({ className }) => {
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const threads = useSelector(getThreadsListSelector);
  const userId = useSelector(getUserId);
  const agents = useSelector(getUserAgentsSelector);
  const { participants, documentVaultUUID, ...params } = useSelector(getMessagesDrawerParams);
  const dispatch = useDispatch();

  const [isThreadCreating, setIsThreadCreating] = useState(false);
  const [participantsValue, setParticipantsValue] = useState(participants);
  const [subject, setSubject] = useState('');

  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);

  useEffect(() => {
    if (isTeamAgentActive) {
      dispatch(requestGetTeamListEffect());
    }
  }, []);

  useEffect(() => {
    dispatch(requestGetUserParticipantsListEffect({}, { silent: true }));
  }, []);

  useEffect(() => {
    setParticipantsValue(participants);
  }, [participants]);

  const onDefaultAttachmentUpload = () => {
    // removing documentVaultUUID from redux params
    dispatch(
      changeMessagesDrawerTypeAction({
        params: {
          participants,
          ...params,
        },
      }),
    );
  };

  const onSendMessage = useCallback(
    (message, attachments, tags, messageId, threadId) => {
      if (messageId) {
        return dispatch(
          editMessageEffect({
            MessageId: messageId,
            Text: message,
            Attachments: attachments,
            MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
            MessageMeta: { Tags: tags },
          }),
        );
      }

      return dispatch(
        sendNewMessageEffect({
          ThreadId: threadId,
          Text: message,
          Attachments: attachments,
          MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
          MessageMeta: { Tags: tags },
        }),
      );
    },
    [dispatch],
  );

  const onCreateThreadAndSendNewMessage = (message, attachments, tags) => {
    if (!participantsValue.length) return null;
    const participantsIds = (participantsValue || []).map(({ id }) => id);
    const existThread = getIfExistThread(
      threads,
      [...participantsIds, userId],
      (thread) => thread?.Type === SOCKET_THREAD_TYPES.CHAT,
    );

    if (existThread) {
      onSendMessage(message, attachments, tags, null, existThread?.Id);
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.CHAT,
          params: { threadId: existThread?.Id },
        }),
      );
      return dispatch(socketsGetMessagesByThreadIdEffect({ Id: existThread?.Id }));
    }

    const config = {
      Type: SOCKET_THREAD_TYPES.CHAT,
      TopicName: subject,
      ParticipantIds: participantsIds,
      FirstMessage: {
        Text: message,
        Attachments: attachments,
        MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
        MessageMeta: { Tags: tags },
      },
    };

    setIsThreadCreating(true);
    dispatch(
      createNewThreadEffect(config, {}, (err, data) => {
        dispatch(
          changeMessagesDrawerTypeAction({
            type: DRAWER_MESSAGES_TYPES.CHAT,
            params: { threadId: data?.Id },
          }),
        );
        setIsThreadCreating(false);
      }),
    );
  };

  const onChangeParticipants = (partic) => {
    setParticipantsValue(partic);
  };

  const onSubjectChange = (sub) => {
    setSubject(sub);
  };

  return (
    <SendMessageForm
      isNewMessage
      id="messagesDrawer"
      className={className}
      onSend={onCreateThreadAndSendNewMessage}
      participantsCount={participantsValue?.length}
      subjectSetter={onSubjectChange}
      subheader={
        <PaddingWrapper
          className={classNames(styles.subheaderWrapper, { [styles.isClient]: !isAgent })}
        >
          <Participants
            onChange={onChangeParticipants}
            value={participantsValue}
            prefixClassName={styles.prefixParticipants}
            valuesClassName={styles.valuesClassName}
          />
        </PaddingWrapper>
      }
      content={
        <PendingWrapper className={styles.threadCreationSpinner} isPending={isThreadCreating} />
      }
      documentVaultUUID={documentVaultUUID}
      onDefaultAttachmentUpload={onDefaultAttachmentUpload}
    />
  );
};

NewMessageContent.propTypes = {
  className: PropTypes.string,
};

NewMessageContent.defaultProps = {
  className: '',
};

export default NewMessageContent;
