import { Button } from 'components-antd';
import { useHistory } from 'react-router-dom';

import Icon from 'pages/Properties/Feed/Icons';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';

export const CreateSearch = () => {
  const history = useHistory();

  const createSearchHandler = () => {
    history.push(routes.mySearches);
  };

  return (
    <div className={styles.updatePrefsBlock}>
      <h2 className={styles.updatePrefsHeading}>Update your preferences</h2>
      <span className={styles.updatePrefsText}>
        In order for us to show you properties, we need a little more information about your
        preferences.
      </span>
      <Button variant="primary" className={styles.criteriaBtn} onClick={createSearchHandler}>
        <Icon variant={Icon.FILTER} className={styles.filterIcon} />
        <span className={styles.btnText}>Create a search</span>
      </Button>
    </div>
  );
};
