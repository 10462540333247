import Api from '../core/api';
import { getAllOfferBuyingAgentsAction } from 'store/actions/offerBuyingAgents';

import { getAllOfferBuyingAgents } from 'api/offers';

export const getAllOfferBuyingAgentsEffect = () => {
  const sendRequest = Api.execBase({
    action: getAllOfferBuyingAgentsAction,
    method: getAllOfferBuyingAgents,
  });
  return sendRequest();
};
