import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Option = (props) => {
  const { className, children, onClick, testid } = props;

  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.option, className)}>
      {children}
    </div>
  );
};

Option.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Option.defaultProps = {
  className: '',
  testid: undefined,
  onClick: () => {},
};

export default Option;
