import {
  deleteServiceTag,
  getListOfServiceTags,
  getServiceTagById,
  postServiceTagData,
  putServiceTagData,
} from 'api/admin';
import {
  requestDeleteServiceTagAction,
  requestGetServiceTagsAction,
  searchServiceTagsAction,
  sortServiceTagsAction,
  requestGetServiceTagByIdAction,
  requestPostServiceTagAction,
  requestUpdateServiceTagAction,
} from 'store/actions/adminPanel';
import { getAllItems, getServiceTagSendData, getItemById } from 'store/effects/adminPanel/helpers';
import Api from 'store/effects/core/api';
import { getState } from 'store/index';

export const getServiceTagsEffect = (cfg = {}, options = {}, cb) => {
  const {
    adminPanel: {
      serviceDirectory: { serviceTags },
    },
  } = getState();
  const { sort, search } = serviceTags;
  return getAllItems(
    requestGetServiceTagsAction,
    getListOfServiceTags,
    options,
    cb,
    sort,
    cfg,
    search,
  );
};

export const searchServiceTagsEffect = (cfg) => (dispatch) => {
  dispatch(searchServiceTagsAction(cfg));
  return dispatch(getServiceTagsEffect());
};

export const sortServiceTagsEffect = (cfg) => (dispatch) => {
  dispatch(sortServiceTagsAction(cfg));
  return dispatch(getServiceTagsEffect());
};

export const deleteServiceTagEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestDeleteServiceTagAction,
    method: deleteServiceTag,
  });

  return sendRequest(cfg, {}, (err, response, dispatch) => {
    if (!err) {
      dispatch(getServiceTagsEffect());
    }
    cb(err, response, dispatch);
  });
};

export const requestGetServiceTagByIdEffect = (cfg, cb) =>
  getItemById(requestGetServiceTagByIdAction, getServiceTagById, cfg, {}, cb);

export const updateServiceTagEffect = ({ values, id }, cb) => {
  const sendRequest = Api.execBase({
    action: requestUpdateServiceTagAction,
    method: putServiceTagData,
  });
  const sendValues = getServiceTagSendData(values, true);

  return sendRequest(sendValues, { id }, (err, response, dispatch) => {
    if (!err) {
      dispatch(requestGetServiceTagByIdEffect({ id }));
      dispatch(getServiceTagsEffect());
    }
    cb(err, response, dispatch);
  });
};

export const createServiceTagEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestPostServiceTagAction,
    method: postServiceTagData,
  });
  const sendValues = getServiceTagSendData(cfg);

  return sendRequest(sendValues, {}, (err, response, dispatch) => {
    if (!err) {
      dispatch(getServiceTagsEffect());
    }
    cb(err, response, dispatch);
  });
};
