import { Icons } from 'pages/Workshop/Activity/Icons';

import { SearchBar } from './SearchBar';
import { FilterPopover } from './FilterPopover';

import styles from './styles.module.scss';

export const Header = (props) => (
  <header className={styles.headerContainer}>
    <SearchBar {...props} />
    <FilterPopover {...props} />
  </header>
);
