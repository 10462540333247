import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UsersMessage } from 'components';
import { ArrowRight, ListingHome } from 'components/Icons';

import styles from './styles.module.scss';

const TransactionsMessages = (props) => {
  const { topicName, unreadNumber, threads, archived, ...rest } = props;

  return threads.length === 1 ? (
    <UsersMessage thread={threads[0]} {...rest} />
  ) : (
    <div
      testid="thread_item"
      className={classNames(styles.item, { [styles.unread]: !!unreadNumber })}
      {...rest}
    >
      <div className={styles.itemImage}>
        <ListingHome className={styles.listingHomeIcon} />
      </div>
      <div className={styles.itemContent}>
        <div className={styles.itemContentTitle}>
          <div testid="item_title" className={styles.itemContentTitleName}>
            {topicName}
          </div>
        </div>
        <div testid="item_subtitle" className={styles.itemContentText}>
          <span>{`${threads.length}${archived ? ' archived' : ''} conversations`}</span>
          {!!unreadNumber && (
            <span className={styles.itemContentTextUnread}>{`, ${unreadNumber} unread`}</span>
          )}
        </div>
      </div>
      <div className={styles.itemRight}>
        <ArrowRight className={styles.itemRightIcon} />
      </div>
    </div>
  );
};

TransactionsMessages.propTypes = {
  topicName: PropTypes.string,
  threads: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  unreadNumber: PropTypes.number.isRequired,
  archived: PropTypes.bool,
};

TransactionsMessages.defaultProps = {
  archived: false,
  topicName: '',
};

export default TransactionsMessages;
