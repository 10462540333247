import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getSimpleDate } from 'helpers';
import styles from './styles.module.scss';

const Subtitle = (props) => {
  const { className, category, dueDate } = props;

  return (
    <div className={classNames(styles.subtitle, className)}>
      {category?.Name && (
        <span className={styles.item}>
          <span testid="category_name" className={styles.category}>
            {category?.Name}
          </span>
        </span>
      )}
      {dueDate && (
        <span className={styles.item}>
          {/* <span className={styles.separator} /> */}
          <span testid="due_date" className={styles.dueDate}>{`Due Date: ${getSimpleDate(
            dueDate,
            'MM/DD/YY',
          )}`}</span>
        </span>
      )}
    </div>
  );
};

Subtitle.propTypes = {
  className: PropTypes.string,
  dueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  category: PropTypes.shape({
    Name: PropTypes.string,
  }),
};

Subtitle.defaultProps = {
  className: '',
  dueDate: null,
  category: null,
};

export default Subtitle;
