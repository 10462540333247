import { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Notes from '../Notes';
import { Modal } from 'components-antd';

import styles from './styles.module.scss';

const NotesModal = (props) => {
  const { className, handleCloseNotesModal, handleNewNotes } = props;

  return (
    <Modal
      open={true}
      width={675}
      footer={null}
      onCancel={handleCloseNotesModal}
      maskClosable={false}
      destroyOnClose
      className={classNames(styles.notesModal, className)}
    >
      <p className={styles.heading}>
        <span>Notes</span>
      </p>
      <Notes isModal={true} />
    </Modal>
  );
};

NotesModal.propTypes = {
  className: PropTypes.string,
  handleCloseNotesModal: PropTypes.func,
  handleNewNotes: PropTypes.func,
};

NotesModal.defaultProps = {
  className: '',
  onCloseModal: () => {},
};

export default NotesModal;
