import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOutsideClick } from 'hooks';
import Picker from 'emoji-picker-react';

import Icon from '../Icons';

import styles from './styles.module.scss';

const Emoji = (props) => {
  const { className, onEmojiClick, open, setOpen } = props;

  const iconRef = useRef();
  const emojiRef = useRef();

  useOutsideClick([iconRef, emojiRef], () => setOpen(false));

  const onOpenEmoji = () => {
    setOpen(!open);
  };

  return (
    <div className={classNames(styles.emoji, className)}>
      <div ref={iconRef} onClick={onOpenEmoji} className={styles.iconWrapper}>
        <Icon testid="emoji_icon" className={styles.icon} variant={Icon.SMILE} />
      </div>
      {open && (
        <div testid="emoji_picker" ref={emojiRef} className={styles.emojiWrapper}>
          <Picker onEmojiClick={onEmojiClick} />
        </div>
      )}
    </div>
  );
};

Emoji.propTypes = {
  className: PropTypes.string,
  onEmojiClick: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

Emoji.defaultProps = {
  className: '',
  onEmojiClick: () => {},
  open: false,
  setOpen: () => {},
};

export default Emoji;
