import { Avatar } from 'components';
import styles from './styles.module.scss';
import { Icons } from 'pages/Workshop/Icons';
import { convertNameToAvatarPlaceholder } from 'helpers';
import PropTypes from 'prop-types';

export const ShowingDetails = ({
  label,
  title,
  icon,
  description,
  FirstName,
  avatarSrc,
  showAvatar,
}) => {
  return (
    <div className={styles.header}>
      <h3>{label}</h3>
      <div className={styles.heading}>
        {showAvatar ? (
          <Avatar
            className={styles.avatarWrapper}
            avatarClassName={styles.avatar}
            src={avatarSrc}
            placeholder={convertNameToAvatarPlaceholder(`${FirstName}`)}
          />
        ) : (
          <Icons className={styles.condoIcon} variant={icon} />
        )}
        <div className={styles.address}>
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
    </div>
  );
};

ShowingDetails.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  FirstName: PropTypes.string,
  showAvatar: PropTypes.bool,
  avatarSrc: PropTypes.string,
};

ShowingDetails.defaultProps = {
  label: '',
  title: '',
  icon: '',
  description: '',
  FirstName: '',
  showAvatar: false,
  avatarSrc: '',
};
