export const FormStatus = [
  { key: 'All' },
  { key: 'Draft', value: 'Draft' },
  { key: 'Active', value: 'Active' },
  { key: 'Inactive', value: 'InActive' },
];

export const FormType = [
  { value: 'Smart', key: 'Smart Form' },
  { value: 'Quote', key: 'Quote Form' },
];
