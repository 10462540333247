import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Header = (props) => {
  const { className, onClose } = props;

  return (
    <div className={classNames(styles.header, className)}>
      <div className={styles.title}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.668 4V9.33333C18.668 9.68695 18.8084 10.0261 19.0585 10.2761C19.3085 10.5262 19.6477 10.6667 20.0013 10.6667H25.3346"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.668 28H9.33464C8.62739 28 7.94911 27.719 7.44902 27.219C6.94892 26.7189 6.66797 26.0406 6.66797 25.3333V6.66667C6.66797 5.95942 6.94892 5.28115 7.44902 4.78105C7.94911 4.28095 8.62739 4 9.33464 4H18.668L25.3346 10.6667V25.3333C25.3346 26.0406 25.0537 26.7189 24.5536 27.219C24.0535 27.719 23.3752 28 22.668 28Z"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 12H13.3333"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 17.334H20"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 22.666H20"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className={styles.editText}>Edit Document</div>
      </div>
      <div onClick={onClose} className={styles.close}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 16L16 8" stroke="#303030" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M16 16L8 8" stroke="#303030" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      </div>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

Header.defaultProps = {
  className: '',
  onClose: () => {},
};

export default Header;
