import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadTransactionDocumentsEffect } from 'store/effects/transactions';

import { Button } from 'components-antd';
import { TransactionCloseComponentIds } from 'app-constants';
import { UploadDocuments as CommonUploadDocuments } from 'components/Transactions';
import Question from 'pages/Workshop/Transactions/TransactionCreate/components/Question';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';
import Attachment from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/UploadFiles/Attachment';

import styles from './styles.module.scss';

interface UploadFilesProps {
  setStage: (stage: TransactionCloseComponentIds) => void;
}

const UploadFiles = ({ setStage }: UploadFilesProps) => {
  const dispatch = useDispatch();

  const [isPending, setIsPending] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const updateStoredFiles = (values) => {
    setUploadedFiles(values);
  };

  const onSave = (newValues, cb) => {
    updateStoredFiles([...uploadedFiles, ...newValues]);
    if (typeof cb === 'function') {
      cb();
    }
  };

  const onAttachmentRemove = (event, index) => {
    event.stopPropagation();
    const updatedUploadedFiles = [...uploadedFiles];
    updatedUploadedFiles.splice(index, 1);
    updateStoredFiles(updatedUploadedFiles);
  };

  const onSubmitHandler = () => {
    if (uploadedFiles.length) {
      setIsPending(true);
      dispatch(
        uploadTransactionDocumentsEffect({ documents: uploadedFiles }, {}, (err) => {
          if (!err) {
            setUploadedFiles([]);
          }
          setIsPending(false);
        }),
      );
    }
  };

  return (
    <div className={styles.uploadFiles}>
      <Question>Do you want to upload any closing documents?</Question>
      <AnswersContainer>
        <CommonUploadDocuments
          dropzone
          uploadButton={null}
          onSave={onSave}
          pendingNeeded={false}
          withPermissions
          isAssignTask={false}
          isCategoryOptional
          fillDocumentName={true}
        />
        <div>
          {uploadedFiles.length ? (
            uploadedFiles?.map((file: {}, index) => (
              <Attachment
                key={index}
                onRemove={(event) => onAttachmentRemove(event, index)}
                {...file}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue
          variant="skip"
          className={styles.skip}
          onClick={() => setStage(TransactionCloseComponentIds.IncompleteForms)}
        />
        <Continue
          isPending={isPending}
          onClick={() => {
            onSubmitHandler();
            setStage(TransactionCloseComponentIds.IncompleteForms);
          }}
          className={styles.submit}
        />
      </ButtonsContainer>
    </div>
  );
};

export default UploadFiles;
