import { LeftSide, Container, RightSide } from 'components/LoginGroup';
import Form from './Form';

const ResetPassword = () => (
  <Container>
    <LeftSide variant={LeftSide.LIGHT} />
    <Form />
    <RightSide />
  </Container>
);

export default ResetPassword;
