import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reduce } from 'lodash-es';

import { NoThreads } from 'components';

import { useLongPress } from 'hooks';

import { changeMessagesDrawerTypeAction } from 'store/actions/drawers/messages';

import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';

import ActionsList from 'components/Drawers/components/ActionsList';

import {
  getArchiveThreadOption,
  getAddParticipantThreadOption,
  getArchiveThreadsListOption,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import ClientMessages from './ClientMessages';

const GroupedClientsThreads = (props) => {
  const { list, openGroupLabel, groupDrawerType, isQuotes, archived } = props;
  const dispatch = useDispatch();
  const [actionsPosition, setActionPosition] = useState(null);
  const [actions, setActions] = useState([]);

  const openHandler = useCallback(
    (user, threads) =>
      dispatch(
        changeMessagesDrawerTypeAction({
          type: threads.length === 1 ? DRAWER_MESSAGES_TYPES.CHAT : groupDrawerType,
          params: {
            threadId: threads.length === 1 ? threads[0].Id : undefined,
            clientId: threads.length > 1 ? user?.Id : undefined,
            archived,
          },
        }),
      ),
    [dispatch, groupDrawerType, archived],
  );

  const onLongPress = (event, [user, threads]) => {
    setActionPosition({ x: event?.clientX, y: event?.clientY });
    if (threads.length === 1) {
      setActions([
        {
          title: 'Open message',
          onClick: () => openHandler(user, threads),
          testid: 'open_message',
        },
        ...getAddParticipantThreadOption(dispatch, {
          info: threads[0]?.TopicName,
          type: threads[0]?.Type,
          threadId: threads[0]?.Id,
        }),
        ...getArchiveThreadOption(dispatch, threads[0], () => setActionPosition(null)),
      ]);
    } else {
      setActions([
        {
          title: openGroupLabel,
          onClick: () => openHandler(user, threads),
        },
        ...getArchiveThreadsListOption(dispatch, threads, () => setActionPosition(null)),
      ]);
    }
  };
  const onPress = (event, [user, threads]) => {
    openHandler(user, threads);
  };

  const longPressEvent = useLongPress(onLongPress, onPress);

  const dataList = useMemo(
    () =>
      reduce(
        list,
        (acc, { user, unreadNotQuotes, quotes, notQuotes, unreadQuotes }) => {
          const threads = isQuotes ? [...quotes, ...notQuotes] : notQuotes;
          const unread = isQuotes ? unreadNotQuotes + unreadQuotes : unreadNotQuotes;
          if (threads?.length) {
            acc.push({ threads, user, unread });
          }
          return acc;
        },
        [],
      ),
    [isQuotes, list],
  );

  return (
    <>
      {dataList?.length ? (
        dataList.map(({ user, threads, unread }) => (
          <ClientMessages
            key={user.Id}
            data={user}
            unreadNumber={unread}
            threads={threads}
            {...longPressEvent(user, threads)}
          />
        ))
      ) : (
        <NoThreads />
      )}
      <ActionsList actions={actions} position={actionsPosition} />
    </>
  );
};

GroupedClientsThreads.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openGroupLabel: PropTypes.string,
  groupDrawerType: PropTypes.string,
  isQuotes: PropTypes.bool,
  isTransactions: PropTypes.bool,
  archived: PropTypes.bool,
};

GroupedClientsThreads.defaultProps = {
  openGroupLabel: 'Open client',
  groupDrawerType: DRAWER_MESSAGES_TYPES.CLIENT,
  isQuotes: false,
  isTransactions: false,
  archived: false,
};

export default GroupedClientsThreads;
