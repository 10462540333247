import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { formatNameToAvatarLetters } from 'helpers';
import { Avatar } from 'components';
import CardTitle from './CardTitle';
import Comment from './Comment';
import Archive from './Archive';
import { Col } from 'antd';

import styles from './styles.module.scss';

const Card = (props) => {
  const { icon, title, category, info, details, isAvatar, className, quotesIds } = props;
  const { viewClassName, onArchive, withoutComments, drawerCommentType, quotesIdsWithStatus } =
    props;

  const commentParams = useMemo(
    () => ({
      type: drawerCommentType,
      title,
      icon,
    }),
    [drawerCommentType, icon, title],
  );

  const getIcon = () => {
    if (isAvatar) {
      return (
        <Avatar
          avatarClassName={styles.icon}
          src={icon}
          placeholder={
            <div className={styles.avatarPlaceholder}>{formatNameToAvatarLetters(title)}</div>
          }
        />
      );
    }

    if (icon) {
      return <img className={styles.icon} src={icon} alt="logo" />;
    }

    return null;
  };

  const getTitle = () => {
    const words = (title || '').split(' ');
    return words.map((word, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <span key={index}>
        {`${word} `}
        {category && words.length - 1 === index ? (
          <span className={styles.category}>{category}</span>
        ) : (
          ''
        )}
      </span>
    ));
  };

  return (
    <div testid="card" className={classNames(styles.card, className)}>
      <div className={styles.logoHolder}>{getIcon()}</div>
      <div className={styles.infoBlock}>
        <div className={styles.title}>
          <CardTitle>{getTitle()}</CardTitle>
        </div>
        <div testid="card_info" className={styles.info}>
          {info}
        </div>
        <div className={classNames(styles.view, viewClassName)}>
          {details || <div />}
          <div className={styles.actions}>
            {!withoutComments && (
              <Comment
                quotesIds={quotesIds}
                quotesIdsWithStatus={quotesIdsWithStatus}
                params={commentParams}
              />
            )}
            <Archive onArchive={() => onArchive(quotesIds)} />
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  viewClassName: PropTypes.string,
  category: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.node,
  details: PropTypes.node,
  isAvatar: PropTypes.bool,
  withoutComments: PropTypes.bool,
  quotesIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
    .isRequired,
  onArchive: PropTypes.func,
  drawerCommentType: PropTypes.string,
  quotesIdsWithStatus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      type: PropTypes.string,
    }),
  ),
};

Card.defaultProps = {
  className: '',
  title: '',
  viewClassName: '',
  category: '',
  icon: null,
  info: null,
  details: null,
  isAvatar: false,
  withoutComments: false,
  onArchive: () => {},
  drawerCommentType: '',
  quotesIdsWithStatus: [],
};

export default Card;
