import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Quotes/components/QuoteDetails/Icons';

import styles from './styles.module.scss';

const MetaItem = (props) => {
  const { icon, value, className, testid } = props;

  return (
    <div className={classNames(styles.metaItem, className)}>
      <Icon className={styles.icon} variant={icon} />
      <div testid={testid} className={styles.value}>
        {value}
      </div>
    </div>
  );
};

MetaItem.propTypes = {
  className: PropTypes.string,
  testid: PropTypes.string,
  icon: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.string]).isRequired,
};

MetaItem.defaultProps = {
  className: '',
  testid: undefined,
};

export default MetaItem;
