import { LocalStorage } from 'services';

import { connectSocket, getSocket, clearSocket } from 'settings/web-services/sockets';
import { SOCKET_EMIT_TYPES, SOCKET_STATE } from 'settings/constants/sockets';
import { get } from 'lodash-es';
import { dispatch } from 'store';
import { showErrorMessage } from '../helpers/errors';
import { postRefreshToken } from '../api/user';
import { setHeaders } from 'settings/web-services/api';
import { socketsChangeStatusAction } from '../store/actions/sockets';

// Open-close
export const connectSocketToBackEnd = (onConnect) => {
  const { accessToken } = LocalStorage.getTokens();

  const socket = connectSocket({ accessToken });
  socket.on('connect', onConnect);
};

export const closeSocket = () => {
  const socket = getSocket();
  if (socket) {
    socket.removeAllListeners();

    socket.close();
    clearSocket();
  }
};

export const onUnauthorized = (handler) => {
  const socket = getSocket();
  socket.on('connect_error', (error) => {
    if (error?.message === 'INVALID_TOKEN') {
      const tokens = LocalStorage.getTokens();
      postRefreshToken(tokens)
        .then((res) => {
          const { accessToken, refreshToken } = get(res, ['data', 'tokens'], {});
          if (accessToken && refreshToken) {
            LocalStorage.setTokens({ accessToken, refreshToken });
            setHeaders({ accessToken });
            connectSocketToBackEnd(() => {
              dispatch(socketsChangeStatusAction({ status: SOCKET_STATE.CONNECTED }));
            });
            handler?.(error);
          } else {
            throw new Error('wrong postRefreshToken result');
          }
        })
        .catch((err) => {
          showErrorMessage(err);
        });
    }
  });
};

// Open-close state listeners
export const setOnDisconnectHandler = (handler) => {
  const socket = getSocket();
  socket.on('disconnect', handler);
};

export const setOnConnectErrorHandler = (handler) => {
  const socket = getSocket();
  socket.on('connect_error', handler);
};

// Messages listeners
export const setOnRecieveNewMessagesHandler = (handler) => {
  const socket = getSocket();
  socket.on(SOCKET_EMIT_TYPES.NEW_MESSAGE, handler);
};

export const setOnRecieveEditedMessagesHandler = (handler) => {
  const socket = getSocket();
  socket.on(SOCKET_EMIT_TYPES.MESSAGE_EDITED, handler);
};

export const setOnRecieveDeletedMessagesHandler = (handler) => {
  const socket = getSocket();
  socket.on(SOCKET_EMIT_TYPES.MESSAGE_DELETED, handler);
};

export const setOnRecieveRemovedMessagesHandler = (handler) => {
  const socket = getSocket();
  socket.on(SOCKET_EMIT_TYPES.MESSAGE_REMOVED, handler);
};

// Threads listeners
export const setOnRecieveInitThreadsHandler = (handler) => {
  const socket = getSocket();
  socket.on(SOCKET_EMIT_TYPES.INIT_THREADS, handler);
};

export const setOnRecieveNewThreadHandler = (handler) => {
  const socket = getSocket();
  socket.on(SOCKET_EMIT_TYPES.NEW_THREAD, handler);
};

export const setOnRecieveRemovedFromThreadHandler = (handler) => {
  const socket = getSocket();
  socket.on(SOCKET_EMIT_TYPES.REMOVED_FROM_THREAD, handler);
};

export const setOnRecieveUpdatedThreadHandler = (handler) => {
  const socket = getSocket();
  socket.on(SOCKET_EMIT_TYPES.THREAD_UPDATED, handler);
};

export const setOnReceiveInitNotificationsHandler = (handler) => {
  const socket = getSocket();
  socket.on(SOCKET_EMIT_TYPES.INITIAL_NOTIFICATIONS, handler);
};

export const setOnReceiveNewNotificationHandler = (handler) => {
  const socket = getSocket();
  socket.on(SOCKET_EMIT_TYPES.NEW_NOTIFICATION, handler);
};

export const setOnReceiveMessageReadReceiptHandler = (handler) => {
  const socket = getSocket();
  socket.on(SOCKET_EMIT_TYPES.MESSAGE_READ_RECEIPT, handler);
};

export const setOnReceiveThreadParticipantOnlineStatusHandler = (handler) => {
  const socket = getSocket();
  socket.on(SOCKET_EMIT_TYPES.PARTICIPANT_ONLINE_STATUS, handler);
};

export const setOnKitConfiguredHandler = (handler) => {
  const socket = getSocket();
  if (socket && !socket.hasListeners(SOCKET_EMIT_TYPES.KIT_CONFIGURED)) {
    socket.on(SOCKET_EMIT_TYPES.KIT_CONFIGURED, handler);
  }
};

// Threads emiters
export const sendThreadMarkAsRead = (cfg, cb) => {
  const socket = getSocket();
  socket.emit(SOCKET_EMIT_TYPES.MARK_THREAD_AS_READ, cfg, cb);
};

export const sendMsgMarkAsRead = (cfg, cb) => {
  const socket = getSocket();
  socket.emit(SOCKET_EMIT_TYPES.MARK_MSG_AS_READ, cfg, cb);
};

export const sendThreadArchiveStatus = (cfg, cb) => {
  const socket = getSocket();
  socket.emit(SOCKET_EMIT_TYPES.ARCHIVE_THREAD, cfg, cb);
};

// Messages emiters
export const getMessagesByThreadId = (cfg, cb) => {
  const socket = getSocket();
  socket.emit(SOCKET_EMIT_TYPES.GET_MESSAGES_FOR_THREAD, cfg, cb);
};

export const sendNewMessage = (cfg, cb) => {
  const socket = getSocket();
  socket.emit(SOCKET_EMIT_TYPES.SEND_MESSAGE, cfg, cb);
};

export const createThread = (cfg, cb) => {
  const socket = getSocket();
  socket.emit(SOCKET_EMIT_TYPES.CREATE_THREAD, cfg, cb);
};

export const editMessage = (cfg, cb) => {
  const socket = getSocket();
  socket.emit(SOCKET_EMIT_TYPES.EDIT_MESSAGE, cfg, cb);
};

export const deleteMessage = (cfg, cb) => {
  const socket = getSocket();
  socket.emit(SOCKET_EMIT_TYPES.DELETE_MESSAGE, cfg, cb);
};

export const updateThread = (cfg, cb) => {
  const socket = getSocket();
  socket.emit(SOCKET_EMIT_TYPES.UPDATE_THREAD, cfg, cb);
};

export const sendThreadOpenedInfo = (cfg) => {
  const socket = getSocket();
  socket.emit(SOCKET_EMIT_TYPES.THREAD_OPENED, cfg);
};

export const sendThreadsClosedInfo = () => {
  const socket = getSocket();
  socket.emit(SOCKET_EMIT_TYPES.THREADS_CLOSED);
};

export const sendUserOfflineInfo = (cfg) => {
  const socket = getSocket();
  socket.emit(SOCKET_EMIT_TYPES.USER_OFFLINE, cfg);
};
