import styles from './styles.module.scss';
import classNames from 'classnames';
import { AnalyzingAnimation } from 'components';

type ImageCompareStatusPropsType = {
  propertiesCount: number;
  status: 'analyzing' | 'empty';
};

function ImageCompareStatus(props: ImageCompareStatusPropsType) {
  const columnWidth = (props.propertiesCount + 1) * 224 + 'px';

  return (
    <div className={styles.imagesStatus}>
      <table className={styles.imagesStatusTable}>
        <tr className={styles.imagesStatusTableRow}>
          <td className={styles.imagesStatusTableColumn}>
            {props.status === 'analyzing' ? (
              <div className={styles.content} style={{ width: columnWidth }}>
                <AnalyzingAnimation />
                <div className={styles.text}>Analyzing Images</div>
              </div>
            ) : (
              <div className={styles.content} style={{ width: columnWidth }}>
                No photos to compare
              </div>
            )}
          </td>
        </tr>
      </table>
    </div>
  );
}

export default ImageCompareStatus;
