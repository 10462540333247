import PropTypes from 'prop-types';

import { Document } from './Document';

import styles from './styles.module.scss';

export const Documents = ({ data }) => {
  const { AppointmentDocuments: documents } = data;

  return documents?.length > 0 ? (
    <div className={styles.documents}>
      <p className={styles.title}>Documents</p>
      {documents?.length ? (
        <>
          {documents.map((d) => (
            <Document key={d.Id} document={d} />
          ))}
        </>
      ) : (
        <p className={styles.disclaimer}>No documents uploaded!</p>
      )}
    </div>
  ) : null;
};

Documents.propTypes = {
  data: PropTypes.object,
};

Documents.defaultProps = {
  data: {},
};
