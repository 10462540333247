import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ adminPanel }) => adminPanel;

const taskCategoriesState = createSelector(localState, ({ taskCategories }) => taskCategories);

const taskCategoryState = createSelector(localState, ({ taskCategory }) => taskCategory);

export const getTaskCategoriesDashboardSelector = createSelector(
  taskCategoriesState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTaskCategoriesFiltersDashboardSelector = createSelector(
  taskCategoriesState,
  (state) => get(state, 'filters') || {},
);

export const getTaskCategoriesDataDashboardSelector = createSelector(
  taskCategoriesState,
  (state) => get(state, 'data') || [],
);

export const getTaskCategoryDashboardSelector = createSelector(
  taskCategoryState,
  ({ state, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTaskCategoryDataDashboardSelector = createSelector(taskCategoryState, (state) =>
  get(state, 'data'),
);
