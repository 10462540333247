import PropTypes from 'prop-types';

import { Switch } from 'components-antd';

import styles from './styles.module.scss';

export const GarageOnly = (props) => {
  const { onChange, value, disabled } = props;

  return (
    <div className={styles.switchActionsGo}>
      <div className={styles.garageSwitchGo}>
        Garage Only
        <Switch
          className={styles.switchItemGo}
          checked={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

GarageOnly.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
};

GarageOnly.defaultProps = {
  onChange: () => {},
  value: false,
  disabled: false,
};
