import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { dropConfetti } from 'utils';

import { PENDING } from 'settings/constants/apiState';
import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';
import { routes } from 'settings/navigation/routes';

import { getClientInstancesSelector } from 'store/selectors/clientInstances';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';
import { getUserDataSelector } from 'store/selectors/user';

import { Button, Wrapper } from 'components';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { Question } from 'pages/RequestQuote/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import styles from './styles.module.scss';

export const PartnerCompleteScreen = (props) => {
  const { className, onNext, Controls } = props;
  const user = useSelector(getUserDataSelector);
  const { onBoarding } = useSelector(getOnBoardingData);
  const signUpRole = useSelector(getSignUpRoleSelector);
  const { searchInstances, transactionInstances, isLoading } = useSelector(
    getClientInstancesSelector,
  );
  const history = useHistory();

  const isPending = onBoarding.state === PENDING;
  let buyer = false;

  if ([AGENT, CLIENT, THIRD_PARTY].includes(signUpRole)) {
    const hasSearchInstance = user.Client?.SearchInstances?.[0];
    if (hasSearchInstance) {
      buyer = true;
    }
  }

  useEffect(() => {
    if (!buyer) {
      dropConfetti();
    }
  }, []);

  return (
    <Wrapper isPending={isPending} className={classNames(styles.stageWrap, className)}>
      <Controls
        onNext={() => {
          history.push(routes.index);
        }}
        isNextDisabled={false}
        className={styles.controls}
        variant="lightFull"
      />
      <>
        <div className={styles.celebrate}>🎉</div>
        <Question className={classNames(styles.title, styles.celebrateTitle)}>
          You&apos;re all set
        </Question>
        <ButtonsContainer>
          <Button
            testid="feed"
            type="button"
            onClick={() => history.push(routes.index)}
            className={classNames(styles.button, styles.submit)}
            titleClassName={styles.title}
            title="Take Me To The App"
            isPending={isPending}
          />
        </ButtonsContainer>
      </>
    </Wrapper>
  );
};

PartnerCompleteScreen.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

PartnerCompleteScreen.defaultProps = {
  className: '',
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};
