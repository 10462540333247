import { ReactChild, ReactElement } from 'react';

import { Divider, Panel } from 'components-antd';

import styles from './styles.module.scss';

interface CustomPanelProps {
  key: string;
  icon: ReactElement;
  header: string;
  title: string;
  children: ReactChild;
}

export const CustomPanel = ({ key, icon, header, title, children, ...props }: CustomPanelProps) => (
  <>
    <Panel
      key={key}
      header={
        <div className={styles.headerContainer}>
          {icon}
          {header}
        </div>
      }
      {...props}
    >
      <div className={styles.panelBox}>
        <div className={styles.panelTitle}>{title}</div>
        {children}
      </div>
    </Panel>
    <Divider className={styles.divider} />
  </>
);
