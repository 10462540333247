import classNames from 'classnames';
import AntSelect, { SelectProps as AntSelectProps, DefaultOptionType } from 'antd/lib/select';

import DropdownIcon from 'pages/FormBuilder/Icons/Dropdown';

import styles from './styles.module.scss';

const { Option: AntOption } = AntSelect;

export interface SelectProps extends AntSelectProps {
  dropdownIconColor?: string;
  large?: boolean;
}

export const Select = ({
  className,
  large,
  dropdownIconColor = '#666666',
  popupClassName,
  ...props
}: SelectProps) => (
  <AntSelect
    suffixIcon={<DropdownIcon stroke={dropdownIconColor} />}
    className={classNames(styles.baseSelect, className, { [styles.largeSelect]: large })}
    popupClassName={popupClassName}
    {...props}
  />
);

export const Option = AntOption;

export type OptionType = DefaultOptionType;
