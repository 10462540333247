import PropTypes from 'prop-types';
import classNames from 'classnames';

import Item from './Item';

import styles from './styles.module.scss';

const PropsList = (props) => {
  const { className, list, client } = props;

  if (!list) return null;

  return (
    <div className={classNames(styles.propsList, className)}>
      {list?.length > 0 ? (
        list.map((item) => <Item key={item?.Id} propertyInfo={item} client={client} />)
      ) : (
        <div className={styles.noFavorites}>
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50.5575 31.6657L31.9997 50.485L13.111 31.3373C13.1094 31.3356 13.1078 31.3339 13.1062 31.3323C11.1776 29.3458 10 26.6604 10 23.7035C10 17.6313 14.9909 12.6665 21.2 12.6665C24.997 12.6665 28.3458 14.5257 30.3715 17.3669L32 19.651L33.6285 17.3669C35.6542 14.5257 39.0031 12.6665 42.8 12.6665C49.0091 12.6665 54 17.6313 54 23.7035C54 26.8076 52.7021 29.6136 50.5993 31.6245L50.5781 31.6448L50.5575 31.6657Z"
              stroke="#E8E9EA"
              strokeWidth="4"
            />
            <path
              d="M32 19L29.5 25L34 30L29.5 36.5L33.5 42L31.5 48.5"
              stroke="#E8E9EA"
              strokeWidth="4"
              strokeLinejoin="round"
            />
          </svg>
          <span className={styles.noFavoritesText}>There are no client favorites</span>
        </div>
      )}
    </div>
  );
};

PropsList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  client: PropTypes.object,
};

PropsList.defaultProps = {
  className: '',
  list: null,
};

export default PropsList;
