import { FormMode, ResponseType } from 'types';
import { TextArea } from 'components-antd';
import { FORM_MODE } from 'app-constants';

import styles from './styles.module.scss';

interface FreeTextQuestionProps {
  mode: FormMode;
  formResponse?: ResponseType;
  handleFormResponse?: ({ Answer }: Partial<ResponseType>) => void;
  maxLength?: number;
}

export const FreeTextQuestion = ({
  mode,
  formResponse,
  handleFormResponse,
  maxLength,
}: FreeTextQuestionProps) => {
  const editMode = mode === FORM_MODE.Edit;

  const handleUserInput = (value: string) => {
    if (formResponse?.UUID && handleFormResponse) {
      handleFormResponse({ Answer: value });
    }
  };

  return (
    <TextArea
      autoSize
      placeholder="Enter a free text answer"
      maxLength={maxLength}
      disabled={editMode}
      className={styles.responseInput}
      size="large"
      value={formResponse?.Answer}
      bordered={false}
      onChange={(e) => handleUserInput(e.target.value)}
    />
  );
};
