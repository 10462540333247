import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Label = (props) => {
  const { className, children, onClick } = props;

  return (
    <div onClick={onClick} className={classNames(styles.label, className)}>
      {children}
    </div>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

Label.defaultProps = {
  className: '',
  onClick: () => {},
};

export default Label;
