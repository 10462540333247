import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash-es';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { ValidationSchema } from './validation';
import { requestFillShowingAppointment } from 'store/actions/showingAppointment';
import { requestCreateShowingAppointmentEffect } from 'store/effects/showingAppointment';
import { getShowingDetails } from 'store/selectors/showingAppointment';
import {
  getFormattedTime,
  getTimeFormatMonthName,
  showErrorMessage,
  showSuccessMessage,
} from 'helpers';
import { Input, Phone } from 'components';
import { Row, Col, Checkbox } from 'components-antd';
import Documents from './Documents';
import { link } from 'settings/navigation/link';

import styles from './styles.module.scss';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ICON_VARIANT_TYPE, Icons } from '../Icons';
import { lightBlue } from 'app-constants';
import { Button } from 'antd';

interface formErrorTypes {
  BuyingAgentPhone?: string | undefined;
  BuyingAgentName?: string | undefined;
  BuyingAgentEmail?: string | undefined;
  BuyingAgentBrokerage?: string | undefined;
}

export const BookingForm = (props) => {
  const {
    setIsLoading,
    setAppointmentCreated,
    setAppointmentFormData,
    appointmentFormData,
    selectedDateTime,
    setCalendarViewMode,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [requiredDocuments, setRequiredDocuments] = useState<any>([]);
  const { OtherRequiredDocuments, RequireDocumentPreApproval } = useSelector(getShowingDetails);
  const [showDocument, setShowDocument] = useState(false);
  const [showContactForm, setShowContactForm] = useState(true);
  const [formError, setFormError] = useState<formErrorTypes>({
    BuyingAgentPhone: '',
    BuyingAgentName: '',
    BuyingAgentEmail: '',
    BuyingAgentBrokerage: '',
  });

  const isDisable = () =>
    appointmentFormData?.BuyingAgentName &&
    appointmentFormData?.BuyingAgentEmail &&
    appointmentFormData?.BuyingAgentBrokerage &&
    appointmentFormData?.BuyingAgentPhone;

  useEffect(() => {
    const documents: JSON[] = [];
    if (RequireDocumentPreApproval) {
      documents.push(
        JSON.parse(
          JSON.stringify({
            Title: 'Pre-Approval Letter',
            Code: 'Pre-Approval Letter',
            required: true,
          }),
        ),
      );
    }
    if (OtherRequiredDocuments) {
      OtherRequiredDocuments?.forEach((element, index) => {
        documents.push(
          JSON.parse(
            JSON.stringify({
              Title: element.name,
              Code: element.name.trim(),
              required: element.required,
            }),
          ),
        );
      });
    }
    setRequiredDocuments([...documents]);
    if (!appointmentFormData?.Id) {
      setAppointmentFormData({
        ...appointmentFormData,
        AppointmentDocuments: documents,
      });
    }
  }, []);

  let initialValues = {
    BuyingAgentName: (appointmentFormData?.BuyingAgentName as string) || '',
    BuyingAgentBrokerage: (appointmentFormData?.BuyingAgentBrokerage as string) || '',
    BuyingAgentPhone: (appointmentFormData?.BuyingAgentPhone as string) || '',
    BuyingAgentEmail: (appointmentFormData?.BuyingAgentEmail as string) || '',
    IsEmailNotification: (appointmentFormData?.IsEmailNotification as boolean) || false,
    IsSMSNotification: (appointmentFormData?.IsSMSNotification as boolean) || false,
  };

  const [phoneCountry, setPhoneCountry] = useState();

  const validationSchema = useMemo(() => ValidationSchema(phoneCountry), [phoneCountry]);

  const onSubmit = async (values) => {
    const requiredDocs = appointmentFormData.AppointmentDocuments?.filter(
      (item) => item.required,
    )?.length;
    if (requiredDocs > 0) {
      return showErrorMessage('Upload all required documents');
    }

    if (values) {
      setIsLoading(true);
      setAppointmentFormData({
        ...appointmentFormData,
        ...values,
      });
      dispatch(
        requestCreateShowingAppointmentEffect(
          { ...appointmentFormData, ...values },
          {},
          (err, res) => {
            setIsLoading(false);
            if (!err && res?.data.statusCode == 200) {
              const apptData = res?.data?.value;
              dispatch(requestFillShowingAppointment({ data: apptData }));
              setAppointmentFormData({
                ...appointmentFormData,
                ...apptData,
              });
              showSuccessMessage('Request Submitted');
              formik.resetForm();
              setAppointmentCreated(true);
              history.push(link.toScheduledShowingAppointment(apptData?.UUID));
            } else {
              showErrorMessage(res?.data.errorMessage);
            }
          },
        ),
      );
    }
  };

  const onChangeValue = (val, fieldName) => {
    setAppointmentFormData({
      ...appointmentFormData,
      [fieldName]: val,
    });
  };

  const onSaveHandler = (newValues, fileCode, cb) => {
    const files: any = cloneDeep(appointmentFormData?.AppointmentDocuments || []);
    const index = files.findIndex((file) => file.Code === fileCode);
    files[index] = {
      code: fileCode,
      ...newValues[0],
    };
    setAppointmentFormData({ ...appointmentFormData, AppointmentDocuments: files });
    if (typeof cb === 'function') {
      cb();
    }
  };

  const onDeleteDocument = (event, index) => {
    const files = cloneDeep(appointmentFormData?.AppointmentDocuments);
    const fileCode = files[index].code;
    files[index] = requiredDocuments?.find((item) => item['Code'] == fileCode);
    onChangeValue(files, 'AppointmentDocuments');
  };

  const handleResetDocuments = () => {
    onChangeValue(requiredDocuments, 'AppointmentDocuments');
  };

  const handleResetForm = () => {
    setAppointmentFormData({
      ...appointmentFormData,
      BuyingAgentBrokerage: '',
      BuyingAgentEmail: '',
      BuyingAgentName: '',
      BuyingAgentPhone: '',
      IsEmailNotification: null,
      IsSMSNotification: null,
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
    onSubmit,
  });

  const handleNextStep = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        setFormError(errors);
      } else {
        setShowDocument(true);
        setShowContactForm(false);
      }
    });
  };

  const handlePrevStep = () => {
    setShowDocument(false);
    setShowContactForm(true);
  };

  useEffect(() => {
    if (formik.errors) {
      setShowContactForm(true);
      setShowDocument(false);
    }
  }, [formik.errors]);

  return (
    <Row gutter={40}>
      <Col xs={24}>
        <form
          name="showingAppointmentForm"
          onSubmit={formik.handleSubmit}
          className={styles.contentWrapper}
        >
          <div className={styles.selectedDateTime}>
            <div className={styles.dateTime}>
              <Icons variant={ICON_VARIANT_TYPE.CALENDAR} color={lightBlue} />
              <div>
                <h3>{getTimeFormatMonthName(selectedDateTime?.date)}</h3>
                <p>{getFormattedTime(selectedDateTime?.startTime)}</p>
              </div>
            </div>
            <Icons
              className={styles.calendarEditIcon}
              variant={ICON_VARIANT_TYPE.EDIT}
              onClick={() => setCalendarViewMode(true)}
            />
          </div>
          <div className={styles.inputsWrapper}>
            <div className={`${styles.formTitleWrapper} ${showContactForm && styles.marginBottom}`}>
              <div className={styles.formTitle}>
                <Icons variant={ICON_VARIANT_TYPE.USER} />
                <div>
                  <h4>Contact Information</h4>
                  {!showContactForm && (
                    <p>
                      {appointmentFormData?.BuyingAgentName},{' '}
                      {appointmentFormData?.BuyingAgentBrokerage}
                    </p>
                  )}
                </div>
              </div>
              {!showContactForm && (
                <Icons
                  className={styles.calendarEditIcon}
                  variant={ICON_VARIANT_TYPE.EDIT}
                  onClick={handlePrevStep}
                />
              )}
            </div>
            {showContactForm && (
              <>
                <div className={styles.inputBlock}>
                  <div className={styles.label}>Name</div>
                  <Input
                    className={styles.input}
                    inputHolderClassName={styles.inputHolder}
                    variant={Input.LIGHT}
                    placeholder="Enter name"
                    value={formik.values.BuyingAgentName}
                    onChange={(e, val) => onChangeValue(val, 'BuyingAgentName')}
                    error={
                      formError.BuyingAgentName || formik.touched.BuyingAgentName
                        ? formik.errors.BuyingAgentName
                        : ''
                    }
                    name="BuyingAgentName"
                    id="BuyingAgentName"
                    testid="buyingAgent_name"
                  />
                </div>
                <div className={styles.inputBlock}>
                  <div className={styles.label}>Brokerage</div>
                  <Input
                    className={styles.input}
                    inputHolderClassName={styles.inputHolder}
                    variant={Input.LIGHT}
                    placeholder="Enter Brokerage"
                    value={formik.values.BuyingAgentBrokerage}
                    onChange={(e, val) => onChangeValue(val, 'BuyingAgentBrokerage')}
                    error={
                      formError.BuyingAgentBrokerage || formik.touched.BuyingAgentBrokerage
                        ? formik.errors.BuyingAgentBrokerage
                        : ''
                    }
                    name="BuyingAgentBrokerage"
                    id="BuyingAgentBrokerage"
                    testid="buyingAgent_Brokerage"
                  />
                </div>
                <div className={styles.inputBlock}>
                  <div className={styles.label}>Phone</div>
                  <Phone
                    id="BuyingAgentPhone"
                    name="BuyingAgentPhone"
                    onChange={(event, value, country) => {
                      setPhoneCountry(country);
                      onChangeValue(value, 'BuyingAgentPhone');
                    }}
                    variant="light"
                    error={
                      formError.BuyingAgentPhone || formik.touched.BuyingAgentPhone
                        ? formik.errors.BuyingAgentPhone
                        : ''
                    }
                    className={classNames(styles.inputBlock, styles.phone)}
                    inputClassName={styles.inputPhone}
                    errorClassName={styles.phonesError}
                    options={{
                      containerClass: styles.rightBlock,
                    }}
                    value={formik.values.BuyingAgentPhone}
                    testid="buyingAgent_Phone"
                  />
                </div>
                <div className={styles.inputBlock}>
                  <div className={styles.label}>Email</div>
                  <Input
                    className={styles.input}
                    inputHolderClassName={styles.inputHolder}
                    variant={Input.LIGHT}
                    placeholder="Enter email address"
                    value={formik.values.BuyingAgentEmail}
                    onChange={(e, val) => onChangeValue(val, 'BuyingAgentEmail')}
                    error={
                      formError.BuyingAgentEmail || formik.touched.BuyingAgentEmail
                        ? formik.errors.BuyingAgentEmail
                        : ''
                    }
                    name="BuyingAgentEmail"
                    id="BuyingAgentEmail"
                    testid="buyingAgent_Email"
                  />
                </div>
                <div className={styles.checkboxBlock}>
                  <div className={styles.label}>How do you want to receive updates?</div>
                  <div className={styles.checkboxArea}>
                    <Checkbox
                      type="checkbox"
                      className={classNames(styles.checkbox, 'mosaikCheckbox')}
                      name="IsSMSNotification"
                      value={formik.values.IsSMSNotification}
                      onChange={(e: CheckboxChangeEvent) =>
                        onChangeValue(!appointmentFormData?.IsSMSNotification, 'IsSMSNotification')
                      }
                      checked={formik.values.IsSMSNotification}
                    >
                      Text
                    </Checkbox>
                  </div>
                  <div className={styles.checkboxArea}>
                    <Checkbox
                      type="checkbox"
                      className={classNames(styles.checkbox, 'mosaikCheckbox')}
                      name="IsEmailNotification"
                      value={formik.values.IsEmailNotification}
                      onChange={(e: CheckboxChangeEvent) =>
                        onChangeValue(
                          !appointmentFormData?.IsEmailNotification,
                          'IsEmailNotification',
                        )
                      }
                      checked={formik.values.IsEmailNotification}
                    >
                      Email
                    </Checkbox>
                  </div>
                </div>

                <div className={styles.formFooter}>
                  <Button onClick={handleResetForm} type="text">
                    Reset
                  </Button>
                  <Button
                    onClick={() =>
                      appointmentFormData?.AppointmentDocuments?.length
                        ? handleNextStep()
                        : formik.handleSubmit()
                    }
                    className={styles.continueBtn}
                    disabled={!isDisable()}
                  >
                    {appointmentFormData?.AppointmentDocuments?.length ? 'Next' : 'Submit'}
                  </Button>
                </div>
              </>
            )}
          </div>
          {showDocument && (
            <div className={styles.appointmentDocuments}>
              {appointmentFormData?.AppointmentDocuments?.length ? (
                <div className={styles.inputBlock}>
                  <div className={styles.docTitleWrapper}>
                    <Icons variant={ICON_VARIANT_TYPE.DOCUMENT} />
                    <h4>Documents</h4>
                  </div>
                  <Documents
                    value={appointmentFormData?.AppointmentDocuments}
                    taskId={appointmentFormData?.ShowingId}
                    onDelete={onDeleteDocument}
                    onSave={onSaveHandler}
                  />
                </div>
              ) : null}
              <div className={styles.formFooter}>
                <Button onClick={handleResetDocuments} type="text">
                  Reset
                </Button>
                <Button className={styles.continueBtn} onClick={() => formik.handleSubmit()}>
                  Submit
                </Button>
              </div>
            </div>
          )}
        </form>
      </Col>
    </Row>
  );
};

BookingForm.defaultProps = {
  setAppointmentFormData: () => {},
};
