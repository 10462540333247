import { useEffect, useState } from 'react';

import { Avatar } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { TransactionContacts } from 'types';

import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionContactsEffect } from 'store/effects';
import Icons from './../../../Icons';
import {
  getTransactionAccessSelector,
  getTransactionContactsSelector,
} from 'store/selectors/transaction';
import classNames from 'classnames';
import { TransactionUserRoleMap } from 'settings/constants/transaction';

const Participant = ({ participant, isInvitee = false }) => {
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const { Name, Role, TransactionAccess, AvatarUrl, Email, Phone, CompanyName, IsPrivate } =
    participant;

  return (
    <div className={styles.participant}>
      <div className={styles.left}>
        <Avatar
          avatarClassName={styles.avatar}
          src={AvatarUrl}
          placeholder={convertNameToAvatarPlaceholder(Name)}
        />
        <div className={styles.contactDetails}>
          <span className={styles.name}>{Name}</span>
          <span className={styles.role}>{TransactionUserRoleMap[Role] || Role}</span>
          {CompanyName && <span className={styles.role}>{CompanyName}</span>}
          <span className={styles.email}>{Email}</span>
          {participant?.Phone && <span className={styles.phone}>{Phone}</span>}
        </div>
      </div>
      {isInvitee && fullAccess && (
        <div className={styles.right}>
          <span className={styles.accessText}>{TransactionAccess ? 'Limited' : 'Full'} Access</span>
        </div>
      )}
      {IsPrivate && (
        <div className={styles.right}>
          <Icons variant={Icons.LOCK} />
        </div>
      )}
    </div>
  );
};

export const Contacts = ({ participants, transactionId, isProject }) => {
  const dispatch = useDispatch();
  const contacts: TransactionContacts = useSelector(getTransactionContactsSelector);

  const formattedParticipants = participants?.map((item) => ({
    Name: item?.FirstName + ' ' + item?.LastName,
    Role: item?.Role?.Title,
    Email: item?.Email,
    TransactionAccess: item?.TransactionAccess,
    AvatarUrl: item?.AvatarUrl,
    Phone: item?.Phone,
  }));

  useEffect(() => {
    dispatch(
      getTransactionContactsEffect({
        id: transactionId,
      }),
    );
  }, [transactionId]);

  return (
    <>
      <div className={classNames(styles.contactTab, styles.inviteBg)}>
        <p className={styles.title}>Invited to {isProject ? 'Project' : 'Transaction'}</p>
        <div className={styles.content}>
          {formattedParticipants?.length > 0 ? (
            formattedParticipants.map((item, idx) => (
              <Participant key={idx} participant={item} isInvitee />
            ))
          ) : (
            <span>No Invitees</span>
          )}
        </div>
      </div>
      <div className={styles.contactTab}>
        <p className={styles.title}>Contact Information</p>
        <div className={styles.content}>
          {contacts?.length > 0 ? (
            contacts.map((item, idx) => <Participant key={idx} participant={item} />)
          ) : (
            <span>No Contacts</span>
          )}
        </div>
      </div>
    </>
  );
};
