import styles from './styles.module.scss';
import WishListRow from './WishListRow';

type WishListPropsType = {
  needs: any[];
  wants: any[];
  hideFields: boolean;
  countStarredFields: number;
  starredFieldsCountHandler: Function;
};

function WishList(props: WishListPropsType) {
  return (
    <table className={styles.wishListTable}>
      {props.needs && props.needs.length && (
        <WishListRow
          sectionName={'Needs'}
          hideFields={props.hideFields}
          needs={props.needs}
          wants={props.wants}
          countStarredFields={props.countStarredFields}
          starredFieldsCountHandler={props.starredFieldsCountHandler}
        />
      )}
      {props.wants && props.wants.length && (
        <WishListRow
          sectionName={'Wants'}
          hideFields={props.hideFields}
          needs={props.needs}
          wants={props.wants}
          countStarredFields={props.countStarredFields}
          starredFieldsCountHandler={props.starredFieldsCountHandler}
        />
      )}
    </table>
  );
}

export default WishList;
