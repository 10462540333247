import { routes } from 'settings/navigation/routes';
import { ExternalOffer } from 'pages';
import { OfferPortal } from 'pages';

export default [
  {
    path: routes.offerSubmission,
    component: ExternalOffer,
    exact: true,
  },
  {
    path: routes.offerPortal,
    component: OfferPortal,
    exact: true,
  },
];
