import { combineReducers } from 'redux';

import status from './status';
import messages from './messages';
import threads from './threads';
import notifications from './notifications';
import transactionMessages from './transactionMessages';
import transactionTaskMessages from './transactionTaskMessages';
import transactionNotes from './transactionNotes';
import offerMessages from './offerMessages';

export default combineReducers({
  status,
  messages,
  threads,
  notifications,
  transactionMessages,
  transactionTaskMessages,
  transactionNotes,
  offerMessages,
});
