import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Menu } from 'antd';
import { useDispatch } from 'react-redux';
import fileDownload from 'js-file-download';

import { PdfViewModal } from 'components';
import { Icons } from 'pages/Workshop/Icons';
import { DocumentVaultResponseType } from 'types';
import { getLocaleDate, limitTextLength } from 'helpers/index';
import { previewDocumentEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';

export const Document = ({ document, offerIteration, offerData }) => {
  const dispatch = useDispatch();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [attachment, setAttachment] = useState<DocumentVaultResponseType>();

  const { BuyingAgentName } = offerData;
  const getAuthorName = () => {
    let authorName;
    if (offerIteration?.SubmittedBy) {
      authorName =
        offerIteration?.SubmittedBy?.FirstName + ' ' + offerIteration?.SubmittedBy?.LastName;
    } else {
      authorName = BuyingAgentName || '';
    }
    return authorName;
  };

  const onDownload = () => {
    if (document.DocumentVaultUUID) {
      dispatch(
        previewDocumentEffect(
          { DocumentVaultUUID: document.DocumentVaultUUID },
          {},
          (err, response) => {
            if (err) {
              return;
            }
            const fileName = response.headers['file-name'];
            const getFileExtension = () => {
              if (fileName.endsWith('.pdf')) {
                return '.pdf';
              } else {
                return '';
              }
            };

            const fileExtension = getFileExtension();
            const fileTitle = document?.DocumentName.endsWith(fileExtension)
              ? document?.DocumentName
              : `${document?.DocumentName}${fileExtension}`;
            fileDownload(response.data, fileTitle || response.headers['file-name']);
          },
        ),
      );
    }
  };

  const onPreview = () => {
    if (document.DocumentVaultUUID) {
      setPreviewOpen(true);
      dispatch(
        previewDocumentEffect(
          { DocumentVaultUUID: document.DocumentVaultUUID },
          {},
          (err, response) => {
            if (!err) {
              setAttachment({
                DocumentBuffer: response,
                Filename: document.DocumentName || response.headers['file-name'],
              });
            }
          },
        ),
      );
    }
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setAttachment(undefined);
  };

  const menu = (
    <Menu className={styles.preApprovalMenu}>
      <Menu.Item
        className={styles.action}
        onClick={onPreview}
        icon={<Icons variant={'previewMenu'} />}
      >
        Preview
      </Menu.Item>
      <Menu.Item
        className={styles.action}
        onClick={onDownload}
        icon={<Icons variant={'downloadMenu'} />}
      >
        Download
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.document}>
      <Icons className={styles.docIcon} variant="document" />
      <div className={styles.info}>
        <h4>{limitTextLength(document.DocumentName || document.Filename, 20)}</h4>
        <p>
          {limitTextLength(getAuthorName(), 15)} {'-'}{' '}
          {moment(getLocaleDate(document.CreatedDate)).format('MMM DD')}
          <span className={styles.time}>
            {' '}
            at {moment(getLocaleDate(document.CreatedDate)).format('hh:mm A')}
          </span>
        </p>
      </div>
      <Dropdown overlay={menu} className={styles.dropdown} placement={'bottomRight'}>
        <a onClick={(e) => e.preventDefault()}>
          <Icons className={styles.moreIcon} variant="more" />
        </a>
      </Dropdown>
      <PdfViewModal isOpen={previewOpen} file={attachment} onClose={handlePreviewClose} />
    </div>
  );
};

Document.propTypes = {
  document: PropTypes.objectOf(
    PropTypes.shape({
      Id: PropTypes.number,
      ShowingAppointmentId: PropTypes.number,
      filename: PropTypes.string,
      documentName: PropTypes.string,
      DocumentType: PropTypes.string,
      DocumentLink: PropTypes.string,
      CreatedDate: PropTypes.string,
      UpdatedDate: PropTypes.string,
    }),
  ),
  offerData: PropTypes.object,
};

Document.defaultProps = {
  document: {},
  offerData: {},
};
