import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button, Checkbox, Collapse, Panel } from 'components-antd';
import { Add, Check, Cross, Remove } from 'components/Icons';
import { NoTimeline } from '../../components/NoTimeline';
import { CancelModal } from '../../components/CancelModal';
import { AcceptMultiModal } from '../../components/AcceptMultiModal/AcceptMultiModal';
import { Tour } from 'types/tours';
import { CardActions, TourSort, TourStatus } from 'app-constants/enums/tours';
import { fetchTours } from '../../helper';
import styles from './styles.module.scss';
import { PanelHeader } from '../../components/PanelHeader';
import { MultiSelectFooter } from '../../components/MultiSelectFooter';
import EmptyToursCard from '../EmptyToursCard';

export const Requests = (props) => {
  const { selectedSort, selectedTeamFilter } = props;
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedKey, setSelectedKey] = useState();
  const [selectedTours, setSelectedTours] = useState<Tour[]>([]);
  const [action, setAction] = useState<string>('');
  const { tours: groupedTours } = useSelector(
    fetchTours(selectedSort, TourStatus.Requested, selectedTeamFilter),
  );
  useEffect(() => {
    resetState();
  }, [selectedSort]);

  const resetState = () => {
    setSelectedTours([]);
    setSelectedKey(undefined);
    setAction('');
  };
  const onCheckboxChange = (checked: boolean, tour: Tour) => {
    if (!checked) {
      setSelectedTours(selectedTours.filter((e) => e.Id !== tour.Id));
      return;
    }
    setSelectedTours([...selectedTours, tour]);
  };
  const checkboxChecked = (tour) => {
    return selectedTours.some((e) => e.Id === tour.Id);
  };
  const onHeaderCheckboxChange = (checked: boolean, key) => {
    if (!checked) {
      setSelectedTours([]);
      return;
    }
    selectAll(key);
  };
  const selectAll = (key) => {
    setSelectedTours(groupedTours[key]);
  };

  const isMultiSelect = (key) => {
    return (
      selectedSort === TourSort.ClientName && action === CardActions.Multiple && key === selectedKey
    );
  };
  const onActionsClick = (type: CardActions, key) => {
    switch (type) {
      case CardActions.Multiple:
        setAction(CardActions.Multiple);
        setSelectedKey(key);
        break;

      default:
        break;
    }
  };
  return (
    <div className={styles.requestsWrap}>
      {!Object.keys(groupedTours).length ? <EmptyToursCard text={'tour requests'} /> : <></>}
      {Object.keys(groupedTours).map((key, i) => (
        <Collapse
          bordered={false}
          accordion
          expandIcon={({ isActive }) =>
            isMultiSelect(key) ? (
              <Checkbox
                className={classNames(styles.selectAll, 'mosaikCheckbox')}
                onChange={(e) => {
                  onHeaderCheckboxChange(e.target.checked, key);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            ) : isActive ? (
              <Remove color={Remove.COLLAPSE} />
            ) : (
              <Add color={Add.COLLAPSE} />
            )
          }
          expandIconPosition={'end'}
          className={classNames(styles.tourCards, {
            [styles.disabled]:
              selectedSort === TourSort.ClientName &&
              action === CardActions.Multiple &&
              key !== selectedKey,
          })}
          ghost={true}
          defaultActiveKey={i}
          key={i}
        >
          <Panel
            header={
              <PanelHeader
                onActionsClick={onActionsClick}
                sort={selectedSort}
                groupedKey={key}
                tours={groupedTours[key]}
              ></PanelHeader>
            }
            className={styles.tourCard}
            key={i}
          >
            {groupedTours[key].map((tour, i) => (
              <NoTimeline
                tour={tour}
                sort={selectedSort}
                key={i}
                hideSeparator={groupedTours[key].length - 1 === i}
              >
                <div className={styles.requestActions}>
                  {isMultiSelect(key) ? (
                    <Checkbox
                      className={classNames(styles.sortCheckbox, 'mosaikCheckbox')}
                      key={i}
                      onChange={(e) => {
                        onCheckboxChange(e.target.checked, tour);
                      }}
                      checked={checkboxChecked(tour)}
                    ></Checkbox>
                  ) : (
                    <>
                      <Button
                        className={classNames(styles.button, styles.buttonAccept)}
                        variant="default"
                        onClick={() => {
                          setShowApproveModal(true);
                          setSelectedTours([tour]);
                        }}
                      >
                        <Check className={styles.raIcon} color="#00B152" />
                      </Button>
                      <Button
                        className={classNames(styles.button, styles.buttonReject)}
                        variant="default"
                        onClick={() => {
                          setShowCancelModal(true);
                          setSelectedTours([tour]);
                        }}
                      >
                        <Cross className={styles.raIcon} color="#EC455E" />
                      </Button>
                    </>
                  )}
                </div>
              </NoTimeline>
            ))}
          </Panel>
        </Collapse>
      ))}

      {selectedTours.length > 0 && showApproveModal && (
        <AcceptMultiModal
          tours={selectedTours}
          acceptModal={showApproveModal}
          onCancel={() => {
            setShowApproveModal(false);
            resetState();
          }}
          onSubmit={() => {
            setShowApproveModal(false);
            resetState();
          }}
          title="Confirm Tour(s)"
        />
      )}

      {selectedTours.length > 0 && showCancelModal && (
        <CancelModal
          showModal={showCancelModal}
          tours={selectedTours}
          onCancel={() => {
            setShowCancelModal(false);
            resetState();
          }}
          onSubmit={() => {
            setShowCancelModal(false);
            resetState();
          }}
        ></CancelModal>
      )}
      <MultiSelectFooter
        onDiscard={() => setShowCancelModal(true)}
        onConfirm={() => setShowApproveModal(true)}
        onCancel={() => resetState()}
        action={action}
        tours={selectedTours}
        status={TourStatus.Requested}
      ></MultiSelectFooter>
    </div>
  );
};
