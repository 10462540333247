import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox } from 'antd';

import {
  getAddEditSchoolOpenSelector,
  getSchoolRawDataSelector,
} from 'store/selectors/searchResults';
import { Answers, Drawer, Button } from 'components';
import { Tag } from 'components-antd';
import { Footer, ScrollDrawerWrapper } from 'pages/Properties/SearchResults/components';
import {
  openSearchResultsFilterDrawerEffect,
  openAddEditSchoolDrawerEffect,
  saveAddEditSchoolDataEffect,
  saveAddEditSchoolRawDataEffect,
  showWishListEffect,
} from 'store/effects';
import {
  SchoolsDistrictsSearch,
  SpecificSchoolOrDistrict,
} from './SchoolsDistrictsSearch/SchoolsDistrictsSearch';
import { ArrowLeft } from 'components/Icons';

import styles from './styles.module.scss';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

const answers = [
  { id: 'yes', label: 'Yes' },
  { id: 'no', label: 'No' },
];

const schoolTypeOptions = ['Public', 'Private', 'Magnet', 'Charter'];

interface Props {
  onAddSchool: (...data) => void;
}

export const SchoolDrawer: FC<Props> = ({ onAddSchool }) => {
  const dispatch = useDispatch();

  const open = useSelector(getAddEditSchoolOpenSelector);
  const { schoolTypes: schoolTypesRawData, specificItems: specificItemsRawData } =
    useSelector(getSchoolRawDataSelector);

  const [isInterestedInSpecific, setInterestedInSpecific] = useState<boolean | null>(true);
  const [schoolTypes, setSchoolTypes] = useState<CheckboxValueType[]>([]);
  const [specificItems, setSpecificItems] = useState<SpecificSchoolOrDistrict[]>([]);

  const closeClickHandler = (backToWishlist: boolean) => {
    dispatch(showWishListEffect({ showWishlistTab: backToWishlist }));
    dispatch(openAddEditSchoolDrawerEffect({ open: false, mode: '' }));
    dispatch(openSearchResultsFilterDrawerEffect({ open: true }));
  };
  const handleRemoveTag = (specificData: SpecificSchoolOrDistrict) => {
    setSpecificItems((data) => data.filter((val) => val !== specificData));
  };
  const handleSchoolSearchForm = async () => {
    const combinedValue = {} as any;
    dispatch(saveAddEditSchoolRawDataEffect({ schoolTypes, specificItems }));

    const selectedSpecifics = specificItems?.map((item) => JSON.parse(item.value) as any) ?? [];

    combinedValue.SpecificSchools = selectedSpecifics.filter((item) => item.Type === 'School');

    combinedValue.SpecificDistricts = selectedSpecifics.filter((item) => item.Type === 'District');
    combinedValue.NonSpecificConfig = schoolTypes;

    await dispatch(showWishListEffect({ showWishlistTab: true }));
    onAddSchool(combinedValue);
    dispatch(saveAddEditSchoolDataEffect({ schoolData: combinedValue }));
  };
  const getSchoolName = (label) => label.split(',')[0];
  const getHeader = () => {
    return (
      <div className={styles.backText} onClick={() => closeClickHandler(true)}>
        <ArrowLeft className={styles.leftIcon} />
        Wish List
      </div>
    );
  };

  const handleSpecificSchoolSelection = (val: string[]) => {
    const isYes = val.includes('yes');
    setInterestedInSpecific(isYes);
    if (isYes) {
      setSchoolTypes(schoolTypesRawData);
    } else {
      setSpecificItems(specificItemsRawData);
    }
  };

  const getTags = () => {
    return (
      <div className={styles.tagItemsContainer}>
        {specificItems.map((data) => (
          <Tag
            className={styles.tagItems}
            closable
            onClose={() => handleRemoveTag(data)}
            color="default"
            key={data.label as string}
          >
            {getSchoolName(data.label)?.length > 25
              ? `${getSchoolName(data.label).slice(0, 25)}...`
              : getSchoolName(data.label)}
          </Tag>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (!open) {
      setInterestedInSpecific(true);
      setSchoolTypes([]);
      setSpecificItems([]);
    } else {
      setSchoolTypes(schoolTypesRawData);
      setSpecificItems(specificItemsRawData);
    }
  }, [open]);

  return (
    <Drawer
      onClose={() => closeClickHandler(false)}
      className={classNames(styles.schoolDrawer)}
      hideOpacityLayer={false}
      isOpen={open}
      header={getHeader()}
    >
      <div>
        <ScrollDrawerWrapper className={styles.scrollWrapper}>
          <div className={styles.wrapper}>
            <h2 className={styles.title}>Schools</h2>

            <p className={styles.subtitle}>
              Is there a specific school or school district that is important to you?
            </p>
            <Answers
              value={isInterestedInSpecific ? ['yes'] : !isInterestedInSpecific ? ['no'] : null}
              displayOptionsInline
              cases={answers}
              onChange={handleSpecificSchoolSelection}
              caseClassName={styles.answer}
              activeCaseClassName={styles.answerActive}
              labelClassName={styles.label}
              className={styles.answersWrapper}
            />
            {isInterestedInSpecific ? (
              <div>
                <p className={styles.subtext}>Which one(s)?</p>
                <div className={styles.schoolsDistrictInputWrapper}>
                  <SchoolsDistrictsSearch
                    isMultiple
                    value={specificItems}
                    onChange={(newValue) => {
                      setSpecificItems(newValue as SpecificSchoolOrDistrict[]);
                    }}
                  />
                  {getTags()}
                </div>
              </div>
            ) : (
              <div>
                <p className={classNames(styles.subtext, styles.typetext)}>
                  What type of schools are you interested in?
                </p>
                <div>
                  <Checkbox.Group
                    className={styles.checkboxGroup}
                    options={schoolTypeOptions}
                    onChange={setSchoolTypes}
                    value={schoolTypes}
                  />
                </div>
              </div>
            )}
          </div>
        </ScrollDrawerWrapper>
        <Footer className={styles.footer}>
          <Button onClick={handleSchoolSearchForm} className={styles.submitButton} title="Save" />
        </Footer>
      </div>
    </Drawer>
  );
};
