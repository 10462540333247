import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { AddPhoto, FormGroup } from 'components';
import { getUserDataSelector, getUserRoleSelector, isPartnerSelector } from 'store/selectors/user';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { onBoardingSignUpEffect } from 'store/effects';
import { PENDING } from 'settings/constants/apiState';

import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

import styles from './styles.module.scss';
import { useExitOnboarding } from 'pages/OnBoarding/hooks/useExitOnboarding';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { OnboardingContinueButton } from '../../agent/components/OnboardingContinueButton';
import { OnboardingSkipButton } from '../../agent/components/OnboardingSkipButton';

type Props = {
  className: string;
  onNext: () => {};
  stageIndex?: number;
  Controls: React.ElementType;
};

const BusinessPhotoForm: React.FC<Props> = (props) => {
  const { className, onNext, stageIndex, Controls } = props;
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const userRole = useSelector(getUserRoleSelector);
  const { onBoarding } = useSelector(getOnBoardingData);
  const isPartner = useSelector(isPartnerSelector);
  const { isPending: isExitOnboardingPending, handleExit: exitOnboarding } = useExitOnboarding();

  const isPending = onBoarding.state === PENDING;

  const skipVendorPreference = useMemo(
    () => (user?.ThirdParty && !user?.ThirdParty?.Partner ? true : false),
    [user?.ThirdParty?.Partner],
  );

  const onSkip = () => (skipVendorPreference ? exitOnboarding() : onNext());

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        onBoardingSignUpEffect(
          { ...values, stageIndex },
          { isPartner, isCommonInfo: true },
          (err) => {
            if (!err) onSkip();
          },
        ),
      );
    },
    [(onSkip, stageIndex, dispatch)],
  );

  const getLogoValue = () => {
    if (isPartner) {
      return user?.[userRole]?.Partner?.LogoUrl;
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      logoUrl: getLogoValue(),
    },
    onSubmit,
  });

  const getValue = () => {
    const img = formik.values?.logoUrl;
    if (typeof img === 'string') {
      return img;
    }
    const image = `data:${img?.ContentType};base64,${btoa(img?.Url)}`;

    return image;
  };

  return (
    <OnBoardingWrapper isPending={isPending} className={styles.wrapper}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />

      <OnBoardingContainer>
        <Question className={styles.title}>
          Do you want to add a logo for your business profile?
        </Question>
        <AnswersContainer className={styles.profilePhotoWrapper}>
          <FormGroup className={styles.formGroup}>
            <form onSubmit={formik.handleSubmit}>
              <AddPhoto
                variant={AddPhoto.LIGHT}
                value={getValue()}
                onChange={(image, files) => formik.setFieldValue('logoUrl', files[0])}
                binary
              />
            </form>
          </FormGroup>
        </AnswersContainer>

        <ButtonsContainer>
          <OnboardingContinueButton
            isPending={isPending}
            disabled={!formik.values?.logoUrl}
            testid="next"
            htmlType="submit"
            className={classNames(styles.submitBtn, {
              [styles.active]: formik.values?.logoUrl,
            })}
            onClick={() => formik.submitForm()}
          />
          <OnboardingSkipButton
            isPending={isExitOnboardingPending}
            testid="skip"
            onClick={onSkip}
            className={styles.skip}
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

export { BusinessPhotoForm };
