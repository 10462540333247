const SaveIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22" fill="none">
      <path
        d="M1.33398 3.66797V20.3346L8.00065 16.3346L14.6673 20.3346V3.66797C14.6673 2.5634 13.7719 1.66797 12.6673 1.66797H3.33398C2.22942 1.66797 1.33398 2.5634 1.33398 3.66797Z"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SaveIcon;
