import {
  deleteFormTemplate,
  getFormTemplateById,
  postFormTemplate,
  putFormTemplate,
} from 'api/templates';
import {
  requestDeleteFormTemplateAction,
  requestGetFormTemplateAction,
  requestPostFormTemplateAction,
  requestPutFormTemplateAction,
  setFormTemplateDrawerOpenedAction,
} from 'store/actions/template';
import Api from 'store/effects/core/api';
import { getAllFormTemplatesEffect } from 'store/effects/templates';
import { get } from 'lodash-es';

export const getFormTemplateEffect = ({ id }, options, cb) => {
  const requestParams = { action: requestGetFormTemplateAction, method: getFormTemplateById };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest({ id }, options, cb);
};

export const setFormTemplateDrawerOpenedEffect = (isOpened, params) => (dispatch) => {
  dispatch(
    setFormTemplateDrawerOpenedAction({
      isDrawerOpened: isOpened,
      drawerParams: params || {},
    }),
  );
};
const getFormTemplateSendData = (values) => ({
  Name: get(values, 'Name') || undefined,
  Description: get(values, 'Description') || undefined,
  DueDate: values?.DueDate || values?.DueDate == 0 ? +values.DueDate : undefined,
  Initial: values?.Initial,
  Operator: values?.Operator,
  ControlOperatorOffset: values?.ControlOperatorOffset || undefined,
  AssigneeRoles: get(values, 'AssigneeRoles'),
  CcRoles: get(values, 'CcRoles'),
  IsRequired: get(values, 'IsRequired'),
});

export const postFormTemplateEffect = (data, options, cb) => {
  const requestParams = { action: requestPostFormTemplateAction, method: postFormTemplate };
  const sendRequest = Api.execBase(requestParams);

  const sendData = getFormTemplateSendData(data);
  return sendRequest(sendData, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllFormTemplatesEffect({}, { silent: true }));
    }
    if (cb) cb(err, response, dispatch);
  });
};

export const putFormTemplateEffect = (data, options, cb) => {
  const requestParams = { action: requestPutFormTemplateAction, method: putFormTemplate };
  const sendRequest = Api.execBase(requestParams);

  const sendData = getFormTemplateSendData(data);

  return sendRequest(sendData, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllFormTemplatesEffect({}, { silent: true }));
    }
    if (cb) cb(err, response, dispatch);
  });
};

export const deleteFormTemplateEffect = ({ id }, options, cb) => {
  const requestParams = { action: requestDeleteFormTemplateAction, method: deleteFormTemplate };
  const sendRequest = Api.execBase(requestParams);

  return sendRequest({ id }, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllFormTemplatesEffect({}, { silent: true }));
    }
    if (cb) cb(err, response, dispatch);
  });
};
