import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  Select,
  Option,
  Row,
  Col,
  Switch,
  ConfirmationModal,
  Cascader,
  TextArea,
  InputNumber,
} from 'components-antd';
import {
  DEFAULT_QUESTION_META,
  FORM_FONT_FAMILY,
  FORM_QUESTION_TYPE,
  FORM_USER_ROLE,
  PDF_FIELD_TYPE,
  SMART_FORM_QUESTION_TYPE,
} from 'app-constants';
import { CheckBox, SearchIcon } from 'components/Icons';
import QuestionIcon from 'pages/FormBuilder/Icons/QuestionIcon';
import {
  updateSmartFormMetaEffect,
  updateSmartFormQuestionEffect,
} from 'store/effects/formBuilder';
import {
  getSmartFormCurrentQuestionSelect,
  getSmartFormMetaSelect,
} from 'store/selectors/formBuilder';
import { DateType, FormQuestionType, FormUserRoleType, ListBoxType, QuestionType } from 'types';
import { getPopulateWithOptions } from 'utils';

import styles from './styles.module.scss';

export const SmartSettingPane = () => {
  const dispatch = useDispatch();

  const { disabled, questionRoles, questions, disassociatedFields, wizardViewEnabled } =
    useSelector(getSmartFormMetaSelect);
  const question = useSelector(getSmartFormCurrentQuestionSelect);

  const [required, setRequired] = useState<boolean>();
  const [dateRange, setDateRange] = useState<boolean>();
  const [multiSelect, setMultiSelect] = useState<boolean>();
  const [type, setType] = useState<FormQuestionType>();
  const [focus, setFocus] = useState(false);

  const [showWarning, setShowWaring] = useState(false);
  const [updatedType, setUpdatedType] = useState<FormQuestionType>();
  const [populateWith, setPopulateWith] = useState<string[] | null | undefined>(
    question?.PopulateWith,
  );

  const [title, setTitle] = useState('');
  const [questionName, setQuestionName] = useState('');

  const [roles, setRoles] = useState<FormUserRoleType[]>([]);

  const populateWithOptions = useCallback(
    () => (question?.Type ? getPopulateWithOptions(question?.Type) || [] : []),
    [question?.Type],
  );

  useEffect(() => {
    setType(question?.Type);
    setPopulateWith(question?.PopulateWith);
    setRequired(question?.Required);

    setTitle(question?.Title || '');
    setQuestionName(question?.Name || '');

    if (question?.Type === FORM_QUESTION_TYPE.Date)
      setDateRange((question?.Meta as DateType)?.DateRange);

    if (question?.Type === FORM_QUESTION_TYPE.ListBox)
      setMultiSelect((question?.Meta as ListBoxType)?.MultiSelect);

    setRoles(question?.RolePermissions || []);
  }, [question]);

  const updateRequired = (checked: boolean) => {
    if (question) {
      const updatedQuestion = {
        ...question,
        Required: checked,
      };
      dispatch(updateSmartFormQuestionEffect(updatedQuestion));
      setRequired(checked);
    }
  };

  const updateDateType = (checked: boolean) => {
    if (question) {
      const updatedQuestion = {
        ...question,
        Meta: {
          DateRange: checked,
        },
      };
      dispatch(updateSmartFormQuestionEffect(updatedQuestion));
      setDateRange(checked);
    }
  };

  const updateMultiSelect = (checked: boolean) => {
    if (question) {
      const updatedQuestion = {
        ...question,
        Meta: {
          MultiSelect: checked,
          ListBoxOptions: (question?.Meta as ListBoxType)?.ListBoxOptions,
        },
        JumpLogic: checked ? null : question.JumpLogic,
      };
      dispatch(updateSmartFormQuestionEffect(updatedQuestion));
      setMultiSelect(checked);
    }
  };

  const confirmQuestionTypeUpdate = (e) => {
    setUpdatedType(e);
    setShowWaring(true);
  };

  const questionTypeUpdate = () => {
    if (updatedType && question) {
      const updatedQuestion = {
        ...question,
        Type: updatedType,
        JumpLogic: undefined,
        PopulateWith: null,
        Meta: DEFAULT_QUESTION_META[updatedType],
        Title: question.Title || '',
      };

      dispatch(updateSmartFormQuestionEffect(updatedQuestion));
      setType(updatedType);
      setPopulateWith([]);
    }
    clearWarningModal();
  };

  const clearWarningModal = () => {
    setUpdatedType(undefined);
    setShowWaring(false);
  };

  const getQuestionTypes = () => {
    switch (question?.FieldType) {
      case PDF_FIELD_TYPE.PDFTextField:
        return [
          SMART_FORM_QUESTION_TYPE.Date,
          SMART_FORM_QUESTION_TYPE.Currency,
          SMART_FORM_QUESTION_TYPE.Email,
          SMART_FORM_QUESTION_TYPE.FreeText,
          SMART_FORM_QUESTION_TYPE.Number,
          SMART_FORM_QUESTION_TYPE.PhoneNumber,
        ].sort();
      case PDF_FIELD_TYPE.PDFCheckBox:
        return [SMART_FORM_QUESTION_TYPE.ListBox];
      case PDF_FIELD_TYPE.PDFDropdown:
        return [SMART_FORM_QUESTION_TYPE.ListBox];
      case PDF_FIELD_TYPE.PDFOptionList:
        return [SMART_FORM_QUESTION_TYPE.ListBox];
      case PDF_FIELD_TYPE.PDFRadioGroup:
        return [SMART_FORM_QUESTION_TYPE.ListBox];
      case PDF_FIELD_TYPE.PDFSignature:
        return [SMART_FORM_QUESTION_TYPE.Signature];

      default:
        return [];
    }
  };

  const updateQuestionMeta = (key, value) => {
    if (question) {
      dispatch(updateSmartFormQuestionEffect({ ...question, [key]: value }));
    }
  };

  const questionPopulateWithUpdate = (options: string[]) => {
    if (question) {
      setPopulateWith(options);
      dispatch(updateSmartFormQuestionEffect({ ...question, PopulateWith: options || null }));
    }
  };

  const handleFieldAttributeUpdate = (key, value) => {
    if (question?.Fields?.length && question?.Fields?.[0]) {
      const updatedQuestion: QuestionType = {
        ...question,
        Fields: question.Fields.map((f) => {
          return {
            ...f,
            [key]: value,
          };
        }),
      };
      dispatch(updateSmartFormQuestionEffect(updatedQuestion));
    }
  };

  const updateFontFamily = (font) => {
    const updatedQuestions = questions.map((q) => {
      if (q.FieldType === PDF_FIELD_TYPE.PDFTextField && q.Fields?.length) {
        q.Fields = q.Fields.map((f) => {
          f.fontFamily = font;

          return f;
        });
      }

      return q;
    });

    const updatedDisassociatedFields = disassociatedFields?.length
      ? disassociatedFields.map((d) => {
          if (d.fieldType === PDF_FIELD_TYPE.PDFTextField) {
            d.fontFamily = font;
          }
          return d;
        })
      : disassociatedFields;
    dispatch(
      updateSmartFormMetaEffect({
        disassociatedFields: updatedDisassociatedFields,
        questions: updatedQuestions,
        lastUpdated: new Date(),
      }),
    );
  };

  return (
    <div className={styles.settingPane} data-testid="smart-setting-page">
      <ConfirmationModal
        variant="Warning"
        open={showWarning}
        confirmText={
          <span>
            Are you sure you want to update question type to &quot;{updatedType}&quot;? <br />
            <br />
            <i>All question related changes will be lost.</i>
          </span>
        }
        onOk={questionTypeUpdate}
        onCancel={clearWarningModal}
      />
      <Row>
        <Col span={24}>
          <label className={styles.label}>Question Type</label>
        </Col>
        <Col span={24}>
          <Select
            disabled={disabled}
            large
            showArrow
            getPopupContainer={(triggerNode) => triggerNode}
            value={type}
            className={styles.selectBox}
            onChange={confirmQuestionTypeUpdate}
          >
            {getQuestionTypes().map((key) => (
              <Option value={key} key={`question-type-${key}`}>
                <span className={styles.questionTypeOption}>
                  <QuestionIcon variant={key} className={styles.questionLogo} />
                  {key}
                </span>
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row className={styles.formRow}>
        <Col span={24}>
          <label className={styles.label}>Question Name</label>
        </Col>
        <Col span={24}>
          <TextArea
            disabled={disabled}
            autoSize
            placeholder={'Enter a question name...'}
            maxLength={64}
            rows={4}
            value={questionName}
            onChange={(e) => setQuestionName(e.target.value)}
            onFocus={(e) => setFocus(true)}
            onBlur={(e) => {
              setFocus(false);
              updateQuestionMeta('Name', e.target.value);
            }}
          />
        </Col>
      </Row>

      {question?.Type !== SMART_FORM_QUESTION_TYPE.Signature && (
        <Row className={styles.formRow}>
          <Col span={24}>
            <label className={styles.label}>End-User Question</label>
          </Col>
          <Col span={24}>
            <TextArea
              disabled={disabled || !wizardViewEnabled}
              autoSize
              maxLength={1024}
              placeholder={'Enter a question...'}
              rows={4}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onFocus={(e) => setFocus(true)}
              onBlur={(e) => {
                updateQuestionMeta('Title', e.target.value);
                setFocus(false);
              }}
            />
          </Col>
        </Row>
      )}
      {question?.FieldType === PDF_FIELD_TYPE.PDFTextField && question.Fields?.[0] && (
        <>
          <Row className={styles.formRow}>
            <Col span={24}>
              <label className={styles.label}>Font Family (All Fields)</label>
            </Col>
            <Col span={24}>
              <Select
                key={`${question.Fields?.[0]?.id}-font-family`}
                disabled={disabled}
                large
                showArrow
                getPopupContainer={(triggerNode) => triggerNode}
                defaultValue={question.Fields?.[0]?.fontFamily}
                className={classNames(styles.selectBox, styles.rolePermissionSelect)}
                onChange={updateFontFamily}
                menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                placeholder="Select Font Family"
                options={Object.values(FORM_FONT_FAMILY).map((font) => ({
                  label: (
                    <span style={{ textTransform: 'capitalize', fontFamily: font }}>{font}</span>
                  ),
                  value: font,
                  key: font,
                }))}
              />
            </Col>
          </Row>
          <Row className={styles.formRow}>
            <Col span={24}>
              <label className={styles.label}>
                Font Size PT {question.Fields?.[0]?.fontSize === 0 ? '(Auto Size)' : ''}
              </label>
            </Col>
            <Col span={24}>
              <InputNumber
                disabled={disabled}
                className={classNames(styles.inputNumber)}
                min={0}
                max={100}
                defaultValue={question.Fields?.[0]?.fontSize}
                onChange={(number) => handleFieldAttributeUpdate('fontSize', number ?? 0)}
                key={`${question.Fields?.[0]?.id}-font-size`}
              />
            </Col>
          </Row>
          <Row className={styles.formRow}>
            <Col span={24}>
              <label className={styles.label}>Max Length</label>
            </Col>
            <Col span={24}>
              <InputNumber
                disabled={disabled}
                className={classNames(styles.inputNumber)}
                min={1}
                max={10000}
                defaultValue={question.Fields?.[0]?.maxLength}
                onChange={(number) => handleFieldAttributeUpdate('maxLength', number)}
                key={`${question.Fields?.[0]?.id}-max-length`}
              />
            </Col>
          </Row>
        </>
      )}
      <Row className={styles.formRow}>
        <Col span={24}>
          <label className={styles.label}>Role Permission</label>
        </Col>
        <Col span={24}>
          <Select
            disabled={disabled}
            large
            showArrow
            getPopupContainer={(triggerNode) => triggerNode}
            value={
              question?.Type === SMART_FORM_QUESTION_TYPE.Signature
                ? question?.FormRole?.[0]
                : question?.RolePermissions?.[0]
            }
            className={classNames(styles.selectBox, styles.rolePermissionSelect)}
            onChange={(e) =>
              updateQuestionMeta(
                question?.Type === SMART_FORM_QUESTION_TYPE.Signature
                  ? 'FormRole'
                  : 'RolePermissions',
                [e],
              )
            }
            menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
            placeholder="Select Role"
          >
            {question?.Type === SMART_FORM_QUESTION_TYPE.Signature
              ? (questionRoles || []).map((role) => (
                  <Option value={role.Id} key={`role-type-${role.Id}`}>
                    {role.Name}
                  </Option>
                ))
              : Object.values(FORM_USER_ROLE).map((el) => (
                  <Option value={el} key={`role-type-${el}`}>
                    {el}
                  </Option>
                ))}
          </Select>
        </Col>
      </Row>

      <Row className={styles.formRow}>
        <Col span={24}>
          <label className={styles.label}>Populate With</label>
        </Col>
        <Col span={24}>
          <Cascader
            large
            allowClear={!focus}
            showSearch
            disabled={disabled}
            suffixIcon={<SearchIcon />}
            placeholder="Search sources"
            value={populateWith as any}
            options={populateWithOptions()}
            onChange={(value: any) => questionPopulateWithUpdate(value)}
          />
        </Col>
      </Row>

      {question?.Type !== FORM_QUESTION_TYPE.Signature && (
        <Row justify="space-between" className={styles.toggleRow}>
          <Col>
            <label className={styles.label}>Required</label>
          </Col>
          <Col>
            <Switch
              disabled={disabled}
              checked={required}
              onChange={updateRequired}
              data-testid="smart-setting-required"
            />
          </Col>
        </Row>
      )}

      {question?.Type === FORM_QUESTION_TYPE.Date && (
        <Row justify="space-between" className={styles.toggleRow}>
          <Col>
            <label className={styles.label}>Date Range</label>
          </Col>
          <Col>
            <Switch disabled={disabled} checked={dateRange} onChange={updateDateType} />
          </Col>
        </Row>
      )}

      {question?.Type === FORM_QUESTION_TYPE.ListBox &&
        question.FieldType !== PDF_FIELD_TYPE.PDFRadioGroup && (
          <Row justify="space-between" className={styles.toggleRow}>
            <Col>
              <label className={styles.label}>Multi-select</label>
            </Col>
            <Col>
              <Switch disabled={disabled} checked={multiSelect} onChange={updateMultiSelect} />
            </Col>
          </Row>
        )}
    </div>
  );
};
