import { useState } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'components-antd';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';

import styles from './styles.module.scss';

export const DateControlInfo = ({ isTemplate, isProject = false }) => {
  const [open, setOpen] = useState(false);

  const contentForTransaction = () => (
    <div className={styles.content}>
      <p>
        <span>
          You can preset dates using the date controls {isTemplate ? 'A' : 'T'}+, CD+, CL+/-, LE+/-
          and O+.
        </span>
      </p>
      <p>
        <span>{isTemplate ? 'A' : 'T'}+</span> will calculate the number of days from the day you
        create your transaction in Mosaik. Ex: {isTemplate ? 'A' : 'T'}+7 is 7 days from the date
        the transaction is created.
      </p>
      <p>
        <span>CD+</span> works the same way, but uses the contract effective date (if it is
        different).
      </p>
      <p>
        <span>CL+/-</span> represents the transaction closing date.
      </p>
      <p>
        <span>LE+/-</span> is the listing agreement expiration date and is applicable for listed
        transactions.
      </p>
      <p>
        <span>TL+/-</span> represents the target live date and is applicable for listings not yet on
        the market.
      </p>
      <p>
        <span>O+</span> signifies another date you want to use and can be specified at the time the
        transaction is created.
      </p>
    </div>
  );
  const contentForProject = () => (
    <div className={styles.content}>
      <p>
        <span>
          You can preset dates using the date controls {isTemplate ? 'A' : 'T'}+, KO+, DL+/-, LE+/-
          and O+.
        </span>
      </p>
      <p>
        <span>{isTemplate ? 'A' : 'T'}+</span> will calculate the number of days from the day you
        create your project in Mosaik. Ex: {isTemplate ? 'A' : 'T'}+7 is 7 days from the date the
        project is created.
      </p>
      <p>
        <span>KO+</span> works the same way, but uses the kickoff date (if it is different).
      </p>
      <p>
        <span>DL+/-</span> represents the project completion date.
      </p>
      <p>
        <span>O+</span> signifies another date you want to use and can be specified at the time the
        project is created.
      </p>
    </div>
  );

  return (
    <div
      className={styles.dateControlInfo}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Tooltip
        title={isProject ? contentForProject : contentForTransaction}
        placement="bottom"
        open={open}
        overlayClassName={styles.datControlsTooltip}
      >
        <Icon variant={Icon.INFO} />
      </Tooltip>
    </div>
  );
};

DateControlInfo.propTypes = {
  isTemplate: PropTypes.bool,
};

DateControlInfo.defaultProps = {
  isTemplate: false,
};
