import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getImageSrc } from 'utils';

interface DocumentVaultImageProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  documentVaultUUID?: string;
  data?: string;
  url?: string;
  contentType?: string;
}

export const DocumentVaultImage = ({
  documentVaultUUID,
  url,
  data,
  contentType,
  ...props
}: DocumentVaultImageProps) => {
  const dispatch = useDispatch();
  const [src, setSrc] = useState<string>();

  const handleImageLink = async () => {
    const imageSrc = await getImageSrc(url, data, contentType, documentVaultUUID, dispatch);
    setSrc(imageSrc);
  };

  useEffect(() => {
    handleImageLink();
  }, []);

  return useMemo(() => <img src={src} {...props} />, [src]);
};
