import { useState } from 'react';
import classnames from 'classnames';
import { Input, Checkbox } from 'antd/lib';
import type { CheckboxValueType } from 'antd/lib/checkbox/Group';
import type { CheckboxChangeEvent, CheckboxOptionType } from 'antd/lib/checkbox';

import { Icons, ICON_VARIANT_TYPE } from 'pages/Workshop/Forms/Icons';
import { FormDetailsEditorType, FormDetailsSignatoryType, FormDetailsUserType } from 'types';
import { Modal, ModalProps, Divider, Collapse, Panel, Row, Col, Button } from 'components-antd';

import styles from './styles.module.scss';

const CheckboxGroup = Checkbox.Group;
const { TextArea } = Input;

interface MegaPhoneModalProps extends ModalProps {
  signatories?: FormDetailsSignatoryType[];
  editors?: FormDetailsEditorType;
  handleSendMessage: (
    message: string,
    users: string[],
    setSendBtnLoading: (loading: boolean) => void,
    setShowMegaPhoneModal: (open: boolean) => void,
  ) => void;
  setShowMegaPhoneModal: (open: boolean) => void;
  onCloseModal?: () => void;
}

export const MegaPhoneModal = ({
  signatories,
  editors,
  handleSendMessage,
  setShowMegaPhoneModal,
  onCloseModal,
}: MegaPhoneModalProps) => {
  const [message, setMessage] = useState<string>('');
  const [sendBtnLoading, setSendBtnLoading] = useState(false);
  const [checkedList, setCheckedList] = useState<CheckboxOptionType[]>();
  const [checkedListEditor, setCheckedListEditor] = useState<CheckboxOptionType[]>();
  const [indeterminate, setIndeterminate] = useState(false);
  const [indeterminateEditor, setIndeterminateEditor] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkAllEditor, setCheckAllEditor] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const filteredSignatories: FormDetailsSignatoryType[] = [];
  signatories?.forEach((signatory) => {
    if (!filteredSignatories.map((signatory) => signatory.email).includes(signatory.email)) {
      filteredSignatories.push(signatory);
    }
  });

  const signatoryOptions: CheckboxOptionType[] = filteredSignatories.map(
    (signatory): CheckboxOptionType => ({
      label: signatory.name,
      value: signatory.email,
    }),
  );

  const editorsArray = [...(editors?.Client?.users || []), ...(editors?.Agent?.users || [])];

  const filteredEditors: FormDetailsUserType[] = [];
  editorsArray.forEach((editor) => {
    if (!filteredEditors.map((editor) => editor.email).includes(editor.email)) {
      filteredEditors.push(editor);
    }
  });

  const editorOptions: CheckboxOptionType[] = filteredEditors.map(
    (editor): CheckboxOptionType => ({
      label: editor.name,
      value: editor.email,
    }),
  );

  const onChangeSignature = () => {
    setIsActive(!isActive);
  };
  const onChange = (list: CheckboxValueType[]) => {
    const updatedList = signatoryOptions.filter((signatory) => list.includes(signatory.value));
    setCheckedList(updatedList);
    setIndeterminate(!!updatedList.length && updatedList.length < signatoryOptions.length);
    setCheckAll(updatedList.length === signatoryOptions.length);
  };
  const onChangeEditor = (list: CheckboxValueType[]) => {
    const updatedList = editorOptions.filter((editor) => list.includes(editor.value));
    setCheckedListEditor(updatedList);
    setIndeterminateEditor(!!updatedList.length && updatedList.length < editorOptions.length);
    setCheckAllEditor(updatedList.length === editorOptions.length);
  };
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? signatoryOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  const onCheckAllChangeEditor = (e: CheckboxChangeEvent) => {
    setCheckedListEditor(e.target.checked ? editorOptions : []);
    setIndeterminateEditor(false);
    setCheckAllEditor(e.target.checked);
  };
  const onChangeAll = (e: CheckboxChangeEvent) => {
    onCheckAllChangeEditor(e);
    onCheckAllChange(e);
  };

  const preparePayload = () => {
    const selectedSignatories: string[] =
      checkedList?.map((list): string => list.value as string) || [];
    const selectedEditors: string[] =
      checkedListEditor?.map((list): string => list.value as string) || [];

    handleSendMessage(
      message,
      [...selectedSignatories, ...selectedEditors],
      setSendBtnLoading,
      setShowMegaPhoneModal,
    );
  };

  return (
    <>
      <Modal
        open={true}
        width={675}
        footer={null}
        title="Message"
        onCancel={() => (onCloseModal ? onCloseModal() : '')}
        className={styles.MegaPhoneModal}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className={styles.MegaPhoneModalBody}>
          <TextArea
            value={message}
            id="Description"
            className={styles.messageInput}
            name="Description"
            rows={6}
            placeholder="Enter message..."
            onChange={(e) => setMessage(e.target.value)}
          />
          <Row>
            <Col xs={24} sm={24} md={24} xl={24} lg={24}>
              <p className={styles.sendTo}>Send to:</p>
            </Col>
          </Row>
          <Collapse
            onChange={onChangeSignature}
            expandIconPosition={'end'}
            ghost={true}
            key="signatories"
            expandIcon={({ isActive }) =>
              isActive ? (
                <Icons variant={ICON_VARIANT_TYPE.ARROW_UP} />
              ) : (
                <Icons variant={ICON_VARIANT_TYPE.ARROW_DOWN} />
              )
            }
          >
            <Panel
              header={
                <>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll || indeterminate}
                  >
                    <p className={styles.signatureCheckbox}>Signatories</p>
                  </Checkbox>
                </>
              }
              key="signatories"
              className={styles.historyPanel}
            >
              <CheckboxGroup
                options={signatoryOptions}
                value={checkedList?.map((list) => list.value)}
                onChange={onChange}
              />
            </Panel>
          </Collapse>
          <Collapse
            onChange={onChangeSignature}
            expandIconPosition={'end'}
            ghost={true}
            key="editors"
            expandIcon={({ isActive }) =>
              isActive ? (
                <Icons variant={ICON_VARIANT_TYPE.ARROW_UP} />
              ) : (
                <Icons variant={ICON_VARIANT_TYPE.ARROW_DOWN} />
              )
            }
          >
            <Panel
              header={
                <>
                  <Checkbox
                    indeterminate={indeterminateEditor}
                    onChange={onCheckAllChangeEditor}
                    checked={checkAllEditor || indeterminateEditor}
                  >
                    <p className={styles.editorCheckbox}>Editors</p>
                  </Checkbox>
                </>
              }
              key="signatories"
              className={styles.historyPanel}
            >
              <CheckboxGroup
                options={editorOptions}
                value={checkedListEditor?.map((list) => list.value)}
                onChange={onChangeEditor}
              />
            </Panel>
          </Collapse>
          <Row>
            <Col xs={24} sm={24} md={24} xl={24} lg={24}>
              <Checkbox
                checked={
                  checkedList?.length === signatoryOptions.length &&
                  checkedListEditor?.length === editorOptions.length
                }
                value="all"
                onChange={onChangeAll}
              >
                All
              </Checkbox>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col xs={24} sm={24} md={24} xl={24} lg={24}>
              <div className={styles.sendBtnDiv}>
                <Button
                  disabled={!message || !(checkedList?.length || checkedListEditor?.length)}
                  loading={sendBtnLoading}
                  variant="secondary"
                  className={classnames(
                    {
                      [styles.disabledBtn]:
                        !message || !(checkedList?.length || checkedListEditor?.length),
                    },
                    styles.sendBtn,
                  )}
                  onClick={() => preparePayload()}
                >
                  <span>Send</span>
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};
