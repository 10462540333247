import { Button } from 'components-antd';
import styles from '../styles.module.scss';
import { Icons } from 'pages/ExternalOffer/OfferSubmission/components/Icons';
import { UploadDocuments } from 'components/Transactions';

export const UploadDocumentField = (uploadProps) => {
  const { docKey, category, index, custom, onSave, uploadText } = uploadProps;

  const uploadButton = custom ? (
    <Button
      className={styles.customDocumentUploadButton}
      testid="upload_new"
      icon={<Icons variant={'cloudUpload'} className={styles.icon} />}
    >
      {uploadText || 'Upload More Files'}
    </Button>
  ) : (
    <Button
      className={styles.iconButton}
      testid="upload_new"
      icon={<Icons variant={'cloudUpload'} className={styles.icon} />}
    >
      Upload
    </Button>
  );

  return (
    <UploadDocuments
      key={docKey}
      docCategoryName={category}
      index={index}
      onSave={onSave}
      withCategory={false}
      addDragInModal={true}
      multiple={false}
      isOfferDocument
      fileCode={index.toString()}
      uploadButton={uploadButton}
      className={styles.uploadDocuments}
      fileNameRequired={true}
      fillDocumentName={true}
    />
  );
};
