import { Modal, Button } from 'components-antd';
import styles from './styles.module.scss';
import { Icons } from 'pages/Clarity/Icons';
import { useState } from 'react';
import { Input } from 'components';
import { updateKitById } from 'api/kits';

type EditModalPropsType = {
  showModal: Function;
  open: boolean;
  targetKit;
  updateNotesHandler: Function;
};

function EditModal(props: EditModalPropsType) {
  const [notes, setNotes] = useState<string>(props?.targetKit?.Notes || '');
  const [savingNotes, setSavingNotes] = useState<boolean>(false);
  const [savingNotesError, setSavingNotesError] = useState<boolean>(false);

  const saveNotes = async () => {
    try {
      if (notes && notes !== props?.targetKit?.Notes) {
        setSavingNotes(true);
        await updateKitById(props.targetKit.Id, { Notes: notes });
        props.updateNotesHandler(props.targetKit.Id, notes);
        setSavingNotes(false);
        setSavingNotesError(false);
      }
    } catch (e) {
      setSavingNotes(false);
      setSavingNotesError(true);
    }
  };

  return (
    <Modal
      footer={null}
      width={675}
      open={props.open}
      onCancel={() => {
        props.showModal(false);
      }}
      title={
        <span className={styles.headerTitle}>
          <Icons variant="kits" />
          <span className={styles.headerText}>{`${props.targetKit?.Name} KIT`}</span>
        </span>
      }
      className={styles.kitsEditModal}
    >
      <div className={styles.kitsNotes}>
        <Input
          className={styles.field}
          variant="light"
          placeholder="Enter notes"
          value={props?.targetKit?.Notes || ''}
          onChange={(e, val) => setNotes(val)}
          testid="name_input"
          maxLength={10000}
        />
        <Button
          className={styles.notesButton}
          loading={savingNotes}
          disabled={savingNotes}
          onClick={() => {
            saveNotes();
          }}
        >
          {savingNotesError ? 'Try Again' : 'Save Notes'}
        </Button>
      </div>
    </Modal>
  );
}

export default EditModal;
