import { useSelector } from 'react-redux';
import {
  getDynamicFormScaleSelector,
  getDynamicFormSelector,
} from 'store/selectors/requestFormProcess';
import { isEmpty } from 'lodash-es';
import { Fragment } from 'react';

export const StrikeThroughFields = ({ pageNumber, documentIndex = -1 }) => {
  const { strikeThrough = {}, bundleDocumentsDetails = [] } = useSelector(getDynamicFormSelector);
  const scale = useSelector(getDynamicFormScaleSelector);

  const isCohesiveFlow = bundleDocumentsDetails.length > 0 && documentIndex >= 0;

  let strikeThroughDetails = isCohesiveFlow
    ? bundleDocumentsDetails[documentIndex].strikeThrough
    : strikeThrough;

  strikeThroughDetails = strikeThroughDetails || {};

  if (isEmpty(strikeThroughDetails)) return <></>;

  return (
    <Fragment>
      {strikeThroughDetails[pageNumber]?.map((item) => {
        const {
          id,
          start: { x: startX, y },
          end: { x: endX },
          strokeWidth,
          color,
        } = item;

        const { r, g, b } = color || { r: 0, g: 0, b: 0 };
        const width = endX * scale - startX * scale;

        return (
          <div
            key={id}
            style={{
              position: 'absolute',
              top: y * scale,
              left: startX * scale,

              width,
              border: `${strokeWidth}px solid rgb(${r},${g},${b})`,
              borderRadius: '4px',
            }}
          ></div>
        );
      })}
    </Fragment>
  );
};
