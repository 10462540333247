import { routes } from 'settings/navigation/routes';
import { Workshop } from 'pages';
import { AGENT, CLIENT } from 'settings/constants/roles';
import {
  AnonFormProcess,
  AuthFormProcess,
  AuthFormProcessWebView,
  CreateRequest,
  SendDocument,
  UpdateRequest,
} from 'pages/FormProcess';
import { FormNotAvailableScreen } from 'pages/FormProcess/components/FormNotAvailableScreen';
import {
  AnonDynamicForm,
  AuthDynamicForm,
  BundleDynamicForm,
  TemplateDynamicForm,
} from 'pages/FormProcess/DynamicForm';

import { FormTemplateWizard } from 'pages/FormProcess/components/FormTemplateWizard';
import { SignFormSuccess } from 'pages/FormProcess/DynamicForm/SignFormSuccess';

export default [
  {
    path: routes.workshopForms,
    component: Workshop,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.workshopFormProcessRequest,
    component: CreateRequest,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.workshopFormsSignatoriesRequest,
    component: FormTemplateWizard,
    exact: true,

    roles: [AGENT, CLIENT],
  },
  {
    path: routes.workshopTemplateFormsSuccess,
    component: FormTemplateWizard,
    exact: true,

    roles: [AGENT, CLIENT],
  },
  {
    path: routes.workshopFormTemplateRequest,
    component: FormTemplateWizard,
    exact: true,

    roles: [AGENT, CLIENT],
  },

  {
    path: routes.workshopFormBundleTemplateRequest,
    component: FormTemplateWizard,
    exact: true,

    roles: [AGENT, CLIENT],
  },

  {
    path: routes.workshopFormProcessRequestUpdate,
    component: UpdateRequest,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.workshopFormProcessDocument,
    component: AuthFormProcess,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.workshopFormProcessDocumentWebView,
    component: AuthFormProcessWebView,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.workshopFormNotAvailable,
    component: FormNotAvailableScreen,
    exact: true,
  },
  {
    path: routes.workshopFormProcessDocumentAnon,
    component: AnonFormProcess,
    exact: true,
  },
  {
    path: routes.workshopFormSendDocument,
    component: SendDocument,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.workshopDynamicFormDocument,
    component: AuthDynamicForm,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.workshopDynamicTemplateForm,
    component: TemplateDynamicForm,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.workshopDynamicFormBundle,
    component: BundleDynamicForm,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.workshopDynamicTemplateBundle,
    component: TemplateDynamicForm,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.workshopDynamicFormDocumentAnon,
    component: AnonDynamicForm,
    exact: true,
  },
  {
    path: routes.workshopDynamicFormSuccess,
    component: SignFormSuccess,
    exact: true,
  },
];
