import { Autocomplete, Button, Input, Select } from 'components';
import { Col, Row } from 'components-antd';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import { InviteVendorIcon } from '../../icons';
import { addVendorValidationSchema } from '../validation';
import styles from './styles.module.scss';

const Header: React.FC = () => {
  return (
    <div className={styles.header}>
      <div className={styles.iconWrapper}>
        <InviteVendorIcon />
      </div>
      <div>Invite a Vendor</div>
    </div>
  );
};

type Props = {
  handleFormSubmit: (values: any) => void;
  isLoading: boolean;
};

export const AddVendorForm: React.FC<Props> = ({ handleFormSubmit, isLoading }) => {
  const allCategories = useSelector(getAllServicesCategoriesList);

  const allCategoriesOptions = useMemo<{ name: string; value: string }[]>(() => {
    return allCategories.map((category) => ({ name: category.Title, value: category.Id }));
  }, [allCategories]);

  const {
    values,
    touched,
    errors,
    isValid,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik<{
    BusinessName: string;
    Email: string;
    PartnerCategoryIds: { name: string; value: string }[];
  }>({
    initialValues: {
      BusinessName: '',
      Email: '',
      PartnerCategoryIds: [],
    },
    validationSchema: addVendorValidationSchema,
    onSubmit: handleFormSubmit,
  });
  return (
    <div className={styles.contentWrapper}>
      <Header />
      <form className={styles.contentBody} onSubmit={handleSubmit}>
        <Row>
          <Col span={24}>
            <Input
              className={styles.inputContainer}
              name="BusinessName"
              variant="lightFull"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.BusinessName}
              label="Name"
              error={touched.BusinessName ? (errors.BusinessName as string) : ''}
              placeholder={'Enter name'}
              maxLength={30}
              errorClassName={styles.error}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input
              className={styles.inputContainer}
              name="Email"
              variant="lightFull"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Email}
              label="Email"
              error={touched.Email ? (errors.Email as string) : ''}
              placeholder={'Enter email'}
              maxLength={75}
              errorClassName={styles.error}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.label}>Category</div>
          </Col>
          <Col span={24}>
            <Select
              multiple
              options={allCategoriesOptions}
              variant={Autocomplete.LIGHT}
              placeholder={'Select categories'}
              onSelect={(values) => setFieldValue('PartnerCategoryIds', values?.target?.value)}
              value={allCategoriesOptions.filter((option) =>
                values.PartnerCategoryIds.find((ids) => ids.value === option.value),
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.btnWrapper}>
              <Button
                title="Send Invitation"
                type="submit"
                disabled={!isValid}
                className={styles.submitButton}
                isPending={isLoading}
              />
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};
