import { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { DatePicker } from 'antd';
import { Row, Col, Checkbox, InputNumber } from 'components-antd';
import { Navigation } from '../../components';
import {
  EditAvailability,
  EditNotify,
  EditDocuments,
  EditNotes,
  EditFeedback,
} from '../components';

import styles from './styles.module.scss';
import { Icons } from '../../Icons';

const { RangePicker } = DatePicker;

const initState = {
  isIndefinite: false,
  isAddDays: false,
  isDateRange: false,
};

export const LinkDuration = ({
  onNext,
  stageIndex,
  onPrev,
  onUpdate,
  onFinish,
  data,
  setCurrentStageIndex,
  stagesStep,
}) => {
  const { SelectedDuration, LinkActiveStartDate, LinkActiveEndDate, LinkActiveDays } = data;

  const [state, setState] = useState(SelectedDuration);
  const [startDate, setStartDate]: any = useState(!LinkActiveDays ? LinkActiveStartDate : null);
  const [endDate, setEndDate]: any = useState(
    !LinkActiveDays && LinkActiveEndDate ? LinkActiveEndDate : null,
  );

  const [activeDays, setActiveDays]: any = useState(LinkActiveDays);
  const [activeStartDate, setActiveStartDate]: any = useState(
    LinkActiveDays ? new Date(LinkActiveStartDate) : null,
  );

  const onChange = (data) => {
    setState({ ...initState, ...data });
  };

  const isDisabled = () => {
    const disabled =
      !state.isIndefinite &&
      (!state.isAddDays || (state.isAddDays && (!activeDays || !activeStartDate))) &&
      (!state.isDateRange || (state.isDateRange && (!startDate || !endDate)));

    return disabled;
  };

  const onSubmit = () => {
    const body = {
      SelectedDuration: state,
      Indefinitely: state.isIndefinite,
      LinkActiveStartDate: startDate,
      LinkActiveEndDate: state.isDateRange ? endDate : null,
      LinkActiveDays: activeDays,
    };

    if (activeDays && activeStartDate) {
      body.LinkActiveStartDate = activeStartDate;
      let activeEndDate = new Date(activeStartDate);
      activeEndDate.setDate(activeStartDate.getDate() + activeDays);
      body.LinkActiveEndDate = activeEndDate;
    }

    if (state.isIndefinite && !startDate) {
      body.LinkActiveStartDate = new Date();
    }

    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(stagesStep.EnableLink);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const disabledDate = (current) => {
    let today = moment(new Date()).format('YYYY-MM-DD');
    return current && current < moment(today, 'YYYY-MM-DD');
  };

  const container = useRef<null | HTMLDivElement>(null);
  const bottomRef: any = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [stageIndex]);

  return (
    <div className={styles.showingLink} ref={container}>
      <div className={styles.contentWrapper}>
        <EditAvailability
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.Availability)}
        />
        <EditNotify data={data} onEdit={() => setCurrentStageIndex(stagesStep.Notify)} />
        <EditDocuments data={data} onEdit={() => setCurrentStageIndex(stagesStep.Documents)} />
        <EditNotes data={data} onEdit={() => setCurrentStageIndex(stagesStep.Notes)} />
        <EditFeedback data={data} onEdit={() => setCurrentStageIndex(stagesStep.Feedback)} />

        <div ref={bottomRef} className={styles.content}>
          <h4 className={styles.title}>
            <Icons className={styles.timeIcon} variant="time" /> How long should this link be
            active?
          </h4>

          <Row className={styles.checkboxesRow}>
            <Col
              span={24}
              className={styles.container}
              onClick={() => {
                onChange({ isIndefinite: true });
                setStartDate(null);
                setEndDate(null);
                setActiveDays(null);
                setActiveStartDate(null);
              }}
            >
              <Checkbox
                className={styles.indefinite}
                checked={state.isIndefinite}
                defaultChecked={state.isIndefinite}
              >
                Indefinitely
              </Checkbox>
            </Col>
            <Col
              span={24}
              className={styles.container}
              onClick={() => {
                onChange({ isAddDays: true });
                setEndDate(null);
              }}
            >
              <Checkbox checked={state.isAddDays} defaultChecked={state.isAddDays} />
              <DatePicker
                className={styles.startDate}
                format={'MM/DD'}
                placeholder={'Start Date'}
                onChange={(e: any) => {
                  setActiveStartDate(new Date(e));
                }}
                value={
                  activeStartDate && state.isAddDays ? moment(new Date(activeStartDate)) : null
                }
                disabledDate={disabledDate}
                getPopupContainer={(trigger) => {
                  return trigger;
                }}
              />
              <span className={styles.static}>+</span>
              <InputNumber
                min={1}
                placeholder="4"
                onChange={(val) => setActiveDays(val)}
                value={activeDays}
                className={styles.activeDays}
              />
              <span className={styles.static}>Days</span>
            </Col>
            <Col
              span={24}
              className={styles.container}
              onClick={() => {
                onChange({ isDateRange: true });
                setActiveDays(null);
                setActiveStartDate(null);
              }}
            >
              <Checkbox checked={state.isDateRange} defaultChecked={state.isDateRange} />
              <RangePicker
                key={startDate + endDate}
                className={styles.rangePicker}
                getPopupContainer={(trigger) => {
                  return trigger;
                }}
                separator="to"
                format={'MM/DD'}
                onChange={(e: any) => {
                  setStartDate(new Date(e[0]));
                  setEndDate(new Date(e[1]));
                }}
                defaultValue={
                  state.isDateRange && [moment(new Date(startDate)), moment(new Date(endDate))]
                }
                disabledDate={disabledDate}
              />
            </Col>
          </Row>

          <Row className={styles.infoWrapper}>
            <Icons variant="info" />
            <p>You can manually deactivate this link at any time. </p>
          </Row>
        </div>
      </div>
      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onPrev={onPrev}
        onFinish={onFinish}
        disabled={isDisabled()}
        className={styles.footer}
        showBackButton={false}
      />
    </div>
  );
};
