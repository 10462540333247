import { Input, InputLabel } from 'components';

import styles from './styles.module.scss';

export const MilestoneName = ({ values, onChangeField }) => {
  return (
    <div className={styles.milestoneName}>
      <div className={styles.fieldWrapper}>
        <InputLabel label="Milestone" className={styles.label} />
        <Input
          className={styles.field}
          variant="light"
          placeholder="Add milestone name..."
          value={values?.MilestoneName || ''}
          onChange={(e, val) => onChangeField(val, 'MilestoneName')}
          testid="name_input"
          maxLength={50}
        />
      </div>
    </div>
  );
};
