import {
  requestClientInstancesAction,
  setSearchInstanceAction,
} from 'store/actions/clientInstances';

import Api from 'store/effects/core/api';
import { getClientInstances } from 'api/user';

export const getClientInstancesEffect = (cfg, options, cb) => {
  const requestParams = { action: requestClientInstancesAction, method: getClientInstances };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }
  return sendRequest(cfg, options, cb);
};
export const addSearchInstance = (cfg) => (dispatch) => {
  dispatch(setSearchInstanceAction(cfg));
};
