import { useMemo } from 'react';
import { Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  getShowingAggregateDataSelector,
  getShowingArchiveIdSelector,
} from 'store/selectors/showingAggregate';
import { routes } from 'settings/navigation/routes';
import { getTransactionsSelector } from 'store/selectors/transactions';

import { Switcher } from './Switcher';
import { SearchFieldWithDebounce } from 'components';
import TransactionActions from 'pages/Workshop/Transactions/components/Actions';
import {
  TransactionsFilter,
  ProjectsFilter,
} from '../Transactions/components/Actions/QuickFilters';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_OWNER, TEAM_ADMIN, TEAM_BASIC } from 'settings/constants/roles';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import {
  resetTransactionsSearchEffect,
  searchTransactionsEffect,
} from 'store/effects/transactions/all';
import { TransactionAndProjectFilter } from '../Transactions/components/Actions/TransactionAndProjectFilter';

import styles from './styles.module.scss';

export const LocalHeader = () => {
  const { pathname } = useLocation();
  const { showingId } = useSelector(getShowingArchiveIdSelector);
  const showingAggregate = useSelector(getShowingAggregateDataSelector);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const { isPending } = useSelector(getTransactionsSelector);

  const renderAddress = () => {
    let address = '';
    showingAggregate &&
      Object.values(showingAggregate).map((appointments: any) => {
        for (let appointment of appointments) {
          const details = appointment?.ShowingDetails;
          if (Number(details?.Id) === showingId) {
            address = details?.PropertyTransaction?.Property?.Address?.Line1;
          }
        }
      });

    return <p className={styles.address}>{address}</p>;
  };

  const renderActions = () => {
    if (pathname === routes.transactions || pathname === routes.projects)
      return <TransactionActions />;
  };

  const isAggregatedPageFilterVisible = useMemo(() => {
    if (
      pathname == routes.tasks ||
      pathname == routes.milestones ||
      pathname == routes.workshopForms
    )
      return true;
    return false;
  }, [pathname]);

  const renderTeamFiltersByRoute = () => {
    if (isTeamAgentActive && [TEAM_OWNER, TEAM_ADMIN, TEAM_BASIC].includes(agentRole)) {
      if (pathname === routes.transactions) return <TransactionsFilter agentRole={agentRole} />;
      if (pathname === routes.projects) return <ProjectsFilter agentRole={agentRole} />;
    }
  };

  return (
    <div className={classNames(styles.workshopHeader)}>
      <Row justify="center">
        <Col xs={24} lg={8} className={classNames(styles.workshopSelector)}>
          {!showingId ? <Switcher /> : renderAddress()}
        </Col>
      </Row>
      {(pathname === routes.transactions || pathname === routes.projects) && (
        <Row className={styles.workshopActionsContainer} align="middle" justify="space-between">
          <Col className={styles.workshopHeaderLeft} xs={24} lg={16}>
            {isAggregatedPageFilterVisible && (
              <TransactionAndProjectFilter className={styles.teamFilter} />
            )}
            <div className={styles.searchWrapper}>
              <SearchFieldWithDebounce
                className={styles.inputWrap}
                classNameInput={styles.searchInput}
                iconClassName={styles.icon}
                resetAction={resetTransactionsSearchEffect}
                sendAction={searchTransactionsEffect}
                isLoading={isPending}
                placeholder="Search"
                testid="search"
                isTransactionAggregate={true}
                isExpandedVersion
              />
            </div>
            <div className="seperator" />
            {renderTeamFiltersByRoute()}
          </Col>
          <Col xs={24} lg={8}>
            {renderActions()}
          </Col>
        </Row>
      )}
    </div>
  );
};
