import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { getSocketConnectionState } from 'store/selectors/sockets/status';
import { retrySocketConnectionEffect } from 'store/effects/sockets';

import { SOCKET_STATE } from 'settings/constants/sockets';

import { ApiRequestError } from 'components';

import styles from './styles.module.scss';

const SocketConnectionWrapper = ({ children }) => {
  const connection = useSelector(getSocketConnectionState);
  const dispatch = useDispatch();

  const handleRetryConnection = useCallback(
    () => dispatch(retrySocketConnectionEffect()),
    [dispatch],
  );

  if (connection === SOCKET_STATE.CONNECTED) {
    return children;
  }

  return (
    <div className={styles.errorSockets}>
      <div className={styles.errorSocketsIcon}>
        {connection !== SOCKET_STATE.DISCONNECTED ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.78646 6C8.19673 4.75527 10.0492 4 12.0781 4C16.4964 4 20.0781 7.58172 20.0781 12C20.0781 14.071 19.2912 15.9582 18 17.3789M4.33015 10C4.16562 10.6392 4.07811 11.3094 4.07811 12C4.07811 13.4812 4.48065 14.8684 5.18229 16.0581L3.54135 19.912C3.36822 20.3186 3.76904 20.7335 4.18134 20.5744L8.22693 19.0137C9.36938 19.6424 10.682 20 12.0781 20C12.7406 20 13.3844 19.9195 14 19.7676"
              stroke="#ADADAD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 3L21 21"
              stroke="#ADADAD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
              stroke="#ADADAD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 10.668V16.0013"
              stroke="#ADADAD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="16" cy="21" r="1.5" fill="#ADADAD" />
          </svg>
        )}
      </div>
      <ApiRequestError
        title={
          (connection === SOCKET_STATE.ERROR && 'Connection chat error') ||
          (connection === SOCKET_STATE.DISCONNECTED && 'Chat was disconnected') ||
          (connection === SOCKET_STATE.IDLE && "Chat wasn't connected") ||
          'Unknown error'
        }
        onRetry={handleRetryConnection}
        className={styles.apiErrorStyle}
      />
    </div>
  );
};

SocketConnectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SocketConnectionWrapper;
