/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { Input, InputLabel } from 'components';
import { Select } from 'components';
import { noRanges } from 'settings/constants/common';

import styles from './styles.module.scss';

interface LotSizeMinMaxProps {
  separatorClassName?: string;
  className?: string;
  variant?: string;
  valueMin?: string | number;
  valueMax?: string | number;
  nameMin?: string;
  nameMax?: string;
  onChangeMin?: (...params) => void;
  onChangeMax?: (...params) => void;
  disabled?: boolean;
  placeholderMin?: string;
  placeholderMax?: string;
  label?: string;
  testidMin?: string;
  testidMax?: string;
  isReset?: boolean;
  selectClassName?: string;
}

export const lotSizeRangeOptions = [
  { name: '2,000 sqft', value: 2000 },
  { name: '4,500 sqft', value: 4500 },
  { name: '6,500 sqft', value: 6500 },
  { name: '8,000 sqft', value: 8000 },
  { name: '9,500 sqft', value: 9500 },
  { name: '0.25 acre', value: 10890 },
  { name: '1 acre', value: 43560 },
  { name: '2 acres', value: 87120 },
  { name: '3 acres', value: 130680 },
  { name: '4 acres', value: 174240 },
  { name: '5 acres', value: 217800 },
  { name: '10 acres', value: 435600 },
  { name: '20 acres', value: 871200 },
  { name: '40 acres', value: 1742000 },
  { name: '100 acres', value: 4356000 },
];

const LotSizeMinMax = ({
  nameMin = 'min',
  nameMax = 'max',
  onChangeMin = () => {},
  onChangeMax = () => {},
  disabled = false,
  placeholderMin = '',
  placeholderMax = '',
  label = '',
  testidMin,
  testidMax,
  isReset = false,
  selectClassName = '',
  className,
  variant,
  valueMin,
  valueMax,
  separatorClassName = '',
}: LotSizeMinMaxProps) => {
  const findLotSizeIndex = (lotSizes, lotSizeValue) =>
    lotSizes.findIndex((lotSize) => lotSize.value === lotSizeValue);

  const getLotSizeOptions = useCallback(() => {
    const lotSizes = lotSizeRangeOptions;

    let minLotSizes = [...lotSizes];
    let maxLotSizes = [...lotSizes];

    if (valueMin) {
      const lotSizeIndex = findLotSizeIndex(lotSizes, valueMin);

      if (lotSizeIndex !== -1) {
        maxLotSizes = lotSizes.slice(lotSizeIndex + 1);
      }
    }

    if (valueMax) {
      const lotSizeIndex = findLotSizeIndex(lotSizes, valueMax);

      if (lotSizeIndex !== -1) {
        minLotSizes = lotSizes.slice(0, lotSizeIndex);
      }
    }

    return {
      min: minLotSizes,
      max: maxLotSizes,
    };
  }, [valueMin, valueMax]);

  const [lotSizeOptionsMin, setLotSizeOptionsMin] = useState(getLotSizeOptions().min);
  const [lotSizeOptionsMax, setLotSizeOptionsMax] = useState(getLotSizeOptions().max);

  useEffect(() => {
    setLotSizeOptionsMin(getLotSizeOptions().min);
    setLotSizeOptionsMax(getLotSizeOptions().max);
  }, [valueMin, valueMax, getLotSizeOptions]);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <InputLabel label={label} className={styles.lotSizeInputLabel} />
      <div className={classNames(styles.horizontalOptions)}>
        <Select
          name={nameMin}
          options={[
            ...(isReset ? [{ name: 'No min', value: noRanges.noMin }] : []),
            ...lotSizeOptionsMin,
          ]}
          placeholder={placeholderMin}
          value={valueMin}
          onSelect={(target, val) => onChangeMin(val.value)}
          disabled={disabled}
          className={{ wrapper: selectClassName }}
          variant={variant}
          testid={testidMin}
        />
        <span className={classNames(separatorClassName, styles.separator)}>to</span>
        <Select
          name={nameMax}
          options={[
            ...(isReset ? [{ name: 'No max', value: noRanges.noMax }] : []),
            ...lotSizeOptionsMax,
          ]}
          placeholder={placeholderMax}
          value={valueMax}
          onSelect={(target, val) => onChangeMax(val.value)}
          disabled={disabled}
          className={{ wrapper: selectClassName }}
          variant={variant}
          testid={testidMax}
        />
      </div>
    </div>
  );
};

export default LotSizeMinMax;
