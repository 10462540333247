export function prepareData(values, stageIndex, defaultClientSearch = {}) {
  return {
    stageIndex,
    DefaultPropertySearchPreferences: {
      ...defaultClientSearch,
      ...(values.squareFeetMin || values.squareFeetMax
        ? {
            SquareFeetRange: {
              Min: values.squareFeetMin ? +values.squareFeetMin : undefined,
              Max: values.squareFeetMax ? +values.squareFeetMax : undefined,
            },
          }
        : { SquareFeetRange: undefined }),
    },
  };
}
