import { List } from 'components/Icons';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const TemplateCard = (props) => {
  const { Name, Description, TemplateId, PreviewTemplate, onSelect } = props;
  return (
    <div className={styles.templateCard}>
      <div className={styles.tcWrap}>
        <div className={styles.tcLeft}>
          <List className={styles.tclIcon} />
          <div className={styles.tclContent}>
            {Name ? <h3 className={styles.title}>{Name}</h3> : ''}
            {Description ? <p className={styles.description}>{Description}</p> : ''}
          </div>
        </div>
        <div className={styles.tcRight}>
          <button
            className={classNames(styles.actionBtn, styles.preview)}
            onClick={() => PreviewTemplate(TemplateId)}
          >
            Preview
          </button>
          <button
            className={classNames(styles.actionBtn, styles.select)}
            onClick={() => onSelect(TemplateId)}
          >
            Select
          </button>
        </div>
      </div>
    </div>
  );
};
