export enum TourStatus {
  Pending = 'Pending',
  Requested = 'Requested',
  Approved = 'Approved',
  Canceled = 'Canceled',
  Past = 'Past',
}

export enum TourSort {
  ClientName = 'ClientName',
  AgentName = 'AgentName',
  Date = 'Date',
}

export enum TeamFilter {
  JustMe = 'JustMe',
  EntireTeam = 'EntireTeam',
}

export enum CardActions {
  Multiple = 'Multiple',
  CopyMLS = 'CopyMLS',
  CopyAddress = 'CopyAddress',
  Download = 'Download',
}
