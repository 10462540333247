import React from 'react';

import moment from 'moment';
import classNames from 'classnames';
import styles from './styles.module.scss';

import { Avatar } from 'components';
import {
  formatNameToAvatarLetters,
  subtractTimeZone,
  getFormattedTime,
  getIfExistThread,
} from 'helpers';
import { Category } from '../../Category';
import { Archive as ArchiveIcon, Unarchive as UnarchiveIcon } from 'components/Icons';
import { Badge, Tooltip } from 'components-antd';
import { quoteRequestStatus } from 'settings/constants/quotes';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { MessageIcon } from 'pages/ServicesCategory/components/AgentPartnersList/icons';

export function tableColumns(
  groupedBy: string,
  onArchieve,
  isClient: boolean,
  threads,
  userId,
  handleMessageButtonClick,
  isThirdParty,
) {
  const onArchiveClick = (e, archiveQuotesIds, status) => {
    e.stopPropagation();
    onArchieve(archiveQuotesIds, status);
  };

  const onMessageClick = (e, threadId) => {
    e.stopPropagation();
    handleMessageButtonClick(threadId);
  };

  const columns = [
    {
      title: 'Vendor',
      key: 'Vendor',
      dataIndex: 'Partner',
      sorter: (a, b) => {
        const nameA = `${
          a?.Status === quoteRequestStatus.Draft
            ? a?.Partners[0]?.BusinessName
            : a?.Partner?.BusinessName
        }`;
        const nameB = `${
          b?.Status === quoteRequestStatus.Draft
            ? b?.Partners[0]?.BusinessName
            : b?.Partner?.BusinessName
        }`;
        return nameA.localeCompare(nameB);
      },
      render: (_, row) => {
        const partner = row?.Status === quoteRequestStatus.Draft ? row?.Partners : row?.Partner;
        const thread = getIfExistThread(
          threads,
          [isThirdParty ? row?.Requestor?.Id : partner.Id, userId],
          ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT,
        );
        return (
          <div className={styles.vendorWrap}>
            <div className={styles.vendorTask}>
              <Avatar
                avatarClassName={styles.icon}
                src={
                  row?.Status === quoteRequestStatus.Draft
                    ? row?.Partners[0]?.LogoUrl
                    : row?.Partner?.LogoUrl
                }
                placeholder={
                  <div className={styles.avatarPlaceholder}>
                    {formatNameToAvatarLetters(
                      `${
                        row?.Status === quoteRequestStatus.Draft
                          ? row?.Partners[0]?.BusinessName
                          : row?.Partner?.BusinessName
                      }`,
                    )}
                  </div>
                }
              />
              <div className={styles.info}>
                <span className={styles.line1}>
                  {row?.Status === quoteRequestStatus.Draft ? (
                    <>{row?.Partners[0]?.BusinessName}</>
                  ) : (
                    <>{row?.Partner?.BusinessName}</>
                  )}
                </span>
              </div>
            </div>
            {thread && (
              <div onClick={(e) => onMessageClick(e, thread.Id)}>
                <Badge count={thread.IsUnread ? 1 : 0}>
                  <MessageIcon />
                </Badge>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Category',
      key: 'Category',
      dataIndex: 'Category',
      width: 180,
      sorter: (a, b) => a?.Category?.Title?.localeCompare(b?.Category?.Title),
      render: (_, row) => {
        return <Category category={row?.Category?.Title} />;
      },
    },
    {
      title: 'Client',
      key: 'client',
      dataIndex: 'Client',
      width: 260,
      sorter: (a, b) => {
        const nameA = `${a?.Client?.FirstName} ${a?.Client?.LastName}`;
        const nameB = `${b?.Client?.FirstName} ${b?.Client?.LastName}`;
        return nameA.localeCompare(nameB);
      },
      render: (_, row) => {
        return (
          <>
            {row?.Client?.FirstName ? (
              <div className={styles.clientWrap}>
                <Avatar
                  avatarClassName={styles.clientIcon}
                  // src={row?.Requestor?.AvatarUrl}
                  placeholder={
                    <div className={styles.avatarPlaceholder}>
                      {formatNameToAvatarLetters(
                        `${row?.Client?.FirstName} ${row?.Client?.LastName}`,
                      )}
                    </div>
                  }
                />
                <div className={styles.info}>
                  <span className={styles.line1}>
                    {row?.Client?.FirstName} {row?.Client?.LastName}
                  </span>
                </div>
              </div>
            ) : (
              <span>-</span>
            )}
          </>
        );
      },
    },
    {
      title: 'Property',
      key: 'property',
      width: 320,
      dataIndex: 'Property',
      render: (_, { Property }) => {
        const address1: string = Property?.Address?.Line1;
        return (
          <>
            {address1 ? (
              <div className={styles.propertyContentWrapper}>
                <div className={styles.propertyContent}>
                  <span className={styles.contentFirstRow}> {address1} </span>
                  <br />
                  <span className={styles.contentSecondRow}>
                    {`${Property?.Address?.City}, ${Property?.Address?.State} ${Property?.Address?.Zip}`}
                  </span>
                </div>
              </div>
            ) : (
              <span>-</span>
            )}
          </>
        );
      },
      sorter: (a, b) => {
        const aAddress = a.Property?.Address?.Line1.toLowerCase();
        const bAddress = b.Property?.Address?.Line1.toLowerCase();
        if (aAddress > bAddress) return 1;
        if (aAddress < bAddress) return -1;
        return 0;
      },
    },
    {
      title: 'Requested',
      key: 'requested',
      dataIndex: 'UpdatedDate',
      width: 150,
      sorter: (a, b) => moment(a.UpdatedDate).unix() - moment(b.UpdatedDate).unix(),
      render: (_, { UpdatedDate }) => {
        let formattedDate = UpdatedDate ? subtractTimeZone(UpdatedDate, 'M/DD/YYYY') : 'N/A';
        let formattedTime = UpdatedDate ? getFormattedTime(UpdatedDate) : '';
        return (
          <div className={styles.dateWrapper}>
            <span className={styles.contentFirstRow}>{formattedDate}</span>
            <br />
            <span className={styles.contentSecondRow}>{formattedTime}</span>
          </div>
        );
      },
    },
    {
      title: '',
      key: '',
      dataIndex: '',
      width: 75,
      render: (_, row) => {
        const archiveQuotesIds = [row?.Id];
        return (
          <Tooltip title={<span>Archive</span>} getPopupContainer={(trigger) => trigger}>
            <div
              testid="archive"
              onClick={(e) => onArchiveClick(e, archiveQuotesIds, row?.Status)}
              className={styles.archive}
            >
              <ArchiveIcon className={styles.icon} />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: '',
      key: '',
      dataIndex: '',
      width: 75,
      render: (_, row) => {
        const unArchiveQuotesIds = [row?.Id];
        return (
          <Tooltip title={<span>Unarchive</span>} getPopupContainer={(trigger) => trigger}>
            <div
              testid="archive"
              onClick={(e) => onArchiveClick(e, unArchiveQuotesIds, row?.Status)}
              className={styles.archive}
            >
              <UnarchiveIcon className={styles.icon} />
            </div>
          </Tooltip>
        );
      },
    },
  ];
  switch (groupedBy) {
    case 'ByStatus':
      if (isClient) {
        return columns.slice(0, 2).concat(columns.slice(3, -1));
      }
      return columns.slice(0, 6);
    case 'ByCategory':
      if (isClient) {
        return columns.slice(0, 1).concat(columns.slice(3, -1));
      }
      return columns.slice(0, 1).concat(columns.slice(2, -1));
    case 'ByClient':
      return columns.slice(0, 2).concat(columns.slice(3, -1));
    case 'ByProperty':
      return columns.slice(0, 3).concat(columns.slice(4, -1));
    case 'ByArchieve':
      if (isClient) {
        return columns.slice(0, 2).concat(columns.slice(3, 5).concat(columns.slice(6)));
      }
      return columns.slice(0, 5).concat(columns.slice(6));
    default:
      return columns;
  }
}
