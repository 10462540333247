import { unsubscribeClient } from 'api/kits';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import { PageNotFound, Spinner } from 'components';
import styles from './styles.module.scss';
import { Footer } from 'components-antd/Footer/Footer';
import classNames from 'classnames';
import { routes } from 'settings/navigation/routes';

type KitsUnsubscribeParamType = {
  uuid: string;
};

function ClientKitsUnsubscribe() {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { uuid } = useParams<KitsUnsubscribeParamType>();

  const updateClientSubscription = async () => {
    try {
      const response = await unsubscribeClient(uuid);
      if (response.status !== 200) setError(true);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!uuidValidate(uuid)) setError(true);
  }, []);

  useEffect(() => {
    if (loading) updateClientSubscription();
  }, [loading]);

  if (loading) {
    return <Spinner loaderClassName={classNames(styles.loader)} />;
  } else if (error) {
    return (
      <div>
        <PageNotFound backUrl={routes.dashboard} />
      </div>
    );
  } else
    return (
      <div className={styles.kitsUnsubscribed}>
        <div className={styles.content}>
          <p className={styles.bold}>{`You've been successfully unsubscribed.`}</p>
          <p className={styles.text}>{`We've unsubscribed you from emails related to KITs.`}</p>
          <p className={styles.text}>
            {`You may still be subscribed to other types of emails. If you'd like to make further adjustments to your subscriptions, please visit your `}
            <a
              className={styles.link}
              href={routes.settingsNotifications}
              target="_blank"
              rel="noreferrer"
            >
              notifications settings page
            </a>
            <span>.</span>
          </p>
        </div>
        <div className={styles.tiles}></div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    );
}

export default ClientKitsUnsubscribe;
