import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const AvatarPlaceholder = memo(({ className }) =>
  useMemo(
    () => (
      <div testid="avatar_placeholder" className={className}>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.0013 25.6667C33.156 25.6667 37.3346 21.488 37.3346 16.3333C37.3346 11.1787 33.156 7 28.0013 7C22.8466 7 18.668 11.1787 18.668 16.3333C18.668 21.488 22.8466 25.6667 28.0013 25.6667Z"
            stroke="#ADADAD"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 49V44.3333C14 41.858 14.9833 39.484 16.7337 37.7337C18.484 35.9833 20.858 35 23.3333 35H32.6667C35.142 35 37.516 35.9833 39.2663 37.7337C41.0167 39.484 42 41.858 42 44.3333V49"
            stroke="#ADADAD"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

AvatarPlaceholder.propTypes = {
  className: PropTypes.string,
};

AvatarPlaceholder.defaultProps = {
  className: '',
};

export default AvatarPlaceholder;
