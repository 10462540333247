import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getTasksForCancelledTransactionFrSelector,
  getTransactionSelector,
} from 'store/selectors/transaction';
import Question from 'pages/Workshop/Transactions/TransactionCreate/components/Question';

import { PendingTasks } from '../PendingTasks';
import { tasksStatusesIds } from 'settings/constants/transactionTasks';
import { prepareCancellationTasks } from './helper';
import { uniqBy } from 'lodash-es';

interface CancellationTasksProps {
  nextStage: () => void;
}

export const CancellationTasks = ({ nextStage }: CancellationTasksProps) => {
  const { tasks: selectorTasks } = useSelector(getTasksForCancelledTransactionFrSelector);

  useEffect(() => {
    if (!selectorTasks.length) {
      nextStage();
    }
  }, []);

  const { transaction } = useSelector(getTransactionSelector);

  const uniqueTasks = uniqBy(selectorTasks, 'transactionActionId') as any;

  const preparedTasks = useMemo(
    () => prepareCancellationTasks(uniqueTasks, transaction.Id),
    [selectorTasks],
  );

  const storedTasks = localStorage.getItem('cancelledPendingTasks');
  const [tasks, setTasks] = useState(storedTasks ? JSON.parse(storedTasks) : preparedTasks);

  const onUpdateTaskAction = (modifiedStatusTasks) => {
    localStorage.setItem('cancelledPendingTasks', JSON.stringify(modifiedStatusTasks));
    setTasks(modifiedStatusTasks);
  };

  const onNext = () => {
    nextStage();
  };

  return tasks?.length ? (
    <div>
      <Question>Please complete the following tasks to cancel this transaction</Question>
      <PendingTasks
        tasks={tasks}
        onNext={onNext}
        cancellationTasks={true}
        onUpdateAction={onUpdateTaskAction}
      />
    </div>
  ) : (
    <></>
  );
};
