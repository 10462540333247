import { List, Typography } from 'antd';
import { ReactElement } from 'react';
import { useDrag } from 'react-dnd';

import { PDF_FIELD_TYPE } from 'app-constants';

interface AllowedFieldType {
  value: string;
  label: string;
  icon: ReactElement;
}

const AllowedFields: AllowedFieldType[] = [
  {
    value: PDF_FIELD_TYPE.PDFTextField,
    label: 'Text',
    icon: <span style={{ fontWeight: 'bolder' }}>T</span>,
  },
  {
    value: PDF_FIELD_TYPE.PDFSignature,
    label: 'Signature',
    icon: <span style={{ fontWeight: 'bolder' }}>&</span>,
  },
  {
    value: PDF_FIELD_TYPE.PDFCheckBox,
    label: 'Checkbox',
    icon: <span style={{ fontWeight: 'bolder' }}>^/</span>,
  },
  {
    value: PDF_FIELD_TYPE.PDFRadioGroup,
    label: 'Radio Group',
    icon: <span style={{ fontWeight: 'bolder' }}>|.|</span>,
  },
];

const RenderItem = (field: AllowedFieldType) => {
  const [{ isDragging }, ref] = useDrag({
    type: field.value,
    item: {
      type: field.value,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <List.Item
      style={{ width: '100%', opacity: isDragging ? '0.6' : '1' }}
      ref={ref}
      key={field.value}
    >
      <Typography.Text>{field.icon}</Typography.Text> {field.label}
    </List.Item>
  );
};

export const NewPDFFields = () => {
  return (
    <List
      header={<div style={{ fontWeight: 'bolder' }}>Drag new field</div>}
      bordered
      dataSource={AllowedFields}
      renderItem={RenderItem}
    />
  );
};
