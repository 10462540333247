import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { getDrawerState } from 'store/selectors/drawers/messageAttachments';
import { openMessageAttachmentsDrawerAction } from 'store/actions/drawers/messageAttachments';

import { Drawer } from 'components/index';

import ActionsHeader from '../components/ActionsHeader';
import MessageAttachmentDrawerContent from './MessageAttachmentDrawerContent';

import styles from './styles.module.scss';

const MessageAttachmentDrawer = () => {
  const { open, params: { threadId } = {} } = useSelector(getDrawerState);
  const dispatch = useDispatch();

  const handleDrawerClose = useCallback(
    () => dispatch(openMessageAttachmentsDrawerAction({ open: false })),
    [dispatch],
  );
  const handleBack = useCallback(() => {
    dispatch(openMessageAttachmentsDrawerAction({ open: false }));
  }, [dispatch, threadId]);

  const label = useMemo(() => {
    return 'Message';
  }, []);

  return (
    <Drawer
      shouldDisableScroll={false}
      zIndex={1000}
      header={<ActionsHeader label={label} onClick={handleBack} />}
      isOpen={open}
      onClose={handleDrawerClose}
      opacityLayerClassName={classNames({})}
      testid="attachments_drawer"
    >
      <MessageAttachmentDrawerContent />
    </Drawer>
  );
};

export default MessageAttachmentDrawer;
