import { Text } from 'components-antd';
import styles from './styles.module.scss';

import {
  TemplateCheckboxFields,
  TemplateFields,
} from 'pages/Workshop/Forms/components/NewFormModal/FormStages/components';

import { useEffect, useState } from 'react';
import { initialTemplateFieldState, initialUploadErrorState } from '../../helper';
import { Navigation } from 'pages/FormProcess/components/PreForm/components';

export const TemplateDetails = (props) => {
  const { isBundle, loading } = props;
  const [templateFieldState, setTemplateState] = useState({
    ...initialTemplateFieldState,
    saveAsTemplate: true,
  });

  const [templateErrors, setTemplateErrors] = useState(initialUploadErrorState);

  const onChangeTemplateField = (data) => {
    setTemplateState((prev) => ({ ...prev, ...data }));
  };

  useEffect(() => {
    if (props?.templateFields) {
      setTemplateState({
        ...templateFieldState,
        ...props.templateFields,
      });
    }
  }, [props.templateFields]);

  const isValid = () => {
    let valid = true;
    const errors = { ...templateErrors };

    const { templateName, categories } = templateFieldState;

    if (!templateName.trim().length) {
      errors.templateNameError = 'Required!';
      valid = false;
    }
    if (!isBundle && !categories.length) {
      errors.categoriesError = 'Required!';
      valid = false;
    }

    !valid && setTemplateErrors(errors);
    return valid;
  };

  const onSubmit = () => {
    const valid = isValid();

    if (valid) {
      props.onContinue({
        ...templateFieldState,
        categories: templateFieldState.categories,
      });
    }
  };

  return (
    <>
      <Text className={styles.questionHeader}>
        What are the {props.isBundle ? 'bundle' : 'template'} details?
      </Text>
      <div className={styles.pageContent}>
        <TemplateFields
          fieldState={templateFieldState}
          onChange={onChangeTemplateField}
          errors={templateErrors}
          setErrors={setTemplateErrors}
          saveAsTemplate={templateFieldState.saveAsTemplate}
          className={styles.templateFieldsList}
          fileNameLabel="Name"
          fileNameRequired={true}
          isNewTemplate={true}
          isBundle={isBundle}
        />

        <TemplateCheckboxFields
          values={templateFieldState}
          onChange={onChangeTemplateField}
          setErrors={setTemplateErrors}
          isNewTemplate={true}
        />

        <Navigation onContinue={onSubmit} loading={loading} />
      </div>
    </>
  );
};
