import { useSelector } from 'react-redux';

import { getDynamicFormEditorConfigSelector } from 'store/selectors/requestFormProcess';
import { UserRoles } from '../UserRoles';

export const SignatureEditorWidgets = (props) => {
  const { selectedRole } = useSelector(getDynamicFormEditorConfigSelector);
  return (
    <>
      <UserRoles {...props} />
    </>
  );
};
