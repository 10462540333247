import Api from 'store/effects/core/api';
import {
  requestGetAgentDetailsAction,
  updateAgentProfileAction,
  updateAgentActiveStatusAction,
  updateAgentRoleAction,
} from 'store/actions/agentDetail';
import {
  getAgentDetail,
  updateMemberProfile,
  updateMemberActiveStatus,
  updateMemberRole,
} from 'api/teamList';

export const getAgentDetailsEffect = (cfg, options, cb) => {
  const requestParams = { action: requestGetAgentDetailsAction, method: getAgentDetail };
  const sendRequest = Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const updateMemberProfileEffect = (cfg, options, cb) => {
  const requestParams = { action: updateAgentProfileAction, method: updateMemberProfile };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const updateMemberActiveStatusEffect = (cfg, options, cb) => {
  const requestParams = { action: updateAgentActiveStatusAction, method: updateMemberActiveStatus };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const updateMemberRoleEffect = (cfg, options, cb) => {
  const requestParams = { action: updateAgentRoleAction, method: updateMemberRole };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};
