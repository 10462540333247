import { useState } from 'react';
import styles from './styles.module.scss';
import { Icons, ICON_VARIANT_TYPE } from 'pages/Workshop/Forms/Icons';
import { DownloadFilesModal } from 'pages/Workshop/Forms/components/FormDetailModal/components';

export const DownloadFilesWidget = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={styles.downloadWidgetContainer}>
      <DownloadFilesModal
        isCompleted={false}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        {...props}
      />

      <div className={styles.downloadWidget} onClick={() => setModalOpen(true)}>
        <Icons variant={ICON_VARIANT_TYPE.DOWNLOAD} stroke="#515151" />
      </div>
    </div>
  );
};
