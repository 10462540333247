import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LocationService } from 'services';

import { LocalHeader } from './components/Localheader';
import { Cancelled } from './filteredComponents/Cancelled';
import { Past } from './filteredComponents/Past';
import { Requests } from './filteredComponents/Requests';
import { Upcoming } from './filteredComponents/Upcoming';

import { TeamFilter, TourSort, TourStatus } from 'app-constants/enums/tours';
import { useDispatch, useSelector } from 'react-redux';
import { requestScheduleToursEffect } from 'store/effects/tour';
import { getTeamTransactionsSelector } from 'store/selectors/transactions';
import styles from './styles.module.scss';

export const Tours = () => {
  const [selectedSort, setSelectedSort] = useState(TourSort.ClientName);
  const [selectedFilter, setSelectedFilter] = useState(TourStatus.Requested);
  const [selectedTeamFilter, setSelectedTeamFilter] = useState(TeamFilter.JustMe);
  const teamTransactions = useSelector(getTeamTransactionsSelector);
  const dispatch = useDispatch();
  const locationSrv = new LocationService();
  const location = useLocation();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  useEffect(() => {
    dispatch(
      requestScheduleToursEffect({
        EntireTeam: selectedTeamFilter == TeamFilter.EntireTeam ? true : teamTransactions,
      }),
    );
  }, [teamTransactions, selectedTeamFilter]);

  useEffect(() => {
    if ('teamStats' in query)
      setSelectedTeamFilter(query.teamStats ? TeamFilter.EntireTeam : TeamFilter.JustMe);
  }, []);

  const onSortChange = (e) => {
    setSelectedSort(e.target.value);
  };

  const onFilterChange = (value: TourStatus) => {
    setSelectedFilter(value);
  };

  const onTeamFilterChange = (value: TeamFilter) => {
    setSelectedTeamFilter(value);
  };

  const renderPage = () => {
    switch (selectedFilter) {
      case TourStatus.Requested:
        return (
          <Requests selectedSort={selectedSort} selectedTeamFilter={selectedTeamFilter}></Requests>
        );
      case TourStatus.Approved:
        return (
          <Upcoming selectedSort={selectedSort} selectedTeamFilter={selectedTeamFilter}></Upcoming>
        );
      case TourStatus.Canceled:
        return (
          <Cancelled
            selectedSort={selectedSort}
            selectedTeamFilter={selectedTeamFilter}
          ></Cancelled>
        );
      case TourStatus.Past:
        return <Past selectedSort={selectedSort} selectedTeamFilter={selectedTeamFilter}></Past>;
      default:
        return (
          <Requests selectedSort={selectedSort} selectedTeamFilter={selectedTeamFilter}></Requests>
        );
    }
  };

  return (
    <div className={styles.toursWrapper}>
      <LocalHeader
        onSortChange={onSortChange}
        onFilterChange={onFilterChange}
        onTeamFilterChange={onTeamFilterChange}
        selectedSort={selectedSort}
        selectedFilter={selectedFilter}
        selectedTeamFilter={selectedTeamFilter}
      />
      <div className={styles.tourCardsWrapper}>
        <div className={styles.tourCards}>{renderPage()}</div>
      </div>
    </div>
  );
};
