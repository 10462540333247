import PropTypes from 'prop-types';

import { Photos, StreetViewImage, VirtualTourImage } from 'components/Icons';
import ListingDetailPhotoCollage from '../PhotoCollage';

import styles from './styles.module.scss';

const ListingDetailPhotosBlock = ({ photos, setTab, openModal, virtualTour }) => {
  return (
    <div className={styles.container}>
      {photos?.length ? (
        <ListingDetailPhotoCollage onClickImage={openModal} photos={photos} setTab={setTab} />
      ) : (
        <div className={styles.noPhotos}>No photos</div>
      )}
      <div className={styles.viewButtons}>
        <button
          onClick={() => {
            openModal();
            setTab('Street View');
          }}
          className={styles.openViewButton}
        >
          <StreetViewImage className={styles.openViewButtonIcon} />
          <span>Street View</span>
        </button>

        {virtualTour && (
          <button
            onClick={() => {
              openModal();
              setTab('Virtual Tour');
            }}
            className={styles.openViewButton}
          >
            <VirtualTourImage className={styles.openViewButtonIcon} />
            <span>Virtual Tour</span>
          </button>
        )}
      </div>

      {!!photos?.length && (
        <button
          testid="photos_button"
          onClick={() => {
            openModal();
            setTab('Photos');
          }}
          className={styles.openPhotos}
        >
          <Photos className={styles.openPhotosIcon} />
          <span>{photos.length}</span>
        </button>
      )}
    </div>
  );
};

ListingDetailPhotosBlock.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ListingDetailPhotosBlock;
