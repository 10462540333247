import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MapControl from '../MapControl';

import styles from './styles.module.scss';
import Icons from '../Icons';

const ZoomControls = ({ map, className, onZoomChanged }) => {
  const onZoomIn = useCallback(() => {
    if (typeof map?.setZoom === 'function' && typeof map?.getZoom === 'function') {
      map.setZoom(map.getZoom() + 1);
      onZoomChanged();
    }
  }, [map]);

  const onZoomOut = useCallback(() => {
    if (typeof map?.setZoom === 'function' && typeof map?.getZoom === 'function') {
      map.setZoom(map.getZoom() - 1);
      onZoomChanged();
    }
  }, [map]);

  return (
    <MapControl
      position={window.google?.maps?.ControlPosition?.RIGHT_TOP}
      map={map}
      className={classNames(styles.zoomControlsWrapper, className)}
    >
      <div className={styles.zoomControls}>
        <button className={styles.control} onClick={onZoomIn}>
          <Icons variant={Icons.ZOOM_IN} />
        </button>
        <hr />
        <button className={styles.control} onClick={onZoomOut}>
          <Icons variant={Icons.ZOOM_OUT} />
        </button>
      </div>
    </MapControl>
  );
};

ZoomControls.propTypes = {
  map: PropTypes.shape({
    getZoom: PropTypes.func,
    setZoom: PropTypes.func,
  }),
  className: PropTypes.string,
};

ZoomControls.defaultProps = {
  map: undefined,
  className: '',
};

export default ZoomControls;
