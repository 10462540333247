import { useDispatch, useSelector } from 'react-redux';
import { smartFormPublishedByOption } from '../../../helper';
import { getPublishedFormsEffect, resetPublishedFormEffect } from 'store/effects/formProcess';
import { useEffect, useState } from 'react';
import { getPublishedFormsSelector } from 'store/selectors/requestFormProcess';

export const useFetchPublishedForms = (props) => {
  const { filterPayload, people, teams, locations } = props;
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const { isPending, data: myLibraryFiles } = useSelector(getPublishedFormsSelector);

  const getPublishedForms = () => {
    setLoading(true);

    const { publishByIds, lastUpdatedTo, lastUpdatedFrom, search, ...restFilters } = filterPayload;
    const includeSmartForms = publishByIds.includes(smartFormPublishedByOption.Id);

    const payload = {
      ...(people ? { people } : {}),
      ...(teams ? { teams } : {}),
      ...(locations ? { locations } : {}),
      ...(lastUpdatedFrom ? { lastUpdatedFrom } : {}),
      ...(lastUpdatedTo ? { lastUpdatedTo } : {}),

      ...restFilters,
      fetchAgentForms: true,
      includeSmartForms,
      search,
      ...(includeSmartForms
        ? {
            publishByIds: publishByIds.filter((id) => id !== smartFormPublishedByOption.Id),
          }
        : { publishByIds }),
    };

    dispatch(
      getPublishedFormsEffect(payload, () => {
        setLoading(false);
      }),
    );
  };

  useEffect(() => {
    getPublishedForms();

    return () => {
      dispatch(resetPublishedFormEffect());
    };
  }, [filterPayload]);

  return {
    getPublishedForms,
    myLibraryFiles,
    isPending: isPending || loading,
    dataSource: myLibraryFiles?.forms,
  };
};
