import React from 'react';
import moment from 'moment';

import { subtractTimeZone } from 'helpers';

import styles from './styles.module.scss';
import {
  ApproveMlsAgentButton,
  InactivateMlsAgentButton,
  RejectMlsAgentButton,
} from './Components/View';

export function tableColumns(isActive, onReactivateClick, onDeactivateClick, onMlsClick) {
  const columns = [
    {
      title: 'MLS',
      key: 'mlsName',
      dataIndex: 'MlsName',
      sorter: (a, b) => {
        return a?.MlsName?.localeCompare(b?.MlsName);
      },
      width: 200,
      render: (_, { MlsName }) => {
        return <span className={styles.tableText}>{MlsName}</span>;
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'Status',
      width: 200,
      sorter: (a, b) => {
        if (a.Status < b.Status) return -1;
        if (a.Status > b.Status) return 1;
        return 0;
      },
      render: (_, { Status }) => {
        return <span className={styles.tableText}>{Status}</span>;
      },
    },
    {
      title: 'Requested At',
      key: 'requestedAt',
      dataIndex: 'RequestedAt',
      width: 250,
      sorter: (a, b) => {
        if (a.RequestedAt < b.RequestedAt) return -1;
        if (a.RequestedAt > b.RequestedAt) return 1;
        return 0;
      },
      render: (_, { RequestedAt }) => {
        let date = moment(RequestedAt).format('MM/DD/YYYY');
        let time = moment(RequestedAt).format('hh:mm A');
        return (
          <span className={styles.tableText}>
            {date}-{time}
          </span>
        );
      },
    },
    {
      title: 'Approved At',
      key: 'memberTitle',
      dataIndex: 'ApprovedAt',
      width: 250,
      sorter: (a, b) => {
        if (a.Title < b.Title) return -1;
        if (a.Title > b.Title) return 1;
        return 0;
      },
      render: (_, { ApprovedAt }) => {
        let date = moment(ApprovedAt).format('MM/DD/YYYY');
        let time = moment(ApprovedAt).format('hh:mm A');
        return <span className={styles.tableText}>{ApprovedAt ? `${date}-${time}` : 'N/A'}</span>;
      },
    },
    {
      title: 'Approved By',
      key: 'approvedByName',
      dataIndex: 'ApprovedByName',
      width: 150,
      sorter: (a, b) => {
        if (a.ApprovedByName < b.ApprovedByName) return -1;
        if (a.ApprovedByName > b.ApprovedByName) return 1;
        return 0;
      },
      render: (_, { ApprovedByName }) => {
        return <span className={styles.tableText}>{ApprovedByName ? ApprovedByName : 'N/A'}</span>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'Actions',
      width: 150,
      render: (_, row) => {
        console.log('Row is ', row);
        return (
          <div className={styles.actionBtns}>
            <ApproveMlsAgentButton status={row.Status} mlsId={row.MlsId} userId={row.AgentId} />
            <RejectMlsAgentButton status={row.Status} mlsId={row.MlsId} userId={row.AgentId} />
            <InactivateMlsAgentButton status={row.Status} mlsId={row.MlsId} userId={row.AgentId} />
          </div>
        );
      },
    },
  ];

  return columns;
}
