import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { getLocationContext, getPlaceComponent } from 'helpers/locations';
import { Locations } from 'components';
import { Checkbox, Col, Row } from 'components-antd';
import { AddFile, RemoveFile } from 'components/Icons';
import { ClientAddress, ClientAddressEntity } from 'app-constants';
import { LOCALITY, POSTAL_CODE, ROUTE, STATE, STREET_NUMBER } from 'settings/constants/locations';
import styles from './styles.module.scss';
import containerStyles from '../styles.module.scss';

interface EditClientAddressesProps {
  clientAddresses: ClientAddress[];
  error: string;
  disabled: boolean;
  onChangeAddress: (address: ClientAddress[]) => void;
}

export type LocationResultType = {
  result: { place_id: string }[];
  placeName: string;
};

export const EditClientAddresses = ({
  clientAddresses,
  error,
  disabled,
  onChangeAddress,
}: EditClientAddressesProps) => {
  const emptyLocation = {
    address: '',
    placeId: '',
    isPrimary: false,
    entityType: ClientAddressEntity.User,
  };

  const [locations, setLocations] = useState<ClientAddress[]>(
    clientAddresses.length ? clientAddresses : [emptyLocation],
  );
  const [addAddressDisabled, setAddAddressDisabled] = useState(false);

  useEffect(() => {
    setAddAddressDisabled(!!locations.find((location) => !location.placeId));
    const updatedAddresses = locations.filter((location) => location.placeId);

    if (updatedAddresses.length && !locations.filter((location) => location.isPrimary).length) {
      updatedAddresses[0].isPrimary = true;
    }

    onChangeAddress(updatedAddresses);
  }, [locations]);

  const addLocation = () => {
    setLocations((prevLocations) => [...prevLocations, emptyLocation]);
  };

  const updateLocation = (
    placeId: string,
    isPrimary: boolean,
    result: LocationResultType,
    preparedData,
  ) => {
    const currentLocation = result?.result?.[0];
    let updatedLocation: any = { ...emptyLocation, isPrimary };
    if (
      currentLocation &&
      !locations.find((location) => location.placeId === currentLocation.place_id)
    ) {
      const updatedCity = getLocationContext(currentLocation, LOCALITY);
      const updatedState = getLocationContext(currentLocation, STATE);
      const updatedZip = getLocationContext(currentLocation, POSTAL_CODE);
      const street = getPlaceComponent(currentLocation, ROUTE);
      const streetNumber = getLocationContext(currentLocation, STREET_NUMBER);
      updatedLocation = {
        address: result?.placeName,
        placeId: currentLocation.place_id,
        isPrimary,
        entityType: ClientAddressEntity.User,
        addressParsed: {
          City: updatedCity,
          State: updatedState,
          Zip: updatedZip,
          Line1: [streetNumber, street?.name].filter((i) => !!i).join(' '),
          ProviderPlaceId: currentLocation.place_id,
          PlaceName: result?.placeName,
          Street: preparedData.length && preparedData[0].Street ? preparedData[0].Street : '',
        },
      };
    }
    setLocations((prevLocations) =>
      prevLocations.map((prevLocation) =>
        prevLocation.placeId === placeId ? updatedLocation : prevLocation,
      ),
    );
  };

  const updatePrimaryStatus = (placeId: string) => {
    const updatedLocations = locations.map((location) => ({
      ...location,
      isPrimary: placeId === location.placeId,
    }));
    setLocations(updatedLocations);
  };

  const removeLocation = (placeId: string) => {
    setLocations((prevLocations) => [...prevLocations.filter((el) => el.placeId !== placeId)]);
  };

  const addressRow = ({ address, placeId, isPrimary }: ClientAddress) => {
    const addressDisabled = disabled || (!!placeId && addAddressDisabled);
    return (
      <Col>
        <Row
          className={classNames(styles.locationAndRemoveIcon, {
            [styles.addressDisabled]: addressDisabled,
          })}
        >
          <Col className={styles.locationCol}>
            <Locations
              multiple={false}
              types={['address']}
              variant="full"
              placeholder="Enter address"
              onResult={(result, preparedData) =>
                updateLocation(placeId, isPrimary, result, preparedData)
              }
              value={
                address && placeId
                  ? [
                      {
                        PlaceName: address,
                        ProviderPlaceId: placeId,
                      },
                    ]
                  : undefined
              }
              error={error}
              activeInputClassName={styles.inputFieldLocations}
              disabled={addressDisabled}
              testid="address"
              placeholderClassName={styles.locationPlaceholder}
            />
          </Col>
          {locations?.length > 1 && (
            <div className={styles.removeIconPosition}>
              <RemoveFile
                className={classNames(styles.deleteIcon, {
                  [styles.addressDisabled]: addressDisabled,
                })}
                onClick={() => !addressDisabled && removeLocation(placeId)}
              />
            </div>
          )}
        </Row>
        <Row className={styles.primaryContainer}>
          <Checkbox
            className={styles.primaryCheckbox}
            checked={isPrimary}
            onChange={() => updatePrimaryStatus(placeId)}
          />
          <span className={styles.primaryText}>Set as Primary</span>
        </Row>
      </Col>
    );
  };

  return (
    <>
      {locations.map((location, index) => (
        <div key={`${location.placeId}-${index}`} className={containerStyles.inputBlock}>
          <label htmlFor="address" className={containerStyles.editLabel}>
            Address {index ? index + 1 : undefined}
          </label>
          <div className={containerStyles.rightBlock}>{addressRow(location)}</div>
        </div>
      ))}
      <Row>
        <Col onClick={() => !addAddressDisabled && addLocation()}>
          <span
            className={classNames(styles.addAddressContainer, {
              [styles.addressDisabled]: addAddressDisabled,
            })}
          >
            <AddFile /> <span className={styles.addAddress}>Add address</span>
          </span>
        </Col>
      </Row>
    </>
  );
};
