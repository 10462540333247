import { apiServer } from 'settings/web-services/api';

export function onBoardingUser(cfg, { withoutRequest }) {
  if (withoutRequest) {
    return new Promise((resolve) => {
      resolve({ meta: cfg });
    });
  }
  const { id, role, stageIndex, ...config } = cfg;
  return apiServer.put(`/user/${role}/${id}`, config);
}

export function updateImportantFeatures(cfg, { withoutRequest }) {
  if (withoutRequest) {
    return new Promise((resolve) => {
      resolve({ meta: cfg });
    });
  }
  return apiServer.patch('/user/client/profile/searchPrefs', cfg);
}

export function uploadAvatar(cfg) {
  return apiServer.post('/user/profile/avatar', cfg);
}

export function requestPreApprovalQuote(cfg) {
  return apiServer.post('/quoteRequest/pre-approval-quote', cfg);
}

export function sendPreApprovalDocument(cfg) {
  const { formData } = cfg;
  delete cfg.formData;

  return apiServer.post(`/quoteRequest/pre-approval/document/upload`, formData, {
    params: cfg,
  });
}
