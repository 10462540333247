import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Wrapper as PendingWrapper } from 'components';

import styles from './styles.module.scss';

const Input = (props) => {
  const {
    className,
    getInputProps,
    placeholder,
    loading,
    variant,
    autoFocus,
    label,
    allowedManualInput,
  } = props;

  return (
    <div className={classNames(styles.inputWrapper, styles[variant], className)}>
      {label && <label>{label}</label>}
      <input
        {...getInputProps({
          placeholder,
          className: styles.input,
          autoFocus,
          autoComplete: 'nope',
        })}
      />
      {!allowedManualInput && (
        <PendingWrapper loaderClassName={styles.loader} isPending={loading} />
      )}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  getInputProps: PropTypes.func,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  allowedManualInput: PropTypes.bool,
};

Input.defaultProps = {
  className: '',
  getInputProps: () => {},
  placeholder: undefined,
  loading: false,
  autoFocus: false,
  allowedManualInput: false,
};

export default Input;
