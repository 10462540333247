import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Avatar } from 'components';

import styles from './styles.module.scss';

const ViewCategory = ({ categoryData: { IsActive, Title, Type, QuoteFormId, Icon } }) => (
  <div className={styles.wrapper}>
    <div>
      <Avatar
        className={styles.avatar}
        src={Icon}
        placeholder={Title?.substring(0, 1).toUpperCase()}
      />
    </div>
    <div className={styles.rightBlock}>
      <div className={styles.flexWrapper}>
        <p className={styles.name}>{Title}</p>
        <div className={styles.flexWrapper}>
          <div
            className={classNames(styles.marker, styles.isActiveMarker, {
              [styles.deactivated]: !IsActive,
            })}
          >
            {IsActive ? 'Active' : 'Deactivated'}
          </div>
        </div>
      </div>
      {!!Type && (
        <div className={styles.inputBlock}>
          <span className={styles.label}>Type</span>
          <span className={styles.infoBlockForOneLine}>
            {Type?.replace(/([A-Z])/g, ' $1').trim()}
          </span>
        </div>
      )}
      <div className={styles.inputBlock}>
        <span className={styles.label}>Form Set</span>
        <span className={styles.infoBlockForOneLine}>{QuoteFormId ? 'Yes' : 'No'}</span>
      </div>
    </div>
  </div>
);

ViewCategory.propTypes = {
  categoryData: PropTypes.shape({
    Title: PropTypes.string,
    Type: PropTypes.string,
    QuoteFormId: PropTypes.string,
    IsActive: PropTypes.bool,
    Icon: PropTypes.string,
  }).isRequired,
};

ViewCategory.defaultProps = {};

export default ViewCategory;
