import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { getFormattedListingDetailBuyerMatch } from 'store/selectors/listingDetail';
import { Accordion, CircularProgressBar } from 'components';
import MatchItemsList from './MatchItemsList';
import { getUserInsight } from 'store/selectors/user';
import { insightUpdateData } from 'store/effects';

import styles from './styles.module.scss';

const Title = ({ match, insight, onChange }) => (
  <div className={styles.titleContainer}>
    <CircularProgressBar size="32" percentage={match} />
    <div testid="match_title" className={styles.title}>{`${match}% Match`}</div>
    <div className={styles.insights}>
      <span>Insights: </span>
      <div className={styles.container} onClick={() => onChange(!insight)}>
        <span>{insight ? 'On' : 'Off'}</span>
      </div>
    </div>
  </div>
);

Title.propTypes = {
  match: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const Match = () => {
  const dispatch = useDispatch();
  const insight = useSelector(getUserInsight);
  const { match, mustHave, wants } = useSelector(getFormattedListingDetailBuyerMatch);

  const onInsightChange = (value) => {
    dispatch(insightUpdateData(value));
  };

  if (match === null) {
    return null;
  }

  return (
    <div testid="match_container" className={styles.matchContainer}>
      <Accordion
        open
        className={styles.accordion}
        title={<Title match={match} insight={insight} onChange={onInsightChange} />}
      >
        <div className={styles.matchContent}>
          {!!mustHave?.length && (
            <div testid="match_needs_block" className={styles.needsContainer}>
              <p className={styles.matchItemsTitle}>Needs</p>
              <MatchItemsList items={mustHave} insight={insight} />
            </div>
          )}
          {!!wants?.length && (
            <div testid="match_wants_block" className={styles.wantsContainer}>
              <p className={styles.matchItemsTitle}>Wants</p>
              <MatchItemsList items={wants} insight={insight} />
            </div>
          )}
        </div>
      </Accordion>
    </div>
  );
};

export default Match;
