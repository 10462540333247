import React from 'react';

import { FORM_QUESTION_TYPE, SMART_FORM_QUESTION_TYPE } from 'app-constants';
import { FormQuestionType, GroupedQuestions } from 'types';

interface QuestionIconProp extends React.HTMLAttributes<HTMLDivElement> {
  variant: FormQuestionType;
}

const { FreeText, YesNo, Currency, Date, Email, Number, PhoneNumber, FileUpload, ListBox } =
  FORM_QUESTION_TYPE;

const { Signature } = SMART_FORM_QUESTION_TYPE;

const QuestionIconList = {
  [FreeText]: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#928CDA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 10C7 9.44772 7.44772 9 8 9H20C20.5523 9 21 9.44772 21 10C21 10.5523 20.5523 11 20 11H8C7.44772 11 7 10.5523 7 10ZM7 14C7 13.4477 7.44772 13 8 13H20C20.5523 13 21 13.4477 21 14C21 14.5523 20.5523 15 20 15H8C7.44772 15 7 14.5523 7 14ZM8 17C7.44772 17 7 17.4477 7 18C7 18.5523 7.44772 19 8 19H14C14.5523 19 15 18.5523 15 18C15 17.4477 14.5523 17 14 17H8Z"
        fill="white"
      />
    </svg>
  ),
  [YesNo]: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#FB913A" />
      <circle cx="9.5" cy="14" r="3.5" fill="white" />
      <circle cx="18.5" cy="14" r="2.5" stroke="white" strokeWidth="2" />
    </svg>
  ),
  [Currency]: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#00B152" />
      <path
        d="M17.6 9.875C17.6 9.875 16.4 8.5 14 8.5C11.6 8.5 10.4 9.875 10.4 11.25C10.4 14.6875 18 12.9 18 16.75C18 18.4 16.6667 19.5 14 19.5C11.3333 19.5 10 17.85 10 17.85"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 7L14 21"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [Date]: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#4673D1" />
      <rect
        x="8"
        y="9.5"
        width="12"
        height="10"
        rx="1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 13H20" stroke="white" strokeWidth="2" />
      <path d="M11 8V10" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M17 8V10" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),

  [Email]: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#4673D1" />
      <rect x="7" y="9" width="14" height="10" rx="1" stroke="white" strokeWidth="2" />
      <path
        d="M7.5 10L13.4308 14.1059C13.7732 14.343 14.2268 14.343 14.5692 14.1059L20.5 10"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [Number]: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#00B152" />
      <path d="M8.5 11H20.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M9 20L13 8" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M15 20L19 8" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M7.5 17H19.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  [PhoneNumber]: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#FF576D" />
      <path
        d="M8.64706 7H11.9412L13.5882 11.1176L11.5294 12.3529C12.4114 14.1413 13.8587 15.5886 15.6471 16.4706L16.8824 14.4118L21 16.0588V19.3529C21 19.7898 20.8265 20.2087 20.5176 20.5176C20.2087 20.8265 19.7898 21 19.3529 21C16.1406 20.8048 13.1107 19.4406 10.835 17.165C8.55936 14.8893 7.19522 11.8594 7 8.64706C7 8.21023 7.17353 7.7913 7.48241 7.48241C7.7913 7.17353 8.21023 7 8.64706 7"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [FileUpload]: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#FB913A" />
      <path
        d="M8.66699 16.333V17.6663C8.66699 18.02 8.80747 18.3591 9.05752 18.6091C9.30757 18.8592 9.6467 18.9997 10.0003 18.9997H18.0003C18.354 18.9997 18.6931 18.8592 18.9431 18.6091C19.1932 18.3591 19.3337 18.02 19.3337 17.6663V16.333"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 11L14 8L17 11"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 8.66699V15"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [ListBox]: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#51BFE1" />
      <circle cx="9" cy="11" r="1" stroke="white" strokeWidth="2" />
      <circle cx="9" cy="17" r="1" stroke="white" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 11C21 11.5523 20.5523 12 20 12H14C13.4477 12 13 11.5523 13 11C13 10.4477 13.4477 10 14 10H20C20.5523 10 21 10.4477 21 11Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 17C21 17.5523 20.5523 18 20 18H14C13.4477 18 13 17.5523 13 17C13 16.4477 13.4477 16 14 16H20C20.5523 16 21 16.4477 21 17Z"
        fill="white"
      />
    </svg>
  ),
  [GroupedQuestions]: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#928CDA" />
      <mask id="path-2-inside-1_6721_47836" fill="white">
        <rect x="7" y="8" width="14" height="5" rx="1" />
      </mask>
      <rect
        x="7"
        y="8"
        width="14"
        height="5"
        rx="1"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-2-inside-1_6721_47836)"
      />
      <mask id="path-3-inside-2_6721_47836" fill="white">
        <rect x="7" y="15" width="14" height="5" rx="1" />
      </mask>
      <rect
        x="7"
        y="15"
        width="14"
        height="5"
        rx="1"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-3-inside-2_6721_47836)"
      />
    </svg>
  ),

  [Signature]: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#4673D1" />
      <path
        d="M13 14.9998H15.475L21.975 8.49928C22.3032 8.17108 22.4876 7.72594 22.4876 7.26179C22.4876 6.79764 22.3032 6.35251 21.975 6.0243C21.6468 5.6961 21.2016 5.51172 20.7375 5.51172C20.2733 5.51172 19.8282 5.6961 19.5 6.0243L13 12.5248V14.9998Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 7.5L20.5 9.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15H8.5C7.67157 15 7 15.6716 7 16.5V16.5C7 17.3284 7.66965 18 8.49808 18C9.84871 18 11.8676 18 13.5011 18C14.3295 18 15 18.6716 15 19.5V19.5C15 20.3284 14.3284 21 13.5 21H10"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

const QuestionIcon = ({ variant, ...props }: QuestionIconProp) => (
  <div {...props}>{QuestionIconList[variant]}</div>
);

export default QuestionIcon;
