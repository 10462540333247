import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';
import { listingsFilters, showingsFilters } from 'settings/constants/showings';

import styles from './styles.module.scss';
import {
  getShowingFilterSelector,
  getShowingIsArchiveSelector,
} from 'store/selectors/showingAggregate';
import {
  getShowingAggregateByUserIdEffect,
  setListingsFilterEffect,
  setShowingsIsArchiveEffect,
} from 'store/effects/showingAggregate';
import { TEAM_OWNER, TEAM_ADMIN } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { getUserId } from 'store/selectors/user';

export const LisitngsFilter = (props) => {
  const userId = useSelector(getUserId);
  const [isFiltersApplied, setIsFilterApplied] = useState(false);
  const [value, setValue] = useState(listingsFilters.allListings);
  const dispatch = useDispatch();
  const { isArchive } = useSelector(getShowingIsArchiveSelector);
  const { showingsFilter } = useSelector(getShowingFilterSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  useEffect(() => {
    // Reset Filters whenever archive button is clicked.
    setValue(listingsFilters.allListings);
    dispatch(setListingsFilterEffect(listingsFilters.allListings));
    setIsFilterApplied(false);
  }, [isArchive]);

  const onChange = (e) => {
    const val = e.target.value;
    const isFiltersApplied = val !== listingsFilters.allListings;

    const additionalFilters = {
      [showingsFilters.myShowings]: { isMyShowing: true },
      [showingsFilters.teamShowings]: { isTeamShowing: true },
      [showingsFilters.allShowings]: {},
    };

    let filters = {
      [listingsFilters.myListings]: { isMyListing: true, ...additionalFilters[showingsFilter] },
      [listingsFilters.teamListings]: { isTeamListing: true, ...additionalFilters[showingsFilter] },
      [listingsFilters.allListings]: { ...additionalFilters[showingsFilter] },
    };

    dispatch(setShowingsIsArchiveEffect(false));
    dispatch(
      getShowingAggregateByUserIdEffect({
        id: userId,
        filters: filters[val],
      }),
    );

    setIsFilterApplied(isFiltersApplied);
    dispatch(setListingsFilterEffect(val));
    setValue(val);
  };

  // Hide Team Listings option for Team members.
  const teamAdminAndOwnerOption = [TEAM_OWNER, TEAM_ADMIN].includes(agentRole)
    ? [listingsFilters.teamListings]
    : [];

  const dropdownFields = [
    listingsFilters.myListings,
    ...teamAdminAndOwnerOption,
    listingsFilters.allListings,
  ];

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => (
          <MenuItem key={idx}>
            <Radio value={item} key={idx}>
              <div className={styles.option}>
                <span className={styles.optionValue}>{item}</span>
              </div>
              {value === item && <Check className={styles.checkIcon} />}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div
      className={classNames(
        styles.filterWrap,
        {
          [styles.filtersAppliedWrapper]: isFiltersApplied,
        },
        props.className,
      )}
    >
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.selectedValue}>{value}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
        </a>
      </Dropdown>
    </div>
  );
};
