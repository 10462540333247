import { Icons } from '../../../Icons';
import { IconText } from '../IconText';

import styles from '../styles.module.scss';

export const EditDocuments = ({ data, onEdit }) => {
  const getInfo = () => {
    const { RequiredDocuments } = data;
    let required = 0;
    let selected = 0;
    const selectedDocs: any = [];
    const requiredDocs: any = [];
    RequiredDocuments.forEach((value) => {
      if (value.required) {
        requiredDocs.push(value?.name);
        required++;
        selected++;
      } else if (value.selected) {
        selectedDocs.push(value?.name);
        selected++;
      }
    });
    let text = '';
    if (RequiredDocuments) {
      requiredDocs.forEach((value) => {
        text += `${value}, `;
      });
      selectedDocs.forEach((value) => {
        text += `${value}, `;
      });
    }

    if (RequiredDocuments?.length) {
      const info = `Required Documents (${required})`;
      text += text ? ` ${info}` : info;
    }

    return text ? text : '-';
  };

  return (
    <div className={styles.editForm}>
      <IconText
        text={'What documents should be submitted?'}
        infoText={getInfo()}
        variant={'document-bg'}
        className={styles.editFormInfo}
      />
      <div className={styles.editIcon} onClick={onEdit}>
        <Icons variant={'edit'} />
      </div>
    </div>
  );
};
