import { apiServer } from 'settings/web-services/api';
import { CLIENT } from 'settings/constants/roles';
import { getState } from 'store';
import { orderBy, toLower } from 'lodash-es';
import { AGENT_CONNECTION_STATUSES } from 'settings/constants/common';

// TODO: drop after open houses are implemented
export function getAllProps(cfg) {
  return apiServer.get('/listings/feed/all', { params: cfg });
}

export function getClientFavorites(cfg) {
  const { userId, ...config } = cfg;
  const { feed, user, context: contextState } = getState();
  const {
    sort: { order, fields },
  } = feed;
  const contextId = contextState.context?.ContextKey;

  return new Promise((resolve) => {
    apiServer
      .get(`/user/client/${userId || 'profile'}/favorites${contextId ? `/${contextId}` : ''}`, {
        params: config,
      })
      .then((response) => {
        resolve({
          data: {
            result: response?.data?.result,
            // result: orderBy(
            //   fields,
            //   fields.map(() => toLower(order)),
            // ),
          },
          meta: { params: config },
        });
      });
  });
}

export function getClientAllFavorites(cfg) {
  const { userId, ...config } = cfg;
  const { feed } = getState();
  const {
    sort: { order, fields },
  } = feed;

  return new Promise((resolve) => {
    apiServer.get(`user/client/profile/favorites-all`, { params: config }).then((response) => {
      resolve({
        data: {
          result: orderBy(
            response?.data?.result,
            fields,
            fields.map(() => toLower(order)),
          ),
        },
        meta: { params: config },
      });
    });
  });
}

export function getAgentClientssWithFavoritesCount() {
  return apiServer.get('/user/agent/clients/favorites');
}

export function sendShareProps(cfg) {
  return apiServer.post('/user/recommendProperty', cfg);
}

export function getCompareProperties(cfg) {
  return apiServer.get(`/listings?ids=${cfg.ids.join(',')}`);
}

export function getClientSearchInstancesByAgent() {
  return apiServer.get('/agent/clients-instances');
}

export function getClientPreferencesForComparison(cfg) {
  return apiServer.get(`/listings/searchInstance/${cfg.instanceId}?ids=${cfg.ids.join(',')}`);
}

export function initiateImageAnalysisMethod(cfg) {
  return apiServer.post('/imageai/v2/process-images', cfg);
  // return apiServer.post('/imageai/initiate', cfg);
}
