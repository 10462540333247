import { handleActions } from 'redux-actions';

import { initialData } from '.';
import { conciergeSearchReducer } from './reducers';

export const conciergeConfigurationReducer = handleActions(
  {
    ...conciergeSearchReducer,
  },
  initialData,
);
