import React, { FC, forwardRef, MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Spinner } from 'components';

import styles from './styles.module.scss';

interface Props {
  id?: string;
  className?: string;
  loaderClassName?: string;
  disabledClassName?: string;
  titleClassName?: string;
  title?: string | React.ReactNode;
  type?: string;
  disabled?: boolean;
  onClick?: (e?: any) => void;
  onKeyPress?: () => void;
  isPending?: boolean;
  icon?: React.ReactNode;
  iconLeft?: React.ReactNode;
  testid?: string;
  form?: string;
  component?: any;
  inverseColor?: boolean;
  to?: string;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
}
const Button: FC<Props> = forwardRef(
  (
    { id, title, type = 'button', component: Component = 'button', inverseColor = false, ...props },
    ref,
  ) => {
    const {
      disabled = false,
      className = '',
      titleClassName = '',
      onClick = () => {},
      icon,
      iconLeft,
      isPending = false,
      testid,
      form,
      disabledClassName = '',
      loaderClassName = '',
      ...rest
    } = props;

    const getTitle = () => (
      <div className={titleClassName}>
        {iconLeft && iconLeft}
        <span>{title}</span>
        {icon && icon}
      </div>
    );

    return (
      <Component
        ref={ref}
        id={id}
        type={type}
        disabled={disabled || isPending}
        className={classNames(
          styles.button,
          { [styles.disabled]: disabled },
          className,
          disabled ? disabledClassName : '',
        )}
        onClick={onClick}
        form={form}
        testid={testid}
        {...rest}
      >
        {isPending ? (
          <Spinner
            loaderClassName={classNames(
              { [styles.loader]: !inverseColor },
              { [styles.loaderInverseColor]: inverseColor },
              loaderClassName,
            )}
          />
        ) : (
          getTitle()
        )}
      </Component>
    );
  },
);

export default Button;
