import serviceDirectory from './serviceDirectory';
import templates from './templates';
import transaction from './transaction';
import users from './users';
import notificationTemplates from './notificationTemplates';
import loginLogs from './loginLogs';
import pdfEditor from './pdfEditor';
import mlsAgents from './mlsAgents';
import areaClassifier from './areaClassifier';
import kits from './kits';
import events from './events';
import salesLead from './salesLead';
import teams from './teams';

export default {
  ...serviceDirectory,
  ...templates,
  ...transaction,
  ...users,
  ...notificationTemplates,
  ...loginLogs,
  ...pdfEditor,
  ...mlsAgents,
  ...areaClassifier,
  ...kits,
  ...events,
  ...salesLead,
  ...teams,
};
