import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { appOpenModalEffect } from 'store/effects/app';
import { getModalSelector } from 'store/selectors/app';
import { Modal } from 'components';
import { Button } from 'components-antd';
import Options from './Options';

import styles from './styles.module.scss';

const AmenitiesModal = ({ className, onNext, value }) => {
  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();
  const [values, setValues] = useState(value || {});

  useEffect(() => setValues(value), [value]);

  if (modal.id !== AmenitiesModal.id) {
    return null;
  }

  const onClose = () => {
    onNext(values);
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  return (
    <Modal
      className={classNames(styles.modalWrapper, className)}
      innerHolderClassName={styles.innerHolder}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      closeClassName={styles.close}
      isOpen={modal.open}
      onClose={onClose}
      testid="amenities_modal"
    >
      <h2 className={styles.title}>
        Which of these amenities are you looking for (in your community or building)?
      </h2>
      <Options testid="amenities_option" onChange={setValues} value={values} />
      <div className={styles.bottom}>
        <Button testid="amenities_continue" onClick={onClose} className={styles.saveButton}>
          Continue
        </Button>
      </div>
    </Modal>
  );
};

AmenitiesModal.id = 'amenitiesModal';

AmenitiesModal.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  value: PropTypes.shape({
    amenities: PropTypes.arrayOf(PropTypes.object),
  }),
};

AmenitiesModal.defaultProps = {
  className: '',
  onNext: () => {},
  value: {},
};

export default AmenitiesModal;
