import { Icons } from '../../../Icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const InformationText = ({ text, className }) => {
  return (
    <div className={classNames(styles.informationText, className)}>
      <Icons variant={'info-light'} />
      <h4 className={classNames(styles.info, styles.text)}>{text}</h4>
    </div>
  );
};

InformationText.propTypes = {
  className: PropTypes.string,
  testid: PropTypes.string,
  text: PropTypes.string,
};

InformationText.defaultProps = {
  className: '',
  text: '',
  testid: undefined,
};
