import React from 'react';
import moment from 'moment';

import styles from './styles.module.scss';
import { formatPrice, limitTextLength } from 'helpers';
import { CardBox } from 'pages/ExternalOffer/OfferSubmission/components/CardBox';
import { Icons } from '../../../../components/Icons';
import { IconText } from '../IconText';

export const EditHighlights = ({ data, onEdit }) => {
  const getInfo = () => {
    const { Buyer, OfferIteration } = data;
    let text = '';

    if (OfferIteration?.PurchasePrice) {
      text += `Price: $${formatPrice(OfferIteration?.PurchasePrice)}, `;
    }
    if (OfferIteration?.FinancingType) {
      text += `Financing Type: ${OfferIteration?.FinancingType}, `;
    }
    if (Buyer.length > 0) {
      text += `Buyer: ${Buyer}, `;
    }

    if (OfferIteration?.DownPayment) {
      text += `DownPayment: $${formatPrice(OfferIteration?.DownPayment)}, `;
    }
    if (OfferIteration?.EarnestMoneyDeposit) {
      text += `EarnestMoneyDeposit: $${formatPrice(OfferIteration?.EarnestMoneyDeposit)}, `;
    }
    if (OfferIteration?.ClosingDate) {
      text += `ClosingDate: ${moment(OfferIteration?.ClosingDate).format('MM/DD/YYYY')}, `;
    }
    if (OfferIteration?.Contingencies?.Appraisal?.selected) {
      text += `Appraisal, `;
    }
    if (OfferIteration?.Contingencies?.Financing?.selected) {
      text += `Financing, `;
    }
    if (OfferIteration?.Contingencies?.Inspection?.selected) {
      text += `Inspection, `;
    }
    if (OfferIteration?.Notes) {
      text += `Notes: ${OfferIteration?.Notes}`;
    }
    return text ? limitTextLength(text, 45) : '-';
  };

  return (
    <CardBox className={styles.editForm}>
      <IconText
        text={'Highlights'}
        infoText={getInfo()}
        variant={'highlights'}
        className={styles.editFormInfo}
      />
      <div className={styles.editIcon} onClick={onEdit}>
        <Icons variant={'edit'} />
      </div>
    </CardBox>
  );
};
