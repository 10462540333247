import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'components-antd';
import { Icons } from 'pages/Vault/Icons';

import { DocsContentView } from 'pages/Vault/constants';
import DocumentsModal from 'components/Transactions/UploadDocuments/DocumentsModal';

import { uploadDocumentVaultEffect } from 'store/effects';
import { getMetaVaultDocuments } from 'store/selectors/vault';
import { getCurrentContextSelector } from 'store/selectors/context';
import { showErrorMessage, showSuccessMessage } from 'helpers';

import styles from './styles.module.scss';

interface LocalHeaderType {
  title: string;
  handleBackBtn?: () => void;
  viewType?: string;
  setViewType?: Function;
  hideUpload?: boolean;
  uploadCallback?: Function;
  isPreapprovals?: boolean;
}

export const LocalHeader = ({
  title,
  handleBackBtn,
  viewType,
  hideUpload = false,
  setViewType = () => {},
  uploadCallback = () => {},
  isPreapprovals = false,
}: LocalHeaderType) => {
  const dispatch = useDispatch();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [modalUpload, setModalUpload] = useState({
    open: false,
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { data } = useSelector(getMetaVaultDocuments);
  const { clientActiveTransactions } = data || {};

  const searchContext = useSelector(getCurrentContextSelector);
  const selectedAgentId = searchContext?.Agents?.[0]?.Id;

  const onUploadFiles = async (files) => {
    const { TransactionOrFolder } = files?.[0] || {};
    const itemExists = clientActiveTransactions?.find(
      (item) => item?.Property?.Address?.PlaceName === TransactionOrFolder,
    );

    const documentsPayload = files?.map((document) => ({
      Title: document?.documentName,
      CategoryId: document?.type,
      File: {
        ContentType: document?.contentType,
        Filename: document?.filename,
        Size: document?.size,
        Data: btoa(document?.data),
      },
    }));

    setUploadLoading(true);

    dispatch(
      uploadDocumentVaultEffect(
        {
          id: selectedAgentId,
          ...(itemExists
            ? { TransactionId: itemExists?.TransactionId }
            : { Folder: TransactionOrFolder }),
          Documents: documentsPayload,
        },
        {},
        (err) => {
          if (!err) {
            setModalUpload({
              open: false,
            });
            showSuccessMessage('Document uploaded successfully');
            uploadCallback();
          } else showErrorMessage(err);

          setUploadLoading(false);
        },
      ),
    );
  };

  return (
    <div className={styles.documentHeader}>
      <div className={styles.leftSide}>
        {handleBackBtn && (
          <Icons className={styles.iconBtn} variant={Icons.BACK} onClick={handleBackBtn} />
        )}
      </div>
      <span className={styles.headerTitle}>{title}</span>
      <div className={styles.rightSide}>
        {!hideUpload && (
          <Button
            variant="secondary"
            className={styles.secondaryBtn}
            icon={<Icons variant={Icons.UPLOAD} />}
            onClick={() => setModalUpload({ open: true })}
          >
            Upload
          </Button>
        )}
        {viewType && (
          <Icons
            className={styles.iconBtn}
            variant={viewType === DocsContentView.GRID_VIEW ? Icons.TABLE_VIEW : Icons.GRID_VIEW}
            onClick={() =>
              setViewType(
                viewType === DocsContentView.GRID_VIEW
                  ? DocsContentView.TABLE_VIEW
                  : DocsContentView.GRID_VIEW,
              )
            }
          />
        )}
        <DocumentsModal
          modal={modalUpload}
          multiple={false}
          files={uploadedFiles}
          isPending={uploadLoading}
          onSave={(files) => onUploadFiles(files)}
          onCloseModal={() => setModalUpload({ open: false })}
          vaultFile
          dropzone
          filesRequired
          fileNameRequired
          addMore={false}
          isPreapprovals={isPreapprovals}
        />
      </div>
    </div>
  );
};
