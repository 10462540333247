import { Switch } from 'react-router-dom';
import routesConfig from 'settings/navigation/config';
import { useSelector } from 'react-redux';

import { getUserRolesSelector } from 'store/selectors/user';
import AppRoute from './AppRoute';
import { getCurrentPlanLevelSelector } from 'store/selectors/subscription';
import { getClientContextsSelector } from 'store/selectors/contexts';
import usePaywallToast from 'pages/Paywall/hooks/usePaywallToast';

const AppRouter = () => {
  const userRoles = useSelector(getUserRolesSelector);
  const userSubscriptionPlanLevel = useSelector(getCurrentPlanLevelSelector);
  const clientContexts = useSelector(getClientContextsSelector);

  usePaywallToast();

  return (
    <Switch>
      {routesConfig.map(({ path, component, exact, roles }) => (
        <AppRoute
          key={path}
          userRoles={userRoles}
          userSubscriptionPlanLevel={userSubscriptionPlanLevel}
          clientContexts={clientContexts}
          routeRoles={roles}
          path={path}
          component={component}
          exact={exact}
        />
      ))}
    </Switch>
  );
};

export default AppRouter;
