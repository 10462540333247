import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const TaskEmpty = (props) => {
  const { className } = props;

  return (
    <div className={classNames(styles.taskEmpty, className)}>
      <div className={styles.message}>Please select the task</div>
    </div>
  );
};

TaskEmpty.propTypes = {
  className: PropTypes.string,
};

TaskEmpty.defaultProps = {
  className: '',
};

export default TaskEmpty;
