import classNames from 'classnames';

import { ActiveTransaction } from '../ActiveTransaction';

import styles from './styles.module.scss';

export const ActiveTransactions = (props) => {
  const { data, wrapperClassName } = props;

  return (
    <>
      {data?.map((item, idx) => {
        return (
          <div className={classNames(styles.activeTransactions, wrapperClassName)} key={idx}>
            <ActiveTransaction transaction={item} />
          </div>
        );
      })}
    </>
  );
};
