import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { LocalHeader } from './LocalHeader';
import TableView from './TableView';
import { getMilestoneAggregateEffect } from 'store/effects/milestoneAggregate';
import { getAggregatePageTypeSelector, getViewModeSelector } from 'store/selectors/workshop';

import { addProOrTransFilterParam } from 'utils/aggregatedPageTypeHelper';
import { PageWrapper } from '../PageWrapper';
import { LocationService } from 'services';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { getMilestoneArchiveStateSelector } from 'store/selectors/milestoneAggregate';

export const Milestones = () => {
  const dispatch = useDispatch();
  const aggregatedPageType = useSelector(getAggregatePageTypeSelector);
  const isArchive = useSelector(getMilestoneArchiveStateSelector, shallowEqual);
  const locationSrv = new LocationService();
  const location = useLocation();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  const fetchAggregatesMilestones = (isArchive = false) =>
    dispatch(
      getMilestoneAggregateEffect({
        filters: addProOrTransFilterParam(aggregatedPageType, {
          ...(query?.statuses && {
            statuses: query.statuses.split(','),
          }),
          isArchive: isArchive,
          ...((query?.dueToday || query?.dueWeek) && {
            dueFrom: query.dueToday
              ? moment()
              : query.dueWeek
              ? moment().startOf('week')
              : undefined,
            dueTo: query.dueToday ? moment() : query.dueWeek ? moment().endOf('week') : undefined,
          }),
        }),
      }),
    );

  useEffect(() => {
    if (isArchive) {
      fetchAggregatesMilestones(isArchive);
    } else {
      fetchAggregatesMilestones();
    }
  }, [aggregatedPageType, query, isArchive]);

  return (
    <PageWrapper>
      <LocalHeader />
      <TableView />
    </PageWrapper>
  );
};
