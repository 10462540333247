import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { DateRangePicker } from 'components/Form/DateRangePicker';
import { Moment } from 'moment';
import { FilterDrawer, FilterSection } from 'pages/Workshop/common';
import { GroupCheckBoxesSection } from 'pages/Workshop/common/GroupCheckBoxesSection';
import { MultiSelectSection } from 'pages/Workshop/common/MultiSelectSection';
import { LocationService } from 'services';
import {
  getQuotesCategoriesSelector,
  getQuotesClientsSelector,
  getQuotesDataSelector,
  getQuotesPropertiesSelector,
  getQuotesVendorsSelector,
} from 'store/selectors/quotes';
import { filterStatusMap, quoteRequestStatus } from 'settings/constants/quotes';
import { getUserRoleSelector } from 'store/selectors/user';
import { AGENT } from 'settings/constants/roles';

let defaultFilters = {};

export const FilterSections = ({ onDone, onReset }) => {
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();
  const userRole = useSelector(getUserRoleSelector);
  const quotesData = useSelector(getQuotesDataSelector);
  const vendorsData = useSelector(getQuotesVendorsSelector);
  const clientsData = useSelector(getQuotesClientsSelector);
  const categoriesData = useSelector(getQuotesCategoriesSelector);
  const propertiesData = useSelector(getQuotesPropertiesSelector);
  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
  const [dueFrom, setDueFrom] = useState<Moment>();
  const [dueTo, setDueTo] = useState<Moment>();
  const [requestedDueFrom, setRequestedDueFrom] = useState<Moment>();
  const [requestedDueTo, setRequestedDueTo] = useState<Moment>();
  const [cachedVendorsData, setCachedVendorsData] = useState<any[]>([]);
  const [cachedClientsData, setCachedClientsData] = useState({});
  const [cachedCategoriesData, setCachedCategoriesData] = useState<any[]>([]);
  const [cachedPropertiesData, setCachedPropertiesData] = useState<any[]>([]);
  const [vendors, setVendors] = useState<string[]>([]);
  const [clients, setClients] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [properties, setProperties] = useState<string[]>([]);

  const [resetRequestedDates, setResetRequestedDates] = useState(false);
  const [resetDates, setResetDates] = useState(false);

  const [showingStatuses, setShowingStatuses] = useState<string[]>([]);
  const [previousFilters, setPreviousFilters] = useState({});
  const getFilters = () => ({
    dueFrom,
    dueTo,
    requestedDueFrom,
    requestedDueTo,
    properties,
    vendors,
    clients,
    categories,
    showingStatuses,
  });

  useEffect(() => {
    defaultFilters = getFilters();
    setPreviousFilters(defaultFilters);
  }, []);

  useEffect(() => {
    if (!cachedVendorsData.length && vendorsData?.length) {
      setCachedVendorsData(vendorsData);
    }
    if (!Object.keys(cachedClientsData)?.length && Object.keys(clientsData)?.length) {
      setCachedClientsData(clientsData);
    }
    if (!cachedCategoriesData.length && categoriesData?.length) {
      setCachedCategoriesData(categoriesData);
    }
    if (!cachedPropertiesData.length && propertiesData?.length) {
      setCachedPropertiesData(propertiesData);
    }
  }, [propertiesData, categoriesData, vendorsData, clientsData]);

  useEffect(() => {
    setIsFiltersApplied(
      Boolean(
        requestedDueFrom ||
          requestedDueTo ||
          dueFrom ||
          dueTo ||
          properties.length ||
          categories.length ||
          vendors.length ||
          clients.length ||
          showingStatuses.length,
      ),
    );
  }, [
    dueFrom,
    dueTo,
    requestedDueFrom,
    requestedDueTo,
    properties,
    vendors,
    clients,
    categories,
    showingStatuses,
  ]);

  const submitHandler = () => {
    let statuses = [...showingStatuses];
    if (
      showingStatuses.length > 0 &&
      showingStatuses.includes(quoteRequestStatus.New) &&
      !showingStatuses.includes(quoteRequestStatus.PendingAnswer)
    ) {
      statuses.push(quoteRequestStatus.PendingAnswer);
    }
    onDone({
      statuses: statuses,
      vendors: vendors,
      clients: clients,
      properties,
      categories,
      dueTo,
      dueFrom,
      requestedDueFrom,
      requestedDueTo,
    });
    setPreviousFilters(getFilters());
  };

  const resetHandler = () => {
    setDueFrom(undefined);
    setDueTo(undefined);
    setRequestedDueFrom(undefined);
    setRequestedDueTo(undefined);
    setCategories([]);
    setProperties([]);
    setResetDates(true);
    setResetRequestedDates(true);
    setVendors([]);
    setClients([]);
    setShowingStatuses([]);
    onReset();
    setPreviousFilters(defaultFilters);
  };

  const discardHandler = () => {
    if (JSON.stringify(previousFilters) === JSON.stringify(getFilters())) {
      return;
    }

    const {
      dueFrom,
      dueTo,
      requestedDueFrom,
      requestedDueTo,
      properties,
      vendors,
      clients,
      categories,
      showingStatuses,
    } = previousFilters as any;

    if (dueFrom) setDueFrom(dueFrom);
    if (dueTo) setDueTo(dueTo);
    if (requestedDueFrom) setRequestedDueFrom(requestedDueFrom);
    if (requestedDueTo) setRequestedDueTo(requestedDueTo);
    if (properties) setProperties(properties);
    if (vendors) setVendors(vendors);
    if (clients) setClients(clients);
    if (categories) setCategories(categories);
    if (showingStatuses) setShowingStatuses(showingStatuses);
  };

  return (
    <FilterDrawer
      className={styles.filterBtn}
      isFiltersApplied={isFiltersApplied}
      onDone={submitHandler}
      onReset={resetHandler}
      onDiscard={discardHandler}
    >
      <div className={styles.filterSections}>
        <FilterSection title="Status" count={showingStatuses?.length}>
          <GroupCheckBoxesSection
            title={''}
            entityList={Object.keys(filterStatusMap(userRole)).map((val) => ({
              name: filterStatusMap(userRole)[val],
              value: val,
            }))}
            entityValues={showingStatuses}
            setEntity={setShowingStatuses}
          />
        </FilterSection>
        <FilterSection title="Vendor" count={vendors?.length}>
          <MultiSelectSection
            placeholder={`Search by Name...`}
            entityList={cachedVendorsData?.map((name) => ({
              value: name,
              label: name,
            }))}
            entity={vendors}
            setEntity={setVendors}
            avatarPrefix
          />
        </FilterSection>
        {userRole === AGENT && (
          <FilterSection title="Client" count={clients?.length}>
            <MultiSelectSection
              placeholder={`Search by Name...`}
              entityList={Object.keys(cachedClientsData)?.map((clientKey) => ({
                value: clientKey,
                label: cachedClientsData[clientKey],
              }))}
              entity={clients}
              setEntity={setClients}
              avatarPrefix
            />
          </FilterSection>
        )}
        <FilterSection title="Category" count={categories?.length}>
          <MultiSelectSection
            placeholder={`Search by Name...`}
            entityList={cachedCategoriesData?.map((name) => ({
              value: name,
              label: name,
            }))}
            entity={categories}
            setEntity={setCategories}
            avatarPrefix
          />
        </FilterSection>
        <FilterSection title="Property" count={properties?.length}>
          <MultiSelectSection
            placeholder={`Search by Name...`}
            entityList={cachedPropertiesData?.map((name) => ({
              value: name,
              label: name,
            }))}
            entity={properties}
            setEntity={setProperties}
            avatarPrefix
          />
        </FilterSection>
        <FilterSection title="Requested date range">
          <div className={styles.datePickerContainer}>
            <DateRangePicker
              span={13}
              title={''}
              onDatesChange={(from, to) => {
                resetRequestedDates && setResetRequestedDates(false);
                setRequestedDueFrom(from);
                setRequestedDueTo(to);
              }}
              fromDateValue={requestedDueFrom}
              toDateValue={requestedDueTo}
              dateRangePickerWrapperClass={styles.dateRangePicker}
              resetDates={resetDates}
              futureDisabled={false}
            />
          </div>
        </FilterSection>
        <FilterSection title="Received date range">
          <div className={styles.datePickerContainer}>
            <DateRangePicker
              span={13}
              title={''}
              onDatesChange={(from, to) => {
                resetDates && setResetDates(false);
                setDueFrom(from);
                setDueTo(to);
              }}
              fromDateValue={dueFrom}
              toDateValue={dueTo}
              dateRangePickerWrapperClass={styles.dateRangePicker}
              resetDates={resetDates}
              futureDisabled={false}
            />
          </div>
        </FilterSection>
      </div>
    </FilterDrawer>
  );
};
