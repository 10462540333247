export const softCriteria = {
  OutdoorSpace: 'Outdoor Space',
  ParkingSpaces: 'Parking Spaces',
  View: 'View',
  Parking: 'Parking',
  Rooftop: 'Rooftop',
  NewConstruction: 'New Construction',
  KitchenFeatures: 'Kitchen Features',
  Flooring: 'Flooring - ',
  MoveinReady: 'Move-In Ready',
  HomeOffice: 'Home Office',
  Style: 'Style',
  OpenFloorPlan: 'Open Floor Plan',
  Commutes: 'Commutes',
  Walkability: 'Walkability',
  PublicTransit: 'Public Transit',
  GoodSchools: 'Schools',
  ParksAndNature: 'Parks and Nature',
  RestaurantsBars: 'Restaurants',
  Nightlife: 'Nightlife',
  CoffeeShops: 'Coffee Shops',
  ArtAndEntertainment: 'Arts & Entertainment',
  Fitness: 'Fitness',
  Elevator: 'Elevator',
  Gym: 'Gym',
  PackageReceiving: 'Package Receiving',
  Doorman: 'Doorman',
  DogRun: 'Dog-Run',
  LaundryRoom: 'Laundry Room',
  DryCleaning: 'Dry Cleaning',
  OnSiteManagement: 'On-Site Management',
  LargerThanAverage: 'Larger Than Average',
  EatIn: 'Eat-In',
  Island: 'Island',
  StainlessSteelAppliances: 'Stainless Steel Appliances',
  Hardwood: 'Hardwood',
  Carpet: 'Carpet',
  Tile: 'Tile',
  Stone: 'Stone',
  Other: 'Other',
  Stories: 'Stories',
  KitchenFeaturesLargerThanAverage: 'Kitchen Features - Larger Than Average',
  ZonedTo: 'Zoned to ',
  CommuteTo: 'Commute to ',
};
