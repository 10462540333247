import PropTypes from 'prop-types';

const TimelineCalendar = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_5368_110996)">
      <path
        d="M18 5.00195H6C4.89543 5.00195 4 5.89738 4 7.00195V19.002C4 20.1065 4.89543 21.002 6 21.002H18C19.1046 21.002 20 20.1065 20 19.002V7.00195C20 5.89738 19.1046 5.00195 18 5.00195Z"
        stroke="#747475"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 3V7"
        stroke="#747475"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3V7"
        stroke="#747475"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 11.002H20"
        stroke="#747475"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5368_110996">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

TimelineCalendar.propTypes = {
  className: PropTypes.string,
};

TimelineCalendar.defaultProps = {
  className: '',
};

export default TimelineCalendar;
