import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import classNames from 'classnames';

import { THIRD_PARTY } from 'settings/constants/roles';
import styles from './styles.module.scss';
import { formatNameToAvatarLetters } from 'helpers';
import { Avatar } from 'components';

interface DataType {
  id: number;
  key: React.Key;
  client: string;
  transaction: string;
  status: string;
  tasks: string;
  totalTasks?: number;
  tasksAmount: number;
  closingDate: string;
  data: any;
  overdueTasksCount: number;
  imageUrl: string;
  imagePlaceholder: string;
  address: string;
  count: Number;
  title: string;
  name: string;
  newCount: number;
  drafts: any;
  pending: any;
  pendingCount: number;
  draftCount: number;
  newPendingCount: number;
  receivedCount: number;
  newReceivedCount: number;
  archievedCount: number;
  icon: string;
}

export function tableColumns(userRole: string, headerColumnLabel: string): ColumnsType<DataType> {
  const columns: ColumnsType<DataType> = [
    {
      title: headerColumnLabel,
      key: 'name',
      dataIndex: 'name',
      render: (_, row) => {
        return (
          <div className={styles.headerCol}>
            <Avatar
              avatarClassName={headerColumnLabel === 'Client' ? styles.clientIcon : styles.icon}
              src={row?.icon}
              placeholder={
                <div className={styles.avatarPlaceholder}>
                  {formatNameToAvatarLetters(row?.name)}
                </div>
              }
            />
            <div className={styles.info}>
              <span className={styles.line1}>{row?.name}</span>
            </div>
          </div>
        );
      },
      sorter: (a, b) => a.name?.localeCompare(b.name),
    },
    {
      title: userRole === THIRD_PARTY ? 'New' : 'Drafts',
      key: userRole === THIRD_PARTY ? 'newCount' : 'Drafts',
      dataIndex: userRole === THIRD_PARTY ? 'newCount' : 'Drafts',
      sorter: (a, b) =>
        userRole === THIRD_PARTY ? a.newCount - b.newCount : a.draftCount - b.draftCount,
      render: (_, row) => {
        return (
          <div>
            <span>{userRole === THIRD_PARTY ? row?.newCount : row?.draftCount} </span>
          </div>
        );
      },
    },
    {
      title: 'Pending',
      key: 'pendingCount',
      dataIndex: 'pendingCount',
      sorter: (a, b) => a.pendingCount - b.pendingCount,
      render: (_, row) => {
        return (
          <div>
            <span>
              {userRole === THIRD_PARTY ? row?.pendingCount : row?.pendingCount + row?.newCount}{' '}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Received',
      key: 'receivedCount',
      dataIndex: 'receivedCount',
      sorter: (a, b) => a.receivedCount - b.receivedCount,
      render: (_, row) => {
        return (
          <div>
            <span>{row?.receivedCount} </span>
          </div>
        );
      },
    },

    {
      title: 'Archive',
      key: 'archievedCount',
      dataIndex: 'archievedCount',
      sorter: (a, b) => a.archievedCount - b.archievedCount,
      render: (_, row) => {
        return (
          <div>
            <span>{row?.archievedCount}</span>
          </div>
        );
      },
    },
  ];

  return columns;
}
