import { createSelector } from 'reselect';
import { map, filter, flow, trim, get, omit, cloneDeep, groupBy, uniq } from 'lodash-es';

import { PENDING, READY } from 'settings/constants/apiState';
import { CLIENT } from 'settings/constants/roles';
import { prefsIds, PREF_TYPE, ALL_PREFERENCES } from 'settings/constants/preferences';
import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { getSearchCriteriaFormattedString, sortStrings } from 'helpers';

import { localState as clientsListState } from './clientsList';
import { EDIT } from 'settings/constants/mode';
import { getSearchResultsFilterDrawerModeSelector } from './searchResults';
import { LocationType } from 'types';

const localState = ({ search }) => search;

export const getSearchCriteriaSelector = createSelector(localState, ({ search }) =>
  Object.entries(search).reduce((acc, [key, val]) => {
    if (val) return { ...acc, [key]: val };
    return { ...acc };
  }, {}),
);

export const getEditedSearchSelector = createSelector(localState, ({ edited }) => edited);

export const getBuyersClientsListSelector = createSelector(clientsListState, (clientsList) => ({
  ...clientsList,
  data: clientsList?.data?.filter((client) => client?.Roles.includes(CLIENT)), // T3-1: BUYER replaced with CLIENT
}));

export const getBuyersClientsListFormattedSelector = createSelector(
  clientsListState,
  ({ data, state }) => ({
    isLoading: state === PENDING && !data,
    loaded: state === READY,
    formattedData: flow(
      (list) => filter(list, (client) => client?.Roles.includes(CLIENT)), // T3-1: BUYER replaced with CLIENT
      (list) =>
        map(list, ({ Id, FirstName, LastName, Buyer }) => {
          const criterias = get(Buyer, 'DefaultPropertySearchPreferences');
          return {
            id: Id,
            name: trim(`${FirstName} ${LastName}`),
            info: getSearchCriteriaFormattedString(criterias)?.info,
            criterias,
          };
        }),
    )(data),
  }),
);

export const getSearchImportantFeaturesSelector = createSelector(
  getSearchCriteriaSelector,
  getSearchResultsFilterDrawerModeSelector,
  getEditedSearchSelector,
  (search, mode, editedSearch) => {
    const allPreferences = [];
    const searchPrefs = mode === EDIT ? editedSearch?.data?.criterias : search;
    Object.entries(searchPrefs).forEach(([key, value]) => {
      let prefs = !Array.isArray(value) ? [value] : value;
      if (value && key === prefsIds.parking) {
        prefs[0].Preference = prefsIds.parking;
        prefs = [...prefs];
      }

      if (value) allPreferences.push(...prefs);
    });

    const allPreferenceNames = allPreferences.map((pref) => pref.Preference);

    const noPreferences = cloneDeep(
      ALL_PREFERENCES.filter((pref) => !allPreferenceNames.includes(pref.id)).map(
        (pref) => pref.id,
      ),
    );
    const groupedPrefs = cloneDeep(groupBy(allPreferences, 'ImportanceAndWeight.Importance'));

    const handleItems = (items) =>
      flow(
        (list) => map(list, (pref) => pref?.Preference),
        (list) => filter(list, (item) => item),
        uniq,
      )(items);

    return {
      [SEARCH_CRITERIA_IMPORTANCE.MUST]: handleItems(groupedPrefs?.Must).sort(sortStrings),
      [SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT]: handleItems(groupedPrefs?.Somewhat).sort(sortStrings),
      [SEARCH_CRITERIA_IMPORTANCE.UNDEFINED]: noPreferences
        .filter(
          (prefId) =>
            prefId !== prefsIds.amenities &&
            prefId !== prefsIds.outdoorPrefs &&
            prefId !== prefsIds.layoutPrefs &&
            prefId !== prefsIds.styles && // Added until ImageAI work is complete
            prefId !== prefsIds.proximityToImportantLocations &&
            prefId !== prefsIds.shortCommute &&
            prefId !== prefsIds.commutes &&
            prefId !== prefsIds.fencedYard &&
            prefId !== prefsIds.kitchenFeatures &&
            prefId !== prefsIds.flooring,
        )
        .sort(sortStrings),
    };
  },
);

export const getSearchCommutesSelector = createSelector(
  getSearchCriteriaSelector,
  getSearchResultsFilterDrawerModeSelector,
  getEditedSearchSelector,
  (search, mode, editedSearch) => {
    const searchPrefs = mode === EDIT ? editedSearch?.data?.criterias : search;
    return cloneDeep(get(searchPrefs, PREF_TYPE.commutePrefs)) || [];
  },
);

export const getSearchIsMapDrawingModeSelector = createSelector(
  localState,
  (state) => state.areaDrawing.isEnabled,
);
export const getSearchRawPrefsSelector = createSelector(
  localState,
  (state) => state.rawSearch.rawPrefs || {},
);
export const getSearchDrawnPolygonSelector = createSelector(
  localState,
  (state) =>
    state.search?.Locations?.filter((loc) => loc.Type === LocationType.Polygon)?.Polygon ?? null,
);

export const getSearchDrawnLocationsSelector = createSelector(localState, (state) =>
  state.search?.Locations?.filter((loc) => loc.Type === LocationType.Polygon),
);

export const getSearchLocationsSelector = createSelector(
  localState,
  (state) => state.search?.Locations ?? null,
);

export const getSearchViewportCoordinatesSelector = createSelector(
  localState,
  (state) =>
    state.search?.Locations?.find((loc) => loc.Type === LocationType.ViewportCoordinates)
      ?.Coordinates ?? null,
);
