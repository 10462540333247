import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import Icon from 'components/CommuteForm/Icons';

import { setFeedDrawerCommuteIdEffect } from 'store/effects';
import Actions from './Actions';

import styles from './styles.module.scss';

const Commute = (props) => {
  const { className, index, name, type, commuteTime, address, onDelete } = props;

  const dispatch = useDispatch();

  const onEditHandler = () => {
    dispatch(setFeedDrawerCommuteIdEffect(index));
  };

  const onDeleteHandler = () => {
    onDelete(index);
  };

  return (
    <div testid="commute_item" className={classNames(styles.commute, className)}>
      <Icon className={styles.icon} variant={type} />
      <div className={styles.info}>
        <p testid="name" className={styles.name}>
          {name}
        </p>
        <p className={styles.meta}>
          <span testid="time">{`${commuteTime} min max`}</span>
          <span className={styles.separator} />
          <span testid="address">{address}</span>
        </p>
      </div>
      <Actions onEdit={onEditHandler} onDelete={onDeleteHandler} />
    </div>
  );
};

Commute.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  commuteTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  address: PropTypes.string.isRequired,
  className: PropTypes.string,
  onDelete: PropTypes.func,
};

Commute.defaultProps = {
  className: '',
  onDelete: () => {},
};

export default Commute;
