import classNames from 'classnames';
import { FormOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import { Modal } from 'components-antd';
import { useEffect, useState } from 'react';
import { Button } from 'components';
import {
  requestGetListingFilterEffect,
  resetSearchCriteriaEffect,
  setSearchCriteriaEffect,
} from 'store/effects';
import { useDispatch, useSelector } from 'react-redux';
import { getListingFiltersSelector } from 'store/selectors/ai';

const Gpt = ({ setUpdateInput }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');
  const { isPending, filters } = useSelector(getListingFiltersSelector);

  useEffect(() => {
    if (filters && !isPending) {
      dispatch(resetSearchCriteriaEffect());
      if (filters?.Location) setUpdateInput(filters?.Location);
      dispatch(setSearchCriteriaEffect(filters));
      setOpen(false);
    }
  }, [filters]);

  const submitHandler = async () => {
    if (input.length) {
      dispatch(requestGetListingFilterEffect({ requirements: input }, {}, (err) => {}));
    }
  };

  return (
    <>
      <Modal
        open={open}
        width={700}
        footer={null}
        onCancel={() => {
          setOpen(false);
        }}
        className={styles.inviteModal}
        cancelButtonStyles={styles.crossButton}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className={styles.container}>
          <p className={styles.title}>Generative Search</p>
          <textarea
            id="w3review"
            name="w3review"
            rows={4}
            cols={50}
            onChange={(e) => {
              setInput(e.target.value);
            }}
          />
          <div className={styles.buttonContainer}>
            <Button
              title={'Submit'}
              className={styles.button}
              onClick={submitHandler}
              isPending={isPending}
            />
          </div>
        </div>
      </Modal>
      <button
        className={classNames(styles.gptSubmit)}
        type="button"
        onClick={() => {
          setOpen(true);
        }}
      >
        <FormOutlined className={styles.gptIcon} />
      </button>
    </>
  );
};

export default Gpt;
