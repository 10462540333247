import { routes } from 'settings/navigation/routes';
import { ClientKitCta } from 'pages';

export default [
  {
    path: routes.kitCta,
    component: ClientKitCta,
    exact: true,
  },
];
