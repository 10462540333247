import { tasksStatusesIds } from 'settings/constants/transactionTasks';
import { isToday, isTomorrow, subtractTimeZone } from 'helpers';

export const getTransactionStatusCount = (tasks) => {
  const { overdue, new: newTask } = tasksStatusesIds;
  let newTasks = 0;
  let overdueTasks = 0;
  let upcomingTasks = 0;
  let tasksDueToday = 0;

  (tasks || []).forEach((task) => {
    if (task?.Status === newTask) {
      newTasks++;
    }
    if (task?.Status === overdue) {
      overdueTasks++;
    }
    if (isTomorrow(task?.DueDate) && !task?.CompletedDate) {
      upcomingTasks++;
    }
    if (isToday(subtractTimeZone(task?.DueDate, 'M/DD/YYYY'))) {
      tasksDueToday++;
    }
  });
  return {
    newTasksCount: newTasks,
    overdueTasksCount: overdueTasks,
    upcomingTasksCount: upcomingTasks,
    tasksDueTodayCount: tasksDueToday,
    totalTasks: tasks?.length || 0,
  };
};
