import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import Actions from './Actions';
import styles from './styles.module.scss';
import PdfViewModal from 'components/PdfViewModal';
import PhotosViewModal from 'components/Messages/MessagesView/PhotosViewModal';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { FILE_TYPES } from 'settings/constants/common';
import { previewDocumentEffect } from 'store/effects/transactions';

const Attachment = (props) => {
  const dispatch = useDispatch();
  const { file, className, truncateLength, isActions } = props;
  const { Filename, SendDate, SendTime, FirstName, LastName } = file || {};

  const [isPdfPreviewOpen, setIsPdfPreviewOpen] = useState(false);
  const [previewImages, setPreviewImages] = useState(false);

  const getDate = () => {
    const name = `${FirstName} ${LastName}`;
    return `${name} on ${SendDate} at ${SendTime}`;
  };

  const truncateName = (name) => {
    const truncatedFileName = name.split('').slice(-truncateLength).join('');
    if (name?.length > truncateLength) {
      return `...${truncatedFileName}`;
    }
    return truncatedFileName;
  };

  const getFilename = () => {
    if (truncateLength) {
      if (typeof Filename === 'string') {
        return truncateName(Filename);
      }
      return '';
    }

    return Filename;
  };

  const onDownload = () => {
    const filename = file?.Filename;

    if (file?.Url) {
      axios.get(file?.Url, { responseType: 'blob' }).then((response) => {
        fileDownload(response.data, filename);
      });
    }

    if (file?.DocumentVaultUUID) {
      dispatch(
        previewDocumentEffect({ DocumentVaultUUID: file.DocumentVaultUUID }, {}, (err, resp) => {
          if (!err) fileDownload(resp.data, resp.headers['file-name']);
        }),
      );
    }
  };

  const getAttachmentType = useCallback((attachment) => {
    const [type] = attachment?.ContentType?.split('/') || [];
    return type;
  }, []);

  const isPDFAttachment = () => {
    return file?.ContentType === FILE_TYPES.PDF;
  };

  const isImageAttachment = () => {
    return getAttachmentType(file) === 'image';
  };

  const onCardClick = () => {
    if (isPDFAttachment()) {
      setIsPdfPreviewOpen(true);
    } else if (isImageAttachment()) {
      setPreviewImages(true);
    } else {
      onDownload();
    }
  };

  return (
    <div testid="document_item" className={classNames(styles.attachment, className)}>
      <Icon className={styles.icon} variant={Icon.FILE} />
      <div className={styles.info} onClick={onCardClick}>
        <div testid="file_name" className={styles.filename}>
          {getFilename()}
        </div>
        <div testid="file_date" className={styles.date}>
          {getDate()}
        </div>
      </div>
      {isActions && (
        <Actions
          file={file}
          onDownloadClick={onDownload}
          onImagePreviewClick={setPreviewImages}
          onPdfPreviewClick={setIsPdfPreviewOpen}
        />
      )}
      {isPdfPreviewOpen && (
        <PdfViewModal
          isOpen={isPdfPreviewOpen}
          file={file}
          onClose={() => setIsPdfPreviewOpen(false)}
        />
      )}
      {previewImages && (
        <PhotosViewModal
          isOpen={previewImages}
          onClose={() => setPreviewImages(false)}
          photos={[file]}
          imageId={0}
        />
      )}
    </div>
  );
};

Attachment.propTypes = {
  className: PropTypes.string,
  file: PropTypes.shape({
    ContentType: PropTypes.string,
    Url: PropTypes.string,
    Filename: PropTypes.string,
    Size: PropTypes.number,
    SendDate: PropTypes.string,
    SendTime: PropTypes.string,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
  }).isRequired,
  truncateLength: PropTypes.number,
  isActions: PropTypes.bool,
};

Attachment.defaultProps = {
  className: '',
  truncateLength: undefined,
  isActions: true,
};

export default Attachment;
