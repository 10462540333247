import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  getIsMessagesDrawerOpenSelector,
  getIsMessagesDrawerTypeSelector,
} from 'store/selectors/drawers/messages';
import {
  openMessagesDrawerAction,
  changeMessagesDrawerTypeAction,
} from 'store/actions/drawers/messages';
import { relatedEntitiesGetDataEffect } from 'store/effects/relatedEntities';

import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { LocationService } from 'services';
import { Drawer } from 'components';
import { getSocketConnectionState } from 'store/selectors/sockets/status';
import { SOCKET_STATE } from 'settings/constants/sockets';
import { link } from 'settings/navigation/link';
import { getIsCommentsDrawerOpenSelector } from 'store/selectors/drawers/comments';
import MessagesDrawerHeader from './components/Header';
import MessagesDrawerContent from './components/Content';
import NewMessageHeader from './components/NewMessageHeader';
import SocketConnectionWrapper from '../components/SocketConnectionWrapper';
import { requestGetQuotesAuditLogsEffect } from 'store/effects/quotes';

const locationSrv = new LocationService();

const MessagesDrawer = () => {
  const isOpen = useSelector(getIsMessagesDrawerOpenSelector);
  const isCommentsDrawerOpen = useSelector(getIsCommentsDrawerOpenSelector);
  const drawerType = useSelector(getIsMessagesDrawerTypeSelector);
  const socketStatus = useSelector(getSocketConnectionState);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  locationSrv.setLocation(location);

  useEffect(() => {
    if (isOpen) {
      const query = locationSrv.getQuery();
      history.replace(locationSrv.setQuery({ ...query, threadId: undefined, openChat: undefined }));

      dispatch(relatedEntitiesGetDataEffect());
      dispatch(requestGetQuotesAuditLogsEffect());
    }
  }, [isOpen]); // eslint-disable-line

  const openDrawer = (threadId) => {
    dispatch(openMessagesDrawerAction(true));
    dispatch(
      changeMessagesDrawerTypeAction({
        type: DRAWER_MESSAGES_TYPES.CHAT,
        params: { threadId },
      }),
    );
  };

  useEffect(() => {
    const {
      threadId: queryThreadId,
      tourRequestedBy,
      openChat: shouldOpenChatDrawer,
    } = locationSrv.getQuery();
    const paramPropId = location.pathname.split('/').pop();
    const isListingDetails = link.toFeedListingDetails(paramPropId) === location.pathname;

    if (
      queryThreadId &&
      socketStatus === SOCKET_STATE.CONNECTED &&
      !tourRequestedBy &&
      !isListingDetails
    ) {
      return openDrawer(queryThreadId);
    }

    if (shouldOpenChatDrawer && socketStatus === SOCKET_STATE.CONNECTED) {
      dispatch(openMessagesDrawerAction(true));
    }

    if (
      queryThreadId &&
      socketStatus === SOCKET_STATE.CONNECTED &&
      isListingDetails &&
      !isCommentsDrawerOpen &&
      queryThreadId
    ) {
      openDrawer(queryThreadId);
    }

    if (isListingDetails && isCommentsDrawerOpen) {
      dispatch(openMessagesDrawerAction(false));
    }
  }, [location, socketStatus, isCommentsDrawerOpen]); // eslint-disable-line

  const handleDrawerClose = useCallback(() => {
    dispatch(openMessagesDrawerAction(false));
  }, [dispatch]);
  const handleNewMessage = useCallback(
    () =>
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
          params: { participants: [] },
        }),
      ),
    [dispatch],
  );
  const handleArchive = useCallback(
    () => dispatch(changeMessagesDrawerTypeAction({ type: DRAWER_MESSAGES_TYPES.ARCHIVED })),
    [dispatch],
  );
  const handleNewMessageClose = useCallback(
    () => dispatch(changeMessagesDrawerTypeAction({ type: DRAWER_MESSAGES_TYPES.INIT })),
    [dispatch],
  );

  const getHeader = () => {
    if (drawerType === DRAWER_MESSAGES_TYPES.NEW_MESSAGE) {
      return <NewMessageHeader onClose={handleNewMessageClose} />;
    }
    return (
      <MessagesDrawerHeader
        isOpen={isOpen}
        onClose={handleDrawerClose}
        onArchive={handleArchive}
        onNewMessage={handleNewMessage}
      />
    );
  };

  return (
    <Drawer
      header={getHeader()}
      isOpen={isOpen}
      onClose={handleDrawerClose}
      testid="messages_drawer"
    >
      <SocketConnectionWrapper>
        <MessagesDrawerContent />
      </SocketConnectionWrapper>
    </Drawer>
  );
};

export default MessagesDrawer;
