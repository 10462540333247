import { get, flow, map, filter } from 'lodash-es';

import Api from 'store/effects/core/api';
import {
  updateTransactionAction,
  updateTransactionStatusAction,
  addClientAddressAction,
  removeClientAddressAction,
  inviteTransactionParticipantAction,
  removeTransactionParticipantAction,
  updateTransactionMilestonesAction,
  updateMilestoneStatusAction,
  updateTransactionParticipantAccessAction,
  updateTransactionParticipantsAction,
  updateTransactionUnderContractAction,
  updateTransactionDetailAction,
  updateTransactionContactAction,
  deleteTransactionContactAction,
  updateCloseDatePriceAction,
  updateUnReadNotesForTransactionAction,
  moveToActiveListingAction,
  resendParticipantInviteAction,
  updateKeyDatesAction,
} from 'store/actions/transactions';
import {
  updateTransaction,
  updateKeyDates,
  updateTransactionStatus,
  addClientAddress,
  removeClientAddress,
  inviteTransactionParticipant,
  removeTransactionParticipant,
  updateTransactionMilestones,
  updateMilestoneStatus,
  updateTransactionParticipantAccess,
  updateTransactionParticipants,
  updateTransactionUnderContract,
  updateTransactionDetail,
  updateTransactionContact,
  deleteTransactionContact,
  updateCloseDatePrice,
  moveToActiveListing,
  resendParticipantInvite,
} from 'api/transactions';
import { getState } from 'store';
import moment from 'moment';
import {
  convertDocuments,
  convertMilestones,
  convertPurchaseDocuments,
  convertTransactionEarnestMoney,
  filterAssignToRolesParticipants,
  getAdditionalRolesForTasks,
} from '../create';

export const updateTransactionEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: updateTransactionAction,
    method: updateTransaction,
  });

  let config = {};
  //send update for cancellation flow
  if (cfg?.ReasonForCancellation || cfg?.AdditionalCancellationComments) {
    config = {
      id: cfg?.id,
      ReasonForCancellation: cfg?.ReasonForCancellation,
      AdditionalCancellationComments: cfg?.AdditionalCancellationComments,
    };
  } else {
    config = {
      ...(cfg?.id ? { id: cfg.id } : undefined),
      ...(cfg?.address
        ? {
            Property: {
              Address: cfg.address,
            },
          }
        : undefined),
      ...(cfg?.FinancingType ? { FinancingType: cfg.FinancingType } : undefined),
      ...(cfg?.LeadSource ? { LeadSource: cfg.LeadSource } : undefined),
      ...(cfg?.BuyerCommission ? { BuyerCommission: cfg?.BuyerCommission } : {}),
      ...(cfg?.SellerCommission ? { SellerCommission: cfg?.SellerCommission } : {}),
      ...(cfg?.OtherFees ? { OtherFees: cfg?.OtherFees } : {}),
      ...(cfg?.TotalCommission ? { TotalCommission: cfg?.TotalCommission } : {}),
      ...(cfg?.ReferralFee ? { ReferralFee: Number(cfg?.ReferralFee) } : {}),
      ...(cfg?.PropertyType ? { PropertyType: cfg.PropertyType } : undefined),
      ...(cfg?.YearBuilt ? { YearBuilt: Number(cfg.YearBuilt) } : undefined),
      ...(cfg?.price ? { Price: Number(cfg.price) } : undefined),
      ...(cfg?.closePrice ? { ClosePrice: Number(cfg.closePrice) } : undefined),
      ...(cfg?.canceledDate
        ? { CanceledDate: moment(cfg.canceledDate).format('YYYY-MM-DD') }
        : undefined),
      ...(cfg?.effectiveDate
        ? { EffectiveDate: moment(cfg.effectiveDate).format('YYYY-MM-DD') }
        : undefined),
      ...(cfg?.closingDate
        ? { ClosingDate: moment(cfg.closingDate).format('YYYY-MM-DD') }
        : undefined),
      ...(cfg?.closeDate ? { CloseDate: moment(cfg.closeDate).format('YYYY-MM-DD') } : undefined),
      ...(cfg?.listingExpireDate
        ? {
            ListingExpireDate: moment(cfg.listingExpireDate).format('YYYY-MM-DD'),
          }
        : undefined),
      ...(cfg?.status?.value ? { Status: cfg.status.value } : undefined),
      ...(cfg?.transactionClients
        ? { TransactionClients: cfg.transactionClients.filter((client) => client) }
        : undefined),
      ...(cfg?.agentsOrOwners
        ? { AgentsOrOwners: cfg.agentsOrOwners.filter((client) => client) }
        : undefined),
      ...(cfg?.name ? { Name: cfg.name } : undefined),
      ...(cfg?.category?.value ? { TransactionCategoryId: cfg.category?.value } : undefined),
    };
  }

  return sendRequest(config, options, cb);
};

export const updateKeyDatesEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: updateKeyDatesAction,
    method: updateKeyDates,
  });

  return sendRequest(cfg, options, cb);
};

export const updateTransactionStatusEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: updateTransactionStatusAction,
    method: updateTransactionStatus,
  });

  const config = {
    id: cfg?.id,
    Status: cfg?.status,
  };

  return sendRequest(config, options, cb);
};

export const moveToActiveListingEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: moveToActiveListingAction,
    method: moveToActiveListing,
  });

  const config = {
    id: cfg?.id,
    MoveToActiveListing: cfg?.moveToActiveListing,
  };

  return sendRequest(config, options, cb);
};

export const updateTransactionUnderContractEffect = (cfg, options, cb) => {
  const state = getState();
  const sendRequest = Api.execBase({
    action: updateTransactionUnderContractAction,
    method: updateTransactionUnderContract,
  });

  const transactionData = get(state, 'transactionCreate.data', {});
  const transactionParticipants = get(state, 'transaction.data.Participants', []);
  const existingParticipantEmails = transactionParticipants.map((p) => p.Email);

  const getAllParticipants = () => transactionData?.participants || [];

  const convertParticipants = () => {
    const combinedParticipants = [
      ...(transactionData?.participants || []),
      ...(transactionData?.whoIsRepresentingTheBuyer || []),
      ...(filterAssignToRolesParticipants(transactionData, getAllParticipants()) || []),
    ];

    const combinedParticipantsFiltered = combinedParticipants.filter((participant) => {
      const email = (participant?.customEmail || participant?.email)?.toLowerCase();
      return !existingParticipantEmails.includes(email);
    });

    const participants = combinedParticipantsFiltered
      .filter((participant) => participant.name || participant.lastName)
      .map((participant) => ({
        Phones: participant?.Phones,
        Email: (participant?.customEmail || participant?.email)?.toLowerCase(),
        Role: participant?.tagRole || undefined,
        RoleId: participant?.roleId,
        FirstName: participant?.firstName,
        LastName: participant?.lastName,
        Name: participant?.name,
        Invite: participant?.invite,
        TransactionAccess: participant?.transactionAccess,
      }));
    return participants;
  };

  let closingDate;

  if (transactionData?.closingDate) {
    closingDate = moment(transactionData?.closingDate).format('YYYY-MM-DD');
  } else if (transactionData?.listingExpireDate) {
    closingDate = moment(transactionData?.listingExpireDate).format('YYYY-MM-DD');
  }

  const config = {
    Id: cfg.Id,
    Status: cfg.Status,
    Price: transactionData?.purchasePrice || transactionData?.listingPrice,
    ClosingDate: closingDate,
    ...(transactionData?.effectiveDate && {
      EffectiveDate: moment(transactionData?.effectiveDate).format('YYYY-MM-DD'),
    }),

    Participants: convertParticipants(),
    ...(transactionData?.assignParticipantsToRoles
      ? { AdditionalRolesForTasks: getAdditionalRolesForTasks(transactionData) }
      : {}),
    FinancingType: transactionData?.financingType,
    TransactionEarnestMoney: convertTransactionEarnestMoney(transactionData),
    ...(transactionData?.referralFee ? { ReferralFee: transactionData?.referralFee } : {}),
    ...(transactionData?.otherFees ? { OtherFees: transactionData?.otherFees } : {}),
    ...(transactionData?.buyerCommission?.Value > 0
      ? { BuyerCommission: transactionData?.buyerCommission }
      : {}),
    ...(transactionData?.sellerCommission?.Value > 0
      ? { SellerCommission: transactionData?.sellerCommission }
      : {}),
    ...(transactionData?.buyerCommission?.Value > 0 &&
    transactionData?.buyerCommissionContribution?.Value > 0
      ? { BuyerCommissionContribution: transactionData?.buyerCommissionContribution }
      : {}),
    ...(transactionData?.buyerCommission?.Value > 0 &&
    transactionData?.sellerCommissionContribution?.Value > 0
      ? { SellerCommissionContribution: transactionData?.sellerCommissionContribution }
      : {}),
    ...(transactionData?.buyerCommission?.Value > 0
      ? {
          BuySideCommissionResponsibility:
            transactionData?.buySideCommissionResponsibility ?? 'Buyer',
        }
      : {}),
    Milestones: convertMilestones(transactionData),
    Documents: [...convertDocuments(transactionData), ...convertPurchaseDocuments(transactionData)],
    DateControls: transactionData?.dateControls,
    templateId: transactionData?.templateId,
    LeadSource: transactionData?.leadSource ?? '',
    WhoIsRepresentingTheBuyer: transactionData?.whoIsRepresentingTheBuyer
      ?.filter((item) => item.invite)
      ?.map((item) => item.customEmail || item.email),
  };

  return sendRequest(config, options, cb);
};

export const updateTransactionDetailEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: updateTransactionDetailAction,
    method: updateTransactionDetail,
  });
  return sendRequest(cfg, {}, cb);
};

export const addClientAddressEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: addClientAddressAction,
    method: addClientAddress,
  });
  return sendRequest(cfg, {}, cb);
};

export const removeClientAddressEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: removeClientAddressAction,
    method: removeClientAddress,
  });
  return sendRequest(cfg, {}, cb);
};

export const inviteTransactionParticipantEffect = (cfg, options, cb) => {
  const requestParams = {
    action: inviteTransactionParticipantAction,
    method: inviteTransactionParticipant,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  const config = {
    transactionId: cfg?.transactionId,
    Email: (cfg?.customEmail || cfg?.email)?.toLowerCase(),
    Role: !cfg?.roleId ? cfg?.tagRole : undefined,
    RoleId: cfg?.roleId ? cfg?.roleId : undefined,
    FirstName: cfg?.firstName || undefined,
    LastName: cfg?.lastName || undefined,
    TransactionAccess: cfg?.transactionAccess || undefined,
  };

  return sendRequest(config, options, cb);
};

export const removeTransactionParticipantEffect = (cfg, options, cb) => {
  const requestParams = {
    action: removeTransactionParticipantAction,
    method: removeTransactionParticipant,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  const config = {
    transactionId: cfg?.transactionId,
    participantId: cfg?.participantId,
  };

  return sendRequest(config, options, cb);
};

export const resendParticipantInviteEffect = (cfg, options, cb) => {
  const requestParams = {
    action: resendParticipantInviteAction,
    method: resendParticipantInvite,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const updateTransactionMilestonesEffect = (cfg, options, cb) => {
  const state = getState();
  const { transaction } = state;
  const transactionData = get(transaction, 'data', {});
  const requestParams = {
    action: updateTransactionMilestonesAction,
    method: updateTransactionMilestones,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  const config = {
    transactionId: transactionData?.Id,
    Milestones: cfg?.milestones.map((milestone) => ({
      Id: milestone?.Id,
      Title: milestone?.Title,
      DueDate: moment(milestone?.DueDate).format('YYYY-MM-DD'),
      IsPrivate: !!milestone?.IsPrivate,
      ControlOperatorOffset: milestone?.ControlOperatorOffset,
    })),
  };

  return sendRequest(config, options, cb);
};

export const updateMilestoneStatusEffect = (cfg, options, cb) => {
  const state = getState();
  const { transaction } = state;
  const transactionData = get(transaction, 'data', {});
  const requestParams = { action: updateMilestoneStatusAction, method: updateMilestoneStatus };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  const config = {
    transactionId: transactionData?.Id,
    milestoneId: cfg?.milestoneId,
    Status: cfg?.status,
  };

  return sendRequest(config, options, cb);
};

export const updateMilestoneStatusEffectAggregated = (cfg, options, cb) => {
  const requestParams = { action: updateMilestoneStatusAction, method: updateMilestoneStatus };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  const config = {
    transactionId: cfg?.transactionId,
    milestoneId: cfg?.milestoneId,
    Status: cfg?.status,
  };

  return sendRequest(config, options, cb);
};

export const updateTransactionParticipantAccessEffect = (cfg, options, cb) => {
  const { transaction } = getState();
  const transactionData = get(transaction, 'data', {});

  const sendRequest = Api.execResult({
    action: updateTransactionParticipantAccessAction,
    method: updateTransactionParticipantAccess,
  });

  const config = {
    transactionId: transactionData?.Id,
    ...cfg,
  };

  return sendRequest(config, options, cb);
};

export const updateTransactionParticipantsEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: updateTransactionParticipantsAction,
    method: updateTransactionParticipants,
  });
  return sendRequest(cfg, {}, cb);
};

export const updateTransactionContactEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: updateTransactionContactAction,
    method: updateTransactionContact,
  });
  return sendRequest(cfg, {}, cb);
};

export const deleteTransactionContactEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: deleteTransactionContactAction,
    method: deleteTransactionContact,
  });
  return sendRequest(cfg, {}, cb);
};

export const updateCloseDatePriceEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: updateCloseDatePriceAction,
    method: updateCloseDatePrice,
  });
  return sendRequest(cfg, {}, cb);
};

export const updateUnReadNotesForTransactionEffect = (cfg) => (dispatch) => {
  dispatch(updateUnReadNotesForTransactionAction(cfg));
};
