import { useEffect } from 'react';
import moment, { Moment } from 'moment';

import { DatePicker, RangePicker } from 'components-antd';
import { FormMode, ResponseType } from 'types';
import { FORM_MODE } from 'app-constants';

import styles from './styles.module.scss';

interface DateQuestionProps {
  mode: FormMode;
  rangePicker: boolean;
  formResponse?: ResponseType;
  handleFormResponse?: ({ Answer }: Partial<ResponseType>) => void;
}

export const DateQuestion = ({
  mode,
  rangePicker,
  formResponse,
  handleFormResponse,
}: DateQuestionProps) => {
  const editMode = mode === FORM_MODE.Edit;

  const getUTCTime = (date: Moment) => date.format('MM/DD/YYYY');

  const handleDatePickerUserInput = (date: Moment) => {
    if (formResponse?.UUID && handleFormResponse) {
      handleFormResponse({
        Answer: getUTCTime(date),
      });
    }
  };

  const handleRangePickerUserInput = (date: Moment[]) => {
    if (formResponse?.UUID && handleFormResponse) {
      handleFormResponse({
        Answer: JSON.stringify([getUTCTime(date[0]), getUTCTime(date[1])]),
      });
    }
  };

  const setRangePickerValue = () => {
    if (formResponse?.Answer) {
      const rangeDate: string[] = JSON.parse(formResponse?.Answer);
      return [moment(rangeDate[0], 'MM/DD/YYYY'), moment(rangeDate[1], 'MM/DD/YYYY')] as any;
    }
  };

  useEffect(() => {
    if (handleFormResponse && formResponse?.Answer && !rangePicker) {
      handleFormResponse({
        Answer: moment(formResponse.Answer).format('MM/DD/YYYY'),
      });
    }
  }, [formResponse?.UUID]);

  return (
    <div className={styles.dateInput}>
      {rangePicker ? (
        <RangePicker
          disabled={editMode}
          value={formResponse?.Answer ? setRangePickerValue() : undefined}
          onChange={(date) => date && handleRangePickerUserInput(date)}
          className={styles.picker}
        />
      ) : (
        <DatePicker
          disabled={editMode}
          value={formResponse?.Answer ? moment(formResponse?.Answer) : undefined}
          onChange={(date) => date && handleDatePickerUserInput(date)}
          className={styles.picker}
        />
      )}
    </div>
  );
};
