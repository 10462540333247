import { Button } from 'antd';
import PropTypes from 'prop-types';

import { AppointmentStatus, ApprovalAction } from 'types/showingAppointment';
import { Icons } from 'pages/Workshop/Icons';
import { FormattedPhone } from 'components';
import { Feedback } from '../Feedback';
import { PerformedBy } from '../PerformedBy';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { ShowingDetails } from '../ShowingDetails';
import { useEffect, useState } from 'react';

export const Details = ({
  data,
  getIsAprovalRequired,
  processAction,
  pendingApprovers,
  isFeedback,
}) => {
  const { BuyingAgentName, BuyingAgentBrokerage, BuyingAgentPhone, BuyingAgentEmail } = data;
  const status = data?.AppointmentStatus;
  const feedback = data?.BuyerFeedback;
  const [isApprovalRequired, setIsApprovalRequired] = useState();

  useEffect(() => {
    if (data) {
      setIsApprovalRequired(getIsAprovalRequired(data));
    }
  }, [data]);

  const isDeclined = () => {
    return status === AppointmentStatus.Canceled || status === AppointmentStatus.Declined;
  };

  const renderApprovers = () => (
    <div className={styles.pending}>
      {isApprovalRequired && pendingApprovers.length && pendingApprovers[0] === 'You' && (
        <div className={styles.actions}>
          <Button
            type="text"
            className={classNames(styles.actionButton, styles.approve)}
            onClick={() => processAction(ApprovalAction.Approve)}
          >
            <Icons variant={Icons.GREEN_LIGHT_CHECKMARK} className={styles.icon} />
            Approve
          </Button>
          <Button
            type="text"
            className={classNames(styles.actionButton, styles.decline)}
            onClick={() => processAction(ApprovalAction.Decline)}
          >
            <Icons variant={Icons.CLOSE} className={styles.icon} />
            Decline
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.details}>
      {status === AppointmentStatus.Pending ? renderApprovers() : null}
      <div className={styles.appointmentInfo}>
        <ShowingDetails
          showAvatar
          label="Requestor:"
          title={BuyingAgentName && BuyingAgentName}
          description={BuyingAgentBrokerage && BuyingAgentBrokerage}
          FirstName={BuyingAgentName}
        />
        <div className={styles.requestor}>
          {BuyingAgentPhone && (
            <FormattedPhone className={styles.phone}>{BuyingAgentPhone}</FormattedPhone>
          )}
          {BuyingAgentEmail ? <span>{BuyingAgentEmail}</span> : null}
        </div>
      </div>
      {(isFeedback || status === AppointmentStatus.Past) && feedback?.length ? (
        <Feedback uuid={data?.UUID} feedback={feedback} isFeedback={isFeedback} />
      ) : null}
      {isDeclined() && <PerformedBy data={data} />}
    </div>
  );
};

Details.propTypes = {
  data: PropTypes.object,
  isApprovalRequired: PropTypes.func,
  processAction: PropTypes.func,
  pendingApprovers: PropTypes.arrayOf(PropTypes.string),
  isFeedback: PropTypes.bool,
};

Details.defaultProps = {
  data: {},
  isApprovalRequired: () => {},
  processAction: () => {},
  pendingApprovers: [],
  isFeedback: false,
};
