export const SEARCH_CRITERIA_IMPORTANCE = {
  UNDEFINED: 'Undefined',
  NOT: 'Not',
  SOMEWHAT: 'Somewhat',
  VERY: 'Very',
  MUST: 'Must',
};

export const SEARCH_CRITERIA_ACTIONS = {
  EDIT: 'Edit',
  DELETE: 'Delete',
};
