import { useDispatch, useSelector } from 'react-redux';
import { getActiveClientSearchInstance, getClientDrawerSendingInvite } from 'store/selectors/app';

import { Tabs } from 'components-antd';
import { InviteHeader } from '../../../Headers';
import Footer from '../../../Footer';
import { PropertyAttributes, LocationAttributes } from './components';
import { inviteClientSteps } from 'types/inviteClient';

import styles from './styles.module.scss';
import { useState } from 'react';
import { appSetActiveClientSearchInstanceAction } from 'store/actions/app';
import { PREF_TYPE, prefsIds } from 'settings/constants/preferences';

type InviteClientWishListType = {
  onNext: Function;
  stageIndex: number;
  onPrev: () => void;
};

const InviteClientWishList = ({ onNext, stageIndex, onPrev }: InviteClientWishListType) => {
  const { state } = useSelector(getClientDrawerSendingInvite);
  const dispatch = useDispatch();
  const isPending = state === 'pending';
  const activeClientSearchInstance = useSelector(getActiveClientSearchInstance);
  const [softCriteria, setSoftCriteria] = useState<any>(
    activeClientSearchInstance?.softCriteria || {},
  );

  const addSoftCriteria = (type, id, value, data, checkboxRemove, allAddtionalOptionsModify) => {
    let criteria: any = { type, value };
    if (data) criteria = { ...criteria, data };
    const oldCriteria = { ...softCriteria };
    if (allAddtionalOptionsModify?.length) {
      // Update all additional options at once
      let updatedsoftCriteria = {};
      allAddtionalOptionsModify?.forEach((item) => {
        updatedsoftCriteria = {
          ...updatedsoftCriteria,
          ...(id === prefsIds.waterFront ? { [id]: { type, value } } : {}),
          [item]: { type, value },
        };
      });
      setSoftCriteria({ ...oldCriteria, ...updatedsoftCriteria });
      return;
    }

    if (checkboxRemove?.length) {
      checkboxRemove.map((id) => delete oldCriteria[id]);
      setSoftCriteria(oldCriteria);
    }

    if (
      type === prefsIds.commutes ||
      type === prefsIds.proximityToImportantLocations ||
      type === prefsIds.school ||
      type === PREF_TYPE.keywordPrefs
    ) {
      setSoftCriteria({
        ...oldCriteria,
        [type]: data,
      });
    } else if (id === prefsIds.parking) {
      let payload = {};
      if (value != 'N/A') {
        payload = {
          value: value,
          data: { ...oldCriteria?.[id]?.data, ...data },
          type: type,
        };
      } else {
        delete oldCriteria[prefsIds.rvParking];
      }
      setSoftCriteria({
        ...oldCriteria,
        [id]: payload,
      });
    } else if (criteria?.data) {
      const { data, ...restCriteria } = criteria;
      data.map(({ id }) => {
        setSoftCriteria({
          ...oldCriteria,
          [id]: restCriteria,
        });
      });
    } else if (
      id &&
      id !== prefsIds.kitchenCabinetColor &&
      id !== prefsIds.heaterSource &&
      id !== prefsIds.waterSource &&
      id !== prefsIds.waste
    ) {
      setSoftCriteria({
        ...oldCriteria,
        [id]: criteria,
      });
    }
  };

  const tabItems = [
    {
      key: '1',
      label: 'Property Attributes',
      children: (
        <PropertyAttributes
          softCriteria={softCriteria}
          addSoftCriteria={addSoftCriteria}
          className={styles.propertyAttributes}
          showCount
          scrollToTop
        />
      ),
    },
    {
      key: '2',
      label: 'Location Attributes',
      children: (
        <LocationAttributes
          softCriteria={softCriteria}
          addSoftCriteria={addSoftCriteria}
          className={styles.locationAttributes}
        />
      ),
    },
  ];

  return (
    <div>
      <InviteHeader
        disabled={isPending}
        stageIndex={stageIndex}
        onPrev={() => {
          dispatch(
            appSetActiveClientSearchInstanceAction({
              ...activeClientSearchInstance,
              softCriteria,
            }),
          );
          onPrev();
        }}
        // isValid={formik.isValid}
      />
      <div className={styles.wishListContainer}>
        <span className={styles.panelHeader}>Wish List</span>
        <div className={styles.tabsContainer}>
          <Tabs defaultActiveKey="1" items={tabItems} />
        </div>
      </div>
      <Footer
        buttonTitle="Continue"
        isValid={true}
        className={styles.footer}
        testid="invite_buyer_footer"
        onClick={() => {
          onNext(inviteClientSteps.ClientSearchInstanceSummary);
          dispatch(
            appSetActiveClientSearchInstanceAction({
              ...activeClientSearchInstance,
              softCriteria,
            }),
          );
        }}
      />
    </div>
  );
};

export default InviteClientWishList;
