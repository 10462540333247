import { combineReducers } from 'redux';

import serviceDirectory from './serviceDirectory';
import * as templates from './templates';
import * as transaction from './transaction';
import * as users from './users';
import * as notificationTemplates from './notificationTemplates';
import * as mlsAgents from './mlsAgents';
import * as teams from './teams';
import * as teamDetails from './teamDetails';
import areaClassifier from './areaClassifier';

export default combineReducers({
  serviceDirectory,
  areaClassifier,
  ...templates,
  ...transaction,
  ...users,
  ...notificationTemplates,
  ...mlsAgents,
  ...teams,
  ...teamDetails,
});
