import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SectionNameItem from './SectionNameItem';
import ListItem from './ListItem';

const ClientsList = ({ groupedList, className }) => {
  const letters = useMemo(() => Object.keys(groupedList).sort(), [groupedList]);

  return (
    <div testid="clients_list" className={classNames(className)}>
      {letters.map((letter) => (
        <div key={letter}>
          <SectionNameItem letter={letter} />
          {groupedList[letter].map(({ Id, FirstName, LastName, Avatar, Favorites }) => (
            <ListItem
              key={Id}
              id={Id}
              name={`${FirstName} ${LastName}`}
              avatar={Avatar}
              favorites={Favorites}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

ClientsList.propTypes = {
  className: PropTypes.string,
  groupedList: PropTypes.shape({}).isRequired,
};

ClientsList.defaultProps = {
  className: '',
};

export default ClientsList;
