import React from 'react';
import { Modal } from 'components-antd';
import { Button } from 'components';
import { Delete } from 'components/Icons';
import styles from './styles.module.scss';
import classNames from 'classnames';
export const DeleteDocument = ({
  isExpandedOpened,
  onCancel,
  deleteDocument,
  deleteDocumentLoading,
}) => {
  return (
    <Modal open={isExpandedOpened} width={420} footer={null} onCancel={onCancel}>
      <div className={styles.contentWrapper}>
        <div className={styles.iconWrapper}>
          <div className={styles.iconBackground}></div>
          <Delete className={styles.icon} />
        </div>
        <h2 className={styles.title}>Are you sure you want to delete this document?</h2>
        <div className={styles.buttonWrapper}>
          <Button
            onClick={onCancel}
            className={classNames(styles.button, styles.cancelButton)}
            title="Cancel"
          />

          <Button
            className={classNames(styles.button, styles.deleteButton)}
            title="Delete"
            onClick={deleteDocument}
            disabled={deleteDocumentLoading}
            isPending={deleteDocumentLoading}
          />
        </div>
      </div>
    </Modal>
  );
};
