import Api from 'store/effects/core/api';
import {
  addFormFolderAction,
  deleteFormFolderAction,
  editFormFolderAction,
  getFolderListAction,
} from 'store/actions/formBuilder';
import {
  addFormFolder,
  deleteFormFolder,
  editFormFolder,
  getFolderList,
} from 'api/formBuilder/folders';

export const getFolderListEffect = (cfg: any = {}) => {
  const sendRequest = Api.execBase({ action: getFolderListAction, method: getFolderList });
  return sendRequest(cfg);
};

export const addFormFolderEffect = (cfg: any = {}) => {
  const sendRequest = Api.execBase({ action: addFormFolderAction, method: addFormFolder });
  return sendRequest(cfg);
};

export const editFormFolderEffect = (cfg: any = {}) => {
  const sendRequest = Api.execBase({ action: editFormFolderAction, method: editFormFolder });
  return sendRequest(cfg);
};

export const deleteFormFolderEffect = (cfg: any = {}) => {
  const sendRequest = Api.execResult({ action: deleteFormFolderAction, method: deleteFormFolder });
  return sendRequest(cfg);
};
