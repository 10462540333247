import { createAction } from 'redux-actions';

export const socketsStoreNewMessageAction = createAction('SOCKETS/STORE_NEW_MESSAGE');
export const socketsStoreEditedMessageAction = createAction('SOCKETS/STORE_EDITED_MESSAGE');
export const socketsStoreDeletedMessageAction = createAction('SOCKETS/STORE_DELETED_MESSAGE');
export const socketsRemoveDeletedMessageAction = createAction('SOCKETS/REMOVE_DELETED_MESSAGE');
export const socketsStoreMessagesByThreadIdAction = createAction(
  'SOCKETS/STORE_MESSAGES_BY_THREAD_ID',
);
export const socketsResetMessagesAction = createAction('SOCKETS/RESET_MESSAGES');
export const socketSetEditMessageAction = createAction('SOCKETS/SET_EDIT_MESSAGE');
export const setNewMessageWithTempIdAction = createAction('SET_NEW_MESSAGE_WITH_TEMP_ID');
export const replaceTempMessageAction = createAction('REPLACE_TEMP_MESSAGE');
