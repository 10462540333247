import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const PhoneVerificationBlockedStep: React.FC<{}> = () => {
  return (
    <div className={styles.layout}>
      <h2 className={styles.stepHeader}>Phone verification</h2>
      <div className={classNames(styles.text, styles.blockedVerificationContent)}>
        Please contact <a href="emailto:support@mosaik.io">support@mosaik.io</a> for further
        assistance.
      </div>
    </div>
  );
};
