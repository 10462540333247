import React from 'react';

const PaymentMethod = ({ ...props }: React.SVGAttributes<any>) => (
  <svg
    className={props.className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="4" width="17" height="12" rx="2" stroke="#AAABAB" strokeWidth="2" />
    <rect x="5" y="7" width="17" height="12" rx="2" fill="white" stroke="#AAABAB" strokeWidth="2" />
    <path d="M5 11H22" stroke="#AAABAB" strokeWidth="2" />
  </svg>
);

export default PaymentMethod;
