import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import {
  setTransactionTaskParamsEffect,
  deleteTransactionTaskEffect,
  resetTransactionTaskEffect,
  getTransactionTaskEffect,
  getTransactionEffect,
} from 'store/effects/transactions';
import { getTransactionTaskSelector } from 'store/selectors/transactionTask';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { useOutsideClick } from 'hooks';
import { ConfirmModal } from 'components';
import { getUserId } from 'store/selectors/user';
import styles from './styles.module.scss';

const Options = (props) => {
  const {
    className,
    optionDotsClassName,
    assignorId,
    transactionId,
    taskId,
    isAggregatedTasks,
    Category,
    showEdit,
  } = props;
  const dispatch = useDispatch();
  const { task } = useSelector(getTransactionTaskSelector);
  const userId = useSelector(getUserId);
  const [requestPending, setRequestPending] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const buttonRef = useRef();
  const optionsRef = useRef();
  const { fullAccess } = useSelector(getTransactionAccessSelector);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const openTaskModal = (transactionId, taskId, formValues) =>
    dispatch(
      setTransactionTaskParamsEffect({
        isTaskForm: true,
        formValues: formValues,
        transactionId,
        taskId,
        isTaskDetail: false,
      }),
    );

  const onEdit = (e) => {
    e.stopPropagation();
    setOpen(false);
    isAggregatedTasks && dispatch(getTransactionEffect({ id: transactionId }));
    transactionId && taskId
      ? dispatch(
          getTransactionTaskEffect({ transactionId, taskId }, null, (err, resp) => {
            openTaskModal(transactionId, taskId, resp?.data?.result);
          }),
        )
      : openTaskModal(transactionId, task?.Id, task);
  };

  const onDelete = (e) => {
    e.stopPropagation();
    setOpen(false);
    setOpenConfirmModal(true);
  };

  const onCloseModal = (e) => {
    e.stopPropagation();
    setOpenConfirmModal(false);
  };

  const onSubmitConfirm = (e) => {
    e.stopPropagation();
    setRequestPending(true);
    dispatch(
      deleteTransactionTaskEffect({ transactionId, taskId: taskId || task?.Id }, {}, () => {
        dispatch(resetTransactionTaskEffect());
        setOpenConfirmModal(false);
        setRequestPending(false);
      }),
    );
  };

  if (assignorId !== userId && task?.Assignor?.Id !== userId && !fullAccess) {
    return null;
  }

  return (
    <div className={classNames(styles.optionsWrapper, className)}>
      <div
        testid="options"
        ref={buttonRef}
        onClick={onClickOptions}
        className={classNames(styles.optionsDots, optionDotsClassName)}
      >
        <Icon variant={Icon.DOTS} />
      </div>
      {open && (
        <div ref={optionsRef} className={styles.options}>
          <ul>
            {showEdit && Category?.Name !== 'Forms' ? (
              <li testid="edit_action" className={styles.item} onClick={onEdit}>
                <div className={styles.icon}>
                  <Icon variant={Icon.OPTIONS_EDIT} />
                </div>
                <span>Edit</span>
              </li>
            ) : (
              <></>
            )}
            <li testid="delete_action" className={styles.item} onClick={onDelete}>
              <div className={styles.icon}>
                <Icon variant={Icon.OPTIONS_DELETE} />
              </div>
              <span>Delete</span>
            </li>
          </ul>
        </div>
      )}
      <ConfirmModal
        title="Are you sure you want to delete the task?"
        isOpen={openConfirmModal}
        onClose={onCloseModal}
        onSubmit={onSubmitConfirm}
        isPending={requestPending}
        submitButtonTitle="Delete"
        cancelButtonTitle="Cancel"
        icon
      />
    </div>
  );
};

Options.propTypes = {
  className: PropTypes.string,
  optionDotsClassName: PropTypes.string,
  assignorId: PropTypes.number,
  transactionId: PropTypes.number,
  taskId: PropTypes.number,
  isAggregatedTasks: PropTypes.bool,
  Category: PropTypes.object,
  showEdit: PropTypes.bool,
};

Options.defaultProps = {
  className: '',
  optionDotsClassName: '',
  assignorId: null,
  transactionId: null,
  taskId: null,
  isAggregatedTasks: false,
  showEdit: true,
};

export default Options;
