import { createAction } from 'redux-actions';

export const setTransactionTaskParamsAction = createAction('SET_TRANSACTION_TASK_PARAMS');
export const deleteTransactionTaskAction = createAction('DELETE_TRANSACTION_TASK');
export const uploadTransactionTaskDocumentsAction = createAction(
  'UPLOAD_TRANSACTION_TASK_DOCUMENTS',
);
export const updateTransactionTaskAction = createAction('UPDATE_TRANSACTION_TASK');
export const deleteTransactionTaskDocumentAction = createAction('DELETE_TRANSACTION_TASK_DOCUMENT');
export const setTransactionTasksLastStateAction = createAction('SET_TRANSACTION_TASKS_LAST_STATE');
export const previewTransactionTaskDocumentAction = createAction(
  'PREVIEW_TRANSACTION_TASK_DOCUMENT',
);
export const setShowCommentIconForTransactionAction = createAction(
  'SHOW_COMMENT_ICON_FOR_TRANSACTION',
);
export const updateUnReadCommentsForTransactionAction = createAction(
  'UPDATE_UNREAD_COMMENTS_FOR_TRANSACTION',
);
export const updateTransactionTaskChecklistAction = createAction(
  'UPDATE_TRANSACTION_TASK_CHECKLIST',
);
export const setTaskFiltersAction = createAction('UPDATE_TRANSACTION_TASK_FILTERS');

export const setTransactionsTasksFilterAction = createAction('SET_TRANSACTIONS_TASKS_FILTER');
