import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { routes } from 'settings/navigation/routes';
import { Button } from 'components-antd';
import { useHistory } from 'react-router-dom';
import { Question } from 'pages/RequestQuote/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';

const Error = (props) => {
  const { className, handleRedirect } = props;
  const history = useHistory();

  return (
    <div className={styles.wrapper}>
      <Question className={styles.question}>This link seems to be expired.</Question>
      <ButtonsContainer>
        <Button
          onClick={() => {
            // history.push(routes.index);
            handleRedirect();
          }}
          variant="secondary"
          className={styles.button}
        >
          Go back to the app
        </Button>
      </ButtonsContainer>
    </div>
  );
};

Error.propTypes = {
  className: PropTypes.string,
  handleRedirect: PropTypes.func,
};

Error.defaultProps = {
  className: '',
  handleRedirect: () => {},
};

export default Error;
