import React from 'react';
import moment from 'moment';

import styles from './styles.module.scss';
import { CardBox } from 'pages/ExternalOffer/OfferSubmission/components/CardBox';
import { Icons } from '../../../../components/Icons';
import { IconText } from '../IconText';

export const EditDocuments = ({ data, onEdit }) => {
  const getInfo = () => {
    const {
      OfferIteration: { OfferIterationDocument },
    } = data;

    if (OfferIterationDocument.length) {
      const names = OfferIterationDocument.filter(Boolean).map(
        (docItem) => docItem.DocumentName || docItem.documentName,
      );
      return names.length ? names.join(', ') : 'N/A';
    }

    return 'N/A';
  };

  return (
    <CardBox className={styles.editForm}>
      <IconText
        text={'Documents'}
        infoText={getInfo()}
        variant={'documents'}
        className={styles.editFormInfo}
      />
      <div className={styles.editIcon} onClick={onEdit}>
        <Icons variant={'edit'} />
      </div>
    </CardBox>
  );
};
