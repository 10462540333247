import classNames from 'classnames';
import moment from 'moment';

import { DocumentIcons } from 'components/ClientsDrawer/ProfileClient/Icons';
import { AgentDocumentType, AgentDocumentTypes, DocumentOptionUtils } from 'types';
import { Options } from './Options';

import styles from './styles.module.scss';

interface CardProps extends DocumentOptionUtils {
  document: AgentDocumentType;
  type: AgentDocumentTypes;
}

const formatDate = (date) => moment(date).format('MMM DD [at] HH:MM A');

const renderDate = (date) => <p className={classNames(styles.cardMeta)}>{formatDate(date)}</p>;

const renderUploadedBy = (user) => (
  <p className={classNames(styles.cardMeta)}>Uploaded By: {user}</p>
);

export const Card = ({ document, type, ...props }: CardProps) => {
  const {
    title,
    messageAttachmentMeta,
    preApprovalMeta,
    transactionMeta,
    draftMeta,
    pendingMeta,
    offerMeta,
    showingMeta,
  } = document;

  const getMetaForType = () => {
    switch (type) {
      case AgentDocumentTypes.Drafts:
        if (draftMeta?.lastEdit) {
          return (
            <p className={classNames(styles.cardMeta)}>
              Last Edited: {formatDate(draftMeta.lastEdit)}
            </p>
          );
        }
        break;
      case AgentDocumentTypes.PendingForms:
        if (pendingMeta?.waitingOn) {
          return (
            <p className={classNames(styles.cardMeta, styles.pending)}>
              Waiting for {pendingMeta.isEdit ? 'Edit' : 'Signature'}: {pendingMeta.waitingOn}
            </p>
          );
        }
        break;
      case AgentDocumentTypes.PreApprovals:
        if (preApprovalMeta?.uploadedBy) {
          return (
            <span>
              {renderUploadedBy(preApprovalMeta.uploadedBy)}
              {renderDate(preApprovalMeta.uploadedAt)}
            </span>
          );
        }
        break;
      case AgentDocumentTypes.Transactions:
        if (transactionMeta?.transactionId) {
          return (
            <span>
              {renderUploadedBy(transactionMeta.uploadedBy)}
              {renderDate(transactionMeta.uploadedAt)}
            </span>
          );
        }
        break;
      case AgentDocumentTypes.MessageAttachments:
        if (messageAttachmentMeta?.messageThreadId) {
          return (
            <span>
              {renderUploadedBy(messageAttachmentMeta.uploadedBy)}
              {renderDate(messageAttachmentMeta.uploadedAt)}
            </span>
          );
        }
        break;
      case AgentDocumentTypes.Ungrouped:
        if (offerMeta?.OfferId) {
          return (
            <span>
              {renderUploadedBy(offerMeta.uploadedBy)}
              {renderDate(offerMeta.uploadedAt)}
            </span>
          );
        } else if (showingMeta?.showingId) {
          return (
            <span>
              {renderUploadedBy(showingMeta.uploadedBy)}
              {renderDate(showingMeta.uploadedAt)}
            </span>
          );
        }
        break;

      default:
        return <></>;
    }
  };

  return (
    <div className={styles.cardWrap}>
      <DocumentIcons type={type} color="#515151" className={styles.cardIcon} />
      <div className={styles.cardContent}>
        <div className={styles.cardTitle}>{title}</div>
        {getMetaForType()}
      </div>
      <Options document={document} type={type} {...props} />
    </div>
  );
};
