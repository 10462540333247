import { routes } from 'settings/navigation/routes';
import { Symphony } from 'pages';

export default [
  {
    path: routes.symphony,
    component: Symphony,
    exact: true,
  },
];
