import React, { useEffect } from 'react';

import { getUserDataSelector } from 'store/selectors/user';
import { useDispatch, useSelector } from 'react-redux';
import { AGENT, CLIENT, THIRD_PARTY, mapRoles } from 'settings/constants/roles';

import { Pulse } from './Pulse';
import { PartnerDashboard } from './PartnerDashboard';
import { ClientDashboard } from './ClientDashboard';
import { push } from 'connected-react-router';
import { link } from 'settings/navigation/link';

import styles from './styles.module.scss';

export const Main: React.FC<any> = () => {
  const user = useSelector(getUserDataSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    const userRole = user?.Roles[0];
    const isProfileComplete = user?.CompletedProfile;

    const onboardingRoles = [CLIENT, AGENT, THIRD_PARTY];
    const isOnBoardingRole = onboardingRoles.some((role) => user?.Roles?.includes(role));
    if (!isProfileComplete && isOnBoardingRole) {
      dispatch(push(link.toOnBoarding(mapRoles[userRole])));
    }
  }, [user]);
  const renderRoleBasedDashboard = () => {
    const roleType = user?.Roles;
    if (roleType.includes(AGENT)) {
      return <Pulse />;
    } else if (roleType.includes(THIRD_PARTY)) {
      return <PartnerDashboard />;
    } else if (roleType.includes(CLIENT)) {
      return <ClientDashboard />;
    } else {
      return <></>;
    }
  };

  return <div className={styles.wrapper}>{renderRoleBasedDashboard()}</div>;
};
