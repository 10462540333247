import React from 'react';
import PropTypes from 'prop-types';

const VirtualTour = ({ className }) => (
  <div className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0015 21C5.19652 21.5296 -0.935233 18.5188 2.00136 14"
        stroke="#303030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9985 20.9668C20.2303 20.4884 23.7279 18.2127 22.5139 15"
        stroke="#303030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 19L13 21L10.5 23"
        stroke="#525252"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 12L16.3511 11.0636C16.044 10.9485 15.7 10.9912 15.4304 11.178C15.1608 11.3648 15 11.672 15 12H16ZM19.3244 13.2466L18.9733 14.183L18.9733 14.183L19.3244 13.2466ZM19.3244 6.75329L18.9733 5.81696L18.9733 5.81696L19.3244 6.75329ZM16 7.99996H15C15 8.32794 15.1608 8.63507 15.4304 8.82188C15.7 9.0087 16.044 9.05145 16.3511 8.93629L16 7.99996ZM6 7C6 6.44772 6.44772 6 7 6V4C5.34315 4 4 5.34315 4 7H6ZM6 13V7H4V13H6ZM7 14C6.44772 14 6 13.5523 6 13H4C4 14.6569 5.34315 16 7 16V14ZM14 14H7V16H14V14ZM15 13C15 13.5523 14.5523 14 14 14V16C15.6569 16 17 14.6569 17 13H15ZM15 12V13H17V12H15ZM19.6756 12.3103L16.3511 11.0636L15.6489 12.9363L18.9733 14.183L19.6756 12.3103ZM19 12.7785C19 12.4294 19.3487 12.1877 19.6756 12.3103L18.9733 14.183C19.9539 14.5507 21 13.8258 21 12.7785H19ZM19 7.22146V12.7785H21V7.22146H19ZM19.6756 7.68962C19.3487 7.8122 19 7.57056 19 7.22146H21C21 6.17415 19.9539 5.44923 18.9733 5.81696L19.6756 7.68962ZM16.3511 8.93629L19.6756 7.68962L18.9733 5.81696L15.6489 7.06363L16.3511 8.93629ZM15 7V7.99996H17V7H15ZM14 6C14.5523 6 15 6.44772 15 7H17C17 5.34315 15.6569 4 14 4V6ZM7 6H14V4H7V6Z"
        fill="#525252"
      />
    </svg>
  </div>
);

VirtualTour.propTypes = {
  className: PropTypes.string,
};

VirtualTour.defaultProps = {
  className: '',
};

export default VirtualTour;
