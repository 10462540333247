import { SVGProps } from 'react';

type variantTypes = 'arrowdown';
interface OfferIconProps extends SVGProps<SVGSVGElement> {
  variant: variantTypes;
}

export const Icons = ({ variant }: OfferIconProps) => {
  const renderIcon = () => {
    switch (variant) {
      case 'arrowdown': {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6L8.5 10.5L13 6"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
    }
  };

  return renderIcon();
};
