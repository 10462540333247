import { routes } from 'settings/navigation/routes';
import { Clarity, ClarityRevamped } from 'pages';
import { AGENT, CLIENT } from 'settings/constants/roles';

export default [
  {
    path: routes.clarity,
    component: Clarity,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.myKits,
    component: Clarity,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.metrics,
    component: ClarityRevamped,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.reports,
    component: ClarityRevamped,
    exact: true,
    roles: [AGENT, CLIENT],
  },
];
