import cloneDeep from 'clone-deep';
import { get } from 'lodash-es';

export function getDataFor(fieldName, payload, initialData) {
  return payload[fieldName] || cloneDeep(initialData[fieldName]);
}

export function getPromiseAllSettledProp(data, propName = 'data') {
  return (data.value && data.value[propName]) || null;
}

export function getPromiseAllSettledReason(data) {
  if ('reason' in data) {
    return { status: data.reason.response.status, message: data.reason.message };
  }

  return { status: getPromiseAllSettledProp(data, 'status') };
}

export function updatePropertyFavorite(state, payload) {
  const id = get(payload, 'meta.id');
  const isFavorite = get(payload, 'options.IsFavorite');
  const newData = cloneDeep(state?.data) || [];
  const favoriteIndex = newData.findIndex((item) => item?.Id === id);

  if (favoriteIndex !== -1) {
    newData[favoriteIndex].IsFavorite = isFavorite;
  }

  return newData;
}
