import AntInput, { InputProps } from 'antd/lib/input';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface AntInputProps extends InputProps {
  hideOutline?: boolean;
  variant?: string;
}

export const Input = ({ className, hideOutline, variant, ...props }: AntInputProps) => (
  <AntInput
    className={classNames(
      styles.baseInput,
      className,
      { [styles.hideOutline]: hideOutline },
      variant ? styles[variant] : null,
    )}
    {...props}
  />
);

Input.LIGHT = 'light';
Input.SQUARE = 'square';
