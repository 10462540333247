import { HTMLAttributes } from 'react';

export const Hamburger = ({ ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 13H30" stroke="#666666" strokeWidth="2" strokeLinecap="round" />
      <path d="M10 20H30" stroke="#666666" strokeWidth="2" strokeLinecap="round" />
      <path d="M10 27H30" stroke="#666666" strokeWidth="2" strokeLinecap="round" />
    </svg>
  </div>
);
