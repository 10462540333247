import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { getThreadsSelector } from './threads';
import { getMessageAttachmentsDrawerParams } from '../drawers/messageAttachments';
import moment from 'moment';
import { SOCKET_MESSAGE_TYPES } from 'settings/constants/sockets';

export const localState = ({ sockets }) => get(sockets, 'messages');

export const getSocketMessages = createSelector(localState, ({ messages }) => messages);

export const getCurrentMessagesThreadId = createSelector(
  localState,
  ({ currentThreadId }) => currentThreadId,
);

export const getCurrentEditMessageSelector = createSelector(
  localState,
  ({ editMessage }) => editMessage,
);

export const getCurrentMessagesInfoSelector = createSelector(
  getSocketMessages,
  getCurrentMessagesThreadId,
  getThreadsSelector,
  (messages, currentThreadId, threads) => {
    const currentThread = threads?.[currentThreadId];
    return messages
      ? messages.map((message) => {
          const participant = currentThread?.Participants.find(
            (partic) => partic?.Id === message?.SenderUserId,
          );

          return {
            ...message,
            ...(participant?.AvatarUrl
              ? { AvatarUrl: participant.AvatarUrl }
              : { AvatarUrl: null }),
            FirstName: participant?.FirstName,
            LastName: participant?.LastName,
          };
        })
      : null;
  },
);

export const getCurrentThreadSelector = createSelector(
  getCurrentMessagesThreadId,
  getThreadsSelector,
  (currentThreadId, threads) => threads?.[currentThreadId],
);

export const getMessageAttachmentsSelector = createSelector(
  getSocketMessages,
  getMessageAttachmentsDrawerParams,
  getThreadsSelector,
  (messages, { threadId, propertyId }, threads) => {
    const currentThread = threads?.[threadId];

    if (propertyId) {
      messages = messages.filter((message) => message.MessageMeta?.EntityId === propertyId);
    }

    return messages
      ? messages
          .filter((message) => message.Attachments?.length)
          .reduce((acc, message) => {
            const participant = currentThread?.Participants.find(
              (user) => user?.Id === message?.SenderUserId,
            );

            message.Attachments.forEach((attachment) => {
              acc.push({
                ...attachment,
                FirstName: participant?.FirstName,
                LastName: participant?.LastName,
                SendDate: moment(message.CreatedDate).format('MMM D'),
                SendTime: moment(message.CreatedDate).format('hh:mm A'),
              });
            });

            return acc;
          }, [])
      : [];
  },
);

export const getPropertyCommentsAttachmentsSelector = createSelector(
  getSocketMessages,
  getMessageAttachmentsDrawerParams,
  (messages, { propertyId }) => {
    if (propertyId) {
      messages = messages.filter((message) => message.MessageMeta?.EntityId === propertyId);
    }

    return messages
      ? messages
          .filter(
            (message) =>
              message.MessageType === SOCKET_MESSAGE_TYPES.PROPERTY_COMMENT &&
              !message.Attachments?.length,
          )
          .map((message) => ({
            PropertyName:
              (message.MessageMeta?.EntityId &&
                message.MessageMeta?.EntityData?.PropertyAddress?.Line1) ||
              'Private Address',
            PropertyId: message.MessageMeta.EntityId,
            SendDate: moment(message.CreatedDate).format('M/D/YYYY'),
            PropertyComment: message.Text,
            messageId: message.Id,
          }))
      : [];
  },
);
