import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { initState } from './initialState';
import { Stages } from 'components';
import { Modal } from 'components-antd';
import {
  LinkDuration,
  OfferDeadline,
  ListingAgent,
  Notifications,
  Documents,
  Notes,
  Notify,
  EnableLink,
} from './StageParts';
import { Icons } from '../../TransactionShowings/ShowingModal/Icons';
import { requestCreateUpdateOfferEffect } from 'store/effects/offers';
import { getTransactionEffect } from 'store/effects/transactions';
import { showSuccessMessage } from 'helpers/success';
import { ClientNames } from 'pages/Workshop/Transactions/TransactionOverview/components/ClientNames/ClientNames';
import { PropertyTransaction } from 'types/transaction';
import { extractClientNames } from 'utils';

import styles from './styles.module.scss';
import { useFetchTeamList } from './hooks/useFetchTeamList';
import { getUserSelector } from 'store/selectors/user';

interface OfferModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  transaction: PropertyTransaction;
  fullAccess?: boolean;
  setReloadOffer?: (boolean) => void;
}

export const OfferModal = ({
  isOpen,
  onClose,
  transaction,
  fullAccess,
  setReloadOffer,
}: OfferModalProps) => {
  const dispatch = useDispatch();
  const address = transaction?.Property?.Address;
  const clients = extractClientNames(transaction, true);
  const loggedInUser = useSelector(getUserSelector);

  const [offerFlow, setOfferFlow]: any = useState(initState);

  const [loading, setLoading] = useState(false);

  const [participantList, setParticipantList] = useState([]) as any[];
  const { teamMembers } = useFetchTeamList(participantList);

  const notifiedUserDetails = useMemo(() => {
    if (transaction && transaction.OfferLink && teamMembers.length) {
      const { NotificationUser }: any = transaction.OfferLink;

      let NotifiedParticipantNames = '';
      let NotificationUserIds: Array<number> = [];

      if (NotificationUser.length) {
        const userIds = NotificationUser.map(({ UserId }) => UserId);
        NotificationUserIds = Array.from(new Set(userIds));

        const userEmail = loggedInUser?.data?.Email;
        let names = teamMembers
          .filter((item) => NotificationUserIds.includes(item.Id))
          .map((user) => (user.Email === userEmail ? 'Me' : user.name));

        NotifiedParticipantNames = names.join(', ');
      }

      return { NotifiedParticipantNames, NotificationUserIds };
    }
  }, [transaction, teamMembers]);

  useEffect(() => {
    const { Participants = [], User } = transaction;

    setParticipantList([...Participants, User]);
  }, [transaction.Participants]);

  useEffect(() => {
    if (transaction.OfferLink) {
      setOfferFlow({
        ...transaction.OfferLink,
        SelectedDuration: {
          isIndefinite: transaction.OfferLink.Indefinitely,
          isAddDays: !!transaction.OfferLink.LinkActiveDays,
          isDateRange: !!(
            transaction.OfferLink.LinkActiveStartDate &&
            transaction.OfferLink.LinkActiveEndDate &&
            !transaction.OfferLink.LinkActiveDays
          ),
          isDeadline: !!transaction.OfferLink.DeadlineDate,
        },

        ...notifiedUserDetails,
      });
    }
  }, [transaction.OfferLink, teamMembers]);

  useEffect(() => {
    if (transaction.Id) {
      onUpdate({ PropertyTransactionId: transaction.Id });
      // fetchParticipants(transaction.Id);
    }
  }, [transaction.Id]);

  const fetchTransactionById = (callback?) => {
    dispatch(getTransactionEffect({ id: transaction?.Id }, { silent: true }, callback));
  };

  const createOrUpdateOffer = (body) => {
    const data = {
      ...offerFlow,
      ...body,
    };
    if (data?.Id) {
      data.Id = Number(data.Id);
    }
    delete data.SelectedDuration;
    dispatch(
      requestCreateUpdateOfferEffect(data, {}, (err) => {
        !err
          ? fetchTransactionById(() => {
              setReloadOffer && setReloadOffer(true);
              const toaster = `Offer ${
                data.Id ? 'configuration updated.' : 'configured successfully.'
              }`;
              showSuccessMessage(toaster);
              setLoading(false);
            })
          : setLoading(false);
      }),
    );
  };

  const onUpdate = (data) => {
    setOfferFlow({
      ...offerFlow,
      ...data,
    });
  };

  const onFinish = (body) => {
    setLoading(true);
    createOrUpdateOffer(body);
  };

  const stagesStep = {
    LinkDuration: 0,
    OfferDeadline: 1,
    ListingAgent: 2,
    Notifications: 3,
    Documents: 4,
    Notes: 5,
    Notify: 6,
    EnableLink: 7,
  };

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      className={styles.offerModal}
    >
      <h1 className={styles.heading}>Offer Portal</h1>
      <div className={styles.content}>
        <Icons className={styles.condoIcon} variant={'condo'} />
        <div className={styles.details}>
          <h4 className={styles.address}>
            {address ? `${address.Line1}, ${address.City}, ${address.State}` : ''}
          </h4>

          <p className={styles.subDetails}>
            <span>
              <strong>Price: </strong>
              {transaction?.Price?.toLocaleString()}
            </span>
            <ClientNames clients={clients} className={styles.clientNames} />
          </p>
        </div>
      </div>
      <Stages
        stages={[
          { Component: LinkDuration },
          { Component: OfferDeadline },
          { Component: ListingAgent },
          { Component: Notifications },
          { Component: Documents },
          { Component: Notes },
          { Component: Notify },
          { Component: EnableLink },
        ]}
        stagesStep={stagesStep}
        classNameProgress={styles.progressBar}
        classNameWrapper={styles.stagesWrapper}
        transaction={transaction}
        modalType={'Offer'}
        participantList={participantList}
        fullAccess={fullAccess}
        onUpdate={onUpdate}
        onFinish={onFinish}
        onClose={onClose}
        data={offerFlow}
        loading={loading}
      />
    </Modal>
  );
};
