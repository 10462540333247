import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Skeleton } from 'antd';
import { Wrapper as PendingWrapper } from 'components';
import { useSelector } from 'react-redux';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import { getUserRolesMapSelector } from 'store/selectors/user';
import UserQuote from './UserQuote';
import ThirdPartyQuote from './ThirdPartyQuote';

import styles from './styles.module.scss';

const Content = (props) => {
  const { className } = props;
  const { isPending, isData } = useSelector(getQuoteDetailsSelector);
  const { isThirdParty } = useSelector(getUserRolesMapSelector);

  const getContent = () =>
    isThirdParty ? (
      <ThirdPartyQuote
        className={styles.detailsContent}
        detailsClassName={styles.details}
        metaClassName={styles.metaInfo}
      />
    ) : (
      <UserQuote
        className={styles.detailsContent}
        detailsClassName={styles.details}
        metaClassName={styles.metaInfo}
      />
    );

  return (
    <div testid="quote_details" className={classNames(styles.content, className)}>
      <PendingWrapper className={styles.pendingWrapper} isPending={isPending}>
        {isData ? (
          getContent()
        ) : (
          <>
            <Skeleton active />
            <Skeleton active />
          </>
        )}
      </PendingWrapper>
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
};

Content.defaultProps = {
  className: '',
};

export default Content;
