import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Input } from 'components';
import EditActions from '../../../EditActions';

import styles from './styles.module.scss';

const Text = (props) => {
  const { className, onSave, onCancel, value, isPending } = props;

  const [inputValue, setInputValue] = useState(value);

  useEffect(() => setInputValue(value), [value]); // eslint-disable-line

  const onSaveHandler = () => {
    onSave(inputValue);
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSaveHandler();
    }
  };

  return (
    <div className={classNames(styles.text, className)}>
      <Input
        className={styles.inputField}
        inputClassName={styles.input}
        variant={Input.LIGHT}
        value={inputValue}
        onChange={(e, val) => setInputValue(val)}
        onKeyPress={onKeyPress}
        autofocus
      />
      <EditActions isPending={isPending} onSave={onSaveHandler} onCancel={onCancel} />
    </div>
  );
};

Text.propTypes = {
  className: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isPending: PropTypes.bool,
};

Text.defaultProps = {
  className: '',
  isPending: false,
};

export default Text;
