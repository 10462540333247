import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ArrowLeft } from 'components/Icons';

import styles from './styles.module.scss';

const GoBack = ({ className, iconClassName, textClassName, onBack, text, testid, showText }) => (
  <button
    testid={testid}
    onClick={() => onBack(null)}
    className={classNames(styles.back, className)}
  >
    <ArrowLeft className={classNames(styles.arrow, iconClassName)} />
    {showText ? <span className={textClassName}>{text || 'Back'}</span> : <></>}
  </button>
);

GoBack.propTypes = {
  className: PropTypes.string,
  textClassName: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  text: PropTypes.string,
  testid: PropTypes.string,
  showText: PropTypes.bool,
};

GoBack.defaultProps = {
  className: '',
  textClassName: '',
  text: undefined,
  testid: undefined,
  showText: true,
};

export default GoBack;
