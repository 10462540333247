import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { groupQuotesBy, quoteRequestStatus } from 'settings/constants/quotes';
import { Wrapper as PendingWrapper } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { getQuotesSelector, getGroupedQuotesSelector } from 'store/selectors/quotes';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { ArchiveModal } from 'pages/Quotes/components';
import { archiveQuotesEffect } from 'store/effects/quotes';
import { ByStatus, ByCategory, ByClient, ByProperty } from './Group';

import styles from './styles.module.scss';
import ByArchieve from './Group/ByArchieve';

const Content = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  const { isPending } = useSelector(getQuotesSelector);
  const { byStatus, groupByPropName, quotesData, isArchive, groupId } =
    useSelector(getGroupedQuotesSelector);
  const { isThirdParty } = useSelector(getUserRolesMapSelector);

  const [archiveQuoteIds, setArchiveQuoteIds] = useState(null);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [isPendingArchiveRequest, setIsPendingArchiveRequest] = useState(false);

  const onArchive = (quoteIds, status) => {
    if (isThirdParty && !isArchive) {
      setArchiveQuoteIds(quoteIds);
      setOpenArchiveModal(true);
    } else {
      dispatch(
        archiveQuotesEffect({ ids: quoteIds }, { isDraft: status === quoteRequestStatus.Draft }),
      );
    }
  };

  const onCloseArchiveModal = () => {
    if (isThirdParty) {
      setOpenArchiveModal(false);
      setArchiveQuoteIds(null);
    }
  };

  const onSubmitArchive = (status) => {
    setIsPendingArchiveRequest(true);
    dispatch(
      archiveQuotesEffect({ ids: archiveQuoteIds, status }, {}, (err) => {
        if (!err) {
          setOpenArchiveModal(false);
        }
        setIsPendingArchiveRequest(false);
      }),
    );
  };

  const getContent = () => {
    if (isArchive) {
      return <ByArchieve onArchive={onArchive} />;
    }

    switch (groupByPropName) {
      case groupQuotesBy.CATEGORY: {
        return <ByCategory onArchive={onArchive} />;
      }
      case groupQuotesBy.CLIENT: {
        return <ByClient onArchive={onArchive} />;
      }
      case groupQuotesBy.PROPERTY: {
        return <ByProperty onArchive={onArchive} />;
      }
      default:
        return <ByStatus onArchive={onArchive} quotesList={byStatus} groupId={groupId} />;
    }
  };

  const getArchivedStatus = () => {
    if (isThirdParty) {
      const archiveQuoteId = archiveQuoteIds?.[0];
      const quoteData = (quotesData || []).find((quote) => quote?.Id === archiveQuoteId);
      return quoteData?.ArchivedStatus;
    }
    return null;
  };

  return (
    <div className={classNames(styles.content, className)}>
      <PendingWrapper
        isPending={isPending}
        className={classNames({ [styles.pendingWrapper]: isPending })}
      >
        {getContent()}
      </PendingWrapper>
      {isThirdParty && openArchiveModal && (
        <ArchiveModal
          isOpen={openArchiveModal}
          onClose={onCloseArchiveModal}
          onSubmit={onSubmitArchive}
          isPending={isPendingArchiveRequest}
          archivedStatus={getArchivedStatus()}
        />
      )}
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
};

Content.defaultProps = {
  className: '',
};

export default Content;
