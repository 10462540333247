import { HTMLAttributes, memo, useMemo } from 'react';

interface NotificationsProps extends HTMLAttributes<HTMLDivElement> {
  header?: boolean;
}

const Notifications = memo(({ className, header }: NotificationsProps) =>
  useMemo(
    () => (
      <div className={className}>
        {header ? (
          <svg
            width="23"
            height="26"
            viewBox="0 0 23 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7724 5.18785V5.28189L12.8628 5.30795L13.4559 5.47904C16.1884 6.26731 18.1058 8.77872 18.1058 11.6664V16.589C18.1058 17.5667 18.8951 18.3558 19.8709 18.3558C20.2538 18.3558 20.5673 18.6689 20.5673 19.0513C20.5673 19.4334 20.2542 19.7468 19.8677 19.7468H3.4656C3.078 19.7468 2.76603 19.4347 2.76603 19.0513C2.76603 18.6684 3.07841 18.3558 3.46239 18.3558C4.43647 18.3558 5.22756 17.564 5.22756 16.589V11.6664C5.22756 8.778 7.14475 6.2672 9.87742 5.47902L10.4705 5.30794L10.5609 5.28188V5.18784V3.86985C10.5609 3.26153 11.057 2.76603 11.6667 2.76603C12.2778 2.76603 12.7724 3.26046 12.7724 3.86985V5.18785ZM11.6667 25.125C13.6637 25.125 15.3025 23.5916 15.4699 21.6378H19.8677C21.2973 21.6378 22.4583 20.4791 22.4583 19.0513C22.4583 17.6655 21.3645 16.5331 19.9968 16.4671V11.6664C19.9968 8.17625 17.8322 5.11161 14.6635 3.89173V3.86985C14.6635 2.21545 13.3215 0.875 11.6667 0.875C10.0135 0.875 8.66987 2.2162 8.66987 3.86985V3.89165C5.50105 5.11137 3.33654 8.17553 3.33654 11.6664H3.46154L3.33654 11.6664V11.6666L3.33654 11.6672L3.33654 11.6699L3.33654 11.6805L3.33653 11.7216L3.33651 11.8779L3.33645 12.4356L3.33637 14.1281L3.33659 15.8205L3.3369 16.3783L3.33703 16.4677C1.96682 16.5332 0.875 17.666 0.875 19.0513C0.875 20.4806 2.03515 21.6378 3.4656 21.6378H7.86344C8.03085 23.5916 9.66964 25.125 11.6667 25.125ZM11.6667 23.234C10.7154 23.234 9.92506 22.5443 9.76855 21.6378H13.5648C13.4083 22.5443 12.6179 23.234 11.6667 23.234Z"
              fill="#666666"
              stroke="#303030"
              strokeWidth="0.25"
            />
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 5C10 4.46957 10.2107 3.96086 10.5858 3.58579C10.9609 3.21071 11.4696 3 12 3C12.5304 3 13.0391 3.21071 13.4142 3.58579C13.7893 3.96086 14 4.46957 14 5C15.1484 5.54303 16.1274 6.38833 16.8321 7.4453C17.5367 8.50227 17.9404 9.73107 18 11V14C18.0753 14.6217 18.2954 15.2171 18.6428 15.7381C18.9902 16.2592 19.4551 16.6914 20 17H4C4.54494 16.6914 5.00981 16.2592 5.35719 15.7381C5.70457 15.2171 5.92475 14.6217 6 14V11C6.05956 9.73107 6.4633 8.50227 7.16795 7.4453C7.8726 6.38833 8.85159 5.54303 10 5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.81641 17V17.7273C9.81641 18.3059 10.0463 18.8609 10.4554 19.2701C10.8646 19.6792 11.4196 19.9091 11.9982 19.9091C12.5769 19.9091 13.1318 19.6792 13.541 19.2701C13.9502 18.8609 14.18 18.3059 14.18 17.7273V17"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    ),
    [className, header],
  ),
);

export default Notifications;
