import { handleActions } from 'redux-actions';
import { Polygon, Feature, Point } from 'geojson';
import {
  addNewAreaGroupAction,
  addNewAreaToEditAction,
  cancelDrawingNewShapeAction,
  cancelNewAreaGroupAction,
  deleteEditableAreaAction,
  getAreaGroupDetailsAction,
  newShapeDrawnAction,
  setEditModeForSelectedAreaGroupAction,
  startDrawingNewShapeAction,
  updateAreaTypeAction,
  updateEditableAreaNameAction,
} from 'store/actions/adminPanel';
import { READY } from 'settings/constants/apiState';

export enum ShapeType {
  CIRCLE = 'circle',
  RECTANGLE = 'rectangle',
  POLYGON = 'polygon',
}

export enum AreaTypeRefined {
  LargeCity = 'LargeCity',
  MediumCity = 'MediumCity',
  SmallCity = 'SmallCity',
  LargeTown = 'LargeTown',
  SmallTown = 'SmallTown',
  Rural = 'Rural',
  Unknown = 'Unknown',
}

export type DrawnShapeData =
  | {
      type: ShapeType.CIRCLE;
      geoJson: Feature<
        Point,
        {
          radius: number;
        }
      >;
    }
  | {
      type: ShapeType.RECTANGLE;
      geoJson: Feature<
        Polygon,
        {
          subType: 'rectangle';
        }
      >;
    }
  | {
      type: ShapeType.POLYGON;
      geoJson: Feature<Polygon>;
    };

export type VisibleShapeData =
  | DrawnShapeData & {
      color: string;
    };

export interface AreaPreview {
  type: string;
  color: string;
}

export type SelectedAreaGroupStateType = {
  editableShapeIndex: number | null;
  selectedAreaGroupId: number | null | 'new';
  isEditMode: boolean;
  editableAreaGroup: {
    id: number;
    name: string;
    areas: Array<{
      type: string;
      shape?: VisibleShapeData;
    }>;
  } | null;
};

const defaultEditableAreaGroup = {
  id: 0,
  name: '',
  areas: [],
};

const initialData: SelectedAreaGroupStateType = {
  editableShapeIndex: null,
  selectedAreaGroupId: null,
  isEditMode: false,
  editableAreaGroup: null,
};

const colors = ['#2B59DA', '#51BFE1', '#FB913A', '#00B152', '#EC455E', '#928CDA'];

const selectNextColor = (currentAreasLength: number) => {
  return colors[currentAreasLength % colors.length];
};

const getShapeTypeFromFeature = (feature: Feature): ShapeType => {
  if (feature.geometry.type === 'Point') {
    return ShapeType.CIRCLE;
  }
  if (feature.geometry.type === 'Polygon') {
    return ShapeType.POLYGON;
  }

  throw new Error('Unknown shape type');
};

export default handleActions(
  {
    [addNewAreaToEditAction as any]: (state, { payload }) => {
      return {
        ...state,
        editableAreaGroup: {
          ...(state.editableAreaGroup ?? defaultEditableAreaGroup),
          areas: [
            ...(state.editableAreaGroup?.areas ?? []),
            {
              type: AreaTypeRefined.LargeCity,
            },
          ],
        },
      };
    },
    [addNewAreaGroupAction as any]: (state, { payload }) => {
      return {
        ...state,
        selectedAreaGroupId: 'new' as const,
        isEditMode: true,
        editableAreaGroup: {
          id: 0,
          name: '',
          areas: [
            {
              type: AreaTypeRefined.LargeCity,
            },
          ],
        },
      };
    },
    [getAreaGroupDetailsAction as any]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        ...(payload.state === READY
          ? {
              selectedAreaGroupId: payload.data.Id,
              editableAreaGroup: {
                id: payload.data.Id,
                name: payload.data.Name,
                areas: payload.data.Areas.map((area, index) => ({
                  type: area.Type,
                  shape: {
                    type: getShapeTypeFromFeature(area.ShapeGeoJson),
                    geoJson: area.ShapeGeoJson,
                    color: selectNextColor(index),
                  },
                })),
              },
            }
          : {}),
      };
    },
    [cancelNewAreaGroupAction as any]: (state, { payload }) => {
      return {
        ...state,
        isEditMode: false,
        selectedAreaGroupId: null,
        editableAreaGroup: null,
      };
    },
    [startDrawingNewShapeAction as any]: (state, { payload }) => {
      const { areaIndex } = payload as unknown as { areaIndex: number };
      return {
        ...state,
        editableShapeIndex: areaIndex,
      };
    },
    [cancelDrawingNewShapeAction as any]: (state) => {
      return {
        ...state,
        editableShapeIndex: null,
      };
    },
    [newShapeDrawnAction as any]: (state, { payload }) => {
      const { shapeData } = payload as unknown as { shapeData: DrawnShapeData };
      const { editableAreaGroup, editableShapeIndex } = state;
      if (editableAreaGroup && editableShapeIndex !== null) {
        const newAreas = [...editableAreaGroup.areas];
        newAreas[editableShapeIndex] = {
          ...newAreas[editableShapeIndex],
          shape: {
            ...shapeData,
            color: selectNextColor(editableShapeIndex),
          },
        };
        return {
          ...state,
          editableAreaGroup: {
            ...editableAreaGroup,
            areas: newAreas,
          },
          editableShapeIndex: null,
        };
      }
      return state;
    },
    [updateEditableAreaNameAction as any]: (state, { payload }) => {
      const { name } = payload as unknown as { name: string };
      const { editableAreaGroup } = state;
      if (editableAreaGroup) {
        return {
          ...state,
          editableAreaGroup: {
            ...editableAreaGroup,
            name,
          },
        };
      }
      return state;
    },
    [deleteEditableAreaAction as any]: (state, { payload }) => {
      const { areaIndex } = payload as unknown as { areaIndex: number };
      const { editableAreaGroup } = state;
      if (editableAreaGroup) {
        const newAreas = [...editableAreaGroup.areas];
        newAreas.splice(areaIndex, 1);
        return {
          ...state,
          editableAreaGroup: {
            ...editableAreaGroup,
            areas: newAreas,
          },
        };
      }
      return state;
    },
    [setEditModeForSelectedAreaGroupAction as any]: (state, { payload }) => {
      const isEditMode = payload as unknown as boolean;
      return {
        ...state,
        isEditMode,
      };
    },
    [updateAreaTypeAction as any]: (state, { payload }) => {
      const { areaIndex, areaType } = payload as unknown as {
        areaIndex: number;
        areaType: AreaTypeRefined;
      };
      const { editableAreaGroup } = state;
      if (editableAreaGroup) {
        const newAreas = [...editableAreaGroup.areas];
        newAreas[areaIndex] = {
          ...newAreas[areaIndex],
          type: areaType,
        };
        return {
          ...state,
          editableAreaGroup: {
            ...editableAreaGroup,
            areas: newAreas,
          },
        };
      }
      return state;
    },
  },
  initialData,
);
