import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { pullAt } from 'lodash-es';

import { DeleteModal } from 'components-antd';
import Delete from 'pages/FormBuilder/Icons/Delete';
import {
  getQuoteFormCurrentQuestionSelect,
  getQuoteFormMetaSelect,
} from 'store/selectors/formBuilder';
import { updateQuoteFormMetaEffect } from 'store/effects/formBuilder';

import styles from './styles.module.scss';

export const QuoteDeleteQuestion = () => {
  const question = useSelector(getQuoteFormCurrentQuestionSelect);
  const { activeQuestionIndex, questions } = useSelector(getQuoteFormMetaSelect);

  const dispatch = useDispatch();

  const [showDelete, setShowDelete] = useState(false);

  const clearQuestionData = () => {
    if (activeQuestionIndex != undefined && questions?.length && [activeQuestionIndex]) {
      const id = questions[activeQuestionIndex].UUID;
      const updatedQuestions = questions.map((el) => {
        const updatedQuestion = { ...el };
        if (updatedQuestion?.JumpLogic) {
          for (const key of Object.keys(updatedQuestion.JumpLogic)) {
            if (updatedQuestion.JumpLogic[key] === id) delete updatedQuestion.JumpLogic[key];
          }
          if (!Object.keys(updatedQuestion.JumpLogic).length) {
            updatedQuestion.JumpLogic = undefined;
          }
        }
        return updatedQuestion;
      });

      pullAt(updatedQuestions, activeQuestionIndex);

      return updatedQuestions;
    }
    return null;
  };

  const handleDeleteForm = () => {
    const updatedQuestions = clearQuestionData();
    if (updatedQuestions) {
      dispatch(
        updateQuoteFormMetaEffect({
          questions: updatedQuestions,
          activeQuestionIndex: undefined,
        }),
      );
    }
  };
  return (
    <>
      {question && (
        <>
          <DeleteModal
            open={showDelete}
            entityName={question.Name}
            onOk={handleDeleteForm}
            onCancel={() => setShowDelete(false)}
            data-testid="quote-delete-modal"
          />
          <div
            data-testid="quote-delete-question"
            onClick={() => setShowDelete(true)}
            className={classNames('show-cursor ', 'prevent-user-select', styles.questionContainer)}
          >
            <Delete className={styles.questionIcon} />
            <span className={styles.questionText}>Remove Question</span>
          </div>
        </>
      )}
    </>
  );
};
