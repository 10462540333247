import { useEffect, MutableRefObject, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import { Drag } from 'components/Icons';

import styles from './styles.module.scss';

export const DraggableMenuList = ({
  items,
  onDragEnd,
  className,
  disabled,
  activeItem,
  onDraggableClick,
  listItemClass = '',
  dragIconClass = '',
}) => {
  useEffect(() => {
    if (activeItem && ref?.current) {
      const result = ref?.current.querySelectorAll(`[data-rbd-draggable-id="${activeItem}"]`);
      if (result?.length && result[0].scrollIntoView) {
        setTimeout(() => {
          result[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 500);
      }
    }
  }, [activeItem]);
  const ref: MutableRefObject<any> = useRef(null);
  return (
    <span ref={ref}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classNames(
                provided.droppableProps.style,
                className,
                styles.draggableContainer,
              )}
            >
              {items.map(({ key, content }, index) => (
                <Draggable
                  key={key}
                  draggableId={key}
                  index={index}
                  isDragDisabled={disabled}
                  className={styles.menuItem}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                      }}
                      className={classNames(
                        { [styles.activeItem]: key === activeItem },
                        listItemClass,
                      )}
                      onClick={(e) => onDraggableClick(index, key)}
                    >
                      <Drag
                        {...provided.dragHandleProps}
                        className={classNames(styles.dragIcon, dragIconClass)}
                      ></Drag>
                      {content}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </span>
  );
};
