import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { getDataFor } from 'store/helpers';
import { cloneDeep, get } from 'lodash-es';

import { requestGetServicesCategoriesAction } from 'store/actions/servicesCategories';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetServicesCategoriesAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
      meta: getDataFor('meta', payload, initialData),
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
