import { useEffect, useState } from 'react';
import { Checkbox, Input, Select } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const DropdownCheckbox = (props) => {
  const {
    options,
    value,
    inputText,
    checked,
    inputValue,
    selectedValue,
    className,
    onChangeCheckbox,
    onChangeInput,
    onChangeSelect,
  } = props;
  const [isEnable, setIsEnable] = useState(false);

  const onChange = (e: CheckboxChangeEvent) => {
    onChangeCheckbox(e.target.checked ? e.target.value : '');
    setIsEnable(e.target.checked);
  };

  useEffect(() => {
    setIsEnable(checked);
  }, []);

  return (
    <div className={styles.checkboxWrap}>
      <Checkbox
        className={classNames(styles.customCheckbox, className)}
        value={value}
        onChange={onChange}
        checked={checked}
      ></Checkbox>
      <div className={classNames(styles.inputWrap, { [styles.disabled]: !isEnable })}>
        <p>{inputText}</p>
        <Input
          placeholder="2"
          disabled={!isEnable}
          value={inputValue}
          onChange={(e) => onChangeInput(e, value)}
        />
        <div className={styles.customSelect}>
          <Select
            disabled={!isEnable}
            options={options || []}
            defaultValue={options[1].value}
            value={selectedValue}
            onChange={(selectedVal) => {
              onChangeSelect(selectedVal, value);
            }}
          />
        </div>
      </div>
    </div>
  );
};
