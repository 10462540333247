import { CardActions, TourSort, TourStatus } from 'app-constants/enums/tours';
import styles from './styles.module.scss';
import { Avatar, Dropdown } from 'components-antd';
import { copyTextToClipBoard, getHeaderText, getUser } from '../../helper';
import { Icons } from 'pages/Workshop/Tours/components/Icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Tour } from 'types/tours';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { useDispatch } from 'react-redux';
import { setDrawerClientProfileId, setProfileExpandedSection } from 'store/effects/app';
import ItineraryPDFDownloadLink from '../ItineraryPDF';

export const PanelHeader = (props) => {
  const { sort, groupedKey, onActionsClick, tours } = props;
  const [isDropdownOpen, setIsDropdowmOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const commonActions = ['MLS', 'Address'];
  const user = getUser(tours, sort);
  const actions = [
    {
      label: 'Multi-Select',
      icon: <Icons variant="multiple" />,
      key: 'Multiple',
      action: (e) => {
        e.stopPropagation();
        onActionsClick(CardActions.Multiple, groupedKey);
        handleCloseDropdown();
      },
    },
    {
      label: 'Copy MLS IDs',
      icon: <Icons variant="copy" />,
      key: 'MLS',
      action: (e) => {
        e.stopPropagation();
        copyTextToClipBoard(tours, CardActions.CopyMLS);
        handleCloseDropdown();
      },
    },
    {
      label: 'Copy Addresses',
      icon: <Icons variant="copy" />,
      key: 'Address',

      action: (e) => {
        e.stopPropagation();
        copyTextToClipBoard(tours, CardActions.CopyAddress);
        handleCloseDropdown();
      },
    },
    {
      label: 'Download Itinerary',
      icon: <Icons variant="download" />,
      key: 'Download',
      action: (e) => {
        e.stopPropagation();
        handleCloseDropdown();
      },
    },
  ];

  const getAvailableActions = () => {
    const tour: Tour = tours[0];
    const availableActions = commonActions;
    if (sort === TourSort.ClientName) {
      if (tour.Status === TourStatus.Requested) {
        availableActions.push('Multiple');
      }
      if (tour.Status === TourStatus.Approved && moment(tour.ScheduleDateTime) > moment()) {
        availableActions.push('Multiple', 'Download');
      }
    }
    return availableActions;
  };

  const renderAvatar = () => {
    if (sort !== TourSort.Date) {
      return (
        <Avatar className={styles.avatar} src={user?.AvatarUrl}>
          {convertNameToAvatarPlaceholder(`${user?.FirstName} ${user?.LastName}`)}
        </Avatar>
      );
    }
  };
  const availableActions = useMemo(() => getAvailableActions(), [sort]);

  const handleOpenDropdown = useCallback(() => setIsDropdowmOpen(true), []);
  const handleCloseDropdown = useCallback(() => setIsDropdowmOpen(false), []);

  return (
    <div className={styles.headerWrap}>
      <div
        className={styles.headerLeft}
        onClick={(e) => {
          if (sort == TourSort.ClientName) {
            dispatch(setProfileExpandedSection(true));
            dispatch(setDrawerClientProfileId({ id: tours[0]?.CreatedBy }));
          }
          e.stopPropagation();
        }}
      >
        {renderAvatar()}
        <p className={styles.title}>{getHeaderText(tours, sort)}</p>
        <p className={styles.tourCount}>{`(${tours.length})`}</p>
      </div>

      <div className={styles.headerRight}>
        <Dropdown
          onOpenChange={(state) => setIsDropdowmOpen(state)}
          overlay={
            <ul className={styles.actionsDropdown}>
              {actions
                .filter((t) => availableActions.includes(t.key))
                .map((item) =>
                  item.key === 'Download' ? (
                    <li
                      className={styles.actionItem}
                      key={item.key}
                      onClick={(e) => item.action(e)}
                    >
                      {item.icon}

                      <ItineraryPDFDownloadLink tours={tours} key={item.key}>
                        <span>{item.label}</span>
                      </ItineraryPDFDownloadLink>
                    </li>
                  ) : (
                    <li
                      className={styles.actionItem}
                      onClick={(e) => item.action(e)}
                      key={item.key}
                    >
                      {item.icon}
                      <span>{item.label}</span>
                    </li>
                  ),
                )}
            </ul>
          }
          getPopupContainer={(node) => node}
          overlayClassName={styles.actionsDropdown}
          trigger={['click']}
          placement="bottomRight"
          className={styles.actions}
          open={isDropdownOpen}
        >
          <a
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleOpenDropdown();
            }}
          >
            <Icons variant="dots" className={styles.icon} />
          </a>
        </Dropdown>
      </div>
    </div>
  );
};
