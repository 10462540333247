import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components-antd';

import styles from './styles.module.scss';

const Navigation = ({
  onNext,
  disabled,
  className,
  loading,
  size = 'medium',
  buttonText = 'Next',
}) => {
  return (
    <div className={classNames(styles.footer, className)}>
      <Button
        className={classNames(
          styles.nextBtn,
          disabled ? styles.nextBtnDisabled : styles.nextBtnEnabled,
          size === 'large' ? styles.largButton : '',
        )}
        onClick={onNext}
        disabled={disabled}
        loading={loading}
      >
        {buttonText}
      </Button>
    </div>
  );
};

Navigation.propTypes = {
  data: PropTypes.object,
  onNext: PropTypes.func,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  stageIndex: PropTypes.number,
  isLast: PropTypes.bool,
};

Navigation.defaultProps = {
  data: {},
  onNext: () => {},
  onClose: () => {},
  onFinish: () => {},
  stageIndex: 0,
  disabled: false,
  className: '',
  isLast: false,
};

export default Navigation;
