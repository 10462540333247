import React, { useState } from 'react';

import { DetailsContent } from './components/DetailsContent/DetailsContent';
import { Tabs } from 'components-antd';

import styles from './styles.module.scss';

enum DetailsTabsPanes {
  Transactions = 'Transactions',
  Projects = 'Projects',
}

export function Details() {
  const [tabKey, setTabKey] = useState<string>(DetailsTabsPanes.Transactions);

  const tabItems = [
    {
      key: DetailsTabsPanes.Transactions,
      label: DetailsTabsPanes.Transactions,
      children: <DetailsContent />,
    },
    {
      key: DetailsTabsPanes.Projects,
      label: DetailsTabsPanes.Projects,
      children: <DetailsContent isProject={true} />,
    },
  ];

  return (
    <div className={styles.detailsContainer}>
      <Tabs
        activeKey={tabKey}
        onTabClick={(key) => setTabKey(key)}
        defaultActiveKey={DetailsTabsPanes.Transactions}
        className={styles.detailsPanes}
        items={tabItems}
      />
    </div>
  );
}
