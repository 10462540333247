import { createSelector } from 'reselect';
import {
  getIsShareDrawerOpenSelector,
  getShareDrawerClientsGroupByNameSelector,
  getShareDrawerClientsSelector,
  getShareDrawerSearchClientsValueSelector,
  getRecipientsSelector,
  getComparesSelector,
  getSharePropertySelector,
} from 'store/selectors/feed';

export const getShareDrawerInfoSelector = createSelector(
  getIsShareDrawerOpenSelector,
  getShareDrawerClientsSelector,
  getShareDrawerClientsGroupByNameSelector,
  getShareDrawerSearchClientsValueSelector,
  getRecipientsSelector,
  getComparesSelector,
  getSharePropertySelector,
  (drawerOpened, clients, groupedClients, searchValue, recipients, compares, shareProperty) => ({
    drawerOpened,
    clients,
    groupedClients,
    searchValue,
    recipients,
    compares,
    shareProperty,
  }),
);
