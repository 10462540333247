import { Col, Row } from 'components-antd';
import styles from './styles.module.scss';
import { SmartFormIcons } from 'components';
import classNames from 'classnames';
import { FormPill } from 'pages/FormProcess/components/FormPill';

export const NavigationRow = (props) => {
  const { onPrevious, onCancel, hasPrevious, pdfName } = props;

  return (
    <Row justify="center" className={styles.navigationInfoRow}>
      <Col className={styles.sideControls}>
        {hasPrevious ? (
          <div className={styles.navigationIcons}>
            <div
              onClick={hasPrevious && onPrevious}
              className={classNames(styles.backNavigation, styles.navigationButton)}
            >
              <SmartFormIcons variant="back" />
            </div>
          </div>
        ) : (
          <></>
        )}
      </Col>

      <Col>
        <FormPill
          pdfName={pdfName}
          formPillClass={styles.formPill}
          pdfInfoClass={styles.preFormPdfInfo}
          pdfNameClass={styles.pdfNameInformation}
          editablePdfClass={styles.editablePdfInfo}
          showBundleIcon={true}
        />
      </Col>

      <Col className={classNames(styles.sideControls, styles.preFormControls)}>
        <div className={styles.navigationIcons}>
          <div
            className={classNames(styles.closeNavigation, styles.navigationButton)}
            onClick={onCancel}
          >
            <SmartFormIcons variant="cancel" />
          </div>
        </div>
      </Col>
    </Row>
  );
};
