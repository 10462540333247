import React from 'react';
import classNames from 'classnames';
import { Button, ButtonProps } from 'components-antd';

import styles from './styles.module.scss';
import { Spinner } from 'components';

export interface OnboardingSkipButtonProps extends ButtonProps {
  isPending?: boolean;
}

export const OnboardingSkipButton: React.FC<OnboardingSkipButtonProps> = (props) => {
  const children = props.isPending ? (
    <Spinner loaderClassName={styles.skipLoader} />
  ) : (
    props.title ?? 'Skip'
  );

  return (
    <Button
      {...props}
      className={classNames(styles.button, props.className)}
      variant="blank-hover-bordered"
    >
      {children}
    </Button>
  );
};
