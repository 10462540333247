import Api from 'store/effects/core/api';
import {
  createUpdateShowingAction,
  deleteShowingAction,
  sendAnnouncementAction,
} from 'store/actions/showings';
import { createUpdateShowing, deleteShowing, sendAnnouncement } from 'api/showings';

export const requestCreateUpdateShowingEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: createUpdateShowingAction,
    method: createUpdateShowing,
  });
  return sendRequest(cfg, options, cb);
};

export const deleteShowingEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: deleteShowingAction,
    method: deleteShowing,
  });
  return sendRequest(cfg, options, cb);
};

export const sendAnnouncementEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: sendAnnouncementAction,
    method: sendAnnouncement,
  });
  return sendRequest(cfg, options, cb);
};
