import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './styles.module.scss';
import { ClientAddress, ClientAddressEntity } from 'app-constants';
import { LocationResultType } from 'pages/Settings/SettingsFullProfile/EditMode/EditClientAddresses';
import { Button, Locations, Spinner } from 'components';
import { getLocationContext, getPlaceComponent } from 'helpers';
import { LOCALITY, POSTAL_CODE, ROUTE, STATE, STREET_NUMBER } from 'settings/constants/locations';
import { ConfettiIcon } from './ConfettiIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { userFullUpdateDataEffect } from 'store/effects';
import { ClientDashboardEligibility } from 'pages/Main/ClientDashboard/ClientDashboard';
import { getClientDashboardEligibility } from 'api/dashboard';
import { getCurrentContextSelector } from 'store/selectors/context';

type Props = {
  wrapperClassname?: string;
  error?: string;
  addresses: ClientAddress[];
  setShowAddAddressFlow: React.Dispatch<React.SetStateAction<boolean>>;
  setDashboardEligibility: Dispatch<SetStateAction<ClientDashboardEligibility>>;
};

const emptyLocation = {
  address: '',
  placeId: '',
  isPrimary: false,
  entityType: ClientAddressEntity.User,
};

export const AddAddress: React.FC<Props> = ({
  wrapperClassname,
  error,
  addresses,
  setShowAddAddressFlow,
  setDashboardEligibility,
}) => {
  const dispatch = useDispatch();
  const { isAgent, isThirdParty, isClient } = useSelector(getUserRolesMapSelector);
  const selectedContext = useSelector(getCurrentContextSelector);
  const clientId = useSelector(getUserId);
  const [locations, setLocations] = useState<ClientAddress[]>([emptyLocation]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateLocation = (
    placeId: string,
    isPrimary: boolean,
    result: LocationResultType,
    preparedData,
  ) => {
    const currentLocation = result?.result?.[0];
    let updatedLocation: any = { ...emptyLocation, isPrimary };
    if (
      currentLocation &&
      !locations.find((location) => location.placeId === currentLocation.place_id)
    ) {
      const updatedCity = getLocationContext(currentLocation, LOCALITY);
      const updatedState = getLocationContext(currentLocation, STATE);
      const updatedZip = getLocationContext(currentLocation, POSTAL_CODE);
      const street = getPlaceComponent(currentLocation, ROUTE);
      const streetNumber = getLocationContext(currentLocation, STREET_NUMBER);
      updatedLocation = {
        address: result?.placeName,
        placeId: currentLocation.place_id,
        isPrimary,
        entityType: ClientAddressEntity.User,
        addressParsed: {
          City: updatedCity,
          State: updatedState,
          Zip: updatedZip,
          Line1: [streetNumber, street?.name].filter((i) => !!i).join(' '),
          ProviderPlaceId: currentLocation.place_id,
          PlaceName: result?.placeName,
          Street: preparedData.length && preparedData[0].Street ? preparedData[0].Street : '',
        },
      };
    }
    setLocations((prevLocations) =>
      prevLocations.map((prevLocation) =>
        prevLocation.placeId === placeId ? updatedLocation : prevLocation,
      ),
    );
  };

  const addressDisabled = useMemo(() => !locations[0]?.placeId, [locations]);

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        userFullUpdateDataEffect(
          { isAgent, isThirdParty, isClient, values, isPartner: false },
          async (err) => {
            if (!err) {
              const stats = await getClientDashboardEligibility({
                clientId,
                contextId: selectedContext.ContextKey,
              });
              setDashboardEligibility(stats?.data);
              setIsLoading(false);
              setShowAddAddressFlow(false);
            }
            setIsLoading(false);
          },
        ),
      );
    },
    [dispatch, isAgent, isThirdParty, isClient],
  );

  const handleAddAddress = () => {
    setIsLoading(true);
    if (addressDisabled || isLoading) {
      return;
    }

    //combine addresses and locations while removing duplicates
    const updatedAddresses = [
      ...addresses,
      ...locations.filter(
        (location) => location.placeId && !addresses.find((a) => a.placeId === location.placeId),
      ),
    ];

    onSubmit({ address: updatedAddresses });
  };

  return (
    <div className={classNames(styles.addAddressFlowCard, wrapperClassname)}>
      <div>
        <ConfettiIcon />
      </div>
      <div className={styles.title}>
        Add an address to start tracking home values, neighborhood intel, etc.
      </div>
      <div className={styles.address}>
        {locations?.map((location, index) => (
          <Locations
            multiple={false}
            types={['address']}
            variant="full"
            placeholder="Enter address"
            onResult={(result, preparedData) => {
              return updateLocation(location.placeId, location.isPrimary, result, preparedData);
            }}
            value={
              location.address && location.placeId
                ? [
                    {
                      PlaceName: location.address,
                      ProviderPlaceId: location.placeId,
                    },
                  ]
                : undefined
            }
            error={error}
            valuesWrapperClassName={styles.inputFieldLocations}
            testid="address"
            placeholderClassName={styles.locationPlaceholder}
            showPrefixIcon={false}
            activeInputClassName={styles.activeInput}
            popupClassName={styles.popup}
            valuesContainerClassName={styles.valueWrapper}
          />
        ))}
      </div>
      <Button
        testid="continue"
        type="button"
        className={styles.continue}
        title="Add"
        disabled={isLoading || addressDisabled}
        onClick={handleAddAddress}
        isPending={isLoading}
      />
    </div>
  );
};
