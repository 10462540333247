import React from 'react';
import PropTypes from 'prop-types';

import { Close as CloseIcon } from 'components/Icons';

import styles from './styles.module.scss';

const Close = ({ onClose }) => (
  <button testid="modal_close" onClick={onClose} className={styles.close}>
    <CloseIcon className={styles.icon} />
  </button>
);

Close.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Close;
