import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AvatarPlaceholder from 'images/avatar-placeholder-2.png';

import styles from './styles.module.scss';

const Avatar = (props) => {
  const { className, avatarClassName, src, placeholder, onClick } = props;

  const getImage = () => {
    if (!src && placeholder) {
      return placeholder;
    }

    return <img src={src || AvatarPlaceholder} alt="" />;
  };

  return (
    <div className={classNames(styles.avatarWrapper, className)} onClick={onClick}>
      <div testid="avatar" className={classNames(styles.avatar, avatarClassName)}>
        {getImage()}
      </div>
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  avatarClassName: PropTypes.string,
  src: PropTypes.string,
  placeholder: PropTypes.node,
  onClick: PropTypes.func,
};

Avatar.defaultProps = {
  className: '',
  avatarClassName: '',
  src: undefined,
  placeholder: undefined,
  onClick: () => {},
};

export default Avatar;
