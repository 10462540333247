/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Wrapper as PendingWrapper } from 'components';
import { Checkmark, Close } from 'components/Icons';
import classNames from 'classnames';

import styles from './styles.module.scss';

const CheckboxComponent = (props) => {
  const {
    checked,
    onChange,
    disabled,
    label,
    direction,
    type,
    checkboxDomClassName,
    labelClassName,
    testid,
    hasOuterClick,
    checkboxColor,
    closeIcon,
  } = props;
  const { id, name, className, labelTextClassName, value, isPending, variant, strokeWidth } = props;
  const { checkboxWrapperClassName, checkboxClassName, checkmarkClassName } = props;

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => setIsChecked(checked), [checked, value, setIsChecked]);

  const onChangeHandler = useCallback(
    (event) => {
      const newIsChecked = !isChecked;
      onChange(event, event.target.value || value, newIsChecked);
    },
    [onChange, isChecked],
  );

  return (
    <div className={classNames(className)} onClick={hasOuterClick ? onChangeHandler : null}>
      <label
        testid={testid}
        className={classNames(styles.wrapper, styles[direction], labelClassName)}
      >
        {label && <span className={classNames(labelTextClassName)}>{label}</span>}
        <input
          id={id}
          name={name}
          className={classNames(styles.checkboxDom, checkboxDomClassName)}
          type={type}
          value={value}
          checked={isChecked}
          disabled={disabled}
          onChange={onChangeHandler}
        />
        <span
          className={classNames(
            styles.customCheckboxWrapper,
            styles[type],
            checkboxWrapperClassName,
          )}
        >
          <div
            className={classNames(styles.customCheckbox, checkboxClassName, {
              [styles[variant]]: !!variant,
            })}
          />
          <PendingWrapper
            className={styles.pendingWrapper}
            loaderClassName={classNames(styles.loader, { [styles.isChecked]: isChecked })}
            isPending={isPending}
          >
            <Checkmark
              className={classNames(styles.checkmark, checkmarkClassName)}
              color={checkboxColor}
              strokeWidth={strokeWidth}
            />
          </PendingWrapper>
        </span>
      </label>
      {closeIcon && (
        <Close className={classNames(styles.closeIcon, { [styles.isChecked]: isChecked })} />
      )}
    </div>
  );
};

CheckboxComponent.DIRECTION_LEFT = 'left';
CheckboxComponent.DIRECTION_RIGHT = 'right';

CheckboxComponent.DARK = 'dark';
CheckboxComponent.CONFETTI_BLUE = 'confettiBlue';

CheckboxComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  direction: PropTypes.string,
  className: PropTypes.string,
  checkmarkClassName: PropTypes.string,
  labelTextClassName: PropTypes.string,
  checkboxWrapperClassName: PropTypes.string,
  checkboxClassName: PropTypes.string,
  checkboxDomClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.string,
  testid: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isPending: PropTypes.bool,
  variant: PropTypes.string,
  strokeWidth: PropTypes.string,
  hasOuterClick: PropTypes.bool,
  checkboxColor: PropTypes.string,
  closeIcon: PropTypes.bool,
};

CheckboxComponent.defaultProps = {
  id: undefined,
  name: undefined,
  checked: false,
  onChange: () => {},
  disabled: false,
  label: '',
  direction: CheckboxComponent.DIRECTION_LEFT,
  className: '',
  checkmarkClassName: '',
  labelTextClassName: '',
  checkboxWrapperClassName: '',
  strokeWidth: '',
  checkboxDomClassName: '',
  checkboxClassName: '',
  labelClassName: '',
  type: 'checkbox',
  value: '',
  testid: undefined,
  isPending: false,
  variant: undefined,
  hasOuterClick: false,
  checkboxColor: '#FFFFFF',
  closeIcon: false,
};

export default CheckboxComponent;
