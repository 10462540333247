import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { setSearchDrawerCommuteIdEffect } from 'store/effects';
import { commuteType } from 'settings/constants/commuteType';

import Icon from 'pages/Properties/SearchResults/Icons';

import styles from './styles.module.scss';

const AddProximity = ({ className, isAnother }) => {
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(setSearchDrawerCommuteIdEffect(commuteType.NEW_PROXIMITY));
  };

  return (
    <div
      onClick={onClickHandler}
      testid="add_commute"
      className={classNames(styles.addCommute, className)}
    >
      <Icon className={styles.icon} variant={Icon.ADD} />
      <span className={styles.text}>Add {isAnother ? 'Another ' : ''}</span>
    </div>
  );
};

AddProximity.propTypes = {
  className: PropTypes.string,
};

AddProximity.defaultProps = {
  className: '',
};

export default AddProximity;
