import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Button } from 'components-antd';
import { featureFlags } from 'utils/featureFlags';
import { resetSearchCriteriaEffect, setSearchCriteriaEffect } from 'store/effects/search';
import { routes } from 'settings/navigation/routes';
import { Locations } from 'components';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { FilterDrawer } from 'pages/Properties/SearchResults/Drawers';
import { openSearchResultsFilterDrawerEffect } from 'store/effects';
import { getSearchCriteriaSelector } from 'store/selectors/search';
import Icon from 'pages/Properties/Feed/Icons';
import SearchIcon from './components/Icons/SearchIcon';
import classNames from 'classnames';
import { CREATE } from 'settings/constants/mode';
import { propertyStatus } from 'settings/constants/properties';
import { useSetSearch } from 'hooks';

import styles from './styles.module.scss';
import Gpt from './components/Gpt';

const Search = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const search = useSelector(getSearchCriteriaSelector);
  const isEdit = Object.keys(search).length;
  const [disabled, setDisabled] = useState(true);
  const [updateInput, setUpdateInput] = useState('');

  const { updateSearchValue } = useSetSearch('search');

  const onClickHandler = () => {
    dispatch(openSearchResultsFilterDrawerEffect({ open: true }));
  };

  const formik = useFormik({
    initialValues: Search.initialValues,
    onSubmit(values) {
      dispatch(openSearchResultsFilterDrawerEffect({ open: false, mode: CREATE }));
      const updatedSearch = {
        ...search,
        ...values,
        Status: search?.Status ?? [propertyStatus.active],
      };
      dispatch(setSearchCriteriaEffect(updatedSearch));
      updateSearchValue(updatedSearch);
      history.push(routes.searchResults + history.location.search);
    },
  });

  useEffect(() => {
    dispatch(resetSearchCriteriaEffect());
  }, []);

  const resetUpdate = () => {
    setUpdateInput('');
  };

  return (
    <div testid="search_page" className={styles.wrapper}>
      <div className={styles.newSearch}>
        <div className={styles.newSearchWrapper}>
          <div className={styles.newSearchTitle}>Start a new search</div>
          <form
            onSubmit={formik.handleSubmit}
            className={classNames({ [styles.agentSearchInput]: isAgent }, styles.newSearchInput)}
          >
            <div className={styles.locationContainer}>
              <Locations
                shouldFilterStateLevelResults
                searchIcon={true}
                className={styles.locations}
                activeInputIconClassName={styles.locationsIcon}
                valuesWrapperClassName={styles.locationsValues}
                dropdownClassName={styles.locationsDropdown}
                popupClassName={styles.locationsPopup}
                name="Locations"
                onResult={(result, preparedData) => {
                  setDisabled(!preparedData.length);
                  formik.setFieldValue('Locations', preparedData);
                }}
                placeholder="Enter a city, address, neighborhood, or ZIP"
                error={formik.touched.Locations ? formik.errors.Locations : ''}
                value={formik?.values?.Locations || []}
                testid="search_locations"
                variant={Locations.ROUND}
                updateInput={updateInput}
                resetUpdate={resetUpdate}
                allowedSmartyAddress={true}
              />
            </div>

            {isAgent ? (
              <Button
                variant="primary"
                className={classNames({ [styles.editBtn]: isEdit }, styles.criteriaBtn)}
                onClick={onClickHandler}
              >
                <Icon variant={Icon.FILTER} className={styles.filterIcon} />
                {isEdit ? 'Edit' : 'Add'} Criteria
              </Button>
            ) : null}

            {featureFlags?.gpt && <Gpt setUpdateInput={setUpdateInput} />}

            <button
              testid="search_button"
              className={classNames({ [styles.disabledBtn]: disabled }, styles.newSearchSubmit)}
              type="submit"
              disabled={disabled}
            >
              <SearchIcon className={styles.newSearchSubmitIcon} disabled={disabled} />
            </button>
          </form>
        </div>
      </div>
      {/* OLD LOGIC COMMENTED */}
      {isAgent && <FilterDrawer withBackground />}
      {/* <DefaultSearchFilterDrawer /> */}
      {/* {isAgent && <SearchAgentsBlock />} */}
      {/* {isThirdParty && <SearchThirdPartyBlock />} */}
      {/*{isClient && <SearchBuyersBlock />}*/}
    </div>
  );
};

Search.initialValues = {
  Locations: [],
};

export default Search;
