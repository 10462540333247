import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';

const Terms = ({ className }) => (
  <div testid="terms_text" className={classNames(styles.termsText, className)}>
    <span>By creating an account, you accept our </span>
    {/* TODO insert link to terms */}
    <Link testid="terms_of_service" className={styles.termsLink} to={routes.index}>
      Terms of Service
    </Link>
    <span> and </span>
    {/* TODO insert link to privacy policy */}
    <Link testid="privacy_policy" className={styles.termsLink} to={routes.index}>
      Privacy Policy
    </Link>
    <span>&#46;</span>
  </div>
);

Terms.propTypes = {
  className: PropTypes.string,
};

Terms.defaultProps = {
  className: '',
};

export default Terms;
