import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

const Remove = ({ className, onClick, color, testid }) => {
  const getIcon = useCallback(() => {
    switch (color) {
      case Remove.ORANGE_COLOR:
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20" cy="20" r="20" fill="#FF576D" />
            <path
              d="M10.0007 21.6654C9.08018 21.6654 8.33398 20.9192 8.33398 19.9987C8.33398 19.0782 9.08018 18.332 10.0007 18.332H30.0007C30.9212 18.332 31.6673 19.0782 31.6673 19.9987C31.6673 20.9192 30.9212 21.6654 30.0007 21.6654H10.0007Z"
              fill="#F6F7F7"
            />
          </svg>
        );
      case Remove.COLLAPSE:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path d="M11 16H21" stroke="#252D44" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      default: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM6 9C5.44772 9 5 9.4477 5 10C5 10.5523 5.44772 11 6 11H14C14.5523 11 15 10.5523 15 10C15 9.4477 14.5523 9 14 9H6Z"
              fill={color ?? '#163C5A'}
            />
          </svg>
        );
      }
    }
  }, [color]);

  return (
    <div testid={testid} className={className} onClick={onClick}>
      {getIcon()}
    </div>
  );
};

Remove.BLUE_COLOR = 'blue';
Remove.ORANGE_COLOR = 'orange';
Remove.COLLAPSE = 'collapse';

Remove.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  testid: PropTypes.string,
};

Remove.defaultProps = {
  className: '',
  onClick: () => {},
  color: Remove.BLUE_COLOR,
  testid: undefined,
};

export default Remove;
