export enum AgentDocumentTypes {
  Drafts = 'Drafts',
  PendingForms = 'Pending Forms',
  PreApprovals = 'Pre-Approvals',
  MessageAttachments = 'Message Attachments',
  Transactions = 'Transactions',
  Ungrouped = 'Ungrouped',
  ShowingAppointment = 'ShowingAppointment',
  OfferIteration = 'OfferIteration',
}

export interface AgentDocumentType {
  title: string;
  documentVaultUUID?: string;
  uploaderId?: number;
  id?: number;
  draftMeta?: {
    lastEdit: Date;
  };
  pendingMeta?: {
    isEdit?: boolean;
    isSign?: boolean;
    waitingOn?: string;
    formProcessId: number;
  };
  transactionMeta: {
    uploadedBy: string;
    uploadedAt: Date;
    transactionId: number;
  };
  preApprovalMeta: {
    uploadedBy: string;
    uploadedAt: Date;
  };
  messageAttachmentMeta: {
    uploadedBy: string;
    uploadedAt: Date;
    messageThreadId: number;
  };
  offerMeta: {
    uploadedBy: string;
    uploadedAt: Date;
    OfferId: number;
  };
  showingMeta: {
    uploadedBy: string;
    uploadedAt: Date;
    showingId: number;
  };
}

export type DocumentResponseType = AgentDocumentType[];

export interface TransactionDocumentResponseType {
  [propertyAddress: string]: AgentDocumentType[];
}

export type AgentDocumentResponse = TransactionDocumentResponseType | DocumentResponseType;

export interface AgentDocumentSearchCriteria {
  name?: string;
  dateRange?: {
    startDate: Date;
    endDate: Date;
  };
}

export interface DocumentOptionUtils {
  optionUtils: {
    previewDocument: (documentVaultUUID: string) => void;
    downloadDocument: (documentVaultUUID: string) => void;
    shareDocument: (documentVaultUUID: string) => void;
    previewDraftForm: () => void;
    handleOpenDeleteDocumentModal: (id: number | undefined, type: string) => void;
    openFormDetailsModal: (formProcessId: number) => void;
  };
}
