import AntMenu from 'antd/lib/menu';
import AntSubMenu from 'antd/lib/menu/SubMenu';
import { MenuItemType as AntMenuItemType } from 'antd/lib/menu/hooks/useItems';

export const Menu = AntMenu;

export const MenuItem = AntMenu.Item;

export type MenuItemType = AntMenuItemType;

export const SubMenu = AntSubMenu;
