import { AggregatedPageTypes } from 'types/aggregatedPageTypes';

export const getTitleByType = (type) => {
  if (type === AggregatedPageTypes.Transaction) return 'Transaction';
  else if (type === AggregatedPageTypes.Project) return 'Project';
  return 'Transaction/Project';
};

export const addProOrTransFilterParam = (aggregatedPageType, filters) => {
  if (aggregatedPageType === AggregatedPageTypes.Transaction) {
    return { ...filters, isProject: false };
  } else if (aggregatedPageType === AggregatedPageTypes.Project) {
    return { ...filters, isProject: true };
  } else {
    return { ...filters };
  }
};
