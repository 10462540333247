import classNames from 'classnames';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';

import styles from './styles.module.scss';

export const VirtualTourSelector = ({
  virtualTours: dropdownFields,
  virtualTourValue: value,
  setVirtualTourValue: setValue,
}) => {
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map(({ item, itemValue }, idx) => (
          <MenuItem key={idx}>
            <Radio value={itemValue} key={idx}>
              <div className={styles.radioContainer}>
                <div className={styles.option}>
                  <span className={styles.optionValue}>{item}</span>
                </div>
                {value === itemValue && <Check className={styles.checkIcon} />}
              </div>
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.filterWrap)}>
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.selectedValue}>
            {dropdownFields.find((field) => field.itemValue === value)?.item}
          </span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
        </a>
      </Dropdown>
    </div>
  );
};
