import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  getIsMessagesDrawerTypeSelector,
  getMessagesDrawerParams,
} from 'store/selectors/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';

import MessagesDrawerInitContent from './InitContent';
import MessagesDrawerClientContent from './ClientContent';
import MessagesDrawerChatContent from './ChatContent';
import MessagesDrawerChatNewMessageContent from './NewMessageContent';
import MessagesDrawerChatListingContent from './ListingContent';
import MessagesDrawerUnreadContent from './UnreadContent';
import MessagesDrawerArchivedContent from './ArchivedContent';
import MessagesDrawerServiceContent from './ServiceContent';
import MessagesDrawerTransactionContent from './TransactionContent';

const MessagesDrawerContent = () => {
  const type = useSelector(getIsMessagesDrawerTypeSelector);
  const params = useSelector(getMessagesDrawerParams);

  const [activeTabVal, setActiveTabVal] = useState({});

  const getActiveTab = useCallback((activeTabIndex, activeTabObj) => {
    setActiveTabVal({
      index: activeTabIndex,
      data: activeTabObj,
    });
  }, []);

  if (type === DRAWER_MESSAGES_TYPES.INIT) {
    return (
      <MessagesDrawerInitContent
        getActiveTab={getActiveTab}
        activeTab={activeTabVal}
        isPartnerGroup={params?.isPartnerGroup}
        threads={params?.threads}
      />
    );
  }

  if (type === DRAWER_MESSAGES_TYPES.UNREAD) {
    return <MessagesDrawerUnreadContent />;
  }

  if (type === DRAWER_MESSAGES_TYPES.CHAT) {
    return <MessagesDrawerChatContent />;
  }

  if (type === DRAWER_MESSAGES_TYPES.ARCHIVED) {
    return <MessagesDrawerArchivedContent />;
  }

  if (type === DRAWER_MESSAGES_TYPES.NEW_MESSAGE) {
    return <MessagesDrawerChatNewMessageContent activeTab={activeTabVal} />;
  }

  if (type === DRAWER_MESSAGES_TYPES.CLIENT) {
    return <MessagesDrawerClientContent isOffers isQuotes={false} archived={!!params?.archived} />;
  }

  if (type === DRAWER_MESSAGES_TYPES.TRANSACTION) {
    return <MessagesDrawerTransactionContent params={params} />;
  }

  if (type === DRAWER_MESSAGES_TYPES.PARTNER) {
    return <MessagesDrawerClientContent isQuotes isOffers={false} archived={!!params?.archived} />;
  }

  if (type === DRAWER_MESSAGES_TYPES.SERVICE) {
    return (
      <MessagesDrawerServiceContent
        isServices
        isQuotes={false}
        isOffers={false}
        archived={!!params?.archived}
      />
    );
  }

  if (type === DRAWER_MESSAGES_TYPES.LISTING) {
    return <MessagesDrawerChatListingContent />;
  }

  return null;
};

export default MessagesDrawerContent;
