import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomPanel } from './CustomPanel';
import { Collapse, Modal, Select, Tooltip } from 'components-antd';
import { Avatar, Minus, Plus, Info } from 'components/Icons';
import { Transaction } from 'components/Icons/Trancation';

import { getFormMetaSelector } from 'store/selectors/requestFormProcess';
import { linkToFormProcessEffect } from 'store/effects/formProcess';
import { caseInsensitiveFilter, showSuccessMessage } from 'helpers';

import { FieldContainer } from 'pages/FormProcess/components/PreForm/ClientOrTransaction/components/FieldContainer';
import {
  getTransactionOptions,
  getClientNamesFromClients,
  getClientOptions,
} from 'pages/FormProcess/components/PreForm/ClientOrTransaction';

import styles from './styles.module.scss';

interface MultiFormsUpdateModalProps {
  showFormUpdateModal: boolean;
  setShowFormUpdateModal: (showModal: boolean) => void;
  selectedFormIds: number[];
  selectedFormNames: string[];
  refetchForms: Function;
}

export const MultiFormsUpdateModal = ({
  showFormUpdateModal,
  setShowFormUpdateModal,
  selectedFormIds,
  selectedFormNames,
  refetchForms,
}: MultiFormsUpdateModalProps) => {
  const key1 = 'transaction';
  const key2 = 'client';
  const dispatch = useDispatch();
  const { clients, transactions } = useSelector(getFormMetaSelector);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState(null);
  const [clientIds, setClientsIds] = useState({
    clientIds: [],
    clientName: '',
  });

  useEffect(() => {
    setTransactionId(null);
    setClientsIds({
      clientIds: [],
      clientName: '',
    });
  }, [showFormUpdateModal]);

  const onUpdate = async () => {
    setUpdateLoading(true);
    dispatch(
      linkToFormProcessEffect(
        {
          formProcessIds: selectedFormIds,
          transactionId: transactionId,
          clientIds: clientIds?.clientIds ?? [],
        },
        (err) => {
          if (!err) {
            refetchForms();
            setShowFormUpdateModal(false);
            showSuccessMessage('Forms linked successfully.');
          }
          setUpdateLoading(false);
        },
      ),
    );
  };

  return (
    <>
      <Modal
        title={
          <div className={styles.multiFormTitle}>
            <p className={styles.title}>Link Forms</p>
            <div className={styles.subtitleContainer}>
              <span className={styles.subtitle}>{selectedFormNames?.length} Forms</span>
              <div
                className={styles.tooltipContainer}
                onMouseEnter={() => setOpenTooltip(true)}
                onMouseLeave={() => setOpenTooltip(false)}
              >
                <Tooltip
                  title={selectedFormNames?.map((item, idx) => (
                    <div key={idx} className={styles.formName}>
                      {item}
                    </div>
                  ))}
                  placement="bottom"
                  open={openTooltip}
                >
                  <Info />
                </Tooltip>
              </div>
            </div>
          </div>
        }
        open={showFormUpdateModal}
        onCancel={() => setShowFormUpdateModal(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={onUpdate}
        okButtonProps={{
          style: { width: 152, height: 52 },
          disabled: !transactionId && clientIds?.clientIds?.length === 0,
        }}
        okText="Save"
        className={styles.formUpdateModal}
        confirmLoading={updateLoading}
        closable={!updateLoading}
        maskClosable={false}
      >
        <Collapse
          ghost
          expandIcon={(panelProps) =>
            panelProps.isActive ? (
              <Minus className={styles.btnWrapper} />
            ) : (
              <Plus className={styles.btnWrapper} />
            )
          }
          expandIconPosition="end"
        >
          <CustomPanel
            key={key1}
            icon={
              <div className={styles.btnWrapper}>
                <Transaction />
              </div>
            }
            header="Transaction"
            title="Link all selected forms to:"
          >
            <FieldContainer>
              <Select
                value={transactionId}
                labelInValue
                onChange={(option) => {
                  setTransactionId(option.value);
                }}
                showSearch
                size="large"
                className={classNames(styles.transactionBoxInput)}
                options={getTransactionOptions(transactions)}
                filterOption={(inputValue, option) =>
                  caseInsensitiveFilter(inputValue, option?.props?.label)
                }
                placeholder="Select Transaction"
              />
            </FieldContainer>
          </CustomPanel>
          <CustomPanel
            key={key2}
            icon={<Avatar className={styles.btnWrapper} />}
            header="Client"
            title="Link all selected forms to:"
          >
            <FieldContainer>
              <Select
                mode="multiple"
                value={clientIds?.clientIds}
                onChange={(option) =>
                  setClientsIds({
                    clientIds: option,
                    clientName: getClientNamesFromClients(option, clients),
                  })
                }
                showSearch
                size="large"
                className={styles.clientBoxInput}
                options={getClientOptions(clients)}
                filterOption={(inputValue, option) =>
                  caseInsensitiveFilter(inputValue, option?.props?.label)
                }
                maxTagCount={'responsive'}
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                    <span>{`+${omittedValues.length}`}</span>
                  </Tooltip>
                )}
                placeholder="Select Client(s)"
              />
            </FieldContainer>
          </CustomPanel>
        </Collapse>
      </Modal>
    </>
  );
};
