import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep } from 'lodash-es';

import StagesControls from 'components/Stages/Controls';
import Progress from './Progress';

import styles from './styles.module.scss';

const Stages = (props) => {
  const {
    stages,
    additionalStages,
    startStageIndex,
    classNameProgress,
    classNameWrapper,
    showProgressBar = true,
    transaction,
    modalType,
    editModal,
    classNameProgressBackground,
    ...rest
  } = props;

  const [, setPrevIndxs] = useState([]);
  const [visitedStages, setVisitedStages] = useState([]);
  const [currentStageIndex, setCurrentStageIndex] = useState(startStageIndex);

  const setPreviousIndex = () => {
    setPrevIndxs((prevState) => {
      const copyPrevState = cloneDeep(prevState);
      return [...copyPrevState, currentStageIndex];
    });
  };

  const popPrevIndex = () => {
    setPrevIndxs((prevState) => {
      const copyPrevState = cloneDeep(prevState);
      const prevI = copyPrevState.pop();
      setCurrentStageIndex(prevI);
      return copyPrevState;
    });
  };

  const onNext = (id) => {
    if (id) {
      const nextIndex = stages.findIndex((stage) => stage.id === id);

      if (nextIndex !== -1) {
        setPreviousIndex();
        return setCurrentStageIndex(nextIndex);
      }
    }

    if (currentStageIndex < stages.length - 1) {
      setPreviousIndex();
      setCurrentStageIndex(currentStageIndex + 1);
    }

    if (!visitedStages.includes(stages[currentStageIndex]?.id)) {
      setVisitedStages((visStages) => [...visStages, stages[currentStageIndex]?.id]);
    }
  };

  const onPrev = (id) => {
    if (id) {
      const prevIndex = stages.findIndex((stage) => stage.id === id);

      if (prevIndex !== -1) {
        return setCurrentStageIndex(prevIndex);
      }
    }

    if (currentStageIndex > 0) {
      popPrevIndex();
    }

    if (!visitedStages.includes(stages[currentStageIndex]?.id)) {
      setVisitedStages((visStages) => [...visStages, stages[currentStageIndex]?.id]);
    }
  };

  const StageComponent = stages?.[currentStageIndex]?.Component;

  useEffect(() => {
    if (modalType === 'Showing' && transaction?.Showing?.Id) {
      setCurrentStageIndex(6);
    }
    if (modalType === 'Offer' && transaction?.OfferLink?.Id) {
      setCurrentStageIndex(stages.length - 1);
    }
  }, [modalType, transaction?.Showing?.Id, transaction?.OfferLink?.Id]);

  if (!StageComponent) return null;

  return (
    <div className={classNames(styles.stages, classNameWrapper)}>
      {showProgressBar && (
        <Progress
          className={classNames(styles.progress, classNameProgress)}
          classNameBackground={classNameProgressBackground}
          currentStageIndex={currentStageIndex}
          stages={stages}
          additionalStages={additionalStages}
          startQuestionForAdditioneewsIds={['stageApproved']}
        />
      )}

      <StageComponent
        onNext={onNext}
        onPrev={onPrev}
        stageIndex={currentStageIndex}
        lastStageIndex={stages.length - 1}
        visitedStages={visitedStages}
        setCurrentStageIndex={setCurrentStageIndex}
        editModal={editModal}
        Controls={({
          children,
          onNext: onNextProp,
          onPrev: onPrevProp,
          onClose: onCloseProp,
          isNextDisabled,
          isPrevDisabled,
          variant,
          ...restProps
        }) => {
          return (
            <StagesControls
              onNext={onNextProp || onNext}
              onPrev={onPrevProp || onPrev}
              onClose={onCloseProp}
              isNextDisabled={
                currentStageIndex === 8
                  ? false
                  : currentStageIndex < visitedStages.length - 1
                  ? false
                  : true
              }
              isPrevDisabled={
                typeof isPrevDisabled === 'boolean' ? isPrevDisabled : currentStageIndex <= 0
              }
              className={styles.controls}
              variant={variant}
              {...restProps}
            >
              {children}
            </StagesControls>
          );
        }}
        {...props}
      />
    </div>
  );
};

Stages.propTypes = {
  stages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])).isRequired,
  additionalStages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
  startStageIndex: PropTypes.number,
  classNameProgress: PropTypes.string,
  classNameWrapper: PropTypes.string,
  transaction: PropTypes.object,
  offerSubmission: PropTypes.object,
  participantList: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func,
  onFinish: PropTypes.func,
  onClose: PropTypes.func,
  data: PropTypes.object,
  fullAccess: PropTypes.bool,
  loading: PropTypes.bool,
  modalType: PropTypes.string,
  editModal: PropTypes.bool,
  showProgressBar: PropTypes.bool,
  classNameProgressBackground: PropTypes.string,
  stagesStep: PropTypes.object,
};

Stages.defaultProps = {
  startStageIndex: 0,
  additionalStages: [],
  classNameProgress: '',
  classNameWrapper: '',
  transaction: {},
  offerSubmission: {},
  modalType: '',
  editModal: false,
  participantList: [],
  onUpdate: () => {},
  onFinish: () => {},
  onClose: () => {},
  data: {},
  fullAccess: false,
  loading: false,
  stagesStep: {},
};

export default Stages;
