import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { dropConfetti } from 'utils';
import { PENDING } from 'settings/constants/apiState';
import { routes } from 'settings/navigation/routes';

import { Confetti } from 'components/Icons';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { Question } from 'pages/RequestQuote/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';

import styles from './styles.module.scss';

const ApproveComplete = (props) => {
  const { className, Controls } = props;
  const { onBoarding } = useSelector(getOnBoardingData);
  const history = useHistory();

  const isPending = onBoarding.state === PENDING;

  useEffect(() => {
    dropConfetti();
  }, []);

  return (
    <OnBoardingWrapper isPending={isPending} className={classNames(className)}>
      <OnBoardingContainer className={styles.container}>
        <Confetti className={styles.celebrate} />
        <Question className={styles.title}>
          <span>Your pre-approval request has been sent to the lenders you selected.</span>
          <span>They will contact you shortly.</span>
        </Question>
        <Question className={styles.title}>
          In the meantime, we have some great houses for you to check out in your feed.
        </Question>
        <ButtonsContainer>
          <OnboardingContinueButton
            testid="feed"
            type="button"
            onClick={() => history.push(routes.feed)}
            className={classNames(styles.submit, styles.celebrateSubmit)}
            titleClassName={styles.submitTitle}
            title="Take Me To My Feed"
            isPending={isPending}
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

ApproveComplete.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

ApproveComplete.defaultProps = {
  className: '',
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default ApproveComplete;
