import classNames from 'classnames';

import { DatePicker } from 'components';

import styles from './styles.module.scss';

export const DueDate = ({ className, values, onChangeField }) => {
  return (
    <div className={classNames(styles.dueDate, className)}>
      <div className={classNames(styles.fieldWrapper, styles.inputBlock)}>
        <DatePicker
          className={styles.dueDateWrapper}
          variant="light"
          options={{ enableTime: false }}
          value={values?.DueDate}
          onChange={(e, val) => onChangeField(val?.[0], 'DueDate')}
          testid="due_date"
          format="m/d/Y"
          placeholder="MM/DD/YYYY"
        />
      </div>
    </div>
  );
};
