/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { Button } from 'components';
import {
  ScrollDrawerWrapper,
  DrawerPaddingWrapper,
  Footer,
} from 'pages/Properties/Feed/components';

import { resetPropertiesWholeTabsEffect, openFeedFilterDrawerEffect } from 'store/effects';
import { useSelector, useDispatch } from 'react-redux';
import { getBuyerUserImportantFeaturesSelector } from 'store/selectors/user';
import { updateClientImportantFeaturesEffect } from 'store/effects/user';
import useIsDirty from 'hooks/use-is-dirty';
import { sortStrings } from 'helpers';

import MustItems from './Must';
import WantsItems from './Wants';
import NoPrefsItems from './NoPrefs';

import styles from './styles.module.scss';

const ImportantFeatures = (props) => {
  const { className } = props;

  const dispatch = useDispatch();
  const features = useSelector(getBuyerUserImportantFeaturesSelector);

  const [prefs, setPrefs] = useState(features);
  const [pending, setPending] = useState(false);
  const [isDirty, setIsDirty] = useIsDirty(prefs);

  const onChangePref = (id, prevImportance, currentImportance) => {
    if (prefs[currentImportance].includes(id)) return null;

    const newPrefs = {
      ...prefs,
      [prevImportance]: prefs[prevImportance].filter((item) => item !== id).sort(sortStrings),
      [currentImportance]: [...prefs[currentImportance], id].sort(sortStrings),
    };

    setPrefs(newPrefs);
  };

  const onSubmit = () => {
    setPending(true);
    dispatch(
      updateClientImportantFeaturesEffect(prefs, {}, (err) => {
        setPending(false);
        if (!err) {
          dispatch(resetPropertiesWholeTabsEffect());
          setIsDirty(false);
          dispatch(openFeedFilterDrawerEffect({ open: false }));
        }
      }),
    );
  };

  return (
    <div>
      <ScrollDrawerWrapper className={styles.scrollWrapper}>
        <DrawerPaddingWrapper className={classNames(className)}>
          <MustItems
            className={styles.block}
            listItems={prefs[SEARCH_CRITERIA_IMPORTANCE.MUST]}
            onChangePref={onChangePref}
          />
          <WantsItems
            className={styles.block}
            listItems={prefs[SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT]}
            onChangePref={onChangePref}
          />
          <NoPrefsItems
            className={styles.block}
            listItems={prefs[SEARCH_CRITERIA_IMPORTANCE.UNDEFINED]}
            onChangePref={onChangePref}
          />
        </DrawerPaddingWrapper>
      </ScrollDrawerWrapper>
      <Footer>
        <Button
          onClick={onSubmit}
          className={styles.submitButton}
          title="Done"
          isPending={pending}
          testid="done"
          disabled={!isDirty}
        />
      </Footer>
    </div>
  );
};

ImportantFeatures.propTypes = {
  className: PropTypes.string,
};

ImportantFeatures.defaultProps = {
  className: '',
};

export default ImportantFeatures;
