import { routes } from 'settings/navigation/routes';
import ClientKitsUnsubscribe from 'pages/Clarity/ClientKitsUnsubscribe';

export default [
  {
    path: routes.kitsUnsubscribe,
    component: ClientKitsUnsubscribe,
    exact: true,
  },
];
