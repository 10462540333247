import { SVGProps } from 'react';

export const EditPencil = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_21884_187126)">
      <path
        d="M2.66797 13.3332H5.33464L12.3346 6.33321C12.6883 5.97959 12.8869 5.49997 12.8869 4.99988C12.8869 4.49978 12.6883 4.02016 12.3346 3.66654C11.981 3.31292 11.5014 3.11426 11.0013 3.11426C10.5012 3.11426 10.0216 3.31292 9.66797 3.66654L2.66797 10.6665V13.3332Z"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 4.33301L11.6667 6.99967"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_21884_187126">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
