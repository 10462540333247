import { Button, TextArea } from 'components-antd';

import styles from './styles.module.scss';
import { useState } from 'react';
import classNames from 'classnames';

import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';

export const DeclineFormReason = (props) => {
  const { onClose } = props;

  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const handleDecline = () => {
    dynamicManager.handleDeclineDocument(reason, setLoading, onClose);
  };

  return (
    <div className={styles.declineFormReason}>
      <div>
        <div className={styles.label}>Provide a reason for declining (optional):</div>
        <TextArea
          rows={4}
          maxLength={250}
          className={styles.textArea}
          placeholder="Enter message..."
          onChange={(e) => setReason(e.target.value)}
          defaultValue={reason}
        />
      </div>

      <div className={styles.buttonContainer}>
        <Button
          variant="hollow-bordered"
          className={classNames(styles.button, styles.greyButton)}
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          variant="secondary"
          onClick={handleDecline}
          className={classNames(styles.button)}
          disabled={loading}
          loading={loading}
        >
          Decline
        </Button>
      </div>
    </div>
  );
};
