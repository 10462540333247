import PropTypes from 'prop-types';

const ArrowLeftRight = ({ className, size }) => (
  <div className={className}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5345_108107)">
        <path
          d="M21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12Z"
          stroke="#AAABAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ fill: '#fff' }}
        />
        <path
          d="M12 12C12 13.5621 12 14.1879 12 15.75"
          stroke="#AAABAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="12" cy="8.25" r="1.25" fill="#AAABAB" />
      </g>
      <defs>
        <clipPath id="clip0_5345_108107">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

ArrowLeftRight.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

ArrowLeftRight.defaultProps = {
  className: '',
  size: '24',
};

export default ArrowLeftRight;
