import { createSelector } from 'reselect';

import { PENDING, IDLE } from 'settings/constants/apiState';

const localState = ({ graphs }) => graphs;

export const getLoadingGraphsSelector = createSelector(localState, ({ state }) => ({
  state,
  isIdle: state === IDLE,
  isPending: state === PENDING,
}));

export const getAllAgentGraphsSelector = createSelector(localState, ({ agentData }) => agentData);

export const getAllTeamGraphsSelector = createSelector(localState, ({ teamData }) => teamData);

export const getFilteredGraphsSelector = (graphName: string, teamStats: boolean) =>
  teamStats
    ? createSelector(localState, ({ teamData }) => teamData?.[graphName])
    : createSelector(localState, ({ agentData }) => agentData?.[graphName]);

export const getUpdatedClientStateFlagSelector = createSelector(
  localState,
  ({ updatedClientStateFlag }) => updatedClientStateFlag.payload,
);
