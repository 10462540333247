import React, { useEffect, useState } from 'react';
import { PDFDocument, PDFField } from 'pdf-lib';
import { Button } from 'antd';
import { Row, Col } from 'antd';
import { PdfPreview } from './PdfPreview/PdfPreview';
import { useDummyFormFiller } from './useDummyFormFiller';

interface PdfParserProps {
  document: ArrayBuffer;
}

export const PdfParser: React.FC<PdfParserProps> = (props) => {
  const [pdfDoc, setPdfDoc] = useState<PDFDocument | null>(null);
  const [forcePreviewRenderTrigger, setForcePreviewRenderTrigger] = useState<{}>({});
  const [fieldToHighlight, setFieldToHighlight] = useState<string | null>(null);

  useEffect(() => {
    const runEffect = async () => {
      const loadedDoc = pdfDoc ?? (await PDFDocument.load(props.document));
      setPdfDoc(loadedDoc);
    };

    void runEffect();
  });

  const { fillForm } = useDummyFormFiller(pdfDoc);

  if (!pdfDoc) return null;

  const form = pdfDoc.getForm();
  const fields = form.getFields();

  const handleFillClick = () => {
    fillForm();
    setForcePreviewRenderTrigger({});
  };

  const handleFieldNameHover = (field: PDFField) => {
    setFieldToHighlight(field.getName());
  };

  const handleFieldNameLeave = () => {
    setFieldToHighlight(null);
  };

  return (
    <div>
      <div>
        <Button onClick={handleFillClick}>Fill dummy form values</Button>
      </div>
      {
        <Row style={{ display: 'flex', height: '500px' }}>
          <Col span={8} style={{ overflow: 'auto', height: '100%' }}>
            {fields.map((f) => (
              <div
                key={f.getName()}
                onMouseEnter={() => {
                  handleFieldNameHover(f);
                }}
                onMouseLeave={() => {
                  handleFieldNameLeave();
                }}
                style={{
                  borderBottom: '1px solid black',
                  padding: '10px 0',
                }}
              >
                <div>{f.getName()}</div>
                <div>{JSON.stringify(f.acroField.getWidgets()[0].getRectangle())}</div>
              </div>
            ))}
          </Col>
          <Col span={16} style={{ position: 'relative' }}>
            <PdfPreview
              pdfDoc={pdfDoc}
              forceRenderTrigger={forcePreviewRenderTrigger}
              fieldToHighlight={fieldToHighlight}
            />
          </Col>
        </Row>
      }
    </div>
  );
};
