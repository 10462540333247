import { DeleteModal, Dropdown, Menu } from 'components-antd';
import { Icons } from 'pages/FormProcess/Icons';
import styles from './styles.module.scss';
import { Fragment, useState } from 'react';
import { FormFilePreview } from 'pages/Workshop/Forms/components/NewFormModal/FormStages/components/FormFilePreview';

export const FormItemMenu = ({ document, hideDelete, onRemove }) => {
  const [open, setOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onPreview = (e) => {
    e.domEvent.stopPropagation();
    setOpen(false);
    setPreviewFile(document);
  };

  const onRename = () => {
    setOpen(false);
  };

  const onDelete = (e) => {
    e.domEvent.stopPropagation();
    setOpen(false);
    setConfirmationOpen(true);
  };

  const menu = (
    <Menu className={styles.formItemMenu} onClick={(e) => e.domEvent.stopPropagation()}>
      <Menu.Item className={styles.action} onClick={onPreview} icon={<Icons variant={'Preview'} />}>
        Preview
      </Menu.Item>
      {/* <Menu.Item className={styles.action} onClick={onRename} icon={<Icons variant={'Pencil'} />}>
        Rename
      </Menu.Item> */}
      {!hideDelete ? (
        <Menu.Item className={styles.action} onClick={onDelete} icon={<Icons variant={'Trash'} />}>
          Delete
        </Menu.Item>
      ) : (
        <></>
      )}
    </Menu>
  );

  return (
    <Fragment>
      <div onClick={(e) => e.stopPropagation()}>
        <DeleteModal
          open={confirmationOpen}
          onCancel={() => {
            setConfirmationOpen(false);
          }}
          entityName={`${document.Title} document`}
          closable={false}
          maskClosable={false}
          confirmLoading={deleteLoading}
          onOk={() => onRemove(setDeleteLoading, setConfirmationOpen)}
          width={450}
        />
      </div>

      {previewFile && (
        <FormFilePreview
          record={previewFile}
          onClosePreview={(e) => {
            e.stopPropagation();
            setPreviewFile('');
          }}
        />
      )}

      <Dropdown
        overlay={menu}
        className={styles.dropdown}
        placement={'bottomRight'}
        trigger={['click']}
        onOpenChange={(visible) => setOpen(visible)}
      >
        <Icons
          className={styles.menuIcon}
          variant={open ? 'MenuDotsBg' : 'Dots'}
          onClick={(e) => e.stopPropagation()}
        />
      </Dropdown>
    </Fragment>
  );
};
