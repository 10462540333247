import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, get } from 'lodash-es';
import PropTypes from 'prop-types';

import { PENDING } from 'settings/constants/apiState';
import { convertPrefs, prefsIds, PREF_TYPE } from 'settings/constants/preferences';

import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';

import Options from './Options';
import { SchoolsModal } from './Options/SchoolsModal';

import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Question } from 'pages/RequestQuote/components';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

import styles from './styles.module.scss';

const StageNeighbors = ({ onNext, Controls, stageIndex }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);

  const { neighborhoodPrefs, schoolPrefs } = OnBoardingWizard.propNames;

  const common = { obj: onBoarding, stageIndex, signUpRole };
  const storedNeighborhoodsValue =
    getWizardStageValue({ ...common, propName: neighborhoodPrefs }) || [];
  const { NeighborhoodPrefs: NeighborhoodPrefsDefault, Schools: schoolsPrefDefault } =
    defaultClientSearch || {};
  const [stageValue, setStageValue] = useState({});
  const [schools, setSchools] = useState({});

  useEffect(() => {
    const newStageValue = storedNeighborhoodsValue.reduce(
      (acc, { Preference }) => (Preference ? { ...acc, [Preference]: true } : acc),
      {},
    );
    const newStageValueDefault =
      NeighborhoodPrefsDefault &&
      NeighborhoodPrefsDefault.reduce(
        (acc, { Preference }) => (Preference ? { ...acc, [Preference]: true } : acc),
        {},
      );

    const storedSchools =
      getWizardStageValue({
        ...common,
        propName: schoolPrefs,
      }) || {};

    if (newStageValue && Object.keys(newStageValue).length > 0) {
      setStageValue(newStageValue);
    } else {
      if (newStageValueDefault && Object.keys(newStageValueDefault).length > 0) {
        setStageValue(newStageValueDefault);
      }
    }

    if (storedSchools && Object.keys(storedSchools)?.length) {
      setSchools(storedSchools);
    } else {
      if (schoolsPrefDefault && Object.keys(schoolsPrefDefault)?.length) {
        setSchools(schoolsPrefDefault);
      }
    }
  }, []); // eslint-disable-line

  const getPref = useCallback(
    (stage, prpName) =>
      get(
        stage,
        `meta.Values.[${signUpRole}].SearchInstance.DefaultPropertySearchPreferences.[${prpName}]`,
      ),
    [signUpRole],
  );

  const getPrevPreferences = useCallback(() => {
    const prevWizardStage = cloneDeep(onBoarding.wizard[stageIndex - 1]);
    return cloneDeep(
      Object.values({ [stageIndex - 1]: prevWizardStage }).filter(
        (stage) =>
          !!getPref(stage, PREF_TYPE.amenitiesPrefs) ||
          !!getPref(stage, prefsIds.parking) ||
          !!getPref(stage, PREF_TYPE.homePrefs),
      ),
    );
  }, [onBoarding?.wizard, getPref, stageIndex]);

  const getExtractedPrevPrefs = useCallback(
    () =>
      cloneDeep(
        getPrevPreferences().map(
          (preference) =>
            get(
              preference,
              `meta.Values.[${signUpRole}].SearchInstance.DefaultPropertySearchPreferences`,
            ),
          [signUpRole, getPrevPreferences],
        ),
      ),
    [getPrevPreferences, signUpRole],
  );

  const flatPrevPrefs = getExtractedPrevPrefs().reduce((acc, prefs) => ({ ...acc, ...prefs }), {});

  const onSave = () => {
    const newValue = convertPrefs(
      stageValue,
      OnBoardingWizard.importances.someWhat,
      'Preference',
      NeighborhoodPrefsDefault,
    );

    const cfg = {
      stageIndex,
      DefaultPropertySearchPreferences: {
        ...flatPrevPrefs,
        [neighborhoodPrefs]: newValue?.length ? newValue : undefined,
        ...(Object.keys(schools).length
          ? {
              [schoolPrefs]: schools,
            }
          : { [schoolPrefs]: undefined }),
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, {}, (err) => {
        if (!err) {
          if (stageValue && 'ShortCommute' in stageValue) {
            onNext();
          } else {
            onNext('stageMustHave');
          }
        }
      }),
    );
  };

  const isPending = onBoarding.state === PENDING;

  const onSaveSchoolsValue = (value) => {
    if (value === 0) {
      const newStageValue = stageValue.filter((o) => o !== schoolPrefs);
      setStageValue(newStageValue);
    }
    setSchools(value);
  };

  return (
    <OnBoardingWrapper testid="neigh_features_stage" isPending={isPending}>
      <Controls onNext={onSave} className={styles.controls} variant="lightFull" />
      <OnBoardingContainer>
        <Question className={styles.title}>What are you looking for in a neighborhood?</Question>
        <AnswersContainer className={styles.answersContainer}>
          <Options
            testid="neigh_option"
            onChange={setStageValue}
            value={stageValue}
            disabled={isPending}
          />
        </AnswersContainer>
        <ButtonsContainer>
          <OnboardingContinueButton
            testid="neigh_continue"
            onClick={onSave}
            isPending={isPending}
          />
        </ButtonsContainer>
      </OnBoardingContainer>
      <SchoolsModal onNext={(val) => onSaveSchoolsValue(val)} value={schools} />
    </OnBoardingWrapper>
  );
};

StageNeighbors.propTypes = {
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

StageNeighbors.defaultProps = {
  onNext: () => {},
  Controls: () => null,
  stageIndex: undefined,
};

export default StageNeighbors;
