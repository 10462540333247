import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Tasks, Task } from '..';

import styles from './styles.module.scss';

const TasksView = (props) => {
  const { className } = props;

  return (
    <div testid="transaction_tasks" className={classNames(styles.tasksView, className)}>
      <Tasks />
      <Task />
    </div>
  );
};

TasksView.propTypes = {
  className: PropTypes.string,
};

TasksView.defaultProps = {
  className: '',
};

export default TasksView;
