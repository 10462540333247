import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Skip = ({ className, onClick, text, testid }) => (
  <div testid={testid} onClick={onClick} className={classNames(styles.skip, className)}>
    <span className={styles.text}>{text || 'Skip question'}</span>
  </div>
);

Skip.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Skip.defaultProps = {
  className: '',
  text: undefined,
  testid: undefined,
  onClick: () => {},
};

export default Skip;
