import AntdSwitch, { SwitchProps } from 'antd/lib/switch';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const Switch = ({ className, ...props }: SwitchProps) => (
  <span className={classNames(styles.baseSwitch, className)}>
    <AntdSwitch {...props} />
  </span>
);
