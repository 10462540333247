import React from 'react';

import classNames from 'classnames';
import styles from './styles.module.scss';

export enum CardTypes {
  ORANGE = 'orange',
  LIGHT_GRAY = 'lightGray',
  CONFETTI_BLUE = 'confettiBlue',
  PURPLE = 'purple',
  LIGHT_BLUE = 'lightBlue',
}

type CardType = {
  children?: React.ReactNode;
  variant?: CardTypes | '';
  hideBoxShadow?: boolean;
};

export const Card = ({ children, variant = '', hideBoxShadow = false }: CardType) => {
  return (
    <div
      className={classNames(styles.cardContainer, styles[variant], {
        [styles.noBoxShadow]: hideBoxShadow,
      })}
    >
      {children}
    </div>
  );
};
