import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { InputLabel } from 'components';
import { Row, Col, Divider } from 'components-antd';
import { cloneDeep } from 'lodash-es';
import { Connection } from './Connection';
import { Users, Plus, Minus } from 'components/Icons';
import { AGENT } from 'settings/constants/roles';
import { getUserId } from 'store/selectors/user';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { getConnectionTypeSelector } from 'store/selectors/app';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';

import styles from './styles.module.scss';

export const ConnectionsList = (props) => {
  const { className, users, connections, onChange, label, connectionAssignType } = props;
  const connectionType = useSelector(getConnectionTypeSelector);
  const userId = useSelector(getUserId);
  const assignToUser: any = useSelector(getAgentDetailsSelector);
  const assignToUserId = assignToUser?.data?.Id;
  const usersWithoutMe = users.filter((user) => user.Id !== userId);
  const usersWithoutAssignToUser = users.filter((user) => user.Id !== assignToUserId);
  const invitedClientAssignToAgents =
    connectionType === AGENT_CONNECTIONS.Clients && connectionAssignType === AGENT;
  const allConnectionIds = users.map((connection) => connection?.Id);
  const [showEntireList, setShowEntireList] = useState(false);

  const onChangeHandler = (e, id, checked) => {
    e.stopPropagation();
    if (checked) {
      const newValue = [...(connections || []), id];
      onChange(e, newValue);
    } else {
      if (!connections) {
        const clonedAllConnectionIds = cloneDeep(allConnectionIds);
        const uncheckedConnectionIndex = clonedAllConnectionIds.findIndex(
          (connectionId) => connectionId === id,
        );
        clonedAllConnectionIds.splice(uncheckedConnectionIndex, 1);
        return onChange(e, clonedAllConnectionIds);
      }

      const clonedValue = cloneDeep(connections);
      const removedIndex = (connections || []).findIndex((val) => val === id);

      if (removedIndex !== -1 && clonedValue) {
        clonedValue.splice(removedIndex, 1);
        onChange(e, clonedValue);
      }
    }
  };

  const onEverybodyChange = (e, val, checked) => onChange(e, checked ? allConnectionIds : []);

  const getIsAllChecked = () =>
    (allConnectionIds || []).every((connection) => connections?.includes(connection));

  const renderMeConnection = () => {
    const me = users.find((user) => user.Id === userId);

    return (
      <div className={styles.meConnection}>
        <Connection
          key={me?.Id}
          connections={connections}
          value={me}
          onChange={(e, val, checked) => onChangeHandler(e, me?.Id, checked)}
          connectionAssignType={connectionAssignType}
        />
        <Divider />
      </div>
    );
  };

  const renderAssignClientToList = () => (
    <div className={styles.assignClientsList}>
      {renderMeConnection()}
      {usersWithoutMe?.length > 0 && (
        <Fragment>
          <Row className={styles.someoneElse}>
            <Col xs={20} sm={20} md={23} lg={23} xl={23}>
              <InputLabel
                label="Someone Else"
                altLabel={`(${usersWithoutMe.length})`}
                className={styles.title}
                altClassName={styles.count}
              />
            </Col>
            <Col xs={4} sm={4} md={1} lg={1} xl={1}>
              <div className={styles.iconContainer}>
                <button
                  className={styles.collapseBtn}
                  onClick={() => setShowEntireList(() => !showEntireList)}
                >
                  {showEntireList ? <Minus /> : <Plus />}
                </button>
              </div>
            </Col>
          </Row>
          {showEntireList ? (
            <div className={styles.entireList}>
              <Connection
                connections={connections}
                isChecked={getIsAllChecked()}
                onChange={onEverybodyChange}
                avatarPlaceholder={<Users />}
                label="All"
              />
              {usersWithoutMe.map((connection) => (
                <Connection
                  key={connection?.Id}
                  connections={connections}
                  value={connection}
                  onChange={(e, val, checked) => onChangeHandler(e, connection?.Id, checked)}
                  connectionAssignType={connectionAssignType}
                />
              ))}
            </div>
          ) : (
            <></>
          )}
        </Fragment>
      )}
    </div>
  );

  const renderManageAgentsList = () => (
    <>
      <Connection
        connections={connections}
        isChecked={getIsAllChecked()}
        onChange={onEverybodyChange}
        avatarPlaceholder={<Users />}
        label="All"
      />
      {usersWithoutAssignToUser.map((connection) => (
        <Connection
          key={connection?.Id}
          connections={connections}
          value={connection}
          onChange={(e, val, checked) => onChangeHandler(e, connection?.Id, checked)}
          connectionAssignType={connectionAssignType}
        />
      ))}
    </>
  );

  return (
    <div className={classNames(styles.connectionsList, className)}>
      {label ? <InputLabel className={styles.label} label={label} /> : null}
      {invitedClientAssignToAgents ? renderAssignClientToList() : renderManageAgentsList()}
    </div>
  );
};

ConnectionsList.propTypes = {
  className: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.shape({
        Id: PropTypes.number,
        FirstName: PropTypes.string,
        LastName: PropTypes.string,
        AvatarUrl: PropTypes.string,
      }),
    ),
  ),
  label: PropTypes.string,
  connections: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  connectionAssignType: PropTypes.string,
};

ConnectionsList.defaultProps = {
  className: '',
  label: '',
  connections: undefined,
  onChange: () => {},
  connectionAssignType: '',
};
