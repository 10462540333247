import { useEffect, useMemo, useState } from 'react';
import { map, trim } from 'lodash-es';
import { useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { getRecommendedByMosaikAndAgentLists } from 'store/selectors/partners';
import { getClientAgentId } from 'store/selectors/user';
import PartnerListItem from './PartnerListItem';
import { Row } from 'antd';
import { getCtaData } from 'api/kits';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { Button } from 'components-antd';
import { Avatar } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { link } from 'settings/navigation/link';

import styles from './styles.module.scss';

const OthersRolesPartnersList = ({ categoryId: serviceCategoryId }) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const partnersIdsParam = queryParams.get('partnersIds');
  const sourceParam = queryParams.get('source');
  const uuidParam = queryParams.get('uuid');
  const categoryIdParam = queryParams.get('categoryId');
  const { isClient } = useSelector(getUserRolesMapSelector);

  const categoryId = useMemo(() => {
    return serviceCategoryId || categoryIdParam;
  }, [categoryIdParam, serviceCategoryId]);

  const { recommendedByMosaik, recommendedByAgent } = useSelector(
    getRecommendedByMosaikAndAgentLists,
  );
  const agentId = useSelector(getClientAgentId);
  const [showAdditionalLenders, setShowAdditionalLenders] = useState(false);

  const redirectToQuotesForm = () => {
    if (sourceParam === 'kits' && uuidParam) {
      getCtaData(uuidParam);
    }
    if (partnersIdsParam && categoryId) {
      const recommendedByMosaikIds = recommendedByMosaik.length
        ? recommendedByMosaik.map((obj) => obj.Id)
        : [];
      const recommendedByAgentId = recommendedByAgent.length
        ? recommendedByAgent.map((obj) => obj.Id)
        : [];
      const allRecommendationsIds = recommendedByMosaikIds.concat(recommendedByAgentId);
      const targetRecommendationsIds = partnersIdsParam.split(',').map(Number);
      let validIds = targetRecommendationsIds.filter(
        (number) => !isNaN(number) && allRecommendationsIds.includes(number),
      );

      if (validIds.length) {
        const quoteFormRedirectUrl = `/services/category/${categoryId}/partner/${validIds.join()}/request-quote`;
        history.push(quoteFormRedirectUrl);
      }
    }
  };

  useEffect(() => {
    if (isClient) redirectToQuotesForm();
  }, []);

  const [enableSelection, setEnableSelection] = useState(false);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const handleSelectRequestQuoteClick = () => setEnableSelection((prev) => !prev);
  const onSelectPartnerFromClient = (partnerId) => {
    if (selectedPartnerId === partnerId) {
      setSelectedPartnerId(null);
      setEnableSelection(false);
    } else {
      setSelectedPartnerId(partnerId);
    }
  };
  const getIsPartnerCheckedFromClient = (partnerId) => {
    return selectedPartnerId === partnerId;
  };
  const getSelectedPartner = () => {
    return recommendedByAgent.find((partner) => partner.Id === selectedPartnerId);
  };
  const handleRequestQuoteClick = useEmailVerificationWall(
    ActionToInterrupt.REQUEST_QUOTE,
    () => {
      if (!selectedPartnerId) return;
      history.push(
        link.toServicesCategoryPartnerQuote({ partnerId: selectedPartnerId, categoryId }),
      );
    },
    {
      categoryId,
    },
  );
  return (
    <div className={styles.wrapper}>
      <div testid="agent_recommendations" className={styles.block}>
        <div className={styles.title}>Agent Recommendations</div>
        <Row gutter={[20, 20]}>
          {recommendedByAgent?.length ? (
            map(recommendedByAgent, (partner) => (
              <PartnerListItem
                agentId={agentId}
                partner={partner}
                key={partner?.Id}
                enableSelection={enableSelection}
                getIsPartnerCheckedFromClient={getIsPartnerCheckedFromClient}
                setSelectedPartnerId={setSelectedPartnerId}
                onSelectPartnerFromClient={onSelectPartnerFromClient}
              />
            ))
          ) : (
            <div testid="no_partners" className={styles.noPartners}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.2881 51.9894L32.0036 44.7783L45.7192 51.9894L43.0992 36.7161L54.1947 25.9005M20.9081 36.7161L9.8125 25.9005L25.1458 23.6739L32.0036 9.77832C34.6817 15.2049 36.1833 18.2473 38.8614 23.6739"
                  stroke="#E8E9EA"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.95081 48.6789L55.0622 12.8036"
                  stroke="#E8E9EA"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
              </svg>
              <span className={styles.noPartnersText}>There are no agent recommendations</span>
            </div>
          )}
        </Row>
      </div>
      <div className={styles.requestQuoteWrapper}>
        <div>
          {!enableSelection && (
            <Button
              variant="secondary"
              className={styles.actionBtn}
              onClick={handleSelectRequestQuoteClick}
            >
              Request Quote
            </Button>
          )}
          {enableSelection && (
            <>
              {selectedPartnerId && (
                <div className={styles.cardImage}>
                  <Avatar
                    src={getSelectedPartner()?.LogoUrl}
                    placeholder={convertNameToAvatarPlaceholder(
                      trim(`${getSelectedPartner()?.BusinessName}`),
                    )}
                    avatarClassName={styles.selectedAvatar}
                  />
                </div>
              )}
              <div className={styles.flex}>
                <Button
                  variant="secondary"
                  className={styles.actionBtn}
                  onClick={handleRequestQuoteClick}
                >
                  Continue
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      {showAdditionalLenders && (
        <div testid="additional_partners" className={styles.block}>
          <div className={styles.title}>Additional Lenders</div>
          <Row gutter={[20, 20]}>
            {map(recommendedByMosaik, (partner) => (
              <PartnerListItem partner={partner} key={partner?.Id} />
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default OthersRolesPartnersList;
