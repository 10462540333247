import React, { useState, useEffect } from 'react';
import { getAllPartnerTags } from 'api/admin';
import SettingsPartnerViewMode from './ViewMode';
import SettingsPartnerEditMode from './EditMode';
import { getUserDataSelector } from 'store/selectors/user';
import { useSelector } from 'react-redux';

const SettingsPartner = ({ tagsError, tagsData, tagsLoading }) => {
  const [isEditMode, setEditMode] = useState(false);
  const changeEditMode = () => setEditMode((val) => !val);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [serviceTags, setServiceTags] = useState([]);
  const user = useSelector(getUserDataSelector);

  const fetchPartnerTags = async () => {
    try {
      const response = await getAllPartnerTags({ active: true });
      if (response.status === 200 && response.data?.result && response.data.result.length) {
        let serviceTagsResult = [];
        for (let i = 0; i < response.data.result.length; i++) {
          serviceTagsResult.push({
            value: response.data.result[i].Id,
            name: response.data.result[i].Name,
          });
        }
        setServiceTags(serviceTagsResult);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      fetchPartnerTags();
    }
    return () => {};
  }, [loading]);

  return (
    <>
      {isEditMode ? (
        <SettingsPartnerEditMode
          changeEditMode={changeEditMode}
          tagsError={error}
          tagsData={serviceTags}
          tagsLoading={loading}
          serviceTags={
            user &&
            user.ThirdParty &&
            user.ThirdParty.Partner &&
            user.ThirdParty.Partner.PartnerTag &&
            user.ThirdParty.Partner.PartnerTag
              ? user.ThirdParty.Partner.PartnerTag
              : []
          }
        />
      ) : (
        <SettingsPartnerViewMode
          changeEditMode={changeEditMode}
          serviceTags={
            user &&
            user.ThirdParty &&
            user.ThirdParty.Partner &&
            user.ThirdParty.Partner.PartnerTag &&
            user.ThirdParty.Partner.PartnerTag
              ? user.ThirdParty.Partner.PartnerTag
              : []
          }
        />
      )}
    </>
  );
};

export default SettingsPartner;
