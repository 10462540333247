import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import {
  onBoardingAction,
  onBoardingSignUpAction,
  onBoardingWizardAction,
} from 'store/actions/onBoarding';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';

const initialData = IDLE;

export default handleActions(
  {
    [onBoardingAction]: (state, { payload }) => payload.state,
    [onBoardingSignUpAction]: (state, { payload }) => payload.state,
    [onBoardingWizardAction]: (state, { payload }) => payload.state,
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
