// eslint-disable-next-line
import { apiServer } from 'settings/web-services/api';

export function getAllTimelineTemplates(cfg) {
  return apiServer.get('/transaction/templates/timeline-templates', { params: cfg });
}

export function getTimelineTemplateById({ id }) {
  return apiServer.get(`/transaction/templates/timeline-templates/${id}`);
}

export function postTimelineTemplate(cfg) {
  return apiServer.post('/transaction/templates/timeline-templates', cfg);
}

export function putTimelineTemplate(cfg, { id }) {
  return apiServer.put(`/transaction/templates/timeline-templates/${id}`, cfg);
}

export function deleteTimelineTemplate({ id }) {
  return apiServer.delete(`/transaction/templates/timeline-templates/${id}`);
}
