import { MosaikResponse } from 'api/types';
import { apiServer } from 'settings/web-services/api';

export function getAddressAutocomplete(data): Promise<MosaikResponse<AddressSuggestions[]>> {
  return apiServer.post(`/listings/address-autocomplete`, { address: data });
}

export function getAddressVerification(data): Promise<MosaikResponse<AddressVerification>> {
  return apiServer.post(`/listings/address-verification`, {
    address: `${data?.street_line} ${data?.secondary} ${data?.city}, ${data?.state} ${data?.zipcode}`,
    city: data?.city,
    state: data?.state,
    zip: data?.zipcode,
    street: data?.street_line,
  });
}

interface AddressSuggestions {
  street_line: string;
  secondary: string;
  state: string;
  zipcode: string;
  entries: number;
}

interface AddressVerification {
  input_index: number;
  candidate_index: number;
  delivery_line_1: string;
  last_line: string;
  delivery_point_barcode: string;
  components: {
    primary_number: string;
    street_name: string;
    city_name: string;
    default_city_name: string;
    state_abbreviation: string;
    zipcode: string;
    plus4_code: string;
    delivery_point: string;
    delivery_point_check_digit: string;
    urbanization: string;
  };
  metadata: {
    record_type: string;
    zip_type: string;
    county_fips: string;
    county_name: string;
    carrier_route: string;
    congressional_district: string;
    rdi: string;
    elot_sequence: string;
    elot_sort: string;
    latitude: number;
    longitude: number;
    precision: string;
    time_zone: string;
    utc_offset: number;
  };
  analysis: {
    dpv_match_code: string;
    dpv_footnotes: string;
    dpv_cmra: string;
    dpv_vacant: string;
    dpv_no_stat: string;
    active: string;
    footnotes: string;
  };
}
