import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Continue } from '../../Continue';
import { DatePicker } from 'components';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';

import { useMemo } from 'react';

import styles from './styles.module.scss';

const ClosingDate = (props) => {
  const {
    className,
    onNext,
    currentQuestionId,
    setStage,
    validateWithEffective = false,
    heading = 'What is the closing date?',
  } = props;
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);

  const onAnswerHandler = (answer) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.closingDate]: answer || undefined,
      }),
    );
  };

  const onNextHandler = (event) => {
    if (setStage) {
      setStage(transactionPreFormQuestionsIds.financingType);
    }
    if (currentQuestionId === transactionPreFormQuestionsIds.closingDate) {
      onNext(event);
    }
  };

  const minDate = useMemo(
    () =>
      validateWithEffective
        ? preForm?.[transactionPreFormQuestionsIds.effectiveDate] ||
          moment().startOf('day').toDate()
        : moment().startOf('day').toDate(),
    [validateWithEffective, preForm?.[transactionPreFormQuestionsIds.effectiveDate]],
  );

  const isDisableContinue = () => {
    const closingDate = preForm?.[transactionPreFormQuestionsIds.closingDate];
    const effectiveDate = preForm?.[transactionPreFormQuestionsIds.effectiveDate];

    return !closingDate || moment(closingDate).isBefore(effectiveDate, 'day');
  };

  return (
    <div testid="what_closing_date" className={classNames(styles.container, className)}>
      <Question>{heading}</Question>
      <AnswersContainer>
        <DatePicker
          variant={DatePicker.ROUNDED}
          placeholder="MM/DD/YY"
          onChange={(e, val) => onAnswerHandler(val?.[0])}
          value={preForm[transactionPreFormQuestionsIds.closingDate]}
          className={styles.dateInput}
          options={{ enableTime: false, minDate }}
          testid="closing_date"
        />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onNextHandler} disabled={isDisableContinue()} />
      </ButtonsContainer>
    </div>
  );
};

ClosingDate.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  setStage: PropTypes.func,
  currentQuestionId: PropTypes.string,
  validateWithEffective: PropTypes.bool,
};

ClosingDate.defaultProps = {
  className: '',
  onNext: () => {},
  currentQuestionId: null,
  validateWithEffective: false,
};

export default ClosingDate;
