import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { cloneDeep } from 'lodash-es';

import { IDLE } from 'settings/constants/apiState';
import {
  requestGetMilestoneAggregate,
  requestUpdateMilestoneAggregate,
  setMilestonesFiltersAction,
  setMilestonesArchiveAction,
} from 'store/actions/milestoneAggregate';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: [],
  meta: {},
  filters: {},
  isFiltersApplied: false,
  isArchive: false,
  search: null,
};

export default handleActions(
  {
    [requestGetMilestoneAggregate as any]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data.result', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },

    [requestUpdateMilestoneAggregate as any]: (state, { payload }) => {
      const data: any = cloneDeep(state.data);
      const payloadData: any = payload;
      const index = data.findIndex((x) => x.Id == payloadData.milestoneId);
      if (index !== -1) {
        data[index].Status = payloadData.status;
      }
      return {
        ...state,
        data: data,
      };
    },
    [setMilestonesFiltersAction as any]: (state, { payload }) => {
      const newState = {
        ...state,
      };

      if (payload?.isFiltersApplied !== undefined) {
        newState['isFiltersApplied'] = !!payload.isFiltersApplied;
      }

      if (payload?.search !== undefined) {
        newState['search'] = payload.search;
      }

      return newState;
    },
    [setMilestonesArchiveAction as any]: (state, { payload }) => {
      return {
        ...state,
        isArchive: !!payload,
      };
    },

    [appLogoutAction as any]: () => initialData,
  },
  initialData,
);
