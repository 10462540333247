import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MatchNone = memo(({ className }) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.5 16.5L16.5 7.5" stroke="#AAABAB" strokeWidth="2" strokeLinecap="round" />
        <path d="M16.5 16.5L7.5 7.5" stroke="#AAABAB" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </div>
  );
});

MatchNone.propTypes = {
  className: PropTypes.string,
};

MatchNone.defaultProps = {
  className: '',
};

export default MatchNone;
