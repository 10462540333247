/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Label = (props) => {
  const { label, altLabel, className, altLabelClassName, showAsterisk } = props;

  if (!label) return null;

  return (
    <label className={classNames(styles.label, className)}>
      <span>
        {label}
        {showAsterisk ? <span className={styles.required}>*</span> : ''}
      </span>
      {altLabel && (
        <span className={classNames(styles.altLabel, altLabelClassName)}>{altLabel}</span>
      )}
    </label>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  altLabelClassName: PropTypes.string,
  altLabel: PropTypes.string,
  label: PropTypes.string,
  showAsterisk: PropTypes.bool,
};

Label.defaultProps = {
  className: '',
  altLabelClassName: '',
  altLabel: '',
  label: '',
  showAsterisk: false,
};

export default Label;
