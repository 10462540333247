import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getUserId } from 'store/selectors/user';
import { convertNameToAvatarPlaceholder, getIfExistThread } from 'helpers';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { link } from 'settings/navigation/link';

import { getThreadsListSelector } from 'store/selectors/sockets/threads';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import BaseNotification from '../../../BaseNotification';

import styles from './styles.module.scss';

const TourCancelledClient = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  let { ClientNames, ClientAvatarUrl } = Payload || {};

  if (ClientNames.split(',').length > 1) {
    ClientAvatarUrl = '/images/Calendar.svg';
  }
  const onClickHandler = () => {
    dispatch(openNotificationsDrawerEffect({ open: false }));
    history.replace(link.toToursDashboard());
  };

  return (
    <div className={classNames(styles.notificationType, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        groupType={groupType}
        onClick={onClickHandler}
        avatarUrl={ClientAvatarUrl}
        avatarPlaceholder={convertNameToAvatarPlaceholder(ClientNames)}
        severityLevel={SeverityLevel}
      />
    </div>
  );
};

export default TourCancelledClient;
