import { TransactionUserRoleMap } from 'settings/constants/transaction';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';

export const isPropertyAddress = (address) => {
  const { City, State, Line1, Zip } = address || {};
  return !!City && !!State && !!Line1 && !!Zip;
};

export const getRepresentValueFor = (answers, role) =>
  answers?.[transactionPreFormQuestionsIds.whoAreYouRepresenting]?.[role];

export const validateParticipantCustomNameWithEmail = (participant) =>
  participant?.invite
    ? participant.tagRole &&
      ((!participant.customName && (participant.name || participant.firstName)) ||
        (participant.customName && participant.customEmail))
    : true;

export const validateParticipantCustomNameWithEmailMandatory = (participant) =>
  participant?.customName ? participant?.customEmail : true;

export const setOptionalEmail = (participant) => (participant?.invite ? false : true);

export const validateParticipantValues = (participants) => {
  return participants?.every(
    (participant) => !participant?.error && validateParticipantCustomNameWithEmail(participant),
  );
};

export const validateParticipant = (participants) => {
  const oneParticipantOnly = (participant) => {
    if (participants.length === 1) {
      if ((participant.customName || participant.name) && !participant.tagRole) return false;
    }
    return true;
  };

  return participants?.every(
    (participant) =>
      !participant?.error &&
      !participant?.phoneNoError &&
      validateParticipantCustomNameWithEmail(participant) &&
      (participants.length > 1
        ? (participant.customName || participant.name) && participant.tagRole
        : true) &&
      oneParticipantOnly(participant),
  );
};

export const validateParticipantWithEmailMandatory = (participants) => {
  return participants?.every(
    (participant) =>
      !participant?.error &&
      !participant?.phoneNoError &&
      validateParticipantCustomNameWithEmailMandatory(participant) &&
      (participant.customName || participant.name) &&
      participant.tagRole,
  );
};

export const processOption = (p) => ({
  id: p.Id || p.id,
  name: `${p?.FirstName || p?.firstName} ${p?.LastName || p?.lastName}$/$${p?.Email || p?.email}`,
  value: p.Id || p?.id,
  role: p?.Role?.Title || p?.tagRole,
  avatarUrl: p.AvatarUrl || p?.avatarUrl,
  firstName: p.FirstName || p?.firstName,
  lastName: p.LastName || p?.lastName,
  email: p?.Email || p?.email,
});

export const getAssignees = (template, type, participantOptions) => {
  const assignees = [];
  const roles = template?.[type] ?? [];

  roles?.forEach((role) =>
    participantOptions?.forEach((p) => {
      const user = processOption(p);
      if (user.role === role || user.role === TransactionUserRoleMap[role]) assignees.push(user);
    }),
  );

  return assignees;
};

export const validateFullAccess = (participantsValues) =>
  participantsValues.every((participant) => !participant.transactionAccess || participant.invite);
