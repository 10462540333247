import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MatchFull = memo(({ className }) => {
  return (
    <div className={className}>
      <svg
        width="19"
        height="10"
        viewBox="0 0 19 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 5L10 9L18 1"
          stroke="#04A451"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 5L5 9L13 1"
          stroke="#04A451"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
});

MatchFull.propTypes = {
  className: PropTypes.string,
};

MatchFull.defaultProps = {
  className: '',
};

export default MatchFull;
