import { createAction } from 'redux-actions';

export const deleteSearchInstanceDrawerAction = createAction(
  'DRAWER_SEARCHES/DELETE_SEARCH_INSTANCE',
);
export const requestDeleteSearchInstanceDrawerAction = createAction(
  'REQUEST/DELETE_DRAWER_SEARCH_INSTANCE',
);
export const setRenameSearchInstanceDrawerAction = createAction(
  'DRAWER_SEARCHES/RENAME_SEARCH_INSTANCE',
);
export const requestRenameSearchInstanceDrawerAction = createAction(
  'REQUEST/RENAME_DRAWER_SEARCH_INSTANCE',
);
export const setStatusSearchInstanceDrawerAction = createAction(
  'DRAWER_SEARCHES/INACTIVE_SEARCH_INSTANCE',
);
export const requestStatusSearchInstanceDrawerAction = createAction(
  'REQUEST/INACTIVE_DRAWER_SEARCH_INSTANCE',
);
