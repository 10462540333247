import { Fragment, useState } from 'react';

import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import { Button } from 'components-antd';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { DeclineFormModal } from './DeclineFormModal';

export const DeclineButton = () => {
  const [openDecline, setDecline] = useState(false);

  return (
    <div className={styles.declinedButtonContainer}>
      <Button
        variant="blank-hover-bordered"
        className={classNames(styles.declineButton)}
        onClick={() => setDecline(true)}
      >
        <Icons variant={ICON_VARIANT_TYPE.Cross} className={styles.svgMessage} /> Decline
      </Button>

      <DeclineFormModal open={openDecline} onClose={() => setDecline(false)} />
    </div>
  );
};
