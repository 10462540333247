import Spin from 'antd/lib/spin';
import Select, { RefSelectProps, SelectProps } from 'antd/lib/select';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';
import { SelectOption } from 'types';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  ref?: React.Ref<RefSelectProps> | undefined;
}

function AsyncSelect<ValueType extends SelectOption = SelectOption>(
  { fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>,
  ref,
) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      dropdownStyle={{ zIndex: 10000 }}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      ref={ref}
    />
  );
}

export default React.forwardRef(AsyncSelect);
