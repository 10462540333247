import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  PlanTeamDetails,
  getLinkToPaymentMethodManagement,
  getLinkToSubscriptionManagement,
} from 'api/subscription';
import { Button, Popover } from 'components-antd';
import { PaymentHistory, PaymentMethod, Plus } from 'components/Icons';
import { showErrorMessage } from 'helpers';
import {
  reactivateSubscriptionEffect,
  requestActiveSubscriptionTeamDataEffect,
  requestGetInvoiceDataOverviewEffect,
} from 'store/effects/subscription';
import {
  getActiveSubscriptionTeamDataSelector,
  getInvoiceDataOverviewSelector,
  getLegacyPlanInterval,
  invoiceDataOverviewLoading,
} from 'store/selectors/subscription';
import { getActiveSubscriptionData, getAgentTypeSelector } from 'store/selectors/user';
import { usePaymentCallbackHandler } from './usePaymentCallback';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { AGENT_CONNECTIONS, AGENT_TYPE } from 'settings/constants/drawers';
import { routes } from 'settings/navigation/routes';
import { appManageClientDrawerAction, appSetShowInviteTeamMemberAction } from 'store/actions/app';
import { appSetConnectionTypeEffect, userGetDataOnBackgroundEffect } from 'store/effects';
import { CheckmarkIcon, SeatsIcon } from './icons';
import { DowngradeModal, LegacyModalChange } from './Modals';
import { BillingHistoryModal } from './Modals/BillingHistoryModal/BillingHistoryModal';
import { BillingHistoryTable } from './Modals/BillingHistoryTable';
import { CancelSubscriptionModal } from './Modals/CancelSubscriptionModal';
import { UpgradeModal } from './Modals/UpgradeModal';
import styles from './styles.module.scss';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_OWNER } from 'settings/constants/roles';

interface SeatsCountPopoverContentProps {
  teamPlanData: PlanTeamDetails;
}

const SeatsCountPopoverContent: React.FC<SeatsCountPopoverContentProps> = ({ teamPlanData }) => {
  const renderTitleAndValue = (title: string, value: number) => (
    <div className={styles.seatsCount__titleAndValue}>
      <span>{title}</span>
      <span>{value}</span>
    </div>
  );

  return (
    <div className={styles.seatsCount}>
      {renderTitleAndValue('Available Seats:', teamPlanData.BilledSeatsCount)}
      {teamPlanData.UnusedSeatsCount ? (
        <>
          <div className={styles.separator} />
          {renderTitleAndValue(
            'Filled Seats:',
            teamPlanData.BilledSeatsCount - teamPlanData.UnusedSeatsCount,
          )}
        </>
      ) : null}
      <div className={styles.seatsCount__disclaimer}>
        You can see a list of team members with seats in your Contacts or in Symphony
      </div>
    </div>
  );
};

const Subscription: React.FC = () => {
  usePaymentCallbackHandler();

  const history = useHistory();
  const dispatch = useDispatch();
  const agentType = useSelector(getAgentTypeSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  // const [teamPlanData, setTeamPlanData] = useState<PlanTeamDetails | null>(null);
  const teamPlanData = useSelector(getActiveSubscriptionTeamDataSelector);

  const activeSubscriptionData = useSelector(getActiveSubscriptionData);

  const invoiceDataOverview = useSelector(getInvoiceDataOverviewSelector);
  const invoiceDataLoading = useSelector(invoiceDataOverviewLoading);
  const currentPlanLegacyInterval = useSelector(getLegacyPlanInterval);

  const [showLegacyChangeModal, setShowLegacyChangeModal] = useState<boolean>(false);
  const [updateLegacyPlanTo, setUpdateLegacyPlanTo] = useState<'month' | 'year'>('month');

  const [isReactivating, setIsReactivating] = useState<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [showDowngradeModal, setShowDowngradeModal] = useState<boolean>(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState<boolean>(false);
  const [showBillingHistoryModal, setShowBillingHistoryModal] = useState<boolean>(false);
  const [InvoiceHistory, setInvoiceHistory] = useState<any[]>(
    invoiceDataOverview?.InvoiceHistory || [],
  );

  const lastInvoiceDate = invoiceDataOverview?.LastInvoice?.Date;
  const nextBillingDate = moment(lastInvoiceDate).add(1, 'M').format('MMMM DD, YYYY');

  const nextBillingInfo = useMemo(() => {
    if (invoiceDataOverview?.NextInvoice?.Date && invoiceDataOverview?.NextInvoice?.Amount) {
      return `Next Bill Date: ${moment(invoiceDataOverview?.NextInvoice?.Date).format(
        'MM/DD/YY',
      )} ($${invoiceDataOverview?.NextInvoice?.Amount})`;
    }
    return null;
  }, [invoiceDataOverview?.NextInvoice]);

  useEffect(() => {
    if (agentType === AGENT_TYPE.Team && agentRole !== TEAM_OWNER) {
      history.push(routes.settings);
    } else {
      dispatch(requestGetInvoiceDataOverviewEffect());
      dispatch(requestActiveSubscriptionTeamDataEffect());
    }
  }, [agentType, agentRole]);

  useEffect(() => {
    setInvoiceHistory(invoiceDataOverview?.InvoiceHistory || []);
  }, [invoiceDataOverview]);

  const fetchDataInBackground = async (cb?: any) => {
    dispatch(requestGetInvoiceDataOverviewEffect(() => {}, { silent: true }));
    dispatch(userGetDataOnBackgroundEffect({}, { showError: false }, cb));
    dispatch(requestActiveSubscriptionTeamDataEffect({}, { showError: false, silent: true }));
  };

  const handleUpdatePaymentMethodsClick = async () => {
    const response = await getLinkToPaymentMethodManagement();

    if (!response.data.success || !response.data.result) {
      showErrorMessage('Error occurred');
    } else {
      window.open(response.data.result, '_blank');
    }
  };

  const handleCancelOrRenewSubscriptionClick = async () => {
    const response = await getLinkToSubscriptionManagement();

    if (!response.data.success || !response.data.result) {
      showErrorMessage('Error occurred');
    } else {
      window.location.href = response.data.result;
    }
  };

  const handleSubscribeClick = () => {
    history.push(routes.paywall);
  };

  const handleViewPaymentHistoryClick = () => setShowBillingHistoryModal(true);

  const renderDefaultPaymentMethod = (defaultPaymentMethod) => {
    if (!defaultPaymentMethod) return null;

    return (
      <div className={styles.paymentInfoSubtitle}>
        {defaultPaymentMethod.Provider} ending in {defaultPaymentMethod.Last4}
      </div>
    );
  };

  const handleReActivate = () => {
    setIsReactivating(true);
    dispatch(
      reactivateSubscriptionEffect({}, (err) => {
        if (!err) {
          fetchDataInBackground();
        }
        setIsReactivating(false);
      }),
    );
  };

  const renderLastInvoiceData = (lastInvoiceData) => {
    if (!lastInvoiceData) return null;

    return (
      <div className={styles.paymentInfoSubtitle}>
        Last Bill Date: {moment(lastInvoiceData.Date).format('L')} · Amount: $
        {lastInvoiceData.Amount}
      </div>
    );
  };

  const renderNextInvoiceData = (nextInvoiceData) => {
    if (!nextInvoiceData) return null;

    return (
      <div className={styles.paymentInfoSubtitle}>
        Next Bill Date: {moment(nextInvoiceData.Date).format('L')} · Amount: $
        {nextInvoiceData.Amount}
        {activeSubscriptionData.isSubscriptionActive &&
          !activeSubscriptionData?.willBeCanceledTimestamp && (
            <>
              ·{' '}
              <Button variant="link" onClick={handleCancelOrRenewSubscriptionClick}>
                Cancel
              </Button>
            </>
          )}
      </div>
    );
  };

  const renderActionButtonOrSeatsCount = () => {
    if (activeSubscriptionData.willBeCanceledTimestamp) {
      return (
        <Button
          variant="secondary"
          className={styles.renewButton}
          onClick={() => handleCancelOrRenewSubscriptionClick()}
        >
          Renew
        </Button>
      );
    }

    if (!activeSubscriptionData.isSubscriptionActive) {
      return (
        <Button
          variant="secondary"
          className={styles.renewButton}
          onClick={() => handleSubscribeClick()}
        >
          Subscribe
        </Button>
      );
    }

    if (agentType === AGENT_TYPE.Team && teamPlanData) {
      return (
        <Popover
          content={<SeatsCountPopoverContent teamPlanData={teamPlanData} />}
          trigger="hover"
          placement="bottomRight"
          overlayClassName={styles.popupClassName}
        >
          <div className={classNames(styles.modifyBtn)}>
            <span>{teamPlanData?.BilledSeatsCount - teamPlanData?.UnusedSeatsCount}</span>/
            {teamPlanData?.BilledSeatsCount} seats
          </div>
        </Popover>
      );
    }
  };

  const renderPlanName = () => {
    if (activeSubscriptionData.isSubscriptionActive) {
      if (activeSubscriptionData.isStandardSoloPlan) {
        return 'Solo';
      }
      if (activeSubscriptionData.isStandardTeamPlan) {
        return 'Team';
      }
      return 'Legacy';
    }

    if (activeSubscriptionData.isComplimentary) {
      return 'Complimentary';
    }

    if (activeSubscriptionData.isInImplementation) {
      return 'In Implementation';
    }

    return 'Inactive';
  };

  const handleClientsClick = useCallback(() => {
    dispatch(appSetConnectionTypeEffect({ type: AGENT_CONNECTIONS.Team }));
    dispatch(appManageClientDrawerAction(true));
  }, [dispatch]);

  return (
    <>
      <div>
        <div className={styles.titleWrapper}>
          <h2 className={styles.title}>Billing</h2>
        </div>
        <div>
          <div className={classNames(styles.section, styles.sectionHeader, styles.flexCol)}>
            <div className={styles.sectionWrapper}>
              <div className={styles.titleContainer}>
                <div className={styles.currentPlanTitle}>
                  {' '}
                  Current Plan:{' '}
                  <strong
                    className={
                      ['Inactive'].includes(renderPlanName())
                        ? styles.subscriptionInactiveLabel
                        : ''
                    }
                  >
                    {renderPlanName()}
                  </strong>
                </div>
                {renderPlanName() === 'Legacy' && (
                  <div className={classNames(styles.legacyPlans)}>
                    <div
                      className={classNames({
                        [styles.selected]: currentPlanLegacyInterval === 'month',
                      })}
                    >
                      <div
                        className={classNames(styles.icon, {
                          [styles.active]: currentPlanLegacyInterval === 'month',
                          [styles.inactive]: currentPlanLegacyInterval === 'year',
                        })}
                        onClick={() => {
                          if (currentPlanLegacyInterval === 'month') return;
                          setUpdateLegacyPlanTo('month');
                          setShowLegacyChangeModal(true);
                        }}
                      >
                        {currentPlanLegacyInterval === 'month' && <CheckmarkIcon />}
                      </div>
                      <div>Monthly</div>
                    </div>
                    <div
                      className={classNames({
                        [styles.selected]: currentPlanLegacyInterval === 'year',
                      })}
                      onClick={() => {
                        if (currentPlanLegacyInterval === 'year') return;
                        setUpdateLegacyPlanTo('year');
                        setShowLegacyChangeModal(true);
                      }}
                    >
                      <div
                        className={classNames(styles.icon, {
                          [styles.active]: currentPlanLegacyInterval === 'year',
                          [styles.inactive]: currentPlanLegacyInterval === 'month',
                        })}
                      >
                        {currentPlanLegacyInterval === 'year' && <CheckmarkIcon />}
                      </div>
                      <div>Annual</div>
                    </div>
                  </div>
                )}

                <div className={styles.planDetails}>
                  <a
                    className={styles.details}
                    href="https://www.mosaik.io/pricing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View plan details
                  </a>
                </div>
              </div>
              {activeSubscriptionData.isStandardSoloPlan && (
                <div>
                  <Button
                    variant="secondary"
                    className={styles.upgradeBtn}
                    onClick={() => setShowUpgradeModal(true)}
                  >
                    Upgrade to Team
                  </Button>
                </div>
              )}
            </div>
            {activeSubscriptionData.isSubscriptionCanceled &&
            !nextBillingInfo &&
            !invoiceDataLoading ? (
              <div className={styles.cancelBanner}>
                <div className={styles.text}>
                  Your subscription will be cancelled at <span>{nextBillingDate}</span>.
                </div>
                <div className={styles.btnWrapper}>
                  <button onClick={() => handleReActivate()} disabled={isReactivating}>
                    {isReactivating ? (
                      <Spin
                        className={styles.loader}
                        indicator={
                          <LoadingOutlined
                            color="antiquewhite"
                            width={24}
                            height={24}
                            className={styles.spinner}
                            spin
                          />
                        }
                      />
                    ) : (
                      `Subscribe Again`
                    )}
                  </button>
                </div>
              </div>
            ) : null}
          </div>

          <div className={styles.section}>
            <div className={styles.paymentInfo}>
              <SeatsIcon />
              <div>
                <div className={styles.paymentInfoTitle}>
                  <div>
                    <span>Seats</span>
                  </div>
                </div>
              </div>
            </div>
            {teamPlanData && (
              <div className={styles.btnContainer}>
                <div className={styles.addTeamMembers} onClick={handleClientsClick}>
                  <Plus color="#515151" className={styles.icon} />
                  <span>Add</span>
                </div>
                <div>
                  <Popover
                    content={<SeatsCountPopoverContent teamPlanData={teamPlanData} />}
                    trigger="hover"
                    placement="bottomRight"
                    overlayClassName={styles.popupClassName}
                  >
                    <div className={classNames(styles.modifyBtn)}>
                      <span>
                        {teamPlanData.BilledSeatsCount - teamPlanData.UnusedSeatsCount || 1}
                      </span>
                      /{teamPlanData.BilledSeatsCount} seats
                    </div>
                  </Popover>
                </div>
              </div>
            )}
          </div>
          <div className={styles.section}>
            <div className={styles.paymentInfo}>
              <PaymentMethod className={styles.paymentInfoTitle__icon} />
              <div>
                <div className={styles.paymentInfoTitle}>
                  <div>
                    <span>Payment Method</span>
                  </div>
                </div>
                {renderDefaultPaymentMethod(invoiceDataOverview?.DefaultPaymentMethod)}
              </div>
            </div>
            <div onClick={handleUpdatePaymentMethodsClick} className={styles.modifyBtn}>
              Update
            </div>
          </div>

          {InvoiceHistory?.length > 0 ? (
            <div className={classNames(styles.section, styles.tableSection)}>
              <div className={styles.wrapper}>
                <div className={styles.paymentInfo}>
                  <PaymentHistory className={styles.paymentInfoTitle__icon} />
                  <div>
                    <div className={styles.paymentInfoTitle}>
                      <div>
                        <span>Payment History</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div onClick={handleViewPaymentHistoryClick} className={styles.modifyBtn}>
                  View All
                </div>
              </div>
              <BillingHistoryTable data={InvoiceHistory.slice(0, 5)} includeDescriptionHeader />
              {nextBillingInfo ? (
                <div className={styles.nextBillingInfo}>{nextBillingInfo}</div>
              ) : (
                <></>
              )}
            </div>
          ) : null}

          {invoiceDataOverview?.NextInvoice !== null && (
            <div className={styles.disclaimer}>
              {activeSubscriptionData?.isComplimentary !== true &&
                invoiceDataOverview?.NextInvoice !== null && (
                  <p
                    className={styles.cancelPlan}
                    onClick={() => setShowCancelSubscriptionModal(true)}
                  >
                    Cancel my plan
                  </p>
                )}
              {(teamPlanData?.BilledSeatsCount || 0) - (teamPlanData?.UnusedSeatsCount || 0) &&
              (teamPlanData?.RequestedSeatsCount || 0) === 3 ? (
                <p className={styles.downgrade}>
                  {' '}
                  or <span onClick={() => setShowDowngradeModal(true)}>Downgrade</span>
                </p>
              ) : null}
            </div>
          )}
        </div>
      </div>
      {teamPlanData ? (
        <DowngradeModal
          isOpen={showDowngradeModal}
          closeModal={() => {
            fetchDataInBackground();
            setShowDowngradeModal(false);
          }}
          teamPlanData={teamPlanData}
          openUpgradeModal={() => setShowUpgradeModal(true)}
        />
      ) : null}
      {showUpgradeModal && (
        <UpgradeModal
          isOpen={showUpgradeModal}
          closeModal={() => {
            fetchDataInBackground();
            setShowUpgradeModal(false);
          }}
        />
      )}
      {showLegacyChangeModal && (
        <LegacyModalChange
          isOpen={showLegacyChangeModal}
          closeModal={() => {
            fetchDataInBackground();
            setShowLegacyChangeModal(false);
          }}
          updateLegacyPlanTo={updateLegacyPlanTo}
        />
      )}
      {showBillingHistoryModal && (
        <BillingHistoryModal
          isOpen={showBillingHistoryModal}
          onClose={() => setShowBillingHistoryModal(false)}
          data={InvoiceHistory}
        />
      )}
      {showCancelSubscriptionModal && (
        <CancelSubscriptionModal
          isOpen={showCancelSubscriptionModal}
          fetchDataInBackground={fetchDataInBackground}
          closeModal={() => {
            fetchDataInBackground();
            setShowCancelSubscriptionModal(false);
          }}
        />
      )}
    </>
  );
};

export default Subscription;
