import React, { memo, useMemo } from 'react';

interface DropdownIconProps extends React.HTMLAttributes<HTMLDivElement> {
  stroke?: string;
}

const Dropdown = ({ stroke = '#7A7A7A', ...props }: DropdownIconProps) =>
  useMemo(
    () => (
      <div {...props}>
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.50012 1L6.00012 5.5L10.5001 1"
            stroke={stroke}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [props.className, stroke],
  );

export default memo(Dropdown);
