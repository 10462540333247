import React, { useState, useEffect } from 'react';
import { Checkbox, Locations, PropertyAddress } from 'components';
import { ClientAddress, ClientAddressEntity } from 'app-constants';
import { useDispatch } from 'react-redux';
import { updateClientDataEffect } from 'store/effects';
import { LOCALITY, POSTAL_CODE, ROUTE, STATE, STREET_NUMBER } from 'settings/constants/locations';
import { showSuccessMessage } from 'helpers';
import { getLocationContext, getPlaceComponent } from 'helpers/locations';
import { showErrorMessage } from 'helpers/errors';

import styles from './addAddressStyles.module.scss';
import { TrashIcon } from 'components/Icons';
import { Button } from 'components-antd';

interface Props {
  clientId: string;
  addresses: ClientAddress[];
  setAddresses: React.Dispatch<React.SetStateAction<ClientAddress[]>>;
  editAddress: ClientAddress | null;
  handleCancelClick: () => void;
  setEditAddress: React.Dispatch<React.SetStateAction<ClientAddress | null>>;
  editAddressIndex: number;
  setPoperOverOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type LocationResultType = {
  result: { place_id: string }[];
  placeName: string;
};

const emptyLocation = {
  address: '',
  placeId: '',
  isPrimary: false,
  entityType: ClientAddressEntity.User,
};

export const AddEditAddress = ({
  addresses,
  setAddresses,
  clientId,
  editAddress,
  handleCancelClick,
  setEditAddress,
  setPoperOverOpen,
  editAddressIndex,
}: Props) => {
  const [isPrimary, setIsPrimary] = useState(false);
  const [newAddress, setNewAddress] = useState<ClientAddress | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (editAddress) {
      setNewAddress(editAddress);
      setIsPrimary(editAddress.isPrimary);
    } else {
      setIsPrimary(false);
    }
  }, [editAddress]);

  const updateLocation = (isPrimary: boolean, result: LocationResultType, preparedData) => {
    const currentLocation = result?.result?.[0];
    let updatedLocation: any = { ...emptyLocation, isPrimary };
    if (
      currentLocation &&
      !addresses.find((location) => location.placeId === currentLocation.place_id)
    ) {
      const updatedCity = getLocationContext(currentLocation, LOCALITY);
      const updatedState = getLocationContext(currentLocation, STATE);
      const updatedZip = getLocationContext(currentLocation, POSTAL_CODE);
      const street = getPlaceComponent(currentLocation, ROUTE);
      const streetNumber = getLocationContext(currentLocation, STREET_NUMBER);
      updatedLocation = {
        address: result?.placeName,
        placeId: currentLocation.place_id,
        isPrimary,
        entityType: ClientAddressEntity.User,
        addressParsed: {
          City: updatedCity,
          State: updatedState,
          Zip: updatedZip,
          Line1: [streetNumber, street?.name].filter((i) => !!i).join(' '),
          ProviderPlaceId: currentLocation.place_id,
          PlaceName: result?.placeName,
          Street: preparedData.length && preparedData[0].Street ? preparedData[0].Street : '',
        },
      };
      setNewAddress(updatedLocation);
    }
  };

  const handleSubmit = () => {
    let addressesCopy = [...addresses];
    if (!newAddress) return;
    //Set All other addresses as false to set this one as primary
    if (isPrimary) {
      addressesCopy = addressesCopy.map((address) => ({ ...address, isPrimary: false }));
    }
    if (editAddress) {
      addressesCopy[editAddressIndex] = { ...newAddress, isPrimary };
      setAddresses(addressesCopy);
      submitAddressChange(addressesCopy, false);
    } else if (!editAddress) {
      const newAddresses = [...addressesCopy, { ...newAddress, isPrimary }];
      setAddresses(newAddresses);
      submitAddressChange(newAddresses, true);
    }
    setPoperOverOpen(false);
  };

  const handleRemoveAddress = () => {
    const addressCopy = addresses;
    if (addressCopy?.length <= 1) {
      showErrorMessage('At least one address is required');
      return;
    }
    const removedAddress = addressCopy.splice(editAddressIndex, 1)?.[0];
    //If the removed address was primary then set the first address as primary
    if (removedAddress?.isPrimary) {
      addressCopy[0].isPrimary = true;
    }
    setAddresses(addressCopy);
    submitAddressChange(addressCopy, false);
    setPoperOverOpen(false);
  };

  const submitAddressChange = (newAddresses: ClientAddress[], isNew: boolean) => {
    const Client = { Address: newAddresses };
    dispatch(
      updateClientDataEffect({ clientId, Client }, (err) => {
        if (!err) {
          showSuccessMessage(isNew ? 'Address added successfully' : 'Address updated successfully');
        }
      }),
    );
  };
  return (
    <div className={styles.addAddressWrapper}>
      <p className={styles.headingWrapper}>{editAddress !== null ? 'Edit' : 'Add'} Address</p>
      <div className={styles.locationWrapper}>
        <Locations
          multiple={false}
          types={['address']}
          variant="full"
          placeholder="Enter address"
          blockClassName={styles.rightBlock}
          onResult={(result, preparedData) => {
            updateLocation(false, result, preparedData);
          }}
          value={
            newAddress && newAddress.placeId
              ? [
                  {
                    PlaceName: newAddress?.addressParsed?.PlaceName,
                    ProviderPlaceId: newAddress.placeId,
                  },
                ]
              : undefined
          }
          className={styles.inputFieldLocations}
          testid="address"
          placeholderClassName={styles.locationPlaceholder}
          showPrefixIcon={true}
          searchIconClassName={styles.searchIcon}
        />
      </div>
      <div className={styles.setAsPrimary}>
        <Checkbox
          className={styles.checkbox}
          checkmarkClassName={editAddress?.isPrimary ? styles.checkmark : ''}
          checked={isPrimary}
          onChange={(e) => setIsPrimary((val) => !val)}
          disabled={!!editAddress?.isPrimary}
        />
        <p>Set as primary address</p>
      </div>
      <div className={styles.footer}>
        <div>
          {editAddress ? (
            <div onClick={handleRemoveAddress} className={styles.removeWrapper}>
              <TrashIcon stroke="#FF576D" className={styles.trashIcon} /> Remove
            </div>
          ) : null}
        </div>
        <div className={styles.buttonWrapper}>
          <Button variant="tertiary" className={styles.cancelButton} onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button variant="secondary" className={styles.doneButton} onClick={handleSubmit}>
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};
