import React, { memo, useMemo } from 'react';

const Team = memo(({ className }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7057 8.2607C15.7057 10.3075 14.0465 11.9667 11.9997 11.9667C9.95294 11.9667 8.2937 10.3075 8.2937 8.2607C8.2937 6.21393 9.95294 4.55469 11.9997 4.55469C14.0465 4.55469 15.7057 6.21393 15.7057 8.2607Z"
            stroke="#515151"
            strokeWidth="2"
          />
          <path
            d="M6.4408 19.0703C6.78725 17.0905 8.38011 15.3643 10.1467 15.3643H13.8528C15.6194 15.3643 17.2124 17.0905 17.5588 19.0703"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.3851 7.02539C19.8447 7.27026 20.9569 8.53966 20.9569 10.0688C20.9569 11.598 19.8447 12.8674 18.3851 13.1123"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M19.4139 15.9839C20.885 15.9839 22.2115 17.4214 22.5 19.07"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.61438 7.02539C4.1548 7.27026 3.04263 8.53966 3.04263 10.0688C3.04263 11.598 4.1548 12.8674 5.61438 13.1123"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M4.58569 15.9839C3.11461 15.9839 1.78809 17.4214 1.49959 19.07"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

export default Team;
