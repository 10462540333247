import { SVGProps } from 'react';

export const PropertyImage = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="236"
    height="194"
    viewBox="0 0 236 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 0H236V182H0V0Z" fill="#F4F5F6" />
    <g opacity="0.65">
      <path
        d="M126.867 119.571V66.2852C126.867 65.7329 126.419 65.2852 125.867 65.2852L90.4285 65.2852C89.8762 65.2852 89.4285 65.7329 89.4285 66.2852V118.571C89.4285 119.123 89.8762 119.571 90.4285 119.571H126.867ZM126.867 119.571H145.571C146.124 119.571 146.571 119.123 146.571 118.571V83.8319C146.571 83.3732 146.259 82.9733 145.814 82.8618L126.867 78.1163"
        stroke="#D6D6D7"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.572 119.571V105.285H114.429V119.571"
        stroke="#D6D6D7"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.4285 75.1055C99.4285 74.4151 99.9767 73.8555 100.653 73.8555H103.102C103.778 73.8555 104.326 74.4151 104.326 75.1055V77.6055C104.326 78.2958 103.778 78.8555 103.102 78.8555H100.653C99.9767 78.8555 99.4285 78.2958 99.4285 77.6055V75.1055Z"
        fill="#D6D6D7"
      />
      <path
        d="M99.4285 85.1055C99.4285 84.4151 99.9767 83.8555 100.653 83.8555H103.102C103.778 83.8555 104.326 84.4151 104.326 85.1055V87.6055C104.326 88.2958 103.778 88.8555 103.102 88.8555H100.653C99.9767 88.8555 99.4285 88.2958 99.4285 87.6055V85.1055Z"
        fill="#D6D6D7"
      />
      <path
        d="M99.4285 95.1055C99.4285 94.4151 99.9767 93.8555 100.653 93.8555H103.102C103.778 93.8555 104.326 94.4151 104.326 95.1055V97.6055C104.326 98.2958 103.778 98.8555 103.102 98.8555H100.653C99.9767 98.8555 99.4285 98.2958 99.4285 97.6055V95.1055Z"
        fill="#D6D6D7"
      />
      <path
        d="M111.673 75.1055C111.673 74.4151 112.222 73.8555 112.898 73.8555H115.347C116.023 73.8555 116.571 74.4151 116.571 75.1055V77.6055C116.571 78.2958 116.023 78.8555 115.347 78.8555H112.898C112.222 78.8555 111.673 78.2958 111.673 77.6055V75.1055Z"
        fill="#D6D6D7"
      />
      <path
        d="M111.673 85.1055C111.673 84.4151 112.222 83.8555 112.898 83.8555H115.347C116.023 83.8555 116.571 84.4151 116.571 85.1055V87.6055C116.571 88.2958 116.023 88.8555 115.347 88.8555H112.898C112.222 88.8555 111.673 88.2958 111.673 87.6055V85.1055Z"
        fill="#D6D6D7"
      />
      <path
        d="M111.673 95.1055C111.673 94.4151 112.222 93.8555 112.898 93.8555H115.347C116.023 93.8555 116.571 94.4151 116.571 95.1055V97.6055C116.571 98.2958 116.023 98.8555 115.347 98.8555H112.898C112.222 98.8555 111.673 98.2958 111.673 97.6055V95.1055Z"
        fill="#D6D6D7"
      />
      <path
        d="M134.428 89.3945C134.428 88.7042 134.988 88.1445 135.678 88.1445H138.178C138.869 88.1445 139.428 88.7042 139.428 89.3945V91.8945C139.428 92.5849 138.869 93.1445 138.178 93.1445H135.678C134.988 93.1445 134.428 92.5849 134.428 91.8945V89.3945Z"
        fill="#D6D6D7"
      />
      <path
        d="M134.428 99.3945C134.428 98.7042 134.988 98.1445 135.678 98.1445H138.178C138.869 98.1445 139.428 98.7042 139.428 99.3945V101.895C139.428 102.585 138.869 103.145 138.178 103.145H135.678C134.988 103.145 134.428 102.585 134.428 101.895V99.3945Z"
        fill="#D6D6D7"
      />
      <path
        d="M134.428 109.395C134.428 108.704 134.988 108.145 135.678 108.145H138.178C138.869 108.145 139.428 108.704 139.428 109.395V111.895C139.428 112.585 138.869 113.145 138.178 113.145H135.678C134.988 113.145 134.428 112.585 134.428 111.895V109.395Z"
        fill="#D6D6D7"
      />
    </g>
  </svg>
);
