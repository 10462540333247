import { get, cloneDeep } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  setTransactionTaskParamsAction,
  getTransactionTaskAction,
  resetTransactionTaskAction,
  updateTransactionTaskAction,
  uploadTransactionTaskDocumentsAction,
  deleteTransactionTaskDocumentAction,
  updateTransactionTaskChecklistAction,
} from 'store/actions/transactions';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  isTaskForm: false,
  isTaskDetail: false,
  transactionId: null,
  taskId: null,
  formValues: null,
  IsBundle: null,
  formTask: null,
  formDocuments: null,
  linkedForms: null,
  canBeginSigning: false,
  canResumeSigning: false,
  canSignForm: false,
  canViewForm: false,
};

export default handleActions(
  {
    [setTransactionTaskParamsAction]: (state, { payload }) => ({
      ...state,
      isTaskForm: get(payload, 'isTaskForm', state.isTaskForm),
      isTaskDetail: get(payload, 'isTaskDetail', state.isTaskDetail),
      transactionId: get(payload, 'transactionId', state.transactionId),
      taskId: get(payload, 'taskId', state.taskId),
      formValues: get(payload, 'formValues', initialData.formValues),
    }),
    [getTransactionTaskAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [updateTransactionTaskAction]: (state, { payload }) => {
      const tasks = get(payload, 'data.result.Tasks', []);
      const currentTaskId = get(state, 'data.Id');
      const newTaskData = tasks.find((task) => task?.Id === currentTaskId);

      return {
        ...state,
        data: newTaskData || state.data,
      };
    },
    [uploadTransactionTaskDocumentsAction]: (state, { payload }) => {
      const tasks = get(payload, 'data.result.Tasks', []);
      const currentTaskId = get(state, 'data.Id');
      const newTaskData = tasks.find((task) => task?.Id === currentTaskId);

      return {
        ...state,
        data: newTaskData || state.data,
      };
    },
    [deleteTransactionTaskDocumentAction]: (state, { payload }) => {
      const tasks = get(payload, 'data.result.Tasks', []);
      const currentTaskId = get(state, 'data.Id');
      const newTaskData = tasks.find((task) => task?.Id === currentTaskId);

      return {
        ...state,
        data: newTaskData || state.data,
      };
    },
    [updateTransactionTaskChecklistAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result') || state.data,
    }),
    [resetTransactionTaskAction]: () => cloneDeep(initialData),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
