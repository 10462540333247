import moment from 'moment';
import Sort from 'pages/Dashboard/components/Sort';
import { sortMlsAgentsEffect } from 'store/effects/adminPanel/mlsAgents';
import { getMlsAgentsSortSelector } from 'store/selectors/adminPanel';

export const gridColumns = [
  {
    title: 'Username',
    propKey: 'Name',
    children: (
      <Sort selector={getMlsAgentsSortSelector} effect={sortMlsAgentsEffect} field="Name" />
    ),
    render: (AgentName, { Email }) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'normal' }}>
          <span>{AgentName}</span>
          <span>({Email})</span>
        </div>
      );
    },
  },
  {
    title: 'MLS',
    propKey: 'MlsName',
    children: <Sort selector={getMlsAgentsSortSelector} effect={sortMlsAgentsEffect} field="Mls" />,
    render: (MlsName) => {
      return <span>{MlsName}</span>;
    },
  },
  {
    title: 'Status',
    propKey: 'Status',
    children: (
      <Sort selector={getMlsAgentsSortSelector} effect={sortMlsAgentsEffect} field="Status" />
    ),
    render: (status) => {
      return <span>{status && status[0].toUpperCase() + status.slice(1)}</span>;
    },
  },
  {
    title: 'Requested At',
    propKey: 'RequestedAt',
    children: (
      <Sort selector={getMlsAgentsSortSelector} effect={sortMlsAgentsEffect} field="RequestedAt" />
    ),
    render: (dateTime) => {
      return (
        <>
          {dateTime ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>{moment(dateTime).format('MM/DD/YYYY')}</span>
              <span>{moment(dateTime).format('hh:mm A')}</span>
            </div>
          ) : (
            <span>{'N/A'}</span>
          )}
        </>
      );
    },
  },
  {
    title: 'Approved At',
    propKey: 'ApprovedAt',
    children: (
      <Sort selector={getMlsAgentsSortSelector} effect={sortMlsAgentsEffect} field="ApprovedAt" />
    ),
    render: (dateTime) => {
      return (
        <>
          {dateTime ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>{moment(dateTime).format('MM/DD/YYYY')}</span>
              <span>{moment(dateTime).format('hh:mm A')}</span>
            </div>
          ) : (
            <span>{'N/A'}</span>
          )}
        </>
      );
    },
  },
  {
    title: 'Approved By',
    propKey: 'ApprovedByName',
    children: (
      <Sort selector={getMlsAgentsSortSelector} effect={sortMlsAgentsEffect} field="ApprovedBy" />
    ),
    render: (approvedBy) => {
      return <span>{approvedBy.trim() ? approvedBy.trim() : 'N/A'}</span>;
    },
  },
  {
    title: 'Team',
    propKey: 'TeamName',
    children: (
      <Sort selector={getMlsAgentsSortSelector} effect={sortMlsAgentsEffect} field="Team" />
    ),
    render: (team) => {
      return <span>{team ? team : 'N/A'}</span>;
    },
  },
];
