import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Popover } from 'components-antd';
import Icon from '../Icons';
import { MAP_VIEWS, MOBILE_MAP_VIEWS } from 'settings/constants/mapView';
import { setSearchResultsMapViewAction } from 'store/actions/searchResults';
import { getSearchResultsViewTypeSelector } from 'store/selectors/searchResults';

import styles from './styles.module.scss';

export const ViewSwitcher = () => {
  const dispatch = useDispatch();
  const view = useSelector(getSearchResultsViewTypeSelector);

  const [iconVariant, setIconVariant] = useState('');
  const [viewType, setViewType] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    function handleWindowSizeChange() {
      if (window.innerWidth <= 992) {
        setIsMobile(true);
        dispatch(setSearchResultsMapViewAction({ view: MAP_VIEWS.LIST }));
        updateStyles(MAP_VIEWS.LIST);
        setIconVariant(Icon.LIST_VIEW);
        setViewType('List Only');
      } else {
        setIsMobile(false);
        updateStyles(MAP_VIEWS.SPLIT);
        setIconVariant(Icon.SPLIT_VIEW);
        setViewType('Split View');
      }
    }
    if (document.readyState === 'complete') {
      handleWindowSizeChange();
    }
    window.addEventListener('resize', handleWindowSizeChange);
    window.addEventListener('load', handleWindowSizeChange);
    return () => {
      window.removeEventListener('load', handleWindowSizeChange);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const updateStyles = (current) => {
    const list = document.getElementById('searchProperties');
    const map = document.getElementById('searchMap');
    const listClassName = document.getElementById('searchProperties')?.classList[0];
    switch (current) {
      case MAP_VIEWS.LIST: {
        if (list && map) {
          map.className = '';
          list.className = listClassName!;
          list.classList.add('show');
          map.classList.add('hide');
        }
        break;
      }
      case MAP_VIEWS.MAP: {
        if (list && map) {
          map.className = '';
          list.className = listClassName!;
          list.classList.add('hide');
          map.classList.add('show');
        }
        break;
      }
      default: {
        if (list && map) {
          map.className = '';
          list.className = listClassName!;
          list.classList.add('show-both');
          map.classList.add('show-both');
        }
      }
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const onSplitView = () => {
    dispatch(setSearchResultsMapViewAction({ view: MAP_VIEWS.SPLIT }));
    updateStyles(MAP_VIEWS.SPLIT);
    setIconVariant(Icon.SPLIT_VIEW);
    setViewType('Split View');
  };
  const onMapView = () => {
    dispatch(setSearchResultsMapViewAction({ view: MAP_VIEWS.MAP }));
    updateStyles(MAP_VIEWS.MAP);
    setIconVariant(Icon.MAP_VIEW);
    setViewType('Map Only');
  };
  const onListView = () => {
    dispatch(setSearchResultsMapViewAction({ view: MAP_VIEWS.LIST }));
    updateStyles(MAP_VIEWS.LIST);
    setIconVariant(Icon.LIST_VIEW);
    setViewType('List Only');
  };

  const options = (
    <div className={styles.optionsList}>
      {!isMobile && (
        <div className={styles.option} onClick={onSplitView}>
          <Icon className={styles.icon} variant={Icon.SPLIT_VIEW} />
          <span>Split View</span>
        </div>
      )}
      <div className={styles.option} onClick={onMapView}>
        <Icon className={styles.icon} variant={Icon.MAP_VIEW} />
        <span>Map Only</span>
      </div>
      <div className={styles.option} onClick={onListView}>
        <Icon className={styles.icon} variant={Icon.LIST_VIEW} />
        <span>List Only</span>
      </div>
    </div>
  );

  return (
    <Popover
      content={options}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      getPopupContainer={(triggerNode) => triggerNode}
      overlayClassName={styles.viewSwitcherDropdown}
    >
      <a className={styles.viewSwitcher} onClick={(e) => e.preventDefault()}>
        <Icon className={styles.icon} variant={iconVariant} />
        <span>{viewType}</span>
      </a>
    </Popover>
  );
};
