import { createSelector } from 'reselect';

const localState = ({ agentTeamDetail }) => agentTeamDetail;

export const getAgentTeamDetailSelector = createSelector(
  localState,
  (agentTeamDetail) => agentTeamDetail,
);

export const getAgentTeamRoleSelector = createSelector(
  localState,
  (agentTeamDetail) => agentTeamDetail?.data?.TeamRole,
);

export const getAgentTeamIdSelector = createSelector(
  localState,
  (agentTeamDetail) => agentTeamDetail?.data?.TeamId,
);

export const getAgentTeamIsActiveSelector = createSelector(
  localState,
  (agentTeamDetail) => !!agentTeamDetail?.data?.IsActive,
);
