import React, { Fragment } from 'react';
import { Title, Modal, ModalProps, Input, Button } from 'components-antd';
import { useHistory } from 'react-router-dom';

import classnames from 'classnames';

import styles from './styles.module.scss';
import { Icons } from '../Icons';
import { Agents } from '../../OfferDetails/components/Agents';
import { DateAndTime } from '../../OfferDetails/components/DateAndTime';
import { Notes } from '../../OfferDetails/components/Notes';

export const OfferInformationModal = ({ isOpen, onClose, offerDetails }) => {
  const getFormatterAddress = () => {
    const { PropertyTransaction } = offerDetails;

    const Property = PropertyTransaction?.Property;
    const { Line1, City, State, Zip } = Property?.Address || {
      Line1: '',
      City: '',
      State: '',
      Zip: '',
    };

    return (
      <Fragment>
        <div className={styles.title}>{Line1}</div>
        <div className={styles.subTitle}>{`${City}, ${State} ${Zip}`}</div>
      </Fragment>
    );
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      okText={'Done'}
      width={'100%'}
      data-testid="offer-information-modal"
      className={classnames(styles.offerInformationModal)}
      centered
      closeIcon={false}
      destroyOnClose
      closable={false}
      onCancel={onClose}
      title={
        <div className={styles.infoTitle}>
          <div className={styles.addressInfo}>{getFormatterAddress()}</div>
          <Icons variant={'close'} onClick={onClose} />
        </div>
      }
    >
      <Fragment>
        <div className={styles.agentDetails}>
          <Agents
            SellerAgent={offerDetails?.SellerAgent}
            Brokerage={offerDetails?.Agent?.Brokerage}
            ListingAgentName={offerDetails?.ListingAgentName}
            ListingAgentEmail={offerDetails?.ListingAgentEmail}
          />
        </div>

        {offerDetails?.DisplayDeadlineOnPortal && (
          <div className={styles.offerDeadline}>
            <DateAndTime
              deadlineDate={offerDetails?.DeadlineDate}
              deadlineTime={offerDetails?.DeadlineDate}
            />
          </div>
        )}

        {offerDetails?.DisplaySubmissionPageNotes && (
          <div className={styles.submissionNotes}>
            <Notes offerLinkDetails={offerDetails} />
          </div>
        )}
      </Fragment>
    </Modal>
  );
};
