import { get } from 'lodash-es';

import { apiServer } from 'settings/web-services/api';
import { createDraftQuoteId } from 'helpers';
import { quoteRequestStatus } from 'settings/constants/quotes';
import { GET_QUOTE_FORM, PATCH_QUOTE_FORM } from 'app-constants';
import { UPLOAD_FILE } from 'app-constants/endpoints';

export function getDraftQuotes(cfg) {
  return apiServer.get('/quoteRequest/draft', { params: cfg });
}

export function getQuotes(cfg) {
  return apiServer.get('/quoteRequest', { params: cfg });
}

export function getQuoteAuditLogs(cfg) {
  return apiServer.get('/quoteRequest/auditLogs', cfg);
}

export function getAllQuotes(cfg) {
  let isOnlyDraft = false;
  if (cfg?.statuses?.length > 0 && !cfg?.statuses.includes(quoteRequestStatus.Draft)) {
    return getQuotes(cfg);
  }
  if (cfg?.statuses?.length === 1 && cfg.statuses[0] === 'Draft') {
    isOnlyDraft = true;
  }
  if (cfg?.statuses?.length > 0 && cfg?.statuses.includes(quoteRequestStatus.Draft)) {
    cfg.statuses = cfg.statuses.filter((status) => status !== quoteRequestStatus.Draft);
  }

  const draftQuotesPromise = getDraftQuotes(cfg);
  let quotesPromise;
  if (isOnlyDraft) {
    quotesPromise = new Promise((resolve, reject) => {
      // Resolving the promise with an empty array
      resolve([]);
    });
  } else {
    quotesPromise = getQuotes(cfg);
  }

  return new Promise((resolve) => {
    Promise.all([draftQuotesPromise, quotesPromise]).then((values) => {
      const [draftsResult, quotesResult] = values;
      const draftQuotes = get(draftsResult, 'data.result', []);
      const otherQuotes = get(quotesResult, 'data.result', []);

      const draftQuotesWithStatus = draftQuotes.map((draftQuote) => ({
        ...draftQuote,
        Id: createDraftQuoteId(draftQuote?.Id),
        Status: quoteRequestStatus.Draft,
      }));
      const result = [...draftQuotesWithStatus, ...otherQuotes];

      resolve({ data: { result }, meta: cfg });
    });
  });
}

export function createQuoteDraft(cfg) {
  return apiServer.post('/quoteRequest/draft', cfg);
}

export function getQuoteDetails(cfg) {
  return apiServer.get(`/quoteRequest/${cfg?.id}`);
}

export function archiveQuotes(cfg = {}) {
  return apiServer.post('/quoteRequest/archive', cfg);
}

export function archiveDraftQuotes(cfg = {}) {
  return apiServer.post('/quoteRequest/draft/archive', cfg);
}

export function respondAndUploadDocument(cfg) {
  const { id, formData } = cfg;
  return apiServer.post(`/quoteRequest/${id}/quoteResponse`, formData);
}
export function uploadQuoteDocument(cfg) {
  const { formData } = cfg;
  return apiServer.post(UPLOAD_FILE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function requestFormResults(cfg) {
  const { id, ...config } = cfg;
  return apiServer.put(`/quoteRequest/draft/${id}/formAnswers`, config);
}

export function updateQuoteDraft(cfg) {
  const { id, ...config } = cfg;
  return apiServer.patch(`/quoteRequest/draft/${id}`, config);
}

export function submitDraftQuote(cfg) {
  const { id, ...config } = cfg;
  return apiServer.post(`/quoteRequest/draft/${id}/submit`, config);
}

export function updateQuoteQuestion(cfg) {
  const { quoteId, questionId, ...config } = cfg;
  return apiServer.patch(`/quoteRequest/draft/${quoteId}/formAnswers/${questionId}`, config);
}

export function resumeDraftQuote(cfg) {
  return apiServer.get(`/quoteRequest/draft/${cfg?.id}`);
}

export function deleteQuoteResponse(cfg) {
  return apiServer.delete(`/quoteRequest/${cfg?.quoteId}/quoteResponse/${cfg?.responseId}`);
}

export const getQuoteForm = ({ categoryId, ...cfg }) =>
  apiServer.post(GET_QUOTE_FORM(categoryId), cfg);

export const saveQuoteForm = (cfg) => {
  const { id, ...config } = cfg;
  return apiServer.patch(PATCH_QUOTE_FORM(id), config);
};
