import { requestMultiFactorAuthCodeInfo, requestMultiFactorAuthCode } from 'api/user';
import { Modal } from 'components-antd';
import { showErrorMessage } from 'helpers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserProfileWithVerfiedPhoneNumberAction } from 'store/actions/user';
import { MultiFactorAuthBlockedStep } from './MultiFactorAuthBlockedStep';
import { MultiFactorAuthProblemStep } from './MultiFactorAuthProblemStep';
import { MultiFactorAuthVerifyCodeStep } from './MultiFactorAuthVerifyCodeStep';

interface MultiFactorAuthModalProps {
  isMultiFactorAuthModalOpened: boolean;
  email: string;
  onCloseModal: () => void;
  onCodeSubmitted: (code: string) => Promise<void>;
}

export const MultiFactorAuthModal: React.FC<MultiFactorAuthModalProps> = (props) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState<
    'multiFactorAuthProblem' | 'verifyCode' | 'multiFactorAuthBlocked'
  >('multiFactorAuthProblem');

  const [verificationCodeData, setVerificationCodeData] = useState<
    undefined | null | VerificationCodeData
  >(undefined);

  const [remainingSecondsToResendCode, setRemainingSecondsToResendCode] = useState<number | null>(
    null,
  );

  // Load info about existing verification code request once modal is open
  useEffect(() => {
    const runEffect = async () => {
      const response = await requestMultiFactorAuthCodeInfo({ email: props.email });
      setVerificationCodeData(response.data);
      setStep(!response.data.IsBlocked ? 'verifyCode' : 'multiFactorAuthBlocked');
    };

    if (props.isMultiFactorAuthModalOpened) {
      void runEffect();
    }
  }, [props.isMultiFactorAuthModalOpened]);

  // Update timer with remaining seconds for next attempt
  useEffect(() => {
    const interval = setInterval(() => {
      const remainingSeconds = moment(verificationCodeData?.AllowCodeResendTimestamp).diff(
        moment(),
        'seconds',
      );
      setRemainingSecondsToResendCode(remainingSeconds);

      if (remainingSeconds < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [verificationCodeData]);

  return (
    <Modal
      open={props.isMultiFactorAuthModalOpened}
      width={675}
      footer={null}
      onCancel={() => {
        props.onCloseModal();
      }}
      // className={styles.inviteModal}
      // cancelButtonStyles={styles.crossButton}
      maskClosable={false}
      destroyOnClose={true}
      title=" "
    >
      {step === 'multiFactorAuthProblem' && <MultiFactorAuthProblemStep />}
      {step === 'verifyCode' && (
        <MultiFactorAuthVerifyCodeStep
          phoneNumber={verificationCodeData?.PhoneNumber}
          onCodeSubmitted={async (code: string) => {
            props.onCodeSubmitted(code);
          }}
          onResendCodeClick={async () => {
            try {
              const response = await requestMultiFactorAuthCode({
                email: props.email,
              });
              setVerificationCodeData(response.data);
            } catch (err: any) {
              if (err.response.data.code === 'MAX_ATTEMPTS_TO_SEND') {
                setStep('multiFactorAuthBlocked');
              }
            }
          }}
          remainingSecondsToResendCode={remainingSecondsToResendCode}
        ></MultiFactorAuthVerifyCodeStep>
      )}
      {step === 'multiFactorAuthBlocked' && <MultiFactorAuthBlockedStep />}
    </Modal>
  );
};

interface VerificationCodeData {
  AllowCodeResendTimestamp: Date;
  ExpirationSeconds: number;
  PhoneNumber: string;
}
