import { FC } from 'react';
import classNames from 'classnames';

import Icon from 'pages/Properties/SearchResults/Icons';
import { Actions } from '../..';
import { SchoolsSelector } from './SchoolsSelector';
import { ParkingsSelector } from './ParkingsSelector';
import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { PARKING_GARAGE_TYPES, prefsIds } from 'settings/constants/preferences';

import styles from './styles.module.scss';
import { BackyardSelector } from './BackyardSelector';

interface Props {
  id?: string;
  iconType?: string;
  className?: string;
  label?: string;
  currentImportance: string;
  onChange: (...props) => any;
  onUpdatePrefs: (...props) => any;
  rawSearchPrefs?: { [key: string]: any };
  isSchool?: boolean;
}

const Item: FC<Props> = (props) => {
  const {
    id,
    label,
    currentImportance,
    onChange,
    onUpdatePrefs,
    className = '',
    rawSearchPrefs,
    iconType = '',
    isSchool = false,
  } = props;

  return (
    <>
      <div testid="feature_item" className={classNames(styles.item, className)}>
        <div className={styles.inner}>
          <Icon
            className={classNames(styles.icon, id ? styles[id] : '')}
            variant={iconType || id}
          />
          <p className={styles.label}>{label === 'Fenced Yard' ? 'Backyard' : label}</p>
        </div>
        <Actions onChange={onChange} currentImportance={currentImportance} isSchool={isSchool} />
      </div>
      {id === prefsIds.goodSchools && currentImportance !== SEARCH_CRITERIA_IMPORTANCE.UNDEFINED && (
        <SchoolsSelector
          schoolsPref={rawSearchPrefs?.Schools || {}}
          onEditSchoolPrefs={(newSchoolPrefs) => {
            onChange(currentImportance, {
              Schools: newSchoolPrefs,
            });
          }}
        />
      )}
      {id === prefsIds.parking && currentImportance !== SEARCH_CRITERIA_IMPORTANCE.UNDEFINED && (
        <ParkingsSelector
          parkingPrefs={rawSearchPrefs?.Parking || {}}
          onEditParkingPrefs={(newParkingPrefs) => {
            onChange(currentImportance, {
              Parking: {
                ...(newParkingPrefs?.GarageOnly === true && {
                  GarageOnly: newParkingPrefs?.GarageOnly,
                }),
                ...(newParkingPrefs?.GarageOnly === true &&
                  newParkingPrefs?.GarageType === PARKING_GARAGE_TYPES.Attached && {
                    GarageType: newParkingPrefs?.GarageType,
                  }),
                NumOfParkingSpaces: newParkingPrefs?.NumOfParkingSpaces,
              },
            });
          }}
        />
      )}

      {(id === prefsIds.outdoorYard || id === prefsIds.fencedYard) &&
        currentImportance !== SEARCH_CRITERIA_IMPORTANCE.UNDEFINED && (
          <BackyardSelector
            backyardPrefs={rawSearchPrefs as any}
            currentImportance={currentImportance}
            onUpdatePrefs={(newPrefs) => onUpdatePrefs(newPrefs)}
            onEditBackyardPrefs={(newOutdoorPrefs) => {
              onChange(currentImportance, {
                OutdoorPrefs: newOutdoorPrefs,
              });
            }}
          />
        )}
    </>
  );
};

export default Item;
