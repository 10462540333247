import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useState, useEffect } from 'react';

import Field from '../Field';
import { RemoveFile } from 'components/Icons';
import { getLocationAddress } from 'helpers/locations';
import { Input, Select, SingleLocation, DatePicker } from 'components';
import { transactionStatuses, listedStatusesArray } from 'settings/constants/transaction';
import { PropertyType } from 'components';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { getProjectCategoriesDataArray } from 'store/selectors/projects';
import { useSelector } from 'react-redux';
import { TransactionFinancingType } from 'app-constants';

const Content = (props) => {
  const { className, isPending, values, setValues } = props;
  const isProject = useIsProjectRoute();
  const projectCategories = useSelector(getProjectCategoriesDataArray);

  const financingTypeOptions = Object.values(TransactionFinancingType).map((type) => ({
    value: type,
    name: type,
  }));

  const updateFields = (obj) => {
    setValues({ ...values, ...obj });
  };

  const onSuiteUnitChange = (e, val) => {
    updateFields({
      address: { ...(values?.address || {}), Line2: val || undefined },
    });
  };

  const onFieldChange = (val, propName) => {
    updateFields({ [propName]: val });
  };

  const getPriceLabel = () => {
    const status = values?.status;
    if (listedStatusesArray.includes(status?.value)) {
      return 'Listing Price';
    }
    return 'Purchase Price';
  };

  const getClosingDateLabel = () => {
    const status = values?.status;
    return listedStatusesArray.includes(status?.value)
      ? 'Listing Agreement Expiration'
      : 'Closing Date';
  };

  return (
    <div className={classNames(styles.content, className)}>
      {isProject ? (
        <>
          <Field label="Name" fieldClassName={styles.label}>
            <Input
              className={styles.field}
              placeholder="Name"
              variant="light"
              value={values?.name}
              onChange={(e, val) => onFieldChange(val, 'name')}
              disabled={isPending}
              testid="project_name_input"
            />
          </Field>
          <Field label="Category">
            <Select
              name="category"
              className={{ wrapper: classNames(styles.selectWrapper, styles.field) }}
              variant="light"
              options={projectCategories?.map((pc) => ({ value: pc.Id, name: pc.Category }))}
              value={values?.category}
              onSelect={(e, val) => onFieldChange(val, 'category')}
              disabled={isPending}
              testid="category_select"
            />
          </Field>
          {values?.status === transactionStatuses.Upcoming ? (
            <Field label={'Kickoff'}>
              <DatePicker
                value={values?.effectiveDate}
                options={{
                  enableTime: false,
                  minDate: moment().startOf('day').toDate(),
                }}
                onChange={(e, val) => onFieldChange(val?.[0], 'effectiveDate')}
                disabled={isPending}
                testid="effectiveDate_date_picker"
              />
            </Field>
          ) : (
            <Field label={'Deadline'}>
              <DatePicker
                value={values?.closingDate}
                options={{
                  enableTime: false,
                  minDate: moment().startOf('day').toDate(),
                }}
                onChange={(e, val) => onFieldChange(val?.[0], 'closingDate')}
                disabled={isPending}
                testid="closing_date_picker"
              />
            </Field>
          )}{' '}
        </>
      ) : (
        <>
          <Field label="Address">
            <SingleLocation
              className={styles.field}
              placeholder="Enter property address..."
              types={['address']}
              variant="light"
              onResult={(result) => onFieldChange(getLocationAddress(result), 'address')}
              testid="address"
              value={values?.address}
              onClear={() => onFieldChange({}, 'address')}
              disabled={isPending}
            />
          </Field>
          <Field label="Suite/Unit">
            <Input
              className={styles.field}
              placeholder="Suite/Unit"
              variant="light"
              value={values?.address?.Line2}
              onChange={onSuiteUnitChange}
              disabled={isPending}
              testid="suite_unit_input"
              maxLength={100}
            />
          </Field>
          <Field label={getPriceLabel()}>
            <Input
              className={styles.field}
              placeholder="Listing Price"
              variant="light"
              isNumberFormat
              prefix="$"
              value={values?.price}
              onChange={(e, val) => onFieldChange(val, 'price')}
              disabled={isPending}
              testid="price_input"
            />
          </Field>
          <Field label={getClosingDateLabel()}>
            <DatePicker
              value={values?.closingDate}
              options={{
                enableTime: false,
                minDate: moment().startOf('day').toDate(),
              }}
              onChange={(e, val) => onFieldChange(val?.[0], 'closingDate')}
              disabled={isPending}
              testid="closing_date_picker"
            />
          </Field>

          <Field label="Property Type">
            <PropertyType
              className={styles.propertyType}
              itemClassName={styles.optionItem}
              multiple={false}
              onChange={(value) => onFieldChange(value, 'PropertyType')}
              value={values?.PropertyType}
              isTransactionCreation={true}
              type="dropdown"
            />
          </Field>
          <Field label="Financing  Type">
            <Select
              value={values.FinancingType}
              onSelect={(_, { value }) => onFieldChange(value, 'FinancingType')}
              options={financingTypeOptions}
              className={{
                wrapper: styles.financingType,
              }}
              placeholder={'Choose financing type'}
              isArrowIcon={true}
              variant={Select.LIGHT_FULL}
            />
          </Field>
          <Field label="Year Built">
            <Input
              isNumberFormat
              thousandSeparator={false}
              variant={Input.LIGHT}
              placeholder="Year (i.e. 2010)"
              onChange={(e, value) => onFieldChange(value, 'YearBuilt')}
              value={values?.YearBuilt}
              testid="year_input"
              minNumber={0}
              maxNumber={new Date().getFullYear()}
              className={styles.input}
            />
          </Field>

          <Field label="Clients">
            <span className={styles.clientNameContainer}>
              <Input
                className={classNames(styles.field, styles.clientNameInput)}
                placeholder="Client names (e.g. John, Doe)"
                variant="light"
                value={values?.transactionClients?.join()}
                onChange={(e) => onFieldChange(e.target.value.split(','), 'transactionClients')}
                disabled={isPending}
              />
              <RemoveFile
                className={styles.removeClientName}
                onClick={() => onFieldChange([], 'transactionClients')}
              />
            </span>
          </Field>
          {/* Commented as status can be updated from overview & includes latest business logic
            <Field label="Status">
              <Select
                name="Status"
                className={{ wrapper: classNames(styles.selectWrapper, styles.field) }}
                variant="light"
                options={transactionStatusesOptions}
                value={values?.status}
                onSelect={(e, val) => onFieldChange(val, 'status')}
                disabled={isPending}
                testid="status_select"
              />
            </Field> */}
        </>
      )}
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  values: PropTypes.object,
  setValues: PropTypes.func,
};

Content.defaultProps = {
  className: '',
  isPending: false,
  values: {},
  setValues: () => {},
};

export default Content;
