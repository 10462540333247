import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { quoteRequestStatus, quotesStatusesMap } from 'settings/constants/quotes';
import { getUserRoleSelector, getUserRolesMapSelector } from 'store/selectors/user';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationService } from 'services';

import { THIRD_PARTY } from 'settings/constants/roles';
import { Icons } from 'pages/Quotes/Icons';
import { NoQuotes } from 'pages/Quotes/components/NoQuotes';

import styles from './styles.module.scss';
import { tableColumns } from 'pages/Quotes/components/Tables/tableColumns';
import { GroupByTable } from 'pages/Quotes/components/Tables/GroupByTable';
import GroupByDetailTable from 'pages/Quotes/components/Tables/GroupByDetailTable';
import { getSearchQuotesSelector } from 'store/selectors/quotes';

const locationSrv = new LocationService();
const ByStatus = (props) => {
  const { className, quotesList, isGroupedByClient, onArchive, groupId } = props;
  const history = useHistory();
  const location = useLocation();
  locationSrv.setLocation(location);
  const userRole = useSelector(getUserRoleSelector);
  const search = useSelector(getSearchQuotesSelector);
  const { isThirdParty } = useSelector(getUserRolesMapSelector);

  const getTitle = (quote) => {
    if (isThirdParty) {
      const { FirstName, LastName } = quote?.Requestor || {};

      if (FirstName && LastName) {
        return `${FirstName} ${LastName}`;
      }
      return 'Ungrouped';
    }

    return quote?.Partners?.[0]?.BusinessName
      ? quote?.Partners?.[0]?.BusinessName
      : quote?.Partner?.BusinessName;
  };

  const getCount = (status) =>
    quotesList?.[status]?.filter((quote) => !quote.IsArchived).length || 0;
  const getPendingCount = (status) =>
    quotesList?.[status]?.filter(
      (quote) =>
        quote?.Status === quoteRequestStatus.PendingAnswer &&
        !quote.IsArchived &&
        quote?.DateViewedByPartner,
    ).length || 0;
  const getPendingQuotes = (status) =>
    quotesList?.[status]?.filter(
      (quote) =>
        quote?.Status === quoteRequestStatus.PendingAnswer &&
        !quote.IsArchived &&
        quote?.DateViewedByPartner,
    );
  const getNewCount = (status) =>
    quotesList?.[status]?.filter(
      (quote) =>
        quote?.Status === quoteRequestStatus.PendingAnswer &&
        !quote.IsArchived &&
        !quote?.DateViewedByPartner,
    ).length || 0;
  const getNewQuotes = (status) => {
    return quotesList?.[status]?.filter(
      (quote) =>
        quote?.Status === quoteRequestStatus.PendingAnswer &&
        !quote.IsArchived &&
        !quote?.DateViewedByPartner,
    );
  };

  const getArchievedCountQuotes = (quotes) =>
    quotes?.reduce((acc, quote) => (quote?.IsArchived ? acc + 1 : acc), 0);
  const getPendingNewCountQuotes = (quotes) =>
    quotes?.reduce(
      (acc, quote) =>
        quote?.Status === quoteRequestStatus.New && !quote.IsArchived ? acc + 1 : acc,
      0,
    );
  const getPendingTitle = () => (userRole === THIRD_PARTY ? 'Pending Response' : 'Pending');
  const getReceivedTitle = () => (userRole === THIRD_PARTY ? 'Quoted' : 'Received');

  const getPendingStatus = () =>
    userRole === THIRD_PARTY ? quoteRequestStatus.PendingAnswer : quoteRequestStatus.New;

  const draftCount = getCount(quotesStatusesMap(userRole)[quoteRequestStatus.Draft]);

  const existingNewCount =
    quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.New]]?.filter(
      (quote) => !quote.IsArchived && quote?.Status === quoteRequestStatus.New,
    ).length || 0;

  const newCount = getNewCount(quotesStatusesMap(userRole)[quoteRequestStatus.PendingAnswer]);

  const pendingCount = getPendingCount(
    quotesStatusesMap(userRole)[quoteRequestStatus.PendingAnswer],
  );

  const respondedCount = getCount(quotesStatusesMap(userRole)[quoteRequestStatus.Responded]);
  const pendingQuotes =
    getPendingQuotes(quotesStatusesMap(userRole)[quoteRequestStatus.PendingAnswer]) || [];
  const newQuotes =
    getNewQuotes(quotesStatusesMap(userRole)[quoteRequestStatus.PendingAnswer]) || [];
  const getDetailTables = () => {
    let groupByStatus = { ByStatus: [] };
    if (groupId === getPendingTitle()) {
      groupByStatus.ByStatus =
        userRole === THIRD_PARTY
          ? pendingQuotes
          : [
              ...pendingQuotes,
              ...newQuotes,
              ...(quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.New]]?.filter(
                (quote) => quote?.Status === quoteRequestStatus.New,
              ) || []),
            ];
    } else if (groupId === getReceivedTitle()) {
      groupByStatus.ByStatus =
        quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.Responded]];
    } else if (groupId === 'New') {
      groupByStatus.ByStatus = [
        ...newQuotes,
        ...(quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.New]]?.filter(
          (quote) => quote?.Status === quoteRequestStatus.New,
        ) || []),
      ];
    } else if (groupId === 'Drafts') {
      groupByStatus.ByStatus = quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.Draft]];
    }
    return (
      <GroupByDetailTable onArchive={onArchive} quotesList={groupByStatus} groupedBy={'ByStatus'} />
    );
  };
  const tableData = [
    {
      name: getPendingTitle(),
      count: userRole === THIRD_PARTY ? pendingCount : pendingCount + newCount + existingNewCount,
      newCount: 0,
      icon: Icons.PENDING_APPROVAL_BG,
      archiveCount:
        userRole === THIRD_PARTY
          ? getArchievedCountQuotes([
              ...(quotesList?.[
                quotesStatusesMap(userRole)[quoteRequestStatus.PendingAnswer]
              ]?.filter(
                (quote) =>
                  quote?.Status === quoteRequestStatus.PendingAnswer && quote?.DateViewedByPartner,
              ) || []),
            ])
          : getArchievedCountQuotes([
              ...(quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.New]] || []),
            ]),
    },
    {
      name: getReceivedTitle(),
      count: respondedCount,
      newCount: 0,
      icon: Icons.GREEN_LIGHT_CHECKMARK_BG,
      archiveCount:
        getArchievedCountQuotes(
          quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.Responded]],
        ) || 0,
    },
    {
      name: userRole === THIRD_PARTY ? 'New' : 'Drafts',
      count: userRole === THIRD_PARTY ? newCount + existingNewCount : draftCount,
      newCount: 0,
      icon: Icons.DRAFTS,
      archiveCount:
        (userRole === THIRD_PARTY
          ? getArchievedCountQuotes([
              ...(quotesList?.[
                quotesStatusesMap(userRole)[quoteRequestStatus.PendingAnswer]
              ]?.filter(
                (quote) =>
                  quote?.Status === quoteRequestStatus.PendingAnswer && !quote?.DateViewedByPartner,
              ) || []),
              ...(quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.New]]?.filter(
                (quote) => quote?.Status === quoteRequestStatus.New,
              ) || []),
            ]) || 0
          : getArchievedCountQuotes(
              quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.Draft]],
            )) || 0,
    },
  ].filter((data) => {
    if (search) {
      const formattedSearch = search.toLowerCase();
      return data?.name?.toLowerCase().includes(formattedSearch);
    }
    return true;
  });
  const tableProps = {
    columns: tableColumns(userRole),
    onRow: (record) => {
      return {
        onClick: (event) => {
          history.push(locationSrv.setQuery({ groupId: record?.name }));
        },
      };
    },
  };

  return (
    <div testid="by_status" className={classNames(styles.byStatus, className)}>
      {groupId ? (
        getDetailTables()
      ) : (
        <>
          {tableData.length > 0 &&
          ((userRole === THIRD_PARTY ? newCount + existingNewCount > 0 : draftCount > 0) ||
            (userRole === THIRD_PARTY ? pendingCount : pendingCount + newCount + existingNewCount) >
              0 ||
            respondedCount > 0) ? (
            <GroupByTable dataSource={tableData} {...tableProps} />
          ) : (
            <NoQuotes />
          )}
        </>
      )}
    </div>
  );
};

ByStatus.propTypes = {
  className: PropTypes.string,
  quotesList: PropTypes.shape({}).isRequired,
  isGroupedByClient: PropTypes.bool,
  onArchive: PropTypes.func,
  groupId: PropTypes.string,
};

ByStatus.defaultProps = {
  className: '',
  isGroupedByClient: false,
  onArchive: () => {},
  groupId: '',
};

export default ByStatus;
