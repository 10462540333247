import { combineReducers } from 'redux';

import categories from './categories';
import category from './category';
import partners from './partners';
import partner from './partner';
import forms from './forms';
import serviceTags from './serviceTags';
import serviceTag from './serviceTag';

export default combineReducers({
  categories,
  category,
  partners,
  partner,
  forms,
  serviceTags,
  serviceTag,
});
