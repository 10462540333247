import { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getTransactionEffect, getKeyDatesEffect } from 'store/effects/transactions';
import { getTransactionSelector } from 'store/selectors/transaction';
import { PageWrapper, AsideNavigation, Content } from 'components/Transactions';
import { TransactionView } from './components';

import styles from './styles.module.scss';

const TransactionOverview = (props) => {
  const { className, match } = props;
  const { isError, status, isPending, isIdle } = useSelector(getTransactionSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTransactionEffect({ id: match.params.id }));
    dispatch(getKeyDatesEffect({ transactionId: match.params.id }, { silent: !isIdle }));
  }, []);

  return (
    <div className={classNames(styles.transactionOverview, className)}>
      <PageWrapper notFound={isError && status === 404} unAuthorized={isError && status === 401}>
        <AsideNavigation />
        <Content isPending={isPending}>
          <TransactionView />
        </Content>
      </PageWrapper>
    </div>
  );
};

TransactionOverview.propTypes = {
  className: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,
};

TransactionOverview.defaultProps = {
  className: '',
};

export default TransactionOverview;
