import { createSelector } from 'reselect';
import { get, reduce, map, filter, orderBy } from 'lodash-es';

import { PENDING } from 'settings/constants/apiState';

const localState = ({ searchMessages }) => searchMessages;

export const isSearchMessagesLoading = createSelector(localState, ({ state }) => state === PENDING);

export const getSearchString = createSelector(localState, ({ meta }) => meta?.search);

export const isSearchActive = createSelector(getSearchString, Boolean);

export const getSearchedResultPreparedList = createSelector(localState, ({ data }) => {
  const messages = get(data, 'Messages', []);
  const threads = get(data, 'Threads', []);
  const threadIdsSet = new Set();

  const messagesThreadsList = reduce(
    messages,
    (acc, thread) => {
      threadIdsSet.add(thread?.Id);
      acc.push(
        ...map(thread?.Messages, ({ CreatedDate, Text, Id }) => ({
          ...thread,
          UniqId: `${thread?.Id}-${Id}`,
          LastMessage: Text,
          LastMessageCreatedDate: CreatedDate,
        })),
      );
      return acc;
    },
    [],
  );

  const filteredThreads = filter(threads, ({ Id }) => !threadIdsSet.has(Id));

  return orderBy(
    [...messagesThreadsList, ...filteredThreads],
    ['IsUnread', 'LastMessageCreatedDate'],
    ['desc', 'desc'],
  );
});
