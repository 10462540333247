import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Hint = (props) => {
  const { className, buyers } = props;

  const getColor = (score) => {
    if (score >= 75) {
      return 'green';
    }
    if (score >= 50 && score < 75) {
      return 'orange';
    }

    return 'gray';
  };

  return (
    <ul className={classNames(styles.list, className)}>
      {(buyers || []).map(({ name, score }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li testid="applicant_item" key={index}>
          <span className={styles.name}>{name}</span>
          <span
            className={classNames(styles.matchPercent, styles[getColor(score)])}
          >{`${score}%`}</span>
        </li>
      ))}
    </ul>
  );
};

Hint.propTypes = {
  className: PropTypes.string,
  buyers: PropTypes.arrayOf(PropTypes.shape({})),
};

Hint.defaultProps = {
  className: '',
  buyers: [],
};

export default Hint;
