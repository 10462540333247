import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { getAgentTypeSelector, getUserRoleSelector } from 'store/selectors/user';
import { AGENT, CLIENT, TEAM_OWNER } from 'settings/constants/roles';
import { Paywall } from './Paywall';
import { PaywallForTeamMember } from './PaywallForTeamMember';
import { AGENT_TYPE } from 'settings/constants/drawers';
import { PaywallForClient } from './PaywallForClient';

interface PaywallProps {}

export const PaywallForRole: React.FC<PaywallProps> = () => {
  const userRole = useSelector(getUserRoleSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const teamAgentRole = useSelector(getAgentTeamRoleSelector);

  if (userRole === CLIENT) {
    return <PaywallForClient />;
  }

  if (userRole !== AGENT) {
    return <Redirect to={routes.index} />;
  }

  if (agentType === AGENT_TYPE.Individual) {
    return <Paywall />;
  }

  if (!teamAgentRole) {
    return null;
  }

  if (teamAgentRole === TEAM_OWNER) {
    return <Paywall />;
  }

  return <PaywallForTeamMember />;
};
