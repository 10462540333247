import { EditStageInfo } from '../EditStageInfo';

import styles from '../styles.module.scss';

export const EditNotify = ({ data, onEdit }) => {
  const getInfo = () => {
    const { ConfirmedBookingNotification: notify, DelegateList, FYIList } = data;

    let text = '';
    if (notify.Self) {
      text += 'Me';
    }
    if (notify.Seller) {
      const info = 'Seller';
      text += text ? `, ${info}` : info;
    }
    if (notify.Delegates && DelegateList.length) {
      const info = `Delegates (${DelegateList.length})`;
      text += text ? `, ${info}` : info;
    }
    if (notify.FYIList && FYIList.length) {
      const info = `FYI List (${FYIList.length})`;
      text += text ? `, ${info}` : info;
    }

    return text ? text : '-';
  };

  return (
    <div className={styles.editForm}>
      <EditStageInfo
        icon="notification"
        title="Who should receive notifications regarding showings?"
        info={getInfo()}
        onClick={onEdit}
      />
    </div>
  );
};
