import { handleActions } from 'redux-actions';

import { openFeedFilterDrawerAction, setFeedDrawerCommuteIdAction } from 'store/actions/feed';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';

const initialData = {
  open: false,
  commuteId: null,
};

export default handleActions(
  {
    [openFeedFilterDrawerAction]: (state, { payload }) => ({
      ...state,
      open: payload.open || initialData.open,
    }),
    [setFeedDrawerCommuteIdAction]: (state, { payload }) => ({
      ...state,
      commuteId: payload,
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
