import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import {
  PropertyInfo,
  AttachmentDateTitle,
  Attachment,
} from 'pages/Quotes/components/QuoteDetails/components';
import { sortGroupedQuoteDocuments } from 'pages/Quotes/components/QuoteDetails/helpers';

import styles from './styles.module.scss';

const Details = (props) => {
  const { className } = props;
  const { quote, requestorName, groupedByDateQuoteDocuments } =
    useSelector(getQuoteDetailsSelector);

  const getSortedDocuments = () =>
    Object.entries(groupedByDateQuoteDocuments).sort(sortGroupedQuoteDocuments);

  return (
    <div className={classNames(styles.details, className)}>
      <PropertyInfo forName={requestorName} property={quote?.Property} />
      <div className={styles.attachments}>
        {getSortedDocuments().map(([formattedDate, documents]) => (
          <div className={styles.block} key={formattedDate}>
            <AttachmentDateTitle>{formattedDate}</AttachmentDateTitle>
            {documents.map((document) => (
              <div key={document?.Url}>
                <Attachment file={document} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

Details.propTypes = {
  className: PropTypes.string,
};

Details.defaultProps = {
  className: '',
};

export default Details;
