import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Description = ({ className, children }) => (
  <p className={classNames(styles.description, className)}>{children || 'Criteria wizard'}</p>
);

Description.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
};

Description.defaultProps = {
  className: '',
  children: undefined,
};

export default Description;
