import { createAction } from 'redux-actions';

export const requestGetTransactionTemplateAction = createAction('REQUEST/GET_TRANSACTION_TEMPLATE');
export const requestPutTransactionTemplateAction = createAction('REQUEST/PUT_TRANSACTION_TEMPLATE');
export const requestPostTransactionTemplateAction = createAction(
  'REQUEST/POST_TRANSACTION_TEMPLATE',
);
export const requestDeleteTransactionTemplateAction = createAction(
  'REQUEST/DELETE_TRANSACTION_TEMPLATE',
);
export const resetTransactionTemplateDataAction = createAction(
  'RESET_TRANSACTION_TEMPLATE_DATA_OPENED',
);
