import { useSelector } from 'react-redux';

import {
  getDynamicFormSelector,
  getDynamicFormSignatureConfigSelector,
} from 'store/selectors/requestFormProcess';
import { FieldRenderer } from '../../FieldRenderer';

interface OtherSignatoryQuestionsProps {
  pageIndex: number;
  documentIndex?: number;
}

export const OtherSignatoryQuestions = ({
  pageIndex,
  documentIndex = -1,
}: OtherSignatoryQuestionsProps) => {
  const { otherSignatoriesQuestions = [] } = useSelector(getDynamicFormSignatureConfigSelector);

  const { bundleDocumentsDetails = [] } = useSelector(getDynamicFormSelector);

  const isCohesiveFlow = bundleDocumentsDetails.length > 0 && documentIndex >= 0;
  const questions = isCohesiveFlow
    ? bundleDocumentsDetails?.[documentIndex]?.otherSignatoriesQuestions || []
    : otherSignatoriesQuestions;

  const pageQuestions = questions.filter(
    (question) => question.Fields?.[0]?.widgets?.[0]?.pageNumber === pageIndex,
  );

  return (
    <>
      {pageQuestions.map((question) => (
        <FieldRenderer
          question={question}
          key={`active-questions-${question.UUID}`}
          documentIndex={documentIndex}
          isCohesiveFlow={isCohesiveFlow}
          otherSignatoryField
        />
      ))}
    </>
  );
};
