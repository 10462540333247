import { handleActions } from 'redux-actions';

import { openMessageAttachmentsDrawerAction } from 'store/actions/drawers/messageAttachments';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  open: false,
  params: undefined,
};

export default handleActions(
  {
    [openMessageAttachmentsDrawerAction]: (state, { payload }) => ({
      ...initialData,
      open: !!payload.open,
      params: payload.params,
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
