import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { Locations, Input, FormTitle } from 'components';
import { getLocationContext, getPlaceComponent } from 'helpers/locations';
import { LOCALITY, POSTAL_CODE, ROUTE, STATE, STREET_NUMBER } from 'settings/constants/locations';
import { handleSendClientInviteForm } from 'store/effects/clientsList';
import {
  appSetInviteClientAddressesAction,
  appResetInviteClientAddressesAction,
} from 'store/actions/app';
import {
  getClientDrawerSendingInvite,
  getActiveClientTransactionInstance,
  getInvitedClientAddresses,
} from 'store/selectors/app';
import { getInvitedClientValues } from 'store/selectors/app';
import { PENDING } from 'settings/constants/apiState';
import Footer from '../../../Footer';
import { InviteHeader } from '../../../Headers';
import { schema } from './validation';
import { DatePicker } from 'components';
import { Radio } from 'components-antd';
import { Wrapper } from 'pages/OnBoardingWizard/components';
import { ClientAddressEntity } from 'app-constants';
import { DashboardIcon, MessagesIcon } from './Icons';

import styles from './styles.module.scss';
import { getUserSelector } from 'store/selectors/user';

const options = [
  { id: 'yes', label: 'Yes', value: 'yes' },
  {
    id: 'no',
    label: 'No',
    value: 'no',
  },
];

enum Steps {
  EnterDetails = 0,
  QueryForMoreAddresses = 1,
  ShowInvitation = 2,
}

const InviteClientRetentionMode = ({ onNext, stageIndex, onPrev }) => {
  const dispatch = useDispatch();
  const { state } = useSelector(getClientDrawerSendingInvite);
  const activeClientTransactionInstance = useSelector(getActiveClientTransactionInstance);
  const { data: user } = useSelector(getUserSelector);
  const { firstName } = useSelector(getInvitedClientValues);
  const addresses = useSelector(getInvitedClientAddresses);
  const [internalStep, setInternalStep] = useState(Steps.EnterDetails);
  const [defaultAnswer, setDefaultAnswer] = useState('No');
  useEffect(() => {
    if (internalStep === Steps.EnterDetails) {
      formik.resetForm();
    }
  }, [internalStep]);
  const formik = useFormik({
    initialValues:
      Object.keys(activeClientTransactionInstance).length > 0
        ? activeClientTransactionInstance
        : InviteClientRetentionMode.initialValues,
    validateOnMount: true,
    validationSchema: schema,
    onSubmit: (values) => {
      if (internalStep === Steps.QueryForMoreAddresses) {
        if (defaultAnswer === 'Yes') {
          setInternalStep(Steps.EnterDetails);
        }
        if (defaultAnswer === 'No') {
          setInternalStep(Steps.ShowInvitation);
        }
        return;
      }
      if (internalStep === Steps.ShowInvitation) {
        dispatch(
          handleSendClientInviteForm({
            values: {
              FromRetentionMode: InviteClientRetentionMode.initialValues.FromRetentionMode,
            },
          }),
        );
        return;
      }
      dispatch(
        appSetInviteClientAddressesAction({
          address: formatAddress(values.address),
          closingDate: values.closingDate[0],
        }),
      );
      setInternalStep(Steps.ShowInvitation);
    },
  });

  const formatAddress = (addressFromForm) => {
    return {
      address: addressFromForm.PlaceName,
      addressParsed: addressFromForm,
      placeId: addressFromForm.ProviderPlaceId,
      suiteUnit: formik?.values?.suiteUnit,
      isPrimary: true,
      entityType: ClientAddressEntity.RetainingAgent,
      entityId: user.Id,
    };
  };

  const onAddressChange = (result, preparedData) => {
    const location = result?.result?.[0];

    if (location) {
      const updatedCity = getLocationContext(location, LOCALITY);
      const updatedState = getLocationContext(location, STATE);
      const updatedZip = getLocationContext(location, POSTAL_CODE);
      const street = getPlaceComponent(location, ROUTE);
      const streetNumber = getLocationContext(location, STREET_NUMBER);
      const address = {
        City: updatedCity,
        State: updatedState,
        Zip: updatedZip,
        Line1: [streetNumber, street?.name].filter((i) => !!i).join(' '),
        ProviderPlaceId: location.place_id,
        PlaceName: result?.placeName,
        Street: preparedData.length && preparedData[0].Street ? preparedData[0].Street : '',
      };
      formik.setFieldValue('address', address);
    }
  };

  const onClearAddress = () => {
    formik.setFieldValue('address', {});
  };
  const isPending = state === PENDING;

  // Yes NO logic
  const handleYesNo = (value) => {
    setDefaultAnswer(value);
  };

  const getAddressUnit = (unit) => {
    if (unit) return `Apt/Unit ${unit}, `;
    return '';
  };

  return (
    <form
      testid="invite_retention_form"
      className={styles.fullWidth}
      onSubmit={formik.handleSubmit}
    >
      <InviteHeader
        disabled={isPending}
        stageIndex={stageIndex}
        onPrev={() => {
          dispatch(appResetInviteClientAddressesAction());
          onPrev();
        }}
        isValid={formik.isValid}
      />
      {internalStep === Steps.EnterDetails && (
        <div className={styles.formContainer}>
          <FormTitle className={styles.titleHeading}>
            {('What is ' + firstName + "'s" + ' address?') as string}
          </FormTitle>
          <div className={styles.propertyInformation}>
            <Locations
              name="listingAddress"
              onResult={(r, preparedData) => {
                r.deleteItemId ? onClearAddress() : onAddressChange(r, preparedData);
              }}
              label="Address"
              placeholder="Enter Address"
              testid="listing_address"
              value={Object.keys(formik.values.address).length > 0 ? [formik.values.address] : []}
              multiple={false}
              activeInputIconClassName={styles.locationsIcon}
              showAsterisk={true}
              variant={Locations.ROUND}
              placeholderClassName={styles.border}
              searchWrapperClassName={styles.border}
              valuesWrapperClassName={styles.border}
              rounded={true}
              shouldFilterCityLevelResults={true}
            />
            <Input
              disabled={isPending}
              className={styles.inputContainer}
              name="suiteUnit"
              variant={Input.LIGHT_ROUND}
              onChange={formik.handleChange}
              value={formik.values.suiteUnit}
              label="Apt/Unit"
              placeholder="Unit Number"
              error={formik.touched.suiteUnit ? (formik.errors.suiteUnit as string) : ''}
              testid="apt_unit"
              maxLength={20}
            />
            <DatePicker
              name="closingDate"
              className={styles.inputContainer}
              label="What was the closing date for this property?"
              variant={DatePicker.ROUNDED}
              options={{ enableTime: false }}
              onChange={formik.handleChange}
              testid="closingDate"
              allowTypedInput={true}
              allowPlugin={false}
              format={'m/d/Y'}
            />
          </div>
        </div>
      )}
      {internalStep === Steps.QueryForMoreAddresses && (
        <div className={styles.formContainer}>
          <FormTitle className={styles.titleHeading}>Do you want to add another address?</FormTitle>
          <div className={styles.contactInformation}>
            <Radio.Group
              className={styles.yesNoInput}
              disabled={false}
              value={defaultAnswer}
              onChange={(e) => {
                handleYesNo(e.target.value);
              }}
              buttonStyle="solid"
            >
              <Radio.Button className={styles.yesRadioBtn} value="Yes">
                Yes
              </Radio.Button>
              <Radio.Button className={styles.noRadioBtn} value="No">
                No
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
      )}
      {internalStep === Steps.ShowInvitation && (
        <div className={styles.formContainer}>
          <FormTitle className={styles.titleHeading}>
            {'Once ' + firstName + "'s" + ' invitation is accepted:'}
          </FormTitle>
          <div className={styles.invitationInformation}>
            <Wrapper className={styles.addresses}>
              <DashboardIcon className={styles.icon} />
              <span className={styles.title}>
                Homeowner dashboards will be available to {firstName} for:
              </span>
              <ul>
                {addresses.map((entry) => {
                  const address = entry.address.addressParsed;
                  return (
                    <li>
                      <b>{address.Line1}</b>, {getAddressUnit(entry?.address?.suiteUnit)}
                      {address.City}
                      {address.City && ', '}
                      {address.State} {address.Zip}
                    </li>
                  );
                })}
              </ul>
            </Wrapper>
            <Wrapper className={styles.infoMessage}>
              <MessagesIcon className={styles.icon} />
              <span className={styles.title}>
                You can enable KITs for {firstName} from your Clarity module.
              </span>
            </Wrapper>
          </div>
        </div>
      )}
      <Footer
        disabled={isPending}
        buttonTitle={internalStep === Steps.ShowInvitation ? 'Send Invitation' : 'Continue'}
        isValid={formik.isValid || internalStep > Steps.EnterDetails}
        testid="invite_seller_footer"
        className={styles.footer}
      />
    </form>
  );
};

InviteClientRetentionMode.initialValues = {
  address: {},
  suiteUnit: '',
  closingDate: '',
  FromRetentionMode: true,
};

InviteClientRetentionMode.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  stageIndex: PropTypes.number,
};

InviteClientRetentionMode.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  stageIndex: 1,
};

export default InviteClientRetentionMode;
