import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { Autocomplete, Avatar, Select, Checkbox } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { getTransactionParticipantsSelector } from 'store/selectors/transaction';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { getUserDataSelector } from 'store/selectors/user';
import { AGENT } from 'settings/constants/roles';
import { TransactionUserRole, TransactionUserRoleMap } from 'settings/constants/transaction';

import styles from './styles.module.scss';

const AssignToInput = (props) => {
  const {
    className,
    onChange,
    wrapperClassName,
    onBlur,
    valueClassName,
    inputClassName,
    values,
    showIcon,
    isAssignTask,
    showAsterisk,
    isArrowIcon,
    disabled,
    showRole,
  } = props;
  const { onSearchInputChange, onKeyPress, placeholder, label, isSearchIcon } = props;
  const { transactionParticipants, userParticipant } = useSelector(
    getTransactionParticipantsSelector,
  );
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const user = useSelector(getUserDataSelector);

  const getTransactionRole = (p) => {
    if (p?.Roles?.[0] === AGENT) return TransactionUserRole.ListingAgent;
    return p?.Role?.Title || p?.tagRole;
  };

  const processOption = (p) => ({
    id: p.Id || p.id,
    name: `${p?.FirstName || p?.firstName}${
      p?.LastName || p?.lastName ? ` ${p?.LastName || p?.lastName}` : ''
    }$/$${p?.Email || p?.email}`,
    value: p.Id || p?.id,
    role: getTransactionRole(p),
    avatarUrl: p.AvatarUrl || p?.avatarUrl,
    firstName: p.FirstName || p?.firstName,
    lastName: p.LastName || p?.lastName || '',
    email: p?.Email || p?.email,
  });

  const getOptions = () => {
    if (!transactionParticipants) return [];

    const participants = isAssignTask
      ? [...(preForm?.participants ?? []), user]
      : [...transactionParticipants, userParticipant];

    const options = participants.filter((p) => p?.Id || p?.id);
    return options.map((p) => processOption(p));
  };

  const getValues = () => {
    const options = getOptions() || [];
    const result = [];

    values.forEach((val) => {
      const option =
        options.find((option) => option?.value === val?.Id || option?.value === val?.id) || null;
      if (option) {
        result.push(option);
      }
    });
    return result;
  };

  const renderOption = ({ optionProps, optionData, optionSnapshot }) => {
    const optProps = { ...optionProps, value: optionData.value, disabled: optionData.disabled };

    if (optionData?.isEmail) {
      return null;
    }

    return (
      <button
        type="button"
        className={classNames(styles.option, {
          [styles['is-highlighted']]: optionSnapshot.selected,
        })}
        {...optProps}
      >
        <div className={styles.optionContainer}>
          <div className={styles.optionInnerHolder}>
            <Avatar
              avatarClassName={styles.avatar}
              src={optionData.avatarUrl}
              placeholder={
                <div className={styles.avatarPlaceholder}>
                  {convertNameToAvatarPlaceholder(`${optionData.firstName} ${optionData.lastName}`)}
                </div>
              }
            />
            <div>
              <div className={styles.name}>
                <span className={styles.firstName}>{optionData.firstName}</span>{' '}
                <span>{optionData.lastName}</span>
              </div>
              <div className={styles.role}>
                {showRole
                  ? TransactionUserRoleMap[optionData?.role] || optionData?.role
                  : optionData?.email}
              </div>
            </div>
          </div>
          <Checkbox
            labelClassName={classNames(styles.checkbox, styles.checkboxEnd)}
            checked={optionSnapshot.selected}
            checkboxColor="#FFF"
          />
        </div>
      </button>
    );
  };

  const renderValueString = (val) => {
    let result = '';
    if (val) {
      const splitted = val.split(', ');
      splitted.forEach((split, index) => {
        const name = (split || '').split('$/$');
        if (name) result += name[0];
        if (index !== splitted.length - 1) result += ', ';
      });
    }
    return result;
  };

  const localValues = getValues();

  return (
    <div onBlur={onBlur} className={classNames(styles.clientInput, className)}>
      <Select
        disabled={disabled}
        closeOnSelect={false}
        label={label}
        multiple={true}
        isSearchIcon={isSearchIcon}
        isTrackInputValue={false}
        testid="assigned_to_select"
        className={{
          wrapper: classNames(styles.search, wrapperClassName),
          value: classNames(styles.value, valueClassName),
          input: showIcon ? classNames(styles.input, inputClassName) : '',
        }}
        variant={Autocomplete.LIGHT}
        options={getOptions()}
        placeholder={placeholder}
        isEmptyValue
        onSelect={onChange}
        value={localValues}
        renderOption={renderOption}
        searchIconClassName={styles.searchIcon}
        onSearchInputChange={onSearchInputChange}
        onKeyPress={onKeyPress}
        emptyMessage={() => null}
        renderValueString={renderValueString}
        showAsterisk={showAsterisk}
        isArrowIcon={isArrowIcon}
      />
    </div>
  );
};

AssignToInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  valueClassName: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  onSearchInputChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isSearchIcon: PropTypes.bool,
  isAssignTask: PropTypes.bool,
  showAsterisk: PropTypes.bool,
  isArrowIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  showRole: PropTypes.bool,
};

AssignToInput.defaultProps = {
  className: '',
  inputClassName: '',
  showIcon: true,
  wrapperClassName: '',
  onChange: () => {},
  onBlur: () => {},
  valueClassName: '',
  values: [],
  onSearchInputChange: () => {},
  onKeyPress: () => {},
  placeholder: 'Search',
  label: undefined,
  isSearchIcon: false,
  isAssignTask: false,
  showAsterisk: false,
  isArrowIcon: false,
  disabled: false,
  showRole: false,
};

export default AssignToInput;
