import React from 'react';
import classNames from 'classnames';

import BaseNotification from '../../../BaseNotification';
import PropertyIcon from '../../../PropertyIcon';

import styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { link } from 'settings/navigation/link';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';

interface CounterViewedProps {
  Ids: number[];
  className: string;
  title: string;
  IsRead: boolean;
  CreatedDate: string;
  groupType: string;
  SeverityLevel: string;
  Payload: any;
}

const CounterViewed = (props: CounterViewedProps) => {
  const {
    className = '',
    Ids,
    IsRead,
    CreatedDate,
    groupType,
    title,
    SeverityLevel,
    Payload,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickHandler = () => {
    if (Payload?.TransactionId && Payload?.OfferUUID) {
      history.push(link.toTransactionOffers(Payload.TransactionId, Payload.OfferUUID));
      dispatch(openNotificationsDrawerEffect({ open: false }));
    }
  };

  return (
    <div className={classNames(styles.notificationCounterViewed, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        groupType={groupType}
        avatarPlaceholder={<PropertyIcon />}
        severityLevel={SeverityLevel}
        onClick={onClickHandler}
      />
    </div>
  );
};

export default CounterViewed;
