import React, { memo, useMemo } from 'react';

const AddRounded2 = memo(({ className, color = '#50566A' }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="12" fill={color} />
          <path d="M7 12H17" stroke="white" strokeWidth="2" strokeLinecap="round" />
          <path d="M12 17L12 7" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </div>
    ),
    [className],
  ),
);

export default AddRounded2;
