import { SVGProps } from 'react';

const CurvedArrow = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="24" height="24" />
    <path
      d="M15 16V13C15 11.8954 14.1046 11 13 11H5M5 11L8.5 7.5M5 11L8.5 14.5"
      stroke="#252D44"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CurvedArrow;
