export enum inviteClientSteps {
  ClientDetails = 'Details',
  ClientTransactionInstance = 'ClientTransactionInstance',
  ClientSearchInstance = 'ClientSearchInstance',
  ClientWishList = 'ClientWishList',
  ClientSearchInstanceSummary = 'ClientSearchInstanceSummary',
  ClientRetentionMode = 'ClientRetentionMode',
  ClientPreApproval = 'ClientPreApproval',
  ClientNotifications = 'ClientNotifications',
}

export enum YesNoOptions {
  Yes = 'Yes',
  No = 'No',
}
