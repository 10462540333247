import { createAction } from 'redux-actions';

export const requestSearchInstancesAction = createAction('REQUEST/SEARCH_INSTANCES');
export const requestGetSearchInstanceByIdAction = createAction('REQUEST/GET_SEARCH_INSTANCES');
export const setEditedSearchCriteriaAction = createAction('MY_SEARCHES/SET_EDITED_SEARCH_CRITERIA');
export const requestDeactivateSearchInstanceAction = createAction(
  'REQUEST/DEACTIVATE_SEARCH_INSTANCE',
);
export const requestActivateSearchInstanceAction = createAction('REQUEST/ACTIVATE_SEARCH_INSTANCE');
export const requestDeleteSearchInstanceAction = createAction('REQUEST/DELETE_SEARCH_INSTANCE');
export const requestUpdateSearchInstanceAction = createAction('REQUEST/UPDATE_SEARCH_INSTANCE');
export const resetEditedSearchInstanceAction = createAction('RESET/EDITED_SEARCH_INSTANCE');
export const setSearchInstanceStatusAction = createAction('MY_SEARCHES/SET_SEARCH_INSTANCE_STATUS');
export const deleteSearchInstanceAction = createAction('MY_SEARCHES/DELETE_SEARCH_INSTANCE');
export const setRenameSearchInstanceAction = createAction('MY_SEARCHES/RENAME_SEARCH_INSTANCE');
export const requestRenameSearchInstanceAction = createAction('REQUEST/RENAME_SEARCH_INSTANCE');

export const requestSavedSearchesAction = createAction('REQUEST/SAVED_SEARCHES');
export const requestGetSavedSearchByIdAction = createAction('REQUEST/GET_SAVED_SEARCH');
export const requestUpdateSavedSearchAction = createAction('REQUEST/UPDATE_SAVED_SEARCH');
export const requestRenameSavedSearchAction = createAction('REQUEST/RENAME_SAVED_SEARCH');
export const setRenameSavedSearchAction = createAction('MY_SEARCHES/RENAME_SAVED_SEARCH');
export const requestDeleteSavedSearchAction = createAction('REQUEST/DELETE_SAVED_SEARCH');
export const deleteSavedSearchAction = createAction('MY_SEARCHES/DELETE_SAVED_SEARCH');
export const requestActivateSavedSearchAction = createAction('REQUEST/ACTIVATE_SAVED_SEARCH');
export const requestDeactivateSavedSearchAction = createAction('REQUEST/DEACTIVATE_SAVED_SEARCH');
export const setSavedSearchStatusAction = createAction('MY_SEARCHES/SET_SAVED_SEARCH_STATUS');

export const setPreApprovalModalAction = createAction('MY_SEARCHES/PRE_APPROVAL_MODAL');
export const requestPreApprovalDocumentsAction = createAction('REQUEST/GET_PRE_APPROVAL_DOCUMENTS');
export const requestPreApprovalRequestsAction = createAction('REQUEST/GET_PRE_APPROVAL_REQUESTS');
