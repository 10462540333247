import { memo, useMemo } from 'react';

const Cross = memo(({ className, color, size, onClick }: any) =>
  useMemo(
    () => (
      <div className={className} onClick={onClick}>
        <svg
          width={size ?? '20'}
          height={size ?? '20'}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.5 14.5L14.5 5.5" stroke={color} strokeWidth="2" strokeLinecap="round" />
          <path d="M14.5 14.5L5.5 5.5" stroke={color} strokeWidth="2" strokeLinecap="round" />
        </svg>
      </div>
    ),
    [className, color],
  ),
);

export default Cross;
