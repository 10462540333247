import { DrawingManager, Polygon } from '@react-google-maps/api';
import Map from 'components/Map';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { LocationType } from 'types';

const polygonOptions: google.maps.PolygonOptions = {
  strokeColor: '#252D44',
  fillColor: '#252D44',
  fillOpacity: 0.08,
};

interface MapWithDrawingManagerProps {
  getDrawnPolygonSelectorFn: (state: any) => object;
  getIsMapDrawingModeSelectorFn: (state: any) => boolean;
  toggleMapDrawingModeDispatchFn: (newValue: boolean) => Action<unknown>;
  setDrawnPolygonDispatchFn: (polygon: object) => Action<unknown>;
  onBoundsChangedHandlerFn?: (viewportCoordinates, isInitialViewportCoordinate) => void;
  listingCountTextForMap: string;
  isMapEnhancementEnabled: boolean;
  selectedLocations: string[];
  polygonLocations: any;
}

export const MapWithDrawingManager: React.FC<MapWithDrawingManagerProps> = (props) => {
  const { polygonLocations, getIsMapDrawingModeSelectorFn } = props;
  const drawingManager = useRef<google.maps.drawing.DrawingManager>();

  const dispatch = useDispatch();

  const isDrawingMode = useSelector(getIsMapDrawingModeSelectorFn);

  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);

  useEffect(() => {
    if (polygon) {
      polygon.setMap(null);
    }
    if (polygonLocations?.length && !isDrawingMode) {
      setPolygon(geoJsonPolygonToGmaps(polygonLocations?.[0]?.Polygon));
    }
  }, [polygonLocations]);

  const handlerLoadDrawingManager = (drawingManagerInstance) => {
    drawingManager.current = drawingManagerInstance;
  };

  useEffect(() => {
    if (isDrawingMode) {
      if (polygon) {
        polygon.setMap(null);
      }
      setPolygon(null);
    }
  }, [isDrawingMode]);

  const handlePolygonComplete = async (polygon) => {
    setPolygon(polygon);

    // Update redux with the new polygon geojson
    const geojsonPolygon = await gmapsPolygonToGeoJson(polygon);
    const newPolygon = {
      Type: LocationType.Polygon,
      Polygon: geojsonPolygon,
    };
    dispatch(props.setDrawnPolygonDispatchFn(newPolygon));
  };

  return (
    <Map {...props}>
      <DrawingManager
        onLoad={handlerLoadDrawingManager}
        drawingMode={isDrawingMode ? window.google.maps.drawing.OverlayType.POLYGON : null}
        onPolygonComplete={handlePolygonComplete}
        options={{
          polygonOptions,
          drawingControl: false,
          drawingControlOptions: {
            drawingModes: [window.google.maps.drawing.OverlayType.POLYGON], // Enable drawing polygons
          },
        }}
      />
      {polygonLocations?.length && (
        <Polygon
          paths={polygonLocations.map(({ Polygon }) => geoJsonPolygonToGmaps(Polygon).getPath())}
          options={polygonOptions}
        />
      )}
    </Map>
  );
};

async function gmapsPolygonToGeoJson(polygon: google.maps.Polygon): Promise<object> {
  var dataLayer = new window.google.maps.Data();
  dataLayer.add(
    new window.google.maps.Data.Feature({
      geometry: new window.google.maps.Data.Polygon([polygon.getPath().getArray()]),
    }),
  );

  return new Promise((resolve) => {
    dataLayer.toGeoJson((geoJsonValue) => {
      const firstFeature = (geoJsonValue as any).features[0];
      const geojsonPolygon = {
        type: 'Polygon',
        coordinates: firstFeature.geometry.coordinates,
      };
      resolve(geojsonPolygon);
    });
  });
}

function geoJsonPolygonToGmaps(geoJsonPolygon: any): google.maps.Polygon {
  const gmapsPolygon = new google.maps.Polygon();
  const coordinates = geoJsonPolygon.coordinates[0]
    .slice(0, -1) // remove the last point which is the same as the first in geojson
    .map(([lng, lat]) => new google.maps.LatLng(lat, lng));
  gmapsPolygon.setPaths(coordinates);
  return gmapsPolygon;
}
