import { Button } from 'components-antd';
import { Plus } from 'components/Icons';
import { TemplateHeaderSearch } from './TemplateHeaderSearch';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import React from 'react';
import CheckboxComponent from 'components/Form/Checkbox';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_BASIC } from 'settings/constants/roles';

type Props = {
  isTask?: boolean;
  label?: string;
  value?: string;
  onNew?: () => void;
  onSearch?: (value: string) => void;
  onClear?: () => void;
  hideOptions?: boolean;
  templatesCount?: number;
};

export const TemplateHeader: React.FC<Props> = (props) => {
  const {
    isTask = false,
    label = '',
    onNew,
    value = '',
    onSearch,
    onClear,
    hideOptions = false,
    templatesCount,
  } = props;
  const agentRole = useSelector(getAgentTeamRoleSelector);

  return (
    <div className={styles.templateHeader}>
      <h1 className={styles.title}>
        {label}
        <span className={styles.templatesCount}>{` (${templatesCount})`}</span>
      </h1>
      {!hideOptions && (
        <div className={styles.thRight}>
          <TemplateHeaderSearch
            className={styles.searchHeader}
            value={value}
            onSearch={onSearch}
            onClear={onClear}
            isTask={isTask}
          />
          {onNew && agentRole !== TEAM_BASIC ? (
            <Button className={styles.newTemplate} onClick={onNew}>
              <Plus className={styles.iconAdd} />
              New
            </Button>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};
