import { useSelector } from 'react-redux';

import { filterNotificationsByType } from '../../helpers';
import { getGroupedNotificationsSelector } from 'store/selectors/sockets/notifications';
import { geNotificationsDrawerParamsSelector } from 'store/selectors/drawers/notifications';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { EnableNotification } from '../EnableNotification/EnableNotification';

import ByDate from './Notification/ByDate';
import ByUser from './Notification/ByUser';

const Notifications = () => {
  const { isClient } = useSelector(getUserRolesMapSelector);
  const drawerParams = useSelector(geNotificationsDrawerParamsSelector);
  const groupedNotificationsByDate = useSelector(
    getGroupedNotificationsSelector({ filter: drawerParams?.filter, isClient }),
  );
  if (!Object.keys(groupedNotificationsByDate)?.length) {
    return <EnableNotification hasNoNotifications={true} />;
  }
  const getContent = () => {
    const { currentNotificationsUser, type } = drawerParams || {};
    if (currentNotificationsUser && type) {
      const notificationsData = (groupedNotificationsByDate?.[type] || []).find(
        (notific) => notific?.name === currentNotificationsUser,
      );
      const filteredByTypeNotifications = notificationsData
        ? filterNotificationsByType(notificationsData?.notifications?.list, type)
        : [];

      return (
        <ByUser
          groupedNotificationsByUser={filteredByTypeNotifications}
          name={notificationsData?.name}
          avatarUrl={notificationsData?.avatarUrl}
          groupType={type}
        />
      );
    }

    // TODO: as client side in-app notifications can be seen adjusted in below ByDateComponent so no need to use ClientByDate
    // if (isClient) {
    //   return <ClientByDate groupedNotificationsByDate={groupedNotificationsByDate} />;
    // }

    return <ByDate groupedNotificationsByDate={groupedNotificationsByDate} />;
  };
  return getContent();
};

export default Notifications;
