import React from 'react';
import classNames from 'classnames';
import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import BaseNotification from '../../../BaseNotification';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';

interface INewPreApprovalProps {
  Ids: number[];
  Payload: any;
  className?: string;
  title: string;
  IsRead: boolean;
  CreatedDate: string;
  groupType: string;
  SeverityLevel: string;
}

const NewPreApproval = (props: INewPreApprovalProps) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const { ClientFirstName, ClientLastName, Link } = Payload || {};

  const Name = `${ClientFirstName} ${ClientLastName}`;

  const openDocument = () => {
    //INFO: Navigating to external Url to download doc
    window.location.href = Link;
  };

  return (
    <div className={classNames(styles.notificationType, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        groupType={groupType}
        avatarPlaceholder={convertNameToAvatarPlaceholder(Name)}
        severityLevel={SeverityLevel}
        onClick={openDocument}
      />
    </div>
  );
};

export default NewPreApproval;
