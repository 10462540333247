import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Title = (props) => {
  const { className, children } = props;

  return (
    <div testid="title" className={classNames(styles.title, className)}>
      {children}
    </div>
  );
};

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Title.defaultProps = {
  className: '',
};

export default Title;
