import { useState, useMemo } from 'react';

import { Row, Col, Checkbox } from 'antd';
import { Navigation } from '../../components';

import styles from './styles.module.scss';
import { IconText } from '../components/IconText';

import { EditLinkDuration, EditListingAgent, EditOfferDeadline } from '../components';
import { useFetchTeamList } from '../../hooks/useFetchTeamList';
import { Spinner } from 'components';
import { useSelector } from 'react-redux';
import { getUserSelector } from 'store/selectors/user';

type optionType = {
  label: string;
  value: number | string;
};

export const Notifications = ({
  onNext,
  stageIndex,
  lastStageIndex,
  onUpdate,
  onFinish,
  data,
  transaction,
  participantList,
  setCurrentStageIndex,
  stagesStep,
}) => {
  const loggedInUser = useSelector(getUserSelector);

  const { NotificationUserIds } = data;
  const [notificationUserIds, setNotificationdUsersIds] = useState(NotificationUserIds || []);

  const { loading, teamMembers, teamMembersPayload } = useFetchTeamList(participantList);

  const formatName = (item) => {
    return `${item.FirstName}${item.LastName ? ' ' + item.LastName : ''}`;
  };

  const particpantOptions = useMemo(() => {
    let options: optionType[] = [];

    if (!teamMembers.length) return [];

    const userEmail = loggedInUser?.data?.Email;
    const myselfAsParticipant = teamMembers.find((item) => item.Email === userEmail);

    myselfAsParticipant && options.push({ label: 'Me', value: myselfAsParticipant.Id });
    teamMembers.forEach((item) => {
      if (item.Email !== userEmail) {
        options.push({ label: item.name, value: item.Id });
      }
    });

    return options;
  }, [teamMembers, loading]);

  const onSubmit = () => {
    const notifiedParticipantNames = particpantOptions
      .filter((item) => notificationUserIds.includes(item.value))
      .map((item) => item.label);

    const body = {
      NotificationUserIds: notificationUserIds,
      NotifiedParticipantNames: notifiedParticipantNames.join(', '),
    };

    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(lastStageIndex);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const onChange = (values) => {
    setNotificationdUsersIds(values);
  };

  return (
    <div className={styles.notifications}>
      <EditLinkDuration data={data} onEdit={() => setCurrentStageIndex(stagesStep.LinkDuration)} />
      <EditOfferDeadline
        data={data}
        onEdit={() => setCurrentStageIndex(stagesStep.OfferDeadline)}
      />
      <EditListingAgent data={data} onEdit={() => setCurrentStageIndex(stagesStep.ListingAgent)} />

      <IconText
        variant={'notification-bg'}
        text={'Who should receive notifications regarding offers?'}
        className={styles.iconText}
      />

      <Row className={styles.content}>
        {loading ? (
          <Spinner loaderClassName={styles.loadingSpinner} />
        ) : (
          <Col span={24}>
            <div className={styles.checkoxes}>
              <Checkbox.Group
                className={styles.checkboxGroup}
                options={particpantOptions}
                onChange={onChange}
                value={notificationUserIds}
              />
            </div>
          </Col>
        )}
      </Row>

      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onFinish={onFinish}
        disabled={!notificationUserIds.length}
      />
    </div>
  );
};
