import styles from './styles.module.scss';

export const ContactModalHeader = ({ mode }) => {
  const headingText = mode === 'edit' ? 'Edit Contact' : 'Add To Contacts';

  return (
    <div className={styles.contactModalHeader}>
      <div>
        <>
          <h1 className={styles.heading}>{headingText}</h1>
        </>
      </div>
    </div>
  );
};
