import { useEffect, useState } from 'react';

import { Table } from 'components-antd';
import styles from './styles.module.scss';
import { SpinProps } from 'antd';
import { Icons } from 'pages/Workshop/Icons';
import classNames from 'classnames';

interface CollapsibleTableProps {
  title: string;
  defaultCollapse?: Boolean;
  loading?: boolean | SpinProps | undefined;
  dataSource: any;
  onRow?: any;
  columns: any[];
  tableClassName?: string;
  disableCollapse?: boolean;
  showCollapseIcon?: boolean;
  rowClassName?: any;
  collapsibleClassName?: string;
}

export const CollapsibleTable = ({
  title,
  defaultCollapse = false,
  loading = false,
  dataSource,
  onRow = null,
  columns,
  tableClassName,
  disableCollapse = false,
  showCollapseIcon = false,
  rowClassName = '',
  collapsibleClassName = '',
}: CollapsibleTableProps) => {
  const [collapsed, setIsCollapsed] = useState(defaultCollapse);

  useEffect(() => {
    setIsCollapsed(defaultCollapse);
  }, [defaultCollapse]);

  if (!dataSource || dataSource?.length === 0) return null;

  function onClick() {
    if (disableCollapse) return;
    setIsCollapsed(!collapsed);
  }

  return (
    <div className={classNames(styles.collapsibleTable, collapsibleClassName)}>
      <p className={styles.tableTitle} onClick={onClick}>
        {title} {collapsed && <span className={styles.countText}>({dataSource?.length})</span>}
        {/* {!disableCollapse && showCollapseIcon && (
          <span>
            <DownOutlined
              className={classNames(styles.collapseIcon, { [styles.iconRotate]: !collapsed })}
            />
          </span>
        )} */}
      </p>
      {!collapsed && (
        <Table
          className={tableClassName}
          loading={loading}
          bordered={false}
          columns={columns}
          dataSource={dataSource.map((item, key) => ({ ...item, key }))} // Insert unique 'key' in every row
          pagination={false}
          onRow={onRow}
          rowClassName={rowClassName}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => {
              if (!record?.children) return null;
              return expanded ? (
                <Icons
                  variant={Icons.COLLAPSE}
                  className={styles.expandableIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    onExpand(record, e);
                  }}
                />
              ) : (
                <Icons
                  variant={Icons.EXPAND}
                  className={styles.expandableIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    onExpand(record, e);
                  }}
                />
              );
            },
          }}
        />
      )}
    </div>
  );
};
