import { StyleSheet, Font } from '@react-pdf/renderer';

export const PDFstyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 5,
  },
  subheader: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 5,
  },
  homeAddress: {
    fontFamily: 'Helvetica-Bold',
    marginTop: 5,
  },
  propertyDetails: {
    fontSize: 16,
    lineHeight: 1.5,
    marginBottom: 5,
  },
  notes: {
    fontSize: 12,
    lineHeight: 1.5,
    fontFamily: 'Helvetica-Bold',
  },
  clientInfo: {
    fontSize: 12,
    marginBottom: 35,
    color: '#787878',
    textAlign: 'center',
  },
  dateHeader: {
    fontSize: 14,
    marginTop: 12,
    marginBottom: 12,
    fontFamily: 'Helvetica-Bold',
  },
  tourItem: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  time: {
    fontSize: 12,
    width: 80,
    color: '#787878',
    marginRight: 20,
    marginLeft: 10,
    marginTop: 10,
  },
  tourDetails: {
    flex: 1,
    fontSize: 12,
    paddingLeft: 10,
  },
  agentName: {
    fontSize: 12,
  },
  privateNotes: {
    fontSize: 10,
    color: '#666666', // Example color for private notes
    marginTop: 2,
  },
  separator: {
    marginVertical: 2,
  },
});
