import { routes } from 'settings/navigation/routes';
import { Properties } from 'pages';
import { AGENT, CLIENT } from 'settings/constants/roles';
import feed from './feed';
import search from './search';
import mySearches from './mySearches';
import clientSearches from './clientSearches';
import tours from './tours';

export default [
  {
    path: routes.properties,
    component: Properties,
    exact: true,
    roles: [CLIENT, AGENT],
  },
  ...feed,
  ...search,
  ...mySearches,
  ...clientSearches,
  ...tours,
];
