import PropTypes from 'prop-types';
import { Footer } from 'components-antd';
import styles from './styles.module.scss';
import { LeftPanel } from '../LeftPanel';

// TODO: use PageWrapper from components
export const PageWrapper = ({ children }) => (
  <div className={styles.wrapper}>
    <div testid="templates_page" className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.leftMenu}>
          <LeftPanel />
        </div>
        <div className={styles.rightContent}>{children}</div>
      </div>
      <Footer />
    </div>
  </div>
);

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
