import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components-antd';
import { getFormMetaSelect } from 'store/selectors/formBuilder';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getUserFormMetaEffect } from 'store/effects/formBuilder';
import { getAllTeamsEffect } from 'store/effects';
import { FormGeoLocations } from 'pages/FormBuilder/components/FormGeoLocations';
import { getFormattedLocations } from 'helpers';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.scss';

export const Permissions = ({ values, templateId, isTemplate, onChangeField, isViewMode }) => {
  const { isSuperUser } = useSelector(getUserRolesMapSelector);
  if (!isSuperUser || !isTemplate) return <></>;

  const { users: allUsers = [], teams: allTeams = [] } = useSelector(getFormMetaSelect);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuperUser && !allUsers?.length) dispatch(getUserFormMetaEffect());
    if (isSuperUser && !allTeams?.length) dispatch(getAllTeamsEffect());
  }, []);

  const areas = useMemo(() => {
    const val = getFormattedLocations(values?.Locations)?.[0];
    if (!val?.areasOfOperation?.length) return [];
    const grouped = val.areasOfOperation.reduce((acc, cur) => {
      const radius = cur?.Radius || 0;

      if (!acc[radius]) acc[radius] = [cur];
      else acc[radius].push(cur);
      return acc;
    }, {});

    return Object.keys(grouped).map((key, index) => ({
      UUID: uuidv4(),
      miles: Number(key),
      areasOfOperation: grouped[key],
    }));
  }, [values?.Locations]);

  return (
    <div className={styles.permissions}>
      <div className={styles.item}>
        <label>People</label>
        <Select
          placeholder="Select People"
          mode="multiple"
          className={styles.select}
          getPopupContainer={(triggerNode) => triggerNode}
          options={allUsers.map((el) => ({
            label: el.Name,
            value: el.UserId,
            key: `users-${el.UserId}`,
          }))}
          onChange={(val) => onChangeField(val, 'People')}
          value={values?.People ?? []}
          filterOption={(input, option) =>
            String(option?.label || '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          disabled={isViewMode}
        />
      </div>
      <div className={styles.item}>
        <label>Teams</label>
        <Select
          placeholder="Select Teams"
          mode="multiple"
          className={styles.select}
          getPopupContainer={(triggerNode) => triggerNode}
          options={allTeams.map((el) => ({
            label: el.Name,
            value: el.Id,
            key: `Team-${el.Id}`,
          }))}
          onChange={(val) => onChangeField(val, 'Teams')}
          value={values?.Teams ?? []}
          filterOption={(input, option) =>
            String(option?.label || '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          disabled={isViewMode}
        />
      </div>
      {(templateId && values) || !templateId ? (
        <div className={styles.itemPlain}>
          <label>Locations</label>
          <FormGeoLocations
            showMiles={true}
            multiple={true}
            onChange={(val) => onChangeField(val, 'Locations')}
            locations={areas || []}
            disabled={true}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
