import { createSelector } from 'reselect';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getListingDetailData } from 'store/selectors/listingDetail';
import { getThreadsPerPropertyIdSelector } from 'store/selectors/sockets/threads';
// import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';

export const getHomeInfoSelector = createSelector(
  getUserRolesMapSelector,
  getListingDetailData,
  getThreadsPerPropertyIdSelector,
  (userRoles, propertyInfo, threadsPerPropertyId) => ({
    propertyInfo,
    threadsPerPropertyId,
    ...userRoles,
  }),
);
