import Api from 'store/effects/core/api';
import {
  requestGetClientContextAction,
  requestGetClientContextsAction,
  requestGetInActiveClientContextsAction,
} from 'store/actions/context';
import { getClientContext, getClientContexts } from 'api/context';

/**
 * @deprecated
 */
export const getClientContextEffect = (cfg, options, cb) => {
  const requestParams = { action: requestGetClientContextAction, method: getClientContext };
  const sendRequest = Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getClientContextsEffect = (cfg, options, cb) => {
  const requestParams = { action: requestGetClientContextsAction, method: getClientContexts };
  const sendRequest = Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getInActiveClientContextsEffect = (cfg = {}, options, cb) => {
  const requestParams = {
    action: requestGetInActiveClientContextsAction,
    method: getClientContexts,
  };
  const sendRequest = Api.execBase(requestParams);
  return sendRequest({ inActiveOnly: true, ...cfg }, options, cb);
};
