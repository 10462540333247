import moment from 'moment';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';

import { Icons } from '../../Icons';

import styles from './styles.module.scss';

export const Request = ({ request }) => {
  return (
    <div className={styles.preApprovalRequest} key={request.Id}>
      <Avatar
        size={40}
        style={{
          backgroundColor: '#E8E9EA',
          verticalAlign: 'middle',
        }}
        src={request?.Partner?.LogoUrl}
      >
        {request?.Partner?.BusinessName[0]}
      </Avatar>
      <div className={styles.info}>
        <h4>{request?.Partner?.BusinessName}</h4>
        <p>
          Updated on: {moment(request.UpdatedDate).format('MM/DD/YYYY')} at{' '}
          {moment(request.UpdatedDate).format('hh:mm A')}
        </p>
      </div>
      <div className={styles.actions}>
        <div className={styles.viewBtn}>
          <span>View</span>
        </div>
        <div className={styles.commentIcon}>
          <Icons variant="comment" />
        </div>
      </div>
    </div>
  );
};

Request.propTypes = {
  request: PropTypes.objectOf(
    PropTypes.shape({
      Id: PropTypes.number,
      Name: PropTypes.string,
      Status: PropTypes.string,
      Type: PropTypes.string,
      UpdatedDate: PropTypes.string,
      IsArchived: PropTypes.bool,
      Partner: PropTypes.objectOf(
        PropTypes.shape({
          Id: PropTypes.number,
          BusinessName: PropTypes.string,
          Email: PropTypes.string,
          LogoUrl: PropTypes.string,
          PhoneNumber: PropTypes.string,
        }),
      ),
    }),
  ),
};

Request.defaultProps = {
  request: {},
};
