import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ProfileIcons } from '../../Icons';
import {
  appManageClientDrawerAction,
  appDrawerSetAgentIdAction,
  appDrawerSetClientIdAndInstancesAction,
} from 'store/actions/app';
import { setProfileExpandedSection } from 'store/effects/app';

import styles from './styles.module.scss';

const Collapse = ({ contactId, isAgent }) => {
  const dispatch = useDispatch();

  const openUser = () => {
    dispatch(appManageClientDrawerAction(true));
    dispatch(
      isAgent
        ? appDrawerSetAgentIdAction({
            id: contactId,
            open: true,
          })
        : appDrawerSetClientIdAndInstancesAction({
            id: contactId,
            searchInstances: [],
            transactionInstances: [],
            open: true,
          }),
    );
  };

  return (
    <div className={styles.collapseIcon}>
      <ProfileIcons
        name={'Expanded'}
        onClick={() => {
          dispatch(setProfileExpandedSection(false));
          openUser();
        }}
      />
    </div>
  );
};

Collapse.propTypes = {
  contactId: PropTypes.number,
  isAgent: PropTypes.bool,
};

Collapse.defaultProps = {
  contactId: null,
  isAgent: false,
};

export default Collapse;
