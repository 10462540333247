import React from 'react';
export interface DateRange {
  startDate?: Date;
  endDate?: Date;
}

export interface MadeByType {
  Id: number;
  FirstName: string;
  LastName: string;
}

export interface Geolocation {
  Long: number;
  Lat: number;
}

export enum LocationType {
  Neighborhood = 'Neighborhood',
  City = 'City',
  Zipcode = 'Zipcode',
  Box = 'Box',
  Street = 'Street',
  State = 'State',
  Polygon = 'Polygon',
  ViewportCoordinates = 'ViewportCoordinates',
}

export type LocationBox = {
  NorthEast: Geolocation;
  SouthWest: Geolocation;
};

export type Location = {
  Type: LocationType;
  Box?: LocationBox;
  LocationId?: number;
  ProviderPlaceId?: string;
  PlaceName?: string;
  NeighborhoodName?: string;
  City?: string;
  State?: string;
  Zipcode?: string;
  Street?: string;
  UnitNumber?: string;
  Radius?: string | number;
};

export interface ErrorProps {
  Id: string;
  message: string;
  type: string;
}

export interface PropertyLocation {
  City?: string;
  State?: string;
  Zip?: string;
  Line1?: string;
  Line2?: string;
  ProviderPlaceId?: string;
  PlaceName?: string;
}

export interface SelectOption {
  key?: string;
  label: string | React.ReactNode;
  value: string | number;
}

export enum AdminFriendlySubscriptionPlan {
  Complimentary = 'complimentary',
  Paid = 'paid',
  Standard = 'standard',
}
