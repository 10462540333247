import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { deleteTaskTemplateEffect } from 'store/effects/template';
import { getAllTaskTemplatesEffect } from 'store/effects/templates';
import {
  getFilteredGroupedTaskTemplatesSelector,
  getTaskTemplatesSelector,
} from 'store/selectors/templates';
import { Wrapper, ConfirmationDialog } from 'components';
import { TemplateCard } from '../components/TemplateCard';
import { NoTemplatesFound } from '../components/NoTemplates';
import { TemplateHeader } from '../components/TemplateHeader';
import { TaskModal } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModal';
import { setTaskTemplatesQueryAction } from 'store/actions/templates';

import styles from './styles.module.scss';
import { getUserId, getUserRoleSelector } from 'store/selectors/user';
import { Role } from 'app-constants';

export const Tasks = (props) => {
  const { className } = props;
  const dispatch = useDispatch();

  const templates = useSelector(getFilteredGroupedTaskTemplatesSelector);
  const [templateToDelete, setTemplateToDelete] = useState<any>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isNewModal, setIsNewModal] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [template, setTemplate] = useState(null);
  const userRole = useSelector(getUserRoleSelector);
  const userId = useSelector(getUserId);
  const [showAgentTemplates, setShowAgentTemplates] = useState<boolean>(false);

  const { isIdle: isTaskTemplatesIdle, isPending: isTaskTemplatesPending } =
    useSelector(getTaskTemplatesSelector);

  useEffect(() => {
    return (): any => dispatch(setTaskTemplatesQueryAction(''));
  }, []);

  const onTemplateView = (id, obj) => onTemplateEdit(id, obj, true);

  const onTemplateEdit = (id, obj, viewMode = false) => {
    setShowModal(true);
    setIsNewModal(false);
    setIsViewMode(viewMode);
    setTemplateId(id);
    setTemplate({ ...obj });
  };

  const onTemplateDelete = (template) => {
    setTemplateToDelete(template);
  };

  const onConfirmDeleteItem = () => {
    if (!templateToDelete) return;

    setIsDeleting(true);
    dispatch(
      deleteTaskTemplateEffect({ id: templateToDelete.Id }, {}, (err) => {
        if (!err) {
          setTemplateToDelete(null);
        }
        setIsDeleting(false);
      }),
    );
  };

  const onCloseDeleteItemModal = () => setTemplateToDelete(null);

  useEffect(() => {
    if (isTaskTemplatesIdle) {
      dispatch(
        getAllTaskTemplatesEffect({
          ...(showAgentTemplates && { agentTemplates: true }),
        }),
      );
    } else {
      dispatch(
        getAllTaskTemplatesEffect(
          { ...(showAgentTemplates && { agentTemplates: true }) },
          { silent: true },
        ),
      );
    }
  }, [showAgentTemplates]); // eslint-disable-line

  const deleteTemplateContent = useMemo(
    () =>
      templateToDelete ? (
        <div testid="modal_title">
          Are you sure that you want to <b>delete</b> the <b>{templateToDelete.Name}</b> task
          template?
        </div>
      ) : null,
    [templateToDelete],
  );

  const onCloseModal = () => {
    setShowModal(false);
    setIsNewModal(false);
    setTemplate(null);
    setTemplateToDelete(null);
  };

  const onToggleAgentTemplateView = () => setShowAgentTemplates((prev) => !prev);
  const filteredTemplates = useMemo(() => {
    if (showAgentTemplates && userRole === Role.SuperUser) {
      return filterObjectValues(templates, (template) => template.CreatorId !== userId);
    } else if (!showAgentTemplates && userRole === Role.SuperUser) {
      return filterObjectValues(templates, (template) => template.CreatorId === userId);
    }
    return templates;
  }, [templates, showAgentTemplates]);

  return (
    <Wrapper isPending={isTaskTemplatesPending}>
      <TemplateHeader
        label="Task Templates"
        onNew={() => {
          setShowModal(true);
          setIsNewModal(true);
        }}
        isTask={true}
        onToggleAgentTemplateView={onToggleAgentTemplateView}
        showAgentTemplates={showAgentTemplates}
      />
      <div className={classNames(styles.wrapper, className)}>
        {Object.keys(filteredTemplates).length ? (
          Object.keys(filteredTemplates).map((groupName) =>
            filteredTemplates?.[groupName]?.length ? (
              <div
                testid="task_group"
                className={classNames(styles.group, 'groupWrap')}
                key={groupName}
              >
                <h3 testid="group_name" className={styles.category}>
                  {groupName}
                </h3>
                {filteredTemplates?.[groupName].map((template) => (
                  <TemplateCard
                    key={template?.Id}
                    onDelete={onTemplateDelete}
                    template={template}
                    onEdit={onTemplateEdit}
                    onView={onTemplateView}
                  />
                ))}
              </div>
            ) : null,
          )
        ) : (
          <NoTemplatesFound className={styles.noTemplates} templatesName="task" />
        )}
        <ConfirmationDialog
          onReject={onCloseDeleteItemModal}
          onConfirm={onConfirmDeleteItem}
          isOpen={!!templateToDelete}
          isPending={isDeleting}
        >
          {deleteTemplateContent}
        </ConfirmationDialog>
      </div>
      <TaskModal
        isOpen={showModal}
        isNew={isNewModal}
        isTemplate={true}
        templateId={templateId}
        editData={template}
        onCloseModal={onCloseModal}
        isViewMode={isViewMode}
      />
    </Wrapper>
  );
};

Tasks.propTypes = {
  className: PropTypes.string,
};

Tasks.defaultProps = {
  className: '',
};

function filterObjectValues(obj, condition) {
  const result = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = obj[key].filter(condition);
    }
  }

  return result;
}
