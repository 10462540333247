import { Row } from 'components-antd';

import styles from './style.module.scss';
import classNames from 'classnames';

import { Icons } from '../../../../../Icons';
import { RemoveFile } from 'components/Icons';
import { Fragment } from 'react';

export const FieldContainer = (props) => {
  const {
    label,
    icon,
    inputClass,
    inputIconClass,
    children: fieldComponent,
    onRemoveField,
    removeClass,
  } = props;

  return (
    <Fragment>
      <div className={styles.fieldContainer}>
        {label && <div className={styles.fieldLabel}>{label}</div>}

        <Row className={classNames(styles.selectInput, inputClass)}>
          {icon && (
            <div className={classNames(styles.inputIcon, inputIconClass)}>
              <Icons variant={icon} stroke="#747475" />
            </div>
          )}

          {fieldComponent}
        </Row>
      </div>
      {onRemoveField && <RemoveFile className={removeClass} onClick={onRemoveField} />}
    </Fragment>
  );
};
