import { createSelector } from 'reselect';
import { groupBy, find, get } from 'lodash-es';
import { groupQuotesBy, quotesStatusesMap } from 'settings/constants/quotes';
import { LocationService } from 'services';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { getRouterSelector } from '../router';
import { convertQuotesData, groupByClientName, filterArchivedQuotes } from './helpers';
import { getUserRoleSelector, getUserId } from '../user';
import {
  getOpenedChatThreadInfo,
  getOpenedPropertyThreadInfo,
  getOpenedQuoteThread,
} from '../sockets/threads';
import { getQuoteActivityDrawerParams } from '../drawers/viewQuoteActivity';
import { THIRD_PARTY } from 'settings/constants/roles';

const locationSrv = new LocationService();

const localState = ({ quotes }) => quotes;

export const getQuotesSelector = createSelector(localState, (quotes) => ({
  isIdle: quotes.state === IDLE,
  isPending: quotes.state === PENDING,
  isData: !!quotes.data,
  quotes,
}));

export const getQuotesAuditLogsSelector = createSelector(
  localState,
  (quotes) => quotes.userQuoteAuditLogs,
);

const getRecipientAuditLogs = (qouteAuditLogs, threadInfo, userId) => {
  const { thread } = threadInfo;
  const viewer = thread?.Participants?.find((p) => p.Id === userId);
  const recipient = thread?.Participants?.find((p) => p.Id !== userId);

  let filterField = viewer.Roles[0] === THIRD_PARTY ? 'QuoteRequester' : 'QuotePartner';
  return qouteAuditLogs.filter((log) => log?.Payload[filterField].Id === recipient?.Id);
};

export const getQuotesAuditLogsForChatRecipient = createSelector(
  getQuotesAuditLogsSelector,
  getOpenedChatThreadInfo,
  getUserId,
  getRecipientAuditLogs,
);

export const getQuotesAuditLogsForPropertyRecipient = createSelector(
  getQuotesAuditLogsSelector,
  getOpenedPropertyThreadInfo,
  getUserId,
  getRecipientAuditLogs,
);

export const getQuotesDataSelector = createSelector(
  getQuotesSelector,
  ({ quotes }) => quotes?.data,
);

export const getQuotesGroupBySelector = createSelector(
  getQuotesSelector,
  ({ quotes }) => quotes?.groupBy,
);

export const getIsArchiveQuotesSelector = createSelector(
  getQuotesSelector,
  ({ quotes }) => !!quotes?.isArchive,
);

export const getSearchQuotesSelector = createSelector(
  getQuotesSelector,
  ({ quotes }) => quotes?.search,
);

const getSearchQuotes = (data, search, groupByName, groupId) => {
  let result = (data || []).filter((quote) => {
    if (search) {
      const formattedSearch = search.toLowerCase();

      const city = get(quote, 'Property.Address.City') || '';
      const line1 = get(quote, 'Property.Address.Line1') || '';
      const state = get(quote, 'Property.Address.State') || '';
      const zip = get(quote, 'Property.Address.Zip') || '';
      const address = `${line1 ? `${line1},` : ''} ${
        city ? `${city},` : ''
      } ${state} ${zip}`.trim();

      const isPropertyAddressMatch = address.toLowerCase().includes(formattedSearch);

      const client = quote?.Client;
      const clientName = client ? `${client.FirstName} ${client.LastName}` : '';
      const requestor = quote?.Requestor;
      const requestorName = requestor ? `${requestor.FirstName} ${requestor.LastName}` : '';
      const partner = quote?.Partner ?? quote?.Partners[0];
      const partnerName = partner ? `${partner?.BusinessName}` : '';
      const category = get(quote, 'Category.Title');
      if (!groupId) {
        switch (groupByName) {
          case groupQuotesBy.CATEGORY:
            return category.toLowerCase().includes(formattedSearch);
          case groupQuotesBy.CLIENT:
            return clientName.toLowerCase().includes(formattedSearch);
          case groupQuotesBy.PROPERTY:
            return isPropertyAddressMatch;
        }
      }
      if (groupId) {
        switch (groupByName) {
          case groupQuotesBy.CATEGORY:
            return (
              isPropertyAddressMatch ||
              clientName.toLowerCase().includes(formattedSearch) ||
              requestorName.toLowerCase().includes(formattedSearch) ||
              partnerName.toLowerCase().includes(formattedSearch)
            );
          case groupQuotesBy.CLIENT:
            return (
              isPropertyAddressMatch ||
              requestorName.toLowerCase().includes(formattedSearch) ||
              category.toLowerCase().includes(formattedSearch) ||
              partnerName.toLowerCase().includes(formattedSearch)
            );
          case groupQuotesBy.PROPERTY:
            return (
              clientName.toLowerCase().includes(formattedSearch) ||
              requestorName.toLowerCase().includes(formattedSearch) ||
              category.toLowerCase().includes(formattedSearch) ||
              partnerName.toLowerCase().includes(formattedSearch)
            );
        }
      }
      return (
        isPropertyAddressMatch ||
        clientName.toLowerCase().includes(formattedSearch) ||
        requestorName.toLowerCase().includes(formattedSearch) ||
        category.toLowerCase().includes(formattedSearch) ||
        partnerName.toLowerCase().includes(formattedSearch)
      );
    }
    return true;
  });

  return result;
};

export const getGroupedQuotesSelector = createSelector(
  getQuotesDataSelector,
  getQuotesGroupBySelector,
  getIsArchiveQuotesSelector,
  getRouterSelector,
  getUserRoleSelector,
  getSearchQuotesSelector,
  (initQuotes, groupByPropName, isArchive, router, userRole, search) => {
    const { groupId, groupBy: queryGroupBy } = locationSrv.setLocation(router.location).getQuery();
    const quotesData = getSearchQuotes(initQuotes, search, groupByPropName, groupId);

    let isGroupQuotes = false;

    const groupByStatus = () => {
      const filteredQuotes = filterArchivedQuotes(quotesData, isArchive);
      return (queryGroupBy || groupByPropName) === groupQuotesBy.STATUS
        ? groupBy(
            groupId ? filteredQuotes : initQuotes,
            (item) => quotesStatusesMap(userRole)[item?.Status],
          )
        : {};
    };

    const groupByArchieveStatus = () => {
      const filteredQuotes = filterArchivedQuotes(quotesData, true);
      return isArchive ? { ByArchieve: filteredQuotes } : {};
    };

    const groupByCategory = () => {
      const byCategory =
        (queryGroupBy || groupByPropName) === groupQuotesBy.CATEGORY
          ? groupBy(quotesData, 'Category.Title')
          : {};
      const convertedQuotes = convertQuotesData({ arrQuotes: byCategory, isArchive, userRole });
      if (!isGroupQuotes) {
        isGroupQuotes = convertedQuotes.some((quote) => quote?.name === groupId);
      }
      return convertedQuotes;
    };

    const groupByClient = () => {
      const byClientName =
        (queryGroupBy || groupByPropName) === groupQuotesBy.CLIENT
          ? groupByClientName(quotesData)
          : {};
      const convertedQuotes = convertQuotesData({
        arrQuotes: byClientName,
        isArchive,
        additionalParams: { isGroupedByClient: true },
        userRole,
      });

      if (!isGroupQuotes) {
        isGroupQuotes = convertedQuotes.some((quote) => quote?.name === groupId);
      }

      return convertedQuotes;
    };

    const groupByProperty = () => {
      const byProperty =
        (queryGroupBy || groupByPropName) === groupQuotesBy.PROPERTY
          ? groupBy(quotesData, (item) => get(item, 'Property.Address.Line1', 'Ungrouped'))
          : {};
      const convertedQuotes = convertQuotesData({ arrQuotes: byProperty, isArchive, userRole });

      if (!isGroupQuotes) {
        isGroupQuotes = convertedQuotes.some((quote) => quote?.name === groupId);
      }

      return convertedQuotes;
    };

    return {
      byStatus: groupByStatus(),
      byCategory: groupByCategory(),
      byClient: groupByClient(),
      byProperty: groupByProperty(),
      byArchieve: groupByArchieveStatus(),
      isGroupQuotes,
      groupId,
      groupByPropName: queryGroupBy || groupByPropName,
      url: locationSrv.getUrlWithQuery(),
      quotesData,
      isArchive,
    };
  },
);

export const getQuoteByOpenedThreadId = createSelector(
  getQuotesDataSelector,
  getOpenedQuoteThread,
  (list, { EntityId }) => {
    const quote = find(list || [], ({ Id }) => Id === Number(EntityId));
    return {
      category: quote?.Category?.Title,
      property: quote?.Property?.Address?.Line1 || quote?.Property?.Address?.Line2,
    };
  },
);

export const getQuotesVendorsSelector = createSelector(getQuotesDataSelector, (quotes) => {
  if (!quotes || quotes?.length <= 0) {
    return [];
  }

  const clientNames = new Set();

  quotes?.forEach((item) => {
    if (item?.Partner?.BusinessName) {
      const clientName = `${item?.Partner?.BusinessName}`;
      clientNames.add(clientName);
    }
  });

  const uniqueClientNames = Array.from(clientNames);
  return uniqueClientNames;
});

export const getQuotesClientsSelector = createSelector(getQuotesDataSelector, (quotes) => {
  if (!quotes || quotes?.length <= 0) {
    return [];
  }
  const clients = {};

  quotes?.forEach((item) => {
    if (item?.Client?.Id && !Object.prototype.hasOwnProperty.call(clients, `${item?.Client?.Id}`)) {
      const clientName = `${item?.Client?.FirstName} ${item?.Client?.LastName}`;
      clients[`${item?.Client?.Id}`] = clientName;
    }
  });

  return clients;
});

export const getQuotesCategoriesSelector = createSelector(getQuotesDataSelector, (quotes) => {
  if (!quotes || quotes?.length <= 0) {
    return [];
  }

  const categoryTitles = new Set();

  quotes.forEach((item) => {
    const categoryTitle = item?.Category?.Title;
    if (categoryTitle) {
      categoryTitles.add(categoryTitle);
    }
  });

  const uniqueCategories = Array.from(categoryTitles);
  return uniqueCategories;
});

export const getQuotesPropertiesSelector = createSelector(getQuotesDataSelector, (quotes) => {
  if (!quotes || quotes?.length <= 0) {
    return [];
  }

  const properties = new Set();

  quotes.forEach((item) => {
    const propertyAddress = item?.Property?.Address?.Line1;
    if (propertyAddress) {
      properties.add(propertyAddress);
    }
  });

  const uniqueProperties = Array.from(properties);
  return uniqueProperties;
});
