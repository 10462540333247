import { useState } from 'react';
import { cloneDeep } from 'lodash-es';

import { ServiceBox } from '../ServiceBox';
import { ServiceInputBox } from '../ServiceInputBox';
import { Plus } from 'components/Icons';

import styles from './styles.module.scss';

export const Content = ({ serviceList, setServiceList }) => {
  const [isView, setIsView] = useState<boolean>(false);

  const addService = (newService) => {
    const existing = serviceList.some((service) => service.value === newService.value);
    if (!existing) setServiceList([...serviceList, newService]);
  };

  const handleCheckBoxChange = (id) => {
    const list = cloneDeep(serviceList);
    list[id].isChecked = !serviceList[id].isChecked;
    setServiceList(list);
  };

  const handleDelete = (id) => setServiceList(serviceList.filter((service, index) => index !== id));

  return (
    <>
      {serviceList.map(({ label, isChecked, isDeletable }, index) => (
        <ServiceBox
          label={label}
          isChecked={isChecked}
          isDeletable={isDeletable}
          handleDelete={() => handleDelete(index)}
          handleCheckBoxChange={() => handleCheckBoxChange(index)}
          key={index}
        />
      ))}
      {isView && <ServiceInputBox addService={addService} onClose={() => setIsView(false)} />}
      <div className={styles.addService} onClick={() => setIsView(true)}>
        <Plus className={styles.plus} color={'#515151'} />
        <span>Add a service</span>
      </div>
    </>
  );
};
