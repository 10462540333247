import { getPartnerById } from 'api/singlePartner';

import Api from 'store/effects/core/api';
import { requestGetPartnerByIdAction } from 'store/actions/singlePartner';

export const requestGetPartnerByIdEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetPartnerByIdAction,
    method: getPartnerById,
  });

  return sendRequest(cfg, {}, cb);
};
