import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import { offersStatus } from 'settings/constants/offer';
import { Icon } from 'components/Transactions';
import { link } from 'settings/navigation/link';
import { routes } from 'settings/navigation/routes';
import { Back, NavItem, Project, Property } from './components';
import { Close, Hamburger } from 'components/Icons';
import { Divider } from 'components-antd';
import {
  getTransactionParticipantSelector,
  getTransactionAccessSelector,
  getTransactionSelector,
  getTranslationAdminAccessSelector,
  getAdminOwnerAccessSelector,
} from 'store/selectors/transaction';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { TransactionUserRole } from 'settings/constants/transaction';
import { getOfferClientVisibility } from 'api/transactions';
import { getUserRolesSelector } from 'store/selectors/user';
import { CLIENT, Role } from 'settings/constants/roles';
import useIsProjectRoute from 'hooks/use-is-project-route';
import NotesModal from '../../../pages/Workshop/Transactions/components/Notes/NotesModal/NotesModal.tsx';

import styles from './styles.module.scss';

const AsideNavigation = (props) => {
  const { className } = props;
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const [showNavigation, setShowNavigation] = useState(false);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);
  const { isTransactionAdminOrOwner } = useSelector(getTranslationAdminAccessSelector);
  const { isAdminOrOwner } = useSelector(getAdminOwnerAccessSelector);
  const participant = useSelector(getTransactionParticipantSelector);
  const loggedinUserRoles = useSelector(getUserRolesSelector);
  const transaction = useSelector(getTransactionSelector);
  const [showOfferLinkToClient, setShowOfferLinkToClient] = useState(false);
  const [isNewNote, setIsNewNote] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const isProject = useIsProjectRoute();
  const { pathname } = useLocation();

  const showShowingsNavigation = useMemo(
    () =>
      isTransactionAdminOrOwner ||
      (fullAccess && isTeamAgentActive) ||
      // Check if the client have any upcoming pending showings then show the showing tab
      (loggedinUserRoles.includes(Role.Client) &&
        (!!transaction.stats?.showings.Cancelled ||
          !!transaction.stats?.showings.Pending ||
          !!transaction.stats?.showings?.Declined)),
    [
      transaction,
      isTransactionAdminOrOwner,
      fullAccess,
      isTeamAgentActive,
      participant,
      loggedinUserRoles,
    ],
  );

  const onBack = () => {
    if (isProject) history.push(routes.projects);
    else history.push(routes.transactions);
  };
  const fetchOfferMenuVisibility = async () => {
    const visibility = await getOfferClientVisibility(transaction.transaction.Id);
    setShowOfferLinkToClient(visibility?.data.value);
  };
  useEffect(() => {
    function handleWindowSizeChange() {
      if (window.innerWidth <= 1200) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    if (document.readyState === 'complete') {
      handleWindowSizeChange();
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.addEventListener('load', handleWindowSizeChange);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (transaction.isData) {
      redirectToCurrentTypeRoute();
    }
  }, [transaction.isPending]);

  useEffect(() => {
    transaction?.transaction.Notes?.forEach((note) => {
      if (note.SenderUserId !== transaction.transaction.userId) {
        note?.MessageReceipts?.some((receipt) => {
          if (receipt.UserId === transaction.transaction.userId && !receipt.IsRead) {
            setIsNewNote(true);
            return true;
          }
        });
      }
    });
  }, [transaction?.transaction.Id]);

  const redirectToCurrentTypeRoute = () => {
    if (
      transaction?.transaction?.IsProject &&
      !pathname.includes('project') &&
      pathname.includes('transaction')
    ) {
      history.push(pathname.replace('transaction', 'project'));
    } else if (
      !transaction?.transaction?.IsProject &&
      !pathname.includes('transaction') &&
      pathname.includes('project')
    ) {
      history.push(pathname.replace('project', 'transaction'));
    }
  };
  const showOrHideDecision = (ObjName, key) =>
    (transaction?.transaction?.[ObjName]?.[key] ||
      transaction?.transaction?.RepresentingRoles?.join('') === TransactionUserRole.Seller) &&
    !isProject
      ? true
      : false;

  useEffect(() => {
    if (isMobile) setShowNavigation(false);
  }, [isMobile]);

  useEffect(() => {
    if (
      loggedinUserRoles?.includes(CLIENT) &&
      [TransactionUserRole.Seller].includes(participant?.Role?.Title)
    ) {
      fetchOfferMenuVisibility();
    }
  }, [participant]);

  return (isMobile && showNavigation) || !isMobile ? (
    <div testid="aside_nav" className={classNames(styles.asideNavigation, className)}>
      {isMobile ? (
        <button className={styles.navTogglerClose}>
          <Close className={styles.closeIcon} onClick={() => setShowNavigation(!showNavigation)} />
        </button>
      ) : (
        ''
      )}
      <Back text={isProject ? 'Projects' : 'Transactions'} onClick={onBack} />
      {isProject ? <Project /> : <Property />}
      <NavItem
        activeClassName={styles.overviewIconActive}
        icon={Icon.OVERVIEW}
        label="Overview"
        linkTo={isProject ? link.toProjectOverview : link.toTransactionOverview}
      />
      <NavItem
        activeClassName={styles.contactActive}
        icon={Icon.CONTACTS}
        label="Contacts"
        linkTo={isProject ? link.toProjectDetails : link.toTransactionDetails}
      />
      {(isTransactionAdminOrOwner || (fullAccess && isTeamAgentActive)) && (
        <>
          {showOrHideDecision('OfferLink', 'Link') && (
            <NavItem icon={Icon.DOCUMENT} label="Offers" linkTo={link.toTransactionOffers} />
          )}
        </>
      )}
      {showShowingsNavigation && (
        <>
          {showOrHideDecision('Showing', 'ShowingLink') && (
            <NavItem icon={Icon.CALENDAR} label="Showings" linkTo={link.toTransactionShowings} />
          )}
        </>
      )}
      <NavItem
        icon={Icon.TASKS}
        label="Tasks"
        linkTo={isProject ? link.toProjectTasks : link.toTransactionTasks}
      />
      <NavItem
        icon={Icon.DOCUMENTS}
        label="Files"
        linkTo={isProject ? link.toProjectDocuments : link.toTransactionDocuments}
      />
      {!isProject && (isAdminOrOwner || (fullAccess && isTeamAgentActive)) && (
        <NavItem
          activeClassName={styles.contactActive}
          icon={Icon.FINANCIALS}
          label="Financials"
          linkTo={link.toTransactionFinancials}
        />
      )}
      {fullAccess ? (
        <NavItem
          icon={Icon.ACTIVITY}
          label="Activity"
          linkTo={isProject ? link.toProjectActivity : link.toTransactionActivity}
        />
      ) : (
        <></>
      )}
      <Divider type="horizontal" className={styles.divider} />(
      <div className={styles.notesContainer} onClick={() => setShowNotes(true)}>
        <div className={styles.notes}>
          <Icon className={classNames(styles.icon)} variant={'notes'} />
          <div className={classNames(styles.label, { [styles.isUnread]: isNewNote })}>Notes</div>
        </div>
      </div>
      ){showNotes ? <NotesModal handleCloseNotesModal={() => setShowNotes(false)} /> : null}
    </div>
  ) : (
    <span className={styles.transactionsMenu} onClick={() => setShowNavigation(!showNavigation)}>
      <Hamburger className={styles.hamburgerIcon} />
      <span className={styles.transactionsMenuText}>Sidebar</span>
    </span>
  );
};

AsideNavigation.propTypes = {
  className: PropTypes.string,
};

AsideNavigation.defaultProps = {
  className: '',
};

export default AsideNavigation;
