import { Logger } from 'services';

export const getMinMaxAsStringValues = (fieldName, values, defaultValues) => {
  if (
    !fieldName ||
    typeof defaultValues !== 'object' ||
    !defaultValues ||
    !defaultValues?.[fieldName]
  ) {
    Logger.log('Helpers: Invalid input for getMinMaxAsString', {
      fieldName,
      values,
      defaultValues,
    });
    return {
      Min: '',
      Max: '',
    };
  }

  return typeof values === 'object' && values?.[fieldName]
    ? {
        Min: `${values[fieldName]?.Min || defaultValues[fieldName]?.Min || ''}`,
        Max: `${values[fieldName]?.Max || defaultValues[fieldName]?.Max || ''}`,
      }
    : defaultValues[fieldName];
};
