import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { changeMessagesDrawerTypeAction } from 'store/actions/drawers/messages';
import {
  getIsMessagesDrawerTypeSelector,
  getMessagesDrawerParams,
} from 'store/selectors/drawers/messages';
import { getUnreadThreadsCount } from 'store/selectors/sockets/threads';

import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';

import { DrawerHeaderTitle, DrawerCancel, Button } from 'components';
import Icon from 'pages/Properties/Feed/Icons';
import { Archive } from 'components/Icons';

import SearchMessages from '../Content/SearchMessages';

import styles from './styles.module.scss';
import DrawerHeaderControlls from '../DrawerHeaderControlls';

// TODO: refactor
const MessagesDrawerHeader = ({ isOpen, className, onClose, onNewMessage, onArchive }) => {
  const dispatch = useDispatch();
  const type = useSelector(getIsMessagesDrawerTypeSelector);
  const drawerParams = useSelector(getMessagesDrawerParams);
  const unreadCount = useSelector(getUnreadThreadsCount);

  const isUnreadOpened = type === DRAWER_MESSAGES_TYPES.UNREAD;
  const toggleUnread = useCallback(
    () =>
      dispatch(
        changeMessagesDrawerTypeAction({
          type: isUnreadOpened ? DRAWER_MESSAGES_TYPES.INIT : DRAWER_MESSAGES_TYPES.UNREAD,
        }),
      ),
    [isUnreadOpened, dispatch],
  );

  if (
    type === DRAWER_MESSAGES_TYPES.CLIENT ||
    type === DRAWER_MESSAGES_TYPES.CHAT ||
    type === DRAWER_MESSAGES_TYPES.NEW_MESSAGE ||
    type === DRAWER_MESSAGES_TYPES.ARCHIVED
  ) {
    return null;
  }

  if (drawerParams?.isPartnerGroup) {
    return (
      <div className={styles.wrapper}>
        <DrawerHeaderControlls archived={type === DRAWER_MESSAGES_TYPES.ARCHIVED} />
        <div className={classNames(styles.header, className)}>
          <div className={styles.titleWrapper}>
            <DrawerHeaderTitle>Messages</DrawerHeaderTitle>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.header, className)}>
        <div className={styles.titleWrapper}>
          <DrawerHeaderTitle>Messages</DrawerHeaderTitle>
        </div>
        <div testid="header_actions" className={styles.actions}>
          <Icon
            testid="edit_icon"
            onClick={onNewMessage}
            className={styles.editIcon}
            variant={Icon.EDIT_COLOR}
          />
          <Archive testid="archive_icon" onClick={onArchive} className={styles.archiveIcon} />
          <DrawerCancel onClick={onClose} />
        </div>
      </div>
      <div className={styles.searchMessages}>
        {isOpen && <SearchMessages className={styles.searchMessagesInput} />}
        <Button
          onClick={toggleUnread}
          className={classNames(styles.searchMessagesBtn, { [styles.active]: isUnreadOpened })}
          title={`Unread${unreadCount ? ` (${unreadCount})` : ''}`}
          testid="unread_button"
        />
      </div>
    </div>
  );
};

MessagesDrawerHeader.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onNewMessage: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

MessagesDrawerHeader.defaultProps = {
  className: '',
};

export default MessagesDrawerHeader;
