import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notification from 'antd/lib/notification';
import classNames from 'classnames';

import { Spinner } from 'components';
import { PostForm } from '../../components';
import {
  getDynamicPostFormEffect,
  getPostFormDataEffect,
  removeEditorEffect,
  replaceEditorEffect,
  replaceSignatoryEffect,
  resetPostFormEffect,
  resetPreFormEffect,
  sendFormProcessDocumentEffect,
  updateSignatureSequenceEffect,
} from 'store/effects/formProcess';
import {
  getDynamicPostFormSelector,
  getPostFormSelector,
  getRequestFormProcessSelector,
} from 'store/selectors/requestFormProcess';
import { ReplaceEntityModal } from 'pages/Workshop/Forms/components/FormDetailModal/components/ReplaceEntityModal';
import { AgentsType, ClientsType, DynamicPostForm } from 'types';

import styles from '../styles.module.scss';
interface SendDocumentProps {
  formProcessPublicId?: string;
  formDocumentPublicId?: string;
  handleExit?: () => void;
  back?: () => void;
  handleDone: () => void;
  handlePreview?: () => void;
  dynamicForm?: boolean;
  isBundle?: boolean;
}

export const SendDocument = ({
  formProcessPublicId,
  formDocumentPublicId,
  handleExit,
  back,
  handleDone,
  handlePreview,
  dynamicForm,
  isBundle,
}: SendDocumentProps) => {
  const dispatch = useDispatch();

  const postForm =
    (dynamicForm ? useSelector(getDynamicPostFormSelector) : useSelector(getPostFormSelector)) ||
    {};

  const remainingQuestions = postForm?.remainingQuestions;

  const { isPending, formDocument } = useSelector(getRequestFormProcessSelector);

  const isLoading = dynamicForm ? !(postForm as DynamicPostForm)?.isReady : !!isPending;

  const [replaceEntityModal, setReplaceEntityModal] = useState<{
    open: boolean;
    isEditor?: boolean;
    entityId?: number;
    name?: string;
    email?: string;
    role?: string;
    options?: ClientsType & AgentsType;
  }>({ open: false });

  useEffect(() => {
    if (!Object.keys(postForm).length) {
      getPostForm();
    }
  }, [postForm]);

  useEffect(() => {
    if (remainingQuestions && back && !dynamicForm) {
      back();
    }
  }, [remainingQuestions]);

  useEffect(() => {
    return () => {
      dispatch(resetPostFormEffect());
      dispatch(resetPreFormEffect());
    };
  }, []);

  const getPostForm = (shouldProgress = true) => {
    if ((formProcessPublicId, formDocumentPublicId)) {
      const handleResponse = (err, resp) => {
        if (!err && resp?.data?.value) {
          const { editorsPresent, signatoriesPresent } = resp.data.value;
          if (!editorsPresent && !signatoriesPresent && shouldProgress) {
            handlePostDone('');
          }
        }
      };

      if (dynamicForm) {
        dispatch(
          getDynamicPostFormEffect({ formProcessPublicId, formDocumentPublicId }, handleResponse),
        );
      } else {
        dispatch(
          getPostFormDataEffect({ formProcessPublicId, formDocumentPublicId }, handleResponse),
        );
      }
    }
  };

  const handlePostDone = (message: string, optionalQuestionIds?: number[]) => {
    dispatch(
      sendFormProcessDocumentEffect(
        {
          formProcessPublicId,
          formDocumentPublicId,
          message,
          optionalQuestionIds,
        },
        (err) => {
          if (!err) {
            handleDone();
          }
        },
      ),
    );
  };

  const updateSignatureSequence = (isSequential, signatories, onUpdate) => {
    if (formProcessPublicId && formDocumentPublicId) {
      dispatch(
        updateSignatureSequenceEffect(
          {
            formProcessPublicId,
            formDocumentPublicId,
            isSequential,
            signatories,
          },
          () => {
            getPostForm();
            onUpdate();
          },
        ),
      );
    }
  };

  const handleRemoveEditor = (editorId) => {
    if (formProcessPublicId && formDocumentPublicId) {
      dispatch(
        removeEditorEffect(
          {
            formProcessPublicId,
            formDocumentPublicId,
            oldEditorId: editorId,
          },
          () => {
            notification.success({
              message: 'Removed editor',
              placement: 'top',
            });
            if (back) back();
          },
        ),
      );
    }
  };

  const closeReplaceEntityModal = () => {
    setReplaceEntityModal({ open: false });
  };

  const handleReplaceEntity = ({
    isEditor,
    oldId,
    userId,
    email,
    setLoading: loadModal,
    onCloseModal,
  }: {
    isEditor?: boolean;
    oldId?: number;
    userId?: number;
    email?: string;
    setLoading: (loading: boolean) => void;
    onCloseModal: () => void;
  }) => {
    if (formProcessPublicId && formDocumentPublicId) {
      loadModal(true);

      const payload = {
        formProcessPublicId,
        formDocumentPublicId,
        ...(isEditor ? { oldEditorId: oldId } : { oldSignatoryId: oldId }),
        newUser: {
          ...(userId ? { userId: userId } : { email: email }),
        },
      };
      const handleResponse = (err) => {
        if (!err) {
          getPostForm(false);
          setTimeout(() => {
            onCloseModal();
          }, 0);
        }
        loadModal(false);
      };
      if (isEditor) {
        dispatch(replaceEditorEffect(payload, handleResponse));
      } else {
        dispatch(replaceSignatoryEffect(payload, handleResponse));
      }
    }
  };

  return (
    <div
      className={classNames({
        [styles.container]: !dynamicForm,
        [styles.dynamicFormContainer]: dynamicForm,
      })}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ReplaceEntityModal
            url={`${formProcessPublicId}/${formDocumentPublicId}`}
            replaceEntityData={replaceEntityModal}
            onCloseModal={closeReplaceEntityModal}
            handleReplaceEntity={handleReplaceEntity}
            dynamicForm={dynamicForm}
          />

          {Object.keys(postForm).length ? (
            <PostForm
              pdfName={formDocument?.documentName}
              clientName={formDocument?.clientName}
              clientAddress={formDocument?.address}
              handleDone={handlePostDone}
              handleExit={handleExit}
              back={back}
              handleRemoveEditor={handleRemoveEditor}
              setReplaceEntityModal={setReplaceEntityModal}
              updateSignatureSequence={updateSignatureSequence}
              handlePreview={handlePreview}
              dynamicForm={dynamicForm}
              isBundle={isBundle}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
