import { DateRangePicker } from 'components/Form/DateRangePicker';
import moment, { Moment } from 'moment';
import { FilterDrawer, FilterSection } from 'pages/Workshop/common';
import { GroupCheckBoxesSection } from 'pages/Workshop/common/GroupCheckBoxesSection';
import { MultiSelectSection } from 'pages/Workshop/common/MultiSelectSection';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocationService } from 'services';
import { milestoneStatus } from 'settings/constants/milestones';
import { projectStatusesOptions, transactionStatusesOptions } from 'settings/constants/transaction';
import {
  getMilestoneAggregateEffect,
  setMilestoneFilterEffect,
} from 'store/effects/milestoneAggregate';
import { getProjectsSelector } from 'store/selectors/projects';
import { getAggregateMilestoneTransactionsSelector } from 'store/selectors/transactions';
import { getAggregatePageTypeSelector } from 'store/selectors/workshop';
import { AggregatedPageTypes } from 'types/aggregatedPageTypes';
import { addProOrTransFilterParam } from 'utils/aggregatedPageTypeHelper';
import styles from './styles.module.scss';
import { getMilestoneStateSelector } from 'store/selectors/milestoneAggregate';
import { Row, Col, Checkbox } from 'antd';

let defaultFilters = {};

export const FilterSections = () => {
  const dispatch = useDispatch();
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();
  const projectsData = useSelector(getProjectsSelector);
  const transactionsData = useSelector(getAggregateMilestoneTransactionsSelector);
  const { search } = useSelector(getMilestoneStateSelector);

  const allTransactionsData: any[] = [...transactionsData, ...projectsData];

  const types = [
    { name: 'Private', value: true },
    { name: 'Shared', value: false },
  ];

  const { isArchive } = useSelector(getMilestoneStateSelector);
  const aggregatedPageType = useSelector(getAggregatePageTypeSelector);
  const isProject = aggregatedPageType === AggregatedPageTypes.Project;
  const transactionStatusesData = isProject
    ? [...projectStatusesOptions]
    : [...transactionStatusesOptions];
  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
  const [resetDates, setResetDates] = useState(false);

  const [dueFrom, setDueFrom] = useState<Moment | undefined>(
    query.dueToday ? moment() : query.dueWeek ? moment().startOf('week') : undefined,
  );
  const [dueTo, setDueTo] = useState<Moment | undefined>(
    query.dueToday ? moment() : query.dueWeek ? moment().endOf('week') : undefined,
  );
  const [transactions, setTransactions] = useState<string[]>([]);
  const [transactionStatuses, setTransactionStatuses] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>(
    query?.statuses ? query?.statuses.split(',') : [],
  );
  const [clients, setClients] = useState<string[]>([]);
  const [isPrivate, setIsPrivate] = useState<boolean>();
  const [previousFilters, setPreviousFilters] = useState({});

  const getFilters = () => ({
    dueTo,
    dueFrom,
    transactions,
    transactionStatuses,
    statuses,
    clients,
    isPrivate,
  });

  useEffect(() => {
    defaultFilters = getFilters();
    setPreviousFilters(defaultFilters);
  }, []);

  useEffect(() => {
    const filtersApplied = Boolean(
      dueFrom ||
        dueTo ||
        statuses.length ||
        transactions.length ||
        clients.length ||
        isPrivate !== undefined,
    );

    setIsFiltersApplied(filtersApplied);
    dispatch(setMilestoneFilterEffect({ isFiltersApplied: filtersApplied }));
  }, [dueTo, dueFrom, transactions, transactionStatuses, statuses, clients, isPrivate]);

  useEffect(() => {
    if (search) {
      dispatch(
        getMilestoneAggregateEffect({
          filters: addProOrTransFilterParam(aggregatedPageType, {
            dueTo,
            dueFrom,
            transactions,
            listingStatus: transactionStatuses,
            statuses,
            clients,
            isPrivate,
            milestone: search,
          }),
        }),
      );
    }
  }, [search]);

  const submitHandler = () => {
    dispatch(
      getMilestoneAggregateEffect({
        filters: addProOrTransFilterParam(aggregatedPageType, {
          dueTo,
          dueFrom,
          transactions,
          listingStatus: transactionStatuses,
          statuses,
          clients,
          isPrivate,
          milestone: search,
        }),
      }),
    );
    setPreviousFilters(getFilters());
  };

  const resetHandler = () => {
    setIsPrivate(undefined);
    setResetDates(true);
    setTransactions([]);
    setTransactionStatuses([]);
    setStatuses([]);
    setClients([]);
    setDueFrom(undefined);
    setDueTo(undefined);

    dispatch(
      getMilestoneAggregateEffect({
        filters: addProOrTransFilterParam(aggregatedPageType, { milestone: search }),
      }),
    );

    setPreviousFilters(defaultFilters);
  };

  const discardHandler = () => {
    if (JSON.stringify(previousFilters) === JSON.stringify(getFilters())) {
      return;
    }

    const { dueTo, dueFrom, transactions, transactionStatuses, statuses, clients, isPrivate } =
      previousFilters as any;

    if (transactions) setTransactions(transactions);
    if (statuses) setStatuses(statuses);
    if (transactionStatuses) setTransactionStatuses(transactionStatuses);
    if (clients) setClients(clients);
    if (dueTo) setDueTo(dueTo);
    if (dueFrom) setDueFrom(dueFrom);
    if (isPrivate) setIsPrivate(isPrivate);
  };

  return (
    <FilterDrawer
      className={styles.filterBtn}
      isFiltersApplied={isFiltersApplied}
      onDone={submitHandler}
      onReset={resetHandler}
      onDiscard={discardHandler}
    >
      <div className={styles.filterSections}>
        <FilterSection title="Status">
          <GroupCheckBoxesSection
            title=""
            entityList={Object.values(milestoneStatus).map((s) => ({ name: s, value: s }))}
            entityValues={statuses}
            setEntity={setStatuses}
          />
        </FilterSection>

        <FilterSection title="Transaction" count={transactions.length + transactionStatuses.length}>
          <MultiSelectSection
            placeholder={`Search by transaction...`}
            entityList={allTransactionsData?.map(({ Id, label }) => ({
              value: Id,
              label,
            }))}
            entity={transactions}
            setEntity={setTransactions}
            locationPrefix
          />
          <GroupCheckBoxesSection
            title={'Statuses'}
            entityList={transactionStatusesData}
            entityValues={transactionStatuses}
            setEntity={setTransactionStatuses}
          />
        </FilterSection>

        <FilterSection title="Type">
          <Row>
            <Col span={12}>
              <div className={styles.statusField}>
                <Checkbox
                  className={styles.checkbox}
                  checked={isPrivate === undefined}
                  onChange={(e) => {
                    const { checked } = e.target;
                    if (checked) {
                      setIsPrivate(undefined);
                    } else {
                      setIsPrivate(true);
                    }
                  }}
                />
                <span className={styles.statusLabel}>All</span>
              </div>
            </Col>
            <Col span={12}>
              <div className={styles.statusField}>
                <Checkbox
                  className={styles.checkbox}
                  checked={isPrivate || isPrivate === undefined}
                  onChange={(e) => {
                    const { checked } = e.target;
                    if (checked) {
                      setIsPrivate(true);
                    } else {
                      setIsPrivate(false);
                    }
                  }}
                />
                <span className={styles.statusLabel}>Private</span>
              </div>
            </Col>
            <Col span={12}>
              <div className={styles.statusField}>
                <Checkbox
                  className={styles.checkbox}
                  checked={isPrivate === false || isPrivate === undefined}
                  onChange={(e) => {
                    const { checked } = e.target;
                    if (checked) {
                      setIsPrivate(false);
                    } else {
                      setIsPrivate(true);
                    }
                  }}
                />
                <span className={styles.statusLabel}>Shared</span>
              </div>
            </Col>
          </Row>
        </FilterSection>

        <FilterSection title="Date">
          <div className={styles.datePickerContainer}>
            <DateRangePicker
              span={13}
              title={''}
              onDatesChange={(from, to) => {
                resetDates && setResetDates(false);
                setDueFrom(from);
                setDueTo(to);
              }}
              fromDateValue={dueFrom}
              toDateValue={dueTo}
              dateRangePickerWrapperClass={styles.dateRangePicker}
              resetDates={resetDates}
              futureDisabled={false}
            />
          </div>
        </FilterSection>
      </div>
    </FilterDrawer>
  );
};
