import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as toastr from 'toastr';

import {
  AppLoading,
  ClientsDrawer,
  MessagesDrawer,
  ThreadContactsDrawer,
  MessageAttachmentsDrawer,
  ViewQuoteActivityDrawer,
  AddParticipantsDrawer,
  NotificationsDrawer,
  QuotesDrawer,
} from 'components';
import ScreenContext from 'contexts/screen';
import { useResize } from 'hooks';
import { appInitEffect } from 'store/effects/app';
import { PageContentContainer } from './PageContentContainer';
import { AgentInvitation } from 'components-antd/Client/AgentInvitation';
import Hint from './Hint';

import 'toastr/build/toastr.css';
import 'styles/global.scss';
import { EmailVerificationWallModal } from 'features/emailVerification/EmailVerificationWallModal/EmailVerificationWallModal';
import { MlsAccessModal } from 'features/mlsAccess/MlsAccessModal';
import Mozzie from 'components/Chatbot/Mozzie';
import { PaymentUpdateModel } from 'pages/Paywall/PaymentUpdateModal/PaymentUpdateModal';

toastr.options.positionClass = 'toast-bottom-right';

const App = () => {
  const specs = useResize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appInitEffect());
  }, []);

  return (
    <ScreenContext.Provider value={specs}>
      <AppLoading>
        <PageContentContainer />
        <AgentInvitation />
        <ClientsDrawer />
        <MessagesDrawer />
        <QuotesDrawer />
        <ThreadContactsDrawer />
        <MessageAttachmentsDrawer />
        <ViewQuoteActivityDrawer />
        <AddParticipantsDrawer />
        <NotificationsDrawer />
        <EmailVerificationWallModal />
        <MlsAccessModal />
        <Hint />
        <Mozzie />
        <PaymentUpdateModel />
      </AppLoading>
    </ScreenContext.Provider>
  );
};

export default App;
