import { useState } from 'react';
import { Switch } from 'components-antd';
import styles from './styles.module.scss';

type KitsDataRowPropsType = {
  data: any;
  kitDisableHandler: Function;
};

function KitsDataRow(props: KitsDataRowPropsType) {
  const [enabled, setEnabled] = useState<boolean>(props.data.Enabled);
  const { Kits } = props.data;

  const getFormattedFrequency = (frequency) => {
    let frequencyString = frequency.FrequencyName;
    if (frequency.ClosingDateRelated) {
      frequencyString += ' (CD';
      if (frequency.OffsetOperator) frequencyString += frequency.OffsetOperator;
      if (frequency.OffsetValue) frequencyString += frequency.OffsetValue;
      if (frequency.OffsetUnit) frequencyString += frequency.OffsetUnit;
      frequencyString += ' )';
    }
    if (frequency.HasSpecificDates && frequency.SpecificDates?.length) {
      frequencyString += ' (';
      frequencyString += frequency.SpecificDates.join();
      frequencyString += ' )';
    }
    return frequencyString;
  };

  return (
    <tr className={styles.kitsRowData}>
      <td>
        <div className={styles.kitName}>
          <div>{Kits.Name}</div>
        </div>
      </td>
      <td>
        <div className={styles.text}>{Kits.Category}</div>
      </td>
      <td>
        <div className={styles.text}>{getFormattedFrequency(props.data.CurrentFrequency)}</div>
      </td>
      <td>
        <div className={styles.switchCell}>
          <Switch
            disabled={false}
            className={styles.switch}
            checked={enabled}
            defaultChecked={props.data.Enable}
            onChange={(v) => {
              setEnabled(v);
              props.kitDisableHandler(Kits.Id, v);
            }}
          />
        </div>
      </td>
    </tr>
  );
}

export default KitsDataRow;
