import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Activity } from './Activity';
import { Plus } from 'components/Icons';
import { getActivityLogByAppointmentId } from 'api/activityLog';
import { orderBy } from 'lodash';
import moment from 'moment';

import styles from './styles.module.scss';

export const History = ({ data }) => {
  const { Id } = data;
  const fetchActivityLog = useCallback(async () => {
    const now = moment().utcOffset() / 60;
    const { data } = await getActivityLogByAppointmentId({
      AppointmentId: Id,
      filters: {
        ActionTypes: [
          'ShowingAppointmentApproved',
          'ShowingAppointmentRescheduled',
          'ShowingAppointmentCancelled',
          'ShowingAppointmentRequested',
        ],
      },
      timeZoneOffset: now,
    });
    if (data?.result) {
      const sorted: any[] = orderBy(data?.result?.items, ['Id'], ['desc']);
      setHistory(sorted);
    }
  }, []);

  useEffect(() => {
    fetchActivityLog();
  }, []);

  const [history, setHistory]: any[] = useState([]);
  const [n, setN] = useState(3);
  const [showAllBtn, setShowAllBtn] = useState(true);
  const slicedList = history?.length > 3 ? history.slice(0, n) : history;
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpander = useCallback(() => {
    setIsExpanded((val) => !val);
    setShowAllBtn(true);
    setN(history?.length);
  }, [setIsExpanded, history]);

  useEffect(() => {
    if (history?.length > 3 && !isExpanded) {
      setShowAllBtn(false);
    }
  }, [history]);

  return (
    <div className={styles.history}>
      <p className={styles.title}>History</p>
      {slicedList?.length ? (
        <>
          {slicedList.map((h) => (
            <Activity data={h} />
          ))}
        </>
      ) : (
        <p className={styles.disclaimer}>No history available!</p>
      )}
      <div onClick={toggleExpander} className={styles.showAllBtn} hidden={showAllBtn}>
        <button testid="show_all" className={styles.showAllIcon}>
          <Plus />
        </button>
        <span className={styles.showAllTxt}>Show All</span>
      </div>
    </div>
  );
};

History.propTypes = {
  data: PropTypes.object,
};

History.defaultProps = {
  data: {},
};
