import * as Yup from 'yup';

export const fileSchema = Yup.object().shape({
  contentType: Yup.string(),
  data: Yup.string(),
  filename: Yup.string(),
  size: Yup.number(),
});

export const validationSchema = Yup.object().shape({
  Amount: Yup.string().test('Amount', 'This field is required', function (value) {
    const parentContext: any = this;
    const isManualDataInput = parentContext?.from[1]?.value?.isManualDataInput;
    if (isManualDataInput && !value) return false;
    return true;
  }),
  ApprovedBy: Yup.string().optional(),
  ApprovalDate: Yup.date().nullable().optional(),
  ExpirationDate: Yup.date().nullable().optional(),
  PreApprovalDocuments: Yup.array().test(
    'PreApprovalDocuments',
    'This field is required',
    function (value) {
      const parentContext: any = this;
      const isManualDataInput = parentContext?.from[1]?.value?.isManualDataInput;
      if (!isManualDataInput && !value?.length) return false;
      return true;
    },
  ),
});
export const preApprovalArraySchema = Yup.object().shape({
  preApprovalsDocuments: Yup.array(validationSchema.optional()).optional(),
  isManualDataInput: Yup.boolean(),
});

export interface PreApprovalValues {
  Amount: string;
  ApprovedBy: string;
  ApprovalDate: Date | null;
  ExpirationDate: Date | null;
  PreApprovalDocuments: any[];
}

export interface InitialValueTypes {
  preApprovalsDocuments: PreApprovalValues[];
  isManualDataInput: boolean;
}
