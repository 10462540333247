import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import classNames from 'classnames';

export const CardBox = ({ children, className }) => {
  return <div className={classNames(styles.cardBox, className)}>{children}</div>;
};

CardBox.propTypes = {
  className: PropTypes.string,
};

CardBox.defaultProps = {
  className: '',
};
