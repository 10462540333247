import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Wrapper as PendingWrapper } from 'components';
import { getAllPropsSelector, getFeedPageInfoSelector } from 'store/selectors/feed';
import { PENDING, READY } from 'settings/constants/apiState';
import { PropertiesWrapper, PropertiesList } from 'pages/Properties/Feed/components';
import { useNoMlsAccessHandler } from '../../hooks/useNoMlsAccessHandler';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getAllPropsV2Effect } from 'store/effects/feed/feedV2';
import { ListSkeleton } from 'components-antd';
import { setFeedPrefsAction } from 'store/actions/feed';
import { getFeedPrefsFilterSelector, getFeedPrefsFilterStateSelector } from 'store/selectors/feed';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';

import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';

const All = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isInitialRender = useRef(true);

  const searchPrefsValue = useSelector(getFeedPrefsFilterSelector);
  const searchPrefsState = useSelector(getFeedPrefsFilterStateSelector);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const allProps = useSelector(getAllPropsSelector);
  const currentPageInfo = useSelector(getFeedPageInfoSelector);

  const { hasMlsAccess, wrappedEffect: getAllPropsEffectWrapped } =
    useNoMlsAccessHandler(getAllPropsV2Effect);

  useEffect(() => {
    if (searchPrefsState === READY) {
      if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
      }
      const cleanedFeedPrefsObj = cleanSearchQueryObj(searchPrefsValue);
      dispatch(setFeedPrefsAction(cleanedFeedPrefsObj));
      if (
        isAgent &&
        (!currentPageInfo?.currentFEPageInfo?.fromListingDetailPage || !allProps?.data?.length)
      )
        dispatch(getAllPropsEffectWrapped());
    }
  }, [searchPrefsState]);

  const isPending = allProps.state === PENDING;

  return (
    <PropertiesWrapper>
      {(wrapperSize, getNextPageIsPending) => (
        <PendingWrapper
          isPending={isPending}
          className={styles.pendingWrapper}
          customLoader={<ListSkeleton />}
        >
          <PropertiesList
            list={allProps?.data}
            wrapperSize={wrapperSize}
            getNextPageIsPending={getNextPageIsPending}
            hasMlsAccess={hasMlsAccess}
            loadDataEffect={getAllPropsEffectWrapped}
          />
        </PendingWrapper>
      )}
    </PropertiesWrapper>
  );
};

export default All;
