import { handleActions } from 'redux-actions';
import { IDLE, READY } from 'settings/constants/apiState';
import { get, map } from 'lodash-es';

import {
  requestGetPartnersListAction,
  partnersSetSearchAction,
  partnersClearSearchAction,
  partnersResetAction,
  partnersChangeRecommendPartnerAction,
  requestGetRecommendedPartnersByAgentListAction,
  toggleRequestQuoteBtnAction,
} from 'store/actions/partners';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  search: '',
  recommendState: IDLE,
  state: IDLE,
  data: null,
  meta: {},
  recommendations: {
    state: IDLE,
    data: null,
  },
  showRequestQuoteBtn: false,
};

export default handleActions(
  {
    [requestGetPartnersListAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
      meta: payload?.meta || state.meta,
    }),
    [partnersChangeRecommendPartnerAction]: (state, { payload }) => {
      if (payload?.state === READY) {
        const newElement = get(payload, ['data', 'result']);

        return {
          ...state,
          data: map(state?.data || [], (partner) =>
            partner?.Id === newElement?.Id ? newElement : partner,
          ),
          recommendState: payload.state,
        };
      }

      return {
        ...state,
        recommendState: payload.state,
      };
    },
    [partnersClearSearchAction]: (state) => ({
      ...state,
      search: '',
    }),
    [partnersSetSearchAction]: (state, { payload }) => ({
      ...state,
      search: get(payload, 'search'),
    }),
    [partnersResetAction]: () => initialData,
    [appLogoutAction]: () => initialData,
    [requestGetRecommendedPartnersByAgentListAction]: (state, { payload }) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        state: payload.state,
        data: get(payload, ['data', 'result']),
      },
    }),
    [toggleRequestQuoteBtnAction]: (state, { payload }) => ({
      ...state,
      showRequestQuoteBtn: payload,
    }),
  },
  initialData,
);
