import classNames from 'classnames';
import { QuotationsCategory } from 'types';

import styles from './styles.module.scss';

export const Category = ({ category }) => {
  function getCategoryClassName() {
    switch (category) {
      case QuotationsCategory.Lenders:
        return styles.confettiBlue;
      case QuotationsCategory.Inspection:
        return styles.lightBlue;
      case QuotationsCategory.Title:
        return styles.purple;
      case QuotationsCategory.Insurance:
        return styles.purple;
      case QuotationsCategory.Plumbing:
        return styles.lightBlue;
      case QuotationsCategory.Landscaping:
        return styles.green;
      case QuotationsCategory.Handymen:
        return styles.mosaikRed;
      case QuotationsCategory.Painters:
        return styles.mosaikRed;
      case QuotationsCategory.Moving:
        return styles.purple;
      case QuotationsCategory.Cleaning:
        return styles.confettiBlue;
      case QuotationsCategory.Attorneys:
        return styles.confettiBlue;
      case QuotationsCategory.Contractors:
        return styles.orange;
      case QuotationsCategory.Electricians:
        return styles.mosaikRed;
      case QuotationsCategory.Locksmiths:
        return styles.orange;
      default:
        return '';
    }
  }

  return (
    <div
      className={classNames(styles.quotationsCategory, getCategoryClassName())}
      testid="task_category"
    >
      <span className={styles.categoryText}>{category}</span>
    </div>
  );
};
