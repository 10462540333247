import moment from 'moment';
import classNames from 'classnames';

import AntCalendar from 'antd/lib/calendar';
import { getDateDifferenceFromToday } from 'helpers';

import styles from './styles.module.scss';

export const Calendar = (props) => {
  const { selectedDate, disabledDate, onSelect, className } = props;

  const onPrevNext = (date, value) => {
    const current = new Date(date);
    let newDate = new Date(current.setMonth(current.getMonth() + value));

    if (getDateDifferenceFromToday(newDate) > 0) {
      newDate = new Date();
    }
    onSelect(moment(newDate));
  };

  return (
    <div className="site-calendar-customize-header-wrapper">
      <AntCalendar
        headerRender={({ value }) => {
          const date = value || new Date();

          return (
            <div className={styles.calendarHeader}>
              <p>{moment(date).format('MMMM YYYY')}</p>

              <div className={styles.actions}>
                <div className={styles.prevIcon} onClick={() => onPrevNext(date, -1)}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.694 18.6943C16.102 18.2867 16.102 17.6259 15.694 17.2184L10.4699 12L15.694 6.78165C16.102 6.37408 16.102 5.71326 15.694 5.30568C15.2859 4.89811 14.6244 4.8981 14.2164 5.30568L8.30602 11.2096C8.08861 11.4267 7.98704 11.7158 8.00132 12.0002C7.98714 12.2844 8.08871 12.5733 8.30604 12.7904L14.2164 18.6943C14.6244 19.1019 15.286 19.1019 15.694 18.6943Z"
                      fill={getDateDifferenceFromToday(value) < 0 ? '#262626' : '#AAABAB'}
                    />
                  </svg>
                </div>
                <div className={styles.nextIcon} onClick={() => onPrevNext(date, 1)}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.30567 18.6943C8.71388 19.1017 9.37573 19.1017 9.78394 18.6943L15.697 12.7931C15.9227 12.5679 16.0236 12.2653 15.9997 11.9708C16.0058 11.696 15.9039 11.4193 15.694 11.2096L9.78361 5.30568C9.37558 4.8981 8.71404 4.8981 8.30601 5.30568C7.89799 5.71326 7.89799 6.37408 8.30601 6.78166L13.5326 12.0025L8.30567 17.219C7.89746 17.6264 7.89746 18.2869 8.30567 18.6943Z"
                      fill={'#262626'}
                    />
                  </svg>
                </div>
              </div>
            </div>
          );
        }}
        fullscreen={false}
        className={classNames(styles.calendarWrapper, className)}
        onSelect={onSelect}
        disabledDate={disabledDate}
        value={selectedDate}
      />
    </div>
  );
};
