import React from 'react';
import classNames from 'classnames';
import { Button, ButtonProps } from 'components-antd';
import { FullArrowRight } from 'components/Icons';
import { Spinner } from 'components';

import styles from './styles.module.scss';

export interface OnboardingContinueButtonProps extends ButtonProps {
  isPending?: boolean;
  hideArrow?: boolean;
}

export const OnboardingContinueButton: React.FC<OnboardingContinueButtonProps> = ({
  title,
  isPending,
  className,
  disabled,
  hideArrow = true,
  ...props
}) => {
  const children = isPending ? (
    <Spinner loaderClassName={styles.loader} />
  ) : (
    <div className={classNames(styles.title, styles.submitTitle)}>
      {title ?? 'Continue'} {!hideArrow && <FullArrowRight className={styles.lightArrow} />}
    </div>
  );

  return (
    <Button
      {...props}
      className={classNames(styles.button, className)}
      disabled={disabled || isPending}
      variant="secondary"
    >
      {children}
    </Button>
  );
};
