import { useState } from 'react';
import { cloneDeep } from 'lodash-es';

import { Row, Col } from 'components-antd';
import { InputLabel } from 'components';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { UploadDocuments as CommonUploadDocuments } from 'components/Transactions';
import { Documents } from 'pages/Workshop/Transactions/TransactionTasks/components';
import { getUserFirstName, getUserId, getUserLastName } from 'store/selectors/user';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

import { TaskEditableFormValuesKeys, TaskEditableFormValuesType } from 'types/transactionTasks';

interface FilesProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  isTemplate: boolean;
  isAssignTask?: boolean | null;
  isViewMode?: boolean | null;
  showOptional?: boolean | null;
  addFiles?: boolean | null;
}

export const Files = ({
  values,
  isAssignTask,
  isTemplate,
  onChangeField,
  isViewMode,
  showOptional,
  addFiles,
}: FilesProps) => {
  // const [addFiles, setAddFiles] = useState(false);
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const userId = useSelector(getUserId);

  const onSave = (newValues, cb) => {
    const extendedValues = (newValues || []).map((val) => ({
      ...val,
      Uploader: { Id: userId, FirstName: userFirstName, LastName: userLastName },
    }));
    onChangeField([...(values?.Documents || []), ...extendedValues], 'Documents');
    if (typeof cb === 'function') {
      cb();
    }
  };

  const onDeleteDocument = (event, index) => {
    if (isViewMode) return;
    const copyDocuments = cloneDeep(values?.Documents);
    copyDocuments.splice(index, 1);
    onChangeField(copyDocuments, 'Documents');
  };

  return (
    <div className={styles.files}>
      {addFiles || values?.Documents?.length ? (
        <div className={styles.spacing}>
          <div className={styles.filesWrapper}>
            <InputLabel label="Files" className={styles.label} showOptional={showOptional} />
            <CommonUploadDocuments
              onSave={onSave}
              uploadButton={
                <div className={styles.uploadDocument}>
                  <Icon className={styles.icon} variant={Icon.UPLOAD} />
                </div>
              }
              withCategory
              withPermissions={!isTemplate}
              isCategoryOptional
              isAssignTask={isAssignTask}
              isViewMode={isViewMode}
              fillDocumentName={true}
            />
          </div>
          <div className={styles.fileListing}>
            <Documents value={values?.Documents} onDelete={onDeleteDocument} />
          </div>
        </div>
      ) : null}
    </div>
  );
};
