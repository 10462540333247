import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Spinner } from 'components';
import { AnonDataType, AuthDataType } from 'types';
import { FormScreenManager } from '../FormScreenManager';
import { getRequestFormProcessSelector } from 'store/selectors/requestFormProcess';
import { AllowedAnonEnum } from 'app-constants';
import { getAuthFormDocument, getAnonFormDocument } from 'pages/FormProcess/helper';

export interface FormStateManagerProps {
  anonData?: AnonDataType;
  authData?: AuthDataType;
  webView?: Boolean;
}

export const FormStateManager = ({ anonData, authData, webView }: FormStateManagerProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);

  const { formDocument } = useSelector(getRequestFormProcessSelector);

  const validateParams = () => {
    let error = false;
    if (!(authData || anonData)) {
      error = true;
    } else if (anonData) {
      if (!Object.values(AllowedAnonEnum).includes(anonData.type)) {
        error = true;
      }
    } else if (authData) {
      if (!(authData.formDocumentPublicId && authData.formProcessPublicId)) {
        error = true;
      }
    }

    if (error) {
      throw new Error('Invalid param');
    }
  };

  const getFormDocument = () => {
    if (authData) {
      getAuthFormDocument(
        dispatch,
        history,
        setLoading,
        authData.formProcessPublicId,
        authData.formDocumentPublicId,
      );
    } else if (anonData) {
      getAnonFormDocument(dispatch, history, setLoading, anonData.token, anonData.type);
    }
  };

  useEffect(() => {
    validateParams();
    getFormDocument();
  }, []);

  return formDocument && !isLoading ? (
    <FormScreenManager
      formDocument={formDocument}
      anonData={anonData}
      authData={authData}
      refresh={getFormDocument}
      webView={webView}
    />
  ) : (
    <Spinner />
  );
};
