import { handleActions } from 'redux-actions';

import {
  setSearchCurrentPageInfoAction,
  setSearchPageInfoAction,
} from 'store/actions/searchResults';

const initialData = {
  endCursor: null,
  hasNextPage: false,
  currentFEPageInfo: {
    pageNumber: 1,
    renderingIndex: -1,
    fromListingDetailPage: false,
  },
};

export default handleActions(
  {
    [setSearchPageInfoAction as any]: (state, { payload }) => ({
      ...state,
      endCursor: payload?.endCursor,
      hasNextPage: payload?.hasNextPage,
    }),
    [setSearchCurrentPageInfoAction as any]: (state, { payload }) => ({
      ...state,
      currentFEPageInfo: payload?.currentFEPageInfo,
    }),
  },
  initialData,
);
