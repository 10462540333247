import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
const CheckRadioButton = ({
  children,
  value,
  checked,
  onChange,
  className = '',
  innerClassName = '',
  color = '#FF576D',
  checkedSvg = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill={color} stroke={color} />
      <g clipPath="url(#clip0_53754_389106)">
        <path
          d="M7.3325 11.9993L10.6658 15.3327L17.3325 8.66602"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_53754_389106">
          <rect width="16" height="16" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  ),
  uncheckedSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect x="1" y="1" width="22" height="22" rx="11" stroke="#AAABAB" strokeWidth="2" />
    </svg>
  ),
}) => {
  return (
    <div
      className={classNames(
        {
          [styles.customRadioSelected]: checked,
          [styles.customRadio]: !checked,
        },
        className,
      )}
      onClick={() => onChange(value)}
    >
      <div className={classNames(styles.customRadioCircle, innerClassName, { checked: checked })}>
        {checked ? checkedSvg : uncheckedSvg}
      </div>
      <span className={styles.customRadioLabel}>{children}</span>
    </div>
  );
};

export default CheckRadioButton;
