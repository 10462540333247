import { get } from 'lodash-es';

import {
  deleteFormVersionModalAction,
  requestGetFormListAction,
  selectReloadFormListAction,
  updateFormListSearchAction,
} from 'store/actions/formBuilder';
import { FormBuilderState } from 'types';
import { formBuilderInitialData } from '..';

export const formListReducer = {
  [requestGetFormListAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      formList: {
        ...state.data.formList,
        ...get(payload, 'data.value', formBuilderInitialData.formList),
        search: state.data.formList.search,
        state: payload.state,
        refresh: 0,
      },
    },
  }),
  [selectReloadFormListAction as any]: (
    state: FormBuilderState,
    { payload },
  ): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      formList: {
        ...state.data.formList,
        refresh: state.data.formList.refresh + 1,
      },
    },
  }),
  [updateFormListSearchAction as any]: (
    state: FormBuilderState,
    { payload },
  ): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      formList: {
        ...state.data.formList,
        search: { ...state.data.formList.search, ...get(payload, 'search') },
      },
    },
  }),
  [deleteFormVersionModalAction as any]: (
    state: FormBuilderState,
    { payload },
  ): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      deleteFormVersion: payload
        ? {
            formName: get(payload, 'formName'),
            formId: get(payload, 'formId'),
            formVersionId: get(payload, 'formVersionId'),
          }
        : undefined,
    },
  }),
};
