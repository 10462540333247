import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Moment } from 'moment';
import { keys } from 'lodash-es';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { AuditLogCard } from 'components-antd/AuditLogCard';
import { formatLogsData, getFormattedFilterObject } from 'utils/activityLogsHelper';
import { activityLogsPageSize } from 'app-constants/activityLogs';
import { Badge } from 'components-antd';
import { Icons } from '../../Icons';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { getClientDetailsSelector } from 'store/selectors/clientDetail';
import { FilterPopover } from '../FilterPopover';
import { getActivityLogs } from 'api/activityLog';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

export const ClientActivityLogSection = ({ isAgent }) => {
  const details = useSelector(isAgent ? getAgentDetailsSelector : getClientDetailsSelector);
  const contact = details?.data || {};
  const users = [contact?.Id];
  const [pageNumber, setPageNumber] = useState(1);
  const [prevY, setPrevY] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [logItems, setLogItems]: any[] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [formatted, setFormatted] = useState([]);
  const initializeFilter = { users };
  const [fieldsPayload, setFieldsPayload] = useState<any>(initializeFilter);
  const logsRef = useRef([]);
  const loadingRef: any = useRef(null);
  const prevYRef = useRef({});
  const pageRef: any = useRef({});
  const totalItemsRef: any = useRef({});
  const fieldsRef = useRef({});
  logsRef.current = [];
  pageRef.current = pageNumber;
  prevYRef.current = prevY;
  totalItemsRef.current = totalItems;
  fieldsRef.current = fieldsPayload;
  const handleObserver = (entities) => {
    const y = entities[0].boundingClientRect.y;
    if (prevYRef.current > y) {
      if (pageRef.current <= Math.ceil(totalItemsRef.current / activityLogsPageSize)) {
        getLogs();
      }
    }
    setPrevY(y);
  };

  useEffect(() => {
    getLogs();
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    observer.observe(loadingRef.current);
  }, []);

  useEffect(() => {
    setFormatted(formatLogsData(logItems));
  }, [logItems]);

  const getLogs = async () => {
    setIsLoading(true);
    try {
      const { queryParams, ...formattedObj } = getFormattedFilterObject(
        fieldsRef.current,
        pageRef.current,
        activityLogsPageSize,
      );
      const { data } = await getActivityLogs(formattedObj, queryParams);
      if (data?.result) {
        let items = data?.result?.items || [];
        if (pageRef.current === 1) {
          setLogItems((prevList) => {
            return [...items];
          });
        } else {
          setLogItems((prevList) => {
            return [...prevList, ...items];
          });
        }
        setPageNumber(pageRef.current + 1);
        setTotalItems(data?.result?.total);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const onDateChangeHandler = (_from?: Moment, _to?: Moment) => {
    setFieldsPayload({ ...fieldsPayload, startDate: _from, endDate: _to });
  };

  const renderFilterIcon = (
    <div className={styles.btn}>
      <Badge>
        <Icons variant={'filterSecondary'} className={styles.filterIcon} />
      </Badge>
    </div>
  );

  return (
    <div className={styles.clientActivityLogSection}>
      <FilterPopover
        filterIcon={renderFilterIcon}
        onDateChangeHandler={onDateChangeHandler}
        onActivityTypeSelectHandler={() => {}}
        onDone={() => {
          pageRef.current = 1;
          setPageNumber(1);
          getLogs();
        }}
        onReset={() => {
          setFieldsPayload(initializeFilter);
          fieldsRef.current = initializeFilter;
          pageRef.current = 1;
          setPageNumber(1);
          getLogs();
        }}
      />
      <div className={styles.clientActivityLogWrap}>
        {formatted?.length ? (
          formatted.map((item, idx) => {
            return (
              <AuditLogCard
                key={idx}
                activityLogs={item[`${keys(item)}`]}
                DayAndDate={keys(item)[0]}
                activityLogCardClassNameSecondary={styles.clientActivityCard}
                timelineItemWrapperClassName={classNames(styles.timelineItemWrapper)}
                avatarWrapperClassName={styles.avatarWrapper}
              />
            );
          })
        ) : !isLoading ? (
          <div className={styles.noActivityFound}>
            <span>No Activity</span>
          </div>
        ) : null}
      </div>
      <div ref={loadingRef} style={{ height: isLoading ? '150px' : 0, margin: '25px' }}>
        <span style={{ display: isLoading ? 'block' : 'none' }}>
          <Spinner />
        </span>
      </div>
    </div>
  );
};

ClientActivityLogSection.propTypes = {
  isAgent: PropTypes.bool,
};

ClientActivityLogSection.defaultProps = {
  isAgent: false,
};
