import React from 'react';
import PropTypes from 'prop-types';

import { Favorite as CommonFavorite } from 'components';

const Favorite = (props) => {
  const { className, propertyInfo, isClient: propsIsClient } = props;
  return (
    <CommonFavorite propertyInfo={propertyInfo} className={className} isClient={propsIsClient} />
  );
};

Favorite.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    IsFavorite: PropTypes.bool,
    AddedToFavoritesBy: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    ),
  }).isRequired,
  isClient: PropTypes.bool,
};

Favorite.defaultProps = {
  className: '',
  isClient: undefined,
};

export default Favorite;
