import React from 'react';
import PropTypes from 'prop-types';

const SmallEditIcon = ({ className }) => (
  <div className={className}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66797 13.3342H5.33464L12.3346 6.33419C12.6883 5.98056 12.8869 5.50095 12.8869 5.00085C12.8869 4.50076 12.6883 4.02114 12.3346 3.66752C11.981 3.3139 11.5014 3.11523 11.0013 3.11523C10.5012 3.11523 10.0216 3.3139 9.66797 3.66752L2.66797 10.6675V13.3342Z"
        stroke="#FF576D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 4.33398L11.6667 7.00065"
        stroke="#FF576D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

SmallEditIcon.propTypes = {
  className: PropTypes.string,
};

SmallEditIcon.defaultProps = {
  className: '',
};

export default SmallEditIcon;
