import React, { useState } from 'react';
import { feedbackQuestion, feedbackForm } from 'types';
import { ValidationSchema } from './validation';
import styles from './styles.module.scss';
import { requestSendShowingAppointmentFeedbackEffect } from 'store/effects/showingAppointment';
import { showSuccessMessage } from 'helpers';
import { useDispatch } from 'react-redux';
import { Radio } from 'antd';
import { Input, PageWrapper } from 'components';
import { useFormik } from 'formik';
import FeedbackSubmitted from 'components/FeedbackSubmitted';

export const Feedback = (props) => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { match } = props;
  const [formFields, setFormFields] = useState<feedbackForm>();
  let appointmentUUID;
  if (match) {
    appointmentUUID = match.params?.appointmentId;
  }

  const initialValues = {
    Question1: formFields?.Question1 || '',
    Question2: formFields?.Question2 || '',
    Question3: formFields?.Question3 || '',
    Question4: formFields?.Question4 || '',
    Question5: formFields?.Question5 || '',
    Question6: formFields?.Question6 || '',
  };

  const question2_Options = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];

  const question5_Options = [
    { label: 'Yes', value: 'Yes' },
    { label: 'Maybe', value: 'Maybe' },
    { label: 'No', value: 'No' },
  ];

  const onChangeValue = (val, fieldName) => {
    setFormFields({
      ...formFields,
      [fieldName]: val,
    });
  };

  const onSubmit = async (values) => {
    const data = {
      BuyerFeedback: Object.entries(values).map((e) => ({ [e[0]]: e[1] })),
    };
    dispatch(
      requestSendShowingAppointmentFeedbackEffect({ UUID: appointmentUUID, ...data }, {}, (err) => {
        if (!err) {
          setFormFields({});
          showSuccessMessage('Feedback Submitted Successfully');
          setIsSubmitted(true);
        }
      }),
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    validateOnChange: true,
    onSubmit,
  });

  return (
    <PageWrapper className={styles.wrapper} contentContainerClassName={styles.servicesContainer}>
      {isSubmitted ? (
        <FeedbackSubmitted />
      ) : (
        <div className={styles.pageWrapper}>
          <div className={styles.headerArea}>
            <div className={styles.headWrapper}>
              <div className={styles.headTitle}>Feedback</div>
            </div>
          </div>
          <form
            name="feedbackForm"
            onSubmit={formik.handleSubmit}
            className={styles.contentWrapper}
          >
            <div className={styles.formArea}>
              <div className={styles.questionArea}>
                <div className={styles.question}>{feedbackQuestion.Question1}</div>
                <Input
                  className={styles.field}
                  placeholder="Add a comment"
                  variant="light"
                  value={formFields?.Question1}
                  onChange={(e, val) => onChangeValue(val, 'Question1')}
                  testid="question1_input"
                  error={formik.touched.Question1 ? formik.errors.Question1 : ''}
                />
              </div>

              <div className={styles.questionArea}>
                <div className={styles.question}>{feedbackQuestion.Question2}</div>
                <div className={styles.optionsArea}>
                  <Radio.Group
                    options={question2_Options}
                    onChange={(e) => onChangeValue(e.target.value, 'Question2')}
                    value={formFields?.Question2}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </div>
              </div>

              <div className={styles.questionArea}>
                <div className={styles.question}>{feedbackQuestion.Question3}</div>
                <Input
                  className={styles.field}
                  placeholder="Add a comment"
                  variant="light"
                  value={formFields?.Question3}
                  onChange={(e, val) => onChangeValue(val, 'Question3')}
                  testid="question3_input"
                  error={formik.touched.Question3 ? formik.errors.Question3 : ''}
                />
              </div>

              <div className={styles.questionArea}>
                <div className={styles.question}>{feedbackQuestion.Question4}</div>
                <Input
                  className={styles.field}
                  placeholder="Add a comment"
                  variant="light"
                  value={formFields?.Question4}
                  onChange={(e, val) => onChangeValue(val, 'Question4')}
                  testid="question4_input"
                  error={formik.touched.Question4 ? formik.errors.Question4 : ''}
                />
              </div>

              <div className={styles.questionArea}>
                <div className={styles.question}>{feedbackQuestion.Question5}</div>
                <div className={styles.optionsArea}>
                  <Radio.Group
                    options={question5_Options}
                    onChange={(e) => onChangeValue(e.target.value, 'Question5')}
                    value={formFields?.Question5}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </div>
              </div>

              <div className={styles.questionArea}>
                <div className={styles.question}>{feedbackQuestion.Question6}</div>
                <Input
                  className={styles.field}
                  placeholder="Add a comment"
                  variant="light"
                  value={formFields?.Question6}
                  onChange={(e, val) => onChangeValue(val, 'Question6')}
                  error={formik.touched.Question6 ? formik.errors.Question6 : ''}
                  testid="question6_input"
                />
              </div>
              <div className={styles.footerArea}>
                <button type="submit" className={styles.submitBtn} title="Submit" testid="Submit">
                  Continue
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </PageWrapper>
  );
};
