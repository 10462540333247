import { requestGetTeamAndOwnerDetails } from 'api/admin/teamDetails';
import { requestGetTeamAndOwnerDetailsAction } from 'store/actions/adminPanel';
import Api from 'store/effects/core/api';

export const requestGetTeamAndOwnerDetailsEffect = (cfg: { teamId: string }, options = {}, cb?) => {
  const { teamId } = cfg;
  const sendRequest = Api.execBase({
    action: requestGetTeamAndOwnerDetailsAction,
    method: requestGetTeamAndOwnerDetails,
  });

  return sendRequest(teamId, options, cb);
};
