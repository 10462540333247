import classNames from 'classnames';
import { InputLabel } from 'components';
import { TaskEditableFormValuesType, TaskEditableFormValuesKeys } from 'types/transactionTasks';

import styles from './styles.module.scss';
import MilestoneInput from './MilestoneInput';
import { TimelineMilestonesInterface } from './types/TransactionMilestone';

interface AssignedToMilestoneProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  isTemplate: boolean;
  className?: string | null;
  label?: string | null;
  isAssignTask?: boolean | null;
  isViewMode?: boolean | null;
  hideAsterisk?: boolean | null;
  isNew?: boolean;
  data: TimelineMilestonesInterface[];
}
export const AssignedToMilestone = ({
  isTemplate,
  values,
  onChangeField,
  isAssignTask,
  className,
  isViewMode,
  label,
  hideAsterisk,
  isNew = false,
  data = [],
}: AssignedToMilestoneProps) => {
  const fieldToUpdate = isTemplate ? 'MilestonePublicId' : 'MilestoneId';
  return (
    <div className={classNames(styles.assignedTo, className)}>
      <div className={styles.fieldWrapper}>
        <>
          <InputLabel
            label={label ?? 'Assign to Milestone'}
            className={styles.label}
            showAsterisk={!hideAsterisk}
          />
          <MilestoneInput
            onChange={(publicId) => {
              onChangeField(publicId, fieldToUpdate);
            }}
            values={data}
            closeOnSelect={true}
            isViewMode={isViewMode}
            isTemplate={isTemplate}
            tasks={values}
          />
        </>
      </div>
    </div>
  );
};
