import { ReactElement } from 'react';
import classNames from 'classnames';

import { Title, Modal, ModalProps } from 'components-antd';
import { WarningFilled, QuestionCircleFilled } from '@ant-design/icons';

import styles from './styles.module.scss';

type ConfirmVariant = 'Confirm' | 'Warning' | 'Primary';

export interface ConfirmationModalProps extends ModalProps {
  confirmText?: ReactElement | string;
  confirmDescription?: ReactElement | string;
  variant?: ConfirmVariant;
}

const getTitle = (variant: ConfirmVariant) => {
  switch (variant) {
    case 'Warning':
      return <WarningFilled className={classNames(styles.icon, styles.warningIcon)} />;
    default:
      return <QuestionCircleFilled className={classNames(styles.icon, styles.confirmationIcon)} />;
  }
};

export const ConfirmationModal = ({
  confirmText,
  confirmDescription,
  variant = 'Primary',
  className,
  title,
  ...props
}: ConfirmationModalProps) => (
  <Modal
    data-testid="confirmation-modal"
    className={classNames(
      styles.confirmationModal,
      { [styles.primary]: variant === 'Primary' },
      className,
    )}
    title={title || getTitle(variant)}
    okText="Confirm"
    cancelButtonProps={{ disabled: props.confirmLoading }}
    {...props}
  >
    <Title level={4}>{confirmText}</Title>
    {confirmDescription && (
      <div testid="description" className={styles.confirmDescription}>
        {confirmDescription}
      </div>
    )}
  </Modal>
);
