import PropTypes from 'prop-types';
import { Document } from './Document';
import UploadDocuments from 'components/Transactions/UploadDocuments';
import { remove } from 'lodash-es';

import styles from './styles.module.scss';
import _ from 'lodash';
export const Documents = ({
  data,
  counterOfferFormData,
  setCounterOfferFormData,
  BuyingAgentName,
}) => {
  const { OfferIterationDocument: documents } = data;
  const onSaveDocument = (newValues, fileCode, cb) => {
    remove(counterOfferFormData?.OfferIterationDocument, {
      Code: fileCode,
    });
    let files: JSON[] = counterOfferFormData?.OfferIterationDocument || [];
    newValues.forEach((element) => {
      const updatedElement = { ...element };

      updatedElement.code = fileCode;

      if (updatedElement.data) {
        updatedElement.data = btoa(updatedElement.data);
      }
      files.push(updatedElement);
    });
    setCounterOfferFormData({
      ...counterOfferFormData,
      OfferIterationDocument: files,
    });
    if (typeof cb === 'function') {
      cb();
    }
  };

  const onDeleteDocument = (data) => {
    counterOfferFormData.OfferIterationDocument =
      counterOfferFormData?.OfferIterationDocument.filter((object) => {
        return object.filename !== data.filename && object.documentName !== data.documentName;
      });
    setCounterOfferFormData({
      ...counterOfferFormData,
      OfferIterationDocument: counterOfferFormData.OfferIterationDocument,
    });
  };

  return (
    <div className={styles.documents}>
      <p className={styles.title}>Documents</p>

      {counterOfferFormData?.OfferIterationDocument?.length ? (
        <>
          {counterOfferFormData?.OfferIterationDocument.map((d) => (
            <Document
              key={d.Id}
              document={d}
              offerIterationData={data}
              onDeleteDocument={onDeleteDocument}
              BuyingAgentName={BuyingAgentName}
            />
          ))}
        </>
      ) : (
        <UploadDocuments
          onSave={onSaveDocument}
          withCategory={false}
          dropzone={true}
          multiple={true}
          uploadButton={false}
          className={styles.documentUpload}
          isTransactionOffer
          fillDocumentName={true}
        />
      )}
    </div>
  );
};

Documents.propTypes = {
  data: PropTypes.object,
};

Documents.defaultProps = {
  data: {},
};
