import { apiServer } from 'settings/web-services/api';

export type LoginLogsParams = {
  limit: number;
  page: number;
  sorting?: string;
  searchString?: string;
} & Record<string, string | number | undefined>;

export function getLoginLogs(cfg: LoginLogsParams) {
  return apiServer.get('/security/loginlog', { params: cfg });
}
