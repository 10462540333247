import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import pluralize from 'pluralize';

import { Button } from 'components';
import { getRequestQuoteSelector } from 'store/selectors/requestQuote';
import { updateQuoteDraftEffect } from 'store/effects/quotes';

import styles from './styles.module.scss';

const Footer = (props) => {
  const { className, onDone } = props;
  const dispatch = useDispatch();
  const { quoteData } = useSelector(getRequestQuoteSelector);
  const [isPending, setIsPending] = useState(false);

  const onDoneHandler = () => {
    setIsPending(true);
    dispatch(
      updateQuoteDraftEffect({}, { silent: true }, (err) => {
        if (!err) {
          onDone();
        }
        setIsPending(false);
      }),
    );
  };

  return (
    <div testid="footer" className={classNames(styles.footer, className)}>
      <div className={styles.count} testid="num_selected">
        {`${pluralize(
          quoteData?.Category?.Title || '',
          quoteData?.Partners?.length,
          true,
        )} selected`}
      </div>
      <Button
        disabled={!quoteData?.Partners?.length}
        isPending={isPending}
        onClick={onDoneHandler}
        className={styles.doneBtn}
        title="Done"
        testid="done_button"
      />
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  onDone: PropTypes.func,
};

Footer.defaultProps = {
  className: '',
  onDone: () => {},
};

export default Footer;
