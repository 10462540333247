import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Continue } from '../../Continue';
import Attachment from './Attachment';
import { Question } from 'pages/RequestQuote/components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { UploadDocuments as CommonUploadDocuments } from 'components/Transactions';

import styles from './styles.module.scss';
import { showErrorMessage } from 'helpers';

const UploadFiles = (props) => {
  const { className, isAssignTask, currentQuestionId, question, onPreFormSubmit, loading } = props;
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);

  const questionId =
    currentQuestionId === transactionPreFormQuestionsIds.purchaseAgreement
      ? transactionPreFormQuestionsIds.purchaseAgreement
      : transactionPreFormQuestionsIds.files;

  const uploadedFiles = cloneDeep(preForm?.[questionId]) || [];

  const updateStoredFiles = (values) => {
    dispatch(setTransactionPreFormQuestionsEffect({ [questionId]: values }));
  };

  const getTotalFileSize = (files) => {
    return files.reduce((total, file) => total + file.size, 0);
  };

  const onSave = (newValues, cb) => {
    const newTotalSize = getTotalFileSize([...uploadedFiles, ...newValues]);
    const maxSize = 190 * 1024 * 1024;
    if (newTotalSize > maxSize) {
      showErrorMessage('exceeds the maximum limit of 190 MB.');
      return;
    }

    updateStoredFiles([...uploadedFiles, ...newValues]);
    cb();
  };

  const onAttachmentRemove = (event, index) => {
    event.stopPropagation();
    uploadedFiles.splice(index, 1);
    updateStoredFiles(uploadedFiles);
  };

  return (
    <div testid="upload_files" className={classNames(styles.container, className)}>
      <Question>{question}</Question>
      <AnswersContainer>
        <CommonUploadDocuments
          dropzone
          uploadButton={null}
          onSave={onSave}
          pendingNeeded={false}
          withPermissions
          isAssignTask={isAssignTask}
          isCategoryOptional
          fillDocumentName={true}
        />
        <div>
          {(uploadedFiles || []).map((file, index) => (
            <Attachment
              key={file?.id || index}
              onRemove={(event) => onAttachmentRemove(event, index)}
              {...file}
            />
          ))}
        </div>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onPreFormSubmit} isPending={loading} />
      </ButtonsContainer>
    </div>
  );
};

UploadFiles.propTypes = {
  className: PropTypes.string,
  currentQuestionId: PropTypes.string,
  isAssignTask: PropTypes.bool,
  question: PropTypes.string,
  onPreFormSubmit: PropTypes.func,
};

UploadFiles.defaultProps = {
  className: '',
  currentQuestionId: null,
  isAssignTask: true,
  question: 'Do you want to upload any files?',
  onPreFormSubmit: () => {},
};

export default UploadFiles;
