import styles from './styles.module.scss';
import { Icons } from '../Icons';

export const FavourtiesHeader = ({ onClose, text }) => {
  return (
    <div className={styles.favHeader}>
      <h3 className={styles.header}>{text}</h3>
      <Icons variant={Icons.CLOSE_ROUND} className={styles.closeButton} onClick={onClose} />
    </div>
  );
};
