import { createSelector } from 'reselect';
import { ConciergeConfigurationType } from 'types';

const localState = ({ conciergeConfiguration }) => conciergeConfiguration.data;

export const getConciergeSearchSelector = createSelector(
  localState,
  ({
    areasOfOperation,
    category,
    services,
    openLocationModal,
    selectedTab,
  }: ConciergeConfigurationType) => {
    return {
      areasOfOperation,
      category,
      services,
      openLocationModal,
      selectedTab,
    };
  },
);
