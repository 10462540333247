import { createAction } from 'redux-actions';

export const socketsStoreNewTransactionMessageAction = createAction(
  'SOCKETS/STORE_NEWTRANSACTION_MESSAGE',
);
export const socketsStoreEditedTransactionMessageAction = createAction(
  'SOCKETS/STORE_EDITED_TRANSACTION_MESSAGE',
);
export const socketsRemoveDeletedTransactionMessageAction = createAction(
  'SOCKETS/REMOVE_DELETED_ TRANSACTION_MESSAGE',
);
export const socketsStoreTransactionMessagesByThreadIdAction = createAction(
  'SOCKETS/STORETRANSACTION_MESSAGES_BY_THREAD_ID',
);
export const socketsResetTransactionMessagesAction = createAction(
  'SOCKETS/RESET_ TRANSACTION_MESSAGES',
);
export const socketSetEditTransactionMessageAction = createAction(
  'SOCKETS/SET_EDIT_  TRANSACTION_MESSAGE',
);
export const setNewTransactionMessageWithTempIdAction = createAction(
  'SET_NEW_TRANSACTION_MESSAGE_WITH_TEMP_ID',
);
export const replaceTempTransactionMessageAction = createAction('REPLACE_TEMP_TRANSACTION_MESSAGE');
export const socketsStoreTransactionMessagesSetThreadIdAction = createAction(
  'SOCKETS/STORE_TRANSACTION_MESSAGES_SET_THREAD_ID',
);
