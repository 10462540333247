import { useSelector } from 'react-redux';
import { LocationService } from 'services';
import { getActiveTabSelector, getAllPropsSelector } from 'store/selectors/feed';
import { getRecommendedPropsSelector } from 'store/selectors/feed';
import { propertiesTabsIds } from 'settings/constants/properties';
import { useNoMlsAccessHandler } from '../Properties/hooks/useNoMlsAccessHandler';
import {
  getAllPropsV2Effect,
  getBackOnMarketPropsV2Effect,
  getNewPropsV2Effect,
  getPriceReductionsPropsV2Effect,
  getRecommendedPropsV2Effect,
  getSavedSearchesPropsV2Effect,
  getStatusChangesPropsV2Effect,
  getSuggestionsPropsV2Effect,
  getWatchlistPropsV2Effect,
  getOpenHousesPropsV2Effect,
  getSearchInstancePropsV2Effect,
} from 'store/effects/feed/feedV2';

import { getClientFavoritesPropsEffect, sortFeedEffect } from 'store/effects';
import { PENDING } from 'settings/constants/apiState';
const locationService = new LocationService();

export const useGetActiveFeedEffect = (feedId: string) => {
  const activeTab = useSelector(getActiveTabSelector);
  const query = locationService.setLocation(location).getQuery();
  const allProps = useSelector(getAllPropsSelector);
  const recommendedProps = useSelector(getRecommendedPropsSelector);

  const { wrappedEffect: getAllPropsEffectWrapped } = useNoMlsAccessHandler(getAllPropsV2Effect);
  const { wrappedEffect: getNewPropsEffectWrapped } = useNoMlsAccessHandler(getNewPropsV2Effect);
  const { wrappedEffect: getSavedSearchesPropsEffectWrapped } = useNoMlsAccessHandler(
    getSavedSearchesPropsV2Effect,
  );
  const { wrappedEffect: getStatusChangesPropsEffectWrapped } = useNoMlsAccessHandler(
    getStatusChangesPropsV2Effect,
  );
  const { wrappedEffect: getRecommendedPropsEffectWrapped } = useNoMlsAccessHandler(
    getRecommendedPropsV2Effect,
  );
  const { wrappedEffect: getClientFavoritesPropsEffectWrapped } = useNoMlsAccessHandler(
    getClientFavoritesPropsEffect,
  );
  const { wrappedEffect: getClientFavoritesPropsEffectForClientIdWrapped } = useNoMlsAccessHandler(
    (cfg, options, cb) =>
      getClientFavoritesPropsEffect(cfg, { ...options, userId: query?.clientId }, cb),
  );
  const { wrappedEffect: getPriceReductionsPropsEffectWrapped } = useNoMlsAccessHandler(
    getPriceReductionsPropsV2Effect,
  );
  const { wrappedEffect: getWatchlistPropsEffectWrapped } =
    useNoMlsAccessHandler(getWatchlistPropsV2Effect);

  const { wrappedEffect: getBackOnMarketPropsEffectWrapped } = useNoMlsAccessHandler(
    getBackOnMarketPropsV2Effect,
  );

  const { wrappedEffect: getSuggestionsPropsEffectWrapped } = useNoMlsAccessHandler(
    getSuggestionsPropsV2Effect,
  );

  const { wrappedEffect: getOpenHousesPropsV2EffectWrapped } = useNoMlsAccessHandler(
    getOpenHousesPropsV2Effect,
  );
  const { wrappedEffect: getSearchInstancePropsEffectWrapped } = useNoMlsAccessHandler(
    getSearchInstancePropsV2Effect,
  );

  const getEffect = () => {
    if (query?.clientId) {
      return getClientFavoritesPropsEffectForClientIdWrapped;
    }
    if (allProps.state !== PENDING && recommendedProps.state !== PENDING) {
      switch (activeTab) {
        case propertiesTabsIds.all:
          return getAllPropsEffectWrapped;
        case propertiesTabsIds.new:
          return getNewPropsEffectWrapped;
        case propertiesTabsIds.savedSearches:
          return getSavedSearchesPropsEffectWrapped;
        case propertiesTabsIds.statusChanges:
          return getStatusChangesPropsEffectWrapped;
        case propertiesTabsIds.recommended:
          return getRecommendedPropsEffectWrapped;
        case propertiesTabsIds.clientFavorites:
          return getClientFavoritesPropsEffectWrapped;
        case propertiesTabsIds.priceReductions:
          return getPriceReductionsPropsEffectWrapped;
        case propertiesTabsIds.watchlist:
          return getWatchlistPropsEffectWrapped;
        case propertiesTabsIds.backOnMarket:
          return getBackOnMarketPropsEffectWrapped;
        case propertiesTabsIds.suggestions:
          return getSuggestionsPropsEffectWrapped;
        case propertiesTabsIds.openHouses:
          return getOpenHousesPropsV2EffectWrapped;
        default:
          return typeof activeTab === 'number' && !isNaN(activeTab)
            ? getSearchInstancePropsEffectWrapped
            : null;
      }
    }
  };

  return getEffect();
};
