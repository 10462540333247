import moment from 'moment';
import styles from './style.module.scss';
import { Icons } from '../components';
import { Table } from 'antd';
import { EditMenu } from './components/EditMenu';
import { FORM_TYPE } from 'app-constants';

import React, { Fragment } from 'react';
import { Checkbox } from 'components-antd';
import { renderSortableTitle, sorterWithCompletedOnTop } from '../../../FormsTable/sortingHelpers';
import { CompareFn, SortOrder } from 'antd/lib/table/interface';
import { PublishedFormType } from 'types';
import { TemplateName } from './components/TemplateName';

const { SELECTION_COLUMN } = Table;

export interface FormLibraryContent {
  formId: number;
  versionId: number;
  name: string;
  lastUpdatedDate: string;
  categories: string[];
  isCAR?: boolean;
  link?: string;
}

interface ColumnProps {
  onPreview: (record) => void;
  showSelection: boolean;
  onDelete: (record) => void;
  rowKeys: React.Key[];
  handleRowKeys: (versionId, checked) => void;
  dynamicForm?: boolean;
  onUpdateNameSuccess?: () => void;
  hideEditIcon?: boolean;
}

export const myLibraryColumns = ({
  rowKeys,
  onDelete,
  onPreview,
  dynamicForm,
  handleRowKeys,
  showSelection,
  onUpdateNameSuccess,
  hideEditIcon,
}: ColumnProps) => [
  {
    key: 'name',
    title: renderSortableTitle('name', 'Form'),
    dataIndex: 'name',
    width: '30%',
    render: (name: string, record) => {
      return (
        <div className={styles.formLibraryName}>
          {showSelection && (
            <div className={styles.multiSelect}>
              <Checkbox
                className={styles.checkbox}
                checked={rowKeys.includes(record.versionId || record.bundleId)}
                onChange={(e: any) => {
                  e.stopPropagation();
                  if (handleRowKeys) {
                    handleRowKeys(record.versionId || record.bundleId, e.target.checked);
                  }
                }}
              />
            </div>
          )}
          <span className={styles.previewAction}>
            <Icons
              variant={record.bundleId ? 'bundleDocument' : 'fileDocument'}
              className={styles.previewIcon}
            />
          </span>

          <TemplateName
            {...record}
            className={styles.fileName}
            iconClass={styles.editFormNameIcon}
            onUpdateNameSuccess={onUpdateNameSuccess}
            showEditIcon={record.type !== FORM_TYPE.Smart && !hideEditIcon}
          />
        </div>
      );
    },
    // sorter: columnSorter('name', onSort) as CompareFn<PublishedFormType>,
    sorter: true,
  },
  {
    key: 'categories',
    title: 'Category',
    dataIndex: 'categories',
    width: '18%',
    render: (categories: string[]) => (
      <div className={styles.formLibraryInfo}>{categories.join(', ')}</div>
    ),
  },
  {
    key: 'publishedBy',
    title: renderSortableTitle('publishedBy', 'Published By'),
    dataIndex: 'publishedBy',
    width: '18%',
    render: (publishedBy: any, record) => (
      <div className={styles.formLibraryInfo}>
        {record?.type === FORM_TYPE.Agent
          ? `${publishedBy?.FirstName} ${publishedBy?.LastName}`
          : 'Mosaik'}
      </div>
    ),
    sorter: sorterWithCompletedOnTop('publishedBy.FirstName') as CompareFn<PublishedFormType>,
  },
  {
    key: 'lastUpdatedDate',
    title: renderSortableTitle('lastUpdatedDate', 'Last Updated'),
    dataIndex: 'lastUpdatedDate',
    width: '17%',
    render: (lastUpdated: string) => (
      <div className={styles.formLibraryInfo}>{moment(lastUpdated).utc().format('MM/DD/YYYY')}</div>
    ),
    sorter: sorterWithCompletedOnTop('lastUpdatedDate') as CompareFn<PublishedFormType>,
  },

  {
    key: '',
    title: '',
    dataIndex: '',
    width: '1%',
    render: (_, record) => {
      return (
        <Fragment>
          <div className={styles.actionIcons}>
            <Icons
              variant={'filePreview'}
              onClick={(e) => {
                e.stopPropagation();
                onPreview(record);
              }}
            />

            {record.type === FORM_TYPE.Agent && record.isOwner && !dynamicForm && (
              <EditMenu onDelete={() => onDelete(record)} record={record} />
            )}

            {record.type === FORM_TYPE.Smart && <Icons variant={'smartForm'} />}
          </div>
        </Fragment>
      );
    },
  },

  ...(showSelection ? [SELECTION_COLUMN] : []),
];
