import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { updateThreadEffect } from 'store/effects/sockets/threads';
import {
  openAddParticipantsDrawerEffect,
  requestGetUserParticipantsListEffect,
} from 'store/effects/drawers/addParticipants';
import { Drawer, Participants, Button } from 'components';
import { Footer } from './components';
import Header from './components/Header';

import { getAddParticipantsDrawerInfoSelector } from './selectors';

import styles from './styles.module.scss';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { requestGetTeamListEffect } from 'store/effects';

const AddParticipantsDrawer = ({ className }) => {
  const dispatch = useDispatch();
  const { participants, drawerOpened, threadId } = useSelector(
    getAddParticipantsDrawerInfoSelector,
  );
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);

  useEffect(() => {
    if (isTeamAgentActive) {
      dispatch(requestGetTeamListEffect());
    }
  }, []);

  useEffect(() => {
    if (drawerOpened && !participants?.length) {
      dispatch(requestGetUserParticipantsListEffect({}, { silent: true }));
    }
  }, [drawerOpened]);

  const [participantsValue, setParticipantsValue] = useState(participants);

  useEffect(() => setParticipantsValue(participants), [participants]);

  const onAddParticipant = (newParticipants) => {
    setParticipantsValue(newParticipants);
  };

  const onDone = () => {
    const newParticipantsIds = participantsValue?.map((participant) => participant?.id);
    dispatch(
      updateThreadEffect({ participantsIds: newParticipantsIds, threadId }, {}, () => {
        dispatch(openAddParticipantsDrawerEffect({ open: false }));
      }),
    );
  };

  const onClose = () => {
    dispatch(openAddParticipantsDrawerEffect({ open: false }));
  };

  return (
    <Drawer
      shouldDisableScroll={false}
      testid="add_participants_drawer"
      onClose={onClose}
      className={classNames(styles.shareDrawer, className)}
      isOpen={drawerOpened}
      header={<Header onClose={() => dispatch(openAddParticipantsDrawerEffect({ open: false }))} />}
      hideOpacityLayer
      zIndex={1000}
    >
      <div className={styles.drawerInner}>
        <div className={styles.scrollWrapper}>
          <Participants
            variant={Participants.BLOCK}
            value={participantsValue}
            onChange={onAddParticipant}
            valuesClassName={styles.participantsValues}
            blockValueClassName={styles.participantsValue}
            inputHolderClassName={styles.autocompleteInputHolder}
            prefixClassName={styles.prefixParticipants}
            wrapperClassName={styles.valuesWrapper}
          />
        </div>
        <Footer>
          <Button
            className={styles.submitButton}
            title="Done"
            onClick={onDone}
            testid="done_button"
            disabled={!participantsValue?.length}
          />
        </Footer>
      </div>
    </Drawer>
  );
};

AddParticipantsDrawer.propTypes = {
  className: PropTypes.string,
};

AddParticipantsDrawer.defaultProps = {
  className: '',
};

export default AddParticipantsDrawer;
