import { prefsIds, PREF_TYPE } from 'settings/constants/preferences';
import Icons from './../../Icons/Icons';

export const LocationCollapsibleOptions = [
  {
    id: prefsIds.walkability,
    showTitle: prefsIds.walkability,
    icon: Icons.WALKABILITY,
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    id: prefsIds.publicTransit,
    showTitle: 'Public Transit',
    icon: Icons.PUBLIC_TRANSIT,
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    id: prefsIds.artAndEntertainment,
    showTitle: 'Arts & Entertainment',
    icon: Icons.ART_AND_ENTERTAINMENT,
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    id: prefsIds.parksAndNature,
    showTitle: 'Parks and Nature',
    icon: Icons.PARKS_AND_NATURE,
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    id: prefsIds.fitness,
    showTitle: prefsIds.fitness,
    icon: Icons.FITNESS,
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    id: prefsIds.restaurants,
    showTitle: 'Restaurants',
    icon: Icons.RESTAURANTS,
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    id: prefsIds.nightlife,
    showTitle: prefsIds.nightlife,
    icon: Icons.NIGHTLIFE,
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    id: prefsIds.coffeeShops,
    showTitle: 'Coffee Shops',
    icon: Icons.COFFEE,
    type: PREF_TYPE.neighborhoodPrefs,
  },
];
