import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import { numberCommaSeparator } from 'helpers/regex';

import styles from './styles.module.scss';

interface NumberInputProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onUpdate: (value: string) => void;
  containerClass?: string;
  prefixClass?: string;
  inputBoxClass?: string;
  containerProps?: any;
  textFitProps?: any;
}

const isDecimal = (value: string, fraction: string) =>
  value.length > 1 && value[value.length - 1] === '.' && fraction === '';

export const NumberInput = ({
  prefix,
  onUpdate,
  inputBoxClass,
  containerClass,
  prefixClass,
  containerProps = {},
  textFitProps = {},
  ...props
}: NumberInputProps) => {
  const handleUserInput = (value: string) => {
    const reformatedValue = value.split(',').join('').trim();
    const [integral, fraction, invalid] = reformatedValue.split('.');

    if (invalid === '' || integral === '00') {
      return;
    } else if (value === '.') {
      onUpdate('0.');
    } else if (value === '' || isDecimal(value, fraction)) {
      onUpdate(value);
    } else if (!Number.isNaN(Number(`${integral}.${fraction ?? '0'}`))) {
      const wholeNumber =
        integral.length > 1 && integral[0] === '0' ? integral.substring(1) : integral;
      onUpdate(
        `${wholeNumber ? wholeNumber.replace(numberCommaSeparator, ',') : '0'}${
          fraction ? '.' + fraction : ''
        }`,
      );
    }
  };

  return (
    <div className={classNames(containerClass, styles.numberBox)} {...containerProps}>
      {prefix && <span className={classNames(prefixClass, styles.prefix)}>{prefix}</span>}
      <input
        type="text"
        onChange={(e) => handleUserInput(e.target.value)}
        className={classNames(
          inputBoxClass,
          { [styles.inputBoxWithPrefix]: !!prefix },
          styles.inputBox,
        )}
        {...props}
        {...textFitProps}
      />
    </div>
  );
};
