import { SuggestionDataItem } from 'react-mentions';
import { useState, ReactNode, useEffect } from 'react';

import { selectAllOption, whiteColor } from 'app-constants';
import { SmartFormIcons, SmartFormIconVariants } from 'components/SmartForm';
import { FormCommentMention } from '../FormCommentMention';
import { Select, Avatar, Divider, Option, Space, Spin, UserOutlinedIcon } from 'components-antd';
import { getMappedCommentUsers } from 'pages/FormProcess/helper';
import {
  AddFormCommentType,
  CommentPermissionType,
  CommentUsersType,
  CustomOptions,
  EditedCommentType,
  FieldInfoType,
} from 'types';

import styles from './styles.module.scss';
import classNames from 'classnames';

interface FormCommentActionBarProps {
  isNewThread: boolean;
  isSending: boolean;
  setSending: (isSending: boolean) => void;
  commentUsers?: CommentUsersType;
  notifyOptions: CustomOptions;
  permissionOptions: CustomOptions;
  notifiedUsers?: string[];
  setNotifiedUsers: (notifiedUsers?: string[]) => void;
  permittedUsers: string[];
  setPermittedUsers: (permittedUsers: string[]) => void;
  handleAddFormComment: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  threadPermissions?: CommentPermissionType[];
  threadId?: number;
  fieldInfo?: FieldInfoType;
  onSend?: () => void;
  actionBarClass?: string;
  permissionSelectorClass?: string;
  placeholder?: string;
  isCommentPopover?: boolean;
}

export const FormCommentActionBar = ({
  isNewThread,
  isCommentPopover,
  isSending,
  setSending,
  commentUsers,
  notifyOptions,
  permissionOptions,
  notifiedUsers,
  setNotifiedUsers,
  permittedUsers,
  setPermittedUsers,
  handleAddFormComment,
  threadPermissions,
  threadId,
  fieldInfo,
  onSend,
  actionBarClass,
  permissionSelectorClass,
  placeholder,
}: FormCommentActionBarProps) => {
  const [tagableUsers, setTagableUsers] = useState<SuggestionDataItem[]>([]);
  const [hideActionBar, setHideActionBar] = useState<boolean>(!!isCommentPopover);

  useEffect(() => {
    if (!threadPermissions?.length) {
      setPermittedUsers([selectAllOption.value]);
    } else {
      setPermittedUsers(threadPermissions.map((threadPermission) => threadPermission.userEmail));
    }
  }, [threadPermissions]);

  useEffect(() => () => setNotifiedUsers(undefined), []);

  useEffect(() => {
    setTagableUsers(
      notifyOptions?.map((notifyOption) => ({
        id: notifyOption.value,
        display: notifyOption.label,
      })) || [],
    );
  }, [notifyOptions]);

  const [editedComment, setEditedComment] = useState<EditedCommentType>({ comment: '' });

  const handleAddComplete = () => {
    let permission: CommentPermissionType[] | undefined;
    let tag: CommentPermissionType[] | undefined;
    let notify: CommentPermissionType[] | undefined;

    if (isNewThread && commentUsers && !permittedUsers.includes('*')) {
      permission = getMappedCommentUsers(commentUsers, permittedUsers);
    }
    if (editedComment?.mentions?.length && commentUsers) {
      tag = getMappedCommentUsers(commentUsers, editedComment?.mentions);
    }
    if (notifiedUsers?.length) {
      notify = getMappedCommentUsers(
        commentUsers,
        notifiedUsers.filter((notifiedUser) => !editedComment.mentions?.includes(notifiedUser)),
      );
    }

    if (editedComment.comment) {
      handleAddFormComment(
        {
          isNewThread,
          comment: editedComment.comment,
          permission,
          tag,
          notify,
          fieldId: fieldInfo?.fieldId,
          meta: fieldInfo?.meta,
          threadId,
        },
        setSending,
      );
      setEditedComment({ comment: '' });
      setNotifiedUsers(undefined);
    }

    if (onSend) onSend();
  };

  return (
    <Space
      direction="vertical"
      style={{ width: '100%' }}
      size="middle"
      className={isCommentPopover ? styles.commentPopoverSpace : ''}
    >
      <div onClick={() => hideActionBar && setHideActionBar(false)}>
        <FormCommentMention
          tagableUsers={tagableUsers}
          editedComment={editedComment}
          setEditedComment={setEditedComment}
          placeholder={placeholder || 'Start typing...'}
        />
      </div>

      <div
        className={classNames(styles.actionBar, actionBarClass, {
          [styles.commentPopoverActionBar]: isCommentPopover,
          [styles.hideActionBar]: hideActionBar,
        })}
      >
        <div className={styles.setActions}>
          <Select
            disabled={isSending}
            suffixIcon={
              <SmartFormIcons variant={SmartFormIconVariants.Tag} style={{ cursor: 'pointer' }} />
            }
            showSearch={false}
            className={styles.tagSelector}
            value={notifiedUsers}
            showArrow={true}
            mode="multiple"
            onDropdownVisibleChange={() =>
              !permittedUsers?.length && setPermittedUsers([selectAllOption.value])
            }
            onChange={setNotifiedUsers}
            popupClassName={styles.notifyOptions}
            menuItemSelectedIcon={<SmartFormIcons variant={SmartFormIconVariants.Tick} />}
          >
            {notifyOptions?.map((option, i) => (
              <Option key={`${option.value}-option-${i}`} value={option.value}>
                <Avatar src={option.avatar} size={24} icon={<UserOutlinedIcon />} />
                <span className={styles.tagName}>{option.label}</span>
                {option.role ? <span className={styles.rolePill}>{option.role}</span> : <></>}
              </Option>
            ))}
          </Select>

          <Divider type="vertical" className={styles.actionDivider} />

          <Select
            disabled={!isNewThread || isSending}
            showSearch={false}
            className={classNames(styles.permissionSelector, permissionSelectorClass, {
              [styles.disabledSelector]: !isNewThread || isSending,
            })}
            mode="multiple"
            showArrow={true}
            value={permittedUsers}
            onChange={(selectedOptions) =>
              selectedOptions.includes(selectAllOption.value)
                ? setPermittedUsers([selectAllOption.value])
                : setPermittedUsers(selectedOptions)
            }
            tagRender={(props) => (
              <span
                style={{ background: 'transparent', border: 'none' }}
                className="ant-select-selection-item"
              >
                {props.label}
              </span>
            )}
            defaultValue={selectAllOption.value}
            popupClassName={styles.permissionOptions}
            menuItemSelectedIcon={<SmartFormIcons variant={SmartFormIconVariants.Tick} />}
            dropdownRender={(originNode: ReactNode) => (
              <div>
                <div style={{ padding: 10, color: '#AAABAB' }}>
                  Select who can see this comment.
                </div>
                {originNode}
              </div>
            )}
          >
            {[selectAllOption, ...permissionOptions].map((option, i) => (
              <Option
                key={`${option.value}-option-${i}`}
                disabled={
                  permittedUsers?.includes(selectAllOption.value) &&
                  option.value !== selectAllOption.value
                }
                value={option.value}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        </div>

        {isSending ? (
          <Spin size="small" className={styles.sendingLoader} />
        ) : (
          <span
            className={!editedComment.comment ? styles.sendIcon : styles.sendIconActive}
            onClick={() => editedComment.comment && handleAddComplete()}
          >
            <SmartFormIcons
              variant={SmartFormIconVariants.Send}
              {...(editedComment.comment && { stroke: whiteColor })}
            />
          </span>
        )}
      </div>
    </Space>
  );
};
