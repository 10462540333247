export const ClarityCriterias = {
  userEngagement: 'user engagement',
  searchEngagement: 'search engagement',
  conciergeEngagement: 'concierge engagement',
  potentialListingOpportunities: 'potential listing opportunities',
  behaviorChanges: 'behavior changes',
  kitsPerformance: 'KITs performance',
};

export const ClarityActivityType = {
  noReportsAvailable: 'no reports available',
  //clientActivity: 'client activity',
};

export const ClarityVisibilityDuration = {
  fiveDays: '5 Days',
  tenDays: '10 Days',
  fifteenDays: '15 Days',
  thirtyDays: '30 Days',
  sixtyDays: '60 Days',
};
