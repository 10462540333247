import {
  NotificationSubVariants,
  TimeOptions,
  mapItemTitles,
} from 'settings/constants/notifications';

export const firstStepInputGroup = [NotificationSubVariants.HasMatchScore];

export const getPropertyNotifications = (name) => ({
  Type: 'Properties',
  NotificationItems: [
    {
      Types: [
        'NewListingClient',
        'NewListingWithCustomMatchscoreClient',
        'NewListingSummary',
        'NewListingSummaryWithMatchscore',
      ],
      Text: `There is a new listing that matches ${name}’s search criteria.`,
      SecondSubText: 'Send the notification:',
      Variants: {
        InApp: false,
        Sms: false,
        Email: false,
      },
      SubVariants: [
        {
          Key: 'All',
          Checked: false,
        },
        {
          Key: 'HasMatchScore',
          Checked: false,
          Types: ['NewListingWithCustomMatchscoreClient'],
        },
      ],
      SecondSubVariants: [
        {
          Key: 'Realtime',
          Checked: false,
          Types: ['NewListingClient', 'NewListingWithCustomMatchscoreClient'],
        },
        {
          Key: 'OnceDay',
          Checked: false,
          Types: ['NewListingSummary', 'NewListingSummaryWithMatchscore'],
        },
      ],
      Roles: ['Client'],
    },
    {
      Types: ['NewAgentRecommendation', 'NewAgentRecommendationSummary'],
      Text: `I suggest a property for ${name}.`,
      SubText: 'Send the notification:',
      Variants: {
        InApp: false,
        Sms: false,
        Email: false,
      },
      SubVariants: [
        {
          Key: 'Realtime',
          Checked: false,
          Types: ['NewAgentRecommendation'],
        },
        {
          Key: 'OnceDay',
          Checked: false,
          Types: ['NewAgentRecommendationSummary'],
        },
      ],
      Roles: ['Client'],
    },
    // {
    //   Types: [
    //     // Use actual types when implemented on BE
    //   ],
    //   // Verify client's text on notification breakdown
    //   Text: `There is a price reduction on a listing that matches ${name}'s search criteria.`,
    //   SecondSubText: 'Send the notification:',
    //   Variants: {
    //     InApp: false,
    //     Sms: false,
    //     Email: false,
    //   },
    //   SubVariants: [
    //     {
    //       Key: 'All',
    //       Checked: false,
    //     },
    //     {
    //       Key: 'HasMatchScore',
    //       Checked: false,
    //       // Use actual types when implemented on BE
    //       Types: [],
    //     },
    //   ],
    //   SecondSubVariants: [
    //     {
    //       Key: 'Realtime',
    //       Checked: false,
    //       // Use actual types when implemented on BE
    //       Types: [],
    //     },
    //     {
    //       Key: 'OnceDay',
    //       Checked: false,
    //       // Use actual types when implemented on BE
    //       Types: [],
    //     },
    //     {
    //       Key: 'OnceWeek',
    //       Checked: false,
    //       // Use actual types when implemented on BE
    //       Types: [],
    //     },
    //   ],
    //   Roles: ['Client'],
    // },
  ],
});

export const getTheFirstStepOptions = (item) => {
  if (item?.SubVariants?.length) {
    return item.SubVariants.map((subVar, index) => {
      if (
        firstStepInputGroup.includes(subVar.Key) ||
        (item.SubVariants.filter((subVar) => firstStepInputGroup.includes(subVar.Key)).length &&
          subVar.Key === NotificationSubVariants.All)
      ) {
        const isInput = subVar.Key === NotificationSubVariants.HasMatchScore;

        return {
          isInput,
          inputText: isInput ? mapItemTitles.SubVariants[subVar.Key]?.split('-')?.[1] : '',
          label: isInput
            ? mapItemTitles.SubVariants[subVar.Key]?.split('-')?.[0]
            : mapItemTitles.SubVariants[subVar.Key],
          value: subVar.Key,
          checked: subVar.Checked,
          inputValue: subVar.Value,
          inputPlaceholder: '99',
        };
      }
      if (subVar.Key === NotificationSubVariants.QuoteRequestRemind) {
        return {
          inputText: mapItemTitles.SubVariants[subVar.Key],
          value: subVar.Key,
          checked: subVar.Checked,
          inputValue: subVar.Value,
          dropdownOptions: TimeOptions,
          selectedValue: subVar.SelectedValue,
        };
      }
      return {
        label: mapItemTitles.SubVariants[subVar.Key],
        value: subVar.Key,
        checked: subVar.Checked,
      };
    });
  }
};

export const getTheSecondStepOptions = (item) => {
  if (item.SecondSubVariants?.length) {
    return item.SecondSubVariants.map((secondSubVar) => {
      if (
        [NotificationSubVariants.TheDayOf, NotificationSubVariants.DaysInAdvance].includes(
          secondSubVar.Key,
        )
      ) {
        return {
          isInput: secondSubVar.Key === NotificationSubVariants.DaysInAdvance,
          inputText: mapItemTitles.SubVariants[secondSubVar.Key],
          label: mapItemTitles.SubVariants[secondSubVar.Key],
          value: secondSubVar.Key,
          checked: secondSubVar.Checked,
          inputValue: secondSubVar.Value,
        };
      }
      return {
        label: mapItemTitles.SubVariants[secondSubVar.Key],
        value: secondSubVar.Key,
        checked: secondSubVar.Checked,
      };
    });
  }
};

export const getInitialValues = (name) => ({
  isEnabled: null,
  NotificationPreferences: [getPropertyNotifications(name)],
});
