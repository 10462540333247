import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from 'components';

import styles from './styles.module.scss';

const Avatars = ({
  avatars,
  className,
  avatarClassName,
  overflowClassName,
  avatarWrapperClassName,
  isNotSingleClassName,
}) => {
  const overflowCount = avatars.length - 2;
  const showOverflowCount = avatars.length > 2;
  const isNotSingleAvatar = avatars.length > 1;

  return (
    <div testid="avatars" className={classNames(styles.avatars, className)}>
      <div className={styles.avatarsHolder}>
        {avatars.slice(0, 2).map(({ url, placeholder }, index) => (
          <Avatar
            key={url || index}
            className={classNames(
              styles.avatarWrapper,
              { [styles.isNotSingleAvatar]: isNotSingleAvatar },
              { [isNotSingleClassName]: isNotSingleAvatar },
              avatarWrapperClassName,
            )}
            avatarClassName={classNames(
              styles.avatar,
              { [styles.isNotSingleAvatar]: isNotSingleAvatar },
              { [isNotSingleClassName]: isNotSingleAvatar },
              avatarClassName,
            )}
            placeholder={placeholder}
            src={url}
          />
        ))}
        {showOverflowCount && (
          <span
            className={classNames(styles.overflowCount, overflowClassName)}
          >{`+${overflowCount}`}</span>
        )}
      </div>
    </div>
  );
};

Avatars.propTypes = {
  className: PropTypes.string,
  avatarWrapperClassName: PropTypes.string,
  isNotSingleClassName: PropTypes.string,
  overflowClassName: PropTypes.string,
  avatarClassName: PropTypes.string,
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      placeholer: PropTypes.string,
    }),
  ),
};

Avatars.defaultProps = {
  className: '',
  avatarWrapperClassName: '',
  isNotSingleClassName: '',
  overflowClassName: '',
  avatarClassName: '',
  avatars: [],
};

export default Avatars;
