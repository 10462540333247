import { Row, Col } from 'components-antd';
import { Filter } from './Filter';

import styles from './styles.module.scss';

export const HeaderTop = (props) => {
  const { onChange, activeValue } = props;
  return (
    <div className={styles.headerTop}>
      <Row>
        <Col xs={0} lg={8} />
        <Col xs={15} lg={8}>
          <h1>Symphony</h1>
        </Col>
        <Col xs={9} lg={8}>
          <Filter onChange={onChange} activeValue={activeValue} />
        </Col>
      </Row>
    </div>
  );
};
