import Api from 'store/effects/core/api';
import { requestMarkNotificationAsReadAction } from 'store/actions/notifications';
import { markNotificationAsRead } from 'api/notifications';

export function markNotificationAsReadEffect(cfg, options, cb) {
  const sendRequest = Api.execResult({
    action: requestMarkNotificationAsReadAction,
    method: markNotificationAsRead,
  });

  return sendRequest(cfg, options, cb);
}
