import { useState } from 'react';
import { Tooltip } from 'components-antd';
import classNames from 'classnames';

import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';

import styles from './styles.module.scss';

export const FullAccessInfo = () => {
  const [open, setOpen] = useState(false);

  const content = () => (
    <div className={styles.content}>
      <p>
        <span>Full access will allow the user to:</span>
      </p>
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Edit timelines</p>
      <br />
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>View all offers and showings</p>
      <br />
      <div className={styles.tasks}>
        <Icon
          variant={Icon.CHECK_MARK}
          className={classNames(styles.inline, styles.taskCheckMark)}
        />
        <p className={styles.inline}>
          View all tasks (regardless of who they are assigned to or who assigned them)
        </p>
      </div>
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Edit transaction details (status, etc.)</p>
      <br />
      <div className={styles.tasks}>
        <Icon
          variant={Icon.CHECK_MARK}
          className={classNames(styles.inline, styles.taskCheckMark)}
        />
        <p className={styles.inline}>View private milestones, contacts, and details</p>
      </div>
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Access financial details</p>
    </div>
  );

  return (
    <div
      className={styles.fullAccessInfo}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Tooltip
        title={content}
        placement="bottom"
        open={open}
        overlayClassName={styles.fullAccessTooltip}
      >
        <Icon variant={Icon.INFO} />
      </Tooltip>
    </div>
  );
};
