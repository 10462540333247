import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Table } from '../components/Table';
import { DEFAULT_PAGE_SIZE } from '../components/Table/configs';
import Input from 'components/Form/Input';
import { Button } from 'components';
import { SearchIcon } from 'components/Icons';
import styles from './styles.module.scss';

import { getEvents } from 'api/admin';
import { EventsDataSource, eventsColumns } from './configs';
import Header from 'pages/Dashboard/components/Header';

export const AuditEvents = () => {
  const [allData, setAllData] = useState<EventsDataSource[]>([]);
  const [dataSource, setDataSource] = useState<EventsDataSource[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const handleChangePage = useCallback(
    (tableInfo) => {
      const page = tableInfo?.paginationInfo?.page || 1;
      setCurrentPage(page);
      const startIndex = (page - 1) * pageSize;
      const paginatedData = allData.slice(startIndex, startIndex + pageSize);
      setDataSource(paginatedData);
    },
    [allData, pageSize],
  );

  const transformData = useCallback((data) => {
    return data.map((item) => ({
      key: item?.key,
      topic: item?.topic,
      auditEventType: item?.auditEvent?.Type,
      auditLogicNotes: item?.auditEvent?.LogicNotes,
      notificationEventType: item?.notificationEvent?.Type,
      notificationCategory: item?.notificationEvent?.Category,
      notificationEligibleRecipients: item?.notificationEvent?.EligibleRecipients,
      notificationSubject: item?.notificationEvent?.Subject,
      notificationLogicNotes: item?.notificationEvent?.LogicNotes,
      notificationChannels: item?.notificationEvent?.channels,
      testable: item?.auditEvent?.testable || item?.notificationEvent?.testable,
    }));
  }, []);

  const handleSubmitSearch = useCallback(
    (values) => {
      const searchString = values.searchString.toLowerCase();
      const filteredData = allData.filter(
        (item) =>
          item.key.toLowerCase().includes(searchString) ||
          item.topic.toLowerCase().includes(searchString) ||
          item.auditEventType?.toLowerCase().includes(searchString) ||
          item.notificationEventType?.toLowerCase().includes(searchString) ||
          item.notificationCategory?.toLowerCase().includes(searchString) ||
          item.notificationEligibleRecipients?.some((recipient) =>
            recipient.toLowerCase().includes(searchString),
          ),
      );
      setDataSource(filteredData.slice(0, pageSize));
      setTotal(filteredData.length);
      setCurrentPage(1);
    },
    [allData, pageSize],
  );

  const handleFetchEvents = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getEvents();
      const transformedData = transformData(data?.value || []);
      setAllData(transformedData);
      setDataSource(transformedData.slice(0, pageSize));
      setTotal(transformedData.length);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    void handleFetchEvents();
  }, []);

  const formik = useFormik({
    initialValues: {
      searchString: '',
    },
    onSubmit: handleSubmitSearch,
  });

  return (
    <div>
      <Header title="Audit Events" />
      <form onSubmit={formik.handleSubmit} className={styles.filtersWrapper}>
        <Input
          id="searchString"
          className={styles.input}
          type="text"
          name="searchString"
          placeholder="Search"
          error={formik.touched.searchString ? formik.errors.searchString : ''}
          value={formik.values.searchString}
          onChange={formik.handleChange}
          disabled={loading}
          variant={Input.LIGHT_FULL}
          data-testid="search_input"
        />
        <Button
          type="submit"
          className={styles.searchButton}
          isPending={loading}
          title={<SearchIcon />}
          testid="search_button"
        />
      </form>
      <Table
        columns={eventsColumns}
        dataSource={dataSource}
        loading={loading}
        pagination={{
          total,
          current: currentPage,
          pageSize,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          defaultCurrent: 1,
        }}
        onChange={handleChangePage}
        className={styles.tableWrapper}
      />
    </div>
  );
};

export default AuditEvents;
