export const TeamIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M25.7989 25.2748C32.6293 18.4445 33.7227 8.7232 32.5723 7.57282C31.422 6.42245 21.7007 7.5159 14.8703 14.3463C8.03997 21.1766 7.50073 27.4677 7.50073 27.4677L12.6774 32.6444C12.6774 32.6444 18.9685 32.1052 25.7989 25.2748Z"
        fill="url(#paint0_linear_53754_390712)"
      />
      <path
        d="M25.7989 25.2748C32.6293 18.4445 33.7227 8.7232 32.5723 7.57282C31.422 6.42245 21.7007 7.5159 14.8703 14.3463C8.03997 21.1766 7.50073 27.4677 7.50073 27.4677L12.6774 32.6444C12.6774 32.6444 18.9685 32.1052 25.7989 25.2748Z"
        fill="url(#paint1_linear_53754_390712)"
      />
      <path
        d="M17.645 21.4648L3.6858 21.4648C2.89234 21.4648 2.49497 22.4242 3.05604 22.9852L17.0153 36.9445C17.5763 37.5055 18.5356 37.1081 18.5356 36.3147L18.5356 22.3555C18.5356 21.8636 18.1369 21.4648 17.645 21.4648Z"
        fill="#FF576D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5871 30.7178V22.4082C18.5871 21.9163 18.1884 21.5176 17.6965 21.5176H9.60652C7.89044 24.805 7.58892 27.1766 7.55566 27.4861L12.7633 32.6937C13.0682 32.661 15.3749 32.3678 18.5871 30.7178Z"
        fill="#E4364D"
      />
      <defs>
        <linearGradient
          id="paint0_linear_53754_390712"
          x1="28.5"
          y1="11"
          x2="20"
          y2="31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB913A" />
          <stop offset="1" stopColor="#FF7809" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_53754_390712"
          x1="28.5"
          y1="11"
          x2="20"
          y2="31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB913A" />
          <stop offset="1" stopColor="#FF6109" />
        </linearGradient>
      </defs>
    </svg>
  );
};
