//These functions will calculate zoom level for dynamic drawn polygon on the bases of coordinates.
export const getZoomForBounds = (bounds, worldDim) => {
  const ZOOM_MAX = 21;

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(worldDim.height, latFraction, bounds);
  const lngZoom = zoom(worldDim.height, lngFraction, bounds);

  const result = Math.min(latZoom, lngZoom, ZOOM_MAX);
  return result;
};

const latRad = (lat) => {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

const zoom = (mapPx, fraction, bounds) => {
  return Math.log(mapPx / 256 / fraction) / Math.LN2;
};
