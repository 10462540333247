import { createAction } from 'redux-actions';

export const requestGetAllFormFolders = createAction('REQUEST?GET_ALL_FORMFOLDERS');
export const requestGetAllFormsAction = createAction('REQUEST/GET_ALL_FORMS');
export const requestGetFormByIdAction = createAction('REQUEST/GET_FORM_BY_ID');
export const requestPostCreateFormAction = createAction('REQUEST/POST_CREATE_FORM');
export const requestGetRefereshFormAction = createAction('REQUEST/GET_REFERESH_FORM');
export const requestUpdateFormFiledAction = createAction('REQUEST/UPDATE_FORM_FIELDS');
export const requestSetSelectedFormFieldAction = createAction('REQUEST/SET_SELECTED_FORM_FIELD');
export const requestPostCreateFolderAction = createAction('REQUEST/CREATE_FOLDER');
export const requestGetFolderByIdAction = createAction('REQUEST/GET_FOLDER_BY_ID');
export const requestUpdateFolderNameAction = createAction('REQUEST/UPDATE_FOLDER_NAME');
export const requestSetActiveFolderAction = createAction('REQUEST/ACTIVE_FOLDER_LINK');
export const requestSetArchiveFolderAction = createAction('REQUEST/ARCHIVE_FOLDER');
export const requestGetSearchFormAction = createAction('REQUEST/SEARCH_FORM');
