import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Image = ({ src, className }) => {
  if (!src) return null;

  return (
    <div className={classNames(styles.imageWrapper, className)}>
      <img src={src} alt="" />
    </div>
  );
};

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
};

Image.defaultProps = {
  className: '',
  src: undefined,
};

export default Image;
