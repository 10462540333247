import { Button, SimpleModal } from 'components';
import React from 'react';
import classNames from 'classnames';

import placeholderImage from 'images/avatar-placeholder.png';

import styles from './styles.module.scss';

type Props = {
  selectedPartner: any;
  onCloseModal: () => void;
  isLoadingRecommendation: boolean;
  onChangeRecommendation: () => void;
};

export const RecommendationModal: React.FC<Props> = ({
  selectedPartner,
  onCloseModal,
  isLoadingRecommendation,
  onChangeRecommendation,
}) => {
  return (
    <SimpleModal
      isOpen={!!selectedPartner}
      onClose={onCloseModal}
      contentClassName={styles.modalContent}
      innerHolderClassName={styles.modalContentInner}
      testid="recommend_modal"
    >
      <div className={styles.modalContentAvatar}>
        <img
          testid="avatar"
          src={selectedPartner?.LogoUrl ? selectedPartner?.LogoUrl : placeholderImage}
          alt="partner avatar"
        />
        {/* <div className={styles.modalContentAvatarRecommended}>
            {seletedPartner?.isMyRecommended ? <UnRecommendedIcon /> : <RecommendedIcon />}
          </div> */}
      </div>
      <div testid="title" className={styles.modalContentTitle}>
        {selectedPartner?.isMyRecommended
          ? `Are you sure you want to remove ${selectedPartner?.BusinessName} from your recommendations?`
          : `Are you sure you want to add ${selectedPartner?.BusinessName} to your recommendations?`}
      </div>
      <div testid="description" className={styles.modalContentDesc}>
        {selectedPartner?.isMyRecommended
          ? `Your clients will no longer see that you recommended ${selectedPartner?.BusinessName}.`
          : `Your clients will see that you recommended ${selectedPartner?.BusinessName}.`}
      </div>
      <div className={styles.modalContentButtons}>
        <Button
          className={styles.cancelButton}
          title="Cancel"
          onClick={onCloseModal}
          testid="cancel_button"
        />
        <Button
          isPending={isLoadingRecommendation}
          className={classNames(
            { [styles.yesButton]: selectedPartner?.isMyRecommended === false },
            {
              [styles.remove]: selectedPartner?.isMyRecommended === true,
            },
          )}
          title={selectedPartner?.isMyRecommended === true ? 'Remove' : 'Add'}
          onClick={onChangeRecommendation}
          testid="yes_button"
        />
      </div>
    </SimpleModal>
  );
};
