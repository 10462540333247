import { prefsIds, PREF_TYPE } from 'settings/constants/preferences';
import { SoftCriteriaType } from '../SoftCriteriaType';

import styles from '../styles.module.scss';

export const NeighborhoodPrefs = ({ softCriteria, addSoftCriteria }) => {
  return (
    <div className={styles.softCriteriaCollapse}>
      <div className={styles.softCollapse}>
        {NeighborhoodPrefs.NEIGHBORHOOD_OPTIONS.map((pref, i) => (
          <SoftCriteriaType
            key={i}
            title={pref.title}
            icon={pref.icon}
            softCriteria={softCriteria}
            onClick={addSoftCriteria}
            showTitle={pref.showTitle}
            type={pref.type}
          />
        ))}
      </div>
    </div>
  );
};

NeighborhoodPrefs.NEIGHBORHOOD_OPTIONS = [
  {
    title: prefsIds.walkability,
    showTitle: prefsIds.walkability,
    icon: 'walkability',
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    title: prefsIds.publicTransit,
    showTitle: 'Public Transit',
    icon: 'publicTransit',
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    title: prefsIds.goodSchools,
    showTitle: 'Schools',
    icon: 'goodSchools',
    // type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    title: prefsIds.parksAndNature,
    showTitle: 'Parks and Nature',
    icon: 'parksAndNature',
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    title: prefsIds.restaurants,
    showTitle: 'Restaurants',
    icon: 'restaurants',
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    title: prefsIds.nightlife,
    showTitle: prefsIds.nightlife,
    icon: 'nightlife',
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    title: prefsIds.coffeeShops,
    showTitle: 'Coffee Shops',
    icon: 'coffeeShops',
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    title: prefsIds.artAndEntertainment,
    showTitle: 'Arts & Entertainment',
    icon: 'artAndEntertainment',
    type: PREF_TYPE.neighborhoodPrefs,
  },
  {
    title: prefsIds.fitness,
    showTitle: prefsIds.fitness,
    icon: 'fitness',
    type: PREF_TYPE.neighborhoodPrefs,
  },
];
