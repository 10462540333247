import React from 'react';
import PropTypes from 'prop-types';

const Icons = ({ testid, className, variant, onClick }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.DEACTIVATE: {
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33 11L11 33"
              stroke="black"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 11L33 33"
              stroke="black"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ACTIVATE: {
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.16675 21.9999L18.3334 31.1666L36.6667 12.8333"
              stroke="black"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className}>
      {renderIcon()}
    </div>
  );
};

Icons.DEACTIVATE = 'deactivate';
Icons.ACTIVATE = 'activate';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};

export default Icons;
