import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers/formatters';

import styles from './styles.module.scss';
import moment from 'moment';
import { AuditLogItemContent } from 'components-antd/AuditLogCard/AuditLogItemContent';

export const Activity = ({ data }) => {
  const FirstName = data?.UserFirstName
    ? data?.UserFirstName
    : data.Payload?.ByWho?.FirstName
    ? data.Payload?.ByWho?.FirstName
    : '';

  const LastName = data?.UserLastName
    ? data?.UserLastName
    : data.Payload?.ByWho?.LastName
    ? data.Payload?.ByWho?.LastName
    : '';
  return (
    <div className={styles.card}>
      <Avatar
        className={styles.avatarWrapper}
        avatarClassName={styles.avatar}
        src={data.AvatarUrl}
        placeholder={convertNameToAvatarPlaceholder(`${FirstName} ${LastName} `)}
      />
      <AuditLogItemContent itemContent={data} />
      <p className={styles.timestamp}>
        {moment(data.ActionTimestamp).format('MM/DD')}{' '}
        {moment(data.ActionTimestamp).format('hh:mm A')}
      </p>
    </div>
  );
};

Activity.propTypes = {
  data: PropTypes.object,
};

Activity.defaultProps = {
  data: {},
};
