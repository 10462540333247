import { Button, Footer, Text } from 'components-antd';
import styles from './styles.module.scss';

import { Navigation } from 'pages/FormProcess/components/PreForm/components';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { previewDocumentEffect } from 'store/effects';
import fileDownload from 'js-file-download';
import { useHistory, useLocation } from 'react-router-dom';
import { DynamicFormType } from 'types';
import { useEffect, useMemo, useState } from 'react';
import { dynamicManager } from '../DynamicManager';

type SuccessFormState = {
  dynamicFormData: DynamicFormType;
};

export const SignFormSuccess = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { state: formState } = useLocation<SuccessFormState>();

  const state = useMemo(() => formState, []);

  useEffect(() => {
    if (!state || !state.dynamicFormData) {
      history.push('/');
    }
  }, []);

  const onDownload = () => {
    const { dynamicFormDocuments } = state.dynamicFormData;

    if (dynamicFormDocuments?.length) {
      const anonymousDetails = dynamicManager.getAnonymousDetails() || {};
      setLoading(true);
      dispatch(
        previewDocumentEffect(
          {
            DocumentVaultUUID: dynamicFormDocuments[0].DocumentLink,
            ...anonymousDetails,
          },
          {},
          (err, response) => {
            setLoading(false);
            if (err) {
              return;
            }
            fileDownload(response.data, response.headers['file-name']);
          },
        ),
      );
    }
  };

  const onGoToMosaik = () => {
    history.push('/');
  };

  if (!state || !state.dynamicFormData) return <></>;

  return (
    <div className={styles.signFormSuccessPage}>
      <div className={styles.successPageContent}>
        <Icons variant={ICON_VARIANT_TYPE.SuccessTick} />
        <Text className={styles.title}>Form has been signed!</Text>

        <div className={styles.buttonsContainer}>
          {/* <Button
            variant="secondary"
            className={classNames(styles.button, styles.downloadButton)}
            onClick={onDownload}
            buttonIcon={<Icons variant={ICON_VARIANT_TYPE.Download} className={styles.editIcon} />}
            loading={loading}
          >
            Download Form
          </Button> */}

          <Button
            variant="default"
            className={classNames(styles.button, styles.mainButton)}
            onClick={onGoToMosaik}
          >
            Go To Mosaik
          </Button>
        </div>
      </div>

      <Footer className={styles.footerArea} />
    </div>
  );
};
