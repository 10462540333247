import { HTMLAttributes, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { appLogoutEffect } from 'store/effects/app';
import { routes } from 'settings/navigation/routes';
import { HelpAndSupport, ArrowRight, Logout, Settings, Drafts } from 'components/Icons';
import { Avatar, AvatarProps, Popover } from 'components-antd';
import {
  getUserAvatar,
  getUserFirstName,
  getUserAvatarPlaceholder,
  getUserFullName,
  getUserRolesMapSelector,
} from 'store/selectors/user';
import styles from './styles.module.scss';
import { upperCase } from 'lodash-es';

interface HeaderAvatarProps extends HTMLAttributes<HTMLDivElement> {
  popover?: boolean;
  avatarNameStyles?: string;
  minimizeAvatar?: boolean;
}

export interface UserAvatarProps extends AvatarProps {
  name: string;
  avatar?: string;
  placeholder?: string;
  avatarNameStyles?: string;
  minimizeAvatar?: boolean;
  size?: number;
  shortName?: boolean;
}
interface UserNavigationProps extends HTMLAttributes<HTMLDivElement> {
  isRow?: boolean;
}

export const HeaderAvatar = ({
  popover,
  className,
  avatarNameStyles,
  minimizeAvatar,
  ...props
}: HeaderAvatarProps) => {
  const avatar = useSelector(getUserAvatar);
  const name = popover ? useSelector(getUserFirstName) : useSelector(getUserFullName);
  const placeholder = useSelector(getUserAvatarPlaceholder);
  const avatarProps = { avatar, name, placeholder, avatarNameStyles, minimizeAvatar };

  return (
    <div
      className={classNames(
        'prevent-user-select',
        styles.avatarContainer,
        {
          [styles.popoverAvatarContainer]: popover,
        },
        className,
      )}
      {...props}
    >
      {popover ? (
        <Popover
          content={<UserNavigation />}
          getPopupContainer={(triggerNode) => triggerNode}
          trigger="hover"
          placement="bottomLeft"
        >
          <span className={styles.popoverAvatar}>
            <UserAvatar {...avatarProps} />
          </span>
        </Popover>
      ) : (
        <div data-testid="header-user-navigation">
          <div className={styles.avatarRow}>
            <UserAvatar {...avatarProps} />
          </div>
          <UserNavigation isRow={true} className={styles.userNavigationRow} />
        </div>
      )}
    </div>
  );
};
export const UserAvatar = ({
  avatar,
  placeholder,
  name,
  avatarNameStyles,
  minimizeAvatar,
  shortName,
  size = 32,
  className,
  ...props
}: UserAvatarProps) => (
  <>
    <Avatar
      className={classNames({ [styles.avatarName]: !avatar }, className)}
      size={size}
      src={avatar}
      {...props}
    >
      {placeholder ?? (shortName && name ? getShortName(name) : <></>)}
    </Avatar>
    {minimizeAvatar ? null : <p className={classNames(styles.name, avatarNameStyles)}>{name}</p>}
  </>
);

export const getShortName = (name: string) => {
  const namesCharacters = name.split(' ');

  const placeholder = upperCase(
    `${namesCharacters.map((nameCharacters) => nameCharacters[0]).join('')}`,
  );

  return placeholder;
};

const UserNavigation = ({ className, isRow }: UserNavigationProps) => {
  const dispatch = useDispatch();

  const onLogout = useCallback(() => {
    dispatch(appLogoutEffect());
  }, [dispatch]);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  return (
    <div className={classNames(styles.userNavigationMenu, className)}>
      <ul>
        <li>
          <Link className={styles.link} to={routes.settings}>
            <span className={styles.iconColumn}>
              <Settings className={styles.icon} />
              Settings
            </span>
            <ArrowRight className={styles.arrowIcon} />
          </Link>
        </li>
        {isAgent ? (
          <li>
            <Link className={styles.link} to={routes.templatesTransactions}>
              <span className={styles.iconColumn}>
                <Drafts className={styles.icon} />
                Templates {}
              </span>
              <ArrowRight className={styles.arrowIcon} />
            </Link>
          </li>
        ) : (
          ''
        )}

        {/* <li className={classNames({ [styles.helpAndSupport]: isRow })}>
          <Link className={styles.link} to={routes.helpAndSupport}>
            <span className={styles.iconColumn}>
              <HelpAndSupport className={styles.icon} />
              Help &amp; Support
            </span>
            <ArrowRight className={styles.arrowIcon} />
          </Link>
        </li> */}
        <li>
          <span className={styles.link} onClick={onLogout}>
            <span className={styles.iconColumn}>
              <Logout className={styles.icon} />
              Log out
            </span>
          </span>
        </li>
      </ul>
    </div>
  );
};
