import classNames from 'classnames';
import moment from 'moment';
import Tooltip from 'antd/lib/tooltip';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { toggleFavoriteFormEffect, selectReloadFormListEffect } from 'store/effects/formBuilder';
import { FolderType, FormListType, FormVersionType } from 'types';
import FavoritesIcon from 'pages/FormBuilder/Icons/Favorites';
import { link } from 'settings/navigation/link';
import { FORM_TYPE } from 'app-constants';

import styles from './styles.module.scss';

export const formListColumns: Array<Record<string, unknown>> = [
  {
    width: 176,
    title: 'Form Name',
    key: 'formName',
    dataIndex: 'CurrentVersion',
    render: (form: FormVersionType, row: FormListType, index) => {
      return (
        <NavLink
          to={
            row.Type === FORM_TYPE.Quote
              ? link.toDashboardServiceDirectoryFormsQuoteForm(form.Id)
              : link.toDashboardServiceDirectoryFormsSmartForm(form.Id)
          }
        >
          <span className={classNames(styles.textBold, styles.formName)}>{form.Name}</span>
          {!!row.FormVersions.length && (
            <span className={styles.subText}>Version: {form.Version}</span>
          )}
        </NavLink>
      );
    },
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'Type',
    width: 113,
  },
  {
    width: 140,
    title: 'Folder',
    key: 'folder',
    dataIndex: 'FormFolder',
    render: (form: FolderType, row, index) => (form?.Name ? `/${form?.Name}` : ''),
  },
  {},
  {
    width: 224,
    title: 'Permissions',
    key: 'permissions',
    render: (form, row: FormListType, index) => {
      return (
        <>
          {row.FormCategoryPermission?.length
            ? row.FormCategoryPermission?.map((formCategory) => formCategory?.Title).join(', ')
            : null}

          {row.FormUserPermission?.length ? (
            <span>
              <Tooltip
                placement="bottom"
                title={row.FormUserPermission?.map((el) => `${el?.Name}`).join(', ')}
              >
                {row.FormUserPermission?.length}{' '}
                {row.FormUserPermission?.length > 1 ? 'Agents' : 'Agent'}
              </Tooltip>
            </span>
          ) : null}
          {row.FormLocationPermission?.states?.length ? (
            <span
              className={classNames({
                [styles.subText]:
                  row.FormCategoryPermission?.length || row.FormUserPermission?.length,
              })}
            >
              {row.FormLocationPermission.states}
            </span>
          ) : null}
        </>
      );
    },
  },
  {
    width: 253,
    title: 'Created',
    key: 'created',
    dataIndex: 'CurrentVersion',
    render: (form: FormVersionType, row, index) => (
      <>
        {moment(form.CreatedDate).format('MM/DD/YYYY')}
        <span
          className={styles.subText}
        >{`${form?.FormVersionCreatedBy?.FirstName} ${form?.FormVersionCreatedBy?.LastName}`}</span>
      </>
    ),
  },
  {
    width: 253,
    title: 'Modified',
    key: 'modified',
    dataIndex: 'CurrentVersion',
    render: (form: FormVersionType, row, index) => (
      <>
        {moment(form.UpdatedDate).format('MM/DD/YYYY')}
        <span
          className={styles.subText}
        >{`${form?.FormVersionUpdatedBy?.FirstName} ${form?.FormVersionUpdatedBy?.LastName}`}</span>
      </>
    ),
  },
  {
    title: '',
    key: 'icons',
    render: (data, row: FormListType, index) => {
      const dispatch = useDispatch();
      return (
        <FavoritesIcon
          key={`favorite-${index}`}
          className={styles.formFavoriteIcon}
          favorite={row.isFavorite}
          onClick={async () => {
            dispatch(
              toggleFavoriteFormEffect({ isFavorite: !row.isFavorite, formId: row.Id }, () => {
                dispatch(selectReloadFormListEffect());
              }),
            );
          }}
        />
      );
    },
  },
];

type NestedFormVersionType = FormVersionType & {
  Type: typeof FORM_TYPE[keyof typeof FORM_TYPE];
};

export const formListNestedColumns: Array<Record<string, unknown>> = [
  {
    width: 430,
    title: 'Form Name',
    key: 'formName',
    dataIndex: 'Name',
    render: (name: string, row: NestedFormVersionType, index) => {
      return (
        <NavLink
          to={
            row.Type === FORM_TYPE.Quote
              ? link.toDashboardServiceDirectoryFormsQuoteForm(row.Id)
              : link.toDashboardServiceDirectoryFormsSmartForm(row.Id)
          }
        >
          <span className={classNames(styles.textBold, styles.formName)}>{name}</span>
          <span className={styles.subText}>Version: {row.Version}</span>
        </NavLink>
      );
    },
  },
  {},
  {
    width: 253,
    title: 'Created',
    key: 'created',
    dataIndex: 'CreatedDate',
    render: (createdDate: string, row: FormVersionType, index) => (
      <>
        {moment(createdDate).format('MM/DD/YYYY')}
        <span
          className={styles.subText}
        >{`${row.FormVersionCreatedBy.FirstName} ${row.FormVersionCreatedBy.LastName}`}</span>
      </>
    ),
  },
  {
    title: 'Modified',
    key: 'modified',
    dataIndex: 'UpdatedDate',
    render: (updatedDate: string, row: FormVersionType, index) => (
      <>
        {moment(updatedDate).format('MM/DD/YYYY')}
        <span
          className={styles.subText}
        >{`${row.FormVersionUpdatedBy.FirstName} ${row.FormVersionUpdatedBy.LastName}`}</span>
      </>
    ),
  },
];
