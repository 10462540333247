import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from 'settings/navigation/routes';
import { CommentsDrawer } from 'components';
import { ShareDrawer } from 'pages/Properties/Feed/Drawers';
import LocalHeader from './LocalHeader';
import Properties from './Properties';
import Map from './Map';
import { FilterDrawer, SearchesDrawer } from './Drawers';
import { ViewSwitcher } from './ViewSwitcher/ViewSwitcher';
import { ContentWrapper } from './components';
import { LocationService } from 'services';
import {
  getSavedSearchesEffect,
  getSearchInstancesEffect,
  openSearchResultsFilterDrawerEffect,
  resetSearchCriteriaEffect,
  setSearchCriteriaEffect,
} from 'store/effects';
import { setEditedSearchCriteriaAction } from 'store/actions/mySearches';
import { EDIT, CREATE } from 'settings/constants/mode';
import {
  getSavedSearchesFormattedSelector,
  getSearchInstancesFormattedSelector,
} from 'store/selectors/mySearches';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getUserRolesMapSelector } from 'store/selectors/user';

import { getClientsSearchInstancesSelector } from 'store/selectors/clientSearches';
import { getClientsSearchInstancesEffect } from 'store/effects/clientSearches';
import { getSearchResultsFilterDrawerSelector } from 'store/selectors/searchResults';

import './SearchResults.scss';
import styles from './styles.module.scss';
import { MapDrawControlsSearchWrapper } from './components/MapDrawControlsSearchWrapper';
import { resetSearchResultsMapViewAction } from '../../../store/actions/searchResults';
import { resetPropertyToScrollInList } from '../../../store/actions/feed';
import { useHistory } from 'react-router-dom';
import { getSearchResultsV2Effect } from 'store/effects/searchResults/searchV2';
import { useNoMlsAccessHandler } from 'pages/Properties/Feed/Properties/hooks/useNoMlsAccessHandler';

const locationSrv = new LocationService();

const SearchResults = (props) => {
  // Info: This is for new listing email button "edit Search Criteria"
  const history = useHistory();
  locationSrv.setLocation(location);
  const { searchInstanceId, search, searchId } = locationSrv.getQuery();
  const { isClient, isAgent } = useSelector(getUserRolesMapSelector);
  if (!searchId && !searchInstanceId && !search) history.push(routes.search);

  const { className } = props;
  const dispatch = useDispatch();
  const { data: agentClientData } = useSelector(getClientsSearchInstancesSelector);
  const { data: clientOwnData } = useSelector(getSearchInstancesFormattedSelector);
  const { formattedData, isLoading: isSavedSearchLoading } = useSelector(
    getSavedSearchesFormattedSelector,
  );
  const searchContext = useSelector(getCurrentContextSelector);
  const { isClientSearch } = useSelector(getSearchResultsFilterDrawerSelector);
  const { wrappedEffect: getSearchResultsEffectWrapped } =
    useNoMlsAccessHandler(getSearchResultsV2Effect);
  const [isSearchQuerySet, setIsSearchQuerySet] = useState(false);

  const cfg = { contextKey: searchContext?.ContextKey };
  const prevSearchContextRef = useRef();

  useEffect(() => {
    if (isAgent) {
      dispatch(getSavedSearchesEffect());
      dispatch(getClientsSearchInstancesEffect());
    }
  }, [isAgent]);

  useEffect(() => {
    if (isClient && searchContext) {
      dispatch(getSearchInstancesEffect(cfg));
    }
  }, [searchContext]);

  useEffect(() => {
    if (
      searchInstanceId &&
      isAgent &&
      agentClientData?.length &&
      searchContext !== prevSearchContextRef.current
    ) {
      prevSearchContextRef.current = searchContext;
      setSearchCriteria(
        agentClientData.filter(
          (item) => item?.SearchQueries?.filter((item) => item.id === searchInstanceId).length > 0,
        )?.[0]?.SearchQueries?.[0],
      );
    }
  }, [agentClientData]);

  useEffect(() => {
    if (
      searchInstanceId &&
      isClient &&
      clientOwnData?.length &&
      searchContext !== prevSearchContextRef.current
    ) {
      prevSearchContextRef.current = searchContext;
      setSearchCriteria(clientOwnData.find((searchQuery) => searchQuery.id === searchInstanceId));
    }
  }, [clientOwnData]);

  useEffect(() => {
    if (
      searchId &&
      isAgent &&
      !isSavedSearchLoading &&
      formattedData?.length &&
      searchContext !== prevSearchContextRef.current
    ) {
      prevSearchContextRef.current = searchContext;
      const search = formattedData.find((searchQuery) => searchQuery.id === searchId);
      dispatch(resetSearchCriteriaEffect());
      dispatch(setEditedSearchCriteriaAction(search));
      dispatch(openSearchResultsFilterDrawerEffect({ open: true, mode: EDIT, isClientSearch }));
      dispatch(getSearchResultsEffectWrapped());
    }
  }, [isSavedSearchLoading, formattedData]);

  useEffect(() => {
    if (search) {
      prevSearchContextRef.current = searchContext;
      setSearch(JSON.parse(search));
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetSearchResultsMapViewAction());
      dispatch(resetPropertyToScrollInList());
    };
  }, []);

  const setSearchCriteria = (search) => {
    if (search) {
      dispatch(resetSearchCriteriaEffect());
      dispatch(setEditedSearchCriteriaAction(search));
      dispatch(
        openSearchResultsFilterDrawerEffect({
          mode: EDIT,
          isClientSearch,
        }),
      );
      dispatch(getSearchResultsEffectWrapped());
      setIsSearchQuerySet(true);
    }
  };

  const setSearch = (search) => {
    dispatch(resetSearchCriteriaEffect());
    dispatch(openSearchResultsFilterDrawerEffect({ open: false, mode: CREATE, isClientSearch }));
    dispatch(setSearchCriteriaEffect(search));
    dispatch(getSearchResultsEffectWrapped());
    setIsSearchQuerySet(true);
  };

  return (
    <div testid="search_results" className={classNames(styles.searchList, className)}>
      <LocalHeader shouldFilterStateLevelResults />
      <ContentWrapper>
        <Properties id="searchProperties" isSearchQuerySet={isSearchQuerySet} />
        <Map id="searchMap" />
      </ContentWrapper>
      <CommentsDrawer />
      <ShareDrawer />
      <SearchesDrawer />
      <FilterDrawer />
      <div className="mapSwitchers">
        <MapDrawControlsSearchWrapper />
        <ViewSwitcher />
      </div>
    </div>
  );
};

SearchResults.propTypes = {
  className: PropTypes.string,
};

SearchResults.defaultProps = {
  className: '',
};

export default SearchResults;
