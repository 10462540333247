import React, { useState, useCallback, HTMLAttributes } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { DocImage, Messages, Share } from 'components/Icons';
import styles from './styles.module.scss';
import { openShareDrawerEffect, setRecipientsEffect, setSharePropertyEffect } from 'store/effects';
import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { Icons } from 'pages/Properties/common/Icons';

interface UserNavigationProps extends HTMLAttributes<HTMLDivElement> {
  propertyInfo: any;
  setShowFormLibraryModal: any;
  setOpenMenu: any;
  recommendedTo: any;
}

export const Menu = ({
  propertyInfo,
  setShowFormLibraryModal,
  setOpenMenu,
  recommendedTo,
}: UserNavigationProps) => {
  const dispatch = useDispatch();

  // const { isAgent } = useSelector(getUserRolesMapSelector);

  const onShare = useCallback(() => {
    const recipients = recommendedTo?.map((item) => ({
      Id: item.Id,
      Name: `${item.FirstName} ${item.LastName}`,
    }));
    dispatch(setRecipientsEffect(recipients ?? []));
    dispatch(setSharePropertyEffect(propertyInfo));
    dispatch(openShareDrawerEffect({ open: true }));
    setOpenMenu(false);
  }, [propertyInfo, dispatch]);

  const onComment = () => {
    dispatch(setSharePropertyEffect(propertyInfo));
    dispatch(openCommentsDrawerEffect({ open: true }));
    setOpenMenu(false);
  };

  return (
    <div className={styles.userNavigationMenu}>
      <ul>
        <li onClick={onComment}>
          <div className={styles.column}>
            <Messages className={styles.icon} color="#515151" variant="hollow" size="24px" />
            <p>Comment</p>
          </div>
        </li>
        <li
          onClick={() => {
            setShowFormLibraryModal(true);
            setOpenMenu(false);
          }}
        >
          <div className={styles.column}>
            <DocImage />
            <p>Initiate Offer </p>
          </div>
        </li>
        <li onClick={onShare}>
          <div className={styles.column}>
            <Icons
              variant={Icons.STAR_2}
              className={classNames({
                [styles.recommended]: recommendedTo?.length,
              })}
            />
            <p>Highlight</p>
          </div>
        </li>
      </ul>
    </div>
  );
};
