import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'components';
import { Question } from 'pages/RequestQuote/components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { Select } from 'components';
import { TransactionLeadSource, TransactionBuySideCommissionResponsibility } from 'app-constants';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { Continue } from '../../Continue';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import styles from './styles.module.scss';
import { AnswerCard } from '../../../AnswerCard';
import { useEffect, useMemo, useState } from 'react';
import { Checkbox, Col, Row } from 'components-antd';
import { CommissionField, OtherFees } from './components';
import { useHistory } from 'react-router-dom';
import { NameAutocomplete } from 'pages/Workshop/Transactions/TransactionFinancials/components';
import { getLeadSourceNames } from 'api/financials';

interface FinancingTypeProps {
  className?: string;
  onNext?: (event) => void;
  currentQuestionId?: typeof transactionPreFormQuestionsIds[keyof typeof transactionPreFormQuestionsIds];
  setStage?: (event) => void;
}

const leadSourceOptions = Object.values(TransactionLeadSource).map((type) => ({
  value: type,
  name: type,
}));

const buySideCommissionResponsibilityOptions = Object.values(
  TransactionBuySideCommissionResponsibility,
).map((type) => ({
  value: type,
  name: type,
}));

interface OtherFee {
  Value: number | '';
  IsFeeTypePercentage: boolean;
  Description: string;
}

export const initialState: OtherFee = {
  Description: '',
  IsFeeTypePercentage: false,
  Value: '',
};

export const FinancingDetails = ({
  className,
  currentQuestionId,
  onNext = () => {},
  setStage,
}: FinancingTypeProps) => {
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const history = useHistory();
  const isTransactionUnderContractRoute = useMemo(
    () => history.location?.pathname.includes('undercontract'),
    [],
  );
  const [inputField, setInputField] = useState<any>(
    preForm?.[transactionPreFormQuestionsIds.leadSource] ?? '',
  );

  const [autocompleteLeadSourceNames, setAutocompleteLeadSourceNames] = useState<any>(null);
  const fetchAutocompleteLeadSourceNames = async () => {
    let autocompleteLeadSourceNames = await getLeadSourceNames();
    setAutocompleteLeadSourceNames(autocompleteLeadSourceNames?.LeadSourceTitles);
  };

  useEffect(() => {
    fetchAutocompleteLeadSourceNames();
  }, []);
  const [otherFeeData, setOtherFeeData] = useState<OtherFee[]>(
    preForm?.[transactionPreFormQuestionsIds.otherFees] ?? [{ ...initialState }],
  );
  const [otherFee, setOtherFee] = useState<boolean>(false);
  const [referralFee, setReferralFee] = useState<boolean>(false);

  useEffect(() => {
    setReferralFee(preForm?.[transactionPreFormQuestionsIds.referralFee]);
    setOtherFee(!!preForm?.[transactionPreFormQuestionsIds.otherFees]?.length);
  }, []);

  const valid =
    (otherFee
      ? otherFeeData?.every((obj) => Object.values(obj).every((value) => value || value !== ''))
      : true) && (referralFee ? preForm?.[transactionPreFormQuestionsIds.referralFee] : true);

  const onAnswerHandler = (answer, key: string) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds[key]]: answer,
      }),
    );
  };

  const onNextHandler = (event) => {
    if (otherFee) {
      dispatch(
        setTransactionPreFormQuestionsEffect({
          [transactionPreFormQuestionsIds.otherFees]: otherFeeData,
        }),
      );
    }

    if (setStage) {
      setStage(transactionPreFormQuestionsIds.purchaseAgreement);
    }

    if (currentQuestionId === transactionPreFormQuestionsIds.financingDetails) {
      onNext(event);
    }
  };

  const setOtherValues = (val: any, index: number, key: string) => {
    setOtherFeeData((prev) => {
      let updated = [...prev];
      updated[index][key] = val;
      return updated;
    });
  };

  const showBuyerSellerContributionFields = useMemo(
    () =>
      preForm?.[transactionPreFormQuestionsIds.buySideCommissionResponsibility] ===
      TransactionBuySideCommissionResponsibility.Both,
    [preForm?.[transactionPreFormQuestionsIds.buySideCommissionResponsibility]],
  );

  const buyerSellerContributionFields = () => (
    <>
      <div className={styles.seperator} />
      <Select
        defaultValue={TransactionBuySideCommissionResponsibility.Buyer}
        value={preForm?.[transactionPreFormQuestionsIds.buySideCommissionResponsibility]}
        onSelect={(_, { value }) => {
          if (value !== TransactionBuySideCommissionResponsibility.Both) {
            onAnswerHandler(null, 'buyerCommissionContribution');
            onAnswerHandler(null, 'sellerCommissionContribution');
          }

          onAnswerHandler(value, 'buySideCommissionResponsibility');
        }}
        options={buySideCommissionResponsibilityOptions}
        className={{
          wrapper: classNames(styles.financingType, styles.marginTop),
        }}
        placeholder={' '}
        isArrowIcon={true}
        arrowIconClassName={styles.arrowdownicon}
        variant={Select.LIGHT_ROUND}
        label={'Who is responsible for the buy-side commission?'}
      />
      {showBuyerSellerContributionFields && (
        <Row gutter={12} className={styles.marginTop}>
          <Col span={12}>
            <CommissionField
              label="Buyer Contribution"
              value={preForm?.[transactionPreFormQuestionsIds.buyerCommissionContribution]?.Value}
              IsFeeTypePercentage={
                preForm?.[transactionPreFormQuestionsIds.buyerCommissionContribution]
                  ?.IsFeeTypePercentage ?? true
              }
              update={(object) => {
                const updatedObject = {
                  ...object,
                  Value: object?.Value === '' ? null : parseFloat(object?.Value),
                };
                onAnswerHandler(updatedObject, 'buyerCommissionContribution');
              }}
            />
          </Col>
          <Col span={12}>
            <CommissionField
              label="Seller Contribution"
              value={preForm?.[transactionPreFormQuestionsIds.sellerCommissionContribution]?.Value}
              IsFeeTypePercentage={
                preForm?.[transactionPreFormQuestionsIds.sellerCommissionContribution]
                  ?.IsFeeTypePercentage ?? true
              }
              update={(object) => {
                const updatedObject = {
                  ...object,
                  Value: object?.Value === '' ? null : parseFloat(object?.Value),
                };
                onAnswerHandler(updatedObject, 'sellerCommissionContribution');
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div testid="what_financing_type" className={classNames(styles.container, className)}>
      <Question>{"Let's confirm a few financial details."}</Question>
      <AnswersContainer>
        <div className={styles.answerContainer}>
          {preForm?.[transactionPreFormQuestionsIds?.whoAreYouRepresenting]?.Seller ||
          isTransactionUnderContractRoute ? (
            <>
              <AnswerCard>
                <CommissionField
                  label="What is the list-side commission"
                  value={preForm?.[transactionPreFormQuestionsIds.sellerCommission]?.Value}
                  IsFeeTypePercentage={
                    preForm?.[transactionPreFormQuestionsIds.sellerCommission]
                      ?.IsFeeTypePercentage ?? true
                  }
                  update={(object) => {
                    const updatedObject = {
                      ...object,
                      Value: object?.Value === '' ? null : parseFloat(object?.Value),
                    };
                    onAnswerHandler(updatedObject, 'sellerCommission');
                  }}
                />
              </AnswerCard>
              <AnswerCard>
                <CommissionField
                  label="What is the buy-side commission?"
                  value={preForm?.[transactionPreFormQuestionsIds.buyerCommission]?.Value}
                  IsFeeTypePercentage={
                    preForm?.[transactionPreFormQuestionsIds.buyerCommission]
                      ?.IsFeeTypePercentage ?? true
                  }
                  update={(object) => {
                    const updatedObject = {
                      ...object,
                      Value: object?.Value === '' ? null : parseFloat(object?.Value),
                    };
                    onAnswerHandler(updatedObject, 'buyerCommission');
                  }}
                />
                {(isTransactionUnderContractRoute ||
                  preForm?.[transactionPreFormQuestionsIds.isPropertyUnderContract]) &&
                  preForm?.[transactionPreFormQuestionsIds.buyerCommission]?.Value > 0 &&
                  buyerSellerContributionFields()}
              </AnswerCard>
            </>
          ) : (
            <AnswerCard>
              <CommissionField
                label="What is the commission rate?"
                value={preForm?.[transactionPreFormQuestionsIds.totalCommission]?.Value}
                IsFeeTypePercentage={
                  preForm?.[transactionPreFormQuestionsIds.totalCommission]?.IsFeeTypePercentage ??
                  true
                }
                update={(object) => {
                  const updatedObject = {
                    ...object,
                    Value: object?.Value === '' ? null : parseFloat(object?.Value),
                  };
                  onAnswerHandler(updatedObject, 'totalCommission');
                }}
              />
              {preForm?.[transactionPreFormQuestionsIds.totalCommission]?.Value > 0 &&
                buyerSellerContributionFields()}
            </AnswerCard>
          )}
          <AnswerCard>
            <NameAutocomplete
              label={'What is the lead source?'}
              allNames={autocompleteLeadSourceNames?.map((item, idx) => ({
                label: item,
                value: idx,
              }))}
              value={inputField}
              onChange={(value) => {
                setInputField(value), onAnswerHandler(value, 'leadSource');
              }}
              onSelect={(value) => {
                setInputField(value), onAnswerHandler(value, 'leadSource');
              }}
            />
          </AnswerCard>
          <AnswerCard>
            <p className={styles.label}>Is there a referral fee?</p>
            <div className={styles.checkboxContainer}>
              <div className={styles.checkbox} onClick={() => setReferralFee(true)}>
                <Checkbox className="mosaikCheckboxRounded" checked={referralFee} />
                <p className={styles.checkboxLabel}>Yes</p>
              </div>
              <div
                className={styles.checkbox}
                onClick={() => {
                  setReferralFee(false);
                  onAnswerHandler(undefined, 'referralFee');
                }}
              >
                <Checkbox className="mosaikCheckboxRounded" checked={!referralFee} />
                <p className={styles.checkboxLabel}>No</p>
              </div>
            </div>
            {referralFee && (
              <Input
                variant={Input.LIGHT_ROUND}
                placeholder="0%"
                isNumberFormat
                suffix="%"
                onChange={(e, val) => onAnswerHandler(val ? Number(val) : '', 'referralFee')}
                value={preForm?.[transactionPreFormQuestionsIds.referralFee] ?? ''}
                label={'What is the fee?'}
                minNumber={0}
                maxNumber={100}
                className={styles.marginTop}
              />
            )}
          </AnswerCard>
          <AnswerCard>
            <p className={styles.label}>Are there any other fees?</p>
            <div className={styles.checkboxContainer}>
              <div
                className={styles.checkbox}
                onClick={() => {
                  setOtherFee(true);
                }}
              >
                <Checkbox className="mosaikCheckboxRounded" checked={otherFee} />
                <p className={styles.checkboxLabel}>Yes</p>
              </div>
              <div
                className={styles.checkbox}
                onClick={() => {
                  setOtherFee(false);
                  onAnswerHandler(undefined, 'otherFee');
                }}
              >
                <Checkbox className="mosaikCheckboxRounded" checked={!otherFee} />
                <p className={styles.checkboxLabel}>No</p>
              </div>
            </div>
            {otherFee && (
              <OtherFees
                otherFeeData={otherFeeData}
                setOtherFeeData={setOtherFeeData}
                setOtherValues={setOtherValues}
              />
            )}
          </AnswerCard>
        </div>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onNextHandler} disabled={!valid} />
      </ButtonsContainer>
    </div>
  );
};
