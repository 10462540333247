import React, { memo, useMemo } from 'react';

const Edit = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div {...props}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.66626 13.3334H5.33293L12.3329 6.33339C12.6865 5.97977 12.8852 5.50016 12.8852 5.00006C12.8852 4.49996 12.6865 4.02035 12.3329 3.66673C11.9793 3.3131 11.4997 3.11444 10.9996 3.11444C10.4995 3.11444 10.0199 3.3131 9.66626 3.66673L2.66626 10.6667V13.3334Z"
            stroke="#E66F3E"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 4.33375L11.6667 7.00041"
            stroke="#E66F3E"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [props.className],
  );

export default memo(Edit);
