import React, { useCallback, useEffect, useState } from 'react';

import { showErrorMessage } from 'helpers/errors';
import { get } from 'lodash-es';
import { Footer, Header } from 'pages/OnboardingV2Agent/components';
import commonStyles from 'pages/OnboardingV2Agent/styles.module.scss';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { link } from 'settings/navigation/link';
import { getAgentTeamDetailEffect, signUpEffect } from 'store/effects';
import { registerSalesLeadEffect } from 'store/effects/salesLead';
import { requestConvertToTeamPlanEffect, updateNewAgentEffect } from 'store/effects/subscription';
import { Location } from 'types';
import {
  ChoosePlan,
  CreatePassword,
  Message,
  PersonalDetails,
  RestrictedMessage,
  SearchStates,
} from './components';
import { BackIcon } from './components/icons';
import { getEmailVerificationInfo } from 'api/user';

enum CommonStepsEnum {
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  CHOOSE_PLAN = 'CHOOSE_PLAN',
  CREATE_PASSWORD = 'CREATE_PASSWORD',
  MESSAGE = 'MESSAGE',
  SEARCH_STATES = 'SEARCH_STATES',
  RESTRICTED_MESSAGE = 'RESTRICTED_MESSAGE',
}

const steps = [
  CommonStepsEnum.CHOOSE_PLAN, //0
  CommonStepsEnum.PERSONAL_DETAILS, //1
  CommonStepsEnum.SEARCH_STATES, //2
  CommonStepsEnum.CREATE_PASSWORD, //3
  CommonStepsEnum.MESSAGE, //4
  CommonStepsEnum.RESTRICTED_MESSAGE, //5
];

export enum FormFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PLAN = 'selectedPlan',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  AREAS_OF_OPERATIONS = 'areasOfOperation',
}

export type Values = {
  [key in FormFields]: key extends FormFields.AREAS_OF_OPERATIONS ? Location[] : string;
};

export const SignupV2: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [state, setState] = useState<Values>({
    [FormFields.EMAIL]: '',
    [FormFields.PASSWORD]: '',
    [FormFields.CONFIRM_PASSWORD]: '',
    [FormFields.FIRST_NAME]: '',
    [FormFields.LAST_NAME]: '',
    [FormFields.PLAN]: '',
    [FormFields.AREAS_OF_OPERATIONS]: [] as Location[],
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const registerSalesLead = (values, leadSource: string) => {
    dispatch(
      registerSalesLeadEffect(
        {
          Email: values?.[FormFields.EMAIL],
          FirstName: values?.[FormFields.FIRST_NAME],
          LastName: values?.[FormFields.LAST_NAME],
          ...(leadSource === 'Restricted' && {
            LeadInformation: {
              AreasOfOperation: values?.[FormFields.AREAS_OF_OPERATIONS],
            },
          }),
          LeadSource: leadSource,
        },
        (err) => {
          if (err) {
            return showErrorMessage(err);
          }
          if (leadSource === 'Enterprise Plan') {
            setCurrentStep(4);
          }
          if (leadSource === 'Restricted') {
            setCurrentStep(5);
          }
        },
      ),
    );
  };

  const onNext = useCallback(
    (value: Partial<typeof state>) => {
      const mergedValues = { ...state, ...value };
      setState((prev) => ({ ...prev, ...mergedValues }));
      if (currentStep === 0 && mergedValues?.[FormFields.PLAN] === 'Enterprise') {
        setCurrentStep(1);
      } else if (currentStep === 1 && mergedValues?.[FormFields.PLAN] === 'Enterprise') {
        registerSalesLead(mergedValues, 'Enterprise Plan');
      } else if (currentStep === 0 && mergedValues?.[FormFields.PLAN] !== 'Enterprise') {
        setCurrentStep(2);
      } else if (currentStep === 2 && mergedValues?.[FormFields.PLAN] !== 'Enterprise') {
        setCurrentStep(1);
      } else if (currentStep === 1 && mergedValues?.[FormFields.PLAN] !== 'Enterprise') {
        const isRestrictedState = mergedValues?.[FormFields.AREAS_OF_OPERATIONS]?.some((area) => {
          //find if any AreasOfOperation contain the states Massachusetts, New Hampshire or Washington
          return ['MA', 'NH', 'WA'].includes(area?.State || '');
        });
        isRestrictedState ? registerSalesLead(mergedValues, 'Restricted') : setCurrentStep(3);
      } else if (currentStep === 3 && mergedValues?.[FormFields.PLAN] !== 'Enterprise') {
        setCurrentStep(4);
      } else return;
    },
    [currentStep, state, registerSalesLead],
  );

  const onBack = useCallback(() => {
    if (currentStep === 3) {
      setCurrentStep(1);
    } else if (currentStep === 1 && state?.[FormFields.PLAN] === 'Enterprise') {
      setCurrentStep(0);
    } else if (currentStep === 1 && state?.[FormFields.PLAN] !== 'Enterprise') {
      setCurrentStep(2);
    } else setCurrentStep(0);
  }, [currentStep]);

  const onSubmit = (lastStepValues?: Partial<Values>) => {
    setIsSubmitting(true);
    const { areasOfOperation, ...rest } = state;
    dispatch(
      signUpEffect({ ...rest, ...(lastStepValues || {}), role: 'agent' }, {}, (err, response) => {
        if (!err) {
          updateAgent(get(response, 'data.Id'));
          getEmailVerificationInfo();
        } else {
          setIsSubmitting(false);
        }
      }),
    );
  };

  const updateAgent = (id: number) => {
    dispatch(
      updateNewAgentEffect(
        {
          Id: id,
          WizardFinished: false,
          AreasOfOperation: state?.[FormFields.AREAS_OF_OPERATIONS],
        },
        (err) => {
          if (!err) convertToTeamPlan();
          else {
            showErrorMessage(err);
            setIsSubmitting(false);
          }
        },
      ),
    );
  };

  const convertToTeamPlan = () => {
    dispatch(
      requestConvertToTeamPlanEffect({}, (err) => {
        if (!err) {
          dispatch(getAgentTeamDetailEffect());
          history.push(link.toOnBoardingV2Agent());
        } else {
          showErrorMessage(err);
        }
        setIsSubmitting(false);
      }),
    );
  };

  const getStep = (currentStep) => {
    switch (steps[currentStep]) {
      case CommonStepsEnum.PERSONAL_DETAILS:
        return <PersonalDetails onNext={onNext} values={state} />;
      case CommonStepsEnum.CHOOSE_PLAN:
        return <ChoosePlan onNext={onNext} values={state} />;
      case CommonStepsEnum.CREATE_PASSWORD:
        return <CreatePassword onSubmit={onSubmit} isSubmitting={isSubmitting} />;
      case CommonStepsEnum.MESSAGE:
        return <Message />;
      case CommonStepsEnum.SEARCH_STATES:
        return <SearchStates onNext={onNext} values={state} />;
      case CommonStepsEnum.RESTRICTED_MESSAGE:
        return <RestrictedMessage />;
      default:
        return null;
    }
  };
  return (
    <div className={commonStyles.wrapper}>
      <Header />
      <div className={commonStyles.contentContainer}>
        {![0, 4, 5].includes(currentStep) ? (
          <div className={commonStyles.backBtn} onClick={onBack}>
            <BackIcon />
          </div>
        ) : null}
        {getStep(currentStep)}
      </div>
    </div>
  );
};
