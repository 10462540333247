const CounterRemove = (props) => (
  <div {...props}>
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="transparent" />
      <path
        d="M18 28L38 28"
        stroke="#262626"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default CounterRemove;
