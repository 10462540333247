import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ArrowLeft } from 'components/Icons';

import styles from './styles.module.scss';

const GoBack = ({ className, to, text }) => (
  <Link testid="go_back" className={classNames(styles.backLink, className)} to={to}>
    <ArrowLeft className={styles.arrow} />
    <span>{text}</span>
  </Link>
);

GoBack.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

GoBack.defaultProps = {
  className: '',
};

export default GoBack;
