import { createAction } from 'redux-actions';

export const requestGetFormListAction = createAction('REQUEST/GET_FORM_LIST');
export const requestToggleFavoriteFormAction = createAction('REQUEST/TOGGLE_FAVORITE_FORM');
export const selectReloadFormListAction = createAction('FORM/RELOAD_FORM_LIST');
export const requestUpdateFormVersionStatusAction = createAction(
  'REQUEST/UPDATE_FORM_VERSION_STATUS',
);
export const updateFormListSearchAction = createAction('REQUEST/UPDATE_FORM_LIST_SEARCH');
export const requestFormMetaAction = createAction('REQUEST/FORM_LIST_META');
export const requestUserFormMetaAction = createAction('REQUEST/USER_FORM_LIST_META');
export const deleteFormVersionModalAction = createAction('REQUEST/DELETE_FORM_MODAL_VERSION');
export const deleteFormVersionAction = createAction('REQUEST/DELETE_FORM_VERSION');
export const createNewFormAction = createAction('REQUEST/CREATE_NEW_FORM');
export const createNewTemplateAction = createAction('REQUEST/CREATE_NEW_TEMPLATE');
export const updateFormTemplateAction = createAction('REQUEST/UPDATE_FORM_TEMPLATE');
export const updateBundleTemplateAction = createAction('REQUEST/UPDATE_BUNDLE_TEMPLATE');
export const smartFormUploadAction = createAction('REQUEST/UPLOAD_SMART_FORM');

export const requestAddTagAction = createAction('REQUEST/ADD_TAG');
