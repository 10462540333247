import { useEffect, useState } from 'react';
import { Checkbox, Input } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import classNames from 'classnames';

import styles from './styles.module.scss';

export const InputCheckbox = (props) => {
  const {
    className,
    wrapClassName,
    value,
    label,
    inputValue,
    inputText,
    checked,
    inputPlaceholder,
    onChangeCheckbox,
    onChangeInput,
  } = props;
  const [isEnable, setIsEnable] = useState(false);

  useEffect(() => {
    setIsEnable(checked);
  }, [checked]);

  const onChange = (e: CheckboxChangeEvent) => {
    onChangeCheckbox(e);
    setIsEnable(true);
  };

  return (
    <div className={classNames(styles.checkboxWrap, wrapClassName)}>
      <Checkbox
        className={classNames(styles.customCheckbox, className)}
        value={value}
        checked={checked}
        onChange={onChange}
      >
        {label}
      </Checkbox>
      <div className={classNames(styles.inputWrap, { [styles.disabled]: !isEnable })}>
        <Input
          value={inputValue}
          placeholder={inputPlaceholder || '2'}
          disabled={!isEnable}
          onChange={onChangeInput}
        />
        <p>{inputText}</p>
      </div>
    </div>
  );
};
