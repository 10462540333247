import { Modal } from 'components-antd';
import { Icons } from './FormStages/components/Icons';

import styles from './style.module.scss';
import { Stages } from 'components';
import {
  FormCategories,
  MyLibrary,
  NewTemplate,
  RecentUploads,
  UploadFileInForm,
} from './FormStages';
import { useState } from 'react';
import { getStagesStep } from './helper';

interface NewFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  dynamicForm?: boolean;
}

export const NewFormModal = ({ onClose, isOpen, dynamicForm }: NewFormModalProps) => {
  const [step, setStep] = useState(0);

  const onUpdate = (iterator) => {
    setStep(iterator);
  };

  const onFinish = () => {};

  const stagesStep = getStagesStep(dynamicForm);

  const onModalClose = () => {
    onClose();
    setTimeout(() => {
      setStep(0);
    }, 300);
  };

  return (
    <Modal
      open={isOpen}
      width={step === stagesStep.Library || step === stagesStep.RecentUploads ? '65%' : 675}
      footer={null}
      onCancel={onModalClose}
      maskClosable={false}
      destroyOnClose
      className={styles.newFormModal}
      closeIcon={<Icons variant={'close'} />}
    >
      <Stages
        stages={[
          {
            Component: (props) => <FormCategories {...props} isDynamicForm={dynamicForm} />,
          },
          {
            Component: (props) => <UploadFileInForm {...props} isDynamicForm={dynamicForm} />,
          },
          {
            Component: MyLibrary,
          },
          {
            Component: RecentUploads,
          },
          {
            Component: NewTemplate,
          },
        ]}
        stagesStep={stagesStep}
        classNameProgress={styles.progressBar}
        classNameWrapper={styles.stagesWrapper}
        fullAccess={true}
        onUpdate={onUpdate}
        onFinish={onFinish}
        onClose={onModalClose}
        startStageIndex={0}
      />
    </Modal>
  );
};
