import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get } from 'lodash-es';
import { requestPostListingFiltersAction } from 'store/actions/ai';

const initialData = {
  state: IDLE,
  data: {},
};

export default handleActions(
  {
    [requestPostListingFiltersAction as any]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data'),
    }),
  },
  initialData,
);
