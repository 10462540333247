import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Continue } from '../../Continue';
import { DatePicker } from 'components';
import { Question } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';

const EffectiveDate = (props) => {
  const {
    className,
    onNext,
    currentQuestionId,
    setStage,
    heading = 'What is the effective date of the \npurchase agreement?',
  } = props;
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);

  const onAnswerHandler = (answer) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.effectiveDate]: answer || undefined,
      }),
    );
  };

  const onNextHandler = (event) => {
    if (setStage) {
      setStage(transactionPreFormQuestionsIds.closingDate);
    }
    if (currentQuestionId === transactionPreFormQuestionsIds.effectiveDate) {
      onNext(event);
    }
  };

  return (
    <div testid="what_effective_date" className={classNames(styles.container, className)}>
      <Question>{heading}</Question>
      <AnswersContainer>
        <DatePicker
          variant={DatePicker.ROUNDED}
          placeholder="MM/DD/YY"
          onChange={(e, val) => onAnswerHandler(val?.[0])}
          value={preForm[transactionPreFormQuestionsIds.effectiveDate]}
          className={styles.dateInput}
          options={{ enableTime: false }}
          testid="effective_date"
        />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue
          onClick={onNextHandler}
          disabled={!preForm?.[transactionPreFormQuestionsIds.effectiveDate]}
        />
      </ButtonsContainer>
    </div>
  );
};

EffectiveDate.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  setStage: PropTypes.func,
  currentQuestionId: PropTypes.string,
};

EffectiveDate.defaultProps = {
  className: '',
  onNext: () => {},
  currentQuestionId: null,
};

export default EffectiveDate;
