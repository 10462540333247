import React from 'react';

import { routes } from 'settings/navigation/routes';
import { useBackPage } from 'hooks';

import { PageNotFound, PageWrapper } from 'components';

const NotFound = () => {
  const [onBack] = useBackPage({ defaultBackUrl: routes.index });

  return (
    <PageWrapper>
      <PageNotFound onClick={onBack} btnLabel="Go back" />
    </PageWrapper>
  );
};

export default NotFound;
