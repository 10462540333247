import PropTypes from 'prop-types';
const BaseSummaryNotification = (props) => {
  const { summary } = props;
  const boldTasks = (text) => {
    const regex = /(\d+\s(?:overdue\s)?(?:task|tasks)\b\.?)/gi;
    const parts = text.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? <strong key={index}>{part}</strong> : part,
    );
  };
  return (
    <>
      <span>{boldTasks(summary)}</span>
    </>
  );
};

BaseSummaryNotification.propTypes = {
  summary: PropTypes.string,
};

BaseSummaryNotification.defaultProps = {
  summary: '',
};

export default BaseSummaryNotification;
