import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Label = ({ className, children }) => (
  <p className={classNames(styles.label, className)}>{children}</p>
);

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Label.defaultProps = {
  className: '',
  children: undefined,
};

export default Label;
