import PropTypes from 'prop-types';

export const Icons = ({ testid, className, variant, onClick }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.BACK: {
        return (
          <svg
            width="9"
            height="16"
            viewBox="0 0 9 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 15L1 8L8 1"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DOCUMENT: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.HEART: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11.2878 18.8541L5.09346 12.5748C4.41382 11.8744 4 10.9293 4 9.88889C4 7.75279 5.75673 6 7.95 6C9.29045 6 10.4715 6.65597 11.1858 7.65779L12 8.79982L12.8142 7.65779C13.5285 6.65597 14.7096 6 16.05 6C18.2433 6 20 7.75279 20 9.88889C20 10.9814 19.5436 11.9693 18.802 12.6786L18.7914 12.6887L18.7811 12.6992L12.7118 18.854C12.3202 19.2511 11.6795 19.2511 11.2878 18.8541Z"
              stroke="#515151"
              strokeWidth="2"
            />
          </svg>
        );
      }
      case Icons.COMMENT: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5.1237 16.0581L6.04377 16.4498L6.24163 15.9851L5.98506 15.5501L5.1237 16.0581ZM3.48275 19.912L2.56268 19.5203H2.56268L3.48275 19.912ZM4.12275 20.5744L4.48267 21.5074H4.48267L4.12275 20.5744ZM8.16834 19.0137L8.65043 18.1376L8.24266 17.9132L7.80841 18.0807L8.16834 19.0137ZM19.0195 12C19.0195 15.866 15.8855 19 12.0195 19V21C16.9901 21 21.0195 16.9706 21.0195 12H19.0195ZM12.0195 5C15.8855 5 19.0195 8.13401 19.0195 12H21.0195C21.0195 7.02944 16.9901 3 12.0195 3V5ZM5.01952 12C5.01952 8.13401 8.15352 5 12.0195 5V3C7.04896 3 3.01952 7.02944 3.01952 12H5.01952ZM5.98506 15.5501C5.37169 14.51 5.01952 13.2977 5.01952 12H3.01952C3.01952 13.6647 3.47242 15.2267 4.26234 16.5661L5.98506 15.5501ZM4.40283 20.3038L6.04377 16.4498L4.20363 15.6663L2.56268 19.5203L4.40283 20.3038ZM3.76283 19.6414C4.17514 19.4824 4.57595 19.8972 4.40283 20.3038L2.56268 19.5203C2.04332 20.7401 3.24575 21.9846 4.48267 21.5074L3.76283 19.6414ZM7.80841 18.0807L3.76283 19.6414L4.48267 21.5074L8.52826 19.9467L7.80841 18.0807ZM12.0195 19C10.7962 19 9.64894 18.687 8.65043 18.1376L7.68624 19.8898C8.97264 20.5977 10.4506 21 12.0195 21V19Z"
              fill="#515151"
            />
          </svg>
        );
      }

      case Icons.CALENDAR: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.25 6H6.75C5.7835 6 5 6.7835 5 7.75V18.25C5 19.2165 5.7835 20 6.75 20H17.25C18.2165 20 19 19.2165 19 18.25V7.75C19 6.7835 18.2165 6 17.25 6Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.5 4.5V7.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.5 4.5V7.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 11H19"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.UNREAD: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.18229 16.0581L6.10236 16.4498L6.30022 15.9851L6.04365 15.5501L5.18229 16.0581ZM3.54135 19.912L2.62128 19.5203H2.62128L3.54135 19.912ZM4.18134 20.5744L4.54126 21.5074H4.54126L4.18134 20.5744ZM8.22693 19.0137L8.70902 18.1376L8.30125 17.9132L7.86701 18.0807L8.22693 19.0137ZM19.0781 12C19.0781 15.866 15.9441 19 12.0781 19V21C17.0487 21 21.0781 16.9706 21.0781 12H19.0781ZM12.0781 5C15.9441 5 19.0781 8.13401 19.0781 12H21.0781C21.0781 7.02944 17.0487 3 12.0781 3V5ZM5.07811 12C5.07811 8.13401 8.21212 5 12.0781 5V3C7.10755 3 3.07811 7.02944 3.07811 12H5.07811ZM6.04365 15.5501C5.43029 14.51 5.07811 13.2977 5.07811 12H3.07811C3.07811 13.6647 3.53101 15.2267 4.32093 16.5661L6.04365 15.5501ZM4.46142 20.3038L6.10236 16.4498L4.26222 15.6663L2.62128 19.5203L4.46142 20.3038ZM3.82142 19.6414C4.23373 19.4824 4.63454 19.8972 4.46142 20.3038L2.62128 19.5203C2.10191 20.7401 3.30435 21.9846 4.54126 21.5074L3.82142 19.6414ZM7.86701 18.0807L3.82142 19.6414L4.54126 21.5074L8.58685 19.9467L7.86701 18.0807ZM12.0781 19C10.8548 19 9.70754 18.687 8.70902 18.1376L7.74483 19.8898C9.03123 20.5977 10.5092 21 12.0781 21V19Z"
              fill="#515151"
            />
            <circle cx="20" cy="5" r="4" fill="#FF576D" stroke="white" strokeWidth="2" />
          </svg>
        );
      }

      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className}>
      {renderIcon()}
    </div>
  );
};

Icons.BACK = 'back';
Icons.DOCUMENT = 'document';
Icons.HEART = 'heart';
Icons.COMMENT = 'comment';
Icons.CALENDAR = 'calendar';
Icons.UNREAD = 'unread';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};
