import { useState } from 'react';

import { AntCard, Divider, Row, Col, Select, Input, Button } from 'components-antd';
import {
  RuleType,
  TemplateAction,
  TemplateActionType,
  TemplateTrigger,
  TemplateTriggerType,
  TriggerComparisonType,
  TriggerOperatorAfterType,
} from 'types/rules';
import { TransactionTemplate } from 'types';

import styles from './styles.module.scss';

interface RuleProps {
  isNew?: boolean;
  onAdd?: (Rule: RuleType) => void;
  goBack?: () => void;
  transactionTemplate: TransactionTemplate;
  defaultTriggers?: TemplateTrigger[];
  defaultActions?: TemplateAction[];
}

const triggerOptions = Object.keys(TemplateTriggerType).map((e) => ({
  key: e,
  value: e,
  label: e,
}));

const actionOptions = Object.keys(TemplateActionType).map((e) => ({
  key: e,
  value: e,
  label: e,
}));

const comparisonOptions = Object.keys(TriggerComparisonType).map((e) => ({
  key: e,
  value: TriggerComparisonType[e],
  label: e,
}));

const DEFAULT_TRIGGER = {
  Type: TemplateTriggerType.Days_Contract_Date,
  OperatorAfter: TriggerOperatorAfterType.OR,
  Comparison: TriggerComparisonType.EQUAL,
} as const;

const DEFAULT_ACTION = { Type: TemplateActionType.Form_Remove_Forms } as const;

export const Rule = ({
  isNew,
  onAdd,
  transactionTemplate,
  goBack,
  defaultActions,
  defaultTriggers,
}: RuleProps) => {
  const [triggers, setTriggers] = useState<TemplateTrigger[]>(
    defaultTriggers ?? [{ ...DEFAULT_TRIGGER }],
  );
  const [actions, setActions] = useState<TemplateAction[]>(
    defaultActions ?? [{ ...DEFAULT_ACTION }],
  );

  const entityIdOptions = [
    ...(transactionTemplate?.TaskTemplates?.length
      ? transactionTemplate.TaskTemplates.map((task) => ({
          label: `Task Template: ` + task.Name,
          value: String(task.Id),
        }))
      : []),
    ...(transactionTemplate?.TransactionFormTemplate?.length
      ? transactionTemplate.TransactionFormTemplate.map((form) => ({
          label: `Form: ` + form?.Name,
          value: String(form.Id),
        }))
      : []),

    ...(transactionTemplate?.TimelineTemplate?.Id &&
    transactionTemplate?.TimelineTemplate?.Milestones?.length
      ? transactionTemplate.TimelineTemplate.Milestones.filter(
          (milestone) => milestone.PublicId,
        ).map((milestone) => ({
          label: 'Milestone: ' + milestone.Title,
          value: `${milestone.PublicId}`,
        }))
      : []),
  ];

  const handleActionChange = (index, key, value) => {
    if (isNew) {
      const updatedActions = [...actions];
      if (updatedActions[index]) {
        updatedActions[index][key] = value;
        setActions(updatedActions);
      }
    }
  };

  const handleTriggerChange = (index, key, value) => {
    if (isNew) {
      const updatedTriggers = [...triggers];
      if (updatedTriggers[index]) {
        updatedTriggers[index][key] = value;
        setTriggers(updatedTriggers);
      }
    }
  };

  const handleNewAction = () => {
    const updatedActions: TemplateAction[] = [...actions];
    updatedActions.push({ ...DEFAULT_ACTION });
    setActions(updatedActions);
  };

  const handleNewTrigger = (operator: TriggerOperatorAfterType) => {
    const updatedTriggers: TemplateTrigger[] = [...triggers];
    if (updatedTriggers.length) {
      updatedTriggers[updatedTriggers.length - 1].OperatorAfter = operator;
    }
    updatedTriggers.push({ ...DEFAULT_TRIGGER });
    setTriggers(updatedTriggers);
  };

  const handleTriggerDelete = (index) => {
    const updatedTriggers: TemplateTrigger[] = [...triggers];
    updatedTriggers.splice(index, 1);
    setTriggers(updatedTriggers);
  };

  const handleActionDelete = (index) => {
    const updatedActions: TemplateAction[] = [...actions];
    updatedActions.splice(index, 1);
    setActions(updatedActions);
  };

  const disabled = !isNew;

  return (
    <>
      {onAdd && (
        <Button
          onClick={() => {
            onAdd?.({
              actions: actions.map((action, index) => ({ ...action, Order: index })),
              triggers: triggers.map((trigger, index) => ({ ...trigger, Order: index })),
            });
          }}
          disabled={disabled}
        >
          Save
        </Button>
      )}
      {goBack && <Button onClick={goBack}>Back</Button>}
      <AntCard title="Triggers">
        {triggers.map((trigger, index) => (
          <Row key={`trigger-${trigger.Id}-${index}`}>
            <Col span={12}>
              <Select
                placeholder="Select Type"
                className={styles.w100}
                disabled={disabled}
                options={triggerOptions}
                value={trigger.Type}
                onChange={(e) => handleTriggerChange(index, 'Type', e)}
              />
            </Col>
            <Col span={12}>
              <Select
                placeholder="Select Entity"
                className={styles.w100}
                disabled={disabled}
                options={entityIdOptions}
                value={trigger.EntityId}
                onChange={(e) => handleTriggerChange(index, 'EntityId', e)}
              />
            </Col>
            <Col span={12}>
              <Select
                placeholder="Select Comparison"
                className={styles.w100}
                disabled={disabled}
                options={comparisonOptions}
                value={trigger.Comparison}
                onChange={(e) => handleTriggerChange(index, 'Comparison', e)}
              />
            </Col>
            <Col span={12}>
              <Input
                placeholder="Enter Value"
                className={styles.w100}
                disabled={disabled}
                value={trigger.Value?.join(', ')}
                onChange={(e) => handleTriggerChange(index, 'Value', e.target.value?.split(', '))}
              />
            </Col>
            <Col span={12}>
              {trigger.OperatorAfter && triggers.length - 1 !== index ? trigger.OperatorAfter : ''}
              {index !== 0 && (
                <Button disabled={disabled} onClick={() => handleTriggerDelete(index)}>
                  DELETE
                </Button>
              )}
            </Col>
          </Row>
        ))}
        <Row>
          {!disabled && (
            <>
              <Col span={12}>
                <Button onClick={(e) => handleNewTrigger(TriggerOperatorAfterType.OR)}>OR</Button>
                <Button onClick={(e) => handleNewTrigger(TriggerOperatorAfterType.AND)}>AND</Button>
              </Col>
            </>
          )}
        </Row>
      </AntCard>
      <Divider />
      <AntCard title="Actions">
        {actions.map((action, index) => (
          <Row key={`action-${action.Id}-${index}`}>
            <Col span={12}>
              <Select
                placeholder="Select Type"
                className={styles.w100}
                disabled={disabled}
                options={actionOptions}
                value={action.Type}
                onChange={(e) => handleActionChange(index, 'Type', e)}
              />
            </Col>
            <Col span={12}>
              <Select
                mode="multiple"
                placeholder="Select Entities"
                className={styles.w100}
                disabled={disabled}
                options={entityIdOptions}
                value={action.EntityIds}
                onChange={(e) => handleActionChange(index, 'EntityIds', e)}
              />
            </Col>
            <Col span={12}>
              {index !== 0 && (
                <>
                  AND
                  <Button disabled={disabled} onClick={() => handleActionDelete(index)}>
                    DELETE
                  </Button>
                </>
              )}
            </Col>
          </Row>
        ))}

        <Row>
          {!disabled && (
            <>
              <Col span={12}>
                <Button onClick={(e) => handleNewAction()}>AND</Button>
              </Col>
            </>
          )}
        </Row>
      </AntCard>
    </>
  );
};
