import { ReactElement, useMemo, useState } from 'react';
import { Table as AntdTable, TableProps as AntdTableProps } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { ColumnType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { emptyFunc, getOptionalValue } from 'helpers';
import { DEFAULT_PAGE_SIZE, FilterType, SortingOrder } from './configs';
import { SearchFilter } from './SearchFilter';

import styles from './styles.module.scss';

export type TableInfo = {
  paginationInfo?: { limit: number; page: number };
  sortingInfo?: string;
  filterInfo?: Record<string, FilterValue | null>;
  isPagination: boolean;
};
export type TableColumnsType<T> = Array<
  ColumnType<T> & { withSort?: boolean; filterType?: FilterType }
>;
export type TableProps<T> = Omit<AntdTableProps<T>, 'columns' | 'onChange'> & {
  columns: TableColumnsType<T>;
  onChange?: (tableInfo: TableInfo) => void;
  onChangePage?: (page: number) => void;
};

export const Table = <T extends object>({
  columns,
  onChange,
  onChangePage,
  ...antdTableProps
}: TableProps<T>): ReactElement => {
  const tableColumns = useMemo(
    () =>
      columns.map(({ withSort, sorter, filterType, ...columnInfo }) => ({
        ...columnInfo,
        sorter: getOptionalValue(withSort || sorter, emptyFunc),
        // You can add other custom filters and filter icons based on filterType
        ...(filterType &&
          filterType === FilterType.Search && {
            filterDropdown: SearchFilter,
            filterIcon: (filtered: boolean) => (
              <SearchOutlined className={cn({ [styles.filteredIcon]: filtered })} />
            ),
          }),
      })),
    [columns],
  );

  const handleChange: AntdTableProps<T>['onChange'] = (pagination, filter, sorter, info) => {
    const pageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

    if (!onChange) return;

    const paginationInfo = pagination.pageSize
      ? {
          limit: pageSize,
          page: (info.action === 'paginate' && pagination?.current) || 1,
        }
      : undefined;

    const sortingInfo =
      !Array.isArray(sorter) && sorter.order && sorter.field
        ? `${sorter.field}.${sorter.order === 'ascend' ? SortingOrder.Asc : SortingOrder.Desc}`
        : undefined;

    const filterInfo = Object.entries(filter).reduce((acc, [column, value]) => {
      const filterMethod = columns.find((col) => col?.dataIndex === column)?.filterType;
      let columnKey = column;
      let columnFilterValue: FilterValue | null | string | undefined = value;
      switch (filterMethod) {
        // You can add cases for other filter types to remap keys and values
        case FilterType.Search: {
          columnKey = `${column}[like]`;
          columnFilterValue = columnFilterValue?.length ? `'${columnFilterValue?.[0]}'` : undefined;
          break;
        }
      }
      return { ...acc, [columnKey]: columnFilterValue };
    }, {});

    onChange({
      sortingInfo,
      paginationInfo,
      filterInfo,
      isPagination: info.action === 'paginate',
    });
  };

  return (
    <AntdTable
      {...antdTableProps}
      columns={tableColumns}
      onChange={handleChange}
      pagination={
        antdTableProps.pagination === false
          ? false
          : {
              position: ['bottomCenter'],
              ...antdTableProps.pagination,
              defaultPageSize: DEFAULT_PAGE_SIZE,
            }
      }
    />
  );
};
