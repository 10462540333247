import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import Option from '../Option';
import Label from '../Label';

import styles from './styles.module.scss';

const GoogleDrive = (props) => {
  const { className } = props;

  return (
    <Option testid="google_drive_option" className={classNames(styles.googleDrive, className)}>
      <Icon className={styles.icon} variant={Icon.GOOGLE_DRIVE} />
      <Label>Google Drive</Label>
    </Option>
  );
};

GoogleDrive.propTypes = {
  className: PropTypes.string,
};

GoogleDrive.defaultProps = {
  className: '',
};

export default GoogleDrive;
