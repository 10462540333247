import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routes } from 'settings/navigation/routes';
import { getUserSelector } from 'store/selectors/user';
import { Row, Col } from 'components-antd';
import { Card } from 'antd';
import { ArrowRight } from 'components/Icons';
import { Icons } from 'pages/Clarity/Icons';
import MyReports from '../MyReports';

import styles from './styles.module.scss';

export const LeftSideBar = () => {
  const { data: user } = useSelector(getUserSelector);

  return (
    <div className={styles.clarityLeftSide}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card className={styles.cardContainers}>
            <Link className={styles.clarityLink} to={routes.myKits}>
              <div className={styles.cardStyles}>
                <Icons variant="kits" />
                <span className={styles.navigationText}>My KITs</span>
                <ArrowRight className={styles.arrow} />
              </div>
            </Link>
          </Card>
        </Col>
        <Col span={24}>
          <MyReports />
        </Col>
      </Row>
    </div>
  );
};
