import { SEARCH_INSTANCE_STATUS } from 'app-constants';
import _ from 'lodash';
import classNames from 'classnames';

import { Button, Input, SimpleModal } from 'components';
import { useFormik } from 'formik';
import { Icons } from 'pages/Properties/common/Icons';
import Icon from 'pages/Properties/SearchResults/Icons';
import styles from 'pages/Properties/SearchResults/LocalHeader/SaveSearchButton/styles.module.scss';
import { ValidationSchema } from 'pages/Properties/SearchResults/LocalHeader/SaveSearchButton/validation';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { selectedSearchAction } from 'store/actions/feedv3';
import { saveSearchInstanceEffect } from 'store/effects';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getFeedCriteriaSelectorV3 } from 'store/selectors/feedv3';
import { TabButton } from '../AgentHeader';
import { Plus } from 'components/Icons';
import { MultiSelectSection } from 'pages/Workshop/common/MultiSelectSection';
import { getShareDrawerInfoSelector } from 'pages/Properties/Feed/Drawers/ShareDrawer/selectors';
import { getClientsSearchInstancesEffect } from 'store/effects/clientSearches';

type Props = {
  className?: string;
  disabled?: boolean;
  isAgent?: boolean;
};

export const SaveSearchBtn: React.FC<Props> = ({
  className,
  disabled = false,
  isAgent = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(false);
  const [showClients, setShowClients] = useState<boolean>(false);
  const [clients, setClients] = useState([]);
  const searchContext = useSelector(getCurrentContextSelector);
  const cfgSearch = { contextKey: searchContext?.ContextKey };
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const {
    clients: { data },
  } = useSelector(getShareDrawerInfoSelector);

  const ClientsData = (data ?? []).map(({ Id, FirstName, LastName }) => ({
    label: `${FirstName} ${LastName}`,
    value: Id,
  }));

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      setIsPending(true);

      const DefaultPropertySearchPreferences = cleanSearchQueryObj(criteria);

      if (DefaultPropertySearchPreferences?.HomeType?.length < 1) {
        DefaultPropertySearchPreferences.HomeType = ['Single', 'Townhouse', 'Condo'];
      }

      const cfg = {
        name: values.name,
        status: SEARCH_INSTANCE_STATUS.ACTIVE,
        DefaultPropertySearchPreferences: {
          ...DefaultPropertySearchPreferences,
        },
        ...(isAgent ? { ClientIds: clients } : {}),
        ...cfgSearch,
      };

      dispatch(
        saveSearchInstanceEffect(cfg, {}, (err, resp) => {
          if (!err) {
            // Get the recently added saved search and apply that on the Feed page.
            const latestIdObject: any = _.maxBy(resp?.data?.result, 'Id');
            if (latestIdObject) {
              const item = {
                id: latestIdObject?.Id,
                name: latestIdObject?.Name,
                criterias: latestIdObject?.DefaultPropertySearchPreferences,
              };
              dispatch(selectedSearchAction({ selectedSearch: item }));
            }
            if (isAgent) {
              dispatch(getClientsSearchInstancesEffect());
            }
            history.push(routes.feed);
            setIsPending(false);
          } else setIsPending(false);
        }),
      );
    },
  });

  const onClose = () => {
    setShowClients(false);
    setPopupOpen(false);
    formik.resetForm();
  };

  const onClickHandler = () => {
    if (!disabled) setPopupOpen(!popupOpen);
  };

  return (
    <div className={classNames(styles.saveSearchButton, className)}>
      <TabButton text="Save Search" icon={Icons.BOOKMARK} onClick={onClickHandler} />

      <SimpleModal isOpen={popupOpen} onClose={onClose} contentClassName={styles.popup}>
        <div className={styles.popupInner}>
          <form testid="save_search_form" onSubmit={formik.handleSubmit}>
            <div className={styles.header}>
              <Icon className={styles.icon} variant={Icon.BOOKMARK} />
              <span className={styles.title}>Save Search</span>
            </div>
            <Input
              label="Name"
              name="name"
              variant={Input.LIGHT_ROUND}
              placeholder="e.g. Chicago - 3 BR / 2 BA"
              maxLength={20}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name ? formik.errors.name : ''}
              testid="search_name"
            />
            {isAgent && (
              <>
                {showClients ? (
                  <>
                    <p className={styles.label}>Clients</p>
                    <MultiSelectSection
                      placeholder={'Select Clients...'}
                      entityList={ClientsData}
                      entity={clients}
                      setEntity={setClients}
                      avatarPrefix
                      showSelectedEntriesBelow={false}
                      multiSelectSectionDropdownClassName={styles.multiSelectSectionDropdown}
                      multiSelectWrapperClassName={styles.multiSelectWrapperClassName}
                    />
                  </>
                ) : (
                  <div
                    className={styles.button}
                    onClick={() => {
                      setShowClients(true);
                    }}
                  >
                    <div className={styles.add}>
                      <Plus color="#747475" />
                    </div>
                    <p className={styles.label}>Add a Client</p>
                  </div>
                )}
              </>
            )}
            <div className={styles.actions}>
              <Button
                onClick={onClose}
                className={classNames(styles.action, styles.cancel)}
                title="Cancel"
                testid="cancel"
                titleClassName={styles.buttonTitle}
              />
              <Button
                type="submit"
                title="Save"
                isPending={isPending}
                className={classNames(styles.action, styles.save)}
                loaderClassName={styles.loader}
                testid="save"
                titleClassName={styles.buttonTitle}
              />
            </div>
          </form>
        </div>
      </SimpleModal>
    </div>
  );
};
