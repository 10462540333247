import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { DateRangePicker } from 'components/Form/DateRangePicker';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { Moment } from 'moment';
import { FilterDrawer, FilterSection } from 'pages/Workshop/common';
import { GroupCheckBoxesSection } from 'pages/Workshop/common/GroupCheckBoxesSection';
import { MultiSelectSection } from 'pages/Workshop/common/MultiSelectSection';
import { LocationService } from 'services';
import { projectStatusesOptions, transactionStatusesOptions } from 'settings/constants/transaction';
import { isEqual } from 'lodash-es';
import {
  getOfferAggregateDataSelector,
  getOfferIsArchiveSelector,
} from 'store/selectors/offerAggregate';
import { ActiveOfferStatus, ArchivedOfferStatus, OfferStatus } from 'types';
import { Input } from 'components';
import { MAX_OFFER_AMOUNT } from 'app-constants';
import { getAllOfferBuyingAgentsSelector } from 'store/selectors/offerBuyingAgents';
import { getTransactionsPersons } from 'store/selectors/transactions';
import moment from 'moment';

let defaultFilters = {};

export const FilterSections = ({ onReset, onDone, transactionRoomId }) => {
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const { isArchive } = useSelector(getOfferIsArchiveSelector);
  const { buyingAgents } = useSelector(getAllOfferBuyingAgentsSelector);
  const transactionsPersons = useSelector(getTransactionsPersons);
  const query = locationSrv.getQuery();
  const isProject = useIsProjectRoute();
  const offers = useSelector(getOfferAggregateDataSelector);
  const transactionsData = new Map<number, string>();
  offers?.map((offer) => {
    if (!offer?.PropertyId || !offer?.Property?.Address?.PlaceName) return;
    if (transactionsData.has(offer.PropertyId)) return;
    transactionsData.set(offer.PropertyId, offer.Property.Address.PlaceName);
  });

  const statusesData = isProject ? [...projectStatusesOptions] : [...transactionStatusesOptions];
  const [initialCache] = useState(transactionsData);
  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
  const [receivedFromDate, setReceivedFromDate] = useState<Moment>();
  const [receivedToDate, setReceivedToDate] = useState<Moment>();
  const [expiresFromDate, setExpiresFromDate] = useState<Moment | undefined>(
    query.dueToday ? moment() : undefined,
  );
  const [expiresToDate, setExpiresToDate] = useState<Moment | undefined>(
    query.dueToday ? moment() : undefined,
  );
  const [resetDates, setResetDates] = useState(false);
  const [cachedTransactions, setCachedTransactions] = useState<Map<number, string>>(
    new Map<number, string>(),
  );
  const [transactions, setTransactions] = useState<string[]>([]);
  const [clients, setClients] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>(query.status ? query.status.split(',') : []);
  const [amountFrom, setAmountFrom] = useState<number | string>('');
  const [amountTo, setAmountTo] = useState<number | string>('');
  const [statuses, setStatuses] = useState<string[]>(
    query.statuses ? query.statuses.split(',') : [],
  );
  const [submittedBy, setSubmittedBy] = useState<string[]>([]);
  const [previousFilters, setPreviousFilters] = useState({});

  const clientsData = transactionsPersons.clientsArray.map((item) => ({
    value: item,
    label: item,
  }));
  const agentsOrOwnersData = transactionsPersons.agentsOrOwnersArray.map((item) => ({
    value: item,
    label: item,
  }));
  const coordinatorsData = transactionsPersons.coordinatorsArray.map((item) => ({
    value: item,
    label: item,
  }));

  const getFilters = () => ({
    receivedFromDate,
    receivedToDate,
    expiresFromDate,
    expiresToDate,
    transactions,
    clients,
    status,
    statuses,
    amountFrom,
    amountTo,
    submittedBy,
  });

  const [queryRender, setQueryRender] = useState<boolean>(true);
  const [ArchiveFilters, setArchiveFilters] = useState([OfferStatus.Withdrawn]);
  useEffect(() => {
    // Reset Filters whenever archive button is clicked.
    if (!queryRender) resetHandler();
    queryRender && ArchiveFilters.includes(query.status) && !isArchive
      ? setQueryRender(true)
      : setQueryRender(false);
  }, [isArchive]);

  useEffect(() => {
    defaultFilters = getFilters();
    setPreviousFilters(defaultFilters);
  }, []);

  useEffect(() => {
    if (
      !cachedTransactions?.size &&
      transactionsData?.size &&
      !isEqual(transactionsData, initialCache)
    ) {
      setCachedTransactions(transactionsData);
    }
  }, [transactionsData]);

  useEffect(() => {
    setIsFiltersApplied(
      Boolean(
        amountFrom ||
          amountTo ||
          receivedFromDate ||
          receivedToDate ||
          expiresFromDate ||
          expiresToDate ||
          status.length ||
          transactions.length ||
          clients.length ||
          statuses.length ||
          submittedBy.length,
      ),
    );
  }, [
    receivedFromDate,
    receivedToDate,
    expiresFromDate,
    expiresToDate,
    transactions,
    clients,
    status,
    statuses,
    amountFrom,
    amountTo,
    submittedBy,
  ]);

  const parseAgents = (agents: string[]) => {
    return agents.map((agent) => {
      const [name, brokerage] = agent.split('|').map((str) => str.trim());
      return { Name: name, Brokerage: brokerage };
    });
  };

  const submitHandler = () => {
    const submitObject = {
      statuses,
      status,
      transactions,
      amountFrom,
      amountTo,
      submittedBy: parseAgents(submittedBy),
      clients,
      receivedFromDate,
      receivedToDate,
      expiresFromDate,
      expiresToDate,
    };

    if (transactionRoomId) {
      submitObject['transactionIds'] = [transactionRoomId];
    }

    onDone(submitObject);
    setPreviousFilters(getFilters());
  };

  const resetHandler = () => {
    setStatuses([]);
    setStatus([]);
    setTransactions([]);
    setAmountFrom('');
    setAmountTo('');
    setSubmittedBy([]);
    setClients([]);
    setReceivedFromDate(undefined);
    setReceivedToDate(undefined);
    setExpiresFromDate(undefined);
    setExpiresToDate(undefined);
    setResetDates(true);

    const filters = {};

    if (transactionRoomId) {
      filters['transactionIds'] = [transactionRoomId];
    }

    onReset(filters);
    setPreviousFilters(defaultFilters);
  };

  const discardHandler = () => {
    if (JSON.stringify(previousFilters) === JSON.stringify(getFilters())) {
      return;
    }

    const {
      receivedFromDate,
      receivedToDate,
      expiresFromDate,
      expiresToDate,
      transactions,
      clients,
      status,
      statuses,
      amountFrom,
      amountTo,
      submittedBy,
    } = previousFilters as any;

    if (receivedFromDate) setReceivedFromDate(receivedFromDate);
    if (receivedToDate) setReceivedToDate(receivedToDate);
    if (expiresFromDate) setExpiresFromDate(expiresFromDate);
    if (expiresToDate) setExpiresToDate(expiresToDate);
    if (transactions) setTransactions(transactions);
    if (clients) setClients(clients);
    if (status) setStatus(status);
    if (statuses) setStatuses(statuses);
    if (amountFrom) setAmountFrom(amountFrom);
    if (amountTo) setAmountTo(amountTo);
    if (submittedBy) setSubmittedBy(submittedBy);
  };

  return (
    <FilterDrawer
      className={styles.filterBtn}
      isFiltersApplied={isFiltersApplied}
      onDone={submitHandler}
      onReset={resetHandler}
      onDiscard={discardHandler}
    >
      <div className={styles.filterSections}>
        <FilterSection title="Offer Status" count={status?.length}>
          <GroupCheckBoxesSection
            entityList={(
              Object.values(isArchive ? ArchivedOfferStatus : ActiveOfferStatus) as Array<string>
            ).map((val) => ({
              name: val.replace(/([A-Z])/g, ' $1'),
              value: val,
            }))}
            entityValues={status}
            setEntity={setStatus}
          />
        </FilterSection>
        {!transactionRoomId && (
          <FilterSection title="Transactions" count={transactions?.length + statuses?.length}>
            <MultiSelectSection
              placeholder={`Search by Transactions...`}
              entityList={Array.from(cachedTransactions?.entries())?.map(
                (property: [number, string]) => ({
                  value: property[1],
                  label: property[1],
                }),
              )}
              entity={transactions}
              setEntity={setTransactions}
              locationPrefix
            />
            <GroupCheckBoxesSection
              title={'Statuses'}
              entityList={statusesData}
              entityValues={statuses}
              setEntity={setStatuses}
            />
          </FilterSection>
        )}
        <FilterSection title="Amount">
          <div className={styles.inputsRange}>
            <Input
              variant={Input.LIGHT_ROUND}
              placeholder="$0"
              isNumberFormat
              prefix="$"
              onChange={(e, val) => setAmountFrom(val)}
              minNumber={0}
              maxNumber={MAX_OFFER_AMOUNT}
              value={amountFrom}
            />
            <p className={styles.to}>to</p>
            <Input
              variant={Input.LIGHT_ROUND}
              placeholder="0"
              isNumberFormat
              prefix="$"
              onChange={(e, val) => setAmountTo(val)}
              minNumber={0}
              maxNumber={MAX_OFFER_AMOUNT}
              value={amountTo}
            />
          </div>
        </FilterSection>
        <FilterSection title="Submitted By" count={submittedBy.length}>
          <MultiSelectSection
            placeholder={`Search by Name...`}
            entityList={buyingAgents?.map((agent) => {
              const name: string = `${agent?.BuyingAgentName} | ${agent?.BuyingAgentBrokerage}`;
              return {
                value: name,
                label: name,
              };
            })}
            entity={submittedBy}
            setEntity={setSubmittedBy}
            avatarPrefix
          />
        </FilterSection>
        <FilterSection title="Client" count={clients?.length}>
          <MultiSelectSection
            placeholder={'Search by Clients...'}
            entityList={clientsData}
            entity={clients}
            setEntity={setClients}
            avatarPrefix
          />
        </FilterSection>

        <FilterSection title="Date Received">
          <div className={styles.datePickerContainer}>
            <DateRangePicker
              span={13}
              title={''}
              onDatesChange={(from, to) => {
                resetDates && setResetDates(false);
                setReceivedFromDate(from);
                setReceivedToDate(to);
              }}
              fromDateValue={receivedFromDate}
              toDateValue={receivedToDate}
              dateRangePickerWrapperClass={styles.dateRangePicker}
              resetDates={resetDates}
              futureDisabled={false}
            />
          </div>
        </FilterSection>
        <FilterSection title="Expires">
          <div className={styles.datePickerContainer}>
            <DateRangePicker
              span={13}
              title={''}
              onDatesChange={(from, to) => {
                resetDates && setResetDates(false);
                setExpiresFromDate(from);
                setExpiresToDate(to);
              }}
              fromDateValue={expiresFromDate}
              toDateValue={expiresToDate}
              dateRangePickerWrapperClass={styles.dateRangePicker}
              resetDates={resetDates}
              futureDisabled={false}
            />
          </div>
        </FilterSection>
      </div>
    </FilterDrawer>
  );
};
