import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SimpleModal } from 'components';
import { Close } from 'components/Icons';

import styles from './styles.module.scss';

const Modal = (props) => {
  const {
    className,
    modalHeaderClassName,
    contentClassName,
    children,
    isOpen,
    onClose,
    title,
    innerHolderClassName,
    modalTitleClassName,
    testid,
  } = props;
  if (!isOpen) return null;

  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={onClose}
      className={classNames(styles.modal, className)}
      contentClassName={classNames(styles.modalContent, contentClassName)}
      innerHolderClassName={styles.innerHolder}
      testid={testid}
      scroll
    >
      <div className={classNames(styles.modalHeader, modalHeaderClassName)}>
        <div className={classNames(styles.titleWrap, modalTitleClassName)}>
          <p className={styles.titleText}>{title}</p>
        </div>
        <Close onClick={onClose} className={styles.closeIcon} />
      </div>
      {children}
    </SimpleModal>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  modalHeaderClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  innerHolderClassName: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  testid: PropTypes.string,
};

Modal.defaultProps = {
  className: '',
  modalHeaderClassName: '',
  modalTitleClassName: '',
  contentClassName: '',
  innerHolderClassName: '',
  title: '',
  isOpen: false,
  testid: undefined,
  onClose: () => {},
};

export default Modal;
