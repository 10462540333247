import { getEmailVerificationInfo, sendVerificationEmail } from 'api/user';
import { Button, Modal } from 'components-antd';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { appSetShowEmailVerificationPopupAction } from 'store/actions/app';
import { isEmailVerificationPopupOpen } from 'store/selectors/app';
import { getUserDataSelector } from 'store/selectors/user';
import { LOCAL_STORAGE_KEY } from '../constants';
import { undoInterruptedActionHandlers } from '../interruptedActions';
import styles from './styles.module.scss';

export const EmailVerificationWallModal: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isModalOpen = useSelector(isEmailVerificationPopupOpen);
  const user = useSelector(getUserDataSelector);

  const [emailVerificationData, setEmailVerificationData] = useState<
    undefined | null | EmailVerificationData
  >(undefined);

  // Load info about existing verification code request once modal is open
  useEffect(() => {
    const runEffect = async () => {
      let response = await getEmailVerificationInfo();
      if (!response.data) {
        // Email verification is not requested yet
        response = await sendVerificationEmail({
          email: user.Email,
        });
      }

      setEmailVerificationData(response.data);
    };

    if (isModalOpen) {
      void runEffect();
    }
  }, [isModalOpen]);

  const [remainingSecondsToResendLink, setRemainingSecondsToResendLink] = useState<number | null>(
    null,
  );

  // Update timer with remaining seconds for next attempt
  useEffect(() => {
    const interval = setInterval(() => {
      const remainingSeconds = moment(emailVerificationData?.AllowLinkResendTimestamp).diff(
        moment(),
        'seconds',
      );
      setRemainingSecondsToResendLink(remainingSeconds);

      if (remainingSeconds < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [emailVerificationData]);

  const handleResendClick = async () => {
    try {
      const response = await sendVerificationEmail({
        email: user.Email,
      });
      setEmailVerificationData(response.data);
      showSuccessMessage('Email verification link sent');
    } catch (err) {
      showErrorMessage('Error occured while sending email verification link');
    }
  };

  const getResendText = () => {
    if (remainingSecondsToResendLink === null) {
      return null;
    }

    if (remainingSecondsToResendLink > 0) {
      return <span>Resend it in {remainingSecondsToResendLink} seconds </span>;
    }

    if (remainingSecondsToResendLink <= 0) {
      return (
        <span>
          <a
            onClick={() => {
              handleResendClick();
            }}
            href="#"
          >
            Resend
          </a>
        </span>
      );
    }
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          width={675}
          footer={null}
          maskClosable={false}
          destroyOnClose={true}
          title=" "
          onCancel={() => {
            const afterVerificationStateJson = window.localStorage.getItem(LOCAL_STORAGE_KEY);
            if (afterVerificationStateJson) {
              const afterVerificationState = JSON.parse(afterVerificationStateJson);

              let actionHandler = undoInterruptedActionHandlers[afterVerificationState.action];
              if (actionHandler) {
                actionHandler(history, dispatch);
              }
              window.localStorage.removeItem(LOCAL_STORAGE_KEY);
            }
            dispatch(appSetShowEmailVerificationPopupAction(false));
          }}
        >
          <div className={styles.layout}>
            <h2 className={styles.stepHeader}>Please verify your email.</h2>
            <div className={styles.text}>
              Sorry for the inconvenience, but we need to verify your email before you can do this
              action. We will send you a verification link to{' '}
              <span className={styles.highlightedEmail}>{user.Email}</span>
            </div>
            <div className={styles.resend}>
              {emailVerificationData &&
                (!emailVerificationData.IsBlocked ? (
                  <p>Didn’t receive it? {getResendText()}</p>
                ) : (
                  <p>
                    Didn’t receive it? Please contact{' '}
                    <a href="emailto:support@mosaik.io">support@mosaik.io</a> for further
                    assistance.
                  </p>
                ))}
            </div>
            <Button disabled={true} variant={'secondary'} className={styles.continueButton}>
              Continue
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

interface EmailVerificationData {
  AllowLinkResendTimestamp: Date;
  IsBlocked: boolean;
  Email: string;
}
