import { cloneDeep, get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import {
  requestGetTaskAggregate,
  resetTaskAggregateAction,
  updateUnReadCommentsAction,
  setShowCommentIconAction,
  updateMultipleTasksAction,
  deleteMultipleTasksAction,
  setTransactionTasksSearchAction,
  setTransactionsTasksArchiveAction,
} from 'store/actions/taskAggregate';
import {
  updateTransactionTaskAction,
  deleteTransactionTaskAction,
  updateTransactionTaskChecklistAction,
  createTransactionTaskAction,
} from 'store/actions/transactions';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  search: '',
  data: null,
  isArchive: false,
  meta: {},
  filters: {},
};

export default handleActions(
  {
    [requestGetTaskAggregate as any]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data.result', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },

    [createTransactionTaskAction as any]: (state, { payload }: { payload: any }) => {
      const { Tasks, ...rest } = get(payload, 'data.result', {});
      const createdTask = {
        ...Tasks?.slice(-1)[0],
        Transaction: { Id: rest.Id, Property: rest.Property },
      };
      return {
        ...state,
        data: [...(state.data || []), createdTask],
      };
    },
    [updateTransactionTaskAction as any]: (state: any, { payload }: any) => {
      const tasks = state.data || [];
      const taskId = payload.meta.taskId;
      const index = tasks.findIndex((item) => item.Id === taskId);
      const updatedTask = payload.data?.result?.Tasks?.find((task) => task.Id === taskId);
      if (index !== -1 && updatedTask) {
        delete updatedTask.Transaction;
        tasks[index] = {
          ...tasks[index],
          ...updatedTask,
        };
      }

      return {
        ...state,
        data: [...tasks],
      };
    },
    [deleteTransactionTaskAction as any]: (state: any, { payload }: any) => {
      const tasks = state.data || [];
      const taskId = payload.meta.taskId;
      const index = tasks.findIndex((item) => item.Id === taskId);
      if (index !== -1) tasks.splice(index, 1);

      return {
        ...state,
        data: [...tasks],
      };
    },
    [resetTaskAggregateAction as any]: () => ({ ...initialData }),
    [appLogoutAction as any]: () => initialData,
    [updateUnReadCommentsAction as any]: (state: any, { payload }: any) => {
      const { taskId, userId } = payload;
      const updatedData = [...(state.data || [])];

      const taskToUpdate = updatedData.find((task) => task.Id === taskId);

      if (taskToUpdate) {
        taskToUpdate.Comments = (taskToUpdate.Comments || []).map((comment) => {
          comment.MessageReceipts = (comment.MessageReceipts || []).map((messageReceipt) => {
            if (messageReceipt.UserId === userId) {
              messageReceipt.IsRead = true;
            }
            return messageReceipt;
          });
          return comment;
        });
      }

      return { ...state, data: updatedData };
    },
    [setShowCommentIconAction as any]: (state: any, { payload }: any) => {
      const tasks = state.data || [];
      const { taskId } = payload;
      const taskIndex = tasks.findIndex((task) => task.Id === taskId);

      if (taskIndex !== -1) {
        const updatedTasks = [...tasks];
        updatedTasks[taskIndex] = {
          ...updatedTasks[taskIndex],
          showCommentIcon: true,
        };

        return {
          ...state,
          data: updatedTasks,
        };
      }
      return state;
    },
    [updateTransactionTaskChecklistAction as any]: (state: any, { payload }: any) => {
      const task = get(payload, 'data.result');
      const tasks = cloneDeep(state.data);

      if (task && tasks?.length) {
        const index = tasks.findIndex((item) => item.Id === task.Id);
        if (index !== -1) {
          tasks[index] = task;
        }
      }

      return {
        ...state,
        data: tasks,
      };
    },
    [updateMultipleTasksAction as any]: (state: any, { payload }: any) => {
      const updatedTasks = state?.data?.map((existingTask) => {
        const updatedTask = payload?.data?.result?.find((task) => task.Id === existingTask.Id);
        return updatedTask ? updatedTask : existingTask;
      });
      return {
        ...state,
        data: updatedTasks,
      };
    },
    [setTransactionTasksSearchAction as any]: (state: any, { payload }: any) => ({
      ...state,
      data: state?.data ? [...state.data] : initialData.data,
      search: payload || '',
    }),
    [setTransactionsTasksArchiveAction as any]: (state: any, { payload }: any) => ({
      ...state,
      isArchive: !!payload,
    }),
    [deleteMultipleTasksAction as any]: (state: any, { payload }: any) => {
      const updatedTasks = state?.data?.filter((existingTask) => {
        return !payload?.data?.result?.includes(existingTask.Id);
      });
      return {
        ...state,
        data: updatedTasks,
      };
    },
  },

  initialData,
);
