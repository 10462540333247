import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import { PENDING } from 'settings/constants/apiState';
import { routes } from 'settings/navigation/routes';
import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';

import { MinMax } from 'components';
import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';

import styles from './styles.module.scss';

const ApprovePriceRange = ({ onNext, Controls, stageIndex }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);

  const propName = OnBoardingWizard.propNames.approvePriceRange;
  const storedAnswer = getWizardStageValue({
    obj: onBoarding,
    stageIndex,
    signUpRole,
    propName,
    parentPropName: 'PreApproval',
  }) || [ApprovePriceRange.range.min, ApprovePriceRange.range.max];

  const [stageValue, setStageValue] = useState(storedAnswer);

  const findPrevInsertedPriceRange = () => {
    const path = `meta.Values.[${signUpRole}].SearchInstance.DefaultPropertySearchPreferences.PriceRange`;
    const parkingStage = Object.values(onBoarding.signUp || {}).find((stage) => !!get(stage, path));

    return get(parkingStage, path) || {};
  };

  useEffect(() => {
    const [min, max] = stageValue;
    const { Min, Max } = findPrevInsertedPriceRange() || {};

    if (Min || Max) {
      setStageValue([min || Min || '', max || Max || '']);
    }
  }, []); // eslint-disable-line

  const onSave = () => {
    const cfg = {
      stageIndex,
      PreApproval: {
        [propName]: stageValue,
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, { withoutRequest: true }, (err) => {
        if (!err) {
          onNext();
        }
      }),
    );
  };

  const onChangeRange = ({ min, max }) => {
    const [minVal, maxVal] = stageValue;
    setStageValue([min ?? minVal, max ?? maxVal]);
  };

  const isPending = onBoarding.state === PENDING;

  const [minVal, maxVal] = stageValue;

  return (
    <OnBoardingWrapper testid="approve_range_stage" isPending={isPending}>
      <Controls
        onNext={() => onSave(stageValue)}
        onClose={() => history.push(routes.mySearches)}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question>What is your price range?</Question>
        <AnswersContainer>
          <MinMax
            placeholderMin="$  No min"
            placeholderMax="$  No max"
            className={styles.minMax}
            separatorClassName={styles.to}
            prefix="$  "
            valueMin={minVal}
            valueMax={maxVal}
            onChangeMin={(e, val) => onChangeRange({ min: val ? +val : val })}
            onChangeMax={(e, val) => onChangeRange({ max: val ? +val : val })}
            testidMin="price_min"
            testidMax="price_max"
            variant="light"
            is
          />
        </AnswersContainer>
        <ButtonsContainer>
          <OnboardingContinueButton
            testid="approve_range_next"
            onClick={() => onSave(stageValue)}
            isPending={isPending}
            iconArrow
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

ApprovePriceRange.range = {
  min: '',
  max: '',
};

ApprovePriceRange.propTypes = {
  onNext: PropTypes.func,
  Controls: PropTypes.elementType,
  stageIndex: PropTypes.number,
};

ApprovePriceRange.defaultProps = {
  onNext: () => {},
  Controls: () => null,
  stageIndex: undefined,
};

export default ApprovePriceRange;
