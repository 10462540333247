import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Select, ConfirmationDialog } from 'components';
import { TEAM_ADMIN, TEAM_BASIC } from 'settings/constants/roles';
import { TeamAgentType } from 'types';
import { updateMemberRoleEffect, requestGetSymphonyListEffect } from 'store/effects';
import { showSuccessMessage } from 'helpers/success';
import { appSetConnectionTypeAction, appSetShowAssignConnectionAction } from 'store/actions/app';
import { AGENT } from 'settings/constants/roles';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';

import styles from './styles.module.scss';

const AgentRole = ({ contact }) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const dispatch = useDispatch();

  const getValue = () => {
    const value =
      contact.TeamRole === TeamAgentType.Admin ? TeamAgentType.Admin : TeamAgentType.Member;
    return { value, name: value };
  };

  const onRoleChange = (val) => setOpenConfirmModal(true);

  const changeUserStatusContent = useMemo(
    () =>
      contact ? (
        <div className={styles.agentRoleConfirmText}>
          <h4>
            Are you sure that you want to {contact.TeamRole === TEAM_ADMIN ? 'change' : 'make'}{' '}
            {contact?.FirstName} {contact?.LastName}
            {contact.TeamRole === TEAM_ADMIN ? ' role to Member' : `'s an Admin`}?
          </h4>
          {contact.TeamRole === TEAM_ADMIN ? null : (
            <>
              <p>As a reminder, admins can:</p>
              <p>• Invite members</p>
              <p>• Invite reassign, & deactivate clients</p>
              <p>• Manage templates</p>
              <p>• Manage concierge recommendations</p>
            </>
          )}
        </div>
      ) : null,
    [contact],
  );

  const onSubmitConfirmModal = () => {
    dispatch(
      updateMemberRoleEffect(
        { role: contact.TeamRole === TEAM_ADMIN ? TEAM_BASIC : TEAM_ADMIN },
        { agentId: contact.Id, silent: true },
        (err) => {
          if (!err) {
            dispatch(requestGetSymphonyListEffect({}, { silent: true }));
            setOpenConfirmModal(false);
            showSuccessMessage(
              `Role changed to ${
                contact.TeamRole === TEAM_ADMIN ? TeamAgentType.Member : TeamAgentType.Admin
              }.`,
            );
            if (contact.TeamRole === TEAM_BASIC) {
              dispatch(appSetConnectionTypeAction({ type: AGENT_CONNECTIONS.Team }));
              dispatch(
                appSetShowAssignConnectionAction({
                  open: true,
                  assignType: AGENT,
                  newInvite: false,
                }),
              );
            }
          }
        },
      ),
    );
  };

  return (
    <>
      <Select
        value={getValue()}
        onSelect={(e, value) => onRoleChange(value)}
        options={[
          { value: 'Admin', name: 'Admin' },
          { value: 'Member', name: 'Member' },
        ]}
        className={{
          wrapper: styles.agentRoleWrapper,
        }}
        arrowIconClassName={styles.arrowIcon}
      />
      <ConfirmationDialog
        onReject={() => setOpenConfirmModal(false)}
        onConfirm={onSubmitConfirmModal}
        isOpen={openConfirmModal}
        confirmText="Continue"
      >
        {changeUserStatusContent}
      </ConfirmationDialog>
    </>
  );
};

AgentRole.propTypes = {
  contact: PropTypes.object,
};

AgentRole.defaultProps = {
  contact: {},
};

export default AgentRole;
