import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPreApprovalRequestsEffect } from 'store/effects';
import { getUserId } from 'store/selectors/user';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getPreApprovalRequestsSelector } from 'store/selectors/mySearches';
import { Spinner } from 'components';
import { PENDING } from 'settings/constants/apiState';
import { Request } from '../Request';

import styles from './styles.module.scss';

export const PreApprovalRequests = () => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const context = useSelector(getCurrentContextSelector);
  const { state: isLoading, data: requests } = useSelector(getPreApprovalRequestsSelector);

  const renderReceivedRequests = () => {
    const received = requests?.filter((r) => r.Status === 'Responded') || [];

    if (received.length) {
      return (
        <>
          <p className={styles.subTitle}>Received:</p>
          {received?.map((request) => (
            <Request request={request} />
          ))}
        </>
      );
    }

    return null;
  };

  const renderPendingRequests = () => {
    const pending =
      requests?.filter((r) => r.Status === 'New' || r.Status === 'PendingAnswer') || [];

    if (pending.length) {
      return (
        <>
          <p className={styles.subTitle}>Pending:</p>
          {pending?.map((request) => (
            <Request request={request} />
          ))}
        </>
      );
    }

    return null;
  };

  const fetchRequests = useCallback(() => {
    dispatch(
      getPreApprovalRequestsEffect({
        clientId: userId,
        contextKey: context.ContextKey,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    fetchRequests();
  }, []);

  return (
    <>
      {isLoading === PENDING ? (
        <Spinner loaderClassName={styles.loader} />
      ) : (
        <div className={styles.preApprovalRequests}>
          {renderReceivedRequests()}
          {renderPendingRequests()}
        </div>
      )}
    </>
  );
};
