import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Radio } from 'components-antd';

import {
  nonActivePropertyStatus,
  propertAttribute,
  propertyStatusOptionsWithoutClosed,
} from 'settings/constants/properties';
import {
  ScrollDrawerWrapper,
  DrawerPaddingWrapper,
  Footer,
  ParkingSpaces,
  GarageOnly,
} from 'pages/Properties/Feed/components';
import {
  MinMax,
  YearMinMax,
  PropertyType,
  Input,
  Button,
  Wrapper as PendingWrapper,
  Select,
  LotSizeMinMax,
} from 'components';
import { sortStrings, sortStatus } from 'helpers';
import { ValidationSchema } from './validation';
import { mergeInitialValues } from './helpers';
import { setFeedPrefsAction } from 'store/actions/feed';
import { getFeedPrefsFilterSelector } from 'store/selectors/feed';
import { openFeedFilterDrawerEffect } from 'store/effects/feed';
import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';
import { useGetActiveFeedEffect } from 'pages/Properties/Feed/helpers/useGetActiveFeedEffect';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import { isEqual } from 'lodash-es';

const AllSubFeedForm = (props) => {
  const { className } = props;
  const [statusDropdownOptions, setStatusDropdownOptions] = useState(
    propertyStatusOptionsWithoutClosed,
  );
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();
  const searchPrefsValue = useSelector(getFeedPrefsFilterSelector);
  const { Status } = searchPrefsValue ?? {};
  const [placement, SetPlacement] = useState(
    isEqual(Status, [propertAttribute.Closed]) ? propertAttribute.Closed : propertAttribute.ForSale,
  );

  const getFeedPropsEffect = useGetActiveFeedEffect();

  const handleRadioChange = (e) => {
    const value = e.target.value;
    SetPlacement(value);
    if (value === propertAttribute.ForSale) {
      setStatusDropdownOptions(propertyStatusOptionsWithoutClosed);
    } else if (value === propertAttribute.Closed) {
      formik.setFieldValue('Status', [propertAttribute.Closed]);
      setStatusDropdownOptions(nonActivePropertyStatus);
    }
  };

  const formik = useFormik({
    initialValues: mergeInitialValues(searchPrefsValue),
    validationSchema: ValidationSchema,
    onSubmit(values) {
      setIsPending(true);
      const { Status } = values;
      if (Status?.length) Status?.sort(sortStatus);

      const cleanedFeedPrefsObj = cleanSearchQueryObj(
        Status?.length ? { ...values, Status } : values,
      );
      dispatch(setFeedPrefsAction(cleanedFeedPrefsObj));
      dispatch(openFeedFilterDrawerEffect({ open: false }));
      dispatch(
        getFeedPropsEffect({ new: true }, {}, () => {
          setIsPending(false);
        }),
      );
      const ref = document.getElementById('contentWrapper');
      if (ref) {
        document.getElementById('contentWrapper').scrollTop = 0;
      }
    },
  });
  const garageOnly = (
    <GarageOnly
      value={formik.values?.GarageOnly}
      onChange={(value) => formik.setFieldValue('GarageOnly', value)}
      disabled={formik.values?.MinParkingSpaces == 0}
    />
  );
  const onResetHandler = () => {
    formik.setValues(AllSubFeedForm.initialValues);
  };
  const flatValue = (propName) => formik?.values?.[propName];

  return (
    <div>
      <ScrollDrawerWrapper className={styles.allSubFeedScroll}>
        <PendingWrapper className={styles.pendingWrapper} isPending={false}>
          <DrawerPaddingWrapper className={className}>
            <form id="allSubFeed" onSubmit={formik.handleSubmit}>
              <Radio.Group
                className={styles.radioGroupCustom}
                value={placement}
                name='input[type="radio"]'
                optionType="button"
                onChange={handleRadioChange}
                size="large"
              >
                <Radio.Button value="For Sale">For Sale</Radio.Button>
                <Radio.Button value="Closed">Closed</Radio.Button>
              </Radio.Group>
              {placement !== propertAttribute.Closed && (
                <Select
                  name="Status"
                  label="Status"
                  multiple
                  className={{ wrapper: styles.field }}
                  variant={Select.LIGHT_FULL}
                  options={statusDropdownOptions}
                  onSelect={formik.handleChange}
                  disabled={isPending}
                  testid="status_select"
                  closeOnSelect={false}
                  defaultValue={Status}
                />
              )}
              {/* @todo Remove locations option after discussing with business*/}
              {/*<Locations*/}
              {/*  className={styles.field}*/}
              {/*  valuesWrapperClassName={styles.locationsValues}*/}
              {/*  name="Locations"*/}
              {/*  label="Locations"*/}
              {/*  onResult={(result, preparedData) => {*/}
              {/*    if (preparedData?.length) preparedData.sort(sortLocations);*/}
              {/*    formik.setFieldValue('Locations', preparedData);*/}
              {/*  }}*/}
              {/*  error={formik.touched.Locations ? formik.errors.Locations : ''}*/}
              {/*  disabled={isPending}*/}
              {/*  testid="locations"*/}
              {/*  value={formik?.values?.Locations || []}*/}
              {/*/>*/}
              <MinMax
                className={styles.field}
                label="Price"
                nameMin="PriceRange.Min"
                nameMax="PriceRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.PriceRange?.Min}
                valueMax={formik.values?.PriceRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                prefix="$"
                disabled={isPending}
                testidMin="price_min"
                testidMax="price_max"
              />
              <MinMax
                className={styles.field}
                label="Beds"
                nameMin="NumBedroomsRange.Min"
                nameMax="NumBedroomsRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.NumBedroomsRange?.Min}
                valueMax={formik.values?.NumBedroomsRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                disabled={isPending}
                testidMin="price_min"
                testidMax="price_max"
              />
              <MinMax
                className={styles.field}
                label="Baths"
                nameMin="NumBathroomsRange.Min"
                nameMax="NumBathroomsRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.NumBathroomsRange?.Min}
                valueMax={formik.values?.NumBathroomsRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                disabled={isPending}
                testidMin="price_min"
                testidMax="price_max"
              />
              <PropertyType
                className={classNames(styles.field, styles.propertyTypes)}
                label="Property Type"
                onChange={(type) => {
                  if (type?.length) type.sort(sortStrings);
                  formik.setFieldValue('HomeType', type);
                }}
                disabled={isPending}
                error={formik.touched.HomeType ? formik.errors.HomeType : ''}
                value={formik.values?.HomeType}
                propertyTypeWrapperClassName={styles.filterPropertyWrapper}
                itemClassName={styles.filterItem}
                isFeedFilter={true}
              />
              <MinMax
                className={styles.field}
                label="Square Feet"
                nameMin="SquareFeetRange.Min"
                nameMax="SquareFeetRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.SquareFeetRange?.Min}
                valueMax={formik.values?.SquareFeetRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                disabled={isPending}
                testidMin="square_min"
                testidMax="square_max"
              />
              <LotSizeMinMax
                isReset
                className={styles.field}
                label="Lot Size"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(val) =>
                  formik.setFieldValue('LotSizeRange', {
                    ...flatValue('LotSizeRange'),
                    Min: val,
                  })
                }
                onChangeMax={(val) =>
                  formik.setFieldValue('LotSizeRange', {
                    ...flatValue('LotSizeRange'),
                    Max: val,
                  })
                }
                valueMin={formik.values?.LotSizeRange?.Min}
                valueMax={formik.values?.LotSizeRange?.Max}
                disabled={isPending}
                testidMin="lot_size_min"
                testidMax="lot_size_max"
              />
              <YearMinMax
                isReset
                className={styles.field}
                label="Year Build"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(val) =>
                  formik.setFieldValue('YearBuiltRange', {
                    ...flatValue('YearBuiltRange'),
                    Min: val,
                  })
                }
                onChangeMax={(val) =>
                  formik.setFieldValue('YearBuiltRange', {
                    ...flatValue('YearBuiltRange'),
                    Max: val,
                  })
                }
                valueMin={formik.values?.YearBuiltRange?.Min}
                valueMax={formik.values?.YearBuiltRange?.Max}
                disabled={isPending}
                testidMin="year_min"
                testidMax="year_max"
              />
              <div className={classNames(styles.pseudo, styles.field)}>
                <Input
                  name="HOARange.Max"
                  className={classNames(styles.field, styles.hoa)}
                  variant={Input.LIGHT_FULL}
                  placeholder="$"
                  isNumericString
                  isNumberFormat
                  label="Maximum HOA"
                  prefix="$"
                  onChange={formik.handleChange}
                  value={formik.values.HOARange.Max}
                  disabled={isPending}
                  testid="max_hoa"
                />
                <p className={styles.pseudoPlaceholder}>Per month</p>
              </div>
              <ParkingSpaces
                className={classNames(styles.parkingSpaces)}
                onChange={(val) => formik.setFieldValue('MinParkingSpaces', val)}
                value={+(formik.values.MinParkingSpaces || 0)}
                disabled={isPending}
              >
                {garageOnly}
              </ParkingSpaces>
              <MinMax
                className={styles.field}
                thousandSeparator={false}
                label="Stories"
                nameMin="StoriesRange.Min"
                nameMax="StoriesRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.StoriesRange?.Min}
                valueMax={formik.values?.StoriesRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                disabled={isPending}
                testidMin="stories_min"
                testidMax="stories_max"
              />
              <Input
                name="MaxDaysOnMarket"
                className={styles.field}
                variant={Input.LIGHT_FULL}
                placeholder="No max"
                isNumericString
                isNumberFormat
                label="Days on Market"
                onChange={formik.handleChange}
                value={formik.values.MaxDaysOnMarket}
                disabled={isPending}
                testid="time_on_market"
              />
            </form>
          </DrawerPaddingWrapper>
        </PendingWrapper>
      </ScrollDrawerWrapper>
      <Footer>
        <div className={styles.resetBlock}>
          <span className={classNames(styles.resetSpan, 'show-cursor')} onClick={onResetHandler}>
            <Icon className={styles.resetIcon} variant={Icon.RESET} />
            <span className={styles.resetAllText}>Reset All</span>
          </span>
        </div>
        <Button
          testid="save_button"
          isPending={isPending}
          form="allSubFeed"
          className={styles.submitButton}
          type="submit"
          title="Save"
          disabled={!formik.dirty}
        />
      </Footer>
    </div>
  );
};

AllSubFeedForm.initialValues = {
  Locations: [],
  HOARange: { Max: '' },
  MinParkingSpaces: 0,
  GarageOnly: false,
  Status: [],
  MaxDaysOnMarket: '',
  HomeType: [],
  PriceRange: {
    Min: '',
    Max: '',
  },
  NumBedroomsRange: {
    Min: '',
    Max: '',
  },
  NumBathroomsRange: {
    Min: '',
    Max: '',
  },
  SquareFeetRange: {
    Min: '',
    Max: '',
  },
  LotSizeRange: {
    Min: '',
    Max: '',
  },
  YearBuiltRange: {
    Min: '',
    Max: '',
  },
  StoriesRange: {
    Min: '',
    Max: '',
  },
};

AllSubFeedForm.propTypes = {
  className: PropTypes.string,
};

AllSubFeedForm.defaultProps = {
  className: '',
};

export default AllSubFeedForm;
