export const isEmail = (value: string) => {
  const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return value.match(reg);
};
/**
 * Create a RegExp pattern based on an array of tag names.
 * @param {string[]} tagNamesArray - Array of tag names.
 * @returns {RegExp} - Regular expression pattern.
 */
export function createTagNamesRegExp(tagNamesArray) {
  const pattern = new RegExp(
    tagNamesArray.map((name) => `(${name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`).join('|'),
    'g',
  );

  return pattern;
}
