import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { EditCircle } from 'components/Icons';
import { EditTimeline } from 'components/Transactions';
import { DateControlInfo } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/Timeline/DateControlInfo';

import styles from '../styles.module.scss';

export const StepTwo = (props) => {
  const {
    timeline,
    onAdd,
    onRemove,
    onFieldsChange,
    timelineTitle,
    onEditClick,
    onSaveTemplate,
    templateId,
    isViewMode,
  } = props;
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    !!timeline?.length && timeline?.every(({ Title }) => !!Title)
      ? setIsDisable(false)
      : setIsDisable(true);
  }, [timeline]);

  return (
    <div className={styles.stepTwo}>
      <div className={styles.stepTwoHeader}>
        <div className={styles.STHLeft}>
          <h3>Details</h3>
          <h4>{timelineTitle}</h4>
        </div>
        <EditCircle className={styles.editIcon} onClick={onEditClick} />
      </div>

      <div className={styles.stTooltip}>
        <h4>Milestones</h4>
        <DateControlInfo isTemplate />
      </div>

      <EditTimeline
        addText="Add milestone"
        listItems={timeline}
        inputPlaceholder="Milestone name"
        classNamesConfig={{
          add: styles.addMilestone,
          timelineItem: styles.milestonePoint,
          timelineName: styles.timelineName,
          inputHolder: styles.inputHolder,
          deadline: styles.deadline,
          icon: styles.icon,
          numberInputClass: styles.numberInput,
        }}
        className={styles.timeline}
        onAdd={onAdd}
        onRemove={onRemove}
        onFieldsChange={onFieldsChange}
        isTemplate
        hideAdd={isViewMode}
        isViewMode={isViewMode}
      />

      <div className={styles.modalFooter}>
        <div className={styles.stepTwoBtn}>
          {!isViewMode && (
            <button
              className={classNames(styles.saveBtn, { [styles.disabled]: isDisable || isViewMode })}
              disabled={isDisable || isViewMode}
              onClick={onSaveTemplate}
            >
              {templateId ? <span>Save</span> : <span>Create</span>}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
