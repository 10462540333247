import React from 'react';
import { Col, Row, Title } from 'components-antd';
import { Documents } from '../Documents';
import { Highlights } from '../Highlights';
import { OfferStatus } from 'types';

import styles from './styles.module.scss';
import { limitTextLength } from 'helpers';

export const InboundIteration = ({ status, offerIteration }) => {
  const { Outbound, Rejected, Withdrawn, Initial, Accepted, Inbound, Expired } = OfferStatus;

  const { Notes } = offerIteration;
  return (
    <div className={styles.inboundIteration}>
      <Row gutter={40}>
        <Col xs={24} lg={24}>
          <Row gutter={40}>
            <Col xs={24} lg={Notes ? 12 : 24} className={styles.marginTop}>
              <Documents data={{ offerIteration }} />
            </Col>

            {Notes ? (
              <Col xs={24} lg={12}>
                <div className={styles.title}>Notes</div>

                <div className={styles.notes}>{limitTextLength(offerIteration.Notes, 100)}</div>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Col>

        {[Outbound, Inbound, Accepted, Rejected, Withdrawn, Initial, Expired].includes(
          offerIteration.status,
        ) && (
          <Col xs={24} lg={24}>
            <Highlights data={offerIteration} />
          </Col>
        )}
      </Row>
    </div>
  );
};
