import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { PENDING } from 'settings/constants/apiState';
import { routes } from 'settings/navigation/routes';
import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';

import { Answers } from 'components';
import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';

import styles from './styles.module.scss';

const ApproveBuyingProcess = ({ onNext, stageIndex, Controls }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);

  const propName = OnBoardingWizard.propNames.approveBuyingProcess;
  const storedBuyingProcess =
    getWizardStageValue({
      obj: onBoarding,
      stageIndex,
      propName,
      signUpRole,
      parentPropName: 'PreApproval',
    }) || [];
  const [stageValue, setStageValue] = useState(storedBuyingProcess);

  const onSave = (val) => {
    const cfg = {
      stageIndex,
      PreApproval: {
        [propName]: val,
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, { withoutRequest: true }, (err) => {
        if (!err) {
          onNext();
        }
      }),
    );
  };

  const onAnswer = (val) => {
    setStageValue(val);
  };

  const isPending = onBoarding.state === PENDING;

  return (
    <OnBoardingWrapper testid="approve_process_stage" isPending={isPending}>
      <Controls
        onNext={() => onSave(stageValue)}
        isNextDisabled={!stageValue.length}
        onClose={() => history.push(routes.mySearches)}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question>Where are you in the home-buying process?</Question>
        <AnswersContainer>
          <Answers
            cases={ApproveBuyingProcess.answerCases}
            onChange={(val) => onAnswer(val)}
            value={stageValue}
            pending={isPending}
            testid="approve_process_answers"
            variant={Answers.LIGHT}
            caseClassName={styles.answer}
            activeCaseClassName={styles.answerActive}
            labelClassName={styles.label}
            className={styles.answersWrapper}
          />
        </AnswersContainer>
        <ButtonsContainer>
          <OnboardingContinueButton
            testid="approve_process_next"
            onClick={() => onSave(stageValue)}
            isPending={isPending}
            disabled={!stageValue.length}
            iconArrow
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

ApproveBuyingProcess.answerCases = [
  { id: 'FoundHome', label: 'Found a home' },
  { id: 'BuyInOneMonth', label: 'Looking to buy in 1 month' },
  { id: 'BuyInOneThreeMonths', label: 'Looking to buy in 1-3 months' },
  { id: 'BuyInThreePlusMonths', label: 'Looking to buy in 3+ months' },
  { id: 'ExploringOptions', label: 'Exploring my options' },
];

ApproveBuyingProcess.propTypes = {
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

ApproveBuyingProcess.defaultProps = {
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default ApproveBuyingProcess;
