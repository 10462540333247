import styles from './styles.module.scss';
import { FC } from 'react';
import { IParking, IParkingComponent } from '../types';
import { Switch } from 'components-antd';
import { Counter } from 'components';
import { RVParking } from '../../constants';

const ParkingComponent: FC<IParkingComponent> = ({
  color,
  value,
  setValue,
  rvValue,
  rvOnChange,
}) => {
  const handleChange = (val, key) => {
    setValue((prev: IParking) => ({ ...prev, [key]: val }));
  };

  const handleRvChange = (bool) => {
    const newValue = [...rvValue];
    const index = newValue.findIndex((val) => val === RVParking);
    if (index === -1 && bool) {
      newValue.push(RVParking);
    } else {
      newValue.splice(index, 1);
    }
    rvOnChange(newValue);
  };

  return (
    <div className={styles.itemsWrapper}>
      <div className={styles.item}>
        <div className={styles.row}>
          <p className={styles.label}>Parking for</p>
          <Counter
            limit={{ min: 0 }}
            addClassName={styles.add}
            removeClassName={styles.remove}
            numberClassName={styles.number}
            onChange={(val) => {
              handleChange(val, 'parkingSpaces');
            }}
            value={value?.parkingSpaces}
            AddComponent={(props) => (
              <svg
                width="28"
                height="28"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
              >
                <circle cx="28" cy="28" r="28" fill="white" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M29.5 18C29.5 17.1716 28.8284 16.5 28 16.5C27.1716 16.5 26.5 17.1716 26.5 18V26.5H18C17.1716 26.5 16.5 27.1716 16.5 28C16.5 28.8284 17.1716 29.5 18 29.5H26.5V38C26.5 38.8284 27.1716 39.5 28 39.5C28.8284 39.5 29.5 38.8284 29.5 38V29.5H38C38.8284 29.5 39.5 28.8284 39.5 28C39.5 27.1716 38.8284 26.5 38 26.5H29.5V18Z"
                  fill="#ADADAD"
                />
              </svg>
            )}
            RemoveComponent={(props) => (
              <svg
                width="28"
                height="28"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
              >
                <circle cx="28" cy="28" r="28" fill="white" />
                <path
                  d="M18 28L38 28"
                  stroke="#ADADAD"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          />
          <p className={styles.label}>Vehicles</p>
        </div>
        <div className={styles.row}>
          <p className={styles.title}>Garage Parking</p>
          <Switch
            checked={value?.garageOnly}
            size="small"
            className={value?.garageOnly ? styles[color] : ''}
            onChange={(val) => {
              handleChange(val, 'garageOnly');
            }}
          />
        </div>
        {value?.garageOnly && (
          <div className={styles.row}>
            <p className={styles.title}>Attached Garage</p>
            <Switch
              checked={value?.attachedGarage}
              size="small"
              className={value?.attachedGarage ? styles[color] : ''}
              onChange={(val) => {
                handleChange(val, 'attachedGarage');
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.item}>
        <div className={styles.row}>
          <p className={styles.title}>RV Parking</p>
          <Switch
            checked={rvValue.includes(RVParking)}
            size="small"
            className={rvValue.includes(RVParking) ? styles[color] : ''}
            onChange={(val) => {
              handleRvChange(val);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ParkingComponent;
