import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import Option from '../Option';
import Label from '../Label';

import styles from './styles.module.scss';

const Docusign = (props) => {
  const { className } = props;

  const onClick = () => {};

  return (
    <Option
      onClick={onClick}
      testid="docusign_option"
      className={classNames(styles.docusign, className)}
    >
      <Icon className={styles.icon} variant={Icon.DOCUSIGN} />
      <Label>Docusign</Label>
    </Option>
  );
};

Docusign.propTypes = {
  className: PropTypes.string,
};

Docusign.defaultProps = {
  className: '',
};

export default Docusign;
