import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { map, omit, values as lodashValues, keys } from 'lodash-es';
import classNames from 'classnames';

import { useOutsideClick } from 'hooks';

import styles from './styles.module.scss';

const DropdownSelect = ({ items, multiple, classNameItems, initValues, onChangeValues }) => {
  const [values, setValues] = useState(initValues);
  const [isOpen, setOpen] = useState(null);
  const toggleOpen = () => setOpen((val) => !val);
  const actionsRef = useRef();

  useEffect(() => {
    if (isOpen === false) {
      onChangeValues(keys(values));
    }
  }, [isOpen, values, onChangeValues]);

  useOutsideClick(actionsRef, () => setOpen(false));

  const onChange = useCallback(
    (val, title) => () => {
      if (values[val]) {
        setValues(omit(values, [val]));
        return;
      }

      if (multiple) {
        setValues({ ...values, [val]: title });
      } else {
        setValues({ [val]: title });
      }
    },
    [values, multiple],
  );

  const btnLabel = useMemo(() => {
    const list = lodashValues(values);
    if (!list.length) {
      return 'No selected';
    }
    return `${list[0]}${list.length > 1 ? ` +${list.length - 1} more` : ''}`;
  }, [values]);

  return (
    <div ref={actionsRef} className={styles.wrapper}>
      <div onClick={toggleOpen} className={styles.openBtn}>
        <span>{btnLabel}</span>
        <div className={styles.openBtnIcon}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 6.5L8 10L11.5 6.5"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div data-open={isOpen} className={classNames(styles.itemsList, classNameItems)}>
        {map(items, ({ title, value }, index) => (
          <div
            onClick={onChange(value, title)}
            className={styles.item}
            key={index}
            data-selected={!!values[value]}
          >
            <span>{title}</span>
            <div className={styles.itemIcon}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.33203 8.0013L6.66536 11.3346L13.332 4.66797"
                  stroke="#FF576D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

DropdownSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  multiple: PropTypes.bool,
  classNameItems: PropTypes.string,
  initValues: PropTypes.shape({}),
  onChangeValues: PropTypes.func.isRequired,
};

DropdownSelect.defaultProps = {
  multiple: false,
  classNameItems: '',
  initValues: {},
};

export default DropdownSelect;
