import { routes } from 'settings/navigation/routes';
import { Workshop } from 'pages';
import transactions from './transactions';
import showings from './showings';
import forms from './forms';
import offers from './offers';
import tasks from './tasks';
import activity from './activity';
import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';
import milestones from './milestones';
import projects from './projects';

export default [
  {
    path: routes.workshop,
    component: Workshop,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  ...forms,
  ...transactions,
  ...showings,
  ...offers,
  ...activity,
  ...tasks,
  ...milestones,
  ...projects,
];
