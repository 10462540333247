/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import classNames from 'classnames';

import InfoItem from '../InfoItem';
import BioInfo from '../../BioInfo';
import LinksInfo from '../../LinksInfo';
import AddressInfo from '../../AddressInfo';
import { TransactionAccess } from '../TransactionAccess';

import styles from './styles.module.scss';

const Agent = (props) => {
  const { className, info } = props;
  const { Bio, Brokerage, AreasOfOperation, Address } = info || {};

  return (
    <div className={classNames(styles.agent, className)}>
      <AddressInfo value={Address} />
      {Brokerage && <InfoItem label="Brokerage" value={Brokerage?.Name} />}
      {!!AreasOfOperation?.length && (
        <InfoItem
          className={styles.areasOfOperation}
          label="Areas of Operation"
          value={AreasOfOperation.map((area, index) => (
            <div className={styles.placeName} key={index}>
              {area?.PlaceName}
            </div>
          ))}
        />
      )}
      <LinksInfo value={info?.Websites} />
      <BioInfo value={Bio} />
      <TransactionAccess />
    </div>
  );
};

Agent.propTypes = {
  className: PropTypes.string,
  info: PropTypes.shape({
    Brokerage: PropTypes.shape({
      Name: PropTypes.string,
    }),
    AreasOfOperation: PropTypes.arrayOf(PropTypes.shape({})),
    Websites: PropTypes.arrayOf(PropTypes.string),
  }),
};

Agent.defaultProps = {
  className: '',
  info: {},
};

export default Agent;
