import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { useSelector } from 'react-redux';
import { Calendar } from 'components/Icons';
import { Button } from 'components';

import { ArrowRight, Photos, Close as CloseIcon } from 'components/Icons';
import { getFormattedListingDetailData } from 'store/selectors/listingDetail';
import styles from './styles.module.scss';

const renderRightNav = (onClick, disabled, fullScreen, isClient) => (
  <button
    className={
      fullScreen
        ? classNames(styles.navFullScreen, styles.rightNavFullScreen)
        : classNames(styles.nav, { [styles.navFullScreenClient]: isClient }, styles.rightNav)
    }
    disabled={disabled}
    onClick={onClick}
  >
    <ArrowRight className={styles.navIcon} color={fullScreen ? '#fff' : undefined} />
  </button>
);

const renderLeftNav = (onClick, disabled, fullScreen, isClient) => (
  <button
    className={
      fullScreen
        ? classNames(styles.navFullScreen, styles.leftNavFullScreen)
        : classNames(styles.nav, { [styles.navFullScreenClient]: isClient }, styles.leftNav)
    }
    disabled={disabled}
    onClick={onClick}
  >
    <ArrowRight className={styles.navIcon} color={fullScreen ? '#fff' : undefined} />
  </button>
);

const closeNav = (onClick) => (
  <button className={classNames(styles.closeNav, styles.close)} onClick={onClick}>
    <CloseIcon className={styles.closeIcon} color="#fff" />
  </button>
);

const PhotosView = ({ photos, imageId, onScheduleTour, isTourScheduled, isFetchingTourStatus }) => {
  const { isClient } = useSelector(getUserRolesMapSelector);
  const [selectedImage, setSelectedImage] = useState(imageId ?? null);
  const [fullScreen, setFullScreen] = useState(false);
  const { activityStatus } = useSelector(getFormattedListingDetailData);
  const isPropertyClosed = useMemo(() => {
    return activityStatus?.trim()?.toLowerCase() === 'closed';
  }, [activityStatus]);

  const nextImg = () => {
    setSelectedImage((prev) => (prev !== 0 ? prev - 1 : prev));
  };

  const prevImg = () => {
    setSelectedImage((prev) => (prev !== photos.length - 1 ? prev + 1 : prev));
  };

  const closeFullScreen = () => {
    setFullScreen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        nextImg();
      }
      if (event.key === 'ArrowRight') {
        prevImg();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div testid="image_gallery" className={styles.container}>
      <div className={styles.leftColumn}>
        {photos.map((photo, index) => (
          <img
            key={index}
            src={photo}
            alt={`Image ${index + 1}`}
            className={selectedImage === index ? styles.activeImage : ''}
            onClick={() => {
              setSelectedImage(index);
            }}
          />
        ))}
      </div>
      <div className={styles.rightColumn}>
        {renderLeftNav(nextImg, selectedImage === 0, fullScreen, isClient)}
        {renderRightNav(prevImg, selectedImage === photos.length - 1, fullScreen, isClient)}
        {fullScreen ? closeNav(closeFullScreen) : null}

        <div className={classNames(styles.counter, { [styles.counterClient]: isClient })}>
          <Photos className={styles.counterIcon} />
          <span testid="count" className={styles.count}>{`${selectedImage + 1} / ${
            photos?.length
          }`}</span>
        </div>
        {fullScreen ? (
          <div className={styles.fullScreen}>
            <img
              src={photos[selectedImage]}
              alt="Full Size Image"
              className={styles.rightColumnImgFullScreen}
            />
          </div>
        ) : (
          <img
            src={photos[selectedImage]}
            alt="Full Size Image"
            className={styles.rightColumnImg}
            onClick={() => {
              setFullScreen(true);
            }}
          />
        )}
        {isClient && !fullScreen && !isPropertyClosed ? (
          <div className={styles.buttonContainer}>
            <div className={styles.buttonWrapper}>
              <Button
                className={classNames(styles.homeActionsButton, styles.lastButton)}
                title={isTourScheduled ? 'Tour Scheduled' : 'Schedule Tour'}
                icon={<Calendar className={styles.buttonIcon} color={'#fff'} />}
                titleClassName={styles.buttonTitle}
                testid="schedule_tour"
                onClick={onScheduleTour}
                disabled={isTourScheduled}
                isPending={isFetchingTourStatus}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

PhotosView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  isTourScheduled: PropTypes.bool.isRequired,
  isFetchingTourStatus: PropTypes.bool,
};

export default PhotosView;
