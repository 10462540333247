import { useSelector } from 'react-redux';

import { getConnectionTypeSelector, isAssignConnectionModalOpened } from 'store/selectors/app';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { AGENT } from 'settings/constants/roles';

import styles from './styles.module.scss';

export const LocalHeader = () => {
  const connectionType = useSelector(getConnectionTypeSelector);
  const { connectionAssignType } = useSelector(isAssignConnectionModalOpened);
  const assignToUser: any = useSelector(getAgentDetailsSelector) || {};

  const getHeading = () => {
    if (connectionType === AGENT_CONNECTIONS.Clients) {
      return `Who should this client be assigned to?`;
    }

    return connectionAssignType === AGENT ? 'Assign Agents' : 'Assign Clients';
  };

  const getSubHeading = () => {
    if (connectionType === AGENT_CONNECTIONS.Clients) {
      return `The client will be assigned to the selected agent(s).`;
    }

    return `Selected ${connectionAssignType === AGENT ? 'agents' : 'clients'} will be assigned to ${
      assignToUser?.data?.FirstName
    }.`;
  };

  return (
    <div className={styles.localHeader}>
      <h1>{getHeading()}</h1>
      <h2>{getSubHeading()}</h2>
    </div>
  );
};
