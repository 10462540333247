import { useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { Table } from 'antd';
import type { TableProps } from 'antd';

import styles from './styles.module.scss';

interface DataType {
  key: string;
  description: string;
  amount: string;
  date: string;
}

export const BillingHistoryTable = ({ data, includeDescriptionHeader = false }) => {
  const tableData = data.map((invoice, index) => {
    return {
      key: `${index + 1}`,
      description: invoice.Reason,
      amount: `$${invoice.Amount}`,
      date: moment(invoice.Date).format('MMMM DD, YYYY'),
    };
  });

  const columns: TableProps<DataType>['columns'] = [
    {
      title: includeDescriptionHeader ? 'Description' : '',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.amount;
          const b = record2.amount;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.date;
          const b = record2.date;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
  ];

  return (
    <div className={classnames(styles.tableContainer)}>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        className={styles.billingHistoryTable}
      />
    </div>
  );
};
