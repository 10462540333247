import { useSelector } from 'react-redux';

import { getDynamicFormSignatureConfigSelector } from 'store/selectors/requestFormProcess';
import { FieldRenderer } from '../../FieldRenderer';

interface DisabledFieldsProps {
  pageIndex: number;
}

export const DisabledFields = ({ pageIndex }: DisabledFieldsProps) => {
  const { disabledQuestions } = useSelector(getDynamicFormSignatureConfigSelector);
  const pageQuestions = disabledQuestions?.[pageIndex];

  if (!pageQuestions?.length) return <></>;

  return (
    <>
      {pageQuestions.map((question) => (
        <FieldRenderer
          question={question}
          key={`active-questions-${question.UUID}`}
          disabled={true}
        />
      ))}
    </>
  );
};
