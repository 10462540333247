import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { Select } from 'components-antd';
import { getAllPartnerTags } from 'api/admin';
import { Checkbox } from 'components';
import { Close } from 'components/Icons';

import styles from './styles.module.scss';

type serviceOption = {
  label: string;
  value: number;
};

export const ServiceInputBox = ({ addService, onClose }) => {
  const [existingServices, setExistingServices] = useState<serviceOption[]>([]);
  const [service, setService] = useState<number | string | string[]>();
  const [isChecked, setIsChecked] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);

  const fetchPartnerServices = async () => {
    const { data, status } = await getAllPartnerTags({
      params: { active: true },
    });
    if (status === 200) {
      setExistingServices(
        data.result.map(({ Id, Name }) => {
          return { label: Name, value: Id };
        }),
      );
    }
  };

  const formatService = (value) => {
    const serviceObj = { label: '', value: value, isChecked: isChecked, isDeletable: true };
    typeof value === 'string'
      ? (serviceObj.label = value)
      : (serviceObj.label = existingServices.filter((el) => el.value === value)[0].label);

    return serviceObj;
  };

  const handleSelect = (value) => {
    setOpenSelect(false);
    addService(formatService(value));
    setService([]);
  };

  useEffect(() => {
    fetchPartnerServices();
  }, []);

  return (
    <div className={styles.ServiceBoxContainer}>
      <Checkbox
        className={classNames(styles.serviceCheckbox, {
          [styles.isChecked]: isChecked,
        })}
        checked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
      />
      <div className={styles.serviceSelector}>
        <Select
          mode="tags"
          large
          showArrow={true}
          options={existingServices}
          getPopupContainer={(triggerNode) => triggerNode}
          dropdownMatchSelectWidth={false}
          className="mosaikConceirgeSelect"
          placement="bottomRight"
          open={openSelect}
          onDropdownVisibleChange={(visible) => setOpenSelect(visible)}
          showSearch
          value={service}
          onSelect={handleSelect}
          autoClearSearchValue
          allowClear
          autoFocus={true}
          filterOption={(input, option) =>
            String((option?.label as string) || '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </div>
      <Close className={styles.closeIcon} color={'#676767'} onClick={onClose} />
    </div>
  );
};
