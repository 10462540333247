import { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import FlatPickr from 'react-flatpickr';
import classNames from 'classnames';
import moment from 'moment';
import { Popover } from 'components-antd';
import { TimelineCalendar } from 'components/Icons';
import { DaysFromToday } from './plugins';

import styles from './styles.module.scss';

import 'flatpickr/dist/themes/material_blue.css';
import './styles.scss';

const DatePicker = (props) => {
  const {
    id,
    name,
    value,
    onChange,
    onDaysFromTodayChange,
    className,
    options,
    label,
    daysFromToday,
    enableDateControls,
    showAsterisk,
    datePickerInputClass,
    datePickerErrorClass,
    autofocus,
    placeholder,
    disabled,
    error,
    variant,
    testid,
    icon,
    format,
    openCalendar,
    popoverCalender,
    scrollToCalendar,
    placement,
    allowTypedInput,
    allowPlugin,
    getPopupContainer,
    debounce = false,
    inputInnerClass = '',
    scrollToBottom = false,
  } = props;
  const fp = useRef(null);
  const buttonRef = useRef();
  useEffect(() => {
    if (fp.current && fp.current.flatpickr) {
      const calendarContainer = fp.current.flatpickr.calendarContainer;
      if (calendarContainer) {
        const daysFromTodayWrapper = calendarContainer.querySelector('.days-from-today-wrapper');
        const inputElement = daysFromTodayWrapper?.querySelector('input[type="text"]');
        if (inputElement) {
          inputElement.value = '';
        }
      }
    }
  }, [fp]);

  const onChangeHandler = useCallback(
    (newDate) => {
      if (debounce) {
        // Clear the previous timeout to avoid multiple setTimeouts
        if (onChangeHandler.timeoutId) {
          clearTimeout(onChangeHandler.timeoutId);
        }

        // Set a new timeout to trigger the debounced onChangeHandler function after 300 milliseconds
        onChangeHandler.timeoutId = setTimeout(() => {
          onChange({ target: { value: newDate, name } }, newDate);
        }, 300);
      } else {
        onChange({ target: { value: newDate, name } }, newDate);
      }
    },
    [name, onChange],
  );

  const fpScrollToView = () => {
    if ((scrollToCalendar && popoverCalender) || scrollToBottom) {
      const calendarContainer = fp.current?.flatpickr?.calendarContainer;
      if (calendarContainer) {
        calendarContainer.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  };

  useEffect(() => {
    if (openCalendar) {
      fp?.current?.flatpickr?.open();
    }
  }, [openCalendar]);

  const onDaysFromTodayChangeHandler = useCallback(
    (newOffset) => {
      onDaysFromTodayChange({ target: { value: newOffset, name } }, newOffset);
    },
    [onDaysFromTodayChange, name],
  );

  const onDatePickerClick = () => {
    popoverCalender && fp?.current?.flatpickr?.open();
  };

  const flatPickrCalender = () => (
    <FlatPickr
      id={id}
      placeholder={placeholder}
      name={name}
      disabled={disabled}
      className={classNames(styles.inputField, datePickerInputClass)}
      value={value}
      onChange={onChangeHandler}
      autoFocus={autofocus}
      ref={fp}
      onOpen={fpScrollToView}
      options={{
        clickOpens: !enableDateControls,
        enableTime: true,
        dateFormat: format,
        mode: 'single',
        disableMobile: true,
        monthSelectorType: 'static',
        allowInput: allowTypedInput,
        parseDate: (dateStr) => {
          return moment(dateStr).toDate();
        },
        plugins: allowPlugin
          ? [
              new DaysFromToday({
                value: daysFromToday,
                onChange: onDaysFromTodayChangeHandler,
                placeholder: '# Days',
              }),
            ]
          : [],
        closeOnSelect: true,
        static: true,
        ...options,
      }}
    />
  );

  return (
    <div
      testid={testid}
      className={classNames(
        styles.datePickerInput,
        styles[variant],
        { [styles.datePickerUnderline]: popoverCalender },
        className,
      )}
      onClick={onDatePickerClick}
      ref={buttonRef}
    >
      {label && (
        <label htmlFor={id}>
          <span>
            {label}
            {showAsterisk ? <span className={styles.required}> *</span> : ''}
          </span>
        </label>
      )}
      <div className={classNames(styles.inputInner, inputInnerClass)}>
        {icon && <TimelineCalendar className={styles.icon} />}
        {popoverCalender ? (
          <Popover
            placement={placement}
            open={true}
            overlayClassName={styles.datePickerPopoverOverlay}
            content={<div className={styles.datePickerPopover}>{flatPickrCalender()}</div>}
            getPopupContainer={getPopupContainer}
          ></Popover>
        ) : (
          flatPickrCalender()
        )}
      </div>
      {error && <div className={classNames(styles.error, datePickerErrorClass)}>{error}</div>}
    </div>
  );
};

DatePicker.LIGHT = 'light';
DatePicker.ROUNDED = 'rounded';

DatePicker.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  daysFromToday: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  ]),
  onChange: PropTypes.func,
  onDaysFromTodayChange: PropTypes.func,
  options: PropTypes.shape({}),
  error: PropTypes.string,
  variant: PropTypes.string,
  testid: PropTypes.string,
  icon: PropTypes.bool,
  enableDateControls: PropTypes.bool,
  showAsterisk: PropTypes.bool,
  datePickerInputClass: PropTypes.string,
  datePickerErrorClass: PropTypes.string,
  autofocus: PropTypes.bool,
  format: PropTypes.string,
  openCalendar: PropTypes.bool,
  popoverCalender: PropTypes.bool,
  scrollToCalendar: PropTypes.bool,
  placement: PropTypes.string,
  allowTypedInput: PropTypes.bool,
  allowPlugin: PropTypes.bool,
  getPopupContainer: PropTypes.func,
  inputInnerClass: PropTypes.string,
  scrollToBottom: PropTypes.bool,
};

DatePicker.defaultProps = {
  id: undefined,
  name: undefined,
  label: undefined,
  className: '',
  value: null,
  onChange: () => {},
  onDaysFromTodayChange: () => {},
  options: {},
  placeholder: 'MM/DD/YY',
  disabled: false,
  error: undefined,
  variant: DatePicker.LIGHT,
  daysFromToday: '',
  testid: undefined,
  icon: true,
  enableDateControls: false,
  showAsterisk: false,
  format: 'F j, Y',
  openCalendar: false,
  popoverCalender: false,
  scrollToCalendar: false,
  placement: '',
  allowTypedInput: true,
  allowPlugin: true,
  debounce: false,
  scrollToBottom: false,
  inputInnerClass: '',
};

export default DatePicker;
