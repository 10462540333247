import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Wrapper as PendingWrapper, PageNotFound } from 'components';
import { routes } from 'settings/navigation/routes';
import styles from './styles.module.scss';

const PageWrapper = (props) => {
  const { className, children, isPending, notFound, unAuthorized } = props;

  return (
    <div className={classNames(styles.pageWrapper, className)}>
      <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
        {notFound ? (
          <PageNotFound info="Transaction not found" backUrl={routes.transactions} />
        ) : unAuthorized ? (
          <PageNotFound
            info="You don’t have access to this transaction."
            backUrl={routes.transactions}
          />
        ) : (
          children
        )}
      </PendingWrapper>
    </div>
  );
};

PageWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isPending: PropTypes.bool,
  notFound: PropTypes.bool,
  unAuthorized: PropTypes.bool,
};

PageWrapper.defaultProps = {
  className: '',
  isPending: false,
  notFound: false,
  unAuthorized: false,
};

export default PageWrapper;
