import styles from './styles.module.scss';
import { FC } from 'react';
import Icons from '../Icons';
import classNames from 'classnames';
import { IHomePrefsListItemProps } from '../types';

const LocationPrefsListItem: FC<IHomePrefsListItemProps> = ({
  color,
  variant,
  label,
  onClick,
  active,
  subtitle = '',
}) => {
  // const handleChange = () => {
  //   setExpanded(keyValue === value ? '' : keyValue);
  // };

  return (
    <div className={classNames(styles.container, { [styles[color]]: active })} onClick={onClick}>
      <div className={classNames(styles.icon, styles[color])}>
        <Icons variant={variant} className={styles.svg} />
      </div>
      <span>
        <p className={styles.label}>{label}</p>
        <p className={styles.subtitle}>{subtitle}</p>
      </span>
    </div>
  );
};

export default LocationPrefsListItem;
