import { MutableRefObject } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_QUESTION_META, FORM_QUESTION_TYPE, SMART_FORM_QUESTION_TYPE } from 'app-constants';
import { QuestionType } from 'types';
import { Row, Col, Cascader } from 'components-antd';
import { SmartQuestionList } from './SmartQuestionList';
import QuestionIcon from 'pages/FormBuilder/Icons/QuestionIcon';
import { getQuoteFormMetaSelect, getSmartFormMetaSelect } from 'store/selectors/formBuilder';
import { updateQuoteFormMetaEffect, updateSmartFormMetaEffect } from 'store/effects/formBuilder';

import styles from './styles.module.scss';

interface QuestionListPopoverProps {
  handleVisibility: (bool: boolean) => void;
  smartForm?: boolean;
  open?: boolean;
}

export const QuestionListPopover = ({
  handleVisibility,
  smartForm = false,
  open = true,
}: QuestionListPopoverProps) => {
  const questionsTypes = Object.values(smartForm ? SMART_FORM_QUESTION_TYPE : FORM_QUESTION_TYPE);
  const dispatch = useDispatch();
  const { questions } = smartForm
    ? useSelector(getSmartFormMetaSelect)
    : useSelector(getQuoteFormMetaSelect);

  const addNewQuestion = (type, fieldType?) => {
    const newQuestion: QuestionType = {
      Type: type,
      JumpLogic: undefined,
      PopulateWith: undefined,
      Meta: DEFAULT_QUESTION_META[type],
      Name: `Q ${questions.length + 1}`,
      Order: questions.length + 1,
      Required: false,
      Title: type === SMART_FORM_QUESTION_TYPE.Signature ? 'signature question' : '',
      UUID: uuidv4(),
    };
    if (smartForm) {
      newQuestion.Fields = [];
      newQuestion.FieldType = fieldType;
      newQuestion.Name = `${newQuestion.FieldType}-${
        questions.filter((el) => el.FieldType === newQuestion.FieldType).length + 1
      }`;
      delete newQuestion.Meta;
      newQuestion.RolePermissions = [];

      dispatch(
        updateSmartFormMetaEffect({
          questions: [...questions, newQuestion],
          activeQuestionIndex: questions.length,
        }),
      );
    } else {
      dispatch(
        updateQuoteFormMetaEffect({
          questions: [...questions, newQuestion],
          activeQuestionIndex: questions.length,
        }),
      );
    }
    handleVisibility(false);
  };

  return (
    <div
      className={classNames(
        { [styles.questionListContainer]: !smartForm },
        { [styles.smartQuestionListContainer]: smartForm },
      )}
      data-testid={`${smartForm ? 'smart' : 'quote'}-new-question-popover`}
    >
      <Row>
        {smartForm ? (
          <Cascader
            open={open}
            className={styles.cascaderQuestionList}
            getPopupContainer={(el) => el}
            placeholder=""
            value={[]}
            searchValue={''}
            options={SmartQuestionList}
            onChange={(options) => {
              if (options.length === 2 && options[1]) addNewQuestion(options[1], options[0]);
            }}
          />
        ) : (
          <>
            {questionsTypes.map((el, index) => (
              <Col span={12} key={`${el}`}>
                <div
                  className={classNames(
                    styles.questionListColumn,
                    'prevent-user-select',
                    'show-cursor',
                  )}
                  data-testid={`quote-new-${index}-popover`}
                  onClick={() => addNewQuestion(el)}
                >
                  <QuestionIcon variant={el} className={styles.questionLogo} />
                  <span className={styles.questionName}>{el}</span>
                </div>
              </Col>
            ))}
            <Col span={12}>
              <div
                className={classNames(
                  styles.questionListColumn,
                  styles.questionDisabled,
                  'prevent-user-select',
                )}
              >
                <QuestionIcon
                  variant={'GroupedQuestions'}
                  className={classNames(styles.questionLogo, styles.disabledLogo)}
                />
                <span className={styles.questionName}>Grouped Questions</span>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
