import React from 'react';
import styles from './styles.module.scss';

export const NoMlsAccessClientFiller: React.FC<{}> = () => {
  return (
    <div className={styles.noMlsAccessWrapper}>
      <div className={styles.noMlsText}>
        <p>
          Hang tight! We are setting up your agent&apos;s connection to the MLS. You&apos;ll be able
          to search for and see properties shortly.
        </p>
      </div>
    </div>
  );
};
