import { getAllMlsAgents } from 'api/admin/mlsAgents';
import Api from 'store/effects/core/api';
import {
  requestGetMlsAgentsDashboardAction,
  searchMlsAgentsDashboardAction,
  sortMlsAgentsDashboardAction,
} from 'store/actions/adminPanel';
import { getState } from 'store/index';

export const getAllMlsAgentsDashboardEffect = (cfg, options, cb) => {
  const {
    adminPanel: {
      mlsAgents: { filters, sort },
    },
  } = getState();
  const requestParams = {
    action: requestGetMlsAgentsDashboardAction,
    method: getAllMlsAgents,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(
    {
      ...filters,
      status: filters?.status ? filters.status : undefined,
      agentId: cfg?.agentId ? cfg.agentId : undefined,
      sortField: sort?.fields?.[0] || undefined,
      sortOrder: sort?.order?.toLowerCase() || undefined,
    },
    options,
    cb,
  );
};

export const sortMlsAgentsEffect = (cfg) => (dispatch) => {
  dispatch(sortMlsAgentsDashboardAction(cfg));
  return dispatch(getAllMlsAgentsDashboardEffect());
};
export const searchMlsAgentsEffect = (cfg) => (dispatch) => {
  dispatch(searchMlsAgentsDashboardAction(cfg));
  return dispatch(getAllMlsAgentsDashboardEffect(cfg));
};
