import { cloneDeep, get } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { appLogoutAction } from 'store/actions/app';
import {
  setTransactionMessagesStatePerTransactionAction,
  setTransactionMessageParamsAction,
  resetTransactionMessagesAction,
} from 'store/actions/transactions';
import { IDLE } from 'settings/constants/apiState';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  statePerTransaction: {},
  isNewMessage: false,
  isAddParticipants: false,
  isViewContacts: false,
  participants: null,
};

export default handleActions(
  {
    [setTransactionMessagesStatePerTransactionAction]: (state, { payload }) => {
      const transactionId = get(payload, 'transactionId');
      const threadId = get(payload, 'threadId');
      return {
        ...state,
        statePerTransaction: {
          ...state.statePerTransaction,
          [transactionId]: {
            ...state.statePerTransaction[transactionId],
            lastThreadId: threadId,
          },
        },
      };
    },
    [setTransactionMessageParamsAction]: (state, { payload }) => ({
      ...state,
      isNewMessage: get(payload, 'isNewMessage', initialData.isNewMessage),
      isAddParticipants: get(payload, 'isAddParticipants', initialData.isAddParticipants),
      isViewContacts: get(payload, 'isViewContacts', initialData.isViewContacts),
      participants: get(payload, 'participants', initialData.participants),
    }),
    [resetTransactionMessagesAction]: (state) => ({
      ...cloneDeep(initialData),
      statePerTransaction: state.statePerTransaction,
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
