import { useEffect, useRef, useState } from 'react';
import { Row, Col, Checkbox } from 'components-antd';
import { Input } from 'antd';

import { EditAvailability, EditNotify, EditDocuments } from '../components';
import { Navigation } from '../../components';

import styles from './styles.module.scss';
import { Icons } from '../../Icons';

const { TextArea } = Input;

export const Notes = ({
  onNext,
  stageIndex,
  onPrev,
  onUpdate,
  onFinish,
  setCurrentStageIndex,
  data,
  transaction,
  stagesStep,
}) => {
  const {
    DisplayBookingPageNotes,
    BookingPageNotes,
    SendAppointmentConfirmationNotes,
    AppointmentConfirmationNotes,
  } = data;
  const [state, setState] = useState({
    DisplayBookingPageNotes,
    BookingPageNotes,
    SendAppointmentConfirmationNotes,
    AppointmentConfirmationNotes,
  });

  const onChange = (data) => {
    setState({ ...state, ...data });
  };

  const onSubmit = () => {
    const body = {
      ...state,
      BookingPageNotes: state.DisplayBookingPageNotes ? state.BookingPageNotes : null,
      AppointmentConfirmationNotes: state.SendAppointmentConfirmationNotes
        ? state.AppointmentConfirmationNotes
        : null,
    };
    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(stagesStep.EnableLink);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const isDisabled = () => {
    return (
      (state.DisplayBookingPageNotes && !state.BookingPageNotes) ||
      (state.SendAppointmentConfirmationNotes && !state.AppointmentConfirmationNotes)
    );
  };

  const bottomRef: any = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [stageIndex]);

  return (
    <div className={styles.notes}>
      <div className={styles.contentWrapper}>
        <EditAvailability
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.Availability)}
        />
        <EditNotify data={data} onEdit={() => setCurrentStageIndex(stagesStep.Notify)} />
        <EditDocuments data={data} onEdit={() => setCurrentStageIndex(stagesStep.Documents)} />

        <div ref={bottomRef} className={styles.content}>
          <h4 className={styles.title}>
            <Icons className={styles.notesIcon} variant="notes" /> Notes
          </h4>
          <div className={styles.checkboxesWrapper}>
            <Row className={styles.container}>
              <Col span={24} className={styles.checkBox}>
                <Checkbox
                  checked={state.DisplayBookingPageNotes}
                  onChange={(e) => onChange({ DisplayBookingPageNotes: e.target.checked })}
                  defaultChecked={state.DisplayBookingPageNotes}
                  className="mosaikCheckbox"
                >
                  <h5>Display note on booking page</h5>
                  <p>Anyone with the link can see this.</p>
                </Checkbox>
              </Col>
              {state.DisplayBookingPageNotes ? (
                <TextArea
                  rows={3}
                  maxLength={250}
                  className={styles.textArea}
                  placeholder="Enter a note"
                  onChange={(e) => onChange({ BookingPageNotes: e.target.value })}
                  defaultValue={state.BookingPageNotes}
                />
              ) : null}
            </Row>
            <Row className={styles.container}>
              <Col span={24} className={styles.checkBox}>
                <Checkbox
                  checked={state.SendAppointmentConfirmationNotes}
                  onChange={(e) => onChange({ SendAppointmentConfirmationNotes: e.target.checked })}
                  defaultChecked={state.SendAppointmentConfirmationNotes}
                  className="mosaikCheckbox"
                >
                  <h5>Send note upon appointment confirmation</h5>
                  <p>This will be sent only to those who submit offers.</p>
                </Checkbox>
              </Col>
              {state.SendAppointmentConfirmationNotes ? (
                <TextArea
                  rows={3}
                  maxLength={250}
                  className={styles.textArea}
                  placeholder="Enter a note"
                  onChange={(e) => onChange({ AppointmentConfirmationNotes: e.target.value })}
                  defaultValue={state.AppointmentConfirmationNotes}
                />
              ) : null}
            </Row>
          </div>
        </div>
      </div>
      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onPrev={onPrev}
        onFinish={onFinish}
        disabled={isDisabled()}
        className={styles.footer}
        showBackButton={false}
      />
    </div>
  );
};
