import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { PropertyImage } from 'components/Icons';

import styles from './styles.module.scss';

const ImgSlider = ({ images, onClick, className }) => (
  <div
    testid="property_image"
    onClick={onClick}
    className={classNames(styles.sliderWrapper, className)}
  >
    {images?.[0] ? (
      <img loading={'lazy'} src={images[0]} alt="" />
    ) : (
      <PropertyImage className={styles.propertyImage} />
    )}
  </div>
);

ImgSlider.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
};

ImgSlider.defaultProps = {
  className: '',
  onClick: () => {},
};

export default ImgSlider;
