import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from 'components';
import Name from '../Name';
import styles from './styles.module.scss';

const Partner = (props) => {
  const { className, businessName, logoUrl, action, onClick } = props;

  function getFirstLetters(str) {
    const firstLetters = str
      .split(' ')
      .slice(0, 2)
      .map((word) => word.charAt(0))
      .join('');
    return firstLetters;
  }

  return (
    <div testid="partner_item" className={classNames(styles.partner, className)}>
      <div className={styles.left}>
        <Avatar
          className={styles.avatarWrapper}
          avatarClassName={styles.avatar}
          placeholder={getFirstLetters(businessName)}
          onClick={onClick}
        />
        <Name testid="partner_name" className={styles.businessName} onClick={onClick}>
          {businessName}
        </Name>
      </div>
      {action}
    </div>
  );
};

Partner.propTypes = {
  className: PropTypes.string,
  businessName: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  action: PropTypes.node,
  onClick: PropTypes.func,
};

Partner.defaultProps = {
  className: '',
  action: undefined,
  onClick: () => {},
};

export default Partner;
