import ReactDOM from 'react-dom';
import styles from './styles.module.scss';

const Icon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#FF576D" />
      <path
        d="M15.333 16H32.6663C33.7709 16 34.6663 16.8954 34.6663 18V30C34.6663 31.1046 33.7709 32 32.6663 32H15.333C14.2284 32 13.333 31.1046 13.333 30V18C13.333 16.8954 14.2284 16 15.333 16Z"
        stroke="#F4F5F6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 21L34 21" stroke="#F4F5F6" strokeWidth="2" />
    </svg>
  );
};

const FailedPaymentToast = ({ closeToast, cta }) => {
  return (
    <div className={styles.toastContainer}>
      <div className={styles.iconWrapper}>
        <Icon />
      </div>
      <div>
        <span className={styles.toastTitle}>Your recent payment failed.</span>{' '}
        <span className={styles.toastMessage}>
          Please update your payment information or you will lose access to your account in 10 days.
        </span>
        <button className={styles.toastButton} onClick={cta}>
          Update Payment Method
        </button>
      </div>
    </div>
  );
};

export const showFailedPaymentToast = (props: { onCTAClick?: () => void }) => {
  const { onCTAClick } = props;
  const id = `payment-failed-toast`;
  const existingToast = document.getElementById(id);
  if (existingToast) {
    return;
  }

  const toastRoot = document.createElement('div');
  toastRoot.id = id;
  document.body.appendChild(toastRoot);

  const closeToast = () => {
    ReactDOM.unmountComponentAtNode(toastRoot);
    document.body.removeChild(toastRoot);
  };

  ReactDOM.render(<FailedPaymentToast closeToast={closeToast} cta={onCTAClick} />, toastRoot);
};
