import { createAction } from 'redux-actions';
export const requestGetTeamsDashboardAction = createAction('REQUEST/GET_TEAMS_DASHBOARD');
export const setSelectedTeamName = createAction('SET_SELECTED_TEAM_NAME');
export const requestGetTeamDetailsByIdAction = createAction('REQUEST/GET_TEAM_DETAILS_BY_ID');
export const fetchTeamSubscriptionDetailsAction = createAction('REQUEST/FETCH_TEAM_SUBSCRIPTION');
export const requestUpdateTeamSubscriptionAction = createAction('REQUEST/UPDATE_TEAM_SUBSCRIPTION');
export const requestGetTeamAndOwnerDetailsAction = createAction(
  'REQUEST/GET_TEAM_AND_OWNER_DETAILS',
);
export const requestGetTeamMembersAction = createAction('REQUEST/GET_TEAM_MEMBERS');

export const requestDeactivateTeamSubscriptionAction = createAction(
  'REQUEST/DEACTIVATE_TEAM_SUBSCRIPTION_ACTION',
);
export const requestReactivateTeamSubscriptionAction = createAction(
  'REQUEST/REACTIVATE_TEAM_SUBSCRIPTION_ACTION',
);

export const requestGetTeamSeatsLogByTeamIdAction = createAction(
  'REQUEST/GET_TEAM_SEATS_LOG_BY_TEAM_ID',
);
export const setMembersSearchAction = createAction('SET_MEMBERS_SEARCH');

export const updateAgentProfileByAdminAction = createAction(
  'REQUEST/UPDATE_AGENT_PROFILE_BY_ADMIN',
);
export const updateAgentActiveStatusByAdminAction = createAction(
  'REQUEST/UPDATE_AGENT_ACTIVE_STATUS_BY_ADMIN',
);
export const updateAgentRoleByAdminAction = createAction('REQUEST/UPDATE_AGENT_ROLE_BY_ADMIN');
export const requestGetLoginLogsAction = createAction('REQUEST/GET_LOGIN_LOGS');
