import { Title, Modal, ModalProps } from 'components-antd';
import Trash from 'pages/FormBuilder/Icons/Trash';

import styles from './styles.module.scss';

interface DeleteModalProps extends ModalProps {
  entityId?: number;
  entityName?: string;
  titleText?: string | any;
}

export const DeleteModal = ({ entityName, titleText, ...props }: DeleteModalProps) => (
  <Modal
    data-testid="delete-modal"
    className={styles.deleteModal}
    title={<Trash className={styles.deleteIcon} />}
    okText="Delete"
    {...props}
  >
    <Title level={4}>{titleText || `Are you sure you want to delete ${entityName}?`} </Title>
  </Modal>
);
