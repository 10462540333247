import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Cross } from 'components/Icons';

import styles from './styles.module.scss';

const Close = ({ className, onClose, label, headerClassName }) => (
  <div
    className={classNames(styles.closeWrap, headerClassName)}
    testid="modal_close"
    onClick={onClose}
  >
    <p>{label && label}</p>
    <Cross color="#262626" className={classNames(styles.close, className)} />
  </div>
);

Close.propTypes = {
  headerClassName: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func,
};

Close.defaultProps = {
  className: '',
  headerClassName: '',
  onClose: () => {},
};

export default Close;
