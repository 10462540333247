import { Typography } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { OfferStatus } from 'types';
import { Check, Cross } from 'components/Icons';

import styles from './styles.module.scss';

const { Title } = Typography;

export const OfferPortalBreadCrumbs = ({ offer }: any) => {
  const { OfferIteration } = offer;
  const [status, setStatus] = useState([] as any);
  const [isViewed, setIsViewed] = useState(false);
  const [width, setWidth] = useState<any | null>(undefined);
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    if (offer && OfferIteration) {
      const viewedStatus = OfferIteration[OfferIteration.length - 1];
      if (viewedStatus !== undefined) {
        viewedStatus?.IsViewed ? setIsViewed(true) : setIsViewed(false);
      }
      setStatus(OfferIteration);
    }
    let elem = document.querySelectorAll('.progress');
    let wrapWidth = document!.querySelector('#progressWrapInner')!.clientWidth;
    let w = elem[0].clientWidth / 2 + elem[elem.length - 1].clientWidth / 2;
    setWidth(wrapWidth - w - 40);
    document.documentElement.style.cssText = `--steps: ${elem.length}`;
  });

  const getTitle = (
    status: OfferStatus,
    iterationStatus: OfferStatus,
    count: number,
    offerId: number,
  ) => {
    if (status == 'Inbound' && iterationStatus == 'Pending') {
      return `Counter #${count} Received`;
    } else if (status === OfferStatus.Outbound && iterationStatus === OfferStatus.Pending) {
      return `Counter #${count} Sent`;
    } else if (status !== OfferStatus.Initial && iterationStatus === OfferStatus.Accepted) {
      return `Counter #${count} Accepted`;
    } else if (status !== OfferStatus.Initial && iterationStatus === OfferStatus.Withdrawn) {
      return `Counter #${count} Withdrawn`;
    } else if (status !== OfferStatus.Initial && iterationStatus === OfferStatus.Rejected) {
      return `Counter #${count} Rejected`;
    } else if (status === OfferStatus.Initial && iterationStatus === OfferStatus.Rejected) {
      return `Offer Rejected`; //{offerId} was being used initially
    }
  };

  useEffect(() => {
    OfferIteration?.at(0)?.IterationStatus == 'Accepted'
      ? setIsAccepted(true)
      : setIsAccepted(false);
  }, [OfferIteration]);

  return (
    <div className={styles.progressWrap}>
      <div
        id="progressWrapInner"
        className={classNames(
          styles.progressWrapInner,
          { [styles.progressNew]: !isViewed },
          { [styles.truncated]: status.length > 4 },
          { [styles.accepted]: isAccepted },
        )}
      >
        <span className={styles.gradientBar} style={{ width: width }}></span>
        {(status.length > 4 ? status.slice(0, 2) : status).map((item, index) => {
          const title = getTitle(
            item.status,
            item.IterationStatus,
            item.IterationCount,
            item.OfferId,
          );
          if (title) {
            return (
              <div className={classNames(styles.progress, 'progress')} key={index}>
                {item.IterationStatus === OfferStatus.Withdrawn ||
                item.IterationStatus === OfferStatus.Rejected ? (
                  <Cross className={classNames(styles.iconCheck, styles.iconCross)} color="white" />
                ) : (
                  <Check className={styles.iconCheck} color="white" />
                )}
                <Title level={5}>{title}</Title>
              </div>
            );
          }
        })}
        <div className={classNames(styles.progress, 'progress')}>
          <Check className={styles.iconCheck} color="white" />
          <Title level={5}>Viewed</Title>
        </div>
        <div className={classNames(styles.progress, 'progress')}>
          <Check className={styles.iconCheck} color="white" />
          <Title level={5}>Submitted</Title>
        </div>
      </div>
    </div>
  );
};
