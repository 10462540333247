export const DoubleLeftArrow = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_53460_110828)">
      <path
        d="M9 6L5 10L9 14"
        stroke="#AAABAB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 6L11 10L15 14"
        stroke="#AAABAB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_53460_110828">
        <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)" />
      </clipPath>
    </defs>
  </svg>
);
