import { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { emailExistsEffect, signUpEffect } from 'store/effects/user';
import { PENDING } from 'settings/constants/apiState';
import { getUserSelector } from 'store/selectors/user';
import { Input, FormTitle, Terms, FormGroup } from 'components';
import { link } from 'settings/navigation/link';

import LoginLink from '../LoginLink';

import { step1ValidationSchema, step2ValidationSchema } from './validation';

import styles from './styles.module.scss';
import { OnboardingSkipButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingSkipButton';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';
import { Role, signUpRouteRoles } from 'settings/constants/roles';
import { getAgentTeamDetailEffect } from 'store/effects';
import { requestConvertToTeamPlanEffect } from 'store/effects/subscription';
import { showErrorMessage } from 'helpers';

const SIGN_UP_STEP = {
  EMAIL_AND_NAME: 'emailAndName',
  PASSWORD: 'password', // pragma: allowlist secret
};

const Form = () => {
  const user = useSelector(getUserSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [stepId, setStepId] = useState(SIGN_UP_STEP.EMAIL_AND_NAME);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (values) => {
    dispatch(
      signUpEffect({ ...values, role: params.role }, {}, (err) => {
        if (!err) {
          convertToTeamPlan();
          history.push(link.toOnBoarding(params.role));
        }
      }),
    );
  };

  const convertToTeamPlan = () => {
    if (params.role === signUpRouteRoles.AGENT) {
      dispatch(
        requestConvertToTeamPlanEffect({}, (err) => {
          if (!err) {
            dispatch(getAgentTeamDetailEffect());
          } else {
            showErrorMessage(err);
          }
        }),
      );
    }
  };

  const formik = useFormik({
    initialValues: { email: '', password: '', confirmPassword: '', firstName: '', lastName: '' },
    validationSchema:
      stepId === SIGN_UP_STEP.EMAIL_AND_NAME ? step1ValidationSchema : step2ValidationSchema,
    validateOnChange: true,
    onSubmit,
  });

  const isPending = user.state === PENDING;

  const getScreenTitle = () => {
    return stepId === SIGN_UP_STEP.EMAIL_AND_NAME ? 'Create an account' : 'Choose a Password';
  };

  const verifyNameAndEmail = async (e) => {
    e.stopPropagation();
    setIsLoading(true);

    const errors = await formik.validateForm();

    formik.setTouched({
      email: true,
      firstName: true,
      lastName: true,
    });

    if (!Object.keys(errors).length) {
      dispatch(
        emailExistsEffect(
          {
            params: {
              email: formik.values.email,
            },
          },
          (err, resp) => {
            setIsLoading(false);
            if (resp?.data?.exists) {
              formik.setErrors({
                email: 'Email already exists',
              });
            } else if (!err) {
              setStepId(SIGN_UP_STEP.PASSWORD);
            }
          },
        ),
      );
    } else {
      setIsLoading(false);
    }
  };

  return (
    <FormGroup className={styles.form} testid="create_account">
      <h2 className={styles.title}>{getScreenTitle()}</h2>
      <form onSubmit={formik.handleSubmit}>
        {stepId === SIGN_UP_STEP.EMAIL_AND_NAME && (
          <>
            <div className={styles.inputsRow}>
              <Input
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                placeholder="First Name"
                error={formik.touched.firstName ? formik.errors.firstName : ''}
                disabled={isPending}
                testid="firstName"
                variant={Input.LIGHT}
                className={styles.inputWrapper}
                replacePlaceholderWithLabelWhenHasValue
              />
              <Input
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                placeholder="Last Name"
                error={formik.touched.lastName ? formik.errors.lastName : ''}
                disabled={isPending}
                testid="lastName"
                variant={Input.LIGHT}
                className={styles.inputWrapper}
                replacePlaceholderWithLabelWhenHasValue
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder="Email Address"
                error={formik.touched.email ? formik.errors.email : ''}
                disabled={isPending}
                testid="email"
                variant={Input.LIGHT}
                className={styles.inputWrapper}
                replacePlaceholderWithLabelWhenHasValue
              />
            </div>
          </>
        )}
        {stepId === SIGN_UP_STEP.PASSWORD && (
          <div className={styles.signupPassword}>
            <Input
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              placeholder="Password"
              error={formik.touched.password ? formik.errors.password : ''}
              disabled={isPending}
              testid="password"
              variant={Input.LIGHT}
              className={styles.inputWrapper}
              replacePlaceholderWithLabelWhenHasValue
            />
            <Input
              type="password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              placeholder="Confirm Password"
              error={formik.touched.confirmPassword ? formik.errors.confirmPassword : ''}
              disabled={isPending}
              testid="confirm_password"
              variant={Input.LIGHT}
              className={styles.inputWrapper}
              replacePlaceholderWithLabelWhenHasValue
            />
          </div>
        )}
        {stepId === SIGN_UP_STEP.EMAIL_AND_NAME && <Terms className={styles.terms} />}
        {stepId === SIGN_UP_STEP.EMAIL_AND_NAME && (
          <OnboardingContinueButton
            testid="sign_up_next"
            onClick={verifyNameAndEmail}
            isPending={isPending || isLoading}
            hideArrow
            title="Sign Up"
            className={styles.signupBtn}
          />
        )}
        {stepId === SIGN_UP_STEP.PASSWORD && (
          <div className={styles.signUpButtonsRow}>
            <OnboardingContinueButton
              testid="sign_up_back"
              onClick={async () => {
                formik.submitForm();
              }}
              title="Continue"
              isPending={isPending}
              className={styles.continueButton}
              hideArrow={true}
            />
            <OnboardingSkipButton
              testid="sign_up_back"
              onClick={async () => {
                setStepId(SIGN_UP_STEP.EMAIL_AND_NAME);
              }}
              title="Back"
              className={styles.backButton}
            />
          </div>
        )}
        {stepId === SIGN_UP_STEP.EMAIL_AND_NAME && <LoginLink className={styles.loginLink} />}
      </form>
    </FormGroup>
  );
};

export default Form;
