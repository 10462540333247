import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTransactionSelector } from 'store/selectors/transaction';
import { TransactionUserRole } from 'settings/constants/transaction';
import { TransactionParticipantType } from 'types';
import { ClientAddress, TransactionCloseComponentIds } from 'app-constants';
import Question from 'pages/Workshop/Transactions/TransactionCreate/components/Question';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';
import { Space } from 'components-antd';
import styles from './styles.module.scss';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { ClientAddressContainer } from './ClientAddressContainer';

interface ClientProfileAddAddressProps {
  previousStage?: TransactionCloseComponentIds;
  setStage: (stage: TransactionCloseComponentIds) => void;
  latestClientAddressAdder: Function;
}

export const ClientProfileAddAddress = ({
  previousStage,
  setStage,
  latestClientAddressAdder,
}: ClientProfileAddAddressProps) => {
  const [runUpdates, setRunUpdates] = useState<boolean>(false);
  const [clients, setClients] = useState<TransactionParticipantType[] | undefined>();
  const { transaction, address, clientAddress } = useSelector(getTransactionSelector);
  const [updatedCount, setUpdatedCount] = useState<number>(0);
  const formatAddress = (address) =>
    `${address.Line1}, ${address.City}, ${address.State} ${address.Zip}`;

  const checkIfAddressExists = (clientId: number): Boolean => {
    const client = clientAddress.data.find((client) => client.Id === clientId);
    return !!client?.Address?.find(
      (clientAddress: ClientAddress) => clientAddress.address === formatAddress(address),
    );
  };

  useEffect(() => {
    setClients(
      transaction.Participants.filter(
        (participant) =>
          participant.IsClient &&
          participant.Role.Title === TransactionUserRole.Buyer &&
          !checkIfAddressExists(participant.Id),
      ) || [],
    );
  }, []);

  useEffect(() => {
    if (clients && !clients?.length) {
      if (previousStage === TransactionCloseComponentIds.ClientProfileRemoveAddress) {
        setStage(TransactionCloseComponentIds.CloseConfirmation);
      } else if (previousStage === TransactionCloseComponentIds.CloseConfirmation) {
        setStage(TransactionCloseComponentIds.ClientProfileRemoveAddress);
      }
    }
  }, [clients]);

  useEffect(() => {
    if (updatedCount === clients?.length) setStage(TransactionCloseComponentIds.CloseConfirmation);
  }, [updatedCount]);

  const incrementUpdatedCount = useCallback(() => {
    setUpdatedCount((prevCount) => prevCount + 1);
  }, []);

  const onSubmit = () => {
    setRunUpdates(true);
  };

  return (
    <div className={styles.ClientProfileAddAddress}>
      <Question>
        Please confirm the address(es) that
        <br />
        should be on file going forward.
      </Question>
      <AnswersContainer>
        <Space className={styles.spacing} size="middle" direction="vertical">
          {clients?.map((client) => {
            return (
              <ClientAddressContainer
                ClientId={client.Id}
                FName={client.FirstName}
                LName={client.LastName}
                AvatarUrl={client.AvatarUrl}
                ClientAddress={address}
                Update={runUpdates}
                setCount={() => incrementUpdatedCount()}
                latestClientAddressAdder={latestClientAddressAdder}
              />
            );
          })}
        </Space>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onSubmit} className={styles.submit} />
      </ButtonsContainer>
    </div>
  );
};
