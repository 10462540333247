import {
  FORM_COMMENT_AUTH,
  FORM_COMMENT_ANON,
  FORM_COMMENT_THREAD_RESOLVE_AUTH,
  FORM_COMMENT_THREAD_RESOLVE_ANON,
  FORM_COMMENT_USERS_AUTH,
  FORM_COMMENT_USERS_ANON,
} from 'app-constants';
import { apiServer } from 'settings/web-services/api';

export const getFormCommentUsersAuth = ({ formProcessPublicId, formDocumentPublicId }) =>
  apiServer.get(FORM_COMMENT_USERS_AUTH(formProcessPublicId, formDocumentPublicId));

export const getFormCommentsAuth = ({ formProcessPublicId, formDocumentPublicId }) =>
  apiServer.get(FORM_COMMENT_AUTH(formProcessPublicId, formDocumentPublicId));

export const addFormCommentAuth = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.post(FORM_COMMENT_AUTH(formProcessPublicId, formDocumentPublicId), cfg);

export const editFormCommentAuth = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.patch(FORM_COMMENT_AUTH(formProcessPublicId, formDocumentPublicId), cfg);

export const deleteFormCommentAuth = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.delete(FORM_COMMENT_AUTH(formProcessPublicId, formDocumentPublicId), { params: cfg });

export const toggleResolveThreadAuth = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.patch(FORM_COMMENT_THREAD_RESOLVE_AUTH(formProcessPublicId, formDocumentPublicId), cfg);

export const getFormCommentUsersAnon = ({ type, token }) =>
  apiServer.get(FORM_COMMENT_USERS_ANON(type, token));

export const getFormCommentsAnon = ({ type, token }) =>
  apiServer.get(FORM_COMMENT_ANON(type, token));

export const addFormCommentAnon = ({ type, token, ...cfg }) =>
  apiServer.post(FORM_COMMENT_ANON(type, token), cfg);

export const editFormCommentAnon = ({ type, token, ...cfg }) =>
  apiServer.patch(FORM_COMMENT_ANON(type, token), cfg);

export const deleteFormCommentAnon = ({ type, token, ...cfg }) =>
  apiServer.delete(FORM_COMMENT_ANON(type, token), { params: cfg });

export const toggleResolveThreadAnon = ({ type, token, ...cfg }) =>
  apiServer.patch(FORM_COMMENT_THREAD_RESOLVE_ANON(type, token), cfg);
