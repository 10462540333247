import { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Icons } from 'pages/Workshop/Icons';
import { Close, MultiSelect } from 'components/Icons';
import { ArchiveViewType } from 'types';
import { getUserRoleSelector, getUserRolesMapSelector } from 'store/selectors/user';
import { FormSelectFilter } from './FormSelectFilter';
import { Role } from 'settings/constants/roles';

import { Filter } from '../Filter';
import { SearchFieldWithDebounce } from 'components';
import { Row, Col } from 'antd';

import styles from './styles.module.scss';
import { updateFormsFiltersAction } from 'store/actions/formProcess';
import { getFormsFiltersSelector } from 'store/selectors/requestFormProcess';

interface LocalHeaderProps {
  onNew: (value: boolean) => void;
  onNewForm: () => void;
  isArchive: boolean;
  archiveView: ArchiveViewType;
  setArchive: (isArchive: boolean) => void;
  setArchiveView: (archiveView: ArchiveViewType) => void;
  setShowDraftModal: (open: boolean) => void;
  showSelection: boolean;
  setShowSelection: (open: boolean) => void;
}

export const LocalHeader = ({
  isArchive,
  setArchive,
  onNewForm,
  showSelection,
  setShowSelection,
}: LocalHeaderProps) => {
  const [isMobile, setIsMobile] = useState(false);

  const { isAgent } = useSelector(getUserRolesMapSelector);

  const userRole = useSelector(getUserRoleSelector);
  const { search: searchedText, ...restFilters } = useSelector(getFormsFiltersSelector);

  useEffect(() => {
    function handleWindowSizeChange() {
      if (window.innerWidth <= 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    if (document.readyState === 'complete') {
      handleWindowSizeChange();
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.addEventListener('load', handleWindowSizeChange);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isMobile]);

  const getNewButton = (onClick?) => (
    <div
      className={classNames(styles.btn, styles.newBtn, { [styles.btnText]: !isMobile })}
      onClick={onClick}
    >
      <Icons className={styles.addIcon} variant={Icons.ADD} color={'#FFF'} />
      {isMobile ? '' : <span>New</span>}
    </div>
  );

  const renderFormActions = () => {
    if (!isAgent) return null;

    if (isArchive) return <Filter isArchive={isArchive} />;

    return (
      <Fragment>
        {getNewButton(() => onNewForm())}
        <Filter isArchive={isArchive} />
      </Fragment>
    );
  };

  return (
    <div className={styles.workshopHeader}>
      <Row className={styles.workshopActionsContainer} align="middle" justify="space-between">
        <Col className={styles.workshopHeaderLeft} xs={24} lg={16}>
          <div className={styles.searchWrapper}>
            <SearchFieldWithDebounce
              value={searchedText}
              className={styles.inputWrap}
              classNameInput={styles.searchInput}
              iconClassName={styles.icon}
              resetAction={() => updateFormsFiltersAction({ ...restFilters, search: undefined })}
              sendAction={({ search }) => updateFormsFiltersAction({ ...restFilters, search })}
              placeholder="Search"
              testid="search"
              isTransactionAggregate={true}
              isExpandedVersion
              otherSearchParams={restFilters}
            />
          </div>
          <div className="seperator" />
          <div className={styles.formsFilterHeader}>
            <FormSelectFilter />
            {userRole === Role.Agent && (
              <div
                className={classNames(styles.multiSelectBtn, { [styles.selected]: showSelection })}
                onClick={() => setShowSelection(true)}
              >
                <MultiSelect />
              </div>
            )}
          </div>
        </Col>

        <Col xs={24} lg={8}>
          <div className={styles.actionsRight}>
            {showSelection ? (
              <Close className={styles.multiSelectBtn} onClick={() => setShowSelection(false)} />
            ) : (
              <>
                <div className="seperator" />
                {renderFormActions()}
                <div
                  className={classNames(styles.btn, { [styles.selected]: isArchive })}
                  onClick={() => setArchive(!isArchive)}
                >
                  <Icons variant={Icons.ARCHIVE} color={isArchive ? '#FFFFFF' : ''} />
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
