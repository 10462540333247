import styles from './styles.module.scss';

export const Notes = (props) => {
  const { BookingPageNotes } = props;
  return (
    <div>
      <div className={styles.detailsArea}>
        <p className={styles.title}>Notes: </p>
        <p className={styles.note}>{BookingPageNotes}</p>
      </div>
    </div>
  );
};
