import { createAction } from 'redux-actions';

export const saveNewAreaGroupAction = createAction('REQUEST/ADMIN_PANEL/SAVE_NEW_AREA_GROUP');
export const updateAreaGroupAction = createAction('REQUEST/ADMIN_PANEL/UPDATE_AREA_GROUP');
export const deleteAreaGroupAction = createAction('REQUEST/ADMIN_PANEL/DELETE_AREA_GROUP');
export const getAreaGroupsAction = createAction('REQUEST/ADMIN_PANEL/GET_AREA_GROUPS');
export const getAreaGroupDetailsAction = createAction('REQUEST/ADMIN_PANEL/GET_AREA_GROUP_DETAILS');

export const updateAreaGroupsSearchTermAction = createAction(
  'ADMIN_PANEL/AREA_CLASSIFIER/UPDATE_AREA_GROUPS_SEARCH_TERM',
);
export const addNewAreaGroupAction = createAction('ADMIN_PANEL/AREA_CLASSIFIER/ADD_NEW_AREA_GROUP');
export const cancelNewAreaGroupAction = createAction(
  'ADMIN_PANEL/AREA_CLASSIFIER/CANCEL_NEW_AREA_GROUP',
);

export const updateEditableAreaNameAction = createAction(
  'ADMIN_PANEL/AREA_CLASSIFIER/UPDATE_AREA_NAME',
);
export const updateAreaTypeAction = createAction('ADMIN_PANEL/AREA_CLASSIFIER/UPDATE_AREA_TYPE');
export const addNewAreaToEditAction = createAction(
  'ADMIN_PANEL/AREA_CLASSIFIER/ADD_NEW_AREA_TO_EDIT',
);
export const deleteEditableAreaAction = createAction(
  'ADMIN_PANEL/AREA_CLASSIFIER/DELETE_EDITABLE_AREA',
);
export const startDrawingNewShapeAction = createAction(
  'ADMIN_PANEL/AREA_CLASSIFIER/START_DRAWING_NEW_SHAPE',
);
export const cancelDrawingNewShapeAction = createAction(
  'ADMIN_PANEL/AREA_CLASSIFIER/CANCEL_DRAWING_NEW_SHAPE',
);
export const newShapeDrawnAction = createAction('ADMIN_PANEL/AREA_CLASSIFIER/NEW_SHAPE_DRAWN');

export const setEditModeForSelectedAreaGroupAction = createAction(
  'ADMIN_PANEL/AREA_CLASSIFIER/SET_EDIT_MODE_FOR_SELECTED_AREA_GROUP',
);
