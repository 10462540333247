import { v4 as uuidv4 } from 'uuid';
import ReactCursorPosition from 'react-cursor-position';
import classNames from 'classnames';

import { LineColor, StrikeThroughLine } from 'types';
import { PageSizeType } from '../PDFViewer';
import { RenderPoint } from './RenderPoint';

import styles from './styles.module.scss';

interface RenderStrikeThroughProps {
  page: number;
  scale: number;
  pageSize: PageSizeType;
  strikeThroughLines?: StrikeThroughLine[];

  color?: LineColor;
  strokeWidth?: number;
  updatePoints?: (strikeThroughLines: StrikeThroughLine[]) => void;
}

export const RenderStrikeThrough = ({
  page,
  scale,
  pageSize,
  strikeThroughLines = [],
  strokeWidth = 2,
  color = { r: 0, g: 0, b: 0, a: 1 },
  updatePoints,
}: RenderStrikeThroughProps) => {
  const editMode = !!updatePoints;

  const handleDrawAreaUpdate = (e) => {
    if (!updatePoints) return;
    var rect = e.currentTarget.getBoundingClientRect();

    var x = (e.clientX - rect.left) / scale;
    var y = (e.clientY - rect.top) / scale;

    const newPoints = [...strikeThroughLines] as StrikeThroughLine[];

    if (newPoints.length) {
      const lastPoint = newPoints.pop();
      if (lastPoint?.start && !lastPoint?.end) {
        lastPoint.end = {
          x,
          y: lastPoint.start.y,
        };
        newPoints.push(lastPoint);
      } else if (lastPoint?.start && lastPoint?.end) {
        newPoints.push(lastPoint);
        newPoints.push({
          start: {
            x,
            y,
          },
          color,
          strokeWidth,
          id: uuidv4(),
        });
      }
    } else {
      newPoints.push({
        start: { x, y },
        color,
        strokeWidth,
        id: uuidv4(),
      });
    }

    updatePoints([...newPoints]);
  };
  const handleDelete = (id) => {
    if (!updatePoints) return;
    const newPoints = [...strikeThroughLines].filter((line) => line.id !== id);
    updatePoints(newPoints);
  };

  return (
    <div
      className={classNames({ [styles.editMode]: editMode })}
      style={{
        ...pageSize,
      }}
    >
      <ReactCursorPosition className={styles.drawArea}>
        <RenderPoints
          strikeThroughLines={strikeThroughLines}
          scale={scale}
          page={page}
          handleDrawAreaUpdate={handleDrawAreaUpdate}
          editMode={editMode}
          handleDelete={handleDelete}
        />
      </ReactCursorPosition>
    </div>
  );
};

const RenderPoints = ({
  strikeThroughLines,
  position = undefined,
  scale,
  page,
  handleDrawAreaUpdate,
  editMode,
  handleDelete,
}) => {
  return (
    <svg className={styles.polyLineContainer} onClick={editMode ? handleDrawAreaUpdate : undefined}>
      {strikeThroughLines.map((point, index) => (
        <RenderPoint
          {...point}
          scale={scale}
          key={`page-${page}-${index}-line`}
          position={position}
          handleDelete={handleDelete}
          editMode={editMode}
        />
      ))}
    </svg>
  );
};
