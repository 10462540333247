import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getClientFavoritePropsSelector, getFeedPageInfoSelector } from 'store/selectors/feed';
import { PENDING } from 'settings/constants/apiState';
import { getClientFavoritesPropsEffect } from 'store/effects';
import { Wrapper as PendingWrapper } from 'components';
import { PropertiesWrapper, PropertiesList } from 'pages/Properties/Feed/components';
import { useNoMlsAccessHandler } from '../../hooks/useNoMlsAccessHandler';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { ListSkeleton } from 'components-antd';

import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';

const Favorites = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const favProps = useSelector(getClientFavoritePropsSelector);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const currentPageInfo = useSelector(getFeedPageInfoSelector);

  const { hasMlsAccess, wrappedEffect: getClientFavoritesPropsEffectWrapped } =
    useNoMlsAccessHandler(getClientFavoritesPropsEffect);

  useEffect(() => {
    if (
      isAgent &&
      (!currentPageInfo?.currentFEPageInfo?.fromListingDetailPage || !favProps?.data?.length)
    )
      dispatch(getClientFavoritesPropsEffectWrapped());
  }, []);

  const isPending = favProps.state === PENDING;

  return (
    <PropertiesWrapper>
      {(wrapperSize, getNextPageIsPending) => (
        <PendingWrapper
          isPending={isPending}
          className={styles.pendingWrapper}
          customLoader={<ListSkeleton />}
        >
          <PropertiesList
            list={favProps?.data}
            wrapperSize={wrapperSize}
            getNextPageIsPending={getNextPageIsPending}
            hasMlsAccess={hasMlsAccess}
          />
        </PendingWrapper>
      )}
    </PropertiesWrapper>
  );
};

export default Favorites;
