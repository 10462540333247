import { PageWrapper } from 'components-antd';
import { FolderListSidebar, PageHeader, SearchBar, FormTable } from 'pages/FormBuilder';

export const FormList = () => (
  <PageWrapper SiderComponent={FolderListSidebar}>
    <PageHeader />
    <SearchBar />
    <FormTable />
  </PageWrapper>
);
