import {
  requestSearchInstancesAction,
  requestDeactivateSearchInstanceAction,
  requestActivateSearchInstanceAction,
  requestDeleteSearchInstanceAction,
  requestRenameSearchInstanceAction,
  resetEditedSearchInstanceAction,
  requestUpdateSearchInstanceAction,
  requestRenameSavedSearchAction,
  requestDeleteSavedSearchAction,
  requestActivateSavedSearchAction,
  requestDeactivateSavedSearchAction,
  requestPreApprovalDocumentsAction,
  requestPreApprovalRequestsAction,
} from 'store/actions/mySearches';
import { requestUpdateClientsSearchInstanceAction } from 'store/actions/clientSearches';
import { getState } from 'store';

import Api from 'store/effects/core/api';
import {
  getSearchInstances,
  deactivateSearchInstance,
  activateSearchInstance,
  deleteSearchInstance,
  renameSearchInstance,
  updateSearchInstance,
} from 'api/user';
import {
  renameSavedSearch,
  deleteSavedSearch,
  activateSavedSearch,
  deactivateSavedSearch,
  getPreApprovalDocuments,
  deletePreApprovalDocument,
} from 'api/mySearches';
import { cleanSearchQueryObj } from '../search/helpers';
import { getQuotes } from 'api/quotes';
import { requestNullAction } from 'store/actions/app';

export const getSearchInstancesEffect = (cfg, options, cb) => {
  const requestParams = { action: requestSearchInstancesAction, method: getSearchInstances };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }
  return sendRequest({ contextKey: cfg.contextKey }, options, cb);
};

export const deactivateSearchInstanceEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: requestDeactivateSearchInstanceAction,
    method: deactivateSearchInstance,
  });

  return sendRequest(cfg, options, cb);
};

export const renameSearchInstanceEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: requestNullAction,
    method: renameSearchInstance,
  });

  return sendRequest(cfg, options, cb);
};

export const activateSearchInstanceEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: requestNullAction,
    method: activateSearchInstance,
  });

  return sendRequest(cfg, options, cb);
};

export const deleteSearchInstanceEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: requestDeleteSearchInstanceAction,
    method: deleteSearchInstance,
  });

  return sendRequest(cfg, options, cb);
};

export const updateSearchInstanceEffect = (cfg, options, cb) => {
  const {
    search,
    searchResults: {
      filterDrawer: { mode, isClientSearch },
    },
  } = getState();
  const data = search?.edited?.data;
  const sendRequest = Api.execBase({
    action: requestUpdateClientsSearchInstanceAction,
    method: updateSearchInstance,
  });

  if (!cfg.Locations?.length) {
    cfg.Locations = data?.criterias?.Locations?.filter((loc) => !loc.ProviderPlaceId);
  }

  const { id, Name, ...otherConfig } = cfg;
  const config = {
    id,
    Name: cfg.Name,
    DefaultPropertySearchPreferences: cleanSearchQueryObj(otherConfig),
  };

  return sendRequest(config, options, cb);
};

export const resetEditedSearchInstanceEffect = () => (dispatch) => {
  dispatch(resetEditedSearchInstanceAction());
};

export const renameSavedSearchEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: requestRenameSavedSearchAction,
    method: renameSavedSearch,
  });

  return sendRequest(cfg, options, cb);
};

export const deleteSavedSearchEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: requestDeleteSavedSearchAction,
    method: deleteSavedSearch,
  });

  return sendRequest(cfg, options, cb);
};

export const activateSavedSearchEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: requestActivateSavedSearchAction,
    method: activateSavedSearch,
  });

  return sendRequest(cfg, options, cb);
};

export const deactivateSavedSearchEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: requestDeactivateSavedSearchAction,
    method: deactivateSavedSearch,
  });

  return sendRequest(cfg, options, cb);
};

export const getPreApprovalDocumentsEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestPreApprovalDocumentsAction,
    method: getPreApprovalDocuments,
  });

  return sendRequest(cfg, options, cb);
};

export const deletePreApprovalDocumentEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestPreApprovalDocumentsAction,
    method: deletePreApprovalDocument,
  });

  return sendRequest(cfg, options, cb);
};

export const getPreApprovalRequestsEffect = (cfg, options, cb) => {
  const requestParams = {
    action: requestPreApprovalRequestsAction,
    method: getQuotes,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};
