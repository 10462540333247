function Magnifier({ colorWithHash }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="1">
        <path
          d="M14.7111 23.4222C19.5221 23.4222 23.4222 19.5221 23.4222 14.7111C23.4222 9.9001 19.5221 6 14.7111 6C9.9001 6 6 9.9001 6 14.7111C6 19.5221 9.9001 23.4222 14.7111 23.4222Z"
          stroke={colorWithHash}
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.4 28.3983L20.9333 20.9316"
          stroke={colorWithHash}
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default Magnifier;
