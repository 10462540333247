import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import styles from './styles.module.scss';

const UserHeader = (props) => {
  const { className } = props;
  const { quote } = useSelector(getQuoteDetailsSelector);

  return (
    <div className={classNames(styles.userHeader, className)}>
      <div>
        {quote?.Partner?.LogoUrl && (
          <img className={styles.logo} src={quote?.Partner?.LogoUrl} alt="" />
        )}
      </div>
      <h1 testid="partner" className={styles.title}>
        {quote?.Partner?.BusinessName}
      </h1>
      <div testid="category" className={styles.category}>
        {quote?.Category?.Title}
      </div>
    </div>
  );
};

UserHeader.propTypes = {
  className: PropTypes.string,
};

UserHeader.defaultProps = {
  className: '',
};

export default UserHeader;
