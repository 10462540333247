import React, { memo, useCallback } from 'react';
import { prefsIds } from 'settings/constants/preferences';
import PropTypes from 'prop-types';

const Icon = memo(({ className, variant, color }) => {
  const getIcon = useCallback(() => {
    switch (variant) {
      case Icon.OUTDOOR_SPACE:
        return (
          <svg
            width="45"
            height="44"
            viewBox="0 0 45 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.875 35.75V37.125" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M17.875 4.125V5.5" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path
              d="M1.375 20.625L2.75 20.625"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M6.18751 8.93757L7.15977 9.90977"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M7.18164 31.3203L6.20937 32.2926"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M29.5626 8.9375L28.5902 9.90977"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <mask id="path-7-inside-1_12141_120738" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.0892 21.697C37.4273 21.6372 37.7753 21.606 38.1305 21.606C41.4075 21.606 44.064 24.2625 44.064 27.5395C44.064 30.6346 41.6941 33.1763 38.6699 33.4488V33.4776H24.6453V33.4737H24.6441C21.665 33.4737 19.25 31.0587 19.25 28.0796C19.25 25.41 21.1894 23.1933 23.7361 22.7616C24.4393 19.6315 27.2356 17.293 30.578 17.293C33.529 17.293 36.0543 19.1159 37.0892 21.697Z"
              />
            </mask>
            <path
              d="M37.0892 21.697L35.2328 22.4413L35.8376 23.9497L37.4378 23.6664L37.0892 21.697ZM38.6699 33.4488L38.4904 31.4568L36.6699 31.6209V33.4488H38.6699ZM38.6699 33.4776V35.4776H40.6699V33.4776H38.6699ZM24.6453 33.4776H22.6453V35.4776H24.6453V33.4776ZM24.6453 33.4737H26.6453V31.4737H24.6453V33.4737ZM23.7361 22.7616L24.0704 24.7335L25.3934 24.5093L25.6875 23.2L23.7361 22.7616ZM38.1305 19.606C37.6579 19.606 37.1932 19.6475 36.7406 19.7277L37.4378 23.6664C37.6613 23.6268 37.8927 23.606 38.1305 23.606V19.606ZM46.064 27.5395C46.064 23.1579 42.5121 19.606 38.1305 19.606V23.606C40.3029 23.606 42.064 25.367 42.064 27.5395H46.064ZM38.8494 35.4407C42.8951 35.0761 46.064 31.679 46.064 27.5395H42.064C42.064 29.5902 40.4931 31.2764 38.4904 31.4568L38.8494 35.4407ZM40.6699 33.4776V33.4488H36.6699V33.4776H40.6699ZM24.6453 35.4776H38.6699V31.4776H24.6453V35.4776ZM22.6453 33.4737V33.4776H26.6453V33.4737H22.6453ZM24.6441 35.4737H24.6453V31.4737H24.6441V35.4737ZM17.25 28.0796C17.25 32.1633 20.5604 35.4737 24.6441 35.4737V31.4737C22.7696 31.4737 21.25 29.9541 21.25 28.0796H17.25ZM23.4019 20.7898C19.9094 21.3818 17.25 24.4181 17.25 28.0796H21.25C21.25 26.4018 22.4694 25.0049 24.0704 24.7335L23.4019 20.7898ZM25.6875 23.2C26.19 20.963 28.1914 19.293 30.578 19.293V15.293C26.2797 15.293 22.6886 18.3 21.7848 22.3233L25.6875 23.2ZM30.578 19.293C32.6842 19.293 34.4917 20.5927 35.2328 22.4413L38.9455 20.9527C37.6169 17.639 34.3738 15.293 30.578 15.293V19.293Z"
              fill="#515151"
              mask="url(#path-7-inside-1_12141_120738)"
            />
            <path
              d="M27.3322 18.8356C26.9725 16.9348 26.0485 15.1865 24.6805 13.8187C23.3125 12.4508 21.5641 11.527 19.6632 11.1676C17.7624 10.8082 15.7974 11.0299 14.0244 11.8038C12.2515 12.5777 10.7529 13.8679 9.72403 15.5062C8.69521 17.1444 8.18392 19.0547 8.25684 20.9878C8.32976 22.921 8.98351 24.7873 10.1328 26.3433C11.2822 27.8994 12.8737 29.0731 14.6999 29.7112C16.5261 30.3494 18.5023 30.4224 20.3706 29.9208"
              stroke="#515151"
              strokeWidth="2"
            />
          </svg>
        );
      case Icon.VIEW:
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.125 30.25L7.10417 28.3782C8.96775 27.2073 11.1989 27.2073 13.0625 28.3782C14.9261 29.5491 17.1572 29.5491 19.0208 28.3782C20.8844 27.2073 23.1156 27.2073 24.9792 28.3782C26.8428 29.5491 29.0739 29.5491 30.9375 28.3782C32.8011 27.2073 35.0322 27.2073 36.8958 28.3782L39.875 30.25"
              stroke="#515151"
              strokeWidth="2"
            />
            <path
              d="M31.3995 27.473C31.7096 25.9873 31.6997 24.4472 31.3707 22.9661C31.0417 21.485 30.4018 20.1008 29.4984 18.9157C28.595 17.7305 27.451 16.7746 26.151 16.1185C24.8509 15.4625 23.4279 15.1229 21.9869 15.125C20.5459 15.1271 19.1238 15.4707 17.8254 16.1305C16.527 16.7903 15.3855 17.7495 14.4852 18.9373C13.5848 20.125 12.9485 21.511 12.6233 22.993C12.2981 24.4751 12.2923 26.0153 12.6062 27.5"
              stroke="#515151"
              strokeWidth="2"
            />
            <path
              d="M4.125 37.125V6.875C4.125 6.11561 4.74061 5.5 5.5 5.5H38.5C39.2594 5.5 39.875 6.11561 39.875 6.875V37.125C39.875 37.8844 39.2594 38.5 38.5 38.5H5.5C4.74061 38.5 4.125 37.8844 4.125 37.125Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.MOVE_IN_READY:
        return (
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.27047 64.43L17.7345 50.914M32.8828 30.8946C38.3117 34.6959 39.6311 42.1785 35.8297 47.6073C33.2821 51.2458 29.0809 53.0383 24.9527 52.6807L21.7376 57.2723L18.9521 57.7635L19.4433 60.5489L17.149 63.8255L14.3635 64.3167L14.8547 67.1021L12.4914 70.4772C12.1699 70.9364 11.6703 71.2396 11.1145 71.3129L5.73482 72.0221C4.70642 72.1577 3.74506 71.4846 3.52073 70.4718L2.34724 65.174C2.226 64.6266 2.34005 64.0535 2.6616 63.5943L15.1229 45.7977C13.3748 42.0408 13.6224 37.4799 16.1701 33.8415C19.9714 28.4126 27.454 27.0933 32.8828 30.8946ZM30.0151 34.9902C28.6579 34.0398 26.7872 34.3697 25.8369 35.7269C24.8866 37.0841 25.2164 38.9547 26.5736 39.9051C27.9308 40.8554 29.8015 40.5256 30.7518 39.1683C31.7021 37.8111 31.3723 35.9405 30.0151 34.9902Z"
              stroke="#303030"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M70.3948 37.413L47.0363 31.9548L40.0839 54.913L43.8728 52.7255L51.5291 65.9865L74.2622 52.8615L66.606 39.6005L70.3948 37.413Z"
              stroke="#303030"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              cx="52.4519"
              cy="42.1835"
              r="3"
              transform="rotate(-10 52.4519 42.1835)"
              stroke="#303030"
              strokeWidth="2.5"
            />
            <path
              d="M59.7239 34.0104C61.0969 31.1164 61.6949 27.9155 61.4595 24.721C61.2241 21.5265 60.1633 18.4479 58.3809 15.7864C56.5985 13.1249 54.1556 10.9719 51.2913 9.53793C48.4271 8.10399 45.2395 7.43831 42.0408 7.60605C38.842 7.7738 35.7416 8.76921 33.043 10.4949C30.3444 12.2205 28.1401 14.6173 26.6458 17.4506C25.1516 20.2838 24.4186 23.4566 24.5186 26.6582C24.6186 29.8598 25.5481 32.9806 27.2163 35.7151"
              stroke="#303030"
              strokeWidth="2.5"
            />
            <path
              d="M37.6073 43.7743C41.6344 45.0039 45.9604 44.8229 49.8707 43.2613"
              stroke="#303030"
              strokeWidth="2.5"
            />
            <path
              d="M53.6862 53.4968L57.8514 54.5974L60.8466 46.1696"
              stroke="#303030"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
          </svg>
        );
      case Icon.LARGE_KITCHEN:
        return (
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M22 47H58" stroke="#303030" strokeWidth="2.5" />
            <path d="M17 56H15" stroke="#303030" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M43 55H37" stroke="#303030" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M65 56H63" stroke="#303030" strokeWidth="2.5" strokeLinecap="round" />
            <rect
              x="2"
              y="37"
              width="76"
              height="36"
              stroke="#303030"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M22 38V73" stroke="#303030" strokeWidth="2.5" />
            <path d="M58 38V73" stroke="#303030" strokeWidth="2.5" />
            <rect
              x="26"
              y="51"
              width="28"
              height="16"
              stroke="#303030"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="34" cy="42" r="2" stroke="#303030" strokeWidth="2.5" />
            <circle cx="46" cy="42" r="2" stroke="#303030" strokeWidth="2.5" />
            <path
              d="M72 36V28C72 25.7909 70.2091 24 68 24V24C65.7909 24 64 25.7909 64 28V28.5"
              stroke="#303030"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M37 3V10L22 18V21H58V18L43 10V3"
              stroke="#303030"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.AMENITIES:
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.14258 32.2148C6.91401 32.2148 6.91401 34.572 10.6854 34.572C14.4569 34.572 14.4569 32.2148 18.2283 32.2148C21.9997 32.2148 21.9997 34.572 25.7712 34.572C29.5426 34.572 29.5426 32.2148 33.314 32.2148C37.0854 32.2148 37.0854 34.572 40.8569 34.572"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M3.14258 26.7148C6.91401 26.7148 6.91401 29.072 10.6854 29.072C14.4569 29.072 14.4569 26.7148 18.2283 26.7148C21.9997 26.7148 21.9997 29.072 25.7712 29.072C29.5426 29.072 29.5426 26.7148 33.314 26.7148C37.0854 26.7148 37.0854 29.072 40.8569 29.072"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <circle cx="33.0006" cy="16.7232" r="3.53571" stroke="#515151" strokeWidth="2" />
            <path
              d="M27.4992 19.5867L28.2849 23.7211C27.8658 24.0391 27.0277 24.3571 25.7706 24.3571C22.6277 24.3571 22.3134 21.8129 18.2277 21.8129C14.4563 21.8129 13.1992 24.3571 11.3134 24.3571L20.4277 17.9966L18.2277 15.1344L11.9218 16.3615C10.8982 16.5607 9.91103 15.8792 9.7344 14.8515C9.56373 13.8585 10.2151 12.9102 11.2033 12.7133L18.9203 11.1752C19.465 11.0666 20.0266 11.2536 20.3974 11.6672L27.4992 19.5867Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.HOME_OFFICE:
        return (
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="5" y="38" width="70" height="4" rx="1" stroke="#303030" strokeWidth="2.5" />
            <rect x="53" y="42" width="19" height="8" rx="1" stroke="#303030" strokeWidth="2.5" />
            <rect x="53" y="50" width="19" height="8" rx="1" stroke="#303030" strokeWidth="2.5" />
            <rect x="53" y="58" width="19" height="8" rx="1" stroke="#303030" strokeWidth="2.5" />
            <path d="M72 43V67" stroke="#303030" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M8 43V67" stroke="#303030" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M53 43V67" stroke="#303030" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M60 45H65" stroke="#303030" strokeWidth="2.5" />
            <path d="M60 53H65" stroke="#303030" strokeWidth="2.5" />
            <path d="M60 61H65" stroke="#303030" strokeWidth="2.5" />
            <path
              d="M68 36.5V26.5L63.5 22L61.5 22.5"
              stroke="#303030"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M56.5087 22.8542C57.6387 21.6425 59.537 21.5762 60.7487 22.7062V22.7062C61.9605 23.8361 62.0268 25.7345 60.8968 26.9462L59.1104 28.862L54.7222 24.77L56.5087 22.8542Z"
              stroke="#303030"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect x="23" y="15" width="27" height="17" rx="1" stroke="#303030" strokeWidth="2.5" />
            <path
              d="M34 32C33.9992 34.4317 33.7535 35.7415 33 38"
              stroke="#303030"
              strokeWidth="2.5"
            />
            <path
              d="M38 32C38.0008 34.4317 38.2465 35.7415 39 38"
              stroke="#303030"
              strokeWidth="2.5"
            />
            <path
              d="M12 30L12.6464 34.5251C12.8493 35.9452 14.0655 37 15.5 37C16.9345 37 18.1507 35.9452 18.3536 34.5251L19 30H12Z"
              stroke="#303030"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.9978 26.5011L15.5392 25.9516C14.9372 25.2303 15.0929 24.1462 15.8737 23.6235V23.6235C16.5746 23.1543 16.7841 22.2177 16.3498 21.4946L15.8066 20.5901"
              stroke="#303030"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9997 31.5008C11.9997 31.5008 9 31.0011 9 33.0011C9 35.0011 11.9997 35.0011 11.9997 35.0011"
              stroke="#303030"
              strokeWidth="2.5"
            />
          </svg>
        );
      case Icon.PARKING:
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.3996 21.9977C37.3996 30.5028 30.5048 37.3977 21.9996 37.3977C13.4944 37.3977 6.59961 30.5028 6.59961 21.9977C6.59961 13.4925 13.4944 6.59766 21.9996 6.59766C30.5048 6.59766 37.3996 13.4925 37.3996 21.9977Z"
              stroke="#515151"
              strokeWidth="2"
            />
            <path
              d="M17.5996 30.8008V23.1008M17.5996 23.1008V14.3008H24.1996C26.6297 14.3008 28.5996 16.2707 28.5996 18.7008C28.5996 21.1308 26.6297 23.1008 24.1996 23.1008H17.5996Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.COMMUTES:
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="22" cy="20.168" rx="5.5" ry="5.5" stroke="#515151" strokeWidth="2" />
            <path
              d="M36.6673 20.1667C36.6673 28.979 27.2543 37.203 23.5102 40.1256C22.6125 40.8264 21.3888 40.8264 20.4911 40.1256C16.747 37.203 7.33398 28.979 7.33398 20.1667C7.33398 12.0665 13.9005 5.5 22.0006 5.5C30.1008 5.5 36.6673 12.0665 36.6673 20.1667Z"
              stroke="#515151"
              strokeWidth="2"
            />
          </svg>
        );
      case Icon.PROXIMITY_TO_IMPORTANT_LOCATIONS:
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.786 22.1002C22.786 27.96 16.9257 33.45 14.0829 35.7684C13.1932 36.494 11.9502 36.494 11.0605 35.7684C8.21769 33.45 2.35742 27.96 2.35742 22.1002C2.35742 16.4033 6.93051 11.7852 12.5717 11.7852C18.2129 11.7852 22.786 16.4033 22.786 22.1002Z"
              fill="white"
              stroke="#515151"
              strokeWidth="2"
            />
            <circle cx="12.5711" cy="21.9989" r="3.92857" stroke="#515151" strokeWidth="2" />
            <path
              d="M42.4289 16.6416C42.4289 23.5766 35.3448 30.0635 32.161 32.6287C31.2655 33.3503 30.0208 33.3503 29.1252 32.6287C25.9415 30.0635 18.8574 23.5766 18.8574 16.6416C18.8574 10.0546 24.1341 4.71484 30.6431 4.71484C37.1522 4.71484 42.4289 10.0546 42.4289 16.6416Z"
              fill="white"
              stroke="#515151"
              strokeWidth="2"
            />
            <circle cx="30.642" cy="16.4994" r="4.71429" stroke="#515151" strokeWidth="2" />
          </svg>
        );
      default:
        return null;
    }
  }, [variant, color]);

  return <div className={className}>{getIcon()}</div>;
});

Icon.OUTDOOR_SPACE = prefsIds.outdoorPrefs;
Icon.VIEW = prefsIds.view;
Icon.MOVE_IN_READY = prefsIds.moveInReady;
Icon.LARGE_KITCHEN = prefsIds.largeKitchen;
Icon.AMENITIES = prefsIds.amenities;
Icon.HOME_OFFICE = prefsIds.homeOffice;
Icon.PARKING = prefsIds.parking;
Icon.COMMUTES = prefsIds.commutes;
Icon.PROXIMITY_TO_IMPORTANT_LOCATIONS = prefsIds.proximityToImportantLocations;

Icon.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  variant: undefined,
  color: '',
};

export default Icon;
