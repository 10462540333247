import { createAction } from 'redux-actions';

export const requestSmartFormAction = createAction('REQUEST/GET_SMART_BUILDER_FORM');
export const resetSmartFormAction = createAction('RESET/SMART_BUILDER_FORM');
export const updateSmartFormAction = createAction('UPDATE/SMART_BUILDER_FORM');
export const updateSmartFormQuestionAction = createAction('UPDATE/SMART_BUILDER_FORM_QUESTION');
export const updateSmartFormMetaAction = createAction('UPDATE/SMART_BUILDER_FORM_META');
export const updateSmartFormPreviewResponseAction = createAction(
  'UPDATE/SMART_FORM_PREVIEW_RESPONSE',
);
export const requestUpdateSmartFormAction = createAction('REQUEST/UPDATE_SMART_BUILDER_FORM');
export const requestAddQuestionRoleAction = createAction('REQUEST/ADD_QUESTION_ROLE');
export const requestCopySmartFormVersionAction = createAction('REQUEST/SMART_COPY_VERSION');
export const requestNewSmartFormVersionAction = createAction('REQUEST/SMART_NEW_VERSION');
export const requestFillDocumentPreviewAction = createAction('REQUEST/GET_FILL_DOCUMENT_PREVIEW');
