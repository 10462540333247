import { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';
import { TransactionInviteModal } from 'components';
import { getTransactionSelector, getTransactionAccessSelector } from 'store/selectors/transaction';
import { getUserRoleSelector } from 'store/selectors/user';
import { AGENT } from 'settings/constants/roles';
import { useOutsideClick } from 'hooks';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { WorkshopType } from 'app-constants';
import { ContactModal } from 'pages/Workshop/Transactions/components/ContactsView/ContactModal';
import { Button } from 'pages/Workshop/Transactions/components/Button';

import styles from './styles.module.scss';

const Invite = (props) => {
  const { className } = props;
  const { transaction } = useSelector(getTransactionSelector);
  const [inviteModal, setInviteModal] = useState(false);
  const userRole = useSelector(getUserRoleSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [showModal, setShowModal] = useState(false);
  const buttonRef = useRef(null);
  const optionsRef = useRef(null);
  const [open, setOpen] = useState(false);
  const isProject = useIsProjectRoute();

  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const type = useMemo(() => {
    if (isProject) return WorkshopType.Project;
    return WorkshopType.Transaction;
  }, [isProject]);

  return (
    <>
      {fullAccess && (
        <div className={classNames(styles.add, className)}>
          <div className={styles.buttonContainer}>
            <Button
              variant="secondary"
              title="Add"
              className={styles.btn}
              icon={<Icon className={styles.icon} variant={Icon.PLUS} />}
              onClick={onClickOptions}
              ref={buttonRef}
            />
          </div>
          {open && (
            <div ref={optionsRef} className={styles.options}>
              <ul>
                <li className={styles.item} onClick={() => setShowModal(true)}>
                  <div className={styles.icon}>
                    <Icon variant={Icon.USER} color="#FF576D" />
                  </div>
                  <span>Contact Info Only</span>
                </li>
                {userRole === AGENT && (
                  <li className={styles.item} onClick={() => setInviteModal(true)}>
                    <div className={styles.icon}>
                      <Icon variant={Icon.PLUS} color="#FF576D" />
                    </div>
                    <span>Invite to {type}</span>
                  </li>
                )}
              </ul>
            </div>
          )}
          <ContactModal isOpen={showModal} editData={null} onCloseModal={onCloseModal} />
          <TransactionInviteModal
            open={inviteModal}
            transactionId={transaction?.Id}
            onClose={() => setInviteModal(false)}
          />
        </div>
      )}
    </>
  );
};

Invite.propTypes = {
  className: PropTypes.string,
};

Invite.defaultProps = {
  className: '',
};

export default Invite;
