import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { trim } from 'lodash-es';

import { getMessageTime, getShortedChatParticipantsNames } from 'helpers/formatters';

import { ListingHome, Offer } from 'components/Icons';

import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';

import styles from './styles.module.scss';

const OfferListingMessage = ({
  thread,
  className,
  openHandler,
  withLabel,
  namesTitle,
  userId,
  formatText,
  ...rest
}) => {
  const dateTime = useMemo(
    () => getMessageTime(thread?.LastMessageCreatedDate),
    [thread?.LastMessageCreatedDate],
  );

  const { title, subTitle } = useMemo(() => {
    if (namesTitle) {
      const namesList = [];

      if (thread?.Participants?.length) {
        thread?.Participants.forEach(({ Id, FirstName = '', LastName = '' }) => {
          if (Id !== userId) {
            namesList.push(trim(FirstName + ' ' + LastName)); // eslint-disable-line prefer-template
          }
        });
      }

      return {
        title: getShortedChatParticipantsNames(namesList),
        subTitle:
          (formatText ? formatText(thread?.TopicName) : thread?.TopicName) || 'No thread name',
      };
    }

    return {
      title: (formatText ? formatText(thread?.TopicName) : thread?.TopicName) || 'No thread name',
      subTitle: '',
    };
  }, [namesTitle, userId, thread?.TopicName, thread?.Participants, formatText]);

  const lastMessage = useMemo(() => {
    if (thread?.LastMessage === null || thread?.LastMessage === undefined) {
      return 'No messages...';
    }

    if (thread?.LastMessage === '') {
      return 'Attachment';
    }

    return formatText ? formatText(thread?.LastMessage) : thread?.LastMessage;
  }, [thread?.LastMessage, formatText]);

  const isOffer = thread.Type === SOCKET_THREAD_TYPES.OFFER;
  const isListing = thread.Type === SOCKET_THREAD_TYPES.LISTING;

  return (
    <div
      className={classNames(styles.item, className, {
        [styles.unread]: thread.IsUnread,
        [styles.offer]: isOffer,
      })}
      onClick={openHandler}
      testid="thread_item"
      {...rest}
    >
      <div className={styles.itemImage}>
        {thread.Type === SOCKET_THREAD_TYPES.LISTING && (
          <ListingHome className={styles.itemImageIcon} />
        )}
        {isOffer && <Offer className={styles.itemImageIcon} />}
      </div>
      <div className={styles.itemContent}>
        <div className={styles.itemContentTitle}>
          <div className={styles.itemContentTitleName}>
            <span testid="item_title">{title}</span>
            {withLabel && isListing && (
              <span testid="item_listing" className={styles.listingTitleSpan}>
                Listing
              </span>
            )}
            {isOffer && (
              <span testid="item_offer" className={styles.offerTitleSpan}>
                Offer
              </span>
            )}
            {subTitle && (
              <div testid="item_subtitle" className={styles.itemContentSubTitle}>
                {subTitle}
              </div>
            )}
          </div>
          <div testid="item_date" className={styles.itemContentTitleTime}>
            {dateTime}
          </div>
        </div>
        <div testid="item_message" className={styles.itemContentText}>
          {lastMessage}
        </div>
      </div>
    </div>
  );
};

OfferListingMessage.propTypes = {
  thread: PropTypes.shape({
    Type: PropTypes.string,
    TopicName: PropTypes.string,
    LastMessage: PropTypes.string,
    IsUnread: PropTypes.bool,
    LastMessageCreatedDate: PropTypes.string,
    Participants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  withLabel: PropTypes.bool,
  userId: PropTypes.number,
  namesTitle: PropTypes.bool,
  className: PropTypes.string,
  openHandler: PropTypes.func,
  formatText: PropTypes.func,
};

OfferListingMessage.defaultProps = {
  className: null,
  userId: null,
  withLabel: false,
  namesTitle: false,
  openHandler: null,
  formatText: null,
};

export default OfferListingMessage;
