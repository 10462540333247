import io from 'socket.io-client';

const getDevelopmentSocketLink = () =>
  process.env.REACT_APP_SOCKET_ENDPOINT || 'https://api.dev.mosaikhome.com:3001';
const getProductionSocketLink = () => process.env.REACT_APP_SOCKET_ENDPOINT;

let socket;

const ENDPOINT =
  process.env.NODE_ENV === 'production' ? getProductionSocketLink() : getDevelopmentSocketLink();

export const getSocket = () => socket;

export const clearSocket = () => {
  socket = null;
};

export const connectSocket = ({ accessToken }) => {
  if (!socket) {
    socket = io(ENDPOINT, {
      transports: ['websocket'],
      auth: {
        token: accessToken,
      },
    });
  } else if (socket.disconnected && accessToken && socket.auth.token !== accessToken) {
    // If socket does already exist, but disconnected due to the expired token, try to update it and reconnect
    socket.auth.token = accessToken;
    socket.connect();
  }

  return socket;
};
