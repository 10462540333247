/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { getQuoteForms, getAllPartnerTags } from 'api/admin';
import { TitleWithButtons } from 'components/SettingsGroup';
import { AddPhoto, Button, PageNotFound, Select, Wrapper } from 'components';
import CheckboxComponent from 'components/Form/Checkbox';
import Input from 'components/Form/Input';
import { Option, Select as AntdSelect } from 'components-antd';
import { CheckBox, ArrowDown } from 'components/Icons';
import { ERROR, PENDING, READY } from 'settings/constants/apiState';
import { getCategorySelector, getLoadingCategoryData } from 'store/selectors/adminPanel';
import {
  createCategoryEffect,
  requestGetCategoryByIdEffect,
  updateCategoryEffect,
} from 'store/effects/adminPanel';
import { useBackPage, useRequest } from 'hooks';
import { routes } from 'settings/navigation/routes';

import { typesOptions } from '../../config';
import { ValidationSchema } from './validation';
import { getFormsOptions } from './helpers';
import styles from './styles.module.scss';
import { cloneDeep } from 'lodash-es';

const CategoryForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const category = useSelector(getCategorySelector);
  const { state } = useSelector(getLoadingCategoryData);
  const [onBack] = useBackPage({ defaultBackUrl: routes.dashboardServiceDirectoryCategories });

  const [{ loading, data: formsOptions }] = useRequest({
    request: getQuoteForms,
    format: getFormsOptions,
  });

  const [{ data: serviceTags }] = useRequest({
    request: getAllPartnerTags,
    format: getFormsOptions,
  });

  useEffect(() => {
    if (id) {
      dispatch(requestGetCategoryByIdEffect({ id }));
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const isPending = state === PENDING;
  const isNotFound = state === ERROR && category?.meta?.status === 404;
  const onSubmit = useCallback(
    (values) => {
      if (id) {
        dispatch(
          updateCategoryEffect({ values, id }, (err) => {
            if (!err) {
              history.push(routes.dashboardServiceDirectoryCategories);
            }
          }),
        );
      } else {
        dispatch(
          createCategoryEffect(values, (err) => {
            if (!err) {
              history.push(routes.dashboardServiceDirectoryCategories);
            }
          }),
        );
      }
    },
    [dispatch, id, history],
  );

  const initCategoryData = (category) => {
    if (!category?.data) return;
    const { data } = category;
    return {
      ...data,
      Tags: data.Tags.map((tag) => tag.Id),
    };
  };

  const formik = useFormik({
    initialValues: id
      ? initCategoryData(category) || CategoryForm.initialValues
      : CategoryForm.initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit,
  });

  const onTypeChange = useCallback((e, { value }) => formik.setFieldValue('Type', value), [formik]);
  const onQuoteFormIdChange = useCallback(
    (e, { value }) => formik.setFieldValue('QuoteFormId', value),
    [formik],
  );

  const onServiceTagSelect = (value) => {
    const tags = [...(formik.values.Tags || [])];

    if (tags.includes(value)) {
      return;
    } else if (typeof value === 'string') {
      const newValue = value.trim();
      if (!newValue) return;
      const existing = serviceTags.find((el) => el.name === newValue);

      if (existing) {
        if (!tags.includes(existing.value)) {
          tags.push(existing.value);
        }
      } else {
        tags.push(newValue);
      }
    } else {
      tags.push(value);
    }

    formik.setFieldValue('Tags', tags);
  };

  const onServiceTagDeselect = (value) => {
    const tags = (formik.values.Tags || []).filter((el) => el !== value);
    formik.setFieldValue('Tags', tags);
  };

  const onIconChange = useCallback(
    (image, files) => {
      formik.setFieldValue('Icon', files[0]);
    },
    [formik],
  );

  return (
    <>
      {isNotFound ? (
        <PageNotFound btnLabel="Back to Categories" onClick={onBack} />
      ) : (
        <Wrapper isPending={isPending}>
          <form testid="category_edit" onSubmit={formik.handleSubmit}>
            <TitleWithButtons className={styles.title} title="Category">
              {formik.dirty ? (
                <Button
                  testid="save_button"
                  type="submit"
                  className={styles.saveBtn}
                  title="Save"
                  isPending={isPending}
                />
              ) : (
                <Button
                  testid="save_button"
                  type="button"
                  className={styles.saveBtn}
                  title="Save"
                />
              )}
            </TitleWithButtons>
            <div className={styles.wrapper}>
              <div className={styles.leftBlock}>
                <AddPhoto
                  className={styles.updateAvatar}
                  onChange={onIconChange}
                  value={id ? category?.data?.Icon : ''}
                  binary
                  placeholder={formik.values.Title?.substring(0, 1).toUpperCase()}
                >
                  <span>Update</span>
                  {formik.touched.Icon ? (
                    <span className={styles.error}>{formik.errors.Icon}</span>
                  ) : null}
                </AddPhoto>
              </div>
              <div className={styles.rightBlock}>
                <div className={styles.inputBlock}>
                  <label htmlFor="Title" className={styles.editLabel}>
                    Title
                  </label>
                  <div className={styles.rightBlock}>
                    <Input
                      id="Title"
                      className={styles.input}
                      type="text"
                      name="Title"
                      error={formik.touched.Title ? formik.errors.Title : ''}
                      value={formik.values.Title}
                      onChange={formik.handleChange}
                      disabled={isPending}
                      testid="title_input"
                    />
                  </div>
                </div>
                <div className={styles.inputBlock}>
                  <label htmlFor="Type" className={styles.editLabel}>
                    Type
                  </label>
                  <div className={styles.rightBlock}>
                    <Select
                      id="Type"
                      name="Type"
                      options={typesOptions}
                      value={formik.values.Type}
                      placeholder="Type"
                      onSelect={onTypeChange}
                      disabled={isPending}
                      className={{ wrapper: styles.selectWrapper }}
                      error={formik.touched.Type ? formik.errors.Type : ''}
                      testid="type_select"
                    />
                  </div>
                </div>
                <div className={styles.inputBlock}>
                  <label htmlFor="QuoteFormId" className={styles.editLabel}>
                    Quote Form
                  </label>
                  <div className={styles.rightBlock}>
                    <Select
                      id="QuoteFormId"
                      name="QuoteFormId"
                      search
                      options={
                        loading || !formsOptions
                          ? [
                              {
                                name: 'Loading...',
                                value: '',
                              },
                            ]
                          : formsOptions
                      }
                      value={formik.values.QuoteFormId}
                      onSelect={onQuoteFormIdChange}
                      disabled={isPending}
                      className={{ wrapper: styles.selectWrapper }}
                      error={formik.touched.QuoteFormId ? formik.errors.QuoteFormId : ''}
                      testid="form_id_select"
                    />
                  </div>
                </div>
                <div className={styles.inputBlock}>
                  <label htmlFor="ServiceFormId" className={styles.editLabel}>
                    Services
                  </label>
                  <div className={styles.rightBlock}>
                    <AntdSelect
                      large
                      id="TagId"
                      name="TagId"
                      showArrow={true}
                      suffixIcon={<ArrowDown className={styles.arrowIcon} />}
                      mode="tags"
                      getPopupContainer={(triggerNode) => triggerNode}
                      menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                      placeholder="Select Services"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      onSelect={onServiceTagSelect}
                      onDeselect={onServiceTagDeselect}
                      value={formik.values.Tags}
                      className={styles.tagSelect}
                      disabled={!serviceTags}
                    >
                      {serviceTags ? (
                        serviceTags.map((el, i) => (
                          <Option value={el.value} key={`tags-${el.value}`}>
                            {el.name}
                          </Option>
                        ))
                      ) : (
                        <Option value={''} key={`tags-${0}`}>
                          Loading...
                        </Option>
                      )}
                    </AntdSelect>
                  </div>
                </div>
                {id ? (
                  <div className={styles.flexWrapper}>
                    <CheckboxComponent
                      id="IsActive"
                      label="Active"
                      checked={formik.values.IsActive}
                      onChange={formik.handleChange}
                      disabled={isPending}
                      direction={CheckboxComponent.DIRECTION_RIGHT}
                      name="IsActive"
                      labelTextClassName={styles.checkboxLabel}
                      className={styles.checkbox}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </form>
        </Wrapper>
      )}
    </>
  );
};

CategoryForm.initialValues = {
  Title: '',
  Type: '',
  Icon: undefined,
  QuoteFormId: undefined,
  Tags: [],
  IsActive: false,
};

export default CategoryForm;
