import classNames from 'classnames';

import { ClientInput, ClientRole } from 'components/Transactions';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { ValueObject } from 'components/Transactions/ClientInput/ClientInputView';
import { Role } from 'app-constants';

import styles from './styles.module.scss';
import { Cross } from 'components/Icons';

interface ParticipantProps {
  value: ValueObject;
  participants?: ValueObject[];
  isParInfo: boolean;
  isAssignRoleInfo: boolean;
  isAllowDuplicates?: boolean;
  allowUser?: boolean;
  onDelete: (...args) => void;
  onChangeClient: (...args) => void;
  onChangeRole: (...args) => void;
  onBlurClientInput: (...args) => void;
  onRoleKeyPress: (...args) => void;
  onRemoveData: (...args) => void;
}

const Participant = ({
  value,
  participants,
  isParInfo,
  isAssignRoleInfo,
  isAllowDuplicates = false,
  allowUser = false,
  onDelete,
  onChangeClient,
  onChangeRole,
  onBlurClientInput,
  onRoleKeyPress,
  onRemoveData,
}: ParticipantProps) => {
  const deleteIcon = (
    <Icon
      onClick={onDelete}
      className={styles.iconDelete}
      variant={Icon.MINUS}
      testid="close_icon"
    />
  );

  return (
    <div className={styles.participantWrap}>
      <div className={styles.participant}>
        <div className={styles.clientInputWrap}>
          <p className={styles.inputLabel}>{'Name'}</p>
          <ClientInput
            onBlur={onBlurClientInput}
            onChange={onChangeClient}
            value={value}
            dropdownContainer={styles.dropdownWrap}
            excludedParticipantIds={
              isAllowDuplicates
                ? []
                : participants
                    ?.filter((p) => p.id !== value.id)
                    .filter((p) => p.id !== undefined)
                    .map((p) => p.id as number)
            }
            className={classNames(styles.client, { [styles.clientHalf]: value.invite })}
            placeholder=" "
            allowUser={allowUser}
          />
          {value?.firstName ? (
            <Cross color="#262626" className={styles.removeButton} onClick={onRemoveData} />
          ) : null}
        </div>{' '}
        <div>
          <p className={styles.inputLabel}>Role</p>
          <ClientRole
            className={styles.clientRole}
            wrapperClassName={styles.inputWrapper}
            arrowIconClassName={styles.arrowdownicon}
            onChange={onChangeRole}
            onKeyPress={onRoleKeyPress}
            value={value}
            isParInfo={isParInfo}
            isAssignRoleInfo={isAssignRoleInfo}
            isSearchIcon={false}
          />
        </div>
      </div>
      {deleteIcon}
    </div>
  );
};

export default Participant;
