import { useSelector } from 'react-redux';

import { TeamFilter } from './TeamFilter';
import Handwave from './Images/Handwave.png';
import { getUserDataSelector } from 'store/selectors/user';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_BASIC } from 'settings/constants/roles';

import styles from './styles.module.scss';

export const Header = ({ teamStats, toggleTeamStats }) => {
  const user = useSelector(getUserDataSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  return (
    <div className={styles.agentHeaderContainer}>
      <h2 className={styles.headerTitle}>
        {`Hey, ${user.FirstName}! `}
        <img className={styles.emoji} src={Handwave} />
      </h2>
      {agentRole !== TEAM_BASIC && (
        <div className={styles.teamFilter}>
          <TeamFilter teamStats={teamStats} filterChange={toggleTeamStats} />
        </div>
      )}
    </div>
  );
};
