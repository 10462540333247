import React from 'react';

import moment from 'moment';

import { Icons } from 'pages/Workshop/Icons';
import { getLocaleDate } from 'helpers';

import styles from './styles.module.scss';
// import { mapTasksStatuses, tasksStatusesIds } from 'settings/constants/transactionTasks';
import { Button, Tooltip } from 'components-antd';
import { ApprovalAction } from 'types';
import classNames from 'classnames';

export function tableColumns(processAction, setData, getPendingApproverNames) {
  const ListingColumn = ({ row }) => {
    const address = row?.ShowingDetails.PropertyTransaction?.Property?.Address;

    return (
      <div className={styles.row}>
        <Icons variant={Icons.LOCATION} className={styles.condoIcon} />
        <div className={styles.flexCol}>
          <span className={styles.bold}>{address?.Line1}</span>
          <span className={styles.line2}>
            {address?.City + `, ` + address?.State + ` ` + address?.Zip}
          </span>
        </div>
      </div>
    );
  };

  const DateTimeColumn = ({ row }) => {
    const date = row?.AppointmentDate;

    return (
      <div className={styles.inlineRowContent}>
        <Icons variant={Icons.CALENDAR} />
        <div className={styles.dateTime}>
          <span className={styles.bold}>{moment(getLocaleDate(date)).format('M/D/YYYY')}</span>
          <span>{moment(getLocaleDate(row?.AppointmentStartTime)).format('h:mm A')}</span>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: 'Date/Time',
      dataIndex: 'dateTime',
      key: 'AppointmentDate',
      render: (_, row) => <DateTimeColumn row={row} />,
      width: 450,
      sorter: (a, b) => moment(a.AppointmentDate).unix() - moment(b.AppointmentDate).unix(),
    },
    {
      title: 'Listing',
      dataIndex: 'listings',
      key: 'Listig',
      render: (_, row) => <ListingColumn row={row} />,
      width: 450,
      sorter: (a, b) => {
        const addressA = a?.ShowingDetails.PropertyTransaction?.Property?.Address?.Line1 || '';
        const addressB = b?.ShowingDetails.PropertyTransaction?.Property?.Address?.Line1 || '';
        return addressA.localeCompare(addressB);
      },
    },

    {
      title: '',
      dataIndex: 'AppointmentStatus',
      width: 150,
      key: 'AppointmentStatus',
      render: (_, row) => {
        var approvers = getPendingApproverNames(row);
        const pendingApprovers = getPendingApproverNames(row);

        if (approvers.includes('You')) {
          approvers = approvers.filter((a) => a !== 'You');
          approvers.unshift('You');
        }

        const approversStr = approvers.join(', ');
        const amIApprover = approversStr.includes('You');
        const otherApprovers = approversStr.replace('You', '');
        return (
          <>
            {
              <span className={styles.pending}>
                {pendingApprovers && pendingApprovers[0] === 'You' ? (
                  <span className={styles.pendingActions}>
                    <Button
                      variant="bordered-text-only"
                      className={styles.approve}
                      onClick={(e) => {
                        e.stopPropagation();
                        setData(row);
                        processAction(ApprovalAction.Approve);
                      }}
                    >
                      <Icons variant={Icons.GREEN_LIGHT_CHECKMARK} />
                    </Button>
                    <Button
                      variant="bordered-text-only"
                      className={styles.reject}
                      onClick={(e) => {
                        e.stopPropagation();
                        setData(row);
                        processAction(ApprovalAction.Decline);
                      }}
                    >
                      <Icons variant={Icons.CLOSE} />
                    </Button>
                  </span>
                ) : (
                  <Tooltip
                    color="black"
                    trigger="hover"
                    placement="topRight"
                    overlayClassName={styles.pendingTooltipContainer}
                    title={
                      <div>
                        <p className={classNames(styles.tooltipTitle)}>
                          <Icons variant={Icons.PENDING_APPROVAL} className={styles.pendingIcon} />
                          <div>
                            <span>Pending Approval:</span>
                            <br />
                            {amIApprover && (
                              <>
                                <span>You{otherApprovers !== '' ? ',' : ''}&nbsp;</span>
                              </>
                            )}
                            <span>{otherApprovers}</span>
                          </div>
                        </p>
                      </div>
                    }
                  >
                    <div>
                      <Icons
                        variant={Icons.PENDING_APPROVAL_BG}
                        className={styles.rowPendingIcon}
                      />
                    </div>
                  </Tooltip>
                )}
              </span>
            }
          </>
        );
      },
    },
  ];

  return columns;
}
