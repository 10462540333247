import { useDispatch } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { Stages } from 'components';

import { initialValues } from './initialValues';
import { requestCreateOfferSubmissionEffect } from '../../../../store/effects/offers';
import { showSuccessMessage } from '../../../../helpers/success';
import { InfoModal } from '../components/InfoModal/InfoModal';

import styles from './styles.module.scss';
import {
  ContactInformation,
  Document,
  Highlights,
  OfferExpiration,
  SubmitOffer,
} from './StageParts';

export const SubmissionForm = (props) => {
  const { offerLinkDetails, hideSubmission, changeSubmission } = props;
  const dispatch = useDispatch();
  const [submissionFormData, setSubmissionFormData] = useState(initialValues);
  const [offerSubmission, setOfferSubmission] = useState<any>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSubmissionFormData({
      ...submissionFormData,
    });
  }, []);

  const createOrUpdateOfferSubmission = (body) => {
    setLoading(true);

    const data = {
      ...submissionFormData,
      ...body,
      OfferLinkId: Number(offerLinkDetails.Id),
    };

    dispatch(
      requestCreateOfferSubmissionEffect(data, {}, (err, res) => {
        if (!err) {
          const toaster = `Offer submitted successfully!`;
          showSuccessMessage(toaster);
          setOfferSubmission(res?.data?.value);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }),
    );
  };

  const onUpdate = (data) => {
    setSubmissionFormData({
      ...submissionFormData,
      ...data,
    });
  };

  const onFinish = (body) => {
    createOrUpdateOfferSubmission(body);
  };

  const stagesStep = {
    ContactInformation: 0,
    Highlights: 1,
    Documents: 2,
    OfferExpiration: 3,
  };

  return (
    <div className={styles.submissionForm}>
      {!hideSubmission ? (
        <SubmitOffer onNext={() => changeSubmission(true)} />
      ) : (
        <Fragment>
          <Stages
            stages={[
              { Component: ContactInformation },
              { Component: Highlights },
              { Component: Document },
              { Component: OfferExpiration },
            ]}
            classNameProgress={styles.progressBar}
            classNameWrapper={styles.stagesWrapper}
            stagesStep={stagesStep}
            transaction={offerLinkDetails}
            onUpdate={onUpdate}
            onFinish={onFinish}
            data={submissionFormData}
            offerSubmission={offerSubmission}
            loading={loading}
          />
          {offerSubmission?.UUID && <InfoModal uuid={offerSubmission.UUID} isOpen={true} />}
        </Fragment>
      )}
    </div>
  );
};
