import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import { PageWrapper, AsideNavigation, Content } from 'components/Transactions';
import { getofferSelector } from 'store/selectors/offers';
import { OffersList } from './OffersList';
import { getTransactionAcceptedOffer } from 'api/offers';
import { TransactionAcceptedOffer } from './TransactionAcceptedOffer';
import { getKeyDatesEffect, getTransactionEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';

import { getOfferAggregateByUserIdEffect } from 'store/effects/offerAggregate';
import { getUserId } from 'store/selectors/user';
import { offerListEffect } from 'store/effects';

export const TransactionOffers = (props) => {
  const { className, match } = props;
  const dispatch = useDispatch();
  const { isError, status, isPending, TransactionId } = useSelector(getofferSelector);
  const [holdDisplay, setHoldDisplay] = useState(false);
  const [acceptedOffer, setAcceptedOffer]: any = useState({});
  const [isAcceptedOffer, setIsAcceptedOffer] = useState(false);

  const [isReloadOffer, setReloadOffer] = useState(false);
  const userId = useSelector(getUserId);

  const fetchTransactionById = (callback?) => {
    dispatch(getTransactionEffect({ id: match.params.id }, { silent: true }, callback));
    dispatch(getKeyDatesEffect({ transactionId: match.params.id }, { silent: true }));
    setReloadOffer(false);
  };

  const fetchTransactionOfferById = async () => {
    getTransactionAcceptedOffer({ transactionId: match.params.id }).then((response) => {
      if (!_.isEmpty(response?.data?.value)) {
        setAcceptedOffer(response?.data?.value);
        setIsAcceptedOffer(true);
      } else {
        dispatch(
          offerListEffect({ transactionId: match.params.id }, { silent: true }, (err) => {}),
        );
        dispatch(
          getOfferAggregateByUserIdEffect({
            id: userId,
            filters: {
              transactionIds: [match.params.id],
            },
          }),
        );
      }
      setHoldDisplay(false);
    });
  };

  useEffect((): any => {
    setHoldDisplay(true);
    fetchTransactionOfferById();
    fetchTransactionById();
  }, [isAcceptedOffer, isReloadOffer]);

  return (
    <div className={classNames(styles.transactionOffers, className)}>
      <PageWrapper notFound={isError && status === 404}>
        <AsideNavigation />
        <Content isPending={isPending || holdDisplay}>
          {isAcceptedOffer ? (
            <TransactionAcceptedOffer
              transactionId={TransactionId}
              Offer={acceptedOffer}
              isData={setAcceptedOffer}
              setIsAcceptedOffer={setIsAcceptedOffer}
            />
          ) : (
            <OffersList
              transactionId={match.params.id}
              offerUUID={match.params.uuid}
              setReloadOffer={setReloadOffer}
            />
          )}
        </Content>
      </PageWrapper>
    </div>
  );
};

TransactionOffers.propTypes = {
  className: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,
};

TransactionOffers.defaultProps = {
  className: '',
};
