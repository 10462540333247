import classNames from 'classnames';
import { DeleteModal, MenuProps } from 'components-antd';

import { Fragment, useState } from 'react';

import { Menu, Popover } from 'components-antd';
import { Icons } from '../../../icons';

import styles from './styles.module.scss';
import { mapTasksStatuses } from 'settings/constants/transactionTasks';
import { Spinner } from 'components';
import { showErrorMessage } from 'helpers';

export const Actions = ({ handleDeleteTasks, handleUpdateTasks, selectedIds }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const actionItems = [
    { label: 'Mark as N/A', key: 'N/A' },
    { label: 'Mark as Done', key: 'Done' },
    ...(handleDeleteTasks
      ? [
          {
            label: <div className={styles.deleteLabel}>Delete</div>,
            key: 'Delete',
          },
        ]
      : []),
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setIsOpen(false);

    if (selectedIds.length) {
      if (e.key === 'Delete') {
        setDeleteConfirmation(true);
      } else {
        const status = Object.keys(mapTasksStatuses).find((key) => mapTasksStatuses[key] === e.key);

        status && handleUpdateTasks(status, setLoading);
      }
    } else {
      showErrorMessage('Please select one or more tasks to complete this action.');
    }
  };

  const menu = <Menu onClick={handleMenuClick} items={actionItems} />;

  return (
    <div className={styles.pendingTaskActions}>
      <DeleteModal
        open={deleteConfirmation}
        entityName={`selected ${selectedIds.length > 1 ? 'tasks' : 'task'}`}
        closable={false}
        maskClosable={false}
        confirmLoading={loading}
        onCancel={() => setDeleteConfirmation(false)}
        onOk={() => handleDeleteTasks(setLoading, setDeleteConfirmation)}
      />
      <Popover
        content={menu}
        trigger={'click'}
        open={isOpen}
        placement={'bottom'}
        onOpenChange={(newOpen: boolean) => {
          !loading && setIsOpen(newOpen);
        }}
        overlayClassName={styles.actionPopover}
        getPopupContainer={(trigger) => {
          return trigger;
        }}
      >
        <div
          className={classNames(styles.popmenu, {
            [styles.openPopMenu]: isOpen,
            [styles.disablePopMenu]: loading,
          })}
        >
          <div className={styles.popmenuButton}>
            {loading && !deleteConfirmation ? (
              <Spinner loaderClassName={styles.loadingSpinner} />
            ) : (
              <Fragment>
                <span>Actions</span>
                <div className={classNames(styles.menuIcon, { [styles.menuOpen]: isOpen })}>
                  <Icons variant={'arrowdown'} className={styles.actionBtn} />
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};
