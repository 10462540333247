const ROLES = {
  AGENT: 'Agent',
  CLIENT: 'Client',
  BUYER: 'Buyer',
  SELLER: 'Seller',
  SUPER_USER: 'SuperUser',
  THIRD_PARTY: 'ThirdParty',
};

const URLS = {
  SETTINGS: '/settings',
  SETTINGS_PROFILE: '/settings',
  SETTINGS_ACCOUNT: '/settings',
  SETTINGS_PARTNER_PROFILE: '/settings/partner',
  SETTINGS_BROKERAGE: '/settings/brokerage',
  SETTINGS_PASSWORD: '/settings/password',
  SETTINGS_NOTIFICATIONS: '/settings/notifications',
  SETTINGS_CO_BUYER: '/settings/co-buyer',
  SETTINGS_SEARCH_CRITERIA: '/settings/search-criteria',
  SETTINGS_TEMPLATES: '/settings/templates',
  SETTINGS_COMMUTES: '/settings/commutes',
  SETTINGS_PRE_APPROVAL: '/settings/pre-approval',
  SETTINGS_THIRD_PARTY_INTEGRATIONS: '/settings/third-party-integrations',
  FEED: '/feed',
  SEARCH: '/search',
  SERVICES: '/services',
  OFFERS: '/offers',
  QUOTES: '/services/quotes',
  TRANSACTIONS: '/transactions',
  MESSAGES: '/messages',
  NOTIFICATIONS: '/notifications',
  HELP_AND_SUPPORT: '/help-and-support',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  TERMS_OF_SERVICE: '/',
  PRIVACY_POLICY: '/',
  BUYER_WIZARD: '/wizard/buyer',
  DASHBOARD_CATEGORIES: '/dashboard/service-directory/categories',
  DASHBOARD_PARTNERS: '/dashboard/service-directory/partners',
  TRANSACTIONS_OVERVIEW_PATTERN: '/transactions/{transaction_id}/overview',
  TRANSACTIONS_TASKS_PATTERN: '/transactions/{transaction_id}/tasks',
  TRANSACTIONS_MESSAGES_PATTERN: '/transactions/{transaction_id}/messages',
  TRANSACTIONS_DOCUMENTS_PATTERN: '/transactions/{transaction_id}/documents',
  TRANSACTIONS_ACTIVITY_PATTERN: '/transactions/{transaction_id}/activity',
};

const SETTINGS = {
  ACCOUNT: 'Account',
  PARTNER_PROFILE: 'Partner Profile',
  PROFILE: 'Profile',
  BROKERAGE: 'Brokerage',
  PASSWORD: 'Password',
  NOTIFICATIONS: 'Notifications',
  CO_BUYER: 'Co Buyer',
  SEARCH_CRITERIA: 'Search Criteria',
  TEMPLATES: 'Templates',
  COMMUTES: 'Commutes',
  PRE_APPROVAL: 'Pre-Approval',
  THIRD_PARTY_INTEGRATIONS: 'Third Party Integrations',
};

const HEADER = {
  FEED: 'Feed',
  SEARCH: 'Search',
  QUOTES: 'Quotes',
  SERVICES: 'Services',
  OFFERS: 'Offers',
  TRANSACTIONS: 'Transactions',
};

const TOAST_MESSAGES = {
  WRONG_CREDENTIALS: 'Wrong login or password',
  INCORRECT_PASSWORD: 'Incorrect password',
};

const VALIDATION = {
  REQUIRED: 'Required',
  REQUIRED_TEMPLATE: '{field_name} is a required field',
  PASSWORD_WEAK_LOWER1: 'Password must contain at least 1 lower-cased letter',
  PASSWORD_WEAK_LOWER2: 'Password must have at least 1 lowercase letter',
  PASSWORD_WEAK_UPPER: 'Password must have at least 1 uppercase letter',
  PASSWORD_WEAK_NUMBER: 'Password must have at least 1 number',
  PASSWORD_SHORT: 'Password must have at least 8 characters',
  PASSWORDS_MUST_MATCH: 'Passwords must match',
  PHONE_MUST_BE_VALID: 'Phone must be a valid phone number.',
  PHONE_MUST_BE_VALID_US: 'Phone must be a valid phone number for region US',
  PHONE_MUST_BE_VALID_US_v2: 'Must be a valid phone number for region US',
  WEBSITE: 'Please enter website',
};

const PROPERTY_TYPES = {
  HOUSE: 'House',
  CONDO: 'Condo',
  TOWN_HOME: 'Townhome',
  MULTI_FAMILY: 'Multi-Family',
  LAND: 'Land',
  OTHER: 'Other',
};

const NUMBER_GROUPS = {
  1: '1+',
  2: '2+',
  3: '3+',
  4: '4+',
  5: '5+',
};

const BEDS = { ...NUMBER_GROUPS };
const BATHS = { ...NUMBER_GROUPS };

const HOME_FEATURES = {
  OUTDOOR_SPACE: 'Outdoor Space',
  VIEW: 'View',
  MOVE_IN_READY: 'Move-In Ready',
  LARGE_KITCHEN: 'Large Kitchen',
  AMENITIES: 'Amenities',
  NEW_CONSTRUCTION: 'New Construction',
  HOME_OFFICE: 'Home Office',
  PARKING: 'Parking',
};

const AMENITIES = {
  ELEVATOR: 'Elevator',
  DOORMAN: 'Doorman',
  GYM: 'Gym',
  DOG_RUN: 'Dog Run',
  PACKAGE_RECEIVING: 'Package Receiving',
  DRY_CLEANING: 'Dry Cleaning',
  ROOFTOP: 'Rooftop',
  ON_SITE_MANAGEMENT: 'On-Site Management',
};

const NEIGHBORHOOD = {
  SHORT_COMMUTE: 'Short Commute',
  GOOD_SCHOOLS: 'Good Schools',
  WALKABILITY: 'Walkability',
  PARKS_AND_NATURE: 'Parks and Nature',
  RESTAURANTS: 'Restaurants and Bars',
  COFFEE_SHOPS: 'Coffee Shops',
};

const COMMUTE_TRANSPORT = {
  CAR: 'Car',
  PUBLIC_TRANSIT: 'Transit',
  BIKE: 'Bike',
  WALK: 'Walk',
};

const COMMUTE_TIME = {
  LESS_15: '15 minutes or less',
  LESS_30: '30 minutes or less',
  LESS_45: '45 minutes or less',
  LESS_60: '60 minutes',
};

const STORIES = {
  SINGLE: 'Single-Story',
  MULTI: 'Multi-Story',
  NOT_IMPORTANT: 'Not Important',
};

const INTERIOR = {
  MODERN: 'Modern',
  LOFT: 'Loft',
  TRADITIONAL: 'Traditional',
  NOT_IMPORTANT: 'Not Important',
};

const MUST_HAVE = { ...HOME_FEATURES, ...AMENITIES, ...NEIGHBORHOOD };
delete MUST_HAVE.AMENITIES;

const BUYING_PROCESS = {
  FOUND_HOME: 'Found a home',
  BUY_IN_ONE_MONTH: 'Looking to buy in 1 month',
  BUY_IN_ONE_THREE_MONTHS: 'Looking to buy in 1-3 months',
  BUY_IN_THREE_PLUS_MONTHs: 'Looking to buy in 3+ months',
  EXPLORING_OPTIONS: 'Exploring my options',
};

const HOME_KIND = {
  SINGLE_FAMILY: 'Single Family',
  TOWNHOME: 'Townhome',
  CONDOMINIUM: 'Condominium',
  MULTI_FAMILY: 'Multi-Family',
  NEW_CONSTRUCTION: 'New Construction',
  LAND: 'Land',
};

const HOME_WHAT = {
  PRIMARY: 'Primary residence',
  SECONDARY: 'Secondary home',
  INVESTMENT: 'Investment property',
};

const EMPLOYMENT = {
  EMPLOYED: 'Employed',
  SELF_EMPLOYED: 'Self-Employed',
  RETIRED: 'Retired',
};

const CREDIT_SCORE = {
  EXCELLENT: 'Excellent (720+)',
  GOOD: 'Good (660 - 719)',
  AVERAGE: 'Average (620 - 659)',
  POOR: 'Average (620 - 659)',
};

const INVITE_CLIENT_STATUS = {
  ACTIVE: 'Active',
  PENDING: 'Pending',
  INACTIVE: 'Inactive',
};

const CLIENT_TABS = {
  CONTACT: 'Contact',
  PROFILE: 'Profile',
};

const YES_NO = {
  YES: 'Yes',
  NO: 'No',
};

const PLACEHOLDERS = {
  ENTER_CITY_NEIGHBORHOODS: 'Enter city or neighborhood',
  SEARCH_ADDRESS_PLACEHOLDER: 'Enter a city, address, neighborhood, or ZIP',
  ENTER_ADDRESS: 'Enter address or location name',
  NO_MIN: 'No min',
  NO_MIN_WITH_$: '$ No min',
  NO_MAX: 'No max',
  NO_MAX_WITH_$: '$ No max',
  ADD_A_NOTE: 'Add a note...',
  SEARCH_NAME: 'Search name',
  TELL_YOUR_CLIENTS_MORE: 'Tell your clients more about yourself',
  ADDRESS_SAMPLE: 'eg. 1234 Main St',
  COMMUTE_SAMPLE: 'e.g. Work, school, daycare, etc',
  WEBSITE: 'Website',
  SELECT: 'Select',
  SAVE_SEARCH: 'e.g. Chicago - 3 BR / 2 BA',
  SEARCH_MESSAGES: 'Search messages',
  SEARCH: 'Search',
  SEARCH_PARTNERS: 'Search partners',
  PROPERTY_ADDRESS: 'Enter property address...',
  UNIT_APT: 'Unit/Apt (optional)',
};

const FEED_TABS = {
  ALL: 'All',
  NEW: 'New',
  SAVED_SEARCHES: 'Saved Searches',
  STATUS_CHANGES: 'Status Changes',
  CLIENT_FAVORITES: 'Client Favorites',
};

const SORT_BY_BUYER = {
  MATCH: 'Match Score',
  PRICE: 'Price',
  DAYS_ON_MARKET: 'Days on Market',
  BEDS: 'Beds',
  BATHS: 'Baths',
  SQUARE_FEET: 'Square Feet',
};

const SORT_BY_AGENT = {
  MATCH: 'Client Match',
  PRICE: 'Price',
  DAYS_ON_MARKET: 'Days on Market',
  BEDS: 'Beds',
  BATHS: 'Baths',
  SQUARE_FEET: 'Square Feet',
};

const SEARCH_CRITERIA_TABS = {
  PRIMARY_CRITERIA: 'Primary Criteria',
  IMPORTANT_FEATURES: 'Important Features',
  COMMUTES: 'Commutes',
};

const PROPERTY_STATUSES = {
  ACTIVE: 'Active',
  PENDING: 'Pending',
  ACTIVE_UNDER_CONTRACT: 'Active Under Contract',
};

const CATEGORY_TYPES = {
  TRANSACTION: 'Transaction',
  MAINTENANCE: 'Maintenance and Home Improvement',
};

const TRANSACTION_STATUSES = {
  LISTED: 'Listed',
  UNDER_CONTRACT: 'Under contract',
  CLEAR_TO_CLOSE: 'Clear to close',
  CANCELED: 'Canceled',
  CLOSED: 'Closed',
};

const TASK_STATUSES = {
  NEW: 'New',
  IN_PROGRESS: 'In Progress',
  STUCK: 'Stuck',
  DONE: 'Done',
};

const TASK_FILTERS = {
  ALL_TASKS: 'All Tasks',
  ASSIGNED_BY_ME: 'Assigned by me',
  ASSIGNED_TO_ME: 'Assigned to me',
  COMPLETED: 'Completed',
};

export {
  ROLES,
  URLS,
  TOAST_MESSAGES,
  SETTINGS,
  HEADER,
  VALIDATION,
  PROPERTY_TYPES,
  BEDS,
  BATHS,
  HOME_FEATURES,
  AMENITIES,
  NEIGHBORHOOD,
  COMMUTE_TRANSPORT,
  COMMUTE_TIME,
  STORIES,
  INTERIOR,
  MUST_HAVE,
  BUYING_PROCESS,
  HOME_KIND,
  HOME_WHAT,
  EMPLOYMENT,
  CREDIT_SCORE,
  INVITE_CLIENT_STATUS,
  CLIENT_TABS,
  YES_NO,
  PLACEHOLDERS,
  FEED_TABS,
  SORT_BY_BUYER,
  SORT_BY_AGENT,
  SEARCH_CRITERIA_TABS,
  PROPERTY_STATUSES,
  CATEGORY_TYPES,
  TRANSACTION_STATUSES,
  TASK_STATUSES,
  TASK_FILTERS,
};
