import React, { SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Question } from 'pages/RequestQuote/components';
import { Input } from 'components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';
import styles from './styles.module.scss';
import { TransactionCloseComponentIds } from 'app-constants';
import { getTransactionPrice } from 'utils';
import { useSelector } from 'react-redux';
import { getTransactionSelector } from 'store/selectors/transaction';

interface ClosingPriceProps {
  setClosingPrice: (closingDate: SetStateAction<string>) => void;
  setStage: (stage: TransactionCloseComponentIds) => void;
}

export const ClosePrice = ({ setClosingPrice, setStage }: ClosingPriceProps) => {
  const [closePrice, setClosePrice] = useState('12121');
  const { transaction } = useSelector(getTransactionSelector);
  const keyDatesPrices = transaction?.PropertyTransactionKeyDatesPrices;

  useEffect(() => {
    const closePrice_ = getTransactionPrice(transaction.Status, keyDatesPrices);
    setClosePrice(closePrice_);
    setClosingPrice(closePrice_);
  }, [transaction]);

  const onChangeHandler = (val) => {
    setClosePrice(val);
    setClosingPrice(val);
  };

  return (
    <div testid="what_listing_price" className={classNames(styles.container)}>
      <Question>What was the closing price?</Question>
      <AnswersContainer>
        <Input
          isNumberFormat
          variant={Input.LIGHT}
          placeholder="$ Price"
          onChange={(e, val) => onChangeHandler(val)}
          value={closePrice}
          prefix="$"
          testid="price_input"
        />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue
          onClick={() => setStage(TransactionCloseComponentIds.UploadFiles)}
          disabled={!closePrice}
        />
      </ButtonsContainer>
    </div>
  );
};
