import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getUserId } from 'store/selectors/user';
import { FilterSections } from './FilterSections';
import { requestGetQuotesEffect } from 'store/effects/quotes';

export const Filter = () => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);

  const doneHandler = (p) => {
    dispatch(requestGetQuotesEffect({ ...p }));
  };

  const resetHandler = () => {
    dispatch(requestGetQuotesEffect());
  };

  return <FilterSections onDone={doneHandler} onReset={resetHandler} />;
};
