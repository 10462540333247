import { EditStageInfo } from '../EditStageInfo';

import styles from '../styles.module.scss';

export const EditDocuments = ({ data, onEdit }) => {
  const getInfo = () => {
    const { RequireDocumentPreApproval, OtherRequiredDocuments } = data;

    let text = '';
    if (RequireDocumentPreApproval) {
      text += 'Pre-Approval Letter';
    }

    if (OtherRequiredDocuments?.length) {
      const info = `Other Required Documents (${OtherRequiredDocuments.length})`;
      text += text ? `, ${info}` : info;
    }

    return text ? text : '-';
  };

  return (
    <div className={styles.editForm}>
      <EditStageInfo
        icon="document"
        title="What documents should be submitted?"
        info={getInfo()}
        onClick={onEdit}
      />
    </div>
  );
};
