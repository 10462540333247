import PropTypes from 'prop-types';
import classNames from 'classnames';
import { chunk, truncate } from 'lodash-es';

import Title from './Title';
import SubTitle from './SubTitle';
import { LenderCard } from 'pages/OnBoardingWizard/components';

import styles from './styles.module.scss';

const Lenders = (props) => {
  const { className, onSelect, selectedLendersIds, recommendedByAgent, recommendedByMosaik } =
    props;

  const getOptions = (options) => {
    return chunk(options, 1);
  };

  const renderOptions = (options) =>
    options.map((option, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className={styles.optionHolder}>
        {option.map(({ id, title, subTitle, logo, isFavorite }) => (
          <LenderCard
            key={id}
            id={id}
            isActive={selectedLendersIds.includes(id)}
            isFavorite={isFavorite}
            className={styles.lenderCard}
            avatarClassName={styles.avatar}
            textWrapperClassName={styles.textWrapper}
            selectWrapperClassName={styles.selectWrapper}
            logo={logo}
            title={title}
            subTitle={subTitle}
            onSelect={onSelect}
          />
        ))}
      </div>
    ));

  const getDescription = (text) => {
    if (!text) return '';

    return truncate(text, { length: 120, separator: ' ' });
  };

  const convertData = (partners, isFavorite = false) =>
    (partners || []).map((partner) => ({
      id: partner?.Id,
      title: partner.BusinessName,
      subTitle: getDescription(partner?.Description),
      logo: partner?.LogoUrl,
      isFavorite,
    }));

  const agentRecommendedOptions = renderOptions(getOptions(convertData(recommendedByAgent, true)));
  const mosaikRecommendedOptions = renderOptions(getOptions(convertData(recommendedByMosaik)));

  return (
    <div className={classNames(styles.lenders, className)}>
      {!!agentRecommendedOptions?.length && (
        <div testid="agent_recommendations" className={styles.favorites}>
          <Title className={styles.title}>Agent Recommendations</Title>
          <SubTitle className={styles.subTitle}>
            These are lenders that your agent recommends
          </SubTitle>
          {agentRecommendedOptions}
        </div>
      )}
      {!!mosaikRecommendedOptions?.length && (
        <div testid="all_lenders" className={styles.allLenders}>
          <Title className={styles.title}>Additional Lenders</Title>
          <SubTitle className={styles.subTitle}>
            These are additional options available to you
          </SubTitle>
          {mosaikRecommendedOptions}
        </div>
      )}
    </div>
  );
};

Lenders.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selectedLendersIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  recommendedByAgent: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  recommendedByMosaik: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Lenders.defaultProps = {
  className: '',
  selectedLendersIds: [],
};

export default Lenders;
