import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash-es';

import { Input, DatePicker, TextareaAutosize, InputLabel } from 'components';
import {
  AssignToInput,
  TagInput,
  UploadDocuments as CommonUploadDocuments,
} from 'components/Transactions';
import { Documents } from 'pages/Workshop/Transactions/TransactionTasks/components';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { getUserFirstName, getUserId, getUserLastName } from 'store/selectors/user';
import Links from './Links';

import styles from './styles.module.scss';

const TaskForm = (props) => {
  const { className, values, onChange } = props;
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const userId = useSelector(getUserId);

  const onChangeField = (val, fieldName) => {
    onChange({ ...(values || {}), [fieldName]: val });
  };

  const setCustomTag = (val) => {
    if (val?.trim()) {
      onChangeField(val, 'customTag');
    }
  };

  const onSave = (newValues, cb) => {
    const extendedValues = (newValues || []).map((val) => ({
      ...val,
      Uploader: { Id: userId, FirstName: userFirstName, LastName: userLastName },
    }));
    onChangeField([...(values?.Documents || []), ...extendedValues], 'Documents');
    if (typeof cb === 'function') {
      cb();
    }
  };

  const onDeleteDocument = (event, index) => {
    const copyDocuments = cloneDeep(values?.Documents);
    copyDocuments.splice(index, 1);
    onChangeField(copyDocuments, 'Documents');
  };

  return (
    <div className={classNames(styles.form, className)}>
      <Input
        className={classNames(styles.field, styles.name)}
        variant="light"
        label="Task Name"
        placeholder="Task Name"
        value={values?.Title || ''}
        onChange={(e, val) => onChangeField(val, 'Title')}
        testid="name_input"
      />
      <TagInput
        onSelect={(val) => onChangeField(val, 'Category')}
        onSelectCustomTag={setCustomTag}
        value={values}
      />
      <div className={classNames(styles.inline, styles.field)}>
        <DatePicker
          className={styles.dueDate}
          label="Due Date:"
          variant="light"
          options={{ enableTime: false }}
          value={values?.DueDate}
          onChange={(e, val) => onChangeField(val?.[0], 'DueDate')}
          testid="due_date"
        />
        <AssignToInput
          className={styles.assignTo}
          label="Assigned to:"
          value={values?.Assignee}
          onChange={(e, val) => onChangeField(val, 'Assignee')}
        />
      </div>
      <TextareaAutosize
        label="Description"
        placeholder="Add description..."
        className={styles.field}
        variant="light"
        value={values?.Description || ''}
        onChange={(e, val) => onChangeField(val, 'Description')}
        testid="description"
      />
      <Links
        label="Links"
        placeholder="Link"
        value={values?.Links}
        onChange={(e, val) => onChangeField(val, 'Links')}
      />
      <div>
        <InputLabel label="Documents" />
        <Documents value={values?.Documents} onDelete={onDeleteDocument} />
        <CommonUploadDocuments
          onSave={onSave}
          uploadButton={
            <div className={styles.uploadDocument}>
              <Icon className={styles.icon} variant={Icon.UPLOAD} />
              <div testid="upload_documents" className={styles.text}>
                Upload Document
              </div>
            </div>
          }
          withCategory
          withPermissions
          isCategoryOptional
          fillDocumentName={true}
        />
      </div>
    </div>
  );
};

TaskForm.propTypes = {
  className: PropTypes.string,
  values: PropTypes.shape({
    Title: PropTypes.string,
    Tag: PropTypes.string,
    DueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    Assignee: PropTypes.shape({}),
    Description: PropTypes.string,
    Links: PropTypes.arrayOf(PropTypes.string),
    Documents: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onChange: PropTypes.func,
};

TaskForm.defaultProps = {
  className: '',
  values: undefined,
  onChange: () => {},
};

export default TaskForm;
