import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getUserRolesMapSelector } from 'store/selectors/user';
import AgentClients from './AgentClients';
import ClientFavorites from './ClientFavorites';

import styles from './styles.module.scss';

const Content = (props) => {
  const { className, onBuyerClick, client } = props;
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);

  const getComponent = () => {
    if (isClient || client?.id) {
      return <ClientFavorites client={client} />;
    }

    if (isAgent) {
      return <AgentClients onBuyerClick={onBuyerClick} />;
    }
  };

  return <div className={classNames(styles.content, className)}>{getComponent()}</div>;
};

Content.propTypes = {
  className: PropTypes.string,
  onBuyerClick: PropTypes.func,
  client: PropTypes.object,
};

Content.defaultProps = {
  className: '',
  onBuyerClick: () => {},
  client: undefined,
};

export default Content;
