/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { ItemTitle } from '../index';

const ItemsWrapper = (props) => {
  const { className, innerClassName, children, title, testid } = props;

  return (
    <div testid={testid} className={classNames(styles.itemsWrapper, className)}>
      {title && <ItemTitle className={styles.title}>{title}</ItemTitle>}
      <div className={classNames(styles.inner, innerClassName)}>{children}</div>
    </div>
  );
};

ItemsWrapper.propTypes = {
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  testid: PropTypes.string,
};

ItemsWrapper.defaultProps = {
  className: '',
  innerClassName: '',
  title: undefined,
  testid: undefined,
};

export default ItemsWrapper;
