import { useState } from 'react';
import fileDownload from 'js-file-download';

import { dispatch } from 'store';
import { previewDocumentEffect } from 'store/effects/transactions';

import { Dropdown, Menu, MenuItem } from 'components-antd';
import { Dots } from 'components/Icons';
import { PdfViewModal } from 'components';
import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';

import styles from './styles.module.scss';

interface originFileObject {
  uid: string;
  lastModified: number;
  lastModifiedDate: string;
  name: string;
  size: number;
  type: string;
  percent: number;
}

interface answerObject {
  Id: string;
  ContentType: string;
  Filename: string;
  Url: string;
  UploadDate: string;
  Size: number;
  DocumentVaultUUID: string;
  UploaderId: 2;
  uid: string;
  name: string;
  status: string;
  url: string;
  originalFile: originFileObject;
}

interface DocumentProps {
  answer: answerObject;
}

export const Document = ({ answer }: DocumentProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const fileName = answer['Filename'] || answer['filename'] || 'File';

  const onPreview = () => {
    setIsPreviewOpen(true);
  };

  const onDownload = () => {
    if (answer?.DocumentVaultUUID) {
      dispatch(
        previewDocumentEffect(
          { DocumentVaultUUID: answer.DocumentVaultUUID },
          {},
          (err, response) => {
            if (err) {
              return;
            }
            fileDownload(response.data, response.headers['file-name']);
          },
        ) as any,
      );
    }
  };

  const menu = (
    <Menu>
      {answer['ContentType'].includes('pdf') ? (
        <MenuItem title="Preview" className={styles.menuItem} onClick={onPreview}>
          <Icon className={styles.icon} variant={Icon.OPEN} /> Open
        </MenuItem>
      ) : (
        <MenuItem title="Download" className={styles.menuItem} onClick={onDownload}>
          <Icon className={styles.icon} variant={Icon.DOWNLOAD} />
          Download
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <div className={styles.documentArea}>
      <p className={styles.fileName}>{fileName}</p>
      <Dropdown overlay={menu} overlayClassName={styles.menuOverlay} placement="bottomRight">
        <a onClick={(e) => e.preventDefault()}>
          <Dots />
        </a>
      </Dropdown>
      {isPreviewOpen && (
        <PdfViewModal
          isOpen={isPreviewOpen}
          file={answer}
          onClose={() => setIsPreviewOpen(false)}
        />
      )}
    </div>
  );
};
