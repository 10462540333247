import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Footer from './Footer';

import styles from './styles.module.scss';

const LocalPopup = forwardRef((props, ref) => {
  const { className, open, children, onSubmit } = props;

  if (!open) return null;

  return (
    <div ref={ref} className={classNames(styles.localPopup, className)}>
      <div className={styles.content}>{children}</div>
      <Footer onSubmit={onSubmit} />
    </div>
  );
});

LocalPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
};

LocalPopup.defaultProps = {
  className: '',
  open: false,
  onSubmit: () => {},
};

export default LocalPopup;
