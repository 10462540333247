import { useCallback, useEffect, useRef, useState } from 'react';
import { isArray, isEqual } from 'lodash-es';

const useIsDirty = (value, deepCompare = true) => {
  const [isDirty, setIsDirty] = useState(false);
  const savedValueRef = useRef(value);

  const updateIsDirty = useCallback(
    (isDirtyValue) => {
      if (isDirtyValue !== isDirty) {
        if (isDirtyValue === false) {
          savedValueRef.current = value;
        }
        setIsDirty(isDirtyValue);
      }
    },
    [isDirty, setIsDirty, value],
  );

  useEffect(() => {
    if (
      (isArray(value) && savedValueRef.current?.length !== value?.length) ||
      (deepCompare && !isEqual(savedValueRef.current, value))
    ) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [value, deepCompare]);

  return [isDirty, updateIsDirty];
};

export default useIsDirty;
