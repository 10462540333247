import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components-antd';
import moment from 'moment';

import { DateControlInfo } from '../DateControlInfo';
import { DateControlsModal } from '../DateControlsModal';
import { timelineDateControls } from 'settings/constants/transaction';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';

export const DateControls = ({ dateControls, onDateChange }) => {
  const [openModal, setOpenModal] = useState(false);
  const isProject = useIsProjectRoute();
  const timelineDateControlsDynamic = useMemo(
    () => ({ ...timelineDateControls, CD: isProject ? 'KO' : 'CD', CL: isProject ? 'DL' : 'CL' }),
    [isProject],
  );

  // If `TL` is present, show 4 date controls in a row
  const dateControlColumnSpan = dateControls.TL ? 8 : 6;

  return (
    <div className={styles.dateControls}>
      <h4>Date Controls</h4>
      <DateControlInfo isProject={isProject} />
      <Icon variant={Icon.EDIT} className={styles.editIcon} onClick={() => setOpenModal(true)} />
      <Row gutter={8}>
        <Col span={dateControlColumnSpan} className={styles.controls}>
          <div className={styles.prefix}>{timelineDateControlsDynamic.T}</div>
          <p> {dateControls.T ? moment(dateControls.T).format('M/DD/YY') : <span>Empty</span>}</p>
        </Col>
        <Col span={dateControlColumnSpan} className={styles.controls}>
          <div className={styles.prefix}>{timelineDateControlsDynamic.CD}</div>
          <p> {dateControls.CD ? moment(dateControls.CD).format('M/DD/YY') : <span>Empty</span>}</p>
        </Col>
        {dateControls.CL ? (
          <Col span={dateControlColumnSpan} className={styles.controls}>
            <div className={styles.prefix}>{timelineDateControlsDynamic.CL}</div>
            <p>
              {' '}
              {dateControls.CL ? moment(dateControls.CL).format('M/DD/YY') : <span>Empty</span>}
            </p>
          </Col>
        ) : (
          <></>
        )}
        {dateControls.LE ? (
          <Col span={dateControlColumnSpan} className={styles.controls}>
            <div className={styles.prefix}>{timelineDateControlsDynamic.LE}</div>
            <p>
              {' '}
              {dateControls.LE ? moment(dateControls.LE).format('M/DD/YY') : <span>Empty</span>}
            </p>
          </Col>
        ) : (
          <></>
        )}
        {dateControls.TL ? (
          <Col span={dateControlColumnSpan} className={styles.controls}>
            <div className={styles.prefix}>{timelineDateControlsDynamic.TL}</div>
            <p>
              {' '}
              {dateControls.TL ? moment(dateControls.TL).format('M/DD/YY') : <span>Empty</span>}
            </p>
          </Col>
        ) : (
          <></>
        )}
        <Col span={dateControlColumnSpan} className={styles.controls}>
          <div className={styles.prefix}>{timelineDateControlsDynamic.O}</div>
          <p> {dateControls.O ? moment(dateControls.O).format('M/DD/YY') : <span>Empty</span>}</p>
        </Col>
      </Row>
      <DateControlsModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        dateControls={dateControls}
        onDateChange={onDateChange}
        isProject={isProject}
      />
    </div>
  );
};

DateControlsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onDateChange: PropTypes.func,
  dateControls: PropTypes.shape({
    A: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    CD: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    CL: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    LE: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    O: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
};

DateControlsModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onDateChange: () => {},
  dateControls: {
    A: undefined,
    CD: undefined,
    CL: undefined,
    LE: undefined,
    O: undefined,
  },
};
