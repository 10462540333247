import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Right = forwardRef((props, ref) => {
  const { className, children } = props;

  return (
    <div ref={ref} className={classNames(styles.right, className)}>
      {children}
    </div>
  );
});

Right.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Right.defaultProps = {
  className: '',
};

export default Right;
