import { apiServer } from 'settings/web-services/api';
import { MosaikResponse } from '../types';

export function getListOfAgentMlsServices(): Promise<MosaikResponse<IMlsService[]>> {
  return apiServer.get(`/user/agent/profile/mlsServices`);
}

export interface IMlsService {
  Id: number;
  Status: string;
  Name: string;
}
