import { createAction } from 'redux-actions';

export const requestGetMilestoneAggregate = createAction('REQUEST/GET_MILESTONE_AGGREGATE');

export const requestUpdateMilestoneAggregate = createAction('REQUEST/UPDATE_MILESTONE_AGGREGATE');

export const requestAddNewMilestone = createAction('REQUEST/ADD_NEW_MILESTONE');

export const setMilestonesFiltersAction = createAction('SET_MILESTONES_FILTER');

export const setMilestonesArchiveAction = createAction('SET_MILESTONES_ARCHIVE');
