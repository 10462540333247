import { createAction } from 'redux-actions';

export const requestGetAvailableSubscriptionPlansAction = createAction(
  'REQUEST/GET_AVAILABLE_SUBSCRIPTION_PLANS',
);
export const requestGetInvoiceDataOverviewAction = createAction(
  'REQUEST/GET_INVOICE_DATA_OVERVIEW',
);

export const requestFetchActiveSubscriptionDataAction = createAction(
  'REQUEST/FETCH_ACTIVE_SUBSCRIPTION_DATA',
);

export const requestConvertToTeamPlanAction = createAction('REQUEST/POST_CONVERT_TO_TEAM_PLAN');

export const requestDowngradeFromTeamPlanAction = createAction(
  'REQUEST/POST_DOWNGRADE_FROM_TEAM_PLAN',
);

export const requestGetCurrentPlaneLevelAction = createAction('REQUEST/GET_CURRENT_PLAN_LEVEL');

export const requestGetImplementationPlansAction = createAction('REQUEST/GET_IMPLEMENTATION_PLAN');

export const requestGetCouponAction = createAction('REQUEST/GET_SUBSCRIPTION_COUPON');

export const requestSubscribeStandardAction = createAction('REQUEST/SUBSCRIBE_STANDARD_PLAN');
export const requestSubscribeLegacyAction = createAction('REQUEST/SUBSCRIBE_LEGACY_PLAN');

export const requestCancelSubscriptionAction = createAction('REQUEST/CANCEL_SUBSCRIPTION');
export const requestReactivateSubscriptionAction = createAction('REQUEST/REACTIVATE_SUBSCRIPTION');
export const requestUpdateStandardSubscriptionAction = createAction(
  'REQUEST/UPDATE_STANDARD_SUBSCRIPTION',
);

export const requestUpdatePlanLegacyAction = createAction('REQUEST/UPDATE_LEGACY_PLAN_LEGACY');

export const updateNewAgentAction = createAction('REQUEST/UPDATE_NEW_AGENT');

export const setPaymentUpdateModalAction = createAction('MODALS/SET_SHOW_PAYMENT_UPDATE_MODAL');

export const requestPaymentUpdateAction = createAction('REQUEST/CUSTOMER_PAYMENT_UPDATE');

export const requestActiveSubscriptionTeamDataAction = createAction(
  'REQUEST/ACTIVE_SUBSCRIPTION_TEAM_DATA',
);
