import React, { useState, useEffect } from 'react';
import { Modal } from 'components-antd';
import { Stages } from 'components';
import { getShowingDetails } from 'store/selectors/showingAppointment';
import {
  ContactInformation,
  Document,
  Highlights,
  OfferExpiration,
} from 'pages/ExternalOffer/OfferSubmission/SubmissionForm/StageParts';

import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorMessage } from 'helpers/errors';
import { requestCreateOfferSubmissionEffect } from 'store/effects/offers';
import { showSuccessMessage } from 'helpers/success';
import { Icons } from 'pages/Workshop/Transactions/TransactionShowings/ShowingModal/Icons';

import styles from './styles.module.scss';

export const EditModal = (props) => {
  const {
    offerData,
    isOpen,
    setEditModalVisibility,
    getUpdatedIterationData,
    transaction,
    seller,
    offerLinkDetails,
    onClose,
  } = props;
  const dispatch = useDispatch();
  const [requiredDocuments, setRequiredDocuments] = useState<any>([]);
  const { OtherRequiredDocuments, RequireDocumentPreApproval } = useSelector(getShowingDetails);

  const [submissionFormData, setSubmissionFormData] = useState({
    ...offerData,
    OfferIteration: offerData.OfferIteration[0],
  });

  const [offerSubmission, setOfferSubmission] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSubmissionFormData({ ...offerData, OfferIteration: offerData.OfferIteration[0] });
  }, [offerData]);

  useEffect(() => {
    const documents: JSON[] = [];
    if (RequireDocumentPreApproval) {
      documents.push(
        JSON.parse(
          JSON.stringify({
            Title: 'Pre-Approval Letter',
            Code: 'Pre-Approval Letter',
          }),
        ),
      );
    }
    if (OtherRequiredDocuments) {
      OtherRequiredDocuments?.forEach((element, index) => {
        documents.push(
          JSON.parse(JSON.stringify({ Title: element.name, Code: element.name.trim() })),
        );
      });
    }
    setRequiredDocuments([...documents]);
    setSubmissionFormData({
      ...submissionFormData,
    });
  }, []);

  const onUpdate = (data) => {
    setSubmissionFormData({
      ...submissionFormData,
      ...data,
    });
  };

  const onFinish = (body) => {
    createOrUpdateOfferSubmission(body);
  };

  const createOrUpdateOfferSubmission = (body) => {
    setLoading(true);

    const { closeModal } = body || { closeModal: false };

    const data = {
      ...submissionFormData,
      ...body,
      Id: Number(offerData.Id),
      OfferIteration: {
        ...body.OfferIteration,
        Id: Number(submissionFormData.OfferIteration.Id),
      },
    };

    delete data.displayOfferSubmissionForm;

    dispatch(
      requestCreateOfferSubmissionEffect(data, {}, (err, res) => {
        if (!err) {
          const toaster = `Offer edited successfully!`;
          showSuccessMessage(toaster);
          setOfferSubmission(res?.data?.value);
          getUpdatedIterationData();
          setLoading(false);
        } else {
          setLoading(false);
        }
      }),
    );
  };

  const stagesStep = {
    ContactInformation: 0,
    Highlights: 1,
    Documents: 2,
    OfferExpiration: 3,
  };

  return (
    <Modal
      open={isOpen}
      width={680}
      footer={null}
      onCancel={() => setEditModalVisibility(false)}
      className={styles.editModal}
      cancelButtonStyles={styles.crossButton}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div>
        <h1 className={styles.heading}>Edit Offer</h1>
        <Row className={styles.content}>
          <Col span={24}>
            <Icons className={styles.condoIcon} variant={'condo'} />
            <div className={styles.details}>
              <h4 className={styles.address}>{transaction?.transactionAddress}</h4>
              <div className={styles.subDetails}>
                <p>
                  <span>List Price: </span> ${transaction?.transactionPrice?.toLocaleString()}
                </p>
                <span className={styles.separator}></span>
                <p>
                  <span>Listing Agent: </span>
                  {seller ? `${seller.FirstName} ${seller.LastName}` : 'N/A'}
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Stages
          stages={[
            { Component: ContactInformation },
            { Component: Highlights },
            { Component: Document },
            { Component: OfferExpiration },
          ]}
          classNameProgress={styles.progressBar}
          classNameWrapper={styles.stagesWrapper}
          transaction={offerLinkDetails}
          startStageIndex={stagesStep.OfferExpiration}
          onUpdate={onUpdate}
          onFinish={onFinish}
          data={{ ...submissionFormData }}
          stagesStep={stagesStep}
          offerSubmission={offerSubmission}
          loading={loading}
          editModal={true}
        />
      </div>
    </Modal>
  );
};
