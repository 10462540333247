/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash-es';

import { Input, Phone } from 'components';
import { Add } from 'components/Icons';

import styles from './styles.module.scss';

const InputList = ({ list, formik, name, addBtnTitle, disabled, isPhone }) => {
  const [listValue, setListValue] = useState(list);

  const onAdd = () => {
    setListValue((prevValue) => [...prevValue, '']);
  };

  const onChange = (value, index) => {
    const copyList = [...listValue];
    copyList.splice(index, 1, value);
    setListValue([...copyList]);
    formik.setFieldValue(name, [...copyList]);
  };

  const onRemove = (index) => {
    const copyList = [...listValue];
    copyList.splice(index, 1);
    setListValue(copyList);
    formik.setFieldValue(name, [...copyList]);
  };

  const getItem = ({ index, item }) => {
    if (isPhone) {
      return (
        <Phone
          name={`${item}-${index}`}
          value={item}
          className={styles.input}
          removeHandler={() => onRemove(index)}
          onChange={(e, value) => onChange(value, index)}
          error={get(formik.touched, name)?.[index] ? get(formik.touched, name)?.[index] : ''}
          disabled={disabled}
        />
      );
    }
    return (
      <Input
        name={`${item}-${index}`}
        value={item}
        className={styles.input}
        onChange={(e, value) => onChange(value, index)}
        removeHandler={() => onRemove(index)}
        error={get(formik.touched, name)?.[index] ? get(formik.errors, name)?.[index] : ''}
        disabled={disabled}
      />
    );
  };

  return (
    <div>
      {listValue.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>{getItem({ index, item })}</div>
      ))}
      <button
        type="button"
        onClick={onAdd}
        className={classNames(styles.addLinkBtn, { [styles.first]: listValue.length === 0 })}
      >
        <Add className={styles.addLinkBtnIcon} color={Add.ORANGE_COLOR} />
        <span>{addBtnTitle}</span>
      </button>
    </div>
  );
};

InputList.propTypes = {
  disabled: PropTypes.bool,
  isPhone: PropTypes.bool,
  name: PropTypes.string.isRequired,
  addBtnTitle: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string),
  formik: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
  }).isRequired,
};

InputList.defaultProps = {
  list: [],
  isPhone: false,
  disabled: false,
};

export default InputList;
