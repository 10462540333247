import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import StatItem from './StatItem';
import { getTransactionTasksStatisticsSelector } from 'store/selectors/transactionTasks';

import styles from './styles.module.scss';

const Statistics = (props) => {
  const { className } = props;
  const { newTasksCount, overdueTasksCount, upcomingTasksCount } = useSelector(
    getTransactionTasksStatisticsSelector,
  );

  return (
    <div className={classNames(styles.statistics, className)}>
      <StatItem
        testid="new_tasks"
        variant={StatItem.NEW}
        title="New Tasks"
        number={newTasksCount}
      />
      <StatItem
        testid="overdue_tasks"
        variant={StatItem.OVERDUE}
        title="Overdue Tasks"
        number={overdueTasksCount}
      />
      <StatItem
        testid="upcoming_tasks"
        variant={StatItem.UPCOMING}
        title="Upcoming Tasks"
        number={upcomingTasksCount}
      />
    </div>
  );
};

Statistics.propTypes = {
  className: PropTypes.string,
};

Statistics.defaultProps = {
  className: '',
};

export default Statistics;
