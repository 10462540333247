import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';

import {
  deleteQuoteResponseAction,
  requestGetQuoteDetailsAction,
  requestPostArchiveQuotesAction,
  resetQuoteDetailsAction,
  respondAndUploadDocumentAction,
} from 'store/actions/quotes';
import { get, cloneDeep } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetQuoteDetailsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestPostArchiveQuotesAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result.0', state.data),
    }),
    [respondAndUploadDocumentAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result', state.data),
    }),
    [deleteQuoteResponseAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result', state.data),
    }),
    [resetQuoteDetailsAction]: () => cloneDeep(initialData),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
