import { createSelector } from 'reselect';
import { get } from 'lodash-es';

const localState = ({ drawers }) => get(drawers, 'threadContacts');

export const getDrawerState = createSelector(localState, (state) => state);

export const isThreadContactsDrawerOpenSelector = createSelector(localState, ({ open }) => !!open);

export const getThreadContactsDrawerTypeSelector = createSelector(localState, ({ type }) => type);

export const getThreadContactsDrawerParams = createSelector(localState, ({ params }) => params);
