export const gridColumns = [
  {
    title: 'Type',
    propKey: 'Type',
  },
  {
    title: 'Template',
    propKey: 'Template',
  },
];
