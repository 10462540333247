export const renderValue = (value: string) => {
  if (value?.includes('$')) {
    const number = parseFloat(value.replace('$', ''));
    return number.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });
  }
  return value;
};
