import { routes } from 'settings/navigation/routes';
import loginRoutes from 'settings/navigation/routes/login';
import signUpRoutes from 'settings/navigation/routes/signUp';
import onBoardingRoutes from 'settings/navigation/routes/onBoarding';
import { matchComponentConfig } from 'helpers';
import { Security } from 'services/security';

interface RouteConfig {
  visible: boolean;
  showChildren: boolean;
  logoText?: string;
  hideActions?: boolean;
  hideAvatar?: boolean;
  previewForm?: boolean;
  minimizeAvatar?: boolean;
  minimizeLogo?: boolean;
  headerLink?: string;
  absoluteHeader?: boolean;
  visitor?: boolean;
}

interface HeaderConfig {
  [key: string]: RouteConfig;
}

function getRouteConfig(route, visible, showChildren = false, visitor = false) {
  let rules: HeaderConfig = {};

  Object.keys(route).map((el) => {
    rules[route[el]] = {
      visible,
      showChildren,
      visitor,
      absoluteHeader: true,
    };
  });

  return rules;
}

const isLoggedIn = Security.isLoggedIn();

const headerConfig: HeaderConfig = {
  ...getRouteConfig(loginRoutes, false),
  ...getRouteConfig(signUpRoutes, false),
  ...getRouteConfig(onBoardingRoutes, false),
  [(routes.appointment, routes.showing)]: {
    visible: true,
    showChildren: isLoggedIn,
    hideActions: !isLoggedIn,
    hideAvatar: !isLoggedIn,
    visitor: !isLoggedIn,
  },
  [routes.offerSubmission]: {
    visible: true,
    showChildren: isLoggedIn,
    hideActions: !isLoggedIn,
    hideAvatar: !isLoggedIn,
    visitor: !isLoggedIn,
  },
  [routes.dashboardServiceDirectoryFormsQuoteFormPreview]: {
    visible: true,
    showChildren: true,
    hideActions: true,
    hideAvatar: true,
    previewForm: true,
    minimizeAvatar: true,
    minimizeLogo: true,
    logoText: 'Form Builder',
    visitor: false,
    headerLink: routes.dashboardServiceDirectoryForms,
  },
  [routes.dashboardServiceDirectoryFormsSmartFormPreview]: {
    visible: true,
    showChildren: true,
    hideActions: true,
    hideAvatar: true,
    previewForm: true,
    minimizeAvatar: true,
    minimizeLogo: true,
    logoText: 'Form Builder',
    visitor: false,
    headerLink: routes.dashboardServiceDirectoryForms,
  },
  [routes.dashboardServiceDirectoryFormsQuoteForm]: {
    visible: true,
    showChildren: true,
    hideActions: true,
    minimizeAvatar: true,
    minimizeLogo: true,
    logoText: 'Form Builder',
    visitor: false,
    headerLink: routes.dashboardServiceDirectoryForms,
  },
  [routes.dashboardServiceDirectoryFormsSmartForm]: {
    visible: true,
    showChildren: true,
    hideActions: true,
    minimizeAvatar: true,
    minimizeLogo: true,
    logoText: 'Form Builder',
    visitor: false,
    headerLink: routes.dashboardServiceDirectoryForms,
  },
  [routes.dashboardServiceDirectoryForms]: {
    visible: true,
    showChildren: false,
    hideActions: true,
    minimizeLogo: true,
    logoText: 'Form Builder',
    visitor: false,
  },
  [routes.offerPortal]: {
    visible: true,
    showChildren: isLoggedIn,
    hideActions: !isLoggedIn,
    hideAvatar: !isLoggedIn,
    visitor: !isLoggedIn,
  },
  [routes.workshopFormNotAvailable]: {
    visible: true,
    showChildren: isLoggedIn,
    hideActions: !isLoggedIn,
    hideAvatar: !isLoggedIn,
    visitor: !isLoggedIn,
  },
  [routes.workshopFormProcessDocumentAnon]: {
    visible: true,
    showChildren: isLoggedIn,
    hideActions: !isLoggedIn,
    hideAvatar: !isLoggedIn,
    visitor: !isLoggedIn,
  },
  [routes.workshopDynamicFormSuccess]: {
    visible: true,
    showChildren: isLoggedIn,
    hideActions: !isLoggedIn,
    hideAvatar: !isLoggedIn,
    visitor: !isLoggedIn,
  },
  [routes.workshopDynamicFormDocumentAnon]: {
    visible: true,
    showChildren: isLoggedIn,
    hideActions: !isLoggedIn,
    hideAvatar: !isLoggedIn,
    visitor: !isLoggedIn,
  },
  [routes.workshopFormProcessDocumentWebView]: {
    visible: false,
    showChildren: false,
    hideActions: true,
    hideAvatar: true,
    visitor: false,
  },
  [routes.verifyEmail]: {
    visible: false,
    showChildren: isLoggedIn,
    hideActions: !isLoggedIn,
    hideAvatar: !isLoggedIn,
    visitor: !isLoggedIn,
  },
  [routes.kitCta]: {
    visible: true,
    showChildren: isLoggedIn,
    hideActions: !isLoggedIn,
    hideAvatar: !isLoggedIn,
    visitor: !isLoggedIn,
  },
  [routes.kitsUnsubscribe]: {
    visible: true,
    showChildren: isLoggedIn,
    hideActions: !isLoggedIn,
    hideAvatar: !isLoggedIn,
    visitor: !isLoggedIn,
  },
};

const headerConfigKeys: string[] = Object.keys(headerConfig);

export const getHeaderConfig = (pathname) => {
  const match = matchComponentConfig(pathname, headerConfigKeys);
  if (match) {
    return headerConfig[match.path];
  }
  return null;
};
