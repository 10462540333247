import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { routes } from 'settings/navigation/routes';
import { Spinner } from 'components';
import {
  deactivateSearchInstanceEffect,
  renameSearchInstanceEffect,
} from 'store/effects/mySearches';
import SearchCard from './SearchCard';
import {
  setRenameSearchInstanceDrawerAction,
  setStatusSearchInstanceDrawerAction,
} from 'store/actions/drawerActiveSearches';
import { appManageClientDrawerAction } from 'store/actions/app';
import { setProfileExpandedSection } from 'store/effects/app';

import { showSuccessMessage } from 'helpers/success';
import { ProfileIcons } from '../Icons';
import { getClientInstancesEffect } from 'store/effects/clientInstances';
import Status from '../Status';

import styles from './styles.module.scss';
import {
  selectedSearchAction,
  setCriteriaAction,
  setSelectedClientSavedSearchAction,
} from 'store/actions/feedv3';
import { useAgentSearchInstance } from 'pages/Properties/AgentFeed/hooks/useAgentSearchInstance';
import { getClientsListDataArray } from 'store/selectors/clientsList';
import { getUserRolesMapSelector } from 'store/selectors/user';

enum filterType {
  Active = 'Active',
  Inactive = 'Inactive',
  All = 'All',
}

export const ActiveSearches = ({ activeSearches, isLoading, clientId = null, view = '' }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isClient } = useSelector(getUserRolesMapSelector);
  const clientsList = useSelector(getClientsListDataArray);
  const [filter, setFilter] = useState<filterType>(filterType.Active);
  const getAgentSearchInstance = useAgentSearchInstance();

  const getSearchHandler = useCallback(
    (criterias) => () => {
      if (view === 'expanded') {
        dispatch(setProfileExpandedSection(false));
      }
      dispatch(appManageClientDrawerAction(false));
      const item = {
        id: criterias?.Id,
        name: criterias?.Name,
        criterias: criterias?.DefaultPropertySearchPreferences,
      };
      const client = clientsList?.find(({ Id }) => Id === clientId);
      dispatch(
        selectedSearchAction({
          selectedSearch: item,
        }),
      );
      dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: client }));
      if (history.location.pathname !== routes.feed) {
        history.push(routes.feed);
        dispatch(
          setCriteriaAction({
            criteria: criterias.DefaultPropertySearchPreferences,
          }),
        );
      } else {
        getAgentSearchInstance(
          {
            criterias: criterias.DefaultPropertySearchPreferences,
          },
          undefined,
          client,
        );
      }
    },
    [dispatch],
  );

  const getRenameHandler = (id) => (name, onCloseRenameModal) => {
    dispatch(
      renameSearchInstanceEffect({ id, name }, {}, (err) => {
        if (!err) {
          dispatch(setRenameSearchInstanceDrawerAction({ id, name }));
          showSuccessMessage('Search Instance Renamed Successfully');
        }
        onCloseRenameModal();
      }),
    );
  };

  const getInactiveHandler = useCallback(
    (id) => () => {
      showSuccessMessage('Success');
      dispatch(
        deactivateSearchInstanceEffect({ id }, {}, (err) => {
          if (!err) {
            isClient && dispatch(getClientInstancesEffect());
            dispatch(setStatusSearchInstanceDrawerAction({ id, status: 'Inactive' }));
            showSuccessMessage('Search Instance Deactivated');
          }
        }),
      );
    },
    [dispatch],
  );
  const getFilteredSearches = (status) => {
    let filtered = [];
    if (status !== 'All') {
      filtered =
        activeSearches &&
        activeSearches.filter(function (item) {
          return item.Status === status;
        });
      return filtered;
    } else {
      return [...activeSearches];
    }
  };

  const getActiveSearches = () => {
    var filtered =
      activeSearches &&
      activeSearches.filter((item) => {
        return item.Status === 'Active';
      });
    return filtered.length || 0;
  };
  const handleStatusChange = (status: filterType) => {
    setFilter(status);
  };

  return (
    <>
      <div className={styles.containerActiveSearch}>
        {view === 'expanded' ? (
          <div className={styles.expandedTransaction}>
            <div className={styles.expandedTransactionHeading}>
              <ProfileIcons name={'Searches'} />
              <h3>Searches</h3>
            </div>
            <Status
              options={ActiveSearches.options}
              handleStatusChange={handleStatusChange}
              filter={filter}
            />
          </div>
        ) : activeSearches && getActiveSearches() ? (
          <h3>Active Searches</h3>
        ) : (
          ''
        )}

        <div className={styles.CardsContainer}>
          {isLoading ? (
            <Spinner loaderClassName={styles.loader} />
          ) : activeSearches?.length ? (
            activeSearches.map(
              (search) =>
                (search.Status === filter || filter === 'All') && (
                  <SearchCard
                    testid="active_search_block"
                    photos={search.PreviewPhotoUrls}
                    key={search.Id}
                    title={search.Name}
                    agentName={search.AgentName}
                    status={search.Status}
                    info={search.DefaultPropertySearchPreferences}
                    footer={`${search.NumOfResults || 'no'} results`}
                    onDelete={null}
                    onSearch={null}
                    onEdit={getSearchHandler(search)}
                    onClick={getSearchHandler(search)}
                    onRename={getRenameHandler(search.Id)}
                    onInactive={getInactiveHandler(search.Id)}
                    view={view}
                  />
                ),
            )
          ) : (
            ''
          )}
          <div>
            {view === 'expanded' && !getFilteredSearches(filter)?.length ? (
              <div className={styles.noResults}>No Results</div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};
ActiveSearches.options = [
  { id: 1, label: 'Active', value: 'Active' },
  { id: 1, label: 'Inactive', value: 'Inactive' },
  { id: 1, label: 'All', value: 'All' },
];
