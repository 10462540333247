import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { routes } from 'settings/navigation/routes';
import { PageWrapper } from 'components-antd';
import { Wrapper } from 'components';
import {
  LocalHeader,
  DocumentsContent,
  DocumentOptions,
  AvatarColumn,
  DocumentNameColumn,
  DateTimeColumn,
  EmptyState,
} from '../components';
import { Icons } from 'pages/Vault/Icons';
import { DocsContentView } from '../constants';

import { getArchiveVaultDocumentsList } from 'store/selectors/vault';
import { getArchiveDocumentsEffect } from 'store/effects';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getClientContextsStateSelector } from 'store/selectors/contexts';
import { READY, PENDING } from 'settings/constants/apiState';

import styles from './styles.module.scss';

export const Archives = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [viewType, setViewType] = useState(DocsContentView.GRID_VIEW);
  const searchContext = useSelector(getCurrentContextSelector);
  const contextsState = useSelector(getClientContextsStateSelector);
  const selectedAgentId = searchContext?.Agents?.[0]?.Id;
  const { data, isPending } = useSelector(getArchiveVaultDocumentsList);
  const { clientArchiveDocuments } = data || {};

  const handleBackBtn = () => history.push(routes.vault);

  const fetchArchiveDocuments = () => {
    dispatch(
      getArchiveDocumentsEffect({
        id: selectedAgentId,
      }),
    );
  };

  useEffect(() => {
    if (contextsState.state === READY && selectedAgentId) fetchArchiveDocuments();
  }, [contextsState.state, selectedAgentId]);

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (_, { Title, Filename, FormName }) => {
        const name = Title || Filename || FormName;
        return <DocumentNameColumn name={name} docIconType={Icons.DOCUMENT_1} />;
      },
      sorter: (a, b) => {
        const aName = a?.Title || a?.Filename || a?.FormName;
        const bName = b?.Title || b?.Filename || b?.FormName;
        return aName?.localeCompare(bName);
      },
    },
    {
      title: 'Owner',
      key: 'owner',
      render: (_, { UploaderFirstName, UploaderLastName, AvatarUrl }) => {
        return (
          <AvatarColumn name={`${UploaderFirstName} ${UploaderLastName}`} imgSrc={AvatarUrl} />
        );
      },
      sorter: (a, b) => {
        const aName = `${a?.UploaderFirstName} ${a?.UploaderLastName}`;
        const bName = `${b?.UploaderFirstName} ${b?.UploaderLastName}`;
        return aName?.localeCompare(bName);
      },
    },
    {
      title: 'Created',
      key: 'uploaded',
      dataIndex: 'uploaded',
      render: (_, { CreatedDate }) => {
        return <DateTimeColumn date={CreatedDate} />;
      },
      sorter: (a, b) => a?.CreatedDate?.localeCompare(b?.CreatedDate),
    },
    {
      title: '',
      key: '',
      render: (_, file) => {
        return (
          <div className={styles.optionsColumn}>
            <DocumentOptions
              className={styles.bottomOffset}
              file={file}
              Property={file?.Property}
              refetch={fetchArchiveDocuments}
              isArchives
            />
          </div>
        );
      },
    },
  ];

  const isEmptyArchiveDocument = useMemo(
    () =>
      !clientArchiveDocuments?.length ||
      clientArchiveDocuments.every((doc) => !doc?.FormDocuments?.length || doc?.Documents?.length),
    [clientArchiveDocuments],
  );

  return (
    <PageWrapper mainPageContentStyle={styles.pagecontentStyles}>
      <LocalHeader
        title="Archives"
        handleBackBtn={handleBackBtn}
        viewType={viewType}
        setViewType={setViewType}
        hideUpload
      />
      <div className={styles.archivesPage}>
        <Wrapper isPending={contextsState?.state === PENDING || isPending}>
          {!isEmptyArchiveDocument ? (
            clientArchiveDocuments?.map((item, idx) => (
              <DocumentsContent
                key={idx}
                title={item?.Property?.Address?.Line1 || 'Misc.'}
                totalCount={item?.Documents?.length || item?.FormDocuments?.length}
                columns={columns}
                data={
                  item?.Documents?.map((i) => ({ ...i, Property: item?.Property })) ||
                  item?.FormDocuments
                }
                Property={item?.Property}
                viewType={viewType}
                refetch={fetchArchiveDocuments}
                isArchives
              />
            ))
          ) : (
            <EmptyState />
          )}
        </Wrapper>
      </div>
    </PageWrapper>
  );
};
