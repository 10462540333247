import { quoteRequestStatus, quotesStatusesMap } from 'settings/constants/quotes';
import { groupBy, filter } from 'lodash-es';

export const filterArchivedQuotes = (quotes, isArchive) =>
  filter(quotes, (quote) => quote?.IsArchived === isArchive);

export const getCountQuotesByStatus = (quotes, status, userRole) =>
  quotes.reduce(
    (acc, quote) => (quotesStatusesMap(userRole)[quote?.Status] === status ? acc + 1 : acc),
    0,
  );

export const getPendingCountQuotesByStatus = (quotes) =>
  quotes.reduce(
    (acc, quote) =>
      quote?.Status === quoteRequestStatus.PendingAnswer && quote?.DateViewedByPartner
        ? acc + 1
        : acc,
    0,
  );

const getPendingNewCountForExistingQuotes = (quotes) =>
  quotes?.reduce((acc, quote) => (quote?.Status === quoteRequestStatus.New ? acc + 1 : acc), 0);

export const getPendingNewCountQuotes = (quotes) =>
  quotes.reduce(
    (acc, quote) =>
      quote?.Status === quoteRequestStatus.PendingAnswer && !quote?.DateViewedByPartner
        ? acc + 1
        : acc,
    0,
  );

export const getArchievedCountQuotes = (quotes) =>
  quotes.reduce((acc, quote) => (quote?.IsArchived ? acc + 1 : acc), 0);

export const convertQuotesData = ({ arrQuotes, isArchive, additionalParams = {}, userRole }) =>
  Object.entries(arrQuotes).map(([name, quotes]) => {
    const filteredQuotes = filterArchivedQuotes(quotes, isArchive);
    const pendingCount = getPendingCountQuotesByStatus(filteredQuotes);
    const receivedCount = getCountQuotesByStatus(
      filteredQuotes,
      quotesStatusesMap(userRole)[quoteRequestStatus.Responded],
      userRole,
    );
    const draftCount = getCountQuotesByStatus(
      filteredQuotes,
      quotesStatusesMap(userRole)[quoteRequestStatus.Draft],
      userRole,
    );
    const newCount =
      getPendingNewCountQuotes(filteredQuotes) +
      getPendingNewCountForExistingQuotes(filteredQuotes);
    const archievedCount = getArchievedCountQuotes(quotes);
    const quotesIds = [];
    const quotesIdsWithStatus = [];
    (filteredQuotes || []).forEach((quote) => {
      quotesIds.push(quote?.Id);
      quotesIdsWithStatus.push({
        id: quote?.Id,
        status: quote?.Status,
      });
    });

    return {
      name,
      pendingCount,
      receivedCount,
      archievedCount,
      draftCount,
      newCount,
      icon: additionalParams.isGroupedByClient
        ? quotes?.[0]?.Client?.AvatarUrl
        : quotes?.[0]?.Category?.Icon,
      quotes: filteredQuotes,
      quotesIds,
      quotesIdsWithStatus,
      ...additionalParams,
    };
  });

export const groupByClientName = (quotes) =>
  groupBy(quotes, (quote) => {
    const { FirstName, LastName } = quote?.Client || {};
    if (!FirstName && !LastName) return 'Ungrouped';
    return `${quote?.Client?.FirstName} ${quote?.Client?.LastName}`;
  });
