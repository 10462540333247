import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getImagesPreview } from 'helpers';
import Icons from '../Icons';
import styles from './styles.module.scss';
import { WarnNotification } from 'components-antd';

const Attachment = (props) => {
  const { id, className, onAttach, value } = props;
  const fileInputRef = useRef();

  useEffect(() => {
    if (!value?.length && fileInputRef?.current) {
      fileInputRef.current.value = null;
    }
  }, [value?.length]);

  const onAttachHandler = (event) => {
    // filter files greater than 50 MB
    const files = [...event.target.files].filter((file) => file.size < 5 * 1e7);

    if (!files.length) {
      WarnNotification({
        message: 'You cannot upload file(s) larger than 50MB',
      });
    } else {
      if (files.length < event?.target?.files?.length) {
        WarnNotification({
          message: 'Some file(s) having size greater than 50MB were not uploaded',
        });
      }
      getImagesPreview(files, true).then((values) => {
        const preparedValues = values.map((val) => ({
          ContentType: val.contentType,
          Filename: val.fileName,
          Url: val.url,
        }));
        onAttach(files, preparedValues);
      });
    }
  };

  return (
    <div className={classNames(styles.attachments, className)}>
      <label className={styles.fileInput} htmlFor={id}>
        <Icons testid="attachment_icon" className={styles.icon} variant={Icons.ATTACHMENT} />
        <input
          ref={fileInputRef}
          multiple
          type="file"
          onChange={onAttachHandler}
          className={styles.nativeFileInput}
          id={id}
          testid="attachment_input"
        />
      </label>
    </div>
  );
};

Attachment.propTypes = {
  className: PropTypes.string,
  onAttach: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string.isRequired,
};

Attachment.defaultProps = {
  className: '',
  onAttach: () => {},
  value: [],
};

export default Attachment;
