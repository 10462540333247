import { routes } from 'settings/navigation/routes';
import { Properties } from 'pages';
import { CLIENT, AGENT, THIRD_PARTY } from 'settings/constants/roles';

export default [
  {
    path: routes.clientSearches,
    component: Properties,
    exact: true,
    roles: [CLIENT, AGENT, THIRD_PARTY],
  },
];
