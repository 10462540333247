import moment from 'moment';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { handleViewForm } from 'utils';
import { Space } from 'components-antd';
import { Icons } from 'pages/Workshop/Forms/Icons';
import { TransactionCloseComponentIds } from 'app-constants';
import { getAllFormProcessEffect } from 'store/effects/formProcess';
import { getTransactionSelector } from 'store/selectors/transaction';
import { FormDetails } from 'pages/Workshop/Forms/components/FormDetails';
import Question from 'pages/Workshop/Transactions/TransactionCreate/components/Question';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { FormProcessTypes } from 'types';

import styles from './styles.module.scss';

interface IncompleteFormsProps {
  getUpdatedTransactionFormData: () => void;
  previousStage?: TransactionCloseComponentIds;
  setStage: (stage: TransactionCloseComponentIds) => void;
}

export const IncompleteForms = ({
  getUpdatedTransactionFormData,
  previousStage,
  setStage,
}: IncompleteFormsProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { transaction, allTransactionFormProcesses } = useSelector(getTransactionSelector);

  const [selectedFormProcess, setSelectedFormProcess] = useState<number>();

  useEffect(() => {
    if (!allTransactionFormProcesses.length) {
      if (previousStage === TransactionCloseComponentIds.UploadFiles) {
        setStage(TransactionCloseComponentIds.PendingTasks);
      } else if (previousStage === TransactionCloseComponentIds.PendingTasks) {
        setStage(TransactionCloseComponentIds.UploadFiles);
      }
    }
  }, [allTransactionFormProcesses]);

  const getStatus = (isInitiated: boolean, isEdit: boolean, isSign: boolean, date: Date) => {
    let phrase = <></>;
    const fromNowDate = moment(date).fromNow();

    if (isInitiated) {
      phrase = <span className={styles.initiatedStatus}>Draft Form</span>;
    } else if (isEdit) {
      phrase = <span className={styles.otherStatus}>Form Incomplete: {fromNowDate}</span>;
    } else if (isSign) {
      phrase = <span className={styles.otherStatus}>Signature Needed: {fromNowDate}</span>;
    }

    return (
      <div className={styles.formStatus}>
        <Icons variant="exclamation" stroke={isInitiated ? '#747475' : '#FF576D'} />
        {phrase}
      </div>
    );
  };

  const showRequiredLabel = (formProcessId: number) => {
    const formTask = transaction?.Tasks?.find(
      (task) => task?.formProcessDetails?.formProcessId === formProcessId,
    );

    return formTask?.isRequired ? (
      <div className={styles.formActions}>
        <div className={styles.pill}>Required</div>
      </div>
    ) : (
      <></>
    );
  };

  const onClickHandler = (
    isInitiated: boolean,
    formProcessId: number,
    url: string,
    type: FormProcessTypes,
  ) => {
    if (isInitiated) {
      handleViewForm(history, url, type);
    } else {
      setSelectedFormProcess(formProcessId);
    }
  };

  const onCloseFormDetailModal = () => {
    setSelectedFormProcess(undefined);
  };

  const handleCancelForm = () => {
    dispatch(getAllFormProcessEffect());
    getUpdatedTransactionFormData();
  };

  return allTransactionFormProcesses.length ? (
    <div className={styles.incompleteForms}>
      <Question>The following forms are incomplete:</Question>
      <AnswersContainer>
        {selectedFormProcess ? (
          <FormDetails
            selectedFormProcess={selectedFormProcess}
            onClose={onCloseFormDetailModal}
            onCancelForm={handleCancelForm}
            onHandleViewForm={(url, type) => handleViewForm(history, url, type)}
          />
        ) : (
          <></>
        )}

        <Space size="middle" className={styles.spacing} direction="vertical">
          {allTransactionFormProcesses.map((formProcess, index) => (
            <div
              key={`${formProcess.Id}-${index}`}
              className={classnames(styles.incompleteForm, {
                [styles.notAllowed]: !formProcess.isParticipant,
              })}
              onClick={() =>
                formProcess.isParticipant
                  ? onClickHandler(
                      formProcess.isInitiated,
                      formProcess.Id,
                      formProcess.url,
                      formProcess.type,
                    )
                  : undefined
              }
            >
              <div className={styles.formData}>
                <span className={styles.formName}>{formProcess.formName}</span>
                {getStatus(
                  formProcess.isInitiated,
                  formProcess.isEdit,
                  formProcess.isSign,
                  formProcess.updatedDate,
                )}
              </div>
              {showRequiredLabel(formProcess.Id)}
            </div>
          ))}
        </Space>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue
          onClick={() => {
            setStage(TransactionCloseComponentIds.PendingTasks);
          }}
          className={styles.submit}
        />
      </ButtonsContainer>
    </div>
  ) : (
    <></>
  );
};
