import AntSkeleton, { SkeletonProps as AntSkeletonProps } from 'antd/lib/skeleton';

import styles from './styles.module.scss';

interface SkeletonProps extends AntSkeletonProps {
  showSkeleton: boolean;
  rowCount?: number;
}

export const Skeleton = ({ showSkeleton, rowCount = 1, children, ...props }: SkeletonProps) => (
  <>
    {showSkeleton
      ? [...Array(rowCount)].map((el, index) => (
          <AntSkeleton
            key={index}
            className={styles.skeleton}
            active={true}
            title={true}
            paragraph={false}
            {...props}
          />
        ))
      : children}
  </>
);
