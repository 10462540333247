import { Icons } from '../../../Icons';

import styles from '../styles.module.scss';
import { IconText } from '../IconText';

export const EditListingAgent = ({ data, onEdit }) => {
  const getInfo = () => {
    const { ListingAgentName } = data;

    return ListingAgentName || 'N/A';
  };

  return (
    <div className={styles.editForm}>
      <IconText
        text={'Who should be displayed as the listing agent on the submission page?'}
        infoText={getInfo()}
        variant={'user-bg'}
        className={styles.editFormInfo}
      />
      <div className={styles.editIcon} onClick={onEdit}>
        <Icons variant={'edit'} />
      </div>
    </div>
  );
};
