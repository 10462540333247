import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { extractClientAddress } from 'utils';

import styles from './styles.module.scss';

const BuyerInfo = (props) => {
  const { className, user } = props;

  if (!user) {
    return null;
  }

  const clientAddress = extractClientAddress(user?.Address);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.inputBlock}>
        <span className={styles.label}>Address</span>
        <span className={styles.infoBlockForMultipleLines}>{clientAddress.address}</span>
      </div>
    </div>
  );
};

BuyerInfo.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    Address: PropTypes.string,
  }).isRequired,
};

BuyerInfo.defaultProps = {
  className: '',
};

export default BuyerInfo;
