/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Wrapper } from 'components';
import Input from 'components/Form/Input';
import {
  getTaskCategoryDashboardSelector,
  getTaskCategoryDataDashboardSelector,
} from 'store/selectors/adminPanel';
import {
  createTaskCategoryEffect,
  getTaskCategoryDashboardEffect,
  updateTaskCategoryEffect,
} from 'store/effects/adminPanel';

import { ValidationSchema } from './validation';
import styles from './styles.module.scss';

const TaskCategoryForm = ({ id, onSubmissionComplete }) => {
  const dispatch = useDispatch();
  const category = useSelector(getTaskCategoryDataDashboardSelector);
  const { isPending, isError, meta } = useSelector(getTaskCategoryDashboardSelector);

  useEffect(() => {
    if (id) {
      dispatch(getTaskCategoryDashboardEffect({ id }));
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
  const isNotFound = isError && meta?.status === 404;

  const onSubmit = useCallback(
    (values) => {
      if (id) {
        dispatch(
          updateTaskCategoryEffect({ values, id }, (err) => {
            if (!err) {
              onSubmissionComplete();
            }
          }),
        );
      } else {
        dispatch(
          createTaskCategoryEffect(values, (err) => {
            if (!err) {
              onSubmissionComplete();
            }
          }),
        );
      }
    },
    [dispatch, id, onSubmissionComplete],
  );

  const formik = useFormik({
    initialValues: id ? category || TaskCategoryForm.initialValues : TaskCategoryForm.initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit,
  });

  return (
    <>
      {isNotFound ? (
        <div>Ups, this category is not found...</div>
      ) : (
        <Wrapper isPending={isPending}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.wrapper}>
              <div className={styles.inputBlock}>
                <label htmlFor="Name" className={styles.editLabel}>
                  Name
                </label>
                <div className={styles.rightBlock}>
                  <Input
                    id="Name"
                    className={styles.input}
                    type="text"
                    name="Name"
                    error={formik.touched.Name ? formik.errors.Name : ''}
                    value={formik.values.Name}
                    onChange={formik.handleChange}
                    disabled={isPending}
                  />
                </div>
              </div>
            </div>
            <div className={styles.buttonsWrapper}>
              {formik.dirty ? (
                <Button
                  type="submit"
                  className={styles.saveBtn}
                  title="Save"
                  isPending={isPending}
                />
              ) : (
                <Button type="button" className={styles.saveBtn} title="Save" />
              )}
            </div>
          </form>
        </Wrapper>
      )}
    </>
  );
};

TaskCategoryForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSubmissionComplete: PropTypes.func.isRequired,
};

TaskCategoryForm.defaultProps = {
  id: null,
};

TaskCategoryForm.initialValues = {
  Name: '',
};

export default TaskCategoryForm;
