import { tasksStatusesIds } from 'settings/constants/transactionTasks';

export const prepareCancellationTasks = (taskDetails, transactionId) => {
  return taskDetails.map((item) => ({
    ...item,
    Id: +item.transactionActionId,
    TransactionId: transactionId,
    Status: tasksStatusesIds.new,
  }));
};
