import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { IDLE, PENDING } from 'settings/constants/apiState';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { uploadPreApprovalDocumentEffect } from 'store/effects';
import { appOpenModalEffect } from 'store/effects/app';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getClientContextsEffect } from 'store/effects/context';
import { getClientContextsStateSelector } from 'store/selectors/contexts';

import RequestModal from '../ApproveTrustedLenders/RequestModal';
import { File } from './Documents/File';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';
import { OnboardingSkipButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingSkipButton';
import { Question } from 'pages/RequestQuote/components';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

import styles from './styles.module.scss';
import { Button } from 'components-antd';

const StageDocument = ({ isModal, Controls, className, onNext }) => {
  const onBoarding = useSelector(getOnBoardingSelector);
  const { isClient } = useSelector(getUserRolesMapSelector);
  const contextsState = useSelector(getClientContextsStateSelector);
  const [isPending, setIsPending] = useState(onBoarding.state === PENDING);
  const [file, setFile] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();

  const fetchContexts = useCallback(() => dispatch(getClientContextsEffect()), [dispatch]);

  useEffect(() => {
    if (isClient && contextsState.state === IDLE) fetchContexts();
  }, []);

  const handleFileSelect = (selectedFile) => {
    setFile(selectedFile);
    selectedFile.length > 0 ? setIsDisabled(false) : setIsDisabled(true);
  };

  const onSave = () => {
    setIsPending(true);
    dispatch(
      uploadPreApprovalDocumentEffect({ files: file }, {}, (err) => {
        if (!err) {
          setIsPending(false);
          isModal ? onNext() : dispatch(appOpenModalEffect({ id: RequestModal.id, open: true }));
        }
      }),
    );
  };

  return (
    <OnBoardingWrapper testid="document_stage" isPending={isPending} className={className}>
      {!isModal && (
        <Controls className={styles.controls} isNextDisabled={true} variant="lightFull" />
      )}
      <OnBoardingContainer>
        <Question className={styles.question}>
          Would you like to upload your <br /> pre-approval document?
        </Question>
        <p className={styles.subTitle}>Only your agent will have access to it.</p>
        <AnswersContainer className={{ [styles.modalAnswersContainer]: isModal }}>
          <File onFileSelect={handleFileSelect} />
          {!isModal ? (
            <ButtonsContainer>
              <OnboardingContinueButton
                testid="document_next"
                onClick={onSave}
                isPending={isPending}
                disabled={isDisabled}
                iconArrow
              />
              <OnboardingSkipButton
                testid="document_skip"
                type="button"
                onClick={onNext}
                className={styles.skip}
                titleClassName={styles.skipTitle}
                title="Skip Question"
              />
            </ButtonsContainer>
          ) : (
            <Button variant="secondary" className={styles.modalSaveButton} onClick={onSave}>
              Save
            </Button>
          )}
        </AnswersContainer>
      </OnBoardingContainer>
      <RequestModal />
    </OnBoardingWrapper>
  );
};

StageDocument.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  Controls: PropTypes.elementType,
  isHidden: PropTypes.bool,
  className: PropTypes.string,
};

StageDocument.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  Controls: () => null,
  isHidden: false,
  className: null,
};

export default StageDocument;
