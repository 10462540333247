import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Field = (props) => {
  const { className, children, label } = props;

  return (
    <div className={classNames(styles.field, className)}>
      <div className={styles.label}>{label}</div>
      <div className={styles.input}>{children}</div>
    </div>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
};

Field.defaultProps = {
  className: '',
  label: '',
};

export default Field;
