import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';

import { Button as AntdButton } from 'components-antd';
import { Button } from 'components';
import { updateStandardSubscriptionEffect } from 'store/effects/subscription';
import { userGetDataOnBackgroundEffect } from 'store/effects';

import styles from '../DowngradeModal/styles.module.scss';
import { ModalClose } from 'components/Icons';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export const UpgradeModal: React.FC<Props> = ({ closeModal, isOpen }) => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);

  const handleUpgradePlan = () => {
    setIsPending(true);
    dispatch(
      updateStandardSubscriptionEffect({ requestedSeatsCount: 3 }, () => {
        dispatch(
          userGetDataOnBackgroundEffect({}, { showError: false }, () => {
            closeModal();
            setIsPending(false);
          }),
        );
      }),
    );
  };
  return (
    <Modal
      open={isOpen}
      centered
      maskStyle={{
        background: 'rgba(255, 255, 255, 0.60)',
        backdropFilter: ' blur(12.5px)',
      }}
      bodyStyle={{
        borderRadius: '24px',
        background: 'var(--REBRAND-Secondary-White, #FFF)',
        boxShadow: '0px 24px 80px 0px rgba(0, 0, 0, 0.20)',
      }}
      wrapClassName={styles.wrapper}
      closable
      footer={null}
      className={styles.modalWrapper}
      destroyOnClose
      onCancel={closeModal}
      closeIcon={<ModalClose />}
    >
      <div className={styles.content}>
        <div className={styles.header}>Are you sure you want to upgrade</div>
        <div className={styles.btnContainer}>
          <AntdButton variant="secondary" className={styles.cancelBtn} onClick={closeModal}>
            Never Mind
          </AntdButton>
          <Button
            title="Yes"
            className={styles.confirmBtn}
            onClick={handleUpgradePlan}
            isPending={isPending}
          />
        </div>
      </div>
    </Modal>
  );
};
