import React, { ReactElement } from 'react';
import { List } from 'antd';
import { ListProps as ListProps, ListItemProps } from 'antd/lib/list';

interface IAntList<T> extends ListProps<T> {}

interface IAntListItem extends ListItemProps {}

export const AntList = <T,>(props: IAntList<T>): ReactElement => {
  const { children } = props;
  return <List {...props}>{children}</List>;
};

export const AntListItem: React.FC<IAntListItem> = (props) => {
  const { children } = props;
  return <List.Item {...props}>{children}</List.Item>;
};
