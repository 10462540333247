import { Typography } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { UPLOAD_FILE } from 'app-constants';
import classNames from 'classnames';
import { UploadDragger } from 'components-antd';
import { routes } from 'settings/navigation/routes';
import { Fragment, useState } from 'react';
import { cloneDeep } from 'lodash-es';
import { useHistory } from 'react-router-dom';

import { handleFileUpload, initialFieldState, initialUploadErrorState } from '../../helper';

import { FormHeader, Icons, TemplateCheckboxFields, TemplateFields } from '../components';
import Navigation from '../components/Footer';

import styles from './style.module.scss';
import { useAddFormsInBundle } from '../hooks/useAddFormsInBundle';
import { Spinner } from 'components';

let filesChangeCounter = 0;
export const UploadFileInForm = ({
  stagesStep,
  onUpdate,
  setCurrentStageIndex,
  isDynamicForm,
  onClose,
}) => {
  const [files, setFiles] = useState<UploadFile<any>[]>([]);
  const [templateFieldState, setTemplateState] = useState(initialFieldState);

  const [templateErrors, setTemplateErrors] = useState(initialUploadErrorState);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const hasFiles = files.length > 0;

  const { addFormsInDynamicBundle, adding: addingDynamicForm } = useAddFormsInBundle({ onClose });

  const onAddNewFile = ({ fileList }: UploadChangeParam<any>) => {
    if (fileList?.length) {
      isDynamicForm && filesChangeCounter++;
      if (isDynamicForm && filesChangeCounter === fileList.length) {
        filesChangeCounter = 0;
        uploadFilesInDynamicBundle(fileList);
      } else {
        setFiles([...fileList]);
      }
    }
  };

  const deleteFile = (index) => {
    let newFiles = cloneDeep(files);
    newFiles = newFiles.filter((_, i) => i !== index);
    setFiles(newFiles);

    if (!newFiles.length) {
      setTemplateState(initialFieldState);
      setTemplateErrors(initialUploadErrorState);
    }
  };

  // Fields onChange Handler
  const onChangeInFields = (data) => {
    setTemplateState((prev) => ({ ...prev, ...data }));
  };

  // Validating Form
  const validateTemplateForm = () => {
    let isValid = true;
    let errors = { ...templateErrors };

    if (templateFieldState.saveAsTemplate) {
      if (!templateFieldState.templateName.trim().length) {
        errors.templateNameError = 'Required!';
        isValid = false;
      }
      if (!templateFieldState.categories.length) {
        errors.categoriesError = 'Required!';
        isValid = false;
      }
    } else if (!templateFieldState.templateName.trim().length) {
      errors.templateNameError = 'Required!';
      isValid = false;
    }

    !isValid && setTemplateErrors(errors);
    return isValid;
  };

  const uploadFilesInDynamicBundle = async (files) => {
    addFormsInDynamicBundle({
      files,
    });
  };

  const onSubmit = async () => {
    let isValid = validateTemplateForm();
    if (!isValid) {
      return;
    }

    const { saveAsTemplate } = templateFieldState;
    const requestData = {
      saveAsTemplate,
      files,

      ...(saveAsTemplate && {
        fileName: templateFieldState.templateName,
        categories: templateFieldState.categories,
        publishToteam: templateFieldState.publishToTeam,
      }),

      ...(!saveAsTemplate && { fileName: templateFieldState.templateName }),
    };

    setLoading(true);

    const result = await handleFileUpload(requestData);

    if (result?.documentLink || result?.templateId) {
      history.push({
        pathname: routes.workshopFormProcessRequest,
        state: {
          documentLink: result.documentLink,
          templateId: result.templateId,
          customForm: true,
          name: requestData.fileName,
          ...(saveAsTemplate && {
            saveAsTemplate,
            categories: templateFieldState.categories,
            publishToTeam: templateFieldState.publishToTeam,
          }),
        },
      });

      setLoading(false);
    }
  };

  const handlePrevious = (e) => {
    onUpdate(stagesStep.FormCategories);
    setCurrentStageIndex(stagesStep.FormCategories);
  };

  return (
    <div className={styles.uploadFileInFormWrapper}>
      <FormHeader heading={'Upload a File'} handlePrevious={handlePrevious} />

      {addingDynamicForm ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className={hasFiles ? styles.uploadFileContent : ''}>
            <div
              className={classNames(styles.uploadContainer, {
                [styles.uploadContainerNoFiles]: !files?.length,
                [styles.uploadedFilesContainer]: !!files?.length,
              })}
            >
              <UploadDragger
                action={UPLOAD_FILE}
                onChange={onAddNewFile}
                multiple={true}
                showUploadList={false}
                beforeUpload={() => false}
                accept="application/pdf"
                className={styles.uploadDragger}
                fileList={files}
              >
                <Icons variant={'uploadFile'} className={styles.uploadFileIcon} />

                <p className={styles.uploadHint}>Drag and drop files here</p>
                <p className={styles.uploadSecondHint}>or</p>
                <button className={styles.uploadButton}>
                  <Typography className={styles.uploadText}>Choose File</Typography>
                </button>
              </UploadDragger>
            </div>

            {files?.map((file, index) => (
              <div className={styles.fileBox} key={`file-${file.name}-${index}`}>
                <div className={styles.fileNameBox}>
                  <Icons variant={'file'} className={styles.fileIcon} />
                  <span className={styles.fileName}>{file.name}</span>
                </div>
                <Icons
                  variant={'trash'}
                  onClick={() => deleteFile(index)}
                  className={styles.deleteIcon}
                />
              </div>
            ))}

            {hasFiles ? (
              <TemplateFields
                fieldState={templateFieldState}
                onChange={onChangeInFields}
                errors={templateErrors}
                setErrors={setTemplateErrors}
                saveAsTemplate={templateFieldState.saveAsTemplate}
                className={styles.templateFieldsList}
              />
            ) : (
              <></>
            )}
          </div>

          {hasFiles && !isDynamicForm ? (
            <TemplateCheckboxFields
              values={templateFieldState}
              errors={templateErrors}
              onChange={onChangeInFields}
              setErrors={setTemplateErrors}
            />
          ) : (
            <></>
          )}

          {!isDynamicForm ? (
            <Navigation loading={loading} disabled={!files.length} onNext={onSubmit} />
          ) : (
            <></>
          )}
        </Fragment>
      )}
    </div>
  );
};
