import { useSelector } from 'react-redux';

import { SignWizard } from 'pages/FormBuilder/components/PDFViewer/SignWizard';
import {
  getDynamicFormSelector,
  getDynamicFormSignatureConfigSelector,
} from 'store/selectors/requestFormProcess';
import { FORM_PROCESS_SCREEN, getDynamicFormColor } from 'app-constants';
import { getSignatureConfig } from '../FieldRenderer/Fields/helper';

export const SignatureContainer = (props) => {
  const { showWizard, activeQuestions, responses, allFormRoles, wizardStarted } = useSelector(
    getDynamicFormSignatureConfigSelector,
  );

  const { bundleDocumentsDetails = [], screen } = useSelector(getDynamicFormSelector);

  const { questions, answers, wizard } = getSignatureConfig({
    bundleDocumentsDetails,
    activeQuestions,
    responses,
    showWizard,
  });

  const color = getDynamicFormColor(allFormRoles, questions?.[0], true);

  const isDeclined = screen === FORM_PROCESS_SCREEN.DeclineSign;

  return (
    <>
      {wizard && !isDeclined && (
        <SignWizard
          allQuestions={questions}
          responses={answers}
          color={color}
          dynamicForm
          wizardStarted={wizardStarted}
        />
      )}
    </>
  );
};
