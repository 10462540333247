import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Input,
  Select,
  Modal,
  ModalProps,
  Title,
  Form,
  SelectProps,
  Checkbox,
} from 'components-antd';
import { Options } from 'types';

import styles from './styles.module.scss';

interface InputSelectModalProps extends ModalProps {
  title?: string;
  okText?: string;
  entityName?: string;
  entityNameHidden?: boolean;
  selectHeading?: string;
  selectPlaceholder?: string;
  selectedItem?: number;
  selectOptions?: Options;
  entityNameRequired?: boolean;
  selectedItemRequired?: boolean;
  selectProps?: SelectProps;
  entityNameMaxLength?: number;
  saveAsTemplateCheckbox?: boolean;
  titleClassName?: string;
  handleUpload?: (templateName: string, saveAsTemplate: boolean) => void;
  submit: (setLoading: Dispatch<SetStateAction<boolean>>, values: any) => any;
}

export const InputSelectModal = ({
  title,
  okText,
  entityName,
  entityNameHidden = false,
  selectHeading,
  selectPlaceholder,
  selectedItem,
  selectOptions,
  entityNameRequired = false,
  entityNameMaxLength,
  selectedItemRequired = false,
  selectProps = {},
  open,
  onOk,
  submit,
  saveAsTemplateCheckbox,
  titleClassName,
  handleUpload,
  ...props
}: InputSelectModalProps) => {
  const [loading, setLoading] = useState(false);
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [form] = Form.useForm();

  const submitForm = () => {
    form.submit();
  };
  const onFinish = async (values) => {
    if (saveAsTemplateCheckbox && submit) {
      setLoading(true);
      values.saveAsTemplate = saveAsTemplate;
      submit(setLoading, values);
    } else if (submit) {
      setLoading(true);
      submit(setLoading, values);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      entityName: entityName || '',
    });
  }, [entityName, entityNameHidden]);

  useEffect(() => {
    form.setFieldsValue({
      selectedItem: selectedItem,
    });
  }, [selectOptions, selectedItem]);

  useEffect(() => {
    if (!open)
      form.setFieldsValue({
        selectedItem: undefined,
        entityName: undefined,
      });
  }, [open]);

  return (
    <Modal
      data-testid="input-select-modal"
      className={styles.inputSelectModal}
      title={
        <div className={titleClassName}>
          <Title level={4}>{title}</Title>
        </div>
      }
      okText={okText}
      onOk={submitForm}
      confirmLoading={loading}
      open={open}
      {...props}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        {!entityNameHidden && (
          <Form.Item
            label={'Name'}
            name="entityName"
            rules={[{ required: entityNameRequired, message: 'Required' }]}
          >
            <Input maxLength={entityNameMaxLength || 200} data-testid="modal-input" size="large" />
          </Form.Item>
        )}
        {saveAsTemplateCheckbox && (
          <Checkbox
            value={saveAsTemplate}
            onChange={(e) => {
              setSaveAsTemplate(e.target.checked);
            }}
          >
            Save As Template
          </Checkbox>
        )}
        {selectOptions && (
          <div className={styles.selectContainer}>
            <Form.Item
              label={selectHeading}
              name="selectedItem"
              rules={[{ required: selectedItemRequired, message: 'Required' }]}
            >
              <Select
                className={styles.select}
                size="large"
                placeholder={selectPlaceholder}
                options={selectOptions}
                {...selectProps}
              />
            </Form.Item>
          </div>
        )}
      </Form>
    </Modal>
  );
};
