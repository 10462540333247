import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { PENDING } from 'settings/constants/apiState';
import { THIRD_PARTY } from 'settings/constants/roles';
import { onBoardingSignUpEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getUserDataSelector, isPartnerSelector } from 'store/selectors/user';

import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from '../../agent/components/OnboardingContinueButton';
import { ValidationSchema } from './validation';

import classNames from 'classnames';
import { Input } from 'components';
import { Col, Row, TextArea } from 'components-antd';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';

const initialValues = {
  description: '',
};

type Props = {
  onNext: () => {};
  stageIndex?: number;
  Controls: React.ElementType;
};

export const DescriptionForm: React.FC<Props> = ({ stageIndex, onNext, Controls }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const user = useSelector(getUserDataSelector);
  const isPartner = useSelector(isPartnerSelector);

  const getSchema = () => {
    return ValidationSchema;
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      description:
        (user?.[THIRD_PARTY]?.Partner?.Description as string) || initialValues.description,
    },
    validationSchema: getSchema(),
    onSubmit(values) {
      dispatch(
        onBoardingSignUpEffect(
          { ...values, stageIndex },
          { isPartner, isCommonInfo: true },
          (err) => {
            if (!err) {
              onNext();
            }
          },
        ),
      );
    },
  });

  const isPending = onBoarding.state === PENDING;

  const getTitle = () => {
    return 'Do you want to add a description/biography?';
  };

  return (
    <OnBoardingWrapper isPending={isPending} className={styles.wrapper}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question className={styles.partnerTitle}>{getTitle()}</Question>
        <p className={styles.partnerSubheader}>
          This is information that may help prospective clients get to know you better.
        </p>
        <AnswersContainer>
          <form onSubmit={formik.handleSubmit}>
            <Row className={classNames(styles.row, styles.partnerRow)} gutter={[16, 20]}>
              <Col xs={24} md={24}>
                <div className={styles.textareaWrapper}>
                  <TextArea
                    name="Description"
                    // error={formik.touched.description ? formik.errors.description : ''}
                    value={formik.values.description}
                    onChange={(e) => formik.setFieldValue('description', e.target.value)}
                    disabled={isPending}
                    testid="description_input"
                    rows={6}
                    placeholder="Enter a description..."
                  />
                </div>
              </Col>
            </Row>
            <ButtonsContainer>
              <OnboardingContinueButton
                testid="done"
                title="Continue"
                className={styles.submit}
                isPending={isPending}
                disabled={!formik.values.description || !formik.isValid}
                onClick={formik.submitForm}
              />
            </ButtonsContainer>
          </form>
        </AnswersContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};
