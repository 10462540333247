import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modal, ModalProps } from 'components-antd';
import { GoBack, ModalClose } from 'components/Icons';
import { AssignTo } from 'pages/Templates/Tasks/components/TaskTemplates/AssignTo';
import TaskTemplates from 'pages/Templates/Tasks/components/TaskTemplates';
import { createTransactionManyTaskEffect } from 'store/effects/transactions';
import { TaskTemplate } from 'types';

import styles from './styles.module.scss';

interface AssignToModalProps extends ModalProps {}

export const TaskTemplateModal = ({ onCancel, ...props }: AssignToModalProps) => {
  const dispatch = useDispatch();

  const [assignTo, setAssignTo] = useState(false);
  const [selected, setSelected] = useState();
  const [formValues, setFormValues] = useState<TaskTemplate[]>();
  const [disabled, setDisabled] = useState(false);

  const closeModal = (e?) => {
    setSelected(undefined);
    setAssignTo(false);
    setDisabled(false);
    if (onCancel && typeof onCancel === 'function') onCancel(e);
  };

  const handleTaskSubmit = (e) => {
    if (e?.assignTo && e?.formValues) {
      setFormValues(e?.formValues);
      setAssignTo(true);
    } else {
      closeModal();
    }
  };

  const goBack = (e) => {
    if (!disabled) {
      setAssignTo(false);
      setFormValues(undefined);
    }
  };

  const handleAssignToSubmit = (selected) => {
    const taskValues = [...(formValues ?? [])];
    if (taskValues?.length && selected?.length) {
      setDisabled(true);
      const tasks = taskValues.map((task: TaskTemplate) => ({
        ...task,
        AssigneeList: [...selected],
      }));
      dispatch(
        createTransactionManyTaskEffect(tasks, {}, (err) => {
          if (!err) {
            closeModal();
          }
          setDisabled(false);
        }),
      );
    } else {
      closeModal();
    }
  };

  const modalTitle = (
    <div className={styles.assignToHeader}>
      {assignTo ? <GoBack className={styles.headerIcon} onClick={goBack} /> : <span />}
      <span className={styles.headerTitle}>{assignTo ? 'Assign To' : 'Task Templates'}</span>
      <ModalClose className={styles.headerIcon} onClick={onCancel as any} />
    </div>
  );

  return (
    <Modal
      title={modalTitle}
      width={675}
      maskClosable={false}
      className={styles.taskTemplateModal}
      onCancel={closeModal}
      destroyOnClose
      footer={<></>}
      closeIcon={<></>}
      {...props}
    >
      {assignTo ? (
        <AssignTo onSubmit={handleAssignToSubmit} pending={disabled} />
      ) : (
        <TaskTemplates
          onSubmit={handleTaskSubmit}
          allowMultiSelect
          selectedTemplates={selected}
          setSelectedTemplates={setSelected}
          className={styles.taskTemplate}
        />
      )}
    </Modal>
  );
};
