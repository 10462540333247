import { ReactElement } from 'react';
import classnames from 'classnames';

import { Title, Modal, ModalProps, Input } from 'components-antd';

import styles from './styles.module.scss';
import { Icon } from 'components/Transactions';

export interface ConfirmationWithReasonModalProps extends ModalProps {
  confirmText?: ReactElement | string;
  okText?: string;
  placeholder?: string;
  showReason?: boolean;
  setReason?: any;
  note?: any;
  icon?: string;
  description?: string;
}

export const ConfirmationWithReasonModal = ({
  confirmText,
  okText = 'Confirm',
  placeholder,
  showReason = true,
  setReason,
  note,
  icon,
  description,
  ...props
}: ConfirmationWithReasonModalProps) => (
  <Modal
    okText={okText}
    data-testid="confirmation-modal"
    className={classnames(
      ['Accept', 'Share', 'Change'].includes(okText)
        ? styles.confirmationWithReasonModalForAccept
        : styles.confirmationWithReasonModal,
    )}
    {...props}
  >
    {icon && <Icon variant={icon} className={styles.icon} />}
    <Title level={4}>{confirmText}</Title>
    {description && <span className={styles.description}>{description}</span>}
    {showReason && (
      <>
        <label className={styles.label}>
          {note === 'Note' ? 'Note ' : 'Message '}
          <span>(Optional)</span>
        </label>
        <Input
          placeholder={placeholder}
          className={styles.reasonInput}
          onChange={(e) => setReason(e.target.value)}
          variant={Input.SQUARE}
        />
      </>
    )}
  </Modal>
);
