import { SVGProps } from 'react';

export const Rule = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 14.1667V12C5 10.8954 5.89543 10 7 10L13 10C14.1046 10 15 10.8954 15 12V14.1667"
      stroke="#ff576d"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="9.99935" cy="3.75008" r="2.08333" stroke="#ff576d" strokeWidth="2" />
    <circle cx="4.99935" cy="16.2501" r="2.08333" stroke="#ff576d" strokeWidth="2" />
    <circle cx="14.9993" cy="16.2501" r="2.08333" stroke="#ff576d" strokeWidth="2" />
    <path d="M10 9.99992V5.83325" stroke="#ff576d" strokeWidth="2" />
  </svg>
);
