import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { PENDING } from 'settings/constants/apiState';
import { getQuoteFormMetaSelect, getSmartFormMetaSelect } from 'store/selectors/formBuilder';

import styles from './styles.module.scss';

interface FormFooterProps {
  smartForm?: boolean;
}

export const FormFooter = ({ smartForm = false }: FormFooterProps) => {
  const { state, lastSaved, lastUpdated } = smartForm
    ? useSelector(getSmartFormMetaSelect)
    : useSelector(getQuoteFormMetaSelect);

  const [autoSave, setAutoSave] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setAutoSave(new Date()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {useMemo(
        () =>
          state !== PENDING &&
          lastSaved && (
            <div className={classNames('prevent-user-select', styles.footerContainer)}>
              <span data-testid="quote-last-saved" className={styles.autoSaveText}>
                Saved {moment(lastSaved).fromNow()}
              </span>
            </div>
          ),
        [state, lastSaved, lastUpdated, autoSave],
      )}
    </>
  );
};
