import { Input } from 'components';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const DaysFromTodayComponent = (props) => {
  const { className, value, placeholder, onChange, selectedOption } = props;

  const onValueChange = useCallback(
    (e, val) => {
      onChange(val);
    },
    [onChange],
  );

  return (
    <div>
      <div className={classNames(styles.content, className)}>
        <div className={styles.innerWrapper}>
          <div className={styles.label}>Days from {selectedOption.value?.split(' ')?.[0]}:</div>
          <Input
            type="number"
            className={styles.inputWrapper}
            inputHolderClassName={styles.inputHolder}
            value={value}
            onChange={onValueChange}
            placeholder={placeholder}
            variant={Input.LIGHT_ROUNDED}
          />
        </div>
      </div>
      <div className={styles.orWrapper}>
        <div className={styles.or}>OR</div>
      </div>
    </div>
  );
};

DaysFromTodayComponent.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.object,
};

DaysFromTodayComponent.defaultProps = {
  className: '',
  placeholder: '',
  value: '',
  selectedOption: {
    label: 'T',
    value: 'Today',
    date: new Date(),
  },
};

export default DaysFromTodayComponent;
