import { FormEditorStatus, ResentEmailEnum } from 'app-constants';
import { Col, Row } from 'components-antd';
import { Signatory } from 'components/Icons/Signatory';
import moment from 'moment';
import { Fragment } from 'react';
import { CustomDropDown } from '../CustomDropDown';
import { useSelector } from 'react-redux';
import { getFormDetailsSelector } from 'store/selectors/requestFormProcess';
import styles from '../../styles.module.scss';
import { ICON_VARIANT_TYPE, Icons } from 'pages/Workshop/Forms/Icons';

export const FormDetailEditor = (props) => {
  const {
    item,
    options,
    role,
    viewOnly,
    handleResendForm,
    setReplaceEntityModal,
    handleRemoveEditor,
    handleMessage,
  } = props;

  const { data: formDetails } = useSelector(getFormDetailsSelector);

  return (
    <Fragment>
      <Row className={styles.fillersRow}>
        <div className={styles.fillerInfo}>
          <Col className={styles.signatureCountRow}>
            {item.status == FormEditorStatus.Completed ? (
              <Icons className={styles.avatarWrapper} variant={ICON_VARIANT_TYPE.SIGNED} />
            ) : (
              <Signatory />
            )}
          </Col>
          <Col>
            <p className={styles.fillersName}>{item.isYou ? 'You' : item.name}</p>
          </Col>
        </div>

        <div className={styles.fillerActions}>
          <Col className={styles.fillersStatus}>
            <p
              className={
                item?.status === FormEditorStatus.Pending ||
                item?.status === FormEditorStatus.Requested
                  ? styles.colorClass
                  : ''
              }
            >
              {item.status == FormEditorStatus.Requested ? 'Pending' : item.status}
            </p>
            <p>{item.date ? moment(item.date).fromNow() : ''}</p>
          </Col>

          <Col>
            {!viewOnly && (
              <CustomDropDown
                messageData={{
                  id: item.userId,
                  name: item.name,
                  avatar: item.avatar,
                }}
                allowResend={item.allowResend}
                allowReplace={item.allowReplace}
                allowRemove={item.allowRemove}
                allowMessage={Boolean(item.userId) && !item.isYou}
                onResend={() =>
                  handleResendForm({
                    url: formDetails?.formDocument?.url,
                    userId: item.userId,
                    email: item.email,
                    type: ResentEmailEnum.editor,
                  })
                }
                onReplace={() =>
                  setReplaceEntityModal({
                    open: true,
                    isEditor: true,
                    entityId: item.editorId,
                    name: item.name,
                    email: item.email,
                    role,
                    options,
                  })
                }
                onRemove={() =>
                  handleRemoveEditor({
                    url: formDetails?.formDocument?.url,
                    editorId: item.editorId,
                  })
                }
                onMessage={handleMessage}
              />
            )}
          </Col>
        </div>
      </Row>

      {formDetails?.isSequential ? (
        <div className={styles.trackingDivider}></div>
      ) : (
        <div className={styles.emptyDivider}></div>
      )}
    </Fragment>
  );
};
