import { useState } from 'react';

export const useTaskSelection = (tasks) => {
  const [selectedTaskIds, setSelectedTaskIds] = useState<number[]>([]);

  const handleSelection = (taskId, checked) => {
    if (taskId === 'all') {
      if (checked) {
        const taskIds = tasks.map(({ Id }) => Id);
        setSelectedTaskIds(taskIds);
      } else {
        setSelectedTaskIds([]);
      }
    } else if (checked) {
      setSelectedTaskIds((prev) => [...prev, taskId]);
    } else {
      const _tasks = selectedTaskIds.filter((id) => id !== taskId);
      setSelectedTaskIds(_tasks);
    }
  };

  return { handleSelection, selectedTaskIds, setSelectedTaskIds };
};
