import classNames from 'classnames';

import { FormMode, ResponseType } from 'types';
import { FORM_MODE } from 'app-constants';
import { NumberInput } from 'components';

import styles from './styles.module.scss';

interface NumberQuestionProps {
  mode: FormMode;
  formResponse?: ResponseType;
  handleFormResponse?: ({ Answer }: Partial<ResponseType>) => void;
  prefix?: string;
}

export const NumberQuestion = ({
  mode,
  formResponse,
  handleFormResponse,
  prefix,
}: NumberQuestionProps) => {
  const editMode = mode === FORM_MODE.Edit;

  const handleUserInput = (value: string) => {
    if (formResponse?.UUID && handleFormResponse) {
      handleFormResponse({ Answer: value });
    }
  };

  return (
    <NumberInput
      prefix={prefix}
      placeholder="Enter number"
      disabled={editMode}
      value={formResponse?.Answer}
      onUpdate={handleUserInput}
      containerClass={styles.responseInputWrap}
      prefixClass={styles.responseInputPrefix}
      className={classNames(styles.responseInput, { [styles.hasPrefix]: prefix })}
    />
  );
};
