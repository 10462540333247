import { Button, Modal } from 'components-antd';
import { LOCAL_STORAGE_KEY } from 'features/emailVerification/constants';
import { interruptedActionHandlers } from 'features/emailVerification/interruptedActions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import styles from './styles.module.scss';

interface EmailVerificationSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EmailVerificationSuccessModal: React.FC<EmailVerificationSuccessModalProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleContinueButton = () => {
    props.onClose();

    const afterVerificationStateJson = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (afterVerificationStateJson) {
      const afterVerificationState = JSON.parse(afterVerificationStateJson);

      let actionHandler = interruptedActionHandlers[afterVerificationState.action];
      if (actionHandler) {
        actionHandler(history, dispatch, afterVerificationState.callbackPayload);
      } else {
        history.push(routes.index);
      }
      window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  };

  return (
    <>
      {props.isOpen && (
        <Modal
          open={props.isOpen}
          width={675}
          footer={null}
          maskClosable={false}
          destroyOnClose={true}
          title=" "
          onCancel={() => props.onClose()}
        >
          <div className={styles.successModalWrapper}>
            <h2 className={styles.stepHeader}>Success!</h2>
            <div className={styles.text}>Your email has been verified.</div>
            <Button
              onClick={handleContinueButton}
              variant={'secondary'}
              className={styles.continueButton}
            >
              Continue
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
