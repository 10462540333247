import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ drawers }) => get(drawers, 'transactionParticipant');

export const getTransactionParticipantDrawerSelector = createSelector(
  localState,
  (transactionParticipant) => transactionParticipant,
);

export const getTransactionParticipant = createSelector(localState, ({ participantInfo }) => ({
  isIdle: participantInfo.state === IDLE,
  isPending: participantInfo.state === PENDING,
  isData: !!participantInfo.data,
  participant: participantInfo.data,
}));
