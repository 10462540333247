import { apiServer } from 'settings/web-services/api';

export function getAvailableSubscriptionPlans(planName?: string) {
  return apiServer.get<WrappedApiResponse<GetAvailableSubscriptionPlansResponse[]>>(
    `/subscription/availablePlans?planName=${planName}`,
  );
}

export function getImplementationPlan() {
  return apiServer.get<WrappedApiResponse<GetAvailableSubscriptionPlansResponse[]>>(
    `/subscription/standard-implementation`,
  );
}
export function getCoupon({ coupon }: { coupon: string }) {
  return apiServer.get<WrappedApiResponse<GetAvailableSubscriptionPlansResponse[]>>(
    `/subscription/coupon?coupon=${coupon}`,
  );
}

export function subscribeStandardPlan(payload: {
  paymentMethodId: string;
  planId: string;
  requestedSeatsCount: number;
  activationCoupon?: string;
  skipImplementation?: boolean;
}) {
  return apiServer.post<WrappedApiResponse<GetAvailableSubscriptionPlansResponse[]>>(
    `/subscription/standard`,
    payload,
  );
}

export function subscribeLegacyPlan(payload: {
  paymentMethodId: string;
  planId: string;
  requestedSeatsCount: number;
  priceKey: string;
  activationCoupon?: string;
}) {
  return apiServer.post<WrappedApiResponse<GetAvailableSubscriptionPlansResponse[]>>(
    `/subscription/legacy`,
    payload,
  );
}

export function getInvoiceDataOverview() {
  return apiServer.get(`/subscription/invoiceDataOverview`);
}

export function initSubscriptionCheckout(data) {
  return apiServer.post(`/subscription/checkout`, data);
}

export function fetchActiveSubscriptionData() {
  return apiServer.get(`/subscription/active`);
}

export function fetchActiveSubscriptionTeamData() {
  return apiServer.get<WrappedApiResponse<PlanTeamDetails>>(`/subscription/active/teamDetails`);
}

export function cancelCurrentSubscription() {
  return apiServer.delete(`/subscription/active`);
}

export function renewCurrentSubscription() {
  return apiServer.post(`/subscription/active`);
}

export function updateCurrentSubscription(data) {
  return apiServer.put(`/subscription/active`, data);
}

export function getLinkToPaymentMethodManagement() {
  return apiServer.get(`/subscription/paymentMethods`);
}

export function getLinkToSubscriptionManagement() {
  return apiServer.get(`/subscription/subscriptionManagement`);
}

export function getLinkToPaymentHistory() {
  return apiServer.get(`/subscription/paymentHistory`);
}

export function convertToTeamPlan() {
  return apiServer.post(`/agentTeam/teams`);
}

export function downgradeFromTeamPlan() {
  return apiServer.post(`/agentTeam/downgrade`);
}

export function getCurrentPlanLevel() {
  return apiServer.get<WrappedApiResponse<GetCurrentPlanLevelResponse>>(
    `/subscription/active/plan`,
  );
}

export function updatePlanIntervalLegacy(cfg) {
  return apiServer.put('/subscription/legacy', cfg);
}

export function adminPanelGetSubscriptionCustomer(userId: number) {
  return apiServer.get<WrappedApiResponse<SubscriptionCustomerDataResponse>>(
    `/subscription/adminPanel/subscriptionCustomer/${userId}`,
  );
}

export function adminPanelCreateNewSubscriptionCustomer(
  userId: number,
  existingExternalCustomerId: string,
) {
  return apiServer.post<WrappedApiResponse<SubscriptionCustomerDataResponse>>(
    `/subscription/adminPanel/subscriptionCustomer/${userId}`,
    existingExternalCustomerId ? { externalCustomerId: existingExternalCustomerId } : {},
  );
}

export function adminPanelTogglePlanLevel(userId: number, newPlan: AdminFriendlySubscriptionPlan) {
  return apiServer.put<WrappedApiResponse<SubscriptionCustomerDataResponse>>(
    `/subscription/adminPanel/subscriptionCustomer/${userId}/togglePlanLevel`,
    {
      newPlan,
    },
  );
}

export function cancelSubscription(payload: { CancellationReason: string }) {
  return apiServer.post(`/subscription/active/cancel`, payload);
}

export function reactivateSubscription() {
  return apiServer.post(`/subscription/active`);
}

export function updateStandardSubscription(payload: { requestedSeatsCount: number }) {
  return apiServer.put(`/subscription/standard`, payload);
}

export function updateNewAgent(payload: { Id: number; WizardFinished: boolean }) {
  const { Id, ...rest } = payload;
  return apiServer.put(`/selfSignup/agent/${Id}`, rest);
}

export function updatePayment(payload: { paymentMethodId: string; payInvoices: string }) {
  return apiServer.post<WrappedApiResponse<boolean>>(`/subscription/active/payment`, payload);
}

export interface WrappedApiResponse<T> {
  success: boolean;
  result: T;
}

export interface SubscriptionCustomerDataResponse {
  UserId: number;
  ExternalCustomerId: string;
  PlanLevel: SubscriptionPlanLevel;
  SubscriptionStatus?: string;
  IsAllowedToSwitchSubscriptionPlan: boolean;
}

export interface SubscriptionCurrentPlanLevelResponse {
  CurrentPlan: SubscriptionPlanLevel;
}

export enum SubscriptionPlanLevel {
  Basic = 'basic',
  Premium = 'premium',
}

export interface GetAvailableSubscriptionPlansResponse {
  ExternalId: string;
  Level: SubscriptionPlanLevel;
  Name: string;
  Prices: SubscriptionPlanPrice[];

  /**
   * @deprecated Use Prices instead (to support graduated & volume pricing)
   */
  Price: {
    Amount: number;
    Currency: string;
    Period: {
      Interval: string;
      IntervalCount: number;
    };
  };
  Plan?: string;
}

export interface SubscriptionPlanPrice {
  PriceKey: string;
  BasePrice: number;
  BasePriceUnitsIncluded: number | null;
  NextPerUnitPrice: number | null;
  Currency: string;
  Period: {
    Interval: string;
    IntervalCount: number;
  };
  IsDefault: boolean;
  Nickname: string;
  allTiers?: {
    flat_amount?: number;
    unit_amount?: number;
  }[];
}

export interface PlanTeamDetails {
  TotalSeatsCount: number;
  IncludedSeatsCount: number;
  AdditionallyPaidSeatsCount: number;
  UnusedSeatsCount: number;
  RequestedSeatsCount: number;
  BilledSeatsCount: number;
  Interval: 'month' | 'year' | undefined;
}

export enum AdminFriendlySubscriptionPlan {
  InImplementation = 'inImplementation',
  Complimentary = 'complimentary',
  Paid = 'paid',
  Standard = 'standard',
}

export interface GetCurrentPlanLevelResponse {
  CurrentPlan: SubscriptionPlanLevel;
}
