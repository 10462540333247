import classnames from 'classnames';
import { useEffect, useState, forwardRef, Ref } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextArea, Tooltip } from 'components-antd';
import {
  DateQuestion,
  EmailQuestion,
  FreeTextQuestion,
  ListBoxQuestion,
  NumberQuestion,
  PhoneNumberQuestion,
  YesNoQuestion,
  UploadQuestion,
  SignatureQuestion,
} from 'pages/FormBuilder/components/QuoteFormQuestionTypes';
import { FORM_MODE, FORM_QUESTION_TYPE } from 'app-constants';
import { FormCommentPopover } from 'pages/FormProcess/components';
import { getQuoteFormMetaSelect } from 'store/selectors/formBuilder';
import { HelpMessage } from '../components/PDFViewer/RenderPageFields';
import { updateQuoteFormQuestionEffect } from 'store/effects/formBuilder';
import {
  FormMode,
  DateType,
  QuestionType,
  ResponseType,
  AddFormCommentType,
  CommentUsersType,
  EditFormCommentType,
  FormCommentType,
  FormCommentThread,
  FieldInfoType,
} from 'types';

import styles from './styles.module.scss';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

interface QuestionContainerProps {
  isBuilder?: boolean;
  mode: FormMode;
  formQuestion?: QuestionType;
  formResponse?: ResponseType;
  smartForm?: boolean;
  responseMode?: boolean;
  handleFormResponse?: (response: Partial<ResponseType>) => void;
  commentUsers?: CommentUsersType;
  formComment?: FormCommentType;
  setFieldInfo?: (fieldInfo: FieldInfoType) => void;
  handleGetFormComments?: () => void;
  handleAddFormComment?: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment?: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment?: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
}

export const QuestionContainer = forwardRef(
  (
    {
      isBuilder,
      mode,
      formQuestion,
      formResponse,
      smartForm = false,
      responseMode = false,
      handleFormResponse,
      commentUsers,
      formComment,
      setFieldInfo,
      handleGetFormComments,
      handleAddFormComment,
      handleEditFormComment,
      handleDeleteFormComment,
      handleToggleResolveThread,
    }: QuestionContainerProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const dispatch = useDispatch();

    const [questionInput, setQuestionInput] = useState('');
    const [threads, setThreads] = useState<FormCommentThread[]>();
    const { disabled } = useSelector(getQuoteFormMetaSelect);

    const maxLength = formQuestion?.Fields?.[0]?.maxLength;

    useEffect(() => {
      if (Object.keys(formComment?.fieldThreads || {}).length) {
        const threadList: FormCommentThread[] = [];
        formQuestion?.Fields?.forEach((field) => {
          if (
            field.id in (formComment?.fieldThreads.data || {}) &&
            Object.keys(formComment?.fieldThreads.data || {}).length
          ) {
            threadList.push(...(formComment?.fieldThreads.data[field.id] || []));
          }
        });
        setThreads(threadList);
      }
    }, [formComment?.fieldThreads, formQuestion]);

    const getQuestionContainer = {
      [FORM_QUESTION_TYPE.FreeText]: (
        <FreeTextQuestion
          maxLength={maxLength}
          mode={mode}
          formResponse={formResponse}
          handleFormResponse={handleFormResponse}
        />
      ),
      [FORM_QUESTION_TYPE.YesNo]: (
        <YesNoQuestion
          mode={mode}
          formResponse={formResponse}
          handleFormResponse={handleFormResponse}
        />
      ),
      [FORM_QUESTION_TYPE.Currency]: (
        <NumberQuestion
          mode={mode}
          formResponse={formResponse}
          handleFormResponse={handleFormResponse}
          prefix="$"
        />
      ),
      [FORM_QUESTION_TYPE.Date]: (
        <DateQuestion
          mode={mode}
          rangePicker={(formQuestion?.Meta as DateType)?.DateRange}
          formResponse={formResponse}
          handleFormResponse={handleFormResponse}
        />
      ),
      [FORM_QUESTION_TYPE.Email]: (
        <EmailQuestion
          mode={mode}
          formResponse={formResponse}
          handleFormResponse={handleFormResponse}
        />
      ),
      [FORM_QUESTION_TYPE.Number]: (
        <NumberQuestion
          mode={mode}
          formResponse={formResponse}
          handleFormResponse={handleFormResponse}
        />
      ),
      [FORM_QUESTION_TYPE.PhoneNumber]: (
        <PhoneNumberQuestion
          mode={mode}
          formResponse={formResponse}
          handleFormResponse={handleFormResponse}
        />
      ),
      [FORM_QUESTION_TYPE.FileUpload]: (
        <UploadQuestion
          mode={mode}
          formResponse={formResponse}
          handleFormResponse={handleFormResponse}
          responseMode={responseMode}
        />
      ),
      [FORM_QUESTION_TYPE.ListBox]: (
        <ListBoxQuestion
          mode={mode}
          disabled={disabled}
          smartForm={smartForm}
          formQuestion={formQuestion}
          formResponse={formResponse}
          handleFormResponse={handleFormResponse}
        />
      ),
      [FORM_QUESTION_TYPE.Signature]: (
        <SignatureQuestion
          formQuestion={formQuestion}
          formResponse={formResponse}
          handleFormResponse={handleFormResponse}
          formWidth={400}
          formHeight={400}
        />
      ),
    };

    const updateQuestionTitle = () => {
      const updatedQuestionTitle = questionInput?.trim();

      if (formQuestion && formQuestion?.Title !== updatedQuestionTitle) {
        dispatch(
          updateQuoteFormQuestionEffect({
            ...formQuestion,
            Title: updatedQuestionTitle,
          }),
        );
      }
    };

    useEffect(() => {
      setQuestionInput(formQuestion?.Title || '');
    }, [formQuestion?.UUID]);

    console.log('Questioons');
    return (
      <div className={classnames({ [styles.adminContainer]: isBuilder }, styles.container)}>
        <div
          className={classnames({
            [styles.content]: mode === FORM_MODE.Edit,
            [styles.previewContent]: mode === FORM_MODE.View,
          })}
        >
          {formQuestion && (
            <>
              <TextArea
                onBlur={() => updateQuestionTitle()}
                disabled={disabled || mode === FORM_MODE.View}
                autoSize
                placeholder="Write your question here..."
                className={classnames({
                  [styles.questionInput]: mode === FORM_MODE.Edit,
                  [styles.questionView]: mode === FORM_MODE.View,
                })}
                value={questionInput}
                onChange={(e) => setQuestionInput(e.target.value)}
                bordered={false}
                maxLength={1024}
              />
              {formQuestion?.Fields?.[0]?.widgets?.length && setFieldInfo ? (
                <Tooltip
                  placement="bottomLeft"
                  title={HelpMessage(
                    formQuestion.UUID,
                    false,
                    {
                      fieldId: formQuestion.Fields[0].id,
                      meta: {
                        coordinates: {
                          x: formQuestion.Fields[0].widgets[0]?.x,
                          y: formQuestion.Fields[0].widgets[0]?.y,
                        },
                        page: formQuestion.Fields[0].widgets[0].pageNumber,
                      },
                    },
                    setFieldInfo,
                  )}
                  trigger="hover"
                  overlayClassName={styles.toolTipContainer}
                  zIndex={999}
                >
                  <span>{getQuestionContainer[formQuestion?.Type]}</span>
                </Tooltip>
              ) : (
                <AnswersContainer>{getQuestionContainer[formQuestion?.Type]}</AnswersContainer>
              )}
              {handleGetFormComments &&
                handleAddFormComment &&
                handleEditFormComment &&
                handleDeleteFormComment && (
                  <div ref={ref} className={styles.commentWrapper}>
                    <FormCommentPopover
                      isNewThread={false}
                      commentUsers={commentUsers}
                      threads={threads}
                      handleGetFormComments={handleGetFormComments}
                      handleAddFormComment={handleAddFormComment}
                      handleEditFormComment={handleEditFormComment}
                      handleDeleteFormComment={handleDeleteFormComment}
                      handleToggleResolveThread={handleToggleResolveThread}
                    />
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    );
  },
);
