import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { map } from 'lodash-es';

import { useLongPress } from 'hooks';

import { getOpenedQuotesListThreads } from 'store/selectors/sockets/threads';
import { getUserId } from 'store/selectors/user';

import ActionsList from 'components/Drawers/components/ActionsList';
import { NoThreads, UsersMessage } from 'components';
import { getArchiveThreadOption } from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import styles from './styles.module.scss';

const QuotesDrawerThreadsList = ({ openCloseThread }) => {
  const dispatch = useDispatch();
  const quotesStatusMap = useSelector(getOpenedQuotesListThreads);
  const userId = useSelector(getUserId);

  const [actionsPosition, setActionPosition] = useState(null);
  const [actions, setActions] = useState([]);

  const list = useMemo(() => Object.entries(quotesStatusMap), [quotesStatusMap]);

  const onLongPress = (event, [thread]) => {
    setActionPosition({ x: event?.clientX, y: event?.clientY });
    setActions([
      ...getArchiveThreadOption(dispatch, thread, () => {
        setActionPosition(null);
      }),
    ]);
  };
  const onPress = (event, [thread]) => {
    openCloseThread(thread?.Id);
  };

  const longPressEvent = useLongPress(onLongPress, onPress);

  return (
    <>
      {list?.length ? (
        map(list, ([status, quotes]) => (
          <div key={status}>
            <div className={styles.label}>{status}</div>
            {map(quotes, (thread) => (
              <UsersMessage
                key={thread.Id}
                userId={userId}
                thread={thread}
                {...longPressEvent(thread)}
              />
            ))}
          </div>
        ))
      ) : (
        <NoThreads />
      )}
      <ActionsList actions={actions} position={actionsPosition} />
    </>
  );
};

QuotesDrawerThreadsList.propTypes = {
  openCloseThread: PropTypes.func.isRequired,
};

export default QuotesDrawerThreadsList;
