import {
  cancelFormDocument,
  deleteDraftForm,
  getDraftForms,
  getTeamAgentFormDetails,
  getformdetails,
  previewCombinedForm,
  previewExternalCombinedForm,
  removeEditor,
  replaceEditor,
  replaceSignatory,
  resendEmail,
  sendMessage,
  voidFormDocument,
} from 'api/formProcess';
import {
  cancelFormDocumentAction,
  deleteDraftFormAction,
  getDraftFormsAction,
  getFormDetailsAction,
  previewCombinedFormAction,
  removeEditorAction,
  replaceEditorAction,
  replaceSignatoryAction,
  resendEmailAction,
  resetFormDetailsAction,
  sendMessageAction,
  voidFormDocumentAction,
} from 'store/actions/formProcess';
import Api from 'store/effects/core/api';

export const sendMessageEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: sendMessageAction,
    method: sendMessage,
  });

  return sendRequest(cfg, {}, cb);
};

export const cancelFormDocumentEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: cancelFormDocumentAction,
    method: cancelFormDocument,
  });

  return sendRequest(cfg, {}, cb);
};

export const voidFormDocumentEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: voidFormDocumentAction,
    method: voidFormDocument,
  });

  return sendRequest(cfg, {}, cb);
};

export const getDraftFormsEffect = () => {
  const sendRequest = Api.execBase({
    action: getDraftFormsAction,
    method: getDraftForms,
  });

  return sendRequest();
};

export const deleteDraftFormEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: deleteDraftFormAction,
    method: deleteDraftForm,
  });

  return sendRequest(cfg, {}, cb);
};

export const getFormDetailsEffect = (cfg, cb = {}) => {
  const sendRequest = Api.execBase({
    action: getFormDetailsAction,
    method: getformdetails,
  });

  return sendRequest(cfg, {}, cb);
};

export const getTeamAgentFormDetailsEffect = (cfg, cb = {}) => {
  const sendRequest = Api.execBase({
    action: getFormDetailsAction,
    method: getTeamAgentFormDetails,
  });

  return sendRequest(cfg, {}, cb);
};

export const resetFormDetailsEffect = (dispatch) => {
  dispatch(resetFormDetailsAction());
};

export const resendEmailEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: resendEmailAction,
    method: resendEmail,
  });

  return sendRequest(cfg, {}, cb);
};

export const replaceEditorEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: replaceEditorAction,
    method: replaceEditor,
  });

  return sendRequest(cfg, {}, cb);
};

export const replaceSignatoryEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: replaceSignatoryAction,
    method: replaceSignatory,
  });

  return sendRequest(cfg, {}, cb);
};

export const removeEditorEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: removeEditorAction,
    method: removeEditor,
  });

  return sendRequest(cfg, {}, cb);
};

export const previewCombinedFileEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: previewCombinedFormAction,
    method: previewCombinedForm,
  });

  const { formProcessId, isCompleted } = cfg;

  const config = {
    formProcessId,
    ...(!isCompleted && { params: { formProcessPublicId: formProcessId } }),
    responseType: 'arraybuffer',
  };

  return sendRequest(config, {}, cb);
};

export const previewExternalCombinedFileEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: previewCombinedFormAction,
    method: previewExternalCombinedForm,
  });

  const { token } = cfg;

  const config = {
    token,
    responseType: 'arraybuffer',
  };

  return sendRequest(config, {}, cb);
};
