import { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { isRoleExistForSignUp } from 'settings/constants/roles';
import { signupRoleEffect } from 'store/effects/loginGroup';
import { routes } from 'settings/navigation/routes';
import { getUserRolesMapSelector } from 'store/selectors/user';

const useSignUpRole = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAgentSelfOnboarding = location.pathname.includes('/onboardingv2');

  const { isAgent } = useSelector(getUserRolesMapSelector);

  useEffect(() => {
    if (isAgent && isAgentSelfOnboarding) {
      dispatch(signupRoleEffect({ role: 'agent' }));
      return;
    }
    if (params.role && isRoleExistForSignUp(params.role)) {
      dispatch(signupRoleEffect({ role: params.role }));
    } else {
      history.push(routes.signUp);
    }
  }, []); // eslint-disable-line
};

export default useSignUpRole;
