export function divide(val1, val2) {
  const result = val1 / val2;

  if (Number.isNaN(result)) return 0;
  if (result === Infinity) return 1;

  return result;
}

export function calculatePercentageRatio(val1, val2, fractionDigits = 2) {
  return parseFloat((divide(val1, val2) * 100).toFixed(fractionDigits));
}

export function fixTick(value) {
  if (parseInt(value, 10) === value) {
    return `${value}`;
  }
  return value.toFixed(1);
}

export function shortenNumber(d, sign = '') {
  if (d >= 1e9) {
    return `${fixTick(d / 1e9)}B`;
  }

  if (d >= 1e6) {
    return `${fixTick(d / 1e6)}M`;
  }

  if (d >= 1e3) {
    return `${fixTick(d / 1e3)}K`;
  }

  return `${d}${sign}`;
}

export function convertToDecimalIfNotWhole(num, decimalPlaces = 2) {
  // Convert to fixed decimal places
  const fixedNum = num?.toFixed(decimalPlaces);

  // Check if the number is effectively an integer
  if (Number.isInteger(parseFloat(fixedNum))) {
    return parseFloat(fixedNum);
  }

  return fixedNum;
}
