import { prefsIds, PREF_TYPE } from 'settings/constants/preferences';
import Icons from './../../Icons/Icons';

export const PropertyCollapsibleOptions: any[] = [
  {
    key: '1',
    title: 'Layout',
    icon: Icons.OPEN_FLOOR_PLAN,
    criteria: [
      {
        id: prefsIds.singleFloorPlan,
        showTitle: 'Single Story',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.multiFloorPlan,
        showTitle: 'Multi-Story',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.basementRoom,
        showTitle: 'Basement',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.openFloorPlan,
        showTitle: 'Open Floor Plan',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.firstFloorBedroom,
        showTitle: 'First-Floor Bedroom',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.flexSpaces,
        showTitle: 'Flex Spaces',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.homeOffice,
        showTitle: 'Home Office',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.aduGuestHouse,
        showTitle: 'ADU/Guest House',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.laundryRoom,
        showTitle: 'Laundry Room',
        type: PREF_TYPE.homePrefs,
      },
    ],
  },
  {
    key: '2',
    title: 'Property Condition',
    icon: Icons.NEW_CONSTRUCTION,
    criteria: [
      {
        id: prefsIds.newConstruction,
        showTitle: 'New Construction',
        type: PREF_TYPE.homePrefs,
      },
      // {
      //   id: prefsIds.moveInReady,
      //   showTitle: 'Move-In Ready',
      //   type: PREF_TYPE.homePrefs,
      // },
      // {
      //   id: prefsIds.fixerUpper,
      //   showTitle: 'Fixer-Upper',
      //   type: PREF_TYPE.homePrefs,
      // },
    ],
  },
  {
    key: '3',
    title: 'Outdoor Spaces',
    icon: Icons.OUTDOOR_SPACE,
    criteria: [
      {
        id: prefsIds.largeLot,
        showTitle: 'Large Lot',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.fencedYard,
        showTitle: 'Fenced Yard',
        type: PREF_TYPE.outdoorPrefs,
      },
      {
        id: prefsIds.balcony,
        showTitle: prefsIds.balcony,
        type: PREF_TYPE.outdoorPrefs,
      },
      {
        id: prefsIds.deck,
        showTitle: prefsIds.deck,
        type: PREF_TYPE.outdoorPrefs,
      },
      {
        id: prefsIds.patio,
        showTitle: prefsIds.patio,
        type: PREF_TYPE.outdoorPrefs,
      },
      {
        id: prefsIds.pool,
        showTitle: prefsIds.pool,
        type: PREF_TYPE.outdoorPrefs,
      },
      {
        id: prefsIds.outdoorKitchen,
        showTitle: 'Outdoor Kitchen',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.waterFront,
        showTitle: 'Waterfront',
        type: PREF_TYPE.outdoorPrefs,
        additionalOptions: {
          type: 'checkbox-optional',
          options: [
            {
              id: prefsIds.dock,
              label: 'Dock',
            },
          ],
        },
      },
    ],
  },
  {
    key: '4',
    title: prefsIds.parking,
    icon: Icons.PARKING,
    criteria: [
      {
        id: prefsIds.parking,
        showTitle: 'Parking',
        type: PREF_TYPE.parking,
      },
    ],
  },
  {
    key: '5',
    title: 'Kitchen Features',
    icon: Icons.LARGE_KITCHEN,
    criteria: [
      {
        id: prefsIds.kitchenFeaturesLargerThanAverage,
        showTitle: 'Larger Size',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.kitchenBreakfastNook,
        showTitle: 'Breakfast Nook',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.kitchenFeaturesIsland,
        showTitle: 'Island',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.kitchenFeaturesStainlessSteelAppliances,
        showTitle: 'Stainless Steel Appliances',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.kitchenCabinetColor,
        showTitle: 'Cabinet Color',
        type: PREF_TYPE.homePrefs,
        additionalOptions: {
          type: 'button',
          label: 'Choose Cabinet color',
          options: [
            {
              id: prefsIds.KitchenCabinetWhite,
              label: 'White',
              showTitle: 'White Kitchen Cabinet',
            },
            {
              id: prefsIds.KitchenCabinetGray,
              label: 'Gray',
              showTitle: 'Gray Kitchen Cabinets',
            },
            {
              id: prefsIds.KitchenCabinetBrown,
              label: 'Brown',
              showTitle: 'Brown Kitchen Cabinets',
            },
            {
              id: prefsIds.KitchenCabinetBlack,
              label: 'Black',
              showTitle: 'Black Kitchen Cabinets',
            },
          ],
        },
      },
      {
        id: prefsIds.kitchenDoubleOven,
        showTitle: 'Double Oven',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.kitchenGasCooktop,
        showTitle: 'Gas Cooktop',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.kitchenElectricCooktop,
        showTitle: 'Electric Cooktop',
        type: PREF_TYPE.homePrefs,
      },
    ],
  },
  {
    key: '6',
    title: prefsIds.flooring,
    icon: Icons.FLOORING,
    criteria: [
      {
        id: prefsIds.flooringHardwood,
        showTitle: 'Wood',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.flooringCarpet,
        showTitle: 'Carpet',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.flooringTile,
        showTitle: 'Tile',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.flooringParquet,
        showTitle: 'Parquet',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.flooringStone,
        showTitle: 'Concrete',
        type: PREF_TYPE.homePrefs,
      },
    ],
  },
  {
    key: '7',
    title: 'Interior Features',
    icon: Icons.INTERIOR_FEATURES,
    criteria: [
      {
        id: prefsIds.fireplace,
        showTitle: prefsIds.fireplace,
        type: PREF_TYPE.interiorStylesPrefs,
      },
      {
        id: prefsIds.ceilingFans,
        showTitle: 'Ceiling Fans',
        type: PREF_TYPE.interiorStylesPrefs,
      },
      {
        id: prefsIds.highCeilings,
        showTitle: 'High Ceilings',
        type: PREF_TYPE.interiorStylesPrefs,
      },
      {
        id: prefsIds.naturalLight,
        showTitle: 'Natural Light',
        type: PREF_TYPE.interiorStylesPrefs,
      },
      {
        id: prefsIds.floorToCeilingWindows,
        showTitle: 'Floor-to-Ceiling Windows',
        type: PREF_TYPE.interiorStylesPrefs,
      },
      {
        id: prefsIds.frenchDoors,
        showTitle: 'French Doors',
        type: PREF_TYPE.interiorStylesPrefs,
      },
      {
        id: prefsIds.crownMolding,
        showTitle: 'Crown Molding',
        type: PREF_TYPE.interiorStylesPrefs,
      },
      {
        id: prefsIds.builtinShelves,
        showTitle: 'Built-In Shelves',
        type: PREF_TYPE.interiorStylesPrefs,
      },
      {
        id: prefsIds.walkinClosets,
        showTitle: 'Walk-in Closet(s)',
        type: PREF_TYPE.interiorStylesPrefs,
      },
    ],
  },
  {
    key: '9',
    title: 'Amenities',
    icon: Icons.AMENITIES,
    criteria: [
      {
        id: prefsIds.elevator,
        showTitle: prefsIds.elevator,
        type: PREF_TYPE.amenitiesPrefs,
      },
      {
        id: prefsIds.gym,
        showTitle: prefsIds.gym,
        type: PREF_TYPE.amenitiesPrefs,
      },
      {
        id: prefsIds.packageReceiving,
        showTitle: 'Package Receiving',
        type: PREF_TYPE.amenitiesPrefs,
      },
      {
        id: prefsIds.rooftop,
        showTitle: 'Rooftop',
        type: PREF_TYPE.amenitiesPrefs,
      },
      {
        id: prefsIds.doorman,
        showTitle: 'Doorman/Security',
        type: PREF_TYPE.amenitiesPrefs,
      },
      {
        id: prefsIds.onSiteManagement,
        showTitle: 'On-Site Management',
        type: PREF_TYPE.amenitiesPrefs,
      },
      {
        id: prefsIds.dogRun,
        showTitle: 'Dog Run',
        type: PREF_TYPE.amenitiesPrefs,
      },
      {
        id: prefsIds.dryCleaning,
        showTitle: 'Dry Cleaning',
        type: PREF_TYPE.amenitiesPrefs,
      },
      {
        id: prefsIds.gatedCommunity,
        showTitle: 'Gated Community',
        type: PREF_TYPE.amenitiesPrefs,
      },
      {
        id: prefsIds.communityPool,
        showTitle: 'Community Pool',
        type: PREF_TYPE.amenitiesPrefs,
      },
      {
        id: prefsIds.playground,
        showTitle: prefsIds.playground,
        type: PREF_TYPE.amenitiesPrefs,
      },
    ],
  },
  {
    key: '8',
    title: prefsIds.view,
    icon: Icons.VIEW,
    criteria: [
      {
        id: prefsIds.ViewWater,
        showTitle: 'Water',
        type: PREF_TYPE.ViewPrefs,
      },
      {
        id: prefsIds.ViewMountain,
        showTitle: 'Mountain',
        type: PREF_TYPE.ViewPrefs,
      },
      {
        id: prefsIds.ViewCity,
        showTitle: 'City',
        type: PREF_TYPE.ViewPrefs,
      },
      {
        id: prefsIds.ViewGolfCourse,
        showTitle: 'Golf Course',
        type: PREF_TYPE.ViewPrefs,
      },
      {
        id: prefsIds.ViewGreenspace,
        showTitle: 'Greenspace',
        type: PREF_TYPE.ViewPrefs,
      },
    ],
  },
  {
    key: '10',
    title: 'Mechanicals',
    icon: Icons.MECHANICALS,
    criteria: [
      {
        id: prefsIds.HeatSourceNaturalGas,
        showTitle: 'Gas Heating',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.HeatSourceElectric,
        showTitle: 'Electric Heating',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.SewageSeptic,
        showTitle: 'Septic System',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.SewageSewer,
        showTitle: 'Sewer System',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.WaterSourceCity,
        showTitle: 'City Water',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.WaterSourceWell,
        showTitle: 'Well Water',
        type: PREF_TYPE.homePrefs,
      },
      {
        id: prefsIds.SolarPanel,
        showTitle: 'Solar Panels',
        type: PREF_TYPE.homePrefs,
      },
    ],
  },
];
