import { HTMLAttributes } from 'react';
import classNames from 'classnames';

interface MosaikLogoProps extends HTMLAttributes<HTMLDivElement> {
  primary?: boolean;
}

const MosaikLogo = ({ primary = true, className, ...props }: MosaikLogoProps) => (
  <div {...props} className={classNames(className, 'prevent-user-select')}>
    {primary ? (
      <svg
        width="138"
        height="28"
        viewBox="0 0 138 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M133.401 18.9891L129.734 22.6562L133.401 26.3234L137.069 22.6562L133.401 18.9891Z"
          fill="#FF576D"
        />
        <path
          d="M32.1102 13.6793V27.0398H25.2079V14.2602C25.2079 13.3034 24.6612 12.7226 22.9527 12.7226C21.8251 12.7226 20.9025 13.3377 19.9799 14.1236V27.0398H13.0092V14.2602C13.0092 13.3034 12.4967 12.7226 10.7882 12.7226C9.72899 12.7226 8.77219 13.2693 7.78123 14.0894V27.0398H0.878906V9.54476H7.78131V12.7567C9.25062 10.809 11.3349 9.20313 14.5811 9.20313C17.4856 9.20313 19.4333 10.4332 19.8775 13.1668C21.2443 11.1166 23.4995 9.20313 26.7115 9.20313C30.1625 9.20306 32.1102 10.7407 32.1102 13.6793Z"
          fill="#252D44"
        />
        <path
          d="M34.502 18.634C34.502 13.1668 38.8415 9.20312 45.0263 9.20312C51.211 9.20312 55.5165 12.6201 55.5165 17.9506C55.5165 23.4178 51.211 27.3815 45.0263 27.3815C38.8415 27.3815 34.502 23.9645 34.502 18.634ZM48.1357 19.6591C48.1357 14.1578 47.2473 10.5699 44.8896 10.5699C42.6002 10.5699 41.8827 12.9276 41.8827 16.9255C41.8827 22.4269 42.7711 26.0147 45.163 26.0147C47.4182 26.0147 48.1357 23.657 48.1357 19.6591Z"
          fill="#252D44"
        />
        <path
          d="M56.8828 24.4088L59.8556 21.6752C62.0767 25.0239 64.7419 25.7072 66.3137 25.6389C67.6122 25.5706 68.774 24.9897 68.774 23.7253C68.774 22.2219 67.0313 21.6751 63.7852 20.8892C59.4797 19.7275 57.3612 18.224 57.3612 15.0462C57.3612 11.2533 60.6073 9.20312 65.5961 9.20312C69.9699 9.20312 72.9426 10.8774 72.9426 12.8593C72.9426 13.7477 72.3276 14.602 70.7558 14.602C69.3548 14.602 68.9789 13.9527 68.9789 13.0644C68.9789 11.5268 68.0905 10.57 65.7328 10.57C63.6143 10.57 62.5208 11.3559 62.5208 12.5177C62.5208 13.9528 64.161 14.397 67.4413 15.3196C71.5417 16.5155 73.9336 17.8823 73.9336 21.2993C73.9336 25.2289 70.6533 27.3816 65.0836 27.3816C61.7008 27.3816 59.0013 26.664 56.8828 24.4088Z"
          fill="#252D44"
        />
        <path
          d="M93.0672 14.8753V27.0398H86.2332V24.1354C85.1056 25.7755 83.3288 27.279 80.4585 27.279C77.5199 27.279 75.2988 25.7072 75.2988 22.9053C75.2988 20.1033 77.5882 18.9074 80.0143 18.1898L86.2332 16.3105V12.996C86.2332 11.4925 85.7207 10.4674 84.3539 10.4674C82.8163 10.4674 82.2353 11.7317 82.2353 13.3719C82.2353 14.8412 80.8685 15.7979 79.0917 15.7979C77.3148 15.7979 76.5631 14.8411 76.5631 13.5427C76.5631 10.7408 80.0826 9.20312 84.9689 9.20312C89.9236 9.20313 93.0672 10.6383 93.0672 14.8753ZM86.2332 23.247V17.6773L84.1489 18.3607C82.8504 18.7707 81.962 19.4199 81.962 21.2651C81.962 22.9053 82.6796 24.1354 84.2514 24.1354C84.6262 24.1385 84.9972 24.0607 85.3392 23.9074C85.6812 23.7541 85.9862 23.5288 86.2332 23.247Z"
          fill="#252D44"
        />
        <path
          d="M96.6543 4.11219C96.6543 2.02791 98.397 0.558594 100.413 0.558594C102.361 0.558594 104.172 2.02791 104.172 4.11219C104.172 6.23074 102.361 7.73421 100.413 7.73421C98.397 7.73427 96.6543 6.2308 96.6543 4.11219ZM96.9618 27.0402V9.54528H103.864V27.0403L96.9618 27.0402Z"
          fill="#252D44"
        />
        <path
          d="M121.53 27.0384L116.404 19.9653C116.097 20.1703 115.823 20.3753 115.516 20.6145V27.0385H108.613V0.898438H115.516V18.6668C118.29 16.1681 120.423 13.0391 121.735 9.54343H127.27C125.835 11.9012 123.511 14.2589 120.71 16.6166L128.705 27.0384H121.53Z"
          fill="#252D44"
        />
      </svg>
    ) : (
      <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="72" height="72" />
        <g clipPath="url(#clip0_794_1145)">
          <path
            d="M52.8668 29.8319V48.3512H43.2993V30.6371C43.2993 29.3109 42.5414 28.5057 40.1732 28.5057C38.6102 28.5057 37.3314 29.3583 36.0526 30.4476V48.3512H26.3903V30.6371C26.3903 29.3109 25.6799 28.5057 23.3117 28.5057C21.8434 28.5057 20.5172 29.2635 19.1437 30.4003V48.3512H9.57617V24.1008H19.1437V28.553C21.1803 25.8533 24.0695 23.6272 28.5691 23.6272C32.595 23.6272 35.2948 25.3323 35.9105 29.1214C37.8051 26.2796 40.9311 23.6272 45.3832 23.6272C50.167 23.6272 52.8668 25.7586 52.8668 29.8319Z"
            fill="#252D44"
          />
          <path
            d="M62.047 38.1849L56.9639 43.2681L62.047 48.3512L67.1302 43.2681L62.047 38.1849Z"
            fill="#FF576D"
          />
        </g>
        <defs>
          <clipPath id="clip0_794_1145">
            <rect
              width="57.5543"
              height="24.724"
              fill="white"
              transform="translate(9.57715 23.6272)"
            />
          </clipPath>
        </defs>
      </svg>
    )}
  </div>
);

export default MosaikLogo;
