import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { link } from 'settings/navigation/link';
import { Icons } from 'pages/Workshop/Icons';
import { Row, Col } from 'antd';
import { SearchFieldWithDebounce } from 'components';
import {
  setShowingsIsArchiveEffect,
  setShowingsArchiveIdEffect,
  searchShowingAggregateByUserIdEffect,
  resetShowingAggregateByUserIdSearchEffect,
  getShowingAggregateByUserIdEffect,
} from 'store/effects/showingAggregate';
import { InactiveShowings } from '../components/InactiveShowings';
import {
  getShowingArchiveIdSelector,
  getShowingIsArchiveSelector,
} from 'store/selectors/showingAggregate';

import styles from './styles.module.scss';
import { Filter } from '../Filter';
import { ShowingsFilter, LisitngsFilter } from '../components/QuickFilters';
import { getUserId } from 'store/selectors/user';

export const LocalHeader = () => {
  const history = useHistory();
  const { isArchive } = useSelector(getShowingIsArchiveSelector);
  const { showingId } = useSelector(getShowingArchiveIdSelector);
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);

  const [inactiveModal, setInactiveModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showingFilters, setShowingFilters] = useState({});

  const redirectTo = () => history.push(link.toTransactionWithFlowBasicPlanCreate('enableShowing'));

  const onArchiveModeChange = () => {
    dispatch(setShowingsIsArchiveEffect(!isArchive));
    const statusFilters = !isArchive ? ['Past', 'Declined', 'Canceled'] : [];
    dispatch(
      getShowingAggregateByUserIdEffect({
        id: userId,
        filters: { Status: statusFilters },
      }),
    );
  };

  const resetArchiveId = () => dispatch(setShowingsArchiveIdEffect(null));

  useEffect(() => {
    function handleWindowSizeChange() {
      if (window.innerWidth <= 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    if (document.readyState === 'complete') {
      handleWindowSizeChange();
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.addEventListener('load', handleWindowSizeChange);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isMobile]);

  const renderBackBtn = () => {
    if (!showingId) return;

    return (
      <div className={styles.btn} onClick={resetArchiveId}>
        <Icons className={styles.addIcon} variant={Icons.ARROW_LEFT} />
        {isMobile ? '' : <span className="icon-text">Back</span>}
      </div>
    );
  };

  const renderActions = () => {
    if (showingId) return;

    return <Filter setShowingFilters={setShowingFilters} />;
  };

  return (
    <div className={styles.workshopHeader}>
      <Row className={styles.workshopActionsContainer} align="middle" justify="space-between">
        <Col className={styles.workshopHeaderLeft} xs={24} lg={16}>
          <div className={styles.searchWrapper}>
            <SearchFieldWithDebounce
              className={styles.inputWrap}
              classNameInput={styles.searchInput}
              iconClassName={styles.icon}
              resetAction={() => resetShowingAggregateByUserIdSearchEffect(userId)}
              sendAction={({ search }) => searchShowingAggregateByUserIdEffect({ search, userId })}
              isLoading={false}
              placeholder="Search"
              testid="search"
              isTransactionAggregate={true}
              isExpandedVersion
            />
          </div>
          <div className="seperator" />
          <ShowingsFilter showingFilters={showingFilters} />
          <LisitngsFilter />
          <div className={styles.actionsLeft}>{renderBackBtn()}</div>
        </Col>
        <Col xs={24} lg={8}>
          <div className={styles.actionsRight}>
            <div className="seperator" />
            {renderActions()}
            <div
              className={classNames(styles.btn, { [styles.selected]: isArchive })}
              onClick={onArchiveModeChange}
            >
              <Icons
                className={styles.btnIcon}
                variant={Icons.ARCHIVE}
                color={isArchive ? '#FFFFFF' : ''}
              />
            </div>
            <InactiveShowings isOpen={inactiveModal} onClose={() => setInactiveModal(false)} />
          </div>
        </Col>
      </Row>
    </div>
  );
};
