import { getAllTransactionTemplates } from 'api/templates';
import { requestGetTransactionTemplatesAction } from 'store/actions/templates';
import Api from 'store/effects/core/api';

export const getAllTransactionTemplatesEffect = (cfg, options, cb) => {
  const requestParams = {
    action: requestGetTransactionTemplatesAction,
    method: getAllTransactionTemplates,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};
