import { get, cloneDeep } from 'lodash-es';
import { any } from 'prop-types';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import {
  requestScheduleToursAction,
  requestCancelScheduleTourAction,
  requestUpdateScheduleTourAction,
} from 'store/actions/tour';
import { READY } from 'settings/constants/apiState';
import { TourState } from 'types/tours';

const initialData: TourState = {
  state: IDLE,
  data: [],
};

export default handleActions(
  {
    [requestScheduleToursAction as any]: (state: TourState, { payload }): TourState => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.value', initialData.data),
    }),
    [requestUpdateScheduleTourAction as any]: (state: TourState, { payload }): TourState => {
      const tours = cloneDeep(state.data || []);

      if (payload.state === READY && tours.length) {
        const updatedTour = get(payload, 'data.value', {});
        const tourId = updatedTour.UUID;
        const index = tours.findIndex((item) => item.UUID === tourId);

        if (index !== -1) {
          tours[index] = {
            ...tours[index],
            ...updatedTour,
          };
        }
      }

      return {
        ...state,
        state: payload.state,
        data: [...tours],
      };
    },
    [requestCancelScheduleTourAction as any]: (state: TourState, { payload }): TourState => {
      const tours = cloneDeep(state.data || []);
      if (payload.state === READY && tours.length) {
        const updatedTour = get(payload, 'data.value', {});
        const tourId = updatedTour.UUID;
        const index = tours.findIndex((item) => item.UUID === tourId);

        if (index !== -1) {
          tours[index] = {
            ...tours[index],
            ...updatedTour,
          };
        }
      }

      return {
        ...state,
        state: payload.state,
        data: [...tours],
      };
    },
  },
  initialData,
);
