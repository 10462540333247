import { Fragment, useMemo, useState } from 'react';
import classNames from 'classnames';
import { convertToDecimalIfNotWhole } from 'helpers';
import NumberFormat from 'react-number-format';
import { PriceHighlighter, Separator } from './../../..';
import { SplitTypes } from '../../../../TransactionFinancials';
import { Icons } from 'pages/Workshop/Icons';
import { DynamicSplitOptions } from './DynamicSplitOptions';

import styles from './styles.module.scss';

type SplitItemType = {
  label: string;
  amount: number;
  percentage?: number;
  valueTypeEntered?: boolean;
  setEditField?: Function;
  toggleRestrict?: Function;
  handleDelete?: Function;
  isTransactionAdminOrOwner: boolean;
  splitType: SplitTypes.AGENT | SplitTypes.TEAM | SplitTypes.BROKERAGE | SplitTypes.CLIENT;
  isDynamicSplit?: boolean;
  isRestricted?: boolean;
  isDisabledField?: boolean;
};

export const SplitItem = ({
  label,
  amount,
  percentage,
  valueTypeEntered,
  setEditField,
  toggleRestrict,
  handleDelete,
  isTransactionAdminOrOwner,
  splitType,
  isDynamicSplit = false,
  isRestricted = false,
  isDisabledField = false,
}: SplitItemType) => {
  const [isEdit, setIsEdit] = useState(false);

  const onEdit = () => {
    setEditField && setEditField(true);
    setIsEdit(true);
  };

  // Display Team and Brokerage splits only to admin/owner & Hide Client Split Item
  if (
    (!isTransactionAdminOrOwner &&
      (splitType === SplitTypes?.TEAM || splitType === SplitTypes?.BROKERAGE)) ||
    splitType === SplitTypes?.CLIENT
  )
    return null;

  return (
    <Fragment>
      <div
        className={classNames(
          styles.splitItem,
          { [styles.splitItemEdit]: !isEdit },
          { [styles.disabled]: isDisabledField },
        )}
      >
        <Fragment>
          <div className={styles.labelContainer}>
            <span className={styles.label}>{label}</span>
            {(isRestricted ||
              splitType === SplitTypes?.TEAM ||
              splitType === SplitTypes?.BROKERAGE) && (
              <Icons variant={Icons.RESTRICTED} className={styles.icon} />
            )}
          </div>
          <div className={styles.amounts}>
            {amount && percentage ? (
              <>
                <NumberFormat
                  thousandSeparator
                  displayType="text"
                  value={convertToDecimalIfNotWhole(amount)}
                  prefix="$"
                  renderText={(val) => (
                    <span className={styles.amount}>
                      {val}
                      <PriceHighlighter isShow={!valueTypeEntered} />
                    </span>
                  )}
                />
                <span className={styles.percentage}>
                  ({convertToDecimalIfNotWhole(percentage)}%)
                  <PriceHighlighter isShow={valueTypeEntered} />
                </span>
                {!isDisabledField && (
                  <Fragment>
                    <div className={styles.editIcon}>
                      <div className={styles.fadeEffect} />
                      {!isDynamicSplit ? (
                        <>
                          <Icons
                            variant={Icons.EDIT_ROUND}
                            onClick={onEdit}
                            className={styles.icon}
                          />
                        </>
                      ) : (
                        <DynamicSplitOptions
                          onEdit={() => setEditField && setEditField()}
                          toggleRestrict={() => toggleRestrict && toggleRestrict()}
                          onDelete={() => handleDelete && handleDelete()}
                          isTransactionAdminOrOwner={isTransactionAdminOrOwner}
                          isRestricted={isRestricted}
                        />
                      )}
                    </div>
                  </Fragment>
                )}
              </>
            ) : (
              <div style={{ display: 'flex' }}>
                <span>-</span>
                {!isDisabledField && (
                  <Fragment>
                    <div className={styles.editIcon}>
                      <div className={styles.fadeEffect} />
                      {!isDynamicSplit ? (
                        <>
                          <Icons
                            variant={Icons.EDIT_ROUND}
                            onClick={onEdit}
                            className={styles.icon}
                          />
                        </>
                      ) : (
                        <DynamicSplitOptions
                          onEdit={() => setEditField && setEditField()}
                          toggleRestrict={() => toggleRestrict && toggleRestrict()}
                          onDelete={() => handleDelete && handleDelete()}
                          isTransactionAdminOrOwner={isTransactionAdminOrOwner}
                          isRestricted={isRestricted}
                        />
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </Fragment>
      </div>
      <Separator />
    </Fragment>
  );
};
