import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { getTransactionSelector } from 'store/selectors/transaction';

import { DatePicker } from 'components';
import { Question } from 'pages/Workshop/Transactions/TransactionCreate/components';

import styles from './styles.module.scss';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';
import { TransactionCloseComponentIds } from 'app-constants';
import { SetStateAction, useEffect, useState } from 'react';
import { formatDate } from 'helpers';
import { getTransactionEndDate } from 'utils';
import { listedStatusesArray } from 'settings/constants/transaction';

interface CloseDateProps {
  setStage: (stage: TransactionCloseComponentIds) => void;
  setClosingDate: (closingDate: SetStateAction<string>) => void;
  nextStage?: TransactionCloseComponentIds;
}

const CloseDate = ({
  setStage,
  setClosingDate,
  nextStage = TransactionCloseComponentIds.ClosePrice,
}: CloseDateProps) => {
  const [closeDate, setCloseDate] = useState('');
  const { transaction } = useSelector(getTransactionSelector);
  const isListed = listedStatusesArray.includes(transaction.Status);
  const keyDatesPrices = transaction?.PropertyTransactionKeyDatesPrices;
  const dateFormat = 'YYYY-MM-DD';

  useEffect(() => {
    const date = getTransactionEndDate(transaction.Status, keyDatesPrices, isListed);
    if (date) {
      setCloseDate(formatDate(date, dateFormat));
      setClosingDate(formatDate(date, dateFormat));
    }
  }, [transaction]);

  const onChangeHandler = (val: SetStateAction<string>) => {
    setCloseDate(formatDate(val, dateFormat));
    setClosingDate(formatDate(val, dateFormat));
  };

  return (
    <div testid="close_date" className={classNames(styles.container)}>
      <Question>What was the closing date?</Question>
      <AnswersContainer>
        <DatePicker
          variant={DatePicker.LIGHT}
          placeholder="MM/DD/YY"
          onChange={(e, val) => onChangeHandler(val?.[0])}
          value={closeDate}
          className={styles.dateInput}
          options={{ enableTime: false }}
          testid="effective_date"
        />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={() => setStage(nextStage)} disabled={!closeDate} />
      </ButtonsContainer>
    </div>
  );
};

export default CloseDate;
