import {
  getAllFormProcess,
  getAllTransactionFormProcess,
  getAllUnlinkFormProcess,
  getArchiveForms,
  previewFormDocument,
} from 'api/formProcess';
import {
  getAllFormProcessAction,
  getAllTransactionFormProcessAction,
  getAllUnlinkFormProcessAction,
  getArchiveFormsAction,
  previewFormDocumentAction,
  setFormShowArchiveAction,
  updateFormsFiltersAction,
} from 'store/actions/formProcess';
import Api from 'store/effects/core/api';

export const getAllFormProcessEffect = (
  cfg = {},
  options = {
    silent: false,
  },
) => {
  const requestParams = {
    action: getAllFormProcessAction,
    method: getAllFormProcess,
  };

  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options);
};

export const updateAllFormProcessEffect = (cfg) => (dispatch) => {
  dispatch(getAllFormProcessAction(cfg));
};

export const getAllUnlinkedFormProcessEffect = (
  cfg = {},
  options = {
    silent: false,
  },
) => {
  const requestParams = {
    action: getAllUnlinkFormProcessAction,
    method: getAllUnlinkFormProcess,
  };

  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options);
};

export const setIsArchiveEffect = (cfg) => (dispatch) => {
  dispatch(setFormShowArchiveAction(cfg));
};

export const setFormFilter = (cfg) => (dispatch) => {
  dispatch(setFormShowArchiveAction(cfg));
};

export const getAllTransactionFormProcessEffect = (cfg: { transactionId?: string }) => {
  const sendRequest = Api.execBase({
    action: getAllTransactionFormProcessAction,
    method: getAllTransactionFormProcess,
  });

  return sendRequest(cfg);
};

export const getArchiveFormsEffect = (cfg = {}) => {
  const sendRequest = Api.execBase({
    action: getArchiveFormsAction,
    method: getArchiveForms,
  });
  return sendRequest(cfg);
};

export const previewFormDocumentEffect = (cfg, options, cb) => {
  const requestParams = {
    action: previewFormDocumentAction,
    method: previewFormDocument,
  };
  const sendRequest = Api.execBase(requestParams);

  const { documentVaultUUID } = cfg;

  const config = {
    documentVaultUUID,
    responseType: 'arraybuffer',
  };

  return sendRequest(config, options, cb);
};
