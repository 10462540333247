import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AgentDocumentType, AgentDocumentTypes, DocumentOptionUtils } from 'types';
import { Dots } from 'components/Icons';
import { MenuIconTypes, MenuIcons } from 'components/ClientsDrawer/ProfileClient/Icons';
import { Menu, MenuItemType, MenuItem, Dropdown } from 'components-antd';
import { getUserId } from 'store/selectors/user';
import styles from './styles.module.scss';

interface OptionProps extends DocumentOptionUtils {
  document: AgentDocumentType;
  type: AgentDocumentTypes;
}

export const Options = ({
  document,
  type,
  optionUtils: {
    downloadDocument,
    previewDocument,
    openFormDetailsModal,
    previewDraftForm,
    handleOpenDeleteDocumentModal,
    shareDocument,
  },
}: OptionProps) => {
  const userId = useSelector(getUserId);

  const getOptionList = useCallback(() => {
    const items: MenuItemType[] = [];

    const deleteObject = {
      key: 'Option-Delete',
      title: 'Delete',
      icon: <MenuIcons type={MenuIconTypes.Delete} />,
      onClick: () => handleOpenDeleteDocumentModal(document.id, type),
    };

    if (type === AgentDocumentTypes.Drafts || type === AgentDocumentTypes.PendingForms) {
      if (type === AgentDocumentTypes.Drafts) {
        items.push({
          key: 'Option-View',
          title: 'View',
          icon: <MenuIcons type={MenuIconTypes.View} />,
          onClick: previewDraftForm,
        });
      } else if (type === AgentDocumentTypes.PendingForms && document?.pendingMeta?.formProcessId) {
        const formProcessId = document.pendingMeta.formProcessId;

        items.push({
          key: 'Option-View',
          title: 'View',
          icon: <MenuIcons type={MenuIconTypes.View} />,
          onClick: () => openFormDetailsModal(formProcessId),
        });
      }
    } else if (document.documentVaultUUID) {
      const documentVaultUUID = document.documentVaultUUID;

      items.push(
        {
          key: 'Option-View',
          title: 'View',
          icon: <MenuIcons type={MenuIconTypes.View} />,
          onClick: () => previewDocument(documentVaultUUID),
        },
        {
          key: 'Option-Download',
          title: 'Download',
          icon: <MenuIcons type={MenuIconTypes.Download} />,
          onClick: () => downloadDocument(documentVaultUUID),
        },
        {
          key: 'Option-Share',
          title: 'Share',
          icon: <MenuIcons type={MenuIconTypes.Share} />,
          onClick: () => shareDocument(documentVaultUUID),
        },
      );
    }
    if (userId === document.uploaderId) {
      items.push(deleteObject);
    }
    return items;
  }, [document]);

  const menu = (
    <Menu>
      {getOptionList().map(({ key, title, ...props }, index) => (
        <MenuItem
          key={`${key}-${document.title}-${index}`}
          title={title}
          {...props}
          className={styles.menuItem}
        >
          {title}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} overlayClassName={styles.menuOverlay} placement="bottomRight">
      <a onClick={(e) => e.preventDefault()}>
        <Dots />
      </a>
    </Dropdown>
  );
};
