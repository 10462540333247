export interface OfferLink {
  Id: number;
  UUID: string;
  SellerAgentId?: number;
  Status?: OfferLinkStatus;
  PropertyTransactionId?: number;
  Indefinitely: boolean;
  LinkActiveStartDate?: Date;
  LinkActiveDays?: number;
  LinkActiveEndDate?: Date;
  DisplayDeadlineOnPortal?: boolean;
  DeadlineDate?: Date;
  DeadlineTime?: Date;
  Timezone?: string;
  RequiredDocuments?: JSON;
  DisplaySubmissionPageNotes?: boolean;
  SubmissionPageNotes?: string;
  SendOfferSubmissionNotes?: boolean;
  OfferSubmissionNotes?: string;
  AutomaticallySendOffersToSeller?: boolean;
  EnableLink?: boolean;
  Link?: string;
  CreatedDate?: Date;
  UpdatedDate?: Date;
  CreatedBy?: number;
  UpdatedBy?: number;
  NotificationUser?: Array<NotificationUserType>;
}

export type NotificationUserType = {
  Id: number;
  OfferLinkId: number;
  UserId: number;
  TeamId: number;
};

export enum OfferLinkStatus {
  Active = 'Active',
  InActive = 'InActive',
}

export enum FinancingType {
  Conventional = 'Conventional',
  NonConforming = 'NonConforming',
  FHA = 'FHA',
  VA = 'VA',
  Cash = 'Cash',
}

export interface CounterOfferForm {
  OfferId?: number;
  PurchasePrice?: number;
  DownPayment?: number;
  EarnestMoneyDeposit?: number;
  ClosingDate?: string;
  FinancingType?: string;
  Contingencies?: any;
  Notes?: string;
  OfferIterationDocument?: JSON[];
  OfferExpiryIndefinitely?: boolean;
  OfferExpiryDays?: string;
  OfferExpiryEndDate?: string;
  TermiteInspection?: boolean;
  TermiteInspectionResponsibility?: string;
  HomeWarranty?: boolean;
  HomeWarrantyCost?: number;
  HomeWarrantyResponsibility?: string;
  Occupancy?: boolean;
  OccupancyDays?: number;
  Appliances?: string;
}

export enum OfferListDirection {
  Row = 'Row',
  Column = 'Column',
}
