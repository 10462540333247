import { createSelector } from 'reselect';
import {
  SelectedAreaGroupStateType,
  VisibleShapeData,
} from 'store/reducers/adminPanel/areaClassifier/selectedAreaGroup';

const localState = ({ adminPanel }) => adminPanel;
const areaClassifierState = createSelector(localState, (state) => state.areaClassifier);

const selectedAreaGroupState = createSelector(
  areaClassifierState,
  (state): SelectedAreaGroupStateType => state.selectedAreaGroup,
);

export const getAreaGroupsSelector = createSelector(
  areaClassifierState,
  ({ areaGroups }) => (areaGroups.data ?? []) as any[],
);

export const getAreaGroupsSearchTermSelector = createSelector(
  areaClassifierState,
  ({ areaGroups }) => areaGroups.searchTerm,
);

export const getEditableAreaGroupSelector = createSelector(
  selectedAreaGroupState,
  (state) => state.editableAreaGroup,
);

export const getVisibleShapesSelector = createSelector(
  selectedAreaGroupState,
  (state) =>
    (state.editableAreaGroup?.areas?.map((a) => a.shape).filter((s) => s) ??
      []) as VisibleShapeData[],
);

export const isEditableShapeSelectedSelector = createSelector(
  selectedAreaGroupState,
  (state) => state.editableShapeIndex !== null,
);

export const getEditableShapeIndexSelector = createSelector(
  selectedAreaGroupState,
  (state) => state.editableShapeIndex,
);

export const isNewAreaGroupEditableSelector = createSelector(
  selectedAreaGroupState,
  (state) => state.selectedAreaGroupId === 'new',
);

export const getIsEditModeSelector = createSelector(
  selectedAreaGroupState,
  (state) => state.isEditMode,
);
