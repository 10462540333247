import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card } from '../Card';
import { ScheduleItem, Stat } from './ScheduleItem';
import { GreenCheckMark } from 'components/Icons';
import {
  getLoadingScheduleSelector,
  getAgentScheduleSelector,
  getTeamScheduleSelector,
} from 'store/selectors/agentDashboard';
import { Wrapper as PendingWrapper } from 'components';

import styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';
import { TourStatus } from 'app-constants/enums/tours';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_BASIC } from 'settings/constants/roles';

type ScheduleWidgetProps = {
  teamStats: boolean | null;
};

type Stats = {
  dueToday: number;
  dueThisWeek: number;
};

export const ScheduleWidget: React.FC<ScheduleWidgetProps> = ({ teamStats }) => {
  const history = useHistory();
  const [stats, setStats] = useState<null | {
    milestones: Stats;
    closing: Stats;
    expiring: Stats;
    kits: Stats;
    showing: Stats;
    tours: Stats;
  }>(null);
  const [haveStats, setHaveStats] = useState<boolean>(false);

  const { isPending } = useSelector(getLoadingScheduleSelector);
  const agentData = useSelector(getAgentScheduleSelector);
  const teamData = useSelector(getTeamScheduleSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  useEffect(() => {
    if (!isPending && (agentData || teamData) && typeof teamStats !== 'undefined') {
      if (teamStats) {
        setStats(teamData);
        const hasStats = Object.values(teamData || {})?.some(
          (stat: any) => stat.dueToday > 0 || stat.dueThisWeek > 0,
        );
        setHaveStats(hasStats);
      } else {
        setStats(agentData);
        const hasStats = Object.values(agentData || {})?.some(
          (stat: any) => stat.dueToday > 0 || stat.dueThisWeek > 0,
        );
        setHaveStats(hasStats);
      }
    }
  }, [isPending, teamStats, agentData, teamData]);

  const handleRedirect = useCallback(
    (name: Stat, duration?: 'dueToday' | 'dueWeek') => {
      // Redirect to the corresponding page
      switch (name) {
        case Stat.TOURS:
          history.push(
            `/properties/tours?status=${TourStatus.Pending}&teamStats=${
              agentRole === TEAM_BASIC ? true : teamStats
            }`,
          );
          break;
        case Stat.SHOWINGS:
          history.push(
            `/workshop/showings?${duration && `${duration}=true`}&teamStats=${
              agentRole === TEAM_BASIC ? true : teamStats
            }`,
          );
          break;
        case Stat.MILESTONES:
          history.push(
            `/workshop/milestones?statuses=Incomplete&${duration && `${duration}=true`}&teamStats=${
              agentRole === TEAM_BASIC ? true : teamStats
            }`,
          );
          break;
        case Stat.CLOSINGS:
          history.push(
            `/workshop/transactions?statuses=Under+contract&${
              duration && `${duration}=true`
            }&teamStats=${agentRole === TEAM_BASIC ? true : teamStats}`,
          );
          break;
        case Stat.EXPIRINGS:
          history.push(
            `/workshop/transactions?statuses=OfficeExclusive,ActiveListing,PreListing,ComingSoon,OnHold${
              duration && `&${duration}=true`
            }&teamStats=${agentRole === TEAM_BASIC ? true : teamStats}`,
          );
          break;
      }
    },
    [teamStats],
  );

  return (
    <Card
      cardTitle={'Schedule'}
      showHeader={false}
      cardWrapperClassName={classNames(styles.cardWrapper, {
        [styles.noDataWrapper]: !stats,
      })}
    >
      <div className={styles.scheduleWidget}>
        <header className={styles.cardheader}>
          <div className={styles.leftHeading}>{'Schedule'}</div>
          <div className={styles.rightHeading}>
            <p>{'Today'}&nbsp;</p>
            <p className={styles.hideSeparator}>{'/'}&nbsp;</p>
            <p className={styles.thisWeek}>{' This Week'}</p>
          </div>
        </header>
        <PendingWrapper
          className={styles.pendingWrapper}
          isPending={isPending}
          loaderClassName={styles.loader}
        >
          {haveStats && stats ? (
            <>
              <ScheduleItem
                item={Stat.TOURS}
                today={stats.tours.dueToday}
                thisWeek={stats.tours.dueThisWeek}
                handleClick={handleRedirect}
              />
              <ScheduleItem
                item={Stat.SHOWINGS}
                today={stats.showing.dueToday}
                thisWeek={stats.showing.dueThisWeek}
                handleCountClick={handleRedirect}
              />
              <ScheduleItem
                item={Stat.MILESTONES}
                today={stats.milestones.dueToday}
                thisWeek={stats.milestones.dueThisWeek}
                handleCountClick={handleRedirect}
              />
              <ScheduleItem
                item={Stat.CLOSINGS}
                today={stats.closing.dueToday}
                thisWeek={stats.closing.dueThisWeek}
                handleCountClick={handleRedirect}
              />
              <ScheduleItem
                item={Stat.EXPIRINGS}
                today={stats.expiring.dueToday}
                thisWeek={stats.expiring.dueThisWeek}
                handleCountClick={handleRedirect}
              />
              {/* <ScheduleItem
                item={Stat.KITS}
                today={stats.kits.dueToday}
                thisWeek={stats.kits.dueThisWeek}
              /> */}
            </>
          ) : (
            <div className={styles.emptySchedule}>
              <div className={styles.checkMark}>
                <GreenCheckMark width={20} height={20} />
              </div>
              <p>
                {'Nothing on the schedule'}
                <br />
                {'for this week (yet).'}
              </p>
            </div>
          )}
        </PendingWrapper>
      </div>
    </Card>
  );
};
