import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ArrowLeft } from 'components/Icons';
import { DrawerHeaderTitle } from 'components/index';
import Icon from '../../Icons';

import styles from './styles.module.scss';

const Header = ({ className, onClose }) => (
  <div className={classNames(styles.header, className)}>
    <button testid="header_button" className={styles.allMessagesBtn} onClick={onClose}>
      <ArrowLeft className={styles.arrow} />
      <span>Message</span>
    </button>
    <div className={styles.titleWrapper}>
      <Icon className={styles.shareIcon} variant={Icon.ADD_PARTICIPANTS} />
      <DrawerHeaderTitle>Add Participants</DrawerHeaderTitle>
    </div>
  </div>
);

Header.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

Header.defaultProps = {
  className: '',
  onClose: () => {},
};

export default Header;
