import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { PENDING } from 'settings/constants/apiState';
import { THIRD_PARTY } from 'settings/constants/roles';
import { onBoardingSignUpEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getUserDataSelector, isPartnerSelector } from 'store/selectors/user';

import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from '../../agent/components/OnboardingContinueButton';
import { ValidationSchema } from './validation';

import classNames from 'classnames';
import { Input, Phone } from 'components';
import { Col, Row } from 'components-antd';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';

const initialValues = {
  partnerEmail: '',
  partnerPhone: '',
  website: '',
};

type Props = {
  onNext: () => {};
  stageIndex?: number;
  Controls: React.ElementType;
};

export const BusinessContactForm: React.FC<Props> = ({ stageIndex, onNext, Controls }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const user = useSelector(getUserDataSelector);
  const isPartner = useSelector(isPartnerSelector);

  const getSchema = () => {
    return ValidationSchema;
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      partnerEmail: (user?.[THIRD_PARTY]?.Partner?.Email as string) || initialValues.partnerEmail,
      partnerPhone:
        (user?.[THIRD_PARTY]?.Partner?.PhoneNumber as string) || initialValues.partnerPhone,
      website:
        (user?.[THIRD_PARTY]?.Partner?.Website as string) || (initialValues.website as string),
    },
    validationSchema: getSchema(),
    onSubmit(values) {
      dispatch(
        onBoardingSignUpEffect(
          { ...values, stageIndex },
          { isPartner, isCommonInfo: true },
          (err) => {
            if (!err) {
              onNext();
            }
          },
        ),
      );
    },
  });

  const isPending = (onBoarding as any).state === PENDING;

  const getTitle = () => {
    if (
      formik.values?.partnerEmail?.trim() ||
      formik.values?.partnerPhone?.trim() ||
      formik.values.website.trim()
    )
      return 'Please confirm your business contact information.';
    return 'What is your business contact information?';
  };

  return (
    <OnBoardingWrapper isPending={isPending} className={styles.wrapper}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question className={{ [styles.partnerTitle]: isPartner }}>{getTitle()}</Question>
        <AnswersContainer>
          <form onSubmit={formik.handleSubmit}>
            <Row className={classNames(styles.row, styles.partnerRow)} gutter={[16, 20]}>
              <Col xs={24} md={24}>
                <div className={classNames(styles.text, styles.partnerHeading)}>Email Address</div>
                <Input
                  name="partnerEmail"
                  value={formik.values.partnerEmail}
                  onChange={formik.handleChange}
                  error={formik.touched.partnerEmail ? formik.errors.partnerEmail : ''}
                  disabled={isPending}
                  testid="email"
                  variant={Input.LIGHT}
                  className={classNames(styles.formInput, styles.partnerFormInput)}
                />
              </Col>
              <Col xs={24} md={24}>
                <div className={classNames(styles.text, styles.partnerHeading)}>Phone Number</div>
                <div className={classNames(styles.phoneWrapper)}>
                  <Phone
                    name="partnerPhone"
                    onChange={(event, value) => {
                      formik.setFieldValue('partnerPhone', value);
                    }}
                    placeholder={''}
                    error={formik.touched.partnerPhone ? formik.errors.partnerPhone : ''}
                    disabled={isPending}
                    testid="phone"
                    value={formik.values.partnerPhone}
                    variant={Phone.LIGHT}
                    className={classNames(styles.formInput, styles.partnerFormInput)}
                  />
                </div>
              </Col>
              <Col xs={24} md={24}>
                <div className={classNames(styles.text, styles.partnerHeading)}>Website</div>
                <Input
                  name="website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  error={formik.touched.website ? formik.errors.website : ''}
                  disabled={isPending}
                  testid="website"
                  variant={Input.LIGHT}
                  className={classNames(styles.formInput, styles.partnerFormInput)}
                />
              </Col>
            </Row>
            <ButtonsContainer>
              <OnboardingContinueButton
                testid="done"
                title="Continue"
                className={styles.submit}
                isPending={isPending}
                disabled={!formik.isValid}
                onClick={formik.submitForm}
              />
            </ButtonsContainer>
          </form>
        </AnswersContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};
