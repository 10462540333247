import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { CurrentActiveSubscriptionPlan } from 'pages/Paywall/types';
import { PENDING } from 'settings/constants/apiState';

const localState = ({ subscription }) => subscription;

const getAvailableSubscriptionsObject = createSelector(
  localState,
  ({ availablePlans }) => availablePlans,
);

export const getActiveSubscriptionTeamDataSelector = createSelector(
  localState,
  (state) => state.activeSubscriptionTeamData.data,
);

export const getAvailableSubscriptionPlansSelector = createSelector(
  getAvailableSubscriptionsObject,
  ({ data }) => data,
);

const getInvoiceDataOverviewObject = createSelector(
  localState,
  ({ invoiceDataOverview }) => invoiceDataOverview,
);

export const invoiceDataOverviewLoading = createSelector(
  localState,
  ({ invoiceDataOverview }) => invoiceDataOverview.state === PENDING,
);

export const getInvoiceDataOverviewSelector = createSelector(
  getInvoiceDataOverviewObject,
  ({ data }) => data,
);

export const getCurrentPlanLevelSelector = createSelector(localState, (subscription) =>
  get(subscription, 'currentPlanLevel.data.CurrentPlan'),
);

export const getLegacyPlanInterval = createSelector(localState, (subscription) =>
  get(subscription, 'currentPlanLevel.data.Interval'),
);

export const getCurrentPlanNameSelector = createSelector(localState, (subscription) =>
  get(subscription, 'currentPlanLevel.data.InternalPlanName'),
);

export const getCurrentPlanSelector = createSelector(
  localState,
  (subscription) => get(subscription, 'currentPlanLevel.data') as CurrentActiveSubscriptionPlan,
);

export const getSubscriptionsObject = createSelector(localState, (subscription) => subscription);

export const getUpdatePaymentModal = createSelector(localState, (subscription) =>
  get(subscription, 'updatePaymentModel.isModalOpened'),
);
