import { createAction } from 'redux-actions';

export const requestConciergeLocationConfiguration = createAction(
  'REQUEST/GET_USER_SERVICE_CONFIGURATION',
);
export const requestUpdateConciergeLocationConfiguration = createAction(
  'REQUEST/UPDATE_USER_SERVICE_CONFIGURATION',
);
export const updateConciergeLocationConfiguration = createAction(
  'UPDATE/UPDATE_USER_SERVICE_CONFIGURATION',
);
export const openConciergeLocationModal = createAction('OPEN/USER_SERVICE_LOCATION_MODAL');
export const updateConciergeCategoryConfiguration = createAction(
  'UPDATE/UPDATE_CONCIERGE_CATEGORY_CONFIGURATION',
);
export const updateConciergeServicesConfiguration = createAction(
  'UPDATE/UPDATE_CONCIERGE_SERVICES_CONFIGURATION',
);
export const updateConciergeSelectedTab = createAction('UPDATE/UPDATE_CONCIERGE_SELECTED_TAB');
