import { forwardRef, Fragment, Ref, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { CalendarOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';

import {
  FORM_QUESTION_TYPE,
  PDF_FIELD_TYPE,
  SIGNATURE_MAPPING,
  SignFormFields,
  whiteColor,
} from 'app-constants';
import { getPDFFieldHexColor } from 'utils';
import {
  FormDocumentAnswersType,
  FormDocumentQuestionsType,
  PDFViewerMode,
  ListBoxType,
  FieldInfoType,
  AddFormCommentType,
} from 'types';
import { PDFSign, PDFSignResponse } from '..';
import { DatePicker, Popover, Tooltip } from 'components-antd';
import { getRequestFormProcessSelector } from 'store/selectors/requestFormProcess';
import { SmartFormIcons, SmartFormIconVariants } from 'components/SmartForm';
import {
  getSignResponse,
  getSignDefaultResult,
  getSignatureRoleIndex,
  handleFillSignature,
  getBackgroundColor,
  getFieldKey,
  handleSignClick,
  getFieldProps,
  getRenderFieldType,
} from './helper';

import styles from './styles.module.scss';
import { FormFieldComment } from 'pages/FormProcess/components';

interface RenderFieldProps {
  page: number;
  questions: FormDocumentQuestionsType;
  responses: FormDocumentAnswersType;
  mode: PDFViewerMode;
  highlightedFields: string[];
  unansweredRequiredQs: number[];
  scale: number;
  showRequiredQs: boolean;
  onFieldClick?: (field) => void;
  onFieldUpdate?: (field) => void;
  onSignUpdate?: (UUUID, result) => void;
  setSignModalFieldsProps: (SignModalProps) => void;
  setActiveIndex?: (index: number) => void;
  signatureFields: number[];
  activeIndex: number;
  isMock?: boolean;
  handleDeferModal?: (uuuid: string[]) => void;
  setFieldInfo?: (fieldInfo: FieldInfoType) => void;
  handleAddFormComment?: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  selectedFieldInfo?: FieldInfoType;
}

export const RenderPageFields = forwardRef(
  (
    {
      page,
      questions = [],
      responses,
      mode,
      highlightedFields,
      unansweredRequiredQs = [],
      scale,
      showRequiredQs,
      onFieldClick,
      onFieldUpdate,
      onSignUpdate,
      setSignModalFieldsProps,
      signatureFields,
      activeIndex,
      setActiveIndex,
      handleDeferModal,
      setFieldInfo,
      handleAddFormComment,
      selectedFieldInfo,
    }: RenderFieldProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { currentUserSignature } = useSelector(getRequestFormProcessSelector);
    const [openMessageTooltip, setOpenMessageTooltip] = useState(false);

    const fields = getRenderFieldType(questions, page, responses, mode);

    if (!fields.length) {
      return <></>;
    }

    useEffect(() => {
      if (!selectedFieldInfo && openMessageTooltip) {
        setOpenMessageTooltip(false);
      }
    }, [selectedFieldInfo]);

    const handleView = (field, width, height, x, y, isHighlightField, i) => {
      return (
        <div
          key={`${field.id}-pdfField-element-${i}`}
          style={{
            fontFamily: field?.fontFamily,
            fontSize: !field?.fontSize ? undefined : field?.fontSize,
            width: width * scale,
            height: height * scale,
            left: x * scale,
            bottom: y * scale,
            border: isHighlightField
              ? `1px solid ${getPDFFieldHexColor(field?.fieldType as any)}`
              : undefined,
          }}
          className={classNames(styles.PDFFieldOverlay, styles.PDFOverlayPosition)}
          onClick={(e) => (onFieldClick ? onFieldClick(field) : null)}
          itemID={`${field.id}-pdfField`}
        />
      );
    };

    const handlePreview = (field, width, height, x, y, i, allowComments = false) => {
      const previewField = (
        <div
          key={`${field.id}-pdfField-element-${i}`}
          style={{
            width: width * scale,
            height: height * scale,
            left: x * scale,
            bottom: y * scale,
          }}
          className={classNames(styles.PDFFieldOverlay, styles.PDFOverlayPosition, {
            [styles.previewResponseWrapper]: !!field.response,
          })}
          itemID={`${field.id}-pdfField`}
        >
          <InputField
            field={field}
            className={styles.inputDisabledPDF}
            disabled
            {...getFieldProps(field, i)}
          />
        </div>
      );

      if (allowComments && setFieldInfo) {
        return (
          <Tooltip
            key={`${field.id}-${i}-pdfField-tooltip`}
            title={HelpMessage(
              questions[field.questionIndex || 0].UUID,
              false,
              {
                fieldId: field.id,
                meta: {
                  questionUUID: questions[field.questionIndex || 0].UUID,
                  coordinates: {
                    x: x + width,
                    y,
                    height,
                  },
                  page,
                },
              },
              handleAddFormComment,
              selectedFieldInfo,
              setFieldInfo,
            )}
            overlayClassName={styles.toolTipContainer}
            trigger="click"
          >
            {previewField}
          </Tooltip>
        );
      } else {
        return previewField;
      }
    };

    const handleEdit = (field, width, height, x, y, i, isUnansweredQuestionRequired) => {
      if (field.questionIndex !== activeIndex) {
        return (
          <div
            key={`${field.id}-pdfField-element-${i}`}
            style={{
              width: width * scale,
              height: height * scale,
              left: x * scale,
              bottom: y * scale,
            }}
            className={classNames(
              styles.PDFFieldOverlay,
              styles.PDFOverlayPosition,
              styles.PDFFieldNotActive,
              {
                [styles.missingRequired]: isUnansweredQuestionRequired,
              },
            )}
            itemID={`${field.id}-pdfField`}
            onClick={() => {
              if (setActiveIndex) setActiveIndex(field.questionIndex);
            }}
          >
            <div
              style={{
                background: getBackgroundColor(questions[field.questionIndex || 0]),
              }}
              className={styles.pdfFieldWrapper}
            />
            <InputField
              field={field}
              className={styles.inputDisabledPDF}
              onFocus={() => {
                if (setActiveIndex) setActiveIndex(field.questionIndex);
              }}
              {...getFieldProps(field, i)}
            />
          </div>
        );
      } else {
        const response = responses[questions[field.questionIndex].UUID]?.Answer;

        return (
          <Tooltip
            key={`${field.id}-${i}-pdfField-tooltip`}
            title={HelpMessage(
              questions[field.questionIndex || 0].UUID,
              !Object.keys(questions[field.questionIndex || 0].JumpLogic || {}).length,
              {
                fieldId: field.id,
                meta: {
                  questionUUID: questions[field.questionIndex || 0].UUID,
                  coordinates: {
                    x: x + width,
                    y,
                    height,
                  },
                  page,
                },
              },
              handleAddFormComment,
              selectedFieldInfo,
              setFieldInfo,
              handleDeferModal,
              questions[field.questionIndex || 0].Type === FORM_QUESTION_TYPE.Date && onFieldUpdate
                ? {
                    dateValue: response ? moment(response, 'MM/DD/YYYY') : undefined,
                    onChangeDateValue: (date) => {
                      onFieldUpdate(date);
                      // the above prop handles the react value
                      // the below function ensures the dummy textbox is updated
                      const dateTextBox = document.getElementById(
                        `field-${field.id}-${i}`,
                      ) as HTMLInputElement;
                      if (dateTextBox?.type) {
                        dateTextBox.value = date;
                      }
                    },
                  }
                : undefined,
              openMessageTooltip,
            )}
            overlayClassName={styles.toolTipContainer}
            trigger="click"
            onOpenChange={(visible) => setOpenMessageTooltip(visible)}
            open={openMessageTooltip}
          >
            <div
              style={{
                width: width * scale,
                height: height * scale,
                left: x * scale,
                bottom: y * scale,
              }}
              ref={ref}
              className={classNames(styles.PDFFieldOverlay, styles.PDFOverlayPosition)}
            >
              <InputField
                isActive={true}
                field={field}
                key={`${field.id}-pdfField-element-${i}`}
                style={{
                  width: width * scale,
                  height: height * scale,
                  left: x * scale,
                  bottom: y * scale,
                }}
                className={classNames(styles.PDFFieldActive, styles.PDFZIndex, {
                  [styles.missingRequired]: isUnansweredQuestionRequired,
                })}
                itemID={`${field.id}-pdfField`}
                name={`${field.id}-pdfField`}
                onChange={(e) => {
                  if (onFieldUpdate) {
                    if (e.target.type === 'radio' && e.target.checked) {
                      const value = JSON.stringify([
                        { fieldId: field.id, selectedOptions: [e.target.value] },
                      ]);
                      onFieldUpdate(value);
                    } else if (e.target.type === 'checkbox') {
                      let defaultAnswer: any = [];
                      try {
                        if (field.response?.Answer) {
                          const result = JSON.parse(field.response.Answer);
                          if (result?.length) {
                            defaultAnswer = result;
                          }
                        }
                      } catch (e) {
                        //
                      }
                      defaultAnswer = defaultAnswer.filter((el) => el !== e.target.value);
                      if (e.target.checked) {
                        if ((questions?.[field.questionIndex]?.Meta as ListBoxType)?.MultiSelect) {
                          defaultAnswer.push(e.target.value);
                        } else {
                          defaultAnswer = [e.target.value];
                        }
                      }
                      onFieldUpdate(JSON.stringify(defaultAnswer));
                    }
                  }
                }}
                onBlur={(e) => {
                  if (onFieldUpdate && (e.target.type === 'text' || e.target.type === 'textarea')) {
                    if (field.response?.Answer !== e.target.value) {
                      onFieldUpdate(e.target.value);
                    }
                  }
                }}
                {...getFieldProps(field, i)}
              />
            </div>
          </Tooltip>
        );
      }
    };

    const onFillSignature = (index) => {
      if (currentUserSignature && questions[index]?.Fields?.length) {
        const tags = questions[index].Fields.map((field) => field.customTag);
        let missingFieldKey: SignFormFields | undefined;

        if (
          tags.includes(SIGNATURE_MAPPING.FullName) &&
          (!currentUserSignature.FullName || !currentUserSignature.FullNameImage)
        ) {
          missingFieldKey = SignFormFields.Name;
        } else if (
          tags.includes(SIGNATURE_MAPPING.Initials) &&
          (!currentUserSignature.Initials || !currentUserSignature.InitialsImage)
        ) {
          missingFieldKey = SignFormFields.Initials;
        } else if (
          tags.includes(SIGNATURE_MAPPING.Signature) &&
          !(currentUserSignature.Signature || currentUserSignature.FullNameImage)
        ) {
          missingFieldKey = SignFormFields.Signature;
        }

        if (missingFieldKey) {
          handleSignClick(
            questions,
            responses,
            index,
            missingFieldKey,
            setSignModalFieldsProps,
            handleFillSignature(questions, responses, index, undefined, currentUserSignature),
          );
        } else {
          handleFillSignature(questions, responses, index, onSignUpdate, currentUserSignature);
        }
      }
    };

    const handleSign = (field, width, height, x, y, i, disabled?, hideOnDisabled = false) => {
      let defaultResult: undefined | PDFSignResponse = undefined;
      let fieldKey = getFieldKey(field);

      let signResult = getSignResponse(questions, responses, field.questionIndex);
      if (signResult) {
        defaultResult = getSignDefaultResult(signResult, field);
        if (defaultResult?.fieldKey) {
          fieldKey = defaultResult.fieldKey;
        }
      }

      return (
        <Tooltip
          key={`${field.id}-${i}-pdfField-tooltip`}
          title={HelpMessage(
            questions[field.questionIndex || 0].UUID,
            false,
            {
              fieldId: field.id,
              meta: {
                questionUUID: questions[field.questionIndex || 0].UUID,
                coordinates: {
                  x: x + width,
                  y,
                  height,
                },
                page,
              },
            },
            handleAddFormComment,
            selectedFieldInfo,
            setFieldInfo,
          )}
          overlayClassName={styles.toolTipContainer}
          trigger="click"
        >
          <PDFSign
            key={`${field.id}-pdfField-element-${i}`}
            style={{
              width: width * scale,
              height: height * scale,
              left: x * scale,
              bottom: y * scale,
              ...(defaultResult?.data &&
              (fieldKey === SignFormFields.Name || fieldKey === SignFormFields.Date)
                ? {
                    fontFamily: field?.fontFamily,
                    fontSize: !field?.fontSize ? undefined : `${field?.fontSize * 1.512}px`,
                  }
                : {}),
            }}
            className={classNames(styles.PDFFieldOverlay, styles.PDFOverlayPosition)}
            itemID={`${field.id}-pdfField`}
            questionIndex={field.questionIndex}
            onFieldClick={
              questions[field.questionIndex || 0].disabled || disabled
                ? undefined
                : (questionIndex) =>
                    handleSignClick(
                      questions,
                      responses,
                      questionIndex,
                      fieldKey,
                      setSignModalFieldsProps,
                      currentUserSignature,
                    )
            }
            defaultResponse={
              questions[field.questionIndex || 0].disabled ? undefined : defaultResult
            }
            signatureColorIndex={getSignatureRoleIndex(
              signatureFields,
              questions[field.questionIndex || 0]?.FormRole?.[0] || 0,
            )}
            disabled={disabled || questions[field.questionIndex || 0].disabled}
            onFillSignature={onFillSignature}
            hideOnDisabled={hideOnDisabled}
            customTag={field.customTag}
          />
        </Tooltip>
      );
    };

    return (
      <Fragment key={`renderFields-${page}`}>
        {fields.map((field) => {
          if (!field) {
            return <></>;
          }
          const widgets = field.widgets;

          const fieldElements: any = [];

          for (let i = 0; i < widgets.length; i++) {
            const widget = widgets[i];
            const { height, width, x, y, pageNumber } = widget;
            if (pageNumber !== page) {
              break;
            }

            const isHighlightField = highlightedFields.includes(field.id);

            const isUnansweredQuestionRequired =
              unansweredRequiredQs?.includes(questions[field.questionIndex].Id) && showRequiredQs;

            switch (mode) {
              case 'View':
                fieldElements.push(handleView(field, width, height, x, y, isHighlightField, i));
                break;
              case 'Preview':
                if (field.questionType === FORM_QUESTION_TYPE.Signature) {
                  fieldElements.push(handleSign(field, width, height, x, y, i, true));
                } else {
                  fieldElements.push(handlePreview(field, width, height, x, y, i, true));
                }
                break;
              case 'StrikeThrough':
                if (field.questionType === FORM_QUESTION_TYPE.Signature) {
                  fieldElements.push(handleSign(field, width, height, x, y, i, true));
                } else {
                  fieldElements.push(handlePreview(field, width, height, x, y, i));
                }
                break;
              case 'Edit':
                fieldElements.push(
                  handleEdit(field, width, height, x, y, i, isUnansweredQuestionRequired),
                );
                break;
              case 'Sign':
                if (field.questionType === FORM_QUESTION_TYPE.Signature) {
                  fieldElements.push(handleSign(field, width, height, x, y, i, false, true));
                } else {
                  fieldElements.push(handlePreview(field, width, height, x, y, i, true));
                }
                break;
              default:
                break;
            }
          }

          return <Fragment key={`render-field-elements-${field.id}`}>{fieldElements}</Fragment>;
        })}
      </Fragment>
    );
  },
);

export const HelpMessage = (
  uuid: string,
  showDefer = true,
  fieldInfo: FieldInfoType,
  handleAddFormComment?: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void,
  selectedFieldInfo?: FieldInfoType,
  setFieldInfo?: (fieldInfo: FieldInfoType) => void,
  handleDeferModal?: (uuuid: string[]) => void,
  date?: {
    dateValue?: Moment;
    onChangeDateValue: (newDate: string) => void;
  },
  openCommentTooltip?: boolean,
) => {
  return (
    <div className={styles.helpMessageContainer}>
      {setFieldInfo && handleAddFormComment && (
        <Popover
          key={'pdf-Thread'}
          destroyTooltipOnHide
          placement="bottom"
          overlayClassName={styles.popoverContent}
          zIndex={9999}
          content={
            <FormFieldComment fieldInfo={fieldInfo} handleAddFormComment={handleAddFormComment} />
          }
          trigger={'click'}
          open={selectedFieldInfo?.fieldId === fieldInfo.fieldId && openCommentTooltip}
        >
          <div className={styles.helpOption} onClick={() => setFieldInfo(fieldInfo)}>
            <SmartFormIcons variant={SmartFormIconVariants.CommentWhite} /> Comment
          </div>
        </Popover>
      )}
      {/* {handleDeferModal && showDefer && uuid && (
      <div className={styles.helpOption} onClick={() => handleDeferModal([uuid])}>
        <SmartFormIcons variant={SmartFormIconVariants.Defer} stroke={whiteColor} /> Defer
      </div>
    )} */}

      {date?.onChangeDateValue && (
        <div className={styles.helpOption}>
          <DatePicker
            allowClear={false}
            className={styles.calendar}
            suffixIcon={
              <span className={styles.calendarSuffixIcon}>
                <CalendarOutlined width={18} height={15} /> Calendar
              </span>
            }
            value={date?.dateValue?.isValid?.() ? date?.dateValue : undefined}
            onChange={(newDate) => {
              date.onChangeDateValue(newDate?.isValid() ? newDate.format('MM/DD/YYYY') : '');
            }}
            getPopupContainer={(e) => e}
          />
        </div>
      )}
    </div>
  );
};

export const InputField = ({ field, type, className, isActive, ...props }: any) => {
  const classes = classNames(className, {
    [styles.pdfFieldTextFont]:
      field?.fieldType === PDF_FIELD_TYPE.PDFTextField && !field?.fontFamily,
    [styles.pdfFieldText]: field?.fieldType === PDF_FIELD_TYPE.PDFTextField,
  });

  const commonProps = {
    ...props,
    style: {
      width: '100%',
      height: '100%',
      fontFamily: field?.fontFamily,
      fontSize: !field?.fontSize ? undefined : `${field?.fontSize * 1.512}px`,
    },
    maxLength: field.maxLength,
    className: classes,
    autoFocus: isActive,
    autoComplete: false,
  };

  if (type === 'textarea') {
    return <textarea {...commonProps} style={{ ...commonProps.style, resize: 'none' }} />;
  } else {
    return <input type={type} {...commonProps} />;
  }
};
