import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Name = ({ testid, name, className }) => (
  <p testid={testid} className={classNames(styles.name, className)}>
    {name}
  </p>
);

Name.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  testid: PropTypes.string,
};

Name.defaultProps = {
  className: '',
  testid: undefined,
};

export default Name;
