import React from 'react';

export const NewTabIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_54045_417777)">
        <path
          d="M13.3346 11.6686V14.1686C13.3346 14.6106 13.1546 15.0346 12.8341 15.3471C12.5136 15.6597 12.0789 15.8353 11.6256 15.8353H5.87701C5.42374 15.8353 4.98904 15.6597 4.66853 15.3471C4.34803 15.0346 4.16797 14.6106 4.16797 14.1686V6.66862C4.16797 6.22659 4.34803 5.80267 4.66853 5.49011C4.98904 5.17755 5.42374 5.00195 5.87701 5.00195H7.58605"
          stroke="#515151"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6606 8.07187L15.6606 3.35782L10.9465 3.35782"
          stroke="#515151"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6602 3.35742L10.3569 8.66072"
          stroke="#515151"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_54045_417777">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
