import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useFormik } from 'formik';

import { getLoginLogs, LoginLogsParams } from 'api/admin';
import { loginLogsColumns, LoginLogsDataSource, LOGIN_LOGS_LIMIT } from './configs';
import Header from 'pages/Dashboard/components/Header';
import { Table, TableInfo } from '../components/Table';
import { DEFAULT_PAGE_SIZE } from '../components/Table/configs';
import Input from 'components/Form/Input';
import { Button } from 'components';
import { SearchIcon } from 'components/Icons';

import styles from './styles.module.scss';

export const LoginLogs = () => {
  const [dataSource, setDataSource] = useState<LoginLogsDataSource[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleSubmitSearch = (values) => {
    const body: LoginLogsParams = {
      limit: pageSize,
      page: 1,
      searchString: values.searchString,
    };

    handleFetchLoginLogs(body, true);
  };

  const formik = useFormik({
    initialValues: {
      searchString: '',
    },
    onSubmit: handleSubmitSearch,
  });

  const handleFetchLoginLogs = useCallback(async (cfg: LoginLogsParams, isRefetch: boolean) => {
    setLoading(true);
    try {
      const { data } = await getLoginLogs(cfg);
      const fetchedData = (data?.results || []).map((loginLog) => ({
        key: uuidv4(),
        LoginName: loginLog?.LoginName,
        AttemptStatus: loginLog?.AttemptStatus ? 'Successful' : 'Unsuccessful',
        ErrorReason: loginLog.ErrorReason,
        IPAddress: loginLog?.IPAddress,
        ScreenHeight: loginLog?.ScreenHeight,
        ScreenWidth: loginLog?.ScreenWidth,
        UserAgentPlatform: loginLog?.UserAgentPlatform,
        UserAgentBrowser: loginLog?.UserAgentBrowser,
        UserAgentVersion: loginLog?.UserAgentVersion,
        CreatedDate: loginLog?.CreatedDate
          ? moment(loginLog?.CreatedDate).format('YYYY-MM-DD HH:mm')
          : '',
        Name: loginLog?.Name,
        TeamName: loginLog?.TeamName,
      }));

      setDataSource(fetchedData);
      setTotal(data?.total || 0);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleChangeTable = (tableInfo: TableInfo) => {
    handleFetchLoginLogs(
      {
        ...(tableInfo?.paginationInfo || { limit: LOGIN_LOGS_LIMIT, page: 1 }),
        sorting: tableInfo.sortingInfo,
        ...(tableInfo?.filterInfo || {}),
        searchString: formik.values.searchString,
      },
      !tableInfo.isPagination,
    ).then(() => {
      if (tableInfo?.paginationInfo) {
        setPageSize(tableInfo.paginationInfo.limit);
        setCurrentPage(tableInfo.paginationInfo.page);
      }
    });
  };

  useEffect(() => {
    handleFetchLoginLogs({ limit: LOGIN_LOGS_LIMIT, page: 1 }, false);
  }, []);

  return (
    <div>
      <Header title="Login Logs" />
      <form onSubmit={formik.handleSubmit} className={styles.filtersWrapper}>
        <Input
          id="searchString"
          className={styles.input}
          type="text"
          name="searchString"
          placeholder="Search"
          error={formik.touched.searchString ? formik.errors.searchString : ''}
          value={formik.values.searchString}
          onChange={formik.handleChange}
          disabled={loading}
          variant={Input.LIGHT_FULL}
          data-testid="search_input"
        />
        <Button
          type="submit"
          className={styles.searchButton}
          isPending={loading}
          title={<SearchIcon />}
          testid="search_button"
        />
      </form>
      <Table
        columns={loginLogsColumns}
        dataSource={dataSource}
        loading={loading}
        pagination={{
          total,
          current: currentPage,
          pageSize,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          defaultCurrent: 1,
        }}
        className={styles.tableWrapper}
        onChange={handleChangeTable}
        onChangePage={handleChangePage}
      />
    </div>
  );
};
