import classNames from 'classnames';
import styles from './styles.module.scss';

export const TimeZone = (props) => {
  let { timeZone, className, zoneAreaClass } = props;

  return (
    <div>
      <div className={classNames(styles.timeZoneArea, zoneAreaClass)}>
        <div className={classNames(styles.inner, className)}>
          <span>
            <span className={styles.static}>Time Zone: </span>
            {timeZone || 'Time Zone'}
          </span>
          <span testid="timeZone" className={styles.accent}></span>
        </div>
      </div>
    </div>
  );
};
