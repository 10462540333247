import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input, Checkbox } from 'antd';
import { useDispatch } from 'react-redux';

import { Button, Modal } from 'components-antd';
import { announcementType } from 'settings/constants/showings';
import { sendAnnouncementEffect } from 'store/effects/showings';
import { showSuccessMessage } from 'helpers';

import styles from './styles.module.scss';

const { TextArea } = Input;

const initState = {
  message: undefined,
  externalShow: false,
  all: false,
};

export const MegaPhoneModal = ({ isOpen, onClose, type, showingId, appointmentId }) => {
  const dispatch = useDispatch();
  const [state, setState]: any = useState(initState);
  const [loading, setLoading] = useState(false);

  const onChange = (obj) => {
    setState({ ...state, ...obj });
  };

  const onSubmit = () => {
    const body: any = {
      ShowingId: showingId,
      Message: state.message,
      DisplayOnBooking: state.externalShow,
      Type: type,
      SendTo: [],
    };

    if (type === announcementType.PUBLIC) {
      if (state.all || state.pending) body.SendTo.push('Pending');
      if (state.all || state.upcoming) body.SendTo.push('Upcoming');
      if (state.all || state.past) body.SendTo.push('Past');
    } else {
      body.AppointmentId = appointmentId;
      if (state.all || state.requestor) body.SendTo.push('Requestor');
      if (state.all || state.approver) body.SendTo.push('Approver');
      if (state.all || state.fyi) body.SendTo.push('FYI');
    }

    setLoading(true);
    dispatch(
      sendAnnouncementEffect(body, {}, (err) => {
        if (!err) {
          showSuccessMessage('Message Sent Successfully');
          onClose();
        }
        setLoading(false);
      }),
    );
  };

  const isDisabled = () => {
    return (
      (!state.all &&
        !state.pending &&
        !state.upcoming &&
        !state.past &&
        !state.requestor &&
        !state.approver &&
        !state.fyi &&
        !state.externalShow) ||
      !state.message
    );
  };

  useEffect(() => {
    if (type === announcementType.PUBLIC) {
      setState({
        ...state,
        pending: false,
        upcoming: false,
        past: false,
      });
    } else {
      setState({
        ...state,
        requestor: false,
        approver: false,
        fyi: false,
      });
    }
  }, [type]);

  useEffect(() => {
    if (!isOpen) setState(initState);
  }, [isOpen]);

  const onChangeCheckbox = ({ checked, key }) => {
    let changeRequest = {};
    const isPublicAnnouncement = type === announcementType.PUBLIC;
    if (key === 'all') {
      changeRequest = {
        [key]: checked,

        ...(isPublicAnnouncement
          ? {
              pending: checked,
              upcoming: checked,
              past: checked,
            }
          : {
              requestor: checked,
              approver: checked,
              fyi: checked,
            }),
      };
    } else {
      const keysToCheck = isPublicAnnouncement
        ? ['pending', 'upcoming', 'past']
        : ['requestor', 'approver', 'fyi'];

      const isAllFilled = keysToCheck
        .filter((keyItem) => keyItem !== key)
        .every((keyItem) => state[keyItem]);

      changeRequest = {
        [key]: checked,
        all: checked && isAllFilled,
      };
    }

    setState({ ...state, ...changeRequest });
  };

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      className={styles.megaPhoneModal}
    >
      <h1 className={styles.heading}>Message</h1>
      {type === announcementType.PUBLIC ? (
        <p className={styles.info}>
          This message will be sent to all agents with open offers via their preferred communication
          method(s).
        </p>
      ) : null}
      <TextArea
        rows={5}
        maxLength={500}
        placeholder="Enter message..."
        className={styles.textArea}
        onChange={(e) => onChange({ message: e.target.value })}
        defaultValue={state.message}
      />
      {type === announcementType.PUBLIC ? (
        <div className={styles.additionalInfo}>
          <Checkbox
            checked={state.externalShow}
            defaultChecked={state.externalShow}
            onChange={(e) => onChange({ externalShow: e.target.checked })}
            className={classNames(styles.checkbox, 'mosaikCheckbox')}
          >
            Display on external showings page
          </Checkbox>
          <div className={styles.divider}></div>
        </div>
      ) : null}
      <p className={styles.subHeading}>
        Send to{type === announcementType.PUBLIC ? ' agents who have' : ''}:
      </p>

      <div className={styles.checkboxWrap}>
        <Checkbox
          checked={state.all}
          defaultChecked={state.all}
          onChange={(e) => onChangeCheckbox({ checked: e.target.checked, key: 'all' })}
          className={classNames(styles.checkbox, 'mosaikCheckbox')}
        >
          All
        </Checkbox>
      </div>

      {type === announcementType.APPOINTMENT ? (
        <>
          <div className={styles.checkboxWrap}>
            <Checkbox
              checked={state.requestor}
              defaultChecked={state.requestor}
              onChange={(e) => onChangeCheckbox({ checked: e.target.checked, key: 'requestor' })}
              className={classNames(styles.checkbox, 'mosaikCheckbox')}
            >
              Requestor
            </Checkbox>
          </div>
          <div className={styles.checkboxWrap}>
            <Checkbox
              checked={state.approver}
              defaultChecked={state.approver}
              onChange={(e) => onChangeCheckbox({ checked: e.target.checked, key: 'approver' })}
              className={classNames(styles.checkbox, 'mosaikCheckbox')}
            >
              Approvers
            </Checkbox>
          </div>
          <div className={styles.checkboxWrap}>
            <Checkbox
              checked={state.fyi}
              defaultChecked={state.fyi}
              onChange={(e) => onChangeCheckbox({ checked: e.target.checked, key: 'fyi' })}
              className={classNames(styles.checkbox, styles.lastBox, 'mosaikCheckbox')}
            >
              FYI Recipients
            </Checkbox>
          </div>
        </>
      ) : null}

      {type === announcementType.PUBLIC ? (
        <>
          <div className={styles.checkboxWrap}>
            <Checkbox
              checked={state.pending}
              defaultChecked={state.pending}
              onChange={(e) => onChangeCheckbox({ checked: e.target.checked, key: 'pending' })}
              className={classNames(styles.checkbox, 'mosaikCheckbox')}
            >
              Pending Requests
            </Checkbox>
          </div>
          <div className={styles.checkboxWrap}>
            <Checkbox
              checked={state.upcoming}
              defaultChecked={state.upcoming}
              onChange={(e) => onChangeCheckbox({ checked: e.target.checked, key: 'upcoming' })}
              className={classNames(styles.checkbox, 'mosaikCheckbox')}
            >
              Upcoming Showings
            </Checkbox>
          </div>
          <div className={styles.checkboxWrap}>
            <Checkbox
              checked={state.past}
              defaultChecked={state.past}
              onChange={(e) => onChangeCheckbox({ checked: e.target.checked, key: 'past' })}
              className={classNames(styles.checkbox, styles.lastBox, 'mosaikCheckbox')}
            >
              Past Showings
            </Checkbox>
          </div>
        </>
      ) : null}

      <div className={styles.divider}></div>
      <div className={styles.actionsContainer}>
        <Button
          variant="secondary"
          className={classNames(styles.action, { [styles.disabledAction]: isDisabled() })}
          onClick={onSubmit}
          disabled={isDisabled()}
          loading={loading}
        >
          Send
        </Button>
      </div>
    </Modal>
  );
};

MegaPhoneModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string,
  showingId: PropTypes.number,
  appointmentId: PropTypes.number,
};

MegaPhoneModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  type: announcementType.PUBLIC,
  showingId: null,
  appointmentId: null,
};
