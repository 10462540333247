import React from 'react';
import parentStyles from 'pages/SignupV2/components/PersonalDetails/styles.module.scss';

import styles from './styles.module.scss';
import { FormContainer, FormHeader } from 'pages/OnboardingV2Agent/components';
import classNames from 'classnames';
import { Button } from 'components';
import { Icons } from 'pages/Clarity/Icons';
import { ConfettiBlueIcon } from './icons/ConfettiBlueIcon';
import { ConfettiRedIcon } from './icons/ConfettiRedIcon';
import { useHistory } from 'react-router-dom';
import { link } from 'settings/navigation/link';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDataSelector } from 'store/selectors/user';
import moment from 'moment';
import { userGetDataOnBackgroundEffect } from 'store/effects';

export const Message: React.FC = () => {
  const history = useHistory();
  const user = useSelector(getUserDataSelector);
  const dispatch = useDispatch();

  const trailEndDate =
    user?.Agent?.SubscriptionCustomer?.TrialEndTimestamp || moment().add(1, 'month').toISOString();

  return (
    <FormContainer classname={styles.formContainer}>
      <div className={classNames(parentStyles.formContainer)}>
        <FormHeader title="Here's what happens next:" />
        <div className={styles.messageContainer}>
          <div className={styles.messageWrapper}>
            <div>
              <Icons variant="clients" />
            </div>
            <div className={styles.text}>
              A member of our team will be in touch to coordinate next steps on your MLS
              integration.
            </div>
          </div>
          <div className={styles.messageWrapper}>
            <div>
              <ConfettiBlueIcon />
            </div>
            <div className={styles.text}>
              We will also reach out to provide any additional activation support you may need.
            </div>
          </div>
          <div className={styles.messageWrapper}>
            <div>
              <Icons variant="partners" />
            </div>
            <div className={styles.text}>
              Your first month&apos;s dues are included in your implementation fee. We encourage you
              to use this time to explore Mosaik and leverage our activation support. You will also
              have access to training resources and lifetime optimization support after the
              activation period concludes.
            </div>
          </div>
          <div className={styles.messageWrapper}>
            <div>
              <ConfettiRedIcon />
            </div>
            <div className={styles.text}>
              <div>
                Your monthly fees will begin on{' '}
                <strong>{moment(trailEndDate).format('MMMM DD, YYYY')}</strong>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.title}>Ready to get started?</div>
          <Button
            className={styles.btn}
            title={`Let's go!`}
            onClick={() => {
              dispatch(userGetDataOnBackgroundEffect({}, { showError: false }));

              history.replace(link.toHome());
            }}
          />
        </div>
      </div>
    </FormContainer>
  );
};
