import { Modal } from 'components-antd';
import { Icons } from 'pages/Clarity/Icons';
import { getEmailTemplateByKit } from 'api/kits';
import styles from './styles.module.scss';
import { useState, useEffect } from 'react';
import { Spinner } from 'components';

type PreviewModalPropsType = {
  showModal: Function;
  open: boolean;
  kitName: string;
  kitSlug: string;
};

function PreviewModal(props: PreviewModalPropsType) {
  const [htmlString, setHtmlString] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchStandardKits = async () => {
    try {
      const response = await getEmailTemplateByKit(props.kitSlug);
      if (response.status === 200 && response.data.result) {
        setHtmlString(response.data.result);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (loading) fetchStandardKits();
  }, [loading]);

  return (
    <Modal
      footer={null}
      width={675}
      open={props.open}
      onCancel={() => {
        props.showModal(false);
      }}
      title={
        <span className={styles.headerTitle}>
          <Icons variant="kits" />
          <span className={styles.headerText}>{`${props.kitName} - Email Template`}</span>
        </span>
      }
      className={styles.kitsPreviewModal}
    >
      {loading ? (
        <Spinner loaderClassName={styles.loader} />
      ) : (
        <iframe
          title="InnerHTMLFrame"
          width="600"
          height="400"
          srcDoc={htmlString}
          className={styles.content}
        />
      )}
    </Modal>
  );
}

export default PreviewModal;
