import classNames from 'classnames';
import { Button } from 'components';

import styles from './styles.module.scss';
import React from 'react';

export interface ContinueProps {
  className?: string;
  disabled?: boolean;
  isPending?: boolean;
  variant?: string;
  onClick: any;
  onKeyPress?: () => void;
}

export const Continue = ({
  className,
  disabled,
  isPending,
  variant,
  onClick,
  onKeyPress,
}: ContinueProps) => {
  const renderTitle = () => {
    if (variant === 'skip') {
      return 'Skip';
    } else if (variant === 'skip-hollow') {
      return 'Skip';
    } else if (variant === 'done') {
      return 'Done';
    } else if (!variant) {
      return 'Continue';
    }
  };
  const renderClass = () => {
    if (variant === 'skip') {
      return styles.skipBtn;
    } else if (variant === 'skip-hollow') {
      return styles.skipBtnHollow;
    } else {
      return styles.continueBtn;
    }
  };

  return (
    <Button
      title={renderTitle()}
      onClick={onClick}
      onKeyPress={onKeyPress}
      className={classNames(renderClass(), className)}
      titleClassName={styles.buttonTitle}
      disabled={disabled}
      isPending={isPending}
      testid="continue_button"
    />
  );
};
