import moment from 'moment';
import * as ReactDOM from 'react-dom';

import DaysFromTodayComponent from 'components/Form/DatePicker/plugins/DaysFromToday/DaysFromTodayComponent';

const defaultConfig = {
  className: '',
  value: null,
  placeholder: '',
  onChange: () => {},
  selectedOption: undefined,
};

export function DaysFromToday(config) {
  const cfg = { ...defaultConfig, ...config };
  const { value, onChange: onChangeHandler, selectedOption } = cfg;
  let inputValue = value;

  return (flatpickr) => {
    let wrapper;

    function setDateFromOffset() {
      if (inputValue) {
        const targetDay = moment(selectedOption ? selectedOption.date : new Date()).add(
          inputValue,
          'days',
        );
        flatpickr?.setDate(targetDay.toDate(), true);
      }
    }

    function onChange(val) {
      inputValue = val;
      setDateFromOffset();
      if (typeof onChangeHandler === 'function') {
        onChangeHandler(val);
      }
    }

    return {
      onReady: () => {
        wrapper = document.createElement('div');
        wrapper.classList.add('days-from-today-wrapper');
        flatpickr?.calendarContainer?.prepend(wrapper);
        flatpickr?.calendarContainer?.classList?.add('days-from-today-plugin');
        setDateFromOffset();
        ReactDOM.render(<DaysFromTodayComponent {...cfg} onChange={onChange} />, wrapper);
      },
      onDestroy: () => {
        ReactDOM.unmountComponentAtNode(wrapper);
      },
    };
  };
}
