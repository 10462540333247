import { Fragment, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Row, Col, Button } from 'components-antd';
import { SplitTypes } from '../..';
import { Card, CardTypes, NameAutocomplete, Separator } from '..';
import { useParams } from 'react-router-dom';
import { addFinancialFee, getFeeNames } from 'api/financials';
import { Select } from 'components';
import { FeeItem } from './components';
import CheckboxComponent from 'components/Form/Checkbox';
import { CommissionField } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/FinancingDetails/components';
import { Icons } from 'pages/Workshop/Icons';

import styles from './styles.module.scss';

export interface FeeData {
  FeeTitle: string;
  FeeValue: number;
  FeePercentage: number;
  IsFeeRestricted: boolean;
  SplitTitle: string;
  AddToAccountId: number;
  Id: number;
  SplitId: number;
  isPercentage: boolean;
  IsFeeTypePercentage: boolean;
  FeesSplit: any;
  SplitType: object;
}

export const Fees = ({
  financials,
  fetchFinancials,
  splitList,
  splitTabType,
  representingRoles,
  isTransactionAdminOrOwner,
  nets,
}) => {
  const [editFieldKey, setEditFieldKey] = useState(null);
  const [addTitle, setAddTitle] = useState('');
  const [isAddCase, setIsAddCase] = useState(false);
  const [isRestrictedAdd, setIsRestrictedAdd] = useState(false);
  const [isValidateAdd, setValidateAdd] = useState(false);
  const [addAmountObj, setAddAmountObj] = useState<{
    isAddPercentage: boolean;
    feeAmount: number | null;
  }>({ isAddPercentage: false, feeAmount: null });
  const [splitId, setSplit] = useState(null);
  const [addToAccount, setAddToAccount] = useState<number>(0);
  const [selectedSplit, setSelectedSplit] = useState({
    name: '',
    value: null,
    amount: 0,
    splitId: null,
    splitType: '',
  });
  const [feeListing, setFeeListing] = useState<FeeData[]>([]);
  const [isAddFeeLoading, setAddFeeLoading] = useState(false);
  const [sumValues, setSumValues] = useState({ feeValuesSum: 0, feePercentagesSum: 0 });
  const [splitInfo, setSplitInfo] = useState<any[]>([]);
  const [addToOptions, setAddToOptions] = useState<any[]>([]);
  const isDisableField = (editKey) => editFieldKey !== null && editFieldKey !== editKey;
  const params: { id?: string } = useParams();

  const [autocompleteFeeNames, setAutocompleteFeeNames] = useState<any>(null);

  const fetchAutocompleteFeeNames = async () => {
    let autocompleteFeeNames = await getFeeNames();
    setAutocompleteFeeNames(autocompleteFeeNames?.FeeTitles);
  };

  useEffect(() => {
    fetchAutocompleteFeeNames();
  }, []);

  function setRestrictedDefaultState(item) {
    const isRestrictedCheck = item?.name === 'Team Split' || item?.name === 'Brokerage Split';
    setIsRestrictedAdd(isTransactionAdminOrOwner && isRestrictedCheck);
    setSelectedSplit(item);
  }

  useEffect(() => {
    let transformedData =
      splitList &&
      splitList.map((item) => ({
        name: item?.SplitType?.SplitTitle,
        value: item?.Id,
        amount: item?.SplitValue,
        splitId: item?.SplitTypeId,
        splitType: item?.SplitType?.SplitType,
      }));
    transformedData && transformedData.push({ name: 'GCI', value: 0 });
    setSplitInfo(transformedData);
    setSplit(transformedData && transformedData[0].value);
    setRestrictedDefaultState(transformedData?.[0]);
  }, [splitList]);

  useEffect(() => {
    if (financials && financials?.TransactionFee) {
      const data = financials?.TransactionFee.filter(
        (item) =>
          (item.FeesSplit && item.FeesSplit.SplitSide === splitTabType) ||
          (!item.FeesSplit && item.SplitSide === splitTabType),
      );
      setFeeListing(data);
    }
  }, [financials, splitTabType]);

  useEffect(() => {
    const { totalFeeValue, totalFeePercentage } = feeListing.reduce(
      (acc, item) => {
        acc.totalFeeValue += item.FeeValue || 0;
        acc.totalFeePercentage += item.FeePercentage || 0;
        return acc;
      },
      { totalFeeValue: 0, totalFeePercentage: 0 },
    );
    setSumValues({ feeValuesSum: totalFeeValue, feePercentagesSum: totalFeePercentage });
  }, [feeListing]);

  useEffect(() => {
    setAddToOptions([
      { name: 'Outbound', value: 0 },
      ...nets.map((item) => ({ name: item?.netTitle, value: item?.id })),
    ]);
  }, [nets]);

  const onAdd = () => {
    setIsAddCase(true);
  };

  const onCancelAdd = () => {
    setIsAddCase(false);
    setValidateAdd(false);
  };

  const handleSelect = (e, selectedOption) => {
    setRestrictedDefaultState(selectedOption);
    setSplit(selectedOption.value);
  };

  const handleAddToAccount = (e, selectedOption) => {
    setAddToAccount(selectedOption?.value);
  };

  const getAddFeeDisable = () => {
    if (
      addAmountObj?.feeAmount === null ||
      addAmountObj?.feeAmount === 0 ||
      addTitle === '' ||
      splitId === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onAddFee = async () => {
    try {
      setAddFeeLoading(true);
      let _payload: any = {
        FeeTitle: addTitle,
        FeeValue: addAmountObj?.feeAmount,
        IsFeeTypePercentage: addAmountObj?.isAddPercentage,
        IsFeeRestricted: isRestrictedAdd,
        ...(addToAccount !== 0 ? { AddToAccountId: addToAccount } : {}),
      };

      if (splitId !== 0) {
        if (selectedSplit?.splitId) {
          _payload.SplitId = splitId;
        } else {
          _payload.SplitSide = splitTabType;
          _payload.SplitType = selectedSplit?.splitType; // sending split type when split type id is not available.
        }
      } else {
        _payload.SplitSide = splitTabType; // FOR GCI, we send SplitSide = "Listing/Purchase"
      }

      await addFinancialFee({
        transactionId: params?.id,
        data: _payload,
      });
      fetchFinancials();
    } catch (err) {
      setAddFeeLoading(false);
    } finally {
      setAddFeeLoading(false);
      setIsAddCase(false);
    }
  };

  const isPriceWithinRange = (compareprice, sum, _updatedObject) => {
    let calculatedValue = 0;
    let _sum = 0;
    if (_updatedObject?.isAddPercentage) {
      let _data = _updatedObject?.feeAmount / 100;

      calculatedValue = (_updatedObject?.feeAmount / 100) * compareprice;
    } else {
      calculatedValue = _updatedObject?.feeAmount;
    }

    _sum = +calculatedValue + sum;

    if (_sum === compareprice || _sum < compareprice) {
      setValidateAdd(false);
    } else {
      setValidateAdd(true);
    }
  };

  const transformedSplitInfo = useMemo(() => {
    if (isTransactionAdminOrOwner) return splitInfo;
    return splitInfo?.filter(
      (item) => item?.splitType !== SplitTypes.BROKERAGE && item?.splitType !== SplitTypes.TEAM,
    );
  }, [splitInfo]);

  const getAddSectionFields = () => {
    return (
      <div>
        <Row gutter={12}>
          <Col lg={12}>
            <NameAutocomplete
              label="Fee"
              allNames={autocompleteFeeNames?.map((item, idx) => ({ label: item, value: idx }))}
              value={addTitle}
              onChange={(value) => setAddTitle(value)}
              onSelect={(value) => setAddTitle(value)}
            />
          </Col>
          <Col lg={12}>
            <CommissionField
              label="Amount"
              value={''}
              update={(object) => {
                const updatedObject = {
                  isAddPercentage: object?.IsFeeTypePercentage,
                  feeAmount: object?.Value === '' || null ? null : parseFloat(object?.Value),
                };
                let _selectedSplitData =
                  splitInfo &&
                  splitInfo.filter((data) => {
                    return data?.value === splitId;
                  });
                let _selectedFeeListingData =
                  feeListing &&
                  feeListing.filter((_data) => {
                    return _data?.SplitId === splitId;
                  });

                let sum = _selectedFeeListingData.reduce((acc, obj) => acc + obj?.FeeValue, 0);
                if (splitId !== 0) {
                  isPriceWithinRange(
                    _selectedSplitData && _selectedSplitData[0].amount,
                    sum,
                    updatedObject,
                  );
                }

                setAddAmountObj(updatedObject);
              }}
              IsFeeTypePercentage={addAmountObj?.isAddPercentage}
            />
            {/* {isValidateAdd && (
              <ErrorMessage
                message={'Fee amount can never be greater than its relevant split price'}
              />
            )} */}
          </Col>
          <Col lg={12} className={styles.marginTop}>
            <p className={styles.applyLabel}>{'Deduct From'}</p>
            <Select
              options={transformedSplitInfo}
              onSelect={handleSelect}
              isArrowIcon={true}
              variant={Select.LIGHT_ROUND}
              value={splitId}
              className={{
                input: styles.inputHeight,
              }}
            />
          </Col>
          <Col lg={12} className={styles.marginTop}>
            <p className={styles.applyLabel}>{'Add To'}</p>
            <Select
              options={addToOptions}
              onSelect={handleAddToAccount}
              isArrowIcon={true}
              variant={Select.LIGHT_ROUND}
              className={{
                input: styles.inputHeight,
              }}
              value={addToAccount}
            />
          </Col>
          <Col lg={12}>
            <div className={styles.buttonsContainer}>
              <Button
                variant="secondary"
                size="large"
                onClick={onAddFee}
                loading={isAddFeeLoading}
                disabled={getAddFeeDisable()}
              >
                Add
              </Button>
              <Button variant="hollow-bordered" size="large" onClick={onCancelAdd}>
                Cancel
              </Button>
            </div>
          </Col>
          {isTransactionAdminOrOwner && (
            <Col lg={12} className={styles.checkboxCont}>
              <CheckboxComponent
                id="restricted-add"
                label="Restricted"
                checked={isRestrictedAdd}
                onChange={(e, v, checked) => setIsRestrictedAdd(checked)}
                direction={CheckboxComponent.DIRECTION_RIGHT}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  };

  return (
    <Card variant={CardTypes.LIGHT_GRAY}>
      <div className={classNames(styles.feesContainer, { [styles.disabledSection]: isAddCase })}>
        <div className={styles.headerCon}>
          <p className={styles.title}>Fees</p>
          <div className={styles.addFeeCon} onClick={onAdd}>
            <Icons variant={Icons.PLUS} />
            <p className={styles.addFee}>Add Fee</p>
          </div>
        </div>
        <div className={styles.content}>
          {feeListing &&
            feeListing.map((item, index) => (
              <Fragment key={item?.Id}>
                <FeeItem
                  label={item?.FeeTitle}
                  amount={item?.FeeValue}
                  percentage={item?.FeePercentage}
                  isPercentage={item?.IsFeeTypePercentage}
                  isRestricted={item?.IsFeeRestricted}
                  splitType={item?.FeesSplit?.SplitType?.SplitTitle || 'GCI'}
                  splitId={item?.SplitId || 0} // 0 is the ID for GCI
                  addToAccount={item?.AddToAccountId}
                  fieldKey={item?.FeeTitle}
                  feeId={item?.Id}
                  setEditField={setEditFieldKey}
                  isDisableField={isDisableField}
                  splitInfo={transformedSplitInfo}
                  fetchFinancials={fetchFinancials}
                  feeArray={feeListing}
                  splitTabType={splitTabType}
                  representingRoles={representingRoles}
                  isTransactionAdminOrOwner={isTransactionAdminOrOwner}
                  autocompleteFeeNames={autocompleteFeeNames}
                  addToOptions={addToOptions}
                />
                {index < feeListing?.length - 1 && <Separator />}
              </Fragment>
            ))}
        </div>
        {!isAddCase && (
          <div className={styles.footer}>
            <div className={styles.fadeContainer} />
            <Separator />
            <FeeItem
              label="Total Fees"
              amount={sumValues?.feeValuesSum}
              percentage={sumValues?.feePercentagesSum || null}
              labelClassName={styles.bold}
              notEditable
              fieldKey="totalFee"
              isDisableField={isDisableField}
              hidePriceHighlighter
            />
          </div>
        )}
      </div>
      {isAddCase && <div className={styles.addContainer}>{getAddSectionFields()}</div>}
    </Card>
  );
};
