import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map } from 'lodash-es';

import styles from './styles.module.scss';

const TitleValueBlock = ({ title, value, className }) => (
  <div testid="block_item" className={classNames(styles.wrapper, className)}>
    <div testid="block_title" className={styles.title}>
      {title}
    </div>
    {Array.isArray(value) && value.length > 1 ? (
      <ul testid="block_value" className={styles.items}>
        {map(value, (val) => val && val.length > 0 && <li key={val}>{val}</li>)}
      </ul>
    ) : (
      <div testid="block_value" className={styles.value}>
        {Array.isArray(value) ? value[0] : value}
      </div>
    )}
  </div>
);

TitleValueBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

TitleValueBlock.defaultProps = {
  className: '',
};

export default TitleValueBlock;
