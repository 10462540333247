// showing details
export const GET_SHOWING_DETAILS = (Id: number) => `/showing-appointment/showing/${Id}`;

export const GET_SHOWING_AVAILABILITY = (Id: number) =>
  `/showing-appointment/showing-availability/${Id}`;

// showing appointments
export const GET_ALL_SHOWING_APPOINTMENTS = '/showing-appointment/';

export const GET_SHOWING_APPOINTMENT_BY_ID = (Id: number) => `/showing-appointment/${Id}`;

export const SAVE_SHOWING_APPOINTMENT = '/showing-appointment/';

export const UPDATE_SHOWING_APPOINTMENT = (UUID: string) => `/showing-appointment/${UUID}`;

export const SEND_SHOWING_APPOINTMENT_FEEDBACK = (UUID: string) =>
  `/showing-appointment/${UUID}/feedback`;

// showing appointments approval
export const GET_ALL_SHOWING_APPOINTMENT_APPROVAL = '/showing-appointment/approval/list/';

export const GET_SHOWING_APPOINTMENT_APPROVAL_BY_USER_ID = (Id: number) =>
  `/showing-appointment/approval/list/${Id}`;

export const UPDATE_SHOWING_APPOINTMENT_APPROVAL = (Id: number) =>
  `/showing-appointment/approval/${Id}`;

export const GET_SHOWING_APPOINTMENT_APPROVAL_BY_UUID = (UUID: number) =>
  `/showing-appointment/approval/${UUID}`;

export const DELETE_APPOINTMENT_DOCUMENT_BY_ID = (Id: number) =>
  `/showing-appointment/document/${Id}`;

export const SEND_VERIFICATION_LINK_FOR_APPOINTMENT_BY_UUID = (uuid: string) =>
  `/showing-appointment/${uuid}/externalVerification`;
