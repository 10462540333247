import styles from './styles.module.scss';
import Destination from './Destination';

type CommutePropsType = {
  destinations: {
    name: string;
    address: string;
    carTimes: string[];
    transitTimes: string[];
  }[];
  hideFields: boolean;
  countStarredFields: number;
  starredFieldsCountHandler: Function;
};

function Commute(props: CommutePropsType) {
  return (
    <div className={styles.commute}>
      {props.destinations.map((dItem) => (
        <Destination
          destination={dItem}
          hideFields={props.hideFields}
          countStarredFields={props.countStarredFields}
          starredFieldsCountHandler={props.starredFieldsCountHandler}
        />
      ))}
    </div>
  );
}

export default Commute;
