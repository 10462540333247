import { FormCommentThread } from 'types';
import { CommentThread, CommentProps } from './CommentThread';

import styles from './styles.module.scss';

interface FormPanelCommentsProps extends CommentProps {
  threads: FormCommentThread[];
}

export const FormPanelComments = ({
  threads,
  commentUsers,
  notifyOptions,
  permissionOptions,
  permittedUsers,
  setPermittedUsers,
  notifiedUsers,
  setNotifiedUsers,
  isSending,
  setSending,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
  setFormCommentFieldId,
}: FormPanelCommentsProps) => {
  return (
    <div className={styles.panelContent}>
      {threads.length ? (
        threads?.map((thread, i) => (
          <CommentThread
            {...{
              thread,
              index: i,
              commentUsers,
              notifyOptions,
              permissionOptions,
              permittedUsers,
              setPermittedUsers,
              notifiedUsers,
              setNotifiedUsers,
              isSending,
              setSending,
              handleAddFormComment,
              handleEditFormComment,
              handleDeleteFormComment,
              handleToggleResolveThread,
              setFormCommentFieldId,
            }}
          />
        ))
      ) : (
        <div className={styles.noCommentsNote}>No comments found</div>
      )}
    </div>
  );
};
