import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';
import styles from './styles.module.scss';
import { Checkbox } from 'antd';
const MoveFilesItem = (props) => {
  return (
    <div className={styles.moveItemWrapper}>
      <div className={styles.moveItemTitle}>
        <Icon variant={Icon.FOLDER} />
        <h3>
          {props.title} {props.subtitle && <span>{`(${props.subtitle})`}</span>}
        </h3>
      </div>
      <Checkbox
        onChange={props.onChange}
        checked={props.id === props.selectedFolderId}
        onClick={(e) => e.stopPropagation()}
        className="mosaikCheckboxRounded"
      ></Checkbox>
    </div>
  );
};
export default MoveFilesItem;
