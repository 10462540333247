const Permission = ({ className, onClick }) => {
  return (
    <div testid="edit_icon" onClick={onClick} className={className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3334 6.66683C13.3334 8.50778 11.841 10.0002 10 10.0002C8.15907 10.0002 6.66669 8.50778 6.66669 6.66683C6.66669 4.82588 8.15907 3.3335 10 3.3335C11.841 3.3335 13.3334 4.82588 13.3334 6.66683Z"
          stroke="#262626"
          strokeWidth="2"
        />
        <path
          d="M5 16.2502C5.31162 14.6921 6.74429 13.3335 8.33323 13.3335H11.6667C13.2556 13.3335 14.6884 14.6921 15 16.2502"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Permission;
