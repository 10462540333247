import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import moment from 'moment';
import styles from './styles.module.scss';
import Avatar from 'components/Avatar';

interface SubmissionActivityPropsType {
  AvatarUrl?: string | null | undefined;
  data: any;
  Time: Date | null | undefined;
}

const SubmissionActivity = (props: SubmissionActivityPropsType) => {
  const {
    TagLine: { SubmittedByName, OfferCount },
  } = props.data;
  const splitName = SubmittedByName ? SubmittedByName.split(' ') : [];
  const firstName = splitName[0] ? splitName[0] : '';
  const lastName = splitName[1] ? splitName[1] : '';

  const name = `${firstName} ${lastName}`;

  return (
    <div className={styles.submissionActivity}>
      <div className={styles.submissionActivityLog}>
        <Avatar src={props.AvatarUrl} placeholder={convertNameToAvatarPlaceholder(name)} />
        <p>
          <span className={styles.userName}>{name}</span>
          <span className={styles.text}>&nbsp;sent offer</span>
          <br />
          <span className={styles.offerNo}> Initial Offer #{OfferCount}</span>
        </p>
      </div>
      <div className={styles.submissionActivityTime}>
        {moment(props.Time).format('MM/DD')}&nbsp;at&nbsp;{moment(props.Time).format('hh:mm A')}
      </div>
    </div>
  );
};

export default SubmissionActivity;
