import { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getOpenedChatThreadInfo,
  getOpenedPropertyThreadInfo,
} from 'store/selectors/sockets/threads';

import { NoThreads } from 'components';

import PropertyCommentList from '../PropertyComments';
import Attachment from '../Attachment';
import styles from './styles.module.scss';
import Avatars from 'components/Avatars';
import { getMessageAttachmentsDrawerParams } from 'store/selectors/drawers/messageAttachments';
import classNames from 'classnames';
import { Add, Remove } from 'components/Icons';
import {
  getMessageAttachmentsSelector,
  getPropertyCommentsAttachmentsSelector,
} from 'store/selectors/sockets/messages';

const MessageAttachmentDrawerContent = () => {
  const { propertyId } = useSelector(getMessageAttachmentsDrawerParams);
  const { names, avatars, recipientRole, subTitle, BusinessName } = useSelector(
    propertyId ? getOpenedPropertyThreadInfo : getOpenedChatThreadInfo,
  );
  const messageAttachments = useSelector(getMessageAttachmentsSelector);
  const propertyComments = useSelector(getPropertyCommentsAttachmentsSelector);

  const [showMoreDocuments, setShowMoreDocuments] = useState(false);
  const [showMorePropertyComments, setShowMorePropertyComments] = useState(false);

  const isRecipientThirdParty = () => {
    return recipientRole === 'ThirdParty';
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.chatHeaderInfo}>
        <div className={styles.chatHeaderAvatars}>
          <Avatars
            className={styles.avatars}
            avatarWrapperClassName={styles.avatarWrapper}
            avatarClassName={styles.avatar}
            isNotSingleClassName={styles.isNotSingle}
            overflowClassName={styles.overflow}
            avatars={avatars}
          />
        </div>
        <div className={styles.chatHeaderTitles}>
          <div testid="header_title" className={styles.chatHeaderTitle}>
            {isRecipientThirdParty() ? `${names} (${BusinessName})` : names}
          </div>
          {isRecipientThirdParty() && (
            <span className={styles.chatHeaderThirdPartyCategory}>{subTitle}</span>
          )}
        </div>
      </div>

      <div className={styles.title}>
        <div testid="title" className={styles.titleLabel}>
          Attachments
        </div>
      </div>
      {messageAttachments.length ? (
        messageAttachments.map((attachment, index) =>
          showMoreDocuments || index < 3 ? (
            <Attachment key={index} file={attachment} isActions={true} />
          ) : (
            <></>
          ),
        )
      ) : (
        <NoThreads>No Attachments</NoThreads>
      )}
      {messageAttachments.length > 3 && (
        <div
          className={classNames(styles.showMore, 'show-cursor')}
          onClick={() => setShowMoreDocuments(!showMoreDocuments)}
        >
          {!showMoreDocuments ? (
            <Add className={styles.showMoreIcon} color={Add.COLLAPSE} />
          ) : (
            <Remove className={styles.showMoreIcon} color={Remove.COLLAPSE} />
          )}
          <span className={styles.showAllText}>{showMoreDocuments ? 'Show less' : 'Show all'}</span>
        </div>
      )}
      <div className={styles.title}>
        <div testid="title" className={styles.titleLabel}>
          Property Comments
        </div>
      </div>
      {propertyComments.length ? (
        <PropertyCommentList
          propertyComments={
            showMorePropertyComments ? propertyComments : propertyComments.slice(0, 3)
          }
        />
      ) : (
        <NoThreads>No Property Comments</NoThreads>
      )}

      {propertyComments.length > 3 && (
        <div
          className={classNames(styles.showMore, 'show-cursor')}
          onClick={() => setShowMorePropertyComments(!showMorePropertyComments)}
        >
          {!showMorePropertyComments ? (
            <Add className={styles.showMoreIcon} color={Add.COLLAPSE} />
          ) : (
            <Remove className={styles.showMoreIcon} color={Remove.COLLAPSE} />
          )}
          <span className={styles.showAllText}>
            {showMorePropertyComments ? 'Show less' : 'Show all'}
          </span>
        </div>
      )}
    </div>
  );
};

export default MessageAttachmentDrawerContent;
