import { combineReducers } from 'redux';

import changePassword from './changePassword';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import signUpRole from './signUpRole';

export default combineReducers({
  forgotPassword,
  resetPassword,
  signUpRole,
  changePassword,
});
