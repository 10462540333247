import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { link } from 'settings/navigation/link';
import BaseNotification from '../../../BaseNotification';
import PropertyIcon from '../../../PropertyIcon';
import AddressAndPrice from '../../../../../AddressAndPrice';

import styles from './styles.module.scss';
import { NewListingNotificationsTypes } from 'settings/constants/notifications';

const NewClientFavorite = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel, Type } =
    props;
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    PropertyPrice,
    PropertyAddress,
    PropertyId,
    ClientAvatarUrl,
    EditSearchCriteriaLink,
    IsASingleListingMatch,
  } = Payload || {};

  const isNewListingNotification = [
    ...NewListingNotificationsTypes.Agent,
    ...NewListingNotificationsTypes.Client,
  ].includes(Type);

  const getSubtitle = () =>
    isNewListingNotification && (IsASingleListingMatch === false || Ids?.length > 1) ? (
      <></>
    ) : (
      <AddressAndPrice address={PropertyAddress} price={PropertyPrice} />
    );

  const onClickHandler = () => {
    if (PropertyId && (IsASingleListingMatch || IsASingleListingMatch !== false)) {
      dispatch(openNotificationsDrawerEffect({ open: false }));
      history.replace(link.toFeedListingDetails(PropertyId));
    } else {
      window.location.replace(EditSearchCriteriaLink);
    }
  };

  return (
    <div className={classNames(styles.notificationNewClientFavorite, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        subtitle={getSubtitle()}
        date={CreatedDate}
        avatarPlaceholder={<PropertyIcon />}
        onClick={onClickHandler}
        avatarUrl={ClientAvatarUrl}
        groupType={groupType}
        severityLevel={SeverityLevel}
      />
    </div>
  );
};

NewClientFavorite.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    ClientFirstName: PropTypes.string,
    ClientLastName: PropTypes.string,
    PropertyPrice: PropTypes.number,
    PropertyAddress: PropTypes.shape({
      Line1: PropTypes.string,
      City: PropTypes.string,
      State: PropTypes.string,
    }),
    ClientAvatarUrl: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

NewClientFavorite.defaultProps = {
  className: '',
};

export default NewClientFavorite;
