import { handleActions } from 'redux-actions';
import { cloneDeep, get } from 'lodash-es';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import { setTeamStatsAction, setSelectedGraphAction } from 'store/actions/clarity';

const initialData = {
  state: IDLE,
  teamStats: 'Team',
  isGraphSelected: false,
  graphName: '',
  meta: {},
};

export default handleActions(
  {
    [setTeamStatsAction.toString()]: (state, { payload }) => ({
      ...state,
      teamStats: get(payload, 'value', initialData.teamStats) || state.teamStats,
      meta: get(payload, 'meta', initialData.meta),
    }),
    [setSelectedGraphAction.toString()]: (state, { payload }) => ({
      ...state,
      isGraphSelected: get(payload, 'isGraphSelected', initialData.isGraphSelected),
      graphName: get(payload, 'graphName', initialData.graphName) || state.graphName,
    }),
    [appLogoutAction.toString()]: () => cloneDeep(initialData),
  },
  initialData,
);
