import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { cloneDeep } from 'lodash-es';

import {
  openActivityFiltersDrawerAction,
  setActivityFiltersDrawerUsersFieldAction,
  setActivityFiltersDrawerPropertiesFieldAction,
  setActivityFiltersDrawerStartDateFieldAction,
  setActivityFiltersDrawerEndDateFieldAction,
  resetActivityFiltersDrawerFieldsAction,
  sortActivityListByOrderAction,
} from 'store/actions/drawers/activityFilters';
import { appLogoutAction } from 'store/actions/app';

interface ActivityFilterDataType {
  users?: any[];
  properties?: any[];
  startDate?: string;
  endDate?: string;
}
interface ActivityLogsSortTypes {
  order: string;
}
export interface ActivityFilterTypes {
  open?: boolean;
  state?: string;
  data?: ActivityFilterDataType;
  initialFilterData?: ActivityFilterDataType;
  getFilteredWorkshopLogs?: boolean;
  sortBy?: ActivityLogsSortTypes;
  meta?: any;
}
const initialData: ActivityFilterTypes = {
  open: false,
  state: IDLE,
  data: {
    users: [],
    properties: [],
    startDate: undefined,
    endDate: undefined,
  },
  initialFilterData: {
    users: [],
    properties: [],
    startDate: undefined,
    endDate: undefined,
  },
  getFilteredWorkshopLogs: false,
  sortBy: {
    order: 'desc',
  },
  meta: {},
};

export default handleActions(
  {
    [openActivityFiltersDrawerAction as any]: (state, { payload }) => ({
      ...state,
      open: !!payload?.open,
    }),
    [sortActivityListByOrderAction as any]: (state, { payload }) => {
      return {
        ...state,
        sortBy: {
          ...state?.sortBy,
          order: state?.sortBy?.order === 'asc' ? 'desc' : 'asc',
        },
      };
    },
    [setActivityFiltersDrawerUsersFieldAction as any]: (state, { payload }) => ({
      ...state,
      data: { ...state.data, users: payload?.data?.users },
    }),
    [setActivityFiltersDrawerPropertiesFieldAction as any]: (state, { payload }) => ({
      ...state,
      data: { ...state.data, properties: payload?.data?.properties },
    }),
    [setActivityFiltersDrawerStartDateFieldAction as any]: (state, { payload }) => ({
      ...state,
      data: { ...state.data, startDate: payload?.data?.startDate },
    }),
    [setActivityFiltersDrawerEndDateFieldAction as any]: (state, { payload }) => ({
      ...state,
      data: { ...state.data, endDate: payload?.data?.endDate },
    }),
    [resetActivityFiltersDrawerFieldsAction as any]: (state, { payload }) => {
      return {
        ...state,
        data: initialData.data,
      };
    },
    [appLogoutAction as any]: () => cloneDeep(initialData),
  },
  initialData,
);
