import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components';
import styles from './styles.module.scss';

const EditActions = (props) => {
  const { className, onSave, onCancel, isPending } = props;

  return (
    <div className={classNames(styles.editActions, className)}>
      <Button
        isPending={isPending}
        className={classNames(styles.btn)}
        loaderClassName={styles.loader}
        onClick={onSave}
        title="Save"
      />
      <Button className={classNames(styles.btn, styles.cancel)} onClick={onCancel} title="Cancel" />
    </div>
  );
};

EditActions.propTypes = {
  className: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
};

EditActions.defaultProps = {
  className: '',
  isPending: false,
};

export default EditActions;
