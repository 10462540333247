/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ItemsWrapper, ItemWrapper, Placeholder } from '../components';
import Amenity from './Amenity';

import styles from './styles.module.scss';

const Amenities = (props) => {
  const { className, amenities, isPending } = props;

  if (!amenities) return null;

  return (
    <ItemsWrapper className={classNames(styles.amenities, className)} innerClassName={styles.inner}>
      {amenities.map((amenityList, index) => (
        <ItemWrapper testid="amenities" isPending={isPending} key={index}>
          {amenityList ? (
            amenityList.map((amenity, i) => <Amenity key={i} value={amenity} />)
          ) : (
            <Placeholder />
          )}
        </ItemWrapper>
      ))}
    </ItemsWrapper>
  );
};

Amenities.propTypes = {
  className: PropTypes.string,
  amenities: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  isPending: PropTypes.bool,
};

Amenities.defaultProps = {
  className: '',
  isPending: false,
};

export default Amenities;
