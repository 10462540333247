import { routes } from 'settings/navigation/routes';
import { Settings } from 'pages';
import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';

export default [
  {
    path: routes.settings,
    component: Settings,
    exact: true,
    roles: [CLIENT, AGENT, THIRD_PARTY],
  },
  {
    path: routes.settingsPartner,
    component: Settings,
    exact: true,
    roles: [THIRD_PARTY],
  },
  {
    path: routes.settingsPassword,
    component: Settings,
    exact: true,
    roles: [CLIENT, AGENT, THIRD_PARTY],
  },
  {
    path: routes.settingsSubscription,
    component: Settings,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.settingsSubscriptionChange,
    component: Settings,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.settingsNotifications,
    component: Settings,
    exact: true,
    roles: [CLIENT, AGENT, THIRD_PARTY],
  },
  {
    path: routes.settingsExport,
    component: Settings,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.settingsBrokerage,
    component: Settings,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.settingsThirdPartyIntegrations,
    component: Settings,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.settingsCoBuyer,
    component: Settings,
    exact: true,
    roles: [CLIENT],
  },
  {
    path: routes.settingsPreApproval,
    component: Settings,
    exact: true,
    roles: [CLIENT],
  },
];
