import { Button } from 'components-antd';
import { Plus } from 'components/Icons';
import KitsTable from './components/KitsTable';
import styles from './styles.module.scss';
import { useState, useEffect } from 'react';
import { PageNotFound, Spinner } from 'components';
import { routes } from 'settings/navigation/routes';
import { getAllKits, getKitsCategories, getKitsFrequencies, getKitsPropertyTypes } from 'api/kits';
import { getAllTeams } from 'api/teamList';
import { getAllSoloAgents } from 'api/admin/users';
import PublishModal from './components/PublishModal';
import CreationModal from './components/CreationModal';
import EditModal from './components/EditModal';

function Kits() {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [kits, setKits] = useState<any[]>([]);
  const [kitsCategories, setKitsCategories] = useState<any[]>([]);
  const [kitsFrequencies, setKitsFrequencies] = useState<any[]>([]);
  const [kitsPropertyTypes, setKitsPropertyTypes] = useState<any[]>([]);
  const [agents, setAgent] = useState<any[]>([]);
  const [teams, setTeams] = useState<any[]>([]);
  const [targetKit, setTargetKit] = useState<any>(null);
  const [showPermissionsModal, setShowPermissionsModal] = useState<boolean>(false);
  const [showCreationModal, setShowCreationModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const fetchKitsData = async () => {
    try {
      const kitsResponse = await getAllKits();
      if (kitsResponse.status === 200) {
        setKits(kitsResponse?.data?.result?.length ? kitsResponse?.data?.result : []);
      } else {
        setError(true);
      }
      const kitsCategoriesResponse = await getKitsCategories();
      if (kitsCategoriesResponse.status === 200) {
        setKitsCategories(
          kitsCategoriesResponse?.data?.result?.length ? kitsCategoriesResponse?.data?.result : [],
        );
      } else {
        setError(true);
      }
      const kitsFrequenciesResponse = await getKitsFrequencies();
      if (kitsFrequenciesResponse.status === 200) {
        setKitsFrequencies(
          kitsFrequenciesResponse?.data?.result?.length
            ? kitsFrequenciesResponse?.data?.result
            : [],
        );
      } else {
        setError(true);
      }
      const kitsPropertyTypesResponse = await getKitsPropertyTypes();
      if (kitsPropertyTypesResponse.status === 200) {
        setKitsPropertyTypes(
          kitsPropertyTypesResponse?.data?.result?.length
            ? kitsPropertyTypesResponse?.data?.result
            : [],
        );
      } else {
        setError(true);
      }
      const agentsResponse = await getAllSoloAgents();
      if (agentsResponse.status === 200) {
        setAgent(agentsResponse?.data?.result?.length ? agentsResponse?.data?.result : []);
      } else {
        setError(true);
      }
      const teamsResponse = await getAllTeams();
      if (teamsResponse.status === 200) {
        setTeams(teamsResponse?.data?.result?.length ? teamsResponse?.data?.result : []);
      } else {
        setError(true);
      }
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  const setPermissionsHandler = async () => {
    const kitsResponse = await getAllKits();
    if (kitsResponse.status === 200 && kitsResponse?.data?.result?.length) {
      setKits(kitsResponse?.data?.result);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (loading) fetchKitsData();
  }, [loading]);

  const permitClickHandler = (id: number) => {
    if (id && kits?.length) {
      const kit = kits.find((obj) => obj.Id === id);
      if (kit) {
        setTargetKit(kit);
        setShowPermissionsModal(true);
      }
    }
  };

  const editKitClickHandler = (id: number) => {
    if (id && kits?.length) {
      const kit = kits.find((obj) => obj.Id === id);
      if (kit) {
        setTargetKit(kit);
        setShowEditModal(true);
      }
    }
  };

  const updateNotesHandler = (kitId: number, notes: string) => {
    if (kitId && notes) {
      const kitsCopy = [...kits];
      const targetKitIndex = kitsCopy.findIndex((kit) => kit.Id === kitId);
      if (targetKitIndex !== -1) {
        const targetKit = { ...kitsCopy[targetKitIndex], Notes: notes };
        kitsCopy[targetKitIndex] = targetKit;
        setKits(kitsCopy);
      }
    }
    setShowEditModal(false);
  };

  const newKitHandler = (newKit) => {
    if (newKit) {
      const kitsCopy = [...kits, newKit];
      setKits(kitsCopy);
    }
    setShowCreationModal(false);
  };

  if (loading) {
    return <Spinner />;
  } else if (error) {
    return (
      <div>
        <PageNotFound backUrl={routes.index} />
      </div>
    );
  } else
    return (
      <div className={styles.adminKits}>
        {showPermissionsModal ? (
          <PublishModal
            showModal={setShowPermissionsModal}
            open={showPermissionsModal}
            agents={agents}
            teams={teams}
            targetKit={targetKit}
            setPermissionsHandler={setPermissionsHandler}
            updateNotesHandler={updateNotesHandler}
          />
        ) : (
          <></>
        )}
        {showCreationModal ? (
          <CreationModal
            showModal={setShowCreationModal}
            open={showCreationModal}
            categories={kitsCategories}
            frequencies={kitsFrequencies}
            propertyTypes={kitsPropertyTypes}
            newKitHandler={newKitHandler}
          />
        ) : (
          <></>
        )}
        {showEditModal ? (
          <EditModal
            showModal={setShowEditModal}
            open={showEditModal}
            targetKit={targetKit}
            updateNotesHandler={updateNotesHandler}
          />
        ) : (
          <></>
        )}
        <div className={styles.adminKitsHeader}>
          <div className={styles.empty}></div>
          <h1 className={styles.heading}>KITs</h1>
          <Button className={styles.newKit} onClick={() => setShowCreationModal(true)}>
            <Plus className={styles.iconAdd} />
            New
          </Button>
        </div>
        <div className={styles.adminKitsContent}>
          <KitsTable
            kitsData={kits}
            categoriesData={kitsCategories}
            frequenciesData={kitsFrequencies}
            propertyTypesData={kitsPropertyTypes}
            permitClickHandler={permitClickHandler}
            editKitClickHandler={editKitClickHandler}
          />
        </div>
      </div>
    );
}

export default Kits;
