import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { appOpenModalEffect } from 'store/effects/app';
import { getModalSelector } from 'store/selectors/app';
import { Modal, Answers } from 'components';
import NextButton from 'pages/OnBoardingWizard/components/NextButton';
import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { prefsIds } from 'settings/constants/preferences';
import { PropertyType } from 'components';

import styles from './styles.module.scss';

const ANSWER_CASES = [
  { id: prefsIds.openFloorPlan, label: 'Open Floor Plan', value: 'OpenFloorPlan' },
  { id: prefsIds.laundryRoom, label: 'Laundry Room', value: 'LaundryRoom' },
  { id: prefsIds.homeOffice, label: 'Home Office', value: 'HomeOffice' },
  { id: prefsIds.basementRoom, label: 'Basement', value: 'BasementRoom' },
  // TODO: Add BE support first
  // { id: prefsIds.firstFloorPrimarySuite, label: 'First Floor Primary Suite', value: 'FirstFloorPrimarySuite' },
  // { id: prefsIds.guestHouse, label: 'Guest House (Accessory Dwelling Unit)', value: 'GuestHouse' },
];

const LayoutModal = ({ className, onNext, value }) => {
  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(value || []);
  const [answerCases, setAnswerCases] = useState(ANSWER_CASES);
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const basementHomeType =
    defaultClientSearch?.HomeType?.length &&
    defaultClientSearch.HomeType.some((homeType) =>
      [PropertyType.HOUSE, PropertyType.TOWN_HOME].includes(homeType),
    );

  useEffect(() => {
    if (!basementHomeType) {
      setAnswerCases(ANSWER_CASES.filter((option) => option.id !== prefsIds.basementRoom));
    }
  }, [basementHomeType]);

  useEffect(() => setSelected(value), [value]);

  if (modal.id !== LayoutModal.id) {
    return null;
  }

  const onClose = () => {
    onNext(selected);
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  const onNextHandler = () => {
    onClose();
  };

  return (
    <Modal
      className={classNames(className)}
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      closeClassName={styles.close}
      innerHolderClassName={styles.innerHolder}
    >
      <h2 className={styles.title}>Which layout elements do you prefer?</h2>
      <Answers
        multiple
        unselectable
        className={styles.answersWrapper}
        caseClassName={styles.option}
        cases={answerCases}
        onChange={(val) => setSelected(val)}
        value={selected}
        testid="interior_answers"
        variant={Answers.CHECKBOX}
      />
      <NextButton onClick={onNextHandler} className={styles.next} />
    </Modal>
  );
};

LayoutModal.id = 'interiorLayoutModal';

LayoutModal.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  value: PropTypes.array,
};

LayoutModal.defaultProps = {
  className: '',
  onNext: () => {},
  value: [],
};

export default LayoutModal;
