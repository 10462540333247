import PropTypes from 'prop-types';

export const Icons = ({ testid, className, variant, onClick }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.BOOKMARK:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 7V19L12 16L17 19V7C17 5.89543 16.1046 5 15 5H9C7.89543 5 7 5.89543 7 7Z"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.RETURN_ARROW:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M16 10H4M4 10L9 5M4 10L9 15"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.CLOSE:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path d="M9 23L23 9" stroke="#747475" strokeWidth="2" strokeLinecap="round" />
            <path d="M23 23L9 9" stroke="#747475" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className} style={{ display: 'flex' }}>
      {renderIcon()}
    </div>
  );
};

Icons.BOOKMARK = 'Bookmark';
Icons.RETURN_ARROW = 'RETURN_ARROW';
Icons.CLOSE = 'Close';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};
