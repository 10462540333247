import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const PasswordIcon = memo(({ onClick, className, mode }) =>
  useMemo(
    () => (
      <div className={className} onClick={onClick}>
        {mode === PasswordIcon.ON ? (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26 16C26 16.5 23 22 16 22C9 22 6 16.5 6 16C6 15.5 9 10 16 10C23 10 26 15.5 26 16Z"
              stroke="#525252"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="16" cy="16" r="3" stroke="#525252" strokeWidth="1.5" />
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.411 12.6279C25.1843 14.119 26 15.7393 26 16C26 16.5 23 22 16 22C15.2929 22 14.6265 21.9439 14 21.8424M21 11.0813C19.6379 10.4395 17.9788 10 16 10C9 10 6 15.5 6 16C6 16.3587 7.54364 19.29 11 20.9187"
              stroke="#525252"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 24L24 8"
              stroke="#525252"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 13.7639C17.4692 13.2889 16.7684 13 16 13C14.3431 13 13 14.3431 13 16C13 16.8241 13.3323 17.5705 13.8701 18.1127M18.9585 16.5C18.7478 17.7563 17.7563 18.7478 16.5 18.9585"
              stroke="#525252"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    ),
    [className, mode, onClick],
  ),
);

PasswordIcon.ON = 'on';
PasswordIcon.OFF = 'off';

PasswordIcon.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string,
  onClick: PropTypes.func,
};

PasswordIcon.defaultProps = {
  className: '',
  mode: 'on',
  onClick: () => {},
};

export default PasswordIcon;
