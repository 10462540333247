import AntTabs, { TabsProps } from 'antd/lib/tabs';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const { TabPane } = AntTabs;

export const Tabs = ({ children, className, ...props }: TabsProps) => (
  <AntTabs {...props} className={classNames(styles.commonTabs, className)}>
    {children}
  </AntTabs>
);
