import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { List } from 'antd';
import { Button, Divider, Input } from 'components-antd';
import { allowMlsAccessForAgent, rejectMlsAccessForAgent, renameTeamName } from 'api/admin/users';
import { useDispatch } from 'react-redux';
import { getUserDashboardEffect } from 'store/effects/adminPanel/users';

import styles from './styles.module.scss';
import { adminPanelGetSubscriptionCustomer } from 'api/subscription';
import { SubscriptionCustomerData } from './agentInfo/SubscriptionCustomerData';

enum MlsAccessStatus {
  REQUESTED = 'requested',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

interface AgentInfoProps {
  className?: string;
  userId: number;
  user: {
    Address: string;
    SelectedMlsServices: {
      MlsId: number;
      MlsName: string;
      AccessStatus: MlsAccessStatus;
    }[];
    Team: {
      Id: number;
      Name: string;
      BrokerageId: number;
    };
  };
}

const AgentInfo: React.FC<AgentInfoProps> = (props) => {
  const dispatch = useDispatch();
  const { className, user } = props;

  if (!user) {
    return null;
  }

  const [teamName, setTeamName] = useState(user.Team?.Name);

  const handleAllowMlsAccessClick = async (mlsId: number) => {
    await allowMlsAccessForAgent({
      userId: props.userId,
      mlsId,
    });
    dispatch(getUserDashboardEffect({ id: props.userId }));
  };

  const handleRejectMlsAccessClick = async (mlsId: number) => {
    await rejectMlsAccessForAgent({
      userId: props.userId,
      mlsId,
    });
    dispatch(getUserDashboardEffect({ id: props.userId }));
  };

  const onSaveTeamName = async () => {
    await renameTeamName({
      teamId: user.Team.Id,
      name: teamName,
    });
    dispatch(getUserDashboardEffect({ id: props.userId }));
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div>Agent Info:</div>
      {user.SelectedMlsServices && (
        <div>
          <Divider>Available MLS</Divider>
          <div>
            {user.SelectedMlsServices.map((mls, index) => (
              <div key={index}>
                <div>{mls.MlsName}</div>
                <div>Status: {mls.AccessStatus}</div>
                {(mls.AccessStatus === MlsAccessStatus.REQUESTED ||
                  mls.AccessStatus === MlsAccessStatus.REJECTED) && (
                  <Button onClick={() => handleAllowMlsAccessClick(mls.MlsId)} variant="secondary">
                    Approve
                  </Button>
                )}
                {(mls.AccessStatus === MlsAccessStatus.REQUESTED ||
                  mls.AccessStatus === MlsAccessStatus.APPROVED) && (
                  <Button onClick={() => handleRejectMlsAccessClick(mls.MlsId)}>Reject</Button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {user.Team && (
        <div className={styles.team}>
          <Divider>Team Agent</Divider>
          <div className={styles.content}>
            <p>Team Name</p>
            <Input value={teamName} onChange={(e) => setTeamName(e.target.value)} />
            <Button
              className={styles.saveBtn}
              disabled={!teamName || teamName === user.Team.Name}
              onClick={onSaveTeamName}
            >
              Save
            </Button>
          </div>
        </div>
      )}
      <SubscriptionCustomerData userId={props.userId} />
    </div>
  );
};

export default AgentInfo;
