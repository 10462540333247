type IconsPropsType = {
  iconName:
    | 'star-red'
    | 'star'
    | 'fullMatch'
    | 'partialMatch'
    | 'noMatch'
    | 'picCount'
    | 'add'
    | 'photoCompare'
    | 'photoCompareRed'
    | 'person'
    | 'down'
    | 'download'
    | 'circledStar'
    | 'yellowCircledStar'
    | 'yellowStar'
    | 'delete'
    | 'comment'
    | 'share'
    | 'book';
};

function Icons(props: IconsPropsType) {
  if (props.iconName === 'star') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.00108 11.834L3.88642 13.9973L4.67242 9.41532L1.34375 6.17065L5.94375 5.50265L8.00108 1.33398L10.0584 5.50265L14.6584 6.17065L11.3298 9.41532L12.1158 13.9973L8.00108 11.834Z"
          stroke="#AAABAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.iconName === 'star-red') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_53616_124651)">
          <path
            d="M8.00108 11.834L3.88642 13.9973L4.67242 9.41532L1.34375 6.17065L5.94375 5.50265L8.00108 1.33398L10.0584 5.50265L14.6584 6.17065L11.3298 9.41532L12.1158 13.9973L8.00108 11.834Z"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_53616_124651">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (props.iconName === 'fullMatch') {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 12L13 16L21 8"
          stroke="#04A451"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 12L8 16L16 8"
          stroke="#04A451"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.iconName === 'partialMatch') {
    return (
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 5L5 9L13 1"
          stroke="#FB913A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.iconName === 'noMatch') {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.5 16.5L16.5 7.5" stroke="#AAABAB" strokeWidth="2" strokeLinecap="round" />
        <path d="M16.5 16.5L7.5 7.5" stroke="#AAABAB" strokeWidth="2" strokeLinecap="round" />
      </svg>
    );
  } else if (props.iconName === 'picCount') {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="5" y="5" width="8" height="8" rx="2" stroke="white" strokeWidth="2" />
        <path
          d="M16 9V12.5C16 14.433 14.433 16 12.5 16H9"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.iconName === 'add') {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#FF576D" />
        <path d="M7 12H17" stroke="white" strokeWidth="2" strokeLinecap="round" />
        <path d="M12 17L12 7" stroke="white" strokeWidth="2" strokeLinecap="round" />
      </svg>
    );
  } else if (props.iconName === 'photoCompare') {
    return (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1" width="42" height="42" rx="21" fill="white" />
        <rect x="14" y="14" width="16" height="16" rx="2.5" stroke="#515151" strokeWidth="2" />
        <path
          d="M19.5005 21.5C20.6051 21.5 21.5005 20.6046 21.5005 19.5C21.5005 18.3954 20.6051 17.5 19.5005 17.5C18.3959 17.5 17.5005 18.3954 17.5005 19.5C17.5005 20.6046 18.3959 21.5 19.5005 21.5Z"
          stroke="#515151"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 30L24.2929 22.7071C24.6834 22.3166 25.3166 22.3166 25.7071 22.7071L30 27"
          stroke="#515151"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="1" y="1" width="42" height="42" rx="21" stroke="#E8E9EA" strokeWidth="2" />
      </svg>
    );
  } else if (props.iconName === 'photoCompareRed') {
    return (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1" width="42" height="42" rx="21" fill="#FFF2F3" />
        <rect x="14" y="14" width="16" height="16" rx="2.5" stroke="#262626" strokeWidth="2" />
        <path
          d="M19.5 21.5C20.6046 21.5 21.5 20.6046 21.5 19.5C21.5 18.3954 20.6046 17.5 19.5 17.5C18.3954 17.5 17.5 18.3954 17.5 19.5C17.5 20.6046 18.3954 21.5 19.5 21.5Z"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 30L24.2929 22.7071C24.6834 22.3166 25.3166 22.3166 25.7071 22.7071L30 27"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="1" y="1" width="42" height="42" rx="21" stroke="#FF576D" strokeWidth="2" />
      </svg>
    );
  } else if (props.iconName === 'person') {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
        <path
          d="M20 12C20 14.2091 18.2091 16 16 16C13.7909 16 12 14.2091 12 12C12 9.79086 13.7909 8 16 8C18.2091 8 20 9.79086 20 12Z"
          stroke="#515151"
          strokeWidth="2"
        />
        <path
          d="M10 23.5C10.3739 21.6303 12.0932 20 13.9999 20H18C19.9067 20 21.6261 21.6303 22 23.5"
          stroke="#515151"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.iconName === 'down') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.5 6L8 10.5L12.5 6"
          stroke="#515151"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.iconName === 'download') {
    return (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1" width="42" height="42" rx="21" fill="white" />
        <path
          d="M15 25V27C15 28.1046 15.8954 29 17 29H27C28.1046 29 29 28.1046 29 27V25"
          stroke="#515151"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 24V14M22 24L18 20M22 24L26 20"
          stroke="#515151"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="1" y="1" width="42" height="42" rx="21" stroke="#E8E9EA" strokeWidth="2" />
      </svg>
    );
  } else if (props.iconName === 'circledStar' || props.iconName === 'yellowCircledStar') {
    return (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="42"
          height="42"
          rx="21"
          fill={props.iconName === 'yellowCircledStar' ? 'rgba(251, 145, 58,0.2)' : 'white'}
        />
        <path
          d="M22.0014 26.793L16.858 29.4971L17.8405 23.7696L13.6797 19.7138L19.4297 18.8788L22.0014 13.668L24.573 18.8788L30.323 19.7138L26.1622 23.7696L27.1447 29.4971L22.0014 26.793Z"
          stroke="#515151"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="1"
          y="1"
          width="42"
          height="42"
          rx="21"
          stroke={props.iconName === 'yellowCircledStar' ? '#FB913A' : '#E8E9EA'}
          strokeWidth="2"
        />
      </svg>
    );
  } else if (props.iconName === 'yellowStar') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_974_81303)">
          <path
            d="M8.00108 11.834L3.88642 13.9973L4.67242 9.41532L1.34375 6.17065L5.94375 5.50265L8.00108 1.33398L10.0584 5.50265L14.6584 6.17065L11.3298 9.41532L12.1158 13.9973L8.00108 11.834Z"
            fill="#FB913A"
            stroke="#FB913A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_974_81303">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (props.iconName === 'delete') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_124_2037)">
          <path
            d="M2.18311 4.36328H13.8195"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.54639 7.27344V11.6371"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.45361 7.27344V11.6371"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.9082 4.36328L3.63548 13.0906C3.63548 13.4763 3.78872 13.8463 4.0615 14.1191C4.33428 14.3919 4.70425 14.5451 5.09002 14.5451H10.9082C11.294 14.5451 11.6639 14.3919 11.9367 14.1191C12.2095 13.8463 12.3627 13.4763 12.3627 13.0906L13.09 4.36328"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.81836 4.36222V2.1804C5.81836 1.98751 5.89498 1.80253 6.03137 1.66614C6.16776 1.52975 6.35275 1.45313 6.54563 1.45312H9.45472C9.64761 1.45313 9.83259 1.52975 9.96898 1.66614C10.1054 1.80253 10.182 1.98751 10.182 2.1804V4.36222"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_124_2037">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (props.iconName === 'comment') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.49867 11.248L3.41874 11.6398L3.6166 11.1751L3.36003 10.74L2.49867 11.248ZM1.18591 14.3312L2.10598 14.7229H2.10598L1.18591 14.3312ZM1.69791 14.8611L2.05783 15.7941L2.05783 15.7941L1.69791 14.8611ZM4.93438 13.6125L5.41647 12.7364L5.0087 12.512L4.57446 12.6796L4.93438 13.6125ZM13.4153 8.00156C13.4153 10.9839 10.9977 13.4016 8.01532 13.4016V15.4016C12.1022 15.4016 15.4153 12.0885 15.4153 8.00156H13.4153ZM8.01532 2.60156C10.9977 2.60156 13.4153 5.01922 13.4153 8.00156H15.4153C15.4153 3.91466 12.1022 0.601562 8.01532 0.601562V2.60156ZM2.61532 8.00156C2.61532 5.01922 5.03298 2.60156 8.01532 2.60156V0.601562C3.92842 0.601562 0.615322 3.91466 0.615322 8.00156H2.61532ZM3.36003 10.74C2.88699 9.93794 2.61532 9.003 2.61532 8.00156H0.615322C0.615322 9.37002 0.987712 10.6546 1.6373 11.756L3.36003 10.74ZM2.10598 14.7229L3.41874 11.6398L1.57859 10.8563L0.265838 13.9394L2.10598 14.7229ZM1.33799 13.9281C1.83275 13.7372 2.31373 14.235 2.10598 14.7229L0.265838 13.9394C-0.218908 15.0779 0.903381 16.2394 2.05783 15.7941L1.33799 13.9281ZM4.57446 12.6796L1.33799 13.9281L2.05783 15.7941L5.2943 14.5455L4.57446 12.6796ZM8.01532 13.4016C7.07122 13.4016 6.18649 13.1601 5.41647 12.7364L4.45228 14.4887C5.51019 15.0708 6.72562 15.4016 8.01532 15.4016V13.4016Z"
          fill="#FF576D"
        />
      </svg>
    );
  } else if (props.iconName === 'share') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.66748 11.332V12.6654C2.66748 13.019 2.80796 13.3581 3.058 13.6082C3.30805 13.8582 3.64719 13.9987 4.00081 13.9987H12.0008C12.3544 13.9987 12.6936 13.8582 12.9436 13.6082C13.1937 13.3581 13.3341 13.019 13.3341 12.6654V11.332"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66748 6.66406L8.00081 3.33073L11.3341 6.66406"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 4V10.5"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.iconName === 'book') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 12.6671C2.91212 12.1405 3.94678 11.8633 5 11.8633C6.05322 11.8633 7.08788 12.1405 8 12.6671C8.91212 12.1405 9.94678 11.8633 11 11.8633C12.0532 11.8633 13.0879 12.1405 14 12.6671"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 3.99916C2.91212 3.47255 3.94678 3.19531 5 3.19531C6.05322 3.19531 7.08788 3.47255 8 3.99916C8.91212 3.47255 9.94678 3.19531 11 3.19531C12.0532 3.19531 13.0879 3.47255 14 3.99916"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 4V12.6667"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 4V12.6667"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 4V12.6667"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else return <></>;
}

export default Icons;
