import { useCallback } from 'react';
import PropTypes from 'prop-types';

const Messages = ({ className, variant, size, color }) => {
  const getIcon = useCallback(() => {
    switch (variant) {
      case Messages.HOLLOW:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5.1237 16.0581L6.04377 16.4498L6.24163 15.9851L5.98506 15.5501L5.1237 16.0581ZM3.48275 19.912L2.56268 19.5203H2.56268L3.48275 19.912ZM4.12275 20.5744L4.48267 21.5074H4.48267L4.12275 20.5744ZM8.16834 19.0137L8.65043 18.1376L8.24266 17.9132L7.80841 18.0807L8.16834 19.0137ZM19.0195 12C19.0195 15.866 15.8855 19 12.0195 19V21C16.9901 21 21.0195 16.9706 21.0195 12H19.0195ZM12.0195 5C15.8855 5 19.0195 8.13401 19.0195 12H21.0195C21.0195 7.02944 16.9901 3 12.0195 3V5ZM5.01952 12C5.01952 8.13401 8.15352 5 12.0195 5V3C7.04896 3 3.01952 7.02944 3.01952 12H5.01952ZM5.98506 15.5501C5.37169 14.51 5.01952 13.2977 5.01952 12H3.01952C3.01952 13.6647 3.47242 15.2267 4.26234 16.5661L5.98506 15.5501ZM4.40283 20.3038L6.04377 16.4498L4.20363 15.6663L2.56268 19.5203L4.40283 20.3038ZM3.76283 19.6414C4.17514 19.4824 4.57595 19.8972 4.40283 20.3038L2.56268 19.5203C2.04332 20.7401 3.24575 21.9846 4.48267 21.5074L3.76283 19.6414ZM7.80841 18.0807L3.76283 19.6414L4.48267 21.5074L8.52826 19.9467L7.80841 18.0807ZM12.0195 19C10.7962 19 9.64894 18.687 8.65043 18.1376L7.68624 19.8898C8.97264 20.5977 10.4506 21 12.0195 21V19Z"
              fill={color}
            />
          </svg>
        );
      default: {
        return (
          <svg
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.65484 21.8053L7.57399 22.1992C7.70276 21.8988 7.67654 21.5543 7.50378 21.2768L6.65484 21.8053ZM4.88629 25.9319L3.96715 25.538H3.96715L4.88629 25.9319ZM6.06805 27.1137L5.67413 26.1945H5.67413L6.06805 27.1137ZM10.1947 25.3451L10.7232 24.4962C10.4456 24.3234 10.1012 24.2972 9.80075 24.426L10.1947 25.3451ZM26 16C26 21.5228 21.5228 26 16 26V28C22.6274 28 28 22.6274 28 16H26ZM16 6C21.5228 6 26 10.4772 26 16H28C28 9.37258 22.6274 4 16 4V6ZM5.99997 16C5.99997 10.4772 10.4771 6 16 6V4C9.37256 4 3.99997 9.37258 3.99997 16H5.99997ZM7.50378 21.2768C6.5507 19.7458 5.99997 17.9388 5.99997 16H3.99997C3.99997 18.3231 4.66109 20.4948 5.8059 22.3338L7.50378 21.2768ZM5.80544 26.3258L7.57399 22.1992L5.7357 21.4114L3.96715 25.538L5.80544 26.3258ZM5.67413 26.1945C5.68124 26.1915 5.7068 26.1855 5.73753 26.1923C5.76255 26.1978 5.77687 26.2084 5.78423 26.2157C5.79159 26.2231 5.80222 26.2374 5.80771 26.2624C5.81445 26.2932 5.80849 26.3187 5.80544 26.3258L3.96715 25.538C3.29152 27.1145 4.88549 28.7085 6.46197 28.0328L5.67413 26.1945ZM9.80075 24.426L5.67413 26.1945L6.46197 28.0328L10.5886 26.2643L9.80075 24.426ZM16 26C14.0612 26 12.2542 25.4493 10.7232 24.4962L9.66618 26.1941C11.5052 27.3389 13.6769 28 16 28V26Z"
              fill={color}
            />
            <circle cx="10" cy="16" r="2" fill={color} />
            <circle cx="16" cy="16" r="2" fill={color} />
            <circle cx="22" cy="16" r="2" fill={color} />
          </svg>
        );
      }
    }
  }, [variant]);
  return <div className={className}>{getIcon()}</div>;
};

Messages.HOLLOW = 'hollow';
Messages.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};
Messages.defaultProps = { className: '', variant: undefined, size: '32', color: '#515151' };

export default Messages;
