export function prepareData(values, stageIndex, defaultClientSearch = {}) {
  return {
    stageIndex,
    DefaultPropertySearchPreferences: {
      ...defaultClientSearch,
      ...(values.lotSizeMin || values.lotSizeMax
        ? {
            LotSizeRange: {
              Min: values.lotSizeMin ? +values.lotSizeMin : undefined,
              Max: values.lotSizeMax ? +values.lotSizeMax : undefined,
            },
          }
        : { LotSizeRange: undefined }),
    },
  };
}
