import { handleActions } from 'redux-actions';
import { signupRoleAction } from 'store/actions/loginGroup';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';

const initialData = '';

export default handleActions(
  {
    [signupRoleAction]: (state, { payload }) => payload.role,
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
