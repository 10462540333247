import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { TransactionCloseComponentIds } from 'app-constants';
import { tasksStatusesIds } from 'settings/constants/transactionTasks';
import Question from 'pages/Workshop/Transactions/TransactionCreate/components/Question';

import useIsProjectRoute from 'hooks/use-is-project-route';
import { getFilteredTasksSelector } from 'store/selectors/transactionTasks';
import { PendingTasks } from '../PendingTasks';
import { uniq, uniqBy } from 'lodash-es';

interface ClosingTasksProps {
  previousStage?: TransactionCloseComponentIds;
  setStage: (stage: TransactionCloseComponentIds) => void;
}

export const ClosingTasks = ({ previousStage, setStage }: ClosingTasksProps) => {
  const { tasks: selectorTasks } = useSelector(getFilteredTasksSelector);

  const isProject = useIsProjectRoute();

  const uniqueTasks = uniqBy(selectorTasks, 'Id') as any;

  const tasks = uniqueTasks.filter(
    (pendingTask) => ![tasksStatusesIds.done, tasksStatusesIds.na].includes(pendingTask.Status),
  );

  useEffect(() => {
    if (!tasks?.length) {
      if (previousStage === TransactionCloseComponentIds.IncompleteForms) {
        onNext();
      } else if (previousStage === TransactionCloseComponentIds.ActiveSearchInstances) {
        setStage(TransactionCloseComponentIds.IncompleteForms);
      }
    }
  }, [tasks]);

  const onNext = () => {
    if (isProject) {
      setStage(TransactionCloseComponentIds.CloseConfirmation);
    } else {
      setStage(TransactionCloseComponentIds.ActiveSearchInstances);
    }
  };

  return tasks?.length ? (
    <div>
      <Question>These tasks are still pending.</Question>
      <PendingTasks tasks={tasks} onNext={onNext} />
    </div>
  ) : (
    <></>
  );
};
