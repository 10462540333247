import React from 'react';
import { useDispatch } from 'react-redux';
import { cancelNewAreaGroupAction } from 'store/actions/adminPanel';
import { saveNewAreaGroupEffect } from 'store/effects/adminPanel/areaClassifier';
import { EditAreaGroup } from './EditAreaGroup';

interface NewAreaProps {}

export const NewArea: React.FC<NewAreaProps> = (props) => {
  const dispatch = useDispatch();

  return (
    <EditAreaGroup
      onSaveAreaGroup={() => {
        dispatch(saveNewAreaGroupEffect());
        dispatch(cancelNewAreaGroupAction());
      }}
    />
  );
};
