import { createSelector } from 'reselect';
import { get } from 'lodash-es';

const localState = ({ adminPanel }) => adminPanel;

const serviceDirectoryState = createSelector(
  localState,
  ({ serviceDirectory }) => serviceDirectory,
);

const categoriesState = createSelector(serviceDirectoryState, ({ categories }) => categories);

const categoryState = createSelector(serviceDirectoryState, ({ category }) => category);

export const getCategoriesSortSelector = createSelector(categoriesState, ({ sort }) => sort);

export const getCategoriesSelector = createSelector(categoriesState, (state) => state);

export const getCategorySelector = createSelector(categoryState, (state) => state);

export const getLoadingCategoryData = createSelector(categoryState, ({ state, data, meta }) => ({
  state,
  dataId: `${get(data, 'Id')}`,
  metaId: `${get(meta, 'id')}`,
}));

export const getCategoriesSearchSelector = createSelector(categoriesState, ({ search }) => search);
