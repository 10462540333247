import { routes } from 'settings/navigation/routes';
import { OnBoarding, OnBoardingWizard, StartWizard } from 'pages';
import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';
import { OnboardingAgentV2 } from 'pages/OnboardingV2Agent/OnboardingV2Agent';

export default [
  {
    path: routes.onBoarding,
    component: OnBoarding,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  {
    path: routes.onBoardingWithRole,
    component: OnBoarding,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  {
    path: routes.onBoardingV2Agent,
    component: OnboardingAgentV2,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.onBoardingWizard,
    component: OnBoardingWizard,
    exact: true,
    roles: [CLIENT],
  },
  {
    path: routes.onBoardingWizardWithRole,
    component: OnBoardingWizard,
    exact: true,
    roles: [CLIENT],
  },
  {
    path: routes.preApprovalWizard,
    component: OnBoardingWizard,
    exact: true,
    roles: [CLIENT],
  },
  {
    path: routes.onBoardingWizardWithRoleStart,
    component: StartWizard,
    exact: true,
    roles: [CLIENT],
  },
];
