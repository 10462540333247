import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash-es';
import moment from 'moment';
import { Switch } from 'components-antd';
import { getClientDetailsSelector } from 'store/selectors/clientDetail';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { isProfileExpandedSectionOPened } from 'store/selectors/app';
import { getAgentTypeSelector } from 'store/selectors/user';
import { retainClient } from 'api/user';
import { AGENT_TYPE } from 'settings/constants/drawers';
import Item from '../Item';
import { DrawerInfoCard } from 'components/DrawerInfoCard';
import { Email } from './components/Email';
import { Phone } from './components/Phone';
import { Status } from './components/Status';
import { AddressesMenu } from './components/Address';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_BASIC } from 'settings/constants/roles';
import { useLocation } from 'react-router-dom';
import { requestUpdateClientStateFlag } from 'store/actions/agentDashboard';

import styles from './styles.module.scss';

const Contact = ({ className, isAgent }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const agentType = useSelector(getAgentTypeSelector);
  const isExpanded = useSelector(isProfileExpandedSectionOPened);
  const details = useSelector(isAgent ? getAgentDetailsSelector : getClientDetailsSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const contact = details?.data || {};
  const [retained, setRetained] = useState(contact?.FromRetentionMode ? true : false);
  const joinedAt = isAgent ? contact.JoinedDate : contact.CreatedDate;
  const getLastActive = () => {
    const lastActive = get(contact, isAgent ? 'LastActive' : 'LastLogin');
    return lastActive ? moment(lastActive).fromNow() : '-';
  };

  const retentionSwitchHandler = (v) => {
    retainClient(contact?.Id, { retain: v });
    if (location.pathname === '/') dispatch(requestUpdateClientStateFlag(true));
  };

  const getRetentionSwitch = () => {
    return (
      <Switch
        className={styles.switch}
        checked={retained}
        defaultChecked={contact?.FromRetentionMode ? false : true}
        onChange={(v) => {
          setRetained(v);
          retentionSwitchHandler(v);
        }}
      />
    );
  };

  return (
    <div testid="contact" className={classNames(styles.contactWrapper, className)}>
      <Status contact={contact} isExpanded={isExpanded} />
      <Phone contact={contact} isExpanded={isExpanded} />
      <Email contact={contact} isExpanded={isExpanded} />
      {isAgent ? (
        // <Item
        //   className={{ [styles.expanded]: isExpanded }}
        //   testid="mailing_address"
        //   label="Address"
        //   value={get(contact, 'Address' || null}
        // />
        <></>
      ) : (
        <AddressesMenu
          className={{ [styles.expanded]: isExpanded }}
          label="Primary Address"
          clientAddresses={get(contact, ['Client', 'Address']) || null}
          clientId={contact.Id}
        />
      )}
      {isExpanded && !isAgent ? (
        <Item
          className={{ [styles.expanded]: isExpanded }}
          testid="under_retention"
          label="Under Retention"
          value={getRetentionSwitch()}
        />
      ) : (
        <></>
      )}
      {!isExpanded && agentType === AGENT_TYPE.Team && !isAgent ? (
        <DrawerInfoCard sectionTitle="Assigned To" isAssignedTo={true} clientInfo={contact} />
      ) : null}
      {!isExpanded && isAgent && contact?.ActiveTransactions?.length ? (
        <DrawerInfoCard
          sectionTitle="Active Transactions"
          isActiveTransaction={true}
          data={contact?.ActiveTransactions}
        />
      ) : null}
      {(!isAgent || agentRole !== TEAM_BASIC) && (
        <Item
          className={{ [styles.expanded]: isExpanded }}
          testid="joined_mosaik"
          label="Joined Mosaik"
          value={moment(joinedAt).format('M/DD/YY')}
        />
      )}
      {(!isAgent || agentRole !== TEAM_BASIC) && getLastActive() !== '-' ? (
        <Item
          className={{ [styles.expanded]: isExpanded }}
          testid="last_active"
          label="Last Active"
          value={getLastActive()}
        />
      ) : null}
      {isExpanded && agentType === AGENT_TYPE.Team && !isAgent ? (
        <DrawerInfoCard
          sectionTitle="This client is assigned to:"
          isAssignedTo={true}
          clientInfo={contact}
          className={styles.assignedTo}
        />
      ) : null}
    </div>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  isAgent: PropTypes.bool,
};

Contact.defaultProps = {
  className: '',
  isAgent: false,
};

export default Contact;
