import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FORM_STATUS_TYPE, FORM_UPDATE_STATUS } from 'app-constants';
import { ConfirmationModal, ConfirmationModalProps, InputSelectModal } from 'components-antd';
import {
  getFolderListEffect,
  getFormMetaEffect,
  getUserFormMetaEffect,
  patchFormVersionStatusEffect,
  requestCopyQuoteFormVersionEffect,
  requestCopySmartFormVersionEffect,
  requestNewQuoteFormVersionEffect,
  requestNewSmartFormVersionEffect,
  requestUpdateQuoteFormEffect,
  requestUpdateSmartFormEffect,
} from 'store/effects/formBuilder';
import { getFolderListSelector, getFormMetaSelect } from 'store/selectors/formBuilder';
import {
  ErrorProps,
  FormTeamPermission,
  FormUpdateStatusVariants,
  FormUserPermission,
} from 'types';
import { LocationProps } from '../FormGeoLocations';
import { PublishModal } from '../PublishModal';
import { getAllTeamsEffect } from 'store/effects';

export interface FormUpdateModalProps {
  variant?: FormUpdateStatusVariants;
  open?: boolean;
  hideModal?: () => void;
  callBack?: (...params) => void;
  formName?: string;
  formId?: number;
  versionId?: number;
  selectedTags?: number[];
  categories?: number[];
  formLocations?: LocationProps[];
  users?: FormUserPermission[];
  smartForm?: boolean;
  formCategoryTags?: number[];
  teams?: FormTeamPermission[];
}

export const FormUpdateModal = ({
  variant,
  open,
  hideModal,
  callBack,
  formName,
  formId,
  versionId,
  selectedTags,
  categories,
  formLocations,
  users,
  formCategoryTags,
  smartForm = false,
  teams = [],
}: FormUpdateModalProps) => {
  const [errors, setErrors] = useState<ErrorProps[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    tags: formMetaTags,
    categories: formMetaCategories,
    users: allUsers,
    teams: allTeams,
  } = useSelector(getFormMetaSelect);

  useEffect(() => {
    if (
      !(
        formMetaTags?.length ||
        formMetaCategories?.length ||
        (smartForm && !formCategoryTags?.length)
      )
    )
      dispatch(getFormMetaEffect());
    if (!allUsers?.length) dispatch(getUserFormMetaEffect());

    if (!allTeams?.length) dispatch(getAllTeamsEffect());
  }, []);

  const dispatch = useDispatch();

  const getConfirmationProps = (): Partial<ConfirmationModalProps | undefined> => {
    switch (variant) {
      case FORM_UPDATE_STATUS.Version:
        return {
          confirmText: `Are you sure you want make a new version for ${formName}?`,
          onOk: () => {
            if (!(formId && versionId)) return;
            setLoading(true);
            const data = {
              versionId,
              formId,
            };
            const cb = (err, res) => {
              setLoading(false);
              if (callBack) callBack(res);
              else handleCancel();
            };
            if (smartForm) {
              dispatch(requestNewSmartFormVersionEffect(data, cb));
            } else {
              dispatch(requestNewQuoteFormVersionEffect(data, cb));
            }
          },
        };
      case FORM_UPDATE_STATUS.Unpublish:
        return {
          confirmText: `Are you sure you want to unpublish ${formName}?`,
          onOk: () => {
            if (!(formId && versionId)) return;
            setLoading(true);
            dispatch(
              patchFormVersionStatusEffect(
                { formId: formId, formVersionId: versionId, status: FORM_STATUS_TYPE.InActive },
                (err, res) => {
                  setLoading(false);
                  if (callBack) callBack(res, err);
                  else handleCancel();
                },
              ),
            );
          },
        };
    }
  };

  const { folderList } = useSelector(getFolderListSelector);

  useEffect(() => {
    if (variant === FORM_UPDATE_STATUS.Copy && !folderList.folders?.length) {
      dispatch(getFolderListEffect());
    }
  }, [variant]);

  const handleCopy = async (setLoading: (loading: boolean) => void, values) => {
    const { entityName, selectedItem } = values;
    if (entityName && selectedItem) {
      setLoading(true);
      const data = {
        versionIds: [versionId],
        folderId: selectedItem,
        formName: entityName,
      };

      const cb = (err, res) => {
        setLoading(false);
        if (callBack) callBack(res);
        else handleCancel();
      };
      if (smartForm) {
        dispatch(requestCopySmartFormVersionEffect(data, cb));
      } else {
        dispatch(requestCopyQuoteFormVersionEffect(data, cb));
      }
    }
  };

  const handlePublish = (data) => {
    setLoading(true);
    const callback = (err, res) => {
      const formErrors = res?.data?.value?.errors;
      if (formErrors?.length) {
        setErrors(formErrors);
        setDisabled(true);
      }
      if (!err) {
        dispatch(
          patchFormVersionStatusEffect(
            { formId: formId, formVersionId: versionId, status: FORM_STATUS_TYPE.Active },
            (err, res) => {
              setLoading(false);
              if (callBack) callBack(null, err, formErrors?.length > 0);
              else if (!errors?.length) handleCancel();
            },
          ),
        );
      } else {
        setLoading(false);
      }
    };
    const updatedData = {
      Id: versionId,
      ...data,
    };
    if (smartForm) {
      dispatch(requestUpdateSmartFormEffect(updatedData, callback));
    } else {
      dispatch(requestUpdateQuoteFormEffect(updatedData, callback));
    }
  };

  const handleCancel = () => {
    setErrors([]);
    setDisabled(false);
    setLoading(false);
    if (hideModal) hideModal();
  };
  return (
    <>
      {(variant === FORM_UPDATE_STATUS.Version || variant === FORM_UPDATE_STATUS.Unpublish) && (
        <ConfirmationModal
          open={open}
          {...(getConfirmationProps() || {})}
          onCancel={handleCancel}
          confirmLoading={loading}
        />
      )}
      {variant === FORM_UPDATE_STATUS.Copy && (
        <InputSelectModal
          open={open}
          title={`Copy Form`}
          selectHeading="Folder"
          selectPlaceholder="Choose Folder"
          selectOptions={(folderList?.folders || []).map((folder) => ({
            label: folder?.Name,
            value: folder?.Id,
          }))}
          submit={handleCopy}
          onCancel={handleCancel}
          entityNameRequired={true}
          selectedItemRequired={true}
          entityName={formName}
          entityNameMaxLength={64}
          destroyOnClose={true}
        />
      )}
      {variant === FORM_UPDATE_STATUS.Publish && (
        <PublishModal
          onCancel={handleCancel}
          open={open}
          confirmationLoading={loading}
          onSubmit={handlePublish}
          errors={errors}
          disabled={disabled}
          selectedTags={selectedTags ? [...selectedTags] : []}
          categories={categories ? [...categories] : []}
          formLocations={formLocations ? [...formLocations] : []}
          formName={formName || ''}
          users={users}
          smartForm={smartForm}
          formCategoryTags={formCategoryTags}
          teams={teams}
        />
      )}
    </>
  );
};
