import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const Icons = (props) => {
  const { className, variant, onClick, testid } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.COPY: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6367 4L17.364 4C17.8944 4 18.4031 4.21071 18.7782 4.58579C19.1533 4.96086 19.364 5.46957 19.364 6V15C19.364 15.5304 19.1533 16.0391 18.7782 16.4142C18.4031 16.7893 17.8944 17 17.364 17H10.6367C10.1063 17 9.59758 16.7893 9.22251 16.4142C8.84743 16.0391 8.63672 15.5304 8.63672 15V6C8.63672 5.46957 8.84743 4.96086 9.22251 4.58579C9.59758 4.21071 10.1063 4 10.6367 4Z"
              stroke="#181D2C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.364 17V19C15.364 19.5304 15.1533 20.0391 14.7782 20.4142C14.4031 20.7893 13.8944 21 13.364 21H6.63672C6.10629 21 5.59758 20.7893 5.22251 20.4142C4.84743 20.0391 4.63672 19.5304 4.63672 19V10C4.63672 9.46957 4.84743 8.96086 5.22251 8.58579C5.59758 8.21071 6.10629 8 6.63672 8H8.63672"
              stroke="#181D2C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ADD: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8Z"
              fill="#FF576D"
            />
          </svg>
        );
      }
      case Icons.REMOVE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM13 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13H11H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11H13Z"
              fill="#FF576D"
            />
          </svg>
        );
      }
      case Icons.EDIT: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 14H5.13144L13.3515 5.77998C13.7667 5.36472 14 4.80152 14 4.21426C14 3.627 13.7667 3.0638 13.3515 2.64854C12.9362 2.23329 12.373 2 11.7857 2C11.1985 2 10.6353 2.23329 10.22 2.64854L2 10.8686V14Z"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.43683 3.43164L12.5683 6.56308"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.CALENDAR_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F6FB" />
            <path
              d="M20.5 10H11.5C10.6716 10 10 10.6716 10 11.5V20.5C10 21.3284 10.6716 22 11.5 22H20.5C21.3284 22 22 21.3284 22 20.5V11.5C22 10.6716 21.3284 10 20.5 10Z"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 9V11"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 9V11"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 14H22"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DEADLINE_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F6FB" />
            <circle cx="16" cy="16" r="6" stroke="#252D44" strokeWidth="2" />
            <path d="M16 8V10" stroke="#252D44" strokeWidth="2" strokeLinecap="round" />
            <path d="M18 14L16 16" stroke="#252D44" strokeWidth="2" strokeLinecap="round" />
            <path d="M18 7.5H14" stroke="#252D44" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.USER_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F6FB" />
            <path
              d="M19.3334 12.6668C19.3334 14.5078 17.841 16.0002 16 16.0002C14.1591 16.0002 12.6667 14.5078 12.6667 12.6668C12.6667 10.8259 14.1591 9.3335 16 9.3335C17.841 9.3335 19.3334 10.8259 19.3334 12.6668Z"
              stroke="#252D44"
              strokeWidth="2"
            />
            <path
              d="M11 22.2502C11.3116 20.6921 12.7443 19.3335 14.3332 19.3335H17.6667C19.2556 19.3335 20.6884 20.6921 21 22.2502"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.NOTIFICATION_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F6FB" />
            <g clipPath="url(#clip0_34907_181384)">
              <path
                d="M14.5 10.833H12C11.558 10.833 11.134 11.0086 10.8215 11.3212C10.5089 11.6337 10.3333 12.0576 10.3333 12.4997V19.9997C10.3333 20.4417 10.5089 20.8656 10.8215 21.1782C11.134 21.4907 11.558 21.6663 12 21.6663H19.5C19.942 21.6663 20.3659 21.4907 20.6785 21.1782C20.9911 20.8656 21.1666 20.4417 21.1666 19.9997V17.4997"
                stroke="#252D44"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.3333 14.167C21.714 14.167 22.8333 13.0477 22.8333 11.667C22.8333 10.2863 21.714 9.16699 20.3333 9.16699C18.9526 9.16699 17.8333 10.2863 17.8333 11.667C17.8333 13.0477 18.9526 14.167 20.3333 14.167Z"
                stroke="#252D44"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_34907_181384">
                <rect width="20" height="20" fill="white" transform="translate(6 6)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.DOCUMENT_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F6FB" />
            <path
              d="M17.7998 7.89844V11.4984C17.7998 11.7371 17.8946 11.9661 18.0634 12.1348C18.2322 12.3036 18.4611 12.3984 18.6998 12.3984H22.2998"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.5002 24.0984H11.5002C11.0228 24.0984 10.565 23.9088 10.2274 23.5712C9.88984 23.2337 9.7002 22.7758 9.7002 22.2984V9.69844C9.7002 9.22105 9.88984 8.76321 10.2274 8.42565C10.565 8.08808 11.0228 7.89844 11.5002 7.89844H17.8002L22.3002 12.3984V22.2984C22.3002 22.7758 22.1106 23.2337 21.773 23.5712C21.4354 23.9088 20.9776 24.0984 20.5002 24.0984Z"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M13.5 15.5H18.5" stroke="#252D44" strokeWidth="2" strokeLinecap="round" />
            <path d="M13.5 19.5H16.5" stroke="#252D44" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.DOCUMENT_LIGHT: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F5F6" />
            <path
              d="M17.7998 7.89844V11.4984C17.7998 11.7371 17.8946 11.9661 18.0634 12.1348C18.2322 12.3036 18.4611 12.3984 18.6998 12.3984H22.2998"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.5002 24.0984H11.5002C11.0228 24.0984 10.565 23.9088 10.2274 23.5712C9.88984 23.2337 9.7002 22.7758 9.7002 22.2984V9.69844C9.7002 9.22105 9.88984 8.76321 10.2274 8.42565C10.565 8.08808 11.0228 7.89844 11.5002 7.89844H17.8002L22.3002 12.3984V22.2984C22.3002 22.7758 22.1106 23.2337 21.773 23.5712C21.4354 23.9088 20.9776 24.0984 20.5002 24.0984Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M13.5 15.5H18.5" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M13.5 19.5H16.5" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.NOTES_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F6FB" />
            <path
              d="M20.8337 14.5V21.8333C20.8337 22.2754 20.6581 22.6993 20.3455 23.0118C20.0329 23.3244 19.609 23.5 19.167 23.5H9.83366C9.39163 23.5 8.96771 23.3244 8.65515 23.0118C8.34259 22.6993 8.16699 22.2754 8.16699 21.8333V10.1667C8.16699 9.72464 8.34259 9.30072 8.65515 8.98816C8.96771 8.67559 9.39163 8.5 9.83366 8.5H16.667L18.3337 10.1667"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 19H16.1314L24.3515 10.78C24.7667 10.3647 25 9.80152 25 9.21426C25 8.627 24.7667 8.0638 24.3515 7.64854C23.9362 7.23329 23.373 7 22.7857 7C22.1985 7 21.6353 7.23329 21.22 7.64854L13 15.8686V19Z"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.NOTIFY_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F6FB" />
            <path
              d="M14.5 17.5L22.375 9.625"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.375 9.625L17.5854 22.8886C17.5531 22.9591 17.5011 23.0189 17.4358 23.0608C17.3705 23.1027 17.2946 23.125 17.2169 23.125C17.1393 23.125 17.0634 23.1027 16.9981 23.0608C16.9327 23.0189 16.8808 22.9591 16.8485 22.8886L14.2695 17.7305L9.11144 15.1515C9.04089 15.1192 8.98111 15.0672 8.93919 15.0019C8.89728 14.9366 8.875 14.8607 8.875 14.7831C8.875 14.7054 8.89728 14.6295 8.93919 14.5642C8.98111 14.4989 9.04089 14.4469 9.11144 14.4146L22.375 9.625Z"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ENABLE_LINK_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F6FB" />
            <g clipPath="url(#clip0_34771_19809)">
              <path
                d="M14.334 17.6676C14.6055 17.9448 14.9297 18.165 15.2874 18.3153C15.6452 18.4656 16.0293 18.5431 16.4173 18.5431C16.8053 18.5431 17.1895 18.4656 17.5472 18.3153C17.9049 18.165 18.2291 17.9448 18.5007 17.6676L21.834 14.3343C22.3865 13.7818 22.6969 13.0324 22.6969 12.251C22.6969 11.4696 22.3865 10.7202 21.834 10.1676C21.2815 9.6151 20.5321 9.30469 19.7507 9.30469C18.9692 9.30469 18.2199 9.6151 17.6673 10.1676L17.2507 10.5843"
                stroke="#252D44"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.6667 14.3325C17.3951 14.0553 17.0709 13.8351 16.7132 13.6848C16.3555 13.5345 15.9714 13.457 15.5833 13.457C15.1953 13.457 14.8112 13.5345 14.4534 13.6848C14.0957 13.8351 13.7716 14.0553 13.5 14.3325L10.1667 17.6658C9.61412 18.2183 9.30371 18.9677 9.30371 19.7491C9.30371 20.5305 9.61412 21.2799 10.1667 21.8325C10.7192 22.385 11.4686 22.6954 12.25 22.6954C13.0314 22.6954 13.7808 22.385 14.3333 21.8325L14.75 21.4158"
                stroke="#252D44"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_34771_19809">
                <rect width="20" height="20" fill="white" transform="translate(6 6)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.INFO_LIGHT: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_34977_54824)">
              <path
                d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 8C8 9.0414 8 9.4586 8 10.5"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="8" cy="5" r="1" fill="#AAABAB" />
            </g>
            <defs>
              <clipPath id="clip0_34977_54824">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icons, className)}>
      {getIcons()}
    </div>
  );
};

Icons.EDIT = 'edit';
Icons.COPY = 'copy';
Icons.ADD = 'add';
Icons.REMOVE = 'remove';
Icons.CALENDAR_BG = 'calendar-bg';
Icons.DEADLINE_BG = 'deadline-bg';
Icons.USER_BG = 'user-bg';
Icons.NOTIFICATION_BG = 'notification-bg';
Icons.NOTES_BG = 'notes-bg';
Icons.NOTIFY_BG = 'notify-bg';
Icons.ENABLE_LINK_BG = 'enable-link-bg';
Icons.DOCUMENT_BG = 'document-bg';
Icons.DOCUMENT_LIGHT = 'document-light';
Icons.INFO_LIGHT = 'info-light';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  testid: PropTypes.string,
  onClick: PropTypes.func,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};
