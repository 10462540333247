import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash-es';

import { useLongPress } from 'hooks';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';

import { openThreadContactsDrawerAction } from 'store/actions/drawers/threadContacts';
import {
  getPartnersGroupedUnarchivedThreadsForOpenedClient,
  getPartnersGroupedArchivedThreadsForOpenedClient,
} from 'store/selectors/sockets/threads';

import { DRAWER_MESSAGES_TYPES, DRAWER_THREAD_CONTACTS_TYPES } from 'settings/constants/drawers';
import { THIRD_PARTY } from 'settings/constants/roles';

import { Avatar, UserMessage, UsersMessage, NoThreads } from 'components';
import ActionsList from 'components/Drawers/components/ActionsList';
import {
  getArchiveThreadOption,
  getAddParticipantThreadOption,
  getOpenChatThreadOption,
  openChatThreadHandler,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import { changeMessagesDrawerTypeAction } from 'store/actions/drawers/messages';
import DrawerHeaderControlls from '../../DrawerHeaderControlls';

import styles from './styles.module.scss';

const emptyThread = {};

const MessagesDrawerServicesContent = ({ archived }) => {
  const dispatch = useDispatch();
  const [actionsPosition, setActionPosition] = useState(null);
  const [actions, setActions] = useState([]);

  const getThreadsForOpenedUserSelector = () =>
    archived
      ? getPartnersGroupedArchivedThreadsForOpenedClient
      : getPartnersGroupedUnarchivedThreadsForOpenedClient;

  const { user, quotes, personal, group, threads, userId } = useSelector(
    getThreadsForOpenedUserSelector(),
  );

  useEffect(() => {
    if (!quotes?.length && !personal?.length && !group?.length) {
      dispatch(
        changeMessagesDrawerTypeAction({
          type: archived ? DRAWER_MESSAGES_TYPES.ARCHIVED : DRAWER_MESSAGES_TYPES.INIT,
        }),
      );
    }
  }, [quotes?.length, personal?.length, group?.length, dispatch, archived]);

  const openChatHandler = useCallback(
    () =>
      openChatThreadHandler(dispatch, {
        participants: [
          {
            id: user?.Id,
            name: `${user?.FirstName || ''} ${user?.LastName || ''} (${
              user?.ThirdParty?.BusinessName
            })`,
            value: user?.Id,
            role: THIRD_PARTY,
            avatarUrl: user?.AvatarUrl,
            firstName: user?.FirstName,
            lastName: user?.LastName,
          },
        ],
      }),
    [dispatch, user],
  );

  const clientActions = useMemo(
    () => [
      {
        title: 'View contact',
        onClick: () =>
          dispatch(
            openThreadContactsDrawerAction({
              open: true,
              type: DRAWER_THREAD_CONTACTS_TYPES.DIRECT_CONTACT,
              params: { contactId: user?.Id, threadId: threads?.[0]?.Id },
            }),
          ),
        testid: 'view_contact',
      },
    ],
    [dispatch, user?.Id, threads],
  );

  const onLongPress = (event, [thread]) => {
    setActionPosition({ x: event?.clientX, y: event?.clientY });
    setActions([
      ...getArchiveThreadOption(dispatch, thread, () => {
        setActionPosition(null);
      }),
    ]);
  };
  const onPress = (event, [thread]) => {
    openChatThreadHandler(dispatch, { threadId: thread.Id, partnerId: user?.Id });
  };

  const longPressEvent = useLongPress(onLongPress, onPress);

  return (
    <>
      <DrawerHeaderControlls actions={clientActions} archived={archived} />
      <div testid="client_content" className={styles.wrapper}>
        <div testid="client_wrapper" className={styles.client}>
          <Avatar
            className={styles.clientAvatar}
            src={user?.AvatarUrl}
            placeholder={convertNameToAvatarPlaceholder(`${user?.FirstName} ${user?.LastName}`)}
          />
          <div className={styles.clientInfo}>
            <div className={styles.clientInfoTitle}>
              <span testid="client_name">
                {user?.ThirdParty?.BusinessName
                  ? `${user?.FirstName} ${user?.LastName} (${user.ThirdParty.BusinessName})`
                  : `${user?.FirstName} ${user?.LastName}`}
              </span>
              {archived && (
                <span testid="client_archived" className={styles.clientInfoTitleLabel}>
                  Archived
                </span>
              )}
            </div>
            <div testid="client_role" className={styles.clientInfoRole}>
              {(get(user, 'ThirdParty.CategoryTitles') || []).join(', ')}
            </div>
          </div>
        </div>
        <div className={styles.scrollWrapper}>
          {!personal?.length && archived ? null : (
            <div className={styles.block}>
              <div testid="direct_messages" className={styles.blockTitle}>
                Direct Messages
              </div>
              {personal?.length
                ? personal.map((thread) => (
                    <UsersMessage
                      key={thread.Id}
                      userId={userId}
                      thread={thread}
                      {...longPressEvent(thread)}
                    />
                  ))
                : user && (
                    <UserMessage user={user} thread={emptyThread} openHandler={openChatHandler} />
                  )}
            </div>
          )}
          {!group?.length ? null : (
            <div className={styles.block}>
              <div testid="grout_messages" className={styles.blockTitle}>
                Group Messages
              </div>
              {group.map((thread) => (
                <UsersMessage
                  key={thread.Id}
                  userId={userId}
                  thread={thread}
                  {...longPressEvent(thread)}
                />
              ))}
            </div>
          )}
          <div className={styles.block}>
            <div testid="quotes_messages" className={styles.blockTitle}>
              Quotes
            </div>
            {quotes?.length ? (
              quotes.map((thread) => (
                <UsersMessage
                  key={thread.Id}
                  userId={userId}
                  thread={thread}
                  {...longPressEvent(thread)}
                />
              ))
            ) : (
              <NoThreads />
            )}
          </div>
        </div>
      </div>
      <ActionsList actions={actions} position={actionsPosition} />
    </>
  );
};

MessagesDrawerServicesContent.propTypes = {
  archived: PropTypes.bool.isRequired,
};

MessagesDrawerServicesContent.defaultProps = {};

export default MessagesDrawerServicesContent;
