import { Avatar } from 'components';
import Name from 'components/ClientsDrawer/ProfileClient/Name';
import { convertNameToAvatarPlaceholder, priceConverter } from 'helpers';

import styles from './styles.module.scss';

export const AgentDetailsBox = ({ agent }) => {
  return (
    <div className={styles.wrapper}>
      <Avatar
        avatarClassName={styles.profileAvatar}
        src={agent.avatarUrl}
        placeholder={convertNameToAvatarPlaceholder(`${agent.firstName} ${agent.lastName}`)}
      />
      <Name className={styles.agentName} name={`${agent.firstName} ${agent.lastName}`} />
      <div className={styles.stats}>
        <div className={styles.statsFirstRow}>
          <div className={styles.amount}>{priceConverter(agent.amount || 0, 0)}</div>
          <div className={styles.requests}>{`(${agent.count})`}</div>
        </div>
        <div className={styles.percentage}>{`${agent.percentage}%`}</div>
      </div>
    </div>
  );
};
