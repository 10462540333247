import { FormWrapper } from 'components';
import { LeftSide, Container, RightSide } from 'components/LoginGroup';

import Form from './Form';

import styles from './styles.module.scss';

const Login = () => (
  <Container>
    <LeftSide className={styles.loginLeftSide} variant={LeftSide.LIGHT} />
    <FormWrapper className={styles.loginFormWrapper} alignCenter={false}>
      <Form />
    </FormWrapper>
    <RightSide />
  </Container>
);

export default Login;
