import { Space } from 'antd';

import { Sidebar } from 'components-antd';
import { NewPDFFields } from './components';

import styles from './styles.module.scss';

export const PDFEditorLeftSidebar = () => {
  return (
    <Sidebar width={240} className={styles.smartLeftSidebar}>
      <Space style={{ width: '100%' }} direction="vertical">
        <NewPDFFields />
      </Space>
    </Sidebar>
  );
};
