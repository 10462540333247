import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useLongPress } from 'hooks';

import { openMessagesDrawerAction } from 'store/actions/drawers/messages';
import { getListingsGroupedThreadsForOpenedListing } from 'store/selectors/sockets/threads';
import { getUserId } from 'store/selectors/user';

import { link } from 'settings/navigation/link';

import { ListingHome } from 'components/Icons';
import { NoThreads, UsersMessage } from 'components';

import ActionsList from 'components/Drawers/components/ActionsList';

import {
  getArchiveThreadOption,
  getAddParticipantThreadOption,
  getOpenChatThreadOption,
  openChatThreadHandler,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import DrawerHeaderControlls from '../../DrawerHeaderControlls';

import styles from './styles.module.scss';

// TODO: add transactions support
const MessagesDrawerChatListingContent = () => {
  const dispatch = useDispatch();
  const [actionsPosition, setActionPosition] = useState(null);
  const [actions, setActions] = useState([]);

  const history = useHistory();
  const userId = useSelector(getUserId);
  const { threads, name, id } = useSelector(getListingsGroupedThreadsForOpenedListing);

  const listingActions = useMemo(
    () => [
      {
        title: 'Go to Listing',
        onClick: () => {
          history.push(link.toFeedListingDetails(id));
          dispatch(openMessagesDrawerAction(false));
        },
        testid: 'go_to_listing',
      }, // TODO: add offers and transactions support
    ],
    [dispatch, id, history],
  );

  const onLongPress = (event, [thread]) => {
    setActionPosition({ x: event?.clientX, y: event?.clientY });
    setActions([
      ...getArchiveThreadOption(dispatch, thread, () => {
        setActionPosition(null);
      }),
    ]);
  };
  const onPress = (event, [thread]) => {
    openChatThreadHandler(dispatch, { threadId: thread.Id, listingId: id });
  };

  const longPressEvent = useLongPress(onLongPress, onPress);

  return (
    <>
      <DrawerHeaderControlls actions={listingActions} />
      <div testid="listing_content" className={styles.wrapper}>
        <div className={styles.listing}>
          <ListingHome className={styles.listingIcon} />
          <div className={styles.listingInfo}>
            <div className={styles.listingInfoTitle}>{name || 'No name'}</div>
            <div className={styles.listingInfoRole}>Listing</div>
          </div>
        </div>
        <div className={styles.scrollWrapper}>
          {threads?.length ? (
            threads.map((thread) => (
              <UsersMessage
                userId={userId}
                thread={thread}
                key={thread.Id}
                {...longPressEvent(thread)}
              />
            ))
          ) : (
            <NoThreads />
          )}
        </div>
      </div>
      <ActionsList actions={actions} position={actionsPosition} />
    </>
  );
};

export default MessagesDrawerChatListingContent;
