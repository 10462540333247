import { routes } from 'settings/navigation/routes';
import { Main, Pulse } from 'pages';
import { AGENT, CLIENT, SUPER_USER, THIRD_PARTY } from 'settings/constants/roles';
import onBoardingConfig from './onBoarding';
import settings from './settings';
import properties from './properties';
import services from './services';
import adminPanel from './adminPanel';
import vault from './vault';
import workshop from './workshop';
import formBuilder from './formBuilder';
import showing from './showing';
import templates from './templates';
import symphony from './symphony';
import clientDashboard from './clientDashboard';
import listings from './listings';
import clarity from './clarity';

export default [
  {
    path: routes.index,
    component: Main,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY, SUPER_USER],
  },
  formBuilder,
  ...onBoardingConfig,
  ...settings,
  ...properties,
  ...services,
  ...adminPanel,
  ...vault,
  ...workshop,
  ...showing,
  ...templates,
  ...symphony,
  ...clientDashboard,
  ...listings,
  ...clarity,
];
