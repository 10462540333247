export const ArchiveView = {
  transaction: 'transaction',
  client: 'client',
};

export enum WorkshopType {
  Transaction = 'Transaction',
  Project = 'Project',
}

export enum ProjectDateType {
  KickoffDate = 'Kickoff Date',
  Kickoff = 'Kickoff',
  Deadline = 'Deadline',
  Completion = 'Completion',
}
