import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Question } from 'pages/RequestQuote/components';

import styles from './styles.module.scss';

const Complete = (props) => {
  const {
    className,
    onPreFormSubmit,
    heading = 'Your transaction has been created and your transaction room is ready for use.',
  } = props;

  return (
    <div testid="transaction_complete" className={classNames(styles.container, className)}>
      <div className={styles.celebrate}>🎉</div>
      <Question className={styles.question}>{heading}</Question>
      <ButtonsContainer>
        <Button
          title="Take Me There"
          onClick={onPreFormSubmit}
          className={styles.completeBtn}
          titleClassName={styles.buttonTitle}
        />
      </ButtonsContainer>
    </div>
  );
};

Complete.propTypes = {
  className: PropTypes.string,
  onPreFormSubmit: PropTypes.func,
};

Complete.defaultProps = {
  className: '',
  onPreFormSubmit: () => {},
};

export default Complete;
