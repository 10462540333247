import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getUserRolesMapSelector } from 'store/selectors/user';

import AgentPartnersList from '../AgentPartnersList';
import OthersRolesPartnersList from '../OthersRolesPartnersList';

import styles from './styles.module.scss';

const PartnersList = ({ category }) => {
  const { isAgent } = useSelector(getUserRolesMapSelector);

  return (
    <div testid="category_view" className={styles.wrapper}>
      {isAgent ? (
        <AgentPartnersList title={category?.Title} />
      ) : (
        <OthersRolesPartnersList categoryId={category?.Id} />
      )}
    </div>
  );
};

PartnersList.propTypes = {
  category: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Title: PropTypes.string.isRequired,
  }).isRequired,
};

export default PartnersList;
