import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAgentUnreadMessagesByClients } from 'api/user';
import AntInput from 'antd/lib/input';
import { Spinner } from 'components';
import { ClientCard } from './components/ClientCard';
import { getClientsSearchInstancesEffect } from 'store/effects/clientSearches';
import { getClientsSearchInstancesSelector } from 'store/selectors/clientSearches';
import { Row, Col } from 'antd';
import SearchGlass from 'components/Icons/SearchGlass';
import classNames from 'classnames';

import { Footer } from 'components-antd';
import Sort from '../Feed/Properties/PropsHeader/Actions/Sort';
import { ActiveInactive } from './components/ActiveInactiveFilter';
import styles from './styles.module.scss';
import { getFeedSortSelector } from 'store/selectors/feed';
import { getUnreadThreads } from 'store/selectors/sockets/threads';

export const ClientSearches = () => {
  enum FilterOption {
    All = 'All',
    Active = 'Active',
    Inactive = 'Inactive',
  }
  enum TeamFilterOption {
    All = 'Just Me',
    Team = 'Entire Team',
  }
  const [selectedClientId, setSelectedClientId] = useState();
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(getClientsSearchInstancesSelector);
  const sort = useSelector(getFeedSortSelector);
  const [unreadMessagesLoading, setUnreadMessagesLoading] = useState<boolean>(false);
  const [unreadMessages, setUnreadMessages] = useState<any[]>([]);
  const selectClient = (clientId) => setSelectedClientId(clientId);
  const deSelectClient = () => setSelectedClientId(undefined);
  const getClient = (id) => data.find((client) => client.Id === id);
  const [searchInput, setSearchInput] = useState('');
  const [selectedFilter, setFilterOption] = useState(FilterOption.All);
  const [selectedTeamFilter, setTeamFilterOption] = useState(TeamFilterOption.All);
  const threads = useSelector(getUnreadThreads);

  useEffect(() => {
    dispatch(getClientsSearchInstancesEffect());
  }, [dispatch]);
  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const fetchAgentUnreadMessagesByClients = async (clientIds) => {
    if (clientIds.length) {
      setUnreadMessagesLoading(true);
      try {
        const response = await getAgentUnreadMessagesByClients({
          clientIds: clientIds.join(),
        });
        if (response.status === 200) {
          setUnreadMessages(response.data.result);
        }
      } catch (e) {
        setUnreadMessagesLoading(false);
      }
      setUnreadMessagesLoading(false);
    }
  };

  const getUnreadMessageCountByClientId = (clientId: number): number => {
    if (!unreadMessages.length) return 0;
    const client = unreadMessages.find((message) => message.ClientId === clientId);
    if (client) return client.UnreadMessageCount;
    return 0;
  };

  useEffect(() => {
    if (!isLoading && data?.length) {
      const clientIds = data.map((item) => item.Id);
      fetchAgentUnreadMessagesByClients(clientIds);
    }
  }, [data, isLoading, threads]);

  const searchedData = data.filter((client) => {
    const matchesSearch = `${client.FirstName} ${client.LastName}`
      .toLowerCase()
      .includes(searchInput.toLowerCase());

    if (selectedFilter === FilterOption.All) {
      return matchesSearch;
    } else if (selectedFilter === FilterOption.Active) {
      return matchesSearch && client.ActiveInstances > 0;
    } else if (selectedFilter === FilterOption.Inactive) {
      return matchesSearch && client.ActiveInstances === 0;
    }
  });
  const sortedData = searchedData.sort((a, b) => {
    let nameA = `${a.FirstName} ${a.LastName}`.toLowerCase();
    let nameB = `${b.FirstName} ${b.LastName}`.toLowerCase();

    if (sort.fields[0] === 'A to Z') {
      return nameA.localeCompare(nameB);
    } else if (sort.fields[0] === 'Z to A') {
      return nameB.localeCompare(nameA);
    } else {
      return nameA.localeCompare(nameA); //Unchanged i.e display as it is. returns 0
    }
  });

  return (
    <div className={styles.ClientSearches}>
      <div className={styles.headerButtonsLeft}>
        <ActiveInactive setFilter={setTeamFilterOption} filterOptions={TeamFilterOption} team />
      </div>
      <div className={styles.headerButtonsRight}>
        <Sort
          offsetLeft={-60}
          sortCases={[
            { label: 'Client Name (A to Z)', value: 'A to Z' },
            { label: 'Client Name (Z to A)', value: 'Z to A' },
          ]}
          alphabeticalSort={true}
        />
        <ActiveInactive setFilter={setFilterOption} filterOptions={FilterOption} />
      </div>

      <div className={styles.content}>
        <div className={styles.widthStyle}>
          <div className={styles.searchBarContainer}>
            <AntInput
              className={`${styles.barStyles} ${styles.searchBar}`}
              placeholder="Search clients..."
              prefix={
                <div className={classNames(styles.searchIcon, styles.icon)}>
                  <SearchGlass color="#747475" />
                </div>
              }
              onChange={handleSearchChange}
            />
          </div>

          {!selectedClientId ? <p className={styles.title}>{null}</p> : null}
          <Row gutter={[24, 24]}>
            {isLoading || unreadMessagesLoading ? (
              <Spinner loaderClassName={styles.loader} />
            ) : (
              <>
                {sortedData.map((client) => (
                  <Col xs={24} md={24} lg={24} xxl={24}>
                    <ClientCard
                      key={client.Id}
                      client={client}
                      onSelect={selectClient}
                      unreadMessageCount={getUnreadMessageCountByClientId(client.Id)}
                    ></ClientCard>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </div>
      </div>
      <Footer className={styles.footer} />
    </div>
  );
};
