import { FormBox } from './components/FormBox';

import styles from './styles.module.scss';

export const LinkedForms = ({ forms, taskId, transactionId }) => (
  <div className={styles.linkedFormsContainer}>
    {forms.map((form) => (
      <FormBox form={form} taskId={taskId} transactionId={transactionId} />
    ))}
  </div>
);
