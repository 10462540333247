import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, ErrorModal, ErrorModalTextProps } from 'components-antd';
import { FormUpdateModal } from 'pages/FormBuilder/components/FormUpdateModal';
import { FORM_QUESTION_TYPE, FORM_STATUS_TYPE, FORM_UPDATE_STATUS } from 'app-constants';
import { getQuoteFormMetaSelect, getSmartFormMetaSelect } from 'store/selectors/formBuilder';
import {
  getQuoteFormBuilderEffect,
  getSmartFormBuilderEffect,
  requestUpdateQuoteFormEffect,
  requestUpdateSmartFormEffect,
} from 'store/effects/formBuilder';
import { link } from 'settings/navigation/link';
import { FormUpdateStatusVariants } from 'types';
import { AutoSave } from './components';

import styles from './styles.module.scss';

interface FormPublishProps {
  previewForm: boolean;
  smartForm?: boolean;
}

export const FormPublish = ({ previewForm, smartForm = false }: FormPublishProps) => {
  const {
    status,
    disabled,
    questions,
    folderName,
    tags,
    isFavorite,
    formName,
    Id,
    formId,
    locations: formLocations,
    teams = [],
  } = smartForm ? useSelector(getSmartFormMetaSelect) : useSelector(getQuoteFormMetaSelect);

  const { categories } = smartForm ? { categories: [] } : useSelector(getQuoteFormMetaSelect);
  const { users, disassociatedFields, formCategoryTags, views } = !smartForm
    ? { users: [], disassociatedFields: [], formCategoryTags: [], views: [] }
    : useSelector(getSmartFormMetaSelect);

  const dispatch = useDispatch();
  const history = useHistory();

  const [error, setError] = useState<ErrorModalTextProps>();

  const [confirmationType, setConfirmationType] = useState<FormUpdateStatusVariants | undefined>();

  const checkDirtyData = () => !!window.onbeforeunload;

  const saveForm = () => {
    return new Promise((res) => {
      let data = {
        questions: disabled ? undefined : questions,
        folderName,
        tags,
        isFavorite,
        formName,
        Id,
      } as any;

      if (smartForm) {
        data = {
          ...data,
          disassociatedFields,
          views,
        };
        dispatch(
          requestUpdateSmartFormEffect(data, () => {
            res(null);
          }),
        );
      } else {
        dispatch(
          requestUpdateQuoteFormEffect(data, () => {
            res(null);
          }),
        );
      }
    });
  };

  const handleFormUpdate = async (type: FormUpdateStatusVariants) => {
    if (checkDirtyData()) {
      await saveForm();
    }

    setConfirmationType(type);
  };

  const handleCallBack = (res, err, customError) => {
    switch (confirmationType) {
      case FORM_UPDATE_STATUS.Version:
        setConfirmationType(undefined);
        if (res?.data?.value?.formVersion?.Id) {
          if (smartForm) {
            history.push(
              link.toDashboardServiceDirectoryFormsSmartForm(res?.data?.value?.formVersion?.Id),
            );
          } else {
            history.push(
              link.toDashboardServiceDirectoryFormsQuoteForm(res?.data?.value?.formVersion?.Id),
            );
          }
        }
        break;
      case FORM_UPDATE_STATUS.Copy:
        setConfirmationType(undefined);
        if (res?.data?.value?.newVersionId) {
          if (smartForm) {
            history.push(
              link.toDashboardServiceDirectoryFormsSmartForm(res?.data?.value?.newVersionId),
            );
          } else {
            history.push(
              link.toDashboardServiceDirectoryFormsQuoteForm(res?.data?.value?.newVersionId),
            );
          }
        }
        break;
      case FORM_UPDATE_STATUS.Unpublish:
        setConfirmationType(undefined);
        if (smartForm) {
          dispatch(getSmartFormBuilderEffect({ versionId: Id }));
        } else {
          dispatch(getQuoteFormBuilderEffect({ versionId: Id }));
        }
        break;
      case FORM_UPDATE_STATUS.Publish:
        if (!customError) setConfirmationType(undefined);
        if (smartForm) {
          dispatch(getSmartFormBuilderEffect({ versionId: Id }));
        } else {
          dispatch(getQuoteFormBuilderEffect({ versionId: Id }));
        }
        break;

      default:
        break;
    }
  };

  const handlePreview = () => {
    history.push(
      smartForm
        ? link.toDashboardServiceDirectoryFormsSmartFormPreview(Id)
        : link.toDashboardServiceDirectoryFormsQuoteFormPreview(Id),
    );
  };

  const handleExitTest = () => {
    history.push(
      smartForm
        ? link.toDashboardServiceDirectoryFormsSmartForm(Id)
        : link.toDashboardServiceDirectoryFormsQuoteForm(Id),
    );
  };

  const getError = (variant = '') => {
    let errorText = '';
    let errorPoints: string[] = [];

    if (!questions?.length) {
      errorText = `Please add questions to ${variant.toLowerCase()} the form`;
    } else {
      const missingTitle: string[] = [];

      for (const ques of questions) {
        if (!ques.Title) {
          missingTitle.push(ques.Name ? ques.Name : 'Unnamed Question');
        }
      }
      if (missingTitle.length) {
        errorText = 'Question Title is missing in the following questions:';
        errorPoints = missingTitle;
      } else if (smartForm) {
        const missingFields: string[] = [];
        for (const ques of questions) {
          if (!ques.Fields?.length) {
            missingFields.push(ques.Name ? ques.Name : 'Unnamed Question');
          }
        }
        if (missingFields?.length) {
          errorText = 'PDF Field(s) are missing in the following questions:';
          errorPoints = missingFields;
        } else {
          const missingRolesInSignature = questions.filter(
            (question) =>
              question.Type === FORM_QUESTION_TYPE.Signature && !question.FormRole?.length,
          );
          if (missingRolesInSignature?.length) {
            errorText = 'Role Permission for signatures are in the following questions:';
            errorPoints = missingRolesInSignature.map((question) =>
              question.Name ? question.Name : 'Unnamed Question',
            );
          }
        }
      }
    }

    if (errorText || errorPoints?.length) {
      return {
        errorText,
        errorPoints,
      };
    } else {
      return null;
    }
  };

  const handlePreviewPublishClick = async (type: 'Preview' | 'Publish') => {
    await saveForm();

    const err = getError(type);
    if (err) {
      setError(err);
    } else {
      if (type === 'Preview') handlePreview();
      else handleFormUpdate(FORM_UPDATE_STATUS.Publish);
    }
  };

  return (
    <div className={styles.quotePublishContainer}>
      <ErrorModal open={!!error} error={error} onCancel={() => setError(undefined)} />
      {previewForm ? (
        <Button className={styles.button} variant="secondary-inverted" onClick={handleExitTest}>
          Exit Test
        </Button>
      ) : (
        <>
          <FormUpdateModal
            formId={formId}
            versionId={Id}
            variant={confirmationType}
            open={!!confirmationType}
            formName={formName}
            hideModal={() => setConfirmationType(undefined)}
            callBack={handleCallBack}
            selectedTags={tags}
            categories={categories}
            formLocations={formLocations}
            users={users}
            formCategoryTags={formCategoryTags}
            smartForm={smartForm}
            teams={teams}
          />
          <AutoSave className={styles.button} smartForm={smartForm} />
          <Button
            variant="secondary-inverted"
            className={styles.button}
            data-testid="quote-copy-button"
            onClick={() => handleFormUpdate(FORM_UPDATE_STATUS.Copy)}
          >
            Copy
          </Button>

          {(status === FORM_STATUS_TYPE.InActive || status === FORM_STATUS_TYPE.Active) && (
            <Button
              variant="secondary-inverted"
              className={styles.button}
              data-testid="quote-new-version-button"
              onClick={() => handleFormUpdate(FORM_UPDATE_STATUS.Version)}
            >
              Version +
            </Button>
          )}

          <Button
            variant="secondary-inverted"
            className={styles.button}
            data-testid="quote-preview-button"
            onClick={() => handlePreviewPublishClick('Preview')}
          >
            Preview
          </Button>

          {status === FORM_STATUS_TYPE.Draft || status === FORM_STATUS_TYPE.InActive ? (
            <Button
              variant="secondary"
              className={styles.button}
              data-testid="quote-publish-button"
              onClick={() => handlePreviewPublishClick('Publish')}
            >
              Publish
            </Button>
          ) : (
            <Button
              variant="secondary"
              className={styles.button}
              data-testid="quote-inactive-button"
              onClick={() => setConfirmationType(FORM_UPDATE_STATUS.Unpublish)}
            >
              Unpublish
            </Button>
          )}
        </>
      )}
    </div>
  );
};
