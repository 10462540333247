import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import { Autocomplete, Select as SelectComponents, Spinner } from 'components';
import { Navigation } from '../../components';

import { getTransactionSelector } from 'store/selectors/transaction';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';

import { getUserRolesMapSelector } from 'store/selectors/user';

import styles from './styles.module.scss';
import { IconText } from '../components/IconText';
import { InformationText } from '../components/InformationText';
import { EditLinkDuration, EditOfferDeadline } from '../components';
import { useFetchTeamList } from '../../hooks/useFetchTeamList';
import { useScrollableRef } from '../../hooks/useScrollableRef';
import { Select } from 'components-antd';

export const ListingAgent = ({
  onNext,
  stageIndex,
  lastStageIndex,
  onUpdate,
  onFinish,
  data,
  setCurrentStageIndex,
  stagesStep,
  participantList,
}) => {
  const { ListingAgentName, ListingAgentEmail } = data;

  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);
  const { isAgent } = useSelector(getUserRolesMapSelector);

  const { transaction } = useSelector(getTransactionSelector);

  const [listingAgentName, setListingAgentName] = useState<string>(ListingAgentName || '');
  const [listingAgentEmail, setListingAgentEmail] = useState<string>(ListingAgentEmail || '');

  const { loading, teamMembers, teamMembersPayload } = useFetchTeamList(participantList);
  const { scrollableDivRef } = useScrollableRef();

  useEffect(() => {
    if (!ListingAgentName && !ListingAgentEmail) {
      if (isAgent && !isTeamAgentActive) {
        const { FirstName, LastName } = transaction.Creator;
        const agentName = `${FirstName} ${LastName}`;
        setListingAgentName(agentName.trim());
        setListingAgentEmail(transaction.Creator.Email);
      } else if (isAgent && isTeamAgentActive && transaction?.OfferLink?.ListingAgentName) {
        setListingAgentName(transaction?.OfferLink?.ListingAgentName);
        if (
          transaction?.OfferLink?.ListingAgentName &&
          !transaction?.OfferLink?.ListingAgentEmail
        ) {
          const targetMember = teamMembersPayload.find(
            (mem) =>
              mem.FirstName + (mem.LastName ? ' ' + mem.LastName : '') ===
              transaction?.OfferLink?.ListingAgentName,
          );
          if (targetMember) {
            setListingAgentEmail(targetMember.Email);
          }
        } else setListingAgentEmail(transaction?.OfferLink?.ListingAgentEmail);
      }
    }
  }, [isAgent, isTeamAgentActive, teamMembersPayload]);

  const onSubmit = () => {
    let agentName = '';
    if (isAgent && isTeamAgentActive) agentName = listingAgentName;
    else if (isAgent) {
      const { FirstName, LastName } = transaction.Creator;

      agentName = `${FirstName} ${LastName || ''}`.trim();
    }

    const body = {
      ListingAgentName: agentName,
      ListingAgentEmail: listingAgentEmail,
    };

    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(lastStageIndex);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const isLoading = isTeamAgentActive && loading;
  return (
    <div className={styles.listingAgent}>
      <div className={styles.contentWrap}>
        <EditLinkDuration
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.LinkDuration)}
        />
        <EditOfferDeadline
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.OfferDeadline)}
        />

        <IconText
          variant={'user-bg'}
          text={'Who should be displayed as the listing agent on the submission page?'}
          className={styles.iconText}
        />

        <Row className={styles.content}>
          {isLoading ? (
            <Spinner loaderClassName={styles.loadingSpinner} />
          ) : (
            isAgent &&
            isTeamAgentActive && (
              <Col span={24} className={styles.agentQuestionContainer}>
                <div className={styles.agentQuestion}>
                  <h4 className={styles.question}>Listing Agent</h4>
                  <div className={styles.questionSelector}>
                    <Select
                      mode="multiple"
                      className={`${styles.inputHolder} mosaikSelect`}
                      options={teamMembers as any}
                      maxTagCount={'responsive'}
                      value={
                        listingAgentName ||
                        (transaction.OfferLink?.ListingAgentName
                          ? transaction.OfferLink?.ListingAgentName
                          : undefined)
                      }
                      onChange={(value) => {
                        if (value.length) {
                          const selectedMember = value.pop();
                          setListingAgentName(selectedMember);
                          const targetMember = teamMembersPayload.find(
                            (mem) =>
                              mem.FirstName + (mem.LastName ? ' ' + mem.LastName : '') ===
                              selectedMember,
                          );
                          if (targetMember) {
                            setListingAgentEmail(targetMember.Email);
                          }
                        } else {
                          setListingAgentName(transaction.OfferLink?.ListingAgentName || '');
                          setListingAgentEmail(transaction.OfferLink?.ListingAgentEmail || '');
                        }
                      }}
                      tagRender={(props) => <div>{props.value}</div>}
                      placeholder={'Select listing agent'}
                      getPopupContainer={(node) => node}
                      popupClassName={`${styles.inputHolderDropdown} mosaikSelectDropdown`}
                    />
                  </div>
                </div>
              </Col>
            )
          )}
        </Row>

        <div ref={scrollableDivRef}>
          <InformationText text={'The listing agent will be displayed on the submission portal.'} />
        </div>
      </div>

      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onFinish={onFinish}
        disabled={!listingAgentName}
      />
    </div>
  );
};
