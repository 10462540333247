import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getLoadingPartnerData } from 'store/selectors/singlePartner';
import { requestGetPartnerByIdEffect } from 'store/effects/singlePartner';

import { Wrapper, PageNotFound } from 'components';

import PartnerProfile from '../PartnerProfileLegacy';

const RequestPartner = ({ partnerId }) => {
  const dispatch = useDispatch();
  const { isData, isPending, isIdle, metaId } = useSelector(getLoadingPartnerData);

  useEffect(() => {
    if (metaId !== partnerId && !isPending) {
      dispatch(requestGetPartnerByIdEffect({ partnerId }));
    }
  }, [partnerId, dispatch, isPending, metaId]);

  const isLoading = metaId !== partnerId || isPending || isIdle;

  return <Wrapper isPending={isLoading}>{isData ? <PartnerProfile /> : <PageNotFound />}</Wrapper>;
};

RequestPartner.propTypes = {
  partnerId: PropTypes.string.isRequired,
};

export default RequestPartner;
