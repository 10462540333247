import {
  GET_ALL_FORM_PROCESS,
  GET_ALL_TRANSACTION_FORM_PROCESS,
  GET_ALL_UNLINK_FORM_PROCESS,
  GET_ARCHIVE_FORMS,
} from 'app-constants';
import { apiServer } from 'settings/web-services/api';

export const getAllFormProcess = (cfg) =>
  apiServer.get(GET_ALL_FORM_PROCESS, { params: cfg?.filters ?? cfg });

export const getAllUnlinkFormProcess = (cfg) =>
  apiServer.get(GET_ALL_UNLINK_FORM_PROCESS, { params: cfg?.filters ?? cfg });

export const getAllTransactionFormProcess = ({ transactionId }: { transactionId: string }) =>
  apiServer.get(GET_ALL_TRANSACTION_FORM_PROCESS(transactionId));

export const getArchiveForms = (cfg) =>
  apiServer.get(GET_ARCHIVE_FORMS, { params: cfg?.filters ?? cfg });

export const previewFormDocument = ({
  documentVaultUUID,
  ...config
}: {
  documentVaultUUID: string;
}) => apiServer.get(`/documentVault/${documentVaultUUID}/document`, config);
