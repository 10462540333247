import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { Select } from 'components-antd';

import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { PENDING } from 'settings/constants/apiState';

import { OnboardingContinueButton } from '../../agent/components/OnboardingContinueButton';
import { OnboardingSkipButton } from '../../agent/components/OnboardingSkipButton';
import { getListOfAvailableMlsServices, MlsService } from 'api/mls';
import { showErrorMessage } from 'helpers';
import { getUserDataSelector } from 'store/selectors/user';
import { setSelectedMlsServicesForAgent } from 'api/user';
import { setAgentSelectedMlsServiceAction } from 'store/actions/user';

import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Question } from 'pages/RequestQuote/components';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

import styles from './styles.module.scss';

const MlsInfo = (props) => {
  const { Controls, onNext } = props;
  const [mlsServices, setMlsServices] = useState([]);
  const [selectedMlsServices, setSelectedMlsServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(getUserDataSelector);
  const { onBoarding } = useSelector(getOnBoardingData);
  const isPending = onBoarding.state === PENDING;
  const dispatch = useDispatch();

  const handleChange = (values) => setSelectedMlsServices(values);

  const selectProps = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    onChange: handleChange,
    placeholder: 'Select Item...',
    maxTagCount: 2,
    getPopupContainer: (triggerNode) => triggerNode,
    popupClassName: styles.onBoardingDropdownPopUp,
  };

  const onSubmit = async () => {
    setLoading(true);
    const { data } = await setSelectedMlsServicesForAgent({
      SelectedMlsServices: selectedMlsServices.map((mlsId) => +mlsId),
    });
    if (data.success) dispatch(setAgentSelectedMlsServiceAction(data.result.SelectedMlsServices));
    setLoading(false);
    onNext();
  };

  const prePopulateSelectedServices = () => {
    if (user.Agent?.SelectedMlsServices?.length) {
      setSelectedMlsServices(user.Agent.SelectedMlsServices.map((m) => m.MlsId));
    }
  };

  const handleError = () =>
    showErrorMessage("Cannot load list of available MLS'. Try to reload the page");

  const fetchListOfMlsServices = async () => {
    try {
      const { data } = await getListOfAvailableMlsServices();
      if (data?.success) {
        setMlsServices(
          data.result
            .filter((m) => m.IsVisible)
            .map((m) => ({
              value: m.Id,
              label: m.Name,
            })),
        );
        prePopulateSelectedServices();
      } else {
        handleError();
      }
    } catch (err) {
      handleError();
    }
  };

  useEffect(() => {
    fetchListOfMlsServices();
  }, []);

  return (
    <OnBoardingWrapper isPending={isPending}>
      <Controls onNext={onNext} className={styles.controls} variant="lightFull" />

      <OnBoardingContainer>
        <Question>Which MLS&apos;s are you with?</Question>
        <AnswersContainer>
          <Select
            className={styles.onBoardingSelect}
            {...selectProps}
            options={mlsServices}
            value={selectedMlsServices}
          />
        </AnswersContainer>
        <ButtonsContainer>
          <OnboardingContinueButton
            isPending={isPending}
            disabled={loading || !selectedMlsServices.length}
            testid="next"
            htmlType="submit"
            className={classNames(styles.submit, {
              [styles.active]: loading || !selectedMlsServices.length,
            })}
            onClick={onSubmit}
          />
          <OnboardingSkipButton testid="skip" className={styles.skip} onClick={onNext} />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

export default MlsInfo;
