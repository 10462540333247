import { Icons } from 'pages/FormProcess/Icons';
import styles from './styles.module.scss';
import { useState } from 'react';
import { NewFormModal } from 'pages/Workshop/Forms/components/NewFormModal';
import classNames from 'classnames';

export const AddForm = (props) => {
  const [openFormModal, setOpenFormModal] = useState(false);

  const closeForm = () => {
    setOpenFormModal(false);
  };

  const openForm = () => {
    setOpenFormModal(true);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div className={classNames(styles.addNewIcon, props.className)} onClick={openForm}>
        <Icons variant="AddForm" />
        <div className={styles.label}>Add</div>
      </div>

      <NewFormModal isOpen={openFormModal} onClose={closeForm} dynamicForm />
    </div>
  );
};
