import React, { useCallback, useState } from 'react';
import { Modal } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Button as AntdButton } from 'components-antd';
import { Button } from 'components';
import { updateStandardSubscriptionEffect } from 'store/effects/subscription';
import { appSetConnectionTypeEffect, userGetDataOnBackgroundEffect } from 'store/effects';
import { getUserDataSelector } from 'store/selectors/user';
import { getInvoiceDataOverviewSelector } from 'store/selectors/subscription';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';
import { appManageClientDrawerAction } from 'store/actions/app';
import { PlanTeamDetails } from 'api/subscription';

import styles from './styles.module.scss';
import { ModalClose } from 'components/Icons';

type StepProps = {
  handleNextStep: () => void;
};

const StepOne: React.FC<StepProps & { onClose: () => void }> = ({ handleNextStep, onClose }) => {
  const { FirstName } = useSelector(getUserDataSelector);
  const invoiceDataOverview = useSelector(getInvoiceDataOverviewSelector);
  const [isPending, setIsPending] = useState(false);

  const nextBillingDate = invoiceDataOverview?.NextInvoice?.Date;

  const dispatch = useDispatch();
  const handleDowngradePlan = () => {
    setIsPending(true);
    dispatch(
      updateStandardSubscriptionEffect({ requestedSeatsCount: 2 }, () => {
        dispatch(
          userGetDataOnBackgroundEffect({}, { showError: false }, () => {
            handleNextStep();
            setIsPending(false);
          }),
        );
      }),
    );
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        {FirstName}, we&apos;re sorry to hear that you&apos;re considering downgrading your plan.
      </div>

      <div className={styles.plans}>
        <div className={styles.left}>
          <div>Current Plan:</div>
          <div>Team</div>
        </div>
        <div className={styles.right}>
          <div>New Plan:</div>
          <div>Solo</div>
        </div>
      </div>
      <div className={styles.subHeader}>Here are the next steps:</div>
      <div className={styles.info}>
        Your plan will be downgraded from Team to Solo on <br />
        <strong>{moment(nextBillingDate).format('MMMM DD, YYYY')}</strong>.
      </div>
      <div className={styles.info}>
        You will not be able to invite more than one other team member to your account after this
        date.
      </div>
      <div className={styles.contact}>Are you sure you want to proceed with downgrading?</div>
      <div className={styles.btnContainer}>
        <AntdButton variant="secondary" className={styles.cancelBtn} onClick={onClose}>
          Never Mind
        </AntdButton>
        <Button
          title="Confirm"
          className={styles.confirmBtn}
          onClick={handleDowngradePlan}
          isPending={isPending}
        />
      </div>
    </div>
  );
};

const StepTwo: React.FC<
  StepProps & {
    closeModal: () => void;
    openUpgradeModal: () => void;
  }
> = ({ handleNextStep, closeModal, openUpgradeModal }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.content}>
      <div className={styles.header} style={{ width: '400px' }}>
        You&apos;ve successfully downgraded to the Solo plan.
      </div>
      <div className={classNames(styles.info)} style={{ width: '460px' }}>
        Your access has been updated to reflect the features available in the <strong>Solo</strong>{' '}
        plan.
      </div>
      <div className={styles.btnContainer}>
        <AntdButton
          variant="primary"
          className={styles.confirmBtn}
          onClick={() => {
            openUpgradeModal();
            closeModal();
          }}
        >
          Upgrade to Team
        </AntdButton>
      </div>
    </div>
  );
};

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  teamPlanData: PlanTeamDetails;
  openUpgradeModal: () => void;
};

export const DowngradeModal: React.FC<Props> = ({
  closeModal,
  isOpen,
  teamPlanData,
  openUpgradeModal,
}) => {
  const [currentStep, setCurrentStep] = useState<1 | 2>(1);
  const [isPending, setIsPending] = useState(false);
  const { FirstName } = useSelector(getUserDataSelector);

  const actualSeats = teamPlanData.BilledSeatsCount - teamPlanData.UnusedSeatsCount;

  const handleNextStep = () => {
    setCurrentStep((prev) => {
      if (prev === 1) {
        return 2;
      }
      return 1;
    });
  };
  const dispatch = useDispatch();
  const handleClientsClick = useCallback(() => {
    setIsPending(true);
    dispatch(appSetConnectionTypeEffect({ type: AGENT_CONNECTIONS.Team }));
    dispatch(appManageClientDrawerAction(true));
    closeModal();
    setIsPending(false);
  }, [dispatch]);

  if (actualSeats >= 3) {
    return (
      <Modal
        open={isOpen}
        centered
        maskStyle={{
          background: 'rgba(255, 255, 255, 0.60)',
          backdropFilter: ' blur(12.5px)',
        }}
        bodyStyle={{
          borderRadius: '24px',
          background: 'var(--REBRAND-Secondary-White, #FFF)',
          boxShadow: '0px 24px 80px 0px rgba(0, 0, 0, 0.20)',
        }}
        wrapClassName={styles.wrapper}
        closable
        footer={null}
        className={styles.modalWrapper}
        destroyOnClose
        onCancel={closeModal}
        closeIcon={<ModalClose />}
      >
        <div className={classNames(styles.content, styles.width_460)}>
          <div className={styles.header}>
            {`${FirstName}, we're sorry to hear that you're considering downgrading your plan.`}
          </div>
          <div className={styles.info}>
            You&apos;ve <strong>{actualSeats} seats</strong> active and solo plan includes only{' '}
            <strong>2 seats</strong>. You will need to{' '}
            <span className={styles.red}>deactivate</span> the other seats before proceeding.
          </div>
          <div className={styles.contact}>
            If you have any questions or need assistance, please contact our support team at{' '}
            <a href="mailto: support@mosaik.io">support@mosaik.io</a>
          </div>
          <div className={styles.btnContainer}>
            <AntdButton
              variant="secondary"
              className={styles.cancelBtn}
              onClick={() => {
                closeModal();
              }}
            >
              Never Mind
            </AntdButton>
            <Button
              title="Remove Members"
              className={styles.confirmBtn}
              onClick={handleClientsClick}
              isPending={isPending}
            />
          </div>
        </div>
      </Modal>
    );
  }
  return (
    <Modal
      open={isOpen}
      centered
      maskStyle={{
        background: 'rgba(255, 255, 255, 0.60)',
        backdropFilter: ' blur(12.5px)',
      }}
      bodyStyle={{
        borderRadius: '24px',
        background: 'var(--REBRAND-Secondary-White, #FFF)',
        boxShadow: '0px 24px 80px 0px rgba(0, 0, 0, 0.20)',
      }}
      wrapClassName={styles.wrapper}
      closable
      footer={null}
      className={styles.modalWrapper}
      destroyOnClose
      onCancel={closeModal}
      closeIcon={<ModalClose />}
    >
      {currentStep === 1 && <StepOne handleNextStep={handleNextStep} onClose={closeModal} />}
      {currentStep === 2 && (
        <StepTwo
          handleNextStep={handleNextStep}
          closeModal={closeModal}
          openUpgradeModal={openUpgradeModal}
        />
      )}
    </Modal>
  );
};
