/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { Label, Description, Items } from '../components';

import styles from './styles.module.scss';

const Must = (props) => {
  const { listItems, className, onChangePref } = props;

  if (!listItems || !listItems.length) return null;

  return (
    <div testid="needs_block" className={classNames(styles.mustWrapper, className)}>
      <Label>Needs</Label>
      <Description>Features that are requirements or "must-haves".</Description>
      <Items
        list={listItems}
        onChangePref={onChangePref}
        currentImportance={SEARCH_CRITERIA_IMPORTANCE.MUST}
      />
    </div>
  );
};

Must.propTypes = {
  className: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string),
  onChangePref: PropTypes.func.isRequired,
};

Must.defaultProps = {
  className: '',
  listItems: undefined,
};

export default Must;
