export const getAvailableDayAndTime = (showingAvailability) => {
  let DayTimeList: any[] = [];
  if (showingAvailability?.length) {
    showingAvailability.forEach((element) => {
      element.DaysOfWeek.forEach((item) => {
        const obj = {
          Day: item,
          StartTime: element.StartTime,
          EndTime: element.EndTime,
          AutomaticApproval: element.AutomaticApproval,
          SequentialApproval: element.SequentialApproval,
          AvailabilityId: element.Id,
          ShowingId: element.ShowingId,
          MinimumNotice: element.MinimumNotice,
        };
        DayTimeList.push(obj);
      });
    });
  }
  return DayTimeList;
};

export const getBookedDayAndTime = (booked) => {
  let DayTimeList: any[] = [];
  if (booked?.length) {
    booked.forEach((element) => {
      const obj = {
        BookedDate: element.AppointmentDate,
        BookedStartTime: element.AppointmentStartTime,
        BookedEndTime: element.AppointmentEndTime,
      };
      DayTimeList.push(obj);
    });
  }
  return DayTimeList;
};

export const getTimeZoneList = () => {
  return [
    {
      title: 'CST',
      Desc: 'Central Time Zone',
    },
    {
      title: 'EST',
      Desc: 'Eastern Time Zone',
    },
  ];
};
