import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { LocationService } from 'services';
import { setActiveTabEffect } from 'store/effects';
import { propertiesTabsIds, propertiesTabs } from 'settings/constants/properties';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { AGENT, CLIENT } from 'settings/constants/roles';
import { Tabs } from 'components';
import PropsHeader from './PropsHeader';
import CompareList from './ComparesList';
import Back from './Back';
import ClientFavoritesProps from './AgentClientFavoritesProps';
import {
  resetFeedMapViewAction,
  resetPropertyToScrollInList,
  setFeedPrefsAction,
} from 'store/actions/feed';
import { getClientActiveSearchInstancesByContext } from 'store/selectors/clientInstances';
import { propertyStatus } from 'settings/constants/properties';
import SearchInstanceTab from './Tabs/SearchInstance/index';
import { SubFilters } from './SubFilters';

import styles from './styles.module.scss';

const locationService = new LocationService();

const Properties = ({ id }) => {
  const dispatch = useDispatch();
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  const clientActiveInstances = isClient && useSelector(getClientActiveSearchInstancesByContext);
  const [hideActions, setHideActions] = useState(false);
  const [showSubFilter, setShowSubFilter] = useState(true);

  const location = useLocation();
  const query = locationService.setLocation(location).getQuery();

  useEffect(() => {
    return () => {
      dispatch(resetFeedMapViewAction());
      dispatch(resetPropertyToScrollInList());
    };
  }, []);

  const getSearchInstanceTabs = () =>
    clientActiveInstances.map((instance) => ({
      id: instance.Id,
      label: instance.Name,
      Component: SearchInstanceTab,
      testid: 'tab_search_instance',
    }));

  const getTabs = () => {
    if (isAgent) {
      return propertiesTabs[AGENT];
    }

    if (isClient) {
      return [...propertiesTabs[CLIENT], ...getSearchInstanceTabs()];
    }
  };

  const getActiveTab = (tabIndex) => {
    const { id: tabId } = getTabs()[query?.activeTab - 1 || tabIndex] || {};
    setShowSubFilter(
      ![propertiesTabsIds.clientFavorites, propertiesTabsIds.watchlist].includes(tabId),
    );
    if (tabId === propertiesTabsIds.agentClients) {
      setHideActions(true);
    } else {
      setHideActions(false);
    }

    // Fetch "Active" status properties by default for all sub-feeds
    dispatch(setFeedPrefsAction({ Status: [propertyStatus.active] }));
    dispatch(setActiveTabEffect(tabId));
  };

  const propsHeader = () => (
    <PropsHeader
      className={isClient ? styles.clientPropHeader : ''}
      name={query?.clientId ? <Back /> : ''}
      hideActions={hideActions && !query?.clientId}
    />
  );

  const getContent = () => {
    if (query?.clientId) {
      return <ClientFavoritesProps />;
    }

    return (
      <Tabs
        enableQueryParams
        tabs={getTabs()}
        wrapperClassName={classNames(styles.tabsWrapper, {
          [styles.clientTabsWrapper]: isClient && showSubFilter,
        })}
        tabsClassName={classNames(styles.tabs, {
          [styles.clientTabs]: isClient && showSubFilter,
        })}
        tabClassName={styles.tab}
        activeTabClassName={styles.tabActive}
        getActiveTab={getActiveTab}
        showSelectDropdown={true}
        actions={propsHeader}
        dropdownClassName={styles.feedDropdown}
        contentWrapperClassName={styles.contentWrapper}
        contentClassName={styles.content}
      />
    );
  };

  return (
    <div testid="feed_wrapper" id={id} className={classNames(styles.propsWrapper)}>
      <SubFilters showSubFilter={showSubFilter} />
      {getContent()}
    </div>
  );
};

Properties.propTypes = {
  id: PropTypes.string,
};

Properties.defaultProps = {
  id: '',
};

export default Properties;
