import { Button, Modal } from 'components-antd';
import { Checkbox } from 'antd';

import styles from './style.module.scss';
import { ICON_VARIANT_TYPE, Icons } from 'pages/Workshop/Forms/Icons';
import { Fragment, useState } from 'react';
import classNames from 'classnames';
import { SingleCheckbox } from './SingleCheckbox';
import { Spinner } from 'components';

import { usePrepareDocumentDetails } from './hooks/usePrepareDocumentDetails';
import { useDownloadPDF } from './hooks/useDownloadPDF';

export const DownloadFilesModal = (props) => {
  const {
    onClose,
    isOpen,
    documents = [],
    auditTrailDocuments = [],
    formProcessPublicId,
    anonymousUserDetails = {},
    isCompleted = true,
    downloadedFileName = '',
  } = props;

  const [documentsChecked, setDocumentsChecked] = useState(false);
  const [certificateChecked, setCertificateChecked] = useState(false);

  const [downloadAllInOneFile, setDownloadAllInOneFile] = useState(false);

  const hasAuditTrails = auditTrailDocuments.length > 0;

  const { allFileText, allDocumentText, allCertificateText } = usePrepareDocumentDetails({
    preparedDocuments: [],
    documents,
    auditTrailDocuments,
  });

  const { downloading, downloadCombinedFile, downloadFiles } = useDownloadPDF({
    anonymousUserDetails,
    isCompleted,
    downloadedFileName,
    formProcessPublicId,
  });

  const onAllFormSelect = (checked) => {
    setDocumentsChecked(checked);
    hasAuditTrails && setCertificateChecked(checked);
  };

  const onAllDocumentCheck = (checked) => {
    setDocumentsChecked(checked);
  };

  const onCertificatesChecked = (checked) => {
    setCertificateChecked(checked);
  };

  const onAllFormInOneFileSelect = (checked) => {
    setDownloadAllInOneFile(checked);
    !documentsChecked && setDocumentsChecked(checked);

    certificateChecked && setCertificateChecked(false);
  };

  const onDownload = () => {
    if (downloadAllInOneFile) {
      downloadCombinedFile(formProcessPublicId, documents);
    } else if (documentsChecked && certificateChecked) {
      downloadFiles([...documents, ...auditTrailDocuments]);
    } else if (documentsChecked) {
      downloadFiles(documents);
    } else if (certificateChecked) {
      downloadFiles(auditTrailDocuments);
    }
  };

  const resetAll = () => {
    setCertificateChecked(false);
    setDocumentsChecked(false);
    setDownloadAllInOneFile(false);
  };

  const disabledButton = downloading || (!documentsChecked && !certificateChecked);

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={() => {
        onClose();
        resetAll();
      }}
      maskClosable={false}
      destroyOnClose
      className={styles.downloadFilesModal}
      closeIcon={<Icons variant={ICON_VARIANT_TYPE.MODAL_CLOSE} />}
      title={'Download File(s)'}
    >
      <div className={styles.downloadFilesModalContent}>
        <div className={classNames(styles.text, styles.blackText)}>
          Select which files you want to download:
        </div>

        <div
          className={classNames(styles.filesCheckboxes, {
            [styles.allFormInFile]: downloadAllInOneFile,
          })}
        >
          <SingleCheckbox
            checked={
              !downloadAllInOneFile &&
              documentsChecked &&
              (hasAuditTrails ? certificateChecked : true)
            }
            onChange={onAllFormSelect}
            text={'All'}
            subText={allFileText}
            textClass={styles.formName}
            subTextClass={styles.fileCount}
            disabled={downloadAllInOneFile}
            className={styles.text}
          />

          <SingleCheckbox
            checked={documentsChecked}
            onChange={onAllDocumentCheck}
            text={'Document'}
            subText={allDocumentText}
            textClass={styles.formName}
            subTextClass={styles.fileCount}
            disabled={downloadAllInOneFile}
            className={styles.text}
          />

          {hasAuditTrails && (
            <SingleCheckbox
              checked={certificateChecked}
              onChange={onCertificatesChecked}
              text={'Audit Trail'}
              subText={allCertificateText}
              textClass={styles.formName}
              subTextClass={styles.fileCount}
              disabled={downloadAllInOneFile}
              className={styles.text}
            />
          )}
        </div>

        <div className={styles.lineSeparator} />

        <SingleCheckbox
          checked={downloadAllInOneFile}
          onChange={onAllFormInOneFileSelect}
          text={'Combine all PDFs into a single file'}
          className={classNames(styles.text, styles.combineAllCheckbox)}
        />

        <Button
          variant="secondary"
          className={classNames(styles.downloadButton, {
            [styles.disabledButton]: disabledButton,
          })}
          onClick={onDownload}
          disabled={disabledButton}
          loading={downloading}
        >
          Download
        </Button>
      </div>
    </Modal>
  );
};
