import TextArea from 'antd/lib/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { Button, DraggableMenuList, Popover, Sidebar, Skeleton } from 'components-antd';
import { QuestionListPopover } from 'pages/FormBuilder/components';
import QuestionIcon from 'pages/FormBuilder/Icons/QuestionIcon';
import {
  getQuoteFormCurrentQuestionSelect,
  getQuoteFormMetaSelect,
} from 'store/selectors/formBuilder';
import { PENDING } from 'settings/constants/apiState';
import { QuestionType } from 'types';
import { arrayMove } from 'helpers';
import {
  updateQuoteFormMetaEffect,
  updateQuoteFormQuestionEffect,
} from 'store/effects/formBuilder';
import { FORM_STATUS_TYPE } from 'app-constants';

import styles from './styles.module.scss';

export const QuoteLeftSidebar = () => {
  const [newQuestionVisibility, setNewQuestionVisibility] = useState<undefined | boolean>(
    undefined,
  );

  const { state, disabled, status, activeQuestionIndex, questions } =
    useSelector(getQuoteFormMetaSelect);
  const question = useSelector(getQuoteFormCurrentQuestionSelect);
  const dispatch = useDispatch();

  const RenderQuestionItem = ({ Type, UUID, Name }: QuestionType, index) => {
    return {
      content: (
        <div className={styles.questionMenuItem}>
          <QuestionIcon variant={Type} className={styles.questionIcon} />
          {disabled ? (
            <span className={styles.questionName}>{Name}</span>
          ) : (
            <TextArea
              maxLength={64}
              className={styles.questionName}
              defaultValue={Name}
              autoSize
              onBlur={(e) => updateQuestionName(e.target.value?.trim())}
              data-testid={`quote-question-name-${index}`}
            />
          )}
        </div>
      ),
      key: UUID,
    };
  };

  const updateQuestionName = (name: string) => {
    if (question && name !== question?.Name) {
      const updatedQuestion = {
        ...question,
        Name: name,
      };
      dispatch(updateQuoteFormQuestionEffect(updatedQuestion));
    }
  };

  const updateQuestions = (data) => {
    const source = data?.source?.index || 0;
    const destination = data.destination?.index || 0;
    if (source !== destination) {
      const updatedQuestions: QuestionType[] = arrayMove([...questions], source, destination);
      dispatch(updateQuoteFormMetaEffect({ questions: updatedQuestions }));
      if (question) {
        const updatedIndex = updatedQuestions.findIndex((el) => el.UUID === question.UUID);
        if (updatedIndex !== activeQuestionIndex) {
          dispatch(
            updateQuoteFormMetaEffect({
              activeQuestionIndex: updatedIndex,
            }),
          );
        }
      }
    }
  };

  const setActiveQuestion = (index) => {
    if (activeQuestionIndex !== index)
      dispatch(
        updateQuoteFormMetaEffect({
          activeQuestionIndex: index,
        }),
      );
  };

  return (
    <Sidebar width={240} className={styles.quoteLeftSidebar}>
      <div className={styles.addQuestionContainer}>
        <span className={styles.questionText}>Entries</span>
        {status === FORM_STATUS_TYPE.Draft && (
          <Popover
            getPopupContainer={(trigger) => trigger}
            placement="bottomRight"
            content={<QuestionListPopover handleVisibility={setNewQuestionVisibility} />}
            trigger="click"
            open={newQuestionVisibility}
          >
            <Button
              variant="primary"
              disabled={state === PENDING || disabled}
              data-testid="quote-add-question-button"
              onClick={() => setNewQuestionVisibility(undefined)}
            >
              + Add
            </Button>
          </Popover>
        )}
      </div>
      <Skeleton
        showSkeleton={state === PENDING}
        rowCount={questions?.length || 10}
        avatar={{ shape: 'square' }}
      >
        {questions.length ? (
          <DraggableMenuList
            activeItem={question?.UUID}
            className={styles.dragMenuList}
            items={questions.map(RenderQuestionItem)}
            onDragEnd={updateQuestions}
            disabled={disabled}
            onDraggableClick={setActiveQuestion}
          />
        ) : (
          !disabled && (
            <div className={styles.noEntries} data-testid="quote-no-questions">
              You have no entries. Click “+ Add” to create a new entry.
            </div>
          )
        )}
      </Skeleton>
    </Sidebar>
  );
};
