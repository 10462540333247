import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ActionsIconGray from '../../ActiveSearches/Icons/ActionsIconGray';

import styles from './styles.module.scss';

const TransactionBlockActions = ({ onSearch }) => {
  return (
    <div>
      <div
        testid="search_actions_menu"
        className={styles.actionBtn}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ActionsIconGray />

        <div testid="search_actions" className={classNames(styles.actionList)}>
          {onSearch && (
            <>
              <button testid="start_search" onClick={onSearch} className={styles.actionListBtn}>
                <span>Open </span>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

TransactionBlockActions.propTypes = {
  onSearch: PropTypes.func,
};

export default TransactionBlockActions;
