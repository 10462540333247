import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useLongPress } from 'hooks';

import { AGENT } from 'settings/constants/roles';

import { getPersonalAgentUnarchivedSortedThreads } from 'store/selectors/sockets/threads';
import { getClientThreadAgent, getUserId } from 'store/selectors/user';

import { UserMessage, UsersMessage } from 'components';

import ActionsList from 'components/Drawers/components/ActionsList';

import {
  getArchiveThreadOption,
  getAddParticipantThreadOption,
  getOpenChatThreadOption,
  openChatThreadHandler,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import styles from './styles.module.scss';

const emptyThread = {};

const MessagesDrawerMyAgent = () => {
  const dispatch = useDispatch();
  const [actionsPosition, setActionPosition] = useState(null);
  const [actions, setActions] = useState([]);

  const agent = useSelector(getClientThreadAgent);
  const userId = useSelector(getUserId);
  const threads = useSelector(getPersonalAgentUnarchivedSortedThreads);

  const openChatHandler = useCallback(
    () =>
      openChatThreadHandler(dispatch, {
        participants: [
          {
            id: agent?.Id,
            name: `${agent?.FirstName || ''} ${agent?.LastName || ''}`,
            value: agent?.Id,
            role: AGENT,
            avatarUrl: agent?.AvatarUrl,
            firstName: agent?.FirstName,
            lastName: agent?.LastName,
          },
        ],
      }),
    [dispatch, agent],
  );

  const onLongPress = (event, [thread]) => {
    setActionPosition({ x: event?.clientX, y: event?.clientY });
    setActions([
      ...getArchiveThreadOption(dispatch, thread, () => {
        setActionPosition(null);
      }),
    ]);
  };
  const onPress = (event, [thread]) => {
    openChatThreadHandler(dispatch, { threadId: thread.Id });
  };

  const longPressEvent = useLongPress(onLongPress, onPress);

  return (
    <div testid="my_agent" className={styles.myAgent}>
      <div className={styles.myAgentTitle}>My Agent</div>
      <div className={styles.myAgentTitleData}>
        {threads.length ? (
          threads.map((thread) => (
            <UsersMessage
              key={thread.Id}
              thread={thread}
              {...longPressEvent(thread)}
              userId={userId}
            />
          ))
        ) : (
          <UserMessage user={agent} thread={emptyThread} openHandler={openChatHandler} />
        )}
        <ActionsList actions={actions} position={actionsPosition} />
      </div>
    </div>
  );
};

export default MessagesDrawerMyAgent;
