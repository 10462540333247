import { useState } from 'react';

import { Button, Col, Form, Popover, RangePicker, Row } from 'components-antd';
import { Icons } from 'pages/Workshop/Activity/Icons';
import { AgentDocumentSearchCriteria } from 'types';
import { get } from 'lodash-es';
import moment from 'moment';

import styles from './styles.module.scss';

interface FilterPopoverProps {
  searchCriteria: AgentDocumentSearchCriteria;
  handleSearchCriteria: (data?: AgentDocumentSearchCriteria) => void;
  setSearchCriteria: (data: AgentDocumentSearchCriteria) => void;
  handleReset: () => void;
}

export const FilterPopover = ({
  handleSearchCriteria,
  searchCriteria,
  setSearchCriteria,
  handleReset,
}: FilterPopoverProps) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean | undefined>(undefined);

  const handlePopoverState = (state: boolean) => {
    setOpen(state);
    setTimeout(() => setOpen(undefined), 0);
  };

  const renderTitle = (
    <div className={styles.filterHeader}>
      <Icons variant={'filter'} className={styles.filterIcon} />
      <span className={styles.titleText}>Filters</span>
    </div>
  );
  const submitForm = (values) => {
    const params = {
      ...searchCriteria,
      dateRange: values.createdDate
        ? {
            startDate: get(values, 'createdDate.0')
              ? moment(get(values, 'createdDate.0')).startOf('day').toDate()
              : undefined,
            endDate: get(values, 'createdDate.1')
              ? moment(get(values, 'createdDate.1')).endOf('day').toDate()
              : undefined,
          }
        : {},
    } as AgentDocumentSearchCriteria;
    handleSearchCriteria(params);
    handlePopoverState(false);
  };

  const onReset = () => {
    form.resetFields();
    handleReset();
    handlePopoverState(false);
  };

  const Separator = <span className={styles.separator}>to</span>;

  const handleDisabledDate = (date) => moment(date).isAfter(moment());

  const renderFields = () => {
    return (
      <div className={styles.popoverContent}>
        <Row>
          <Col span={24}>{renderTitle}</Col>
        </Row>
        <Form form={form} onFinish={submitForm} layout="vertical">
          <Row className={styles.filterPopoverContainer} justify="start" align="stretch">
            <Col span={24}>
              <Form.Item label="Date Range" name="createdDate" className={styles.formItem}>
                <RangePicker
                  className={styles.rangePicker}
                  separator={Separator}
                  disabledDate={handleDisabledDate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={6}>
              <Button
                variant="default"
                className={styles.resetButton}
                title="Reset"
                onClick={onReset}
              >
                <span className={styles.resetText}>Reset</span>
              </Button>
            </Col>
            <Col span={12} />
            <Col span={6}>
              <Button
                htmlType="submit"
                variant="secondary"
                className={styles.submitButton}
                title="Done"
              >
                <span className={styles.btnText}>Done</span>
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <Popover
      content={renderFields}
      trigger="click"
      placement="bottomRight"
      className={styles.filterPopover}
      overlayClassName={styles.documentsFilterPopover}
      open={open}
    >
      <div className={styles.filterBtn}>
        <Icons variant={'filterSecondary'} className={styles.filterIcon} />
      </div>
    </Popover>
  );
};
