import { useState, Fragment } from 'react';
import classNames from 'classnames';

import { Tooltip } from 'components-antd';
import styles from './styles.module.scss';

interface NamesContainerType {
  names: string[];
  maxDisplayCount: number;
  tooltipClass?: string;
  isWidthRestrict?: boolean;
}

export const NamesContainer = ({
  names,
  maxDisplayCount,
  tooltipClass,
  isWidthRestrict,
}: NamesContainerType) => {
  const [open, setOpen] = useState(false);

  const Content = ({ array }) =>
    array?.map((item, i) => (
      <div key={i} className={classNames({ [styles.nameText]: isWidthRestrict })}>
        {item}
      </div>
    ));

  return (
    <Fragment>
      {names?.length > maxDisplayCount ? (
        <>
          <Content array={[...names.slice(0, maxDisplayCount)]} />
          <div
            className={classNames(styles.namesTooltip, tooltipClass)}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          >
            <Tooltip
              title={<Content array={[...names.slice(maxDisplayCount)]} />}
              placement="bottom"
              open={open}
            >
              + <span className={styles.text}>{names.length - maxDisplayCount} more</span>
            </Tooltip>
          </div>
        </>
      ) : (
        <Content array={names ?? []} />
      )}
    </Fragment>
  );
};
