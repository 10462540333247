import classNames from 'classnames';

import styles from './styles.module.scss';

export const TaskModalHeader = ({ isTransactionTask, isTemplate, isNew, showSubheading }) => {
  return (
    <div className={styles.taskModalHeader}>
      {!isTransactionTask && (
        <div>
          {isTemplate ? (
            <>
              <h1 className={classNames(styles.heading, styles.headingTemplate)}>
                {isNew ? 'New' : 'Edit'} Template
              </h1>
              <p className={styles.subHeading}>Task</p>
            </>
          ) : (
            <>
              <h1 className={styles.heading}>{isNew ? 'New Task' : 'Edit Task'}</h1>
              {showSubheading && <p className={styles.subHeading}>Task</p>}
            </>
          )}
        </div>
      )}
    </div>
  );
};
