/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Optional } from 'pages/Templates/Transactions/components/TemplateTabs/components/TransactionPanes/Optional';

import styles from './styles.module.scss';

const InputLabel = ({
  id,
  className,
  altClassName,
  label,
  altLabel,
  showAsterisk,
  showOptional,
}) => {
  if (!label && !altLabel) return null;
  return (
    <label className={classNames(styles.label, className)} htmlFor={id}>
      <span>
        {label || ''}
        {showAsterisk ? <span className={styles.required}> *</span> : ''}
        {showOptional && (
          <>
            {' '}
            <Optional />
          </>
        )}
      </span>
      {altLabel && <span className={classNames(styles.altLabel, altClassName)}>{altLabel}</span>}
    </label>
  );
};

InputLabel.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  altClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  altLabel: PropTypes.string,
  showAsterisk: PropTypes.bool,
  showOptional: PropTypes.bool,
};

InputLabel.defaultProps = {
  id: undefined,
  className: '',
  altClassName: '',
  altLabel: '',
  showAsterisk: false,
  showOptional: false,
};

export default InputLabel;
