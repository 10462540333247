import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get, orderBy } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetTransactionTemplateAction,
  requestPostTransactionTemplateAction,
  requestPutTransactionTemplateAction,
  resetTransactionTemplateDataAction,
} from 'store/actions/template';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetTransactionTemplateAction]: (state, { payload }) => {
      let data = get(payload, 'data.result', initialData.data);

      if (data?.TimelineTemplate?.Milestones?.length) {
        data = {
          ...data,
          TimelineTemplate: {
            ...data.TimelineTemplate,
            Milestones: orderBy(data.TimelineTemplate.Milestones, 'Offset', ['asc']),
          },
        };
      }

      return {
        ...state,
        state: payload.state,
        data,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestPutTransactionTemplateAction]: (state, { payload }) => {
      const result = get(payload, ['data', 'result']);
      return {
        ...state,
        state: payload.state,
        data: result || state.data,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestPostTransactionTemplateAction]: (state, { payload }) => {
      const result = get(payload, ['data', 'result']);
      return {
        ...state,
        state: payload.state,
        data: result || state.data,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [resetTransactionTemplateDataAction]: (state) => ({
      ...state,
      data: null,
      meta: {},
      state: IDLE,
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
