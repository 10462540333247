import {
  cancelSubscription,
  convertToTeamPlan,
  downgradeFromTeamPlan,
  fetchActiveSubscriptionData,
  getAvailableSubscriptionPlans,
  getCoupon,
  getCurrentPlanLevel,
  getImplementationPlan,
  getInvoiceDataOverview,
  reactivateSubscription,
  subscribeLegacyPlan,
  subscribeStandardPlan,
  updatePlanIntervalLegacy,
  updateStandardSubscription,
  updateNewAgent,
  updatePayment,
  fetchActiveSubscriptionTeamData,
} from 'api/subscription';
import {
  requestCancelSubscriptionAction,
  requestConvertToTeamPlanAction,
  requestDowngradeFromTeamPlanAction,
  requestFetchActiveSubscriptionDataAction,
  requestGetAvailableSubscriptionPlansAction,
  requestGetCouponAction,
  requestGetCurrentPlaneLevelAction,
  requestGetImplementationPlansAction,
  requestGetInvoiceDataOverviewAction,
  requestReactivateSubscriptionAction,
  requestSubscribeLegacyAction,
  requestSubscribeStandardAction,
  requestUpdatePlanLegacyAction,
  requestUpdateStandardSubscriptionAction,
  updateNewAgentAction,
  requestPaymentUpdateAction,
  requestActiveSubscriptionTeamDataAction,
} from 'store/actions/subscription';
import Api from 'store/effects/core/api';

export const requestGetAvailableSubscriptionPlansEffect = (cb) => {
  const sendRequest = Api.execBase({
    action: requestGetAvailableSubscriptionPlansAction,
    method: getAvailableSubscriptionPlans,
  });

  return sendRequest(null, {}, cb);
};

export const requestGetImplementationPlansEffect = (cb) => {
  const sendRequest = Api.execBase({
    action: requestGetImplementationPlansAction,
    method: getImplementationPlan,
  });

  return sendRequest(null, {}, cb);
};

export const requestGetCouponEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestGetCouponAction,
    method: getCoupon,
  });

  return dispatch(sendRequest(cfg, {}, cb));
};

export const requestSubscribeStandardEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestSubscribeStandardAction,
    method: subscribeStandardPlan,
  });

  return dispatch(sendRequest(cfg, {}, cb));
};

export const requestSubscribeLegacyEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestSubscribeLegacyAction,
    method: subscribeLegacyPlan,
  });

  return dispatch(sendRequest(cfg, {}, cb));
};

export const requestGetInvoiceDataOverviewEffect = (cb, options) => {
  let sendRequest = Api.execBase({
    action: requestGetInvoiceDataOverviewAction,
    method: getInvoiceDataOverview,
  });

  if (options?.silent) {
    sendRequest = Api.execResult({
      action: requestGetInvoiceDataOverviewAction,
      method: getInvoiceDataOverview,
    });
  }

  return sendRequest(null, {}, cb);
};

export const requestFetchActiveSubscriptionDataEffect = (cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestFetchActiveSubscriptionDataAction,
    method: fetchActiveSubscriptionData,
  });

  dispatch(sendRequest({}, {}, cb));
};

export const requestConvertToTeamPlanEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestConvertToTeamPlanAction,
    method: convertToTeamPlan,
  });

  dispatch(sendRequest(cfg, {}, cb));
};

export const updateNewAgentEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: updateNewAgentAction,
    method: updateNewAgent,
  });

  dispatch(sendRequest(cfg, {}, cb));
};

export const requestDowngradeFromTeamPlanEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestDowngradeFromTeamPlanAction,
    method: downgradeFromTeamPlan,
  });

  dispatch(sendRequest(cfg, {}, cb));
};

export const getCurrentPlanLevelEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestGetCurrentPlaneLevelAction,
    method: getCurrentPlanLevel,
  });

  return dispatch(sendRequest(cfg, {}, cb));
};

export const cancelSubscriptionEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestCancelSubscriptionAction,
    method: cancelSubscription,
  });

  return dispatch(sendRequest(cfg, {}, cb));
};

export const reactivateSubscriptionEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestReactivateSubscriptionAction,
    method: reactivateSubscription,
  });

  return dispatch(sendRequest(cfg, {}, cb));
};

export const updateStandardSubscriptionEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestUpdateStandardSubscriptionAction,
    method: updateStandardSubscription,
  });

  return dispatch(sendRequest(cfg, {}, cb));
};

export const requestUpdatePlanLegacyEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestUpdatePlanLegacyAction,
    method: updatePlanIntervalLegacy,
  });

  return dispatch(sendRequest(cfg, {}, cb));
};

export const requestPaymentUpdateEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestPaymentUpdateAction,
    method: updatePayment,
  });

  return dispatch(sendRequest(cfg, {}, cb));
};

export const requestActiveSubscriptionTeamDataEffect = (cfg, opt) => (dispatch) => {
  let sendRequest = Api.execBase({
    action: requestActiveSubscriptionTeamDataAction,
    method: fetchActiveSubscriptionTeamData,
  });

  if (opt?.silent) {
    sendRequest = Api.execResult({
      action: requestActiveSubscriptionTeamDataAction,
      method: fetchActiveSubscriptionTeamData,
    });
  }

  return dispatch(sendRequest(cfg, opt));
};
