import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { LocationService } from 'services';
import BaseNotification from '../../../BaseNotification';

import styles from './styles.module.scss';

const locationSrv = new LocationService();

const ClientPreferencesUpdated = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  locationSrv.setLocation(location);

  const { ClientId, ClientFirstName, ClientLastName, ClientAvatarUrl } = Payload || {};

  const Name = `${ClientFirstName} ${ClientLastName}`;

  const onClickHandler = () => {
    dispatch(openNotificationsDrawerEffect({ open: false }));

    const query = locationSrv.getQuery();
    history.replace(
      locationSrv.setQuery({ ...query, profileClientId: ClientId, profileDrawerActiveTab: 2 }),
    );
  };

  return (
    <div className={classNames(styles.notificationClientPrefsUpdated, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        groupType={groupType}
        avatarUrl={ClientAvatarUrl}
        avatarPlaceholder={convertNameToAvatarPlaceholder(Name)}
        severityLevel={SeverityLevel}
        onClick={onClickHandler}
      />
    </div>
  );
};

ClientPreferencesUpdated.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    ClientFirstName: PropTypes.string,
    ClientLastName: PropTypes.string,
    ClientAvatarUrl: PropTypes.string,
    ClientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

ClientPreferencesUpdated.defaultProps = {
  className: '',
};

export default ClientPreferencesUpdated;
