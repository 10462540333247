import { textColor } from 'app-constants';
import PropTypes from 'prop-types';

export const Icons = ({ testid, className, variant, onClick, color, svgClass }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.UP_ARROW: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path
              d="M16 21.834V10.1673"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21 15.166L16 10.166"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 15.166L16 10.166"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DOWN_ARROW: {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" height="32" width="32">
            <g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 32 32)">
              <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
              <path
                d="M16 21.834V10.1673"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21 15.166L16 10.166"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 15.166L16 10.166"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        );
      }
      case Icons.CALENDAR: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path
              d="M20.3753 11H11.6253C10.8199 11 10.167 11.6529 10.167 12.4583V21.2083C10.167 22.0138 10.8199 22.6667 11.6253 22.6667H20.3753C21.1807 22.6667 21.8337 22.0138 21.8337 21.2083V12.4583C21.8337 11.6529 21.1807 11 20.3753 11Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.917 9.75V12.25"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.083 9.75V12.25"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.167 15.166H21.8337"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.RADIO_ON: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="23"
              height="23"
              rx="11.5"
              fill="#FF576D"
              stroke="#FF576D"
            />
            <g clipPath="url(#clip0_36894_195855)">
              <path
                d="M7.33203 11.9993L10.6654 15.3327L17.332 8.66602"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_36894_195855">
                <rect width="16" height="16" fill="white" transform="translate(4 4)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.ACCEPTED_RADIO: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="23"
              height="23"
              rx="11.5"
              fill="#04A451"
              stroke="#04A451"
            />
            <g clipPath="url(#clip0_40873_265564)">
              <path
                d="M7.33252 12.0013L10.6659 15.3346L17.3325 8.66797"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_40873_265564">
                <rect width="16" height="16" fill="white" transform="translate(4 4)" />
              </clipPath>
            </defs>
          </svg>
        );
      }

      case Icons.GREEN_TICK_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="16" cy="16" r="16" fill="#00B152" />
            <g clipPath="url(#clip0_35186_38981)">
              <path
                d="M10.1656 16.0026L14.3323 20.1693L22.6657 11.8359"
                stroke="#04A451"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_35186_38981">
                <rect width="20" height="20" fill="white" transform="translate(6 6)" />
              </clipPath>
            </defs>
          </svg>
        );
      }

      case Icons.RED_CROSS_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#FFF2F3" />
            <path
              d="M11.5804 20.418L20.4193 11.5791"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M20.4196 20.418L11.5807 11.5791"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        );
      }

      case Icons.RED_CALENDAR_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#FFF2F3" />
            <path
              d="M20.375 11H11.625C10.8195 11 10.1666 11.6529 10.1666 12.4583V21.2083C10.1666 22.0138 10.8195 22.6667 11.625 22.6667H20.375C21.1804 22.6667 21.8333 22.0138 21.8333 21.2083V12.4583C21.8333 11.6529 21.1804 11 20.375 11Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9166 9.75V12.25"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.0834 9.75V12.25"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.1666 15.168H21.8333"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.GREEN_CALENDAR_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="16" cy="16" r="16" fill="#00B152" />
            <path
              d="M20.3743 11H11.6243C10.8189 11 10.166 11.6529 10.166 12.4583V21.2083C10.166 22.0138 10.8189 22.6667 11.6243 22.6667H20.3743C21.1798 22.6667 21.8327 22.0138 21.8327 21.2083V12.4583C21.8327 11.6529 21.1798 11 20.3743 11Z"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.916 9.75V12.25"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.084 9.75V12.25"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.166 15.168H21.8327"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.GREY_TICK_CIRCLE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" fill="white" />
            <rect x="1" y="1" width="22" height="22" rx="11" stroke="#D6D6D7" strokeWidth="2" />
            <path
              d="M7 12.3333L10.3333 15.6667L17 9"
              stroke="#D6D6D7"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.GREY_DOT: {
        return (
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="12" height="12" rx="6" fill="#E8E9EA" />
          </svg>
        );
      }
      case Icons.CROSS_CIRCLE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" fill="white" />
            <rect x="1" y="1" width="22" height="22" rx="11" stroke="#D6D6D7" strokeWidth="2" />
            <path d="M8 16L16 8.00003" stroke="#D6D6D7" strokeWidth="2" strokeLinecap="round" />
            <path
              d="M16 16L8.00003 8.00003"
              stroke="#D6D6D7"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        );
      }
      case Icons.REJECTED_RADIO: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" fill="white" />
            <rect x="1" y="1" width="22" height="22" rx="11" stroke="#EC455E" strokeWidth="2" />
            <path d="M8 16L16 8.00003" stroke="#EC455E" strokeWidth="2" strokeLinecap="round" />
            <path
              d="M16 16L8.00003 8.00003"
              stroke="#EC455E"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        );
      }
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className}>
      {renderIcon()}
    </div>
  );
};

Icons.CALENDAR = 'calendar';
Icons.UP_ARROW = 'up-arrow';
Icons.DOWN_ARROW = 'down-arrow';
Icons.RADIO_ON = 'radio-on';
Icons.ACCEPTED_RADIO = 'accepted';
Icons.GREEN_TICK_BG = 'green-tick-bg';
Icons.RED_CROSS_BG = 'red-cross-bg';
Icons.RED_CALENDAR_BG = 'red-calendar-bg';
Icons.GREEN_CALENDAR_BG = 'green-calendar-bg';
Icons.GREY_TICK_CIRCLE = 'grey-tick-circle';
Icons.GREY_DOT = 'grey-dot';
Icons.CROSS_CIRCLE = 'cross-circle';
Icons.REJECTED_RADIO = 'rejected';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
  svgClass: PropTypes.string,
};

Icons.defaultProps = {
  svgClass: '',
  className: '',
  variant: '',
  color: '',
  testid: undefined,
  onClick: () => {},
};
