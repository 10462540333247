import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { prepareData } from './helpers';
import { ValidationSchema } from './validation';

import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { onBoardingSignUpSearchEffect } from 'store/effects';
import { addSearchInstance } from 'store/effects/clientInstances';

import { PropertyType, Select } from 'components';
import { useEffect } from 'react';
import { useDebounce } from 'hooks/use-debounce';

const PropertyTypeForm = (props) => {
  const { stageIndex } = props;
  const dispatch = useDispatch();
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);

  const formik = useFormik({
    initialValues: {
      propertyType: defaultClientSearch?.HomeType || [],
    },
    validationSchema: ValidationSchema,
    onSubmit(values) {
      const cfg = prepareData(values, stageIndex, defaultClientSearch);
      dispatch(
        onBoardingSignUpSearchEffect(cfg, {}, (err) => {
          if (!err) {
            dispatch(addSearchInstance({ data: cfg.DefaultPropertySearchPreferences }));
          }
        }),
      );
    },
  });

  const debounced = useDebounce(formik.values?.propertyType);

  useEffect(() => {
    if (formik.dirty) {
      formik.handleSubmit();
    }
  }, [debounced]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <PropertyType
        label="Property Type"
        value={formik.values?.propertyType || []}
        onChange={(type) => formik.setFieldValue('propertyType', type)}
        type={'dropdown'}
        variant={Select.LIGHT_ROUND}
        error={formik.touched.propertyType ? formik.errors.propertyType : ''}
      />
    </form>
  );
};

export default PropertyTypeForm;
