import { useHistory } from 'react-router-dom';
import { Icons } from 'pages/Workshop/Icons';
import { Button } from 'components-antd';
import { Download } from 'components/Icons';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getScheduledTours } from 'store/selectors/tour';
import { useMemo } from 'react';
import { TourStatus } from 'app-constants/enums/tours';
import moment from 'moment';
import ItineraryPDFDownloadLink from 'pages/Workshop/Tours/components/ItineraryPDF';
import { routes } from 'settings/navigation/routes';

export const LocalHeader = ({ isArchive, setIsArchive }) => {
  const history = useHistory();
  const { tours } = useSelector(getScheduledTours);
  const upcomingTours = useMemo(
    () =>
      tours.filter(
        (tour) => tour.Status === TourStatus.Approved && moment(tour.ScheduleDateTime) > moment(),
      ),
    [tours],
  );
  return (
    <div className={styles.propertiesHeader}>
      <h3 className={styles.header}>Tours</h3>
      <div className={styles.headerActions}>
        {!isArchive && (
          <Button className={styles.haButton}>
            <ItineraryPDFDownloadLink isClient={true} tours={upcomingTours}>
              <Download className={styles.icon}> </Download>
            </ItineraryPDFDownloadLink>
          </Button>
        )}
        <Button
          className={isArchive ? styles.activeButton : styles.haButton}
          onClick={() => setIsArchive(!isArchive)}
        >
          <Icons
            className={styles.btnIcon}
            variant={Icons.ARCHIVE}
            color={isArchive ? '#FFFFFF' : '#303030'}
          />
        </Button>
        <Button className={styles.closeBtn} onClick={() => history.push(routes.feed)}>
          <Icons className={styles.btnIcon} variant={Icons.CLOSE} color={'#303030'} />
        </Button>
      </div>
    </div>
  );
};
