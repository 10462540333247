import { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  LISTED_STATUS,
  projectStatusesOptions,
  transactionStatusesOptions,
} from 'settings/constants/transaction';
import { transactionRoles } from 'settings/constants/roles';
import checkMark from 'images/check-green.png';

import styles from './styles.module.scss';

const List = forwardRef((props, ref) => {
  const {
    className,
    onChange,
    open,
    representingRoles,
    skipListed,
    isProject = false,
    selectedValue,
  } = props;

  const options = useMemo(() => {
    if (isProject) return projectStatusesOptions;
    return representingRoles.includes(transactionRoles.BUYER) || skipListed
      ? transactionStatusesOptions.filter(
          ({ value }) =>
            ![
              LISTED_STATUS.PreListing,
              LISTED_STATUS.OfficeExclusive,
              LISTED_STATUS.ComingSoon,
              LISTED_STATUS.ActiveListing,
              LISTED_STATUS.OnHold,
              LISTED_STATUS.Expired,
            ].includes(value),
        )
      : transactionStatusesOptions;
  }, [representingRoles, isProject, selectedValue]);

  if (!open) return null;

  return (
    <div testid="statuses_list" ref={ref} className={classNames(styles.listWrapper, className)}>
      <ul>
        {options.map((status) => (
          <li onClick={() => onChange(status)} key={status?.name} className={styles.item}>
            {status?.name}
            {status.value === selectedValue && <img src={checkMark} />}
          </li>
        ))}
      </ul>
    </div>
  );
});

List.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  open: PropTypes.bool,
  representingRoles: PropTypes.arrayOf(PropTypes.string),
  skipListed: PropTypes.bool,
};

List.defaultProps = {
  className: '',
  onChange: () => {},
  open: false,
  representingRoles: [],
  skipListed: false,
};

export default List;
