import React, { FC, useCallback } from 'react';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

import { useAutoFocus } from 'hooks';

import styles from './styles.module.scss';

export const SearchFilter: FC<FilterDropdownProps> = ({
  confirm,
  visible,
  clearFilters,
  selectedKeys,
  setSelectedKeys,
}) => {
  const ref = useAutoFocus(visible);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedKeys([e.target.value]);
    },
    [setSelectedKeys],
  );
  const handleConfirm = () => {
    if (!selectedKeys?.[0] && clearFilters) {
      clearFilters();
      confirm();
    } else {
      confirm();
    }
  };
  const handleReset = () => {
    if (!clearFilters) return;
    clearFilters();
    confirm();
  };
  return (
    <div className={styles.filterContainer}>
      <div className={styles.inputContainer}>
        <Input
          ref={ref}
          allowClear
          onPressEnter={handleConfirm}
          value={selectedKeys?.[0] || ''}
          onChange={handleChange}
          addonAfter={<SearchOutlined />}
          placeholder="Search"
        />
      </div>
      <div className={styles.buttonsContainer}>
        <Button onClick={handleReset}>Reset</Button>
        <Button type="primary" onClick={handleConfirm}>
          Filter
        </Button>
      </div>
    </div>
  );
};
