import { triggerMockEvent } from 'api/admin';
import { showSuccessMessage, showErrorMessage } from 'helpers';
import { useState, useCallback } from 'react';

const useTriggerMockEvent = () => {
  const [isLoading, setIsLoading] = useState(false);

  const triggerEvent = useCallback(async ({ topic, subEvent, eventName }) => {
    setIsLoading(true);
    try {
      await triggerMockEvent({ topic, subEvent, eventName });
      showSuccessMessage('Event triggered successfully');
    } catch (err) {
      showErrorMessage(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { triggerEvent, isLoading };
};

export default useTriggerMockEvent;
