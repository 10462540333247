// eslint-disable-next-line
import { apiServer } from 'settings/web-services/api';

export function getAllTransactionTemplates(cfg) {
  return apiServer.get('/transaction/templates/transaction-templates', { params: cfg });
}

export function getTransactionTemplateById({ id }) {
  return apiServer.get(`/transaction/templates/transaction-templates/${id}`);
}

export function postTransactionTemplate(cfg) {
  return apiServer.post('/transaction/templates/transaction-templates', cfg);
}

export function putTransactionTemplate(cfg, { id }) {
  return apiServer.put(`/transaction/templates/transaction-templates/${id}`, cfg);
}

export function deleteTransactionTemplate({ id }) {
  return apiServer.delete(`/transaction/templates/transaction-templates/${id}`);
}

export const editTransactionTemplate = (templateId: number, cfg: { IsEditing: boolean }) => {
  return apiServer.patch(`/transaction/templates/transaction-template/${templateId}/edit`, cfg);
};

export const copyMasterTransactionTemplates = ({ teamId }) => {
  return apiServer.get(`/transaction/templates/copy-transaction-templates/${teamId}`);
};

export const getMasterTransactionTemplates = () => {
  return apiServer.get(`/transaction/templates/master`);
};

export const getTeamTransactionTemplates = (teamId) => {
  return apiServer.get(`/transaction/templates/team/${teamId}`);
};
