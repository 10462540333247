import { handleActions } from 'redux-actions';
import { SOCKET_STATE } from 'settings/constants/sockets';
import { socketsChangeStatusAction, socketsResetAction } from 'store/actions/sockets';

const initialData = {
  connection: SOCKET_STATE.IDLE,
  error: null,
};

export default handleActions(
  {
    [socketsChangeStatusAction]: (state, { payload }) => ({
      ...state,
      connection: payload?.status,
      error: payload?.error,
    }),
    [socketsResetAction]: () => initialData,
  },
  initialData,
);
