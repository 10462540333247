import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Title } from 'components-antd';
import ActionButtons from '../ActionButtons/ActionButtons';
import { Plus } from 'components/Icons';
import { OfferStatus, OfferLinkStatus } from 'types/offers';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';
import {
  expiryTypes,
  getExpiration,
  getIterationStatusDate,
  getOfferExpiry,
  getOfferStatuses,
  getStatusForStages,
  keywordStatus,
  prepareInitialIterations,
  renderOfferExpiry,
} from './helper';

import { cloneDeep } from 'lodash-es';
import { Timeline } from 'antd';
import { CardBox } from 'pages/ExternalOffer/OfferSubmission/components/CardBox';
import { Icons } from '../Icons';
import { InboundIteration } from '../InboundIteration';

export const InboundOutBoundContainer = ({ offer, handleActionClick, LinkUUID, linkStatus }) => {
  const { OfferIteration } = offer;

  const [offerIteration, setOfferIterations] = useState<any>([]);
  const [activeTab, setActiveTab] = useState(0);

  const history = useHistory();

  const getIterationHeader = (status: string, count: string) => {
    if (status !== 'Initial') {
      return `Counter #${count}`;
    } else {
      return `Initial Offer`;
    }
  };

  useEffect(() => {
    setActiveTab(OfferIteration?.length > 1 ? 0 : OfferIteration?.length - 1);
    setOfferIterations(OfferIteration);
  }, [offer]);

  const getDotIcon = ({ item, isActiveTab }) => {
    const { IterationStatus, initialIteration } = item;
    const { Accepted } = OfferStatus;

    let iconVariant = '';
    if (initialIteration) {
      iconVariant = item.dotIcon;
    } else if (IterationStatus === Accepted && isActiveTab) {
      iconVariant = 'accepted';
    } else if (isActiveTab) {
      iconVariant = 'radio-on';
    }

    return iconVariant ? <Icons variant={iconVariant} className={styles.customIcon} /> : null;
  };

  const renderIterationSteps = () => {
    let iterations = cloneDeep(offerIteration);

    iterations = iterations.length === 1 ? prepareInitialIterations(iterations[0]) : iterations;

    const { Accepted } = OfferStatus;

    return (
      <Timeline className={styles.iterationsTimeline} reverse={offerIteration.length > 1}>
        {iterations?.map((item, index) => {
          const { Id, status, IterationCount, IterationStatus } = item;

          const isInitial = status === 'Initial';
          const isActiveTab = index === activeTab;
          const isLastIteration = index === 0;
          const showSubStage = !isInitial && isLastIteration;

          return (
            <Timeline.Item
              className={classNames(styles.iterationTimelineItem, {
                [styles.activeTimelineItem]: item.initialIteration ? !item.disabled : isActiveTab,
                [styles.initialIterationsItem]: item.initialIteration,
                [styles.dotItem]: item.isDot,
              })}
              key={Id}
              dot={getDotIcon({ item, isActiveTab })}
            >
              <div
                className={classNames(styles.timelineContent, {
                  [styles.disabledItem]: item.disabled,
                  [styles.lastIteration]: isLastIteration,
                })}
                onClick={() => !item.initialIteration && setActiveTab(index)}
              >
                {item.initialIteration
                  ? item.title
                  : isInitial
                  ? 'Initial'
                  : `Counter #${IterationCount}`}

                {showSubStage ? (
                  <div
                    className={classNames(styles.timelineSubContent, {
                      [styles.acceptedContent]: IterationStatus === Accepted,
                    })}
                  >
                    {getStatusForStages(item, index > 0)}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  };

  const renderIterationContent = () => {
    const iteration = offerIteration[activeTab];

    return (
      <Fragment>
        <Title className={styles.cardTitle} level={4}>
          {getIterationHeader(iteration.status, iteration.IterationCount)}
        </Title>

        <InboundIteration
          status={offer.OfferStatus}
          offerIteration={{
            ...iteration,
            BuyingAgentName: offer.BuyingAgentName,
            OfferCount: offer.OfferCount,
          }}
        />
      </Fragment>
    );
  };

  const renderActionPanel = () => {
    const iteration = offerIteration[activeTab];
    const keyword = iteration.IterationCount ? 'counter' : 'offer';

    const {
      isAcceptedRejectedOrWithdrawnOrCancelled,
      isOfferAccepted,
      isRejectedOrWithdrawnOrCancelled,
      isExpired,
    } = getOfferStatuses(iteration);

    return (
      <Fragment>
        <div className={styles.metaWrap}>
          {isAcceptedRejectedOrWithdrawnOrCancelled || isExpired ? (
            <Fragment>
              <h6 className={styles.offerIterationText}>{`This ${keyword} ${
                isExpired ? 'expired' : `was ${keywordStatus(iteration)}:`
              }`}</h6>

              <div
                className={classNames(
                  styles.meta,
                  {
                    [styles.accepted]: isOfferAccepted,
                  },
                  {
                    [styles.rejected]: isRejectedOrWithdrawnOrCancelled || isExpired,
                  },
                )}
              >
                {isOfferAccepted && (
                  <Icons
                    variant={'green-calendar-bg'}
                    className={classNames(styles.customIcon, styles.iconAccepted)}
                  />
                )}

                {(isRejectedOrWithdrawnOrCancelled || isExpired) && (
                  <Icons
                    variant={'red-calendar-bg'}
                    className={classNames(styles.customIcon, styles.iconRejected)}
                  />
                )}

                <p>{isExpired ? getExpiration(iteration) : getIterationStatusDate(iteration)}</p>
              </div>
            </Fragment>
          ) : !iteration?.OfferExpiryIndefinitely ? (
            <div className={styles.meta}>
              <h6>This {keyword} expires:</h6>
              <div className={styles.expirationContent}>
                <Icons variant={'calendar'} className={styles.icons} />
                <div className={styles.expirationDetails}>
                  <div className={styles.expiryTime}>
                    in <div className={styles.expiryDetails}>{renderOfferExpiry(iteration)}</div>
                  </div>
                  {`(${getExpiration(iteration)})`}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {activeTab === 0 ? (
          <Fragment>
            {!isAcceptedRejectedOrWithdrawnOrCancelled && OfferIteration?.length && !isExpired && (
              <ActionButtons
                IterationStatus={iteration?.status}
                handleActionClick={handleActionClick}
                className={iteration?.OfferExpiryIndefinitely ? styles.iterationActionButton : ''}
              />
            )}

            {isRejectedOrWithdrawnOrCancelled && linkStatus === OfferLinkStatus.Active && (
              <div className={styles.submitBtnContainer}>
                <button
                  className={styles.submitBtn}
                  onClick={() => history.push(`/offer/form/${LinkUUID}`)}
                >
                  <Plus className={styles.icon} /> Submit New
                </button>
              </div>
            )}

            {/* {iteration.status !== OfferStatus.Inbound &&
              isExpired &&
              linkStatus === OfferLinkStatus.Active && (
                <ActionButtons
                  IterationStatus={iteration?.IterationStatus}
                  handleActionClick={handleActionClick}
                />
              )} */}
          </Fragment>
        ) : (
          <div className={styles.emptyBox}>No additional details for this {keyword}</div>
        )}
      </Fragment>
    );
  };

  if (!offerIteration?.length) return null;

  return (
    <div className={styles.inboundOutboundContainer}>
      <CardBox className={styles.iterationStagesBox}>
        <Title className={styles.statusCardTitle} level={4}>
          Status
        </Title>
        {renderIterationSteps()}
      </CardBox>

      <CardBox className={styles.iterationContentBox}>{renderIterationContent()}</CardBox>

      <CardBox className={styles.iterationActionBox}>{renderActionPanel()}</CardBox>
    </div>
  );
};
