import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Price = ({ className, value, prefix, thousandSeparator }) => {
  if (!value) return null;

  return (
    <NumberFormat
      className={classNames(styles.price, className)}
      value={value}
      prefix={prefix}
      thousandSeparator={thousandSeparator}
    />
  );
};

Price.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prefix: PropTypes.string,
  thousandSeparator: PropTypes.bool,
};

Price.defaultProps = {
  className: '',
  prefix: '$',
  thousandSeparator: true,
  value: undefined,
};

export default Price;
