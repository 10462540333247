import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getOpenedChatThreadInfo,
  getOpenedPropertyThreadInfo,
} from 'store/selectors/sockets/threads';

import { NoThreads } from 'components';

import styles from './styles.module.scss';
import Avatars from 'components/Avatars';
import { getQuoteActivityDrawerParams } from 'store/selectors/drawers/viewQuoteActivity';
import { ActivityLogsSections } from 'pages/Main/AgentDashboard/components/ActivityLogsSection';
import { ActivityLogsTypesValue } from 'types/activityLogs';
import {
  getQuotesAuditLogsForChatRecipient,
  getQuotesAuditLogsForPropertyRecipient,
} from 'store/selectors/quotes';

const ViewQuoteActivityDrawerContent = () => {
  const { propertyId } = useSelector(getQuoteActivityDrawerParams);
  const { names, avatars, recipientRole, subTitle, BusinessName, thread } = useSelector(
    propertyId ? getOpenedPropertyThreadInfo : getOpenedChatThreadInfo,
  );

  const logs = useSelector(
    propertyId ? getQuotesAuditLogsForPropertyRecipient : getQuotesAuditLogsForChatRecipient,
  );

  const isRecipientThirdParty = () => {
    return recipientRole === 'ThirdParty';
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.chatHeaderInfo}>
        <div className={styles.chatHeaderAvatars}>
          <Avatars
            className={styles.avatars}
            avatarWrapperClassName={styles.avatarWrapper}
            avatarClassName={styles.avatar}
            isNotSingleClassName={styles.isNotSingle}
            overflowClassName={styles.overflow}
            avatars={avatars}
          />
        </div>
        <div className={styles.chatHeaderTitles}>
          <div testid="header_title" className={styles.chatHeaderTitle}>
            {isRecipientThirdParty() ? `${names} (${BusinessName})` : names}
          </div>
          {
            <span className={styles.chatHeaderThirdPartyCategory}>
              {isRecipientThirdParty() ? subTitle : recipientRole}
            </span>
          }
        </div>
      </div>
      <div className={styles.item}>
        <ActivityLogsSections
          activityLogCardClassName={styles.activityLogCardWrapper}
          activityLogActionType={[
            ActivityLogsTypesValue.QuoteRequested,
            ActivityLogsTypesValue.QuoteResponseReceived,
          ]}
          timelineItemWrapperClassName={styles.timelineItemWrapper}
          timelineTimeLabelClassName={styles.timelineTimeLabel}
          timelineContentClassName={styles.timelineContent}
          avatarWrapperClassName={styles.avatarWrapper}
          width={'100%'}
          logs={logs}
        />
      </div>
    </div>
  );
};

export default ViewQuoteActivityDrawerContent;
