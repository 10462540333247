import classNames from 'classnames';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';

import { convertNameToAvatarPlaceholder } from 'helpers';
import { getTransactionTaskSelector } from 'store/selectors/transactionTask';

import styles from './styles.module.scss';

const CC = (props) => {
  const { className } = props;
  const { task } = useSelector(getTransactionTaskSelector);
  const { CcList } = task || {};
  const getName = () =>
    CcList?.map((item) => `${item.FirstName} ${item.LastName}`).join(', ') || '';

  const getIcon = () => {
    return (
      <Avatar.Group
        maxCount={2}
        maxPopoverTrigger="click"
        size="large"
        maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
      >
        {CcList?.map((item) =>
          item.AvatarUrl ? (
            <Avatar key={item.Id} className={styles.avatar} src={item.AvatarUrl} />
          ) : (
            <Avatar key={item.Id} className={styles.avatar}>
              {convertNameToAvatarPlaceholder(`${item.FirstName} ${item.LastName}`)}
            </Avatar>
          ),
        )}
      </Avatar.Group>
    );
  };
  return (
    <div testid="ccList" className={classNames(styles.cc, className)}>
      {CcList?.length ? (
        <>
          {getIcon()}
          <p className={styles.name}>{getName()} </p>
        </>
      ) : (
        <p className={styles.notAvailable}>No CC available</p>
      )}
    </div>
  );
};

export default CC;
