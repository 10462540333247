import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { LotSizeMinMax, Select } from 'components';
import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { onBoardingSignUpSearchEffect } from 'store/effects';
import { addSearchInstance } from 'store/effects/clientInstances';

import { PENDING } from 'settings/constants/apiState';

import { ValidationSchema } from './validation';
import { prepareData } from './helpers';
import styles from './styles.module.scss';

const LotSizeForm = (props) => {
  const { stageIndex } = props;
  const dispatch = useDispatch();
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const { onBoarding } = useSelector(getOnBoardingData);

  const formik = useFormik({
    initialValues: {
      lotSizeMin: defaultClientSearch?.LotSizeRange?.Min || '',
      lotSizeMax: defaultClientSearch?.LotSizeRange?.Max || '',
    },
    validationSchema: ValidationSchema,
    onSubmit(values) {
      const cfg = prepareData(values, stageIndex, defaultClientSearch);
      dispatch(
        onBoardingSignUpSearchEffect(cfg, {}, (err) => {
          if (!err) {
            dispatch(addSearchInstance({ data: cfg.DefaultPropertySearchPreferences }));
          }
        }),
      );
    },
  });

  const isPending = onBoarding.state === PENDING;

  return (
    <form onSubmit={formik.handleSubmit}>
      <LotSizeMinMax
        className={styles.minMax}
        placeholderMin="No min"
        placeholderMax="No max"
        onChangeMin={(val) => {
          formik.setFieldValue('lotSizeMin', val);
          formik.handleSubmit();
        }}
        onChangeMax={(val) => {
          formik.setFieldValue('lotSizeMax', val);
          formik.handleSubmit();
        }}
        valueMin={formik.values.lotSizeMin}
        valueMax={formik.values.lotSizeMax}
        testidMin="lot_size_min"
        testidMax="lot_size_max"
        variant={Select.LIGHT_ROUND}
      />
    </form>
  );
};

export default LotSizeForm;
