import { Button, Modal } from 'components-antd';
import { PaymentFailed } from 'components/Icons/PaymentFailed';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const PaymentSuccessModal: React.FC<{}> = () => {
  const history = useHistory();

  return (
    <Modal
      getContainer={'#paywallModalContainer'}
      open={true}
      width={675}
      footer={null}
      closable={false}
      maskClosable={false}
      mask={false}
    >
      <div className={styles.modalLayout}>
        <PaymentFailed className={styles.paymentStatusIcon} />
        <h2 className={styles.stepHeader}>Payment Failed.</h2>
        <div>
          It looks like there was an issue processing your payment. Please try again or contact us
          at <a href="emailto:support@mosaik.io">support@mosaik.io</a>.
        </div>
        <Button
          onClick={() => {
            history.replace(routes.paywall);
          }}
          variant="secondary"
          className={classNames(styles.continueButton, styles.button)}
        >
          Retry
        </Button>
      </div>
    </Modal>
  );
};
