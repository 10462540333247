import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import {
  getBundleTemplateEffect,
  getDynamicTemplateDocumentEffect,
} from 'store/effects/formProcess';

import { updateBundleTemplateEffect, updateFormTemplateEffect } from 'store/effects/formBuilder';

export const useManageTemplateDetails = (props) => {
  const { open, templateId, bundleId, onSuccess } = props;
  const [templateDetails, setTemplateDetails] = useState<any>({});

  const [fetching, setFetching] = useState(true);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const hasEmptyDetails = _.isEmpty(templateDetails);
    if (!open) {
      setTemplateDetails({});
    } else if (open && hasEmptyDetails) {
      fetchDetails();
    }
  }, [open]);

  const fetchDetails = async () => {
    setFetching(true);
    try {
      if (templateId) {
        const response: any = await dispatch(getDynamicTemplateDocumentEffect({ templateId }));

        setTemplateDetails(response?.data?.value.formTemplate);

        setFetching(false);
      } else if (bundleId) {
        const response: any = await dispatch(getBundleTemplateEffect(bundleId));
        const { FormBundleTemplate } = response.data.value.response;
        setTemplateDetails(FormBundleTemplate[0].FormBundleTemplate);

        setFetching(false);
      }
    } catch (error) {
      setFetching(false);
    }
  };

  const updateBundleTemplate = (templateName) => {
    const requestPayload = {
      name: templateName,
      publishToTeam: templateDetails.PublishToTeam,
      templateBundleId: bundleId,
    };

    setSaving(true);

    dispatch(
      updateBundleTemplateEffect(requestPayload, (error) => {
        setSaving(false);
        if (!error) {
          onSuccess();
        }
      }),
    );
  };

  const updateTemplate = (templateName) => {
    const { FormVersion, FormVersionId, FormRolesIds, FormId, PublishToTeam } = templateDetails;
    const categories = FormVersion.FormCategoryTag.map((cat) => cat.Id);

    const requestPayload = {
      templateId,
      templateName,
      formId: FormId,
      formCategoryTag: categories,
      publishToTeam: PublishToTeam,
      formVersionId: FormVersionId,
      templateRoleIds: FormRolesIds.map((Id) => ({ Id })),
    };

    setSaving(true);

    dispatch(
      updateFormTemplateEffect(requestPayload, (err) => {
        setSaving(false);
        if (!err) {
          onSuccess();
        }
      }),
    );
  };

  return { templateDetails, fetching, saving, updateBundleTemplate, updateTemplate };
};
