import { ReactChild } from 'react';

import styles from './styles.module.scss';

interface FadeInAnimationWrapperProps {
  children: ReactChild;
  key: string;
}

export const FadeInAnimationWrapper = ({ children, key }: FadeInAnimationWrapperProps) => (
  <div key={key} className={styles.fadeInBlock}>
    {children}
  </div>
);
