//Certain ui elements need to be hidden from DEMO clients (democlient@mosaik.io or brightclient@mosaik.io)
//but we want them visible for alias accounts.
//If a navigation element is hidden, it can still be accessed directly using the route
export function hideFromDemoClient(user) {
  if (
    user.data.Email.includes('client@mosaik.io') &&
    !user.data.Email.includes('+client@mosaik.io')
  )
    return true;
  else return false;
}

//Certain ui elements need to be hidden from DEMO agents (demoagent@mosaik.io or brightagent@mosaik.io)
//but we want them visible for alias accounts.
//If a navigation element is hidden, it can still be accessed directly using the route
export function hideFromDemoAgent(user) {
  if (user.data.Email.includes('mls@mosaik.io') && !user.data.Email.includes('+mls@mosaik.io'))
    return true;
  else return false;
}
