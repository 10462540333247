import { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { appManageClientDrawerAction } from 'store/actions/app';
import { useOutsideClick } from 'hooks';

import { HouseTransaction } from 'components/Icons';
import { Dots } from 'components/Icons';

import styles from './styles.module.scss';

export const ActiveTransaction = (props) => {
  const { transaction } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLInputElement>(null);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onClickOpen = (id) => {
    if (id) {
      history.push({
        pathname: `/workshop/transactions/${id}/overview`,
        state: {
          transactionId: id,
        },
      });
      dispatch(appManageClientDrawerAction(false));
    }
  };

  const getDate = (date) => {
    return moment(date).format('ll');
  };

  return (
    <div className={styles.activeTransaction}>
      <HouseTransaction className={styles.homeIcon} />
      <div className={styles.content}>
        <h4 className={styles.title}>{transaction?.Property?.Address?.Line1}</h4>
        <ul className={styles.description}>
          <li>{transaction?.TasksCount} Tasks</li>
          {transaction?.UnderContractCloseDate && (
            <li>Closing on {getDate(transaction?.UnderContractCloseDate)}</li>
          )}
        </ul>
      </div>
      <div className={styles.optionsWrapper}>
        <div ref={buttonRef} onClick={onClickOptions} className={styles.optionsDots}>
          <Dots />
        </div>
        {open && (
          <div ref={optionsRef} className={styles.options}>
            <ul className={styles.dropdown}>
              <li className={styles.item} onClick={() => onClickOpen(transaction?.Id)}>
                Open
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
