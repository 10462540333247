import {
  GET_SMART_FORM_VERSION,
  UPDATE_SMART_FORM_VERSION,
  COPY_NEW_SMART_VERSION,
  ADD_NEW_SMART_VERSION,
  ADD_QUESTION_ROLE,
  FILL_DOCUMENT_PREVIEW,
} from 'app-constants';
import { apiServer } from 'settings/web-services/api';

export const getSmartForm = ({ versionId, ...cfg }) =>
  apiServer.get(GET_SMART_FORM_VERSION(versionId), cfg);

export const updateSmartForm = ({ Id, ...cfg }) =>
  apiServer.patch(UPDATE_SMART_FORM_VERSION(Id), cfg);

export const addQuestionRole = (cfg) => apiServer.post(ADD_QUESTION_ROLE, cfg);

export const copySmartFormVersion = (cfg) => apiServer.post(COPY_NEW_SMART_VERSION, cfg);

export const newSmartFormVersion = ({ versionId, formId, ...cfg }) =>
  apiServer.post(ADD_NEW_SMART_VERSION(formId, versionId), cfg);

export const fillDocumentPreview = (cfg) => apiServer.post(FILL_DOCUMENT_PREVIEW, cfg);
