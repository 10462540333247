import { combineReducers } from 'redux';

import state from './state';
import signUp from './signUp';
import wizard from './wizard';

export default combineReducers({
  state,
  signUp,
  wizard,
});
