import { handleActions } from 'redux-actions';
import { cloneDeep } from 'lodash-es';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetServiceTagsAction,
  sortServiceTagsAction,
  searchServiceTagsAction,
} from 'store/actions/adminPanel';

import { handleGetListOfItems, searchItems, sortItems } from '../helpers';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  sort: {
    order: 'asc',
    fields: ['Name'],
  },
  search: {
    searchString: '',
    type: '',
  },
};

export default handleActions(
  {
    [requestGetServiceTagsAction]: handleGetListOfItems(initialData),
    [sortServiceTagsAction]: sortItems,
    [searchServiceTagsAction]: searchItems,
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
