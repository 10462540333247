import { apiServer } from 'settings/web-services/api';
import { MosaikResponse } from './types';

export function getListOfAvailableMlsServices(): Promise<MosaikResponse<MlsService[]>> {
  return apiServer.get(`/mls/list`);
}

export interface MlsService {
  Id: number;
  Code: string;
  Name: string;
  IsVisible: boolean;
}
