import { Collapse, Panel } from 'components-antd';
import { ArrowDown } from 'components/Icons';
import { AgentDocumentTypes, DocumentOptionUtils, TransactionDocumentResponseType } from 'types';
import { DocumentSection } from '../DocumentSection';

import styles from './styles.module.scss';

interface TransactionSectionProps extends DocumentOptionUtils {
  documents: TransactionDocumentResponseType;
}

export const TransactionSection = ({ documents, ...props }: TransactionSectionProps) => {
  if (!documents && !Object.keys(documents).length) {
    return <></>;
  }

  const renderTransactionProperty = (propertyAddress: string) => {
    const address = propertyAddress.split('-');
    address.shift();

    return (
      <Panel header={address.join('-')} key={`${propertyAddress}-panel`}>
        <DocumentSection
          documents={documents[propertyAddress]}
          type={AgentDocumentTypes.Transactions}
          {...props}
        />
      </Panel>
    );
  };

  return (
    <div>
      {Object.keys(documents).map((propertyAddress: string) => (
        <Collapse
          key={propertyAddress}
          expandIconPosition="end"
          expandIcon={() => <ArrowDown className={styles.expandIconWrap} />}
          className={styles.transactionsCollapse}
        >
          {renderTransactionProperty(propertyAddress)}
        </Collapse>
      ))}
    </div>
  );
};
