import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Modal, ModalProps, Title, SelectProps } from 'components-antd';
import { Options } from 'types';

import styles from './styles.module.scss';
import { Icons } from '../Icons';
import classNames from 'classnames';
import { initialFieldState, initialUploadErrorState } from '../../../helper';
import { TemplateCheckboxFields } from '../TemplateCheckboxFields';
import { TemplateFields } from '../TemplateFields';

interface FormFieldsModalProps extends ModalProps {
  open?: boolean;
  checkboxFieldsRequired?: boolean;
  title?: string;
  headerIcon?: string;
  okText?: string;
  className?: string;
  titleClassName?: string;
  libraryFields?: boolean;
  submit: (setLoading: Dispatch<SetStateAction<boolean>>, values: any) => any;
}

export const FormFieldsModal = ({
  title,
  headerIcon,
  okText,

  open,
  onOk,
  submit,
  titleClassName,
  className,
  checkboxFieldsRequired,
  libraryFields,
  ...props
}: FormFieldsModalProps) => {
  const [loading, setLoading] = useState(false);

  const [templateFieldState, setTemplateState] = useState(initialFieldState);

  const [templateErrors, setTemplateErrors] = useState(initialUploadErrorState);

  useEffect(() => {
    if (!open) {
      setTemplateState(initialFieldState);
      setTemplateErrors(initialUploadErrorState);
    }
  }, [open]);

  // Fields onChange Handler
  const onChangeTemplateField = (data) => {
    setTemplateState((prev) => ({ ...prev, ...data }));
  };

  const isValid = () => {
    let valid = true;
    const errors = { ...templateErrors };

    if (!libraryFields) {
      const { templateName, categories, saveAsTemplate } = templateFieldState;

      if (!saveAsTemplate && !templateName.trim().length) {
        errors.templateNameError = 'Required!';
        valid = false;
      } else if (saveAsTemplate) {
        if (!templateName.trim().length) {
          errors.templateNameError = 'Required!';
          valid = false;
        } else if (!categories.length) {
          errors.categoriesError = 'Required!';
          valid = false;
        }
      }
    }

    !valid && setTemplateErrors(errors);
    return valid;
  };

  const submitForm = () => {
    const valid = !libraryFields ? isValid() : true;

    if (valid) {
      submit(setLoading, templateFieldState);
    }
  };

  return (
    <Modal
      data-testid="input-select-modal"
      width={420}
      className={classNames(styles.inputSelectModal, className)}
      title={
        <div className={styles.formTitle}>
          <Icons variant={headerIcon} className={styles.formHeaderIcon} />
          <Title level={4}>{title}</Title>
        </div>
      }
      okText={okText}
      onOk={submitForm}
      confirmLoading={loading}
      open={open}
      {...props}
      closable={false}
    >
      <div>
        <TemplateFields
          fieldState={templateFieldState}
          onChange={onChangeTemplateField}
          errors={templateErrors}
          setErrors={setTemplateErrors}
          saveAsTemplate={templateFieldState.saveAsTemplate}
          className={styles.templateFieldsList}
          fileNameLabel="Name"
        />

        {checkboxFieldsRequired ? (
          <TemplateCheckboxFields
            values={templateFieldState}
            errors={templateErrors}
            onChange={onChangeTemplateField}
            setErrors={setTemplateErrors}
          />
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};
