import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { SAVED_SEARCHES } from 'settings/constants/drawers';
import { openSavedSearchesDrawerEffect } from 'store/effects';
import {
  getIsSearchesDrawerOpenSelector,
  getSearchesDrawerTypeSelector,
} from 'store/selectors/searchResults';
import { Drawer } from 'components';
import Icon from 'pages/Properties/SearchResults/Icons';

import Header from './Header';

import styles from './styles.module.scss';
import Searches from './Searches';
import Buyers from './Buyers';

const FilterDrawer = ({ className }) => {
  const dispatch = useDispatch();
  const open = useSelector(getIsSearchesDrawerOpenSelector);
  const type = useSelector(getSearchesDrawerTypeSelector);
  const getHeader = () => (
    <Header
      iconVariant={type === SAVED_SEARCHES ? Icon.BOOKMARK_LIGHT : Icon.PEOPLE_COLOR}
      title={type === SAVED_SEARCHES ? 'Saved Searches' : 'Search by Buyer'}
      onClose={() => dispatch(openSavedSearchesDrawerEffect({ open: false, type: null }))}
    />
  );

  return (
    <Drawer
      onClose={() => dispatch(openSavedSearchesDrawerEffect({ open: false, type: null }))}
      className={classNames(styles.filterDrawer, className)}
      isOpen={open}
      header={getHeader()}
      testid="searches_drawer"
    >
      <div className={styles.drawerInner}>
        {type === SAVED_SEARCHES ? <Searches /> : <Buyers />}
      </div>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  className: PropTypes.string,
};

FilterDrawer.defaultProps = {
  className: '',
};

export default FilterDrawer;
