import { createSelector } from 'reselect';

const localState = ({ mozzie }) => mozzie;

export const getIsCheckMessageSelector = createSelector(
  localState,
  (mozzie) => mozzie.isCheckMessage,
);

export const getNegativeCheckResponseSelector = createSelector(
  localState,
  (mozzie) => mozzie.negativeCheckResponse,
);
