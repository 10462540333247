import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get, filter, capitalize, startCase } from 'lodash-es';
import PropTypes from 'prop-types';

import { pushOrRemove, formatNumber } from 'helpers';
import { QUOTE_TYPE } from 'app-constants';

import { routes } from 'settings/navigation/routes';
import { getCurrentContextSelector } from 'store/selectors/context';
import { sendPreApprovalQuoteEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';
import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import { requestGetServicesCategoriesEffect } from 'store/effects/servicesCategories';
import { requestGetPartnersListByCategoryIdEffect } from 'store/effects/partners';
import { getRecommendedByMosaikAndAgentLists } from 'store/selectors/partners';

import Lenders from './Lenders';
import RequestModal from './RequestModal';
import OnBoardingWizard from 'pages/OnBoardingWizard';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { Question } from 'pages/RequestQuote/components';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

import styles from './styles.module.scss';

const ApproveTrustedLenders = ({ Controls, onNext, stageIndex }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);
  const { isReady, recommendedByMosaik, recommendedByAgent } = useSelector(
    getRecommendedByMosaikAndAgentLists,
  );
  const categories = useSelector(getAllServicesCategoriesList);
  const context = useSelector(getCurrentContextSelector);

  const [stageValue, setStageValue] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [requestPending, setRequestPending] = useState(false);

  const lendersCategory = useMemo(
    () => categories.find((category) => category?.Title === 'Lenders'),
    [categories],
  );

  useEffect(() => dispatch(requestGetServicesCategoriesEffect()), [dispatch]);

  useEffect(() => {
    if (lendersCategory) {
      dispatch(
        requestGetPartnersListByCategoryIdEffect(
          { categories: [String(lendersCategory?.Id)] },
          {},
          () => {
            setIsPending(false);
          },
        ),
      );
    } else {
      setIsPending(false);
    }
  }, [lendersCategory, dispatch]);

  const extractAnswers = () => {
    const wizard = get(onBoarding, 'wizard');
    const preApprovalStages = filter(
      wizard,
      (stage) =>
        !!get(stage, `meta.Values.[${signUpRole}].Context.PreApproval`) &&
        get(stage, 'meta.stageIndex') !== stageIndex,
    );

    const allAnswers = preApprovalStages.reduce(
      (acc, value) => ({
        ...acc,
        ...(get(value, `meta.Values.[${signUpRole}].Context.PreApproval`) || {}),
      }),
      {},
    );

    return Object.entries(allAnswers).reduce((acc, [key, value]) => {
      let val = value;

      if (Array.isArray(value)) {
        if (value.includes('yes') || value.includes('no')) {
          val = capitalize(value?.[0] || '');
        } else if (key === OnBoardingWizard.propNames.approvePriceRange) {
          const [min, max] = value;
          val = {
            Min: min || 0,
            Max: max || 0,
          };
        } else if (key === OnBoardingWizard.propNames.approveHomeType) {
          val = value;
        } else {
          val = value?.[0];
        }
      }

      return {
        ...acc,
        [key]: val,
      };
    }, {});
  };

  const prepareAnswers = () =>
    Object.entries(extractAnswers() || {}).reduce((acc, [key, value]) => {
      const createAnswer = () => {
        if (key === OnBoardingWizard.propNames.approvePriceRange) {
          return `Minimum ${formatNumber(value?.Min) || ''} Maximum ${
            formatNumber(value?.Max) || ''
          }`;
        }

        if (key === OnBoardingWizard.propNames.approveHomeType) {
          return value.map((val) => startCase(val)).join(', ');
        }

        if (typeof value === 'string') {
          return startCase(value);
        }

        return value;
      };
      return [...acc, { Question: key, Answer: createAnswer() }];
    }, []);

  const onRequest = () => {
    if (stageValue?.length) {
      setRequestPending(true);
      const config = {
        Partners: stageValue.map((id) => ({ Id: id, CategoryId: lendersCategory?.Id })),
        Questions: prepareAnswers(),
        Type: QUOTE_TYPE.PreApproval,
        ContextKey: context.ContextKey,
      };
      dispatch(
        sendPreApprovalQuoteEffect(config, {}, (err) => {
          if (!err) {
            onNext('approveComplete');
          }
          setRequestPending(false);
        }),
      );
    }
  };

  const onSelectLender = (id) => {
    const newValue = pushOrRemove({ arr: stageValue, id, multiple: true });
    setStageValue(newValue);
  };

  return (
    <OnBoardingWrapper testid="approve_lenders_stage" isPending={isPending || requestPending}>
      <Controls
        onNext={onRequest}
        isNextDisabled={!stageValue?.length}
        onClose={() => history.push(routes.mySearches)}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question>Which lenders would you like to connect with?</Question>
        <AnswersContainer>
          {isReady && !recommendedByMosaik?.length && !recommendedByAgent?.length ? (
            <div className={styles.emptyLenders}>No Lenders</div>
          ) : (
            <>
              <Lenders
                onSelect={onSelectLender}
                selectedLendersIds={stageValue}
                recommendedByMosaik={recommendedByMosaik}
                recommendedByAgent={recommendedByAgent}
              />
              {isReady && (
                <ButtonsContainer>
                  {stageValue?.length > 0 ? (
                    <div className={styles.bottomText}>
                      {`${stageValue?.length} lender${stageValue?.length > 1 ? 's' : ''} selected`}
                    </div>
                  ) : (
                    <div />
                  )}
                  <OnboardingContinueButton
                    testid="approve_lenders_request"
                    title="Request Pre-Approval"
                    onClick={onRequest}
                    isPending={requestPending}
                    disabled={!stageValue?.length}
                  />
                </ButtonsContainer>
              )}
            </>
          )}
        </AnswersContainer>
      </OnBoardingContainer>
      <RequestModal />
    </OnBoardingWrapper>
  );
};

ApproveTrustedLenders.answerCases = [
  { id: 'yes', label: 'Yes' },
  { id: 'no', label: 'No' },
];

ApproveTrustedLenders.propTypes = {
  Controls: PropTypes.func,
  stageIndex: PropTypes.number,
};

ApproveTrustedLenders.defaultProps = {
  Controls: () => {},
  stageIndex: undefined,
};

export default ApproveTrustedLenders;
