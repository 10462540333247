import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Answers = (props) => {
  const { children, className } = props;

  return <div className={classNames(styles.answers, className)}>{children}</div>;
};

Answers.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Answers.defaultProps = {
  className: '',
};

export default Answers;
