type IconsPropsType = {
  iconName: 'add' | 'lock' | 'menu' | 'edit' | 'delete' | 'redLocked' | 'redUnlocked';
  className?: string;
};

function Icons(props: IconsPropsType) {
  if (props.iconName === 'add') {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <rect width="24" height="24" rx="12" fill="#FF576D" />
        <path d="M7 12H17" stroke="white" strokeWidth="2" strokeLinecap="round" />
        <path d="M12 17L12 7" stroke="white" strokeWidth="2" strokeLinecap="round" />
      </svg>
    );
  } else if (props.iconName === 'lock') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <g clipPath="url(#clip0_5345_106208)">
          <path
            d="M11.75 7.25H4.25C3.42157 7.25 2.75 7.92157 2.75 8.75V13.25C2.75 14.0784 3.42157 14.75 4.25 14.75H11.75C12.5784 14.75 13.25 14.0784 13.25 13.25V8.75C13.25 7.92157 12.5784 7.25 11.75 7.25Z"
            stroke="#AAABAB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 11.75C8.41421 11.75 8.75 11.4142 8.75 11C8.75 10.5858 8.41421 10.25 8 10.25C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75Z"
            stroke="#AAABAB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 7.25V4.25C5 3.45435 5.31607 2.69129 5.87868 2.12868C6.44129 1.56607 7.20435 1.25 8 1.25C8.79565 1.25 9.55871 1.56607 10.1213 2.12868C10.6839 2.69129 11 3.45435 11 4.25V7.25"
            stroke="#AAABAB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5345_106208">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (props.iconName === 'menu') {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <rect width="32" height="32" rx="16" fill="#E8E9EA" />
        <circle cx="9" cy="16" r="2" fill="#262626" />
        <circle cx="16" cy="16" r="2" fill="#262626" />
        <circle cx="23" cy="16" r="2" fill="#262626" />
      </svg>
    );
  } else if (props.iconName === 'edit') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 14H5.13144L13.3515 5.77998C13.7667 5.36472 14 4.80152 14 4.21426C14 3.627 13.7667 3.0638 13.3515 2.64854C12.9362 2.23329 12.373 2 11.7857 2C11.1985 2 10.6353 2.23329 10.22 2.64854L2 10.8686V14Z"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.43652 3.43359L12.568 6.56503"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.iconName === 'delete') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5345_106285)">
          <path
            d="M2.00195 4.25H14.002"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.50195 7.25V11.75"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.49805 7.25V11.75"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.74805 4.25L3.49805 13.25C3.49805 13.6478 3.65608 14.0294 3.93739 14.3107C4.21869 14.592 4.60022 14.75 4.99805 14.75H10.998C11.3959 14.75 11.7774 14.592 12.0587 14.3107C12.34 14.0294 12.498 13.6478 12.498 13.25L13.248 4.25"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.75 4.25V2C5.75 1.80109 5.82902 1.61032 5.96967 1.46967C6.11032 1.32902 6.30109 1.25 6.5 1.25H9.5C9.69891 1.25 9.88968 1.32902 10.0303 1.46967C10.171 1.61032 10.25 1.80109 10.25 2V4.25"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5345_106285">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (props.iconName === 'redLocked') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5345_106278)">
          <path
            d="M11.75 7.25H4.25C3.42157 7.25 2.75 7.92157 2.75 8.75V13.25C2.75 14.0784 3.42157 14.75 4.25 14.75H11.75C12.5784 14.75 13.25 14.0784 13.25 13.25V8.75C13.25 7.92157 12.5784 7.25 11.75 7.25Z"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 11.75C8.41421 11.75 8.75 11.4142 8.75 11C8.75 10.5858 8.41421 10.25 8 10.25C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75Z"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 7.25V4.25C5 3.45435 5.31607 2.69129 5.87868 2.12868C6.44129 1.56607 7.20435 1.25 8 1.25C8.79565 1.25 9.55871 1.56607 10.1213 2.12868C10.6839 2.69129 11 3.45435 11 4.25V7.25"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5345_106278">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (props.iconName === 'redUnlocked') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5345_106483)">
          <path
            d="M11.75 7.25H4.25C3.42157 7.25 2.75 7.92157 2.75 8.75V13.25C2.75 14.0784 3.42157 14.75 4.25 14.75H11.75C12.5784 14.75 13.25 14.0784 13.25 13.25V8.75C13.25 7.92157 12.5784 7.25 11.75 7.25Z"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 11.75C8.41421 11.75 8.75 11.4142 8.75 11C8.75 10.5858 8.41421 10.25 8 10.25C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75Z"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 7.25V4.25C5 3.45435 5.31607 2.69129 5.87868 2.12868C6.44129 1.56607 7.20435 1.25 8 1.25C8.79565 1.25 9.55871 1.56607 10.1213 2.12868C10.6839 2.69129 11 3.45435 11 4.25"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5345_106483">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else return <></>;
}

export default Icons;
