import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from 'pages/Dashboard/components/Grid';
import Header from 'pages/Dashboard/components/Header';
import Icons from 'pages/Dashboard/components/Icons';
import { Edit, Write, Plus } from 'components/Icons';
import { Modal, Wrapper as PendingWrapper, ConfirmationDialog } from 'components';
import {
  getTransactionRolesDashboardSelector,
  getTransactionRolesDataDashboardSelector,
} from 'store/selectors/adminPanel';
import {
  activateTransactionRoleEffect,
  deactivateTransactionRoleEffect,
  getAllTransactionRolesDashboardEffect,
} from 'store/effects/adminPanel';
import Filters from './FilterForm';
import { gridColumns } from './config';
import { TransactionRoleForm } from './TransactionRole';

import styles from './styles.module.scss';

const TransactionRoles = () => {
  const dispatch = useDispatch();
  const { isPending, isIdle } = useSelector(getTransactionRolesDashboardSelector);
  const taskCategories = useSelector(getTransactionRolesDataDashboardSelector);

  useEffect(() => {
    if (isIdle) {
      dispatch(getAllTransactionRolesDashboardEffect());
    }
  }, []); // eslint-disable-line

  const [itemToChangeStatus, setItemToChangeStatus] = useState(null);
  const [editCategory, setEditCategory] = useState(null);
  const [createMode, setCreateMode] = useState(false);

  const onFormModalClose = useCallback(() => {
    setEditCategory(null);
    setCreateMode(false);
  }, [setEditCategory, setCreateMode]);
  const onRowClick = useCallback((e, data) => setEditCategory(data), [setEditCategory]);

  const onCloseChangeStatusItemModal = useCallback(
    () => setItemToChangeStatus(null),
    [setItemToChangeStatus],
  );
  const onConfirmChangeItemStatus = useCallback(() => {
    if (!itemToChangeStatus) return;

    const effect = itemToChangeStatus.IsActive
      ? deactivateTransactionRoleEffect
      : activateTransactionRoleEffect;

    dispatch(
      effect({ id: itemToChangeStatus.Id }, {}, (err) => {
        if (!err) {
          setItemToChangeStatus(null);
        }
      }),
    );
  }, [dispatch, itemToChangeStatus, setItemToChangeStatus]);

  const renderActions = useCallback(
    (Id, data) => (
      <>
        <div
          className={styles.editButton}
          onClick={(e) => {
            e.stopPropagation();
            setEditCategory(data);
          }}
        >
          <Edit className={styles.buttonIcon} />
        </div>
        <div
          className={data.IsActive ? styles.deactivateButton : styles.activateButton}
          onClick={(e) => {
            e.stopPropagation();
            setItemToChangeStatus(data);
          }}
        >
          <Icons
            className={styles.buttonIcon}
            variant={data.IsActive ? Icons.DEACTIVATE : Icons.ACTIVATE}
          />
        </div>
      </>
    ),
    [],
  );

  const changeItemStatusContent = useMemo(
    () =>
      itemToChangeStatus ? (
        <div>
          Are you sure that you want to&nbsp;
          <b>{itemToChangeStatus.IsActive ? 'deactivate' : 'activate'}</b>
          &nbsp;category&nbsp;
          <b>{itemToChangeStatus.Title}</b>?
        </div>
      ) : null,
    [itemToChangeStatus],
  );

  return (
    <PendingWrapper isPending={isPending} className={styles.pageWrapper}>
      <Header
        title="Transaction Roles"
        buttonText="New Transaction Role"
        buttonIcon={<Write />}
        onButtonClick={() => setCreateMode(true)}
      />
      <Filters />
      <Grid
        items={taskCategories || []}
        columns={gridColumns}
        keyProp="Id"
        renderActions={renderActions}
        onRowClick={onRowClick}
      />
      <ConfirmationDialog
        onReject={onCloseChangeStatusItemModal}
        onConfirm={onConfirmChangeItemStatus}
        isOpen={!!itemToChangeStatus}
      >
        {changeItemStatusContent}
      </ConfirmationDialog>
      <Modal
        isOpen={!!editCategory || createMode}
        onClose={onFormModalClose}
        innerHolderClassName={styles.modalInnerHolder}
        contentClassName={styles.modalContent}
      >
        {(!!editCategory || createMode) && (
          <TransactionRoleForm
            onSubmissionComplete={() => {
              setCreateMode(false);
              setEditCategory(null);
            }}
            id={editCategory?.Id}
          />
        )}
      </Modal>
    </PendingWrapper>
  );
};

export default TransactionRoles;
