import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AgentHeader, PropertiesList } from './components';
import Map from '../common/Map';
import { ViewSwitcher } from './components/ViewSwitcher/ViewSwitcher';

import { Col, Row } from 'components-antd';

import { useAgentSearchInstance } from './hooks/useAgentSearchInstance';
import {
  getAgentPropertiesList,
  getAgentPropertiesMarkersSelector,
  getFeedCriteriaSelectorV3,
  getFeedMetaSelectorV3,
} from 'store/selectors/feedv3';
import {
  getClientFavoriteMarkersSelector,
  getClientFavoritePropsSelector,
  getFeedSortSelector,
  getSubFilterTypeSelector,
} from 'store/selectors/feed';
import {
  getClientFavoritesPropsEffect,
  getSavedSearchesEffect,
  sortFeedEffect,
  updateSearchInstanceEffect,
} from 'store/effects';

import styles from './styles.module.scss';
import ClientSearch from '../ClientSearch';
import { getSavedSearchesFormattedSelector } from 'store/selectors/mySearches';
import { SORT_ASC } from 'settings/constants/sort';
import { subFilterTypes } from 'settings/constants/properties';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { MapDrawControlsSearchWrapper } from '../SearchResults/components/MapDrawControlsSearchWrapper';
import { LocationType } from 'types';
import { toggleSearchMapDrawingModeAction } from 'store/actions/searchResults';
import { getClientsSearchInstancesEffect } from 'store/effects/clientSearches';
import { setCriteriaAction } from 'store/actions/feedv3';

const AgentFeed = () => {
  const dispatch = useDispatch();
  const getAgentSearchInstance = useAgentSearchInstance();
  const [showBlurBg, setShowBlurBg] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [isSimpleHeaderTitleVisible, setIsSimpleHeaderTitleVisible] = useState({
    value: false,
    text: '',
  });
  const isFavorites =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Favorites';
  const isUnreadComments =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Unread Comments';
  const { properties, isPending, totalCount } = useSelector(getAgentPropertiesList);
  const agentPropertiesMarkers = useSelector(getAgentPropertiesMarkersSelector);
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const sort = useSelector(getFeedSortSelector);
  const clientFavoritesProps = useSelector(getClientFavoritePropsSelector);
  const favouriteMarkers = useSelector(getClientFavoriteMarkersSelector);
  const [viewType, setViewType] = useState('Split View');
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const polygonLocations =
    criteria?.Locations?.filter(({ Type }) => Type === LocationType.Polygon) ?? [];
  const { agentClientMode, isClientMatches, selectedClientSavedSearch, selectedSearch } =
    useSelector(getFeedMetaSelectorV3);
  const { isPending: isSavedSearchPending } = useSelector(getSavedSearchesFormattedSelector);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState<boolean>(false);

  const fetchMySearchProperties = () => {
    if (criteria) {
      const searchInstance = {
        criterias: criteria,
      };
      getAgentSearchInstance(
        searchInstance,
        undefined,
        selectedClientSavedSearch,
        isClientMatches,
        isUnreadComments,
      );
    }
  };

  const fetchClientFavorites = () => {
    dispatch(getClientFavoritesPropsEffect({ userId: clientFavoritesProps?.meta?.userId }));
  };

  const fetchProperties = () => {
    isFavorites ? fetchClientFavorites() : fetchMySearchProperties();
  };

  useEffect(() => {
    if (!firstRender) fetchProperties();
  }, [subFilterType, JSON.stringify(sort)]);

  useEffect(() => {
    setIsSimpleHeaderTitleVisible({
      value: clientFavoritesProps.state !== 'idle',
      text: 'Favorites',
    });
  }, [clientFavoritesProps.state]);

  useEffect(() => {
    if (subFilterType !== SORT_ASC || sort !== subFilterTypes.ALL) {
      dispatch(sortFeedEffect({ order: SORT_ASC }));
      dispatch(setSubFilterTypeAction(subFilterTypes.ALL));
    } else {
      fetchProperties();
    }
    setFirstRender(false);
  }, []);

  let propertyProps = {
    isPending: isFavorites
      ? clientFavoritesProps.state === 'pending'
      : isPending || isSavedSearchPending,
    properties: isFavorites ? clientFavoritesProps.data : properties,
  };

  const editModeCallback = (err, criterias) => {
    if (!err) {
      getAgentSearchInstance(
        { criterias },
        undefined,
        selectedClientSavedSearch,
        isClientMatches,
        isUnreadComments,
      );
      if (selectedClientSavedSearch) {
        dispatch(getClientsSearchInstancesEffect());
      } else {
        dispatch(getSavedSearchesEffect());
      }
    }
  };

  const polygonsUpdateFn = (polygons) => {
    const nonPolygonLocations =
      criteria?.Locations?.filter(({ Type }) => Type !== LocationType.Polygon) ?? [];
    const newCriteria = {
      ...criteria,
      Locations: [...nonPolygonLocations, ...polygons],
      id: selectedSearch?.id,
    };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    dispatch(
      updateSearchInstanceEffect(newCriteria, {}, (err) => editModeCallback(err, newCriteria)),
    );
    dispatch(toggleSearchMapDrawingModeAction(false));
  };

  const setDrawnPolygonDispatchFn = (polygon) => {
    const newCriteria = {
      ...criteria,
      Locations: [...(criteria?.Locations ?? []), polygon],
      id: selectedSearch?.id,
    };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    dispatch(
      updateSearchInstanceEffect(newCriteria, {}, (err) => editModeCallback(err, newCriteria)),
    );
    dispatch(toggleSearchMapDrawingModeAction(false));
  };

  const setPolygonLocations = (payload) => {
    const nonPolygonLocations =
      criteria?.Locations?.filter(({ Type }) => Type !== LocationType.Polygon) ?? [];
    const newCriteria = { ...criteria, Locations: [...nonPolygonLocations, ...payload] };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    getAgentSearchInstance(
      { criterias: newCriteria },
      undefined,
      selectedClientSavedSearch,
      isClientMatches,
      isUnreadComments,
    );
  };

  let mapProps = {
    markers: isFavorites ? favouriteMarkers : agentPropertiesMarkers,
    properties: isFavorites ? clientFavoritesProps.data : properties,
    totalCount: isFavorites ? clientFavoritesProps.totalCount : totalCount,
    setDrawnPolygonDispatchFn: setDrawnPolygonDispatchFn,
    polygonLocations: polygonLocations,
  };

  const getViewType = (value) => {
    setViewType(value);
  };

  return (
    <div className={styles.agentFeedWrapper}>
      {isOpenSearchModal && (
        <ClientSearch onClose={() => setIsOpenSearchModal(false)} isAgent={true} />
      )}
      <AgentHeader
        isSimpleHeaderTitleVisible={isSimpleHeaderTitleVisible}
        setIsSimpleHeaderTitleVisible={setIsSimpleHeaderTitleVisible}
        setShowBlurBg={(value) => setShowBlurBg(value)}
        setIsOpenSearchModal={setIsOpenSearchModal}
      />
      <Row className={styles.agentFeed}>
        {showBlurBg && <div className={styles.backdropBlur} />}
        {viewType !== 'List Only' && (
          <Col
            md={viewType === 'Split View' ? 10 : 24}
            sm={24}
            xs={24}
            className={styles.mapContainer}
          >
            <Map {...mapProps} />
          </Col>
        )}
        {viewType !== 'Map Only' && (
          <Col
            md={viewType === 'Split View' ? 14 : 24}
            sm={24}
            xs={24}
            className={styles.propertiesContainer}
          >
            <PropertiesList
              {...propertyProps}
              isSimpleHeaderTitleVisible={isSimpleHeaderTitleVisible}
              isAgentType={agentClientMode?.value ? true : !isFavorites}
              agentClientMode={agentClientMode}
              viewType={viewType}
            />
          </Col>
        )}
      </Row>
      <div className={styles.mapSwitchers}>
        <ViewSwitcher getView={getViewType} />
        {viewType !== 'List Only' && (
          <MapDrawControlsSearchWrapper
            polygonLocations={polygonLocations}
            setPolygonLocations={setPolygonLocations}
            polygonsUpdateFn={polygonsUpdateFn}
          />
        )}
      </div>
    </div>
  );
};

export default AgentFeed;
