import { createAction } from 'redux-actions';

export const socketsStoreNewOfferMessageAction = createAction('SOCKETS/STORE_NEW_OFFER_MESSAGE');
export const socketsStoreEditedOfferMessageAction = createAction(
  'SOCKETS/STORE_EDITED_OFFER_MESSAGE',
);
export const socketsRemoveDeletedOfferMessageAction = createAction(
  'SOCKETS/REMOVE_DELETED_OFFER_MESSAGE',
);
export const socketsStoreOfferMessagesByThreadIdAction = createAction(
  'SOCKETS/STORE_OFFER_MESSAGES_BY_THREAD_ID',
);
export const socketsResetOfferMessagesAction = createAction('SOCKETS/RESET_OFFER_MESSAGES');
export const socketSetEditOfferMessageAction = createAction('SOCKETS/SET_EDIT_OFFER_MESSAGE');
export const setNewOfferMessageWithTempIdAction = createAction(
  'SET_NEW_OFFER_MESSAGE_WITH_TEMP_ID',
);
export const replaceTempOfferMessageAction = createAction('REPLACE_TEMP_OFFER_MESSAGE');
export const socketsStoreOfferMessagesSetThreadIdAction = createAction(
  'SOCKETS/STORE_OFFER_MESSAGES_SET_THREAD_ID',
);
