import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Wrapper as PendingWrapper } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentClientsGroupByNameSelector, getAgentClientsSelector } from 'store/selectors/feed';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { getAgentClientsWithFavoritesCountEffect } from 'store/effects';

import BuyersList from './BuyersList';

import styles from './styles.module.scss';

const AgentClients = (props) => {
  const { onBuyerClick } = props;
  const dispatch = useDispatch();
  const clientFavorites = useSelector(getAgentClientsSelector);
  const groupedBuyersList = useSelector(getAgentClientsGroupByNameSelector);

  useEffect(() => {
    dispatch(
      getAgentClientsWithFavoritesCountEffect({}, { silent: clientFavorites.state !== IDLE }),
    );
  }, []); // eslint-disable-line

  const isPending = clientFavorites.state === PENDING;

  return (
    <div className={classNames(styles.buyersList)}>
      <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
        <BuyersList onBuyerClick={onBuyerClick} groupedList={groupedBuyersList} />
      </PendingWrapper>
    </div>
  );
};

AgentClients.propTypes = {
  onBuyerClick: PropTypes.func,
};

AgentClients.defaultProps = {
  onBuyerClick: () => {},
};

export default AgentClients;
