import { Role } from 'settings/constants/roles';
import { transactionStatuses } from 'settings/constants/transaction';

export const formatCurrency = (value: string | number, curencySign: string = '$') => {
  return (
    curencySign +
    Number(value).toLocaleString('en-US', {
      currency: 'USD',
      maximumFractionDigits: 0,
    })
  );
};
