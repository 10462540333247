import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';

const Header = ({ title, buttonText, buttonIcon, buttonLink, children, onButtonClick }) => (
  <div testid="header" className={styles.header}>
    <div>{title}</div>
    <div className={styles.headerButtons}>
      {children}
      {(buttonText || buttonIcon) && !onButtonClick ? (
        <NavLink testid="nav_link" className={styles.button} to={buttonLink}>
          {buttonIcon}
          {buttonText}
        </NavLink>
      ) : null}
      {(buttonText || buttonIcon) && onButtonClick ? (
        <button className={styles.button} to={buttonLink} onClick={onButtonClick}>
          {buttonIcon}
          {buttonText}
        </button>
      ) : null}
    </div>
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.element,
  children: PropTypes.node,
  onButtonClick: PropTypes.func,
  buttonLink: PropTypes.string,
};

Header.defaultProps = {
  buttonText: '',
  buttonIcon: null,
  children: null,
  buttonLink: '',
  onButtonClick: null,
};

export default Header;
