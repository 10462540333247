import { createSelector } from 'reselect';

const localState = ({ contexts }) => contexts;

export const getClientContextsSelector = createSelector(localState, (state) => state.data);

export const getClientContextsStateSelector = createSelector(localState, (state) => state);
export const getInActiveClientContextsStateSelector = createSelector(
  localState,
  (state) => state?.inActiveContexts,
);
