import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useLongPress } from 'hooks';

import { getSortedUnreadThreads } from 'store/selectors/sockets/threads';
import { getUserId } from 'store/selectors/user';
import { isSearchActive } from 'store/selectors/searchMessages';
import { openMessagesDrawerAction } from 'store/actions/drawers/messages';

import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';

import { NoThreads, OfferListingMessage, UsersMessage } from 'components';

import ActionsList from 'components/Drawers/components/ActionsList';

import {
  getArchiveThreadOption,
  getAddParticipantThreadOption,
  getOpenChatThreadOption,
  openChatThreadHandler,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import MessagesDrawerSearchedContent from '../SearchedContent';

import styles from './styles.module.scss';

// TODO: add transactions
const MessagesDrawerUnreadContent = (props) => {
  const { messageClassName, isWidget } = props;
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const threads = useSelector(getSortedUnreadThreads);
  const isSearch = useSelector(isSearchActive);

  const [actionsPosition, setActionPosition] = useState(null);
  const [actions, setActions] = useState([]);

  const onLongPress = (event, [thread]) => {
    setActionPosition({ x: event?.clientX, y: event?.clientY });
    setActions([
      ...getArchiveThreadOption(dispatch, thread, () => {
        setActionPosition(null);
      }),
    ]);
  };
  const onPress = (event, [thread]) => {
    isWidget && dispatch(openMessagesDrawerAction(true));
    openChatThreadHandler(dispatch, { threadId: thread.Id });
  };

  const longPressEvent = useLongPress(onLongPress, onPress);

  return (
    <div testid="unread_content" className={styles.scrollWrapper}>
      {isSearch ? (
        <MessagesDrawerSearchedContent className={styles.search} />
      ) : (
        <>
          {threads?.length ? (
            isWidget ? (
              threads.slice(0, 4).map((thread) => {
                if (thread.Type === SOCKET_THREAD_TYPES.CHAT) {
                  return (
                    <UsersMessage
                      key={thread.Id}
                      thread={thread}
                      userId={userId}
                      {...longPressEvent(thread)}
                      className={messageClassName}
                      isWidget={isWidget}
                    />
                  );
                }
                return null;
              })
            ) : (
              threads.map((thread) => {
                if (thread.Type === SOCKET_THREAD_TYPES.CHAT) {
                  return (
                    <UsersMessage
                      key={thread.Id}
                      thread={thread}
                      userId={userId}
                      {...longPressEvent(thread)}
                      className={messageClassName}
                    />
                  );
                }
                return null;
              })
            )
          ) : (
            <NoThreads />
          )}
          <ActionsList actions={actions} position={actionsPosition} />
        </>
      )}
    </div>
  );
};

export default MessagesDrawerUnreadContent;
