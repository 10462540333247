import React from 'react';
import PropTypes from 'prop-types';

const ActionsIconGray = ({ className }) => (
  <div className={className}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="white" />
      <path
        d="M9.00024 17C9.55253 17 10.0002 16.5523 10.0002 16C10.0002 15.4477 9.55253 15 9.00024 15C8.44796 15 8.00024 15.4477 8.00024 16C8.00024 16.5523 8.44796 17 9.00024 17Z"
        fill="#747475"
        stroke="#747475"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9998 17C16.552 17 16.9998 16.5523 16.9998 16C16.9998 15.4477 16.552 15 15.9998 15C15.4475 15 14.9998 15.4477 14.9998 16C14.9998 16.5523 15.4475 17 15.9998 17Z"
        fill="#747475"
        stroke="#747475"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 17C23.5523 17 24 16.5523 24 16C24 15.4477 23.5523 15 23 15C22.4477 15 22 15.4477 22 16C22 16.5523 22.4477 17 23 17Z"
        fill="#747475"
        stroke="#747475"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

ActionsIconGray.propTypes = {
  className: PropTypes.string,
};

ActionsIconGray.defaultProps = {
  className: '',
};

export default ActionsIconGray;
