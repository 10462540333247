function Slice({ colorWithHash, iconClassName }) {
  return (
    <svg
      className={iconClassName}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9846 30.9979C32.0016 31.5499 31.5523 32 31 32L1 32C0.447716 32 3.91405e-08 31.5523 8.74228e-08 31L2.71011e-06 0.999997C2.75839e-06 0.447713 0.450128 -0.00158967 1.00215 0.0153915C17.8788 0.53456 31.4654 14.1212 31.9846 30.9979Z"
        fill={colorWithHash}
      />
    </svg>
  );
}

export default Slice;
