import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useOutsideClick } from 'hooks';
import Icon from 'pages/Workshop/Transactions/TransactionOverview/Icons';
import { useSelector, useDispatch } from 'react-redux';
import { getTransactionSelector, getTransactionAccessSelector } from 'store/selectors/transaction';
import { listedStatusesArray } from 'settings/constants/transaction';
import { useState, useRef } from 'react';
import List from './List';
import { ArrowDown, ArrowUp } from 'components/Icons';

import styles from './styles.module.scss';

const Status = (props) => {
  const { className, options, filter, handleStatusChange } = props;
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [openList, setOpenList] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newStatusValue, setNewStatusValue] = useState(null);
  const [requestPending, setRequestPending] = useState(false);

  const history = useHistory();
  const buttonRef = useRef(null);
  const modalRef = useRef(null);
  const listRef = useRef();
  const skipListed = !listedStatusesArray.includes(transaction?.Status);

  useOutsideClick([buttonRef], () => setOpenList(false));

  const onClick = (event) => {
    event.stopPropagation();
    setOpenList((val) => !val);
  };

  const onChange = (newStatus) => {
    setOpenList((val) => !val);
    handleStatusChange(newStatus.value);
  };

  return (
    <div
      ref={buttonRef}
      className={classNames(styles.status, { [styles.fullAccess]: fullAccess }, className)}
    >
      <div onClick={onClick} className={classNames(styles.inner)}>
        <span testid="status" className={styles.accent}>
          <span className={styles.statusValue}>{filter}</span>
          {openList ? (
            <ArrowUp className={styles.icon} color="#515151" />
          ) : (
            <ArrowDown className={styles.icon} color="#515151" />
          )}
        </span>
      </div>
      <List
        ref={listRef}
        open={openList}
        onChange={onChange}
        options={options}
        representingRoles={transaction?.RepresentingRoles}
        skipListed={skipListed}
      />
    </div>
  );
};

export default Status;
