import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { routes } from 'settings/navigation/routes';

export const VisitorNavLinks = (props) => {
  return (
    <div
      className={classNames(styles.headerButtons, {
        [styles.drawarNavHeader]: props.inDrawer,
      })}
    >
      <Link to={routes.login}>
        <button
          className={classNames(styles.button, styles.loginBtn)}
          title="Sign in"
          testid="login"
        >
          Sign in
        </button>
      </Link>

      {/*<Link to={routes.signUp}>*/}
      <Link to={{ pathname: 'https://www.mosaik.io/get-a-demo' }} target="_blank">
        <button
          className={classNames(styles.button, styles.tryMosaikBtn)}
          title="Try Mosaik"
          testid="retry_mosaikquest_quote"
        >
          Try Mosaik
        </button>
      </Link>
    </div>
  );
};
