import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getInviteTransactionParticipantsContactsFormattedList } from 'store/selectors/drawers/addParticipants';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { requestGetUserParticipantAndContactsListEffect } from 'store/effects/drawers/addParticipants';
import { Role } from 'app-constants';
import ParticipantInputView, { ValueObject } from './ParticipantInputView';

interface ParticipantInputProps {
  excludeUser?: boolean;
  className?: string;
  dropdownContainer?: string;
  userInfoContainer?: string;
  userNameContainer?: string;
  userEmailContainer?: string;
  value: ValueObject;
  onChange?: (...args) => void;
  onBlur?: (...args) => void;
  disabled?: boolean;
  placeholder?: string;
  allowedRoles?: Role[];
  excludedParticipantIds?: number[];
  currentQuestionId?: string;
}

const ParticipantInput = ({
  excludeUser = false,
  allowedRoles,
  currentQuestionId,
  ...props
}: ParticipantInputProps) => {
  let participants = useSelector(getInviteTransactionParticipantsContactsFormattedList || []);

  if (currentQuestionId === 'inviteClient') {
    participants = participants.filter((participant) => participant?.role === Role.Client);
  }

  if (
    currentQuestionId === 'agents' ||
    currentQuestionId === 'coordinators' ||
    currentQuestionId === 'whoIsRepresentingTheBuyer'
  ) {
    participants = participants.filter((participant) => participant?.role !== Role.Client);
  }

  return <ParticipantInputView participants={participants} {...props} />;
};

export default ParticipantInput;
