import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Icons = (props) => {
  const { variant, className, onClick, testid } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.ARROW: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 15L12 9L6 15"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.CLOSE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17 17L7 7" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M7 17L17 7" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icons, className)}>
      {getIcons()}
    </div>
  );
};

Icons.ARROW = 'arrow';
Icons.CLOSE = 'close';

Icons.propTypes = {
  testid: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

Icons.defaultProps = {
  className: '',
  variant: undefined,
  testid: undefined,
  onClick: () => {},
};

export default Icons;
