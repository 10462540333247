import AntSkeleton, { SkeletonProps as AntSkeletonProps } from 'antd/lib/skeleton';

import { Space } from 'components-antd/Space';

import styles from './styles.module.scss';

interface ListSkeletonProps extends AntSkeletonProps {
  rowCount?: number;
}

export const ListSkeleton = ({ rowCount = 5 }: ListSkeletonProps) => (
  <>
    {[...Array(rowCount)].map((el, index) => (
      <div className={styles.row} key={`skeleton-list-${index}`}>
        <div className={styles.image}>
          <AntSkeleton.Button active block shape={'square'} />
        </div>

        <div className={styles.description}>
          <div className={styles.data}>
            <AntSkeleton.Input active className={styles.data1} />
            <AntSkeleton.Input active className={styles.data2} />
            <AntSkeleton.Input active className={styles.data3} />
            <AntSkeleton.Input active className={styles.data4} />
          </div>
          <div className={styles.icons}>
            <AntSkeleton.Avatar active shape={'circle'} className={styles.icon1} />
            <AntSkeleton.Avatar active shape={'circle'} className={styles.icon2} />

            <AntSkeleton.Avatar active shape={'circle'} className={styles.icon3} />
            <AntSkeleton.Avatar active shape={'circle'} className={styles.icon4} />
          </div>
        </div>
      </div>
    ))}
  </>
);
