import { SVGProps } from 'react';

export const Menu = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="16" fill="#E8E9EA" />
    <circle cx="9" cy="16" r="2" fill="#262626" />
    <circle cx="16" cy="16" r="2" fill="#262626" />
    <circle cx="23" cy="16" r="2" fill="#262626" />
  </svg>
);
