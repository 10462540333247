import { getHrefLink } from 'helpers';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'components-antd';
import { useDispatch } from 'react-redux';
import { createPartnerFromThirdPartyEffect } from 'store/effects';
import { getUserDashboardEffect } from 'store/effects/adminPanel/users';
import styles from './styles.module.scss';

const ThirdPartyInfo = (props) => {
  const { className, user, userEmail } = props;
  const dispatch = useDispatch();
  if (!user) {
    return null;
  }

  const handleCreatePartner = () => {
    const id = user.Id;
    const values = {
      Email: userEmail,
      BusinessName: user?.Company?.BusinessName || '',
    };

    dispatch(
      createPartnerFromThirdPartyEffect({ values, id }, (err) => {
        if (!err) {
          dispatch(getUserDashboardEffect({ id }));
        }
      }),
    );
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.inputBlock}>
        <span className={styles.label}>Bio</span>
        <span className={styles.infoBlockForMultipleLines}>{user?.Bio}</span>
      </div>
      <div className={styles.inputBlock}>
        <span className={styles.label}>Company</span>
        <span className={styles.infoBlockForOneLine}>{user?.Company?.BusinessName}</span>
      </div>
      <div className={styles.inputBlock}>
        <span className={styles.label}>Company Address</span>
        <span className={styles.infoBlockForOneLine}>
          {`${user?.Company?.Address?.Line1}, ${user?.Company?.SuiteUnit}, ${user?.Company?.Address?.City}, ${user?.Company?.Address?.State}`}
        </span>
      </div>
      <div className={styles.inputBlock}>
        <span className={styles.label}>Job</span>
        <span className={styles.infoBlockForOneLine}>{user?.Company?.JobTitle}</span>
      </div>
      <div className={styles.inputBlock}>
        <span className={styles.label}>Website</span>
        <span className={styles.infoBlockForOneLine}>
          <a href={getHrefLink(user?.Company?.Website)} target="_blank" rel="noreferrer">
            {user?.Company?.Website}
          </a>
        </span>
      </div>
      <div className={styles.inputBlock}>
        <span className={styles.label}>Is Partner</span>
        <span className={styles.infoBlockForOneLine}>{user?.IsPartner ? 'Yes' : 'No'}</span>
        {!user?.IsPartner && (
          <Button className={styles.createPartner} onClick={handleCreatePartner}>
            Create Partner
          </Button>
        )}
      </div>
    </div>
  );
};

ThirdPartyInfo.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    Bio: PropTypes.string,
    Company: PropTypes.shape({
      BusinessName: PropTypes.string,
      Address: PropTypes.shape({
        Line1: PropTypes.string,
        State: PropTypes.string,
        City: PropTypes.string,
      }),
      JobTitle: PropTypes.string,
      SuiteUnit: PropTypes.string,
      Website: PropTypes.string,
    }),
    IsPartner: PropTypes.bool,
  }).isRequired,
};

ThirdPartyInfo.defaultProps = {
  className: '',
};

export default ThirdPartyInfo;
