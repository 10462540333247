import classNames from 'classnames';
import AntAutoComplete, { AutoCompleteProps } from 'antd/lib/auto-complete';

import styles from './styles.module.scss';

export const AutoComplete = ({ className, ...props }: AutoCompleteProps) => (
  <div className={styles.autoCompleteWrapper}>
    <AntAutoComplete
      bordered={false}
      size="large"
      className={classNames(styles.autoCompleteBase, className)}
      {...props}
    />
  </div>
);
