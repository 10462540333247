import { handleActions } from 'redux-actions';
import { setisCheckMessage, setNegativeCheckResponse } from 'store/actions/mozzie';

const initialData = {
  isCheckMessage: false,
  negativeCheckResponse: false,
};

export default handleActions(
  {
    [setisCheckMessage]: (state, { payload }) => ({
      ...state,
      isCheckMessage: payload,
    }),
    [setNegativeCheckResponse]: (state, { payload }) => ({
      ...state,
      negativeCheckResponse: payload,
    }),
  },
  initialData,
);
