import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { shortenNumber, getPropertyAddress, getFirstImage } from 'helpers';
import { useDispatch } from 'react-redux';
import { setPreFormQuestionsEffect } from 'store/effects/quotes';
import { preFormQuestionsIds } from 'settings/constants/preForm';

import styles from './styles.module.scss';

const Item = (props) => {
  const { className, propertyInfo, client } = props;
  const { Id, PhotoUrls, Address, SellingPrice, NumBeds, NumBathsTotal, Media } = propertyInfo;
  const firstImage = getFirstImage(Media);
  const ImageToSend = firstImage ? firstImage : PhotoUrls?.[0];
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(
      setPreFormQuestionsEffect({
        [preFormQuestionsIds.property]: {
          address: {
            ...(Address || {}),
            ProviderPlaceId: Id,
            PlaceName: getPropertyAddress(Address),
            Line2: undefined,
          },
        },
        [preFormQuestionsIds.isClient]: true,
        [preFormQuestionsIds.client]: {
          Id: client?.id,
          FirstName: client?.firstName,
          LastName: client?.lastName,
        },
      }),
    );
  };

  return (
    <div
      onClick={onClickHandler}
      className={classNames(styles.item, { [styles.disabled]: !Address?.Line1 }, className)}
      testid="property_item"
    >
      <div className={styles.photo}>
        <img testid="image" src={ImageToSend} alt="" />
      </div>
      <div className={styles.info}>
        <div testid="address_1" className={styles.title}>
          {`${Address?.Line1}, ${Address?.City}, ${Address?.State}`}
        </div>
        <div testid="details" className={styles.details}>{`$${shortenNumber(
          SellingPrice,
        )}, ${NumBeds}+ beds, ${NumBathsTotal}+ baths`}</div>
      </div>
      <div testid="select" className={styles.select}>
        Select
      </div>
    </div>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    Address: PropTypes.shape({
      Line1: PropTypes.string,
      City: PropTypes.string,
      State: PropTypes.string,
      Zip: PropTypes.string,
    }),
    PhotoUrls: PropTypes.arrayOf(PropTypes.string),
    SellingPrice: PropTypes.number,
    NumBeds: PropTypes.number,
    NumBathsTotal: PropTypes.number,
  }).isRequired,
  client: PropTypes.object,
};

Item.defaultProps = {
  className: '',
};

export default Item;
