import * as Yup from 'yup';

// eslint-disable-next-line
const urlRegex =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const ValidationSchema = (country) =>
  Yup.object().shape({
    BuyingAgentName: Yup.string().max(100).trim().required('Required'),
    BuyingAgentBrokerage: Yup.string().max(100).trim().required('Required'),
    BuyingAgentPhone: getPhoneValidator(country),
    BuyingAgentEmail: Yup.string().max(100).trim().email('Invalid email').required('Required'),
  });

const getPhoneValidator = (country) => {
  const countryCode = country?.countryCode?.toUpperCase() || 'US';
  return Yup.string()
    .phone(countryCode, false, `Must be a valid phone number for region ${countryCode}`)
    .required('Required');
};
