import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardTypes } from './../../..';
import { Row, Col, Button } from 'components-antd';
import { SplitTabTypes, SplitTypes } from '../../../../TransactionFinancials';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from '../../.././Error/ErrorMessage';
import { SplitItem, EditDynamicSplit, DynamicSplitItem } from '..';
import { EditSplitItem } from '../EditSplitItem';
import { Icons } from 'pages/Workshop/Icons';

import { updateFinancialSplits } from 'api/financials';

import styles from './styles.module.scss';
import { showErrorMessage } from 'helpers';

export interface SplitType {
  Id: number;
  SplitTitle: string;
  SplitType: SplitTypes.AGENT | SplitTypes.TEAM | SplitTypes.BROKERAGE | SplitTypes.CLIENT;
  IsRestricted?: boolean;
  CreatedDate: string;
  UpdatedDate: string;
}

export interface SplitData {
  Id: number;
  SplitTypeId: number;
  SplitSide: string;
  SplitValue: number;
  IsSplitTypePercentage: boolean;
  defaultSplitValueType?: boolean;
  SplitPercentage: number;
  CreatedDate: string;
  UpdatedDate: string;
  SplitType: SplitType;
}

interface SplitCardProps {
  data: SplitData[];
  fetchFinancials;
  getSplitList;
  comparePrice;
  isTransactionAdminOrOwner: boolean;
  splitType: SplitTabTypes.LISTING | SplitTabTypes.PURCHASE;
  autocompleteSplitNames: string[];
}

export const SplitCard: React.FC<SplitCardProps> = ({
  data = [],
  fetchFinancials,
  getSplitList,
  comparePrice,
  isTransactionAdminOrOwner,
  splitType,
  autocompleteSplitNames,
}) => {
  const [editFieldKey, setEditFieldKey] = useState(null);
  const [splitList, setSplitList] = useState<SplitData[]>([]);
  const [dynamicSplitList, setDynamicSplitList] = useState<SplitData[]>([]);
  const [isEditInput, setEditInput] = useState(false);
  const [isUpdateSplitLoading, setUpdateSplitLoading] = useState(false);
  const [isValidate, setValidate] = useState(false);
  const [isUpdateButtonDisable, setIsUpdateButtonDisable] = useState(false);
  const [isAddSplitToggle, setIsAddSplitToggle] = useState(false);

  const params: { id?: string } = useParams();

  useEffect(() => {
    // Modify data to show % by default on the Inputs.
    const modifyData = data?.map((item) => ({
      ...item,
      defaultSplitValueType: item?.IsSplitTypePercentage,
      IsSplitTypePercentage: true,
    }));

    // Setting Dynamic Split List: Splits other than Agent, Team, Brokerage type.
    const dynamicSplits = modifyData.filter(
      (item) =>
        ![SplitTypes.AGENT, SplitTypes.TEAM, SplitTypes.BROKERAGE, SplitTypes.CLIENT].includes(
          item?.SplitType?.SplitType,
        ),
    );
    setDynamicSplitList(dynamicSplits);

    // Setting Split List: Splits Agent, Team, Brokerage type.
    const splits = modifyData.filter((item) =>
      [SplitTypes.AGENT, SplitTypes.TEAM, SplitTypes.BROKERAGE, SplitTypes.CLIENT].includes(
        item?.SplitType?.SplitType,
      ),
    );
    setSplitList(splits);
    getSplitList(modifyData);
  }, [isEditInput]);

  const isPriceWithinRange = (sum) => {
    if (sum === comparePrice || sum < comparePrice) {
      setValidate(false);
      setIsUpdateButtonDisable(false);
    } else {
      setValidate(true);
      setIsUpdateButtonDisable(true);
    }
  };

  const handleUpdate = async () => {
    const transformedData =
      splitList &&
      splitList.map((item) => ({
        ...(item?.SplitTypeId ? { Id: item?.Id } : {}),
        ...(item?.SplitTypeId
          ? { SplitTypeId: item?.SplitTypeId }
          : { SplitType: item?.SplitType?.SplitType }),
        SplitValue: item?.IsSplitTypePercentage
          ? item?.SplitPercentage || 0
          : item?.SplitValue || 0,
        IsSplitTypePercentage: item?.IsSplitTypePercentage,
        SplitSide: item?.SplitSide,
      }));

    try {
      setUpdateSplitLoading(true);
      await updateFinancialSplits({
        transactionId: params?.id,
        data: transformedData,
      });
      fetchFinancials();
    } catch (err) {
      setUpdateSplitLoading(false);
      showErrorMessage(err);
    } finally {
      setUpdateSplitLoading(false);
      setEditInput(false);
    }
  };

  const setEditField = (value) => {
    setEditInput(value);
  };

  const onCancel = () => {
    setSplitList(data);
    setEditInput(false);
    setValidate(false);
  };

  const updateSplitValue = (id, updatedObject) => {
    const filteredArray =
      splitList && [...splitList, ...dynamicSplitList].filter((obj) => obj.Id !== id);

    let sum = 0;
    let calculatedValue = 0;
    const comparedToBeSum = filteredArray.reduce((acc, obj) => {
      if (typeof obj.SplitValue === 'number' || obj.SplitValue === null) {
        if (obj?.IsSplitTypePercentage) {
          return acc + (obj?.SplitPercentage / 100) * comparePrice;
        }
        return acc + obj.SplitValue;
      }
      return acc;
    }, 0);
    if (updatedObject?.IsSplitTypePercentage) {
      calculatedValue = (updatedObject?.SplitValue / 100) * comparePrice;
    } else {
      calculatedValue = updatedObject?.SplitValue;
    }
    sum = +calculatedValue + +comparedToBeSum;

    if (updatedObject?.SplitValue === null || !isNaN(updatedObject?.SplitValue)) {
      setValidate(false);
      isPriceWithinRange(parseFloat(sum?.toFixed(2)));
    }
    let objectToUpdate = {
      IsSplitTypePercentage: updatedObject?.IsSplitTypePercentage,
      ...(updatedObject?.IsSplitTypePercentage
        ? {
            SplitPercentage: updatedObject?.SplitValue,
          }
        : {
            SplitValue: updatedObject?.SplitValue,
          }),
    };
    setSplitList((prevList) =>
      prevList.map((item) => (item.Id === id ? { ...item, ...objectToUpdate } : item)),
    );
  };

  const handleAddClick = () => {
    setIsAddSplitToggle(true);
    setEditField(false);
    setValidate(false);
  };

  return (
    <div style={{ marginTop: '24px' }}>
      <Card variant={CardTypes.ORANGE}>
        <div className={styles.splitsContainer}>
          <div className={styles.headerContainer}>
            <p className={styles.title} style={{ marginBottom: isEditInput ? '0' : '1rem' }}>
              Splits
            </p>
            <div className={styles.addSplitCon} onClick={handleAddClick}>
              <Icons variant={Icons.PLUS} />
              <p className={styles.addSplit}>Add Split</p>
            </div>
          </div>
          {!isEditInput ? (
            splitList &&
            splitList.map((item, index) => (
              <Fragment key={item.Id}>
                <SplitItem
                  label={item?.SplitType?.SplitTitle}
                  amount={item?.SplitValue}
                  percentage={item?.SplitPercentage}
                  setEditField={setEditField}
                  valueTypeEntered={item?.defaultSplitValueType} // Property tells which type of value(% or $) user added while adding Split.
                  splitType={item?.SplitType?.SplitType}
                  isTransactionAdminOrOwner={isTransactionAdminOrOwner}
                  isDisabledField={isAddSplitToggle || editFieldKey !== null}
                />
              </Fragment>
            ))
          ) : (
            <>
              {splitList &&
                splitList.map((item, index) => (
                  <EditSplitItem
                    key={index}
                    item={item}
                    updateSplitValue={updateSplitValue}
                    isTransactionAdminOrOwner={isTransactionAdminOrOwner}
                  />
                ))}
              <Row style={{ margin: '14px 0' }}>
                <Col lg={24}>
                  {isValidate && (
                    <ErrorMessage message="The cumulative value of the splits cannot exceed the GCI value." />
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className={styles.buttonsContainer}>
                    <Button
                      variant="secondary"
                      size="large"
                      disabled={isUpdateButtonDisable}
                      loading={isUpdateSplitLoading}
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>
                    <Button variant="hollow-bordered" size="large" onClick={onCancel}>
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}

          {/* DYNAMIC SPLITS */}

          {dynamicSplitList.map((item, index) => (
            <DynamicSplitItem
              key={index}
              item={item}
              autocompleteSplitNames={autocompleteSplitNames}
              transactionId={params?.id}
              updateSplitValue={updateSplitValue}
              isValidate={isValidate}
              fetchFinancials={fetchFinancials}
              isTransactionAdminOrOwner={isTransactionAdminOrOwner}
              isDisabledField={
                isAddSplitToggle ||
                (editFieldKey !== null && item?.Id !== editFieldKey) ||
                isEditInput
              }
              setEditFieldKey={(editFieldKey) => setEditFieldKey(editFieldKey)}
            />
          ))}

          {isAddSplitToggle && (
            <EditDynamicSplit
              updateSplitValue={updateSplitValue}
              isTransactionAdminOrOwner={isTransactionAdminOrOwner}
              transactionId={params?.id}
              fetchFinancials={fetchFinancials}
              onCancel={() => setIsAddSplitToggle(false)}
              isValidate={isValidate}
              splitType={splitType}
              autocompleteSplitNames={autocompleteSplitNames}
            />
          )}
        </div>
      </Card>
    </div>
  );
};
