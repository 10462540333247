import styles from '../styles.module.scss';
import { Avatar } from 'components';
import moment from 'moment';
import { Button, Space } from 'components-antd';

import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';
import DateTime from 'pages/Workshop/Transactions/TransactionDocuments/components/DocumentsContent/DateTime';

export function tableColumns(onPreview) {
  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'Title',
      width: 450,
      render: (_, row) => {
        const getSecondLineText = () => {
          const { IsProject, Name, Property } = row?.Transaction ?? {};
          return IsProject ? Name : Property?.Address?.Line1;
        };
        return (
          <Space size="small" className={styles.documentName}>
            <Icon variant={Icon.FILE} />
            <div>
              <strong>{row?.Title}</strong>
              <span className={styles.line2}>{getSecondLineText()}</span>
            </div>
          </Space>
        );
      },
      sorter: (a, b) => a.Title?.localeCompare(b.Title),
    },
    {
      title: 'Owner',
      key: 'owner',
      dataIndex: 'Uploader',
      sorter: (a, b) => {
        if (a.Uploader.FirstName < b.Uploader.FirstName) return -1;
        if (a.Uploader.FirstName > b.Uploader.FirstName) return 1;
        return 0;
      },
      width: 250,

      render: (Uploader) => {
        return (
          <div className={styles.clientField}>
            <Avatar
              avatarClassName={styles.avatar}
              placeholder={
                <span
                  className={styles.avatarText}
                >{`${Uploader.FirstName[0]} ${Uploader.LastName[0]}`}</span>
              }
            />
            <span>{`${Uploader.FirstName} ${Uploader.LastName}`}</span>
          </div>
        );
      },
    },
    {
      title: 'Created',
      width: 150,
      key: 'created',
      dataIndex: 'DateUploaded',
      sorter: (a, b) => moment(a.DateUploaded).unix() - moment(b.DateUploaded).unix(),
      render: (_, { DateUploaded }) => <DateTime value={DateUploaded} />,
    },
    {
      title: '',
      key: '',
      dataIndex: '',
      width: 75,
      render: (_, row) => {
        return (
          <div testid="task_view_btn" onClick={() => {}} className={styles.viewBtn}>
            <Button
              variant="bordered-text-only"
              className={styles.signBtn}
              onClick={() => onPreview(row?.DocumentVaultUUID, row?.Title)}
            >
              <span>View</span>
            </Button>
          </div>
        );
      },
    },
  ];

  return columns;
}
