import { useEffect, useMemo, useRef, useState } from 'react';
import { Row, Col, Checkbox, Tooltip } from 'components-antd';
import { Select } from 'antd';
import { showErrorMessage } from 'helpers/errors';
import { EditAvailability } from '../components';
import { Navigation } from '../../components';
import { Icons } from '../../Icons';

import styles from './styles.module.scss';
import { isEmail } from 'utils';
import { prepareOptions } from './helper';

export const Notify = ({
  onNext,
  stageIndex,
  onPrev,
  onUpdate,
  onFinish,
  setCurrentStageIndex,
  data,
  transaction,
  participantList,
  stagesStep,
}) => {
  const { InvolveSellerAgent, InvolveSeller, ConfirmedBookingNotification, DelegateList, FYIList } =
    data;

  const [state, setState] = useState({
    ...ConfirmedBookingNotification,
    InvolveSellerAgent,
    InvolveSeller,
    FYI: !!FYIList.length,
    Delegates: !!DelegateList.length,
    FYIList: FYIList || [],
    DelegateList: DelegateList || [],
  });

  const [disabled, setDisabled] = useState(false);
  const [disclaimerFYI, setDisclaimerFYI] = useState(false);
  const [disclaimerDelegate, setDisclaimerDelegate] = useState(false);

  const creator = transaction?.Creator;
  const seller = transaction?.Participants?.find((p) => p?.Role?.Title === 'Seller');

  const delegateOptions = useMemo(
    () => prepareOptions(participantList, seller, creator, true),
    [participantList],
  );

  const FYIOptions = useMemo(
    () => prepareOptions(participantList, seller, creator),
    [participantList],
  );

  const onChange = (data) => {
    setState({ ...state, ...data });
  };

  const handleChange = (emails, key) => {
    setDisabled(false);
    setState({
      ...state,
      [key]: emails.map((e) => {
        if (isEmail(e) === null) {
          const participant = participantList.find((p) => p.Email === e);
          if (!participant) {
            setDisabled(true);
            showErrorMessage('Invalid Email');
          }
        }
        const participant = participantList.find((p) => p.Email === e);
        return participant
          ? {
              UserId: participant.Id,
              Email: participant.Email,
              FirstName: participant.FirstName,
              LastName: participant.LastName,
            }
          : { Email: e };
      }),
    });
  };

  const getCurrentValues = (key) => {
    const values = state[key];
    return values?.map((v) => {
      const participant = participantList.find((p) => p.Id === v.UserId);
      return participant ? participant.Email : v.Email;
    });
  };

  const onSubmit = () => {
    const body = {
      ConfirmedBookingNotification: state,
      InvolveSellerAgent: state.InvolveSellerAgent,
      InvolveSeller: state.InvolveSeller,
      FYIList: state.FYI ? state.FYIList : [],
      DelegateList: state.Delegates ? state.DelegateList : [],
    };
    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(stagesStep.EnableLink);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const bottomRef: any = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [stageIndex]);
  return (
    <div className={styles.parties}>
      <div className={styles.contentWrapper}>
        <EditAvailability
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.Availability)}
        />

        <div ref={bottomRef} className={styles.content}>
          <h4 className={styles.title}>
            <Icons className={styles.notifyIcon} variant="notification" /> Who should receive
            notifications regarding showings?
          </h4>
          <Row className={styles.checkBoxes} gutter={[0, 12]}>
            <Col span={24}>
              <div className={styles.checkboxArea}>
                <Checkbox
                  checked={state.Self}
                  onChange={(e) => onChange({ Self: e.target.checked })}
                  defaultChecked={state.Self}
                  className={styles.checkboxSquare}
                >
                  Me
                </Checkbox>
              </div>
            </Col>
            {seller && (
              <Col span={24}>
                <div className={styles.checkboxArea}>
                  <Checkbox
                    checked={state.Seller}
                    onChange={(e) => onChange({ Seller: e.target.checked })}
                    defaultChecked={state.Seller}
                    className={styles.checkboxSquare}
                  >
                    Seller ({seller.FirstName} {seller.LastName})
                  </Checkbox>
                </div>
              </Col>
            )}
            <Col span={24}>
              <div className={styles.checkboxArea}>
                <Checkbox
                  checked={state.Delegates}
                  defaultChecked={state.Delegates}
                  onChange={() => onChange({ Delegates: !state.Delegates })}
                  className={styles.checkboxSquare}
                >
                  Delegates
                </Checkbox>
                <Tooltip
                  visible={disclaimerDelegate}
                  title={
                    <span>
                      Someone other than the agent or seller who will need to approve/reject/cancel
                      showings, such as the agent’s assistant or a renter/occupant
                    </span>
                  }
                  overlayInnerStyle={{ borderRadius: '1rem', padding: '1rem', minWidth: '17rem' }}
                  color="#262626"
                >
                  <div
                    onMouseEnter={() => setDisclaimerDelegate(true)}
                    onMouseLeave={() => setDisclaimerDelegate(false)}
                    className={styles.infoIcon}
                  >
                    <Icons variant={Icons.INFO} />
                  </div>
                </Tooltip>
              </div>
              {state.Delegates && (
                <div className={styles.selectorContainer}>
                  <Select
                    mode="tags"
                    className={styles.selector}
                    onChange={(v) => handleChange(v, 'DelegateList')}
                    value={getCurrentValues('DelegateList')}
                    options={delegateOptions}
                    popupClassName={styles.selectPopup}
                  />
                </div>
              )}
            </Col>
            <Col span={24}>
              <div className={styles.checkboxArea}>
                <Checkbox
                  checked={state.FYI}
                  className={styles.checkboxSquare}
                  defaultChecked={state.FYI}
                  onChange={() => onChange({ FYI: !state.FYI })}
                >
                  FYI
                </Checkbox>
                <Tooltip
                  visible={disclaimerFYI}
                  title={
                    <span>
                      People in this role will receive notifications of confirmed showings,
                      cancelled showings, etc., but won’t be able to approve, reschedule, cancel, or
                      decline
                    </span>
                  }
                  overlayInnerStyle={{ borderRadius: '1rem', padding: '1rem', minWidth: '17rem' }}
                  color="#262626"
                >
                  <div
                    onMouseEnter={() => setDisclaimerFYI(true)}
                    onMouseLeave={() => setDisclaimerFYI(false)}
                    className={styles.infoIcon}
                  >
                    <Icons variant={Icons.INFO} />
                  </div>
                </Tooltip>
              </div>
              {state.FYI && (
                <div className={styles.selectorContainer}>
                  <Select
                    mode="tags"
                    className={styles.selector}
                    onChange={(v) => handleChange(v, 'FYIList')}
                    defaultValue={getCurrentValues('FYIList')}
                    options={FYIOptions}
                    popupClassName={styles.selectPopup}
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onPrev={onPrev}
        onFinish={onFinish}
        className={styles.footer}
        showBackButton={false}
      />
    </div>
  );
};
