export type ClientKitDataType = {
  firstName: string;
  lastName: string;
  avatarUrl: string;
  addressCount: number;
  activeKitsCount: number;
  eligibleKitsCount: number;
};

export type KitStateConfigurationUpdateType = {
  enabled?: boolean;
  frequencyId?: number;
};

export enum kitsTimeFrequencies {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Biannually = 'Biannually',
  OneTime = 'One Time',
  AdHoc = 'Ad Hoc',
}
