import fileDownload from 'js-file-download';

import { Popover } from 'components-antd';
import { Icons, ICON_VARIANT_TYPE } from 'pages/Workshop/Forms/Icons';
import { useDispatch } from 'react-redux';
import { downloadNonVoidDocumentEffect, previewDocumentEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';
import { useState } from 'react';
import classNames from 'classnames';

export interface MessageDataProps {
  id: number;
  name: string;
  avatar: string;
}

interface CustomDropDownProps {
  messageData?: MessageDataProps;
  allowProfile?: boolean;
  allowResend?: boolean;
  allowReplace?: boolean;
  allowRemove?: boolean;
  isDocument?: boolean;
  allowMessage?: boolean;
  pdfUrl?: string;
  onResend?: () => void;
  onReplace?: () => void;
  onRemove?: () => void;
  onViewForm?: () => void;
  onMessage?: (messageData: MessageDataProps) => void;
  selectedFormProcess?: number;
  formProcessDocumentId?: number;
  isVoid?: Boolean;
}

export const CustomDropDown = ({
  messageData,
  allowProfile,
  isDocument,
  allowResend,
  allowReplace,
  allowRemove,
  allowMessage,
  pdfUrl,
  onResend,
  onReplace,
  onRemove,
  onViewForm,
  onMessage,
  selectedFormProcess,
  formProcessDocumentId,
  isVoid,
}: CustomDropDownProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleDownload = () => {
    if (selectedFormProcess && !isVoid) {
      dispatch(
        downloadNonVoidDocumentEffect(
          {
            formProcessId: selectedFormProcess,
            formProcessDocumentId: formProcessDocumentId,
          },
          {},
          (err, response) => {
            if (!err) {
              fileDownload(response.data, response.headers['file-name']);
            }
          },
        ),
      );
    } else {
      dispatch(
        previewDocumentEffect(
          {
            DocumentVaultUUID: pdfUrl,
          },
          {},
          (err, response) => {
            if (!err) {
              fileDownload(response.data, response.headers['file-name']);
            }
          },
        ),
      );
    }
  };

  return (
    <Popover
      content={
        isDocument ? (
          <div className={styles.entityOptions}>
            {onViewForm && (
              <p onClick={() => onViewForm()}>
                <span className={styles.optionIcon}>
                  <Icons
                    variant={ICON_VARIANT_TYPE.INCOMPLETE_DOCUMENT}
                    width="16px"
                    height="16px"
                    stroke="#FF576D"
                    strokeWidth={'3'}
                  />
                </span>
                <span>View Form</span>
              </p>
            )}
            <p>
              <span className={styles.downloadOption} onClick={handleDownload}>
                <span className={styles.optionIcon}>
                  <Icons variant={ICON_VARIANT_TYPE.DOWNLOAD} stroke="#FF576D" />
                </span>
                <span className={styles.downloadText}>Download PDF</span>
              </span>
            </p>
          </div>
        ) : (
          <div className={styles.entityOptions}>
            {allowResend && (
              <p onClick={() => onResend && onResend()}>
                <span className={styles.optionIcon}>
                  <Icons variant={ICON_VARIANT_TYPE.RESEND} />
                </span>
                <span>Resend</span>
              </p>
            )}

            {allowProfile && (
              <p>
                <span className={styles.optionIcon}>
                  <Icons variant={ICON_VARIANT_TYPE.PROFILE} />
                </span>
                <span>Profile</span>
              </p>
            )}

            {allowMessage && (
              <p onClick={() => onMessage && messageData && onMessage(messageData)}>
                <span className={styles.optionIcon}>
                  <Icons variant={ICON_VARIANT_TYPE.MESSAGE} />
                </span>
                <span>Message</span>
              </p>
            )}

            {allowReplace && (
              <p
                onClick={() => {
                  setOpen(false);
                  onReplace && onReplace();
                }}
              >
                <span className={styles.optionIcon}>
                  <Icons variant={ICON_VARIANT_TYPE.REPLACE} />
                </span>
                <span>Replace</span>
              </p>
            )}

            {allowRemove && (
              <p onClick={() => onRemove && onRemove()}>
                <span className={styles.optionIcon}>
                  <Icons variant={ICON_VARIANT_TYPE.REMOVE} />
                </span>
                <span>Remove</span>
              </p>
            )}
          </div>
        )
      }
      className={styles.antdCustomPopover}
      trigger="click"
      placement="bottomRight"
      getPopupContainer={(e) => e}
      open={open}
      overlayClassName={styles.entityOptionsDropDown}
      onOpenChange={(visible) => setOpen(visible)}
    >
      <span
        className={classNames(styles.buttonDot, {
          [styles.openedButtonDot]: open,
        })}
      >
        <Icons variant={ICON_VARIANT_TYPE.SIMPLE_DOTS} height="32px" width="32px" />
      </span>
    </Popover>
  );
};
