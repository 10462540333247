import classNames from 'classnames';

import { Select, Tooltip } from 'components-antd';
import { GreenCheckMark, StrokesIcon } from 'components/Icons';
import { Allowed_Stroke_Sizes } from 'app-constants';
import DropdownIcon from 'pages/FormBuilder/Icons/Dropdown';

import styles from './styles.module.scss';

export const StrokeDropDown = ({ setStrokeWidth, strokeWidth }) => {
  const strikeLabel = (size: number) => {
    return (
      <div
        className={styles.strikeLabel}
        onClick={(e) => setStrokeWidth(size)}
        key={`strike-${size}`}
      >
        <div className={styles.strikeLabelColumn}>
          <div
            className={styles.stroke}
            style={{
              height: `${size}px`,
            }}
          >
            &nbsp;
          </div>
          <div className={styles.strokeNumber}>{size}px</div>
        </div>
        {size === strokeWidth && <GreenCheckMark />}
      </div>
    );
  };

  const strokeOptions = (
    <div className={styles.strokeListContainer}>
      {Allowed_Stroke_Sizes.map((size) => strikeLabel(size))}
    </div>
  );

  return (
    <div className={styles.strokeListContainer}>
      <Tooltip
        title={strokeOptions}
        color="white"
        overlayClassName={styles.strokeToolTipContainer}
        placement="bottomRight"
      >
        <div className={classNames(styles.settingSwitch, styles.strokeSelect)}>
          <StrokesIcon className={styles.strokeIcon} />
          <DropdownIcon className={classNames(styles.dropDownIcon)} />
        </div>
      </Tooltip>
    </div>
  );
};
