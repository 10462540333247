import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import { PENDING } from 'settings/constants/apiState';
import { THIRD_PARTY } from 'settings/constants/roles';
import { onBoardingSignUpEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getUserDataSelector, isPartnerSelector } from 'store/selectors/user';

import { ValidationSchema, ValidationPartnerSchema } from './validation';
import Organization from './Organization';
import Partner from './Partner';
import { FullArrowRight } from 'components/Icons';
import { OnBoardingWrapper } from '../../OnBoardingWrapper';
import { Question } from 'pages/RequestQuote/components';
import { OnBoardingContainer } from '../../OnBoardingContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from '../agent/components/OnboardingContinueButton';

import styles from './styles.module.scss';

const initialValues = {
  organization: '',
  jobTitle: '',
  address: null,
  suiteUnit: '',
  businessName: '',
  additionalBusinessName: '',
  email: '',
  partnerEmail: '',
  partnerPhone: '',
  description: '',
  website: '',
};

const OrganizationDetailsForm = ({ stageIndex, onNext, Controls }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const user = useSelector(getUserDataSelector);
  const isPartner = useSelector(isPartnerSelector);

  const getSchema = () => {
    if (isPartner) {
      return ValidationPartnerSchema;
    }
    return ValidationSchema;
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      businessName: user?.[THIRD_PARTY]?.Partner?.BusinessName || initialValues.businessName,
      additionalBusinessName:
        user?.[THIRD_PARTY]?.Partner?.AdditionalBusinessName ||
        initialValues.additionalBusinessName,
      partnerEmail: user?.[THIRD_PARTY]?.Partner?.Email || initialValues.partnerEmail,
      partnerPhone: user?.[THIRD_PARTY]?.Partner?.PhoneNumber || initialValues.partnerPhone,
      address: user?.[THIRD_PARTY]?.Partner?.Address || initialValues.address,
      suiteUnit: user?.[THIRD_PARTY]?.Partner?.SuiteUnit || initialValues.suiteUnit,
      description: user?.[THIRD_PARTY]?.Partner?.Description || initialValues.description,
      website: user?.[THIRD_PARTY]?.Partner?.Website || initialValues.website,
    },
    validationSchema: getSchema(),
    onSubmit(values) {
      dispatch(
        onBoardingSignUpEffect(
          { ...values, stageIndex },
          { isPartner, isCommonInfo: true },
          (err) => {
            if (!err) {
              onNext();
            }
          },
        ),
      );
    },
  });

  const isPending = onBoarding.state === PENDING;

  const getTitle = () => {
    if (isPartner) {
      return 'Profile Information';
    }
    return 'Organization details';
  };

  return (
    <OnBoardingWrapper isPending={isPending}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question className={{ [styles.partnerTitle]: isPartner }}>{getTitle()}</Question>
        {isPartner && (
          <p className={styles.partnerSubheader}>
            This is the information that will be displayed on your partner profile in our Directory
            and will be visible to potential clients.
          </p>
        )}
        <AnswersContainer>
          <form testid="organization_form" onSubmit={formik.handleSubmit}>
            {!isPartner ? (
              <Organization formik={formik} isPending={isPending} />
            ) : (
              <Partner formik={formik} isPending={isPending} />
            )}
            <ButtonsContainer>
              <OnboardingContinueButton
                testid="done"
                title="Continue"
                type="submit"
                className={styles.submit}
                isPending={isPending}
                onClick={formik.handleSubmit}
              />
            </ButtonsContainer>
          </form>
        </AnswersContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

OrganizationDetailsForm.propTypes = {
  stageIndex: PropTypes.number,
  onNext: PropTypes.func,
  Controls: PropTypes.elementType,
};

OrganizationDetailsForm.defaultProps = {
  stageIndex: undefined,
  onNext: () => {},
  Controls: () => null,
};

export default OrganizationDetailsForm;
