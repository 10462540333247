import React, { useState } from 'react';

import SettingsFullProfileViewMode from './ViewMode';
import SettingsFullProfileEditMode from './EditMode';

const SettingsFullProfile = () => {
  const [isEditMode, setEditMode] = useState(false);
  const changeEditMode = () => setEditMode((val) => !val);

  return (
    <>
      {isEditMode ? (
        <SettingsFullProfileEditMode changeEditMode={changeEditMode} />
      ) : (
        <SettingsFullProfileViewMode changeEditMode={changeEditMode} />
      )}
    </>
  );
};

export default SettingsFullProfile;
