import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Table } from 'components-antd';
import styles from './styles.module.scss';
import { SpinProps } from 'antd';

interface ModalTableProps {
  loading?: boolean | SpinProps | undefined;
  dataSource: any;
  onRow?: any;
  columns: any[];
  rowClassName?: any;
  tableClassName?: string;
}

export const TeamsTable = ({
  loading = false,
  dataSource,
  onRow = null,
  columns,
  rowClassName = '',
  tableClassName = '',
}: ModalTableProps) => {
  if (dataSource?.length === 0) return null;

  return (
    <div className={styles.modalsList}>
      <Table
        className={classNames(styles.modalsTable, tableClassName)}
        loading={loading}
        bordered={false}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey="id"
        onRow={onRow}
        rowClassName={rowClassName}
      />
    </div>
  );
};
