import moment from 'moment';

export const initState = {
  Status: 'Active',
  PropertyTransactionId: null,
  SelectedDuration: {
    isIndefinite: false,
    isAddDays: false,
    isDateRange: false,
    isDeadline: false,
  },
  Indefinitely: false,
  LinkActiveStartDate: null,
  LinkActiveDays: null,
  LinkActiveEndDate: null,
  DeadlineDate: null,
  DeadlineTime: null,
  Timezone: moment.tz.guess(),
  DisplayDeadlineOnPortal: false,
  DisplaySubmissionPageNotes: false,
  SubmissionPageNotes: null,
  SendOfferSubmissionNotes: false,
  OfferSubmissionNotes: null,
  AutomaticallySendOffersToSeller: undefined,
  RequiredDocuments: [
    {
      name: 'Offer',
      required: false,
      selected: false,
      format: '*',
    },
    {
      name: 'Pre-Approval/Proof of Funds',
      required: false,
      selected: false,
      format: '*',
    },
  ],
  EnableLink: false,
  Link: '',
};
