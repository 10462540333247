import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const ParkingSpaces = (props) => {
  const { className, onClick } = props;

  return (
    <button type="button" onClick={onClick} className={classNames(styles.addWrapper, className)}>
      <Icon testid="add_icon" variant={Icon.PLUS} />
    </button>
  );
};

ParkingSpaces.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ParkingSpaces.defaultProps = {
  className: '',
  onClick: () => {},
};

export default ParkingSpaces;
