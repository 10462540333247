import classnames from 'classnames';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, Fragment } from 'react';

import { ArchiveViewType, FormProcessTypes } from 'types';
import { Tooltip, Row, Col, Footer } from 'components-antd';
import { LocalHeader } from './LocalHeader';
import { FormPage } from './components/FormPage';
import { ArchivePage } from './components/ArchivePage';
import { ArchiveView } from 'app-constants';

import { DraftFormModal, FormProcessDataType } from './components/DraftFormModal';
import { getFormsFiltersSelector } from 'store/selectors/requestFormProcess';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import {
  setFormLibraryModalVisibleAction,
  updateFormsFiltersAction,
} from 'store/actions/formProcess';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import {
  deleteDraftFormEffect,
  getAllFormProcessEffect,
  getDraftFormsEffect,
  setIsArchiveEffect,
  getFormMetaEffect,
  resetSmartFormDataEffect,
} from 'store/effects/formProcess';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { handleViewForm } from 'utils';
import { FormDetails } from './components/FormDetails';
import { FormsTable } from './components/FormsTable/FormsTable';
import { getAggregatePageTypeSelector, getViewModeSelector } from 'store/selectors/workshop';
import { ViewModes } from 'app-constants';
import { ICON_VARIANT_TYPE, Icons } from './Icons';
import { FormsTableStatus } from 'app-constants';
import { FormProcessType } from 'types';
import { LocationService } from 'services';
import { addProOrTransFilterParam } from 'utils/aggregatedPageTypeHelper';

import styles from './styles.module.scss';
import { NewFormModal } from './components/NewFormModal';
import { FormSelectionFooter } from './components/FormSelectionFooter';
import { MultiFormsUpdateModal } from './components/MultiFormsUpdateModal';

import _ from 'lodash';

interface locationTypes {
  showDraftsModal?: boolean;
  showFormModal?: boolean;
  FormProcessId?: number;
}

const locationService = new LocationService();

export type onHandleViewFormType = (url: string, type: FormProcessTypes) => void;

export const Forms = () => {
  const history = useHistory();
  const location = useLocation();
  locationService.setLocation(location);
  const locationState = location.state as locationTypes;
  const dispatch = useDispatch();
  const viewMode = useSelector(getViewModeSelector);
  const { search, ...restFilters } = useSelector(getFormsFiltersSelector);

  const [isArchive, setArchive] = useState(false);
  const [showDraftModal, setShowDraftModal] = useState(false);
  const [selectedFormProcess, setSelectedFormProcess] = useState<number>();
  const [selectedFormStatus, setSelectedFormStatus] = useState<string>();
  const [showSelection, setShowSelection] = useState<boolean>(false);
  const [selectedFormIds, setSelectedFormIds] = useState<number[]>([]);
  const [selectedFormNames, setSelectedFormNames] = useState<string[]>([]);
  const [showFormUpdateModal, setShowFormUpdateModal] = useState<boolean>(false);

  const aggregatedPageType = useSelector(getAggregatePageTypeSelector);

  const [newFormModal, setNewFormModal] = useState(false);

  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);

  if (!isAgent && !isClient) return <></>;

  const [archiveView, setArchiveView] = useState<ArchiveViewType>(
    ArchiveView.transaction as ArchiveViewType,
  );

  const formFilters = JSON.stringify(restFilters || {});

  useEffect(() => {
    setSelectedFormIds([]);
    setSelectedFormNames([]);
  }, [showSelection]);

  useEffect(() => {
    locationState?.showDraftsModal && setShowDraftModal(true);

    // open form modal redirected from agent dashboard
    if (locationState?.showFormModal) {
      setSelectedFormProcess(locationState?.FormProcessId);
    }
  }, []);

  useEffect(() => {
    if (isAgent) {
      dispatch(getFormMetaEffect());
    }
    dispatch(resetSmartFormDataEffect());
  }, []);

  useEffect(() => {
    dispatch(setIsArchiveEffect({ isArchive }));
    fetchAllForms();
  }, [aggregatedPageType, isArchive, formFilters]);

  useEffect(() => {
    setArchiveView(ArchiveView.transaction as ArchiveViewType);
  }, [isArchive]);

  // useEffect(() => {
  //   const fetchData = () => {
  //     if (location.search.includes('from=signature')) {
  //       fetchAllForms({ silent: true });
  //     }
  //   };

  //   const intervalId = setInterval(fetchData, 3000);

  //   // Cleanup the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [location, aggregatedPageType, isArchive, formFilters]);

  useEffect(() => {
    if (showDraftModal) {
      dispatch(getDraftFormsEffect());
    }
  }, [showDraftModal]);

  const fetchAllForms = (options?) => {
    const filters = JSON.parse(formFilters);
    dispatch(
      getAllFormProcessEffect(
        {
          filters: {
            ...(isArchive && {
              statuses:
                filters?.statuses?.length > 0 ? filters.statuses : ['Voided', 'RecentlyCompleted'],
            }),
            ...addProOrTransFilterParam(aggregatedPageType, {
              isArchive,
              ...(filters && filters),
            }),
          },
        },
        options && options,
      ),
    );
  };

  // Toggling Visibility of New Form Modal
  const toggleNewFormModal = () => {
    setNewFormModal((prev) => !prev);
  };

  // Opening Library Modal
  const openFormLibraryModalOrRequireEmailVerification = useEmailVerificationWall(
    ActionToInterrupt.CREATE_FORM,
    (value: boolean) => {
      dispatch(setFormLibraryModalVisibleAction(value));
    },
  );

  // Closing Draft Modal
  const onCloseModal = () => {
    setShowDraftModal(false);
  };

  // Closing Detail Modal
  const onCloseFormDetailModal = () => {
    setSelectedFormProcess(undefined);
  };

  const handleDeleteDraftForm = (
    formProcessId: number,
    setShowConfirmationModal: (open: boolean) => void,
    setFormProcess: ({ formName, formProcessId }: FormProcessDataType) => void,
  ) => {
    dispatch(
      deleteDraftFormEffect({ formProcessId }, () => {
        setShowConfirmationModal(false);
        setFormProcess({});
        dispatch(getDraftFormsEffect());
      }),
    );
  };

  //Form View Handler
  const onHandleViewForm: onHandleViewFormType = (url, type) => {
    handleViewForm(history, url, type);
  };

  const handleSelectForm = (formProcessId: number, formProcessName, checked: boolean) => {
    let selectedForms = [...selectedFormIds];
    let selectedFormNames_ = [...selectedFormNames];

    if (checked) {
      selectedForms.push(formProcessId);
      selectedFormNames_.push(formProcessName);
    } else {
      const deselectedTaskIndex = selectedForms.indexOf(formProcessId);
      selectedForms.splice(deselectedTaskIndex, 1);
      selectedFormNames_.splice(deselectedTaskIndex, 1);
    }

    setSelectedFormNames(selectedFormNames_);
    setSelectedFormIds(selectedForms);
  };

  const onToggleArchive = (value) => {
    if (!_.isEmpty(JSON.parse(formFilters))) {
      dispatch(updateFormsFiltersAction({ search }));
      setTimeout(() => setArchive(value), 300);
    } else {
      setArchive(value);
    }
  };

  return (
    <div className={styles.formsPage}>
      <div className={classnames(styles.formsWrapper, { [styles.archiveFormWrapper]: isArchive })}>
        <div className={styles.formContainer}>
          <LocalHeader
            onNew={(value) => {
              openFormLibraryModalOrRequireEmailVerification(value);
            }}
            onNewForm={toggleNewFormModal}
            isArchive={isArchive}
            archiveView={archiveView}
            setArchive={onToggleArchive}
            setArchiveView={setArchiveView}
            setShowDraftModal={setShowDraftModal}
            showSelection={showSelection}
            setShowSelection={setShowSelection}
          />
          <MultiFormsUpdateModal
            showFormUpdateModal={showFormUpdateModal}
            setShowFormUpdateModal={setShowFormUpdateModal}
            selectedFormIds={selectedFormIds}
            selectedFormNames={selectedFormNames}
            refetchForms={() => {
              dispatch(
                getAllFormProcessEffect({
                  filters: {
                    ...addProOrTransFilterParam(aggregatedPageType, {}),
                  },
                }),
              );
              setSelectedFormIds([]);
              setShowSelection(false);
            }}
          />
          <>
            <NewFormModal isOpen={newFormModal} onClose={toggleNewFormModal} />

            {isArchive ? (
              <ArchivePage
                handleViewForm={onHandleViewForm}
                setSelectedFormProcess={
                  isAgent
                    ? (formId, formStatus) => {
                        setSelectedFormProcess(formId);
                        setSelectedFormStatus(formStatus);
                      }
                    : undefined
                }
                isArchive={isArchive}
                archiveView={archiveView}
                refetchForms={() => fetchAllForms()}
              />
            ) : (
              <>
                {viewMode === ViewModes.Grid ? (
                  <FormPage
                    setSelectedFormProcess={isAgent ? setSelectedFormProcess : undefined}
                    handleViewForm={onHandleViewForm}
                  />
                ) : (
                  <FormsTable
                    justSignedFormProcessPublicId={locationService.getQuery()?.formId}
                    setSelectedFormProcess={
                      isAgent
                        ? (formId, formStatus) => {
                            setSelectedFormProcess(formId);
                            setSelectedFormStatus(formStatus);
                          }
                        : undefined
                    }
                    handleViewForm={onHandleViewForm}
                    handleSelectForm={handleSelectForm}
                    selectedFormIds={selectedFormIds}
                    showSelectAll={true}
                    showSelection={showSelection}
                    refetchForms={() => fetchAllForms()}
                  />
                )}
              </>
            )}

            {showDraftModal && isAgent ? (
              <DraftFormModal deleteDraftForm={handleDeleteDraftForm} onCloseModal={onCloseModal} />
            ) : (
              <></>
            )}

            {selectedFormProcess && isAgent ? (
              <FormDetails
                selectedFormProcess={selectedFormProcess}
                onClose={onCloseFormDetailModal}
                onHandleViewForm={onHandleViewForm}
                selectedFormStatus={selectedFormStatus}
                refetchForms={() => fetchAllForms()}
                onDeleteFormSuccess={() => {
                  onCloseFormDetailModal();
                }}
              />
            ) : (
              ''
            )}
          </>
        </div>
        {showSelection && (
          <FormSelectionFooter
            selectedForms={selectedFormIds?.length}
            handleContinueFormUpdates={() => setShowFormUpdateModal(true)}
          />
        )}
      </div>

      <Footer className={styles.footer} />
    </div>
  );
};

export const getTooltipInfo = (form, styles) => {
  if (form.isEdit) {
    return (
      <div className={styles.container}>
        <p className={styles.title}>Waiting on:</p>
        {form?.editors?.map((e) => (
          <Fragment key={`${form.url}-${e.editorId}`}>
            <div className={styles.editorRow}>
              <p className={styles.userName}>{e.isYou ? 'You' : e.name}</p>
            </div>
            <div className={styles.editorRow}>
              <p className={styles.formStatus}>
                {e.status}: {moment(e.date).fromNow()}
              </p>
            </div>
          </Fragment>
        ))}
      </div>
    );
  } else if (form.isSign || form.isFinalizing) {
    return (
      <div className={classnames(styles.container, styles.toolTipContainer)}>
        <p className={styles.title}>
          {form.isFinalizing ? 'Pending Digital Signature' : 'Waiting on:'}
        </p>
        {form?.signatories?.map((e) => (
          <Row key={`${form.url}-${e.signatoryId}`} className={styles.row}>
            {form?.IsSequential ? (
              <Col className={styles.sequenceBoxColumn}>
                <span className={styles.sequenceBox}>{e.order}</span>
              </Col>
            ) : (
              <></>
            )}
            <Col className={styles.column}>
              <p className={styles.userName}>{e.isYou ? 'You' : e.name}</p>
              <p className={styles.formStatus}>
                {e.status}: {moment(e.date).fromNow()}
              </p>
            </Col>
            <Col span={2}>
              {e.status === 'Signed' ? (
                <Icons variant={ICON_VARIANT_TYPE.SIGNED} />
              ) : (
                <Icons variant={ICON_VARIANT_TYPE.PENDING} />
              )}
            </Col>
          </Row>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
};

export const renderTooltip = (form: FormProcessType, children: any) => {
  const isCompleted = form.status === FormsTableStatus.Complete ? true : false;

  return (
    <Tooltip
      key={`${form.url}-tooltip`}
      trigger={'hover'}
      title={!isCompleted && !!(form?.editors || form?.signatories) && getTooltipInfo(form, styles)}
      overlayClassName={styles.toolTipContainer}
      color={'white'}
    >
      {children}
    </Tooltip>
  );
};
