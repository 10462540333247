import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from 'components';
import { Col, Row } from 'components-antd';
import { TransactionCloseComponentIds } from 'app-constants';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getClientsSearchInstancesEffect } from 'store/effects/clientSearches';
import { getClientsSearchInstancesSelector } from 'store/selectors/clientSearches';
import { ClientCard } from 'pages/Properties/ClientSearches/components/ClientCard';
import { ClientDetails } from 'pages/Properties/ClientSearches/components/ClientDetails';
import Question from 'pages/Workshop/Transactions/TransactionCreate/components/Question';
import Answers from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Answers';
import { PreApprovalDrawer } from 'pages/Properties/ClientSearches/components/PreApprovalDrawer';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';

import styles from './styles.module.scss';

interface ActiveSearchInstancesProps {
  previousStage?: TransactionCloseComponentIds;
  setStage: (stage: TransactionCloseComponentIds) => void;
}

export const ActiveSearchInstances = ({ previousStage, setStage }: ActiveSearchInstancesProps) => {
  const dispatch = useDispatch();

  const { transaction } = useSelector(getTransactionSelector);
  const { data, isLoading } = useSelector(getClientsSearchInstancesSelector);

  const [selectedClientId, setSelectedClientId] = useState();

  const clients = transaction.Participants.filter((participant) => participant.IsClient);

  const clientsWithSearchInstances = data.filter(
    (client) =>
      !!clients.find((participant) => participant.Id === client.Id) && client.ActiveInstances,
  );

  useEffect(() => {
    if (!isLoading) {
      if (!clientsWithSearchInstances?.length) {
        if (previousStage === TransactionCloseComponentIds.PendingTasks) {
          setStage(TransactionCloseComponentIds.ClientProfileRemoveAddress);
        } else if (previousStage === TransactionCloseComponentIds.ClientProfileRemoveAddress) {
          setStage(TransactionCloseComponentIds.PendingTasks);
        }
      }
    }
  }, [clientsWithSearchInstances]);

  const selectClient = (clientId) => setSelectedClientId(clientId);
  const deSelectClient = () => setSelectedClientId(undefined);
  const getClient = (id) => data.find((client) => client.Id === id);

  useEffect(() => {
    if (!selectedClientId) {
      dispatch(getClientsSearchInstancesEffect({ agentId: transaction?.Creator?.Id }));
    }
  }, [selectedClientId]);

  return (
    <div className={styles.activeSearchInstances}>
      <Question className={styles.question}>
        These are the active search instances for all client participants. Do you want to deactivate
        them?
      </Question>
      <div className={styles.content}>
        <Answers className={styles.answers}>
          <div className={styles.clientSearches}>
            <Row gutter={[30, 30]}>
              {isLoading ? (
                <Spinner loaderClassName={styles.loader} />
              ) : (
                <>
                  {!selectedClientId ? (
                    clientsWithSearchInstances.map((client) => (
                      <Col xs={24} md={12}>
                        <ClientCard
                          key={client.Id}
                          client={client}
                          onSelect={selectClient}
                          unreadMessageCount={0}
                        />
                      </Col>
                    ))
                  ) : (
                    <Col xs={24}>
                      <ClientDetails
                        client={getClient(selectedClientId)}
                        goBack={deSelectClient}
                        unreadMessageCount={0}
                      />
                      <PreApprovalDrawer clientId={selectedClientId} />
                    </Col>
                  )}
                </>
              )}
            </Row>
          </div>
        </Answers>
      </div>
      <div className={styles.bottom}>
        <Continue
          onClick={() => {
            setStage(TransactionCloseComponentIds.ClientProfileRemoveAddress);
          }}
          className={styles.submit}
        />
      </div>
    </div>
  );
};
