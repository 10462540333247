import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { getInvitedClientData } from 'store/selectors/app';
import { Input, PropertyAddress } from 'components';
import { ClientCategory } from 'types';

const PropertyAddressInput = (props) => {
  const {
    className,
    valuesWrapperClassName,
    searchWrapperClassName,
    unitClassName,
    placeholder,
    unitFieldPlaceholderText,
  } = props;
  const dispatch = useDispatch();
  const invitedClient = useSelector(getInvitedClientData);
  const { preForm } = useSelector(getTransactionPreFormSelector);

  const onAddressChange = (address) => {
    if (address) {
      dispatch(
        setTransactionPreFormQuestionsEffect({
          [transactionPreFormQuestionsIds.address]: {
            ...(preForm?.[transactionPreFormQuestionsIds.address] || {}),
            ...address,
          },
        }),
      );
    } else {
      dispatch(
        setTransactionPreFormQuestionsEffect({
          [transactionPreFormQuestionsIds.address]: null,
        }),
      );
    }
  };

  useEffect(() => {
    if (invitedClient?.address) {
      onAddressChange({
        ...invitedClient.address,
        Line2: invitedClient.aptUnit ? invitedClient.aptUnit : undefined,
      });
    }
  }, []);

  return (
    <PropertyAddress
      updateLocation={onAddressChange}
      location={preForm?.[transactionPreFormQuestionsIds.address] || {}}
      className={className}
      address={invitedClient?.aptUnit ? { Line2: invitedClient.aptUnit } : undefined}
      unitClassName={unitClassName}
      valuesWrapperClassName={valuesWrapperClassName}
      searchWrapperClassName={searchWrapperClassName}
      variant={Input.LIGHT_ROUND}
      placeholder={placeholder}
      unitFieldPlaceholderText={unitFieldPlaceholderText}
    />
  );
};

PropertyAddressInput.propTypes = {
  className: PropTypes.string,
  unitClassName: PropTypes.string,
  valuesWrapperClassName: PropTypes.string,
  searchWrapperClassName: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  unitFieldPlaceholderText: PropTypes.string,
};

PropertyAddressInput.defaultProps = {
  className: '',
  unitClassName: '',
  valuesWrapperClassName: '',
  searchWrapperClassName: '',
  onChange: () => {},
  placeholder: '',
  unitFieldPlaceholderText: '',
};

export default PropertyAddressInput;
