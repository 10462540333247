import { getServicesCategoriesList } from 'api/servicesCategories';

import Api from 'store/effects/core/api';
import { requestGetServicesCategoriesAction } from 'store/actions/servicesCategories';

export const requestGetServicesCategoriesEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetServicesCategoriesAction,
    method: getServicesCategoriesList,
  });

  return sendRequest(cfg, options, cb);
};
