import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Popover } from 'antd';

import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const _popoverContent = () => <span>Compare Listings</span>;

const Multiple = ({ className, onClick, popoverContent }) => (
  <Popover
    content={popoverContent || _popoverContent}
    placement="top"
    overlayClassName={classNames('mosaikTooltip')}
    getPopupContainer={(triggerNode) => triggerNode}
    arrow={true}
  >
    <div onClick={onClick} className={classNames(styles.multiple, className)}>
      <Icon testid="select_multiple" variant={Icon.SELECT} />
    </div>
  </Popover>
);

Multiple.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  popoverContent: PropTypes.node,
};

Multiple.defaultProps = {
  className: '',
  onClick: () => {},
  popoverContent: null,
};

export default Multiple;
