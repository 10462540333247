/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Add } from 'components/Icons';
import { Avatar } from 'components';
import AvatarPlaceholderLight from 'images/avatar-placeholder.png';

import { getImagesPreview } from 'helpers';

import styles from './styles.module.scss';

const Photo = ({
  className,
  avatarClassName,
  onChange,
  value,
  children,
  placeholder,
  binary,
  variant,
}) => {
  const [imageValue, setImageValue] = useState(value);

  useEffect(() => setImageValue(value), [value, setImageValue]);

  const onChangeHandler = (event) => {
    getImagesPreview(event.target.files, binary).then((values) => {
      if (!binary) {
        const image = values?.[0]?.url;
        setImageValue(image);
        onChange(image, event.target.files);
      } else {
        const preparedValues = values.map((val) => ({
          ContentType: val.contentType,
          Filename: val.fileName,
          Url: val.url,
        }));
        const attachment = preparedValues[0];
        const image = `data:${attachment?.ContentType};base64,${btoa(attachment?.Url)}`;
        setImageValue(image);
        onChange(event.target.files, preparedValues);
      }
    });
  };

  return (
    <div className={classNames(styles.photoWrapper, className, styles[variant])}>
      <label className={styles.photo} htmlFor="photo">
        <Avatar
          className={styles.avatar}
          avatarClassName={classNames(
            styles.innerAvatar,
            { [styles.hasValue]: !!imageValue },
            avatarClassName,
          )}
          testid="photo_image"
          src={imageValue}
          placeholder={
            placeholder ||
            (variant === Photo.FULL ? undefined : <img src={AvatarPlaceholderLight} alt="" />)
          }
        />
        {children || (
          <Add
            testid="photo_add"
            className={classNames(styles.add, {
              [styles.display]:
                !variant === Photo.LIGHT || (variant === Photo.LIGHT && !imageValue),
            })}
            color={variant === Photo.FULL ? Add.ORANGE_COLOR : Add.BLUE_COLOR}
          />
        )}
        <input
          testid="photo_upload"
          className={styles.nativeInput}
          id="photo"
          type="file"
          onChange={onChangeHandler}
        />
      </label>
    </div>
  );
};

Photo.FULL = 'full';
Photo.LIGHT = 'light';

Photo.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  avatarClassName: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
  binary: PropTypes.bool,
};

Photo.defaultProps = {
  variant: Photo.FULL,
  className: '',
  avatarClassName: '',
  placeholder: null,
  value: null,
  children: null,
  onChange: () => {},
  binary: false,
};

export default Photo;
