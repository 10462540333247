import React, { memo, useMemo } from 'react';

const ArrowDownFull = memo(
  ({ className, color = '#262626' }: React.HTMLAttributes<HTMLDivElement>) =>
    useMemo(
      () => (
        <div className={className}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_562_29039)">
              <path
                d="M8 3.33398V12.6673"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 8.66602L8 12.666"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 8.66602L8 12.666"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_562_29039">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      ),
      [className],
    ),
);

export default ArrowDownFull;
