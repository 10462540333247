/* eslint-disable react/prop-types */
import { useState } from 'react';
import classNames from 'classnames';

import { Button, Switch, Row, Col } from 'components-antd';
import { Input, InputLabel, Select, Locations } from 'components';
import { getLocationContext } from 'helpers/locations';
import { POSTAL_CODE } from 'settings/constants/locations';
import { Close } from 'components/Icons';
import { prefsIds } from 'settings/constants/preferences';

import styles from './styles.module.scss';

export const Commute = ({
  onClick,
  softCriteria,
  title,
  commuteHandler,
  commute: propCommute,
  type,
  showCommuteSection,
}) => {
  const [commute, setCommute] = useState({ ...propCommute });
  const [transport, setTransport] = useState(propCommute.by || '');

  const selectTransportType = (type) => {
    setTransport(type);
    commute['by'] = type;
  };

  const onChangeLocation = (result) => {
    const { deleteItemId, coordinates, placeName, result: locations } = result;
    const zip = getLocationContext(locations?.[0], POSTAL_CODE);

    const cfg =
      !deleteItemId && coordinates
        ? {
            PlaceName: placeName,
            Geolocation: {
              Long: coordinates.lng,
              Lat: coordinates.lat,
            },
            Zip: zip,
          }
        : undefined;

    setCommute((prevState) => ({
      ...prevState,
      address: cfg ? [cfg] : undefined,
    }));
  };

  const isDisabled = !commute.minutes || !commute.locationLabel || !commute.address || !commute.by;

  return (
    <>
      <div className={styles.commutes}>
        <div className={styles.commuteHeader}>
          <h4>
            {title === prefsIds.proximityToImportantLocations
              ? 'Which locations do you want to be close to'
              : 'Where are you commuting to'}
            ?
          </h4>
          <Close className={styles.commuteClose} onClick={() => showCommuteSection(false)} />
        </div>
        <Locations
          placeholder="Enter address or location name"
          value={commute.address || []}
          className={styles.locationsInput}
          activeInputClassName={styles.activeLocationsInput}
          valuesWrapperClassName={styles.valuesWrapper}
          multiple={false}
          onResult={onChangeLocation}
          label="Location"
          showPrefixIcon={true}
        />

        <Row gutter={[24, 24]}>
          <Col xs={24} md={14}>
            <InputLabel label="Preference" />
            <div className={classNames(styles.inputWrapper, styles.preferenceWrapper)}>
              <span className={styles.innerLabel}>Within:</span>
              <Input
                name="minutesInput"
                value={commute.minutes || ''}
                onChange={(e) => {
                  setCommute((prevState) => ({
                    ...prevState,
                    minutes: e.target.value,
                  }));
                }}
                placeholder="0"
                inputClassName={styles.inputMaxBorderBottom}
                className={styles.inputFieldMinutes}
                maxLength={'9'}
                type={'number'}
              />
              <Select
                options={Commute.unitTypes}
                value={'minutes'}
                // onSelect={(target, val) => setType(val)} // TODO: Make this functional
                placeholder="minutes"
                variant="darkHalf"
                className={{ wrapper: styles.timeSelectWrapper }}
                style={{ width: '110px' }}
              />
            </div>
          </Col>
          <Col xs={24} md={10}>
            <InputLabel label="Transportation Method" />
            <Select
              options={Commute.transportationMethods}
              value={transport}
              defaultValue={transport}
              onSelect={(target, obj) => selectTransportType(obj.value)}
              placeholder="Car"
              variant="darkHalf"
              className={{ wrapper: styles.transportationSelectWrapper }}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
        <div className={styles.locationType}>
          <Input
            name="addressLabel"
            value={commute.locationLabel || ''}
            onChange={(e) => {
              setCommute((prevState) => ({
                ...prevState,
                locationLabel: e.target.value,
              }));
            }}
            label="Label"
            variant={Input.LIGHT_FULL}
            placeholder="e.g. Work, school, daycare, etc"
            inputClassName={styles.inputMaxBorderBottom}
            className={styles.inputField}
            maxLength={40}
          />
        </div>
        <div className={styles.bottom}>
          <span className={styles.rushHour}>
            <span className={styles.rushHourTitle}>Account for rush hour?</span>
            <Switch
              defaultChecked
              checked={commute.rushHour || false}
              onChange={(value) => {
                setCommute((prevState) => ({
                  ...prevState,
                  rushHour: value,
                }));
              }}
            />
          </span>
          <Button
            className={classNames(styles.saveButton, { [styles.active]: !isDisabled })}
            onClick={() => {
              commuteHandler(false);
              onClick(
                title,
                softCriteria?.[title]?.value,
                type,
                commute,
                Object.keys(propCommute).length === 0 ? 'add' : 'edit',
              );
            }}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

Commute.unitTypes = [
  { id: 1, name: 'Minutes', value: 'minutes' },
  { id: 2, name: 'Miles', value: 'miles' },
];

Commute.transportationMethods = [
  { id: 1, name: 'Car', value: 'Car' },
  { id: 2, name: 'Public Transit', value: 'PublicTransit' },
  { id: 3, name: 'Bike', value: 'Bicycle' },
  { id: 4, name: 'Walk', value: 'Walking' },
];

Commute.propTypes = {};
