import { combineReducers } from 'redux';

import savedInstances from './savedInstances';
import savedSearches from './savedSearches';
import preApproval from './preApproval';
import preApprovalRequests from './preApprovalRequests';

export default combineReducers({
  savedInstances,
  savedSearches,
  preApproval,
  preApprovalRequests,
});
