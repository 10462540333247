import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Wrapper = forwardRef((props, ref) => {
  const { variant, disabled, className, children } = props;

  return (
    <div
      ref={ref}
      testid="wrapper"
      className={classNames(
        styles.locations,
        styles[variant],
        { [styles.disabled]: disabled },
        className,
      )}
    >
      {children}
    </div>
  );
});

Wrapper.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Wrapper.defaultProps = {
  className: '',
  variant: 'lightFull',
  disabled: false,
};

export default Wrapper;
