import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map } from 'lodash-es';
import { useDispatch } from 'react-redux';

import Image from './Image';
import ImagesCollage from './ImagesCollage';
import PhotosViewModal from '../../../PhotosViewModal';
import { getImageSrc } from 'utils';

const Images = (props) => {
  const dispatch = useDispatch();
  const { className, imageClassName, attachments, isMessageSavedOnServer } = props;
  const [previewImages, setPreviewImages] = useState(null);
  const [startImageIndex, setStartImageIndex] = useState(0);

  const onOpenImage = useCallback(
    (url, images) => {
      if (isMessageSavedOnServer && images?.length) {
        setPreviewImages([]);
        setTimeout(() => {
          const index = images.findIndex(
            (image) => image?.Url === url || image?.DocumentVaultUUID === url,
          );

          handleImagePreview(images, index === -1 ? 0 : index);
        }, 0);
      }
    },
    [isMessageSavedOnServer],
  );

  const handleImagePreview = async (images, index) => {
    const previewImages = await Promise.all(
      images.map(async (image) => ({
        ...image,
        Url: await getImageSrc(
          image?.Url,
          image?.Data,
          image?.ContentType,
          image?.DocumentVaultUUID,
          dispatch,
        ),
      })),
    );
    setPreviewImages(previewImages);
    setStartImageIndex(index);
  };

  const getAttachmentType = useCallback((attachment) => {
    const [type] = attachment?.ContentType?.split('/') || [];
    return type;
  }, []);

  const getImagesPreview = () => {
    const images = (attachments || []).filter(
      (attachment) => getAttachmentType(attachment) === 'image',
    );

    if (images?.length > 1) {
      return (
        <ImagesCollage
          className={classNames(imageClassName)}
          images={images}
          onClick={() => onOpenImage('', images)}
          isMessageSavedOnServer={isMessageSavedOnServer}
        />
      );
    }

    return map(images, (image, index) => (
      <Image
        key={image.Url || index}
        className={classNames(imageClassName)}
        image={image}
        onClick={() => onOpenImage(image?.Url || image?.DocumentVaultUUID, images)}
        isMessageSavedOnServer={isMessageSavedOnServer}
      />
    ));
  };

  return (
    <div className={className}>
      {getImagesPreview()}
      {previewImages && (
        <PhotosViewModal
          isOpen
          onClose={() => setPreviewImages(null)}
          photos={previewImages}
          imageId={startImageIndex}
        />
      )}
    </div>
  );
};

Images.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMessageSavedOnServer: PropTypes.bool.isRequired,
};

Images.defaultProps = {
  className: '',
  imageClassName: '',
};

export default Images;
