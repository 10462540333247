import React from 'react';
import styles from './styles.module.scss';
import { InviteClient, ArrowLeftFull } from 'components/Icons';
import { useSelector, useDispatch } from 'react-redux';
import { setCriteriaSectionInviteFlow } from 'store/effects/app';
import { isCriteriaSectionInviteFlowOPened } from 'store/selectors/app';

interface PropType {
  isValid?: boolean;
  stageIndex: number;
  onPrev?: () => any;
  disabled?: boolean;
}
const InviteHeader = ({ isValid, stageIndex, onPrev, disabled }: PropType) => {
  const isCriteriaSectionOpened = useSelector(isCriteriaSectionInviteFlowOPened);
  const dispatch = useDispatch();

  return (
    <>
      <div className={styles.headerContanierInvite}>
        {stageIndex > 0 && (
          <>
            {!isCriteriaSectionOpened && (
              <ArrowLeftFull onClick={onPrev} className={styles.arrowLeft} />
            )}
            {isCriteriaSectionOpened && (
              <ArrowLeftFull
                onClick={() => dispatch(setCriteriaSectionInviteFlow(false))}
                className={styles.arrowLeft}
              />
            )}
          </>
        )}
        <div className={styles.headerInvite}>
          {isCriteriaSectionOpened ? (
            <h2 className={styles.inviteHeading}>Add Search Criteria</h2>
          ) : (
            <>
              <InviteClient />
              <h2 className={styles.inviteHeading}>Invite Client</h2>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InviteHeader;
