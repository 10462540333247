import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from 'components';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';

import RightArrowIcon from '../Icons/RightArrowIcon';

import styles from './styles.module.scss';

const BuyerLine = ({ name, info, className, onClick }) => (
  <div
    testid="search_buyer_block"
    onClick={onClick}
    className={classNames(styles.container, className)}
  >
    <Avatar
      className={styles.avatarWrapper}
      avatarClassName={styles.avatar}
      placeholder={convertNameToAvatarPlaceholder(name)}
    />
    <div className={styles.infoBlock}>
      <div testid="buyer_name" className={styles.descTitle}>
        {name || '-'}
      </div>
      <div testid="buyer_info" className={styles.descInfo}>
        {info || '-'}
      </div>
    </div>
    <div className={styles.arrow}>
      <RightArrowIcon className={styles.arrowIcon} />
    </div>
  </div>
);

BuyerLine.propTypes = {
  name: PropTypes.string,
  info: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

BuyerLine.defaultProps = {
  className: '',
  name: '',
  info: '',
  onClick: () => {},
};

export default BuyerLine;
