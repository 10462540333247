import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getIsQuotesDrawerOpenSelector,
  getQuotesDrawerParams,
} from 'store/selectors/drawers/quotes';
import { openQuotesDrawerAction, setQuotesDrawerParamsAction } from 'store/actions/drawers/quotes';

import { Drawer } from 'components';

import Header from './components/Header';
import SocketConnectionWrapper from '../components/SocketConnectionWrapper';
import QuotesDrawerThreadsList from './components/ThreadsList';
import QuotesDrawerOpenedThread from './components/OpenedThread';

const QuotesDrawer = () => {
  const isOpen = useSelector(getIsQuotesDrawerOpenSelector);
  const { threadId, quotesIds, title, type, icon, ...rest } = useSelector(getQuotesDrawerParams);
  const dispatch = useDispatch();

  const openCloseThread = useCallback(
    (id) =>
      dispatch(
        setQuotesDrawerParamsAction({ title, type, icon, ...rest, quotesIds, threadId: id }),
      ),
    [dispatch, quotesIds, title, type, icon, rest],
  );

  const handleDrawerClose = useCallback(() => {
    dispatch(openQuotesDrawerAction(false));
  }, [dispatch]);

  const closeThread = useCallback(() => {
    if (quotesIds === undefined || quotesIds?.length === 1) {
      handleDrawerClose();
    } else {
      openCloseThread(null);
    }
  }, [openCloseThread, handleDrawerClose, quotesIds]);

  return (
    <Drawer
      header={
        <Header
          threadId={threadId}
          icon={icon}
          type={type}
          title={title}
          onClose={handleDrawerClose}
        />
      }
      isOpen={isOpen}
      onClose={handleDrawerClose}
      testid="quotes_drawer"
    >
      <SocketConnectionWrapper>
        {threadId ? (
          <QuotesDrawerOpenedThread closeThread={closeThread} />
        ) : (
          <QuotesDrawerThreadsList openCloseThread={openCloseThread} />
        )}
      </SocketConnectionWrapper>
    </Drawer>
  );
};

export default QuotesDrawer;
