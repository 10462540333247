import { useState, useEffect } from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import { Row, Col, Collapse, Panel, Checkbox } from 'components-antd';
import { Icons, ICON_VARIANT_TYPE } from 'pages/Workshop/Forms/Icons';
import { CounterOffers } from '../CounterOffers';
import { CompareOfferTooltip } from '../CompareOfferTooltip';

import styles from './styles.module.scss';

export const StatusWiseOffers = ({
  panelHeading,
  panelKey,
  offers,
  onOfferSelection,
  totalOffersSelected = 0,
  selectedOffers,
  setSelectedOffers,
  setTotalOffersSelected,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [sWoffers, setOffers] = useState(offers);
  const onChangeSignature = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    offers?.forEach((element) => {
      if (!element.selected) {
        element.selected = false;
      }
    });
    setOffers(offers);
  }, [offers]);

  return (
    <div className={styles.statusWiseOffers}>
      <Collapse
        onChange={onChangeSignature}
        defaultActiveKey={['Pending']}
        expandIconPosition={'end'}
        ghost={true}
        expandIcon={({ isActive }) =>
          isActive ? (
            <Icons variant={ICON_VARIANT_TYPE.OPEN_ACCORDIAN} />
          ) : (
            <Icons variant={ICON_VARIANT_TYPE.CLOSE_ACCORDIAN} />
          )
        }
      >
        <Panel header={panelHeading} key={panelKey} className={styles.offerStatusHeading}>
          {offers?.map((offer) => (
            <div key={offer.UUID}>
              <Row className={styles.DocumentDiv} gutter={12}>
                <Col xs={24}>
                  <div className={styles.offerInfoWrap}>
                    <div className={styles.iconWrap}>
                      <Icons
                        className={styles.incompleteDocumentIcon}
                        variant={ICON_VARIANT_TYPE.INCOMPLETE_DOCUMENT}
                      />
                    </div>
                    <div className={styles.info}>
                      <p className={styles.offereName}>{`${
                        offer.OfferIteration.length > 1
                          ? `Counter-Offer#${offer.OfferCount}-${offer.OfferIteration[0]?.IterationCount}`
                          : `Offer#${offer.OfferCount}`
                      }`}</p>
                      <p className={styles.offerReceivedAndPrice}>
                        <span>
                          Received:&nbsp;
                          {new Date(offer.OfferIteration[0]?.CreatedDate).toLocaleDateString()} -
                          Offer Price:&nbsp;{' '}
                          <NumberFormat
                            thousandSeparator
                            displayType="text"
                            value={
                              offer?.OfferIteration[0]?.PurchasePrice
                                ? offer?.OfferIteration[0]?.PurchasePrice
                                : 0
                            }
                            prefix="$"
                            renderText={(val) => <span testid="property_price">{val}</span>}
                          />
                          &nbsp;
                        </span>
                        <CompareOfferTooltip Buyer={offer.Buyer} />
                      </p>
                    </div>
                    <Checkbox
                      checked={offer.selected}
                      onChange={(e) => {
                        onOfferSelection(e, offer, setOffers);
                      }}
                      className={classNames(styles.checkbox, 'mosaikCheckbox')}
                    />
                  </div>
                </Col>
              </Row>
              <CounterOffers
                offer={offer}
                onOfferSelection={onOfferSelection}
                totalOffersSelected={totalOffersSelected}
              />
            </div>
          ))}
        </Panel>
      </Collapse>
    </div>
  );
};
