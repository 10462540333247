import { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { convertNameToAvatarPlaceholder, getPropertyAddress } from 'helpers';
import { getUserDataSelector } from 'store/selectors/user';
import { SendMessageFormView, Avatar, Wrapper as PendingWrapper, CommentsView } from 'components';
import { Modal } from 'components-antd';

import {
  editTransactionTaskMessageEffect,
  sendNewTransactionTaskMessageEffect,
  socketsGetTransactionTaskMessagesByThreadIdEffect,
  socketSetEditTransactionTaskMessageEffect,
} from 'store/effects/sockets/transactionTaskMessages';
import { createNewThreadEffect, sendMarkThreadAsReadEffect } from 'store/effects/sockets/threads';
import { SOCKET_THREAD_TYPES, SOCKET_MESSAGE_TYPES } from 'settings/constants/sockets';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getTransactionTaskMessagesSelector } from 'store/selectors/transactionTaskMessages';
import { getCurrentEditTransactionTaskMessageSelector } from 'store/selectors/sockets/transactionTaskMessages';
import { getTransactionTaskSelector } from 'store/selectors/transactionTask';
import { setShowCommentIconEffect } from 'store/effects/taskAggregate';
import { setShowCommentIconForTransactionEffect } from 'store/effects/transactions';
import { useLocation } from 'react-router-dom';

import styles from './styles.module.scss';
import { deleteTransactionMessageEffect } from 'store/effects/sockets/transactionMessages';
import { socketsStoreEditedTransactionTaskMessageAction } from 'store/actions/sockets/transactionTaskMessages';

const Comments = (props) => {
  const {
    className,
    commentAutoFocus,
    inputPlaceholder,
    emptyLabel,
    onCreateThreadHandler,
    isModal,
    isTransactionDrawer,
    onSendHandler,
    getContentHandler,
    getMessagesSelector,
    customSend,
    getEditMessageSelector,
    onCustomCancelEditSelector,
  } = props;
  const commentViewWrapperRef = useRef(null);
  const hasScrolledToCommentRef = useRef(false);
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const { task } = useSelector(getTransactionTaskSelector);
  const location = useLocation();
  const { currentThreadId, messages, threadsList, userId } = useSelector(getMessagesSelector);
  const currentEditMessage = useSelector(getEditMessageSelector);
  const [isThreadCreating, setIsThreadCreating] = useState(false);

  const checkAndGetThread = () =>
    threadsList.find(
      (thread) =>
        thread?.EntityId === `${task?.Id}` && thread?.Type === SOCKET_THREAD_TYPES.TRANSACTION_TASK,
    );

  useEffect(() => {
    if (currentThreadId) {
      dispatch(sendMarkThreadAsReadEffect({ threadId: currentThreadId }));
    }
  }, [dispatch, currentThreadId]);

  const onCancelEditHandler = () => {
    dispatch(onCustomCancelEditSelector({ editMessage: null }));
  };

  const onSendMessage = (message, attachments, tagsValue, messageId, threadId, accessType) => {
    if (currentEditMessage) {
      onCancelEditHandler();
    }

    if (messageId) {
      const payload = messages.find((message) => message.Id === messageId);
      dispatch(
        socketsStoreEditedTransactionTaskMessageAction({
          data: {
            ...payload,
            Text: message,
            Attachments: attachments,
            MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
            MessageMeta: { Tags: tagsValue },
            AccessType: accessType,
          },
        }),
      );
      return dispatch(
        editTransactionTaskMessageEffect({
          MessageId: messageId,
          Text: message,
          Attachments: attachments,
          MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
          MessageMeta: { Tags: tagsValue },
          AccessType: accessType,
        }),
      );
    }

    return dispatch(
      sendNewTransactionTaskMessageEffect({
        ThreadId: threadId || currentThreadId,
        Text: message,
        Attachments: attachments,
        MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
        MessageMeta: { Tags: tagsValue },
        AccessType: accessType,
      }),
    );
  };

  const onDeleteMessage = (messageId) => {
    return dispatch(deleteTransactionMessageEffect({ MessageId: messageId }));
  };

  const onCreateThreadAndSendNewMessage = (
    message,
    attachments,
    tagsValue,
    messageId,
    threadId,
    accessType,
  ) => {
    const existThread = checkAndGetThread();

    if (existThread) {
      onSendMessage(message, attachments, null, existThread?.Id);
      return dispatch(socketsGetTransactionTaskMessagesByThreadIdEffect({ Id: existThread?.Id }));
    }

    setIsThreadCreating(true);
    dispatch(
      createNewThreadEffect(
        {
          Type: SOCKET_THREAD_TYPES.TRANSACTION_TASK,
          EntityId: `${task?.Id}`,
          TopicName: getPropertyAddress(transaction?.Property?.Address),
          ParticipantIds: [
            ...(transaction?.Participants || []).map((participant) => participant?.Id),
            userId,
          ],
          FirstMessage: {
            Text: message,
            Attachments: attachments,
            MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
            MessageMeta: { Tags: tagsValue },
            AccessType: accessType,
          },
        },
        {},
        (err, data) => {
          dispatch(socketsGetTransactionTaskMessagesByThreadIdEffect({ Id: data?.Id }));
          setIsThreadCreating(false);
          if (task?.Id) dispatch(setShowCommentIconEffect({ taskId: task.Id }));
          if (task?.Id && task?.TransactionId) {
            dispatch(
              setShowCommentIconForTransactionEffect({
                taskId: task.Id,
              }),
            );
          }
        },
      ),
    );
  };

  const getUserName = () => `${user?.FirstName} ${user?.LastName}`;

  const onEditMessageHandler = useCallback(
    (messageId) => {
      const editMessage = messages.find((message) => message?.Id === messageId);
      dispatch(onCustomCancelEditSelector({ editMessage }));
    },
    [dispatch, messages],
  );

  const scrollToComment = useCallback((messageId) => {
    if (commentViewWrapperRef?.current && messageId) {
      const targetMessage = commentViewWrapperRef.current.querySelector(`#message_${messageId}`);
      if (targetMessage) {
        setTimeout(() => {
          targetMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
          hasScrolledToCommentRef.current = true;
        }, 0);
      }
    }
  }, []);

  useEffect(() => {
    let messageId = null;
    if (messages?.length) {
      messageId = messages[messages?.length - 1]?.Id;
    }

    if (messageId && currentThreadId && !hasScrolledToCommentRef.current) {
      scrollToComment(messageId);
    } else if (commentViewWrapperRef?.current && currentThreadId) {
      commentViewWrapperRef.current.scrollTop = commentViewWrapperRef?.current?.scrollHeight;
    }
  }, [messages?.length, currentThreadId, location.state, scrollToComment]);

  const getMessagesContent = () => {
    if (isThreadCreating) {
      return (
        <PendingWrapper
          isPending={isThreadCreating}
          className={styles.pendingWrapper}
          loaderClassName={styles.loader}
        />
      );
    }

    if (currentThreadId) {
      return (
        <div className={styles.CommentsViewWrapper} ref={commentViewWrapperRef}>
          <CommentsView
            isModal={isModal}
            isTransactionDrawer={isTransactionDrawer}
            threadId={currentThreadId}
            list={messages}
            currentEditMessage={currentEditMessage}
            onEditMessage={onEditMessageHandler}
            className={styles.messages}
            onDeleteMessage={onDeleteMessage}
          />
        </div>
      );
    }

    return (
      <div className={classNames(styles.empty, { [styles.emptyModal]: isModal })}>
        <span testid="no_comments" className={styles.message}>
          {emptyLabel}
        </span>
      </div>
    );
  };

  return (
    <div className={classNames(styles.comments, { [styles.commentsModal]: isModal })}>
      <div className={classNames(styles.holder, { [styles.holderModal]: isModal })}>
        <SendMessageFormView
          id="transactionTaskComments"
          onSend={
            currentThreadId
              ? customSend
                ? onSendHandler
                : onSendMessage
              : customSend
              ? onCreateThreadHandler
              : onCreateThreadAndSendNewMessage
          }
          subheader={null}
          content={customSend ? getContentHandler() : getMessagesContent()}
          autoFocus={commentAutoFocus}
          footerClassName={classNames(styles.footer)}
          footerInnerClassName={styles.footerInner}
          inputGroupClassName={styles.inputGroup}
          attachments={false}
          emoji={true}
          showDropdown
          autocomplete={true}
          inputPlaceholder={inputPlaceholder}
          sendButtonClassName={styles.sendButton}
          onCancelEdit={onCancelEditHandler}
          currentEditMessage={currentEditMessage}
          placeholder="Type to search users"
          isTaskComments
          isModal={isModal}
          className={classNames(styles.commentsForm, { [styles.commentsFormModal]: isModal })}
          avatar={true}
          avatarPlaceHolder={
            <div className={styles.avatarPlaceholder}>
              {convertNameToAvatarPlaceholder(getUserName())}
            </div>
          }
        />
      </div>
    </div>
  );
};

Comments.propTypes = {
  className: PropTypes.string,
  commentAutoFocus: PropTypes.bool,
  isModal: PropTypes.bool,
  isTransactionDrawer: PropTypes.bool,
  onCreateThreadHandler: PropTypes.func,
  onSendHandler: PropTypes.func,
  getContentHandler: PropTypes.func,
  inputPlaceholder: PropTypes.string,
  emptyLabel: PropTypes.string,
  getMessagesSelector: PropTypes.func,
  customSend: PropTypes.bool,
  getEditMessageSelector: PropTypes.func,
  onCustomCancelEditSelector: PropTypes.func,
};

Comments.defaultProps = {
  className: '',
  commentAutoFocus: false,
  isModal: false,
  isTransactionDrawer: false,
  onCreateThreadHandler: () => {},
  onSendHandler: () => {},
  getContentHandler: () => {},
  onCustomCancelEditHandler: () => {},
  getMessagesSelector: getTransactionTaskMessagesSelector,
  getEditMessageSelector: getCurrentEditTransactionTaskMessageSelector,
  onCustomCancelEditSelector: socketSetEditTransactionTaskMessageEffect,
  inputPlaceholder: 'Add a comment ...',
  emptyLabel: 'No Comments',
  customSend: false,
};

export default Comments;
