import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DRAWER_QUOTES_VIEW_TYPES } from 'settings/constants/drawers';
import { formatNameToAvatarLetters } from 'helpers';

import { DrawerHeaderTitle, DrawerCancel, Avatar } from 'components';

import styles from './styles.module.scss';

const Header = ({ className, onClose, title, threadId, type, icon }) => {
  if (threadId) {
    return null;
  }

  return (
    <div className={classNames(styles.header, className)}>
      <div className={styles.titleWrapper}>
        <Avatar
          avatarClassName={styles.commentIcon}
          src={icon}
          placeholder={formatNameToAvatarLetters(title)}
        />
        <DrawerHeaderTitle className={styles.title}>
          {type === DRAWER_QUOTES_VIEW_TYPES.CLIENT && (
            <div testid="drawer_for" className={styles.subTitle}>
              For Client:
            </div>
          )}
          <div testid="header_name">{title}</div>
        </DrawerHeaderTitle>
      </div>
      <div className={styles.actions}>
        <DrawerCancel onClick={onClose} />
      </div>
    </div>
  );
};

Header.propTypes = {
  threadId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  type: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
};

Header.defaultProps = {
  type: null,
  threadId: null,
  icon: null,
  className: '',
  title: 'Listing Comments',
  onClose: () => {},
};

export default Header;
