import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getRecommendedPartnersByAgentList,
  showRequestQuoteBtnSelector,
} from 'store/selectors/partners';

import PartnersList from '../PartnersList';
import { toggleRequestQuoteBtnAction } from 'store/actions/partners';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';

const MyRecomendationsTab = ({ categoryTitle }) => {
  const dispatch = useDispatch();

  const list = useSelector(getRecommendedPartnersByAgentList);
  const showRequestQuoteBtn = useSelector(showRequestQuoteBtnSelector);
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);

  useEffect(() => {
    if (list?.length > 0) {
      !showRequestQuoteBtn && dispatch(toggleRequestQuoteBtnAction(true));
    } else {
      showRequestQuoteBtn && dispatch(toggleRequestQuoteBtnAction(false));
    }
  }, [list, dispatch, showRequestQuoteBtn]);

  const activePartnersList = useMemo(() => {
    if (!list) return;
    if (multiple?.modeEnable) {
      return list.filter(
        (partner) => partner?.ThirdParty?.User?.IsEmailVerified && partner?.IsActive,
      );
    }
    return list;
  }, [list, multiple.modeEnable]);

  return <PartnersList list={activePartnersList} categoryTitle={categoryTitle} />;
};

export default MyRecomendationsTab;
