import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { setFeedDrawerCommuteIdEffect } from 'store/effects';
import Icon from 'pages/Properties/Feed/Icons';
import styles from './styles.module.scss';

const AddCommute = ({ className }) => {
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(setFeedDrawerCommuteIdEffect('new'));
  };

  return (
    <div
      onClick={onClickHandler}
      testid="add_commute"
      className={classNames(styles.addCommute, className)}
    >
      <div className={styles.wrapper}>
        <Icon className={styles.icon} variant={Icon.ADD} />
        <p className={styles.text}>Add Commute</p>
      </div>
    </div>
  );
};

AddCommute.propTypes = {
  className: PropTypes.string,
};

AddCommute.defaultProps = {
  className: '',
};

export default AddCommute;
