import React, { useEffect, useState } from 'react';

import { Modal, Form, Input } from 'components-antd';
import Icon from 'pages/Properties/SearchResults/Icons';

import styles from './styles.module.scss';

export const RenameSearchInstanceModal = ({ searchName, onCancel, open, submit }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const submitForm = () => {
    form.submit();
  };
  const onFinish = ({ Name }) => {
    setLoading(true);
    submit(Name, setLoading);
  };

  useEffect(() => {
    if (open) {
      form.setFieldsValue({ Name: searchName });
    }
  }, [open]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        data-testid="rename-modal"
        className={styles.renameModal}
        okText={'Save'}
        open={open}
        confirmLoading={loading}
        onOk={submitForm}
        destroyOnClose={true}
        onCancel={onCancel}
        width={420}
      >
        <Form
          form={form}
          layout={'vertical'}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className={styles.header}>
            <Icon className={styles.icon} variant={Icon.BOOKMARK} />
            <span className={styles.title}>Rename</span>
          </div>
          <Form.Item
            name="Name"
            className={styles.inputField}
            label={'Name'}
            required={true}
            rules={[{ required: true, message: 'Please enter name' }]}
          >
            <Input
              placeholder={'Chicago - 3 BR / 2 BA'}
              maxLength={32}
              variant={Input.LIGHT}
              className={styles.input}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
