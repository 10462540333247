import { useState, useRef, useMemo } from 'react';
import classNames from 'classnames';

import { Popover } from 'components-antd';
import { Icons as Icon } from './Icons';
import { useOutsideClick } from 'hooks';
import { SplitTabTypes } from 'pages/Workshop/Transactions/TransactionFinancials/TransactionFinancials';

import styles from './styles.module.scss';

export const Options = ({ onEdit, onDelete, onMoveSide, splitTabType, representingRoles }) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLInputElement>(null);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const isDualTransaction = useMemo(() => {
    // Checking if the transaction is a dual transaction.
    return representingRoles?.length >= 2;
  }, [representingRoles]);

  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const PopoverContent = () => (
    <div ref={optionsRef} className={styles.options}>
      <ul>
        {isDualTransaction && (
          <li
            className={styles.item}
            onClick={() => {
              setOpen(false);
              onMoveSide();
            }}
          >
            <div className={styles.icon}>
              <Icon variant={Icon.MOVE} />
            </div>
            <span>Move to {splitTabType === SplitTabTypes.LISTING ? 'Buy' : 'List'}-Side</span>
          </li>
        )}
        <li className={styles.item} onClick={onEdit}>
          <div className={styles.icon}>
            <Icon variant={Icon.EDIT} />
          </div>
          <span>Edit</span>
        </li>
        <li
          className={styles.item}
          onClick={() => {
            setOpen(false);
            onDelete();
          }}
        >
          <div className={styles.icon}>
            <Icon variant={Icon.DELETE} />
          </div>
          <span>Delete</span>
        </li>
      </ul>
    </div>
  );

  return (
    <div className={classNames(styles.feeOptionsWrapper)}>
      <div
        testid="options"
        ref={buttonRef}
        onClick={onClickOptions}
        className={classNames(styles.optionsDots)}
      >
        <Icon variant={Icon.DOTS} />
      </div>
      <Popover
        open={open}
        overlayClassName={styles.feeOptionsPopover}
        content={<PopoverContent />}
      ></Popover>
    </div>
  );
};
