import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ adminPanel }) => adminPanel;
const mlsAgentsState = createSelector(localState, ({ mlsAgents }) => mlsAgents);

export const getMlsAgentsDashboardSelector = createSelector(
  mlsAgentsState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getMlsAgentsDataDashboardSelector = createSelector(mlsAgentsState, (state) =>
  get(state, 'data'),
);

export const getMlsAgentsSortSelector = createSelector(mlsAgentsState, ({ sort }) => sort);

export const getMlsAgentsFiltersSelector = createSelector(mlsAgentsState, ({ filters }) => filters);
