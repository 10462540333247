import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ vault }) => vault;

export const getVaultDocumentsList = createSelector(localState, ({ allClientDocuments }) => {
  return {
    ...allClientDocuments,
    isIdle: allClientDocuments?.state === IDLE,
    isPending: allClientDocuments?.state === PENDING,
    isData: !!allClientDocuments?.data,
  };
});

export const getClientTransactionDocumentsList = createSelector(
  localState,
  ({ clientTransactionDocuments }) => {
    return {
      ...clientTransactionDocuments,
      isIdle: clientTransactionDocuments?.state === IDLE,
      isPending: clientTransactionDocuments?.state === PENDING,
      isData: !!clientTransactionDocuments?.data,
    };
  },
);

export const getPreApprovalVaultDocumentsList = createSelector(
  localState,
  ({ preapprovalDocuments }) => {
    return {
      ...preapprovalDocuments,
      isIdle: preapprovalDocuments?.state === IDLE,
      isPending: preapprovalDocuments?.state === PENDING,
      isData: !!preapprovalDocuments?.data,
    };
  },
);

export const getArchiveVaultDocumentsList = createSelector(localState, ({ archives }) => {
  return {
    ...archives,
    isIdle: archives?.state === IDLE,
    isPending: archives?.state === PENDING,
    isData: !!archives?.data,
  };
});

export const getMetaVaultDocuments = createSelector(localState, ({ meta }) => {
  return {
    ...meta,
    isIdle: meta?.state === IDLE,
    isPending: meta?.state === PENDING,
    isData: !!meta?.data,
  };
});
