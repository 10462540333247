import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { Spinner } from 'components';

import styles from './styles.module.scss';

interface OnBoardingWrapperProps {
  className?: string;
  spinnerClassname?: string;
  isPending?: boolean;
  children?: React.ReactNode;
  loaderClassName?: string;
  testid?: string;
  customLoader?: React.ReactNode;
}

export const OnBoardingWrapper = forwardRef<any, OnBoardingWrapperProps>(
  (
    { isPending, className, spinnerClassname, children, loaderClassName, testid, customLoader },
    ref,
  ) => (
    <div testid={testid} ref={ref} className={classNames(styles.wrapper, className)}>
      {isPending
        ? customLoader ?? <Spinner className={spinnerClassname} loaderClassName={loaderClassName} />
        : children}
    </div>
  ),
);
