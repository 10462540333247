import { Collapse, Panel } from 'components-antd';
import { Add, Remove } from 'components/Icons';
import { GroupedTimeline } from '../../components/GroupedTimeline';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { TourSort, TourStatus } from 'app-constants/enums/tours';
import { fetchTours } from '../../helper';
import { TimelineItem } from '../../components/TimelineItem';
import { PanelHeader } from '../../components/PanelHeader';
import EmptyToursCard from '../EmptyToursCard';

export const Past = (props) => {
  const { onEdit, selectedSort, selectedTeamFilter } = props;

  const { tours: groupedTours } = useSelector(
    fetchTours(selectedSort, TourStatus.Past, selectedTeamFilter),
  );
  return (
    <>
      {!Object.keys(groupedTours).length ? <EmptyToursCard text={'past tours'} /> : <></>}
      {Object.keys(groupedTours)
        .sort()
        .reverse()
        .map((key, i) => (
          <Collapse
            bordered={false}
            accordion
            expandIcon={({ isActive }) =>
              isActive ? <Remove color={Remove.COLLAPSE} /> : <Add color={Add.COLLAPSE} />
            }
            expandIconPosition={'end'}
            className={styles.tourCards}
            ghost={true}
            defaultActiveKey={i}
            key={i}
          >
            <Panel
              header={
                <PanelHeader
                  sort={selectedSort}
                  groupedKey={key}
                  tours={groupedTours[key]}
                ></PanelHeader>
              }
              className={styles.tourCard}
              key={i}
            >
              {selectedSort === TourSort.Date ? (
                groupedTours[key].map((tour, index) => (
                  <TimelineItem sort={TourSort.Date} tour={tour} key={index}></TimelineItem>
                ))
              ) : (
                <GroupedTimeline
                  sort={selectedSort}
                  tours={groupedTours[key]}
                  key={i}
                ></GroupedTimeline>
              )}
            </Panel>
          </Collapse>
        ))}
    </>
  );
};
