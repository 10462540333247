import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';

import Icon from 'pages/Properties/SearchResults/Icons';
import { Edit } from 'components/Icons';
import { Dropdown, Radio } from 'components-antd';

import styles from './styles.module.scss';

const Actions = (props) => {
  const { className, index, prefType, onChangePref, onEdit, onDelete } = props;
  const [pref, setPref] = useState(prefType ? prefType : SEARCH_CRITERIA_IMPORTANCE.UNDEFINED);

  const onDeleteHandler = () => {
    onDelete();
  };

  Actions.options = [
    { id: SEARCH_CRITERIA_IMPORTANCE.MUST, label: 'Need' },
    { id: SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT, label: 'Want' },
    { id: SEARCH_CRITERIA_IMPORTANCE.UNDEFINED, label: 'N/A' },
  ];
  const onChangeHandler = (e, idx) => {
    setPref(e.target.value);
    onChangePref(e.target.value, idx);
  };

  const menu = (
    <div className={styles.dropdown}>
      <Radio.Group
        className={styles.checkboxItemWrap}
        onChange={(e) => onChangeHandler(e, index)}
        value={pref}
      >
        {Actions.options.map((item, idx) => (
          <Radio className={styles.checkboxItem} value={item.id} idx={idx}>
            {item.label}
          </Radio>
        ))}
      </Radio.Group>
      <div className={styles.divider} />
      <div onClick={onEdit} className={styles.item}>
        <Icon variant={Icon.EDIT} className={styles.menuIcon} />
        Edit
      </div>
      <div onClick={onDeleteHandler} className={styles.item}>
        <Icon variant={Icon.TRASH} className={styles.menuIcon} />
        Delete
      </div>
    </div>
  );

  return (
    <Dropdown
      overlay={menu}
      className={classNames(styles.actions, className)}
      getPopupContainer={(node) => node}
      trigger={['click']}
      overlayClassName={styles.actionsDropdown}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Edit className={styles.buttonIcon} />
      </a>
    </Dropdown>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

Actions.defaultProps = {
  className: '',
  onEdit: () => {},
  onDelete: () => {},
};

export default Actions;
