import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { convertNameToAvatarPlaceholder, getPropertyAddress } from 'helpers';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { link } from 'settings/navigation/link';
import { setTransactionTasksLastStateEffect } from 'store/effects/transactions';
import styles from './styles.module.scss';
import BaseNotification from '../../../BaseNotification';

const TaskNotificationSummary = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;

  const { TaskId, TransactionId, Assignor, Address } = Payload || {};

  const CreatorName = `${Assignor?.FirstName} ${Assignor?.LastName}` || '00:00';

  const getSubtitle = () => getPropertyAddress(Address);

  const onClickHandler = () => {
    const url = Payload.ViewLink;
    const urlObj = new URL(url);

    const idsData = urlObj.searchParams.get('ids');
    if (idsData) {
      dispatch(openNotificationsDrawerEffect({ open: false }));
      history.replace(`/workshop/tasks?ids=${idsData}`);
    }
  };

  return (
    <div className={classNames(styles.notificationType, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        subtitle={getSubtitle()}
        date={CreatedDate}
        groupType={groupType}
        onClick={onClickHandler}
        avatarPlaceholder={convertNameToAvatarPlaceholder(CreatorName)}
        severityLevel={SeverityLevel}
        notificationSummary={Payload.notificationSummary}
      />
    </div>
  );
};

TaskNotificationSummary.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

TaskNotificationSummary.defaultProps = {
  className: '',
};

export default TaskNotificationSummary;
