import { Fragment, useCallback, useEffect, useState } from 'react';
import { debounce, uniq } from 'lodash-es';

import { Modal, Button, Select, Option } from 'components-antd';
import { ConfirmationDialog, Input } from 'components';
import { showSuccessMessage } from 'helpers/success';

import { getTeamClients } from 'api/teamList';
import { getTeamListDataArray } from 'store/selectors/teamList';
import { useSelector, useDispatch } from 'react-redux';

import styles from './styles.module.scss';
import { ClientItem, TeamCollapsePanel } from './components';
import { reassignShareClientsEffect } from 'store/effects';

type AssignClientModalType = {
  isOpen: boolean;
  onClose: Function;
  contact: any;
  fetchTeamMemberClients: Function;
};

const sortType = {
  AgentASC: 'agentsAsc',
  AgentDESC: 'agentsDesc',
  ClientASC: 'clientsAsc',
  ClientDESC: 'clientsDesc',
};

const sortOptionsList = [
  { label: 'Agents (A to Z)', value: sortType.AgentASC },
  { label: 'Agents (Z to A)', value: sortType.AgentDESC },
  { label: 'Clients (A to Z)', value: sortType.ClientASC },
  { label: 'Clients (Z to A)', value: sortType.ClientDESC },
];

export const AssignClientsModal = ({
  contact,
  isOpen,
  onClose,
  fetchTeamMemberClients,
}: AssignClientModalType) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [selected, setSelected] = useState<Number[]>([]);
  const [teamClients, setTeamClients] = useState<any>([]);
  const teamList = useSelector(getTeamListDataArray);
  const teamListWithoutContact = teamList?.filter((item) => item?.Id !== contact?.Id);
  const [selectedSort, setSelectedSort] = useState(sortType.AgentASC);
  const [modalType, setModalType] = useState('Reassign');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const fetchTeamClients = async () => {
    setLoading(true);
    const list = await getTeamClients();
    setTeamClients(list?.data || []);
    setLoading(false);
  };

  useEffect(() => {
    fetchTeamClients();
  }, []);

  const closeDialog = () => {
    onClose();
    setSelected([]);
    setSelectedSort(sortType.AgentASC);
  };

  const onChangeHandler = (e, id) => {
    if (e.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((i) => i !== id));
    }
  };

  const allCheckedHandler = (e, clientIds) => {
    if (e.target.checked) {
      setSelected([...selected, ...clientIds]);
    } else {
      const filteredSelected = selected.filter((id) => !clientIds.includes(id));
      setSelected(filteredSelected);
    }
  };

  const getClients = (id) => {
    return teamClients?.filter((item) => item?.AssignedAgentIds.includes(id));
  };

  const getTeamClientsList = () => {
    if (searchString !== '') {
      return searchOnName('clients');
    } else {
      return sortedTeamClientsList();
    }
  };

  const getTeamMembersList = () => {
    if (searchString !== '') {
      return searchOnName('agents');
    } else {
      return sortedTeamAgentsList();
    }
  };

  const searchOnName = (type) => {
    const arrayToSearch = type === 'clients' ? teamClients : teamListWithoutContact;
    const searchRegex = new RegExp(searchString, 'i'); // 'i' flag for case-insensitive search
    return arrayToSearch?.filter(
      (obj) => searchRegex.test(obj?.FirstName) || searchRegex.test(obj?.LastName),
    );
  };

  const sortedTeamAgentsList = () => {
    let sortedTeamList: any[] = [];
    if (teamListWithoutContact && teamListWithoutContact.length > 0) {
      if (selectedSort === sortType.AgentASC) {
        sortedTeamList = [...teamListWithoutContact].sort((a, b) => {
          const fullNameA = `${a.FirstName} ${a.LastName}`.toLowerCase();
          const fullNameB = `${b.FirstName} ${b.LastName}`.toLowerCase();
          return fullNameA.localeCompare(fullNameB);
        });
      } else if (selectedSort === sortType.AgentDESC) {
        sortedTeamList = [...teamListWithoutContact].sort((a, b) => {
          const fullNameA = `${a.FirstName} ${a.LastName}`.toLowerCase();
          const fullNameB = `${b.FirstName} ${b.LastName}`.toLowerCase();
          return fullNameB.localeCompare(fullNameA);
        });
      } else {
        sortedTeamList = teamListWithoutContact;
      }
      return sortedTeamList;
    }

    return teamListWithoutContact;
  };

  const sortedTeamClientsList = () => {
    let sortedTeamClients: any[] = [];
    if (teamClients && teamClients.length > 0) {
      if (selectedSort === sortType.ClientASC) {
        sortedTeamClients = [...teamClients].sort((a, b) => {
          const fullNameA = `${a.FirstName} ${a.LastName}`.toLowerCase();
          const fullNameB = `${b.FirstName} ${b.LastName}`.toLowerCase();
          return fullNameA.localeCompare(fullNameB);
        });
      } else if (selectedSort === sortType.ClientDESC) {
        sortedTeamClients = [...teamClients].sort((a, b) => {
          const fullNameA = `${a.FirstName} ${a.LastName}`.toLowerCase();
          const fullNameB = `${b.FirstName} ${b.LastName}`.toLowerCase();
          return fullNameB.localeCompare(fullNameA);
        });
      } else {
        sortedTeamClients = teamClients;
      }
      return sortedTeamClients;
    }

    return teamClients;
  };

  const getClientsAssignedTo = (assignedAgentIds) => {
    const assignedToNames: string[] = [];
    assignedAgentIds.forEach((item) => {
      const teamAgent = teamList.find((teamAgent) => teamAgent?.Id === item);
      if (teamAgent) {
        assignedToNames.push(`${teamAgent?.FirstName} ${teamAgent?.LastName}`);
      }
    });
    return assignedToNames.join(', ');
  };

  const onChangeSearchString = useCallback(
    debounce((e) => setSearchString(e.target.value), 300),
    [],
  );

  const onReassignShare = async () => {
    setLoading(true);
    dispatch(
      reassignShareClientsEffect(
        {
          clientIds: uniq(selected),
          agentIds: [contact?.Id],
        },
        { type: modalType.toLowerCase() },
        (err) => {
          if (!err) {
            fetchTeamClients();
            fetchTeamMemberClients();
            showSuccessMessage(`Client(s) ${modalType.toLowerCase()} successful.`);
            setOpenConfirmationModal(false);
          }
        },
      ),
    );
    setLoading(false);
  };

  const handleConfirmationModal = (e, type) => {
    e.stopPropagation();
    setOpenConfirmationModal(true);
    setModalType(type);
  };

  const reassignShareContent = () => {
    const clientNames = () => {
      let names: string[] = [];
      selected.forEach((item) => {
        const client = teamClients.find((x) => x?.Id === item);
        if (client) {
          names.push(`${client?.FirstName} ${client?.LastName}`);
        }
      });
      return names.join(', ');
    };

    return (
      <>
        <h4>Are you sure you want to {modalType.toLocaleLowerCase()} the client(s)?</h4>
        <p>
          The client(s)&nbsp;<span className={styles.textBold}>{clientNames()}</span>&nbsp;will be
          &nbsp;{modalType === 'Reassign' ? 'reassigned to' : 'shared with'}&nbsp;
          <span className={styles.textBold}>
            {contact?.FirstName}&nbsp;{contact?.LastName}.
          </span>
        </p>
      </>
    );
  };

  return (
    <Fragment>
      <Modal
        open={isOpen}
        width={675}
        footer={null}
        onCancel={closeDialog}
        maskClosable={false}
        destroyOnClose
        className={styles.assignClientModal}
      >
        <div className={styles.header}>
          <h2 className={styles.title}>Assign Clients</h2>
          <p className={styles.subHeading}>
            Selected clients will be assigned to {contact?.FirstName} {contact?.LastName}.
          </p>
        </div>
        <div className={styles.secondaryHeader}>
          <Input
            className={styles.searchInput}
            placeholder="Search"
            variant={Input.LIGHT_ROUND}
            type="search"
            value={searchString}
            onChange={onChangeSearchString}
          />
          <Select
            onChange={(value) => setSelectedSort(value)}
            value={selectedSort}
            size="large"
            className={styles.sortSelectContainer}
            popupClassName={styles.sortDropdown}
            bordered={false}
          >
            {sortOptionsList.map((item, idx) => (
              <Option key={idx} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className={styles.content}>
          {selectedSort == sortType.AgentASC || selectedSort == sortType.AgentDESC ? (
            getTeamMembersList()?.map((teamItem, idx) => (
              <TeamCollapsePanel
                key={idx}
                name={`${teamItem?.FirstName} ${teamItem?.LastName}`}
                clients={getClients(teamItem?.Id)}
                selected={selected}
                onChange={onChangeHandler}
                allCheckedHandler={allCheckedHandler}
              />
            ))
          ) : (
            <div className={styles.clientsContainer}>
              {getTeamClientsList().map((client, idx) => (
                <ClientItem
                  key={idx}
                  client={client}
                  selected={selected}
                  onChange={onChangeHandler}
                  clientsAssignedTo={getClientsAssignedTo(client?.AssignedAgentIds)}
                />
              ))}
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <Button
            variant="secondary"
            className={styles.doneBtn}
            onClick={(e) => handleConfirmationModal(e, 'Reassign')}
            disabled={selected?.length === 0}
          >
            Reassign
          </Button>
          <Button
            variant="hollow-bordered"
            className={styles.doneBtn}
            onClick={(e) => handleConfirmationModal(e, 'Share')}
            disabled={selected?.length === 0}
          >
            Share
          </Button>
        </div>
      </Modal>

      {/* Confirmation Dialog */}
      <ConfirmationDialog
        onReject={() => setOpenConfirmationModal(false)}
        onConfirm={onReassignShare}
        isOpen={openConfirmationModal}
        confirmText={modalType}
        className={styles.reassignShareModal}
        isPending={loading}
      >
        <div className={styles.reassignShareConfirmText}>{reassignShareContent()}</div>
      </ConfirmationDialog>
    </Fragment>
  );
};
