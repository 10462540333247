import { useRef, useState } from 'react';
import { Row, Col, Checkbox } from 'antd';

import {
  EditLinkDuration,
  EditListingAgent,
  EditNotifications,
  EditOfferDeadline,
} from '../components';
import { Icons } from '../../Icons';
import { Navigation } from '../../components';

import styles from './styles.module.scss';
import { showErrorMessage } from '../../../../../../../helpers';
import { cloneDeep } from 'lodash-es';
import { IconText } from '../components/IconText';
import { Dropdown, Menu, MenuItem, Switch } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';

import classNames from 'classnames';
import { InformationText } from '../components/InformationText';
import { useScrollableRef } from '../../hooks/useScrollableRef';

const documentInitState: any = {
  name: '',
  required: false,
  selected: false,
  format: '*',
};
const RequiredOptionalFields = [
  {
    title: 'Required',
    key: 'required',
  },
  {
    title: 'Optional',
    key: 'optional',
  },
];

export const Documents = ({
  onNext,
  stageIndex,
  lastStageIndex,
  onUpdate,
  onFinish,
  setCurrentStageIndex,
  stagesStep,
  data,
}) => {
  const { RequiredDocuments } = data;
  const [state, setState] = useState({
    RequiredDocuments,
  });

  const addNewRef = useRef<null | HTMLDivElement>(null);
  const { scrollableDivRef } = useScrollableRef();

  const scrollToBottom = () => {
    addNewRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const hasEmptyDocument = () => {
    const length = state?.RequiredDocuments?.length;
    return state?.RequiredDocuments[length - 1]?.name === '';
  };

  const addDocument = () => {
    if (!hasEmptyDocument()) {
      const data = cloneDeep(state.RequiredDocuments);
      data.push(documentInitState);
      setState({ ...state, RequiredDocuments: data });
      setTimeout(() => scrollToBottom(), 500);
    }
  };

  const validateNoOverlap = () => {
    let error = false;
    const documents = state?.RequiredDocuments;
    const length = state?.RequiredDocuments?.length;
    const name = documents[length - 1].name;
    for (let i = 0; i < documents.length - 1; i++) {
      if (name === documents[i].name) {
        error = true;
        showErrorMessage('This document name already exists!');
        break;
      }
    }
    return error;
  };

  const isDisabled = () => {
    let disabled = false;

    state?.RequiredDocuments?.forEach((s) => {
      if (!s.name) {
        disabled = true;
      }
    });
    if (!state?.RequiredDocuments?.length) disabled = true;
    if (!disabled) disabled = validateNoOverlap();

    return disabled;
  };

  const removeDocument = (index) => {
    const data = state.RequiredDocuments;
    data.splice(index, 1);
    setState({ ...state, RequiredDocuments: data });
  };

  const onFieldChange = (field, value, index) => {
    const documents = cloneDeep([...state.RequiredDocuments]);
    if (field === 'required') {
      documents[index]['selected'] = value;
    }
    documents[index][field] = value;
    setState({
      ...state,
      RequiredDocuments: documents,
    });
  };

  const onSubmit = () => {
    const body = { ...state };
    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(lastStageIndex);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const menu = (document, index) => (
    <Menu
      className={styles.menuDropdown}
      onClick={(e) => {
        onFieldChange('required', e.key === 'required', index);
      }}
    >
      {RequiredOptionalFields.map(({ key, title, ...props }) => {
        const { required } = document;
        const requiredKey = key === 'required';
        return (
          <MenuItem key={key} {...props} className={styles.menuItem}>
            <span className={styles.menuItemSpan}>
              {title}{' '}
              {((required && requiredKey) || (!required && key === 'optional')) && (
                <Check color="#00B152" />
              )}
            </span>
          </MenuItem>
        );
      })}
    </Menu>
  );

  return (
    <div className={styles.documents}>
      <div className={styles.contentWrap}>
        <EditLinkDuration
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.LinkDuration)}
        />

        <EditOfferDeadline
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.OfferDeadline)}
        />

        <EditListingAgent
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.ListingAgent)}
        />

        <EditNotifications
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.Notifications)}
        />

        <IconText
          variant={'document-bg'}
          text={'What documents should be submitted?'}
          className={styles.iconText}
        />

        <div className={styles.content}>
          <div className={styles.documentsContainer}>
            {state.RequiredDocuments.map((d, index) => (
              <div key={index} className={styles.documentRow}>
                <Row className={styles.container}>
                  <Col span={24}>
                    <Checkbox
                      className={styles.checkbox}
                      checked={d.selected}
                      onChange={(e) => onFieldChange('selected', e.target.checked, index)}
                      defaultChecked={d.selected}
                    >
                      <div className={styles.documentInputContainer}>
                        <Icons variant="document-light" className={styles.documentIcon} />
                        <input
                          className={classNames(
                            styles.selector,
                            !d.name.trim() ? styles.emptySelector : '',
                          )}
                          type="Text"
                          value={d.name}
                          onChange={(e) => {
                            onFieldChange('name', e.target.value, index);
                          }}
                          placeholder="Enter Document Name"
                          required={true}
                        />
                        <Icons
                          className={styles.removeIcon}
                          variant="remove"
                          onClick={() => removeDocument(index)}
                        />
                      </div>
                    </Checkbox>
                  </Col>
                </Row>
                <span className={styles.requiredOptions}>
                  <Dropdown overlay={menu(d, index)} placement="bottom" trigger={['click']}>
                    <a
                      onClick={(e) => e.preventDefault()}
                      className={classNames(
                        styles.filterButton,
                        d.required ? styles.requiredButton : styles.optionalButton,
                      )}
                    >
                      <p>{d.required ? 'Required' : 'Optional'}</p>
                      <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
                    </a>
                  </Dropdown>
                </span>
              </div>
            ))}
            <div ref={addNewRef} />
          </div>

          <div
            className={classNames(styles.addNew, {
              [styles.disableAddNew]: hasEmptyDocument(),
            })}
            onClick={addDocument}
          >
            <Icons className={styles.addIcon} variant="add" />
            <h4>Add Document</h4>
          </div>
        </div>

        <div ref={scrollableDivRef}>
          <InformationText
            text={
              'Selected documents will be displayed in the document list on offer portal. Choose “Required” for documents that are essential for submitting offers.'
            }
          />
        </div>
      </div>
      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onFinish={onFinish}
        disabled={isDisabled()}
        className={styles.footer}
      />
    </div>
  );
};
