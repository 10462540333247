import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const TickType = {
  SENT: 'SENT',
  READ: 'READ',
};

const ReadTick = ({ className, tickType }) => {
  if (tickType === TickType.SENT) {
    return (
      <svg
        className={classNames(styles.tickIcon, className)}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 14L4 17L11 10"
          stroke="#D6D6D7"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (tickType === TickType.READ) {
    return (
      <svg
        className={classNames(styles.tickIcon, className)}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 14L4 17L11 10"
          stroke="#00B152"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 14L10 17L17 10"
          stroke="#00B152"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};

ReadTick.propTypes = {
  className: PropTypes.string,
  tickType: PropTypes.string,
};

export default ReadTick;
