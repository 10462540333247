import { Button } from 'components-antd';
import classNames from 'classnames';
import React from 'react';

import './CircleButton.scss';

interface CircleButtonProps {
  icon: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const CircleButton: React.FC<CircleButtonProps> = ({ icon, className, onClick }) => (
  <Button
    variant="tertiary"
    className={classNames('circleButton', className)}
    icon={icon}
    onClick={onClick}
  ></Button>
);
