import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getTransactionSelector } from 'store/selectors/transaction';
import { InputLabel } from 'components';
import { cloneDeep } from 'lodash-es';
import Reviewer from './Reviewer';
import { Users } from 'components/Icons';

import styles from './styles.module.scss';
import Icons from '../Icons';

const DocumentReviewers = (props) => {
  const {
    className,
    value,
    onChange,
    label,
    getEveryoneAccess,
    accessToEveryone,
    getJustMeAccess,
  } = props;
  const { transaction } = useSelector(getTransactionSelector);
  const allParticipantsIds = (transaction?.Participants || []).map(
    (participant) => participant?.Id,
  );

  const onChangeHandler = (e, id, checked) => {
    e.stopPropagation();
    getEveryoneAccess(false);
    if (checked) {
      const newValue = [...(value || []), id];
      onChange(e, newValue);
    } else {
      if (!value) {
        const clonedAllParticipantsIds = cloneDeep(allParticipantsIds);
        const uncheckedParticipantIndex = clonedAllParticipantsIds.findIndex(
          (participantId) => participantId === id,
        );
        clonedAllParticipantsIds.splice(uncheckedParticipantIndex, 1);
        return onChange(e, clonedAllParticipantsIds);
      }

      const clonedValue = cloneDeep(value);
      const removedIndex = (value || []).findIndex((val) => val === id);

      if (removedIndex !== -1 && clonedValue) {
        clonedValue.splice(removedIndex, 1);
        onChange(e, clonedValue);
      }
    }
  };

  const onEverybodyChange = (e, val, checked) => {
    if (checked) {
      onChange(e, allParticipantsIds);
      getEveryoneAccess(true);
      getJustMeAccess(false);
    } else {
      onChange(e, []);
      getEveryoneAccess(false);
    }
  };
  const onJustMeChange = (e, val, checked) => {
    if (checked) {
      getJustMeAccess(true);
    } else {
      getJustMeAccess(false);
    }
  };

  const getIsAllChecked = () => {
    const isAllChecked = accessToEveryone;
    if (isAllChecked) {
      getEveryoneAccess(true);
      getJustMeAccess(false);
    } else {
      getEveryoneAccess(false);
    }
    return isAllChecked;
  };

  return (
    <div className={classNames(styles.documentReviewers, className)}>
      <InputLabel className={styles.label} label={label} />
      <Reviewer
        reviewers={value}
        isChecked={getIsAllChecked()}
        onChange={onEverybodyChange}
        avatarPlaceholder={<Users className={styles.userIcon} color="#fff" />}
        label="Everyone"
        isRole={false}
      />
      <Reviewer
        reviewers={value}
        isChecked={true}
        onChange={onJustMeChange}
        avatarPlaceholder={<Icons className={styles.userIcon} variant={Icons.ME} />}
        label="Me"
        isRole={false}
      />
      {(transaction?.Participants || []).map((participant) => (
        <Reviewer
          key={participant?.Id}
          reviewers={value}
          value={participant}
          onChange={(e, val, checked) => onChangeHandler(e, participant?.Id, checked)}
        />
      ))}
    </div>
  );
};

DocumentReviewers.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.number),
  accessToEveryone: PropTypes.bool,
  onChange: PropTypes.func,
  getEveryoneAccess: PropTypes.func,
};

DocumentReviewers.defaultProps = {
  className: '',
  label: 'Who can access this document?',
  value: undefined,
  accessToEveryone: false,
  onChange: () => {},
  getEveryoneAccess: () => {},
};

export default DocumentReviewers;
