import * as Yup from 'yup';

export const schema = Yup.object().shape({
  bedsMin: Yup.number().positive(),
  bedsMax: Yup.number().positive(),
  bathsMin: Yup.number().positive(),
  bathsMax: Yup.number().positive(),
  lotSizeMin: Yup.number().positive(),
  lotSizeMax: Yup.number().positive(),
});
