import { upperCase } from 'lodash-es';
import classNames from 'classnames';
import { Avatar } from 'components-antd';

import styles from './styles.module.scss';

export const SingleMultiAvatar = (props) => {
  const { avatar, showOverlayCounter = true } = props;
  return (
    <div className={styles.avatarWrap}>
      {avatar?.length > 1 ? (
        <div className={styles.multiAvatar}>
          {avatar?.slice(0, 2).map((item, idx) => {
            return (
              <div key={idx} className={styles.avatarWrap}>
                {showOverlayCounter && avatar.length > 2 && idx === 1 ? (
                  <span className={styles.overlay}>+{avatar.length - 2}</span>
                ) : null}
                <Avatar
                  key={idx}
                  className={classNames(styles.avatar, {
                    [styles.avatarName]: !avatar,
                  })}
                  src={item?.AvatarUrl && item?.AvatarUrl}
                >
                  {upperCase(`${item?.FirstName?.[0] || ''}${item?.LastName?.[0] || ''}`)}
                </Avatar>
              </div>
            );
          })}
        </div>
      ) : (
        <Avatar
          className={classNames(styles.avatar, { [styles.avatarName]: !avatar })}
          src={avatar?.[0]?.AvatarUrl}
        >
          {upperCase(`${avatar?.[0]?.FirstName?.[0] || ''}${avatar?.[0]?.LastName?.[0] || ''}`)}
        </Avatar>
      )}
    </div>
  );
};
