import React from 'react';

export const IconUnRecommended = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_36068_241069)">
      <path
        d="M10.0014 14.7891L4.85802 17.4932L5.84052 11.7657L1.67969 7.7099L7.42969 6.8749L10.0014 1.66406L12.573 6.8749L18.323 7.7099L14.1622 11.7657L15.1447 17.4932L10.0014 14.7891Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_36068_241069">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconUnRecommended;
