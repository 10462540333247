import React, { memo, useMemo } from 'react';

const Filter = memo(({ className, color }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.72559 5.82925C3.44271 5.50596 3.6723 5 4.10188 5H19.8981C20.3277 5 20.5573 5.50596 20.2744 5.82925L14 13V18.691C14 18.8804 13.893 19.0535 13.7236 19.1382L10.7236 20.6382C10.3912 20.8044 10 20.5627 10 20.191V13L3.72559 5.82925Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className, color],
  ),
);

export default Filter;
