import React from 'react';
import PropTypes from 'prop-types';

const FullArrowDown = ({ className }) => (
  <div className={className}>
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 9.16675V34.8334"
        stroke="black"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 23.8333L22 34.8333"
        stroke="black"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 23.8333L22 34.8333"
        stroke="black"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

FullArrowDown.propTypes = {
  className: PropTypes.string,
};

FullArrowDown.defaultProps = {
  className: '',
};

export default FullArrowDown;
