export enum QuotationsCategory {
  Lenders = 'Lenders',
  Inspection = 'Inspection',
  Title = 'Title',
  Insurance = 'Insurance',
  Plumbing = 'Plumbing',
  Landscaping = 'Landscaping',
  Handymen = 'Handymen',
  Painters = 'Painters',
  Moving = 'Moving',
  Cleaning = 'Cleaning',
  Attorneys = 'Attorneys',
  Contractors = 'Contractors',
  Electricians = 'Electricians',
  Locksmiths = 'Locksmiths',
}
