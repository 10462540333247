import PropTypes from 'prop-types';

import { Select, Input, Phone } from 'components';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMlsAgentsDashboardSelector,
  getMlsAgentsDataDashboardSelector,
} from 'store/selectors/adminPanel';
import { useEffect, useMemo, useState } from 'react';
import {
  getTeamTitlesByTeamIdEffect,
  getTeamTitlesEffect,
  updateMemberProfileByAdminEffect,
  updateMemberRoleByAdminEffect,
} from 'store/effects';
import { Button } from 'components-antd';
import { TEAM_BASIC } from 'settings/constants/roles';
import { showSuccessMessage } from 'helpers';

export const MemberForm = (props) => {
  const {
    agentId,
    teamId,
    firstName,
    lastName,
    email,
    phone,
    title,
    role,
    getTeamMembers,
    onClose,
  } = props;
  const dispatch = useDispatch();
  const [titleVal, setTitleVal] = useState(title);
  const [roleVal, setRoleVal] = useState(role);
  const [teamTitles, setTeamTitles] = useState(null);
  const [pending, setPending] = useState(false);

  const fetchTeamTitles = () => {
    dispatch(
      getTeamTitlesByTeamIdEffect({ teamId }, {}, (err, resp) => {
        if (!err) setTeamTitles(resp.data);
      }),
    );
  };

  useEffect(() => {
    fetchTeamTitles();
  }, []);

  const titleOptions = useMemo(
    () =>
      (teamTitles || []).map(({ Title, Id }) => ({
        value: Title,
        name: Title,
        titleId: Title,
      })),
    [teamTitles],
  );

  const onClickSave = () => {
    setPending(true);
    if (roleVal.value !== role && roleVal !== role) {
      dispatch(
        updateMemberRoleByAdminEffect(
          {
            role:
              roleVal.value === 'Member' || roleVal.value === 'Basic' ? TEAM_BASIC : roleVal.value,
          },
          { agentId: agentId },
          (err) => {
            if (!err) {
              getTeamMembers(false);
              showSuccessMessage(
                `Role changed to ${
                  roleVal.value === 'Member' || roleVal.value === 'Basic'
                    ? TEAM_BASIC
                    : roleVal.value
                }.`,
              );
            }
          },
        ),
      );
    }
    if (titleVal.value !== title && titleVal !== title) {
      dispatch(
        updateMemberProfileByAdminEffect(
          { titleFreeText: titleVal.value },
          { agentId: agentId, teamId: teamId },
          (err) => {
            if (!err) {
              getTeamMembers(false);
              showSuccessMessage(`Agent title updated.`);
            }
            setPending(false);
            onClose();
          },
        ),
      );
    } else {
      getTeamMembers(false);
      setPending(false);
      onClose();
    }
  };

  return (
    <div className={styles.memberForm}>
      <div className={styles.inputWrapper}>
        <Input
          className={styles.formInput}
          name="firstName"
          type="text"
          value={firstName}
          variant={Input.LIGHT_ROUND}
          label="First Name"
          disabled
        />
        <Input
          className={styles.formInput}
          name="lastName"
          type="text"
          value={lastName}
          variant={Input.LIGHT_ROUND}
          label="Last Name"
          disabled
        />
      </div>
      <div className={styles.inputWrapper}>
        <Input
          className={styles.formInput}
          name="email"
          type="email"
          value={email}
          variant={Input.LIGHT_ROUND}
          label="Email Address"
          disabled
        />
      </div>
      <div className={styles.inputWrapper}>
        <Phone
          className={styles.formInput}
          inputClassName={styles.partnerPhoneWrapper}
          name="phone"
          value={phone}
          testid="phone"
          placeholder="(---) --- ----"
          label="Phone Number"
          variant={Phone.LIGHT_ROUND}
          disabled
        />
      </div>
      <div className={styles.inputWrapper}>
        <Select
          label={'Title'}
          options={titleOptions || []}
          placeholder={'Title'}
          onSelect={(e, val) => setTitleVal(val)}
          value={titleVal}
          selectWrapperClassname={styles.select}
          variant={Input.LIGHT_ROUND}
        />
        <Select
          label={'Role'}
          value={roleVal}
          onSelect={(e, value) => setRoleVal(value)}
          options={[
            ...(role === 'Owner' ? [{ value: 'Owner', name: 'Owner' }] : []),
            { value: 'Admin', name: 'Admin' },
            { value: 'Basic', name: 'Member' },
          ]}
          className={{
            wrapper: styles.agentRoleWrapper,
          }}
          arrowIconClassName={styles.arrowIcon}
          variant={Input.LIGHT_ROUND}
          disabled={role === 'Owner'}
        />
      </div>
      <div className={styles.btnWrapper}>
        <Button
          variant="secondary-rounded"
          className={styles.saveBtn}
          onClick={() => onClickSave()}
          loading={pending}
        >
          <span>Save</span>
        </Button>
      </div>
    </div>
  );
};

MemberForm.propTypes = {
  teamId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  title: PropTypes.string,
  role: PropTypes.string,
  getTeamMembers: PropTypes.func,
  onClose: PropTypes.func,
};

MemberForm.defaultProps = {
  teamId: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  title: '',
  role: '',
  getTeamMembers: (a) => {},
  onClose: () => {},
};
