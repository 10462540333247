import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ showingAggregate }) => showingAggregate;

export const getShowingAggregateDataSelector = createSelector(localState, ({ data }) => data);

export const getShowingAggregateList = createSelector(localState, (currentState) => {
  const showingAggregate = currentState?.data;
  return {
    ...showingAggregate,
    isIdle: currentState?.state === IDLE,
    isPending: currentState?.state === PENDING,
    isData: !!currentState?.data,
  };
});

export const getShowingIsArchiveSelector = createSelector(localState, (currentState) => ({
  isArchive: currentState.isArchive,
}));

export const getShowingIsFilterSelector = createSelector(localState, (currentState) => ({
  isFiltersApplied: currentState.isFiltersApplied,
}));

export const getShowingFilterSelector = createSelector(localState, (currentState) => ({
  showingsFilter: currentState.showingsFilter,
  listingsFilter: currentState.listingsFilter,
}));

export const getShowingArchiveIdSelector = createSelector(localState, (currentState) => ({
  showingId: currentState.showingId,
}));

export const getShowingAddressesSelector = createSelector(localState, (currentState) => {
  const showingAggregate = currentState?.data;
  let addrs = showingAggregate
    ? Object.keys(showingAggregate)
        .map((showingStatusAsKey) =>
          showingAggregate[showingStatusAsKey].map(
            (showing) => showing.ShowingDetails.PropertyTransaction.Property.Address,
          ),
        )
        .flat()
        .filter(
          (addressLine, position, self) =>
            position === self.findIndex((ad) => ad.Line1 === addressLine.Line1),
        )
    : [];
  return addrs;
});

export const getShowingClientsSelector = createSelector(localState, (currentState) => {
  const showingsData = currentState?.data;
  const uniqueClients = new Set();

  // Iterate through each key in the object (Upcoming, Past, Canceled, and NewFeedback)
  for (const key in showingsData) {
    if (Object.hasOwnProperty.call(showingsData, key) && Array.isArray(showingsData[key])) {
      const showingsArray = showingsData[key];

      // Iterate through each showing object in the array
      showingsArray.forEach((showing) => {
        // Check if the 'Clients' property exists and is an array
        if (Object.hasOwnProperty.call(showing, 'Clients') && Array.isArray(showing.Clients)) {
          // Add each client to the uniqueClients set
          showing.Clients.forEach((client) => {
            uniqueClients.add(client);
          });
        }
      });
    }
  }

  // Convert the set to an array and return it
  return Array.from(uniqueClients);
});

export const getShowingRequestersSelector = createSelector(localState, (currentState) => {
  const showingAggregate = currentState?.data;
  let requestors = showingAggregate
    ? Object.keys(showingAggregate)
        .map((showingStatusAsKey) =>
          showingAggregate[showingStatusAsKey].map((showing) => showing.BuyingAgentName),
        )
        .flat()
        .filter((buyingAgentName, position, self) => position === self.indexOf(buyingAgentName))
    : [];
  return requestors;
});
