import moment from 'moment';

export const initState = {
  Status: 'Active',
  PropertyTransactionId: null,
  SelectedDuration: {
    isIndefinite: false,
    isAddDays: false,
    isDateRange: false,
  },
  Indefinitely: false,
  LinkActiveStartDate: null,
  LinkActiveDays: null,
  LinkActiveEndDate: null,
  InvolveSellerAgent: false,
  InvolveSeller: false,
  FYIList: [],
  DelegateList: [],
  ConfirmedBookingNotification: {
    Self: false,
    Seller: false,
    Delegates: true,
    FYIList: true,
  },
  ShowingDuration: null,
  AddBufferBetweenShowings: false,
  BufferTime: null,
  DisplayBookingPageNotes: false,
  BookingPageNotes: null,
  SendAppointmentConfirmationNotes: false,
  AppointmentConfirmationNotes: null,
  RequestFeedback: false,
  AutomaticallySendFeedback: false,
  RequireDocumentPreApproval: false,
  OtherRequiredDocuments: [],
  EnableShowingLink: false,
  ShowingAvailability: [],
  Timezone: moment.tz.guess(),
};
