import { map } from 'lodash-es';
import { Select } from 'antd';
import { ArrowDown } from 'components/Icons';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

type SwitcherPropsType = {
  useKey: boolean;
  options?: string[];
  keyedOptions?: { key: number; val: string }[];
  default?: string;
  onchangeHandler: Function;
};

const { Option } = Select;

const Switcher = (props: SwitcherPropsType) => {
  const handleChange = (value) => {
    props.onchangeHandler(value);
  };

  const handleKeyedChange = (val, option) => {
    if (!Number.isNaN(Number(option.key))) props.onchangeHandler(Number(option.key));
  };

  if (props?.keyedOptions?.length && props.useKey) {
    return (
      <Select
        defaultValue={props.default ? props.default : props.keyedOptions[0].val}
        onChange={handleKeyedChange}
        bordered={false}
        className={styles.kitSwitcher}
        popupClassName={styles.kitSwitcherDropdown}
        getPopupContainer={(triggerNode) => triggerNode}
        suffixIcon={<ArrowDown />}
      >
        {map(props.keyedOptions, (item) => (
          <Option key={item.key} value={item.val}>
            {item.val}
          </Option>
        ))}
      </Select>
    );
  }

  if (props?.options?.length && !props.useKey) {
    return (
      <Select
        defaultValue={props.default ? props.default : props.options[0]}
        onChange={handleChange}
        bordered={false}
        className={styles.kitSwitcher}
        popupClassName={styles.kitSwitcherDropdown}
        getPopupContainer={(triggerNode) => triggerNode}
        suffixIcon={<ArrowDown />}
      >
        {map(props.options, (item) => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </Select>
    );
  }

  return <></>;
};

export default Switcher;
