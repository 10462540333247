import { simpleHash } from 'helpers';
import { LOGIN_CONTEXT } from 'settings/constants/sessionStorage';

export class SessionStorage {
  static clearSessionStorage() {
    sessionStorage.removeItem(LOGIN_CONTEXT);
  }
  /*
   * TokenContext is used in conjunction with SessionStorage LoginContext
   * to confirm that the currently logged in user is the same one for
   * the LocalStorage persisted Token
   */
  static getLoginContext() {
    return sessionStorage.getItem(LOGIN_CONTEXT);
  }

  static setLoginContextFromEmail(email) {
    const loginContext = simpleHash(email);
    return sessionStorage.setItem(LOGIN_CONTEXT, loginContext);
  }
}
