import { apiServer } from 'settings/web-services/api';

export function deleteTransactionFolder(cfg) {
  const { transactionId, folderId } = cfg;
  return apiServer.delete(`/transaction/${transactionId}/folders/${folderId}`);
}

export function moveTransactionDocument(cfg) {
  const { transactionId } = cfg;
  const body = {
    documentIds: cfg?.documentIds,
    folderId: cfg?.folderId,
  };
  return apiServer.put(`/transaction/${transactionId}/folders/move-documents`, body);
}

export function editTransactionFolder(cfg) {
  const { transactionId, folderId } = cfg;
  const body = {
    title: cfg?.title,
  };
  return apiServer.patch(`/transaction/${transactionId}/folders/${folderId}`, body);
}
