import { handleActions } from 'redux-actions';
import { get, cloneDeep } from 'lodash-es';
import {
  requestClientsSearchInstancesAction,
  requestUpdateClientsSearchInstanceAction,
  setClientsRenameSearchInstanceAction,
  deleteClientsSearchInstanceAction,
  setClientsSearchInstanceStatusAction,
} from 'store/actions/clientSearches';
import { IDLE, READY } from 'settings/constants/apiState';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestClientsSearchInstancesAction]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data.result', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestUpdateClientsSearchInstanceAction]: (state, { payload }) => {
      const clients = cloneDeep(state.data || []);
      if (payload.state === READY && clients?.length) {
        const newSearchInstance = get(payload, 'data.result');
        const clientIndex = clients.findIndex(
          (client) => client.Id === newSearchInstance?.ClientId,
        );
        if (clientIndex) {
          const client = clients[clientIndex];
          const queryIndex = client?.SearchQueries?.findIndex(
            (item) => item.Id === newSearchInstance?.Id,
          );
          if (queryIndex) {
            clients[clientIndex].SearchQueries[queryIndex] = newSearchInstance;
          }
        }
      }

      return {
        ...state,
        data: clients,
      };
    },
    [setClientsRenameSearchInstanceAction]: (state, { payload }) => {
      const clients = cloneDeep(state.data || []);
      const clientIndex = clients.findIndex((client) => client.Id === payload.clientId);
      const client = clients[clientIndex];
      const queryIndex = client.SearchQueries.findIndex((item) => item.Id === payload.id);
      const query = client.SearchQueries[queryIndex];
      clients[clientIndex].SearchQueries[queryIndex] = {
        ...query,
        Name: payload.name,
      };

      return {
        ...state,
        data: clients,
      };
    },
    [deleteClientsSearchInstanceAction]: (state, { payload }) => {
      const clients = cloneDeep(state.data || []);
      const clientIndex = clients.findIndex((client) => client.Id === payload.clientId);
      const client = clients[clientIndex];
      const SearchQueries = client.SearchQueries.filter((item) => item.Id !== payload.id) || [];
      clients[clientIndex].SearchQueries = SearchQueries;

      return {
        ...state,
        data: clients,
      };
    },
    [setClientsSearchInstanceStatusAction]: (state, { payload }) => {
      const clients = cloneDeep(state.data || []);
      const clientIndex = clients.findIndex((client) => client.Id === payload.clientId);
      const client = clients[clientIndex];
      const queryIndex = client.SearchQueries.findIndex((item) => item.Id === payload.id);
      const query = client.SearchQueries[queryIndex];
      clients[clientIndex].SearchQueries[queryIndex] = {
        ...query,
        Status: payload.status,
      };

      return {
        ...state,
        data: clients,
      };
    },
  },
  initialData,
);
