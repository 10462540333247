import transactions from './transactions';
import showings from './showings';
import offers from './offers';
import activity from './activity';
import forms from './forms';
import tasks from './tasks';
import milestones from './milestones';
import projects from './projects';

export default {
  workshop: '/workshop',
  ...forms,
  ...transactions,
  ...showings,
  ...activity,
  ...offers,
  ...tasks,
  ...milestones,
  ...projects,
};
