import { routes } from 'settings/navigation/routes';
import {
  Workshop,
  TransactionCreate,
  TransactionOverview,
  TransactionDetails,
  TransactionFinancials,
  TransactionTasks,
  TransactionDocuments,
  TransactionActivity,
  TransactionShowings,
  BasicPlanCreate,
  TransactionCancellation,
} from 'pages';
import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';
import { TransactionClose } from 'pages/Workshop/Transactions/TransactionClose';
import { TransactionOffers } from 'pages/Workshop/Transactions/TransactionOffers';
import { PostForm as TransactionPostCreate } from 'pages/Workshop/Transactions/TransactionCreate/components/PostForm';
import { TransactionUnderContract } from 'pages/Workshop/Transactions/TransactionUnderContract';

export default [
  {
    path: routes.transactions,
    component: Workshop,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  {
    path: routes.transactionCreate,
    component: TransactionCreate,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.transactionClose,
    component: TransactionClose,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.transactionUnderContract,
    component: TransactionUnderContract,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.transactionPostCreate,
    component: TransactionPostCreate,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.transactionBasicPlanCreate,
    component: BasicPlanCreate,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.transactionWithFlowBasicPlanCreate,
    component: BasicPlanCreate,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.transactionOverview,
    component: TransactionOverview,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  {
    path: routes.transactionDetails,
    component: TransactionDetails,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  {
    path: routes.transactionFinancials,
    component: TransactionFinancials,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.transactionShowings,
    component: TransactionShowings,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.transactionTasks,
    component: TransactionTasks,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },

  {
    path: routes.transactionDocuments,
    component: TransactionDocuments,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  {
    path: routes.transactionActivity,
    component: TransactionActivity,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  {
    path: routes.transactionOffers,
    component: TransactionOffers,
    exact: true,
    roles: [AGENT, CLIENT],
  },
  {
    path: routes.transactionCancellation,
    component: TransactionCancellation,
    exact: true,
    roles: [AGENT],
  },
];
