import { SubscriptionPlanPrice } from 'api/subscription';
import { keyBy } from 'lodash-es';

export const sortAndFilterPlans = (plans: SubscriptionPlanPrice[]): SubscriptionPlanPrice[] => {
  return plans
    .filter((plan) => ['month', 'year'].includes(plan.Period.Interval))
    .sort((a, b) => (a.Period.Interval === 'month' ? -1 : 1));
};

export const mapPriceIntervalToTitle = (interval: string) => {
  switch (interval) {
    case 'month':
      return 'Monthly';
    case 'year':
      return 'Annual';
    default:
      return 'Default'; // fallback, not used
  }
};

export const mapPriceIntervalToSubtext = (interval: string) => {
  switch (interval) {
    case 'year':
      return 'Save 20%';
    default:
      return null;
  }
};

export const calculateStandardPrice = (seats, standardPlans) => {
  let totalCost = 0;
  const allPrices = standardPlans.reduce((acc, plan) => [...acc, ...plan.Prices], []);
  const priceLookup = keyBy(allPrices, 'Nickname');

  const soloPrice = priceLookup.solo?.BasePrice || 0;
  const teamPrice = priceLookup.team?.BasePrice || 0;
  const seatPrice = priceLookup.seat?.BasePrice || 0;

  if (seats <= 2) {
    totalCost = soloPrice;
  } else if (seats === 3) {
    totalCost = teamPrice;
  } else if (seats > 3) {
    totalCost = teamPrice + Math.abs(seats - 3) * seatPrice;
  }

  return totalCost;
};

export const calculateLegacyPrice = (seats, prices, priceKey) => {
  let totalCost = 0;
  const priceDetails = prices.find((price) => price.PriceKey === priceKey);

  if (!priceDetails) {
    throw new Error(`No price found for price key: ${priceKey}`);
  }

  const { BasePrice, BasePriceUnitsIncluded, NextPerUnitPrice } = priceDetails;

  if (seats <= BasePriceUnitsIncluded) {
    totalCost = BasePrice;
  } else {
    totalCost = seats * NextPerUnitPrice;
  }

  return totalCost;
};
