import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeftRight = ({ className, color = '#AAABAB' }) => (
  <div className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 7H19M19 7L15.5 3.5M19 7L15.5 10.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 17H5M5 17L8.5 13.5M5 17L8.5 20.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

ArrowLeftRight.propTypes = {
  className: PropTypes.string,
};

ArrowLeftRight.defaultProps = {
  className: '',
};

export default ArrowLeftRight;
