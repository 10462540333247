import { SVGProps } from 'react';

type variantTypes =
  | 'add'
  | 'upload'
  | 'book'
  | 'delete'
  | 'document'
  | 'user'
  | 'compare'
  | 'accept'
  | 'reject'
  | 'counter'
  | 'share'
  | 'arrowup'
  | 'arrowdown'
  | 'edit'
  | 'checkoutlinedgreen'
  | 'eyeInvisibleCircleOutlined';
interface OfferIconProps extends SVGProps<SVGSVGElement> {
  variant: variantTypes;
}

export const Icons = ({ variant, ...props }: OfferIconProps) => {
  const renderIcon = () => {
    switch (variant) {
      case 'add': {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path d="M5 10H15" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M10 15L10 5" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case 'upload': {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <rect width="20" height="20" fill="#252D44" />
            <path
              d="M3.3335 14.166V15.8327C3.3335 16.2747 3.50909 16.6986 3.82165 17.0112C4.13421 17.3238 4.55814 17.4993 5.00016 17.4993H15.0002C15.4422 17.4993 15.8661 17.3238 16.1787 17.0112C16.4912 16.6986 16.6668 16.2747 16.6668 15.8327V14.166"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.8335 7.50065L10.0002 3.33398L14.1668 7.50065"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 3.33398V13.334"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'book': {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 12.6668C2.91212 12.1402 3.94678 11.8629 5 11.8629C6.05322 11.8629 7.08788 12.1402 8 12.6668C8.91212 12.1402 9.94678 11.8629 11 11.8629C12.0532 11.8629 13.0879 12.1402 14 12.6668"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 3.99989C2.91212 3.47328 3.94678 3.19604 5 3.19604C6.05322 3.19604 7.08788 3.47328 8 3.99989C8.91212 3.47328 9.94678 3.19604 11 3.19604C12.0532 3.19604 13.0879 3.47328 14 3.99989"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 4V12.6667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 4V12.6667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 4V12.6667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'delete': {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66748 4.6665H13.3341"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.66748 7.3335V11.3335"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.33252 7.3335V11.3335"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33252 4.6665L3.99919 12.6665C3.99919 13.0201 4.13966 13.3593 4.38971 13.6093C4.63976 13.8594 4.9789 13.9998 5.33252 13.9998H10.6659C11.0195 13.9998 11.3586 13.8594 11.6087 13.6093C11.8587 13.3593 11.9992 13.0201 11.9992 12.6665L12.6659 4.6665"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'document': {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_10962_82414)">
              <path
                d="M9.3335 2V4.66667C9.3335 4.84348 9.40373 5.01305 9.52876 5.13807C9.65378 5.2631 9.82335 5.33333 10.0002 5.33333H12.6668"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.3335 14H4.66683C4.31321 14 3.97407 13.8595 3.72402 13.6095C3.47397 13.3594 3.3335 13.0203 3.3335 12.6667V3.33333C3.3335 2.97971 3.47397 2.64057 3.72402 2.39052C3.97407 2.14048 4.31321 2 4.66683 2H9.3335L12.6668 5.33333V12.6667C12.6668 13.0203 12.5264 13.3594 12.2763 13.6095C12.0263 13.8595 11.6871 14 11.3335 14Z"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_10962_82414">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case 'compare': {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 12L11.5 14.5L19.5 6.5"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 4.5H7C5.61929 4.5 4.5 5.61929 4.5 7V17C4.5 18.3807 5.61929 19.5 7 19.5H17C18.3807 19.5 19.5 18.3807 19.5 17V12"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'user': {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6668 5.33341C10.6668 6.80617 9.47292 8.00008 8.00016 8.00008C6.5274 8.00008 5.3335 6.80617 5.3335 5.33341C5.3335 3.86066 6.5274 2.66675 8.00016 2.66675C9.47292 2.66675 10.6668 3.86066 10.6668 5.33341Z"
              stroke="#FF576D"
              strokeWidth="2"
            />
            <path
              d="M4 13.0001C4.24929 11.7536 5.39544 10.6667 6.66659 10.6667H9.33333C10.6045 10.6667 11.7507 11.7536 12 13.0001"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'accept': {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_10962_85318)">
              <path
                d="M4.99902 11.9998L9.99902 16.9998L19.999 6.99985"
                stroke="#00B152"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_10962_85318">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case 'reject': {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.69629 17.3033L17.3029 6.69668"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M17.3037 17.3033L6.69711 6.69668"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        );
      }
      case 'counter': {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_10962_85322)">
              <path
                d="M18 18V14C18 12.3431 16.6569 11 15 11H5M5 11L10 6M5 11L10 16"
                stroke="#51BFE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_10962_85322">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case 'share': {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 14V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V14"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 9L12 5L16 9"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 5V14"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'arrowup': {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.5 10L8 5.5L12.5 10"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'arrowdown': {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.5 6L8 10.5L12.5 6"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'edit': {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.00195 20.0013H8.00195L18.502 9.50128C19.0324 8.97085 19.3304 8.25142 19.3304 7.50128C19.3304 6.75113 19.0324 6.03171 18.502 5.50128C17.9715 4.97085 17.2521 4.67285 16.502 4.67285C15.7518 4.67285 15.0324 4.97085 14.502 5.50128L4.00195 16.0013V20.0013Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 6.50073L17.5 10.5007"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'checkoutlinedgreen': {
        return (
          <svg
            width="50"
            height="50"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="2" y="2" width="54" height="54" rx="27" stroke="#00B152" strokeWidth="4" />
            <path
              d="M20 30L26 36L38 24"
              stroke="#00B152"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'eyeInvisibleCircleOutlined': {
        return (
          <svg
            width="18"
            height="18"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="21" fill="#FFF2F3" stroke="#FF576D" strokeWidth="2" />
            <rect width="24" height="24" transform="translate(10 10)" fill="#FFF2F3" />
            <path
              d="M27.4113 16.5C25.9761 15.6238 24.1825 15 22 15C15 15 12 21.4167 12 22C12 22.395 13.3754 25.4643 16.4366 27.4053M30.357 19.1087C31.4766 20.495 32 21.7564 32 22C32 22.5833 29 29 22 29C21.4973 29 21.0152 28.9669 20.5534 28.9053"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.7298 23.2461C24.4326 23.8964 23.9099 24.422 23.2617 24.723"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24 19.7639C23.4692 19.2889 22.7684 19 22 19C20.3431 19 19 20.3431 19 22C19 22.7684 19.2889 23.4692 19.7639 24"
              stroke="#262626"
              strokeWidth="2"
            />
            <path
              d="M13 31L31 13"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
    }
  };

  return renderIcon();
};
