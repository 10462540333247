import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { getFormattedTime } from 'helpers';

import styles from './styles.module.scss';
import moment from 'moment';

export const Time = (props) => {
  let { timeSelect, timeList } = props;
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const currentDate = moment().date();

  const filteredTime = useMemo(() => {
    return timeList
      ?.filter((item) => {
        if (props.selectedDate === currentDate) {
          return item.time.isAfter();
        } else {
          return item;
        }
      })
      .sort((a, b) => a.time - b.time);
  }, [timeList, props.selectedDate, currentDate]);

  return (
    <div>
      <p className={styles.selectTimeLabel}>Select Time:</p>
      {filteredTime?.length ? (
        <div className={styles.timeListArea}>
          {filteredTime.map((item, index) =>
            item.selected ? (
              <div className={styles.timeBlockSelected} key={index}>
                <div className={styles.timeAvailableSelected}>
                  <span>{getFormattedTime(item?.time)}</span>
                </div>
              </div>
            ) : (
              <div
                className={classNames(styles.timeBlock, {
                  [styles.selected]: selectedIndex === index,
                })}
                key={index}
                onClick={() => setSelectedIndex(index)}
              >
                <div className={styles.timeAvailable}>
                  <span>{getFormattedTime(item?.time)}</span>
                </div>
                <div
                  className={styles.confirmButton}
                  onClick={() => {
                    timeSelect(item?.time, item?.minimumNotice);
                  }}
                >
                  <span>Confirm</span>
                </div>
              </div>
            ),
          )}
        </div>
      ) : (
        <p>Time Not Available</p>
      )}
    </div>
  );
};
