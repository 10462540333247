import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const FormGroup = ({ testid, className, children }) => (
  <div testid={testid} className={classNames(styles.formGroup, className)}>
    {children}
  </div>
);

FormGroup.propTypes = {
  testid: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FormGroup.defaultProps = {
  className: '',
  testid: undefined,
};

export default FormGroup;
