import { useState, useEffect } from 'react';
import { Spinner } from 'components';
import { Add } from 'components/Icons';
import ReportCard from './ReportCard';
import classNames from 'classnames';
import styles from './styles.module.scss';

function MyReports() {
  const [reports, setReports] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const delay = (delay) => {
    return new Promise((resolve) => setTimeout(resolve, delay));
  };

  const fetchKitsReports = async () => {
    try {
      await delay(1000);
      /*
      const response = await getStandardKitData();
      if (response.status === 200) {
        setStandardKitData(TempStandardKitData);
        setByKitLoaded(true);
      } else {
        setError(true);
      }
      */
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (loading) fetchKitsReports();
  }, [loading]);

  if (loading) {
    return (
      <div className={styles.myReports}>
        <div className={styles.myReportsHeader}>
          <div className={styles.myReportsHeaderText}>My Reports</div>
          <div className={styles.myReportsHeaderLink} onClick={() => {}}>
            {/* View All */}
          </div>
        </div>
        <div className={classNames(styles.myReportsContent, styles.myReportsContentFlex)}>
          <Spinner loaderClassName={classNames(styles.loader)} />
        </div>
        <div className={styles.myReportsFooter}>
          {/* <div className={styles.myReportsFooterNew} onClick={() => {}}>
            <Add color="collapse" />
            <div className={styles.myReportsFooterNewText}>New</div>
          </div> */}
        </div>
      </div>
    );
  } else if (error || !reports.length) {
    return (
      <div className={styles.myReports}>
        <div className={styles.myReportsHeader}>
          <div className={styles.myReportsHeaderText}>My Reports</div>
          <div className={styles.myReportsHeaderLink} onClick={() => {}}>
            {/* View All */}
          </div>
        </div>
        <div className={classNames(styles.myReportsContent, styles.myReportsContentFlex)}>
          <p className={styles.noReports}>No Reports Available</p>
        </div>
        <div className={styles.myReportsFooter}>
          {/* <div className={styles.myReportsFooterNew} onClick={() => {}}>
            <Add color="collapse" />
            <div className={styles.myReportsFooterNewText}>New</div>
          </div> */}
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.myReports}>
        <div className={styles.myReportsHeader}>
          <div className={styles.myReportsHeaderText}>My Reports</div>
          <div className={styles.myReportsHeaderLink} onClick={() => {}}>
            {/* View All */}
          </div>
        </div>
        <div className={styles.myReportsContent}>
          {reports.map((item) => (
            <ReportCard name="Report" day={1} month={1} year={2023} />
          ))}
        </div>
        <div className={styles.myReportsFooter}>
          {/* <div className={styles.myReportsFooterNew} onClick={() => {}}>
            <Add color="collapse" />
            <div className={styles.myReportsFooterNewText}>New</div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default MyReports;
