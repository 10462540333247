import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';

import { Button } from 'components-antd/Button';
import {
  requestUpdateQuoteFormEffect,
  requestUpdateSmartFormEffect,
} from 'store/effects/formBuilder';
import { getQuoteFormMetaSelect, getSmartFormMetaSelect } from 'store/selectors/formBuilder';

const AUTO_SAVE_AFTER = 10000; // auto save after every 10 seconds
const ON_LEAVE_MESSAGE =
  'Are you sure you want to leave this page? The changes you made will be lost';

export const AutoSave = ({ className, smartForm = false }) => {
  const {
    disabled,
    questions,
    folderName,
    tags,
    isFavorite,
    formName,
    Id,
    lastSaved,
    lastUpdated,
  } = smartForm ? useSelector(getSmartFormMetaSelect) : useSelector(getQuoteFormMetaSelect);
  const { disassociatedFields, views, isCAR } = smartForm
    ? useSelector(getSmartFormMetaSelect)
    : { disassociatedFields: [], views: [], isCAR: false };

  const dispatch = useDispatch();

  const [autoSave, setAutoSave] = useState(new Date());
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (dirty) {
        event.preventDefault();
        event.returnValue = ON_LEAVE_MESSAGE;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dirty]);

  useEffect(() => {
    if (lastUpdated && !lastSaved) {
      setDirty(true);
    } else if (lastUpdated && lastSaved) {
      if (moment(lastUpdated).isAfter(lastSaved)) setDirty(true);
      else setDirty(false);
    }
  }, [lastSaved, lastUpdated]);

  const autoSetInterval = () => {
    setAutoSave(new Date());
  };

  useEffect(() => {
    const interval = setInterval(autoSetInterval, AUTO_SAVE_AFTER);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    autoSaveData();
  }, [autoSave]);

  const routerPrompt = <Prompt when={dirty} message={ON_LEAVE_MESSAGE} />;

  const autoSaveData = (skipCheck = false) => {
    let data = {
      questions: disabled ? undefined : questions,
      folderName,
      tags,
      isFavorite,
      formName,
      Id,
    } as any;

    if (
      !skipCheck &&
      !(lastUpdated && !lastSaved) &&
      !(lastUpdated && lastSaved && moment(lastUpdated).isAfter(lastSaved))
    )
      return;

    setLoading(true);

    if (smartForm) {
      data = {
        ...data,
        disassociatedFields,
        views,
        isCAR,
      };
      dispatch(
        requestUpdateSmartFormEffect(data, () => {
          setLoading(false);
        }),
      );
    } else {
      dispatch(
        requestUpdateQuoteFormEffect(data, () => {
          setLoading(false);
        }),
      );
    }
  };

  return (
    <>
      {routerPrompt}
      <Button
        onClick={() => autoSaveData(true)}
        variant="secondary-inverted"
        className={className}
        data-testid="quote-save-button"
        disabled={loading}
      >
        Save
      </Button>
    </>
  );
};
