import { useState } from 'react';
import classNames from 'classnames';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';
import { Icons } from 'pages/Workshop/Icons';

import styles from './styles.module.scss';

const dropdownFields = [
  { itemValue: 'Just Me', icon: Icons.USER, iconColor: '#04a451' },
  { itemValue: 'Team', icon: Icons.TEAM_USER, iconColor: '#04a451' },
];

export const TeamFilter = ({ teamStats, filterChange }) => {
  const [value, setValue] = useState(dropdownFields[teamStats ? 0 : 1]);

  const onChange = (e) => {
    const val = e.target.value;
    filterChange(val.itemValue === 'Team');
    setValue(val);
  };

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => {
          return (
            <MenuItem key={idx}>
              <Radio value={{ itemValue: item.itemValue, icon: item.icon }} key={idx}>
                <div className={styles.option}>
                  <Icons variant={item.icon} className={styles.dropdownOptionIcon} />
                  <span className={styles.optionValue}>{item.itemValue}</span>
                </div>
                <Check className={styles.checkIcon} color={item.iconColor} />
              </Radio>
            </MenuItem>
          );
        })}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.filterWrap)}>
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <Icons variant={value.icon} className={styles.optionIcon} />
          <span className={styles.optionValue}>{value.itemValue}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} size={16} />
        </a>
      </Dropdown>
    </div>
  );
};
