import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getPreFormSelector } from 'store/selectors/requestQuote';
import { preFormQuestionsIds } from 'settings/constants/preForm';
import Icon from 'pages/RequestQuote/Icons';

import styles from './styles.module.scss';

const Controls = (props) => {
  const { className, isClient, onPrev, onNext, onSubmit, currentQuestionId } = props;
  const preForm = useSelector(getPreFormSelector);

  const onNextHandler = () => {
    if (
      (currentQuestionId === preFormQuestionsIds.property &&
        preForm?.[preFormQuestionsIds.property].address &&
        isClient) ||
      (currentQuestionId === preFormQuestionsIds.isProperty &&
        preForm?.[preFormQuestionsIds.isProperty] === false &&
        isClient)
    ) {
      return onSubmit();
    }

    if (
      currentQuestionId === preFormQuestionsIds.isProperty &&
      preForm?.[preFormQuestionsIds.isProperty] === false
    ) {
      return onNext(preFormQuestionsIds.isClient);
    }

    if (
      currentQuestionId === preFormQuestionsIds.isClient &&
      preForm?.[preFormQuestionsIds.isClient] === false
    ) {
      return onSubmit();
    }

    onNext();
  };

  const isNextDisabled = () =>
    (currentQuestionId === preFormQuestionsIds.property &&
      !preForm?.[preFormQuestionsIds.property]?.address) ||
    (currentQuestionId === preFormQuestionsIds.client && !preForm?.[preFormQuestionsIds.client]);

  return (
    <div testid="controls" className={classNames(styles.controls, className)}>
      <Icon testid="back" variant={Icon.ARROW} className={styles.prev} onClick={() => onPrev()}>
        Prev
      </Icon>
      {/* <Icon
        variant={Icon.ARROW}
        className={classNames(styles.next, { [styles.disabled]: isNextDisabled() })}
        onClick={onNextHandler}
        testid="next"
      >
        Next
      </Icon> */}
    </div>
  );
};

Controls.propTypes = {
  className: PropTypes.string,
  isClient: PropTypes.bool,
  onSubmit: PropTypes.func,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  currentQuestionId: PropTypes.string.isRequired,
};

Controls.defaultProps = {
  className: '',
  onPrev: () => {},
  onNext: () => {},
  onSubmit: () => {},
};

export default Controls;
