import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Actions from 'components/Drawers/components/Actions';
import { ArrowLeft } from 'components/Icons';

import styles from './styles.module.scss';
import { DrawerCancel } from 'components';

const ActionsHeader = ({ className, actions, label, onClick }) => (
  <div className={classNames(styles.wrapper, className)}>
    <div className={styles.headerBtns}>
      <button testid="header_button" className={styles.allMessagesBtn} onClick={onClick}>
        <ArrowLeft className={styles.arrow} />
        <span>{label}</span>
      </button>
      {actions.length ? <Actions actions={actions} /> : <DrawerCancel onClick={onClick} />}
    </div>
  </div>
);

ActionsHeader.propTypes = {
  className: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

ActionsHeader.defaultProps = {
  className: '',
  actions: [],
};

export default ActionsHeader;
