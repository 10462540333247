import { useState } from 'react';
import classNames from 'classnames';

import { Popover, Checkbox } from 'components-antd';
import { SortIcon } from 'components/Icons';

import styles from './styles.module.scss';

export const DrawerSort = (props) => {
  const { className } = props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const category = [
    { label: 'highToLow', value: 'Priority (High to low)' },
    { label: 'lowToHigh', value: 'Priority (Low to high)' },
    { label: 'newestToOldest', value: 'Date (Newest to oldest)' },
    { label: 'oldestToNewest', value: 'Date (Oldest to newest)' },
  ];

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleChange = (checkedValues) => {
    setValue(checkedValues.target.value);
  };

  const popoverContent = () => {
    return (
      <ul className={styles.filterOptions}>
        {category.map((item) => {
          return (
            <li>
              <Checkbox
                className={styles.sortCheckbox}
                key={item.label}
                onChange={handleChange}
                checked={item.value == value}
                value={item.value}
              >
                {item.value}
              </Checkbox>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Popover
      content={popoverContent}
      title="Sort By"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      overlayClassName={styles.filterDropdown}
      placement="bottomRight"
      className={styles.dropdown}
    >
      <a onClick={(e) => e.preventDefault()}>
        <SortIcon className={styles.icon} color="#262626" />
      </a>
    </Popover>
  );
};
