import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ template: { timeline } }) => timeline;

export const getTimelineTemplateSelector = createSelector(
  localState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTimelineTemplateDataSelector = createSelector(localState, (state) => ({
  title: get(state, 'data.Name') || '',
  description: get(state, 'data.Description') || '',
  areasOfOperation: get(state, 'data.AreasOfOperation') || '',
  milestones: (get(state, 'data.Milestones') || []).map(
    ({ Title, Offset, Initial, Operator, ControlOperatorOffset, IsPrivate }) => ({
      Title,
      DueDate: Offset,
      Initial,
      Operator,
      ControlOperatorOffset,
      IsPrivate,
    }),
  ),
  id: get(state, 'data.Id'),
}));

export const getTimelineTemplateDrawerIsOpenedSelector = createSelector(localState, (state) =>
  get(state, 'isDrawerOpened'),
);

export const getTimelineTemplateDrawerParamsSelector = createSelector(localState, (state) =>
  get(state, 'drawerParams'),
);
