import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash-es';

import { TitleValueBlock } from 'components';

import styles from './styles.module.scss';

const PropertyTabInfoBlockList = ({ list }) => {
  return (
    <div className={styles.content}>
      <div className={styles.twoBlocksWrapper}>
        {map(list, ({ title, value }, index) => (
          <TitleValueBlock
            key={index}
            className={styles.twoBlocksBlock}
            title={title}
            value={value}
          />
        ))}
      </div>
    </div>
  );
};

PropertyTabInfoBlockList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PropertyTabInfoBlockList;
