import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Select as CustomSelect } from 'components';
import { Checkbox } from 'components-antd';
import { prefsIds } from 'settings/constants/preferences';
import {
  SchoolsDistrictsSearch,
  SpecificSchoolOrDistrict,
} from 'pages/OnBoardingWizard/components/StageParts/StageNeighbors/Options/SchoolsModal/SchoolsDistrictsSearch/SchoolsDistrictsSearch';
import { SpecificSchoolOrDistrictValue } from 'pages/OnBoardingWizard/components/StageParts/StageNeighbors/Options/SchoolsModal';

import styles from './styles.module.scss';

export const SchoolOptions = ({ title, softCriteria, type, onClick }) => {
  const [isSpecificSchool, setIsSpecificSchool] = useState(true);
  const [specificItems, setSpecificItems] = useState<SpecificSchoolOrDistrict[]>([]);
  const [schoolTypes, setSchoolTypes] = useState<string[]>([]);

  const onSchoolChange = (selectedSpecificItems, selectedSchoolTypes?) => {
    const combinedValue = {} as any;

    if (isSpecificSchool) {
      setSpecificItems(selectedSpecificItems);
      if (selectedSpecificItems?.length) {
        const selectedSpecifics = selectedSpecificItems.map(
          (item) => JSON.parse(item.value) as SpecificSchoolOrDistrictValue,
        );
        combinedValue.SpecificSchools = selectedSpecifics
          .filter((item) => item.Type === 'School')
          .map((item) => ({
            Id: item.Id,
            Name: item.Name,
          }));
        combinedValue.SpecificDistricts = selectedSpecifics
          .filter((item) => item.Type === 'District')
          .map((item) => ({
            Id: item.Id,
            Name: item.Name,
          }));
      }
    } else {
      setSchoolTypes(selectedSchoolTypes);
      if (selectedSchoolTypes?.length) {
        combinedValue.NonSpecificConfig = {
          SchoolTypes: selectedSchoolTypes,
        };
      }
    }

    onClick(title, softCriteria[title].value, type, combinedValue);
  };

  useEffect(() => {
    if (title === prefsIds.goodSchools && softCriteria[title]) {
      const schoolData = softCriteria[title].data;
      const initialSpecificItems: SpecificSchoolOrDistrict[] = [];
      if (schoolData?.SpecificDistricts) {
        initialSpecificItems.push(
          ...schoolData.SpecificDistricts.map((item) => ({
            label: item.Name,
            value: JSON.stringify({
              Id: item.Id,
              Name: item.Name,
              Type: 'District',
            }),
          })),
        );
      }
      if (schoolData?.SpecificSchools) {
        initialSpecificItems.push(
          ...schoolData.SpecificSchools.map((item) => ({
            label: item.Name,
            value: JSON.stringify({
              Id: item.Id,
              Name: item.Name,
              Type: 'School',
            }),
          })),
        );
      }

      setIsSpecificSchool(initialSpecificItems?.length !== 0);
      setSpecificItems(initialSpecificItems);
      setSchoolTypes(schoolData?.NonSpecificConfig?.SchoolTypes ?? []);
    }
  }, []);

  return (
    <>
      {title === prefsIds.goodSchools &&
        softCriteria[title] &&
        softCriteria[title]?.value !== 'N/A' && (
          <div className={styles.schoolOptions}>
            <h4>Is there a specific school or school district that is important to you? </h4>
            <Checkbox
              className={styles.yesCheckbox}
              checked={isSpecificSchool}
              defaultChecked={isSpecificSchool}
              onChange={() => setIsSpecificSchool(true)}
            >
              Yes
            </Checkbox>
            <Checkbox
              checked={!isSpecificSchool}
              defaultChecked={!isSpecificSchool}
              onChange={() => setIsSpecificSchool(false)}
            >
              No
            </Checkbox>

            {isSpecificSchool ? (
              <>
                <h4 className={styles.specificSchoolHeading}>Which ones? </h4>
                <SchoolsDistrictsSearch
                  value={specificItems}
                  onChange={(newValue) => {
                    onSchoolChange(newValue as SpecificSchoolOrDistrict[]);
                  }}
                />
              </>
            ) : (
              <>
                <h4 className={styles.schoolTypesHeading}>
                  What type of schools are you interested in?{' '}
                </h4>
                <CustomSelect
                  name={prefsIds.goodSchools}
                  multiple
                  className={{ wrapper: styles.wrapper, input: styles.selectInput }}
                  variant={CustomSelect.DARK_HALF}
                  options={SchoolOptions.SCHOOL_OPTIONS}
                  value={schoolTypes}
                  onSelect={(event, values) => onSchoolChange([], values)}
                  placeholder="School type"
                  checkboxPostion="start"
                  closeOnSelect={false}
                />
              </>
            )}
          </div>
        )}
    </>
  );
};

SchoolOptions.SCHOOL_OPTIONS = [
  { id: 1, name: 'Public', value: 'Public' },
  { id: 2, name: 'Private', value: 'Private' },
  { id: 3, name: 'Magnet', value: 'Magnet' },
  { id: 4, name: 'Charter', value: 'Charter' },
];

SchoolOptions.propTypes = {
  title: PropTypes.string,
  softCriteria: PropTypes.shape({}),
  type: PropTypes.string,
  onClick: PropTypes.func,
};

SchoolOptions.defaultProps = {
  title: '',
  softCriteria: {},
  type: '',
  onClick: () => {},
};
