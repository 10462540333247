import { createAction } from 'redux-actions';

export const requestPublishedFormsAction = createAction('REQUEST/PUBLISHED_FORMS');

export const requestFormTemplatesAction = createAction('REQUEST/FORM_TEMPLATES');

export const requestFormRecentUploadsAction = createAction('REQUEST/FORM_RECENT_UPLOADS');

export const requestFormMetaAction = createAction('REQUEST/FORM_META');

export const requestSmartFormDataAction = createAction('REQUEST/SMART_FORM_DATA');
export const requestBundleTemplateAction = createAction('REQUEST/BUNDLE_FORM_DATA');

export const requestAgentFormRolesAction = createAction('REQUEST/AGENT_FORM_ROLES');

export const resetSmartFormDataAction = createAction('RESET/SMART_FORM_DATA');

export const requestSendFormProcessAction = createAction('REQUEST/SEND_FORM_PROCESS');

export const updatePreFormResponseAction = createAction('UPDATE/PRE_FORM_RESPONSE');

export const resetPreFormAction = createAction('RESET/PRE_FORM_RESPONSE');

export const createNewFormAction = createAction('CREATE/NEW_FORM');

export const addNewDocumentInDynamicFormAction = createAction('CREATE/NEW_FORM_DYNAMIC_VIEW');

export const updateFormAction = createAction('UPDATE/FORM');

export const reorderBundleDocumentAction = createAction('UPDATE/REORDER_BUNDLE_DOCUMENT');

export const requestFormProcessDocumentAction = createAction('REQUEST/FORM_PROCESS_DOCUMENT');

export const resetFormProcessAction = createAction('RESET/FORM_PROCESS_DOCUMENT');

export const updateFormProcessDocumentResponseAction = createAction(
  'UPDATE/FORM_PROCESS_DOCUMENT_RESPONSE',
);

export const requestPostFormDataAction = createAction('REQUEST/POST_FORM_DATA');

export const setFormLibraryModalVisibleAction = createAction('SET_FORM_LIBRARY_MODAL_VISIBLE');

export const setFormShowArchiveAction = createAction('SET_FORM_SHOW_ARCHIVE');

export const deferQuestionAuthAction = createAction('POST/DEFER_QUESTION_AUTH');

export const undoDeferQuestionAuthAction = createAction('POST/UNDO_DEFER_QUESTION_AUTH');

export const deferQuestionAnonAction = createAction('POST/DEFER_QUESTION_ANON');

export const undoDeferQuestionAnonAction = createAction('POST/UNDO_DEFER_QUESTION_ANON');

export const requestDeferredQuestionsAction = createAction('REQUEST/DEFERRED_QUESTIONS');

export const resetDeferredQuestionsAction = createAction('RESET/DEFERRED_QUESTIONS');

export const deleteDeferredQuestionAction = createAction('DELETE/DEFERRED_QUESTION');

export const resetPostFormEffectAction = createAction('RESET/POST_FORM');

export const updateSignatureSequenceAction = createAction('UPDATE/SIGNATURE_SEQUENCE');

export const requestPublishedFormAction = createAction('REQUEST/PUBLISHED_FORM');

export const requestCreateBundleFormAction = createAction('REQUEST/CREATE_BUNDLE_FORM');

export const updateFormRoleAction = createAction('UPDATE/PRE_FORM_ROLE');

export const requestDynamicFormDocumentAction = createAction('REQUEST/DYNAMIC_FORM_DOCUMENT');
export const requestDynamicFormBundleDocumentAction = createAction(
  'REQUEST/DYNAMIC_FORM_DOCUMENT_BUNDLE',
);

export const resetDynamicFormAction = createAction('RESET/DYNAMIC_FORM');

export const updateDynamicFormScaleAction = createAction('RESET/DYNAMIC_FORM_SCALE_UPDATE');

export const selectDynamicFormRoleAction = createAction('SELECT/DYNAMIC_FORM_ROLE_SELECT');

export const updateDynamicFormQuestionAction = createAction('UPDATE/DYNAMIC_FORM_QUESTION');

export const selectDynamicFormFieldAction = createAction('SELECT/DYNAMIC_FORM_FIELD_SELECT');

export const selectDynamicFormAction = createAction('SELECT/DYNAMIC_FORM_SELECT');

export const deleteDynamicFormQuestionAction = createAction('DELETE/DYNAMIC_FORM_QUESTION');

export const updateDynamicFormDocumentQuestionAction = createAction(
  'UPDATE/DYNAMIC_FORM_DOCUMENT_QUESTION',
);

export const progressDynamicFormAction = createAction('UPDATE/DYNAMIC_FORM_PROGRESS');
export const progressSaveAsTemplateFormAction = createAction(
  'UPDATE/SAVE_AS_TEMPLATE_FORM_PROGRESS',
);

export const regressFormDocumentAction = createAction('UPDATE/DYNAMIC_FORM_REGRESS');

export const updateDynamicFormActiveQuestionAction = createAction(
  'UPDATE/DYNAMIC_FORM_ACTIVE_QUESTION',
);

export const updateDynamicFormEditorConfigAction = createAction(
  'UPDATE/DYNAMIC_FORM_EDITOR_CONFIG',
);

export const updateDynamicFormResponseAction = createAction(
  'UPDATE/DYNAMIC_FORM_QUESTION_RESPONSE',
);

export const updateDynamicFormAction = createAction('UPDATE/DYNAMIC_FORM_UPDATE');
export const updateDynamicFormSignatureConfigAction = createAction(
  'UPDATE/DYNAMIC_FORM_SIGNATURE_CONFIG_UPDATE',
);

export const requestDynamicPostForm = createAction('REQUEST/DYNAMIC_POST_FORM_DATA');

export const requestSignatories = createAction('REQUEST/GET_SIGNATORIES');

export const updateRequestSignatories = createAction('REQUEST/UPDATE_SIGNATORIES');

export const updateDynamicRequiredQuestionAction = createAction(
  'UPDATE/DYNAMIC_REQUIRED_ERROR_CONFIG',
);

export const updateHideQuestionRoleAction = createAction('UPDATE/HIDE_QUESTION_ROLE_UPDATE');

export const updateFormsFiltersAction = createAction('UPDATE/FORMS_FILTERS');

export const saveBundleAsTemplateAction = createAction('UPDATE/SAVE_BUNDLE_TEMPLATE');

export const deleteDocumentAction = createAction('DELETE/BUNDLE_DOCUMENT');
