import React from 'react';
import { TagCount } from './TagCount';

interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'Empty' | 'Filled' | 'Filled Count';
  count?: number;
}
const TagIcon = ({ variant, count }) => {
  switch (variant) {
    case 'Filled':
      return (
        <svg
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="path-1-inside-1_1946_42916" fill="white">
            <path d="M12.6202 -3.22582e-07L2 -7.86805e-07C0.89543 -8.35087e-07 -3.91405e-08 0.89543 -8.74228e-08 2L-5.24537e-07 12C-5.72819e-07 13.1046 0.895429 14 2 14L12.6202 14C13.496 14 14.328 13.6173 14.898 12.9524L18.8844 8.30158C19.5263 7.5526 19.5263 6.4474 18.8844 5.69842L14.898 1.04763C14.328 0.382688 13.496 -2.843e-07 12.6202 -3.22582e-07Z" />
          </mask>
          <path
            d="M12.6202 -3.22582e-07L2 -7.86805e-07C0.89543 -8.35087e-07 -3.91405e-08 0.89543 -8.74228e-08 2L-5.24537e-07 12C-5.72819e-07 13.1046 0.895429 14 2 14L12.6202 14C13.496 14 14.328 13.6173 14.898 12.9524L18.8844 8.30158C19.5263 7.5526 19.5263 6.4474 18.8844 5.69842L14.898 1.04763C14.328 0.382688 13.496 -2.843e-07 12.6202 -3.22582e-07Z"
            fill="#747475"
          />
          <path
            d="M14.898 1.04763L16.4165 -0.253958L16.4165 -0.253958L14.898 1.04763ZM18.8844 5.69842L17.3658 7L17.3658 7L18.8844 5.69842ZM18.8844 8.30158L20.4029 9.60317L20.4029 9.60317L18.8844 8.30158ZM14.898 12.9524L16.4165 14.254L16.4165 14.254L14.898 12.9524ZM12.6202 14L12.6202 12L12.6202 14ZM2 14L2 16L2 16L2 14ZM2 2L12.6202 2L12.6202 -2L2 -2L2 2ZM13.3794 2.34921L17.3658 7L20.4029 4.39683L16.4165 -0.253958L13.3794 2.34921ZM17.3658 7L13.3795 11.6508L16.4165 14.254L20.4029 9.60317L17.3658 7ZM12.6202 12L2 12L2 16L12.6202 16L12.6202 12ZM2 12L2 2L-2 2L-2 12L2 12ZM2 12L-2 12C-2 14.2091 -0.209141 16 2 16L2 12ZM13.3795 11.6508C13.1895 11.8724 12.9121 12 12.6202 12L12.6202 16C14.0798 16 15.4666 15.3622 16.4165 14.254L13.3795 11.6508ZM17.3658 7L17.3658 7L20.4029 9.60317C21.6868 8.10521 21.6868 5.89479 20.4029 4.39683L17.3658 7ZM12.6202 2C12.9121 2 13.1895 2.12756 13.3795 2.34921L16.4165 -0.253958C15.4666 -1.36219 14.0798 -2 12.6202 -2L12.6202 2ZM2 -2C-0.209141 -2 -2 -0.209138 -2 2L2 2L2 2L2 -2Z"
            fill="#747475"
            mask="url(#path-1-inside-1_1946_42916)"
          />
        </svg>
      );
    case 'Filled Count':
      return <TagCount count={count} />;
    default:
      return (
        <svg
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="path-1-inside-1_1946_42861" fill="white">
            <path d="M12.6202 -3.22582e-07L2 -7.86805e-07C0.89543 -8.35087e-07 -3.91405e-08 0.89543 -8.74228e-08 2L-5.24537e-07 12C-5.72819e-07 13.1046 0.895429 14 2 14L12.6202 14C13.496 14 14.328 13.6173 14.898 12.9524L18.8844 8.30158C19.5263 7.5526 19.5263 6.4474 18.8844 5.69842L14.898 1.04763C14.328 0.382688 13.496 -2.843e-07 12.6202 -3.22582e-07Z" />
          </mask>
          <path
            d="M14.898 1.04763L16.4165 -0.253958L16.4165 -0.253958L14.898 1.04763ZM18.8844 5.69842L17.3658 7L17.3658 7L18.8844 5.69842ZM18.8844 8.30158L20.4029 9.60317L20.4029 9.60317L18.8844 8.30158ZM14.898 12.9524L16.4165 14.254L16.4165 14.254L14.898 12.9524ZM12.6202 14L12.6202 12L12.6202 14ZM2 14L2 16L2 16L2 14ZM2 2L12.6202 2L12.6202 -2L2 -2L2 2ZM13.3794 2.34921L17.3658 7L20.4029 4.39683L16.4165 -0.253958L13.3794 2.34921ZM17.3658 7L13.3795 11.6508L16.4165 14.254L20.4029 9.60317L17.3658 7ZM12.6202 12L2 12L2 16L12.6202 16L12.6202 12ZM2 12L2 2L-2 2L-2 12L2 12ZM2 12L-2 12C-2 14.2091 -0.209141 16 2 16L2 12ZM13.3795 11.6508C13.1895 11.8724 12.9121 12 12.6202 12L12.6202 16C14.0798 16 15.4666 15.3622 16.4165 14.254L13.3795 11.6508ZM17.3658 7L17.3658 7L20.4029 9.60317C21.6868 8.10521 21.6868 5.89479 20.4029 4.39683L17.3658 7ZM12.6202 2C12.9121 2 13.1895 2.12756 13.3795 2.34921L16.4165 -0.253958C15.4666 -1.36219 14.0798 -2 12.6202 -2L12.6202 2ZM2 -2C-0.209141 -2 -2 -0.209138 -2 2L2 2L2 2L2 -2Z"
            fill="#747475"
            mask="url(#path-1-inside-1_1946_42861)"
          />
        </svg>
      );
  }
};
const Tag = ({ variant, count, ...props }: TagProps) => (
  <div {...props}>
    <TagIcon variant={variant} count={count} />
  </div>
);
export default Tag;
