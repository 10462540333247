import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { link } from 'settings/navigation/link';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import BaseNotification from '../../../BaseNotification';
import PropertyIcon from '../../../PropertyIcon';

import styles from './styles.module.scss';
import { getRecommendedPropsV2Effect } from 'store/effects/feed/feedV2';

const NewAgentRecommendation = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const { PropertyIds } = Payload || {};

  const getSubtitle = () => '';

  const onClickHandler = () => {
    if (PropertyIds?.length > 1) {
      dispatch(getRecommendedPropsV2Effect());
      dispatch(openNotificationsDrawerEffect({ open: false }));
      history.replace(routes.feed);
    } else {
      dispatch(openNotificationsDrawerEffect({ open: false }));
      history.replace(link.toFeedListingDetails(PropertyIds?.[0]));
    }
  };

  return (
    <div className={classNames(styles.notificationNewAgentRecommendation, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        subtitle={getSubtitle()}
        date={CreatedDate}
        avatarPlaceholder={<PropertyIcon />}
        onClick={onClickHandler}
        groupType={groupType}
        severityLevel={SeverityLevel}
      />
    </div>
  );
};

NewAgentRecommendation.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    AgentFirstName: PropTypes.string,
    AgentLastName: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

NewAgentRecommendation.defaultProps = {
  className: '',
};

export default NewAgentRecommendation;
