import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { LocalHeader } from './components/LocalHeader';
import { ClientsList } from './components/ClientsList';
import { ReassignShareModal } from './components/ReassignShareModal';
import { AssignClientsModal } from './components/AssignClientsModal';
import { LocalFooter } from './components/LocalFooter';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { getTeamMemberClients } from 'api/teamList';
import { reassignShareClientsEffect, requestGetTeamListEffect } from 'store/effects';
import { showSuccessMessage } from 'helpers/success';

import styles from './styles.module.scss';

export const Clients = () => {
  const [multiSelect, setMultiSelect] = useState(false);
  const [clientList, setClientList]: any = useState([]);
  const [selectedClients, setSelectedClients]: any = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAssignClientsModal, setShowAssignClientsModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [loading, setLoading] = useState(false);
  const details = useSelector(getAgentDetailsSelector);
  const contact = details?.data || {};
  const dispatch = useDispatch();

  const fetchTeamMemberClients = async () => {
    const clients = await getTeamMemberClients(null, { agentId: contact?.Id });
    setClientList(clients?.data || []);
  };

  useEffect(() => {
    fetchTeamMemberClients();
    dispatch(requestGetTeamListEffect({}, { silent: true }));
  }, []);

  const onOpenModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const onReassignShare = (agentIds) => {
    setLoading(true);
    dispatch(
      reassignShareClientsEffect(
        {
          clientIds: selectedClients,
          agentIds,
        },
        { type: modalType.toLowerCase() },
        (err) => {
          if (!err) {
            fetchTeamMemberClients();
            showSuccessMessage(`Client(s) ${modalType.toLowerCase()} successful.`);
            onCloseModal();
          }
          setLoading(false);
        },
      ),
    );
  };

  const onCloseModal = () => {
    setShowModal(false);
    setMultiSelect(false);
    setSelectedClients([]);
  };

  return (
    <div className={styles.clients}>
      <LocalHeader
        multiSelect={multiSelect}
        setMultiSelect={(val) => {
          setMultiSelect(val);
          setSelectedClients([]);
        }}
        openAssignClientsModal={() => setShowAssignClientsModal(true)}
        count={clientList?.length}
        teamRole={contact?.TeamRole}
      />
      <ClientsList
        contact={contact}
        multiSelect={multiSelect}
        clients={clientList}
        selected={selectedClients}
        onChange={(e, val) => setSelectedClients(val)}
        setShowModal={onOpenModal}
        openAssignClientsModal={() => setShowAssignClientsModal(true)}
      />
      <ReassignShareModal
        isOpen={showModal}
        modalType={modalType}
        onReassignShare={onReassignShare}
        onClose={onCloseModal}
        clients={clientList.filter((c) => selectedClients.includes(c.Id))}
        loading={loading}
      />
      <AssignClientsModal
        contact={contact}
        isOpen={showAssignClientsModal}
        onClose={() => setShowAssignClientsModal(false)}
        fetchTeamMemberClients={fetchTeamMemberClients}
      />
      <LocalFooter
        multiSelect={multiSelect}
        count={selectedClients.length}
        setShowModal={onOpenModal}
      />
    </div>
  );
};

Clients.propTypes = {};

Clients.defaultProps = {};
