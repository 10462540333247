import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash-es';
import { formatNumberValue, getFontSize, modifyFontSizeHistory } from '../helper';

const initialFontSize = 14;
const minFontSize = 3;
const phoneDefaultFont = 12;
const minDateFontSize = 5;

export const useTextFitField = (props) => {
  const {
    inputElementRef,
    defaultValue,
    onBlur,
    persistOldValue,
    isPhone,
    isDateSigned,
    isNumberInput,
  } = props;

  const [currentFontSize, setFontSize] = useState(initialFontSize);
  const [fontSizeHistory, setFontSizeHistory] = useState({});

  const [fieldValue, setFieldValue] = useState(defaultValue);

  useEffect(() => {
    !fieldValue && setFieldValue(defaultValue);

    if (inputElementRef?.current) {
      let fontSize = getFontSize(inputElementRef.current, currentFontSize, false);
      let valueLength = inputElementRef.current.value?.length;

      if (fontSize !== currentFontSize) {
        const history = modifyFontSizeHistory(valueLength, fontSizeHistory, fontSize);

        setFontSizeHistory(history);
      }

      modifyFontSize(fontSize, valueLength);
    }
  }, [defaultValue]);

  const onChange = (event) => {
    event.target.value = isNumberInput ? formatNumberValue(event.target.value) : event.target.value;
    setFieldValue(event.target.value);

    const history = fontSizeHistory;

    const valueLength = event.target.value.length;

    let fontSize = getFontSize(event.target, currentFontSize);

    if (valueLength) {
      if (history[valueLength]) {
        fontSize = history[valueLength];
        delete history[valueLength];
        setFontSizeHistory(history);
      } else if (fontSize !== currentFontSize) {
        history[valueLength - 4] = currentFontSize;

        setFontSizeHistory(history);
      }
    } else if (!isEmpty(fontSizeHistory)) {
      setFontSizeHistory({});
      fontSize = initialFontSize;
    } else {
      fontSize = initialFontSize;
    }

    fontSize = fontSize < minFontSize ? minFontSize : fontSize;

    setFontSize(fontSize);
  };

  const onFieldBlur = (event) => {
    if (!event.target.value.trim().length && persistOldValue) {
      setFieldValue(defaultValue);

      setTimeout(() => {
        let fontSize = getFontSize(inputElementRef.current, currentFontSize, false);
        const valueLength = inputElementRef.current.value.length;

        const history = modifyFontSizeHistory(valueLength, fontSizeHistory, fontSize);
        fontSize = fontSize < minFontSize ? minFontSize : fontSize;
        setFontSize(fontSize);
        setFontSizeHistory(history);
      }, 200);
    } else {
      onBlur(event);
    }
  };

  const modifyFontSize = (fontSize, valueLength) => {
    if (isNumberInput && valueLength <= 3 && fontSize < 5) {
      fontSize = 11;
    } else {
      fontSize = fontSize < minFontSize ? minFontSize : fontSize;

      fontSize = isPhone && fontSize > phoneDefaultFont ? 14 : fontSize;
      fontSize = isDateSigned && fontSize < minDateFontSize ? 7 : fontSize;
    }
    setFontSize(fontSize);
  };

  return { currentFontSize, fieldValue, onFieldBlur, onChange };
};
