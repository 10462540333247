import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { TransactionUserRoleMap } from 'settings/constants/transaction';
import { getTransactionTemplateEffect, getTimelineTemplateEffect } from 'store/effects/template';
import {
  getTransactionTemplateDataSelector,
  getTransactionTemplateSelector,
  getTimelineTemplateDataSelector,
  getTimelineTemplateSelector,
} from 'store/selectors/template';
import { getUserDataSelector } from 'store/selectors/user';

import { Wrapper } from 'components';
import { Lock, ArrowLeft, TaskCard } from 'components/Icons';

import styles from './styles.module.scss';
import { orderBy } from 'lodash-es';

const TemplatePreview = (props) => {
  const {
    className,
    templateId,
    isTransactionTemplate,
    isTransactionModal,
    onBack,
    onSelect,
    isProject = false,
    getPreviewModalData,
  } = props;
  const dispatch = useDispatch();
  const template = useSelector(
    isTransactionTemplate ? getTransactionTemplateDataSelector : getTimelineTemplateDataSelector,
  );
  const user = useSelector(getUserDataSelector);

  const { isPending } = useSelector(
    isTransactionTemplate ? getTransactionTemplateSelector : getTimelineTemplateSelector,
  );
  const milestones = isTransactionTemplate
    ? template?.timelineTemplate?.milestones
    : template?.milestones;

  const getTemplateEffect = () =>
    isTransactionTemplate ? getTransactionTemplateEffect : getTimelineTemplateEffect;

  useEffect(() => {
    dispatch(getTemplateEffect()({ id: templateId }));
  }, []); //eslint-disable-line

  const updateInitialBasedOnType = (initial) => {
    if (isProject) {
      if (initial === 'CD') return 'KO';
      else if (initial === 'CL') return 'DL';
    }
    return initial;
  };
  if (template && template.id !== '' && getPreviewModalData) {
    getPreviewModalData(template);
  }

  return (
    <Wrapper
      className={classNames(
        styles.wrapper,
        { [styles.transactionWrapper]: isTransactionModal },
        className,
      )}
      isPending={isPending}
    >
      {isTransactionModal ? (
        <div className={styles.transactionHeader}>
          <div className={styles.thBack}>
            <button onClick={onBack}>
              <ArrowLeft className={styles.thbIcon} />
            </button>
          </div>
          <div className={styles.thContent}>
            <h4 className={styles.thTitle}>{template?.title}</h4>
            <p className={styles.thDesc}>{template?.description}</p>
          </div>
        </div>
      ) : null}

      {milestones?.length ? (
        <div className={styles.milestonesWrapper}>
          <h4 className={styles.wrapperTitle}>Timeline</h4>
          <div className={styles.milestones}>
            <div className={styles.pointsWrapper}>
              {milestones.map((data, i) => (
                <>
                  <div key={`${data?.Title}-${data?.DueDate}`} className={styles.point}>
                    <div className={styles.dot}>{i + 1}</div>
                    {i !== milestones.length - 1 && <div className={styles.line} />}
                  </div>
                  {data?.IsPrivate ? <Lock className={styles.private} /> : null}
                </>
              ))}
            </div>
            <div className={styles.milestonesInfoBlocksWrapper}>
              {milestones.map((data) => (
                <div
                  testid="milestone"
                  key={`${data?.Title}-${data?.DueDate}`}
                  className={styles.milestoneInfo}
                >
                  <div testid="milestone_name" className={styles.milestoneTitle}>
                    {data?.Title}
                  </div>
                  {typeof data?.DueDate === 'number' ? (
                    <div testid="milestone_date" className={styles.milestoneDeadline}>
                      {data?.Initial || 'T'}
                      {data?.Operator || '+'}
                      {data?.DueDate}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}

      {template?.taskTemplates?.length ? (
        <div className={styles.milestonesWrapper}>
          <h4 className={styles.wrapperTitle}>Tasks</h4>
          <div className={styles.tasks}>
            {orderBy(template.taskTemplates, ['id'], ['asc']).map((task) => (
              <div className={styles.task} key={task.id}>
                <div className={styles.iconWrap}>
                  <TaskCard className={styles.icon} />
                </div>
                <div className={styles.data}>
                  <p testid="task_name" className={styles.taskTitle}>
                    {task?.title}
                  </p>
                  <div className={styles.taskInfo}>
                    <div className={styles.assignee}>
                      <span>Assignee: </span>{' '}
                      {task?.assigneeRoles?.length > 0
                        ? task?.assigneeRoles
                            ?.map((item) => TransactionUserRoleMap[item] || item)
                            .join(', ')
                        : 'Unassigned'}
                    </div>
                    <div className={styles.dueDate}>
                      <span className={styles.dueDate}>Due Date:</span> {task.initial || 'T'}
                      {task.operator || '+'}
                      {task.dueDate}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {template?.formTemplates?.length ? (
        <div className={styles.milestonesWrapper}>
          <h4 className={styles.wrapperTitle}>Forms</h4>
          <div className={styles.tasks}>
            {orderBy(template.formTemplates, ['id'], ['asc']).map((form) => (
              <div className={styles.task} key={form.Id}>
                <div className={styles.iconWrap}>
                  <TaskCard className={styles.icon} />
                </div>
                <div className={styles.data}>
                  <p testid="formtask_name" className={styles.taskTitle}>
                    {form?.Name}
                  </p>
                  <div className={styles.taskInfo}>
                    <div className={styles.assignee}>
                      <span>Assignee: </span>{' '}
                      {form?.AssigneeRoles?.length > 0
                        ? form?.AssigneeRoles?.map(
                            (item) => TransactionUserRoleMap[item] || item,
                          ).join(', ')
                        : 'Unassigned'}
                    </div>
                    <div className={styles.dueDate}>
                      <span className={styles.dueDate}>Due Date:</span> {form.Initial || 'T'}
                      {form.Operator || '+'}
                      {form.DueDate}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {isTransactionModal ? (
        <div className={styles.transactionFooter}>
          <button className={styles.selectBtn} onClick={() => onSelect(templateId)}>
            Select
          </button>
        </div>
      ) : (
        ''
      )}
    </Wrapper>
  );
};

TemplatePreview.propTypes = {
  className: PropTypes.string,
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isTransactionTemplate: PropTypes.bool,
  isTransactionModal: PropTypes.bool,
  onBack: PropTypes.func,
  onSelect: PropTypes.func,
  getPreviewModalData: PropTypes.func,
};

TemplatePreview.defaultProps = {
  className: '',
  templateId: null,
  isTransactionTemplate: false,
  isTransactionModal: false,
  onBack: () => {},
  onSelect: () => {},
  getPreviewModalData: () => {},
};

export default TemplatePreview;
