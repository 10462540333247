import { useEffect, useRef } from 'react';

export const useScrollableRef = () => {
  const scrollableDivRef = useRef<any>(null);

  useEffect(() => {
    if (scrollableDivRef?.current) {
      scrollableDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  });

  return { scrollableDivRef };
};
