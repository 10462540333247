import React, { useState } from 'react';
import classNames from 'classnames';
import { AxiosError } from 'axios';

import { Button } from 'components-antd';
import { FormattedPhone, Input } from 'components';
import { verifyPhoneNumberWithCode } from 'api/user';
import { showErrorMessage } from 'helpers';

import styles from './styles.module.scss';

interface VerifyCodeStepProps {
  phoneNumber?: string;
  remainingSecondsToResendCode: number | null;
  onResendCodeClick: () => void;
  onEditPhoneNumberClick: () => void;
  onCodeVerfied: (codeVerifiedResponse: any) => Promise<void>;
}

const CODE_LENGTH = 4;

export const VerifyCodeStep: React.FC<VerifyCodeStepProps> = (props) => {
  const [enteredCode, setEnteredCode] = useState('');
  const [errorText, setErrorText] = useState<string | null>(null);

  const getResendText = () => {
    if (props.remainingSecondsToResendCode === null) {
      return null;
    }

    if (props.remainingSecondsToResendCode > 0) {
      return <span>Resend it in {props.remainingSecondsToResendCode} seconds </span>;
    }

    if (props.remainingSecondsToResendCode <= 0) {
      return (
        <span>
          <a
            onClick={() => {
              setErrorText(null);
              setEnteredCode('');
              props.onResendCodeClick();
            }}
            href="#"
          >
            Resend
          </a>
        </span>
      );
    }
  };

  function handleVerifyCodeError(err: any) {
    const axiosError = err as AxiosError;

    if (!axiosError.isAxiosError) {
      showErrorMessage('Unknown error occured while verifying the phone number');
    }

    const errorMessageMapping = {
      INVALID_CODE: 'Sorry, this passcode is incorrect. Please try again.',
      CODE_EXPIRED:
        'This passcode is expired. Please try requesting new code via the "Resend" button',
      MAX_ATTEMPTS_TO_VERIFY:
        'Maximum attempts reached. Please request a new code via the “Resend” button.',
    };

    const data = axiosError.response!.data;

    if (data.code in errorMessageMapping) {
      setErrorText(errorMessageMapping[data.code]);
    } else {
      showErrorMessage(data?.message || 'Unknown error occured while verifying passcode');
    }

    setEnteredCode('');
  }

  const handleContinue = async () => {
    try {
      setErrorText(null);
      const response = await verifyPhoneNumberWithCode({
        code: enteredCode,
      });
      props.onCodeVerfied(response);
    } catch (err) {
      handleVerifyCodeError(err);
    }
  };

  return (
    <div className={styles.layout}>
      <h2 className={styles.stepHeader}>Enter passcode</h2>
      <div className={styles.text}>
        We sent you a {CODE_LENGTH}-digit passcode to{' '}
        <FormattedPhone className={styles.inlinedPhoneNumber}>{props.phoneNumber}</FormattedPhone>
      </div>
      {props.remainingSecondsToResendCode !== null && (
        <div className={styles.text}>
          Didn’t receive it? {getResendText()}
          <span> or </span>
          <a onClick={props.onEditPhoneNumberClick} href="#">
            Edit Phone #
          </a>
        </div>
      )}
      <div className={styles.inputWrapper}>
        <Input
          inputClassName={classNames(styles.input, styles.codeInput)}
          placeholder={'0'.repeat(CODE_LENGTH)}
          variant={Input.LIGHT}
          value={enteredCode}
          className={styles.input}
          autofocus
          onChange={(e, value) => {
            setEnteredCode(value);
          }}
          maxLength={CODE_LENGTH}
        />
      </div>
      {errorText && (
        <div className={styles.errorTextWrapper}>
          <div className={styles.invalidCodeText}>{errorText}</div>
        </div>
      )}
      <div>
        <Button
          disabled={!enteredCode}
          onClick={handleContinue}
          variant={'secondary'}
          className={styles.continueButton}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
