import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isUrl from 'is-url';

import Icon from './Icons';
import styles from './styles.module.scss';

const AttachmentsPreview = (props) => {
  const { className, attachments, onRemove } = props;

  if (!attachments?.length) return null;

  const renderAttachment = (attachment, index) => {
    const [type] = attachment?.ContentType?.split('/') || [];
    const b64str = btoa(attachment?.Url);
    const src = `data:${attachment?.ContentType};base64,${b64str}`;

    if (type === 'image') {
      return (
        <div testid="image_preview" className={styles.image}>
          <Icon
            testid="close_icon"
            onClick={() => onRemove(index)}
            className={styles.closeIcon}
            variant={Icon.CLOSE}
          />
          <img src={isUrl(attachment?.Url) ? attachment?.Url : src} alt="" />
        </div>
      );
    }
    const maxFileNameLength = 20;
    const fileName = attachment?.Filename || '';
    const truncFileName = fileName.slice(-maxFileNameLength);
    return (
      <div testid="attachment" className={styles.file}>
        <Icon
          testid="close_icon"
          onClick={() => onRemove(index)}
          className={styles.closeIcon}
          variant={Icon.CLOSE}
        />
        <Icon className={styles.icon} variant={Icon.FILE} />
        <span testid="file_name">
          {fileName.length > maxFileNameLength ? `...${truncFileName}` : truncFileName}
        </span>
      </div>
    );
  };

  return (
    <div className={classNames(styles.attachmentsPreview, className)}>
      {attachments.map((attachment, index) => (
        <div className={styles.attachment} key={attachment.Url || index}>
          {renderAttachment(attachment, index)}
        </div>
      ))}
    </div>
  );
};

AttachmentsPreview.propTypes = {
  className: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  onRemove: PropTypes.func,
};

AttachmentsPreview.defaultProps = {
  className: '',
  attachments: [],
  onRemove: () => {},
};

export default AttachmentsPreview;
