import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { priceConverter } from 'helpers';

import { SymphonyStats } from '../HeaderStats';
import { setTransactionsIsArchiveEffect } from 'store/effects/transactions/all';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';

interface StatCardInterface {
  label: string;
  variant: string;
  data: { count: number; volume: number } | undefined;
}

export const StatCard = ({ label, variant, data }: StatCardInterface) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const redirectTo = (label) => {
    if (label === SymphonyStats.ActiveClients) return;
    dispatch(setTransactionsIsArchiveEffect(SymphonyStats.Closed === label));
    if (label === SymphonyStats.UnderContract) {
      history.push(`${routes.transactions}?statuses=Under contract`);
    } else if (label === SymphonyStats.Listed) {
      history.push(
        `${routes.transactions}?statuses=ComingSoon,ActiveListing,OnHold,OfficeExclusive,PreListing`,
      );
    } else {
      history.push(`${routes.transactions}?all=true`);
    }
  };

  return (
    <div className={classNames(styles.statCard, styles[variant])}>
      <p className={styles.cardLabel}>{label}</p>
      <p className={styles.cardStat} onClick={() => redirectTo(label)}>
        {label === SymphonyStats.ActiveClients ? data?.count : priceConverter(data?.volume)}
        {label != SymphonyStats.ActiveClients && <small>({data?.count})</small>}
      </p>
    </div>
  );
};
