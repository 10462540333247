import { FormikProps, useFormikContext } from 'formik';

import { Row, Col } from 'components-antd/Grid';
import { DatePicker, Input } from 'components';

import styles from './styles.module.scss';
import { FC } from 'react';

interface Props {
  isSubmitted: boolean;
  index?: number;
  isDocumentArray: boolean;
}
export const AddManualEntrySection: FC<Props> = ({ isSubmitted, index, isDocumentArray }) => {
  const { values, errors, setFieldValue }: FormikProps<any> = useFormikContext();
  const formErrors = errors?.['preApprovalsDocuments']?.[index ?? 0];
  const formValues = values?.['preApprovalsDocuments']?.[index ?? 0];
  const nameSpace = `preApprovalsDocuments.${index}.`;
  return (
    <>
      <Row gutter={24}>
        <Col xs={12} sm={12}>
          <Input
            label="Amount"
            isNumberFormat
            name={nameSpace + 'Amount'}
            prefix="$"
            type="number"
            onChange={(e) => setFieldValue(nameSpace + 'Amount', e.target.value)}
            value={formValues.Amount}
            placeholder="$100,000"
            className={styles.inputWrapper}
            error={isSubmitted ? (formErrors?.Amount as string) : ''}
          />
        </Col>
        <Col xs={12} sm={12}>
          <Input
            label="Approved by"
            name={nameSpace + 'ApprovedBy'}
            onChange={(e) => setFieldValue(nameSpace + 'ApprovedBy', e.target.value)}
            value={formValues.ApprovedBy}
            placeholder="Mountain Mortgage"
            className={styles.inputWrapper}
            error={isSubmitted ? (formErrors?.ApprovedBy as string) : ''}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={12} sm={12}>
          <DatePicker
            name={nameSpace + 'ApprovalDate'}
            label="Approval Date"
            options={{ enableTime: false }}
            onChange={(e) => setFieldValue(nameSpace + 'ApprovalDate', e?.target?.value[0])}
            value={formValues.ApprovalDate}
            error={isSubmitted ? (formErrors?.ApprovalDate as string) : ''}
          />
        </Col>
        <Col xs={12} sm={12}>
          <DatePicker
            name={nameSpace + 'ExpirationDate'}
            label="Expiration Date"
            options={{ enableTime: false }}
            onChange={(e) => setFieldValue(nameSpace + 'ExpirationDate', e?.target?.value[0])}
            value={formValues.ExpirationDate}
            error={isSubmitted ? (formErrors?.ExpirationDate as string) : ''}
          />
        </Col>
      </Row>
    </>
  );
};
