import { useHistory } from 'react-router-dom';

import { Menu, SubMenu, Paragraph } from 'components-antd';
import CreateNew from 'pages/FormBuilder/Icons/CreateNew';
import NewFolder from 'pages/FormBuilder/Icons/NewFolder';
import QuoteForm from 'pages/FormBuilder/Icons/QuoteForm';
import SmartForm from 'pages/FormBuilder/Icons/SmartForm';
import Dropdown from 'pages/FormBuilder/Icons/Dropdown';
import NewForm from 'pages/FormBuilder/Icons/NewForm';
import { FormFolderModalState, FolderType } from 'types';
import { FORM_TYPE } from 'app-constants';
import { link } from 'settings/navigation/link';

import styles from '../../styles.module.scss';

interface CreateNewMenuProps {
  folders?: FolderType[];
  setFormFolderModal: (formFolderModal: FormFolderModalState) => void;
  setFormType: (formType: typeof FORM_TYPE[keyof typeof FORM_TYPE]) => void;
}

export const CreateNewMenu = ({ folders, setFormFolderModal, setFormType }: CreateNewMenuProps) => {
  const history = useHistory();

  const selectOptions = folders?.map((folder) => ({
    label: folder.Name,
    value: folder.Id,
  }));

  return (
    <div className={styles.createNewMenuItem}>
      <Menu mode="vertical">
        <SubMenu
          popupClassName={styles.menuPopup}
          key="createNew"
          popupOffset={[-220, 83]}
          title={
            <div data-testid="create-new-options" className={styles.menuItemCollection}>
              <CreateNew className={styles.menuItemIcon} />
              <Paragraph className={styles.menuItemContent}>Create New</Paragraph>
              <Dropdown stroke="#303030" className={styles.createNewMenuIcon} />
            </div>
          }
        >
          <Menu.Item
            key="newFolder"
            data-testid="new-folder-menu-item"
            className={styles.subMenuItem}
            onClick={() => {
              setFormFolderModal({
                open: true,
                title: 'Create New Folder',
                okText: 'Create',
                type: 'newFolder',
              });
            }}
          >
            <div className={styles.menuItemCollection}>
              <NewFolder className={styles.menuItemIcon} />
              <Paragraph className={styles.menuItemContent}>New Folder</Paragraph>
            </div>
          </Menu.Item>

          <SubMenu
            key="newForm"
            popupClassName={styles.menuPopup}
            title={
              <div data-testid="new-form-menu-item" className={styles.menuItemCollection}>
                <NewForm className={styles.menuItemIcon} />
                <Paragraph className={styles.menuItemContent}>New Form</Paragraph>
              </div>
            }
            className={styles.subMenuTitle}
          >
            <Menu.Item
              key="quoteForm"
              data-testid="quote-form-menu-item"
              className={styles.nestedSubMenuItem}
              onClick={() => {
                setFormType(FORM_TYPE.Quote);
                setFormFolderModal({
                  open: true,
                  title: 'Create Form',
                  okText: 'Create',
                  selectOptions,
                  type: 'newForm',
                });
              }}
            >
              <div className={styles.menuItemCollection}>
                <QuoteForm className={styles.menuItemIcon} />
                <Paragraph className={styles.menuItemContent}>Quote Form</Paragraph>
              </div>
            </Menu.Item>

            <Menu.Item
              key="smartForm"
              data-testid="smart-form-menu-item"
              className={styles.nestedSubMenuItem}
              onClick={() => {
                history.push(link.toDashboardServiceDirectoryFormsSmartForm('new'));
              }}
            >
              <div className={styles.menuItemCollection}>
                <SmartForm className={styles.menuItemIcon} />
                <Paragraph className={styles.menuItemContent}>Smart Form</Paragraph>
              </div>
            </Menu.Item>
          </SubMenu>
        </SubMenu>
      </Menu>
    </div>
  );
};
