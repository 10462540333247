import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { getScheduledTours } from 'store/selectors/tour';
import { TourStatus } from 'app-constants/enums/tours';
import { useMemo, useState } from 'react';
import { Cross } from 'components/Icons';
import moment from 'moment';
import { TimelineItem } from '../TimelineItem';
import { Button } from 'components-antd';
import classNames from 'classnames';
import { GroupedTimeline } from '../GroupedTimeline';
import { Tour } from 'types/tours';
import { CancelModal } from '../CancelModal';
import { LocalHeader } from '../LocalHeader';
import { orderBy } from 'lodash-es';

export const UpcomingAndRequested = ({ isArchive, setIsArchive }) => {
  const { tours } = useSelector(getScheduledTours);
  const [selectedTour, setSelectedTour] = useState<Tour>();
  const [showCancelModal, setShowCancelModal] = useState(false);

  const onTourCancel = (tour: Tour) => {
    setShowCancelModal(true);
    setSelectedTour(tour);
  };

  const upcomingTours = useMemo(
    () =>
      orderBy(
        tours.filter(
          (tour) => tour.Status === TourStatus.Approved && moment(tour.ScheduleDateTime) > moment(),
        ),
        [(obj) => new Date(obj.ScheduleDateTime || '')],
        ['asc'],
      ),
    [tours],
  );
  const requestedTours = useMemo(
    () => tours.filter((tour) => tour.Status === TourStatus.Requested),
    [tours],
  );
  return (
    <>
      {!!upcomingTours.length && (
        <>
          <div className={styles.headContainer}>
            <h3 className={styles.title}>
              Upcoming <span className={styles.count}>({upcomingTours.length})</span>
            </h3>
          </div>

          <div className={styles.requestsWrap}>
            <GroupedTimeline tours={upcomingTours} onTourCancel={onTourCancel}></GroupedTimeline>
          </div>
        </>
      )}
      {!!requestedTours.length && (
        <>
          <h3 className={styles.title}>
            Requests <span className={styles.count}>({requestedTours.length})</span>
          </h3>
          <div className={styles.requestsWrap}>
            {requestedTours.map((tour, index) => (
              <TimelineItem tour={tour} status={TourStatus.Requested} key={index}>
                <Button
                  className={styles.buttonReject}
                  variant="red-outlined"
                  onClick={() => {
                    setShowCancelModal(true);
                    setSelectedTour(tour);
                  }}
                >
                  <Cross className={styles.icon} color="#EC455E" />
                </Button>
              </TimelineItem>
            ))}
          </div>
        </>
      )}

      {!upcomingTours.length && !requestedTours.length && (
        <p className={styles.noTours}>You have no upcoming tours.</p>
      )}

      {selectedTour && showCancelModal && (
        <CancelModal
          showModal={showCancelModal}
          tour={selectedTour}
          onCancel={() => {
            setShowCancelModal(false);
          }}
          onSubmit={() => {
            setShowCancelModal(false);
          }}
        ></CancelModal>
      )}
    </>
  );
};
