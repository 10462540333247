import moment from 'moment';

import { Icons } from '../../../Icons';

import styles from '../styles.module.scss';
import { IconText } from '../IconText';

export const EditOfferDeadline = ({ data, onEdit }) => {
  const getInfo = () => {
    const { DeadlineDate: deadline, DisplayDeadlineOnPortal } = data;

    if (DisplayDeadlineOnPortal) {
      return `${moment(deadline).format('MM/DD/YYYY')} at ${moment(deadline).format('hh:mm A')}`;
    } else {
      return 'No';
    }
  };

  return (
    <div className={styles.editForm}>
      <IconText
        variant={'deadline-bg'}
        infoText={getInfo()}
        text={'Is there a deadline to submit offers?'}
        className={styles.editFormInfo}
      />
      <div className={styles.editIcon} onClick={onEdit}>
        <Icons variant={'edit'} />
      </div>
    </div>
  );
};
