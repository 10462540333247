import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from './Icon';
import styles from './styles.module.scss';

const ApiRequestError = ({ title, onRetry, className }) => (
  <div className={classNames(styles.wrapper, className)}>
    <div>{title}</div>
    {!!onRetry && (
      <button className={styles.retryBtn} onClick={onRetry}>
        <Icon className={styles.retryBtnIcon} />
        <span>Retry</span>
      </button>
    )}
  </div>
);

ApiRequestError.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onRetry: PropTypes.func,
};

ApiRequestError.defaultProps = {
  onRetry: null,
  className: null,
};

export default ApiRequestError;
