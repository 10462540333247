import { Plus, Minus } from 'components/Icons';

import styles from './styles.module.scss';

export const getCollapseIcon = (isActive = false, setExpanded: (expanded: boolean) => void) => {
  setExpanded(isActive);
  return isActive ? (
    <Minus className={styles.btnWrapper} />
  ) : (
    <Plus className={styles.btnWrapper} />
  );
};
