import PropTypes from 'prop-types';

import { Modal } from 'components-antd';

import styles from './styles.module.scss';
import { MemberForm } from './MemberForm';

export const MemberModal = (props) => {
  const {
    isOpen,
    onCloseModal,
    agentId,
    teamId,
    firstName,
    lastName,
    email,
    phone,
    title,
    role,
    getTeamMembers,
  } = props;

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onCloseModal}
      maskClosable={false}
      destroyOnClose
      className={styles.memberModal}
    >
      <span className={styles.memberTitle}>Edit Member</span>
      <MemberForm
        agentId={agentId}
        teamId={teamId}
        firstName={firstName}
        lastName={lastName}
        email={email}
        phone={phone}
        title={title}
        role={role}
        getTeamMembers={getTeamMembers}
        onClose={onCloseModal}
      />
    </Modal>
  );
};

MemberModal.propTypes = {
  onCloseModal: PropTypes.func,
  isOpen: PropTypes.bool,
  agentId: PropTypes.number,
  teamId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  title: PropTypes.string,
  role: PropTypes.string,
  getTeamMembers: PropTypes.func,
};

MemberModal.defaultProps = {
  onCloseModal: () => {},
  isOpen: false,
  agentId: 0,
  teamId: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  title: '',
  role: '',
  getTeamMembers: (a) => {},
};
