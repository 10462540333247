import classNames from 'classnames';
import { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DetailCard } from './DetailCard';
import { TransactionPropertyType, YearBuilt, FinancingType } from './TransactionFields';
import { TransactionDetail, TransactionDetails } from 'types';
import { getTransactionDetailsEffect } from 'store/effects/transactions';
import {
  getTransactionAccessSelector,
  getTransactionDetailsSelector,
} from 'store/selectors/transaction';

import styles from './styles.module.scss';

interface DetailsViewProps {
  editDetailId?: number;
  setEditDetailId: (editDetailId?: number) => void;
  transactionId?: number;
  isProject?: boolean;
}

export const DetailsView = ({
  editDetailId,
  setEditDetailId,
  transactionId,
  isProject,
}: DetailsViewProps) => {
  const dispatch = useDispatch();
  const params: { id?: string } = useParams();
  const details: TransactionDetails = useSelector(getTransactionDetailsSelector);

  useEffect(() => {
    const id = params?.id ? params?.id : transactionId;
    dispatch(getTransactionDetailsEffect({ id }));
  }, []);
  // Props that disables other fields when one is opened for modification
  const isDisableField = (editKey) => editDetailId !== undefined && editDetailId !== editKey;
  const editProps = { setEditDetailId, isDisableField };

  return (
    <div className={styles.detailsContainer}>
      <div className={styles.detailsContent}>
        <div className={styles.contentWrapper}>
          <div className={styles.contentSpacing}>
            {/* Transaction Details Fields */}
            {!isProject && (
              <>
                <TransactionPropertyType {...editProps} />
                <FinancingType {...editProps} />
                <YearBuilt {...editProps} />
              </>
            )}

            {/* Template Details Fields */}
            {details.length
              ? details.map((field: TransactionDetail, idx: number) => (
                  <div
                    key={idx}
                    className={classNames({
                      [styles.disabledSection]: editDetailId && editDetailId !== field.Id,
                    })}
                  >
                    <DetailCard
                      field={field}
                      editMode={editDetailId === field.Id}
                      setEditDetailId={setEditDetailId}
                      transactionId={transactionId}
                    />
                    <br />
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};
