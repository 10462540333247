import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Applicants } from 'components';

import styles from './styles.module.scss';

const ApplicantsComponent = (props) => {
  const { applicantsWrapperClassName, className, propertyInfo } = props;

  return (
    <Applicants
      propertyInfo={propertyInfo}
      className={classNames(styles.applicantsWrapper, applicantsWrapperClassName)}
      applicantsClassName={classNames(styles.applicants, className)}
    />
  );
};

ApplicantsComponent.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    MatchedClientsCount: PropTypes.number,
  }).isRequired,
};

ApplicantsComponent.defaultProps = {
  className: '',
};

export default ApplicantsComponent;
