import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { geAggregatedPageTypeSelector } from 'store/selectors/taskAggregate';
import { AggregatedPageTypes } from 'types/aggregatedPageTypes';

const useIsTranOrProFilterVisible = () => {
  const [isProFilterVisible, setIsProFilterVisible] = useState(false);
  const [isTranFilterVisible, setIsTranFilterVisible] = useState(false);
  const aggregatedPageType = useSelector(geAggregatedPageTypeSelector);

  useEffect(() => {
    if (aggregatedPageType === AggregatedPageTypes.Transaction) {
      setIsProFilterVisible(false);
      setIsTranFilterVisible(true);
    } else if (aggregatedPageType === AggregatedPageTypes.Project) {
      setIsProFilterVisible(true);
      setIsTranFilterVisible(false);
    } else {
      setIsProFilterVisible(true);
      setIsTranFilterVisible(true);
    }
  }, [aggregatedPageType]);

  return [isProFilterVisible, isTranFilterVisible];
};

export default useIsTranOrProFilterVisible;
