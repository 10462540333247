import styles from './styles.module.scss';
import { FC } from 'react';
import { ICabinetComponentProps } from '../types';
import { map } from 'lodash-es';
import { KITCHEN_CABINET_TYPES } from '../../constants';
import classNames from 'classnames';

const CabinetComponent: FC<ICabinetComponentProps> = ({ color, value, onChange }) => {
  const handleChange = (id) => {
    const newValue = [...value];
    const index = newValue.findIndex((val) => val === id);
    if (index === -1) {
      newValue.push(id);
    } else {
      newValue.splice(index, 1);
    }
    onChange(newValue);
  };

  return (
    <div className={styles.itemsWrapper}>
      <div className={styles.item}>
        <p>Cabinet Color</p>
        <div className={styles.chipsContainer}>
          {map(KITCHEN_CABINET_TYPES, ({ label, id }) => (
            <div
              className={classNames(styles.chip, { [styles[color]]: value.includes(id) })}
              onClick={() => {
                handleChange(id);
              }}
            >
              {label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CabinetComponent;
