import React from 'react';
import PropTypes from 'prop-types';

const TaskList = ({ className }) => (
  <div className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11108_162660)">
        <path
          d="M3.49902 5.5L4.99902 7L7.49902 4.5"
          stroke="#AAABAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.49902 11.5L4.99902 13L7.49902 10.5"
          stroke="#AAABAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.49902 17.5L4.99902 19L7.49902 16.5"
          stroke="#AAABAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.999 6H19.999"
          stroke="#AAABAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.999 12H19.999"
          stroke="#AAABAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.999 18H19.999"
          stroke="#AAABAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11108_162660">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

TaskList.propTypes = {
  className: PropTypes.string,
};

TaskList.defaultProps = {
  className: '',
};

export default TaskList;
