import {
  ActiveQuestions,
  DisabledFields,
  OtherSignatoryQuestions,
  StrikeThroughFields,
} from './layers';

interface SignaturePageProps {
  pageIndex: number;
  documentIndex?: number;
}

export const SignaturePage = ({ pageIndex, documentIndex = -1 }: SignaturePageProps) => {
  return (
    <>
      <DisabledFields pageIndex={pageIndex} />
      <ActiveQuestions pageIndex={pageIndex} documentIndex={documentIndex} />
      <OtherSignatoryQuestions pageIndex={pageIndex} documentIndex={documentIndex} />
      <StrikeThroughFields pageNumber={pageIndex} documentIndex={documentIndex} />
    </>
  );
};
