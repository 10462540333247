import React, { useState, useEffect, useLayoutEffect } from 'react';
import { PageWrapper } from './components';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getQuotesSelector } from 'store/selectors/quotes';
import {
  requestGetQuotesEffect,
  setQuotesArchiveEffect,
  setQuotesGroupByEffect,
} from 'store/effects/quotes';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { PageHeader, Content, Actions } from './components';

import styles from './styles.module.scss';
import { groupQuotesBy } from 'settings/constants/quotes';

const Quotes = () => {
  const { isThirdParty } = useSelector(getUserRolesMapSelector);
  const { isIdle } = useSelector(getQuotesSelector);

  const dispatch = useDispatch();
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    if (isIdle) {
      dispatch(requestGetQuotesEffect());
    } else {
      dispatch(requestGetQuotesEffect({}, { silent: true }));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    function handleWindowSizeChange() {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    if (document.readyState === 'complete') {
      handleWindowSizeChange();
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      dispatch(setQuotesArchiveEffect(false));
      dispatch(setQuotesGroupByEffect(groupQuotesBy.STATUS));
      window.addEventListener('load', handleWindowSizeChange);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <PageWrapper
      className={styles.wrapper}
      containerClassName={styles.container}
      contentContainerClassName={styles.contentContainer}
      subHeader={<PageHeader className={styles.quotesTitle} isThirdParty={isThirdParty} />}
    >
      {/* <LocalHeader /> */}
      <div testid="my_quotes" className={styles.contentWrapper}>
        <Content />
      </div>
    </PageWrapper>
  );
};

export default Quotes;
