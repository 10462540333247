import React, { useEffect, useState } from 'react';

import { InputLabel } from 'components';
import { Option } from 'components-antd';
import { AutoComplete } from './AutoComplete';
import { caseInsensitiveFilter, convertNameToAvatarPlaceholder } from 'helpers';

import styles from './styles.module.scss';

interface NameAutocompleteProps {
  label?: string;
  allNames: { label: string | React.ReactNode; value: any; key?: string }[];
  value?: string;
  onChange?: (...args) => void;
  onSelect?: (...args) => void;
  disabled?: boolean;
  placeholder?: string;
}

export const NameAutocomplete = ({
  label,
  allNames = [],
  value,
  onChange,
  onSelect,
  disabled = false,
  placeholder,
}: NameAutocompleteProps) => {
  const [isSearch, setIsSearch] = useState(false);
  const [name, setName] = useState(value || '');

  useEffect(() => {
    if (value) {
      setName(value);
    }
  }, [value]);

  const getSelectedName = (value?: string) => allNames.find((item) => item.value === value);

  const handleSelect = (value) => {
    const selectedName = getSelectedName(value);
    onSelect && onSelect(selectedName?.label);
    setIsSearch(false);
  };

  const handleChange = (value) => {
    setName(value);
    onChange && onChange(value);
  };

  const handleBlur = () => {
    setIsSearch(false);
  };

  return (
    <div className={styles.autoCompleteDropdown}>
      <div className={styles.fieldWrapper}>
        {label && <InputLabel label={label} className={styles.label} />}
      </div>
      <AutoComplete
        disabled={disabled}
        value={name}
        onChange={handleChange}
        backfill
        open={isSearch}
        popupClassName={styles.autoCompleteDropdown}
        placeholder={placeholder}
        filterOption={(inputValue, option) =>
          caseInsensitiveFilter(inputValue, option?.label as string)
        }
        onSearch={(val) => setIsSearch(!!val.trim())}
        onSelect={handleSelect}
        onBlur={handleBlur}
        className={styles.nameAutoComplete}
      >
        {allNames?.map((item, idx) => (
          <Option key={idx} label={item.label} value={item.value}>
            <div className={styles.autoCompleteOption}>{item.label}</div>
          </Option>
        ))}
      </AutoComplete>
    </div>
  );
};
