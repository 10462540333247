import { showPaywallToast } from '../PaywallToast/paywallToast';
import { showFailedPaymentToast } from '../FailedPaymentToast/failedPaymentToast';

import { useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentPlanSelector } from 'store/selectors/subscription';
import { getIsAuthSelector } from 'store/selectors/app';
import ReactDOM from 'react-dom';
import { setPaymentUpdateModalAction } from 'store/actions/subscription';

export const usePaywallToast = () => {
  const currentActivePlan = useSelector(getCurrentPlanSelector);
  const isAuth = useSelector(getIsAuthSelector);
  const dispatch = useDispatch();

  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const openPaymentModal = useCallback(() => {
    dispatch(setPaymentUpdateModalAction({ isModalOpened: true }));
  }, []);

  const removeGlobalToasts = (ids: string[] = []) => {
    ids.forEach((id) => {
      const el = document.getElementById(id);
      if (el) {
        ReactDOM.unmountComponentAtNode(el);
        document.body.removeChild(el);
      }
    });
  };

  useEffect(() => {
    if (!isAuth) {
      [`paywall-toast-success`, `paywall-toast-fail`, `payment-failed-toast`].forEach((id) => {
        const el = document.getElementById(id);
        if (el) {
          ReactDOM.unmountComponentAtNode(el);
          document.body.removeChild(el);
        }
      });
    }
  }, [isAuth]);

  useEffect(() => {
    if (currentActivePlan?.PaymentStatus === 'failed') {
      showFailedPaymentToast({ onCTAClick: openPaymentModal });
    } else if (currentActivePlan?.PaymentStatus === 'paid') {
      removeGlobalToasts(['payment-failed-toast']);
    }
  }, [currentActivePlan]);

  useEffect(() => {
    if (query.get('showPaywallSuccess') === 'true') {
      showPaywallToast(true);
      const newUrl =
        window.location.pathname +
        window.location.search.replace(/(\?|&)showPaywallSuccess=true/, '');
      history.replace(newUrl);
    }

    if (query.get('showPaywallFail') === 'true') {
      showPaywallToast(false);
      const newUrl =
        window.location.pathname + window.location.search.replace(/(\?|&)showPaywallFail=true/, '');
      history.replace(newUrl);
    }
  }, [query, history]);
};

export default usePaywallToast;
