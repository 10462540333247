import * as Yup from 'yup';

// eslint-disable-next-line
const urlRegex =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const ValidationSchema = (country) =>
  Yup.object().shape({
    Question1: Yup.string().max(300).trim().required('Required'),
    Question2: Yup.string().trim().required('Required'),
    Question3: Yup.string().max(300).trim().required('Required'),
    Question4: Yup.string().max(300).trim().required('Required'),
    Question5: Yup.string().trim().required('Required'),
    Question6: Yup.string().max(300).trim().required('Required'),
  });
