import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { link } from 'settings/navigation/link';
import { LocationService } from 'services';
import BaseNotification from '../../BaseNotification';

import styles from './styles.module.scss';

const locationSrv = new LocationService();

const NewMessage = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { SenderFirstName, SenderLastName, Text, ThreadType } = Payload;
  const { TopicName, ThreadId, SenderAvatarUrl, EntityId, TransactionId } = Payload;

  locationSrv.setLocation(location);

  const Name = `${SenderFirstName} ${SenderLastName}`;

  const getSubtitle = () => {
    if (ThreadType === SOCKET_THREAD_TYPES.CHAT) {
      return Text;
    }

    if (ThreadType === SOCKET_THREAD_TYPES.LISTING || ThreadType === SOCKET_THREAD_TYPES.QUOTE) {
      return TopicName;
    }
  };

  const onClickHandler = () => {
    dispatch(openNotificationsDrawerEffect({ open: false }));
    let linkTo = '';

    const query = locationSrv.getQuery();

    if (ThreadType === SOCKET_THREAD_TYPES.LISTING) {
      linkTo = `${link.toFeedListingDetails(EntityId)}?threadId=${ThreadId}`;
    }

    if (ThreadType === SOCKET_THREAD_TYPES.CHAT) {
      linkTo = locationSrv.setQuery({ ...query, threadId: ThreadId });
    }

    if (ThreadType === SOCKET_THREAD_TYPES.QUOTE) {
      linkTo = `${location.pathname}?threadId=${ThreadId}`;
    }

    if (ThreadType === SOCKET_THREAD_TYPES.TRANSACTION) {
      linkTo = `${link.toTransactionMessages(EntityId)}?transactionThreadId=${ThreadId}`;
    }

    if (ThreadType === SOCKET_THREAD_TYPES.TRANSACTION_TASK && TransactionId) {
      linkTo = `${link.toTransactionTasks(TransactionId)}?taskId=${EntityId}`;
    }

    history.replace(linkTo);
  };

  return (
    <div className={classNames(styles.notificationType, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        subtitle={getSubtitle()}
        date={CreatedDate}
        onClick={onClickHandler}
        groupType={groupType}
        avatarUrl={SenderAvatarUrl}
        avatarPlaceholder={convertNameToAvatarPlaceholder(Name)}
        severityLevel={SeverityLevel}
      />
    </div>
  );
};

NewMessage.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    SenderFirstName: PropTypes.string,
    SenderLastName: PropTypes.string,
    Text: PropTypes.string,
    ThreadType: PropTypes.string,
    TopicName: PropTypes.string,
    ThreadId: PropTypes.number,
    TransactionId: PropTypes.number,
    SenderAvatarUrl: PropTypes.string,
    EntityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

NewMessage.defaultProps = {
  className: '',
};

export default NewMessage;
