import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Title = ({ className, children }) => (
  <h2 className={classNames(styles.title, className)}>{children}</h2>
);

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
};

Title.defaultProps = {
  className: '',
};

export default Title;
