import { showErrorMessage } from 'helpers';

export const validateMembership = (record, userId) => {
  if (
    !record.data.IsManagedMemberTransaction &&
    record.data.IsTeamTransaction &&
    !record.data.Participants.some((participant) => participant.Id === userId)
  ) {
    showErrorMessage('Inaccessible: You are not a member');
    return false;
  }
  return true;
};

export const getParticipantsData = (document) => {
  const reviewers = document?.reviewers || [];

  const ids = reviewers?.filter((item) => typeof item === 'number');
  const emails = reviewers?.filter((item) => typeof item === 'string');
  const access =
    reviewers?.length === 0
      ? [{ accessToEveryone: true }]
      : reviewers?.filter((item) => typeof item === 'object');

  return {
    UsersWithAccess: ids || [],
    UsersEmailWithAccess: emails || [],
    AccessToEveryone: access[0].accessToEveryone,
  };
};
