import { apiServer } from 'settings/web-services/api';
import { AreaTypeRefined } from 'store/reducers/adminPanel/areaClassifier/selectedAreaGroup';

export function getAreaGroups(query: GetAreaGroupsQuery) {
  return apiServer.get('/geo/areaClassifier/manualAreaGroups', {
    params: query,
  });
}

export function getAreaGroupDetails(areaGroupId: number) {
  return apiServer.get(`/geo/areaClassifier/manualAreaGroups/${areaGroupId}`);
}

export function createNewAreaGroup(payload: CreateNewAreaGroupRequest) {
  return apiServer.post('/geo/areaClassifier/manualAreaGroups', payload);
}

export function updateAreaGroup(cfg: { areaGroupId: number; payload: UpdateAreaGroupRequest }) {
  return apiServer.patch(`/geo/areaClassifier/manualAreaGroups/${cfg.areaGroupId}`, cfg.payload);
}

export function deleteAreaGroup(areaGroupId: number) {
  return apiServer.delete(`/geo/areaClassifier/manualAreaGroups/${areaGroupId}`);
}

export interface GetAreaGroupsQuery {
  searchTerm?: string;
  pageNum?: number;
  pageSize?: number;
}

export interface CreateNewAreaGroupRequest {
  Name: string;
  Areas: Array<{
    Type: AreaTypeRefined;
    ShapeGeoJson: any;
  }>;
}

export type UpdateAreaGroupRequest = CreateNewAreaGroupRequest;
