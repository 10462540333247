import * as Yup from 'yup';
import 'yup-phone';

export const ValidationSchemaClient = () =>
  Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phone: Yup.string().length(10).required('Required'),
  });

export const ValidationSchemaAgentThirdParty = () =>
  Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phone: Yup.string().length(10).required('Required'),
    bio: Yup.string().required('Required'),
  });

export const ValidationSchemaThirdPartyNotPartner = () =>
  Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phone: Yup.string().length(10).required('Required'),
    bio: Yup.string().required('Required'),
  });

const phoneRegex = /^(?:\+1\s?)?(\(?[2-9]\d{2}\)?[\s.-]?)?[2-9]\d{2}[\s.-]?\d{4}$/;

export const ValidationSchemaThirdPartyPartner = () =>
  Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phone: Yup.string().length(10).matches(phoneRegex, 'Phone number is not valid').nullable(true),
    email: Yup.string().email().required('Required'),
  });
