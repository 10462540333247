import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  ScrollDrawerWrapper,
  DrawerPaddingWrapper,
  Footer,
} from 'pages/Properties/Feed/components';
import { useDispatch, useSelector } from 'react-redux';
import { getBuyerCommutesSelector } from 'store/selectors/user';
import { updateBuyerCommutesEffect, resetPropertiesWholeTabsEffect } from 'store/effects';

import { Button } from 'components';
import { cloneDeep } from 'lodash-es';
import useIsDirty from 'hooks/use-is-dirty';
import Title from './Title';
import Commute from './Commute';
import AddCommute from './AddCommute';

import styles from './styles.module.scss';

const Commutes = (props) => {
  const { className } = props;

  const dispatch = useDispatch();
  const commutes = useSelector(getBuyerCommutesSelector);
  const [pending, setPending] = useState(false);
  const [commutesList, setCommutesList] = useState(commutes);
  const [isDirty, setIsDirty] = useIsDirty(commutesList, false);

  useEffect(() => setCommutesList(commutes), [commutes]);

  const onSubmit = () => {
    setPending(true);
    dispatch(
      updateBuyerCommutesEffect(commutesList, {}, (err) => {
        setPending(false);
        if (!err) {
          dispatch(resetPropertiesWholeTabsEffect());
          setIsDirty(false);
        }
      }),
    );
  };

  const onDeleteCommute = (index) => {
    const clonedCommutes = cloneDeep(commutesList);
    clonedCommutes.splice(index, 1);
    setCommutesList(clonedCommutes);
  };

  return (
    <div>
      <ScrollDrawerWrapper className={styles.scrollWrapper}>
        <DrawerPaddingWrapper className={classNames(className)}>
          <div>
            {commutesList.map((commute, index) => {
              const { Id, Name, TransportationModePreference } = commute;
              const { MaxCommuteTimeInMinutes, DestinationAddress } = commute;

              return (
                <Commute
                  key={Id || index}
                  index={index}
                  name={Name}
                  type={TransportationModePreference?.[0]}
                  commuteTime={MaxCommuteTimeInMinutes}
                  address={DestinationAddress?.PlaceName}
                  onDelete={onDeleteCommute}
                />
              );
            })}
          </div>
          <AddCommute />
        </DrawerPaddingWrapper>
      </ScrollDrawerWrapper>
      <Footer>
        <Button
          onClick={onSubmit}
          className={styles.submitButton}
          title="Done"
          isPending={pending}
          testid="done"
          disabled={!isDirty}
        />
      </Footer>
    </div>
  );
};

Commutes.cases = [
  { id: 1, name: 'Office', type: 'car', commuteTime: 15, address: '2128 West Broadway' },
  { id: 2, name: 'Daycare', type: 'transit', commuteTime: 30, address: '2128 West Broadway' },
  { id: 3, name: 'Grocery', type: 'walk', commuteTime: 15, address: '2128 West Broadway' },
];

Commutes.propTypes = {
  className: PropTypes.string,
};

Commutes.defaultProps = {
  className: '',
};

export default Commutes;
