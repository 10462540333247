import PropTypes from 'prop-types';
import { Button } from 'components-antd';
import { useDispatch } from 'react-redux';
import { inactivateMlsAccessForAgent } from 'api/admin/users';
import { getAllMlsAgentsDashboardEffect } from 'store/effects/adminPanel/mlsAgents';
import { MlsAccessStatusEnum } from '../../constants';
import styles from './styles.module.scss';

const InactivateMlsAgentButton = (props) => {
  const dispatch = useDispatch();
  const { status, mlsId, userId } = props;

  if (![MlsAccessStatusEnum.APPROVED].includes(status)) {
    return null;
  }

  const handleInactivateMlsAccessClick = async (mlsId, userId) => {
    await inactivateMlsAccessForAgent({
      userId,
      mlsId,
    });
    dispatch(getAllMlsAgentsDashboardEffect({}));
  };

  return (
    <Button
      onClick={() => handleInactivateMlsAccessClick(mlsId, userId)}
      className={styles.inactiveBtn}
    >
      Deactivate
    </Button>
  );
};

InactivateMlsAgentButton.propTypes = {
  status: PropTypes.string,
  mlsId: PropTypes.string,
  userId: PropTypes.string,
};

InactivateMlsAgentButton.defaultProps = {
  status: '',
  mlsId: '',
  userId: '',
};

export default InactivateMlsAgentButton;
