import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getOfferAggregateByUserIdEffect,
  setOffersFiltersAppliedEffect,
} from 'store/effects/offerAggregate';
import { getUserId } from 'store/selectors/user';
import { relatedEntitiesCompleteListGetDataEffect } from 'store/effects/relatedEntities';
import { getAllOfferBuyingAgentsEffect } from 'store/effects/offerBuyingAgents';
import { FilterSections } from './FilterSections';
import { requestGetTransactionPersons } from 'store/effects';

export const Filter = ({ transactionRoomId }: { transactionRoomId?: string }) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);

  useEffect(() => {
    dispatch(relatedEntitiesCompleteListGetDataEffect());
    dispatch(getAllOfferBuyingAgentsEffect());
    dispatch(requestGetTransactionPersons());
  }, []);

  const doneHandler = (filters) => {
    dispatch(setOffersFiltersAppliedEffect(true));
    dispatch(getOfferAggregateByUserIdEffect({ id: userId, filters }));
  };

  const resetHandler = (filters) => {
    dispatch(setOffersFiltersAppliedEffect(false));
    dispatch(getOfferAggregateByUserIdEffect({ id: userId, filters }));
  };

  return (
    <FilterSections
      onReset={resetHandler}
      onDone={doneHandler}
      transactionRoomId={transactionRoomId}
    />
  );
};
