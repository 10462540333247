import { combineReducers } from 'redux';

import availablePlans from './availablePlans';
import invoiceDataOverview from './invoiceDataOverview';
import currentPlanLevel from './currentPlanLevel';
import implementationPlan from './implementationPlan';
import standardCoupon from './standardCoupon';
import standardSubscription from './standardSubscription';
import cancelSubscription from './cancelSubscription';
import reactivateSubscription from './reactivateSubscription';
import updateStandardSubscription from './updateStandardSubscription';
import legacySubscription from './legacySubscription';
import updatePaymentModel from './updatePaymentModel';
import updatePayment from './updatePayment';
import activeSubscriptionTeamData from './activeSubscriptionTeamData';

export default combineReducers({
  availablePlans,
  invoiceDataOverview,
  currentPlanLevel,
  implementationPlan,
  standardCoupon,
  standardSubscription,
  legacySubscription,
  cancelSubscription,
  reactivateSubscription,
  updateStandardSubscription,
  updatePaymentModel,
  updatePayment,
  activeSubscriptionTeamData,
});
