import { useSelector } from 'react-redux';

import { Col, Row } from 'components-antd';
import { Header } from './Components/Header';
import { HeaderTabs } from './Components/HeaderTabs';
import { getIsGraphSelected } from 'store/selectors/clarity';
import { Stats } from './Components/Stats';

export const ClarityRevamped = () => {
  const isGraphSelected = useSelector(getIsGraphSelected);

  return (
    <>
      {isGraphSelected ? (
        <Stats />
      ) : (
        <>
          <Header />
          <Row justify="center">
            <Col span={24}>
              <HeaderTabs />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
