import { createSelector } from 'reselect';
import { get, groupBy } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ templates: { task } }) => task;

export const getTaskTemplatesSelector = createSelector(localState, ({ state, meta, ...rest }) => ({
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!rest?.data,
  ...rest,
}));

export const getTaskTemplatesDataSelector = createSelector(localState, (state) =>
  get(state, 'data'),
);

export const getGroupedTaskTemplatesSelector = createSelector(
  getTaskTemplatesDataSelector,
  (templates) => groupBy(templates, 'Category.Name'),
);

export const getFilteredGroupedTaskTemplatesSelector = createSelector(
  getTaskTemplatesDataSelector,
  localState,
  (templates, { query }) => {
    const search = query?.toLowerCase() || '';
    const filteredTemplates = templates?.filter((item) => {
      const name = item?.Name?.toLowerCase();
      const categoryName = item?.Category?.Name ? item.Category.Name.toLowerCase() : 'general';
      return name.includes(search) || categoryName.includes(search);
    });

    const templatesWithoutCategory = [];
    const templatesWithCategory = filteredTemplates?.filter((template) => {
      if (template?.Category?.Name) {
        return true;
      } else {
        templatesWithoutCategory.push(template);
        return false;
      }
    });

    return {
      ...groupBy(templatesWithCategory, 'Category.Name'),
      General: templatesWithoutCategory,
    };
  },
);
