import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SingleMultiAvatar } from 'components/SingleMultiAvatar';
import styles from './styles.module.scss';

const AssignItem = (props) => {
  const { className, name, listItems } = props;

  return (
    <div
      className={classNames(
        styles.assignItem,
        { [styles.multipleAssignItem]: listItems?.length > 1 },
        { [styles.singleAssignItem]: listItems?.length <= 1 },
        className,
      )}
    >
      <SingleMultiAvatar avatar={listItems} showOverlayCounter={false} />
      <div testid="assign_item" className={styles.name}>
        {name?.layout || name}
      </div>
    </div>
  );
};

AssignItem.propTypes = {
  className: PropTypes.string,
  avatarUrl: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.shape({})]),
};

AssignItem.defaultProps = {
  className: '',
  avatarUrl: null,
  name: null,
};

export default AssignItem;
