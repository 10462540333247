import { useEffect, useState } from 'react';
import { pullAt } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

import { Input, Select, Option, Row, Col, Link, Button, Divider, Tag } from 'components-antd';
import { updateQuoteFormQuestionEffect } from 'store/effects/formBuilder';
import { FormMode, ListBoxType, QuestionType, ResponseType } from 'types';
import { ArrowDown, CheckBox, Close as CloseIcon } from 'components/Icons';
import { FORM_MODE, PDF_FIELD_TYPE } from 'app-constants';

import styles from './styles.module.scss';

interface ListBoxQuestionProps {
  mode: FormMode;
  disabled: boolean;
  smartForm?: boolean;
  formQuestion?: QuestionType;
  formResponse?: ResponseType;
  handleFormResponse?: ({ Answer }: Partial<ResponseType>) => void;
}

export const ListBoxQuestion = ({
  mode,
  disabled,
  smartForm = false,
  formQuestion,
  formResponse,
  handleFormResponse,
}: ListBoxQuestionProps) => {
  const dispatch = useDispatch();

  const getSmartListBoxOptions = () => {
    switch (formQuestion?.FieldType) {
      case PDF_FIELD_TYPE.PDFCheckBox:
        return formQuestion?.Fields || [];

      case PDF_FIELD_TYPE.PDFRadioGroup:
        return formQuestion.Fields?.length &&
          formQuestion.Fields[0] &&
          formQuestion.Fields[0]?.radioOptions?.length
          ? formQuestion.Fields[0].radioOptions.map((option) => ({
              fieldName: option.key,
              id: option.value,
            }))
          : [];
      default:
        return [];
    }
  };
  const editMode = mode === FORM_MODE.Edit;

  const multiSelect = (formQuestion?.Meta as ListBoxType)?.MultiSelect;
  const listBoxOptions = (formQuestion?.Meta as ListBoxType)?.ListBoxOptions;
  const smartFormListBoxOptions = smartForm ? getSmartListBoxOptions() : [];
  const radio = smartForm && formQuestion?.FieldType === PDF_FIELD_TYPE.PDFRadioGroup;

  const [name, setName] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    setSelectedOptions([]);
    if (formResponse?.Answer) {
      const value = JSON.parse(formResponse.Answer);

      setSelectedOptions(radio ? value?.[0]?.selectedOptions || [] : value);
    }
  }, [formQuestion?.UUID]);

  const updateItems = (items: string[]) => {
    if (formQuestion) {
      dispatch(
        updateQuoteFormQuestionEffect({
          ...formQuestion,
          Meta: {
            ListBoxOptions: items,
            MultiSelect: (formQuestion?.Meta as ListBoxType)?.MultiSelect,
          },
        }),
      );
    }
  };

  const addItem = (e) => {
    e.preventDefault();
    if (name && name.replace(/\s/g, '').length) {
      updateItems([...listBoxOptions, name]);
    }
    setName('');
  };

  const removeItem = (index: number) => {
    const updatedItems = [...listBoxOptions];
    pullAt(updatedItems, index);
    updateItems(updatedItems);
  };

  const removeAllItems = () => {
    updateItems([]);
  };

  const tagRender = ({ label }) => <Tag closable={false}>{label}</Tag>;

  const handleUserInput = (selectedOptions: string[]) => {
    if (formResponse?.UUID && handleFormResponse) {
      handleFormResponse({
        Answer: JSON.stringify(
          radio ? [{ fieldId: formQuestion?.Fields?.[0]?.id, selectedOptions }] : selectedOptions,
        ),
      });
    }
  };

  const handleSelectInput = (e: any) => {
    if (multiSelect) {
      const updatedSelectedOptions = [...selectedOptions, e];
      setSelectedOptions(updatedSelectedOptions);
      handleUserInput(updatedSelectedOptions);
    } else {
      setSelectedOptions([e]);
      handleUserInput([e]);
      if (editMode) {
        e.preventDefault();
      }
    }
  };

  const handleDeselectInput = (e: any) => {
    if (multiSelect) {
      const updatedSelectedOptions = [...selectedOptions];
      const index = updatedSelectedOptions.indexOf(e);
      pullAt(updatedSelectedOptions, index);
      setSelectedOptions(updatedSelectedOptions);
      handleUserInput(updatedSelectedOptions);
    } else {
      setSelectedOptions([]);
      handleUserInput([]);
    }
  };

  return (
    <div className={styles.inputContainer}>
      <Select
        onSelect={handleSelectInput}
        onDeselect={handleDeselectInput}
        menuItemSelectedIcon={editMode ? null : <CheckBox className={styles.checkBoxIcon} />}
        tagRender={editMode ? tagRender : undefined}
        optionLabelProp="label"
        mode={multiSelect ? 'multiple' : undefined}
        allowClear={editMode ? false : multiSelect ? false : true}
        value={editMode ? (multiSelect ? listBoxOptions : '') : selectedOptions}
        size="large"
        className={styles.listBoxInput}
        dropdownRender={
          editMode && !disabled
            ? (menu) => (
                <>
                  {menu}
                  <Divider className={styles.divider} />
                  <Row className={styles.addListOptions}>
                    <Col className={styles.addOptionInput}>
                      <Input
                        placeholder="Please enter list option"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onPressEnter={(e) => {
                          e.stopPropagation();
                          addItem(e);
                        }}
                      />
                    </Col>
                    <Col className={styles.addOptionBtn}>
                      <Link className={styles.link} onClick={addItem}>
                        <PlusOutlined /> Add option
                      </Link>
                    </Col>
                  </Row>
                </>
              )
            : undefined
        }
        suffixIcon={<ArrowDown />}
      >
        {smartForm
          ? smartFormListBoxOptions.map((item, index) => (
              <Option
                className={styles.optionContainer}
                key={index}
                label={item.fieldName}
                value={item.id}
              >
                {item.fieldName}
                {editMode && (
                  <CloseIcon className={styles.closeIcon} onClick={() => removeItem(index)} />
                )}
              </Option>
            ))
          : listBoxOptions?.map((item, index) => (
              <Option className={styles.optionContainer} key={index} label={item} value={item}>
                {item}
                {editMode && (
                  <CloseIcon className={styles.closeIcon} onClick={() => removeItem(index)} />
                )}
              </Option>
            ))}
      </Select>
      {editMode && !disabled && (
        <Button variant="link" className={styles.clearBtn} onClick={removeAllItems}>
          clear all options
        </Button>
      )}
    </div>
  );
};
