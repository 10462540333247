// import { handleActions } from 'redux-actions';
// import { initialState } from './initialState';
// import { showingDetailsReducer } from './reducers';

// export default handleActions(
//   {
//     ...showingDetailsReducer,
//   },
//   initialState,
// );

import { combineReducers } from 'redux';
import showingDetails from './showingDetails';
import showingAvailability from './showingAvailability';
import showingAppointment from './showingAppointment';

export default combineReducers({
  showingDetails,
  showingAvailability,
  showingAppointment,
});
