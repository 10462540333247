import { handleActions } from 'redux-actions';

import { setSharePropertyAction } from 'store/actions/feed';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';

const initialData = null;

export default handleActions(
  {
    [setSharePropertyAction]: (state, { payload }) => payload,
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
