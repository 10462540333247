import { Row, Col } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Switcher } from './Switcher';
import { TeamFilter } from '../Filters/TeamFilter';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_BASIC } from 'settings/constants/roles';
import { DateRangeFilter } from '../Filters/DateRangeFilter';

import styles from './styles.module.scss';

export const Header = () => {
  const agentRole = useSelector(getAgentTeamRoleSelector);

  return (
    <div className={classNames(styles.clarityHeader)}>
      <Row justify="center">
        <Col xs={24} lg={8} className={styles.switcherWrapper}>
          {/* <Switcher /> */}
          <span className={styles.title}>Metrics</span>
        </Col>
        <Col xs={24} lg={8} className={styles.filterWrapper}>
          <DateRangeFilter cb={() => {}} />
          {agentRole !== TEAM_BASIC && <TeamFilter />}
        </Col>
      </Row>
    </div>
  );
};
