import { Fragment } from 'react';
import classNames from 'classnames';

import {
  DeferredUsers,
  FormDocumentAnswersType,
  FormDocumentQuestionsType,
  PDFViewerMode,
  RenderFieldsType,
} from 'types';
import { getBackgroundColor, getFieldProps, getRenderFieldType } from './helper';
import { InputField } from './RenderPageFields';
import { AvatarIcons, InitialsWithIconAvatar } from 'components-antd';
import { SmartFormIconVariants, SmartFormIcons } from 'components';

import styles from './styles.module.scss';

interface RenderFieldProps {
  page: number;
  questions: FormDocumentQuestionsType;
  scale: number;
  responses: FormDocumentAnswersType;
  mode: PDFViewerMode;
  isCreator?: boolean;
  deferredUsers?: DeferredUsers[];
  setUndoDefer?: (questionUUID: string[]) => void;
}

export const RenderDisabledPageFields = ({
  page,
  questions = [],
  scale,
  responses,
  mode,
  isCreator,
  deferredUsers,
  setUndoDefer,
}: RenderFieldProps) => {
  const fields = getRenderFieldType(questions, page, responses, mode);

  if (!fields.length) {
    return null;
  }

  const renderToolTip = (user: DeferredUsers, fieldId: string) => {
    return (
      <div className={styles.deferTooltipContainer}>
        <div className={styles.deferUser}>
          <span className={styles.deferAvatarUser}>
            <InitialsWithIconAvatar
              userName={user.name}
              avatarSize={32}
              tooltipProps={{ title: user.name }}
              absoluteStyles={false}
            />
          </span>
          <span className={styles.deferUserName}>Deferred to {user.name}</span>
        </div>
        {setUndoDefer && fieldId && (
          <div className={styles.deferRemoveRow} onClick={() => setUndoDefer([fieldId])}>
            <SmartFormIcons variant={SmartFormIconVariants.Cross} className={styles.cancelIcon} />
            <span className={styles.deferRemoveText}>Remove</span>
          </div>
        )}
      </div>
    );
  };

  const DeferAvatar = ({
    field,
    fieldHeight,
  }: {
    field: RenderFieldsType;
    fieldHeight: number;
  }) => {
    if (field.questionUUID) {
      const userDetails = deferredUsers?.find((user) =>
        user?.questions?.includes(field.questionUUID),
      );
      if (userDetails?.name) {
        // top : (field Height / 2) - (Avatar Logo (not sub logo) /2)
        // right: 0 - (logo & sub logo width + padding)
        return (
          <InitialsWithIconAvatar
            containerStyles={{ top: fieldHeight / 2 - 16, right: -32 }}
            avatarTooltipClassName={styles.deferAvatarContainer}
            userName={userDetails?.name}
            tooltipProps={{
              title: renderToolTip(userDetails, field.questionUUID),
            }}
            subIcon={AvatarIcons.Defer}
          />
        );
      }
    }

    return <></>;
  };
  return (
    <Fragment key={`render-disabled-fields-${page}`}>
      {fields.map(({ isLastField, ...field }) => {
        const widgets = field?.widgets;

        if (!field?.widgets?.length) {
          return <></>;
        }

        const fieldElements: any = [];

        for (let i = 0; i < widgets.length; i++) {
          const widget = widgets[i];
          const { height, width, x, y, pageNumber } = widget;
          if (pageNumber !== page) {
            break;
          }

          fieldElements.push(
            <div
              key={`${field.id}-pdfField-disabled-element-${i}`}
              style={{
                width: width * scale,
                height: height * scale,
                position: 'absolute',
                left: x * scale,
                bottom: y * scale,
                zIndex: 999,
              }}
              className={classNames(
                styles.PDFFieldOverlay,
                styles.PDFFieldNotActive,
                styles.PDFFieldDisabled,
              )}
              itemID={`${field.id}-pdfField`}
            >
              <div
                style={{
                  background: isCreator
                    ? getBackgroundColor(questions[field.questionIndex || 0])
                    : undefined,
                }}
                className={styles.pdfFieldWrapper}
              />
              <InputField
                field={field}
                className={styles.inputDisabledPDF}
                disabled
                {...getFieldProps(field, i)}
              />
              {i === widgets.length - 1 && isLastField && (
                <DeferAvatar field={field} fieldHeight={height * scale} />
              )}
            </div>,
          );
        }

        return (
          <Fragment key={`render-disabled-field-elements-${field.id}`}>{fieldElements}</Fragment>
        );
      })}
    </Fragment>
  );
};
