import { useState } from 'react';
import { Switch } from 'components-antd';
import styles from './styles.module.scss';
import { Edit } from 'components/Icons';
import Permission from 'components/Icons/Permission';
import { updateKitById } from 'api/kits';
import classNames from 'classnames';
import getGeoLocationsAsString from 'helpers/getGeoLocationsAsString';
import { Tooltip } from 'antd';

type KitDataRowPropsType = {
  data;
  editKitClickHandler: Function;
  permitClickHandler: Function;
};

function KitDataRow(props: KitDataRowPropsType) {
  const [enabled, setEnabled] = useState<boolean>(props.data.Published);

  const getEligibleFrequencies = () => {
    if (props.data?.EligibleFrequencies?.length) {
      let frequencies = props.data?.EligibleFrequencies.map((item) => {
        let frequencyString = item.FrequencyName;
        if (item.ClosingDateRelated) {
          frequencyString += ' (CD';
          if (item.OffsetOperator) frequencyString += item.OffsetOperator;
          if (item.OffsetValue) frequencyString += item.OffsetValue;
          if (item.OffsetUnit) frequencyString += item.OffsetUnit;
          frequencyString += ' )';
        }
        if (item.HasSpecificDates && item.SpecificDates?.length) {
          frequencyString += ' (';
          frequencyString += item.SpecificDates.join();
          frequencyString += ' )';
        }
        return frequencyString;
      });
      return frequencies.join(',');
    } else return '-';
  };

  const switchHandler = async (v: boolean) => {
    await updateKitById(props.data.Id, { Published: v });
  };

  return (
    <tr className={styles.kitsRowData}>
      <td>
        <div className={styles.kitName}>
          <div>{props.data.Name}</div>
          <div className={styles.kitOptions}>
            <Edit
              onClick={() => {
                props.editKitClickHandler(props.data.Id);
              }}
              stroke="#000"
              className={styles.kitEdit}
            />
            <Permission
              onClick={() => {
                props.permitClickHandler(props.data.Id);
              }}
              className={styles.kitPermission}
            />
          </div>
        </div>
      </td>
      <td>
        <div className={styles.text}>{props.data.Category}</div>
      </td>
      <td>
        <div className={styles.options}>
          <p>{getEligibleFrequencies()}</p>
        </div>
      </td>
      <td>
        {props?.data?.Notes ? (
          <Tooltip title={props.data.Notes}>
            <div className={classNames(styles.text, styles.notes)}>{props.data.Notes}</div>
          </Tooltip>
        ) : (
          <div className={classNames(styles.text, styles.notes)}>-</div>
        )}
      </td>
      <td>
        <div className={styles.text}>
          {props.data.EligiblePropertyTypes.length
            ? props.data.EligiblePropertyTypes.replaceAll('{', '')
                .replaceAll('}', '')
                .replaceAll('"', '')
            : '-'}
        </div>
      </td>
      <td>
        <div className={classNames(styles.text, styles.locations)}>
          {getGeoLocationsAsString(props.data?.KitsLocation, true)}
        </div>
      </td>
      <td>
        <div className={styles.switchCell}>
          <Switch
            className={styles.switch}
            checked={enabled}
            defaultChecked={props.data.Enable}
            onChange={(v) => {
              setEnabled(v);
              switchHandler(v);
            }}
          />
        </div>
      </td>
    </tr>
  );
}

export default KitDataRow;
