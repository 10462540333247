export const transactionStatuses = {
  UnderContract: 'Under contract',
  ClearToClose: 'Clear to close',
  Canceled: 'Canceled',
  Closed: 'Closed',
  InProgress: 'In Progress',
  Upcoming: 'Upcoming',
  OnHold: 'On Hold',
  AtRisk: 'At Risk',
};

export const LISTED_STATUS = {
  Active: 'Active',
  Pending: 'Pending',
  PreListing: 'PreListing',
  OfficeExclusive: 'OfficeExclusive',
  ComingSoon: 'ComingSoon',
  ActiveListing: 'ActiveListing',
  OnHold: 'On Hold',
  Expired: 'Expired',
};

export const listedStatusesArray = [
  LISTED_STATUS.Active,
  LISTED_STATUS.ActiveListing,
  LISTED_STATUS.ComingSoon,
  LISTED_STATUS.OfficeExclusive,
  LISTED_STATUS.Pending,
  LISTED_STATUS.PreListing,
  LISTED_STATUS.OnHold,
  LISTED_STATUS.Expired,
];
