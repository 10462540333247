import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icons } from 'pages/FormProcess/Icons';
import { UserAvatar } from 'components-antd';
import styles from './styles.module.scss';

const List = forwardRef(
  ({ className, onChange, userOptions, extraOptions, selectedValue, open }, ref) => {
    if (!open) return null;

    return (
      <div testid="users_list" ref={ref} className={classNames(styles.listWrapper, className)}>
        <ul>
          {userOptions?.map((user) => (
            <li onClick={() => onChange(user)} key={user?.roleId} className={styles.item}>
              <div className={styles.profileWrap}>
                <UserAvatar
                  name={user?.userName}
                  className={styles.userAvatar}
                  avatar={user?.avatar}
                  minimizeAvatar
                  shortName
                />
                <p className={styles.optionUsername}>{user?.userName}</p>
              </div>
              {user.roleId === selectedValue && (
                <Icons variant={'Checked'} className={styles.checkIcon} />
              )}
            </li>
          ))}
          <li>{extraOptions && extraOptions()}</li>
        </ul>
      </div>
    );
  },
);

List.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  open: PropTypes.bool,
  extraOptions: PropTypes.func,
};

List.defaultProps = {
  className: '',
  onChange: () => {},
  open: false,
  userOptions: [],
  extraOptions: () => {},
};

export default List;
