import { combineReducers } from 'redux';

import search from './search';
import edited from './edited';
import rawSearch from './rawSearchData';
import areaDrawing from './areaDrawing';

export default combineReducers({
  search,
  edited,
  rawSearch,
  areaDrawing,
});
