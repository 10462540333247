import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';

import { useDispatch, useSelector } from 'react-redux';
import { updateKeyDatesEffect } from 'store/effects/transactions';
import {
  getKeyDatesSelector,
  getTransactionAccessSelector,
  getTransactionSelector,
} from 'store/selectors/transaction';

import { Input } from 'components';
import { Button } from 'components-antd';
import Icon from 'pages/Workshop/Transactions/TransactionOverview/Icons';

import transactionViewStyles from './../TransactionView/styles.module.scss';
import styles from './styles.module.scss';

const Price = (props) => {
  const dispatch = useDispatch();
  const { className, isDisableField, setEditId } = props;
  const [isPending, setIsPending] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);
  const keyDates = useSelector(getKeyDatesSelector);

  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [isEdit, setIsEdit] = useState(false);
  const [price, setPrice] = useState(
    keyDates?.ClosePrice || keyDates?.UnderContractPrice || keyDates?.ListingPrice,
  );
  const keyToBeUpdated = keyDates?.ClosePrice
    ? 'ClosePrice'
    : keyDates?.UnderContractPrice
    ? 'UnderContractPrice'
    : 'ListingPrice';

  const editKey = 'price';
  const payloadKey = transaction?.IsProject ? 'ProjectKeyDates' : 'TransactionKeyDatePrices';
  const onEdit = () => {
    if (!fullAccess || isDisableField(editKey)) return null;
    setIsEdit(true);
    setEditId(editKey);
  };

  const priceValue = useMemo(
    () => keyDates?.ClosePrice || keyDates?.UnderContractPrice || keyDates?.ListingPrice,
    [keyDates],
  );

  const isDisabled = useMemo(() => {
    const numericPrice = parseFloat(price);
    return isNaN(numericPrice) || numericPrice === 0 || price === priceValue;
  }, [price, priceValue]);

  const updatePrice = () => {
    setIsPending(true);
    dispatch(
      updateKeyDatesEffect(
        {
          transactionId: transaction?.Id,
          [payloadKey]: {
            [keyToBeUpdated]: price,
          },
        },
        {},
        (err) => {
          if (!err) {
            onCancel();
          }
          setIsPending(false);
        },
      ),
    );
  };

  const onCancel = () => {
    setIsEdit(false);
    setEditId(null);
  };

  return (
    <>
      {!isEdit ? (
        <div
          className={classNames(
            styles.price,
            { [transactionViewStyles.fullAccess]: fullAccess },
            { [transactionViewStyles.disableField]: !!isDisableField(editKey) },
          )}
          onClick={onEdit}
        >
          <div>
            <div>Price:</div>
            <div testid="price" className={classNames(styles.price, className)}>
              <NumberFormat prefix="$" displayType="text" thousandSeparator value={priceValue} />
            </div>
          </div>
          {fullAccess && !isDisableField(editKey) && (
            <Icon testid="edit_icon" className={styles.editIcon} variant={Icon.EDIT} />
          )}
        </div>
      ) : (
        <div className={transactionViewStyles.editWrapper}>
          <Input
            label="Listing Price"
            placeholder="Listing Price"
            variant="lightRound"
            isNumberFormat
            prefix="$"
            value={priceValue}
            onChange={(e, val) => setPrice(val)}
            disabled={isPending}
            testid="price_input"
            maxNumber={1000000000}
          />
          <div className={transactionViewStyles.buttons}>
            <Button
              variant="secondary"
              size="large"
              disabled={isPending || isDisabled}
              onClick={updatePrice}
              loading={isPending}
            >
              Update
            </Button>
            <Button variant="hollow-bordered" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

Price.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
};

Price.defaultProps = {
  className: '',
  value: null,
};

export default Price;
