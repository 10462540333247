import { apiServer } from 'settings/web-services/api';

export function updateTransaction(cfg) {
  const { id, ...config } = cfg;
  return apiServer.patch(`/transaction/${id}`, config);
}

export function updateKeyDates(cfg) {
  const { transactionId, ...config } = cfg;
  return apiServer.patch(`/transaction/${transactionId}/key-dates`, config);
}

export function addClientAddress({ id, ...cfg }) {
  return apiServer.post(`/transaction/${id}/client/address`, cfg);
}

export function removeClientAddress({ id, ...cfg }) {
  return apiServer.patch(`/transaction/${id}/client/address`, cfg);
}

export function updateTransactionStatus(cfg) {
  const { id, ...config } = cfg;
  return apiServer.patch(`/transaction/${id}/status`, config);
}

export function moveToActiveListing(cfg) {
  const { id, ...config } = cfg;
  return apiServer.put(`/transaction/${id}/canceled`, config);
}

export function updateTaskStatusForCancelledTransactionFr(cfg) {
  const { transactionActionId, ...config } = cfg;
  return apiServer.patch(
    `/transaction/tasks/canceled-transaction-task-status/${cfg.transactionActionId}`,
    config,
  );
}

export function updateTransactionUnderContract(cfg) {
  const { Id, ...config } = cfg;
  return apiServer.patch(`/transaction/${Id}`, config);
}

export function inviteTransactionParticipant(cfg) {
  const { transactionId, ...config } = cfg;
  return apiServer.post(`/transaction/${transactionId}/participant`, config);
}

export function resendParticipantInvite(cfg) {
  const { transactionId, participantId, ...config } = cfg;

  return apiServer.post(`/transaction/${transactionId}/resendInvite/${participantId}`, config);
}

export function removeTransactionParticipant(cfg) {
  const { transactionId, participantId } = cfg;
  return apiServer.delete(`/transaction/${transactionId}/participant/${participantId}`);
}

export function updateTransactionMilestones(cfg) {
  const { transactionId, ...config } = cfg;
  return apiServer.put(`/transaction/${transactionId}/milestones`, config);
}

export function updateMilestoneStatus(cfg) {
  const { transactionId, milestoneId, ...config } = cfg;
  return apiServer.put(`/transaction/${transactionId}/milestones/${milestoneId}`, config);
}

export function updateTransactionParticipantAccess(cfg) {
  const { transactionId, participantId, TransactionAccess } = cfg;
  return apiServer.put(`/transaction/${transactionId}/participant/${participantId}`, {
    TransactionAccess,
  });
}

export const updateTransactionDetail = ({ id, detailId, ...cfg }) =>
  apiServer.patch(`/transaction/${id}/detail/${detailId}`, cfg);

export const updateTransactionContact = ({ id, contactId, ...cfg }) =>
  apiServer.put(`/transaction/${id}/contacts/${contactId}`, cfg);

export const updateTransactionParticipants = ({ id, ...cfg }) =>
  apiServer.put(`/transaction/${id}/participant`, cfg);

export const deleteTransactionContact = ({ id, contactId, ...cfg }) =>
  apiServer.delete(`/transaction/${id}/contacts/${contactId}`, cfg);

export const updateCloseDatePrice = ({ id, ...cfg }) => apiServer.put(`/transaction/${id}`, cfg);
