import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { pushOrRemove } from 'helpers';

import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const Fields = ({ className, onChange, list, activeFields }) => {
  const [actives, setActives] = useState(activeFields);

  useEffect(() => setActives(activeFields), [activeFields]);

  const onClickHandler = (val) => {
    const newVal = pushOrRemove({ arr: actives, id: val, multiple: false });
    if (newVal?.length) {
      setActives(newVal);
      onChange(newVal);
    }
  };

  if (!list) return null;

  return (
    <ul className={classNames(styles.sorts, className)}>
      {list.map(({ label, value }) => {
        const isActive = actives.includes(value);
        return (
          <li
            testid="sorting_field"
            key={value}
            className={styles.item}
            onClick={() => onClickHandler(value)}
          >
            <span className={classNames(styles.label, { [styles.active]: isActive })}>{label}</span>
            {isActive && <Icon variant={Icon.CHECKMARK} />}
          </li>
        );
      })}
    </ul>
  );
};

Fields.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  activeFields: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

Fields.defaultProps = {
  className: '',
  list: [],
  activeFields: [],
  onChange: () => {},
};

export default Fields;
