/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';

import { Input, Locations, MinMax } from 'components';
import { SearchCriteria } from '../SearchCriteria';

import styles from '../styles.module.scss';
import { useState } from 'react';
import { dispatch } from 'store';
import { useSelector } from 'react-redux';
import { getSearchDrawnPolygonSelector } from 'store/selectors/search';
import { isInviteClientCustomLocationModalOpened } from 'store/selectors/app';
import { setShowInviteCustomLocationAction } from 'store/actions/app';
import { cleanSearchDrawnPolygonAction } from 'store/actions/searchResults';
import { featureFlags } from 'utils/featureFlags';

export const BuyerProfile = ({ formik, disabled }) => {
  const isClientInviteOpened = useSelector(isInviteClientCustomLocationModalOpened);
  const [locationSelector, setLocationSelector] = useState(false);
  const customDrawing = useSelector(getSearchDrawnPolygonSelector);
  const setModalOpen = () => {
    setLocationSelector(true);
    dispatch(setShowInviteCustomLocationAction(true));
  };
  const onSearchClick = () => {
    dispatch(cleanSearchDrawnPolygonAction());
    setLocationSelector(false);
  };
  return (
    <>
      <div className={styles.modalContainer}>
        <Locations
          showAsterisk={true}
          variant={Locations.ROUND}
          name="locations"
          onResult={(result, preparedData) => {
            formik.setFieldValue('locations', preparedData);
          }}
          label={'Desired Locations'}
          placeholder="Enter city, ZIP, or neighborhood"
          error={formik.touched.locations ? formik.errors.locations : ''}
          disabled={disabled}
          testid="desired_locations"
          multiple={true}
          value={formik?.values?.locations || []}
          fieldTouched={() => formik.setFieldTouched('locations', true)}
          placeholderClassName={styles.border}
          searchWrapperClassName={styles.border}
          valuesWrapperClassName={styles.border}
          enableDraw={featureFlags.customSearchPolygonDrawing}
          rounded={true}
        />
        <MinMax
          variant={Input.LIGHT_ROUND}
          className={styles.field}
          label="Price"
          nameMin="priceMin"
          nameMax="priceMax"
          onChangeMin={formik.handleChange}
          onChangeMax={formik.handleChange}
          valueMin={formik.values.priceMin}
          valueMax={formik.values.priceMax}
          placeholderMin="No min"
          placeholderMax="No max"
          prefix="$"
          disabled={disabled}
          testidMin="price_min"
          testidMax="price_max"
          errorMin={formik.touched.priceMin ? formik.errors.priceMin : ''}
          errorMax={formik.touched.priceMax ? formik.errors.priceMax : ''}
        />
        <SearchCriteria formik={formik} disabled={disabled} />
      </div>
    </>
  );
};

BuyerProfile.propTypes = {
  formik: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  showFooter: PropTypes.func,
};

BuyerProfile.defaultProps = {
  disabled: false,
};
