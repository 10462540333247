const haversineDistance = (lat1, lng1, lat2, lng2) => {
  const R = 3958.8; // Earth's radius in miles

  const dLat = degToRad(lat2 - lat1);
  const dLon = degToRad(lng2 - lng1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // Distance in miles
  return distance.toFixed(1);
};

const degToRad = (deg) => {
  return deg * (Math.PI / 180);
};

export default haversineDistance;
