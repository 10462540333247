import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../styles.module.scss';

const Point = ({ links, title, getIcon }) => {
  const [isActive, setIsActive] = useState(false);
  const handlePointClick = () => setIsActive((val) => !val);

  const getLinks = (childLinks) =>
    childLinks.map(({ link, target, title: linkTitle }) => (
      <li key={linkTitle}>
        <NavLink
          className={styles.link}
          activeClassName={styles.linkActive}
          title={linkTitle}
          target={target}
          to={link}
        >
          {linkTitle}
        </NavLink>
      </li>
    ));

  return links.length === 1 ? (
    <>
      <div className={classNames(styles.point)}>
        <NavLink
          className={classNames(styles.pointContentWrapper, styles.link)}
          activeClassName={styles.linkActive}
          title={title}
          to={links[0].link}
          onClick={handlePointClick}
        >
          {getIcon ? getIcon(styles.pointIcon) : null}
          <div className={styles.pointTitle}>{title}</div>
        </NavLink>
      </div>
    </>
  ) : (
    <>
      <div className={classNames(styles.point, { [styles.pointActive]: isActive })}>
        <div className={styles.pointContentWrapper} onClick={handlePointClick}>
          <div className={styles.pointContent}>
            {getIcon ? getIcon(styles.pointIcon) : null}
            <div className={styles.pointTitle}>{title}</div>
          </div>
        </div>
      </div>
      {links?.length ? (
        <ul className={classNames(styles.links, { [styles.open]: isActive })}>{getLinks(links)}</ul>
      ) : null}
    </>
  );
};

Point.propTypes = {
  title: PropTypes.string.isRequired,
  getIcon: PropTypes.func,
  links: PropTypes.arrayOf(PropTypes.shape({ link: PropTypes.string, title: PropTypes.string })),
};

Point.defaultProps = {
  getIcon: () => null,
  links: [],
};

export default Point;
