import PartnerCard from './PartnerCard';

import styles from './styles.module.scss';

const Content = ({
  partners,
  setSelectedPartner,
  setAddEditModal,
  deletePartner,
  categoryOptions,
  existingEmails,
  agentOptions,
}) => {
  return (
    <div className={styles.container}>
      {partners.map((partner, index) => (
        <PartnerCard
          partner={partner}
          key={index}
          setSelectedPartner={() => {
            setSelectedPartner(index);
            setAddEditModal(true);
          }}
          deletePartner={() => deletePartner(index)}
          categoryOptions={categoryOptions}
          existingEmails={existingEmails}
          agentOptions={agentOptions}
        />
      ))}
    </div>
  );
};

export default Content;
