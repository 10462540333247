import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash-es';

import Icons from 'components/CommuteForm/Icons';

import styles from './styles.module.scss';

const BuyerCommutes = ({ data }) => (
  <div className={styles.wrapper}>
    {map(data, ({ title, address, times }, index) => (
      <div testid="commute_item" className={styles.commuteWrapper} key={index}>
        <div className={styles.commuteTitle}>
          <b testid="title">{title}</b>
          <span testid="address">{`(${address})`}</span>
        </div>
        <div className={styles.commuteVariants}>
          {times?.length
            ? map(times, ({ value, type, variant }, id) => (
                <div className={styles.commuteVariant} key={id}>
                  <Icons className={styles.commuteVariantIcon} variant={variant} />
                  <div
                    testid="time_by"
                    className={styles.commuteVariantText}
                  >{`${value} by ${type}`}</div>
                </div>
              ))
            : '-'}
        </div>
      </div>
    ))}
  </div>
);

BuyerCommutes.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      times: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default BuyerCommutes;
