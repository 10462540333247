import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink, useParams } from 'react-router-dom';

import { Icon } from 'components/Transactions';

import styles from './styles.module.scss';

const NavItem = (props) => {
  const { icon, className, activeClassName, linkTo, label, isUnread } = props;
  const params = useParams();

  return (
    <NavLink
      to={linkTo(params?.id)}
      className={classNames(styles.navItem, className)}
      activeClassName={classNames(styles.active, activeClassName)}
      testid={label}
    >
      <div className={styles.inner}>
        <Icon className={classNames(styles.icon)} variant={icon} />
        <div className={classNames(styles.label, { [styles.isUnread]: isUnread })}>{label}</div>
      </div>
    </NavLink>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  icon: PropTypes.string.isRequired,
  linkTo: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isUnread: PropTypes.bool,
};

NavItem.defaultProps = {
  className: '',
  activeClassName: '',
  isUnread: false,
};

export default NavItem;
