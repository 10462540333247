import Api from 'store/effects/core/api';
import { uploadTransactionDocumentsAction } from 'store/actions/transactions';
import { uploadTransactionDocuments } from 'api/transactions/documents/create';
import { getState } from 'store';
import { get } from 'lodash-es';
import { prepareReviewersIds } from '../../tasks/helpers';
import { getParticipantsData } from 'utils/workshopHelper';

export const uploadTransactionDocumentsEffect = (cfg, options, cb) => {
  const transactionData = get(getState(), 'transaction.data');
  const sendRequest = Api.execResult({
    action: uploadTransactionDocumentsAction,
    method: uploadTransactionDocuments,
  });

  const config = {
    transactionId: transactionData?.Id,
    Documents: (cfg?.documents || []).map((document) => {
      const participants = getParticipantsData(document);
      return {
        Title: document?.documentName,
        Category: document?.customTag || document?.category?.name || document?.category,
        File: {
          ContentType: document?.contentType,
          Filename: document?.filename,
          Size: document?.size,
          Data: btoa(document?.data),
        },
        UsersWithAccess: participants.UsersWithAccess,
        UsersEmailWithAccess: participants.UsersEmailWithAccess,
        AccessToEveryone: participants.AccessToEveryone,
      };
    }),
  };
  return sendRequest(config, options, cb);
};
