import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'components-antd';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';

import { Title } from '..';

import styles from './styles.module.scss';
import { LocalHeader } from 'pages/Quotes/LocalHeader';
import { useSelector } from 'react-redux';
import { getGroupedQuotesSelector, getIsArchiveQuotesSelector } from 'store/selectors/quotes';

const PageHeader = (props) => {
  const { className, isPending, isThirdParty } = props;
  const { groupId } = useSelector(getGroupedQuotesSelector);
  const isArchive = useSelector(getIsArchiveQuotesSelector);
  const history = useHistory();

  const onGoBackBtnClick = () => {
    if (groupId) {
      history.push(routes.servicesQuotes);
    } else {
      history.push(routes.services);
    }
  };

  const getHeaderElements = () => {
    return (
      <>
        <div testid="quotes_header" className={styles.head}>
          {isThirdParty && !groupId ? (
            <div className={styles.headerLeft} />
          ) : (
            <div className={styles.headerLeft}>
              <Button
                variant={'go-back'}
                onClick={() => onGoBackBtnClick()}
                className={styles.goBackButton}
              />
            </div>
          )}
          <Title
            className={classNames(styles.quotesTitle, {
              [styles.quotesTitlePartner]: isThirdParty,
            })}
          >
            {groupId && !isArchive ? groupId : 'My Quotes'}
          </Title>
        </div>
        <LocalHeader />
      </>
    );
  };

  if (isPending) return null;

  return <div className={classNames(styles.pageHeader, className)}>{getHeaderElements()}</div>;
};

PageHeader.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  isThirdParty: PropTypes.bool,
};

PageHeader.defaultProps = {
  className: '',
  isPending: false,
  isThirdParty: false,
};

export default PageHeader;
