export enum FormsTableStatus {
  WaitingOnYou = 'Waiting on You',
  WaitingOnOthers = 'Waiting on Others',
  Complete = 'Completed',
  RecentlyCompleted = 'Recently Completed',
  Finalizing = 'Finalizing',
  Draft = 'Draft',
}

export enum FormsArchiveTableStatus {
  Canceled = 'Canceled',
  Complete = 'Completed',
  Voided = 'Voided',
}

export enum FormsFilterTableStatus {
  WaitingOnYou = 'Waiting on You',
  WaitingOnOthers = 'Waiting on Others',
  RecentlyCompleted = 'Complete',
  Draft = 'Draft',
}

export enum FormsArchiveFilterTableStatus {
  RecentlyCompleted = 'Complete',
  Voided = 'Voided',
}
