import { handleActions } from 'redux-actions';
import { setSearchRawPrefsAction } from 'store/actions/search';
import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';

const initialData = {
  rawPrefs: {
    [SEARCH_CRITERIA_IMPORTANCE.MUST]: [],
    [SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT]: [],
    [SEARCH_CRITERIA_IMPORTANCE.UNDEFINED]: [],
  },
};

export default handleActions(
  {
    [setSearchRawPrefsAction as any]: (state, { payload }) => {
      return {
        ...state,
        rawPrefs: {
          [SEARCH_CRITERIA_IMPORTANCE.MUST]: payload?.[SEARCH_CRITERIA_IMPORTANCE.MUST] || [],
          [SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT]:
            payload?.[SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT] || [],
          [SEARCH_CRITERIA_IMPORTANCE.UNDEFINED]:
            payload?.[SEARCH_CRITERIA_IMPORTANCE.UNDEFINED] || [],
        },
      };
    },
  },
  initialData,
);
