import styles from './styles.module.scss';
import React from 'react';
import { formatPhoneNumber, limitTextLength } from 'helpers';
import { IconText } from '../IconText';
import { Icons } from '../../../../components/Icons';
import { CardBox } from 'pages/ExternalOffer/OfferSubmission/components/CardBox';

export const EditContactInformation = ({ data, onEdit }) => {
  const getInfo = () => {
    const {
      BuyingAgentName: agentName,
      BuyingAgentBrokerage: brokerage,
      BuyingAgentPhone: phone,
      BuyingAgentEmail: email,
    } = data;

    let text = '';

    if (agentName) {
      text += `${agentName}, `;
    }
    if (brokerage) {
      text += `${brokerage}, `;
    }
    if (phone) {
      text += `${formatPhoneNumber(phone)}, `;
    }
    if (email) {
      text += `${email}`;
    }
    return text ? limitTextLength(text, 45) : '-';
  };

  return (
    <CardBox className={styles.editForm}>
      <IconText
        text={'Contact Information'}
        infoText={getInfo()}
        variant={'contactInformation'}
        className={styles.editFormInfo}
      />
      <div className={styles.editIcon} onClick={onEdit}>
        <Icons variant={'edit'} />
      </div>
    </CardBox>
  );
};
