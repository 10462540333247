import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from 'components/Transactions';

import styles from './styles.module.scss';

const Back = (props) => {
  const { className, onClick, text } = props;

  return (
    <div testid="back" onClick={onClick} className={classNames(styles.back, className)}>
      <Icon className={styles.icon} variant={Icon.ARROW_LEFT} />
      <div>{text}</div>
    </div>
  );
};

Back.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

Back.defaultProps = {
  className: '',
  text: 'Transactions',
  onClick: () => {},
};

export default Back;
