import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  resetMessagesEffect,
  socketsGetMessagesByThreadIdEffect,
  sendNewMessageEffect,
  editMessageEffect,
  deleteMessageEffect,
} from 'store/effects/sockets/messages';
import { getMessagesDrawerParams } from 'store/selectors/drawers/messages';
import { getCurrentMessagesInfoSelector } from 'store/selectors/sockets/messages';

import { SendMessageForm, Messages } from 'components';

import { sendThreadOpenedEffect, sendThreadsClosedEffect } from 'store/effects/sockets/threads';
import Header from './Header';

import styles from './styles.module.scss';
import { SOCKET_MESSAGE_TYPES } from 'settings/constants/sockets';
import { socketsStoreEditedMessageAction } from 'store/actions/sockets/messages';

const MessagesDrawerChatContent = () => {
  const dispatch = useDispatch();
  const messages = useSelector(getCurrentMessagesInfoSelector);
  const { threadId } = useSelector(getMessagesDrawerParams);

  const onSendMessage = useCallback(
    (message, attachments, tags, messageId) => {
      if (messageId) {
        const payload = messages?.find((message) => message.Id === messageId);

        // Persist previous Message Meta for property comments
        const messageMeta = payload?.MessageMeta
          ? Array.isArray(payload?.MessageMeta)
            ? payload?.MessageMeta?.map((meta) => ({
                ...meta,
                Tags: [...(meta?.Tags || []), ...tags],
              }))
            : { ...payload?.MessageMeta, Tags: [...(payload?.MessageMeta?.Tags || []), ...tags] }
          : { Tags: tags };

        dispatch(
          socketsStoreEditedMessageAction({
            data: {
              ...payload,
              Text: message,
              Attachments: attachments,
              MessageType: payload?.MessageType || SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
              MessageMeta: messageMeta,
            },
          }),
        );

        return dispatch(
          editMessageEffect({
            MessageId: messageId,
            Text: message,
            Attachments: attachments,
            MessageType: payload?.MessageType || SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
            MessageMeta: messageMeta,
          }),
        );
      }

      return dispatch(
        sendNewMessageEffect({
          ThreadId: threadId,
          Text: message,
          Attachments: attachments,
          MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
          MessageMeta: { Tags: tags },
        }),
      );
    },
    [threadId, dispatch, messages],
  );

  const onDeleteMessage = useCallback(
    (messageId) => {
      return dispatch(
        deleteMessageEffect({
          MessageId: messageId,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(socketsGetMessagesByThreadIdEffect({ Id: threadId }));
    return () => dispatch(resetMessagesEffect());
  }, [threadId, dispatch]);

  // When user is opened some thread - notify backend about this
  useEffect(() => {
    dispatch(sendThreadOpenedEffect({ threadId }));
    return () => dispatch(sendThreadsClosedEffect());
  }, [threadId, dispatch]);

  return (
    <div testid="chat_content" className={styles.wrapper}>
      <SendMessageForm
        id="messagesDrawer"
        className={styles.sendMessageForm}
        onSend={onSendMessage}
        subheader={<Header threadId={threadId} />}
        content={<Messages threadId={threadId} list={messages} onDelete={onDeleteMessage} />}
      />
    </div>
  );
};

export default MessagesDrawerChatContent;
