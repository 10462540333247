import classNames from 'classnames';

import styles from './styles.module.scss';

interface IconsProps {
  className?: string;
  variant: string;
  testid?: string;
  onClick?: () => {};
}

const Icons = (props: IconsProps) => {
  const { className, variant, onClick, testid } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.PEOPLE: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#E66F3E" />
            <path
              d="M9.44955 10.1603C9.44955 9.53526 9.6349 8.92424 9.98216 8.40453C10.3294 7.88482 10.823 7.47976 11.4005 7.24056C11.9779 7.00137 12.6134 6.93878 13.2264 7.06073C13.8394 7.18267 14.4026 7.48366 14.8445 7.92564C15.2865 8.36761 15.5875 8.93073 15.7094 9.54377C15.8314 10.1568 15.7688 10.7922 15.5296 11.3697C15.2904 11.9472 14.8853 12.4408 14.3656 12.788C13.8459 13.1353 13.2349 13.3206 12.6099 13.3206C12.1948 13.3206 11.7839 13.2389 11.4005 13.0801C11.017 12.9212 10.6686 12.6885 10.3752 12.395C10.0817 12.1015 9.84893 11.7531 9.69011 11.3697C9.53129 10.9863 9.44955 10.5753 9.44955 10.1603ZM25 23.5173C25 20.8815 22.3368 18.7447 19.0515 18.7447C15.7663 18.7447 13.103 20.8815 13.103 23.5173C13.103 24.5058 16.0773 25 19.0515 25C22.0258 25 25 24.5058 25 23.5173ZM12.6099 20.2031C12.9972 20.2031 13.3842 20.1939 13.7641 20.1761C14.7984 19.1086 16.1472 18.4003 17.6132 18.1548C16.9015 17.8852 16.2795 17.4217 15.8177 16.8169C15.3558 16.212 15.0725 15.4899 14.9998 14.7324C14.2356 14.4464 13.4258 14.3012 12.6099 14.3038C9.51162 14.3038 7 16.319 7 18.8048C7 19.737 9.80493 20.2031 12.6099 20.2031ZM19.0515 17.7021C19.7143 17.7021 20.3622 17.5056 20.9133 17.1374C21.4643 16.7691 21.8939 16.2458 22.1475 15.6334C22.4011 15.0211 22.4675 14.3473 22.3382 13.6973C22.2089 13.0473 21.8897 12.4502 21.4211 11.9815C20.9524 11.5128 20.3553 11.1937 19.7053 11.0644C19.0552 10.9351 18.3814 11.0015 17.7691 11.2551C17.1568 11.5087 16.6334 11.9382 16.2652 12.4893C15.897 13.0404 15.7004 13.6883 15.7004 14.3511C15.7004 14.7911 15.7871 15.2269 15.9555 15.6335C16.1239 16.04 16.3708 16.4095 16.682 16.7206C16.9931 17.0318 17.3626 17.2786 17.7691 17.447C18.1757 17.6155 18.6115 17.7021 19.0515 17.7021Z"
              fill="white"
            />
          </svg>
        );
      }
      case Icons.PLUS: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="16" height="16" fill="none" />
            <path
              d="M8 3.33398V12.6673"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33203 8H12.6654"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.TIMELINE: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#E66F3E" />
            <path
              d="M9.44955 10.1603C9.44955 9.53526 9.6349 8.92424 9.98216 8.40453C10.3294 7.88482 10.823 7.47976 11.4005 7.24056C11.9779 7.00137 12.6134 6.93878 13.2264 7.06073C13.8394 7.18267 14.4026 7.48366 14.8445 7.92564C15.2865 8.36761 15.5875 8.93073 15.7094 9.54377C15.8314 10.1568 15.7688 10.7922 15.5296 11.3697C15.2904 11.9472 14.8853 12.4408 14.3656 12.788C13.8459 13.1353 13.2349 13.3206 12.6099 13.3206C12.1948 13.3206 11.7839 13.2389 11.4005 13.0801C11.017 12.9212 10.6686 12.6885 10.3752 12.395C10.0817 12.1015 9.84893 11.7531 9.69011 11.3697C9.53129 10.9863 9.44955 10.5753 9.44955 10.1603ZM25 23.5173C25 20.8815 22.3368 18.7447 19.0515 18.7447C15.7663 18.7447 13.103 20.8815 13.103 23.5173C13.103 24.5058 16.0773 25 19.0515 25C22.0258 25 25 24.5058 25 23.5173ZM12.6099 20.2031C12.9972 20.2031 13.3842 20.1939 13.7641 20.1761C14.7984 19.1086 16.1472 18.4003 17.6132 18.1548C16.9015 17.8852 16.2795 17.4217 15.8177 16.8169C15.3558 16.212 15.0725 15.4899 14.9998 14.7324C14.2356 14.4464 13.4258 14.3012 12.6099 14.3038C9.51162 14.3038 7 16.319 7 18.8048C7 19.737 9.80493 20.2031 12.6099 20.2031ZM19.0515 17.7021C19.7143 17.7021 20.3622 17.5056 20.9133 17.1374C21.4643 16.7691 21.8939 16.2458 22.1475 15.6334C22.4011 15.0211 22.4675 14.3473 22.3382 13.6973C22.2089 13.0473 21.8897 12.4502 21.4211 11.9815C20.9524 11.5128 20.3553 11.1937 19.7053 11.0644C19.0552 10.9351 18.3814 11.0015 17.7691 11.2551C17.1568 11.5087 16.6334 11.9382 16.2652 12.4893C15.897 13.0404 15.7004 13.6883 15.7004 14.3511C15.7004 14.7911 15.7871 15.2269 15.9555 15.6335C16.1239 16.04 16.3708 16.4095 16.682 16.7206C16.9931 17.0318 17.3626 17.2786 17.7691 17.447C18.1757 17.6155 18.6115 17.7021 19.0515 17.7021Z"
              fill="white"
            />
          </svg>
        );
      }
      case Icons.EDIT: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.33203 16.6663H6.66536L15.4154 7.91627C15.8574 7.47424 16.1057 6.87472 16.1057 6.2496C16.1057 5.62448 15.8574 5.02496 15.4154 4.58293C14.9733 4.14091 14.3738 3.89258 13.7487 3.89258C13.1236 3.89258 12.5241 4.14091 12.082 4.58293L3.33203 13.3329V16.6663Z"
              stroke="#f18154"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.25 5.41797L14.5833 8.7513"
              stroke="#f18154"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DELETE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 7H20"
              stroke="#f18154"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 11V17"
              stroke="#f18154"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 11V17"
              stroke="#f18154"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7"
              stroke="#f18154"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
              stroke="#f18154"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.CHECKMARK: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_16045_158636)">
              <path
                d="M4.99902 12L9.99902 17L19.999 7"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_16045_158636">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.ARROW_BOTTOM: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 6L8 10L4 6"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.CLOSE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 16L16 8" stroke="#163C5A" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M16 16L8 8" stroke="#163C5A" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.CommentDrawer: {
        return (
          <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.18174 13.0559L4.10181 13.4476L4.29966 12.9829L4.04312 12.5479L3.18174 13.0559ZM1.54135 16.9085L2.46142 17.3003H2.46142L1.54135 16.9085ZM2.18134 17.5709L1.82142 16.6379H1.82142L2.18134 17.5709ZM6.22503 16.0109L6.70731 15.1349L6.29947 14.9104L5.86511 15.078L6.22503 16.0109ZM17.0777 8.99805C17.0777 12.864 13.9437 15.998 10.0777 15.998V17.998C15.0483 17.998 19.0777 13.9686 19.0777 8.99805H17.0777ZM10.0777 1.99805C13.9437 1.99805 17.0777 5.13205 17.0777 8.99805H19.0777C19.0777 4.02748 15.0483 -0.00195312 10.0777 -0.00195312V1.99805ZM3.07772 8.99805C3.07772 5.13205 6.21173 1.99805 10.0777 1.99805V-0.00195312C5.10716 -0.00195312 1.07772 4.02748 1.07772 8.99805H3.07772ZM4.04312 12.5479C3.42984 11.5079 3.07772 10.2956 3.07772 8.99805H1.07772C1.07772 10.6626 1.53055 12.2245 2.32036 13.5638L4.04312 12.5479ZM2.46142 17.3003L4.10181 13.4476L2.26167 12.6641L0.621275 16.5168L2.46142 17.3003ZM1.82142 16.6379C2.23373 16.4788 2.63454 16.8937 2.46142 17.3003L0.621276 16.5168C0.101911 17.7366 1.30434 18.981 2.54127 18.5039L1.82142 16.6379ZM5.86511 15.078L1.82142 16.6379L2.54126 18.5039L6.58495 16.9439L5.86511 15.078ZM10.0777 15.998C8.85385 15.998 7.70614 15.6848 6.70731 15.1349L5.74275 16.8869C7.02954 17.5954 8.50811 17.998 10.0777 17.998V15.998Z"
              fill="#515151"
            />
          </svg>
        );
      }
      case Icons.ZoomPlus: {
        return (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 3.0598e-07C6.4477 2.81838e-07 6 0.44772 6 1L6 6L1 6C0.44772 6 -2.81838e-07 6.4477 -3.0598e-07 7C-3.30122e-07 7.5523 0.44772 8 1 8L6 8L6 13C6 13.5523 6.4477 14 7 14C7.5523 14 8 13.5523 8 13L8 8L13 8C13.5523 8 14 7.5523 14 7C14 6.4477 13.5523 6 13 6L8 6L8 1C8 0.44772 7.5523 3.30122e-07 7 3.0598e-07Z"
              fill="#262626"
            />
          </svg>
        );
      }
      case Icons.ZoomMinus: {
        return (
          <svg
            width="14"
            height="2"
            viewBox="0 0 14 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="14" height="2" rx="1" fill="#262626" />
          </svg>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icons, className)}>
      {getIcons()}
    </div>
  );
};

Icons.PEOPLE = 'people';
Icons.PLUS = 'plus';
Icons.TIMELINE = 'timeline';
Icons.EDIT = 'edit';
Icons.DELETE = 'delete';
Icons.CHECKMARK = 'checkmark';
Icons.ARROW_BOTTOM = 'arrowBottom';
Icons.CLOSE = 'close';
Icons.CommentDrawer = 'commentDrawer';
Icons.ZoomPlus = 'zoomPlus';
Icons.ZoomMinus = 'zoomMinus';

export default Icons;
