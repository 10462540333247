import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Icons = forwardRef((props, ref) => {
  const { className, variant, onClick } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.COMMENT: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.1237 16.0581L6.04377 16.4498L6.24163 15.9851L5.98506 15.5501L5.1237 16.0581ZM3.48275 19.912L2.56268 19.5203H2.56268L3.48275 19.912ZM4.12275 20.5744L4.48267 21.5074H4.48267L4.12275 20.5744ZM8.16834 19.0137L8.65043 18.1376L8.24266 17.9132L7.80841 18.0807L8.16834 19.0137ZM19.0195 12C19.0195 15.866 15.8855 19 12.0195 19V21C16.9901 21 21.0195 16.9706 21.0195 12H19.0195ZM12.0195 5C15.8855 5 19.0195 8.13401 19.0195 12H21.0195C21.0195 7.02944 16.9901 3 12.0195 3V5ZM5.01952 12C5.01952 8.13401 8.15352 5 12.0195 5V3C7.04896 3 3.01952 7.02944 3.01952 12H5.01952ZM5.98506 15.5501C5.37169 14.51 5.01952 13.2977 5.01952 12H3.01952C3.01952 13.6647 3.47242 15.2267 4.26234 16.5661L5.98506 15.5501ZM4.40283 20.3038L6.04377 16.4498L4.20363 15.6663L2.56268 19.5203L4.40283 20.3038ZM3.76283 19.6414C4.17514 19.4824 4.57595 19.8972 4.40283 20.3038L2.56268 19.5203C2.04332 20.7401 3.24575 21.9846 4.48267 21.5074L3.76283 19.6414ZM7.80841 18.0807L3.76283 19.6414L4.48267 21.5074L8.52826 19.9467L7.80841 18.0807ZM12.0195 19C10.7962 19 9.64894 18.687 8.65043 18.1376L7.68624 19.8898C8.97264 20.5977 10.4506 21 12.0195 21V19Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.5 12C9.5 12.8284 8.82843 13.5 8 13.5C7.17157 13.5 6.5 12.8284 6.5 12C6.5 11.1716 7.17157 10.5 8 10.5C8.82843 10.5 9.5 11.1716 9.5 12ZM13.5 12C13.5 12.8284 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1716 13.5 12ZM16 13.5C16.8284 13.5 17.5 12.8284 17.5 12C17.5 11.1716 16.8284 10.5 16 10.5C15.1716 10.5 14.5 11.1716 14.5 12C14.5 12.8284 15.1716 13.5 16 13.5Z"
              fill="white"
            />
          </svg>
        );
      }
      case Icons.PHONE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 3.99976H9L11 8.99976L8.5 10.4998C9.57096 12.6713 11.3285 14.4288 13.5 15.4998L15 12.9998L20 14.9998V18.9998C20 19.5302 19.7893 20.0389 19.4142 20.414C19.0391 20.789 18.5304 20.9998 18 20.9998C14.0993 20.7627 10.4202 19.1063 7.65683 16.3429C4.8935 13.5796 3.23705 9.90049 3 5.99976C3 5.46932 3.21071 4.96062 3.58579 4.58554C3.96086 4.21047 4.46957 3.99976 5 3.99976"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.EMAIL: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 5.00037H5C3.89543 5.00037 3 5.8958 3 7.00037V17.0004C3 18.1049 3.89543 19.0004 5 19.0004H19C20.1046 19.0004 21 18.1049 21 17.0004V7.00037C21 5.8958 20.1046 5.00037 19 5.00037Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 6.99976L12 12.9998L21 6.99976"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.WEBSITE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.9297 16C18.1887 17.2811 17.1026 18.3375 15.7986 19.0424C16.354 18.2618 16.9304 17.2476 17.354 16H18.9297ZM17.8458 14C17.9442 13.3752 18 12.7085 18 12C18 11.2915 17.9442 10.6248 17.8458 10H19.748C19.9125 10.6392 20 11.3094 20 12C20 12.6906 19.9125 13.3608 19.748 14H17.8458ZM15.8392 14C15.9417 13.3732 16 12.7063 16 12C16 11.2937 15.9417 10.6268 15.8392 10H13V14H15.8392ZM13 16H15.3321C14.7154 17.7728 13.7891 19.0922 13.0695 19.9291C13.0463 19.9322 13.0232 19.9352 13 19.9381V16ZM11 14V10H8.16083C8.0583 10.6268 8 11.2937 8 12C8 12.7063 8.0583 13.3732 8.16083 14H11ZM8.66794 16H11V19.9381C10.9768 19.9352 10.9537 19.9322 10.9305 19.9291C10.2109 19.0922 9.28456 17.7728 8.66794 16ZM6.15415 14C6.05577 13.3752 6 12.7085 6 12C6 11.2915 6.05577 10.6248 6.15415 10H4.25203C4.08751 10.6392 4 11.3094 4 12C4 12.6906 4.08751 13.3608 4.25204 14H6.15415ZM5.07026 16H6.64603C7.06963 17.2476 7.64599 18.2618 8.20142 19.0424C6.89738 18.3375 5.81131 17.2811 5.07026 16ZM18.9297 8H17.354C16.9304 6.75238 16.354 5.73825 15.7986 4.9576C17.1026 5.66246 18.1887 6.71895 18.9297 8ZM13 8H15.3321C14.7154 6.22723 13.7891 4.90784 13.0695 4.07087C13.0463 4.06778 13.0232 4.06479 13 4.06189V8ZM11 4.06189V8H8.66795C9.28456 6.22723 10.2109 4.90784 10.9305 4.07087C10.9537 4.06778 10.9768 4.06479 11 4.06189ZM8.20141 4.9576C7.64598 5.73825 7.06962 6.75238 6.64603 8H5.07026C5.8113 6.71895 6.89738 5.66246 8.20141 4.9576Z"
              fill="#FF576D"
            />
          </svg>
        );
      }
      case Icons.STAR: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0016 17.7505L5.82963 20.9955L7.00863 14.1225L2.01562 9.25549L8.91563 8.25349L12.0016 2.00049L15.0876 8.25349L21.9876 9.25549L16.9946 14.1225L18.1736 20.9955L12.0016 17.7505Z"
              fill="#FF576D"
              stroke="#FF576D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.FILE: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.666 4V9.33333C18.666 9.68695 18.8065 10.0261 19.0565 10.2761C19.3066 10.5262 19.6457 10.6667 19.9993 10.6667H25.3327"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.666 28H9.33268C8.62544 28 7.94716 27.719 7.44706 27.219C6.94697 26.7189 6.66602 26.0406 6.66602 25.3333V6.66667C6.66602 5.95942 6.94697 5.28115 7.44706 4.78105C7.94716 4.28095 8.62544 4 9.33268 4H18.666L25.3327 10.6667V25.3333C25.3327 26.0406 25.0517 26.7189 24.5516 27.219C24.0515 27.719 23.3733 28 22.666 28Z"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ACTIONS_DOTS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 13.0005C5.55228 13.0005 6 12.5528 6 12.0005C6 11.4482 5.55228 11.0005 5 11.0005C4.44772 11.0005 4 11.4482 4 12.0005C4 12.5528 4.44772 13.0005 5 13.0005Z"
              fill="#303030"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 13.0005C12.5523 13.0005 13 12.5528 13 12.0005C13 11.4482 12.5523 11.0005 12 11.0005C11.4477 11.0005 11 11.4482 11 12.0005C11 12.5528 11.4477 13.0005 12 13.0005Z"
              fill="#303030"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 13.0005C19.5523 13.0005 20 12.5528 20 12.0005C20 11.4482 19.5523 11.0005 19 11.0005C18.4477 11.0005 18 11.4482 18 12.0005C18 12.5528 18.4477 13.0005 19 13.0005Z"
              fill="#303030"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ARCHIVE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.54415 5.36754L3.05132 12.8461C3.01733 12.948 3 13.0548 3 13.1623V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18V13.1623C21 13.0548 20.9827 12.948 20.9487 12.8461L18.4558 5.36754C18.1836 4.55086 17.4193 4 16.5585 4H7.44152C6.58066 4 5.81638 4.55086 5.54415 5.36754Z"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 10H8.5V10.5C8.5 12.433 10.067 14 12 14V14C13.933 14 15.5 12.433 15.5 10.5V10H19"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.UPLOAD: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.33594 14.166V15.8327C3.33594 16.2747 3.51153 16.6986 3.82409 17.0112C4.13665 17.3238 4.56058 17.4994 5.0026 17.4994H15.0026C15.4446 17.4994 15.8686 17.3238 16.1811 17.0112C16.4937 16.6986 16.6693 16.2747 16.6693 15.8327V14.166"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.83594 7.50065L10.0026 3.33398L14.1693 7.50065"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 3.33398V13.334"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ADDRESS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.657 16.6572L13.414 20.9002C13.2284 21.0859 13.0081 21.2333 12.7656 21.3338C12.523 21.4344 12.2631 21.4861 12.0005 21.4861C11.738 21.4861 11.478 21.4344 11.2354 21.3338C10.9929 21.2333 10.7726 21.0859 10.587 20.9002L6.343 16.6572C5.22423 15.5384 4.46234 14.1129 4.15369 12.5611C3.84504 11.0092 4.00349 9.40071 4.60901 7.93893C5.21452 6.47714 6.2399 5.22774 7.55549 4.3487C8.87107 3.46967 10.4178 3.00049 12 3.00049C13.5822 3.00049 15.1289 3.46967 16.4445 4.3487C17.7601 5.22774 18.7855 6.47714 19.391 7.93893C19.9965 9.40071 20.155 11.0092 19.8463 12.5611C19.5377 14.1129 18.7758 15.5384 17.657 16.6572V16.6572Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DOWNLOAD: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 14V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V14"
              stroke="#163C5A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 10L12 14L16 10"
              stroke="#163C5A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 14V5"
              stroke="#163C5A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.EXPAND: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 6L14 2M14 2H10M14 2V6"
              stroke="#163C5A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 10L2 14M2 14H6M2 14V10"
              stroke="#163C5A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.PREVIEW: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 12.6671C2.91212 12.1405 3.94678 11.8633 5 11.8633C6.05322 11.8633 7.08788 12.1405 8 12.6671C8.91212 12.1405 9.94678 11.8633 11 11.8633C12.0532 11.8633 13.0879 12.1405 14 12.6671"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 3.99916C2.91212 3.47255 3.94678 3.19531 5 3.19531C6.05322 3.19531 7.08788 3.47255 8 3.99916C8.91212 3.47255 9.94678 3.19531 11 3.19531C12.0532 3.19531 13.0879 3.47255 14 3.99916"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 4V12.6667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 4V12.6667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 4V12.6667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.OPTIONS_DELETE: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_11240_162721)">
              <path
                d="M2.66797 4.66602H13.3346"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.66797 7.33398V11.334"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.33203 7.33398V11.334"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.33203 4.66602L3.9987 12.666C3.9987 13.0196 4.13917 13.3588 4.38922 13.6088C4.63927 13.8589 4.97841 13.9993 5.33203 13.9993H10.6654C11.019 13.9993 11.3581 13.8589 11.6082 13.6088C11.8582 13.3588 11.9987 13.0196 11.9987 12.666L12.6654 4.66602"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_11240_162721">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.OPTIONS_DOWNLOAD: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66699 11.332V12.6654C2.66699 13.019 2.80747 13.3581 3.05752 13.6082C3.30757 13.8582 3.6467 13.9987 4.00033 13.9987H12.0003C12.3539 13.9987 12.6931 13.8582 12.9431 13.6082C13.1932 13.3581 13.3337 13.019 13.3337 12.6654V11.332"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.66699 7.33203L8.00033 10.6654L11.3337 7.33203"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 2.66797V10.668"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div ref={ref} onClick={onClick} className={classNames(styles.icons, className)}>
      {getIcons()}
    </div>
  );
});

Icons.COMMENT = 'comment';
Icons.PHONE = 'phone';
Icons.EMAIL = 'email';
Icons.WEBSITE = 'website';
Icons.STAR = 'star';
Icons.FILE = 'file';
Icons.ACTIONS_DOTS = 'actionsDots';
Icons.ARCHIVE = 'archive';
Icons.UPLOAD = 'upload';
Icons.ADDRESS = 'address';
Icons.DOWNLOAD = 'download';
Icons.OPTIONS_DOWNLOAD = 'optionsDownload';
Icons.EXPAND = 'expand';
Icons.PREVIEW = 'preview';
Icons.OPTIONS_DELETE = 'optionsDelete';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

Icons.defaultProps = {
  className: '',
  variant: undefined,
  onClick: () => {},
};

export default Icons;
