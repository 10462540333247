import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageWrapper } from 'components-antd';
import { SmartLeftSidebar, SmartRightSidebar } from 'pages/FormBuilder';
import {
  getSmartFormBuilderEffect,
  resetSmartFormBuilderEffect,
  updateSmartFormMetaEffect,
} from 'store/effects/formBuilder';
import { getSmartFormMetaSelect } from 'store/selectors/formBuilder';
import { PDFViewer } from 'pages/FormBuilder/components/PDFViewer';
import { FormFooter } from 'pages/FormBuilder/components';
import { PDFFields } from 'types';

import styles from './styles.module.scss';

export const EditSmartForm = ({ versionId }) => {
  const dispatch = useDispatch();

  const { link, questions } = useSelector(getSmartFormMetaSelect);

  useEffect(() => {
    return () => {
      dispatch(resetSmartFormBuilderEffect());
    };
  }, []);

  useEffect(() => {
    if (versionId) dispatch(getSmartFormBuilderEffect({ versionId }));
  }, [versionId]);

  const { highlightedFields } = useSelector(getSmartFormMetaSelect);

  const footer = () => <FormFooter smartForm={true} />;

  const onFieldClick = (field: PDFFields) => {
    if (!questions.length) return;
    const questionIndex = questions.findIndex((question) =>
      question?.Fields?.some((f) => f.id === field.id),
    );

    if (questionIndex >= 0) {
      dispatch(
        updateSmartFormMetaEffect({
          activeQuestionIndex: questionIndex,
          highlightedFields: [field.id],
        }),
      );
    }
  };

  return (
    <PageWrapper
      SiderComponent={SmartLeftSidebar}
      RightSiderComponent={SmartRightSidebar}
      mainPageContentStyle={styles.questionContainer}
      footerStyles={styles.footerStyles}
      Footer={versionId && footer}
    >
      <PDFViewer
        highlightedFields={highlightedFields}
        Url={link}
        questions={questions as any}
        onFieldClick={onFieldClick}
        mode="View"
      />
    </PageWrapper>
  );
};
