import * as XLSX from 'xlsx';

export const readExcelFile = (
  e: any,
  callback,
  fileNumber: number = 0,
  sheetNumber: number = 0,
) => {
  const selectedFile = e.target.files[fileNumber];
  let result: any = null;
  if (selectedFile) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(selectedFile);
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: 'buffer' });
      const sheetName = workbook.SheetNames[sheetNumber];
      const worksheet = workbook.Sheets[sheetName];
      result = XLSX.utils.sheet_to_json(worksheet);
      callback(result);
    };
  }
  e.target.value = '';
};
