import classNames from 'classnames';
import { Radio } from 'components-antd';
import styles from './styles.module.scss';

export const SignFormToggleRow = ({
  isSequentialSignature,
  handleSequentialSigning,
  disabled = false,
  className = '',
}) => {
  return (
    <Radio.Group
      onChange={handleSequentialSigning}
      value={isSequentialSignature}
      disabled={disabled}
      className={classNames(styles.signSequence, 'mosaikCheckbox', className)}
    >
      <Radio value={false} className={styles.radio}>
        Sign at the same time
      </Radio>
      <Radio value={true} className={styles.radio}>
        Sign sequentially
      </Radio>
    </Radio.Group>
  );
};
