import { ApprovalAction, ApprovalStatus, AppointmentStatus } from 'types';
import { ICON_VARIANT_TYPE, Icons } from '../../Icons';

import styles from './styles.module.scss';

export const Footer = ({ processAction, approvalStatus, appointmentStatus }) => {
  return (
    <div>
      {appointmentStatus === AppointmentStatus.Pending ? (
        <div className={styles.footer}>
          {approvalStatus === ApprovalStatus.Pending && (
            <div
              testid="approve_button"
              className={styles.approve}
              onClick={() => {
                processAction(ApprovalAction.Approve);
              }}
            >
              <Icons variant={ICON_VARIANT_TYPE.APPROVE} />
              <span>Approve</span>
            </div>
          )}
          {approvalStatus === ApprovalStatus.Pending && (
            <div
              testid="decline_button"
              className={styles.decline}
              onClick={() => {
                processAction(ApprovalAction.Decline);
              }}
            >
              <Icons variant={ICON_VARIANT_TYPE.CANCEL} />
              <span>Decline</span>
            </div>
          )}
          {approvalStatus === ApprovalStatus.Approved && (
            <div
              testid="cancel_button"
              className={styles.cancel}
              onClick={() => {
                processAction(ApprovalAction.Cancel);
              }}
            >
              <Icons variant={ICON_VARIANT_TYPE.CANCEL} />
              <span>Cancel Showing</span>
            </div>
          )}
        </div>
      ) : (
        <p className={styles.appointmentDisclaimer}>Appointment is {appointmentStatus}!</p>
      )}
    </div>
  );
};
