import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Question } from 'pages/RequestQuote/components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { Select } from 'components';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { Continue } from '../../Continue';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';

import styles from './styles.module.scss';
import { useEffect, useMemo } from 'react';
import { getProjectCategoriesDataArray } from 'store/selectors/projects';
import { requestGetProjectCategories } from 'store/effects/projects';

interface ProjectCategoryProps {
  className: string;
  onNext: (event) => void;
  currentQuestionId: typeof transactionPreFormQuestionsIds[keyof typeof transactionPreFormQuestionsIds];
}

export const ProjectCategory = ({ className, currentQuestionId, onNext }: ProjectCategoryProps) => {
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const projectCategories = useSelector(getProjectCategoriesDataArray);

  useEffect(() => {
    dispatch(requestGetProjectCategories({}, { silent: true }));
  }, []);

  const onAnswerHandler = (answer) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.projectCategory]: answer,
      }),
    );
  };

  const onNextHandler = (event) => {
    if (currentQuestionId === transactionPreFormQuestionsIds.projectCategory) {
      onNext(event);
    }
  };

  const projectCategoriesAsOptions = useMemo(
    () => projectCategories.map((pc) => ({ value: pc.Id, name: pc.Category })),
    [projectCategories],
  );

  return (
    <div testid="what_project_category" className={classNames(styles.container, className)}>
      <Question>What category does this project belong to?</Question>
      <AnswersContainer>
        <Select
          value={preForm?.[transactionPreFormQuestionsIds.projectCategory]}
          onSelect={(_, { value }) => onAnswerHandler(value)}
          options={projectCategoriesAsOptions}
          className={{
            wrapper: styles.projectCategory,
          }}
          placeholder={'Choose category'}
          isArrowIcon={true}
          variant={Select.LIGHT_FULL}
        />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onNextHandler} />
      </ButtonsContainer>
    </div>
  );
};
