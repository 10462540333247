import { createSelector } from 'reselect';

const localState = ({ clientDashboard }) => clientDashboard;

export const getClientDashboardPropertySelector = createSelector(
  localState,
  ({ propertyStats }) => propertyStats,
);

export const getClientDashboardNewMatchesSelector = createSelector(
  localState,
  ({ newMatches }) => newMatches,
);

export const getClientDashboardAgentSuggestionsSelector = createSelector(
  localState,
  ({ agentSuggestions }) => agentSuggestions,
);

export const getClientDashboardAgentCommentsSelector = createSelector(
  localState,
  ({ agentComments }) => agentComments,
);

export const getClientDashboardOpenHousesSelector = createSelector(
  localState,
  ({ openHouses }) => openHouses,
);

export const getClientDashboardPriceReductionsSelector = createSelector(
  localState,
  ({ priceReductions }) => priceReductions,
);

export const getClientDashboardBackOnMarketsSelector = createSelector(
  localState,
  ({ backOnMarkets }) => backOnMarkets,
);

export const getClientDashboardMyTransactionsSelector = createSelector(
  localState,
  ({ myTransactions }) => myTransactions,
);

export const getClientDashboardShowingsSelector = createSelector(
  localState,
  ({ upcomingShowings }) => upcomingShowings,
);

export const getClientDashboardNeedsAttentionSelector = createSelector(
  localState,
  ({ needsAttention }) => needsAttention,
);

export const getClientDashboardAddressesValuationSelector = createSelector(
  localState,
  ({ addressesValuation }) => addressesValuation,
);
