import { handleActions } from 'redux-actions';
import { get, reduce, flow } from 'lodash-es';
import { socketsResetAction } from 'store/actions/sockets';
import {
  socketsStoreEditedMessageAction,
  socketsStoreDeletedMessageAction,
  socketsStoreNewMessageAction,
} from 'store/actions/sockets/messages';
import {
  socketsStoreInitThreadsAction,
  socketsStoreNewThreadAction,
  socketsStoreRemovedFromThreadAction,
  socketsStoreUpdatedThreadAction,
  socketsStoreParticipantOnlineStatusAction,
  socketsMarkThreadAsReadAction,
  socketsChangeThreadArchiveStatusAction,
} from 'store/actions/sockets/threads';
import {
  socketsStoreNewTransactionMessageAction,
  socketsStoreEditedTransactionMessageAction,
} from 'store/actions/sockets/transactionMessages';
import { socketsStoreNewTransactionTaskMessageAction } from 'store/actions/sockets/transactionTaskMessages';

const initialData = {};

export default handleActions(
  {
    [socketsStoreInitThreadsAction]: flow(
      (state, { payload }) => get(payload, 'data') || [],
      (threadsList) =>
        reduce(
          threadsList,
          (acc, thread) => {
            acc[thread.Id] = thread;
            return acc;
          },
          {},
        ),
    ),
    [socketsStoreRemovedFromThreadAction]: (state, { payload }) => {
      const data = get(payload, 'data');
      const updatedThreads = { ...state };
      delete updatedThreads[data];

      return {
        ...updatedThreads,
      };
    },
    [socketsStoreNewThreadAction]: (state, { payload }) => {
      const data = get(payload, 'data');

      return {
        ...state,
        [data.Id]: data,
      };
    },
    [socketsStoreUpdatedThreadAction]: (state, { payload }) => {
      const data = get(payload, 'data');

      return {
        ...state,
        [data.Id]: data,
      };
    },
    [socketsStoreParticipantOnlineStatusAction]: (state, { payload }) => {
      const thread = get(state, payload.threadId);
      const participant = thread.Participants.find((p) => p.Id === payload.participantId);
      const participantIndex = thread.Participants.findIndex((p) => p.Id === payload.participantId);
      const Participants = [...thread.Participants];

      Participants[participantIndex] = {
        ...participant,
        LastOnlineTime: payload.lastOnline,
      };

      return {
        ...state,
        [payload.threadId]: {
          ...thread,
          Participants,
        },
      };
    },
    [socketsStoreNewMessageAction]: (state, { payload: { data, currentThread } }) => {
      const thread = get(state, data.MessageThreadId);

      return {
        ...state,
        [data.MessageThreadId]: {
          ...thread,
          LastMessage: data.Text,
          LastMessageMeta: data.MessageMeta,
          LastMessageCreatedDate: data.CreatedDate,
          IsUnread: !currentThread,
        },
      };
    },
    [socketsStoreNewTransactionMessageAction]: (state, { payload: { data, currentThread } }) => {
      const thread = get(state, data.MessageThreadId);

      return {
        ...state,
        [data.MessageThreadId]: {
          ...thread,
          LastMessage: data.Text,
          LastMessageCreatedDate: data.CreatedDate,
          IsUnread: !currentThread,
        },
      };
    },
    [socketsStoreEditedTransactionMessageAction]: (state, { payload }) => {
      const messageThreadId = get(payload, 'data.MessageThread.Id');
      const text = get(payload, 'data.Text');
      const isLastMessage = get(payload, 'isLastMessage', false);

      return {
        ...state,
        [messageThreadId]: {
          ...(state?.[messageThreadId] || {}),
          ...(isLastMessage ? { LastMessage: text } : {}),
        },
      };
    },
    [socketsStoreEditedMessageAction]: (state, { payload }) => {
      const messageThreadId = get(payload, 'data.MessageThread.Id');
      const text = get(payload, 'data.Text');
      const isLastMessage = get(payload, 'isLastMessage', false);

      return {
        ...state,
        [messageThreadId]: {
          ...(state?.[messageThreadId] || {}),
          ...(isLastMessage ? { LastMessage: text } : {}),
        },
      };
    },
    [socketsStoreDeletedMessageAction]: (state, { payload }) => {
      const messageThreadId = get(payload, 'data.MessageThread.Id');
      const text = get(payload, 'data.Text');
      const lastMessage = get(payload, 'lastMessage');

      return {
        ...state,
        [messageThreadId]: {
          ...(state?.[messageThreadId] || {}),
          ...(lastMessage
            ? { LastMessage: lastMessage.Text, LastMessageCreatedDate: lastMessage.CreatedDate }
            : {}),
        },
      };
    },
    [socketsStoreNewTransactionTaskMessageAction]: (
      state,
      { payload: { data, currentThread } },
    ) => {
      const thread = get(state, data.MessageThreadId);

      return {
        ...state,
        [data.MessageThreadId]: {
          ...thread,
          LastMessage: data.Text,
          LastMessageCreatedDate: data.CreatedDate,
          IsUnread: !currentThread,
        },
      };
    },
    [socketsMarkThreadAsReadAction]: (state, { payload: { threadId } }) => {
      const thread = get(state, threadId);

      return {
        ...state,
        [threadId]: {
          ...thread,
          IsUnread: false,
        },
      };
    },
    [socketsChangeThreadArchiveStatusAction]: (state, { payload: { threadIds, archived } }) => ({
      ...state,
      ...reduce(
        threadIds,
        (acc, threadId) => {
          acc[threadId].IsArchived = archived;
          return acc;
        },
        state,
      ),
    }),
    [socketsResetAction]: () => initialData,
  },
  initialData,
);
