import Api from '../core/api';
import {
  requestGetTaskAggregate,
  resetTaskAggregateAction,
  setShowCommentIconAction,
  updateUnReadCommentsAction,
  updateMultipleTasksAction,
  deleteMultipleTasksAction,
  setTransactionTasksSearchAction,
  setTransactionsTasksArchiveAction,
} from 'store/actions/taskAggregate';
import { getTaskAggregate } from 'api/taskAggregate';
import { deleteMultipleTasks, updateMultipleTasks } from 'api/transactions';

export const getTaskAggregateEffect = (
  cfg = {},
  options = {
    silent: false,
  },
  cb = () => {},
) => {
  const requestParams = { action: requestGetTaskAggregate, method: getTaskAggregate };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const resetTaskAggregateEffect = () => (dispatch) => {
  dispatch(resetTaskAggregateAction());
};

export const setShowCommentIconEffect = (cfg) => (dispatch) => {
  dispatch(setShowCommentIconAction(cfg));
};
export const updateUnReadCommentsEffect = (cfg) => (dispatch) => {
  dispatch(updateUnReadCommentsAction(cfg));
};

export const updateMultipleTasksEffect = (cfg, cb) => {
  const requestParams = { action: updateMultipleTasksAction, method: updateMultipleTasks };
  const sendRequest = Api.execBase(requestParams);
  return sendRequest(cfg, {}, cb);
};

export const deleteMultipleTasksEffect = (cfg, cb) => {
  const requestParams = { action: deleteMultipleTasksAction, method: deleteMultipleTasks };
  const sendRequest = Api.execBase(requestParams);
  return sendRequest(cfg, {}, cb);
};

export const searchTransactionsTasksEffect =
  ({ search }) =>
  (dispatch) => {
    dispatch(setTransactionTasksSearchAction(search));
  };

export const resetTransactionsTasksSearchEffect = () => (dispatch) => {
  dispatch(searchTransactionsTasksEffect({ search: '' }));
};

export const setTransactionsTasksIsArchiveEffect = (cfg) => (dispatch) => {
  dispatch(setTransactionsTasksArchiveAction(cfg));
};
