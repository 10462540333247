import { useState } from 'react';
import { Switch, Select } from 'components-antd';
import styles from './styles.module.scss';
import getGeoLocationsAsString from 'helpers/getGeoLocationsAsString';
import { configureKit } from 'api/kits';
import { KitStateConfigurationUpdateType } from '../../DataTypes';
import { useSelector } from 'react-redux';
import { getUserSelector } from 'store/selectors/user';
import { Tooltip } from 'antd';
import { kitsTimeFrequencies } from '../../DataTypes';
import moment from 'moment';

type KitsDataRowPropsType = {
  data;
  masterControl: boolean;
  kitClickHandler: Function;
  owner: boolean;
  updateKitsConfigurationState: Function;
};

function KitsDataRow(props: KitsDataRowPropsType) {
  const { data: user } = useSelector(getUserSelector);
  const getFormattedFrequency = (frequency) => {
    let frequencyString = frequency.FrequencyName;
    if (frequency.ClosingDateRelated) {
      frequencyString += ' (CD';
      if (frequency.OffsetOperator) frequencyString += frequency.OffsetOperator;
      if (frequency.OffsetValue) frequencyString += frequency.OffsetValue;
      if (frequency.OffsetUnit) frequencyString += frequency.OffsetUnit;
      frequencyString += ')';
    }
    if (frequency.HasSpecificDates && frequency.SpecificDates?.length) {
      frequencyString += ' (';
      frequencyString += frequency.SpecificDates.join();
      frequencyString += ')';
    }
    return frequencyString;
  };

  const getAgentRelevantLocation = () => {
    try {
      const kitLocations = getGeoLocationsAsString(Locations.AreasOfOperation, false);
      if (user?.Agent?.AreasOfOperation?.length) {
        const AreasOfOperations = user?.Agent?.AreasOfOperation;
        const AreasOfOperationCities = AreasOfOperations.map((area) => area.City);
        const agentRelevantLocations = (kitLocations as string[]).filter((location) =>
          AreasOfOperationCities.includes(location),
        );
        return agentRelevantLocations.join(', ');
      }
      return Locations.join(', ');
    } catch (e) {
      return '-';
    }
  };

  const [enabled, setEnabled] = useState<boolean>(props.data.Enabled);
  const [kitFrequency, setKitFrequency] = useState<string>(
    getFormattedFrequency(props.data.CurrentFrequency),
  );
  const [currentFrequencyId, setCurrentFrequencyId] = useState<number>(
    props.data.CurrentFrequency.Id,
  );
  const { Kits, Locations, permittedTo, nextRunDate } = props.data;

  const FormattedEligibleFrequencies = Kits.EligibleFrequencies.map((frequency) => {
    const name = getFormattedFrequency(frequency);

    return {
      name: name,
      freqId: frequency.Id,
    };
  });

  const getToolTipText = () => {
    let toolTipText = '';
    const EligibleFrequencies = Kits?.EligibleFrequencies;
    const targetFrequency = EligibleFrequencies.find((freq) => freq.Id === currentFrequencyId);
    if (targetFrequency) {
      if (targetFrequency.ClosingDateRelated) {
        let timeUnit = '';
        switch (targetFrequency.OffsetUnit) {
          case 'd':
            timeUnit = 'days';
            break;
          case 'm':
            timeUnit = 'months';
            break;
          case 'y':
            timeUnit = 'years';
            break;
          default:
            break;
        }
        switch (targetFrequency.FrequencyName) {
          case kitsTimeFrequencies.Biannually:
            toolTipText = `Email will be sent every 2 years ${
              targetFrequency.OffsetValue
            } ${timeUnit} ${
              targetFrequency.OffsetOperator === '+' ? 'after' : 'before'
            } closing date.`;
            break;
          case kitsTimeFrequencies.OneTime:
            toolTipText = `Email will be sent only once ${
              targetFrequency.OffsetValue
            } ${timeUnit} ${
              targetFrequency.OffsetOperator === '+' ? 'after' : 'before'
            } closing date.`;
            break;
          case kitsTimeFrequencies.Yearly:
            toolTipText = `Email will be sent every year ${
              targetFrequency.OffsetValue
            } ${timeUnit} ${
              targetFrequency.OffsetOperator === '+' ? 'after' : 'before'
            } closing date.`;
            break;
          default:
            break;
        }
      } else if (targetFrequency.HasSpecificDates) {
        switch (targetFrequency.FrequencyName) {
          case kitsTimeFrequencies.Biannually:
            toolTipText =
              'Email will be sent every 2 years on ' +
              targetFrequency.SpecificDates.join(', ') +
              '.';
            break;
          case kitsTimeFrequencies.Monthly:
            toolTipText =
              'Email will be sent every month on ' + targetFrequency.SpecificDates.join(', ') + '.';
            break;
          case kitsTimeFrequencies.OneTime:
            toolTipText =
              'Email will be sent only once on ' + targetFrequency.SpecificDates.join(', ') + '.';
            break;
          case kitsTimeFrequencies.Yearly:
            toolTipText =
              'Email will be sent every year on ' + targetFrequency.SpecificDates.join(', ') + '.';
            break;
          default:
            break;
        }
      } else {
        switch (targetFrequency.FrequencyName) {
          case kitsTimeFrequencies.Biannually:
            toolTipText = 'Email will be sent every 2 years.';
            break;
          case kitsTimeFrequencies.Daily:
            toolTipText = 'Email will be sent day.';
            break;
          case kitsTimeFrequencies.Monthly:
            toolTipText = 'Email will be sent every month.';
            break;
          case kitsTimeFrequencies.AdHoc:
            toolTipText = 'Email will be sent by the agent on an ad hoc basis.';
            break;
          case kitsTimeFrequencies.OneTime:
            toolTipText = 'Email will be sent only once.';
            break;
          case kitsTimeFrequencies.Weekly:
            toolTipText = 'Email will be sent every week.';
            break;
          case kitsTimeFrequencies.Yearly:
            toolTipText = 'Email will be sent every year,';
            break;
          default:
            break;
        }
      }
    }
    return toolTipText;
  };

  const switchHandler = (v: boolean) => {
    configureKit(Kits.Id, { UUID: props.data?.UUID, Enabled: v });
    const updates: KitStateConfigurationUpdateType = {
      enabled: v,
    };
    props.updateKitsConfigurationState(Kits.Id, updates);
  };

  const frequencyHandler = (val) => {
    setCurrentFrequencyId(Number(val));
    setKitFrequency(val);
    configureKit(Kits.Id, { UUID: props.data?.UUID, FrequencyId: val });
    const updates: KitStateConfigurationUpdateType = {
      frequencyId: Number(val),
    };
    props.updateKitsConfigurationState(Kits.Id, updates);
  };

  return (
    <tr className={styles.kitsRowData}>
      <td>
        <div className={styles.kitName}>
          <div
            onClick={(e) => {
              props.kitClickHandler(Kits.Id, Locations?.UUID ? Locations.UUID : undefined);
            }}
          >
            {Kits.Name}
          </div>
        </div>
      </td>
      <td>
        <div className={styles.text}>{Kits.Category}</div>
      </td>
      <td>
        {FormattedEligibleFrequencies?.length > 1 ? (
          <div className={styles.options}>
            <Tooltip title={getToolTipText()}>
              <Select
                disabled={props.masterControl ? false : true}
                defaultValue={currentFrequencyId}
                className={styles.select}
                options={FormattedEligibleFrequencies?.map((freq) => ({
                  label: freq.name,
                  value: freq.freqId,
                  key: `freq-${freq.freqId}`,
                }))}
                onChange={(val) => {
                  frequencyHandler(val);
                }}
              />
            </Tooltip>
          </div>
        ) : (
          <Tooltip title={getToolTipText()}>
            <div className={styles.optionText}>{FormattedEligibleFrequencies[0].name}</div>
          </Tooltip>
        )}
      </td>
      <td>
        <div className={styles.text}>
          {Kits.EligiblePropertyTypes.length
            ? Kits.EligiblePropertyTypes.replaceAll('{', '').replaceAll('}', '').replaceAll('"', '')
            : '-'}
        </div>
      </td>
      <td>
        <div className={styles.text}>
          {Locations?.AreasOfOperation?.length ? getAgentRelevantLocation() : '-'}
        </div>
      </td>
      {props.owner ? (
        <td>
          <div className={styles.text}>{permittedTo || '-'}</div>
        </td>
      ) : (
        <></>
      )}
      <td>
        <div className={styles.text}>
          {nextRunDate ? moment(nextRunDate).format('M/D/YYYY') : '-'}
        </div>
      </td>
      <td>
        <div className={styles.switchCell}>
          <Switch
            disabled={props.masterControl ? false : true}
            className={styles.switch}
            checked={enabled}
            defaultChecked={props.data.Enable}
            onChange={(v) => {
              setEnabled(v);
              switchHandler(v);
            }}
          />
        </div>
      </td>
    </tr>
  );
}

export default KitsDataRow;
