import styles from './styles.module.scss';
import HomePrefsListItem from '../HomePrefsListItem';
import { FC, useState } from 'react';
import { map } from 'lodash-es';
import { IHomePrefsListProps, IParking, IconsVariant } from '../types';
import SelectComponent from '../SelectComponent';
import ParkingComponent from '../ParkingComponent';
import CabinetComponent from '../CabinetComponent';
import MechanicalComponent from '../MechanicalComponent';
import { Button } from 'components';
import {
  IMPORTANCE,
  OUTDOOR_TYPES,
  LAYOUT_TYPES,
  PROPERTY_CONDITION_TYPES,
  FLOORING_TYPES,
  KITCHEN_FEATURES_TYPES,
  VIEW_TYPES,
  INTERIOR_TYPES,
  AMENITIES_TYPES,
  KITCHEN_CABINET_TYPES,
  RVParking,
  MECHANICAL_HEAT_SOURCE,
  MECHANICAL_WATER_SOURCE,
  MECHANICAL_WASTE,
  MECHANICAL_ENERGY,
  getDefaultValues,
  MECHANIC_ALL,
} from '../../constants';
import ArrowRightFull from 'components/Icons/ArrowRightFull';
import { onBoardingWizardEffect } from 'store/effects';
import { useDispatch, useSelector } from 'react-redux';
import { PARKING_GARAGE_TYPES, PREF_TYPE, convertPrefs } from 'settings/constants/preferences';
import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { addSearchInstance } from 'store/effects/clientInstances';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { PENDING } from 'settings/constants/apiState';

function camelCaseToNormal(str) {
  const result = str.replace(/([A-Z])/g, ' $1').toLowerCase();
  return result.replace(/\b\w/g, (char) => char.toUpperCase());
}

const HomePrefsList: FC<IHomePrefsListProps> = ({ onNext }) => {
  const dispatch = useDispatch();
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const {
    HomePrefs: homePrefersDefault,
    OutdoorPrefs: outdoorSpaceDefault,
    AmenitiesPrefs: amenitiesPrefersDefault,
    Parking: parkingDefault,
    ViewPrefs: viewPrefersDefault,
    InteriorStylesPrefs: stylePrefsDefault,
  } = defaultClientSearch || {};
  const { onBoarding } = useSelector(getOnBoardingData);
  const isPending = onBoarding.state === PENDING;

  const [expanded, setExpanded] = useState('');
  const [kitchenFeatures, setKitchenFeatures] = useState(
    getDefaultValues(homePrefersDefault, [...KITCHEN_FEATURES_TYPES, ...KITCHEN_CABINET_TYPES]),
  );
  const [amenities, setAmenities] = useState(
    getDefaultValues(amenitiesPrefersDefault, AMENITIES_TYPES),
  );
  const [layout, setLayout] = useState(getDefaultValues(homePrefersDefault, LAYOUT_TYPES));
  const [outdoorSpace, setOutdoorSpace] = useState(
    getDefaultValues(outdoorSpaceDefault, [...OUTDOOR_TYPES, { id: RVParking }]),
  );
  const [interior, setInterior] = useState(getDefaultValues(stylePrefsDefault, INTERIOR_TYPES));
  const [views, setViews] = useState(getDefaultValues(viewPrefersDefault, VIEW_TYPES));
  const [flooring, setFlooring] = useState(getDefaultValues(homePrefersDefault, FLOORING_TYPES));
  const [mechanicals, setMechanicals] = useState(
    getDefaultValues(homePrefersDefault, MECHANIC_ALL),
  );
  const [propertyCondition, setPropertyCondition] = useState(
    getDefaultValues(homePrefersDefault, PROPERTY_CONDITION_TYPES),
  );
  const [parking, setParking] = useState<IParking>({
    parkingSpaces: parkingDefault?.NumOfParkingSpaces ?? 0,
    garageOnly: parkingDefault?.GarageOnly ?? false,
    attachedGarage: parkingDefault?.GarageType === PARKING_GARAGE_TYPES.Attached,
  });

  const onSave = () => {
    const convertedAmenitiesPrefs = convertPrefs(
      amenities,
      IMPORTANCE.someWhat,
      'Preference',
      amenitiesPrefersDefault,
    );

    const convertedLayoutPrefs = convertPrefs(
      layout,
      IMPORTANCE.someWhat,
      'Preference',
      homePrefersDefault,
    );

    const convertedPropertyConditionPrefs = convertPrefs(
      propertyCondition,
      IMPORTANCE.someWhat,
      'Preference',
      homePrefersDefault,
    );

    const convertedOutdoorPrefs = convertPrefs(
      outdoorSpace,
      IMPORTANCE.someWhat,
      'Preference',
      outdoorSpaceDefault,
    );

    const convertedFlooringPrefs = convertPrefs(
      flooring,
      IMPORTANCE.someWhat,
      'Preference',
      homePrefersDefault,
    );

    const convertedViewsPrefs = convertPrefs(
      views,
      IMPORTANCE.someWhat,
      'Preference',
      viewPrefersDefault,
    );

    const convertedInteriorPrefs = convertPrefs(
      interior,
      IMPORTANCE.someWhat,
      'Preference',
      stylePrefsDefault,
    );

    const convertedKitchenPrefs = convertPrefs(
      kitchenFeatures,
      IMPORTANCE.someWhat,
      'Preference',
      homePrefersDefault,
    );

    const convertedMechanicalsPrefs = convertPrefs(
      mechanicals,
      IMPORTANCE.someWhat,
      'Preference',
      homePrefersDefault,
    );

    const { attachedGarage, garageOnly, parkingSpaces } = parking;

    const cfg = {
      stageIndex: 'homePrefs',
      DefaultPropertySearchPreferences: {
        ...defaultClientSearch,
        [PREF_TYPE.homePrefs]: [
          ...(convertedLayoutPrefs || []),
          ...(convertedPropertyConditionPrefs || []),
          ...(convertedFlooringPrefs || []),
          ...(convertedKitchenPrefs || []),
          ...(convertedMechanicalsPrefs || []),
        ],
        [PREF_TYPE.ViewPrefs]: convertedViewsPrefs?.length ? convertedViewsPrefs : undefined,
        [PREF_TYPE.interiorStylesPrefs]: convertedInteriorPrefs?.length
          ? convertedInteriorPrefs
          : undefined,
        [PREF_TYPE.parking]: parkingSpaces
          ? {
              NumOfParkingSpaces: parkingSpaces,
              ...(garageOnly
                ? {
                    GarageOnly: true,
                    GarageType: attachedGarage
                      ? PARKING_GARAGE_TYPES.Attached
                      : PARKING_GARAGE_TYPES.Detached,
                  }
                : {}),
              ImportanceAndWeight: {
                Importance: parkingDefault?.ImportanceAndWeight?.Importance || IMPORTANCE.someWhat,
              },
            }
          : undefined,
        [PREF_TYPE.outdoorPrefs]: convertedOutdoorPrefs?.length ? convertedOutdoorPrefs : undefined,
        [PREF_TYPE.amenitiesPrefs]: convertedAmenitiesPrefs?.length
          ? convertedAmenitiesPrefs
          : undefined,
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, {}, (err) => {
        if (!err) {
          dispatch(addSearchInstance({ data: cfg.DefaultPropertySearchPreferences }));
          onNext();
        }
      }),
    );
  };

  const HomePrefItems1 = [
    {
      label: 'Layout',
      variant: IconsVariant.LAYOUT,
      color: 'darkBlue',
      active: layout.length > 0,
      subtitle: LAYOUT_TYPES?.filter(({ id }) => layout.includes(id))
        ?.map(({ label }) => label)
        ?.join(', '),
      component: () => (
        <SelectComponent
          items={LAYOUT_TYPES}
          color={'darkBlue'}
          onChange={(value) => {
            setLayout(value);
          }}
          value={layout}
        />
      ),
    },
    {
      label: 'Outdoor Spaces',
      variant: IconsVariant.OUTDOOR_SPACES,
      color: 'yellow',
      active: outdoorSpace.filter((val) => val !== RVParking).length > 0,
      subtitle: outdoorSpace.filter((val) => val !== RVParking).join(', '),
      component: () => (
        <SelectComponent
          items={OUTDOOR_TYPES}
          color={'yellow'}
          onChange={(value) => {
            setOutdoorSpace(value);
          }}
          value={outdoorSpace}
        />
      ),
    },
    {
      label: 'Kitchen Features',
      variant: IconsVariant.KITCHEN_FEATURES,
      color: 'blue',
      active: kitchenFeatures.length > 0,
      subtitle: kitchenFeatures
        .map((item) =>
          item.includes('Features')
            ? 'Large Kitchen'
            : item.includes('Cabinet')
            ? item.replace('Kitchen', '').replace('Cabinet', '') + 'Cabinet'
            : item.replace('Kitchen', ''),
        )
        .join(', '),
      component: () => (
        <>
          <CabinetComponent
            color={'blue'}
            value={kitchenFeatures}
            onChange={(value) => {
              setKitchenFeatures(value);
            }}
          />
          <SelectComponent
            value={kitchenFeatures}
            onChange={(value) => {
              setKitchenFeatures(value);
            }}
            items={KITCHEN_FEATURES_TYPES}
            color={'blue'}
          />
        </>
      ),
    },
    {
      label: 'Interior Features',
      variant: IconsVariant.INTERIOR_FEATURES,
      color: 'red',
      active: interior.length > 0,
      subtitle: interior.join(', '),
      component: () => (
        <SelectComponent
          value={interior}
          onChange={(value) => {
            setInterior(value);
          }}
          items={INTERIOR_TYPES}
          color={'red'}
        />
      ),
    },
    {
      label: 'Amenities',
      variant: IconsVariant.AMENITIES,
      color: 'yellow',
      active: amenities.length > 0,
      subtitle: amenities.join(', '),
      component: () => (
        <SelectComponent
          value={amenities}
          onChange={(value) => {
            setAmenities(value);
          }}
          items={AMENITIES_TYPES}
          color={'yellow'}
        />
      ),
    },
  ];

  const HomePrefItems2 = [
    {
      label: 'Property Condition',
      variant: IconsVariant.PROPERTY_CONDITION,
      color: 'red',
      active: propertyCondition.length > 0,
      subtitle: propertyCondition.join(', '),
      component: () => (
        <SelectComponent
          value={propertyCondition}
          onChange={(value) => {
            setPropertyCondition(value);
          }}
          items={PROPERTY_CONDITION_TYPES}
          color={'red'}
        />
      ),
    },
    {
      label: 'Parking',
      variant: IconsVariant.PARKING,
      color: 'purple',
      active:
        parking?.attachedGarage ||
        parking?.garageOnly ||
        parking?.parkingSpaces > 0 ||
        outdoorSpace.filter((val) => val === RVParking).length > 0,
      subtitle: [
        ...(parking?.attachedGarage ? ['Attached Garage'] : []),
        ...(parking?.garageOnly ? ['Garage'] : []),
        ...(parking?.parkingSpaces > 0 ? [`Parking for ${parking?.parkingSpaces} Vehicles`] : []),
        ...outdoorSpace.filter((val) => val === RVParking),
      ].join(', '),
      component: () => (
        <ParkingComponent
          color="purple"
          value={parking}
          setValue={setParking}
          rvOnChange={(value) => {
            setOutdoorSpace(value);
          }}
          rvValue={outdoorSpace}
        />
      ),
    },
    {
      label: 'Flooring',
      variant: IconsVariant.FLOORING,
      color: 'darkBlue',
      active: flooring.length > 0,
      subtitle: flooring.map((item) => item.replace('Flooring', '')).join(', '),
      component: () => (
        <SelectComponent
          value={flooring}
          onChange={(value) => {
            setFlooring(value);
          }}
          items={FLOORING_TYPES}
          color={'darkBlue'}
        />
      ),
    },
    {
      label: 'View',
      variant: IconsVariant.VIEW,
      color: 'purple',
      active: views.length > 0,
      subtitle: views.map((item) => item.replace('View', '')).join(', '),
      component: () => (
        <SelectComponent
          value={views}
          onChange={(value) => {
            setViews(value);
          }}
          items={VIEW_TYPES}
          color={'purple'}
        />
      ),
    },
    {
      label: 'Mechanicals',
      variant: IconsVariant.MECHANICAL,
      color: 'blue',
      active: mechanicals.length > 0,
      subtitle: mechanicals.join(', '),
      component: () => (
        <MechanicalComponent
          value={mechanicals}
          onChange={(value) => {
            setMechanicals(value);
          }}
          color={'blue'}
        />
      ),
    },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.row}>
          {map(HomePrefItems1, ({ label, variant, color, component, active, subtitle }, index) => (
            <HomePrefsListItem
              label={label}
              variant={variant}
              color={color}
              keyValue={String(index) + 'row-1'}
              key={index + 'row-1'}
              value={expanded}
              setExpanded={setExpanded}
              component={component}
              active={active}
              subtitle={camelCaseToNormal(subtitle)}
            />
          ))}
        </div>
        <div className={styles.row}>
          {map(HomePrefItems2, ({ label, variant, color, component, active, subtitle }, index) => (
            <HomePrefsListItem
              label={label}
              variant={variant}
              color={color}
              keyValue={String(index) + 'row-2'}
              key={index + 'row-2'}
              value={expanded}
              setExpanded={setExpanded}
              component={component}
              active={active}
              subtitle={camelCaseToNormal(subtitle)}
            />
          ))}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.button}
          titleClassName={styles.titleClassName}
          title="Continue"
          onClick={onSave}
          icon={<ArrowRightFull color={'#fff'} />}
          isPending={isPending}
        />
      </div>
    </>
  );
};

export default HomePrefsList;
