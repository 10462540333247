import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getPropertyAddress } from 'helpers';

import BaseNotification from '../../../BaseNotification';

import styles from './styles.module.scss';

const NewComment = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;

  const { FirstName, LastName, AvatarUrl, Address } = Payload || {};

  const Name = `${FirstName} ${LastName}`;
  const getSubtitle = () => getPropertyAddress(Address);

  return (
    <div className={classNames(styles.notificationType, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        subtitle={getSubtitle()}
        date={CreatedDate}
        groupType={groupType}
        avatarUrl={AvatarUrl}
        avatarPlaceholder={Name}
        severityLevel={SeverityLevel}
      />
    </div>
  );
};

NewComment.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    UserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    TransactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    AvatarUrl: PropTypes.string,
    Address: PropTypes.shape({
      Line1: PropTypes.string,
      City: PropTypes.string,
      State: PropTypes.string,
    }),
    TaskName: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

NewComment.defaultProps = {
  className: '',
};

export default NewComment;
