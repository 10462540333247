import { apiServer } from 'settings/web-services/api';
import {
  GET_MILESTONE_AGGREGATE,
  ADD_MILESTONE_AGGREGATE,
} from 'app-constants/endpoints/milestoneAggregate';

export const getMilestoneAggregate = (config) => {
  return apiServer.get(GET_MILESTONE_AGGREGATE(), { params: config?.filters ?? {} });
};

export const addTransactionMilestone = (cfg) => {
  const { transactionId, ...config } = cfg;
  return apiServer.post(ADD_MILESTONE_AGGREGATE(transactionId), config);
};
