import { handleActions } from 'redux-actions';
import { cloneDeep, get } from 'lodash-es';
import {
  requestSearchInstancesAction,
  setSearchInstanceStatusAction,
  deleteSearchInstanceAction,
  setRenameSearchInstanceAction,
  resetEditedSearchInstanceAction,
  requestUpdateSearchInstanceAction,
} from 'store/actions/mySearches';
import { appLogoutAction } from 'store/actions/app';
import { IDLE, READY } from 'settings/constants/apiState';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestSearchInstancesAction]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data.result', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [setSearchInstanceStatusAction]: (state, { payload }) => {
      const instances = cloneDeep(state.data);
      const index = instances.findIndex((item) => item.Id === payload.id);
      instances[index].Status = payload.status;

      return {
        ...state,
        data: instances,
      };
    },
    [setRenameSearchInstanceAction]: (state, { payload }) => {
      const instances = cloneDeep(state.data);
      const index = instances.findIndex((item) => item.Id === payload.id);
      instances[index].Name = payload.name;

      return {
        ...state,
        data: instances,
      };
    },
    [deleteSearchInstanceAction]: (state, { payload }) => {
      const instances = state.data.filter((item) => item.Id !== payload.id) || [];

      return {
        ...state,
        data: instances,
      };
    },
    [requestUpdateSearchInstanceAction]: (state, { payload }) => {
      const searchInstances = cloneDeep(state.data);

      if (payload.state === READY) {
        const newSearchInstance = get(payload, 'data.result');
        const replacedSearchIndex = searchInstances.findIndex(
          (search) => search?.Id === newSearchInstance?.Id,
        );
        searchInstances[replacedSearchIndex] = newSearchInstance;
      }

      return {
        ...state,
        data: searchInstances,
      };
    },
    [resetEditedSearchInstanceAction]: () => initialData,
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
