import PropTypes from 'prop-types';
import classNames from 'classnames';
import prettyBytes from 'pretty-bytes';

import { truncateFilename } from 'helpers';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { Input } from 'components';
import { TagInput } from 'components/Transactions';

import styles from './styles.module.scss';

const ItemWithCategory = (props) => {
  const { className, documentName, filename, size, onChangeDocumentName } = props;
  const { onChangeTag, onBlurTag, onDelete, category, customTag } = props;

  return (
    <div testid="upload_item" className={classNames(styles.item, className)}>
      <div className={styles.innerTop}>
        <Icon className={styles.icon} variant={Icon.FILE_SIMPLE} />
        <Input
          variant={Input.LIGHT_ROUNDED}
          placeholder="Document name"
          onChange={onChangeDocumentName}
          value={documentName}
          className={styles.input}
          testid="document_name"
          maxLength={50}
        />
        <Icon
          testid="delete_icon"
          onClick={onDelete}
          className={styles.iconDelete}
          variant={Icon.DELETE}
        />
      </div>
      <div className={styles.innerBottom}>
        <TagInput
          label=""
          onSelect={onChangeTag}
          onSelectCustomTag={onBlurTag}
          value={{ Category: category, customTag }}
          selectClassName={styles.tagSelect}
        />
        <div className={styles.filename}>
          <span testid="file_name">{truncateFilename(filename, 15)}</span>
          <span testid="size" className={styles.fileSize}>
            {prettyBytes(size)}
          </span>
        </div>
      </div>
    </div>
  );
};

ItemWithCategory.propTypes = {
  className: PropTypes.string,
  documentName: PropTypes.string,
  onChangeDocumentName: PropTypes.func,
  onDelete: PropTypes.func,
  filename: PropTypes.string,
  size: PropTypes.number,
  onChangeTag: PropTypes.func,
  onBlurTag: PropTypes.func,
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  customTag: PropTypes.string,
};

ItemWithCategory.defaultProps = {
  className: '',
  documentName: '',
  onChangeDocumentName: () => {},
  onDelete: () => {},
  filename: '',
  size: 0,
  onChangeTag: () => {},
  onBlurTag: () => {},
  category: {},
  customTag: '',
};

export default ItemWithCategory;
