import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { convertNameToAvatarPlaceholder, getPropertyAddress } from 'helpers/formatters';
import { LocationService } from 'services';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { routes } from 'settings/navigation/routes';
import BaseNotification from '../../BaseNotification';

import styles from './styles.module.scss';

const locationSrv = new LocationService();

const QuoteResponse = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  locationSrv.setLocation(location);

  const { QuoteRequestId, PartnerName, PropertyAddress } = Payload || {};

  const getSubtitle = () => {
    if (PropertyAddress) {
      return getPropertyAddress(PropertyAddress);
    }
    return null;
  };

  const onClickHandler = () => {
    dispatch(openNotificationsDrawerEffect({ open: false }));
    history.replace(`${routes.servicesQuotes}?quoteId=${QuoteRequestId}`);
  };

  return (
    <div className={classNames(styles.notificationQuoteResponse, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        subtitle={getSubtitle()}
        date={CreatedDate}
        groupType={groupType}
        avatarPlaceholder={convertNameToAvatarPlaceholder(PartnerName)}
        onClick={onClickHandler}
        severityLevel={SeverityLevel}
      />
    </div>
  );
};

QuoteResponse.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  Payload: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
  }).isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

QuoteResponse.defaultProps = {
  className: '',
};

export default QuoteResponse;
