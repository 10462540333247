import { apiServer } from 'settings/web-services/api';
import { AdminFriendlySubscriptionPlan } from 'types';

export function getTeams() {
  return apiServer.get(`/admin-panel/teams`);
}

export function getTeamDetailsById(id) {
  return apiServer.get(`/admin-panel/teams/${id}`);
}

export function getTeamMembersByTeamId({ teamId, isActive }) {
  return apiServer.get(`/admin-panel/teams/${teamId}/members?isActive=${isActive}`);
}

export const getTeamSubscriptionDetails = ({ teamId }: { teamId: string }) => {
  return apiServer.get(`/subscription/adminPanel/teamSubscriptionDetails/${teamId}`);
};

export const updateTeamSubscriptionPlan = ({
  userId,
  ...cfg
}: {
  newPlan: AdminFriendlySubscriptionPlan;
  userId: string;
}) => {
  return apiServer.put(
    `/subscription/adminPanel/subscriptionCustomer/${userId}/changePlanLevel`,
    cfg,
  );
};

export const deactivateTeamSubscriptionPlan = ({ userId, ...cfg }: { userId: string }) => {
  return apiServer.put(`/subscription/adminPanel/subscriptionCustomer/${userId}/deactivate`, cfg);
};

export const reactivateTeamSubscriptionPlan = ({ userId, ...cfg }: { userId: string }) => {
  return apiServer.put(`/subscription/adminPanel/subscriptionCustomer/${userId}/reactivate`, cfg);
};

export function getTeamSeatsLogByTeamId(id) {
  return apiServer.get(`/admin-panel/teams/${id}/seats`);
}

export function updateMemberActiveStatusByAdmin(cfg, { agentId }) {
  return apiServer.put(`/admin-panel/members/${agentId}/activeStatus`, cfg);
}

export function updateMemberProfileByAdmin(cfg, { agentId, teamId }) {
  return apiServer.put(`/admin-panel/members/${agentId}/profile/${teamId}`, cfg);
}

export function updateMemberRoleByAdmin(cfg, { agentId }) {
  return apiServer.put(`/admin-panel/members/${agentId}/role`, cfg);
}
