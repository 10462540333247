import { handleActions } from 'redux-actions';
import { IDLE, READY } from 'settings/constants/apiState';
import {
  addNewAreaGroupAction,
  getAreaGroupsAction,
  updateAreaGroupsSearchTermAction,
} from 'store/actions/adminPanel';
import { AreaTypeRefined } from './selectedAreaGroup';
import { get } from 'lodash-es';

const initialData = {
  state: IDLE,
  data: [],
  meta: {},
  searchTerm: '',

  // Hardcoded values for now. FE pagingation can be added if needed
  pageNum: 1,
  pageSize: 10000,
};

export default handleActions(
  {
    [getAreaGroupsAction as any]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload as unknown, 'data.items'),
    }),
    [updateAreaGroupsSearchTermAction as any]: (state, { payload }) => {
      const { searchTerm } = payload as unknown as { searchTerm: string };
      return {
        ...state,
        searchTerm,
      };
    },
  },
  initialData,
);
