import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Lock } from 'components/Icons';

import styles from './styles.module.scss';

const Point = (props) => {
  const { className, innerClassName, children, style, onClick, isPrivate } = props;

  return (
    <div onClick={onClick} className={classNames(styles.point, className)} style={style}>
      <div className={classNames(styles.inner, innerClassName)}>{children}</div>
      {isPrivate ? <Lock className={styles.private} /> : null}
    </div>
  );
};

Point.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
  isPrivate: PropTypes.bool,
};

Point.defaultProps = {
  className: '',
  innerClassName: '',
  style: {},
  onClick: () => {},
  isPrivate: false,
};

export default Point;
