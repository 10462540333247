import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Label = ({ label, className }) => (
  <p className={classNames(styles.title, className)}>{label}</p>
);

Label.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
};

Label.defaultProps = {
  className: '',
};

export default Label;
