import React from 'react';
import PropTypes from 'prop-types';

const CheckmarkIcon = ({ className }) => (
  <div className={className}>
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="48" cy="48" r="45" stroke="#6FCF97" strokeWidth="5" />
      <path
        d="M28 51.75L38.9091 63L68 33"
        stroke="#6FCF97"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

CheckmarkIcon.propTypes = {
  className: PropTypes.string,
};

CheckmarkIcon.defaultProps = {
  className: '',
};

export default CheckmarkIcon;
