import React from 'react';
import PropTypes from 'prop-types';

const RightArrowIcon = ({ className }) => (
  <div testid="right_arrow" className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 6L15 12L9 18"
        stroke="#FF576D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

RightArrowIcon.propTypes = {
  className: PropTypes.string,
};

RightArrowIcon.defaultProps = {
  className: '',
};

export default RightArrowIcon;
