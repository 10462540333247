import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  SEARCH_CRITERIA_IMPORTANCE,
  SEARCH_CRITERIA_ACTIONS,
} from 'settings/constants/searchCriterias';
import Icon from 'pages/Properties/SearchResults/Icons';
import { Menu, MenuItem, Dropdown, Divider } from 'components-antd';

import styles from './styles.module.scss';

const Actions = (props) => {
  const { currentImportance, onChange, className, isSchool } = props;

  const onClickOption = (id) => {
    onChange(id);
  };
  const getOptions = () => {
    return (isSchool ? Actions.schoolOptions : Actions.options) ?? [];
  };
  const menu = (
    <Menu>
      {getOptions()?.map(({ id, label, icon }) => (
        <>
          {id === SEARCH_CRITERIA_ACTIONS.DELETE && <Divider className={styles.divider} />}
          <MenuItem
            key={`${id}-${document.title}`}
            title={label}
            onClick={() => onClickOption(id)}
            className={classNames(styles.option)}
          >
            <span>
              {icon ? (
                <div className={styles.iconWrapper}>
                  <Icon testid="edit_icon" className={styles.optionIcon} variant={icon} />
                  {label}
                </div>
              ) : (
                label
              )}
            </span>
            {currentImportance === id && <Icon className={styles.icon} variant={Icon.CHECKMARK} />}
          </MenuItem>
        </>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      overlayClassName={styles.dropdown}
      placement="topLeft"
      className={classNames(styles.actions, className)}
      trigger={['click']}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Icon testid="edit_icon" className={styles.edit} variant={Icon.EDIT} />
      </a>
    </Dropdown>
  );
};

Actions.options = [
  { id: SEARCH_CRITERIA_IMPORTANCE.MUST, label: 'Need', testid: 'need' },
  { id: SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT, label: 'Want', testid: 'want' },
  { id: SEARCH_CRITERIA_IMPORTANCE.UNDEFINED, label: 'No preference', testid: 'no_preference' },
];

Actions.schoolOptions = [
  ...Actions.options,
  { id: SEARCH_CRITERIA_ACTIONS.DELETE, label: 'Delete', testid: 'delete', icon: Icon.TRASH },
];

Actions.propTypes = {
  className: PropTypes.string,
  currentImportance: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isSchool: PropTypes.bool,
};

Actions.defaultProps = {
  className: '',
  isSchool: false,
};

export default Actions;
