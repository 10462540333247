import { useHistory } from 'react-router-dom';
import styles from './style.module.scss';

const FeedbackSubmitted = (props) => {
  const history = useHistory();

  return (
    <div className={styles.feedbackMainDiv}>
      <div className={styles.mainHeading}>
        <h2>Your feedback has been submitted!</h2>
      </div>
      <div className={styles.feedbackDescription}>
        <p>
          Thank you for providing feedback on your recent property! Your insights are incredibly
          valuable as we strive to enhance our services and find the perfect property for you.
        </p>
      </div>
      <div className={styles.footerArea}>
        <button
          type="submit"
          className={styles.submitBtn}
          title="Submit"
          testid="Submit"
          onClick={() => history.push('/')}
        >
          Go to Mosaik
        </button>
      </div>
    </div>
  );
};

export default FeedbackSubmitted;
