import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';
import { Cross } from 'components/Icons';

interface ICloseProps {
  onClick?: () => void;
}

const Close: React.FC<ICloseProps> = ({ onClick }) => {
  const { goBack } = useHistory();
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      goBack();
    }
  };
  return <Cross size={20} onClick={handleClick} className={styles.button} color="#515151" />;
};

export default Close;
