import { routes } from 'settings/navigation/routes';
import {
  Workshop,
  TransactionCreate,
  TransactionOverview,
  TransactionDetails,
  TransactionTasks,
  TransactionDocuments,
  TransactionActivity,
  TransactionCancellation,
} from 'pages';
import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';
import { TransactionClose } from 'pages/Workshop/Transactions/TransactionClose';

export default [
  {
    path: routes.projects,
    component: Workshop,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  {
    path: routes.projectCreate,
    component: TransactionCreate,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.projectClose,
    component: TransactionClose,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.projectOverview,
    component: TransactionOverview,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  {
    path: routes.projectDetails,
    component: TransactionDetails,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  {
    path: routes.projectTasks,
    component: TransactionTasks,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },

  {
    path: routes.projectDocuments,
    component: TransactionDocuments,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  {
    path: routes.projectActivity,
    component: TransactionActivity,
    exact: true,
    roles: [AGENT, CLIENT, THIRD_PARTY],
  },
  {
    path: routes.projectCancellation,
    component: TransactionCancellation,
    exact: true,
    roles: [AGENT],
  },
];
