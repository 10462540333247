import { ALL_PREFS } from 'pages/OnBoarding/components/Forms/constants';

export const prefsIds = {
  // OutdoorSpace types
  outdoorPrefs: 'OutdoorPrefs', //Needed to launch the modal
  fencedYard: 'FencedYard',
  balcony: 'Balcony',
  deck: 'Deck',
  patio: 'Patio',
  roofDeck: 'RoofDeck',
  terrace: 'Terrace',
  pool: 'Pool',
  waterFront: 'Waterfront',
  dock: 'Dock',

  // homePrefs
  view: 'View',
  moveInReady: 'MoveinReady',
  largeKitchen: 'LargeKitchen',
  amenities: 'AmenitiesPrefs',
  newConstruction: 'NewConstruction',
  fixerUpper: 'FixerUpper',
  rvParking: 'RVParking',
  HeatSourceElectric: 'HeatSourceElectric',
  HeatSourceNaturalGas: 'HeatSourceNaturalGas',
  WaterSourceCity: 'WaterSourceCity',
  WaterSourceWell: 'WaterSourceWell',
  SewageSeptic: 'SewageSeptic',
  SewageSewer: 'SewageSewer',
  SolarPanel: 'SolarPanel',

  // parking
  parking: 'Parking',

  // amenities
  elevator: 'Elevator',
  gym: 'Gym',
  packageReceiving: 'PackageReceiving',
  rooftop: 'Rooftop',
  dryCleaning: 'DryCleaning',
  onSiteManagement: 'OnSiteManagement',
  dogRun: 'DogRun',
  doorman: 'Doorman',
  gatedCommunity: 'GatedCommunity',
  communityPool: 'CommunityPool',
  playground: 'Playground',

  // neighborhoods
  // shortCommute: 'ShortCommute', // This field is commented because won't be neecesary (check in spring 2)
  walkability: 'Walkability',
  publicTransit: 'PublicTransit',
  artAndEntertainment: 'ArtAndEntertainment',
  parksAndNature: 'ParksAndNature',
  fitness: 'Fitness',
  coffeeShops: 'CoffeeShops',
  restaurants: 'RestaurantsBars',
  goodSchools: 'GoodSchools',
  nightlife: 'Nightlife',

  style: 'InteriorStylesPrefs',
  commutes: 'Commutes',
  proximityToImportantLocations: 'ProximityToImportantLocations',
  // Layout types
  layoutPrefs: 'LayoutPrefs', //Needed to launch the modal
  largeLot: 'LargeLot',
  openFloorPlan: 'OpenFloorPlan',
  singleFloorPlan: 'SingleStory',
  multiFloorPlan: 'MultiStory',
  firstFloorBedroom: 'FirstFloorBedroom',
  flexSpaces: 'FlexSpaces',
  homeOffice: 'HomeOffice',
  aduGuestHouse: 'GuestHouse',
  laundryRoom: 'LaundryRoom',
  basementRoom: 'BasementRoom',
  outdoorKitchen: 'OutdoorKitchen',

  // Kitchen types
  kitchenFeatures: 'KitchenFeatures', //Needed to launch the modal
  kitchenFeaturesLargerThanAverage: 'KitchenFeaturesLargerThanAverage',
  kitchenBreakfastNook: 'KitchenBreakfastNook',
  kitchenDoubleOven: 'KitchenDoubleOven',
  kitchenFeaturesEatIn: 'KitchenFeaturesEatIn',
  kitchenFeaturesIsland: 'KitchenFeaturesIsland',
  kitchenFeaturesStainlessSteelAppliances: 'KitchenFeaturesStainlessSteelAppliances',
  kitchenGasCooktop: 'KitchenGasCooktop',
  kitchenElectricCooktop: 'KitchenElectricCooktop',
  KitchenCabinetWhite: 'KitchenCabinetWhite',
  KitchenCabinetGray: 'KitchenCabinetGray',
  KitchenCabinetBrown: 'KitchenCabinetBrown',
  KitchenCabinetBlack: 'KitchenCabinetBlack',

  // Flooring types
  flooring: 'Flooring', //Needed to launch the modal
  flooringHardwood: 'FlooringHardwood',
  flooringCarpet: 'FlooringCarpet',
  flooringTile: 'FlooringTile',
  flooringParquet: 'FlooringParquet',
  flooringConcrete: 'FlooringConcrete',
  flooringStone: 'FlooringStone',
  flooringOther: 'FlooringOther',

  // Interior Features
  interior: 'Interior',
  fireplace: 'Fireplace',
  ceilingFans: 'CeilingFan',
  highCeilings: 'HighCeilings',
  naturalLight: 'NaturalLight',
  floorToCeilingWindows: 'FloortoCeilingWindows',
  frenchDoors: 'FrenchDoors',
  crownMolding: 'CrownMolding',
  builtinShelves: 'BuiltInShelves',
  walkinClosets: 'WalkInCloset',

  // View Features
  ViewWater: 'ViewWater',
  ViewMountain: 'ViewMountain',
  ViewCity: 'ViewCity',
  ViewGolfCourse: 'ViewGolfCourse',
  ViewGreenspace: 'ViewGreenspace',

  //extras
  heaterSource: 'heaterSource',
  waterSource: 'waterSource',
  waste: 'waste',
  kitchenCabinetColor: 'KitchenCabinetColor',
  school: 'Schools',
};

export const PREF_TYPE = {
  keywordPrefs: 'KeywordPrefs',
  homePrefs: 'HomePrefs',
  amenitiesPrefs: 'AmenitiesPrefs',
  commutePrefs: 'CommutePrefs',
  neighborhoodPrefs: 'NeighborhoodPrefs',
  outdoorPrefs: 'OutdoorPrefs',
  ViewPrefs: 'ViewPrefs',
  interiorStylesPrefs: 'InteriorStylesPrefs',
  parking: 'Parking',
  school: 'Schools',
};

export const HOME_PREFS_OPTIONS = [
  {
    id: prefsIds.outdoorPrefs,
    label: 'Outdoor Space',
    type: PREF_TYPE.homePrefs,
    displayInWizard: true,
  },
  { id: prefsIds.parking, label: 'Parking Spaces', type: prefsIds.parking, displayInWizard: true },
  { id: prefsIds.amenities, label: 'Amenities', type: PREF_TYPE.homePrefs, displayInWizard: true },
  { id: prefsIds.view, label: 'View', type: PREF_TYPE.homePrefs, displayInWizard: true },
  {
    id: prefsIds.commutes,
    label: 'Commute Times',
    type: PREF_TYPE.commutePrefs,
    displayInWizard: true,
  },
  {
    id: prefsIds.proximityToImportantLocations,
    label: 'Proximity to Important Locations',
    type: PREF_TYPE.commutePrefs,
    displayInWizard: true,
  },
  {
    id: prefsIds.kitchenFeaturesLargerThanAverage,
    label: 'Larger Than Average',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
  {
    id: prefsIds.kitchenGasCooktop,
    label: 'Kitchen Gas Cooktop',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.kitchenBreakfastNook,
    label: 'Kitchen Breakfast Nook',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.kitchenDoubleOven,
    label: 'Kitchen Double Oven',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.KitchenCabinetWhite,
    label: 'White Kitchen Cabinets',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.KitchenCabinetGray,
    label: 'Gray Kitchen Cabinets',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.KitchenCabinetBrown,
    label: 'Brown Kitchen Cabinets',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.KitchenCabinetBlack,
    label: 'Black Kitchen Cabinets',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.SewageSeptic,
    label: 'Septic System',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.SewageSewer,
    label: 'Sewer System',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.singleFloorPlan,
    label: 'Single Story',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.multiFloorPlan,
    label: 'Multi-Story',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.singleFloorPlan,
    label: 'Single Story',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.multiFloorPlan,
    label: 'Multi-Story',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.basementRoom,
    label: 'Basement',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.openFloorPlan,
    label: 'Open Floor Plan',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.firstFloorBedroom,
    label: 'First-Floor Bedroom',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.flexSpaces,
    label: 'Flex Spaces',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.homeOffice,
    label: 'Home Office',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.laundryRoom,
    label: 'Laundry Room',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.outdoorKitchen,
    label: 'Outdoor Kitchen',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.HeatSourceNaturalGas,
    label: 'Gas Heating',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.HeatSourceElectric,
    label: 'Electric Heating',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.SewageSeptic,
    label: 'Septic System',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.SewageSewer,
    label: 'Sewer System',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.WaterSourceCity,
    label: 'City Water',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.WaterSourceWell,
    label: 'Well Water',
    type: PREF_TYPE.homePrefs,
  },
  {
    id: prefsIds.SolarPanel,
    label: 'Solar Panels',
    type: PREF_TYPE.homePrefs,
  },
  // {
  //   id: prefsIds.kitchenFeaturesEatIn,
  //   label: 'Eat-In',
  //   type: PREF_TYPE.homePrefs,
  //   displayInWizard: false,
  // },
  {
    id: prefsIds.kitchenFeaturesIsland,
    label: 'Kitchen Island',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
  {
    id: prefsIds.kitchenFeaturesStainlessSteelAppliances,
    label: 'Stainless Steel Appliances',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
  // {
  //   id: prefsIds.kitchenGasCooktop,
  //   label: 'Gas Cooktop',
  //   type: PREF_TYPE.homePrefs,
  //   displayInWizard: false,
  // },
  {
    id: prefsIds.kitchenElectricCooktop,
    label: 'Kitchen Electric Cooktop',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
  {
    id: prefsIds.flooringHardwood,
    label: 'Hardwood',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
  {
    id: prefsIds.flooringCarpet,
    label: 'Carpet',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
  {
    id: prefsIds.flooringTile,
    label: 'Tile',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
  {
    id: prefsIds.flooringStone,
    label: 'Stone',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
  {
    id: prefsIds.flooringOther,
    label: 'Other',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
  // {
  //   id: prefsIds.shortCommute,
  //   label: 'Short Commute',
  //   type: PREF_TYPE.commutePrefs,
  //   displayInWizard: false,
  // },
  {
    id: prefsIds.openFloorPlan,
    label: 'Open Floor Plan',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
  {
    id: prefsIds.laundryRoom,
    label: 'Laundry Room',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
  {
    id: prefsIds.homeOffice,
    label: 'Home Office',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
  {
    id: prefsIds.basementRoom,
    label: 'Basement',
    type: PREF_TYPE.homePrefs,
    displayInWizard: false,
  },
];

export const INTERIOR_PREFS_OPTIONS = [
  { id: prefsIds.moveInReady, label: 'Move-In Ready', type: PREF_TYPE.homePrefs },
  {
    id: prefsIds.newConstruction,
    label: 'New Construction',
    type: PREF_TYPE.homePrefs,
    displayInWizard: true,
  },
  { id: prefsIds.layoutPrefs, label: 'Layout', type: PREF_TYPE.homePrefs },
  // Hidden until ImageAI work is complete
  // { id: prefsIds.style, label: 'Style', type: PREF_TYPE.homePrefs },
  { id: prefsIds.kitchenFeatures, label: 'Kitchen Features', type: PREF_TYPE.homePrefs },
  { id: prefsIds.flooring, label: 'Flooring', type: PREF_TYPE.homePrefs },
];

export const AMENITIES_OPTIONS = [
  { id: prefsIds.elevator, label: 'Elevator', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.gym, label: 'Gym', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.packageReceiving, label: 'Package Receiving', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.openFloorPlan, label: 'Open Floor Plan', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.rooftop, label: 'Rooftop', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.doorman, label: 'Doorman/ Security', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.dogRun, label: 'Dog Run', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.dryCleaning, label: 'Dry Cleaning', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.onSiteManagement, label: 'On-Site Management', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.gatedCommunity, label: 'Gated Community', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.communityPool, label: 'Community Pool', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.playground, label: 'Playground', type: PREF_TYPE.amenitiesPrefs },
];

export const NEIGHBORHOOD_OPTIONS = [
  { id: prefsIds.walkability, label: 'Walkability', type: PREF_TYPE.neighborhoodPrefs },
  { id: prefsIds.publicTransit, label: 'Public Transit', type: PREF_TYPE.neighborhoodPrefs },
  { id: prefsIds.parksAndNature, label: 'Parks and Nature', type: PREF_TYPE.neighborhoodPrefs },
  { id: prefsIds.restaurants, label: 'Restaurants', type: PREF_TYPE.neighborhoodPrefs },
  { id: prefsIds.nightlife, label: 'Night life', type: PREF_TYPE.neighborhoodPrefs },
  { id: prefsIds.coffeeShops, label: 'Coffee Shops', type: PREF_TYPE.neighborhoodPrefs },
  {
    id: prefsIds.artAndEntertainment,
    label: 'Arts & Entertainment',
    type: PREF_TYPE.neighborhoodPrefs,
  },
  { id: prefsIds.fitness, label: 'Fitness', type: PREF_TYPE.neighborhoodPrefs },
];

export const OUTDOOR_PREFS_OPTIONS = [
  // { id: prefsIds.outdoorYard, label: 'Backyard', type: PREF_TYPE.outdoorPrefs },
  { id: prefsIds.fencedYard, label: 'Fenced Yard', type: PREF_TYPE.outdoorPrefs },
  { id: prefsIds.balcony, label: 'Balcony', type: PREF_TYPE.outdoorPrefs },
  { id: prefsIds.deck, label: 'Deck', type: PREF_TYPE.outdoorPrefs },
  { id: prefsIds.patio, label: 'Patio', type: PREF_TYPE.outdoorPrefs },
  { id: prefsIds.roofDeck, label: 'Roof Deck', type: PREF_TYPE.outdoorPrefs },
  { id: prefsIds.terrace, label: 'Terrace', type: PREF_TYPE.outdoorPrefs },
  { id: prefsIds.pool, label: 'Pool', type: PREF_TYPE.outdoorPrefs },
];

export const ALL_PREFERENCES = [
  ...HOME_PREFS_OPTIONS,
  ...AMENITIES_OPTIONS,
  ...NEIGHBORHOOD_OPTIONS,
  ...INTERIOR_PREFS_OPTIONS,
  ...OUTDOOR_PREFS_OPTIONS,
];
export const getPrefOptionById = (id) => ALL_PREFS.find((pref) => pref?.id === id);
export const getPrefLabelOptionById = (id) =>
  ALL_PREFS.find((pref) => pref?.id === id)?.label || id;

export const getPrefLabelOption = (feature, level) => {
  const preference = ALL_PREFS.find((pref) => pref.id === feature) || feature;
  return preference
    ? { label: preference?.label ? preference?.label : preference, MatchLevel: level }
    : null;
};

export function convertPrefs(
  prefs,
  importance,
  preference = 'Preference',
  defaultPrefs: DefaultPrefType[] = [],
) {
  if (!prefs) return undefined;

  const preferencesKeys = Array.isArray(prefs) ? prefs : Object.keys(prefs);

  return preferencesKeys.map((key) => {
    let defaultImportance;
    if (defaultPrefs?.length) {
      const pref = defaultPrefs.find((pref) => pref.Preference === key);
      if (pref) defaultImportance = pref.ImportanceAndWeight?.Importance;
    }

    return {
      [preference]: key,
      ImportanceAndWeight: {
        Importance: defaultImportance || importance,
      },
    };
  });
}
interface ImportanceAndWeight {
  Importance: string;
  Weight?: Number;
}

interface DefaultPrefType {
  Preference: string;
  ImportanceAndWeight: ImportanceAndWeight;
}

export const PARKING_GARAGE_TYPES = {
  Attached: 'Attached',
  Detached: 'Detached',
};

export const NUMBER_OF_STORIES = {
  One: 'One',
  OnePreferred: 'onePreferred', // TODO: add option to the BE
  Multiple: 'Multiple',
  NotImportant: 'NotImportant',
};

export const HOA_TYPE = {
  None: 'None',
  Mandatory: 'Mandatory',
  Flexible: 'Flexible',
};

// TO BE USED ON SEARCH INSTANCES ON CLIENT WISHLIST

export const LAYOUT_OPTIONS = {
  OPEN_FLOOR_PLANS: prefsIds.openFloorPlan,
  LAUNDRY_ROOM: prefsIds.laundryRoom,
  HOME_OFFICE: prefsIds.homeOffice,
  BASEMENT: prefsIds.basementRoom,
};

export const KITCHEN_FEATURES = {
  LARGER_THAN_AVERAGE: prefsIds.kitchenFeaturesLargerThanAverage,
  // EAT_IN: prefsIds.kitchenFeaturesEatIn,
  ISLAND: prefsIds.kitchenFeaturesIsland,
  STAINLESS_STEEL_APPLIANCES: prefsIds.kitchenFeaturesStainlessSteelAppliances,
  // GAS_COOKTOP: prefsIds.kitchenGasCooktop,
  // ELECTRIC_COOKTOP: prefsIds.kitche  nElectricCooktop,
};

export const FLOORING = {
  HARDWOOD: prefsIds.flooringHardwood,
  CARPET: prefsIds.flooringCarpet,
  TILE: prefsIds.flooringTile,
  STONE: prefsIds.flooringStone,
  OTHER: prefsIds.flooringOther,
};

export const OUTDOOR_SPACE = {
  // BACKYARD: prefsIds.outdoorYard,
  BALCONY: prefsIds.balcony,
  DECK: prefsIds.deck,
  PATIO: prefsIds.patio,
  ROOF_DECK: prefsIds.roofDeck,
  TERRACE: prefsIds.terrace,
  POOL: prefsIds.pool,
};

export const BUILDING_COMMUNITY_AMENITIES = {
  ELEVATOR: prefsIds.elevator,
  GYM: prefsIds.gym,
  PACKAGE_RECEIVING: prefsIds.packageReceiving,
  ROOFTOP: prefsIds.rooftop,
  DOORMAN_SECURITY: prefsIds.doorman,
  ON_SITE_MANAGEMENT: prefsIds.onSiteManagement,
  DOG_RUN: prefsIds.dogRun,
  DRY_CLEANING: prefsIds.dryCleaning,
  GATED_COMMUNITY: prefsIds.gatedCommunity,
  COMMUNITY_POOL: prefsIds.communityPool,
  PLAYGROUND: prefsIds.playground,
};

export const NEIGHBORHOOD = {
  WALKABILITY: prefsIds.walkability,
  PUBLIC_TRANSIT: prefsIds.publicTransit,
  SCHOOLS: prefsIds.goodSchools,
  PARKS_AND_NATURE: prefsIds.parksAndNature,
  RESTAURANTS: prefsIds.restaurants,
  NIGHTLIFE: prefsIds.nightlife,
  COFFEE_SHOPS: prefsIds.coffeeShops,
  ART_ENTERTAINMENT: prefsIds.artAndEntertainment,
  FITNESS: prefsIds.fitness,
};

export const COMMUTES = {
  COMMUTES: prefsIds.commutes,
};

export const PREF_ITEMS = {
  others: {
    itemsList: [] as string[],
  },
  layout: {
    itemTitle: 'Layout',
    itemsList: [] as string[],
  },
  kitchen_features: {
    itemTitle: 'Kitchen Features',
    itemsList: [] as string[],
  },
  flooring: {
    itemTitle: 'Flooring',
    itemsList: [] as string[],
  },
  outdoor_space: {
    itemTitle: 'Outdoor Space',
    itemsList: [] as string[],
  },
  amenities: {
    itemTitle: 'Building/Community Amenities',
    itemsList: [] as string[],
  },
  neighborhood: {
    itemTitle: 'Neighborhood',
    itemsList: [] as string[],
  },
  schools: {
    itemTitle: 'Schools',
    itemsList: [] as string[],
  },
};

export const ITEMS_WITH_ADD_MORE_OPTION = ['Schools'];
