import { useState } from 'react';
import { Avatar, ConfirmationDialog } from 'components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Space, Dropdown, Menu } from 'antd';
import { convertNameToAvatarPlaceholder, showErrorMessage, showSuccessMessage } from 'helpers';

import { removeMlsAccessForAgent, requestMlsAccessForAgent } from 'api/admin/users';
import { Icons as Icon } from './Icons';

import styles from './styles.module.scss';

export const MlsItem = (props) => {
  const { className, value, setOnUpdate: onUpdateHandler } = props;
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const unqiueItemId = `${value.Id}_mlsItem`;
  const StatusChangeActionMap = {
    requested: 'Cancel',
    approved: 'Remove',
    rejected: 'Request',
    inactive: 'Request',
    'request cancelled': 'Request',
  };
  const statusChangeAction = StatusChangeActionMap[value.Status];
  const menu = (
    <Menu
      items={[
        {
          key: `{mlsItem_${value.Id}}`,
          label: (
            <div
              className={styles.dropdownItem}
              onClick={(item) =>
                statusChangeAction === 'Request'
                  ? RequestMlsService()
                  : ShowRemoveConfirmationModal()
              }
            >
              <span>{statusChangeAction}</span>
            </div>
          ),
        },
      ]}
    />
  );
  const RequestMlsService = async () => {
    try {
      await requestMlsAccessForAgent(value.Id);
      showSuccessMessage(`"${value.Name}" Requested Successfully`);
      onUpdateHandler();
    } catch (err) {
      showErrorMessage("Unable to remove/cancel MLS request'. Try to reload the page");
    }
  };

  const ShowRemoveConfirmationModal = () => {
    setShowDeleteModal(true);
  };

  const RemoveMlsService = async () => {
    try {
      await removeMlsAccessForAgent(value.Id);
      setShowDeleteModal(false);
      showSuccessMessage(`"${value.Name}" Removed Successfully`);
      onUpdateHandler();
    } catch (err) {
      setShowDeleteModal(false);
      showErrorMessage("Unable to request MLS'. Try to reload the page");
    } finally {
      onUpdateHandler();
    }
  };
  return (
    <div testid="mls_item" className={classNames(styles.mlsItem, className)}>
      <div className={styles.name}>
        <Avatar
          placeholder={
            <div className={styles.avatarPlaceholder}>
              {convertNameToAvatarPlaceholder(value?.Name)}
            </div>
          }
          avatarClassName={styles.avatar}
        />
        <div testid="label" className={styles.label}>
          {value?.Name}
          <div className={styles.info}>
            <p>{value?.Status}</p>
          </div>
        </div>
      </div>
      <div id={unqiueItemId} className={styles.dropdownWrapper}>
        <Dropdown
          overlay={menu}
          trigger={['click']}
          getPopupContainer={() => document.getElementById(unqiueItemId)!}
          placement="bottomRight"
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <Icon variant={Icon.DOTS} className={styles.dropdownDots} />
            </Space>
          </a>
        </Dropdown>
      </div>
      <ConfirmationDialog
        onReject={() => setShowDeleteModal(false)}
        onConfirm={RemoveMlsService}
        isOpen={showDeleteModal}
        isPending={false}
      >
        Are you sure you want to remove affiliation with <b>{value.Name}</b> ?
      </ConfirmationDialog>
    </div>
  );
};

MlsItem.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    Name: PropTypes.string,
    AvatarUrl: PropTypes.string,
    Status: PropTypes.string,
  }),
  setOnUpdate: PropTypes.func,
};

MlsItem.defaultProps = {
  className: '',
  value: {},
  setOnUpdate: () => {},
};
