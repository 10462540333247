import React from 'react';
import PropTypes from 'prop-types';

const Listing = ({ className, ...rest }) => (
  <div testid="edit_icon" className={className} {...rest}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 13V29H27V13"
        stroke="#525252"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 14L16 4L21.9062 8.54324M29 14L26.6335 12.1796M21.9062 8.54324V4H26.6335V12.1796M21.9062 8.54324L26.6335 12.1796"
        stroke="#525252"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 16H21M21 16L18.5 13.5M21 16L18.5 18.5"
        stroke="#525252"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 22H11M11 22L13.5 19.5M11 22L13.5 24.5"
        stroke="#525252"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

Listing.propTypes = {
  className: PropTypes.string,
};

Listing.defaultProps = {
  className: '',
};

export default Listing;
