import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { OfferStatus } from 'types/offers';
import { Tooltip } from 'components-antd';
import { TooltipIcon } from 'components/Icons';
import { Icons } from 'pages/Workshop/Icons';
import { MegaPhoneModal } from 'pages/Workshop//Transactions/TransactionOffers/MegaPhoneModal';
import { announcementType } from 'settings/constants/offer';
import { getTransactionSelector } from 'store/selectors/transaction';

import styles from './styles.module.scss';

export const Header = ({
  iteration,
  offerData,
  onModalClose,
  offerIterations,
  renderiteration,
  readOnly,
}: any) => {
  const [megPhoneModal, setMegaPhoneModal] = useState(false);
  const status = iteration?.IterationStatus;
  const { address } = useSelector(getTransactionSelector);

  const renderIterations = () => {
    if (offerIterations && offerIterations.length) {
      const reversedIterations = [...offerIterations].reverse();

      if (offerIterations.length <= 4) {
        return reversedIterations.map((iteration, index, array) =>
          renderiteration(iteration, index, reversedIterations),
        );
      } else {
        const firstThreeElements = reversedIterations.slice(0, 3);
        const lastElement = reversedIterations.slice(-1);

        return (
          <>
            {firstThreeElements.map((iteration, index) =>
              renderiteration(iteration, index, reversedIterations),
            )}
            <span>... {'>'} </span>
            {renderiteration(lastElement[0], reversedIterations.length - 1, reversedIterations)}
          </>
        );
      }
    }

    return <></>;
  };

  return (
    <div className={styles.header}>
      <div className={styles.heading}>
        <div className={styles.headingLeft}>
          <p>{renderIterations()}</p>

          <span className={styles.offer}>
            Offer #{' '}
            {iteration === undefined || !offerData?.OfferCount
              ? ''
              : iteration?.IterationCount > 0
              ? offerData?.OfferCount + '-' + iteration?.IterationCount
              : offerData?.OfferCount}
            {address && (
              <Tooltip
                title={
                  <div className={styles.tooltipInnerContainer}>
                    <div>{address?.Line1}</div>
                    <div
                      className={styles.tooltipAddress}
                    >{`${address?.City},  ${address?.State} ${address?.Zip}`}</div>
                  </div>
                }
                getPopupContainer={(trigger) => trigger}
                overlayClassName={styles.tooltipOverlay}
                placement="top"
              >
                <span>
                  <TooltipIcon className={styles.tooltip} />
                </span>
              </Tooltip>
            )}
          </span>
        </div>
        <div className={styles.actions}>
          <div
            className={classnames(
              styles.status,
              {
                [styles.pending]: status === OfferStatus.Pending,
              },
              {
                [styles.upcoming]: status === OfferStatus.CounterSent,
              },
            )}
          >
            <span>{status}</span>
          </div>
          {!readOnly && (
            <Icons
              className={styles.megaPhoneIcon}
              variant={Icons.MEGA_PHONE}
              onClick={() => setMegaPhoneModal(true)}
            />
          )}
          <Icons
            className={styles.commentIcon}
            color={'#000'}
            variant={Icons.CLOSE}
            onClick={onModalClose}
          />
        </div>
      </div>
      <MegaPhoneModal
        offerId={iteration?.OfferId}
        isOpen={megPhoneModal}
        onClose={() => setMegaPhoneModal(false)}
        type={announcementType.ITERATION}
        offerData={offerData}
      />
    </div>
  );
};
