import React, { useCallback } from 'react';

export enum MenuIconTypes {
  View = 'View',
  Download = 'Download',
  Edit = 'Edit',
  Share = 'Share',
  Delete = 'Delete',
}

interface MenuIconProps extends React.SVGProps<SVGSVGElement> {
  type: MenuIconTypes;
}

export const MenuIcons = ({ type, ...props }: MenuIconProps) => {
  const getIcon = useCallback(() => {
    switch (type) {
      case MenuIconTypes.View:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_17122_164453)">
              <path
                d="M2 12.6668C2.91212 12.1402 3.94678 11.8629 5 11.8629C6.05322 11.8629 7.08788 12.1402 8 12.6668C8.91212 12.1402 9.94678 11.8629 11 11.8629C12.0532 11.8629 13.0879 12.1402 14 12.6668"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 3.99989C2.91212 3.47328 3.94678 3.19604 5 3.19604C6.05322 3.19604 7.08788 3.47328 8 3.99989C8.91212 3.47328 9.94678 3.19604 11 3.19604C12.0532 3.19604 13.0879 3.47328 14 3.99989"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 4V12.6667"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 4V12.6667"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 4V12.6667"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_17122_164453">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );

      case MenuIconTypes.Download:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_17122_164445)">
              <path
                d="M2.66797 11.3334V12.6667C2.66797 13.0203 2.80844 13.3595 3.05849 13.6095C3.30854 13.8596 3.64768 14 4.0013 14H12.0013C12.3549 14 12.6941 13.8596 12.9441 13.6095C13.1942 13.3595 13.3346 13.0203 13.3346 12.6667V11.3334"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.66797 7.33337L8.0013 10.6667L11.3346 7.33337"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 2.66663V10.6666"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_17122_164445">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case MenuIconTypes.Share:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_17122_164463)">
              <path
                d="M2.66797 11.3334V12.6667C2.66797 13.0203 2.80844 13.3595 3.05849 13.6095C3.30854 13.8596 3.64768 14 4.0013 14H12.0013C12.3549 14 12.6941 13.8596 12.9441 13.6095C13.1942 13.3595 13.3346 13.0203 13.3346 12.6667V11.3334"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.66797 5.99988L8.0013 2.66655L11.3346 5.99988"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 10.6671V2.66711"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_17122_164463">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case MenuIconTypes.Edit:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_17122_164494)">
              <path
                d="M2.66797 13.3335H5.33464L12.3346 6.33345C12.6883 5.97983 12.8869 5.50022 12.8869 5.00012C12.8869 4.50002 12.6883 4.02041 12.3346 3.66679C11.981 3.31316 11.5014 3.1145 11.0013 3.1145C10.5012 3.1145 10.0216 3.31316 9.66797 3.66679L2.66797 10.6668V13.3335Z"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 4.3335L11.6667 7.00016"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_17122_164494">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case MenuIconTypes.Delete:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_17122_164484)">
              <path
                d="M2.66797 4.6665H13.3346"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.66797 7.3335V11.3335"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.33203 7.3335V11.3335"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.33203 4.6665L3.9987 12.6665C3.9987 13.0201 4.13917 13.3593 4.38922 13.6093C4.63927 13.8594 4.97841 13.9998 5.33203 13.9998H10.6654C11.019 13.9998 11.3581 13.8594 11.6082 13.6093C11.8582 13.3593 11.9987 13.0201 11.9987 12.6665L12.6654 4.6665"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_17122_164484">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      default: {
        return <></>;
      }
    }
  }, [type]);

  return getIcon();
};
