import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationService } from 'services';
const locationSrv = new LocationService();

const useSetSearch = (key) => {
  const location = useLocation();
  locationSrv.setLocation(location);
  const cache = locationSrv.getQuery()?.[key];
  const history = useHistory();
  const [searchValue, setSearchValue] = useState(cache ?? '');

  useEffect(() => {
    const updateSearch = () => {
      const searchParams = new URLSearchParams(location.search);
      const currentValue = searchParams.get(key) || '';

      // Update state
      setSearchValue(currentValue);
    };

    // Call the function initially
    updateSearch();

    // Listen for changes in the URL
    const unlisten = history.listen(updateSearch);

    // Clean up the listener on component unmount
    return () => {
      unlisten();
    };
  }, [key, location.search, history]);

  // Function to update both state and route
  const updateSearchValue = (newValue) => {
    let value = newValue;
    if (typeof newValue !== 'string') {
      value = JSON.stringify(newValue);
    }
    // Update state
    setSearchValue(value);

    // Update route
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    history.push({ search: searchParams.toString() });
  };

  return { searchValue, updateSearchValue };
};

export default useSetSearch;
