import React from 'react';
import { useDispatch } from 'react-redux';
import { cancelNewAreaGroupAction } from 'store/actions/adminPanel';
import { updateAreaGroupEffect } from 'store/effects/adminPanel/areaClassifier';
import { EditAreaGroup } from './EditAreaGroup';

interface EditExistingAreaGroupProps {
  areaGroupId: number;
}

export const EditExistingAreaGroup: React.FC<EditExistingAreaGroupProps> = (props) => {
  const dispatch = useDispatch();

  return (
    <EditAreaGroup
      onSaveAreaGroup={() => {
        dispatch(updateAreaGroupEffect({ areaGroupId: props.areaGroupId }));
        dispatch(cancelNewAreaGroupAction());
      }}
    />
  );
};
