import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, CommuteForm } from 'components';

import styles from './styles.module.scss';

const CommuteEditor = (props) => {
  const { onChange, value, isPending, activeCommuteIndex, onCommuteSave, className, onClose } =
    props;
  const [commuteValue, setCommuteValue] = useState({
    ...value,
    ...(value?.TransportationModePreference ? {} : { TransportationModePreference: ['Car'] }),
  });

  useEffect(() => {
    setCommuteValue({
      ...value,
      ...(value?.TransportationModePreference ? {} : { TransportationModePreference: ['Car'] }),
    });
  }, [value]);

  const onSave = () => {
    onChange({ ...commuteValue }, activeCommuteIndex);
    onCommuteSave();
  };

  const onValueChange = (val) => {
    const newValue = { ...commuteValue, ...val };
    setCommuteValue(newValue);
  };

  const disabled =
    !commuteValue?.MaxCommuteTimeInMinutes ||
    !commuteValue?.Name ||
    !commuteValue?.DestinationAddress ||
    !Object.keys(commuteValue?.TransportationModePreference || {}).length;

  return (
    <div>
      <CommuteForm
        onChange={onValueChange}
        value={commuteValue}
        isPending={isPending}
        className={className}
      />
      <div className={styles.bottonContainer}>
        <Button
          onClick={() => onClose({ save: true })}
          className={classNames(styles.button, styles.cancel)}
          title="Cancel"
        />
        <Button
          testid="add_commute_save"
          onClick={onSave}
          className={styles.button}
          disabled={disabled}
          title="Save"
        />
      </div>
    </div>
  );
};

CommuteEditor.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({}),
  isPending: PropTypes.bool,
  activeCommuteIndex: PropTypes.number,
  onCommuteSave: PropTypes.func,
};

CommuteEditor.defaultProps = {
  isPending: false,
  value: {},
  onCommuteSave: () => {},
};

export default CommuteEditor;
