import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

interface PropType {
  className?: string;
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}

const ArrowLeftFull = ({ className = '', color = '#303030', width, height, onClick }: PropType) => (
  <div className={className} onClick={onClick}>
    <svg
      width={width ?? '32'}
      height={height ?? '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66699 16H25.3337"
        stroke="#515151"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66699 16L14.667 24"
        stroke="#515151"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66699 16L14.667 8"
        stroke="#515151"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

ArrowLeftFull.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
};

ArrowLeftFull.defaultProps = {
  className: '',
  color: '#303030',
};

export default ArrowLeftFull;
