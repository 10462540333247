import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { userGetDataOnBackgroundEffect } from 'store/effects';

/**
 * Hook which is used to redirect user from onboarding to the main app
 * @returns
 */
export function useExitOnboarding() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isPending, setIsPending] = useState(false);

  const handleExit = () => {
    // As SubscriptionCustomer is created asyncronously - we need to refetch user data if the user skips the onboarding
    setIsPending(true);
    dispatch(
      userGetDataOnBackgroundEffect({}, {}, () => {
        setIsPending(false);
        history.push(routes.index);
      }),
    );
  };

  return {
    isPending,
    handleExit,
  };
}
