import { createSelector } from 'reselect';
import { get, groupBy } from 'lodash-es';
import moment from 'moment';

import { IDLE, PENDING } from 'settings/constants/apiState';
import { transformFormResponse } from 'utils';

const localState = ({ quoteDetails }) => quoteDetails;

export const getQuoteDetailsSelector = createSelector(localState, (quote) => {
  const quoteData = quote?.data;
  const quoteDocuments = quoteData?.QuoteDocuments || [];
  const requestorRole = get(quoteData, 'Requestor.Roles.0');
  const requestorName = `${quoteData?.Requestor?.FirstName} ${quoteData?.Requestor?.LastName}`;
  const agentName = Object.keys(quoteData?.Requestor?.Agent || {}).length
    ? `${quoteData?.Requestor?.Agent?.FirstName} ${quoteData?.Requestor?.Agent?.LastName}`
    : '';
  const clientName = quoteData?.Client
    ? `${quoteData?.Client?.FirstName} ${quoteData?.Client?.LastName}`
    : '';
  const groupedByDateQuoteDocuments = groupBy(quoteDocuments, (document) =>
    moment(document?.UploadDate).format('MMM Do, YYYY'),
  );
  const formResponses = transformFormResponse(get(quoteData, 'FormResponses', []));
  const requestQuoteFile = quoteData?.RequestQuoteFile;

  return {
    isIdle: quote.state === IDLE,
    isPending: quote.state === PENDING,
    isData: !!quote.data,
    quote: quoteData,
    requestorRole,
    requestorName,
    agentName,
    groupedByDateQuoteDocuments,
    formResponses,
    clientName,
    requestQuoteFile,
  };
});

export const getQuoteDetailsForPartnerSelector = createSelector(localState, (quote) => {
  const quoteData = quote?.data;
  const quoteDocuments = quoteData?.QuoteDocuments || [];
  const requestorRole = get(quoteData, 'Requestor.Roles.0');
  const requestorName = `${quoteData?.Requestor?.FirstName} ${quoteData?.Requestor?.LastName}`;
  const agentName = Object.keys(quoteData?.Requestor?.Agent || {}).length
    ? `${quoteData?.Requestor?.Agent?.FirstName} ${quoteData?.Requestor?.Agent?.LastName}`
    : '';
  const clientName = quoteData?.Client
    ? `${quoteData?.Client?.FirstName} ${quoteData?.Client?.LastName}`
    : '';
  const groupedByDateQuoteDocuments = groupBy(quoteDocuments, (document) =>
    moment(document?.UploadDate).format('MMM Do, YYYY'),
  );
  const formResponses = get(quoteData, 'FormResponses', []);

  const requestQuoteFile = quoteData?.RequestQuoteFile;

  return {
    isIdle: quote.state === IDLE,
    isPending: quote.state === PENDING,
    isData: !!quote.data,
    quote: quoteData,
    requestorRole,
    requestorName,
    agentName,
    groupedByDateQuoteDocuments,
    formResponses,
    clientName,
    requestQuoteFile,
  };
});
