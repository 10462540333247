import { useEffect, useState } from 'react';

import { Input } from 'components';
import { OptionButton } from '../../CriteraType';
import Icons from '../../../Icons/Icons';

import styles from './styles.module.scss';

const KEYS = {
  Importance: 'ImportanceAndWeight',
  Keyword: 'Keyword',
};

export const CustomPreference = ({ keyword, selectedImportance, onChange, onRemove }) => {
  const [editState, setEditState] = useState(keyword === '');
  const [valueKeyword, setValueKeyword] = useState(keyword || '');

  const onClick = (value) => onChange({ Importance: value }, KEYS.Importance);

  const saveCustomPref = () => {
    if (valueKeyword.trim() !== '') {
      onChange(valueKeyword, KEYS.Keyword);
      setEditState(false);
    }
  };

  return (
    <div className={styles.customPrefContainer}>
      {editState ? (
        <Input
          placeholder="Enter feature here..."
          variant={Input.LIGHT_ROUND}
          className={styles.featureInput}
          value={valueKeyword}
          onChange={(e) => setValueKeyword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') saveCustomPref();
          }}
          onBlur={saveCustomPref}
        />
      ) : (
        <div className={styles.savedCustomPref}>
          <span className={styles.pref}>{keyword}</span>
          <div className={styles.icons}>
            <Icons variant={Icons.EDIT} onClick={() => setEditState(true)} />
            <Icons variant={Icons.REMOVE} onClick={onRemove} />
          </div>
        </div>
      )}
      <div className={styles.importanceBtns}>
        <OptionButton
          title="Need"
          value="Need"
          onClick={(value) => onClick(value)}
          selectedImportance={selectedImportance}
        />
        <OptionButton
          title="Want"
          value="Want"
          onClick={(value) => onClick(value)}
          selectedImportance={selectedImportance}
        />
      </div>
    </div>
  );
};
