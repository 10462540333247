import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchFieldWithDebounce } from 'components';
import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';
import {
  getTransactionDocumentsFilterEffect,
  setTransactionDocumentsFilterEffect,
  setTransactionDocumentsFilterLoadingEffect,
  setTransactionDocumentsPageEffect,
} from 'store/effects/transactions/documents';
import { getTransactionDocumentsFilterSelector } from 'store/selectors/transactionDocuments';

import styles from './styles.module.scss';
import { IDLE, PENDING } from 'settings/constants/apiState';

export const Search = () => {
  const { filter } = useSelector(getTransactionDocumentsFilterSelector);
  const dispatch = useDispatch();

  const getSendAction = ({ search }) => {
    dispatch(setTransactionDocumentsFilterLoadingEffect({ state: PENDING }));
    dispatch(setTransactionDocumentsPageEffect({ page: 0 }));
    dispatch(setTransactionDocumentsFilterEffect({ name: search }));
    return getTransactionDocumentsFilterEffect();
  };

  const getResetAction = () => {
    dispatch(setTransactionDocumentsFilterEffect({ name: undefined }));
    return getTransactionDocumentsFilterEffect();
  };

  return (
    <div className={styles.searchWrapper}>
      <div testid="search_documents" className={styles.search}>
        <SearchFieldWithDebounce
          className={styles.searchField}
          classNameInput={styles.searchInput}
          placeholder="Search"
          sendAction={getSendAction}
          value={filter?.name}
          resetAction={getResetAction}
          resetByUnmount={false}
        />
      </div>
    </div>
  );
};
