import { Menu, Popover } from 'components-antd';
import styles from './style.module.scss';

import { ICON_VARIANT_TYPE, Icons } from '../../../../../Icons';
import { Fragment, useState } from 'react';
import { AddFile } from 'components/Icons';
import classNames from 'classnames';

export const LinkPopover = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { showTransaction, showClient, showAddress, onMenuClick } = props;

  const actionItems = [
    ...(showTransaction
      ? [
          {
            label: 'Transaction',
            key: 'transaction',
            icon: <Icons variant={ICON_VARIANT_TYPE.DoubleArrow} />,
          },
        ]
      : []),

    ...(showClient
      ? [{ label: 'Client', key: 'client', icon: <Icons variant={ICON_VARIANT_TYPE.User} /> }]
      : []),

    ...(showAddress
      ? [
          {
            label: 'New Address',
            key: 'newAddress',
            icon: <Icons variant={ICON_VARIANT_TYPE.Address} />,
          },
        ]
      : []),
  ];

  const handleMenuClick = (menuProps) => {
    onMenuClick(menuProps, setIsOpen);
  };

  if (!actionItems.length) return <></>;

  const menu = <Menu onClick={handleMenuClick} items={actionItems} />;
  return (
    <Popover
      content={menu}
      trigger={'click'}
      open={isOpen}
      placement={'bottom'}
      onOpenChange={setIsOpen}
      overlayClassName={styles.actionPopover}
      getPopupContainer={(trigger) => {
        return trigger;
      }}
    >
      <div
        className={classNames(styles.popmenu, {
          [styles.openPopMenu]: isOpen,
          [styles.popupMenuWithFields]: actionItems.length < 3,
        })}
      >
        <div className={styles.popmenuButton}>
          <Fragment>
            <div className={classNames(styles.menuIcon, { [styles.menuOpen]: isOpen })}>
              <AddFile />
            </div>
            <span>Link</span>
          </Fragment>
        </div>
      </div>
    </Popover>
  );
};
