import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import { Avatar, AvatarGroup } from 'components-antd';
import avatarPlaceholder from 'images/avatar-placeholder-light.png';

import styles from './styles.module.scss';

export const Tasks = (props) => {
  const history = useHistory();
  const {
    transactionId,
    taskId,
    avatar,
    avatars,
    taskTitle,
    taskDescription,
    dueDate,
    taskStatus,
    tabGroup,
  } = props;

  const [currentDate, setCurrentDate] = useState<string>('');
  const [formattedDate, setFormattedDate] = useState<string>('');

  useEffect(() => {
    setFormattedDate(moment(dueDate).format('MM/DD'));
    setCurrentDate(moment().format('MM/DD'));
  }, [dueDate]);

  const taskHandler = () => {
    history.push({
      pathname: `/workshop/transactions/${transactionId}/tasks`,
      state: {
        taskId: taskId,
        transactionId: transactionId,
      },
    });
  };

  return (
    <div className={styles.taskCard} onClick={taskHandler}>
      <div
        className={classNames(styles.avatarWrap, { [styles.multipleAvatar]: avatars?.length > 1 })}
      >
        {tabGroup == 'assignedToYou' && (
          <Avatar className={styles.avatar} src={avatar ? avatar : avatarPlaceholder} />
        )}
        {tabGroup == 'assignedByYou' && (
          <AvatarGroup maxCount={2} maxPopoverTrigger="click">
            {avatars?.map((item, idx) => {
              return (
                <Avatar
                  key={idx}
                  className={styles.avatar}
                  src={item?.Assignee?.AvatarUrl ? item?.Assignee?.AvatarUrl : avatarPlaceholder}
                />
              );
            })}
          </AvatarGroup>
        )}
      </div>
      <div className={styles.contentArea}>
        <h4 className={styles.taskTitle}>{taskTitle}</h4>
        <p className={styles.taskSubTitle}>{taskDescription}</p>
      </div>
      <div
        className={classNames(styles.taskMeta, {
          [styles.isDue]: formattedDate < currentDate,
        })}
      >
        <p className={styles.dueDate}>Due: {formattedDate}</p>
        <p className={styles.taskStatus}>{taskStatus}</p>
      </div>
    </div>
  );
};
