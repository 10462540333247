export type PhotoCompareSectionDataType = {
  photoData: {
    url: string;
    labels: string[];
    topLabels: string[];
  }[];
};

export type LabelType = {
  name: string;
  prediction: number;
  probability: number;
};

export type ClientSearchInstanceDataType = {
  clientId: number;
  instanceId: number;
  agentId: number;
  clientFirstName: string;
  clientLastName: string;
  clientFullName: string;
  clientInitials: string;
  clientPhotoUrl: string | null | undefined;
  instanceName: string;
  instanceStatus: string;
};

export type WishListItemType = {
  text: string;
  match: 'FULL' | 'PARTIAL' | 'NONE';
};

export type KeyDetailsDataType = {
  price: string;
  status: string;
  propertyType: string;
  daysOnMarket: string;
  beds: string;
  baths: string;
  size: string;
  yearBuilt: string;
};

export type FinancialDataType = {
  pricePerSqFt: string;
  hoaDue: string;
  duesInclude: string[];
  propertyTax: string;
};

export type GeneralInfoDataType = {
  Id: string;
  photo: string;
  photoCount: number;
  address: string;
  city: string;
  state: string;
  zip: string;
};

export type WishItemDataType = {
  feature: string;
  importance: 'Must' | 'Somewhat';
  matchLevel: 'FULL' | 'PARTIAL' | 'NONE';
};

export type InteriorDetails = {
  livingRoom: string[];
  kitchen: string[];
  primaryBedroom: string[];
  primaryBathroom: string[];
  additionalRoom: string[];
  interiorFeatures: string[];
  equipment: string[];
};

export type ExteriorDetails = {
  features: string[];
  exterior: string[];
  lotDimensions: string[];
  amenities: string[];
};

export type WishListDataType = {
  wishListData: WishItemDataType[];
};

export type Destinations = {
  name: string;
  address: string;
  carTimes: string[];
  transitTimes: string[];
};

export type CompareDataType = {
  generalInfo: GeneralInfoDataType[];
  keyDetails: KeyDetailsDataType[];
  financial: FinancialDataType[];
  needs: WishListDataType[];
  wants: WishListDataType[];
  interiorDetails: InteriorDetails[];
  exteriorDetails: ExteriorDetails[];
  destinations: Destinations[];
  livingAreaPhotoUrls: PhotoCompareSectionDataType[];
  kitchenPhotoUrls: PhotoCompareSectionDataType[];
  bedroomsPhotoUrls: PhotoCompareSectionDataType[];
  bathroomsPhotoUrls: PhotoCompareSectionDataType[];
  otherRoomsPhotoUrls: PhotoCompareSectionDataType[];
  outdoorPhotoUrls: PhotoCompareSectionDataType[];
  flexSpacesPhotoUrls: PhotoCompareSectionDataType[];
  aerialPhotoUrls: PhotoCompareSectionDataType[];
  atticPhotoUrls: PhotoCompareSectionDataType[];
  exteriorPhotoUrls: PhotoCompareSectionDataType[];
  foyerPhotoUrls: PhotoCompareSectionDataType[];
  entryPhotoUrls: PhotoCompareSectionDataType[];
  floorPlanPhotoUrls: PhotoCompareSectionDataType[];
  garagePhotoUrls: PhotoCompareSectionDataType[];
  hallwayPhotoUrls: PhotoCompareSectionDataType[];
  laundryPhotoUrls: PhotoCompareSectionDataType[];
  mudRoomPhotoUrls: PhotoCompareSectionDataType[];
  stairsPhotoUrls: PhotoCompareSectionDataType[];
  viewPhotoUrls: PhotoCompareSectionDataType[];
  platMapPhotoUrls: PhotoCompareSectionDataType[];
  fireplacePhotoUrls: PhotoCompareSectionDataType[];
  showEmptyPhotoCompare: boolean;
};

export type ClientPreferencesDataType = {
  needs: WishListDataType[];
  wants: WishListDataType[];
  destinations: Destinations[];
};

export enum ComparisonStateDataType {
  details,
  photos,
}

export enum PhotoLabel {
  room_bathroom,
  room_bedroom,
  room_closet,
  room_dining,
  room_exterior,
  room_floorplan,
  room_frontporch,
  room_garage,
  room_homeoffice,
  room_kitchen,
  room_laundry,
  room_livingarea,
  room_outdoor,
  room_swimmingpool,
}
