import { Cascader as AntCascader, CascaderProps as AntCascaderProps } from 'antd';
import classNames from 'classnames';

import styles from './styles.module.scss';

type CascaderProps = AntCascaderProps<any> & {
  large?: boolean;
};

export const Cascader = ({ large, className, ...props }: CascaderProps) => (
  <AntCascader
    className={classNames(styles.baseCascader, { [styles.largeCascader]: large }, className)}
    {...props}
  />
);
