import React from 'react';
import { useSelector } from 'react-redux';

import { getUserRolesMapSelector } from 'store/selectors/user';
import { isSearchActive } from 'store/selectors/searchMessages';

import SearchMessages from '../SearchMessages';

import DrawerHeaderControlls from '../../DrawerHeaderControlls';
import AgentRoleView from './AgentRoleView';
import OtherRolesView from './OtherRolesView';

import MessagesDrawerSearchedContent from '../SearchedContent';

import styles from './styles.module.scss';

const MessagesDrawerArchivedContent = () => {
  const isSearch = useSelector(isSearchActive);
  const { isAgent } = useSelector(getUserRolesMapSelector);

  return (
    <div testid="archived_content" className={styles.wrapper}>
      <DrawerHeaderControlls />
      <div className={styles.title}>
        <div className={styles.titleIcon}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.54415 6.69958L4.05132 17.1781C4.01733 17.28 4 17.3868 4 17.4943V24.6654C4 25.7699 4.89543 26.6654 6 26.6654H26C27.1046 26.6654 28 25.7699 28 24.6654V17.4943C28 17.3868 27.9827 17.28 27.9487 17.1781L24.4558 6.69958C24.1836 5.88289 23.4193 5.33203 22.5585 5.33203H9.44152C8.58066 5.33203 7.81638 5.88289 7.54415 6.69958Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.66602 13.332H11.3327V14.6654C11.3327 16.8745 13.1235 18.6654 15.3327 18.6654H16.666C18.8752 18.6654 20.666 16.8745 20.666 14.6654V13.332H25.3327"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div testid="title">Archive</div>
      </div>
      <SearchMessages placeholder="Search" className={styles.searchMessagesInput} />
      <div className={styles.scrollWrapper}>
        {isSearch ? <MessagesDrawerSearchedContent /> : <AgentRoleView />}
      </div>
    </div>
  );
};

export default MessagesDrawerArchivedContent;
