import { createAction } from 'redux-actions';

export const setTransactionDocumentsFilterAction = createAction('SET_TRANSACTION_DOCUMENTS_FILTER');
export const setTransactionDocumentsSortAction = createAction('SET_TRANSACTION_DOCUMENTS_SORT');
export const setTransactionDocumentsPageAction = createAction('SET_TRANSACTION_DOCUMENTS_PAGE');
export const setTransactionDocumentsFilterPendingAction = createAction(
  'SET_TRANSACTION_DOCUMENTS_FILTER_PENDING',
);
export const deleteTransactionDocumentAction = createAction('DELETE_TRANSACTION_DOCUMENT');
export const resetTransactionDocumentsFiltersAction = createAction(
  'RESET_TRANSACTION_DOCUMENTS_FILTERS',
);
export const resetTransactionDocumentsAction = createAction('RESET_TRANSACTION_DOCUMENTS_FILTERS');
export const editTransactionDocumentAction = createAction('EDIT_TRANSACTION_DOCUMENT');
