import { Input } from 'components-antd';
import { SearchIcon } from 'components/Icons';
import { AgentDocumentSearchCriteria } from 'types';

import styles from './styles.module.scss';

interface SearchBarProps {
  searchCriteria: AgentDocumentSearchCriteria;
  setSearchCriteria: (data: AgentDocumentSearchCriteria) => void;
  handleSearchCriteria: (data?: AgentDocumentSearchCriteria) => void;
}

export const SearchBar = ({
  searchCriteria,
  setSearchCriteria,
  handleSearchCriteria,
}: SearchBarProps) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearchCriteria();
  };

  return (
    <div className={styles.searchBar}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <button type="submit">
          <span className={styles.searchIcon}>
            <SearchIcon />
          </span>
        </button>
        <Input
          className={styles.input}
          placeholder="Search documents"
          value={searchCriteria?.name || ''}
          onChange={(e) => setSearchCriteria({ ...searchCriteria, name: e.target.value })}
          maxLength={100}
        />
      </form>
    </div>
  );
};
