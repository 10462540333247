import { Header, Layout } from 'components-antd';
import AppRouter from '../AppRouter';

import styles from './styles.module.scss';

export const PageContentContainer = () => {
  return (
    <Layout className={styles.baseLayout}>
      <Header />
      <AppRouter />
    </Layout>
  );
};
