import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ template: { form } }) => form;

export const getFormTemplateSelector = createSelector(localState, ({ state, meta, ...rest }) => ({
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!rest?.data,
  ...rest,
}));

export const getFormTemplateDataSelector = createSelector(localState, (state) =>
  get(state, 'data'),
);

export const getFormTemplateDrawerIsOpenedSelector = createSelector(localState, (state) =>
  get(state, 'isDrawerOpened'),
);

export const getFormTemplateDrawerParamsSelector = createSelector(localState, (state) =>
  get(state, 'drawerParams'),
);
