import { createSelector } from 'reselect';
import { get } from 'lodash-es';

const localState = ({ drawers }) => get(drawers, 'comments');

export const getIsCommentsDrawerOpenSelector = createSelector(localState, ({ open }) => !!open);

export const getCommentsDrawerParamsSelector = createSelector(localState, ({ params }) => params);

export const getCurrentThreadIdSelector = createSelector(
  localState,
  ({ currentThreadId }) => currentThreadId,
);
