const getDevelopmentSocketLink = (userId) => `wss://llm02.mosaik.io/ws/${userId}`;

let socket;

const ENDPOINT = (userId) => getDevelopmentSocketLink(userId);

export const getSocket = () => socket;

export const clearSocket = () => {
  socket = null;
};

export const connectSocket = (userId) => {
  if (!socket) {
    let wsUrl = ENDPOINT(userId);
    socket = new WebSocket(wsUrl);
  } else if (socket.disconnected) {
    socket.connect();
  }

  return socket;
};
