import templates from '../adminPanel/templates';
import settings from './settings';
import agentTemplates from './templates';

export default {
  helpAndSupport: '/help-and-support',
  // feed: '/feed',
  // search: '/search',
  // transactions: '/transactions',

  clients: '/clients',
  offers: '/offers',

  settings: '/settings',
  ...settings,
  templates: '/templates',
  ...agentTemplates,
};
