import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { commuteTime } from 'settings/constants/commuteTime';
import { RadioOptions } from 'components';

import styles from './styles.module.scss';
import Custom from './Custom';

const CommuteTime = ({ className, onChange, value, disabled, testid }) => {
  const [commuteId, setCommuteId] = useState(value);

  useEffect(() => setCommuteId(value), [value]);

  const getOptionBy = (val) => CommuteTime.options.find(({ value: optValue }) => optValue === val);

  const onChangeHandler = (val) => {
    const option = getOptionBy(val);

    if (option) {
      setCommuteId(option.id);
    }

    onChange(val ?? +val);
  };

  const isCustomChecked = () =>
    !CommuteTime.options.map(({ value: optValue }) => optValue).includes(value) ||
    commuteId === commuteTime.CUSTOM;

  const isStaticValue = (val) => !!getOptionBy(val);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <RadioOptions
        options={CommuteTime.options}
        name="MaxCommuteTimeInMinutes"
        value={commuteId}
        titleLabel="Preferred Commute Time"
        onChange={onChangeHandler}
        disabled={disabled}
        testid={testid}
      />
      <Custom
        value={value}
        textValue={isStaticValue(value) ? '' : value ?? ''}
        onChangeValue={onChangeHandler}
        onChangeOption={() => setCommuteId(commuteTime.CUSTOM)}
        checked={isCustomChecked()}
        disabled={disabled}
        testid={testid}
      />
    </div>
  );
};

CommuteTime.options = [
  {
    id: commuteTime.FIFTEEN_MINUTES,
    value: commuteTime.FIFTEEN_MINUTES,
    label: '15 minutes or less',
  },
  {
    id: commuteTime.THIRSTY_MINUTES,
    value: commuteTime.THIRSTY_MINUTES,
    label: '30 minutes or less',
  },
  {
    id: commuteTime.FORTY_FIVE_MINUTES,
    value: commuteTime.FORTY_FIVE_MINUTES,
    label: '45 minutes or less',
  },
  { id: commuteTime.SIXTE_MINUTES, value: commuteTime.SIXTE_MINUTES, label: '60 minutes' },
];

CommuteTime.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  testid: PropTypes.string,
};

CommuteTime.defaultProps = {
  className: '',
  onChange: () => {},
  disabled: false,
  value: undefined,
  testid: undefined,
};

export default CommuteTime;
