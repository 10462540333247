import {
  editMessage,
  getMessagesByThreadId,
  sendNewMessage,
  deleteMessage,
} from 'services/sockets';
import { showErrorMessage } from 'helpers/errors';
import {
  replaceTempTransactionMessageAction,
  setNewTransactionMessageWithTempIdAction,
  socketSetEditTransactionMessageAction,
  socketsResetTransactionMessagesAction,
  socketsStoreTransactionMessagesByThreadIdAction,
  socketsStoreTransactionMessagesSetThreadIdAction,
} from 'store/actions/sockets/transactionMessages';
import { getState } from 'store';
import { uniqueId } from 'lodash-es';
import moment from 'moment';
import { preparedAttachments } from '../helpers';

export function socketsGetTransactionMessagesByThreadIdEffect(cfg, options, cb = () => {}) {
  return (dispatch) => {
    const config = {
      Id: cfg?.Id,
    };
    getMessagesByThreadId(config, (err, data) => {
      if (err) {
        return showErrorMessage(err);
      }
      dispatch(socketsStoreTransactionMessagesByThreadIdAction({ data, threadId: cfg?.Id }));
      cb(err, data);
    });
  };
}

export function editTransactionMessageEffect(cfg) {
  return () => {
    const config = {
      MessageId: cfg?.MessageId,
      Text: cfg?.Text || '',
      Attachments: preparedAttachments(cfg?.Attachments),
    };
    editMessage(config, (err) => {
      if (err) {
        return showErrorMessage(err);
      }
    });
  };
}

export function deleteTransactionMessageEffect(cfg) {
  return () => {
    const config = {
      MessageId: cfg?.MessageId,
    };
    deleteMessage(config, (err) => {
      if (err) {
        return showErrorMessage(err);
      }
    });
  };
}

export function setNewTransactionMessageWithTempIdEffect(cfg) {
  return (dispatch) => {
    const { user } = getState();
    const userId = user?.data?.Id;
    const config = {
      CreatedDate: new Date(),
      SenderUserId: userId,
      MessageThreadId: cfg?.ThreadId,
      TempId: cfg?.TempId,
      Text: cfg?.Text,
      Attachments: cfg?.Attachments,
    };
    dispatch(setNewTransactionMessageWithTempIdAction(config));
  };
}

export function sendNewTransactionMessageEffect(cfg, options, cb = () => {}) {
  return (dispatch) => {
    const config = {
      ThreadId: cfg?.ThreadId,
      TempId: `${uniqueId()}-${moment().valueOf()}`,
      Text: cfg?.Text || '',
      Attachments: preparedAttachments(cfg?.Attachments),
      RequestTour: cfg?.RequestTour,
    };

    dispatch(setNewTransactionMessageWithTempIdEffect(config));
    sendNewMessage(config, (err, data) => {
      if (err) {
        return showErrorMessage(err);
      }
      dispatch(replaceTempTransactionMessageAction(data));
      cb(err, data);
    });
  };
}

export function resetTransactionMessagesEffect() {
  return (dispatch) => {
    dispatch(socketsResetTransactionMessagesAction());
  };
}

export function socketSetEditTransactionMessageEffect(cfg) {
  return (dispatch) => {
    dispatch(socketSetEditTransactionMessageAction(cfg));
  };
}

export function setTransactionMessagesCurrentThreadIdEffect(cfg) {
  return (dispatch) => {
    dispatch(socketsStoreTransactionMessagesSetThreadIdAction(cfg));
  };
}
