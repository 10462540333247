import classnames from 'classnames';
import { Spinner } from '../../../../../../components';
import { Button } from 'components-antd';

import styles from './styles.module.scss';
import classNames from 'classnames';

export const Navigation = ({
  data,
  onNext,
  onFinish,
  disabled,
  isPending,
  edit = false,
  onReset,
  isLast,
  className = '',
}) => {
  const editMode = data?.Id && edit;

  return (
    <div className={classNames(styles.footer, className)}>
      {isPending ? (
        <Spinner className={styles.spinnerClassname} loaderClassName={styles.loadingSpinner} />
      ) : (
        <div className={styles.flexButtons}>
          <div className={styles.resetText} onClick={onReset}>
            Reset
          </div>
          {isLast ? (
            <Button
              variant="secondary"
              className={classnames(styles.nextBtn, styles.submitBtn, {
                [styles.submitBtnDisabled]: disabled,
              })}
              onClick={() => {
                onFinish();
              }}
              disabled={disabled}
            >
              {editMode ? 'Save' : 'Submit'}
            </Button>
          ) : (
            <Button
              className={classNames(styles.nextBtn, { [styles.nextBtnDisabled]: disabled })}
              onClick={editMode ? onFinish : onNext}
              variant="secondary"
              disabled={disabled}
            >
              {editMode ? 'Save' : 'Next'}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
