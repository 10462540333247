import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Icons from '../icons';
import Field from '../Field';
import { Footer } from 'components-antd';
import { PageNotFound, Spinner } from 'components';
import { routes } from 'settings/navigation/routes';
import { TEAM_BASIC } from 'settings/constants/roles';
import { getAgentTeamDetailEffect } from 'store/effects';
import { getAgentTeamDetailSelector } from 'store/selectors/agentTeamDetail';
import { getDetailsTemplate, postDetailsTemplate, putDetailsTemplate } from 'api/templates';
import { showSuccessMessage } from 'helpers';

import styles from './styles.module.scss';

type FieldsData = {
  Name: string;
  IsPrivate: boolean;
  EditMode: boolean;
};

type DetailsTemplate = {
  Fields: {
    Name: string;
    IsPrivate: boolean;
  }[];
};

interface DetailsProps {
  isUnderTransaction?: boolean;
  saveDetailsTemplate?: boolean;
  viewMode?: boolean;
  isProject?: boolean;
  setSaveDetailsTemplateSetter?: Function;
}

export function DetailsContent({
  isUnderTransaction = false,
  saveDetailsTemplate = false,
  viewMode = false,
  isProject = false,
  setSaveDetailsTemplateSetter,
}: DetailsProps) {
  const dispatch = useDispatch();

  const [detailsTemplateId, setDetailsTemplateId] = useState<number>(0);
  const [detailsTemplateFields, setDetailsTemplateFields] = useState<FieldsData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(true);
  const fieldsLimit = 100;

  const [disabled, setDisabled] = useState(true);
  const { data } = useSelector(getAgentTeamDetailSelector);

  useEffect(() => {
    dispatch(getAgentTeamDetailEffect());
  }, []);

  useEffect(() => {
    if (!data || data.TeamRole !== TEAM_BASIC) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [data]);

  const fetchDetailsTemplate = async () => {
    try {
      const params = isProject ? { isProject: true } : {};
      const response = await getDetailsTemplate(params);
      const {
        status,
        data: { result },
      } = response || {};

      if (status === 200) {
        if (result?.Id) setDetailsTemplateId(result?.Id);
        if (result?.Fields?.length) {
          let fields: FieldsData[] = [];
          for (let i = 0; i < result?.Fields?.length; i++) {
            fields.push({
              Name: result?.Fields[i].Name as string,
              IsPrivate: result?.Fields[i].IsPrivate as boolean,
              EditMode: false,
            });
          }
          setDetailsTemplateFields(fields);
        }
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const updateDetailsTemplate = async () => {
    setUpdating(true);
    try {
      let newDetailsTemplate: DetailsTemplate = {
        Fields: [],
      };
      for (let i = 0; i < detailsTemplateFields.length; i++) {
        newDetailsTemplate.Fields.push({
          Name: detailsTemplateFields[i].Name,
          IsPrivate: detailsTemplateFields[i].IsPrivate,
        });
      }
      let response: any = null;
      if (detailsTemplateId) {
        const id = isProject ? `${detailsTemplateId}?isProject=true` : detailsTemplateId;
        response = await putDetailsTemplate(newDetailsTemplate, { id });
      } else {
        response = await postDetailsTemplate(isProject, newDetailsTemplate);
      }

      const {
        status,
        data: { result },
      } = response || {};

      if (status == 200) {
        showSuccessMessage('Changes are saved successfully');
        if (result?.Id) setDetailsTemplateId(result?.Id);
        if (result?.Fields?.length) {
          let fields: FieldsData[] = [];
          for (let i = 0; i < result?.Fields?.length; i++) {
            fields.push({
              Name: result?.Fields[i].Name as string,
              IsPrivate: result?.Fields[i].IsPrivate as boolean,
              EditMode: false,
            });
          }
          setDetailsTemplateFields(fields);
        }
        setSaveDetailsTemplateSetter && setSaveDetailsTemplateSetter(false);
      } else setUpdateError(true);
    } catch (e) {
      setUpdateError(true);
    }
    setUpdating(false);
  };

  const handleFieldNameChange = (index: number, value: string) => {
    let updatedFields = [...detailsTemplateFields];
    if (updatedFields.length) {
      updatedFields[index].Name = value;
      setDetailsTemplateFields(updatedFields);
    }
  };

  const handleFieldPrivacyChange = (index: number, value: boolean) => {
    let updatedFields = Array.from(detailsTemplateFields);
    if (updatedFields.length) {
      updatedFields[index].IsPrivate = value;
      setDetailsTemplateFields(updatedFields);
    }
  };

  const handleEditModeChange = (index: number) => {
    let updatedFields = [...detailsTemplateFields];
    if (updatedFields.length) {
      updatedFields[index].EditMode = true;
      setDetailsTemplateFields(updatedFields);
    }
  };

  const closeAllEditModeChange = () => {
    let updatedFields = [...detailsTemplateFields];
    if (updatedFields.length) {
      for (let i = 0; i < updatedFields.length; i++) {
        updatedFields[i].EditMode = false;
      }
      setDetailsTemplateFields(updatedFields);
    }
  };

  const deleteField = (index: number) => {
    let updatedFields = [...detailsTemplateFields];
    if (updatedFields.length) {
      updatedFields.splice(index, 1);
      setDetailsTemplateFields(updatedFields);
    }
  };

  const addField = () => {
    let updatedFields = [...detailsTemplateFields];
    updatedFields.push({
      Name: '',
      IsPrivate: false,
      EditMode: true,
    });
    setDetailsTemplateFields(updatedFields);
  };

  useEffect(() => {
    if (loading) fetchDetailsTemplate();
  }, [loading]);

  useEffect(() => {
    if (detailsTemplateFields.length) {
      if (detailsTemplateFields.some((item) => item.Name === '')) setValidated(false);
      else setValidated(true);
    }
  }, [detailsTemplateFields]);

  useEffect(() => {
    if (saveDetailsTemplate) {
      if (!isUnderTransaction) updateDetailsTemplate();
      closeAllEditModeChange();
    }
  }, [saveDetailsTemplate]);

  if (loading) {
    return <Spinner loaderClassName={classNames(styles.loader)} />;
  } else if (error || updateError) {
    return (
      <div>
        <PageNotFound backUrl={routes.templatesTransactions} />
        <div className={styles.Footer}>
          <Footer />
        </div>
      </div>
    );
  } else
    return (
      <div className={styles.detailTemplate}>
        <div className={styles.content}>
          {detailsTemplateFields.map((item: any, index) => (
            <Field
              key={index}
              serialNumber={index}
              fieldName={item.Name}
              isPrivate={item.IsPrivate}
              fieldEditMode={item.EditMode}
              viewMode={viewMode}
              disabled={disabled}
              nameChangeEventHandler={handleFieldNameChange}
              privacyChangeEventHandler={handleFieldPrivacyChange}
              editModeChangeHandler={handleEditModeChange}
              deleteFieldHandler={deleteField}
            />
          ))}
        </div>
        <div className={styles.footer}>
          {!(disabled || viewMode) ? (
            <button
              disabled={detailsTemplateFields.length === fieldsLimit || updating}
              className={classNames(
                styles.add,
                detailsTemplateFields.length === fieldsLimit || updating ? styles.btnDisabled : '',
              )}
              onClick={(e) => {
                addField();
              }}
            >
              <Icons iconName="add" />
              <span>Add Field</span>
            </button>
          ) : (
            <></>
          )}
          {!disabled && !isUnderTransaction && detailsTemplateFields.length ? (
            <button
              disabled={updating || !validated}
              onClick={(e) => {
                updateDetailsTemplate();
                closeAllEditModeChange();
              }}
              className={classNames(
                styles.SaveBtn,
                updating || !validated ? styles.btnDisabled : '',
              )}
            >
              {updating ? (
                <Spinner loaderClassName={classNames(styles.whiteLoader)} />
              ) : (
                <p className={styles.SaveBtnText}>Save</p>
              )}
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
}
