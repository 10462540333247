import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import {
  ScrollDrawerWrapper,
  DrawerPaddingWrapper,
  Footer,
  ParkingSpaces,
} from 'pages/Properties/Feed/components';
import { GarageOnly } from 'pages/Properties/Feed/components';
import {
  updateSearchCriteriaEffect,
  resetPropertiesWholeTabsEffect,
  openFeedFilterDrawerEffect,
} from 'store/effects';
import { getUserSearchCriteriaPrefsSelector } from 'store/selectors/user';
import {
  Beds,
  Baths,
  Locations,
  MinMax,
  YearMinMax,
  PropertyType,
  Input,
  Button,
  Wrapper as PendingWrapper,
  Select,
  LotSizeMinMax,
} from 'components';
import { sortStrings, sortLocations, sortStatus } from 'helpers';
import { propertyStatusOptions } from 'settings/constants/properties';
import { ValidationSchema } from './validation';

import { mergeInitialValues } from './helpers';

import styles from './styles.module.scss';

const PrimaryCriteria = ({ className }) => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);

  const searchPrefsValue = useSelector(getUserSearchCriteriaPrefsSelector);

  const formik = useFormik({
    initialValues: mergeInitialValues(searchPrefsValue),
    validationSchema: ValidationSchema,
    onSubmit(values) {
      setIsPending(true);
      dispatch(
        updateSearchCriteriaEffect(values, {}, (err, res) => {
          if (!err) {
            dispatch(resetPropertiesWholeTabsEffect());
            const updatedSearchPrefsValue =
              res.data?.result?.Client?.SearchInstances[0].DefaultPropertySearchPreferences;
            //TODO Support Context Switching
            if (updatedSearchPrefsValue) {
              formik.resetForm({ values: mergeInitialValues(updatedSearchPrefsValue) });
            }
            dispatch(openFeedFilterDrawerEffect({ open: false }));
          }
          setIsPending(false);
        }),
      );
    },
  });

  const garageOnly = (
    <GarageOnly
      value={formik.values?.GarageOnly}
      onChange={(value) => formik.setFieldValue('GarageOnly', value)}
      disabled={formik.values?.MinParkingSpaces == 0}
    />
  );

  const flatValue = (propName) => formik?.values?.[propName];
  return (
    <div>
      <PendingWrapper className={styles.pendingWrapper} isPending={false}>
        <ScrollDrawerWrapper>
          <DrawerPaddingWrapper className={className}>
            <form id="primaryCriteria" onSubmit={formik.handleSubmit}>
              <Select
                name="Status"
                label="Status"
                multiple
                className={{ wrapper: classNames(styles.field, styles.statusField) }}
                variant={Select.LIGHT_FULL}
                options={propertyStatusOptions}
                value={formik.values.Status}
                onSelect={(event, val) => {
                  if (val?.length) val.sort(sortStatus);
                  formik.setFieldValue('Status', val);
                }}
                disabled={isPending}
                testid="status_select"
              />
              <Locations
                className={styles.field}
                valuesWrapperClassName={styles.locationsValues}
                name="Locations"
                label="Locations"
                onResult={(result, preparedData) => {
                  if (preparedData?.length) preparedData.sort(sortLocations);
                  formik.setFieldValue('Locations', preparedData);
                }}
                error={formik.touched.Locations ? formik.errors.Locations : ''}
                disabled={isPending}
                value={formik.values.Locations}
                testid="locations"
              />
              <MinMax
                className={styles.field}
                label="Price"
                onChangeMin={(e, val) =>
                  formik.setFieldValue('PriceRange', {
                    ...flatValue('PriceRange'),
                    Min: val,
                  })
                }
                onChangeMax={(e, val) =>
                  formik.setFieldValue('PriceRange', {
                    ...flatValue('PriceRange'),
                    Max: val,
                  })
                }
                valueMin={formik.values?.PriceRange?.Min}
                valueMax={formik.values?.PriceRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                prefix="$"
                disabled={isPending}
                testidMin="price_min"
                testidMax="price_max"
              />
              <Beds
                buttonGroupClassName={styles.buttonGroup}
                className={styles.field}
                disabled={isPending}
                onChange={(val) =>
                  formik.setFieldValue('NumBedroomsRange', {
                    ...flatValue('NumBedroomsRange'),
                    Min: val && val !== 'any' ? val : '',
                  })
                }
                value={
                  formik.values?.NumBedroomsRange?.Min
                    ? [formik.values?.NumBedroomsRange?.Min]
                    : null
                }
              />
              <Baths
                buttonGroupClassName={styles.buttonGroup}
                className={styles.field}
                disabled={isPending}
                onChange={(val) =>
                  formik.setFieldValue('NumBathroomsRange', {
                    ...flatValue('NumBathroomsRange'),
                    Min: val && val !== 'any' ? val : '',
                  })
                }
                value={
                  formik.values?.NumBathroomsRange?.Min
                    ? [formik.values?.NumBathroomsRange?.Min]
                    : null
                }
              />
              <PropertyType
                className={classNames(styles.field, styles.propertyWrapper)}
                label="Property Type"
                onChange={(type) => {
                  if (type?.length) type.sort(sortStrings);
                  formik.setFieldValue('HomeType', type);
                }}
                disabled={isPending}
                error={formik.touched.HomeType ? formik.errors.HomeType : ''}
                value={formik.values?.HomeType}
                propertyTypeWrapperClassName={styles.filterPropertyWrapper}
                itemClassName={styles.filterItem}
              />
              <MinMax
                className={styles.field}
                label="Square Feet"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(e, val) =>
                  formik.setFieldValue('SquareFeetRange', {
                    ...flatValue('SquareFeetRange'),
                    Min: val,
                  })
                }
                onChangeMax={(e, val) =>
                  formik.setFieldValue('SquareFeetRange', {
                    ...flatValue('SquareFeetRange'),
                    Max: val,
                  })
                }
                valueMin={formik.values?.SquareFeetRange?.Min}
                valueMax={formik.values?.SquareFeetRange?.Max}
                disabled={isPending}
                testidMin="square_min"
                testidMax="square_max"
              />
              <LotSizeMinMax
                isReset
                className={styles.field}
                label="Lot Size"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(val) =>
                  formik.setFieldValue('LotSizeRange', {
                    ...flatValue('LotSizeRange'),
                    Min: val,
                  })
                }
                onChangeMax={(val) =>
                  formik.setFieldValue('LotSizeRange', {
                    ...flatValue('LotSizeRange'),
                    Max: val,
                  })
                }
                valueMin={formik.values?.LotSizeRange?.Min}
                valueMax={formik.values?.LotSizeRange?.Max}
                disabled={isPending}
                testidMin="lot_size_min"
                testidMax="lot_size_max"
              />
              <YearMinMax
                isReset
                className={styles.field}
                label="Year Build"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(val) =>
                  formik.setFieldValue('YearBuiltRange', {
                    ...flatValue('YearBuiltRange'),
                    Min: val,
                  })
                }
                onChangeMax={(val) =>
                  formik.setFieldValue('YearBuiltRange', {
                    ...flatValue('YearBuiltRange'),
                    Max: val,
                  })
                }
                valueMin={formik.values?.YearBuiltRange?.Min}
                valueMax={formik.values?.YearBuiltRange?.Max}
                disabled={isPending}
                testidMin="year_min"
                testidMax="year_max"
              />
              <div className={classNames(styles.pseudo, styles.field)}>
                <Input
                  name="HOARange.Max"
                  className={classNames(styles.field, styles.hoa)}
                  variant={Input.LIGHT_FULL}
                  placeholder="$"
                  isNumericString
                  isNumberFormat
                  label="Maximum HOA"
                  prefix="$"
                  onChange={formik.handleChange}
                  value={formik.values.HOARange.Max}
                  disabled={isPending}
                  testid="max_hoa"
                />
                <p className={styles.pseudoPlaceholder}>Per month</p>
              </div>
              <ParkingSpaces
                className={classNames(styles.parkingSpaces)}
                onChange={(val) => formik.setFieldValue('MinParkingSpaces', val)}
                value={formik.values.MinParkingSpaces}
                disabled={isPending}
              >
                {garageOnly}
              </ParkingSpaces>
              <MinMax
                className={styles.field}
                thousandSeparator={false}
                label="Stories"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(e, val) => {
                  formik.setFieldValue('StoriesRange', { ...flatValue('StoriesRange'), Min: val });
                  if (searchPrefsValue?.Stories) {
                    formik.setFieldValue('Stories', {
                      ...flatValue('Stories'),
                      StoriesRange: { ...flatValue('StoriesRange'), Min: val },
                    });
                  }
                }}
                onChangeMax={(e, val) => {
                  formik.setFieldValue('StoriesRange', { ...flatValue('StoriesRange'), Max: val });

                  if (searchPrefsValue?.Stories) {
                    formik.setFieldValue('Stories', {
                      ...flatValue('Stories'),
                      StoriesRange: { ...flatValue('StoriesRange'), Max: val },
                    });
                  }
                }}
                valueMin={formik.values?.StoriesRange?.Min}
                valueMax={formik.values?.StoriesRange?.Max}
                disabled={isPending}
                testidMin="stories_min"
                testidMax="stories_max"
              />
              <Input
                name="MaxDaysOnMarket"
                className={styles.field}
                variant={Input.LIGHT_FULL}
                placeholder="No max"
                isNumericString
                isNumberFormat
                label="Days on Market"
                onChange={formik.handleChange}
                value={formik.values.MaxDaysOnMarket}
                disabled={isPending}
                testid="time_on_market"
              />
            </form>
          </DrawerPaddingWrapper>
        </ScrollDrawerWrapper>
        <Footer>
          <Button
            testid="save_button"
            isPending={isPending}
            form="primaryCriteria"
            className={styles.submitButton}
            type="submit"
            title="Save"
            disabled={!formik.dirty}
          />
        </Footer>
      </PendingWrapper>
    </div>
  );
};

PrimaryCriteria.initialValues = {
  Locations: [],
  HOARange: { Max: '' },
  MinParkingSpaces: 0,
  GarageOnly: false,
  Status: [],
  MaxDaysOnMarket: '',
  HomeType: [],
  PriceRange: {
    Min: '',
    Max: '',
  },
  NumBedroomsRange: {
    Min: '',
  },
  NumBathroomsRange: {
    Min: '',
  },
  SquareFeetRange: {
    Min: '',
    Max: '',
  },
  LotSizeRange: {
    Min: '',
    Max: '',
  },
  YearBuiltRange: {
    Min: '',
    Max: '',
  },
  StoriesRange: {
    Min: '',
    Max: '',
  },
};

PrimaryCriteria.propTypes = {
  className: PropTypes.string,
};

PrimaryCriteria.defaultProps = {
  className: '',
};

export default PrimaryCriteria;
