import classNames from 'classnames';
import { ShowingsArchiveStatus, ShowingsStatus } from 'app-constants';

import styles from './styles.module.scss';

export const Status = ({ status }) => {
  function getStatusClassName() {
    switch (status) {
      case ShowingsStatus.Pending:
        return styles.pending;
      case ShowingsStatus.Upcoming:
        return styles.upcoming;
      case ShowingsStatus.NewFeedback:
        return styles.newFeedback;
      case ShowingsArchiveStatus.Past:
        return styles.archived;
      case ShowingsArchiveStatus.Declined:
        return styles.archived;
      case ShowingsArchiveStatus.Canceled:
        return styles.archived;
      default:
        return '';
    }
  }

  return (
    <div className={classNames(styles.showingStatus, getStatusClassName())} testid="showing_status">
      <span className={styles.statusText}>{status}</span>
    </div>
  );
};
