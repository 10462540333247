import { useEffect } from 'react';

import { FormMode, ResponseType } from 'types';
import { FORM_MODE } from 'app-constants';
import { Radio } from 'components-antd';

import styles from './styles.module.scss';

interface YesNoQuestionProps {
  mode: FormMode;
  formResponse?: ResponseType;
  inputState?: string | boolean;
  setInputState?: (value: boolean) => void;
  handleFormResponse?: ({ Answer }: Partial<ResponseType>) => void;
}

export const YesNoQuestion = ({
  mode,
  formResponse,
  inputState,
  setInputState,
  handleFormResponse,
}: YesNoQuestionProps) => {
  const editMode = mode === FORM_MODE.Edit;

  const handleUserInput = (value: string | boolean) => {
    if (setInputState && (value === true || value === false)) {
      setInputState(value);
    } else if (formResponse?.UUID && handleFormResponse) {
      handleFormResponse({ Answer: value as string });
    }
  };

  useEffect(() => {
    if (handleFormResponse) {
      if (formResponse?.Answer === 'true') handleFormResponse({ Answer: 'Yes' });
      else if (formResponse?.Answer === 'false') handleFormResponse({ Answer: 'No' });
    }
  }, [formResponse?.UUID]);

  return (
    <Radio.Group
      className={styles.yesNoInput}
      disabled={editMode}
      value={formResponse?.Answer || inputState}
      onChange={(e) => handleUserInput(e.target.value)}
      buttonStyle="solid"
    >
      <Radio.Button className={styles.yesRadioBtn} value={formResponse ? 'Yes' : true}>
        Yes
      </Radio.Button>
      <Radio.Button className={styles.noRadioBtn} value={formResponse ? 'No' : false}>
        No
      </Radio.Button>
    </Radio.Group>
  );
};
