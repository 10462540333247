import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const MapControl = ({ position, map, children, className }) => {
  const controlDiv = useMemo(() => document.createElement('div', {}), []);

  useEffect(() => {
    const controls = map?.controls?.[position];
    const index = controls?.length;
    controls?.push(controlDiv);
    return () => controls?.removeAt(index);
  }, [map, position, controlDiv]);

  return createPortal(
    <div className={classNames(styles.mapControl, className)}>{children}</div>,
    controlDiv,
  );
};

MapControl.propTypes = {
  position: PropTypes.number,
  map: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

MapControl.defaultProps = {
  position: 0,
  map: undefined,
  className: '',
};

export default MapControl;
