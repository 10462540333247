import { push } from 'connected-react-router';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { Button, Input, Spinner } from 'components';
import { Question } from 'pages/RequestQuote/components';
import { PENDING } from 'settings/constants/apiState';
import { routes } from 'settings/navigation/routes';
import { loginEffect } from 'store/effects';
import { resetPasswordEffect } from 'store/effects/loginGroup';
import { getResetPasswordSelector } from 'store/selectors/loginGroup';
import { ValidationSchema } from './validation';

import { getTokenValidationStatus } from 'api/user';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

const Form = () => {
  const resetPassword = useSelector(getResetPasswordSelector);
  const dispatch = useDispatch();
  const params = useParams();
  const match = useRouteMatch();
  const history = useHistory();

  const [checkStatus, setCheckStatus] = useState(true);
  const [isLoading, setIsloading] = useState(true);

  const onSubmit = (values) => {
    dispatch(
      resetPasswordEffect({ ...values, token: params.token }, {}, (err, resp) => {
        if (!err) {
          const isNewUser = match.path === routes.enrollAccount;
          if (isNewUser) {
            const cfg = { email: resp?.data?.user?.Email, password: values.password };
            dispatch(loginEffect(cfg, { isNewUser: isNewUser }));
          } else {
            //If not new user, route to login instead to support MFA
            dispatch(push('/login/reset'));
          }
        }
      }),
    );
  };

  const formik = useFormik({
    initialValues: { password: '', confirmPassword: '' },
    validationSchema: ValidationSchema,
    validateOnChange: true,
    onSubmit,
  });

  const isPending = resetPassword.state === PENDING;

  const getTitle = () => {
    if (match.path === routes.enrollAccount) {
      return 'Create Password';
    }
    return 'Reset Password?';
  };

  const getSubmitButtonText = () => {
    if (match.path === routes.enrollAccount) {
      return 'Continue';
    }
    return 'Reset';
  };

  useEffect(() => {
    if (match.path === routes.enrollAccount && checkStatus) {
      setCheckStatus(false);
      getTokenValidationStatus({ token: params.token }).then((res) => {
        if (res.data === false) {
          history.push('/login');
        }
        setIsloading(false);
      });
    } else {
      setIsloading(false);
    }
  }, [match, checkStatus]);

  if (isLoading) {
    return (
      <div className={styles.resetPassword}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.resetPassword}>
      <div className={styles.resetPasswordInner}>
        <Question className={styles.question}>{getTitle()}</Question>
        <form onSubmit={formik.handleSubmit}>
          <Input
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder="New Password"
            className={styles.input}
            error={formik.touched.password ? formik.errors.password : ''}
            disabled={isPending}
            testid="password"
            variant={Input.LIGHT_ROUND}
            // replacePlaceholderWithLabelWhenHasValue
          />
          <Input
            type="password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            placeholder="Confirm Password"
            className={styles.input}
            error={formik.touched.confirmPassword ? formik.errors.confirmPassword : ''}
            disabled={isPending}
            testid="confirm_password"
            variant={Input.LIGHT_ROUND}
            // replacePlaceholderWithLabelWhenHasValue
          />
          <ButtonsContainer>
            <Button
              testid="reset"
              type="submit"
              className={styles.submit}
              title={getSubmitButtonText()}
              isPending={isPending}
            />
          </ButtonsContainer>
        </form>
      </div>
    </div>
  );
};

export default Form;
