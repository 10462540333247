/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'services';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { hasError } = this.state;
    if (hasError) {
      setTimeout(() => {
        this.setState({ hasError: false }, () => {
          this.forceUpdate();
        });
      }, 100);
    }
    Logger.log('ErrorBoundary component', error, errorInfo);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
