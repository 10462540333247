import loginRoutes from './login';
import signUpRoutes from './signUp';
import verifyEmail from './verifyEmail';
import offer from './offer';
import externalVerification from './externalVerification';
import kitCta from './kitCta';
import kitsUnsubscribe from './kitsUnsubscribe';
import paywall from './paywall';
import ShortUrl from './shortUrl';

export default [
  ...loginRoutes,
  ...signUpRoutes,
  ...verifyEmail,
  ...externalVerification,
  ...offer,
  ...kitCta,
  ...kitsUnsubscribe,
  ...paywall,
  ...ShortUrl,
];
