import React, { memo, useMemo } from 'react';

const Logout = memo(({ className }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5839 8.16687V6.33343C14.5839 5.84718 14.3907 5.38084 14.0469 5.037C13.703 4.69316 13.2367 4.5 12.7504 4.5H6.33343C5.84718 4.5 5.38084 4.69316 5.037 5.037C4.69316 5.38084 4.5 5.84718 4.5 6.33343V17.334C4.5 17.8203 4.69316 18.2866 5.037 18.6305C5.38084 18.9743 5.84718 19.1675 6.33343 19.1675H12.7504C13.2367 19.1675 13.703 18.9743 14.0469 18.6305C14.3907 18.2866 14.5839 17.8203 14.5839 17.334V15.5006"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.16797 11.8361H21.002M21.002 11.8361L18.2518 9.08594M21.002 11.8361L18.2518 14.5862"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

export default Logout;
