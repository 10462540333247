import { Tabs } from 'components-antd';

import { Volume } from './Volume';
import { Clients } from './Clients';
import { Financials } from './Financials';

import styles from './styles.module.scss';

export const HeaderTabs = () => {
  const tabItems = [
    {
      key: '1',
      label: 'Volume',
      children: <Volume />,
      disabled: true,
    },
    {
      key: '2',
      label: 'Clients',
      children: <Clients />,
      disabled: true,
    },
    {
      key: '3',
      label: 'Financials',
      children: <Financials />,
    },
  ];

  return <Tabs className={styles.headerTabs} defaultActiveKey="3" items={tabItems} />;
};
