import { useEffect, useRef, useState } from 'react';

interface Phone {
  PhoneNumber: string;
  IsVerified: boolean;
  IsPrimary: boolean;
}

export function usePhoneVerification(
  userPhones: Phone[] | null,
  onRequirePhoneVerification: () => {},
) {
  const primaryUserPhone = userPhones?.find((phone) => phone.IsPrimary) ?? null;
  const isPhoneNumberVerified = primaryUserPhone?.IsVerified ?? null;
  const onPhoneNumberVerified = useRef(() => {});

  const [isWaitingForPhoneVerification, setWaitingForPhoneVerification] = useState(false);

  useEffect(() => {
    if (isWaitingForPhoneVerification && isPhoneNumberVerified) {
      onPhoneNumberVerified.current();
      setWaitingForPhoneVerification(false);
    }
  }, [isWaitingForPhoneVerification, userPhones]);

  const requestPhoneVerification = (onVerifiedCallback: () => {}) => {
    onPhoneNumberVerified.current = onVerifiedCallback;

    setWaitingForPhoneVerification(true);
    onRequirePhoneVerification();
  };

  return {
    isPhoneNumberVerified,
    isWaitingForPhoneVerification,
    requestPhoneVerification,
  };
}
