import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'services/history';

import app from './app';
import user from './user';
import loginGroup from './loginGroup';
import onBoarding from './onBoarding';
import clientsList from './clientsList';
import teamList from './teamList';
import feed from './feed';
import feedv3 from './feedv3';
import listingDetail from './listingDetail';
import mlsScores from './mlsScores';
import searchResults from './searchResults';
import search from './search';
import mySearches from './mySearches';
import clientSearches from './clientSearches';
import sockets from './sockets';
import drawers from './drawers';
import searchMessages from './searchMessages';
import quotes from './quotes';
import quoteDetails from './quoteDetails';
import requestQuote from './requestQuote';
import servicesCategories from './servicesCategories';
import adminPanel from './adminPanel';
import partners from './partners';
import singlePartner from './singlePartner';
import transactionCreate from './transactionCreate';
import transactions from './transactions';
import transaction from './transaction';
import templates from './templates';
import template from './template';
import transactionRoles from './transactionRoles';
import transactionTasks from './transactionTasks';
import transactionTask from './transactionTask';
import transactionMessages from './transactionMessages';
import transactionNotes from './transactionNotes';
import transactionDocuments from './transactionDocuments';
import transactionFolder from './transactionFolder';
import transactionTaskCategories from './transactionTaskCategories';
import transactionDocumentsCategories from './transactionDocumentsCategories';
import transactionActivities from './transactionActivities';
import context from './context';
import contexts from './contexts';
import { formBuilderReducer } from './formBuilder';
import formComment from './formComment';
import { conciergeConfigurationReducer } from './concierge';
import agents from './agents';
import clientInstances from './clientInstances';
import clientDetail from './clientDetail';
import showingAppointment from './showingAppointment';
import subscription from './subscription';
import showingAggregate from './showingAggregate';
import offerAggregate from './offerAggregate';
import propertyFeatures from './propertyFeatures';
import offer from './offer';
import requestFormProcess from './requestFormProcess';
import relatedEntities from './relatedEntities';
import activityLogsWorkshop from './activityLogsWorkshop';
import mlsAccess from './mlsAccess';
import taskAggregate from './taskAggregate';
import milestoneAggregate from './milestoneAggregate';
import symphony from './symphony';
import agentDetail from './agentDetail';
import agentTeamDetail from './agentTeamDetail';
import collaboratorsList from './collaboratorsList';
import vault from './vault';
import workshop from './workshop';
import offerBuyingAgents from './offerBuyingAgents';
import projects from './projects';
import tour from './tour';
import ai from './ai';
import graphs from './agentDashboard/graphs';
import needsAttention from './agentDashboard/needsAttention';
import schedule from './agentDashboard/schedule';
import mozzie from './mozzie';
import clientDashboard from './clientDashboard';
import clarity from './clarity';
import salesLead from './salesLead';

export default combineReducers({
  router: connectRouter(history),
  app,
  user,
  loginGroup,
  onBoarding,
  clientsList,
  teamList,
  feed,
  feedv3,
  listingDetail,
  mlsScores,
  searchResults,
  search,
  mySearches,
  clientSearches,
  sockets,
  drawers,
  searchMessages,
  quotes,
  quoteDetails,
  requestFormProcess,
  requestQuote,
  servicesCategories,
  adminPanel,
  partners,
  singlePartner,
  transactionCreate,
  transactions,
  transaction,
  templates,
  template,
  transactionRoles,
  transactionTasks,
  transactionTask,
  transactionMessages,
  transactionNotes,
  transactionDocuments,
  transactionFolder,
  transactionTaskCategories,
  transactionDocumentsCategories,
  transactionActivities,
  context,
  contexts,
  formBuilder: formBuilderReducer,
  formComment,
  agents,
  clientInstances,
  clientDetail,
  conciergeConfiguration: conciergeConfigurationReducer,
  showingAppointment: showingAppointment,
  subscription,
  showingAggregate,
  offerAggregate,
  propertyFeatures,
  offer,
  relatedEntities,
  activityLogsWorkshop,
  mlsAccess,
  taskAggregate,
  milestoneAggregate,
  symphony,
  agentDetail,
  agentTeamDetail,
  collaboratorsList,
  vault,
  workshop,
  offerBuyingAgents,
  projects,
  tour,
  ai,
  graphs,
  needsAttention,
  schedule,
  mozzie,
  clientDashboard,
  clarity,
  salesLead,
});
