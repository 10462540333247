import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Close } from 'components/Icons';

import {
  getClientsEffect,
  openShareDrawerEffect,
  setSharePropertyEffect,
  shareDrawerSearchClientsEffect,
  sendSharePropsToRecipientsEffect,
  setRecipientsEffect,
} from 'store/effects';
import { Button, Drawer, Input, Wrapper as PendingWrapper, DrawerComparePhotos } from 'components';

import { IDLE, PENDING } from 'settings/constants/apiState';
import { ScrollDrawerWrapper, DrawerPaddingWrapper } from '../../components';
import Header from './Header';
import ClientsList from './ClientsList';

import { getShareDrawerInfoSelector } from './selectors';
import { showSuccessMessage } from 'helpers/success';
import { showErrorMessage } from 'helpers/errors';

import styles from './styles.module.scss';
import { isArray, map } from 'lodash-es';
import { useOutsideClick } from 'hooks';

const ShareDrawer = ({ className }) => {
  const [pending, setPending] = useState(false);
  const [focus, setFocus] = useState(false);
  const dispatch = useDispatch();
  const {
    drawerOpened,
    clients,
    groupedClients,
    searchValue,
    recipients,
    compares,
    shareProperty,
  } = useSelector(getShareDrawerInfoSelector);

  const valuesWrapperRef = useRef();
  const inputRef = useRef();

  useOutsideClick([valuesWrapperRef, inputRef], () => {
    setFocus(false);
  });

  useEffect(() => {
    if (drawerOpened) {
      if (clients.state === IDLE) {
        dispatch(getClientsEffect());
      } else {
        dispatch(getClientsEffect({}, { silent: true }));
      }
    } else {
      dispatch(setSharePropertyEffect(null));
    }
  }, [drawerOpened]); // eslint-disable-line

  const getNames = (isHtml = true) => {
    if (!recipients.length) return null;

    const names = recipients.map(({ Name }) => Name);
    const joinedNames = names.join(', ');

    if (!isHtml) return joinedNames;

    return (
      <span testid="to_name" className={styles.clientsNames}>
        To: <span>{joinedNames}</span>
      </span>
    );
  };

  const onSendShares = () => {
    setPending(true);
    if (shareProperty) {
      const cfg = { recipients, shares: isArray(shareProperty) ? shareProperty : [shareProperty] };
      return dispatch(
        sendSharePropsToRecipientsEffect(
          cfg,
          {
            clients,
          },
          (err) => {
            setPending(false);

            if (!err) {
              showSuccessMessage(
                recipients?.length
                  ? 'Successfully shared with the client'
                  : 'Property successfully unshared with the client(s).',
              );
              dispatch(setRecipientsEffect([]));
              dispatch(openShareDrawerEffect({ open: false }));
            } else {
              showErrorMessage('An error occurred while sharing with the client ');
            }
          },
        ),
      );
    }

    const cfg = { recipients, shares: compares?.data || [] };

    return dispatch(
      sendSharePropsToRecipientsEffect(cfg, {}, (err) => {
        setPending(false);
        if (!err) {
          dispatch(setRecipientsEffect([]));
          dispatch(openShareDrawerEffect({ open: false }));
          showSuccessMessage('Successfully shared with the client');
        } else {
          showErrorMessage('An error occurred while sharing with the client ');
        }
      }),
    );
  };

  const isPending = clients.state === PENDING;
  const isFooterHint = recipients.length ? { 'data-rh': getNames(false) } : {};

  return (
    <Drawer
      testid="share_drawer"
      onClose={() => dispatch(openShareDrawerEffect(false))}
      className={classNames(styles.shareDrawer, className)}
      isOpen={drawerOpened}
      header={<Header onClose={() => dispatch(openShareDrawerEffect({ open: false }))} />}
    >
      <div className={styles.drawerInner}>
        <div>
          <div className={styles.photoSection}>
            <DrawerComparePhotos
              compares={isArray(shareProperty) ? shareProperty : [shareProperty]}
            />
          </div>
          <div className={styles.inputSection} onClick={() => setFocus(true)}>
            <span ref={valuesWrapperRef}>
              <p className={styles.to}>To:</p>
              {focus ? (
                <span ref={inputRef}>
                  <Input
                    className={styles.searchInput}
                    placeholder="Search name"
                    value={searchValue}
                    onChange={(event, val) =>
                      dispatch(shareDrawerSearchClientsEffect({ search: val }))
                    }
                    testid="search_input"
                    variant={Input.LIGHT_ROUND}
                  />
                </span>
              ) : (
                <div className={styles.valuesContainer}>
                  {map(recipients.slice(0, 3), ({ Name, Id }) => (
                    <div className={styles.value}>
                      {Name}
                      <Close
                        onClick={(e) => {
                          e.stopPropagation();
                          const filteredShares = recipients.filter(
                            (recipient) => recipient.Id !== Id,
                          );
                          dispatch(setRecipientsEffect(filteredShares));
                        }}
                        className={styles.icon}
                      />
                    </div>
                  ))}
                  {recipients.length > 3 && <div className={styles.value}>...</div>}
                </div>
              )}
            </span>
          </div>
          <ScrollDrawerWrapper className={styles.scrollWrapper}>
            <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
              <ClientsList groupedList={groupedClients} />
            </PendingWrapper>
          </ScrollDrawerWrapper>
        </div>
        <div {...isFooterHint} className={styles.footer}>
          {getNames()}
          <Button
            isPending={pending}
            type="submit"
            className={styles.submitButton}
            title="Done"
            onClick={onSendShares}
            testid="send_button"
          />
        </div>
      </div>
    </Drawer>
  );
};

ShareDrawer.propTypes = {
  className: PropTypes.string,
};

ShareDrawer.defaultProps = {
  className: '',
};

export default ShareDrawer;
