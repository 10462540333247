import moment from 'moment';

import { Button, Collapse, Panel } from 'components-antd';
import { TransactionTemplate, RuleType } from 'types';
import { Rule } from '../Rule/Rule';

interface RuleListProps {
  rules: RuleType[];
  onDeleteRule: (id: number) => void;
  transactionTemplate: TransactionTemplate;
}

export const RuleList = ({ rules, onDeleteRule, transactionTemplate }: RuleListProps) => {
  return (
    <Collapse>
      {rules.map((rule, index) => (
        <Panel
          header={
            <>
              {`RULE# ${index + 1} ${moment(rule?.CreatedDate).fromNow()}`}{' '}
              <Button onClick={() => (rule.Id ? onDeleteRule(rule.Id) : undefined)}>Delete</Button>
            </>
          }
          key={`rule-${rule.Id}-${index}`}
        >
          <Rule
            defaultTriggers={rule.triggers}
            defaultActions={rule.actions}
            transactionTemplate={transactionTemplate}
          />
        </Panel>
      ))}
    </Collapse>
  );
};
