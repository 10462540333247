import { get } from 'lodash-es';
import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ adminPanel }) => adminPanel;

const teamState = createSelector(localState, ({ teams }) => teams);

export const getTeamsDashboardSelector = createSelector(teamState, ({ state, meta, ...rest }) => ({
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!rest?.data,
  ...rest,
}));

export const getTeamsDataDashboardSelector = createSelector(teamState, (state) =>
  get(state, 'data'),
);

export const getSelectedTeamNameSelector = createSelector(teamState, (state) =>
  get(state, 'teamName'),
);

export const teamSubscriptionDetailsSelector = createSelector(teamState, (state) =>
  get(state, 'subscription'),
);
