import moment from 'moment';
import { cloneDeep } from 'lodash-es';
import { Button } from 'components';
import Input from 'components/Form/Input';
import { SearchIcon } from 'components/Icons';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from '../components/Table';
import { DEFAULT_PAGE_SIZE } from '../components/Table/configs';
import styles from './styles.module.scss';

import { showErrorMessage } from 'helpers';
import Header from 'pages/Dashboard/components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSalesLeadEffect, fetchSalesLeadSignUpUsersEffect } from 'store/effects/salesLead';
import {
  getSalesLeadListSelector,
  getSalesLeadSignUpUsersSelector,
} from 'store/selectors/salesLead';
import {
  SalesLeadDataSource,
  salesLeadColumns,
  salesLeadColumnsRestricted,
  salesLeadColumnsSignUpUsers,
} from './configs';
import classNames from 'classnames';

export const SalesLead = () => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState<SalesLeadDataSource[]>([]);
  const [signupDataSource, setSignupDataSource] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const salesLead = useSelector(getSalesLeadListSelector);
  const initSalesLead = cloneDeep(salesLead);
  const signupUsers = useSelector(getSalesLeadSignUpUsersSelector);
  const initSignupUsers = cloneDeep(signupUsers);

  const [selectedTab, setSelectedTab] = useState<
    'Enterprise Plan' | 'Restricted' | 'Sign-Up Users'
  >('Enterprise Plan');

  const handleChangePage = useCallback(
    (tableInfo) => {
      let tempData = [...(salesLead?.data?.result ?? [])];
      if (tableInfo?.sortingInfo === 'CreatedDate.asc') {
        tempData = salesLead?.data?.result?.sort(
          (a, b) => moment(a.CreatedDate).unix() - moment(b.CreatedDate).unix(),
        );
      } else if (tableInfo?.sortingInfo === 'CreatedDate.desc') {
        tempData = salesLead?.data?.result?.sort(
          (a, b) => moment(b.CreatedDate).unix() - moment(a.CreatedDate).unix(),
        );
      } else {
        tempData = [...(initSalesLead?.data?.result ?? [])];
      }
      tempData = tempData?.filter((lead) => lead.LeadSource === selectedTab);
      const page = tableInfo?.paginationInfo?.page || 1;
      setCurrentPage(page);
      const startIndex = (page - 1) * pageSize;
      const paginatedData = tempData?.slice(startIndex, startIndex + pageSize);
      setDataSource(paginatedData);
    },
    [salesLead?.data?.result, pageSize, selectedTab],
  );

  const handleSignUpUserSort = useCallback(
    (tableInfo) => {
      let tempData = [...(signupUsers?.data ?? [])];
      if (tableInfo?.sortingInfo === 'User,CreatedDate.asc') {
        tempData?.sort(
          (a, b) => moment(a?.User?.CreatedDate).unix() - moment(b?.User?.CreatedDate).unix(),
        );
      } else if (tableInfo?.sortingInfo === 'User,CreatedDate.desc') {
        tempData?.sort(
          (a, b) => moment(b?.User?.CreatedDate).unix() - moment(a?.User?.CreatedDate).unix(),
        );
      } else {
        tempData = [...(initSignupUsers?.data ?? [])];
      }
      setSignupDataSource(tempData);
    },
    [signupUsers?.data],
  );

  const handleSubmitSearch = useCallback(
    (values) => {
      const searchString = values.searchString.toLowerCase();
      const filteredData = salesLead?.data?.result?.filter(
        (item) =>
          item.Email.toLowerCase().includes(searchString) ||
          item.FirstName.toLowerCase().includes(searchString) ||
          item.LastName?.toLowerCase().includes(searchString),
      );
      setDataSource(filteredData.slice(0, pageSize));
      setTotal(filteredData.length);
      setCurrentPage(1);
    },
    [salesLead?.data, pageSize],
  );

  const handleFetchEvents = useCallback(async () => {
    setLoading(true);
    try {
      dispatch(
        fetchSalesLeadEffect({}, (err) => {
          if (err) {
            return showErrorMessage(err);
          }
        }),
      );
      dispatch(
        fetchSalesLeadSignUpUsersEffect({}, (err) => {
          if (err) {
            return showErrorMessage(err);
          }
        }),
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetchEvents();
  }, []);

  useEffect(() => {
    if (salesLead?.data?.result?.length) {
      let data = salesLead?.data?.result?.filter((lead) => lead.LeadSource === selectedTab);

      setDataSource(data?.slice(0, pageSize));
      setTotal(data?.length);
      setCurrentPage(1);
    }
  }, [salesLead?.data?.result, selectedTab]);

  useEffect(() => {
    if (signupUsers?.data?.length) {
      setSignupDataSource(signupUsers?.data);
    }
  }, [signupUsers?.data, selectedTab]);

  const formik = useFormik({
    initialValues: {
      searchString: '',
    },
    onSubmit: handleSubmitSearch,
  });

  const renderTable = useMemo(() => {
    return (
      <Table
        columns={
          selectedTab === 'Restricted'
            ? salesLeadColumnsRestricted
            : selectedTab === 'Sign-Up Users'
            ? salesLeadColumnsSignUpUsers
            : salesLeadColumns
        }
        dataSource={selectedTab === 'Sign-Up Users' ? signupDataSource || [] : dataSource}
        loading={loading}
        pagination={
          selectedTab !== 'Sign-Up Users' && {
            total,
            current: currentPage,
            pageSize,
            defaultPageSize: DEFAULT_PAGE_SIZE,
            defaultCurrent: 1,
          }
        }
        onChange={selectedTab === 'Sign-Up Users' ? handleSignUpUserSort : handleChangePage}
        className={styles.tableWrapper}
      />
    );
  }, [dataSource, loading, selectedTab, signupDataSource, total, currentPage, pageSize]);
  return (
    <div>
      <Header title="Requests" />
      <div className={styles.tabWrapper}>
        <div
          className={classNames(styles.tab, { [styles.active]: selectedTab === 'Enterprise Plan' })}
          onClick={() => setSelectedTab('Enterprise Plan')}
        >
          Enterprise
        </div>
        <div
          className={classNames(styles.tab, { [styles.active]: selectedTab === 'Restricted' })}
          onClick={() => setSelectedTab('Restricted')}
        >
          Restricted Location
        </div>
        <div
          className={classNames(styles.tab, { [styles.active]: selectedTab === 'Sign-Up Users' })}
          onClick={() => setSelectedTab('Sign-Up Users')}
        >
          Sign-Up
        </div>
      </div>
      {renderTable}
    </div>
  );
};

export default SalesLead;
