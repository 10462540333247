import React, { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactRoutePropTypes from 'react-router-prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { find } from 'lodash-es';
import { Link } from 'react-router-dom';

import { routes } from 'settings/navigation/routes';
import { link } from 'settings/navigation/link';

import { singlePartnerClear } from 'store/actions/singlePartner';
import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import { getPartnerBusinessNameAndId, getPartnerObject } from 'store/selectors/singlePartner';
import { getUserId } from 'store/selectors/user';

import { PageWrapper, PageNotFound, Breadcrumbs, Button } from 'components';
import { getIfExistThread } from 'helpers';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { THIRD_PARTY } from 'settings/constants/roles';
import { getThreadsListSelector } from 'store/selectors/sockets/threads';

import RequestCategoriesWrapper from '../Services/components/RequestCategoriesWrapper';
import RequestPartner from './components/RequestPartner';

import styles from './styles.module.scss';
import Icon from '../Quotes/components/QuoteDetails/Icons';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';

const ServicesCategoryPartner = ({
  location,
  match: {
    params: { partnerId, categoryId },
  },
}) => {
  const dispatch = useDispatch();
  const categories = useSelector(getAllServicesCategoriesList);
  const { id, name } = useSelector(getPartnerBusinessNameAndId);
  const partnerData = useSelector(getPartnerObject);
  const userId = useSelector(getUserId);
  const threads = useSelector(getThreadsListSelector);

  const { breadcrumbs, categoryById } = useMemo(() => {
    const category = find(categories, ({ Id }) => Id === Number(categoryId));
    const bread = [
      { label: 'All Services', link: routes.services },
      ...(category
        ? [
            {
              label: location?.state?.label || category?.Title,
              link: location?.state?.link || link.toServicesCategory(category?.Id),
            },
          ]
        : []),
      ...(id === Number(partnerId)
        ? [{ label: name, link: link.toServicesCategoryPartner({ categoryId, partnerId }) }]
        : []),
    ];

    return { breadcrumbs: bread, categoryById: category };
  }, [categories, categoryId, id, name, partnerId, location]);

  const onMessageButtonClick = useCallback(() => {
    const thread = getIfExistThread(
      threads,
      [id, userId],
      ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT,
    );

    dispatch(openMessagesDrawerAction(true));

    if (!thread) {
      const firstName = partnerData?.ThirdParty?.User?.FirstName;
      const lastName = partnerData?.ThirdParty?.User?.LastName;

      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
          params: {
            threadId: thread?.Id || null,
            participants: [
              {
                id: partnerData?.Id,
                name: `${firstName} ${lastName || ''} (${partnerData?.BusinessName || ''})`,
                value: partnerData?.Id,
                role: THIRD_PARTY,
                avatarUrl: partnerData?.LogoUrl,
                firstName: partnerData?.FirstName,
                lastName: partnerData?.LastName,
              },
            ],
          },
        }),
      );
    } else {
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.CHAT,
          params: {
            threadId: thread.Id,
          },
        }),
      );
    }
  }, [dispatch, threads, id, userId, partnerData]);

  useEffect(
    () => () => {
      dispatch(singlePartnerClear());
    },
    [dispatch],
  );

  return (
    <PageWrapper
      subHeader={
        <div testid="partner_header" className={styles.subHeader}>
          <Breadcrumbs list={breadcrumbs} className={styles.breadcrumbs} />
          <div className={styles.buttons}>
            <Button
              component={Link}
              to={link.toServicesCategoryPartnerQuote({ partnerId, categoryId })}
              className={styles.myQuotesBtn}
              title="Request Quote"
              testid="request_quote_button"
            />
            <Button
              className={styles.messageBtn}
              titleClassName={styles.messageBtnTitle}
              title="Message"
              iconLeft={<Icon variant={Icon.COMMENT} />}
              onClick={onMessageButtonClick}
            />
          </div>
        </div>
      }
    >
      <RequestCategoriesWrapper className={styles.wrapper}>
        {categoryById ? (
          <RequestPartner partnerId={partnerId} />
        ) : (
          <PageNotFound backUrl={routes.services} btnLabel="Go to services" />
        )}
      </RequestCategoriesWrapper>
    </PageWrapper>
  );
};

ServicesCategoryPartner.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      categoryId: PropTypes.string,
      partnerId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: ReactRoutePropTypes.location.isRequired,
};

export default ServicesCategoryPartner;
