export const AGENT_CONNECTION_STATUSES = {
  ACTIVE: 'Accepted',
  RETAINED: 'Retained',
  INACTIVE: 'Inactive',
  PENDING: 'Pending',
  DECLINED: 'Declined',
};

export const MATCH_LEVEL = {
  FULL: 'FULL',
  PARTIAL: 'PARTIAL',
  NONE: 'NONE',
  UNKNOWN: 'UNKNOWN',
};

export const FILE_TYPES = {
  PDF: 'application/pdf',
};

export const uploadFilePopupModes = {
  uploading: 'uploading',
  uploaded: 'uploaded',
};

export const noRanges = {
  noMin: 'noMin',
  noMax: 'noMax',
};

export const FORMATES = {
  ONLY_DATE: 'MM-DD-YYYY',
};
