import React from 'react';

const ArrowContinue = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 6H14M14 6L9 1M14 6L9 11"
        stroke="#163C5A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default ArrowContinue;
