import KitsDataRow from '../KitDataRow';
import EmptyKit from '../EmptyKit';
import styles from './styles.module.scss';
import { TEAM_BASIC } from 'settings/constants/roles';
import { useSelector } from 'react-redux';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

type KitsTablePropsType = {
  data;
  masterControl: boolean;
  kitClickHandler: Function;
  updateKitsConfigurationState: Function;
};

function KitsTable(props: KitsTablePropsType) {
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const owner = agentRole !== TEAM_BASIC;

  return (
    <div className={styles.KitsTable}>
      <table className={styles.table}>
        <tr className={styles.kitsRowHeading}>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>KIT</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Category</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Frequency</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Eligible Property Type(s)</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Eligible Location(s)</div>
          </td>
          {owner ? (
            <td className={styles.kitsCellHeading}>
              <div className={styles.heading}>Permitted To</div>
            </td>
          ) : (
            <></>
          )}
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Next Execution</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Enable</div>
          </td>
        </tr>
        {props.data.length ? (
          props.data.map((item) => (
            <KitsDataRow
              data={item}
              masterControl={props.masterControl}
              kitClickHandler={props.kitClickHandler}
              updateKitsConfigurationState={props.updateKitsConfigurationState}
              owner={owner}
            />
          ))
        ) : (
          <tr>
            <td colSpan={owner ? 7 : 6}>
              <EmptyKit text="No KITs to Display" />
            </td>
          </tr>
        )}
      </table>
    </div>
  );
}

export default KitsTable;
