import { useEffect } from 'react';
import validator from 'validator';
import classNames from 'classnames';
import { cloneDeep, uniq } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';

import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Spinner, PhoneNumber } from 'components';
import { Continue } from '../../Continue';
import Participant from './Participant';
import { ParticipantEmail } from './ParticipantEmail';
import CheckboxComponent from 'components/Form/Checkbox';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { Question } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { FullAccessInfo } from 'components/Modals/TransactionInviteModal/FullAccessInfo';
import { getAssignees } from 'pages/Workshop/Transactions/TransactionCreate/helpers';
import {
  validateParticipantWithEmailMandatory,
  setOptionalEmail,
  validateParticipant,
} from 'pages/Workshop/Transactions/TransactionCreate/helpers';

import { splitFirstOccurrence } from 'utils';

import {
  transactionPreFormQuestionsIds as questionId,
  participantEmpty,
  TransactionUserRoleMap,
} from 'settings/constants/transaction';
import { getUserId } from 'store/selectors/user';
import { requestGetTeamListEffect } from 'store/effects';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { getTransactionRolesSelector } from 'store/selectors/transactionRoles';
import { transactionRoles as TRANSACTION_ROLES } from 'settings/constants/roles';
import {
  getTransactionRolesEffect,
  setTransactionPreFormQuestionsEffect,
} from 'store/effects/transactions';
import { getStateAndDataFlag, getTeamListDataArray } from 'store/selectors/teamList';
import { requestGetUserParticipantsListEffect } from 'store/effects/drawers/addParticipants';
import { getInviteTransactionParticipantsFormattedList } from 'store/selectors/drawers/addParticipants';
import { getInvitedClientData } from 'store/selectors/app';

import styles from './styles.module.scss';

interface ParticipantsProps {
  className?: string;
  onNext?: (...args) => void;
  currentQuestionId?: string;
  previousQuestionId?: string;
  setPreviousQuestionId?: (Id: string) => void;
  setCurrentQuestionId?: (Id: string) => void;
  heading?: string;
}

const Participants = ({
  className,
  onNext,
  currentQuestionId,
  previousQuestionId,
  setPreviousQuestionId,
  setCurrentQuestionId,
  heading = 'Do you want to invite anyone else into the transaction?',
}: ParticipantsProps) => {
  const dispatch = useDispatch();

  const { preForm, isPending } = useSelector(getTransactionPreFormSelector);
  const { transactionRoles } = useSelector(getTransactionRolesSelector);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);
  const { isPending: isTeamListPending } = useSelector(getStateAndDataFlag);
  const teamMembers = useSelector(getTeamListDataArray);

  const assignToRolesQuestion = currentQuestionId === questionId.assignParticipantsToRoles;
  const inviteParticipantQuestion = currentQuestionId === questionId.participants;

  useEffect(() => {
    dispatch(getTransactionRolesEffect());
  }, []);

  const isSomeTaskRoleUnAssigned = () => {
    const tasks = preForm?.[questionId.tasks];
    if (!tasks?.length) return false;

    const assigneeRoles = new Set();
    const taskRolesToBeAssigned = new Set();
    tasks.map((task) => {
      task?.AssigneeList?.map((assignee) => {
        const role = assignee?.role;
        if (role) {
          assigneeRoles.add(TransactionUserRoleMap[role] || role);
        }
      });
      task?.AssigneeRoles?.map((role) => {
        if (role) {
          taskRolesToBeAssigned.add(TransactionUserRoleMap[role] || role);
        }
      });
    });
    let unassigned = false;
    taskRolesToBeAssigned.forEach((role) => {
      if (!assigneeRoles.has(role)) {
        unassigned = true;
      }
    });

    return unassigned;
  };

  useEffect(() => {
    if (
      assignToRolesQuestion &&
      !preForm?.assignParticipantsToRoles?.length &&
      (preForm.transactionTemplate === 'no' || !isSomeTaskRoleUnAssigned())
    ) {
      if (setPreviousQuestionId && setCurrentQuestionId) {
        if (previousQuestionId === questionId.timeline) {
          setPreviousQuestionId(questionId.timeline);
          setCurrentQuestionId(questionId.participants);
        } else if (previousQuestionId === questionId.participants) {
          setPreviousQuestionId(questionId.participants);
          setCurrentQuestionId(questionId.timeline);
        } else if (previousQuestionId === questionId.creatorRole) {
          setPreviousQuestionId(questionId.creatorRole);
          setCurrentQuestionId(questionId.agents);
        } else if (previousQuestionId === questionId.agents) {
          setPreviousQuestionId(questionId.agents);
          setCurrentQuestionId(questionId.coordinators);
        }
      }
    }
  }, [currentQuestionId, assignToRolesQuestion]);

  const preFormQuestionId = assignToRolesQuestion
    ? questionId.assignParticipantsToRoles
    : questionId.participants;

  const participantsValues = cloneDeep(preForm?.[preFormQuestionId]) || [];

  const updateStoredParticipants = (values) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [preFormQuestionId]: [...values],
      }),
    );
  };

  const updateAssignToRoleParticipants = () => {
    const tasks = preForm?.[questionId.tasks] || [];
    const clients = preForm?.[questionId.clients]?.filter((client) => client.Id || client.id) || [];
    let unassignedRoles: string[] = [];
    tasks.forEach((task) => {
      task?.AssigneeRoles?.forEach((role) => {
        // Check if the role is present in any of the AssigneeList role
        const isRoleAssigned = task.AssigneeList.some(
          (assignee) => assignee.role === TransactionUserRoleMap[role] || assignee.role === role,
        );
        if (!isRoleAssigned) {
          unassignedRoles = [...unassignedRoles, role];
        }
      });

      task?.CcRoles?.forEach((role) => {
        // Check if the role is present in any of the CcList role
        const isRoleAssigned = task.CcList.some(
          (assignee) => assignee.role === TransactionUserRoleMap[role] || assignee.role === role,
        );
        if (!isRoleAssigned) {
          unassignedRoles = [...unassignedRoles, role];
        }
      });
    });
    unassignedRoles = uniq(unassignedRoles);
    const participantRoles = [...participantsValues, ...clients].map(
      (participant) => participant.tagRole,
    );
    const unassignedUniqueRoles = unassignedRoles?.filter(
      (role) => !participantRoles.includes(role),
    );
    const assignToRoles = unassignedUniqueRoles
      ?.filter((role) => role !== preForm?.creatorRole?.value)
      ?.map((role) => {
        const existingRole = transactionRoles?.find(
          (transactionRole) => transactionRole.Title === role,
        );
        return {
          ...participantEmpty,
          ...(existingRole
            ? {
                tagRole: existingRole.Title,
                roleId: existingRole.Id,
              }
            : { tagRole: role }),
          invite: true,
        };
      });
    updateStoredParticipants([...participantsValues, ...assignToRoles]);
  };

  useEffect(() => {
    if (
      preForm.transactionTemplate === 'yes' &&
      assignToRolesQuestion &&
      isSomeTaskRoleUnAssigned()
    ) {
      updateAssignToRoleParticipants();
    }
  }, [currentQuestionId, assignToRolesQuestion]);

  const onAdd = () => {
    participantsValues.push({ ...participantEmpty });
    updateStoredParticipants(participantsValues);
  };

  const onDeleteParticipant = (index) => {
    participantsValues.splice(index, 1);
    updateStoredParticipants(participantsValues);
  };

  const onRemoveData = (index) => {
    participantsValues[index] = {
      ...participantEmpty,
      ...(assignToRolesQuestion
        ? {
            tagRole: participantsValues[index]?.tagRole,
            roleId: participantsValues[index]?.roleId,
          }
        : undefined),
    };
    updateStoredParticipants(participantsValues);
  };

  const onEmailInput = (value: string, index: number) => {
    const email = value.trim();
    updateParticipantValues(
      {
        customEmail: email,
        error: email && !validator.isEmail(email) ? 'Please enter a valid email' : undefined,
      },
      index,
    );
  };

  const onPhoneInput = (value: string, index: number) => {
    const phoneNum = value.trim();
    updateParticipantValues(
      {
        Phones: [
          {
            PhoneNumber: phoneNum,
            PhoneType: 'Mobile',
            IsPrimary: true,
            IsVerified: false,
          },
        ],
      },
      index,
    );
  };

  const onPhoneInputBlur = (value: string, index: number) => {
    const phoneNum = value.trim();
    updateParticipantValues(
      {
        phoneNoError:
          phoneNum && !validator.isMobilePhone(phoneNum, 'en-US')
            ? 'Please enter a valid phone number'
            : undefined,
      },
      index,
    );
  };

  const insertName = (value, index: number) => {
    const inputValue = value?.trim();
    const { first, remainder } = splitFirstOccurrence(inputValue, ' ');
    if (inputValue !== '') {
      updateParticipantValues(
        {
          customName: inputValue,
          customEmail: participantsValues[index].customEmail,
          email: '',
          id: '',
          value: '',
          name: '',
          firstName: first,
          lastName: remainder,
          role: '',
          tagRole: participantsValues[index]?.tagRole,
          roleId: participantsValues[index]?.roleId,
          error: '',
        },
        index,
      );
    }
  };

  const insertRole = (event, index) => {
    const inputValue = event.target.value.trim();

    if (inputValue !== '') {
      updateParticipantValues(
        {
          tagRole: inputValue,
        },
        index,
      );
    }
  };

  const onBlurClientInput = (value, index) => {
    insertName(value, index);
  };

  const updateParticipantValues = (obj, index) => {
    participantsValues[index] = {
      ...(participantsValues?.[index] || {}),
      ...obj,
    };
    updateStoredParticipants(participantsValues);
  };

  const onChangeClient = (value, index) => {
    updateParticipantValues(
      {
        ...value,
        customEmail: value.email,
        customName: undefined,
        error: undefined,
        roleId: participantsValues[index]?.roleId,
        tagRole: participantsValues[index]?.tagRole,
        name: `${value?.firstName} ${value?.lastName}`,
      },
      index,
    );
  };

  const onRoleKeyPress = (event, index) => {
    if (event.key === 'Enter') {
      insertRole(event, index);
    }
  };

  const onChangeRole = (value, index) => {
    updateParticipantValues(
      {
        tagRole: value?.name || value,
        roleId: value?.roleId,
      },
      index,
    );
  };
  const onInvite = (checked, index) => {
    updateParticipantValues(
      {
        invite: checked,
      },
      index,
    );
  };

  const canContinue = () => validateParticipantWithEmailMandatory(participantsValues);

  const getQuestionTitle = () => {
    if (assignToRolesQuestion)
      return 'The transaction template you selected has tasks designated for the following roles.';
    else return heading;
  };

  const handleContinue = () => {
    onNext?.();
  };

  const onChangeAccess = (value, index) => {
    updateParticipantValues(
      {
        transactionAccess: value,
      },
      index,
    );
  };

  const showFullAccess = (id: number, tagRole: string) => {
    const isSelectedUserTeamMember = !!teamMembers?.find((el: { Id: number }) => el.Id === id);
    return isTeamAgentActive && isSelectedUserTeamMember;
  };

  return (
    <div
      testid="invite_participants"
      className={classNames(styles.participants, styles.container, className)}
    >
      <Question
        className={classNames(styles.question, {
          [styles.assignToRolesQuestion]: assignToRolesQuestion,
        })}
      >
        {getQuestionTitle()}
      </Question>
      {assignToRolesQuestion ? (
        <Question>Who do you want to assign these roles to?</Question>
      ) : (
        <></>
      )}
      <AnswersContainer className={styles.answersContainer}>
        {isTeamListPending ? (
          <Spinner />
        ) : (
          <>
            <div>
              {participantsValues.map((participant, index) =>
                inviteParticipantQuestion || assignToRolesQuestion || participant?.invite ? (
                  <div className={styles.wrap}>
                    <Participant
                      key={participant?.id || index}
                      onBlurClientInput={(value) => onBlurClientInput(value, index)}
                      onChangeClient={(value) => onChangeClient(value, index)}
                      onRoleKeyPress={(event) => onRoleKeyPress(event, index)}
                      onChangeRole={(e, value) => onChangeRole(value, index)}
                      onDelete={() => onDeleteParticipant(index)}
                      onRemoveData={() => onRemoveData(index)}
                      value={participant}
                      isParInfo={inviteParticipantQuestion}
                      isAssignRoleInfo={assignToRolesQuestion}
                      participants={participantsValues}
                      isAllowDuplicates={assignToRolesQuestion}
                      allowUser={assignToRolesQuestion}
                    />
                    {participant.customName ? (
                      <div>
                        <div className={styles.displayInputLabelWrap}>
                          <p className={styles.inputLabel}>Email</p>
                        </div>
                        <ParticipantEmail
                          customEmail={participant.customEmail}
                          error={participant.error}
                          placeholder=" "
                          onUpdate={() => {}}
                          onBlurInput={(value) => onEmailInput(value, index)}
                          inputHolderClassName={styles.customName}
                          className={styles.clientEmailInput}
                        />
                      </div>
                    ) : null}
                    {showFullAccess(participant.id, participant.tagRole) ? (
                      <div className={styles.fullAccessContainer}>
                        <CheckboxComponent
                          id="FullAccess"
                          label="Full Access"
                          checked={participant.transactionAccess}
                          onChange={(e, v, checked) => onChangeAccess(checked, index)}
                          direction={CheckboxComponent.DIRECTION_RIGHT}
                          name="FullAccess"
                          labelTextClassName={styles.checkboxLabel}
                          className={styles.checkbox}
                        />
                        <FullAccessInfo />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <></>
                ),
              )}
            </div>
            <div onClick={onAdd} className={styles.add}>
              <Icon className={styles.addIcon} variant={Icon.ADDPLAIN} />
              <div testid="invite_another" className={styles.addText}>
                {participantsValues.length < 1 ? 'Add' : 'Add another'}
              </div>
            </div>
            {assignToRolesQuestion ? (
              <p className={styles.disclaimer}>
                Anyone you add to one of these roles will be invited to your transaction room. If
                you don&apos;t assign a role, any tasks related to this role will be assigned to
                you.
              </p>
            ) : (
              <></>
            )}
          </>
        )}
      </AnswersContainer>
      <ButtonsContainer buttonsContainerClassName={styles.buttonCon}>
        <Continue
          className={classNames(styles.continue)}
          onClick={handleContinue}
          isPending={isPending}
          disabled={!canContinue()}
        />
      </ButtonsContainer>
    </div>
  );
};

export default Participants;
