import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from 'hooks';

import { getSearchDrawerCommuteIdSelector } from 'store/selectors/searchResults';
import { Drawer } from 'components';
import { Tabs, TabPane } from 'components-antd';
import Header from './Header';
import CommuteForm from './CommuteForm';
import CommuteHeader from './CommuteHeader';
import Commutes from './Tabs/Commutes';
import PrimaryCriteria from './Tabs/PrimaryCriteria';
import ImportantFeatures from './Tabs/ImportantFeatures';
import { openSearchResultsFilterDrawerEffect, setSearchDrawerCommuteIdEffect } from 'store/effects';
import {
  getIsSearchResultsFilterDrawerOpenSelector,
  getSearchResultsFilterDrawerSelector,
} from 'store/selectors/searchResults';

import Icon from 'pages/Properties/SearchResults/Icons';
import { setSearchCriteriaEffect, updateSavedSearchEffect } from 'store/effects/search';
import { updateSearchInstanceEffect } from 'store/effects/mySearches';
import { EDIT } from 'settings/constants/mode';
import { getEditedSearchSelector, getSearchCriteriaSelector } from 'store/selectors/search';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { PENDING } from 'settings/constants/apiState';
import FilterForm from './FilterForm';
import { setEditedSearchCriteriaAction } from 'store/actions/mySearches';
import { getSearchResultsV2Effect } from 'store/effects/searchResults/searchV2';
import { useSetSearch } from 'hooks';
import styles from './styles.module.scss';
import { LocationService } from 'services';

const locationSrv = new LocationService();

const FilterDrawer = ({ className, withBackground }) => {
  locationSrv.setLocation(location);
  const { searchInstanceId, searchId } = locationSrv.getQuery();
  const dispatch = useDispatch();
  const { searchValue, updateSearchValue } = useSetSearch('search');
  const open = useSelector(getIsSearchResultsFilterDrawerOpenSelector);
  const { mode, isClientSearch } = useSelector(getSearchResultsFilterDrawerSelector);
  const search = useSelector(getSearchCriteriaSelector);
  const editedSearch = useSelector(getEditedSearchSelector);
  const [isPending, setIsPending] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const isEditMode = mode === EDIT;
  const hasCriterias = !!Object.keys(search).length;
  const hideModalBackground = isEditMode && !withBackground;
  const commuteId = useSelector(getSearchDrawerCommuteIdSelector);

  const prevCommuteId = usePrevious(commuteId);
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  const Id = searchInstanceId ?? searchId ?? editedSearch?.data?.id;

  const onCloseDrawer = () => {
    dispatch(openSearchResultsFilterDrawerEffect({ open: false, mode, isClientSearch }));
    setActiveTab(1);
  };

  const getHeader = () => {
    if (commuteId !== null) {
      return <CommuteHeader onClose={() => dispatch(setSearchDrawerCommuteIdEffect(null))} />;
    }

    return (
      <Header
        title={isEditMode ? 'Edit Saved Search' : `${hasCriterias ? 'Edit' : 'Add'} Criteria`}
        onClose={() =>
          dispatch(openSearchResultsFilterDrawerEffect({ open: false, mode, isClientSearch }))
        }
        iconVariant={isEditMode || hasCriterias ? Icon.EDIT_COLOR : Icon.FILTER}
      />
    );
  };

  const editModeCallback = (err, resp) => {
    if (!err) {
      const savedSearch = resp?.data?.result;
      dispatch(openSearchResultsFilterDrawerEffect({ open: false, mode, isClientSearch }));
      dispatch(
        setEditedSearchCriteriaAction({
          ...editedSearch.data,
          name: savedSearch?.Name,
          criterias:
            isClient || isClientSearch
              ? savedSearch?.DefaultPropertySearchPreferences
              : savedSearch.SearchQuery,
        }),
      );
    }
    setIsPending(false);
  };

  const onEditModeSubmit = (values) => {
    setIsPending(true);
    const criterias = {
      id: Id,
      ...values,
    };
    if (isClient || isClientSearch) {
      dispatch(updateSearchInstanceEffect(criterias, {}, editModeCallback));
    } else {
      dispatch(updateSavedSearchEffect(criterias, {}, editModeCallback));
    }
  };

  const onSubmit = (values) => {
    if (searchValue) {
      const updatedSearch = { ...JSON.parse(searchValue), ...values };
      updateSearchValue(updatedSearch);
    }
    dispatch(openSearchResultsFilterDrawerEffect({ open: false, mode, isClientSearch }));
    dispatch(setSearchCriteriaEffect(values));
    if (isEditMode) {
      onEditModeSubmit(values);
    }
    if (!values?.Locations?.length) return;
    setIsPending(true);
    dispatch(
      getSearchResultsV2Effect({}, {}, () => {
        setIsPending(false);
      }),
    );
    const ref = document.getElementById('searchProperties');
    if (ref) {
      document.getElementById('searchProperties').scrollTop = 0;
    }
  };

  const getSearchValues = () => {
    return isEditMode ? editedSearch?.data?.criterias : search || {};
  };

  const getIsPending = () =>
    isPending || search?.state === PENDING || editedSearch?.state === PENDING;

  const tabs = [
    {
      key: 1,
      id: 'search',
      label: 'Primary Criteria',
      children: (
        <PrimaryCriteria
          isPending={getIsPending()}
          onSubmit={onSubmit}
          search={getSearchValues()}
        />
      ),
      testid: 'primary_criteria',
    },
    {
      key: 2,
      id: 'features',
      label: 'Wish List',
      children: (
        <ImportantFeatures
          isPending={getIsPending()}
          onSubmit={onSubmit}
          search={getSearchValues()}
        />
      ),
      testid: 'important_features',
    },
    {
      key: 3,
      id: 'commutes',
      label: 'Important Locations',
      children: (
        <Commutes isPending={getIsPending()} onSubmit={onSubmit} search={getSearchValues()} />
      ),
      testid: 'commutes',
    },
  ];

  const getContent = () => {
    if (isAgent && !isClientSearch) {
      return (
        <FilterForm
          mode={mode}
          searchName={editedSearch?.data?.Name || editedSearch?.data?.name}
          onSubmit={onSubmit}
          isPending={getIsPending()}
          isSubmitPending={isPending}
          search={getSearchValues()}
        />
      );
    }

    if (isClient || isClientSearch) {
      return (
        <>
          <CommuteForm onSubmit={onSubmit} isPending={getIsPending()} search={getSearchValues()} />
          <Tabs
            className={styles.tabs}
            items={tabs}
            defaultActiveKey={activeTab}
            onTabClick={(e) => setActiveTab(e)}
          />
        </>
      );
    }

    return null;
  };

  return (
    <Drawer
      onClose={onCloseDrawer}
      className={classNames(styles.filterDrawer, className)}
      hideOpacityLayer={hideModalBackground}
      isOpen={open}
      header={getHeader()}
      testid="search_drawer"
    >
      <div className={styles.drawerInner}>{getContent()}</div>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  className: PropTypes.string,
  withBackground: PropTypes.bool,
};

FilterDrawer.defaultProps = {
  className: '',
  withBackground: false,
};

export default FilterDrawer;
