import classNames from 'classnames';
import { Rnd } from 'react-rnd';

import { PDFEditorFieldType, PDFEditorFieldAttribute } from 'types';
import { getPDFFieldHexColor } from 'utils';

import styles from './styles.module.scss';

interface PDFEditorFieldRendererProps {
  field: PDFEditorFieldType;
  scale: number;
  onFieldUpdate: (fieldId: string, attribute: Partial<PDFEditorFieldAttribute>) => void;
  highlighted?: PDFEditorFieldType;
  setHighlighted: (field) => void;
  disabled: boolean;
}

export const PDFEditorFieldRenderer = ({
  scale,
  field,
  onFieldUpdate,
  highlighted,
  setHighlighted,
  disabled,
}: PDFEditorFieldRendererProps) => {
  const attributes = field.attributes;

  const width = attributes.width * scale;
  const height = attributes.height * scale;
  const x = attributes.x * scale;
  const y = attributes.y * scale;

  const isHighlighted = highlighted?.id === field?.id;

  const highlightField =
    !isHighlighted && !disabled
      ? () => {
          setHighlighted(field.id);
        }
      : undefined;

  return (
    <Rnd
      className={classNames({ [styles.highlightField]: isHighlighted })}
      position={{ x, y }}
      size={{
        width,
        height,
      }}
      style={
        highlighted?.id === field.id
          ? {
              background: getPDFFieldHexColor(field.type as any),
            }
          : {
              border: `2px dotted ${getPDFFieldHexColor(field.type as any)}`,
            }
      }
      onResizeStart={highlightField}
      onDragStart={highlightField}
      bounds="parent"
      onDragStop={(e, d) => {
        const { x, y } = d;

        onFieldUpdate(field.id, {
          x: x / scale,
          y: y / scale,
        });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        onFieldUpdate(field.id, {
          width: parseInt(ref.style.width) / scale,
          height: parseInt(ref.style.height) / scale,
          x: position.x / scale,
          y: position.y / scale,
        });
      }}
      dragGrid={[1, 1]}
      resizeGrid={[1, 1]}
    />
  );
};
