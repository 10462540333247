import { upperCase } from 'lodash-es';

import classNames from 'classnames';
import { Avatar } from 'components-antd';
import { Col } from 'antd';

import { Accordion } from 'components';
import { ClientDetails } from '../ClientDetails';
import { PreApprovalDrawer } from '../PreApprovalDrawer';

import styles from './styles.module.scss';

export const ClientCard = ({ client, onSelect, unreadMessageCount }) => {
  const placeholder = upperCase(`${client.FirstName?.[0] || ''}${client.LastName?.[0] || ''}`);

  const filterByStatus = (data, status) => {
    return data?.filter((item) => item.status === status);
  };

  return (
    <div className={styles.ClientCard} onClick={() => onSelect(client.Id)}>
      <Accordion
        testid="client_search_result_card"
        className={styles.accordion}
        applyDefaultContainerStyles={false}
        title={(open: boolean) => {
          let count = filterByStatus(client.SearchQueries, 'Active').length;
          return (
            <>
              <Avatar
                className={classNames(styles.ClientAvatar, {
                  [styles.avatarName]: !client.AvatarUrl,
                })}
                size={32}
                src={client.AvatarUrl}
              >
                {placeholder}
              </Avatar>
              <div className={styles.Details}>
                <h3 className={styles.ClientName}>
                  {client.FirstName} {client.LastName}
                  {!open && count ? (
                    <span className={styles.numberOfSearches}>{` (${count})`}</span>
                  ) : null}
                </h3>
              </div>
            </>
          );
        }}
        titleClassName={styles.inactiveTitle}
        allowOverflowWhenOpen={true}
      >
        <ClientDetails
          client={client}
          goBack={() => {
            return null;
          }}
          unreadMessageCount={unreadMessageCount}
        ></ClientDetails>
      </Accordion>
    </div>
  );
};
