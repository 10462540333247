import { Messages as MessagesIcon } from 'components/Icons';
import { Avatar } from 'components-antd';
import { UserOutlined } from '@ant-design/icons';
import { AgentDetailsUpdate } from 'types';

import styles from './styles.module.scss';
import { useState } from 'react';

export const Agents = (props) => {
  const { SellerAgent } = props;
  const [isViewMode, setViewMode] = useState(false);

  const agentData: AgentDetailsUpdate = {
    ...SellerAgent,
    Phone: SellerAgent?.Phones?.find((item) => item.IsPrimary)?.PhoneNumber,
  };
  return (
    <div>
      <div className={styles.iconImage}>
        <Avatar size={48} icon={<UserOutlined />} />
      </div>
      <div className={styles.details}>
        <p className={styles.title}>{agentData?.FirstName + ' ' + agentData?.LastName}</p>
        <p
          className={styles.viewMore}
          onClick={() => {
            setViewMode(!isViewMode);
          }}
        >
          {isViewMode ? '- Hide Contact Info' : '+ View Contact Info'}
        </p>
        {isViewMode && (
          <>
            <p className={styles.subTitle}>{agentData?.Company}</p>
            <p className={styles.subTitle}>{agentData?.Phone}</p>
            <a className={styles.subTitle}>{agentData?.Email}</a>
          </>
        )}
      </div>
      <div className={styles.sendMessages}>
        <div testid="messages_button" className={styles.messages}>
          <MessagesIcon className={styles.messagesIcon} />
          <span>Messages</span>
        </div>
      </div>
    </div>
  );
};
