import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import { onBoardingSignUpEffect } from 'store/effects/onBoarding';
import { getUserDataSelector } from 'store/selectors/user';

import { PENDING } from 'settings/constants/apiState';

import InputList from './InputList';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { OnboardingContinueButton } from './components/OnboardingContinueButton';
import { OnboardingSkipButton } from './components/OnboardingSkipButton';

import { Question } from 'pages/RequestQuote/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { OnBoardingWrapper } from '../../OnBoardingWrapper';
import { OnBoardingContainer } from '../../OnBoardingContainer';

import styles from './styles.module.scss';

interface WebsiteFormProps {
  onNext: () => any;
  stageIndex: number;
  Controls: React.ElementType;
}

const formInitialValues = {
  websites: [''],
};

const WebsiteForm: React.FC<WebsiteFormProps> = ({ onNext, stageIndex, Controls }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const { onBoarding } = useSelector(getOnBoardingData);

  const onSubmit = (values) => {
    dispatch(
      onBoardingSignUpEffect({ ...values, stageIndex }, { isCommonInfo: true }, (err) => {
        if (!err) {
          onNext();
        }
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      ...formInitialValues,
      websites: user?.Agent?.Websites?.length ? user.Agent.Websites : formInitialValues.websites,
    },
    validateOnChange: true,
    onSubmit,
  });

  const isPending = onBoarding.state === PENDING;

  return (
    <OnBoardingWrapper isPending={isPending}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question>Do you want to display a link to your website?</Question>
        <AnswersContainer>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.formContent}>
              <InputList
                testid="links_list"
                disabled={isPending}
                formik={formik}
                name="websites"
                list={formik.values.websites}
                addBtnTitle="Add link"
                inputClass={styles.formInput}
              />
            </div>
            <ButtonsContainer>
              <OnboardingContinueButton
                isPending={isPending}
                disabled={!formik.isValid}
                htmlType="submit"
                className={styles.submit}
              />
              <OnboardingSkipButton onClick={onNext} className={styles.skip} />
            </ButtonsContainer>
          </form>
        </AnswersContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

export default WebsiteForm;
