import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { getThreadsSelector } from './threads';

export const localState = ({ sockets }) => get(sockets, 'offerMessages');

export const getSocketOfferMessagesSelector = createSelector(
  localState,
  ({ messages }) => messages,
);

export const getCurrentOfferMessagesThreadIdSelector = createSelector(
  localState,
  ({ currentThreadId }) => currentThreadId,
);

export const getCurrentEditOfferMessageSelector = createSelector(
  localState,
  ({ editMessage }) => editMessage,
);

export const getCurrentOfferMessagesInfoSelector = createSelector(
  getSocketOfferMessagesSelector,
  getCurrentOfferMessagesThreadIdSelector,
  getThreadsSelector,
  (messages, currentThreadId, threads) => {
    const currentThread = threads?.[currentThreadId];
    return messages
      ? messages.map((message) => {
          const participant = currentThread?.Participants.find(
            (partic) => partic?.Id === message?.SenderUserId,
          );

          return {
            ...message,
            ...(participant?.AvatarUrl
              ? { AvatarUrl: participant.AvatarUrl }
              : { AvatarUrl: null }),
            FirstName: participant?.FirstName,
            LastName: participant?.LastName,
          };
        })
      : null;
  },
);

export const getCurrentOfferThreadSelector = createSelector(
  getCurrentOfferMessagesThreadIdSelector,
  getThreadsSelector,
  (currentThreadId, threads) => threads?.[currentThreadId],
);
