export const getPages = (itemsAmount, limit, pagesAmount, pagesLimit, pageIndex) => {
  if (!pagesLimit || pagesLimit >= pagesAmount) {
    return Array.from({ length: pagesAmount }, (v, i) => i + 1);
  }

  const limitedPages = [];
  const beforeCurrent = (pagesAmount + pageIndex) % pagesAmount;
  const afterCurrent = pagesAmount - beforeCurrent - 1;
  let showBefore = Math.min(beforeCurrent, Math.ceil((pagesLimit - 1) / 2));
  const showAfter = Math.min(pagesLimit - 1 - showBefore, afterCurrent);

  if (showBefore + showAfter + 1 !== pagesLimit) {
    showBefore = pagesLimit - 1 - showAfter;
  }
  limitedPages.push(...Array.from({ length: showBefore }, (v, i) => pageIndex - i));
  limitedPages.push(pageIndex + 1);
  limitedPages.push(...Array.from({ length: showAfter }, (v, i) => pageIndex + i + 2));
  limitedPages.sort((a, b) => a - b);

  return limitedPages;
};
