import { SVGProps } from 'react';

export const ZoomIn = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 3.0598e-07C6.4477 2.81838e-07 6 0.44772 6 1L6 6L1 6C0.44772 6 -2.81838e-07 6.4477 -3.0598e-07 7C-3.30122e-07 7.5523 0.44772 8 1 8L6 8L6 13C6 13.5523 6.4477 14 7 14C7.5523 14 8 13.5523 8 13L8 8L13 8C13.5523 8 14 7.5523 14 7C14 6.4477 13.5523 6 13 6L8 6L8 1C8 0.44772 7.5523 3.30122e-07 7 3.0598e-07Z"
        fill="#262626"
      />
    </svg>
  );
};
