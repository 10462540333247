import PropTypes from 'prop-types';

import { Document } from './Document';

import styles from './styles.module.scss';

export const Documents = ({ data }) => {
  const {
    offerIteration: { OfferIterationDocument: documents, status },
  } = data;
  return (
    <div className={styles.documents}>
      <p className={styles.title}>Files</p>
      {documents?.length ? (
        <>
          {documents.map((d, index) => (
            <Document key={d.Id} document={d} offerData={data.offerIteration} />
          ))}
        </>
      ) : (
        <p className={styles.disclaimer}>
          This {status !== 'Initial' ? 'counter' : 'initial'} offer has no files.
        </p>
      )}
    </div>
  );
};

Documents.propTypes = {
  data: PropTypes.object,
};

Documents.defaultProps = {
  data: {},
};
