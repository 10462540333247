import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import File from './File';
import Docusign from './Docusign';
import GoogleDrive from './GoogleDrive';
import Dropbox from './Dropbox';

import styles from './styles.module.scss';

const Options = (props) => {
  const { className, onOptionFileChange, multiple, addDragInModal, onCloseModal, accept } = props;

  const showDefaultView = () => {
    return (
      <div testid="options" className={classNames(styles.options, className)}>
        <File
          onCloseModal={onCloseModal}
          onChange={onOptionFileChange}
          addDragInModal={addDragInModal}
          multiple={multiple}
          accept={accept}
        />
        <Docusign />
        <GoogleDrive />
        <Dropbox />
      </div>
    );
  };
  const showDropView = () => {
    return (
      <>
        <div className={styles.dropView}>{addDragInModal}</div>
      </>
    );
  };
  return addDragInModal == undefined ? showDefaultView() : showDropView();
};

Options.propTypes = {
  className: PropTypes.string,
  onOptionFileChange: PropTypes.func,
  multiple: PropTypes.bool,
};

Options.defaultProps = {
  className: '',
  onOptionFileChange: () => {},
  multiple: true,
  addDragInModal: undefined,
  onCloseModal: () => {},
};

export default Options;
