import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const Offer = memo(({ className }) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 7C7 5.89543 7.89543 5 9 5H18.5858C18.851 5 19.1054 5.10536 19.2929 5.29289L24.7071 10.7071C24.8946 10.8946 25 11.149 25 11.4142V25C25 26.1046 24.1046 27 23 27H9C7.89543 27 7 26.1046 7 25V7Z"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M19 5V9C19 10.1046 19.8954 11 21 11H25" stroke="#303030" strokeWidth="2" />
          <path d="M11 10H15" stroke="#303030" strokeWidth="2" strokeLinecap="round" />
          <path d="M11 14H21" stroke="#303030" strokeWidth="2" strokeLinecap="round" />
          <path d="M11 18H21" stroke="#303030" strokeWidth="2" strokeLinecap="round" />
          <path d="M16 22L21 22" stroke="#303030" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </div>
    ),
    [className],
  ),
);

Offer.propTypes = {
  className: PropTypes.string,
};

Offer.defaultProps = {
  className: '',
};

export default Offer;
