import React from 'react';
import PropTypes from 'prop-types';

const FullArrowRight = ({ className, color = '#163C5A' }) => (
  <div className={className}>
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 6H14M14 6L9 1M14 6L9 11"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

FullArrowRight.propTypes = {
  className: PropTypes.string,
};

FullArrowRight.defaultProps = {
  className: '',
};

export default FullArrowRight;
