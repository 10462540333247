import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components-antd';
import { ArrowLeftFull } from 'components/Icons';

import styles from './styles.module.scss';

const Navigation = ({ data, onNext, onClose, onFinish, isLast, disabled, className }) => {
  return (
    <div className={classNames(styles.footer, className)}>
      {isLast ? (
        <Button
          className={classNames(styles.transactionBtn, styles.submitBtn)}
          onClick={() => {
            onFinish();
            onClose();
          }}
          disabled={disabled}
        >
          Return to Transaction
        </Button>
      ) : (
        <Button
          className={classNames(
            styles.nextBtn,
            disabled ? styles.nextBtnDisabled : styles.nextBtnEnabled,
          )}
          onClick={onNext}
          disabled={disabled}
        >
          {data?.Id ? 'Save' : 'Next'}
        </Button>
      )}
    </div>
  );
};

Navigation.propTypes = {
  data: PropTypes.object,
  onNext: PropTypes.func,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  stageIndex: PropTypes.number,
  isLast: PropTypes.bool,
};

Navigation.defaultProps = {
  data: {},
  onNext: () => {},
  onClose: () => {},
  onFinish: () => {},
  stageIndex: 0,
  disabled: false,
  className: '',
  isLast: false,
};

export default Navigation;
