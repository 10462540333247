import React, { useEffect, useRef, useState } from 'react';

import { AuditLogCard } from 'components-antd/AuditLogCard';
import { getActivityLogs } from 'api/activityLog';
import PropTypes from 'prop-types';

import { Spinner } from 'components';

import moment from 'moment';
import { keys } from 'lodash-es';
import {
  ActivityLogsActionsTypes,
  ActivityLogsActionsTypesDashboard,
} from 'utils/activityLogsActionsTypes';

import styles from './styles.module.scss';
import { makeTaskGroupForDashboard } from 'utils/activityLogsHelper';
import { getTransactionTaskCategoriesSelector } from 'store/selectors/transactionTaskCategories';
import { useDispatch, useSelector } from 'react-redux';

import { getTaskCategoriesEffect } from 'store/effects/transactions';

const ActivityLogsSections: React.FC<any> = ({
  activityLogCardClassName,
  activityLogActionType,
  timelineItemWrapperClassName,
  timelineTimeLabelClassName,
  timelineContentClassName,
  avatarWrapperClassName,
  fromTimeStamp,
  toTimeStamp,
  width,
  logs,
}) => {
  const { categories } = useSelector(getTransactionTaskCategoriesSelector);
  const [todayLog, setTodayLog] = useState([]);
  const [yesterdayLog, setYesterdayLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let loadingRef: any = useRef(null);

  const getLogs = async () => {
    setIsLoading(true);
    try {
      const now = moment().utcOffset() / 60;

      const searchFilter: Record<string, any> = {
        ActionTypes:
          activityLogActionType.length > 0
            ? activityLogActionType
            : ActivityLogsActionsTypesDashboard,
        timeZoneOffset: now,
        ExcludeSignedInUser: false,
      };

      if (fromTimeStamp && toTimeStamp) {
        searchFilter.FromTimestamp = fromTimeStamp;
        searchFilter.ToTimestamp = toTimeStamp;
      }

      const { data } = await getActivityLogs(searchFilter);
      let userIdsHash = {};
      if (data?.result) {
        const today = (data?.result?.items || []).filter((logItem) =>
          filterTodayAndYesterday(true, logItem),
        );
        const yesterday = (data?.result?.items || []).filter((logItem) =>
          filterTodayAndYesterday(false, logItem),
        );

        const { groupedData: todaysGrouped, userIdsHash } = makeTaskGroupForDashboard(today, {
          categories: categories,
        });
        setTodayLog(dateFormatter(todaysGrouped));
        const { groupedData: yesterdaysGroup } = makeTaskGroupForDashboard(yesterday, {
          userIdsHash: userIdsHash,
          categories,
        });
        setYesterdayLog(dateFormatter(yesterdaysGroup));
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTaskCategoriesEffect());
    getLogs();
  }, []);

  const getDayAndDateTitleStr = (dateTimestamp) => {
    let dateStr = moment(new Date(dateTimestamp)).format('dddd, MMM D, YYYY');
    if (moment().add(-1, 'days').format('dddd, MMM D, YYYY') === dateStr) {
      return 'Yesterday';
    }
    if (moment().format('dddd, MMM D, YYYY') === dateStr) {
      return 'Today';
    }
    return dateStr;
  };

  const filterTodayAndYesterday = (today = false, logItem) => {
    let dateStr = moment(new Date(logItem.ActionTimestamp)).format('dddd, MMM D, YYYY');
    if (today && moment().format('dddd, MMM D, YYYY') === dateStr) {
      return true;
    } else if (!today && moment().add(-1, 'days').format('dddd, MMM D, YYYY') === dateStr) {
      return true;
    }
    return false;
  };

  const dateFormatter = (logItems: any) => {
    const formattedLogs: any = [];
    logItems?.forEach((document) => {
      if (document.ActionTimestamp) {
        let date = getDayAndDateTitleStr(document.ActionTimestamp);
        const index = formattedLogs.findIndex((f) => f[date] !== undefined);
        if (index === -1) {
          formattedLogs.push({ [date]: [document] });
        } else {
          formattedLogs[index][date].push(document);
        }
      }
    });
    return formattedLogs;
  };

  return (
    <div>
      <div className={styles.activityLogWrapper}>
        {!isLoading ? (
          todayLog?.length ? (
            todayLog?.map((item, idx) => {
              return (
                <AuditLogCard
                  key={idx}
                  activityLogs={item[`${keys(item)}`]}
                  DayAndDate={keys(item)[0]}
                  activityLogCardClassName={activityLogCardClassName}
                  timelineItemWrapperClassName={timelineItemWrapperClassName}
                  timelineTimeLabelClassName={timelineTimeLabelClassName}
                  timelineContentClassName={timelineContentClassName}
                  avatarWrapperClassName={avatarWrapperClassName}
                  IsQuoteDrawer={logs && logs.length > 0}
                />
              );
            })
          ) : (
            <div className={styles.activityLog}>
              <h4 className={styles.activityDay}>Today</h4>
              <p className={styles.noActivity}>There hasn&apos;t been any activity yet.</p>
            </div>
          )
        ) : (
          <div ref={loadingRef}>
            <span style={{ display: isLoading ? 'block' : 'none' }}>
              <div className={styles.activityLog}>
                <h4 className={styles.activityDay}>Today</h4>
                <Spinner />
              </div>
            </span>
          </div>
        )}

        {!isLoading ? (
          yesterdayLog?.length ? (
            yesterdayLog?.map((item, idx) => {
              return (
                <AuditLogCard
                  key={idx}
                  activityLogs={item[`${keys(item)}`]}
                  DayAndDate={keys(item)[0]}
                  activityLogCardClassName={activityLogCardClassName}
                  timelineItemWrapperClassName={timelineItemWrapperClassName}
                  timelineTimeLabelClassName={timelineTimeLabelClassName}
                  timelineContentClassName={timelineContentClassName}
                  avatarWrapperClassName={avatarWrapperClassName}
                  IsQuoteDrawer={logs && logs.length > 0}
                />
              );
            })
          ) : (
            <div className={styles.activityLog}>
              <h4 className={styles.activityDay}>Yesterday</h4>
              <p className={styles.noActivity}>There wasn&apos;t any activity yesterday.</p>
            </div>
          )
        ) : (
          <div ref={loadingRef}>
            <span style={{ display: isLoading ? 'block' : 'none' }}>
              <div className={styles.activityLog}>
                <h4 className={styles.activityDay}>Yesterday</h4>
                <Spinner />
              </div>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

ActivityLogsSections.propTypes = {
  activityLogCardClassName: PropTypes.string,
  activityLogActionType: PropTypes.array,
  timelineItemWrapperClassName: PropTypes.string,
  timelineTimeLabelClassName: PropTypes.string,
  timelineContentClassName: PropTypes.string,
  avatarWrapperClassName: PropTypes.string,
  fromTimeStamp: PropTypes.string,
  toTimeStamp: PropTypes.string,
  width: PropTypes.string,
  logs: PropTypes.array,
};

ActivityLogsSections.defaultProps = {
  activityLogCardClassName: '',
  activityLogActionType: [],
  timelineItemWrapperClassName: '',
  timelineTimeLabelClassName: '',
  timelineContentClassName: '',
  avatarWrapperClassName: '',
  fromTimeStamp: '',
  toTimeStamp: '',
  width: '',
  logs: [],
};

export default ActivityLogsSections;
