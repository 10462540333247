import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ adminPanel }) => adminPanel;

const transactionRolesState = createSelector(
  localState,
  ({ transactionRoles }) => transactionRoles,
);

const transactionRoleState = createSelector(localState, ({ transactionRole }) => transactionRole);

export const getTransactionRolesDashboardSelector = createSelector(
  transactionRolesState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTransactionRolesFiltersDashboardSelector = createSelector(
  transactionRolesState,
  (state) => get(state, 'filters') || {},
);

export const getTransactionRolesDataDashboardSelector = createSelector(
  transactionRolesState,
  (state) => get(state, 'data') || [],
);

export const getTransactionRoleDashboardSelector = createSelector(
  transactionRoleState,
  ({ state, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTransactionRoleDataDashboardSelector = createSelector(
  transactionRoleState,
  (state) => get(state, 'data'),
);
