import { Table } from 'antd';
import styles from './styles.module.scss';
import React from 'react';
import { FORM_TYPE } from 'app-constants';

interface FormFilesTableProps {
  columns: Array<object>;
  dataSource: any;
  loading: boolean;

  rowKeyId: string;
  total: number;
  onPageChange?: (page) => void;
  className?: string;
  showSelection: boolean;
  hidePagination?: boolean;
  onRowClick: (record) => void;
  currentPage?: number;
  onTableChange?: any;
  pageSize?: number;
}

export const FormFilesTable = (props: FormFilesTableProps) => {
  const {
    columns,
    dataSource,
    loading,
    rowKeyId,
    total,
    onPageChange,
    className,
    showSelection,
    hidePagination,
    onRowClick,
    currentPage,
    onTableChange,
    pageSize = 5,
  } = props;

  const tableProps = {
    pagination: {
      position: [hidePagination ? 'none' : 'bottomRight'],
      ...(!hidePagination
        ? {
            defaultPageSize: pageSize,
            className: styles.tablePagination,
            total,
            onChange: onPageChange,
            showSizeChanger: false,
            ...(currentPage && { current: currentPage }),
          }
        : { total: dataSource?.length, defaultPageSize: dataSource?.length }),
    },

    ...(!showSelection && {
      onRow: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
            onRowClick(record);
          },
        };
      },
    }),

    ...(hidePagination && {
      scroll: { y: 450 },
    }),
    ...(onTableChange && { onChange: onTableChange }),
  } as any;

  return (
    <Table
      className={`${styles.formFilesTable} ${className || ''}`}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      rowKey={rowKeyId}
      {...tableProps}
    />
  );
};
