import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationService } from 'services';

import styles from './styles.module.scss';

const locationSrv = new LocationService();

const ViewButton = (props) => {
  const { categoryId, label, className } = props;
  const history = useHistory();
  const location = useLocation();

  locationSrv.setLocation(location);

  const onViewAll = () => {
    history.push(locationSrv.setQuery({ groupId: categoryId }));
  };

  return (
    <div className={classNames(styles.viewButton, className)}>
      <div testid="action_button" onClick={onViewAll} className={styles.btn}>
        {label || 'View All'}
      </div>
    </div>
  );
};

ViewButton.propTypes = {
  className: PropTypes.string,
  categoryId: PropTypes.string,
  label: PropTypes.string,
};

ViewButton.defaultProps = {
  className: '',
  categoryId: '',
  label: undefined,
};

export default ViewButton;
