import Api from 'store/effects/core/api';
import {
  getScheduleTours,
  cancelScheduleTour,
  batchUpdateScheduleTours,
  batchCancelScheduleTours,
} from 'api/tour';
import {
  requestScheduleToursAction,
  requestBatchCancelScheduleTourAction,
  requestUpdateScheduleTourAction,
  requestCancelScheduleTourAction,
} from 'store/actions/tour';

export const requestScheduleToursEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestScheduleToursAction,
    method: getScheduleTours,
  });

  return sendRequest(cfg, options, cb);
};

export const requestUpdateScheduleTourEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestUpdateScheduleTourAction,
    method: batchUpdateScheduleTours,
  });
  const body = {
    tours: cfg.state.map((tour) => {
      return {
        UUID: tour.UUID,
        ScheduleDateTime: tour.ScheduleDateTime,
        ClientNotes: tour.ClientNotes,
        PrivateNotes: tour.PrivateNotes,
        IsApproval: !cfg.isEdit,
        AssigneeIds: tour.AssigneeIds,
      };
    }),
  };

  return sendRequest(body, options, cb);
};
export const requestCancelScheduleTourEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestCancelScheduleTourAction,
    method: cancelScheduleTour,
  });

  return sendRequest(cfg, options, cb);
};
export const requestBatchCancelScheduleTourEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestBatchCancelScheduleTourAction,
    method: batchCancelScheduleTours,
  });

  return sendRequest(cfg, options, cb);
};
