import { useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { upperCase } from 'lodash-es';

import { LocationService } from 'services';
import { Avatar } from 'components-antd';
import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const locationService = new LocationService();

const ListItem = ({ id, firstName, lastName, avatar, favorites }) => {
  const location = useLocation();
  const history = useHistory();

  const handleItemClick = () => {
    locationService.setLocation(location);
    const pathname = locationService.setQuery({ clientId: id });
    history.push(pathname);
  };

  const placeholder = upperCase(`${firstName?.[0] || ''}${lastName?.[0] || ''}`);

  return (
    <div testid="list_item" onClick={handleItemClick} className={styles.itemContainer}>
      <div className={styles.inner}>
        <Avatar
          className={classNames(styles.ClientAvatar, { [styles.avatarName]: !avatar })}
          size={56}
          src={avatar}
        >
          {placeholder}
        </Avatar>
        <div className={styles.nameHolder}>
          <p testid="buyer_name" className={styles.name}>
            {firstName + ' ' + lastName}
          </p>
          <p testid="buyer_favorites" className={styles.favorites}>{`${favorites} favorites`}</p>
        </div>
      </div>
      <Icon variant={Icon.ARROW_RIGHT} />
    </div>
  );
};

ListItem.propTypes = {
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  avatar: PropTypes.string,
  favorites: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ListItem.defaultProps = {
  name: '',
  id: null,
  favorites: 0,
  avatar: undefined,
};

export default ListItem;
