import { handleActions } from 'redux-actions';
import { get, cloneDeep } from 'lodash-es';
import {
  requestClientInstancesAction,
  setSearchInstanceAction,
} from 'store/actions/clientInstances';
import { IDLE } from 'settings/constants/apiState';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestClientInstancesAction]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data.result', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [setSearchInstanceAction]: (state, { payload }) => {
      const instances = cloneDeep(state.data);
      instances.searchInstances[0].DefaultPropertySearchPreferences = { ...payload.data };
      return {
        ...state,
        data: instances,
      };
    },
  },
  initialData,
);
