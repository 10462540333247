import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { cloneDeep, get } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import { requestGetVaultDocumentsMetaAction } from 'store/actions/vault';

const initialData = {
  state: IDLE,
  data: null,
};

export default handleActions(
  {
    [requestGetVaultDocumentsMetaAction as any]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
    }),
    [appLogoutAction as any]: () => cloneDeep(initialData),
  },
  initialData,
);
