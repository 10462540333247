import { createSelector } from 'reselect';
import { ERROR, IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ transactionRoles }) => transactionRoles;

export const getTransactionRolesSelector = createSelector(localState, (transactionState) => ({
  isIdle: transactionState.state === IDLE,
  isPending: transactionState.state === PENDING,
  isError: transactionState.state === ERROR,
  isData: !!transactionState.data,
  transactionRoles: transactionState.data,
}));
