import styles from '../styles.module.scss';

import { ErrorMessage } from '../../../components/ErrorMessages';
import YesNo from '../../components/YesNo';
import { Select } from 'components-antd';
import { responsibleParty } from '../helper';
import classNames from 'classnames';
import { Input } from 'components';
import { RequiredAsterik } from '../../components/RequiredAsterik';

export const Breakpoint3 = (props) => {
  const { state, setErrors, errorMessages, onChange, setState, renderField, currentBreakpoint } =
    props;

  const yesNoHandler = (val: boolean, key: string) => {
    const subFields = {
      TermiteInspection: { TermiteInspectionResponsibility: null },
      HomeWarranty: { HomeWarrantyCost: '', HomeWarrantyResponsibility: null },
      Occupancy: { OccupancyDays: null },
    };

    let newState = {
      ...state,
      OfferIteration: {
        ...state.OfferIteration,
        [key]: val,
        ...(!val && subFields[key]),
      },
    };

    setState(newState);
  };

  return (
    <div className={styles.breakpoint3}>
      <div className={styles.inputBlock}>
        <div className={styles.label}>
          Termite Inspection? <RequiredAsterik />
        </div>
        <YesNo
          questionHandler={(val, key) => {
            setErrors({
              ...errorMessages,
              TermiteInspectionError: '',
            });
            yesNoHandler(val, key);
          }}
          objectKey={'TermiteInspection'}
          yes={state?.OfferIteration?.TermiteInspection}
          className={styles.yesNoContainer}
          optionClass={styles.yesNoOption}
        />

        {state?.OfferIteration?.TermiteInspection ? (
          <div className={styles.questionInput}>
            <div className={styles.questionInputLabel}>
              Responsible Party? <RequiredAsterik />
            </div>
            <Select
              className={styles.inputHolder}
              options={responsibleParty}
              value={state?.OfferIteration?.TermiteInspectionResponsibility}
              onSelect={(e) => {
                setErrors({
                  ...errorMessages,
                  TermiteInspectionError: '',
                });
                onChange({
                  ...state,
                  OfferIteration: {
                    ...state.OfferIteration,
                    TermiteInspectionResponsibility: e,
                  },
                });
              }}
              placeholder={'Select Party'}
              getPopupContainer={(node) => node}
              popupClassName={styles.inputHolderDropdown}
            />
            <ErrorMessage id="termite_inspection" message={errorMessages.TermiteInspectionError} />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={styles.fieldSeparator} />

      {/** Home Warranty */}
      <div className={styles.inputBlock}>
        <div className={styles.label}>
          Home Warranty? <RequiredAsterik />
        </div>
        <YesNo
          questionHandler={(val, key) => {
            setErrors({
              ...errorMessages,
              HomeWarrantyCostError: '',
              HomeWarrantyResponsibleError: '',
            });
            yesNoHandler(val, key);
          }}
          objectKey={'HomeWarranty'}
          yes={state?.OfferIteration?.HomeWarranty}
          className={styles.yesNoContainer}
          optionClass={styles.yesNoOption}
        />
        {state?.OfferIteration?.HomeWarranty ? (
          <div className={styles.questionInput}>
            <div className={styles.questionInputLabel}>
              Cost? <RequiredAsterik />
            </div>
            <Input
              className={styles.input}
              type="number"
              placeholder="$0"
              inputHolderClassName={styles.inputHolder}
              variant={Input.LIGHT_ROUND}
              prefix="$"
              isNumberFormat
              value={state?.OfferIteration?.HomeWarrantyCost}
              onChange={(e) => {
                const { value } = e.target;
                setErrors({
                  ...errorMessages,
                  HomeWarrantyCostError: '',
                });
                onChange({
                  ...state,
                  OfferIteration: {
                    ...state.OfferIteration,
                    HomeWarrantyCost: value ? Number(value) : '',
                  },
                });
              }}
              name="homeWarranty"
              id="homeWarranty"
              testid="homeWarranty"
            />
            <ErrorMessage id="home_warranty" message={errorMessages.HomeWarrantyCostError} />

            <div
              className={classNames(styles.questionInputLabel, styles.questionInputLabelSecondary)}
            >
              Responsible Party? <RequiredAsterik />
            </div>
            <Select
              className={styles.inputHolder}
              options={responsibleParty}
              value={state?.OfferIteration?.HomeWarrantyResponsibility}
              onSelect={(e) => {
                setErrors({
                  ...errorMessages,
                  HomeWarrantyResponsibleError: '',
                });
                onChange({
                  ...state,
                  OfferIteration: {
                    ...state.OfferIteration,
                    HomeWarrantyResponsibility: e,
                  },
                });
              }}
              placeholder={'Select Party'}
              getPopupContainer={(node) => node}
              popupClassName={styles.inputHolderDropdown}
            />
            <ErrorMessage id="home_warranty" message={errorMessages.HomeWarrantyResponsibleError} />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={styles.fieldSeparator} />

      {/** Appliances */}
      <div className={styles.inputBlock}>
        <div className={styles.label}>Appliances?</div>

        {renderField({
          name: 'Appliances',
          value: state?.OfferIteration?.Appliances,
          placeholder: 'Enter appliances',
          errorKey: '',
          id: 'Appliances',
          testId: 'submission_appliances',
          customOnChange: (value) => {
            onChange({
              ...state,
              OfferIteration: {
                ...state.OfferIteration,
                Appliances: value,
              },
            });
          },
          maxLength: 10000,
        })}
      </div>

      <div className={styles.fieldSeparator} />

      {/** Post/Closing */}
      <div className={styles.inputBlock}>
        <div className={styles.label}>
          Post-Closing Occupancy? <RequiredAsterik />
        </div>
        <YesNo
          questionHandler={(val, key) => {
            setErrors({
              ...errorMessages,
              OccupancyError: '',
            });
            yesNoHandler(val, key);
          }}
          yes={state?.OfferIteration?.Occupancy}
          objectKey={'Occupancy'}
          hasNumberInput={true}
          inputLabel={'days'}
          inputPlaceholder="#"
          inputValue={state?.OfferIteration?.OccupancyDays}
          inputHandler={(e) => {
            setErrors({
              ...errorMessages,
              OccupancyError: '',
            });
            onChange({
              ...state,
              OfferIteration: {
                ...state.OfferIteration,
                OccupancyDays: Number(e),
              },
            });
          }}
          yesClass={styles.yesOptionContainer}
          className={styles.yesNoContainer}
          optionClass={styles.yesNoOption}
          inputClass={styles.yesNoInputHolder}
        />
        <ErrorMessage id="occupancy" message={errorMessages.OccupancyError} />
      </div>

      {currentBreakpoint === 4 ? <div className={styles.fieldSeparator} /> : null}
    </div>
  );
};
