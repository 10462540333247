import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import { removeAllCharactersExceptNumbers } from 'helpers/regex';

import styles from './styles.module.scss';

interface PhoneNumberProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onUpdate: (value: string) => void;
  onBlurInput?: (value: string) => void;
  error?: string;
  errorClassName?: string;
  textFitProps?: any;
}

const KEYS = {
  NUMBER_0: 48,
  NUMBER_9: 57,
  NUMPAD_0: 96,
  NUMPAD_9: 105,
  BACKSPACE: 8,
  DELETE: 46,
  BACK_ARROW: 37,
  FORWARD_ARROW: 39,
};

const ALLOWED_MODIFIER_KEYS = [KEYS.BACKSPACE, KEYS.DELETE, KEYS.BACK_ARROW, KEYS.FORWARD_ARROW];

const isNumericInput = (event, pasted = false) => {
  const isCtrlPressed = event.ctrlKey || event.metaKey; // metaKey is for Mac

  const isNumericKey =
    (!event.shiftKey && event.keyCode >= KEYS.NUMBER_0 && event.keyCode <= KEYS.NUMBER_9) ||
    (event.keyCode >= KEYS.NUMPAD_0 && event.keyCode <= KEYS.NUMPAD_9);

  return isCtrlPressed || isNumericKey || pasted;
};

const isModifierKey = (event) => ALLOWED_MODIFIER_KEYS.includes(event.keyCode);

export const enforceFormat = (event) => {
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

export const formatToPhone = (event, pasted = false) => {
  if (isModifierKey(event) || !isNumericInput(event, pasted)) {
    return;
  }

  let value = event.target.value;

  if (pasted) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    value = pastedText;
  }

  const input = value.replace(removeAllCharactersExceptNumbers, '').substring(0, 10);
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    event.target.value = `(${areaCode}) ${middle}-${last}`;
  } else if (input.length > 3) {
    event.target.value = `(${areaCode}) ${middle}`;
  } else if (input.length > 0) {
    event.target.value = `(${areaCode}`;
  }
};

export const PhoneNumber = ({
  onUpdate,
  onBlurInput,
  error,
  className,
  errorClassName,
  ...props
}: PhoneNumberProps) => {
  const { textFitProps, ...restProps } = props;
  const refProps = {
    ...(textFitProps && textFitProps),
  };

  const handlePaste = (event) => {
    const check = /^(\(\d{3}\)\s*|\d{3}[-.]?\s*)?\d{3}[-.]?\s*\d{4}$/.test(
      event.clipboardData.getData('text'),
    );

    if (!check) {
      event.preventDefault();
      return;
    }
    formatToPhone(event, true);

    if (textFitProps) {
      textFitProps.onChange(event);
    } else {
      onUpdate(event.target.value);
      onBlurInput && onBlurInput(event.target.value);
    }
  };

  return (
    <>
      <input
        type="text"
        onChange={(e) => {
          onUpdate(e.target.value);
        }}
        onBlur={(e) => {
          onBlurInput && onBlurInput(e.target.value);
        }}
        onKeyDown={enforceFormat}
        onKeyUp={formatToPhone}
        onPaste={handlePaste}
        maxLength={14}
        className={classNames(styles.customPhoneNumber, className)}
        {...restProps}
        {...refProps}
      />
      {error && <div className={classNames(styles.error, errorClassName)}>{error}</div>}
    </>
  );
};
