import styles from './styles.module.scss';

const EmptyToursCard = ({ text }) => {
  return (
    <div className={styles.emptyCard}>
      <p className={styles.text}>You have no {text}.</p>
    </div>
  );
};

export default EmptyToursCard;
