import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useLongPress } from 'hooks';

import {
  getTransactionsGroupedAndSortedUnarchivedThreadsList,
  getTransactionsGroupedArchivedThreadsForOpenedClient,
} from 'store/selectors/sockets/threads';
import { link } from 'settings/navigation/link';
import { Avatar, NoThreads, UsersMessage } from 'components';

import ActionsList from 'components/Drawers/components/ActionsList';

import {
  getArchiveThreadOption,
  getAddParticipantThreadOption,
  openChatThreadHandler,
  getGoToTypeOption,
  getOpenChatThreadOption,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import { ListingHome } from 'components/Icons';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import DrawerHeaderControlls from '../../DrawerHeaderControlls';

import styles from './styles.module.scss';

const TransactionContent = (props) => {
  const { params } = props;
  const { archived, transactionTopicName } = params || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionsPosition, setActionPosition] = useState(null);
  const [actions, setActions] = useState([]);

  const getThreadsForOpenedUserSelector = () =>
    archived
      ? getTransactionsGroupedArchivedThreadsForOpenedClient
      : getTransactionsGroupedAndSortedUnarchivedThreadsList;

  const list = useSelector(getThreadsForOpenedUserSelector());
  const currentTransaction = (list || []).find(
    (thread) => thread?.topicName === transactionTopicName,
  );

  useEffect(() => {
    if (!currentTransaction?.threads?.length) {
      dispatch(
        changeMessagesDrawerTypeAction({
          type: archived ? DRAWER_MESSAGES_TYPES.ARCHIVED : DRAWER_MESSAGES_TYPES.INIT,
        }),
      );
    }
  }, [currentTransaction?.threads?.length, archived, dispatch]);

  const clientActions = useMemo(
    () => [
      {
        title: 'View transaction',
        onClick: () => {
          history.push(link.toTransactionOverview(currentTransaction?.transactionId));
          dispatch(openMessagesDrawerAction(false));
        },
      },
    ],
    [dispatch, history, currentTransaction?.transactionId],
  );

  const onLongPress = (event, [thread]) => {
    setActionPosition({ x: event?.clientX, y: event?.clientY });
    setActions([
      ...getArchiveThreadOption(dispatch, thread, () => {
        setActionPosition(null);
      }),
    ]);
  };
  const onPress = (event, [thread]) => {
    openChatThreadHandler(dispatch, { threadId: thread.Id, transactionTopicName });
  };

  const longPressEvent = useLongPress(onLongPress, onPress);

  return (
    <>
      <DrawerHeaderControlls actions={clientActions} archived={archived} />
      <div className={styles.wrapper}>
        <div testid="client_wrapper" className={styles.transaction}>
          <Avatar
            className={styles.transactionAvatar}
            placeholder={<ListingHome className={styles.listingHomeIcon} />}
          />
          <div className={styles.transactionInfoTitle}>
            <span testid="client_name">{currentTransaction?.topicName}</span>
            {archived && (
              <span testid="client_archived" className={styles.transactionInfoTitleLabel}>
                Archived
              </span>
            )}
          </div>
        </div>
        <div className={styles.scrollWrapper}>
          {currentTransaction?.threads?.length ? (
            currentTransaction?.threads.map((thread) => (
              <UsersMessage key={thread?.Id} thread={thread} {...longPressEvent(thread)} />
            ))
          ) : (
            <NoThreads />
          )}
        </div>
      </div>
      <ActionsList actions={actions} position={actionsPosition} />
    </>
  );
};

TransactionContent.propTypes = {
  params: PropTypes.shape({}),
  // archived: PropTypes.bool.isRequired,
  // isOffers: PropTypes.bool,
  // isQuotes: PropTypes.bool,
};

TransactionContent.defaultProps = {
  params: {},
  // isQuotes: false,
  // isOffers: true,
};

export default TransactionContent;
