import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { chunk } from 'lodash-es';

import { pushOrRemoveStageOptionArr } from 'pages/OnBoardingWizard/helpers';
import { InputLabel } from 'components';
import Icon from '../Icons';

import styles from './styles.module.scss';

const TransportOptions = (props) => {
  const { className, onChange, value, disabled, testid, optionClassName } = props;
  const [actives, setActives] = useState(value);

  useEffect(() => setActives(value), [value]);

  const onClick = useCallback(
    (type) => {
      const newActives = pushOrRemoveStageOptionArr({ arr: actives, value: type, multiple: true });

      setActives(newActives);
      // onChange({ [StageCommute.propNames.transportationModePreference]: newActives });
      onChange(newActives);
    },
    [onChange, actives],
  );

  const getOptions = () => chunk(TransportOptions.options, 4);

  const renderOptions = () =>
    getOptions().map((option, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className={styles.optionHolder}>
        {option.map(({ id, label }) => (
          <div
            key={id}
            onClick={() => onClick(id)}
            className={classNames(
              styles.option,
              { [styles.active]: actives.includes(id) },
              optionClassName,
            )}
            testid={testid}
          >
            <div className={styles.optionInner}>
              <Icon className={styles.icon} variant={id} />
              <p className={styles.title}>{label}</p>
            </div>
          </div>
        ))}
      </div>
    ));

  return (
    <div className={classNames(styles.wrapper, { [styles.disabled]: disabled }, className)}>
      <div className={styles.holder}>
        <InputLabel label="Transport Mode" />
        {renderOptions()}
      </div>
    </div>
  );
};

TransportOptions.CAR = 'Car';
TransportOptions.PUBLIC_TRANSIT = 'PublicTransit';
TransportOptions.BIKE = 'Bicycle';
TransportOptions.WALK = 'Walking';

TransportOptions.options = [
  { id: TransportOptions.CAR, label: 'Car' },
  { id: TransportOptions.PUBLIC_TRANSIT, label: 'Transit' },
  { id: TransportOptions.BIKE, label: 'Bike' },
  { id: TransportOptions.WALK, label: 'Walk' },
];

TransportOptions.propTypes = {
  className: PropTypes.string,
  optionClassName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  testid: PropTypes.string,
};

TransportOptions.defaultProps = {
  className: '',
  optionClassName: '',
  onChange: () => {},
  value: [],
  disabled: false,
  testid: undefined,
};

export default TransportOptions;
