import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { LocationService } from 'services';
import { useLocation } from 'react-router-dom';

import { TasksView } from './components';
import { TaskModal } from './components/TaskModal/TaskModal';
import { getTransactionEffect, getKeyDatesEffect } from 'store/effects/transactions';
import { getTransactionTasksSelector } from 'store/selectors/transactionTasks';
import { AsideNavigation, Content, PageWrapper } from 'components/Transactions';

import styles from './styles.module.scss';

const locationSrv = new LocationService();

const TransactionTasks = (props) => {
  const { className, match } = props;
  const location = useLocation();
  locationSrv.setLocation(location);
  const { refetch } = locationSrv.getQuery();
  const dispatch = useDispatch();
  const { isPending } = useSelector(getTransactionTasksSelector);
  const [taskModal, setTaskModal] = useState(false);

  useEffect(() => {
    dispatch(getTransactionEffect({ id: match.params.id }, { silent: !refetch }));
    dispatch(getKeyDatesEffect({ transactionId: match.params.id }, { silent: true }));
  }, [match.params.id]);

  return (
    <div className={classNames(styles.transactionTasks, className)}>
      <PageWrapper>
        <AsideNavigation />
        <Content isPending={isPending}>
          <TasksView />
          <TaskModal isOpen={taskModal} onCloseModal={() => setTaskModal(false)} isNew={true} />
        </Content>
      </PageWrapper>
    </div>
  );
};

TransactionTasks.propTypes = {
  className: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,
};

TransactionTasks.defaultProps = {
  className: '',
};

export default TransactionTasks;
