import moment from 'moment';
import PropTypes from 'prop-types';
import { Dropdown, Menu } from 'antd';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { Icons } from 'pages/Workshop/Icons';
import { getLocaleDate, limitTextLength } from 'helpers/index';

import { useState } from 'react';
import { PdfViewModal } from 'components';
import { useDispatch } from 'react-redux';
import { previewDocumentEffect } from 'store/effects/transactions';
import { DocumentVaultResponseType } from 'types';
import { LocalStorage } from 'services';
import { TokenTypes } from 'app-constants';
import { Buffer } from 'buffer';
import styles from './styles.module.scss';

export const Document = ({ document, offerData }) => {
  const onDownload = () => {
    const token = LocalStorage.getExternalToken();

    dispatch(
      previewDocumentEffect(
        { DocumentVaultUUID: document.DocumentVaultUUID, token, type: TokenTypes.Offer_Iteration },
        {},
        (err, response) => {
          if (err) {
            return;
          }
          fileDownload(response.data, response.headers['file-name']);
        },
      ),
    );

    setOpen(false);
  };
  const dispatch = useDispatch();
  const [attachment, setAttachment] = useState<DocumentVaultResponseType>();
  const [open, setOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const onPreview = () => {
    setIsPreviewOpen(true);
    if (document.DocumentVaultUUID) {
      const token = LocalStorage.getExternalToken();

      dispatch(
        previewDocumentEffect(
          {
            DocumentVaultUUID: document.DocumentVaultUUID,
            token,
            type: TokenTypes.Offer_Iteration,
          },
          {},
          (err, response) => {
            if (!err) {
              setAttachment({
                DocumentBuffer: response,
                Filename: document.DocumentName || response.headers['file-name'],
              });
            }
          },
        ),
      );
      setOpen(false);
    }
  };

  const { BuyingAgentName } = offerData;
  const getAuthorName = () => {
    let authorName;
    if (offerData?.SubmittedBy) {
      authorName = offerData?.SubmittedBy?.FirstName + ' ' + offerData?.SubmittedBy?.LastName;
    } else {
      authorName = offerData?.BuyingAgentName;
    }
    return authorName;
  };

  const menu = (
    <Menu className={styles.preApprovalMenu}>
      <Menu.Item
        className={styles.action}
        onClick={onPreview}
        key={'1'}
        icon={<Icons variant={'previewMenu'} />}
      >
        Preview
      </Menu.Item>
      <Menu.Item
        className={styles.action}
        onClick={onDownload}
        key={'2'}
        icon={<Icons variant={'downloadMenu'} />}
      >
        Download
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.document}>
      <div className={styles.docInfo}>
        <Icons className={styles.docIcon} variant="document" />
        <div className={styles.info}>
          <h4>{limitTextLength(document.DocumentName || document.Filename, 25)}</h4>
          <p>
            {limitTextLength(getAuthorName(), 20)} {'-'}{' '}
            {moment(getLocaleDate(document.CreatedDate)).format('MMM DD')}
            <span className={styles.time}>
              {' '}
              at {moment(getLocaleDate(document.CreatedDate)).format('hh:mm A')}
            </span>
          </p>
        </div>
      </div>
      <Dropdown overlay={menu} className={styles.dropdown} placement={'bottomRight'}>
        <a onClick={(e) => e.preventDefault()}>
          <Icons className={styles.moreIcon} variant="more" />
        </a>
      </Dropdown>

      {attachment?.DocumentBuffer && (
        <PdfViewModal
          isOpen={isPreviewOpen}
          file={attachment}
          onClose={() => setIsPreviewOpen(false)}
        />
      )}
    </div>
  );
};

Document.propTypes = {
  document: PropTypes.shape({
    Id: PropTypes.number,
    ShowingAppointmentId: PropTypes.number,
    filename: PropTypes.string,
    DocumentName: PropTypes.string,
    DocumentType: PropTypes.string,
    DocumentLink: PropTypes.string,
    CreatedDate: PropTypes.string,
    UpdatedDate: PropTypes.string,
  }),

  offerData: PropTypes.object,
};

Document.defaultProps = {
  document: {},
  offerData: {},
};
