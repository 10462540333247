import { useState } from 'react';
import { Tooltip } from 'components-antd';

import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';

import styles from './styles.module.scss';

export const InfoTooltip = (props) => {
  const { text, getPopupContainer } = props;
  const [open, setOpen] = useState(false);

  return (
    <div
      className={styles.InfoTooltipInfo}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Tooltip
        title={text}
        placement="bottom"
        open={open}
        overlayClassName={styles.InfoTooltip}
        getPopupContainer={getPopupContainer}
      >
        <Icon variant={Icon.INFO} />
      </Tooltip>
    </div>
  );
};
