import Api from 'store/effects/core/api';
import { getTaskCategories, getTaskCategoryById } from 'api/transactions';
import {
  requestDeleteTaskCategoryDashboardAction,
  requestGetTaskCategoriesDashboardAction,
  requestGetTaskCategoryDashboardAction,
  requestPostTaskCategoryDashboardAction,
  requestPutActivateTaskCategoryDashboardAction,
  requestPutTaskCategoryDashboardAction,
  searchTaskCategoriesDashboardAction,
} from 'store/actions/adminPanel';
import {
  deleteDeactivateTaskCategory,
  postTaskCategoryData,
  putActivateTaskCategory,
  putTaskCategoryData,
} from 'api/admin/taskCategories';
import { get } from 'lodash-es';
import { getState } from 'store/index';

export const getAllTaskCategoriesDashboardEffect = (cfg, options, cb) => {
  const {
    adminPanel: {
      taskCategories: { filters },
    },
  } = getState();
  const requestParams = {
    action: requestGetTaskCategoriesDashboardAction,
    method: getTaskCategories,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(
    { ...(cfg || {}), searchString: filters?.searchString || undefined },
    options,
    cb,
  );
};

export const getTaskCategoryDashboardEffect = ({ id }, options, cb) => {
  const requestParams = {
    action: requestGetTaskCategoryDashboardAction,
    method: getTaskCategoryById,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest({ id }, options, cb);
};

export const deactivateTaskCategoryEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestDeleteTaskCategoryDashboardAction,
    method: deleteDeactivateTaskCategory,
  });

  return sendRequest(cfg, options, (err, response, dispatch) => cb(err, response, dispatch));
};

export const activateTaskCategoryEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestPutActivateTaskCategoryDashboardAction,
    method: putActivateTaskCategory,
  });

  return sendRequest(cfg, options, (err, response, dispatch) => cb(err, response, dispatch));
};

const getTaskCategorySendData = (values) => ({
  Name: get(values, 'Name'),
});

export const updateTaskCategoryEffect = ({ values, id }, cb) => {
  const sendRequest = Api.execBase({
    action: requestPutTaskCategoryDashboardAction,
    method: putTaskCategoryData,
  });
  const sendValues = getTaskCategorySendData(values, true);

  return sendRequest(sendValues, { id }, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllTaskCategoriesDashboardEffect());
    }
    cb(err, response, dispatch);
  });
};

export const createTaskCategoryEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestPostTaskCategoryDashboardAction,
    method: postTaskCategoryData,
  });
  const sendValues = getTaskCategorySendData(cfg);

  return sendRequest(sendValues, {}, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllTaskCategoriesDashboardEffect());
    }
    cb(err, response, dispatch);
  });
};

export const searchTaskCategoriesDashboardEffect = (cfg) => (dispatch) => {
  dispatch(searchTaskCategoriesDashboardAction(cfg));
  return dispatch(getAllTaskCategoriesDashboardEffect());
};
