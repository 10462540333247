import { createAction } from 'redux-actions';

export const sendMessageAction = createAction('POST/SEND_MESSAGE');

export const cancelFormDocumentAction = createAction('POST/CANCEL_FORM_DOCUMENT');

export const voidFormDocumentAction = createAction('POST/VOID_FORM_DOCUMENT');

export const getDraftFormsAction = createAction('REQUEST/DRAFT_FORM');

export const deleteDraftFormAction = createAction('DELETE/DRAFT_FORM');

export const getFormDetailsAction = createAction('REQUEST/GET_FORM_DETAILS');

export const resetFormDetailsAction = createAction('RESET/FORM_DETAILS');

export const resendEmailAction = createAction('RESEND/EMAIL');

export const replaceEditorAction = createAction('REPLACE/EDITOR');

export const replaceSignatoryAction = createAction('REPLACE/SIGNATORY');

export const removeEditorAction = createAction('REMOVE/EDITOR');

export const previewCombinedFormAction = createAction('REQUEST/COMBINED_FORM');
