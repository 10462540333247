import React, { useRef } from 'react';
import { RefSelectProps } from 'antd/lib/select';

import { SearchIcon } from 'components/Icons';
import { AsyncSelect } from 'components';
import { getSchoolsDistrictsAutocompleteResults } from 'api/listingDetail';
import { SelectOption } from 'types';

import styles from './styles.module.scss';

interface SchoolsDistrictsSearchProps {
  value: SpecificSchoolOrDistrict[];
  onChange: (newValue: SpecificSchoolOrDistrict | SpecificSchoolOrDistrict[]) => void;
  isMultiple?: boolean;
}

export const SchoolsDistrictsSearch: React.FC<SchoolsDistrictsSearchProps> = (props) => {
  const selectRef = useRef<RefSelectProps | null>(null);

  const handleSelectChange = (value: SelectOption | SelectOption[]) => {
    const select = selectRef.current as any;
    if (select) {
      select.blur();
    }
    props.onChange(value as SpecificSchoolOrDistrict[]);
  };
  return (
    <AsyncSelect
      value={props.value}
      ref={selectRef}
      onChange={handleSelectChange}
      mode="multiple"
      maxTagCount="responsive"
      fetchOptions={fetchAutocompleteData}
      placeholder={
        <div className={styles.searchIconWrapper}>
          <SearchIcon {...{ className: styles.searchIcon }} /> Enter address, school(s), and/or
          district(s)
        </div>
      }
      className={props.isMultiple ? styles.multiSelectStyling : styles.schoolsDistrictSelect}
    />
  );
};

export interface SpecificSchoolOrDistrict extends SelectOption {
  value: string;
}

async function fetchAutocompleteData(searchString: string): Promise<SpecificSchoolOrDistrict[]> {
  if (!searchString) {
    return [];
  }

  const response = await getSchoolsDistrictsAutocompleteResults({
    text: searchString,
  });

  const items = response.data.result.Items;

  return items.map((item) => ({
    label: item.Name,
    value: JSON.stringify({
      Id: item.Id,
      Name: item.Name,
      Type: item.Type,
    }),
  }));
}
