import { createSelector } from 'reselect';
import { get } from 'lodash-es';

const localState = ({ drawers }) => get(drawers, 'viewQuoteActivity');

export const getDrawerState = createSelector(localState, (state) => state);

export const isQuoteActivityDrawerOpenSelector = createSelector(localState, ({ open }) => !!open);

export const getQuoteActivityDrawerParams = createSelector(localState, ({ params }) => params);
