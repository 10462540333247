export default {
  dashboardTimelineTemplates: '/dashboard/templates/timeline',
  dashboardTimelineTemplate: '/dashboard/templates/timeline/:id',
  dashboardTaskTemplates: '/dashboard/templates/task',
  dashboardTaskTemplate: '/dashboard/templates/task/:id',
  dashboardTransactionsTemplates: '/dashboard/templates/transactions',
  dashboardTransactionTemplateView: '/dashboard/templates/transaction/:id',
  dashboardTransactionTemplateEdit: '/dashboard/templates/transaction/:id/edit',
  dashboardTransactionTemplateCreate: '/dashboard/templates/transaction/create',
  dashboardProjectsTemplates: '/dashboard/templates/projects',
  dashboardProjectTemplateView: '/dashboard/templates/project/:id',
  dashboardProjectTemplateEdit: '/dashboard/templates/project/:id/edit',
  dashboardProjectTemplateCreate: '/dashboard/templates/project/create',
  dashboardMasterTemplates: '/dashboard/templates/masterTransactions',
  dashboardTeamTransactionTemplates: '/dashboard/teams/:teamId',
  dashboardTeamTransactionTemplateView: '/dashboard/templates/transaction/:teamId/:id',
  dashboardTeamTransactionTemplateEdit: '/dashboard/templates/transaction/:teamId/:id/edit',
  dashboardTeamTransactionTemplateCreate: '/dashboard/templates/transaction/create/:teamId',
};
