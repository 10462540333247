import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { appOpenModalEffect } from 'store/effects/app';
import { getModalSelector } from 'store/selectors/app';
import { Modal, Answers } from 'components';
import NextButton from 'pages/OnBoardingWizard/components/NextButton';

import styles from './styles.module.scss';
import { prefsIds } from 'settings/constants/preferences';

const KitchenFeaturesModal = ({ className, onNext, value }) => {
  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();
  const [features, setFeatures] = useState(value || []);

  useEffect(() => setFeatures(value), [value]);

  if (modal.id !== KitchenFeaturesModal.id) {
    return null;
  }

  const onClose = () => {
    onNext(features);
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  const onNextHandler = () => {
    onClose();
  };

  return (
    <Modal
      className={classNames(className)}
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      closeClassName={styles.close}
      innerHolderClassName={styles.innerHolder}
    >
      <h2 className={styles.title}>Which kitchen features are important to you? </h2>
      <Answers
        multiple
        unselectable
        className={styles.answersWrapper}
        caseClassName={styles.option}
        cases={KitchenFeaturesModal.answerCases}
        onChange={(val) => setFeatures(val)}
        value={features}
        testid="interior_answers"
        variant={Answers.CHECKBOX}
      />
      <NextButton onClick={onNextHandler} className={styles.next} />
    </Modal>
  );
};

KitchenFeaturesModal.id = 'interiorKitchenFeaturesModal';
// TODO: add to the BE
KitchenFeaturesModal.answerCases = [
  {
    id: prefsIds.kitchenFeaturesLargerThanAverage,
    label: 'Larger Than Average',
    value: 'LargerThanAverage',
  },
  // { id: prefsIds.kitchenFeaturesEatIn, label: 'Eat-In', value: 'EatIn' },
  { id: prefsIds.kitchenFeaturesIsland, label: 'Island', value: 'Island' },
  {
    id: prefsIds.kitchenFeaturesStainlessSteelAppliances,
    label: 'Stainless Steel Appliances',
    value: 'Stainless Steel Appliances',
  },
  // { id: prefsIds.kitchenGasCooktop, label: 'Gas Cooktop', value: 'GasCooktop' },
  // {
  //   id: prefsIds.kitchenElectricCooktop,
  //   label: 'Electric Cooktop',
  //   value: 'ElectricCooktop',
  // },
];

KitchenFeaturesModal.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  value: PropTypes.array,
};

KitchenFeaturesModal.defaultProps = {
  className: '',
  onNext: () => {},
  value: [],
};

export default KitchenFeaturesModal;
