import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getShortedChatParticipantsNames, getPropertyAddress } from 'helpers/formatters';

import styles from './styles.module.scss';

const Info = (props) => {
  const { className, names, shareProperty } = props;

  const { Address } = shareProperty || {};

  return (
    <div testid="info" className={classNames(styles.info, className)}>
      <div testid="participants_names" className={styles.names}>
        {getShortedChatParticipantsNames(names)}
      </div>
    </div>
  );
};

Info.propTypes = {
  className: PropTypes.string,
  names: PropTypes.arrayOf(PropTypes.string),
  shareProperty: PropTypes.shape({
    Address: PropTypes.shape({
      Line1: PropTypes.string,
      City: PropTypes.string,
      State: PropTypes.string,
    }),
  }),
};

Info.defaultProps = {
  className: '',
  names: [],
  shareProperty: {},
};

export default Info;
