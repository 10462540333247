import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { TrashIcon } from 'components/Icons';
import { Modal, Button, CommuteForm } from 'components';
import { getModalSelector } from 'store/selectors/app';
import { appOpenModalEffect } from 'store/effects/app';

import { Title } from 'pages/OnBoardingWizard/components';

import { commuteTime } from 'settings/constants/commuteTime';
import styles from './styles.module.scss';

const AddCommuteModal = (props) => {
  const { onChange, onDelete, value, isPending, creatableCommuteIndex } = props;
  const { className, onAnotherSave } = props;
  const [commuteValue, setCommuteValue] = useState({
    MaxCommuteTimeInMinutes: commuteTime.FIFTEEN_MINUTES,
    ...value,
  });

  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();

  useEffect(() => setCommuteValue(value), [value]);

  if (modal.id !== AddCommuteModal.id) {
    return null;
  }

  const closeModal = (isSave) => {
    if (!isSave) {
      onDelete(creatableCommuteIndex);
    }
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  const onSave = () => {
    onChange({ ...commuteValue }, creatableCommuteIndex);
    onAnotherSave();
    closeModal(true);
  };

  const onValueChange = (val) => {
    const newValue = { ...commuteValue, ...val };
    setCommuteValue(newValue);
    onChange(newValue, creatableCommuteIndex);
  };

  const disabled =
    !commuteValue?.Name ||
    !commuteValue?.DestinationAddress ||
    !Object.keys(commuteValue?.TransportationModePreference || {}).length;

  return (
    <Modal
      className={classNames(styles.modal, className)}
      isOpen={modal.open}
      onClose={() => closeModal(null)}
      testid="add_commute_modal"
    >
      <Title>Where is your commute to?</Title>
      <CommuteForm onChange={onValueChange} value={commuteValue} isPending={isPending} />
      <Button
        testid="add_commute_save"
        onClick={onSave}
        className={styles.saveButton}
        title="Save"
        disabled={disabled}
      />
      <Button
        titleClassName={styles.deleteButtonTitle}
        className={styles.deleteButton}
        onClick={() => closeModal(null)}
        title="Delete"
        icon={<TrashIcon className={styles.deleteIcon} />}
        testid="add_commute_delete"
      />
    </Modal>
  );
};

AddCommuteModal.id = 'commuteModal';

AddCommuteModal.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.shape({}),
  isPending: PropTypes.bool,
  creatableCommuteIndex: PropTypes.number.isRequired,
  onAnotherSave: PropTypes.func,
};

AddCommuteModal.defaultProps = {
  className: '',
  isPending: false,
  value: {},
  onAnotherSave: () => {},
};

export default AddCommuteModal;
