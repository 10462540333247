import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LocalHeader } from './LocalHeader';
import { OffersTable } from './components/OffersTable';
import { resetOfferAggregateEffect, setOffersIsArchiveEffect } from 'store/effects/offerAggregate';
import { getUserId } from 'store/selectors/user';
import {
  getOfferAggregateDataSelector,
  getOfferIsArchiveSelector,
} from 'store/selectors/offerAggregate';
import { getOfferAggregateByUserIdEffect } from 'store/effects/offerAggregate';

import styles from './styles.module.scss';
import { PageWrapper } from 'pages/Workshop/PageWrapper';
import { LocationService } from 'services';
import moment from 'moment';

export const Offers = () => {
  const dispatch = useDispatch();
  const { isArchive } = useSelector(getOfferIsArchiveSelector);
  const userId = useSelector(getUserId);
  const offerAggregate = useSelector(getOfferAggregateDataSelector);
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  const getOffers = (filters) => {
    dispatch(
      getOfferAggregateByUserIdEffect({
        id: userId,
        filters: filters,
      }),
    );
  };
  useEffect(() => {
    if (!Object.keys(query).length && !isArchive) {
      dispatch(
        getOfferAggregateByUserIdEffect({
          id: userId,
        }),
      );
    }
  }, [isArchive]);

  useEffect(() => {
    if (Object.keys(query).length) {
      if (query.isArchive) dispatch(setOffersIsArchiveEffect(true));

      const filterStatus = query.teamStats ? {} : { isMyOffers: true };
      const filters = {
        status: query.status ? query.status.split(',') : [],
        statuses: query.statuses ? query.statuses.split(',') : [],
        expiresFromDate: query.dueToday ? moment() : undefined,
        expiresToDate: query.dueToday ? moment() : undefined,
      };

      getOffers({ ...filters, ...filterStatus });
    }
  }, []);
  useEffect(() => {
    return () => {
      dispatch(resetOfferAggregateEffect());
    };
  }, []);

  return (
    <PageWrapper isContainer={false}>
      <div className={styles.offersPage}>
        <LocalHeader />
        <div className={styles.offersWrapper}>
          <OffersTable offerAggregate={offerAggregate} />
        </div>
      </div>
    </PageWrapper>
  );
};
