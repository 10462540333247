import React, { memo, useMemo } from 'react';

const ArrowRight2 = memo(({ className, color = '#AAABAB' }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div testid="arrow_right_icon" className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.30616 18.6943C8.71437 19.1017 9.37622 19.1017 9.78443 18.6943L15.6975 12.7931C15.9232 12.5679 16.0241 12.2653 16.0002 11.9708C16.0063 11.696 15.9044 11.4193 15.6945 11.2096L9.7841 5.30568C9.37607 4.8981 8.71453 4.8981 8.3065 5.30568C7.89848 5.71326 7.89848 6.37408 8.3065 6.78166L13.5331 12.0025L8.30616 17.219C7.89795 17.6264 7.89795 18.2869 8.30616 18.6943Z"
            fill={color}
          />
        </svg>
      </div>
    ),
    [className, color],
  ),
);

export default ArrowRight2;
