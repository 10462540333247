import classNames from 'classnames';
import { useSelector } from 'react-redux';

import {
  getAllTransactionsSelector,
  getAllTransactionsWithStatusSelector,
} from 'store/selectors/transactions';
import { Wrapper } from 'components';
import { ProjectsTables, TransactionsTables } from './Tables';
import { getUserRoleSelector } from 'store/selectors/user';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';

interface TransactionsAndProjectsTableProps {
  className?: string;
  isArchive?: boolean;
  transactionFilter: string;
  projectFilter: string;
  isFiltersApplied: boolean;
}

export const TransactionsAndProjectsTable = ({
  className,
  isArchive,
  transactionFilter,
  isFiltersApplied,
  projectFilter,
}: TransactionsAndProjectsTableProps) => {
  const data = useSelector(getAllTransactionsWithStatusSelector);
  const userRole = useSelector(getUserRoleSelector);
  const isProject = useIsProjectRoute();

  const tablesProps = {
    isFiltersApplied: isFiltersApplied,
    isArchive: isArchive,
    transactions: data?.transactions,
    userRole: userRole,
    transactionFilter: transactionFilter,
    projectFilter: projectFilter,
  };

  return (
    <Wrapper isPending={data?.isPending}>
      <div className={classNames(styles.transactionsList, className)}>
        {!isProject ? <TransactionsTables {...tablesProps} /> : <ProjectsTables {...tablesProps} />}
      </div>
    </Wrapper>
  );
};
