import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Popover } from 'antd';

import classNames from 'classnames';
import { openSearchResultsFilterDrawerEffect } from 'store/effects';
import { CREATE } from 'settings/constants/mode';
import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const MoreFilters = ({ search, className, mode, isClientSearch }) => {
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(openSearchResultsFilterDrawerEffect({ open: true, mode, isClientSearch }));
  };

  const isMoreFilters = !!Object.keys(search).length;

  const popoverContent = () => <span>{isMoreFilters ? 'Edit' : 'Add'} Criteria</span>;

  return (
    <Popover
      content={popoverContent}
      placement="top"
      overlayClassName={classNames('mosaikTooltip')}
      getPopupContainer={(triggerNode) => triggerNode}
      arrow={true}
    >
      <div
        onClick={onClickHandler}
        className={classNames({ [styles.active]: isMoreFilters }, styles.filter, className)}
      >
        <Icon variant={Icon.FILTER} />
      </div>
    </Popover>
  );
};

MoreFilters.propTypes = {
  className: PropTypes.string,
  search: PropTypes.shape({}).isRequired,
  mode: PropTypes.string,
  isClientSearch: PropTypes.bool,
};

MoreFilters.defaultProps = {
  className: '',
  mode: CREATE,
  isClientSearch: false,
};

export default MoreFilters;
