import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash-es';

import { Locations } from 'components';
import { sortLocations } from 'helpers';

import styles from './styles.module.scss';

const AreaOperations = ({ isPending, formik, className }) => {
  const onLocationsChange = useCallback(
    (result, prepareData) => {
      let value = prepareData?.length
        ? [...prepareData, ...(formik.values.areasOfOperation || [])]
        : formik.values.areasOfOperation || [];

      if (result?.deleteItemId) {
        value = value.filter(({ ProviderPlaceId: itemId }) => itemId !== result.deleteItemId);
      }

      value = uniqBy(value, 'ProviderPlaceId');
      value.sort(sortLocations);
      formik.setFieldValue('areasOfOperation', value);
    },
    [formik],
  );

  return (
    <div className={className}>
      <Locations
        variant={Locations.LIGHT}
        placeholder="Cities"
        types={['(cities)']}
        onResult={onLocationsChange}
        value={
          formik.values.areasOfOperation?.filter(
            ({ Type: type }) => type === 'City' || type === 'Zipcode',
          ) || []
        }
        error={formik.touched.areasOfOperation ? formik.errors.areasOfOperation : ''}
        disabled={isPending}
        className={styles.areaOfOperations}
        testid="city"
        valuesWrapperClassName={styles.valuesWrapper}
        activeInputClassName={styles.activeInput}
        showPrefixIcon={true}
      />
      <Locations
        types={['address']}
        variant={Locations.LIGHT}
        placeholder="Neighborhoods (Optional)"
        onResult={onLocationsChange}
        value={(formik.values.areasOfOperation || []).filter(
          ({ Type: type }) => type !== 'City' && type !== 'Zipcode',
        )}
        disabled={isPending}
        className={styles.areaOfOperations}
        testid="neighborhoods"
        valuesWrapperClassName={styles.valuesWrapper}
        activeInputClassName={styles.activeInput}
        showPrefixIcon={true}
      />
    </div>
  );
};
AreaOperations.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      areasOfOperation: PropTypes.arrayOf(PropTypes.shape({})),
      websites: PropTypes.arrayOf(PropTypes.string),
    }),
    handleChange: PropTypes.func,
    touched: PropTypes.shape({
      areasOfOperation: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.shape({})),
      ]),
    }),
    errors: PropTypes.shape({
      areasOfOperation: PropTypes.string,
    }),
    setFieldValue: PropTypes.func,
  }).isRequired,
};

AreaOperations.defaultProps = {
  className: '',
  isPending: false,
};

export default AreaOperations;
