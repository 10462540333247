import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const SoftCriteria = ({ className, onClick, icon, testid }) => {
  const getIcon = useCallback(() => {
    switch (icon) {
      case SoftCriteria.PARKING:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16Z"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <path
              d="M12 24V17M12 17V9H18C20.2091 9 22 10.7909 22 13C22 15.2091 20.2091 17 18 17H12Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case SoftCriteria.VIEW:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.79297 24.3109L9.16078 23.5868C10.0164 23.1338 11.0408 23.1338 11.8964 23.5868C12.752 24.0398 13.7764 24.0398 14.632 23.5868C15.4877 23.1338 16.5121 23.1338 17.3677 23.5868C18.2233 24.0398 19.2477 24.0398 20.1033 23.5868C20.9589 23.1338 21.9833 23.1338 22.8389 23.5868L24.2068 24.3109"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <path
              d="M20.7144 23.4182C20.87 22.7124 20.865 21.9806 20.7 21.277C20.535 20.5733 20.214 19.9157 19.7609 19.3527C19.3078 18.7896 18.734 18.3355 18.0819 18.0238C17.4299 17.7121 16.7161 17.5508 15.9934 17.5518C15.2706 17.5527 14.5573 17.716 13.9061 18.0295C13.2549 18.3429 12.6823 18.7986 12.2308 19.3629C11.7792 19.9272 11.46 20.5857 11.2969 21.2898C11.1338 21.9939 11.1309 22.7256 11.2883 23.431"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <path
              d="M12.1377 10.2754C13.1032 10.5168 13.586 10.9995 14.0687 11.7237C14.5515 10.9995 15.0342 10.5168 15.9998 10.2754"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.9312 13.6553C18.6553 13.8162 19.0174 14.138 19.3794 14.6208C19.7415 14.138 20.1036 13.8162 20.8277 13.6553"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M9.72412 27.6553H22.2758" stroke="#AAABAB" strokeWidth="2" />
            <path d="M10.6895 4H21.3101" stroke="#AAABAB" strokeWidth="2" />
            <path
              d="M2 4.48276C2 4.21614 2.21614 4 2.48276 4H10.6897C10.6897 11.7241 8.75862 15.8276 4.41379 18.9655C8.56428 22.1937 9.631 25.2016 9.71813 28.6204C9.72492 28.887 9.508 29.1034 9.24138 29.1034H2.48276C2.21614 29.1034 2 28.8873 2 28.6207V4.48276Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M30.0002 4.48276C30.0002 4.21614 29.7841 4 29.5174 4H21.3105C21.3105 11.7241 23.2416 15.8276 27.5864 18.9655C23.4359 22.1937 22.3692 25.2016 22.2821 28.6204C22.2753 28.887 22.4922 29.1034 22.7588 29.1034H29.5174C29.7841 29.1034 30.0002 28.8873 30.0002 28.6207V4.48276Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 18.9658H5.86207"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M30 18.9658L26.1379 18.9658"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case SoftCriteria.ROOF_TOP:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 28V20H28V28"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 28V25H7V28"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 28V25H14V28"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25 28V25H21V28"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 12C7.34286 14.704 7.5 15.88 7.5 19"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M10.9998 18.9999L10.9999 16.2666H7.57129"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25 12C24.6571 14.704 24.5 15.88 24.5 19"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M21.0001 18.9999L21 16.2666H24.4286"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 13H13M21 13L19 13M13 13L19 20M13 13L19 13M19 13L13 20"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 6H9V5L16 2L23 5V6H16ZM16 6V13"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      default: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16Z"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <path
              d="M12 24V17M12 17V9H18C20.2091 9 22 10.7909 22 13C22 15.2091 20.2091 17 18 17H12Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
    }
  }, [icon]);

  return (
    <div testid={testid} className={className} onClick={onClick}>
      {getIcon()}
    </div>
  );
};

SoftCriteria.PARKING = 'parking';
SoftCriteria.VIEW = 'view';
SoftCriteria.ROOF_TOP = 'roof_top';

SoftCriteria.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  testid: PropTypes.string,
};

SoftCriteria.defaultProps = {
  className: '',
  onClick: () => {},
  icon: SoftCriteria.PARKING,
  testid: undefined,
};

export default SoftCriteria;
