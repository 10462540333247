import { createAction } from 'redux-actions';

export const requestGetUserDataAction = createAction('REQUEST/GET_USER_DATA');
export const requestPutUserDataAction = createAction('REQUEST/PUT_USER_DATA');
export const signUpAction = createAction('REQUEST/POST_SIGNUP');
export const emailExistsActions = createAction('REQUEST/SIGNUP_EMAIL_EXISTS');
export const requestUpdateBuyerImportantFeaturesAction = createAction(
  'REQUEST/PUT_UPDATE_BUYER_IMPORTANT_FEATURES',
);
export const requestUpdateBuyerCommutesAction = createAction('REQUEST/PUT_UPDATE_BUYER_COMMUTES');
export const requestVerifyEmailAction = createAction('REQUEST/VERIFY_EMAIL');
export const requestNotificationsSettingsAction = createAction('REQUEST/NOTIFICATIONS_SETTINGS');
export const requestPutUserInsightAction = createAction('REQUEST/PUT_USER_INSIGHT');
export const requestPutUserIsMfaEnabledAction = createAction('REQUEST/PUT_USER_IS_MFA_ENABLED');
export const requestGetMarkAgentProfileAsCompleteAction = createAction(
  'REQUEST/GET_USER_MARK_PROFILE_AS_COMPLETE',
);

export const updateUserProfileWithVerfiedPhoneNumberAction = createAction(
  'UPDATE_USER_PROFILE_WITH_VERIFIED_PHONE_NUMBER',
);

export const setVerifiedEmailFlagAction = createAction('SET_USER_VERIFIED_EMAIL_FLAG');
export const setAgentSelectedMlsServiceAction = createAction('SET_AGENT_SELECTED_MLS_SERVICES');
