import Api from 'store/effects/core/api';
import {
  createOfferSubmissionAction,
  createUpdateOfferAction,
  deleteOfferAction,
  offerListAction,
  selectedOffersForComparison,
  sendAnnouncementAction,
  updateOfferIterationAction,
  sendCounterOfferAction,
} from 'store/actions/offers';

import {
  createUpdateOffer,
  deleteOffer,
  listOffer,
  sendAnnouncement,
  submitOffer,
  updateOfferIteration,
  sendCounterOffer,
  getOfferDetailsByUUID,
} from 'api/offers';
import { LocalStorage } from 'services';
import { ApiRequestError } from 'components';

export const getOfferByUUID = (UUID) => {
  return async () => {
    const res = await getOfferDetailsByUUID({ UUID }).catch((err) => {
      ApiRequestError(err);
      return Promise.reject(err.data?.message);
    });
    return Promise.resolve(res.data?.value);
  };
};

export const requestCreateUpdateOfferEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: createUpdateOfferAction,
    method: createUpdateOffer,
  });
  return sendRequest(cfg, options, cb);
};

export const requestCreateOfferSubmissionEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: createOfferSubmissionAction,
    method: submitOffer,
  });

  const body = {
    ...cfg,
    OfferIteration: {
      ...(cfg.OfferIteration || {}),
      OfferIterationDocument: [
        ...(cfg.OfferIteration?.OfferIterationDocument || [])
          .filter((d) => d)
          .map((d) => ({
            ...d,
            data: btoa(d.data),
          })),
      ],
    },
  };
  return sendRequest(body, options, (err, res, dispatch) => {
    if (res?.data?.value?.AccessToken) {
      // SubmitOffer endpoint will send back external token
      // to allow access to same page afterwards without extra verification
      LocalStorage.setExternalToken(res.data.value.AccessToken);
    }

    cb && cb(err, res, dispatch);
  });
};

export const deleteOfferEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: deleteOfferAction,
    method: deleteOffer,
  });
  return sendRequest(cfg, options, cb);
};

export const offerListEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: offerListAction,
    method: listOffer,
  });
  return sendRequest(cfg, options, cb);
};

export const sendAnnouncementEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: sendAnnouncementAction,
    method: sendAnnouncement,
  });
  return sendRequest(cfg, options, cb);
};

export const updateOfferIterationEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: updateOfferIterationAction,
    method: updateOfferIteration,
  });
  return sendRequest(cfg, options, cb);
};

export const sendCounterOfferEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: sendCounterOfferAction,
    method: sendCounterOffer,
  });
  return sendRequest(cfg, options, cb);
};

export const setSelectedOffersForComparison = (cfg) => (dispatch) => {
  dispatch(selectedOffersForComparison(cfg));
};
