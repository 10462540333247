import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash-es';
import SmoothCollapse from 'react-smooth-collapse';

import { Minus, Plus } from 'components/Icons';

import styles from './styles.module.scss';

const Row = (props) => {
  const { item, columns, collapsible } = props;
  const { className, cellClassName, childRowClassName, heightTransition } = props;
  const { onClick, getChildRowContent } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpander = useCallback(() => setIsExpanded((val) => !val), [setIsExpanded]);
  const handleRowClick = useCallback(
    (e) => {
      if (typeof onClick === 'function') {
        onClick(e, item);
      }
    },
    [onClick, item],
  );

  const getCollapseIcon = () => (isExpanded ? <Minus /> : <Plus />);

  const getCells = () => (
    <>
      {columns.map(({ propKey, render, onClick: onCellClick, innerCellClassName }, i) => (
        <td
          key={propKey}
          className={classNames(styles.rowCell, cellClassName)}
          onClick={onCellClick}
        >
          <div className={classNames(styles.flexCell, innerCellClassName)}>
            {i === 0 && collapsible ? (
              <div className={styles.collapseButton} onClick={toggleExpander}>
                {getCollapseIcon()}
              </div>
            ) : null}
            {typeof render === 'function' ? render(get(item, propKey), item) : get(item, propKey)}
          </div>
        </td>
      ))}
    </>
  );

  return (
    <>
      <tr
        className={classNames(styles.row, className, { [styles.hasAction]: !!onClick })}
        onClick={handleRowClick}
        testid="grid_row"
      >
        {getCells()}
      </tr>
      <tr className={classNames(styles.childRow, childRowClassName)}>
        <td colSpan={columns.length}>
          <SmoothCollapse expanded={isExpanded} heightTransition={heightTransition}>
            <div>
              {typeof getChildRowContent === 'function' ? getChildRowContent(item, columns) : null}
            </div>
          </SmoothCollapse>
        </td>
      </tr>
    </>
  );
};

Row.propTypes = {
  item: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      propKey: PropTypes.string.isRequired,
      render: PropTypes.func,
    }),
  ).isRequired,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  childRowClassName: PropTypes.string,
  heightTransition: PropTypes.string,
  collapsible: PropTypes.bool,
  onClick: PropTypes.func,
  getChildRowContent: PropTypes.func,
};
Row.defaultProps = {
  collapsible: false,
  className: '',
  cellClassName: '',
  childRowClassName: '',
  heightTransition: '0.3s ease',
  onClick: () => {},
  getChildRowContent: () => null,
};

export default Row;
