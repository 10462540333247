import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Slider from 'react-slick';

import { propertiesSubFeed, subFilterTypes } from 'settings/constants/properties';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { getFeedPageInfoSelector, getSubFilterTypeSelector } from 'store/selectors/feed';
import { getUserRolesMapSelector } from 'store/selectors/user';
import subFilterType from 'store/reducers/feed/subFilterType';

import { Radio } from 'components-antd';
import { ArrowLeft } from 'components/Icons';

import styles from './styles.module.scss';

export const SubFilters = (props) => {
  const { sliderClassName, showSubFilter } = props;
  const dispatch = useDispatch();
  const type = useSelector(getSubFilterTypeSelector);
  const { isClient } = useSelector(getUserRolesMapSelector);
  const location = useLocation();

  const currentPageInfo = useSelector(getFeedPageInfoSelector);

  useEffect(() => {
    if (!currentPageInfo?.currentFEPageInfo?.fromListingDetailPage) {
      dispatch(setSubFilterTypeAction(subFilterTypes.ALL));
    }
  }, [location]);

  const CarouselNext = (props) => {
    const { onClick, className } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowLeft className={styles.icon} />
      </div>
    );
  };
  const CarouselPrev = (props) => {
    const { onClick, className } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowLeft className={styles.icon} />
      </div>
    );
  };

  const settings = {
    draggable: true,
    accessibility: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    nextArrow: <CarouselNext className={styles.arrows} />,
    prevArrow: <CarouselPrev className={styles.arrows} />,
    responsive: [
      {
        breakpoint: 1880,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1525,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1165,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const onChange = (e) => {
    dispatch(setSubFilterTypeAction(e.target.value));
  };

  return (
    <>
      {isClient && showSubFilter && (
        <Radio.Group
          defaultValue={subFilterType.ALL}
          className={styles.feedSubFilters}
          value={type}
          onChange={onChange}
        >
          <Slider {...settings} className={classNames(styles.subfeedSlider, sliderClassName)}>
            {propertiesSubFeed?.map((item, idx) => {
              return (
                <Radio key={idx} className={styles.buttonCheckbox} value={item.id}>
                  {item.label}
                </Radio>
              );
            })}
          </Slider>
        </Radio.Group>
      )}
    </>
  );
};
