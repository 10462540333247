import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { TransactionTemplate } from 'types';

const localState = ({ templates: { transaction } }) => transaction;

export const getTransactionTemplatesSelector = createSelector(
  localState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTransactionTemplatesDataSelector = createSelector(localState, (state) => {
  const data = get(state, 'data') || [];

  return data.map((d) => {
    const timelineTemplate = get(d, 'TimelineTemplate', {});
    const milestones = get(timelineTemplate, 'Milestones', []);
    return {
      ...d,
      TimelineTemplate: {
        ...timelineTemplate,
        Milestones: milestones.map((m) => ({
          Title: m.Title,
          DueDate: m.Offset,
          Initial: m.Initial || undefined,
          Operator: m.Operator || undefined,
          ControlOperatorOffset: m.ControlOperatorOffset || undefined,
          IsPrivate: !!m.IsPrivate,
          PublicId: m.PublicId,
        })),
      },
    };
  }) as TransactionTemplate[];
});
