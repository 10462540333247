import { SchoolsModal } from 'pages/OnBoardingWizard/components/StageParts/StageNeighbors/Options/SchoolsModal';
import React, { useCallback } from 'react';
import { dispatch } from 'store';
import { appOpenModalEffect } from 'store/effects';
import styles from './styles.module.scss';
import Icon from 'pages/Properties/SearchResults/Icons';

export enum SchoolType {
  Public = 'Public',
  Private = 'Private',
  Magnet = 'Magnet',
  Charter = 'Charter',
}

interface SpecificSchoolPref {
  Id: string;
  Name: string;
}

interface SpecificDistrictPref {
  Id: string;
  Name: string;
}

export interface SchoolPrefConfig {
  SpecificSchools?: SpecificSchoolPref[];
  SpecificDistricts?: SpecificDistrictPref[];
  NonSpecificConfig?: {
    SchoolTypes: SchoolType[];
    DistanceInMiles?: number;
  };
  ImportanceAndWeight?: {
    Importance: string;
  };
}

interface SchoolsSelectorProps {
  schoolsPref: SchoolPrefConfig;
  onEditSchoolPrefs: (newValue: SchoolPrefConfig) => void;
}

export const SchoolsSelector: React.FC<SchoolsSelectorProps> = ({
  schoolsPref,
  onEditSchoolPrefs,
}) => {
  const openModal = useCallback(
    (id) => {
      dispatch(appOpenModalEffect({ id, open: true }) as any);
    },
    [dispatch],
  );

  if (!schoolsPref) {
    return null;
  }

  let title = '';
  let subtitle = '';
  if (schoolsPref.NonSpecificConfig) {
    const typesStr = schoolsPref.NonSpecificConfig.SchoolTypes.join(' or ');
    title = `Any ${typesStr} School`;
  } else if (schoolsPref.SpecificDistricts) {
    const districtsStr = schoolsPref.SpecificDistricts.map((d) => d.Name).join(' or ');
    title += `Within ${districtsStr}`;
  } else if (schoolsPref.SpecificSchools) {
    const schoolsStr = schoolsPref.SpecificSchools.map((d) => d.Name).join(' or ');
    title += schoolsStr;
  }

  const renderTitle = () => {
    if (!Object.keys(schoolsPref).length) {
      return <div>Please select preferences</div>;
    }

    if (schoolsPref.NonSpecificConfig) {
      const typesStr = schoolsPref.NonSpecificConfig.SchoolTypes.join(' or ');
      return `Any ${typesStr} School`;
    }

    return [...(schoolsPref.SpecificDistricts ?? []), ...(schoolsPref.SpecificSchools ?? [])].map(
      (item) => <div key={item.Name}>{item.Name}</div>,
    );
  };

  const renderSubtitle = () => {
    if (schoolsPref.NonSpecificConfig) {
      return (
        <div className={styles.subtitle}>
          Within {schoolsPref.NonSpecificConfig.DistanceInMiles} miles
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.leftSection}>
          <Icon className={styles.icon} variant={Icon.GOOD_SCHOOLS} />
          <div>
            {renderTitle()}
            {renderSubtitle()}
          </div>
        </div>
        <button
          className={styles.actionButton}
          onClick={() => {
            openModal((SchoolsModal as any).id as string);
          }}
          type="button"
        >
          <span className={styles.dots}>...</span>
        </button>
      </div>
      <SchoolsModal
        onNext={(val) => {
          onEditSchoolPrefs(val);
        }}
        value={schoolsPref}
      />
    </>
  );
};
