export const SAVED_SEARCHES = 'savedSearches';
export const SEARCH_BY_BUYER = 'searchByBuyer';

export const DRAWER_MESSAGES_TYPES = {
  INIT: 'init',
  UNREAD: 'unread',
  CLIENT: 'client',
  PARTNER: 'partner',
  SERVICE: 'service',
  LISTING: 'listing',
  CHAT: 'chat',
  NEW_MESSAGE: 'newMessage',
  ARCHIVED: 'archived',
  TRANSACTION: 'transaction',
};

export const DRAWER_MESSAGES_TABS_IDS = {
  CLIENTS: 'clients',
  TRANSACTIONS: 'transactions',
  LISTINGS_AND_OFFERS: 'ListingsAndOffers',
  QUOTES: 'quotes',
  SERVICES: 'services',
};

export const DRAWERS_TYPES = {
  COMMENTS: 'comments',
};

export const DRAWER_THREAD_CONTACTS_TYPES = {
  CONTACTS_LIST: 'contactsList',
  DIRECT_CONTACT: 'directContact',
  CONTACT: 'contact',
};

export const DRAWER_QUOTES_VIEW_TYPES = {
  CATEGORY: 'category',
  CLIENT: 'client',
  PROPERTY: 'property',
};

export const AGENT_CONNECTIONS = {
  Clients: 'Clients',
  Collaborators: 'Collaborators',
  Team: 'Team',
};

export const AGENT_TYPE = {
  Individual: 'Individual',
  Team: 'Team',
};
