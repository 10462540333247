import { useState } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox';
import Space from 'antd/lib/space';
import { useDrag } from 'react-dnd';
import List from 'antd/lib/list';
import Typography from 'antd/lib/typography';

import { PDF_FIELD_TYPE } from 'app-constants';
import { Form, Input, Row, Sidebar } from 'components-antd';
import { PDFEditorFieldType } from 'types';

import styles from './styles.module.scss';

interface PDFEditorRightSidebarProps {
  allFields: PDFEditorFieldType[];
  field?: PDFEditorFieldType;
  onFieldUpdate: (...props) => void;
  onFieldAttributeUpdate: (...prosp) => void;
  onFieldDelete: (...props) => void;
  setHighlighted: (str) => void;
}

export const PDFEditorRightSidebar = ({
  field,
  onFieldUpdate,
  onFieldAttributeUpdate,
  onFieldDelete,
  allFields,
  setHighlighted,
}: PDFEditorRightSidebarProps) => {
  return (
    <Sidebar width={300} className={styles.editorRightSideBar}>
      <div className={styles.sidebarContainer}>
        {field ? (
          renderFieldSettings(
            allFields,
            field,
            setHighlighted,
            onFieldUpdate,
            onFieldAttributeUpdate,
            onFieldDelete,
          )
        ) : (
          <div className={styles.noDocumentHint}>
            <span className={styles.helperText}>Select a form field</span>
          </div>
        )}
      </div>
    </Sidebar>
  );
};

const renderFieldSettings = (
  allFields: PDFEditorFieldType[],
  field: PDFEditorFieldType,
  setHighlighted,
  onFieldUpdate,
  onFieldAttributeUpdate,
  onFieldDelete,
) => {
  const [nameError, setNameError] = useState(false);
  const [fieldError, setFieldError] = useState<any>([]);

  const attributes = field.attributes;

  const updateName = (name: string) => {
    const newName = name.split('.').join('-').trim();
    if (newName) {
      onFieldUpdate(field.id, { name: newName });
    }
  };

  const handleMultiline = (checked) => {
    onFieldAttributeUpdate(field.id, {
      ...attributes,
      isMultiLine: checked,
    });
  };

  const handleFieldDelete = () => {
    onFieldDelete(field.id);
  };

  const validateOption = (id: string, value: string) => {
    const newValue = value.split('.').join('-').trim();
    const cField = allFields.find((f) => f.id === id);

    if (newValue && cField) {
      let errors = fieldError.filter((e) => e.id !== cField.id);
      if (
        allFields
          .filter((f) => f.groupName === field.groupName && f.widgetId !== cField.widgetId)
          .find((f) => f.value === newValue)
      ) {
        errors.push({ id: cField.id });
      }

      setFieldError(errors);
    }
  };

  const validateFieldName = (name: string) => {
    const newName = name.split('.').join('-').trim();
    if (newName) {
      if (
        allFields.filter((f) => f.groupName !== field.groupName).find((f) => f.name === newName)
      ) {
        setNameError(true);
      } else {
        setNameError(false);
      }
    }
  };

  const updateRadioValue = (id, value) => {
    const newValue = value.split('.').join('-').trim();
    if (newValue) {
      onFieldUpdate(id, { value: newValue });
    }
  };

  const radioOptions = () => {
    const options = allFields.filter((f) => f.groupName === field.groupName && !f.isDeleted);
    const [{ isDragging }, ref] = useDrag({
      type: PDF_FIELD_TYPE.PDFTextField,
      item: {
        type: PDF_FIELD_TYPE.PDFRadioGroup,
        groupName: options?.[0]?.groupName,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });
    return (
      <>
        <h3>Option Values</h3>
        {options.map((el) => (
          <Form.Item
            key={el.id}
            label={
              <span>
                Option value
                {el.id === field.id && (
                  <span style={{ fontWeight: 'bolder' }}> currently selected</span>
                )}
                <DeleteFilled onClick={handleFieldDelete} />
              </span>
            }
            {...(fieldError.find((e) => e.id === el.id)
              ? { validateStatus: 'error', help: 'Options should be unique' }
              : {})}
          >
            <Input
              defaultValue={el.value}
              maxLength={50}
              onClick={() => {
                setHighlighted(el.id);
              }}
              onChange={(e) => validateOption(el.id, e.target.value)}
              onBlur={(e) => {
                if (!fieldError.find((e) => e.id === el.id)) {
                  updateRadioValue(el.id, e.target.value);
                }
              }}
            />
          </Form.Item>
        ))}
        <h4>Drag new option</h4>
        <List.Item style={{ width: '100%', opacity: isDragging ? '0.6' : '1' }} ref={ref}>
          <Typography.Text>|O|</Typography.Text> Radio options
        </List.Item>
      </>
    );
  };

  return (
    <Space direction="vertical" size="large">
      <Row>
        <div>
          {field.type}
          {field.type !== PDF_FIELD_TYPE.PDFRadioGroup && (
            <DeleteFilled onClick={handleFieldDelete} />
          )}
        </div>
      </Row>
      <Row>
        <Form.Item
          label="Name"
          {...(nameError ? { validateStatus: 'error', help: 'Field name already exists' } : {})}
        >
          <Input
            key={field.id}
            disabled={!field.isNew || !field.isNewGroup}
            defaultValue={field.name}
            maxLength={50}
            onChange={(e) => validateFieldName(e.target.value)}
            onBlur={(e) => {
              if (!nameError) {
                updateName(e.target.value);
              }
            }}
          />
        </Form.Item>

        {field.type === PDF_FIELD_TYPE.PDFTextField ? (
          <Form.Item name="Multiline">
            <Checkbox
              onChange={(e) => handleMultiline(e.target.checked)}
              checked={!!attributes.isMultiLine}
            >
              Is Multiline
            </Checkbox>
          </Form.Item>
        ) : (
          <></>
        )}

        {field.type === PDF_FIELD_TYPE.PDFRadioGroup ? radioOptions() : <></>}
      </Row>
    </Space>
  );
};
