import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { updateQuoteDraftEffect } from 'store/effects/quotes';
import { PropertyAddressInput } from 'pages/RequestQuote/components';
import EditActions from '../EditActions';

import styles from './styles.module.scss';

const EditPropertyAddress = (props) => {
  const { className, onCancel, onSave } = props;
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);

  const onSaveHandler = () => {
    setIsPending(true);
    dispatch(
      updateQuoteDraftEffect({}, { silent: true }, (err) => {
        if (!err) {
          onSave();
        }
        setIsPending(false);
      }),
    );
  };

  const onCancelHandler = (event) => {
    event.stopPropagation();
    onCancel();
  };

  return (
    <div className={classNames(styles.editPropertyAddress, className)}>
      <PropertyAddressInput
        className={styles.propertyAddressInput}
        unitClassName={styles.unit}
        wrapperClassName={styles.searchWrapper}
        valuesWrapperClassName={styles.valuesWrapper}
        searchWrapperClassName={styles.autocompleteWrapper}
        popupClassName={styles.popup}
      />
      <EditActions onSave={onSaveHandler} onCancel={onCancelHandler} isPending={isPending} />
    </div>
  );
};

EditPropertyAddress.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

EditPropertyAddress.defaultProps = {
  className: '',
};

export default EditPropertyAddress;
