import React from 'react';
import { Typography, Space } from 'antd';
import moment, { Moment } from 'moment';

import { Col, DatePicker, Row } from 'components-antd';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IDateRangePicker {
  onDatesChange: (from: Moment, to: Moment) => void;
}
const { Title } = Typography;
export const DateRangePicker: React.FC<IDateRangePicker> = ({ onDatesChange }) => {
  const [fromDate, setFromDate]: any = React.useState('');
  const [toDate, setToDate]: any = React.useState('');
  React.useMemo(() => {
    onDatesChange(fromDate, toDate);
  }, [fromDate, toDate]);

  return (
    <div className={styles.dateRangePickerWrapper}>
      <Title level={4}>{'Date Range'}</Title>
      <Row align="middle">
        <Col span={11}>
          <DatePicker
            className={classNames(styles.datepicker)}
            format={'MM/DD/YYYY'}
            disabledDate={(current) => {
              return toDate ? current.isAfter(toDate) : moment(current).isAfter(moment(new Date()));
            }}
            value={fromDate}
            onChange={(_from: any) => {
              setFromDate(_from);
              if (_from.isAfter(toDate)) {
                setToDate(_from);
              }
            }}
          />
        </Col>
        <Col span={2} className={classNames(styles.dateRangeToText)}>
          To
        </Col>
        <Col span={11}>
          <DatePicker
            className={classNames(styles.datepicker)}
            format={'MM/DD/YYYY'}
            disabledDate={(current) => {
              return moment(current).isAfter(moment(new Date()));
            }}
            value={toDate}
            onChange={(_to: any) => {
              setToDate(_to);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
