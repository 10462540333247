export const ConfettiIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
      <path
        d="M28 13C28 11.3431 29.3431 10 31 10H65C66.6569 10 68 11.3431 68 13V71.6296C68 72.9388 66.9388 74 65.6296 74H31C29.3431 74 28 72.6569 28 71V13Z"
        fill="#4673D1"
      />
      <path
        d="M10 33C10 31.3431 11.3431 30 13 30H39C40.6569 30 42 31.3431 42 33V74H13C11.3431 74 10 72.6569 10 71V33Z"
        fill="#51BFE1"
      />
      <path
        d="M28 30H39C40.6569 30 42 31.3431 42 33V74H31C29.3431 74 28 72.6569 28 71V30Z"
        fill="#34569D"
      />
    </svg>
  );
};
