import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import {
  getTransactionEffect,
  getKeyDatesEffect,
  resetTransactionEffect,
} from 'store/effects/transactions';
import { getTransactionSelector, getTransactionAccessSelector } from 'store/selectors/transaction';
import { resetShowingAggregateEffect } from 'store/effects/showingAggregate';
import { PageWrapper, AsideNavigation, Content } from 'components/Transactions';
import { Showings } from './components';

import styles from './styles.module.scss';

export const TransactionShowings = (props) => {
  const { className, match } = props;
  const dispatch = useDispatch();
  const { transaction, isError, status, isPending } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);

  const fetchTransactionById = (callback?) => {
    dispatch(getTransactionEffect({ id: match.params.id }, { silent: true }, callback));
    dispatch(getKeyDatesEffect({ transactionId: match.params.id }, { silent: true }));
  };

  useEffect((): any => {
    fetchTransactionById();

    return () => dispatch(resetShowingAggregateEffect());
  }, [match.params.id]);

  return (
    <div className={classNames(styles.transactionShowings, className)}>
      <PageWrapper notFound={isError && status === 404}>
        <AsideNavigation />
        <Content isPending={isPending}>
          {transaction && <Showings transaction={transaction} fullAccess={fullAccess} />}
        </Content>
      </PageWrapper>
    </div>
  );
};

TransactionShowings.propTypes = {
  className: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,
};

TransactionShowings.defaultProps = {
  className: '',
};
