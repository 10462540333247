import { Button, Modal } from 'components-antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import classNames from 'classnames';
import { Confetti } from 'components/Icons';

import styles from './styles.module.scss';

export const PaymentFailedModal: React.FC<{}> = () => {
  const history = useHistory();

  return (
    <Modal
      getContainer={'#paywallModalContainer'}
      open={true}
      width={675}
      footer={null}
      closable={false}
      maskClosable={false}
      mask={false}
    >
      <div className={styles.modalLayout}>
        <Confetti className={styles.paymentStatusIcon} />
        <h2 className={styles.stepHeader}>Your plan is active!</h2>
        <Button
          onClick={() => {
            history.push(routes.index);
          }}
          variant="secondary"
          className={classNames(styles.continueButton, styles.button)}
        >
          Take me to the app
        </Button>
      </div>
    </Modal>
  );
};
