import { useSelector } from 'react-redux';

import { Input } from 'components-antd';
import { SearchIcon } from 'components/Icons';
import { getConnectionTypeSelector, isAssignConnectionModalOpened } from 'store/selectors/app';
import { AGENT } from 'settings/constants/roles';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';

import styles from './styles.module.scss';

export const Search = ({ query, setQuery }) => {
  const connectionType = useSelector(getConnectionTypeSelector);
  const { connectionAssignType } = useSelector(isAssignConnectionModalOpened);
  const onQueryChange = (value) => setQuery(value);

  if (connectionType === AGENT_CONNECTIONS.Clients && connectionAssignType === AGENT) return <></>;

  return (
    <div className={styles.filterWrap}>
      <SearchIcon />
      <Input
        placeholder="Search"
        value={query}
        className={styles.filterInput}
        onChange={(e) => onQueryChange(e.target.value)}
      />
    </div>
  );
};
