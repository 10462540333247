import { apiServer } from 'settings/web-services/api';

export function getAgentTeamDetail(cfg, { teamId }) {
  return apiServer.get(`/agentTeam/currentUser`);
}

export function reassignShareClients(cfg, { type }) {
  return apiServer.post(`/agentTeam/clients/${type}`, cfg);
}

export function getClientAssignedToList(cfg) {
  return apiServer.get(`/agentteam/client/${cfg.id}/assignedTo`);
}

export function getAllAgentDocuments({ agentId, ...cfg }) {
  return apiServer.post(`/agentteam/documents/${agentId}`, cfg);
}

export function deleteAgentDocument({ agentId, ...cfg }) {
  return apiServer.delete(`/agentteam/documents/${agentId}?documentId=${cfg.id}&type=${cfg.type}`);
}
