import moment from 'moment';
import { get } from 'lodash-es';
import { createSelector } from 'reselect';

import { getDate } from 'helpers';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { AggregatedPageTypes } from 'types/aggregatedPageTypes';

const localState = ({ taskAggregate }) => taskAggregate;
const localAggregatedPageTypeState = ({ workshop }) => workshop;

export const geAggregatedPageTypeSelector = createSelector(localAggregatedPageTypeState, (state) =>
  get(state, 'aggregatePageType', AggregatedPageTypes.All),
);
export const getTaskAggregateDataSelector = createSelector(localState, ({ data }) => data);
export const getTasksSearchSelector = createSelector(localState, ({ search }) => search);

export const getTaskAggregateList = createSelector(
  localState,
  geAggregatedPageTypeSelector,
  (currentState, aggregatePageType) => {
    const taskAggregate = currentState?.data || [];
    return {
      tasks:
        aggregatePageType === AggregatedPageTypes.Project
          ? [...taskAggregate.filter((task) => !task?.Transaction?.Property?.Address)]
          : aggregatePageType === AggregatedPageTypes.Transaction
          ? [...taskAggregate.filter((task) => task?.Transaction?.Property?.Address)]
          : [...taskAggregate],
      search: currentState?.search,
      isArchive: currentState?.isArchive,
      meta: currentState?.meta,
      isIdle: currentState?.state === IDLE,
      isPending: currentState?.state === PENDING,
      isData: !!currentState?.data,
    };
  },
);

export const getTransactionsTasksIsArchiveSelector = createSelector(
  localState,
  ({ isArchive }) => isArchive,
);

const getSearchTransactionsTasks = ({ data, search }) => {
  let result = data.filter((transactionTask) => {
    if (search) {
      const formattedSearch = search.toLowerCase();
      if (transactionTask?.Title?.toLowerCase().includes(formattedSearch)) return true;
      else if (transactionTask?.Description?.toLowerCase().includes(formattedSearch)) return true;
      else if (
        transactionTask?.Transaction?.Property?.Address?.Line1?.toLowerCase().includes(
          formattedSearch,
        )
      )
        return true;
      else if (
        transactionTask?.Transaction?.Property?.Address?.City?.toLowerCase().includes(
          formattedSearch,
        )
      )
        return true;
      else if (
        transactionTask?.Transaction?.Property?.Address?.State?.toLowerCase().includes(
          formattedSearch,
        )
      )
        return true;
      else if (
        transactionTask?.Transaction?.Property?.Address?.PlaceName?.toLowerCase().includes(
          formattedSearch,
        )
      )
        return true;
      else return false;
    }

    return true;
  });

  return result;
};

export const getAggregateTaskType = createSelector(localState, ({ taskType }) => taskType);

export const getFilteredTaskAggregateSelector = createSelector(
  getTaskAggregateList,
  ({ tasks, search, isPending, isArchive }) => {
    tasks = getSearchTransactionsTasks({ data: tasks, search });

    return {
      tasks,
      isPending,
      isArchive,
    };
  },
);
