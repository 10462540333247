import { handleActions } from 'redux-actions';

import {
  openCommentsDrawerAction,
  setCommentsDrawerParamsAction,
  setCurrentCommentsThreadIdAction,
} from 'store/actions/drawers/comments';
import { cloneDeep } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  open: false,
  currentThreadId: null,
  params: {},
};

export default handleActions(
  {
    [openCommentsDrawerAction]: (state, { payload }) => ({
      ...state,
      open: payload?.open || initialData.open,
      currentThreadId: payload?.currentThreadId || initialData.currentThreadId,
      params: payload?.params || state.params,
    }),
    [setCurrentCommentsThreadIdAction]: (state, { payload }) => ({
      ...state,
      currentThreadId: payload.threadId || initialData.currentThreadId,
    }),
    [setCommentsDrawerParamsAction]: (state, { payload }) => ({
      ...state,
      params: payload?.params,
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
