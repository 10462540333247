import { useState } from 'react';
import classNames from 'classnames';
import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check, Team, Profile } from 'components/Icons';

import styles from './styles.module.scss';

export const ActiveInactive = (props) => {
  const [value, setValue] = useState(props.filterOptions.All);
  const onChange = (e) => {
    const val = e.target.value;
    setValue(val);
    props.setFilter(val);
  };
  const ActiveInactiveMenu = Object.values(props.filterOptions).map((item) => ({
    itemValue: item,
  }));

  const filterIconColor = '#515151';

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {ActiveInactiveMenu.map((item, idx) => (
          <MenuItem key={idx}>
            <Radio value={item.itemValue} key={idx}>
              <div className={styles.optionContainer}>
                {item.itemValue === 'Just Me' && (
                  <Profile className={styles.Teamicon} color={filterIconColor} />
                )}
                {item.itemValue === 'Entire Team' && <Team className={styles.Teamicon} />}
                <div className={styles.option}>
                  <span className={styles.optionValue}>{item.itemValue}</span>
                </div>
              </div>
              {value === item.itemValue && <Check className={styles.checkIcon} />}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={styles.filterWrap}>
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          {props.team ? (
            <>
              {value === 'Just Me' ? (
                <Profile className={styles.Teamicon} color="#515151" />
              ) : (
                <Team className={styles.Teamicon} />
              )}
            </>
          ) : (
            <span className={styles.selectedValue}>{value}</span>
          )}
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
        </a>
      </Dropdown>
    </div>
  );
};
