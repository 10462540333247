import { handleActions } from 'redux-actions';
import { ERROR, IDLE } from 'settings/constants/apiState';
import { get, cloneDeep } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetMlsAgentsDashboardAction,
  sortMlsAgentsDashboardAction,
  searchMlsAgentsDashboardAction,
} from 'store/actions/adminPanel';
import { sortItems } from 'store/reducers/adminPanel/helpers';

const initialData = {
  state: IDLE,
  data: null,
  filters: {
    status: '',
  },
  sort: {
    order: 'asc',
    fields: ['Name'],
  },
  meta: {},
};

export default handleActions(
  {
    [requestGetMlsAgentsDashboardAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [sortMlsAgentsDashboardAction]: sortItems,
    [searchMlsAgentsDashboardAction]: (state, { payload }) => ({
      ...state,
      filters: payload || state.filters,
    }),
  },
  initialData,
);
