import * as Yup from 'yup';
import yupPassword from 'yup-password';

yupPassword(Yup);

export const step1ValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
});

export const step2ValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .minLowercase(1, 'Password must contain at least 1 lower-cased letter')
    .minUppercase(1, 'Password must contain at least 1 upper-cased letter')
    .minNumber(1, 'Password must contain at least 1 number'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
