import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Space from 'antd/lib/space';

import {
  Button,
  Col,
  ConfirmationModal,
  InputSelectModal,
  Row,
  Sidebar,
  TextArea,
} from 'components-antd';
import { getFolderListSelector, getSmartFormMetaSelect } from 'store/selectors/formBuilder';
import {
  createNewFormEffect,
  getFolderListEffect,
  requestUpdateSmartFormEffect,
  smartFormUploadEffect,
  updateSmartFormEffect,
} from 'store/effects/formBuilder';
import { link } from 'settings/navigation/link';
import { FORM_TYPE } from 'app-constants';

import styles from './styles.module.scss';

interface SmartRightSidebarUploadProps {
  file?: File;
  setFile: (file: File | undefined) => void;
  setEditMode: (bool: boolean) => void;
}

export const SmartRightSidebarUpload = ({
  file,
  setFile,
  setEditMode,
}: SmartRightSidebarUploadProps) => {
  const dispatch = useDispatch();
  const [showResetWarning, setShowResetWarning] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { folderList } = useSelector(getFolderListSelector);
  const { questions } = useSelector(getSmartFormMetaSelect);
  const history = useHistory();

  useEffect(() => {
    if (!folderList?.folders?.length) {
      dispatch(getFolderListEffect());
    }
  }, []);

  const resetDocument = () => {
    dispatch(
      updateSmartFormEffect({
        questions: [],
      }),
    );
    setFile(undefined);
    setShowResetWarning(false);
  };
  const uploadPDF = async (setLoading: (loading: boolean) => void, values) => {
    const { entityName, selectedItem } = values;

    if (file && entityName?.trim && entityName.trim()?.length) {
      const formData = new FormData();
      formData.append('files', file);
      dispatch(
        smartFormUploadEffect(formData, (err, resp) => {
          if (resp.data.success && resp.data.result?.DocumentVaultUUID) {
            const result = resp.data.result?.DocumentVaultUUID;

            dispatch(
              createNewFormEffect(
                {
                  folderId: selectedItem,
                  type: FORM_TYPE.Smart,
                  formName: entityName.trim(),
                  link: result,
                },
                (err, response) => {
                  const formVersion = response?.data?.value?.formVersionId;
                  if (formVersion) {
                    const data = {
                      questions: questions,
                      Id: formVersion,
                    };
                    dispatch(
                      requestUpdateSmartFormEffect(data, (err) => {
                        if (!err)
                          history.push(link.toDashboardServiceDirectoryFormsSmartForm(formVersion));
                      }),
                    );
                  }
                },
              ),
            );
          }
          setLoading(false);
        }),
      );
    }
  };
  return (
    <Sidebar width={300} className={styles.quoteRightSidebar}>
      <ConfirmationModal
        variant="Warning"
        open={showResetWarning}
        confirmText={
          <span>
            Are you sure you want to remove this document?
            <br />
          </span>
        }
        onOk={resetDocument}
        onCancel={() => setShowResetWarning(false)}
      />
      <InputSelectModal
        open={showUploadModal}
        title={`Create New Smart Form`}
        okText={'Create'}
        selectHeading="Folder"
        selectPlaceholder="Choose Folder"
        selectOptions={(folderList?.folders || [])?.map((folder) => ({
          label: folder.Name,
          value: folder.Id,
        }))}
        submit={uploadPDF}
        onCancel={() => setShowUploadModal(false)}
        entityNameRequired={true}
        selectedItemRequired={true}
        entityName={''}
        entityNameMaxLength={255}
        destroyOnClose={true}
      />
      <div className={styles.sidebarContainer} data-testid="quote-right-sidebar">
        <h3>Document Settings</h3>
        {file?.name ? (
          <>
            <Space direction="vertical" size="large">
              <Row>
                <Col className={styles.expandedRowBtn}>
                  <Button
                    className={styles.expandedRowBtn}
                    variant="primary"
                    onClick={() => setEditMode(true)}
                  >
                    Edit
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className={styles.expandedRowBtn}>
                  <Button
                    className={styles.expandedRowBtn}
                    variant="secondary-inverted"
                    onClick={() => setShowResetWarning(true)}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <h4 className={styles.label}>File Name:</h4>
                  <TextArea
                    className={styles.fileName}
                    bordered={false}
                    autoSize
                    value={file?.name}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col className={styles.expandedRowBtn}>
                  {questions?.length ? (
                    <Button
                      className={styles.expandedRowBtn}
                      variant="secondary"
                      onClick={() => setShowUploadModal(true)}
                    >
                      Confirm
                    </Button>
                  ) : (
                    <label className={styles.invalidFormFields}>
                      This document does not contain valid PDF Form Fields
                    </label>
                  )}
                </Col>
              </Row>
            </Space>
          </>
        ) : (
          <div className={styles.noDocumentHint}>
            <span className={styles.helperText}>
              You have no documents uploaded. Click “Upload Document” or Drag and drop it to create
              a new Smart Form.
            </span>
          </div>
        )}
      </div>
    </Sidebar>
  );
};
