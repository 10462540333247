import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { lowerCase } from 'lodash-es';
import { Popover } from 'antd';

import Icon from 'pages/Properties/Feed/Icons';
import { getSearchResultsSortSelector } from 'store/selectors/searchResults';
import { sortSearchResultsEffect } from 'store/effects';
import { useOutsideClick } from 'hooks';
import { OptionsPopup } from 'components';
import Title from './Title';
import AscDesc from './AscDesc';
import Fields from './Fields';

import styles from './styles.module.scss';
import { getSearchResultsV2Effect } from 'store/effects/searchResults/searchV2';

const fieldsPriority = [
  'MatchScore',
  'ClientsMatched',
  'SellingPrice',
  'DaysOnMarket',
  'NumBeds',
  'NumBathsTotal',
  'SquareFeet',
].reduce((acc, field, index) => ({ ...acc, [field]: index }), {});

const Sort = ({ className, sortCases, disabled }) => {
  const dispatch = useDispatch();
  const sort = useSelector(getSearchResultsSortSelector);

  const [open, setOpen] = useState(false);

  const sortButtonRef = useRef();
  const popupRef = useRef();

  useOutsideClick([popupRef, sortButtonRef], () => setOpen(false));

  const onClickHandler = () => {
    setOpen(!open);
  };

  const onChangeSort = (orderValue) => {
    dispatch(sortSearchResultsEffect({ order: orderValue, fields: sort?.fields }));

    dispatch(getSearchResultsV2Effect());
  };

  const onChangeFields = (fieldNames) => {
    const sortedFields = fieldNames.sort((o1, o2) => fieldsPriority[o1] - fieldsPriority[o2]);
    dispatch(sortSearchResultsEffect({ order: sort?.order, fields: sortedFields }));

    dispatch(getSearchResultsV2Effect());
  };

  const popoverContent = () => <span>Sort By</span>;

  return (
    <div className={classNames(className, styles.wrapper, { [styles.disabled]: disabled })}>
      <Popover
        content={popoverContent}
        placement="top"
        overlayClassName={classNames('mosaikTooltip')}
        getPopupContainer={(triggerNode) => triggerNode}
        arrow={true}
      >
        <div
          ref={sortButtonRef}
          onClick={onClickHandler}
          className={classNames(styles.sort, { [styles.disabled]: disabled })}
        >
          <Icon
            testid="sort_icon"
            className={classNames(styles.icon, styles[lowerCase(sort?.order)])}
            variant={Icon.SORT}
          />
        </div>
      </Popover>
      <OptionsPopup
        className={classNames(styles.popup, styles.sortingPopup)}
        ref={popupRef}
        offsetTop={5}
        parentRef={sortButtonRef}
        open={open}
      >
        <div className={styles.popupInner}>
          <Title>Sort by</Title>
          <AscDesc onChange={onChangeSort} activeOrder={sort?.order} />
        </div>
        <Fields onChange={onChangeFields} list={sortCases} activeFields={sort?.fields} />
      </OptionsPopup>
    </div>
  );
};

Sort.propTypes = {
  className: PropTypes.string,
  sortCases: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
};

Sort.defaultProps = {
  className: '',
  sortCases: [
    { label: 'Match Score', value: 'MatchScore' },
    { label: 'Price', value: 'SellingPrice' },
    { label: 'Days on Market', value: 'DaysOnMarket' },
    { label: 'Beds', value: 'NumBeds' },
    { label: 'Baths', value: 'NumBathsTotal' },
    { label: 'Square Feet', value: 'SquareFeet' },
  ],
  disabled: false,
};

export default Sort;
