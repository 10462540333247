import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';

const LoginLink = ({ className }) => (
  <div className={classNames(styles.loginText, className)}>
    <span>Already have an account?</span>
    <Link testid="login" className={styles.loginLink} to={routes.login}>
      Login
    </Link>
  </div>
);

LoginLink.propTypes = {
  className: PropTypes.string,
};

LoginLink.defaultProps = {
  className: '',
};

export default LoginLink;
