import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Icons = ({ testid, className, variant, onClick }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.FILE: {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 7V12.4C23 12.8243 23.1686 13.2313 23.4686 13.5314C23.7687 13.8314 24.1757 14 24.6 14H30"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M27.8571 34H12.1429C11.3093 34 10.5099 33.6722 9.92052 33.0888C9.33112 32.5053 9 31.714 9 30.8889V9.11111C9 8.28599 9.33112 7.49467 9.92052 6.91122C10.5099 6.32778 11.3093 6 12.1429 6H22.5858C22.851 6 23.1054 6.10536 23.2929 6.29289L30.7071 13.7071C30.8946 13.8946 31 14.149 31 14.4142V30.8889C31 31.714 30.6689 32.5053 30.0795 33.0888C29.4901 33.6722 28.6907 34 27.8571 34Z"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icon, className)}>
      {renderIcon()}
    </div>
  );
};

Icons.FILE = 'file';
Icons.CLOSE = 'close';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};

export default Icons;
