import classNames from 'classnames';
import styles from './styles.module.scss';

function LocationCategoryIcons(props) {
  if (props.category === 'Coffee Shop') {
    return (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect opacity="0.12" width="64" height="64" rx="8" fill="#51BFE1" />
        <path
          d="M42 30.75L44.875 30.75C47.0841 30.75 48.875 32.4289 48.875 34.5C48.875 36.5711 47.0841 38.25 44.875 38.25H42"
          stroke="#51BFE1"
          strokeWidth="2.5"
        />
        <path
          d="M31.375 22C31.375 22 32.625 20.9062 32.625 19.2656C32.625 17.625 30.75 16.8847 30.75 15.4375C30.75 13.9903 31.375 13.25 31.375 13.25"
          stroke="#51BFE1"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5 35.125V27H42V35.125C42 40.3027 37.8027 44.5 32.625 44.5H28.875C23.6973 44.5 19.5 40.3027 19.5 35.125Z"
          stroke="#51BFE1"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.category === 'Night Life') {
    return (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect opacity="0.12" width="64" height="64" rx="8" fill="#928CDA" />
        <path
          d="M27.625 48.25H36.375"
          stroke="#928CDA"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 24.5H42L32 38.25L22 24.5Z"
          stroke="#928CDA"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M32 37V48.25" stroke="#928CDA" strokeWidth="2.5" />
        <path
          d="M36.375 24.5L40.173 18.25H45.2499"
          stroke="#928CDA"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.3247 23.6048C28.2571 22.3793 27.7936 21.209 27.0037 20.2697C26.2139 19.3303 25.1404 18.6728 23.9447 18.396C22.7491 18.1192 21.4959 18.2381 20.3736 18.7347C19.2513 19.2313 18.3205 20.0789 17.7213 21.1499C17.1221 22.221 16.8868 23.4576 17.0508 24.6739C17.2148 25.8901 17.7693 27.0203 18.6308 27.8944C19.4923 28.7685 20.6143 29.3392 21.828 29.5209C22.9101 29.6828 24.013 29.5276 25.0045 29.0785"
          stroke="#928CDA"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.category === 'Fitness') {
    return (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect opacity="0.12" width="64" height="64" rx="8" fill="#51BFE1" />
        <circle cx="35.4268" cy="19.0743" r="3.32427" stroke="#51BFE1" strokeWidth="2.5" />
        <path
          d="M32.2139 34.1381L34.1058 29.7746L36.6036 32.15C37.0432 32.5681 37.671 32.7229 38.2545 32.557L42.2526 31.4207C43.089 31.183 43.6035 30.3439 43.4362 29.4907C43.2579 28.5817 42.3746 27.991 41.4665 28.1732L38.0051 28.7042L35.3346 25.0368C35.0138 24.6314 34.589 24.3206 34.1056 24.1375L33.147 23.7744C32.8439 23.6596 32.5235 23.5972 32.1995 23.5898L27.2185 23.8122C26.5769 23.7976 25.9588 24.0532 25.5149 24.5166L21.1362 29.0883C20.5122 29.7397 20.5403 30.7754 21.1987 31.392C21.8519 32.0038 22.8775 31.9703 23.4893 31.317L27.2185 28.1732L29.4682 27.9043L26.3881 33.9031L24.5753 38.8425C24.4327 39.205 24.1154 39.47 23.7333 39.5457L19.637 40.9774C18.7298 41.1571 18.1267 42.0216 18.2714 42.9351C18.4233 43.8941 19.338 44.5377 20.292 44.3569L25.92 42.6775C26.5211 42.5635 27.0515 42.2134 27.3926 41.7054L29.8823 37.6911L33.6945 39.9697L32.4373 46.0022C32.1708 47.2809 33.2665 48.4309 34.5566 48.2265C35.3272 48.1045 35.9405 47.516 36.0944 46.7512L37.613 39.204C37.8099 38.2255 37.3494 37.2323 36.4753 36.7503L32.2139 34.1381Z"
          stroke="#51BFE1"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.category === 'Beauty & Spas') {
    return (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect opacity="0.12" width="64" height="64" rx="8" fill="#928CDA" />
        <path
          d="M20.5351 29.5401C18.4705 29.3986 16.6516 29.66 15.564 29.8801C14.9494 30.0044 14.5824 30.5943 14.7491 31.1988C15.35 33.3783 17.2011 38.3506 22 40.75C25.6894 42.8541 29.5861 43.25 32 43.25"
          stroke="#928CDA"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
        <path
          d="M43.4649 29.5401C45.5295 29.3986 47.3484 29.66 48.436 29.8801C49.0506 30.0044 49.4176 30.5943 49.2509 31.1988C48.65 33.3783 46.7989 38.3506 42 40.75C38.3106 42.8541 34.4139 43.25 32 43.25"
          stroke="#928CDA"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
        <path
          d="M28.22 26.8865C26.0695 25.2748 23.8547 24.2433 22.5251 23.7052C21.861 23.4365 21.1518 23.8348 21.04 24.5424C20.6637 26.9239 20.2352 32.137 22.7007 36.2451C25.518 40.9394 29.48 43.25 32 43.25"
          stroke="#928CDA"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
        <path
          d="M35.78 26.8865C37.9305 25.2748 40.1453 24.2433 41.4749 23.7052C42.139 23.4365 42.8482 23.8348 42.96 24.5424C43.3363 26.9239 43.7648 32.137 41.2993 36.2451C38.482 40.9394 34.52 43.25 32 43.25"
          stroke="#928CDA"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
        <path
          d="M37 32.625C37 38.875 34.5 43.25 32 43.25C29.5 43.25 27 38.875 27 32.625C27 27.8696 29.6288 23.1098 31.0741 20.8522C31.5194 20.1568 32.4806 20.1568 32.9259 20.8522C34.3712 23.1098 37 27.8696 37 32.625Z"
          stroke="#928CDA"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.category === 'Restaurants') {
    return (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect opacity="0.12" width="64" height="64" rx="8" fill="#FB913A" />
        <path
          d="M20.125 47H27.625"
          stroke="#FB913A"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.25 15.75H29.5001C30.9852 22.15 35.7583 34.5 23.875 34.5C11.9918 34.5 16.7642 22.15 18.25 15.75Z"
          stroke="#FB913A"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M23.875 34.5V47" stroke="#FB913A" strokeWidth="2.5" />
        <path
          d="M15.75 26.6875C15.75 26.6875 18.0714 25.75 20.3929 25.75C23.2946 25.75 24.939 27 27.3571 27C29.2917 27 32 26.0625 32 26.0625"
          stroke="#FB913A"
          strokeWidth="2.5"
        />
        <path
          d="M40.75 15.75V22.625"
          stroke="#FB913A"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.875 15.75V22.625"
          stroke="#FB913A"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.625 15.75C37.625 15.75 37.625 23.5827 37.625 25.6175C37.625 27.6523 40.75 29.0088 40.75 30.3653C40.75 31.4078 40.3809 41.0407 40.21 45.4454C40.1637 46.6385 41.1185 47.625 42.3125 47.625V47.625C43.5065 47.625 44.4613 46.6385 44.415 45.4454C44.2441 41.0407 43.875 31.4078 43.875 30.3653C43.875 29.0088 47 27.6523 47 25.6175C47 23.5827 47 15.75 47 15.75"
          stroke="#FB913A"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.category === 'Bars') {
    return (
      <div
        style={{
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '64px',
          width: '64px',
          background: 'rgba(70, 115, 209, 0.2)',
        }}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 20C7.5 16.5482 10.2982 13.75 13.75 13.75V13.75C17.2018 13.75 20 16.5482 20 20V35C20 36.3807 18.8807 37.5 17.5 37.5H10C8.61929 37.5 7.5 36.3807 7.5 35V20Z"
            stroke="#4673D1"
            strokeWidth="2.5"
          />
          <path
            d="M25 26.25C25 28.3211 26.6789 30 28.75 30V30C30.8211 30 32.5 28.3211 32.5 26.25V18.75C32.5 18.0596 31.9404 17.5 31.25 17.5H26.25C25.5596 17.5 25 18.0596 25 18.75V26.25Z"
            stroke="#4673D1"
            strokeWidth="2.5"
          />
          <path
            d="M25 23.7524C25 23.7524 26.875 22.5032 28.75 23.1282C30.1787 23.6044 32.5 23.1269 32.5 23.1269"
            stroke="#4673D1"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.25 14.375V3.75C16.25 3.05964 15.6904 2.5 15 2.5H12.5C11.8096 2.5 11.25 3.05964 11.25 3.75V14.375"
            stroke="#4673D1"
            strokeWidth="2.5"
          />
          <path
            d="M28.75 30V37.5"
            stroke="#4673D1"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.25 37.5H31.25"
            stroke="#4673D1"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 21.25H13.75V31.25H20"
            stroke="#4673D1"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.875 6.25H16.25"
            stroke="#4673D1"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  } else if (props.category === 'Groceries') {
    return (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect opacity="0.12" width="64" height="64" rx="8" fill="#FB913A" />
        <path
          d="M14.5 19.5H19.6296C20.2903 19.5 20.8684 19.9442 21.0387 20.5826L25.4613 37.1674C25.6316 37.8058 26.2097 38.25 26.8704 38.25L40.915 38.25C41.559 38.25 42.1267 37.8276 42.3118 37.2107L45.1868 27.6274C45.4675 26.6917 44.7668 25.75 43.79 25.75L23.25 25.75"
          stroke="#FB913A"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="27" cy="44.5" r="2.5" stroke="#FB913A" strokeWidth="2.5" />
        <circle cx="40.75" cy="44.5" r="2.5" stroke="#FB913A" strokeWidth="2.5" />
      </svg>
    );
  } else if (props.category === 'Parks & Nature') {
    return (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect opacity="0.12" width="64" height="64" rx="8" fill="#04A451" />
        <path
          d="M37.625 41.3743C42.7386 42.2266 47 37.5626 47 33.2493C47 31.0986 46.1448 28.9588 44.7613 27.5464C44.8327 27.1631 44.87 26.7674 44.87 26.363C44.87 22.8712 42.0855 20.0406 38.6505 20.0406C37.9805 20.0406 37.3352 20.1483 36.7305 20.3477C33.25 15.1243 23.875 16.9993 22.625 23.2493C19.5 24.4993 17 28.1399 17 31.9993C17 37.3396 21.7466 41.9993 27 41.9993"
          stroke="#04A451"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32 35.125L27.625 31.375M32 35.125L35.125 32.625M32 35.125V49.5"
          stroke="#04A451"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (props.category === 'Health & Medical') {
    return (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect opacity="0.12" width="64" height="64" rx="8" fill="#FF576D" />
        <path
          d="M30.9695 22.8524L32 24.3533L33.0305 22.8524C34.4297 20.8144 36.7274 19.5 39.3125 19.5C43.5285 19.5 47 23.0144 47 27.4167C47 29.6597 46.0962 31.6777 44.6484 33.1155L44.6354 33.1284L44.6228 33.1417L33.8124 44.5261C32.8268 45.564 31.1727 45.5641 30.1869 44.5264L19.1491 32.9068C17.8201 31.4854 17 29.5533 17 27.4167C17 23.0144 20.4715 19.5 24.6875 19.5C27.2727 19.5 29.5703 20.8144 30.9695 22.8524Z"
          stroke="#FF576D"
          strokeWidth="2.5"
        />
        <path
          d="M14.5 33.25H25.75L28.25 27.625L32 36.375L35.125 30.125L37 33.25H49.5"
          stroke="#FF576D"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect opacity="0.12" width="64" height="64" rx="8" fill="#AAABAB" />
        <ellipse cx="32" cy="30.3334" rx="5" ry="5" stroke="#515151" strokeWidth="2.5" />
        <path
          d="M45.3334 30.3333C45.3334 38.5676 36.2929 46.2369 33.1003 48.6885C32.4434 49.193 31.5566 49.193 30.8997 48.6885C27.7072 46.2369 18.6667 38.5676 18.6667 30.3333C18.6667 22.9695 24.6362 17 32 17C39.3638 17 45.3334 22.9695 45.3334 30.3333Z"
          stroke="#515151"
          strokeWidth="2.5"
        />
      </svg>
    );
  }
}

export default LocationCategoryIcons;
