/* eslint-disable no-undef */

import { FORM_STATUS_TYPE } from 'app-constants';
import { get } from 'lodash-es';
import {
  requestAddQuestionRoleAction,
  requestSmartFormAction,
  requestUpdateSmartFormAction,
  resetSmartFormAction,
  updateSmartFormAction,
  updateSmartFormMetaAction,
  updateSmartFormPreviewResponseAction,
  updateSmartFormQuestionAction,
} from 'store/actions/formBuilder';
import { FormBuilderState, QuestionType, SmartBuilderType } from 'types';
import { formBuilderInitialData } from '..';

export const smartBuilderReducer = {
  [requestSmartFormAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => {
    const form = get(payload, 'data.value.form');

    const smartBuilder = form
      ? ({
          Id: form.Id,
          state: payload.state,
          formName: form.Name,
          folderName: get(form, 'FormFolder.Name', ''),
          disabled: form.Status !== FORM_STATUS_TYPE.Draft,
          questions: get(form, 'FormQuestion', []),
          highlightedFields: get(form, 'FormQuestion.0.Fields', []).map((f) => f.id),
          answers: get(form, 'FormQuestion', []).reduce(
            (acc, answer) => ({
              ...acc,
              [answer.UUID]: {
                UUID: answer.UUID,
                answer: answer.Title,
                Answer: '',
              },
            }),
            {},
          ),
          status: form.Status,
          tags: get(form, 'FormTag', []).map((el) => el.TagId),
          formCategoryTags: get(form, 'FormCategoryTag', []).map((el) => el.TagId),
          isFavorite: form.isFavorite,
          formId: form.FormId,
          activeQuestionIndex: get(form, 'FormQuestion.0') ? 0 : undefined,
          locations: get(form, 'FormLocationPermission', []).map((el) => ({
            UUID: el.UUID,
            miles: el.Radius,
            areasOfOperation: el.AreasOfOperation,
          })),
          link: form.Link,
          disassociatedFields: form.DisassociatedFields || [],
          users: get(form, 'FormUserPermission', []),
          teams: get(form, 'FormTeamPermission', []),
          questionRoles: get(form, 'FormRoles', []),
          views: get(form, 'View', []),
          isCAR: get(form, 'Form.IsCAR', false),
        } as SmartBuilderType)
      : formBuilderInitialData.smartBuilder;

    return {
      ...state,
      data: {
        ...state.data,
        smartBuilder: {
          ...state.data.smartBuilder,
          ...smartBuilder,
          lastUpdated: undefined,
          lastSaved: undefined,
          activeQuestionIndex: smartBuilder.questions?.length ? 0 : undefined,
        },
      },
    };
  },
  [resetSmartFormAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      smartBuilder: formBuilderInitialData.smartBuilder,
    },
  }),
  [updateSmartFormAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      smartBuilder: {
        ...state.data.smartBuilder,
        ...payload,
      },
    },
  }),
  [updateSmartFormMetaAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      smartBuilder: {
        ...state.data.smartBuilder,
        ...payload,
        lastUpdated: !payload.lastUpdate
          ? payload?.activeQuestionIndex !== undefined
            ? state.data.smartBuilder.lastUpdated
            : new Date()
          : payload.lastUpdate,
      },
    },
  }),

  [updateSmartFormQuestionAction as any]: (
    state: FormBuilderState,
    { payload },
  ): FormBuilderState => {
    const updatedQuestions = [...state.data.smartBuilder.questions];
    const activeIndex = state.data.smartBuilder.activeQuestionIndex;
    if (activeIndex !== undefined) {
      updatedQuestions[activeIndex] = payload;
    }

    return {
      ...state,
      data: {
        ...state.data,
        smartBuilder: {
          ...state.data.smartBuilder,
          questions: updatedQuestions,
          lastUpdated: new Date(),
        },
      },
    };
  },
  [updateSmartFormPreviewResponseAction as any]: (
    state: FormBuilderState,
    { payload },
  ): FormBuilderState => {
    const answers = state.data.smartBuilder.answers || {};
    const updatedResponse = (answers || {})[payload?.UUID];

    if (updatedResponse) {
      answers[payload?.UUID] = {
        ...answers[payload?.UUID],
        DefaultAnswer: payload?.DefaultAnswer,
        Answer: payload?.Answer,
      };
    }

    return {
      ...state,
      data: {
        ...state.data,
        smartBuilder: {
          ...state.data.smartBuilder,
          answers,
        },
      },
    };
  },
  [requestUpdateSmartFormAction as any]: (
    state: FormBuilderState,
    { payload },
  ): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      smartBuilder: {
        ...state.data.smartBuilder,
        lastSaved: new Date(),
      },
    },
  }),
  [requestAddQuestionRoleAction as any]: (
    state: FormBuilderState,
    { payload },
  ): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      smartBuilder: {
        ...state.data.smartBuilder,
        questionRoles: [
          ...(state.data.smartBuilder.questionRoles || []),
          {
            Name: get(payload, 'data.value.Name'),
            Id: get(payload, 'data.value.Id'),
            RoleId: get(payload, 'data.value.RoleId'),
            UserType: get(payload, 'data.value.UserType'),
          },
        ].filter((el) => el?.Name),
      },
    },
  }),
};
