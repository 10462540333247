import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import prettyBytes from 'pretty-bytes';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import styles from './styles.module.scss';

const Attachment = (props) => {
  const { className, documentName, filename, Filename, size, Size, onRemove, onClick } = props;
  const documentNameRef = useRef(null);
  const filenameRef = useRef(null);
  const [isDocumentNameOverflowing, setIsDocumentNameOverflowing] = useState(false);
  const [isFilenameOverflowing, setIsFilenameOverflowing] = useState(false);

  const checkOverflow = (ref, setOverflow) => {
    if (ref.current) {
      setOverflow(ref.current.scrollWidth > ref.current.clientWidth);
    }
  };

  useEffect(() => {
    checkOverflow(documentNameRef, setIsDocumentNameOverflowing);
    checkOverflow(filenameRef, setIsFilenameOverflowing);
  }, [documentName, filename, Filename, size, Size]);

  return (
    <div testid="attachment" onClick={onClick} className={classNames(styles.attachment, className)}>
      <div className={styles.info}>
        <Icon className={styles.icon} variant={Icon.FILE_SIMPLE} />
        <div className={styles.names}>
          <p
            ref={documentNameRef}
            testid="document_name"
            className={styles.documentName}
            title={isDocumentNameOverflowing ? documentName : ''}
          >
            {documentName}
          </p>
          <p
            ref={filenameRef}
            testid="file_name_size"
            className={styles.filename}
            title={
              isFilenameOverflowing ? `${filename ?? Filename} - ${prettyBytes(Size ?? size)}` : ''
            }
          >
            {`${filename ?? Filename} - ${prettyBytes(Size ?? size)}`}
          </p>
        </div>
      </div>
      <Icon
        testid="delete_icon"
        onClick={onRemove}
        className={styles.removeIcon}
        variant={Icon.DELETE}
      />
    </div>
  );
};

Attachment.propTypes = {
  className: PropTypes.string,
  documentName: PropTypes.string,
  filename: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};

Attachment.defaultProps = {
  className: '',
  documentName: '',
  filename: '',
  size: 0,
  onClick: () => {},
  onRemove: () => {},
};

export default Attachment;
