import { getUsersParticipantsList, getUserParticipantsAndContacts } from 'api/clientsList';

import Api from 'store/effects/core/api';
import { socketsStoreMessagesSetThreadIdAction } from 'store/actions/sockets/threads';
import {
  openAddParticipantsDrawerAction,
  requestGetUserParticipantsAndContactsListAction,
  requestGetUserParticipantsListAction,
} from 'store/actions/drawers/addParticipants';

export const openAddParticipantsDrawerEffect = (cfg) => (dispatch) => {
  if (cfg?.threadId) {
    dispatch(socketsStoreMessagesSetThreadIdAction(cfg));
  }

  dispatch(openAddParticipantsDrawerAction(cfg));
};

export const requestGetUserParticipantsListEffect = (cfg, options = {}, cb) => {
  const requestParams = {
    action: requestGetUserParticipantsListAction,
    method: getUsersParticipantsList,
  };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetUserParticipantAndContactsListEffect = (cfg, options = {}, cb) => {
  const requestParams = {
    action: requestGetUserParticipantsAndContactsListAction,
    method: getUserParticipantsAndContacts,
  };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};
