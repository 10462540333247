export interface feedbackForm {
  Question1?: string;
  Question2?: string;
  Question3?: string;
  Question4?: string;
  Question5?: string;
  Question6?: string;
}
export enum feedbackQuestion {
  'Question1' = 'What was your overall impression of the home?',
  'Question2' = 'Do you feel the home is priced well?',
  'Question3' = 'What did you like most about the home?',
  'Question4' = 'What did you like least about the home?',
  'Question5' = 'Is your buyer interested in submitting an offer?',
  'Question6' = 'Any other comments or feedback?',
}
