import moment from 'moment';
import PropTypes from 'prop-types';
import { Dropdown, Menu } from 'antd';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';
import { previewDocumentEffect } from 'store/effects/transactions';

import { Icons } from '../../Icons';

import styles from './styles.module.scss';

export const Document = ({ document, onDelete, MenuItem }) => {
  const dispatch = useDispatch();
  const onDownload = () => {
    dispatch(
      previewDocumentEffect(
        { DocumentVaultUUID: document.DocumentVaultUUID },
        {},
        (err, response) => {
          if (!err) {
            fileDownload(response.data, response.headers['file-name']);
          }
        },
      ),
    );
  };

  const menu = (
    <Menu className={styles.preApprovalMenu}>
      <Menu.Item className={styles.action} onClick={onDownload}>
        Download
      </Menu.Item>
      <Menu.Item className={styles.action} onClick={() => onDelete(document.Id)}>
        Remove
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.document}>
      <div>
        <Icons className={styles.docIcon} variant="document" />
        <div className={styles.info}>
          <h4>{document.Filename}</h4>
          <p>
            Uploaded on: {moment(document.UploadDate).format('MM/DD/YYYY')} at{' '}
            {moment(document.UploadDate).format('hh:mm A')}
          </p>
        </div>
      </div>
      {MenuItem ? (
        MenuItem
      ) : (
        <Dropdown overlay={menu} className={styles.dropdown} placement={'bottomRight'}>
          <a onClick={(e) => e.preventDefault()}>
            <Icons className={styles.moreIcon} variant="more" />
          </a>
        </Dropdown>
      )}
    </div>
  );
};

Document.propTypes = {
  document: PropTypes.objectOf(
    PropTypes.shape({
      Id: PropTypes.string,
      ContentType: PropTypes.string,
      Filename: PropTypes.string,
      Size: PropTypes.number,
      UploadDate: PropTypes.string,
      Url: PropTypes.string,
      DocumentVaultUUID: PropTypes.string,
    }),
  ),
  onDelete: PropTypes.func,
  MenuItem: PropTypes.any,
};

Document.defaultProps = {
  document: {},
  onDelete: () => {},
};
