import { handleActions } from 'redux-actions';
import { cloneDeep } from 'lodash-es';

import { openTransactionDocumentDrawerAction } from 'store/actions/drawers/transactionDocument';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  open: false,
  params: {},
};

export default handleActions(
  {
    [openTransactionDocumentDrawerAction]: (state, { payload }) => ({
      ...state,
      open: payload?.open,
      params: payload?.params || {},
      participantInfo: payload?.open ? state.participantInfo : initialData.participantInfo,
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
