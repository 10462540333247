import PropTypes from 'prop-types';
import classNames from 'classnames';
import prettyBytes from 'pretty-bytes';
import { useState, useEffect } from 'react';
import { truncateFilename } from 'helpers';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { Input } from 'components';
import styles from './styles.module.scss';

const Item = (props) => {
  const [defaultName, setDefaultName] = useState(true);
  const { className, documentName, filename, size, onChange, onDelete, docCategoryName } = props;

  let defaultFileName = docCategoryName;
  if (docCategoryName.includes('/')) defaultFileName = docCategoryName.split('/')[1];

  useEffect(() => {
    onChange(null, defaultFileName || documentName);
  }, []);

  useEffect(() => {
    if (documentName) setDefaultName(false);
  }, [documentName]);

  return (
    <div testid="upload_item" className={classNames(styles.item, className)}>
      <Icon className={styles.icon} variant={Icon.FILE_SIMPLE} />
      <Input
        variant={Input.LIGHT_ROUNDED}
        placeholder="Document name"
        onChange={onChange}
        value={defaultName ? (documentName ? documentName : defaultFileName) : documentName}
        className={styles.input}
        testid="document_name"
      />
      <div className={styles.filename}>
        <span testid="file_name">{truncateFilename(filename, 15)}</span>
        <span testid="size" className={styles.fileSize}>
          {prettyBytes(size)}
        </span>
      </div>
      <Icon
        testid="delete_icon"
        onClick={onDelete}
        className={styles.iconDelete}
        variant={Icon.DELETE}
      />
    </div>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  documentName: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  filename: PropTypes.string,
  size: PropTypes.number,
  docCategoryName: PropTypes.string,
};

Item.defaultProps = {
  className: '',
  documentName: '',
  onChange: () => {},
  onDelete: () => {},
  filename: '',
  size: 0,
  docCategoryName: '',
};

export default Item;
