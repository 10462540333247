import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Footer } from 'components-antd';

import styles from './styles.module.scss';

const PageWrapper = (props) => {
  const { children, subHeader, contentHeader, className, containerClassName } = props;
  const { contentContainerClassName } = props;

  return (
    <div className={classNames(styles.wrapper, className)}>
      {subHeader}
      {contentHeader}
      <div className={classNames(styles.container, containerClassName)}>
        <div className={classNames(styles.contentContainer, contentContainerClassName)}>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  subHeader: PropTypes.node,
  contentHeader: PropTypes.node,
  contentContainerClassName: PropTypes.string,
};

PageWrapper.defaultProps = {
  containerClassName: '',
  contentContainerClassName: '',
  className: '',
  subHeader: null,
  contentHeader: null,
};

export default PageWrapper;
