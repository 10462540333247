import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Action = (props) => {
  const { className, selected, onClick } = props;

  return (
    <div
      testid="action"
      className={classNames(styles.action, { [styles.selected]: selected }, className)}
      onClick={onClick}
    >
      <span>{selected ? 'Added' : 'Add'}</span>
    </div>
  );
};

Action.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

Action.defaultProps = {
  className: '',
  selected: false,
  onClick: () => {},
};

export default Action;
