import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Button, Modal, Switch, TagSelector, TextArea, Tooltip } from 'components-antd';
import Favorites from 'pages/FormBuilder/Icons/Favorites';
import Folder from 'pages/FormBuilder/Icons/Folder';
import Tag from 'pages/FormBuilder/Icons/Tag';
import {
  getFormMetaSelect,
  getQuoteFormMetaSelect,
  getSmartFormMetaSelect,
} from 'store/selectors/formBuilder';
import {
  getFormMetaEffect,
  getUserFormMetaEffect,
  requestAddTag,
  updateQuoteFormMetaEffect,
  updateSmartFormMetaEffect,
} from 'store/effects/formBuilder';
import { FormView } from 'app-constants';

import styles from './styles.module.scss';

interface FormMetaProps {
  previewForm: boolean;
  smartForm?: boolean;
}

export const FormMeta = ({ previewForm, smartForm = false }: FormMetaProps) => {
  const {
    folderName,
    formName: name,
    isFavorite,
    tags: selectedTags,
    formId,
  } = smartForm ? useSelector(getSmartFormMetaSelect) : useSelector(getQuoteFormMetaSelect);

  const { tags: allTags, users: allUsers } = useSelector(getFormMetaSelect);

  const [tagModal, setTagModal] = useState(false);

  const [formName, setFormName] = useState(name);

  const dispatch = useDispatch();

  const { wizardViewEnabled = false, isCAR = false } = useSelector(getSmartFormMetaSelect);

  useEffect(() => {
    setFormName(name);
  }, [name]);

  useEffect(() => {
    if (!allTags?.length) dispatch(getFormMetaEffect());
    if (!allUsers?.length) dispatch(getUserFormMetaEffect());
  }, []);

  const updateFormName = () => {
    const updatedFormName = formName?.trim();
    if (updatedFormName && formId && updatedFormName !== name) {
      const update = {
        formName: updatedFormName,
      };
      if (smartForm) {
        dispatch(updateSmartFormMetaEffect(update));
      } else {
        dispatch(updateQuoteFormMetaEffect(update));
      }
    } else {
      setFormName(name);
    }
  };

  const updateFavorite = () => {
    if (smartForm) {
      dispatch(updateSmartFormMetaEffect({ isFavorite: !isFavorite }));
    } else {
      dispatch(updateQuoteFormMetaEffect({ isFavorite: !isFavorite }));
    }
  };

  const handleChangeTag = (tagIds: number[]) => {
    if (smartForm) {
      dispatch(
        updateSmartFormMetaEffect({
          tags: [...tagIds],
        }),
      );
    } else {
      dispatch(
        updateQuoteFormMetaEffect({
          tags: [...tagIds],
        }),
      );
    }
  };

  const addTag = (tag, cb) => {
    dispatch(requestAddTag(tag, cb));
  };

  const toggleWizardView = (checked) => {
    const views = [FormView.PDFEditor];
    if (checked) {
      views.push(FormView.Wizard);
    }
    dispatch(
      updateSmartFormMetaEffect({
        views,
      }),
    );
  };

  const toggleCARCheck = (checked) => {
    dispatch(
      updateSmartFormMetaEffect({
        isCAR: checked,
      }),
    );
  };

  return (
    <div className={styles.quoteHeaderContainer}>
      <span className={styles.slash} />
      <Folder staticIcon className={styles.folderIcon} />
      <span className={styles.folderName} data-testid="quote-folder-name">
        {folderName}
      </span>
      <span className={styles.slash} />
      <span className={styles.formName} data-testid="quote-form-name">
        <Tooltip placement="bottom" title="Rename" getPopupContainer={(trigger) => trigger}>
          <TextArea
            disabled={previewForm}
            maxLength={64}
            value={formName}
            autoSize
            className={styles.formNameInput}
            onChange={(e) => setFormName(e.target.value)}
            onBlur={updateFormName}
          />
        </Tooltip>
      </span>
      {!previewForm && (
        <>
          <Modal
            open={tagModal}
            title="Select Form Tags"
            onCancel={() => setTagModal(false)}
            footer={
              <Button variant="secondary" onClick={() => setTagModal(false)}>
                Close
              </Button>
            }
          >
            <TagSelector
              allTags={allTags}
              selectedTags={selectedTags}
              handleChangeTag={handleChangeTag}
              addTag={addTag}
            />
          </Modal>

          <span className={styles.icons}>
            <Tooltip
              placement="bottom"
              title={`${
                selectedTags.length
                  ? allTags
                      .filter((el) => selectedTags.includes(el.TagId))
                      .map((el) => el.Name)
                      .join(', ')
                  : 'Add Tags'
              }`}
              getPopupContainer={(trigger) => trigger}
            >
              <Tag
                data-testid="quote-form-tags"
                variant={
                  selectedTags.length
                    ? selectedTags.length > 1
                      ? 'Filled Count'
                      : 'Filled'
                    : 'Empty'
                }
                count={selectedTags.length}
                className={classNames(styles.tagIcon, 'show-cursor')}
                onClick={() => setTagModal(true)}
              />
            </Tooltip>
            <Tooltip placement="bottom" title={`Add to ${isFavorite ? 'unfavorite' : 'favorite'}`}>
              <Favorites
                data-testid={isFavorite ? 'quote-form-favorite' : 'quote-form-unfavorite'}
                onClick={updateFavorite}
                favorite={isFavorite}
                className={classNames(styles.favIcon, 'show-cursor')}
              />
            </Tooltip>
          </span>
        </>
      )}
      {!!smartForm && (
        <>
          <div className={styles.wizardViewToggle}>
            <Tooltip
              placement="bottom"
              title={`${wizardViewEnabled ? 'Disable' : 'Enable'} Wizard View`}
            >
              <Switch
                checked={wizardViewEnabled}
                onChange={toggleWizardView}
                checkedChildren="Wizard"
                unCheckedChildren="Wizard"
              />
            </Tooltip>
          </div>

          <div className={styles.wizardViewToggle}>
            <Tooltip placement="bottom" title={`${wizardViewEnabled ? 'Disable' : 'Enable'} CAR`}>
              <Switch
                checked={isCAR}
                onChange={toggleCARCheck}
                checkedChildren="CAR"
                unCheckedChildren="CAR"
              />
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
};
