import { handleActions } from 'redux-actions';

import { setMultipleModAction } from 'store/actions/feed';
import { appLogoutAction } from 'store/actions/app';

const initialData = false;

export default handleActions(
  {
    [setMultipleModAction]: (state, { payload }) => payload,
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
