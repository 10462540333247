import { useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';
import { CompareFn } from 'antd/lib/table/interface';

import { renderSortableTitle, sorterWithCompletedOnTop } from './sortingHelpers';
import { Table } from 'components-antd';
import {
  getAllFormProcessesSelector,
  getFormsFiltersSelector,
  getRequestFormProcessSelector,
} from 'store/selectors/requestFormProcess';
import { onHandleViewFormType } from '../../Forms';
import { Icons } from '../../Icons';
import { FormsArchiveTableStatus } from 'app-constants';
import { FormProcessType } from 'types';
import { getTitleByType } from 'utils/aggregatedPageTypeHelper';
import { getAggregatePageTypeSelector } from 'store/selectors/workshop';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { Avatar, Wrapper } from 'components';
import { CollapsibleTable, WorkshopTableWrapper } from 'pages/Workshop/common';
import { NamesContainer } from 'pages/Workshop/Transactions/components/NamesContainer';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { ClientColumn, TransactionColumn } from './FormsTable';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_OWNER } from 'settings/constants/roles';
import { isExternalMember } from './helper';

interface FormTableProps {
  setSelectedFormProcess?: (formProcessId: number, status?: string) => void;
  handleViewForm: onHandleViewFormType;
  justSignedFormProcessPublicId?: string;
  refetchForms: Function;
}

export const FormsArchiveTable = ({
  setSelectedFormProcess,
  handleViewForm,
  justSignedFormProcessPublicId,
  refetchForms,
}: FormTableProps) => {
  const {
    recentlyCompletedForms,
    voidedForms = [],
    canceledForms = [],
  } = useSelector(getAllFormProcessesSelector);

  const { isPending } = useSelector(getRequestFormProcessSelector);
  const aggregatedPageType = useSelector(getAggregatePageTypeSelector);
  const { search } = useSelector(getFormsFiltersSelector);
  const { isClient } = useSelector(getUserRolesMapSelector);
  const teamRole = useSelector(getAgentTeamRoleSelector);
  const [editId, setEditId] = useState(null);

  let dataSource = [
    ...voidedForms.map((data) => ({
      ...data,
      key: data.formDocumentId,
      status: FormsArchiveTableStatus.Voided,
    })),

    ...recentlyCompletedForms.map((data) => ({
      ...data,
      key: data.formDocumentId,
      status: FormsArchiveTableStatus.Complete,
    })),

    ...canceledForms.map((data) => ({
      ...data,
      key: data.formDocumentId,
      status: FormsArchiveTableStatus.Canceled,
    })),
  ];

  const formsData: any = {
    voidedData: [],
    completedData: [],
    cancelledData: [],
  };

  for (const data of dataSource) {
    let keepItem = true;

    if (search) {
      keepItem = data.formName.toLowerCase().includes(search.toLowerCase());
    }

    if (!keepItem) continue;

    let addAtStart = false;

    if (
      justSignedFormProcessPublicId &&
      data.formProcessPublicId === justSignedFormProcessPublicId
    ) {
      addAtStart = true;
    }

    switch (data.status) {
      case FormsArchiveTableStatus.Voided:
        if (addAtStart) {
          formsData.voidedData.unshift(data);
        } else {
          formsData.voidedData.push(data);
        }
        break;

      case FormsArchiveTableStatus.Complete:
        if (addAtStart) {
          formsData.completedData.unshift(data);
        } else {
          formsData.completedData.push(data);
        }
        break;

      case FormsArchiveTableStatus.Canceled:
        if (addAtStart) {
          formsData.cancelledData.unshift(data);
        } else {
          formsData.cancelledData.push(data);
        }
        break;

      default:
    }
  }

  const openFormDetailModal = (form) => {
    if (setSelectedFormProcess && (form.isOwner || isExternalMember(form)))
      setSelectedFormProcess(form.formProcessId, form.status || '');
    else if (form.url && handleViewForm) {
      handleViewForm(form.url, form.type);
    }
  };

  const renderStatus = (status: string) => {
    function getStatusClassName() {
      switch (status) {
        case FormsArchiveTableStatus.Voided:
          return styles.voided;

        case FormsArchiveTableStatus.Complete:
          return styles.complete;

        case FormsArchiveTableStatus.Canceled:
          return styles.canceled;
        default:
          return '';
      }
    }
    return (
      <div className={classNames(styles.formsStatus, getStatusClassName())}>
        <span className={styles.statusText}>{status}</span>
      </div>
    );
  };

  const renderLastActivity = (form: FormProcessType) => {
    const { status, date, waitingOnYou, isFinalizing, isEdit, isSign } = form;
    const now = moment(new Date());
    const formDate = moment(date);
    var duration = moment.duration(now.diff(formDate));
    var hours = duration.asHours();

    const renderStatus = (label) => {
      return (
        <div className={styles.lastActivity}>
          <span>{label}:</span>
          <div>
            <span>
              {' ' + (hours > 48 ? moment(date).format('MM/DD/YYYY') : moment(date).fromNow())}
            </span>
          </div>
        </div>
      );
    };

    const renderTheLastActivity = () => {
      switch (status) {
        case FormsArchiveTableStatus.Complete:
          return renderStatus('Completed');

        case FormsArchiveTableStatus.Voided:
          return renderStatus('Voided');

        case FormsArchiveTableStatus.Canceled:
          return renderStatus('Canceled');

        default:
          return renderStatus('Completed');
      }
    };

    return (
      <div
        className={classnames(styles.status, {
          [styles.statusWarning]: waitingOnYou,
        })}
      >
        {renderTheLastActivity()}
      </div>
    );
  };

  const StatusColumn = ({ row: { status } }) => {
    return renderStatus(status);
  };

  const LastActivityColumn = ({ row }) => {
    return renderLastActivity(row);
  };

  const FormColumn = ({ row }) => {
    const formName = row?.formName;

    return (
      <div className={classnames(styles.row, styles.name)}>
        <div className={styles.formAvatarIcon}>
          <Icons variant="file" />
        </div>
        <div className={styles.flexCol}>
          <span className={styles.formName}>{formName}</span>
        </div>
      </div>
    );
  };

  const OwnerColumn = ({ row }) => {
    const ownerName = `${row?.creator?.FirstName} ${row?.creator?.LastName}`;
    return (
      <div className={styles.ownerWrapper}>
        {ownerName && row?.creator ? (
          <>
            <Avatar placeholder={row?.creator?.AvatarUrl} avatarClassName={styles.avatarCustom} />
            <div>
              <NamesContainer names={[ownerName]} maxDisplayCount={1} />
            </div>
          </>
        ) : (
          '-'
        )}
      </div>
    );
  };

  const columns: ColumnsType<FormProcessType> = [
    {
      title: renderSortableTitle('formName', 'Form'),
      dataIndex: 'formName',
      key: 'formName',
      width: 300,
      render: (text, row) => <FormColumn row={row} />,
      sorter: sorterWithCompletedOnTop('formName') as CompareFn<FormProcessType>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (text, row) => <StatusColumn row={row} />,
    },
    {
      title: renderSortableTitle('address', getTitleByType(aggregatedPageType)),
      dataIndex: 'address',
      key: 'address',
      width: 250,
      render: (text, row) => (
        <TransactionColumn
          row={row}
          setEditId={(value) => setEditId(value)}
          editId={editId}
          refetchForms={() => refetchForms()}
        />
      ),
      sorter: sorterWithCompletedOnTop('address') as CompareFn<FormProcessType>,
    },
    ...(!isClient
      ? [
          {
            title: renderSortableTitle('client', 'Client'),
            dataIndex: 'client',
            key: 'client',
            width: 250,
            render: (text, row) => (
              <ClientColumn
                row={row}
                setEditId={(value) => setEditId(value)}
                editId={editId}
                refetchForms={refetchForms}
              />
            ),
            sorter: sorterWithCompletedOnTop('client') as CompareFn<FormProcessType>,
          },
        ]
      : []),
    ...(teamRole === TEAM_OWNER
      ? [
          {
            title: 'Owner',
            dataIndex: 'creator',
            key: 'creator',
            width: 250,
            render: (text, row) => <OwnerColumn row={row} />,
          },
        ]
      : []),
    {
      title: 'Last Activity',
      dataIndex: 'status',
      key: 'lastActivity',
      width: 300,
      render: (text, row) => <LastActivityColumn row={row} />,
    },
  ];

  const tableProps = {
    columns,
    onRow: (record) => {
      return {
        onClick: () => {
          openFormDetailModal(record);
        },
      };
    },
    tableClassName: styles.tableView,
    pagination: false,
  };

  let tablesData = [
    { title: 'Voided', data: formsData.voidedData },
    { title: 'Complete', data: formsData.completedData },
    { title: 'Canceled', data: formsData.cancelledData },
  ];

  return (
    <Wrapper isPending={isPending}>
      <WorkshopTableWrapper
        data={[...formsData.completedData, ...formsData.voidedData]}
        emptyText="No Forms."
      >
        {tablesData?.map(({ title, data }) =>
          data.length ? <CollapsibleTable title={title} dataSource={data} {...tableProps} /> : null,
        )}
      </WorkshopTableWrapper>
    </Wrapper>
  );
};
