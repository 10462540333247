import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { groupBy } from 'lodash-es';

import { Avatar } from 'components';
import { setNotificationsDrawerParamsEffect } from 'store/effects/drawers/notifications';
import Notification from '..';
import { NewListingNotificationsTypes } from 'settings/constants/notifications';

import styles from './styles.module.scss';

const ByUser = (props) => {
  const { className, groupedNotificationsByUser, name: userName, avatarUrl, groupType } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!groupedNotificationsByUser?.length) {
      dispatch(setNotificationsDrawerParamsEffect({ currentNotificationsUser: null, type: null }));
    }
  }, [groupedNotificationsByUser?.length, dispatch]);

  const getAvatarPlaceholder = () => {
    const [firstName, lastName] = (userName || '').split(' ');
    return `${firstName ? firstName?.[0] : ''}${lastName ? lastName?.[0] : ''}`;
  };

  const newListingNotifications = groupedNotificationsByUser.filter((notific) =>
    NewListingNotificationsTypes.Agent.includes(notific.Type),
  );

  let groupByType = {};
  if (newListingNotifications.length) {
    groupByType = groupBy(newListingNotifications, (item) => item.Type);
  }

  const getNotificationsMarkup = (groupedNotificationsByUser = []) => {
    let groupedNotifications = groupedNotificationsByUser;
    NewListingNotificationsTypes.Agent.forEach((type) => {
      const index = groupedNotificationsByUser.findIndex((item) => item?.Type === type);
      if (index > -1) {
        groupedNotifications = groupedNotifications.filter(
          (notific, i) => notific.Type !== type || index === i,
        );
      }
    });

    return groupedNotifications.map((item) => {
      if (NewListingNotificationsTypes.Agent.includes(item.Type)) {
        return (
          newListingNotifications?.length > 0 && (
            <Notification
              key={item.Type}
              {...groupByType[item.Type]?.[0]}
              groupedNotifications={groupByType[item.Type]}
              groupIds={groupByType[item.Type]?.map((li) => li.Id) || []}
              groupType={groupType}
            />
          )
        );
      }
      return <Notification key={item?.Id} {...item} groupType={groupType} />;
    });
  };

  return (
    <div testid="by_user" className={classNames(styles.byUser, className)}>
      <div testid="head" className={styles.head}>
        <Avatar
          avatarClassName={styles.avatar}
          src={avatarUrl}
          placeholder={getAvatarPlaceholder()}
        />
        <div testid="name" className={styles.name}>
          {userName}
        </div>
      </div>
      <div className={styles.content}>{getNotificationsMarkup(groupedNotificationsByUser)}</div>
    </div>
  );
};

ByUser.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  avatarUrl: PropTypes.string,
  groupType: PropTypes.string,
  groupedNotificationsByUser: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ByUser.defaultProps = {
  className: '',
  name: '',
  avatarUrl: '',
  groupType: '',
};

export default ByUser;
