import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  getActivePropertiesMapMarkers,
  getFeedDrawnPolygonSelector,
  getFeedIsMapDrawingModeSelector,
  getFeedPageInfoSelector,
} from 'store/selectors/feed';
import { link } from 'settings/navigation/link';
import { MapWithDrawingManager } from 'components/MapWithDrawingManager/MapWithDrawingManager';
import { Map as CommonMap } from 'components';
import { featureFlags } from 'utils/featureFlags';
import {
  setFeedDrawnPolygonAction,
  setFeedViewportCoordinatesAction,
  toggleFeedMapDrawingModeAction,
} from 'store/actions/feed';

const Map = ({ id, mapContainerClassName }) => {
  const dispatch = useDispatch();

  const markers = useSelector(getActivePropertiesMapMarkers);
  const { totalCount, pageInfo } = useSelector(getFeedPageInfoSelector);
  const getPropertyLink = useCallback((id) => link.toFeedListingDetails(id), []);

  const onBoundChangeHandlerMap = (viewportCoordinates, isInitialViewportCoordinate) => {
    if (!isInitialViewportCoordinate) {
      dispatch(setFeedViewportCoordinatesAction(viewportCoordinates));
    }
  };

  const getListingCountTextForMap = () => {
    if (pageInfo?.itemsLoaded && pageInfo?.itemsLoaded !== totalCount?.value) {
      const countText = totalCount?.type === 'Exact' ? totalCount?.value : `${totalCount?.value}+`;
      return `${pageInfo?.itemsLoaded} of ${countText} listings`;
    }
    return '';
  };

  if (featureFlags.customSearchPolygonDrawing) {
    return (
      <MapWithDrawingManager
        mapContainerClassName={mapContainerClassName}
        markersMap={markers}
        id={id}
        getPropertyLink={getPropertyLink}
        getDrawnPolygonSelectorFn={getFeedDrawnPolygonSelector}
        getIsMapDrawingModeSelectorFn={getFeedIsMapDrawingModeSelector}
        setDrawnPolygonDispatchFn={setFeedDrawnPolygonAction}
        toggleMapDrawingModeDispatchFn={toggleFeedMapDrawingModeAction}
        onBoundsChangedHandlerFn={onBoundChangeHandlerMap}
        listingCountTextForMap={getListingCountTextForMap()}
        isMapEnhancementEnabled={true}
      />
    );
  }

  return (
    <CommonMap
      mapContainerClassName={mapContainerClassName}
      markersMap={markers}
      id={id}
      getPropertyLink={getPropertyLink}
      onBoundsChangedHandlerFn={onBoundChangeHandlerMap}
      listingCountTextForMap={getListingCountTextForMap()}
      isMapEnhancementEnabled={true}
    />
  );
};

Map.propTypes = {
  id: PropTypes.string,
  mapContainerClassName: PropTypes.string,
};

Map.defaultProps = {
  id: '',
  mapContainerClassName: '',
};

export default Map;
