import { cloneDeep, get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { requestGetFavoritesPropertiesAction } from 'store/actions/feedv3';
import { requestToggleFavoriteListingAction } from 'store/actions/listingDetail';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetFavoritesPropertiesAction as any]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestToggleFavoriteListingAction as any]: (state, { payload }) => {
      const id = get(payload, 'meta.id');
      const isFavorite = get(payload, 'options.IsFavorite');
      const newData: any = cloneDeep(state?.data) || { items: [] };
      const favoriteIndex = newData?.items.findIndex((item) => item?.Id === id);

      if (favoriteIndex !== -1 && !isFavorite) {
        newData.items.splice(favoriteIndex, 1);
        newData.totalCount = {
          ...newData?.totalCount,
          value: newData.items?.length,
        };
      }

      return {
        ...state,
        data: newData,
      };
    },
    [appLogoutAction as any]: () => initialData,
  },
  initialData,
);
