export enum TransactionCloseComponentIds {
  UploadFiles = 'UploadFiles',
  ClosePrice = 'ClosePrice',
  CloseDate = 'CloseDate',
  IncompleteForms = 'IncompleteForms',
  PendingTasks = 'PendingTasks',
  ClientProfileAddAddress = 'ClientProfileAddAddress',
  ClientProfileRemoveAddress = 'ClientProfileRemoveAddress',
  ActiveSearchInstances = 'ActiveSearchInstances',
  KitsActivation = 'KitsActivation',
  CloseConfirmation = 'CloseConfirmation',
}

export enum TransactionStagePercentage {
  CloseDate = 10,
  ClosePrice = 20,
  UploadFiles = 30,
  IncompleteForms = 40,
  PendingTasks = 50,
  ClientProfileRemoveAddress = 60,
  ClientProfileAddAddress = 70,
  ActiveSearchInstances = 80,
  KitsActivation = 90,
  CloseConfirmation = 100,
}

export enum TransactionFinancingType {
  Conventional = 'Conventional',
  Nonconforming = 'Nonconforming',
  FHA = 'FHA',
  VA = 'VA',
  Cash = 'Cash',
  Other = 'Other',
}

export enum TransactionLeadSource {
  Sphere = 'Sphere',
  Zillow = 'Zillow',
  ZillowFlex = 'Zillow Flex',
  Realtor = 'Realtor.com',
  Referral = 'Referral',
  OpenHouse = 'Open House',
  DigitalAds = 'Digital Ads',
  Farm = 'Farm',
  SocialMedia = 'Social Media',
  ColdCall = 'Cold Call',
  YouTube = 'YouTube',
  Other = 'Other',
}

export enum TransactionBuySideCommissionResponsibility {
  Buyer = 'Buyer',
  Seller = 'Seller',
  Both = 'Both',
}
