import { useSignupRole } from 'hooks';
import { Container } from 'components/LoginGroup';

import { Middle } from './components';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';
import { CLIENT } from 'settings/constants/roles';
import classNames from 'classnames';

const OnBoarding = () => {
  useSignupRole();
  const signUpRole = useSelector(getSignUpRoleSelector);

  return (
    <Container
      className={classNames(styles.onBoardingContainer, {
        [styles.greyBackground]: signUpRole === CLIENT,
      })}
    >
      <Middle className={styles.middle} />
    </Container>
  );
};

export default OnBoarding;
