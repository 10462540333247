import moment from 'moment';
import { getLocaleDate } from 'helpers/index';

import styles from './styles.module.scss';
import { Fragment } from 'react';

export const DateAndTime = (props) => {
  const { deadlineDate, deadlineTime } = props;

  return (
    <div className={styles.deadlineDetails}>
      <div className={styles.headTitle}>Deadline to Submit</div>
      {deadlineDate || deadlineTime ? (
        <Fragment>
          <p className={styles.date}>
            {moment(getLocaleDate(deadlineDate)).format('MMMM Do, YYYY')}
          </p>
          <p className={styles.subTitle}>
            {moment(getLocaleDate(deadlineDate)).format('hh:mm A (z)')}
          </p>
        </Fragment>
      ) : (
        <p>-</p>
      )}
    </div>
  );
};
