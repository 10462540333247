import { createAction } from 'redux-actions';

export const setTransactionPreFormQuestionsAction = createAction(
  'SET_TRANSACTION_PRE_FORM_QUESTIONS',
);
export const createTransactionAction = createAction('REQUEST/CREATE_TRANSACTION');
export const resetTransactionCreateAction = createAction('RESET_TRANSACTION_CREATE');
export const createTransactionContactAction = createAction('REQUEST/CREATE_TRANSACTION_CONTACT');
export const resetTransactionCreateTemplateAction = createAction(
  'RESET_TRANSACTION_CREATE_TEMPLATE',
);
