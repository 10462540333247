import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AutoComplete, Input } from 'antd';
import { useSelector } from 'react-redux';

import Icon from '../Icons';
import { getRelatedEntitiesState } from 'store/selectors/relatedEntities';
import { getTransactionParticipantsContactsList } from 'store/selectors/transaction';
import { getTransactionTaskSelector } from 'store/selectors/transactionTask';
import { getUserSelector } from 'store/selectors/user';
import { TransactionUserRoleMap } from 'settings/constants/transaction';

import styles from './styles.module.scss';

const AutoCompleteSuggestion = (props) => {
  const {
    className,
    onAutoCompleteClick,
    open,
    setOpen,
    placeholder,
    isTaskComments,
    isAutoSuggestionTyping,
    setIsAutoSuggestion,
    inputValue,
    notePrivacy = '',
  } = props;
  const relatedEntities = useSelector(
    isTaskComments ? getTransactionParticipantsContactsList : getRelatedEntitiesState,
  );
  const [result, setResult] = useState(relatedEntities || []);
  const [openMenu, setOpenMenu] = useState(false);
  const iconRef = useRef();
  const autoCompleteRef = useRef();
  const teamAgents = useSelector((state) => state.teamList.data);
  const { task } = useSelector(getTransactionTaskSelector);
  const user = useSelector(getUserSelector);
  useEffect(() => {
    if (isAutoSuggestionTyping) {
      setOpenMenu(true);
      setResult(
        inputValue?.length
          ? relatedEntities?.filter((entity) =>
              entity.SearchableName.toLowerCase().includes(inputValue.toLowerCase()),
            )
          : relatedEntities,
      );
    }
  }, [inputValue, isAutoSuggestionTyping]);

  const onOpenAutoComplete = () => {
    setOpen(!open);
    setOpenMenu(!openMenu);
    setIsAutoSuggestion(false);
  };

  const getOptions = () => {
    // limit tag options to team member + anyone on the task (assignee or CC)
    const mentionablePeople = [
      ...(teamAgents || []),
      ...(task?.AssigneeList || []),
      ...(task?.CcList || []),
    ];
    return (
      result
        // filter external entities in case of Internal Note
        .filter((entity) => {
          switch (notePrivacy) {
            case 'Me':
              return entity.Id === user.Id;
            case 'Internal':
              return mentionablePeople.find((teamAgent) => teamAgent.Id === entity.Id);
            default:
              return entity;
          }
        })
        .filter((entity) =>
          ['Transaction', 'Client', 'Agent', 'TeamAgent', 'QuoteRequest'].includes(entity.Source),
        )
        .map((entity) => ({
          value: entity.SearchableName,
          label:
            entity.Type === 'Contact' ? (
              <span>
                <span className="dropdownItem">
                  {/* <Avatars
                className={styles.avatars}
                avatarWrapperClassName={styles.avatarWrapper}
                avatarClassName={styles.avatar}
                isNotSingleClassName={styles.isNotSingle}
                avatars={participantsAvatarUrls}
                overflowClassName={styles.overflow}
              /> */}
                  {`${entity.FirstName} ${entity.LastName}`}
                </span>
                <span className="tagTypePill">
                  {TransactionUserRoleMap[entity.Role] || entity.Role}
                </span>
              </span>
            ) : entity.Type === 'Property' ? (
              <span className="propertyTransactionIcon">
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75 9.75V21.75H20.25V9.75"
                      stroke="#676767"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.25 10.5L12 3L16.4297 6.40743M21.75 10.5L19.9751 9.1347M16.4297 6.40743V3H19.9751V9.1347M16.4297 6.40743L19.9751 9.1347"
                      stroke="#676767"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.25 12H15.75M15.75 12L13.875 10.125M15.75 12L13.875 13.875"
                      stroke="#676767"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.75 16.5H8.25M8.25 16.5L10.125 14.625M8.25 16.5L10.125 18.375"
                      stroke="#676767"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span className="dropdownItem">{`${entity.Address.Line1} #${entity.Address.Zip}`}</span>
                <span className="tagTypePill">{entity.Source}</span>
              </span>
            ) : (
              ''
            ),
        }))
    );
  };

  const handleSearch = (val) => {
    setOpenMenu(true);
    setResult(
      relatedEntities.filter((entity) =>
        entity.SearchableName.toLowerCase().includes(val.toLowerCase()),
      ),
    );
  };

  const handleSelection = (val, options) => {
    const [selectedValue] = relatedEntities.filter((entity) => entity.SearchableName === val);
    onAutoCompleteClick(selectedValue);
    setIsAutoSuggestion(false);
    setOpen(!open);
  };

  return (
    <div className={classNames(styles.autocomplete, className)}>
      <div ref={iconRef} onClick={onOpenAutoComplete} className={styles.iconWrapper}>
        <Icon testid="autocomplete_icon" className={styles.icon} variant={Icon.AUTOCOMPLETE} />
      </div>
      {open && (
        <div testid="autocomplete_dropdown" className={styles.autocompleteWrapper}>
          <AutoComplete
            open={openMenu}
            ref={autoCompleteRef}
            onSearch={handleSearch}
            onSelect={handleSelection}
            className={classNames(styles.autocompleteContainer, {
              [styles.autoCompleteTyping]: isAutoSuggestionTyping,
            })}
            popupClassName={styles.suggestionPopupContainer}
            getPopupContainer={() => document.getElementById('send_message_form')}
            notFoundContent={<p>You have no matched result</p>}
            options={getOptions()}
            onClick={() => setOpenMenu(true)}
            onBlur={() => {
              setOpenMenu(false);
              setOpen(false);
            }}
            autoFocus={isAutoSuggestionTyping ? false : true}
            dropdownAlign={{
              points: ['bl', 'tl'], // align dropdown bottom-left to top-left of input element
              offset: [0, -4], // align offset
              overflow: {
                adjustX: 0,
                adjustY: 0, // do not auto flip in y-axis
              },
            }}
          >
            <Input
              className={styles.autocompleteInput}
              prefix={
                <Icon
                  testid="autocomplete_icon"
                  className={styles.searchIcon}
                  variant={Icon.SEARCH}
                  onClick={() => autoCompleteRef?.current?.click()}
                />
              }
              placeholder={placeholder}
            />
          </AutoComplete>
        </div>
      )}
    </div>
  );
};

AutoCompleteSuggestion.propTypes = {
  className: PropTypes.string,
  onAutoCompleteClick: PropTypes.func,
  setIsAutoSuggestion: PropTypes.func,
  isAutoSuggestionTyping: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  placeholder: PropTypes.string,
  inputValue: PropTypes.string,
  isTaskComments: PropTypes.bool,
  notePrivacy: PropTypes.string,
};

AutoCompleteSuggestion.defaultProps = {
  className: '',
  onAutoCompleteClick: () => {},
  setIsAutoSuggestion: () => {},
  open: false,
  isAutoSuggestionTyping: false,
  setOpen: () => {},
  placeholder: '',
  isTaskComments: false,
  inputValue: '',
  notePrivacy: '',
};

export default AutoCompleteSuggestion;
