import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import { Input, DatePicker } from 'components';
import { LoadingOutlined } from '@ant-design/icons';
import {
  validateControlOperatorOffset,
  convertToDateTimeForAllFormats,
} from 'utils/templatesHelper';
import { getTransactionDateControlsSelector } from 'store/selectors/transaction';
import { TaskEditableFormValuesType } from 'types/transactionTasks';
import { subtractTimeZone, showSuccessMessage, getDateOnly } from 'helpers';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { Spin } from 'antd';

interface DueDateProps {
  isViewMode?: boolean;
  addMode?: boolean;
  hideAsterisk?: boolean;
  milestone?: any;
  index?: number;
  scrollToBottom?: boolean;
  setDueDate?: (value: string) => void;
  setControlOperatorOffset?: (value: string) => void;
  onFieldsChange?: (value: object, index?: number) => void;
}

const DueDate = ({
  isViewMode,
  addMode,
  hideAsterisk,
  milestone,
  setDueDate,
  setControlOperatorOffset,
  onFieldsChange,
  index,
  scrollToBottom,
}: DueDateProps) => {
  const dueDate = useRef<any>('');
  const isProject = useIsProjectRoute();
  const dateControls = useSelector(getTransactionDateControlsSelector);

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(true);
  const [fieldValues, setFieldValues] = useState({
    ControlOperatorOffset: milestone
      ? milestone?.MilestoneTemplateInitial +
        milestone?.MilestoneTemplateOperator +
        milestone?.MilestoneTemplateDateOffset
      : '',
    DueDate: milestone?.DueDate,
  } as TaskEditableFormValuesType);

  const onChangeManyFields = (fields) => {
    setFieldValues((values) => ({ ...values, ...fields }));
  };

  const updateDueDate = () => {
    if (
      !dueDate?.current ||
      (milestone?.DueDate &&
        dueDate.current &&
        new Date(subtractTimeZone(milestone.DueDate)).getTime() ===
          new Date(dueDate.current).getTime())
    ) {
      return;
    }

    onFieldsChange?.(
      {
        DueDate: getDateOnly(moment(dueDate?.current).format('ll'), 'll'),
      },
      index,
    );
    setDueDate?.(dueDate?.current);
    setControlOperatorOffset?.(fieldValues.ControlOperatorOffset);
  };

  const onClose = () => {
    setOpenCalendar(false);
    dueDate ? updateDueDate() : setEdit(false);
  };

  const onChangeControlOperatorOffset = (e, val) => {
    if (fieldValues?.DueDate) {
      onChangeManyFields({
        DueDate: null,
        ControlOperatorOffset: '',
      });
      onFieldsChange?.(
        {
          DueDate: moment(fieldValues?.DueDate).format('ll'),
        },
        index,
      );
      setDueDate?.(fieldValues?.DueDate);
    } else {
      validateControlOperatorOffset(e, val, onChangeManyFields, null, undefined, true);
    }
  };

  const datePickerRef = useRef(null);

  const handleDateChange = (e) => {
    convertToDateTimeForAllFormats(
      e.target.value,
      dateControls,
      (val) => {
        if (val.DueDate) {
          dueDate.current = val.DueDate;
          setDueDate?.(val.DueDate);
          onFieldsChange?.(
            {
              DueDate: moment(val?.DueDate).format('ll'),
            },
            index,
          );
        }
        onChangeManyFields(val);
        setShowErrMsg(false);
        if (!val?.DueDate) {
          setShowErrMsg(true);
          return;
        } else onClose();
      },
      null,
      true,
    );
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  const getControlOffsetValue = () => {
    if (fieldValues?.DueDate) {
      return getDateOnly(fieldValues.DueDate, 'll');
    } else {
      return fieldValues?.ControlOperatorOffset;
    }
  };
  return (
    <div className={styles.dueDate} style={{ flex: addMode ? '0 0 23.7%' : 'unset' }}>
      <div className={classNames({ [styles.editable]: !edit }, styles.fieldWrapper)}>
        <div className={styles.taskDueDatePicker} ref={datePickerRef}>
          <DatePicker
            className={styles.templateControlPicker}
            variant="light"
            scrollToBottom
            value={subtractTimeZone(milestone?.DueDate)}
            onChange={(e, val) => {
              setShowErrMsg(false);

              if (val?.length) {
                dueDate.current = val[0]?.toString();
                onChangeManyFields({ DueDate: val[0], ControlOperatorOffset: '' });
                updateDueDate();
              }
            }}
            options={{
              enableTime: false,
              dateFormat: 'M d, Y',
            }}
            daysFromToday={fieldValues?.Offset}
            autofocus={false}
            testid="due_date"
            disabled={isViewMode || loading}
            icon={true}
            openCalendar={edit && openCalendar}
            datePickerInputClass={styles.datePickerInput}
            debounce={true}
          />
          <Input
            variant={Input.LIGHT}
            placeholder={`T+7, ${isProject ? 'KO+2, DL-4' : 'CD+2'}`}
            value={getControlOffsetValue()}
            onChange={(e, val) => onChangeControlOperatorOffset(e, val)}
            maxLength={10}
            onBlur={(e) => handleDateChange(e)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleDateChange(e);
              }
            }}
            saveOnChange={false}
            disabled={isViewMode || loading}
            inputClassName={styles.input}
          />
          {showErrMsg && <div className={styles.errorMsg}>Invalid Date</div>}
          {loading && (
            <Spin
              className={styles.loader}
              indicator={<LoadingOutlined className={styles.spinner} spin />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DueDate;
