import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ ai }) => ai;

export const getListingFiltersSelector = createSelector(localState, ({ state, data }) => ({
  filters: data,
  isIdle: state === IDLE,
  isPending: state === PENDING,
}));
