import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Status = ({ status, className }) => (
  <div
    testid="property_status"
    className={classNames(styles.statusWrapper, styles[status?.replace(/ /g, '')], className)}
  >
    <span>{status}</span>
  </div>
);

Status.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
};

Status.defaultProps = {
  className: '',
};

export default Status;
