import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { openShareDrawerEffect, setSharePropertyEffect } from 'store/effects';
import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const Share = ({ className, propertyInfo }) => {
  const dispatch = useDispatch();

  const onShare = (e) => {
    e.stopPropagation();
    dispatch(setSharePropertyEffect(propertyInfo));
    dispatch(openShareDrawerEffect({ open: true }));
  };

  return (
    <div onClick={onShare} className={classNames(styles.shareWrapper, className)}>
      <Icon testid="share_icon" variant={Icon.SHARE} />
    </div>
  );
};

Share.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({}).isRequired,
};

Share.defaultProps = {
  className: '',
};

export default Share;
