import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Icons = ({ testid, className, variant, onClick }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.FILE: {
        return (
          <svg
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 3C1 1.89543 1.89543 1 3 1H11.2459C11.515 1 11.7727 1.10847 11.9609 1.30088L16.715 6.16307C16.8977 6.34992 17 6.60087 17 6.86219V19C17 20.1046 16.1046 21 15 21H3C1.89543 21 1 20.1046 1 19V3Z"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M11 1V5C11 6.10457 11.8954 7 13 7H17" stroke="#525252" strokeWidth="2" />
          </svg>
        );
      }
      case Icons.CLOSE: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="18"
              height="18"
              rx="9"
              fill="#163C5A"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M13 7L7 13"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 7L13 13"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icon, className)}>
      {renderIcon()}
    </div>
  );
};

Icons.FILE = 'file';
Icons.CLOSE = 'close';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};

export default Icons;
