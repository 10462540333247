/* eslint-disable max-len */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { reduce, trim } from 'lodash-es';

import { getPartnerObject } from 'store/selectors/singlePartner';
import { getClientAgentId } from 'store/selectors/user';

import { getOneLineAddress, getHrefLink } from 'helpers';

import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import MetaItem from 'pages/Quotes/components/QuoteDetails/components/Content/UserQuote/MetaInfo/MetaItem';
import { FormattedPhone, Map } from 'components';

import styles from './styles.module.scss';

const PartnerProfile = () => {
  const data = useSelector(getPartnerObject);
  const agentId = useSelector(getClientAgentId);

  const { address, categories, recommendedBy, markers } = useMemo(
    () => ({
      address: getOneLineAddress(data?.Address),
      categories: reduce(
        data?.PartnerCategories,
        (acc, { Title }) => {
          if (acc) {
            return `${acc}, ${Title}`;
          }
          return Title;
        },
        '',
      ),
      recommendedBy: reduce(
        data?.RecommendingAgents || [],
        (acc, { Id, FirstName, LastName }) => {
          if (Id === agentId) {
            return trim(`${FirstName} ${LastName}`);
          }
          return acc;
        },
        null,
      ),
      markers: data?.Address?.Geolocation?.Long && {
        [`${data?.Address?.Geolocation?.Long} ${data?.Address?.Geolocation?.Lat}`]: [
          {
            id: data?.Address?.Geolocation?.Long,
            geometry: {
              coordinates: {
                lng: data?.Address?.Geolocation?.Long,
                lat: data?.Address?.Geolocation?.Lat,
              },
            },
          },
        ],
      },
    }),
    [data?.Address, data?.PartnerCategories, data?.RecommendingAgents, agentId],
  );

  return (
    <div testid="partner_view">
      <div>
        <div className={styles.headerBlock}>
          <div testid="avatar" className={styles.headerBlockAvatar}>
            <img src={data?.LogoUrl} alt="avatar" />
          </div>
          <div className={styles.headerBlockTitle}>
            <div testid="business_name" className={styles.headerBlockTitleName}>
              {data?.BusinessName}
            </div>
            <div testid="categories" className={styles.headerBlockTitleCategory}>
              {categories}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <div className={styles.contentTitle}>Description</div>
          <div testid="description">{data?.Description}</div>
        </div>
        <div className={styles.contentRight}>
          {!!recommendedBy && (
            <div className={styles.recommendedBlock}>
              <div className={styles.recommendedBlockLeft}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0016 17.7505L5.82963 20.9955L7.00863 14.1225L2.01562 9.25549L8.91563 8.25349L12.0016 2.00049L15.0876 8.25349L21.9876 9.25549L16.9946 14.1225L18.1736 20.9955L12.0016 17.7505Z"
                    fill="#FF576D"
                    stroke="#FF576D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div testid="recommended_by" className={styles.recommendedBlockRight}>
                Recommended by <b>{recommendedBy}</b>
              </div>
            </div>
          )}
          <div className={styles.addressLine}>
            {data?.PhoneNumber && (
              <MetaItem
                icon={Icon.PHONE}
                value={<FormattedPhone testid="phone">{data?.PhoneNumber}</FormattedPhone>}
              />
            )}
            {data?.Email && <MetaItem testid="email" icon={Icon.EMAIL} value={data?.Email} />}
            {address && <MetaItem testid="address" icon={Icon.ADDRESS} value={address} />}
            {data?.Website && (
              <MetaItem
                testid="website"
                icon={Icon.WEBSITE}
                value={
                  <a href={getHrefLink(data.Website)} target="_blank" rel="noreferrer">
                    {data.Website}
                  </a>
                }
              />
            )}
          </div>
          <div className={styles.mapWrapper}>
            <Map
              className={styles.map}
              controlsClassName={styles.mapControls}
              markersMap={markers}
              oneDotZoom={10}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerProfile;
