import { filter, find, get, groupBy, includes, map, upperCase } from 'lodash-es';
import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { AGENT_CONNECTION_STATUSES } from 'settings/constants/common';

export const localState = ({ clientsList }) => clientsList;

export const connectionsSearchFilter = (list, search) =>
  filter(list, ({ name }) => includes(upperCase(name), upperCase(search)));

export const connectionsGroupByName = (list) => groupBy(list, ({ name }) => upperCase(name[0]));

export const getStateAndDataFlag = createSelector(localState, ({ state, data }) => ({
  state,
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!data,
}));

export const getClientsListDataArray = createSelector(localState, ({ data }) => data || []);

export const getFormattedClientsList = createSelector(getClientsListDataArray, (data) =>
  map(data, (item = {}) => {
    const role = get(item, 'Roles.0');
    const agents = get(item, 'Client.Agents');
    const context = agents?.[0];
    return {
      id: item.Id,
      name: `${item.FirstName} ${item.LastName}`,
      firstName: item.FirstName,
      lastName: item.LastName,
      role,
      clientStatus: get(item, [role, 'InvitationStatus']),
      contextStatus: context?.InvitationStatus,
      sendDate: get(item, 'InviteSentAt'),
      avatarUrl: get(item, 'AvatarUrl'),
      deactivated: get(item, 'Deactivated'),
      searchInstances: get(item, [role, 'SearchInstances']),
      transactionInstances: get(item, [role, 'TransactionInstances']),
      retention: !!agents?.[0]?.FromRetentionMode,
    };
  }),
);
export const getActiveClientsList = createSelector(getFormattedClientsList, (list) =>
  filter(
    list,
    ({ contextStatus, retention }) =>
      contextStatus === AGENT_CONNECTION_STATUSES.ACTIVE && !retention,
  ),
);

export const getRetainedClientsList = createSelector(getFormattedClientsList, (list) =>
  filter(list, ({ retention }) => retention),
);

export const getInactiveClientsList = createSelector(getFormattedClientsList, (list) =>
  filter(
    list,
    ({ deactivated, contextStatus }) =>
      contextStatus === AGENT_CONNECTION_STATUSES.INACTIVE || deactivated,
  ),
);

export const getPendingClientsList = createSelector(getFormattedClientsList, (list) =>
  filter(
    list,
    ({ contextStatus, retention }) =>
      contextStatus === AGENT_CONNECTION_STATUSES.PENDING && !retention,
  ),
);

export const getDeclinedClientsList = createSelector(getFormattedClientsList, (list) =>
  filter(list, ({ contextStatus }) => contextStatus === AGENT_CONNECTION_STATUSES.DECLINED),
);

export const getClientById = (id) =>
  createSelector(getClientsListDataArray, (list) => find(list, ({ Id }) => Id === id));

export const getClientDataByOpenedUserId = (id) =>
  createSelector(getClientById(id), (item) => ({
    ...item,
  }));
