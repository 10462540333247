import { Modal } from 'components-antd';
import PartnerForm from '../../../Partner/Form';

import styles from './styles.module.scss';

const AddEditPartnerModal = ({ open, onClose, selectedPartner, onEdit }) => (
  <Modal open={open} onCancel={onClose} footer={null} width={1050} maskClosable={false}>
    <div className={styles.addEditPartnerForm}>
      <PartnerForm uploadEdit={true} selectedPartner={selectedPartner} onEdit={onEdit} />
    </div>
  </Modal>
);

export default AddEditPartnerModal;
