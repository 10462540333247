/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import PropTypes from 'prop-types';

import Marker from './Marker';
import MultiplePropertiesMarkerComponent from './MultiplePropertiesMarker';

const Markers = (props) => {
  const { markers, popupClassName, markerClassName, onClickMarker, clusterer, getPropertyLink } =
    props;

  return (
    <div>
      {markers.map((properties, index) => {
        if (properties.length > 1) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { lng, lat } = properties?.[0]?.geometry?.coordinates;
          const id = properties?.[0]?.id;

          return (
            <MultiplePropertiesMarkerComponent
              id={id}
              key={id || index}
              longitude={lng}
              latitude={lat}
              properties={properties}
              popupClassName={popupClassName}
              markerClassName={markerClassName}
              onClickMarker={onClickMarker}
              clusterer={clusterer}
              getPropertyLink={getPropertyLink}
            />
          );
        }

        const {
          id,
          image,
          price,
          info,
          title,
          geometry: { coordinates },
          highlight,
          address,
          matchedClientsCount,
          addedToFavoritesBy,
          ...rest
          // eslint-disable-next-line no-unsafe-optional-chaining
        } = properties?.[0];
        const { lng, lat } = coordinates;
        return (
          <Marker
            id={id}
            key={id || index}
            longitude={lng}
            latitude={lat}
            image={image}
            price={price}
            info={info}
            title={title}
            address={address}
            popupClassName={popupClassName}
            markerClassName={markerClassName}
            onClickMarker={onClickMarker}
            clusterer={clusterer}
            getPropertyLink={getPropertyLink}
            highlight={highlight}
            neighborhoodExploration={props.neighborhoodExploration}
            propertyProps={properties?.[0]}
            {...rest}
          />
        );
      })}
    </div>
  );
};

const MarkerType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  geometry: PropTypes.shape({
    coordinates: PropTypes.shape({ lng: PropTypes.number, lat: PropTypes.number }),
  }),
  info: PropTypes.string,
  properties: PropTypes.shape({}),
});

Markers.propTypes = {
  popupClassName: PropTypes.string,
  markerClassName: PropTypes.string,
  markers: PropTypes.arrayOf(PropTypes.arrayOf(MarkerType)),
  onClickMarker: PropTypes.func,
  getPropertyLink: PropTypes.func,
  clusterer: PropTypes.shape({}).isRequired,
  neighborhoodExploration: PropTypes.bool,
};

Markers.defaultProps = {
  popupClassName: '',
  markerClassName: '',
  markers: [],
  onClickMarker: () => {},
  getPropertyLink: () => {},
  neighborhoodExploration: false,
};

export default Markers;
