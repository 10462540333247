import { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown } from 'components/Icons';

import styles from './styles.module.scss';
import { AggregatedPageTypes } from 'types/aggregatedPageTypes';
import { useLocation } from 'react-router-dom';
import { updateAggregatePageTypeAction } from 'store/actions/workshop';
import { getAggregatePageTypeSelector } from 'store/selectors/workshop';

export const TransactionAndProjectFilter = (props) => {
  const aggregatedPageType = useSelector(getAggregatePageTypeSelector);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const onChange = (e) => {
    dispatch(updateAggregatePageTypeAction(e.target.value));
  };

  const dropdownFields = [
    { itemValue: AggregatedPageTypes.All },
    { itemValue: AggregatedPageTypes.Transaction },
    { itemValue: AggregatedPageTypes.Project },
  ];

  useEffect(() => {
    if (aggregatedPageType !== AggregatedPageTypes.All) {
      dispatch(updateAggregatePageTypeAction(AggregatedPageTypes.All));
    }
  }, [pathname]);

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={aggregatedPageType}
      className={styles.optionsItems}
      defaultValue={aggregatedPageType}
    >
      <Menu>
        {dropdownFields.map((item, idx) => {
          return (
            <MenuItem key={idx}>
              <Radio value={item.itemValue} key={idx}>
                <div className={styles.option}>
                  <span className={styles.optionValue}>{item.itemValue}</span>
                </div>
              </Radio>
            </MenuItem>
          );
        })}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.filterWrap, props.className)}>
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.optionValue}>{aggregatedPageType}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
        </a>
      </Dropdown>
    </div>
  );
};
