import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getCurrentParticipants } from 'store/selectors/drawers/addParticipants';
import { useSelector } from 'react-redux';

import { Avatars } from 'components';
import Info from './Info';

import styles from './styles.module.scss';

const Subheader = (props) => {
  const { className, info, chatType } = props;
  const { participantsAvatarUrls, names } = useSelector(getCurrentParticipants);

  return (
    <div
      testid="add_participants_subheader"
      className={classNames(styles.messagesSubheader, className)}
    >
      <Avatars
        className={styles.avatars}
        avatarWrapperClassName={styles.avatarWrapper}
        avatarClassName={styles.avatar}
        isNotSingleClassName={styles.isNotSingle}
        avatars={participantsAvatarUrls}
        overflowClassName={styles.overflow}
      />
      <Info names={names} info={info} chatType={chatType} />
    </div>
  );
};

Subheader.propTypes = {
  className: PropTypes.string,
  info: PropTypes.string,
  chatType: PropTypes.string,
};

Subheader.defaultProps = {
  className: '',
  info: '',
  chatType: '',
};

export default Subheader;
