import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Input } from 'components';

import styles from './../../styles.module.scss';

interface CommissionFieldType {
  label: string;
  value: number | '';
  IsFeeTypePercentage: boolean;
  update: Function;
  className?: string;
}

export const CommissionField = ({
  label,
  value,
  IsFeeTypePercentage,
  update,
  className,
}: CommissionFieldType) => {
  const [isPercentage, setIsPercentage] = useState(IsFeeTypePercentage);

  useEffect(() => {
    setIsPercentage(IsFeeTypePercentage);
  }, [IsFeeTypePercentage]);

  return (
    <div className={classNames(styles.commissionFieldContainer, className)}>
      <Input
        label={label}
        numberInputClassName={styles.amountInput}
        variant={Input.LIGHT_ROUND}
        placeholder={IsFeeTypePercentage ? '0%' : '$0'}
        isNumberFormat
        suffix={isPercentage ? '%' : undefined}
        prefix={!isPercentage ? '$' : undefined}
        icon={
          <div className={styles.valueTypeContainer}>
            {['%', '$'].map((symbol, i) => {
              const active =
                (IsFeeTypePercentage && symbol === '%') || (!IsFeeTypePercentage && symbol === '$');
              return (
                <div
                  key={symbol}
                  onClick={() => {
                    if (!active) {
                      const updatedObject = {
                        IsFeeTypePercentage: symbol === '%',
                        Value: value,
                      };
                      update(updatedObject);
                    }
                    if (symbol === '%') {
                      const updatedObject = {
                        IsFeeTypePercentage: symbol === '%',
                        Value: value > '100' ? undefined : value,
                      };
                      update(updatedObject);
                    }
                  }}
                  className={classNames(
                    styles.button,
                    {
                      [styles.active]: active,
                    },
                    {
                      [styles.border]: i === 0,
                    },
                  )}
                >
                  {symbol}
                </div>
              );
            })}
          </div>
        }
        onChange={(e, val) => {
          const updatedObject = {
            Value: val,
            IsFeeTypePercentage: !!IsFeeTypePercentage,
          };
          update(updatedObject);
        }}
        value={value ?? ''}
        minNumber={0}
        maxNumber={IsFeeTypePercentage ? 100 : undefined}
      />
    </div>
  );
};
