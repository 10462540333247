import { handleActions } from 'redux-actions';
import { cloneDeep } from 'lodash-es';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  type: 'All',
};

export default handleActions(
  {
    [setSubFilterTypeAction]: (state, { payload }) => {
      return {
        type: payload,
      };
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
