import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { useHistory } from 'react-router-dom';

import { Collapse, Panel, ErrorModal as DraftFormModal } from 'components-antd';
import { ArrowDown } from 'components/Icons';
import { DocumentIcons } from '../../Icons';
import { Header } from './components/Header';
import { DocumentSection, TransactionSection } from './components/DocumentSections';
import {
  AgentDocumentResponse,
  AgentDocumentSearchCriteria,
  AgentDocumentTypes,
  DocumentOptionUtils,
  DocumentVaultResponseType,
} from 'types';
import { getAgentDocumentsEffect, deleteAgentDocumentEffect } from 'store/effects';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import Spinner from 'components/Spinner';
import { previewDocumentEffect } from 'store/effects/transactions';
import { PdfViewModal } from 'components';
import { FormDetails } from 'pages/Workshop/Forms/components/FormDetails';
import { handleViewForm } from 'utils';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';

import styles from './styles.module.scss';
import { DeleteDocument } from './components/DocumentSections/DeleteDocument';

export const Documents = ({ closeAgentDetails }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const details = useSelector(getAgentDetailsSelector);
  const agentId = details?.data?.Id;
  const [openDeleteDocumentModal, setOpenDeleteDocumentModal] = useState(false);
  const [documentToBeDeletedId, setDocumentToBeDeletedId] = useState<number>();
  const [documentToBeDeletedType, setDocumentToBeDeletedType] = useState('');
  const [deleteDocumentLoading, setDeleteDocumentLoading] = useState(false);
  const [documents, setDocuments] = useState<AgentDocumentResponse>({});
  const [searchCriteria, setSearchCriteria] = useState<AgentDocumentSearchCriteria>({});
  const [isLoading, setIsLoading] = useState(false);

  const [attachment, setAttachment] = useState<DocumentVaultResponseType>();
  const [previewOpen, setPreviewOpen] = useState(false);

  const [selectedFormProcess, setSelectedFormProcess] = useState<number>();

  const [showDraftFormModal, setShowDraftFormModal] = useState(false);

  const fetchDocuments = (updatedSearch?: AgentDocumentSearchCriteria) => {
    if (agentId) {
      setIsLoading(true);
      const search = { ...(updatedSearch ?? searchCriteria) };
      if (search.name) {
        search.name = search.name.trim();
      }
      dispatch(
        getAgentDocumentsEffect({ agentId, searchCriteria: search }, (err, resp) => {
          setIsLoading(false);

          if (!err) {
            setDocuments(resp.data);
          } else {
            setDocuments({});
          }
        }),
      );
    }
  };

  useEffect(() => {
    if (agentId) {
      fetchDocuments();
    }
  }, [agentId]);

  const handleSearchCriteria = (updatedSearch?: AgentDocumentSearchCriteria) => {
    if (updatedSearch) {
      setSearchCriteria(updatedSearch);
    }
    fetchDocuments(updatedSearch);
  };

  const handleReset = () => {
    setSearchCriteria({});
    fetchDocuments({});
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setAttachment(undefined);
  };

  const onDownload = (documentVaultUUID) => {
    if (documentVaultUUID) {
      dispatch(
        previewDocumentEffect({ DocumentVaultUUID: documentVaultUUID }, {}, (err, response) => {
          if (err) {
            return;
          }
          fileDownload(response.data, response.headers['file-name']);
        }),
      );
    }
  };

  const onPreview = (documentVaultUUID) => {
    if (documentVaultUUID) {
      setPreviewOpen(true);
      dispatch(
        previewDocumentEffect({ DocumentVaultUUID: documentVaultUUID }, {}, (err, response) => {
          if (!err) {
            setAttachment({
              DocumentBuffer: response,
              Filename: response.headers['file-name'],
            });
          }
        }),
      );
    }
  };
  const handleOpenDeleteDocumentModal = (id, type) => {
    setDocumentToBeDeletedId(id);
    setDocumentToBeDeletedType(type);
    setOpenDeleteDocumentModal(true);
  };
  const handleCloseDeleteDocumentModal = () => {
    setDocumentToBeDeletedId('');
    setOpenDeleteDocumentModal(false);
  };

  const deleteDocument = () => {
    if (!agentId || !documentToBeDeletedId) {
      return;
    }
    setDeleteDocumentLoading(true);
    dispatch(
      deleteAgentDocumentEffect(
        { agentId, id: documentToBeDeletedId, type: documentToBeDeletedType },
        (err, response) => {
          if (!err) {
            fetchDocuments();
            setOpenDeleteDocumentModal(false);
          }
          setDeleteDocumentLoading(false);
        },
      ),
    );
  };

  const openFormDetails = (formProcessId: number) => {
    if (formProcessId) {
      setSelectedFormProcess(formProcessId);
    }
  };

  const onCloseFormDetailModal = () => {
    setSelectedFormProcess(undefined);
  };

  const handleShareDocument = (documentVaultUUID) => {
    if (documentVaultUUID) {
      dispatch(openMessagesDrawerAction(true));
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
          params: {
            threadId: null,
            documentVaultUUID,
          },
        }),
      );
      if (closeAgentDetails) {
        closeAgentDetails();
      }
    }
  };

  const optionUtils: DocumentOptionUtils['optionUtils'] = {
    previewDocument: onPreview,
    downloadDocument: onDownload,
    openFormDetailsModal: openFormDetails,
    previewDraftForm: () => setShowDraftFormModal(true),
    handleOpenDeleteDocumentModal: handleOpenDeleteDocumentModal,
    shareDocument: handleShareDocument,
  };

  const getCollapseSection = (type: AgentDocumentTypes, data: AgentDocumentResponse) => {
    const isUngrouped = type === AgentDocumentTypes.Ungrouped;

    const documents = isUngrouped
      ? [
          ...(data[AgentDocumentTypes.OfferIteration] ?? []),
          ...(data[AgentDocumentTypes.ShowingAppointment] ?? []),
        ]
      : data[type];

    if (!documents || (isUngrouped && !documents?.length)) {
      return [];
    }

    const commonProps = {
      documents,
      optionUtils,
    };

    if (type === AgentDocumentTypes.Transactions) {
      return [
        {
          name: AgentDocumentTypes.Transactions,
          component: <TransactionSection {...commonProps} />,
        },
      ];
    } else {
      return [
        {
          name: type,
          component: <DocumentSection type={type} {...commonProps} />,
        },
      ];
    }
  };

  const collapseTypes = [
    ...getCollapseSection(AgentDocumentTypes.Drafts, documents),
    ...getCollapseSection(AgentDocumentTypes.PendingForms, documents),
    ...getCollapseSection(AgentDocumentTypes.PreApprovals, documents),
    ...getCollapseSection(AgentDocumentTypes.MessageAttachments, documents),
    ...getCollapseSection(AgentDocumentTypes.Transactions, documents),
    ...getCollapseSection(AgentDocumentTypes.Ungrouped, documents),
  ];

  return (
    <div className={styles.documentsTab}>
      <PdfViewModal isOpen={previewOpen} file={attachment} onClose={handlePreviewClose} />
      <DraftFormModal
        open={showDraftFormModal}
        error={{
          errorText: 'This form is in a draft state and not viewable',
        }}
        onCancel={() => setShowDraftFormModal(false)}
      />
      <DeleteDocument
        isExpandedOpened={openDeleteDocumentModal}
        onCancel={handleCloseDeleteDocumentModal}
        deleteDocument={deleteDocument}
        deleteDocumentLoading={deleteDocumentLoading}
      />
      {selectedFormProcess ? (
        <FormDetails
          agentId={agentId}
          selectedFormProcess={selectedFormProcess}
          onClose={onCloseFormDetailModal}
          onHandleViewForm={(url, type) => handleViewForm(history, url, type)}
          viewOnly
        />
      ) : (
        <></>
      )}

      <Header
        handleSearchCriteria={handleSearchCriteria}
        setSearchCriteria={setSearchCriteria}
        searchCriteria={searchCriteria}
        handleReset={handleReset}
      />
      <div className={styles.documentsTypes}>
        {isLoading && documents ? (
          <Spinner />
        ) : Object.keys(documents).length ? (
          <Collapse
            expandIcon={() => <ArrowDown className={styles.expandIconWrap} />}
            expandIconPosition="end"
            className={styles.documentsCollapse}
          >
            {collapseTypes.map(({ name, component }) => (
              <Panel
                header={
                  <div className={styles.headerLeft}>
                    <DocumentIcons type={name} />
                    <h3>{name}</h3>
                  </div>
                }
                key={`panel-${name}`}
              >
                {component}
              </Panel>
            ))}
          </Collapse>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

const NoData = () => <div className={styles.noData}>Documents not found</div>;
