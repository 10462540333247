import { routes } from 'settings/navigation/routes';
import { Listings } from 'pages';
import { AGENT, CLIENT } from 'settings/constants/roles';

export default [
  {
    path: routes.listings,
    component: Listings,
    exact: true,
    roles: [CLIENT, AGENT],
  },
];
