import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Info = (props) => {
  const { pendingCount, receivedCount, className } = props;

  return (
    <div testid="count_info" className={classNames(styles.countInfo, className)}>
      <div>
        <span testid="pending_count" className={styles.count}>
          {pendingCount}
        </span>
        <span>Pending</span>
      </div>
      <div className={styles.received}>
        <span testid="received_count" className={styles.count}>
          {receivedCount}
        </span>
        <span>Received</span>
      </div>
    </div>
  );
};

Info.propTypes = {
  className: PropTypes.string,
  pendingCount: PropTypes.number.isRequired,
  receivedCount: PropTypes.number.isRequired,
};

Info.defaultProps = {
  className: '',
};

export default Info;
