import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Close } from 'components/Icons';
import Icon from 'components/CommuteForm/Icons';
import { Col } from 'antd';
import styles from './styles.module.scss';

const Commute = ({ className, onDelete, onClick, ...props }) => {
  const { Name, MaxCommuteTimeInMinutes, TransportationModePreference } = props;

  return (
    <Col xs={24} sm={12} lg={8}>
      <div
        testid="saved_commute"
        onClick={onClick}
        className={classNames(styles.commute, className)}
      >
        <Icon className={styles.icon} variant={TransportationModePreference?.[0] || Icon.CAR} />
        <div className={styles.info}>
          <p testid="commute_name" className={styles.name}>
            {Name || 'Unnamed'}
          </p>
          <p
            testid="commute_time"
            className={styles.commuteTime}
          >{`${MaxCommuteTimeInMinutes} minutes`}</p>
        </div>
        <Close
          className={styles.removeIcon}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        />
      </div>
    </Col>
  );
};

Commute.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  Name: PropTypes.string,
  MaxCommuteTimeInMinutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  TransportationModePreference: PropTypes.arrayOf(PropTypes.string),
};

Commute.defaultProps = {
  className: '',
  Name: '',
  MaxCommuteTimeInMinutes: '',
  TransportationModePreference: [],
};

export default Commute;
