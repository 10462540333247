import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd';
import { SearchFieldWithDebounce } from 'components';
import { Close, MultiSelect } from 'components/Icons';
import { Icons } from 'pages/Workshop/Icons';
import {
  resetTransactionsTasksSearchEffect,
  searchTransactionsTasksEffect,
  setTransactionsTasksIsArchiveEffect,
} from 'store/effects/taskAggregate';
import { resetTransactionEffect } from 'store/effects/transactions';
import { getTransactionsTasksIsArchiveSelector } from 'store/selectors/taskAggregate';
import { TaskModal } from '../../Transactions/TransactionTasks/components/TaskModal';
import { Filter } from '../../Transactions/TransactionTasks/components/Tasks/components/Actions/Filter';

import { Role } from 'settings/constants/roles';
import { getUserRoleSelector } from 'store/selectors/user';
import { TasksFilter } from '../TasksFilter';
import {
  getAgentTeamIsActiveSelector,
  getAgentTeamRoleSelector,
} from 'store/selectors/agentTeamDetail';
import { TEAM_OWNER, TEAM_ADMIN, TEAM_BASIC } from 'settings/constants/roles';
import { AGENT } from 'settings/constants/roles';

import styles from './styles.module.scss';

interface LocalHeaderProps {
  showSelection: boolean;
  setShowSelection: (showSelection: boolean) => void;
}

export const LocalHeader = ({ showSelection, setShowSelection }: LocalHeaderProps) => {
  const dispatch = useDispatch();

  const isArchive = useSelector(getTransactionsTasksIsArchiveSelector);
  const userRole = useSelector(getUserRoleSelector);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const [isMobile, setIsMobile] = useState(false);
  const [taskModal, setTaskModal] = useState(false);

  useEffect(() => {
    function handleWindowSizeChange() {
      setIsMobile(window.innerWidth <= 992);
    }
    if (document.readyState === 'complete') {
      handleWindowSizeChange();
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.addEventListener('load', handleWindowSizeChange);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isMobile]);

  const onArchiveModeChange = useCallback(() => {
    dispatch(setTransactionsTasksIsArchiveEffect(!isArchive));
  }, [dispatch, isArchive]);

  const onNew = () => {
    dispatch(resetTransactionEffect());
    setTaskModal(true);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    const ids = queryParams.get('ids');
    const taskNumbersArray: number[] = (ids || '')
      .split(',')
      .map(Number)
      .filter(Boolean)
      .filter((id) => !isNaN(id));
    const includeDoneTasks: boolean = taskNumbersArray.length > 0;
  }, []);

  const renderTeamFilters = () => {
    if (isTeamAgentActive && [TEAM_OWNER, TEAM_ADMIN, TEAM_BASIC].includes(agentRole)) {
      return <TasksFilter />;
    }
  };

  return (
    <div className={styles.workshopHeader}>
      <Row className={styles.workshopActionsContainer} align="middle" justify="space-between">
        <Col className={styles.workshopHeaderLeft} xs={24} lg={16}>
          <div className={styles.searchWrapper}>
            <SearchFieldWithDebounce
              className={styles.inputWrap}
              classNameInput={styles.searchInput}
              iconClassName={styles.icon}
              resetAction={resetTransactionsTasksSearchEffect}
              sendAction={searchTransactionsTasksEffect}
              isLoading={false}
              placeholder="Search"
              testid="search"
              isTransactionAggregate={true}
              isExpandedVersion
            />
          </div>
          <div className="seperator" />
          <div className={styles.filterWrapper}>
            {renderTeamFilters()}
            {userRole === Role.Agent && (
              <div
                className={classNames(styles.multiSelectBtn, { [styles.selected]: showSelection })}
                onClick={() => setShowSelection(true)}
              >
                <MultiSelect />
              </div>
            )}
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <div className={styles.actionsRight}>
            {showSelection ? (
              <Close className={styles.multiSelectBtn} onClick={() => setShowSelection(false)} />
            ) : (
              <>
                <div className="seperator" />
                {userRole === AGENT && (
                  <div
                    className={classNames(styles.btn, styles.newBtn, {
                      [styles.btnText]: !isMobile,
                    })}
                    onClick={onNew}
                  >
                    <Icons className={styles.addIcon} variant={Icons.ADD} />
                    {isMobile ? '' : <span className={styles.iconText}>New</span>}
                  </div>
                )}
                <Filter isAggregatedTasks />
                <div
                  className={classNames(styles.btn, { [styles.selected]: isArchive })}
                  onClick={onArchiveModeChange}
                >
                  <Icons variant={Icons.ARCHIVE} color={isArchive ? '#FFFFFF' : ''} />
                </div>
                <TaskModal
                  isOpen={taskModal}
                  onCloseModal={() => setTaskModal(false)}
                  isNew={true}
                  isAggregatedTasks
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
