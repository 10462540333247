import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import { ZoomIn, ZoomOut } from 'components/Icons';

import styles from './styles.module.scss';

type PrintPDFProps = {
  pdfDocuments: { documentBuffer: ArrayBuffer; pdfDoc: any }[];
  pageRender: (...params) => ReactElement;
  className?: string;
  classNamePdfDocument?: string;
  classNameDocumentPage?: string;
  handleScaleChange?: (scale) => void;
  children?: ReactNode;
  widgets?: ReactNode;
  currentPage?: number;
  currentDocument?: number;

  pdfLoading?: boolean;
  pdfDocRefs?: any;
  pdfDocAndPageRefs?: any;
  pdfPageClassName?: string;
  isEditor?: boolean;
  isDynamicView?: boolean;
};

const MAX_ZOOM_SCALE = 2.5;
const SCALE_STEP = 0.5;

export const DEFAULT_PDF_SCALE = 1.5;

export const PrintPDF = ({
  pdfDocuments,
  pageRender,
  className,
  classNamePdfDocument,
  classNameDocumentPage,
  handleScaleChange,
  children,
  widgets,

  pdfLoading,
  pdfDocRefs,
  pdfDocAndPageRefs,
  pdfPageClassName,
  isDynamicView,
}: PrintPDFProps) => {
  const [loader, setLoader] = useState(true);

  const [scale, setScale] = useState(DEFAULT_PDF_SCALE);

  const updateScale = (step) => {
    const newScale = scale + step * SCALE_STEP;
    if (newScale < DEFAULT_PDF_SCALE || newScale > MAX_ZOOM_SCALE) {
      return;
    }
    setScale(newScale);
    handleScaleChange?.(newScale);
  };

  const handleDocumentLoad = () => {
    setLoader(false);
  };

  const RenderPage = (page, pageIndex, documentIndex, pages, docZIndex) => {
    const size = page.getSize();

    const key = `pdf-page-${documentIndex}-${pageIndex}`;

    const pageZIndex = docZIndex !== -1 ? pages.length - pageIndex + docZIndex : -1;
    const pageStyle: any = {
      ...(isDynamicView && { zIndex: pageZIndex, position: 'relative' }),
    };

    return (
      <div
        key={key}
        id={key}
        className={classNameDocumentPage}
        ref={(el) => (pdfDocAndPageRefs ? (pdfDocAndPageRefs.current[key] = el) : undefined)}
        style={pageStyle}
      >
        <Page
          key={key}
          pageNumber={pageIndex + 1}
          renderInteractiveForms={false}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          scale={scale}
          className={pdfPageClassName}
        >
          <div
            className={styles.renderFieldsContainer}
            style={{
              width: size.width * scale,
              height: size.height * scale,
            }}
            key={`page-field-container-${documentIndex}-${pageIndex}`}
          >
            {pageRender({ pageIndex, documentIndex, scale })}
          </div>

          <div className={styles.blackWrapper}>
            <div>{`${pageIndex + 1} of ${pages.length}`}</div>
          </div>
        </Page>
      </div>
    );
  };

  const pdf = (
    <div className={classNames(styles.pdfContainer, className)}>
      {pdfDocuments && !pdfLoading ? (
        <>
          {pdfDocuments.map((doc, docIndex) => {
            const pages = (!loader && doc.pdfDoc?.getPages()) || [];

            const docZIndex = pdfDocuments.length - docIndex;

            return (
              <div
                key={docIndex}
                id={`pdf-doc-${docIndex}`}
                ref={(el) => (pdfDocRefs ? (pdfDocRefs.current[docIndex] = el) : undefined)}
                className={classNames(styles.pdfDocument, classNamePdfDocument)}
                style={isDynamicView ? { zIndex: docZIndex } : {}}
              >
                <Document
                  key={`pdf-doc-${docIndex}`}
                  file={doc.documentBuffer}
                  onLoadSuccess={handleDocumentLoad}
                >
                  {pages.map((page, pageIndex) =>
                    RenderPage(page, pageIndex, docIndex, pages, docZIndex),
                  )}

                  {docIndex === 0 ? children : <></>}
                </Document>
              </div>
            );
          })}

          {widgets}
          {!loader && (
            <PDFWidgets
              updateScale={updateScale}
              disableZoomIn={scale === MAX_ZOOM_SCALE}
              disableZoomOut={scale === DEFAULT_PDF_SCALE}
              className={isDynamicView ? styles.dynamicViewerZoomWidget : ''}
            />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );

  return <>{pdf}</>;
};

const PDFWidgets = ({ className, updateScale, disableZoomIn, disableZoomOut }) => {
  return (
    <div className={classNames(styles.zoomWidgets, className)}>
      <div
        className={classNames(styles.zoomWidget, { [styles.disabledZoomWidget]: disableZoomIn })}
        onClick={() => updateScale(1)}
      >
        <ZoomIn />
      </div>
      <div
        className={classNames(styles.zoomWidget, { [styles.disabledZoomWidget]: disableZoomOut })}
        onClick={() => !disableZoomOut && updateScale(-1)}
      >
        <ZoomOut />
      </div>
    </div>
  );
};
