import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Select, Autocomplete } from 'components';
import { getTeamTitlesEffect } from 'store/effects/teamList';
import { getAgentTeamIdSelector } from 'store/selectors/agentTeamDetail';

import styles from './styles.module.scss';

export const TitleInput = ({ values, setFieldValue }) => {
  const [teamTitles, setTeamTitles] = useState(null);
  const dispatch = useDispatch();
  const hasTeam = useSelector(getAgentTeamIdSelector);
  const fetchTeamTitles = () => {
    dispatch(
      getTeamTitlesEffect({}, {}, (err, resp) => {
        if (!err) setTeamTitles(resp.data);
      }),
    );
  };

  useEffect(() => {
    if (hasTeam) {
      fetchTeamTitles();
    }
  }, [hasTeam]);

  const titleOptions = useMemo(
    () =>
      (teamTitles || []).map(({ Title, Id }) => ({
        value: Id,
        name: Title,
        titleId: Id,
      })),
    [teamTitles],
  );

  const getOptions = () => {
    if (values?.titleFreeText) {
      return [
        ...titleOptions,
        { name: values.titleFreeText, value: values.titleFreeText, isNewTitle: true },
      ];
    }

    return titleOptions;
  };

  const getValue = () => {
    const options = getOptions() || [];
    if (values?.titleFreeText) {
      const option = options.find((opt) => opt?.value === values?.titleFreeText);

      if (option) {
        return option;
      }
    }

    if (values?.title || values?.titleId) {
      const option = options.find(
        (opt) =>
          opt?.value === values?.title ||
          opt?.value === values?.titleId ||
          opt?.name === values?.title ||
          opt?.name === values?.title?.name,
      );
      if (option) {
        return option;
      }
    }

    return '';
  };

  const renderOption = ({ optionProps, optionData, optionSnapshot }) => {
    const optProps = { ...optionProps, value: optionData.value, disabled: optionData.disabled };

    if (optionData?.isNewTitle) return null;

    return (
      <button
        type="button"
        className={classNames(styles.option, {
          [styles['is-highlighted']]: optionSnapshot.highlighted,
        })}
        {...optProps}
      >
        <div className={styles.optionInnerHolder}>
          <div>{optionData.name}</div>
        </div>
      </button>
    );
  };

  const setTitleFreeText = (val) => {
    if (val?.trim()) {
      setFieldValue('title', null);
      setFieldValue('titleFreeText', val);
    }
  };

  return (
    <div onBlur={(e) => setTitleFreeText(e.target.value)} className={styles.titleFreeText}>
      <Select
        label={'Title'}
        multiple={false}
        isSearchIcon={false}
        isTrackInputValue={false}
        search
        className={{
          wrapper: styles.search,
          value: styles.value,
        }}
        variant={Autocomplete.LIGHT}
        options={getOptions() || []}
        placeholder="Enter or select title"
        isEmptyValue
        onSelect={(e, val) => {
          setFieldValue('titleFreeText', '');
          setFieldValue('title', val);
        }}
        value={getValue()}
        renderOption={renderOption}
        arrowIconClassName={styles.arrowIcon}
        emptyMessage={() => null}
        isArrowIcon
      />
    </div>
  );
};
