import {
  deleteTimelineTemplate,
  getAllTimelineTemplates,
  getTimelineTemplateById,
} from 'api/templates';
import {
  requestDeleteTimelineTemplateDashboardAction,
  requestGetTimelineTemplateDashboardAction,
  requestGetTimelineTemplatesDashboardAction,
  searchTimelineTemplatesAction,
  sortTimelineTemplatesAction,
} from 'store/actions/adminPanel';
import Api from 'store/effects/core/api';
import { getState } from 'store/index';

export const getAllTimelineTemplatesDashboardEffect = (cfg, options, cb) => {
  const {
    adminPanel: {
      timelineTemplates: { sort },
    },
  } = getState();
  const requestParams = {
    action: requestGetTimelineTemplatesDashboardAction,
    method: getAllTimelineTemplates,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(
    {
      ...cfg,
      sortField: sort?.fields?.[0] || undefined,
      sortOrder: sort?.order || undefined,
    },
    options,
    cb,
  );
};

export const getTimelineTemplateByIdDashboardEffect = (cfg, options, cb) => {
  const requestParams = {
    action: requestGetTimelineTemplateDashboardAction,
    method: getTimelineTemplateById,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const sortTimelineTemplatesEffect = (cfg) => (dispatch) => {
  dispatch(sortTimelineTemplatesAction(cfg));
  return dispatch(getAllTimelineTemplatesDashboardEffect());
};

export const searchTimelineTemplatesEffect = (cfg) => (dispatch) => {
  dispatch(searchTimelineTemplatesAction(cfg));
  return dispatch(getAllTimelineTemplatesDashboardEffect());
};

export const getTimelineTemplateEffect = ({ id }, options, cb) => {
  const requestParams = {
    action: requestGetTimelineTemplateDashboardAction,
    method: getTimelineTemplateById,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest({ id }, options, cb);
};

export const deleteTimelineTemplateDashboardEffect = ({ id }, options, cb) => {
  const requestParams = {
    action: requestDeleteTimelineTemplateDashboardAction,
    method: deleteTimelineTemplate,
  };
  const sendRequest = Api.execBase(requestParams);

  return sendRequest({ id }, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllTimelineTemplatesDashboardEffect());
    }
    if (cb) cb(err, response, dispatch);
  });
};
