import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DocumentVaultImage, Wrapper as PendingWrapper } from 'components';

import styles from './styles.module.scss';

const Image = (props) => {
  const { className, image, isMessageSavedOnServer, onClick } = props;

  return (
    <div className={className}>
      <div onClick={onClick} className={classNames(styles.image)} testid="attachment_preview">
        <DocumentVaultImage
          url={image?.Url}
          data={image?.Data}
          contentType={image?.ContentType}
          documentVaultUUID={image?.DocumentVaultUUID}
        />
        <PendingWrapper
          className={styles.imagePendingWrapper}
          loaderClassName={styles.imageLoader}
          isPending={!isMessageSavedOnServer}
        />
      </div>
    </div>
  );
};

Image.propTypes = {
  className: PropTypes.string,
  image: PropTypes.shape({
    ContentType: PropTypes.string,
    Data: PropTypes.string,
    Url: PropTypes.string,
  }).isRequired,
  isMessageSavedOnServer: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

Image.defaultProps = {
  className: '',
  onClick: () => {},
};

export default Image;
