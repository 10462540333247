import { SVGProps } from 'react';

export const CCtag = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="9" stroke="#AAABAB" strokeWidth="2" />
    <path
      d="M10.5 11.144C10.5 8.91667 6.5 8.7114 6.5 12.0358C6.5 15.1546 10.5 15.1542 10.5 12.9262"
      stroke="#AAABAB"
      strokeWidth="2"
    />
    <path
      d="M17.5 11.144C17.5 8.91667 13.5 8.7114 13.5 12.0358C13.5 15.1546 17.5 15.1542 17.5 12.9262"
      stroke="#AAABAB"
      strokeWidth="2"
    />
  </svg>
);
