import { useState } from 'react';
import { orderBy } from 'lodash-es';
import { useDispatch } from 'react-redux';

import { getTimelineTemplateEffect } from 'store/effects';
import { TimelineMilestones, TimelineTemplate } from 'types';
import { getEmptyMilestone } from 'settings/constants/transaction';
import { EditTimeline } from 'components/Transactions';
import UploadTimelines from 'components/Transactions/UploadTimelines';
import { Form } from 'components-antd';
import { TemplatesModal } from '../../../TemplatesModal';
import { getTemplateButtons } from '../TemplateButtons';
import { AddNew, Timeline as TimelineIcon } from 'components/Icons';

import styles from './styles.module.scss';

interface TimeLineError {
  nameError?: string;
  dateError?: string;
}

export const TimelinePane = ({ isViewMode }) => {
  const [errors, setErrors] = useState<TimeLineError[]>([]);

  const validateMilestone = async (_, timeLine: TimelineTemplate) => {
    let containsError = false;
    const errors: TimeLineError[] = [];

    timeLine?.Milestones?.forEach((milestone) => {
      const error = {
        nameError: !milestone?.Title ? 'Required' : undefined,
      };

      if (error.nameError) {
        containsError = true;
      }

      errors.push(error);
    });

    if (!containsError) {
      setErrors([]);
      return Promise.resolve();
    } else {
      setErrors(errors);
      return Promise.reject(new Error(''));
    }
  };

  return (
    <div className={styles.timelinePaneContainer}>
      <Form.Item
        name="TimelineTemplate"
        validateTrigger={'onSubmit'}
        rules={[
          {
            validator: validateMilestone,
          },
        ]}
      >
        <Timeline errors={errors} isViewMode={isViewMode} />
      </Form.Item>
    </div>
  );
};

interface TimelineProps {
  onChange?: (e) => void;
  value?: TimelineTemplate;
  errors: TimeLineError[];
  isViewMode: boolean;
}

const Timeline = ({ onChange, value, errors, isViewMode }: TimelineProps) => {
  const template = value ?? { Milestones: [] };

  if (!template.Milestones) {
    template.Milestones = [];
  }

  const [timelineTemplateModal, setTimelineTemplateModal] = useState(false);
  const dispatch = useDispatch();

  const onUpdate = (timeline) => {
    if (onChange) onChange(timeline);
  };

  const updateMilestones = (milestone: TimelineMilestones[]) => {
    const timeline = { ...template };
    timeline.Milestones = milestone;

    onUpdate(timeline);
  };

  const onAdd = () => {
    if (isViewMode) return;
    const timeline = { ...template };

    updateMilestones([
      ...timeline.Milestones,
      getEmptyMilestone() as unknown as TimelineMilestones,
    ]);
  };

  const onRemove = (index) => {
    const timeline = { ...template };
    timeline.Milestones.splice(index, 1);
    updateMilestones([...timeline.Milestones]);
  };

  const onFieldsChange = (data, index) => {
    const timeline = { ...template };
    timeline.Milestones[index] = {
      ...timeline.Milestones[index],
      ...data,
    };
    updateMilestones([...timeline.Milestones]);
  };

  const onSelectTimelineTemplate = (templateId) => {
    dispatch(
      getTimelineTemplateEffect({ id: templateId }, {}, (err, res) => {
        if (!err) {
          const result = res?.data?.result;
          const name = result?.Name || null;
          const description = result?.Description || null;
          const milestones = result?.Milestones || [];
          const sortedMilestones = orderBy(milestones, 'Offset', ['asc']);
          const timeline = {
            Name: name,
            Description: description,
            Milestones: sortedMilestones.map((m) => ({
              Title: m.Title,
              DueDate: m.Offset,
              Initial: m.Initial || undefined,
              Operator: m.Operator,
              ControlOperatorOffset: m.ControlOperatorOffset,
            })),
          };
          setTimelineTemplateModal(false);
          updateMilestones([]);
          onUpdate(timeline);
        }
      }),
    );
  };

  return (
    <>
      <TemplatesModal
        isOpen={timelineTemplateModal}
        onCancel={() => setTimelineTemplateModal(false)}
        onSelect={onSelectTimelineTemplate}
      />
      {template?.Milestones?.length ? (
        <div className={styles.paneContainer}>
          <EditTimeline
            errors={errors}
            addText="Add milestone"
            listItems={template?.Milestones}
            inputPlaceholder="Milestone name..."
            classNamesConfig={{
              add: styles.addMilestone,
              timelineItem: styles.milestonePoint,
              timelineName: styles.timelineName,
              inputHolder: styles.inputHolder,
              deadline: styles.deadline,
              icon: styles.offsetIcon,
              numberInputClass: styles.numberInput,
              timelineNameInput: styles.timelineNameInput,
              datePickerInputClass: styles.datePickerInputClass,
              datePickerErrorClass: styles.datePickerErrorClass,
            }}
            className={styles.timeline}
            pointsClassName={styles.timelinePoints}
            hideAdd
            onRemove={onRemove}
            onFieldsChange={onFieldsChange}
            isTemplate
            allowFirstDelete
            isViewMode={isViewMode}
          />
        </div>
      ) : (
        <></>
      )}
      {!isViewMode && (
        <>
          {getTemplateButtons([
            {
              name: 'Add milestone',
              icon: <AddNew />,
              onClick: onAdd,
            },
            {
              name: 'Templates',
              icon: <TimelineIcon />,
              onClick: () => !isViewMode && setTimelineTemplateModal(true),
            },
          ])}
          <UploadTimelines updateMilestones={updateMilestones} isViewMode={isViewMode} />
        </>
      )}
    </>
  );
};
