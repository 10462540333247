import { numberCommaSeparator } from 'helpers/regex';

export const getFontSize = (elementTarget, fontSize, onInputChage = true, initialFontSize = 14) => {
  const { scrollWidth, clientWidth } = elementTarget;
  const isOverflown = scrollWidth > clientWidth;

  if (onInputChage) {
    if (isOverflown) {
      fontSize -= fontSize * 0.17;
    } else if (!elementTarget.value) {
      fontSize = initialFontSize;
    }
  } else if (isOverflown) {
    const increment = (scrollWidth / (clientWidth - 20)).toFixed(2);
    fontSize = fontSize / parseFloat(increment);
  }

  return fontSize;
};

export const modifyFontSizeHistory = (valueLength, fontSizeHistory, fontSize) => {
  let length = valueLength;
  const history = { ...fontSizeHistory };
  let historyFontSize = fontSize;

  while (length - 4 > 0) {
    historyFontSize += historyFontSize * 0.18;

    history[length - 4] = historyFontSize >= 14 ? 14 : historyFontSize;
    length -= 4;
  }

  return history;
};

const isDecimal = (value: string, fraction: string) =>
  value.length > 1 && value[value.length - 1] === '.' && fraction === '';

export const formatNumberValue = (value) => {
  const trimmedValue = value.split(',').join('').trim();
  const [integral, fraction, invalid] = trimmedValue.split('.');

  let formattedValue = '';
  if (invalid === '' || integral === '00') {
    if (integral !== '00') {
      const commaSeparated = integral.replace(numberCommaSeparator, ',');
      formattedValue = `${commaSeparated}${fraction ? `.${fraction}` : ''}`;
    }
    return formattedValue;
  } else if (value === '.') {
    formattedValue = '0.';
  } else if (value === '' || isDecimal(value, fraction)) {
    formattedValue = value;
  } else if (!Number.isNaN(Number(`${integral}.${fraction ?? '0'}`))) {
    const wholeNumber =
      integral.length > 1 && integral[0] === '0' ? integral.substring(1) : integral;

    formattedValue = `${wholeNumber ? wholeNumber.replace(numberCommaSeparator, ',') : '0'}${
      fraction ? '.' + fraction : ''
    }`;
  } else if (isNaN(value)) {
    return value?.substring(0, value.length - 1);
  }

  return formattedValue;
};
