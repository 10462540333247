import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const TitleWithButtons = ({ title, children, className }) => (
  <div className={classNames(styles.wrapper, className)}>
    <h2 className={styles.title}>{title}</h2>
    <div>{children}</div>
  </div>
);

TitleWithButtons.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

TitleWithButtons.defaultProps = {
  className: null,
  children: null,
};

export default TitleWithButtons;
