import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { getUserId } from 'store/selectors/user';
import Attachment from './Attachment';

import styles from './styles.module.scss';

const Documents = (props) => {
  const { className, value, taskId, onDelete } = props;
  const userId = useSelector(getUserId);

  return (
    <div className={classNames(styles.documents, className)}>
      <div>
        {(value || []).map((document, index) => (
          <Attachment
            key={document?.Id || index}
            DocumentVaultUUID={document?.DocumentVaultUUID}
            taskId={taskId}
            file={document}
            uploadedBy={document?.Uploader}
            deletable={!document.Category && document?.Uploader?.Id === userId}
            onDelete={(event) => onDelete(event, index)}
            isCompleted={!!document.Category}
          />
        ))}
      </div>
    </div>
  );
};

Documents.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDelete: PropTypes.func,
};

Documents.defaultProps = {
  className: '',
  value: [],
  taskId: null,
  onDelete: () => {},
};

export default Documents;
