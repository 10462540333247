import { createAction } from 'redux-actions';

export const requestQuoteFormAction = createAction('REQUEST/GET_QUOTE_BUILDER_FORM');
export const resetQuoteFormAction = createAction('RESET/QUOTE_BUILDER_FORM');
export const updateQuoteFormMetaAction = createAction('UPDATE/QUOTE_BUILDER_FORM_META');
export const updateQuoteFormQuestionAction = createAction('UPDATE/QUOTE_BUILDER_FORM_QUESTION');
export const requestUpdateQuoteFormAction = createAction('REQUEST/QUOTE_BUILDER_FORM');
export const requestNewQuoteFormVersionAction = createAction('REQUEST/QUOTE_NEW_VERSION');
export const requestCopyQuoteFormVersionAction = createAction('REQUEST/QUOTE_COPY_VERSION');
export const requestPublishQuoteFormVersionAction = createAction('REQUEST/QUOTE_PUBLISH_VERSION');
export const updateQuoteFormPreviewResponse = createAction('UPDATE/QUOTE_FORM_PREVIEW_RESPONSE');
