import { FC, useCallback } from 'react';
import { FormikProps, useFormikContext } from 'formik';

import { readFiles } from 'helpers';
import DropzoneWithFileInput from 'components/DropzoneWithFileInput';
import Attachment from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/UploadFiles/Attachment';

interface Props {
  isSubmitted: boolean;
  index: number;
}
export const AddDocumentsSection: FC<Props> = ({ isSubmitted, index }) => {
  const { values, setFieldValue, errors }: FormikProps<any> = useFormikContext();
  const formErrors = errors?.['preApprovalsDocuments']?.[index ?? 0];
  const formValues = values?.['preApprovalsDocuments']?.[index ?? 0];
  const nameSpace = `preApprovalsDocuments.${index}.`;

  const handleDropFiles = (acceptedFiles) => {
    readFiles(acceptedFiles).then((files) => {
      const { contentType: ContentType, data, filename: Filename, size: Size } = files[0];
      const Data = btoa(data);
      setFieldValue(nameSpace + 'PreApprovalDocuments', [
        { ContentType, Data, Filename, Size, filename: files[0].filename },
      ]);
    });
  };

  const handleChangeFiles = (event) => {
    readFiles(event.target.files).then((files) => {
      const { contentType: ContentType, data, filename: Filename, size: Size } = files[0];
      const Data = btoa(data);
      setFieldValue(nameSpace + 'PreApprovalDocuments', [
        { ContentType, Data, Filename, Size, filename: files[0].filename },
      ]);
    });
  };

  const handleRemoveAttachment = useCallback(() => {
    setFieldValue(nameSpace + 'PreApprovalDocuments', []);
  }, [formValues.PreApprovalDocuments]);

  return (
    <div>
      <DropzoneWithFileInput
        onDrop={handleDropFiles}
        onChange={handleChangeFiles}
        error={isSubmitted ? (formErrors?.PreApprovalDocuments as string) : ''}
      />
      <div>
        {formValues.PreApprovalDocuments.map((file, index) => (
          <Attachment key={file?.id || index} onRemove={() => handleRemoveAttachment()} {...file} />
        ))}
      </div>
    </div>
  );
};
