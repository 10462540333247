import { handleActions } from 'redux-actions';
import { cloneDeep } from 'lodash-es';
import {
  cleanFeedDrawnPolygonAction,
  setFeedDrawnPolygonAction,
  setFeedPrefsAction,
  setFeedViewportCoordinatesAction,
  cleanFeedViewportCoordinatesAction,
} from 'store/actions/feed';
import { appLogoutAction } from 'store/actions/app';
import { LocationType } from 'types';
const initialData = {
  filters: null,
};

export default handleActions(
  {
    [setFeedPrefsAction]: (state, { payload }) => {
      return {
        filters: payload,
      };
    },
    [cleanFeedDrawnPolygonAction]: (state, { payload }) => {
      const locations = state.filters?.Locations;
      if (!locations) {
        return state;
      }
      const locationsWithoutPolygon = locations.filter(
        (location) => location.Type !== LocationType.Polygon,
      );

      return {
        ...state,
        filters: {
          ...state.filters,
          Locations: locationsWithoutPolygon.length ? locationsWithoutPolygon : undefined,
        },
      };
    },
    [setFeedDrawnPolygonAction]: (state, { payload }) => {
      const polygonLocationObject = {
        Type: LocationType.Polygon,
        Polygon: payload,
      };

      const locations = state.filters?.Locations;
      const locationsWithoutPolygon =
        locations?.filter((location) => location.Type !== LocationType.Polygon) ?? [];

      const newLocationsList = locationsWithoutPolygon
        ? [...locationsWithoutPolygon, polygonLocationObject]
        : [polygonLocationObject];

      return {
        ...state,
        filters: {
          ...state.filters,
          Locations: newLocationsList,
        },
      };
    },
    [cleanFeedViewportCoordinatesAction]: (state, { payload }) => {
      const locations = state.filters?.Locations;
      if (!locations) {
        return state;
      }
      const locationsWithoutViewport = locations.filter(
        (location) => location.Type !== LocationType.ViewportCoordinates,
      );

      return {
        ...state,
        filters: {
          ...state.filters,
          Locations: locationsWithoutViewport.length ? locationsWithoutViewport : undefined,
        },
      };
    },
    [setFeedViewportCoordinatesAction]: (state, { payload }) => {
      const viewPortCoordinatesObject = {
        Type: LocationType.ViewportCoordinates,
        Coordinates: payload,
      };

      const locations = state.filters?.Locations;
      const locationsWithoutViewport = locations?.filter(
        (location) => location.Type !== LocationType.ViewportCoordinates,
      );

      const newLocationsList = locationsWithoutViewport
        ? [...locationsWithoutViewport, viewPortCoordinatesObject]
        : [viewPortCoordinatesObject];

      return {
        ...state,
        filters: {
          ...state.filters,
          Locations: newLocationsList,
        },
      };
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
