import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  appManageClientDrawerAction,
  appSetClientDrawerActiveTab,
  appSetClientsSearchValueAction,
} from 'store/actions/app';
import { setClientsListStatusAction } from 'store/actions/clientsList';
import { appSetConnectionTypeEffect } from 'store/effects';
import { Clients, Collaborators, Team, Close } from 'components/Icons';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';
import { DrawerHeader } from 'components';
import { getConnectionTypeSelector } from 'store/selectors/app';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_OWNER, TEAM_ADMIN } from 'settings/constants/roles';
import { getAgentTypeSelector } from 'store/selectors/user';
import { AGENT_TYPE } from 'settings/constants/drawers';
import { AGENT_CONNECTION_STATUSES } from 'settings/constants/common';

import styles from './styles.module.scss';

const ClientHeader = ({ setShowInviteConnection }) => {
  const dispatch = useDispatch();
  const connectionType = useSelector(getConnectionTypeSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const titleOptions = [
    { label: AGENT_CONNECTIONS.Clients, IconComponent: Clients },
    { label: AGENT_CONNECTIONS.Collaborators, IconComponent: Collaborators },
    ...(agentType === AGENT_TYPE.Team
      ? [{ label: AGENT_CONNECTIONS.Team, IconComponent: Team }]
      : []),
  ];

  const handleClose = () => {
    dispatch(appManageClientDrawerAction(false));
    dispatch(appSetConnectionTypeEffect({ type: AGENT_CONNECTIONS.Clients }));
    dispatch(appSetClientsSearchValueAction(''));
    dispatch(appSetClientDrawerActiveTab(0));
  };

  const handleInvite = () => {
    setShowInviteConnection(true, connectionType);
    handleClose();
  };

  const onChange = (label) => {
    dispatch(appSetConnectionTypeEffect({ type: label }));
    dispatch(appSetClientDrawerActiveTab(0));
    dispatch(setClientsListStatusAction(AGENT_CONNECTION_STATUSES.ACTIVE));
  };

  return (
    <DrawerHeader
      titleOptions={titleOptions}
      onChange={onChange}
      TitleIcon={<Clients color="pink" />}
      ButtonIcon={
        connectionType === AGENT_CONNECTIONS.Clients ||
        (connectionType === AGENT_CONNECTIONS.Team &&
          [TEAM_OWNER, TEAM_ADMIN].includes(agentRole)) ? (
          <div testid="invite_client" className={styles.inviteClientButton} onClick={handleInvite}>
            <span>Invite</span>
          </div>
        ) : null
      }
      CloseIcon={
        <Close
          testid="main_button"
          className={styles.closeClientDrawerIcon}
          type="circle"
          onClick={handleClose}
        />
      }
      testid="clients_header"
    />
  );
};

ClientHeader.propTypes = {
  setShowInviteConnection: PropTypes.func,
};

ClientHeader.defaultProps = {
  setShowInviteConnection: () => {},
};

export default ClientHeader;
