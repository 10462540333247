import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Sidebar, Skeleton, TabPane, Tabs } from 'components-antd';
import {
  SmartSettingPane,
  SmartJumpLogicPane,
  SmartGroupingPane,
  SmartFieldPane,
} from 'pages/FormBuilder/components';
import {
  getSmartFormCurrentQuestionSelect,
  getSmartFormMetaSelect,
} from 'store/selectors/formBuilder';
import { PENDING } from 'settings/constants/apiState';
import { FORM_QUESTION_TYPE } from 'app-constants';
import { ListBoxType } from 'types';
import { SmartDeleteQuestion } from 'pages/FormBuilder/components/SmartDeleteQuestion';

import styles from './styles.module.scss';

export const SmartRightSidebar = () => {
  const { state, disabled } = useSelector(getSmartFormMetaSelect);
  const question = useSelector(getSmartFormCurrentQuestionSelect);

  const [hidden, setHidden] = useState<boolean>();

  const [hideLogic, setHideLogic] = useState(false);

  useEffect(() => {
    if (question) {
      const { Type, Meta } = question;
      if (Type) {
        setHidden(false);
      }
      if (Type === FORM_QUESTION_TYPE.ListBox && !(Meta as ListBoxType)?.MultiSelect) {
        setHideLogic(false);
      } else if ((Meta as ListBoxType)?.MultiSelect) {
        setHideLogic(true);
      } else {
        setHideLogic(true);
      }
    } else {
      setHidden(true);
    }
  }, [question]);

  return (
    <Sidebar width={300} className={styles.quoteRightSidebar}>
      <Skeleton showSkeleton={state === PENDING} rowCount={10}>
        {question?.FieldType ? (
          <div className={styles.sidebarContainer} data-testid="quote-right-sidebar">
            {!hidden && (
              <Tabs defaultActiveKey="1" className={styles.settingTabs}>
                <TabPane tab="Settings" key="1" data-testid="quote-setting-pane">
                  <SmartSettingPane />
                </TabPane>
                {!hideLogic && (
                  <TabPane tab="Logic" key="2" data-testid="quote-logic-pane">
                    <SmartJumpLogicPane />
                  </TabPane>
                )}
                {question?.Fields?.length ? (
                  <TabPane tab="Fields" key="3" data-testid="quote-fields-pane">
                    <SmartFieldPane />
                  </TabPane>
                ) : (
                  <></>
                )}
                <TabPane tab="Grouping" key="4" data-testid="quote-grouping-pane">
                  <SmartGroupingPane />
                </TabPane>
              </Tabs>
            )}
          </div>
        ) : (
          <div className={styles.noQuestionSelected}>Select a question to modify its settings</div>
        )}
        {!hidden && !disabled && <SmartDeleteQuestion />}
      </Skeleton>
    </Sidebar>
  );
};
