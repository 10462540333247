import React from 'react';
import { TitleValueTable } from 'components';
import moment from 'moment';
import { formatPrice, getLocaleDate } from 'helpers';
import NumberFormat from 'react-number-format';
import styles from './styles.module.scss';

export const ParentOffer = ({ iterationData }) => {
  return (
    <div className={styles.parentOffer}>
      <p className={styles.title}>
        {iterationData.IterationCount > 0 ? `Counter Offer #` : `Offer #`}
        {iterationData?.OfferCount}
        {iterationData.IterationCount > 0 ? `- ${iterationData.IterationCount}` : ''}
      </p>
      <div className={styles.content}>
        <div className={styles.subHeading}>
          Purchase Price
          <span>
            {iterationData?.PurchasePrice ? `$${formatPrice(iterationData?.PurchasePrice)}` : '-'}
          </span>
        </div>
        <div className={styles.subHeading}>
          Down Payment
          <span>
            {iterationData?.DownPayment ? `$${formatPrice(iterationData?.DownPayment)}` : '-'}
          </span>
        </div>
        <div className={styles.subHeading}>
          Earnest Money Deposit
          <span>
            {iterationData?.EarnestMoneyDeposit
              ? `$${formatPrice(iterationData?.EarnestMoneyDeposit)}`
              : '-'}
          </span>
        </div>
        <div className={styles.subHeading}>
          Closing Date
          <span>
            {iterationData.ClosingDate
              ? moment(getLocaleDate(iterationData.ClosingDate)).format('MM/DD/YYYY')
              : '-'}
          </span>
        </div>
        <div className={styles.subHeading}>
          Financing Type
          <span>{iterationData.FinancingType || '-'}</span>
        </div>
        <div className={styles.subHeading}>
          Termite Inspection
          <span>
            {iterationData.TermiteInspection && iterationData.TermiteInspectionResponsibility
              ? 'Yes (Responsible Party: ' + iterationData.TermiteInspectionResponsibility + ')'
              : 'No'}
          </span>
        </div>
        <div className={styles.subHeading}>
          Home Warranty
          <span>
            {iterationData.HomeWarranty && iterationData.HomeWarrantyCost !== null
              ? 'Yes ($' +
                iterationData.HomeWarrantyCost +
                ' / Responsible Party: ' +
                iterationData.HomeWarrantyResponsibility +
                ')'
              : 'No'}
          </span>
        </div>
        <div className={styles.subHeading}>
          Appliances
          <span>{iterationData.Appliances ? iterationData.Appliances : '-'}</span>
        </div>
        <div className={styles.subHeading}>
          Post-Closing Occupancy/SIP
          <span>
            {iterationData.Occupancy && iterationData.OccupancyDays !== null
              ? 'Yes (' + iterationData.OccupancyDays + ' Days)'
              : 'No'}
          </span>
        </div>
      </div>
    </div>
  );
};
