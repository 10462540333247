export const splitFirstOccurrence = (
  str: string,
  separator: string,
): { first: string; remainder: string } => {
  const [first, ...rest] = (str || '').split(separator);
  const remainder = rest.join(' ');
  return { first, remainder };
};

export const getFirstNameLastInitial = (name?: string) => {
  const { first, remainder } = splitFirstOccurrence(name || '', ' ');
  return `${first}${remainder ? ` ${remainder[0]}` : ''}`;
};

export const sortNamesAlphabetically = (names: string[]) =>
  names.slice().sort((a, b) => a.localeCompare(b));

export const getShowingIdAndTypeFromUrl = () => {
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const showingId = queryParams.get('showing');
  const type = queryParams.get('type');
  return { showingId, type };
};
