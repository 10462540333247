import React, { memo, useMemo } from 'react';

const HouseTransaction = memo(({ className }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.75 9.75V21.75H20.25V9.75"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.25 10.5L12 3L16.4297 6.40743M21.75 10.5L19.9751 9.1347M16.4297 6.40743V3H19.9751V9.1347M16.4297 6.40743L19.9751 9.1347"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.25 12H15.75M15.75 12L13.875 10.125M15.75 12L13.875 13.875"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.75 16.5H8.25M8.25 16.5L10.125 14.625M8.25 16.5L10.125 18.375"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

export default HouseTransaction;
