import React from 'react';
import PropTypes from 'prop-types';

import GroupBy from '../GroupBy';

import styles from './styles.module.scss';

const Header = ({ title, seatLogsGroupBy, setSeatLogsGroupBy }) => (
  <div testid="header" className={styles.header}>
    <div>{title}</div>
    <div className={styles.action}>
      <GroupBy seatLogsGroupBy={seatLogsGroupBy} setSeatLogsGroupBy={setSeatLogsGroupBy} />
    </div>
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  seatLogsGroupBy: PropTypes.string.isRequired,
  setSeatLogsGroupBy: PropTypes.func,
};

Header.defaultProps = {
  title: '',
  seatLogsGroupBy: '',
  setSeatLogsGroupBy: () => {},
};

export default Header;
