export const priceConverter = (price, decimalPlaces = 1) => {
  const convertedPrice = (value, unit = '') => {
    return `${value > -1 ? '' : '-'}$${parseFloat(Math.abs(value).toFixed(decimalPlaces))}${unit}`;
  };

  const val = parseFloat(price);
  const K = 1000;
  const M = 1000000;
  const B = 1000000000;
  const T = 1000000000000;
  const P = 1000000000000000;
  const E = 1000000000000000000;

  if (val >= K && val < M) return convertedPrice(val / K, 'K');
  else if (val >= M && val < B) return convertedPrice(val / M, 'M');
  else if (val >= B && val < T) return convertedPrice(val / B, 'B');
  else if (val >= T && val < P) return convertedPrice(val / T, 'T');
  else if (val >= P && val < E) return convertedPrice(val / P, 'P');
  else if (val >= E) return convertedPrice(val / E, 'E');
  return convertedPrice(val);
};
