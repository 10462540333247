import { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { LocationService } from 'services';

import Tab from './Tab';
import Content from './Content';
import { Select, Option } from 'components-antd';
import { Checkmark } from 'components/Icons';

import styles from './styles.module.scss';

const locationSrv = new LocationService();

const Tabs = (props) => {
  const {
    tabs,
    animation,
    enableQueryParams,
    activeTabIndex,
    wrapperClassName,
    direction,
    showSelectDropdown,
    placement,
    actions,
    onChange,
    ...rest
  } = props;
  const {
    queryParamName,
    tabsClassName,
    tabClassName,
    selectClassName,
    dropdownClassName,
    showMenuItemSelectedIcon,
    contentClassName,
    activeTabClassName,
    getActiveTab,
    contentWrapperClassName,
    ...passedProps
  } = rest;
  const { location, history } = passedProps;

  locationSrv.setLocation(location);

  const [activeTab, setActiveTab] = useState(
    enableQueryParams
      ? locationSrv.getQuery()[queryParamName] - 1 || activeTabIndex
      : activeTabIndex,
  );

  useEffect(() => {
    const activeTabObj = tabs?.[activeTab];
    getActiveTab(activeTab, activeTabObj);
  }, [activeTab, getActiveTab, tabs]);

  useEffect(() => {
    setActiveTab(
      enableQueryParams
        ? locationSrv.getQuery()[queryParamName] - 1 || activeTabIndex
        : activeTabIndex,
    );
  }, [activeTabIndex, location]);

  const onClick = useCallback(
    (index) => {
      if (enableQueryParams) {
        history.replace(locationSrv.setQuery({ [queryParamName]: index + 1 }));
        setActiveTab(index);
      } else {
        onChange(index);
        setActiveTab(index);
      }
    },
    [enableQueryParams, history, queryParamName],
  );

  const getIsActiveTab = (index) => {
    if (enableQueryParams) {
      const queryActiveTab = locationSrv.getQuery(location)[queryParamName];
      return (queryActiveTab ? queryActiveTab - 1 : activeTabIndex) === index;
    }

    return activeTab === index;
  };

  const renderMenu = () => {
    return showSelectDropdown ? renderSelectTabs() : renderTabs();
  };

  const renderSelectTabs = () => (
    <>
      <Select
        size={'large'}
        value={activeTab}
        className={classNames(selectClassName, 'mosaikDropdown')}
        popupClassName={classNames(dropdownClassName, 'mosaikDropdownPopover withCheck')}
        onChange={(index) => {
          onClick(index);
          onChange(index);
        }}
        placement={placement}
        dropdownIconColor={'#262626'}
        menuItemSelectedIcon={
          showMenuItemSelectedIcon ? (
            <Checkmark className={styles.iconCheckmark} color="#262626" />
          ) : null
        }
        getPopupContainer={(triggerNode) => triggerNode}
        listHeight={464}
      >
        {(tabs || []).map(({ label }, index) => (
          <Option key={index} value={index}>
            {label}
          </Option>
        ))}
      </Select>
      {actions()}
    </>
  );

  const renderTabs = () =>
    (tabs || []).map(({ label, Icon, testid }, index) => (
      <Tab
        key={label}
        label={label}
        onClick={() => onClick(index)}
        isActive={getIsActiveTab(index)}
        direction={direction}
        activeTabClassName={activeTabClassName}
        className={tabClassName}
        icon={Icon}
        testid={testid}
      />
    ));

  const renderContent = () =>
    (tabs || []).map(({ id, label, Component, testid }, index) => (
      <Content
        className={contentClassName}
        key={label}
        animation={animation}
        isActive={getIsActiveTab(index)}
        direction={direction}
        testid={testid && `${testid}_content`}
      >
        <Component tabLabel={label} tabId={id} {...passedProps} />
      </Content>
    ));

  return (
    <div className={classNames(styles.wrapper, styles[direction], wrapperClassName)}>
      <div className={classNames(styles.tabs, tabsClassName)}>{renderMenu()}</div>
      <div
        className={classNames(styles.contentWrapper, contentWrapperClassName)}
        id="contentWrapper"
      >
        {renderContent()}
      </div>
    </div>
  );
};

Tabs.DEFAULT = 'default';
Tabs.FADE_IN = 'fadeIn';
Tabs.SLIDE_IN_BOTTOM = 'slideInBottom';
Tabs.SLIDE_IN_LEFT = 'slideInLeft';
Tabs.SLIDE_IN_RIGHT = 'slideInRight';

Tabs.DIRECTION_HORIZONTAL = 'horizontal';
Tabs.DIRECTION_VERTICAL = 'vertical';

Tabs.propTypes = {
  wrapperClassName: PropTypes.string,
  tabsClassName: PropTypes.string,
  tabClassName: PropTypes.string,
  selectClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  showMenuItemSelectedIcon: PropTypes.bool,
  contentClassName: PropTypes.string,
  activeTabClassName: PropTypes.string,
  contentWrapperClassName: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    }),
  ).isRequired,
  animation: PropTypes.string,
  enableQueryParams: PropTypes.bool,
  activeTabIndex: PropTypes.number,
  direction: PropTypes.string,
  queryParamName: PropTypes.string,
  getActiveTab: PropTypes.func,
  showSelectDropdown: PropTypes.bool,
  actions: PropTypes.func,
  isPending: PropTypes.bool,
  onChange: PropTypes.func,
};

Tabs.defaultProps = {
  wrapperClassName: '',
  tabsClassName: '',
  tabClassName: '',
  selectClassName: '',
  dropdownClassName: '',
  showMenuItemSelectedIcon: false,
  contentClassName: '',
  activeTabClassName: '',
  contentWrapperClassName: '',
  animation: Tabs.FADE_IN,
  enableQueryParams: false,
  activeTabIndex: 0,
  direction: Tabs.DIRECTION_HORIZONTAL,
  queryParamName: 'activeTab',
  getActiveTab: () => {},
  showSelectDropdown: false,
  placement: 'bottomLeft',
  actions: () => {},
  isPending: false,
  onChange: (tabIndex) => {},
};

export default withRouter(Tabs);
