import {
  getPartnerById,
  getPartners,
  postPartnerData,
  putActivatePartner,
  putDeactivatePartner,
  putPartnerData,
  getSendInviteById,
  postPartnerDatabyAgent,
  postPartnerDataFromThirdParty,
} from 'api/admin';
import {
  requestDeleteDeactivatePartnerAction,
  requestGetPartnerByIdAction,
  requestGetPartnersAction,
  requestPostPartnerAction,
  requestPutActivatePartnerAction,
  requestUpdatePartnerAction,
  searchPartnersAction,
  sortPartnersAction,
  requestSendInviteAction,
} from 'store/actions/adminPanel';
import {
  getAgentPartnerSendData,
  getAllItems,
  getItemById,
  getPartnerSendData,
} from 'store/effects/adminPanel/helpers';
import Api from 'store/effects/core/api';
import { getState } from 'store/index';

export const getPartnersEffect = (cfg = {}, options = {}, cb) => {
  const {
    adminPanel: {
      serviceDirectory: { partners },
    },
  } = getState();
  const { sort, search } = partners;
  const searchQuery = {
    ...search,
  };

  if (searchQuery?.categories?.length) {
    searchQuery.categories = searchQuery.categories.map(({ value }) => value.toString());
  }

  return getAllItems(requestGetPartnersAction, getPartners, options, cb, sort, cfg, searchQuery);
};

export const requestGetPartnerByIdEffect = (cfg, cb) =>
  getItemById(requestGetPartnerByIdAction, getPartnerById, cfg, {}, cb);

export const sortPartnersEffect = (cfg) => (dispatch) => {
  dispatch(sortPartnersAction(cfg));
  return dispatch(getPartnersEffect());
};

export const searchPartnersEffect = (cfg) => (dispatch) => {
  dispatch(searchPartnersAction(cfg));
  return dispatch(getPartnersEffect());
};

export const updatePartnerEffect = ({ values, id }, cb) => {
  const sendRequest = Api.execBase({ action: requestUpdatePartnerAction, method: putPartnerData });
  const sendValues = getPartnerSendData(values, true);

  return sendRequest(sendValues, { id }, (err, response, dispatch) => {
    if (!err) {
      dispatch(requestGetPartnerByIdEffect({ id }));
      dispatch(getPartnersEffect());
    }
    cb(err, response, dispatch);
  });
};

export const createPartnerEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({ action: requestPostPartnerAction, method: postPartnerData });

  const sendValues = getPartnerSendData(cfg);

  return sendRequest(sendValues, {}, (err, response, dispatch) => {
    if (!err) {
      dispatch(getPartnersEffect());
    }
    cb(err, response, dispatch);
  });
};

export const createPartnerByAgentEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestPostPartnerAction,
    method: postPartnerDatabyAgent,
  });
  const sendValues = getAgentPartnerSendData(cfg);

  return sendRequest(sendValues, {}, (err, response, dispatch) => {
    if (!err) {
      dispatch(getPartnersEffect());
    }
    cb(err, response, dispatch);
  });
};
export const createPartnerFromThirdPartyEffect = ({ values, id }, cb) => {
  const sendRequest = Api.execBase({
    action: requestPostPartnerAction,
    method: postPartnerDataFromThirdParty,
  });
  const sendValues = getPartnerSendData(values);
  return sendRequest(sendValues, { id }, (err, response, dispatch) => {
    cb(err, response, dispatch);
  });
};

export const deactivatePartnerEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestDeleteDeactivatePartnerAction,
    method: putDeactivatePartner,
  });

  return sendRequest(cfg, options, (err, response, dispatch) => cb(err, response, dispatch));
};

export const activatePartnerEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestPutActivatePartnerAction,
    method: putActivatePartner,
  });

  return sendRequest(cfg, options, (err, response, dispatch) => cb(err, response, dispatch));
};
export const sendInviteEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestSendInviteAction,
    method: getSendInviteById,
  });

  return sendRequest(cfg, options, (err, response, dispatch) => cb(err, response, dispatch));
};
