import { useDrop } from 'react-dnd';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';

import { FieldRenderer } from '..';
import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import {
  getDynamicFormEditorConfigSelector,
  getDynamicFormScaleSelector,
  getDynamicFormSelector,
} from 'store/selectors/requestFormProcess';
import {
  selectDynamicFormFieldEffect,
  updateDynamicFormQuestionEffect,
} from 'store/effects/formProcess';

import styles from './styles.module.scss';
import { DynamicFormEditorQuestion } from 'types';
import {
  INITIALS_MIN_HEIGHT,
  INITIALS_MIN_WIDTH,
  SIGNATURE_MIN_HEIGHT,
  SIGNATURE_MIN_WIDTH,
} from '../FieldRenderer/helper';

export interface PDFEditorPageProps {
  pageIndex: number;
  documentIndex?: number;
  scale: number;
}

export const getDefaultFieldSize = (type, scale) => {
  let width, height;

  if (type === DYNAMIC_QUESTION_TYPE.Signature) {
    width = SIGNATURE_MIN_WIDTH;
    height = SIGNATURE_MIN_HEIGHT;
  } else if (type === DYNAMIC_QUESTION_TYPE.Initials) {
    width = INITIALS_MIN_WIDTH;
    height = INITIALS_MIN_HEIGHT;
  } else if (type === DYNAMIC_QUESTION_TYPE.CheckBox) {
    width = 21;
    height = 21;
  } else if (type === DYNAMIC_QUESTION_TYPE.FullName) {
    width = SIGNATURE_MIN_WIDTH;
    height = 20;
  } else if (type === DYNAMIC_QUESTION_TYPE.DateSigned) {
    width = 106.67;
    height = 20;
  } else if (type === DYNAMIC_QUESTION_TYPE.Date) {
    width = 106.67;
    height = 20;
  } else if (type === DYNAMIC_QUESTION_TYPE.Address) {
    width = 160;
    height = 20;
  } else if (type === DYNAMIC_QUESTION_TYPE.StrikeThrough) {
    width = 160;
    height = 0;
  } else {
    width = 106.67;
    height = 20;
  }

  return {
    width: width * scale,
    height: height * scale,
  };
};

let listenerAttached = false;

export const EditorPage = ({ pageIndex, documentIndex = -1, scale }: PDFEditorPageProps) => {
  const ref = useRef<HTMLSpanElement>(null);

  const dispatch = useDispatch();

  const {
    selectedRole,
    questions: formQuestions,
    formRoles,
  } = useSelector(getDynamicFormEditorConfigSelector);
  const { bundleDocumentsDetails = [] } = useSelector(getDynamicFormSelector);

  const isCohesiveFlow = bundleDocumentsDetails.length > 0 && documentIndex >= 0;

  const questions = isCohesiveFlow
    ? bundleDocumentsDetails?.[documentIndex]?.questions || {}
    : formQuestions;

  const getFields = () => {
    let fieldsData: DynamicFormEditorQuestion[] = [];

    formRoles.forEach((role) => {
      const documentQuestions = questions;

      const roleQuestions = documentQuestions?.[pageIndex]?.[role.roleId]?.map((item, index) => ({
        ...item,
        customRole: role,
        customIndex: index,
      }));

      fieldsData = [...fieldsData, ...(roleQuestions || [])];
    });

    return fieldsData;
  };

  useEffect(() => {
    if (listenerAttached) return;

    const eventName = 'click';

    const clickHandler = (event) => {
      const target = event.target;

      const fields = document.getElementsByName('Form-Field');
      const sidebar = document.getElementById('Right-Sidebar');

      let outsideClicked = true;

      fields.forEach((field) => {
        if (
          target === field ||
          field.contains(target) ||
          target === sidebar ||
          sidebar?.contains(target)
        ) {
          outsideClicked = false;
        }
      });

      if (outsideClicked) {
        dispatch(selectDynamicFormFieldEffect({}));
      }
    };

    document.addEventListener(eventName, clickHandler);

    listenerAttached = true;

    return () => {
      document.removeEventListener(eventName, clickHandler);
      listenerAttached = false;
    };
  }, []);

  const [{ isOver }, dropRef] = useDrop({
    accept: Object.values(DYNAMIC_QUESTION_TYPE),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item, monitor) => {
      const clientOffset = monitor.getClientOffset();

      if (ref.current && clientOffset) {
        const dropTargetXy = ref.current.getBoundingClientRect();

        const type: DYNAMIC_QUESTION_TYPE = (item as any).type;
        const { width, height } = getDefaultFieldSize(type, scale);

        const x = (clientOffset.x - dropTargetXy.left - width / 2) / scale;
        const y = (clientOffset.y - dropTargetXy.top - height / 2) / scale;

        const fieldX = x * scale;
        const fieldY = y * scale;

        const isOverflowX = fieldX < 0 || width + fieldX > dropTargetXy.width;
        const isOverflowY = fieldY < 0 || height + fieldY > dropTargetXy.height;

        if (isOverflowX || isOverflowY) return;

        const newQuestion = {
          roleName: selectedRole.roleId,
          pageNumber: pageIndex,
          documentIndex,
          isCohesiveFlow,
          isNew: true,
          UUID: uuidv4(),
          type: type,
          width: width / scale,
          height: height / scale,
          x,
          y,
          required:
            type === DYNAMIC_QUESTION_TYPE.Signature || type === DYNAMIC_QUESTION_TYPE.Initials,
          ...(type === DYNAMIC_QUESTION_TYPE.StrikeThrough && {
            strokeWidth: 2,
            strokeColor: { r: 0, g: 0, b: 0, a: 1 },
          }),
        };

        dispatch(updateDynamicFormQuestionEffect(newQuestion));
      }
    },
  });

  return (
    <div className={styles.pageContainer} ref={dropRef}>
      <span className={styles.pageContainerDrop} ref={ref}>
        {getFields().map((field, index) => {
          return (
            <FieldRenderer
              field={field}
              key={`field-render-${field.UUID}-${index}`}
              index={field.customRole ? field.customIndex : index}
              documentIndex={documentIndex}
              isCohesiveFlow={isCohesiveFlow}
              scale={scale}
            />
          );
        })}
      </span>
    </div>
  );
};
