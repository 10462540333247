import { getIntent, loadTemplate, sendMessageToLLM02 } from 'api/mozzie';
import { ERROR_TEMPLATES } from 'settings/constants/mozzie';

export const getIntentFromLLM = async (userId, message) => {
  const payload = {
    uid: userId,
    message: message,
    stream: false,
  };

  try {
    const intentResp = await getIntent(payload);
    console.log('Intent resp => ', intentResp);
    const { data } = intentResp;
    if (!data.type || data?.type === 'API') {
      return '';
    }

    return data?.model_ouput?.intent ?? '';
  } catch (err) {
    console.log('Error is => ', err);
    return '';
  }
};

export const loadLlmTemplate = async (id, templateName) => {
  const payload = {
    uid: id,
    templateName: templateName,
    stream: false,
  };

  try {
    const loadTemplateResp = await loadTemplate(payload);
    console.log('Load template response => ', loadTemplateResp);
    const { data } = loadTemplateResp;
    return data ?? '';
  } catch (err) {
    console.log('Error in template => ', err);
    return '';
  }
};

export const loadTransactionTemplateWithRepresentation = async (id, templateName, rep) => {
  const payload = {
    uid: id,
    templateName: templateName,
    stream: true,
    extra_params: { template_params: { representation: rep } },
  };
  try {
    const loadTemplateResp = await loadTemplate(payload);
    console.log('Load template response => ', loadTemplateResp);
    const { data } = loadTemplateResp;
    return data ?? '';
  } catch (err) {
    console.log('Error in template => ', err);
    return '';
  }
};

export const sendMessageToLLM = async (userId, message) => {
  const payload = {
    uid: userId,
    message: message,
    stream: true,
  };
  try {
    const messageResp = await sendMessageToLLM02(payload);
    console.log('message resp => ', messageResp);
    const { data } = messageResp;
    return data ?? '';
  } catch (err) {
    console.log('Error is => ', err);
    return '';
  }
};

export const handleLoadErrorTemplate = async (userId, templateName, errors) => {
  let payload = {
    uid: userId,
    templateName: templateName,
    stream: true,
  };
  if (templateName === ERROR_TEMPLATES.INCORRECT_INFORMATION_FIX) {
    payload['extra_params'] = { template_params: { user_response: errors } };
  }
  if (templateName === ERROR_TEMPLATES.VALIDATION_ERROR) {
    payload['extra_params'] = { template_params: { errors: errors } };
  }
  try {
    const loadTemplateResp = await loadTemplate(payload);
    console.log('Load template response => ', loadTemplateResp);
    const { data } = loadTemplateResp;
    return data ?? '';
  } catch (err) {
    console.log('Error in template => ', err);
    return '';
  }
};
export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const extractJsonFromText = (text) => {
  /**
   * Extracts JSON objects from a given text string.
   *
   * @param {string} text - The input text containing JSON-like substrings.
   * @returns {Array<Object>} - A list of objects containing the parsed JSON data.
   */

  const jsonData = [];
  const stack = [];
  let startIndex = -1;

  for (let i = 0; i < text.length; i++) {
    const char = text[i];

    if (char === '{') {
      if (stack.length === 0) {
        startIndex = i;
      }
      stack.push(char);
    } else if (char === '}') {
      stack.pop();
      if (stack.length === 0 && startIndex !== -1) {
        const jsonString = text.slice(startIndex, i + 1);
        try {
          jsonData.push(JSON.parse(jsonString));
        } catch (e) {
          console.error(`Error decoding JSON: ${e} | JSON string: ${jsonString}`);
        }
        startIndex = -1;
      }
    }
  }

  return jsonData;
};

export const formatJsonToText = (json, indentLevel = 0) => {
  let formattedText = '';

  for (const key in json) {
    // eslint-disable-next-line no-prototype-builtins
    if (json.hasOwnProperty(key)) {
      const value = json[key];
      const formattedKey = `<b>${key.charAt(0).toUpperCase() + key.slice(1)}:</b> `;
      const indent = '&nbsp;'.repeat(indentLevel * 2);

      if (Array.isArray(value)) {
        formattedText += indent + formattedKey + value.join(', ') + '<br>';
      } else if (typeof value === 'object' && value !== null) {
        formattedText += indent + formattedKey + '<br>';
        formattedText += formatJsonToText(value, indentLevel + 1);
      } else {
        formattedText += indent + formattedKey + value + '<br>';
      }
    }
  }

  return formattedText;
};
