import { handleActions } from 'redux-actions';
import { get, cloneDeep } from 'lodash-es';
import { socketsResetAction } from 'store/actions/sockets';
import {
  socketsStoreEditedOfferMessageAction,
  socketsStoreNewOfferMessageAction,
  socketsStoreOfferMessagesByThreadIdAction,
  socketsResetOfferMessagesAction,
  socketSetEditOfferMessageAction,
  setNewOfferMessageWithTempIdAction,
  replaceTempOfferMessageAction,
  socketsRemoveDeletedOfferMessageAction,
  socketsStoreOfferMessagesSetThreadIdAction,
} from 'store/actions/sockets/offerMessages';

const initialData = {
  currentThreadId: null,
  editMessage: null,
  messages: null,
};

export default handleActions(
  {
    [socketsStoreOfferMessagesByThreadIdAction]: (state, { payload }) => ({
      ...state,
      currentThreadId: payload.threadId,
      messages: payload.data,
    }),
    [socketsStoreOfferMessagesSetThreadIdAction]: (state, { payload: { threadId } }) => ({
      ...state,
      currentThreadId: threadId,
    }),
    [socketsStoreNewOfferMessageAction]: (state, { payload }) => {
      const data = get(payload, 'data');

      const isMessageAlreadyExist = (state.messages || []).some(
        (message) => message?.Id === data?.Id,
      );
      if (data.MessageThreadId !== state.currentThreadId || isMessageAlreadyExist) {
        return state;
      }

      return {
        ...state,
        messages: [...(state.messages || []), ...(data ? [data] : [])],
      };
    },
    [setNewOfferMessageWithTempIdAction]: (state, { payload }) => ({
      ...state,
      messages: [...(state.messages || []), payload],
    }),
    [replaceTempOfferMessageAction]: (state, { payload }) => {
      const clonedMessages = cloneDeep(state.messages);
      const tempMessageIndex = (clonedMessages || []).findIndex(
        (message) => message?.TempId === payload?.TempId,
      );

      if (tempMessageIndex !== -1) {
        clonedMessages[tempMessageIndex] = payload;
      }
      return {
        ...state,
        messages: clonedMessages,
      };
    },
    [socketSetEditOfferMessageAction]: (state, { payload }) => ({
      ...state,
      editMessage: payload?.editMessage,
    }),
    [socketsStoreEditedOfferMessageAction]: (state, { payload }) => {
      const data = get(payload, 'data');
      const clonedMessages = cloneDeep(state.messages);
      const editedMessageIndex = (clonedMessages || []).findIndex(
        (message) => message?.Id === data?.Id,
      );

      if (editedMessageIndex !== -1) {
        clonedMessages[editedMessageIndex] = data;
      }

      return {
        ...state,
        messages: clonedMessages,
      };
    },
    [socketsRemoveDeletedOfferMessageAction]: (state) => state, // TODO: find thread by id and then find message by id and remove it;
    [socketsResetAction]: () => cloneDeep(initialData),
    [socketsResetOfferMessagesAction]: () => cloneDeep(initialData),
  },
  initialData,
);
