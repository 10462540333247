import { createSelector } from 'reselect';
import { ERROR, IDLE, PENDING } from 'settings/constants/apiState';
import { get } from 'lodash-es';
import { getUserId } from '../user';
import { getTransactionSelector } from '../transaction';

const localState = ({ transactionDocuments }) => transactionDocuments;

export const getTransactionDocumentsSelector = createSelector(
  localState,
  getTransactionSelector,
  getUserId,
  (transactionDocuments, { transaction }, userId) => {
    return {
      isIdle: transactionDocuments.state === IDLE,
      isPending: transactionDocuments.state === PENDING,
      isError: transactionDocuments.state === ERROR,
      isData: !!transactionDocuments?.data,
      status: transactionDocuments?.meta?.status,
      isCreator: userId === transaction?.Creator?.Id,
      documents: transactionDocuments?.data,
      count: transactionDocuments?.count,
      countPerPage: transactionDocuments?.countPerPage,
      page: transactionDocuments?.page,
    };
  },
);

export const getTransactionDocumentsFilterSelector = createSelector(localState, (state) => ({
  filter: get(state, 'filter'),
  isPending: get(state, 'state') === PENDING,
}));

export const getTransactionDocumentsSortSelector = createSelector(localState, (state) =>
  get(state, 'sort', {}),
);

export const getTransactionDocumentsDataSelector = createSelector(localState, (state) =>
  get(state, 'data'),
);
