import { Modal } from 'components-antd';
import { BillingHistoryTable } from '../BillingHistoryTable';

import styles from './styles.module.scss';

export const BillingHistoryModal = ({ isOpen, onClose, data }) => {
  return (
    <Modal
      width={675}
      className={styles.billingHistoryModal}
      open={isOpen}
      footer={null}
      onCancel={onClose}
    >
      <div className={styles.modalContent}>
        <h2 className={styles.billingHistoryModalHeader}>Payment History</h2>
        <BillingHistoryTable data={data} includeDescriptionHeader={true} />
      </div>
    </Modal>
  );
};
