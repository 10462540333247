/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getLink } from 'helpers';
import InfoItem from '../Content/InfoItem';

import styles from './styles.module.scss';

const LinksInfo = (props) => {
  const { className, value } = props;

  if (!value) return null;

  return (
    <div className={classNames(styles.linksInfo, className)}>
      <InfoItem
        className={styles.links}
        label="Website"
        value={
          value && value.length ? (
            value.map((val, index) => (
              <div className={styles.linkHolder} key={index}>
                <a className={styles.link} href={getLink(val)} target="_blank" rel="noreferrer">
                  {val}
                </a>
              </div>
            ))
          ) : (
            <div className={styles.linkHolder}>-</div>
          )
        }
      />
    </div>
  );
};

LinksInfo.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
};

LinksInfo.defaultProps = {
  className: '',
  value: null,
};

export default LinksInfo;
