import React from 'react';
import PropTypes from 'prop-types';

const Icons = ({ testid, className, variant, onClick }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.ADD_PARTICIPANT: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 8.16406C13.5 10.0971 11.933 11.6641 10 11.6641C8.067 11.6641 6.5 10.0971 6.5 8.16406C6.5 6.23107 8.067 4.66406 10 4.66406C11.933 4.66406 13.5 6.23107 13.5 8.16406Z"
              stroke="#666666"
              strokeWidth="2"
            />
            <path
              d="M20 4V10"
              stroke="#666666"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 7L23 7"
              stroke="#666666"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 18.998C4.37394 17.1284 6.09315 15.498 7.99988 15.498H12C13.9067 15.498 15.6261 17.1284 16 18.998"
              stroke="#666666"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className}>
      {renderIcon()}
    </div>
  );
};

Icons.ADD_PARTICIPANT = 'addParticipant';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};

export default Icons;
