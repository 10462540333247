import { useEffect, useRef } from 'react';
import { InputRef } from 'antd';

const useAutoFocus = (visible?: boolean) => {
  const ref = useRef<InputRef>(null);
  useEffect(() => {
    if (visible) {
      setImmediate(() => {
        ref.current?.focus();
      });
    }
  }, [visible]);
  return ref;
};

export default useAutoFocus;
