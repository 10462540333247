import { SVGProps } from 'react';

export enum SmartFormIconVariants {
  PDF = 'pdf',
  List = 'list',
  Back = 'back',
  Next = 'next',
  Cancel = 'cancel',
  Continue = 'continue',
  Help = 'help',
  HelpWhite = 'help-white',
  Cross = 'cross',
  Pencil = 'pencil',
  Lock = 'lock',
  Comment = 'comment',
  Defer = 'defer',
  Tag = 'tag',
  Send = 'send',
  Tick = 'tick',
  DotMenu = 'dot-menu',
  Trash = 'trash',
  Preview = 'preview',
  CurveArrow = 'curve-arrow',
  SendActive = 'send-active',
  Bundle = 'pdf-bundle',
  CommentWhite = 'comment-white',
  CommentRed = 'comment-red',
  CommentDelete = 'comment-delete',
}

type SmartFormIconVariantsType =
  | 'pdf'
  | 'list'
  | 'back'
  | 'next'
  | 'cancel'
  | 'continue'
  | 'help'
  | 'help-white'
  | 'cross'
  | 'pencil'
  | 'lock'
  | 'comment'
  | 'defer'
  | 'tag'
  | 'send'
  | 'tick'
  | 'dot-menu'
  | 'trash'
  | 'preview'
  | 'curve-arrow'
  | 'send-active'
  | 'pdf-bundle'
  | 'comment-white'
  | 'comment-red'
  | 'comment-delete'
  | 'file';

interface SmartFormIconProps extends SVGProps<SVGSVGElement> {
  variant: SmartFormIconVariantsType;
}

export const SmartFormIcons = ({ variant, ...props }: SmartFormIconProps) => {
  const renderIcon = () => {
    switch (variant) {
      case 'trash':
        return (
          <svg
            width={props.width || '16'}
            height={props.height || '16'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_23773_143254)">
              <path
                d="M4 7H20"
                stroke={props.stroke || '#FF576D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 11V17"
                stroke={props.stroke || '#FF576D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 11V17"
                stroke={props.stroke || '#FF576D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7"
                stroke={props.stroke || '#FF576D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                stroke={props.stroke || '#FF576D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_23773_143254">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case 'dot-menu':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="5" cy="12" r="2" fill="#747475" />
            <circle cx="12" cy="12" r="2" fill="#747475" />
            <circle cx="19" cy="12" r="2" fill="#747475" />
          </svg>
        );
      case 'tick':
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_20278_181286)">
              <path
                d="M3.33203 7.99935L6.66536 11.3327L13.332 4.66602"
                stroke={props.stroke || '#00B152'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_20278_181286">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case 'send':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_20736_2767)">
              <path
                d="M10 14L20.5 3.5"
                stroke={props.stroke || '#676767'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.5 3.5L14.1138 21.1847C14.0707 21.2788 14.0015 21.3585 13.9144 21.4144C13.8274 21.4703 13.7261 21.5 13.6226 21.5C13.5191 21.5 13.4178 21.4703 13.3307 21.4144C13.2437 21.3585 13.1745 21.2788 13.1314 21.1847L9.69265 14.3073L2.81525 10.8686C2.72119 10.8255 2.64148 10.7563 2.58559 10.6693C2.52971 10.5822 2.5 10.4809 2.5 10.3774C2.5 10.2739 2.52971 10.1726 2.58559 10.0856C2.64148 9.99847 2.72119 9.92926 2.81525 9.88616L20.5 3.5Z"
                stroke={props.stroke || '#676767'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_20736_2767">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case 'tag':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_20736_1080)">
              <path
                d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                stroke="#676767"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 11.9998V13.4998C16 14.1629 16.2634 14.7988 16.7322 15.2676C17.2011 15.7365 17.837 15.9998 18.5 15.9998C19.163 15.9998 19.7989 15.7365 20.2678 15.2676C20.7366 14.7988 21 14.1629 21 13.4998V11.9998C21.0025 10.066 20.382 8.18269 19.2304 6.62904C18.0788 5.07539 16.4574 3.934 14.6064 3.37395C12.7554 2.81389 10.7732 2.86497 8.95343 3.51961C7.13371 4.17425 5.57324 5.39763 4.50321 7.00852C3.43317 8.61942 2.91047 10.5321 3.01255 12.4633C3.11463 14.3945 3.83605 16.2415 5.06995 17.7306C6.30385 19.2197 7.98459 20.2718 9.86319 20.7309C11.7418 21.1901 13.7183 21.0319 15.5 20.2799"
                stroke="#676767"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_20736_1080">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case 'defer':
        return (
          <svg
            width="18"
            height="15"
            viewBox="0 0 18 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M1.7998 14.0004V9.80039C1.7998 8.14354 3.14295 6.80039 4.7998 6.80039H16.1998M16.1998 6.80039L10.7998 1.40039M16.1998 6.80039L10.7998 12.2004"
              stroke={props.stroke || '#FF576D'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'comment':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M5.18229 16.0581L6.10236 16.4498L6.30022 15.9851L6.04365 15.5501L5.18229 16.0581ZM3.54135 19.912L2.62128 19.5203H2.62128L3.54135 19.912ZM4.18134 20.5744L4.54126 21.5074H4.54126L4.18134 20.5744ZM8.22693 19.0137L8.70902 18.1376L8.30125 17.9132L7.86701 18.0807L8.22693 19.0137ZM19.0781 12C19.0781 15.866 15.9441 19 12.0781 19V21C17.0487 21 21.0781 16.9706 21.0781 12H19.0781ZM12.0781 5C15.9441 5 19.0781 8.13401 19.0781 12H21.0781C21.0781 7.02944 17.0487 3 12.0781 3V5ZM5.07811 12C5.07811 8.13401 8.21212 5 12.0781 5V3C7.10755 3 3.07811 7.02944 3.07811 12H5.07811ZM6.04365 15.5501C5.43029 14.51 5.07811 13.2977 5.07811 12H3.07811C3.07811 13.6647 3.53101 15.2267 4.32093 16.5661L6.04365 15.5501ZM4.46142 20.3038L6.10236 16.4498L4.26222 15.6663L2.62128 19.5203L4.46142 20.3038ZM3.82142 19.6414C4.23373 19.4824 4.63454 19.8972 4.46142 20.3038L2.62128 19.5203C2.10191 20.7401 3.30435 21.9846 4.54126 21.5074L3.82142 19.6414ZM7.86701 18.0807L3.82142 19.6414L4.54126 21.5074L8.58685 19.9467L7.86701 18.0807ZM12.0781 19C10.8548 19 9.70754 18.687 8.70902 18.1376L7.74483 19.8898C9.03123 20.5977 10.5092 21 12.0781 21V19Z"
              fill={props.fill || '#FF576D'}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.55859 12C9.55859 12.8284 8.88702 13.5 8.05859 13.5C7.23017 13.5 6.55859 12.8284 6.55859 12C6.55859 11.1716 7.23017 10.5 8.05859 10.5C8.88702 10.5 9.55859 11.1716 9.55859 12ZM13.5586 12C13.5586 12.8284 12.887 13.5 12.0586 13.5C11.2302 13.5 10.5586 12.8284 10.5586 12C10.5586 11.1716 11.2302 10.5 12.0586 10.5C12.887 10.5 13.5586 11.1716 13.5586 12ZM16.0586 13.5C16.887 13.5 17.5586 12.8284 17.5586 12C17.5586 11.1716 16.887 10.5 16.0586 10.5C15.2302 10.5 14.5586 11.1716 14.5586 12C14.5586 12.8284 15.2302 13.5 16.0586 13.5Z"
              fill={props.fill || '#FF576D'}
            />
          </svg>
        );
      case 'comment-white': {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M5.18229 16.0581L6.10236 16.4498L6.30022 15.9851L6.04365 15.5501L5.18229 16.0581ZM3.54135 19.912L4.46142 20.3038L4.46142 20.3038L3.54135 19.912ZM4.18134 20.5744L3.82142 19.6414H3.82142L4.18134 20.5744ZM8.22693 19.0137L8.70902 18.1376L8.30125 17.9132L7.86701 18.0807L8.22693 19.0137ZM19.0781 12C19.0781 15.866 15.9441 19 12.0781 19V21C17.0487 21 21.0781 16.9706 21.0781 12H19.0781ZM12.0781 5C15.9441 5 19.0781 8.13401 19.0781 12H21.0781C21.0781 7.02944 17.0487 3 12.0781 3V5ZM5.07811 12C5.07811 8.13401 8.21212 5 12.0781 5V3C7.10755 3 3.07811 7.02944 3.07811 12H5.07811ZM6.04365 15.5501C5.43029 14.51 5.07811 13.2977 5.07811 12H3.07811C3.07811 13.6647 3.53101 15.2267 4.32093 16.5661L6.04365 15.5501ZM4.46142 20.3038L6.10236 16.4498L4.26222 15.6663L2.62128 19.5203L4.46142 20.3038ZM3.82142 19.6414C4.23373 19.4824 4.63454 19.8972 4.46142 20.3038L2.62128 19.5203C2.10191 20.7401 3.30434 21.9846 4.54127 21.5074L3.82142 19.6414ZM7.86701 18.0807L3.82142 19.6414L4.54126 21.5074L8.58685 19.9467L7.86701 18.0807ZM12.0781 19C10.8548 19 9.70754 18.687 8.70902 18.1376L7.74483 19.8898C9.03123 20.5977 10.5092 21 12.0781 21V19Z"
              fill="#F4F5F6"
            />
            <path
              d="M12.2008 12.9703C12.1879 12.7431 12.2492 12.5178 12.3754 12.3284C12.5017 12.1391 12.6861 11.9958 12.9008 11.9203C13.1639 11.8197 13.4001 11.6594 13.5907 11.452C13.7813 11.2446 13.9212 10.9958 13.9993 10.7251C14.0774 10.4545 14.0917 10.1694 14.0409 9.89234C13.9901 9.61526 13.8757 9.35375 13.7067 9.12839C13.5377 8.90304 13.3187 8.71999 13.0669 8.59366C12.8151 8.46734 12.5374 8.40118 12.2557 8.4004C11.9741 8.39962 11.696 8.46423 11.4436 8.58916C11.1911 8.71409 10.971 8.89592 10.8008 9.12033"
              stroke="#F4F5F6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="12.2" cy="15.6004" r="1.2" fill="#F4F5F6" />
          </svg>
        );
      }
      case 'comment-red': {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M4.3176 13.3799L5.23767 13.7716L5.4355 13.307L5.17901 12.872L4.3176 13.3799ZM3.02768 16.4094L3.94775 16.8012H3.94775L3.02768 16.4094ZM3.66767 17.0718L3.30775 16.1388H3.30775L3.66767 17.0718ZM6.85372 15.8427L7.33603 14.9667L6.92818 14.7421L6.4938 14.9097L6.85372 15.8427ZM15.7311 9.9987C15.7311 13.1283 13.194 15.6654 10.0644 15.6654V17.6654C14.2986 17.6654 17.7311 14.2329 17.7311 9.9987H15.7311ZM10.0644 4.33203C13.194 4.33203 15.7311 6.86908 15.7311 9.9987H17.7311C17.7311 5.76451 14.2986 2.33203 10.0644 2.33203V4.33203ZM4.39776 9.9987C4.39776 6.86908 6.93481 4.33203 10.0644 4.33203V2.33203C5.83025 2.33203 2.39776 5.76451 2.39776 9.9987H4.39776ZM5.17901 12.872C4.68274 12.0303 4.39776 11.0493 4.39776 9.9987H2.39776C2.39776 11.4163 2.78343 12.7468 3.45619 13.8878L5.17901 12.872ZM3.94775 16.8012L5.23767 13.7716L3.39752 12.9881L2.1076 16.0177L3.94775 16.8012ZM3.30775 16.1388C3.72005 15.9798 4.12087 16.3946 3.94775 16.8012L2.1076 16.0177C1.58824 17.2375 2.79068 18.482 4.02759 18.0048L3.30775 16.1388ZM6.4938 14.9097L3.30775 16.1388L4.02759 18.0048L7.21364 16.7757L6.4938 14.9097ZM10.0644 15.6654C9.0733 15.6654 8.14441 15.4118 7.33603 14.9667L6.37142 16.7187C7.46778 17.3223 8.72754 17.6654 10.0644 17.6654V15.6654Z"
              fill="#FF576D"
            />
            <path
              d="M10.1667 10.8083C10.1559 10.6189 10.207 10.4312 10.3122 10.2734C10.4174 10.1156 10.5711 9.9962 10.75 9.93328C10.9693 9.84944 11.1661 9.71584 11.3249 9.54301C11.4838 9.37018 11.6003 9.16283 11.6654 8.93729C11.7305 8.71176 11.7424 8.47419 11.7001 8.24329C11.6578 8.01239 11.5624 7.79446 11.4216 7.60667C11.2808 7.41887 11.0982 7.26633 10.8884 7.16106C10.6786 7.05579 10.4472 7.00066 10.2125 7.00001C9.97773 6.99935 9.74604 7.0532 9.53564 7.15731C9.32525 7.26142 9.14189 7.41294 9 7.59995"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="10.168" cy="13" r="1" fill="#FF576D" />
          </svg>
        );
      }
      case 'lock':
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_9371_149316)">
              <path
                d="M14.1665 9.16663H5.83317C4.9127 9.16663 4.1665 9.91282 4.1665 10.8333V15.8333C4.1665 16.7538 4.9127 17.5 5.83317 17.5H14.1665C15.087 17.5 15.8332 16.7538 15.8332 15.8333V10.8333C15.8332 9.91282 15.087 9.16663 14.1665 9.16663Z"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.99984 14.1667C10.4601 14.1667 10.8332 13.7936 10.8332 13.3333C10.8332 12.8731 10.4601 12.5 9.99984 12.5C9.5396 12.5 9.1665 12.8731 9.1665 13.3333C9.1665 13.7936 9.5396 14.1667 9.99984 14.1667Z"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.6665 9.16667V5.83333C6.6665 4.94928 7.01769 4.10143 7.64281 3.47631C8.26794 2.85119 9.11578 2.5 9.99984 2.5C10.8839 2.5 11.7317 2.85119 12.3569 3.47631C12.982 4.10143 13.3332 4.94928 13.3332 5.83333V9.16667"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_9371_149316">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case 'pencil':
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M4 16H7.13144L15.3515 7.77998C15.7667 7.36472 16 6.80152 16 6.21426C16 5.627 15.7667 5.0638 15.3515 4.64854C14.9362 4.23329 14.373 4 13.7857 4C13.1985 4 12.6353 4.23329 12.22 4.64854L4 12.8686V16Z"
              stroke={props.stroke || '#515151'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.4375 5.43164L14.5689 8.56308"
              stroke={props.stroke || '#515151'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'cross':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path d="M17 17L7 7" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M7 17L17 7" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      case 'pdf':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 9H10"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 13H15"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 17H15"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'list':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="5.5" cy="5.5" r="1.5" fill="white" stroke="#515151" strokeWidth="2" />
            <circle cx="5.5" cy="12" r="1.5" fill="white" stroke="#515151" strokeWidth="2" />
            <circle cx="5.5" cy="18.5" r="1.5" fill="white" stroke="#515151" strokeWidth="2" />
            <path
              d="M11 5.5H19"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 12H19"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 18.5H19"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case 'back':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M14 6L8 12L14 18"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case 'next':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M10 6L16 12L10 18"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case 'cancel':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path d="M17 17L7 7" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M7 17L17 7" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );

      case 'continue':
        return (
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M1 6H14M14 6L9 1M14 6L9 11"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case 'help':
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M4.31792 13.3819L5.238 13.7736L5.43585 13.3089L5.17929 12.8739L4.31792 13.3819ZM3.02768 16.4122L3.94775 16.8039H3.94775L3.02768 16.4122ZM3.66767 17.0746L3.30775 16.1416H3.30775L3.66767 17.0746ZM6.85512 15.8449L7.33722 14.9688L6.92944 14.7444L6.4952 14.9119L6.85512 15.8449ZM15.7311 10.0002C15.7311 13.1298 13.1941 15.6668 10.0644 15.6668V17.6668C14.2986 17.6668 17.7311 14.2343 17.7311 10.0002H15.7311ZM10.0644 4.3335C13.1941 4.3335 15.7311 6.87055 15.7311 10.0002H17.7311C17.7311 5.76598 14.2986 2.3335 10.0644 2.3335V4.3335ZM4.39777 10.0002C4.39777 6.87055 6.93483 4.3335 10.0644 4.3335V2.3335C5.83026 2.3335 2.39777 5.76598 2.39777 10.0002H4.39777ZM5.17929 12.8739C4.68286 12.0321 4.39777 11.051 4.39777 10.0002H2.39777C2.39777 11.418 2.78358 12.7488 3.45656 13.8899L5.17929 12.8739ZM3.94775 16.8039L5.238 13.7736L3.39785 12.9901L2.1076 16.0204L3.94775 16.8039ZM3.30775 16.1416C3.72005 15.9825 4.12087 16.3973 3.94775 16.8039L2.1076 16.0204C1.58824 17.2402 2.79068 18.4847 4.02759 18.0075L3.30775 16.1416ZM6.4952 14.9119L3.30775 16.1416L4.02759 18.0075L7.21504 16.7779L6.4952 14.9119ZM10.0644 15.6668C9.0738 15.6668 8.14532 15.4135 7.33722 14.9688L6.37303 16.721C7.46902 17.3241 8.72821 17.6668 10.0644 17.6668V15.6668Z"
              fill="#515151"
            />
            <path
              d="M10.1667 10.8083C10.1559 10.6189 10.207 10.4312 10.3122 10.2734C10.4174 10.1156 10.5711 9.9962 10.75 9.93328C10.9693 9.84944 11.1661 9.71584 11.3249 9.54301C11.4838 9.37018 11.6003 9.16283 11.6654 8.93729C11.7305 8.71176 11.7424 8.47419 11.7001 8.24329C11.6578 8.01239 11.5624 7.79446 11.4216 7.60667C11.2808 7.41887 11.0982 7.26633 10.8884 7.16106C10.6786 7.05579 10.4472 7.00066 10.2125 7.00001C9.97773 6.99935 9.74604 7.0532 9.53564 7.15731C9.32525 7.26142 9.14189 7.41294 9 7.59995"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="10.166" cy="13" r="1" fill="#515151" />
          </svg>
        );

      case 'help-white':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M5.18229 16.0581L6.10236 16.4498L6.30022 15.9851L6.04365 15.5501L5.18229 16.0581ZM3.54135 19.912L4.46142 20.3038L4.46142 20.3038L3.54135 19.912ZM4.18134 20.5744L3.82142 19.6414H3.82142L4.18134 20.5744ZM8.22693 19.0137L8.70902 18.1376L8.30125 17.9132L7.86701 18.0807L8.22693 19.0137ZM19.0781 12C19.0781 15.866 15.9441 19 12.0781 19V21C17.0487 21 21.0781 16.9706 21.0781 12H19.0781ZM12.0781 5C15.9441 5 19.0781 8.13401 19.0781 12H21.0781C21.0781 7.02944 17.0487 3 12.0781 3V5ZM5.07811 12C5.07811 8.13401 8.21212 5 12.0781 5V3C7.10755 3 3.07811 7.02944 3.07811 12H5.07811ZM6.04365 15.5501C5.43029 14.51 5.07811 13.2977 5.07811 12H3.07811C3.07811 13.6647 3.53101 15.2267 4.32093 16.5661L6.04365 15.5501ZM4.46142 20.3038L6.10236 16.4498L4.26222 15.6663L2.62128 19.5203L4.46142 20.3038ZM3.82142 19.6414C4.23373 19.4824 4.63454 19.8972 4.46142 20.3038L2.62128 19.5203C2.10191 20.7401 3.30434 21.9846 4.54127 21.5074L3.82142 19.6414ZM7.86701 18.0807L3.82142 19.6414L4.54126 21.5074L8.58685 19.9467L7.86701 18.0807ZM12.0781 19C10.8548 19 9.70754 18.687 8.70902 18.1376L7.74483 19.8898C9.03123 20.5977 10.5092 21 12.0781 21V19Z"
              fill="#F4F5F6"
            />
            <path
              d="M12.1998 12.9698C12.1869 12.7426 12.2482 12.5173 12.3745 12.328C12.5007 12.1386 12.6851 11.9953 12.8998 11.9198C13.1629 11.8192 13.3991 11.6589 13.5897 11.4515C13.7803 11.2441 13.9202 10.9953 13.9983 10.7247C14.0765 10.454 14.0907 10.1689 14.0399 9.89185C13.9891 9.61477 13.8747 9.35326 13.7057 9.1279C13.5367 8.90255 13.3177 8.7195 13.0659 8.59318C12.8141 8.46685 12.5365 8.40069 12.2548 8.39991C11.9731 8.39913 11.6951 8.46374 11.4426 8.58867C11.1901 8.7136 10.9701 8.89543 10.7998 9.11984"
              stroke="#F4F5F6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="12.2" cy="15.5999" r="1.2" fill="#F4F5F6" />
          </svg>
        );
      case SmartFormIconVariants.Preview:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M2.5 15.8334C3.64014 15.1752 4.93347 14.8286 6.25 14.8286C7.56652 14.8286 8.85986 15.1752 10 15.8334C11.1401 15.1752 12.4335 14.8286 13.75 14.8286C15.0665 14.8286 16.3599 15.1752 17.5 15.8334"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.5 4.99993C3.64014 4.34166 4.93347 3.99512 6.25 3.99512C7.56652 3.99512 8.85986 4.34166 10 4.99993C11.1401 4.34166 12.4335 3.99512 13.75 3.99512C15.0665 3.99512 16.3599 4.34166 17.5 4.99993"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.5 5V15.8333"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 5V15.8333"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5 5V15.8333"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'curve-arrow':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M4.80078 17.9984V13.7984C4.80078 12.1416 6.14393 10.7984 7.80078 10.7984H19.2008M19.2008 10.7984L13.8008 5.39844M19.2008 10.7984L13.8008 16.1984"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'send-active':
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <rect width="40" height="40" rx="20" fill="#252D44" />
            <g clipPath="url(#clip0_12709_82347)">
              <path
                d="M18 22L28.5 11.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28.5 11.5L22.1138 29.1847C22.0707 29.2788 22.0015 29.3585 21.9144 29.4144C21.8274 29.4703 21.7261 29.5 21.6226 29.5C21.5191 29.5 21.4178 29.4703 21.3307 29.4144C21.2437 29.3585 21.1745 29.2788 21.1314 29.1847L17.6927 22.3073L10.8153 18.8686C10.7212 18.8255 10.6415 18.7563 10.5856 18.6693C10.5297 18.5822 10.5 18.4809 10.5 18.3774C10.5 18.2739 10.5297 18.1726 10.5856 18.0856C10.6415 17.9985 10.7212 17.9293 10.8153 17.8862L28.5 11.5Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_12709_82347">
                <rect width="24" height="24" fill="white" transform="translate(8 8)" />
              </clipPath>
            </defs>
          </svg>
        );
      case 'pdf-bundle':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            {...props}
          >
            <g clipPath="url(#clip0_42404_98680)">
              <path
                d="M8.63672 14L15.364 14C15.8944 14 16.4031 13.7893 16.7782 13.4142C17.1533 13.0391 17.364 12.5304 17.364 12V5L13.5 0.999998H8.63672C8.10629 0.999998 7.59758 1.21071 7.22251 1.58578C6.84743 1.96086 6.63672 2.46957 6.63672 3V12C6.63672 12.5304 6.84743 13.0391 7.22251 13.4142C7.59758 13.7893 8.10629 14 8.63672 14Z"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13 1V4.4C13 4.62543 13.0896 4.84163 13.249 5.00104C13.4084 5.16045 13.6246 5.25 13.85 5.25H17.25"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M10 7.5H14" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
              <path d="M10 10.5H12" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
              <path
                d="M13.364 14V16C13.364 16.5304 13.1533 17.0391 12.7782 17.4142C12.4031 17.7893 11.8944 18 11.364 18H4.63672C4.10629 18 3.59758 17.7893 3.22251 17.4142C2.84743 17.0391 2.63672 16.5304 2.63672 16V7C2.63672 6.46957 2.84743 5.96086 3.22251 5.58579C3.59758 5.21071 4.10629 5 4.63672 5H6.63672"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_42404_98680">
                <rect width="20" height="20" fill="white" transform="matrix(1 0 0 -1 0 20)" />
              </clipPath>
            </defs>
          </svg>
        );
      case 'comment-delete':
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66797 4.66699H13.3346"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.66797 7.33301V11.333"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.33203 7.33301V11.333"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33203 4.66699L3.9987 12.667C3.9987 13.0206 4.13917 13.3598 4.38922 13.6098C4.63927 13.8598 4.97841 14.0003 5.33203 14.0003H10.6654C11.019 14.0003 11.3581 13.8598 11.6082 13.6098C11.8582 13.3598 11.9987 13.0206 11.9987 12.667L12.6654 4.66699"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'file': {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.8008 1.89844V5.49844C11.8008 5.73713 11.8956 5.96605 12.0644 6.13483C12.2332 6.30362 12.4621 6.39844 12.7008 6.39844H16.3008"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.4992 18.0984H5.49922C5.02183 18.0984 4.56399 17.9088 4.22643 17.5712C3.88886 17.2337 3.69922 16.7758 3.69922 16.2984V3.69844C3.69922 3.22105 3.88886 2.76321 4.22643 2.42565C4.56399 2.08808 5.02183 1.89844 5.49922 1.89844H11.7992L16.2992 6.39844V16.2984C16.2992 16.7758 16.1096 17.2337 15.772 17.5712C15.4344 17.9088 14.9766 18.0984 14.4992 18.0984Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M7.5 9.5H12.5" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M7.5 13.5H10.5" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      default:
        return <></>;
    }
  };

  return renderIcon();
};
