import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

export const MultiFactorAuthBlockedStep: React.FC<{}> = () => {
  return (
    <div className={styles.layout}>
      <h2 className={styles.stepHeader}>Two Factor Authentication</h2>
      <div className={classNames(styles.text, styles.blockedVerificationContent)}>
        Your account is temporarily blocked. Please try again later or contact{' '}
        <a href="emailto:support@mosaik.io">support@mosaik.io</a> for further assistance.
      </div>
    </div>
  );
};
