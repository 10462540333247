import { SVGProps } from 'react';

import { PDF_FIELD_TYPE } from 'app-constants';
import { QuestionFieldType } from 'types';

interface PDFIconProp extends SVGProps<SVGSVGElement> {
  variant: QuestionFieldType;
}

const getPDFIcon = ({ variant, ...props }: PDFIconProp) => {
  switch (variant) {
    case PDF_FIELD_TYPE.PDFButton:
      return (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="28" height="28" rx="4" fill="#FF576D" />
          <rect x="6" y="10" width="16" height="8" rx="1" stroke="white" strokeWidth="2" />
          <circle cx="10" cy="14" r="1.5" fill="white" />
          <path d="M13.5 14L18 14" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
      );
    case PDF_FIELD_TYPE.PDFCheckBox:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13 6H8C6.89543 6 6 6.89543 6 8V16C6 17.1046 6.89543 18 8 18H16C17.1046 18 18 17.1046 18 16V13"
            stroke="#FB913A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.5 11L11.5 13.5L17.5 7.5"
            stroke="#FB913A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case PDF_FIELD_TYPE.PDFDropdown:
      return (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <rect width="28" height="28" rx="4" fill="#51BFE1" />
          <rect x="7" y="8" width="14" height="12" rx="1" stroke="white" strokeWidth="2" />
          <path
            d="M11.5 13L14 15.5L16.5 13"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case PDF_FIELD_TYPE.PDFOptionList:
      return (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <rect width="28" height="28" rx="4" fill="#00B152" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 11C21 11.5523 20.5523 12 20 12H14C13.4477 12 13 11.5523 13 11C13 10.4477 13.4477 10 14 10H20C20.5523 10 21 10.4477 21 11Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 17C21 17.5523 20.5523 18 20 18H14C13.4477 18 13 17.5523 13 17C13 16.4477 13.4477 16 14 16H20C20.5523 16 21 16.4477 21 17Z"
            fill="white"
          />
          <path
            d="M7 11L8.5 12.5L11 9.5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 17L8.5 18.5L11 15.5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case PDF_FIELD_TYPE.PDFRadioGroup:
      return (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <rect width="28" height="28" rx="4" fill="#928CDA" />
          <circle cx="10" cy="10" r="2" fill="white" stroke="white" strokeWidth="2" />
          <circle cx="10" cy="18" r="2" stroke="white" strokeWidth="2" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 10C21 10.5523 20.5523 11 20 11H16C15.4477 11 15 10.5523 15 10C15 9.44772 15.4477 9 16 9H20C20.5523 9 21 9.44772 21 10Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 18C21 18.5523 20.5523 19 20 19H16C15.4477 19 15 18.5523 15 18C15 17.4477 15.4477 17 16 17H20C20.5523 17 21 17.4477 21 18Z"
            fill="white"
          />
        </svg>
      );
    case PDF_FIELD_TYPE.PDFSignature:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11 12.9998H13.475L19.975 6.49928C20.3032 6.17108 20.4876 5.72594 20.4876 5.26179C20.4876 4.79764 20.3032 4.35251 19.975 4.0243C19.6468 3.6961 19.2016 3.51172 18.7375 3.51172C18.2733 3.51172 17.8282 3.6961 17.5 4.0243L11 10.5248V12.9998Z"
            stroke="#4673D1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 5.5L18.5 7.5"
            stroke="#4673D1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 13H6.5C5.67157 13 5 13.6716 5 14.5V14.5C5 15.3284 5.66965 16 6.49808 16C7.84871 16 9.86762 16 11.5011 16C12.3295 16 13 16.6716 13 17.5V17.5C13 18.3284 12.3284 19 11.5 19H8"
            stroke="#4673D1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case PDF_FIELD_TYPE.PDFTextField:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 8C5 7.44772 5.44772 7 6 7H18C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9H6C5.44772 9 5 8.55228 5 8ZM5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12ZM6 15C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17H12C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15H6Z"
            fill="#928CDA"
          />
        </svg>
      );

    default:
      return <></>;
  }
};

export const PDFIcon = (props: PDFIconProp) => getPDFIcon(props);
