import { Button, Modal } from 'components-antd';
import { termsAgreementStatement } from 'app-constants';

import styles from './styles.module.scss';

interface TermsAndConditionsModalProps {
  loading: boolean;
  handleAgree: () => void;
}

export const TermsAndConditionsModal = ({ loading, handleAgree }: TermsAndConditionsModalProps) => {
  const footer = (
    <div className={styles.footer}>
      <div className={styles.agreement}>{termsAgreementStatement}</div>
      <div className={styles.agreeBtn}>
        <Button loading={loading} variant="secondary" onClick={() => handleAgree()}>
          I Agree
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      closable={false}
      open={true}
      width={675}
      footer={footer}
      maskClosable={false}
      destroyOnClose={true}
      title={'Terms & Conditions'}
      className={styles.termsAndConditionsModal}
    >
      <div className={styles.terms}>
        <p>
          By electronically signing this document, I understand and agree that the signature and
          initials will be the electronic representation of my signature and initials for all
          purposes when I use them on documents, including legally binding contracts - just as a
          &quot;pen-and-paper&quot; signature or initial.
        </p>
        <p>
          I agree to be legally bound by this document and Mosaik&apos;s{' '}
          <a target="_blank" rel="noreferrer" href="https://www.mosaik.io/terms-of-service">
            Terms of Service.
          </a>{' '}
          Click &quot;I agree&quot; to sign this document.
        </p>
      </div>
    </Modal>
  );
};
