import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useOutsideClick } from 'hooks';
import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import { FILE_TYPES } from 'settings/constants/common';
import { requestDeleteAppointmentDocumentByIdEffect } from 'store/effects/showingAppointment';
import { PdfViewModal, Wrapper as PendingWrapper, OptionsPopup } from 'components';
import axios from 'axios';
import fileDownload from 'js-file-download';
import styles from './styles.module.scss';

const Actions = (props) => {
  const { className, deletable, file, taskId, onDelete } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const actionsRef = useRef();
  const buttonRef = useRef();
  const popupRef = useRef();

  useOutsideClick([actionsRef, buttonRef, popupRef], () => setOpen(false));

  const onClick = () => {
    setOpen(!open);
  };

  const onPreview = () => {
    setIsPreviewOpen(true);
    setOpen(false);
  };

  const onDownload = () => {
    const fileExt = (file?.Meta?.Filename || file?.filename || '').split('.').pop();
    const filename = `${file?.documentName || file?.Title}.${fileExt}`;
    const URL = file?.Meta?.Url || file?.location;
    if (URL) {
      axios.get(URL, { responseType: 'blob' }).then((response) => {
        fileDownload(response.data, filename);
      });
    }

    if (file?.data) {
      const src = `data:${file?.Meta?.ContentType || file?.contentType};base64,${file?.data}`;
      fileDownload(src, filename);
    }

    setOpen(false);
  };

  const onDeleteHandler = (event) => {
    if (file?.Id) {
      setIsPending(true);
      dispatch(
        requestDeleteAppointmentDocumentByIdEffect({ Id: file?.Id }, {}, () => {
          onDelete(event);
          setIsPending(false);
        }),
      );
      setOpen(false);
    } else {
      onDelete(event);
    }
  };

  return (
    <PendingWrapper
      isPending={isPending}
      spinnerClassname={styles.spinnerWrapper}
      loaderClassName={styles.loader}
    >
      <div testid="doc_actions" ref={actionsRef} className={classNames(styles.actions, className)}>
        <Icon ref={buttonRef} onClick={onClick} variant={Icon.ACTIONS_DOTS} />
        <OptionsPopup
          className={classNames(styles.attachmentOptions, styles.options)}
          ref={popupRef}
          offsetLeft={-70}
          parentRef={buttonRef}
          open={open}
        >
          <div>
            <ul className={styles.list}>
              {(file?.Meta?.ContentType === FILE_TYPES.PDF ||
                file?.contentType === FILE_TYPES.PDF) &&
                (file?.Meta?.Url || file?.url) && (
                  <li testid="preview" onClick={onPreview} className={styles.item}>
                    Preview
                  </li>
                )}
              <li testid="download" onClick={onDownload} className={styles.item}>
                Download
              </li>
              {deletable && (
                <li testid="delete" onClick={onDeleteHandler} className={styles.item}>
                  Delete
                </li>
              )}
            </ul>
          </div>
        </OptionsPopup>
        {isPreviewOpen && (
          <PdfViewModal
            isOpen={isPreviewOpen}
            file={file?.Meta}
            onClose={() => setIsPreviewOpen(false)}
          />
        )}
      </div>
    </PendingWrapper>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  deletable: PropTypes.bool,
  file: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    Meta: PropTypes.shape({
      Filename: PropTypes.string,
      Url: PropTypes.string,
      ContentType: PropTypes.string,
    }),
    Title: PropTypes.string,
    data: PropTypes.string,
    filename: PropTypes.string,
    documentName: PropTypes.string,
    contentType: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDelete: PropTypes.func,
};

Actions.defaultProps = {
  className: '',
  deletable: false,
  taskId: undefined,
  onDelete: () => {},
};

export default Actions;
