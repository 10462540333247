import { Tours } from 'pages/Workshop/Tours';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { getUserRolesMapSelector } from 'store/selectors/user';
import ClientFeed from './ClientFeed';
import ClientResults from './ClientResults/ClientResults';
import { ClientSearches } from './ClientSearches/ClientSearches';
import Feed from './Feed';
import AgentFeed from './AgentFeed';

import ListingDetail from './ListingDetail';
import { LocalHeader } from './LocalHeader';
import MySearches from './MySearches';
import Search from './Search';
import SearchResults from './SearchResults';
import { ClientTours } from './Tours';
import WithSelectedProperties from './WithSelectedProperties';
import AgentResults from './AgentResults/AgentResults';

export const Properties = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { isClient } = useSelector(getUserRolesMapSelector);

  const showComponent = () => {
    if (pathname === routes.properties) {
      history.replace(routes.feed);
      return;
    }

    switch (pathname) {
      case routes.search:
        return (
          <>
            <LocalHeader />
            <Search />
          </>
        );
      case routes.mySearches:
        return (
          <>
            <LocalHeader />
            <MySearches />
          </>
        );
      case routes.clientSearches:
        return (
          <>
            <LocalHeader />
            <ClientSearches />
          </>
        );
      case routes.searchResults:
        return (
          <>
            {isClient ? (
              <WithSelectedProperties>
                <ClientResults />
              </WithSelectedProperties>
            ) : (
              <WithSelectedProperties>
                <AgentResults />
              </WithSelectedProperties>
            )}
          </>
        );
      case routes.feed:
        return (
          <>
            {isClient ? (
              <WithSelectedProperties>
                <ClientFeed />
              </WithSelectedProperties>
            ) : (
              <WithSelectedProperties>
                <AgentFeed />
              </WithSelectedProperties>
            )}
          </>
        );
      case routes.clientTours:
        // The tours option for the agent was initially under the workshop.
        // DEV-5435: Move tours under the Properties section.
        return isClient ? <ClientTours /> : <Tours />;
      default:
        return <ListingDetail />;
    }
  };

  return <>{showComponent()}</>;
};
