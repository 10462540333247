import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'components';

const AvatarComponent = (props) => {
  const { src, className, avatarClassName, show, ...rest } = props;

  if (!show) return null;

  return <Avatar className={className} avatarClassName={avatarClassName} src={src} {...rest} />;
};

AvatarComponent.propTypes = {
  className: PropTypes.string,
  avatarClassName: PropTypes.string,
  src: PropTypes.string,
  show: PropTypes.bool,
};

AvatarComponent.defaultProps = {
  className: '',
  avatarClassName: '',
  src: undefined,
  show: true,
};

export default AvatarComponent;
