import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import {
  getIsOpenCompareModalSelector,
  getGroupedComparesSelector,
  getComparesSelector,
} from 'store/selectors/feed';
import {
  openCompareModalEffect,
  getComparePropertiesEffect,
  resetComparePropertiesStateEffect,
} from 'store/effects';
import { CompareModal } from 'components';
import { IDLE, PENDING } from 'settings/constants/apiState';
import CompareContent from './CompareContent';

import styles from './styles.module.scss';

const Compares = ({ className }) => {
  const dispatch = useDispatch();
  const isCompareModalOpen = useSelector(getIsOpenCompareModalSelector);
  const compares = useSelector(getComparesSelector);
  const groupedCompares = useSelector(getGroupedComparesSelector);

  useEffect(() => {
    if (isCompareModalOpen) {
      if (compares.state === IDLE) {
        dispatch(getComparePropertiesEffect());
      }
    } else if (!isCompareModalOpen && compares.state !== IDLE) {
      dispatch(resetComparePropertiesStateEffect());
    }
  }, [isCompareModalOpen]); // eslint-disable-line

  return (
    <CompareModal
      className={classNames(className, styles.modal)}
      innerHolderClassName={styles.modalHolder}
      onClose={() => dispatch(openCompareModalEffect({ open: false }))}
      isOpen={isCompareModalOpen}
      testid="compare_modal"
    >
      <CompareContent compares={groupedCompares} isPending={compares.state === PENDING} />
    </CompareModal>
  );
};

Compares.propTypes = {
  className: PropTypes.string,
};

Compares.defaultProps = {
  className: '',
};

export default Compares;
