import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { quoteRequestStatus, quotesStatusesMap } from 'settings/constants/quotes';
import { getUserId, getUserRoleSelector, getUserRolesMapSelector } from 'store/selectors/user';

import { THIRD_PARTY } from 'settings/constants/roles';
import { NoQuotes } from 'pages/Quotes/components/NoQuotes';

import styles from './styles.module.scss';
import { tableColumns } from 'pages/Quotes/components/Tables/GroupByDetailTableColumns/tableColumns';
import { GroupByTable } from 'pages/Quotes/components/Tables/GroupByTable';
import { CollapsibleTable } from '../CollapsibleTable';
import Details from '../../Content/Group/ByStatus/Details';

import { getThreadsListSelector } from 'store/selectors/sockets/threads';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { dispatch } from 'store';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { getConciergeSearchSelector } from 'store/selectors/concierge';

const GroupByDetailTable = (props) => {
  const { className, quotesList, isGroupedByClient, onArchive, groupedBy } = props;
  const userRole = useSelector(getUserRoleSelector);
  const threads = useSelector(getThreadsListSelector);
  const { isThirdParty, isClient } = useSelector(getUserRolesMapSelector);
  const userId = useSelector(getUserId);
  const { category: categoryId } = useSelector(getConciergeSearchSelector);
  const [detailQuote, setDetailQuote] = useState();

  const getCount = (status) => quotesList?.[status]?.length || 0;
  const getPendingStatus = () =>
    userRole === THIRD_PARTY ? quoteRequestStatus.PendingAnswer : quoteRequestStatus.New;
  const getPendingQuotes = (status) =>
    quotesList?.[status]?.filter(
      (quote) =>
        quote?.Status === quoteRequestStatus.PendingAnswer &&
        !quote.IsArchived &&
        quote?.DateViewedByPartner,
    );
  const getNewQuotes = (status) => {
    return quotesList?.[status]?.filter(
      (quote) =>
        quote?.Status === quoteRequestStatus.PendingAnswer &&
        !quote.IsArchived &&
        !quote?.DateViewedByPartner,
    );
  };
  const draftCount = getCount(quotesStatusesMap(userRole)[quoteRequestStatus.Draft]);
  const respondedCount = getCount(quotesStatusesMap(userRole)[quoteRequestStatus.Responded]);
  const pendingQuotes =
    getPendingQuotes(quotesStatusesMap(userRole)[quoteRequestStatus.PendingAnswer]) || [];
  const pendingCount = pendingQuotes.length || 0;
  const newQuotes =
    getNewQuotes(quotesStatusesMap(userRole)[quoteRequestStatus.PendingAnswer]) || [];
  const newCount =
    (quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.New]]?.filter(
      (quote) => !quote.IsArchived && quote?.Status === quoteRequestStatus.New,
    ).length || 0) + (newQuotes.length || 0);

  const initiateSendingMessageToPartner = (threadId) => {
    dispatch(openMessagesDrawerAction(true));

    dispatch(
      changeMessagesDrawerTypeAction({
        type: DRAWER_MESSAGES_TYPES.CHAT,
        params: {
          threadId: threadId,
        },
      }),
    );
  };

  const handleMessageButtonClick = (threadId) =>
    useEmailVerificationWall(
      ActionToInterrupt.MESSAGE_TO_PARTNER,
      initiateSendingMessageToPartner(threadId),
      {
        categoryId,
      },
    );

  const tableProps = {
    columns: tableColumns(
      groupedBy,
      onArchive,
      isClient,
      threads,
      userId,
      handleMessageButtonClick,
      userRole === THIRD_PARTY,
    ),
    onRow: (record) => {
      return {
        onClick: (event) => {
          setDetailQuote(record);
        },
      };
    },
    tableClassName: styles.quotationsTable,
  };

  return (
    <>
      <div testid="by_status" className={classNames(styles.byStatus, className)}>
        {draftCount > 0 ||
        pendingCount > 0 ||
        newCount > 0 ||
        respondedCount > 0 ||
        quotesList['ByStatus']?.length > 0 ||
        quotesList['ByArchieve']?.length > 0 ? (
          <>
            {groupedBy === 'ByStatus' && (
              <GroupByTable
                dataSource={quotesList['ByStatus']}
                {...tableProps}
                tableClassName={styles.quotationsListNonCollapsible}
              />
            )}
            {groupedBy === 'ByArchieve' && (
              <div className={styles.quotationsList}>
                <GroupByTable
                  dataSource={quotesList['ByArchieve']}
                  {...tableProps}
                  tableClassName={styles.quotationsListNonCollapsible}
                />
              </div>
            )}
            {groupedBy !== 'ByStatus' && groupedBy !== 'ByArchieve' && (
              <div className={styles.collapsibleTables}>
                {(isThirdParty ? pendingCount > 0 : pendingCount + newCount > 0) && (
                  <div className={styles.quotationsList}>
                    <CollapsibleTable
                      title="Pending"
                      dataSource={
                        userRole === THIRD_PARTY
                          ? pendingQuotes
                          : [
                              ...pendingQuotes,
                              ...newQuotes,
                              ...(quotesList?.[
                                quotesStatusesMap(userRole)[quoteRequestStatus.New]
                              ]?.filter((quote) => quote?.Status === quoteRequestStatus.New) || []),
                            ]
                      }
                      {...tableProps}
                    />
                  </div>
                )}
                {respondedCount > 0 && (
                  <div className={styles.quotationsList}>
                    <CollapsibleTable
                      title="Received"
                      dataSource={
                        quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.Responded]]
                      }
                      {...tableProps}
                    />
                  </div>
                )}

                {isThirdParty && newCount > 0 && (
                  <div className={styles.quotationsList}>
                    <CollapsibleTable
                      title="New"
                      dataSource={[
                        ...newQuotes,
                        ...(quotesList?.[
                          quotesStatusesMap(userRole)[quoteRequestStatus.New]
                        ]?.filter((quote) => quote?.Status === quoteRequestStatus.New) || []),
                      ]}
                      {...tableProps}
                    />
                  </div>
                )}
                {draftCount > 0 && (
                  <div className={styles.quotationsList}>
                    <CollapsibleTable
                      title="Drafts"
                      dataSource={
                        userRole === THIRD_PARTY
                          ? quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.New]]
                          : quotesList?.[quotesStatusesMap(userRole)[quoteRequestStatus.Draft]]
                      }
                      {...tableProps}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <NoQuotes />
          </>
        )}
      </div>
      {detailQuote && (
        <Details
          {...detailQuote}
          status={detailQuote?.Status}
          onClose={() => {
            setDetailQuote();
          }}
        />
      )}
    </>
  );
};

GroupByDetailTable.propTypes = {
  className: PropTypes.string,
  quotesList: PropTypes.shape({}).isRequired,
  isGroupedByClient: PropTypes.bool,
  onArchive: PropTypes.func,
  groupedBy: PropTypes.string,
};

GroupByDetailTable.defaultProps = {
  className: '',
  isGroupedByClient: false,
  onArchive: () => {},
  groupedBy: '',
};

export default GroupByDetailTable;
