import { NotFound } from 'pages';
import commonRoutesConfig from './common';
import protectedRoutesConfig from './protected';
import { featureFlags } from 'utils/featureFlags';

const allRoutes = [
  ...commonRoutesConfig,
  ...protectedRoutesConfig,
  { path: '*', component: NotFound },
];

export const protectedRoutes = allRoutes.filter((route) => !!route?.roles).map(({ path }) => path);
export const matchProtectedRoutes = (pathname) => {
  const pathnameSplit = pathname.split('/');

  return protectedRoutes.some((protectedRoute) => {
    const protectedSplit = protectedRoute.split('/');

    return pathnameSplit.every((pathPart, index) => {
      const protectPath = protectedSplit[index];

      if (protectPath && protectPath.startsWith(':')) {
        return true;
      }

      return protectPath === pathPart;
    });
  });
};

export const checkRouteRequiresCompletedProfile = (pathname) => {
  const pathnameSplit = pathname.split('/');

  const firstPart = pathnameSplit[1];
  // Very dummy check as currently no parts of the app except onboarding and wizard are allowed
  // without profile completion. Improve once needed.
  return (
    firstPart !== 'onboarding' && firstPart !== 'wizard' && firstPart !== 'subscriptionRequired'
  );
};

export const checkRouteRequiresPremiumPlan = (pathname) => {
  if (!featureFlags.paywall) {
    return false;
  }

  const pathnameSplit = pathname.split('/');

  const firstPart = pathnameSplit[1];
  // The current logic of Premium plans for agents is quite simple, and we basically want to restrict
  // access to the main functionality of the app
  return (
    firstPart !== 'onboarding' &&
    firstPart !== 'wizard' &&
    firstPart !== 'settings' &&
    firstPart !== 'subscriptionRequired' &&
    firstPart !== 'verify-email'
  );
};

export default allRoutes;
