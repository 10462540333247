import { handleActions } from 'redux-actions';
import { cloneDeep, get } from 'lodash-es';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetAllFormsAction,
  requestGetFormByIdAction,
  requestGetRefereshFormAction,
  requestUpdateFormFiledAction,
  requestSetSelectedFormFieldAction,
  requestGetAllFormFolders,
  requestGetFolderByIdAction,
  requestSetActiveFolderAction,
  requestSetArchiveFolderAction,
  requestGetSearchFormAction,
} from 'store/actions/adminPanel';

const initialData = {
  state: IDLE,
  data: null,
  folders: null,
  selectedForm: null,
  selectedFormField: null,
  activeFolderId: -1,
  activeFolderName: '',
  meta: {},
  sort: {
    order: 'asc',
    fields: ['Name'],
  },
  search: {
    searchString: '',
    type: '',
  },
  questions: [
    {
      id: '1',
      element: 'text',
      label: 'Free Text',
      iconBG: 'rgb(55, 156, 251)',
      configuration: {},
    },
    {
      id: '2',
      element: 'checkBox',
      label: 'List Box',
      iconBG: 'rgb(214, 92, 153)',
      configuration: {},
    },
    {
      id: '3',
      element: 'hidden',
      label: 'Hidden Text',
      iconBG: 'rgb(92, 214, 200)',
      configuration: {},
    },
    {
      id: '4',
      element: 'radio',
      label: 'Yes/No',
      iconBG: 'rgb(224, 133, 179)',
      configuration: {},
    },
    {
      id: '5',
      element: 'currency',
      label: 'Currency',
      iconBG: 'rgb(92, 214, 200)',
      configuration: {},
    },
    {
      id: '6',
      element: 'date',
      label: 'Date',
      iconBG: 'rgb(133, 224, 214)',
      configuration: {},
    },
    {
      id: '7',
      element: 'password',
      label: 'Password',
      iconBG: 'rgb(92, 214, 200)',
      configuration: {},
    },
    {
      id: '8',
      element: 'number',
      label: 'Number',
      iconBG: 'rgb(92, 214, 200)',
      configuration: {},
    },
    {
      id: '9',
      element: 'email',
      label: 'Email',
      iconBG: 'rgb(155, 206, 253)',
      configuration: {},
    },
    {
      id: '10',
      element: 'file',
      label: 'File Upload',
      iconBG: 'rgb(253, 208, 155)',
      configuration: {},
    },
    {
      id: '11',
      element: 'phone',
      label: 'Phone Number',
      iconBG: 'rgb(173, 235, 228)',
      configuration: {},
    },
    {
      id: '12',
      element: 'group',
      label: 'Question Group',
      iconBG: 'rgb(251, 161, 55)',
      configuration: {},
    },
  ],
};

export default handleActions(
  {
    [appLogoutAction]: () => cloneDeep(initialData),
    [requestGetAllFormsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: payload.data?.value,
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestGetAllFormFolders]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      folders: payload.data?.value,
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestSetActiveFolderAction]: (state, { payload }) => ({
      ...state,
      activeFolderId: payload.id,
      activeFolderName: payload.Name,
    }),
    [requestGetFormByIdAction]: (state, { payload }) => ({
      ...state,
      selectedForm: payload.data,
    }),
    [requestGetFolderByIdAction]: (state, { payload }) => ({
      ...state,
      data: payload.data?.value,
    }),
    [requestSetSelectedFormFieldAction]: (state, { payload }) => ({
      ...state,
      selectedFormField: payload,
    }),
    [requestGetRefereshFormAction]: (state, { payload }) => ({
      ...state,
      selectedForm: payload.data,
    }),
    [requestUpdateFormFiledAction]: (state, { payload }) => ({
      ...state,
      selectedForm: {
        ...state.selectedForm,
        value: payload,
      },
    }),
    [requestSetArchiveFolderAction]: (state, { payload }) => ({
      ...state,
    }),
    [requestGetSearchFormAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: payload.data?.value,
      meta: get(payload, 'meta', initialData.meta),
    }),
  },
  initialData,
);
