import { createAction } from 'redux-actions';

export const requestGetTransactionRolesDashboardAction = createAction(
  'REQUEST/GET_TRANSACTION_ROLES_DASHBOARD',
);
export const requestGetTransactionRoleDashboardAction = createAction(
  'REQUEST/GET_TRANSACTION_ROLE_DASHBOARD',
);
export const requestPutTransactionRoleDashboardAction = createAction(
  'REQUEST/PUT_TRANSACTION_ROLE_DASHBOARD',
);
export const requestPostTransactionRoleDashboardAction = createAction(
  'REQUEST/POST_TRANSACTION_ROLE_DASHBOARD',
);
export const requestDeleteTransactionRoleDashboardAction = createAction(
  'REQUEST/DELETE_TRANSACTION_ROLE_DASHBOARD',
);
export const requestPutActivateTransactionRoleDashboardAction = createAction(
  'REQUEST/PUT_ACTIVATE_TRANSACTION_ROLE_DASHBOARD',
);
export const searchTransactionRolesDashboardAction = createAction(
  'DASHBOARD/SEARCH_TRANSACTION_ROLES',
);
