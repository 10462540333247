import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  createUpdateOfferAction,
  offerListAction,
  updateOfferIterationAction,
  sendCounterOfferAction,
  selectedOffersForComparison,
} from 'store/actions/offers';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  selectedOffers: {},
};

export default handleActions(
  {
    [createUpdateOfferAction as any]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.value', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [offerListAction as any]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.value', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [updateOfferIterationAction as any]: (state, { payload }) => ({
      ...state,
      state: payload?.state,
    }),
    [sendCounterOfferAction as any]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.value', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [selectedOffersForComparison as any]: (state, { payload }) => ({
      ...state,
      selectedOffers: { ...payload },
    }),
    [appLogoutAction as any]: () => initialData,
  },
  initialData,
);
