import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getTaskCategoriesEffect,
  getTransactionDocumentsFilterEffect,
  setTransactionDocumentsFilterEffect,
  setTransactionDocumentsFilterLoadingEffect,
  setTransactionDocumentsPageEffect,
} from 'store/effects/transactions';

import { FilterSections } from './FilterSections';
import { formatDate } from 'helpers';
import { PENDING } from 'settings/constants/apiState';

export const Filter = ({ setIsFilterApplied }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTaskCategoriesEffect());
  }, []);

  const fetchDocuments = (payload) => {
    dispatch(setTransactionDocumentsFilterLoadingEffect({ state: PENDING }));
    dispatch(setTransactionDocumentsPageEffect({ page: 0 }));
    dispatch(setTransactionDocumentsFilterEffect(payload));
    dispatch(getTransactionDocumentsFilterEffect());
  };

  const formatFilterDate = (date) => {
    if (date === undefined || !date) return null;
    return formatDate(date, 'YYYY-MM-DD');
  };

  const doneHandler = ({ userIds, tags, createdFrom, createdTo, editedFrom, editedTo }) => {
    const payload = {
      uploaderIds: userIds,
      categoryIds: tags,
      createdFrom: formatFilterDate(createdFrom),
      createdTo: formatFilterDate(createdTo),
      editedFrom: formatFilterDate(editedFrom),
      editedTo: formatFilterDate(editedTo),
    };
    fetchDocuments(payload);
  };

  const resetHandler = () => {
    fetchDocuments({
      uploaderIds: null,
      categoryIds: null,
      createdFrom: null,
      createdTo: null,
      editedFrom: null,
      editedTo: null,
    });
  };

  return (
    <FilterSections
      setIsFilterApplied={setIsFilterApplied}
      onDone={doneHandler}
      onReset={resetHandler}
    />
  );
};
