import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { PageWrapper } from 'components-antd';
import { QuoteRightSidebar, QuoteLeftSidebar } from 'pages/FormBuilder';
import { FormFooter } from '../components';
import { getQuoteFormBuilderEffect, resetQuoteFormBuilderEffect } from 'store/effects/formBuilder';
import {
  getQuoteFormCurrentQuestionSelect,
  getQuoteFormMetaSelect,
} from 'store/selectors/formBuilder';
import { FORM_MODE } from 'app-constants';
import { QuestionContainer } from '../QuestionContainer';

import styles from './styles.module.scss';

export const QuoteForm = () => {
  const dispatch = useDispatch();
  const { versionId } = useParams<{ versionId: string }>();
  const { formId } = useSelector(getQuoteFormMetaSelect);

  const question = useSelector(getQuoteFormCurrentQuestionSelect);

  useEffect(() => {
    if (versionId) dispatch(getQuoteFormBuilderEffect({ versionId }));
  }, [versionId]);

  useEffect(() => {
    return () => {
      dispatch(resetQuoteFormBuilderEffect());
    };
  }, []);

  return (
    <PageWrapper
      SiderComponent={QuoteLeftSidebar}
      RightSiderComponent={QuoteRightSidebar}
      Footer={formId && FormFooter}
      mainPageContentStyle={styles.questionContainer}
    >
      <QuestionContainer isBuilder={true} mode={FORM_MODE.Edit} formQuestion={question} />
    </PageWrapper>
  );
};
