import {
  postRecommendPartnerByAgent,
  deleteUnrecommendPartnerByAgent,
  getRecommendedPartnerByAgentList,
} from 'api/partners';
import { getPartners } from 'api/admin/partners';
import Api from 'store/effects/core/api';
import {
  requestGetPartnersListAction,
  partnersChangeRecommendPartnerAction,
  requestGetRecommendedPartnersByAgentListAction,
} from 'store/actions/partners';
import { getState } from 'store';

const getConciergeSearchedState = () => {
  const state = getState();
  const {
    conciergeConfiguration: { data },
  } = state;

  return {
    areasOfOperation: data.areasOfOperation,
    categories: [data.category],
    services: data.services,
  };
};

export const requestGetPartnersListByCategoryIdEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetPartnersListAction,
    method: getPartners,
  });
  return cfg
    ? sendRequest(cfg, options, cb)
    : sendRequest(getConciergeSearchedState(), options, cb);
};

export const requestChangeRecommendPartnerStatus = (cfg, cb) => {
  if (!cfg?.recommend) {
    const sendRequest = Api.execBase({
      action: partnersChangeRecommendPartnerAction,
      method: postRecommendPartnerByAgent,
    });

    return sendRequest(cfg, {}, cb);
  }

  const sendRequest = Api.execBase({
    action: partnersChangeRecommendPartnerAction,
    method: deleteUnrecommendPartnerByAgent,
  });

  return sendRequest(cfg, {}, cb);
};

export const requestGetRecommendedPartnersByAgentListEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetRecommendedPartnersByAgentListAction,
    method: getRecommendedPartnerByAgentList,
  });

  return cfg
    ? sendRequest(cfg, options, cb)
    : sendRequest(getConciergeSearchedState(), options, cb);
};
