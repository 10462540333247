import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { PENDING } from 'settings/constants/apiState';

import styles from './styles.module.scss';
import { OnboardingContinueButton } from './components/OnboardingContinueButton';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

const AccountSetupIntroScreen = (props) => {
  const { onNext } = props;
  const { onBoarding } = useSelector(getOnBoardingData);

  const isPending = onBoarding.state === PENDING;

  return (
    <div className={styles.accountIntro}>
      <Question>Let&apos;s set up your Mosaik profile.</Question>
      <AnswersContainer>
        <p className={styles.text}>
          Your profile will be visible to any clients, collaborators, and team members you invite to
          Mosaik. You can edit it anytime.
        </p>
      </AnswersContainer>
      <ButtonsContainer>
        <OnboardingContinueButton
          className={styles.submit}
          isPending={isPending}
          onClick={onNext}
        />
      </ButtonsContainer>
    </div>
  );
};

AccountSetupIntroScreen.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

AccountSetupIntroScreen.defaultProps = {
  className: '',
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default AccountSetupIntroScreen;
