import Api from 'store/effects/core/api';
import {
  getTransactionDocumentsFilterAction,
  getTransactionDocumentAction,
  getTransactionDocumentsCategoriesAction,
} from 'store/actions/transactions';
import {
  getTransactionDocumentsFilter,
  getTransactionDocument,
  getTransactionDocumentCategories,
} from 'api/transactions/documents/read';
import { get } from 'lodash-es';
import { getState } from 'store';

export const getTransactionDocumentsFilterEffect = (cfg, options = { silent: true }, cb) => {
  const state = getState();
  const transactionData = get(state, 'transaction.data');
  const { filter, sort, countPerPage, page } = get(state, 'transactionDocuments');
  const requestParams = {
    action: getTransactionDocumentsFilterAction,
    method: getTransactionDocumentsFilter,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  const config = {
    transactionId: cfg?.transactionId || transactionData?.Id,
    PerPage: countPerPage,
    Page: page || undefined,
    Title: filter?.name || undefined,
    CategoryIds: (filter?.categoryIds || []).map((val) => val) || undefined,
    UploaderIds: (filter?.uploaderIds || []).map((val) => val) || undefined,
    CreatedFrom: filter?.createdFrom || undefined,
    CreatedTo: filter?.createdTo || undefined,
    EditedFrom: filter?.editedFrom || undefined,
    EditedTo: filter?.editedTo || undefined,
    SortOrder: sort?.order?.toUpperCase() || undefined,
    SortBy: sort?.fields?.[0] || undefined,
  };

  return sendRequest(config, options, cb);
};

export const getTransactionDocumentEffect = (cfg, options = { silent: true }, cb) => {
  const state = getState();
  const transactionData = get(state, 'transaction.data');
  const requestParams = { action: getTransactionDocumentAction, method: getTransactionDocument };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  const config = {
    transactionId: transactionData?.Id,
    documentId: cfg?.documentId,
  };

  return sendRequest(config, options, cb);
};

export const getTransactionDocumentCategoriesEffect = (cfg, options = { silent: true }, cb) => {
  const requestParams = {
    action: getTransactionDocumentsCategoriesAction,
    method: getTransactionDocumentCategories,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  const config = {
    transactionId: cfg?.transactionId,
  };

  return sendRequest(config, options, cb);
};
