import PropTypes from 'prop-types';

const Plus = ({ className }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 3.76619L4 12.2338C4 13.0111 4.84797 13.4912 5.5145 13.0913L12.5708 8.85749C13.2182 8.46909 13.2182 7.53091 12.5708 7.14251L5.5145 2.9087C4.84797 2.50878 4 2.9889 4 3.76619Z"
      stroke="#515151"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Plus.propTypes = {
  className: PropTypes.string,
};

Plus.defaultProps = {
  className: '',
};

export default Plus;
