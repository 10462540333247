import React, { memo, useMemo } from 'react';

const Collaborators = memo(({ className }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="6" r="3" stroke="#515151" strokeWidth="2" />
          <circle cx="18.75" cy="17.25" r="3" stroke="#515151" strokeWidth="2" />
          <circle cx="5.25" cy="17.25" r="3" stroke="#515151" strokeWidth="2" />
          <path
            d="M8.92045 6.66141C7.49575 7.30297 6.30701 8.37348 5.52023 9.72342C4.73345 11.0734 4.38792 12.6353 4.53191 14.1912"
            stroke="#515151"
            strokeWidth="1.875"
            strokeLinecap="round"
          />
          <path
            d="M7.67764 19.6292C8.95455 20.5297 10.4808 21.0089 12.0433 20.9999C13.6057 20.9909 15.1264 20.4941 16.3928 19.5789"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M15.0796 6.66141C16.5042 7.30297 17.693 8.37348 18.4798 9.72342C19.2666 11.0734 19.6121 12.6353 19.4681 14.1912"
            stroke="#515151"
            strokeWidth="1.875"
            strokeLinecap="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

export default Collaborators;
