import { createAction } from 'redux-actions';

export const getTransactionDocumentsFilterAction = createAction('GET_TRANSACTION_DOCUMENTS_FILTER');
export const getTransactionDocumentAction = createAction('GET_TRANSACTION_DOCUMENT');
export const resetTransactionDocumentsSearchAction = createAction(
  'RESET_TRANSACTION_DOCUMENTS_FILTER',
);
export const getTransactionDocumentsCategoriesAction = createAction(
  'GET_TRANSACTION_DOCUMENTS_CATEGORIES',
);
