import { get } from 'lodash-es';

import { getFolderListAction } from 'store/actions/formBuilder';
import { FormBuilderState } from 'types';

export const folderListReducer = {
  [getFolderListAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      folderList: {
        folders: get(payload, 'data.value.folders'),
        state: payload.state,
      },
    },
  }),
};
