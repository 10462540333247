import classNames from 'classnames';
import { Icons } from 'pages/FormProcess/Icons';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getDynamicFormSelector } from 'store/selectors/requestFormProcess';

export const FormFilesName = (props) => {
  const { documentName } = props;
  const { dynamicFormDocuments } = useSelector(getDynamicFormSelector);

  const hasMultipleDocuments = dynamicFormDocuments && dynamicFormDocuments.length > 1;

  return (
    <div className={styles.fileNameWrap}>
      <div
        className={classNames(styles.fileIconBundle, {
          [styles.singleFileIcon]: !hasMultipleDocuments,
        })}
      >
        <Icons variant={hasMultipleDocuments ? 'FileBundle' : 'File'} />
      </div>
      <p className={styles.documentName}>
        {documentName}
        <span className={styles.documentsCount}>({dynamicFormDocuments?.length})</span>
      </p>
    </div>
  );
};
