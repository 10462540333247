/* eslint-disable no-unsafe-optional-chaining */
import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { SORT_DESC } from 'settings/constants/sort';
import { getMultipleModSelector, getFeedSortSelector } from 'store/selectors/feed';
import { setMultipleModeEffect, resetFullDataComparePropertiesEffect } from 'store/effects';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { sortFeedEffect } from 'store/effects/feed';
import Filter from './Filter';
import Sort from './Sort';
import Multiple from './Multiple';

import styles from './styles.module.scss';

const Actions = ({ className }) => {
  const dispatch = useDispatch();
  const multiple = useSelector(getMultipleModSelector);
  const sort = useSelector(getFeedSortSelector);
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);

  const sortCases = useMemo(() => {
    if (isAgent) {
      return [
        { label: 'Client Match', value: 'ClientsMatched' },
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...Sort.defaultProps?.sortCases,
      ];
    }
    return Sort.defaultProps?.sortCases || [];
  }, [isAgent]);

  useEffect(() => {
    if (!sort?.fields?.length) {
      if (isAgent) {
        dispatch(sortFeedEffect({ order: SORT_DESC, fields: ['ClientsMatched'] }));
      } else if (isClient) {
        dispatch(sortFeedEffect({ order: SORT_DESC, fields: ['MatchScore'] }));
      }
    }
  }, [isAgent, isClient, dispatch, sort]);

  const onClickMultiple = () => {
    if (!multiple) {
      dispatch(setMultipleModeEffect(true));
    } else {
      dispatch(setMultipleModeEffect(false));
      dispatch(resetFullDataComparePropertiesEffect());
    }
  };

  return (
    <div className={classNames(styles.actions, className)}>
      <Filter />
      <Sort sortCases={sortCases} customleft={0} />
      <Multiple className={classNames({ [styles.active]: multiple })} onClick={onClickMultiple} />
    </div>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
};

Actions.defaultProps = {
  className: '',
};

export default Actions;
