import { Address } from './components/Address';
import { AgentDetails } from '../../../../components';
import { Notes } from './components/Notes';
import { DateAndTime } from './components/DateAndTime';

import styles from './styles.module.scss';
import { Agents } from './components/Agents';
import { Row, Col } from 'antd';

export const OfferDetails = (props) => {
  const { offerLinkDetails, mobileWidth } = props;
  const { DisplaySubmissionPageNotes, DisplayDeadlineOnPortal } = offerLinkDetails || {};

  return (
    <Row gutter={mobileWidth ? [16, 16] : [24, 24]}>
      <Col xs={24} sm={12} md={24}>
        <div className={styles.card}>
          <Agents
            SellerAgent={offerLinkDetails?.SellerAgent}
            Brokerage={offerLinkDetails?.Agent?.Brokerage}
            ListingAgentName={offerLinkDetails?.ListingAgentName}
            ListingAgentEmail={offerLinkDetails?.ListingAgentEmail}
          />
        </div>
      </Col>

      {DisplayDeadlineOnPortal && (
        <Col xs={24} sm={12} md={24}>
          <div className={styles.card}>
            <DateAndTime
              deadlineDate={offerLinkDetails?.DeadlineDate}
              deadlineTime={offerLinkDetails?.DeadlineDate}
            />
          </div>
        </Col>
      )}
      {DisplaySubmissionPageNotes && (
        <Col xs={24} sm={12} md={24}>
          <div className={styles.card}>
            <Notes offerLinkDetails={offerLinkDetails} />
          </div>
        </Col>
      )}
    </Row>
  );
};
