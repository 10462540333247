import { forwardRef, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Popover } from 'antd';

import { initiateImageAnalysisEffect, setComparesEffect } from 'store/effects';
import { getUserSelector, getUserRolesMapSelector } from 'store/selectors/user';
import { isEmpty } from 'lodash';
import { ImgSlider, Checkbox } from 'components';
import { FullArrowRight } from 'components/Icons';
import {
  getPropertyAddressWithHash,
  getSearchCriteriaFormattedString,
  formatUsd,
  extractOrderedPhotosForFiltering,
  filterImagesOnlyFromPhotoUrls,
  filterImagesOnly,
} from 'helpers';
import {
  getMultipleModSelector,
  getComparesSelector,
  getAllProperyImageProcessesSelector,
  getSubFilterTypeSelector,
} from 'store/selectors/feed';
import Info from './Info';
import Status from './Status';
import Match from './Match';
import Comment from './Comment';
import Favorite from './Favorite';
import Applicants from './Applicants';
import Share from './Share';
import RecommendedBy from './RecommendedBy';
import { LocationService } from 'services';
import { TooltipIcon } from 'components/Icons';
import { resetKitchenAvgSizeAction, setKitchenAvgSizeAction } from 'store/actions/propertyFeatures';
import { openNewTab } from 'services/newTab';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';
import { initialImageAnalysisAction } from 'store/actions/feed';
import { subFilterTypes } from 'settings/constants/properties';
import moment from 'moment';
import { getBathsTotalNum } from 'helpers/utils';
const Property = forwardRef((props) => {
  const {
    className,
    style,
    isClient: propsIsClient,
    threadsPerPropertyId,
    ...propertyProps
  } = props;

  const {
    Id,
    PhotoUrls,
    SellingPrice,
    NumBeds,
    NumBathsTotal,
    SquareFeet,
    IsAuction,
    SearchInstanceId,
    SearchInstanceName,
    SearchCriterias,
    CustomKitchenFeatures,
    PropertyHistory = {},
    Suggestions,
    OpenHouses,
    renderingIndex,
    shouldScrollIntoView,
    getPageNumber,
    ClosePrice,
    Media,
    PhotoCount,
    Market,
  } = propertyProps || {};

  const photos = !isEmpty(Media)
    ? filterImagesOnly(extractOrderedPhotosForFiltering(Media, Media.length), Market)
    : filterImagesOnlyFromPhotoUrls(PhotoUrls, Market);
  const { Address, ActivityStatus } = propertyProps || {};
  const { oldPrice = 0, newPrice = 0, newStatus = '', oldStatus = '' } = PropertyHistory;
  const [{ Agent: RecommendedByAgent }] = Suggestions || [{}];
  const dispatch = useDispatch();
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  const multiple = useSelector(getMultipleModSelector);
  const compares = useSelector(getComparesSelector);
  const imageProcesses = useSelector(getAllProperyImageProcessesSelector);
  const type = useSelector(getSubFilterTypeSelector);

  const propertyRef = useRef(null);

  const user = useSelector(getUserSelector);

  const hideMatch =
    user.data.Email.includes('client@mosaik.io') && !user.data.Email.includes('+client@mosaik.io');
  const PropertyStatusMapping = {
    Active: 'Active',
    Contingent: 'Contingent',
    ActiveUnderContract: 'Active Under Contract',
    Pending: 'Pending',
    Expired: 'Expired',
    Closed: 'Closed',
    Canceled: 'Canceled',
    Delete: 'Delete',
    Hold: 'Hold',
    Withdrawn: 'Withdrawn',
    ComingSoon: 'Coming Soon',
  };
  const isChecked = compares?.data?.findIndex((property) => property?.Id === Id) !== -1;
  const [shouldShowBorder, setShouldShowBorder] = useState(false);
  const [nearestOpenHouse] = OpenHouses.length ? OpenHouses : [undefined];

  const { Line1, Line2 } = getPropertyAddressWithHash({ address: Address });
  const isNotProd = window.location.hostname !== 'app.mosaik.io';

  const getFeatureInfoText = () => (
    <ul className={classNames(styles.featureWrapper, styles.feature)}>
      <li>
        {typeof NumBeds === 'number' ? (
          <span testid="property_beds" className={classNames(styles.accent, styles.amount)}>
            {NumBeds}
          </span>
        ) : null}
        <span>beds</span>
      </li>
      <li>
        <span testid="property_baths" className={classNames(styles.accent, styles.amount)}>
          {getBathsTotalNum(NumBathsTotal)}
        </span>
        <span>baths</span>
      </li>
      <li>
        <NumberFormat
          displayType="text"
          thousandSeparator
          value={SquareFeet}
          renderText={(val) => (
            <span testid="property_feet" className={classNames(styles.accent, styles.amount)}>
              {val}
            </span>
          )}
        />
        <span>sqft</span>
      </li>
    </ul>
  );

  const getStreetInfoText = () =>
    Line1 ? (
      <p testid="property_address" className={(styles.featureWrapper, styles.line1)}>
        {Line1}
      </p>
    ) : (
      <></>
    );

  const getLocationInfoText = () => (
    <div className={classNames(styles.location, styles.street)}>
      <span testid="property_location">{`${Line2}`}</span>
    </div>
  );

  const onCheckHandler = () => {
    const propertyId = Id;
    if (!isChecked) {
      dispatch(setComparesEffect([...compares.data, propertyProps]));
      if (!Object.keys(imageProcesses).includes(propertyId)) {
        dispatch(initialImageAnalysisAction({ data: { [Id]: false } })); //start with default false
        dispatch(initiateImageAnalysisEffect({ Id }));
      }
    } else {
      const newCompares = compares.data.filter((property) => property.Id !== propertyId);
      dispatch(setComparesEffect(newCompares));
    }
  };

  const getMetaInfo = () => {
    if (isAgent && multiple)
      return (
        <Checkbox
          checkboxClassName={styles.checkbox}
          value={Id}
          checked={isChecked}
          testid="multiple_checkbox"
          hasOuterClick={true}
          className={compares.data.length === 5 && !isChecked ? styles.pointerEventsNone : ''}
        />
      );
    if ((isClient || propsIsClient) && !hideMatch) {
      return (
        <>
          <div className={styles.matchContainer}>
            <Match
              propertyInfo={propertyProps}
              isPriceReduction={isClient && type === subFilterTypes.PRICE_REDUCTIONS}
            />
            {multiple && (
              <div className={styles.checkboxContainer}>
                <Checkbox
                  checkboxClassName={styles.checkbox}
                  value={Id}
                  checked={isChecked}
                  testid="multiple_checkbox"
                  hasOuterClick={true}
                  className={
                    compares.data.length === 5 && !isChecked ? styles.pointerEventsNone : ''
                  }
                />
              </div>
            )}
          </div>
          {!propsIsClient && !multiple ? (
            <div className={styles.comFavMetaInfo}>
              <Comment propertyInfo={propertyProps} threadsPerPropertyId={threadsPerPropertyId} />
              <Favorite propertyInfo={propertyProps} isClient={propsIsClient} />
            </div>
          ) : null}
        </>
      );
    }

    if (isAgent) {
      return (
        <>
          <div>
            <Applicants propertyInfo={propertyProps} />
            <Favorite propertyInfo={propertyProps} className={styles.agentFavorite} />
          </div>
          <div className={styles.comFavMetaInfo}>
            <Comment propertyInfo={propertyProps} threadsPerPropertyId={threadsPerPropertyId} />
            <Share propertyInfo={propertyProps} />
          </div>
        </>
      );
    }
  };
  const locationService = new LocationService();
  const location = useLocation();
  const query = locationService.setLocation(location).getQuery();

  const storeCustomFeatures = () => {
    dispatch(resetKitchenAvgSizeAction());

    if (CustomKitchenFeatures?.IsBiggerThanAverage) {
      dispatch(setKitchenAvgSizeAction({ kitchenAvgSize: CustomKitchenFeatures?.AvgSize }));
    }
  };

  const propertyDetailLink = (id, activeTab, searchId, renderingIndex) => {
    let route = routes.feedListingDetail.replace(':id', `${id}`);
    if (activeTab && searchId) {
      route = `${route}?activeTab=${activeTab}&searchId=${searchId}`;
    } else if (activeTab) {
      route = `${route}?activeTab=${activeTab}`;
    } else if (searchId) {
      route = `${route}?searchId=${searchId}`;
    }

    if (renderingIndex) {
      route += route.includes('?')
        ? `&renderingIndex=${renderingIndex}`
        : `?renderingIndex=${renderingIndex}`;
    }
    if (getPageNumber && getPageNumber()) {
      route += route.includes('?')
        ? `&pageNumber=${getPageNumber()}`
        : `?pageNumber=${getPageNumber()}`;
    }
    return route;
  };

  const redirectToDetails = (e) => {
    storeCustomFeatures();
    const path = propertyDetailLink(Id, query?.activeTab, SearchInstanceId, renderingIndex);

    e.preventDefault();
    if (multiple && !(compares.data.length === 5 && !isChecked)) onCheckHandler();
    else if (!multiple) openNewTab(path);
  };

  useEffect(() => {
    if (shouldScrollIntoView && shouldScrollIntoView()) {
      propertyRef?.current?.scrollIntoView({ behavior: 'smooth' });
      setShouldShowBorder(true);
    }
  }, []);

  return (
    <div
      testid="feed_property"
      ref={propertyRef}
      className={classNames(
        styles.propertyWrapper,
        {
          [styles.focusedProperty]: shouldShowBorder,
        },
        {
          [styles.normalBorder]: !shouldShowBorder,
        },
        {
          [styles.notAllowed]: compares.data.length === 5 && !isChecked,
        },
        className,
      )}
      style={style}
      onClick={redirectToDetails}
    >
      <Link className={styles.photos}>
        <Status
          className={styles.status}
          status={
            nearestOpenHouse
              ? `Open ${
                  nearestOpenHouse?.date !== 'Today'
                    ? nearestOpenHouse?.date?.slice(0, 3)
                    : nearestOpenHouse?.date
                }, ${nearestOpenHouse?.time?.replaceAll(':00', '')}`
              : ActivityStatus
          }
        />
        <ImgSlider className={styles.photosHolder} images={photos} />
      </Link>
      <div className={styles.inner}>
        <div className={styles.info}>
          {oldStatus && newStatus && (
            <span className={styles.statusHistory}>
              <span className={styles.oldStatus}>{PropertyStatusMapping[oldStatus]}</span>
              <FullArrowRight className={styles.arrowRightIcon} />
              <span className={styles.newStatus}>{PropertyStatusMapping[newStatus]}</span>
            </span>
          )}
          {Number(SellingPrice) > 0 ? (
            <NumberFormat
              displayType="text"
              thousandSeparator
              value={formatUsd(
                ActivityStatus == 'Closed' && ClosePrice ? ClosePrice : SellingPrice,
                { maximumFractionDigits: 0 },
              )}
              prefix="$"
              renderText={(val) => (
                <span testid="property_price" className={styles.price}>
                  {oldPrice && newPrice ? (
                    <>
                      <NumberFormat
                        displayType="text"
                        thousandSeparator
                        value={parseInt(oldPrice)}
                        prefix="$"
                        className={styles.oldPrice}
                      ></NumberFormat>
                      <FullArrowRight className={styles.arrowRightIcon} />
                      <NumberFormat
                        displayType="text"
                        thousandSeparator
                        value={parseInt(newPrice)}
                        prefix="$"
                      ></NumberFormat>
                    </>
                  ) : (
                    val
                  )}
                  {IsAuction && <span className={styles.auction}>(auction)</span>}
                  {SearchInstanceName && (
                    <Popover
                      content={
                        <div>
                          <div>{SearchInstanceName}</div>
                          <div>{getSearchCriteriaFormattedString(SearchCriterias)?.fullInfo}</div>
                        </div>
                      }
                      placement="top"
                      overlayClassName={classNames('mosaikTooltip')}
                      arrow={true}
                    >
                      <span>
                        <TooltipIcon className={styles.tooltip} />
                      </span>
                    </Popover>
                  )}
                </span>
              )}
            />
          ) : (
            <div className={styles.price}>Price Unavailable</div>
          )}
          <Info text={getFeatureInfoText()} />
          <Info text={getStreetInfoText()} />
          <Info text={getLocationInfoText()} />
          {/* {isNotProd && (
            <span className={styles.matchDate}>
              {moment(MatchScorePopulatedAt).format('YYYYMMDD:HH:mm:ss')}
            </span>
          )} */}
          <RecommendedBy recommendedBy={propertyProps?.RecommendedBy || RecommendedByAgent} />
        </div>
        <div className={styles.metaInfo}>{getMetaInfo()}</div>
      </div>
    </div>
  );
});

Property.propTypes = {
  className: PropTypes.string,
  Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  PhotoUrls: PropTypes.arrayOf(PropTypes.string),
  SellingPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  SquareFeet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  NumBeds: PropTypes.number,
  NumBathsTotal: PropTypes.number,
  Address: PropTypes.shape({
    Line1: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
    Zip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  ActivityStatus: PropTypes.string,
  MatchScorePopulatedAt: PropTypes.string,
  MatchScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape({}),
  isClient: PropTypes.bool,
  threadsPerPropertyId: PropTypes.shape({}).isRequired,
  PropertyHistory: PropTypes.shape({
    oldStatus: PropTypes.number,
    newStatus: PropTypes.number,
    oldPrice: PropTypes.string,
    newPrice: PropTypes.string,
  }),
  renderingIndex: PropTypes.number,
  getPageNumber: PropTypes.func,
  shouldScrollIntoView: PropTypes.func,
};

Property.defaultProps = {
  className: '',
  PhotoUrls: [],
  NumBeds: null,
  NumBathsTotal: null,
  SellingPrice: '',
  SquareFeet: '',
  ActivityStatus: '',
  style: {},
  MatchScore: null,
  isClient: undefined,
  PropertyHistory: {},
  OpenHouses: [],
  renderingIndex: 0,
  getPageNumber: () => 0,
  shouldScrollIntoView: () => false,
};

export default Property;
