import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { filter, upperCase, includes, cloneDeep } from 'lodash-es';
import classNames from 'classnames';

import { Modal } from 'components-antd';
import Button from 'components/Button';
import Name from 'components/ClientsDrawer/ProfileClient/Name';
import { getTeamPermittedList } from 'api/teamList';
import { Avatar, Wrapper as PendingWrapper, SearchFieldWithDebounce } from 'components';
import { getUserId } from 'store/selectors/user';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { Check } from 'components/Icons';

import styles from './styles.module.scss';

type agentDetail = {
  Id: number;
  AvatarUrl: any;
  FirstName: string;
  LastName: string;
  selected: boolean;
};
export const AgentSelectorModal = ({ open, onClose, onSubmit }) => {
  const userId = useSelector(getUserId);
  const [isPending, setIsPending] = useState(true);
  const [agentList, setAgentList] = useState<agentDetail[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<agentDetail>();
  const [searchText, setSearchText] = useState<string>('');

  const fetchManagaedTeamMemberDetails = async () => {
    const { data, status } = await getTeamPermittedList();
    if (status === 200) {
      const managedAgents = data.filter(({ Id }) => Id !== userId);
      setAgentList(managedAgents);
      setIsPending(false);
    }
  };

  const teamSearchFilter = (search, list) => {
    if (search === '') return list;
    return filter(
      list,
      ({ FirstName, LastName }) =>
        includes(upperCase(FirstName), upperCase(search)) ||
        includes(upperCase(LastName), upperCase(search)),
    );
  };

  const handleAgentSelection = (agent) => {
    const updatedAgentList = cloneDeep(agentList);
    let selectedAgentIndex;

    if (selectedAgent) {
      selectedAgentIndex = updatedAgentList.findIndex((a) => a.Id === selectedAgent.Id);
      updatedAgentList[selectedAgentIndex].selected = false;
    }

    selectedAgentIndex = updatedAgentList.findIndex((a) => a.Id === agent.Id);
    updatedAgentList[selectedAgentIndex].selected = true;
    setAgentList(updatedAgentList);
    setSelectedAgent(agent);
  };

  const agentListContent = (list) =>
    list.map((agent) => (
      <div key={agent.Id} onClick={() => handleAgentSelection(agent)} className={styles.agentList}>
        <Avatar
          avatarClassName={styles.profileAvatar}
          src={agent.AvatarUrl}
          placeholder={convertNameToAvatarPlaceholder(`${agent.FirstName} ${agent.LastName}`)}
        />
        <Name
          className={classNames(styles.agentName, { [styles.selectedAgentName]: agent.selected })}
          name={`${agent.FirstName} ${agent.LastName}`}
        />
        {agent.selected && <Check className={styles.checkIcon} />}
      </div>
    ));

  const renderAgentList = useCallback(() => {
    if (searchText === '') return agentListContent(agentList);
    return agentListContent(teamSearchFilter(searchText, agentList));
  }, [searchText, agentList]);

  const onSave = () => {
    onSubmit('Agent', selectedAgent);
    onClose();
  };

  useEffect(() => {
    fetchManagaedTeamMemberDetails();
  }, []);

  return (
    <Modal
      open={open}
      width={576}
      footer={null}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      className={styles.agentSelectorModal}
    >
      <div className={styles.modalHeader}>
        <h2 className={styles.modalTitle}>Select Agent</h2>
      </div>
      <PendingWrapper isPending={isPending} className={styles.modalWrapper}>
        <div className={styles.searchWrapper}>
          <SearchFieldWithDebounce
            className={styles.inputWrap}
            classNameInput={styles.searchInput}
            iconClassName={styles.icon}
            resetAction={() => setSearchText('')}
            sendAction={setSearchText}
            isLoading={isPending}
            placeholder="Search"
            testid="search"
            isTransactionAggregate={true}
            isExpandedVersion
            doNotDispatch={true}
          />
        </div>
        <div className={styles.modalContentWrapper}>{renderAgentList()}</div>
        <footer className={styles.agentModalFooter}>
          <Button title="Save" onClick={onSave} className={styles.submitButton} />
        </footer>
      </PendingWrapper>
    </Modal>
  );
};
