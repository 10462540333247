import { useState, useRef } from 'react';
import classNames from 'classnames';

import { Document } from '../Document';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import Trash from 'components/Icons/Trash';

import styles from './styles.module.scss';

interface FileProps {
  className?: string;
  onFileSelect: (any) => any;
}

export const File = ({ className, onFileSelect }: FileProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [fileInfo, setFileInfo] = useState({
    file: null,
    fileName: '',
    fileSize: 0,
  });

  const onChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileInfo({
        file: selectedFile,
        fileName: selectedFile.name,
        fileSize: selectedFile.size,
      });
    }
    onFileSelect(event.target.files);
  };

  const onDropzoneClick = () => {
    inputFileRef?.current?.click();
  };

  const onRemove = () => {
    setFileInfo({
      file: null,
      fileName: '',
      fileSize: 0,
    });
  };

  return (
    <div className={styles.file}>
      <label htmlFor={File.id}>
        <input
          onChange={onChange}
          className={styles.fileDomInput}
          id={File.id}
          type="file"
          ref={inputFileRef}
        />
        <Document onButtonClick={onDropzoneClick} className={classNames(styles.file, className)} />
      </label>
      {fileInfo.file && (
        <div className={styles.documentItem}>
          <Icon className={styles.icon} variant={Icon.FILE_SIMPLE} />
          <div className={styles.info}>
            <p className={styles.fileName}>{fileInfo.fileName}</p>
            <p className={styles.fileSize}>{fileInfo.fileSize}KB</p>
          </div>
          <Trash className={styles.trash} onClick={onRemove} />
        </div>
      )}
    </div>
  );
};

File.id = 'documentFile';
