import { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type RatingsPropsType = {
  rating: number;
  stylesClassName?: string | undefined | null;
  colorWithHash: string;
};

function Ratings(props: RatingsPropsType) {
  let stars: ReactElement[] = [];
  const emptyStar = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        d="M6.63267 0.853117C6.77151 0.530732 7.2286 0.530731 7.36744 0.853117L8.79878 4.17684C8.85669 4.31131 8.98344 4.4034 9.12922 4.41692L12.7326 4.75112C13.0821 4.78354 13.2233 5.21826 12.9596 5.44992L10.2409 7.8383C10.1309 7.93493 10.0825 8.08393 10.1147 8.22676L10.9103 11.757C10.9875 12.0994 10.6177 12.3681 10.3159 12.1889L7.20428 10.3413C7.07839 10.2665 6.92172 10.2665 6.79583 10.3413L3.68421 12.1889C3.3824 12.3681 3.0126 12.0994 3.08978 11.757L3.88543 8.22676C3.91762 8.08393 3.86921 7.93493 3.75921 7.8383L1.04048 5.44992C0.77677 5.21826 0.91802 4.78354 1.26753 4.75112L4.87089 4.41692C5.01667 4.4034 5.14342 4.31131 5.20133 4.17684L6.63267 0.853117Z"
        fill="#AAABAB"
      />
    </svg>
  );
  const fullStar = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.63267 0.853117C6.77151 0.530732 7.2286 0.530731 7.36744 0.853117L8.79878 4.17684C8.85669 4.31131 8.98344 4.4034 9.12922 4.41692L12.7326 4.75112C13.0821 4.78354 13.2233 5.21826 12.9596 5.44992L10.2409 7.8383C10.1309 7.93493 10.0825 8.08393 10.1147 8.22676L10.9103 11.757C10.9875 12.0994 10.6177 12.3681 10.3159 12.1889L7.20428 10.3413C7.07839 10.2665 6.92172 10.2665 6.79583 10.3413L3.68421 12.1889C3.3824 12.3681 3.0126 12.0994 3.08978 11.757L3.88543 8.22676C3.91762 8.08393 3.86921 7.93493 3.75921 7.8383L1.04048 5.44992C0.77677 5.21826 0.91802 4.78354 1.26753 4.75112L4.87089 4.41692C5.01667 4.4034 5.14342 4.31131 5.20133 4.17684L6.63267 0.853117Z"
        fill={props.colorWithHash}
      />
    </svg>
  );
  const halfStar = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        d="M6.63267 0.853117C6.77151 0.530732 7.2286 0.530731 7.36744 0.853117L8.79878 4.17684C8.85669 4.31131 8.98344 4.4034 9.12922 4.41692L12.7326 4.75112C13.0821 4.78354 13.2233 5.21826 12.9596 5.44992L10.2409 7.8383C10.1309 7.93493 10.0825 8.08393 10.1147 8.22676L10.9103 11.757C10.9875 12.0994 10.6177 12.3681 10.3159 12.1889L7.20428 10.3413C7.07839 10.2665 6.92172 10.2665 6.79583 10.3413L3.68421 12.1889C3.3824 12.3681 3.0126 12.0994 3.08978 11.757L3.88543 8.22676C3.91762 8.08393 3.86921 7.93493 3.75921 7.8383L1.04048 5.44992C0.77677 5.21826 0.91802 4.78354 1.26753 4.75112L4.87089 4.41692C5.01667 4.4034 5.14342 4.31131 5.20133 4.17684L6.63267 0.853117Z"
        fill="#AAABAB"
      />
      <path
        d="M7.00005 0.611328C6.85107 0.611328 6.70209 0.691925 6.63267 0.853117L5.20133 4.17684C5.14342 4.31131 5.01667 4.4034 4.87089 4.41692L1.26753 4.75112C0.91802 4.78354 0.77677 5.21826 1.04048 5.44992L3.75921 7.8383C3.86921 7.93493 3.91762 8.08393 3.88543 8.22676L3.08978 11.757C3.0126 12.0994 3.3824 12.3681 3.68421 12.1889L6.79583 10.3413C6.85878 10.3039 6.92941 10.2852 7.00005 10.2852V0.611328Z"
        fill={props.colorWithHash}
      />
    </svg>
  );
  if (props.rating) {
    const fullStars = Math.floor(props.rating);
    const hasHalfStar = props.rating % 1 >= 0.5;
    const emptyStars = 5 - Math.ceil(props.rating);
    for (let i = 0; i < fullStars; i++) {
      stars.push(fullStar);
    }

    if (hasHalfStar) {
      stars.push(halfStar);
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(emptyStar);
    }
  } else {
    for (let i = 0; i < 5; i++) {
      stars.push(emptyStar);
    }
  }

  if (stars.length < 5) {
    for (let i = 0; i < 5 - stars.length; i++) {
      stars.push(emptyStar);
    }
  }

  return <div className={classNames(styles.ratingStars, props.stylesClassName)}>{stars}</div>;
}

export default Ratings;
