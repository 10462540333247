import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { DatePicker } from 'components';

import { Avatar, Collapse, Modal, Panel, TimeInput, Input, Button, Select } from 'components-antd';
import { Add, CheckBox, Lock, Marker, Remove } from 'components/Icons';
import { cloneDeep } from 'lodash-es';
import { convertNameToAvatarPlaceholder, getMergedDateTime } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { requestScheduleToursEffect, requestUpdateScheduleTourEffect } from 'store/effects/tour';
import { requestGetTeamListEffect } from 'store/effects/teamList';
import { getTeamListDataArray } from 'store/selectors/teamList';
import { TourStatus } from 'app-constants/enums/tours';
import { getBrowserTimezoneName, getTimeDifference } from '../../helper';
import { getTeamTransactionsSelector } from 'store/selectors/transactions';
import { Tour } from 'types/tours';
import { getAgentTypeSelector, getUserDataSelector } from 'store/selectors/user';
import { AGENT_TYPE } from 'settings/constants/drawers';
import { Wrapper as PendingWrapper } from 'components';

export const AcceptMultiModal = (props) => {
  const { title, tours, avatar, acceptModal, onCancel, onSubmit, isEdit } = props;
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const teamList = useSelector(getTeamListDataArray);
  const [state, setState] = useState<Tour[]>([...tours]);
  const teamTransactions = useSelector(getTeamTransactionsSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (agentType === AGENT_TYPE.Team) {
      dispatch(requestGetTeamListEffect({ Status: 'Accepted' }));
    }
    initialize();
  }, [tours]);

  const teamMembers = useMemo(() => {
    return teamList.map((member) => {
      return {
        label: `${member.FirstName} ${member.LastName}`,
        value: member.Id,
      };
    });
  }, [teamList]);

  const initialize = () => {
    const newArray = [...state];
    newArray.map((tour: Tour) => {
      tour.AssigneeIds = tour.TourAssignee.map((r) => r.AssigneeId);
      tour.HasClientNotes = tour.ClientNotes ? true : false;
      tour.HasPrivateNotes = tour.PrivateNotes ? true : false;
    });
    setState(newArray);
  };
  const getOptions = () => {
    if (agentType === AGENT_TYPE.Individual) {
      return [{ label: `${user.FirstName} ${user.LastName}`, value: user.Id }];
    }
    return teamMembers;
  };

  const getValue = (index) => {
    const assignees = state[index].AssigneeIds;
    return assignees?.length ? getOptions().filter((t) => assignees.includes(t.value)) : [];
  };

  const setDateTime = (date, index) => {
    const newArray = [...state];
    const datetime = getMergedDateTime(newArray[index].ScheduleDateTime, cloneDeep(date));
    newArray[index].ScheduleDate = date.toString();
    newArray[index].ScheduleDateTime = datetime.toDate();
    setState(newArray);
  };
  const onDateChange = (date, index) => {
    const datetime = date;
    const newArray = [...state];
    newArray[index].ScheduleDateTime = datetime;
    setState(newArray);
  };
  const onAssgineeChange = (ids, values, index) => {
    const newArray = [...state];
    newArray[index].AssigneeIds = ids;
    setState(newArray);
  };
  const handleButtonClick = () => {
    setIsLoading(true);
    dispatch(
      requestUpdateScheduleTourEffect({ state, isEdit }, null, (err) => {
        if (!err) {
          dispatch(
            requestScheduleToursEffect({ EntireTeam: teamTransactions }, null, (err) => {
              if (!err) {
                onSubmit();
              }
              setIsLoading(false);
            }),
          );
        } else {
          onSubmit();
          setIsLoading(false);
        }
      }),
    );
  };

  const setHasNotes = (type, index) => {
    const newArray = [...state];
    type === 'Client'
      ? (newArray[index].HasClientNotes = true)
      : (newArray[index].HasPrivateNotes = true);
    setState(newArray);
  };
  const setClientNotes = (value, index) => {
    const newArray = [...state];
    newArray[index].ClientNotes = value;
    setState(newArray);
  };
  const setPrivateNotes = (value, index) => {
    const newArray = [...state];
    newArray[index].PrivateNotes = value;
    setState(newArray);
  };

  const IsValid = () => {
    let isValid: boolean = true;

    state.forEach((tour: Tour) => {
      const date = moment().format('YYYY-MM-DD');
      const selectDate = moment(tour?.ScheduleDateTime).format('YYYY-MM-DD');
      const currentTime = moment().hour();
      const selectedDateTime = moment(tour?.ScheduleDateTime).hour();
      let currentDate = date == selectDate && selectedDateTime < currentTime ? true : false;
      if (!tour.ScheduleDateTime || tour.AssigneeIds?.length === 0 || currentDate) {
        isValid = false;
      }
    });
    return isValid;
  };
  return (
    <Modal
      open={acceptModal}
      width={675}
      footer={null}
      onCancel={onCancel}
      className={styles.acceptToursModal}
      cancelButtonStyles={styles.crossButton}
      maskClosable={false}
      destroyOnClose={true}
      title={title}
    >
      <div className={styles.modalBody}>
        <div className={styles.clientInfo}>
          <Avatar className={styles.ciAvatar} src={tours[0].Creator.AvatarUrl}>
            {convertNameToAvatarPlaceholder(
              `${tours[0].Creator.FirstName} ${tours[0].Creator.LastName}`,
            )}
          </Avatar>
          <div className={styles.ciRight}>
            <p
              className={styles.name}
            >{`${tours[0].Creator.FirstName} ${tours[0].Creator.LastName}`}</p>
            <p className={styles.about}>Client</p>
          </div>
        </div>
        {state.map((tour: Tour, index) => (
          <Collapse
            bordered={false}
            accordion
            expandIcon={({ isActive }) =>
              isActive ? <Remove color={Remove.COLLAPSE} /> : <Add color={Add.COLLAPSE} />
            }
            expandIconPosition={'end'}
            className={styles.propertyFormWrap}
            ghost={true}
            defaultActiveKey={index}
            key={index}
          >
            <Panel
              className={styles.propertyForm}
              header={
                <div className={styles.headerWrap}>
                  <Marker className={styles.icon} />
                  <div className={styles.headerRight}>
                    <p className={styles.address}>
                      <span className={styles.addressOne}>
                        {tour.PropertyAddress.Line2
                          ? `${tour.PropertyAddress.Line1} ${tour.PropertyAddress.Line2},`
                          : `${tour.PropertyAddress.Line1},`}
                      </span>{' '}
                      {`${tour.PropertyAddress.City}, ${tour.PropertyAddress.State} ${tour.PropertyAddress.Zip}`}
                    </p>
                    <p className={styles.mls}>
                      MLS # {tour?.PropertyKey || tour.PropertyId}
                      {tour.Status === TourStatus.Requested &&
                        ` - Pending (${getTimeDifference(tour.CreatedDate)})`}
                    </p>
                  </div>
                </div>
              }
              key={index}
            >
              <div className={styles.tourData}>
                <div className={styles.fieldWrap}>
                  <label className={styles.label}>
                    Date & Time:{' '}
                    {state && !state[index]?.ScheduleDateTime ? (
                      <span className={styles.required}> *</span>
                    ) : (
                      ''
                    )}
                    <span className={styles.timezone}>{getBrowserTimezoneName()}</span>
                  </label>
                  <div className={styles.dateAndTime}>
                    <DatePicker
                      onChange={(e, val) => onDateChange(val?.[0], index)}
                      className={styles.toursDatePicker}
                      variant="light"
                      options={{
                        enableTime: false,
                        minDate: new Date().setHours(0, 0, 0, 0),
                      }}
                      autofocus={false}
                      datePickerInputClass={styles.tdpInput}
                      format={'m/d/Y'}
                      placeholder={'mm/dd/yyyy'}
                      icon={false}
                      value={state && state[index].ScheduleDateTime}
                    />
                    <span className={styles.text}>at</span>
                    <TimeInput
                      className={styles.toursTimePicker}
                      value={state && state[index].ScheduleDateTime}
                      onChange={(e: any) => {
                        setDateTime(e, index);
                      }}
                      getPopupContainer={(node) => node}
                    />
                  </div>
                </div>
                <div className={styles.fieldWrap}>
                  <label className={styles.label}>Assign To:</label>
                  <Select
                    mode="multiple"
                    className={styles.assignTo}
                    options={getOptions()}
                    value={getValue(index)}
                    onChange={(ids, values) => onAssgineeChange(ids, values, index)}
                    disabled={false}
                    menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                    getPopupContainer={(node) => node}
                    popupClassName={styles.assignToDropdown}
                  />
                </div>
                <div className={styles.fieldWrap}>
                  {tour.HasClientNotes ? (
                    <>
                      <label className={styles.label}>Client Notes :</label>
                      <Input
                        type="text"
                        className={styles.toursNotes}
                        value={tour.ClientNotes}
                        onChange={(e) => setClientNotes(e.target.value.slice(0, 250), index)}
                        placeholder="Add a note..."
                      />
                    </>
                  ) : (
                    <span
                      className={styles.notesToggle}
                      onClick={() => {
                        setHasNotes('Client', index);
                      }}
                    >
                      <Add color="orange_circle" className={styles.addIcon} /> Add Client Note
                    </span>
                  )}
                </div>
                <div className={styles.fieldWrap}>
                  {tour.HasPrivateNotes ? (
                    <>
                      <label className={styles.label}>
                        Private Notes : <Lock className={styles.icon} />
                      </label>
                      <Input
                        type="text"
                        value={tour.PrivateNotes}
                        onChange={(e) => setPrivateNotes(e.target.value.slice(0, 250), index)}
                        className={styles.toursNotes}
                        placeholder="Add a note..."
                      />
                    </>
                  ) : (
                    <span
                      className={styles.notesToggle}
                      onClick={() => {
                        setHasNotes('Private', index);
                      }}
                    >
                      <Add color="orange_circle" className={styles.addIcon} /> Add Private Note
                    </span>
                  )}
                </div>
              </div>
            </Panel>
          </Collapse>
        ))}
        <footer>
          <PendingWrapper
            isPending={isLoading}
            className={styles.pendingWrapper}
            loaderClassName={styles.loader}
            spinnerClassname={styles.spinner}
          >
            <Button
              variant="secondary"
              className={styles.submit}
              disabled={!IsValid()}
              onClick={() => handleButtonClick()}
            >
              Confirm
            </Button>
          </PendingWrapper>
        </footer>
      </div>
    </Modal>
  );
};
