import styles from './styles.module.scss';
import EmptyKit from '../EmptyKit';
import { useState } from 'react';
import EligibilityDataRow from './EligibilityDataRow';

type ClientsEligibilityTablePropsType = {
  kitCreatedDate: Date;
  kitId: number;
  data;
  mode: 'Active' | 'Eligible' | 'Not Eligible';
};

function ClientsEligibilityTable(props: ClientsEligibilityTablePropsType) {
  const { mode } = props;

  if (mode === 'Active')
    return (
      <div className={styles.clientTable}>
        <table className={styles.table}>
          <tr className={styles.rowHeading}>
            <td>
              <div className={styles.heading}>Client</div>
            </td>
            <td>
              <div className={styles.heading}>Primary Address</div>
            </td>
            <td>
              <div className={styles.heading}>Activated</div>
            </td>
            <td>
              <div className={styles.heading}>Sent</div>
            </td>
            <td>
              <div className={styles.heading}>Opened</div>
            </td>
            <td>
              <div className={styles.heading}>Clicked</div>
            </td>
            <td>
              <div className={styles.heading}>Enable</div>
            </td>
          </tr>
          {props.data.length ? (
            props.data.map((item) => (
              <EligibilityDataRow
                item={item}
                mode={mode}
                kitId={props.kitId}
                kitCreatedDate={props.kitCreatedDate}
              />
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <EmptyKit text="No active clients." />
              </td>
            </tr>
          )}
        </table>
      </div>
    );
  else if (mode === 'Eligible')
    return (
      <div className={styles.clientTable}>
        <table className={styles.table}>
          <tr className={styles.rowHeading}>
            <td>
              <div className={styles.heading}>Client</div>
            </td>
            <td>
              <div className={styles.heading}>Primary Address</div>
            </td>
            <td>
              <div className={styles.heading}>Activated</div>
            </td>
            <td>
              <div className={styles.heading}>Deactivated</div>
            </td>
            <td>
              <div className={styles.heading}>Sent</div>
            </td>
            <td>
              <div className={styles.heading}>Opened</div>
            </td>
            <td>
              <div className={styles.heading}>Enable</div>
            </td>
          </tr>
          {props.data.length ? (
            props.data.map((item) => (
              <EligibilityDataRow
                item={item}
                mode={mode}
                kitId={props.kitId}
                kitCreatedDate={props.kitCreatedDate}
              />
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <EmptyKit text="No eligible clients." />
              </td>
            </tr>
          )}
        </table>
      </div>
    );
  else if (mode === 'Not Eligible')
    return (
      <div className={styles.clientTable}>
        <table className={styles.table}>
          <tr className={styles.rowHeading}>
            <td>
              <div className={styles.heading}>Client</div>
            </td>
            <td>
              <div className={styles.heading}>Primary Address</div>
            </td>
            <td>
              <div className={styles.heading}>Notes</div>
            </td>
          </tr>
          {props.data.length ? (
            props.data.map((item) => (
              <EligibilityDataRow
                item={item}
                mode={mode}
                kitId={props.kitId}
                kitCreatedDate={props.kitCreatedDate}
              />
            ))
          ) : (
            <tr>
              <td colSpan={3}>
                <EmptyKit text="No clients to display." />
              </td>
            </tr>
          )}
        </table>
      </div>
    );
  else return <></>;
}

export default ClientsEligibilityTable;
