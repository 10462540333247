import React, { memo, useMemo } from 'react';

const MilestoneRounded = ({ color = '#928CDA' }: { color?: string }) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.08" cx="18" cy="18" r="18" fill={color} />
      <g clipPath="url(#clip0_46715_41682)">
        <path
          d="M17.167 13H24.667"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.167 18H24.667"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.167 23H24.667"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="12.5833" cy="13.0003" r="1.08333" stroke={color} strokeWidth="2" />
        <circle cx="12.5833" cy="18.0003" r="1.08333" stroke={color} strokeWidth="2" />
        <circle cx="12.5833" cy="23.0003" r="1.08333" stroke={color} strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_46715_41682">
          <rect width="20" height="20" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MilestoneRounded;
