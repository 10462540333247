import { useSelector } from 'react-redux';

import {
  getDynamicFormSelector,
  getDynamicFormSignatureConfigSelector,
} from 'store/selectors/requestFormProcess';
import { FieldRenderer } from '../../FieldRenderer';

interface ActiveQuestionsProps {
  pageIndex: number;
  documentIndex?: number;
}

export const ActiveQuestions = ({ pageIndex, documentIndex = -1 }: ActiveQuestionsProps) => {
  const { activeQuestions = [] } = useSelector(getDynamicFormSignatureConfigSelector);

  const { bundleDocumentsDetails = [] } = useSelector(getDynamicFormSelector);

  const isCohesiveFlow = bundleDocumentsDetails.length > 0 && documentIndex >= 0;

  const questions = isCohesiveFlow
    ? bundleDocumentsDetails?.[documentIndex]?.activeQuestions || []
    : activeQuestions;

  const pageQuestions = questions.filter(
    (question) => question.Fields?.[0]?.widgets?.[0]?.pageNumber === pageIndex,
  );

  return (
    <>
      {pageQuestions.map((question) => (
        <FieldRenderer
          question={question}
          key={`active-questions-${question.UUID}`}
          documentIndex={documentIndex}
          isCohesiveFlow={isCohesiveFlow}
          pageQuestions={pageQuestions}
          pageNumber={pageIndex}
        />
      ))}
    </>
  );
};
