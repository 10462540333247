import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { DetailedHTMLProps, HTMLAttributes, ReactChild, useEffect, useState } from 'react';

import { getSignatureFieldColor } from 'utils';
import { PencilIcon, SignIcon } from './PDFSignIcons';
import { SIGNATURE_FIELD_COLORS, SIGNATURE_MAPPING, SignFormFields } from 'app-constants';
import { getRequestFormProcessSelector } from 'store/selectors/requestFormProcess';
import { getUserRoleSelector } from 'store/selectors/user';
import { AGENT, CLIENT } from 'settings/constants/roles';
import { Tooltip } from 'components-antd';
import { SignatureMappingType } from 'types';

import styles from './styles.module.scss';

export interface PDFSignResponse {
  type: 'text' | 'image';
  font?: string;
  data: string;
  fieldKey?: SignFormFields;
}
interface PDFSignProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onFieldClick?: (number) => void;
  questionIndex: number;
  defaultResponse?: PDFSignResponse;
  disabled?: boolean;
  hideOnDisabled?: boolean;
  signatureColorIndex: number;
  onFillSignature: (questionIndex: number) => void;
  customTag?: SignatureMappingType;
}

export const PDFSign = ({
  className,
  onFieldClick,
  questionIndex,
  defaultResponse,
  disabled,
  hideOnDisabled,
  signatureColorIndex = 0,
  style = {},
  onFillSignature,
  customTag,
  ...props
}: PDFSignProps) => {
  const { currentUserSignature } = useSelector(getRequestFormProcessSelector);
  const userRole = useSelector(getUserRoleSelector);

  const fillSignaturePermission =
    !defaultResponse?.data && currentUserSignature && (userRole === AGENT || userRole === CLIENT);

  const handleFieldClick = () => {
    if (!disabled) {
      if (fillSignaturePermission && onFillSignature) onFillSignature(questionIndex);
      else if (onFieldClick) onFieldClick(questionIndex);
    }
  };

  const [colors, setColors] = useState(SIGNATURE_FIELD_COLORS[0]);
  const [minimizedTextAndIcons, setMinimizedTextAndIcons] = useState(false);

  useEffect(() => {
    if (style.width && Number(style.width) < 120) {
      setMinimizedTextAndIcons(true);
    }
  }, [styles]);

  useEffect(() => {
    setColors(getSignatureFieldColor(signatureColorIndex));
  }, [signatureColorIndex]);

  const returnLabelBasedOnCustomTag = (customTag: SignatureMappingType) => {
    switch (customTag) {
      case SIGNATURE_MAPPING.SignatureDate:
        return 'Date';
      case SIGNATURE_MAPPING.Initials:
        return 'Initial';
      default:
        return 'Sign here';
    }
  };

  return hideOnDisabled && disabled ? (
    <></>
  ) : (
    <div
      {...props}
      className={classNames(
        className,
        styles.pdfSignField,
        {
          [styles.pdfSignFieldEmpty]: !defaultResponse?.data,
        },
        { [styles.hideBorder]: disabled },
      )}
      style={{
        ...style,
        minHeight: 24,
        padding: '0px 5px',
        ...(!defaultResponse?.data
          ? { background: colors.background, border: `2px solid ${colors.border}` }
          : {}),
      }}
    >
      <div
        onClick={handleFieldClick}
        className={classNames(
          { [styles.signatureContainer]: !defaultResponse?.data || !style.fontFamily },
          { [styles.signatureContainerText]: style.fontFamily && defaultResponse?.data },
        )}
      >
        {defaultResponse?.data ? (
          <>
            {disabled ? <></> : <PencilIcon className={styles.editIcon} />}
            {defaultResponse.type === 'text' ? (
              <>
                {style.fontFamily ? (
                  defaultResponse.data
                ) : (
                  <div className={styles.signTextContainer}>{defaultResponse.data}</div>
                )}
              </>
            ) : (
              <img src={defaultResponse.data} width={style?.width} />
            )}
          </>
        ) : disabled ? (
          <></>
        ) : (
          <WrapInTooltip wrap={minimizedTextAndIcons} tooltipText="Sign here">
            <div className={styles.clickToSignContainer}>
              <SignIcon stroke={colors.border} />
              {!minimizedTextAndIcons && (
                <span className={styles.signText}>
                  {customTag && returnLabelBasedOnCustomTag(customTag)}
                </span>
              )}
            </div>
          </WrapInTooltip>
        )}
      </div>
    </div>
  );
};

const WrapInTooltip = (props: { wrap: any; tooltipText: string; children: ReactChild }) =>
  props.wrap ? (
    <Tooltip
      placement="bottom"
      title={props.tooltipText}
      color="white"
      overlayInnerStyle={{ color: 'black', borderRadius: 10 }}
    >
      {props.children}
    </Tooltip>
  ) : (
    <>{props.children}</>
  );
