import { useState } from 'react';
import classNames from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';
import { updateTransactionEffect } from 'store/effects/transactions';

import { Edit } from 'components/Icons';
import { Input } from 'components';
import { Button } from 'components-antd';

import commonStyles from './../styles.module.scss';
import styles from './styles.module.scss';

export const YearBuilt = ({ isDisableField, setEditDetailId }) => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [isEdit, setIsEdit] = useState(false);
  const [hover, setHover] = useState(false);
  const [yearBuilt, setYearBuilt] = useState(transaction?.YearBuilt);
  const editKey = 'yearBuilt';

  const onEdit = () => {
    if (!fullAccess || isDisableField(editKey)) return null;
    setIsEdit(true);
    setEditDetailId(editKey);
  };

  const updatePropertyType = () => {
    setIsPending(true);
    dispatch(
      updateTransactionEffect(
        {
          id: transaction?.Id,
          YearBuilt: yearBuilt,
        },
        {},
        (err) => {
          if (!err) {
            setIsEdit(false);
          }
          setIsPending(false);
          setEditDetailId(undefined);
        },
      ),
    );
  };

  const onCancel = () => {
    setIsEdit(false);
    setEditDetailId(undefined);
    setYearBuilt(transaction?.YearBuilt);
  };

  return (
    <>
      {!isEdit ? (
        <div
          className={classNames(commonStyles.cardWrapper, {
            [commonStyles.disabledSection]: !!isDisableField(editKey),
          })}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={onEdit}
        >
          <div className={commonStyles.fieldContainer}>
            <p className={commonStyles.label}>Year Built</p>
            <span className={commonStyles.value}>{yearBuilt}</span>
          </div>
          {fullAccess && !isDisableField(editKey) && (
            <Edit
              className={classNames(commonStyles.editIcon, { [commonStyles.hideIcon]: !hover })}
              stroke="#262626"
            />
          )}
        </div>
      ) : (
        <div className={commonStyles.editWrapper}>
          <Input
            label={'Year Built'}
            isNumberFormat
            thousandSeparator={false}
            variant={Input.LIGHT_ROUND}
            placeholder="Year (i.e. 2010)"
            onChange={(e, value) => setYearBuilt(value)}
            value={yearBuilt}
            testid="year_input"
            minNumber={0}
            maxNumber={new Date().getFullYear()}
            className={styles.yearBuiltInput}
          />
          <div className={commonStyles.buttons}>
            <Button
              variant="secondary"
              size="large"
              disabled={transaction?.YearBuilt === yearBuilt}
              onClick={updatePropertyType}
              loading={isPending}
            >
              Update
            </Button>
            <Button variant="hollow-bordered" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
