import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { AgentDetails, Spinner } from 'components';
import { Address } from './Address';
import { DateAndTime } from './DateAndTime';
import { Notes } from './Notes';
import { Footer } from './Footer';
import { requestUpdateShowingAppointmentApprovalEffect } from 'store/effects/showingAppointment';
import { ApprovalAction, ApprovalStatus } from 'types';
import { showSuccessMessage } from 'helpers/success';
import { ConfirmationWithReasonModal } from 'components-antd';
import { getUserId, getUserRolesSelector } from 'store/selectors/user';
import { Role } from 'app-constants';

import styles from './styles.module.scss';
import { appGetNotificationMessagesEffect, userGetDataEffect } from 'store/effects';
import { socketsInitEffect } from 'store/effects/sockets';

export const ShowingApproval = (props) => {
  const { approval, isPending, setIsPending, UUID, fetchAppointmentDetails } = props;
  const ShowingAppointment = approval?.ShowingAppointment;
  const ShowingDetails = ShowingAppointment?.ShowingDetails;
  const PropertyTransaction = ShowingDetails?.PropertyTransaction;
  const appointmentStatus = ShowingAppointment?.AppointmentStatus;
  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRolesSelector);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState(null);
  const [actionConfig, setActionConfig] = useState({
    action: '',
  });

  const onConfirmationCancel = () => {
    setShowModal(false);
    setReason(null);
  };

  const processAction = (action) => {
    setShowModal(true);
    setActionConfig({ ...actionConfig, action });
  };

  const onConfirm = () => {
    switch (actionConfig?.action) {
      case ApprovalAction.Approve:
        onSubmit(ApprovalStatus.Approved);
        break;
      case ApprovalAction.Decline:
        onSubmit(ApprovalStatus.Rejected);
        break;
      case ApprovalAction.Cancel:
        onSubmit(ApprovalStatus.Canceled);
        break;
      default:
        break;
    }
    setShowModal(false);
  };

  const onSubmit = (approvalStatus) => {
    setIsPending(true);
    dispatch(
      requestUpdateShowingAppointmentApprovalEffect(
        { UUID, ApprovalStatus: approvalStatus, Reason: reason },
        {},
        (err) => {
          if (!err) {
            fetchAppointmentDetails(UUID);
            showSuccessMessage(`Showing ${approvalStatus} Successfully`);
            setReason(null);
          }
        },
      ),
    );
  };

  const getHeader = () => {
    switch (approval?.ApprovalStatus) {
      case ApprovalStatus.Pending:
        return <PendingApproval />;
      case ApprovalStatus.Canceled:
        return <ShowingCanceled />;
      case ApprovalStatus.Approved:
        return <ShowingApproved />;
      case ApprovalStatus.Rejected:
        return <ShowingDeclined />;
      default:
        return <></>;
    }
  };

  const showAgentDetails = () => {
    const isAgent =
      ShowingAppointment?.PerformedBy?.UserId === userId && userRole[0] === Role.Agent;
    return (
      !isAgent && (
        <div className={styles.detailArea}>
          <h4>If you have any questions or concerns, please contact:</h4>
          <AgentDetails SellerAgent={ShowingDetails?.SellerAgent} />
        </div>
      )
    );
  };

  const PendingApproval = () => {
    return (
      <div>
        <div className={styles.headTitle}>Showing Request:</div>
        <div className={styles.headTitle}>Approval Required</div>
        <div className={styles.headTitleDescription}>
          Please approve or deny the following request.
        </div>
      </div>
    );
  };

  const ShowingApproved = () => {
    return (
      <div>
        <div className={styles.headTitle}>Showing Approved</div>
        <div className={styles.headTitleDescription}>Your showing has been approved.</div>
      </div>
    );
  };

  const ShowingDeclined = () => {
    return (
      <div>
        <div className={styles.headTitle}>Showing Declined</div>
        <div className={styles.headTitleDescription}>This showing has been declined.</div>
      </div>
    );
  };

  const ShowingCanceled = () => {
    return (
      <div>
        <div className={styles.headTitle}>Showing Canceled</div>
        <div className={styles.headTitleDescription}>This showing has been cancelled.</div>
      </div>
    );
  };

  useEffect(() => {
    if (userId) {
      dispatch(userGetDataEffect({}, { showError: false }));
      dispatch(socketsInitEffect());
      dispatch(appGetNotificationMessagesEffect());
    }
  }, []);

  return (
    <div className={styles.confirmationArea}>
      <div className={styles.headWrapper}>{getHeader()}</div>
      {isPending ? (
        <Spinner loaderClassName={classNames(styles.loader)} />
      ) : (
        <>
          <div className={styles.detailArea}>
            <Address PropertyTransaction={PropertyTransaction} />
          </div>
          <div className={styles.detailArea}>
            <DateAndTime
              startTime={ShowingAppointment?.AppointmentStartTime}
              endTime={ShowingAppointment?.AppointmentEndTime}
            />
          </div>
          {ShowingDetails?.BookingPageNotes && (
            <div className={styles.detailArea}>
              <Notes BookingPageNotes={ShowingDetails?.BookingPageNotes} />
            </div>
          )}
          {showAgentDetails}

          <div className={styles.footerArea}>
            <Footer
              processAction={processAction}
              approvalStatus={approval?.ApprovalStatus}
              appointmentStatus={appointmentStatus}
            />
          </div>
        </>
      )}
      <ConfirmationWithReasonModal
        open={showModal}
        confirmText={<span>Are you sure you want to {actionConfig?.action} this showing?</span>}
        placeholder={`Write a reason for ${
          actionConfig?.action === ApprovalAction.Decline ? 'declining' : 'cancelling'
        }...`}
        okText="Confirm"
        showReason={!(actionConfig?.action === ApprovalAction.Approve)}
        setReason={setReason}
        onOk={onConfirm}
        onCancel={onConfirmationCancel}
      />
    </div>
  );
};
