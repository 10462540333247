import Api from 'store/effects/core/api';
import {
  copyQuoteFormVersion,
  getQuoteForm,
  newQuoteFormVersion,
  updateQuoteForm,
} from 'api/formBuilder';
import {
  requestCopyQuoteFormVersionAction,
  requestNewQuoteFormVersionAction,
  requestQuoteFormAction,
  requestUpdateQuoteFormAction,
  resetQuoteFormAction,
  updateQuoteFormMetaAction,
  updateQuoteFormPreviewResponse,
  updateQuoteFormQuestionAction,
} from 'store/actions/formBuilder';
import { QuestionType, QuoteBuilderType } from 'types';

export const getQuoteFormBuilderEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestQuoteFormAction,
    method: getQuoteForm,
  });

  return sendRequest(cfg, {}, cb);
};

export const resetQuoteFormBuilderEffect = () => (dispatch) => {
  dispatch(resetQuoteFormAction());
};

export const updateQuoteFormMetaEffect = (cfg: Partial<QuoteBuilderType>) => (dispatch) => {
  dispatch(updateQuoteFormMetaAction(cfg));
};

export const updateQuoteFormQuestionEffect = (cfg: QuestionType) => (dispatch) => {
  dispatch(updateQuoteFormQuestionAction(cfg));
};

export const requestUpdateQuoteFormEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestUpdateQuoteFormAction,
    method: updateQuoteForm,
  });

  return sendRequest(cfg, {}, cb);
};

export const requestNewQuoteFormVersionEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestNewQuoteFormVersionAction,
    method: newQuoteFormVersion,
  });

  return sendRequest(cfg, {}, cb);
};

export const requestCopyQuoteFormVersionEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestCopyQuoteFormVersionAction,
    method: copyQuoteFormVersion,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateQuoteFormResponse = (cfg) => (dispatch) => {
  dispatch(updateQuoteFormPreviewResponse(cfg));
};
