import { memo, useCallback } from 'react';
import { IIconsProps, IconsVariant } from '../types';

const Icons = memo(({ className, variant }: IIconsProps) => {
  const getIcon = useCallback(() => {
    switch (variant) {
      case IconsVariant.COMMUTE_Time:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <circle cx="16" cy="14.667" r="4" stroke="#4673D1" strokeWidth="2" />
            <path
              d="M26.6667 14.6667C26.6667 20.7785 20.441 26.5011 17.5022 28.8621C16.6144 29.5753 15.3857 29.5753 14.4979 28.8621C11.559 26.5011 5.33337 20.7785 5.33337 14.6667C5.33337 8.77563 10.109 4 16 4C21.8911 4 26.6667 8.77563 26.6667 14.6667Z"
              stroke="#4673D1"
              strokeWidth="2"
            />
          </svg>
        );

      case IconsVariant.NIGHT_LIFE:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M12.5 29L19.5 29"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 9.99951H24L16 20.9995L8 9.99951Z"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M16 20.0005V29.0005" stroke="#4673D1" strokeWidth="2" />
            <path
              d="M19.5 10.0005L22.5384 5.00049H26.5999"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.0598 9.28429C13.0057 8.30396 12.6349 7.36767 12.003 6.61621C11.3711 5.86476 10.5123 5.33875 9.5558 5.11731C8.59928 4.89586 7.59672 4.99093 6.69887 5.38823C5.80103 5.78554 5.05644 6.46359 4.57707 7.32043C4.0977 8.17727 3.90946 9.16658 4.04066 10.1396C4.17187 11.1126 4.61542 12.0167 5.30461 12.716C5.99381 13.4153 6.8914 13.8719 7.86241 14.0172C8.72808 14.1467 9.61038 14.0226 10.4036 13.6633"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case IconsVariant.PARK_NATURE:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M20.5 23.5004C24.5909 24.1822 28 20.4511 28 17.0004C28 15.2799 27.3159 13.568 26.209 12.4381C26.2661 12.1314 26.296 11.8149 26.296 11.4913C26.296 8.69796 24.0684 6.43348 21.3204 6.43348C20.7844 6.43348 20.2681 6.51965 19.7844 6.6791C17 2.50041 9.5 4.00043 8.5 9.00043C6 10.0004 4 12.9129 4 16.0004C4 20.2727 7.79724 24.0004 12 24.0004"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 18.4995L12.5 15.4995M16 18.4995L18.5 16.4995M16 18.4995V29.9995"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case IconsVariant.PROXIMITY_LOCATION:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <g clipPath="url(#clip0_44133_26552)">
              <path
                d="M17.3625 16.0731C17.3625 20.0753 13.6038 23.8402 11.4337 25.6804C10.558 26.4229 9.30984 26.4229 8.43421 25.6804C6.2641 23.8402 2.50537 20.0753 2.50537 16.0731C2.50537 11.93 5.83125 8.57129 9.93394 8.57129C14.0366 8.57129 17.3625 11.93 17.3625 16.0731Z"
                fill="#FEF1F2"
                stroke="#FF576D"
                strokeWidth="2"
              />
              <circle cx="9.9338" cy="16.0002" r="2.85714" stroke="#FF576D" strokeWidth="2" />
              <path
                d="M31.6482 12.1027C31.6482 16.8715 27.0423 21.3491 24.5832 23.3998C23.7 24.1363 22.4536 24.1363 21.5704 23.3998C19.1113 21.3491 14.5054 16.8715 14.5054 12.1027C14.5054 7.31219 18.3429 3.42871 23.0768 3.42871C27.8107 3.42871 31.6482 7.31219 31.6482 12.1027Z"
                fill="#FEF1F2"
                stroke="#FF576D"
                strokeWidth="2"
              />
              <circle cx="23.0768" cy="11.9999" r="3.42857" stroke="#FF576D" strokeWidth="2" />
            </g>
            <defs>
              <clipPath id="clip0_44133_26552">
                <rect width="32" height="32" fill="white" transform="translate(0.791016)" />
              </clipPath>
            </defs>
          </svg>
        );

      case IconsVariant.SCHOOLS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M25 14V19.799C25 20.3638 24.7249 20.9027 24.2357 21.2315C18.7452 24.9228 13.2548 24.9228 7.76428 21.2315C7.27509 20.9027 7 20.3638 7 19.799V14"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.5316 11.7445C1.8228 11.4642 1.8228 10.5368 2.5316 10.2564L15.6545 5.06594C15.8751 4.97867 16.1249 4.97867 16.3455 5.06594L29.4684 10.2564C30.1772 10.5368 30.1772 11.4642 29.4684 11.7445L16.3455 16.935C16.1249 17.0223 15.8751 17.0223 15.6545 16.935L2.5316 11.7445Z"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M3.5 12.5V23" stroke="#FB913A" strokeWidth="2" />
            <path
              d="M4.5 25.5005L5 29.3907C4.14286 30.2037 2.85714 30.2037 2 29.3907L2.5 25.5005"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path
              d="M5 24.5005C5 25.3289 4.32843 26.0005 3.5 26.0005C2.67157 26.0005 2 25.3289 2 24.5005C2 23.6721 2.67157 23.0005 3.5 23.0005C4.32843 23.0005 5 23.6721 5 24.5005Z"
              stroke="#FB913A"
              strokeWidth="2"
            />
          </svg>
        );

      case IconsVariant.WALK:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <path
              d="M20.7558 5.18784C20.7558 6.61729 19.597 7.77608 18.1676 7.77608C16.7381 7.77608 15.5793 6.61729 15.5793 5.18784C15.5793 3.7584 16.7381 2.59961 18.1676 2.59961C19.597 2.59961 20.7558 3.7584 20.7558 5.18784Z"
              stroke="#8079DB"
              strokeWidth="2"
            />
            <path
              d="M13.1249 19.1251L14.3908 13.5972L13.8799 13.8679L11.6576 17.6384C11.4067 18.2506 10.706 18.5439 10.0927 18.2934C9.47927 18.043 9.18544 17.3437 9.43637 16.7315L11.425 12.7779C11.5782 12.4731 11.7941 12.2065 12.063 11.9966C13.0544 11.2227 15.5599 9.3003 16.1021 9.21012C16.7817 9.09707 18.991 9.4319 19.4354 10.0973L21.7125 14.3679L24.4565 16.1937C25.0079 16.5606 25.1569 17.3042 24.7893 17.8546C24.4217 18.4049 23.6767 18.5537 23.1252 18.1868L20.1253 16.1906C19.9574 16.0789 19.8204 15.9267 19.7271 15.7481L18.7687 14.0897L18.3243 17.8602L21.6016 21.9352C21.7916 22.1548 21.918 22.4218 21.9674 22.7077L22.9674 28.4965C23.1175 29.3656 22.5333 30.1916 21.6625 30.3415C20.7918 30.4913 19.9641 29.9082 19.814 29.0391L18.8895 23.6872L16.5465 20.7436L15.137 23.9893C15.0985 24.1335 15.04 24.2715 14.9631 24.3995L11.9631 29.3898C11.5085 30.146 10.5257 30.3913 9.768 29.9375C9.01029 29.4838 8.76459 28.5028 9.21921 27.7466L12.1032 22.9491L13.1249 19.1251Z"
              stroke="#8079DB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case IconsVariant.PUBLIC_TRANSIT:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M17.001 24C17.001 25.933 15.434 27.5 13.501 27.5C11.568 27.5 10.001 25.933 10.001 24C10.001 22.067 11.568 20.5 13.501 20.5C15.434 20.5 17.001 22.067 17.001 24Z"
              stroke="#51BFE1"
              strokeWidth="2"
            />
            <path
              d="M2.00073 23.9995L10.0007 23.9995"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.0007 23.9995H27.1007C27.9843 23.9995 28.7007 23.2832 28.7007 22.3995V16.758C28.7007 16.6527 28.6903 16.5476 28.6696 16.4443L27.0007 7.49951C26.8512 6.75163 26.2634 5.99951 25.5007 5.99951H2.00073"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.5002 16.9995H26.0002C26.2787 16.9995 26.5275 17.0722 26.7692 17.2103L29.2003 18.5995"
              stroke="#51BFE1"
              strokeWidth="2"
            />
            <path
              d="M2.00073 16.9995H16.5007"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.9995 5.99951V23.9995M23.9995 5.99951V23.9995"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.00024 5.99951V15.9995"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case IconsVariant.ARTS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <path
              d="M25.9295 6.64772C19.1988 8.85661 14.3832 8.85661 7.65253 6.64772C6.74441 6.34969 5.80699 7.01099 5.86519 7.96499C6.18365 13.1849 7.86922 26.9995 16.791 26.9995C25.7128 26.9995 27.3984 13.1849 27.7168 7.96498C27.775 7.01099 26.8376 6.34969 25.9295 6.64772Z"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path
              d="M16.791 23.0005C14.8782 23.0005 13.7784 21.7808 13.2326 20.8968C13.0045 20.5276 13.3448 20.127 13.7719 20.2039C14.541 20.3423 15.666 20.5005 16.791 20.5005C17.916 20.5005 19.041 20.3423 19.8101 20.2039C20.2372 20.127 20.5775 20.5276 20.3495 20.8968C19.8036 21.7808 18.7039 23.0005 16.791 23.0005Z"
              fill="#4673D1"
            />
            <path
              d="M10.791 14.4996C11.791 13.9995 13.791 13.9995 14.791 14.4996"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.291 14.4996C20.291 13.9995 22.291 13.9995 23.291 14.4996"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        );

      case IconsVariant.FITNESS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <path
              d="M3.41602 25.375L23.1035 24.5"
              stroke="#FB913A"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M21.3535 24.5L24.416 12.25M24.416 12.25L26.166 11.375M24.416 12.25L21.791 13.5625"
              stroke="#FB913A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="17.937" cy="3.5442" r="2.5442" stroke="#FB913A" strokeWidth="1.5" />
            <path
              d="M13.6423 13.1889L14.9092 10.2668L16.582 11.8575C16.8763 12.1375 17.2967 12.2411 17.6875 12.1301L20.3649 11.3691C20.925 11.2099 21.2696 10.648 21.1575 10.0766C21.0382 9.46794 20.4467 9.07231 19.8385 9.19437L17.5205 9.54993L15.7321 7.09401C15.5173 6.82253 15.2329 6.61439 14.9091 6.49176L14.2672 6.24862C14.0642 6.17174 13.8496 6.12994 13.6327 6.125L10.297 6.2739C9.86735 6.26412 9.45337 6.4353 9.15611 6.74565L6.22381 9.80716C5.80594 10.2434 5.82477 10.937 6.26569 11.3499C6.70315 11.7596 7.38992 11.7372 7.79964 11.2997L10.297 9.19437L11.8035 9.01427L9.7409 13.0315L8.52688 16.3393C8.43142 16.5821 8.21892 16.7596 7.96302 16.8102L5.21986 17.769C4.61232 17.8893 4.20847 18.4683 4.30536 19.08C4.40708 19.7222 5.01962 20.1533 5.6585 20.0321L9.42741 18.9075C9.82998 18.8312 10.1852 18.5967 10.4136 18.2565L12.0809 15.5682L14.6338 17.0942L13.7919 21.134C13.6134 21.9903 14.3471 22.7604 15.2111 22.6236C15.7271 22.5418 16.1379 22.1478 16.241 21.6355L17.2579 16.5814C17.3897 15.9261 17.0814 15.261 16.4961 14.9383L13.6423 13.1889Z"
              stroke="#FB913A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case IconsVariant.COFFEE_SHOPS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M24 16.0005L26.3 16.0005C28.0673 16.0005 29.5 17.3436 29.5 19.0005C29.5 20.6573 28.0673 22.0005 26.3 22.0005H24"
              stroke="#8079DB"
              strokeWidth="2"
            />
            <path
              d="M15.5 8.99951C15.5 8.99951 16.5 8.12451 16.5 6.81201C16.5 5.49951 15 4.90727 15 3.74951C15 2.59175 15.5 1.99951 15.5 1.99951"
              stroke="#8079DB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 17.9995V12.9995H24V17.9995C24 22.9701 19.9706 26.9995 15 26.9995C10.0294 26.9995 6 22.9701 6 17.9995Z"
              stroke="#8079DB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case IconsVariant.RESTAURANT:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <path
              d="M7.29102 27.9995H13.291"
              stroke="#51BFE1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.79099 3H14.791C15.9791 8.12 19.7976 18 10.291 18C0.784388 18 4.60233 8.12 5.79099 3Z"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M10.291 18V28" stroke="#51BFE1" strokeWidth="2" />
            <path
              d="M3.79102 11.75C3.79102 11.75 5.64816 11 7.5053 11C9.82673 11 11.1422 12 13.0767 12C14.6243 12 16.791 11.25 16.791 11.25"
              stroke="#51BFE1"
              strokeWidth="2"
            />
            <path
              d="M23.791 3V8.5"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.291 3V8.5"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.291 3C21.291 3 21.291 9.26618 21.291 10.894C21.291 12.5218 23.791 13.6071 23.791 14.6923C23.791 15.5262 23.4957 23.2325 23.359 26.7563C23.322 27.7108 24.0858 28.5 25.041 28.5V28.5C25.9962 28.5 26.7601 27.7108 26.723 26.7563C26.5863 23.2325 26.291 15.5262 26.291 14.6923C26.291 13.6071 28.791 12.5218 28.791 10.894C28.791 9.26618 28.791 3 28.791 3"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      default:
        return null;
    }
  }, [variant]);

  return <div className={className}>{getIcon()}</div>;
});

export default Icons;
