import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const DashboardRoute = ({ path, component, render, exact }) => (
  <Route key={path} path={path} render={render} component={component} exact={exact} />
);

DashboardRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func,
  render: PropTypes.func,
  exact: PropTypes.bool,
};

DashboardRoute.defaultProps = {
  exact: false,
  component: null,
  render: null,
};

export default DashboardRoute;
