import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep } from 'lodash-es';
import useIsDirty from 'hooks/use-is-dirty';
import { getSearchDrawerCommuteIdSelector } from 'store/selectors/searchResults';

import { setSearchCriteriaEffect } from 'store/effects';
import { getSearchResultsFilterDrawerModeSelector } from 'store/selectors/searchResults';
import { getSearchCommutesSelector } from 'store/selectors/search';
import { EDIT } from 'settings/constants/mode';

import {
  ScrollDrawerWrapper,
  DrawerPaddingWrapper,
  Footer,
} from 'pages/Properties/SearchResults/components';
import { Button } from 'components';
import Commute from './Commute';
import AddCommute from './AddCommute';
import AddProximity from './AddProximity';

import styles from './styles.module.scss';

const Commutes = ({ className, onSubmit, isPending, search }) => {
  const dispatch = useDispatch();
  const mode = useSelector(getSearchResultsFilterDrawerModeSelector);
  const commuteId = useSelector(getSearchDrawerCommuteIdSelector);
  const isEditMode = mode === EDIT;
  const commutesList = useSelector(getSearchCommutesSelector);

  const getGroupedData = commutesList.reduce((groups, location, index) => {
    const key = location.IsImportantLocation ? 'proximity' : 'commutes';
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push({ ...location, index });
    return groups;
  }, {});

  const [groupedCommutes, setGroupedCommutes] = useState(getGroupedData);

  useEffect(() => {
    setGroupedCommutes(getGroupedData);
  }, [commutesList]);

  const [commutes, setCommutes] = useState(cloneDeep(commutesList));

  const getFormattedData = (cfg) => {
    return {
      ...search,
      CommutePrefs: cfg?.map((commute) => ({
        ...commute,
      })),
    };
  };

  const submitHandler = () => {
    onSubmit(getFormattedData(commutesList));
  };

  const onDeleteCommute = (index) => {
    const clonedCommutes = cloneDeep(commutesList);
    clonedCommutes.splice(index, 1);
    dispatch(setSearchCriteriaEffect(getFormattedData(clonedCommutes)));
  };

  const onChangePref = (pref, index) => {
    const data = commutes;
    data[index] = {
      ...data[index],
      ImportanceAndWeight: {
        Importance: pref,
      },
    };
    setCommutes(data);
    dispatch(setSearchCriteriaEffect(getFormattedData(commutes)));
  };

  if (commuteId || commuteId === 0) {
    return null;
  }
  return (
    <div className={styles.commutesAndProximity}>
      <ScrollDrawerWrapper className={styles.scrollWrapper}>
        <DrawerPaddingWrapper className={classNames(className)}>
          <section className={styles.commuteType}>
            <h3 className={styles.sectionTitle}>Commutes</h3>
            {groupedCommutes?.commutes?.map((commute, idx) => {
              const {
                Id,
                Name,
                MaxCommuteTimeInMinutes,
                DestinationAddress,
                ImportanceAndWeight,
                index,
              } = commute;
              return (
                <Commute
                  key={Id || idx}
                  index={index}
                  name={Name}
                  type="Commutes"
                  commuteTime={MaxCommuteTimeInMinutes}
                  address={DestinationAddress?.PlaceName}
                  onDelete={onDeleteCommute}
                  onChange={onChangePref}
                  prefType={ImportanceAndWeight?.Importance}
                  isCommute={true}
                />
              );
            })}
            <AddCommute isAnother={groupedCommutes?.commutes?.length > 0} />
          </section>
          <section className={styles.commuteType}>
            <h3 className={styles.sectionTitle}>Proximity to Important Locations</h3>
            {groupedCommutes?.proximity?.map((commute, idx) => {
              const {
                Id,
                Name,
                MaxCommuteTimeInMinutes,
                DestinationAddress,
                ImportanceAndWeight,
                index,
              } = commute;
              return (
                <Commute
                  key={Id || idx}
                  index={index}
                  name={Name}
                  type="Proximity"
                  commuteTime={MaxCommuteTimeInMinutes}
                  address={DestinationAddress?.PlaceName}
                  onDelete={onDeleteCommute}
                  onChange={onChangePref}
                  prefType={ImportanceAndWeight?.Importance}
                  isCommute={false}
                />
              );
            })}
            <AddProximity isAnother={groupedCommutes?.proximity?.length > 0} />
          </section>
        </DrawerPaddingWrapper>
      </ScrollDrawerWrapper>
      <Footer className={styles.footer}>
        <Button
          onClick={submitHandler}
          className={styles.submitButton}
          title={isEditMode ? 'Save' : 'Search'}
          isPending={isPending}
          testid="done"
          // disabled={!isDirty}
        />
      </Footer>
    </div>
  );
};

Commutes.cases = [
  { id: 1, name: 'Office', type: 'car', commuteTime: 15, address: '2128 West Broadway' },
  { id: 2, name: 'Daycare', type: 'transit', commuteTime: 30, address: '2128 West Broadway' },
  { id: 3, name: 'Grocery', type: 'walk', commuteTime: 15, address: '2128 West Broadway' },
];

Commutes.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  onSubmit: PropTypes.func,
};

Commutes.defaultProps = {
  className: '',
  isPending: false,
  onSubmit: () => {},
};

export default Commutes;
