import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { Wrapper } from 'components';
import { getTransactionTemplateSelector } from 'store/selectors/template';
import { getTransactionTemplateEffect } from 'store/effects/template';
import { requestGetProjectCategories } from 'store/effects/projects';
import { TemplateTabs } from 'pages/Templates/Transactions/components/TemplateTabs';
import { link } from 'settings/navigation/link';

export const TeamTransactionTemplateView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const teamId = +params.teamId;

  const { id: templateId } = useParams<{ id: string }>();

  const template = useSelector(getTransactionTemplateSelector);

  useEffect(() => {
    dispatch(requestGetProjectCategories({}, { silent: true }));
    dispatch(getTransactionTemplateEffect({ id: templateId }));
  }, [templateId, dispatch]);

  const handleGoBack = () => {
    history.push(link.toDashboardTeamTransactionTemplate(teamId));
  };

  return (
    <Wrapper isPending={template.isPending}>
      <TemplateTabs
        isViewMode={true}
        handleGoBack={handleGoBack}
        handleCancel={handleGoBack}
        transactionTemplate={template.data}
      />
    </Wrapper>
  );
};
