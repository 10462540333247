import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Row, Col } from 'antd';
import { Icons } from 'pages/Workshop/Icons';
import { Filter } from '../Filter';
import { MilestoneModal } from '../MilestoneModal';
import { resetTransactionEffect } from 'store/effects/transactions';
import { SearchFieldWithDebounce } from 'components';
import { TEAM_OWNER, TEAM_ADMIN, TEAM_BASIC } from 'settings/constants/roles';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TransactionsFilter } from 'pages/Workshop/Transactions/components/Actions/QuickFilters';
import {
  setMilestoneArchiveEffect,
  setMilestoneFilterEffect,
} from 'store/effects/milestoneAggregate';
import { getMilestoneStateSelector } from 'store/selectors/milestoneAggregate';
import { LocationService } from 'services';
import { useLocation } from 'react-router-dom';

import styles from './styles.module.scss';

export const LocalHeader = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const { isArchive } = useSelector(getMilestoneStateSelector);

  const dispatch = useDispatch();
  const locationSrv = new LocationService();
  const location = useLocation();

  locationSrv.setLocation(location);
  useEffect(() => {
    function handleWindowSizeChange() {
      setIsMobile(window.innerWidth <= 992);
    }
    if (document.readyState === 'complete') {
      handleWindowSizeChange();
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.addEventListener('load', handleWindowSizeChange);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isMobile]);

  useEffect(() => {
    const query = locationSrv.getQuery();
    if ('isArchive' in query) dispatch(setMilestoneArchiveEffect(query.isArchive));

    return () => {
      dispatch(setMilestoneArchiveEffect(false));
    };
  }, []);

  const onNew = () => {
    dispatch(resetTransactionEffect());
    setTaskModal(true);
  };

  const onArchiveChange = () => {
    dispatch(setMilestoneArchiveEffect(!isArchive));
  };

  return (
    <div className={styles.workshopHeader}>
      <Row className={styles.workshopActionsContainer} align="middle" justify="space-between">
        <Col className={styles.workshopHeaderLeft} xs={24} lg={16}>
          <div className={styles.searchWrapper}>
            <SearchFieldWithDebounce
              className={styles.inputWrap}
              classNameInput={styles.searchInput}
              iconClassName={styles.icon}
              resetAction={() => setMilestoneFilterEffect({ search: null })}
              sendAction={({ search }) => setMilestoneFilterEffect({ search })}
              isLoading={false}
              placeholder="Search"
              testid="search"
              isTransactionAggregate={true}
              isExpandedVersion
            />
          </div>
          <div className="seperator" />
          {isTeamAgentActive && [TEAM_OWNER, TEAM_ADMIN, TEAM_BASIC].includes(agentRole) && (
            <TransactionsFilter agentRole={agentRole} />
          )}
        </Col>
        <Col xs={24} lg={8}>
          <div className={styles.actionsRight}>
            <div className="seperator" />
            <div
              className={classNames(styles.btn, styles.newBtn, { [styles.btnText]: !isMobile })}
              onClick={onNew}
            >
              <Icons className={styles.addIcon} variant={Icons.ADD} />
              {isMobile ? '' : <span className={styles.iconText}>New</span>}
            </div>
            <Filter />
            <div
              className={classNames(styles.btn, { [styles.selected]: isArchive })}
              onClick={() => onArchiveChange()}
            >
              <Icons
                className={styles.btnIcon}
                variant={Icons.ARCHIVE}
                color={isArchive ? '#FFFFFF' : ''}
              />
            </div>
            <MilestoneModal isOpen={taskModal} onCloseModal={() => setTaskModal(false)} />
          </div>
        </Col>
      </Row>
    </div>
  );
};
