import { apiServer } from 'settings/web-services/api';
import { GET_TRANSACTION_CONTACTS } from 'app-constants/endpoints';

export function getAllTransactions(cfg) {
  return apiServer.get('/transaction', { params: cfg?.filters ?? cfg });
}

export function getTransaction(cfg) {
  return apiServer.get(`/transaction/${cfg.id}`, { params: cfg?.filters });
}

export function getKeyDates(cfg) {
  return apiServer.get(`/transaction/${cfg.transactionId}/key-dates`);
}

export function getFinacialPrice(cfg) {
  return apiServer.get(`/transaction/${cfg.transactionId}/price-history`);
}

export function getAllTransactionContacts(cfg) {
  return apiServer.get(`/transaction/${cfg.id}/all-contacts`);
}

export function getTasksForCancelledTransactionFr(cfg) {
  return apiServer.get(`/transaction/canceled-transaction-rule-tasks/${cfg.id}`);
}

export function getTransactionClientAddress(cfg) {
  return apiServer.get(`/transaction/${cfg.id}/client/address`);
}

export function getTransactionRoles(cfg) {
  return apiServer.get('/transaction/participants/roles', { params: cfg });
}

export function getTransactionRoleById(cfg) {
  return apiServer.get(`/transaction/participants/roles/${cfg?.id}`);
}

export function getTransactionParticipant(cfg) {
  return apiServer.get(`/transaction/${cfg?.transactionId}/participant/${cfg.participantId}`);
}

export function getTransactionTask(cfg) {
  return apiServer.get(`/transaction/${cfg?.transactionId}/tasks/${cfg.taskId}`);
}

export const getTransactionContacts = ({ id }) => apiServer.get(GET_TRANSACTION_CONTACTS(id));

export const getTransactionDetails = ({ id }) => apiServer.get(`/transaction/${id}/details`);

export function getTransactionStats(transactionId) {
  return apiServer.get(`/transaction/${transactionId}/stats`);
}
export function getTransactionClientStats(transactionId) {
  return apiServer.get(`/transaction/${transactionId}/client-stats`);
}

export function getTransactionPersons(cfg) {
  return apiServer.get(`transaction/filter-names`, { params: cfg });
}
