import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Select, Option, Row, Col, Tag } from 'components-antd';
import {
  getSmartFormCurrentQuestionSelect,
  getSmartFormMetaSelect,
  getSmartFormSimilarQuestionsTypeSelect,
} from 'store/selectors/formBuilder';
import { PDFFields, QuestionFieldType, SignatureMappingType } from 'types';
import { updateSmartFormMetaEffect } from 'store/effects/formBuilder';
import { PDFIcon } from 'pages/FormBuilder/Icons/PDFIcon';
import { getPDFFieldHexColor } from 'utils';
import { PDF_FIELD_TYPE, SIGNATURE_MAPPING } from 'app-constants';

import styles from './styles.module.scss';

export const SmartGroupingPane = () => {
  const dispatch = useDispatch();

  const {
    disabled,
    questions: AllQuestions,
    disassociatedFields,
  } = useSelector(getSmartFormMetaSelect);

  const questions = useSelector(getSmartFormSimilarQuestionsTypeSelect);

  const question = useSelector(getSmartFormCurrentQuestionSelect);

  const [fieldType, setFieldType] = useState<QuestionFieldType | undefined>();

  const [optionList, setOptionList] = useState<PDFFields[]>([]);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [ungroupedFields, setUngroupedFields] = useState<string[]>([]);

  useEffect(() => {
    setSelectedValues(question?.Fields?.map((f) => f.id) || []);
    setFieldType(question?.FieldType);
  }, [question]);

  useEffect(() => {
    setUngroupedFields(disassociatedFields?.map((f) => f.id) || []);
  }, [disassociatedFields]);

  const updatedOptionList = () => {
    let options: PDFFields[] = [...(disassociatedFields || [])];

    if (questions?.length && fieldType) {
      const allFields = AllQuestions.map((q) => q.Fields || []).flat();

      options = options.concat(allFields);
    }
    const updateOptionList = [...options].sort((a, b) => {
      if (a.fieldName < b.fieldName) {
        return -1;
      }
      if (a.fieldName > b.fieldName) {
        return 1;
      }
      return 0;
    });

    setOptionList(updateOptionList);
  };

  useEffect(() => {
    updatedOptionList();
  }, [AllQuestions, fieldType, disassociatedFields]);

  const clearSignatureField = (type: SignatureMappingType) => {
    const fieldsToRemove = question?.Fields?.filter((el) => el.customTag === type).map(
      (el) => el.id,
    );
    if (fieldsToRemove?.length) {
      const updatedUngrouped = [...ungroupedFields, ...fieldsToRemove];

      setUngroupedFields(updatedUngrouped);
      updateGrouping(selectedValues, updatedUngrouped);
    }
  };

  const handleMultiSignatureChange = (values, updateOption: Partial<PDFFields>) => {
    if (values && Array.isArray(values) && fieldType) {
      const replacedFields =
        question?.Fields?.filter(
          (el) => el.customTag === updateOption.customTag && !values.includes(el.id),
        ).map((el) => el?.id) || [];

      const updatedUngrouped = [
        ...ungroupedFields.filter((el) => !values.includes(el)),
        ...replacedFields,
      ];
      const updatedSelected = [
        ...selectedValues.filter((el) => !replacedFields.includes(el)),
        ...values,
      ];

      setSelectedValues(updatedSelected);
      setUngroupedFields(updatedUngrouped);
      updateGrouping(updatedSelected, updatedUngrouped, { keys: values, updateOption });
    }
  };

  const handleSignatureChange = (value, updateOption: Partial<PDFFields>) => {
    if (value && fieldType) {
      const replacedFields =
        question?.Fields?.filter(
          (el) => el.customTag === updateOption.customTag || el.id === value,
        ).map((el) => el?.id) || [];

      const updatedUngrouped = [...ungroupedFields.filter((el) => el !== value), ...replacedFields];
      const updatedSelected = [
        ...selectedValues.filter((el) => !replacedFields.includes(el)),
        value,
      ];

      setSelectedValues(updatedSelected);
      setUngroupedFields(updatedUngrouped);
      updateGrouping(updatedSelected, updatedUngrouped, { keys: [value], updateOption });
    }
  };

  const handleChange = (value) => {
    const updatedUngrouped = [
      ...ungroupedFields.filter((el) => el !== value),
      ...(selectedValues?.length ? selectedValues : []),
    ];
    const updatedSelected = [value];

    setSelectedValues(updatedSelected);
    setUngroupedFields(updatedUngrouped);
    updateGrouping(updatedSelected, updatedUngrouped);
  };

  const handleSelect = (value) => {
    if (value && fieldType) {
      const updatedUngrouped = ungroupedFields.filter((el) => el !== value);
      const updatedSelected = [...selectedValues, value];

      setSelectedValues(updatedSelected);
      setUngroupedFields(updatedUngrouped);
      updateGrouping(updatedSelected, updatedUngrouped);
    }
  };

  const handleDeselect = (value) => {
    if (value && fieldType) {
      const updatedSelected = selectedValues.filter((el) => el !== value);
      const updatedUngrouped = [...ungroupedFields, value];

      setSelectedValues(updatedSelected);
      setUngroupedFields(updatedUngrouped);
      updateGrouping(updatedSelected, updatedUngrouped);
    }
  };

  const updateGrouping = (selected, ungrouped, selectedFieldUpdate?) => {
    const UUID = question?.UUID;

    let fieldsToSelect = optionList.filter((op) => selected.includes(op.id));

    if (selectedFieldUpdate?.keys?.length && selectedFieldUpdate?.updateOption) {
      fieldsToSelect = fieldsToSelect.map((el) =>
        selectedFieldUpdate.keys.includes(el?.id)
          ? { ...el, ...selectedFieldUpdate.updateOption }
          : el,
      );
    }

    const newSelectedFields = selected.filter((s) => !question?.Fields?.some((f) => f.id === s));

    if (fieldsToSelect.length || ungrouped?.length) {
      const updatedQuestions = AllQuestions.map((q) => {
        if (
          fieldType === q.FieldType ||
          fieldType === PDF_FIELD_TYPE.PDFSignature ||
          fieldType === PDF_FIELD_TYPE.PDFTextField
        ) {
          if (q.JumpLogic) {
            if (q.FieldType === PDF_FIELD_TYPE.PDFRadioGroup) {
              if (q.UUID !== question?.UUID) {
                for (const field of newSelectedFields) {
                  if (q.Fields?.some((f) => f.id === field)) {
                    const options = (
                      fieldsToSelect.find((f) => f.id === field)?.radioOptions || []
                    ).map((o) => o.value);

                    for (const option of options) {
                      if (q.JumpLogic[option]) delete q.JumpLogic[option];
                    }
                  }
                }
              }
              for (const field of ungrouped) {
                if (q.Fields?.some((f) => f.id === field)) {
                  const options = (
                    fieldsToSelect.find((f) => f.id === field)?.radioOptions || []
                  ).map((o) => o.value);

                  for (const option of options) {
                    if (q.JumpLogic[option]) delete q.JumpLogic[option];
                  }
                }
              }
            } else {
              if (q.UUID !== question?.UUID) {
                for (const field of newSelectedFields) {
                  if (q.JumpLogic?.[field]) delete q.JumpLogic[field];
                }
              }
              for (const field of ungrouped) {
                if (q.JumpLogic?.[field]) delete q.JumpLogic[field];
              }
            }
          }

          if (!q.Fields) {
            q.Fields = [];
          }

          if (q.UUID === UUID) {
            q.Fields = fieldsToSelect;
          } else {
            q.Fields = q.Fields.filter((f) => !selected.includes(f.id));
          }

          q.Fields = q.Fields.filter((f) => !ungrouped.includes(f.id));
        }

        return q;
      });

      const updatedData = {
        questions: updatedQuestions,
        disassociatedFields: optionList.filter((op) => ungrouped.includes(op.id)),
      };

      dispatch(updateSmartFormMetaEffect(updatedData));
    }
  };

  const tagRender = ({ label }) => (
    <Tag
      className={styles.groupingTag}
      style={{ backgroundColor: getPDFFieldHexColor(fieldType) }}
      closable={false}
    >
      <span className={styles.tagRender}>{label}</span>
    </Tag>
  );

  const signatureOptionList = (type: SignatureMappingType) =>
    optionList
      .filter((o) => o.fieldType === PDF_FIELD_TYPE.PDFTextField)
      .filter((o) => {
        const existingField = question?.Fields?.find((el) => el?.id === o?.id);
        if (existingField) {
          return existingField?.customTag === type;
        }
        return true;
      });

  const getSignatureMappingFields = () => {
    return (
      <>
        {[
          { label: 'Name', type: SIGNATURE_MAPPING.FullName },
          { label: 'Initials', type: SIGNATURE_MAPPING.Initials },
          { label: 'Signature Date', type: SIGNATURE_MAPPING.SignatureDate },
        ].map((field) => (
          <>
            <Col span={24}>
              <label className={styles.label}>{field.label} Field</label>
            </Col>
            <Col span={24}>
              <Select
                value={question?.Fields?.filter((el) => el?.customTag === field.type)
                  .map((el) => el?.id)
                  .filter((el) => el)}
                large
                showArrow
                tagRender={tagRender}
                getPopupContainer={(triggerNode) => triggerNode}
                className={classNames(styles.selectBox, styles.fieldSelect)}
                mode="multiple"
                onChange={(e) => handleMultiSignatureChange(e, { customTag: field.type })}
                disabled={disabled}
              >
                {signatureOptionList(field.type).map((f) => (
                  <Option key={`fullName-${f.id}`} label={f.fieldName} value={f.id}>
                    <div className={styles.pdfIcon}>
                      <PDFIcon variant={f.fieldType as QuestionFieldType} />
                    </div>
                    {f.fieldName}
                  </Option>
                ))}
              </Select>
              {question?.Fields?.filter((el) => el?.customTag === field.type)?.length ? (
                <span className={styles.clearText} onClick={(e) => clearSignatureField(field.type)}>
                  Clear
                </span>
              ) : (
                <></>
              )}
            </Col>
          </>
        ))}
      </>
    );
  };

  return (
    <div className={styles.groupingPane} data-testid="smart-grouping-page">
      {fieldType && question && (
        <Row key={question.UUID}>
          {question?.FieldType === PDF_FIELD_TYPE.PDFSignature ? (
            <>
              <Col span={24}>
                <label className={styles.label}>Signature Field</label>
              </Col>
              <Col span={24}>
                <Select
                  value={question?.Fields?.filter(
                    (el) => el?.customTag === SIGNATURE_MAPPING.Signature,
                  )
                    .map((el) => el?.id)
                    .filter((el) => el)}
                  large
                  showArrow
                  tagRender={tagRender}
                  getPopupContainer={(triggerNode) => triggerNode}
                  className={classNames(styles.selectBox, styles.fieldSelect)}
                  mode={undefined}
                  onChange={(e) =>
                    handleSignatureChange(e, { customTag: SIGNATURE_MAPPING.Signature })
                  }
                  removeIcon={<></>}
                  disabled={disabled}
                >
                  {optionList
                    .filter((o) => o.fieldType === PDF_FIELD_TYPE.PDFSignature)
                    .map((f) => (
                      <Option key={`signature-${f.id}`} label={f.fieldName} value={f.id}>
                        <div className={styles.pdfIcon}>
                          <PDFIcon variant={f.fieldType as QuestionFieldType} />
                        </div>
                        {f.fieldName}
                      </Option>
                    ))}
                </Select>
              </Col>
              {getSignatureMappingFields()}
            </>
          ) : (
            <>
              <Col span={24}>
                <h4 className={styles.groupingHeader}>{question.Name} Items</h4>
              </Col>
              <Col span={24}>
                <Select
                  value={selectedValues}
                  large
                  showArrow
                  tagRender={tagRender}
                  getPopupContainer={(triggerNode) => triggerNode}
                  className={styles.selectBox}
                  {...(question.FieldType === PDF_FIELD_TYPE.PDFRadioGroup
                    ? {
                        mode: undefined,
                        onChange: handleChange,
                      }
                    : {
                        disabled: disabled,
                        mode: 'multiple',
                        onSelect: handleSelect,
                        onDeselect: handleDeselect,
                      })}
                  removeIcon={<></>}
                >
                  {optionList
                    .filter((o) => o.fieldType === fieldType)
                    .map((f) => (
                      <Option key={f.id} label={f.fieldName} value={f.id}>
                        <div className={styles.pdfIcon}>
                          <PDFIcon variant={f.fieldType as QuestionFieldType} />
                        </div>
                        {f.fieldName}
                      </Option>
                    ))}
                </Select>
              </Col>
            </>
          )}
        </Row>
      )}
    </div>
  );
};
