import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Space } from 'components-antd';
import { TransactionCloseComponentIds } from 'app-constants';
import { getTransactionSelector } from 'store/selectors/transaction';
import Question from 'pages/Workshop/Transactions/TransactionCreate/components/Question';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';
import { TransactionParticipantType } from 'types';
import styles from './styles.module.scss';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { TransactionUserRole } from 'settings/constants/transaction';
import { getPublishedKits } from 'api/kits';
import { Spinner } from 'components';
import KitsTable from './KitsTable';
import { disableKitsByClient } from 'api/kits';
import { retainClient } from 'api/user';

interface KitsActivationProps {
  previousStage?: TransactionCloseComponentIds;
  setStage: (stage: TransactionCloseComponentIds) => void;
  clientAddresses: {
    ClientId: number;
    Address: string;
  }[];
}

export const KitsActivation = ({
  previousStage,
  setStage,
  clientAddresses,
}: KitsActivationProps) => {
  const [clients, setClients] = useState<TransactionParticipantType[] | undefined>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { transaction, address } = useSelector(getTransactionSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [standardKitData, setStandardKitData] = useState<any[]>([]);
  const [disabledKits, setDisabledKits] = useState<number[]>([]);

  const removeDuplicateKits = (array) => {
    const uniqueIds = new Set();
    return array.filter((obj) => {
      if (!uniqueIds.has(obj.KitId)) {
        uniqueIds.add(obj.KitId);
        return true;
      }
      return false;
    });
  };

  const fetchStandardKits = async () => {
    setLoading(true);
    try {
      const publishedKitsResponse = await getPublishedKits();
      if (publishedKitsResponse.status === 200) {
        if (publishedKitsResponse?.data?.result?.length)
          setStandardKitData(removeDuplicateKits(publishedKitsResponse?.data?.result));
        else setStage(TransactionCloseComponentIds.CloseConfirmation);
      } else {
        setError(true);
        setStage(TransactionCloseComponentIds.CloseConfirmation);
      }
    } catch (e) {
      setError(true);
      setStage(TransactionCloseComponentIds.CloseConfirmation);
    }
    setLoading(false);
  };

  const setClientKits = () => {
    if (clients?.length) {
      retainClient(clients[currentIndex].Id, { retain: true });
      if (disabledKits.length)
        disableKitsByClient(clients[currentIndex].Id, { kitIds: disabledKits });
    }
  };

  useEffect(() => {
    if (clients && !clients?.length) {
      if (previousStage === TransactionCloseComponentIds.ClientProfileAddAddress) {
        setStage(TransactionCloseComponentIds.CloseConfirmation);
      } else if (previousStage === TransactionCloseComponentIds.CloseConfirmation) {
        setStage(TransactionCloseComponentIds.ClientProfileAddAddress);
      }
    } else if (clients?.length && !loading) {
      fetchStandardKits();
    }
  }, [clients]);

  useEffect(() => {
    setClients(
      transaction.Participants.filter(
        (participant) =>
          participant.IsClient && participant.Role.Title === TransactionUserRole.Buyer,
      ) || [],
    );
  }, []);

  const formatAddress = (address) =>
    `${address.Line1}, ${address.City}, ${address.State} ${address.Zip}`;

  const getAddress = () => {
    if (clients?.length && clientAddresses.length) {
      const found = clientAddresses.find((obj) => obj.ClientId === clients[currentIndex].Id);
      if (found) return found.Address;
    }
    return formatAddress(address);
  };

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function simulateLoad() {
    setLoading(true);
    await delay(500);
    setLoading(false);
  }

  const kitDisableHandler = useCallback((id: number, add: boolean) => {
    setDisabledKits((prevArray) => {
      if (!add && !prevArray.includes(id)) {
        return [...prevArray, id];
      } else if (add && prevArray.includes(id)) {
        const kitIds = [...prevArray];
        const indexToRemove = kitIds.indexOf(id);
        if (indexToRemove !== -1) {
          kitIds.splice(indexToRemove, 1);
          return kitIds;
        }
      }
      return prevArray;
    });
  }, []);

  useEffect(() => {
    if (currentIndex !== 0) {
      setDisabledKits([]);
      simulateLoad();
    }
  }, [currentIndex]);

  return clients?.length ? (
    <div className={styles.kitsActivation}>
      <Question>Do you want to enable any KITs?</Question>
      {loading ? (
        <Spinner loaderClassName={styles.loader} />
      ) : (
        <>
          <AnswersContainer className={styles.answers}>
            <Space size="middle" className={styles.spacing} direction="vertical">
              <p className={styles.text}>
                {`Selected KITs will be turned on for `}
                <span className={styles.info}>
                  {clients[currentIndex].FirstName}
                  {clients[currentIndex].LastName ? ` ${clients[currentIndex].LastName}` : ''}
                </span>
                {` regarding `}
                <span className={styles.info}>{getAddress()}</span>
                {`. KIT configuration can be updated anytime from `}
                <span className={styles.info}>{clients[currentIndex].FirstName}</span>
                {`'s client profile.`}
              </p>
              <KitsTable data={standardKitData} kitDisableHandler={kitDisableHandler} />
            </Space>
          </AnswersContainer>
          <ButtonsContainer>
            <Continue
              onClick={() => {
                if (clients?.length && currentIndex < clients.length) {
                  setClientKits();
                  const index = currentIndex + 1;
                  if (index < clients.length) setCurrentIndex(index);
                  else setStage(TransactionCloseComponentIds.CloseConfirmation);
                } else setStage(TransactionCloseComponentIds.CloseConfirmation);
              }}
              className={styles.submit}
            />
          </ButtonsContainer>
        </>
      )}
    </div>
  ) : (
    <></>
  );
};
