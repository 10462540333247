import axios from 'axios';

const getDevelopmentLLM01ApiLink = () => 'https://llm01.mosaik.io';
const getDevelopmentLLM02ApiLink = () => 'https://llm02.mosaik.io';

export const getLLM01ApiLink = () => {
  return getDevelopmentLLM01ApiLink();
};

export const getLLM02ApiLink = () => {
  return getDevelopmentLLM02ApiLink();
};

export const llm01ApiServer = axios.create({
  baseURL: getLLM01ApiLink(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'secret-key': 'ABCDEF',
  },
});

export const llm02ApiServer = axios.create({
  baseURL: getLLM02ApiLink(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'secret-key': 'ABCDEF',
  },
});
