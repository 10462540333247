import { useEffect, useState } from 'react';
import { upperCase } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import SavedSearchesBlock from 'pages/Properties/MySearches/components/SavedSearchesBlock';
import { Icons } from '../Icons';
import { routes } from 'settings/navigation/routes';
import {
  openMessagesDrawerAction,
  changeMessagesDrawerTypeAction,
} from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { ClientSearchPreApprovals } from '../ClientSearchPreApprovals';
import { TickFileIcon } from 'components/Icons';

import styles from './styles.module.scss';
import { getUnreadThreads } from 'store/selectors/sockets/threads';

export const ClientDetails = ({ client, goBack, unreadMessageCount }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showApprovalsModal, setShowApprovalsModal] = useState(false);
  const [preApprovalCount, setPreApprovalCount] = useState(0);
  const threads = useSelector(getUnreadThreads);

  const getThreadIdByParticipantId = (clientId) =>
    threads.find(({ Participants }) => Participants.some(({ Id }) => Id === clientId))?.Id || null;

  const filterByStatus = (data, status) => {
    return data?.filter((item) => item.status === status);
  };

  useEffect(() => {
    setPreApprovalCount(+client.PreApprovalsCount ?? 0);
  }, [client.PreApprovalsCount]);
  const redirectTo = () => {
    history.push(`${routes.feed}?activeTab=5&clientId=${client.Id}`);
  };

  const openMessageDrawer = (clientId) => {
    const threadId = getThreadIdByParticipantId(clientId);
    dispatch(openMessagesDrawerAction(true));
    dispatch(
      changeMessagesDrawerTypeAction({
        type: DRAWER_MESSAGES_TYPES.CHAT,
        params: {
          threadId: threadId,
          participants: [
            {
              id: client.Id,
              name: `${client.FirstName} ${client.LastName || ''}`,
              value: client.Id,
              role: client.Role,
              avatarUrl: client.AvatarUrl,
              firstName: client.FirstName,
              lastName: client.LastName,
            },
          ],
        },
      }),
    );
  };
  const handleViewPreApprovals = () => {
    setShowApprovalsModal(true);
  };
  return (
    <div className={styles.clientDetails}>
      <div className={styles.clientDetailsActions}>
        <div className={styles.clientActions}>
          {/* TODO: un-comment and implement this when tour module is ready */}
          {/* <div className={classNames(styles.actionBtn, styles.heartBtn)}>
            <Icons className={styles.heartIcon} variant="calendar" />
            <span className={styles.count}>{client.FavoritesCount}</span>
          </div> */}
          {client.FavoritesCount ? (
            <div className={classNames(styles.actionBtn, styles.heartBtn)} onClick={redirectTo}>
              <Icons className={styles.heartIcon} variant="heart" />
              <span className={styles.count}>{client.FavoritesCount}</span>
            </div>
          ) : null}
          {preApprovalCount ? (
            <div
              className={classNames(styles.actionBtn, styles.heartBtn)}
              onClick={handleViewPreApprovals}
            >
              <TickFileIcon height={20} width={20} />
              <span className={styles.count}>{preApprovalCount}</span>
            </div>
          ) : null}
          {unreadMessageCount > 0 && (
            <div
              className={classNames(styles.actionBtn, unreadMessageCount ? styles.heartBtn : '')}
              onClick={() => openMessageDrawer(client.Id)}
            >
              <>
                <Icons variant="unread" />
                {unreadMessageCount}
              </>
            </div>
          )}
        </div>
      </div>
      <ClientSearchPreApprovals
        clientId={client.Id}
        showApprovalsModal={showApprovalsModal}
        setShowApprovalsModal={setShowApprovalsModal}
        setPreApprovalCount={setPreApprovalCount}
      />
      <SavedSearchesBlock
        activeSearches={filterByStatus(client.SearchQueries, 'Active')}
        inactiveSearches={filterByStatus(client.SearchQueries, 'Inactive')}
        isLoading={false}
        isClientSearch={true}
        clientId={client.Id}
      />
    </div>
  );
};
