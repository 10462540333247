import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'antd';

import { initState } from './initialState';
import { Stages } from 'components';
import { Modal } from 'components-antd';
import {
  LinkDuration,
  Availability,
  Notify,
  Notes,
  Feedback,
  Documents,
  EnableLink,
} from './StageParts';
import { Icons } from './Icons';
import { requestCreateUpdateShowingEffect } from 'store/effects/showings';
import { getTransactionEffect } from 'store/effects/transactions';
import { ClientNames } from 'pages/Workshop/Transactions/TransactionOverview/components/ClientNames/ClientNames';
import { getShowingParticipantsList } from 'api/showings';
import { showSuccessMessage } from 'helpers/success';
import { extractClientNames } from 'utils';

import styles from './styles.module.scss';

export const ShowingModal = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, transaction, fullAccess } = props;

  const address = transaction?.Property?.Address || '';
  const clients = extractClientNames(transaction, true);

  const [showingFlow, setShowingFlow]: any = useState(initState);
  const [participantList, setParticipantList]: any = useState([]);
  const [loading, setLoading] = useState(false);

  const onUpdate = (data) => {
    setShowingFlow({
      ...showingFlow,
      ...data,
    });
  };

  const fetchTransactionById = (callback?) => {
    dispatch(getTransactionEffect({ id: transaction?.Id }, { silent: true }, callback));
  };

  const createOrUpdateShowing = (body) => {
    const data = {
      ...showingFlow,
      ...body,
    };
    if (data.Id) {
      data.Id = Number(data.Id);
    }
    delete data.SelectedDuration;

    dispatch(
      requestCreateUpdateShowingEffect(data, {}, (err) => {
        !err
          ? fetchTransactionById(() => {
              const toaster = `Showing ${
                data.Id ? 'configuration updated.' : 'configured successfully.'
              }`;
              showSuccessMessage(toaster);
              setLoading(false);
            })
          : setLoading(false);
      }),
    );
  };

  const onFinish = (body) => {
    setLoading(true);
    createOrUpdateShowing(body);
  };

  const fetchParticipants = useCallback(
    async (transactionId) => {
      const { data }: any = await getShowingParticipantsList(transactionId);
      setParticipantList(data?.value || []);
    },
    [dispatch],
  );

  useEffect(() => {
    if (transaction.Id) {
      onUpdate({ PropertyTransactionId: transaction.Id });
      fetchParticipants(transaction.Id);
    }
    if (transaction.Showing) {
      setShowingFlow({
        ...transaction.Showing,
        SelectedDuration: {
          isIndefinite: transaction.Showing.Indefinitely,
          isAddDays: !!transaction.Showing.LinkActiveDays,
          isDateRange: !!(
            transaction.Showing.LinkActiveStartDate &&
            transaction.Showing.LinkActiveEndDate &&
            !transaction.Showing.LinkActiveDays
          ),
        },
      });
    }
  }, [transaction.Id, transaction.Showing]);

  const stagesStep = {
    Availability: 0,
    Notify: 1,
    Documents: 2,
    Notes: 3,
    Feedback: 4,
    LinkDuration: 5,
    EnableLink: 6,
  };

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      className={styles.showingModal}
    >
      <h1 className={styles.heading}>Configure Booking Link</h1>
      <Row className={styles.content}>
        <Col span={24}>
          <div className={styles.propertyTitle}>
            <Icons className={styles.condoIcon} variant={'condo'} />
            <div className={styles.details}>
              <h4 className={styles.address}>
                {address.Line1}, {address.City}, {address.State}
              </h4>

              <p className={styles.subDetails}>
                <span>
                  <strong>Price: </strong> ${transaction?.Price?.toLocaleString()}
                </span>
                <ClientNames clients={clients} className={styles.clientNames} />
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Stages
        stages={[
          { Component: Availability },
          { Component: Notify },
          { Component: Documents },
          { Component: Notes },
          { Component: Feedback },
          { Component: LinkDuration },
          { Component: EnableLink },
        ]}
        classNameProgress={styles.progressBar}
        classNameWrapper={styles.stagesWrapper}
        transaction={transaction}
        modalType={'Showing'}
        participantList={participantList}
        fullAccess={fullAccess}
        onUpdate={onUpdate}
        onFinish={onFinish}
        onClose={onClose}
        data={showingFlow}
        loading={loading}
        stagesStep={stagesStep}
      />
    </Modal>
  );
};
