import PropTypes from 'prop-types';
import { map } from 'lodash-es';

import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { updateConciergeCategoryConfiguration } from 'store/actions/concierge';

import styles from './styles.module.scss';

const CategoriesList = ({ items, onClick }) => {
  const dispatch = useDispatch();
  return (
    <Row gutter={[24, 24]} className={styles.wrapper}>
      {items?.length ? (
        map(items, ({ Id, Icon, Title }) => (
          <Col
            xs={12}
            lg={8}
            testid="service_card"
            key={Id}
            onClick={(e) => {
              onClick(e);
              dispatch(updateConciergeCategoryConfiguration(Id.toString()));
            }}
          >
            <div className={styles.cardContent}>
              <div className={styles.cardImage}>
                {Icon ? <img src={Icon} alt="category" /> : '?'}
              </div>
              <div testid="title" className={styles.cardTitle}>
                {Title}
              </div>
            </div>
          </Col>
        ))
      ) : (
        <div testid="no_services" className={styles.noServices}>
          No services
        </div>
      )}
    </Row>
  );
};
CategoriesList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Icon: PropTypes.string.isRequired,
      Title: PropTypes.string.isRequired,
    }),
  ),
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

CategoriesList.defaultProps = {
  items: [],
};

export default CategoriesList;
