import React from 'react';
import { AntCard } from 'components-antd';
import { Button, Input, Select } from 'components-antd';
import { CloseOutlined, EditFilled, DeleteFilled, GatewayOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewAreaToEditAction,
  cancelDrawingNewShapeAction,
  cancelNewAreaGroupAction,
  deleteEditableAreaAction,
  startDrawingNewShapeAction,
  updateAreaTypeAction,
  updateEditableAreaNameAction,
} from 'store/actions/adminPanel';
import {
  getEditableAreaGroupSelector,
  getEditableShapeIndexSelector,
  isEditableShapeSelectedSelector,
} from 'store/selectors/adminPanel/areaClassifier';
import {
  AreaTypeRefined,
  VisibleShapeData,
} from 'store/reducers/adminPanel/areaClassifier/selectedAreaGroup';

import './EditAreaGroup.scss';
import { CircleButton } from './CircleButton';
import { areaTypeReadableNames } from '../utils';

const areaTypes = Object.values(AreaTypeRefined);

interface EditAreaGroupProps {
  onSaveAreaGroup: () => void;
}

export const EditAreaGroup: React.FC<EditAreaGroupProps> = (props) => {
  const dispatch = useDispatch();
  const editableAreaGroup = useSelector(getEditableAreaGroupSelector);
  const isEditableShapeSelected = useSelector(isEditableShapeSelectedSelector);
  const editableShapeIndex = useSelector(getEditableShapeIndexSelector);

  if (!editableAreaGroup) {
    return null;
  }

  const shouldDisplayDrawButton = (area: { shape?: VisibleShapeData }) =>
    !area.shape && !isEditableShapeSelected;
  const shouldDisplayEditButton = (area: { shape?: VisibleShapeData }) =>
    area.shape && !isEditableShapeSelected;
  const shouldDisplayCancelEditButton = (area: { shape?: VisibleShapeData }, index: number) =>
    isEditableShapeSelected && editableShapeIndex === index;
  const shouldDisplayDeleteAreaButton = (area: { shape?: VisibleShapeData }) =>
    !isEditableShapeSelected && editableAreaGroup.areas.length > 1;

  return (
    <AntCard>
      <div className="formWrapper">
        <div>
          <Input
            placeholder="Area group name"
            value={editableAreaGroup.name}
            onChange={(event) => {
              dispatch(
                updateEditableAreaNameAction({
                  name: event.target.value,
                }),
              );
            }}
          ></Input>
        </div>
        {editableAreaGroup?.areas.map((area, index) => (
          <div key={index} className="areaRow">
            {/* {area.shape?.color && <span style={{ color: area.shape?.color }}>⏺ </span>} */}
            <Select
              defaultValue={area.type}
              options={areaTypes.map((areaType) => ({
                label: areaTypeReadableNames[areaType],
                value: areaType,
              }))}
              className="areaTypeSelector"
              style={{
                ...(area.shape
                  ? {
                      borderColor: area.shape?.color,
                    }
                  : {}),
              }}
              onChange={(value) => {
                dispatch(
                  updateAreaTypeAction({
                    areaType: value,
                    areaIndex: index,
                  }),
                );
              }}
            ></Select>
            <div className="drawAreaButtonsWrapper">
              {shouldDisplayEditButton(area) && (
                <CircleButton
                  icon={<EditFilled />}
                  onClick={() => {
                    dispatch(
                      startDrawingNewShapeAction({
                        areaIndex: index,
                      }),
                    );
                  }}
                />
              )}
              {shouldDisplayDrawButton(area) && (
                <CircleButton
                  icon={<GatewayOutlined />}
                  onClick={() => {
                    dispatch(
                      startDrawingNewShapeAction({
                        areaIndex: index,
                      }),
                    );
                  }}
                />
              )}
              {shouldDisplayCancelEditButton(area, index) && (
                <CircleButton
                  icon={<CloseOutlined />}
                  onClick={() => {
                    dispatch(cancelDrawingNewShapeAction());
                  }}
                />
              )}
              {shouldDisplayDeleteAreaButton(area) && (
                <CircleButton
                  icon={<DeleteFilled />}
                  onClick={() => {
                    dispatch(
                      deleteEditableAreaAction({
                        areaIndex: index,
                      }),
                    );
                  }}
                />
              )}
            </div>
          </div>
        ))}
        {editableAreaGroup.areas[editableAreaGroup.areas.length - 1].shape && (
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(addNewAreaToEditAction());
            }}
            className="addNewAreaButtonWrapper"
          >
            <span>+ Add new area</span>
          </Button>
        )}
        {!isEditableShapeSelected && (
          <>
            <Button
              onClick={() => {
                props.onSaveAreaGroup();
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                dispatch(cancelNewAreaGroupAction());
              }}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    </AntCard>
  );
};

interface NewAreaModel {
  Uuid: string;
  Type: string;
  Shape?: any;
}

interface NewAreaGroupModel {
  Name: string;
  Areas: NewAreaModel[];
}
