import { Checkbox } from 'antd';
import { Fragment } from 'react';

export const SingleCheckbox = ({
  onChange,
  checked,
  text,
  subText = '',
  textClass = '',
  subTextClass = '',
  disabled = false,
  className,
}) => {
  return (
    <Checkbox
      onChange={(e) => {
        onChange(e.target.checked);
      }}
      checked={checked}
      className={className}
      disabled={disabled}
    >
      <Fragment>
        <div className={textClass}>{text}</div>
        {subText && <div className={subTextClass}>{subText}</div>}
      </Fragment>
    </Checkbox>
  );
};
