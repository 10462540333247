import { ICON_VARIANT_TYPE, Icons } from 'pages/Workshop/Forms/Icons';
import styles from './styles.module.scss';
import { Collapse, Panel } from 'components-antd';

export const FormDetailCollapsePanel = (props) => {
  const {
    activeKey,
    onChange,
    disableSections,
    panelHeading,
    panelKey,
    children,
    defaultActiveKey,
    extra,
  } = props;

  const collapseProps = {
    ...(activeKey && onChange && { activeKey, onChange }),
    ...(defaultActiveKey && { defaultActiveKey }),
  };

  return (
    <Collapse
      ghost={true}
      expandIconPosition="end"
      expandIcon={({ isActive }) =>
        isActive ? (
          <Icons variant={ICON_VARIANT_TYPE.OPEN_ACCORDIAN} />
        ) : (
          <Icons variant={ICON_VARIANT_TYPE.CLOSE_ACCORDIAN} />
        )
      }
      {...collapseProps}
    >
      <Panel
        key={panelKey}
        header={panelHeading}
        className={styles.formPanel}
        collapsible={disableSections ? 'disabled' : 'header'}
        extra={extra || undefined}
      >
        {children}
      </Panel>
    </Collapse>
  );
};
