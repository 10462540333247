import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from 'pages/Dashboard/components/Grid';
import Header from 'pages/Dashboard/components/Header';
import Icons from 'pages/Dashboard/components/Icons';
import { Edit, Write } from 'components/Icons';
import { Modal, Wrapper as PendingWrapper, ConfirmationDialog } from 'components';
import { link } from 'settings/navigation/link';
import { routes } from 'settings/navigation/routes';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { getCategoriesSelector } from 'store/selectors/adminPanel';
import {
  getCategoriesEffect,
  deactivateCategoryEffect,
  activateCategoryEffect,
} from 'store/effects/adminPanel';

import Filters from './FilterForm';
import { ViewCategory } from './Category';
import { gridColumns } from './config';
import styles from './styles.module.scss';

const Categories = () => {
  const dispatch = useDispatch();
  const categories = useSelector(getCategoriesSelector);

  useEffect(() => {
    if (categories.state === IDLE) {
      dispatch(getCategoriesEffect());
    }
  }, []); // eslint-disable-line

  const isPending = categories.state === PENDING;

  const [itemToChangeStatus, setItemToChangeStatus] = useState(null);
  const [previewCategory, setPreviewCategory] = useState(null);

  const onPreviewModalClose = useCallback(() => setPreviewCategory(null), [setPreviewCategory]);
  const onRowClick = useCallback((e, data) => setPreviewCategory(data), [setPreviewCategory]);

  const onCloseChangeStatusItemModal = useCallback(
    () => setItemToChangeStatus(null),
    [setItemToChangeStatus],
  );
  const onConfirmChangeItemStatus = useCallback(() => {
    if (!itemToChangeStatus) return;

    const effect = itemToChangeStatus.IsActive ? deactivateCategoryEffect : activateCategoryEffect;

    dispatch(
      effect({ id: itemToChangeStatus.Id }, {}, (err) => {
        if (!err) {
          setItemToChangeStatus(null);
        }
      }),
    );
  }, [dispatch, itemToChangeStatus, setItemToChangeStatus]);

  const renderActions = useCallback(
    (Id, data) => (
      <>
        <Link
          className={styles.editButton}
          to={link.toDashboardServiceDirectoryCategoryEdit(Id)}
          testid="edit_link"
        >
          <Edit className={styles.buttonIcon} />
        </Link>
        <div
          className={data.IsActive ? styles.deactivateButton : styles.activateButton}
          onClick={(e) => {
            e.stopPropagation();
            setItemToChangeStatus(data);
          }}
          testid="activate_button"
        >
          <Icons
            className={styles.buttonIcon}
            variant={data.IsActive ? Icons.DEACTIVATE : Icons.ACTIVATE}
          />
        </div>
      </>
    ),
    [],
  );

  const changeItemStatusContent = useMemo(
    () =>
      itemToChangeStatus ? (
        <div>
          Are you sure that you want to&nbsp;
          <b>{itemToChangeStatus.IsActive ? 'deactivate' : 'activate'}</b>
          &nbsp;category&nbsp;
          <b>{itemToChangeStatus.Title}</b>?
        </div>
      ) : null,
    [itemToChangeStatus],
  );

  return (
    <PendingWrapper testid="categories_view" isPending={isPending} className={styles.pageWrapper}>
      <Header
        title="Categories"
        buttonText="New Category"
        buttonIcon={<Write />}
        buttonLink={routes.dashboardServiceDirectoryCategoryCreate}
      />
      <Filters />
      <Grid
        items={categories.data || []}
        columns={gridColumns}
        keyProp="Id"
        renderActions={renderActions}
        onRowClick={onRowClick}
      />
      <ConfirmationDialog
        onReject={onCloseChangeStatusItemModal}
        onConfirm={onConfirmChangeItemStatus}
        isOpen={!!itemToChangeStatus}
      >
        {changeItemStatusContent}
      </ConfirmationDialog>
      <Modal
        isOpen={!!previewCategory}
        onClose={onPreviewModalClose}
        innerHolderClassName={styles.modalInnerHolder}
        contentClassName={styles.modalContent}
      >
        {!!previewCategory && <ViewCategory categoryData={previewCategory} />}
      </Modal>
    </PendingWrapper>
  );
};

export default Categories;
