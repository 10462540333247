import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SmoothCollapse from 'react-smooth-collapse';

import { Plus, Minus } from 'components/Icons';

import styles from './styles.module.scss';

const Accordion = (props) => {
  const {
    children,
    title,
    className,
    heightTransition,
    testid,
    eagerRender,
    onAnimationComplete,
    resetOpen,
    applyDefaultContainerStyles,
    open,
    getExpandItems,
    iconContainerClassName,
    allowOverflowWhenOpen,
    accordionOpenedClass,
    titleClassName,
    previewClassName,
    additionalItems,
  } = props;
  const [isOpen, setIsOpen] = useState(open);
  const handleButtonClick = useCallback(() => setIsOpen((val) => !val), [setIsOpen]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const applyClassesWhenOpened = () => {
    if (isOpen) {
      return accordionOpenedClass;
    }
  };
  const getExpandIcons = () => {
    if (typeof getExpandItems === 'function') {
      return getExpandItems(isOpen);
    }

    return isOpen ? <Minus /> : <Plus />;
  };

  const onChangeEnd = () => {
    if (!isOpen) return;
    if (onAnimationComplete) {
      onAnimationComplete();
    }
  };

  return (
    <div
      testid={testid}
      className={classNames(className, applyClassesWhenOpened(), {
        [styles.accordionContainer]: applyDefaultContainerStyles,
      })}
    >
      <div
        className={classNames(styles.preview, previewClassName)}
        onClick={() => {
          if (resetOpen) {
            resetOpen(!isOpen);
          }
          handleButtonClick();
        }}
      >
        <h3 className={classNames(styles.title, titleClassName)}>
          {typeof title === 'function' ? title(isOpen) : title}
        </h3>
        <div className={styles.expandWrapper}>
          {additionalItems}
          <div className={classNames(styles.iconContainer, iconContainerClassName)}>
            <button testid="expand_collapse" className={styles.button}>
              {getExpandIcons()}
            </button>
          </div>
        </div>
      </div>
      <SmoothCollapse
        expanded={isOpen}
        heightTransition={heightTransition}
        allowOverflowWhenOpen={allowOverflowWhenOpen}
        className={styles.collapse}
        eagerRender={eagerRender}
        onChangeEnd={onChangeEnd}
      >
        {children}
      </SmoothCollapse>
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  previewClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  iconContainerClassName: PropTypes.string,
  testid: PropTypes.string,
  open: PropTypes.bool,
  heightTransition: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  getExpandItems: PropTypes.func,
  allowOverflowWhenOpen: PropTypes.bool,
  additionalItems: PropTypes.node,
  eagerRender: PropTypes.bool,
  applyDefaultContainerStyles: PropTypes.bool,
  onAnimationComplete: PropTypes.func,
  resetOpen: PropTypes.func,
  accordionOpenedClass: PropTypes.string,
};

Accordion.defaultProps = {
  applyDefaultContainerStyles: true,
  className: null,
  titleClassName: '',
  previewClassName: '',
  iconContainerClassName: null,
  children: null,
  title: null,
  testid: undefined,
  open: false,
  heightTransition: '0.3s ease',
  getExpandItems: undefined,
  allowOverflowWhenOpen: false,
  additionalItems: undefined,
  eagerRender: false,
  onAnimationComplete: null,
  resetOpen: null,
  accordionOpenedClass: '',
};

export default Accordion;
