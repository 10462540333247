import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useEffect, useCallback } from 'react';

import { appointmentFeedback } from 'settings/constants/showings';
import { requestUpdateShowingAppointmentEffect } from 'store/effects/showingAppointment';

import styles from './styles.module.scss';

export const Feedback = ({ uuid, feedback, isFeedback }) => {
  const dispatch = useDispatch();

  const updateIsNewFeedback = useCallback(() => {
    dispatch(
      requestUpdateShowingAppointmentEffect({ UUID: uuid, IsNewFeedback: false }, {}, (err) => {}),
    );
  }, []);

  useEffect(() => {
    if (isFeedback) updateIsNewFeedback();
  }, []);

  return (
    <div className={styles.feedback}>
      <h4>Feedback</h4>
      {feedback.map((obj, index) => {
        return Object.keys(obj).map((f) => {
          return (
            <>
              <p>
                {index + 1}. {appointmentFeedback[f]}
              </p>
              <p className={styles.answer}>{obj[f]}</p>
            </>
          );
        });
      })}
      <p></p>
    </div>
  );
};

Feedback.propTypes = {
  uuid: PropTypes.string,
  feedback: PropTypes.object,
  isFeedback: PropTypes.bool,
};

Feedback.defaultProps = {
  uuid: null,
  feedback: [],
  isFeedback: false,
};
