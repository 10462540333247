const Icons = ({ variant, color = '#FF576D' }) => {
  switch (variant) {
    case 'compare':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M5.5 8.00033L7.58333 10.0837L14.25 3.41699"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.0833 1.75H3.83333C2.68274 1.75 1.75 2.68274 1.75 3.83333V12.1667C1.75 13.3173 2.68274 14.25 3.83333 14.25H12.1667C13.3173 14.25 14.25 13.3173 14.25 12.1667V8"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'schedule':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M12.5 2H3.5C2.67157 2 2 2.67157 2 3.5V12.5C2 13.3284 2.67157 14 3.5 14H12.5C13.3284 14 14 13.3284 14 12.5V3.5C14 2.67157 13.3284 2 12.5 2Z"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 1V3"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 1V3"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 6H14"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'watchlist':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_42913_525)">
            <path
              d="M8.00108 11.834L3.88642 13.9973L4.67242 9.41532L1.34375 6.17065L5.94375 5.50265L8.00108 1.33398L10.0584 5.50265L14.6584 6.17065L11.3298 9.41532L12.1158 13.9973L8.00108 11.834Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_42913_525">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    case 'comment':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M2.4985 11.2473L3.41858 11.639L3.6164 11.1744L3.35994 10.7394L2.4985 11.2473ZM1.18542 14.3312L2.10549 14.7229H2.10549L1.18542 14.3312ZM1.69742 14.8611L2.05734 15.7941L2.05734 15.7941L1.69742 14.8611ZM4.93435 13.6124L5.41649 12.7363L5.0087 12.5118L4.57443 12.6794L4.93435 13.6124ZM13.4156 8.00156C13.4156 10.9839 10.998 13.4016 8.01562 13.4016V15.4016C12.1025 15.4016 15.4156 12.0885 15.4156 8.00156H13.4156ZM8.01562 2.60156C10.998 2.60156 13.4156 5.01922 13.4156 8.00156H15.4156C15.4156 3.91466 12.1025 0.601562 8.01562 0.601562V2.60156ZM2.61562 8.00156C2.61562 5.01922 5.03328 2.60156 8.01562 2.60156V0.601562C3.92871 0.601562 0.615615 3.91466 0.615615 8.00156H2.61562ZM3.35994 10.7394C2.88714 9.93743 2.61562 9.00273 2.61562 8.00156H0.615615C0.615615 9.36965 0.987804 10.6539 1.63707 11.7551L3.35994 10.7394ZM2.10549 14.7229L3.41858 11.639L1.57843 10.8555L0.26535 13.9394L2.10549 14.7229ZM1.3375 13.9281C1.83226 13.7372 2.31324 14.235 2.10549 14.7229L0.26535 13.9394C-0.219396 15.0779 0.902892 16.2394 2.05734 15.7941L1.3375 13.9281ZM4.57443 12.6794L1.3375 13.9281L2.05734 15.7941L5.29427 14.5453L4.57443 12.6794ZM8.01562 13.4016C7.0714 13.4016 6.18658 13.1601 5.41649 12.7363L4.4522 14.4885C5.5102 15.0707 6.72577 15.4016 8.01562 15.4016V13.4016Z"
            fill="#FF576D"
          />
        </svg>
      );

    case 'share':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8.75 2.10784V5C4.24924 5 1.66628 7.55708 1.29624 12.3761C1.26645 12.7641 1.76789 12.9386 2.02698 12.6483C3.74918 10.7183 5.82794 9.875 8.75 9.875L8.75 13.1422C8.75 13.3203 8.96543 13.4096 9.09142 13.2836L14.4672 7.90784C14.6234 7.75163 14.6234 7.49837 14.4672 7.34216L9.09142 1.96642C8.96543 1.84043 8.75 1.92966 8.75 2.10784Z"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'heart':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
        >
          <path
            d="M7.28822 13.0542L1.85598 7.54741C1.26173 6.93487 0.900391 6.1091 0.900391 5.20005C0.900391 3.33396 2.4355 1.80005 4.35539 1.80005C5.52842 1.80005 6.56145 2.37393 7.18615 3.25012L8.00039 4.39214L8.81463 3.25012C9.43932 2.37394 10.4724 1.80005 11.6454 1.80005C13.5653 1.80005 15.1004 3.33396 15.1004 5.20005C15.1004 6.15471 14.7018 7.01813 14.053 7.6385L14.0424 7.64864L14.0321 7.65908L8.71216 13.054C8.32059 13.4511 7.67987 13.4512 7.28822 13.0542Z"
            stroke="#FF576D"
            strokeWidth="2"
          />
        </svg>
      );
    case 'delete':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_45937_26292)">
            <path
              d="M4.00195 7H20.002"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.002 11V17"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.998 11V17"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.99805 7L5.99805 19C5.99805 19.5304 6.20876 20.0391 6.58383 20.4142C6.95891 20.7893 7.46761 21 7.99805 21H15.998C16.5285 21 17.0372 20.7893 17.4123 20.4142C17.7873 20.0391 17.998 19.5304 17.998 19L18.998 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_45937_26292">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    case 'clear':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 13L3 3" stroke="#FF576D" strokeWidth="2" strokeLinecap="round" />
          <path d="M3 13L13 3" stroke="#FF576D" strokeWidth="2" strokeLinecap="round" />
        </svg>
      );
    case 'star':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_48872_160121)">
            <path
              d="M8.00108 11.834L3.88642 13.9973L4.67242 9.41532L1.34375 6.17065L5.94375 5.50265L8.00108 1.33398L10.0584 5.50265L14.6584 6.17065L11.3298 9.41532L12.1158 13.9973L8.00108 11.834Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_48872_160121">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    default:
      return null;
  }
};

Icons.COMPARE = 'compare';
Icons.SCHEDULE = 'schedule';
Icons.WATCHLIST = 'watchlist';
Icons.COMMENT = 'comment';
Icons.SHARE = 'share';
Icons.HEART = 'heart';
Icons.DELETE = 'delete';
Icons.CLEAR = 'clear';
Icons.STAR = 'star';

export default Icons;
