import { useSelector } from 'react-redux';

import { getSubFilterTypeSelector } from 'store/selectors/feed';
import { subFilterTypes } from 'settings/constants/properties';

import styles from './styles.module.scss';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { getUserRolesMapSelector } from 'store/selectors/user';

export const EmptyState = ({ isSimpleHeaderTitleVisible }) => {
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const history = useHistory();
  const currentPath = history.location.pathname;
  const { isAgent } = useSelector(getUserRolesMapSelector);

  const emptyMessage = useMemo(() => {
    if (currentPath.includes(routes.searchResults)) {
      return "We didn't find any results for the criteria you entered. Please try changing your criteria" +
        isAgent
        ? '.'
        : ' or contact your agent.';
    }

    if (isSimpleHeaderTitleVisible?.value) {
      return `It looks like you don't have any ${isSimpleHeaderTitleVisible?.text?.toLowerCase()} yet.`;
    }

    switch (subFilterType) {
      case subFilterTypes.ALL:
        return "We didn't find any properties for this search.";
      case subFilterTypes.NEW:
        return "We didn't find any new properties for this search.";
      case subFilterTypes.OPEN_HOUSES:
        return "We didn't find any open houses for this search.";
      case subFilterTypes.RECOMMENDED:
        return "We didn't any find any recommended properties for this search.";
      case subFilterTypes.STATUS_CHANGES:
        if (isAgent)
          return "We didn't find any properties with recent status changes for this search.";
        return "We didn't find any status changes for this search.";
      case subFilterTypes.PRICE_REDUCTIONS:
        return "We didn't find any price reductions for this search.";
      case subFilterTypes.BACK_ON_THE_MARKET:
        if (isAgent)
          return "We didn't find any properties that recently came back on the market for this search.";
        return "We didn't find any back on the market for this search.";
      case subFilterTypes.COMMENTS:
        return "We didn't find any properties with comments for this search.";
      default:
        return '';
    }
  }, []);

  const emptyTitle = useMemo(() => {
    if (currentPath.includes(routes.searchResults)) {
      return 'No Results';
    }

    if (isSimpleHeaderTitleVisible?.value) {
      return `No ${isSimpleHeaderTitleVisible?.text}.`;
    }

    switch (subFilterType) {
      case subFilterTypes.ALL:
        return 'No Properties';
      case subFilterTypes.NEW:
        return 'No New Properties';
      case subFilterTypes.OPEN_HOUSES:
        return 'No Open Houses';
      case subFilterTypes.RECOMMENDED:
        return 'No Recommended Properties';
      case subFilterTypes.STATUS_CHANGES:
        return 'No Status Changes';
      case subFilterTypes.PRICE_REDUCTIONS:
        return 'No Price Reductions';
      case subFilterTypes.BACK_ON_THE_MARKET:
        if (isAgent) return `No Properties "Back on the Market"`;
        return 'No Back on the Market Properties';
      case subFilterTypes.COMMENTS:
        return 'No Comments';
      default:
        return '';
    }
  }, []);

  return (
    <div className={styles.propertiesEmptyContainer}>
      <p className={styles.title}>{emptyTitle}</p>
      <p className={styles.subtitle}>{emptyMessage}</p>
    </div>
  );
};
