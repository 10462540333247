import React, { useCallback, useLayoutEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { ArrowRight as Arrow } from 'components/Icons';

import styles from './styles.module.scss';

const CarouselComponent = (props) => {
  const { slides = [] } = props;

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const slidesRef = useRef();
  const previouseSlideIndexRef = useRef(0);
  const currentOffsetRef = useRef(0);

  const onNextClick = useCallback(() => {
    setActiveSlideIndex((index) => (index + 1 < slides.length ? index + 1 : 0));
  }, [setActiveSlideIndex, slides.length]);

  const onPrevClick = useCallback(() => {
    setActiveSlideIndex((index) => (index - 1 >= 0 ? index - 1 : slides.length - 1));
  }, [setActiveSlideIndex, slides.length]);

  useLayoutEffect(() => {
    if (slidesRef.current) {
      const offset = slidesRef.current.scrollWidth / slides.length;

      if (offset) {
        if (previouseSlideIndexRef.current < activeSlideIndex) {
          currentOffsetRef.current -= offset * (activeSlideIndex - previouseSlideIndexRef.current);
        } else if (previouseSlideIndexRef.current > activeSlideIndex) {
          currentOffsetRef.current += offset * (previouseSlideIndexRef.current - activeSlideIndex);
        }

        slidesRef.current.style.transform = `translateX(${currentOffsetRef.current}px)`;
      }
    }
    previouseSlideIndexRef.current = activeSlideIndex;
  }, [activeSlideIndex, slides]);

  return (
    <div className={styles.carousel}>
      <div className={styles.slides} ref={slidesRef}>
        {slides}
      </div>
      <hr />
      <div className={styles.controls}>
        <div onClick={onPrevClick} className={styles.arrow}>
          <Arrow className={styles.arrowLeft} color="#303030" width={16} height={16} />
        </div>
        <div className={styles.pages}>
          {activeSlideIndex + 1}
          &nbsp;of&nbsp;
          {slides.length}
        </div>
        <div onClick={onNextClick} className={styles.arrow}>
          <Arrow color="#303030" width={16} height={16} />
        </div>
      </div>
    </div>
  );
};

CarouselComponent.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
};
CarouselComponent.defaultProps = {};

export default CarouselComponent;
