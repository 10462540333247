import { editMessage, getMessagesByThreadId, sendNewMessage } from 'services/sockets';
import { showErrorMessage } from 'helpers/errors';
import {
  replaceTempTransactionTaskMessageAction,
  setNewTransactionTaskMessageWithTempIdAction,
  socketSetEditTransactionTaskMessageAction,
  socketsResetTransactionTaskMessagesAction,
  socketsStoreTransactionTaskMessagesByThreadIdAction,
  socketsStoreTransactionTaskMessagesSetThreadIdAction,
} from 'store/actions/sockets/transactionTaskMessages';
import { getState } from 'store';
import { uniqueId } from 'lodash-es';
import moment from 'moment';
import { preparedAttachments } from '../helpers';

export function socketsGetTransactionTaskMessagesByThreadIdEffect(cfg, options, cb = () => {}) {
  return (dispatch) => {
    const config = {
      Id: cfg?.Id,
    };
    getMessagesByThreadId(config, (err, data) => {
      if (err) {
        return showErrorMessage(err);
      }
      dispatch(socketsStoreTransactionTaskMessagesByThreadIdAction({ data, threadId: cfg?.Id }));
      cb(err, data);
    });
  };
}

export function editTransactionTaskMessageEffect(cfg) {
  return () => {
    const config = {
      MessageId: cfg?.MessageId,
      Text: cfg?.Text || '',
      Attachments: preparedAttachments(cfg?.Attachments),
      MessageType: cfg?.MessageType,
      MessageMeta: cfg?.MessageMeta,
      AccessType: cfg?.AccessType,
    };
    editMessage(config, (err) => {
      if (err) {
        return showErrorMessage(err);
      }
    });
  };
}

export function setNewTransactionTaskMessageWithTempIdEffect(cfg) {
  return (dispatch) => {
    const { user } = getState();
    const userId = user?.data?.Id;
    const config = {
      CreatedDate: new Date(),
      SenderUserId: userId,
      MessageThreadId: cfg?.ThreadId,
      TempId: cfg?.TempId,
      Text: cfg?.Text,
      Attachments: cfg?.Attachments,
      MessageType: cfg?.MessageType,
      MessageMeta: cfg?.MessageMeta,
      AccessType: cfg.AccessType,
    };
    dispatch(setNewTransactionTaskMessageWithTempIdAction(config));
  };
}

export function sendNewTransactionTaskMessageEffect(cfg, options, cb = () => {}) {
  return (dispatch) => {
    const config = {
      ThreadId: cfg?.ThreadId,
      TempId: `${uniqueId()}-${moment().valueOf()}`,
      Text: cfg?.Text || '',
      Attachments: preparedAttachments(cfg?.Attachments),
      RequestTour: cfg?.RequestTour,
      MessageType: cfg?.MessageType,
      MessageMeta: cfg?.MessageMeta,
      AccessType: cfg.AccessType,
    };

    dispatch(setNewTransactionTaskMessageWithTempIdEffect(config));
    sendNewMessage(config, (err, data) => {
      if (err) {
        return showErrorMessage(err);
      }
      dispatch(replaceTempTransactionTaskMessageAction(data));
      cb(err, data);
    });
  };
}

export function resetTransactionTaskMessagesEffect() {
  return (dispatch) => {
    dispatch(socketsResetTransactionTaskMessagesAction());
  };
}

export function socketSetEditTransactionTaskMessageEffect(cfg) {
  return (dispatch) => {
    dispatch(socketSetEditTransactionTaskMessageAction(cfg));
  };
}

export function setTransactionTaskMessagesCurrentThreadIdEffect(cfg) {
  return (dispatch) => {
    dispatch(socketsStoreTransactionTaskMessagesSetThreadIdAction(cfg));
  };
}
