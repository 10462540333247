import { Input } from 'components';
import { FormContainer, FormFooter, FormHeader } from 'pages/OnboardingV2Agent/components';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import classNames from 'classnames';
import { useFormik } from 'formik';
import parentStyles from 'pages/SignupV2/components/PersonalDetails/styles.module.scss';
import { useDispatch } from 'react-redux';
import { getAgentTeamDetailEffect, onBoardingSignUpEffect } from 'store/effects';
import styles from './styles.module.scss';

enum FormFields {
  TEAM = 'teamName',
}

type FormValues = {
  [key in FormFields]: string;
};

const schema = Yup.object().shape({
  [FormFields.TEAM]: Yup.string().trim().required('Team name is required'),
});

const initialValues = {
  [FormFields.TEAM]: '',
} as FormValues;

type Props = {
  onNext: () => void;
  onPrev: () => void;
};

export const Team: React.FC<Props> = ({ onNext, onPrev }) => {
  const [isPending, setIsPending] = useState<boolean>(false);
  const dispatch = useDispatch();
  const onSubmit = (values: FormValues) => {
    setIsPending(true);
    dispatch(
      onBoardingSignUpEffect({ ...values }, {}, (err) => {
        setIsPending(false);
        if (!err) {
          onNext();
        }
      }),
    );
  };
  useEffect(() => {
    dispatch(
      getAgentTeamDetailEffect({}, {}, (err, resp) => {
        if (!err) {
          formik.setFieldValue(FormFields.TEAM, resp?.data?.TeamName || '');
        }
      }),
    );
  }, []);
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit,
  });
  return (
    <>
      <FormContainer>
        <div className={classNames(parentStyles.formContainer, styles.formContainer)}>
          <FormHeader title="What is your team name?" />
          <form className={parentStyles.form} onSubmit={formik.handleSubmit}>
            <div className={parentStyles.inputContainer}>
              <Input
                type="text"
                name={FormFields.TEAM}
                value={formik.values[FormFields.TEAM]}
                onChange={formik.handleChange}
                className={parentStyles.input}
                error={formik.touched[FormFields.TEAM] ? formik.errors[FormFields.TEAM] : ''}
                errorClassName={parentStyles.error}
                disabled={isPending}
                variant={Input.LIGHT_ROUND}
              />
            </div>

            <FormFooter isDisabled={!formik.dirty} />
          </form>
        </div>
      </FormContainer>
    </>
  );
};
