import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { PENDING } from 'settings/constants/apiState';
import { THIRD_PARTY } from 'settings/constants/roles';
import { onBoardingSignUpEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getUserDataSelector, isPartnerSelector } from 'store/selectors/user';

import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from '../../agent/components/OnboardingContinueButton';
import { ValidationSchema } from './validation';

import classNames from 'classnames';
import { Input, Locations } from 'components';
import { Col, Row } from 'components-antd';
import React, { useCallback, useEffect } from 'react';
import { getLocationContext, getPlaceComponent } from 'helpers';
import { LOCALITY, POSTAL_CODE, ROUTE, STATE, STREET_NUMBER } from 'settings/constants/locations';

import styles from './styles.module.scss';

const initialValues = {
  address: null,
  suiteUnit: '',
};

type Props = {
  onNext: () => {};
  stageIndex?: number;
  Controls: React.ElementType;
};

export const BusinessAddressForm: React.FC<Props> = ({ stageIndex, onNext, Controls }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const user = useSelector(getUserDataSelector);
  const isPartner = useSelector(isPartnerSelector);

  const getSchema = () => {
    return ValidationSchema;
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      address: (user?.[THIRD_PARTY]?.Partner?.Address as any) || initialValues.address,
      suiteUnit: (user?.[THIRD_PARTY]?.Partner?.SuiteUnit as string) || initialValues.suiteUnit,
    },
    validationSchema: getSchema(),
    onSubmit(values) {
      dispatch(
        onBoardingSignUpEffect(
          { ...values, stageIndex },
          { isPartner, isCommonInfo: true },
          (err) => {
            if (!err) {
              onNext();
            }
          },
        ),
      );
    },
  });

  const onAddressChange = useCallback(
    (result) => {
      const location = result?.result?.[0];
      const coordinates = result?.coordinates;

      if (location) {
        const updatedCity = getLocationContext(location, LOCALITY);
        const updatedState = getLocationContext(location, STATE);
        const updatedZip = getLocationContext(location, POSTAL_CODE);
        const street = getPlaceComponent(location, ROUTE);
        const streetNumber = getLocationContext(location, STREET_NUMBER);
        const geolocation = coordinates
          ? {
              Lat: coordinates.lat,
              Long: coordinates.lng,
            }
          : null;

        formik.setFieldValue('address', {
          City: updatedCity,
          State: updatedState,
          Zip: updatedZip,
          Line1: [streetNumber, street?.name].filter((i) => !!i).join(' '),
          ProviderPlaceId: location.place_id,
          PlaceName: result?.placeName,
          Geolocation: geolocation,
        });
      } else {
        formik.setFieldValue('address', null);
      }
    },
    [formik],
  );

  const isPending = onBoarding.state === PENDING;

  const getTitle = () => {
    return 'Please confirm your business address.';
  };

  return (
    <OnBoardingWrapper isPending={isPending} className={styles.wrapper}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question className={styles.partnerTitle}>{getTitle()}</Question>
        <AnswersContainer>
          <form onSubmit={formik.handleSubmit}>
            <Row className={classNames(styles.row, styles.partnerRow)} gutter={[16, 20]}>
              <Col xs={24} md={24}>
                <Locations
                  multiple={false}
                  name="address"
                  types={['address']}
                  onResult={onAddressChange}
                  error={
                    (formik.touched.address as unknown as any)
                      ? (formik.errors.address as unknown as any)
                      : ''
                  }
                  disabled={isPending}
                  value={
                    formik.values.address
                      ? [
                          {
                            ...formik.values.address,
                            ProviderPlaceId: 1,
                            PlaceName: formik?.values?.address?.Line1,
                          },
                        ]
                      : []
                  }
                  testid="address"
                  variant={Locations.LIGHT}
                  searchIcon={false}
                  className={styles.searchWrapper}
                  valuesWrapperClassName={styles.valuesWrapper}
                  labelClassName={styles.searchWrapper}
                  blockClassName={styles.partnerLocationInput}
                  activeInputClassName={styles.activeInputClassName}
                  label={'Office Address'}
                  placeholder={''}
                  valueClassName={styles.valueClassName}
                  customLocationFlag={true}
                />
              </Col>
              <Col xs={24} md={24}>
                <Input
                  name="suiteUnit"
                  value={formik.values.suiteUnit}
                  onChange={formik.handleChange}
                  error={formik.touched.suiteUnit ? formik.errors.suiteUnit : ''}
                  disabled={isPending}
                  testid="suite_unit"
                  variant={Input.LIGHT}
                  className={classNames(
                    styles.partnerFormInput,
                    styles.suiteUnit,
                    styles.searchWrapper,
                  )}
                  label={'Suite / Unit'}
                />
              </Col>
            </Row>
            <ButtonsContainer>
              <OnboardingContinueButton
                testid="done"
                title="Continue"
                className={styles.submit}
                isPending={isPending}
                disabled={!formik.isValid}
                onClick={formik.submitForm}
              />
            </ButtonsContainer>
          </form>
        </AnswersContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};
