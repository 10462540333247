import { handleActions } from 'redux-actions';
import { setMlsAccessValue, setMlsAccessModalAction } from 'store/actions/mlsAccess';

const initialData = {
  hasMlsAccess: true,
  isModalOpened: false,
  showOnboardingText: false,
};

export default handleActions(
  {
    [setMlsAccessValue as any]: (state, { payload }) => {
      return {
        ...state,
        hasMlsAccess: payload as any,
      };
    },
    [setMlsAccessModalAction as any]: (state, { payload }) => {
      return {
        ...state,
        isModalOpened: payload.isModalOpened,
        showOnboardingText: payload.showOnboardingText,
      };
    },
  },
  initialData,
);
