import { useParams } from 'react-router-dom';

import { AllowedAnonEnum } from 'app-constants';
import { DynamicFormManager } from '../DynamicFormManager';

export const AnonDynamicForm = () => {
  const { token, type } = useParams<{ token: string; type: AllowedAnonEnum }>();

  return token && type ? (
    <DynamicFormManager
      anonData={{
        token,
        type,
      }}
    />
  ) : (
    <></>
  );
};
