import Api from 'store/effects/core/api';
import {
  requestGetAllTeamsAction,
  requestGetTeamListAction,
  requestGetTeamPermittedListAction,
  requestGetTeamTitlesAction,
  requestPostAssignClientsToMemberAction,
  requestPostConfigureAdminAccessAction,
} from 'store/actions/teamList';
import {
  configureAdminAccess,
  assignClientsToMember,
  getTeamList,
  getTeamPermittedList,
  getTeamTitles,
  getAllTeams,
  getTeamTitlesByTeamId,
} from 'api/teamList';
import { appSetConnectionTypeEffect } from 'store/effects';
import { postInviteTeamMember } from 'api/teamList';
import { appSetShowAssignConnectionAction, requestPostSendInvite } from 'store/actions/app';
import { getAgentDetailsEffect } from '../agentDetail';
import { TEAM_ADMIN, AGENT, TEAM_OWNER } from 'settings/constants/roles';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';

export const requestGetTeamListEffect = (cfg, options = {}, cb) => {
  const requestParams = { action: requestGetTeamListAction, method: getTeamList };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetTeamPermittedListEffect = (cfg, options = {}, cb) => {
  const requestParams = { action: requestGetTeamPermittedListAction, method: getTeamPermittedList };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, {}, cb);
};

export const handleSendTeamMemberInviteForm = (cfg, cb) => {
  const sendRequest = Api.execBase({ action: requestPostSendInvite, method: postInviteTeamMember });

  return sendRequest(cfg, {}, (err, resp, dispatch) => {
    if (!err) {
      dispatch(getAgentDetailsEffect({ id: resp.data.Id }));
      if (resp?.data?.Role === TEAM_ADMIN)
        dispatch(appSetConnectionTypeEffect({ type: AGENT_CONNECTIONS.Team }));
      dispatch(
        appSetShowAssignConnectionAction({
          ...(resp.data.Role === TEAM_ADMIN
            ? {
                open: true,
                assignType: AGENT,
              }
            : { open: false }),
          newInvite: true,
        }),
      );
      dispatch(requestGetTeamListEffect());
    }
    cb?.(err, resp, dispatch);
  });
};

export const getTeamTitlesEffect = (cfg, options, cb) => {
  const requestParams = { action: requestGetTeamTitlesAction, method: getTeamTitles };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const getTeamTitlesByTeamIdEffect = (cfg, options, cb) => {
  const { teamId } = cfg;
  const requestParams = { action: requestGetTeamTitlesAction, method: getTeamTitlesByTeamId };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(teamId, options, cb);
};

export const postConfigureAdminAccessEffect = (cfg, options, cb) => {
  const requestParams = {
    action: requestPostConfigureAdminAccessAction,
    method: configureAdminAccess,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const postAssignClientsToMemberEffect = (cfg, options, cb) => {
  const requestParams = {
    action: requestPostAssignClientsToMemberAction,
    method: assignClientsToMember,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const getAllTeamsEffect = (cfg, options, cb) => {
  const requestParams = { action: requestGetAllTeamsAction, method: getAllTeams };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};
