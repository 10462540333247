import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { SignatureResponseType } from 'pages/FormBuilder/components';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { FormDocumentQuestionsType } from 'types';

export const getSignatureConfig = ({
  bundleDocumentsDetails,
  activeQuestions = [] as FormDocumentQuestionsType,
  responses,
  showWizard = false,
}) => {
  const isSignatureCohesiveFlow =
    bundleDocumentsDetails.length > 1 && bundleDocumentsDetails[0].activeQuestions;

  let questions: any[] = activeQuestions;
  let answers = responses;
  let wizard = showWizard;

  if (isSignatureCohesiveFlow) {
    questions = [];
    answers = {};
    wizard = false;

    bundleDocumentsDetails.forEach((item) => {
      questions = [...questions, ...item.activeQuestions];
      answers = { ...answers, ...item.answers };
      wizard = wizard || item.showWizard;
    });
  }

  return { questions, answers, wizard };
};

export const getCohesiveFieldAnswers = (bundleDocumentsDetails) => {
  let answers = {};

  bundleDocumentsDetails.forEach((item) => {
    answers = { ...answers, ...(item.answers || {}) };
  });

  return answers;
};

export const getCohesiveFieldQuestions = (bundleDocumentsDetails) => {
  let questions: any[] = [];

  bundleDocumentsDetails.forEach((item) => {
    questions = [...questions, ...(item.activeQuestions || [])];
  });

  return questions;
};

const getFieldType = (questions, uuid) => {
  return questions.find(({ UUID }) => uuid === UUID).FieldType;
};

export const getSignatureFieldsUUID = (questions) => {
  const signatureTypeUUID = questions
    .filter(({ FieldType }) =>
      [DYNAMIC_QUESTION_TYPE.Initials, DYNAMIC_QUESTION_TYPE.Signature].includes(FieldType),
    )
    .map(({ UUID }) => UUID);

  return signatureTypeUUID;
};

const prepareAnswers = (answers, updatedSignature, questions, questionUUID) => {
  let signatureUUIDs = getSignatureFieldsUUID(questions);
  signatureUUIDs = signatureUUIDs.filter((uuid) => uuid !== questionUUID);

  const allAnswers = { ...answers };

  signatureUUIDs.forEach((UUID) => {
    if (allAnswers[UUID]) {
      const { type: answerType, signature: answerSignature } = JSON.parse(allAnswers[UUID].Answer);
      const { type, signModalTab = '', ...rest } = JSON.parse(updatedSignature);

      const fieldType = getFieldType(questions, UUID);

      const isInitial = fieldType === DYNAMIC_QUESTION_TYPE.Initials;
      const isSignature = fieldType === DYNAMIC_QUESTION_TYPE.Signature;

      const signature = {
        ...rest,
        ...(isInitial && type === SignatureResponseType.Image
          ? { type: SignatureResponseType.Text }
          : isSignature && signModalTab === 'draw'
          ? { type: answerType, signature: answerSignature }
          : { type }),
      };

      allAnswers[UUID] = { ...allAnswers[UUID], Answer: JSON.stringify(signature) };
    }
  });

  return allAnswers;
};

export const getSignatureAnswers = (answers, data, questionUUID, isCohesiveFlow?) => {
  let answerDetails;

  if (isCohesiveFlow) {
    const documentDetails = dynamicManager.getBundleDocumentsDetails();

    documentDetails.forEach((item) => {
      item.answers = prepareAnswers(item.answers, data, item.activeQuestions, questionUUID);
    });

    answerDetails = documentDetails;
  } else {
    const questions = dynamicManager.getQuestions();
    answerDetails = prepareAnswers(answers, data, questions, questionUUID);
  }

  return { answerDetails };
};

export const modifySignatureUUID = (UUID) => {
  if (!UUID) return '';

  const plainText = UUID.replaceAll('-', '').toUpperCase();
  return `${plainText.substring(0, 14)}...`;
};

export const getDateFontSize = (width) => {
  if (width <= 70) return '3px';
  else if (width <= 100) return '9px';
  else if (width <= 130) return '12px';

  return '14px';
};
