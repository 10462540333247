import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ template: { transaction } }) => transaction;

export const getTransactionTemplateSelector = createSelector(
  localState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTransactionTemplateDataSelector = createSelector(localState, (state) => ({
  id: get(state, 'data.Id'),
  title: get(state, 'data.Name') || '',
  description: get(state, 'data.Description') || '',
  propertyType: get(state, 'data.PropertyType') || '',
  financing: get(state, 'data.Financing') || '',
  timelineTemplate: {
    id: get(state, 'data.TimelineTemplate.Id'),
    title: get(state, 'data.TimelineTemplate.Name') || '',
    description: get(state, 'data.TimelineTemplate.Description') || '',
    areasOfOperation: get(state, 'data.TimelineTemplate.AreasOfOperation') || '',
    milestones: (get(state, 'data.TimelineTemplate.Milestones') || []).map(
      ({ Title, Offset, Initial, Operator, ControlOperatorOffset, IsPrivate }) => ({
        Title,
        DueDate: Offset,
        Initial,
        Operator,
        ControlOperatorOffset,
        IsPrivate,
      }),
    ),
  },
  formTemplates: get(state, 'data.TransactionFormTemplate') || [],
  taskTemplates: get(state, 'data.TaskTemplates', []).map((task) => ({
    id: get(task, 'Id'),
    title: get(task, 'Name') || '',
    description: get(task, 'Description'),
    category: get(task, 'Category')
      ? {
          name: get(task, 'Category.Name'),
          value: get(task, 'Category.Id'),
        }
      : null,
    assigneeRoles: get(task, 'AssigneeRoles'),
    ccRoles: get(task, 'CcRoles'),
    documents: get(task, 'Documents'),
    dueDate: get(task, 'DueDate'),
    initial: get(task, 'Initial'),
    operator: get(task, 'Operator'),
    links: get(task, 'Links'),
  })),
}));
