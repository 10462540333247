import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Wrapper as PendingWrapper } from 'components';
import styles from './styles.module.scss';

const UploadFile = (props) => {
  const {
    id,
    className,
    onChange,
    value,
    label,
    isPending,
    icon,
    innerClassName,
    pendingPlaceholder,
    onCloseModal,
    accept,
  } = props;
  const { multiple } = props;
  const fileInputRef = useRef();

  useLayoutEffect(() => {
    if (!value?.length && fileInputRef?.current) {
      fileInputRef.current.value = null;
      fileInputRef.current.click();
      onCloseModal();
    }
  }, [value?.length]);
  useLayoutEffect(() => {
    fileInputRef.current.addEventListener('change', (e) => onChange(e));
  });
  return (
    <div className={classNames(className)}>
      <label
        testid="upload"
        className={classNames(styles.fileInput, styles.upload, innerClassName)}
        htmlFor={id}
      >
        {isPending && pendingPlaceholder ? (
          pendingPlaceholder
        ) : (
          <PendingWrapper
            isPending={isPending}
            className={styles.pendingWrapper}
            loaderClassName={styles.loader}
          >
            <>
              {icon}
              <div>{label}</div>
              <input
                ref={fileInputRef}
                multiple={multiple}
                type="file"
                onChange={onChange}
                className={styles.nativeFileInput}
                id={id}
                testid="attachment_input"
                accept={accept}
              />
            </>
          </PendingWrapper>
        )}
      </label>
    </div>
  );
};

UploadFile.propTypes = {
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  isPending: PropTypes.bool,
  icon: PropTypes.node,
  pendingPlaceholder: PropTypes.node,
  multiple: PropTypes.bool,
};

UploadFile.defaultProps = {
  className: '',
  innerClassName: '',
  onChange: () => {},
  value: [],
  label: 'UploadFile',
  isPending: false,
  icon: undefined,
  pendingPlaceholder: undefined,
  multiple: true,
  onCloseModal: () => {},
};

export default UploadFile;
