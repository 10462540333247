import classNames from 'classnames';
import { useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import { SmartFormIcons } from '../SmartFormIcons';
import {
  AddFormCommentType,
  CommentUsersType,
  EditFormCommentType,
  FormCommentType,
  FormDocumentAnswersType,
  FormDocumentQuestionsType,
  FormRolesType,
  FormRoleType,
  FieldInfoType,
  StrikeThrough,
  FormCommentThread,
} from 'types';
import { PDFViewer } from 'pages/FormBuilder/components/PDFViewer';
import { Button, Col, ConfirmationWithReasonModal, Popover, Row } from 'components-antd';
import { FORM_QUESTION_TYPE, PDF_FIELD_TYPE } from 'app-constants';
import { SignatureResult } from 'pages/FormBuilder/components';
import { FormPill } from 'pages/FormProcess/components';

import styles from './styles.module.scss';

interface SignFormProps {
  questions?: FormDocumentQuestionsType;
  responses?: FormDocumentAnswersType;
  handleDone: () => void;
  handleResponse: (data) => void;
  link?: string;
  pdfName?: string;
  handleExit?: () => void;
  clientName?: string;
  address?: string;
  declined?: boolean;
  declineLoading?: boolean;
  setDeclineMessage?: (message: string) => void;
  handleDecline: () => void;
  showRolesColumn?: boolean;
  allRoles?: FormRolesType;
  isMock?: boolean;
  handleUnlock?: () => void;
  handleSaveSignature?: (signature: SignatureResult) => void;
  handleEdit?: () => void;
  isCreatorEdit?: boolean;
  commentUsers?: CommentUsersType;
  formComment?: FormCommentType;
  setFieldInfo?: (fieldInfo: FieldInfoType) => void;
  handleGetFormComments?: () => void;
  handleAddFormComment?: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment?: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment?: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
  setCommentPanelOpen?: Dispatch<SetStateAction<boolean>> | null;
  strikeThrough?: StrikeThrough;
  formCommentThreads?: FormCommentThread[];
}

export const SignForm = ({
  questions,
  responses,
  handleDone,
  link,
  handleExit,
  pdfName,
  handleResponse,
  clientName,
  address,
  showRolesColumn,
  allRoles,
  declined,
  declineLoading,
  setDeclineMessage,
  handleDecline,
  isMock,
  handleUnlock,
  handleSaveSignature,
  handleEdit,
  isCreatorEdit,
  commentUsers,
  formComment,
  setFieldInfo,
  handleGetFormComments,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
  setCommentPanelOpen,
  strikeThrough = {},
  formCommentThreads,
}: SignFormProps) => {
  const [disabledContinue, setDisabledContinue] = useState(true);
  const [openDecline, setDecline] = useState(false);
  const [signRoles, setSignRoles] = useState(allRoles || []);

  const ref = useRef<HTMLDivElement>(null);

  const updateRole = (role: FormRoleType) => {
    if (signRoles.some((_role) => _role.Id === role.Id)) {
      setSignRoles(signRoles.filter((el) => el.Id !== role.Id));
    } else {
      setSignRoles([...signRoles, role]);
    }
  };

  const EditRow = () => {
    return (
      <Row justify="center" className={styles.editRow}>
        {handleEdit ? (
          <Col>
            <div
              className={classNames(styles.editForm, styles.navigationButton)}
              onClick={handleEdit}
            >
              <SmartFormIcons variant="pencil" />
              Edit
            </div>
          </Col>
        ) : (
          <></>
        )}
        {showRolesColumn && allRoles?.length ? (
          <Col>
            <div className={styles.editRoles}>
              {allRoles.map((role) => (
                <div
                  className={styles.userRow}
                  onClick={() => updateRole(role)}
                  key={`role-${role.Id}`}
                >
                  <Checkbox
                    className={classNames(styles.checkbox, {
                      [styles.selectedCheckbox]: signRoles.some((_role) => _role.Id === role.Id),
                    })}
                    checked={signRoles?.some((_role) => _role.Id === role.Id)}
                  />
                  <span>
                    <span className={styles.userTitle}>{role.Name}</span>
                  </span>
                </div>
              ))}
            </div>
          </Col>
        ) : (
          <></>
        )}
        {handleUnlock ? (
          <Col>
            <div
              className={classNames(styles.editForm, styles.navigationButton)}
              onClick={handleUnlock}
            >
              <SmartFormIcons variant="lock" />
              Unlock
            </div>
          </Col>
        ) : (
          <></>
        )}
        <FormPill clientAddress={address} clientName={clientName} pdfName={pdfName} />

        <Col>
          <div className={styles.navigationIcons}>
            <div
              className={classNames(styles.closeNavigation, styles.navigationButton)}
              onClick={handleExit}
            >
              <SmartFormIcons variant="cancel" />
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const checkDisabled = () => {
    let check = false;
    const signQuestions = questions?.filter((q) => q.FieldType === PDF_FIELD_TYPE.PDFSignature);

    if (signQuestions?.length && responses) {
      if (Array.isArray(responses)) {
        if (!responses?.length) {
          check = true;
        } else {
          for (const question of signQuestions) {
            const response = responses[question.UUID];
            if (!response?.Answer) {
              check = true;
              break;
            }
          }
        }
      } else {
        signQuestions
          .filter((ques) => !ques.disabled)
          .forEach((ques) => {
            if (!responses?.[ques.UUID]?.Answer) {
              check = true;
            }
          });
      }
    }

    setDisabledContinue(check);
  };

  useEffect(() => {
    checkDisabled();
  }, [responses, questions]);

  const handleFormResponse = (uuid, result) => {
    if (responses?.[uuid]) {
      const response = responses[uuid];
      const question = questions?.find((ques) => ques.UUID === uuid);

      if (response && question) {
        handleResponse({
          ...response,
          Answer: JSON.stringify(result),
          FieldType: question?.FieldType,
          Fields: question?.Fields,
        });
        checkDisabled();
      }
    }
  };

  const getSignQuestions = () =>
    questions
      ? questions.map((q) => {
          if (
            q.Type !== FORM_QUESTION_TYPE.Signature && allRoles && signRoles
              ? signRoles.some((r) => q.FormRole.includes(r.Id))
              : true
          ) {
            return q;
          }
          return { ...q, disabled: true };
        })
      : [];

  return (
    <>
      <ConfirmationWithReasonModal
        confirmLoading={declineLoading}
        open={openDecline}
        confirmText={<span>Are you sure you want to decline signing?</span>}
        placeholder={`Write a reason for declining...`}
        okText="Decline"
        showReason={true}
        onOk={() => {
          handleDecline();
        }}
        onCancel={() => setDecline(false)}
        setReason={setDeclineMessage}
      />

      {declined ? (
        <div className={styles.statusBar}>
          <span>This document is declined</span>
        </div>
      ) : (
        <></>
      )}

      <div
        className={classNames(styles.previewFormContainer, {
          [styles.viewModePDFContainer]: declined,
        })}
        itemID="formContainer"
        ref={ref}
      >
        {!declined ? <EditRow /> : <></>}

        <div className={classNames(styles.pdfContainer)}>
          <PDFViewer
            Url={link}
            questions={getSignQuestions()}
            mode={declined ? 'View' : 'Sign'}
            responses={responses}
            onSignUpdate={handleFormResponse}
            isMock={isMock}
            handleSaveSignature={handleSaveSignature}
            commentUsers={commentUsers}
            formComment={formComment}
            setFieldInfo={setFieldInfo}
            handleGetFormComments={handleGetFormComments}
            handleAddFormComment={handleAddFormComment}
            handleEditFormComment={handleEditFormComment}
            handleDeleteFormComment={handleDeleteFormComment}
            handleToggleResolveThread={handleToggleResolveThread}
            strikeThrough={strikeThrough}
            containerRef={ref}
            setCommentPanelOpen={setCommentPanelOpen || undefined}
            formCommentThreads={formCommentThreads}
          />
        </div>
      </div>

      {!declined ? (
        <div className={styles.editFormFooter}>
          <Row className={styles.editFormFooterColumn}>
            {!isCreatorEdit ? (
              <Button
                variant="blank-hover-bordered"
                className={classNames(styles.editFormFooterMessage, styles.editFormFooterButton)}
                onClick={() => setDecline(true)}
              >
                <SmartFormIcons variant="cross" className={styles.svgMessage} /> Decline
              </Button>
            ) : (
              <></>
            )}

            <Button
              variant="secondary"
              className={classNames(styles.editFormFooterContinue, styles.editFormFooterButton, {
                [styles.disabledBtn]: disabledContinue,
              })}
              onClick={handleDone}
              disabled={disabledContinue}
            >
              {`I'm Done`}
            </Button>
          </Row>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
