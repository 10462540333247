import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SimpleModal } from 'components';
import { Close } from 'components/Icons';

import styles from './styles.module.scss';

const QuoteModal = (props) => {
  const { className, isOpen, onClose, children, title, headerClassName, testid, header } = props;
  const { contentClassName, innerHolderClassName } = props;

  if (!isOpen) return null;

  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={onClose}
      className={classNames(styles.favoritesModal, className)}
      contentClassName={classNames(styles.content, contentClassName)}
      opacityLayerClassName={styles.opacityLayer}
      innerHolderClassName={innerHolderClassName}
      testid={testid}
    >
      <div className={classNames(styles.header, headerClassName)}>
        <h2 testid="modal_title" className={styles.title}>
          {title}
        </h2>
        {header}
        <Close onClick={onClose} className={styles.close} />
      </div>
      {children}
    </SimpleModal>
  );
};

QuoteModal.propTypes = {
  className: PropTypes.string,
  innerHolderClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.node,
  children: PropTypes.node.isRequired,
  testid: PropTypes.string,
};

QuoteModal.defaultProps = {
  className: '',
  innerHolderClassName: '',
  contentClassName: '',
  headerClassName: '',
  title: '',
  isOpen: false,
  testid: undefined,
};

export default QuoteModal;
