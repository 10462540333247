import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep, uniqueId } from 'lodash-es';
import { Link } from 'react-router-dom';
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';

import Emoji from './Emoji';
import Attachment from './Attachment';
import { Menu, MenuItem, Dropdown, Tag, Row } from 'components-antd';
import EditActions from './EditActions';
import {
  getIsMessagesDrawerTypeSelector,
  getMessagesDrawerParams,
} from 'store/selectors/drawers/messages';
import Icon from 'pages/Properties/Feed/Icons';
import { link } from 'settings/navigation/link';
import { showErrorMessage } from 'helpers/errors';
import AttachmentsPreview from './AttachmentsPreview';
import { Footer } from 'pages/Properties/Feed/components';
import AutoCompleteSuggestion from './AutoCompleteSuggestion';
import { Avatar, Textarea, TextareaAutosize } from 'components';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { previewDocumentEffect } from 'store/effects/transactions';
import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { convertNameToAvatarPlaceholder, getImagesPreview } from 'helpers';
import { getParticipantsFormattedList } from 'store/selectors/drawers/addParticipants';
import { INSERTION_MARKER, Role } from 'app-constants';

import styles from './styles.module.scss';
import Icons from './Icons';
import { ArrowDown, ArrowUp, Check } from 'components/Icons';
import { getUserRolesSelector, getUserSelector } from 'store/selectors/user';
import { MessageAccessType } from 'app-constants/enums/message';
import { getAgentTeamDetailSelector } from 'store/selectors/agentTeamDetail';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getCommentsDrawerParamsSelector } from 'store/selectors/drawers/comments';

export const MessageDropdownFields = [
  {
    title: 'Everybody',
    key: MessageAccessType.Everybody,
    icon: <Icons variant={Icons.EVERYBODY} />,
    iconColor: '#04a451',
  },
  {
    title: 'Internal',
    key: MessageAccessType.Internal,
    icon: <Icons variant={Icons.INTERNAL} />,
    iconColor: '#04a451',
  },
  {
    title: 'Just Me',
    key: MessageAccessType.Me,
    icon: <Icons variant={Icons.ME} />,
    iconColor: '#04a451',
  },
];

const SendMessageFormView = (props) => {
  const dispatch = useDispatch();
  const { id, className, content, subheader, onSend, value } = props;
  const {
    footerClassName,
    footerInnerClassName,
    inputGroupClassName,
    attachments,
    emoji,
    autocomplete,
    inputPlaceholder,
    sendButtonClassName,
    shareProperty,
    placeholder,
    isTaskComments,
    documentVaultUUID,
    onDefaultAttachmentUpload,
    showDropdown,
    sideBarChatView,
    isModal,
    readOnly,
    placeholderClassName,
  } = props;
  const { avatar, avatarSrc, avatarPlaceHolder, avatarClassName } = props;
  const { isNewMessage, currentEditMessage, onCancelEdit } = props;

  const [inputValue, setInputValue] = useState(value);
  const [isMobile, setIsMobile] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [isAutoSuggestionTyping, setIsAutoSuggestionTyping] = useState(false);
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [mentionValue, setMentionValue] = useState('');
  const [attachmentsValue, setAttachmentsValue] = useState([]);
  const [dropdownValue, setDropdownValue] = useState('Internal');
  const [tagsValue, setTagsValue] = useState([]);
  const drawerType = useSelector(getIsMessagesDrawerTypeSelector);
  const activeClients = useSelector(getParticipantsFormattedList);
  const userRoles = useSelector(getUserRolesSelector);
  const inputRef = useRef();
  const attachmentRef = useRef();
  const sendButtonRef = useRef();
  const agentDetail = useSelector(getAgentTeamDetailSelector);
  const { selectedProperties } = useSelector(getCommentsDrawerParamsSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const getTagStyledText = (fullName) => {
    return `<span style="color: #2b59da" contentEditable="false" onclick="handleClick(event)">
        ${fullName}
      </span>`;
  };

  useEffect(() => {
    if (
      userRoles?.includes(Role.Client) ||
      agentDetail?.data.TeamId !== transaction?.CreatorTeamId
    ) {
      setDropdownValue('Everybody');
    }
  }, [userRoles]);

  const menu = useMemo(
    () => (
      <Menu className={styles.commentDropdown} onClick={(e) => setDropdownValue(e.key)}>
        <li className={styles.commentDropdownHeader}>Select who can see this comment.</li>
        {MessageDropdownFields.map(({ key, title, icon, ...props }) => (
          <MenuItem key={key} icon={icon} {...props} className={styles.menuItem}>
            <span className={styles.menuItemSpan}>
              {title} {dropdownValue === key && <Check color="#00B152" />}
            </span>
          </MenuItem>
        ))}
      </Menu>
    ),
    [dropdownValue],
  );

  const getEditText = () => {
    const tags = currentEditMessage?.MessageMeta?.Tags;
    const text = currentEditMessage?.Text;
    let updatedText = currentEditMessage?.Text;
    if (!tags?.length) {
      return text ?? '';
    }
    tags?.forEach(({ FirstName, LastName, Address }) => {
      const tagText = `@${FirstName} ${LastName}`;
      const addressTagText = `@${Address?.Line1}`;
      if (text.includes(tagText)) {
        updatedText = updatedText.replaceAll(tagText, getTagStyledText(tagText));
      } else if (text.includes(addressTagText)) {
        updatedText = updatedText.replaceAll(addressTagText, getTagStyledText(addressTagText));
      }
    });
    return updatedText;
  };
  useEffect(() => {
    setInputValue(getEditText());
    setAttachmentsValue(currentEditMessage?.Attachments);
    setTagsValue(currentEditMessage?.MessageMeta?.Tags || []);
  }, [currentEditMessage]); // eslint-disable-line

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    isTagTextEdited();
  });

  useEffect(() => {
    const resizer = () => {
      const newWidth = window.innerWidth;
      setIsMobile(newWidth < 768);
    };
    resizer();
    window.addEventListener('resize', resizer);
    return () => {
      window.removeEventListener('resize', resizer);
    };
  }, []);

  useEffect(() => {
    if (documentVaultUUID) {
      dispatch(
        previewDocumentEffect({ DocumentVaultUUID: documentVaultUUID }, {}, (err, response) => {
          if (onDefaultAttachmentUpload) {
            onDefaultAttachmentUpload();
          }
          if (err) {
            return;
          }
          const type = { type: response.headers['content-type'] };
          const files = [new File([response.data], response.headers['file-name'], type)];

          getImagesPreview(files, true).then((data) => {
            const preparedValues = data.map((val) => ({
              ContentType: val.contentType,
              Filename: val.fileName,
              Url: val.url,
            }));
            onAttach(files, preparedValues);
          });
        }),
      );
    }
  }, [documentVaultUUID]);

  const isRecipient = isNewMessage ? props.participantsCount > 0 : true;
  function removeHtmlTags(inputString) {
    var doc = new DOMParser().parseFromString(inputString, 'text/html');
    return doc.body.textContent || '';
  }
  const onSendHandler = () => {
    const simpleText = removeHtmlTags(inputValue);

    if (!simpleText && !attachmentsValue?.length) return null;

    if (!isRecipient) {
      return showErrorMessage('Please add a recipient');
    }
    const filteredTags = tagsValue?.filter(({ FirstName, LastName, Address }) => {
      const tagText = `@${FirstName} ${LastName}`;
      const addressTagText = `@${Address?.Line1}`;
      return simpleText.includes(tagText) || simpleText.includes(addressTagText);
    });
    const uniqueTags = Array.from(new Set(filteredTags.map((tag) => tag.Id))).map((id) => {
      return filteredTags.find((tag) => tag.Id === id);
    });
    onSend(
      simpleText,
      attachmentsValue,
      uniqueTags,
      currentEditMessage?.Id,
      null,
      showDropdown ? dropdownValue : shareProperty,
    );
    setInputValue('');
    setCursorPosition(0);
    setOpenEmoji(false);
    setAttachmentsValue([]);

    if (attachmentRef?.current) {
      attachmentRef.current.value = null;
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendButtonRef?.current?.click();
    }
  };

  const isPropertyCommentDrawerOpened = () => {
    return id === 'commentsDrawer' && shareProperty.length;
  };

  const getCursorPosition = () => {
    const target = document.createTextNode(INSERTION_MARKER);
    document.getSelection().getRangeAt(0).insertNode(target);
    const position = inputRef.current.innerHTML.indexOf(INSERTION_MARKER);
    target.parentNode.removeChild(target);
    setCursorPosition(position);
    return position ?? 0;
  };

  const onInputValue = (e, val) => {
    const atIndex = val.lastIndexOf('@');
    if (atIndex !== -1) {
      const afterAt = val.substring(atIndex + 1).replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags after '@'
      // expand this regular expression to add support for other characters after '@'
      const nonAlphaNumIndex = afterAt.search(/[^a-zA-Z0-9]/);

      if (afterAt === '') {
        // User entered '@' and it is at the end
        setOpenAutoComplete(true);
        setIsAutoSuggestionTyping(true);
        setMentionValue('');
        setInputValue(val.slice(0, atIndex + 1));
      } else if (nonAlphaNumIndex === -1) {
        // User entered '@' followed by some alphanumeric string
        setOpenAutoComplete(true);
        setIsAutoSuggestionTyping(true);
        setMentionValue(afterAt);
      } else {
        // '@' is followed by a non-alphanumeric character
        setInputValue(val);
        setOpenAutoComplete(false);
        setIsAutoSuggestionTyping(false);
        setMentionValue('');
      }
    } else {
      // Handle normal string input
      setInputValue(val);
      setOpenAutoComplete(false);
      setIsAutoSuggestionTyping(false);
    }
  };

  const isTagTextEdited = () => {
    tagsValue.forEach((tag) => {
      if (tag.Type === 'Property' && !inputValue?.includes(`@${tag.Address.Line1}`)) {
        setTagsValue(tagsValue.filter((t) => t.SearchableName !== tag.SearchableName));
      } else if (
        tag.Type === 'Contact' &&
        !inputValue?.includes(`@${tag.FirstName} ${tag.LastName}`)
      ) {
        setTagsValue(tagsValue.filter((t) => t.SearchableName !== tag.SearchableName));
      }
    });
  };

  const onEmojiClick = (event, emojiObject) => {
    const arrChars = (inputValue || '').split('');
    arrChars.splice(cursorPosition, 0, emojiObject?.emoji);
    setInputValue(arrChars.join(''));
    setOpenEmoji(false);
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  };

  const isLastCharTagSymbol = (val = inputValue) => {
    return val[cursorPosition - 1] === '@';
  };

  const onAutoCompleteClick = (entityObject) => {
    setOpenAutoComplete(false);

    let tag = '';
    const isLastCharacter = isLastCharTagSymbol();
    if (entityObject.Type === 'Property') {
      tag = getTagStyledText(`@${entityObject.Address.Line1}`);
    } else if (entityObject.Type === 'Contact') {
      tag = getTagStyledText(`@${entityObject.FirstName} ${entityObject.LastName}`);
    }
    setInputValue((input) => {
      const inputValue = input.slice(0, cursorPosition - 1) + tag;
      return inputValue;
    });
    setTagsValue([...(tagsValue || []), ...([entityObject] || [])]);

    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  };

  const onAttach = (files, values) => {
    setAttachmentsValue([...(attachmentsValue || []), ...(values || [])]);
  };

  const onRemoveAttachment = (index) => {
    const copyAttachments = cloneDeep(attachmentsValue);
    copyAttachments.splice(index, 1);
    setAttachmentsValue(copyAttachments);
  };

  const onSubjectChangeHandler = (e) => {
    if (e.target.value) {
      props.subjectSetter(e.target.value);
    }
  };

  useEffect(() => {
    inputRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [inputRef]);

  return (
    <div
      testid="send_message_form"
      id="send_message_form"
      className={classNames(styles.messagesForm, className)}
    >
      {subheader || <div />}
      <div className={styles.content} data-id={id}>
        {content}
      </div>
      {isPropertyCommentDrawerOpened() && (
        <div className={styles.propertyCommentWrapper}>
          <p>Property Comment:</p>
          {shareProperty.map((property) => (
            <Link
              onClick={() => dispatch(openCommentsDrawerEffect({ open: false, params: {} }))}
              className={styles.propertyCommentLink}
              to={link.toFeedListingDetails(property?.Id)}
            >
              {property?.Address?.Line1 || 'Private Address'}
            </Link>
          ))}
        </div>
      )}
      {!readOnly && (
        <Footer
          testid="message_footer"
          className={classNames(styles.footer, 'customFooter', footerClassName)}
        >
          {avatar && (
            <div className={styles.smfAvatar}>
              <Avatar
                src={avatarSrc && avatarSrc}
                placeholder={avatarPlaceHolder}
                className={classNames(styles.avatarWrapper, {
                  [styles.editMode]: !!currentEditMessage,
                })}
                avatarClassName={classNames(styles.avatar, avatarClassName)}
              />
            </div>
          )}
          <div
            className={classNames(styles.footerContent, {
              [styles.footerContentWithoutAvatar]: !avatar,
            })}
          >
            <div className={classNames(styles.inputGroup, inputGroupClassName)}>
              {drawerType === DRAWER_MESSAGES_TYPES.NEW_MESSAGE && props.participantsCount > 1 ? (
                <div className={styles.subjectInput}>
                  <input
                    type={'text'}
                    placeholder={'Add Subject'}
                    maxLength="30"
                    className={styles.subjectInputText}
                    onChange={(e) => onSubjectChangeHandler(e)}
                  />
                </div>
              ) : (
                <></>
              )}
              <div
                className={classNames([
                  styles.inputFieldWrapper,
                  sideBarChatView ? styles.chatWrapper : '',
                ])}
              >
                <TextareaAutosize
                  ref={inputRef}
                  className={classNames(
                    styles.sendInputWrapper,
                    isModal
                      ? styles.chatModalText
                      : sideBarChatView
                      ? styles.chatSideBarText
                      : styles.chatText,
                  )}
                  cursorPosition={cursorPosition}
                  inputFieldClassName={styles.inputField}
                  variant={Textarea.LIGHT_FULL}
                  placeholder={inputPlaceholder}
                  testid="message_input"
                  onKeyDown={onKeyDown}
                  onChange={onInputValue}
                  value={inputValue}
                  setInputValue={setInputValue}
                  maxRows={10}
                  sideBarChatView={sideBarChatView}
                  placeholderClassName={placeholderClassName}
                />

                <AttachmentsPreview
                  attachments={attachmentsValue}
                  setAttachments={setAttachmentsValue}
                  onRemove={onRemoveAttachment}
                />
              </div>
              {/* bottom icons  */}
              <div className={classNames(styles.footerInner, footerInnerClassName)}>
                <div className={styles.actions}>
                  {attachments && (
                    <Attachment id={id} value={attachmentsValue} onAttach={onAttach} />
                  )}
                  {((emoji && sideBarChatView) || (!isMobile && emoji)) && (
                    <Emoji open={openEmoji} setOpen={setOpenEmoji} onEmojiClick={onEmojiClick} />
                  )}
                  {autocomplete && (
                    <AutoCompleteSuggestion
                      open={openAutoComplete}
                      setOpen={setOpenAutoComplete}
                      onAutoCompleteClick={onAutoCompleteClick}
                      placeholder={placeholder}
                      isTaskComments={isTaskComments}
                      isAutoSuggestionTyping={isAutoSuggestionTyping}
                      setIsAutoSuggestion={setIsAutoSuggestionTyping}
                      inputValue={mentionValue}
                      notePrivacy={dropdownValue}
                    />
                  )}

                  {showDropdown &&
                    userRoles.includes(Role.Agent) &&
                    agentDetail?.data?.TeamId === transaction?.CreatorTeamId && (
                      <>
                        <div className={styles.comentDivider}>|</div>
                        <Dropdown
                          overlay={menu}
                          placement="topCenter"
                          overlayClassName={styles.dropdownMenu}
                        >
                          <a
                            onClick={(e) => e.preventDefault()}
                            className={classNames(styles.filterButton)}
                          >
                            <p>
                              {
                                MessageDropdownFields.find(({ key }) => key === dropdownValue)
                                  ?.title
                              }
                            </p>
                            <ArrowDown
                              className={classNames(styles.icon, { [styles.open]: open })}
                            />
                          </a>
                        </Dropdown>
                      </>
                    )}
                </div>
                {!currentEditMessage && (
                  <div onClick={onSendHandler} ref={sendButtonRef}>
                    <Icon
                      testid="send_icon"
                      className={classNames(
                        styles.sendButton,
                        {
                          [styles.enabledSendButton]:
                            (attachmentsValue?.length || inputValue) && isRecipient,
                        },
                        sendButtonClassName,
                      )}
                      variant={Icon.SEND}
                    />
                  </div>
                )}
              </div>
            </div>

            <EditActions
              isScreenMobile={isMobile}
              onSave={onSendHandler}
              show={!!currentEditMessage}
              disabledSave={!inputValue && !attachmentsValue?.length}
              onCancelEdit={onCancelEdit}
            />
          </div>
        </Footer>
      )}
    </div>
  );
};

SendMessageFormView.propTypes = {
  isNewMessage: PropTypes.bool,
  className: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  footerInnerClassName: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.shape({})]),
  subheader: PropTypes.oneOfType([PropTypes.shape({})]),
  onSend: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  attachments: PropTypes.bool,
  emoji: PropTypes.bool,
  autocomplete: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
  sendButtonClassName: PropTypes.string,
  onCancelEdit: PropTypes.func,
  participantsCount: PropTypes.number,
  currentEditMessage: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    Text: PropTypes.string,
    Attachments: PropTypes.arrayOf(PropTypes.shape({})),
    MessageMeta: PropTypes.shape({}),
  }),
  shareProperty: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      PhotoUrls: PropTypes.arrayOf(PropTypes.string),
      SellingPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      Address: PropTypes.shape({
        Line1: PropTypes.string,
      }),
    }),
  ),
  placeholder: PropTypes.string,
  messageFieldWrapper: PropTypes.string,
  isTaskComments: PropTypes.bool,
  documentVaultUUID: PropTypes.string,
  onDefaultAttachmentUpload: PropTypes.func,
  subjectSetter: PropTypes.func.isRequired,
  avatar: PropTypes.bool,
  showDropdown: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholderClassName: PropTypes.string,
};

SendMessageFormView.defaultProps = {
  messageFieldWrapper: '',
  isNewMessage: false,
  className: '',
  sendButtonClassName: '',
  inputGroupClassName: '',
  footerClassName: '',
  footerInnerClassName: '',
  content: undefined,
  subheader: undefined,
  value: '',
  autoFocus: true,
  attachments: true,
  emoji: true,
  autocomplete: true,
  inputPlaceholder: 'Start typing...',
  onCancelEdit: () => {},
  currentEditMessage: null,
  shareProperty: [],
  placeholder: 'Type to search users or transactions',
  isTaskComments: false,
  participantsCount: 0,
  avatar: false,
  showDropdown: false,
  readOnly: false,
  placeholderClassName: '',
};

export default SendMessageFormView;
