import { AntList, AntListItem } from 'components-antd';
import { Content, Layout, Button } from 'components-antd';
import { SearchFieldWithDebounce } from 'components';

import { NewArea } from './components/NewArea';
import { DrawingMap } from './components/DrawingMap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAreaGroupsSearchTermSelector,
  getAreaGroupsSelector,
  getEditableAreaGroupSelector,
  getIsEditModeSelector,
  isNewAreaGroupEditableSelector,
} from 'store/selectors/adminPanel/areaClassifier';
import {
  addNewAreaGroupAction,
  setEditModeForSelectedAreaGroupAction,
} from 'store/actions/adminPanel';
import { useEffect } from 'react';
import {
  deleteAreaGroupEffect,
  getAreaGroupDetailsEffect,
  getAreaGroupsEffect,
  getAreaGroupsWithSearchTermEffect,
} from 'store/effects/adminPanel/areaClassifier';
import { AreaGroupPreview } from './components/AreaGroupPreview';
import { EditExistingAreaGroup } from './components/EditExistingAreaGroup';
import Sider from 'antd/lib/layout/Sider';

import './styles.scss';

export const AreaClassifier = () => {
  const areaGroups = useSelector(getAreaGroupsSelector);
  const searchTerm = useSelector(getAreaGroupsSearchTermSelector);
  const editableAreaGroup = useSelector(getEditableAreaGroupSelector);
  const isEditMode = useSelector(getIsEditModeSelector);

  const dispatch = useDispatch();
  const isNewAreaGroupEditable = useSelector(isNewAreaGroupEditableSelector);

  const onAddNewAreaGroupClick = () => {
    dispatch(addNewAreaGroupAction());
  };

  useEffect(() => {
    dispatch(getAreaGroupsEffect());
  }, []);

  return (
    <div>
      <Layout>
        <Content style={{ position: 'relative' }}>
          <DrawingMap id="#drawingMap" />
        </Content>
        <Sider className="sidebar" width={300}>
          <div className="sidebarContent">
            <SearchFieldWithDebounce
              // className={styles.searchField}
              // classNameInput={styles.searchInput}
              placeholder="Search"
              sendAction={getAreaGroupsWithSearchTermEffect}
              value={searchTerm}
              resetAction={getAreaGroupsEffect}
              resetByUnmount={false}
            />

            <div className="newAreaGroupSectionWrapper">
              {isNewAreaGroupEditable ? (
                <>
                  <NewArea />
                </>
              ) : (
                <div className="newAreaGroupButtonWrapper">
                  <Button onClick={onAddNewAreaGroupClick} variant="primary">
                    Add new area group
                  </Button>
                </div>
              )}
            </div>
            <AntList
              className="areaGroupsList"
              grid={{
                column: 1,
              }}
              dataSource={areaGroups}
              renderItem={(item) => (
                <AntListItem key={item.Id}>
                  {editableAreaGroup && editableAreaGroup.id === item.Id ? (
                    isEditMode ? (
                      <EditExistingAreaGroup areaGroupId={item.Id} />
                    ) : (
                      <AreaGroupPreview
                        areaGroup={editableAreaGroup}
                        onEyeClick={() => {
                          dispatch(getAreaGroupDetailsEffect({ areaGroupId: item.Id }));
                        }}
                        onEditClick={() => {
                          dispatch(getAreaGroupDetailsEffect({ areaGroupId: item.Id }));
                          dispatch(setEditModeForSelectedAreaGroupAction(true));
                        }}
                        onDeleteClick={() => {
                          dispatch(deleteAreaGroupEffect({ areaGroupId: item.Id }));
                        }}
                      />
                    )
                  ) : (
                    <AreaGroupPreview
                      areaGroup={{
                        id: item.Id,
                        name: item.Name,
                        areas: item.Areas.map((area) => ({
                          type: area.Type,
                        })),
                      }}
                      onEyeClick={() => {
                        dispatch(getAreaGroupDetailsEffect({ areaGroupId: item.Id }));
                      }}
                      onEditClick={() => {
                        dispatch(getAreaGroupDetailsEffect({ areaGroupId: item.Id }));
                        dispatch(setEditModeForSelectedAreaGroupAction(true));
                      }}
                      onDeleteClick={() => {
                        dispatch(deleteAreaGroupEffect({ areaGroupId: item.Id }));
                      }}
                    />
                  )}
                </AntListItem>
              )}
            />
          </div>
        </Sider>
      </Layout>
    </div>
  );
};
