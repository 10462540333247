import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LocationInfoWindow from '../LocationInfoWindow';
import NumberFormat from 'react-number-format';
import { Marker, InfoWindow } from '@react-google-maps/api';
import MarkerImage from 'components/Map/images/marker.svg';
import HoverMarkerImage from 'components/Map/images/marker-hover.svg';
import MarkerActive from 'components/Map/images/marker-active.svg';
import MarkerSeen from 'components/Map/images/marker-viewed.svg';
import MarkerSelected from 'components/Map/images/marker-selected.svg';
import RestaurantsMarker from 'components/Map/images/restaurants-marker.svg';
import ParksMarker from 'components/Map/images/parks-marker.svg';
import NightlifeMarker from 'components/Map/images/nightlife-marker.svg';
import CoffeeMarker from 'components/Map/images/coffee-marker.svg';
import GroceriesMarker from 'components/Map/images/groceries-marker.svg';
import BeautyMarker from 'components/Map/images/beauty-marker.svg';
import HealthMarker from 'components/Map/images/health-marker.svg';
import BarMarker from 'components/Map/images/bar-marker.svg';
import FitnessMarker from 'components/Map/images/fitness-marker.svg';
import Image from '../Image';
import styles from './styles.module.scss';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { propertySeenOnMap, setPropertyToScrollInList } from 'store/actions/feed';
import {
  getAllSeenOnMapSelector,
  getComparesSelector,
  getFeedViewTypeSelector,
} from 'store/selectors/feed';
import { getUserSelector, getUserRolesMapSelector } from 'store/selectors/user';
import { getThreadsPerPropertyIdSelector } from 'store/selectors/sockets/threads';
import { Content } from 'antd/lib/layout/layout';
import Price from '../Price';
import Info from '../Info';
import SubInfo from '../SubInfo';
import ApplicantsComponent from 'pages/Properties/Feed/Properties/Property/Applicants';
import Favorite from 'pages/Properties/Feed/Properties/Property/Favorite';
import Comment from 'pages/Properties/Feed/Properties/Property/Comment';
import Share from 'pages/Properties/Feed/Properties/Property/Share';
import Match from 'pages/Properties/Feed/Properties/Property/Match';
import { MAP_VIEWS } from '../../../../settings/constants/mapView';
import { getSearchResultsViewTypeSelector } from '../../../../store/selectors/searchResults';

const MarkerComponent = (props) => {
  const dispatch = useDispatch();
  const seenProperties = useSelector(getAllSeenOnMapSelector);
  const { className, imageClassName, onClickMarker, getPropertyLink } = props;
  const {
    category,
    id,
    longitude,
    latitude,
    image,
    price,
    info,
    clusterer,
    highlight,
    priceToDisplay,
    address,
    NumBeds,
    NumBathsTotal,
    SquareFeet,
    neighborhoodExploration,
    propertyProps,
  } = props;
  const { title, distance, rating, yelpIcon, maxWidth } = props;
  const user = useSelector(getUserSelector);
  const feedViewType = useSelector(getFeedViewTypeSelector);
  const searchViewType = useSelector(getSearchResultsViewTypeSelector);
  const threadsPerPropertyId = useSelector(getThreadsPerPropertyIdSelector);
  const hideMatch =
    user.data.Email.includes('client@mosaik.io') && !user.data.Email.includes('+client@mosaik.io');
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  const [isHover, setIsHover] = useState(false);
  const [markerMap, setMarkerMap] = useState({});
  const [open, setOpen] = useState(false);
  const [isMapOnlyView, setIsMapOnlyView] = useState(
    feedViewType.view === MAP_VIEWS.MAP || searchViewType.view === MAP_VIEWS.MAP,
  );
  const anyInfo = info || image || price;
  const isHighlighted = open || isHover || highlight;
  const compares = useSelector(getComparesSelector);
  const isSelected = useMemo(
    () => compares?.data.findIndex((item) => item.Id === id) !== -1,
    [compares],
  );

  useEffect(() => {
    setIsMapOnlyView(feedViewType.view === MAP_VIEWS.MAP || searchViewType.view === MAP_VIEWS.MAP);
  }, [feedViewType, searchViewType]);

  const onClickMarkerHandler = () => {
    if (anyInfo && isMapOnlyView) {
      setOpen(!open);
    }
    onClickMarker(id);
    dispatch(propertySeenOnMap({ id }));
    if (!isMapOnlyView) {
      dispatch(setPropertyToScrollInList({ id }));
    }
  };
  const markerLoadHandler = (marker) =>
    setMarkerMap((prevState) => ({ ...prevState, [id]: marker }));
  const propertyLink = getPropertyLink ? getPropertyLink(id) : null;

  const getFeatureInfoText = () => (
    <ul className={styles.featureWrapper}>
      <li>
        {typeof NumBeds === 'number' ? <span testid="property_beds">{NumBeds}</span> : null} beds
      </li>
      <li>
        <span testid="property_baths">{NumBathsTotal}</span> baths
      </li>
      <li>
        <NumberFormat
          displayType="text"
          thousandSeparator
          value={SquareFeet}
          renderText={(val) => (
            <span
              testid="property_feet"
              className={classNames(styles.markerAccent, styles.markerAmount)}
            >
              {val}
            </span>
          )}
        />{' '}
        sqft
      </li>
    </ul>
  );

  const PropertyImage = () =>
    GetWrappedByPropertyLink(
      <Image className={classNames(styles.image, imageClassName)} src={image} alt="" />,
    );

  const GetWrappedByPropertyLink = (uIComponent) => {
    if (propertyLink) {
      return <Link to={propertyLink}>{uIComponent}</Link>;
    }

    return uIComponent;
  };

  const getPropertyMetaBottom = () => {
    if (isClient && !hideMatch) {
      return (
        <div className={styles.metaBottom}>
          <Comment propertyInfo={propertyProps} threadsPerPropertyId={threadsPerPropertyId} />
          <Favorite propertyInfo={propertyProps} isClient={isClient} />
        </div>
      );
    }
    if (isAgent) {
      return (
        <>
          <Comment propertyInfo={propertyProps} threadsPerPropertyId={threadsPerPropertyId} />
          <Share propertyInfo={propertyProps} />
        </>
      );
    }
  };

  const getNeighborhoodIcon = (category) => {
    switch (category) {
      case 'Restaurants':
        return RestaurantsMarker;

      case 'Parks & Nature':
        return ParksMarker;

      case 'Nightlife':
        return NightlifeMarker;

      case 'Coffee Shops':
        return CoffeeMarker;

      case 'Health & Medical':
        return HealthMarker;

      case 'Bars':
        return BarMarker;

      case 'Fitness':
        return FitnessMarker;

      case 'Beauty & Spas V2':
        return BeautyMarker;

      case 'Groceries':
        return GroceriesMarker;

      default:
        break;
    }
  };

  const getMarkerIcon = () => {
    if (isSelected) {
      return MarkerSelected;
    }
    return seenProperties.includes(id) ? MarkerSeen : MarkerActive;
  };

  return (
    <div className={classNames(className)}>
      {(isHover && !priceToDisplay && rating) || (isHover && title && distance) ? (
        <>
          <InfoWindow anchor={markerMap[id]} position={{ lng: longitude, lat: latitude }}>
            {neighborhoodExploration ? (
              <LocationInfoWindow
                name={title}
                rating={rating}
                distance={distance}
                category={category}
                voteCount={props.voteCount}
              />
            ) : (
              <div className={styles.markerCard} key={id}>
                <div className={styles.propertyImg}>
                  <PropertyImage />
                </div>
                <Content className={styles.popupContainer}>
                  {GetWrappedByPropertyLink(
                    <div
                      className={classNames(styles.propertyInfo, {
                        [styles.agentPropertyInfo]: isAgent,
                      })}
                    >
                      {title && (
                        <div className={styles.title}>
                          <b>{title}</b>
                          {distance && <span>{distance}</span>}
                        </div>
                      )}
                      <div className={styles.priceWrap}>
                        <Price className={isClient ? styles.price : ''} value={price} />
                        {isClient && !hideMatch && (
                          <Match className={styles.matchScore} propertyInfo={propertyProps} />
                        )}
                      </div>
                      <Info value={getFeatureInfoText()} />
                      <SubInfo className={styles.subInfoBold} value={address?.Line1} />
                      <SubInfo value={`${address?.City}, ${address?.State}`} />
                    </div>,
                  )}
                  {isAgent && (
                    <div className={styles.meta}>
                      <div className={styles.metaTop}>
                        <ApplicantsComponent
                          applicantsWrapperClassName={styles.applicantsWrapper}
                          applicantsClassName={styles.applicants}
                          propertyInfo={props}
                        />
                        <Favorite propertyInfo={propertyProps} className={styles.agentFavorite} />
                      </div>
                    </div>
                  )}
                  <div className={styles.metaBottom}>{getPropertyMetaBottom()}</div>
                </Content>
              </div>
            )}
          </InfoWindow>
        </>
      ) : (
        ''
      )}
      <div onClick={onClickMarkerHandler}>
        {priceToDisplay ? (
          <Marker
            position={{ lng: longitude, lat: latitude }}
            onClick={onClickMarkerHandler}
            onLoad={(marker) => markerLoadHandler(marker)}
            clusterer={clusterer}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
            icon={{
              url: getMarkerIcon(),
              anchor: new window.google.maps.Point(20, 20),
              labelOrigin: new window.google.maps.Point(40, 20),
            }}
            label={{
              text:
                priceToDisplay === 'NaN' || priceToDisplay === '' || priceToDisplay === undefined
                  ? '$ --'
                  : priceToDisplay,
              className: styles.customMapMarkerLabel,
            }}
            noClustererRedraw
          />
        ) : (
          <Marker
            position={{ lng: longitude, lat: latitude }}
            onClick={onClickMarkerHandler}
            onLoad={(marker) => markerLoadHandler(marker)}
            clusterer={clusterer}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
            icon={{
              url: category
                ? getNeighborhoodIcon(category)
                : isHighlighted
                ? HoverMarkerImage
                : MarkerImage,
              anchor: category
                ? new window.google.maps.Point(20, 20)
                : isHighlighted
                ? new window.google.maps.Point(24, 24)
                : new window.google.maps.Point(20, 20),
            }}
            noClustererRedraw
          />
        )}
      </div>
    </div>
  );
};

MarkerComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  onClickMarker: PropTypes.func,
  getPropertyLink: PropTypes.func,
  longitude: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  image: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  info: PropTypes.string,
  clusterer: PropTypes.shape({}).isRequired,
  distance: PropTypes.string,
  title: PropTypes.string,
  yelpIcon: PropTypes.bool,
  rating: PropTypes.number,
  maxWidth: PropTypes.number,
  highlight: PropTypes.bool,
  neighborhoodExploration: PropTypes.bool,
};

MarkerComponent.defaultProps = {
  className: '',
  imageClassName: '',
  onClickMarker: () => {},
  getPropertyLink: () => {},
  image: undefined,
  info: undefined,
  price: undefined,
  distance: undefined,
  title: undefined,
  highlight: false,
  yelpIcon: false,
  rating: null,
  maxWidth: 277,
  neighborhoodExploration: false,
};
export default MarkerComponent;
