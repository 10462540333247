import { handleActions } from 'redux-actions';
import { cloneDeep, get } from 'lodash-es';

import {
  openShareDrawerAction,
  requestGetClientsAction,
  searchClientsAction,
  setRecipientsAction,
} from 'store/actions/feed';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  open: false,
  clients: {
    state: IDLE,
    data: null,
    meta: {},
  },
  search: '',
  recipients: [],
};

export default handleActions(
  {
    [openShareDrawerAction]: (state, { payload }) => ({
      ...state,
      open: payload.open || initialData.open,
    }),
    [requestGetClientsAction]: (state, { payload }) => ({
      ...state,
      clients: {
        state: payload.state,
        data: get(payload, 'data.result', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      },
    }),
    [searchClientsAction]: (state, { payload }) => ({
      ...state,
      search: payload.search,
    }),
    [setRecipientsAction]: (state, { payload }) => ({
      ...state,
      recipients: payload,
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
