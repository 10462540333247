import { EditStageInfo } from '../EditStageInfo';

import styles from '../styles.module.scss';

export const EditAvailability = ({ data, onEdit }) => {
  const getInfo = () => {
    const { ShowingAvailability } = data;
    return ShowingAvailability?.length
      ? `Availability Windows (${ShowingAvailability.length})`
      : '-';
  };

  return (
    <div className={styles.editForm}>
      <EditStageInfo icon="calendar" title="Availability" info={getInfo()} onClick={onEdit} />
    </div>
  );
};
