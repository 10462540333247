import classNames from 'classnames';
import Button from 'antd/lib/button';

import { Space } from 'components-antd';

import styles from './styles.module.scss';

export enum YesNoQuestionDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum YesNoStatus {
  Yes = 'Yes',
  No = 'No',
}

interface YesNoQuestionProps {
  status?: YesNoStatus;
  direction?: YesNoQuestionDirection;
  onAnswerHandler: (status: YesNoStatus) => void;
  yesBtnClassName?: string;
  noBtnClassName?: string;
}

export const YesNoQuestion = ({
  status,
  direction = YesNoQuestionDirection.Vertical,
  onAnswerHandler,
  yesBtnClassName,
  noBtnClassName,
}: YesNoQuestionProps) => (
  <div className={styles.yesNoQuestion}>
    <Space className={styles.spacing} size="middle" direction={direction}>
      <Button
        type="text"
        className={classNames(styles.answerBtn, yesBtnClassName, {
          [styles.active]: status === YesNoStatus.Yes,
        })}
        onClick={() => onAnswerHandler(YesNoStatus.Yes)}
      >
        Yes
      </Button>
      <Button
        type="text"
        className={classNames(styles.answerBtn, noBtnClassName, {
          [styles.active]: status === YesNoStatus.No,
        })}
        onClick={() => onAnswerHandler(YesNoStatus.No)}
      >
        No
      </Button>
    </Space>
  </div>
);
