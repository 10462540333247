import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components';

import styles from './styles.module.scss';

const EditActions = (props) => {
  const { show, className, onSave, disabledSave, onCancelEdit, isScreenMobile } = props;

  if (!show) return null;

  return (
    <div className={classNames(styles.editActions, className)}>
      <Button
        testid="cancel"
        onClick={onCancelEdit}
        className={classNames(
          styles.button,
          { [styles.buttonWidthMobile]: isScreenMobile },
          { [styles.buttonWidthNotMobile]: !isScreenMobile },
          styles.cancel,
        )}
        title="Cancel"
      />
      <Button
        testid="save"
        disabled={disabledSave}
        onClick={onSave}
        className={classNames(
          styles.button,
          { [styles.buttonWidthMobile]: isScreenMobile },
          { [styles.buttonWidthNotMobile]: !isScreenMobile },
        )}
        title="Save"
      />
    </div>
  );
};

EditActions.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  isScreenMobile: PropTypes.bool,
  onSave: PropTypes.func,
  disabledSave: PropTypes.bool,
  onCancelEdit: PropTypes.func,
};

EditActions.defaultProps = {
  className: '',
  show: false,
  isScreenMobile: false,
  onSave: () => {},
  disabledSave: false,
  onCancelEdit: () => {},
};

export default EditActions;
