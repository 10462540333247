import { useState } from 'react';
import PropTypes from 'prop-types';

import { Input, Select as CustomSelect, MenuListItems } from 'components';
import { Row, Col, Button, Popover } from 'components-antd';
import { Add, Edit, TrashIcon as Trash } from 'components/Icons';
import Icon from '../../Icons';
import { prefsIds } from 'settings/constants/preferences';
import { Commute } from './Commute';

import styles from './styles.module.scss';

export const Commutes = ({ title, softCriteria, type, onClick }) => {
  const [showCommute, setShowCommute] = useState(!softCriteria[title]?.data?.length);
  const [commute, setCommute]: any = useState([]);

  const commuteHandler = (value) => {
    setCommute({});
    setShowCommute(value);
  };

  const showCommuteSection = (value) => {
    setShowCommute(value);
  };

  const deleteCommute = (item) => {
    onClick(title, softCriteria[title].value, type, item, 'delete');
  };

  const editCommute = (item) => {
    setShowCommute(true);
    setCommute(item);
  };

  return (
    <>
      {(title === prefsIds.commutes || title === prefsIds.proximityToImportantLocations) &&
        softCriteria[title] && (
          <>
            {!showCommute &&
              softCriteria[title].data &&
              softCriteria[title].data.map((item, i) => (
                <div key={i} className={styles.commuteList}>
                  <div className={styles.details}>
                    {item.by === 'Car' && <Icon variant={'car'} />}
                    {item.by === 'PublicTransit' && <Icon variant={'transit'} />}
                    {item.by === 'Bicycle' && <Icon variant={'bike'} />}
                    {item.by === 'Walking' && <Icon variant={'walk'} />}
                    <div>
                      <h4>{item.locationLabel && item.locationLabel}</h4>
                      <p>{item.minutes && item.minutes} minutes</p>
                    </div>
                  </div>
                  <Popover
                    content={
                      <MenuListItems
                        items={[
                          {
                            id: 0,
                            name: 'Edit',
                            icon: Edit,
                            onClick: () => editCommute(item),
                          },
                          {
                            id: 1,
                            name: 'Delete',
                            icon: Trash,
                            onClick: () => deleteCommute(item),
                          },
                        ]}
                      />
                    }
                    trigger="click"
                    placement="bottomRight"
                    overlayClassName={styles.popoverOverlay}
                  >
                    <span className={styles.dotsImage}>
                      <Icon variant={'dots'} />
                    </span>
                  </Popover>
                </div>
              ))}
            {showCommute && (
              <Commute
                onClick={onClick}
                softCriteria={softCriteria}
                title={title}
                commuteHandler={commuteHandler}
                showCommuteSection={showCommuteSection}
                commute={commute}
                type={type}
              />
            )}
            {!showCommute && (
              <div className={styles.addAnother} onClick={() => commuteHandler(true)}>
                <Add className={styles.addIcon} color={Add.ORANGE_CIRCLE} />
                <span>Add Another</span>
              </div>
            )}
          </>
        )}
    </>
  );
};

Commutes.propTypes = {
  title: PropTypes.string,
  softCriteria: PropTypes.shape({}),
  type: PropTypes.string,
  onClick: PropTypes.func,
};

Commutes.defaultProps = {
  title: '',
  softCriteria: {},
  type: '',
  onClick: () => {},
};
