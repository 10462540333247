import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Input } from 'components-antd';
import { Close } from 'components/Icons';
import { setTaskTemplatesQueryAction } from 'store/actions/templates';

import styles from './styles.module.scss';

export const TemplateHeaderSearch = (props) => {
  const { isTask, className, onClear, onSearch, value } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [query, setQuery] = useState('');

  const dispatch = useDispatch();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsExpanded(false);
        }
      }
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const onQueryChange = (value) => {
    setQuery(value);
    dispatch(setTaskTemplatesQueryAction(value));
  };

  const clearSearch = (value) => {
    setQuery(value);
    dispatch(setTaskTemplatesQueryAction(value));
  };

  return (
    <div
      className={classNames(styles.searchWrapper, className, { [styles.expanded]: isExpanded })}
      onClick={() => setIsExpanded(true)}
      ref={wrapperRef}
    >
      <div className={classNames(styles.icon, styles.searchIcon)}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66667 11.3333C9.24399 11.3333 11.3333 9.24399 11.3333 6.66667C11.3333 4.08934 9.24399 2 6.66667 2C4.08934 2 2 4.08934 2 6.66667C2 9.24399 4.08934 11.3333 6.66667 11.3333Z"
            stroke="#ADADAD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 14L10 10"
            stroke="#ADADAD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <Input
        placeholder="Search"
        value={isTask ? query : value}
        onChange={(e) => (isTask ? onQueryChange(e.target.value) : onSearch(e.target.value))}
      />
      <Close
        className={classNames(styles.icon, styles.closeIcon)}
        onClick={() => {
          isTask ? clearSearch(null) : onClear();
        }}
      />
    </div>
  );
};

TemplateHeaderSearch.propTypes = {
  isTask: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
};

TemplateHeaderSearch.defaultProps = {
  isTask: false,
  className: '',
  value: '',
  onSearch: () => {},
  onClear: () => {},
};
