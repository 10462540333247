import { CommonHeader, QuoteFormHeader, SmartFormHeader } from 'components-antd/Header/components';
import { OfferPortal } from 'pages';
import { routes } from 'settings/navigation/routes';

export const HeaderNavLinks = CommonHeader;

export const headerRoutes = [
  {
    path: routes.dashboardServiceDirectoryFormsQuoteForm,
    component: QuoteFormHeader,
    exact: true,
  },
  {
    path: routes.dashboardServiceDirectoryFormsQuoteFormPreview,
    component: QuoteFormHeader,
    exact: true,
  },
  {
    path: routes.dashboardServiceDirectoryFormsSmartFormPreview,
    component: SmartFormHeader,
    exact: true,
  },
  {
    path: routes.dashboardServiceDirectoryFormsSmartForm,
    component: SmartFormHeader,
    exact: true,
  },
  { path: '*', component: HeaderNavLinks },
];
