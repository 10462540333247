import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PageWrapper, Row, Col } from 'components-antd';
import { Wrapper } from 'components';
import { DocumentCard, DocumentCardTypes, LocalHeader, Archive } from '../components';

import { Icons } from 'pages/Vault/Icons';

import { getVaultDocumentsList } from 'store/selectors/vault';
import { getVaultDocumentsEffect } from 'store/effects';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getClientContextsStateSelector } from 'store/selectors/contexts';

import { READY, PENDING } from 'settings/constants/apiState';

import styles from './styles.module.scss';

export const MyDocuments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchContext = useSelector(getCurrentContextSelector);
  const contextsState = useSelector(getClientContextsStateSelector);
  const selectedAgentId = searchContext?.Agents?.[0]?.Id;
  const { data, isPending } = useSelector(getVaultDocumentsList);
  const { clientArchiveDocuments, clientTransactionDocuments, preApprovalDocuments } = data || {};

  const cardTypes = [DocumentCardTypes.ORANGE, DocumentCardTypes.GREEN, DocumentCardTypes.PURPLE];

  const fetchDocuments = () => {
    dispatch(
      getVaultDocumentsEffect(
        {
          id: selectedAgentId,
        },
        { silent: true },
      ),
    );
  };

  useEffect(() => {
    if (contextsState.state === READY && selectedAgentId) fetchDocuments();
  }, [contextsState.state, selectedAgentId]);

  return (
    <PageWrapper
      bgWhite
      mainPageContentStyle={styles.pagecontentStyles}
      footerStyles={styles.stickyFooter}
    >
      <LocalHeader title="My Documents" uploadCallback={fetchDocuments} />
      <div className={styles.pageContent}>
        <Wrapper isPending={contextsState?.state === PENDING || isPending}>
          <Row gutter={24}>
            {clientTransactionDocuments?.map((item, idx) => (
              <Col key={idx} span={6}>
                <DocumentCard
                  title={item?.Property?.Address?.Line1 || item?.Folder}
                  docsCount={item?.DocumentsCount}
                  bgColor={cardTypes?.[idx % cardTypes?.length]}
                  onClickHandler={() =>
                    history.push(`/vault/${item?.Id || `folder-${item?.Folder}`}/details`)
                  }
                />
              </Col>
            ))}
            <Col span={6}>
              <DocumentCard
                title="Pre-Approvals"
                docsCount={preApprovalDocuments?.DocumentsCount}
                bgColor={DocumentCardTypes.WHITE}
                cardIcon={<Icons variant={Icons.PRE_APPROVAL} />}
                onClickHandler={() => history.push('/vault/pre-approvals')}
              />
            </Col>
          </Row>
        </Wrapper>
      </div>
      <Archive docsCount={clientArchiveDocuments?.DocumentsCount} />
    </PageWrapper>
  );
};
