import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { map, split } from 'lodash-es';

import { useLongPress } from 'hooks';

import {
  getSearchedResultPreparedList,
  getSearchString,
  isSearchMessagesLoading,
} from 'store/selectors/searchMessages';
import { getUserId } from 'store/selectors/user';

import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';

import { NoThreads, OfferListingMessage, UsersMessage, Wrapper } from 'components';

import ActionsList from 'components/Drawers/components/ActionsList';

import {
  getArchiveThreadOption,
  getAddParticipantThreadOption,
  getOpenChatThreadOption,
  openChatThreadHandler,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import styles from './styles.module.scss';

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const MessagesDrawerSearchedContent = ({ className }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isSearchMessagesLoading);
  const list = useSelector(getSearchedResultPreparedList);
  const searchString = useSelector(getSearchString);
  const userId = useSelector(getUserId);

  const [actionsPosition, setActionPosition] = useState(null);
  const [actions, setActions] = useState([]);

  const highlightSearchString = useCallback(
    (string) =>
      map(split(string, new RegExp(`(${escapeRegExp(searchString)})`, 'gi')), (part, i) => (
        <span
          key={i}
          className={
            part.toLowerCase() === searchString.toLowerCase() ? styles.searchedString : null
          }
        >
          {part}
        </span>
      )),
    [searchString],
  );

  const onLongPress = (event, [thread]) => {
    setActionPosition({ x: event?.clientX, y: event?.clientY });
    setActions([
      ...getArchiveThreadOption(dispatch, thread, () => {
        setActionPosition(null);
      }),
    ]);
  };
  const onPress = (event, [thread]) => {
    openChatThreadHandler(dispatch, {
      threadId: thread.Id,
      searchedMessageId: thread.UniqId.split('-')[1],
    });
  };

  const longPressEvent = useLongPress(onLongPress, onPress);

  return (
    <div testid="searched_content" className={classNames(styles.wrapper, className)}>
      <Wrapper isPending={isLoading}>
        {!!list.length && (
          <div
            testid="number_results"
            className={styles.resultNumber}
          >{`${list.length} Results Found`}</div>
        )}
        {list?.length ? (
          map(list, (thread) => {
            if (thread.Type === SOCKET_THREAD_TYPES.CHAT) {
              return (
                <UsersMessage
                  withLabel
                  key={thread.UniqId}
                  thread={thread}
                  userId={userId}
                  formatText={highlightSearchString}
                  {...longPressEvent(thread)}
                />
              );
            }
            return null;
          })
        ) : (
          <NoThreads>No results found</NoThreads>
        )}
        <ActionsList actions={actions} position={actionsPosition} />
      </Wrapper>
    </div>
  );
};

MessagesDrawerSearchedContent.propTypes = {
  className: PropTypes.string,
};

MessagesDrawerSearchedContent.defaultProps = {
  className: null,
};

export default MessagesDrawerSearchedContent;
