import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { LocationService } from 'services';
import { Drawer } from 'components';
import {
  getIsClientDrawerSelector,
  getClientIdFromDrawerSelector,
  isInviteClientModalOpened,
  isProfileExpandedSectionOPened,
  isInviteTeamMemberModalOpened,
  isAssignConnectionModalOpened,
  getAgentIdFromDrawerSelector,
} from 'store/selectors/app';
import {
  appManageClientDrawerAction,
  appSetClientDrawerActiveTab,
  appSetClientsSearchValueAction,
  appSetShowInviteClientAction,
  appSetShowInviteTeamMemberAction,
} from 'store/actions/app';
import { getClientsListDataArray } from 'store/selectors/clientsList';
import {
  appSetConnectionTypeEffect,
  requestGetTeamListEffect,
  setDrawerAgentProfileId,
  setDrawerClientProfileId,
  setProfileExpandedSection,
} from 'store/effects';
import { ClientHeader, ProfileHeader } from './Headers';
import InviteClient from './InviteClient';
import InviteTeamMember from './InviteTeamMember';
import ProfileClient from './ProfileClient';
import ViewClients from './ViewClients';
import { requestGetClientsListEffect } from '../../store/effects/clientsList';
import ProfileExpanded from './ProfileClient/ExpandedSection';
import { getUserDataSelector } from 'store/selectors/user';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';
import { AssignConnectionModal } from 'components-antd';

const locationSrv = new LocationService();

const ClientsDrawer = () => {
  const isOpen = useSelector(getIsClientDrawerSelector);
  const user = useSelector(getUserDataSelector);
  const clientId = useSelector(getClientIdFromDrawerSelector);
  const agentId = useSelector(getAgentIdFromDrawerSelector);
  const clientsList = useSelector(getClientsListDataArray);
  const isClientInviteOpened = useSelector(isInviteClientModalOpened);
  const isTeamMemberInviteOpened = useSelector(isInviteTeamMemberModalOpened);
  const { showAssignConnection: isAssignConnectionOpened } = useSelector(
    isAssignConnectionModalOpened,
  );
  const isProfileExpandedOpened = useSelector(isProfileExpandedSectionOPened);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  locationSrv.setLocation(location);
  const { profileClientId, profileAgentId, activeTab } = locationSrv.getQuery();

  const inviteClientOrRequireEmailVerification = useEmailVerificationWall(
    ActionToInterrupt.INVITE_CLIENT,
    (state) => {
      dispatch(appSetShowInviteClientAction(state));
    },
  );

  const inviteTeamMemberOrRequireEmailVerification = useEmailVerificationWall(
    ActionToInterrupt.INVITE_TEAM_MEMBER,
    (state) => {
      dispatch(appSetShowInviteTeamMemberAction(state));
    },
  );

  const setShowInviteConnection = useCallback(
    (state, type) => {
      type === AGENT_CONNECTIONS.Clients
        ? inviteClientOrRequireEmailVerification(state)
        : inviteTeamMemberOrRequireEmailVerification(state);
    },
    [dispatch, user?.IsEmailVerified],
  );

  const handleDrawerClose = useCallback(() => {
    dispatch(appManageClientDrawerAction(false));
    dispatch(appSetConnectionTypeEffect({ type: AGENT_CONNECTIONS.Clients }));
    dispatch(appSetClientsSearchValueAction(''));
    dispatch(appSetClientDrawerActiveTab(0));
  }, [dispatch]);

  useEffect(() => {
    if (isOpen) {
      const query = locationSrv.getQuery();
      history.replace(
        locationSrv.setQuery({
          ...query,
          profileClientId: undefined,
          profileAgentId: undefined,
        }),
      );
    }
    if (!isOpen && !isProfileExpandedOpened) {
      dispatch(setDrawerClientProfileId({ id: null }));
      dispatch(setDrawerAgentProfileId({ id: null }));
    }
  }, [isOpen, isProfileExpandedOpened]); // eslint-disable-line

  useEffect(() => {
    if (profileClientId && !activeTab) {
      dispatch(requestGetClientsListEffect());
      dispatch(setDrawerClientProfileId({ id: profileClientId }));
      dispatch(appManageClientDrawerAction(true));
    }

    if (profileClientId && !!activeTab) {
      dispatch(requestGetClientsListEffect());
      dispatch(setDrawerClientProfileId({ id: profileClientId }));
      dispatch(setProfileExpandedSection(true));
    }

    if (profileAgentId) {
      dispatch(requestGetTeamListEffect());
      dispatch(setDrawerAgentProfileId({ id: profileAgentId }));
      dispatch(appManageClientDrawerAction(true));
    }
  }, [location, dispatch]); // eslint-disable-line

  const getHeader = () => {
    if (clientId || agentId) {
      return <ProfileHeader isAgent={!!agentId} />;
    }

    return <ClientHeader setShowInviteConnection={setShowInviteConnection} />;
  };

  const getContent = () => {
    if (clientId || agentId) {
      return <ProfileClient id={clientId || agentId} isAgent={!!agentId} />;
    }

    if (!isOpen) {
      return null;
    }

    return <ViewClients />;
  };

  return (
    <>
      <Drawer
        shouldDisableScroll={!profileClientId}
        isOpen={isOpen && !!clientsList}
        header={getHeader()}
        onClose={handleDrawerClose}
        testid="clients_drawer"
        zIndex={1000}
      >
        {getContent()}
      </Drawer>
      {isClientInviteOpened && <InviteClient />}
      {isTeamMemberInviteOpened && <InviteTeamMember />}
      {isAssignConnectionOpened && (
        <AssignConnectionModal disableOnEmpty={false} closeOnClientEmpty />
      )}
      {isProfileExpandedOpened && (
        <ProfileExpanded id={clientId || agentId} isAgent={!!agentId} activeTab={activeTab} />
      )}
    </>
  );
};

export default ClientsDrawer;
