import React from 'react';

import { Avatar } from 'components';
import { getCategoriesSortSelector } from 'store/selectors/adminPanel';
import { sortCategoriesEffect } from 'store/effects/adminPanel';
import Sort from 'pages/Dashboard/components/Sort';
import { PartnerCategoryType } from 'app-constants';

import styles from './styles.module.scss';

export const gridColumns = [
  {
    title: 'Icon',
    propKey: 'Icon',
    render: (Icon, { Title }) => (
      <Avatar
        className={styles.categoryIconWrapper}
        avatarClassName={styles.categoryIcon}
        src={Icon}
        placeholder={Title?.substring(0, 1).toUpperCase()}
      />
    ),
  },
  {
    title: 'Title',
    propKey: 'Title',
    children: (
      <Sort selector={getCategoriesSortSelector} effect={sortCategoriesEffect} field="Title" />
    ),
  },
  {
    title: 'Type',
    propKey: 'Type',
    children: (
      <Sort selector={getCategoriesSortSelector} effect={sortCategoriesEffect} field="Type" />
    ),
    render: (type) => type?.replace(/([A-Z])/g, ' $1').trim(),
  },
];

export const typesOptions = Object.values(PartnerCategoryType).map((el) => ({
  name: el,
  value: el,
}));

export const emptyValue = { name: '', value: null };
