import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';

import MessageParser from './MessageParser/MessageParser';
import { Config } from './Config/Config';
import Action from './Actions/Action';
import { Icons } from './Icons/Icons';

import styles from './styles.module.scss';
import { clearSocket, connectSocket } from 'settings/web-services/Mozzie/sockets';
import { featureFlags } from 'utils/featureFlags';
import { useDispatch } from 'react-redux';
import { setisCheckMessageEffect, setNegativeCheckResponseEffect } from 'store/effects/mozzie';

const Mozzie = () => {
  const dispatch = useDispatch();
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const [showChatBot, setShowChatBot] = useState(false);
  const [intent, setIntent] = useState('');
  const [sessionId, setSessionId] = useState('');
  // const [isCheckMessage, setIsCheckMessage] = useState(false);
  // const [negativeCheckResponse, setNegativeCheckResponse] = useState(false);
  const [intentPayload, setIntentPayload] = useState({});
  const [messages, setMessages] = useState(Config.initialMessages);
  const socket = useRef<WebSocket | null>(null);

  const onIsCheckMessage = (payload) => {
    dispatch(setisCheckMessageEffect(payload));
  };
  const onNegativeCheckResponse = (payload) => {
    dispatch(setNegativeCheckResponseEffect(payload));
  };
  const generateUniqueId = () => {
    const uuid = uuidv4();
    const timestamp = Date.now(); // get the current timestamp
    return `${uuid}-${timestamp}`;
  };
  useEffect(() => {
    // Initialize the socket connection
    if (showChatBot) {
      const currentSessionId = generateUniqueId();
      setSessionId(currentSessionId);
      socket.current = connectSocket(currentSessionId);
    }
    if (!showChatBot) {
      clearSocket();
      onIsCheckMessage(false);
      onNegativeCheckResponse(false);
      setSessionId('');
    }
    return () => {
      // Disconnect the socket
    };
  }, [showChatBot]);
  console.log('current session id ', sessionId);
  return (
    <>
      {!featureFlags.mozzie ? (
        <></>
      ) : (
        <>
          <Icons
            variant={Icons.BOT}
            onClick={() => {
              setShowChatBot((prev) => !prev);
              setIsFirstMessage(true);
            }}
            className={styles.appChatbotButton}
          />

          {showChatBot && (
            <div className={styles.appChatbotContainer}>
              <Chatbot
                config={Config}
                messageParser={MessageParser}
                actionProvider={(props) => (
                  <Action
                    {...props}
                    state={Config.state}
                    isFirstMessage={isFirstMessage}
                    setIsFirstMessage={setIsFirstMessage}
                    socket={socket.current}
                    intent={intent}
                    intentPayload={intentPayload}
                    setIntent={setIntent}
                    setIntentPayload={setIntentPayload}
                    sessionId={sessionId}
                  />
                )}
                headerText="Mozzie"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Mozzie;
