import React from 'react';
import { useSelector } from 'react-redux';
import {
  getIsCheckMessageSelector,
  getNegativeCheckResponseSelector,
} from 'store/selectors/mozzie';

const MessageParser = ({ children, actions }) => {
  const isCheckMessage = useSelector(getIsCheckMessageSelector);
  const negativeCheckResponse = useSelector(getNegativeCheckResponseSelector);
  const parse = (message) => {
    if (negativeCheckResponse) {
      actions.handleNegativeCheckResponse(message);
    } else if (isCheckMessage) {
      if (message.toLowerCase() === 'yes' || message.toLowerCase() === 'y') {
        actions.handlePositiveCheck();
      } else if (message.toLowerCase() === 'no' || message.toLowerCase() === 'n') {
        actions.handleNegativeCheck();
      }
    } else {
      console.log(message);
      // Call some action depending upon the input
      actions.handleInputMessage(message);
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: {},
        });
      })}
    </div>
  );
};

export default MessageParser;
