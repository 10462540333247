import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from 'components';
import { Checkbox } from 'components-antd';

import styles from './styles.module.scss';

const LenderCard = (props) => {
  const {
    id,
    title,
    subTitle,
    logo,
    onSelect,
    isActive,
    className,
    avatarClassName,
    textWrapperClassName,
    selectWrapperClassName,
  } = props;

  const [checked, setChecked] = useState(isActive);
  const getPlaceholder = () => {
    let str = title.split(' ');
    let first = str[0][0];
    let last = str[1] !== undefined && str[1].length ? str[1][0] : '';
    let placeholder = first + last;
    return placeholder;
  };

  const onChange = () => {
    onSelect(id);
    setChecked(!checked);
  };

  return (
    <div
      testid="lender_card"
      className={classNames(styles.card, { [styles.active]: isActive }, className)}
    >
      <Avatar
        src={logo}
        placeholder={getPlaceholder()}
        avatarClassName={classNames(styles.logo, avatarClassName)}
      />
      <div className={classNames(styles.textWrapper, textWrapperClassName)}>
        <p testid="title" className={styles.title}>
          {title}
        </p>
        <p testid="subtitle" className={styles.subTitle}>
          {subTitle}
        </p>
      </div>
      <div className={classNames(styles.selectWrapper, selectWrapperClassName)}>
        <Checkbox className="mosaikCheckbox" checked={checked} onChange={onChange} />
      </div>
    </div>
  );
};

LenderCard.propTypes = {
  className: PropTypes.string,
  avatarClassName: PropTypes.string,
  textWrapperClassName: PropTypes.string,
  selectWrapperClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  logo: PropTypes.node.isRequired,
  onSelect: PropTypes.func.isRequired,
  isFavorite: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isActive: PropTypes.bool,
};

LenderCard.defaultProps = {
  className: '',
  avatarClassName: '',
  textWrapperClassName: '',
  selectWrapperClassName: '',
  isFavorite: false,
  isActive: false,
};

export default LenderCard;
