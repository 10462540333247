import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PENDING } from 'settings/constants/apiState';
import { routes } from 'settings/navigation/routes';

import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';

import { Answers } from 'components';
import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';

import styles from './styles.module.scss';

const ApproveMilitary = ({ onNext, Controls, stageIndex }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);

  const propName = OnBoardingWizard.propNames.approveMilitary;
  const storedAnswer =
    getWizardStageValue({
      obj: onBoarding,
      stageIndex,
      signUpRole,
      propName,
      parentPropName: 'PreApproval',
    }) || [];
  const [stageValue, setStageValue] = useState(storedAnswer);

  const onSave = (val) => {
    const cfg = {
      stageIndex,
      PreApproval: {
        [propName]: val,
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, { withoutRequest: true }, (err) => {
        if (!err) {
          onNext();
        }
      }),
    );
  };

  const onAnswer = (val) => {
    setStageValue(val);
  };

  const isPending = onBoarding.state === PENDING;

  return (
    <OnBoardingWrapper testid="approve_spouse_stage" isPending={isPending}>
      <Controls
        onNext={() => onSave(stageValue)}
        isNextDisabled={!stageValue.length}
        onClose={() => history.push(routes.mySearches)}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question>Are you or your spouse active military or veteran?</Question>
        <AnswersContainer>
          <Answers
            cases={ApproveMilitary.answerCases}
            onChange={onAnswer}
            value={stageValue}
            pending={isPending}
            testid="approve_spouse_answers"
            variant={Answers.LIGHT}
            caseClassName={styles.answer}
            activeCaseClassName={styles.answerActive}
            labelClassName={styles.label}
            className={styles.answersWrapper}
          />
        </AnswersContainer>
        <ButtonsContainer>
          <OnboardingContinueButton
            testid="approve_spouse_next"
            onClick={() => onSave(stageValue)}
            isPending={isPending}
            disabled={!stageValue.length}
            iconArrow
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

ApproveMilitary.answerCases = [
  { id: 'yes', label: 'Yes' },
  { id: 'no', label: 'No' },
];

ApproveMilitary.propTypes = {
  onNext: PropTypes.func,
  Controls: PropTypes.elementType,
  stageIndex: PropTypes.number,
};

ApproveMilitary.defaultProps = {
  onNext: () => {},
  Controls: () => null,
  stageIndex: undefined,
};

export default ApproveMilitary;
