import PropTypes from 'prop-types';
import { Button } from 'components-antd';
import { useDispatch } from 'react-redux';
import { rejectMlsAccessForAgent } from 'api/admin/users';
import { getAllMlsAgentsDashboardEffect } from 'store/effects/adminPanel/mlsAgents';
import { MlsAccessStatusEnum } from '../constants';

const RejectMlsAgentButton = (props) => {
  const dispatch = useDispatch();
  const { status, mlsId, userId } = props;

  if (![MlsAccessStatusEnum.REQUESTED].includes(status)) {
    return null;
  }
  const handleRejectMlsAccessClick = async (mlsId, userId) => {
    await rejectMlsAccessForAgent({
      userId,
      mlsId,
    });
    dispatch(getAllMlsAgentsDashboardEffect({}));
  };
  return <Button onClick={() => handleRejectMlsAccessClick(mlsId, userId)}>Reject</Button>;
};

RejectMlsAgentButton.propTypes = {
  status: PropTypes.string,
};

RejectMlsAgentButton.defaultProps = {
  status: '',
};

export default RejectMlsAgentButton;
