import { Select } from 'components-antd';
import classNames from 'classnames';

import styles from '../styles.module.scss';

import { ErrorMessage } from '../../../components/ErrorMessages';
import { Icons } from '../../../../components/Icons';
import { DatePicker } from 'antd';
import { financingType } from '../helper';
import moment from 'moment';
import { RequiredAsterik } from '../../components/RequiredAsterik';

export const Breakpoint1 = (props) => {
  const {
    state,
    newBuyer,
    toggleNewBuyer,
    setErrors,
    errorMessages,
    onChange,
    removeBuyer,
    renderField,
    showAll,
  } = props;

  const disabledDate = (current) => {
    let today = moment(new Date()).format('YYYY-MM-DD');
    return current && current < moment(today, 'YYYY-MM-DD');
  };

  return (
    <div className={styles.breakpoint1}>
      <div className={styles.inputBlock}>
        <div className={styles.buyerInput}>
          <div className={styles.label}>
            Buyer <RequiredAsterik />
          </div>
          {!newBuyer ? (
            <div className={styles.iconText} onClick={toggleNewBuyer}>
              <Icons variant="add" className={styles.addIcon} />
              <div className={styles.addText}>Add Another</div>
            </div>
          ) : null}
        </div>
        {renderField({
          name: 'Buyer',
          value: state?.Buyer,
          placeholder: 'Enter name',
          errorKey: 'BuyerNameError',
          id: 'buyer_name',
          testId: 'buyer',
          onChange,
        })}
      </div>

      {newBuyer ? (
        <div className={styles.inputBlock}>
          <div className={styles.buyerInput}>
            <div className={styles.label}>
              Buyer 2 <RequiredAsterik />
            </div>

            <Icons variant="remove" className={styles.removeIcon} onClick={removeBuyer} />
          </div>
          {renderField({
            name: 'Buyer2',
            value: state?.Buyer2,
            placeholder: 'Enter name',
            errorKey: 'Buyer2NameError',
            id: 'buyer2_name',
            testId: 'buyer2',
            onChange,
          })}
        </div>
      ) : null}

      <div className={styles.inputBlock}>
        <div className={styles.label}>
          Purchase Price <RequiredAsterik />
        </div>
        {renderField({
          type: 'number',
          prefix: '$',
          isNumberFormat: true,
          name: 'PurchasePrice',
          value: state?.OfferIteration?.PurchasePrice,
          placeholder: '$0',
          errorKey: 'PurchasePriceError',
          id: 'PurchasePrice',
          testId: 'PurchasePrice',
          customOnChange: (value) => {
            onChange({
              ...state,
              OfferIteration: {
                ...state.OfferIteration,
                PurchasePrice: value ? Number(value) : '',
              },
            });

            setErrors({
              ...errorMessages,
              PurchasePriceError: '',
            });
          },
        })}
      </div>

      <div className={styles.inputBlock}>
        <div className={styles.label}>Down Payment</div>
        {renderField({
          type: 'number',
          prefix: '$',
          isNumberFormat: true,

          name: 'DownPayment',
          value: state?.OfferIteration?.DownPayment,
          placeholder: '$0',
          errorKey: '',
          id: 'DownPayment',
          testId: 'DownPayment',
          customOnChange: (value) => {
            onChange({
              ...state,
              OfferIteration: {
                ...state.OfferIteration,
                DownPayment: value ? Number(value) : '',
              },
            });
          },
        })}
      </div>

      <div className={styles.inputBlock}>
        <div className={styles.label}>Earnest Money Deposit</div>
        {renderField({
          type: 'number',
          prefix: '$',
          isNumberFormat: true,

          name: 'EarnestMoneyDeposit',
          value: state?.OfferIteration?.EarnestMoneyDeposit,
          placeholder: '$0',
          errorKey: '',
          id: 'EarnestMoneyDeposit',
          testId: 'EarnestMoneyDeposit',
          customOnChange: (value) => {
            onChange({
              ...state,
              OfferIteration: {
                ...state.OfferIteration,
                EarnestMoneyDeposit: value ? Number(value) : '',
              },
            });
          },
        })}
      </div>

      <div className={styles.inputBlock}>
        <div className={styles.label}>
          Closing Date <RequiredAsterik />
        </div>
        <DatePicker
          className={classNames(styles.inputHolder, styles.closingDate)}
          format={'MM/DD/YYYY'}
          placeholder={'MM/DD/YYYY'}
          disabledDate={disabledDate}
          suffixIcon={<Icons variant={'datePicker'} />}
          onChange={(e: any) => {
            setErrors({
              ...errorMessages,
              ClosingDateError: '',
            });
            onChange({
              ...state,
              OfferIteration: { ...state.OfferIteration, ClosingDate: e },
            });
          }}
          value={
            state?.OfferIteration?.ClosingDate
              ? moment(state?.OfferIteration?.ClosingDate)
              : state?.OfferIteration?.ClosingDate
          }
          getPopupContainer={(node) => node}
          popupClassName={classNames('mosaikCalendar', styles.popupCalender)}
        />
        <ErrorMessage id="closing_date" message={errorMessages.ClosingDateError} />
      </div>

      <div className={styles.inputBlock}>
        <div className={styles.label}>
          Financing Type <RequiredAsterik />
        </div>
        <Select
          className={styles.inputHolder}
          options={financingType}
          value={state?.OfferIteration?.FinancingType}
          onSelect={(e) => {
            setErrors({
              ...errorMessages,
              FinancingTypeError: '',
            });
            onChange({
              ...state,
              OfferIteration: { ...state.OfferIteration, FinancingType: e },
            });
          }}
          placeholder={'Select Financing Type'}
          getPopupContainer={(node) => node}
          popupClassName={styles.inputHolderDropdown}
        />
        <ErrorMessage id="financing_type" message={errorMessages.FinancingTypeError} />
      </div>

      {showAll ? <div className={styles.fieldSeparator} /> : null}
    </div>
  );
};
