import moment from 'moment';
import { Table, TableProps } from 'antd';
import { DataType, sampleData } from './helper';

import styles from './styles.module.scss';

export const TableModal = () => {
  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Listing',
      dataIndex: 'listing',
      key: 'listing',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.listing;
          const b = record2.listing;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: {
        compare: (record1, record2) => {
          const a = moment(record1.date, 'MMMM DD');
          const b = moment(record2.date, 'MMMM DD');
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      sorter: {
        compare: (record1, record2) => {
          const a = parseInt(record1.value.replace(/[^0-9.]/g, ''));
          const b = parseInt(record2.value.replace(/[^0-9.]/g, ''));
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Agent',
      dataIndex: 'agent',
      key: 'agent',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.agent;
          const b = record2.agent;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.client;
          const b = record2.client;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
  ];
  return (
    <div className={styles.tableContainer}>
      <Table
        className={styles.statsTable}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              console.log(e, record, rowIndex);
              //   setRowValue(record);
            },
          };
        }}
        columns={columns}
        dataSource={sampleData}
        pagination={false}
      />
    </div>
  );
};
