import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import smallZero from './images/small_0.png';
import smallZero2x from './images/small_0@2x.png';
import smallOne from './images/small_1.png';
import smallOne2x from './images/small_1@2x.png';
import smallOneHalf from './images/small_1_half.png';
import smallOneHalf2x from './images/small_1_half@2x.png';
import smallTwo from './images/small_2.png';
import smallTwo2x from './images/small_2@2x.png';
import smallTwoHalf from './images/small_2_half.png';
import smallTwoHalf2x from './images/small_2_half@2x.png';
import smallThree from './images/small_3.png';
import smallThree2x from './images/small_3@2x.png';
import smallThreeHalf from './images/small_3_half.png';
import smallThreeHalf2x from './images/small_3_half@2x.png';
import smallFour from './images/small_4.png';
import smallFour2x from './images/small_4@2x.png';
import smallFourHalf from './images/small_4_half.png';
import smallFourHalf2x from './images/small_4_half@2x.png';
import smallFive from './images/small_5.png';
import smallFive2x from './images/small_5@2x.png';

const ratingImages = {
  0: { src: smallZero, srcSet: `${smallZero2x} 2x` },
  1: { src: smallOne, srcSet: `${smallOne2x} 2x` },
  1.5: { src: smallOneHalf, srcSet: `${smallOneHalf2x} 2x` },
  2: { src: smallTwo, srcSet: `${smallTwo2x} 2x` },
  2.5: { src: smallTwoHalf, srcSet: `${smallTwoHalf2x} 2x` },
  3: { src: smallThree, srcSet: `${smallThree2x} 2x` },
  3.5: { src: smallThreeHalf, srcSet: `${smallThreeHalf2x} 2x` },
  4: { src: smallFour, srcSet: `${smallFour2x} 2x` },
  4.5: { src: smallFourHalf, srcSet: `${smallFourHalf2x} 2x` },
  5: { src: smallFive, srcSet: `${smallFive2x} 2x` },
};

const YelpRating = ({ rating, className }) => (
  <div className={classNames(styles.wrapper, className)}>
    <img alt="yelp-rating" {...ratingImages[rating]} />
  </div>
);

YelpRating.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number,
};

YelpRating.defaultProps = {
  rating: 0,
  className: '',
};

export default YelpRating;
