import React, { useEffect } from 'react';
import {
  GetAvailableSubscriptionPlansResponse,
  SubscriptionPlanLevel,
  getAvailableSubscriptionPlans,
} from 'api/subscription';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { PaywallState, usePaymentCallbackHandler } from './usePaymentCallback';
import { PaymentFailedModal } from './PaymentFailedModal';
import { PaymentSuccessModal } from './PaymentSuccessModal';
import { PaywallModal } from './PaywallModal';
import {
  getCurrentPlanLevelSelector,
  getCurrentPlanNameSelector,
  getCurrentPlanSelector,
} from 'store/selectors/subscription';

interface PaywallProps {}

export const Paywall: React.FC<PaywallProps> = () => {
  const userSubscriptionPlanLevel = useSelector(getCurrentPlanLevelSelector);
  const userSubscriptionPlanName = useSelector(getCurrentPlanNameSelector);
  const currentActivePlan = useSelector(getCurrentPlanSelector);

  const paywallState = usePaymentCallbackHandler();

  const [availablePlan, setAvailablePlan] =
    React.useState<GetAvailableSubscriptionPlansResponse | null>(null);

  useEffect(() => {
    const runEffect = async () => {
      const response = await getAvailableSubscriptionPlans();
      const plan = response.data.result.find((plan) => plan?.Plan === userSubscriptionPlanName);

      if (plan) {
        setAvailablePlan(plan);
      }
    };

    if (userSubscriptionPlanName && userSubscriptionPlanLevel === SubscriptionPlanLevel.Basic) {
      runEffect();
    }
  }, [userSubscriptionPlanLevel, userSubscriptionPlanName]);

  if (!paywallState) {
    return null;
  }

  if (paywallState === PaywallState.Init) {
    if (userSubscriptionPlanLevel === SubscriptionPlanLevel.Premium) {
      return <Redirect to={routes.index} />;
    }

    if (!availablePlan) {
      return null;
    }
  }

  return (
    <div className={styles.paywallBackground} id="paywallModalContainer">
      {paywallState === PaywallState.Init && (
        <PaywallModal availablePlan={availablePlan} currentActivePlan={currentActivePlan} />
      )}
      {paywallState === PaywallState.Success && <PaymentFailedModal />}
      {paywallState === PaywallState.Error && <PaymentSuccessModal />}
    </div>
  );
};
