import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Wrapper = ({ children, className, testid }) => (
  <div testid={testid} className={classNames(styles.wrapper, className)}>
    {children}
  </div>
);

Wrapper.propTypes = {
  className: PropTypes.string,
  testid: PropTypes.string,
};

Wrapper.defaultProps = {
  className: '',
  testid: undefined,
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Wrapper;
