import { useParams } from 'react-router-dom';

import { FormProcessDataType } from 'types';
import { DynamicFormManager } from '../DynamicFormManager';

export const AuthDynamicForm = () => {
  const { formDocumentPublicId, formProcessPublicId } = useParams<FormProcessDataType>();

  return formDocumentPublicId && formProcessPublicId ? (
    <DynamicFormManager
      authData={{
        formDocumentPublicId,
        formProcessPublicId,
      }}
    />
  ) : (
    <></>
  );
};
