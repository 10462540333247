import { useSelector } from 'react-redux';

import { DynamicEditor, DynamicViewer } from '../Containers';
import { getDynamicFormSelector } from 'store/selectors/requestFormProcess';
import { FORM_PROCESS_SCREEN } from 'app-constants';
import { FormNotAvailableScreen } from 'pages/FormProcess/components/FormNotAvailableScreen';
import { Spin } from 'components-antd';
import { DynamicFooter } from '../DynamicFooter';
import { FormWidget } from '../Containers/DynamicViewer/components';

import styles from './styles.module.scss';
import { dynamicManager } from '../DynamicManager';

const PDFScreens = [
  FORM_PROCESS_SCREEN.Sign,
  FORM_PROCESS_SCREEN.SignPreview,
  FORM_PROCESS_SCREEN.CompletePreview,
  FORM_PROCESS_SCREEN.CancelPreview,
  FORM_PROCESS_SCREEN.SendDocument,
  FORM_PROCESS_SCREEN.DeclineSign,
]; // const

export const DynamicContainer = () => {
  const { screen, isLoading } = useSelector(getDynamicFormSelector);
  const isNotAvailableScreen = screen && screen === FORM_PROCESS_SCREEN.NotAvailable;

  const renderContainer = () => {
    if (screen) {
      if (isNotAvailableScreen) {
        return <FormNotAvailableScreen />;
      }
      if (screen === FORM_PROCESS_SCREEN.PDFEditor) {
        return <DynamicEditor />;
      } else if (PDFScreens.includes(screen as any)) {
        return <DynamicViewer />;
      }
    }
  };

  return (
    <>
      <FormWidget />
      {isLoading && (
        <div className={styles.spinner}>
          <Spin spinning />
        </div>
      )}
      {renderContainer()}

      {!isNotAvailableScreen && <DynamicFooter />}
    </>
  );
};
