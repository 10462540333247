import React from 'react';
import PropTypes from 'prop-types';

const EditCircle = ({ className, onClick }) => (
  <div testid="edit_icon" onClick={onClick} className={className}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#F4F5F6" />
      <path
        d="M10 22H13.1314L21.3515 13.78C21.7667 13.3647 22 12.8015 22 12.2143C22 11.627 21.7667 11.0638 21.3515 10.6485C20.9362 10.2333 20.373 10 19.7857 10C19.1985 10 18.6353 10.2333 18.22 10.6485L10 18.8686V22Z"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.437 11.4321L20.5684 14.5636"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

EditCircle.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

EditCircle.defaultProps = {
  className: '',
  onClick: () => {},
};

export default EditCircle;
