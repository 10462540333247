import { createAction } from 'redux-actions';

export const getAllFormProcessAction = createAction('REQUEST/GET_ALL_FORM_PROCESS');
export const getAllUnlinkFormProcessAction = createAction('REQUEST/GET_ALL_UNLINK_FORM_PROCESS');

export const getAllTransactionFormProcessAction = createAction(
  'REQUEST/GET_ALL_TRANSACTION_FORM_PROCESS',
);

export const getArchiveFormsAction = createAction('REQUEST/GET_ARCHIVE_FORMS');

export const previewFormDocumentAction = createAction('REQUEST/PREVIEW_FORM_DOCUMENT');
