import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MapControl from '../MapControl';
import Icons from '../Icons';
import { checkIsFullscreen } from './helpers';

import styles from './styles.module.scss';

const FullScreenControl = ({ map, className }) => {
  const elementToSendFullscreen = map?.getDiv()?.firstChild;

  const [isFullscreen, setIsFullscreen] = useState(false);

  const exitFullscreen = useCallback(() => {
    if (typeof document.exitFullscreen === 'function') {
      document.exitFullscreen();
    }
  }, []);

  const requestFullscreen = useCallback((element) => {
    if (!element) return;

    if (typeof element.requestFullscreen === 'function') {
      element.requestFullscreen();
    }
  }, []);

  const onClick = useCallback(() => {
    if (checkIsFullscreen(elementToSendFullscreen)) {
      exitFullscreen();
    } else {
      requestFullscreen(elementToSendFullscreen);
    }
  }, [exitFullscreen, requestFullscreen, elementToSendFullscreen]);

  const handleFullscreenChange = useCallback(
    () => setIsFullscreen((currentIsFullscreen) => !currentIsFullscreen),
    [setIsFullscreen],
  );

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, [handleFullscreenChange]);

  return (
    <MapControl
      position={window.google?.maps?.ControlPosition?.RIGHT_TOP}
      map={map}
      className={classNames(styles.fullscreenControlWrapper, className)}
    >
      <button
        className={
          isFullscreen
            ? classNames(styles.fullscreenControl, styles.isFullscreen)
            : styles.fullscreenControl
        }
        onClick={onClick}
      >
        <Icons variant={isFullscreen ? Icons.EXIT_FULLSCREEN : Icons.REQUEST_FULLSCREEN} />
      </button>
    </MapControl>
  );
};

FullScreenControl.propTypes = {
  map: PropTypes.shape({
    getDiv: PropTypes.func,
  }),
  className: PropTypes.string,
};

FullScreenControl.defaultProps = {
  map: undefined,
  className: '',
};

export default FullScreenControl;
