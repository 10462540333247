import { SVGProps } from 'react';

export const Signatory = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="3"
      y="5"
      width="18"
      height="14"
      rx="2.5"
      stroke="#AAABAB"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M3 6L11.3861 12.5225C11.7472 12.8034 12.2528 12.8034 12.6139 12.5225L21 6"
      stroke="#AAABAB"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
