import { registerSalesLead, fetchSalesLead, fetchSalesLeadSignUpUsers } from 'api/user';
import {
  registerSalesLeadAction,
  fetchSalesLeadAction,
  fetchSalesLeadSignUpUsersAction,
} from 'store/actions/salesLead';
import Api from 'store/effects/core/api';

export const registerSalesLeadEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: registerSalesLeadAction,
    method: registerSalesLead,
  });
  return sendRequest(cfg, {}, cb);
};

export const fetchSalesLeadEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: fetchSalesLeadAction,
    method: fetchSalesLead,
  });
  return sendRequest(cfg, {}, cb);
};

export const fetchSalesLeadSignUpUsersEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: fetchSalesLeadSignUpUsersAction,
    method: fetchSalesLeadSignUpUsers,
  });

  return sendRequest(cfg, {}, cb);
};
