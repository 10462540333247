import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { openNewTab } from 'services/newTab';
import { deleteCompareItemEffect } from 'store/effects';
import { Button } from 'components';
import { getComparesSelector, getMultipleModSelector } from 'store/selectors/feed';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { routes } from 'settings/navigation/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { setMultipleModeEffect, resetFullDataComparePropertiesEffect } from 'store/effects';
import { LocationService } from 'services';

import styles from './styles.module.scss';

const locationService = new LocationService();

const CompareList = ({ className }) => {
  const location = useLocation();
  const query = locationService.setLocation(location).getQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const compares = useSelector(getComparesSelector);
  const isMultipleMode = useSelector(getMultipleModSelector);
  const { isAgent } = useSelector(getUserRolesMapSelector);

  const onDeleteItem = (id) => {
    dispatch(deleteCompareItemEffect({ id }));
  };

  const getQueryParameter = () => {
    if (compares.data.length > 1) {
      let str = '';
      for (let i = 0; i < compares.data.length; i++) {
        str += compares.data[i].Id;
        if (i != compares.data.length - 1) {
          str += ',';
        }
      }
      if (query?.activeTab) {
        str += '&activeTab=' + query?.activeTab;
      }
      return str;
    } else {
      return '';
    }
  };

  const onCompare = (e) => {
    let path = routes.listings + '?ids=' + getQueryParameter();
    dispatch(setMultipleModeEffect(false));
    dispatch(resetFullDataComparePropertiesEffect());
    e.preventDefault();
    if (e.ctrlKey || e.metaKey) {
      openNewTab(path);
    } else {
      history.push(path);
    }
  };

  const onCancel = () => {
    dispatch(setMultipleModeEffect(false));
    dispatch(resetFullDataComparePropertiesEffect());
  };

  return (
    <div
      testid="compare_list"
      className={classNames(
        styles.compareList,
        { [styles.open]: compares.data.length > 1 && isMultipleMode },
        className,
      )}
    >
      <div className={styles.buttons}>
        <Button
          onClick={onCancel}
          className={styles.button}
          disabledClassName={styles.disabled}
          title="Cancel"
          testid="cancel"
        />
        <Button
          testid="compare"
          onClick={onCompare}
          className={styles.button + ' ' + (compares.data.length < 2 ? styles.hide : '')}
          title={'Compare ' + compares.data.length + ' Listings'}
        />
      </div>
    </div>
  );
};

CompareList.propTypes = {
  className: PropTypes.string,
};

CompareList.defaultProps = {
  className: '',
};

export default CompareList;
