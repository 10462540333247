import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestGetTeamListEffect } from 'store/effects';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { getUserRolesMapSelector } from 'store/selectors/user';

export const useFetchTeamList = (participantList) => {
  const dispatch = useDispatch();

  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const [loading, setLoading] = useState(true);

  const [teamMembers, setTeamMembers] = useState<
    { name: string; value: string; Email: string; Id: number; label?: string }[]
  >([]);

  const [teamMembersPayload, setTeamMembersPayload] = useState<any[]>([]);

  const fetchTeamList = async () => {
    dispatch(
      requestGetTeamListEffect({ Status: 'Accepted' }, {}, (err, resp) => {
        if (!err && resp.data?.length) {
          const activeMembers = resp.data.filter(({ IsActive }) => IsActive);
          const membersBeingParticipants = activeMembers.filter(
            ({ Id }) => participantList.findIndex((item) => item.Id === Id) !== -1,
          );

          const members = membersBeingParticipants.map((member) => {
            const label = member.FirstName + (member.LastName ? ' ' + member.LastName : '');
            return {
              name: label,
              value: label,
              Email: member.Email,
              Id: member.Id,
              label,
            };
          });
          setTeamMembersPayload(resp.data ?? []);
          setTeamMembers(members);
        }
        setLoading(false);
      }),
    );
  };

  useEffect(() => {
    if (participantList.length > 0) {
      setLoading(true);
      if (isAgent && isTeamAgentActive) {
        fetchTeamList();
      } else setLoading(false);
    }
  }, [participantList]);

  return { loading, teamMembers, teamMembersPayload };
};
