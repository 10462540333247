import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash-es';
import classNames from 'classnames';

import { FORM_QUESTION_TYPE, PDF_FIELD_TYPE } from 'app-constants';
import { Select, Row, Col } from 'components-antd';
import Next from 'pages/FormBuilder/Icons/Next';
import QuestionIcon from 'pages/FormBuilder/Icons/QuestionIcon';
import { updateSmartFormQuestionEffect } from 'store/effects/formBuilder';
import {
  getSmartFormCurrentQuestionSelect,
  getSmartFormMetaSelect,
} from 'store/selectors/formBuilder';
import { QuestionType } from 'types';

import styles from './styles.module.scss';

interface SmartOption {
  label: string;
  value: string;
}

export const SmartJumpLogicPane = () => {
  const dispatch = useDispatch();
  const { questions, disabled } = useSelector(getSmartFormMetaSelect);
  const question = useSelector(getSmartFormCurrentQuestionSelect);

  const [options, setOptions] = useState<SmartOption[]>([]);
  const [filteredQuestions, setFilteredQuestions] = useState<QuestionType[]>([]);

  useEffect(() => {
    const currentQuestionIndex = questions.findIndex((qs) => qs.UUID === question?.UUID);
    const finalQuestions = questions.slice();
    setFilteredQuestions(finalQuestions.splice(currentQuestionIndex));
  }, [question]);

  useEffect(() => {
    if (question?.Type) {
      if (question?.FieldType === PDF_FIELD_TYPE.PDFCheckBox && question.Fields?.length) {
        setOptions(question.Fields.map((f) => ({ label: f.fieldName, value: f.id })));
      } else if (
        question?.FieldType === PDF_FIELD_TYPE.PDFRadioGroup &&
        question?.Fields?.length &&
        question?.Fields[0] &&
        question?.Fields[0]?.radioOptions?.length
      ) {
        setOptions(
          question?.Fields[0]?.radioOptions.map((o) => ({ label: o.key, value: o.value })),
        );
      } else {
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  }, [question, question?.Fields]);

  const getOptions = (index) => [
    {
      label: (
        <span className={styles.questionTypeOption}>
          <Next className={styles.questionLogo} />
          Go to next page
        </span>
      ),
      key: `option-${index}-next`,
      value: null,
    },
    ...filteredQuestions
      .filter((el) => el.UUID !== question?.UUID)
      .map((el) => ({
        label: (
          <span className={styles.questionTypeOption}>
            <QuestionIcon variant={el.Type} className={styles.questionLogo} />
            {el.Name}
          </span>
        ),
        value: el.UUID,
        key: `option-${el.UUID}-next`,
      })),
  ];
  const updateJumpValue = (option: SmartOption, value: string) => {
    if (question) {
      let jumpLogic = { ...get(question, 'JumpLogic', {}) };

      if (!value && jumpLogic[option.value]) {
        delete jumpLogic[option.value];
      } else {
        jumpLogic[option.value] = value;
      }

      const updatedQuestion: QuestionType = {
        ...question,
        JumpLogic: Object.keys(jumpLogic)?.length ? jumpLogic : null,
      };

      dispatch(updateSmartFormQuestionEffect(updatedQuestion));
    }
  };
  return (
    <>
      {filteredQuestions?.length && question?.Type && (
        <div className={styles.logicPane} data-testid="smart-logic-page">
          {options.map((option, index) => (
            <Row
              className={classNames({
                [styles.choiceRowFirst]: !index,
                [styles.choiceRow]: index,
              })}
              key={option.value}
            >
              <Col span={24}>
                <label className={styles.label}>Choice &quot;{option.label}&quot;:</label>
              </Col>
              <Col span={24}>
                <Select
                  large
                  showArrow
                  getPopupContainer={(triggerNode) => triggerNode}
                  className={styles.selectBox}
                  options={getOptions(index)}
                  value={(question?.JumpLogic && question?.JumpLogic[option.value]) || null}
                  onChange={(e) => updateJumpValue(option, e)}
                  disabled={disabled}
                />
              </Col>
            </Row>
          ))}

          {question?.Type === FORM_QUESTION_TYPE.ListBox && !options?.length && (
            <div className={styles.noOptions}>
              Add options in the list box to select choices for each option
            </div>
          )}
        </div>
      )}
    </>
  );
};
