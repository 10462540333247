import { HTMLAttributes, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { Close, Hamburger, MosaikLogo } from 'components/Icons';
import { Drawer, HeaderAvatar } from 'components-antd';
import { HeaderNavLinks } from 'settings/navigation/config/components/header/routes';

import styles from './styles.module.scss';
import { VisitorNavLinks } from '../VisitorNavLinks';

interface HamburgerDrawerProps extends HTMLAttributes<HTMLDivElement> {
  hideActions: boolean;
  visitor?: boolean;
}

export const HamburgerDrawer = ({ hideActions, visitor, className }: HamburgerDrawerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpen) setIsOpen(false);
  }, [pathname]);

  const updateVisibility = () => setIsOpen(!isOpen);
  return (
    <>
      <div className={classNames(styles.hamburgerContainer, className)}>
        <Hamburger
          className={styles.iconWrap}
          data-testid="header-hamburger"
          onClick={updateVisibility}
        />
      </div>
      {isOpen && (
        <Drawer
          data-testid="header-drawer"
          open={isOpen}
          header={
            <>
              <MosaikLogo primary={false} data-testid="header-drawer-logo" />
              <Close onClick={updateVisibility} className={'prevent-user-select show-cursor'} />
            </>
          }
          footer={true}
          className={styles.headerDrawer}
        >
          <div className={styles.navLinks}>
            {!hideActions && !visitor && <HeaderNavLinks mode="vertical" />}
            {visitor && hideActions && <VisitorNavLinks inDrawer={true} />}
          </div>
          {!visitor ? (
            <div>
              <HeaderAvatar popover={false} />
            </div>
          ) : (
            <></>
          )}
        </Drawer>
      )}
    </>
  );
};
