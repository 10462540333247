import { createAction } from 'redux-actions';

export const requestGetTaskAggregate = createAction('REQUEST/GET_TASK_AGGREGATE');
export const resetTaskAggregateAction = createAction('RESET_TASK_AGGREGATE');
export const updateUnReadCommentsAction = createAction('UPDATE_TASK_AGGREGATE_READ_MESSAGES');
export const setShowCommentIconAction = createAction('SET_SHOW_COMMENT_ICON');
export const updateMultipleTasksAction = createAction('UPDATE_MULTIPLE_TASKS');
export const deleteMultipleTasksAction = createAction('DELETE_MULTIPLE_TASKS');
export const setTransactionTasksSearchAction = createAction('SET_TRANSACTIONS_TASKS_SEARCH');
export const setTransactionsTasksArchiveAction = createAction('SET_TRANSACTIONS_TASKS_ARCHIVE');
