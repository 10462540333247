import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Close as CloseIcon } from 'components/Icons';
import { Icons } from 'pages/Workshop/Transactions/Icons';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';

import styles from './styles.module.scss';

export const Controls = ({
  stage,
  setStage,
  enableShowing,
  setShowingModal,
  enableOffer,
  setOfferModal,
  onClose,
}) => {
  const onPrev = () => {
    if (isPrevDisabled()) return;

    switch (stage) {
      case transactionPreFormQuestionsIds.showingModal:
        setStage(transactionPreFormQuestionsIds.enableShowing);
        setShowingModal(false);
        break;
      case transactionPreFormQuestionsIds.offerModal:
        setStage(transactionPreFormQuestionsIds.enableOffer);
        setOfferModal(false);
        break;
    }
  };

  const onNext = () => {
    if (isNextDisabled()) return;

    switch (stage) {
      case transactionPreFormQuestionsIds.enableShowing:
        if (enableShowing === 'yes') {
          setStage(transactionPreFormQuestionsIds.showingModal);
          setShowingModal(true);
        } else {
          setStage(transactionPreFormQuestionsIds.enableOffer);
        }
        break;
      case transactionPreFormQuestionsIds.enableOffer:
        if (enableOffer === 'yes') {
          setStage(transactionPreFormQuestionsIds.offerModal);
          setOfferModal(true);
        } else {
          onClose();
        }
        break;
    }
  };

  const isPrevDisabled = () => {
    return (
      stage === transactionPreFormQuestionsIds.enableShowing ||
      stage === transactionPreFormQuestionsIds.enableOffer ||
      stage === transactionPreFormQuestionsIds.complete
    );
  };

  const isNextDisabled = () => {
    if (
      (stage === transactionPreFormQuestionsIds.enableShowing && !enableShowing) ||
      (stage === transactionPreFormQuestionsIds.enableOffer && !enableOffer) ||
      stage === transactionPreFormQuestionsIds.complete
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.controls}>
      <Icons
        variant="arrowLeft"
        className={classnames(styles.iconBtn, styles.arrowLeft, {
          [styles.disabled]: isPrevDisabled(),
        })}
        onClick={onPrev}
      />
      {/* <Icons
        variant="arrowRight"
        className={classnames(styles.iconBtn, styles.arrowRight, {
          [styles.disabled]: isNextDisabled(),
        })}
        onClick={onNext}
      /> */}
      <CloseIcon onClick={onClose} className={styles.iconBtn} />
    </div>
  );
};

Controls.prototype = {
  onClose: PropTypes.func,
  stage: PropTypes.number,
  setStage: PropTypes.func,
  enableShowing: PropTypes.string,
  setShowingModal: PropTypes.func,
  enableOffer: PropTypes.string,
  setOfferModal: PropTypes.func,
};

Controls.defaultProps = {
  onClose: () => {},
  stage: 0,
  setStage: () => {},
  enableShowing: null,
  setShowingModal: () => {},
  enableOffer: null,
  setOfferModal: () => {},
};
