import classNames from 'classnames';

import { PointCoordinate, StrikeThroughLine } from 'types';

import styles from './styles.module.scss';

interface RenderPointProps extends StrikeThroughLine {
  scale: number;
  position?: PointCoordinate;
  handleDelete: (id) => void;
  editMode?: boolean;
}

export const RenderPoint = ({
  scale,
  position,
  handleDelete,
  editMode,
  ...point
}: RenderPointProps) => {
  const { strokeWidth, color, start, end } = point;
  const fill = `rgb(${color.r},${color.g},${color.b})`;

  const getPoints = () => {
    let line: string[] = [];
    if (start && end) {
      line = [`${start.x * scale},${start.y * scale}`, `${end.x * scale},${end.y * scale}`];
    } else if (start && position?.x) {
      const positionX = position.x;
      const positionY = start.y * scale;
      line = [
        `${start.x * scale},${start.y * scale}`,
        positionX >= 0 && positionY >= 0 ? `${positionX},${positionY}` : '',
      ];
    }

    return line.filter((point) => point !== '').join(' ');
  };

  const pointData = getPoints();

  const onLineClick = (e) => {
    if (point.end) {
      e.stopPropagation();
      handleDelete(point.id);
    }
  };

  return (
    <polyline
      points={pointData}
      fill={fill}
      stroke={fill}
      strokeWidth={strokeWidth * scale}
      className={classNames({ [styles.polyLine]: editMode })}
      onClick={onLineClick}
    />
  );
};
