import { Card, Col, Footer, Row } from 'components-antd';
import { LocalHeader } from '../Activity/LocalHeader';

import styles from './styles.module.scss';
const PageWrapper = ({ Header, SubHeader, LeftSideBar, RightSideBar, Content }) => {
  return (
    <div className={styles.clarityPage}>
      <div className={styles.clarityHeaderArea}>
        <Row justify="center" align="middle">
          <Col md={24} xs={24} span={24} align="middle">
            <Header />
          </Col>

          {SubHeader && (
            <Col md={24} xs={24} span={24} align="middle">
              <SubHeader />
            </Col>
          )}
        </Row>
      </div>

      <div className={styles.containerRow}>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={5} className={styles.gutterRow}>
            <LeftSideBar />
          </Col>
          <Col xs={24} md={14} className={styles.gutterRow}>
            <Content />
          </Col>

          <Col xs={24} md={5} className={styles.gutterRow}>
            <RightSideBar />
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default PageWrapper;
