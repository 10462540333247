import { apiServer } from 'settings/web-services/api';

export function getSavedSearchById(cfg) {
  return apiServer.get(`/user/profile/savedSearches/${cfg.id}`);
}

export function updateSavedSearch(cfg) {
  const { id, ...config } = cfg;
  return apiServer.patch(`/user/profile/savedSearches/${cfg.id}`, config);
}

export function getDefaultSearchPreview() {
  return apiServer.get('/listings/defaultSearchPreview');
}
