import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { routes } from 'settings/navigation/routes';
import { useDispatch } from 'react-redux';
import { isAvailableWizardRole } from 'settings/constants/roles';
import { signupRoleEffect } from 'store/effects/loginGroup';

const useWizardRole = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.role && isAvailableWizardRole(params.role)) {
      dispatch(signupRoleEffect({ role: params.role }));
    } else {
      history.push(routes.login);
    }
  }, []); // eslint-disable-line
};

export default useWizardRole;
