import Api from 'store/effects/core/api';
import {
  getTransaction,
  getKeyDates,
  getFinacialPrice,
  getTransactionParticipant,
  getTransactionRoles,
  getTaskCategories,
  getTransactionClientAddress,
  getTransactionContacts,
  getTransactionDetails,
  getTasksForCancelledTransactionFr,
  getAllTransactionContacts,
  getTransactionPersons,
} from 'api/transactions';
import {
  getTransactionAction,
  getKeyDatesAction,
  getFinancialPricingAction,
  getTransactionParticipantAction,
  resetTransactionAction,
  getTransactionRolesAction,
  getTaskCategoriesAction,
  setTaskFiltersAppliedAction,
  getTransactionClientAddressAction,
  getTransactionContactAction,
  getTransactionDetailsAction,
  getTasksForCancelledTransactionFrAction,
  requestGetAllTransactionContactsAction,
  setTaskFiltersAction,
  requestGetTransactionPersonsAction,
} from 'store/actions/transactions';
import { getState } from 'store';
import { get } from 'lodash-es';

export const getTransactionEffect = (cfg, options, cb) => {
  const requestParams = { action: getTransactionAction, method: getTransaction };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const getKeyDatesEffect = (cfg, options, cb) => {
  const requestParams = { action: getKeyDatesAction, method: getKeyDates };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getFinancialPricing = (cfg, options, cb) => {
  const requestParams = { action: getFinancialPricingAction, method: getFinacialPrice };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getTasksForCancelledTransactionFrEffect = (cfg, options, cb) => {
  const requestParams = {
    action: getTasksForCancelledTransactionFrAction,
    method: getTasksForCancelledTransactionFr,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const getTransactionDetailsEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: getTransactionDetailsAction,
    method: getTransactionDetails,
  });
  return sendRequest(cfg, {}, cb);
};

export const getTransactionClientAddressEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: getTransactionClientAddressAction,
    method: getTransactionClientAddress,
  });
  return sendRequest(cfg, {}, cb);
};

export const resetTransactionEffect = () => (dispatch) => {
  dispatch(resetTransactionAction());
};

export const setTaskFiltersAppliedEffect = (cfg) => (dispatch) => {
  dispatch(setTaskFiltersAppliedAction(cfg));
};

export const setTaskFiltersEffect = (cfg) => (dispatch) => {
  dispatch(setTaskFiltersAction(cfg));
};

export const getTransactionParticipantEffect = (cfg, options, cb) => {
  const transactionData = get(getState(), 'transaction.data');
  const requestParams = {
    action: getTransactionParticipantAction,
    method: getTransactionParticipant,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  const config = {
    transactionId: transactionData?.Id,
    participantId: cfg?.participantId,
  };

  return sendRequest(config, options, cb);
};

export const getTransactionRolesEffect = (cfg, options, cb) => {
  const requestParams = { action: getTransactionRolesAction, method: getTransactionRoles };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const getTaskCategoriesEffect = (cfg, options, cb) => {
  const requestParams = { action: getTaskCategoriesAction, method: getTaskCategories };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const getTransactionContactsEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: getTransactionContactAction,
    method: getTransactionContacts,
  });
  return sendRequest(cfg, {}, cb);
};

export const requestGetAllTransactionContactsEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetAllTransactionContactsAction,
    method: getAllTransactionContacts,
  });
  return sendRequest(cfg, {}, cb);
};

export const requestGetTransactionPersons = (cfg, options = {}, cb) => {
  const requestParams = {
    action: requestGetTransactionPersonsAction,
    method: getTransactionPersons,
  };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, {}, cb);
};
