import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ArrowRight, ArrowLeft } from 'components/Icons';

import { getPages } from './helpers';
import styles from './styles.module.scss';

const Pagination = (props) => {
  const { onPageChange, pageAutoHandle, defaultPageNumber } = props;
  const { items, limit, pagesLimit } = props;
  const { className, pageClassName, controlClassName } = props;
  const serverSidePaginated = !Array.isArray(items);
  const itemsAmount = serverSidePaginated ? items : items.length;
  const pagesAmount = Math.ceil(itemsAmount / limit);

  const [pageIndex, setPageIndex] = useState(defaultPageNumber);
  const [pages, setPages] = useState(
    getPages(itemsAmount, limit, pagesAmount, pagesLimit, pageIndex),
  );

  const handlePageChange = useCallback(
    (newPage) => {
      if (!serverSidePaginated) {
        const firstPageItemIndex = newPage * limit;
        const newPageItems = items.slice(firstPageItemIndex, firstPageItemIndex + limit);
        if (typeof onPageChange === 'function') onPageChange(newPage, newPageItems);
      } else if (typeof onPageChange === 'function') {
        // If this is a server side pagination
        onPageChange(newPage);
      }

      setPages(getPages(itemsAmount, limit, pagesAmount, pagesLimit, newPage));
    },
    [limit, itemsAmount, onPageChange, pagesAmount, pagesLimit, items, serverSidePaginated],
  );

  const onPageClick = useCallback(
    (e, nextIndex) => {
      setPageIndex(nextIndex);
      if (!pageAutoHandle) {
        handlePageChange(nextIndex);
      }
    },
    [setPageIndex, handlePageChange, pageAutoHandle],
  );
  const onNextClick = useCallback(
    () =>
      setPageIndex((val) => {
        if (val + 1 < pagesAmount) {
          const next = val + 1;
          if (!pageAutoHandle) {
            handlePageChange(next);
          }
          return next;
        }

        if (!pageAutoHandle) {
          handlePageChange(val);
        }
        return val;
      }),
    [setPageIndex, pagesAmount, handlePageChange, pageAutoHandle],
  );
  const onPrevClick = useCallback(
    () =>
      setPageIndex((val) => {
        if (val - 1 >= 0) {
          const prev = val - 1;

          if (!pageAutoHandle) {
            handlePageChange(prev);
          }

          return prev;
        }

        if (!pageAutoHandle) {
          handlePageChange(val);
        }

        return val;
      }),
    [setPageIndex, handlePageChange, pageAutoHandle],
  );

  useEffect(() => {
    if (pageAutoHandle) {
      handlePageChange(pageIndex);
    }
  }, [pageIndex, handlePageChange, pageAutoHandle]);

  return (
    <>
      {!!itemsAmount && pages.length > 1 && (
        <div className={classNames(styles.pagination, className)}>
          <div
            className={classNames(
              styles.prevButton,
              { [styles.disabled]: pageIndex === 0 },
              controlClassName,
            )}
            onClick={onPrevClick}
          >
            <ArrowLeft className={styles.controlIcon} />
          </div>
          {pages?.map((p) => (
            <div
              key={p}
              className={classNames(
                styles.page,
                { [styles.active]: pageIndex + 1 === p },
                pageClassName,
              )}
              onClick={(e) => onPageClick(e, p - 1)}
            >
              {p}
            </div>
          ))}
          <div
            className={classNames(
              styles.nextButton,
              { [styles.disabled]: pageIndex + 1 === pages.length },
              controlClassName,
            )}
            onClick={onNextClick}
          >
            <ArrowRight className={styles.controlIcon} />
          </div>
        </div>
      )}
    </>
  );
};

Pagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  items: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.shape({}))]).isRequired,
  pagesLimit: PropTypes.number,
  className: PropTypes.string,
  pageClassName: PropTypes.string,
  controlClassName: PropTypes.string,
  pageAutoHandle: PropTypes.bool,
  defaultPageNumber: PropTypes.number,
};

Pagination.defaultProps = {
  pagesLimit: 4,
  className: '',
  pageClassName: '',
  controlClassName: '',
  pageAutoHandle: true,
  defaultPageNumber: 0,
};

export default Pagination;
