import { createSelector } from 'reselect';

const localState = ({ salesLead }) => salesLead;

export const getSalesLeadRegisterSelector = createSelector(
  localState,
  (salesLead) => salesLead.register,
);

export const getSalesLeadListSelector = createSelector(localState, (salesLead) => salesLead.list);

export const getSalesLeadSignUpUsersSelector = createSelector(
  localState,
  (salesLead) => salesLead.signupUsers,
);
