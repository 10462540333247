import { Fragment } from 'react';

import {
  AddFormCommentType,
  EditFormCommentType,
  FormCommentType,
  PDFViewerMode,
  CommentUsersType,
  FormDocumentQuestionsType,
} from 'types';
import { FormCommentPopover } from 'pages/FormProcess/components';
import { PageSizeType } from './PDFViewer';

interface RenderCommentsProps {
  mode: PDFViewerMode;
  page: number;
  scale: number;
  disabledQuestions?: FormDocumentQuestionsType;
  commentUsers?: CommentUsersType;
  formComment: FormCommentType;
  handleGetFormComments: () => void;
  handleAddFormComment: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
  getPageSize: (page: number) => PageSizeType;
}

export const RenderComments = ({
  page,
  mode,
  scale,
  commentUsers,
  formComment,
  handleGetFormComments,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
  getPageSize,
  disabledQuestions = [],
}: RenderCommentsProps) => {
  const { height: pageHeight } = getPageSize(page);

  const getPopoverPosition = (
    x: number = 0,
    y: number = 0,
    height: number,
    scale: number,
    questionUUID,
  ) => {
    const isAlsoDeferred = disabledQuestions?.some((question) => question.UUID === questionUUID);
    const fieldMid = (height * scale) / 2;
    return {
      // x * scale + halfOfAvatarWidth(16px) + 6px padding
      left: isAlsoDeferred ? x * scale + 26 : x * scale + 4,
      // pageHeight - y * scale - halfOfAvatar - halfOfFieldHeight
      top: pageHeight - y * scale - 16 - fieldMid,
    };
  };

  const fieldThreads = formComment.fieldThreads.data;

  return Object.keys(fieldThreads).length && !formComment.isPending ? (
    <Fragment key={`renderComments-${page}`}>
      {Object.keys(fieldThreads).map((field, i) => {
        const meta = fieldThreads[field].find((thread) => thread.Meta)?.Meta;
        return (
          <Fragment key={`render-field-comment-${i + 1}`}>
            <FormCommentPopover
              isNewThread={false}
              zIndex={9999}
              tooltipStyles={getPopoverPosition(
                meta?.coordinates?.x,
                meta?.coordinates?.y,
                meta?.coordinates?.height ?? 0,
                scale,
                meta?.questionUUID,
              )}
              threads={fieldThreads[field].filter(
                (thread) => thread.Meta && thread.Meta.page === page && !thread.Resolved,
              )}
              commentUsers={commentUsers}
              handleGetFormComments={handleGetFormComments}
              handleAddFormComment={handleAddFormComment}
              handleEditFormComment={handleEditFormComment}
              handleDeleteFormComment={handleDeleteFormComment}
              handleToggleResolveThread={handleToggleResolveThread}
            />
          </Fragment>
        );
      })}
    </Fragment>
  ) : (
    <></>
  );
};
