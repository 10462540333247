import { Checkbox } from 'components';

import styles from './styles.module.scss';

export const Private = ({ values, onChangeField }) => (
  <div className={styles.private}>
    <div className={styles.fieldWrapper}>
      <Checkbox
        checked={values?.IsPrivate}
        label="Private"
        labelTextClassName={styles.customLabel}
        direction={Checkbox.DIRECTION_RIGHT}
        onChange={() => onChangeField(!values?.IsPrivate, 'IsPrivate')}
      />
    </div>
  </div>
);
