import { Switch, Route } from 'react-router-dom';

import { containers } from 'settings/navigation/config/protected/formBuilder';

export const FormBuilderRouter = () => (
  <Switch>
    {containers.map(({ path, component, exact }) => (
      <Route key={path} path={path} component={component} exact={exact} />
    ))}
  </Switch>
);
