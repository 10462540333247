import React, { memo, useMemo, useState } from 'react';

interface FolderProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: Boolean;
  staticIcon?: Boolean;
  stroke?: string;
}

const Folder = memo(({ active, staticIcon, stroke = '#303030', ...props }: FolderProps) => {
  const [activeFolder, setActiveFolder] = useState(active || false);
  const { onClick } = props;
  const handleClick = (e) => {
    if (!staticIcon) {
      setActiveFolder(!activeFolder);
      if (onClick) onClick(e);
    }
  };
  return useMemo(
    () => (
      <div {...{ ...props, onClick: undefined }}>
        {activeFolder ? (
          <svg
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
          >
            <path
              d="M4 1.99998H8L11 4.99998H18C18.5304 4.99998 19.0391 5.21069 19.4142 5.58576C19.7893 5.96084 20 6.46954 20 6.99998V15C20 15.5304 19.7893 16.0391 19.4142 16.4142C19.0391 16.7893 18.5304 17 18 17H4C3.46957 17 2.96086 16.7893 2.58579 16.4142C2.21071 16.0391 2 15.5304 2 15V3.99998C2 3.46954 2.21071 2.96084 2.58579 2.58576C2.96086 2.21069 3.46957 1.99998 4 1.99998"
              stroke="#3b87ee"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
          >
            <path
              d="M4 1.99998H8L11 4.99998H18C18.5304 4.99998 19.0391 5.21069 19.4142 5.58576C19.7893 5.96084 20 6.46954 20 6.99998V15C20 15.5304 19.7893 16.0391 19.4142 16.4142C19.0391 16.7893 18.5304 17 18 17H4C3.46957 17 2.96086 16.7893 2.58579 16.4142C2.21071 16.0391 2 15.5304 2 15V3.99998C2 3.46954 2.21071 2.96084 2.58579 2.58576C2.96086 2.21069 3.46957 1.99998 4 1.99998"
              stroke={stroke}
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    ),
    [activeFolder],
  );
});

export default Folder;
