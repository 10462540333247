import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';

import { Question } from 'pages/RequestQuote/components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { Input } from 'components';

import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Continue } from '../../Continue';

import styles from './styles.module.scss';

interface ProjectNameProps {
  className?: string;
  onNext: (...arg) => void;
  currentQuestionId: string;
}

export const ProjectName = ({ className, onNext, currentQuestionId }: ProjectNameProps) => {
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);

  const onAnswerHandler = (answer) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.projectName]: answer,
      }),
    );
  };

  const onNextHandler = (event) => {
    if (currentQuestionId === transactionPreFormQuestionsIds.projectName) {
      onNext(event);
    }
  };

  return (
    <div testid="what_project_name" className={classNames(styles.container, className)}>
      <Question>What is the name of this project?</Question>
      <AnswersContainer>
        <Input
          variant={Input.LIGHT}
          placeholder="Enter project name..."
          onChange={(e, val) => onAnswerHandler(val)}
          value={preForm[transactionPreFormQuestionsIds.projectName]}
          testid="name_input"
        />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue
          onClick={onNextHandler}
          disabled={!preForm?.[transactionPreFormQuestionsIds.projectName]}
        />
      </ButtonsContainer>
    </div>
  );
};
