import React from 'react';
import styles from './styles.module.scss';
import { Spinner } from 'components';
import classNames from 'classnames';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type Props = {
  text?: string | React.ReactNode;
  isPending?: boolean;
  setIsSubmitted?: (a: boolean) => void;
  isDisabled?: boolean;
  onSubmitClick?: () => void;
};

export const FormFooter: React.FC<Props> = ({
  text,
  isPending = false,
  setIsSubmitted,
  isDisabled = false,
  onSubmitClick,
}) => {
  return (
    <>
      {text && <div className={styles.info}>{text}</div>}
      <div
        className={styles.btnContainer}
        onClick={() => {
          if (setIsSubmitted) {
            setIsSubmitted(true);
          }
          if (onSubmitClick) {
            onSubmitClick();
          }
        }}
      >
        <button
          type="submit"
          className={classNames(styles.btn, { [styles.disabled]: isPending || isDisabled })}
          disabled={isPending}
        >
          {isPending ? (
            <Spin
              className={styles.loader}
              indicator={
                <LoadingOutlined
                  color="antiquewhite"
                  width={24}
                  height={24}
                  className={styles.spinner}
                  spin
                />
              }
            />
          ) : (
            'Continue'
          )}
        </button>
      </div>
    </>
  );
};
