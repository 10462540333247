import { useSelector } from 'react-redux';

import { getDynamicFormEditorConfigSelector } from 'store/selectors/requestFormProcess';

import { getSignatoryColor } from 'app-constants';

import styles from './styles.module.scss';
import classNames from 'classnames';

export const UserRoles = (props) => {
  const { formRoles } = useSelector(getDynamicFormEditorConfigSelector);

  return (
    <div
      className={classNames(styles.stickyBottom, { [styles.bundleListSticky]: props.isBundleList })}
    >
      <div className={styles.userRoleContainer}>
        {formRoles.map((role, index) => (
          <div className={styles.userRow} key={role.roleId}>
            <span
              style={{
                backgroundColor: role.color?.border,
              }}
              className={styles.userColorBox}
            />
            <span className={styles.userTitle}>{role.roleName}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
