import { routes } from 'settings/navigation/routes';
import { Workshop } from 'pages';
import { AGENT } from 'settings/constants/roles';

export default [
  {
    path: routes.showings,
    component: Workshop,
    exact: true,
    roles: [AGENT],
  },
];
