/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { Label, Description, Items } from '../components';

import styles from './styles.module.scss';

const Must = (props) => {
  const { listItems, className, onChangePref, onUpdatePrefs, rawSearchPrefs } = props;

  return (
    <div testid="needs_block" className={classNames(styles.mustWrapper, className)}>
      <Label>Needs</Label>
      <Description>Features that are requirements or "must-haves".</Description>
      {/* TODO: Remove this check once schools is implemented in clienc invitation and onboarding */}
      {!listItems?.filter((val) => val !== 'GoodSchools')?.length ? (
        <div className={styles.noItemWrap}>
          <p className={styles.noItemContent}>You have no Needs selected</p>
        </div>
      ) : (
        <Items
          list={listItems}
          onChangePref={onChangePref}
          onUpdatePrefs={onUpdatePrefs}
          rawSearchPrefs={rawSearchPrefs}
          currentImportance={SEARCH_CRITERIA_IMPORTANCE.MUST}
        />
      )}
    </div>
  );
};

Must.propTypes = {
  className: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string),
  onChangePref: PropTypes.func.isRequired,
  rawSearchPrefs: PropTypes.object,
};

Must.defaultProps = {
  className: '',
  listItems: undefined,
};

export default Must;
