import React from 'react';
import { Switch } from 'react-router-dom';

import routesConfig from 'settings/navigation/config/protected/adminPanel/childRoutes';

import DashboardRoute from './DashboardRoute';

const DashboardRouter = () => (
  <Switch>
    {routesConfig.map(({ path, component, exact, render }) => (
      <DashboardRoute key={path} path={path} component={component} render={render} exact={exact} />
    ))}
  </Switch>
);

export default DashboardRouter;
