import classnames from 'classnames';

import { Button } from 'components-antd';

import styles from './styles.module.scss';

interface TaskSelectionFooterProps {
  className?: string;
  selectedTasks: number;
  handleContinueTaskUpdates: () => void;
  isTransactionTaskFooter?: boolean;
}

export const TaskSelectionFooter = ({
  className,
  selectedTasks,
  handleContinueTaskUpdates,
  isTransactionTaskFooter = false,
}: TaskSelectionFooterProps) => (
  <div
    className={classnames(styles.taskSelectionFooterContainer, className, {
      [styles.transactionTaskFooter]: isTransactionTaskFooter,
    })}
  >
    <div className={classnames(styles.content, 'container')}>
      <div>
        {selectedTasks} Task{selectedTasks === 1 ? '' : 's'} Selected
      </div>
      <Button
        disabled={!selectedTasks}
        className={classnames(styles.continueBtn, { [styles.disabledBtn]: !selectedTasks })}
        variant="secondary"
        onClick={handleContinueTaskUpdates}
      >
        Continue
      </Button>
    </div>
  </div>
);
