import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { getCurrentEditMessageSelector } from 'store/selectors/sockets/messages';
import { socketSetEditMessageEffect } from 'store/effects/sockets/messages';
import SendMessageFormView from './SendMessageFormView';

import styles from './styles.module.scss';

const SendMessageForm = (props) => {
  const { onSend, onCancelEdit } = props;
  const dispatch = useDispatch();
  const currentEditMessage = useSelector(getCurrentEditMessageSelector);

  const onCancelEditHandler = (...args) => {
    dispatch(socketSetEditMessageEffect({ editMessage: null }));
    onCancelEdit(...args);
  };

  const onSendHandler = (...args) => {
    if (currentEditMessage) {
      dispatch(socketSetEditMessageEffect({ editMessage: null }));
    }
    onSend(...args);
  };

  return (
    <SendMessageFormView
      {...props}
      onCancelEdit={onCancelEditHandler}
      currentEditMessage={currentEditMessage}
      onSend={onSendHandler}
      messageFieldWrapper={styles.messageFieldWrapper}
      sideBarChatView
    />
  );
};

SendMessageForm.propTypes = {
  isNewMessage: PropTypes.bool,
  className: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.shape({})]),
  subheader: PropTypes.oneOfType([PropTypes.shape({})]),
  onSend: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  attachments: PropTypes.bool,
  emoji: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
  sendButtonClassName: PropTypes.string,
  onCancelEdit: PropTypes.func,
  documentVaultUUID: PropTypes.string,
  onDefaultAttachmentUpload: PropTypes.func,
  participantsCount: PropTypes.number,
  subjectSetter: PropTypes.func.isRequired,
};

SendMessageForm.defaultProps = {
  className: '',
  sendButtonClassName: '',
  inputGroupClassName: '',
  footerClassName: '',
  content: undefined,
  subheader: undefined,
  value: '',
  autoFocus: true,
  attachments: true,
  emoji: true,
  inputPlaceholder: 'Start typing...',
  onCancelEdit: () => {},
  participantsCount: 0,
};

export default SendMessageForm;
