import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep } from 'lodash-es';

import { Close } from 'components/Icons';
import { Input, InputLabel } from 'components';

import styles from './styles.module.scss';

const Links = (props) => {
  const { className, value, placeholder, label, onChange } = props;

  const onAdd = (e) => {
    const clonedValue = cloneDeep(value);
    clonedValue.push('');
    onChange(e, clonedValue);
  };

  const onChangeHandler = (e, val, index) => {
    const clonedValue = cloneDeep(value);
    clonedValue[index] = val;
    onChange(e, clonedValue);
  };

  const onRemove = (e, index) => {
    const clonedValue = cloneDeep(value);
    clonedValue.splice(index, 1);
    onChange(e, clonedValue);
  };

  return (
    <div className={classNames(styles.links, className)}>
      <InputLabel label={label} />
      <div>
        {(value || []).map((link, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={classNames(styles.inputWrapper)}>
            <Input
              variant="light"
              placeholder={placeholder}
              value={link}
              onChange={(e, val) => onChangeHandler(e, val, index)}
              testid="link_input"
            />
            <Close onClick={(e) => onRemove(e, index)} className={styles.removeIcon} />
          </div>
        ))}
      </div>
      <div testid="add_link" onClick={onAdd} className={styles.add}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="16" fill="#F6F7F7" />
          <path
            d="M16 11V21"
            stroke="#163C5A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 16H21"
            stroke="#163C5A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className={styles.text}>Add link</div>
      </div>
    </div>
  );
};

Links.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

Links.defaultProps = {
  className: '',
  placeholder: '',
  label: '',
  value: [],
  onChange: () => {},
};

export default Links;
