import { useHistory, useLocation } from 'react-router-dom';
import { TemplateTabs } from '../components/TemplateTabs';
import { link } from 'settings/navigation/link';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRoleSelector } from 'store/selectors/user';
import { Role } from 'app-constants';
import { routes } from 'settings/navigation/routes';
import { useEffect } from 'react';
import { requestGetProjectCategories } from 'store/effects/projects';

export const TransactionTemplateCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isProject = location.pathname.includes('project');

  const userRole = useSelector(getUserRoleSelector);

  useEffect(() => {
    dispatch(requestGetProjectCategories({}, { silent: true }));
  }, []);

  const handleGoBack = (transactionId?: number) => {
    if (userRole === Role.SuperUser) {
      history.push(
        isProject
          ? link.toDashboardProjectTemplateEdit(transactionId)
          : link.toDashboardTransactionTemplateEdit(transactionId),
      );
    } else if (userRole === Role.Agent) {
      history.push(
        isProject
          ? link.toProjectTemplateEdit(transactionId)
          : link.toTransactionTemplateEdit(transactionId),
      );
    }
  };

  const handleCancel = () => {
    if (userRole === Role.SuperUser) {
      history.push(
        isProject ? routes.dashboardProjectsTemplates : routes.dashboardTransactionsTemplates,
      );
    } else if (userRole === Role.Agent) {
      history.push(isProject ? routes.templatesProjects : routes.templatesTransactions);
    }
  };

  return (
    <TemplateTabs isViewMode={false} handleGoBack={handleGoBack} handleCancel={handleCancel} />
  );
};
