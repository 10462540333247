import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MapControl from '../MapControl';

import styles from './styles.module.scss';
import Icons from '../Icons';

const Center = ({ map, className }) => {
  const onCenter = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          map.setCenter({ lat: latitude, lng: longitude });
          map.setcenter(12);
        },
        (error) => {
          console.error('Error retrieving location:', error);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, [map]);

  return (
    <MapControl
      position={window.google?.maps?.ControlPosition?.RIGHT_TOP}
      map={map}
      className={classNames(styles.centerControlsWrapper, className)}
    >
      <div className={styles.centerControls}>
        <button className={styles.control} onClick={onCenter}>
          <Icons variant={Icons.CENTER} />
        </button>
      </div>
    </MapControl>
  );
};

Center.propTypes = {
  map: PropTypes.shape({
    getcenter: PropTypes.func,
    setcenter: PropTypes.func,
  }),
  className: PropTypes.string,
};

Center.defaultProps = {
  map: undefined,
  className: '',
};

export default Center;
