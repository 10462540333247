import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'components';
import classNames from 'classnames';

import { getShowingDetails } from 'store/selectors/showingAppointment';
import { LocationDetails } from './LocationDetails';
import { AgentDetails } from './AgentDetails';
import { Notes } from './Notes';

import styles from './styles.module.scss';

export const LeftPanel = () => {
  const {
    isPending,
    SellerAgent,
    AgentBrokerage,
    DisplayBookingPageNotes,
    BookingPageNotes,
    BookingPageMessages,
    CreatedDate,
  } = useSelector(getShowingDetails);
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (document.body.scrollHeight - (window.innerHeight + window.scrollY) <= 0.5) {
        setIsScrollAtBottom(true);
      } else {
        setIsScrollAtBottom(false);
      }
    };
    window?.addEventListener('scroll', handleScroll);

    return () => {
      window?.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div
      className={classNames(styles.leftPanel, {
        [styles.bottomPadding]: !BookingPageMessages?.length && !isScrollAtBottom,
        [styles.bottomPaddingScrolledAtBottom]: !BookingPageMessages?.length && isScrollAtBottom,
        [styles.bottomPaddingNotes]: BookingPageMessages?.length && !isScrollAtBottom,
        [styles.bottomPaddingNotesScrolledAtBottom]:
          BookingPageMessages?.length && isScrollAtBottom,
      })}
    >
      {isPending ? (
        <Spinner loaderClassName={classNames(styles.loader)} />
      ) : (
        <>
          <div
            className={classNames(
              styles.detailArea,
              isScrollAtBottom
                ? styles.detailAreaScrolledAtBottom
                : styles.detailAreaScrolledNotAtBottom,
            )}
          >
            <h4>Listing Agent</h4>
            <AgentDetails SellerAgent={SellerAgent} AgentBrokerage={AgentBrokerage} />
          </div>
          {BookingPageNotes || BookingPageMessages?.length ? (
            <div className={styles.notesArea}>
              <h4>Notes</h4>
              <div className={styles.notes}>
                {BookingPageMessages?.map((b, index) => (
                  <Notes
                    key={index}
                    BookingPageNotes={b?.Message}
                    CreatedDate={b?.CreatedDate}
                    SellerAgent={SellerAgent}
                  />
                ))}
                {DisplayBookingPageNotes && (
                  <Notes
                    BookingPageNotes={BookingPageNotes}
                    CreatedDate={CreatedDate}
                    SellerAgent={SellerAgent}
                  />
                )}
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
