import { SVGProps } from 'react';

export const StrokesIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="4" y="16" width="16" height="4" fill="#262626" />
    <rect x="4" y="10" width="16" height="3" fill="#262626" />
    <rect x="4" y="5" width="16" height="2" fill="#262626" />
  </svg>
);
