/* eslint-disable react/no-unescaped-entities */
import WishlistModal from './WishlistModal';
import styles from './styles.module.scss';

import { Checkbox } from 'components';
import { Col } from 'components-antd';
import classNames from 'classnames';
import { cloneDeep } from 'lodash-es';

const Keywords = ({ keywords, setKeywords }) => {
  return (
    <>
      {keywords.map(({ Keyword: id, ImportanceAndWeight: { Importance } }, index) => {
        const checked = Importance === 'Must';
        return (
          <Col xs={24} sm={12} className={styles.optionHolder}>
            <Checkbox
              key={id}
              name={id}
              label={id}
              direction={Checkbox.DIRECTION_RIGHT}
              className={classNames(styles.checkboxOption, {
                [styles.checked]: checked,
                [styles.offsetLeft]: index % 2 !== 0,
              })}
              labelClassName={styles.label}
              labelTextClassName={classNames(styles.labelText)}
              onChange={(_, val, checked) => {
                const clone = cloneDeep(keywords);
                clone[index] = {
                  ...clone[index],
                  ImportanceAndWeight: {
                    Importance: checked ? 'Must' : 'Somewhat',
                  },
                };
                setKeywords(clone);
              }}
              checkboxWrapperClassName={styles.checkboxWrapper}
              checkboxClassName={classNames(styles.checkbox, {
                [styles.unchecked]: !checked,
              })}
              checkmarkClassName={classNames(styles.checkmark, {
                [styles.unchecked]: !checked,
              })}
              checked={checked}
              value={id}
              hasOuterClick={true}
              checkboxColor="#fff"
            />
          </Col>
        );
      })}
      <WishlistModal
        onNext={(val) => {
          let newKeywords = cloneDeep(keywords);
          Object.values(val).forEach((id) => {
            if (id !== '') {
              newKeywords.push({
                Keyword: id,
                ImportanceAndWeight: {
                  Importance: 'Somewhat',
                },
              });
            }
          });
          setKeywords(newKeywords);
        }}
        title="What else would you like to add?"
      />
    </>
  );
};

export default Keywords;
