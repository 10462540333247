import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Counter, InputLabel } from 'components';

import Remove from './Remove';
import Add from './Add';

import styles from './styles.module.scss';

const ParkingSpaces = (props) => {
  const { className, onChange, value, disabled } = props;

  return (
    <div testid="parking_spaces" className={classNames(styles.parkingSpaces, className)}>
      <InputLabel label="Parking Spaces" />
      <Counter
        RemoveComponent={Remove}
        AddComponent={Add}
        onChange={onChange}
        holderClassName={styles.holder}
        numberClassName={styles.count}
        limit={{ min: 0 }}
        postfix="+"
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

ParkingSpaces.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
};

ParkingSpaces.defaultProps = {
  className: '',
  onChange: () => {},
  value: '',
  disabled: false,
};

export default ParkingSpaces;
