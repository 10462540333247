import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import { getAllTransactionFormProcessAction } from 'store/actions/formProcess';
import {
  getTransactionAction,
  getKeyDatesAction,
  getFinancialPricingAction,
  updateKeyDatesAction,
  getTransactionClientAddressAction,
  updateTransactionAction,
  resetTransactionAction,
  createTransactionAction,
  updateTransactionStatusAction,
  inviteTransactionParticipantAction,
  removeTransactionParticipantAction,
  updateTransactionMilestonesAction,
  deleteTransactionTaskAction,
  updateMilestoneStatusAction,
  uploadTransactionTaskDocumentsAction,
  updateTransactionTaskAction,
  createTransactionTaskAction,
  deleteTransactionTaskDocumentAction,
  createTransactionContactAction,
  getTransactionContactAction,
  getTransactionDetailsAction,
  getTasksForCancelledTransactionFrAction,
  updateUnReadNotesForTransactionAction,
  updateTransactionStatsAction,
} from 'store/actions/transactions';

const initialData = {
  state: IDLE,
  updateState: IDLE,
  data: null,
  meta: {},
  address: null,
  allTransactionFormProcesses: [],
  clientAddress: {
    state: IDLE,
    data: [],
  },
  contacts: [],
  details: [],
  cancellationTasks: [],
  keyDates: {},
  financialPricing: [],
  stats: null,
};

export default handleActions(
  {
    [getTransactionAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
      address: get(payload, 'data.result.Property.Address', state.address),
    }),
    [getKeyDatesAction]: (state, { payload }) => ({
      ...state,
      keyDates: get(payload, 'data.result', state.keyDates),
    }),
    [getFinancialPricingAction]: (state, { payload }) => ({
      ...state,
      financialPricing: payload?.data?.result || [],
    }),
    [updateKeyDatesAction]: (state, { payload }) => ({
      ...state,
      keyDates: get(payload, 'data.result', state.keyDates),
    }),
    [getTasksForCancelledTransactionFrAction]: (state, { payload }) => ({
      ...state,
      cancellationTasks: get(payload, 'data.result', initialData.cancellationTasks),
    }),
    [getTransactionDetailsAction]: (state, { payload }) => ({
      ...state,
      details: get(payload, 'data.result', state.details),
    }),
    [getTransactionClientAddressAction]: (state, { payload }) => ({
      ...state,
      clientAddress: {
        state: payload.state,
        data: get(payload, 'data.result', initialData.clientAddress.data),
      },
    }),
    [createTransactionAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [updateTransactionAction]: (state, { payload }) => ({
      ...state,
      updateState: payload.state,
      data: get(payload, 'data.result', state.data),
      meta: get(payload, 'meta', initialData.meta),
      address: get(payload, 'data.result.Property.Address', state.address),
    }),
    [deleteTransactionTaskDocumentAction]: (state, { payload }) => ({
      ...state,
      updateState: payload.state,
      data: get(payload, 'data.result', state.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [inviteTransactionParticipantAction]: (state, { payload }) => ({
      ...state,
      updateState: payload.state,
      data: get(payload, 'data', state.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [updateTransactionStatusAction]: (state, { payload }) => {
      const Status = get(payload, 'data.result.Status', []);
      return {
        ...state,
        updateState: payload.state,
        data: {
          ...state.data,
          Status,
        },
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [removeTransactionParticipantAction]: (state, { payload }) => {
      const participants = get(payload, 'data.result.Participants', []);
      return {
        ...state,
        updateState: payload.state,
        data: {
          ...state.data,
          Participants: participants,
        },
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [updateTransactionMilestonesAction]: (state, { payload }) => ({
      ...state,
      updateState: payload.state,
      data: get(payload, 'data.result', state.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [updateMilestoneStatusAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result', state.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [deleteTransactionTaskAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', state.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [uploadTransactionTaskDocumentsAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result', state.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [createTransactionTaskAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result', state.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [updateTransactionTaskAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result', state.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [resetTransactionAction]: (state) => ({
      ...initialData,
      address: get(state, 'address', state.address),
    }),
    [appLogoutAction]: () => initialData,
    [getAllTransactionFormProcessAction]: (state, { payload }) => ({
      ...state,
      state: payload?.state,
      allTransactionFormProcesses: get(
        payload,
        'data.value',
        initialData.allTransactionFormProcesses,
      ),
    }),
    [createTransactionContactAction]: (state, { payload }) => ({
      ...state,
      contacts: get(payload, 'data.result', state.contacts),
    }),
    [getTransactionContactAction]: (state, { payload }) => ({
      ...state,
      contacts: get(payload, 'data.result', state.contacts),
    }),
    [updateUnReadNotesForTransactionAction]: (state, { payload }) => {
      const { transactionId, userId } = payload;
      const updatedData = [...(state.data || [])];

      const transactionToUpdate = updatedData.find(
        (transaction) => transaction.Id === transactionId,
      );

      if (transactionToUpdate) {
        transactionToUpdate.Notes = (transactionToUpdate.Notes || []).map((note) => {
          note.MessageReceipts = (note.MessageReceipts || []).map((messageReceipt) => {
            if (messageReceipt.UserId === userId) {
              messageReceipt.IsRead = true;
            }
            return messageReceipt;
          });
          return note;
        });
      }

      return { ...state, data: updatedData };
    },
    [updateTransactionStatsAction]: (state, { payload }) => ({
      ...state,
      stats: payload,
    }),
  },
  initialData,
);
