import { useState, useEffect, Fragment, FC } from 'react';
import { cloneDeep } from 'lodash-es';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { openAddEditSchoolDrawerEffect, setRawPrefDataEffect } from 'store/effects';
import { CREATE } from 'settings/constants/mode';
import {
  LAYOUT_OPTIONS,
  KITCHEN_FEATURES,
  FLOORING,
  OUTDOOR_SPACE,
  BUILDING_COMMUNITY_AMENITIES,
  NEIGHBORHOOD,
  PREF_ITEMS,
  ITEMS_WITH_ADD_MORE_OPTION,
  prefsIds,
} from 'settings/constants/preferences';
import { Accordion } from 'components';
import { Label, Description, Items } from '../components';
import { getSchoolDataSelector } from 'store/selectors/searchResults';

import styles from './styles.module.scss';
import { SchoolDrawer } from 'pages/Properties/SearchResults/Drawers/SchoolDrawer';

const schoolDataFactory = ({ Id, Name }, type) => {
  return {
    Id,
    Name,
    displayName:
      type?.toLowerCase() === 'nonspecificconfig' ? Name + ' Schools' : Name?.split(',')[0],
    type,
  };
};

interface Props {
  className?: string;
  listItems?: string[];
  onChangePref: (...props) => any;
  rawSearchPrefs?: { [key: string]: any };
  setPreferenceForSchools?: (schoolData: any) => void;
}

const NoPrefs: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const schoolData = useSelector(getSchoolDataSelector);

  const { listItems, className = '', onChangePref, rawSearchPrefs } = props;
  const [prefs, setPrefs] = useState(cloneDeep(PREF_ITEMS));

  useEffect(() => {
    const updatedPrefs = cloneDeep(PREF_ITEMS);
    listItems?.map((item) => {
      if (Object.values(LAYOUT_OPTIONS).includes(item)) {
        updatedPrefs.layout.itemsList.push(item);
        updatedPrefs.layout.itemsList.sort();
      } else if (Object.values(KITCHEN_FEATURES).includes(item)) {
        updatedPrefs.kitchen_features.itemsList.push(item);
        updatedPrefs.kitchen_features.itemsList.sort();
      } else if (Object.values(FLOORING).includes(item)) {
        updatedPrefs.flooring.itemsList.push(item);
        updatedPrefs.flooring.itemsList.sort();
      } else if (Object.values(OUTDOOR_SPACE).includes(item)) {
        updatedPrefs.outdoor_space.itemsList.push(item);
        updatedPrefs.outdoor_space.itemsList.sort();
      } else if (Object.values(BUILDING_COMMUNITY_AMENITIES).includes(item)) {
        updatedPrefs.amenities.itemsList.push(item);
        updatedPrefs.amenities.itemsList.sort();
      } else if (Object.values(NEIGHBORHOOD).includes(item)) {
        updatedPrefs.neighborhood.itemsList.push(item);
        updatedPrefs.neighborhood.itemsList.sort();
      } else if (typeof item !== 'string') {
        updatedPrefs.schools.itemsList.push(item);
        updatedPrefs.schools.itemsList.sort();
      } else {
        updatedPrefs.others.itemsList.push(item);
        updatedPrefs.others.itemsList.sort();
      }
    });
    setPrefs(updatedPrefs);
  }, [listItems]);

  const handleAddItem = (title: string) => {
    dispatch(openAddEditSchoolDrawerEffect({ open: true, mode: CREATE }));
  };
  const handleAddSchool = (schoolData) => {
    const prefCopy = cloneDeep(prefs);
    let allSchools: any[] = [];
    const { SpecificSchools, SpecificDistricts, NonSpecificConfig } = schoolData || {};

    if (NonSpecificConfig?.length) {
      const data = NonSpecificConfig.map((type) =>
        schoolDataFactory({ Id: type, Name: type }, 'NonSpecificConfig'),
      );
      allSchools = [...allSchools, ...data];
    }
    if (SpecificSchools?.length) {
      const data = SpecificSchools.map((data) => schoolDataFactory(data, 'SpecificSchools'));
      allSchools = [...allSchools, ...data];
    }
    if (SpecificDistricts?.length) {
      const data = SpecificDistricts.map((data) => schoolDataFactory(data, 'SpecificDistricts'));
      allSchools = [...allSchools, ...data];
    }

    prefCopy.schools.itemsList = allSchools;
    setPrefs(prefCopy);
    props?.setPreferenceForSchools?.(allSchools);
  };

  return (
    <>
      <div testid="no_prefs_block" className={classNames(styles.noPrefsWrapper, className)}>
        <Label>No Preference</Label>
        <Description>
          Features that are not important or for which you have no preference.
        </Description>
        {!listItems || !listItems.length ? (
          <div className={styles.noItemWrap}>
            <p className={styles.noItemContent}>You have no item in No Preference</p>
          </div>
        ) : (
          Object.keys(prefs)?.map((elem, idx) => {
            if (elem === 'others') {
              return (
                <Items
                  list={prefs[elem]?.itemsList}
                  onChangePref={onChangePref}
                  currentImportance={SEARCH_CRITERIA_IMPORTANCE.UNDEFINED}
                  rawSearchPrefs={rawSearchPrefs}
                  key={idx}
                />
              );
            } else {
              return (
                <Accordion
                  className={styles.accordion}
                  title={
                    <Fragment>
                      {prefs[elem]?.itemTitle}{' '}
                      <span className={styles.count}>({prefs[elem]?.itemsList?.length})</span>
                    </Fragment>
                  }
                  key={idx}
                  titleClassName={styles.accordionTitle}
                >
                  <Items
                    list={prefs[elem]?.itemsList}
                    title={prefs[elem]?.itemTitle}
                    showAddMoreOption={ITEMS_WITH_ADD_MORE_OPTION.includes(prefs[elem]?.itemTitle)}
                    onClickAddItem={() => handleAddItem(prefs[elem]?.itemTitle)}
                    onChangePref={onChangePref}
                    currentImportance={SEARCH_CRITERIA_IMPORTANCE.UNDEFINED}
                    rawSearchPrefs={rawSearchPrefs}
                  />
                </Accordion>
              );
            }
          })
        )}
      </div>
      <SchoolDrawer onAddSchool={handleAddSchool} />
    </>
  );
};

export default NoPrefs;
