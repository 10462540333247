import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LocationService } from 'services';

const locationSrv = new LocationService();

export const usePaymentCallbackHandler = () => {
  const location = useLocation();
  locationSrv.setLocation(location);

  const [paymentResult, setPaymentResult] = React.useState<PaywallState | null>(null);

  useEffect(() => {
    const query = locationSrv.getQuery();

    if (query.canceled) {
      setPaymentResult(PaywallState.Error);
    } else if (query.success) {
      setPaymentResult(PaywallState.Success);
    } else {
      setPaymentResult(PaywallState.Init);
    }
  }, [location.search, location.state]);

  return paymentResult;
};

export enum PaywallState {
  Init = 'init',
  Success = 'success',
  Error = 'error',
}
