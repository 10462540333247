import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { getTransactionFolderAction } from 'store/actions/transactions/folder/read';

const initialState = {
  data: null,
  state: IDLE,
  TeamId: null,
  BrokerageId: null,
  Owner: {
    AvatarUrl: null,
    FirstName: '',
    LastName: '',
  },
  Title: '',
  TransactionId: null,
};

export default handleActions(
  {
    [getTransactionFolderAction]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        folders: get(payload, 'data.result'),
      },
    }),
  },
  initialState,
);
