import Api from 'store/effects/core/api';
import { getProjectCategories, getProjectOwners } from 'api/projectCategories';
import {
  requestGetProjectCategoriesAction,
  requestGetProjectOwnersAction,
} from 'store/actions/projects';

export const requestGetProjectCategories = (cfg, options = {}, cb) => {
  const requestParams = { action: requestGetProjectCategoriesAction, method: getProjectCategories };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, {}, cb);
};
