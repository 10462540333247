import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { notificationVariants } from 'settings/constants/notifications';
import styles from './styles.module.scss';

const Icons = (props) => {
  const { className, variant } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.InApp: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 6H7C6.46957 6 5.96086 6.21071 5.58579 6.58579C5.21071 6.96086 5 7.46957 5 8V18C5 18.5304 5.21071 19.0391 5.58579 19.4142C5.96086 19.7893 6.46957 20 7 20H17C17.5304 20 18.0391 19.7893 18.4142 19.4142C18.7893 19.0391 19 18.5304 19 18V14"
              stroke="#ADADAD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 10C19.6569 10 21 8.65685 21 7C21 5.34315 19.6569 4 18 4C16.3431 4 15 5.34315 15 7C15 8.65685 16.3431 10 18 10Z"
              stroke="#ADADAD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.Sms: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 20.0015L4.3 16.1015C3.17644 14.4398 2.76999 12.472 3.15622 10.5638C3.54244 8.65573 4.69506 6.93722 6.39977 5.72782C8.10447 4.51842 10.2453 3.90044 12.4241 3.98878C14.6029 4.07713 16.6715 4.86578 18.2453 6.2081C19.819 7.55043 20.7909 9.35509 20.9801 11.2865C21.1693 13.218 20.563 15.1448 19.2739 16.7087C17.9848 18.2726 16.1007 19.3672 13.9718 19.7889C11.8429 20.2106 9.6142 19.9309 7.7 19.0015L3 20.0015"
              stroke="#ADADAD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 12V12.01"
              stroke="#ADADAD"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 12V12.01"
              stroke="#ADADAD"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 12V12.01"
              stroke="#ADADAD"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.Email: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
              stroke="#ADADAD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 7L12 13L21 7"
              stroke="#ADADAD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ArrowTop: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 15L12 9L18 15"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default:
        return null;
    }
  };

  return <div className={classNames(styles.icons, className)}>{getIcons()}</div>;
};

Icons.InApp = notificationVariants.InApp;
Icons.Sms = notificationVariants.Sms;
Icons.Email = notificationVariants.Email;
Icons.ArrowTop = 'arrowTop';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
};

export default Icons;
