import { handleActions } from 'redux-actions';

import {
  changeQuoteActivityDrawerParamsAction,
  openQuoteActivityDrawerAction,
} from 'store/actions/drawers/viewQuoteActivity';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  open: false,
  params: undefined,
};

export default handleActions(
  {
    [openQuoteActivityDrawerAction]: (state, { payload }) => ({
      ...initialData,
      open: !!payload.open,
      params: payload.params,
    }),
    [changeQuoteActivityDrawerParamsAction]: (state, { payload }) => ({
      ...state,
      params: payload.params,
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
