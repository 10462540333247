import { Button, Modal } from 'components-antd';
import { ServiceIconTypes, ServiceIcons } from 'pages/Services/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openLocationModalEffect } from 'store/effects/concierge';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { ServiceLocation } from '../ServiceLocation';

import styles from './styles.module.scss';

export const LocationModal: React.FC = () => {
  const dispatch = useDispatch();
  const { openLocationModal, areasOfOperation } = useSelector(getConciergeSearchSelector);

  const handleClose = () => {
    dispatch(openLocationModalEffect(false));
  };
  return (
    <Modal
      title={<ServiceIcons icon={ServiceIconTypes.filledLocation} className={styles.modalIcon} />}
      open={openLocationModal || false}
      destroyOnClose={true}
      footer={null}
      closable={false}
      width={675}
      onCancel={handleClose}
      className={styles.modalContainer}
    >
      <span className={styles.modalTitle}>In what area(s) would you like to search?</span>
      <span className={styles.modalSubTitle}>{'You can change this anytime.'}</span>
      <ServiceLocation className={styles.locationInput} />
      <Button
        disabled={false}
        onClick={handleClose}
        className={styles.modalButton}
        variant={'secondary'}
      >
        Continue
      </Button>
    </Modal>
  );
};
