import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { QuoteModal } from 'pages/RequestQuote/components';
import { requestGetPartnersListByCategoryIdEffect } from 'store/effects/partners';

import { getRequestQuoteSelector } from 'store/selectors/requestQuote';
import PartnersView from './PartnersView';

import styles from './styles.module.scss';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { useParams } from 'react-router-dom';

const PartnersModal = (props) => {
  const { className, isOpen, onClose } = props;
  const { quoteData } = useSelector(getRequestQuoteSelector);
  const { areasOfOperation } = useSelector(getConciergeSearchSelector);

  const dispatch = useDispatch();
  const params = useParams();

  const { categoryId } = params;

  useEffect(() => {
    if (isOpen) {
      dispatch(
        requestGetPartnersListByCategoryIdEffect({
          categories: [categoryId],
          ...(areasOfOperation?.length !== 0 && { areasOfOperation }),
        }),
      );
    }
  }, [isOpen, dispatch, categoryId]);

  const getTitle = () => `Add ${quoteData?.Category?.Title}`;

  return (
    <QuoteModal
      title={getTitle()}
      isOpen={isOpen}
      onClose={onClose}
      className={classNames(styles.partnersModal, className)}
      contentClassName={styles.modalContent}
      testid="add_partner_modal"
    >
      <PartnersView onDone={onClose} />
    </QuoteModal>
  );
};

PartnersModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

PartnersModal.defaultProps = {
  className: '',
  isOpen: false,
  onClose: () => {},
};

export default PartnersModal;
