import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MatchUnknown = memo(({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_9005_33720)">
          <path
            d="M12 19.1602V19.1745"
            stroke="#AAABAB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 14.5C11.9736 14.035 12.0991 13.2217 12.3574 12.8342C12.6157 12.4467 12.993 12.1536 13.4323 11.9991C13.9707 11.7933 14.4539 11.4652 14.8439 11.0409C15.234 10.6165 15.5202 10.1074 15.68 9.55362C15.8399 8.99985 15.869 8.41653 15.7651 7.84959C15.6612 7.28265 15.4271 6.74756 15.0813 6.28646C14.7355 5.82535 14.2873 5.45082 13.7722 5.19234C13.257 4.93386 12.6889 4.79849 12.1125 4.79689C11.5361 4.79529 10.9672 4.92751 10.4506 5.18312C9.93401 5.43874 9.4838 5.81079 9.13542 6.26997"
            stroke="#AAABAB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_9005_33720">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
});

MatchUnknown.propTypes = {
  className: PropTypes.string,
};

MatchUnknown.defaultProps = {
  className: '',
};

export default MatchUnknown;
