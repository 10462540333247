import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Wrapper, Checkbox } from 'components';
import { pushOrRemove } from 'helpers';

import styles from './styles.module.scss';

const Answers = (props) => {
  const {
    className,
    onChange,
    value,
    multiple,
    disabled,
    cases,
    valueRequired,
    pending,
    testid,
    displayOptionsInline,
    direction,
    color,
    checkboxOptionClassname,
    caseClassname,
    classNameHolder,
  } = props;
  const {
    unselectable,
    caseClassName,
    activeCaseClassName,
    labelClassName,
    loaderWrapperClassName,
    variant,
    subOption,
  } = props;

  const [answers, setAnswers] = useState(value?.filter((v) => !!v));

  useEffect(() => setAnswers(value), [value]);

  const onClick = useCallback(
    (answerId) => {
      const newAnswers = pushOrRemove({ arr: answers, id: answerId, multiple });

      setAnswers(newAnswers);

      if (valueRequired && newAnswers.length === 0 && !unselectable) {
        setAnswers([...answers]);
        return onChange([...answers]);
      }

      onChange(newAnswers);
    },
    [onChange, answers, multiple, valueRequired, unselectable],
  );

  const renderCases = () =>
    cases.map(({ id, label }) => {
      const activeCase = answers?.includes(id);

      if (variant === Answers.CHECKBOX) {
        return (
          <>
            <Checkbox
              key={id}
              name={name}
              label={label}
              direction={Checkbox.DIRECTION_RIGHT}
              className={classNames(checkboxOptionClassname, styles.checkboxOption, {
                [styles.checked]: activeCase,
                [styles[color]]: activeCase && color,
              })}
              labelClassName={styles.label}
              labelTextClassName={classNames(styles.labelText)}
              onChange={() => onClick(id)}
              checkboxWrapperClassName={styles.checkboxWrapper}
              checkboxClassName={classNames(styles.checkbox, { [styles.unchecked]: !activeCase })}
              checkmarkClassName={classNames(styles.checkmark, { [styles.unchecked]: !activeCase })}
              checked={activeCase}
              value={label}
              testid={testid}
              isPending={activeCase && pending}
              hasOuterClick={true}
              checkboxColor="#fff"
            />
            {subOption(id)}
          </>
        );
      }

      return (
        <div
          key={id}
          onClick={() => onClick(id)}
          className={classNames(
            caseClassname,
            styles.case,
            {
              [styles.active]: activeCase,
              [activeCaseClassName]: activeCase,
              [styles[color]]: activeCase && color,
            },
            caseClassName,
          )}
          testid={testid}
        >
          <div className={styles.caseInner}>
            <Wrapper
              className={classNames(styles.loaderWrapper, loaderWrapperClassName)}
              isPending={activeCase && pending}
              loaderClassName={styles.loader}
            >
              <p className={classNames(styles.label, labelClassName)}>{label}</p>
            </Wrapper>
          </div>
        </div>
      );
    });

  return (
    <div
      className={classNames(
        styles.wrapper,
        { [styles.disabled]: disabled },
        styles[variant],
        className,
      )}
    >
      <div
        className={classNames(
          classNameHolder,
          styles.holder,
          {
            [styles.holderColumn]: direction === 'column',
          },
          {
            [styles.holderRow]: direction === 'row',
          },
          {
            [styles.holderMultiple]: displayOptionsInline,
          },
        )}
      >
        {renderCases()}
      </div>
    </div>
  );
};

Answers.FULL_LIGHT = 'fullLight';
Answers.LIGHT = 'light';
Answers.CHECKBOX = 'checkBox';

Answers.propTypes = {
  displayOptionsInline: PropTypes.bool,
  className: PropTypes.string,
  activeCaseClassName: PropTypes.string,
  caseClassName: PropTypes.string,
  loaderWrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
  cases: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ).isRequired,
  valueRequired: PropTypes.bool,
  pending: PropTypes.bool,
  testid: PropTypes.string,
  unselectable: PropTypes.bool,
  variant: PropTypes.string,
  subOption: PropTypes.func,
  direction: PropTypes.string,
  color: PropTypes.string,
  checkboxOptionClassname: PropTypes.string,
  caseClassname: PropTypes.string,
  classNameHolder: PropTypes.string,
};

Answers.defaultProps = {
  displayOptionsInline: false,
  className: '',
  direction: 'column',
  activeCaseClassName: '',
  labelClassName: '',
  caseClassName: '',
  classNameHolder: '',
  onChange: () => {},
  multiple: false,
  value: [],
  disabled: false,
  valueRequired: true,
  pending: false,
  testid: undefined,
  unselectable: false,
  variant: Answers.LIGHT,
  subOption: () => <></>,
};

export default Answers;
