import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TrashIcon } from 'components/Icons';
import Icon from 'components/CommuteForm/Icons';

import styles from './styles.module.scss';

const Commute = ({ className, onDelete, onClick, ...props }) => {
  const { Name, MaxCommuteTimeInMinutes, TransportationModePreference } = props;

  return (
    <div testid="saved_commute" onClick={onClick} className={classNames(styles.commute, className)}>
      <Icon className={styles.icon} variant={TransportationModePreference?.[0]} />
      <div className={styles.info}>
        <p testid="commute_name" className={styles.name}>
          {Name}
        </p>
        <p
          testid="commute_time"
          className={styles.commuteTime}
        >{`${MaxCommuteTimeInMinutes} minutes`}</p>
      </div>
      <TrashIcon
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      />
    </div>
  );
};

Commute.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  Name: PropTypes.string,
  MaxCommuteTimeInMinutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  TransportationModePreference: PropTypes.arrayOf(PropTypes.string),
};

Commute.defaultProps = {
  className: '',
  Name: '',
  MaxCommuteTimeInMinutes: '',
  TransportationModePreference: [],
};

export default Commute;
