import { Icons } from '../../../Icons';

import styles from '../styles.module.scss';
import { IconText } from '../IconText';

export const EditNotifications = ({ data, onEdit }) => {
  const getInfo = () => {
    const { NotifiedParticipantNames } = data;

    if (!NotifiedParticipantNames) return 'N/A';
    return NotifiedParticipantNames;
  };

  return (
    <div className={styles.editForm}>
      <IconText
        text={'Who should receive notifications regarding offers?'}
        infoText={getInfo()}
        variant={'notification-bg'}
        className={styles.editFormInfo}
      />
      <div className={styles.editIcon} onClick={onEdit}>
        <Icons variant={'edit'} />
      </div>
    </div>
  );
};
