import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { FormMeta, FormPublish } from '../components';
import { PENDING } from 'settings/constants/apiState';
import { getSmartFormMetaSelect } from 'store/selectors/formBuilder';
import { getHeaderConfig } from 'settings/navigation/config/components/header';

export const SmartFormHeader = () => {
  const { pathname } = useLocation();

  const { state } = useSelector(getSmartFormMetaSelect);

  const [previewForm, setPreviewForm] = useState(false);
  const [newPage, setNewPage] = useState(true);
  const { versionId } = useParams<{ versionId: string }>();

  useEffect(() => {
    if (versionId === 'new') {
      setNewPage(true);
    } else {
      setNewPage(false);
    }
  }, [versionId]);

  useEffect(() => {
    const config = getHeaderConfig(pathname);
    if (config) {
      setPreviewForm(!!config.previewForm);
    } else {
      if (previewForm) {
        setPreviewForm(false);
      }
    }
  }, [pathname]);

  return (
    <>
      {!(state === PENDING) && !newPage && (
        <>
          <FormMeta previewForm={previewForm} smartForm={true} />
          <FormPublish previewForm={previewForm} smartForm={true} />
        </>
      )}
    </>
  );
};
