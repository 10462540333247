import PropTypes from 'prop-types';
import { Modal } from 'components-antd';
import styles from './styles.module.scss';
import { tableColumns } from './Table/FormTableColumns';
import { ModalTable } from 'pages/Main/ClientDashboard/components/ModalTable';
import { useHistory } from 'react-router-dom';
const FormsModal = (props) => {
  const { className, isOpen, onCloseModal, formDetails, title = 'Forms To Sign' } = props;
  const history = useHistory();
  const tableProps = {
    columns: tableColumns(history),
    onRow: (record) => {
      return {
        onClick: () => {},
      };
    },
  };
  return (
    <Modal
      open={isOpen}
      width={1200}
      footer={null}
      onCancel={() => {
        onCloseModal();
      }}
      maskClosable={false}
      destroyOnClose
      className={styles.taskViewModal}
    >
      <div testid="forms_modal" className={styles.formModal}>
        <p className={styles.title}>{title}</p>
        <ModalTable dataSource={formDetails} {...tableProps} />
      </div>
    </Modal>
  );
};

FormsModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  formDetails: PropTypes.any,
};

FormsModal.defaultProps = {
  className: '',
  isOpen: false,
  onCloseModal: () => {},
};

export default FormsModal;
