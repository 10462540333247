import { useMemo, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumbs } from 'components';
import { routes } from 'settings/navigation/routes';
import { EnableShowing } from '../PreForm/Questions/EnableShowing';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import {
  getTransactionRolesEffect,
  resetTransactionCreateEffect,
} from 'store/effects/transactions';
import { ShowingModal } from 'pages/Workshop/Transactions/TransactionShowings/ShowingModal';
import { getTransactionSelector, getTransactionAccessSelector } from 'store/selectors/transaction';
import { Controls } from './Controls';
import { EnableOffer } from '../PreForm/Questions/EnableOffer';
import { OfferModal } from 'pages/Workshop/Transactions/TransactionOffers/OfferModal';
import { showSuccessMessage } from 'helpers/success';
import { link } from 'settings/navigation/link';
import Complete from '../PreForm/Questions/Complete';

import styles from './styles.module.scss';
import ContentWrapper from '../ContentWrapper';
import _ from 'lodash';

export const PostForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [stage, setStage] = useState(transactionPreFormQuestionsIds.enableShowing);
  const [enableShowing, setEnableShowing] = useState(null);
  const [enableOffer, setEnableOffer] = useState(null);
  const [showingModal, setShowingModal] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);

  const breadcrumbsList = useMemo(
    () => [
      { label: 'Transactions', link: routes.transactions },
      { label: 'New seller transaction', link: location.pathname },
    ],
    [location.pathname],
  );

  const onClose = () => {
    dispatch(resetTransactionCreateEffect());
    history.push(routes.transactionOverview.replace(':id', transaction.Id));
  };

  const isShowingEnabled = () => {
    if (enableShowing === 'yes') {
      setStage(transactionPreFormQuestionsIds.showingModal);
      setShowingModal(true);
    } else {
      setStage(transactionPreFormQuestionsIds.enableOffer);
    }
  };

  const isOfferEnabled = () => {
    if (enableOffer === 'yes') {
      setStage(transactionPreFormQuestionsIds.offerModal);
      setOfferModal(true);
    } else {
      setStage(transactionPreFormQuestionsIds.complete);
    }
  };

  const redirectToTransactionOverview = () =>
    history.push(link.toTransactionOverview(transaction?.Id));

  const enableShowingStage = () => (
    <EnableShowing enable={enableShowing} onChange={setEnableShowing} onNext={isShowingEnabled} />
  );

  const showingModalStage = () => (
    <ShowingModal
      isOpen={showingModal}
      onClose={() => {
        setShowingModal(false);
        setStage(transactionPreFormQuestionsIds.enableOffer);
      }}
      transaction={transaction}
      fullAccess={fullAccess}
    />
  );

  const enableOfferStage = () => (
    <EnableOffer enable={enableOffer} onChange={setEnableOffer} onNext={isOfferEnabled} />
  );

  const offerModalStage = () => (
    <OfferModal
      isOpen={offerModal}
      onClose={() => {
        setOfferModal(false);
        setStage(transactionPreFormQuestionsIds.complete);
      }}
      transaction={{
        ...transaction,
        ...(_.isEmpty(transaction.User) && { User: transaction.Creator }),
      }}
      fullAccess={fullAccess}
    />
  );

  const completeStage = () => <Complete onPreFormSubmit={redirectToTransactionOverview} />;

  useEffect(() => {
    showSuccessMessage('Transaction created successfully.');
    dispatch(getTransactionRolesEffect());
  }, []);

  const renderStage = () => {
    switch (stage) {
      case transactionPreFormQuestionsIds.enableShowing:
        return enableShowingStage();
      case transactionPreFormQuestionsIds.showingModal:
        return showingModalStage();
      case transactionPreFormQuestionsIds.enableOffer:
        return enableOfferStage();
      case transactionPreFormQuestionsIds.offerModal:
        return offerModalStage();
      case transactionPreFormQuestionsIds.complete:
        return completeStage();
      default:
        return enableShowingStage();
    }
  };

  return (
    <div className={styles.basicPlanCreate}>
      <div className={styles.head}>
        <Controls
          onClose={onClose}
          stage={stage}
          setStage={setStage}
          enableShowing={enableShowing}
          setShowingModal={setShowingModal}
          enableOffer={enableOffer}
          setOfferModal={setOfferModal}
        />
      </div>
      <ContentWrapper>{renderStage()}</ContentWrapper>
    </div>
  );
};
