import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const NoThreadsLabel = ({ children, className }) => (
  <div testid="no_threads" className={classNames(styles.label, className)}>
    {children}
  </div>
);

NoThreadsLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

NoThreadsLabel.defaultProps = {
  children: 'No threads',
  className: null,
};

export default NoThreadsLabel;
