import moment from 'moment';

import { Checkbox } from 'components';
import Button from 'components/Button';

import styles from './styles.module.scss';

export const CompareByYear = ({ selectedYears, setSelectedYears, showMonthsScreen }) => {
  const currentYear = moment().year();
  const options = [currentYear, currentYear - 1, currentYear - 2];

  const getRow = (year, index) => {
    return (
      <>
        <div className={styles.row}>
          <span className={styles.label}>{year}</span>
          <Checkbox
            checked={selectedYears.includes(year)}
            direction={Checkbox.DIRECTION_RIGHT}
            onChange={(e) => {
              const { checked } = e.target;
              const updatedYearsList = checked
                ? [...selectedYears, year]
                : selectedYears.filter((y) => y !== year);
              setSelectedYears(updatedYearsList);
            }}
          />
        </div>
        {index !== 2 && <div className={styles.customLine}></div>}
      </>
    );
  };
  return (
    <div className={styles.compareByYearContainer}>
      <header className={styles.title}>Select Years</header>
      {options.map((year, index) => getRow(year, index))}
      <footer className={styles.footer}>
        <Button
          title="Select Month(s)"
          disabled={!selectedYears.length}
          onClick={showMonthsScreen}
          className={styles.selectMonthsBtn}
        />
      </footer>
    </div>
  );
};
