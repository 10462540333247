import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Select, Option } from 'components-antd';
import { FORM_STATUS_TYPE } from 'app-constants';
import { deleteFormVersionModalEffect } from 'store/effects/formBuilder';
import { FormStatus } from 'types';
import {
  formStatusForActive,
  formStatusForDraft,
  formStatusForInActive,
  strokeColors,
} from './config';

import styles from './styles.module.scss';

export interface StatusDropdownProps {
  status: FormStatus;
  index: Number;
  formId: number;
  versionId: number;
  formName: string;
  onValueChange?: (key: FormStatus) => {};
  disabled?: boolean;
}

export const StatusDropdown = ({
  status,
  index,
  formId,
  versionId,
  formName,
  disabled = false,
  onValueChange,
}: StatusDropdownProps) => {
  const dispatch = useDispatch();

  const changeHandler = (key) => {
    if (key === 'Delete') {
      dispatch(deleteFormVersionModalEffect({ formId, formName, formVersionId: versionId }));
    } else {
      if (onValueChange) onValueChange(key);
    }
  };
  let statusOptions = formStatusForDraft;

  switch (status) {
    case FORM_STATUS_TYPE.Active:
      statusOptions = formStatusForActive;
      break;
    case FORM_STATUS_TYPE.InActive:
      statusOptions = formStatusForInActive;
      break;

    default:
      break;
  }

  return useMemo(
    () => (
      <span className={styles.dropdown}>
        <Select
          className={styles[`statusDropdown${status}`]}
          dropdownIconColor={strokeColors[status]}
          value={status}
          key={`status-${index}`}
          onChange={changeHandler}
          disabled={disabled}
        >
          {statusOptions.map((el) => (
            <Option key={`status-${index}-${el.value}`} value={el.value}>
              {el.key}
            </Option>
          ))}
        </Select>
      </span>
    ),
    [status],
  );
};
