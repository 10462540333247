import { useState, FC } from 'react';

import { PREF_TYPE, prefsIds } from 'settings/constants/preferences';
import { SoftCriteriaType } from '../SoftCriteriaType';
import { Panel, Collapse, Popover } from 'components-antd';
import { Add, Edit, TrashIcon as Trash, Remove } from 'components/Icons';
import { FormikProps } from 'formik';

import { Commute } from '../SoftCriteriaType/Commutes/Commute';
import MenuListItems from 'components/MenuListItems';

import styles from './styles.module.scss';

const TYPE = PREF_TYPE.commutePrefs;
const COLLAPSIBLE_OPTIONS = {
  category: [
    {
      key: prefsIds.commutes,
      title: 'Commutes',
    },
    {
      key: prefsIds.proximityToImportantLocations,
      title: 'Proximity to Important Locations',
    },
  ],
};

interface CommuteType {
  ImportanceAndWeight?: string;
  address?: string[];
  by?: string;
  id?: string;
  locationLabel?: string;
  minutes?: string;
}
interface Props {
  addSoftCriteria: (
    key: string,
    value: string,
    type: string,
    data: CommuteType,
    action: string,
  ) => void;
  formik: FormikProps<any>;
}

export const ImportantFeatures: FC<Props> = ({ addSoftCriteria, formik }) => {
  const softCriteria = formik?.values?.softCriteria;
  const commutes = softCriteria?.Commutes?.data;
  const importantLocations = softCriteria?.ProximityToImportantLocations?.data;

  const [commute, setCommute] = useState<CommuteType>({});
  const [showAddCompute, setShowAddCompute] = useState<boolean>(false);
  const [editItemType, setEditItemType] = useState<string>('');

  const getDataByType = (type: string) => {
    return type === prefsIds.commutes ? commutes : importantLocations;
  };
  const handleDeleteAction = (data: CommuteType, key: string, action: string) => {
    addSoftCriteria(key, 'Need', TYPE, data, action);
  };

  const handleAddEditAction = (
    action: 'add' | 'edit',
    show: boolean,
    data: CommuteType,
    type: string,
  ) => {
    setShowAddCompute(show);
    setCommute(action === 'add' ? {} : data);
    setEditItemType(type);
  };

  const menuItem = (data, key) => [
    {
      id: 0,
      name: 'Edit',
      icon: Edit,
      onClick: () => handleAddEditAction('edit', true, data, key),
    },
    {
      id: 1,
      name: 'Delete',
      icon: Trash,
      onClick: () => handleDeleteAction(data, key, 'delete'),
    },
  ];

  const getHeading = (data, key, title?: string) => {
    return (
      <Popover
        content={<MenuListItems items={menuItem(data, key)} />}
        trigger="hover"
        placement="bottomLeft"
        overlayClassName={styles.popoverOverlay}
        getPopupContainer={(triggerNode) => triggerNode}
      >
        {title}
      </Popover>
    );
  };

  const handleAddData = (title, itemImp, type, data, action) => {
    const ImportanceAndWeight = action === 'edit' ? commute.ImportanceAndWeight : 'Want';
    addSoftCriteria(title, itemImp, type, { ...data, ImportanceAndWeight }, action);
  };

  const updateSoftCriteriaImportance = (data, key, index) => {
    const importance = data[1];
    addSoftCriteria(key, importance, TYPE, index, 'update');
  };

  const getStateDataByType = (type) => {
    switch (type) {
      case prefsIds.commutes:
        return commutes;

      default:
        return importantLocations;
    }
  };

  return (
    <div className={styles.buyerPreferenceImportantFeatures}>
      <Collapse
        bordered={false}
        accordion
        expandIcon={({ isActive }) =>
          isActive ? <Remove color={Remove.COLLAPSE} /> : <Add color={Add.COLLAPSE} />
        }
        expandIconPosition={'end'}
        className={styles.collapseStyle}
        ghost={true}
      >
        {COLLAPSIBLE_OPTIONS.category.map((item, i) => {
          return (
            <>
              <Panel
                header={<p className={styles.heading}>{item.title}</p>}
                key={item.key}
                className={styles.softCollapse}
              >
                {getDataByType(item.key)?.map((data, i) => (
                  <SoftCriteriaType
                    key={i}
                    title={data.locationLabel}
                    icon={'commutes'}
                    importance={data?.ImportanceAndWeight}
                    softCriteria={softCriteria}
                    onClick={(...data) => updateSoftCriteriaImportance(data, item.key, i)}
                    showTitle={getHeading(data, item.key, data.locationLabel)}
                    type={TYPE}
                    showCommutes={false}
                    hideNA={true}
                  />
                ))}
                {showAddCompute && editItemType === item.key && (
                  <Commute
                    onClick={handleAddData}
                    softCriteria={softCriteria}
                    title={editItemType}
                    commuteHandler={(show: boolean, type: string) => {
                      handleAddEditAction('add', show, {}, type);
                    }}
                    showCommuteSection={(val) => setShowAddCompute(val)}
                    commute={commute}
                    type={TYPE}
                    key={i}
                  />
                )}
                {!showAddCompute && (
                  <div
                    className={styles.addAnother}
                    onClick={() => handleAddEditAction('add', true, {}, item.key)}
                  >
                    <Add className={styles.addIcon} color={Add.ORANGE_CIRCLE} />
                    <span>Add {getStateDataByType(item.key)?.length ? 'Another' : ''}</span>
                  </div>
                )}
              </Panel>
            </>
          );
        })}
      </Collapse>
    </div>
  );
};
