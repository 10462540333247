import { createAction } from 'redux-actions';

/**
 * @deprecated
 */
export const requestGetClientContextAction = createAction('REQUEST/GET_AGENTS_CONTEXT');
export const requestGetClientContextsAction = createAction('REQUEST/GET_CLIENT_CONTEXTS');
export const requestGetInActiveClientContextsAction = createAction(
  'REQUEST/GET_INACTIVE_CLIENT_CONTEXTS',
);

/**
 * @deprecated
 */
export const setSelectedContextIdAction = createAction('CONTEXT/SET_CONTEXT');
export const setCurrentContextAction = createAction('CONTEXT/SET_CONTEXT_NEW');
