import { Card } from 'antd';
import styles from './styles.module.scss';
import { ArrowRight, Clients } from 'components/Icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Icons } from 'pages/Clarity/Icons';
import { useState } from 'react';
import { ClarityPopup } from '../ClarityPopup';

export const RightSideBar = () => {
  const [clarityModel, setClarityModel] = useState(false);
  const showModel = () => {
    setClarityModel(true);
  };
  const onCloseModel = () => {
    setClarityModel(false);
  };
  return (
    <Card className={styles.cardContainers}>
      <span className={styles.drillDown}>Drill Down</span>
      <Link className={styles.clarityLink} onClick={showModel}>
        <div className={styles.cardStyles}>
          <Icons variant="clients" />
          <span className={styles.navigationText}>Clients</span>
          <ArrowRight className={styles.arrow} />
        </div>
      </Link>
      <Link className={styles.clarityLink} onClick={showModel}>
        <div className={styles.cardStyles}>
          <Icons variant="listings" />
          <span className={styles.navigationText}>Listings</span>
          <ArrowRight className={styles.arrow} />
        </div>
      </Link>
      <Link className={styles.clarityLink} onClick={showModel}>
        <div className={styles.cardStyles}>
          <Icons variant="transactions" />
          <span className={styles.navigationText}>Transactions</span>
          <ArrowRight className={styles.arrow} />
        </div>
      </Link>
      <Link className={styles.clarityLink} onClick={showModel}>
        <div className={styles.cardStyles}>
          <Icons variant="partners" />
          <span className={styles.navigationText}>Partners</span>
          <ArrowRight className={styles.arrow} />
        </div>
      </Link>
      <Link className={styles.clarityLink} onClick={showModel}>
        <div className={styles.cardStyles}>
          <Icons variant="teams" />
          <span className={styles.navigationText}>Team</span>
          <ArrowRight className={styles.arrow} />
        </div>
      </Link>
      <ClarityPopup isOpen={clarityModel} onCloseModal={onCloseModel} />
    </Card>
  );
};
