import PropTypes from 'prop-types';

import { Modal } from 'components-antd';
import { StageDocument } from 'pages/OnBoardingWizard/components';

import styles from './styles.module.scss';

export const UploadPreApprovalModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      open={isOpen}
      width={580}
      footer={null}
      onCancel={onClose}
      className={styles.preApprovalsUploadModal}
      cancelButtonStyles={styles.crossButton}
      destroyOnClose={true}
    >
      <StageDocument isModal={true} className={styles.uploadWrapper} onNext={onClose} />
    </Modal>
  );
};

UploadPreApprovalModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

UploadPreApprovalModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};
