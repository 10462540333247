import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown, Menu, MenuItem } from 'components-antd';
import { useOutsideClick } from 'hooks';
import styles from './styles.module.scss';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import moment from 'moment';

const MessageActions = (props) => {
  const { isOwnMessage, createdDate, className, onEditClick, onDeleteClick } = props;
  const [open, setOpen] = useState(true);
  const iconRef = useRef();
  const listRef = useRef();

  const shouldShowEditButton = () => {
    const messageTime = moment(createdDate);

    return isOwnMessage && messageTime.add(10, 'minutes').isAfter(moment());
  };

  const shouldShowDeleteButton = () => {
    const messageTime = moment(createdDate);

    return isOwnMessage && messageTime.add(2, 'minutes').isAfter(moment());
  };

  useOutsideClick([listRef, iconRef], () => setOpen(false));

  const onClickActions = () => {
    setOpen(!open);
  };

  const actions = [
    {
      title: 'Edit',
      icon: <Icon variant={Icon.OPTIONS_EDIT} />,
      testid: 'edit-id',
      onClick: () => {
        setOpen(false);
        onEditClick();
      },
      show: shouldShowEditButton(),
    },
    {
      title: 'Delete',
      icon: <Icon variant={Icon.OPTIONS_DELETE} />,
      testid: 'delete-id',
      onClick: () => {
        setOpen(false);
        onDeleteClick();
      },
      show: shouldShowDeleteButton(),
    },
  ];

  if (!shouldShowDeleteButton() && !shouldShowEditButton()) {
    return <></>;
  }

  const menuItems = () => (
    <Menu className={styles.messageActionMenu}>
      {actions
        .filter(({ show }) => !!show)
        .map(({ title, icon, onClick, testid, show }) => {
          return (
            <MenuItem onClick={onClick} className={styles.menuItem} key={testid} icon={icon}>
              <span className={styles.menuItemSpan}>{title}</span>
            </MenuItem>
          );
        })}
    </Menu>
  );

  return (
    <div>
      <div testid="action_dots" className={`${open ? className : ''}`}>
        <div testid="drawer_actions" className={classNames(styles.actions)}>
          <Dropdown overlay={menuItems} placement="bottomRight" onOpenChange={onClickActions}>
            <div ref={iconRef} onClick={onClickActions} className={styles.circle}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                  fill="#303030"
                  stroke="#303030"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                  fill="#303030"
                  stroke="#303030"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                  fill="#303030"
                  stroke="#303030"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

MessageActions.propTypes = {
  className: PropTypes.string,
  isOwnMessage: PropTypes.bool,
  createdDate: PropTypes.string,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

MessageActions.defaultProps = {
  className: '',
  isOwnMessage: false,
  createdDate: moment.now(),
  onEditClick: () => {},
  onDeleteClick: () => {},
};

export default MessageActions;
