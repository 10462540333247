import moment from 'moment';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { Icons } from '../../Icons';
import { Spinner } from 'components';
import { FormProcessType } from 'types';
import { AntCard, Col, Row, Text, Tabs, TabPane } from 'components-antd';
import {
  getAllFormProcessesSelector,
  getRequestFormProcessSelector,
} from 'store/selectors/requestFormProcess';
import { onHandleViewFormType, renderTooltip } from '../../Forms';
import { FormsTableStatus } from 'app-constants';

import styles from './styles.module.scss';

interface FormPageProps {
  setSelectedFormProcess?: (formProcessId: number) => void;
  handleViewForm: onHandleViewFormType;
}

export const FormPage = ({ setSelectedFormProcess, handleViewForm }: FormPageProps) => {
  const { isPending } = useSelector(getRequestFormProcessSelector);
  const { waitingOnYou, waitingOnOthers, recentlyCompletedForms } = useSelector(
    getAllFormProcessesSelector,
  );

  const renderHeader = (header: string, count: number) => (
    <div className={styles.tabtitle}>
      {header}
      <span className={styles.count}>{`(${count})`}</span>
    </div>
  );

  const renderStatus = (status: string, date: string, waitingOnYou: boolean) => (
    <div
      className={classnames(styles.status, {
        [styles.statusWarning]: waitingOnYou,
      })}
    >
      <div className={styles.labelWithIcon}>
        <Icons
          variant="exclamation"
          className={styles.warningIcon}
          stroke={waitingOnYou ? '#FF576D' : '#747475'}
        />
        <span className={styles.label}>{status}:</span>
      </div>
      <div>{moment(date).fromNow()}</div>
    </div>
  );

  const renderCard = (form: FormProcessType, isCompleted?: boolean) => {
    return (
      <Col xs={24} md={12} lg={8} key={form.formDocumentId}>
        <AntCard className={styles.card} key={form.url}>
          <div
            className={styles.formDetails}
            onClick={() => {
              if (setSelectedFormProcess) setSelectedFormProcess(form.formProcessId);
              else if (form.url && handleViewForm) {
                handleViewForm(form.url, form.type);
              }
            }}
          >
            <div className={styles.formAvatar}>
              {form.waitingOnYou ? (
                <Icons variant="warning_document" />
              ) : (
                <Icons variant="document" />
              )}
            </div>
            <div className={styles.formInfo}>
              <Text className={styles.address}>{form.formName}</Text>
              <div className={styles.labelValue}>
                {form.address && (
                  <span>
                    <Text>{form.address}</Text>
                  </span>
                )}
                {(form.waitingOnYou || isCompleted) && form.client && (
                  <span>
                    <Text className={styles.label}>Client: </Text>
                    <Text>{form.client}</Text>
                  </span>
                )}
                {renderTooltip(
                  form,
                  <>
                    {form.isEdit && renderStatus('Form Incomplete', form.date, form.waitingOnYou)}
                    {form.isSign &&
                      renderStatus(
                        form.waitingOnYou ? 'Signature Needed' : 'Pending Signatures',
                        form.date,
                        form.waitingOnYou,
                      )}
                  </>,
                )}
                {isCompleted && (
                  <span>
                    <Text className={styles.label}>Completed: </Text>
                    <Text>{moment(form.date).fromNow()}</Text>
                  </span>
                )}
              </div>
            </div>
          </div>
        </AntCard>
      </Col>
    );
  };

  return (
    <div className={styles.formPage}>
      <Tabs className={styles.workshopTabs}>
        <TabPane
          tab={renderHeader(FormsTableStatus.WaitingOnYou, waitingOnYou.length)}
          key={FormsTableStatus.WaitingOnYou}
        >
          <Row gutter={[40, 24]} className={styles.contentRow}>
            {isPending ? (
              <Spinner />
            ) : waitingOnYou.length ? (
              waitingOnYou.map((form) => renderCard(form))
            ) : (
              <Col xs={24}>
                <span className={styles.noForms}>You have no forms waiting on you</span>
              </Col>
            )}
          </Row>
        </TabPane>
        <TabPane
          tab={renderHeader(FormsTableStatus.WaitingOnOthers, waitingOnOthers.length)}
          key={FormsTableStatus.WaitingOnOthers}
        >
          <Row gutter={[40, 24]} className={styles.contentRow}>
            {isPending ? (
              <Spinner />
            ) : waitingOnOthers.length ? (
              waitingOnOthers.map((form) => renderCard(form))
            ) : (
              <Col xs={24}>
                <span className={styles.noForms}>You have no forms waiting on others</span>
              </Col>
            )}
          </Row>
        </TabPane>
        <TabPane
          tab={renderHeader(FormsTableStatus.RecentlyCompleted, recentlyCompletedForms.length)}
          key={FormsTableStatus.RecentlyCompleted}
        >
          <Row gutter={[40, 24]} className={styles.contentRow}>
            {isPending ? (
              <Spinner />
            ) : recentlyCompletedForms.length ? (
              recentlyCompletedForms.map((form) => renderCard(form, true))
            ) : (
              <Col xs={24}>
                <span className={styles.noForms}>You have no completed forms</span>
              </Col>
            )}
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};
