import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { cloneDeep, get } from 'lodash-es';

import { requestGetNewPropsAction, resetPropertiesWholeTabsAction } from 'store/actions/feed';
import { requestToggleFavoriteListingAction } from 'store/actions/listingDetail';
import { updatePropertyFavorite } from 'store/helpers';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetNewPropsAction]: (state, { payload }) => ({
      state: payload.state,
      data:
        payload?.options && payload?.options?.add
          ? [...state.data, ...get(payload, 'data.items', [])]
          : get(payload, 'data.items', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestToggleFavoriteListingAction]: (state, { payload }) => ({
      ...state,
      data: updatePropertyFavorite(state, payload),
    }),
    [resetPropertiesWholeTabsAction]: () => cloneDeep(initialData),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
