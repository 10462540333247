import { useState } from 'react';

import { Form } from 'components-antd';
import { TaskTemplate } from 'types';
import { TransactionTemplateTaskModal } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModalForm';
import TaskTemplates from 'pages/Templates/Tasks/components/TaskTemplates';
import { Modal as TemplateModal } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { getTemplateButtons } from '../TemplateButtons';
import { TaskCard } from '../TaskCard';
import { AddNew, Timeline } from 'components/Icons';
import UploadTasks from 'components/Transactions/UploadTasks';

import styles from './styles.module.scss';

export const TasksPane = ({ isViewMode, data }) => {
  return (
    <div className={styles.taskPaneContainer}>
      <Form.Item name="TaskTemplates">
        <TaskList isViewMode={isViewMode} data={data} />
      </Form.Item>
    </div>
  );
};

interface TaskListProps {
  onChange?: (e) => void;
  value?: TaskTemplate[];
  isViewMode: boolean;
  data: [];
}

const TaskList = ({ onChange, value, isViewMode, data }: TaskListProps) => {
  const [showTemplateModal, setShowTemplateModal] = useState(false);

  const [editTask, setEditTask] = useState<number | undefined>(undefined);
  const [showTaskModal, setShowTaskModal] = useState(false);

  const onRemoveTask = (index) => {
    if (isViewMode) return;
    const result = [...(value || [])];
    result.splice(index, 1);

    if (onChange) onChange(result);
    handleTaskModalCancel();
  };

  const handleTemplate = (tasks) => {
    const result = [...(value || []), ...tasks];

    if (onChange) onChange(result);

    setShowTemplateModal(false);
  };

  const onSaveTask = (obj) => {
    const result = [...(value || [])];

    if (editTask !== undefined && result[editTask]) {
      result[editTask] = obj;
    } else {
      result.push(obj);
    }

    if (onChange) onChange(result);

    handleTaskModalCancel();
  };

  const handleTaskModalCancel = () => {
    setEditTask(undefined);
    setShowTaskModal(false);
  };

  const handleEditTask = (index) => {
    setEditTask(index);
    setShowTaskModal(true);
  };

  const handleNewTask = () => {
    if (isViewMode) return;
    setShowTaskModal(true);
    setEditTask(undefined);
  };

  const handleUpload = (tasks) => {
    const result = tasks;
    if (onChange) onChange(result);
  };

  if (value) {
    return (
      <>
        {showTaskModal && (
          <TransactionTemplateTaskModal
            modelProps={{
              onCancel: handleTaskModalCancel,
              open: true,
              title: editTask !== undefined && value[editTask] ? 'Edit Task' : 'Add Task',
            }}
            onSave={onSaveTask}
            task={editTask !== undefined && value[editTask] ? value[editTask] : undefined}
            isViewMode={isViewMode}
            data={data}
          />
        )}

        <TemplateModal
          title="Task Templates"
          isOpen={showTemplateModal}
          onClose={() => setShowTemplateModal(false)}
          innerHolderClassName={styles.taskTemplatesInnerHolder}
          testid="task_templates"
        >
          <TaskTemplates isTransactionTask={true} isPreForm={true} onSubmit={handleTemplate} />
        </TemplateModal>

        {value?.length ? (
          <div className={styles.paneContainer}>
            {value
              .sort((a, b) => a.Id - b.Id)
              .map((task, index) => {
                return (
                  <TaskCard
                    task={task}
                    key={`${task.Name}-task-${index}`}
                    setEditTask={handleEditTask}
                    handleRemove={onRemoveTask}
                    taskNumber={index}
                    isViewMode={isViewMode}
                  />
                );
              })}
          </div>
        ) : (
          <></>
        )}

        {!isViewMode && (
          <>
            {getTemplateButtons([
              {
                name: 'Create New',
                icon: <AddNew />,
                onClick: handleNewTask,
              },
              {
                name: 'Templates',
                icon: <Timeline />,
                onClick: () => !isViewMode && setShowTemplateModal(true),
              },
            ])}
            <UploadTasks handleUpload={handleUpload} isViewMode={isViewMode} />
          </>
        )}
      </>
    );
  }

  return <></>;
};
