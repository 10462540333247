import { useState } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'components';
import { Switch } from 'components-antd';
import Icon from '../../Icons';
import { prefsIds, PARKING_GARAGE_TYPES } from 'settings/constants/preferences';

import styles from './styles.module.scss';

export const ParkingSpaces = ({ title, softCriteria, type, onClick }) => {
  const parkingData = softCriteria[title]?.data;
  const [garageOnly, setGarageOnly] = useState(!!parkingData?.garageOnly);
  const [attachedGarage, setAttachedGarage] = useState(
    parkingData?.garageType === PARKING_GARAGE_TYPES.Attached,
  );

  const formatData = (count, garageFlag, attachedGarageFlag) => ({
    count,
    garageOnly: !!garageFlag,
    ...(garageFlag && attachedGarageFlag
      ? { garageType: PARKING_GARAGE_TYPES.Attached }
      : garageFlag
      ? { garageType: PARKING_GARAGE_TYPES.Detached }
      : {}),
  });

  const onSubmit = (values) => {
    onClick(
      title,
      softCriteria[title].value,
      type,
      formatData(values.count, values.garageOnly, values.attachedGarage),
    );
  };

  return (
    <>
      {title === prefsIds.parking && softCriteria[title] && softCriteria[title]?.value !== 'N/A' && (
        <div className={styles.parkingSpaces}>
          <h4>How many parking spaces do you need? </h4>
          <div className={styles.parkingActions}>
            <Icon
              className={styles.icon}
              variant={'minusParking'}
              onClick={() =>
                onSubmit({
                  count: Number(parkingData?.count) - 1,
                  garageOnly,
                  attachedGarage,
                })
              }
            />
            <Input
              name="parking"
              value={parkingData?.count || 0}
              onChange={(e) =>
                onSubmit({
                  count: e.target.value,
                  garageOnly,
                  attachedGarage,
                })
              }
              placeholder="0"
              inputClassName={styles.inputMaxBorderBottom}
              inputHolderClassName={styles.inputHolderClassName}
              className={styles.inputFieldMinutes}
              maxLength={9}
              type={'number'}
            />
            <Icon
              className={styles.icon}
              variant={'addParking'}
              onClick={() =>
                onSubmit({
                  count: parkingData?.count ? Number(parkingData.count) + 1 : 1,
                  garageOnly,
                  attachedGarage,
                })
              }
            />
          </div>
          <div className={styles.switchActions}>
            <div className={styles.garageSwitch}>
              <p>Garage only?</p>
              <Switch
                checked={garageOnly}
                onChange={(bool) => {
                  setGarageOnly(bool);
                  onSubmit({
                    count: parkingData?.count || 0,
                    garageOnly: bool,
                    attachedGarage,
                  });
                }}
              />
            </div>
            {garageOnly ? (
              <div className={styles.garageSwitch}>
                <p>Must be attached?</p>
                <Switch
                  checked={attachedGarage}
                  onChange={(bool) => {
                    setAttachedGarage(bool);
                    onSubmit({
                      count: parkingData?.count || 0,
                      garageOnly,
                      attachedGarage: bool,
                    });
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
};

ParkingSpaces.propTypes = {
  title: PropTypes.string,
  softCriteria: PropTypes.shape({}),
  type: PropTypes.string,
  onClick: PropTypes.func,
};

ParkingSpaces.defaultProps = {
  title: '',
  softCriteria: {},
  type: '',
  onClick: () => {},
};
