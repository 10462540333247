import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import { Controls } from './Controls';
import { link } from 'settings/navigation/link';
import { Breadcrumbs, Spinner } from 'components';
import { routes } from 'settings/navigation/routes';
import {
  transactionPreFormQuestionsIds,
  transactionStatuses,
} from 'settings/constants/transaction';
import { getTransactionSelector } from 'store/selectors/transaction';
import { resetTransactionCreateAction } from 'store/actions/transactions';
import { getAllTransactionFormProcessEffect } from 'store/effects/formProcess';
import {
  getTransactionEffect,
  getTransactionClientAddressEffect,
  updateTransactionUnderContractEffect,
  getTransactionRolesEffect,
  setTransactionPreFormQuestionsEffect,
} from 'store/effects/transactions';
import { getFinacials } from 'api/financials';

import {
  Participants,
  PurchasePrice,
  Timeline,
  TransactionTemplate,
  ClosingDate,
  FinancingType,
  UploadFiles,
  EffectiveDate,
  FinancingDetails,
} from '../TransactionCreate/components/PreForm/Questions';
import { ContentWrapper } from '../TransactionCreate/components';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { requestGetTeamListEffect } from 'store/effects';

import styles from './styles.module.scss';
import {
  requestGetUserParticipantAndContactsListEffect,
  requestGetUserParticipantsListEffect,
} from 'store/effects/drawers/addParticipants';
import TransactionParticipants from '../TransactionCreate/components/PreForm/Questions/TransactionParticipants';

export const TransactionUnderContract = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const params: { id?: string } = useParams();

  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState<string>(
    transactionPreFormQuestionsIds.whoIsRepresentingTheBuyer,
  );
  const [previousStage, setPreviousStage] = useState<string>();

  const handleNextStage = (nextStage: string) => {
    setPreviousStage(stage);
    setStage(nextStage);
  };

  const { transaction } = useSelector(getTransactionSelector);

  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);

  useEffect(() => {
    if (isTeamAgentActive) {
      dispatch(requestGetTeamListEffect());
    }
  }, []);

  const setupFinancialFields = async () => {
    const _data = await getFinacials({ transactionId: params?.id });
    const transactionCommission = _data?.financial?.TransactionCommission || {};
    const fees = _data?.financial?.TransactionFee || [];
    const referralFeeTitle = 'Referral Fee';

    const feesTransformed = fees.map((fee) => ({
      Description: fee?.FeeTitle,
      IsFeeTypePercentage: fee?.IsFeeTypePercentage,
      Value: fee?.IsFeeTypePercentage ? fee?.FeePercentage : fee?.FeeValue,
      AddToAccountId: fee?.AddToAccountId || undefined,
      SplitId: fee?.SplitId || undefined,
    }));

    const referralFee = feesTransformed?.find((item) => item?.Description === referralFeeTitle);
    const otherFees = feesTransformed?.filter((item) => item?.Description !== referralFeeTitle);

    const fieldMappings = {
      buyerCommission: transactionCommission.BuyerCommissionFees,
      sellerCommission: transactionCommission.SellerCommissionFees,
      buySideCommissionResponsibility: transactionCommission.BuySideCommissionResponsibility,
      buyerCommissionContribution: transactionCommission.BuyerCommissionContribution,
      sellerCommissionContribution: transactionCommission.SellerCommissionContribution,
      leadSource: transaction?.LeadSource,
      referralFee: referralFee?.Value,
      otherFees: otherFees,
    };

    Object.entries(fieldMappings).forEach(([key, value]) => {
      if (value !== undefined) {
        dispatch(
          setTransactionPreFormQuestionsEffect({
            [transactionPreFormQuestionsIds[key]]: value,
          }),
        );
      }
    });
  };

  useEffect(() => {
    setupFinancialFields();
    dispatch(getTransactionRolesEffect());
    dispatch(
      requestGetUserParticipantAndContactsListEffect({
        includeAgents: true,
      }),
    );
    dispatch(requestGetUserParticipantsListEffect({}, { silent: true }));
  }, []);

  useEffect(
    () => () => {
      dispatch(resetTransactionCreateAction());
    },
    [],
  );

  useEffect(() => {
    if (!transaction.Id) {
      dispatch(getTransactionEffect({ id: params?.id }));
    }
    if (params?.id) {
      dispatch(getAllTransactionFormProcessEffect({ transactionId: params?.id }));
      dispatch(getTransactionClientAddressEffect({ id: params?.id }));
    }
  }, [params?.id]);

  const breadcrumbsList = useMemo(
    () => [
      { label: 'Transactions', link: routes.transactions },
      { label: 'Under Contract', link: location.pathname },
    ],
    [location.pathname],
  );

  const onSubmit = () => {
    setLoading(true);
    dispatch(
      updateTransactionUnderContractEffect(
        { Id: params?.id, Status: transactionStatuses.UnderContract },
        {},
        (err) => {
          if (!err) {
            history.push(link.toTransactionOverview(transaction?.Id));
          }
          setLoading(false);
        },
      ),
    );
  };

  const onClose = () => {
    history.push(link.toTransactionOverview(String(params?.id)));
  };

  const renderStage = () => {
    switch (stage) {
      case transactionPreFormQuestionsIds.whoIsRepresentingTheBuyer:
        return (
          <TransactionParticipants
            currentQuestionId={transactionPreFormQuestionsIds.whoIsRepresentingTheBuyer}
            heading="Who is representing the buyer?"
            onNext={() => handleNextStage(transactionPreFormQuestionsIds.purchasePrice)}
          />
        );
      case transactionPreFormQuestionsIds.purchasePrice:
        return <PurchasePrice setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.effectiveDate:
        return <EffectiveDate setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.closingDate:
        return <ClosingDate validateWithEffective setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.financingType:
        return <FinancingType setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.financingDetails:
        return <FinancingDetails setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.purchaseAgreement:
        return (
          <UploadFiles
            currentQuestionId={transactionPreFormQuestionsIds.purchaseAgreement}
            question="Please upload a copy of the executed purchase agreement"
            onPreFormSubmit={() =>
              handleNextStage(transactionPreFormQuestionsIds.transactionTemplate)
            }
          />
        );
      case transactionPreFormQuestionsIds.transactionTemplate:
        return <TransactionTemplate showSkipButton={false} setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.timeline:
        return <Timeline setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.assignParticipantsToRoles:
        return (
          <Participants
            heading="Do you want to invite any other collaborators?"
            currentQuestionId={stage}
            previousQuestionId={previousStage}
            setCurrentQuestionId={setStage}
            setPreviousQuestionId={setPreviousStage}
            onNext={() => {
              handleNextStage(transactionPreFormQuestionsIds.participants);
            }}
          />
        );
      case transactionPreFormQuestionsIds.participants:
        return (
          <Participants
            currentQuestionId={stage}
            onNext={() => {
              handleNextStage(transactionPreFormQuestionsIds.files);
            }}
          />
        );
      case transactionPreFormQuestionsIds.files:
        return <UploadFiles onPreFormSubmit={onSubmit} />;
      default:
        return <PurchasePrice setStage={handleNextStage} />;
    }
  };

  return (
    <div className={styles.transactionUnderContract}>
      <div className={styles.head}>
        <Controls onClose={onClose} stage={stage} setStage={handleNextStage} />
      </div>
      <ContentWrapper>{loading ? <Spinner /> : renderStage()}</ContentWrapper>
    </div>
  );
};
