import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getDynamicFormSelector } from 'store/selectors/requestFormProcess';
import { PDFCacheType, PdfCache, PrintPDF, Spinner } from 'components';
import { dynamicFormScaleChangeEffect, updateDynamicFormEffect } from 'store/effects/formProcess';
import { SignatureContainer, SignaturePage, SignatureWidgets } from './components';
import { PageWrapper } from 'components-antd';
import { DynamicHeader } from '../../DynamicHeader';
import { FORM_PROCESS_SCREEN } from 'app-constants';
import { SendDocument } from 'pages/FormProcess/Ancillary';
import { dynamicManager } from '../../DynamicManager';

import styles from './styles.module.scss';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BundleDataType } from 'types';

export const DynamicViewer = () => {
  const dispatch = useDispatch();
  const anonymousDetails = dynamicManager.getAnonymousDetails();

  const {
    screen,
    meta: { link } = {},
    isCanceled,
    isCompleted,
    isPreview,
    hideFooter: footerHidden,

    dynamicFormDocuments,
  } = useSelector(getDynamicFormSelector);

  const [loading, setLoading] = useState(false);
  const pdfDocRefs = useRef({});
  const history = useHistory() as any;

  const { state: formState } = useLocation<BundleDataType>();
  const state = useMemo(() => formState, []);
  const allDocuments = dynamicFormDocuments || state?.allDocuments || [];

  const isMultipleDocument = (dynamicFormDocuments?.length as number) > 1;
  const isBundle = dynamicManager.isBundleForm();

  const [pdfData, setPdfData] = useState<any>(
    isMultipleDocument ? {} : [PdfCache.getDocument(link)],
  );

  const isDeclined = screen === FORM_PROCESS_SCREEN.DeclineSign;
  const hideFooter = isCanceled || isCompleted || isPreview || footerHidden || isDeclined;

  useEffect(() => {
    const isBundle = (dynamicFormDocuments?.length as number) > 1;

    if (isBundle && dynamicFormDocuments && state?.allDocuments) {
      const isExist = dynamicFormDocuments.find(
        ({ PublicId }) => PublicId === state.allDocuments[0].PublicId,
      );

      if (!isExist) {
        history.replace(history.location.pathname, {
          ...history.location.state,
          allDocuments: dynamicFormDocuments,
        });

        dynamicManager.updateBundleDocumentsData(dynamicFormDocuments);
      }
    }
  }, [dynamicFormDocuments]);

  const handleScaleChange = (scale) => {
    dispatch(dynamicFormScaleChangeEffect({ scale }));
  };

  const manageDocument = async () => {
    setLoading(true);

    const documents = await Promise.all(
      allDocuments?.map(async (doc: any) => {
        await PdfCache.loadDocuments(doc.DocumentLink, anonymousDetails);
        return PdfCache.getDocument(doc.DocumentLink);
      }),
    );

    setPdfData(documents);
    setLoading(false);
  };

  useEffect(() => {
    if (isMultipleDocument) {
      manageDocument();
    }
  }, [dynamicFormDocuments]);

  if (!pdfData?.length) return <Spinner />;

  const isSendDocument = screen === FORM_PROCESS_SCREEN.SendDocument;

  return (
    <PageWrapper
      version={hideFooter ? 2 : 3}
      showFooter={false}
      mainPageContentStyle={classNames(styles.mainContainer, {
        [styles.mainPageContainer]: hideFooter,
        [styles.sendDocument]: isSendDocument,
      })}
    >
      <DynamicHeader
        isSendDocument={isSendDocument}
        isMultipleDocument={isMultipleDocument}
        pdfDocRefs={pdfDocRefs}
      />

      {isSendDocument ? (
        <SendDocument
          {...(isBundle
            ? {
                formProcessPublicId: dynamicManager.getFormProcessPublicId(),
                formDocumentPublicId: dynamicFormDocuments?.[0].PublicId,
              }
            : { ...dynamicManager.getAuthData() })}
          back={dynamicManager.gotBackToPreviousScreen}
          handleExit={dynamicManager.handleFormExit}
          handleDone={dynamicManager.progressForm}
          dynamicForm
          isBundle={isBundle}
        />
      ) : (
        <PrintPDF
          className={styles.pdfDocument}
          pdfDocuments={pdfData}
          classNameDocumentPage={`${styles.documentPage} ${styles.pageScrollwiseDocument}`}
          classNamePdfDocument={styles.dynamicViewerPDFDocument}
          pdfPageClassName={styles.pdfPage}
          pageRender={(props) => (isDeclined ? <></> : <SignaturePage {...props} />)}
          handleScaleChange={handleScaleChange}
          widgets={<SignatureWidgets allDocuments={allDocuments} hideDownload={isCompleted} />}
          pdfLoading={loading}
          pdfDocRefs={pdfDocRefs}
          isDynamicView={true}
        >
          <SignatureContainer />
        </PrintPDF>
      )}
    </PageWrapper>
  );
};
