import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Button } from 'components';

const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
      <rect x="5" y="5" width="54" height="54" rx="27" stroke="#00B152" strokeWidth="4" />
      <path
        d="M23 33L29 39L41 27"
        stroke="#00B152"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

type Props = {
  handleClose: () => void;
  name: string;
};
export const OnError: React.FC<Props> = ({ handleClose, name }) => {
  return (
    <div className={classNames(styles.contentWrapper, styles.feedback)}>
      <div className={styles.successIconWrapper}>
        <Icon />
      </div>
      <div className={styles.info}>
        {`${name} is already in Mosaik and can be found in the directory. `}
      </div>
      <div className={classNames(styles.btnWrapper, styles.feedbackbtnWrapper)}>
        <Button title="View Directory" className={styles.submitButton} onClick={handleClose} />
      </div>
    </div>
  );
};
