import { handleActions } from 'redux-actions';

import {
  folderListReducer,
  formListReducer,
  formBuilderMetaReducer,
  quoteBuilderReducer,
  smartBuilderReducer,
} from './reducers';
import { initialData } from '.';

export const formBuilderReducer = handleActions(
  {
    ...folderListReducer,
    ...formListReducer,
    ...formBuilderMetaReducer,
    ...quoteBuilderReducer,
    ...smartBuilderReducer,
  },
  initialData,
);
