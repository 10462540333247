import Api from 'store/effects/core/api';
import { getState } from 'store';
import { get } from 'lodash-es';
import { createTransactionFolderAction } from 'store/actions/transactions/folder/create';
import { createTransactionFolder } from 'api/transactions/folders/create';

export const createTransactionFolderEffect = (folderName, options, cb) => {
  const transactionData = get(getState(), 'transaction.data');
  const sendRequest = Api.execResult({
    action: createTransactionFolderAction,
    method: createTransactionFolder,
  });

  const config = {
    transactionId: transactionData?.Id,
    title: folderName,
  };

  return sendRequest(config, options, cb);
};
