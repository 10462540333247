import { TokenTypes } from './token';

export const FORM_PROCESS_SCREEN = {
  NotAvailable: 'NotAvailable',
  Edit: 'Edit',
  CreatorEdit: 'CreatorEdit',
  EditPreview: 'EditPreview',
  CreatorEditPreview: 'CreatorEditPreview',
  CreatorSignPreview: 'CreatorSignPreview',
  SignPreview: 'SignPreview',
  Sign: 'Sign',
  DeclineSign: 'DeclineSign',
  OnlyPreview: 'OnlyPreview',
  CompletePreview: 'CompletePreview',
  CancelPreview: 'CancelPreview',
  PDFEditor: 'PDFEditor',
  CreatorSign: 'CreatorSign',
  SendDocument: 'SendDocument',
} as const;

export const FORM_PROCESS_USER = {
  Editor: 'editor',
  Signatory: 'signatory',
};

export enum FORM_RECIPIENTS {
  SEND_COPY,
  SEND_ALL_UPDATES,
}

export enum AllowedAnonEnum {
  EDITOR = 'editor',
  SIGNATORY = 'signatory',
  COMPLETE = 'complete',
}

export const FORM_TOKEN = {
  [AllowedAnonEnum.EDITOR]: TokenTypes.Form_Editor,
  [AllowedAnonEnum.SIGNATORY]: TokenTypes.Form_Signatory,
  [AllowedAnonEnum.COMPLETE]: TokenTypes.Form_Complete,
};

export enum PreFormScreens {
  ClientOrTransaction,
  FillForm,
  SignForm,
  FormCopy,
}

export const ALL_PRE_FORM_SCREENS = [
  PreFormScreens.ClientOrTransaction,
  PreFormScreens.FillForm,
  PreFormScreens.SignForm,
  PreFormScreens.FormCopy,
];

export enum FormTemplateWizardScreens {
  TemplateDetails,
  SignForm,
  Success,
}

export const ALL_FORM_TEMPLATE_WIZARD_SCREENS = [
  FormTemplateWizardScreens.TemplateDetails,
  FormTemplateWizardScreens.SignForm,
  FormTemplateWizardScreens.Success,
];

export const Allowed_Stroke_Sizes = [1, 2, 4, 6, 8, 10] as const;
