import React, { useState } from 'react';
import { TitleValueTable } from 'components';
import moment from 'moment';
import { getLocaleDate } from 'helpers';

import styles from './styles.module.scss';
import { ParentOffer } from './ParentOffer';
import { CounterOffer } from './CounterOffer';

export const Highlights = ({
  data,
  counterOfferFormData,
  setCounterOfferFormData,
  validation,
  contingenciesRef,
}) => {
  return (
    <div className={styles.highlights}>
      <p className={styles.title}></p>
      <div className={styles.content}>
        <ParentOffer iterationData={data} />
        <CounterOffer
          iterationData={data}
          counterOfferFormData={counterOfferFormData}
          setCounterOfferFormData={setCounterOfferFormData}
          validation={validation}
          contingenciesRef={contingenciesRef}
        />
      </div>
    </div>
  );
};
