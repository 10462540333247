import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { Notifications } from 'components/Icons';

import styles from './styles.module.scss';

export const EnableNotification = ({ hasNoNotifications = false }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.noNotifications}>
      <div className={styles.wrap}>
        {hasNoNotifications ? (
          <p className={styles.text}>You have no notifications.</p>
        ) : (
          <>
            <p className={styles.text}>You don’t have any in-app notifications enabled.</p>
            <Link
              to="/settings/notifications"
              className={styles.button}
              onClick={() => dispatch(openNotificationsDrawerEffect({ open: false }))}
            >
              <Notifications className={styles.icon} />
              <span className={styles.buttonText}>Enable Notifications</span>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
