import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { useLongPress } from 'hooks';

import { UsersMessage } from 'components';

import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { socketsGetMessagesByThreadIdEffect } from 'store/effects/sockets/messages';
import { getUserId } from 'store/selectors/user';

import ActionsList from 'components/Drawers/components/ActionsList';

import {
  getArchiveThreadOption,
  getAddParticipantThreadOption,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import ScrollWrapper from '../../ScrollWrapper';

import styles from './styles.module.scss';

const ThreadsList = ({ list, className }) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);

  const [actionsPosition, setActionPosition] = useState(null);
  const [actions, setActions] = useState([]);

  const onOpenThread = useCallback(
    (threadId) => {
      dispatch(socketsGetMessagesByThreadIdEffect({ Id: threadId }));
      dispatch(openCommentsDrawerEffect({ open: true, currentThreadId: threadId }));
    },
    [dispatch],
  );

  const onLongPress = (event, [thread]) => {
    setActionPosition({ x: event?.clientX, y: event?.clientY });
    setActions([
      ...getArchiveThreadOption(dispatch, thread, () => {
        setActionPosition(null);
      }),
    ]);
  };
  const onPress = (event, [thread]) => {
    onOpenThread(thread?.Id);
  };

  const longPressEvent = useLongPress(onLongPress, onPress);

  return (
    <div className={classNames(styles.list, className)}>
      {list.map((thread) => (
        <UsersMessage
          key={thread.Id}
          className={className}
          userId={userId}
          thread={thread}
          {...longPressEvent(thread)}
        />
      ))}

      <ActionsList actions={actions} position={actionsPosition} />
    </div>
  );
};

ThreadsList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})),
};

ThreadsList.defaultProps = {
  className: null,
  list: [],
};

export default ThreadsList;
