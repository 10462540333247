export const initialValues = {
  BuyingAgentName: null,
  BuyingAgentBrokerage: null,
  BuyingAgentPhone: null,
  BuyingAgentEmail: null,
  ContactViaEmail: false,
  ContactViaPhone: false,
  Buyer: [],
  OfferIteration: {
    PurchasePrice: null,
    DownPayment: null,
    EarnestMoneyDeposit: null,
    ClosingDate: null,
    FinancingType: null,
    Contingencies: {
      Inspection: {
        selected: false,
        days: null,
      },
      Appraisal: {
        selected: false,
        days: null,
      },
      Financing: {
        selected: false,
        days: null,
      },
    },
    Notes: null,
    OfferIterationDocument: [],
    OfferExpiryIndefinitely: true,
    OfferExpiryDays: null,
    OfferExpiryEndDate: null,
    IterationStatus: 'Pending',
    OfferLinkId: null,
    TermiteInspection: null,
    TermiteInspectionResponsibility: null,
    HomeWarranty: null,
    HomeWarrantyCost: null,
    HomeWarrantyResponsibility: null,
    Occupancy: null,
    OccupancyDays: null,
    Appliances: null,
  },
};
