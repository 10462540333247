import React, { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useOutsideClick } from 'hooks';
import { ArrowRight } from 'components/Icons';
import { setQuotesGroupByEffect } from 'store/effects/quotes';
import { getGroupedQuotesSelector } from 'store/selectors/quotes';
import { groupQuotesBy } from 'settings/constants/quotes';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { LocationService } from 'services';

import styles from './styles.module.scss';

const locationSrv = new LocationService();

const GroupBy = (props) => {
  const { className } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAgent, isThirdParty, isClient } = useSelector(getUserRolesMapSelector);
  const { groupByPropName } = useSelector(getGroupedQuotesSelector);
  const optionsRef = useRef();

  locationSrv.setLocation(location);

  const [open, setOpen] = useState(false);

  useOutsideClick([optionsRef], () => setOpen(false));

  const options = useMemo(() => {
    const list = [{ label: 'View By Status', value: groupQuotesBy.STATUS }];

    if (isClient) {
      list.push({ label: 'View By Categories', value: groupQuotesBy.CATEGORY });
    }

    if (isThirdParty) {
      list.push(
        { label: 'View By Client', value: groupQuotesBy.CLIENT },
        { label: 'View By Property', value: groupQuotesBy.PROPERTY },
      );
    }

    if (isAgent) {
      list.push(
        { label: 'View By Categories', value: groupQuotesBy.CATEGORY },
        { label: 'View By Client', value: groupQuotesBy.CLIENT },
        { label: 'View By Property', value: groupQuotesBy.PROPERTY },
      );
    }

    return list;
  }, [isAgent, isThirdParty, isClient]);

  const getGroupByLabel = () => {
    const optionObj = options.find((option) => option.value === groupByPropName);
    return optionObj?.label || options?.[0]?.label;
  };

  const onClickHandler = () => {
    setOpen(!open);
  };

  const onGroupBy = (val) => {
    dispatch(setQuotesGroupByEffect(val));
    history.replace(locationSrv.setQuery({ groupBy: val }));
  };

  return (
    <div
      testid="group_by"
      ref={optionsRef}
      onClick={onClickHandler}
      className={classNames(styles.groupBy, className)}
    >
      <div testid="group_by_button" className={styles.value}>
        <span>{getGroupByLabel()}</span>
        <ArrowRight className={classNames(styles.arrow)} />
      </div>
      {open && (
        <div className={styles.options}>
          <ul>
            {options.map((option) => (
              <li
                onClick={() => onGroupBy(option?.value)}
                key={option?.value}
                className={styles.option}
                testid={option?.value}
              >
                {option?.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

GroupBy.propTypes = {
  className: PropTypes.string,
};

GroupBy.defaultProps = {
  className: '',
};

export default GroupBy;
