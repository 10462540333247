import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { getTimeZone, showErrorMessage } from 'helpers';
import { Checkbox, InputNumber } from 'components-antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Navigation } from '../../components';
import { AvailabilityForm } from '../components';
import { Icons } from '../../Icons';

import styles from './styles.module.scss';

const initState = {
  DaysOfWeek: [],
  StartTime: null,
  EndTime: null,
  AutomaticApproval: true,
  SequentialApproval: false,
  MinimumNotice: null,
  ApprovalUsers: [],
};

export const Availability = ({
  onNext,
  stageIndex,
  onPrev,
  onUpdate,
  onFinish,
  setCurrentStageIndex,
  data,
  transaction,
  participantList,
  stagesStep,
}) => {
  const { ShowingAvailability, AddBufferBetweenShowings, BufferTime, ShowingDuration } = data;
  const bufferRef = useRef<HTMLInputElement | null>(null);
  const durationRef = useRef<HTMLInputElement | null>(null);

  if (ShowingAvailability.length) {
    const { DaysOfWeek } = ShowingAvailability[0];
    ShowingAvailability[0].DaysOfWeek =
      DaysOfWeek.length === 7 ? ['All', ...DaysOfWeek] : DaysOfWeek;
  }

  const [state, setState] = useState({
    ShowingAvailability: ShowingAvailability?.length ? [...ShowingAvailability] : [initState],
    AddBufferBetweenShowings:
      AddBufferBetweenShowings !== undefined ? AddBufferBetweenShowings : undefined,
    BufferTime: BufferTime !== null ? BufferTime : null,
    ShowingDuration: ShowingDuration !== null ? ShowingDuration : null,
  });

  const [bufferDuration, setBufferDuration] = useState(false);
  const [showingDuration, setShowingDuration] = useState(false);
  const [showEditForm, setShowEditForm] = useState(0);

  useEffect(() => {
    BufferTime !== null && setBufferDuration(true);
    ShowingDuration !== null && setShowingDuration(true);
  }, []);

  const validateTimeInput = (startTime, endTime) => {
    const startHour = startTime.getHours();
    const endHour = endTime.getHours();

    if (endHour < startHour) {
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    const isTimeInputValid = validateTimeInput(
      new Date(state.ShowingAvailability[0].StartTime),
      new Date(state.ShowingAvailability[0].EndTime),
    );
    if (!isTimeInputValid) {
      showErrorMessage('End time should be greater than start time');
      return;
    }

    if (state.BufferTime > 0) {
      setState({ ...state, AddBufferBetweenShowings: true });
    }

    if (state.ShowingAvailability[0].DaysOfWeek.includes('All')) {
      let { DaysOfWeek } = state.ShowingAvailability[0];
      state.ShowingAvailability[0].DaysOfWeek = DaysOfWeek.filter((day) => day !== 'All');
    }

    const body = {
      ShowingAvailability: state.ShowingAvailability,
      ShowingDuration: state.ShowingDuration,
      BufferTime: state.BufferTime,
      AddBufferBetweenShowings: state.BufferTime > 0 ? true : false,
    };
    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(stagesStep.EnableLink);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const addAvailability = () => {
    const data = state.ShowingAvailability;
    data.push(initState);
    setShowEditForm((prevIndex) => (prevIndex === data.length - 1 ? 0 : data.length - 1));

    setState({ ...state, ShowingAvailability: data });
  };

  const onChange = (index, data) => {
    const availabilities = state.ShowingAvailability;
    availabilities[index] = {
      ...availabilities[index],
      ...data,
    };
    setState({ ...state, ShowingAvailability: availabilities });
  };

  const onDurationChange = (data) => {
    setState({ ...state, ...data });
  };

  const onDelete = (index) => {
    const data = state.ShowingAvailability;
    data.splice(index, 1);
    setState({ ...state, ShowingAvailability: data });
  };

  const getTime = (date) => {
    if (!date) return null;

    const hours = new Date(date).getHours();
    const minutes = new Date(date).getMinutes();
    return new Date().setHours(hours, minutes);
  };

  const validateNoOverlap = () => {
    let error = false;
    const windows = state?.ShowingAvailability;
    for (let i = 0; i < windows.length; i++) {
      const current = windows[i];
      const days = current.DaysOfWeek;
      const currentStartTime = getTime(current.StartTime);
      const currentEndTime = getTime(current.EndTime);

      if (!days.length || !currentStartTime || !currentEndTime) return false;

      const rest = state?.ShowingAvailability.filter(
        (s, index) => index > i && s?.DaysOfWeek?.length && s?.StartTime && s?.EndTime,
      );

      if (!error && rest?.length) {
        for (const day of days) {
          for (const item of rest) {
            if (item.DaysOfWeek.includes(day)) {
              const startTime: any = getTime(item.StartTime);
              const endTime: any = getTime(item.EndTime);

              if (
                !error &&
                ((currentStartTime >= startTime && currentStartTime <= endTime) ||
                  (currentEndTime >= startTime && currentEndTime <= endTime))
              ) {
                error = true;
                showErrorMessage('Overlapping windows not allowed!');
                break;
              }
            }
          }
          if (error) break;
        }
      }
      if (error) break;
    }
    return error;
  };

  const isDisabled = () => {
    let disabled = false;
    state?.ShowingAvailability?.forEach((s) => {
      if (
        !s.DaysOfWeek.length ||
        !s.StartTime ||
        !s.EndTime ||
        (!s.AutomaticApproval && !s.ApprovalUsers.length)
      ) {
        disabled = true;
      }
    });
    if (!state?.ShowingAvailability?.length) disabled = true;
    if (!disabled) disabled = validateNoOverlap();
    if (!showingDuration || !state.ShowingDuration) disabled = true;

    return disabled;
  };

  const getDelegates = (approvalUsers) => {
    const list = [...data.DelegateList];
    approvalUsers?.forEach((a) => {
      const delegate = list.find((d) => a.UserId === d.UserId || a.Email === d.Email);
      if (!delegate && a?.Role === 'Delegate') {
        const participant = participantList?.find(
          (p) => a.UserId === p.UserId || a.Email === p.Email,
        );
        if (participant) {
          list.push({
            FirstName: participant.FirstName,
            LastName: participant.LastName,
            Email: a.Email || null,
            RequireApproval: true,
            Role: 'Delegate',
            Sequence: a.Sequence,
            UserId: a.UserId || null,
          });
        }
      }
    });
    return list;
  };
  useEffect(() => {
    if (bufferDuration && bufferRef.current) {
      bufferRef.current.focus();
    }
  }, [bufferDuration]);

  useEffect(() => {
    if (showingDuration && durationRef.current) {
      durationRef.current.focus();
    }
  }, [showingDuration]);

  return (
    <div className={styles.parties}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.availabilityHeader}>
            <div className={styles.availabilityWrapper}>
              <Icons className={styles.calendarIcon} variant="calendar" />
              <h4 className={styles.title}>Availability</h4>
            </div>
            <div>
              <p className={styles.timezone}>
                Time Zone:{' '}
                {data?.Timezone ? moment.tz(new Date(), data.Timezone).format('z') : getTimeZone()}
              </p>
            </div>
          </div>
          {state.ShowingAvailability.map((availability, index) => (
            <AvailabilityForm
              key={index}
              participantList={participantList}
              data={availability}
              transaction={transaction}
              delegates={getDelegates(availability.ApprovalUsers || [])}
              index={index}
              onDelete={onDelete}
              onChange={onChange}
              onUpdate={onUpdate}
              showEditForm={showEditForm}
              setShowEditForm={setShowEditForm}
            />
          ))}
          <div className={styles.addNewWrap}>
            <div className={styles.addNew} onClick={addAvailability}>
              <h4>New to Add</h4>
              <Icons className={styles.addIcon} variant="add" />
            </div>
          </div>
        </div>

        <div className={styles.showingsDuration}>
          <div className={styles.duration}>
            <Checkbox
              className={classNames(styles.radio, 'mosaikCheckbox')}
              onChange={(e: CheckboxChangeEvent) => {
                setShowingDuration(!showingDuration);
              }}
              checked={showingDuration}
            />
            <p className={classNames({ [styles.active]: showingDuration })}>
              Showing Duration:{' '}
              <InputNumber
                min={1}
                ref={durationRef}
                disabled={!showingDuration}
                placeholder={showingDuration ? '' : '30'}
                onChange={(val) => onDurationChange({ ShowingDuration: Math.floor(val) })}
                value={state.ShowingDuration}
                className={styles.input}
              />{' '}
              minutes
            </p>
          </div>
          <div className={styles.duration}>
            <Checkbox
              className="mosaikCheckbox"
              onChange={(e: CheckboxChangeEvent) => {
                setBufferDuration(!bufferDuration);
              }}
              checked={bufferDuration}
            />
            <p className={classNames({ [styles.active]: bufferDuration })}>
              Buffer between Showings:{' '}
              <InputNumber
                ref={bufferRef}
                min={0}
                disabled={!bufferDuration}
                placeholder={bufferDuration ? '' : '2'}
                onChange={(val) => onDurationChange({ BufferTime: Math.floor(val) })}
                value={state.BufferTime}
                className={styles.input}
              />{' '}
              minutes
            </p>
          </div>
        </div>
      </div>
      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onPrev={onPrev}
        onFinish={onFinish}
        disabled={isDisabled()}
        className={styles.footer}
      />
    </div>
  );
};
