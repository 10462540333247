import { apiServer } from 'settings/web-services/api';

const detailsTemplateEndPoint = '/transaction/templates/details-template/';

export function getDetailsTemplate(cfg) {
  return apiServer.get(detailsTemplateEndPoint, { params: cfg });
}

export function postDetailsTemplate(isProject, cfg) {
  const endpoint = isProject
    ? detailsTemplateEndPoint + '?isProject=true'
    : detailsTemplateEndPoint;
  return apiServer.post(endpoint, cfg);
}

export function getDetailsTemplateById({ id }) {
  return apiServer.get(detailsTemplateEndPoint + id);
}

export function deleteDetailsTemplate({ id }) {
  return apiServer.delete(detailsTemplateEndPoint + id);
}

export function putDetailsTemplate(cfg, { id }) {
  return apiServer.put(detailsTemplateEndPoint + id, cfg);
}
