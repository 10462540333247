import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOutsideClick } from 'hooks';
import styles from './styles.module.scss';

const Actions = ({ className, actions }) => {
  const [open, setOpen] = useState(false);
  const iconRef = useRef();
  const listRef = useRef();

  useOutsideClick([listRef, iconRef], () => setOpen(false));

  const onClickActions = () => {
    setOpen(!open);
  };

  return (
    <div testid="drawer_actions" className={classNames(styles.actions, className)}>
      {!!actions.length && (
        <>
          <div ref={iconRef} onClick={onClickActions} className={styles.circle}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                fill="#303030"
                stroke="#303030"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                fill="#303030"
                stroke="#303030"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                fill="#303030"
                stroke="#303030"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div ref={listRef} className={classNames(styles.listHolder, { [styles.open]: open })}>
            <ul className={styles.list}>
              {actions.map(({ title, onClick, testid }, index) => (
                <li testid={testid} className={styles.item} key={testid + index} onClick={onClick}>
                  {title}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  className: PropTypes.string,
};

Actions.defaultProps = {
  className: '',
  actions: [],
};

export default Actions;
