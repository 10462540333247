import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useOutsideClick } from 'hooks';
import fileDownload from 'js-file-download';

import { Icons } from 'pages/Vault/Icons';
import { ConfirmationDialog } from 'components';
import { Popover } from 'components-antd';
import DocumentsModal from 'components/Transactions/UploadDocuments/DocumentsModal';

import {
  deleteVaultDocumentEffect,
  previewDocumentEffect,
  updateDocumentVaultEffect,
} from 'store/effects';
import { getMetaVaultDocuments } from 'store/selectors/vault';
import { getUserId } from 'store/selectors/user';
import { showErrorMessage, showSuccessMessage } from 'helpers';

import styles from './styles.module.scss';

export const DocumentOptions = ({
  className = '',
  file,
  Property,
  isPreapprovals = false,
  isArchives = false,
  refetch,
}) => {
  const dispatch = useDispatch();
  const { data } = useSelector(getMetaVaultDocuments);
  const loggedInUserId = useSelector(getUserId);
  const { clientActiveTransactions } = data || {};
  const [open, setOpen] = useState(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [updateDocumentLoading, setUpdateDocumentLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const popupRef = useRef(null);
  const [modalUpload, setModalUpload] = useState({
    open: false,
  });
  const isModifyDocumentAccess = loggedInUserId === file?.UploaderId;

  useEffect(() => {
    if (file) {
      setUploadedFiles([
        {
          TransactionOrFolder: Property?.Address?.PlaceName,
          type: file?.DocumentCategoryId,
          contentType: 'application/pdf',
          data: '',
          documentName: file?.Title,
          filename: file?.Filename,
          size: Number(file?.OriginalContentLength || 0),
        },
      ]);
    }
  }, [file]);

  useOutsideClick([popupRef], () => {
    setOpen(false);
  });

  const togglePopup = () => setOpen(!open);

  const onDelete = () => {
    setDeleteConfirmationDialog(true);
    togglePopup();
  };

  const onEdit = () => {
    setModalUpload({ open: true });
    togglePopup();
  };

  const onDownload = () => {
    dispatch(
      previewDocumentEffect(
        { DocumentVaultUUID: file?.DocumentVaultUUID || file?.DocumentLink },
        {},
        (err, response) => {
          if (err) {
            return showErrorMessage(err);
          }
          const fileName = response.headers['file-name'];
          const getFileExtension = () => {
            if (fileName.endsWith('.pdf')) {
              return '.pdf';
            } else if (fileName.endsWith('.doc')) {
              return '.doc';
            } else if (fileName.endsWith('.zip')) {
              return '.zip';
            } else {
              return '.pdf';
            }
          };

          const fileExtension = getFileExtension();

          const fileTitle = fileName?.endsWith(fileExtension)
            ? fileName
            : `${fileName}${fileExtension}`;
          fileDownload(response.data, fileTitle || response.headers['file-name']);
          showSuccessMessage('File downloaded.');
        },
      ),
    );

    togglePopup();
  };

  const handleDeleteDocument = async () => {
    setConfirmationLoading(true);
    dispatch(
      deleteVaultDocumentEffect(
        {
          id: file?.DocumentVaultUUID || file?.DocumentLink,
        },
        {},
        (err) => {
          if (!err) {
            showSuccessMessage('File deleted');
            setDeleteConfirmationDialog(false);
            refetch();
          }
          setConfirmationLoading(false);
        },
      ),
    );
  };

  const onSaveEditted = async (files) => {
    setUpdateDocumentLoading(true);

    const document = files?.[0] || {};
    const { TransactionOrFolder } = document || {};
    const itemExists = clientActiveTransactions?.find(
      (item) => item?.Property?.Address?.PlaceName === TransactionOrFolder,
    );

    const payload = {
      id: file?.DocumentVaultUUID,
      ...(itemExists
        ? { TransactionId: itemExists?.TransactionId }
        : { Folder: TransactionOrFolder }),
      Title: document?.documentName,
      CategoryId: document?.type,
      ...(document?.data === ''
        ? { DocumentVaultUUID: file?.DocumentVaultUUID }
        : {
            File: {
              ContentType: document?.contentType,
              Filename: document?.filename,
              Size: document?.size,
              Data: btoa(document?.data),
            },
          }),
    };

    dispatch(
      updateDocumentVaultEffect(payload, {}, (err) => {
        if (!err) {
          setModalUpload({
            open: false,
          });
          showSuccessMessage('Document updated successfully');
          refetch();
        }

        setUpdateDocumentLoading(false);
      }),
    );
  };

  const getPopupContainer = (triggerNode) => {
    const parentElement = triggerNode.parentElement;
    // Ensure it returns an HTMLElement, otherwise fallback to document.body or a specific element
    return parentElement instanceof HTMLElement ? parentElement : document.body;
  };

  return (
    <div>
      <Popover
        open={open}
        trigger={'click'}
        placement="bottomRight"
        getPopupContainer={getPopupContainer}
        overlayClassName={styles.documentPopover}
        content={
          <div ref={popupRef} className={classNames(styles.documentOptions, className)}>
            <ul className={styles.list}>
              {/* hidden option until we implement share functionality */}
              {/* <li className={styles.item} onClick={togglePopup}>
                <Icons variant={Icons.SHARE} /> Share
              </li> */}
              <li className={styles.item} onClick={onDownload}>
                <Icons variant={Icons.DOWNLOAD} />
                Download
              </li>
              {!isArchives && isModifyDocumentAccess && (
                <>
                  <li className={styles.item} onClick={onEdit}>
                    <Icons variant={Icons.EDIT} />
                    Edit
                  </li>
                  <li className={styles.item} onClick={onDelete}>
                    <Icons variant={Icons.DELETE} />
                    Delete
                  </li>
                </>
              )}
            </ul>
          </div>
        }
      >
        <div
          onClick={togglePopup}
          className={classNames(styles.documentDots, { [styles.active]: open })}
        >
          <Icons variant={Icons.DOTS} />
        </div>
      </Popover>
      <ConfirmationDialog
        onReject={() => setDeleteConfirmationDialog(false)}
        onConfirm={handleDeleteDocument}
        isOpen={deleteConfirmationDialog}
        confirmText="Delete"
        isPending={confirmationLoading}
        className={styles.deleteDocumentDialog}
      >
        <div className={styles.content}>
          <p className={styles.title}>Delete Document</p>
          Are you sure you want to delete <b>{file?.Title || file?.Filename || file?.FormName}</b>?
        </div>
      </ConfirmationDialog>
      <DocumentsModal
        modal={modalUpload}
        multiple={false}
        files={uploadedFiles}
        isPending={updateDocumentLoading}
        onSave={(files) => onSaveEditted(files)}
        onCloseModal={() => setModalUpload({ open: false })}
        vaultFile
        dropzone
        filesRequired
        fileNameRequired
        addMore={false}
        isPreapprovals={isPreapprovals}
      />
    </div>
  );
};
