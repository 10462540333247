import { createAction } from 'redux-actions';

export const sortCategoriesAction = createAction('DASHBOARD/SORT_CATEGORIES');
export const searchCategoriesAction = createAction('DASHBOARD/SEARCH_CATEGORIES');
export const requestGetCategoriesAction = createAction('REQUEST/GET_CATEGORIES');
export const requestDeleteDeactivateCategoryAction = createAction(
  'REQUEST/DELETE_DEACTIVATE_CATEGORY',
);
export const requestPutActivateCategoryAction = createAction('REQUEST/PUT_ACTIVATE_CATEGORY');
export const requestGetCategoryByIdAction = createAction('REQUEST/GET_CATEGORY_BY_ID');
export const requestUpdateCategoryAction = createAction('REQUEST/PUT_CATEGORY');
export const requestPostCategoryAction = createAction('REQUEST/POST_CATEGORY');
