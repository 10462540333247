import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { Question } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { Continue } from '../../Continue';
import { Wrapper } from 'components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';

import styles from './styles.module.scss';

const MoveTransactionToListing = ({
  showSkipButton,
  moveToActiveListing,
  heading = 'Do you want to restore this listing?',
  disclaimer = 'The original listing will be moved to the status of <b>Active Listing</b> and the transaction that was cancelled will be moved to your archive.',
}) => {
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const apply = preForm?.[transactionPreFormQuestionsIds.moveToActiveListing];
  const dispatch = useDispatch();

  const onNextHandler = (event) => {
    moveToActiveListing(event);
  };

  const onAnswerHandler = (answer) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.moveToActiveListing]: answer,
      }),
    );
  };

  return (
    <div testid="apply_transaction_template" className={styles.container}>
      <Question>{heading}</Question>
      <AnswersContainer>
        <Wrapper>
          <Button
            type="text"
            className={classNames(styles.answerBtn, { [styles.active]: apply === 'yes' })}
            onClick={() => {
              onAnswerHandler('yes');
            }}
          >
            Yes
          </Button>
          <Button
            type="text"
            className={classNames(styles.answerBtn, { [styles.active]: apply === 'no' })}
            onClick={() => {
              onAnswerHandler('no');
            }}
          >
            No
          </Button>
          <p className={styles.disclaimer} dangerouslySetInnerHTML={{ __html: disclaimer }}></p>
        </Wrapper>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={moveToActiveListing} disabled={!apply} />
        {showSkipButton ? <Continue onClick={onNextHandler} variant="skip-hollow" /> : null}
      </ButtonsContainer>
    </div>
  );
};

MoveTransactionToListing.propTypes = {
  moveToActiveListing: PropTypes.func,
  showSkipButton: PropTypes.bool,
};

MoveTransactionToListing.defaultProps = {
  moveToActiveListing: () => {},
  showSkipButton: false,
};

export default MoveTransactionToListing;
