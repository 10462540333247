import {
  ADD_NEW_QUOTE_VERSION,
  COPY_NEW_QUOTE_VERSION,
  GET_FORM_VERSION,
  UPDATE_FORM_VERSION,
  ADD_TAG,
} from 'app-constants';
import { apiServer } from 'settings/web-services/api';

export const getQuoteForm = ({ versionId, ...cfg }) =>
  apiServer.get(GET_FORM_VERSION(versionId), cfg);

export const updateQuoteForm = ({ Id, ...cfg }) => apiServer.patch(UPDATE_FORM_VERSION(Id), cfg);

export const newQuoteFormVersion = ({ versionId, formId, ...cfg }) =>
  apiServer.post(ADD_NEW_QUOTE_VERSION(formId, versionId), cfg);

export const copyQuoteFormVersion = (cfg) => apiServer.post(COPY_NEW_QUOTE_VERSION, cfg);

export const addTag = (cfg) => apiServer.post(ADD_TAG, cfg);
