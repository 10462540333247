import { handleActions } from 'redux-actions';
import { cloneDeep, get } from 'lodash-es';

import {
  setComparesAction,
  deleteCompareItemAction,
  requestGetComparePropertiesAction,
  resetComparePropertiesStateAction,
  resetFullDataComparePropertiesAction,
} from 'store/actions/feed';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: [],
  meta: {},
};

export default handleActions(
  {
    [setComparesAction]: (state, { payload }) => ({
      ...state,
      data: payload.data || initialData.data,
    }),
    [deleteCompareItemAction]: (state, { payload }) => {
      const newCompares = state.data.filter((item) => item.Id !== payload.id);
      return {
        ...state,
        data: newCompares,
      };
    },
    [requestGetComparePropertiesAction]: (state, { payload }) => {
      const requestData = get(payload, 'data.result', initialData.data);
      const newData = state.data.map((stateData) => {
        const propertyFromRequest = requestData.find((rData) => rData.Id === stateData.Id);
        if (propertyFromRequest) {
          return { ...stateData, ...propertyFromRequest };
        }
        return { ...stateData };
      });

      return {
        ...state,
        state: get(payload, 'state', initialData.state),
        data: newData,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [resetComparePropertiesStateAction]: (state) => ({
      ...state,
      state: initialData.state,
      meta: initialData.meta,
    }),
    [resetFullDataComparePropertiesAction]: () => cloneDeep(initialData),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
