/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import classNames from 'classnames';

import InfoItem from '../InfoItem';
import BioInfo from '../../BioInfo';
import { TransactionAccess } from '../TransactionAccess';

import styles from './styles.module.scss';

const ThirdParty = (props) => {
  const { className, info } = props;
  const { Bio, Partner } = info || {};

  const getAddress = (address) => {
    const { City, State, Line1, Line2, Zip } = address || {};

    const line2String = Line2 ? `, ${Line2}` : '';
    const statsString = State ? `, ${State}` : '';

    return (
      <div className={styles.address}>
        <div>{`${Line1 || ''}${line2String}`}</div>
        <div>{`${City}${statsString} ${Zip || ''}`}</div>
      </div>
    );
  };

  const getAreasValue = (areas) => (
    <div>
      {(areas || []).map((area, index) => (
        <div key={index}>{getAddress(area)}</div>
      ))}
    </div>
  );

  return (
    <div className={classNames(styles.thirdParty, className)}>
      {Partner?.Address && <InfoItem label="Address" value={getAddress(Partner?.Address)} />}
      {Partner?.AreasOfOperation && Partner?.AreasOfOperation?.length && (
        <InfoItem label="Areas of Operation" value={getAreasValue(Partner?.AreasOfOperation)} />
      )}
      <BioInfo value={Bio} />
      <TransactionAccess />
    </div>
  );
};
ThirdParty.propTypes = {
  className: PropTypes.string,
  info: PropTypes.shape({
    Bio: PropTypes.string,
    Partner: PropTypes.shape({
      Address: PropTypes.shape({}),
      AreasOfOperation: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};
ThirdParty.defaultProps = {
  className: '',
  info: {},
};
export default ThirdParty;
