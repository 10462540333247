import { map } from 'lodash-es';
import classNames from 'classnames';

import { Menu } from 'components-antd';

import styles from './styles.module.scss';

interface HeaderProps {
  mode?: 'horizontal' | 'vertical';
  setTab: (val: string) => void;
  tabValue: string;
  virtualTour: boolean;
  photosTab: boolean;
}

export const Header = ({
  mode = 'horizontal',
  setTab,
  tabValue,
  virtualTour,
  photosTab,
}: HeaderProps) => {
  const tabs = ['Street View', 'Map'];
  if (virtualTour) tabs.push('Virtual Tour');
  if (photosTab) tabs.unshift('Photos');

  return (
    <span className={styles.linkContainer}>
      <Menu
        data-testid="header-links"
        mode={mode}
        inlineCollapsed={false}
        className={classNames(
          styles.links,
          { [styles.linksHorizontal]: mode === 'horizontal' },
          { [styles.linksVertical]: mode === 'vertical' },
        )}
        getPopupContainer={(triggerNode) => triggerNode}
        activeKey={tabValue}
      >
        {map(tabs, (title) => (
          <Menu.Item key={title}>
            <a
              className={styles.link}
              title={title}
              onClick={() => {
                setTab(title);
              }}
              // data-testid={`header-link-${title}`}
            >
              {title}
            </a>
          </Menu.Item>
        ))}
      </Menu>
    </span>
  );
};
