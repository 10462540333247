import { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  getClientFavoritesPropsEffect,
  resetClientFavoritesPropsEffect,
  getClientContextsEffect,
} from 'store/effects';
import { getAgentClientsSelector, getClientFavoritePropsSelector } from 'store/selectors/feed';
import { getClientContextsStateSelector } from 'store/selectors/contexts';
import { getCurrentContextSelector } from 'store/selectors/context';
import { setCurrentContextAction } from 'store/actions/context';
import { Wrapper as PendingWrapper } from 'components';
import { PENDING } from 'settings/constants/apiState';
import PropsList from './PropsList';
import { IDLE, READY } from 'settings/constants/apiState';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getCompareProperties } from 'api/feed';

import styles from './styles.module.scss';

const useGetAgentClientFavourites = (ids) => {
  const [status, setStatus] = useState('idle');
  const [listingData, setListingData] = useState(null);
  useEffect(() => {
    if (ids.length > 0 && status === 'idle') {
      setStatus('pending');
      getCompareProperties({ ids })
        .then((response) => {
          setListingData(response.data.result);
          setStatus('success');
        })
        .catch((err) => {
          setStatus('failed');
        });
    }
  }, [ids, status]);

  return { listingData, isLoading: status === 'pending' };
};

const Favorites = (props) => {
  const { client } = props;
  const dispatch = useDispatch();
  const favProps = useSelector(getClientFavoritePropsSelector);
  const { isClient } = useSelector(getUserRolesMapSelector);

  const fetchContexts = useCallback(() => dispatch(getClientContextsEffect()), [dispatch]);

  const contextsState = useSelector(getClientContextsStateSelector);
  const context = useSelector(getCurrentContextSelector);
  useEffect(() => {
    if (isClient && contextsState.state === IDLE) fetchContexts();
  }, []);

  useEffect(() => {
    if (!context && contextsState.state === READY) {
      const contexts = contextsState.data || [];
      const selectedContext = contexts[0];

      // TODO: we won't depend on specific agent
      const firstAgent = selectedContext?.Agents[0];
      if (firstAgent) {
        dispatch(setCurrentContextAction(selectedContext));
      }
    }
  }, [contextsState.state]);

  useEffect(() => {
    if (context && context.ContextKey) {
      dispatch(getClientFavoritesPropsEffect({ userId: client?.id }));

      return () => dispatch(resetClientFavoritesPropsEffect());
    }
  }, [client?.id, context]);

  const { data } = useSelector(getAgentClientsSelector);
  const favListIds = useMemo(
    () =>
      data?.find((item) => client.id === item.Id)?.Client?.Favorites?.map((fav) => fav.MLSId) || [],
    [data],
  );
  const { listingData, isLoading } = useGetAgentClientFavourites(favListIds);

  const isPending = favProps.state === PENDING || isLoading;
  return (
    <div className={styles.favorites}>
      <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
        <PropsList list={isClient ? favProps?.data : listingData} client={client} />
      </PendingWrapper>
    </div>
  );
};

Favorites.propTypes = {
  client: PropTypes.object,
};

Favorites.defaultProps = {
  client: null,
};

export default Favorites;
