import PageWrapper from 'pages/Clarity/Components/PageWrapper';
import { Header } from 'pages/Clarity/Components/Header';
import { LeftSideBar } from 'pages/Clarity/Components/LeftSideBar';
import { RightSideBar } from 'pages/Clarity/Components/RightSideBar';
import SubHeader from 'pages/Clarity/Components/SubHeader';
import { WorkShopActivity } from 'pages/Clarity/Components/Activity';
import { Footer } from 'components-antd';
export const Home = () => {
  return (
    <>
      <PageWrapper
        Header={Header}
        LeftSideBar={LeftSideBar}
        RightSideBar={RightSideBar}
        SubHeader={SubHeader}
        Content={WorkShopActivity}
      ></PageWrapper>
      <Footer />
    </>
  );
};
