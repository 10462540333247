import { handleActions } from 'redux-actions';
import { cloneDeep } from 'lodash-es';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetPartnersAction,
  sortPartnersAction,
  searchPartnersAction,
  requestDeleteDeactivatePartnerAction,
  requestPutActivatePartnerAction,
} from 'store/actions/adminPanel';
import {
  handleActivateItem,
  handleDeactivateItem,
  handleGetListOfItems,
  searchItems,
  sortItems,
} from '../helpers';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  sort: {
    order: 'asc',
    fields: ['BusinessName'],
  },
  search: {
    name: '',
    categories: [],
    searchString: '',
  },
};

export default handleActions(
  {
    [requestGetPartnersAction]: handleGetListOfItems(initialData),
    [requestDeleteDeactivatePartnerAction]: handleDeactivateItem,
    [requestPutActivatePartnerAction]: handleActivateItem,
    [sortPartnersAction]: sortItems,
    [searchPartnersAction]: searchItems,
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
