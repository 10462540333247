import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
interface IActivityFilterFooter {
  testid?: string;
  className?: string;
  children?: React.ReactNode;
}
const defaultProps: IActivityFilterFooter = {
  children: undefined,
  testid: '',
  className: '',
};
export const Footer: React.FC<IActivityFilterFooter> = ({ className, children, testid }) => (
  <div testid={testid} className={classNames(styles.footer, className)}>
    {children}
  </div>
);

Footer.defaultProps = defaultProps;
