import { SVGProps } from 'react';

type variantTypes = 'copy' | 'download' | 'multiple' | 'dots';
interface OfferIconProps extends SVGProps<SVGSVGElement> {
  variant: variantTypes;
}

export const Icons = ({ variant, ...props }: OfferIconProps) => {
  const renderIcon = () => {
    switch (variant) {
      case 'copy': {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M12.5 1H7.5C6.67157 1 6 1.67157 6 2.5V9.5C6 10.3284 6.67157 11 7.5 11H12.5C13.3284 11 14 10.3284 14 9.5V2.5C14 1.67157 13.3284 1 12.5 1Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 5H3.5C2.67157 5 2 5.67157 2 6.5V13.5C2 14.3284 2.67157 15 3.5 15H8.5C9.32843 15 10 14.3284 10 13.5V11"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'download': {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M2.66699 11.333V12.6663C2.66699 13.02 2.80747 13.3591 3.05752 13.6092C3.30757 13.8592 3.6467 13.9997 4.00033 13.9997H12.0003C12.3539 13.9997 12.6931 13.8592 12.9431 13.6092C13.1932 13.3591 13.3337 13.02 13.3337 12.6663V11.333"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.66699 7.33301L8.00033 10.6663L11.3337 7.33301"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 2.66699V10.667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'multiple': {
        return (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M6.75 9L8.625 10.875L14.625 4.875"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.875 3.375H5.25C4.21447 3.375 3.375 4.21447 3.375 5.25V12.75C3.375 13.7855 4.21447 14.625 5.25 14.625H12.75C13.7855 14.625 14.625 13.7855 14.625 12.75V9"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'dots': {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="5" cy="12" r="2" fill="#515151" />
            <circle cx="12" cy="12" r="2" fill="#515151" />
            <circle cx="19" cy="12" r="2" fill="#515151" />
          </svg>
        );
      }
    }
  };

  return renderIcon();
};
