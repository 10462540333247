import { routes } from 'settings/navigation/routes';
import { AGENT, CLIENT } from 'settings/constants/roles';
import { PaywallForRole } from 'pages/Paywall/PaywallForRole';

export default [
  {
    path: routes.paywall,
    component: PaywallForRole,
    exact: true,
    roles: [AGENT, CLIENT],
  },
];
