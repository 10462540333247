import { SVGProps } from 'react';

export const Transaction = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
    <path
      d="M10.166 11.8346H21.8327M21.8327 11.8346L18.916 8.91797M21.8327 11.8346L18.916 14.7513"
      stroke="#515151"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.832 20.1667H10.1654M10.1654 20.1667L13.082 17.25M10.1654 20.1667L13.082 23.0833"
      stroke="#515151"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
