import { createSelector } from 'reselect';

const localState = ({ agents }) => agents;

/**
 * @deprecated
 */
export const getClientContextSelector = createSelector(localState, (agents) => agents);

/**
 * @deprecated
 */
export const getClientAgentsSelector = createSelector(localState, (agents) => agents.data);
