import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ adminPanel }) => adminPanel;
const usersState = createSelector(localState, ({ users }) => users);
const userState = createSelector(localState, ({ user }) => user);

export const getUsersDashboardSelector = createSelector(usersState, ({ state, meta, ...rest }) => ({
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!rest?.data,
  ...rest,
}));

export const getUsersDataDashboardSelector = createSelector(usersState, (state) =>
  get(state, 'data'),
);

export const getUserDashboardSelector = createSelector(userState, ({ state, meta, ...rest }) => ({
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!rest?.data,
  ...rest,
}));

export const getUserDataDashboardSelector = createSelector(userState, (state) =>
  get(state, 'data'),
);

export const getUsersSortSelector = createSelector(usersState, ({ sort }) => sort);

export const getUsersSearchSelector = createSelector(usersState, ({ filters }) => filters);
