import { propertyStatusOptions } from 'settings/constants/properties';
import { getMinMaxAsStringValues } from 'components/MinMax/helpers';
import { sortStatus } from 'helpers';
import PrimaryCriteria from '../index';

const fieldsToStringify = ['PriceRange', 'SquareFeetRange', 'LotSizeRange', 'StoriesRange'];

export const mergeInitialValues = (search) => {
  const searchPrefsValue = {
    ...search,
    StoriesRange: search?.StoriesRange ? search?.StoriesRange : search?.Stories?.StoriesRange,
  };

  return {
    ...PrimaryCriteria.initialValues,
    ...searchPrefsValue,
    HOARange: { Max: +searchPrefsValue?.HOARange?.Max || '' },
    MaxDaysOnMarket: searchPrefsValue?.MaxDaysOnMarket
      ? `${searchPrefsValue?.MaxDaysOnMarket}`
      : PrimaryCriteria.initialValues.MaxDaysOnMarket,
    ...fieldsToStringify.reduce(
      (searchObj, fieldName) =>
        fieldName
          ? {
              ...searchObj,
              [fieldName]: getMinMaxAsStringValues(
                fieldName,
                searchPrefsValue,
                PrimaryCriteria.initialValues,
              ),
            }
          : searchObj,
      {},
    ),
    MinParkingSpaces: searchPrefsValue?.MinParkingSpaces ?? 0,
    Status: propertyStatusOptions
      .filter((option) => searchPrefsValue?.Status?.includes(option.value))
      .sort(sortStatus),
  };
};
