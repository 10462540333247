import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Workshop/Transactions/TransactionOverview/Icons';
import { Button } from 'components';

import styles from './styles.module.scss';

const Actions = (props) => {
  const { className, onAdd, onEdit, editMode, onSave, onCancel, isUpdatePending } = props;
  const { addMode, disabled } = props;

  const getButtons = () => {
    if (editMode || addMode) {
      return (
        <>
          <Button
            onClick={onSave}
            className={classNames(styles.btn)}
            loaderClassName={styles.loader}
            title="Save"
            titleClassName={styles.btnTitle}
            isPending={isUpdatePending}
            disabled={disabled}
            testid="save_button"
          />
          <Button
            onClick={onCancel}
            className={classNames(styles.btn, styles.cancel)}
            title="Cancel"
            titleClassName={styles.btnTitle}
            testid="cancel_button"
          />
        </>
      );
    }

    return (
      <>
        <Button
          onClick={onAdd}
          className={classNames(styles.btn, styles.add)}
          title="Add"
          iconLeft={<Icon className={styles.btnIcon} variant={Icon.PLUS} />}
          titleClassName={styles.btnTitle}
          testid="add_button"
        />
        <Button
          onClick={onEdit}
          className={classNames(styles.btn, styles.edit)}
          iconLeft={<Icon className={styles.btnIcon} variant={Icon.EDIT} />}
          testid="edit_button"
        />
      </>
    );
  };

  return <div className={classNames(styles.actions, className)}>{getButtons()}</div>;
};

Actions.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  editMode: PropTypes.bool,
  addMode: PropTypes.bool,
  isUpdatePending: PropTypes.bool,
  disabled: PropTypes.bool,
};

Actions.defaultProps = {
  className: '',
  onAdd: () => {},
  onEdit: () => {},
  onSave: () => {},
  onCancel: () => {},
  editMode: false,
  addMode: false,
  isUpdatePending: false,
  disabled: false,
};

export default Actions;
