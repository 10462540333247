import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  DraggableMenuList,
  Sidebar,
  Skeleton,
  Collapse,
  Panel,
  Popover,
  TextArea,
} from 'components-antd';
import {
  getSmartFormCurrentQuestionSelect,
  getSmartFormMetaSelect,
} from 'store/selectors/formBuilder';
import {
  updateSmartFormMetaEffect,
  updateSmartFormQuestionEffect,
} from 'store/effects/formBuilder';
import { arrayMove } from 'helpers';
import { FORM_STATUS_TYPE } from 'app-constants';
import { PENDING } from 'settings/constants/apiState';
import { PDFIcon } from 'pages/FormBuilder/Icons/PDFIcon';
import QuestionIcon from 'pages/FormBuilder/Icons/QuestionIcon';
import { PDFFields, QuestionFieldType, QuestionType } from 'types';
import { QuestionListPopover } from 'pages/FormBuilder/components';

import styles from './styles.module.scss';

export const SmartLeftSidebar = ({ upload = false }) => {
  const [newQuestionVisibility, setNewQuestionVisibility] = useState<undefined | boolean>(
    undefined,
  );

  const { state, disabled, status, activeQuestionIndex, questions, highlightedFields } =
    useSelector(getSmartFormMetaSelect);
  const question = useSelector(getSmartFormCurrentQuestionSelect);
  const dispatch = useDispatch();

  const highlightFields = (fields: PDFFields[]) => {
    const highlightFields = fields.map((f) => f.id);
    if (JSON.stringify(highlightedFields || []) !== JSON.stringify(highlightFields))
      dispatch(
        updateSmartFormMetaEffect({
          highlightedFields: highlightFields,
        }),
      );
  };

  const RenderQuestionItem = ({ Type, UUID, Name, Fields, FieldType }: QuestionType, index) => {
    return {
      content: (
        <div className={styles.questionMenuItem}>
          <span className={styles.questionBox}>
            {FieldType ? (
              <Collapse
                expandIcon={
                  Fields?.length ? undefined : () => <span className={styles.spanSpace}></span>
                }
                collapsible={Fields?.length ? undefined : 'disabled'}
              >
                <Panel
                  forceRender={true}
                  key={Fields?.length ? UUID : `${UUID}-`}
                  header={
                    <span className={styles.questionTextArea}>
                      <QuestionIcon variant={Type} className={styles.questionIcon} />
                      <div className={styles.nameField}>
                        <span className={styles.name}>{Name}</span>
                        <span className={styles.collapseSpan}>
                          {`${Fields?.length || 0} ${Fields?.length === 1 ? 'Field' : 'Fields'}`}
                        </span>
                      </div>
                    </span>
                  }
                >
                  <span className={styles.fields}>
                    <ul className={styles.fieldsList}>
                      {Fields?.map((el) => (
                        <li
                          key={el.id}
                          onClick={() => (el ? highlightFields([el]) : null)}
                          className={classNames(styles.dropdownItems, styles.fieldListItem)}
                        >
                          <PDFIcon variant={el.fieldType as QuestionFieldType} />
                          <span className={styles.fieldName}>{el.fieldName}</span>
                        </li>
                      ))}
                    </ul>
                  </span>
                </Panel>
              </Collapse>
            ) : (
              <span className={styles.questionTextArea}>
                <TextArea
                  maxLength={64}
                  className={styles.questionName}
                  defaultValue={Name}
                  autoSize
                  onBlur={(e) => updateQuestionName(e.target.value?.trim())}
                  data-testid={`smart-question-name-${index}`}
                />
              </span>
            )}
          </span>
        </div>
      ),
      key: UUID,
    };
  };

  const updateQuestionName = (name: string) => {
    if (question && name !== question?.Name) {
      const updatedQuestion = {
        ...question,
        Name: name,
      };
      dispatch(updateSmartFormQuestionEffect(updatedQuestion));
    }
  };

  const updateQuestions = (data) => {
    const source = data?.source?.index || 0;
    const destination = data.destination?.index || 0;
    if (source !== destination) {
      const updatedQuestions: QuestionType[] = arrayMove([...questions], source, destination);
      dispatch(updateSmartFormMetaEffect({ questions: updatedQuestions }));
      if (question) {
        const updatedIndex = updatedQuestions.findIndex((el) => el.UUID === question.UUID);
        if (updatedIndex !== activeQuestionIndex) {
          dispatch(
            updateSmartFormMetaEffect({
              activeQuestionIndex: updatedIndex,
            }),
          );
        }
      }
    }
  };

  const setActiveQuestion = (index) => {
    if (activeQuestionIndex !== index) {
      if (questions?.[index]?.Fields?.length) {
        highlightFields(questions?.[index]?.Fields || []);
      }
      dispatch(
        updateSmartFormMetaEffect({
          activeQuestionIndex: index,
        }),
      );
    }
  };

  return (
    <Sidebar width={240} className={styles.smartLeftSidebar}>
      <div className={styles.addQuestionContainer}>
        <span className={styles.questionText}>Entries</span>
        {!upload && status === FORM_STATUS_TYPE.Draft && (
          <Popover
            getPopupContainer={(trigger) => trigger}
            placement="bottomRight"
            content={
              <QuestionListPopover
                handleVisibility={setNewQuestionVisibility}
                smartForm={true}
                open={newQuestionVisibility}
              />
            }
            trigger="click"
            open={newQuestionVisibility}
          >
            <Button
              variant="primary"
              disabled={state === PENDING || disabled}
              data-testid="quote-add-question-button"
              onClick={() => setNewQuestionVisibility(undefined)}
              className={styles.addBtn}
            >
              + Add
            </Button>
          </Popover>
        )}
      </div>
      <Skeleton
        showSkeleton={state === PENDING}
        rowCount={questions?.length || 10}
        avatar={{ shape: 'square' }}
      >
        {questions.length ? (
          <DraggableMenuList
            activeItem={question?.UUID}
            className={styles.dragMenuList}
            items={questions.map(RenderQuestionItem)}
            onDragEnd={updateQuestions}
            disabled={disabled || upload}
            onDraggableClick={setActiveQuestion}
            dragIconClass={styles.dragIcon}
            listItemClass={classNames({ [styles.listItem]: !disabled })}
          />
        ) : (
          !disabled && (
            <div className={styles.noEntries} data-testid="smart-no-questions">
              {upload
                ? 'Upload a document with valid Form Fields'
                : 'You have no entries. Click “+ Add” to create a new entry.'}
            </div>
          )
        )}
      </Skeleton>
    </Sidebar>
  );
};
