import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ConfirmationModal } from 'components-antd';
import Icon from 'pages/RequestQuote/Icons';

import styles from './styles.module.scss';

const StagesControls = (props) => {
  const {
    className,
    onPrev,
    onNext,
    onClose,
    isNextDisabled,
    isPrevDisabled,
    variant,
    children,
    hideNext,
  } = props;
  const [confirmClose, setConfirmClose] = useState(false);

  return (
    <div className={classNames(styles.controls, styles[variant], className)}>
      <div>{children}</div>
      <div className={styles.buttons}>
        <Icon
          testid="back"
          variant={Icon.ARROW}
          className={classNames(styles.prev, { [styles.disabled]: isPrevDisabled })}
          onClick={() => onPrev()}
        >
          Prev
        </Icon>
        {hideNext ? null : (
          <Icon
            variant={Icon.ARROW}
            className={classNames(styles.next, { [styles.disabled]: isNextDisabled })}
            onClick={() => onNext()}
            testid="next"
          >
            Next
          </Icon>
        )}
        {!!onClose && (
          <Icon
            variant={Icon.CLOSE}
            className={styles.close}
            onClick={() => setConfirmClose(true)}
            testid="close"
          >
            Close
          </Icon>
        )}
      </div>
      <ConfirmationModal
        open={confirmClose}
        confirmText={<span>Are you sure you want to cancel this pre-approval request?</span>}
        onOk={onClose}
        okText={'Yes'}
        onCancel={() => setConfirmClose(false)}
        cancelText={'No'}
      />
    </div>
  );
};

StagesControls.propTypes = {
  className: PropTypes.string,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  isNextDisabled: PropTypes.bool,
  isPrevDisabled: PropTypes.bool,
  hideNext: PropTypes.bool,
  variant: PropTypes.string,
  children: PropTypes.node,
};

StagesControls.defaultProps = {
  className: '',
  onClose: undefined,
  isNextDisabled: false,
  isPrevDisabled: false,
  hideNext: true,
  variant: null,
  children: null,
};

export default StagesControls;
