import React from 'react';

const Phone = ({ color, ...props }: React.SVGAttributes<any>) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_14895_4823)">
      <path
        d="M18.3321 14.1014V16.6014C18.333 16.8335 18.2855 17.0632 18.1925 17.2758C18.0995 17.4885 17.9632 17.6794 17.7922 17.8363C17.6211 17.9932 17.4192 18.1126 17.1994 18.187C16.9795 18.2613 16.7466 18.2889 16.5154 18.268C13.9511 17.9894 11.4879 17.1132 9.32376 15.7097C7.31029 14.4303 5.60321 12.7232 4.32376 10.7097C2.91541 8.53572 2.03897 6.06054 1.76543 3.48472C1.74461 3.25427 1.77199 3.02202 1.84585 2.80274C1.9197 2.58346 2.03841 2.38196 2.1944 2.21107C2.3504 2.04018 2.54027 1.90364 2.75192 1.81015C2.96358 1.71666 3.19238 1.66827 3.42376 1.66805H5.92376C6.32819 1.66407 6.72026 1.80728 7.0269 2.07099C7.33354 2.33471 7.53383 2.70092 7.59043 3.10138C7.69595 3.90144 7.89164 4.68699 8.17376 5.44305C8.28588 5.74132 8.31015 6.06548 8.24369 6.37712C8.17722 6.68875 8.02282 6.97481 7.79876 7.20138L6.74043 8.25972C7.92673 10.346 9.65414 12.0734 11.7404 13.2597L12.7988 12.2014C13.0253 11.9773 13.3114 11.8229 13.623 11.7565C13.9347 11.69 14.2588 11.7143 14.5571 11.8264C15.3132 12.1085 16.0987 12.3042 16.8988 12.4097C17.3036 12.4668 17.6733 12.6707 17.9375 12.9826C18.2018 13.2945 18.3422 13.6927 18.3321 14.1014Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14895_4823">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Phone;
