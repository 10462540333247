import styles from './styles.module.scss';
import { FC } from 'react';
import { Answers } from 'components';
import { ISelectComponentProps } from '../types';
import classNames from 'classnames';

const SelectComponent: FC<ISelectComponentProps> = ({
  items = [],
  color,
  onChange,
  value,
  multiple = true,
}) => (
  <Answers
    multiple={multiple}
    unselectable
    cases={items}
    variant={Answers.CHECKBOX}
    color={color}
    direction="row"
    checkboxOptionClassname={classNames(styles.checkboxOption, { [styles.full]: items.length < 2 })}
    onChange={onChange}
    value={value}
  />
);

export default SelectComponent;
