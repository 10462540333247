import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';

import { Question } from 'pages/RequestQuote/components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { Input } from 'components';

import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Continue } from '../../Continue';

import styles from './styles.module.scss';

const ListingPrice = (props) => {
  const { className, onNext, currentQuestionId } = props;
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);

  const onAnswerHandler = (answer) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.listingPrice]: answer ? Number(answer) : undefined,
        [transactionPreFormQuestionsIds.purchasePrice]: undefined,
      }),
    );
  };

  const onNextHandler = (event) => {
    if (currentQuestionId === transactionPreFormQuestionsIds.listingPrice) {
      onNext(event);
    }
  };

  return (
    <div testid="what_listing_price" className={classNames(styles.container, className)}>
      <Question>What is the listing price?</Question>
      <AnswersContainer>
        <Input
          isNumberFormat
          variant={Input.LIGHT_ROUNDED}
          placeholder="$ Price"
          onChange={(e, val) => onAnswerHandler(val)}
          value={preForm[transactionPreFormQuestionsIds.listingPrice]}
          prefix="$"
          testid="price_input"
          maxNumber={1000000000}
        />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue
          onClick={onNextHandler}
          disabled={!preForm?.[transactionPreFormQuestionsIds.listingPrice]}
        />
      </ButtonsContainer>
    </div>
  );
};

ListingPrice.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  currentQuestionId: PropTypes.string,
};

ListingPrice.defaultProps = {
  className: '',
  onNext: () => {},
  currentQuestionId: null,
};

export default ListingPrice;
