import { handleActions } from 'redux-actions';
import { onBoardingWizardAction } from 'store/actions/onBoarding';
import { cloneDeep, get } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';

const initialData = {};

export default handleActions(
  {
    [onBoardingWizardAction]: (state, { payload }) => {
      const data = get(payload, 'data.result', initialData);
      const meta = get(payload, 'meta', initialData);

      const stages = meta?.stageIndex !== undefined ? { [meta?.stageIndex]: { meta, data } } : {};

      return { ...state, ...stages };
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
