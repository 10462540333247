import classNames from 'classnames';
import { memo, useMemo } from 'react';

const Transaction = memo(
  ({ className, color = '#51BFE1' }: { className?: string; color?: string }) =>
    useMemo(
      () => (
        <div className={classNames(className)}>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect opacity="0.12" width="48" height="48" rx="12" fill={color} />
            <path
              d="M16.2207 18.4455H31.7763M31.7763 18.4455L27.8874 14.5566M31.7763 18.4455L27.8874 22.3344"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M31.7773 29.5549H16.2218M16.2218 29.5549L20.1107 25.666M16.2218 29.5549L20.1107 33.4438"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      [className],
    ),
);

export default Transaction;
