import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import {
  getSavedSearchesEffect,
  resetSearchCriteriaEffect,
  setSearchCriteriaEffect,
  getSavedSearchByIdEffect,
} from 'store/effects/search';
import { getIsSearchesDrawerOpenSelector } from 'store/selectors/searchResults';
import {
  getSavedSearchesFormattedSelector,
  getSavedSearchesSelector,
} from 'store/selectors/mySearches';

import { EDIT } from 'settings/constants/mode';

import { Wrapper as PendingWrapper } from 'components';
import {
  ScrollDrawerWrapper,
  DrawerPaddingWrapper,
} from 'pages/Properties/SearchResults/components';
import SearchesComponent from 'pages/Properties/Search/components/SearchAgentsBlock/Searches';

import { deleteSavedSearchEffect, openSearchResultsFilterDrawerEffect } from 'store/effects';
import { propertyStatusOptions } from 'settings/constants/properties';

import { IDLE } from 'settings/constants/apiState';
import styles from './styles.module.scss';
import { getSearchResultsV2Effect } from 'store/effects/searchResults/searchV2';

const Searches = (props) => {
  const { className } = props;
  const open = useSelector(getIsSearchesDrawerOpenSelector);
  const savedSearches = useSelector(getSavedSearchesSelector);
  const { formattedData, isLoading } = useSelector(getSavedSearchesFormattedSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open && savedSearches.state === IDLE) {
      dispatch(getSavedSearchesEffect());
    } else {
      dispatch(getSavedSearchesEffect({}, { silent: true }));
    }
  }, [open]); // eslint-disable-line

  const onSearch = (searchQuery) => {
    dispatch(resetSearchCriteriaEffect());
    dispatch(
      setSearchCriteriaEffect({
        ...searchQuery,
        Status: propertyStatusOptions.filter((option) =>
          searchQuery?.Status?.includes(option.value),
        ),
      }),
    );
    dispatch(getSearchResultsV2Effect());
  };

  const onDelete = (id) => {
    dispatch(
      deleteSavedSearchEffect({ id }, {}, (err) => {
        if (!err) {
          dispatch(getSavedSearchesEffect());
        }
      }),
    );
  };

  const onEdit = (id) => {
    dispatch(getSavedSearchByIdEffect({ id }));
    dispatch(openSearchResultsFilterDrawerEffect({ open: true, mode: EDIT }));
  };

  return (
    <ScrollDrawerWrapper className={classNames(styles.searchesScroll, className)}>
      <PendingWrapper isPending={isLoading} className={styles.pendingWrapper}>
        <DrawerPaddingWrapper>
          <SearchesComponent
            onSearch={onSearch}
            onEdit={onEdit}
            onDelete={onDelete}
            list={formattedData}
            popupPosition="bottom"
          />
        </DrawerPaddingWrapper>
      </PendingWrapper>
    </ScrollDrawerWrapper>
  );
};

Searches.propTypes = {
  className: PropTypes.string,
};

Searches.defaultProps = {
  className: '',
};

export default Searches;
