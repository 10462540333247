function MenuDots({ bgColor, colorWithHash, iconClassName }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={iconClassName}
    >
      <rect width="32" height="32" rx="16" fill={bgColor} />
      <circle cx="9" cy="16" r="2" fill={colorWithHash} />
      <circle cx="16" cy="16" r="2" fill={colorWithHash} />
      <circle cx="23" cy="16" r="2" fill={colorWithHash} />
    </svg>
  );
}

export default MenuDots;
