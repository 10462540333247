import { useState, useRef, useEffect } from 'react';
import { Switch } from 'antd';
import classNames from 'classnames';

import { Icons } from '../../Icons';
import {
  EditLinkDuration,
  EditNotify,
  EditNotes,
  EditDocuments,
  EditListingAgent,
  EditNotifications,
  EditOfferDeadline,
} from '../components';
import { Spinner } from 'components';
import { Navigation } from '../../components';
import { showSuccessMessage } from 'helpers/success';

import { useSelector } from 'react-redux';
import { getCreatedOfferDetails } from '../../../../../../../store/selectors/offers';
import styles from './styles.module.scss';
import { IconText } from '../components/IconText';
import { InformationText } from '../components/InformationText';
import { useScrollableRef } from '../../hooks/useScrollableRef';

export const EnableLink = ({
  stageIndex,
  onClose,
  onFinish,
  setCurrentStageIndex,
  data,
  transaction,
  loading,
  stagesStep,
}) => {
  const { EnableLink } = data;
  const { Link } = useSelector(getCreatedOfferDetails);
  const { scrollableDivRef } = useScrollableRef();

  const [state, setState] = useState({
    EnableLink,
  });

  const copyLink = () => {
    navigator.clipboard.writeText(transaction?.OfferLink?.Link || Link);
    showSuccessMessage('Link Copied');
  };

  return (
    <div className={styles.enableLink}>
      <div className={styles.contentWrap}>
        <EditLinkDuration
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.LinkDuration)}
        />

        <EditOfferDeadline
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.OfferDeadline)}
        />

        <EditListingAgent
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.ListingAgent)}
        />

        <EditNotifications
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.Notifications)}
        />

        <EditDocuments data={data} onEdit={() => setCurrentStageIndex(stagesStep.Documents)} />
        <EditNotes data={data} onEdit={() => setCurrentStageIndex(stagesStep.Notes)} />
        <EditNotify data={data} onEdit={() => setCurrentStageIndex(stagesStep.Notify)} />

        <div className={styles.content}>
          <div className={styles.enableLinkHeader}>
            <IconText
              variant={'enable-link-bg'}
              text={'Do you want to enable the link to the offer portal now?'}
              className={styles.iconText}
            />
            <Switch
              disabled={loading}
              className={classNames(styles.switch, { [styles.switchChecked]: state.EnableLink })}
              checked={state.EnableLink}
              defaultChecked={state.EnableLink}
              onChange={(v) => {
                setState({ EnableLink: v });
                onFinish({ ...data, EnableLink: v });
              }}
            />
          </div>

          {loading ? (
            <Spinner loaderClassName={styles.loadingSpinner} />
          ) : !state.EnableLink ? (
            <div className={classNames(styles.linkContainer, styles.disableLink)}>
              The link is not active yet.
            </div>
          ) : (
            <div className={styles.linkContainer}>
              <a>{transaction?.OfferLink?.Link || Link}</a>
              <div className={styles.copyBtn} onClick={copyLink}>
                <Icons variant={'copy'} className={styles.copyIcon} />
                <span>Copy</span>
              </div>
            </div>
          )}
        </div>
        <div ref={scrollableDivRef}>
          <InformationText
            text={
              state.EnableLink
                ? 'This link will be available on your transaction page if you want to copy it later.'
                : "If you're not ready to enable it, we can save your configuration for later."
            }
          />
        </div>
      </div>

      <Navigation
        data={data}
        stageIndex={stageIndex}
        onClose={onClose}
        onFinish={() => (transaction.OfferLink ? onClose() : onFinish())}
        className={styles.footer}
        isLast={true}
        disabled={loading}
      />
    </div>
  );
};
