import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Info = ({ value, className }) => {
  if (!value) return null;

  return <div className={classNames(styles.infoWithinMarker, className)}>{value}</div>;
};

Info.propTypes = {
  className: PropTypes.string,
  value: PropTypes.node,
};

Info.defaultProps = {
  className: '',
  value: undefined,
};

export default Info;
