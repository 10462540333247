import { ReactNode } from 'react';
import AntDrawer, { DrawerProps as AntdDrawerProps } from 'antd/lib/drawer';
import classNames from 'classnames';

import { Footer } from 'components-antd';

import styles from './styles.module.scss';

interface DrawerProps extends AntdDrawerProps {
  header?: ReactNode;
  children?: ReactNode;
  footer?: boolean;
}

export const Drawer = ({ header, children, footer, className, ...props }: DrawerProps) => (
  <AntDrawer
    closable={false}
    width={'100%'}
    getContainer={false}
    destroyOnClose={true}
    className={classNames(styles.drawer, className)}
    {...props}
  >
    <div className={styles.drawerHeaderContainer}>{header}</div>
    <div className={styles.drawerBody}>
      <div className={footer ? styles.drawerContentWithFooter : styles.drawerContent}>
        {children}
      </div>
      {footer && <Footer />}
    </div>
  </AntDrawer>
);
