import moment from 'moment';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Delete, List, Edit, Rule } from 'components/Icons';
import { Space, Dropdown, Menu, MenuItemType } from 'components-antd';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { getAgentTeamRoleSelector, getAgentTeamIdSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_OWNER, TEAM_ADMIN, TEAM_BASIC } from 'settings/constants/roles';
import { TransactionTemplate } from 'types';
import { PropertyTypeMap } from 'types/property';

import styles from './styles.module.scss';
import { getFormMetaSelect } from 'store/selectors/formBuilder';
import { getAllTeamsEffect } from 'store/effects';

interface TemplateCardProps {
  template: TransactionTemplate;
  onSetRules: (templateId: number) => void;
  onView: (templateId: number) => void;
  onEdit: (templateId: number) => void;
  onDelete: (templateId: number) => void;
}

export const TemplateCard = ({
  template,
  onSetRules,
  onView,
  onEdit,
  onDelete,
}: TemplateCardProps) => {
  const userId = useSelector(getUserId);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const teamId = useSelector(getAgentTeamIdSelector);
  const { isSuperUser } = useSelector(getUserRolesMapSelector);

  const { teams: allTeams = [] } = useSelector(getFormMetaSelect);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuperUser && !allTeams?.length) dispatch(getAllTeamsEffect());
  }, []);

  const [menuItems, setMenuItems] = useState<MenuItemType[]>([
    {
      key: '2',
      label: (
        <div className={styles.dropdownItem} onClick={() => onView(template.Id)}>
          <List className={classNames(styles.icon)} />
          <span>View</span>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div className={styles.dropdownItem} onClick={() => onEdit(template.Id)}>
          <Edit className={classNames(styles.icon)} />
          <span>Edit</span>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className={styles.dropdownItem} onClick={() => onDelete(template.Id)}>
          <Delete className={styles.icon} />
          <span>Delete</span>
        </div>
      ),
    },
  ]);

  useEffect(() => {
    if (isSuperUser) {
      const items = [...menuItems];
      items.unshift({
        key: '1',
        label: (
          <div className={styles.dropdownItem} onClick={() => onSetRules(template.Id)}>
            <Rule className={classNames(styles.icon)} />
            <span>Add Rules</span>
          </div>
        ),
      });
      setMenuItems(items);
    }
  }, [isSuperUser]);

  const getTemplateActions = () => {
    if (
      !isSuperUser &&
      template.IsGlobal &&
      !(template.TeamId === teamId || (template.Teams || []).includes(teamId))
    )
      return [menuItems[0]];
    else if (
      userId === template.CreatorId ||
      isSuperUser ||
      ([TEAM_OWNER, TEAM_ADMIN].includes(agentRole) &&
        (template.TeamId === teamId || (template.Teams || []).includes(teamId)))
    ) {
      return menuItems;
    }

    return [menuItems[0]];
  };

  return (
    <div className={styles.templateCard}>
      <div className={styles.tcLeft}>
        <div className={styles.templateInfo}>
          <h3 className={styles.title}>{template.Name}</h3>
          <p className={styles.description}>{template.Description}</p>
          {template?.IsProject ? (
            <ul className={styles.metaInfo}>
              <li>
                Category:{' '}
                <span>
                  {template?.TransactionCategory?.Category
                    ? template?.TransactionCategory?.Category
                    : 'N/A'}
                </span>
              </li>
            </ul>
          ) : (
            <ul className={styles.metaInfo}>
              <li>
                Property Type:{' '}
                <span>
                  {template.PropertyType?.length
                    ? template.PropertyType.map((val) => PropertyTypeMap[val]).join(', ')
                    : 'N/A'}
                </span>
              </li>
              <li>
                Financing:{' '}
                <span>{template.Financing?.length ? template.Financing.join(', ') : 'N/A'}</span>
              </li>
            </ul>
          )}
          {isSuperUser && template?.Creator && userId !== template.CreatorId ? (
            <ul className={styles.metaInfo}>
              <li>
                <strong>Agent Name:</strong> {template.Creator?.FirstName}{' '}
                {template.Creator?.LastName}
              </li>
              <li>
                <strong>Team Name:</strong>{' '}
                {allTeams.find((team) => String(team.Id) === String(template.TeamId))?.Name}
              </li>
              <li>
                <strong>Created At:</strong>{' '}
                {moment(template.CreatedDate).format('MM/DD/YY [at] hh:mm A')}
              </li>
            </ul>
          ) : (
            <></>
          )}
        </div>
        {isSuperUser && template?.Editor?.Email ? (
          <div className={styles.editInfo}>
            <span>
              <strong>Editing:</strong> {template.Editor?.Email}
            </span>
            <span>
              <strong>Date:</strong> {moment(template.EditDate).format('MM/DD/YY [at] hh:mm A')}
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.tcRight}>
        <div id="dropdown" className={styles.dropdownWrap}>
          <Dropdown
            overlay={<Menu items={getTemplateActions()} />}
            trigger={['click']}
            overlayClassName={styles.transactionMoreOptions}
            getPopupContainer={() => document.getElementById('dropdown')!}
            placement="bottomRight"
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Icon variant={Icon.DOTS} className={styles.dropdownTrigger} />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
