import { createSelector } from 'reselect';

const localState = ({ subscription }) => subscription;
export const getAvailablePlansSelector = createSelector(
  localState,
  ({ availablePlans }) => availablePlans,
);
export const getCurrentPlanLevelSelector = createSelector(
  localState,
  ({ currentPlanLevel }) => currentPlanLevel,
);

export const getImplementationPlanSelector = createSelector(
  localState,
  ({ implementationPlan }) => implementationPlan,
);

export const getStandardCouponSelector = createSelector(
  localState,
  ({ standardCoupon }) => standardCoupon,
);

export const getStandardSubscriptionSelector = createSelector(
  localState,
  ({ standardSubscription }) => standardSubscription,
);

export const getLegacySubscriptionSelector = createSelector(
  localState,
  ({ legacySubscription }) => legacySubscription,
);

export const getCancelSubscriptionSelector = createSelector(
  localState,
  ({ cancelSubscription }) => cancelSubscription,
);

export const getReactivateSubscriptionSelector = createSelector(
  localState,
  ({ reactivateSubscription }) => reactivateSubscription,
);

export const getUpdateStandardSubscriptionSelector = createSelector(
  localState,
  ({ updateStandardSubscription }) => updateStandardSubscription,
);
