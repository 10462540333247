import { reduce, values, forEach } from 'lodash-es';

export const getLinksByRoles =
  (linksOrGetLinks = []) =>
  (roles = [], options) => {
    const links =
      typeof linksOrGetLinks === 'function' ? linksOrGetLinks(options) : linksOrGetLinks;
    const res = reduce(
      roles,
      (acc, role) => {
        forEach(links[role], (roleRoute) => {
          if (
            !acc[roleRoute.link] &&
            (typeof roleRoute.useLink !== 'function' || roleRoute.useLink(options))
          ) {
            acc[roleRoute.link] = roleRoute;
          }
        });
        return acc;
      },
      {},
    );

    return values(res);
  };
