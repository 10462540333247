import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col } from 'components-antd';
import { AsideNavigation, Content, PageWrapper } from 'components/Transactions';
import { TransactionActivityLogSection } from './components/TransactionActivityLogSection';
import { getKeyDatesEffect, getTransactionEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';

export const TransactionActivity: React.FC<any> = ({ match }) => {
  const dispatch = useDispatch();

  useEffect((): any => {
    dispatch(getTransactionEffect({ id: match.params.id }, { silent: true }));
    dispatch(getKeyDatesEffect({ transactionId: match.params.id }, { silent: true }));
  }, []);

  return (
    <div className={classNames(styles.transactionActivity)}>
      <PageWrapper>
        <AsideNavigation />
        <Content>
          <Row align="middle" className={classNames(styles.transactionActivityWrapper)}>
            <Col xs={24} lg={24} xxl={24}>
              <TransactionActivityLogSection />
            </Col>
          </Row>
        </Content>
      </PageWrapper>
    </div>
  );
};
