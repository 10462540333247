import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { uniqBy, cloneDeep } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { Optional } from './Optional';
import { Locations } from 'components';
import { sortLocations } from 'helpers';
import { FinancingType } from 'types/offers';
import { PropertyType, PropertyTypeMap } from 'types/property';
import { getFormattedLocations } from 'helpers';
import { getAllTeamsEffect } from 'store/effects';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getFormMetaSelect } from 'store/selectors/formBuilder';
import { getUserFormMetaEffect } from 'store/effects/formBuilder';
import { Row, Col, Form, Input, Select, TextArea } from 'components-antd';
import { FormGeoLocations } from 'pages/FormBuilder/components/FormGeoLocations';
import { getProjectCategoriesDataArray } from 'store/selectors/projects';
import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.scss';

interface DetailPaneProps {
  locations: any[];
  setLocations: (location: any[]) => void;
  isViewMode: boolean;
  isProject?: boolean;
  teamId?: number;
}

export const DetailPane = ({
  locations,
  setLocations,
  isViewMode,
  isProject,
  teamId,
}: DetailPaneProps) => {
  const dispatch = useDispatch();

  const form = Form.useFormInstance();

  const projectCategories = useSelector(getProjectCategoriesDataArray);

  const { isSuperUser } = useSelector(getUserRolesMapSelector);
  const { users: allUsers = [], teams: allTeams = [] } = useSelector(getFormMetaSelect);

  const propertyType: string[] | undefined = Form.useWatch('PropertyType', form);
  const financing: string[] | undefined = Form.useWatch('Financing', form);

  useEffect(() => {
    if (isSuperUser && !allUsers?.length) dispatch(getUserFormMetaEffect());
    if (isSuperUser && !allTeams?.length) dispatch(getAllTeamsEffect());
  }, []);

  useEffect(() => {
    if (propertyType?.includes('All')) {
      form.setFieldValue('PropertyType', Object.values(PropertyType));
    }
  }, [propertyType]);

  useEffect(() => {
    if (financing?.includes('All')) {
      form.setFieldValue('Financing', Object.values(FinancingType));
    }
  }, [financing]);

  const updateLocation = (result, prepareData, prevValue, onChange) => {
    const locations = prevValue || [];

    let updatedData = locations?.length ? [...locations] : [];

    let areasOfOperation = [...(prepareData?.length ? prepareData : []), ...updatedData];

    if (result?.deleteItemId) {
      areasOfOperation = areasOfOperation.filter(
        ({ ProviderPlaceId: itemId }) => itemId !== result.deleteItemId,
      );
    }

    areasOfOperation = uniqBy(areasOfOperation, 'ProviderPlaceId');
    areasOfOperation.sort(sortLocations);
    onChange(cloneDeep(areasOfOperation));
  };

  const areas = useMemo(() => {
    const val = getFormattedLocations(locations)?.[0];
    if (!val?.areasOfOperation?.length) return [];
    const grouped = val.areasOfOperation.reduce((acc, cur) => {
      const radius = cur?.Radius || 0;

      if (!acc[radius]) acc[radius] = [cur];
      else acc[radius].push(cur);
      return acc;
    }, {});

    return Object.keys(grouped).map((key, index) => ({
      UUID: uuidv4(),
      miles: Number(key),
      areasOfOperation: grouped[key],
    }));
  }, [locations]);

  return (
    <div className={styles.detailPaneContainer}>
      <Row className={styles.row}>
        <Col md={24} lg={6}>
          <div className={styles.label}>Transaction</div>
        </Col>
        <Col md={24} lg={18}>
          <Form.Item
            className={styles.formItem}
            name="Name"
            validateTrigger={'onSubmit'}
            rules={[
              {
                required: true,
                message: 'Required',
              },
            ]}
          >
            <Input
              size="large"
              className="mosaikFormInput"
              placeholder="Add transaction name"
              disabled={isViewMode}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col md={24} lg={6}>
          <div className={styles.label}>
            Description <Optional />
          </div>
        </Col>
        <Col md={24} lg={18}>
          <Form.Item className={styles.formItem} name="Description">
            <TextArea
              autoSize
              size="large"
              className="mosaikFormInput"
              placeholder="Add description"
              disabled={isViewMode}
            />
          </Form.Item>
        </Col>
      </Row>
      {isProject ? (
        <Row className={styles.row}>
          <Col md={24} lg={6}>
            <div className={styles.label}>
              Category <Optional />
            </div>
          </Col>
          <Col md={24} lg={18}>
            <Form.Item className={styles.formItem} name="TransactionCategoryId">
              <Select
                size="large"
                className="mosaikFormSelect"
                placeholder="Select category"
                options={projectCategories?.map((projectCategory) => ({
                  value: projectCategory.Id,
                  label: projectCategory.Category,
                }))}
                disabled={isViewMode}
              />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <>
          <Row className={styles.row}>
            <Col md={24} lg={6}>
              <div className={styles.label}>
                Property Type <Optional />
              </div>
            </Col>
            <Col md={24} lg={18}>
              <Form.Item className={styles.formItem} name="PropertyType">
                <Select
                  size="large"
                  mode="multiple"
                  className="mosaikFormSelect"
                  placeholder="Select property type"
                  options={[
                    { value: 'All', label: 'All' },
                    ...Object.values(PropertyType).map((val) => ({
                      value: val,
                      label: PropertyTypeMap[val],
                    })),
                  ]}
                  disabled={isViewMode}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md={24} lg={6}>
              <div className={styles.label}>
                Financing <Optional />
              </div>
            </Col>
            <Col md={24} lg={18}>
              <Form.Item className={styles.formItem} name="Financing">
                <Select
                  size="large"
                  mode="multiple"
                  className="mosaikFormSelect"
                  placeholder="Select financing type"
                  options={[
                    { value: 'All', label: 'All' },
                    ...Object.values(FinancingType).map((val) => ({ value: val, label: val })),
                  ]}
                  disabled={isViewMode}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      {isSuperUser ? (
        <>
          <Row className={styles.row}>
            <Col md={24} lg={6}>
              <div className={styles.label}>
                People <Optional />
              </div>
            </Col>
            <Col md={24} lg={18}>
              <Form.Item className={styles.formItem} name="People">
                <Select
                  placeholder="Select People"
                  size="large"
                  mode="multiple"
                  className="mosaikFormSelect"
                  options={allUsers.map((el) => ({
                    label: el.Name,
                    value: el.UserId,
                    key: `users-${el.UserId}`,
                  }))}
                  filterOption={(input, option) =>
                    String((option?.label as unknown as string) || '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  disabled={isViewMode}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md={24} lg={6}>
              <div className={styles.label}>
                Teams <Optional />
              </div>
            </Col>
            <Col md={24} lg={18}>
              <Form.Item className={styles.formItem} name="Teams">
                <Select
                  placeholder="Select Teams"
                  size="large"
                  className="mosaikFormSelect"
                  options={allTeams.map((el) => ({
                    label: el.Name,
                    value: el.Id,
                    key: `Team-${el.Id}`,
                  }))}
                  filterOption={(input, option) =>
                    String((option?.label as unknown as string) || '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  disabled={isViewMode || !!teamId}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row className={styles.row}>
            <Col md={24} lg={6}>
              <div className={styles.label}>
                Locations <Optional />
              </div>
            </Col>
            <Col md={24} lg={18}>
              <Form.Item className={classNames(styles.formItem, styles.disabled)} name="Locations">
                <FormGeoLocations
                  showMiles={true}
                  multiple={true}
                  onChange={setLocations}
                  locations={areas || []}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row> */}
        </>
      ) : (
        <></>
      )}
      {!isProject ? (
        <>
          <Row className={styles.row}>
            <Col md={24} lg={6}>
              <div className={styles.label}>
                State <Optional />
              </div>
            </Col>
            <Col md={24} lg={18}>
              <Form.Item className={styles.formItem} name="State">
                <TransactionLocation
                  updateLocation={updateLocation}
                  placeHolder="Add state"
                  isViewMode={isViewMode}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md={24} lg={6}>
              <div className={styles.label}>
                County <Optional />
              </div>
            </Col>
            <Col md={24} lg={18}>
              <Form.Item className={styles.formItem} name="County">
                <TransactionLocation
                  updateLocation={updateLocation}
                  placeHolder="Add county"
                  isViewMode={isViewMode}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md={24} lg={6}>
              <div className={styles.label}>
                City <Optional />
              </div>
            </Col>
            <Col md={24} lg={18}>
              <Form.Item className={styles.formItem} name="City">
                <TransactionLocation
                  updateLocation={updateLocation}
                  placeHolder="Add city"
                  isViewMode={isViewMode}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

interface TransactionLocationProps {
  onChange?: (e) => void;
  value?: Location[];
  updateLocation: (...params) => void;
  placeHolder: string;
  error?: string;
  isViewMode: boolean;
}

const TransactionLocation = ({
  onChange,
  updateLocation,
  error,
  value,
  placeHolder,
  isViewMode,
}: TransactionLocationProps) => {
  return (
    <span className={classNames(styles.locationWrapper, styles.fieldText)}>
      <Locations
        multiple
        allowStates={true}
        getStates={true}
        variant="light"
        onResult={(result, prepareData) => updateLocation(result, prepareData, value, onChange)}
        value={value}
        error={error}
        placeholder={placeHolder}
        activeInputClassName={styles.activeInputClassName}
        valuesWrapperClassName={styles.valuesWrapperClassName}
        searchIcon={!!value?.length}
        valueClassName={styles.valueServiceLocation}
        placeholderClassName={styles.placeholderClassName}
        disabled={isViewMode}
      />
    </span>
  );
};
