import { handleActions } from 'redux-actions';

import { sortFeedAction } from 'store/actions/feedv3';
import { SORT_DESC } from 'settings/constants/sort';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';

const initialData = {
  order: SORT_DESC,
  fields: [],
};

export default handleActions(
  {
    [sortFeedAction as any]: (state, { payload }) => ({
      ...state,
      order: payload.order || state.order,
      fields: payload.fields || state.fields,
    }),
    [appLogoutAction as any]: () => cloneDeep(initialData),
  },
  initialData,
);
