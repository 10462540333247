import { FC, MouseEventHandler } from 'react';

interface Props {
  className?: string;
  onClick?: (event: any) => void;
}

export const UploadIcon: FC<Props> = ({ onClick, className }) => {
  return (
    <div onClick={onClick} className={className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.16602 11.6665V13.8332C4.16602 14.9377 5.06145 15.8332 6.16602 15.8332H13.8327C14.9372 15.8332 15.8327 14.9377 15.8327 13.8332V11.6665"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66602 7.49984L9.99935 4.1665L13.3327 7.49984"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 4.1665V11.6665"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
