import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ offerAggregate }) => offerAggregate;

export const getOfferAggregateDataSelector = createSelector(localState, ({ data }) => {
  return data;
});

export const getOfferAggregateList = createSelector(localState, (currentState) => {
  const offerAggregate = currentState?.data;
  return {
    ...offerAggregate,
    isIdle: currentState?.state === IDLE,
    isPending: currentState?.state === PENDING,
    isData: !!currentState?.data,
  };
});

export const getOfferIsArchiveSelector = createSelector(localState, (currentState) => ({
  isArchive: currentState.isArchive,
}));

export const getOfferFiltersAppliedSelector = createSelector(localState, (currentState) => ({
  isFiltersApplied: currentState.isFiltersApplied,
}));

export const getOfferArchiveIdSelector = createSelector(localState, (currentState) => ({
  offerId: currentState.offerId,
}));
