import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reduce } from 'lodash-es';

import { NoThreads } from 'components';

import { useLongPress } from 'hooks';

import { changeMessagesDrawerTypeAction } from 'store/actions/drawers/messages';

import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';

import ActionsList from 'components/Drawers/components/ActionsList';

import {
  getArchiveThreadOption,
  getAddParticipantThreadOption,
  getArchiveThreadsListOption,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import PartnerMessages from './PartnerMessages';

const GroupedServicesThreads = ({ list, openGroupLabel, archived }) => {
  const dispatch = useDispatch();
  const [actionsPosition, setActionPosition] = useState(null);
  const [actions, setActions] = useState([]);

  const openHandler = useCallback(
    (partner, threads) =>
      dispatch(
        changeMessagesDrawerTypeAction({
          type: threads.length === 1 ? DRAWER_MESSAGES_TYPES.CHAT : DRAWER_MESSAGES_TYPES.SERVICE,
          params: {
            threadId: threads.length === 1 ? threads[0].Id : undefined,
            partnerId: threads.length > 1 ? partner?.Id : undefined,
            archived,
          },
        }),
      ),
    [dispatch, archived],
  );

  const onLongPress = (event, [partner, threads]) => {
    setActionPosition({ x: event?.clientX, y: event?.clientY });
    if (threads.length === 1) {
      setActions([
        {
          title: 'Open message',
          onClick: () => openHandler(partner, threads),
          testid: 'open_message',
        },
        ...getAddParticipantThreadOption(dispatch, {
          info: threads[0]?.TopicName,
          type: threads[0]?.Type,
          threadId: threads[0]?.Id,
        }),
        ...getArchiveThreadOption(dispatch, threads[0], () => setActionPosition(null)),
      ]);
    } else {
      setActions([
        {
          title: openGroupLabel,
          onClick: () => openHandler(partner, threads),
          testid: 'open_partner',
        },
        ...getArchiveThreadsListOption(dispatch, threads, () => setActionPosition(null)),
      ]);
    }
  };
  const onPress = (event, [partner, threads]) => {
    openHandler(partner, threads);
  };

  const longPressEvent = useLongPress(onLongPress, onPress);

  const dataList = useMemo(
    () =>
      reduce(
        list,
        (acc, { user, quotes, unreadQuotes, notQuotes, unreadNotQuotes }) => {
          const threadsList = [...quotes, ...notQuotes];
          const unread = unreadQuotes + unreadNotQuotes;

          if (threadsList?.length) {
            acc.push({ threads: threadsList, user, unread });
          }
          return acc;
        },
        [],
      ),
    [list],
  );

  return (
    <>
      {dataList?.length ? (
        dataList.map(({ user, threads, unread }) => (
          <PartnerMessages
            key={user.Id}
            data={user}
            unreadNumber={unread}
            threads={threads}
            {...longPressEvent(user, threads)}
          />
        ))
      ) : (
        <NoThreads />
      )}
      <ActionsList actions={actions} position={actionsPosition} />
    </>
  );
};

GroupedServicesThreads.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openGroupLabel: PropTypes.string,
  archived: PropTypes.bool,
};

GroupedServicesThreads.defaultProps = {
  openGroupLabel: 'Open partner',
  archived: false,
};

export default GroupedServicesThreads;
