import React, { useCallback, useState, useEffect } from 'react';
import { chunk } from 'lodash-es';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { appOpenModalEffect } from 'store/effects/app';

import { useScreen } from 'hooks';
import { pushOrRemoveStageOptionArr } from 'pages/OnBoardingWizard/helpers';
import { INTERIOR_PREFS_OPTIONS, prefsIds } from 'settings/constants/preferences';
import LayoutModal from '../LayoutModal';
import StylesModal from '../StylesModal';
import KitchenFeaturesModal from '../KitchenFeaturesModal';
import FlooringModal from '../FlooringModal';

import Option from './Option';
import { Row, Col } from 'antd';

import styles from './styles.module.scss';

const Options = ({
  className,
  onChange,
  value,
  layoutValues,
  kitchenFeatureValues,
  flooringValues,
  disabled,
  isHomeTypeLand,
  testid,
}) => {
  const [actives, setActives] = useState(value);
  const { screen } = useScreen();
  const dispatch = useDispatch();

  useEffect(() => setActives(value), [value]);

  const openModal = useCallback(
    (id) => {
      dispatch(appOpenModalEffect({ id, open: true }));
    },
    [dispatch],
  );

  const onClick = useCallback(
    (id) => {
      const newActives = pushOrRemoveStageOptionArr({ arr: actives, value: id, multiple: true });

      setActives(newActives);
      onChange(newActives);

      // Selection of styles is disabled for now as it's currently broken in different parts of the app
      // Properly implement it once decided on what should be the behavior
      // if (newActives.includes(prefsIds.style) && id === prefsIds.style) {
      //   openModal(StylesModal.id);
      // } else

      if (newActives.includes(prefsIds.layoutPrefs) && id === prefsIds.layoutPrefs) {
        return openModal(LayoutModal.id);
      } else if (newActives.includes(prefsIds.flooring) && id === prefsIds.flooring) {
        return openModal(FlooringModal.id);
      } else if (newActives.includes(prefsIds.kitchenFeatures) && id === prefsIds.kitchenFeatures) {
        return openModal(KitchenFeaturesModal.id);
      }
    },
    [onChange, actives, openModal],
  );

  const onEdit = (e, optionId) => {
    e.stopPropagation();
    switch (optionId) {
      // case prefsIds.style:
      //   return openModal(StylesModal.id);
      case prefsIds.layoutPrefs:
        return openModal(LayoutModal.id);
      case prefsIds.flooring:
        return openModal(FlooringModal.id);
      case prefsIds.kitchenFeatures:
        return openModal(KitchenFeaturesModal.id);
      default:
        return null;
    }
  };

  const getOptions = () => {
    const interiorOptions = isHomeTypeLand
      ? INTERIOR_PREFS_OPTIONS.filter(
          (opt) =>
            ![
              prefsIds.moveInReady,
              prefsIds.newConstruction,
              prefsIds.layoutPrefs,
              prefsIds.style,
              prefsIds.kitchenFeatures,
              prefsIds.flooring,
            ].includes(opt.id),
        )
      : INTERIOR_PREFS_OPTIONS;

    if (screen.mobileSmallWidth) {
      return chunk(interiorOptions, 1);
    }
    if (screen.mobileWidth) {
      return chunk(interiorOptions, 2);
    }
    return chunk(interiorOptions, 3);
  };

  return (
    <div className={classNames(styles.wrapper, { [styles.disabled]: disabled }, className)}>
      <Row gutter={[32, 16]}>
        {getOptions().map((option, index) => (
          <Option
            key={index} // eslint-disable-line
            option={option}
            actives={actives}
            layoutValues={layoutValues}
            kitchenFeatureValues={kitchenFeatureValues}
            flooringValues={flooringValues}
            onClick={onClick}
            onEdit={onEdit}
            testid={testid}
          />
        ))}
      </Row>
    </div>
  );
};

Options.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  testid: PropTypes.string,
  layoutValues: PropTypes.arrayOf(PropTypes.string),
  kitchenFeatureValues: PropTypes.arrayOf(PropTypes.string),
  flooringValues: PropTypes.arrayOf(PropTypes.string),
  isHomeTypeLand: PropTypes.bool,
};

Options.defaultProps = {
  className: '',
  onChange: () => {},
  value: [],
  disabled: false,
  testid: undefined,
  isHomeTypeLand: false,
};

export default Options;
