import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'components';

interface WrapperProps {
  className?: string;
  spinnerClassname?: string;
  isPending: boolean;
  children?: React.ReactNode;
  loaderClassName?: string;
  testid?: string;
  customLoader?: React.ReactNode;
}

const Wrapper = forwardRef<any, WrapperProps>(
  (
    { isPending, className, spinnerClassname, children, loaderClassName, testid, customLoader },
    ref,
  ) => (
    <div testid={testid} ref={ref} className={className}>
      {isPending
        ? customLoader ?? <Spinner className={spinnerClassname} loaderClassName={loaderClassName} />
        : children}
    </div>
  ),
);

Wrapper.propTypes = {
  className: PropTypes.string,
  spinnerClassname: PropTypes.string,
  isPending: PropTypes.bool.isRequired,
  children: PropTypes.node,
  customLoader: PropTypes.node,
  loaderClassName: PropTypes.string,
  testid: PropTypes.string,
};

Wrapper.defaultProps = {
  className: '',
  spinnerClassname: '',
  children: undefined,
  testid: undefined,
  loaderClassName: '',
  customLoader: undefined,
};

export default Wrapper;
