import { apiServer } from 'settings/web-services/api';

export function deleteTransactionDocument(cfg) {
  const { transactionId, documentId } = cfg;
  return apiServer.delete(`/transaction/${transactionId}/documents/${documentId}`);
}

export function editTransactionDocument(cfg) {
  const { transactionId, documentId, ...config } = cfg;
  return apiServer.put(`/transaction/${transactionId}/documents/${documentId}`, config);
}
