import { Input } from 'components';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { FormContainer, FormFooter, FormHeader } from 'pages/OnboardingV2Agent/components';
import styles from './styles.module.scss';
import { Values } from 'pages/SignupV2/SignupV2';
import { useDispatch } from 'react-redux';
import { emailExistsEffect } from 'store/effects';

enum FormFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
}

const schema = Yup.object().shape({
  [FormFields.FIRST_NAME]: Yup.string().trim().required('First Name is required'),
  [FormFields.LAST_NAME]: Yup.string().trim().required('Last Name is required'),
  [FormFields.EMAIL]: Yup.string().email('Invalid email').required('Email is required'),
});

type FormValues = {
  [key in FormFields]: string;
};

type Props = {
  onNext: (values: Partial<Values>) => void;
  values: Values;
};

export const PersonalDetails: React.FC<Props> = ({ onNext, values }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = (values: FormValues) => {
    setIsLoading(true);
    dispatch(
      emailExistsEffect(
        {
          params: {
            email: formik.values.email,
          },
        },
        (err, resp) => {
          setIsLoading(false);
          if (resp?.data?.exists) {
            formik.setErrors({
              email: 'Email already exists',
            });
          } else if (!err) {
            setIsLoading(false);
            onNext(values);
          }
        },
      ),
    );
  };

  const formik = useFormik({
    initialValues: {
      [FormFields.FIRST_NAME]: values[FormFields.FIRST_NAME] || '',
      [FormFields.LAST_NAME]: values[FormFields.LAST_NAME] || '',
      [FormFields.EMAIL]: values[FormFields.EMAIL] || '',
    } as FormValues,
    validationSchema: schema,
    onSubmit: onSubmit,
  });
  return (
    <FormContainer>
      <div className={styles.formContainer}>
        <FormHeader title="Please enter your contact information." />

        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <div className={styles.inputContainer}>
            <Input
              type="text"
              name={FormFields.FIRST_NAME}
              value={formik.values[FormFields.FIRST_NAME]}
              onChange={formik.handleChange}
              className={styles.input}
              error={
                formik.touched[FormFields.FIRST_NAME] ? formik.errors[FormFields.FIRST_NAME] : ''
              }
              errorClassName={styles.error}
              disabled={isLoading}
              variant={Input.LIGHT_ROUND}
              label={'First Name'}
            />
            <Input
              type="text"
              name={FormFields.LAST_NAME}
              value={formik.values[FormFields.LAST_NAME]}
              onChange={formik.handleChange}
              className={styles.input}
              error={
                formik.touched[FormFields.LAST_NAME] ? formik.errors[FormFields.LAST_NAME] : ''
              }
              disabled={isLoading}
              errorClassName={styles.error}
              variant={Input.LIGHT_ROUND}
              label={'Last Name'}
            />
          </div>
          <div className={styles.inputContainer}>
            <Input
              type="text"
              name={FormFields.EMAIL}
              value={formik.values[FormFields.EMAIL]}
              onChange={formik.handleChange}
              className={styles.input}
              error={formik.touched[FormFields.EMAIL] ? formik.errors[FormFields.EMAIL] : ''}
              disabled={isLoading}
              variant={Input.LIGHT_ROUND}
              errorClassName={styles.error}
              label={'Email Address'}
            />
          </div>
          <FormFooter
            isDisabled={
              !!formik.errors?.email || !!formik.errors?.firstName || !!formik.errors?.lastName
            }
            isPending={isLoading}
          />
        </form>
      </div>
    </FormContainer>
  );
};
