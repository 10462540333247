import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Avatar, Select } from 'components';
import { preFormQuestionsIds } from 'settings/constants/preForm';
import { requestGetClientsListEffect } from 'store/effects/clientsList';
import { getActiveClientsList, getStateAndDataFlag } from 'store/selectors/clientsList';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { getPreFormSelector } from 'store/selectors/requestQuote';

import { setPreFormQuestionsEffect } from 'store/effects/quotes';
import styles from './styles.module.scss';

const ClientInput = (props) => {
  const { className, onChange, wrapperClassName, onBlur, valueClassName, inputClassName } = props;
  const dispatch = useDispatch();
  const activeClients = useSelector(getActiveClientsList);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const { isIdle, isData } = useSelector(getStateAndDataFlag);
  const preForm = useSelector(getPreFormSelector);

  useEffect(() => {
    if (!isAgent) return null;

    dispatch(requestGetClientsListEffect({}, { silent: !isIdle && isData }));
  }, []); // eslint-disable-line

  const getOptions = () => {
    if (!activeClients) return [];
    return activeClients.map((client) => ({
      id: client.id,
      name: client.name,
      value: client.id,
      role: client.role,
      avatarUrl: client.avatarUrl,
      firstName: client.firstName,
      lastName: client.lastName,
    }));
  };

  const renderOption = ({ optionProps, optionData, optionSnapshot }) => {
    const optProps = { ...optionProps, value: optionData.value, disabled: optionData.disabled };
    return (
      <button
        type="button"
        className={classNames(styles.option, {
          [styles['is-highlighted']]: optionSnapshot.highlighted,
        })}
        {...optProps}
      >
        <div className={styles.optionInnerHolder}>
          <Avatar
            avatarClassName={styles.avatar}
            src={optionData.avatarUrl}
            placeholder={
              <div className={styles.avatarPlaceholder}>
                {convertNameToAvatarPlaceholder(`${optionData.firstName} ${optionData.lastName}`)}
              </div>
            }
          />
          <span>{optionData.name}</span>
        </div>
      </button>
    );
  };

  const onSelectHandler = (event, val) => {
    dispatch(
      setPreFormQuestionsEffect({
        [preFormQuestionsIds.client]: val,
        [preFormQuestionsIds.isClient]: true,
      }),
    );
    onChange(val);
  };

  const getPlaceholder = () => {
    const { FirstName, LastName } = preForm[preFormQuestionsIds.client] || {};
    if (FirstName && LastName) {
      return `${FirstName} ${LastName}`;
    }
    return 'Enter client’s name';
  };

  return (
    <div onBlur={onBlur} className={classNames(styles.clientInput, className)}>
      <Select
        multiple={false}
        isTrackInputValue={false}
        testid="to_input_select"
        search
        className={{
          wrapper: classNames(styles.search, wrapperClassName),
          value: classNames(styles.value, valueClassName),
          input: inputClassName,
        }}
        variant={Autocomplete.LIGHT}
        options={getOptions()}
        placeholder={getPlaceholder()}
        isEmptyValue
        onSelect={onSelectHandler}
        value={preForm[preFormQuestionsIds.client]}
        renderOption={renderOption}
        searchIconClassName={styles.searchIcon}
        isSearchIcon={false}
        isArrowIcon={false}
      />
    </div>
  );
};

ClientInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  valueClassName: PropTypes.string,
};

ClientInput.defaultProps = {
  className: '',
  inputClassName: '',
  wrapperClassName: '',
  onChange: () => {},
  onBlur: () => {},
  valueClassName: '',
};

export default ClientInput;
