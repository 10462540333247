import { useHistory } from 'react-router-dom';

const useBackPage = ({ defaultBackUrl, state = {} }) => {
  const history = useHistory();

  const onBack = (event) => {
    event?.preventDefault?.();

    // TODO: im not sure about this check
    // if (history.action !== 'POP') {
    //   history.goBack();
    // }

    history.push(defaultBackUrl, { ...state });
  };

  return [onBack];
};

export default useBackPage;
