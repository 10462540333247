import { Row, Col } from 'antd';

import classNames from 'classnames';
import { Switcher } from './Switcher';

import styles from './styles.module.scss';
import { PropertyCart } from '../components/PropertyCart';

export const LocalHeader = () => {
  return (
    <Row className={classNames(styles.propertiesHeader)} align="middle">
      <Col xs={0} xl={8} />
      <Col xs={24} xl={8} className={classNames(styles.textCenter, styles.propertiesHeaderToggle)}>
        <Switcher />
      </Col>
      <Col xs={0} md={0} lg={2} xl={3} xxl={4} />
      <Col xs={24} lg={8} xl={5} xxl={4}>
        <div className={styles.container}>
          <PropertyCart />
        </div>
      </Col>
    </Row>
  );
};
