import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Select, Option } from 'components-antd';
import { Checkmark } from 'components/Icons';
import { getConnectionTypeSelector } from 'store/selectors/app';

import styles from './styles.module.scss';

const DrawerHeader = ({
  testid,
  title,
  titleOptions,
  onChange,
  TitleIcon,
  ButtonIcon,
  CloseIcon,
}) => {
  const connectionType = useSelector(getConnectionTypeSelector);

  return (
    <div testid={testid} className={styles.clientsHeader}>
      <div className={styles.titleContainer}>
        {titleOptions?.length > 0 ? (
          <Select
            size={'large'}
            defaultValue={connectionType}
            className={styles.drawerHeaderSelect}
            popupClassName={styles.drawerHeaderDropdown}
            onChange={onChange}
            placement={'bottomLeft'}
            dropdownIconColor={'#262626'}
            menuItemSelectedIcon={<Checkmark className={styles.iconCheckmark} color="#262626" />}
          >
            {titleOptions.map(({ label, IconComponent }, index) => (
              <Option key={index} value={label}>
                <IconComponent color="pink" className={styles.selectIcon} />
                {label}
              </Option>
            ))}
          </Select>
        ) : (
          <>
            {TitleIcon}
            <p testid="drawer_header" className={styles.title}>
              {title}
            </p>
          </>
        )}
      </div>
      {!(ButtonIcon || CloseIcon) ? null : (
        <div className={styles.buttonsContainer}>
          {ButtonIcon}
          {CloseIcon}
        </div>
      )}
    </div>
  );
};
DrawerHeader.propTypes = {
  title: PropTypes.string,
  titleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      IconComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  onChange: PropTypes.func,
  TitleIcon: PropTypes.object || PropTypes.func,
  ButtonIcon: PropTypes.object || PropTypes.func,
  CloseIcon: PropTypes.object || PropTypes.func,
  testid: PropTypes.string,
};

DrawerHeader.defaultProps = {
  title: '',
  titleOptions: [],
  onChange: null,
  TitleIcon: null,
  ButtonIcon: null,
  CloseIcon: null,
  testid: undefined,
};

export default DrawerHeader;
