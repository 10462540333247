import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTimelineTemplatesEffect } from 'store/effects/templates';
import {
  getTimelineTemplatesDataSelector,
  getTimelineTemplatesSelector,
} from 'store/selectors/templates';

import { Wrapper } from 'components';

import { TemplateCard } from '../TemplateCard';

import styles from './styles.module.scss';

export const TemplateList = (props) => {
  const { PreviewTemplate, onSelect } = props;
  const dispatch = useDispatch();

  const templates = useSelector(getTimelineTemplatesDataSelector);
  const { isPending, isIdle } = useSelector(getTimelineTemplatesSelector);

  useEffect(() => {
    if (isIdle) {
      dispatch(getAllTimelineTemplatesEffect({}));
    } else {
      dispatch(getAllTimelineTemplatesEffect({}, { silent: true }));
    }
  }, []); //eslint-disable-line

  return (
    <Wrapper isPending={isPending}>
      <div className={styles.stepOne}>
        <div className={styles.MBHInner}>
          <h4>Templates</h4>
        </div>

        <div className={styles.templatesWrap}>
          {templates?.map((val, index) => {
            return (
              <TemplateCard
                key={index}
                Name={val?.Name}
                Description={val?.Description}
                PreviewTemplate={PreviewTemplate}
                TemplateId={val?.Id}
                onSelect={onSelect}
              />
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};
