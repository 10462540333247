import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { setQuotesArchiveEffect } from 'store/effects/quotes';
import { getIsArchiveQuotesSelector } from 'store/selectors/quotes';
import { Archive as ArchiveIcon } from 'components/Icons';
import styles from './styles.module.scss';

const Archive = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  const isArchive = useSelector(getIsArchiveQuotesSelector);

  const onArchive = () => {
    dispatch(setQuotesArchiveEffect(!isArchive));
  };

  return (
    <div
      testid="archive"
      onClick={onArchive}
      className={classNames(styles.archive, { [styles.active]: isArchive }, className)}
    >
      <ArchiveIcon className={styles.icon} />
    </div>
  );
};

Archive.propTypes = {
  className: PropTypes.string,
};

Archive.defaultProps = {
  className: '',
};

export default Archive;
