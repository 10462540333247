import { handleActions } from 'redux-actions';
import { get, cloneDeep } from 'lodash-es';
import { socketsResetAction } from 'store/actions/sockets';
import {
  socketsStoreEditedTransactionNoteAction,
  socketsStoreNewTransactionNoteAction,
  socketsStoreTransactionNotesByThreadIdAction,
  socketsResetTransactionNotesAction,
  socketSetEditTransactionNoteAction,
  setNewTransactionNoteWithTempIdAction,
  replaceTempTransactionNoteAction,
  socketsRemoveDeletedTransactionNoteAction,
  socketsStoreTransactionNotesSetThreadIdAction,
} from 'store/actions/sockets/transactionNotes';

const initialData = {
  currentThreadId: null,
  editMessage: null,
  messages: null,
};

export default handleActions(
  {
    [socketsStoreTransactionNotesByThreadIdAction]: (state, { payload }) => ({
      ...state,
      currentThreadId: payload.threadId,
      messages: payload.data,
    }),
    [socketsStoreTransactionNotesSetThreadIdAction]: (state, { payload: { threadId } }) => ({
      ...state,
      currentThreadId: threadId,
    }),
    [socketsStoreNewTransactionNoteAction]: (state, { payload }) => {
      const data = get(payload, 'data');

      const isMessageAlreadyExist = (state.messages || []).some(
        (message) => message?.Id === data?.Id,
      );
      if (data.MessageThreadId !== state.currentThreadId || isMessageAlreadyExist) {
        return state;
      }

      return {
        ...state,
        messages: [...(state.messages || []), ...(data ? [data] : [])],
      };
    },
    [setNewTransactionNoteWithTempIdAction]: (state, { payload }) => ({
      ...state,
      messages: [...(state.messages || []), payload],
    }),
    [replaceTempTransactionNoteAction]: (state, { payload }) => {
      const clonedMessages = cloneDeep(state.messages);
      const tempMessageIndex = (clonedMessages || []).findIndex(
        (message) => message?.TempId === payload?.TempId,
      );

      if (tempMessageIndex !== -1) {
        clonedMessages[tempMessageIndex] = payload;
      }
      return {
        ...state,
        messages: clonedMessages,
      };
    },
    [socketSetEditTransactionNoteAction]: (state, { payload }) => ({
      ...state,
      editMessage: payload?.editMessage,
    }),
    [socketsStoreEditedTransactionNoteAction]: (state, { payload }) => {
      const data = get(payload, 'data');
      const clonedMessages = cloneDeep(state.messages);
      const editedMessageIndex = (clonedMessages || []).findIndex(
        (message) => message?.Id === data?.Id,
      );

      if (editedMessageIndex !== -1) {
        clonedMessages[editedMessageIndex] = data;
      }

      return {
        ...state,
        messages: clonedMessages,
      };
    },
    [socketsRemoveDeletedTransactionNoteAction]: (state) => state,
    [socketsResetAction]: () => cloneDeep(initialData),
    [socketsResetTransactionNotesAction]: () => cloneDeep(initialData),
  },
  initialData,
);
