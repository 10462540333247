import PropTypes from 'prop-types';
import classNames from 'classnames';

import View from './View';

import styles from './styles.module.scss';

const ViewTimeline = (props) => {
  const { className, listItems, addMode, editMode, onRemove, isAggregated, setDueDate } = props;
  const { onFieldsChange, startDate, endDate } = props;

  return (
    <div className={classNames(styles.timelineComponent, className)}>
      <View
        listItems={listItems}
        addMode={addMode}
        editMode={editMode}
        startDate={startDate}
        endDate={endDate}
        onRemove={onRemove}
        onFieldsChange={onFieldsChange}
        className={classNames({ [styles.edit]: editMode })}
        isAggregated={isAggregated}
      />
    </div>
  );
};

ViewTimeline.propTypes = {
  className: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.shape({})),
  addMode: PropTypes.bool,
  editMode: PropTypes.bool,
  onRemove: PropTypes.func,
  onFieldsChange: PropTypes.func,
  isAggregated: PropTypes.bool,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

ViewTimeline.defaultProps = {
  className: '',
  listItems: [],
  isAggregated: false,
  addMode: false,
  editMode: false,
  onRemove: () => {},
  onFieldsChange: () => {},
  startDate: null,
  endDate: null,
};

export default ViewTimeline;
