import { get, pick } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { IDLE } from 'settings/constants/apiState';
import { transactionRoles, transactionsFilters, projectsFilters } from 'settings/constants/roles';
import { appLogoutAction } from 'store/actions/app';
import { requestGetTransactionPersonsAction } from 'store/actions/transactions';
import {
  requestGetAllTransactionsAction,
  setTransactionsArchiveAction,
  setTransactionsFilterAction,
  setProjectsFilterAction,
  setTransactionsSearchAction,
  requestGetAllTransactionContactsAction,
  transactionsResetAction,
  setTeamTransactionsAction,
  setTransactionFiltersAppliedAction,
} from 'store/actions/transactions/all';

const initialData = {
  state: IDLE,
  data: null,
  allTransactionContacts: null,
  meta: {},
  search: '',
  isArchive: false,
  transactionFilter: transactionsFilters.allTransactions,
  projectFilter: projectsFilters.allProjects,
  teamTransactions: false,
  isFiltersApplied: false,
  transactionsPersons: {
    coordinatorsArray: [],
    agentsOrOwnersArray: [],
    clientsArray: [],
  },
};

export default handleActions(
  {
    [requestGetAllTransactionsAction]: (state, { payload }) => {
      const result = get(payload, 'data.result', initialData.data);
      return {
        ...state,
        state: payload.state,
        data: result
          ? result.map((transaction) => {
              const buyer = transaction.Participants?.find(
                (p) => p?.Role?.Title === transactionRoles.BUYER,
              );
              const seller = transaction.Participants?.find(
                (p) => p?.Role?.Title === transactionRoles.SELLER,
              );
              return {
                ...transaction,
                Buyer: buyer
                  ? {
                      Id: buyer.Id,
                      User: { ...pick(buyer, ['FirstName', 'LastName', 'AvatarUrl', 'Id']) },
                    }
                  : buyer,
                Seller: seller
                  ? {
                      Id: seller.Id,
                      User: {
                        ...pick(seller, ['FirstName', 'LastName', 'AvatarUrl', 'Id', 'Email']),
                      },
                    }
                  : seller,
              };
            })
          : result,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [setTransactionsArchiveAction]: (state, { payload }) => ({
      ...state,
      isArchive: !!payload,
    }),
    [setTransactionsSearchAction]: (state, { payload }) => ({
      ...state,
      data: state?.data ? [...state.data] : initialData.data,
      search: payload || '',
    }),
    [requestGetAllTransactionContactsAction]: (state, { payload }) => {
      const result = get(
        payload,
        'data.allTransactionContacts',
        initialData.allTransactionContacts,
      );
      return {
        ...state,
        state: payload.state,
        allTransactionContacts: result,
      };
    },
    [setTeamTransactionsAction]: (state, { payload }) => ({
      ...state,
      teamTransactions: payload || false,
    }),
    [setTransactionFiltersAppliedAction]: (state, { payload }) => ({
      ...state,
      isFiltersApplied: payload,
    }),
    [setTransactionsFilterAction]: (state, { payload }) => ({
      ...state,
      transactionFilter: payload || transactionsFilters.allTransactions,
    }),
    [setProjectsFilterAction]: (state, { payload }) => ({
      ...state,
      projectFilter: payload || projectsFilters.myProjects,
    }),
    [transactionsResetAction]: () => initialData,
    [appLogoutAction]: () => initialData,
    [requestGetTransactionPersonsAction]: (state, { payload }) => ({
      ...state,
      transactionsPersons: payload.data || state.transactionsPersons,
    }),
  },
  initialData,
);
