import classNames from 'classnames';
import { Modal, ModalProps, Title, SelectProps } from 'components-antd';
import { Input, Spinner } from 'components';
import { useEffect, useState } from 'react';
import { ErrorMessage, Icons, RequiredAsterik } from '../../../../components';
import styles from './styles.module.scss';
import _ from 'lodash';
import { useManageTemplateDetails } from './useManageTemplateDetails';

export const EditTemplateNameModal = (props) => {
  const { open, onClose, name, templateId, bundleId } = props;

  const [templateName, setTemplateName] = useState(name);

  const {
    fetching,
    saving: loading,
    updateBundleTemplate,
    updateTemplate,
  } = useManageTemplateDetails(props);

  useEffect(() => {
    if (!open) {
      setTemplateName(name);
    }
  }, [open]);

  const onUpdateName = () => {
    if (name !== templateName) {
      if (bundleId) {
        updateBundleTemplate(templateName);
      } else if (templateId) {
        updateTemplate(templateName);
      }
    } else {
      onClose();
    }
  };

  return (
    <Modal
      data-testid="input-select-modal"
      width={420}
      className={classNames(styles.inputSelectModal)}
      title={
        <div className={styles.formTitle}>
          <Icons
            variant={templateId ? 'singleForm' : 'formBundle'}
            className={styles.formHeaderIcon}
          />
          <Title level={4}>Edit Form Name</Title>
        </div>
      }
      okText={'Update'}
      onOk={onUpdateName}
      confirmLoading={loading}
      open={open}
      closable={false}
      onCancel={onClose}
      okButtonProps={{ disabled: !templateName || loading, loading }}
    >
      {fetching ? (
        <Spinner className={styles.spinner} loaderClassName={styles.loadingSpinner} />
      ) : (
        <div className={styles.inputBlock}>
          <div className={styles.label}>
            Name <RequiredAsterik />
          </div>
          <Input
            className={styles.input}
            inputHolderClassName={styles.inputHolder}
            variant={Input.LIGHT_ROUND}
            placeholder="Enter name"
            value={templateName}
            maxLength={255}
            onChange={(e) => {
              setTemplateName(e.target.value);
            }}
            name="TemplateName"
            id="TemplateName"
            testid="template_name"
          />
        </div>
      )}
    </Modal>
  );
};
