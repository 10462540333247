import React from 'react';
import ReactDOM from 'react-dom';
import toastr from 'toastr';
import styles from './styles.module.scss';

const PaywallToastSuccess = ({ closeToast }) => {
  return (
    <div className={styles.toastContainerSuccess}>
      <h3 className={styles.toastTitle}>Your plan is now active. 🚀</h3>
      <p className={styles.toastMessage}>
        Explore the app to enjoy all the features available to you
      </p>
      <button className={styles.toastButton} onClick={closeToast}>
        Explore Mosaik
      </button>
    </div>
  );
};

export const showPaywallToast = (success?: boolean) => {
  const type = success ? 'success' : 'fail';
  const id = `paywall-toast-${type}`;
  const existingToast = document.getElementById(id);
  if (existingToast) {
    return;
  }

  const toastRoot = document.createElement('div');
  toastRoot.id = id;
  document.body.appendChild(toastRoot);

  const closeToast = () => {
    ReactDOM.unmountComponentAtNode(toastRoot);
    document.body.removeChild(toastRoot);
  };

  ReactDOM.render(<PaywallToastSuccess closeToast={closeToast} />, toastRoot);
};
