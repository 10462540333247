import {
  Listings,
  UnderContract,
  Closed,
  Expired,
  Cancelled,
  LeadSources,
  ActiveClients,
  PortfolioClients,
  PortfolioValue,
  PortfolioVolume,
  GCI,
  Fees,
  Expenses,
  AgentSplit,
  TeamSplit,
  BrokerageSplit,
  AgentNetIncome,
  TeamNetIncome,
  BrokerageNetIncome,
} from './ContentTypes';

export const Content = ({ value }) => {
  const renderContent = () => {
    switch (value) {
      case 'listings':
        return <Listings />;
      case 'under contract':
        return <UnderContract />;
      case 'closed':
        return <Closed />;
      case 'expired':
        return <Expired />;
      case 'cancelled':
        return <Cancelled />;
      case 'lead sources':
        return <LeadSources />;
      case 'active clients':
        return <ActiveClients />;
      case 'portfolio clients':
        return <PortfolioClients />;
      case 'portfolio value':
        return <PortfolioValue />;
      case 'portfolio volume':
        return <PortfolioVolume />;
      case 'gci':
        return <GCI />;
      case 'fees':
        return <Fees />;
      case 'expenses':
        return <Expenses />;
      case 'agent split':
        return <AgentSplit />;
      case 'team split':
        return <TeamSplit />;
      case 'brokerage split':
        return <BrokerageSplit />;
      case 'agent net income':
        return <AgentNetIncome />;
      case 'team net income':
        return <TeamNetIncome />;
      case 'brokerage net income':
        return <BrokerageNetIncome />;
      default:
        return <></>;
    }
  };
  return renderContent();
};
