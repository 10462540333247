import { List, Typography } from 'antd';
import classNames from 'classnames';
import { Collapse, Panel } from 'components-antd';
import { CalendarRounded, GreenCheckMark, MilestoneRounded, Minus, Plus } from 'components/Icons';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { ITimelineCardProps, ITimelineItem, TimelineType } from './types';

const TimelineItem = ({ main, sub, type, top }: ITimelineItem) => (
  <List.Item className={styles.timelineItem}>
    {type === TimelineType.Tour && (
      <div className={styles.iconWrapper}>
        <CalendarRounded />
      </div>
    )}
    {type === TimelineType.Showing && (
      <div className={styles.iconWrapper}>
        <CalendarRounded color="#04A451" />
      </div>
    )}
    {type === TimelineType.Milestone && (
      <div className={styles.iconWrapper}>
        <MilestoneRounded />
      </div>
    )}

    <div className={styles.details}>
      {top && <Typography.Text className={styles.top}>{top}</Typography.Text>}
      <Typography.Text className={styles.main}>{main}</Typography.Text>
      <Typography.Text className={styles.sub}> {sub}</Typography.Text>
    </div>
  </List.Item>
);
const TimelineItemCard = ({ data }: { data: ITimelineItem[] }) => (
  <List
    className={styles.timelineList}
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item) => <TimelineItem {...item} />}
  />
);
const getCollapseIcon = (panelProps) => {
  const { isActive } = panelProps;
  return isActive ? (
    <Minus className={styles.btnWrapper} />
  ) : (
    <Plus className={styles.btnWrapper} />
  );
};

export const TimelineCard = (props: ITimelineCardProps) => {
  const { timelineData, title, viewAllRoute } = props;
  const history = useHistory();

  return (
    <div className={styles.timelineCard}>
      <div className={classNames(styles.headerWrapper)}>
        <div className={classNames(styles.titleWrapper)}>
          <div className={classNames(styles.title)}>{title}</div>
        </div>
        {viewAllRoute && (
          <div className={classNames(styles.link)}>
            <a
              onClick={() => {
                history.push(viewAllRoute);
              }}
            >
              View All
            </a>
          </div>
        )}
      </div>
      {/* collapse */}
      {timelineData?.length === 0 && (
        <div className={styles.emptySchedule}>
          <div className={styles.checkMark}>
            <GreenCheckMark width={20} height={20} />
          </div>
          <p>
            {'Nothing on the schedule'}
            <br />
            {'for this week (yet).'}
          </p>
        </div>
      )}
      <Collapse
        className={styles.collapse}
        ghost
        expandIcon={getCollapseIcon}
        expandIconPosition="end"
        defaultActiveKey={'timeline'}
        accordion
      >
        {timelineData.map((day, index) => (
          <>
            <Panel
              header={
                <span>
                  <Typography.Text className={styles.mainHeading}>{day.heading} </Typography.Text>
                  <Typography.Text className={styles.countHeading}>
                    ({day.data.length})
                  </Typography.Text>
                </span>
              }
              key={index}
            >
              <TimelineItemCard data={day.data} />
            </Panel>

            <div
              className={classNames(styles.divider, {
                [styles.lastDivider]: index === timelineData.length - 1,
              })}
            ></div>
          </>
        ))}
      </Collapse>
      {/* collapse */}
    </div>
  );
};

export default TimelineCard;
