import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import { Icons, ICON_VARIANT_TYPE } from 'pages/ShowingAppointment/Icons';
import Actions from './Actions';
import UploadDocuments from 'components/Transactions/UploadDocuments';

import styles from './styles.module.scss';

const Attachment = (props) => {
  const { file, className, truncateLength, isActions, deletable, taskId, onDelete, onSave } = props;
  const { Title, Meta, DateUploaded, documentName } = file || {};
  const { Filename } = Meta || {};

  const getDate = () => {
    const momentDate = moment(DateUploaded);
    const formattedDate = momentDate.format('MMM DD, YYYY');
    const formattedTime = momentDate.format('h:mm A');
    return `Uploaded on ${formattedDate} at ${formattedTime}`;
  };

  const getFilename = () => {
    const truncate = (name) => {
      const truncatedFileName = name.split('').slice(-truncateLength).join('');
      if (name?.length > truncateLength) {
        return `...${truncatedFileName}`;
      }
      return truncatedFileName;
    };

    if (truncateLength) {
      if (
        typeof Title === 'string' ||
        typeof Filename === 'string' ||
        typeof documentName === 'string'
      ) {
        return truncate(Title || documentName || Filename);
      }
      return '';
    }

    return Title || documentName || Filename;
  };

  return (
    <div testid="document_item" className={classNames(styles.attachment, className)}>
      <Icon className={styles.icon} variant={Icon.FILE} />
      <div className={styles.info}>
        <div testid="file_name" className={styles.filename}>
          {getFilename()}
        </div>

        <div testid="file_date" className={styles.date}>
          {file?.required && <div className={styles.requiredDoc}>(Required)</div>}
        </div>
      </div>
      {file.filename && isActions && (
        <Actions file={file} deletable={deletable} taskId={taskId} onDelete={onDelete} />
      )}
      {file.Title && (
        <UploadDocuments
          onSave={onSave}
          uploadButton={
            <div testid="upload_new" className={styles.fileNew}>
              <Icons
                className={classNames(styles.uploadFileIcon)}
                variant={ICON_VARIANT_TYPE.UPLOAD}
              />
              <div className={styles.uploadFileText}>Upload</div>
            </div>
          }
          fileCode={file.Code}
          withCategory={false}
          isShowingDocument
          fileNameRequired={true}
          fillDocumentName={true}
        />
      )}
    </div>
  );
};

Attachment.propTypes = {
  className: PropTypes.string,
  file: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ContentType: PropTypes.string,
    Url: PropTypes.string,
    UploadDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    Filename: PropTypes.string,
  }).isRequired,
  truncateLength: PropTypes.number,
  isActions: PropTypes.bool,
  deletable: PropTypes.bool,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDelete: PropTypes.func,
};

Attachment.defaultProps = {
  className: '',
  truncateLength: undefined,
  isActions: true,
  deletable: false,
  taskId: undefined,
  onDelete: () => {},
};

export default Attachment;
