import PropTypes from 'prop-types';

import { Modal } from 'components-antd';

import styles from './styles.module.scss';
import SandClock from 'components/Icons/SandClock';

export const ClarityPopup = (props) => {
  const { isOpen, onCloseModal } = props;

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onCloseModal}
      maskClosable={false}
      destroyOnClose
      className={styles.clarityPopup}
    >
      <div className={styles.clockIcon}>
        <SandClock></SandClock>
      </div>
      <div className={styles.dFlexAndCenter}>
        <p className={styles.popupContentText}>
          We don&apos;t have enough data about your business to do a drill-down just yet. As you
          keep using Mosaik, drill-down capabilities will become available to you.
        </p>
      </div>
      <div className={styles.dFlexAndCenter}>
        <button className={styles.popupButton} onClick={onCloseModal}>
          Got it
        </button>
      </div>
    </Modal>
  );
};

ClarityPopup.propTypes = {
  onCloseModal: PropTypes.func,
  isOpen: PropTypes.bool,
};

ClarityPopup.defaultProps = {
  onCloseModal: () => {},
  isOpen: false,
};
