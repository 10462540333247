import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  resetMessagesEffect,
  socketsGetMessagesByThreadIdEffect,
  sendNewMessageEffect,
  editMessageEffect,
} from 'store/effects/sockets/messages';
import { getQuotesDrawerParams } from 'store/selectors/drawers/quotes';
import { getCurrentMessagesInfoSelector } from 'store/selectors/sockets/messages';

import { SendMessageForm, Messages } from 'components';

import { sendThreadOpenedEffect, sendThreadsClosedEffect } from 'store/effects/sockets/threads';

import Header from './Header';

import styles from './styles.module.scss';
import { SOCKET_MESSAGE_TYPES } from 'settings/constants/sockets';

const QuotesDrawerOpenedThread = ({ closeThread }) => {
  const dispatch = useDispatch();
  const messages = useSelector(getCurrentMessagesInfoSelector);
  const { threadId } = useSelector(getQuotesDrawerParams);

  const onSendMessage = useCallback(
    (message, attachments, messageId) => {
      if (messageId) {
        return dispatch(
          editMessageEffect({
            MessageId: messageId,
            Text: message,
            Attachments: attachments,
            MessageType: SOCKET_MESSAGE_TYPES.QOUTE_MESSAGE,
          }),
        );
      }

      return dispatch(
        sendNewMessageEffect({
          ThreadId: threadId,
          Text: message,
          Attachments: attachments,
          MessageType: SOCKET_MESSAGE_TYPES.QOUTE_MESSAGE,
        }),
      );
    },
    [threadId, dispatch],
  );

  useEffect(() => {
    dispatch(socketsGetMessagesByThreadIdEffect({ Id: threadId }));
    return () => dispatch(resetMessagesEffect());
  }, [threadId, dispatch]);

  // When user is opened some thread - notify backend about this
  useEffect(() => {
    dispatch(sendThreadOpenedEffect({ threadId }));
    return () => dispatch(sendThreadsClosedEffect());
  }, [threadId, dispatch]);

  return (
    <div testid="chat_content" className={styles.wrapper}>
      <SendMessageForm
        id="messagesDrawer"
        className={styles.sendMessageForm}
        onSend={onSendMessage}
        subheader={<Header onBack={closeThread} />}
        content={<Messages threadId={threadId} list={messages} />}
      />
    </div>
  );
};

QuotesDrawerOpenedThread.propTypes = {
  closeThread: PropTypes.func.isRequired,
};

export default QuotesDrawerOpenedThread;
