import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { NoResults } from 'components';
import SectionNameItem from './SectionNameItem';
import ListItem from './ListItem';

const BuyersList = ({ groupedList, className }) => {
  const letters = useMemo(() => Object.keys(groupedList).sort(), [groupedList]);

  if (!Object.keys(groupedList || {}).length) {
    return <NoResults />;
  }

  return (
    <div testid="buyers_list" className={classNames(className)}>
      {letters.map((letter) => (
        <div key={letter}>
          <SectionNameItem letter={letter} />
          {groupedList[letter].map(({ Id, FirstName, LastName, AvatarUrl, TotalFavorites }) => (
            <ListItem
              key={Id}
              id={Id}
              firstName={FirstName}
              lastName={LastName}
              avatar={AvatarUrl}
              favorites={TotalFavorites}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

BuyersList.propTypes = {
  className: PropTypes.string,
  groupedList: PropTypes.shape({}).isRequired,
};

BuyersList.defaultProps = {
  className: '',
};

export default BuyersList;
