import { apiServer } from 'settings/web-services/api';

export function renameSavedSearch({ id, ...cfg }) {
  return apiServer.post(`/user/profile/saved-search/${id}/rename`, cfg);
}

export function deleteSavedSearch({ id }) {
  return apiServer.delete(`/user/profile/saved-search/${id}`);
}

export function activateSavedSearch({ id, ...cfg }) {
  return apiServer.post(`/user/profile/saved-search/${id}/activate`, cfg);
}

export function deactivateSavedSearch({ id, ...cfg }) {
  return apiServer.post(`/user/profile/saved-search/${id}/deactivate`, cfg);
}

export function getPreApprovalDocuments(cfg) {
  return apiServer.get(`/quoteRequest/pre-approval-documents`, { params: cfg });
}

export function addPreApprovalDocuments(cfg) {
  return apiServer.post(`/user/pre-approval/document-manual-entry`, cfg);
}
export function editPreApprovalDocuments(cfg) {
  return apiServer.put(`/user/pre-approval/document-manual-entry`, cfg);
}
export function getPreApprovalDocumentsWithManualEntries(cfg) {
  return apiServer.get(`/user/pre-approval/document-manual-entry`, { params: cfg });
}

export function deletePreApprovalManualEntry(cfg) {
  return apiServer.delete(`/user/pre-approval/document-manual-entry`, { params: cfg });
}

export function deletePreApprovalDocument(cfg) {
  return apiServer.delete(`/quoteRequest/pre-approval-documents`, { params: cfg });
}
