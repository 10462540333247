import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { prefsIds } from 'settings/constants/preferences';

import styles from './styles.module.scss';

const Label = ({
  id,
  label,
  outdoorSpaceValue,
  amenitiesValue,
  parkingSpacesValue,
  commutesValue,
  importantLocationsValue,
  className,
}) => {
  const getLabel = () => {
    switch (id) {
      case prefsIds.outdoorPrefs: {
        const count = outdoorSpaceValue.length;
        return count ? `${label} (${count})` : label;
      }
      case prefsIds.parking: {
        return parkingSpacesValue ? `${label} (${parkingSpacesValue})` : label;
      }
      case prefsIds.amenities: {
        const count = Object.keys(amenitiesValue || {}).length;
        return count ? `${label} (${count})` : label;
      }
      case prefsIds.commutes: {
        const count = commutesValue.length;
        return count ? `${label} (${count})` : label;
      }
      case prefsIds.proximityToImportantLocations: {
        const count = importantLocationsValue.length;
        return count ? `Proximity to Imp. Locations (${count})` : label;
      }
      default:
        return label;
    }
  };

  return <p className={classNames(styles.title, className)}>{getLabel()}</p>;
};

Label.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  amenitiesValue: PropTypes.shape({}).isRequired,
  parkingSpacesValue: PropTypes.number.isRequired,
  commutesValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  importantLocationsValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Label.defaultProps = {
  id: undefined,
  className: '',
};

export default Label;
