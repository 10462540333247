import styles from './styles.module.scss';

interface NoQuotesProps {
  content?: string;
}

export const NoQuotes = ({ content }: NoQuotesProps) => {
  return (
    <div className={styles.noQuotes}>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.6689 7V16.3333C32.6689 16.9522 32.9148 17.5457 33.3524 17.9832C33.7899 18.4208 34.3834 18.6667 35.0023 18.6667H44.3356"
          stroke="#E8E9EA"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.6689 49H16.3356C15.0979 49 13.911 48.5083 13.0358 47.6332C12.1606 46.758 11.6689 45.571 11.6689 44.3333V11.6667C11.6689 10.429 12.1606 9.242 13.0358 8.36683C13.911 7.49167 15.0979 7 16.3356 7H32.6689L44.3356 18.6667V44.3333C44.3356 45.571 43.8439 46.758 42.9688 47.6332C42.0936 48.5083 40.9066 49 39.6689 49Z"
          stroke="#E8E9EA"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 37C23 32.9996 33 33 35 37"
          stroke="#E8E9EA"
          strokeWidth="3.5"
          strokeLinecap="round"
        />
        <path d="M21 27H24" stroke="#E8E9EA" strokeWidth="3.5" strokeLinecap="round" />
        <path d="M32 27H35" stroke="#E8E9EA" strokeWidth="3.5" strokeLinecap="round" />
      </svg>
      <span className={styles.noQuotesText}>{content ? content : 'You have no quotes.'}</span>
    </div>
  );
};
