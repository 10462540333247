import { getAllTransactions } from 'api/transactions';
import {
  requestGetAllTransactionsAction,
  setTransactionsArchiveAction,
  setTransactionsSearchAction,
  setTeamTransactionsAction,
  setTransactionsFilterAction,
  setProjectsFilterAction,
  setTransactionFiltersAppliedAction,
  requestGetAllProjectsAction,
  transactionsResetAction,
} from 'store/actions/transactions/all';
import Api from 'store/effects/core/api';

export const requestGetTransactionsEffect = (cfg = {}, options, cb) => {
  const requestParams = {
    action: requestGetAllTransactionsAction,
    method: getAllTransactions,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetProjectsEffect = (cfg = {}, options, cb) => {
  const requestParams = {
    action: requestGetAllProjectsAction,
    method: getAllTransactions,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }
  return sendRequest(cfg, options, cb);
};

export const setTransactionSearchEffect = (cfg) => (dispatch) => {
  dispatch(setTransactionsSearchAction(cfg));
};

export const searchTransactionsEffect =
  ({ search }) =>
  (dispatch) => {
    dispatch(setTransactionSearchEffect(search));
  };

export const resetTransactionsEffect = () => (dispatch) => {
  dispatch(transactionsResetAction());
};

export const resetTransactionsSearchEffect = () => (dispatch) => {
  dispatch(searchTransactionsEffect({ search: '' }));
};

export const setTransactionsIsArchiveEffect = (cfg) => (dispatch) => {
  dispatch(setTransactionsArchiveAction(cfg));
};

export const setTransactionsFilterEffect = (cfg) => (dispatch) => {
  dispatch(setTransactionsFilterAction(cfg));
};

export const setProjectsFilterEffect = (cfg) => (dispatch) => {
  dispatch(setProjectsFilterAction(cfg));
};

export const setTeamTransactionsEffect = (cfg) => (dispatch) => {
  dispatch(setTeamTransactionsAction(cfg));
};

export const setTransactionFiltersAppliedEffect = (cfg) => (dispatch) => {
  dispatch(setTransactionFiltersAppliedAction(cfg));
};
