export const MAP_VIEWS = {
  SPLIT: 'Split',
  LIST: 'List',
  MAP: 'Map',
};

export const MOBILE_MAP_VIEWS = {
  LIST: 'List',
  MAP: 'Map',
};
