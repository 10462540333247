import { PDF_FIELD_TYPE, SMART_FORM_QUESTION_TYPE } from 'app-constants';
import { PDFIcon } from 'pages/FormBuilder/Icons/PDFIcon';
import QuestionIcon from 'pages/FormBuilder/Icons/QuestionIcon';

import styles from './styles.module.scss';

const QuestionList = (type) => (
  <span className={styles.questionItem}>
    <QuestionIcon className={styles.questionItemIcon} variant={type} /> {type}
  </span>
);

export const SmartQuestionList = [
  {
    value: PDF_FIELD_TYPE.PDFTextField,
    label: (
      <span className={styles.questionItem}>
        <PDFIcon className={styles.pdfIcon} variant={PDF_FIELD_TYPE.PDFTextField} />
        Text Field
      </span>
    ),
    children: [
      {
        value: SMART_FORM_QUESTION_TYPE.Currency,
        label: <span>{QuestionList(SMART_FORM_QUESTION_TYPE.Currency)}</span>,
      },
      {
        value: SMART_FORM_QUESTION_TYPE.Date,
        label: <span>{QuestionList(SMART_FORM_QUESTION_TYPE.Date)}</span>,
      },
      {
        value: SMART_FORM_QUESTION_TYPE.Email,
        label: <span>{QuestionList(SMART_FORM_QUESTION_TYPE.Email)}</span>,
      },
      {
        value: SMART_FORM_QUESTION_TYPE.FreeText,
        label: <span>{QuestionList(SMART_FORM_QUESTION_TYPE.FreeText)}</span>,
      },
      {
        value: SMART_FORM_QUESTION_TYPE.Number,
        label: <span>{QuestionList(SMART_FORM_QUESTION_TYPE.Number)}</span>,
      },
      {
        value: SMART_FORM_QUESTION_TYPE.PhoneNumber,
        label: <span>{QuestionList(SMART_FORM_QUESTION_TYPE.PhoneNumber)}</span>,
      },
    ],
  },
  {
    value: PDF_FIELD_TYPE.PDFCheckBox,
    label: (
      <span className={styles.questionItem}>
        <PDFIcon className={styles.pdfIcon} variant={PDF_FIELD_TYPE.PDFCheckBox} />
        Checkbox Field
      </span>
    ),
    children: [
      {
        value: SMART_FORM_QUESTION_TYPE.ListBox,
        label: <span>{QuestionList(SMART_FORM_QUESTION_TYPE.ListBox)}</span>,
      },
    ],
  },
  {
    value: PDF_FIELD_TYPE.PDFRadioGroup,
    label: (
      <span className={styles.questionItem}>
        <PDFIcon className={styles.pdfIcon} variant={PDF_FIELD_TYPE.PDFRadioGroup} />
        Radio Group Field
      </span>
    ),
    children: [
      {
        value: SMART_FORM_QUESTION_TYPE.ListBox,
        label: <span>{QuestionList(SMART_FORM_QUESTION_TYPE.ListBox)}</span>,
      },
    ],
  },
  {
    value: PDF_FIELD_TYPE.PDFSignature,
    label: (
      <span className={styles.questionItem}>
        <PDFIcon className={styles.pdfIcon} variant={PDF_FIELD_TYPE.PDFSignature} />
        Signature Field
      </span>
    ),
    children: [
      {
        value: SMART_FORM_QUESTION_TYPE.Signature,
        label: <span>{QuestionList(SMART_FORM_QUESTION_TYPE.Signature)}</span>,
      },
    ],
  },
];
