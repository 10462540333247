import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getUserId } from 'store/selectors/user';
import { getLocaleDate, getFormattedTime } from 'helpers';

import styles from './styles.module.scss';

export const PerformedBy = ({ data }) => {
  const userId = useSelector(getUserId);
  const status = data?.AppointmentStatus;
  const performedBy = data?.PerformedBy;

  return (
    <div className={styles.performedBy}>
      <span>
        {status} by{' '}
        {performedBy?.UserId === userId
          ? 'You'
          : performedBy?.FirstName
          ? `${performedBy.FirstName} ${performedBy.LastName || ''}`
          : performedBy?.Email}
        {data?.Reason && <p>{data?.Reason}</p>}
        <p>
          {moment(getLocaleDate(data?.UpdatedDate)).format('MMMM Do, YYYY')} at{' '}
          {getFormattedTime(getLocaleDate(data?.UpdatedDate), true)}
        </p>
      </span>
    </div>
  );
};

PerformedBy.propTypes = {
  data: PropTypes.object,
};

PerformedBy.defaultProps = {
  data: {},
};
