import { PDFDocument } from 'pdf-lib';
import React, { useEffect, useState } from 'react';
import { useFieldsHighlighter } from './useFieldsHighlighter';

interface PdfPreviewProps {
  pdfDoc: PDFDocument | null;
  fieldToHighlight: string | null;
  forceRenderTrigger: {};
}

export const PdfPreview: React.FC<PdfPreviewProps> = (props) => {
  const [previewPdfUrl, setPreviewPdfUrl] = useState<string>('');

  const {
    canvas: highlightFieldsCanvas,
    highlightField,
    clearFieldHighlight,
  } = useFieldsHighlighter(props.pdfDoc);

  useEffect(() => {
    if (!props.pdfDoc) return;

    if (props.fieldToHighlight) {
      const pdfForm = props.pdfDoc.getForm();
      clearFieldHighlight();
      highlightField(pdfForm.getField(props.fieldToHighlight));
    } else {
      clearFieldHighlight();
    }
  }, [props.pdfDoc, props.fieldToHighlight]);

  useEffect(() => {
    const runEffect = async (pdfDoc: PDFDocument) => {
      const pdfBytes = await pdfDoc.save();
      const bytes = new Uint8Array(pdfBytes);
      const blob = new Blob([bytes], { type: 'application/pdf' });
      const docUrl = URL.createObjectURL(blob);
      setPreviewPdfUrl(docUrl);
    };

    if (props.pdfDoc) {
      void runEffect(props.pdfDoc);
    }
  }, [props.pdfDoc, props.forceRenderTrigger]);

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      {highlightFieldsCanvas}
      <iframe
        width="100%"
        height="100%"
        style={{
          borderWidth: 0,
        }}
        src={`${previewPdfUrl}#toolbar=0&statusbar=0&navpanes=0&scrollbar=0`}
      ></iframe>
    </div>
  );
};
