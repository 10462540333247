import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useWizardRole } from 'hooks';

import { PREF_TYPE, prefsIds } from 'settings/constants/preferences';
import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';

import { PRE_APPROVAL, preApprovalRoute } from 'settings/constants/preApproval';

import { getSignUpRoleSelector } from 'store/selectors/loginGroup';

import { Stages } from 'components';
import { stagesMap, additionalQuestions, stageDocument, stageApproved } from './options';
import { Header } from 'pages/OnBoarding/components/Header';

import styles from './styles.module.scss';

const OnBoardingWizard = () => {
  useWizardRole();
  const { pathname } = useLocation();
  const signUpRole = useSelector(getSignUpRoleSelector);

  const [stagesValue, setStagesValue] = useState([]);

  useEffect(() => {
    const path = pathname.split('/');
    setStagesValue(
      path[path.length - 1] === preApprovalRoute
        ? stagesMap[PRE_APPROVAL]
        : stagesMap[signUpRole] || [],
    );
  }, [signUpRole, pathname]);

  const findIndex = (stageId) => stagesValue.findIndex(({ id }) => id === stageId);

  const isUserWasPreApproved = (isAnswer) => {
    if (isAnswer) {
      const isDocumentStage = findIndex('stageDocument') !== -1;
      const approvedStageIndex = findIndex('stageApproved');

      if (approvedStageIndex !== -1) {
        stagesValue.splice(approvedStageIndex, 1);
      }

      if (!isDocumentStage) {
        stagesValue.splice(stagesValue.length - 1, 0, ...stageDocument);
      }
    } else {
      // Pre-approval flow to be revamped
      // const isApprovedStage = findIndex('stageApproved') !== -1;
      // const preApprovedIndex = findIndex('stagePreApproved');
      const documentStageIndex = findIndex('stageDocument');

      if (documentStageIndex !== -1) {
        stagesValue.splice(documentStageIndex, 1);
      }

      // if (!isApprovedStage) {
      //   stagesValue.splice(preApprovedIndex + 1, 0, ...stageApproved);
      // }
    }
  };

  return (
    <>
      <Header />
      <Stages
        classNameWrapper={styles.stages}
        stages={stagesValue}
        additionalStages={additionalQuestions}
        isUserWasPreApproved={isUserWasPreApproved}
      />
    </>
  );
};

// TODO: use constants form the file
OnBoardingWizard.propNames = {
  homePrefers: PREF_TYPE.homePrefs,
  amenitiesPrefs: PREF_TYPE.amenitiesPrefs,
  parking: prefsIds.parking,
  numOfParkingSpaces: 'NumOfParkingSpaces',
  neighborhoodPrefs: PREF_TYPE.neighborhoodPrefs,
  schoolPrefs: 'Schools',
  commutePrefs: PREF_TYPE.commutePrefs,
  importantLocationsPrefs: prefsIds.proximityToImportantLocations,
  stories: 'Stories',
  layoutPrefs: prefsIds.layoutPrefs,
  interiorStylesPrefs: prefsIds.style,
  kitchenFeaturesPrefs: prefsIds.kitchenFeatures,
  flooringPrefs: prefsIds.flooring,
  mustHave: PREF_TYPE.amenitiesPrefs,
  isPreApproved: 'IsPreApproved',
  isWantToBeApproved: 'IsWantToBeApproved',
  document: 'document',
  outdoorSpacePrefs: PREF_TYPE.outdoorPrefs,
  viewPref: prefsIds.view,
  newConstructionPref: prefsIds.newConstruction,
  commutesPref: prefsIds.commutes,
  moveInReadyPref: prefsIds.moveInReady,
  openFloorPlanPref: prefsIds.openFloorPlan,
  laundryRoomPref: prefsIds.laundryRoom,
  homeOfficePref: prefsIds.homeOffice,
  basementRoomPref: prefsIds.basementRoom,

  approveIsFirstTimeBuyer: 'Are you a first-time home buyer?',
  approveBuyingProcess: 'Where are you in the home-buying process?',
  approveHomeType: 'What are you looking for in a home?',
  approveWhat: 'This home will be my',
  approveMilitary: 'Are you or your spouse active military or veteran?',
  approveEmployed: 'What is your employment status?',
  approveTaxes: 'What is your annual income before taxes?',
  approvePriceRange: 'What is your price range?',
  approveDownPayment: 'How much do you have for a down payment?',
  approveEstimateCredit: 'What is your estimated credit score?',
  approveWasSale: 'Have you gone through bankruptcy, foreclosure, or short sale in past 7 years?',
  approveBorrower: 'Do you have a co-borrower?',
  approveTrustedLenders: 'TrustedLenders',
};

// TODO: use constants form the file
OnBoardingWizard.importances = {
  not: SEARCH_CRITERIA_IMPORTANCE.NOT,
  someWhat: SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
  very: SEARCH_CRITERIA_IMPORTANCE.VERY,
  must: SEARCH_CRITERIA_IMPORTANCE.MUST,
};

export default OnBoardingWizard;
