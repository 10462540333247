/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import { ItemsWrapper, ItemWrapper, Placeholder, Value } from '../components';

import styles from './styles.module.scss';

const Prices = (props) => {
  const { className, prices } = props;

  if (!prices) return null;

  return (
    <ItemsWrapper className={classNames(styles.prices, className)}>
      {prices.map((price, index) => (
        <ItemWrapper testid="price" key={index}>
          {price ? (
            <NumberFormat
              displayType="text"
              thousandSeparator
              value={price}
              prefix="$"
              renderText={(val) => <Value className={styles.price} value={val} />}
            />
          ) : (
            <Placeholder />
          )}
        </ItemWrapper>
      ))}
    </ItemsWrapper>
  );
};

Prices.propTypes = {
  className: PropTypes.string,
  prices: PropTypes.arrayOf(PropTypes.number).isRequired,
};

Prices.defaultProps = {
  className: '',
};

export default Prices;
