import { lazy, Suspense, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'components-antd';
import styles from './styles.module.scss';
import { ModalTable } from 'pages/Main/ClientDashboard/components/ModalTable';
import { tableColumns } from './tableColumns';
import { getUserId } from 'store/selectors/user';
import { dispatch } from 'store';
import {
  getTransactionEffect,
  setTransactionTaskParamsEffect,
  setTransactionTasksLastStateEffect,
} from 'store/effects';

const Task = lazy(() => import('pages/Workshop/Transactions/TransactionTasks/components/Task'));

const TasksModal = (props) => {
  const { className, isOpen, onCloseModal, tasksDetails, title = 'Tasks to Complete' } = props;
  const userId = useSelector(getUserId);

  const onClickTask = useCallback(
    (record) => {
      if (record) {
        const { TransactionId: transactionId, Id: taskId } = record;
        dispatch(getTransactionEffect({ id: transactionId }));
        dispatch(setTransactionTaskParamsEffect({ taskId, transactionId, isTaskDetail: true }));
        dispatch(setTransactionTasksLastStateEffect({ taskId }));
      }
    },
    [dispatch],
  );

  const tableProps = {
    columns: tableColumns(userId, (record) => onClickTask(record)),
    onRow: (record) => {
      return {
        onClick: () => {},
      };
    },
    tableClassName: styles.taskTable,
  };
  return (
    <>
      <Modal
        open={isOpen}
        width={1200}
        footer={null}
        onCancel={() => {
          onCloseModal();
        }}
        maskClosable={false}
        destroyOnClose
        className={styles.taskViewModal}
      >
        <div testid="forms_modal" className={styles.formModal}>
          <p className={styles.title}>{title}</p>
          <ModalTable dataSource={tasksDetails} {...tableProps} />
        </div>
      </Modal>
      <Suspense fallback={<div>Loading...</div>}>
        <Task />
      </Suspense>
    </>
  );
};

TasksModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  tasksDetails: PropTypes.any,
};

TasksModal.defaultProps = {
  className: '',
  isOpen: false,
  onCloseModal: () => {},
};

export default TasksModal;
