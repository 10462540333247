import { HTMLAttributes } from 'react';

const Next = ({ ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 5H13M13 5L9 1M13 5L9 9"
        stroke="#303030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default Next;
