import React, { useCallback, useState, useEffect } from 'react';
import { chunk } from 'lodash-es';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { appOpenModalEffect } from 'store/effects/app';

import { useScreen } from 'hooks';
import { pushOrRemoveStageOptionArr } from 'pages/OnBoardingWizard/helpers';
import { HOME_PREFS_OPTIONS, prefsIds } from 'settings/constants/preferences';
import CommutesModal from 'pages/OnBoardingWizard/components/StageParts/StagePrefs/CommutesModal';

import Option from './Option';
import OutdoorSpaceModal from '../OutdoorSpaceModal';
import ParkingModal from '../ParkingModal';
import AmenitiesModal from '../AmenitiesModal';
import { Row, Col } from 'antd';
import styles from './styles.module.scss';

const Options = ({
  className,
  onChange,
  value,
  outdoorSpaceValue,
  amenitiesValue,
  parkingSpacesValue,
  commutesValue,
  importantLocationsValue,
  setEditImportantLocations,
  disabled,
  isHomeTypeLand,
  testid,
}) => {
  const [actives, setActives] = useState(value);
  const { screen } = useScreen();
  const dispatch = useDispatch();

  useEffect(() => setActives(value), [value]);

  const openModal = useCallback(
    (id) => {
      dispatch(appOpenModalEffect({ id, open: true }));
    },
    [dispatch],
  );

  const onClick = useCallback(
    (id) => {
      const newActives = pushOrRemoveStageOptionArr({ arr: actives, value: id, multiple: true });

      setActives(newActives);
      onChange(newActives);

      if (newActives.includes(prefsIds.outdoorPrefs) && id === prefsIds.outdoorPrefs) {
        return openModal(OutdoorSpaceModal.id);
      } else if (newActives.includes(prefsIds.parking) && id === prefsIds.parking) {
        openModal(ParkingModal.id);
      } else if (newActives.includes(prefsIds.amenities) && id === prefsIds.amenities) {
        openModal(AmenitiesModal.id);
      } else if (newActives.includes(prefsIds.commutes) && id === prefsIds.commutes) {
        openModal(CommutesModal.id);
      }
    },
    [onChange, actives, openModal],
  );

  const onEdit = (e, optionId) => {
    e.stopPropagation();
    switch (optionId) {
      case prefsIds.outdoorPrefs:
        return openModal(OutdoorSpaceModal.id);
      case prefsIds.amenities:
        return openModal(AmenitiesModal.id);
      case prefsIds.parking:
        return openModal(ParkingModal.id);
      case prefsIds.commutes:
        setEditImportantLocations(false);
        return openModal(CommutesModal.id);
      case prefsIds.proximityToImportantLocations:
        setEditImportantLocations(true);
        return openModal(CommutesModal.id);
      default:
        return null;
    }
  };

  const getOptions = () => {
    const homeOptions = isHomeTypeLand
      ? HOME_PREFS_OPTIONS.filter(
          (opt) => ![prefsIds.outdoorPrefs, prefsIds.parking].includes(opt.id),
        )
      : HOME_PREFS_OPTIONS.filter((option) => option.displayInWizard === true);

    if (screen.mobileSmallWidth) {
      return chunk(homeOptions, 1);
    }
    if (screen.mobileWidth) {
      return chunk(homeOptions, 2);
    }
    return chunk(homeOptions, 3);
  };

  return (
    <div className={classNames(styles.wrapper, { [styles.disabled]: disabled }, className)}>
      <Row gutter={[16, 16]}>
        {getOptions().map((option, index) => (
          <Col xs={24} md={24} key={index}>
            <Option
              key={index} // eslint-disable-line
              option={option}
              actives={actives}
              outdoorSpaceValue={outdoorSpaceValue}
              amenitiesValue={amenitiesValue}
              parkingSpacesValue={parkingSpacesValue}
              commutesValue={commutesValue}
              importantLocationsValue={importantLocationsValue}
              onClick={onClick}
              onEdit={onEdit}
              testid={testid}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

Options.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  outdoorSpaceValue: PropTypes.arrayOf(PropTypes.string),
  amenitiesValue: PropTypes.shape({
    Amenities: PropTypes.shape({}),
  }),
  parkingSpacesValue: PropTypes.number.isRequired,
  commutesValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  importantLocationsValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setEditImportantLocations: PropTypes.func,
  isHomeTypeLand: PropTypes.bool,
  testid: PropTypes.string,
};

Options.defaultProps = {
  className: '',
  onChange: () => {},
  value: [],
  disabled: false,
  outdoorSpaceValue: [],
  amenitiesValue: {},
  setEditImportantLocations: () => {},
  isHomeTypeLand: false,
  testid: undefined,
};

export default Options;
