import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Checkbox, Avatar } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import styles from './styles.module.scss';

const Reviewer = (props) => {
  const { className, value, reviewers, onChange, avatarPlaceholder, label } = props;
  const { isRole, isChecked } = props;

  const name = `${value?.FirstName} ${value?.LastName}`;

  return (
    <div testid="reviewer_item" className={classNames(styles.reviewer, className)}>
      <div className={styles.name}>
        <Avatar
          src={value?.AvatarUrl}
          placeholder={
            avatarPlaceholder || (
              <div className={styles.avatarPlaceholder}>{convertNameToAvatarPlaceholder(name)}</div>
            )
          }
          avatarClassName={styles.avatar}
        />
        <div testid="name" className={styles.name}>
          {label || name}
        </div>
        {isRole && (
          <div testid="role" className={styles.role}>{`(${
            value?.Role?.DisplayTitle || value?.Role?.Title
          })`}</div>
        )}
      </div>
      <Checkbox
        onChange={onChange}
        checked={isChecked || !reviewers || reviewers.includes(value?.Id)}
        testid="reviewer_checkbox"
      />
    </div>
  );
};

Reviewer.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    AvatarUrl: PropTypes.string,
    Role: PropTypes.shape({
      Title: PropTypes.string,
    }),
  }),
  reviewers: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  avatarPlaceholder: PropTypes.node,
  label: PropTypes.string,
  isRole: PropTypes.bool,
  isChecked: PropTypes.bool,
};

Reviewer.defaultProps = {
  className: '',
  value: {},
  reviewers: undefined,
  onChange: () => {},
  avatarPlaceholder: undefined,
  label: undefined,
  isRole: true,
  isChecked: false,
};

export default Reviewer;
