import { useEffect } from 'react';

import { getExpandedUrl } from 'api/url';
import { AxiosError } from 'axios';
import { Spinner } from 'components';
import { useHistory } from 'react-router-dom';

const ShortUrl = () => {
  const history = useHistory();

  const loadExpandedUrl = async () => {
    const { pathname } = window.location;
    const shortCode = pathname.split('/').pop();

    try {
      const resp = await getExpandedUrl({ shortCode });
      if (resp.status === 200) {
        history.push(resp.data.redirect);
      }
    } catch (err) {
      if ((err as AxiosError).response!.status) {
        history.replace('/404');
      }
      // TODO: May be we can take more targetted action here
      history.replace('/404');
    }
  };

  useEffect(() => {
    loadExpandedUrl();
  }, []);

  return <Spinner />;
};

export default ShortUrl;
