import {
  openCommentsDrawerAction,
  setCurrentCommentsThreadIdAction,
  setCommentsDrawerParamsAction,
} from 'store/actions/drawers/comments';

export const openCommentsDrawerEffect = (cfg) => (dispatch) =>
  dispatch(openCommentsDrawerAction(cfg));

export const setCurrentCommentsThreadIdEffect = (cfg) => (dispatch) =>
  dispatch(setCurrentCommentsThreadIdAction(cfg));

export const setCommentsDrawerParamsEffect = (cfg) => (dispatch) =>
  dispatch(setCommentsDrawerParamsAction(cfg));
