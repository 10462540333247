import { handleActions } from 'redux-actions';
import { CREATE } from 'settings/constants/mode';

import {
  openSearchResultsFilterDrawerAction,
  setSearchDrawerCommuteIdAction,
} from 'store/actions/searchResults';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';

const initialData = {
  open: false,
  mode: CREATE,
  commuteId: null,
  isClientSearch: false,
};

export default handleActions(
  {
    [openSearchResultsFilterDrawerAction]: (state, { payload }) => ({
      ...state,
      ...('open' in payload ? { open: payload.open } : {}),
      ...('mode' in payload ? { mode: payload.mode } : {}),
      isClientSearch: payload.isClientSearch || initialData.isClientSearch,
    }),
    [setSearchDrawerCommuteIdAction]: (state, { payload }) => ({
      ...state,
      commuteId: payload,
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
