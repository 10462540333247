import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { DeclineFormReason } from './DeclineFormReason';
import { Modal } from 'components-antd';
import { Icons } from 'pages/Workshop/Forms/Icons';
import { DeclineConfirmation } from './DeclineConfirmation';

export const DeclineFormModal = (props) => {
  const { open, onClose } = props;
  const [modalType, setModalType] = useState('confirmation');

  useEffect(() => {
    if (!open && modalType !== 'confirmation') {
      setModalType('confirmation');
    }
  }, [open]);

  return (
    <Modal
      open={open}
      width={675}
      footer={null}
      onCancel={() => {
        onClose();
      }}
      maskClosable={false}
      destroyOnClose
      closeIcon={<Icons variant={'modal-close'} stroke="#262626" />}
      title={'Decline to Sign'}
      className={styles.declineFormModal}
    >
      {modalType === 'confirmation' && (
        <DeclineConfirmation onContinue={() => setModalType('reason')} onClose={onClose} />
      )}

      {modalType === 'reason' && <DeclineFormReason onClose={onClose} />}
    </Modal>
  );
};
