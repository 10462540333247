import React from 'react';
import { Title, Modal, ModalProps, Input, Button } from 'components-antd';
import { useHistory } from 'react-router-dom';
import { Icons } from 'pages/Workshop/Offers/icons';
import classnames from 'classnames';

import styles from './styles.module.scss';

export const InfoModal = ({ uuid, isOpen }) => {
  const history = useHistory();

  return (
    <Modal
      open={isOpen}
      footer={null}
      okText={'Done'}
      width={650}
      data-testid="confirmation-modal"
      className={classnames(styles.confirmationWithReasonModalForAccept)}
    >
      <Title level={4}>
        <p>
          <Icons variant="checkoutlinedgreen" />
        </p>
        <span className={styles.titleText}>Offer Submitted</span>
      </Title>
      <h4 className={styles.subTitle}>
        Your offer has been sent to the listing agent. We&apos;ll notify you when there is a
        response.
      </h4>
      <button
        className={styles.btn}
        onClick={() => history.push(`/offer/details/${uuid}`)}
        title="Done"
        testid="offer-submitted"
      >
        <span className={styles.btnTxt}>Done</span>
      </button>
    </Modal>
  );
};
