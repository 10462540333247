import { isEqual } from 'lodash-es';

export const getIfExistThread = (threads, participants, additionalCriteria = (arg) => true) =>
  (threads || []).find((thread) => {
    const threadParticipantsIds = (thread?.Participants || []).map((partic) => partic?.Id).sort();
    const participantsIds = (participants || [])
      .map((partic) => partic?.Id || partic?.id || partic)
      .sort();

    return isEqual(threadParticipantsIds, participantsIds) && additionalCriteria(thread);
  });
