import PropTypes from 'prop-types';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import { Avatar } from 'components';
import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const ListItem = ({ id, firstName, lastName, avatar, favorites, onClick }) => (
  <div
    testid="list_item"
    onClick={() => onClick(id, firstName, lastName)}
    className={styles.itemContainer}
  >
    <div className={styles.inner}>
      <Avatar
        src={avatar}
        className={styles.avatar}
        avatarClassName={styles.avatarInner}
        placeholder={convertNameToAvatarPlaceholder(`${firstName} ${lastName}`)}
      />
      <div className={styles.nameHolder}>
        <p testid="buyer_name" className={styles.name}>
          {`${firstName} ${lastName}`}
        </p>
        <p testid="buyer_favorites" className={styles.favorites}>{`${favorites} favorites`}</p>
      </div>
    </div>
    <Icon variant={Icon.ARROW_RIGHT} className={styles.iconArrow} />
  </div>
);

ListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatar: PropTypes.string,
  favorites: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
};

ListItem.defaultProps = {
  id: null,
  firstName: '',
  lastName: '',
  favorites: 0,
  avatar: undefined,
  onClick: () => {},
};

export default ListItem;
