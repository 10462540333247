import { createSelector } from 'reselect';

import {
  PublishedFormsDataType,
  DraftFormsType,
  FormDetailsType,
  FormDocumentType,
  MetaType,
  PreFormType,
  RequestFormProcessType,
  AllFormProcessesType,
  AllArchiveFormsType,
  DeferredQuestionsType,
  PostFormType,
  FormTemplatesDataType,
  DynamicFormType,
  PDFEditorConfig,
  DynamicFormEditorQuestion,
  DynamicSignatureConfig,
  DynamicPostForm,
  RequiredQuestion,
  FormRecentUploadsDataType,
  FormRoleType,
} from 'types';
import { ERROR, PENDING } from 'settings/constants/apiState';
import { DEFAULT_PDF_SCALE } from 'components/PrintPDF';

const localState = ({ requestFormProcess }) => requestFormProcess;

export const getRequestFormProcessSelector = createSelector(
  localState,
  (requestFormProcess: RequestFormProcessType): RequestFormProcessType => ({
    isPending: requestFormProcess.state === PENDING,
    state: requestFormProcess.state,
    publishedForms: requestFormProcess.publishedForms,
    formTemplates: requestFormProcess.formTemplates,
    formRecentUploads: requestFormProcess.formRecentUploads,
    preForm: requestFormProcess.preForm,
    meta: requestFormProcess.meta,
    formRoles: requestFormProcess.formRoles,
    formName: requestFormProcess.formName,
    formDocument: requestFormProcess.formDocument,
    isFormLibraryModalVisible: requestFormProcess.isFormLibraryModalVisible,
    allFormProcesses: requestFormProcess.allFormProcesses,
    allUnlinkFormProcesses: requestFormProcess.allUnlinkFormProcesses,
    allArchiveForms: requestFormProcess.allArchiveForms,
    formDetails: requestFormProcess.formDetails,
    draftForms: requestFormProcess.draftForms,
    deferredQuestions: requestFormProcess.deferredQuestions,
    currentUserSignature: requestFormProcess.currentUserSignature,
    filters: requestFormProcess.filters,
    agentFormRoles: requestFormProcess.agentFormRoles,
  }),
);

export const getPreFormSelector = createSelector(
  localState,
  ({ preForm, agentFormRoles }: { preForm: PreFormType; agentFormRoles: FormRoleType[] }) => ({
    ...preForm,
    agentFormRoles,
  }),
);

export const getPostFormSelector = createSelector(
  localState,
  ({ postForm }: { postForm: PostFormType }) => postForm,
);

export const getPublishedFormsSelector = createSelector(
  localState,
  ({ publishedForms }: { publishedForms: PublishedFormsDataType }) => ({
    isPending: publishedForms.state === PENDING,
    ...publishedForms,
  }),
);

export const getFormTemplatesSelector = createSelector(
  localState,
  ({ formTemplates }: { formTemplates: FormTemplatesDataType }) => ({
    isPending: formTemplates.state === PENDING,
    ...formTemplates,
  }),
);

export const getFormRecentUploadsSelector = createSelector(
  localState,
  ({ formRecentUploads }: { formRecentUploads: FormRecentUploadsDataType }) => ({
    isPending: formRecentUploads.state === PENDING,
    ...formRecentUploads,
  }),
);

export const getFormMetaSelector = createSelector(
  localState,
  ({ meta }: { meta: MetaType }) => meta,
);

export const getSmartFormDataSelector = createSelector(
  localState,
  ({
    state,
    formRoles,
    editorsPresent,
    signatoriesPresent,
    defaultEditors,
    clientFieldsPresent,
    showClientEditorScreen,
    editorsRoles,
  }: RequestFormProcessType) => ({
    isError: state === ERROR,
    isPending: state === PENDING,
    formRoles,
    editorsPresent,
    signatoriesPresent,
    defaultEditors,
    clientFieldsPresent,
    showClientEditorScreen,
    editorsRoles,
  }),
);

export const getFormDocumentSelector = createSelector(
  localState,
  ({ formDocument }: { formDocument: FormDocumentType }) => formDocument || {},
);

export const isFormLibraryModalVisibleSelector = createSelector(
  localState,
  ({ isFormLibraryModalVisible }) => isFormLibraryModalVisible as boolean,
);

export const getDraftFormsSelector = createSelector(
  localState,
  ({ draftForms }: { draftForms: DraftFormsType }) => ({
    isPending: draftForms.state === PENDING,
    ...draftForms,
  }),
);

export const getFormDetailsSelector = createSelector(
  localState,
  ({ formDetails }: { formDetails: FormDetailsType }) => ({
    isPending: formDetails.state === PENDING,
    ...formDetails,
  }),
);

export const getAllFormProcessesSelector = createSelector(
  localState,
  ({ allFormProcesses }: { allFormProcesses: AllFormProcessesType }) => allFormProcesses,
);

export const getAllUnlinkFormProcessesSelector = createSelector(
  localState,
  ({ allUnlinkFormProcesses }: { allUnlinkFormProcesses }) => allUnlinkFormProcesses,
);

export const getArchiveFormsSelector = createSelector(
  localState,
  ({ allArchiveForms }: { allArchiveForms: AllArchiveFormsType }) => allArchiveForms,
);

export const getDeferredQuestionsSelector = createSelector(
  localState,
  ({ deferredQuestions }: { deferredQuestions: DeferredQuestionsType }) => ({
    isPending: deferredQuestions.state === PENDING,
    ...deferredQuestions,
  }),
);

export const getFormNamesSelector = createSelector(localState, (currentState) => {
  const allFormProcesses = currentState?.allFormProcesses;
  let formNames = allFormProcesses
    ? Object.keys(allFormProcesses).map((formProcessKey) =>
        allFormProcesses[formProcessKey].map((formObj) => formObj.formName),
      )
    : [];
  return formNames.flat().filter((formName, position, self) => position === self.indexOf(formName));
});

export const getDynamicFormSelector = createSelector(
  localState,
  ({ dynamicForm }: { dynamicForm: DynamicFormType }) => dynamicForm || ({} as DynamicFormType),
);

interface PDFEditorConfigSelectType extends PDFEditorConfig {
  selectedFieldIndex: number;
  selectedField?: DynamicFormEditorQuestion;
}

export const getDynamicFormEditorConfigSelector = createSelector(
  localState,
  ({ dynamicForm }: { dynamicForm: DynamicFormType }) => {
    const editorConfig = dynamicForm.editorConfig || ({} as PDFEditorConfig);

    const selectedField = editorConfig.selectedField;
    const questions = editorConfig.questions;
    const selectedRole = editorConfig.selectedRole;

    const userQuestions = selectedField
      ? questions?.[selectedField.pageNumber]?.[selectedField?.roleId || selectedRole?.roleId]
      : undefined;

    const selectedFieldIndex = selectedField
      ? userQuestions?.findIndex((question) => question.UUID === selectedField?.UUID)
      : -1;

    return {
      ...editorConfig,
      formRoles: editorConfig.formRoles || [],
      questions: editorConfig.questions || {},
      selectedRole: editorConfig.selectedRole || {},
      selectedField:
        selectedFieldIndex !== undefined ? userQuestions?.[selectedFieldIndex] : undefined,
      selectedFieldIndex: selectedFieldIndex,
    } as PDFEditorConfigSelectType;
  },
);

export const getDynamicFormScaleSelector = createSelector(
  localState,
  ({ dynamicForm }: { dynamicForm: DynamicFormType }) => dynamicForm.scale || DEFAULT_PDF_SCALE,
);

interface SignatureConfigSelectType extends DynamicSignatureConfig {
  selectedFieldUUID: string;
}

export const getDynamicFormSignatureConfigSelector = createSelector(
  localState,
  ({ dynamicForm }: { dynamicForm: DynamicFormType }) => {
    const signatureConfig = dynamicForm?.signatureConfig || ({} as DynamicSignatureConfig);

    let selectedFieldUUID =
      signatureConfig.activeQuestions?.[signatureConfig?.activeQuestionIndex]?.UUID;

    if (!selectedFieldUUID) {
      selectedFieldUUID =
        signatureConfig.otherSignatoriesQuestions?.[signatureConfig?.activeQuestionIndex]?.UUID;
    }

    return {
      ...signatureConfig,
      selectedFieldUUID,
    } as SignatureConfigSelectType;
  },
);

export const getDynamicPostFormSelector = createSelector(
  localState,
  ({ dynamicForm }: { dynamicForm: DynamicFormType }) =>
    dynamicForm?.postForm || ({} as DynamicPostForm),
);

export const getDynamicFormRequiredErrorSelector = createSelector(
  localState,
  ({ dynamicForm }: { dynamicForm: DynamicFormType }) =>
    dynamicForm.errorConfig?.requiredQuestion || ([] as RequiredQuestion[]),
);

export const getFormsFiltersSelector = createSelector(localState, ({ filters }) => filters);
