import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

const InviteClient = memo(({ testid, className, onClick, type }) => {
  const getIcon = useCallback(() => {
    switch (type) {
      case 'circle':
        return (
          <>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 19C19.2091 19 21 17.2091 21 15C21 12.7909 19.2091 11 17 11C14.7909 11 13 12.7909 13 15C13 17.2091 14.7909 19 17 19Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 29V27C11 25.9391 11.4214 24.9217 12.1716 24.1716C12.9217 23.4214 13.9391 23 15 23H19C20.0609 23 21.0783 23.4214 21.8284 24.1716C22.5786 24.9217 23 25.9391 23 27V29"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M27 16V22M24 19H30H24Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Invite</span>
          </>
        );
      default: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0013 14.6667C14.9468 14.6667 17.3346 12.2789 17.3346 9.33333C17.3346 6.38781 14.9468 4 12.0013 4C9.05578 4 6.66797 6.38781 6.66797 9.33333C6.66797 12.2789 9.05578 14.6667 12.0013 14.6667Z"
              stroke="#FF576D"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 28V25.3333C4 23.9188 4.5619 22.5623 5.5621 21.5621C6.56229 20.5619 7.91885 20 9.33333 20H14.6667C16.0812 20 17.4377 20.5619 18.4379 21.5621C19.4381 22.5623 20 23.9188 20 25.3333V28"
              stroke="#FF576D"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25.332 10.666V18.666M21.332 14.666H29.332H21.332Z"
              stroke="#FF576D"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
    }
  }, [type]);

  return (
    <div testid={testid} className={className} onClick={onClick}>
      {getIcon()}
    </div>
  );
});

InviteClient.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  testid: PropTypes.string,
};

InviteClient.defaultProps = {
  className: '',
  onClick: () => {},
  type: '',
  testid: undefined,
};

export default InviteClient;
