import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

const Icon = memo(({ className, variant, color }) => {
  const getIcon = useCallback(() => {
    switch (variant) {
      case Icon.CAR:
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8.08398" cy="26.8203" r="3.5" stroke={color ?? '#303030'} strokeWidth="2" />
            <circle cx="31.7441" cy="26.8203" r="3.5" stroke={color ?? '#303030'} strokeWidth="2" />
            <path
              d="M12 27.5H28"
              stroke={color ?? '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.50037 27.5H3.25037C2.85453 27.5 1.67887 26.3043 1.25034 25.4254C0.821817 24.5466 1.17785 19.7127 1.60637 19.2733C2.0349 18.8338 5.00034 18 5.00034 18C7.28582 16.0958 12.4295 14 18.0003 14C23.1427 14 26.4291 17.0352 28.0003 18.5C29.2859 18.6465 32.5892 18.9062 34.6462 19.6093C36.7031 20.3124 38.3218 21.4705 38.7503 22.3494C39.1789 23.2282 38.9289 26.5606 38.5003 27C38.0718 27.4394 35.5003 27.5 35.5003 27.5"
              stroke={color ?? '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.2141 15.434C11.2141 15.434 9.24643 17.8307 9.66086 18.5886C9.83698 18.9107 13.0665 19.0086 16.7496 19.0181M27.528 18.2381C27.528 18.2381 26.1633 18.9229 25.1975 18.9391C23.0574 18.9751 19.7491 19.0259 16.7496 19.0181M16.7496 19.0181V14.5"
              stroke={color ?? '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.PUBLIC_TRANSIT:
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.83 28.5849C21.83 30.5648 20.2249 32.1698 18.2451 32.1698C16.2652 32.1698 14.6602 30.5648 14.6602 28.5849C14.6602 26.605 16.2652 25 18.2451 25C20.2249 25 21.83 26.605 21.83 28.5849Z"
              stroke={color ?? '#303030'}
              strokeWidth="2"
            />
            <path
              d="M1.5 29L14 29"
              stroke={color ?? '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 29H34.5C35.6046 29 36.5 28.1046 36.5 27V19.698C36.5 19.5663 36.487 19.435 36.4612 19.3058L34.3216 8.60777C34.1346 7.67292 33.3138 7 32.3604 7H1.5"
              stroke={color ?? '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.5 10H12M39 13L38 10H31M12 10V20M12 10H24M12 20H1.5M12 20H24M24 20V10M24 20V29M24 10H31M31 10V29"
              stroke={color ?? '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M31 20H32.4689C32.8169 20 33.159 20.0908 33.4611 20.2635L36.5 22"
              stroke={color ?? '#303030'}
              strokeWidth="2"
            />
          </svg>
        );
      case Icon.BIKE:
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 24C15 27.866 11.866 31 8 31C4.13401 31 1 27.866 1 24C1 20.134 4.13401 17 8 17C11.866 17 15 20.134 15 24Z"
              stroke={color ?? '#303030'}
              strokeWidth="2"
            />
            <path
              d="M39 24C39 27.866 35.866 31 32 31C28.134 31 25 27.866 25 24C25 20.134 28.134 17 32 17C35.866 17 39 20.134 39 24Z"
              stroke={color ?? '#303030'}
              strokeWidth="2"
            />
            <path
              d="M19.5 24H8L14.5 13H27L19.5 24Z"
              stroke={color ?? '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32 24L26 9L28.5 8.5"
              stroke={color ?? '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.5 24L13 9.75"
              stroke={color ?? '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5 9.5H15"
              stroke={color ?? '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.WALK:
        return (
          <svg
            width="45"
            height="45"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="42.5" cy="19.5" r="5.5" stroke={color ?? '#303030'} strokeWidth="2.5" />
            <path
              d="M31.7846 49.1154L34.4747 37.3685L33.3889 37.9437L28.6667 45.9561C28.1334 47.2571 26.6445 47.8803 25.3411 47.348C24.0376 46.8158 23.4133 45.3298 23.9465 44.0288L28.1722 35.6274C28.498 34.9799 28.9567 34.4134 29.5281 33.9673C31.6348 32.3226 36.9591 28.2376 38.1111 28.046C39.5553 27.8058 44.25 28.5173 45.1944 29.9312L50.0333 39.0062L55.8642 42.8861C57.036 43.6658 57.3526 45.2459 56.5715 46.4155C55.7903 47.585 54.2071 47.901 53.0353 47.1213L46.6605 42.8796C46.3036 42.6421 46.0126 42.3187 45.8142 41.9392L43.7778 38.415L42.8333 46.4274L49.7976 55.0869C50.2013 55.5534 50.47 56.1208 50.575 56.7283L52.6999 69.0295C53.019 70.8763 51.7775 72.6317 49.9271 72.9501C48.0767 73.2685 46.318 72.0295 45.999 70.1826L44.0344 58.8099L39.0556 52.5546L36.0604 59.4518C35.9786 59.7581 35.8541 60.0515 35.6907 60.3233L29.3158 70.9278C28.3498 72.5348 26.2613 73.0559 24.6512 72.0917C23.0411 71.1275 22.5189 69.043 23.485 67.436L29.6135 57.2413L31.7846 49.1154Z"
              stroke={color ?? '#303030'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.COMMUTES:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="16" cy="14.666" rx="4" ry="4" stroke="#AAABAB" strokeWidth="2" />
            <path
              d="M17.0979 29.1823L17.7132 29.9706L17.0979 29.1823ZM25.6667 14.6667C25.6667 17.5315 24.124 20.4069 22.1229 22.9057C20.1393 25.3825 17.814 27.3547 16.4826 28.394L17.7132 29.9706C19.1047 28.8843 21.5639 26.8032 23.684 24.1558C25.7865 21.5305 27.6667 18.2108 27.6667 14.6667H25.6667ZM15.5174 28.394C14.186 27.3547 11.8607 25.3825 9.87716 22.9057C7.87604 20.4069 6.33334 17.5315 6.33334 14.6667H4.33334C4.33334 18.2108 6.21355 21.5305 8.31607 24.1558C10.4361 26.8032 12.8953 28.8843 14.2868 29.9705L15.5174 28.394ZM6.33334 14.6667C6.33334 9.32791 10.6613 5 16 5V3C9.55669 3 4.33334 8.22334 4.33334 14.6667H6.33334ZM16 5C21.3388 5 25.6667 9.32791 25.6667 14.6667H27.6667C27.6667 8.22334 22.4433 3 16 3V5ZM16.4826 28.394C16.1913 28.6213 15.8087 28.6213 15.5174 28.394L14.2868 29.9705C15.3013 30.7625 16.6987 30.7625 17.7132 29.9706L16.4826 28.394Z"
              fill="#AAABAB"
            />
          </svg>
        );
      case Icon.PROXIMITY:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.9524 18.999C14.6942 22.0791 11.8255 24.7216 10.2427 26.0125C9.59566 26.5402 8.6914 26.5404 8.04435 26.0127C5.97686 24.3266 1.71484 20.3338 1.71484 16.0721C1.71484 11.929 5.04073 8.57031 9.14341 8.57031C10.9183 8.57031 12.5478 9.1989 13.8255 10.2476"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <circle cx="9.1423" cy="15.9978" r="2.85714" stroke="#AAABAB" strokeWidth="2" />
            <path
              d="M30.8577 12.1037C30.8577 17.1473 25.7056 21.8651 23.3902 23.7307C22.7389 24.2554 21.8336 24.2555 21.1823 23.7307C18.8669 21.8651 13.7148 17.1473 13.7148 12.1037C13.7148 7.31317 17.5524 3.42969 22.2863 3.42969C27.0201 3.42969 30.8577 7.31317 30.8577 12.1037Z"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <circle cx="22.2879" cy="11.9989" r="3.42857" stroke="#AAABAB" strokeWidth="2" />
          </svg>
        );

      default:
        return null;
    }
  }, [variant]);

  return <div className={className}>{getIcon()}</div>;
});

Icon.CAR = 'Car';
Icon.PUBLIC_TRANSIT = 'PublicTransit';
Icon.BIKE = 'Bicycle';
Icon.WALK = 'Walking';
Icon.COMMUTES = 'Commutes';
Icon.PROXIMITY = 'Proximity';

Icon.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  variant: undefined,
  color: undefined,
};

export default Icon;
