import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';

import { changeThreadContactsDrawerParamsAction } from 'store/actions/drawers/threadContacts';
import {
  getThreadContactsParticipantsListAndOwnerId,
  getThreadById,
} from 'store/selectors/sockets/threads';
import { getUserRoleSelector } from 'store/selectors/user';

import { DRAWER_THREAD_CONTACTS_TYPES } from 'settings/constants/drawers';
import { openAddParticipantsDrawerEffect } from 'store/effects/drawers/addParticipants';
import { getClientDetailsEffect } from 'store/effects/clientDetail';
import { requestGetClientsListEffect } from 'store/effects/clientsList';
import { setProfileExpandedSection } from 'store/effects/app';
import { NoThreads, Avatar } from 'components';
import { ArrowRight } from 'components/Icons';

import styles from './styles.module.scss';
import { appDrawerSetClientIdAction } from 'store/actions/app';

// TODO: add scroll
const ContactsListContent = (props) => {
  const { isTransactionMessages } = props;
  const dispatch = useDispatch();
  const { contacts, ownerId, threadId } = useSelector(getThreadContactsParticipantsListAndOwnerId);
  const viewerRole = useSelector(getUserRoleSelector);
  const thread = useSelector(getThreadById(threadId));

  const getClientDetails = useCallback(
    (contactId) => {
      const participant = thread.Participants.filter((p) => p.Id === contactId);

      dispatch(requestGetClientsListEffect());
      dispatch(getClientDetailsEffect({ id: participant[0].Id }, {}, (err) => {}));
    },
    [dispatch],
  );

  const onSelectContact = useCallback(
    (contactId, contactRole) => () => {
      const isClient = contactRole[0] === 'Client';
      const isViewerAgent = viewerRole === 'Agent';

      if (isClient && isViewerAgent) {
        dispatch(appDrawerSetClientIdAction({ id: contactId }));
        dispatch(setProfileExpandedSection(true));
      } else {
        dispatch(
          changeThreadContactsDrawerParamsAction({
            params: {
              contactId,
              threadId,
              isTransactionMessages,
            },
            open: true,
            type: DRAWER_THREAD_CONTACTS_TYPES.CONTACT,
          }),
        );
      }
    },
    [threadId, thread, dispatch, isTransactionMessages],
  );

  const onAddContact = useCallback(() => {
    dispatch(openAddParticipantsDrawerEffect({ open: true }));
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <div testid="title" className={styles.titleLabel}>
          Contacts
        </div>
      </div>
      {contacts?.length ? (
        contacts.map((participant) => (
          <div
            testid="contact_item"
            key={participant.Id}
            className={styles.item}
            onClick={onSelectContact(participant.Id, participant.Roles)}
          >
            <div className={styles.itemImage}>
              <Avatar
                src={participant.AvatarUrl}
                avatarClassName={styles.itemImageAvatar}
                placeholder={convertNameToAvatarPlaceholder(
                  `${participant.FirstName} ${participant.LastName}`,
                )}
              />
            </div>
            <div className={styles.itemContent}>
              <div className={styles.itemContentTitle}>
                <div
                  testid="item_title"
                  className={styles.itemContentTitleName}
                >{`${participant.FirstName} ${participant.LastName}`}</div>
              </div>
              <div testid="item_subtitle" className={styles.itemContentText}>
                {participant.Id === ownerId ? 'Owner' : participant?.Roles?.[0]}
              </div>
            </div>
            <div className={styles.itemRight}>
              <ArrowRight className={styles.itemRightIcon} />
            </div>
          </div>
        ))
      ) : (
        <NoThreads>No contacts</NoThreads>
      )}
    </div>
  );
};

ContactsListContent.propTypes = {
  isTransactionMessages: PropTypes.bool,
};

ContactsListContent.defaultProps = {
  isTransactionMessages: false,
};

export default ContactsListContent;
