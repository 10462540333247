import { Edit } from 'components/Icons';
import styles from './styles.module.scss';
import { FC, useState } from 'react';
import classNames from 'classnames';

interface ISummaryListItemProps {
  label: string;
  placeholder: string;
  value: any;
  component: any;
  stageIndex: string;
}

const SummaryListItem: FC<ISummaryListItemProps> = ({
  label,
  placeholder,
  value,
  stageIndex,
  component: Component,
}) => {
  const [edit, setEdit] = useState(false);

  const handleClick = () => {
    setEdit((prev) => !prev);
  };

  const handleClose = () => {
    setEdit(false);
  };

  return (
    <div className={classNames(styles.container, { [styles.active]: edit })}>
      <p className={styles.label}>{label}</p>
      {edit ? (
        <div className={styles.componentWrapper}>
          <Component handleClose={handleClose} stageIndex={stageIndex} />
        </div>
      ) : value ? (
        <>{value}</>
      ) : (
        <p className={styles.placeholder}> {placeholder}</p>
      )}
      <div className={classNames(styles.edit, { [styles.active]: edit })} onClick={handleClick}>
        <Edit stroke={'#262626'} strokeWidth={2} />
      </div>
    </div>
  );
};

export default SummaryListItem;
