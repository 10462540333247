import { handleActions } from 'redux-actions';
import { cloneDeep, get } from 'lodash-es';
import {
  requestPreApprovalDocumentsAction,
  setPreApprovalModalAction,
} from 'store/actions/mySearches';
import { appLogoutAction } from 'store/actions/app';
import { IDLE } from 'settings/constants/apiState';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  isModalOpen: false,
};

export default handleActions(
  {
    [requestPreApprovalDocumentsAction]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data.result', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [setPreApprovalModalAction]: (state, { payload }) => ({
      isModalOpen: get(payload, 'isModalOpen', initialData.isModalOpen),
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
