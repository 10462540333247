import { createSelector } from 'reselect';
import { get } from 'lodash-es';

const localState = ({ drawers }) => get(drawers, 'activityFilters');

export const getIsActivityFiltersDrawerOpenSelector = createSelector(
  localState,
  ({ open }) => open,
);

export const getActivityFiltersDrawerFieldsSelector = createSelector(
  localState,
  ({ data }) => data,
);

export const getActivityFiltersDrawerInitialFieldsSelector = createSelector(
  localState,
  ({ initialFilterData }) => initialFilterData,
);

export const getActivityLogsWorkshopSortOrderSelector = createSelector(
  localState,
  (currentState) => {
    return currentState?.sortBy?.order;
  },
);
