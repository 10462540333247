import { Button } from 'components-antd';

import styles from './style.module.scss';
import classNames from 'classnames';

export const Navigation = (props) => {
  const { buttonText, className, onContinue, disabled, loading = false } = props;

  return (
    <Button
      variant="secondary"
      className={classNames(styles.preFormFooterButton, className, {
        [styles.disabledButton]: disabled,
      })}
      onClick={onContinue}
      disabled={disabled}
      loading={loading}
    >
      {buttonText || 'Continue'}
    </Button>
  );
};
