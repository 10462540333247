export enum ShowingsArchiveStatus {
  Past = 'Past',
  Declined = 'Declined',
  Canceled = 'Canceled',
}

export enum ShowingsStatus {
  Pending = 'Pending',
  Upcoming = 'Upcoming',
  NewFeedback = 'New Feedback',
}
