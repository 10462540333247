import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { ArrowRight, HouseTransaction } from 'components/Icons';

import styles from './styles.module.scss';
import classNames from 'classnames';

export const Transaction = (props) => {
  const { transactionId, address, closingDate } = props;
  const history = useHistory();
  const [formattedDate, setFormattedDate] = useState<string>('');
  const [remaingDays, setRemaingDays] = useState<any>();

  useEffect(() => {
    const today = moment().format('YYYY-MM-DD');
    setRemaingDays(
      moment(closingDate, 'YYYY-MM-DD')
        .startOf('day')
        .diff(moment(today, 'YYYY-MM-DD').startOf('day'), 'days') + 1,
    );
    setFormattedDate(moment(closingDate).format('MM/DD'));
  }, [closingDate]);

  const routeTransactionRoom = () => {
    history.push(`/workshop/transactions/${transactionId}/overview`);
  };

  return (
    <div className={styles.transactionWrap} onClick={routeTransactionRoom}>
      <HouseTransaction className={styles.homeIcon} />
      <div className={styles.contentArea}>
        <div className={styles.propertyAddressWrap}>
          <h4 className={styles.address}>{address?.Line1}</h4>
          <h5 className={styles.state}>
            {address?.City}, {address?.State} {address?.Zip}
          </h5>
        </div>
        <div className={styles.transactionInfo}>
          <h6 className={styles.closingInfo}>Closing: {formattedDate}</h6>
          <span className={classNames(styles.badge, { [styles.overdue]: remaingDays < 0 })}>
            {remaingDays > 0 ? remaingDays + (remaingDays === 1 ? ' day' : ' days') : 'Overdue'}
          </span>
        </div>
      </div>
      <ArrowRight className={styles.arrowIcon} />
    </div>
  );
};
