import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TransactionContacts, TransactionContact } from 'types';
import { Row, Col } from 'components-antd';
import { Wrapper } from 'components';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import {
  getTransactionContactsEffect,
  requestGetAllTransactionContactsEffect,
} from 'store/effects/transactions';
import { getTransactionContactsSelector } from 'store/selectors/transaction';
import { ContactModal } from './ContactModal';

import styles from './styles.module.scss';
import { Contact } from './Contact';
import { requestGetUserParticipantAndContactsListEffect } from 'store/effects/drawers/addParticipants';

interface ContactsViewProps {
  isPending: boolean;
  className?: string;
  AvatarUrl?: string;
  detailEditMode?: boolean;
}

export const ContactsView = ({ detailEditMode, isPending }: ContactsViewProps) => {
  const dispatch = useDispatch();

  const params: { id?: string } = useParams();
  const contacts: TransactionContacts = useSelector(getTransactionContactsSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [showModal, setShowModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState<TransactionContact | null>(null);

  const openEditModal = (contact) => {
    setSelectedContact(contact);
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(
      getTransactionContactsEffect({
        id: params?.id,
      }),
    );
    fullAccess &&
      dispatch(
        requestGetUserParticipantAndContactsListEffect({
          includeAll: true,
        }),
      );
  }, []);

  const onCloseModal = () => {
    setShowModal(false);
    setSelectedContact(null);
  };

  return (
    <div className={classNames(styles.contactsView, { [styles.disabledSection]: detailEditMode })}>
      <div className={styles.contactsHeader}>
        <div className={styles.title}>Contacts</div>
        <div className={styles.subHeading}>Contact Information</div>
      </div>
      <div className={styles.cardView}>
        <Wrapper isPending={isPending}>
          {contacts.length === 0 ? (
            <div className={styles.noContacts}>No Contacts</div>
          ) : (
            contacts.map((contact, index) => (
              <Contact key={index} contact={contact} openEditModal={openEditModal} />
            ))
          )}
        </Wrapper>
      </div>
      <ContactModal
        isOpen={showModal}
        editData={selectedContact as TransactionContact}
        onCloseModal={onCloseModal}
      />
    </div>
  );
};
