import { createAction } from 'redux-actions';

export const requestGetPartnersListAction = createAction('REQUEST/GET_PARTNERS_LIST');

export const partnersSetSearchAction = createAction('PARTNERS/SET_SEARCH');
export const partnersClearSearchAction = createAction('PARTNERS/CLEAR_SEARCH');
export const partnersResetAction = createAction('PARTNERS/RESET');

export const partnersChangeRecommendPartnerAction = createAction(
  'PARTNERS/CHANGE_RECOMMEND_PARTNER_STATUS',
);

export const requestGetRecommendedPartnersByAgentListAction = createAction(
  'REQUEST/GET_RECOMMENDED_PARTNERS_BY_AGENT_LIST',
);

export const toggleRequestQuoteBtnAction = createAction('TOGGLE/REQUEST_QUOTE_BUTTON');
