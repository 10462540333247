import { Share } from 'components/Icons';

import styles from './styles.module.scss';

export const SettingsCoBuyer = () => {
  return (
    <div className={styles.coBuyer}>
      <h1 className={styles.pageTitle}>Co-Buyer</h1>
      <div className={styles.contentWrap}></div>
    </div>
  );
};
