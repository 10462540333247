import { GetAvailableSubscriptionPlansResponse } from 'api/subscription';
import { calculateStandardPrice, calculateLegacyPrice } from 'pages/Paywall/helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestGetAvailableSubscriptionPlansEffect,
  requestGetImplementationPlansEffect,
  requestGetCouponEffect,
  requestSubscribeStandardEffect,
  requestSubscribeLegacyEffect,
} from 'store/effects/subscription';
import {
  getAvailablePlansSelector,
  getCurrentPlanLevelSelector,
  getImplementationPlanSelector,
  getStandardCouponSelector,
  getStandardSubscriptionSelector,
} from 'store/selectors/subscriptions';
import { debounce } from 'lodash';
import { CurrentActiveSubscriptionPlan } from 'pages/Paywall/types';

export function usePaywallSubscription(props?: {
  requestedSeatCount?: number;
  selectedPlan: string | null;
  planId: string;
  isStandard: boolean;
  currentActivePlan: CurrentActiveSubscriptionPlan;
}) {
  const {
    requestedSeatCount = 2,
    isStandard,
    planId,
    selectedPlan,
    currentActivePlan,
  } = props || {};
  const dispatch = useDispatch();
  const availablePlans = useSelector(getAvailablePlansSelector);
  const availablePlansLoading = availablePlans?.state === 'pending';
  const currentPlan = useSelector(getCurrentPlanLevelSelector);
  const implementationPlan = useSelector(getImplementationPlanSelector);
  const implementationPlanLoading = implementationPlan?.state === 'pending';
  const isResubscribing = currentActivePlan?.SubscriptionStatus === 'canceled';
  // Test Coupon JOCl9Gdp
  const coupon = useSelector(getStandardCouponSelector);
  const couponLoading = coupon?.state === 'pending';
  const standardSubscriptionState = useSelector(getStandardSubscriptionSelector);
  const checkoutLoading = standardSubscriptionState?.state === 'pending';

  const standardPlans: GetAvailableSubscriptionPlansResponse[] = useMemo(
    () =>
      availablePlans?.data?.length
        ? availablePlans?.data?.filter(({ Plan }) => Plan === 'standard')
        : null,
    [availablePlans],
  );

  const standardPlan: GetAvailableSubscriptionPlansResponse | null = useMemo(() => {
    if (!standardPlans?.length) return null;
    const activeMembersCount = currentActivePlan?.ActualSeatsCount || 0;
    const count = Math.max(requestedSeatCount, activeMembersCount);
    const nickname = count >= 3 ? 'team' : 'solo';
    return standardPlans.find((plan) =>
      plan.Prices.some((price) => price.Nickname === nickname),
    ) as GetAvailableSubscriptionPlansResponse;
  }, [requestedSeatCount, currentActivePlan, standardPlans]);

  const legacyPlan: GetAvailableSubscriptionPlansResponse = useMemo(
    () =>
      availablePlans?.data?.length
        ? availablePlans?.data?.find(({ Plan }) => Plan === 'paid')
        : null,
    [availablePlans],
  );

  const legacyPlanDetails = useMemo(() => {
    const planDetails = legacyPlan?.Prices?.find((price) => price.PriceKey === selectedPlan);
    return planDetails ?? null;
  }, [legacyPlan?.Prices]);

  const subscriptionPrice = useMemo(() => {
    const activeMembersCount = currentActivePlan?.ActualSeatsCount || 0;
    if (isStandard && standardPlans?.length) {
      const count = Math.max(requestedSeatCount, activeMembersCount);
      return calculateStandardPrice(count, standardPlans);
    } else if (legacyPlanDetails && !isStandard && selectedPlan) {
      const planDetails = legacyPlan?.Prices;
      return calculateLegacyPrice(activeMembersCount, planDetails, selectedPlan);
    }
    return 0;
  }, [standardPlans, legacyPlanDetails, isStandard, currentActivePlan]);

  const implementationPrice = useMemo(() => {
    if (!implementationPlan?.data?.Price || isResubscribing) {
      return 0;
    }
    const originalAmount = implementationPlan.data.Price.Amount;
    const percentOff = coupon?.data?.valid && coupon.data.percent_off ? coupon.data.percent_off : 0;
    const amountOff =
      coupon?.data?.valid && coupon.data.amount_off ? coupon.data.amount_off / 100 : 0;

    let finalAmount;
    if (percentOff > 0) {
      finalAmount = originalAmount - (originalAmount * percentOff) / 100;
    } else if (amountOff > 0) {
      finalAmount = originalAmount - amountOff;
    } else {
      finalAmount = originalAmount;
    }

    return finalAmount > 0 ? finalAmount : 0;
  }, [implementationPlan, isResubscribing, coupon?.data]);

  const getCoupon = useCallback(
    debounce((coupon: string) => {
      dispatch(requestGetCouponEffect({ coupon }));
    }, 500),
    [],
  );

  const subscribeStandard = useCallback(
    async (
      paymentMethodId: string,
      planId: string,
      requestedSeatsCount: number,
      activationCoupon?: string,
      skipImplementation?: boolean,
      cb?: (err: Error) => void,
    ) => {
      dispatch(
        requestSubscribeStandardEffect(
          {
            paymentMethodId,
            planId,
            requestedSeatsCount,
            activationCoupon,
            skipImplementation,
          },
          cb,
        ),
      );
    },
    [],
  );

  const subscribeLegacy = useCallback(
    async (
      paymentMethodId: string,
      planId: string,
      requestedSeatsCount: number,
      priceKey?: string,
      activationCoupon?: string,
      cb?: (err: Error) => void,
    ) =>
      dispatch(
        requestSubscribeLegacyEffect(
          {
            paymentMethodId,
            planId,
            requestedSeatsCount,
            priceKey,
            activationCoupon,
          },
          cb,
        ),
      ),
    [],
  );

  useEffect(() => {
    dispatch(requestGetAvailableSubscriptionPlansEffect());
    dispatch(requestGetImplementationPlansEffect());
  }, []);

  return {
    getCoupon,
    subscribeStandard,
    availablePlansLoading,
    checkoutLoading,
    couponLoading,
    implementationPlanLoading,
    implementationPrice,
    subscriptionPrice,
    currentPlan,
    availablePlans,
    standardPlan,
    legacyPlanDetails,
    legacyPlan,
    subscribeLegacy,
  };
}
