import { Tooltip } from 'components-antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';

import styles from './styles.module.scss';
import classNames from 'classnames';

interface CompareofferTooltipProps {
  price: string;
  client: string;
}
export const CompareOfferTooltip = ({ price, client }: CompareofferTooltipProps) => {
  return (
    <Tooltip
      placement="top"
      getPopupContainer={(trigger) => trigger}
      overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
      title={
        <>
          <p className={styles.tooltipData}>
            <span className={styles.fieldTitle}>Price: </span>
            <NumberFormat
              thousandSeparator
              displayType="text"
              value={price}
              prefix="$"
              renderText={(val) => <span testid="property_price">{val}</span>}
            />
          </p>
          <p className={styles.tooltipData}>
            <span className={styles.fieldTitle}>Client: </span>
            {client}
          </p>{' '}
        </>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );
};
