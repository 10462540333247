import { createAction } from 'redux-actions';

// showing details
export const requestGetShowingDetails = createAction('REQUEST/GET_SHOWING_DETAILS');
export const requestGetShowingAvailability = createAction('REQUEST/GET_SHOWING_AVAILABILITY');
export const requestFillShowingDetails = createAction('REQUEST/FILL_SHOWING_DETAILS');
export const requestFillShowingAvailability = createAction('REQUEST/FILL_SHOWING_AVAILABILITY');

// showing appointments
export const requestCreateShowingAppointment = createAction('REQUEST/CREATE_SHOWING_APPOINTMENT');
export const requestUpdateShowingAppointment = createAction('REQUEST/UPDATE_SHOWING_APPOINTMENT');
export const requestGetAllShowingAppointment = createAction('REQUEST/GET_ALL_SHOWING_APPOINTMENT');
export const requestGetShowingAppointmentById = createAction(
  'REQUEST/GET_SHOWING_APPOINTMENT_BY_ID',
);
export const requestFillShowingAppointment = createAction('REQUEST/FILL_SHOWING_APPOINTMENT');
export const setShowingAppointmentStatusAction = createAction('SET_SHOWINGS_APPOINTMENT_STATUS');

// showing appointments approval
export const requestGetShowingAppointmentApprovalByUserId = createAction(
  'REQUEST/GET_SHOWING_APPOINTMENT_APPROVAL_BY_USER_ID',
);
export const requestUpdateShowingAppointmentApproval = createAction(
  'REQUEST/UPDATE_SHOWING_APPOINTMENT_APPROVAL',
);

export const requestDeleteAppointmentDocumentById = createAction(
  'DELETE_APPOINTMENT_DOCUMENT_BY_ID',
);
