import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icons } from '../../../../components/Icons';

import styles from './styles.module.scss';

export const IconText = ({ variant, text, infoText, className, detailClass, infoClass }) => {
  return (
    <div className={classNames(styles.iconText, className)}>
      <Icons variant={variant} className={styles.icon} />
      <div className={classNames(styles.detail, detailClass)}>
        <h4 className={classNames(styles.title, styles.text)}>{text}</h4>
        {infoText && <h4 className={classNames(styles.info, infoClass)}>{infoText}</h4>}
      </div>
    </div>
  );
};

IconText.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  testid: PropTypes.string,
  text: PropTypes.string,
  infoText: PropTypes.string,
  detailClass: PropTypes.string,
  infoClass: PropTypes.string,
};

IconText.defaultProps = {
  className: '',
  variant: '',
  text: '',
  infoText: '',
  infoClass: '',
  detailClass: '',
  testid: undefined,
};
