import { sortDetails } from 'pages/FormProcess/helper';

export const prepareVoidFormData = (formDetails) => {
  const {
    clientDetails,
    client,
    signatories,
    transactionId,
    address,
    copyRecipients = [],
    propertyAddress,
  } = formDetails;

  const sortedSignatories = sortDetails(signatories, 'order');
  const modifiedFormRoles = sortedSignatories.map((item, index) => ({
    RoleId: index + 1,
    Id: index + 1,
    UserType: 'Agent',
    Name: '',
  }));

  let modifiedSignatories = {};
  modifiedFormRoles.forEach((item, index) => {
    const signatoryItem = signatories[index];
    modifiedSignatories[item.RoleId] = {
      Email: signatoryItem.email,
      FormRole: item.RoleId,
      Name: signatoryItem.name,
      UserId: signatoryItem.userId,
      SignatureSequence: index + 1,
    };
  });

  let modifiedRecipients = copyRecipients.map((recipient, index) => ({
    type: index,
    id: recipient.Id.toString(),
  }));

  let clientIds = clientDetails.map(({ userId }) => userId);

  return {
    formRoles: modifiedFormRoles,
    signatories: modifiedSignatories,
    copyRecipients: modifiedRecipients,
    clientIds,
    clientName: client,

    ...(transactionId && { transactionId, clientAddress: address }),
    ...(!transactionId && propertyAddress && { address: propertyAddress }),
  };
};
