import {
  editMessage,
  getMessagesByThreadId,
  sendNewMessage,
  deleteMessage,
} from 'services/sockets';
import { showErrorMessage } from 'helpers/errors';
import {
  replaceTempMessageAction,
  setNewMessageWithTempIdAction,
  socketSetEditMessageAction,
  socketsResetMessagesAction,
  socketsStoreMessagesByThreadIdAction,
} from 'store/actions/sockets/messages';
import { getState } from 'store';
import { uniqueId } from 'lodash-es';
import moment from 'moment';
import { preparedAttachments } from '../helpers';

export function socketsGetMessagesByThreadIdEffect(cfg, options, cb = () => {}) {
  return (dispatch) => {
    const config = {
      Id: cfg?.Id,
    };
    getMessagesByThreadId(config, (err, data) => {
      if (err) {
        return showErrorMessage(err);
      }
      dispatch(socketsStoreMessagesByThreadIdAction({ data, threadId: cfg?.Id }));
      cb(err, data);
    });
  };
}

export function editMessageEffect(cfg) {
  return () => {
    const config = {
      MessageId: cfg?.MessageId,
      Text: cfg?.Text || '',
      Attachments: preparedAttachments(cfg?.Attachments),
      MessageType: cfg?.MessageType,
      MessageMeta: cfg?.MessageMeta,
    };
    editMessage(config, (err) => {
      if (err) {
        return showErrorMessage(err);
      }
    });
  };
}

export function deleteMessageEffect(cfg) {
  return () => {
    const config = {
      MessageId: cfg?.MessageId,
    };
    deleteMessage(config, (err) => {
      if (err) {
        return showErrorMessage(err);
      }
    });
  };
}

export function setNewMessageWithTempIdEffect(cfg) {
  return (dispatch) => {
    const { user } = getState();
    const userId = user?.data?.Id;
    const config = {
      CreatedDate: new Date(),
      SenderUserId: userId,
      MessageThreadId: cfg?.ThreadId,
      TempId: cfg?.TempId,
      Text: cfg?.Text,
      Attachments: cfg?.Attachments,
      MessageType: cfg?.MessageType,
      MessageMeta: cfg?.MessageMeta,
    };
    dispatch(setNewMessageWithTempIdAction(config));
  };
}

export function sendNewMessageEffect(cfg, options, cb = () => {}) {
  return (dispatch) => {
    const config = {
      ThreadId: cfg?.ThreadId,
      TempId: `${uniqueId()}-${moment().valueOf()}`,
      Text: cfg?.Text || '',
      Attachments: preparedAttachments(cfg?.Attachments),
      RequestTour: cfg?.RequestTour,
      MessageType: cfg?.MessageType,
      MessageMeta: cfg?.MessageMeta,
    };

    dispatch(setNewMessageWithTempIdEffect(config));
    sendNewMessage(config, (err, data) => {
      if (err) {
        return showErrorMessage(err);
      }
      dispatch(replaceTempMessageAction(data));
      cb(err, data);
    });
  };
}

export function resetMessagesEffect() {
  return (dispatch) => {
    dispatch(socketsResetMessagesAction());
  };
}

export function socketSetEditMessageEffect(cfg) {
  return (dispatch) => {
    dispatch(socketSetEditMessageAction(cfg));
  };
}
