import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { Button, Select } from 'components';
import Input from 'components/Form/Input';
import { SearchIcon } from 'components/Icons';
import { AGENT, CLIENT, SUPER_USER, THIRD_PARTY } from 'settings/constants/roles';
import { searchUsersDashboardEffect } from 'store/effects/adminPanel/users';
import { getUsersDashboardSelector, getUsersSearchSelector } from 'store/selectors/adminPanel';
import styles from './styles.module.scss';

const rolesOptions = [
  {
    value: SUPER_USER,
    name: 'Super User',
  },
  {
    value: CLIENT,
    name: 'Client',
  },
  {
    value: AGENT,
    name: 'Agent',
  },
  {
    value: THIRD_PARTY,
    name: 'Third Party',
  },
];

const Filters = () => {
  const dispatch = useDispatch();
  const { isPending } = useSelector(getUsersDashboardSelector);
  const search = useSelector(getUsersSearchSelector);
  const onSubmit = useCallback(
    (values) => dispatch(searchUsersDashboardEffect(values)),
    [dispatch],
  );

  const formik = useFormik({
    initialValues: search,
    enableReinitialize: true,
    onSubmit,
  });

  const onRolesChanged = useCallback(
    (e, values) => formik.setFieldValue('roles', values),
    [formik],
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.filtersFormWrapper}>
        <div className={styles.filtersWrapper}>
          <div className={styles.filterWrapper}>
            <Input
              id="searchString"
              className={styles.input}
              type="text"
              name="searchString"
              placeholder="Search"
              error={formik.touched.searchString ? formik.errors.searchString : ''}
              value={formik.values.searchString}
              onChange={formik.handleChange}
              disabled={isPending}
              variant={Input.LIGHT_FULL}
              testid="search_input"
            />
          </div>
          <div className={styles.filterWrapper}>
            <Select
              id="roles"
              name="roles"
              search
              multiple
              options={rolesOptions}
              value={formik.values.roles}
              placeholder="Roles"
              onSelect={onRolesChanged}
              disabled={isPending}
              className={{ wrapper: styles.selectWrapper }}
              error={formik.touched.roles ? formik.errors.roles : ''}
              variant={Select.LIGHT_FULL}
              testid="roles_select"
            />
          </div>
        </div>
        {formik.dirty ? (
          <Button
            type="submit"
            className={styles.searchButton}
            isPending={isPending}
            title={<SearchIcon />}
            testid="search_button"
          />
        ) : (
          <Button
            type="button"
            className={styles.searchButton}
            title={<SearchIcon />}
            testid="search_button"
          />
        )}
      </div>
    </form>
  );
};

Filters.propTypes = {};
Filters.defaultProps = {};

export default Filters;
