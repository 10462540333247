import PropTypes from 'prop-types';
import { Wrapper as PendingWrapper, SearchFieldWithDebounce, Select } from 'components';

import { Modal } from 'components-antd';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMlsAgentsDashboardSelector,
  getMlsAgentsDataDashboardSelector,
} from 'store/selectors/adminPanel';
import { getAllMlsAgentsDashboardEffect, searchMlsAgentsEffect } from 'store/effects';
import { useEffect, useState } from 'react';
import { MlsAccessStatusEnum } from './constants';
import { TeamsTable } from '../Components/TeamsTable';
import { tableColumns } from './tableColumns';
import GroupBy from './Components/GroupBy';

export const MlsAgents = (props) => {
  const { agentId, agentName } = props;
  const dispatch = useDispatch();
  const agents = useSelector(getMlsAgentsDataDashboardSelector);
  const [mlsAgents, setMlsAgents] = useState([]);
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const { isIdle, isPending } = useSelector(getMlsAgentsDashboardSelector);

  const resetMlsSearch = () => {
    setSearch('');
  };

  const searchMls = (val) => {
    setSearch(val);
  };

  const getSearchAgents = (agents) => {
    let result = (agents || []).filter((data) => {
      if (search) {
        const formattedSearch = search?.toLowerCase();
        let mlsName = `${data?.MlsName}`;
        let status = data?.Status;
        let approvedByName = data?.ApprovedByName;
        return (
          mlsName?.toLowerCase()?.includes(formattedSearch) ||
          status?.toLowerCase()?.includes(formattedSearch) ||
          approvedByName?.toLowerCase()?.includes(formattedSearch)
        );
      }
      return true;
    });
    return result;
  };

  useEffect(() => {
    dispatch(getAllMlsAgentsDashboardEffect({ agentId }));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (agents) {
      const tempAgents = getSearchAgents(agents);
      setMlsAgents(tempAgents);
    }
  }, [agents, search]);

  const onStatusFilterChanged = (val) => {
    setStatus(val);
    dispatch(
      searchMlsAgentsEffect({
        status: val,
        agentId,
      }),
    );
  };

  const tableProps = {
    columns: tableColumns(),
    onRow: (record) => {
      return {
        onClick: (event) => {},
      };
    },
  };

  return (
    <div>
      <PendingWrapper
        isPending={isPending}
        className={classNames({ [styles.pendingWrapper]: isPending })}
      >
        <div>
          <span className={styles.mlsTitle}>{`MLS(${agentName})`}</span>
        </div>
        <div className={styles.mlsBar}>
          <span className={styles.mlsCount}>{agents?.length ?? 0} MLS&apos;s</span>
          <div className={styles.filterWrapper}>
            <GroupBy mlsGroupBy={status} setMlsGroupBy={onStatusFilterChanged} />
            <div className={styles.searchWrapper}>
              <SearchFieldWithDebounce
                className={styles.inputWrap}
                classNameInput={styles.searchInput}
                iconClassName={styles.icon}
                resetAction={resetMlsSearch}
                sendAction={searchMls}
                isLoading={isPending}
                placeholder="Search"
                testid="search"
                isTransactionAggregate={true}
                isExpandedVersion
                doNotDispatch={true}
              />
            </div>
          </div>
        </div>
        <TeamsTable dataSource={mlsAgents?.length > 0 ? mlsAgents : []} {...tableProps} />
      </PendingWrapper>
    </div>
  );
};

MlsAgents.propTypes = {
  agentId: PropTypes.number,
  agentName: PropTypes.string,
};

MlsAgents.defaultProps = {
  agentId: 0,
  agentName: '',
};
