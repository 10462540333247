import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';

import { get } from 'lodash-es';
import {
  fetchSalesLeadAction,
  fetchSalesLeadSignUpUsersAction,
  registerSalesLeadAction,
} from 'store/actions/salesLead';

const initialData = {
  register: {
    state: IDLE,
    data: null,
    meta: {},
  },
  list: {
    state: IDLE,
    data: null,
    meta: {},
  },
  signupUsers: {
    state: IDLE,
    data: null,
    meta: {},
  },
};

export default handleActions(
  {
    [registerSalesLeadAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        register: {
          ...state.register,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },
    [fetchSalesLeadAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        list: {
          ...state.list,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },
    [fetchSalesLeadSignUpUsersAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        signupUsers: {
          ...state.signupUsers,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },
  },
  initialData,
);
