import styles from './styles.module.scss';
import { Switch } from 'components-antd';

type MasterControlPropsType = {
  enabled: boolean;
  handleMasterControl: Function;
};

function MasterControl(props: MasterControlPropsType) {
  return (
    <div className={styles.masterControl}>
      <div className={styles.masterControlLabel}>
        {props.enabled ? 'KITs Active' : 'KITs Inactive'}
      </div>
      <Switch
        checked={props.enabled}
        onChange={(v) => {
          props.handleMasterControl(v);
        }}
      />
    </div>
  );
}

export default MasterControl;
