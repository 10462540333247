import { combineReducers } from 'redux';

import filterDrawer from './filterDrawer';
import savedSearchesDrawer from './savedSearchesDrawer';
import results from './results';
import sort from './sort';
import viewType from './viewType';
import pageInfo from './pageInfo';
import addEditSchoolDrawer from './addEditSchoolDrawer';

export default combineReducers({
  pageInfo,
  filterDrawer,
  savedSearchesDrawer,
  results,
  sort,
  viewType,
  addEditSchoolDrawer,
});
