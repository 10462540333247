import React, { useCallback } from 'react';
import { formatNameToAvatarLetters } from 'helpers/index';
import { Avatar } from 'components';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import { getQuotesEntryIdThreadIdMap } from 'store/selectors/sockets/threads';
import { Icons } from 'pages/Quotes/Icons';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { quoteRequestStatus } from 'settings/constants/quotes';

interface DetailsProps {
  name: string;
  type: string;
  logoUrl?: string;
  property?: any;
  client?: string;
  setFullProfile: (a: boolean) => void;
  onCancel: any;
  handleThirdPartyMessageButtonClick: () => void;
}

export const Details = ({
  name,
  type,
  logoUrl,
  property,
  client,
  setFullProfile,
  onCancel,
  handleThirdPartyMessageButtonClick,
}: DetailsProps) => {
  const address1: string = property?.Address?.Line1;
  const { quote } = useSelector(getQuoteDetailsSelector);
  const { isThirdParty } = useSelector(getUserRolesMapSelector);
  const dispatch = useDispatch();
  const entryThreadIdMap = useSelector(getQuotesEntryIdThreadIdMap);
  return (
    <div className={styles.details}>
      <div></div>
      <div className={styles.vendorWrap}>
        <Avatar
          avatarClassName={styles.icon}
          src={logoUrl}
          placeholder={
            <div className={styles.avatarPlaceholder}>{formatNameToAvatarLetters(`${name}`)}</div>
          }
        />
        <div className={styles.info}>
          <div>
            <span
              className={classNames(styles.line1, styles.clickable)}
              onClick={() => {
                if (!isThirdParty) {
                  setFullProfile(true);
                }
              }}
            >
              {name}
            </span>
            <br />
            <span className={styles.line2}>{type}</span>
          </div>
          {isThirdParty && (
            <div
              onClick={() => {
                onCancel();
                handleThirdPartyMessageButtonClick();
              }}
              className={styles.commentWrapper}
            >
              <Icons
                className={classNames(styles.icon, styles.clickable)}
                testid="comment_icon"
                variant={Icons.DETAILS_COMMENT}
              />
            </div>
          )}
        </div>
      </div>
      {isThirdParty && quote?.Status !== quoteRequestStatus.Responded ? (
        <></>
      ) : (
        <>
          {address1 && (
            <div className={styles.propertyInfo}>
              <span className={styles.title}>Property</span>
              <br />
              <span className={styles.contentFirstRow}>{address1}</span>
              <br />
              <span className={styles.contentSecondRow}>
                {`${property?.Address?.City}, ${property?.Address?.State} ${property?.Address?.Zip}`}
              </span>
            </div>
          )}
          {isThirdParty && client && (
            <div className={styles.clientInfo}>
              <span className={styles.title}>Client</span>
              <br />
              <span className={styles.contentFirstRow}>{client}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
