import AntInput, { InputProps } from 'antd/lib/input';
import classNames from 'classnames';

import { FilterSearch } from 'components/Icons';

import styles from './styles.module.scss';

interface SearchProps extends InputProps {}

export const SearchInput = ({ className, ...props }: SearchProps) => {
  return (
    <AntInput
      prefix={<FilterSearch />}
      placeholder="Search"
      className={classNames(styles.searchInput, className)}
      {...props}
    />
  );
};
