import { handleActions } from 'redux-actions';

import { initialImageAnalysisAction } from 'store/actions/feed';
import { IDLE } from 'settings/constants/apiState';

const initialData = {
  propertyImageProcesses: {},
};

export default handleActions(
  {
    [initialImageAnalysisAction]: (state, { payload }) => ({
      ...state,
      propertyImageProcesses: payload.data
        ? { ...state.propertyImageProcesses, ...payload.data }
        : state.propertyImageProcesses,
    }),
  },
  initialData,
);
