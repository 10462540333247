import { Table } from 'antd';
import styles from './styles.module.scss';

export const LinkFormsTable = ({ columns, dataSource, onRowClick, loading, ...tableProps }) => {
  return (
    <Table
      className={styles.linkFormsTable}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={{ pageSize: 5, showSizeChanger: false }}
      onRow={(record) => {
        return {
          onClick: (event) => {
            onRowClick(record);
          },
        };
      }}
      {...tableProps}
    />
  );
};
