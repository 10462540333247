import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import { PENDING } from 'settings/constants/apiState';
import { AGENT_TYPE } from 'settings/constants/drawers';

import { onBoardingSignUpEffect } from 'store/effects';
import {
  getUserDataSelector,
  getAgentTypeSelector,
  isPartnerSelector,
  getUserRoleSelector,
} from 'store/selectors/user';

import { ValidationSchema } from '../agent/AreasOfOperationForm/validation';

import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import AreaOperations from 'pages/OnBoarding/components/Forms/AboutForm/AreaOperations';
import { Question } from 'pages/RequestQuote/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';

import styles from './styles.module.scss';
import { OnboardingContinueButton } from '../agent/components/OnboardingContinueButton';
import { OnboardingSkipButton } from '../agent/components/OnboardingSkipButton';
import { THIRD_PARTY } from 'settings/constants/roles';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';

type Props = {
  className?: string;
  onNext?: () => void;
  stageIndex?: number;
  Controls?: any;
};

export const AreasOfOperationPartnerForm: React.FC<Props> = ({
  className = '',
  onNext = () => {},
  stageIndex,
  Controls = () => null,
}) => {
  const dispatch = useDispatch();
  const { onBoarding, signUpRole } = useSelector(getOnBoardingData);
  const isPending = onBoarding.state === PENDING;
  const user = useSelector(getUserDataSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const isPartner = useSelector(isPartnerSelector);
  const userRole = useSelector(getUserRoleSelector);
  const history = useHistory();

  const onSkip = () => (userRole === THIRD_PARTY ? history.push(routes.index) : onNext());

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        onBoardingSignUpEffect(
          { ...values, radius: 100, stageIndex },
          { isCommonInfo: true, isPartner },
          (err) => {
            if (!err) {
              onSkip();
            }
          },
        ),
      );
    },
    [stageIndex, onSkip, dispatch],
  );

  const formik = useFormik({
    initialValues: {
      areasOfOperation: user?.[signUpRole]?.Partner?.AreasOfOperation || [],
    },
    validationSchema: ValidationSchema,
    validateOnChange: true,
    onSubmit,
  });

  return (
    <OnBoardingWrapper className={styles.stageWrap}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question>What are your areas of operation?</Question>
        <form onSubmit={formik.handleSubmit}>
          <AnswersContainer>
            <AreaOperations formik={formik as any} isPending={isPending} />
          </AnswersContainer>
          <ButtonsContainer>
            <OnboardingContinueButton
              htmlType="submit"
              testid="next"
              isPending={isPending}
              className={styles.submitBtn}
              disabled={!formik.isValid}
            />
          </ButtonsContainer>
        </form>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};
