import moment from 'moment';
import { useState } from 'react';

import { Table, TableProps } from 'antd';
import { Modal } from 'components-antd';
import { DataType, sampleData } from './helper';
import classnames from 'classnames';
import { TableModal } from './TableModal';

import styles from './styles.module.scss';

const TableView = ({ open }) => {
  const [rowValue, setRowValue] = useState<DataType>();
  const [openModal, setOpenModal] = useState(false);

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      sorter: {
        compare: (record1, record2) => {
          const a = moment(record1.month, 'MMMM YYYY');
          const b = moment(record2.month, 'MMMM YYYY');
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      sorter: {
        compare: (record1, record2) => {
          const a = parseInt(record1.value.replace(/[^0-9.]/g, ''));
          const b = parseInt(record2.value.replace(/[^0-9.]/g, ''));
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: '',
      dataIndex: 'percentage',
      key: 'percentage',
      render: (percentage) => {
        return percentage.includes('+') ? (
          <span className={styles.positive}>{percentage}</span>
        ) : (
          <span className={styles.negative}>{percentage}</span>
        );
      },
    },
  ];

  return (
    <div className={classnames(styles.tableContainer, { [styles.openMenu]: open })}>
      <Table
        className={styles.statsTable}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              console.log(e, record, rowIndex);
              setRowValue(record);
              setOpenModal(true);
            },
          };
        }}
        columns={columns}
        dataSource={sampleData}
        pagination={false}
      />
      <Modal
        width={1000}
        className={styles.tableModal}
        open={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
      >
        <div className={styles.modalContent}>
          <h2 className={styles.modalHeader}>Listing Activity</h2>
          <TableModal />
        </div>
      </Modal>
    </div>
  );
};

export default TableView;
