import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

const ApproveKindIcon = memo(({ className, variant, color }) => {
  const getIcon = useCallback(() => {
    switch (variant) {
      case ApproveKindIcon.SINGLE_FAMILY:
        return (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 25V47.3C12 47.6866 12.3134 48 12.7 48H43.3C43.6866 48 44 47.6866 44 47.3V25"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5 48V36.7C22.5 36.3134 22.8134 36 23.2 36H32.8C33.1866 36 33.5 36.3134 33.5 36.7V48"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.40039 27.75L27.5615 12.3523C27.8176 12.1466 28.1824 12.1466 28.4385 12.3523L47.6004 27.75"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M37 19.2222V12.7C37 12.3134 37.3134 12 37.7 12H43.3C43.6866 12 44 12.3134 44 12.7V25"
              stroke={color}
              strokeWidth="2.5"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ApproveKindIcon.TOWNHOME:
        return (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.0043 25V48M7 25V47.3C7 47.6866 7.3134 48 7.7 48H28.0043M49 25V47.3C49 47.6866 48.6866 48 48.3 48H28.0043"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 27L17.5004 14L28 24.5L38.5004 14L51 27"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 48V38.7C14 38.3134 14.3134 38 14.7 38L20.3 38C20.6866 38 21 38.3134 21 38.7V48"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M35 48V38.7C35 38.3134 35.3134 38 35.7 38L41.3 38C41.6866 38 42 38.3134 42 38.7V48"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.25 28.875C19.25 28.3918 19.6418 28 20.125 28H21.875C22.3582 28 22.75 28.3918 22.75 28.875V30.625C22.75 31.1082 22.3582 31.5 21.875 31.5H20.125C19.6418 31.5 19.25 31.1082 19.25 30.625V28.875Z"
              fill={color}
            />
            <path
              d="M12.25 28.875C12.25 28.3918 12.6418 28 13.125 28H14.875C15.3582 28 15.75 28.3918 15.75 28.875V30.625C15.75 31.1082 15.3582 31.5 14.875 31.5H13.125C12.6418 31.5 12.25 31.1082 12.25 30.625V28.875Z"
              fill={color}
            />
            <path
              d="M40.25 28.875C40.25 28.3918 40.6418 28 41.125 28H42.875C43.3582 28 43.75 28.3918 43.75 28.875V30.625C43.75 31.1082 43.3582 31.5 42.875 31.5H41.125C40.6418 31.5 40.25 31.1082 40.25 30.625V28.875Z"
              fill={color}
            />
            <path
              d="M33.25 28.875C33.25 28.3918 33.6418 28 34.125 28H35.875C36.3582 28 36.75 28.3918 36.75 28.875V30.625C36.75 31.1082 36.3582 31.5 35.875 31.5H34.125C33.6418 31.5 33.25 31.1082 33.25 30.625V28.875Z"
              fill={color}
            />
          </svg>
        );
      case ApproveKindIcon.CONDOMINIUM:
        return (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.2069 48V10.7C34.2069 10.3134 33.8935 10 33.5069 10L8.7 10C8.3134 10 8 10.3134 8 10.7V47.3C8 47.6866 8.3134 48 8.7 48H34.2069ZM34.2069 48H47.3C47.6866 48 48 47.6866 48 47.3V22.9827C48 22.6616 47.7815 22.3817 47.4701 22.3037L34.2069 18.9818"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 48V38H25.5V48"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 16.875C15 16.3918 15.3838 16 15.8571 16H17.5714C18.0448 16 18.4286 16.3918 18.4286 16.875V18.625C18.4286 19.1082 18.0448 19.5 17.5714 19.5H15.8571C15.3838 19.5 15 19.1082 15 18.625V16.875Z"
              fill={color}
            />
            <path
              d="M15 23.875C15 23.3918 15.3838 23 15.8571 23H17.5714C18.0448 23 18.4286 23.3918 18.4286 23.875V25.625C18.4286 26.1082 18.0448 26.5 17.5714 26.5H15.8571C15.3838 26.5 15 26.1082 15 25.625V23.875Z"
              fill={color}
            />
            <path
              d="M15 30.875C15 30.3918 15.3838 30 15.8571 30H17.5714C18.0448 30 18.4286 30.3918 18.4286 30.875V32.625C18.4286 33.1082 18.0448 33.5 17.5714 33.5H15.8571C15.3838 33.5 15 33.1082 15 32.625V30.875Z"
              fill={color}
            />
            <path
              d="M23.5714 16.875C23.5714 16.3918 23.9552 16 24.4286 16H26.1429C26.6162 16 27 16.3918 27 16.875V18.625C27 19.1082 26.6162 19.5 26.1429 19.5H24.4286C23.9552 19.5 23.5714 19.1082 23.5714 18.625V16.875Z"
              fill={color}
            />
            <path
              d="M23.5714 23.875C23.5714 23.3918 23.9552 23 24.4286 23H26.1429C26.6162 23 27 23.3918 27 23.875V25.625C27 26.1082 26.6162 26.5 26.1429 26.5H24.4286C23.9552 26.5 23.5714 26.1082 23.5714 25.625V23.875Z"
              fill={color}
            />
            <path
              d="M23.5714 30.875C23.5714 30.3918 23.9552 30 24.4286 30H26.1429C26.6162 30 27 30.3918 27 30.875V32.625C27 33.1082 26.6162 33.5 26.1429 33.5H24.4286C23.9552 33.5 23.5714 33.1082 23.5714 32.625V30.875Z"
              fill={color}
            />
            <path
              d="M39.5 26.875C39.5 26.3918 39.8918 26 40.375 26H42.125C42.6082 26 43 26.3918 43 26.875V28.625C43 29.1082 42.6082 29.5 42.125 29.5H40.375C39.8918 29.5 39.5 29.1082 39.5 28.625V26.875Z"
              fill={color}
            />
            <path
              d="M39.5 33.875C39.5 33.3918 39.8918 33 40.375 33H42.125C42.6082 33 43 33.3918 43 33.875V35.625C43 36.1082 42.6082 36.5 42.125 36.5H40.375C39.8918 36.5 39.5 36.1082 39.5 35.625V33.875Z"
              fill={color}
            />
            <path
              d="M39.5 40.875C39.5 40.3918 39.8918 40 40.375 40H42.125C42.6082 40 43 40.3918 43 40.875V42.625C43 43.1082 42.6082 43.5 42.125 43.5H40.375C39.8918 43.5 39.5 43.1082 39.5 42.625V40.875Z"
              fill={color}
            />
          </svg>
        );
      case ApproveKindIcon.MULTI_FAMILY:
        return (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35 17V12H41V17"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 26H9V17H46.9992V26H46M10 26V47.9992H46V26M10 26H46"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 47.4V39H24V47.4"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32 47.4V39H39V47.4"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.75 31.875C22.75 31.3918 23.1418 31 23.625 31H25.375C25.8582 31 26.25 31.3918 26.25 31.875V33.625C26.25 34.1082 25.8582 34.5 25.375 34.5H23.625C23.1418 34.5 22.75 34.1082 22.75 33.625V31.875Z"
              fill={color}
            />
            <path
              d="M36.75 31.875C36.75 31.3918 37.1418 31 37.625 31H39.375C39.8582 31 40.25 31.3918 40.25 31.875V33.625C40.25 34.1082 39.8582 34.5 39.375 34.5H37.625C37.1418 34.5 36.75 34.1082 36.75 33.625V31.875Z"
              fill={color}
            />
            <path
              d="M15.75 31.875C15.75 31.3918 16.1418 31 16.625 31H18.375C18.8582 31 19.25 31.3918 19.25 31.875V33.625C19.25 34.1082 18.8582 34.5 18.375 34.5H16.625C16.1418 34.5 15.75 34.1082 15.75 33.625V31.875Z"
              fill={color}
            />
            <path
              d="M29.75 31.875C29.75 31.3918 30.1418 31 30.625 31H32.375C32.8582 31 33.25 31.3918 33.25 31.875V33.625C33.25 34.1082 32.8582 34.5 32.375 34.5H30.625C30.1418 34.5 29.75 34.1082 29.75 33.625V31.875Z"
              fill={color}
            />
          </svg>
        );
      case ApproveKindIcon.NEW:
        return (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 25V47.3C12 47.6866 12.3134 48 12.7 48H43.3C43.6866 48 44 47.6866 44 47.3V25"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5 48V36.7C22.5 36.3134 22.8134 36 23.2 36H32.8C33.1866 36 33.5 36.3134 33.5 36.7V48"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.40039 27.75L27.5615 12.3523C27.8176 12.1466 28.1824 12.1466 28.4385 12.3523L47.6004 27.75"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M45.1612 1.80469C43.6558 7.82619 42.1504 9.33157 36.1289 10.8369C42.1504 12.3423 43.6558 13.8477 45.1612 19.8692C46.6665 13.8477 48.1719 12.3423 54.1934 10.8369C48.1719 9.33157 46.6665 7.82619 45.1612 1.80469Z"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.03245 5.41797C7.58729 10.476 6.8647 11.1986 1.80664 12.6438C6.8647 14.0889 7.58729 14.8115 9.03245 19.8696C10.4776 14.8115 11.2002 14.0889 16.2583 12.6438C11.2002 11.1986 10.4776 10.476 9.03245 5.41797Z"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ApproveKindIcon.LAND:
        return (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33.1248 37.7385C34.2733 38.234 35.5433 38.5092 36.8788 38.5092C42.0272 38.5092 46.2008 34.4207 46.2008 29.3773C46.2008 26.8626 45.1632 24.5853 43.4844 22.9339C43.5711 22.4856 43.6164 22.023 43.6164 21.5501C43.6164 17.4674 40.2378 14.1577 36.0701 14.1577C35.2571 14.1577 34.4741 14.2836 33.7404 14.5166C31.9633 12.1428 29.0954 10.6016 25.86 10.6016C21.3103 10.6016 17.4873 13.6493 16.4024 17.7744C12.4996 19.5877 9.80078 23.4828 9.80078 27.9955C9.80078 34.2397 14.9681 39.3016 21.3423 39.3016C22.3719 39.3016 23.3701 39.1695 24.32 38.9217"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29 31L24 27M29 31L32 28M29 31V35M29 48V35M29 35L25.5 32.5"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      default:
        return null;
    }
  }, [variant, color]);

  return <div className={className}>{getIcon()}</div>;
});

ApproveKindIcon.SINGLE_FAMILY = 'Single';
ApproveKindIcon.TOWNHOME = 'Townhouse';
ApproveKindIcon.CONDOMINIUM = 'Condo';
ApproveKindIcon.MULTI_FAMILY = 'MultiFamily';
ApproveKindIcon.NEW = 'NewConstruction';
ApproveKindIcon.LAND = 'Land';

ApproveKindIcon.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

ApproveKindIcon.defaultProps = {
  className: '',
  variant: undefined,
  color: '#666666',
};

export default ApproveKindIcon;
