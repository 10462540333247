import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ activityLogsWorkshop }) => activityLogsWorkshop;

export const getActivityLogsWorkshopSelector = createSelector(localState, ({ state, data }) => ({
  activities: data,
  isIdle: state === IDLE,
  isPending: state === PENDING,
}));
