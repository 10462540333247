import { apiServer } from 'settings/web-services/api';

export function getTransactionDocumentsFilter(cfg) {
  const { transactionId, ...config } = cfg;
  return apiServer.get(`/transaction/${transactionId}/documents`, { params: config });
}

export function getTransactionDocument(cfg) {
  return apiServer.get(`/transaction/${cfg?.transactionId}/documents/${cfg?.documentId}`);
}

export function getTransactionDocumentCategories(cfg) {
  return apiServer.get(`/transaction/${cfg?.transactionId}/document-categories`);
}
