import { Modal } from 'antd';
import { ModalClose } from 'components/Icons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { cancelSubscriptionEffect, reactivateSubscriptionEffect } from 'store/effects/subscription';
import styles from '../DowngradeModal/styles.module.scss';
import { StepFive } from './StepFive';
import { StepFour } from './StepFour';
import { StepOne } from './StepOne';
import { StepThree } from './StepThree';
import { StepTwo } from './StepTwo';

export const reasonsList = [
  { title: 'This is temporary, I plan to resume my subscription later' },
  { title: 'I am transitioning out of real estate or no longer have a business need' },
  {
    title: "Didn't have features or integrations I needed",
    additional: 'What features or integrations do you need?',
  },
  { title: 'The price is too high' },
  { title: "I didn't really understand the benefits of using Mosaik" },
  {
    title: 'I am switching to a different platform',
    additional: 'Which platform(s) are you switching to?',
  },
  { title: 'The user experience was difficult or confusing' },
  { title: 'The platform is unreliable or has poor performance' },
  { title: 'Another reason', additional: true },
];

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  fetchDataInBackground: (cb: any) => Promise<void>;
};

export const CancelSubscriptionModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  fetchDataInBackground,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [selectedReason, setSelectedReason] = useState<number>(-1);
  const [additionalInput, setAdditionalInput] = useState<string>('');
  const dispatch = useDispatch();
  const [isLoadingReactive, setIsLoadingReactive] = useState<boolean>(false);
  const handleNextStep = () => {
    if (currentStep === 5) {
      setIsLoadingReactive(true);
      dispatch(
        reactivateSubscriptionEffect({}, (err) => {
          if (!err) {
            closeModal();
          }
        }),
      );
      setIsLoadingReactive(true);
    } else setCurrentStep((prev) => (prev === 2 ? prev + 2 : prev + 1));
  };

  const handleClose = () => {
    if (currentStep === 3) {
      setCurrentStep(2);
      return;
    }
    closeModal();
    setCurrentStep(1);
    setSelectedReason(-1);
    setAdditionalInput('');
  };
  const cancelSubscription = (cb) => {
    if (selectedReason < 0) return;
    dispatch(
      cancelSubscriptionEffect({ CancellationReason: reasonsList[selectedReason].title }, () => {
        // dispatch(userGetDataOnBackgroundEffect({}, { showError: false }, cb));
        fetchDataInBackground(cb);
      }),
    );
  };
  return (
    <Modal
      open={isOpen}
      centered
      maskStyle={{
        background: 'rgba(255, 255, 255, 0.60)',
        backdropFilter: ' blur(12.5px)',
      }}
      bodyStyle={{
        borderRadius: '24px',
        background: 'var(--REBRAND-Secondary-White, #FFF)',
        boxShadow: '0px 24px 80px 0px rgba(0, 0, 0, 0.20)',
      }}
      wrapClassName={styles.wrapper}
      closable
      footer={null}
      className={styles.modalWrapper}
      onCancel={handleClose}
      destroyOnClose
      closeIcon={<ModalClose />}
    >
      {currentStep === 1 && <StepOne handleNextStep={handleNextStep} closeModal={closeModal} />}
      {currentStep === 2 && (
        <StepTwo
          setCurrentStep={setCurrentStep}
          handleNextStep={handleNextStep}
          selectedReason={selectedReason}
          additionalInput={additionalInput}
          setAdditionalInput={setAdditionalInput}
          closeModal={closeModal}
        />
      )}
      {currentStep === 3 && (
        <StepThree
          setCurrentStep={setCurrentStep}
          setSelectedReason={setSelectedReason}
          selectedReason={selectedReason}
        />
      )}
      {currentStep === 4 && (
        <StepFour
          handleNextStep={handleNextStep}
          cancelSubscription={cancelSubscription}
          closeModal={closeModal}
        />
      )}
      {currentStep === 5 && (
        <StepFive handleNextStep={handleNextStep} isLoading={isLoadingReactive} />
      )}
    </Modal>
  );
};
