import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { requestGetMatchedClientsWithScoresByMlsEffect } from 'store/effects/mlsScores';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper as PendingWrapper } from 'components';
import Icon from 'pages/Properties/Feed/Icons';

import { getMlsScoreByIdWithStatus } from 'store/selectors/mlsScores';
import styles from './styles.module.scss';

const Applicants = ({ className, applicantsClassName, propertyInfo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { isData, buyers } =
    useSelector(
      getMlsScoreByIdWithStatus(propertyInfo?.Id ? propertyInfo?.Id : propertyInfo?.id),
    ) || {};

  const isHint = buyers?.length && !isLoading ? { 'data-rh': true } : {};

  const onGetData = () => {
    if (!isData) {
      setIsLoading(true);
      dispatch(
        requestGetMatchedClientsWithScoresByMlsEffect(
          { ids: [propertyInfo?.Id ? propertyInfo?.Id : propertyInfo?.id] },
          {},
          () => {
            setIsLoading(false);
          },
        ),
      );
    }
  };

  return (
    <div
      {...isHint}
      data-buyers={JSON.stringify(buyers)}
      onMouseEnter={onGetData}
      className={classNames(styles.applicantsWrapper, className)}
      onClick={(e) => e.stopPropagation()}
    >
      <div testid="applicants" className={classNames(styles.applicants, applicantsClassName)}>
        <PendingWrapper
          className={styles.pendingWrapper}
          isPending={isLoading}
          loaderClassName={styles.loader}
        >
          <Icon variant={Icon.APPLICANTS} className={styles.icon} />
          <span className={styles.count}>{propertyInfo?.MatchedClientsCount || 0}</span>
        </PendingWrapper>
      </div>
    </div>
  );
};

Applicants.propTypes = {
  className: PropTypes.string,
  applicantsClassName: PropTypes.string,
  propertyInfo: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    MatchedClientsCount: PropTypes.number,
  }).isRequired,
};

Applicants.defaultProps = {
  className: '',
  applicantsClassName: '',
};

export default Applicants;
