import React from 'react';
import { ConfirmationWithReasonModal } from 'components-antd';

export const ConfirmationModal = ({
  showConfirmationModal,
  setReason,
  iterationId,
  status,
  reason,
  updateOfferIterationStatus,
  setConfirmationModal,
  confirmText,
  okText,
}) => {
  return (
    <ConfirmationWithReasonModal
      open={showConfirmationModal}
      confirmText={confirmText}
      placeholder={`Add a note......`}
      okText={okText}
      note="Note"
      showReason={true}
      setReason={setReason}
      onOk={() => {
        updateOfferIterationStatus({
          OfferIterationId: iterationId,
          OfferStatus: status,
          Reason: reason,
        });
        setConfirmationModal(false);
      }}
      onCancel={() => setConfirmationModal(false)}
    />
  );
};
