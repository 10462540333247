import React from 'react';
import { Input } from 'components';
import { useFormik } from 'formik';
import { FormFooter, FormHeader, FormContainer } from 'pages/OnboardingV2Agent/components';
import * as Yup from 'yup';

import styles from '../PersonalDetails/styles.module.scss';
import { Values } from 'pages/SignupV2/SignupV2';

enum FormFields {
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
}

type FormValues = {
  [key in FormFields]: string;
};

const schema = Yup.object().shape({
  [FormFields.PASSWORD]: Yup.string().trim().required('Password is required'),
  [FormFields.CONFIRM_PASSWORD]: Yup.string().oneOf(
    [Yup.ref(FormFields.PASSWORD), null],
    'Passwords must match',
  ),
});

const initialValues = {
  [FormFields.PASSWORD]: '',
  [FormFields.CONFIRM_PASSWORD]: '',
} as FormValues;

type Props = {
  onSubmit: (values: Partial<Values>) => void;
  isSubmitting: boolean;
};

export const CreatePassword: React.FC<Props> = ({ onSubmit: handleUserCreate, isSubmitting }) => {
  const onSubmit = (values: FormValues) => {
    handleUserCreate(values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit,
  });
  return (
    <FormContainer>
      <div className={styles.formContainer}>
        <FormHeader title="Please create a password." />
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <div className={styles.inputContainer}>
            <Input
              type="password"
              name={FormFields.PASSWORD}
              value={formik.values[FormFields.PASSWORD]}
              onChange={formik.handleChange}
              className={styles.input}
              error={formik.touched[FormFields.PASSWORD] ? formik.errors[FormFields.PASSWORD] : ''}
              errorClassName={styles.error}
              disabled={isSubmitting}
              variant={Input.LIGHT_ROUND}
              label={'Set a Password'}
            />
          </div>
          <div className={styles.inputContainer}>
            <Input
              type="password"
              name={FormFields.CONFIRM_PASSWORD}
              value={formik.values[FormFields.CONFIRM_PASSWORD]}
              onChange={formik.handleChange}
              className={styles.input}
              error={
                formik.touched[FormFields.CONFIRM_PASSWORD]
                  ? formik.errors[FormFields.CONFIRM_PASSWORD]
                  : ''
              }
              errorClassName={styles.error}
              disabled={isSubmitting}
              variant={Input.LIGHT_ROUND}
              label={'Confirm Password'}
            />
          </div>
          <FormFooter
            isPending={isSubmitting}
            text={
              <>
                By creating an account, you accept our{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.mosaik.io/terms-of-service"
                  className={styles.link}
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.mosaik.io/privacy-policy"
                  className={styles.link}
                >
                  Privacy Policy
                </a>
                .
              </>
            }
          />
        </form>
      </div>
    </FormContainer>
  );
};
