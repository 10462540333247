import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components-antd';
import styles from './styles.module.scss';
import { tableColumns } from './Table/DocumentsTableColumns';
import { ModalTable } from 'pages/Main/ClientDashboard/components/ModalTable';
import { useHistory } from 'react-router-dom';
import { dispatch } from 'store';
import { previewDocumentEffect } from 'store/effects';
import { showErrorMessage } from 'helpers';
import { PdfViewModal } from 'components';

const DocumentsModal = (props) => {
  const { className, isOpen, onCloseModal, documentsDetails, title = 'New Documents' } = props;
  const history = useHistory();

  const [document, setDocument] = useState(null);

  const [loadingPreview, setLoadingPreview] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const onPreview = (DocumentVaultUUID, Title) => {
    setLoadingPreview(true);
    dispatch(
      previewDocumentEffect({ DocumentVaultUUID }, {}, (err, response) => {
        setLoadingPreview(false);
        if (err) {
          return showErrorMessage(err);
        }
        setIsPreviewOpen(true);
        setDocument({
          DocumentBuffer: response,
          Filename: Title || response.headers['file-name'],
        });
      }),
    );
  };
  const tableProps = {
    columns: tableColumns((DocumentVaultUUID, Title) => onPreview(DocumentVaultUUID, Title)),
    onRow: (record) => {
      return {
        onClick: () => {},
      };
    },
  };
  return (
    <>
      {isPreviewOpen && (
        <PdfViewModal
          isOpen={isPreviewOpen}
          file={document}
          onClose={() => setIsPreviewOpen(false)}
        />
      )}
      <Modal
        open={isOpen}
        width={1200}
        footer={null}
        onCancel={() => {
          onCloseModal();
        }}
        maskClosable={false}
        destroyOnClose
        className={styles.taskViewModal}
      >
        <div testid="documents_modal" className={styles.formModal}>
          <p className={styles.title}>{title}</p>
          <ModalTable dataSource={documentsDetails} loading={loadingPreview} {...tableProps} />
        </div>
      </Modal>
    </>
  );
};

DocumentsModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  documentsDetails: PropTypes.any,
};

DocumentsModal.defaultProps = {
  className: '',
  isOpen: false,
  onCloseModal: () => {},
};

export default DocumentsModal;
