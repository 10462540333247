import { useState, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import PropTypes from 'prop-types';
import { Modal } from 'components-antd';
import styles from './styles.module.scss';
import { ModalTable } from 'pages/Main/ClientDashboard/components/ModalTable';
import { tableColumns } from './tableColumns';
import { getUserId } from 'store/selectors/user';
import { getClientDashboardNeedsAttionEffect } from 'store/effects/clientDashboard';

const OfferDetailsModal = lazy(() =>
  import('pages/Workshop/Offers/components/OfferDetailsModal/OfferDetailsModal'),
);

const OffersModal = (props) => {
  const { className, isOpen, onCloseModal, offersDetails, title = 'Offers' } = props;
  const userId = useSelector(getUserId);
  const [offerDetailsModal, setOfferDetailsModal] = useState(undefined);
  const [transactionId, setTransactionId] = useState(0);
  const onClickOffer = (data) => {
    const offer = data?.OfferIteration?.[0];
    setTransactionId(data?.PropertyTransactionId);
    const tempOffer = {
      ...offer,
      OfferId: data?.Id,
      TransactionId: data?.PropertyTransactionId,
    };
    setOfferDetailsModal(tempOffer);
  };

  const onCloseOffer = () => {
    setOfferDetailsModal(undefined);
    dispatch(
      getClientDashboardNeedsAttionEffect(
        {},
        {
          silent: true,
        },
      ),
    );
  };

  const tableProps = {
    columns: tableColumns((record) => onClickOffer(record)),
    onRow: (record) => {
      return {
        onClick: () => {},
      };
    },
    tableClassName: styles.taskTable,
  };
  return (
    <>
      <Modal
        open={isOpen}
        width={1200}
        footer={null}
        onCancel={() => {
          onCloseModal();
        }}
        maskClosable={false}
        destroyOnClose
        className={styles.taskViewModal}
      >
        <div testid="offers_modal" className={styles.formModal}>
          <p className={styles.title}>{title}</p>
          <ModalTable dataSource={offersDetails} {...tableProps} />
        </div>
      </Modal>
      <Suspense fallback={<div>Loading...</div>}>
        <OfferDetailsModal
          isOpen={!!offerDetailsModal}
          onClose={onCloseOffer}
          offerData={offerDetailsModal}
          transactionId={transactionId}
          setOfferCompareModalIsOpen={false}
          isActionDisabled={true}
          isActivityDisabled={true}
        />
      </Suspense>
    </>
  );
};

OffersModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  offersDetails: PropTypes.any,
};

OffersModal.defaultProps = {
  className: '',
  isOpen: false,
  onCloseModal: () => {},
};

export default OffersModal;
