import { Collapse, Avatar, Panel } from 'components-antd';
import { Remove, Add } from 'components/Icons';
import { TimelineItem } from '../../components/TimelineItem';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { TourSort, TourStatus } from 'app-constants/enums/tours';
import { fetchTours } from '../../helper';
import { NoTimeline } from '../../components/NoTimeline';
import { PanelHeader } from '../../components/PanelHeader';
import EmptyToursCard from '../EmptyToursCard';

export const Cancelled = ({ selectedSort, selectedTeamFilter }) => {
  const { tours: groupedTours } = useSelector(
    fetchTours(selectedSort, TourStatus.Canceled, selectedTeamFilter),
  );

  return (
    <>
      {!Object.keys(groupedTours).length ? <EmptyToursCard text={'cancelled tours'} /> : <></>}
      {Object.keys(groupedTours)
        .sort()
        .reverse()
        .map((key, i) => (
          <Collapse
            bordered={false}
            accordion
            expandIcon={({ isActive }) =>
              isActive ? <Remove color={Remove.COLLAPSE} /> : <Add color={Add.COLLAPSE} />
            }
            expandIconPosition={'end'}
            className={styles.tourCards}
            ghost={true}
            defaultActiveKey={i}
            key={i}
          >
            <Panel
              header={
                <PanelHeader
                  sort={selectedSort}
                  groupedKey={key}
                  tours={groupedTours[key]}
                ></PanelHeader>
              }
              className={styles.tourCard}
              key={i}
            >
              {groupedTours[key].map((tour, i) => {
                return selectedSort === TourSort.Date ? (
                  <TimelineItem
                    sort={TourSort.Date}
                    tour={tour}
                    infoWrapClassName={styles.cancelledInfo}
                    key={i}
                  >
                    <p className={styles.cancelled}>Cancelled</p>
                  </TimelineItem>
                ) : (
                  <NoTimeline tour={tour} sort={selectedSort} key={i}>
                    <p className={styles.cancelled}>Cancelled</p>
                  </NoTimeline>
                );
              })}
            </Panel>
          </Collapse>
        ))}
    </>
  );
};
