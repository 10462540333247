export const CLIENT = 'Client';
export const AGENT = 'Agent';
export const THIRD_PARTY = 'ThirdParty';
export const BROKERAGE_ADMIN = 'BrokerageAdmin';
export const SUPER_USER = 'SuperUser';
export const TEAM_OWNER = 'Owner';
export const TEAM_ADMIN = 'Admin';
export const TEAM_BASIC = 'Basic';

export const Role = {
  Client: CLIENT,
  Agent: AGENT,
  ThirdParty: THIRD_PARTY,
  BrokerageAdmin: BROKERAGE_ADMIN,
  SuperUser: SUPER_USER,
};

export const signUpRouteRoles = {
  CLIENT: 'client',
  AGENT: 'agent',
  THIRD_PARTY: 'thirdParty',
};

export const transactionRoles = {
  BUYER: 'Buyer',
  SELLER: 'Seller',
};

export const availableRouteRoles = [
  signUpRouteRoles.CLIENT,
  signUpRouteRoles.AGENT,
  signUpRouteRoles.THIRD_PARTY,
];

export const availableWizardRoles = [signUpRouteRoles.CLIENT];

export const mapRouteRoles = {
  [signUpRouteRoles.CLIENT]: CLIENT,
  [signUpRouteRoles.AGENT]: AGENT,
  [signUpRouteRoles.THIRD_PARTY]: THIRD_PARTY,
};

export const mapRoles = {
  [CLIENT]: signUpRouteRoles.CLIENT,
  [AGENT]: signUpRouteRoles.AGENT,
  [THIRD_PARTY]: signUpRouteRoles.THIRD_PARTY,
};

export const transactionsFilters = {
  myTransactions: 'My Transactions',
  teamTransactions: 'Team Transactions',
  externalTransactions: 'External Transactions',
  allTransactions: 'All Transactions',
};

export const projectsFilters = {
  myProjects: 'My Projects',
  teamProjects: 'Team Projects',
  externalProjects: "Projects I'm invited to",
  allProjects: 'All Projects',
};

export const formsFilters = {
  myForms: 'My Forms',
  teamForms: 'Team Forms',
  externalForms: 'External Forms',
  allForms: 'All Forms',
};

export const isRoleExistForSignUp = (routeParamRole) =>
  availableRouteRoles.includes(routeParamRole);
export const isAvailableWizardRole = (role) => availableWizardRoles.includes(role);
