import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { addressToSentenceCase } from 'helpers';

import { getPropertyAddressWithHash } from 'helpers';

import styles from './styles.module.scss';

const AddressComponent = (props) => {
  const { propertyInfo, className } = props;

  const { Address } = propertyInfo;
  if (Address.Line1) Address.Line1 = addressToSentenceCase(Address.Line1);
  if (Address.City) Address.City = addressToSentenceCase(Address.City);
  const { Line1, Line2 } = getPropertyAddressWithHash({ address: Address });

  return (
    <div className={classNames(styles.address, className)}>
      <div testid="property_address" className={styles.line1}>
        {Line1}
      </div>
      <div testid="property_location" className={styles.cityState}>
        <span>{Line2}</span>
      </div>
    </div>
  );
};

AddressComponent.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    Address: PropTypes.shape({
      Line1: PropTypes.string,
      City: PropTypes.string,
      State: PropTypes.string,
    }),
  }).isRequired,
};

AddressComponent.defaultProps = {
  className: '',
};

export default AddressComponent;
