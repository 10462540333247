import Api from 'store/effects/core/api';
import { get } from 'lodash-es';
import { getTransactionRoleById, getTransactionRoles } from 'api/transactions';
import {
  requestDeleteTransactionRoleDashboardAction,
  requestGetTransactionRoleDashboardAction,
  requestGetTransactionRolesDashboardAction,
  requestPostTransactionRoleDashboardAction,
  requestPutActivateTransactionRoleDashboardAction,
  requestPutTransactionRoleDashboardAction,
  searchTransactionRolesDashboardAction,
} from 'store/actions/adminPanel';
import {
  deleteDeactivateTransactionRole,
  postTransactionRoleData,
  putActivateTransactionRole,
  putTransactionRoleData,
} from 'api/admin/transactionRoles';
import { getState } from 'store/index';

export const getAllTransactionRolesDashboardEffect = (cfg, options, cb) => {
  const {
    adminPanel: {
      transactionRoles: { filters },
    },
  } = getState();
  const requestParams = {
    action: requestGetTransactionRolesDashboardAction,
    method: getTransactionRoles,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(
    { ...(cfg || {}), searchString: filters?.searchString || undefined },
    options,
    cb,
  );
};

export const getTransactionRoleDashboardEffect = ({ id }, options, cb) => {
  const requestParams = {
    action: requestGetTransactionRoleDashboardAction,
    method: getTransactionRoleById,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest({ id }, options, cb);
};

export const deactivateTransactionRoleEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestDeleteTransactionRoleDashboardAction,
    method: deleteDeactivateTransactionRole,
  });

  return sendRequest(cfg, options, (err, response, dispatch) => cb(err, response, dispatch));
};

export const activateTransactionRoleEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestPutActivateTransactionRoleDashboardAction,
    method: putActivateTransactionRole,
  });

  return sendRequest(cfg, options, (err, response, dispatch) => cb(err, response, dispatch));
};

const getTransactionRoleSendData = (values) => ({
  Title: get(values, 'Title'),
});

export const updateTransactionRoleEffect = ({ values, id }, cb) => {
  const sendRequest = Api.execBase({
    action: requestPutTransactionRoleDashboardAction,
    method: putTransactionRoleData,
  });
  const sendValues = getTransactionRoleSendData(values, true);

  return sendRequest(sendValues, { id }, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllTransactionRolesDashboardEffect());
    }
    cb(err, response, dispatch);
  });
};

export const createTransactionRoleEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestPostTransactionRoleDashboardAction,
    method: postTransactionRoleData,
  });
  const sendValues = getTransactionRoleSendData(cfg);

  return sendRequest(sendValues, {}, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllTransactionRolesDashboardEffect());
    }
    cb(err, response, dispatch);
  });
};

export const searchTransactionRolesDashboardEffect = (cfg) => (dispatch) => {
  dispatch(searchTransactionRolesDashboardAction(cfg));
  return dispatch(getAllTransactionRolesDashboardEffect());
};
