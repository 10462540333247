import { PDFDocument, PDFTextField } from 'pdf-lib';

const dummySmartFormValues = {
  'CharacterName 2': 'Mario',
  Age: '18',
  Height: '1.5 m',
  Weight: '16kg',
};

export const useDummyFormFiller = (pdfDoc: PDFDocument | null) => {
  const fillForm = () => {
    if (!pdfDoc) return;

    const form = pdfDoc.getForm();

    Object.entries(dummySmartFormValues).forEach(([fieldName, value]) => {
      const field = form.getField(fieldName);
      if (field instanceof PDFTextField) {
        field.setText(value);
      }
    });
  };

  return { fillForm };
};
