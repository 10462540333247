import PropTypes from 'prop-types';

import { Document } from './Document';

import styles from './styles.module.scss';

export const Documents = ({ offerIteration, offerData }) => {
  const { OfferIterationDocument: documents } = offerIteration || {};
  return (
    <div className={styles.documents}>
      {documents?.length ? (
        <>
          {documents.map((d) => (
            <Document
              key={d.Id}
              document={d}
              offerIteration={offerIteration}
              offerData={offerData}
            />
          ))}
        </>
      ) : (
        <div className={styles.disclaimerContainer}>
          <p className={styles.disclaimer}>No Files Uploaded</p>
        </div>
      )}
    </div>
  );
};

Documents.propTypes = {
  data: PropTypes.object,
};

Documents.defaultProps = {
  data: {},
};
