import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ListingHome, Offer, ArrowRight } from 'components/Icons';
import { OfferListingMessage } from 'components';

import styles from './styles.module.scss';

const ListingMessages = ({ withOffer, unreadNumber, threads, name, ...rest }) =>
  threads.length === 1 ? (
    <OfferListingMessage thread={threads[0]} {...rest} />
  ) : (
    <div
      className={classNames(styles.item, {
        [styles.offer]: withOffer,
        [styles.unread]: !!unreadNumber,
      })}
      {...rest}
    >
      <div className={styles.itemImage}>
        {withOffer ? (
          <Offer className={styles.itemImageIcon} />
        ) : (
          <ListingHome className={styles.itemImageIcon} />
        )}
      </div>
      <div className={styles.itemContent}>
        <div className={styles.itemContentTitle}>
          <div className={styles.itemContentTitleName}>
            <span>{name || 'No name thread'}</span>
            {withOffer && <span className={styles.offerTitleSpan}>Offer</span>}
          </div>
        </div>
        <div className={styles.itemContentText}>
          <span>{`${threads.length} conversations`}</span>
          {!!unreadNumber && (
            <>
              <span>, </span>
              <span className={styles.itemContentTextUnread}>{`${unreadNumber} unread`}</span>
            </>
          )}
        </div>
      </div>
      <div className={styles.itemRight}>
        <ArrowRight className={styles.itemRightIcon} />
      </div>
    </div>
  );

ListingMessages.propTypes = {
  name: PropTypes.string,
  withOffer: PropTypes.bool,
  threads: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  unreadNumber: PropTypes.number.isRequired,
};

ListingMessages.defaultProps = {
  withOffer: false,
  name: null,
};

export default ListingMessages;
