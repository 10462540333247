import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { PENDING } from 'settings/constants/apiState';
import { Button, Select } from 'components';
import Input from 'components/Form/Input';
import { SearchIcon } from 'components/Icons';
import { getCategoriesEffect, searchCategoriesEffect } from 'store/effects/adminPanel';
import { getCategoriesSearchSelector } from 'store/selectors/adminPanel';

import { emptyValue, typesOptions } from '../config';
import styles from './styles.module.scss';

const Filters = () => {
  const dispatch = useDispatch();
  const { state } = useSelector(getCategoriesEffect);
  const search = useSelector(getCategoriesSearchSelector);
  const isPending = state === PENDING;
  const onSubmit = useCallback((values) => dispatch(searchCategoriesEffect(values)), [dispatch]);

  const formik = useFormik({
    initialValues: search,
    enableReinitialize: true,
    onSubmit,
  });

  const onTypeChange = useCallback((e, { value }) => formik.setFieldValue('type', value), [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.filtersFormWrapper}>
        <div className={styles.filtersWrapper}>
          <div className={styles.filterWrapper}>
            <Input
              id="searchString"
              className={styles.input}
              type="text"
              name="searchString"
              placeholder="Search"
              error={formik.touched.searchString ? formik.errors.searchString : ''}
              value={formik.values.searchString}
              onChange={formik.handleChange}
              disabled={isPending}
              variant={Input.LIGHT_FULL}
              testid="search_input"
            />
          </div>
          <div className={styles.filterWrapper}>
            <Select
              id="type"
              name="type"
              options={typesOptions}
              value={formik.values.type}
              placeholder="Type"
              onSelect={onTypeChange}
              disabled={isPending}
              className={{ wrapper: styles.selectWrapper }}
              error={formik.touched.type ? formik.errors.type : ''}
              variant={Select.LIGHT_FULL}
              defaultValue={emptyValue}
              allowClear
              testid="type_select"
            />
          </div>
        </div>
        {formik.dirty ? (
          <Button
            testid="search_button"
            type="submit"
            className={styles.searchButton}
            isPending={isPending}
            title={<SearchIcon />}
          />
        ) : (
          <Button
            testid="search_button"
            type="button"
            className={styles.searchButton}
            title={<SearchIcon />}
          />
        )}
      </div>
    </form>
  );
};

Filters.propTypes = {};
Filters.defaultProps = {};

export default Filters;
