import React, { useEffect } from 'react';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { useSelector, useDispatch } from 'react-redux';
import SavedSearchesBlock from './components/SavedSearchesBlock';
import { getSearchInstancesEffect } from 'store/effects/mySearches/index';
import { getSearchInstancesFormattedSelector } from 'store/selectors/mySearches';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getSavedSearchesEffect } from 'store/effects/search';
import { getSavedSearchesFormattedSelector } from 'store/selectors/mySearches';
import { LocalHeader } from './LocalHeader';
import { PreApprovalsModal } from './components/PreApprovalsModal';

import styles from './styles.module.scss';

const MySearches = () => {
  const dispatch = useDispatch();
  const { isClient, isAgent, isThirdParty } = useSelector(getUserRolesMapSelector);
  const { data, isLoading } = useSelector(getSearchInstancesFormattedSelector);
  const { formattedData, isLoading: isSavedSearchLoading } = useSelector(
    getSavedSearchesFormattedSelector,
  );
  const searchContext = useSelector(getCurrentContextSelector);
  const cfg = { contextKey: searchContext?.ContextKey };

  const filterByStatus = (data, status) => {
    return data?.filter((item) => item.status === status);
  };

  useEffect(() => {
    if (isClient && searchContext) dispatch(getSearchInstancesEffect(cfg));
  }, [searchContext]);

  useEffect(() => {
    if (isAgent) dispatch(getSavedSearchesEffect());
  }, [isAgent]);

  return (
    <div className={styles.MySearches}>
      <div className={styles.activeSearch}>
        {/* OLD LOGIC COMMENTED */}
        {/* {isAgent && <SearchAgentsBlock />} */}
        {/* {isThirdParty && <SearchThirdPartyBlock />} */}
        {(isAgent || isThirdParty) && (
          <>
            <LocalHeader />
            <SavedSearchesBlock
              activeSearches={filterByStatus(formattedData, 'Active')}
              inactiveSearches={filterByStatus(formattedData, 'Inactive')}
              isLoading={isSavedSearchLoading}
            />
          </>
        )}
        {isClient && (
          <>
            <LocalHeader isClient={true} />
            <SavedSearchesBlock
              activeSearches={filterByStatus(data, 'Active')}
              inactiveSearches={filterByStatus(data, 'Inactive')}
              isLoading={isLoading}
            />
            <PreApprovalsModal />
          </>
        )}
      </div>
    </div>
  );
};

export default MySearches;
