import { createSelector } from 'reselect';
import {
  getIsParticipantsDrawerOpenSelector,
  getParticipantsDrawerInfoSelector,
  getCurrentParticipants,
  getParticipantsDrawerChatTypeSelector,
} from 'store/selectors/drawers/addParticipants';
import { getCurrentMessagesThreadId } from 'store/selectors/sockets/messages';
import { filter, flow, map } from 'lodash-es';
import { getUserId } from 'store/selectors/user';

export const getAddParticipantsDrawerInfoSelector = createSelector(
  getIsParticipantsDrawerOpenSelector,
  getParticipantsDrawerInfoSelector,
  getParticipantsDrawerChatTypeSelector,
  getCurrentParticipants,
  getCurrentMessagesThreadId,
  getUserId,
  (drawerOpened, info, chatType, { participants }, threadId, userId) => ({
    drawerOpened,
    info,
    chatType,
    threadId,
    participants: flow(
      (parts) =>
        map(parts, (participant) => ({
          id: participant?.Id,
          name: `${participant?.FirstName} ${participant?.LastName}`,
          value: participant?.Id,
          role: participant?.Roles?.[0],
          avatarUrl: participant?.AvatarUrl,
          firstName: participant?.FirstName,
          lastName: participant?.LastName,
        })),
      (parts) => filter(parts, (participant) => participant.id !== userId),
    )(participants),
  }),
);
