export const offersStatus = {
  PENDING: 'Pending',
  PAST: 'Past',
  CANCELED: 'Cancelled',
  DECLINED: 'Declined',
  LISTED: 'Listed',
  COUNTER_SENT: 'Counter Sent',
  COUNTER_RECEIVED: 'Counter Received',
  WITHDRAWN: 'Withdrawn',
  REJECTED: 'Rejected',
};

export const announcementType = {
  PUBLIC: 'Offer_Public_Message',
  ITERATION: 'Offer_Private_Message',
};

export const offerFilters = {
  myOffers: 'My Offers',
  teamOffers: 'Team Offers',
  allOffers: 'All Offers',
};
