/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useCallback, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { groupBy } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';
import { openChatThreadHandler } from 'components/Drawers/MessagesDrawer/helpers/threadActions';
import { groupByOwnerMessages, groupByDate } from 'helpers';
import { getUserId } from 'store/selectors/user';
import { sendMarkThreadAsReadEffect, sendMarkMsgAsReadEffect } from 'store/effects/sockets/threads';
import { getMessagesDrawerParams } from 'store/selectors/drawers/messages';
import { Wrapper as PendingWrapper } from 'components';
import moment from 'moment';
import Message from './Message';
import ArchivedLabel from './ArchivedLabel';

import styles from './styles.module.scss';
import { resetMessagesEffect } from 'store/effects/sockets/messages';
import { getSharePropertySelector } from 'store/selectors/feed';

const MessagesView = (props) => {
  const {
    className,
    list,
    threadId,
    currentEditMessage,
    onEditMessage,
    onDeleteMessage,
    currentThread,
  } = props;
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();
  const containerRef = useRef();
  const drawerParams = useSelector(getMessagesDrawerParams);
  const messageRef = useRef({});
  const scrollToBottom = useCallback(() => {
    if (containerRef && containerRef.current) {
      containerRef.current.scroll({
        top: containerRef.current.scrollHeight,
      });
    }
  }, [containerRef]);
  const { params } = useSelector((state) => state.drawers?.comments);
  const shareProperty = useSelector(getSharePropertySelector);

  useEffect(() => {
    scrollToBottom();
  }, [list?.length, containerRef?.scrollHeight]); // eslint-disable-line
  useEffect(() => {
    if (list === null) return;
    if (drawerParams?.searchedMessageId) {
      const node = messageRef?.current[drawerParams.searchedMessageId];
      if (node) {
        node.scrollIntoView();
        // removed the searchedMessageId to stop working it on every list change
        openChatThreadHandler(dispatch, {
          threadId: threadId,
        });
      }
    }
  }, [list]);

  useEffect(() => {
    if (params?.onlyReadMsg && list) {
      dispatch(
        sendMarkMsgAsReadEffect({
          threadId,
          msgIds: (list || [])
            .filter((msg) => {
              if (Array.isArray(msg.MessageMeta)) {
                return msg.MessageMeta.some((meta) => meta.EntityId === shareProperty.Id);
              }
              return msg.MessageMeta?.EntityId === shareProperty.Id;
            })
            .map((msg) => msg.Id),
        }),
      );
    } else {
      dispatch(sendMarkThreadAsReadEffect({ threadId }));
    }
    return () => dispatch(resetMessagesEffect());
  }, [dispatch, threadId, params]);

  const extendedMessages = useMemo(
    () =>
      (list || [])
        .filter((message) => !message.IsDeleted)
        .map((message) => ({
          ...message,
          IsOwnMessage: message?.SenderUserId === userId,
        })),
    [list, userId],
  );

  const groupedByDateMessages = useMemo(
    () => groupByDate({ items: extendedMessages }),
    [extendedMessages],
  );

  return (
    <PendingWrapper
      ref={containerRef}
      isPending={list === null}
      className={classNames(styles.messages, className)}
    >
      <div testid="messages" className={styles.innerHolder}>
        {Object.entries(groupedByDateMessages || {}).map(([groupedDate, messages]) => {
          const groupedByTimeMessages = groupBy(messages, (mess) =>
            moment(mess?.CreatedDate).format('h:mm A'),
          );
          return (
            <div key={groupedDate}>
              <div className={styles.groupedDate}>{groupedDate}</div>
              {Object.entries(groupedByTimeMessages || {}).map(([groupedTime, msgs]) => (
                <Fragment key={groupedTime}>
                  {(groupByOwnerMessages(msgs) || []).map((groupedByOwnerMessages, index) => (
                    <div key={groupedByOwnerMessages?.id || index}>
                      {groupedByOwnerMessages.items.map((messageItem) => (
                        <div
                          key={messageItem?.Id || messageItem?.TempId}
                          ref={(node) => {
                            if (node) {
                              messageRef.current[messageItem?.Id] = node;
                            } else {
                              delete messageRef.current[messageItem?.Id];
                            }
                          }}
                        >
                          <Message
                            key={messageItem?.TempId || messageItem?.Id}
                            currentEditMessage={currentEditMessage}
                            messageClassName={styles.message}
                            groupedTime={groupedTime}
                            onEdit={onEditMessage}
                            onDelete={onDeleteMessage}
                            {...messageItem}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </Fragment>
              ))}
            </div>
          );
        })}
      </div>
      <ArchivedLabel currentThread={currentThread} />
    </PendingWrapper>
  );
};

MessagesView.propTypes = {
  className: PropTypes.string,
  threadId: PropTypes.number.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  currentEditMessage: PropTypes.shape({}),
  onEditMessage: PropTypes.func,
  onDeleteMessage: PropTypes.func,
  currentThread: PropTypes.shape({}),
};

MessagesView.defaultProps = {
  className: '',
  list: null,
  currentEditMessage: null,
  onEditMessage: () => {},
  onDeleteMessage: () => {},
  currentThread: undefined,
};

export default MessagesView;
