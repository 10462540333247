import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { Button } from 'components-antd';
import { ArrowContinue } from 'components/Icons';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getPreFormSelector } from 'store/selectors/requestQuote';
import { preFormQuestionsIds } from 'settings/constants/preForm';
import FavoritesModal from '../FavoritesModal';

import styles from './styles.module.scss';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';

const Actions = (props) => {
  const { className, onNext, onPreFormSubmit } = props;
  const [favModalOpen, setFavModalOpen] = useState(false);
  const preForm = useSelector(getPreFormSelector);
  const { isClient } = useSelector(getUserRolesMapSelector);

  const onImportFavorites = () => {
    setFavModalOpen(true);
  };

  const canContinue = useMemo(() => !!preForm?.[preFormQuestionsIds.property]?.address, [preForm]);

  const onNextHandler = () => {
    if (canContinue) {
      if (isClient) {
        onPreFormSubmit();
      } else {
        onNext();
      }
    }
  };

  return (
    <div className={classNames(styles.actions, className)}>
      {/* All clients will be able to have favorites in Phase II */}
      {/* {!isSeller && ( */}
      <Button
        variant="secondary-inverted"
        className={styles.favoritesBtn}
        onClick={onImportFavorites}
      >
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.09346 7.57477C1.41382 6.87438 1 5.92933 1 4.88889C1 2.75279 2.75673 1 4.95 1C6.29045 1 7.47148 1.65597 8.18576 2.65779L9 3.79982L9.81424 2.65779C10.5285 1.65597 11.7096 1 13.05 1C15.2433 1 17 2.75279 17 4.88889C17 5.98141 16.5436 6.96934 15.802 7.67858L15.7914 7.68872L15.7811 7.69916L8.99987 14.5759L2.09346 7.57477Z"
            stroke="#163C5A"
            strokeWidth="2"
          />
        </svg>
        {isClient ? 'My' : 'Client'} Favorites
      </Button>
      <Continue onClick={onNextHandler} disabled={!canContinue} />
      <FavoritesModal isOpen={favModalOpen} onClose={() => setFavModalOpen(false)} />
    </div>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  onPreFormSubmit: PropTypes.func,
};

Actions.defaultProps = {
  className: '',
  onPreFormSubmit: () => {},
};

export default Actions;
