import { Col } from 'components-antd';
import styles from './styles.module.scss';
import { useState } from 'react';
import { Edit } from 'components/Icons';
import classNames from 'classnames';

const SearchCriteriaListItem = ({
  value,
  placeholder,
  label,
  component: Component,
  full = false,
}) => {
  const [edit, setEdit] = useState(false);

  const handleClick = () => {
    setEdit((prev) => !prev);
  };

  const handleClose = () => {
    setEdit(false);
  };

  return (
    <Col xs={24} sm={full ? 24 : 12}>
      <div className={styles.singleList}>
        <h4>{label}</h4>
        {edit ? (
          <div className={styles.componentWrapper}>
            <Component handleClose={handleClose} />
          </div>
        ) : (
          <p>{value ? value : placeholder}</p>
        )}
        <div className={classNames(styles.edit, { [styles.active]: edit })} onClick={handleClick}>
          <Edit stroke={'#262626'} strokeWidth={2} />
        </div>
      </div>
    </Col>
  );
};
export default SearchCriteriaListItem;
