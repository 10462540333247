import { routes } from 'settings/navigation/routes';
import { SUPER_USER } from 'settings/constants/roles';
import { FormBuilder, FormList, QuoteForm, QuoteFormPreview, SmartForm } from 'pages/FormBuilder';
import { NotFound } from 'pages';
import { SmartFormPreview } from 'pages/FormBuilder/SmartFormPreview';

export default {
  path: routes.dashboardServiceDirectoryForms,
  component: FormBuilder,
  roles: [SUPER_USER],
};

export const containers = [
  {
    path: routes.dashboardServiceDirectoryForms,
    component: FormList,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryFormsQuoteForm,
    component: QuoteForm,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryFormsQuoteFormPreview,
    component: QuoteFormPreview,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryFormsSmartForm,
    component: SmartForm,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryFormsSmartFormPreview,
    component: SmartFormPreview,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: '*',
    component: NotFound,
    roles: [SUPER_USER],
  },
];
