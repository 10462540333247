import React from 'react';
import PropTypes from 'prop-types';

const Fail = ({ className }) => (
  <div className={className}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM13.5355 6.46447C13.9261 6.85499 13.9261 7.48816 13.5355 7.87868L11.4142 10L13.5355 12.1213C13.9261 12.5118 13.9261 13.145 13.5355 13.5355C13.145 13.9261 12.5118 13.9261 12.1213 13.5355L10 11.4142L7.87868 13.5355C7.48816 13.9261 6.85499 13.9261 6.46447 13.5355C6.07394 13.145 6.07394 12.5118 6.46447 12.1213L8.5858 10L6.46447 7.87868C6.07394 7.48816 6.07394 6.85499 6.46447 6.46447C6.85499 6.07394 7.48816 6.07394 7.87868 6.46447L10 8.5858L12.1213 6.46447C12.5118 6.07394 13.145 6.07394 13.5355 6.46447Z"
        fill="#ADADAD"
      />
    </svg>
  </div>
);

Fail.propTypes = {
  className: PropTypes.string,
};

Fail.defaultProps = {
  className: '',
};

export default Fail;
