import { handleActions } from 'redux-actions';

import {
  selectedSearchAction,
  setAgentClientModeAction,
  setCriteriaModeAction,
  setSavedSearchTabAction,
  setSelectedClientSavedSearchAction,
  setIsClientMatchesAction,
} from 'store/actions/feedv3';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';
import { EDIT } from 'settings/constants/mode';

const initialData = {
  savedSearchTab: 1,
  selectedSearch: null,
  isClientMatches: false,
  selectedClientSavedSearch: null,
  agentClientMode: { value: false, user: null },
  mode: EDIT,
};

export default handleActions(
  {
    [selectedSearchAction as any]: (state, { payload }) => ({
      ...state,
      selectedSearch: payload.selectedSearch,
    }),
    [setCriteriaModeAction as any]: (state, { payload }) => {
      return {
        ...state,
        mode: payload.mode,
      };
    },
    [setSavedSearchTabAction as any]: (state, { payload }) => {
      return {
        ...state,
        savedSearchTab: payload.savedSearchTab,
      };
    },
    [setSelectedClientSavedSearchAction as any]: (state, { payload }) => {
      return {
        ...state,
        selectedClientSavedSearch: payload.selectedClientSavedSearch,
      };
    },
    [setIsClientMatchesAction as any]: (state, { payload }) => {
      return {
        ...state,
        isClientMatches: payload.isClientMatches,
      };
    },
    [setAgentClientModeAction as any]: (state, { payload }) => {
      return {
        ...state,
        agentClientMode: payload.agentClientMode,
      };
    },
    [appLogoutAction as any]: () => cloneDeep(initialData),
  },
  initialData,
);
