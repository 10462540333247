import Api from 'store/effects/core/api';
import {
  requestGetFormListAction,
  requestToggleFavoriteFormAction,
  selectReloadFormListAction,
  requestUpdateFormVersionStatusAction,
  updateFormListSearchAction,
  requestFormMetaAction,
  deleteFormVersionModalAction,
  deleteFormVersionAction,
  createNewFormAction,
  requestAddTagAction,
  smartFormUploadAction,
  requestUserFormMetaAction,
  createNewTemplateAction,
  updateFormTemplateAction,
} from 'store/actions/formBuilder';
import {
  addFormToFavorites,
  addTag,
  createNewForm,
  deleteFormVersion,
  getFormList,
  getFormMeta,
  getUserFormMeta,
  updateFormVersionStatus,
  uploadSmartForm,
  updateFormTemplate,
  updateBundleTemplate,
} from 'api/formBuilder';
import { DateRange, DeleteFormVersionType, FormListSearch, FormStatus, FormType } from 'types';
import { createNewTemplate } from 'api/formProcess';

interface getFormListBodyType {
  page?: number;
  pageSize?: number;
  isFavorite?: boolean;
  type?: FormType[];
  tags?: number[];
  categories?: number[];
  createdDate?: DateRange;
  updatedDate?: DateRange;
  createdBy?: string;
  status?: FormStatus;
  searchText?: string;
  locationState?: string[];
  folderId?: number;
}

export const getFormListEffect = (cfg: getFormListBodyType = {}, options: any = {}, cb?) => {
  const requestParams = { action: requestGetFormListAction, method: getFormList };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const toggleFavoriteFormEffect = (cfg, cb) => {
  const { isFavorite, formId } = cfg;

  const sendRequest = Api.execBase({
    action: requestToggleFavoriteFormAction,
    method: addFormToFavorites,
  });

  return sendRequest({ formId, isFavorite }, {}, cb);
};

export const getFormMetaEffect = () => {
  const sendRequest = Api.execBase({
    action: requestFormMetaAction,
    method: getFormMeta,
  });

  return sendRequest();
};

export const getUserFormMetaEffect = () => {
  const sendRequest = Api.execBase({
    action: requestUserFormMetaAction,
    method: getUserFormMeta,
  });

  return sendRequest();
};

export const patchFormVersionStatusEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestUpdateFormVersionStatusAction,
    method: updateFormVersionStatus,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateFormListSearchEffect = (cfg: FormListSearch) => (dispatch) => {
  dispatch(updateFormListSearchAction({ search: { ...cfg, page: cfg.page || 1 } }));
  dispatch(selectReloadFormListAction());
};

export const selectReloadFormListEffect = (cfg?) => (dispatch) => {
  dispatch(selectReloadFormListAction(cfg));
};

export const deleteFormVersionModalEffect = (cfg?: DeleteFormVersionType) => (dispatch) => {
  dispatch(deleteFormVersionModalAction(cfg));
};

export const deleteFormVersionEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: deleteFormVersionAction,
    method: deleteFormVersion,
  });

  return sendRequest(cfg, {}, cb);
};

export const createNewFormEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: createNewFormAction,
    method: createNewForm,
  });

  return sendRequest(cfg, {}, cb);
};

export const createNewTemplateEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: createNewTemplateAction,
    method: createNewTemplate,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateFormTemplateEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: updateFormTemplateAction,
    method: updateFormTemplate,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateBundleTemplateEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: updateFormTemplateAction,
    method: updateBundleTemplate,
  });

  return sendRequest(cfg, {}, cb);
};

export const requestAddTag = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestAddTagAction,
    method: addTag,
  });

  return sendRequest(cfg, {}, cb);
};

export const smartFormUploadEffect = (formData, cb) => {
  const requestParams = {
    action: smartFormUploadAction,
    method: uploadSmartForm,
  };
  let sendRequest = Api.execBase(requestParams);

  const config = {
    formData,
  };

  return sendRequest(config, {}, cb);
};
