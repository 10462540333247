import styles from './styles.module.scss';
import { FC, useEffect, useState } from 'react';
import { IMechanicalComponent } from '../types';
import SelectComponent from '../SelectComponent';
import {
  MECHANICAL_ENERGY,
  MECHANICAL_HEAT_SOURCE,
  MECHANICAL_WASTE,
  MECHANICAL_WATER_SOURCE,
  getDefaultValues,
} from '../../constants';
import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { useSelector } from 'react-redux';

const MechanicalComponent: FC<IMechanicalComponent> = ({ color, value, onChange }) => {
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const { HomePrefs: homePrefersDefault } = defaultClientSearch || {};
  const [heatSource, setHeatSource] = useState(
    getDefaultValues(homePrefersDefault, MECHANICAL_HEAT_SOURCE),
  );
  const [waterSource, setWaterSource] = useState(
    getDefaultValues(homePrefersDefault, MECHANICAL_WATER_SOURCE),
  );
  const [waste, setWaste] = useState(getDefaultValues(homePrefersDefault, MECHANICAL_WASTE));
  const [energy, setEnergy] = useState(getDefaultValues(homePrefersDefault, MECHANICAL_ENERGY));

  useEffect(() => {
    onChange([...heatSource, ...waterSource, ...waste, ...energy]);
  }, [heatSource, waterSource, waste, energy]);

  return (
    <>
      <p className={styles.label}>Heat Source</p>
      <SelectComponent
        items={MECHANICAL_HEAT_SOURCE}
        color={color}
        value={heatSource}
        onChange={(value) => {
          setHeatSource(value);
        }}
        multiple={false}
      />
      <p className={styles.label}>Water Source</p>
      <SelectComponent
        items={MECHANICAL_WATER_SOURCE}
        color={color}
        value={waterSource}
        onChange={(value) => {
          setWaterSource(value);
        }}
        multiple={false}
      />
      <p className={styles.label}>Waste</p>
      <SelectComponent
        items={MECHANICAL_WASTE}
        color={color}
        value={waste}
        onChange={(value) => {
          setWaste(value);
        }}
        multiple={false}
      />
      <p className={styles.label}>Energy</p>
      <SelectComponent
        items={MECHANICAL_ENERGY}
        color={color}
        value={energy}
        onChange={(value) => {
          setEnergy(value);
        }}
        multiple={false}
      />
    </>
  );
};

export default MechanicalComponent;
