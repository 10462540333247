import { Icons } from '../../../Icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const IconText = ({ variant, text, infoText, className }) => {
  return (
    <div className={classNames(styles.iconText, className)}>
      <Icons variant={variant} />
      <div className={styles.detail}>
        <h4 className={classNames(styles.title, styles.text)}>{text}</h4>
        {infoText && <h4 className={styles.info}>{infoText}</h4>}
      </div>
    </div>
  );
};

IconText.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  testid: PropTypes.string,
  text: PropTypes.string,
  infoText: PropTypes.string,
};

IconText.defaultProps = {
  className: '',
  variant: '',
  text: '',
  infoText: '',
  testid: undefined,
};
