import { AgentDocumentTypes, DocumentResponseType, DocumentOptionUtils } from 'types';
import { Card } from '../../Card';

interface DocumentSectionProps extends DocumentOptionUtils {
  type: AgentDocumentTypes;
  documents: DocumentResponseType;
}

export const DocumentSection = ({ type, documents, ...props }: DocumentSectionProps) => {
  if (!documents?.length) {
    return <></>;
  }

  return (
    <div>
      {documents.map((document, index) => (
        <Card
          type={type}
          document={document}
          key={`${type}-${index}-document-section`}
          {...props}
        />
      ))}
    </div>
  );
};
