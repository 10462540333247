import { handleActions } from 'redux-actions';
import { getDataFor } from 'store/helpers';
import { onBoardingSignUpAction } from 'store/actions/onBoarding';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';

const initialData = {};

export default handleActions(
  {
    [onBoardingSignUpAction]: (state, { payload }) => {
      const data = getDataFor('data', payload, initialData);
      const meta = getDataFor('meta', payload, initialData);

      const stages = meta?.stageIndex !== undefined ? { [meta?.stageIndex]: { meta, data } } : {};

      return { ...state, ...stages };
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
