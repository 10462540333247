import * as yup from 'yup';

export const schema = yup.object().shape({
  address: yup
    .object()
    .shape({
      City: yup.string(),
      State: yup.string(),
      Zip: yup.string(),
      Line1: yup.string(),
      ProviderPlaceId: yup.string().required(),
      PlaceName: yup.string(),
    })
    .required('Location is a required field'),
});
