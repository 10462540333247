import { useParams } from 'react-router-dom';

import { FormProcessDataType } from 'types';
import { FormStateManager } from 'pages/FormProcess';

export const AuthFormProcess = () => {
  const { formDocumentPublicId, formProcessPublicId } = useParams<FormProcessDataType>();

  return formDocumentPublicId && formProcessPublicId ? (
    <FormStateManager
      authData={{
        formDocumentPublicId,
        formProcessPublicId,
      }}
    />
  ) : (
    <></>
  );
};
