import { getAccessToken } from 'api/externalAuth';
import { showErrorMessage } from 'helpers';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { LocalStorage, LocationService } from 'services';
import { routes } from 'settings/navigation/routes';

const locationService = new LocationService();

export const ExternalVerificationInner: React.FC<{}> = () => {
  const params = useParams<{ token: string }>();
  const history = useHistory();
  locationService.setLocation(location);

  useEffect(() => {
    const runEffect = async () => {
      try {
        const response = await getAccessToken({
          Token: params.token,
        });

        const accessToken = response.data.result;

        LocalStorage.setExternalToken(accessToken);

        const query = locationService.getQuery();
        if (query.callbackUrl) {
          location.href = query.callbackUrl;
        } else {
          history.push(routes.index);
        }
      } catch (err) {
        showErrorMessage('Invalid token');
      }
    };

    if (params.token) {
      runEffect();
    }
  }, [params.token]);

  return null;
};
