import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import Row from './Row';

const Grid = (props) => {
  const { items, columns, getHeader: getHeaderProp, getFooter } = props;
  const { keyProp, collapsibleRows, getChildRowContent, onRowClick } = props;
  const {
    className,
    headerClassName,
    footerClassName,
    bodyClassName,
    wrapperClassName,
    headerColContentClassName,
    rowHeightTransition,
  } = props;

  const getHeader = () => {
    if (typeof getHeaderProp === 'function') return getHeaderProp(columns);
    return (
      <>
        {columns.map(({ title, className: colClassName, children, onClick }) => (
          <th key={title} className={classNames(styles.column, colClassName)} onClick={onClick}>
            <div className={headerColContentClassName}>
              {title}
              {children}
            </div>
          </th>
        ))}
      </>
    );
  };

  const getBody = () => (
    <>
      {items.map((item, index) => (
        <Row
          key={item[keyProp] || index}
          item={item}
          columns={columns}
          className={styles.row}
          cellClassName={styles.column}
          childRowClassName={styles.childRow}
          collapsible={collapsibleRows}
          heightTransition={rowHeightTransition}
          getChildRowContent={getChildRowContent}
          onClick={onRowClick}
        />
      ))}
    </>
  );

  return (
    <div className={classNames(styles.gridWrapper, wrapperClassName)}>
      <table className={classNames(styles.grid, className)}>
        <thead className={classNames(styles.gridHeader, headerClassName)}>
          <tr className={styles.row}>{getHeader()}</tr>
        </thead>
        {items?.length ? (
          <tbody className={classNames(styles.gridBody, bodyClassName)}>{getBody()}</tbody>
        ) : null}
      </table>
      {!items?.length ? <div className={styles.noContentWrapper}>No results found...</div> : null}
      {typeof getFooter === 'function' ? (
        <div className={classNames(styles.gridFooter, footerClassName)}>{getFooter(items)}</div>
      ) : null}
    </div>
  );
};

Grid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      children: PropTypes.node,
      className: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  headerColContentClassName: PropTypes.string,
  rowHeightTransition: PropTypes.string,
  keyProp: PropTypes.string.isRequired,
  collapsibleRows: PropTypes.bool,
  getChildRowContent: PropTypes.func,
  onRowClick: PropTypes.func,
  getHeader: PropTypes.func,
  getFooter: PropTypes.func,
};
Grid.defaultProps = {
  className: '',
  bodyClassName: '',
  headerClassName: '',
  footerClassName: '',
  wrapperClassName: '',
  headerColContentClassName: '',
  rowHeightTransition: '0.3s ease',
  collapsibleRows: false,
  getChildRowContent: () => null,
  onRowClick: () => {},
  getHeader: null,
  getFooter: null,
};

export default Grid;
