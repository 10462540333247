import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Activity } from './Activity';
import { Plus } from 'components/Icons/index';
import { getActivityLogs } from 'api/activityLog';
import { orderBy } from 'lodash';
import moment from 'moment';
import SubmissionActivity from './SubmissionActivity';
import styles from './styles.module.scss';
import Spinner from 'components/Spinner';
import { showErrorMessage } from 'helpers';
import classNames from 'classnames';

interface HistoryLogsProps {
  query: any;
  offerSubmissionDate?: Date;
  BuyingAgentName?: string;
  showHeader?: boolean;
  showAddress?: boolean;
  className?: string;
}

export const HistoryLogs = ({
  query,
  offerSubmissionDate,
  BuyingAgentName,
  showHeader = true,
  showAddress = true,
  className,
}: HistoryLogsProps) => {
  const [history, setHistory]: any[] = useState([]);
  const [loading, setLoading] = useState(true);
  const [historyLimit, setHistoryLimit] = useState(3);
  const [visibleShowAllBtn, setVisibleShowAllBtn] = useState(
    history?.length > historyLimit || false,
  );

  const fetchActivityLog = useCallback(async () => {
    const now = moment().utcOffset() / 60;
    const { data } = await getActivityLogs({
      ...query,
      timeZoneOffset: now,
    });
    if (data?.result) {
      const sorted: any[] = orderBy(data?.result?.items, ['Id'], ['desc']);
      setHistory(sorted);
      setVisibleShowAllBtn(sorted.length > historyLimit);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchActivityLog().catch((err) => {
      showErrorMessage(err);
      setLoading(false);
    });
  }, []);

  const toggleExpander = useCallback(() => {
    setVisibleShowAllBtn(false);
    setHistoryLimit(history.length - 1);
  }, [history]);

  const slicedHistoryList = useMemo(() => {
    if (history && history.length) {
      if (history.length > 3) return history.slice(0, historyLimit);

      return history.slice(0, history.length - 1);
    }

    return [];
  }, [history, historyLimit]);

  return (
    <div className={classNames(styles.history, className)}>
      {showHeader && <p className={styles.title}>History</p>}

      {loading ? (
        <Spinner loaderClassName={styles.loadingSpinner} />
      ) : history.length ? (
        <div>
          {slicedHistoryList.map((historyItem) => (
            <Activity
              key={historyItem.Id}
              data={{ ...historyItem, showAddress }}
              className={styles.historyItem}
              auditItemClass={styles.auditLogItem}
            />
          ))}

          {!visibleShowAllBtn && (
            <SubmissionActivity data={history[history.length - 1]} Time={offerSubmissionDate} />
          )}

          <div onClick={toggleExpander} className={styles.showAllBtn} hidden={!visibleShowAllBtn}>
            <button testid="show_all" className={styles.showAllIcon}>
              <Plus />
            </button>
            <span className={styles.showAllTxt}>Show All</span>
          </div>
        </div>
      ) : !offerSubmissionDate ? (
        <p className={styles.disclaimer}></p> // History Check For Again an offer
      ) : (
        <></>
      )}
    </div>
  );
};
