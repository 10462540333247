import { useState } from 'react';
import PropTypes from 'prop-types';

import { Tabs } from 'components-antd';
import { PropertyPrefs } from './PropertyPrefs';
import { NeighborhoodPrefs } from './NeighborhoodPrefs';
import { ImportantFeatures } from './ImportantFeaturesPrefs';
import { prefsIds } from 'settings/constants/preferences';

import styles from './styles.module.scss';

const ACTION = {
  add: 'add',
  edit: 'edit',
  delete: 'delete',
  update: 'update',
};

const BuyerPreferences = ({ formik }) => {
  const [softCriteria, setSoftCriteria] = useState({ ...formik.values['softCriteria'] });

  const [commutes, setCommutes] = useState(
    softCriteria[prefsIds.commutes]?.data ? [...softCriteria[prefsIds.commutes].data] : [],
  );
  const [importantLocations, setImportantLocations] = useState(
    softCriteria[prefsIds.proximityToImportantLocations]?.data
      ? [...softCriteria[prefsIds.proximityToImportantLocations].data]
      : [],
  );
  const addCommuteLocations = (
    key,
    value,
    type,
    data,
    action,
    locationState,
    setLocationState,
    criteriaObj,
    defaultCriteria,
  ) => {
    let locations = [];
    let filtered = null;
    switch (action) {
      case ACTION.add:
        {
          data.id = Math.random().toString(16).slice(2);
          data && setLocationState([...locationState, data]);
          locations = [...locationState, data];
        }
        break;
      case ACTION.delete:
        {
          filtered = locationState.filter((item) => item.id != data.id);
          setLocationState([...filtered]);
        }
        break;
      case ACTION.edit:
        {
          locations = [...locationState];
          const objIndex = locations.findIndex((obj) => obj.id == data.id);
          locations[objIndex] = { ...data };
          setLocationState([...locations]);
        }
        break;
      case ACTION.update:
        {
          const index = data;
          const softCriteriaCopy = [...criteriaObj[key].data];
          softCriteriaCopy[index].ImportanceAndWeight = value;
          criteriaObj[key].data = softCriteriaCopy;
        }
        break;
    }
    if (data && action !== ACTION.update) {
      criteriaObj[key] = {
        value: value,
        data: action === ACTION.delete ? filtered : locations,
        type: type,
      };
    } else if (action !== ACTION.update) {
      defaultCriteria();
    }
  };

  const addSoftCriteria = (key, value, type, data, action) => {
    const criteriaObj = { ...softCriteria };

    const defaultCriteria = () => {
      criteriaObj[key] = {
        value: value,
        type: type,
      };
    };

    if (
      key === prefsIds.goodSchools ||
      key === prefsIds.outdoorPrefs ||
      key === prefsIds.flooring
    ) {
      if (data) {
        criteriaObj[key] = {
          value: value,
          data: data,
          type: type,
        };
      } else {
        defaultCriteria();
      }
    } else if (key === prefsIds.parking) {
      if (data) {
        criteriaObj[key] = {
          value: value,
          data: { ...data },
          type: type,
        };
      } else {
        defaultCriteria();
      }
    } else if (key === prefsIds.commutes) {
      addCommuteLocations(
        key,
        value,
        type,
        data,
        action,
        commutes,
        setCommutes,
        criteriaObj,
        defaultCriteria,
      );
    } else if (key === prefsIds.proximityToImportantLocations) {
      addCommuteLocations(
        key,
        value,
        type,
        data,
        action,
        importantLocations,
        setImportantLocations,
        criteriaObj,
        defaultCriteria,
      );
    } else {
      defaultCriteria();
    }
    setSoftCriteria({ ...criteriaObj });
    formik.setFieldValue('softCriteria', { ...criteriaObj });
  };

  const tabItems = [
    {
      key: '1',
      label: 'Property',
      children: (
        <PropertyPrefs
          formik={formik}
          softCriteria={softCriteria}
          addSoftCriteria={addSoftCriteria}
        />
      ),
    },
    {
      key: '2',
      label: 'Neighborhood',
      children: <NeighborhoodPrefs softCriteria={softCriteria} addSoftCriteria={addSoftCriteria} />,
    },
    {
      key: '3',
      label: 'Commutes',
      children: (
        <ImportantFeatures
          formik={formik}
          softCriteria={softCriteria}
          addSoftCriteria={addSoftCriteria}
        />
      ),
    },
  ];

  return (
    <>
      <h3 className={styles.heading}>Wish List</h3>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </>
  );
};

BuyerPreferences.propTypes = {
  formik: PropTypes.shape({}).isRequired,
};

BuyerPreferences.defaultProps = {};

export default BuyerPreferences;
