import { PageNotFound, Spinner } from 'components';
import { Footer } from 'components-antd/Footer/Footer';
import { Play, ArrowLeft } from 'components/Icons';
import styles from './styles.module.scss';
import { Tabs } from 'antd';
import { useState, useEffect } from 'react';
import { routes } from 'settings/navigation/routes';
import classNames from 'classnames';
import { getKitEligibilityStats } from 'api/kits';
import ClientsEligibilityTable from '../ClientsEligibilityTable';
import PreviewModal from '../PreviewModal';
import { useSelector } from 'react-redux';
import { TEAM_OWNER } from 'settings/constants/roles';
import {
  getAgentTeamRoleSelector,
  getAgentTeamIsActiveSelector,
} from 'store/selectors/agentTeamDetail';
import Switcher from '../Switcher';
import { getUserSelector } from 'store/selectors/user';

type DetailsViewPropsType = {
  backHandler: Function;
  data;
  teamMembers;
};

function DetailsView(props: DetailsViewPropsType) {
  const { targetKit, Locations } = props.data;
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeClients, setActiveClients] = useState([]);
  const [eligibleClients, setEligibleClients] = useState([]);
  const [notEligibleClients, setNotEligibleClients] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [currentMember, setCurrentMember] = useState<number>(0);
  const [memberDataLoading, setMemberDataLoading] = useState<boolean>(false);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const user = useSelector(getUserSelector);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);

  const fetchKitEligibilityData = async () => {
    try {
      const response = await getKitEligibilityStats(targetKit.Id, {
        located: Locations?.UUID ? 1 : 0,
        uuid: Locations?.UUID ? Locations?.UUID : '',
      });
      if (response.status === 200) {
        const { Active, Eligible, NotEligible } = response.data.result;
        setActiveClients(Active);
        setEligibleClients(Eligible);
        setNotEligibleClients(NotEligible);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const fetchMemberWiseKitEligibilityData = async () => {
    setMemberDataLoading(true);
    try {
      const response = await getKitEligibilityStats(targetKit.Id, {
        located: Locations?.UUID ? 1 : 0,
        uuid: Locations?.UUID ? Locations?.UUID : '',
        TeamMemberId: currentMember,
      });
      if (response.status === 200) {
        const { Active, Eligible, NotEligible } = response.data.result;
        setActiveClients(Active);
        setEligibleClients(Eligible);
        setNotEligibleClients(NotEligible);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
    setMemberDataLoading(false);
  };

  useEffect(() => {
    if (loading) fetchKitEligibilityData();
  }, [loading]);

  useEffect(() => {
    if (
      agentRole === TEAM_OWNER &&
      isTeamAgentActive &&
      props?.teamMembers?.length &&
      currentMember
    )
      fetchMemberWiseKitEligibilityData();
  }, [currentMember]);

  const getOptions = () => {
    const options = props.teamMembers
      ?.filter((item) => item.Id != user.data.Id)
      .map((item) => {
        return {
          key: item.Id,
          val: item.FirstName + (item.LastName ? ' ' + item.LastName : ''),
        };
      });
    return [{ key: user.data.Id, val: 'Me' }, ...options];
  };

  if (loading) {
    return <Spinner loaderClassName={classNames(styles.loader)} />;
  } else if (error) {
    return (
      <div>
        <PageNotFound backUrl={routes.clarity} />
        <div className={styles.Footer}>
          <Footer />
        </div>
      </div>
    );
  } else
    return (
      <>
        {showPreviewModal ? (
          <PreviewModal
            showModal={setShowPreviewModal}
            open={showPreviewModal}
            kitName={targetKit.Name}
            kitSlug={targetKit.Slug}
          />
        ) : (
          <></>
        )}
        <div className={styles.myKitsHeader}>
          <div className={styles.left}>
            <div className={styles.backLink} onClick={() => props.backHandler(false)}>
              <ArrowLeft className={styles.back} />
            </div>
            {agentRole === TEAM_OWNER && isTeamAgentActive ? (
              <Switcher
                useKey={true}
                keyedOptions={getOptions()}
                onchangeHandler={setCurrentMember}
              />
            ) : (
              <></>
            )}
          </div>
          <h1 className={styles.heading}>{targetKit.Name}</h1>
          <div
            className={styles.preview}
            onClick={() => {
              setShowPreviewModal(true);
            }}
          >
            <Play />
            <p className={styles.previewText}>Preview</p>
          </div>
        </div>
        {memberDataLoading ? (
          <Spinner loaderClassName={classNames(styles.loader)} />
        ) : (
          <div className={styles.myKitsContent}>
            <Tabs
              defaultActiveKey="1"
              centered
              tabBarStyle={{
                color: '#AAABAB',
                backgroundColor: '#FFF',
              }}
            >
              <Tabs.TabPane
                tab={`Active ( ${activeClients.length ? activeClients.length : 0} )`}
                key={'Active'}
              >
                <ClientsEligibilityTable
                  data={activeClients}
                  mode={'Active'}
                  kitId={targetKit.Id}
                  kitCreatedDate={targetKit.CreatedDate}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={`Eligible ( ${eligibleClients.length ? eligibleClients.length : 0} )`}
                key={'Eligible'}
              >
                <ClientsEligibilityTable
                  data={eligibleClients}
                  mode={'Eligible'}
                  kitId={targetKit.Id}
                  kitCreatedDate={targetKit.CreatedDate}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={`Not Eligible ( ${
                  notEligibleClients.length ? notEligibleClients.length : 0
                } )`}
                key={'Not Eligible'}
              >
                <ClientsEligibilityTable
                  data={notEligibleClients}
                  mode={'Not Eligible'}
                  kitId={targetKit.Id}
                  kitCreatedDate={targetKit.CreatedDate}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        )}
      </>
    );
}

export default DetailsView;
