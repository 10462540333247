import { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { UploadChangeParam } from 'antd/lib/upload/interface';

import { updateSmartFormMetaEffect } from 'store/effects/formBuilder';
import { getSmartFormMetaSelect } from 'store/selectors/formBuilder';
import { UploadDragger } from 'components-antd';
import { UPLOAD_FILE } from 'app-constants';
import { PDFViewer } from 'pages/FormBuilder/components/PDFViewer';
import { PDFFields } from 'types';

import styles from './styles.module.scss';

interface UploadPDFContainerProps {
  setFile: (file: File) => void;
  file?: File;
}
export const UploadPDFContainer = ({ setFile, file }: UploadPDFContainerProps) => {
  const [link, setLink] = useState('');

  const dispatch = useDispatch();

  const onChange = ({ fileList }: UploadChangeParam<any>) => {
    if (fileList.length) {
      setFile(fileList[fileList.length - 1].originFileObj);
    }
  };

  useEffect(() => {
    if (file) {
      updatePDF();
    } else {
      setLink('');
    }
  }, [file]);

  const updatePDF = async () => {
    if (file) {
      const newLink = URL.createObjectURL(file);
      setLink(newLink);
    }
  };

  const { highlightedFields, questions } = useSelector(getSmartFormMetaSelect);

  const onFieldClick = (field: PDFFields) => {
    if (!questions.length) return;
    const questionIndex = questions.findIndex((question) =>
      question?.Fields?.some((f) => f.id === field.id),
    );

    if (questionIndex >= 0) {
      dispatch(
        updateSmartFormMetaEffect({
          activeQuestionIndex: questionIndex,
          highlightedFields: [field.id],
        }),
      );
    }
  };

  return file && link ? (
    <PDFViewer
      Url={link}
      highlightedFields={highlightedFields || []}
      questions={questions as any}
      onFieldClick={onFieldClick}
    />
  ) : (
    <div className={styles.uploadContainer}>
      <UploadDragger
        action={UPLOAD_FILE}
        onChange={onChange}
        multiple={false}
        showUploadList={false}
        beforeUpload={() => false}
        accept="application/pdf"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className={styles.uploadText}>Upload Document</p>
        <p className={styles.uploadHint}>
          Drag and drop your document here or upload it from your local disk
        </p>
      </UploadDragger>
    </div>
  );
};
