import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { keys } from 'lodash-es';

import { getActivityFiltersDrawerFieldsSelector } from 'store/selectors/drawers/activityFilters';
import { requestGetActivityLogsEffect } from 'store/effects/activityLogsWorkshop';
import { getActivityLogsWorkshopSelector } from 'store/selectors/activityLogsWorkshop';
import { formatLogsData, getFormattedFilterObject } from 'utils/activityLogsHelper';
import { activityLogsPageSize } from 'app-constants/activityLogs';
import { AuditLogCard } from 'components-antd/AuditLogCard';
import { LocalHeader } from '../../LocalHeader';
import { Spinner } from 'components';
import { Col } from 'components-antd';

import styles from './styles.module.scss';
import { getTransactionTaskCategoriesSelector } from 'store/selectors/transactionTaskCategories';
import { getTaskCategoriesEffect } from 'store/effects/transactions';

export const WorkshopActivityLogSection: React.FC<any> = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const fieldsPayload = useSelector(getActivityFiltersDrawerFieldsSelector);
  const { isPending, activities } = useSelector(getActivityLogsWorkshopSelector);
  const { categories } = useSelector(getTransactionTaskCategoriesSelector);
  const dispatch = useDispatch();

  const [prevY, setPrevY] = useState(0);
  const logsRef: any = useRef([]);
  const loadingRef: any = useRef(null);
  const prevYRef = useRef({});
  const pageRef: any = useRef({});
  const totalItemsRef: any = useRef({});
  const fieldsRef = useRef({});
  logsRef.current = [];
  pageRef.current = pageNumber;
  prevYRef.current = prevY;
  totalItemsRef.current = activities?.total;

  const handleObserver = (entities) => {
    const y = entities[0].boundingClientRect.y;
    if (prevYRef.current > y) {
      if (pageRef.current <= Math.ceil(totalItemsRef.current / activityLogsPageSize)) {
        dispatch(
          requestGetActivityLogsEffect(
            getFormattedFilterObject(fieldsRef?.current, pageRef.current, activityLogsPageSize),
          ),
        );
        setPageNumber(pageRef.current + 1);
      }
    }
    setPrevY(y);
  };

  useEffect(() => {
    fieldsRef.current = fieldsPayload;
  }, [fieldsPayload]);

  useEffect(() => {
    setPageNumber(pageRef.current + 1);

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    observer.observe(loadingRef.current);
    dispatch(getTaskCategoriesEffect());
    dispatch(
      requestGetActivityLogsEffect(
        getFormattedFilterObject(fieldsPayload, pageRef.current, activityLogsPageSize),
      ),
    );
  }, []);

  const getDayAndDateTitleStr = (dateTimestamp) => {
    const dateStr = moment(new Date(dateTimestamp)).format('dddd, MMM D, YYYY');
    if (moment().add(-1, 'days').format('dddd, MMM D, YYYY') === dateStr) {
      return 'Yesterday';
    }
    if (moment().format('dddd, MMM D, YYYY') === dateStr) {
      return 'Today';
    }
    return dateStr;
  };

  const renderActivities = () => {
    const formatted: any = [];
    activities?.items?.length > 0 &&
      activities?.items?.forEach((document) => {
        if (document.ActionTimestamp) {
          const date = getDayAndDateTitleStr(document.ActionTimestamp);
          const index = formatted.findIndex((f) => f[date] !== undefined);
          index === -1
            ? formatted.push({ [date]: [document] })
            : formatted[index][date].push(document);
        }
      });
    const taskedGroupedData = formatLogsData(activities?.items, {
      taskGroup: true,
      categories: categories,
    });
    if (taskedGroupedData.length) {
      return (
        <>
          {taskedGroupedData.map((item, idx) => (
            <AuditLogCard
              key={idx}
              activityLogs={item[`${keys(item)}`]}
              DayAndDate={keys(item)[0]}
              activityLogCardClassName={styles.clarityActivityLogCard}
            />
          ))}
        </>
      );
    }

    if (!isPending && !formatted.length) return <p className={styles.noDataFound}>No data found</p>;
  };

  return (
    <div>
      <div className={styles.activityLogWrapper}>
        <LocalHeader />
        {renderActivities()}
      </div>
      <div ref={loadingRef} style={{ height: '150px', margin: '25px' }}>
        <span style={{ display: isPending ? 'block' : 'none' }}>
          <Spinner />
        </span>
      </div>
    </div>
  );
};
