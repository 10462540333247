import React from 'react';
import classNames from 'classnames';
import { Menu } from 'components-antd';
import { map } from 'lodash-es';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';
import { useScreen } from 'hooks';

interface ClientDashboardTabsProps {
  mode?: 'horizontal' | 'vertical';
  activeKey: string;
  links: {
    value: string;
    title: string;
    icon?: React.ComponentType<{ className?: string; color?: string }>;
  }[];
  setActiveTab: (tab: string) => void;
  className?: string;
}

export const ClientDashboardTabs = ({
  mode = 'horizontal',
  activeKey,
  links,
  setActiveTab,
  className,
}: ClientDashboardTabsProps) => {
  const { screen } = useScreen();
  return (
    <div>
      <div
        className={classNames(
          styles.links,
          { [styles.linksHorizontal]: mode === 'horizontal' },
          { [styles.linksVertical]: mode === 'vertical' },
        )}
      >
        {map(links, ({ value, title, icon: Icon }) => (
          <div
            key={value}
            className={classNames(styles.link, {
              [styles.active]: activeKey === value,
            })}
          >
            <div className={className} title={title} onClick={() => setActiveTab(value)}>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '15px',
                  width: '200px',
                }}
              >
                {!screen.mobileWidth && Icon && (
                  <Icon className={styles.icon} color="currentColor" />
                )}
                {title}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
