import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getOpenedQuoteThreadInfo } from 'store/selectors/sockets/threads';
import { getQuoteByOpenedThreadId } from 'store/selectors/quotes';

import { Avatars } from 'components';
import DrawerHeaderControlls from 'components/Drawers/MessagesDrawer/components/DrawerHeaderControlls';

import {
  getViewContactsOption,
  getGoToTypeOption,
  getArchiveThreadOption,
  getAddParticipantThreadOption,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';
import { getQuotesDrawerParams } from 'store/selectors/drawers/quotes';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';

const MessagesDrawerChatHeader = ({ onBack }) => {
  const dispatch = useDispatch();
  const { names, subTitle, type, avatars, thread } = useSelector(getOpenedQuoteThreadInfo);
  const { isQuoteDetails } = useSelector(getQuotesDrawerParams);
  const { category, property } = useSelector(getQuoteByOpenedThreadId);

  const getActions = useCallback(
    () => [
      ...getAddParticipantThreadOption(dispatch, {
        info: subTitle,
        type,
      }),
      ...getViewContactsOption(dispatch, thread),
      ...(!isQuoteDetails
        ? getGoToTypeOption(dispatch, thread, () => {}, routes.servicesQuotes)
        : []),
      ...getArchiveThreadOption(dispatch, thread),
    ],
    [dispatch, thread, subTitle, type, isQuoteDetails],
  );

  if (!thread) return null;

  return (
    <div
      testid="chat_header"
      className={classNames(styles.wrapper, { [styles.hidden]: !property })}
    >
      <DrawerHeaderControlls
        actions={getActions()}
        archived={thread.IsArchived}
        onBack={onBack}
        label={isQuoteDetails ? 'Back' : category}
      />
      <div className={classNames(styles.chatHeaderInfo, { [styles.hidden]: !property })}>
        <div className={styles.chatHeaderAvatars}>
          <Avatars
            className={styles.avatars}
            avatarWrapperClassName={styles.avatarWrapper}
            avatarClassName={styles.avatar}
            isNotSingleClassName={styles.isNotSingle}
            overflowClassName={styles.overflow}
            avatars={avatars}
          />
        </div>
        <div className={styles.chatHeaderTitles}>
          <div className={styles.chatHeaderTitle}>
            <span testid="header_name">{names}</span>
            {category && (
              <span testid="header_type" className={styles.chatType}>
                {category}
              </span>
            )}
          </div>
          <div className={styles.chatHeaderSubTitle}>
            <span testid="header_subtitle" className={styles.chatHeaderTitleSpan}>{`Property: ${
              property || '-'
            }`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

MessagesDrawerChatHeader.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default MessagesDrawerChatHeader;
