import { apiServer } from 'settings/web-services/api';

/**
 * @deprecated
 */
export function getClientContext() {
  return apiServer.get('/user/client/agents');
}

export function getClientContexts(params) {
  const { inActiveOnly = false } = params || {};
  return apiServer.get(`/user/client/contexts?inActiveOnly=${inActiveOnly}`);
}
