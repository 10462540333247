import { createSelector } from 'reselect';
import {
  getThreadsListSelector,
  getTransactionThreadsListSelector,
} from 'store/selectors/sockets/threads';
import {
  getCurrentOfferMessagesInfoSelector,
  getCurrentOfferMessagesThreadIdSelector,
  getCurrentOfferThreadSelector,
} from 'store/selectors/sockets/offerMessages';
import { ERROR, IDLE, PENDING } from 'settings/constants/apiState';
import { orderBy } from 'lodash-es';
import { getUserId } from '../user';
import { getTransactionSelector } from '../transaction';

const localState = ({ offerMessages }) => offerMessages;

export const getOfferMessagesStateSelector = createSelector(localState, (transactionMessages) => ({
  isIdle: transactionMessages?.state === IDLE,
  isPending: transactionMessages?.state === PENDING,
  isError: transactionMessages?.state === ERROR,
  isData: !!transactionMessages?.data,
  statePerTransaction: transactionMessages?.statePerTransaction,
}));

export const getOfferMessagesLastThreadIdSelector = createSelector(
  getOfferMessagesStateSelector,
  getTransactionSelector,
  ({ statePerTransaction }, { transaction: currentTransaction }) =>
    statePerTransaction?.[currentTransaction?.Id]?.lastThreadId,
);

export const getOfferMessagesSelector = createSelector(
  getCurrentOfferMessagesThreadIdSelector,
  getCurrentOfferThreadSelector,
  getCurrentOfferMessagesInfoSelector,
  getThreadsListSelector,
  getUserId,
  (currentThreadId, currentThread, messages, threadsList, userId) => {
    const handledCurrentThread = {
      ...(currentThread || {}),
      Participants: orderBy(
        (currentThread?.Participants || []).filter((participant) => participant?.Id !== userId),
        ['FirstName'],
        ['asc'],
      ),
    };

    return {
      currentThreadId,
      currentThread: handledCurrentThread,
      messages,
      threadsList,
      userId,
    };
  },
);

export const getUnreadCountOfferMessagesSelector = createSelector(
  getTransactionThreadsListSelector,
  (transactionThreadsList) => {
    const count = transactionThreadsList.reduce((acc, cur) => {
      if (cur?.IsUnread) {
        return acc + 1;
      }
      return acc;
    }, 0);

    return {
      unreadMessagesCount: count,
    };
  },
);

export const getOfferMessagesParamsSelector = createSelector(
  localState,
  ({ isNewMessage, isAddParticipants }) => ({ isNewMessage, isAddParticipants }),
);
