import { Grid } from 'antd';
import { useState } from 'react';

import { Input } from 'components';
import { Col, Row } from 'components-antd';
import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';

import styles from './styles.module.scss';

const { useBreakpoint } = Grid;

interface LocalHeaderProps {
  Filter: any;
  onChangeSearch: (value: string) => void;
}
export const LocalHeader = ({ Filter, onChangeSearch }: LocalHeaderProps) => {
  const { xs } = useBreakpoint();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Row
      className={styles.transactionActivityHeader}
      style={xs ? { justifyContent: 'center', marginRight: '0px' } : {}}
    >
      <Col span={8} className={styles.heading}>
        Activity
      </Col>
      <Col span={16} className={styles.filterColumn}>
        {isExpanded ? (
          <Input
            type="search"
            variant={Input.FULL}
            name="clients"
            value={''}
            onChange={(event, inputVal) => {
              onChangeSearch(inputVal);
            }}
            placeholder="Search"
            testid="search_activity"
            inputHolderClassName={styles.searchInputHolder}
            className={styles.searchInput}
          />
        ) : (
          <Icon variant={Icon.SEARCH} className={styles.icon} onClick={() => setIsExpanded(true)} />
        )}
        <Filter />
      </Col>
    </Row>
  );
};
