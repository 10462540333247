import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Wrapper as PendingWrapper } from 'components';
import { getAgentClientsSelector, getClientFavoritePropsSelector } from 'store/selectors/feed';
import {
  getClientFavoritesPropsEffect,
  getAgentClientsWithFavoritesCountEffect,
  resetClientFavoritesPropsEffect,
  setActiveTabEffect,
} from 'store/effects';
import { LocationService } from 'services';
import { PENDING, IDLE } from 'settings/constants/apiState';
import { propertiesTabsIds } from 'settings/constants/properties';
import { PropertiesWrapper, PropertiesList } from 'pages/Properties/Feed/components';
import UserInfo from './UserInfo';
import { ListSkeleton } from 'components-antd';

import styles from './styles.module.scss';

const locationService = new LocationService();

const AgentClientFavoritesProps = () => {
  const dispatch = useDispatch();
  const clientFavoritesProps = useSelector(getClientFavoritePropsSelector);
  const agentClients = useSelector(getAgentClientsSelector);
  const location = useLocation();

  const query = locationService.setLocation(location).getQuery();

  useEffect(() => {
    dispatch(setActiveTabEffect(propertiesTabsIds.clientFavorites));
    if (clientFavoritesProps.state === IDLE) {
      dispatch(getClientFavoritesPropsEffect({ userId: query?.clientId }));
    }
    if (agentClients.state === IDLE) {
      dispatch(getAgentClientsWithFavoritesCountEffect({}));
    }

    return () => dispatch(resetClientFavoritesPropsEffect());
  }, []); // eslint-disable-line

  const isPending = clientFavoritesProps.state === PENDING;

  return (
    <div>
      <UserInfo />
      <PropertiesWrapper className={styles.scrollWrapper}>
        {(wrapperSize, getNextPageIsPending) => (
          <PendingWrapper
            isPending={isPending}
            className={styles.pendingWrapper}
            customLoader={<ListSkeleton />}
          >
            <PropertiesList
              propertyContainerClassName={styles.propertyContainerClassName}
              propertyListWrapperClassName={styles.propertyListWrapperClassName}
              effect={(cfg) => getClientFavoritesPropsEffect({ ...cfg, userId: query?.clientId })}
              list={clientFavoritesProps?.data}
              wrapperSize={wrapperSize}
              getNextPageIsPending={getNextPageIsPending}
              isClient
            />
          </PendingWrapper>
        )}
      </PropertiesWrapper>
    </div>
  );
};

export default AgentClientFavoritesProps;
