import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get, orderBy } from 'lodash-es';
import { requestGetTimelineTemplateDashboardAction } from 'store/actions/adminPanel';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  sort: {
    order: 'asc',
    fields: ['Title'],
  },
  search: {
    title: '',
    serviceAreas: [],
  },
  meta: {},
};

export default handleActions(
  {
    [requestGetTimelineTemplateDashboardAction]: (state, { payload }) => {
      let data = get(payload, 'data.result', initialData.data);

      if (data?.Milestones?.length) {
        data = {
          ...data,
          Milestones: orderBy(data.Milestones, 'Offset', ['asc']),
        };
      }

      return {
        ...state,
        state: payload.state,
        data,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
