export enum OfferStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Withdrawn = 'Withdrawn',
  CounterSent = 'CounterSent',
  CounterReceived = 'CounterReceived',
  Expired = 'Expired',
  Void = 'Void',
  Closed = 'Closed',
  Cancelled = 'Cancelled',
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  Initial = 'Initial',
}

export enum ActiveOfferStatus {
  Pending = OfferStatus.Pending,
  Withdrawn = OfferStatus.Withdrawn,
  CounterSent = OfferStatus.CounterSent,
  CounterReceived = OfferStatus.CounterReceived,
}

export enum ArchivedOfferStatus {
  Accepted = OfferStatus.Accepted,
  Rejected = OfferStatus.Rejected,
  Withdrawn = OfferStatus.Withdrawn,
  Closed = OfferStatus.Closed,
  Expired = OfferStatus.Expired,
  Cancelled = OfferStatus.Cancelled,
}
