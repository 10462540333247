import React from 'react';

import moment from 'moment';
import classNames from 'classnames';

import { Icons } from 'pages/Workshop/Icons';
import { subtractTimeZone } from 'helpers';
import { isBeforeToday, isToday, isYesterday } from 'helpers/date';

import styles from './styles.module.scss';
import { Button } from 'components-antd';

export function tableColumns(onViewOffer) {
  const getStatusClassName = (val) => {
    switch (val) {
      case 'New':
        return styles.new;
      case 'Pending':
        return styles.inProgress;
      default:
        return '';
    }
  };

  const columns = [
    {
      title: 'Transaction',
      key: 'transaction',
      dataIndex: 'Transaction',
      width: 500,
      sorter: (a, b) => {
        const aAddress = a?.Property?.Address?.Line1?.toLowerCase();
        const bAddress = b?.Property?.Address?.Line1?.toLowerCase();
        if (aAddress > bAddress) return 1;
        if (aAddress < bAddress) return -1;
        return 0;
      },
      render: (text, { Property }) => {
        const address = Property?.Property?.Address;
        return (
          <div className={styles.transactionContentWrapper}>
            {!address?.Line1 ? (
              <span>{'-'}</span>
            ) : (
              <>
                <div>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16" cy="16" r="16" fill="#D6D6D7" fillOpacity="0.25" />
                    <path
                      d="M17.6665 8.5V11.8333C17.6665 12.0543 17.7543 12.2663 17.9106 12.4226C18.0669 12.5789 18.2788 12.6667 18.4998 12.6667H21.8332"
                      stroke="#515151"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.1665 23.5H11.8332C11.3911 23.5 10.9672 23.3244 10.6547 23.0118C10.3421 22.6993 10.1665 22.2754 10.1665 21.8333V10.1667C10.1665 9.72464 10.3421 9.30072 10.6547 8.98816C10.9672 8.67559 11.3911 8.5 11.8332 8.5H17.6665L21.8332 12.6667V21.8333C21.8332 22.2754 21.6576 22.6993 21.345 23.0118C21.0325 23.3244 20.6085 23.5 20.1665 23.5Z"
                      stroke="#515151"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.5 13.5H14.3333"
                      stroke="#515151"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.5 16.834H18.5"
                      stroke="#515151"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.5 20.166H18.5"
                      stroke="#515151"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className={styles.transactionContent}>
                  <span className={styles.transactionContentFirstRow}> {address?.Line1} </span>
                  <span className={styles.contentSecondRow}>
                    {`${address?.City}, ${address?.State} ${address?.Zip}`}
                  </span>
                </div>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'Status',
      width: 100,
      render: (_, row) => {
        const Status = row?.OfferStatus;
        const isViewed = !row?.OfferIteration?.[0]?.IsViewed;
        return (
          <div
            className={classNames(styles.statusOnly, getStatusClassName(isViewed ? 'New' : Status))}
            testid="offer_status"
          >
            <span className={styles.statusText}>{isViewed ? 'New' : Status}</span>
          </div>
        );
      },
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'Amount',
      sorter: (a, b) =>
        Number(a?.OfferIteration[0]?.PurchasePrice) - Number(b?.OfferIteration[0]?.PurchasePrice),
      render: (_, row) => {
        const text = row?.OfferIteration[0]?.PurchasePrice;

        return Number(text).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
        });
      },
    },
    {
      title: 'Expires',
      key: 'dueDate',
      dataIndex: 'DueDate',
      width: 148,
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        moment(a?.OfferIteration[0]?.OfferExpiryEndDate).unix() -
        moment(b?.OfferIteration[0]?.OfferExpiryEndDate).unix(),
      render: (_, row) => {
        let DueDate = row?.OfferIteration[0]?.OfferExpiryEndDate;
        let dueDate = DueDate ? subtractTimeZone(DueDate, 'M/D/YYYY') : '-';
        let isPrimaryDate = false;

        if (isToday(dueDate)) {
          dueDate = 'Today';
          isPrimaryDate = true;
        }
        if (isYesterday(dueDate)) {
          dueDate = 'Yesterday';
          isPrimaryDate = true;
        }
        if (isBeforeToday(dueDate)) {
          isPrimaryDate = true;
        }

        return (
          <span className={classNames({ [styles.datePrimary]: isPrimaryDate })}>{dueDate}</span>
        );
      },
    },

    {
      title: '',
      key: '',
      dataIndex: '',
      width: 75,
      render: (_, row) => {
        return (
          <div testid="offer_view_btn" onClick={() => {}} className={styles.viewBtn}>
            <Button
              variant="bordered-text-only"
              className={styles.signBtn}
              onClick={() => onViewOffer(row)}
            >
              <span>View</span>
            </Button>
          </div>
        );
      },
    },
  ];

  return columns;
}
