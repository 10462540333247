import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const ThirdParty = memo(({ className }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 14.0004C10.3259 14.333 10.7148 14.5972 11.1441 14.7776C11.5734 14.958 12.0344 15.0509 12.5 15.0509C12.9656 15.0509 13.4266 14.958 13.8559 14.7776C14.2852 14.5972 14.6741 14.333 15 14.0004L19 10.0004C19.663 9.33734 20.0355 8.43806 20.0355 7.50038C20.0355 6.5627 19.663 5.66342 19 5.00038C18.337 4.33734 17.4377 3.96484 16.5 3.96484C15.5623 3.96484 14.663 4.33734 14 5.00038L13.5 5.50038"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.0004 9.99973C13.6745 9.66713 13.2855 9.4029 12.8563 9.22252C12.427 9.04213 11.966 8.94922 11.5004 8.94922C11.0347 8.94922 10.5738 9.04213 10.1445 9.22252C9.71523 9.4029 9.32626 9.66713 9.00038 9.99973L5.00038 13.9997C4.33734 14.6628 3.96484 15.562 3.96484 16.4997C3.96484 17.4374 4.33734 18.3367 5.00038 18.9997C5.66342 19.6628 6.5627 20.0353 7.50038 20.0353C8.43806 20.0353 9.33734 19.6628 10.0004 18.9997L10.5004 18.4997"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 21V19"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 16H21"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 8H5"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 3V5"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

export default ThirdParty;
