import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';

import { Input, Row, Col, Popover } from 'components-antd';
import { SearchFilter } from 'pages/FormBuilder/components';
import FilterIcon from 'pages/FormBuilder/Icons/Filter';
import { updateFormListSearchEffect } from 'store/effects/formBuilder';

import styles from './styles.module.scss';

export const SearchBar = () => {
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateFormListSearchEffect({ searchText: searchText ? searchText : undefined }));
  };

  const [searchText, setSearchText] = useState('');
  return (
    <Row>
      <Col span={22}>
        <form onSubmit={handleSubmit}>
          <Input
            size="large"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className={styles.formSearchBox}
            placeholder="Search Forms"
            prefix={<SearchOutlined onClick={handleSubmit} />}
          />
        </form>
      </Col>
      <Col span={2}>
        <FilterButton />
      </Col>
    </Row>
  );
};

const FilterButton = () => (
  <span className={styles.filterButton}>
    <Popover
      getPopupContainer={(triggerNode) => triggerNode}
      placement="bottomRight"
      content={<SearchFilter />}
      trigger="click"
    >
      <Row justify="end" align="middle" className={classNames(styles.formFilterRow, 'show-cursor')}>
        <Col>
          <FilterIcon className={styles.formFilterIcon} />
        </Col>
        <Col className={classNames(styles.formSearchFilter, 'prevent-user-select')}>Filter</Col>
      </Row>
    </Popover>
  </span>
);
