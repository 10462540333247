import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get, cloneDeep } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetAgentDetailsAction,
  updateAgentProfileAction,
  updateAgentActiveStatusAction,
  updateAgentRoleAction,
} from 'store/actions/agentDetail';

const initialData = {
  state: IDLE,
  data: {},
  meta: {},
};

export default handleActions(
  {
    [requestGetAgentDetailsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data'),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [updateAgentProfileAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: {
        ...state.data,
        Title: get(payload, 'data.Title'),
      },
    }),
    [updateAgentActiveStatusAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: {
        ...state.data,
        IsActive: get(payload, 'data.IsActive'),
      },
    }),
    [updateAgentRoleAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: {
        ...state.data,
        TeamRole: get(payload, 'data.TeamRole'),
      },
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
