import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Name = (props) => {
  const { children, className, testid, onClick } = props;

  return (
    <div testid={testid} className={classNames(styles.name, className)} onClick={onClick}>
      {children}
    </div>
  );
};

Name.propTypes = {
  className: PropTypes.string,
  testid: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

Name.defaultProps = {
  className: '',
  testid: undefined,
  onClick: () => {},
};

export default Name;
