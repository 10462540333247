import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components-antd';
import { Plus } from 'components/Icons';
import Commute from './Commute';

import { Row, Col } from 'antd';

import styles from './styles.module.scss';

const Commutes = ({ values, onDelete, onClose, onClick, className }) => {
  if (values?.length < 1) return null;

  return (
    <div className={classNames(styles.commutes, className)}>
      <Row gutter={[20, 20]} className={styles.commutesInner}>
        {values.map((commute, index) => (
          <Commute
            key={index} // eslint-disable-line
            {...commute}
            onDelete={() => onDelete(index)}
            onClick={() => onClick(index)}
          />
        ))}
        <Col xs={24} sm={12} lg={8}>
          <div className={classNames(styles.addCommute)} onClick={() => onClick()}>
            <Plus className={styles.addIcon} />
            <p className={styles.addText}>Add Another Commute</p>
          </div>
        </Col>
      </Row>
      <div className={styles.bottom}>
        <Button
          testid="commute_continue"
          onClick={() => onClose({ save: true })}
          className={styles.saveButton}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

Commutes.propTypes = {
  className: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({})),
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

Commutes.defaultProps = {
  className: '',
  values: [],
};

export default Commutes;
