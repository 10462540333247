import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash-es';

import { getSearchCriteriaFormattedString } from 'helpers/formatters';

import SearchBlockActions from '../SearchBlockActions';
import { SearchIcon } from 'components/Icons';

import styles from './styles.module.scss';

const SearchCard = ({
  title,
  status,
  agentName,
  info,
  footer,
  withActions,
  infoClassName,
  className,
  onClick,
  onEdit,
  onSearch,
  onRename,
  onDelete,
  onInactive,
  onActivate,
  photos,
  testid,
  view,
}) => {
  const deleteHandler = useCallback(() => {
    onDelete();
  }, [onDelete]);

  const editHandler = useCallback(() => {
    onEdit();
  }, [onEdit]);

  const searchHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onSearch();
    },
    [onSearch],
  );

  const inactiveHandler = useCallback(() => {
    onInactive();
  }, [onInactive]);

  const activateHandler = useCallback(() => {
    onActivate();
  }, [onActivate]);

  return (
    <div onClick={onClick} className={styles.containerActiveSearch}>
      <div className={styles.images}>
        {photos?.length ? (
          map(photos, (link, index) => (
            <img key={index} alt="Search images" src={`${link}?width=${index === 0 ? 200 : 100}`} />
          ))
        ) : (
          <SearchIcon className={styles.noImages} />
        )}
      </div>
      <div className={styles.searchName}>
        {title ? (
          <>
            <div className={styles.searchTitle}>
              {title} {status === 'Inactive' && <span className={styles.inactive}>Inactive</span>}
            </div>
          </>
        ) : (
          <a>&nbsp;</a>
        )}
        {info ? (
          <div testid="search_info" className={styles.descInfo}>
            {getSearchCriteriaFormattedString(info)?.info}
          </div>
        ) : (
          <div className={styles.emptyDescInfo}></div>
        )}
        {footer && view === 'expanded' && <div className={styles.footer}>{footer}</div>}
      </div>
      <div className={styles.menuActions}>
        {withActions && (
          <SearchBlockActions
            onDelete={onDelete && deleteHandler}
            onSearch={onSearch && searchHandler}
            onRename={onRename}
            searchName={title}
            onEdit={editHandler}
            onInactive={status !== 'Inactive' && inactiveHandler}
            clientProfileDrawer={true}
          />
        )}
      </div>
    </div>
  );
};

SearchCard.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  status: PropTypes.string,
  agentName: PropTypes.string,
  info: PropTypes.string,
  footer: PropTypes.string,
  className: PropTypes.string,
  infoClassName: PropTypes.string,
  testid: PropTypes.string,
  withActions: PropTypes.bool,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onSearch: PropTypes.func,
  onRename: PropTypes.func,
  searchName: PropTypes.string,
  onDelete: PropTypes.func,
  onInactive: PropTypes.func,
  onActivate: PropTypes.func,
};

SearchCard.defaultProps = {
  photos: null,
  withActions: true,
  className: '',
  infoClassName: '',
  testid: undefined,
  title: '',
  agentName: '',
  status: '',
  info: '',
  footer: '',
  onClick: null,
  searchName: '',
  onEdit: () => {},
  onSearch: () => {},
  onRename: () => {},
  onDelete: () => {},
  onActivate: () => {},
  onInactive: () => {},
};

export default SearchCard;
