import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Spinner } from 'components';

import styles from './styles.module.scss';

const Close = ({ onClick, Icon, variant, color, isPending }) => (
  <div className={styles.buttonWrap}>
    <button testid="modal_close" onClick={onClick} className={styles.close}>
      {isPending ? (
        <div className={styles.spinnerContainer}>
          <Spinner loaderClassName={classNames(styles.loaderInverseColor)} />
        </div>
      ) : (
        <Icon className={styles.icon} variant={variant} color={color} colorWithHash={color} />
      )}
    </button>
  </div>
);

Close.propTypes = {
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.node,
};

export default Close;
