import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getDashboardPartnerTasksStats } from 'api/dashboard';

import { Tabs } from 'antd';
import { Card } from '../Card';
import { Tasks } from './Tasks/Tasks';

import styles from './styles.module.scss';

export const TasksWidget = () => {
  const history = useHistory();

  const [assignedToYou, setAssignedToYou] = useState<any>([]);
  const [assignedByYou, setAssignedByYou] = useState<any>([]);

  const fetchPartnerTasksStats = async () => {
    const stats = await getDashboardPartnerTasksStats();
    setAssignedToYou(stats?.data?.assignedToYou || []);
    setAssignedByYou(stats?.data?.assignedByYou || []);
  };

  useEffect(() => {
    fetchPartnerTasksStats();
  }, []);

  const handleTasks = () => {
    history.push({
      pathname: `/workshop/tasks`,
    });
  };

  return (
    <Card
      cardTitle="Tasks"
      onClickHandler={handleTasks}
      isClickable={assignedToYou.length || assignedByYou.length > 0 ? true : false}
      showFooter={true}
      showHeader={true}
    >
      <Tabs defaultActiveKey="1" className={styles.tasksTab}>
        <Tabs.TabPane tab="Assigned To You" key="1">
          {assignedToYou?.map((item, idx) => {
            return (
              <Tasks
                key={idx}
                transactionId={item?.TransactionId}
                taskId={item?.Id}
                avatar={item?.Assignor?.AvatarUrl}
                taskTitle={item?.Title}
                taskDescription={item?.Description}
                dueDate={item?.DueDate}
                taskStatus={item?.Status}
                tabGroup={'assignedToYou'}
              />
            );
          })}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Assigned By You" key="2">
          {assignedByYou?.map((item, idx) => {
            return (
              <Tasks
                key={idx}
                transactionId={item?.TransactionId}
                taskId={item?.Id}
                avatars={item?.AssigneeList}
                taskTitle={item?.Title}
                taskDescription={item?.Description}
                dueDate={item?.DueDate}
                taskStatus={item?.Status}
                tabGroup={'assignedByYou'}
              />
            );
          })}
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};
