import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DrawerHeaderTitle, DrawerCancel } from 'components';
import Icon from 'pages/Properties/Feed/Icons/index';

import styles from './styles.module.scss';

const Header = ({ title, className, onClose }) => (
  <div className={classNames(styles.header, className)}>
    <div className={styles.titleWrapper}>
      <Icon className={styles.drawerHeaderIcon} variant={Icon.FILTER} />
      <DrawerHeaderTitle>{title}</DrawerHeaderTitle>
    </div>
    <DrawerCancel onClick={onClose} />
  </div>
);

Header.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

Header.defaultProps = {
  className: '',
  onClose: () => {},
  title: 'Search Criteria',
};

export default Header;
