import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const SubTitle = ({ className, children }) => (
  <p className={classNames(styles.subtitle, className)}>{children}</p>
);

SubTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
};

SubTitle.defaultProps = {
  className: '',
};

export default SubTitle;
