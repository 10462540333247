import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { Area, AreaConfig } from '@ant-design/plots';
import { ClientAddress } from 'app-constants';

import styles from './styles.module.scss';
import moment from 'moment';
import { ArrowDown, ArrowUp } from './icons';

type Props = {
  address: ClientAddress;
  wrapperClassname?: string;
  valuationData: any;
};

const getConfig = (data: any) =>
  ({
    data,
    xField: 'day',
    yField: 'value',
    color: '#928CDA',
    responsive: true,
    autoFit: true,
    height: 80,
    padding: [20, 0],
    smooth: true,
    areaStyle: {
      fill: 'l(270) 0:#4673D100 1:#4673D1',
    },
    xAxis: false,
    yAxis: false,
    tooltip: {
      showTitle: false,
      showMarkers: true,
      // itemTpl: '<li>{value}</li>',
    },
  } as AreaConfig);

export const AddressDetails: React.FC<Props> = ({ address, wrapperClassname, valuationData }) => {
  const [addressValuationData, setAddressValuationData] = React.useState<any>(null);
  const [rentalValuationData, setRentalValuationData] = React.useState<any>(null);

  const neighborhoodActivityCount = useMemo(
    () =>
      valuationData?.neighborhoodActivity?.activeListings?.cumulativeCount ||
      0 + valuationData?.neighborhoodActivity?.newListings?.cumulativeCount ||
      0 + valuationData?.neighborhoodActivity?.priceChanges?.cumulativeCount ||
      0 + valuationData?.neighborhoodActivity?.recentlySold?.cumulativeCount ||
      0,
    [valuationData],
  );

  useEffect(() => {
    if (valuationData) {
      if (valuationData?.addressValuation.valuations?.length > 0) {
        const addressData = valuationData?.addressValuation.valuations.map((valuation) => ({
          day: moment(valuation.UpdatedDate).format('DD MMM YYYY'),
          value: valuation.Valuation || 0,
        }));
        setAddressValuationData(addressData);
      }
      if (valuationData?.rentalValuation.valuations?.length > 0) {
        const rentalData = valuationData?.rentalValuation.valuations.map((valuation) => ({
          day: moment(valuation.UpdatedDate).format('DD MMM YYYY'),
          value: valuation.Valuation || 0,
        }));
        setRentalValuationData(rentalData);
      }
    }
  }, [valuationData]);

  return (
    <div className={classNames(styles.addressWrapper, wrapperClassname)}>
      <div className={styles.header}>
        <div className={styles.title}>{address.addressParsed?.Line1}</div>
        <div className={styles.subtitle}>
          {address.addressParsed?.City ? address.addressParsed.City + ',' : null}{' '}
          {address.addressParsed?.State} {address.addressParsed?.Zip}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.plotContainer}>
          <>
            <div className={styles.title}>Estimated Home Value</div>
            <div className={classNames(styles.subtitle, styles.purple)}>
              {valuationData?.addressValuation.recentValuation
                ? '$' +
                  Number(valuationData?.addressValuation.recentValuation).toLocaleString('en-US', {
                    currency: 'USD',
                    maximumFractionDigits: 0,
                  })
                : '-'}
              <span
                className={classNames({
                  [styles.decrease]: valuationData?.addressValuation.depreciationPercentWise > 0,
                  [styles.increase]: valuationData?.addressValuation.appreciationPercentWise > 0,
                })}
              >
                {valuationData?.addressValuation.depreciationPercentWise > 0 && (
                  <div className={styles.percentage}>
                    <ArrowDown />-
                    {parseFloat(
                      valuationData?.addressValuation.depreciationPercentWise || 0,
                    ).toFixed(2)}
                    %
                  </div>
                )}
                {valuationData?.addressValuation.appreciationPercentWise > 0 && (
                  <div>
                    <ArrowUp />+
                    {parseFloat(valuationData?.addressValuation.appreciationPercentWise).toFixed(2)}
                    %
                  </div>
                )}
              </span>
            </div>
            {addressValuationData && (
              <div className={styles.plot}>
                <Area
                  className={styles.areaGraphPadding}
                  {...getConfig(addressValuationData)}
                ></Area>
              </div>
            )}
          </>
        </div>
        <div className={styles.plotContainer}>
          <>
            <div className={styles.title}>Estimated Rental Value</div>
            <div className={classNames(styles.subtitle, styles.aqua)}>
              {valuationData?.rentalValuation.recentValuation
                ? '$' +
                  Number(valuationData?.rentalValuation.recentValuation).toLocaleString('en-US', {
                    currency: 'USD',
                    maximumFractionDigits: 0,
                  })
                : '-'}
              <span
                className={classNames({
                  [styles.decrease]: valuationData?.rentalValuation.depreciationPercentWise > 0,
                  [styles.increase]: valuationData?.rentalValuation.appreciationPercentWise > 0,
                })}
              >
                {valuationData?.rentalValuation.depreciationPercentWise > 0 && (
                  <div className={styles.percentage}>
                    <ArrowDown />-
                    {parseFloat(
                      valuationData?.rentalValuation.depreciationPercentWise || 0,
                    ).toFixed(2)}
                    %
                  </div>
                )}
                {valuationData?.rentalValuation.appreciationPercentWise > 0 && (
                  <div>
                    <ArrowUp />+
                    {parseFloat(valuationData?.rentalValuation.appreciationPercentWise).toFixed(2)}%
                  </div>
                )}
              </span>
            </div>
            {rentalValuationData && (
              <div>
                <Area
                  className={styles.areaGraphPadding}
                  {...getConfig(rentalValuationData)}
                ></Area>
              </div>
            )}
          </>
        </div>
      </div>
      <div className={styles.activityWrapper}>
        <div className={styles.activityCard}>
          <div className={styles.activityCardTitle}>Neighborhood Activity</div>
          <div className={classNames(styles.activityCardContent, styles.orangeTitle)}>
            {neighborhoodActivityCount}
          </div>
        </div>
        <div className={styles.activityCard}>
          <div className={styles.activityCardTitle}>Reminders</div>
          <div className={classNames(styles.activityCardContent, styles.redTitle)}>-</div>
        </div>
        <div className={styles.activityCard}>
          <div className={styles.activityCardTitle}>New Intel</div>
          <div className={classNames(styles.activityCardContent, styles.greenTitle)}>-</div>
        </div>
      </div>
    </div>
  );
};
