import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useOutsideClick } from 'hooks';
import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import { FILE_TYPES } from 'settings/constants/common';
import { Wrapper as PendingWrapper, OptionsPopup } from 'components';
import styles from './styles.module.scss';

const Actions = (props) => {
  const { className, file, onDownloadClick, onImagePreviewClick, onPdfPreviewClick } = props;
  const [open, setOpen] = useState(false);

  const [isPending, setIsPending] = useState(false);
  const actionsRef = useRef();
  const buttonRef = useRef();
  const popupRef = useRef();

  useOutsideClick([actionsRef, buttonRef, popupRef], () => setOpen(false));

  const onClick = () => {
    setOpen(!open);
  };

  const onPreview = () => {
    if (isPDFAttachment()) {
      onPdfPreviewClick(true);
    } else if (isImageAttachment()) {
      onImagePreviewClick(true);
    }
    setOpen(false);
  };

  const onDownload = () => {
    onDownloadClick();
    setOpen(false);
  };

  const getAttachmentType = useCallback((attachment) => {
    const [type] = attachment?.ContentType?.split('/') || [];
    return type;
  }, []);

  const isPDFAttachment = () => {
    return file?.ContentType === FILE_TYPES.PDF;
  };

  const isImageAttachment = () => {
    return getAttachmentType(file) === 'image';
  };

  return (
    <PendingWrapper
      isPending={isPending}
      spinnerClassname={styles.spinnerWrapper}
      loaderClassName={styles.loader}
    >
      <div testid="doc_actions" ref={actionsRef} className={classNames(styles.actions, className)}>
        <Icon ref={buttonRef} onClick={onClick} variant={Icon.ACTIONS_DOTS} />
        <OptionsPopup
          className={classNames(styles.attachmentOptions, styles.options)}
          ref={popupRef}
          offsetLeft={-70}
          parentRef={buttonRef}
          open={open}
        >
          <div>
            <ul className={styles.list}>
              {(isPDFAttachment() || isImageAttachment()) && file?.Url && (
                <li testid="preview" onClick={onPreview} className={styles.item}>
                  Preview
                </li>
              )}
              <li testid="download" onClick={onDownload} className={styles.item}>
                Download
              </li>
            </ul>
          </div>
        </OptionsPopup>
      </div>
    </PendingWrapper>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  file: PropTypes.shape({
    ContentType: PropTypes.string,
    Url: PropTypes.string,
    Filename: PropTypes.string,
    Size: PropTypes.number,
    SendDate: PropTypes.string,
    SendTime: PropTypes.string,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
  }).isRequired,
  onPdfPreviewClick: PropTypes.func,
  onImagePreviewClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
};

Actions.defaultProps = {
  className: '',
  onPdfPreviewClick: () => {},
  onImagePreviewClick: () => {},
  onDownloadClick: () => {},
};

export default Actions;
