import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ adminPanel }) => adminPanel;

const timelineTemplatesState = createSelector(
  localState,
  ({ timelineTemplates }) => timelineTemplates,
);

const timelineTemplateState = createSelector(
  localState,
  ({ timelineTemplate }) => timelineTemplate,
);

export const getTimelineTemplatesDashboardSelector = createSelector(
  timelineTemplatesState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTimelineTemplatesDataDashboardSelector = createSelector(
  timelineTemplatesState,
  (state) => get(state, 'data'),
);

export const getTimelineTemplateDashboardSelector = createSelector(
  timelineTemplateState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTimelineTemplateDataDashboardSelector = createSelector(
  timelineTemplateState,
  (state) => ({
    title: get(state, 'data.Name') || '',
    description: get(state, 'data.Description') || '',
    areasOfOperation: get(state, 'data.AreasOfOperation') || '',
    milestones: (get(state, 'data.Milestones') || []).map(({ Title, Offset }) => ({
      Title,
      DueDate: Offset,
    })),
    id: get(state, 'data.Id'),
  }),
);

export const getTimelineTemplatesSearchSelector = createSelector(
  timelineTemplatesState,
  ({ search }) => search,
);

export const getTimelineTemplatesSortSelector = createSelector(
  timelineTemplatesState,
  ({ sort }) => sort,
);
