import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import { getListOfAvailableMlsServices, MlsService } from 'api/mls';
import { setSelectedMlsServicesForAgent } from 'api/user';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import { setMlsAccessModalAction } from 'store/actions/mlsAccess';
import { setAgentSelectedMlsServiceAction } from 'store/actions/user';
import {
  isMlsAccessModalOpenedSelector,
  showMlsAccessModalOnboardingTextSelector,
} from 'store/selectors/mlsAccess';
import { getUserDataSelector } from 'store/selectors/user';

import { Button, Modal, Option } from 'components-antd';
import { MultiSelect } from 'components-antd/MultiSelect';
import { ArrowDown, MosaikLogo } from 'components/Icons';

import styles from './styles.module.scss';

export const MlsAccessModal: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const isModalOpen = useSelector(isMlsAccessModalOpenedSelector);
  const showOnboardingText = useSelector(showMlsAccessModalOnboardingTextSelector);
  const [mlsServices, setMlsServices] = useState<MlsService[]>([]);
  const [selectedMlsServices, setSelectedMlsServices] = useState<string[]>([]);

  // Ideally this has to be backend-based check.
  // For now though this modal will be opened once after onboarding
  // useEffect(() => {
  //   if (user) {
  //     if (user.Roles?.includes(AGENT) && user.CompletedProfile) {
  //       const agent = user.Agent;
  //       if (!agent.SelectedMlsServices?.length) {
  //         dispatch(setMlsAccessModalAction(true));
  //       }
  //     }
  //   }
  // }, [user]);

  useEffect(() => {
    function handleError() {
      showErrorMessage("Cannot load list of available MLS'. Try to reload the page");
    }

    const runEffect = async () => {
      try {
        const response = await getListOfAvailableMlsServices();
        if (response.data?.success) {
          setMlsServices(response.data.result);
          prePopulateSelectedServices();
        } else {
          handleError();
        }
      } catch (err) {
        handleError();
      }
    };

    if (isModalOpen) {
      runEffect();
    }
  }, [isModalOpen]);

  const prePopulateSelectedServices = () => {
    if (user?.Agent?.SelectedMlsServices?.length) {
      setSelectedMlsServices(
        user.Agent.SelectedMlsServices.map((service) => service.MlsId || service.Id),
      );
    }
  };

  const handleSubmitClick = async () => {
    const response = await setSelectedMlsServicesForAgent({
      SelectedMlsServices: selectedMlsServices.map((mls) => +mls),
    });
    if (response.data) {
      dispatch(setAgentSelectedMlsServiceAction(response.data.result?.SelectedMlsServices));
    }
    dispatch(setMlsAccessModalAction(false));
  };

  const isDoneButtonDisabled = !selectedMlsServices.length;

  return (
    <>
      {isModalOpen && (
        <Modal
          open={!!isModalOpen}
          width={675}
          footer={null}
          closable={true}
          maskClosable={false}
          onCancel={() => {
            dispatch(setMlsAccessModalAction(false));
            //  To remove selected services which are not added
            prePopulateSelectedServices();
          }}
          destroyOnClose={true}
          title=" "
        >
          <div className={styles.layout}>
            {showOnboardingText && <MosaikLogo primary={false} />}
            {showOnboardingText ? (
              <h2 className={styles.stepHeader}>Welcome to Mosaik!</h2>
            ) : (
              <h2 className={styles.stepHeader}>Select MLS</h2>
            )}
            <div className={styles.text}>
              {showOnboardingText && (
                <p>
                  We can&apos;t wait to show you around. Your onboarding specialist will be in touch
                  within the next 24 hours.
                </p>
              )}
              {showOnboardingText ? (
                <p>
                  In the meantime, can you please let us know which MLS&apos;s you&apos;re a member
                  of, so we can start setting up your collaborative search functionality?
                </p>
              ) : (
                <p>
                  Please let us know which MLS&apos;s you&apos;re a member of, so we can start
                  setting up your collaborative search functionality.
                </p>
              )}
            </div>
            <div className={styles.selectorWrapper}>
              <MultiSelect
                size="large"
                values={selectedMlsServices}
                title=""
                placeholderText="Select MLS"
                onHandleChange={(values) => {
                  setSelectedMlsServices(values);
                }}
                optionFilterProp="children"
                propClass={styles.mlsDropdown}
                selectIcon={<ArrowDown />}
                getPopupContainer={(triggerNode) => triggerNode}
              >
                {mlsServices
                  .filter((mls) => mls.IsVisible)
                  .map((mls) => (
                    <Option key={mls.Id} value={mls.Id}>
                      {mls.Name}
                    </Option>
                  ))}
              </MultiSelect>
            </div>
            <Button
              disabled={isDoneButtonDisabled}
              variant={'secondary'}
              className={classNames(
                styles.continueButton,
                isDoneButtonDisabled && styles.continueButton_disabled,
              )}
              onClick={handleSubmitClick}
            >
              Done
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
