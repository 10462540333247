import { useJsApiLoader, useLoadScript } from '@react-google-maps/api';
import { useMemo } from 'react';

export const useLoadGoogleMaps = () => {
  const libraries = useMemo<
    ('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[]
  >(() => ['places', 'drawing'], []);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries,
    language: 'en',
  });

  return isLoaded;
};
