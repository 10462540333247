import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Wrapper as PendingWrapper } from 'components';
import Header from './Components/Header';
import { TeamsTable } from './Components/TeamsTable';
import { tableColumns } from './tableColumns';

import { getTeamSeatsLogEffect } from 'store/effects';
import {
  getTeamDetailsDashboardSelector,
  getTeamSeatsLogSelector,
} from 'store/selectors/adminPanel/teamDetails';

import styles from './styles.module.scss';

export const SeatsLogs = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { isPending, isIdle } = useSelector(getTeamDetailsDashboardSelector);
  const teamSeatLogsData = useSelector(getTeamSeatsLogSelector);
  const [seatLogsGroupBy, setSeatLogsGroupBy] = useState('added');
  const [seatLogs, setSeatLogs] = useState([]);
  const teamId = params?.id;

  useEffect(() => {
    dispatch(getTeamSeatsLogEffect({ teamId }));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (teamSeatLogsData) {
      let data = [];
      if (seatLogsGroupBy === 'added') {
        data = teamSeatLogsData?.filter((seatLog) => seatLog.IsAdded);
      } else {
        data = teamSeatLogsData?.filter((seatLog) => !seatLog.IsAdded);
      }
      setSeatLogs(data);
    }
  }, [teamSeatLogsData, seatLogsGroupBy]);

  const tableProps = useMemo(() => {
    return {
      columns: tableColumns(seatLogsGroupBy === 'added'),
      onRow: (record) => {
        return {
          onClick: (event) => {},
        };
      },
    };
  }, [seatLogsGroupBy]);

  return (
    <div>
      <Header
        title="Seats"
        seatLogsGroupBy={seatLogsGroupBy}
        setSeatLogsGroupBy={setSeatLogsGroupBy}
      />
      <PendingWrapper
        isPending={isPending}
        className={classNames({ [styles.pendingWrapper]: isPending })}
      >
        <TeamsTable dataSource={seatLogs ?? []} {...tableProps} />
      </PendingWrapper>
    </div>
  );
};

export default SeatsLogs;
