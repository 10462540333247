import { routes } from 'settings/navigation/routes';
import { Main } from 'pages';
import { CLIENT } from 'settings/constants/roles';

export default [
  {
    path: routes.clientDashboard,
    component: Main,
    exact: true,
    roles: [CLIENT],
  },
];
