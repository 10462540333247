import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { Icons } from '../../Icons/Icons';
import { Close as CloseIcon } from 'components/Icons';
import { isPropertyAddress } from 'store/effects/quotes/helpers';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';

import styles from './styles.module.scss';

export const Controls = ({
  stage,
  setStage,
  transaction,
  enableShowing,
  setShowingModal,
  enableOffer,
  setOfferModal,
  onClose,
}) => {
  const { preForm } = useSelector(getTransactionPreFormSelector);

  const onPrev = () => {
    if (isPrevDisabled()) return;

    switch (stage) {
      case transactionPreFormQuestionsIds.purchasePrice:
        setStage(transactionPreFormQuestionsIds.address);
        break;
      case transactionPreFormQuestionsIds.participants:
        setStage(transactionPreFormQuestionsIds.creatorRole);
        break;
      case transactionPreFormQuestionsIds.creatorRole:
        setStage(transactionPreFormQuestionsIds.purchasePrice);
        break;
      case transactionPreFormQuestionsIds.showingModal:
        setStage(transactionPreFormQuestionsIds.enableShowing);
        setShowingModal(true);
        break;
      case transactionPreFormQuestionsIds.offerModal:
        setStage(transactionPreFormQuestionsIds.enableOffer);
        setOfferModal(true);
        break;
    }
  };

  const onNext = () => {
    if (isNextDisabled()) return;

    switch (stage) {
      case transactionPreFormQuestionsIds.address:
        setStage(transactionPreFormQuestionsIds.purchasePrice);
        break;
      case transactionPreFormQuestionsIds.purchasePrice:
        setStage(transactionPreFormQuestionsIds.creatorRole);
        break;
      case transactionPreFormQuestionsIds.creatorRole:
        setStage(transactionPreFormQuestionsIds.participants);
        break;
      case transactionPreFormQuestionsIds.participants:
        setStage(transactionPreFormQuestionsIds.enableShowing);
        break;
      case transactionPreFormQuestionsIds.enableShowing:
        setStage(transactionPreFormQuestionsIds.showingModal);
        break;
      case transactionPreFormQuestionsIds.enableOffer:
        setStage(transactionPreFormQuestionsIds.offerModal);
        break;
    }
  };

  const isPrevDisabled = () => {
    return (
      stage === transactionPreFormQuestionsIds.address ||
      stage === transactionPreFormQuestionsIds.enableShowing ||
      stage === transactionPreFormQuestionsIds.enableOffer
    );
  };

  const isNextDisabled = () => {
    if (
      (stage === transactionPreFormQuestionsIds.address && !isPropertyAddress(preForm)) ||
      (stage === transactionPreFormQuestionsIds.purchasePrice && !preForm?.purchasePrice) ||
      (stage === transactionPreFormQuestionsIds.participants && !transaction?.Id) ||
      (stage === transactionPreFormQuestionsIds.enableShowing && !enableShowing) ||
      (stage === transactionPreFormQuestionsIds.enableOffer && !enableOffer)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.controls}>
      <Icons
        variant="arrowLeft"
        className={classnames(styles.iconBtn, styles.arrowLeft, {
          [styles.disabled]: isPrevDisabled(),
        })}
        onClick={onPrev}
      />
      <Icons
        variant="arrowRight"
        className={classnames(styles.iconBtn, styles.arrowRight, {
          [styles.disabled]: isNextDisabled(),
        })}
        onClick={onNext}
      />
      <CloseIcon onClick={onClose} className={styles.iconBtn} />
    </div>
  );
};

Controls.prototype = {
  onClose: PropTypes.func,
  stage: PropTypes.number,
  setStage: PropTypes.func,
  transaction: PropTypes.object,
  enableShowing: PropTypes.string,
  setShowingModal: PropTypes.func,
  enableOffer: PropTypes.string,
  setOfferModal: PropTypes.func,
};

Controls.defaultProps = {
  onClose: () => {},
  stage: 0,
  setStage: () => {},
  transaction: {},
  enableShowing: null,
  setShowingModal: () => {},
  enableOffer: null,
  setOfferModal: () => {},
};
