import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const ScrollWrapper = ({ className, children }) => (
  <div className={classNames(styles.scrollWrapper, className)}>{children}</div>
);

ScrollWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

ScrollWrapper.defaultProps = {
  className: '',
  children: undefined,
};

export default ScrollWrapper;
