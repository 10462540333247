import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { InputLabel } from 'components';
import Commute from './Commute';

import styles from './styles.module.scss';

const Commutes = ({ values, onDelete, onClick, className }) => {
  if (values?.length < 2) return null;

  return (
    <div className={classNames(styles.commutes, className)}>
      <InputLabel label="Saved Commutes" />
      {values.map((commute, index) => (
        <Commute
          key={index} // eslint-disable-line
          {...commute}
          onDelete={() => onDelete(index)}
          onClick={() => onClick(index)}
        />
      ))}
    </div>
  );
};

Commutes.propTypes = {
  className: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({})),
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

Commutes.defaultProps = {
  className: '',
  values: [],
};

export default Commutes;
