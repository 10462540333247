import styles from './styles.module.scss';
import { ReactNode } from 'react';
import Remove from 'components/Icons/Remove';

interface AnswerCardProps {
  children: ReactNode;
  onRemove?: () => void;
  remove?: boolean;
}

export const AnswerCard = ({ children, remove, onRemove }: AnswerCardProps) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.card}>{children}</div>
      {remove && (
        <div className={styles.remove} onClick={onRemove}>
          <Remove className={styles.icon} color={'#FF576D'} />
        </div>
      )}
    </div>
  );
};
