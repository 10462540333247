import { Share } from 'components/Icons';

import styles from './styles.module.scss';

export const SettingsExport = () => {
  return (
    <div className={styles.export}>
      <h1 className={styles.pageTitle}>Export</h1>
      <div className={styles.contentWrap}>
        <Share className={styles.icon} />
        <p className={styles.content}>
          Self-service data exports will be available soon. In the meantime, if you need a data
          export, please contact <a href="mailto:support@mosaik.io">support@mosaik.io</a> and
          we&apos;ll be happy to help.
        </p>
      </div>
    </div>
  );
};
