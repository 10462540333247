import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getTransactionSelector, getKeyDatesSelector } from 'store/selectors/transaction';
import styles from './styles.module.scss';
import { Row, Col } from 'antd';
import { Icons } from 'pages/Workshop/Icons';
import { isToday, isAfterToday, getDateOnly } from 'helpers';
import { NamesContainer } from 'pages/Workshop/Transactions/components/NamesContainer';
import { transactionStatuses } from 'settings/constants/transaction';

interface ProjectProps {
  className?: string;
}

const DetailField = ({ label, value }) => (
  <>
    <Col lg={8} className={styles.label}>
      {label}:
    </Col>
    <Col lg={16} className={styles.value}>
      {value}
    </Col>
  </>
);

const Project = ({ className }: ProjectProps) => {
  const { isPending, transaction } = useSelector(getTransactionSelector);
  const keyDates = useSelector(getKeyDatesSelector);

  const getCorrespondingDate = () => {
    const { Status } = transaction;
    const date =
      keyDates?.KickoffDate &&
      (isToday(keyDates?.KickoffDate) || isAfterToday(keyDates?.KickoffDate))
        ? keyDates?.KickoffDate
        : Status === transactionStatuses.Closed && keyDates?.CloseDate
        ? keyDates?.CloseDate
        : keyDates?.CompletionDate || keyDates?.ExpiredDate;

    return date ? getDateOnly(date, 'MMM DD, YYYY') : 'N/A';
  };

  const getCorrespondingLabel = () => {
    return keyDates?.KickoffDate &&
      (isToday(keyDates?.KickoffDate) || isAfterToday(keyDates?.KickoffDate))
      ? 'Kickoff'
      : 'Deadline';
  };

  return (
    <>
      <div className={styles.projectContainer}>
        <div className={classNames(styles.Project, className)}>
          <div>
            <Icons variant={Icons.PROJECT_OVERVIEW} className={classNames(styles.icon)} />
          </div>
          {!isPending && (
            <div testid="address" className={styles.address}>
              <div>{transaction?.Name}</div>
              <div className={styles.secondLabel}>{transaction?.TransactionCategory?.Category}</div>
            </div>
          )}
        </div>
        {!isPending && (
          <Row className={styles.projectDetails}>
            {[
              { label: 'Listing', value: transaction?.Status },
              {
                label: getCorrespondingLabel(),
                value: getCorrespondingDate(),
              },
              {
                label: 'Agent(s)',
                value: <NamesContainer names={transaction?.AgentsOrOwners} maxDisplayCount={2} />,
              },
            ].map((item, idx) => (
              <DetailField key={idx} {...item} />
            ))}
          </Row>
        )}
      </div>
      <div className={styles.divider} />
    </>
  );
};

export default Project;
