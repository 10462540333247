import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';
import { CLIENT } from 'settings/constants/roles';

interface OnBoardingContainerProps {
  className?: string;
  children: React.ReactNode;
}

export const OnBoardingContainer = ({ className, children }: OnBoardingContainerProps) => {
  const signUpRole = useSelector(getSignUpRoleSelector);

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.client]: signUpRole === CLIENT,
      })}
    >
      {children}
    </div>
  );
};
