import { handleActions } from 'redux-actions';
import { setSearchCriteriaAction, resetSearchCriteriaAction } from 'store/actions/search';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';
import {
  cleanSearchDrawnPolygonAction,
  setSearchDrawnPolygonAction,
  setSearchViewportCoordinatesAction,
  cleanSearchViewportCoordinatesAction,
  setSearchGoogleLocationAction,
} from 'store/actions/searchResults';
import { LocationType } from 'types';

const initialData = {};

export default handleActions(
  {
    [setSearchCriteriaAction]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [resetSearchCriteriaAction]: () => initialData,
    [setSearchGoogleLocationAction]: (state, { payload }) => {
      const locations = state.Locations;
      const locationsWithCustomShapes =
        locations?.filter((location) => location.Type === LocationType.Polygon) ?? [];

      const locationsWithViewport =
        locations?.filter((location) => location.Type === LocationType.ViewportCoordinates) ?? [];

      let newLocationsList = [];
      if (payload.length) {
        newLocationsList = [...payload];
      } else {
        newLocationsList = [...locationsWithCustomShapes, ...locationsWithViewport];
      }

      return {
        ...state,
        Locations: newLocationsList,
      };
    },
    [cleanSearchDrawnPolygonAction]: (state, { payload }) => {
      const locations = state.Locations;
      if (!locations) {
        return state;
      }
      let locationsWithoutPolygon = locations.filter(
        (location) => location.Type !== LocationType.Polygon,
      );

      if (payload?.length) {
        locationsWithoutPolygon = [...locationsWithoutPolygon, ...payload];
      }

      return {
        ...state,
        Locations: locationsWithoutPolygon.length ? locationsWithoutPolygon : undefined,
      };
    },
    [setSearchDrawnPolygonAction]: (state, { payload }) => {
      const polygonLocationObject = {
        Type: LocationType.Polygon,
        Polygon: payload,
      };

      const locations = state.Locations;

      const newLocationsList = [...locations, polygonLocationObject];

      return {
        ...state,
        Locations: newLocationsList,
      };
    },
    [setSearchViewportCoordinatesAction]: (state, { payload }) => {
      const viewPortCoordinatesObject = {
        Type: LocationType.ViewportCoordinates,
        Coordinates: payload,
      };

      const locations = state.Locations;
      const locationsWithoutViewportCoordinates =
        locations?.filter((location) => location.Type !== LocationType.ViewportCoordinates) ?? [];

      const newLocationsList = locationsWithoutViewportCoordinates
        ? [...locationsWithoutViewportCoordinates, viewPortCoordinatesObject]
        : [viewPortCoordinatesObject];

      return {
        ...state,
        Locations: newLocationsList,
      };
    },
    [cleanSearchViewportCoordinatesAction]: (state, { payload }) => {
      const locations = state.Locations;
      if (!locations) {
        return state;
      }
      const locationsWithoutViewportCoordinates = locations.filter(
        (location) => location.Type !== LocationType.ViewportCoordinates,
      );

      return {
        ...state,
        Locations: locationsWithoutViewportCoordinates,
      };
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
