import classNames from 'classnames';
import StripeProvider from 'pages/OnboardingV2Agent/ProAndGrowFlow/StripeCheckout/StripeProvider/StripeProvider';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PaywallCheckoutForm } from '../PaywallCheckout/PaywallCheckoutForm';
import styles from './styles.module.scss';
import { routes } from 'settings/navigation/routes';
import { CurrentActiveSubscriptionPlan } from 'pages/Paywall/types';
interface PaywallModalStep2Props {
  selectedPlan: string | null;
  planId: string;
  requestedSeatsCount: 2 | 3;
  isStandard: boolean;
  currentActivePlan: CurrentActiveSubscriptionPlan;
}

export const PaywallModalStep2: React.FC<PaywallModalStep2Props> = (props) => {
  const { selectedPlan, planId, isStandard, requestedSeatsCount, currentActivePlan } = props;
  const history = useHistory();
  const onNext = () => {
    history.push(`${routes.index}?showPaywallSuccess=true`);
  };
  return (
    <StripeProvider>
      <div className={classNames(styles.container)}>
        <PaywallCheckoutForm
          isStandard={isStandard}
          selectedPlan={selectedPlan}
          planId={planId}
          requestedSeatsCount={requestedSeatsCount}
          currentActivePlan={currentActivePlan}
          onNext={onNext}
        />
      </div>
    </StripeProvider>
  );
};
