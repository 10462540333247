import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { formatNumber, getFirstImage } from 'helpers';
import { getMatchedCriteriasList } from 'settings/constants/match';
import { formatOpenHouses, preparePriceForMarkup, openHouseSorter } from './feed';

const localState = ({ searchResults }) => searchResults;

export const getSearchResultsFilterDrawerSelector = createSelector(
  localState,
  ({ filterDrawer }) => filterDrawer,
);

export const getIsSearchResultsFilterDrawerOpenSelector = createSelector(
  localState,
  ({ filterDrawer }) => filterDrawer?.open,
);

export const getSearchResultsFilterDrawerModeSelector = createSelector(
  localState,
  ({ filterDrawer }) => filterDrawer?.mode,
);

export const getIsSearchesDrawerOpenSelector = createSelector(
  localState,
  ({ savedSearchesDrawer }) => savedSearchesDrawer?.open,
);

export const getAddEditSchoolOpenSelector = createSelector(
  localState,
  ({ addEditSchoolDrawer }) => addEditSchoolDrawer?.open,
);

export const getSchoolDataSelector = createSelector(
  localState,
  ({ addEditSchoolDrawer }) => addEditSchoolDrawer?.schoolData,
);
export const getSchoolRawDataSelector = createSelector(localState, ({ addEditSchoolDrawer }) => ({
  schoolTypes: addEditSchoolDrawer.schoolTypes ?? [],
  specificItems: addEditSchoolDrawer.specificItems ?? [],
}));

export const viewWishlistSelector = createSelector(
  localState,
  ({ addEditSchoolDrawer }) => addEditSchoolDrawer?.showWishlistTab,
);

export const getSearchesDrawerTypeSelector = createSelector(
  localState,
  ({ savedSearchesDrawer }) => savedSearchesDrawer?.type,
);

export const getSearchResultsSelector = createSelector(localState, ({ results }) => ({
  isIdle: results?.state === IDLE,
  isPending: results?.state === PENDING,
  properties: results?.data?.Properties?.map((prop) => ({
    ...prop,
    MatchedCriterias: getMatchedCriteriasList(prop),
    OpenHouses:
      prop?.OpenHouses?.map((openHouse) => {
        formatOpenHouses(openHouse);
      })
        .filter((openHouse) => {
          openHouse?.isActual;
        })
        .sort(function (a, b) {
          return openHouseSorter(a, b);
        }) || [],
  })),
  isAlreadySavedSearch: !!results?.data?.IsAlreadySavedSearch,
  totalCount: results?.data?.TotalCount,
}));

export const getSearchPageInfoSelector = createSelector(
  localState,
  (searchResults) => searchResults?.pageInfo,
);

export const getSearchResultsIsAlreadySavedSearch = createSelector(
  getSearchResultsSelector,
  ({ isAlreadySavedSearch }) => isAlreadySavedSearch,
);

export const getSearchResultsSortSelector = createSelector(
  localState,
  (searchResults) => searchResults?.sort,
);

export const getSearchResultsMarkersSelector = createSelector(localState, ({ results }) =>
  (results?.data?.Properties || [])
    .map((property) => {
      const {
        Id,
        Geolocation,
        PhotoUrls,
        SellingPrice,
        NumBathsTotal,
        NumBeds,
        SquareFeet,
        Address,
        IsFavorite,
        Media,
      } = property;
      const beds = NumBeds ? `${NumBeds} bd` : '';
      const baths = NumBathsTotal ? `${NumBathsTotal} ba` : '';
      const feet = SquareFeet ? `${formatNumber(SquareFeet)} ft` : '';
      const firstImage = getFirstImage(Media);
      return {
        id: Id,
        IsFavorite: IsFavorite,
        image: firstImage ? firstImage : PhotoUrls?.[0],
        price: SellingPrice,
        priceToDisplay: preparePriceForMarkup(SellingPrice),
        address: Address,
        NumBeds,
        NumBathsTotal,
        SquareFeet,
        geometry: {
          coordinates: { lng: Geolocation?.Long, lat: Geolocation?.Lat },
        },
        info: [beds, baths, feet].filter((val) => !!val).join(', '),
      };
    })
    .reduce((markersMap, marker) => {
      const {
        geometry: {
          coordinates: { lng, lat },
        },
      } = marker;

      if (typeof lng !== 'number' || typeof lat !== 'number') {
        return markersMap;
      }

      const key = `${lng} ${lat}`;

      return markersMap[key]?.length
        ? { ...markersMap, [key]: [...markersMap[key], marker] }
        : { ...markersMap, [key]: [marker] };
    }, {}),
);

export const getSearchDrawerCommuteIdSelector = createSelector(
  localState,
  ({ filterDrawer }) => filterDrawer?.commuteId,
);

export const getSearchResultsViewTypeSelector = createSelector(
  localState,
  ({ viewType }) => viewType,
);
