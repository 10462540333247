import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ template: { task } }) => task;

export const getTaskTemplateSelector = createSelector(localState, ({ state, meta, ...rest }) => ({
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!rest?.data,
  ...rest,
}));

export const getTaskTemplateDataSelector = createSelector(localState, (state) =>
  get(state, 'data'),
);

export const getTaskTemplateEditDataSelector = createSelector(localState, (state) => ({
  ...(state?.data || {}),
  Category: get(state, 'data.Category')
    ? {
        name: get(state, 'data.Category.Name'),
        value: get(state, 'data.Category.Id'),
      }
    : null,
}));

export const getTaskTemplateDrawerIsOpenedSelector = createSelector(localState, (state) =>
  get(state, 'isDrawerOpened'),
);

export const getTaskTemplateDrawerParamsSelector = createSelector(localState, (state) =>
  get(state, 'drawerParams'),
);
