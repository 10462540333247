import { Button, Modal, TextArea } from 'components-antd';

import styles from './styles.module.scss';
import { useState } from 'react';
import classNames from 'classnames';

export const DeclineConfirmation = (props) => {
  const { onClose, onContinue } = props;

  return (
    <div className={styles.declineConfirmation}>
      <div className={styles.informationText}>
        If you <span className={styles.bold}>continue</span>, this document will be declined.
      </div>

      <div className={styles.informationText}>
        To <span className={styles.bold}>request changes</span> to this document, please select{' '}
        <span className={styles.bold}>cancel </span>
        and contact the sender directly with your request. You can exit signing and save the
        information you’ve entered and sign the document later.
      </div>

      <div className={styles.buttonContainer}>
        <Button
          variant="hollow-bordered"
          className={classNames(styles.button, styles.greyButton)}
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button variant="secondary" onClick={onContinue} className={classNames(styles.button)}>
          Continue
        </Button>
      </div>
    </div>
  );
};
