import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Accordion, FormattedPhone } from 'components';
import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import { useSelector, useDispatch } from 'react-redux';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import { link } from 'settings/navigation/link';
import { Attachment } from 'pages/Quotes/components/QuoteDetails/components';
import { openQuotesDrawerAction } from 'store/actions/drawers/quotes';
import { getQuotesEntryIdThreadIdMap } from 'store/selectors/sockets/threads';
import MetaItem from './MetaItem';

import styles from './styles.module.scss';

const MetaInfo = (props) => {
  const { className } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { quote, requestQuoteFile } = useSelector(getQuoteDetailsSelector);
  const entryThreadIdMap = useSelector(getQuotesEntryIdThreadIdMap);

  const onViewFullProfile = () => {
    const categoryId = quote?.Category?.Id;
    const partnerId = quote?.Partner?.Id;
    history.push({
      pathname: link.toServicesCategoryPartner({ categoryId, partnerId }),
      state: {
        link: location.pathname,
        label: `My Quotes ${quote?.Partner?.BusinessName}`,
      },
    });
  };

  const onComment = useCallback(() => {
    dispatch(
      openQuotesDrawerAction({
        open: true,
        params: { threadId: entryThreadIdMap[quote?.Id], isQuoteDetails: true },
      }),
    );
  }, [dispatch, entryThreadIdMap, quote?.Id]);

  return (
    <div className={classNames(styles.metaInfo, className)}>
      <Button
        className={classNames(styles.button, styles.messages)}
        titleClassName={styles.messageButtonTitle}
        title="Messages"
        onClick={onComment}
        iconLeft={<Icon variant={Icon.COMMENT} />}
      />
      <Accordion title="Contact Details" open className={styles.accordion}>
        <MetaItem
          icon={Icon.PHONE}
          value={
            <FormattedPhone className={styles.phone}>{quote?.Partner?.PhoneNumber}</FormattedPhone>
          }
        />
        <MetaItem icon={Icon.EMAIL} value={quote?.Partner?.Email} />
        {quote?.Partner?.Website && (
          <MetaItem icon={Icon.WEBSITE} value={quote?.Partner?.Website} />
        )}
      </Accordion>
      <Button
        onClick={onViewFullProfile}
        className={classNames(styles.button, styles.viewProfile)}
        title="View Full Profile"
      />
      <Accordion title="Your Requests" open className={styles.accordion} allowOverflowWhenOpen>
        {requestQuoteFile && (
          <Attachment truncateLength={20} variant={Attachment.SUBMITTED} file={requestQuoteFile} />
        )}
      </Accordion>
    </div>
  );
};

MetaInfo.propTypes = {
  className: PropTypes.string,
};

MetaInfo.defaultProps = {
  className: '',
};

export default MetaInfo;
