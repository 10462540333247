import Api from 'store/effects/core/api';
import {
  getTransactionTaskAction,
  resetTransactionTaskAction,
  resetTransactionTasksAction,
} from 'store/actions/transactions';
import { getTransactionTask } from 'api/transactions';

export const getTransactionTaskEffect = (cfg, options, cb) => {
  const requestParams = { action: getTransactionTaskAction, method: getTransactionTask };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const resetTransactionTaskEffect = () => (dispatch) => {
  dispatch(resetTransactionTaskAction());
};

export const resetTransactionTasksEffect = () => (dispatch) => {
  dispatch(resetTransactionTasksAction());
};
