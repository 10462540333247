import { Select } from 'antd';
import classNames from 'classnames';
import { map } from 'lodash-es';

import { ArrowDown } from 'components/Icons';

import styles from './styles.module.scss';

const { Option } = Select;

export const Switcher = (props: DashboardSwitcherType) => {
  return (
    <Select
      defaultValue={props.defaultValue}
      className={styles.switcherSelect}
      popupClassName={classNames(styles.switcherDropdown)}
      onChange={props.handleChangedValue}
      bordered={false}
      suffixIcon={<ArrowDown />}
    >
      {map(props.dashboardTypes, ({ value, title }) => (
        <Option key={value} value={value}>
          {title}
        </Option>
      ))}
    </Select>
  );
};

interface DashboardSwitcherType {
  dashboardTypes: Array<{
    value: string;
    title: string;
  }>;
  defaultValue: string;
  handleChangedValue: (value: string) => void;
}
