import { ClientKitDataType } from '../../DataTypes';
import { Avatar } from 'components-antd';
import EmptyKit from '../EmptyKit';
import styles from './styles.module.scss';

type ClientTablePropsType = {
  data: ClientKitDataType[];
};

function ClientTable(props: ClientTablePropsType) {
  return (
    <div className={styles.clientTable}>
      <table className={styles.table}>
        <tr className={styles.rowHeading}>
          <td>
            <div className={styles.heading}>Client</div>
          </td>
          <td>
            <div className={styles.heading}>Addresses</div>
          </td>
          <td>
            <div className={styles.heading}>Active KITs</div>
          </td>
          <td>
            <div className={styles.heading}>Eligible KITs</div>
          </td>
        </tr>
        {props.data?.length ? (
          props.data.map((item) => (
            <tr className={styles.rowData}>
              <td>
                <div className={styles.avatar}>
                  <Avatar
                    className={''}
                    size={'default'}
                    src={item.avatarUrl}
                    alt={item.firstName}
                    shape={'circle'}
                  >
                    {item.firstName[0] + (item.lastName ? item.lastName[0] : '')}
                  </Avatar>
                  <div className={styles.avatarName}>
                    {item.firstName + (item.lastName ? ' ' + item.lastName : '')}
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.text}>{item.addressCount}</div>
              </td>
              <td>
                <div className={styles.text}>{item.activeKitsCount}</div>
              </td>
              <td>
                <div className={styles.text}>{item.eligibleKitsCount}</div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={4}>
              <EmptyKit text="No KITs to Display" />
            </td>
          </tr>
        )}
      </table>
    </div>
  );
}

export default ClientTable;
