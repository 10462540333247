import { Fragment, useRef, useState } from 'react';

import { Icons } from '../../../components';
import { EditTemplateNameModal } from './EditTemplateNameModal';
import { InfoTooltip } from 'pages/FormProcess/DynamicForm/Containers/DynamicEditor/components/InfoTooltip';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { FORM_TYPE } from 'app-constants';

export const TemplateName = (props) => {
  const { className, iconClass, name, onUpdateNameSuccess, showEditIcon, ...restRecord } = props;

  const [openEditModal, setOpenEditModal] = useState(false);

  const onEditClick = (e) => {
    e.stopPropagation();
    toggleNameModal();
  };

  const toggleNameModal = () => {
    setOpenEditModal((prev) => !prev);
  };

  const onSuccess = () => {
    setOpenEditModal(false);
    onUpdateNameSuccess();
  };

  const hasOverflownName = name && name.length > 50;
  return (
    <Fragment>
      <div className={classNames({ [styles.fileNameWrap]: hasOverflownName }, className)}>
        {hasOverflownName ? (
          <InfoTooltip text={name} className={styles.documentNameTooltip}>
            <p className={styles.documentName}>{name} </p>
          </InfoTooltip>
        ) : (
          <p className={styles.documentName}>
            {name}{' '}
            {showEditIcon && (
              <Icons variant={'editPencil'} className={iconClass} onClick={onEditClick} />
            )}
          </p>
        )}

        {showEditIcon && hasOverflownName && (
          <Icons variant={'editPencil'} className={iconClass} onClick={onEditClick} />
        )}
      </div>

      <div onClick={(e) => e.stopPropagation()}>
        <EditTemplateNameModal
          open={openEditModal}
          onClose={toggleNameModal}
          name={name}
          onSuccess={onSuccess}
          {...restRecord}
        />
      </div>
    </Fragment>
  );
};
