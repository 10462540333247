import React from 'react';
import moment from 'moment';

import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { Dropdown, Menu, Space } from 'components-antd';
import { Edit } from 'components/Icons';

import { subtractTimeZone } from 'helpers';

import styles from './styles.module.scss';
import { TEAM_OWNER } from 'settings/constants/roles';

export function tableColumns(
  isActive,
  onReactivateClick,
  onDeactivateClick,
  onMlsClick,
  onEdit,
  onLoginCick,
) {
  const handleActionClick = (e, row) => {
    e.stopPropagation();
    if (isActive) {
      onDeactivateClick(row);
    } else {
      onReactivateClick(row);
    }
  };

  const handleMlsClick = (e, row) => {
    e.stopPropagation();
    if (Number(row?.AgentMlsCount) > 0) onMlsClick(row);
  };

  const handleEditClick = (e, row) => {
    e.stopPropagation();
    onEdit(row);
  };

  const handleLoginCLick = (e, row) => {
    e.stopPropagation();
    onLoginCick(row);
  };

  const columns = [
    {
      title: 'Name',
      key: 'memberFirstName',
      dataIndex: 'MemberFirstName',
      sorter: (a, b) => {
        let aName = `${a?.MemberFirstName} ${a?.MemberLastName}`;
        let bName = `${b?.MemberFirstName} ${b?.MemberLastName}`;
        return aName?.localeCompare(bName);
      },
      width: 200,
      render: (_, row) => {
        return (
          <span
            className={styles.tableText}
          >{`${row?.MemberFirstName} ${row?.MemberLastName}`}</span>
        );
      },
    },
    {
      title: 'Email',
      key: 'memberEmail',
      dataIndex: 'MemberEmail',
      width: 300,
      sorter: (a, b) => {
        if (a.MemberEmail < b.MemberEmail) return -1;
        if (a.MemberEmail > b.MemberEmail) return 1;
        return 0;
      },
      render: (_, { MemberEmail }) => {
        return <span className={styles.tableText}>{MemberEmail}</span>;
      },
    },
    {
      title: 'Phone',
      key: 'memberPhone',
      dataIndex: 'MemberPhones',
      width: 200,
      sorter: (a, b) => {
        let aPhone = a?.MemberPhones?.find((phone) => phone?.IsPrimary)?.PhoneNumber;
        let bPhone = b?.MemberPhones?.find((phone) => phone?.IsPrimary)?.PhoneNumber;
        if (aPhone < bPhone) return -1;
        if (aPhone > bPhone) return 1;
        return 0;
      },
      render: (_, { MemberPhones }) => {
        let phone = MemberPhones?.find((phone) => phone?.IsPrimary)?.PhoneNumber;
        return <span className={styles.tableText}>{phone}</span>;
      },
    },
    {
      title: 'Role',
      key: 'memberRole',
      dataIndex: 'MemberRole',
      width: 150,
      sorter: (a, b) => {
        if (a.MemberRole < b.MemberRole) return -1;
        if (a.MemberRole > b.MemberRole) return 1;
        return 0;
      },
      render: (_, { MemberRole }) => {
        return <span className={styles.tableText}>{MemberRole}</span>;
      },
    },
    {
      title: 'Title',
      key: 'memberTitle',
      dataIndex: 'Title',
      width: 150,
      sorter: (a, b) => {
        if (a.Title < b.Title) return -1;
        if (a.Title > b.Title) return 1;
        return 0;
      },
      render: (_, { Title }) => {
        return <span className={styles.tableText}>{Title}</span>;
      },
    },
    {
      title: 'Last Login',
      key: 'lastLogin',
      dataIndex: 'MemberLastLogin',
      width: 150,
      sorter: (a, b) => moment(a.MemberLastLogin).unix() - moment(b.MemberLastLogin).unix(),
      render: (_, row) => {
        let loginDate = row?.MemberLastLogin
          ? subtractTimeZone(row?.MemberLastLogin, 'M/D/YYYY')
          : '';
        return (
          <>
            {loginDate ? (
              <span className={styles.loginLink} onClick={(e) => handleLoginCLick(e, row)}>
                {loginDate}
              </span>
            ) : (
              <span className={styles.tableText}>{`N/A`}</span>
            )}
          </>
        );
      },
    },
    {
      title: 'Date Joined',
      key: 'dateJoined',
      dataIndex: 'MemberCreatedDate',
      width: 150,
      sorter: (a, b) => moment(a.MemberCreatedDate).unix() - moment(b.MemberCreatedDate).unix(),
      render: (_, { MemberCreatedDate }) => {
        let createdDate = MemberCreatedDate
          ? subtractTimeZone(MemberCreatedDate, 'M/D/YYYY')
          : 'N/A';
        return <span className={styles.tableText}>{createdDate}</span>;
      },
    },
    {
      title: 'No# MLS',
      key: 'agentMlsCount',
      dataIndex: 'AgentMlsCount',
      width: 150,
      sorter: (a, b) => {
        if (Number(a.AgentMlsCount) < Number(b.AgentMlsCount)) return -1;
        if (Number(a.AgentMlsCount) > Number(b.AgentMlsCount)) return 1;
        return 0;
      },
      render: (_, row) => {
        return (
          <span className={styles.mlsCount} onClick={(e) => handleMlsClick(e, row)}>
            {row?.AgentMlsCount}
          </span>
        );
      },
    },
    {
      title: 'Action',
      key: '',
      dataIndex: '',
      width: 150,
      render: (_, row) => {
        return (
          <>
            {row?.MemberRole !== TEAM_OWNER && (
              <span
                className={isActive ? styles.deactivateBtn : styles.reactivateBtn}
                onClick={(e) => handleActionClick(e, row)}
              >
                {isActive ? 'Deactivate' : 'Reactivate'}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: '',
      key: '',
      dataIndex: '',
      width: 150,
      render: (_, row) => {
        return (
          <div className={styles.tcRight}>
            <div id="dropdown" className={styles.dropdownWrap}>
              <Dropdown
                overlay={
                  <Menu
                    items={[
                      {
                        key: '2',
                        label: (
                          <div
                            className={styles.dropdownItem}
                            onClick={(e) => handleEditClick(e, row)}
                          >
                            <Edit className={styles.icon} />
                            <span>Edit</span>
                          </div>
                        ),
                      },
                    ]}
                  />
                }
                trigger={['click']}
                overlayClassName={styles.transactionMoreOptions}
                getPopupContainer={() => document.getElementById('dropdown')!}
                placement="bottomRight"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <Icon variant={Icon.DOTS} className={styles.dropdownTrigger} />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
        );
      },
    },
  ];

  return columns;
}
