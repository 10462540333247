import React from 'react';
import moment from 'moment';

import { subtractTimeZone } from 'helpers';

import styles from './styles.module.scss';

export function tableColumns() {
  const columns = [
    {
      title: 'Team Name',
      key: 'teamName',
      dataIndex: 'TeamName',
      width: 300,
      sorter: (a, b) => {
        if (a.TeamName < b.TeamName) return -1;
        if (a.TeamName > b.TeamName) return 1;
        return 0;
      },
      render: (_, { TeamName }) => {
        return <span className={styles.tableText}>{TeamName}</span>;
      },
    },
    {
      title: 'Date Created',
      key: 'dueDate',
      dataIndex: 'CreatedDate',
      width: 300,
      sorter: (a, b) => moment(a.CreatedDate).unix() - moment(b.CreatedDate).unix(),
      render: (_, { CreatedDate }) => {
        let createdDate = CreatedDate ? subtractTimeZone(CreatedDate, 'M/D/YYYY') : 'N/A';
        return <span className={styles.tableText}>{createdDate}</span>;
      },
    },
    {
      title: 'Active Seats',
      key: 'activeSeats',
      dataIndex: 'TeamAgents',
      width: 300,
      sorter: (a, b) => {
        if (a?.TeamAgents?.length < b?.TeamAgents?.length) return -1;
        if (a?.TeamAgents?.length > b?.TeamAgents?.length) return 1;
        return 0;
      },
      render: (_, { TeamAgents }) => {
        return <span className={styles.tableText}>{TeamAgents?.length}</span>;
      },
    },

    {
      title: 'Team Owner',
      key: 'ownerFirstName',
      dataIndex: 'OwnerFirstName',
      sorter: (a, b) => {
        let aName = `${a?.OwnerFirstName} ${a?.OwnerLastName}`;
        let bName = `${b?.OwnerFirstName} ${b?.OwnerLastName}`;
        return aName?.localeCompare(bName);
      },
      render: (_, row) => {
        return (
          <span className={styles.tableText}>{`${row?.OwnerFirstName} ${row?.OwnerLastName}`}</span>
        );
      },
    },
  ];

  return columns;
}
