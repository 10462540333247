import { handleActions } from 'redux-actions';

import { cloneDeep } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import { setCriteriaAction, setFilterAction } from 'store/actions/feedv3';

const initialData = {
  criteria: null,
  filter: null,
};

export default handleActions(
  {
    [setCriteriaAction as any]: (state, { payload }) => ({
      ...state,
      criteria: payload.criteria,
    }),
    [setFilterAction as any]: (state, { payload }) => ({
      ...state,
      filter: payload.filter,
    }),
    [appLogoutAction as any]: () => cloneDeep(initialData),
  },
  initialData,
);
