import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ProfileIcons } from '../Icons';
import { Select } from 'components';
import { AgentStatus } from 'types';

import styles from './styles.module.scss';

const Item = ({
  testid,
  label,
  value,
  className,
  labelClassName,
  valueClassName,
  editable,
  onChange,
}) => {
  const getValue = () => {
    if (label === ProfileIcons.ADDRESS && value?.length) {
      const primaryAddress = value.find((clientAddress) => clientAddress.isPrimary);
      return (
        <div testid={testid} className={classNames(styles.value, styles.address, valueClassName)}>
          <p>{primaryAddress?.address.split(',')[0] || ''}</p>
          <p>{`${primaryAddress?.address.split(',')[1] || '-'}, ${
            primaryAddress?.address.split(',')[2] || '-'
          }`}</p>
        </div>
      );
    }

    if (editable && label === ProfileIcons.STATUS) {
      return (
        <Select
          value={{ value, name: value }}
          onSelect={(e, value) => onChange(value)}
          options={[
            { value: AgentStatus.Active, name: AgentStatus.Active },
            { value: AgentStatus.Inactive, name: AgentStatus.Inactive },
          ]}
          className={{
            wrapper: styles.statusWrapper,
          }}
          arrowIconClassName={styles.arrowIcon}
        />
      );
    }

    return (
      <div testid={testid} className={classNames(styles.value, valueClassName)}>
        {value || '-'}
      </div>
    );
  };

  return (
    <div testid="item_wrapper" className={classNames(styles.item, className)}>
      <div className={styles.iconLabel}>
        <ProfileIcons name={label} />
        <div className={classNames(styles.label, labelClassName)}>{label}</div>
      </div>
      {getValue()}
    </div>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  testid: PropTypes.string,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
};

Item.defaultProps = {
  className: '',
  label: '',
  value: '',
  labelClassName: '',
  valueClassName: '',
  testid: undefined,
  editable: false,
  onChange: () => {},
};

export default Item;
