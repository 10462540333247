import { Fragment, useMemo } from 'react';
import { getAllFilesCount } from '../helper';
import styles from '../style.module.scss';

export const usePrepareDocumentDetails = (props) => {
  const { preparedDocuments, documents, auditTrailDocuments } = props;

  const pluralText = (text, count) => `${count} ${text}${count > 1 ? 's' : ''}`;

  const formOptions = useMemo(() => {
    return preparedDocuments.map(({ Id, formName, pageCount }) => ({
      label: (
        <Fragment>
          <div className={styles.formName}>{formName}</div>
          <div className={styles.fileCount}>{`${pluralText('Page', pageCount)}`}</div>
        </Fragment>
      ),
      value: Id,
    }));
  }, [preparedDocuments]);

  const allDocumentIds = useMemo(() => documents.map(({ Id }) => Id), [documents]);

  const allFileText = pluralText('File', documents.length + auditTrailDocuments.length);
  const allDocumentText = pluralText('File', documents.length);
  const allCertificateText = pluralText('File', auditTrailDocuments.length);

  return { formOptions, allDocumentIds, allFileText, allDocumentText, allCertificateText };
};
