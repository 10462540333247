import { Fragment, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { Row, Col, Button } from 'components-antd';
import { ConfirmationDialog } from 'components';
import { FeeData, NameAutocomplete, PriceHighlighter } from '../../..';
import { useParams } from 'react-router-dom';
import { deleteFinancialFee, moveFinancialFee, updateFinancialFee } from 'api/financials';
import { Select } from 'components';
import CheckboxComponent from 'components/Form/Checkbox';
import { Options } from './../../components';
import { CommissionField } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/FinancingDetails/components';
import { Icons } from 'pages/Workshop/Icons';

import { SplitTabTypes } from '../../../../TransactionFinancials';
import { convertToDecimalIfNotWhole } from 'helpers';

import styles from './styles.module.scss';

export const FeeItem = ({
  feeId,
  label,
  labelClassName,
  containerClassName,
  splitType,
  amount,
  isRestricted = false,
  percentage,
  notEditable = false,
  fieldKey,
  setEditField,
  isDisableField,
  splitId,
  addToAccount = 0,
  splitInfo,
  fetchFinancials,
  isPercentage,
  feeArray,
  splitTabType,
  representingRoles,
  isTransactionAdminOrOwner,
  autocompleteFeeNames = [],
  hidePriceHighlighter = false,
  addToOptions,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationProps, setConfirmationProps] = useState({
    title: '',
    message: <></>,
    action: () => {},
  });
  const [split, setSplitId] = useState<number | null>(null);
  const [localAddToAccount, setLocalAddToAccount] = useState<number>(0);
  const [updateTitle, setUpdateTitle] = useState('');
  const [isValidateUpdate, setValidateUpdate] = useState(false);
  const [isRestrictedUpdate, setIsRestrictedUpdate] = useState(false);
  const [updateAmountObj, setUpdateAmountObj] = useState<any>({
    isUpdatePercentage: false,
    feeAmount: null,
  });
  const [feeListing, setFeeListing] = useState<FeeData[]>([]);
  const [isUpdateFeeLoading, setUpdateFeeLoading] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const params: { id?: string } = useParams();

  // Restricted fee should be visible to Transaction owner or admin only
  if (isRestricted && !isTransactionAdminOrOwner) return null;

  useEffect(() => {
    setSplitId(splitId);
    setLocalAddToAccount(addToAccount);
    setUpdateTitle(label);
    setUpdateAmountObj({
      isUpdatePercentage: isPercentage,
      feeAmount: isPercentage ? percentage : amount,
    });
    setIsRestrictedUpdate(isRestricted);
  }, []);

  const onEdit = () => {
    setEditField && setEditField(fieldKey);
    setIsEdit(true);
  };

  const onCancel = () => {
    setEditField && setEditField(null);
    setSplitId(splitId);
    setUpdateTitle(label);
    setUpdateAmountObj({ isUpdatePercentage: isPercentage, feeAmount: amount });
    setIsRestrictedUpdate(isRestricted);
    setIsEdit(false);
    setValidateUpdate(false);
  };

  const handleSelect = (e, selectedOption) => {
    setSplitId(selectedOption.value);
  };

  const handleAddToAccount = (e, selectedOption) => {
    setLocalAddToAccount(selectedOption?.value);
  };

  const getUpdateFeeDisable = () => {
    if (
      updateAmountObj?.feeAmount === null ||
      updateAmountObj?.feeAmount === 0 ||
      updateTitle === '' ||
      split === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isPriceWithinRangeUpdate = (compareprice, sum, _updatedObject) => {
    let calculatedValue = 0;
    let _sum = 0;
    if (_updatedObject?.isUpdatePercentage) {
      let _data = _updatedObject?.feeAmount / 100;

      calculatedValue = (_updatedObject?.feeAmount / 100) * compareprice;
    } else {
      calculatedValue = _updatedObject?.feeAmount;
    }

    _sum = +calculatedValue + sum;

    if (_sum === compareprice || _sum < compareprice) {
      setValidateUpdate(false);
    } else {
      setValidateUpdate(true);
    }
  };

  const onUpdateFee = async () => {
    try {
      setUpdateFeeLoading(true);
      let _payload: any = {
        FeeTitle: updateTitle,
        FeeValue: updateAmountObj?.feeAmount,
        IsFeeTypePercentage: updateAmountObj?.isUpdatePercentage,
        IsFeeRestricted: isRestrictedUpdate,
        ...(localAddToAccount !== 0 ? { AddToAccountId: localAddToAccount } : {}),
      };

      if (split !== 0) {
        _payload.SplitId = split;
      } else {
        _payload.SplitSide = splitTabType; // FOR GCI, we send SplitSide = "Listing/Purchase"
      }

      await updateFinancialFee({
        transactionId: params?.id,
        id: feeId,
        data: _payload,
      });
      fetchFinancials();
    } catch (err) {
      setUpdateFeeLoading(false);
    } finally {
      setUpdateFeeLoading(false);
      setIsEdit(false);
    }
  };

  const onDeleteFee = async () => {
    try {
      setConfirmationLoading(true);
      await deleteFinancialFee({
        transactionId: params?.id,
        id: feeId,
      });
      fetchFinancials();
    } catch (err) {
      setConfirmationLoading(false);
    } finally {
      setConfirmationLoading(false);
      setShowConfirmationModal(false);
    }
  };

  const onMoveFeeToOtherSide = async () => {
    try {
      setConfirmationLoading(true);
      await moveFinancialFee({
        transactionId: params?.id,
        id: feeId,
      });
      fetchFinancials();
    } catch (err) {
      setConfirmationLoading(false);
    } finally {
      setConfirmationLoading(false);
      setShowConfirmationModal(false);
    }
  };

  const addToAccountLabel = () => {
    if (!splitType) return null;
    return `-> ${addToOptions?.find((item) => item.value === addToAccount)?.name || 'Outbound'} `;
  };

  return (
    <div
      className={classNames(
        styles.feeItem,
        { [styles.feeItemEdit]: !isEdit && !notEditable && !isDisableField(fieldKey) },
        { [styles.disabled]: isDisableField(fieldKey) },
        containerClassName,
      )}
    >
      {!isEdit ? (
        <Fragment>
          <div>
            <div className={styles.labelContainer}>
              <p className={classNames(styles.label, labelClassName)}>{label}</p>
              {!notEditable && isRestrictedUpdate && <Icons variant={Icons.RESTRICTED} />}
            </div>
            <p className={styles.secondaryLabel}>
              {splitType} {addToAccountLabel()}
            </p>
          </div>
          <div className={styles.amounts}>
            {amount || percentage ? (
              <>
                <NumberFormat
                  thousandSeparator
                  displayType="text"
                  value={convertToDecimalIfNotWhole(amount)}
                  prefix="$"
                  renderText={(val) => (
                    <span className={styles.amount}>
                      {val}
                      <PriceHighlighter isShow={!isPercentage && !hidePriceHighlighter} />
                    </span>
                  )}
                />
                {percentage && (
                  <span className={styles.percentage}>
                    ({convertToDecimalIfNotWhole(percentage)}%)
                    <PriceHighlighter isShow={isPercentage && !hidePriceHighlighter} />
                  </span>
                )}
              </>
            ) : (
              <span>-</span>
            )}
            <div className={styles.editIcon}>
              <Options
                onEdit={onEdit}
                onDelete={() => {
                  setConfirmationProps({
                    title: 'Delete',
                    message: (
                      <>
                        Are you sure you want to delete <b>{label}</b>?
                      </>
                    ),
                    action: onDeleteFee,
                  });
                  setShowConfirmationModal(true);
                }}
                onMoveSide={() => {
                  setConfirmationProps({
                    title: 'Move',
                    message: (
                      <>
                        Are you sure you want to move <b>{label}</b> to{' '}
                        <b>{splitTabType === SplitTabTypes.LISTING ? 'Buy Side' : 'List side'}</b>?
                      </>
                    ),
                    action: onMoveFeeToOtherSide,
                  });
                  setShowConfirmationModal(true);
                }}
                splitTabType={splitTabType}
                representingRoles={representingRoles}
              />
            </div>
          </div>
        </Fragment>
      ) : (
        <Row gutter={12}>
          <Col lg={12}>
            <NameAutocomplete
              label="Split"
              allNames={autocompleteFeeNames?.map((item, idx) => ({ label: item, value: idx }))}
              value={updateTitle}
              onChange={(value) => setUpdateTitle(value)}
              onSelect={(value) => setUpdateTitle(value)}
            />
          </Col>
          <Col lg={12}>
            <CommissionField
              label="Amount"
              value={updateAmountObj?.feeAmount}
              update={(object) => {
                const updatedObject = {
                  isUpdatePercentage: object?.IsFeeTypePercentage,
                  feeAmount: object?.Value === '' || null ? null : parseFloat(object?.Value),
                };
                let _selectedSplitData =
                  splitInfo &&
                  splitInfo.filter((data) => {
                    return data?.value === split;
                  });
                let _selectedFeeListingData =
                  feeArray &&
                  feeArray.filter((_data) => {
                    return _data?.SplitId === split && _data?.Id !== feeId;
                  });

                let sum = _selectedFeeListingData.reduce((acc, obj) => acc + obj?.FeeValue, 0);

                if (split !== 0) {
                  isPriceWithinRangeUpdate(
                    _selectedSplitData && _selectedSplitData[0]?.amount,
                    sum,
                    updatedObject,
                  );
                }
                setUpdateAmountObj(updatedObject);
              }}
              IsFeeTypePercentage={updateAmountObj?.isUpdatePercentage}
            />
            {/* {isValidateUpdate && (
              <ErrorMessage
                message={'Fee amount can never be greater than its relevant split price'}
              />
            )} */}
          </Col>
          <Col lg={12} className={styles.marginTop}>
            <p className={styles.applyLabel}>{'Deduct From'}</p>
            <Select
              options={splitInfo}
              onSelect={handleSelect}
              isArrowIcon={true}
              variant={Select.LIGHT_ROUND}
              className={{
                input: styles.inputHeight,
              }}
              value={split}
            />
          </Col>
          <Col lg={12} className={styles.marginTop}>
            <p className={styles.applyLabel}>{'Add To'}</p>
            <Select
              options={addToOptions}
              onSelect={handleAddToAccount}
              isArrowIcon={true}
              variant={Select.LIGHT_ROUND}
              className={{
                input: styles.inputHeight,
              }}
              value={localAddToAccount}
            />
          </Col>
          <Col lg={12}>
            <div className={styles.buttonsContainer}>
              <Button
                variant="secondary"
                size="large"
                onClick={onUpdateFee}
                loading={isUpdateFeeLoading}
                disabled={getUpdateFeeDisable()}
              >
                Update
              </Button>
              <Button variant="hollow-bordered" size="large" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Col>
          {isTransactionAdminOrOwner && (
            <Col lg={12} className={styles.checkboxCont}>
              <CheckboxComponent
                id="restricted-update"
                label="Restricted"
                checked={isRestrictedUpdate}
                onChange={(e, v, checked) => setIsRestrictedUpdate(checked)}
                direction={CheckboxComponent.DIRECTION_RIGHT}
              />
            </Col>
          )}
        </Row>
      )}
      <ConfirmationDialog
        onReject={() => setShowConfirmationModal(false)}
        onConfirm={confirmationProps?.action}
        isOpen={showConfirmationModal}
        confirmText={confirmationProps?.title}
        isPending={confirmationLoading}
        className={styles.deleteFeeDialog}
      >
        <div className={styles.content}>{confirmationProps?.message}</div>
      </ConfirmationDialog>
    </div>
  );
};
