import { useState, useEffect } from 'react';
import { Select, Modal } from 'components-antd';

import { validationToast } from 'pages/FormProcess/helper';
import { isEmail } from 'utils';
import { DeferUserType, FormDocumentQuestionType } from 'types';
import { incorrectEmail } from 'app-constants';
import { caseInsensitiveFilter } from 'helpers';
import styles from './styles.module.scss';
import { SmartFormIcons, SmartFormIconVariants } from '../SmartFormIcons';

interface EditFormDeferModalProps {
  questions?: FormDocumentQuestionType[];
  handleClose: () => void;
  handleDefer: (uuid: string[], user) => void;
  isLoading?: boolean;
  allDeferrableUsers: DeferUserType[];
  mask?: boolean;
  position: { top: number | string; left: number | string };
}

export const EditFormDeferModal = ({
  questions,
  handleClose,
  handleDefer,
  isLoading = false,
  allDeferrableUsers = [],
  mask = true,
  position,
}: EditFormDeferModalProps) => {
  const [editor, setEditor] = useState<string>();
  const [selectOpen, setSelectOpen] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setEditor(undefined);
  }, [questions]);

  const options = [...allDeferrableUsers]
    .filter((u) => !u.self)
    .map((u) => ({
      key: String(u.Id ?? u.Email),
      label: u.FirstName ? `${u.FirstName} ${u.LastName}` : u.Email,
      value: String(u.Id ?? u.Email),
    }));

  const validateOption = (value: string) => {
    if (isNaN(Number(value))) {
      if (!isEmail(value)) {
        setEditor('');
        setTimeout(() => setEditor(undefined), 0);
        return validationToast(incorrectEmail);
      }
    }
    setEditor(value);
  };
  if (!questions?.length) return <></>;

  const onSubmit = () => {
    const uuids = questions?.map((q) => q.UUID);
    if (uuids?.length && editor) {
      const defer = isEmail(editor)
        ? {
            userEmail: editor,
          }
        : {
            userId: Number(editor),
          };
      handleDefer(uuids, defer);
    }
  };

  const handleDeferOnChange = (options) => {
    if (options?.length) {
      validateOption(options.pop());
      setSelectOpen(false);
      setTimeout(() => setSelectOpen(undefined), 0);
    } else {
      setEditor(undefined);
    }
  };

  return (
    <div className={styles.deferModalContainer}>
      <Modal
        maskClosable={true}
        closable={!isLoading}
        className={styles.deferModal}
        footer={null}
        mask={questions?.length > 1}
        width={312}
        closeIcon={<></>}
        centered={false}
        onCancel={handleClose}
        open={!!questions?.length}
        style={{ ...position, textAlign: 'left', verticalAlign: 'top' }}
      >
        <div className={styles.deferModalBody}>
          <div className={styles.modalHeader}>
            <SmartFormIcons variant={SmartFormIconVariants.CurveArrow} />
            <p className={styles.modalText}>
              {questions?.length === 1
                ? 'Defer this question to:'
                : 'Defer all questions on this form to:'}
            </p>
          </div>
          <Select
            open={selectOpen}
            onSearch={() => setEditor(undefined)}
            mode="tags"
            value={editor}
            onChange={handleDeferOnChange}
            large
            placeholder={'Enter name or email address...'}
            bordered={false}
            className={styles.listBoxInput}
            options={options}
            filterOption={(inputValue, option) =>
              caseInsensitiveFilter(inputValue, option?.props?.label)
            }
          />
          <span
            className={styles.sendIcon}
            onClick={() => {
              editor && onSubmit();
            }}
          >
            <SmartFormIcons
              variant={editor ? SmartFormIconVariants.SendActive : SmartFormIconVariants.Send}
            />
          </span>
        </div>
      </Modal>
    </div>
  );
};
