import React from 'react';
import { Page, Text, View, Document, PDFDownloadLink, Svg, Line, Path } from '@react-pdf/renderer';
import { Tour } from 'types/tours';
import { PDFstyles } from './PDFStyles';
import {
  getClientName,
  getNotesCount,
  getTime,
  getFormattedDate,
  getAgentName,
} from '../../helper';
import moment from 'moment';

interface ItineraryDocumentProps {
  tours: Tour[];
  isClient?: boolean;
}
interface GroupedTours {
  [date: string]: Tour[];
}

const ItineraryDocument: React.FC<ItineraryDocumentProps> = ({ tours, isClient }) => {
  const groupToursByDate = () => {
    return tours?.reduce((groups, tour) => {
      const date = moment(tour.ScheduleDateTime).format('MM/DD/YYYY');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(tour);
      return groups;
    }, {});
  };
  const groupedTours = groupToursByDate();
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return '';
    }
    if (phoneNumber.length === 10) {
      const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]} ${match[3]}`;
      }
    } else if (phoneNumber.length === 11) {
      const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{5})$/);
      if (match) {
        return `(${match[1]}) ${match[2]} ${match[3]}`;
      }
    }
    return phoneNumber;
  };
  return (
    <Document>
      <Page style={PDFstyles.page} size="A4">
        <View style={PDFstyles.section}>
          <Text style={PDFstyles.header}>Property Tour Itinerary</Text>
          {isClient ? (
            <Text style={PDFstyles.subheader}>Agent(s): {getAgentName(tours[0])}</Text>
          ) : (
            <Text style={PDFstyles.subheader}>Client: {getClientName(tours[0])}</Text>
          )}
          {!isClient && (
            <Text style={PDFstyles.clientInfo}>
              {`Email: ${tours[0]?.Creator.Email}       Phone: ${formatPhoneNumber(
                tours[0]?.Creator?.Phones?.[0]?.PhoneNumber,
              )}`}
            </Text>
          )}

          {Object.entries(groupedTours)
            .sort()
            .map(([dateKey, toursOnDate]: [string, any]) => (
              <React.Fragment key={dateKey}>
                <Text style={PDFstyles.dateHeader}>
                  {getFormattedDate(toursOnDate[0].ScheduleDateTime)}
                </Text>
                {toursOnDate.map((tour, index) => (
                  <View style={PDFstyles.tourItem} key={index}>
                    <Text style={PDFstyles.time}>{getTime(tour.ScheduleDateTime)}</Text>

                    <Svg width="18" height="50" viewBox="0 0 18 50">
                      <Path
                        d="M9 10.5C10.2426 10.5 11.25 9.49264 11.25 8.25C11.25 7.00736 10.2426 6 9 6C7.75736 6 6.75 7.00736 6.75 8.25C6.75 9.49264 7.75736 10.5 9 10.5Z"
                        stroke="#ADADAD"
                        strokeWidth="1.5"
                      />
                      <Path
                        d="M13.2428 12.4925L10.0605 15.6748C9.92133 15.8141 9.75608 15.9246 9.57417 16C9.39227 16.0754 9.19729 16.1142 9.00038 16.1142C8.80347 16.1142 8.60849 16.0754 8.42658 16C8.24468 15.9246 8.07942 15.8141 7.94025 15.6748L4.75725 12.4925C3.91817 11.6534 3.34675 10.5843 3.11527 9.42043C2.88378 8.25655 3.00262 7.05017 3.45676 5.95383C3.91089 4.85749 4.67993 3.92044 5.66661 3.26116C6.6533 2.60189 7.81333 2.25 9 2.25C10.1867 2.25 11.3467 2.60189 12.3334 3.26116C13.3201 3.92044 14.0891 4.85749 14.5433 5.95383C14.9974 7.05017 15.1162 8.25655 14.8847 9.42043C14.6533 10.5843 14.0818 11.6534 13.2428 12.4925V12.4925Z"
                        stroke="#ADADAD"
                        strokeWidth="1.5"
                      />
                      <Line
                        x1="9"
                        y1="16"
                        x2="9"
                        y2="50"
                        strokeWidth={3}
                        stroke="rgb(211, 211, 211)"
                      />
                    </Svg>

                    <View style={PDFstyles.tourDetails}>
                      <Text style={PDFstyles.homeAddress}>
                        {tour.PropertyAddress.Line2
                          ? `${tour.PropertyAddress.Line1} ${tour.PropertyAddress.Line2},`
                          : `${tour.PropertyAddress.Line1},`}{' '}
                      </Text>
                      <Text>
                        {`${tour.PropertyAddress.City}, ${tour.PropertyAddress.State} ${tour.PropertyAddress.Zip}`}
                      </Text>

                      {getNotesCount(tour) > 0 && (
                        <>
                          {' '}
                          {tour.ClientNotes && (
                            <Text>
                              <Text style={PDFstyles.notes}>Notes:</Text>
                              <Text> {tour.ClientNotes}</Text>
                            </Text>
                          )}
                          {tour.PrivateNotes && !isClient ? (
                            <Text>
                              <Text style={PDFstyles.notes}>Private Notes:</Text>
                              <Text> {tour.PrivateNotes}</Text>
                            </Text>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </View>
                  </View>
                ))}
              </React.Fragment>
            ))}
        </View>
      </Page>
    </Document>
  );
};

interface ItineraryPDFDownloadLinkProps {
  tours: Tour[];
  isClient?: boolean;
  children?;
}

export const ItineraryPDFDownloadLink: React.FC<ItineraryPDFDownloadLinkProps> = ({
  tours,
  isClient,
  children,
}) => (
  <PDFDownloadLink
    document={<ItineraryDocument isClient={isClient} tours={tours} />}
    fileName="itinerary.pdf"
    style={{ color: '#000000D9' }}
  >
    {({ loading, error }) => (children ? children : 'Download Itinerary')}
  </PDFDownloadLink>
);

export default ItineraryPDFDownloadLink;
