import React from 'react';
import { useSelector } from 'react-redux';

import {
  getClientsGroupedAndSortedArchivedThreadsList,
  getPartnersGroupedAndSortedArchivedThreadsList,
  getTransactionsGroupedAndSortedArchivedThreadsList,
  getArchivedChatThreadsListRecipientWise,
} from 'store/selectors/sockets/threads';

import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import GroupedThreadsRecipientWise from '../GroupedThreadsRecipientWise';
import GroupedTransactionsThreads from '../GroupedTransactionsThreads';
import GroupedClientsThreads from '../GroupedClientsThreads';
import GroupedServicesThreads from '../GroupedServicesThreads';

const ArchivedContentAgentView = () => {
  const clientsList = useSelector(getClientsGroupedAndSortedArchivedThreadsList);
  const transactionsList = useSelector(getTransactionsGroupedAndSortedArchivedThreadsList);
  const servicesList = useSelector(getPartnersGroupedAndSortedArchivedThreadsList);
  const archivedThreadsRecipientWise = useSelector(getArchivedChatThreadsListRecipientWise);

  return (
    <>
      {
        // TODO: DEV-45 code cleaning
        /* {!!clientsList?.length && <GroupedClientsThreads list={clientsList} archived />}
      {!!transactionsList?.length && (
        <GroupedTransactionsThreads list={transactionsList} archived />
      )}
      {!!servicesList?.length && (
        <GroupedServicesThreads
          groupDrawerType={DRAWER_MESSAGES_TYPES.SERVICE}
          list={servicesList}
          archived
        />
      )} */
      }
      <GroupedThreadsRecipientWise list={archivedThreadsRecipientWise} archived />
    </>
  );
};

export default ArchivedContentAgentView;
