import { routes } from 'settings/navigation/routes';
import { ShowingAppointment, ExternalDelegates } from 'pages';
import { Feedback } from 'pages/Feedback';

export default [
  {
    path: routes.appointment,
    component: ShowingAppointment,
    exact: true,
  },
  {
    path: routes.showing,
    component: ShowingAppointment,
    exact: true,
  },
  {
    path: routes.feedback,
    component: Feedback,
    exact: true,
  },
  {
    path: routes.externalDelegates,
    component: ExternalDelegates,
    exact: true,
  },
];
