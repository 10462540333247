import { apiServer } from 'settings/web-services/api';

export function getCategories(cfg) {
  return apiServer.get('/partner/categories', { params: cfg });
}

export function getAllPartnerTags(cfg) {
  return apiServer.get('/partner/allpartnertags', { params: cfg });
}

export function getCategoryById({ id }) {
  return apiServer.get(`/partner/categories/${id}`);
}

export function putCategoryData(cfg, { id }) {
  return apiServer.put(`/partner/categories/${id}`, cfg);
}

export function deleteDeactivateCategory({ id }) {
  return apiServer.delete(`/partner/categories/${id}`);
}

export function postCategoryData(cfg) {
  return apiServer.post('/partner/categories', cfg);
}

export function putActivateCategory({ id }) {
  return apiServer.put(`/partner/categories/${id}`, {
    IsActive: true,
  });
}

export function getQuoteForms(cfg) {
  return apiServer.get('/quoteRequest/forms', { params: cfg });
}
