import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const DollarsIcon = memo(({ className }) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="9" stroke="#AAABAB" strokeWidth="2" />
          <path
            d="M14.7 8.625C14.7 8.625 13.8 7.5 12 7.5C10.2 7.5 9.3 8.625 9.3 9.75C9.3 12.5625 15 11.1 15 14.25C15 15.6 14 16.5 12 16.5C10 16.5 9 15.15 9 15.15"
            stroke="#AAABAB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 6V18"
            stroke="#AAABAB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

DollarsIcon.propTypes = {
  className: PropTypes.string,
};

DollarsIcon.defaultProps = {
  className: '',
};

export default DollarsIcon;
