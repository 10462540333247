export const SeatsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12.5 8C12.5 10.2091 10.7091 12 8.5 12C6.29086 12 4.5 10.2091 4.5 8C4.5 5.79086 6.29086 4 8.5 4C10.7091 4 12.5 5.79086 12.5 8Z"
        stroke="#AAABAB"
        strokeWidth="2"
      />
      <path
        d="M15.5 12C17.7091 12 19.5 10.2091 19.5 8C19.5 5.79086 17.7091 4 15.5 4"
        stroke="#AAABAB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M2.5 19.5C2.87394 17.6303 4.59315 16 6.49988 16H10.5C12.4067 16 14.1261 17.6303 14.5 19.5"
        stroke="#AAABAB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 16C19.4067 16 21.1261 17.6303 21.5 19.5"
        stroke="#AAABAB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
