import { routes } from 'settings/navigation/routes';
import { Services, Quotes, ServicesCategory, ServicesCategoryPartner, RequestQuote } from 'pages';
import { CLIENT, AGENT, THIRD_PARTY } from 'settings/constants/roles';

export default [
  {
    path: routes.services,
    component: Services,
    exact: true,
    roles: [CLIENT, AGENT],
  },
  {
    path: routes.servicesQuotes,
    component: Quotes,
    exact: true,
    roles: [CLIENT, AGENT, THIRD_PARTY],
  },
  {
    path: routes.servicesCategoryPartner,
    component: ServicesCategoryPartner,
    exact: true,
    roles: [CLIENT, AGENT],
  },
  {
    path: routes.servicesCategoryPartnerRequestQuote,
    component: RequestQuote,
    exact: true,
    roles: [CLIENT, AGENT],
  },
];
