import { DeleteModal } from 'components-antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteTemplateEffect } from 'store/effects/formProcess';

export const DeleteTemplateModal = (props) => {
  const { open, document, onClose, rowKeys, rowKeyRecords, onDeleteSuccess } = props;

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const manageDelete = () => {
    const { versionId, bundleId } = document;
    let keys = [];
    let records = [];

    if (rowKeys.includes(versionId || bundleId)) {
      keys = rowKeys.filter(
        (key) => (bundleId && key !== bundleId) || (versionId && key !== versionId),
      );
    }

    if (rowKeyRecords.length) {
      records = rowKeyRecords.filter(
        (record) =>
          (bundleId && record.bundleId !== bundleId) ||
          (versionId && record.versionId !== versionId),
      );
    }

    onDeleteSuccess({ keys, records });
  };

  const onDeleteConfirm = () => {
    setLoading(true);

    let body = {};

    if (document.bundleId) {
      body = { bundleTemplateIds: [document.bundleId] };
    } else {
      body = { templateIds: [document.templateId] };
    }

    dispatch(
      deleteTemplateEffect(body, (error) => {
        if (!error) {
          setLoading(false);
          manageDelete();
        } else {
          setLoading(false);
        }
      }),
    );
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DeleteModal
        open={open}
        onCancel={onClose}
        entityName={`${document.name} document`}
        closable={false}
        maskClosable={false}
        confirmLoading={loading}
        onOk={onDeleteConfirm}
        width={400}
      />
    </div>
  );
};
