export interface DataType {
  key: string;
  listing: string;
  date: string;
  value: string;
  agent: string;
  client: string;
}

export const sampleData: DataType[] = [
  {
    key: '1',
    listing: '910 Broadway St., #1301 Chicago, IL 60661',
    date: 'June 3',
    value: '$120,000',
    agent: 'Ava Williams',
    client: 'Roberto Carlos',
  },
  {
    key: '2',
    listing: '910 Broadway St., #1301 Chicago, IL 60661',
    date: 'June 10',
    value: '$320,000',
    agent: 'Ava Williams',
    client: 'Bruno Hernandez',
  },
  {
    key: '3',
    listing: '910 Broadway St., #1301 Chicago, IL 60661',
    date: 'June 15',
    value: '$320,000',
    agent: 'Billie Hobbs',
    client: 'Martin Guptil',
  },
  {
    key: '4',
    listing: '910 Broadway St., #1301 Chicago, IL 60661',
    date: 'June 16',
    value: '$120,000',
    agent: 'Ava Williams',
    client: 'Roberto Carlos',
  },
  {
    key: '5',
    listing: '910 Broadway St., #1301 Chicago, IL 60661',
    date: 'June 17',
    value: '$320,000',
    agent: 'Ava Williams',
    client: 'Bruno Hernandez',
  },
  {
    key: '6',
    listing: '910 Broadway St., #1301 Chicago, IL 60661',
    date: 'June 19',
    value: '$320,000',
    agent: 'Billie Hobbs',
    client: 'Martin Guptil',
  },
  {
    key: '7',
    listing: '910 Broadway St., #1301 Chicago, IL 60661',
    date: 'June 20',
    value: '$120,000',
    agent: 'Ava Williams',
    client: 'Roberto Carlos',
  },
  {
    key: '8',
    listing: '910 Broadway St., #1301 Chicago, IL 60661',
    date: 'June 21',
    value: '$320,000',
    agent: 'Ava Williams',
    client: 'Bruno Hernandez',
  },
  {
    key: '9',
    listing: '910 Broadway St., #1301 Chicago, IL 60661',
    date: 'June 23',
    value: '$320,000',
    agent: 'Billie Hobbs',
    client: 'Martin Guptil',
  },
  {
    key: '10',
    listing: '910 Broadway St., #1301 Chicago, IL 60661',
    date: 'June 29',
    value: '$120,000',
    agent: 'Ava Williams',
    client: 'Roberto Carlos',
  },
];
