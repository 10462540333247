import React from 'react';
import PropTypes from 'prop-types';

import { Icons } from '../Icons/Icons';
import styles from './styles.module.scss';

export const ProfileOption = ({ optionText }) => (
  <div className={styles.threadOption}>
    <Icons color="#FF576D" variant={Icons.PROFILE} className={styles.threadOptionIcon} />
    <span>{optionText}</span>
  </div>
);

ProfileOption.propTypes = {
  optionText: PropTypes.string,
};

ProfileOption.defaultProps = {
  optionText: '',
};
