import { useState, useRef } from 'react';
import { useOutsideClick } from 'hooks';
import { useDispatch } from 'react-redux';

import { Popover } from 'components-antd';
import { showSuccessMessage } from 'helpers';
import { ConfirmationDialog } from 'components';
import { unlinkFormProcessEffect } from 'store/effects/formProcess';
import { getTransactionTaskEffect } from 'store/effects';
import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';

import styles from './styles.module.scss';

export const Actions = ({ data, taskId, transactionId }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const dotsRef = useRef();
  const optionsRef = useRef();

  useOutsideClick([dotsRef, optionsRef], () => setOpen(false));

  const onClick = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const unlinkForm = async () => {
    setConfirmationLoading(true);
    dispatch(
      unlinkFormProcessEffect(
        {
          formProcessId: data.formProcessId,
          taskId,
        },
        (err) => {
          if (!err) {
            dispatch(getTransactionTaskEffect({ transactionId, taskId }));
            setShowConfirmationModal(false);
            showSuccessMessage('Form unlinked successfully.');
          }
          setConfirmationLoading(false);
        },
      ),
    );
  };

  return (
    <>
      <Popover
        content={
          <div>
            <div className={styles.option} onClick={() => setShowConfirmationModal(true)}>
              <Icon className={styles.icon} variant={Icon.DELETE} />
              Unlink Form
            </div>
          </div>
        }
        className={styles.antdCustomPopover}
        trigger="hover"
        placement="bottomRight"
        overlayClassName={styles.optionsDropDown}
      >
        <div>
          <Icon variant={Icon.DOTS} color={'#747475'} />
        </div>
      </Popover>
      <ConfirmationDialog
        onReject={() => setShowConfirmationModal(false)}
        onConfirm={() => unlinkForm()}
        isOpen={showConfirmationModal}
        confirmText={'Unlink'}
        isPending={confirmationLoading}
        className={styles.unlinkFormDialog}
      >
        <div
          className={styles.content}
        >{`Are you sure you want to unlink ${data.formName} from this task?`}</div>
      </ConfirmationDialog>
    </>
  );
};
