import React from 'react';
import styles from './styles.module.scss';

export const ErrorMessage = (props) => {
  const { message, id } = props;

  return (
    <span id={id || 'error-message'} className={styles.dangerErrorMessage}>
      {message}
    </span>
  );
};
