import React, { forwardRef, useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import Suggestion from './Suggestion';
import Input from './Input';

import styles from './styles.module.scss';

const autocompleteRequestSupportedTypes = [
  'geocode',
  'address',
  'establishment',
  '(regions)',
  '(cities)',
];

const GeoCoderComponent = forwardRef((props, ref) => {
  const { className, onResult, placeholder, countries, types, value, onClear, inputLabel } = props;
  const {
    onChange,
    variant,
    dropdownClassName,
    searchWrapperClassName,
    autoFocus,
    allowedManualInput,
  } = props;
  const [localValue, setLocalValue] = useState(value?.PlaceName || '');
  const [isPending, setIsPending] = useState(false);

  useEffect(() => setLocalValue(value?.PlaceName || ''), [value?.PlaceName]);

  const onSelectHandler = useCallback(
    (placeName, placeId, placeInfo) => {
      setIsPending(true);
      geocodeByPlaceId(placeId).then((res) => {
        getLatLng(res[0]).then((coordinates) => {
          onResult({ result: res, placeName, coordinates, placeInfo });
          setLocalValue(placeName);
          setIsPending(false);
        });
      });
    },
    [setIsPending, onResult],
  );

  const onChangeHandler = useCallback(
    (val) => {
      if (val === '') {
        onClear();
      }
      onChange(val);
      setLocalValue(val);
    },
    [onChange, setLocalValue, onClear],
  );

  const searchTypes = useMemo(
    () => (types || []).filter((type) => autocompleteRequestSupportedTypes.includes(type)),
    [types],
  );

  const searchOptions = useMemo(
    () => ({
      componentRestrictions: { country: countries },
      // Google Autocomplete allows to specify only one type
      types: searchTypes?.length ? [searchTypes[0]] : undefined,
    }),
    [countries, searchTypes],
  );

  return (
    <div className={classNames(styles.placesAutocompleteWrapper, searchWrapperClassName)}>
      <div
        testid="geocoder"
        ref={ref}
        className={classNames(styles.wrapper, styles[variant], className)}
      >
        <PlacesAutocomplete
          value={localValue}
          onChange={onChangeHandler}
          onSelect={onSelectHandler}
          searchOptions={searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <>
              <Input
                getInputProps={getInputProps}
                placeholder={placeholder}
                loading={loading || isPending}
                variant={variant}
                autoFocus={autoFocus}
                label={inputLabel}
                allowedManualInput={allowedManualInput}
              />
              {!allowedManualInput && suggestions?.length ? (
                <div className={classNames(styles.dropdownWrapper, dropdownClassName)}>
                  <div testid="suggestions" className={classNames(styles.suggestions)}>
                    {suggestions.map((suggestion) => (
                      <Suggestion
                        key={suggestion.placeId}
                        getSuggestionItemProps={getSuggestionItemProps}
                        suggestion={suggestion}
                      />
                    ))}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </PlacesAutocomplete>
      </div>
    </div>
  );
});

GeoCoderComponent.propTypes = {
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  searchWrapperClassName: PropTypes.string,
  onResult: PropTypes.func,
  placeholder: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  variant: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.shape({
    PlaceName: PropTypes.string,
  }),
  onClear: PropTypes.func,
  autoFocus: PropTypes.bool,
  allowedManualInput: PropTypes.bool,
};

GeoCoderComponent.defaultProps = {
  className: '',
  dropdownClassName: '',
  searchWrapperClassName: '',
  onResult: () => {},
  placeholder: 'Enter address or location name',
  onChange: () => {},
  countries: ['us'],
  variant: 'lightFull',
  types: [],
  value: null,
  onClear: () => {},
  autoFocus: false,
  allowedManualInput: false,
};

export default GeoCoderComponent;
