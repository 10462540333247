import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { ProfileIcons } from 'components/ClientsDrawer/ProfileClient/Icons/ProfileIcons';
import { Popover } from 'components-antd';
import { ArrowDown, ArrowUp } from 'components/Icons';
import { AddressListing } from './AddressListing';
import { AddEditAddress } from './AddEditAddress';
import { useOutsideClick } from 'hooks';
import { ClientAddress } from 'app-constants';

export const AddressesMenu = ({ testid, label, clientAddresses, className, clientId }) => {
  const addressRef = useRef(null);
  const [addresses, setAddresses] = useState<ClientAddress[]>(clientAddresses ?? []);
  const [editAddress, setEditAddress] = useState<ClientAddress | null>(null);
  const [editAddressIndex, setEditAddressIndex] = useState<number>(-1);
  const [open, setOpen] = useState(false);
  const [showAddAddressMenu, setShowAddAddressMenu] = useState(false);

  useOutsideClick([addressRef], () => {
    setOpen(false);
  });

  const getPrimaryAddress = () => {
    const primaryAddress = addresses.find((clientAddress) => clientAddress.isPrimary);
    return (
      <div testid={testid} className={classNames(styles.value, styles.address)}>
        {primaryAddress ? (
          <>
            <p>{primaryAddress?.address.split(',')[0] || ''}</p>
            <p>{`${primaryAddress?.address.split(',')[1] || '-'}, ${
              primaryAddress?.address.split(',')[2] || '-'
            }`}</p>
          </>
        ) : (
          <p>{'-'}</p>
        )}
      </div>
    );
  };
  const handleAddressClick = (address: ClientAddress) => {
    setEditAddress(address);
    setShowAddAddressMenu(true);
    setEditAddressIndex(addresses?.indexOf(address));
  };
  const handleAddAddressClick = () => {
    setEditAddress(null);
    setShowAddAddressMenu(true);
  };

  useEffect(() => {
    if (!open) {
      setEditAddress(null);
      setEditAddressIndex(-1);
    }
  }, [open]);

  const handleCancelClick = () => {
    setOpen(false);
  };
  return (
    <div
      testid="item_wrapper"
      ref={addressRef}
      className={classNames(styles.addressListing, className)}
    >
      <Popover
        content={
          showAddAddressMenu ? (
            <AddEditAddress
              addresses={addresses}
              clientId={clientId}
              setAddresses={setAddresses}
              setPoperOverOpen={setOpen}
              editAddressIndex={editAddressIndex}
              editAddress={editAddress}
              setEditAddress={setEditAddress}
              handleCancelClick={handleCancelClick}
            />
          ) : (
            <AddressListing
              addresses={addresses}
              handleAddressClick={handleAddressClick}
              isPopoverOpen={open}
              clientId={clientId}
              handleAddAddressClick={handleAddAddressClick}
            />
          )
        }
        placement="bottom"
        overlayClassName={styles.popoverOverlay}
        getPopupContainer={(triggerNode) => triggerNode}
        open={open}
      >
        <div className={styles.iconLabel}>
          <ProfileIcons name={ProfileIcons.ADDRESS} />
          <div className={classNames(styles.label)}>{label}</div>
          <div
            className={styles.iconContainer}
            onClick={() => {
              setShowAddAddressMenu(false);
              setOpen((val) => !val);
            }}
          >
            {open ? (
              <ArrowUp className={styles.icon} color="#515151" />
            ) : (
              <ArrowDown className={styles.icon} color="#515151" />
            )}
          </div>
        </div>
      </Popover>

      {getPrimaryAddress()}
    </div>
  );
};
