import {
  openActivityFiltersDrawerAction,
  setActivityFiltersDrawerUsersFieldAction,
  setActivityFiltersDrawerPropertiesFieldAction,
  setActivityFiltersDrawerStartDateFieldAction,
  setActivityFiltersDrawerEndDateFieldAction,
  resetActivityFiltersDrawerFieldsAction,
  sortActivityListByOrderAction,
} from 'store/actions/drawers/activityFilters';

export const openActivityFiltersDrawerEffect = (cfg) => (dispatch) =>
  dispatch(openActivityFiltersDrawerAction(cfg));

export const sortActivityListByOrderEffect = (cfg) => (dispatch) =>
  dispatch(sortActivityListByOrderAction(cfg));

export const setActivityFiltersDrawerUsersFieldEffect = (cfg) => (dispatch) =>
  dispatch(setActivityFiltersDrawerUsersFieldAction(cfg));

export const setActivityFiltersDrawerPropertiesFieldEffect = (cfg) => (dispatch) =>
  dispatch(setActivityFiltersDrawerPropertiesFieldAction(cfg));

export const setActivityFiltersDrawerStartDateFieldEffect = (cfg) => (dispatch) =>
  dispatch(setActivityFiltersDrawerStartDateFieldAction(cfg));

export const setActivityFiltersDrawerEndDateFieldEffect = (cfg) => (dispatch) =>
  dispatch(setActivityFiltersDrawerEndDateFieldAction(cfg));

export const resetActivityFiltersDrawerFieldsEffect = (cfg) => (dispatch) =>
  dispatch(resetActivityFiltersDrawerFieldsAction(cfg));
