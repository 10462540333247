import React from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { TitleValueTable } from 'components';
import { transformJSXResponse } from 'utils';
import { getPropertyAddress, getPropertyTwoLinesAddress } from 'helpers';

import styles from './styles.module.scss';
import { Icons } from 'pages/Quotes/Icons';

export const Highlights = ({ quote, UploadDate }) => {
  const formResponses =
    quote?.FormResponses?.map((r) => ({
      title: `${r?.Question}:`,
      value: transformJSXResponse(r?.Answer || '-'),
    })) || [];
  const highlightValues = [
    {
      title: 'Property:',
      value: quote?.Property?.Address ? getPropertyTwoLinesAddress(quote.Property.Address) : '-',
    },
    ...formResponses,
  ];
  const onDownload = () => {
    axios.get(quote.RequestQuoteFile?.Url, { responseType: 'blob' }).then((response) => {
      fileDownload(response.data, `${getPropertyAddress(quote?.Property?.Address)}.pdf`);
    });
  };
  return (
    <div className={styles.highlights}>
      <div className={styles.header}>
        <div>
          <span className={styles.recentQuoteTitle}>Request Details</span>
          <br />
          <span className={styles.recentQuoteDate}>{UploadDate}</span>
        </div>
        <div onClick={onDownload} className={styles.commentWrapper}>
          <Icons className={styles.clickable} testid="comment_icon" variant={Icons.DOWNLOAD_BG} />
        </div>
      </div>
      <div className={styles.content}>
        <TitleValueTable items={highlightValues} />
      </div>
    </div>
  );
};
