import { Button, Collapse, Panel } from 'components-antd';

import { PropertyCollapsibleOptions } from './PropertyCollapsibleOptions';
import { Add, Remove } from 'components/Icons';
import Icons from '../../Icons/Icons';

import styles from './styles.module.scss';
import { CriteriaType } from './../';
import { PREF_TYPE, prefsIds } from 'settings/constants/preferences';
import { ParkingSpaces } from '../ParkingSpaces';
import { FC, useEffect, useRef, useState } from 'react';
import { CustomPreference } from './CustomPreference';
import _ from 'lodash';

type PanelHeaderType = {
  showCount?: boolean;
  count?: number;
  icon: string;
  title: string;
};

const PanelHeader = ({ showCount = false, count = 0, icon, title }: PanelHeaderType) => (
  <div className={styles.panelHeader}>
    <Icons variant={icon} />
    <span className={styles.title}>{title}</span>
    {showCount && count > 0 && <span className={styles.count}>({count})</span>}
  </div>
);

interface PropertyAttributesProps {
  softCriteria: any;
  addSoftCriteria: Function;
  className?: string;
  data?: any;
  additionalSections?: any;
  hideKeywords?: boolean;
  keywordsOnly?: boolean;
  showCount?: boolean;
  scrollToTop?: boolean;
}

export const PropertyAttributes: FC<PropertyAttributesProps> = ({
  softCriteria,
  addSoftCriteria,
  className = '',
  data = PropertyCollapsibleOptions,
  additionalSections,
  hideKeywords = false,
  keywordsOnly = false,
  showCount = false,
  scrollToTop = false, // Scroll the section to the top when expanded
}) => {
  const [activePanel, setActivePanel] = useState<any>(undefined);
  const customPrefRef = useRef<any>(null);
  const [customPrefs, setCustomPrefs] = useState<any>([]);
  const emptyCustomPref = {
    Keyword: '',
    ImportanceAndWeight: {
      Importance: 'Want',
    },
  };

  useEffect(() => {
    if (softCriteria?.[PREF_TYPE.keywordPrefs]) {
      const keywordPrefs = softCriteria?.[PREF_TYPE.keywordPrefs];
      setCustomPrefs(
        keywordPrefs.map(({ Keyword, ImportanceAndWeight: { Importance } }, idx) => ({
          id: idx,
          Keyword,
          ImportanceAndWeight: {
            Importance: Importance === 'Must' ? 'Need' : 'Want',
          },
        })),
      );
    } else {
      setCustomPrefs([]);
    }
  }, [softCriteria]);

  const addEmptyCustomPref = () => {
    setCustomPrefs([...customPrefs, { ...emptyCustomPref, id: customPrefs.length }]);
    customPrefRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const saveCriteria = (arr) => {
    addSoftCriteria(
      PREF_TYPE.keywordPrefs,
      undefined,
      undefined,
      arr?.map(({ Keyword, ImportanceAndWeight: { Importance } }) => ({
        Keyword,
        ImportanceAndWeight: {
          Importance: Importance === 'Need' ? 'Must' : 'Somewhat',
        },
      })),
    );
  };

  const modifyCustomPref = (id, updateObj) => {
    setCustomPrefs((prevArray) => {
      const index = _.findIndex(prevArray, { id: id });
      if (index !== -1) {
        const newArray = [...prevArray];
        newArray[index] = _.assign({}, newArray[index], updateObj);
        saveCriteria(newArray);
        return newArray;
      }

      return prevArray;
    });
  };

  const onRemoveCustomPref = (id) => {
    setCustomPrefs((prevArray) => {
      const newArray = prevArray?.filter((obj) => obj.id !== id);
      const rearrangedArray = newArray.map((obj, index) => ({
        ...obj,
        id: index,
      }));
      saveCriteria(rearrangedArray);
      return rearrangedArray;
    });
  };

  if (keywordsOnly) {
    return (
      <div className={className} ref={customPrefRef}>
        <div className={styles.preferencesContainer}>
          {customPrefs?.map((item, idx) => (
            <CustomPreference
              key={idx}
              keyword={item?.Keyword}
              selectedImportance={item?.ImportanceAndWeight?.Importance}
              onChange={(value, key) => modifyCustomPref(idx, { ...item, [key]: value })}
              onRemove={() => onRemoveCustomPref(idx)}
            />
          ))}
          <Button
            className={styles.addButton}
            variant="default"
            icon={<Icons variant={Icons.ADD} />}
            size="large"
            onClick={addEmptyCustomPref}
          >
            Add Feature
          </Button>
        </div>
      </div>
    );
  }

  const getCount = (item) => {
    let count = 0;
    item?.criteria?.forEach((item) => {
      const pref = softCriteria?.[item.id];
      if (pref && (pref.value === 'Need' || pref.value === 'Want')) {
        count++;
      } else if (item?.additionalOptions) {
        item?.additionalOptions?.options.forEach((option) => {
          const additionalPref = softCriteria?.[option.id];

          if (
            additionalPref &&
            (additionalPref.value === 'Need' || additionalPref.value === 'Want')
          ) {
            count++;
          }
        });
      }
    });
    return count;
  };

  return (
    <div className={className} ref={customPrefRef}>
      <Collapse
        accordion
        expandIcon={({ isActive }) =>
          isActive ? (
            <Remove color={Remove.COLLAPSE} className={styles.icon} />
          ) : (
            <Add color={Add.COLLAPSE} className={styles.icon} />
          )
        }
        expandIconPosition={'end'}
        className={styles.propertyAttributesPanel}
        ghost={true}
        onChange={(activeKey) => {
          setActivePanel(activeKey);
          if (typeof activeKey == 'string' && scrollToTop) {
            const sectionId = document.getElementById(activeKey);
            setTimeout(() => {
              sectionId?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 700);
          }
        }}
      >
        {additionalSections && (
          <>
            {additionalSections?.map((item) => (
              <Panel
                key={item?.key}
                id={item?.key}
                header={<PanelHeader icon={item?.icon} title={item?.title} />}
                className={styles.panel}
              >
                {item?.component}
              </Panel>
            ))}
          </>
        )}
        {data?.map((item) => (
          <Panel
            key={item?.key}
            id={item?.key}
            header={
              <PanelHeader
                count={getCount(item)}
                showCount={showCount && activePanel !== item?.key}
                icon={item?.icon}
                title={item?.title}
              />
            }
            className={styles.panel}
          >
            {item?.criteria?.map((criteria, idx) => (
              <CriteriaType
                key={`criteria-${idx}`}
                title={criteria?.showTitle}
                id={criteria?.id}
                additionalOptions={criteria?.additionalOptions}
                type={criteria?.type}
                softCriteria={softCriteria}
                addSoftCriteria={addSoftCriteria}
                parking={(selectedImportance) => {
                  return criteria?.id === prefsIds.parking ? (
                    <ParkingSpaces
                      id={criteria?.id}
                      type={criteria?.type}
                      softCriteria={softCriteria}
                      addSoftCriteria={addSoftCriteria}
                      selectedImportance={selectedImportance}
                    />
                  ) : null;
                }}
              />
            ))}
          </Panel>
        ))}
      </Collapse>
      {/* Add Custom Preferences */}
      {!hideKeywords && (
        <div className={styles.preferencesContainer}>
          {customPrefs?.map((item, idx) => (
            <CustomPreference
              key={idx}
              keyword={item?.Keyword}
              selectedImportance={item?.ImportanceAndWeight?.Importance}
              onChange={(value, key) => modifyCustomPref(idx, { ...item, [key]: value })}
              onRemove={() => onRemoveCustomPref(idx)}
            />
          ))}
          <Button
            className={styles.addButton}
            variant="default"
            icon={<Icons variant={Icons.ADD} />}
            size="large"
            onClick={addEmptyCustomPref}
          >
            Add Feature
          </Button>
        </div>
      )}
    </div>
  );
};
