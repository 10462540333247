import React from 'react';

export const ResendIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M7.0201 12.0301C6.92223 13.1718 7.18343 14.3156 7.7672 15.3017C8.35097 16.2878 9.22816 17.0669 10.2762 17.5302C11.3243 17.9935 12.4909 18.1179 13.6131 17.886C14.7353 17.6541 15.7571 17.0774 16.5356 16.2366C17.3142 15.3958 17.8107 14.3328 17.9558 13.1961C18.1008 12.0594 17.8872 10.9057 17.3448 9.89633C16.8024 8.88692 15.9582 8.07215 14.9302 7.56582C13 6.61512 10 7 10 7M10 7L13 4M10 7L13 9.89633"
        stroke="#2B59DA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
