import { TabPane, Tabs } from 'components-antd';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getSelectedTeamNameSelector } from 'store/selectors/adminPanel';
import { routes } from 'settings/navigation/routes';

import Details from './Components/Details';
import Header from './Components/Header';
import { Templates } from './Components/Templates';

import { Onboarding } from './Onboarding';
import { TeamSubscriptionDetails } from './TeamSubscriptionDetails';

import styles from './styles.module.scss';
import Members from './Members/Members';
import SeatsLogs from './SeatsLogs';

export const TeamDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const teamName = useSelector(getSelectedTeamNameSelector);
  const [tabKey, setTabKey] = useState('1');

  const TABS_ITEMS = useMemo(
    () => [
      {
        key: '1',
        label: 'Details',
        children: <Details />,
      },
      {
        key: '4',
        label: 'Templates',
        children: <Templates />,
      },
      {
        key: '2',
        label: 'Subscriptions',
        children: <TeamSubscriptionDetails />,
      },
      {
        key: '5',
        label: 'Onboarding',
        children: <Onboarding />,
      },
      {
        key: '3',
        label: 'Members',
        children: <Members />,
      },
      {
        key: '6',
        label: 'Seats',
        children: <SeatsLogs />,
      },
    ],
    [tabKey],
  );

  const onGoBackBtnClick = () => {
    history.push(routes.teams);
  };

  return (
    <div>
      <Header title={teamName} onButtonClick={onGoBackBtnClick} />
      <div className={styles.tabs}>
        <Tabs activeKey={tabKey} onChange={setTabKey} defaultActiveKey={'1'}>
          {TABS_ITEMS.map(({ key, children, label }) => (
            <TabPane
              tab={
                <div className={styles.tabRow}>
                  <span>{label} </span>
                </div>
              }
              key={key}
            >
              {children}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default TeamDetails;
