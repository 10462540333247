import { Tag, Tooltip } from 'antd';
import { TriggerMockTestEvent } from 'pages/Dashboard/AuditEvents/TriggerMockEvent/TriggerMockEvent';
import { TableColumnsType } from '../components/Table';

export type EventsDataSource = {
  key: string;
  topic: string;
  auditEventType: string;
  auditLogicNotes: string;
  notificationEventType: string;
  notificationCategory: string;
  notificationEligibleRecipients: string[];
  notificationSubject: string;
  notificationLogicNotes: string;
  notificationChannels: string;
  testable?: boolean;
};

export const eventsColumns: TableColumnsType<EventsDataSource> = [
  {
    title: 'Key',
    dataIndex: 'key',
    withSort: false,
  },
  {
    title: 'Topic',
    dataIndex: 'topic',
    withSort: false,
    render: (topic) => (
      <Tooltip title={topic}>
        <div
          style={{
            width: '500px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {topic || '---'}
        </div>
      </Tooltip>
    ),
    width: 500,
  },
  {
    title: 'Audit Event Type',
    dataIndex: 'auditEventType',
    withSort: false,
    render: (v) => v || '---',
  },
  {
    title: 'Audit Logic Notes',
    dataIndex: 'auditLogicNotes',
    withSort: false,
    render: (v) => <div style={{ minWidth: '200px' }}>{v || '---'}</div>,
  },
  {
    title: 'Notification Event Type',
    dataIndex: 'notificationEventType',
    withSort: false,
    render: (v) => v || '---',
  },
  {
    title: 'Notification Category',
    dataIndex: 'notificationCategory',
    withSort: false,
    render: (v) => v || '---',
  },
  {
    title: 'Eligible Recipients',
    dataIndex: 'notificationEligibleRecipients',
    render: (recipients) => (recipients ? recipients?.join(', ') : '---'),
    withSort: false,
  },
  {
    title: 'Notification Subject',
    dataIndex: 'notificationSubject',
    withSort: false,
    render: (v) => v || '---',
  },
  {
    title: 'Notification Logic Notes',
    dataIndex: 'notificationLogicNotes',
    withSort: false,
    render: (v) => <div style={{ minWidth: '200px' }}>{v || '---'}</div>,
  },
  {
    title: 'Notification Channels',
    dataIndex: 'notificationChannels',
    render: (channels) => {
      const channelsEl = Object.keys(channels || {})
        .filter((key) => channels[key]?.enabled)
        .map((key) => (
          <Tooltip title={channels[key].template || ''} key={key}>
            <Tag color="blue" style={{ margin: '2px', cursor: 'default' }}>
              {key}
            </Tag>
          </Tooltip>
        ));
      return channelsEl.length ? channelsEl : '---';
    },
    withSort: false,
  },
  {
    title: 'Trigger',
    dataIndex: 'testable',
    render: (testable, record) => {
      if (testable) {
        const eventName = record.auditEventType || record.notificationEventType;
        const topic = record.topic;
        const subEvent = record?.auditEventType ? 'Audit' : 'Notification';
        return (
          <TriggerMockTestEvent
            key={`${subEvent}-${eventName}`}
            eventName={eventName}
            topic={topic}
            subEvent={subEvent}
          />
        );
      }
      return null;
    },
    withSort: false,
  },
];
