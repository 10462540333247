import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import BaseNotification from '../../../BaseNotification';
import PropertyIcon from '../../../PropertyIcon';

import styles from './styles.module.scss';
import { link } from 'settings/navigation/link';

interface CounterWithdrawnProps {
  Ids: number[];
  Payload: any;
  className: string;
  title: string;
  IsRead: boolean;
  CreatedDate: string;
  groupType: string;
  SeverityLevel: string;
}

const CounterWithdrawn = (props: CounterWithdrawnProps) => {
  const {
    className = '',
    Ids,
    IsRead,
    CreatedDate,
    groupType,
    title,
    SeverityLevel,
    Payload,
  } = props;
  const history = useHistory();

  const onClickHandler = () => {
    if (Payload?.TransactionId && Payload?.OfferUUID) {
      history.push(link.toTransactionOffers(Payload.TransactionId, Payload.OfferUUID));
    }
  };

  return (
    <div className={classNames(styles.notificationCounterWithdrawn, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        groupType={groupType}
        avatarPlaceholder={<PropertyIcon />}
        severityLevel={SeverityLevel}
        onClick={onClickHandler}
      />
    </div>
  );
};

export default CounterWithdrawn;
