import PropTypes from 'prop-types';
import { Button } from 'components-antd';
import { useDispatch } from 'react-redux';
import { allowMlsAccessForAgent } from 'api/admin/users';
import { getAllMlsAgentsDashboardEffect } from 'store/effects/adminPanel/mlsAgents';
import { MlsAccessStatusEnum } from '../constants';

const ApproveMlsAgentButton = (props) => {
  const dispatch = useDispatch();
  const { status, mlsId, userId } = props;

  if (
    ![
      MlsAccessStatusEnum.REQUESTED,
      MlsAccessStatusEnum.REJECTED,
      MlsAccessStatusEnum.INACTIVE,
    ].includes(status)
  ) {
    return null;
  }

  const handleAllowMlsAccessClick = async (mlsId, userId) => {
    await allowMlsAccessForAgent({
      userId,
      mlsId,
    });
    dispatch(getAllMlsAgentsDashboardEffect({}));
  };

  return <Button onClick={() => handleAllowMlsAccessClick(mlsId, userId)}>Approve</Button>;
};

ApproveMlsAgentButton.propTypes = {
  status: PropTypes.string,
  mlsId: PropTypes.string,
};

ApproveMlsAgentButton.defaultProps = {
  status: '',
  mlsId: '',
};

export default ApproveMlsAgentButton;
