/* eslint-disable jsx-a11y/label-has-associated-control */
import { useFormik } from 'formik';
import { uniqBy } from 'lodash-es';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import {
  getCommonEditProfileFields,
  getUserRolesMapSelector,
  getSpecificRoleProfileData,
  getAgentBrokerageSettingsData,
  isUserPending,
  isPartnerSelector,
  getUserDataSelector,
} from 'store/selectors/user';
import Input from 'components/Form/Input';
import { Button, AddPhoto, Select } from 'components';
import { ClientAddress } from 'app-constants';
import Textarea from 'components/Form/Textarea';
import Locations from 'components/Form/Locations';
import { getValidationSchema } from './validation';
import { EditClientAddresses } from './EditClientAddresses';
import { sortLocations, getPropertyAddress } from 'helpers';
import { TitleWithButtons } from 'components/SettingsGroup';
import { userFullUpdateDataEffect } from 'store/effects/user';
import InputsList from '../../SettingsProfile/EditMode/InputList';
import { getLocationContext, getPlaceComponent } from 'helpers/locations';
import { LOCALITY, POSTAL_CODE, ROUTE, STATE, STREET_NUMBER } from 'settings/constants/locations';

import styles from '../styles.module.scss';
import moment from 'moment';

const timezones = [
  'US/Alaska',
  'US/Arizona',
  'US/Central',
  'US/Mountain',
  'US/Pacific',
  'US/Eastern',
  'US/Hawaii',
];

const mosaikTimezones = ['Poland', 'Asia/Manila', 'Asia/Karachi'];

const SettingsFullProfileEditMode = ({ changeEditMode }) => {
  const dispatch = useDispatch();
  const { isAgent, isThirdParty, isClient } = useSelector(getUserRolesMapSelector);
  const isUserPartner = useSelector(isPartnerSelector);
  const { avatar, firstName, lastName, phones, email, avatarPlaceholder, timezone } = useSelector(
    getCommonEditProfileFields,
  );
  const {
    bio,
    links,
    address,
    website,
    companyBusinessName,
    companyJobTitle,
    companyAddress,
    companySuiteUnit,
    isPartner,
  } = useSelector(getSpecificRoleProfileData);
  const {
    brokerage: {
      name: brokerageName,
      address: { line1, city, state, zip },
      officeSuiteUnit,
    },
    areasOfOperation = [],
  } = useSelector(getAgentBrokerageSettingsData);
  const user = useSelector(getUserDataSelector);

  const isPending = useSelector(isUserPending);
  const onSubmit = useCallback(
    (values) => {
      dispatch(
        userFullUpdateDataEffect({ isAgent, isThirdParty, isClient, values }, (err) => {
          if (!err) {
            changeEditMode();
          }
        }),
      );
    },
    [dispatch, changeEditMode, isAgent, isThirdParty, isClient],
  );

  const timezoneOptions = useMemo(() => {
    let options = timezones;
    const offsetInMinutes = moment().utcOffset();
    const offset = offsetInMinutes / 60;
    if (
      (email.includes('@mosaik') || email.includes('@yopmail')) &&
      !(offset <= -4 && offset >= -9)
    ) {
      options = [...timezones, ...mosaikTimezones];
    }
    return Object.values(options).map((el) => ({
      name: el,
      value: el,
    }));
  }, [email]);

  const formik = useFormik({
    initialValues: {
      email,
      phones,
      firstName,
      lastName,
      bio,
      address,
      links,
      timezone: timezone || '',
      website:
        user.ThirdParty && user.ThirdParty.Company && user.ThirdParty.Company.Website
          ? user.ThirdParty.Company.Website
          : '',
      companyBusinessName,
      companyJobTitle,
      companyAddress,
      companySuiteUnit,
      brokerageName,
      officeAddress: line1
        ? {
            city,
            state,
            zip,
            line1,
            PlaceName: [line1, city, state].filter((i) => !!i).join(', '),
            ProviderPlaceId: line1, // Just set to the address line1, because we do not save ProviderPlaceId for Brokerage Address
          }
        : null,
      officeSuiteUnit,
      areasOfOperation,
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema({
      isAgent,
      isThirdParty,
      isClient,
      isPartner,
    }),
    onSubmit,
  });

  const onOfficeAddressChange = useCallback(
    (result) => {
      const location = result?.result?.[0];
      if (location) {
        const updatedCity = getLocationContext(location, LOCALITY);
        const updatedState = getLocationContext(location, STATE);
        const updatedZip = getLocationContext(location, POSTAL_CODE);
        const street = getPlaceComponent(location, ROUTE);
        const streetNumber = getLocationContext(location, STREET_NUMBER);

        formik.setFieldValue('officeAddress', {
          city: updatedCity,
          state: updatedState,
          zip: updatedZip,
          line1: [streetNumber, street?.name].filter((i) => !!i).join(' '),
          ProviderPlaceId: location.place_id,
          PlaceName: result?.placeName,
        });
      } else {
        formik.setFieldValue('officeAddress', null);
      }
    },
    [formik],
  );

  const onClientChangeAddress = (address) => {
    formik.setFieldValue('address', address);
  };

  const onAreasOfOperationChange = useCallback(
    (result, prepareData) => {
      let value = prepareData?.length
        ? [...prepareData, ...(formik.values.areasOfOperation || [])]
        : formik.values.areasOfOperation || [];

      if (result?.deleteItemId) {
        value = value.filter(({ ProviderPlaceId: itemId }) => itemId !== result.deleteItemId);
      }

      value = uniqBy(value, 'ProviderPlaceId');
      value.sort(sortLocations);
      formik.setFieldValue('areasOfOperation', value);
    },
    [formik],
  );
  const onTimezoneChange = useCallback(
    (e, { value }) => formik.setFieldValue('timezone', value),
    [formik],
  );

  return (
    <form testid="edit_profile_form" onSubmit={formik.handleSubmit}>
      <TitleWithButtons
        className={styles.title}
        title={isUserPartner ? 'Profile Settings' : 'Account Settings'}
      >
        {formik.dirty ? (
          <Button
            testid="profile_save"
            type="submit"
            className={styles.saveBtn}
            title="Save"
            isPending={isPending}
          />
        ) : (
          <Button
            testid="profile_save"
            type="button"
            className={styles.saveBtn}
            title="Save"
            onClick={changeEditMode}
          />
        )}
      </TitleWithButtons>
      <div className={styles.profileWrapper}>
        <div className={styles.leftBlock}>
          <AddPhoto
            className={styles.updateAvatar}
            onChange={(image, files) => formik.setFieldValue('file', files[0])}
            value={avatar}
            placeholder={avatarPlaceholder}
          >
            <span>Update</span>
          </AddPhoto>
        </div>
        <div className={styles.rightBlock}>
          {isPartner ? (
            <h3 className={styles.sectionTitle}>Primary Contact Profile</h3>
          ) : (
            <h3 className={styles.sectionTitle}>Personal Info</h3>
          )}
          {!!isUserPartner && (
            <p className={styles.partnerDesc}>
              This what clients and collaborators will see on your personal contact card.
            </p>
          )}
          <div className={styles.inputBlock}>
            <label htmlFor="firstName" className={styles.editLabel}>
              First name
            </label>
            <div className={styles.rightBlock}>
              <Input
                id="firstName"
                className={styles.input}
                inputClassName={styles.inputField}
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName ? formik.errors.firstName : ''}
                disabled={isPending}
                testid="first_name"
              />
            </div>
          </div>
          <div className={styles.inputBlock}>
            <label htmlFor="lastName" className={styles.editLabel}>
              Last name
            </label>
            <div className={styles.rightBlock}>
              <Input
                id="lastName"
                className={styles.input}
                inputClassName={styles.inputField}
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName ? formik.errors.lastName : ''}
                disabled={isPending}
                testid="last_name"
              />
            </div>
          </div>
          {isUserPartner && (
            <div className={styles.inputBlock}>
              <label htmlFor="companyJobTitle" className={styles.editLabel}>
                Title
              </label>
              <div className={styles.rightBlock}>
                <Input
                  id="companyJobTitle"
                  className={styles.input}
                  inputClassName={styles.inputField}
                  type="text"
                  name="companyJobTitle"
                  value={formik.values.companyJobTitle}
                  onChange={formik.handleChange}
                  error={formik.touched.companyJobTitle ? formik.errors.companyJobTitle : ''}
                  disabled={isPending}
                  testid="job_title"
                />
              </div>
            </div>
          )}
          <div className={styles.inputBlock}>
            <label htmlFor="email" className={styles.editLabel}>
              Email
            </label>
            <div className={styles.rightBlock}>
              <Input
                id="email"
                className={styles.input}
                inputClassName={styles.inputField}
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email ? formik.errors.email : ''}
                disabled={isPending}
                testid="email"
              />
            </div>
          </div>
          <div className={classNames(styles.inputBlock, styles.inputMultiple)}>
            <span className={styles.editLabel}>Phone</span>
            <div className={styles.rightBlock}>
              <div className={styles.input}>
                <InputsList
                  testid="phones_list"
                  isPhone
                  disabled={isPending}
                  formik={formik}
                  name="phones"
                  list={phones}
                  addBtnTitle="Add phone"
                  className={styles.inputField}
                />
              </div>
            </div>
          </div>
          <div className={styles.inputBlock}>
            <label htmlFor="email" className={styles.editLabel}>
              Time Zone
            </label>
            <div className={styles.rightBlock}>
              <Select
                id="Timezone"
                name="timezone"
                options={timezoneOptions}
                value={formik.values.timezone}
                placeholder="Select timezone"
                onSelect={onTimezoneChange}
                disabled={isPending}
                className={{ wrapper: styles.selectWrapper }}
                error={formik.touched.timezone ? formik.errors.timezone : ''}
                testid="timezone_select"
              />
            </div>
          </div>
          {isClient && (
            <EditClientAddresses
              clientAddresses={formik.values.address}
              error={formik.touched.address ? formik.errors.address : ''}
              disabled={isPending}
              onChangeAddress={onClientChangeAddress}
            />
          )}
          {isAgent && (
            <div className={classNames(styles.inputBlock, styles.inputMultiple)}>
              <span className={styles.editLabel}>Website</span>
              <div className={styles.rightBlock}>
                <div className={styles.input}>
                  <InputsList
                    testid="links_list"
                    disabled={isPending}
                    formik={formik}
                    name="links"
                    list={links}
                    addBtnTitle="Add link"
                    inputClassName={styles.inputField}
                  />
                </div>
              </div>
            </div>
          )}
          {isThirdParty && !isPartner && (
            <>
              <h3 className={styles.sectionTitle}>Organization</h3>
              <div className={styles.inputBlock}>
                <label htmlFor="companyBusinessName" className={styles.editLabel}>
                  Organization Name
                </label>
                <div className={styles.rightBlock}>
                  <Input
                    id="companyBusinessName"
                    className={styles.input}
                    inputClassName={styles.inputField}
                    type="text"
                    name="companyBusinessName"
                    value={formik.values.companyBusinessName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.companyBusinessName ? formik.errors.companyBusinessName : ''
                    }
                    disabled={isPending}
                    testid="company_business_name"
                  />
                </div>
              </div>
              <div className={styles.inputBlock}>
                <label htmlFor="companyJobTitle" className={styles.editLabel}>
                  Title
                </label>
                <div className={styles.rightBlock}>
                  <Input
                    id="companyJobTitle"
                    className={styles.input}
                    inputClassName={styles.inputField}
                    type="text"
                    name="companyJobTitle"
                    value={formik.values.companyJobTitle}
                    onChange={formik.handleChange}
                    error={formik.touched.companyJobTitle ? formik.errors.companyJobTitle : ''}
                    disabled={isPending}
                    testid="job_title"
                  />
                </div>
              </div>
              <div className={styles.inputBlock}>
                <label htmlFor="companyAddress" className={styles.editLabel}>
                  Office Address
                </label>
                <div className={styles.rightBlock}>
                  <span
                    testid="profile_company_address"
                    className={styles.infoBlockForOneLine}
                    style={{ display: 'block' }}
                  >
                    {typeof companyAddress === 'string'
                      ? companyAddress
                      : getPropertyAddress(companyAddress)}
                  </span>
                </div>
                {/*<div className={styles.rightBlock}>
                  
                  <Input
                    id="companyAddress"
                    className={styles.input}
                    inputClassName={styles.inputField}
                    type="text"
                    name="companyAddress"
                    value={
                      typeof formik.values.companyAddress === 'string'
                        ? formik.values.companyAddress
                        : getPropertyAddress(formik.values.companyAddress)
                    }
                    onChange={formik.handleChange}
                    error={formik.touched.companyAddress ? formik.errors.companyAddress : ''}
                    disabled={isPending}
                    testid="office_address"
                  />
                  
                </div>*/}
              </div>
              <div className={styles.inputBlock}>
                <label htmlFor="companySuiteUnit" className={styles.editLabel}>
                  Unit / Suite
                </label>
                <div className={styles.rightBlock}>
                  <Input
                    id="companySuiteUnit"
                    className={styles.input}
                    inputClassName={styles.inputField}
                    type="text"
                    name="companySuiteUnit"
                    value={formik.values.companySuiteUnit}
                    onChange={formik.handleChange}
                    error={formik.touched.companySuiteUnit ? formik.errors.companySuiteUnit : ''}
                    disabled={isPending}
                    testid="company_apt_unit"
                  />
                </div>
              </div>
              {isThirdParty && !isPartner && (
                <div className={styles.inputBlock}>
                  <label htmlFor="website" className={styles.editLabel}>
                    Website
                  </label>
                  <div className={styles.rightBlock}>
                    <Input
                      id="website"
                      className={styles.input}
                      inputClassName={styles.inputField}
                      type="text"
                      name="website"
                      value={formik.values.website}
                      onChange={formik.handleChange}
                      error={formik.touched.website ? formik.errors.website : ''}
                      disabled={isPending}
                      testid="website"
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {isAgent && (
            <div className={styles.brokerageWrap}>
              <h3 className={styles.sectionTitle}>Brokerage</h3>
              <div className={styles.inputBlock}>
                <label htmlFor="brokerageName" className={styles.editLabel}>
                  Name
                </label>
                <div className={styles.rightBlock}>
                  <Input
                    id="brokerageName"
                    className={styles.input}
                    inputClassName={styles.inputField}
                    type="text"
                    name="brokerageName"
                    error={formik.touched.brokerageName ? formik.errors.brokerageName : ''}
                    value={formik.values.brokerageName}
                    onChange={formik.handleChange}
                    disabled={isPending}
                    testid="brokerage_name"
                  />
                </div>
              </div>
              <div className={classNames(styles.inputBlock, styles.inputBlockLocations)}>
                <Locations
                  multiple={false}
                  label="Address"
                  types={['address']}
                  variant={Locations.LIGHT}
                  showPrefixIcon={true}
                  onResult={onOfficeAddressChange}
                  value={[formik.values.officeAddress] || []}
                  error={formik.touched.officeAddress ? formik.errors.officeAddress : ''}
                  className={styles.inputBlock}
                  labelClassName={styles.editLabel}
                  blockClassName={styles.rightBlock}
                  valuesWrapperClassName={classNames(styles.input, styles.inputFieldLocations)}
                  searchWrapperClassName={styles.inputFieldPlaceholder}
                  testid="address"
                  placeholder="Enter address or location name"
                />
              </div>
              <div className={styles.inputBlock}>
                <label htmlFor="suiteUnit" className={styles.editLabel}>
                  Unit / Suite
                </label>
                <div className={styles.rightBlock}>
                  <div className={styles.input}>
                    <Input
                      id="officeSuiteUnit"
                      className={styles.input}
                      inputClassName={styles.inputField}
                      type="text"
                      name="officeSuiteUnit"
                      value={formik.values.officeSuiteUnit}
                      onChange={formik.handleChange}
                      disabled={isPending}
                      testid="suite_unit"
                    />
                  </div>
                </div>
              </div>

              <div className={styles.areasOfOperationWrapper}>
                <h3 className={styles.sectionTitle}>Areas of Operation</h3>
                <Locations
                  types={['(cities)']}
                  label="Cities"
                  variant={Locations.LIGHT}
                  showPrefixIcon={true}
                  onResult={onAreasOfOperationChange}
                  value={
                    formik.values.areasOfOperation?.filter(
                      ({ Type: type }) => type === 'City' || type === 'Zipcode',
                    ) || []
                  }
                  error={formik.touched.areasOfOperation ? formik.errors.areasOfOperation : ''}
                  className={styles.inputBlock}
                  labelClassName={styles.editLabel}
                  blockClassName={classNames(styles.rightBlock, styles.locationsBlock)}
                  errorClassName={styles.locationError}
                  valuesWrapperClassName={styles.input}
                  testid="city"
                  searchWrapperClassName={styles.inputFieldPlaceholder}
                  menuTop={true}
                />
                <Locations
                  label="Neighborhoods"
                  types={['address']}
                  variant={Locations.LIGHT}
                  showPrefixIcon={true}
                  onResult={onAreasOfOperationChange}
                  value={(formik.values.areasOfOperation || []).filter(
                    ({ Type: type }) => type !== 'City' && type !== 'Zipcode',
                  )}
                  className={styles.inputBlock}
                  labelClassName={styles.editLabel}
                  blockClassName={classNames(styles.rightBlock, styles.locationsBlock)}
                  valuesWrapperClassName={styles.input}
                  testid="neighborhoods"
                  searchWrapperClassName={styles.inputFieldPlaceholder}
                  menuTop={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

SettingsFullProfileEditMode.propTypes = {
  changeEditMode: PropTypes.func.isRequired,
};

export default SettingsFullProfileEditMode;
