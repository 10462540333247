import { Fragment } from 'react';
import { CheckboxField } from './CheckboxField';

import styles from './style.module.scss';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { useSelector } from 'react-redux';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { initialUploadErrorState } from '../../../helper';
import classNames from 'classnames';

interface TemplateCheckboxFieldsProps {
  values: any;
  errors?: any;
  onChange: (value: any) => void;
  setErrors: (errors: any) => void;
  isNewTemplate?: boolean;
}

export const TemplateCheckboxFields = ({
  values,
  onChange,
  setErrors,
  isNewTemplate,
}: TemplateCheckboxFieldsProps) => {
  const agentRole = useSelector(getAgentTeamRoleSelector);

  const isAdmin = [TEAM_ADMIN, TEAM_OWNER].includes(agentRole);

  if (isNewTemplate && !isAdmin) return <></>;

  return (
    <div
      className={classNames(styles.templateCheckboxFields, {
        [styles.newTemplateCheckboxFields]: isNewTemplate,
      })}
    >
      {!isNewTemplate ? (
        <div className={styles.checkboxWrap}>
          <CheckboxField
            name="SaveAsTemplate"
            label={'Save as Template'}
            value={values.saveAsTemplate}
            onChange={(checked) => {
              setErrors(initialUploadErrorState);
              onChange({ saveAsTemplate: checked, ...(!checked && { publishToTeam: false }) });
            }}
          />
        </div>
      ) : (
        <></>
      )}

      {isAdmin && values.saveAsTemplate ? (
        <div
          className={classNames(styles.checkboxWrap, {
            [styles.newTemplateCheckbox]: isNewTemplate,
          })}
        >
          <CheckboxField
            name="PublishToTeam"
            label={'Publish to Team'}
            value={values.publishToTeam}
            onChange={(value) => onChange({ publishToTeam: value })}
            className={styles.publishToTeamCheckbox}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
