import React from 'react';
import PropTypes from 'prop-types';

const Geotag = ({ className, color = '#ADADAD' }) => (
  <div className={className}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="10" cy="9.16797" rx="2.5" ry="2.5" stroke={color} strokeWidth="2" />
      <path
        d="M16.6666 9.16667C16.6666 12.8846 12.9805 16.3721 11.0902 17.9162C10.4487 18.4401 9.55124 18.4401 8.90979 17.9162C7.01944 16.3721 3.33331 12.8846 3.33331 9.16667C3.33331 5.48477 6.31808 2.5 9.99998 2.5C13.6819 2.5 16.6666 5.48477 16.6666 9.16667Z"
        stroke="#AAABAB"
        strokeWidth="2"
      />
    </svg>
  </div>
);

Geotag.propTypes = {
  className: PropTypes.string,
};

Geotag.defaultProps = {
  className: '',
};

export default Geotag;
