import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { differenceBy, cloneDeep } from 'lodash-es';

import { Input, Wrapper as PendingWrapper } from 'components';
import { SearchIcon } from 'components/Icons';
import { partnersSetSearchAction } from 'store/actions/partners';
import {
  getAgentRecommendedPartnersList,
  getAllPartnersList,
  getLoadingPartnersData,
  getRecommendedByMosaikAndAgentLists,
} from 'store/selectors/partners';
import { getRequestQuoteSelector } from 'store/selectors/requestQuote';
import { setRequestQuotePartnersEffect } from 'store/effects/quotes';

import { getUserRolesMapSelector } from 'store/selectors/user';
import Partner from '../../../Partner';
import Action from './Action';
import Footer from './Footer';

import styles from './styles.module.scss';

const PartnersView = (props) => {
  const { className, onDone } = props;
  const dispatch = useDispatch();
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const myRecommendedPartners = useSelector(getAgentRecommendedPartnersList);
  const { recommendedByAgent } = useSelector(getRecommendedByMosaikAndAgentLists);
  const { isPending } = useSelector(getLoadingPartnersData);
  const allPartners = useSelector(getAllPartnersList);
  const { quoteData } = useSelector(getRequestQuoteSelector);

  const isSelected = (partner) =>
    (quoteData?.Partners || []).some((quotePartner) => quotePartner?.Id === partner?.Id);

  const onTogglePartner = (partner) => {
    const copyQuotePartners = cloneDeep(quoteData?.Partners);
    if (isSelected(partner)) {
      const partnerIndex = copyQuotePartners.findIndex(
        (quotePartner) => quotePartner?.Id === partner?.Id,
      );
      copyQuotePartners.splice(partnerIndex, 1);
      dispatch(setRequestQuotePartnersEffect({ partners: copyQuotePartners }));
    } else {
      copyQuotePartners.push(partner);
      dispatch(setRequestQuotePartnersEffect({ partners: copyQuotePartners }));
    }
  };

  const renderPartners = (partners) =>
    (partners || []).map((partner) => {
      const selected = isSelected(partner);
      return (
        <Partner
          key={partner?.Id}
          logoUrl={partner?.LogoUrl}
          businessName={partner?.BusinessName}
          className={styles.partner}
          action={
            <Action
              className={classNames(styles.action, { [styles.selected]: selected })}
              selected={selected}
              onClick={() => onTogglePartner(partner)}
            />
          }
          onClick={() => onTogglePartner(partner)}
        />
      );
    });

  const onSearch = (e, val) => {
    dispatch(partnersSetSearchAction({ search: val }));
  };

  const otherPartners = useMemo(
    () =>
      differenceBy(allPartners, [...(isAgent ? myRecommendedPartners : recommendedByAgent)], 'Id'),
    [allPartners, isAgent, myRecommendedPartners, recommendedByAgent],
  );

  return (
    <div className={classNames(styles.agentView, className)}>
      <div className={styles.search}>
        <Input
          variant="light"
          placeholder={`Search`}
          inputClassName={styles.searchInput}
          onChange={onSearch}
          testid="search"
          iconLeft={<SearchIcon className={styles.searchIcon} />}
        />
      </div>

      <div className={styles.contentHolder}>
        <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
          {(!!myRecommendedPartners?.length || !!recommendedByAgent?.length) && (
            <div className={styles.innerPadding}>
              <div className={styles.blockTitle}>
                {isAgent ? 'My Recommendations' : 'Agent Recommendations'}
              </div>
              {renderPartners(isAgent ? myRecommendedPartners : recommendedByAgent)}
            </div>
          )}
          {!!otherPartners?.length && (
            <div className={styles.innerPadding}>
              <div className={styles.blockTitle}>{isAgent ? 'All' : 'Additional Lenders'}</div>
              {renderPartners(otherPartners)}
            </div>
          )}
        </PendingWrapper>
      </div>
      <Footer onDone={onDone} />
    </div>
  );
};

PartnersView.propTypes = {
  className: PropTypes.string,
  onDone: PropTypes.func,
};

PartnersView.defaultProps = {
  className: '',
  onDone: () => {},
};

export default PartnersView;
