import React from 'react';
import PropTypes from 'prop-types';

import { InviteClient } from 'components/Icons';
import { DrawerHeader } from 'components';

const InviteClientHeader = ({ setShowInviteClient }) => (
  <DrawerHeader
    title="Invite"
    buttonTitle="Cancel"
    onButtonClick={() => setShowInviteClient(false)}
    TitleIcon={<InviteClient />}
    testid="invite_client_header"
  />
);

InviteClientHeader.propTypes = {
  setShowInviteClient: PropTypes.func,
};

InviteClientHeader.defaultProps = {
  setShowInviteClient: () => {},
};

export default InviteClientHeader;
