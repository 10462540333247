import { useState, useEffect } from 'react';
import styles from './styles.module.scss';

type ReportCardPropsType = {
  name: string;
  day: number;
  month: number;
  year: number;
};

function ReportCard(props: ReportCardPropsType) {
  return <div onClick={() => {}}>Report Card</div>;
}

export default ReportCard;
