import {
  ArchiveView,
  FormEditorStatus,
  FormProcessLogAction,
  FormProcessNotificationType,
  FORM_PROCESS_USER,
  SignatoryStatus,
  SMART_FORM_QUESTION_TYPE,
  FORM_RECIPIENTS,
  FormEditorRole,
  AllowedAnonEnum,
  FormView,
  DYNAMIC_QUESTION_TYPE,
} from 'app-constants';
import {
  DateType,
  FormQuestionType,
  FormUserRoleType,
  ListBoxType,
  PDFFields,
  PDFWidget,
  PropertyLocation,
  QuestionFieldType,
  SmartListBoxType,
} from 'types';
import { FormProcessScreenType } from './utils';

export type AnonDataType = {
  token: string;
  type: AllowedAnonEnum;
};

export type AuthDataType = {
  formProcessPublicId: string;
  formDocumentPublicId: string;
};

export type TemplateQuestion = {
  type: string;
  width: number;
  height: number;
  x: number;
  y: number;
  required: boolean;
  pageNumber: number;
  UUID: string;
  roleName: string;
};

export type TemplateForm = {
  documentPublicId: string;
  questions: TemplateQuestion[];
  Order?: number;
};

export type BundleDataType = {
  saveAsTemplate?: boolean;
  categories?: string[];
  publishToTeam?: boolean;
  templateForms?: TemplateForm[];

  questions?: DynamicFormEditorQuestion[];
  templateRoleIds: { Id: number }[];

  formBundle: boolean;
  formProcessPublicId: string;
  formDocumentPublicId: string;

  allDocuments: FormBundleDocument[];
  bundleName?: string;

  templateBundle?: boolean;
  allTemplates?: BundleTemplate[];
};

export type FormProcessUserType = typeof FORM_PROCESS_USER[keyof typeof FORM_PROCESS_USER];

export type ArchiveViewType = keyof typeof ArchiveView;

export type FormDetails = {
  formName: string;
  client: string;
  clientDetails: ClientDetails[];
  formDocuments: FormDocument[];
  formDocument: FormDocument;
  isSequential: boolean;
  signatories: Signatory[];
  copyRecipients: any[];
  updateRecipients: any[];
  editors: Editors;
  historyLogs: HistoryLog[];
  isCompleted: boolean;
  isCancelled: boolean;
  allowCancel: boolean;
  formProcessDocuments: any[];
  type: string;
  propertyAddress: PropertyLocation;
  transactionId: number;
};

export type ClientDetails = {
  userId: number;
  userName: string;
};

export interface Editors {
  Client: Ent;
  Agent: Ent;
}

export interface Ent {
  users: any[];
  status: string;
  date: string;
}

export interface FormDocument {
  formName: string;
  date: Date;
  isCompleted: boolean;
  Id: number;
  isVoid: boolean;
  link: string;
  url: string;
  type: string;
}

export interface HistoryLog {
  Id: number;
  FormProcessId: number;
  Action: string;
  LogValue: null;
  CreatedDate: Date;
  UserIp: string;
  name: string;
  avatar: null;
  email: string;
  isYou: boolean;
}

export interface Signatory {
  name: string;
  email: string;
  avatar: null;
  role: string;
  userId: number;
  signatoryId: number;
  status: string;
  order: number;
  isYou: boolean;
  isDeclined: boolean;
  declineMessage: null;
  date: Date;
  allowResend: boolean;
  allowReplace: boolean;
}

export type FormBundleDetails = {
  Id: number;
  Name: string;
};

export type FormIdsType = {
  formId: number;
  FormId?: number;
  versionId: number;
  propertyTransactionId?: number;
  transactionId?: number;
  name?: string;
  dueDate?: number;
  isRequired?: boolean;
  taskId?: number;
  type?: string;
  templateName?: string;
  templateId?: number;
  documentLink?: string;
  customForm?: boolean;
  createTemplate?: boolean;
  isTemplate?: boolean;
  saveAsTemplate?: boolean;
  categories?: string[];
  publishToTeam?: boolean;

  voidedForm?: boolean;
  formDetails?: FormDetails;
  formBundle?: boolean;
  documentIds?: number[];
  formProcessId?: number;

  formTemplateIds?: number[];
  bundleTemplateIds?: number[];

  documentLinks?: string[];

  bundleId?: number;
  propertyAddress?: PropertyLocation;
  navigationInfo?: any;
  bundleTemplateName?: string;
  isLibraryTemplate?: boolean;
};

export type BundleTemplate = {
  Id: number;
  Name: string;
  DocumentLink: string;
  TeamId: number;
  CreatedDate: Date;
  UpdatedDate: Date;
  CreatedBy: number;
  UpdatedBy: null;
  FormId: number;
  PublishToTeam: boolean;
  FormVersionId: number;
  FormRolesIds: number[];
};

export type FormTemplateWizardType = {
  formId?: number;
  formVersionId?: number;

  templateName?: string;
  templateId?: number;
  createNewTemplate?: boolean;
  editMode?: boolean;
  templateSuccess?: boolean;

  formBundle?: boolean;
  bundleId?: number;
  templateIds?: number[];
  formRoleIds?: number[];

  documentLinks?: string[];
  categories?: number[];
  publishToTeam?: boolean;
  allTemplates?: BundleTemplate[];
  templateBundle?: boolean;
};

export type FormTemplateWizardParamType = {
  templateId: string;
};

export type SaveAsTemplateType = {
  saveAsTemplate?: boolean;
  categories?: string[];
  publishToTeam?: boolean;
  questions?: DynamicFormEditorQuestion[];
  templateRoleIds: { Id: number }[];
  isLibraryTemplate?: boolean;
  templateForms?: TemplateForm[];
};

export type SignatoryType = {
  UserId?: number;
  Id?: number;
  Email?: string;
  Name?: string;
  FormRole?: number;
  RoleId?: number;
  SignatureSequence: number;
  Avatar?: string;
  Role?: string;
  FormRoleId?: number;
  DocumentID?: number;
  customName?: boolean;
  sequence?: number;
  email?: string;
  signatoryId?: number;
  isReplaced?: boolean;
  userId?: number;
};

export type FetchSignatoryType = {
  signatoryParticipants: SignatoryType[];
  isSequentialSigning: boolean;
  signatoriesPresent: boolean;
};

export type SignatoriesType = { [key: string]: SignatoryType };

export type EditorType = {
  UserId?: number;
  Email?: string;
  FormRole: string;
  Name?: string;
  Avatar?: string;
};
export type EditorsType = EditorType[];

export type TagType = {
  TagId: number;
  Name: string;
};
export type TagsType = TagType[];

export type ClientType = {
  Id: number;
  FirstName: string;
  LastName: string;
  Email: string;
  AvatarUrl: string;
  self?: boolean;
};
export type ClientsType = ClientType[];

export type FormTransactionParticipant = {
  userId: number;
  formRoleId: number;
  name: string;
};

export type TransactionType = {
  Id: number;
  address?: string;
  name: string;
  participants: FormTransactionParticipant[];
  transactionClients: number[];
};

export type AgentType = ClientType;
export type AgentsType = AgentType[];

export type FormRoleType = {
  Id: number;
  RoleId: number;
  Name: string;
  UserType: FormUserRoleType;
};
export type FormRolesType = FormRoleType[];

export type PublishedFormType = {
  formId: number;
  versionId: number;
  link?: string;
  name: string;
  lastUpdatedDate: string;
  categories: string[];
  isCAR?: boolean;
  type?: string;
  templateId?: string;
  isOwner?: boolean;
  bundleId?: number;
  templateIds?: number[];
  templateLinks?: string[];
};

export type FormTemplateType = {
  id: number;
  name: string;
  url: string;
  updatedDate: string;
};

type FormBundleRelationType = {
  Id: number;
  ProcessId: number;
  FormBundleId: number;
  DocumentId: number;
  VersionId: number;
  FormId: number;
  CreatedBy: number;
  CreatedDate: Date;
  UpdatedDate: Date;
  documentIds?: number[];
};

export type FormRecentUploadsType = {
  Id: number;
  Name: string;
  url: string;
  UpdatedDate: string;
  DocumentLink: string;
  FormProcessId?: string;
  FormVersionId?: string;
  PublicId?: string;
  bundleId?: number;
  documentIds?: number[];
  FormBundleRelation?: FormBundleRelationType;
};

export type PublishedFormsType = PublishedFormType[];

export type FormTemplatesType = FormTemplateType[];

export type RecentUploadsType = FormRecentUploadsType[];

export type PreFormRecipientType = {
  id: string;
  type: FORM_RECIPIENTS;
};

export type PreFormType = {
  clientIds?: string[];
  clientName?: string;
  transactionId?: number;
  isProject?: boolean;
  clientAddress?: string;
  isSequentialSignature: boolean;
  editors?: {
    agent?: EditorsType;
    client?: EditorsType;
  };
  signatories?: SignatoriesType;
  copyRecipients?: PreFormRecipientType[];
  allowedEditors?: FormUserRoleType[];

  clientEditorRole?: number;

  address?: PropertyLocation;
  formName?: string;

  customFormRoles?: FormRolesType;

  documentLink?: string;
  templateId?: number;
  saveAsTemplateFormRolesIds?: number[];
  saveAsTemplate?: boolean;

  documentIds?: number[];
  templateIds?: number[];
  bundleId?: number;
  formProcessId?: number;
  isLibraryTemplate?: boolean;
};

export type FormProcessDataType = {
  formProcessPublicId?: string;
  formDocumentPublicId?: string;
  url?: string;
};

export type FormDocumentQuestionType = {
  Id: number;
  FormId: number;
  FormVersionId: number;
  Order: number;
  UUID: string;
  Name: string;
  Title: string;
  Type: FormQuestionType | DYNAMIC_QUESTION_TYPE;
  FieldType: QuestionFieldType;
  Fields: PDFFields[];
  RolePermissions: [];
  Required: boolean;
  Meta: DateType | ListBoxType | SmartListBoxType;
  JumpLogic: {};
  PopulateWith: string[] | null;
  CreatedDate: Date;
  UpdatedDate: Date;
  CreatedBy: number;
  UpdatedBy: number;
  FormQuestionRole: [
    {
      RoleId: number;
      QuestionId: number;
      CreatedDate: string;
      UpdatedDate: string;
      CreatedBy: null;
      UpdatedBy: null;
    },
  ];
  FormRole: number[];
  disabled: boolean;
  formName?: string;
  CustomPermissions?: string[];
};

export type PDFViewerMode = 'View' | 'Edit' | 'Sign' | 'Preview' | 'StrikeThrough';

export interface PointCoordinate {
  x: number;
  y: number;
}
export interface LineColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface StrikeThroughLine {
  start: PointCoordinate;
  end?: PointCoordinate;
  strokeWidth: number;
  color: LineColor;
  id: string;
}

export interface StrikeThrough {
  [page: number]: StrikeThroughLine[];
}

export type FormDocumentQuestionsType = FormDocumentQuestionType[];

export interface RenderFieldsType extends PDFFields {
  questionIndex: number;
  response: FormDocumentAnswerType;
  questionType: FormQuestionType;
  disabled: boolean;
  questionUUID: string;
  isLastField?: boolean;
}

export type FormDocumentAnswerType = {
  UUID: string;
  Question: string;
  Answer: string;
  DefaultAnswer: string;
};
export type FormDocumentAnswersType = { [key: string]: FormDocumentAnswerType };

export type FormEditRolesType = {
  Agent: {
    id: string;
    name: string;
    self?: boolean;
  }[];
  Client: {
    id: string;
    name: string;
    self?: boolean;
  }[];
};

export type DeferUserType = {
  Id?: number;
  FirstName?: string;
  LastName?: string;
  Email: string;
  self: boolean;
};

export type OtherDocumentsType = {
  link: string;
  screen: string;
  documentName: string;
};

export type FormDocumentType = {
  questions?: FormDocumentQuestionsType;
  answers?: FormDocumentAnswersType;
  clientName?: string;
  address?: string;
  link?: string;
  isCreator: boolean;
  documentName?: string;
  screen: FormProcessScreenType;
  formEditRoles: FormEditRolesType;
  showTermsAndConditions: boolean;
  showUnlock: boolean;
  deferredUsers?: DeferredUsers[];
  formProcessId: number;
  allDeferrableUsers: DeferUserType[];
  isEditSign: boolean;
  views: FormView[];
  allowStrikeThrough?: boolean;
  strikeThrough?: StrikeThrough;
  otherDocuments?: OtherDocumentsType[];
};

export type MetaType = {
  tags?: TagsType;
  clients?: ClientsType;
  agents?: AgentsType;
  transactions?: TransactionType[];
};

export type PublishedFormsDataType = {
  state: string;
  isPending?: boolean;
  data: {
    page: number;
    pageSize: number;
    total: number;
    forms: PublishedFormsType;
  };
};

export type FormTemplatesDataType = {
  state: string;
  data: {
    results: any;
    page: number;
    pageSize: number;
    total: number;
    forms: FormTemplatesType;
  };
};

export type FormRecentUploadsDataType = {
  state: string;
  data: {
    results: any;
    page: number;
    pageSize: number;
    total: number;
    documents: RecentUploadsType;
  };
};

export type DraftFormType = {
  id: number;
  name: string;
  client: string;
  address: string;
  lastEdited: string;
  url: string;
  type: FormProcessTypes;
};

export type DraftFormsType = {
  state: string;
  isPending?: boolean;
  drafts?: DraftFormType[];
};

export type FormDetailsDocumentType = {
  formName: string;
  date: string;
  isCompleted: boolean;
  Id: number;
  isVoid: boolean;
  link: string;
  url: string;
  type: FormProcessTypes;
  order: number;
};

export type FormDetailsSignatoryType = {
  name: string;
  email: string;
  avatar: string;
  signatoryId: number;
  status: SignatoryStatus;
  replaceable: boolean;
  order: number;
  isYou: boolean;
  isDeclined: boolean;
  declineMessage: string;
  date: string;
  userId: number;
  allowResend: boolean;
  allowReplace: boolean;
  role: string;
};

export type FormDetailsUserType = {
  name: string;
  email: string;
  avatar: string;
  isYou: boolean;
  role: string;
  date: string;
  status: FormEditorStatus;
  userId: number;
  editorId: number;
  allowResend: boolean;
  allowReplace: boolean;
  allowRemove: boolean;
};

export type FormDetailsEditorType = {
  Agent: {
    users: FormDetailsUserType[];
    remainingQuestions: number;
    status: FormEditorStatus;
    date: string;
  };
  Client: {
    users: FormDetailsUserType[];
    remainingQuestions: number;
    status: FormEditorStatus;
    date: string;
  };
};

export interface UpdatedFormLogValue {
  previousValue?: string;
  questionTitle: string;
  newValue: string;
  questionType: typeof SMART_FORM_QUESTION_TYPE[keyof typeof SMART_FORM_QUESTION_TYPE];
  dynamicForm?: boolean;
}

export interface DeclineSignatureLogValue {
  reason?: string;
}

export interface DeferredFormLogValue {
  to: string;
  questionTitles: string[];
}

export interface UndoDeferFormLogValue {
  from: string[];
  questionTitles: string[];
}

export type FormProcessLogValues = UpdatedFormLogValue | DeclineSignatureLogValue;

export type HistoryLogType = {
  Id: number;
  FormProcessId: number;
  Action: FormProcessLogAction;
  LogValue: FormProcessLogValues;
  CreatedDate: string;
  name: string;
  avatar: string;
  email: string;
  isYou: boolean;
};

export type UpdateRecipients = {
  id: number;
  FirstName: string;
  LastName: string;
  email: string;
};

export type CopyRecipients = {
  id: number;
  FirstName: string;
  LastName: string;
  email: string;
};

export type HistoryLogsType = HistoryLogType[];

export type FormProcessDocumentType = {
  DocumentName: string;
  DocumentLink: string;
};
export type FormProcessDocumentsType = FormProcessDocumentType[];

export type FormDetailsType = {
  state: string;
  isPending?: boolean;
  data?: {
    formName: string;
    client: string;
    address: string;
    isSequential: false;
    formDocuments: FormDetailsDocumentType[];
    formDocument: FormDetailsDocumentType;
    signatories: FormDetailsSignatoryType[];
    editors: FormDetailsEditorType;
    historyLogs: HistoryLogsType;
    isCompleted: boolean;
    isCancelled: boolean;
    isVoid: boolean;
    allowCancel: boolean;
    formProcessDocuments: FormProcessDocumentsType;
    updateRecipients: UpdateRecipients[];
    copyRecipients: CopyRecipients[];
    transactionId?: number;
    isOwner?: boolean;
  };
};

export type FormProcessType = {
  formName: string;
  formProcessId: number;
  formProcessPublicId: string;
  formDocumentId: number;
  client: string;
  address: string;
  url: string;
  isSign: boolean;
  isEdit: boolean;
  isOwner: boolean;
  isSignatory: boolean;
  isEditor: boolean;
  isFinalizing?: boolean;
  date: string;
  editors: FormDetailsEditorType;
  signatories: FormDetailsEditorType;
  waitingOnYou: boolean;
  status: string;
  type: FormProcessTypes;
};
export type FormProcessesType = FormProcessType[];

export type ArchiveFormType = {
  formName: string;
  formProcessId: number;
  formProcessDocumentId: number;
  url: string;
  clientId: number;
  clientName: string;
  address: string;
  isCompleted: boolean;
  isCanceled: boolean;
  date: string;
  type: FormProcessTypes;
};
export type ArchiveFormsType = ArchiveFormType[];

export type AllFormProcessesType = {
  recentlyCompletedForms: FormProcessesType;
  finalizingForms: FormProcessesType;
  waitingOnYou: FormProcessesType;
  waitingOnOthers: FormProcessesType;
  voidedForms: FormProcessesType;
  draftForms: FormProcessesType;
  canceledForms: FormProcessesType;
};

export type ClientDataMapType = {
  [key: number]: {
    Id: 3;
    FirstName: string;
    LastName: string;
    Email: string;
    AvatarUrl: string;
  };
};

export type AllArchiveFormsType = {
  archiveForms: ArchiveFormsType;
  clientDataMap: ClientDataMapType;
};

export type DeferredQuestionType = {
  Id: number;
  FormProcessPublicId: string;
  FormDocumentPublicId: string;
  QuestionId: number;
  FromUserId?: number;
  FromUserName?: string;
  FromUserEmail: string;
  ToUserId?: number;
  ToUserName?: string;
  ToUserEmail: string;
  FormProcessNotificationType: FormProcessNotificationType;
  CreatedDate: string;
  UpdatedDate: string;
};

export type DeferredQuestionsType = {
  state: string;
  isPending?: boolean;
  data?: DeferredQuestionType[];
};

export type UserSignature = {
  Id: number;
  UserId: number;
  Font: string;
  FullName?: string;
  Initials?: string;
  FullNameImage?: string;
  InitialsImage?: string;
  Signature?: string;
  CreatedDate?: string;
  UpdatedDate?: string;
  SignatureDate?: string;
  UUID: string;
};

export type DeferredUsers = {
  questions: string[];
  name: string;
  id: string;
};

export type SignatoryParticipantType = {
  AvatarUrl?: string;
  Name: string;
  Id: number | string;
  self: boolean;
  email: string;
  userId?: number;
  Role: string;
  RoleId?: number | string;
  sequence: number;
  signatoryId: number;
  done: boolean;
  allowReplace: boolean;
};

export type EditorParticipantType = {
  AvatarUrl?: string;
  Name: string;
  Id: number | string;
  self: boolean;
  email: string;
  userId?: number;
  editorId: number;
  sequence: number;
  Role: FormEditorRole;
  allowedQuestions?: string[];
  done: boolean;
  allowReplace: boolean;
  allowRemove: boolean;
};

export type PostFormType = {
  editorParticipants: EditorParticipantType[];
  signatoryParticipants: SignatoryParticipantType[];
  isSequentialSigning: boolean;
  editorsPresent: boolean;
  signatoriesPresent: boolean;
  remainingQuestions?: boolean;
};

export interface DynamicFormEditorQuestion extends PDFWidget {
  required?: boolean;
  id?: number;
  order?: number;
  UUID: string;
  type: DYNAMIC_QUESTION_TYPE;
  isUpdated?: boolean;
  roleName: string;
  isDeleted?: boolean;
  customRole?: PDFEditorRole;
  customIndex?: number;
  strokeWidth?: number;
  strokeColor?: { r: number; g: number; b: number; a: number };
}

export interface DynamicFormEditorQuestionUpdate extends DynamicFormEditorQuestion {
  isNew?: boolean;
  index?: number;
  newRole?: string;
  templateEditMode?: boolean;
  roleId?: string;
  documentIndex: number;
  isCohesiveFlow?: boolean;
}

export type PDFEditorColor = {
  background: string;
  border: string;
};

export type PDFEditorRole = {
  roleId: string;
  roleName: string;
  userName: string;
  avatar?: string;
  color?: PDFEditorColor;
  formRole?: number;
};

export type DynamicFormQuestions = {
  [page: number]: {
    [roleName: string]: DynamicFormEditorQuestion[];
  };
};
export type DynamicFormSelectedField = {
  pageNumber: number;
  roleName: string;
  UUID: string;
  roleId?: string;
};

export type DynamicSelectedForm = {
  documentLink: string;
  selectedFormPage: number;
  UUID: string;
};

export type PDFEditorConfig = {
  formRoles: PDFEditorRole[];
  questions: DynamicFormQuestions;
  selectedRole: PDFEditorRole;
  selectedField?: DynamicFormSelectedField;
  lastSave?: Date;
  lastUpdated?: Date;
  selectedForm?: DynamicSelectedForm;
};

export type DynamicFormMeta = {
  link: string;
  isCreator: boolean;
  documentName?: string;
  clientName?: string;
  address?: string;
  formId?: number;
  formVersionId: number;
};

export type SignatureFormRole = {
  roleId: string;
  roleName: string;
};

export type DynamicSignatureConfig = {
  isCreator?: boolean;
  showSendDocumentScreen?: boolean;
  activeQuestions: FormDocumentQuestionsType;
  otherSignatoriesQuestions: FormDocumentQuestionsType;
  disabledQuestions: { [page: string]: FormDocumentQuestionsType };
  activeQuestionIndex: number;
  responses: FormDocumentAnswersType;
  allFormRoles: SignatureFormRole[];
  closeOnProgress?: boolean;
  showTermsAndConditions?: boolean;
  showWizard?: boolean;
  showUserRoles?: boolean;
  hideQuestionRoles?: string[];
  wizardStarted?: boolean;
};

export type RequiredQuestion = {
  questionUUID: string;
};

export type DynamicErrorConfig = {
  requiredQuestion?: RequiredQuestion[];
};

export interface DynamicPostForm extends PostFormType {
  isReady: boolean;
}

export type FormBundleDocument = {
  Id: number;
  FormProcessId: number;
  FormVersionId: number;
  FormId: number;
  FormName: string;
  formName: string;
  DocumentLink: string;
  Status: string;
  CreatedDate: Date;
  UpdatedDate: Date;
  Answers: any;
  PublicId: string;
  EditDate: null;
  SignDate: null;
  CompleteDate: null;
  CancelDate: null;
  DocumentVaultUUID: null;
  CreatedById: number;
  TeamId: number;

  questions?: DynamicFormQuestions;
  IsViewed?: boolean;
  StrikeThrough?: StrikeThroughType[];
  templateId?: number;
};

export type BundleDocumentsType = {
  screen: FormProcessScreenType;
  meta: DynamicFormMeta;
  documents: FormBundleDocument[];
  formRoles: PDFEditorRole[];
  questions: DynamicFormQuestions;
  activeQuestions: FormDocumentQuestionsType;
  otherSignatoriesQuestions: FormDocumentQuestionsType;
  answers: FormDocumentAnswersType;
  showWizard: boolean;
  activeQuestionIndex: number;
  activeQuestionUUID: string;
  PublicId: string;
  strikeThrough: StrikeThroughType[];
  showUnlock?: boolean;
  templateId: number;
};

/**************** Strike Through types **************/

export type StrikeThroughType = {
  strikeThrough: { [key: string]: StrikeThroughItem[] };
};

export type StrikeThroughItem = {
  id: string;
  end: Coordinates;
  color: Color;
  start: Coordinates;
  strokeWidth: number;
};

export type Color = {
  a: number;
  b: number;
  g: number;
  r: number;
};

export type Coordinates = {
  x: number;
  y: number;
};

/**************** Strike through types End **************/

export type DynamicFormType = {
  screen: FormProcessScreenType;
  isLoading?: boolean;
  meta: DynamicFormMeta;

  scale?: number;

  editorConfig?: PDFEditorConfig;

  signatureConfig?: DynamicSignatureConfig;

  postForm?: DynamicPostForm;

  isPreview?: boolean;
  isCompleted?: boolean;
  isDeclined?: boolean;
  isCanceled?: boolean;
  hideFooter?: boolean;
  showUnlock?: boolean;
  allowRegress?: boolean;

  errorConfig?: DynamicErrorConfig;
  activeDocument?: FormBundleDocument;
  allDocuments?: FormBundleDocument[];
  dynamicFormDocuments?: FormBundleDocument[];

  bundleDocumentsDetails?: BundleDocumentsType[];
  strikeThrough?: StrikeThroughType[];

  allTemplates?: BundleTemplate[];

  dynamicScrolledDocumentId?: number;
  dynamicScrolledDocumentIndex?: number;
};

export type RequestFormProcessType = {
  isPending: boolean;
  isArchive?: boolean;
  state: string;
  preForm: PreFormType;
  postForm?: PostFormType;
  publishedForms: PublishedFormsDataType;
  formTemplates: FormTemplatesDataType;
  formRecentUploads: FormRecentUploadsDataType;
  meta: MetaType;
  formRoles: FormRolesType;
  agentFormRoles: FormRolesType;
  formName: string;
  formDocument?: FormDocumentType;
  isFormLibraryModalVisible: boolean;
  draftForms: DraftFormsType;
  formDetails: FormDetailsType;
  allFormProcesses: AllFormProcessesType;
  allUnlinkFormProcesses: [];
  allArchiveForms: AllArchiveFormsType;
  signatoriesPresent?: boolean;
  editorsPresent?: boolean;
  deferredQuestions: DeferredQuestionsType;
  currentUserSignature?: UserSignature;
  defaultEditors?: EditorsType;
  clientFieldsPresent?: boolean;
  showClientEditorScreen?: boolean;
  publishedForm?: PublishedFormTypeDataType;
  dynamicForm?: DynamicFormType;
  filters: Record<string, any>;
  editorsRoles?: FormRolesType;

  selectedForm?: DynamicSelectedForm;
};

export type PublishedFormTypeDataType = {
  state: string;
  isPending?: boolean;
  data?: {
    Id: number;
    FormId: number;
  };
};

export type ArchiveFormsDetailsType = {
  clients?: string[];
  property?: string;
  client?: string;
  avatar?: string;
  forms?: {
    clients?: string[];
    completedForms: ArchiveFormsType;
    cancelledForms: ArchiveFormsType;
  };
};

export enum FormProcessTypes {
  FormBuilder = 'FormBuilder',
  DynamicForm = 'DynamicForm',
}
