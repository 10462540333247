import React, { useState } from 'react';

import { Modal } from 'antd';
import { Button } from 'components-antd';
import { useDispatch } from 'react-redux';
import { requestUpdatePlanLegacyEffect } from 'store/effects/subscription';
import styles from '../DowngradeModal/styles.module.scss';
import { userGetDataOnBackgroundEffect } from 'store/effects';
import { ModalClose } from 'components/Icons';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  updateLegacyPlanTo: 'month' | 'year';
};

export const LegacyModalChange: React.FC<Props> = ({ isOpen, closeModal, updateLegacyPlanTo }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleUpgradePlan = () => {
    setIsLoading(true);
    dispatch(
      requestUpdatePlanLegacyEffect({ interval: updateLegacyPlanTo }, () => {
        dispatch(userGetDataOnBackgroundEffect({}, { showError: false }, () => closeModal()));
        setIsLoading(false);
      }),
    );
  };

  return (
    <Modal
      open={isOpen}
      centered
      maskStyle={{
        background: 'rgba(255, 255, 255, 0.60)',
        backdropFilter: ' blur(12.5px)',
      }}
      bodyStyle={{
        borderRadius: '24px',
        background: 'var(--REBRAND-Secondary-White, #FFF)',
        boxShadow: '0px 24px 80px 0px rgba(0, 0, 0, 0.20)',
      }}
      wrapClassName={styles.wrapper}
      closable
      footer={null}
      className={styles.modalWrapper}
      onCancel={closeModal}
      destroyOnClose
      closeIcon={<ModalClose />}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          Are you sure you want to change to{' '}
          {`${updateLegacyPlanTo === 'month' ? 'monthly' : 'annual'}`} plan?
        </div>
        {updateLegacyPlanTo === 'year' && (
          <div className={styles.info}>
            You&apos;ll get a <strong>20% discount</strong> on annual plan.
          </div>
        )}
        <div className={styles.btnContainer}>
          <Button
            variant="primary"
            className={styles.confirmBtn}
            onClick={handleUpgradePlan}
            loading={isLoading}
          >
            {updateLegacyPlanTo === 'year' ? 'Upgrade' : 'Downgrade'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
