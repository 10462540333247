import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Beds,
  Baths,
  MinMax,
  YearMinMax,
  PropertyType,
  Input,
  Button,
  Wrapper as PendingWrapper,
  Select,
  Locations,
  LotSizeMinMax,
} from 'components';
import {
  ScrollDrawerWrapper,
  DrawerPaddingWrapper,
  Footer,
} from 'pages/Properties/SearchResults/components';
import { GarageOnly, ParkingSpaces } from 'pages/Properties/Feed/components';
import Icon from 'pages/Properties/Feed/Icons';
import { mergeInitialValues } from './helpers';
import { EDIT } from 'settings/constants/mode';
import { ValidationSchema } from './validation';
import { sortLocations, sortStatus, sortStrings } from 'helpers';
import { propertyStatusOptions } from 'settings/constants/properties';

import styles from './styles.module.scss';
import { LocationType } from '../../../../../../types';

const FilterForm = (props) => {
  const { className, onSubmit, isPending, search, searchName, mode } = props;

  const formik = useFormik({
    initialValues: mergeInitialValues(search, searchName),
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit,
  });

  const garageOnly = (
    <GarageOnly
      value={formik.values?.GarageOnly}
      onChange={(value) => formik.setFieldValue('GarageOnly', value)}
      disabled={formik.values?.MinParkingSpaces == 0}
    />
  );

  const flatValue = (propName) => formik?.values?.[propName];

  const onResetHandler = () => {
    if (mode === EDIT) {
      formik.setValues(FilterForm.initialValues);
    } else {
      formik.setValues({ ...FilterForm.initialValues, Locations: search?.Locations });
    }
  };

  return (
    <div>
      <ScrollDrawerWrapper>
        <PendingWrapper className={styles.pendingWrapper} isPending={false}>
          <DrawerPaddingWrapper className={className}>
            <form id="filter" onSubmit={formik.handleSubmit}>
              {mode === EDIT && (
                <>
                  <Input
                    name="Name"
                    className={classNames(styles.field)}
                    variant={Input.LIGHT_FULL}
                    label="Search Name"
                    placeholder="e.g. Chicago - 3 BR / 2 BA"
                    onChange={formik.handleChange}
                    value={formik.values.Name || ''}
                    disabled={isPending}
                    testid="search_name"
                  />
                  <Locations
                    className={styles.field}
                    valuesWrapperClassName={styles.locationsValues}
                    name="Locations"
                    label="Locations"
                    onResult={(result, preparedData) => {
                      if (preparedData?.length) preparedData.sort(sortLocations);
                      formik.setFieldValue('Locations', preparedData);
                    }}
                    error={formik.touched.Locations ? formik.errors.Locations : ''}
                    value={
                      formik.values.Locations?.filter(
                        (loc) => loc.Type !== LocationType.ViewportCoordinates,
                      ) || []
                    }
                    disabled={isPending}
                    testid="locations"
                    variant={Locations.LIGHT}
                    showPrefixIcon={true}
                  />
                </>
              )}
              <Select
                name="Status"
                label="Status"
                multiple
                className={{ wrapper: styles.field }}
                variant={Select.LIGHT_FULL}
                options={propertyStatusOptions}
                value={formik.values.Status}
                onSelect={(event, val) => {
                  if (val?.length) val.sort(sortStatus);
                  formik.setFieldValue('Status', val);
                  event.stopPropagation();
                }}
                disabled={isPending}
                testid="status_select"
              />
              <MinMax
                label="Price"
                className={styles.field}
                onChangeMin={(e, val) =>
                  formik.setFieldValue('PriceRange', { ...flatValue('PriceRange'), Min: val })
                }
                onChangeMax={(e, val) =>
                  formik.setFieldValue('PriceRange', { ...flatValue('PriceRange'), Max: val })
                }
                valueMin={formik.values?.PriceRange?.Min}
                valueMax={formik.values?.PriceRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                prefix="$"
                disabled={isPending}
                testidMin="price_min"
                testidMax="price_max"
              />
              <MinMax
                className={styles.field}
                label="Beds"
                nameMin="NumBedroomsRange.Min"
                nameMax="NumBedroomsRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.NumBedroomsRange?.Min}
                valueMax={formik.values?.NumBedroomsRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                disabled={isPending}
              />
              <MinMax
                className={styles.field}
                label="Baths"
                nameMin="NumBathroomsRange.Min"
                nameMax="NumBathroomsRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.NumBathroomsRange?.Min}
                valueMax={formik.values?.NumBathroomsRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                disabled={isPending}
              />
              <PropertyType
                className={styles.field}
                label="Property Type"
                onChange={(type) => {
                  if (type?.length) type.sort(sortStrings);
                  formik.setFieldValue('HomeType', type);
                }}
                disabled={isPending}
                error={formik.touched.HomeType ? formik.errors.HomeType : ''}
                value={formik.values?.HomeType}
                propertyTypeWrapperClassName={styles.filterPropertyWrapper}
                itemClassName={styles.filterItem}
                isFeedFilter={true}
              />
              <MinMax
                className={styles.field}
                label="Square Feet"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(e, val) =>
                  formik.setFieldValue('SquareFeetRange', {
                    ...flatValue('SquareFeetRange'),
                    Min: val,
                  })
                }
                onChangeMax={(e, val) =>
                  formik.setFieldValue('SquareFeetRange', {
                    ...flatValue('SquareFeetRange'),
                    Max: val,
                  })
                }
                valueMin={formik.values?.SquareFeetRange?.Min}
                valueMax={formik.values?.SquareFeetRange?.Max}
                disabled={isPending}
                testidMin="square_min"
                testidMax="square_max"
              />
              <LotSizeMinMax
                isReset
                className={styles.field}
                label="Lot Size"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(val) =>
                  formik.setFieldValue('LotSizeRange', {
                    ...flatValue('LotSizeRange'),
                    Min: val,
                  })
                }
                onChangeMax={(val) =>
                  formik.setFieldValue('LotSizeRange', {
                    ...flatValue('LotSizeRange'),
                    Max: val,
                  })
                }
                valueMin={formik.values?.LotSizeRange?.Min}
                valueMax={formik.values?.LotSizeRange?.Max}
                disabled={isPending}
                testidMin="lot_size_min"
                testidMax="lot_size_max"
              />
              <YearMinMax
                isReset
                className={styles.field}
                label="Year Build"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(val) =>
                  formik.setFieldValue('YearBuiltRange', {
                    ...flatValue('YearBuiltRange'),
                    Min: val,
                  })
                }
                onChangeMax={(val) =>
                  formik.setFieldValue('YearBuiltRange', {
                    ...flatValue('YearBuiltRange'),
                    Max: val,
                  })
                }
                valueMin={formik.values?.YearBuiltRange?.Min}
                valueMax={formik.values?.YearBuiltRange?.Max}
                disabled={isPending}
                testidMin="year_min"
                testidMax="year_max"
              />
              <div className={classNames(styles.pseudo, styles.field)}>
                <Input
                  name="HOARange.Max"
                  className={classNames(styles.field, styles.hoa)}
                  variant={Input.LIGHT_FULL}
                  placeholder="$"
                  isNumberFormat
                  isNumericString
                  label="Maximum HOA"
                  prefix="$"
                  onChange={formik.handleChange}
                  value={formik.values.HOARange.Max}
                  disabled={isPending}
                  testid="max_hoa"
                />
                <p className={styles.pseudoPlaceholder}>Per month</p>
              </div>
              <ParkingSpaces
                className={classNames(styles.parkingSpaces)}
                onChange={(val) => formik.setFieldValue('MinParkingSpaces', val)}
                value={+formik.values.MinParkingSpaces}
                disabled={isPending}
              >
                {garageOnly}
              </ParkingSpaces>
              <MinMax
                className={styles.field}
                thousandSeparator={false}
                label="Stories"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(e, val) =>
                  formik.setFieldValue('StoriesRange', { ...flatValue('StoriesRange'), Min: val })
                }
                onChangeMax={(e, val) =>
                  formik.setFieldValue('StoriesRange', { ...flatValue('StoriesRange'), Max: val })
                }
                valueMin={formik.values?.StoriesRange?.Min}
                valueMax={formik.values?.StoriesRange?.Max}
                disabled={isPending}
                testidMin="stories_min"
                testidMax="stories_max"
              />
              <Input
                name="MaxDaysOnMarket"
                className={styles.field}
                variant={Input.LIGHT_FULL}
                placeholder="No Max"
                isNumberFormat
                isNumericString
                label="Days on the market"
                onChange={formik.handleChange}
                value={formik.values.MaxDaysOnMarket}
                disabled={isPending}
                testid="time_on_market"
              />
            </form>
          </DrawerPaddingWrapper>
        </PendingWrapper>
      </ScrollDrawerWrapper>
      <Footer className={styles.footer}>
        <div className={styles.resetBlock}>
          <span className={classNames(styles.resetSpan, 'show-cursor')} onClick={onResetHandler}>
            <Icon className={styles.resetIcon} variant={Icon.RESET} />
            <span className={styles.resetAllText}>Reset All</span>
          </span>
        </div>
        <Button
          testid="save_button"
          isPending={isPending}
          form="filter"
          className={styles.submitButton}
          type="submit"
          title={mode === EDIT ? 'Save' : 'Search'}
          disabled={mode === EDIT && !search?.Locations?.length}
        />
      </Footer>
    </div>
  );
};

FilterForm.initialValues = {
  Locations: [],
  HOARange: { Max: '' },
  MinParkingSpaces: 0,
  GarageOnly: false,
  Status: [],
  MaxDaysOnMarket: '',
  HomeType: [],
  PriceRange: {
    Min: '',
    Max: '',
  },
  NumBedroomsRange: {
    Min: '',
    Max: '',
  },
  NumBathroomsRange: {
    Min: '',
    Max: '',
  },
  SquareFeetRange: {
    Min: '',
    Max: '',
  },
  LotSizeRange: {
    Min: '',
    Max: '',
  },
  YearBuiltRange: {
    Min: '',
    Max: '',
  },
  StoriesRange: {
    Min: '',
    Max: '',
  },
};

FilterForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  isPending: PropTypes.bool,
  search: PropTypes.shape({
    Status: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  searchName: PropTypes.string,
  mode: PropTypes.string,
};

FilterForm.defaultProps = {
  className: '',
  onSubmit: () => {},
  isPending: false,
  searchName: undefined,
  mode: undefined,
};

export default FilterForm;
