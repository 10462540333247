import { createSelector } from 'reselect';

import { PENDING, IDLE, READY } from 'settings/constants/apiState';

const localState = ({ needsAttention }) => needsAttention;

export const getLoadingNeedsAttentionDataSelector = createSelector(localState, ({ state }) => ({
  state,
  isIdle: state === IDLE,
  isPending: state === PENDING,
}));

export const getAgentNeedsAttentionSelector = createSelector(
  localState,
  ({ agentData }) => agentData,
);
export const getTeamNeedsAttentionSelector = createSelector(localState, ({ teamData }) => teamData);
