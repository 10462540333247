import React from 'react';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { getListingDetailAgentInformationData } from 'store/selectors/listingDetail';
import { TitleValueBlock } from 'components';
import InfoBlockList from '../InfoBlockList';
import styles from './styles.module.scss';

const PropertyTabAgentInformation = () => {
  const {
    name,
    office,
    avatarName,
    officePhone,
    mobilePhone,
    directPhone,
    email,
    url,
    fax,
    designation,
    aOR,
    coListingAgent,
    blocks,
    market,
    stateLicenseNumber,
    showingsData,
    nationalAssociationId,
  } = useSelector(getListingDetailAgentInformationData);
  const [showings, setShowings] = useState(undefined);
  const [privateRemarks, setPrivateRemarks] = useState(undefined);
  const titlesToRemove = ['Showings', 'Private Remarks'];
  useEffect(() => {
    if (blocks?.length) {
      let obj = blocks.find((obj) => obj.title === titlesToRemove[0]);
      if (obj) setShowings(obj);
      obj = blocks.find((obj) => obj.title === titlesToRemove[1]);
      if (obj) setPrivateRemarks(obj);
    }
  }, [blocks]);

  const getBlocks = () => {
    return blocks.filter((obj) => !titlesToRemove.includes(obj.title));
  };

  const getContactDetails = () => {
    if (mobilePhone || officePhone || directPhone) {
      return (
        <>
          {mobilePhone && (
            <div testid="agent_mobilePhone" className={styles.agentInfoItemInner}>
              {mobilePhone} (mobile)
            </div>
          )}
          {officePhone && (
            <div testid="agent_officePhone" className={styles.agentInfoItemInner}>
              {officePhone} (office)
            </div>
          )}
          {directPhone && (
            <div testid="agent_directPhone" className={styles.agentInfoItemInner}>
              {directPhone} (direct)
            </div>
          )}
        </>
      );
    } else if (showingsData?.ContactType?.toLowerCase() === 'agent' && showingsData?.Phone) {
      return (
        <div testid="agent_phone" className={styles.agentInfoItemInner}>
          {showingsData?.Phone}
        </div>
      );
    }
    return null;
  };
  return (
    <div className={styles.agentInformation}>
      <div className={styles.agentInformationArea}>
        <div className={styles.agentInformationAreaSection}>
          <div className={styles.agentInformationTitle}>Listing Agent:</div>
          {!!name && (
            <div className={styles.agentInformationAgent}>
              <div className={styles.agentInformationAgentAvatar}>{avatarName}</div>
              <div className={styles.agentInfoItem}>
                <div testid="agent_name" className={styles.agentInfoItemInner}>
                  {name}
                </div>
                {office && (
                  <div testid="agent_office" className={styles.agentInfoItemInner}>
                    {office}
                  </div>
                )}
                {getContactDetails()}
                {email && (
                  <div testid="agent_email" className={styles.agentInfoItemInner}>
                    {email}
                  </div>
                )}
                {url && (
                  <div testid="agent_url" className={styles.agentInfoItemInner}>
                    {url}
                  </div>
                )}
                {fax && (
                  <div testid="agent_fax" className={styles.agentInfoItemInner}>
                    {fax} (Fax)
                  </div>
                )}

                {designation && (
                  <div testid="agent_designation" className={styles.agentInfoItemInner}>
                    {designation} (Designation)
                  </div>
                )}

                {aOR && (
                  <div testid="agent_AOR" className={styles.agentInfoItemInner}>
                    {aOR} (AOR)
                  </div>
                )}
                {stateLicenseNumber && market === 'crmls' && (
                  <div testid="agent_stateLicenseNumber" className={styles.agentInfoItemInner}>
                    {stateLicenseNumber} (State License Number)
                  </div>
                )}
                {nationalAssociationId && (
                  <div testid="agent_nationalAssociationId" className={styles.agentInfoItemInner}>
                    {nationalAssociationId} (National Association ID)
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={styles.agentInformationAreaSection}>
          {!!coListingAgent?.Name && (
            <div className={styles.agentInformationTitle}>Co-Listing Agent:</div>
          )}
          {!!coListingAgent?.Name && (
            <div className={styles.agentInformationAgent}>
              <div className={styles.agentInformationAgentAvatar}>{avatarName}</div>
              <div className={styles.agentInfoItem}>
                <div testid="co_list_agent_name" className={styles.agentInfoItemInner}>
                  {coListingAgent?.Name}
                </div>
                {coListingAgent?.Email && (
                  <div testid="co_list_agent_email" className={styles.agentInfoItemInner}>
                    {coListingAgent?.Email}
                  </div>
                )}
                {coListingAgent?.Phone && (
                  <div testid="co_list_agent_mobilePhone" className={styles.agentInfoItemInner}>
                    {coListingAgent?.Phone} (mobile)
                  </div>
                )}

                {coListingAgent?.Designation && (
                  <div testid="co_list_agent_designation" className={styles.agentInfoItemInner}>
                    {coListingAgent?.Designation} (Designation)
                  </div>
                )}

                {coListingAgent?.AOR && (
                  <div testid="co_list_agent_AOR" className={styles.agentInfoItemInner}>
                    {coListingAgent?.AOR} (AOR)
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {privateRemarks ? (
        <div className={styles.privateRemarks}>
          <TitleValueBlock
            key={-2}
            className={styles.twoBlocksBlock}
            title={privateRemarks.title}
            value={privateRemarks.value}
          />
        </div>
      ) : (
        <></>
      )}
      {showings ? (
        <div>
          <TitleValueBlock
            key={-1}
            className={styles.twoBlocksBlock}
            title={showings.title}
            value={showings.value}
          />
        </div>
      ) : (
        <></>
      )}
      {!!blocks?.length && <InfoBlockList list={getBlocks()} />}
    </div>
  );
};

export default PropertyTabAgentInformation;
