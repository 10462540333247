import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getChatAdviceStatusSelector } from 'store/selectors/app';
import { appChangeChatAdviceStatusEffect } from 'store/effects/app';

import styles from './styles.module.scss';

const BottomAdviceBlock = () => {
  const dispatch = useDispatch();
  const status = useSelector(getChatAdviceStatusSelector);

  const onClose = useCallback(() => dispatch(appChangeChatAdviceStatusEffect(true)), [dispatch]);

  useEffect(() => setTimeout(onClose, 5000), [onClose]);

  if (status) {
    return null;
  }

  return (
    <div className={styles.wrapper} onClick={onClose}>
      <div className={styles.icon}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
            stroke="#ADADAD"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 6.66797V10.0013"
            stroke="#ADADAD"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 13.332H10.0083"
            stroke="#ADADAD"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={styles.text}>Click and hold for message shortcuts</div>
    </div>
  );
};

export default BottomAdviceBlock;
