import { useEffect, useState } from 'react';
import { Row, Col, Button } from 'components-antd';
import { CommissionField } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/FinancingDetails/components';
import { ErrorMessage } from '../../../Error/ErrorMessage';
import CheckboxComponent from 'components/Form/Checkbox';
import { NameAutocomplete } from '../../../';
import { showErrorMessage } from 'helpers/errors';
import { addFinancialSplit, updateFinancialSplit } from 'api/financials';

import { SplitData } from '../SplitCard';
import { SplitTabTypes } from 'pages/Workshop/Transactions/TransactionFinancials/TransactionFinancials';

import styles from './styles.module.scss';

interface EditDynamicSplitType {
  splitItem?: SplitData;
  autocompleteSplitNames: string[];
  splitType: SplitTabTypes.LISTING | SplitTabTypes.PURCHASE;
  updateSplitValue: Function;
  isTransactionAdminOrOwner: boolean;
  transactionId?: number | string;
  fetchFinancials: Function;
  onCancel: Function;
  isValidate: boolean;
}

const initialState = {
  Id: null,
  IsSplitTypePercentage: true,
  SplitPercentage: null,
  SplitValue: null,
  SplitTitle: '',
  IsRestricted: false,
};

export const EditDynamicSplit = ({
  splitItem,
  autocompleteSplitNames,
  splitType,
  updateSplitValue,
  isTransactionAdminOrOwner,
  transactionId,
  fetchFinancials,
  onCancel,
  isValidate,
}: EditDynamicSplitType) => {
  const [isAddUpdateSplitLoading, setAddUpdateSplitLoading] = useState(false);
  const [splitItemField, setSplitItemField] = useState<any>(initialState);

  useEffect(() => {
    if (splitItem) {
      setSplitItemField({
        Id: splitItem?.Id,
        IsSplitTypePercentage: splitItem?.IsSplitTypePercentage,
        SplitPercentage: splitItem?.SplitPercentage || '',
        SplitValue: splitItem?.SplitValue || '',
        SplitTitle: splitItem?.SplitType?.SplitTitle,
        IsRestricted: splitItem?.SplitType?.IsRestricted,
      });
    }
  }, [splitItem]);

  const handleAddOrUpdate = async () => {
    try {
      setAddUpdateSplitLoading(true);
      const { Id, SplitValue, IsSplitTypePercentage, SplitTitle, IsRestricted } =
        splitItemField || {};
      let _payload: any = {
        SplitValue: SplitValue || 0,
        SplitTitle,
        IsSplitTypePercentage,
        SplitSide: splitType,
        IsRestricted,
      };

      if (Id) {
        await updateFinancialSplit({
          transactionId: transactionId,
          id: Id,
          data: _payload,
        });
      } else {
        await addFinancialSplit({
          transactionId: transactionId,
          data: _payload,
        });
      }

      fetchFinancials();
    } catch (err) {
      showErrorMessage(err);
      setAddUpdateSplitLoading(false);
    } finally {
      setAddUpdateSplitLoading(false);
      onCancel();
    }
  };

  return (
    <Row gutter={12} className={styles.dynamicSplit}>
      <Col lg={12}>
        <NameAutocomplete
          label="Split"
          allNames={autocompleteSplitNames?.map((item, idx) => ({ label: item, value: idx }))}
          value={splitItemField?.SplitTitle}
          onChange={(value) => setSplitItemField({ ...splitItemField, SplitTitle: value })}
          onSelect={(value) => setSplitItemField({ ...splitItemField, SplitTitle: value })}
        />
      </Col>
      <Col lg={12}>
        <CommissionField
          IsFeeTypePercentage={splitItemField?.IsSplitTypePercentage}
          update={(object) => {
            const updatedObject = {
              SplitValue: object?.Value === '' || null ? null : parseFloat(object?.Value),
              IsSplitTypePercentage: object?.IsFeeTypePercentage,
            };
            setSplitItemField({ ...splitItemField, ...updatedObject });
            updateSplitValue(splitItem?.Id || null, updatedObject);
          }}
          label="Amount"
          value={
            splitItemField?.IsSplitTypePercentage
              ? splitItemField?.SplitPercentage
              : splitItemField?.SplitValue
          }
        />
      </Col>
      <Col lg={12}>
        <div className={styles.buttonsContainer}>
          <Button
            variant="secondary"
            size="large"
            onClick={handleAddOrUpdate}
            loading={isAddUpdateSplitLoading}
            disabled={isAddUpdateSplitLoading || isValidate}
          >
            {splitItem?.Id ? 'Update' : 'Add'}
          </Button>
          <Button variant="hollow-bordered" size="large" onClick={() => onCancel()}>
            Cancel
          </Button>
        </div>
      </Col>
      {isTransactionAdminOrOwner && (
        <Col lg={12} className={styles.checkboxCont}>
          <CheckboxComponent
            id="restricted-add"
            label="Restricted"
            checked={splitItemField?.IsRestricted}
            onChange={(e, v, checked) =>
              setSplitItemField({ ...splitItemField, IsRestricted: checked })
            }
            direction={CheckboxComponent.DIRECTION_RIGHT}
          />
        </Col>
      )}
      <Col lg={24} className={styles.errorContainer}>
        {isValidate && (
          <ErrorMessage message="The cumulative value of the splits cannot exceed the GCI value." />
        )}
      </Col>
    </Row>
  );
};
