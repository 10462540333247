import classNames from 'classnames';

import Icons from '../../Icons';

import styles from './styles.module.scss';
import React from 'react';

export enum Stat {
  TOURS = 'Tours',
  SHOWINGS = 'Showings',
  MILESTONES = 'Milestones',
  CLOSINGS = 'Closings',
  EXPIRINGS = 'Expiring Listings',
  KITS = 'KITs Going Out',
}

type Props = {
  item: Stat;
  today: number;
  thisWeek: number;
  handleClick?: (name: Stat) => void;
  handleCountClick?: (name: Stat, duration: 'dueToday' | 'dueWeek') => void;
};

export const ScheduleItem: React.FC<Props> = ({
  item,
  today,
  thisWeek,
  handleClick,
  handleCountClick,
}) => {
  const getVariant = (value) => {
    if (value === Stat.TOURS || value === Stat.SHOWINGS) {
      return 'calendar';
    } else if (value === Stat.MILESTONES) {
      return 'list';
    } else if (value === Stat.CLOSINGS || value === Stat.EXPIRINGS) {
      return 'arrows';
    } else if (value === Stat.KITS) {
      return 'messages';
    } else return null;
  };

  const onCardClick = () => typeof handleClick !== 'undefined' && handleClick(item);
  const onCountClick = (e: any, duration: 'dueToday' | 'dueWeek', count: number) => {
    if (typeof handleCountClick !== 'undefined' && count > 0) {
      e.stopPropagation();
      handleCountClick(item, duration);
    }
  };

  if (today === 0 && thisWeek === 0) return null;
  return (
    <div className={styles.scheduleItemRow} onClick={onCardClick}>
      <div className={styles.leftContent}>
        <Icons className={styles.icon} variant={getVariant(item)} />
        <span className={styles.scheduleItem}>{item}</span>
      </div>
      <div className={styles.rightContent}>
        <div
          className={classNames(styles.circle, styles.todayNum)}
          onClick={(e) => onCountClick(e, 'dueToday', today)}
        >
          {today}
        </div>
        <div
          className={classNames(styles.circle, styles.thisWeekNum)}
          onClick={(e) => onCountClick(e, 'dueWeek', thisWeek)}
        >
          {thisWeek}
        </div>
      </div>
    </div>
  );
};
