import { cloneDeep, get } from 'lodash-es';
import { handleActions, ReducerMap } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { requestGetAgentPropertiesAction, markPropertyCommentsAsRead } from 'store/actions/feedv3';
import { appLogoutAction } from 'store/actions/app';
import { requestSendSharePropsToRecipientsAction } from 'store/actions/feed';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  options: {},
};

export default handleActions<
  {
    state: string;
    data: any;
    meta: {};
    options: {};
  },
  any
>(
  {
    [requestGetAgentPropertiesAction as any]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [markPropertyCommentsAsRead.toString()]: (state, { payload: { msgIds = [] } }) => {
      if (state.data) {
        return {
          ...state,
          data: {
            ...state.data,
            items: state.data?.items?.map((item) => ({
              ...item,
              Comments: item?.Comments?.map((comment) => ({
                ...comment,
                IsRead: comment.IsRead || msgIds.includes(comment.MessageId),
              })),
            })),
          },
        };
      }
      return state;
    },
    [requestSendSharePropsToRecipientsAction as any]: (state, { payload }) => {
      const updatedData: any = cloneDeep((state?.data as any)?.items);
      if (payload.state === 'ready' && payload?.meta && updatedData?.length) {
        const { ClientIds, MLSIds } = payload?.meta as any;
        const clients = (payload?.options as any)?.clients?.data
          .filter((client) => ClientIds.includes(client.Id))
          ?.map((data) => ({ FirstName: data?.FirstName, LastName: data?.LastName, Id: data?.Id }));

        MLSIds.forEach((mlsId) => {
          const mlsIndex: number = updatedData?.findIndex((mls) => mls.Id === mlsId);

          if (mlsIndex !== -1) {
            updatedData[mlsIndex].RecommendedTo = clients;
          }
        });

        return {
          ...state,
          data: { ...(state?.data as any), items: updatedData },
        };
      } else return state;
    },
    [appLogoutAction as any]: () => initialData,
  },
  initialData,
);
