import { getTransactionFolder } from 'api/transactions/folders/read';
import { get } from 'lodash-es';
import { getState } from 'store';
import { getTransactionFolderAction } from 'store/actions/transactions/folder/read';
import Api from 'store/effects/core/api';

export const getTransactionFolderEffect = (cfg, options = { silent: true }, cb) => {
  const state = getState();
  const transactionData = get(state, 'transaction.data');

  const requestParams = {
    action: getTransactionFolderAction,
    method: getTransactionFolder,
  };

  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  const config = {
    transactionId: cfg?.Id || transactionData?.Id,
  };

  return sendRequest(config, options, cb);
};
