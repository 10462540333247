import { apiServer } from 'settings/web-services/api';

export function getNotificationTemplates(cfg) {
  return apiServer.get('/notificationTemplate', { params: cfg });
}

export function getNotificationTemplateById({ id }) {
  return apiServer.get(`/notificationTemplate/${id}`);
}

export function putNotificationTemplate(cfg, { id }) {
  return apiServer.put(`/notificationTemplate/${id}`, cfg);
}
