import classNames from 'classnames';
import styles from './styles.module.scss';
import Icons from 'pages/Properties/Feed/Icons';

const MatchWithBuyerHint = ({ className, buyers = [], addedToFavoritesBy = [] }) => {
  const getColor = (score) => {
    if (score >= 75) {
      return 'green';
    }
    if (score >= 50 && score < 75) {
      return 'orange';
    }

    return 'gray';
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      {!!buyers?.length && (
        <div className={styles.section}>
          <div className={styles.title}>
            <Icons variant={Icons.APPLICANTS} className={styles.icon} />
            <span>Client Matches:</span>
          </div>
          <ul className={styles.list}>
            {buyers.map(({ name, score }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li testid="applicant_item" key={index}>
                <span className={styles.name}>{name}</span>
                <span
                  className={classNames(styles.matchPercent, styles[getColor(score)])}
                >{`${score}%`}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      {!!addedToFavoritesBy?.length && (
        <div className={styles.section}>
          <div className={styles.title}>
            <Icons variant={Icons.FAVORITE} className={styles.icon} />
            <span>Added to Favorites:</span>
          </div>
          <ul className={styles.list}>
            {addedToFavoritesBy.map(({ FirstName, LastName }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li testid="applicant_item" key={index}>
                <span className={styles.name}>
                  {FirstName} {LastName}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MatchWithBuyerHint;
