import DOMPurify from 'dompurify';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { SuggestionDataItem } from 'react-mentions';

import { Spin } from 'components-antd';
import { SmartFormIcons, SmartFormIconVariants } from 'components/SmartForm';
import { FormCommentMention } from '../FormCommentMention';
import {
  matchLineBreaksOrMentionsUserTag,
  matchMentionsUserTag,
  matchMentionsUserTagEmail,
  matchTrailingSpacesIgnoringLineBreaks,
  whiteColor,
} from 'app-constants';
import { FormCommentBoxHeader } from '../FormCommentBoxHeader';
import { getMappedCommentUsers } from 'pages/FormProcess/helper';
import {
  CommentUsersType,
  CommentUserType,
  CustomOptions,
  EditedCommentType,
  EditFormCommentType,
  FormComment,
} from 'types';

import styles from './styles.module.scss';

interface FormCommentBoxProps {
  isPanelComment?: boolean;
  isHeader?: boolean;
  isResolved?: boolean;
  threadId?: number;
  isSending: boolean;
  setSending: (isSending: boolean) => void;
  userInfo: CommentUserType;
  comment: FormComment;
  commentUsers: CommentUsersType;
  notifyOptions?: CustomOptions;
  handleEditFormComment?: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment?: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
  commentBoxClass?: string;
}

export const FormCommentBox = ({
  isPanelComment = false,
  isHeader,
  isResolved,
  threadId,
  isSending,
  setSending,
  userInfo,
  comment,
  commentUsers,
  notifyOptions,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
  commentBoxClass,
}: FormCommentBoxProps) => {
  const [tagableUsers, setTagableUsers] = useState<SuggestionDataItem[]>([]);
  const [editingMode, setEditingMode] = useState(false);
  const [editedComment, setEditedComment] = useState<EditedCommentType>({
    comment: comment.Comment,
  });

  useEffect(() => {
    setTagableUsers(
      notifyOptions?.map((notifyOption) => ({
        id: notifyOption.value,
        display: notifyOption.label,
      })) || [],
    );
  }, [notifyOptions]);

  const handleEditComplete = () => {
    handleEditFormComment &&
      handleEditFormComment(
        {
          formCommentId: comment.Id,
          comment: editedComment.comment.replace(matchTrailingSpacesIgnoringLineBreaks, ' ').trim(),
          tag: editedComment?.mentions?.length
            ? getMappedCommentUsers(commentUsers, editedComment?.mentions)
            : undefined,
        },
        setEditingMode,
        setSending,
      );
  };

  const checkSendStatus = () => {
    if (editedComment.comment && editedComment.comment.trim() !== comment.Comment) {
      handleEditComplete();
    } else {
      setEditingMode(false);
      setEditedComment({ comment: comment.Comment });
    }
  };

  const renderComment = (comment: string) => {
    const sanitizedComment = DOMPurify.sanitize(comment);
    const parts = sanitizedComment.split(matchLineBreaksOrMentionsUserTag);

    return (
      <div className={styles.viewComment} onClick={(e) => e.stopPropagation()}>
        {parts.map((part, index) => {
          if (matchMentionsUserTag.test(part)) {
            const email = part.match(matchMentionsUserTagEmail)?.[1];
            const existingUser = Object.values(commentUsers).find(
              (commentUser) => commentUser.email === email,
            );
            if (existingUser?.userId) {
              return (
                <a href="javascript:void(0);" className={styles.mentionedUser}>
                  @{existingUser.name}
                </a>
              );
            } else {
              return (
                <a href={`mailto:${email}`} className={styles.mentionedUser}>
                  {email}
                </a>
              );
            }
          } else if (part === '\n') {
            return <br />;
          } else {
            return <span key={index}>{part}</span>;
          }
        })}
      </div>
    );
  };

  return (
    <div
      key={`comment-box-${comment.Id}`}
      className={classNames(
        styles.commentBox,
        {
          [styles.panelComment]: isPanelComment,
        },
        commentBoxClass,
      )}
    >
      <FormCommentBoxHeader
        isHeader={isHeader}
        isResolved={isResolved}
        threadId={threadId}
        userInfo={userInfo}
        comment={comment}
        setEditingMode={setEditingMode}
        handleDeleteFormComment={handleDeleteFormComment}
        handleToggleResolveThread={handleToggleResolveThread}
      />
      <div
        className={classNames(styles.comment, {
          [styles.panelCommentText]: isPanelComment,
        })}
      >
        {editingMode ? (
          <div className={styles.editingMode} onClick={(e) => editingMode && e.stopPropagation()}>
            <div className={styles.inputContainer}>
              <FormCommentMention
                tagableUsers={tagableUsers}
                editedComment={editedComment}
                setEditedComment={setEditedComment}
              />
            </div>
            <div className={styles.sendContainer}>
              {isSending ? (
                <Spin size="small" className={styles.sendingLoader} />
              ) : (
                <span
                  className={
                    editedComment.comment && editedComment.comment.trim() !== comment.Comment
                      ? styles.sendIconActive
                      : styles.sendIcon
                  }
                  onMouseDown={checkSendStatus}
                >
                  <SmartFormIcons
                    variant={
                      editedComment.comment && editedComment.comment.trim() !== comment.Comment
                        ? SmartFormIconVariants.Send
                        : SmartFormIconVariants.Cross
                    }
                    {...(editedComment.comment &&
                      editedComment.comment.trim() !== comment.Comment && {
                        stroke: whiteColor,
                      })}
                  />
                </span>
              )}
            </div>
          </div>
        ) : (
          renderComment(comment.Comment)
        )}
      </div>
    </div>
  );
};
