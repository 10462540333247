import { useRef, useEffect, useState } from 'react';
import { Tooltip } from 'components-antd';

import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import styles from './styles.module.scss';

type LimitedLinesTextType = {
  text: string;
  className: string;
  linesCount?: number;
};

export const LimitedLinesText = ({ text, className, linesCount = 2 }: LimitedLinesTextType) => {
  const containerRef = useRef<any>();
  const textRef = useRef<any>();
  const [exceedsTwoLines, setExceedsTwoLines] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    textRef.current.className = '';
    // Get the height of the container and the text
    const containerHeight = containerRef.current.clientHeight;
    const textHeight = textRef.current.offsetHeight;

    // Calculate the line height based on the font size
    const fontSize: any = parseFloat(window.getComputedStyle(textRef.current).fontSize);
    const lineHeight = parseFloat(window.getComputedStyle(textRef.current).lineHeight || fontSize);

    // Calculate the number of lines
    const lines = Math.round(textHeight / lineHeight);
    // Check if the text exceeds two lines
    setExceedsTwoLines(lines > linesCount);
    textRef.current.classList.add(className);
  }, [text]);

  return (
    <div className={styles.limitedLinesContainer} ref={containerRef}>
      <span ref={textRef}>{text}</span>
      {exceedsTwoLines && (
        <div
          className={styles.tooltipContainer}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <Tooltip title={text} placement="left" open={open}>
            <Icon variant={Icon.INFO} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
