import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Button, Col, Modal, Row } from 'components-antd';
import { DatePicker } from 'components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';

import styles from './styles.module.scss';

export const DateControlsModal = ({
  isOpen,
  onClose,
  onDateChange,
  dateControls,
  isProject = false,
}) => {
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const [controls, setControls] = useState({
    ...dateControls,
  });

  useEffect(() => {
    if (dateControls) {
      setControls({
        ...dateControls,
      });
    }
  }, [dateControls]);

  const onSave = (e) => {
    onDateChange(e, controls);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      className={styles.dateControlsModal}
    >
      <h1 className={styles.heading}>Date Controls</h1>
      <Row className={styles.content}>
        <Row gutter={16} className={styles.container}>
          <Col span={12}>
            <div className={styles.title}>
              <div className={styles.prefix}>T</div>
              <p>Today</p>
            </div>
          </Col>
          <Col span={12}>
            <DatePicker
              value={dateControls.T}
              variant={DatePicker.ROUNDED}
              options={{ enableTime: false, maxDate: preForm.closingDate }}
              className={styles.datePicker}
              disabled={true}
            />
          </Col>
        </Row>

        <Row gutter={16} className={styles.container}>
          <Col span={12}>
            <div className={styles.title}>
              <div className={styles.prefix}>{isProject ? 'KO' : 'CD'} </div>
              <p>{isProject ? 'Kickoff Date' : 'Contract Date'} </p>
            </div>
          </Col>
          <Col span={12}>
            <DatePicker
              value={dateControls.CD}
              variant={DatePicker.ROUNDED}
              options={{ enableTime: false, maxDate: preForm.closingDate }}
              className={styles.datePicker}
              disabled={true}
            />
          </Col>
        </Row>

        {dateControls.CL && (
          <Row gutter={16} className={styles.container}>
            <Col span={12}>
              <div className={styles.title}>
                <div className={styles.prefix}>{isProject ? 'DL' : 'CL'}</div>
                <p>{isProject ? 'Deadline' : 'Closing Date'} </p>
              </div>
            </Col>
            <Col span={12}>
              <DatePicker
                value={dateControls.CL}
                variant={DatePicker.ROUNDED}
                options={{ enableTime: false, maxDate: preForm.closingDate }}
                className={styles.datePicker}
                disabled={true}
              />
            </Col>
          </Row>
        )}

        {!isProject && dateControls.LE ? (
          <Row gutter={16} className={styles.container}>
            <Col span={12}>
              <div className={styles.title}>
                <div className={styles.prefix}>LE</div>
                <p>Listing Expiration Date</p>
              </div>
            </Col>
            <Col span={12}>
              <DatePicker
                value={dateControls.LE}
                variant={DatePicker.ROUNDED}
                options={{ enableTime: false, maxDate: preForm.closingDate }}
                className={styles.datePicker}
                disabled={true}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {!isProject && dateControls.TL ? (
          <Row gutter={16} className={styles.container}>
            <Col span={12}>
              <div className={styles.title}>
                <div className={styles.prefix}>TL</div>
                <p>Target Live Date</p>
              </div>
            </Col>
            <Col span={12}>
              <DatePicker
                value={dateControls.TL}
                variant={DatePicker.ROUNDED}
                options={{ enableTime: false, maxDate: preForm.closingDate }}
                className={styles.datePicker}
                disabled={true}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Row gutter={16} className={styles.container}>
          <Col span={12}>
            <div className={styles.title}>
              <div className={styles.prefix}>O</div>
              <p>Custom Date</p>
            </div>
          </Col>
          <Col span={12}>
            <DatePicker
              onChange={(val) => setControls({ ...controls, O: val?.target?.value?.[0] })}
              value={dateControls.O}
              variant={DatePicker.ROUNDED}
              options={{ enableTime: false }}
              className={styles.customDatePicker}
            />
          </Col>
        </Row>
      </Row>
      <Row justify="center" className={styles.actions}>
        <Button variant="secondary" className={styles.saveBtn} onClick={onSave}>
          Save
        </Button>
      </Row>
    </Modal>
  );
};

DateControlsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onDateChange: PropTypes.func,
  dateControls: PropTypes.shape({
    A: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    CD: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    CL: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    LE: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    O: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
};

DateControlsModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onDateChange: () => {},
  dateControls: {
    A: undefined,
    CD: undefined,
    CL: undefined,
    LE: undefined,
    O: undefined,
  },
};
