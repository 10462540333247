import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from 'react-tooltip-lite';

import styles from './styles.module.scss';
import './styles.scss';

const TooltipComponent = (props) => {
  const { children, content, direction, className, tipContentClassName, show, ...rest } = props;

  if (!show) return children;

  return (
    <Tooltip
      className={classNames(className)}
      tipContentClassName={tipContentClassName}
      content={<div className={styles.tooltip}>{content}</div>}
      direction={direction}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

TooltipComponent.propTypes = {
  className: PropTypes.string,
  tipContentClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  direction: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  show: PropTypes.bool,
};

TooltipComponent.defaultProps = {
  className: '',
  tipContentClassName: '',
  direction: undefined,
  show: true,
};

export default TooltipComponent;
