import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Icon from 'pages/Properties/Feed/Icons';
import { DrawerHeaderTitle, DrawerCancel } from 'components';
import { getFeedDrawerCommuteIdSelector } from 'store/selectors/feed';

import { getBuyerCommutesSelector } from 'store/selectors/user';
import styles from './styles.module.scss';

const CommuteHeader = ({ className, onClose }) => {
  const commuteId = useSelector(getFeedDrawerCommuteIdSelector);
  const userCommutes = useSelector(getBuyerCommutesSelector);

  const getTitle = () => {
    if (userCommutes?.[commuteId]) {
      return userCommutes?.[commuteId]?.Name;
    }
    return 'New Commute';
  };

  return (
    <div className={classNames(styles.header, className)}>
      <div className={styles.titleWrapper}>
        <Icon variant={Icon.ADD_BIG} />
        <DrawerHeaderTitle>{getTitle()}</DrawerHeaderTitle>
      </div>
      <DrawerCancel onClick={onClose} />
    </div>
  );
};

CommuteHeader.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

CommuteHeader.defaultProps = {
  className: '',
  onClose: () => {},
};

export default CommuteHeader;
