import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { link } from 'settings/navigation/link';
import BaseNotification from '../../../BaseNotification';
import AddressAndPrice from 'components/Drawers/NotificationsDrawer/components/AddressAndPrice';
import PropertyIcon from '../../../PropertyIcon';

import styles from './styles.module.scss';

const PropertyStatusChange = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const { PropertyPrice, PropertyAddress, PropertyId } = Payload || {};

  const getSubtitle = () => <AddressAndPrice address={PropertyAddress} price={PropertyPrice} />;

  const onClickHandler = () => {
    dispatch(openNotificationsDrawerEffect({ open: false }));
    history.replace(link.toFeedListingDetails(PropertyId));
  };

  return (
    <div className={classNames(styles.notificationType, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        subtitle={getSubtitle()}
        date={CreatedDate}
        groupType={groupType}
        onClick={onClickHandler}
        avatarPlaceholder={<PropertyIcon />}
        severityLevel={SeverityLevel}
      />
    </div>
  );
};

PropertyStatusChange.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    PropertyAddress: PropTypes.shape({
      Line1: PropTypes.string,
      City: PropTypes.string,
      State: PropTypes.string,
    }),
    PropertyId: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

PropertyStatusChange.defaultProps = {
  className: '',
};

export default PropertyStatusChange;
