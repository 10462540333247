import React from 'react';
import { Card, Grid } from 'antd';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { AuditLogItem } from 'components-antd/AuditLogCard/AuditLogItem';
import { AuditLogItemConfig } from 'components-antd/AuditLogCard/helper';
const { useBreakpoint } = Grid;
interface IActivityLogCard {
  activityLogs?: any[];
  DayAndDate?: string;
  activityLogCardClassName?: any;
  timelineItemWrapperClassName?: any;
  timelineTimeLabelClassName?: any;
  timelineContentClassName?: any;
  avatarWrapperClassName?: any;
  activityLogCardClassNameSecondary?: any;
  IsQuoteDrawer?: boolean;
  showActivityTag?: boolean;
  taskCategories?: any[];
}
export const AuditLogCard: React.FC<IActivityLogCard> = ({
  activityLogs = [],
  DayAndDate = 'Monday, January 1, 2022',
  activityLogCardClassName,
  timelineItemWrapperClassName,
  timelineTimeLabelClassName,
  timelineContentClassName,
  avatarWrapperClassName,
  activityLogCardClassNameSecondary,
  IsQuoteDrawer,
  showActivityTag,
  taskCategories,
}) => {
  const { xs } = useBreakpoint();
  return (
    <Card
      className={classNames(
        styles.auditLogCardWrapper,
        xs && styles.auditCardOverflow,
        activityLogCardClassNameSecondary,
        activityLogCardClassName,
      )}
      title={DayAndDate}
    >
      {activityLogs?.length > 0 &&
        activityLogs.map((logItem, idx) => {
          return (
            AuditLogItemConfig[logItem?.ActionType] && (
              <AuditLogItem
                key={idx}
                timestamp={logItem?.ActionTimestamp}
                imgUrl={logItem?.AvatarUrl}
                name={
                  logItem?.UserFirstName &&
                  logItem?.UserLastName &&
                  `${logItem?.UserFirstName[0]} ${logItem?.UserLastName[0]}`
                }
                logItemData={logItem}
                timelineItemWrapperClassName={timelineItemWrapperClassName}
                timelineTimeLabelClassName={timelineTimeLabelClassName}
                timelineContentClassName={timelineContentClassName}
                avatarWrapperClassName={`${
                  activityLogs?.length - 1 === idx && styles.avatarWrapperNoBorder
                } ${avatarWrapperClassName ? avatarWrapperClassName : ''}`}
                IsQuoteDrawer={IsQuoteDrawer}
                showActivityTag={showActivityTag}
                taskCategories={taskCategories}
              />
            )
          );
        })}
    </Card>
  );
};
