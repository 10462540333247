import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Close, Compare } from 'components/Icons';
import { ProfileIcons } from 'components/ClientsDrawer/ProfileClient/Icons';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';

import styles from './styles.module.scss';

export const LocalHeader = ({
  multiSelect,
  setMultiSelect,
  openAssignClientsModal,
  count,
  teamRole,
}) => {
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);

  if (count === 0) return null;
  return (
    <div className={styles.clientsHeader}>
      <h4>{multiSelect ? `Select` : count} Clients</h4>
      {multiSelect ? (
        <Close className={styles.icon} onClick={() => setMultiSelect(false)} />
      ) : (
        isTeamAgentActive && (
          <div className={styles.headerRight}>
            {(teamRole === TEAM_OWNER || teamRole === TEAM_ADMIN) && (
              <div className={styles.icon} onClick={openAssignClientsModal}>
                <ProfileIcons name="addParticipants" />
              </div>
            )}
            <Compare className={styles.icon} onClick={() => setMultiSelect(true)} />
          </div>
        )
      )}
    </div>
  );
};

LocalHeader.propTypes = {
  multiSelect: PropTypes.bool,
  setMultiSelect: PropTypes.func,
  count: PropTypes.number,
};

LocalHeader.defaultProps = {
  multiSelect: false,
  setMultiSelect: () => {},
  count: 0,
};
