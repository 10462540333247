import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import axios from 'axios';

import { PDFEditor } from 'pages';
import { PageWrapper } from 'components-antd';
import { getPDFQuestions } from 'utils';
import { SmartLeftSidebar, SmartRightSidebarUpload, UploadPDFContainer } from 'pages/FormBuilder';
import { resetSmartFormBuilderEffect, updateSmartFormEffect } from 'store/effects/formBuilder';

import styles from './styles.module.scss';

export const NewSmartForm = () => {
  const [file, setFile] = useState<File>();

  const [editMode, setEditMode] = useState(false);

  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(resetSmartFormBuilderEffect());
    },
    [],
  );

  useEffect(() => {
    if (file) {
      updatePDF();
    }
  }, [file]);

  const updatePDF = async () => {
    if (file) {
      const data = await axios.get(URL.createObjectURL(file), {
        responseType: 'arraybuffer',
      });

      const pdfDoc = await PDFDocument.load(data.data);

      const questions = getPDFQuestions(pdfDoc);
      dispatch(
        updateSmartFormEffect({
          questions: questions,
        }),
      );
    }
  };

  const handleUpdateDocument = (file: File) => {
    setFile(file);
    setTimeout(() => setEditMode(false), 0);
  };

  return editMode && file ? (
    <PDFEditor
      link={URL.createObjectURL(file)}
      handleExit={() => setEditMode(false)}
      documentName={file?.name}
      updateDocument={handleUpdateDocument}
    />
  ) : (
    <PageWrapper
      SiderComponent={() => <SmartLeftSidebar upload={true} />}
      RightSiderComponent={() => (
        <SmartRightSidebarUpload file={file} setFile={setFile} setEditMode={setEditMode} />
      )}
      mainPageContentStyle={styles.questionContainer}
      footerStyles={styles.footerStyles}
    >
      <UploadPDFContainer file={file} setFile={setFile} />
    </PageWrapper>
  );
};
