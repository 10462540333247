import { AntCard } from 'components-antd';

import { TimelineItem } from '../TimelineItem';

import styles from './styles.module.scss';
import moment from 'moment';
import { getFormattedDate } from '../../helper';
import classNames from 'classnames';

export const GroupedTimeline = (props) => {
  const {
    children,
    action,
    checkboxChecked,
    onCheckboxChange,
    selectedKey,
    groupedKey,
    tours,
    onCancelTour,
    onEditTour,
    sort,
    groupedTimelineClassName,
    timelineItemClassName,
  } = props;
  const groupsToursByDate = tours.reduce((groups, tour) => {
    const date = moment(tour.ScheduleDateTime).format('MM/DD/YYYY');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(tour);
    return groups;
  }, {});

  return (
    <>
      {Object.keys(groupsToursByDate)
        .sort()
        .map((item, i) => (
          <AntCard
            title={getFormattedDate(groupsToursByDate[item][0].ScheduleDateTime)}
            className={classNames(styles.groupedItems, groupedTimelineClassName)}
            key={i}
          >
            {groupsToursByDate[item].map((tour, i) => (
              <TimelineItem
                onCancelTour={onCancelTour}
                onEditTour={onEditTour}
                tour={tour}
                sort={sort}
                selectedKey={selectedKey}
                groupedKey={groupedKey}
                action={action}
                checkboxChecked={checkboxChecked}
                onCheckboxChange={onCheckboxChange}
                key={i}
                className={timelineItemClassName}
              >
                {children}
              </TimelineItem>
            ))}
          </AntCard>
        ))}
    </>
  );
};
