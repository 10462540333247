import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { setSharePropertyEffect } from 'store/effects';
import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const Comment = ({ className, propertyInfo, threadsPerPropertyId }) => {
  const dispatch = useDispatch();

  const onComment = (e) => {
    e.stopPropagation();
    dispatch(setSharePropertyEffect(propertyInfo));
    dispatch(openCommentsDrawerEffect({ open: true }));
  };

  const propertyThreads = threadsPerPropertyId?.[propertyInfo?.Id] || [];
  const countThreads = propertyThreads?.length;
  const isUnreadThread = propertyThreads.some((thread) => !!thread?.IsUnread);

  return (
    <div
      onClick={onComment}
      className={classNames(
        styles.commentWrapper,
        { [styles.isUnread]: isUnreadThread },
        className,
      )}
    >
      <Icon className={styles.icon} testid="comment_icon" variant={Icon.COMMENT} />
      {!!countThreads && <span className={styles.count}>{countThreads}</span>}
    </div>
  );
};

Comment.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  threadsPerPropertyId: PropTypes.shape({}).isRequired,
};

Comment.defaultProps = {
  className: '',
};

export default Comment;
