const haversineDistance = (coords1, coords2) => {
  const toRadians = (deg) => (deg * Math.PI) / 180;

  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(coords2.lat - coords1.lat);
  const dLng = toRadians(coords2.lng - coords1.lng);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(coords1.lat)) *
      Math.cos(toRadians(coords2.lat)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in kilometers
};

const filterMarkers = (markers, distanceThreshold) => {
  const filteredMarkers = [];

  markers.forEach((marker1) => {
    const coords1 = marker1?.[0]?.geometry?.coordinates;
    let closeMarkersCount = 0;

    markers.forEach((marker2) => {
      const coords2 = marker2?.[0]?.geometry?.coordinates;
      const distance = haversineDistance(
        { lat: coords1.lat, lng: coords1.lng },
        { lat: coords2.lat, lng: coords2.lng },
      );

      if (distance <= distanceThreshold) {
        closeMarkersCount++;
      }
    });

    if (closeMarkersCount > filteredMarkers.length) {
      filteredMarkers.length = 0; // Reset the array
      markers.forEach((marker2) => {
        const coords2 = marker2?.[0]?.geometry?.coordinates;
        const distance = haversineDistance(
          { lat: coords1.lat, lng: coords1.lng },
          { lat: coords2.lat, lng: coords2.lng },
        );

        if (distance <= distanceThreshold) {
          filteredMarkers.push(marker2);
        }
      });
    }
  });

  return filteredMarkers;
};

export const getMarkersBounds = (markers, distanceThreshold = 500) => {
  if (markers?.length > 1 && window.google?.maps?.LatLngBounds) {
    const filteredMarkers = filterMarkers(markers, distanceThreshold);
    const bounds = new window.google.maps.LatLngBounds();

    filteredMarkers.forEach((properties) => {
      const marker = properties?.[0];
      const coordinates = marker?.geometry?.coordinates;

      if (coordinates?.lat && coordinates?.lng) {
        bounds.extend(new window.google.maps.LatLng(coordinates.lat, coordinates.lng));
      }
    });

    return bounds;
  }
};

export const getClusterStyleIndex = (clusterMarkers, stylesLength) => {
  let index = 0;
  let divided = clusterMarkers.length;

  while (divided !== 0) {
    divided = Math.trunc(divided / 10);
    index++;
  }

  return Math.min(index, stylesLength);
};
//These functions will calculate zoom level for dynamic drawn polygon on the bases of coordinates.
export const getZoomForBounds = (bounds, worldDim) => {
  const ZOOM_MAX = 21;

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(worldDim.height, latFraction, bounds);
  const lngZoom = zoom(worldDim.height, lngFraction, bounds);

  const result = Math.min(latZoom, lngZoom, ZOOM_MAX);
  return result;
};

const latRad = (lat) => {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

const zoom = (mapPx, fraction, bounds) => {
  return Math.log(mapPx / 256 / fraction) / Math.LN2;
};

export const getClusterText = (clusterMarkers, markersMap, label = 'Units') => {
  let text = '';

  if (clusterMarkers?.length) {
    text = `${clusterMarkers.length}`;

    if (markersMap && Object.keys(markersMap).length) {
      const propertiesWithinSameLocationsLength = clusterMarkers.reduce((amount, marker) => {
        const { position } = marker;
        const key = `${position?.lng()} ${position?.lat()}`;
        if (markersMap[key]?.length > 1) {
          return amount + markersMap[key].length - 1;
        }
        return amount;
      }, 0);

      if (propertiesWithinSameLocationsLength) {
        text = `${propertiesWithinSameLocationsLength + clusterMarkers.length}`;
      }
    }
  }

  return `${text} ${label}`;
};
