import { useEffect, useCallback, useState } from 'react';

import { ShowingApproval } from './Confirmation';
import { getShowingAppointmentApprovalByUUID } from 'api/showingAppointment';
import { LinkExpired } from 'components';

import styles from './styles.module.scss';

export const ExternalDelegates = (props) => {
  const { match } = props;
  const UUID = match?.params?.uuid;
  const [approval, setApproval] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const fetchAppointmentDetails = useCallback(async (UUID) => {
    setIsPending(true);
    const { data } = await getShowingAppointmentApprovalByUUID({ UUID });
    if (data?.value) setApproval(data?.value);
    setIsPending(false);
  }, []);

  useEffect(() => {
    if (UUID) fetchAppointmentDetails(UUID);
  }, [UUID]);

  return (
    <div className={styles.confirmationArea}>
      {!approval && !isPending ? (
        <LinkExpired />
      ) : (
        <ShowingApproval
          approval={approval}
          isPending={isPending}
          setIsPending={setIsPending}
          UUID={UUID}
          fetchAppointmentDetails={fetchAppointmentDetails}
        />
      )}
    </div>
  );
};
