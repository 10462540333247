import { createSelector } from 'reselect';
import { FormBuilderType } from 'types';

const localState = ({ formBuilder }) => formBuilder.data;

const dynamicFormState = ({ requestFormProcess }) => requestFormProcess;

export const getFormListSelector = createSelector(localState, ({ formList }: FormBuilderType) => {
  return {
    formList,
  };
});

export const getFormReloadSelect = createSelector(
  localState,
  ({ formList: { refresh } }: FormBuilderType) => {
    return refresh;
  },
);

export const getFormListSearchSelect = createSelector(
  localState,
  ({ formList: { search } }: FormBuilderType) => {
    return search;
  },
);

export const getFormMetaSelect = createSelector(
  localState,
  ({ meta: { tags, categories, state, users, smartFormCategories, teams } }: FormBuilderType) => {
    return { tags, categories, state, users, smartFormCategories, teams };
  },
);

export const getDeleteFormVersionSelect = createSelector(
  localState,
  ({ deleteFormVersion }: FormBuilderType) => {
    return deleteFormVersion;
  },
);

export const getFormListSearch = createSelector(
  localState,
  ({
    formList: {
      search: { locations },
      errors,
    },
  }: FormBuilderType) => {
    return { locations, errors };
  },
);

export const getTemplateForm = createSelector(dynamicFormState, ({ dynamicForm }: any) => {
  return { dynamicForm };
});
