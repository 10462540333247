import Api from 'store/effects/core/api';
import {
  requestGetShowingDetails,
  requestGetShowingAvailability,
  requestCreateShowingAppointment,
  requestUpdateShowingAppointment,
  requestGetShowingAppointmentById,
  requestGetShowingAppointmentApprovalByUserId,
  requestGetAllShowingAppointment,
  requestUpdateShowingAppointmentApproval,
  requestDeleteAppointmentDocumentById,
} from 'store/actions/showingAppointment';
import {
  getShowingDetails,
  getShowingAvailability,
  saveShowingAppointment,
  updateShowingAppointment,
  getShowingAppointmentById,
  getAllAppointmentApprovalByUserId,
  getAllShowingAppointments,
  updateShowingAppointmentsApproval,
  deleteAppointmentDocumentById,
  sendShowingAppointmentFeedback,
} from 'api/showingAppointment';
import { LocalStorage } from 'services';

export const requestGetShowingDetailsEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetShowingDetails,
    method: getShowingDetails,
  });
  return sendRequest(cfg, options, cb);
};

export const requestGetShowingAvailabilityEffect = (cfg) => {
  const sendRequest = Api.execBase({
    action: requestGetShowingAvailability,
    method: getShowingAvailability,
  });
  return sendRequest(cfg);
};

export const requestCreateShowingAppointmentEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestCreateShowingAppointment,
    method: saveShowingAppointment,
  });
  const body = {
    ...cfg,
    AppointmentDocuments: [
      ...(cfg.AppointmentDocuments || []).map((d) => ({
        ...d,
        data: btoa(d.data),
      })),
    ],
  };
  return sendRequest(body, options, (err, res, dispatch) => {
    if (res?.data?.value?.AccessToken) {
      // Showing appointment endpoint will send back external token
      // to allow access to same page afterwards without extra verification
      LocalStorage.setExternalToken(res.data.value.AccessToken);
    }

    cb && cb(err, res, dispatch);
  });
};

export const requestGetShowingAppointmentDetailsEffect = (
  cfg,
  externalToken: string | null,
  cb,
) => {
  const sendRequest = Api.execBase({
    action: requestGetShowingAppointmentById,
    method: getShowingAppointmentById,
  });
  return sendRequest(
    cfg,
    {
      externalToken,
    },
    cb,
  );
};

export const requestGetAllAppointmentsApprovalEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetShowingAppointmentApprovalByUserId,
    method: getAllAppointmentApprovalByUserId,
  });
  return sendRequest(cfg, options, cb);
};

export const requestGetAllShowingAppointmentsEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetAllShowingAppointment,
    method: getAllShowingAppointments,
  });
  return sendRequest(cfg, options, cb);
};

export const requestUpdateShowingAppointmentEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestUpdateShowingAppointment,
    method: updateShowingAppointment,
  });
  return sendRequest(cfg, options, cb);
};

export const requestSendShowingAppointmentFeedbackEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestUpdateShowingAppointment,
    method: sendShowingAppointmentFeedback,
  });
  return sendRequest(cfg, options, cb);
};

export const requestUpdateShowingAppointmentApprovalEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestUpdateShowingAppointmentApproval,
    method: updateShowingAppointmentsApproval,
  });
  return sendRequest(cfg, options, cb);
};

export const requestDeleteAppointmentDocumentByIdEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestDeleteAppointmentDocumentById,
    method: deleteAppointmentDocumentById,
  });
  return sendRequest(cfg, options, cb);
};
