import { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Input, Select, Option } from 'components-antd';
import { showErrorMessage } from 'helpers';

import styles from './styles.module.scss';

const timeOptions = {
  AM: 'AM',
  PM: 'PM',
};

export const TimeInput = ({
  value,
  onChange,
  getPopupContainer,
  className,
  wrapperClassName,
  selectClassName,
  popupClassName,
  disabled,
}) => {
  const getDigitFormat = (digit: number) => {
    if (digit < 10) {
      return `0${digit}`;
    } else {
      return digit;
    }
  };

  const getHourMinutes = (val) => {
    if (!val) return undefined;

    const dateTime = new Date(val);
    let hour = dateTime.getHours();
    hour = hour % 12;
    hour = hour ? hour : 12;
    const minutes = dateTime.getMinutes();

    return `${getDigitFormat(hour)}:${getDigitFormat(minutes)}`;
  };

  const getTimeOption = (val) => {
    if (!val) return timeOptions.AM;

    const timeOption = moment(val).format('LT').split(' ')[1];

    return timeOption;
  };

  const [time, setTime]: any = useState(getHourMinutes(value));
  const [option, setOption]: any = useState(getTimeOption(value));

  const onTimeChange = (event) => {
    let { value: inputValue, selectionStart, selectionEnd } = event.target;

    // Remove any non-numeric characters
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

    // Limit to 4 characters (HHMM format)
    const formattedValue = sanitizedValue.slice(0, 4);

    // Insert colon after two digits
    if (
      formattedValue.length === 2 &&
      inputValue.length === 2 &&
      selectionStart === 2 &&
      selectionEnd === 2
    ) {
      inputValue = `${formattedValue}:`;
      // Adjust selectionStart to position after colon
      selectionStart++;
    }

    // Handle backspace to remove colon
    if (
      event.nativeEvent.inputType === 'deleteContentBackward' &&
      selectionStart === 3 &&
      inputValue.charAt(2) === ':'
    ) {
      inputValue = inputValue.slice(0, 2);
    }

    // Update input value and cursor position
    const regex = /^(?:0?[0-9]|1[0-2])(?::([0-5]?[0-9])?)?$|^$/;
    if (regex.test(inputValue)) {
      setTime(inputValue);
    }
    event.target.value = inputValue;
    event.target.setSelectionRange(selectionStart, selectionStart);
  };

  const onBlur = (val, opt) => {
    if (!val) return;
    let tmp = val.split(':');

    if (tmp[0] && isNaN(tmp[0] || tmp[1].length > 2)) {
      setTime(tmp[1] ? `:${tmp[1]}` : '');
      showErrorMessage('Invalid hour format');
      return;
    } else if (tmp[1] && (isNaN(tmp[1]) || tmp[1].length !== 2)) {
      setTime(tmp[0] ? `${tmp[0]}:` : '');
      showErrorMessage('Invalid minute format');
      return;
    }

    let hour = Number(tmp[0]);
    const minutes = tmp[1]?.length ? Number(tmp[1]) : 0;

    setTime(`${getDigitFormat(hour)}:${getDigitFormat(minutes)}`);

    if (hour < 1 || hour > 12 || minutes > 59) {
      setTime('');
      showErrorMessage('Invalid time entered');
      return;
    }

    const now = moment(`${hour}:${minutes} ${opt}`, 'h:mm A');

    onChange(now.toDate());
  };

  return (
    <div className={classNames(styles.timeInput, wrapperClassName)}>
      <Input
        placeholder="00:00"
        value={time}
        onChange={onTimeChange}
        onBlur={(e) => onBlur(e.target.value, option)}
        className={classNames(className)}
        disabled={disabled}
        maxLength={5}
      />
      <Select
        defaultValue={timeOptions.AM}
        placeholder={timeOptions.AM}
        value={option}
        onChange={(val) => {
          setOption(val);
          onBlur(time, val);
        }}
        className={selectClassName}
        popupClassName={popupClassName}
        getPopupContainer={getPopupContainer}
        disabled={disabled}
      >
        <Option key={timeOptions.AM} value={timeOptions.AM}>
          AM
        </Option>
        <Option key={timeOptions.PM} value={timeOptions.PM}>
          PM
        </Option>
      </Select>
    </div>
  );
};

TimeInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  getPopupContainer: PropTypes.func,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  popupClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

TimeInput.defaultProps = {
  value: undefined,
  onChange: () => {},
  getPopupContainer: () => {},
  wrapperClassName: '',
  className: '',
  selectClassName: '',
  popupClassName: '',
  disabled: false,
};
