/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { PENDING } from 'settings/constants/apiState';
import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';

import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';
import { Answers, Button } from 'components';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';
import { NextButton, Wrapper } from '../..';
import { MosaikLogo } from 'components/Icons';

import styles from './styles.module.scss';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingSkipButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingSkipButton';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

const StagePreApproved = ({ onNext, Controls, stageIndex, isUserWasPreApproved }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);

  const propName = OnBoardingWizard.propNames.isPreApproved;
  const storedWantToBeApprovedVal =
    getWizardStageValue({
      obj: onBoarding,
      stageIndex,
      propName,
      signUpRole,
    }) || [];
  const [stageValue, setStageValue] = useState(storedWantToBeApprovedVal);

  const onSave = () => {
    const cfg = {
      stageIndex,
      DefaultPropertySearchPreferences: {
        [propName]: stageValue,
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, { withoutRequest: true }, (err) => {
        if (!err) {
          if (stageValue.includes('no') || !stageValue.length) {
            onNext();
          } else {
            onNext('stageDocument');
          }
        }
      }),
    );
  };

  const onAnswer = (val) => {
    isUserWasPreApproved(val.includes('yes'));
    setStageValue(val);
  };

  const isPending = onBoarding.state === PENDING;

  return (
    <OnBoardingWrapper isPending={isPending} testid="pre_approved_stage">
      <Controls
        onNext={onSave}
        isNextDisabled={!stageValue.length}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer className={styles.container}>
        <Question className={styles.question}>
          Awesome! You can check out the homes that meet your criteria anytime in your Feed (or
          create new searches anytime on the Search page).
        </Question>
        <Question className={styles.question}>
          One last thing - have you been pre-approved?
        </Question>
        <AnswersContainer>
          <Answers
            cases={StagePreApproved.answerCases}
            onChange={onAnswer}
            value={stageValue}
            pending={isPending}
            testid="pre_approved_answers"
            caseClassName={styles.answer}
            activeCaseClassName={styles.answerActive}
            labelClassName={styles.label}
            className={styles.answersWrapper}
          />
        </AnswersContainer>
        <ButtonsContainer>
          <OnboardingContinueButton
            testid="pre_approved_next"
            onClick={onSave}
            isPending={isPending}
            disabled={!stageValue.length}
            iconArrow
          />
          <OnboardingSkipButton
            testid="skip"
            type="button"
            onClick={onNext}
            className={styles.skip}
            titleClassName={styles.skipTitle}
            title="Skip Question"
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

StagePreApproved.answerCases = [
  { id: 'yes', label: 'Yes' },
  { id: 'no', label: 'No, take me to my feed.' },
];

StagePreApproved.propTypes = {
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  isUserWasPreApproved: PropTypes.func,
  Controls: PropTypes.elementType,
};

StagePreApproved.defaultProps = {
  onNext: () => {},
  stageIndex: undefined,
  isUserWasPreApproved: () => {},
  Controls: () => null,
};

export default StagePreApproved;
