import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { convertNameToAvatarPlaceholder, getPropertyAddress } from 'helpers';
import { useSelector, useDispatch } from 'react-redux';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import { ArchiveModal } from 'pages/Quotes/components';
import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import { Avatar } from 'components';
import { archiveQuotesEffect } from 'store/effects/quotes';
import { AGENT } from 'settings/constants/roles';
import { openQuotesDrawerAction } from 'store/actions/drawers/quotes';
import { getQuotesEntryIdThreadIdMap } from 'store/selectors/sockets/threads';
import { quoteRequestStatusDisplayMap } from 'settings/constants/quotes';

import styles from './styles.module.scss';

const ThirdPartyHeader = (props) => {
  const { className, onCancel } = props;
  const { quote, requestorRole, requestorName, agentName, clientName } =
    useSelector(getQuoteDetailsSelector);
  const dispatch = useDispatch();
  const entryThreadIdMap = useSelector(getQuotesEntryIdThreadIdMap);

  const [archiveQuoteId, setArchiveQuoteId] = useState(null);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [isPendingArchiveRequest, setIsPendingArchiveRequest] = useState(false);

  const status = quoteRequestStatusDisplayMap[quote?.Status];

  const onArchive = () => {
    if (!quote?.IsArchived) {
      setArchiveQuoteId(quote?.Id);
      setOpenArchiveModal(true);
    } else {
      dispatch(archiveQuotesEffect({ ids: [quote?.Id], newArchivedState: false }));
    }
  };

  const onCloseArchiveModal = () => {
    setOpenArchiveModal(false);
    setArchiveQuoteId(null);
  };

  const onSubmitArchive = (status) => {
    setIsPendingArchiveRequest(true);
    dispatch(
      archiveQuotesEffect({ ids: [archiveQuoteId], status }, {}, (err) => {
        if (!err) {
          setOpenArchiveModal(false);
        }
        setIsPendingArchiveRequest(false);
      }),
    );
  };

  const onComment = useCallback(() => {
    onCancel();
    dispatch(
      openQuotesDrawerAction({
        open: true,
        params: { threadId: entryThreadIdMap[quote?.Id], isQuoteDetails: true },
      }),
    );
  }, [dispatch, entryThreadIdMap, quote?.Id]);

  moment.updateLocale('en', {
    relativeTime: {
      future: 'just now',
    },
  });
  const getDate = () => moment(quote?.UpdatedDate).from(new Date());

  return (
    <div className={classNames(styles.thirdPartyHeader, className)}>
      <div className={styles.inner}>
        <div className={styles.details}>
          <div className={styles.logo}>
            <Avatar
              avatarClassName={styles.avatar}
              src={quote?.Requestor?.LogoUrl}
              placeholder={convertNameToAvatarPlaceholder(requestorName)}
            />
          </div>
          <div>
            <h1 testid="requestor" className={styles.title}>
              {requestorName}
              {requestorRole === AGENT && <span className={styles.category}>{requestorRole}</span>}
            </h1>
            <p className={styles.date}>{`Last updated: ${getDate()}`}</p>
          </div>
        </div>
        <div className={styles.actions}>
          <div
            className={classNames(styles.status, {
              [styles.isPending]: status === quoteRequestStatusDisplayMap.PendingAnswer,
              [styles.isResponded]: status === quoteRequestStatusDisplayMap.Responded,
            })}
          >
            <span className={styles.statusInner}>{'Status: '}</span>
            {status}
          </div>
          <div onClick={onComment} className={styles.commentWrapper}>
            <Icon className={styles.icon} testid="comment_icon" variant={Icon.COMMENT} />
          </div>
          <div onClick={onArchive} className={styles.archiveWrapper}>
            <Icon className={styles.icon} testid="archive_icon" variant={Icon.ARCHIVE} />
          </div>
        </div>
      </div>
      <ArchiveModal
        isOpen={openArchiveModal}
        onClose={onCloseArchiveModal}
        onSubmit={onSubmitArchive}
        isPending={isPendingArchiveRequest}
        archivedStatus={quote?.ArchivedStatus}
      />
    </div>
  );
};

ThirdPartyHeader.propTypes = {
  className: PropTypes.string,
};

ThirdPartyHeader.defaultProps = {
  className: '',
};

export default ThirdPartyHeader;
