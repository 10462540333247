import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'components-antd';

import { AddPhoto, FormGroup } from 'components';
import { getUserDataSelector, getUserRoleSelector, isPartnerSelector } from 'store/selectors/user';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { onBoardingSignUpEffect } from 'store/effects';
import { PENDING } from 'settings/constants/apiState';
import { routes } from 'settings/navigation/routes';
import { OnboardingSkipButton } from '../agent/components/OnboardingSkipButton';
import { OnboardingContinueButton } from '../agent/components/OnboardingContinueButton';
import { THIRD_PARTY } from 'settings/constants/roles';

import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { OnBoardingWrapper } from '../../OnBoardingWrapper';
import { OnBoardingContainer } from '../../OnBoardingContainer';

import styles from './styles.module.scss';

const VendorDirectory = (props) => {
  const { className, onNext, stageIndex, Controls } = props;
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const userRole = useSelector(getUserRoleSelector);
  const { onBoarding } = useSelector(getOnBoardingData);
  const isPartner = useSelector(isPartnerSelector);
  const history = useHistory();

  const isPending = onBoarding.state === PENDING;
  const onSkip = () => (userRole === THIRD_PARTY ? history.push(routes.index) : onNext());

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        onBoardingSignUpEffect(
          { ...values, stageIndex },
          { isPartner, isCommonInfo: true },
          (err) => {
            if (!err) onNext();
          },
        ),
      );
    },
    [(onNext, stageIndex, dispatch)],
  );

  const formik = useFormik({
    initialValues: {
      DirectoryPreference: null,
    },
    onSubmit,
  });

  return (
    <OnBoardingWrapper isPending={isPending}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />

      <OnBoardingContainer className={styles.container}>
        <Question className={styles.title}>
          Do you want to be added to the Mosaik vendor directory?
        </Question>
        <p className={styles.partnerSubheader}>
          This will make your profile visible to all agents who use Mosaik in your area who are
          looking for services that you offer or vendors to recommend to their clients.
          <br />
          <br />
          There are no costs or fees associated with inclusion in the directory.
        </p>
        <AnswersContainer className={styles.answersContainer}>
          <Button
            className={classNames(styles.button, {
              [styles.active]: formik.values?.DirectoryPreference === true,
            })}
            variant="secondary"
            onClick={() => formik.setFieldValue('DirectoryPreference', true)}
          >
            Yes
          </Button>
          <Button
            className={classNames(styles.button, {
              [styles.active]: formik.values?.DirectoryPreference === false,
            })}
            variant="secondary"
            onClick={() => formik.setFieldValue('DirectoryPreference', false)}
          >
            No
          </Button>
        </AnswersContainer>
        <ButtonsContainer>
          <OnboardingContinueButton
            isPending={isPending}
            disabled={formik.values?.DirectoryPreference === null}
            testid="next"
            htmlType="submit"
            className={classNames(styles.submitBtn, {
              [styles.active]: formik.values?.DirectoryPreference !== null,
            })}
            onClick={() => formik.submitForm()}
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

VendorDirectory.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

VendorDirectory.defaultProps = {
  className: '',
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default VendorDirectory;
