import { createSelector } from 'reselect';
import { getUserEmail, getUserPhones } from 'store/selectors/user';

export const getUserInfo = createSelector(
  getUserEmail as never,
  getUserPhones as any,
  (userEmail: string, userPhones: any) => ({
    userEmail,
    userPhones,
  }),
);
