import { useCallback } from 'react';
import PropTypes from 'prop-types';

const Heart = ({ className, color, variant }) => {
  const getIcon = useCallback(() => {
    switch (variant) {
      case Heart.HOLLOW:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M7.18576 4.31158L8 5.4536L8.81424 4.31158C9.37921 3.51916 10.3136 3 11.375 3C13.1126 3 14.5 4.38799 14.5 6.07407C14.5 6.93684 14.1399 7.71724 13.5531 8.27837L13.5425 8.2885L13.5322 8.29894L8.47447 13.428C8.21342 13.6927 7.78627 13.6927 7.52517 13.428L2.36368 8.19576C1.82636 7.64178 1.5 6.89553 1.5 6.07407C1.5 4.38798 2.88736 3 4.625 3C5.68642 3 6.62078 3.51916 7.18576 4.31158Z"
              stroke={color}
              strokeWidth="1.5"
            />
          </svg>
        );
      default: {
        return (
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.0011 20.0001L5.00108 13.0001C4.60711 12.5733 4.30107 12.0731 4.10042 11.528C3.89977 10.9829 3.80844 10.4037 3.83166 9.82333C3.87854 8.65122 4.38912 7.54575 5.25108 6.7501C6.11303 5.95445 7.25575 5.5338 8.42785 5.58069C9.59996 5.62757 10.7054 6.13815 11.5011 7.0001C11.6491 7.09899 11.8231 7.15177 12.0011 7.15177C12.1791 7.15177 12.3531 7.09899 12.5011 7.0001C13.2967 6.13815 14.4022 5.62757 15.5743 5.58069C16.7464 5.5338 17.8891 5.95445 18.7511 6.7501C19.613 7.54575 20.1236 8.65122 20.1705 9.82333C20.2174 10.9954 19.7967 12.1382 19.0011 13.0001L12.0011 20.0001Z"
              stroke={color}
              fill="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
    }
  }, [variant]);
  return <div className={className}>{getIcon(variant)}</div>;
};

Heart.HOLLOW = 'hollow';
Heart.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
};

Heart.defaultProps = {
  className: '',
  color: 'white',
  variant: undefined,
};

export default Heart;
