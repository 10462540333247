import React, { Fragment, useRef, useState } from 'react';

import { cloneDeep } from 'lodash-es';
import classNames from 'classnames';
import moment from 'moment';

import { EditContactInformation, EditHighlights } from '../components';
import { Navigation } from '../../components/Footer';

import styles from './styles.module.scss';
import { deleteOfferIterationDocumentById } from 'api/offers';
import { useExternalVerificationRestriction } from 'features/externalVerification/useExtermalVerificationRestriction';
import { ScrollToTop } from 'pages/ExternalOffer/OfferSubmission/components/ScrollToTop';
import { CardBox } from 'pages/ExternalOffer/OfferSubmission/components/CardBox';
import { Icons } from 'pages/ExternalOffer/OfferSubmission/components/Icons';
import { IconText } from '../components/IconText';

import { useScrollableRef } from 'pages/ExternalOffer/OfferSubmission/hooks/useScrollableRef';
import { UploadDocumentField } from './UploadDocumentField';
import { showErrorMessage } from 'helpers';

const documentInitState: any = {
  name: '',
  required: false,
  selected: false,
  format: '*',
};

export const Document = ({
  stageIndex,
  onUpdate,
  onFinish,
  onNext,
  setCurrentStageIndex,
  data,
  loading,
  offerSubmission,
  transaction,
  editModal,
  token,
  stagesStep,
  lastStageIndex,
}) => {
  const { OfferIteration: iteration, RequiredDocuments } = data;

  const [state, setState] = useState({ OfferIteration: cloneDeep(iteration) });
  const [requiredDocuments, setRequiredDocuments] = useState(
    RequiredDocuments || transaction.RequiredDocuments,
  );

  const [removedDocuments, setRemovedDocuments] = useState<string[]>([]);
  const [removing, setRemoving] = useState(false);

  const [requiredDocsError, setRequiredDocsError] = useState(false);
  const { token: externalToken, isTokenLoaded: isExternalTokenLoaded } =
    useExternalVerificationRestriction();

  const { scrollableDivRef } = useScrollableRef();

  const saveAndContinue = () => {
    const body = {
      ...data,
      ...state,
      RequiredDocuments: requiredDocuments,
    };

    if (data.Id) {
      onUpdate(body);
      onFinish(body);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const onSubmit = () => {
    if (!editModal) {
      let requiredDocsIndexes: number[] = [];

      requiredDocuments.forEach((docItem, index) => {
        if (docItem.required) {
          requiredDocsIndexes.push(index);
        }
      });

      const { OfferIterationDocument } = state.OfferIteration;

      const uploadedDocuments = OfferIterationDocument.filter((item) => item);
      let isRequiredFilled = requiredDocsIndexes.every((requiredIndex) =>
        uploadedDocuments.find((doc) => +doc.code === requiredIndex),
      );

      if (!isRequiredFilled) {
        setRequiredDocsError(true);
        return;
      }

      saveAndContinue();
    } else {
      if (removedDocuments.length > 0 && externalToken) {
        const deletePromises = removedDocuments.map((fileId) =>
          deleteOfferIterationDocumentById(fileId, { externalToken }),
        );

        setRemoving(true);
        Promise.all(deletePromises)
          .then(() => {
            setRemoving(false);
            saveAndContinue();
          })
          .catch((error) => {
            setRemoving(false);
            showErrorMessage(error);
          });
      } else {
        saveAndContinue();
      }
    }
  };

  const onSaveDocument = async (newValues, fileCode, index, cb) => {
    let fileIndex;
    let OfferIterationDocument = state?.OfferIteration?.OfferIterationDocument;

    if (OfferIterationDocument.length >= index + 1) {
      fileIndex = state?.OfferIteration?.OfferIterationDocument.findIndex(
        (x) => x?.documentName === newValues[0].documentName,
      );
    }

    if (fileIndex > -1) {
      OfferIterationDocument.splice(fileIndex, 1);
    }

    let files = OfferIterationDocument || [];

    newValues.forEach((element) => {
      element.code = fileCode || index;
      index > -1 ? (files[index] = element) : files.push(element);
    });

    setState({
      ...state,
      OfferIteration: { ...state.OfferIteration, OfferIterationDocument: files },
    });
    if (typeof cb === 'function') {
      cb();
    }
  };

  const onSaveCustomDocument = (newValues, fileCode, index, cb) => {
    if (!editModal) {
      const data = cloneDeep(requiredDocuments);
      data.push({ ...documentInitState, name: newValues[0].documentName, custom: true });
      setRequiredDocuments(data);
    }

    onSaveDocument(newValues, fileCode, index, cb);
  };

  const deleteSavedFile = async (fileId) => {
    await deleteOfferIterationDocumentById(fileId, { externalToken });
  };

  const onRemoveIteratedDocument = (docItem, fileIndex) => {
    let { OfferIterationDocument } = state.OfferIteration;
    let IteratedDocuments = cloneDeep(OfferIterationDocument);

    IteratedDocuments = IteratedDocuments.filter((item, index) => index !== fileIndex);

    if (docItem.Id) {
      const removed = [...removedDocuments];
      removed.push(docItem.Id);
      setRemovedDocuments(removed);
    }

    setState({
      ...state,
      OfferIteration: {
        ...state.OfferIteration,
        OfferIterationDocument: IteratedDocuments,
      },
    });
  };

  const onRemoveDocument = (document, fileIndex, isCustomDoc) => {
    let { OfferIterationDocument } = state.OfferIteration;
    let IteratedDocuments = cloneDeep(OfferIterationDocument);

    if (editModal && !isCustomDoc) {
      const docIndex = IteratedDocuments.findIndex((item) => item?.DocumentName === document.name);
      if (docIndex > -1) {
        if (isExternalTokenLoaded) {
          deleteSavedFile(IteratedDocuments[docIndex].Id);
        }

        delete IteratedDocuments[docIndex];
      } else {
        delete IteratedDocuments[fileIndex];
      }
    } else {
      delete IteratedDocuments[fileIndex];
    }

    const _requiredDocs = cloneDeep(requiredDocuments);

    if (isCustomDoc) {
      _requiredDocs.splice(fileIndex, 1);
      setRequiredDocuments(_requiredDocs);
    }

    setState({
      ...state,
      OfferIteration: {
        ...state.OfferIteration,
        OfferIterationDocument: IteratedDocuments,
      },
    });
  };

  const onResetDocuments = () => {
    setState({
      ...state,
      OfferIteration: {
        ...state.OfferIteration,
        OfferIterationDocument: editModal ? data.OfferIteration.OfferIterationDocument : [],
      },
    });

    if (!editModal) {
      const documents = requiredDocuments.filter((doc) => !doc.custom);
      setRequiredDocuments(documents);
    }

    removedDocuments.length && setRemovedDocuments([]);
  };

  const formatCreatedDate = (createdDate) => {
    if (!createdDate) return '';

    const date = moment(createdDate).format('MMM D');
    const time = moment(createdDate).format('LT');

    return `${date} at ${time}`;
  };

  let { OfferIterationDocument } = state.OfferIteration;

  return (
    <ScrollToTop>
      <div className={styles.documents}>
        <EditContactInformation
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.ContactInformation)}
        />

        <EditHighlights data={data} onEdit={() => setCurrentStageIndex(stagesStep.Highlights)} />

        <CardBox className={styles.documentsBox}>
          <IconText variant={'documents'} text={'Documents'} className={styles.headerIcon} />

          <div className={styles.documentsWrapper}>
            <div className={styles.inputBlock}>
              {!editModal &&
                requiredDocuments.map((doc, index) => {
                  const fileProps = OfferIterationDocument[index] || {
                    documentName: '',
                    DocumentName: '',
                  };

                  const uploadedFile = fileProps.DocumentName || fileProps.documentName;
                  const hasError = doc.required && requiredDocsError;

                  return (
                    <div
                      key={index}
                      className={classNames(styles.documentItem, {
                        [styles.uploadedDocument]: Boolean(uploadedFile),
                        [styles.documentItemError]: hasError,
                      })}
                    >
                      <IconText
                        variant={uploadedFile ? 'selectedFile' : 'unselectedFile'}
                        text={fileProps.documentName || doc.name}
                        infoText={!uploadedFile && doc.required ? '(Required)' : ''}
                        infoClass={!uploadedFile && doc.required ? styles.requiredText : ''}
                        detailClass={styles.iconTextDetail}
                      />
                      <div>
                        {uploadedFile ? (
                          <Icons
                            variant="cross"
                            className={styles.icon}
                            onClick={() => onRemoveDocument(doc, index, doc.custom)}
                          />
                        ) : (
                          <UploadDocumentField
                            docKey={doc.name}
                            category={doc.name}
                            index={index}
                            onSave={onSaveDocument}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}

              {editModal && (
                <Fragment>
                  {OfferIterationDocument.map((uploadedDoc, index) => {
                    return (
                      <div
                        key={index}
                        className={classNames(styles.documentItem, styles.uploadedDocument)}
                      >
                        <IconText
                          variant={'selectedFile'}
                          text={uploadedDoc.DocumentName || uploadedDoc.documentName}
                          infoText={formatCreatedDate(uploadedDoc.CreatedDate)}
                          detailClass={styles.iconTextDetail}
                        />

                        <Icons
                          variant="cross"
                          className={styles.icon}
                          onClick={() => onRemoveIteratedDocument(uploadedDoc, index)}
                        />
                      </div>
                    );
                  })}
                </Fragment>
              )}

              <div ref={editModal ? scrollableDivRef : null}>
                <UploadDocumentField
                  custom={true}
                  docKey={'custom-document'}
                  onSave={onSaveCustomDocument}
                  uploadText={editModal && !OfferIterationDocument.length ? 'Upload Files' : ''}
                  index={editModal ? OfferIterationDocument.length : requiredDocuments.length}
                />
              </div>
            </div>
          </div>

          <Navigation
            data={data}
            onNext={onSubmit}
            edit={editModal}
            onFinish={onSubmit}
            disabled={false}
            isPending={loading || removing}
            onReset={onResetDocuments}
            isLast={stageIndex === lastStageIndex}
          />
        </CardBox>
      </div>
    </ScrollToTop>
  );
};
