import { Input, InputLabel } from 'components';

import styles from './styles.module.scss';

import { TaskEditableFormValuesKeys, TaskEditableFormValuesType } from 'types/transactionTasks';

interface TaskNameProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  isTemplate: boolean;
  isViewMode?: boolean | null;
  hideAsterisk?: boolean | null;
  isFormTask?: boolean | null;
  inputHolderClassName?: string;
}

export const TaskName = ({
  isTemplate,
  values,
  onChangeField,
  isViewMode,
  hideAsterisk,
  isFormTask = false,
  inputHolderClassName,
}: TaskNameProps) => {
  return (
    <div className={styles.taskName}>
      <div className={styles.fieldWrapper}>
        <InputLabel
          label={isFormTask ? 'Name' : 'Task Name'}
          className={styles.label}
          showAsterisk={!hideAsterisk}
        />
        <Input
          className={styles.field}
          variant="light"
          placeholder="Enter task name"
          value={isTemplate ? values?.Name : values?.Title || ''}
          onChange={(e, val) => onChangeField(val, isTemplate ? 'Name' : 'Title')}
          testid="name_input"
          maxLength={50}
          disabled={isViewMode}
          inputHolderClassName={inputHolderClassName}
        />
      </div>
    </div>
  );
};
