import { useEffect, useState } from 'react';

import { Wrapper as PendingWrapper } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedPageInfoSelector, getWatchlistPropsSelector } from 'store/selectors/feed';
import { PENDING } from 'settings/constants/apiState';
import { useNoMlsAccessHandler } from '../../hooks/useNoMlsAccessHandler';
import { getWatchlistPropsV2Effect } from 'store/effects/feed/feedV2';
import { ListSkeleton } from 'components-antd';
import { PropertiesWrapper, PropertiesList } from 'pages/Properties/Feed/components';
import { getUserRolesMapSelector } from 'store/selectors/user';

import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';

const Watchlist = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const watchlistProps = useSelector(getWatchlistPropsSelector);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const currentPageInfo = useSelector(getFeedPageInfoSelector);

  const { hasMlsAccess, wrappedEffect: getWatchlistPropsEffectWrapped } =
    useNoMlsAccessHandler(getWatchlistPropsV2Effect);

  useEffect(() => {
    if (
      isAgent &&
      (!currentPageInfo?.currentFEPageInfo?.fromListingDetailPage || !watchlistProps?.data?.length)
    )
      dispatch(getWatchlistPropsEffectWrapped());
  }, []);

  const isPending = watchlistProps.state === PENDING;

  return (
    <PropertiesWrapper>
      {(wrapperSize, getNextPageIsPending) => (
        <PendingWrapper
          isPending={isPending}
          className={styles.pendingWrapper}
          customLoader={<ListSkeleton />}
        >
          <PropertiesList
            list={watchlistProps?.data}
            wrapperSize={wrapperSize}
            getNextPageIsPending={getNextPageIsPending}
            hasMlsAccess={hasMlsAccess}
            loadDataEffect={getWatchlistPropsEffectWrapped}
          />
        </PendingWrapper>
      )}
    </PropertiesWrapper>
  );
};

export default Watchlist;
