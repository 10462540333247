import { useState } from 'react';
import { Row, Col, Checkbox, Input } from 'antd';

import {
  EditLinkDuration,
  EditDocuments,
  EditListingAgent,
  EditNotifications,
  EditOfferDeadline,
} from '../components';
import { Navigation } from '../../components';

import styles from './styles.module.scss';
import { IconText } from '../components/IconText';
import { useScrollableRef } from '../../hooks/useScrollableRef';

const { TextArea } = Input;

export const Notes = ({
  onNext,
  stageIndex,
  lastStageIndex,
  onUpdate,
  onFinish,
  setCurrentStageIndex,
  data,
  stagesStep,
}) => {
  const {
    DisplaySubmissionPageNotes,
    SubmissionPageNotes,
    SendOfferSubmissionNotes,
    OfferSubmissionNotes,
  } = data;

  const [state, setState] = useState({
    DisplaySubmissionPageNotes,
    SubmissionPageNotes,
    SendOfferSubmissionNotes,
    OfferSubmissionNotes,
  });

  const { scrollableDivRef } = useScrollableRef();

  const onChange = (data) => {
    setState({ ...state, ...data });
  };

  const onSubmit = () => {
    const body = {
      ...state,
      SubmissionPageNotes: state.DisplaySubmissionPageNotes ? state.SubmissionPageNotes : null,
      OfferSubmissionNotes: state.SendOfferSubmissionNotes ? state.OfferSubmissionNotes : null,
    };
    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(lastStageIndex);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const isDisabled = () => {
    return (
      (state.DisplaySubmissionPageNotes && !state.SubmissionPageNotes) ||
      (state.SendOfferSubmissionNotes && !state.OfferSubmissionNotes)
    );
  };

  return (
    <div className={styles.notes}>
      <div className={styles.contentWrap}>
        <EditLinkDuration
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.LinkDuration)}
        />

        <EditOfferDeadline
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.OfferDeadline)}
        />

        <EditListingAgent
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.ListingAgent)}
        />

        <EditNotifications
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.Notifications)}
        />

        <EditDocuments data={data} onEdit={() => setCurrentStageIndex(stagesStep.Documents)} />

        <IconText variant={'notes-bg'} text={'Notes'} className={styles.iconText} />
        <div className={styles.content}>
          <Row className={styles.container}>
            <Col span={24} className={styles.checkBox}>
              <Checkbox
                checked={state.DisplaySubmissionPageNotes}
                onChange={(e) => onChange({ DisplaySubmissionPageNotes: e.target.checked })}
                defaultChecked={state.DisplaySubmissionPageNotes}
                className={styles.checkbox}
              >
                <div className={styles.noteTitle}>Display a note on the submission page</div>
                <div className={styles.noteSubtitle}>Anyone with the link can see this.</div>
              </Checkbox>
            </Col>
            {state.DisplaySubmissionPageNotes ? (
              <TextArea
                rows={2}
                maxLength={250}
                className={styles.textArea}
                placeholder="Enter a note"
                onChange={(e) => onChange({ SubmissionPageNotes: e.target.value })}
                defaultValue={state.SubmissionPageNotes}
              />
            ) : null}
          </Row>
          <Row className={styles.container} ref={scrollableDivRef}>
            <Col span={24} className={styles.checkBox}>
              <Checkbox
                checked={state.SendOfferSubmissionNotes}
                onChange={(e) => onChange({ SendOfferSubmissionNotes: e.target.checked })}
                defaultChecked={state.SendOfferSubmissionNotes}
                className={styles.checkbox}
              >
                <div className={styles.noteTitle}>Send a note upon offer submission</div>
                <div className={styles.noteSubtitle}>
                  This will be sent only to those who submit offers.
                </div>
              </Checkbox>
            </Col>
            {state.SendOfferSubmissionNotes ? (
              <TextArea
                rows={2}
                maxLength={250}
                className={styles.textArea}
                placeholder="Enter a note"
                onChange={(e) => onChange({ OfferSubmissionNotes: e.target.value })}
                defaultValue={state.OfferSubmissionNotes}
              />
            ) : null}
          </Row>
        </div>
      </div>
      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onFinish={onFinish}
        disabled={isDisabled()}
        className={styles.footer}
      />
    </div>
  );
};
