import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'components-antd';
import { Input, Phone } from 'components';
import { getUserInfo } from '../../selectors';

import styles from './styles.module.scss';

interface SetPhoneNumberStepProps {
  onSubmitPhone: (phoneNumber: string) => void;
}

export const SetPhoneNumberStep: React.FC<SetPhoneNumberStepProps> = (props) => {
  const userInfo = useSelector(getUserInfo);
  const { userPhones } = userInfo || [];
  const primaryPhone = userPhones?.find((phone) => phone.IsPrimary) ?? null;
  const [editablePhone, setEditablePhone] = useState<string | null>(
    primaryPhone?.PhoneNumber ?? null,
  );

  return (
    <div className={styles.layout}>
      <h2 className={styles.stepHeader}>What phone number should we use?</h2>
      <div>
        Please enter the phone number you want us to send mobile notifications to. You can change
        your notification settings anytime
      </div>
      <div>
        <Phone
          inputClassName={styles.input}
          placeholder="(000) 000-0000"
          variant={Input.LIGHT}
          value={editablePhone}
          className={styles.inputWrapper}
          onChange={(e, value) => {
            setEditablePhone(value);
          }}
          // TODO: add validation
        />
      </div>
      <div>
        <Button
          disabled={!editablePhone}
          onClick={() => props.onSubmitPhone(editablePhone!)}
          variant={'secondary'}
          className={styles.continueButton}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
