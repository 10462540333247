import classNames from 'classnames';
import { Checkbox } from 'components-antd';

import styles from './style.module.scss';

interface CheckboxFieldProps {
  className?: string;
  name?: string;
  label: string;
  value: boolean;
  onChange: (value) => void;
}

export const CheckboxField = ({ className, name, label, value, onChange }: CheckboxFieldProps) => {
  return (
    <Checkbox
      type="checkbox"
      name={name}
      className={classNames(styles.checkbox, 'mosaikCheckbox', className)}
      value={value}
      onChange={(e) => {
        onChange(e.target.checked);
      }}
      checked={value}
      disabled={false}
    >
      {label}
    </Checkbox>
  );
};
