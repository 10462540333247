import styles from './styles.module.scss';
import Icons from '../Icons';
import Menu from '../Menu';
import { MenuDots } from 'components/Icons';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function PropertyAvatar(props) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuIconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      menuIconRef.current &&
      !menuIconRef.current.contains(event.target)
    ) {
      setShowMenu(false);
    }
  };

  const sentenceCase = (sentence) => {
    const words = sentence.split(' ');
    const capitalizedWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return firstLetter + restOfWord;
    });

    return capitalizedWords.join(' ');
  };

  if (!props.empty)
    return (
      <div className={styles.avatar}>
        <div className={styles.photoDiv}>
          <div
            className={styles.photoDivIcon}
            ref={menuIconRef}
            tabIndex={props.index}
            onClick={(e) => {
              setShowMenu(!showMenu);
            }}
          >
            <MenuDots bgColor={'#FFF'} colorWithHash={'#262626'} iconClassName={''} />
          </div>
          {showMenu ? (
            <Menu
              menuRef={menuRef}
              setShowMenu={setShowMenu}
              removePropertyHandler={props.removePropertyHandler}
              propertyId={props.propertyId}
              currentPropertyCount={props.currentPropertyCount}
              commentOnProperty={props.commentOnProperty}
              shareProperty={props.shareProperty}
              setFavoriteStatus={props.setFavoriteStatus}
              favoriteStatus={props.favoriteStatus}
              favoriteFetchStatus={props.favoriteFetchStatus}
            />
          ) : (
            <></>
          )}
          {props.photo ? (
            <img
              className={classNames(styles.photo, styles['photo' + props.countClass])}
              src={props.photo}
              alt={props.address}
            />
          ) : (
            <div
              className={classNames(
                styles.photo,
                styles['photo' + props.countClass],
                styles.noPhoto,
              )}
            >
              <p className={styles.noPhotoText}>No&nbsp;</p>
              <p className={styles.noPhotoText}>Photos</p>
            </div>
          )}
          {props.photoCount ? (
            <div className={styles.countIcon}>
              <Icons iconName={'picCount'} />
              <p className={styles.count}>{props.photoCount}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <p className={styles.address + ' ' + styles['address' + props.countClass]}>
          {sentenceCase(props.address)}
        </p>
        <p className={styles.zip + ' ' + styles['zip' + props.countClass]}>
          {sentenceCase(props.city) + ', ' + props.state + ' ' + props.zip}
        </p>
      </div>
    );
  else
    return (
      <div className={styles.avatar}>
        <div className={styles.photoDiv}>
          <div
            className={
              styles.photo +
              ' ' +
              styles['photo' + props.countClass] +
              ' ' +
              styles.noPhoto +
              ' ' +
              styles.empty
            }
          ></div>
        </div>
      </div>
    );
}

PropertyAvatar.propTypes = {
  index: PropTypes.number,
  propertyId: PropTypes.string,
  photo: PropTypes.string,
  countClass: PropTypes.string,
  address: PropTypes.string,
  photoCount: PropTypes.number,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  removePropertyHandler: PropTypes.any,
  currentPropertyCount: PropTypes.number,
  commentOnProperty: PropTypes.any,
  shareProperty: PropTypes.any,
  setFavoriteStatus: PropTypes.any,
  favoriteStatus: PropTypes.bool,
  favoriteFetchStatus: PropTypes.string,
  empty: PropTypes.bool,
};

PropertyAvatar.defaultProps = {
  index: 0,
  propertyId: '',
  photo: '',
  countClass: '',
  address: '',
  photoCount: 0,
  city: '',
  state: '',
  zip: '',
  removePropertyHandler: () => {},
  currentPropertyCount: 0,
  commentOnProperty: () => {},
  shareProperty: () => {},
  setFavoriteStatus: () => {},
  favoriteStatus: false,
  favoriteFetchStatus: '',
  empty: false,
};

export default PropertyAvatar;
