import classNames from 'classnames';
import { useFormik } from 'formik';
import { FormFooter, FormHeader, FormContainer } from 'pages/OnboardingV2Agent/components';
import React from 'react';
import * as Yup from 'yup';
import styles from './styles.module.scss';
import { ListingIcon, RocketIcon, PartnerIcon } from '../icons';
import { Values } from 'pages/SignupV2/SignupV2';

type Plans = 'Solo' | 'Team' | 'Enterprise';

enum FormFields {
  Plan = 'selectedPlan',
}

type FormValues = {
  [key in FormFields]: Plans | '';
};

const schema = Yup.object().shape({
  [FormFields.Plan]: Yup.string().trim().required('Plan is required'),
});

type Props = {
  onNext: (values: Partial<Values>) => void;
  values: Values;
};

export const ChoosePlan: React.FC<Props> = ({ onNext, values }) => {
  const onSubmit = async (values: FormValues) => {
    onNext(values);
  };

  const formik = useFormik({
    initialValues: {
      [FormFields.Plan]: values[FormFields.Plan] || '',
    } as FormValues,
    validationSchema: schema,
    onSubmit: onSubmit,
  });

  return (
    <FormContainer>
      <div className={styles.wrapper}>
        <FormHeader
          title="Which plan would you like to move forward with?"
          subTitle="Already using Mosaik?"
          link="Sign In"
        />
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <div
            className={classNames(styles.planWrapper, {
              [styles.selected]: formik.values[FormFields.Plan] === 'Solo',
            })}
            onClick={() => formik.setFieldValue(FormFields.Plan, 'Solo')}
          >
            <div className={styles.iconWrapper}>
              <PartnerIcon />
            </div>
            <div className={styles.title}>Solo</div>
          </div>
          <div
            className={classNames(styles.planWrapper, {
              [styles.selected]: formik.values[FormFields.Plan] === 'Team',
            })}
            onClick={() => formik.setFieldValue(FormFields.Plan, 'Team')}
          >
            <div className={styles.iconWrapper}>
              <RocketIcon />
            </div>
            <div className={styles.title}>Team</div>
          </div>
          <div
            className={classNames(styles.planWrapper, {
              [styles.selected]: formik.values[FormFields.Plan] === 'Enterprise',
            })}
            onClick={() => formik.setFieldValue(FormFields.Plan, 'Enterprise')}
          >
            <div className={styles.iconWrapper}>
              <ListingIcon />
            </div>
            <div className={styles.title}>Enterprise</div>
          </div>
          <FormFooter
            isDisabled={formik.values.selectedPlan === ''}
            text={
              <>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.mosaik.io/pricing"
                  className={styles.link}
                >
                  See pricing details
                </a>
              </>
            }
          />
        </form>
      </div>
    </FormContainer>
  );
};
