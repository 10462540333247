import { createSelector } from 'reselect';
import { uniqBy, get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { transactionStatuses } from 'settings/constants/transaction';
import { getTransactionClosingDate } from 'utils';

export const localState = ({ projects }) => projects;

export const getStateAndDataFlag = createSelector(localState, ({ state }) => ({
  state,
  isIdle: state === IDLE,
  isPending: state === PENDING,
}));

export const getProjectCategoriesDataArray = createSelector(
  localState,
  ({ projectCategories }) => projectCategories || [],
);

export const getProjectsForMilestoneSelector = createSelector(localState, ({ projects }) => {
  const filteredProjects = projects?.filter(
    (transaction) =>
      ![transactionStatuses.Canceled, transactionStatuses.Closed].includes(transaction.Status),
  );

  const milestoneProjects = filteredProjects?.map((transaction) => {
    const keyDatesPrices =
      get(transaction, 'PropertyTransactionKeyDatesPrices') || get(transaction, 'ProjectKeyDates');
    const ClosingDate = getTransactionClosingDate(keyDatesPrices, transaction?.IsProject);
    return {
      Id: transaction.Id,
      label: transaction.Name,
      Milestones: transaction.Milestones,
      CreatedDate: transaction.CreatedDate,
      Participants: transaction.Participants,
      ClosingDate,
    };
  });
  return uniqBy(milestoneProjects, 'Id');
});

export const getProjectsForTaskCreationSelector = createSelector(localState, ({ projects }) => {
  const filteredProjects = projects?.filter(
    (transaction) =>
      ![transactionStatuses.Canceled, transactionStatuses.Closed].includes(transaction.Status),
  );

  const projectsData = filteredProjects?.map((transaction) => ({
    Id: transaction.Id,
    label: transaction.Name,
  }));
  return uniqBy(projectsData, 'Id');
});

export const getProjectsClientsSelector = createSelector(localState, ({ projects }) => {
  const uniqueClientsData = [];
  projects.forEach((project) => {
    if (project && project?.TransactionClients) {
      project.TransactionClients.forEach((name) => {
        if (name && !uniqueClientsData.includes(name)) {
          uniqueClientsData.push(name);
        }
      });
    }
  });
  return uniqueClientsData;
});

export const getProjectsSelector = createSelector(localState, ({ projects }) => projects || []);
