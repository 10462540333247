import { apiServer } from 'settings/web-services/api';

export function putTaskCategoryData(cfg, { id }) {
  return apiServer.put(`/transaction/templates/task-categories/${id}`, cfg);
}

export function deleteDeactivateTaskCategory({ id }) {
  return apiServer.delete(`/transaction/templates/task-categories/${id}`);
}

export function postTaskCategoryData(cfg) {
  return apiServer.post('/transaction/templates/task-categories', cfg);
}

export function putActivateTaskCategory({ id }) {
  return apiServer.put(`/transaction/templates/task-categories/${id}`, {
    IsActive: true,
  });
}
