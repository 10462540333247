import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Tabs } from 'components';
import AllTab from './components/AllTab';
import MyRecomendationsTab from './components/MyRecomendationsTab';
import { OpenFolderIcon, StarIcon } from './icons';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { updateConciergeSelectedTab } from 'store/actions/concierge';

import styles from './styles.module.scss';

const AgentPartnersList = ({ title }) => {
  const dispatch = useDispatch();
  const { selectedTab } = useSelector(getConciergeSearchSelector);

  const setTab = (tabIndex) => {
    dispatch(updateConciergeSelectedTab(tabIndex));
  };

  const tabs = useMemo(
    () => [
      {
        id: 'recomendations',
        label: 'My Recommendations',
        Icon: <StarIcon />,
        Component: MyRecomendationsTab,
        testid: 'recommendations',
      },
      { id: 'all', label: `Directory`, Icon: <OpenFolderIcon />, Component: AllTab, testid: 'all' },
    ],
    [title],
  );

  return (
    <div className={styles.wrapper}>
      <Tabs
        tabsClassName={styles.messagesItemTabs}
        tabClassName={styles.messagesItemTab}
        activeTabClassName={styles.messagesActiveItemTab}
        tabs={tabs}
        contentClassName={styles.agentPartnerTab}
        categoryTitle={title}
        activeTabIndex={selectedTab}
        onChange={setTab}
      />
    </div>
  );
};

AgentPartnersList.propTypes = {
  title: PropTypes.string,
};

AgentPartnersList.defaultProps = {
  title: null,
};

export default AgentPartnersList;
