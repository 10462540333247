import React, { useCallback, useEffect, useMemo } from 'react';

import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'components-antd';
import { appLogoutEffect } from 'store/effects';
import classNames from 'classnames';

import { getInActiveClientContextsStateSelector } from 'store/selectors/contexts';
import { getInActiveClientContextsEffect } from 'store/effects/context';
import { READY } from 'settings/constants/apiState';

export const PaywallForClient: React.FC<{ allowLeave?: boolean }> = (props) => {
  const { allowLeave = false } = props;
  const contextsState = useSelector(getInActiveClientContextsStateSelector);
  const dispatch = useDispatch();

  const fetchContexts = useCallback(() => dispatch(getInActiveClientContextsEffect()), [dispatch]);
  const agentName = useMemo(() => {
    const fName = contextsState?.data?.[0]?.Agents?.[0]?.FirstName;
    const lName = contextsState?.data?.[0]?.Agents?.[0]?.LastName;
    return `${fName} ${lName ?? ''}`;
  }, [contextsState]);

  useEffect(() => {
    fetchContexts();
  }, []);

  if (contextsState.state !== READY) {
    return null;
  }

  return (
    <div className={styles.paywallBackground} id="paywallModalContainer">
      <Modal
        getContainer={'#paywallModalContainer'}
        open={true}
        width={675}
        footer={null}
        closable={false}
        maskClosable={false}
        mask={false}
      >
        <div className={styles.modalLayout}>
          <h2 className={styles.stepHeader}>Welcome to Mosaik!</h2>
          <div className={styles.errorSection}>
            <span className={styles.errorSection__boldText}>
              There&apos;s an issue with your account.
            </span>
            <br />
            Please contact your agent {agentName} for more information.
          </div>
          {allowLeave && (
            <Button
              onClick={() => {
                dispatch(appLogoutEffect());
              }}
              variant="secondary"
              className={classNames(styles.continueButton, styles.button)}
            >
              Leave
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};
