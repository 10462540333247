import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map } from 'lodash-es';

import SearchBlockActions from '../SearchBlockActions';

import styles from './styles.module.scss';

const SearchLine = (props) => {
  const { title, info, className, popupPosition, onDelete, onSearch, onEdit, onInactive, photos } =
    props;

  const deleteHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onDelete();
    },
    [onDelete],
  );

  const editHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onEdit();
    },
    [onEdit],
  );

  const searchHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onSearch();
    },
    [onSearch],
  );

  const inactiveHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onInactive();
    },
    [onInactive],
  );

  return (
    <div testid="search_block" className={classNames(styles.container, className)}>
      <div className={styles.images}>
        {photos?.length ? (
          map(photos, (link, index) => (
            <img key={index} alt="Saved search preview" src={`${link}?width=24`} />
          ))
        ) : (
          <div className={styles.noImages}>?</div>
        )}
      </div>
      <div className={styles.infoBlock}>
        <div testid="search_title" className={styles.descTitle}>
          {title || '-'}
        </div>
        <div testid="search_info" className={styles.descInfo}>
          {info || '-'}
        </div>
      </div>
      <div className={styles.actions}>
        <SearchBlockActions
          popupPosition={popupPosition}
          onDelete={deleteHandler}
          onSearch={searchHandler}
          onEdit={editHandler}
          onInactive={inactiveHandler}
        />
      </div>
    </div>
  );
};

SearchLine.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  info: PropTypes.string,
  className: PropTypes.string,
  popupPosition: PropTypes.string,
  onDelete: PropTypes.func,
  onSearch: PropTypes.func,
  onEdit: PropTypes.func,
};

SearchLine.defaultProps = {
  className: '',
  title: '',
  info: '',
  popupPosition: 'top',
  onDelete: () => {},
  onSearch: () => {},
  onEdit: () => {},
};

export default SearchLine;
