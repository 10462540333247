import { get } from 'lodash-es';

import { quoteRequestStatus } from 'settings/constants/quotes';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { getPropertyAddress } from 'helpers';
import { createSelector } from 'reselect';

const localState = ({ requestQuote }) => requestQuote;

export const getRequestQuoteSelector = createSelector(localState, (requestQuote) => ({
  isIdle: requestQuote.state === IDLE,
  isPending: requestQuote.state === PENDING,
  isData: !!requestQuote.data,
  isDraft: requestQuote.data?.Status === quoteRequestStatus.Draft,
  quoteData: requestQuote.data,
  quoteForm: {
    state: requestQuote.quoteForm.state,
  },
}));

export const getPreFormSelector = createSelector(
  localState,
  (requestQuote) => requestQuote.preForm,
);

export const getQuoteFormSelector = createSelector(
  localState,
  (requestQuote) => requestQuote.quoteForm,
);

export const getRequestQuoteSummarySelector = createSelector(localState, (requestQuote) => {
  const client = get(requestQuote?.data, 'Client');
  const address = get(requestQuote?.data, 'Property.Address');
  const responses = get(requestQuote?.quoteForm, 'FormResponses');

  return {
    location: address ? getPropertyAddress(address) : undefined,
    client: client
      ? {
          id: client?.Id,
          name: `${client?.FirstName} ${client?.LastName}`,
        }
      : undefined,
    responses,
  };
});

export const getRequestMultipleQuotesModeSelector = createSelector(
  localState,
  (requestQuote) => requestQuote.multiple,
);
