import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface ButtonsContainerProps {
  buttonsContainerClassName?: string;
  children: React.ReactNode;
}

export const ButtonsContainer = ({
  buttonsContainerClassName,
  children,
}: ButtonsContainerProps) => {
  return (
    <div className={classNames(styles.buttonsContainer, buttonsContainerClassName)}>{children}</div>
  );
};
