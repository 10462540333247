import { handleActions } from 'redux-actions';

import { resetPropertyToScrollInList, setPropertyToScrollInList } from 'store/actions/feed';

const initialData = {
  propertyId: '',
};

export default handleActions(
  {
    [setPropertyToScrollInList]: (state, { payload }) => ({ propertyId: payload.id }),
    [resetPropertyToScrollInList]: (state, { payload }) => initialData,
  },
  initialData,
);
