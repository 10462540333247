import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BuyerLine from '../../BuyerLine';

import styles from './styles.module.scss';

const Buyers = (props) => {
  const { className, list, onClick, buyerLineClassName } = props;

  if (!list) return null;

  return (
    <div className={classNames(styles.wrapper, className)}>
      {list.map(({ id, name, info, criterias }) => (
        <BuyerLine
          key={id}
          name={name}
          info={info}
          onClick={() => onClick(criterias)}
          className={buyerLineClassName}
        />
      ))}
    </div>
  );
};

Buyers.propTypes = {
  className: PropTypes.string,
  buyerLineClassName: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
};

Buyers.defaultProps = {
  className: '',
  buyerLineClassName: '',
  list: [],
  onClick: () => {},
};

export default Buyers;
