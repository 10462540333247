import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { getPreFormSelector, getRequestQuoteSelector } from 'store/selectors/requestQuote';
import { setPreFormQuestionsEffect, updateQuoteDraftEffect } from 'store/effects/quotes';
import { preFormQuestionsIds } from 'settings/constants/preForm';
import { YesNoQuestion } from 'pages/FormBuilder/components';
import { Question } from 'pages/RequestQuote/components';
import { FORM_MODE } from 'app-constants';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';

import styles from './styles.module.scss';

interface IsClientProps {
  className: string;
  onAnswer: (answer: boolean) => {};
  onContinue: () => void;
}

const IsClient = ({ className, onAnswer, onContinue }: IsClientProps) => {
  const dispatch = useDispatch();
  const preForm = useSelector(getPreFormSelector);
  const { isDraft } = useSelector(getRequestQuoteSelector);

  const [answer, setAnswer] = useState<boolean | undefined>(
    preForm?.[preFormQuestionsIds.isClient],
  );

  const onAnswerHandler = () => {
    if (typeof answer === 'undefined') return;
    dispatch(
      setPreFormQuestionsEffect({
        [preFormQuestionsIds.isClient]: answer,
        ...(!answer ? { [preFormQuestionsIds.client]: undefined } : {}),
      }),
    );

    if (isDraft) {
      dispatch(
        updateQuoteDraftEffect({}, { silent: true, isNegativeAnswer: !answer }, (err) => {
          if (!err) {
            onAnswer(answer);
          }
        }),
      );
    } else {
      onAnswer(answer);
    }
  };

  return (
    <div testid="is_client" className={classNames(styles.isClient, className)}>
      <Question>Is this quote for a client?</Question>
      <AnswersContainer>
        <YesNoQuestion mode={FORM_MODE.View} inputState={answer} setInputState={setAnswer} />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue disabled={typeof answer === 'undefined'} onClick={onAnswerHandler} />
      </ButtonsContainer>
    </div>
  );
};

export default IsClient;
