import { SVGProps } from 'react';

export const TickFileIcon = ({
  stroke = '#ff576d',
  height = 32,
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}`}
    height={`${height}`}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M11.666 2.5V5.83333C11.666 6.05435 11.7538 6.26631 11.9101 6.42259C12.0664 6.57887 12.2783 6.66667 12.4993 6.66667H15.8327"
      stroke="#515151"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.166 17.5H5.83268C5.39065 17.5 4.96673 17.3244 4.65417 17.0118C4.34161 16.6993 4.16602 16.2754 4.16602 15.8333V4.16667C4.16602 3.72464 4.34161 3.30072 4.65417 2.98816C4.96673 2.67559 5.39065 2.5 5.83268 2.5H11.666L15.8327 6.66667V15.8333C15.8327 16.2754 15.6571 16.6993 15.3445 17.0118C15.032 17.3244 14.608 17.5 14.166 17.5Z"
      stroke="#515151"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 11.5L9.5 13L12.5 10"
      stroke="#515151"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
