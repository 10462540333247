import moment from 'moment';
import { TableColumnsType } from 'pages/Dashboard/components/Table';

export type BillingHistory = {
  Plan: string;
  Amount: string;
  Date: string;
  Card: string;
};

export const columns: TableColumnsType<BillingHistory> = [
  {
    title: 'Reason',
    dataIndex: 'Reason',
    withSort: false,
  },
  {
    title: 'Amount',
    dataIndex: 'Amount',
    withSort: false,
    render: (v) => v || '---',
  },
  {
    title: 'Date',
    dataIndex: 'Date',
    withSort: false,
    render: (v) => (
      <div style={{ minWidth: '200px' }}>{moment(v).format('MMM DD, YYYY') || '---'}</div>
    ),
  },
];
