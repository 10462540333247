import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Images from './Images';
import Files from './Files';
import styles from './styles.module.scss';

const AttachmentsPreview = (props) => {
  const { className, attachments, isOwnMessage, isMessageSavedOnServer } = props;

  if (!attachments?.length) return null;

  return (
    <div className={classNames(styles.attachmentsPreview, className)}>
      <Images
        imageClassName={styles.attachmentOuter}
        attachments={attachments}
        isMessageSavedOnServer={isMessageSavedOnServer}
        isOwnMessage={isOwnMessage}
      />
      <Files
        fileClassName={styles.attachmentOuter}
        attachments={attachments}
        isMessageSavedOnServer={isMessageSavedOnServer}
        isOwnMessage={isOwnMessage}
      />
    </div>
  );
};

AttachmentsPreview.propTypes = {
  className: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  isOwnMessage: PropTypes.bool,
  isMessageSavedOnServer: PropTypes.bool,
};

AttachmentsPreview.defaultProps = {
  className: '',
  attachments: [],
  isOwnMessage: false,
  isMessageSavedOnServer: false,
};

export default AttachmentsPreview;
