import { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import { PriceHighlighter } from './../../..';
import { Row, Col, Button } from 'components-antd';
import { Input } from 'components';
import { Icons } from 'pages/Workshop/Icons';
import { ErrorMessage } from '../../.././Error/ErrorMessage';

import { convertToDecimalIfNotWhole } from 'helpers';
import { updateFinancialCommission } from 'api/financials';

import styles from './styles.module.scss';

type CommissionFieldFamily = {
  label: string;
  rate: number;
  gci: number;
  notEditable?: boolean;
  fieldKey?: string;
  setEditField?: Function;
  isDisableField: Function;
  calculateGCI?: Function;
  calculateRate?: Function;
  fetchFinancials?: Function;
  gciOther?: number;
  transactionPrice?: number;
  isFeeTypePercentage?: boolean;
  hidePriceHighlighter?: boolean;
};

export const CommissionUnit = ({
  label,
  rate,
  gci,
  notEditable = false,
  fieldKey,
  setEditField,
  isDisableField,
  calculateGCI,
  calculateRate,
  fetchFinancials,
  gciOther,
  transactionPrice = 0,
  isFeeTypePercentage = false,
  hidePriceHighlighter = false,
}: CommissionFieldFamily) => {
  const [isEdit, setIsEdit] = useState(false);
  const [sellerCommissionEdit, setSellerCommissionEdit] = useState({});
  const [buyerCommissionEdit, setBuyerCommissionEdit] = useState({});
  const [isValidate, setValidate] = useState(false);
  const [isUpdateButtonDisable, setIsUpdateButtonDisable] = useState(false);
  const [isUpdateCommissionLoading, setUpdateCommissionLoading] = useState(false);
  const [ratePercentage, setRate] = useState<number | null>(null);
  const [gciValue, setGCIValue] = useState<number | null>(null);
  const [focusedField, setFocusedField] = useState<'gci' | 'rate' | null>(null);

  const handleRateFocus = () => setFocusedField('rate');
  const handleGCIFocus = () => setFocusedField('gci');
  const handleBlur = () => setFocusedField(null);

  const params: { id?: string } = useParams();

  useEffect(() => {
    setRate(rate);
    setGCIValue(gci);
  }, [rate, gci]);

  const onEdit = () => {
    setEditField && setEditField(fieldKey);
    setIsEdit(true);
  };

  const onCancel = () => {
    setEditField && setEditField(null);
    setRate(rate);
    setGCIValue(gci);
    setIsEdit(false);
    setValidate(false);
  };

  const handleRateChange = (e) => {
    const newRate = e.target.value;
    setRate(newRate);

    // Only calculate GCI if the GCI field is not focused
    if (focusedField !== 'gci') {
      let gciResult = calculateGCI && calculateGCI(newRate);
      setGCIValue(convertToDecimalIfNotWhole(gciResult));
      if (label === 'Buy Side') {
        setBuyerCommissionEdit({
          BuyerCommission: {
            Value: parseFloat(newRate) || 0,
            IsFeeTypePercentage: true,
          },
        });
      } else {
        setSellerCommissionEdit({
          SellerCommission: {
            Value: parseFloat(newRate) || 0,
            IsFeeTypePercentage: true,
          },
        });
      }
    }

    let _result = isPriceWithinRange(calculateGCI ? calculateGCI(newRate) : gciValue);
    if (!_result || newRate?.length === 0) {
      setIsUpdateButtonDisable(true);
    } else {
      setIsUpdateButtonDisable(false);
    }
  };

  const handleGCIChange = (e) => {
    const newGCI = e.target.value;
    setGCIValue(newGCI);

    // Only calculate the rate if the Rate field is not focused
    if (focusedField !== 'rate') {
      let rateResult = calculateRate && calculateRate(newGCI);
      setRate(rateResult);
      if (label === 'Buy Side') {
        setBuyerCommissionEdit({
          BuyerCommission: {
            Value: parseFloat(newGCI) || 0,
            IsFeeTypePercentage: false,
          },
        });
      } else {
        setSellerCommissionEdit({
          SellerCommission: {
            Value: parseFloat(newGCI) || 0,
            IsFeeTypePercentage: false,
          },
        });
      }
    }

    let _result = isPriceWithinRange(newGCI);
    if (!_result || newGCI?.length === 0) {
      setIsUpdateButtonDisable(true);
    } else {
      setIsUpdateButtonDisable(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setUpdateCommissionLoading(true);
      await updateFinancialCommission({
        transactionId: params?.id,
        data: label === 'Buy Side' ? buyerCommissionEdit : sellerCommissionEdit,
      });
      if (fetchFinancials) fetchFinancials();
    } catch (err) {
      setUpdateCommissionLoading(false);
    } finally {
      setUpdateCommissionLoading(false);
      setEditField && setEditField(null);
      setIsEdit(false);
    }
  };

  const isPriceWithinRange = (enteredGCI) => {
    let _otherGCI = gciOther ?? 0;
    let compareToBeAmount = +enteredGCI + +_otherGCI;

    if (compareToBeAmount === transactionPrice || compareToBeAmount < transactionPrice) {
      setValidate(false);
      setIsUpdateButtonDisable(false);
      return true;
    } else {
      setValidate(true);
      setIsUpdateButtonDisable(true);
      return false;
    }
  };

  return (
    <Row
      gutter={12}
      className={classNames(
        styles.commissionRow,
        { [styles.commissionRowEdit]: !isEdit && !notEditable && !isDisableField(fieldKey) },
        { [styles.disabled]: isDisableField(fieldKey) },
      )}
    >
      {!isEdit ? (
        <Fragment>
          <Col lg={14}>
            <span className={styles.value}>{label}</span>
          </Col>
          <Col lg={4}>
            <span className={styles.value}>
              {rate === null || isNaN(rate) ? (
                '-'
              ) : (
                <>
                  {convertToDecimalIfNotWhole(ratePercentage)}%
                  <PriceHighlighter isShow={isFeeTypePercentage && !hidePriceHighlighter} />
                </>
              )}
            </span>
          </Col>
          <Col lg={6}>
            {gci === null || isNaN(gci) ? (
              <span className={styles.value}>{'-'}</span>
            ) : (
              <NumberFormat
                thousandSeparator
                displayType="text"
                value={convertToDecimalIfNotWhole(gciValue)}
                prefix="$"
                renderText={(val) => (
                  <span className={styles.value}>
                    {val}
                    <PriceHighlighter isShow={!isFeeTypePercentage && !hidePriceHighlighter} />
                  </span>
                )}
              />
            )}
          </Col>
          <Icons variant={Icons.EDIT_ROUND} className={styles.editIcon} onClick={onEdit} />
        </Fragment>
      ) : (
        <Fragment>
          <Col lg={24}>
            <p className={styles.boldLabel}>{label}</p>
          </Col>
          <Col lg={12}>
            <Input
              label="Rate"
              isNumberFormat
              value={ratePercentage || ''}
              variant={Input.LIGHT_ROUND}
              maxNumber={100}
              onFocus={handleRateFocus}
              onBlur={handleBlur}
              onChange={handleRateChange}
              suffix="%"
            />
          </Col>
          <Col lg={12}>
            <Input
              label="GCI"
              isNumberFormat
              value={gciValue || ''}
              variant={Input.LIGHT_ROUND}
              onFocus={handleGCIFocus}
              onBlur={handleBlur}
              onChange={handleGCIChange}
              prefix="$"
            />
            {isValidate && (
              <ErrorMessage message={'Total commission can never be greater than price'} />
            )}
          </Col>
          <Col lg={12}>
            <div className={styles.buttonsContainer}>
              <Button
                variant="secondary"
                size="large"
                onClick={handleUpdate}
                disabled={isUpdateButtonDisable}
                loading={isUpdateCommissionLoading}
              >
                Update
              </Button>
              <Button variant="hollow-bordered" size="large" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Col>
        </Fragment>
      )}
    </Row>
  );
};
