import { Input, Locations } from 'components';
import { FormFooter, FormHeader, FormContainer } from 'pages/OnboardingV2Agent/components';
import React, { useState } from 'react';

import parentStyles from 'pages/SignupV2/components/PersonalDetails/styles.module.scss';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { ValidationSchema } from 'pages/OnBoarding/components/Forms/agent/BrokerDetailsForm/validation';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDataSelector } from 'store/selectors/user';
import { onBoardingSignUpEffect } from 'store/effects';
import { getLocationContext, getPlaceComponent } from 'helpers';
import { LOCALITY, POSTAL_CODE, ROUTE, STATE, STREET_NUMBER } from 'settings/constants/locations';

import styles from './styles.module.scss';
import { BackIcon } from 'pages/SignupV2/components/icons';

enum FormFields {
  BROKERAGE_NAME = 'brokerageName',
  ADDRESS = 'address',
  BROKERAGE_ADDRESS = 'brokerageAddress',
  SUITE_UNIT = 'suiteUnit',
}

type FormValues = {
  [FormFields.ADDRESS]: any;
  [FormFields.BROKERAGE_NAME]: string;
  [FormFields.SUITE_UNIT]: string;
  [FormFields.BROKERAGE_ADDRESS]: any;
};

const initialValues = {
  [FormFields.BROKERAGE_NAME]: '',
  [FormFields.ADDRESS]: null,
  [FormFields.SUITE_UNIT]: '',
  [FormFields.BROKERAGE_ADDRESS]: undefined,
} as FormValues;

type Props = {
  onNext: () => void;
  onPrev: () => void;
};

export const Brokerage: React.FC<Props> = ({ onNext, onPrev }) => {
  const [isPending, setIsPending] = useState<boolean>(false);
  const user = useSelector(getUserDataSelector);
  const dispatch = useDispatch();

  const onSubmit = (values: FormValues) => {
    setIsPending(true);
    const { address, ...rest } = values;
    dispatch(
      onBoardingSignUpEffect({ ...rest, stageIndex: 3 }, {}, (err) => {
        setIsPending(false);
        if (!err) {
          onNext();
        }
      }),
    );
  };
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      [FormFields.BROKERAGE_NAME]: user?.Agent?.Brokerage?.Name || initialValues?.brokerageName,
      [FormFields.BROKERAGE_ADDRESS]: user?.Agent?.Brokerage?.Address?.Line1
        ? ({
            line1: user.Agent.Brokerage.Address.Line1,
            city: user.Agent.Brokerage.Address.City,
            state: user.Agent.Brokerage.Address.State,
            zip: user.Agent.Brokerage.Address.Zip,
            ProviderPlaceId: user.Agent.Brokerage.Address.Line1,
            PlaceName: user.Agent.Brokerage.Address.Line1,
          } as any)
        : initialValues?.brokerageAddress,
      // [FormFields.SUITE_UNIT]: user?.Agent?.Brokerage?.Address?.Line2 || initialValues?.suiteUnit,
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });

  const onOfficeAddressChange = (result) => {
    const location = result?.result?.[0];

    if (location) {
      const city = getLocationContext(location, LOCALITY);
      const state = getLocationContext(location, STATE);
      const zip = getLocationContext(location, POSTAL_CODE);
      const street = getPlaceComponent(location, ROUTE);
      const streetNumber = getLocationContext(location, STREET_NUMBER);

      formik.setFieldValue(FormFields.BROKERAGE_ADDRESS, {
        city,
        state,
        zip,
        line1: [streetNumber, street?.name].filter((i) => !!i).join(' '),
        ProviderPlaceId: location.place_id,
        PlaceName: result?.placeName,
      });
    } else {
      formik.setFieldValue(FormFields.BROKERAGE_ADDRESS, null);
    }
  };
  return (
    <>
      <div className={styles.backBtn} onClick={onPrev}>
        <BackIcon />
      </div>
      <FormContainer>
        <div className={classNames(parentStyles.formContainer)}>
          <FormHeader title="What brokerage are you with?" />
          <form className={parentStyles.form} onSubmit={formik.handleSubmit}>
            <div className={parentStyles.inputContainer}>
              <Input
                type="text"
                name={FormFields.BROKERAGE_NAME}
                value={formik.values[FormFields.BROKERAGE_NAME]}
                onChange={formik.handleChange}
                className={parentStyles.input}
                error={
                  formik.touched[FormFields.BROKERAGE_NAME]
                    ? formik.errors[FormFields.BROKERAGE_NAME]
                    : ''
                }
                errorClassName={parentStyles.error}
                disabled={isPending}
                variant={Input.LIGHT_ROUND}
                label={'Brokerage Name'}
              />
            </div>
            <div>
              <div className={styles.heading}>Address (optional)</div>
              <div className={classNames(styles.wrapper)}>
                <Locations
                  multiple
                  variant="light"
                  placeholder={''}
                  onResult={onOfficeAddressChange}
                  value={
                    formik.values[FormFields.BROKERAGE_ADDRESS]
                      ? [formik.values[FormFields.BROKERAGE_ADDRESS]]
                      : []
                  }
                  error={
                    formik.touched[FormFields.BROKERAGE_ADDRESS]
                      ? (formik.errors[FormFields.BROKERAGE_ADDRESS] as string)
                      : ''
                  }
                  activeInputClassName={styles.activeInputClassName}
                  valuesWrapperClassName={styles.valuesWrapperClassName}
                  searchIcon={false}
                  valueClassName={styles.valueServiceLocation}
                  placeholderClassName={styles.placeholderClassName}
                  dropdownClassName={styles.dropdownClassName}
                  disabled={false}
                  valuesContainerClassName={styles.valuesContainer}
                  loaderClassName={styles.loader}
                />
              </div>
            </div>
            <FormFooter
              isPending={isPending}
              isDisabled={formik.values[FormFields.BROKERAGE_NAME] === ''}
            />
          </form>
        </div>
      </FormContainer>
    </>
  );
};
