import { useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { setSharePropertyEffect, openShareDrawerEffect, setRecipientsEffect } from 'store/effects';

import { Tooltip } from 'components-antd';
import { Icons } from '../../../common/Icons/Icons';

import styles from './styles.module.scss';

export const RecommendedTo = ({ propertyInfo, idx }) => {
  const dispatch = useDispatch();
  const isRecommended = useMemo(
    () => propertyInfo?.RecommendedTo?.length,
    [propertyInfo?.RecommendedTo],
  );

  const TooltipContent = () => (
    <div style={{ padding: 2 }}>
      <p style={{ marginBottom: 5, fontSize: '12px' }}>
        <b>Highlighted to:</b>
      </p>
      {propertyInfo?.RecommendedTo?.map((item, idx) => (
        <p key={idx} style={{ marginBottom: 2 }}>
          {item?.FirstName} {item?.LastName}
        </p>
      ))}
    </div>
  );

  const onOpenShareDrawer = (e) => {
    e.stopPropagation();
    const recipients = propertyInfo?.RecommendedTo?.map((item) => ({
      Id: item.Id,
      Name: `${item.FirstName} ${item.LastName}`,
    }));

    dispatch(setRecipientsEffect(recipients ?? []));
    dispatch(setSharePropertyEffect(propertyInfo));
    dispatch(openShareDrawerEffect({ open: true }));
  };

  return (
    <div className={classNames(styles.recommendedTo, { [styles.hoverEffect]: isRecommended })}>
      {isRecommended ? (
        <Tooltip
          title={<TooltipContent />}
          placement={idx % 2 !== 0 ? 'top' : 'topLeft'}
          color="white"
          overlayClassName={styles.recommendedToTooltip}
        >
          <button className={styles.tooltipButton} onClick={onOpenShareDrawer}>
            <Icons variant={Icons.STAR_2} className={styles.recommended} />
          </button>
        </Tooltip>
      ) : (
        <Icons variant={Icons.STAR_2} onClick={onOpenShareDrawer} />
      )}
    </div>
  );
};
