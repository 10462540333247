import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { getTransactionTaskSelector } from 'store/selectors/transactionTask';
import { AssignItem, AssignTitle } from '../index';

import styles from './styles.module.scss';

const AssignedBy = (props) => {
  const { className } = props;
  const { task } = useSelector(getTransactionTaskSelector);

  const getName = () => {
    const { Assignor } = task || {};
    return `${Assignor?.FirstName ?? ''} ${Assignor?.LastName ?? ''}`;
  };

  return (
    <div testid="assigned_by" className={classNames(styles.assignedBy, className)}>
      <AssignTitle className={styles.title}>Assigned by:</AssignTitle>
      <AssignItem name={getName()} listItems={[task?.Assignor]} />
    </div>
  );
};

AssignedBy.propTypes = {
  className: PropTypes.string,
};

AssignedBy.defaultProps = {
  className: '',
};

export default AssignedBy;
