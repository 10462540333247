import classNames from 'classnames';
import { Wrapper as PendingWrapper } from 'components';
import { MosaikLogo } from 'components/Icons';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { mapRoles, Role, signUpRouteRoles } from 'settings/constants/roles';
import { link } from 'settings/navigation/link';
import { setVerifiedEmailFlagAction } from 'store/actions/user';
import { verifyEmailEffect } from 'store/effects';
import { getUserDataSelector } from 'store/selectors/user';
import Error from './Error';
import styles from './styles.module.scss';
import Success from './Success';
import { EmailVerificationSuccessModal } from './Success/EmailVerificationSuccessModal';
import { routes } from 'settings/navigation/routes';
import { LOCAL_STORAGE_KEY } from '../constants';

const EmailVerificationResult = (props) => {
  const { className } = props;
  const user = useSelector(getUserDataSelector);
  const selfSignup = typeof user?.Agent?.WizardFinished === 'boolean';
  console.log('🚀 ~ EmailVerificationResult ~ selfSignup:', selfSignup);
  const navigateToDashboardIfSelfSignUp = user?.Agent?.WizardFinished === true;
  const isEmailVerified = user?.IsEmailVerified;

  const params = useParams<{ token: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState<boolean | null>(null);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleRedirect = () => {
    if (selfSignup) {
      if (navigateToDashboardIfSelfSignUp) {
        history.push(routes.index);
      } else {
        dispatch(push(link.toOnBoardingV2Agent()));
      }
    } else {
      dispatch(push(link.toOnBoarding(signUpRouteRoles.AGENT)));
    }
  };

  useEffect(() => {
    dispatch(
      verifyEmailEffect({ token: params?.token }, {}, (err) => {
        dispatch(setVerifiedEmailFlagAction());

        setSuccess(!err);

        // Check if component who requested verification requires showing success modal instead of page
        const afterVerificationStateJson = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        if (afterVerificationStateJson) {
          const afterVerificationState = JSON.parse(afterVerificationStateJson);

          if (afterVerificationState.showSuccessModal && !err) {
            history.push(routes.index);
            setShowSuccessModal(true);
            return;
          }
        }

        if (!err) {
          handleRedirect();
          return;
        } else {
          return;
        }
        // setShowSuccessPage(true);
      }),
    );
  }, []);

  return (
    <div className={classNames(styles.verifyEmail, className)}>
      <header className={styles.header}>
        <MosaikLogo className={styles.logo} />
      </header>
      <PendingWrapper className={styles.pendingWrapper} isPending={success === null}>
        {success ? showSuccessPage && <Success /> : <Error handleRedirect={handleRedirect} />}
        <EmailVerificationSuccessModal
          isOpen={showSuccessModal}
          onClose={() => {
            setShowSuccessModal(false);
          }}
        />
      </PendingWrapper>
    </div>
  );
};

EmailVerificationResult.propTypes = {
  className: PropTypes.string,
};

EmailVerificationResult.defaultProps = {
  className: '',
};

export default EmailVerificationResult;
