import { apiServer } from 'settings/web-services/api';
import {
  GET_OFFER_DETAILS_BY_UUID,
  GET_OFFER_ITERATION_DETAILS_BY_ID,
  GET_OFFER_LINK_DETAILS_BY_UUID,
  SEND_VERIFICATION_LINK_FOR_OFFER_PORTAL,
} from '../../app-constants/endpoints';

export function createUpdateOffer(cfg) {
  return apiServer.post('/offer/create-link', cfg);
}

export function submitOffer(cfg) {
  return apiServer.post('/offer/submit', cfg);
}

export const getOfferLinkDetailsByUUID = ({ UUID, ...cfg }) => {
  return apiServer.get(GET_OFFER_LINK_DETAILS_BY_UUID(UUID), cfg);
};

export const getOfferDetailsByUUID = ({ UUID }) => {
  return apiServer.get(GET_OFFER_DETAILS_BY_UUID(UUID));
};

export const getOfferIterationDetailsById = ({ Id, ...cfg }) =>
  apiServer.get(GET_OFFER_ITERATION_DETAILS_BY_ID(Id), cfg);

export function getOfferParticipantsList(transactionId) {
  return apiServer.get(`/offer/participant-list/${transactionId}`);
}

export function getOfferSummary(transactionId) {
  return apiServer.get(`/offer/summary/${transactionId}`);
}

export function getFilteredOffers(query) {
  return apiServer.get(`/offer/filtered?${query}`);
}

export function deleteOffer(cfg) {
  return apiServer.delete(`/offer/${cfg.Id}`);
}

export function listOffer({ transactionId }) {
  return apiServer.get(`/offer/list/${transactionId}`);
}

export function sendAnnouncement(cfg) {
  return apiServer.post(`/announcement`, cfg);
}

export function getOfferDetailsForExternalUser(uuid, { externalToken }: ExternalTokenOptions) {
  return apiServer.get(`/offer/external/offer-details/${uuid}`, {
    headers: {
      'X-External-Token': externalToken ?? '',
    },
  });
}

export function updateExternalOfferIterationStatus(
  uuid: string,
  cfg,
  { externalToken }: ExternalTokenOptions,
) {
  return apiServer.put(`/offer/external/iteration-status/${uuid}`, cfg, {
    headers: {
      'X-External-Token': externalToken ?? '',
    },
  });
}

export function updateOfferIteration(cfg) {
  return apiServer.put(`/offer/iteration-status`, cfg);
}

export function getCompareOffers({ transactionId }) {
  return apiServer.get(`/offer/transaction-offers/${transactionId}`);
}

export const offerDetails = ({ UUID, ...cfg }) => {
  return apiServer.get(`/offer/${UUID}`, cfg);
};

export const getAuthTokenForExternalUser = (cfg) => {
  return apiServer.post(`/offer/external/auth-token`, cfg);
};

export function sendCounterOffer({ UUID, ...cfg }) {
  return apiServer.post(`/offer/counter-offer/${UUID}`, cfg);
}

export function getTransactionAcceptedOffer({ transactionId }) {
  return apiServer.get(`/offer/accepted-offer/${transactionId}`);
}
export const sendCounterForExternalUser = (token, cfg, { externalToken }: ExternalTokenOptions) => {
  return apiServer.post(`/offer/external/counter-offer/${token}`, cfg, {
    headers: {
      'X-External-Token': externalToken ?? '',
    },
  });
};

export const setOfferIterationViewedStatusForExternalUser = (
  iterationUuid: string,
  { externalToken }: ExternalTokenOptions,
) => {
  return apiServer.patch(
    `/offer/external/iteration-viewed/${iterationUuid}`,
    {},
    {
      headers: {
        'X-External-Token': externalToken ?? '',
      },
    },
  );
};

export const deleteOfferIterationDocumentById = (
  iterationId: string,
  { externalToken }: ExternalTokenOptions,
) => {
  return apiServer.delete(`/offer/external/iteration-document/${iterationId}`, {
    headers: {
      'X-External-Token': externalToken ?? '',
    },
  });
};

export function sendOfferPortalExternalVerificationLink({ uuid }) {
  return apiServer.post(SEND_VERIFICATION_LINK_FOR_OFFER_PORTAL(uuid));
}

export function getOfferPortalExternalVerificationData({ uuid }) {
  return apiServer.get(SEND_VERIFICATION_LINK_FOR_OFFER_PORTAL(uuid));
}

export function shareOfferIteration(cfg) {
  return apiServer.put(`/offer/share`, cfg);
}

export function getAllOfferBuyingAgents() {
  return apiServer.get(`/offer/buying-agents`);
}

interface ExternalTokenOptions {
  externalToken: string | null;
}
