import styles from './styles.module.scss';

const VirtualTour = ({ virtualTourValue: value }) => {
  return (
    <div className={styles.mapModal}>
      <div className={styles.bottomBar}>
        <iframe
          src={value}
          name="virtualTourUnBranded"
          height="100%"
          width="100%"
          title="virtualTourUnBranded"
        ></iframe>
      </div>
    </div>
  );
};

export default VirtualTour;
