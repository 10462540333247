import { updateKitsMasterControl } from 'api/kits';
import classNames from 'classnames';
import styles from './styles.module.scss';

type ClientKitNotificationsType = {
  emailChecked: boolean;
  emailCheckChangeHandler: Function;
};

const ClientKitNotifications = (props: ClientKitNotificationsType) => {
  return (
    <div className={styles.kitNotifications}>
      <div className={styles.notificationLabel}>I have an update regarding my home.</div>
      <div className={styles.optionsGroup}>
        <div className={classNames(styles.option, styles.unchecked)}>In-App</div>
        <div className={classNames(styles.option, styles.unchecked)}>Text</div>
        <div
          className={classNames(
            styles.option,
            props.emailChecked ? styles.checked : styles.unchecked,
          )}
          onClick={async () => {
            await updateKitsMasterControl({ MasterControl: !props.emailChecked });
            props.emailCheckChangeHandler(!props.emailChecked);
          }}
        >
          Email
        </div>
      </div>
    </div>
  );
};

export default ClientKitNotifications;
