function Rhombus({ colorWithHash }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.9">
        <path
          d="M0.707106 16.7071C0.316581 16.3166 0.316583 15.6834 0.707107 15.2929L15.2929 0.707106C15.6834 0.316581 16.3166 0.316583 16.7071 0.707107L31.2929 15.2929C31.6834 15.6834 31.6834 16.3166 31.2929 16.7071L16.7071 31.2929C16.3166 31.6834 15.6834 31.6834 15.2929 31.2929L0.707106 16.7071Z"
          fill={colorWithHash}
        />
      </g>
    </svg>
  );
}

export default Rhombus;
