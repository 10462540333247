import { Fragment, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Icons } from '../Icons';
import { OfferInformationModal } from '../OfferInformationModal';

export const Header = (props) => {
  const { offerLinkDetails, hasError, mobileWidth } = props;
  const [infoOpen, setInfoOpen] = useState(false);

  useEffect(() => {
    if (!mobileWidth && infoOpen) {
      setInfoOpen(false);
    }
  }, [mobileWidth]);

  const getFormatterAddress = () => {
    const { PropertyTransaction } = offerLinkDetails;

    const Property = PropertyTransaction?.Property;
    const { Line1, City, State, Zip } = Property?.Address || {
      Line1: '',
      City: '',
      State: '',
      Zip: '',
    };

    return (
      <Fragment>
        <div className={styles.headSubtitle}>{Line1}</div>
        <div className={styles.headDescription}>{`${City}, ${State} ${Zip}`}</div>
      </Fragment>
    );
  };

  const toggleModal = () => {
    setInfoOpen((prev) => !prev);
  };

  return (
    <div className={styles.headerArea}>
      <OfferInformationModal
        isOpen={infoOpen}
        offerDetails={offerLinkDetails}
        onClose={toggleModal}
      />

      <div className={styles.headWrapper}>
        <div className={styles.headTitle}>Submit Offer</div>

        {!hasError ? getFormatterAddress() : null}
      </div>
      {!hasError ? (
        <Icons variant={'info'} className={styles.infoIcon} onClick={toggleModal} />
      ) : null}
    </div>
  );
};
