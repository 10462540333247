import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { DatePicker } from 'components';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { Question } from 'pages/RequestQuote/components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';

import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Continue } from '../../Continue';

import styles from './styles.module.scss';

const LiveDate = (props) => {
  const { className, onNext, currentQuestionId } = props;
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const dispatch = useDispatch();

  const onAnswerHandler = (answer) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.liveDate]:
          answer &&
          moment(answer).diff(
            moment(preForm[transactionPreFormQuestionsIds.effectiveDate]),
            'days',
          ),
        [transactionPreFormQuestionsIds.liveDate]: answer || undefined,
      }),
    );
  };

  const onNextHandler = (event) => {
    if (currentQuestionId === transactionPreFormQuestionsIds.liveDate) {
      onNext(event);
    }
  };

  const isDisableContinue = () => {
    const targetLiveDate = preForm?.[transactionPreFormQuestionsIds.liveDate];
    const effectiveDate = preForm?.[transactionPreFormQuestionsIds.effectiveDate];

    return !targetLiveDate || moment(targetLiveDate).isBefore(effectiveDate, 'day');
  };

  return (
    <div testid="when_live_date_for" className={classNames(styles.container, className)}>
      <Question>What is the target live date for this listing?</Question>
      <AnswersContainer>
        <DatePicker
          variant={DatePicker.ROUNDED}
          placeholder="MM/DD/YY"
          onChange={(e, val) => onAnswerHandler(val?.[0])}
          value={preForm[transactionPreFormQuestionsIds.liveDate]}
          className={styles.dateInput}
          options={{
            minDate: preForm[transactionPreFormQuestionsIds.effectiveDate],
            maxDate: preForm[transactionPreFormQuestionsIds.listingExpireDate],
            enableTime: false,
          }}
        />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onNextHandler} disabled={isDisableContinue()} />
      </ButtonsContainer>
    </div>
  );
};

LiveDate.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  currentQuestionId: PropTypes.string,
};

LiveDate.defaultProps = {
  className: '',
  onNext: () => {},
  currentQuestionId: null,
};

export default LiveDate;
