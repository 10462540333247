import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Match from '../Match';
import Favorite from '../Favorite';
import Comment from '../Comment';
import { Checkbox } from 'components';
import styles from './styles.module.scss';
import { getComparesSelector, getMultipleModSelector } from 'store/selectors/feed';
import { useSelector } from 'react-redux';

const BuyerBlock = (props) => {
  const { className, propertyInfo, threadsPerPropertyId, Id, isChecked } = props;
  const multiple = useSelector(getMultipleModSelector);
  const compares = useSelector(getComparesSelector);

  return (
    <div className={classNames(styles.buyerBlock, className)}>
      <Match propertyInfo={propertyInfo} />
      {multiple ? (
        <Checkbox
          checkboxClassName={styles.checkbox}
          value={Id}
          checked={isChecked}
          hasOuterClick={true}
          className={compares?.data?.length === 5 && !isChecked ? styles.pointerEventsNone : ''}
        />
      ) : (
        <div className={styles.actions}>
          <Comment threadsPerPropertyId={threadsPerPropertyId} propertyInfo={propertyInfo} />
          <Favorite propertyInfo={propertyInfo} />
        </div>
      )}
    </div>
  );
};

BuyerBlock.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({}).isRequired,
  threadsPerPropertyId: PropTypes.shape({}).isRequired,
};

BuyerBlock.defaultProps = {
  className: '',
};

export default BuyerBlock;
