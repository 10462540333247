import React from 'react';

export const MosaikLogoWhite: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="139" height="28" viewBox="0 0 139 28" fill="none">
    <path
      d="M135.286 18.9891L131.57 22.6562L135.286 26.3234L139.003 22.6562L135.286 18.9891Z"
      fill="#FF576D"
    />
    <path
      d="M32.6262 13.6813V27.0417H25.6306V14.2622C25.6306 13.3054 25.0765 12.7246 23.3449 12.7246C22.2021 12.7246 21.267 13.3396 20.3319 14.1255V27.0418H13.2671V14.2622C13.2671 13.3054 12.7476 12.7246 11.016 12.7246C9.94252 12.7246 8.97279 13.2713 7.96844 14.0914V27.0418H0.972839V9.54671H7.96852V12.7587C9.45769 10.811 11.5701 9.20508 14.8602 9.20508C17.8039 9.20508 19.7779 10.4352 20.2281 13.1688C21.6134 11.1186 23.8991 9.20508 27.1545 9.20508C30.6522 9.20501 32.6262 10.7427 32.6262 13.6813Z"
      fill="white"
    />
    <path
      d="M35.0503 18.636C35.0503 13.1688 39.4485 9.20508 45.7168 9.20508C51.9852 9.20508 56.3487 12.6221 56.3487 17.9526C56.3487 23.4198 51.9852 27.3835 45.7168 27.3835C39.4485 27.3835 35.0503 23.9665 35.0503 18.636ZM48.8683 19.6611C48.8683 14.1597 47.9679 10.5719 45.5783 10.5719C43.258 10.5719 42.5307 12.9296 42.5307 16.9275C42.5307 22.4288 43.4311 26.0167 45.8553 26.0167C48.141 26.0167 48.8683 23.6589 48.8683 19.6611Z"
      fill="white"
    />
    <path
      d="M57.7326 24.4108L60.7456 21.6772C62.9966 25.0258 65.6979 25.7092 67.2909 25.6409C68.6069 25.5725 69.7844 24.9916 69.7844 23.7273C69.7844 22.2238 68.0182 21.6771 64.7282 20.8912C60.3646 19.7294 58.2174 18.2259 58.2174 15.0481C58.2174 11.2553 61.5074 9.20508 66.5636 9.20508C70.9965 9.20508 74.0094 10.8794 74.0094 12.8613C74.0094 13.7497 73.3861 14.6039 71.793 14.6039C70.3731 14.6039 69.9922 13.9547 69.9922 13.0663C69.9922 11.5287 69.0917 10.5719 66.7021 10.5719C64.555 10.5719 63.4468 11.3578 63.4468 12.5196C63.4468 13.9548 65.1091 14.3989 68.4337 15.3215C72.5895 16.5175 75.0137 17.8843 75.0137 21.3013C75.0137 25.2309 71.6891 27.3835 66.0441 27.3835C62.6156 27.3835 59.8797 26.666 57.7326 24.4108Z"
      fill="white"
    />
    <path
      d="M94.407 14.8773V27.0418H87.4806V24.1373C86.3378 25.7775 84.5369 27.281 81.6278 27.281C78.6495 27.281 76.3984 25.7091 76.3984 22.9072C76.3984 20.1053 78.7188 18.9093 81.1776 18.1918L87.4806 16.3124V12.9979C87.4806 11.4945 86.9611 10.4694 85.5759 10.4694C84.0175 10.4694 83.4287 11.7337 83.4287 13.3738C83.4287 14.8431 82.0434 15.7999 80.2426 15.7999C78.4417 15.7999 77.6798 14.8431 77.6798 13.5447C77.6798 10.7427 81.2469 9.20508 86.1992 9.20508C91.2208 9.20508 94.407 10.6402 94.407 14.8773ZM87.4806 23.2489V17.6792L85.3681 18.3626C84.0521 18.7727 83.1516 19.4218 83.1516 21.2671C83.1516 22.9072 83.8789 24.1373 85.472 24.1373C85.8518 24.1404 86.2279 24.0626 86.5745 23.9093C86.9211 23.756 87.2302 23.5308 87.4806 23.2489Z"
      fill="white"
    />
    <path
      d="M98.0425 4.11366C98.0425 2.02937 99.8087 0.560059 101.852 0.560059C103.826 0.560059 105.662 2.02937 105.662 4.11366C105.662 6.2322 103.826 7.73567 101.852 7.73567C99.8087 7.73574 98.0425 6.23226 98.0425 4.11366ZM98.3542 27.0416V9.54674H105.35V27.0417L98.3542 27.0416Z"
      fill="white"
    />
    <path
      d="M123.254 27.0419L118.059 19.9687C117.747 20.1737 117.47 20.3787 117.159 20.6179V27.0419H110.163V0.901855H117.159V18.6702C119.971 16.1715 122.132 13.0425 123.462 9.54685H129.072C127.617 11.9046 125.263 14.2623 122.423 16.62L130.527 27.0419H123.254Z"
      fill="white"
    />
  </svg>
);
