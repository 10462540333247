import { useSelector } from 'react-redux';
import { LocationDetails } from '../LeftPanel/LocationDetails';
import styles from './styles.module.scss';
import { getShowingDetails } from 'store/selectors/showingAppointment';

export const Header = () => {
  const { PropertyTransaction } = useSelector(getShowingDetails);
  return (
    <div className={styles.headerArea}>
      <h1 className={styles.headTitle}>Book a Showing</h1>
      <LocationDetails PropertyTransaction={PropertyTransaction} />
    </div>
  );
};
