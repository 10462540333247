import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal } from 'components-antd';
import { InputLabel, Select, Autocomplete } from 'components';
import styles from './styles.module.scss';

import { useSelector } from 'react-redux';
import { getClientContextsStateSelector } from 'store/selectors/contexts';

const AgentSelectionModal = ({ showModal, onCloseModal, onSubmit }) => {
  const [agentId, setAgentId] = useState();
  const selectedContext = useSelector(getClientContextsStateSelector);
  const agents = useMemo(() => {
    const agents: any = [];
    selectedContext.data?.forEach((ctx: any) => {
      ctx.Agents.forEach((agent) =>
        agents.push({
          name: `${agent.FirstName} ${agent.LastName}`,
          value: agent.Id,
        }),
      );
    });
    return agents;
  }, [selectedContext]);
  const agent = useMemo(() => agents.find((t) => t.value == agentId), [agentId]);

  const getOptions = () => {
    return agents;
  };

  const getValue = () => {
    return {
      name: `${agent?.FirstName} ${agent?.LastName}` || '',
      value: agent?.Id || '',
    };
  };
  const onAgentChange = ({ target: { value: val } }) => {
    setAgentId(val.value);
  };
  const handleSubmitClick = () => {
    onSubmit && onSubmit(agentId);
    onCloseModal && onCloseModal();
  };

  return (
    <>
      {showModal && (
        <Modal
          open={showModal}
          width={675}
          footer={null}
          closable={true}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={onCloseModal}
          title="Agent Selection"
          className={styles.scheduleTourModal}
        >
          <div className={styles.fieldWrapper}>
            <InputLabel label="Agents" className={styles.label} showAsterisk />
            <Select
              search
              className={{
                wrapper: styles.search,
                value: styles.value,
              }}
              value={getValue()}
              variant={Autocomplete.LIGHT}
              options={getOptions()}
              placeholder="Select Agent"
              onSelect={onAgentChange}
              searchIconClassName={styles.searchIcon}
            />
          </div>

          <Button
            disabled={!agentId}
            onClick={() => {
              handleSubmitClick();
            }}
          >
            Schedule
          </Button>
        </Modal>
      )}
    </>
  );
};

export default AgentSelectionModal;
