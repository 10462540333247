import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getPropertyAddress, getFirstImage } from 'helpers';

import styles from './styles.module.scss';

const PropertyInfo = (props) => {
  const { className, property, forName } = props;
  const { Media, PhotoUrls } = property;
  const firstImage = getFirstImage(Media);
  const imageToSend = firstImage ? firstImage : PhotoUrls?.[0];
  if (!property) return null;

  return (
    <div className={classNames(styles.propertyInfo, className)}>
      <div>
        <img className={styles.photo} src={imageToSend} alt="" />
      </div>
      <div className={styles.info}>
        <div className={styles.address}>{getPropertyAddress(property?.Address)}</div>
        {!!forName && <div className={styles.metaInfo}>{forName}</div>}
      </div>
    </div>
  );
};

PropertyInfo.propTypes = {
  className: PropTypes.string,
  forName: PropTypes.string,
  property: PropTypes.shape({
    PhotoUrls: PropTypes.arrayOf(PropTypes.string),
    Address: PropTypes.shape({
      Line1: PropTypes.string,
      City: PropTypes.string,
      State: PropTypes.string,
    }),
  }),
};

PropertyInfo.defaultProps = {
  className: '',
  forName: '',
  property: null,
};

export default PropertyInfo;
