import { ReactNode } from 'react';

export interface IHomePrefsListProps {
  onNext: () => void;
}

export interface IMenuItems {
  label: string;
  id: string;
}

export interface IHomePrefsListItemProps {
  color: string;
  variant: IconsVariant;
  label: string;
  keyValue: string;
  value: string;
  setExpanded: (value: string) => void;
  component: () => ReactNode;
  active: boolean;
  subtitle?: string;
}

export enum IconsVariant {
  LAYOUT = 'layout',
  PROPERTY_CONDITION = 'propertyCondition',
  OUTDOOR_SPACES = 'outdoorSpaces',
  PARKING = 'parking',
  KITCHEN_FEATURES = 'kitchenFeatures',
  FLOORING = 'flooring',
  INTERIOR_FEATURES = 'interiorFeatures',
  VIEW = 'view',
  AMENITIES = 'amenities',
  MECHANICAL = 'mechanical',
}

export interface IIconsProps {
  className?: string;
  variant: IconsVariant;
}

export interface IParking {
  parkingSpaces: number;
  garageOnly: boolean;
  attachedGarage: boolean;
}

export interface IParkingComponent {
  color: string;
  value: IParking;
  setValue: any;
  rvOnChange: any;
  rvValue: any;
}

export interface IMechanicalComponent {
  color: string;
  onChange: any;
  value: any;
}

export interface ISelectComponentProps {
  color: string;
  items?: IMenuItems[];
  onChange: any;
  value: any;
  multiple?: boolean;
}

export interface ICabinetComponentProps {
  color: string;
  onChange: any;
  value: any;
}

export interface IHomePrefsProps {
  onNext: any;
  Controls: any;
}
