/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { prefsIds } from 'settings/constants/preferences';

import { Row, Col, Button } from 'components-antd';
import { OutdoorSpaceTypes } from './OutdoorSpaceTypes';
import { ParkingSpaces } from './ParkingSpaces';
import { FlooringOptions } from './FlooringOptions';
import { SchoolOptions } from './SchoolOptions';
import { Commutes } from './Commutes';

import Icon from '../Icons';

import styles from './styles.module.scss';

export const SoftCriteriaType = ({
  title,
  icon,
  onClick,
  softCriteria,
  type,
  showTitle,
  importance,
  showCommutes,
  hideNA,
}) => {
  const getClassnamePerImportance = (value: string) => {
    if (importance || importance === '') {
      if (importance === '' && value === 'N/A') {
        return styles.softRadioButtonClicked;
      } else if (importance === value) {
        return styles.softRadioButtonClicked;
      } else {
        return styles.softRadioButton;
      }
    } else {
      return (softCriteria[title] && softCriteria[title].value === value) ||
        (!softCriteria[title] && value === 'N/A')
        ? styles.softRadioButtonClicked
        : styles.softRadioButton;
    }
  };

  const renderSoftRadioButton = (value) => (
    <Button
      variant="default"
      className={classNames(styles.optionButtons, getClassnamePerImportance(value))}
      onClick={() => onClick(title, value, type)}
    >
      {value}
    </Button>
  );

  return (
    <>
      <div className={styles.softCriteriaType}>
        <Row>
          <Col xs={24} sm={12} className={styles.logoCol}>
            <Icon variant={icon} className={styles.softIcon} />
            <p>{showTitle}</p>
          </Col>
          <Col xs={24} sm={12} className={styles.radioButtonsRow}>
            {renderSoftRadioButton('Need')}
            {renderSoftRadioButton('Want')}
            {!hideNA ? renderSoftRadioButton('N/A') : <></>}
          </Col>
        </Row>
      </div>
      <OutdoorSpaceTypes
        title={title}
        softCriteria={softCriteria}
        type={prefsIds.outdoorPrefs}
        onClick={onClick}
      />
      <ParkingSpaces title={title} softCriteria={softCriteria} type={type} onClick={onClick} />
      <FlooringOptions title={title} softCriteria={softCriteria} type={type} onClick={onClick} />
      <SchoolOptions title={title} softCriteria={softCriteria} type={type} onClick={onClick} />
      {showCommutes && (
        <Commutes title={title} softCriteria={softCriteria} type={type} onClick={onClick} />
      )}
    </>
  );
};

SoftCriteriaType.propTypes = {
  formik: PropTypes.shape({}),
  disabled: PropTypes.bool,
  changeStep: PropTypes.func,
  importance: PropTypes.string,
  showCommutes: PropTypes.bool,
  hideNA: PropTypes.bool,
};

SoftCriteriaType.defaultProps = {
  disabled: false,
  showCommutes: true,
  hideNA: false,
};
