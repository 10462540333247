import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { setDrawerAgentProfileId, setDrawerClientProfileId } from 'store/effects/app';
import { Back } from 'components';
import Actions from './Actions';

import styles from './styles.module.scss';

const ProfileHeader = ({ className, isAgent }) => {
  const dispatch = useDispatch();

  const getEffect = () => (isAgent ? setDrawerAgentProfileId : setDrawerClientProfileId);

  return (
    <div testid="profile_header" className={classNames(styles.profileHeader, className)}>
      <Back
        className={styles.back}
        textClassName={styles.text}
        onBack={() => dispatch(getEffect()({ id: null }))}
        text={isAgent ? 'Agents' : 'Clients'}
        testid="back_all_clients"
      />
      <Actions isAgent={isAgent} />
    </div>
  );
};

ProfileHeader.propTypes = {
  className: PropTypes.string,
  isAgent: PropTypes.bool,
};

ProfileHeader.defaultProps = {
  className: '',
  isAgent: false,
};

export default ProfileHeader;
