import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { changeMessagesDrawerTypeAction } from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';

import ActionsHeader from 'components/Drawers/components/ActionsHeader';

const DrawerHeaderControlls = ({ actions, archived, onBack, label }) => {
  const dispatch = useDispatch();

  const toAllMessagesHandler = useCallback(
    () =>
      dispatch(
        changeMessagesDrawerTypeAction({
          type: archived ? DRAWER_MESSAGES_TYPES.ARCHIVED : DRAWER_MESSAGES_TYPES.INIT,
        }),
      ),
    [dispatch, archived],
  );

  const labelDefault = archived ? 'Archive' : 'All messages';

  return (
    <ActionsHeader
      label={label || labelDefault}
      onClick={onBack || toAllMessagesHandler}
      actions={actions}
    />
  );
};

DrawerHeaderControlls.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  archived: PropTypes.bool,
  label: PropTypes.string,
  onBack: PropTypes.func,
};

DrawerHeaderControlls.defaultProps = {
  actions: [],
  archived: false,
  label: undefined,
  onBack: null,
};

export default DrawerHeaderControlls;
