import { createSelector } from 'reselect';
import { filter, groupBy, upperCase, includes, map, get, find } from 'lodash-es';
import { AGENT_CONNECTION_STATUSES } from 'settings/constants/common';
import { IDLE, PENDING } from 'settings/constants/apiState';

export const localState = ({ collaboratorsList }) => collaboratorsList;

export const collaboratorsSearchFilter = (list, search) =>
  filter(list, ({ name }) => includes(upperCase(name), upperCase(search)));

export const getStateAndDataFlag = createSelector(localState, ({ state, data }) => ({
  state,
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!data,
}));

export const getCollaboratorsListDataArray = createSelector(localState, ({ data }) => data || []);

export const getFormattedCollaboratorsList = createSelector(getCollaboratorsListDataArray, (data) =>
  map(data, (item = {}) => ({
    id: item.Id,
    name: `${item.FirstName} ${item.LastName}`,
    firstName: item.FirstName,
    lastName: item.LastName,
    role: item.Title,
    contextStatus: AGENT_CONNECTION_STATUSES.ACTIVE,
    avatarUrl: get(item, 'AvatarUrl'),
    deactivated: get(item, 'Deactivated'),
  })),
);

export const getActiveCollaboratorsList = createSelector(getFormattedCollaboratorsList, (list) =>
  filter(list, ({ contextStatus }) => contextStatus === AGENT_CONNECTION_STATUSES.ACTIVE),
);

export const getInactiveCollaboratorsList = createSelector(getFormattedCollaboratorsList, (list) =>
  filter(
    list,
    ({ deactivated, contextStatus }) =>
      contextStatus === AGENT_CONNECTION_STATUSES.INACTIVE || deactivated,
  ),
);

export const getPendingCollaboratorsList = createSelector(getFormattedCollaboratorsList, (list) =>
  filter(list, ({ contextStatus }) => contextStatus === AGENT_CONNECTION_STATUSES.PENDING),
);

export const getDeclinedCollaboratorsList = createSelector(getFormattedCollaboratorsList, (list) =>
  filter(list, ({ contextStatus }) => contextStatus === AGENT_CONNECTION_STATUSES.DECLINED),
);
