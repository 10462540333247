import { apiServer } from 'settings/web-services/api';

export function requestScheduleTour(cfg) {
  return apiServer.post('/tour', cfg);
}

export function getScheduleTour(cfg) {
  return apiServer.get(`/tour/getByPropertyId/${cfg.propertyId}`);
}

export function getScheduleTours(cfg) {
  return apiServer.get(`/tour?EntireTeam=${cfg.EntireTeam}`);
}

export function batchUpdateScheduleTours(cfg) {
  return apiServer.put('/tour/batchUpdate', cfg);
}
export function cancelScheduleTour(cfg) {
  return apiServer.patch(`/tour/cancel/${cfg.UUID}`, cfg);
}
export function batchCancelScheduleTours(cfg) {
  return apiServer.patch('/tour/batchCancel/', cfg);
}
