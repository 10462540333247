import PropTypes from 'prop-types';
import { convertNameToAvatarPlaceholder } from 'helpers/formatters';

import { Avatar } from 'components-antd';
import styles from './styles.module.scss';

const MatchUserListItem = ({ item: { name, avatar, score, mustHave, wants, searchInstance } }) => {
  return (
    <div className={styles.wrapper}>
      <div
        data-rh
        className={styles.labelBlock}
        data-matchuser={JSON.stringify({ avatar, name, score, mustHave, wants, searchInstance })}
      >
        <Avatar className={styles.avatar} size={40} src={avatar}>
          {avatar ? avatar : convertNameToAvatarPlaceholder(`${name}`)}
        </Avatar>
        <span className={styles.name}>{name}</span>
      </div>
    </div>
  );
};

MatchUserListItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    mustHave: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        isMatched: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    wants: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        isMatched: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
};

export default MatchUserListItem;
