import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TaskName } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModalForm/TaskName';
import { AssignedTo } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModalForm/AssignedTo';
import { DueDate } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModalForm/DueDate';
import { Description } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModalForm/Description';

import { Footer } from '../Footer';
import { TaskEditableFormValuesType } from 'types/transactionTasks';
import { Button } from 'antd';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { useOutsideClick } from 'hooks';
import { getTransactionRolesEffect } from 'store/effects';
import { Switch } from 'components-antd';
import { CcTo } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModalForm/CcTo';
import { AssignedToMilestone } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModalForm/AssignedToMilestone';

import styles from './styles.module.scss';

export const FormTask = (props) => {
  const {
    isNew,
    showSubheading,
    isTemplate,
    editData,
    templateId,
    isAssignTask,
    assignTask,
    onClose,
    isTransactionTask,
    onUpdate,
    className,
    onRemove,
    taskLength,
    isAggregatedTasks,
    isViewMode,
    onSubmit,
    title,
    data = [],
  } = props;

  const [updatedFormValues, setUpdatedFormValues] = useState(editData);
  const [addDescription, setAddDescription] = useState(!!editData?.Description?.length);
  const [showCC, setShowCC] = useState(!!editData?.CcRoles?.length);
  const [open, setOpen] = useState(false);
  const [showMdDropdown, setShowMdDropdown] = useState(false);

  const [changedFormvalues, setNewFormValues] = useState({
    formId: editData?.Id,
  } as TaskEditableFormValuesType);

  const buttonRef = useRef<any>();
  const optionsRef = useRef<any>();

  const dispatch = useDispatch();

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  useEffect(() => {
    if (((isTemplate && templateId) || assignTask || isTransactionTask) && editData) {
      setNewFormValues({ ...editData });
      setUpdatedFormValues({ ...editData });
    }
  }, [isTemplate, templateId, assignTask, editData, isTransactionTask]);

  useEffect(() => {
    if (updatedFormValues?.Initial === 'MD') {
      setShowMdDropdown(true);
    } else {
      setShowMdDropdown(false);
    }
    dispatch(getTransactionRolesEffect());
  }, []);

  const onFormValueChange = (values) => {
    setNewFormValues(values);
    setUpdatedFormValues({ ...(updatedFormValues || {}), ...values });
    onUpdate(updatedFormValues);
  };

  const onChangeField = (val, fieldName) => {
    onFormValueChange({ ...(changedFormvalues || {}), [fieldName]: val });
  };

  const onChangeManyFields = (data) => {
    onFormValueChange({ ...(changedFormvalues || {}), ...data });
    if (data?.Initial === 'MD') {
      setShowMdDropdown(true);
    } else {
      setShowMdDropdown(false);
    }
  };

  return (
    <div
      className={classNames(styles.taskModalForm, {
        [styles.transactionTaskForm]: isTransactionTask,
      })}
    >
      <div className={styles.modalHeader}>
        <span className={styles.heading}>{title}</span>
      </div>
      <div className={classNames(styles.content, className)}>
        <TaskName
          isTemplate={isTemplate}
          values={updatedFormValues}
          onChangeField={onChangeField}
          isViewMode={isViewMode}
          isFormTask={true}
          inputHolderClassName={styles.inputHolder}
        />
        <div className={classNames({ [styles.rolesWrapper]: isTransactionTask })}>
          <AssignedTo
            isTemplate={isTemplate}
            values={updatedFormValues}
            onChangeField={onChangeField}
            isAssignTask={isAssignTask}
            className={isTransactionTask && styles.fieldHalf}
            isViewMode={isViewMode}
            showRole={true}
            setShowCC={setShowCC}
            showCC={showCC}
            isNew={isNew}
            valueClassName={styles.inputHolder}
          />
          {showCC ? (
            <CcTo
              isTemplate={isTemplate}
              values={updatedFormValues}
              onChangeField={onChangeField}
              className={''}
              isAssignTask={false}
              showOptional={true}
              isViewMode={isViewMode}
            />
          ) : (
            <></>
          )}
        </div>
        <DueDate
          isTemplate={isTemplate}
          values={updatedFormValues}
          onChangeManyFields={onChangeManyFields}
          isViewMode={isViewMode}
          scrollToBottom={true}
          inputWrapperClassName={styles.inputHolder}
        />

        {showMdDropdown && (
          <div className={styles.mdDropdownContainer}>
            <AssignedToMilestone
              isTemplate={isTemplate}
              values={updatedFormValues}
              onChangeField={onChangeField}
              className={''}
              isAssignTask={false}
              hideAsterisk={true}
              label="Milestone"
              isViewMode={isViewMode}
              data={data}
            />
          </div>
        )}
        <Description
          values={updatedFormValues}
          onChangeField={onChangeField}
          isViewMode={isViewMode}
          addDescription={addDescription}
          inputWrapperClassName={styles.descriptionInputHolder}
        />

        <div className={styles.moreOptionsContainer}>
          {!addDescription && (
            <Button
              onClick={() => {
                setAddDescription(true);
                setOpen(false);
              }}
              className={styles.moreOptionsBtn}
              icon={<Icon variant={Icon.ADD} />}
              type="text"
              ref={buttonRef}
            >
              Add Description
            </Button>
          )}
        </div>
        <div className={styles.checkRequired}>
          <Switch
            checked={updatedFormValues?.IsRequired}
            defaultChecked={updatedFormValues?.IsRequired}
            onChange={(checked) => onChangeField(checked, 'IsRequired')}
            disabled={!!isViewMode}
          />
          <span className={styles.requiredText}>Required</span>
        </div>
      </div>
      {!isViewMode && (
        <Footer
          isTransactionTask={isTransactionTask}
          isNew={isNew}
          isTemplate={isTemplate}
          isAssignTask={isAssignTask}
          templateId={templateId}
          values={updatedFormValues}
          changedValues={changedFormvalues}
          setNewFormValues={setNewFormValues}
          onClose={onClose}
          assignTask={assignTask}
          isAggregatedTasks={isAggregatedTasks}
          isViewMode={isViewMode}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

FormTask.propTypes = {
  isOpen: PropTypes.bool,
  isNew: PropTypes.bool,
  showSubheading: PropTypes.bool,
  isTemplate: PropTypes.bool,
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editData: PropTypes.object,
  isAssignTask: PropTypes.bool,
  assignTask: PropTypes.func,
  onClose: PropTypes.func,
  isTransactionTask: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onFieldChange: PropTypes.func,
  className: PropTypes.string,
  taskLength: PropTypes.number,
  taskData: PropTypes.object,
  isAggregatedTasks: PropTypes.bool,
  isViewMode: PropTypes.bool,
  onSubmit: PropTypes.func,
  data: PropTypes.array,
};

FormTask.defaultProps = {
  isOpen: false,
  isNew: false,
  showSubheading: false,
  isTemplate: false,
  templateId: null,
  editData: null,
  isAssignTask: false,
  assignTask: () => {},
  onClose: () => {},
  isTransactionTask: false,
  onUpdate: () => {},
  onRemove: () => {},
  onFieldChange: () => {},
  className: '',
  taskLength: null,
  taskData: {},
  isAggregatedTasks: false,
  isViewMode: false,
  onSubmit: () => {},
  data: [],
};
