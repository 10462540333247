import { createSelector } from 'reselect';
import { get } from 'lodash-es';

const localState = ({ drawers }) => get(drawers, 'messages');

export const getIsMessagesDrawerOpenSelector = createSelector(localState, ({ open }) => !!open);

export const getIsMessagesDrawerTypeSelector = createSelector(localState, ({ type }) => type);

export const getMessagesDrawerParams = createSelector(localState, ({ params }) => params);
