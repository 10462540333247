import {
  deleteAreaGroupAction,
  getAreaGroupDetailsAction,
  getAreaGroupsAction,
  saveNewAreaGroupAction,
  updateAreaGroupAction,
} from 'store/actions/adminPanel';
import Api from '../core/api';
import { getState } from 'store';
import {
  CreateNewAreaGroupRequest,
  GetAreaGroupsQuery,
  createNewAreaGroup,
  deleteAreaGroup,
  getAreaGroupDetails,
  getAreaGroups,
  updateAreaGroup,
} from 'api/admin/areaClassifier';
import { AreaTypeRefined } from 'store/reducers/adminPanel/areaClassifier/selectedAreaGroup';

export const saveNewAreaGroupEffect = (cfg?, options?, cb?) => {
  const {
    adminPanel: { areaClassifier },
  } = getState();

  const editableAreaGroup = areaClassifier.selectedAreaGroup.editableAreaGroup;

  if (!editableAreaGroup) {
    return;
  }

  const sendRequest = Api.execBase({
    action: saveNewAreaGroupAction,
    method: createNewAreaGroup,
  });
  const request: CreateNewAreaGroupRequest = {
    Name: editableAreaGroup.name,
    Areas: editableAreaGroup.areas
      .filter((a) => a.shape)
      .map((area) => ({
        Type: area.type as AreaTypeRefined,
        ShapeGeoJson: area.shape!.geoJson,
      })),
  };

  return sendRequest(request, {}, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAreaGroupsEffect());
    }
    cb(err, response, dispatch);
  });
};

export const updateAreaGroupEffect = (cfg: { areaGroupId: number }, options?, cb?) => {
  const {
    adminPanel: { areaClassifier },
  } = getState();

  const editableAreaGroup = areaClassifier.selectedAreaGroup.editableAreaGroup;

  if (!editableAreaGroup) {
    return;
  }

  const sendRequest = Api.execBase({
    action: updateAreaGroupAction,
    method: updateAreaGroup,
  });
  const request: CreateNewAreaGroupRequest = {
    Name: editableAreaGroup.name,
    Areas: editableAreaGroup.areas
      .filter((a) => a.shape)
      .map((area) => ({
        Type: area.type as AreaTypeRefined,
        ShapeGeoJson: area.shape!.geoJson,
      })),
  };

  return sendRequest(
    { areaGroupId: cfg.areaGroupId, payload: request },
    {},
    (err, response, dispatch) => {
      if (!err) {
        dispatch(getAreaGroupsEffect());
      }
      cb(err, response, dispatch);
    },
  );
};

export const getAreaGroupsEffect = (cfg?, options?, cb?) => {
  const {
    adminPanel: { areaClassifier },
  } = getState();

  const areaGroupsState = areaClassifier.areaGroups;

  const sendRequest = Api.execBase({
    action: getAreaGroupsAction,
    method: getAreaGroups,
  });
  const request: GetAreaGroupsQuery = {
    searchTerm: areaGroupsState.searchTerm || undefined,
  };

  return sendRequest(request, {}, (err, response, dispatch) => {
    if (cb) {
      cb(err, response, dispatch);
    }
  });
};

export const getAreaGroupsWithSearchTermEffect = (cfg: { search: string }, options?, cb?) => {
  const {
    adminPanel: { areaClassifier },
  } = getState();

  const areaGroupsState = areaClassifier.areaGroups;

  const sendRequest = Api.execBase({
    action: getAreaGroupsAction,
    method: getAreaGroups,
  });
  const request: GetAreaGroupsQuery = {
    searchTerm: cfg.search || undefined,
  };

  return sendRequest(request, {}, (err, response, dispatch) => {
    if (cb) {
      cb(err, response, dispatch);
    }
  });
};

export const getAreaGroupDetailsEffect = (cfg: { areaGroupId: number }, options?, cb?) => {
  const sendRequest = Api.execBase({
    action: getAreaGroupDetailsAction,
    method: getAreaGroupDetails,
  });

  return sendRequest(cfg.areaGroupId, {}, (err, response, dispatch) => {
    if (cb) {
      cb(err, response, dispatch);
    }
  });
};

export const deleteAreaGroupEffect = (cfg: { areaGroupId: number }, options?, cb?) => {
  const sendRequest = Api.execBase({
    action: deleteAreaGroupAction,
    method: deleteAreaGroup,
  });

  return sendRequest(cfg.areaGroupId, {}, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAreaGroupsEffect());
    }
    cb(err, response, dispatch);
  });
};
