import styles from './styles.module.scss';
import moment from 'moment';
import { getLocaleDate } from 'helpers/index';
import React, { useState } from 'react';

export const Notes = (props) => {
  const { offerLinkDetails } = props;
  const [showFull, setFull] = useState(false);
  const wordLimit = 75;

  const pageNotes = offerLinkDetails?.SubmissionPageNotes || '';

  const toggleShowFull = () => {
    setFull(!showFull);
  };

  const note = showFull
    ? `${pageNotes} `
    : `${pageNotes.slice(0, wordLimit)}${pageNotes.length > wordLimit ? '..' : ''}`;

  return (
    <div className={styles.notesDetails}>
      <div className={styles.headTitle}>Notes from the Listing Agent</div>
      {offerLinkDetails?.SubmissionPageNotes ? (
        <>
          <div className={styles.note}>
            {note}
            {pageNotes.length > wordLimit && (
              <span className={styles.readMoreLess} onClick={toggleShowFull}>
                Read {showFull ? 'Less' : 'More'}
              </span>
            )}
          </div>
          <p className={styles.time}>
            {moment(getLocaleDate(offerLinkDetails?.CreatedDate)).format('MMM Do, hh:mm A')}
          </p>
        </>
      ) : (
        '-'
      )}
    </div>
  );
};
