import { useState } from 'react';
import { Tooltip } from 'components-antd';
import classNames from 'classnames';

import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';

import styles from './styles.module.scss';

export const AccessInfo = () => {
  const [open, setOpen] = useState(false);

  const content = () => (
    <div className={styles.content}>
      <p>
        <span>Full access</span>
      </p>
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Can edit transaction details</p>
      <br />
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Can edit timelines</p>
      <br />
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Can remove participants</p>
      <br />
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Can view and interact with all tasks</p>
      <br />
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Can view transaction activity</p>
      <br />
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Can upload documents</p>
      <br />
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Can only view documents they have access to</p>
      <br />
      <p className={styles.limited}>
        <span>Limited access</span>
      </p>
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Can view transaction details</p>
      <br />
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Can view timelines</p>
      <br />
      <div className={styles.tasks}>
        <Icon
          variant={Icon.CHECK_MARK}
          className={classNames(styles.inline, styles.taskCheckMark)}
        />
        <p className={styles.inline}>
          Can only view and interact with tasks assigned to or by the user
        </p>
      </div>
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Can upload documents</p>
      <br />
      <Icon variant={Icon.CHECK_MARK} className={styles.inline} />
      <p className={styles.inline}>Can only view documents they have access to</p>
      <br />
    </div>
  );

  return (
    <div
      className={styles.accessInfo}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Tooltip
        title={content}
        placement="bottom"
        open={open}
        overlayClassName={styles.accessTooltip}
      >
        <Icon variant={Icon.INFO} />
      </Tooltip>
    </div>
  );
};
