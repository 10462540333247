/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ItemsWrapper, ItemWrapper, Value } from '../components';

import styles from './styles.module.scss';

const Neighborhoods = (props) => {
  const { className, neighborhoods } = props;

  if (!neighborhoods) return null;

  return (
    <ItemsWrapper className={classNames(styles.neighborhoods, className)}>
      {neighborhoods.map((neighborhood, index) => (
        <ItemWrapper key={index}>
          <Value testid="neighborhood" value={neighborhood} />
        </ItemWrapper>
      ))}
    </ItemsWrapper>
  );
};

Neighborhoods.propTypes = {
  className: PropTypes.string,
  neighborhoods: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Neighborhoods.defaultProps = {
  className: '',
};

export default Neighborhoods;
