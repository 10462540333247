/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const ItemTitle = ({ className, children }) => (
  <div testid="item_title" className={classNames(styles.itemTitle, className)}>
    {children}
  </div>
);

ItemTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ItemTitle.defaultProps = {
  className: '',
};

export default ItemTitle;
