import classNames from 'classnames';

import { LinkExpired, NoResults, PageNotFound, Spinner, Stages } from 'components';
import { Header } from './components/Header';
import { SubmissionForm } from './SubmissionForm';
import { OfferDetails } from './OfferDetails';
import { Row, Col } from 'antd';

import styles from './styles.module.scss';
import { Footer } from 'components-antd';
import { useResize } from 'hooks';
import { InactiveLink } from './components/InactiveLink';
import { useState } from 'react';

export const OfferSubmission = (props) => {
  const { isPending, setIsPending, onClose, offerLinkDetails, isError } = props;
  const [hideSubmission, setHideSubmission] = useState(false);

  const { screen } = useResize();

  if (isPending) return <Spinner className={classNames(styles.spinner)} />;

  const { mobileWidth, mobileSmallWidth } = screen;

  return (
    <div className={styles.offerSubmission}>
      <Header
        mobileWidth={mobileWidth}
        offerLinkDetails={offerLinkDetails}
        hasError={isError.status}
      />

      <div className={classNames(styles.wrapper, { [styles.errorWrapper]: isError.status })}>
        {isError.status ? (
          <Row justify="center" className={styles.submissionRow}>
            <Col xs={24} sm={24} md={mobileWidth ? 24 : 12}>
              {getErrorComponent(isError.type, isError?.resObj, isError?.message)}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col
              xs={24}
              sm={24}
              lg={{ span: 18, offset: 6 }}
              xl={{ span: 16, offset: 8 }}
              xxl={{ span: 13, offset: 9 }}
            >
              <Row
                className={
                  hideSubmission
                    ? styles.containerRow
                    : `${styles.submissionRow} ${styles.initialLandingPage}`
                }
                gutter={mobileSmallWidth || mobileWidth ? [16, 16] : [70, 56]}
              >
                <Col xs={24} sm={24} md={mobileWidth ? 24 : 16}>
                  <SubmissionForm
                    offerLinkDetails={offerLinkDetails}
                    hideSubmission={hideSubmission}
                    changeSubmission={setHideSubmission}
                  />
                </Col>
                {!mobileWidth ? (
                  <Col
                    xs={24}
                    md={8}
                    className={classNames({
                      [styles.submissionDetails]: !hideSubmission,
                      [styles.submissionSideDetails]: hideSubmission,
                    })}
                  >
                    <OfferDetails mobileWidth={mobileWidth} offerLinkDetails={offerLinkDetails} />
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        )}
      </div>

      <Footer className={styles.footerArea} />
    </div>
  );
};

const getErrorComponent = (type, resObj, message) => {
  switch (type) {
    case 'LinkExpired':
      return <LinkExpired className={styles.linkExpiredCard} SellerAgent={resObj} />;
    case 'NoRecord':
      return <NoResults text={message} />;
    case 'InactiveLink':
      return <InactiveLink />;
    default:
      return <PageNotFound />;
  }
};
