import { createSelector } from 'reselect';
import { FormBuilderType } from 'types';

const localState = ({ formBuilder }) => formBuilder.data;

export const getFolderListSelector = createSelector(
  localState,
  ({ folderList }: FormBuilderType) => {
    return {
      folderList,
    };
  },
);
