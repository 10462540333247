import { useSelector } from 'react-redux';

import { FormMeta, FormPublish } from '../components';
import { PENDING } from 'settings/constants/apiState';
import { getQuoteFormMetaSelect } from 'store/selectors/formBuilder';
import { useEffect, useState } from 'react';
import { getHeaderConfig } from 'settings/navigation/config/components/header';
import { useLocation } from 'react-router-dom';

export const QuoteFormHeader = () => {
  const { pathname } = useLocation();

  const { state } = useSelector(getQuoteFormMetaSelect);

  const [previewForm, setPreviewForm] = useState(false);

  useEffect(() => {
    const config = getHeaderConfig(pathname);
    if (config) {
      setPreviewForm(!!config.previewForm);
    } else {
      if (previewForm) {
        setPreviewForm(false);
      }
    }
  }, [pathname]);

  return (
    <>
      {!(state === PENDING) && (
        <>
          <FormMeta previewForm={previewForm} />
          <FormPublish previewForm={previewForm} />
        </>
      )}
    </>
  );
};
