const getGeoLocationsAsString = (geoLocation, nested) => {
  const Locations: string[] = [];
  if (nested && geoLocation?.length) {
    for (let i = 0; i < geoLocation?.length; i++) {
      if (geoLocation[i]?.AreasOfOperation?.length) {
        for (let j = 0; j < geoLocation[i]?.AreasOfOperation?.length; j++) {
          Locations.push(geoLocation[i]?.AreasOfOperation[j]?.City);
        }
      }
    }
    return Locations.join(', ');
  } else if (!nested && geoLocation?.length) {
    for (let i = 0; i < geoLocation?.length; i++) {
      Locations.push(geoLocation[i]?.City);
    }
    return Locations;
  }
  return '-';
};

export default getGeoLocationsAsString;
