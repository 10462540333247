import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getTransactionParticipant } from 'store/selectors/drawers/transactionParticipant';
import { Avatar, FormattedPhone } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import NewMessage from '../NewMessage';
import ScrollWrapper from '../ScrollWrapper';
import PartTitle from './PartTitle';
import InfoItem from './InfoItem';

import Client from './Client';
import ThirdParty from './ThirdParty';
import Agent from './Agent';
import { TransactionUserRoleMap } from 'settings/constants/transaction';

import styles from './styles.module.scss';

const Content = (props) => {
  const { className } = props;
  const { participant } = useSelector(getTransactionParticipant);
  const { fullAccess } = useSelector(getTransactionAccessSelector);

  const name = `${participant?.FirstName} ${participant?.LastName}`;

  const getAdditionalInfo = () => {
    if (participant?.Agent) {
      return <Agent info={participant?.Agent} />;
    }

    if (participant?.Client) {
      return <Client info={participant.Client} />;
    }

    if (participant?.ThirdParty) {
      return <ThirdParty info={participant.ThirdParty} />;
    }

    return null;
  };

  return (
    <div className={classNames(styles.content, { [styles.shrink]: !fullAccess }, className)}>
      <div className={styles.head}>
        <Avatar
          className={styles.avatarWrapper}
          avatarClassName={styles.avatar}
          src={participant?.AvatarUrl}
          placeholder={
            name?.trim() ? (
              <div className={styles.avatarPlaceholder}>{convertNameToAvatarPlaceholder(name)}</div>
            ) : undefined
          }
        />
        <div testid="name" className={styles.name}>
          {name}
        </div>
        <div testid="title" className={styles.role}>
          {TransactionUserRoleMap[participant?.TransactionRole?.Title] ||
            participant?.TransactionRole?.Title}
        </div>
        <NewMessage className={styles.newMessage} participant={participant} />
      </div>
      <ScrollWrapper className={classNames({ [styles.footerOff]: !fullAccess })}>
        <div className={styles.contactInfo}>
          {/* <PartTitle>Contact Info</PartTitle> */}
          {participant?.Phones?.[0]?.PhoneNumber && (
            <InfoItem
              label="Phone"
              value={
                <FormattedPhone className={styles.phone}>
                  {participant?.Phones?.[0]?.PhoneNumber}
                </FormattedPhone>
              }
            />
          )}
          {participant?.Email && (
            <InfoItem
              label="Email"
              value={
                <a className={styles.link} href={`mailto:${participant?.Email}`}>
                  {participant?.Email}
                </a>
              }
            />
          )}
          {getAdditionalInfo()}
        </div>
      </ScrollWrapper>
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
};

Content.defaultProps = {
  className: '',
};

export default Content;
