import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Placeholder } from '..';

import styles from './styles.module.scss';

const Value = ({ value, postfix, className, testid }) => (
  <p testid={testid} className={classNames(styles.value, className)}>
    {value ? `${value} ${postfix}` : <Placeholder />}
  </p>
);

Value.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  postfix: PropTypes.string,
  testid: PropTypes.string,
};

Value.defaultProps = {
  className: '',
  value: '',
  postfix: '',
  testid: undefined,
};

export default Value;
