import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { tableColumns } from './tableColumns';
import { CollapsibleTable, WorkshopTableWrapper } from 'pages/Workshop/common';

import { OfferStatus } from 'types';

import {
  getOfferAggregateList,
  getOfferIsArchiveSelector,
  getOfferFiltersAppliedSelector,
} from 'store/selectors/offerAggregate';
import { link } from 'settings/navigation/link';
import { Wrapper } from 'components';
import { LocationService } from 'services';

import styles from './styles.module.scss';

interface OffersTableProps {
  isTransactionRoom?: boolean;
  onRowClick?: Function;
  className?: string;
  offerAggregate: any[];
  showSelection?: boolean;
  setOffersSelected?: Function;
}

export const OffersTable = ({
  isTransactionRoom = false,
  onRowClick,
  className,
  offerAggregate,
  showSelection,
  setOffersSelected,
}: OffersTableProps) => {
  const { isPending } = useSelector(getOfferAggregateList);
  const { isArchive } = useSelector(getOfferIsArchiveSelector);
  const { isFiltersApplied } = useSelector(getOfferFiltersAppliedSelector);
  const history = useHistory();

  const locationSrv = new LocationService();
  const location = useLocation();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  const redirectToOffers = (data) => {
    return history.push({
      pathname: link.toTransactionOffers(data?.PropertyId),
      state: {
        offerIterationUUID: data?.LatestOfferIteration?.UUID,
        triggerOfferDetail: true,
      },
    });
  };

  function newOffers() {
    return offerAggregate.filter((item) => !item?.LatestOfferIteration?.IsViewed);
  }

  function pendingOffers() {
    return offerAggregate.filter(
      (item) => item.OfferStatus === OfferStatus.Pending && item?.LatestOfferIteration?.IsViewed,
    );
  }

  function counterReceivedOffers() {
    return offerAggregate.filter(
      (item) =>
        item.OfferStatus === OfferStatus.CounterReceived && item?.LatestOfferIteration?.IsViewed,
    );
  }

  function counterSentOffers() {
    return offerAggregate.filter(
      (item) =>
        item.OfferStatus === OfferStatus.CounterSent && item?.LatestOfferIteration?.IsViewed,
    );
  }

  function acceptedOffers() {
    return offerAggregate.filter(
      (item) => item.OfferStatus === OfferStatus.Accepted && item?.LatestOfferIteration?.IsViewed,
    );
  }

  function rejectedOffers() {
    return offerAggregate
      .filter(
        (item) => item.OfferStatus === OfferStatus.Rejected && item?.LatestOfferIteration?.IsViewed,
      )
      .map((item) => ({ ...item, OfferExpiryEndDate: item?.LatestOfferIteration?.RejectedOn })); // OfferExpiryEndDate is assigned RejectedOn value to show in the table column under Rejected column, see tableColumns.tsx line 325 for more details
  }

  function expiredOffers() {
    return offerAggregate.filter(
      (item) => item.OfferStatus === OfferStatus.Expired && item?.LatestOfferIteration?.IsViewed,
    );
  }

  function withdrawnOffers() {
    return offerAggregate.filter(
      (item) => item.OfferStatus === OfferStatus.Withdrawn && item?.LatestOfferIteration?.IsViewed,
    );
  }

  function cancelledOffers() {
    return offerAggregate.filter(
      (item) => item.OfferStatus === OfferStatus.Cancelled && item?.LatestOfferIteration?.IsViewed,
    );
  }

  const tableProps = {
    columns: tableColumns(isTransactionRoom, showSelection, setOffersSelected),
    onRow: (record) => {
      return {
        onClick: (event) => {
          if (isTransactionRoom && onRowClick) onRowClick(record);
          else redirectToOffers(record);
        },
      };
    },
    tableClassName: styles.OffersTable,
  };

  const getOfferTables = () => {
    if (query.show === 'New')
      return <CollapsibleTable title="New" dataSource={newOffers()} {...tableProps} />;
    else if (query.status === OfferStatus.Pending)
      return <CollapsibleTable title="Pending" dataSource={pendingOffers()} {...tableProps} />;
    return (
      <>
        <CollapsibleTable title="New" dataSource={newOffers()} {...tableProps} />
        <CollapsibleTable title="Pending" dataSource={pendingOffers()} {...tableProps} />
        <CollapsibleTable
          title="Counter Received"
          dataSource={counterReceivedOffers()}
          {...tableProps}
        />
        <CollapsibleTable title="Counter Sent" dataSource={counterSentOffers()} {...tableProps} />
      </>
    );
  };

  const getArchivedOfferTables = () => {
    return (
      <>
        <CollapsibleTable
          title="Accepted"
          defaultCollapse={!isTransactionRoom}
          dataSource={acceptedOffers()}
          {...tableProps}
        />
        <CollapsibleTable
          title="Rejected"
          defaultCollapse={!isTransactionRoom}
          dataSource={rejectedOffers()}
          {...tableProps}
          columns={tableColumns(isTransactionRoom, showSelection, setOffersSelected, true)}
        />
        <CollapsibleTable
          title="Expired"
          defaultCollapse={!isTransactionRoom}
          dataSource={expiredOffers()}
          {...tableProps}
        />
        <CollapsibleTable
          title="Withdrawn"
          dataSource={withdrawnOffers()}
          defaultCollapse={!isTransactionRoom}
          {...tableProps}
        />
        <CollapsibleTable
          title="Cancelled"
          dataSource={cancelledOffers()}
          defaultCollapse={!isTransactionRoom}
          {...tableProps}
        />
      </>
    );
  };
  return (
    <Wrapper isPending={isPending}>
      <div className={classNames(styles.OffersTableWrapper, className)}>
        {offerAggregate && (
          <div>
            {isFiltersApplied ? (
              <WorkshopTableWrapper data={[offerAggregate]} emptyText="No Offers.">
                <CollapsibleTable
                  title={`${offerAggregate.length} Offers`}
                  dataSource={offerAggregate}
                  disableCollapse
                  {...tableProps}
                />
              </WorkshopTableWrapper>
            ) : isArchive ? (
              <WorkshopTableWrapper
                data={[
                  acceptedOffers(),
                  rejectedOffers(),
                  expiredOffers(),
                  cancelledOffers(),
                  withdrawnOffers(),
                ]}
                emptyText="No Offers."
              >
                {getArchivedOfferTables()}
              </WorkshopTableWrapper>
            ) : (
              <WorkshopTableWrapper
                data={[newOffers(), pendingOffers(), counterReceivedOffers(), counterSentOffers()]}
                emptyText="No Offers."
              >
                {isTransactionRoom ? (
                  <CollapsibleTable
                    title=""
                    disableCollapse
                    dataSource={[
                      ...newOffers(),
                      ...pendingOffers(),
                      ...counterReceivedOffers(),
                      ...counterSentOffers(),
                    ]}
                    {...tableProps}
                  />
                ) : (
                  getOfferTables()
                )}
              </WorkshopTableWrapper>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};
