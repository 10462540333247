import { createAction } from 'redux-actions';

export const requestGetNotificationTemplatesDashboardAction = createAction(
  'REQUEST/GET_NOTIFICATION_TEMPLATES_DASHBOARD',
);
export const requestGetNotificationTemplateDashboardAction = createAction(
  'REQUEST/GET_NOTIFICATION_TEMPLATE_DASHBOARD',
);
export const requestPutNotificationTemplateDashboardAction = createAction(
  'REQUEST/PUT_NOTIFICATION_TEMPLATE_DASHBOARD',
);
