import React from 'react';

export const RocketIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <g opacity="0.7">
        <path
          d="M26.7987 25.2743C33.629 18.444 34.7225 8.72271 33.5721 7.57234C32.4217 6.42196 22.7004 7.51542 15.8701 14.3458C9.03973 21.1761 8.50049 27.4672 8.50049 27.4672L13.6772 32.6439C13.6772 32.6439 19.9683 32.1047 26.7987 25.2743Z"
          fill="#FB913A"
        />
        <path
          d="M18.645 21.4644L4.6858 21.4644C3.89234 21.4644 3.49497 22.4237 4.05604 22.9847L18.0153 36.944C18.5763 37.505 19.5356 37.1077 19.5356 36.3142L19.5356 22.355C19.5356 21.8631 19.1369 21.4644 18.645 21.4644Z"
          fill="#FF576D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.587 30.716V22.4072C19.587 21.9153 19.1883 21.5166 18.6964 21.5166H10.6059C8.88905 24.8059 8.58833 27.1782 8.55542 27.485L13.7625 32.692C14.0648 32.6596 16.3726 32.3672 19.587 30.716Z"
          fill="#E4364D"
        />
      </g>
    </svg>
  );
};
