import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { setComparesEffect } from 'store/effects';
import { ImgSlider, Checkbox } from 'components';
import { getSearchCriteriaFormattedString, extractOrderedPhotos } from 'helpers';
import { getMultipleModSelector, getComparesSelector } from 'store/selectors/feed';
import Info from 'pages/Properties/Feed/Properties/Property/Info';
import Match from 'pages/Properties/Feed/Properties/Property/Match';
import Comment from 'pages/Properties/Feed/Properties/Property/Comment';
import RecommendedBy from 'pages/Properties/Feed/Properties/Property/RecommendedBy';
import { LocationService } from 'services';
import { Tooltip } from 'components-antd';
import { TooltipIcon } from 'components/Icons';
import { resetKitchenAvgSizeAction, setKitchenAvgSizeAction } from 'store/actions/propertyFeatures';
import { openNewTab } from 'services/newTab';
import { routes } from 'settings/navigation/routes';
import { getThreadsPerPropertyIdSelector } from 'store/selectors/sockets/threads';
import Icon from 'pages/Properties/Feed/Icons';
import { Wrapper as PendingWrapper } from 'components';
import { toggleFavoriteListingDetailEffect } from 'store/effects/listingDetail';
import { getCurrentContextSelector } from 'store/selectors/context';
import styles from './styles.module.scss';
import { isEmpty } from 'lodash-es';

const Property = forwardRef((props, ref) => {
  // prettier-ignore
  const threadsPerPropertyId = useSelector(getThreadsPerPropertyIdSelector);
  const { className, style, ...propertyProps } = props;
  const {
    Id,
    PhotoUrls,
    SellingPrice,
    NumBeds,
    NumBathsTotal,
    SquareFeet,
    IsAuction,
    SearchInstanceId,
    SearchInstanceName,
    SearchCriterias,
    CustomKitchenFeatures,
    Media,
    PhotoCount,
  } = propertyProps || {};
  const OrderedPhotos = extractOrderedPhotos(Media, PhotoCount);
  const ImagesToSend = !isEmpty(OrderedPhotos) ? OrderedPhotos : PhotoUrls;
  const { Address, ActivityStatus } = propertyProps || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const multiple = useSelector(getMultipleModSelector);
  const compares = useSelector(getComparesSelector);
  const context = useSelector(getCurrentContextSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState();
  const [isFavorite, setIsFavorite] = useState(propertyProps?.IsFavorite);

  const getFeatureInfoText = () => (
    <div className={classNames(styles.featureWrapper, styles.feature)}>
      {NumBeds ? (
        <span testid="property_beds" className={classNames(styles.accent, styles.amount)}>
          {NumBeds}
        </span>
      ) : null}
      <span>beds</span>
      <span className={styles.separator} />
      <span testid="property_baths" className={classNames(styles.accent, styles.amount)}>
        {NumBathsTotal}
      </span>
      <span>baths</span>
      <span className={styles.separator} />
      <NumberFormat
        displayType="text"
        thousandSeparator
        value={SquareFeet}
        renderText={(val) => (
          <span testid="property_feet" className={classNames(styles.accent, styles.amount)}>
            {val}
          </span>
        )}
      />
      <span>sqft</span>
    </div>
  );

  const getStreetInfoText = () =>
    Address?.Line1 ? (
      <div testid="property_address" className={(styles.featureWrapper, styles.line1)}>
        {Address?.Line1}
      </div>
    ) : (
      <></>
    );

  const getLocationInfoText = () => (
    <div className={classNames(styles.location, styles.street)}>
      <span testid="property_location">{`${Address?.City}, ${Address?.State}, ${Address?.Zip}`}</span>
    </div>
  );

  const isPropertyInCompare = (propertyId) => {
    const isChecked = compares.data.findIndex((compare) => compare.Id === propertyId) !== -1;
    setIsChecked(!isChecked);
    return isChecked;
  };

  const onCheckHandler = () => {
    const propertyId = Id;
    if (!isPropertyInCompare(propertyId)) {
      dispatch(setComparesEffect([...compares.data, propertyProps]));
    } else {
      const newCompares = compares.data.filter((property) => property.Id !== propertyId);
      dispatch(setComparesEffect(newCompares));
    }
  };

  const onClickHandlerFavortie = (e) => {
    e.stopPropagation();
    const newActiveValue = !isFavorite;
    const cfg = {
      isFavorite: newActiveValue,
      id: propertyProps?.Id,
      contextKey: context.ContextKey,
    };
    setIsLoading(true);
    if (!isLoading) {
      dispatch(
        toggleFavoriteListingDetailEffect(cfg, { IsFavorite: newActiveValue }, () => {
          setIsLoading(false);
          setIsFavorite(newActiveValue);
        }),
      );
    }
  };

  const favorite = () => {
    return (
      <div
        data-addedtofavoritesby={JSON.stringify(propertyProps?.AddedToFavoritesBy)}
        onClick={onClickHandlerFavortie}
        className={classNames(styles.favWrapper, styles.buyer, className)}
        testid="favorites"
      >
        <PendingWrapper
          className={styles.pendingWrapper}
          isPending={isLoading}
          loaderClassName={styles.loader}
        >
          <Icon testid="favorites_icon" variant={Icon.FAVORITE} active={!!isFavorite} />
        </PendingWrapper>
      </div>
    );
  };

  const getMetaInfo = () => {
    if (multiple) {
      return (
        <Checkbox
          checkboxClassName={styles.checkbox}
          value={Id}
          onChange={onCheckHandler}
          checked={isChecked}
          testid="multiple_checkbox"
          hasOuterClick={true}
        />
      );
    }

    return (
      <>
        <Match propertyInfo={propertyProps} />
        {
          <div className={styles.comFavMetaInfo}>
            <Comment propertyInfo={propertyProps} threadsPerPropertyId={threadsPerPropertyId} />
            {favorite()}
          </div>
        }
      </>
    );
  };
  const locationService = new LocationService();
  const location = useLocation();
  const query = locationService.setLocation(location).getQuery();

  const storeCustomFeatures = () => {
    dispatch(resetKitchenAvgSizeAction());

    if (CustomKitchenFeatures?.IsBiggerThanAverage) {
      dispatch(setKitchenAvgSizeAction({ kitchenAvgSize: CustomKitchenFeatures?.AvgSize }));
    }
  };

  const propertyDetailLink = (id, activeTab, searchId) => {
    let route = routes.feedListingDetail.replace(':id', `${id}`);

    if (activeTab && searchId) {
      route = `${route}?activeTab=${activeTab}&searchId=${searchId}`;
    } else if (activeTab) {
      route = `${route}?activeTab=${activeTab}`;
    } else if (searchId) {
      route = `${route}?searchId=${searchId}`;
    }
    return route;
  };

  const redirectToDetails = (e) => {
    storeCustomFeatures();
    const path = propertyDetailLink(Id, query?.activeTab, SearchInstanceId);

    e.preventDefault();
    if (e.ctrlKey || e.metaKey) {
      openNewTab(path);
    } else if (!multiple) {
      history.push(path);
    }
  };

  return (
    <div
      testid="feed_property"
      ref={ref}
      className={classNames(styles.propertyWrapper, className)}
      style={style}
      onClick={redirectToDetails}
    >
      <Link className={styles.photos}>
        <ImgSlider className={styles.photosHolder} images={ImagesToSend} />
      </Link>
      <div className={styles.inner}>
        <div className={styles.info}>
          {Number(SellingPrice) > 0 ? (
            <NumberFormat
              displayType="text"
              thousandSeparator
              value={SellingPrice}
              prefix="$"
              renderText={(val) => (
                <span testid="property_price" className={styles.price}>
                  {val}
                  {IsAuction && <span className={styles.auction}>(auction)</span>}
                  {SearchInstanceName && (
                    <Tooltip
                      title={
                        <div>
                          <div>{SearchInstanceName}</div>
                          <div>{getSearchCriteriaFormattedString(SearchCriterias)?.fullInfo}</div>
                        </div>
                      }
                      content={<div className={styles.tooltip}>content</div>}
                      getPopupContainer={(trigger) => trigger}
                    >
                      <span>
                        <TooltipIcon className={styles.tooltip} />
                      </span>
                    </Tooltip>
                  )}
                </span>
              )}
            />
          ) : (
            <div className={styles.price}>Price Unavailable</div>
          )}
          <Info text={getFeatureInfoText()} />
          <Info text={getStreetInfoText()} />
          <Info text={getLocationInfoText()} />
          <RecommendedBy recommendedBy={propertyProps?.RecommendedBy} />
        </div>
        <div className={styles.metaInfo}>{getMetaInfo()}</div>
      </div>
    </div>
  );
});

Property.propTypes = {
  className: PropTypes.string,
  Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  PhotoUrls: PropTypes.arrayOf(PropTypes.string),
  SellingPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  SquareFeet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  NumBeds: PropTypes.number,
  NumBathsTotal: PropTypes.number,
  Address: PropTypes.shape({
    Line1: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
    Zip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  ActivityStatus: PropTypes.string,
  MatchScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape({}),
  isClient: PropTypes.bool,
  threadsPerPropertyId: PropTypes.shape({}),
};

Property.defaultProps = {
  className: '',
  PhotoUrls: [],
  NumBeds: null,
  NumBathsTotal: null,
  SellingPrice: '',
  SquareFeet: '',
  ActivityStatus: '',
  style: {},
  MatchScore: null,
  isClient: undefined,
};

export default Property;
