import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { LocationService } from 'services';
import { ArrowRight } from '../Icons';

import styles from './styles.module.scss';

const locationSrv = new LocationService();

const Breadcrumbs = (props) => {
  const { list, className, onClick, isPending } = props;
  const location = useLocation();
  locationSrv.setLocation(location);

  if (isPending) return null;

  return (
    <div testid="breadcrumbs" className={classNames(styles.breadcrumbs, className)}>
      {list.map((item, index) => (
        <div key={item?.link || index} className={styles.item}>
          {index > 0 && <ArrowRight className={styles.icon} />}
          <NavLink
            key={item?.link}
            onClick={(event) => onClick(event, item)}
            exact
            className={styles.link}
            activeClassName={classNames({ [styles.active]: list?.length - 1 === index })}
            replace={item?.link === locationSrv.getUrlWithQuery()}
            to={item?.link}
            testid="breadcrumb_link"
          >
            {item?.label}
          </NavLink>
        </div>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func,
  isPending: PropTypes.bool,
};

Breadcrumbs.defaultProps = {
  className: '',
  onClick: () => {},
  isPending: false,
};

export default Breadcrumbs;
