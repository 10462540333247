export const formStatusForDraft = [
  { value: 'Draft', key: 'Draft' },
  { value: 'Active', key: 'Active' },
  { value: 'InActive', key: 'Inactive' },
  { value: 'Delete', key: 'Delete' },
];
export const formStatusForActive = [
  { value: 'Active', key: 'Active' },
  { value: 'InActive', key: 'Inactive' },
];
export const formStatusForInActive = [
  { value: 'InActive', key: 'Inactive' },
  { value: 'Active', key: 'Active' },
];

export const strokeColors = {
  Active: '#27ae60',
  Draft: '#7a7a7a',
  Inactive: '#7a7a7a',
};
