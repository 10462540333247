import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useDispatch } from 'react-redux';
import { Checkbox, Input, RadioOptions } from 'components';
import { updateNotificationsSettingsEffect } from 'store/effects';

import styles from './styles.module.scss';

const optionsIds = {
  all: 0,
  custom: 'custom',
};

const Matches = (props) => {
  const { className, labelClassName, groupType, type, variant, value } = props;

  const dispatch = useDispatch();

  const [matchesOptionsValue, setMatchesOptionsValue] = useState(
    value > 0 ? optionsIds.custom : value,
  );
  const [customInputValue, setCustomInputValue] = useState(value || '');
  const [isPending, setIsPending] = useState(false);

  const onFocusInputField = () => {
    setMatchesOptionsValue(optionsIds.custom);
  };

  const onCustomInputChange = (e, val) => {
    setCustomInputValue(val);
  };

  const getValue = (val) => {
    if (val === optionsIds.all) {
      return 0;
    }

    if (val === optionsIds.custom) {
      return +customInputValue;
    }
  };

  const onChangeHandler = (val) => {
    setIsPending(true);
    const config = { groupType, type, variant, value: getValue(val) };
    dispatch(
      updateNotificationsSettingsEffect(config, {}, (err) => {
        if (!err) {
          setMatchesOptionsValue(val);
        }

        if (val === optionsIds.all) {
          setCustomInputValue('');
        }
        setIsPending(false);
      }),
    );
  };

  const onSaveValue = () => {
    if (
      matchesOptionsValue === optionsIds.custom &&
      (!customInputValue || customInputValue === '0')
    ) {
      setMatchesOptionsValue(optionsIds.all);
      setCustomInputValue('');
    } else {
      onChangeHandler(matchesOptionsValue);
    }
  };

  const onKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      onSaveValue();
    }
  };

  const getCustomLabelScore = () => (
    <div className={styles.customMatchScore}>
      <span>Matches</span>
      <Input
        maxNumber={100}
        minNumber={0}
        thousandSeparator={false}
        onFocus={onFocusInputField}
        onBlur={onSaveValue}
        isNumberFormat
        className={styles.input}
        variant={Input.LIGHT_FULL}
        onChange={onCustomInputChange}
        value={customInputValue}
        onKeyPress={onKeyPressHandler}
        placeholder="75%"
        testid="custom_matches_input"
      />
      <span>or greater</span>
    </div>
  );

  const getOptions = () => [
    { id: optionsIds.all, value: optionsIds.all, label: 'All matches' },
    { id: optionsIds.custom, value: optionsIds.custom, label: getCustomLabelScore() },
  ];

  return (
    <div className={classNames(styles.sendingTime, className)}>
      <div className={labelClassName}>Matches</div>
      <div className={styles.values}>
        <RadioOptions
          labelTextClassName={styles.labelText}
          direction={Checkbox.DIRECTION_LEFT}
          className={styles.radioOptions}
          optionClassName={styles.radioOption}
          options={getOptions()}
          name={type}
          onChange={onChangeHandler}
          value={matchesOptionsValue}
          isPending={isPending}
          testid="matches_option"
        />
      </div>
    </div>
  );
};

Matches.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  groupType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

Matches.defaultProps = {
  className: '',
  labelClassName: '',
};

export default Matches;
