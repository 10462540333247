import { addTransactionMilestone } from 'api/milestoneAggregate';
import { getCreateMilestonePayload, getEditMileastonePayload } from '../payload';
import { getMilestoneByName } from '../helpers';

export const createMilestone = async (payload) => {
  let transactionName = payload?.transaction;
  let transaction = {
    Id: 818,
    EffectiveDate: '2024-02-01T00:00:00.000Z',
    ListingExpireDate: '2024-02-01T00:00:00.000Z',
    ClosingDate: '2024-02-28T00:00:00.000Z',
    Price: 22222,
    RepresentingRoles: ['Seller'],
    Status: 'Under contract',
    Property: {
      Address: {
        City: 'New York',
        State: 'NY',
        Zip: '10038',
        Line1: '123 William St',
        ProviderPlaceId: 'ChIJIaGbBBhawokRUmbgNsUmr-s',
        PlaceName: '123 William Street, New York, NY, USA',
      },
    },
    IsArchived: false,
    Milestones: [
      {
        EditCount: 0,
        Id: 1106,
        Title: 'Show',
        DueDate: '2024-02-28T00:00:00.000Z',
        IsPrivate: false,
        TransactionId: 818,
        CreatedDate: '2024-02-27T09:32:48.473Z',
        UpdatedDate: '2024-05-31T17:17:00.530Z',
        CreatedBy: null,
        UpdatedBy: null,
        OverdueNotifiedTime: '2024-02-28T19:23:22.111Z',
        UpcomingNotifiedTime: null,
        DeletedBy: null,
        DateDeleted: null,
        IsKeyDate: false,
        DateCompleted: null,
        StatusId: 2,
        LastEditedBy: null,
        DateLastEdited: null,
        DueDateWithTime: '2024-02-28T19:00:00.000Z',
        Status: 'Complete',
      },
      {
        EditCount: 0,
        Id: 3974,
        Title: 'Contract date',
        DueDate: '2024-02-01T00:00:00.000Z',
        IsPrivate: false,
        TransactionId: 818,
        CreatedDate: '2024-04-25T10:17:43.189Z',
        UpdatedDate: '2024-05-31T17:17:00.530Z',
        CreatedBy: null,
        UpdatedBy: null,
        OverdueNotifiedTime: null,
        UpcomingNotifiedTime: null,
        DeletedBy: null,
        DateDeleted: null,
        IsKeyDate: true,
        DateCompleted: null,
        StatusId: 1,
        LastEditedBy: null,
        DateLastEdited: null,
        DueDateWithTime: '2024-02-01T19:00:00.000Z',
        Status: 'Incomplete',
      },
      {
        EditCount: 0,
        Id: 3975,
        Title: 'Listing expires',
        DueDate: '2024-02-01T00:00:00.000Z',
        IsPrivate: false,
        TransactionId: 818,
        CreatedDate: '2024-04-25T10:17:43.189Z',
        UpdatedDate: '2024-05-31T17:17:00.530Z',
        CreatedBy: null,
        UpdatedBy: null,
        OverdueNotifiedTime: null,
        UpcomingNotifiedTime: null,
        DeletedBy: null,
        DateDeleted: null,
        IsKeyDate: true,
        DateCompleted: null,
        StatusId: 1,
        LastEditedBy: null,
        DateLastEdited: null,
        DueDateWithTime: '2024-02-01T19:00:00.000Z',
        Status: 'Incomplete',
      },
      {
        EditCount: 0,
        Id: 3976,
        Title: 'Closing date',
        DueDate: '2024-06-13T00:00:00.000Z',
        IsPrivate: false,
        TransactionId: 818,
        CreatedDate: '2024-04-25T10:17:43.189Z',
        UpdatedDate: '2024-06-04T09:50:45.213Z',
        CreatedBy: null,
        UpdatedBy: null,
        OverdueNotifiedTime: null,
        UpcomingNotifiedTime: null,
        DeletedBy: null,
        DateDeleted: null,
        IsKeyDate: true,
        DateCompleted: null,
        StatusId: 1,
        LastEditedBy: null,
        DateLastEdited: null,
        DueDateWithTime: '2024-02-28T19:00:00.000Z',
        Status: 'Incomplete',
      },
    ],
    CreatedDate: '2024-02-27T09:24:07.174Z',
    NotesThreadId: null,
    Notes: [],
    DateControls: {
      T: '2024-02-27T09:31:31.883Z',
      CD: '2024-02-01T19:00:00.000Z',
      CL: '2024-02-28T19:00:00.000Z',
    },
    TransactionClients: null,
    IsTransactionAdminOrOwner: false,
    IsProject: false,
    Name: null,
    TransactionCategory: null,
    OnHoldDate: null,
    FinancingType: null,
    PropertyType: 'ResidentialLease',
    YearBuilt: null,
    ClosePrice: 789456,
    CloseDate: '2024-06-13T00:00:00.000Z',
    AgentsOrOwners: null,
    Coordinators: null,
    OtherFees: [],
    ReferralFee: null,
    LeadSource: null,
    PropertyTransactionKeyDatesPrices: {
      Id: 1575,
      PropertyTransactionId: 818,
      ListingPrice: 22222,
      UnderContractPrice: null,
      ClosePrice: 789456,
      ListingEffectiveDate: null,
      PurchaseEffectiveDate: null,
      ListingExpireDate: '2024-02-01T00:00:00.000Z',
      UnderContractCloseDate: '2024-02-28T00:00:00.000Z',
      CloseDate: '2024-06-13T00:00:00.000Z',
      UpdatedBy: null,
      UpdatedDate: '2024-05-20T10:25:34.955Z',
      ExpiredDate: null,
      CanceledDate: null,
      PropertyTypeId: 10,
      ChangedToUnderContractDate: null,
      ListingEffectiveDateWithTime: null,
      PurchaseEffectiveDateWithTime: null,
      ListingExpireDateWithTime: '2024-02-01T19:00:00.000Z',
      UnderContractCloseDateWithTime: '2024-02-28T19:00:00.000Z',
      CloseDateWithTime: null,
      ExpiredDateWithTime: null,
      CanceledDateWithTime: null,
    },
    Creator: {
      Id: 2,
      FirstName: 'MosaikAgent',
      LastName: 'Agent',
      Email: 'mosaikagent@yopmail.com',
      Roles: ['Agent'],
      AvatarUrl: null,
    },
    CreatorTeamId: 46,
    BuyerCommission: null,
    SellerCommission: null,
    TotalCommission: null,
    Participants: [
      {
        Id: 2,
        FirstName: 'MosaikAgent',
        LastName: 'Agent',
        AvatarUrl: null,
        Email: 'mosaikagent@yopmail.com',
        Role: {
          Title: 'TransactionCoordinator',
          Id: 7,
        },
        TransactionAccess: true,
        IsClient: false,
        InvitationStatus: null,
      },
      {
        Id: 721,
        FirstName: 'nimra+client@mosaik.io',
        LastName: '',
        AvatarUrl: null,
        Email: 'nimra+client@mosaik.io',
        Role: {
          Title: 'Seller',
          Id: 2,
        },
        TransactionAccess: false,
        IsClient: true,
        InvitationStatus: 'Pending',
      },
      {
        Id: 1395,
        FirstName: 'Client 2',
        LastName: 'Akmal',
        AvatarUrl: null,
        Email: 'usman+31@mosaik.io',
        Role: {
          Title: 'Seller',
          Id: 2,
        },
        TransactionAccess: false,
        IsClient: true,
        InvitationStatus: 'Accepted',
      },
      {
        Id: 963,
        FirstName: 'Zoya',
        LastName: 'Client 01',
        AvatarUrl: null,
        Email: 'zoya+client01@mosaik.io',
        Role: {
          Title: 'Buyer',
          Id: 1,
        },
        TransactionAccess: false,
        IsClient: true,
        InvitationStatus: 'Accepted',
      },
    ],
    Showing: {
      Id: '198',
      UUID: '940da6bf-46dd-485e-bad3-e7e0582bfa1e',
      AgentId: 2,
      Status: 'Active',
      PropertyTransactionId: 818,
      Indefinitely: true,
      LinkActiveStartDate: '2024-06-04T20:24:47.485Z',
      LinkActiveDays: null,
      LinkActiveEndDate: null,
      InvolveSellerAgent: false,
      InvolveSeller: false,
      FYIList: [],
      DelegateList: [],
      ConfirmedBookingNotification: {
        Self: true,
        Seller: false,
        Delegates: false,
        FYIList: [],
        InvolveSellerAgent: false,
        InvolveSeller: false,
        FYI: false,
        DelegateList: [],
      },
      Timezone: 'Asia/Karachi',
      ShowingDuration: 2,
      AddBufferBetweenShowings: false,
      BufferTime: null,
      DisplayBookingPageNotes: false,
      BookingPageNotes: null,
      SendAppointmentConfirmationNotes: false,
      AppointmentConfirmationNotes: null,
      RequestFeedback: false,
      AutomaticallySendFeedback: false,
      RequireDocumentPreApproval: false,
      OtherRequiredDocuments: [],
      EnableShowingLink: true,
      ShowingLink: 'https://qa.mosaik.io/showing/940da6bf-46dd-485e-bad3-e7e0582bfa1e',
      CreatedDate: '2024-06-04T20:24:49.896Z',
      UpdatedDate: '2024-06-04T20:24:49.896Z',
      CreatedBy: '2',
      UpdatedBy: null,
      ShowingAvailability: [
        {
          Id: 213,
          ShowingId: 198,
          DaysOfWeek: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          StartTime: '2024-06-04T20:00:00.000Z',
          EndTime: '2024-06-05T18:00:00.000Z',
          MinimumNotice: null,
          AutomaticApproval: false,
          SequentialApproval: false,
          ApprovalUsers: [
            {
              Role: 'SellerAgent',
              RequireApproval: true,
              Sequence: 1,
              UserId: 2,
              Email: 'mosaikagent@yopmail.com',
              Name: 'MosaikAgent Agent',
            },
            {
              Role: 'Seller',
              RequireApproval: true,
              Sequence: 2,
              UserId: 721,
              Email: 'nimra+client@mosaik.io',
              Name: 'nimra+client@mosaik.io ',
            },
          ],
          CreatedDate: '2024-06-04T20:24:49.896Z',
          UpdatedDate: '2024-06-04T20:24:49.896Z',
          CreatedBy: null,
          UpdatedBy: null,
          DateDeleted: null,
          DeletedBy: null,
        },
      ],
    },
    OfferLink: {
      Id: '133',
      UUID: '92cc0c96-a670-45c4-84e6-64d9d65773f9',
      SellerAgentId: 2,
      Status: 'Active',
      PropertyTransactionId: 818,
      Indefinitely: false,
      LinkActiveStartDate: '2024-06-04T00:00:00.000Z',
      LinkActiveDays: null,
      LinkActiveEndDate: '2024-06-13T23:59:59.999Z',
      DisplayDeadlineOnPortal: false,
      DeadlineDate: null,
      DeadlineTime: null,
      Timezone: 'Asia/Karachi',
      RequiredDocuments: [
        {
          name: 'Offer',
          required: false,
          selected: false,
          format: '*',
        },
        {
          name: ' Pre-Approval/Proof of Funds',
          required: false,
          selected: false,
          format: '*',
        },
      ],
      DisplaySubmissionPageNotes: false,
      SubmissionPageNotes: '',
      SendOfferSubmissionNotes: false,
      OfferSubmissionNotes: '',
      AutomaticallySendOffersToSeller: false,
      EnableLink: true,
      Link: 'https://qa.mosaik.io/offer/form/92cc0c96-a670-45c4-84e6-64d9d65773f9',
      CreatedDate: '2024-02-27T09:25:27.177Z',
      UpdatedDate: '2024-06-04T12:02:18.866Z',
      CreatedBy: '2',
      UpdatedBy: '2',
      ListingAgentName: null,
      ListingAgentEmail: null,
      Offer: [],
      NotificationUser: [],
    },
    Tasks: [],
  };
  if (!transaction) {
    return {
      success: false,
      data: null,
      errMsgg: 'Transaction not found',
    };
  }
  const transactionId = transaction?.Id;
  const createMilestonePayload = getCreateMilestonePayload(payload);
  // Call create a task api
  const resp = await addTransactionMilestone({
    transactionId: transactionId,
    ...createMilestonePayload,
  });

  console.log('Create a milestone response => ', resp);
};

export const editMilestone = (payload) => {
  let transactionName = payload?.transaction;
  let transaction = {
    Id: 818,
    EffectiveDate: '2024-02-01T00:00:00.000Z',
    ListingExpireDate: '2024-02-01T00:00:00.000Z',
    ClosingDate: '2024-02-28T00:00:00.000Z',
    Price: 22222,
    RepresentingRoles: ['Seller'],
    Status: 'Under contract',
    Property: {
      Address: {
        City: 'New York',
        State: 'NY',
        Zip: '10038',
        Line1: '123 William St',
        ProviderPlaceId: 'ChIJIaGbBBhawokRUmbgNsUmr-s',
        PlaceName: '123 William Street, New York, NY, USA',
      },
    },
    IsArchived: false,
    Milestones: [
      {
        EditCount: 0,
        Id: 1106,
        Title: 'Show',
        DueDate: '2024-02-28T00:00:00.000Z',
        IsPrivate: false,
        TransactionId: 818,
        CreatedDate: '2024-02-27T09:32:48.473Z',
        UpdatedDate: '2024-05-31T17:17:00.530Z',
        CreatedBy: null,
        UpdatedBy: null,
        OverdueNotifiedTime: '2024-02-28T19:23:22.111Z',
        UpcomingNotifiedTime: null,
        DeletedBy: null,
        DateDeleted: null,
        IsKeyDate: false,
        DateCompleted: null,
        StatusId: 2,
        LastEditedBy: null,
        DateLastEdited: null,
        DueDateWithTime: '2024-02-28T19:00:00.000Z',
        Status: 'Complete',
      },
      {
        EditCount: 0,
        Id: 3974,
        Title: 'Contract date',
        DueDate: '2024-02-01T00:00:00.000Z',
        IsPrivate: false,
        TransactionId: 818,
        CreatedDate: '2024-04-25T10:17:43.189Z',
        UpdatedDate: '2024-05-31T17:17:00.530Z',
        CreatedBy: null,
        UpdatedBy: null,
        OverdueNotifiedTime: null,
        UpcomingNotifiedTime: null,
        DeletedBy: null,
        DateDeleted: null,
        IsKeyDate: true,
        DateCompleted: null,
        StatusId: 1,
        LastEditedBy: null,
        DateLastEdited: null,
        DueDateWithTime: '2024-02-01T19:00:00.000Z',
        Status: 'Incomplete',
      },
      {
        EditCount: 0,
        Id: 3975,
        Title: 'Listing expires',
        DueDate: '2024-02-01T00:00:00.000Z',
        IsPrivate: false,
        TransactionId: 818,
        CreatedDate: '2024-04-25T10:17:43.189Z',
        UpdatedDate: '2024-05-31T17:17:00.530Z',
        CreatedBy: null,
        UpdatedBy: null,
        OverdueNotifiedTime: null,
        UpcomingNotifiedTime: null,
        DeletedBy: null,
        DateDeleted: null,
        IsKeyDate: true,
        DateCompleted: null,
        StatusId: 1,
        LastEditedBy: null,
        DateLastEdited: null,
        DueDateWithTime: '2024-02-01T19:00:00.000Z',
        Status: 'Incomplete',
      },
      {
        EditCount: 0,
        Id: 3976,
        Title: 'Closing date',
        DueDate: '2024-06-13T00:00:00.000Z',
        IsPrivate: false,
        TransactionId: 818,
        CreatedDate: '2024-04-25T10:17:43.189Z',
        UpdatedDate: '2024-06-04T09:50:45.213Z',
        CreatedBy: null,
        UpdatedBy: null,
        OverdueNotifiedTime: null,
        UpcomingNotifiedTime: null,
        DeletedBy: null,
        DateDeleted: null,
        IsKeyDate: true,
        DateCompleted: null,
        StatusId: 1,
        LastEditedBy: null,
        DateLastEdited: null,
        DueDateWithTime: '2024-02-28T19:00:00.000Z',
        Status: 'Incomplete',
      },
    ],
    CreatedDate: '2024-02-27T09:24:07.174Z',
    NotesThreadId: null,
    Notes: [],
    DateControls: {
      T: '2024-02-27T09:31:31.883Z',
      CD: '2024-02-01T19:00:00.000Z',
      CL: '2024-02-28T19:00:00.000Z',
    },
    TransactionClients: null,
    IsTransactionAdminOrOwner: false,
    IsProject: false,
    Name: null,
    TransactionCategory: null,
    OnHoldDate: null,
    FinancingType: null,
    PropertyType: 'ResidentialLease',
    YearBuilt: null,
    ClosePrice: 789456,
    CloseDate: '2024-06-13T00:00:00.000Z',
    AgentsOrOwners: null,
    Coordinators: null,
    OtherFees: [],
    ReferralFee: null,
    LeadSource: null,
    PropertyTransactionKeyDatesPrices: {
      Id: 1575,
      PropertyTransactionId: 818,
      ListingPrice: 22222,
      UnderContractPrice: null,
      ClosePrice: 789456,
      ListingEffectiveDate: null,
      PurchaseEffectiveDate: null,
      ListingExpireDate: '2024-02-01T00:00:00.000Z',
      UnderContractCloseDate: '2024-02-28T00:00:00.000Z',
      CloseDate: '2024-06-13T00:00:00.000Z',
      UpdatedBy: null,
      UpdatedDate: '2024-05-20T10:25:34.955Z',
      ExpiredDate: null,
      CanceledDate: null,
      PropertyTypeId: 10,
      ChangedToUnderContractDate: null,
      ListingEffectiveDateWithTime: null,
      PurchaseEffectiveDateWithTime: null,
      ListingExpireDateWithTime: '2024-02-01T19:00:00.000Z',
      UnderContractCloseDateWithTime: '2024-02-28T19:00:00.000Z',
      CloseDateWithTime: null,
      ExpiredDateWithTime: null,
      CanceledDateWithTime: null,
    },
    Creator: {
      Id: 2,
      FirstName: 'MosaikAgent',
      LastName: 'Agent',
      Email: 'mosaikagent@yopmail.com',
      Roles: ['Agent'],
      AvatarUrl: null,
    },
    CreatorTeamId: 46,
    BuyerCommission: null,
    SellerCommission: null,
    TotalCommission: null,
    Participants: [
      {
        Id: 2,
        FirstName: 'MosaikAgent',
        LastName: 'Agent',
        AvatarUrl: null,
        Email: 'mosaikagent@yopmail.com',
        Role: {
          Title: 'TransactionCoordinator',
          Id: 7,
        },
        TransactionAccess: true,
        IsClient: false,
        InvitationStatus: null,
      },
      {
        Id: 721,
        FirstName: 'nimra+client@mosaik.io',
        LastName: '',
        AvatarUrl: null,
        Email: 'nimra+client@mosaik.io',
        Role: {
          Title: 'Seller',
          Id: 2,
        },
        TransactionAccess: false,
        IsClient: true,
        InvitationStatus: 'Pending',
      },
      {
        Id: 1395,
        FirstName: 'Client 2',
        LastName: 'Akmal',
        AvatarUrl: null,
        Email: 'usman+31@mosaik.io',
        Role: {
          Title: 'Seller',
          Id: 2,
        },
        TransactionAccess: false,
        IsClient: true,
        InvitationStatus: 'Accepted',
      },
      {
        Id: 963,
        FirstName: 'Zoya',
        LastName: 'Client 01',
        AvatarUrl: null,
        Email: 'zoya+client01@mosaik.io',
        Role: {
          Title: 'Buyer',
          Id: 1,
        },
        TransactionAccess: false,
        IsClient: true,
        InvitationStatus: 'Accepted',
      },
    ],
    Showing: {
      Id: '198',
      UUID: '940da6bf-46dd-485e-bad3-e7e0582bfa1e',
      AgentId: 2,
      Status: 'Active',
      PropertyTransactionId: 818,
      Indefinitely: true,
      LinkActiveStartDate: '2024-06-04T20:24:47.485Z',
      LinkActiveDays: null,
      LinkActiveEndDate: null,
      InvolveSellerAgent: false,
      InvolveSeller: false,
      FYIList: [],
      DelegateList: [],
      ConfirmedBookingNotification: {
        Self: true,
        Seller: false,
        Delegates: false,
        FYIList: [],
        InvolveSellerAgent: false,
        InvolveSeller: false,
        FYI: false,
        DelegateList: [],
      },
      Timezone: 'Asia/Karachi',
      ShowingDuration: 2,
      AddBufferBetweenShowings: false,
      BufferTime: null,
      DisplayBookingPageNotes: false,
      BookingPageNotes: null,
      SendAppointmentConfirmationNotes: false,
      AppointmentConfirmationNotes: null,
      RequestFeedback: false,
      AutomaticallySendFeedback: false,
      RequireDocumentPreApproval: false,
      OtherRequiredDocuments: [],
      EnableShowingLink: true,
      ShowingLink: 'https://qa.mosaik.io/showing/940da6bf-46dd-485e-bad3-e7e0582bfa1e',
      CreatedDate: '2024-06-04T20:24:49.896Z',
      UpdatedDate: '2024-06-04T20:24:49.896Z',
      CreatedBy: '2',
      UpdatedBy: null,
      ShowingAvailability: [
        {
          Id: 213,
          ShowingId: 198,
          DaysOfWeek: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          StartTime: '2024-06-04T20:00:00.000Z',
          EndTime: '2024-06-05T18:00:00.000Z',
          MinimumNotice: null,
          AutomaticApproval: false,
          SequentialApproval: false,
          ApprovalUsers: [
            {
              Role: 'SellerAgent',
              RequireApproval: true,
              Sequence: 1,
              UserId: 2,
              Email: 'mosaikagent@yopmail.com',
              Name: 'MosaikAgent Agent',
            },
            {
              Role: 'Seller',
              RequireApproval: true,
              Sequence: 2,
              UserId: 721,
              Email: 'nimra+client@mosaik.io',
              Name: 'nimra+client@mosaik.io ',
            },
          ],
          CreatedDate: '2024-06-04T20:24:49.896Z',
          UpdatedDate: '2024-06-04T20:24:49.896Z',
          CreatedBy: null,
          UpdatedBy: null,
          DateDeleted: null,
          DeletedBy: null,
        },
      ],
    },
    OfferLink: {
      Id: '133',
      UUID: '92cc0c96-a670-45c4-84e6-64d9d65773f9',
      SellerAgentId: 2,
      Status: 'Active',
      PropertyTransactionId: 818,
      Indefinitely: false,
      LinkActiveStartDate: '2024-06-04T00:00:00.000Z',
      LinkActiveDays: null,
      LinkActiveEndDate: '2024-06-13T23:59:59.999Z',
      DisplayDeadlineOnPortal: false,
      DeadlineDate: null,
      DeadlineTime: null,
      Timezone: 'Asia/Karachi',
      RequiredDocuments: [
        {
          name: 'Offer',
          required: false,
          selected: false,
          format: '*',
        },
        {
          name: ' Pre-Approval/Proof of Funds',
          required: false,
          selected: false,
          format: '*',
        },
      ],
      DisplaySubmissionPageNotes: false,
      SubmissionPageNotes: '',
      SendOfferSubmissionNotes: false,
      OfferSubmissionNotes: '',
      AutomaticallySendOffersToSeller: false,
      EnableLink: true,
      Link: 'https://qa.mosaik.io/offer/form/92cc0c96-a670-45c4-84e6-64d9d65773f9',
      CreatedDate: '2024-02-27T09:25:27.177Z',
      UpdatedDate: '2024-06-04T12:02:18.866Z',
      CreatedBy: '2',
      UpdatedBy: '2',
      ListingAgentName: null,
      ListingAgentEmail: null,
      Offer: [],
      NotificationUser: [],
    },
    Tasks: [],
  };
  if (!transaction) {
    return {
      success: false,
      data: null,
      errMsgg: 'Transaction not found',
    };
  }

  const milestoneToEdit = getMilestoneByName(transaction, payload?.milestone);
  if (!milestoneToEdit) {
    return {
      success: false,
      data: null,
      errMsgg: 'Milestone not found',
    };
  }

  const editMilestonePayload = getEditMileastonePayload(payload);
};
