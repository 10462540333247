import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAdminOwnerAccessSelector } from 'store/selectors/transaction';
import { getTransactionSelector, getFinancialPricingSelector } from 'store/selectors/transaction';

import { Row, Col } from 'components-antd';
import { PageWrapper, AsideNavigation, Content } from 'components/Transactions';
import { Wrapper } from 'components';
import {
  getKeyDatesEffect,
  getFinancialPricing,
  getTransactionEffect,
} from 'store/effects/transactions';
import { getFinacials } from 'api/financials';
import { transactionRoles } from 'settings/constants/roles';
import { ListPrice, LeadSource, Commission, Splits, Fees, NetCard, CardTypes } from './components';

import styles from './styles.module.scss';

export enum SplitTabTypes {
  LISTING = 'Listing',
  PURCHASE = 'Purchase',
}

export enum SplitTypes {
  AGENT = 'Agent_Split',
  TEAM = 'Team_Split',
  BROKERAGE = 'Brokerage_Split',
  CLIENT = 'Client_Split',
}

interface NetType {
  id: number;
  netTitle: string;
  netValue: number;
  isRestricted: boolean;
}

export const TransactionFinancials = () => {
  const dispatch = useDispatch();
  const params: { id?: string } = useParams();
  const [splitTabType, setSplitTabType] = useState(SplitTabTypes.LISTING);
  const [priceHistory, setPriceHistory] = useState([]);
  const [financials, setFinancials] = useState<any>(null);
  const [financialsRawData, setFinancialRawData] = useState<any>(null);
  const [splitList, setSplitData] = useState<any>([]);
  const [nets, setNets] = useState<NetType[]>([]);
  const [loading, setLoading] = useState(false);
  const { transaction, isError, status, isIdle, isPending } = useSelector(getTransactionSelector);
  let _pricing = useSelector(getFinancialPricingSelector);
  const { isAdminOrOwner } = useSelector(getAdminOwnerAccessSelector);
  const netCardTypes = [CardTypes.CONFETTI_BLUE, CardTypes.PURPLE, CardTypes.LIGHT_BLUE];

  const isBuyerTransaction = useMemo(() => {
    // Checking if the transaction is a buyer.
    return (
      transaction?.RepresentingRoles?.length < 2 &&
      transaction?.RepresentingRoles?.includes(transactionRoles.BUYER)
    );
  }, [transaction?.RepresentingRoles]);

  const isSellerTransaction = useMemo(() => {
    // Checking if the transaction is a seller.
    return (
      transaction?.RepresentingRoles?.length <= 2 &&
      transaction?.RepresentingRoles?.includes(transactionRoles.SELLER)
    );
  }, [transaction?.RepresentingRoles]);

  const isDualTransaction = useMemo(() => {
    // Checking if the transaction is a dual.
    return (
      transaction?.RepresentingRoles?.length === 2 &&
      transaction?.RepresentingRoles?.includes(transactionRoles.BUYER) &&
      transaction?.RepresentingRoles?.includes(transactionRoles.SELLER)
    );
  }, [transaction?.RepresentingRoles]);

  useEffect(() => {
    if (isBuyerTransaction) {
      setSplitTabType(SplitTabTypes.PURCHASE);
    }
  }, [transaction?.RepresentingRoles]);

  useEffect(() => {
    dispatch(getKeyDatesEffect({ transactionId: params?.id }, { silent: !isIdle }));
    dispatch(getFinancialPricing({ transactionId: params?.id }));
    fetchFinancials();
  }, []);

  const fetchFinancials = async () => {
    try {
      setLoading(true);
      let _data = await getFinacials({ transactionId: params?.id });

      // If buyer transaction, set `Purchase` as split tab type.
      if (transaction?.RepresentingRoles) {
        if (splitTabType === SplitTabTypes.PURCHASE) setSplitTabType(SplitTabTypes.PURCHASE);
        else
          setSplitTabType(
            transaction?.RepresentingRoles?.length < 2 &&
              transaction?.RepresentingRoles?.includes(transactionRoles.BUYER)
              ? SplitTabTypes.PURCHASE
              : SplitTabTypes.LISTING,
          );
      }

      setFinancials(_data?.financial);
      setFinancialRawData(_data);
    } catch (err) {
      setFinancials(null);
      setFinancialRawData(null);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPriceHistory(_pricing);
  }, [_pricing]);

  useEffect(() => {
    dispatch(getTransactionEffect({ id: params?.id }, { silent: !isIdle }));
  }, []);

  const getSplitList = (data) => {
    const filterUnrestrictedSplits = data?.filter((item) => !item?.SplitType?.IsRestricted);
    setSplitData(isAdminOrOwner ? data : filterUnrestrictedSplits);
  };

  const getAddToOptions = () => {
    if (isAdminOrOwner) return nets;

    const unrestrictedNets = nets?.filter((item) => !item?.isRestricted);
    return unrestrictedNets;
  };

  useEffect(() => {
    setNets(
      splitTabType === SplitTabTypes.LISTING
        ? financialsRawData?.listSideNetValues
        : financialsRawData?.buySideNetValues,
    );
  }, [financialsRawData, splitTabType]);

  return (
    <div>
      <PageWrapper notFound={isError && status === 404} unAuthorized={isError && status === 401}>
        <AsideNavigation />
        <Content>
          <Row>
            <Col xs={24} md={12} className={styles.pageDivider}>
              <div className={styles.financialsContent}>
                <h1 className={styles.pageTitle}>Financials</h1>
                <ListPrice priceHistory={priceHistory} isBuyerTransaction={isBuyerTransaction} />
                <LeadSource
                  leadSource={transaction?.LeadSource}
                  transactionId={params?.id}
                  isTransactionAdminOrOwner={isAdminOrOwner}
                  transactionCreator={transaction?.Creator}
                />
                <Wrapper isPending={loading}>
                  <Commission
                    financials={financials}
                    fetchFinancials={fetchFinancials}
                    isDualTransaction={isDualTransaction}
                    isSellerTransaction={isSellerTransaction}
                  />
                </Wrapper>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className={styles.financialsContent}>
                <Wrapper isPending={loading}>
                  <Splits
                    transactionId={params?.id}
                    financials={financials}
                    fetchFinancials={fetchFinancials}
                    getSplitList={getSplitList}
                    representingRoles={transaction?.RepresentingRoles}
                    isBuyerTransaction={isBuyerTransaction}
                    splitTabType={splitTabType}
                    setSplitTabType={(tabType) => setSplitTabType(tabType)}
                    isTransactionAdminOrOwner={isAdminOrOwner}
                  />
                </Wrapper>
                <Wrapper isPending={loading || isPending}>
                  <Fees
                    financials={financials}
                    fetchFinancials={fetchFinancials}
                    splitList={splitList}
                    splitTabType={splitTabType}
                    representingRoles={transaction?.RepresentingRoles}
                    isTransactionAdminOrOwner={isAdminOrOwner}
                    nets={getAddToOptions()}
                  />
                </Wrapper>
                <Wrapper isPending={loading}>
                  {nets?.map((item, idx) => (
                    <NetCard
                      key={idx}
                      label={item?.netTitle}
                      variant={netCardTypes?.[idx % netCardTypes?.length]}
                      amount={item?.netValue ?? 0}
                      isRestricted={item?.isRestricted}
                      isAdminOrOwner={isAdminOrOwner}
                    />
                  ))}
                </Wrapper>
              </div>
            </Col>
          </Row>
        </Content>
      </PageWrapper>
    </div>
  );
};
