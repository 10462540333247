import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';

interface CardPropTypes {
  labels: string[];
  values: string[];
  cardWidth: number;
}
const Card = ({ labels, values, cardWidth }: CardPropTypes) => {
  return (
    <div testid="team-details-card" className={styles.card} style={{ width: `${cardWidth}px` }}>
      {labels.map((label, index) => (
        <div className={styles.data}>
          <span className={styles.label}>{label}</span>
          <span className={styles.value}>{values[index] ?? '-'}</span>
        </div>
      ))}
    </div>
  );
};

export default Card;
