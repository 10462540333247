import React from 'react';

import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'components-antd';
import { appLogoutEffect } from 'store/effects';
import classNames from 'classnames';

export const PaywallForTeamMember: React.FC<{ allowLeave?: boolean }> = (props) => {
  const { allowLeave = false } = props;
  const dispatch = useDispatch();

  return (
    <div className={styles.paywallBackground} id="paywallModalContainer">
      <Modal
        getContainer={'#paywallModalContainer'}
        open={true}
        width={675}
        footer={null}
        closable={false}
        maskClosable={false}
        mask={false}
      >
        <div className={styles.modalLayout}>
          <h2 className={styles.stepHeader}>Welcome to Mosaik!</h2>
          <div className={styles.errorSection}>
            <span className={styles.errorSection__boldText}>Your account is not active.</span>
            <br />
            Please contact your team leader for more information.
          </div>
          {allowLeave && (
            <Button
              onClick={() => {
                dispatch(appLogoutEffect());
              }}
              variant="secondary"
              className={classNames(styles.continueButton, styles.button)}
            >
              Leave
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};
