import { LoadingOutlined } from '@ant-design/icons';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { CreatePaymentMethodCardData } from '@stripe/stripe-js';
import { Spin } from 'antd';
import classNames from 'classnames';
import { AddRounded2, Lock2, Stripe } from 'components/Icons';
import { useRetrievePlan } from 'pages/Paywall/hooks/useRetrievePlan';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { usePaywallSubscription } from './usePaywallSubscription';
import { CurrentActiveSubscriptionPlan } from 'pages/Paywall/types';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#000',
      fontFamily: 'Inter, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#AAABAB',
      },
      ':focus': {},
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '-0.18px',
      padding: '16px 14px',
      border: '1px solid #E8E9EA',
      borderRadius: '4px',
      backgroundColor: 'transparent',
    },
    invalid: {
      color: '#ff3333',
      iconColor: '#ff3333',
    },
  },
};

type StripeCheckoutProps = {
  onNext: () => void;
  selectedPlan: string | null;
  planId: string;
  isStandard: boolean;
  requestedSeatsCount: 2 | 3;
  currentActivePlan: CurrentActiveSubscriptionPlan;
};

export const PaywallCheckoutForm: React.FC<StripeCheckoutProps> = ({
  onNext,
  isStandard,
  planId,
  selectedPlan,
  currentActivePlan,
  requestedSeatsCount = 2,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [coupon, setCoupon] = useState('');
  const isResubscribing = currentActivePlan?.SubscriptionStatus === 'canceled';
  const resubscribingRef = useRef(false);

  const {
    getCoupon,
    availablePlansLoading,
    implementationPlanLoading,
    checkoutLoading,
    couponLoading,
    implementationPrice,
    subscriptionPrice,
    subscribeStandard,
    currentPlan,
    standardPlan,
    availablePlans,
    legacyPlanDetails,
    legacyPlan,
    subscribeLegacy,
  } = usePaywallSubscription({
    requestedSeatCount: requestedSeatsCount,
    isStandard,
    planId,
    selectedPlan,
    currentActivePlan,
  });

  const { startRetrievingPlan } = useRetrievePlan(onNext);
  const mainLoading = availablePlansLoading;
  const amount = isStandard && !resubscribingRef.current ? `$0` : `$${subscriptionPrice}`;

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!stripe || !elements || !standardPlan?.ExternalId) {
        console.log('Stripe.js has not yet loaded.');
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      if (!cardElement || !cardExpiryElement || !cardCvcElement) {
        console.error('some fields are empty!');
        return;
      }
      setProcessing(true);

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      } as CreatePaymentMethodCardData);

      if (result.error) {
        throw new Error(result?.error?.message);
      } else {
        const paymentMethodId = result.paymentMethod.id;
        if (isStandard) {
          await subscribeStandard(
            paymentMethodId,
            standardPlan.ExternalId,
            requestedSeatsCount,
            coupon,
            true,
            (err) => {
              if (!err) {
                setCompleted(true);
                startRetrievingPlan();
              }
            },
          );
        } else {
          await subscribeLegacy(
            paymentMethodId,
            legacyPlan.ExternalId,
            1,
            legacyPlanDetails?.PriceKey,
            coupon,
            (err) => {
              if (!err) {
                setCompleted(true);
                startRetrievingPlan();
              }
            },
          );
        }
      }
    } catch (err) {
      console.error(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (isResubscribing) {
      resubscribingRef.current = true;
    }
  }, [isResubscribing]);

  const loading = mainLoading || couponLoading || checkoutLoading || processing;

  const subTitle = () => {
    const standardHeading = (
      <p className={styles.subHeading}>
        Your card will be charged <span className={styles.price}>{amount}</span>. This card will be
        used for your monthly dues and can be changed via the settings in your account.
      </p>
    );
    const legacyHeading = (
      <p className={styles.subHeading}>
        Your card will be charged <span className={styles.price}>{amount}</span>. This card will be
        used for your {legacyPlanDetails?.Period?.Interval === 'month' ? 'monthly' : 'yearly'} dues
        and can be changed via the settings in your account.
      </p>
    );
    return isStandard ? standardHeading : legacyHeading;
  };

  return (
    <div className={styles.formContainer}>
      <h1 className={styles.heading}>Please Enter Your Payment Info</h1>
      {subTitle()}
      <form onSubmit={handleSubmit} className={styles.formWrapper}>
        <div className={styles.inputWrapper}>
          <label className={styles.formLabel}>Name on Card</label>
          <input type="text" placeholder="Jane Smith" required className={styles.cardElement} />
        </div>
        <div className={styles.inputWrapper}>
          <label className={styles.formLabel}>Card Number</label>

          <CardNumberElement
            className={styles.cardElement}
            options={{
              ...CARD_ELEMENT_OPTIONS,
              showIcon: true,
              iconStyle: 'solid',
              preferredNetwork: ['visa', 'mastercard', 'amex', 'jcb'],
            }}
          />
        </div>

        <div className={styles.flexRow}>
          <div className={styles.inputWrapper}>
            <label className={styles.formLabel}>Expiry Date</label>
            <CardExpiryElement className={styles.cardElement} options={CARD_ELEMENT_OPTIONS} />
          </div>

          <div className={styles.inputWrapper}>
            <label className={styles.formLabel}>CVC</label>
            <CardCvcElement className={styles.cardElement} options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>

        <div className={styles.divider}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="589"
            height="2"
            viewBox="0 0 589 2"
            fill="none"
          >
            <path d="M0 1H589" stroke="white" strokeOpacity="0.1" />
          </svg>
        </div>

        <div className={styles.footer}>
          <div className={styles.stripeSecure}>
            <Lock2 />
            Secured by
            <Stripe className={styles.stripeIcon} color="#262626" />
          </div>

          <button type="submit" className={styles.payBtn} disabled={loading || completed}>
            {loading || completed ? (
              <>
                <Spin
                  className={styles.loader}
                  indicator={
                    <LoadingOutlined
                      color="antiquewhite"
                      width={24}
                      height={24}
                      className={styles.spinner}
                      spin
                    />
                  }
                />
              </>
            ) : (
              <>Pay {amount}</>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
