import { textColor } from 'app-constants';
import PropTypes from 'prop-types';

export const Icons = ({ testid, className, variant, onClick, color, svgClass }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.EDIT: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path
              d="M10 22H13.1314L21.3515 13.78C21.7667 13.3647 22 12.8015 22 12.2143C22 11.627 21.7667 11.0638 21.3515 10.6485C20.9362 10.2333 20.373 10 19.7857 10C19.1985 10 18.6353 10.2333 18.22 10.6485L10 18.8686V22Z"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.4368 11.4316L20.5683 14.5631"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.INFO: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <g clipPath="url(#clip0_39353_42011)">
              <path
                d="M22 16C22 19.3137 19.3137 22 16 22C12.6863 22 10 19.3137 10 16C10 12.6863 12.6863 10 16 10C19.3137 10 22 12.6863 22 16Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 16C16 17.0414 16 17.4586 16 18.5"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="16" cy="13" r="1" fill="#262626" />
            </g>
            <defs>
              <clipPath id="clip0_39353_42011">
                <rect width="16" height="16" fill="white" transform="translate(8 8)" />
              </clipPath>
            </defs>
          </svg>
        );
      }

      case Icons.ADD: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path d="M4 8H12" stroke="#747475" strokeWidth="2" strokeLinecap="round" />
            <path d="M8 12L8 4" stroke="#747475" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.REMOVE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM13 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13H11H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11H13Z"
              fill="#FF576D"
            />
          </svg>
        );
      }
      case Icons.CLOSE: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M18 18L6 6" stroke="#262626" strokeWidth="2" strokeLinecap="round" />
            <path d="M6 18L18 6" stroke="#262626" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.PLUS: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F5F6" />
            <path d="M11 16H21" stroke="#262626" strokeWidth="2" strokeLinecap="round" />
            <path d="M16 21L16 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.CONCIERGE_ATTORNEY_GREY: {
        return (
          <svg
            width="112"
            height="112"
            viewBox="0 0 112 112"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.3">
              <path
                d="M21 19.6004C21 17.2808 22.8804 15.4004 25.2 15.4004H55.1616C56.3019 15.4004 57.3931 15.864 58.1846 16.6847L73.023 32.069C73.7781 32.8518 74.2 33.8971 74.2 34.9847V75.6004C74.2 77.92 72.3196 79.8004 70 79.8004H25.2C22.8804 79.8004 21 77.92 21 75.6004V19.6004Z"
                fill="#AAABAB"
              />
              <path
                d="M41.8302 84.5309C40.19 82.8907 40.19 80.2314 41.8302 78.5912L74.3906 46.0309C76.0308 44.3907 78.69 44.3907 80.3302 46.0309L98.8632 64.5639C100.503 66.2041 100.503 68.8634 98.8632 70.5036L66.3029 103.064C64.6627 104.704 62.0034 104.704 60.3632 103.064L41.8302 84.5309Z"
                fill="#AAABAB"
              />
              <path
                d="M74.1999 46.2227V75.602C74.1999 77.9216 72.3195 79.8021 69.9999 79.8021H40.9854C41.1874 79.364 41.469 78.9536 41.8301 78.5925L74.1999 46.2227Z"
                fill="#676767"
              />
            </g>
          </svg>
        );
      }
      case Icons.CONTACT_INFORMATION: {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect opacity="0.08" width="40" height="40" rx="20" fill="#4673D1" />
            <path
              d="M24 16C24 18.2091 22.2091 20 20 20C17.7909 20 16 18.2091 16 16C16 13.7909 17.7909 12 20 12C22.2091 12 24 13.7909 24 16Z"
              stroke="#4673D1"
              strokeWidth="2"
            />
            <path
              d="M14 27.5C14.3739 25.6303 16.0932 24 17.9999 24H22C23.9067 24 25.6261 25.6303 26 27.5"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.HIGHLIGHTS: {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect opacity="0.08" width="40" height="40" rx="20" fill="#928CDA" />
            <path
              d="M22.4349 15.5832C22.6245 15.0521 23.3755 15.0521 23.5651 15.5832L25.0088 19.6278C25.0693 19.7973 25.2027 19.9307 25.3721 19.9912L29.4168 21.4349C29.9479 21.6245 29.9479 22.3755 29.4168 22.5651L25.3722 24.0088C25.2027 24.0693 25.0693 24.2027 25.0088 24.3721L23.5651 28.4168C23.3755 28.9479 22.6245 28.9479 22.4349 28.4168L20.9912 24.3722C20.9307 24.2027 20.7973 24.0693 20.6279 24.0088L16.5832 22.5651C16.0521 22.3755 16.0521 21.6245 16.5832 21.4349L20.6278 19.9912C20.7973 19.9307 20.9307 19.7973 20.9912 19.6279L22.4349 15.5832Z"
              stroke="#928CDA"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path
              d="M13.7212 11.702C13.8214 11.4497 14.1786 11.4497 14.2788 11.702L15.0892 13.7427C15.1197 13.8194 15.1806 13.8803 15.2573 13.9108L17.298 14.7212C17.5503 14.8214 17.5503 15.1786 17.298 15.2788L15.2573 16.0892C15.1806 16.1197 15.1197 16.1806 15.0892 16.2573L14.2788 18.298C14.1786 18.5503 13.8214 18.5503 13.7212 18.298L12.9108 16.2573C12.8803 16.1806 12.8194 16.1197 12.7427 16.0892L10.702 15.2788C10.4497 15.1786 10.4497 14.8214 10.702 14.7212L12.7427 13.9108C12.8194 13.8803 12.8803 13.8194 12.9108 13.7427L13.7212 11.702Z"
              stroke="#928CDA"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DOCUMENTS: {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect opacity="0.08" width="40" height="40" rx="20" fill="#51BFE1" />
            <path
              d="M22 11V15C22 15.2652 22.1054 15.5196 22.2929 15.7071C22.4804 15.8946 22.7348 16 23 16H27"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25 29H15C14.4696 29 13.9609 28.7893 13.5858 28.4142C13.2107 28.0391 13 27.5304 13 27V13C13 12.4696 13.2107 11.9609 13.5858 11.5858C13.9609 11.2107 14.4696 11 15 11H22L27 16V27C27 27.5304 26.7893 28.0391 26.4142 28.4142C26.0391 28.7893 25.5304 29 25 29Z"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 17H18"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21H23"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 25H23"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.SELECTED_FILE: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_34848_177374)">
              <path
                d="M18.668 4V9.33333C18.668 9.68695 18.8084 10.0261 19.0585 10.2761C19.3085 10.5262 19.6477 10.6667 20.0013 10.6667H25.3346"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.668 28H9.33464C8.62739 28 7.94911 27.719 7.44902 27.219C6.94892 26.7189 6.66797 26.0406 6.66797 25.3333V6.66667C6.66797 5.95942 6.94892 5.28115 7.44902 4.78105C7.94911 4.28095 8.62739 4 9.33464 4H18.668L25.3346 10.6667V25.3333C25.3346 26.0406 25.0537 26.7189 24.5536 27.219C24.0535 27.719 23.3752 28 22.668 28Z"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g clipPath="url(#clip1_34848_177374)">
                <path
                  d="M24.0003 31.272C28.0169 31.272 31.273 28.0159 31.273 23.9993C31.273 19.9827 28.0169 16.7266 24.0003 16.7266C19.9836 16.7266 16.7275 19.9827 16.7275 23.9993C16.7275 28.0159 19.9836 31.272 24.0003 31.272Z"
                  fill="#04A451"
                />
                <path
                  d="M21.4541 23.9998L23.2723 25.818L26.9086 22.1816"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_34848_177374">
                <rect width="32" height="32" fill="white" />
              </clipPath>
              <clipPath id="clip1_34848_177374">
                <rect width="16" height="16" fill="white" transform="translate(16 16)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.UNSELECTED_FILE: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <g clipPath="url(#clip0_34876_177794)">
              <path
                d="M18.668 4V9.33333C18.668 9.68695 18.8084 10.0261 19.0585 10.2761C19.3085 10.5262 19.6477 10.6667 20.0013 10.6667H25.3346"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.668 28H9.33464C8.62739 28 7.94911 27.719 7.44902 27.219C6.94892 26.7189 6.66797 26.0406 6.66797 25.3333V6.66667C6.66797 5.95942 6.94892 5.28115 7.44902 4.78105C7.94911 4.28095 8.62739 4 9.33464 4H18.668L25.3346 10.6667V25.3333C25.3346 26.0406 25.0537 26.7189 24.5536 27.219C24.0535 27.719 23.3752 28 22.668 28Z"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_34876_177794">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.CROSS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M17 17L7 7" stroke="#747475" strokeWidth="2" strokeLinecap="round" />
            <path d="M7 17L17 7" stroke="#747475" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.CLOUD_UPLOAD: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clipPath="url(#clip0_36894_194648)">
              <path
                d="M5.83452 14C4.79475 14 3.79757 13.6049 3.06234 12.9016C2.32711 12.1984 1.91406 11.2446 1.91406 10.25C1.91406 9.25544 2.32711 8.30161 3.06234 7.59835C3.79757 6.89509 4.79475 6.5 5.83452 6.5C6.08009 5.40598 6.79849 4.44457 7.83167 3.82726C8.34326 3.5216 8.91672 3.30962 9.51933 3.20343C10.1219 3.09723 10.7419 3.0989 11.3438 3.20833C11.9457 3.31777 12.5177 3.53283 13.0273 3.84123C13.5368 4.14964 13.9739 4.54535 14.3135 5.00577C14.6531 5.4662 14.8886 5.98232 15.0066 6.52466C15.1246 7.06701 15.1228 7.62496 15.0012 8.16667H15.8345C16.6081 8.16667 17.3499 8.47396 17.8969 9.02094C18.4439 9.56792 18.7512 10.3098 18.7512 11.0833C18.7512 11.8569 18.4439 12.5987 17.8969 13.1457C17.3499 13.6927 16.6081 14 15.8345 14H15.0012"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 11.5L10 9L12.5 11.5"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 9V16.5"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_36894_194648">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.OFFER_EXPIRATION: {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect opacity="0.08" width="40" height="40" rx="20" fill="#FB913A" />
            <path
              d="M25.25 14H14.75C13.7835 14 13 14.7835 13 15.75V26.25C13 27.2165 13.7835 28 14.75 28H25.25C26.2165 28 27 27.2165 27 26.25V15.75C27 14.7835 26.2165 14 25.25 14Z"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.5 12.5V15.5"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 12.5V15.5"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 19H27"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DATEPICKER: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M17.25 6H6.75C5.7835 6 5 6.7835 5 7.75V18.25C5 19.2165 5.7835 20 6.75 20H17.25C18.2165 20 19 19.2165 19 18.25V7.75C19 6.7835 18.2165 6 17.25 6Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.5 4.5V7.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.5 4.5V7.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 11H19"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className}>
      {renderIcon()}
    </div>
  );
};

Icons.EDIT = 'edit';
Icons.INFO = 'info';
Icons.ADD = 'add';
Icons.REMOVE = 'remove';
Icons.CLOSE = 'close';
Icons.PLUS = 'plus';
Icons.CONCIERGE_ATTORNEY_GREY = 'conceirgeAttorneyGrey';
Icons.CONTACT_INFORMATION = 'contactInformation';
Icons.HIGHLIGHTS = 'highlights';
Icons.DOCUMENTS = 'documents';
Icons.UNSELECTED_FILE = 'unselectedFile';
Icons.SELECTED_FILE = 'selectedFile';
Icons.CROSS = 'cross';
Icons.CLOUD_UPLOAD = 'cloudUpload';
Icons.OFFER_EXPIRATION = 'offerExpiration';
Icons.DATEPICKER = 'datePicker';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
  svgClass: PropTypes.string,
};

Icons.defaultProps = {
  svgClass: '',
  className: '',
  variant: '',
  color: '',
  testid: undefined,
  onClick: () => {},
};
