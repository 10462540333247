import { handleActions } from 'redux-actions';
import { get, cloneDeep } from 'lodash-es';
import { socketsResetAction } from 'store/actions/sockets';
import { socketsStoreMessagesSetThreadIdAction } from 'store/actions/sockets/threads';
import {
  socketsStoreEditedMessageAction,
  socketsStoreDeletedMessageAction,
  socketsStoreNewMessageAction,
  socketsStoreMessagesByThreadIdAction,
  socketsResetMessagesAction,
  socketSetEditMessageAction,
  setNewMessageWithTempIdAction,
  replaceTempMessageAction,
  socketsRemoveDeletedMessageAction,
} from 'store/actions/sockets/messages';
import { socketsStoreEditedTransactionMessageAction } from 'store/actions/sockets/transactionMessages';

const initialData = {
  currentThreadId: null,
  editMessage: null,
  messages: null,
};

export default handleActions(
  {
    [socketsStoreMessagesByThreadIdAction]: (state, { payload }) => ({
      ...state,
      currentThreadId: payload.threadId,
      messages: payload.data,
    }),
    [socketsStoreMessagesSetThreadIdAction]: (state, { payload: { threadId } }) => ({
      ...state,
      currentThreadId: threadId,
    }),
    [socketsStoreNewMessageAction]: (state, { payload }) => {
      const data = get(payload, 'data');

      const isMessageAlreadyExist = (state.messages || []).some(
        (message) => message?.Id === data?.Id,
      );
      if (data.MessageThreadId !== state.currentThreadId || isMessageAlreadyExist) {
        return state;
      }

      return {
        ...state,
        messages: [...(state.messages || []), ...(data ? [data] : [])],
      };
    },
    [setNewMessageWithTempIdAction]: (state, { payload }) => ({
      ...state,
      messages: [...(state.messages || []), payload],
    }),
    [replaceTempMessageAction]: (state, { payload }) => {
      const clonedMessages = cloneDeep(state.messages);
      const tempMessageIndex = (clonedMessages || []).findIndex(
        (message) => message?.TempId === payload?.TempId,
      );

      if (tempMessageIndex !== -1) {
        clonedMessages[tempMessageIndex] = payload;
      }
      return {
        ...state,
        messages: clonedMessages,
      };
    },
    [socketSetEditMessageAction]: (state, { payload }) => ({
      ...state,
      editMessage: payload?.editMessage,
    }),
    [socketsStoreEditedMessageAction]: (state, { payload }) => {
      const data = get(payload, 'data');
      const clonedMessages = cloneDeep(state.messages);
      const editedMessageIndex = (clonedMessages || []).findIndex(
        (message) => message?.Id === data?.Id,
      );

      if (editedMessageIndex !== -1) {
        clonedMessages[editedMessageIndex] = data;
      }

      return {
        ...state,
        messages: clonedMessages,
      };
    },

    [socketsStoreDeletedMessageAction]: (state, { payload }) => {
      // separation of concerns for soft deletes
      const data = get(payload, 'data');
      const clonedMessages = cloneDeep(state.messages);

      const deletedMessageIndex = (clonedMessages || []).findIndex(
        (message) => message?.Id === data?.Id,
      );

      if (deletedMessageIndex !== -1) {
        clonedMessages[deletedMessageIndex] = data;
      }

      return {
        ...state,
        messages: clonedMessages,
      };
    },

    [socketsStoreEditedTransactionMessageAction]: (state, { payload }) => {
      const data = get(payload, 'data');
      const clonedMessages = cloneDeep(state.messages);
      const editedMessageIndex = (clonedMessages || []).findIndex(
        (message) => message?.Id === data?.Id,
      );

      if (editedMessageIndex !== -1) {
        clonedMessages[editedMessageIndex] = data;
      }

      return {
        ...state,
        messages: clonedMessages,
      };
    },
    [socketsRemoveDeletedMessageAction]: (state) => state, // TODO: find thread by id and then find message by id and remove it;
    [socketsResetAction]: () => cloneDeep(initialData),
    [socketsResetMessagesAction]: () => cloneDeep(initialData),
  },
  initialData,
);
