import React from 'react';
import PropTypes from 'prop-types';

const FullArrowUp = ({ className }) => (
  <div className={className}>
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 9.16675V34.8334"
        stroke="black"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 20.1667L22 9.16675"
        stroke="black"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 20.1667L22 9.16675"
        stroke="black"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

FullArrowUp.propTypes = {
  className: PropTypes.string,
};

FullArrowUp.defaultProps = {
  className: '',
};

export default FullArrowUp;
