import Api from '../core/api';

import {
  getSavedSearchProperties,
  getFavoritesProperties,
  getAgentProperties,
} from 'api/feed/feedV2';
import {
  requestGetSavedSearchesPropertiesAction,
  requestGetFavoritesPropertiesAction,
  requestGetAgentPropertiesAction,
} from 'store/actions/feedv3';

export const getSavedSearchPropertiesV3Effect = (
  cfg,
  options = {},
  cb = (err: any, resp: any) => {},
) => {
  const sendRequest = Api.execBase({
    action: requestGetSavedSearchesPropertiesAction,
    method: getSavedSearchProperties,
  });
  return sendRequest(cfg, options, cb);
};

export const getFavoritesPropertiesV3Effect = (cfg, options = {}, cb = () => {}) => {
  const sendRequest = Api.execBase({
    action: requestGetFavoritesPropertiesAction,
    method: getFavoritesProperties,
  });
  return sendRequest(cfg, options, cb);
};

// AGENT SIDE

export const getAgentPropertiesV3Effect = (cfg, options = {}, cb = (err: any, resp: any) => {}) => {
  const sendRequest = Api.execBase({
    action: requestGetAgentPropertiesAction,
    method: getAgentProperties,
  });
  return sendRequest(cfg, options, cb);
};
