import axios from 'axios';

import { AuthToken } from 'services';

const getDevelopmentApiLink = () =>
  process.env.REACT_APP_API_ENDPOINT || 'https://api.dev.mosaikhome.com:3001';
const getProductionApiLink = () => process.env.REACT_APP_API_ENDPOINT;

export const getApiLink = () => {
  return process.env.NODE_ENV === 'production' ? getProductionApiLink() : getDevelopmentApiLink();
};

export const apiServer = axios.create({
  baseURL: getApiLink(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

AuthToken.initRest();

export const setHeaders = ({ accessToken }) => {
  apiServer.defaults.headers.Authorization = `Bearer ${accessToken}`;
};

export const clearHeaders = () => {
  delete apiServer.defaults.headers.Authorization;
};
