import { useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { requestGetClientsListEffect } from 'store/effects/clientsList';
import { requestGetCollaboratorsListEffect } from 'store/effects/collaboratorsList';
import { requestGetTeamListEffect } from 'store/effects/teamList';
import {
  getActiveSearchAndGroupedClientsList,
  getRetainedSearchAndGroupedClientsList,
  getInactiveSearchAndGroupedClientsList,
  getDeclinedSearchAndGroupedClientsList,
  getPendingSearchAndGroupedClientsList,
  getConnectionTypeSelector,
  getActiveSearchAndGroupedTeamList,
  getInactiveSearchAndGroupedTeamList,
  getActiveSearchAndGroupedCollaboratorsList,
  getInactiveSearchAndGroupedCollaboratorsList,
  getPendingSearchAndGroupedCollaboratorsList,
  getDeclinedSearchAndGroupedCollaboratorsList,
} from 'store/selectors/app';
import { getStateAndDataFlag } from 'store/selectors/clientsList';
import { READY } from 'settings/constants/apiState';
import SectionNameItem from '../SectionNameItem';
import ListItem from '../ListItem';
import { AGENT_CONNECTIONS, AGENT_TYPE } from 'settings/constants/drawers';
import { getAgentTypeSelector } from 'store/selectors/user';
import { TransactionUserRoleMap } from 'settings/constants/transaction';

import styles from './styles.module.scss';

const ClientsList = ({ tabId, tabLabel }) => {
  const dispatch = useDispatch();
  const { state, isData } = useSelector(getStateAndDataFlag);
  const connectionType = useSelector(getConnectionTypeSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const connectionsList = useSelector(ClientsList.CONNECTION_SELECTORS[connectionType][tabLabel]);
  const sortedSectionNames = useMemo(() => Object.keys(connectionsList).sort(), [connectionsList]);
  useEffect(() => {
    if (connectionType === AGENT_CONNECTIONS.Clients) dispatch(requestGetClientsListEffect());
    if (connectionType === AGENT_CONNECTIONS.Collaborators)
      dispatch(requestGetCollaboratorsListEffect({ Status: tabId }));
    if (connectionType === AGENT_CONNECTIONS.Team && agentType === AGENT_TYPE.Team)
      dispatch(requestGetTeamListEffect({ Status: tabId }));
  }, [connectionType]);

  const getConnectionRole = (role) =>
    connectionType === AGENT_CONNECTIONS.Collaborators ? TransactionUserRoleMap[role] : role;

  return (
    <>
      {sortedSectionNames.length > 0 ? (
        sortedSectionNames.map((letter) => (
          <div key={letter}>
            <SectionNameItem letter={letter} />
            {connectionsList[letter].map(
              ({
                name,
                role,
                teamRole,
                sendDate,
                sendStatus,
                id,
                searchInstances,
                transactionInstances,
              }) => (
                <ListItem
                  connectionName={name}
                  connectionRole={getConnectionRole(role)}
                  teamRole={teamRole}
                  activeTabName={tabLabel}
                  sendDate={sendDate}
                  sendStatus={sendStatus}
                  key={id}
                  connectionId={id}
                  searchInstances={searchInstances}
                  transactionInstances={transactionInstances}
                  testid="connection_item"
                />
              ),
            )}
          </div>
        ))
      ) : state === READY && isData ? (
        <div className={styles.noClient}>
          <p className={styles.noClientsText}>
            {`You have no ${tabLabel.toLowerCase()} ${
              connectionType === AGENT_CONNECTIONS.Team
                ? 'team members'
                : connectionType.toLowerCase()
            }.`}
          </p>
        </div>
      ) : null}
    </>
  );
};

ClientsList.CONNECTION_SELECTORS = {
  [AGENT_CONNECTIONS.Clients]: {
    Active: getActiveSearchAndGroupedClientsList,
    Retained: getRetainedSearchAndGroupedClientsList,
    Pending: getPendingSearchAndGroupedClientsList,
    Declined: getDeclinedSearchAndGroupedClientsList,
    Inactive: getInactiveSearchAndGroupedClientsList,
  },
  [AGENT_CONNECTIONS.Collaborators]: {
    Active: getActiveSearchAndGroupedCollaboratorsList,
    Pending: getInactiveSearchAndGroupedCollaboratorsList,
    Declined: getPendingSearchAndGroupedCollaboratorsList,
    Inactive: getDeclinedSearchAndGroupedCollaboratorsList,
  },
  [AGENT_CONNECTIONS.Team]: {
    Active: getActiveSearchAndGroupedTeamList,
    Pending: getActiveSearchAndGroupedTeamList,
    Inactive: getInactiveSearchAndGroupedTeamList,
  },
};

ClientsList.propTypes = {
  tabLabel: PropTypes.string,
};

ClientsList.defaultProps = {
  tabLabel: '',
};

export default ClientsList;
