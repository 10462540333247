import AntSkeleton, { SkeletonProps as AntSkeletonProps } from 'antd/lib/skeleton';

import styles from './styles.module.scss';
import { Row, Col } from 'components-antd';
import { Fragment } from 'react';

interface PropertySkeletonProps extends AntSkeletonProps {
  rowCount?: number;
}

const Card = () => (
  <div className={styles.card}>
    <div className={styles.image}>
      <AntSkeleton.Button active block shape={'square'} />
    </div>
    <div className={styles.infoContainer}>
      <div>
        <AntSkeleton.Button active size="small" shape="round" className={styles.data1} block />
        <AntSkeleton.Button active size="small" shape="round" className={styles.data2} block />
        <AntSkeleton.Button active size="small" shape="round" className={styles.data3} block />
        <AntSkeleton.Button active size="small" shape="round" className={styles.data4} block />
      </div>
      <div>
        <AntSkeleton.Button active size="small" shape="round" block className={styles.data5} />
      </div>
    </div>
  </div>
);

export const PropertySkeleton = ({ rowCount = 5 }: PropertySkeletonProps) => (
  <div className={styles.skeletonRowContainer}>
    {[...Array(rowCount)].map((el, index) => (
      <Row key={`skeleton-list-${index}`} className={styles.skeletonRow} gutter={24}>
        <Col lg={12}>
          <Card />
        </Col>
        <Col lg={12}>
          <Card />
        </Col>
      </Row>
    ))}
  </div>
);
