import { handleActions } from 'redux-actions';
import {
  cleanFeedDrawnPolygonAction,
  setFeedDrawnPolygonAction,
  toggleFeedMapDrawingModeAction,
} from 'store/actions/feed';

const initialData = {
  isEnabled: false,
  polygon: null,
};

export default handleActions(
  {
    [toggleFeedMapDrawingModeAction as any]: (state, { payload }) => ({
      ...state,
      isEnabled: payload !== undefined ? (payload as unknown as boolean) : !state.isEnabled,
    }),
    [cleanFeedDrawnPolygonAction as any]: (state, { payload }) => ({
      ...state,
      isEnabled: false,
      polygon: null,
    }),
    [setFeedDrawnPolygonAction as any]: (state, { payload }) => ({
      ...state,
      isEnabled: false,
      polygon: payload as any,
    }),
  },
  initialData,
);
