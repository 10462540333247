import React from 'react';

export const IconRecommended = ({ ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.00109 11.8336L3.88642 13.997L4.67242 9.41495L1.34375 6.17029L5.94375 5.50229L8.00109 1.33362L10.0584 5.50229L14.6584 6.17029L11.3298 9.41495L12.1158 13.997L8.00109 11.8336Z"
      fill="#FF576D"
      stroke="#FF576D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconRecommended;
