import { routes } from 'settings/navigation/routes';

export const link = {
  toHome: () => routes.index,
  toToursDashboard: () => routes.clientTours,
  toSignUp: (role) => routes.signUpWithRole.replace(':role', role),
  toOnBoarding: (role) => routes.onBoardingWithRole.replace(':role', role),
  toOnBoardingV2Agent: () => routes.onBoardingV2Agent,
  toOnBoardingWizard: (role) => routes.onBoardingWizardWithRole.replace(':role', role),
  toOnBoardingWizardStart: (role) => routes.onBoardingWizardWithRoleStart.replace(':role', role),
  toFeedListingDetails: (id) => routes.feedListingDetail.replace(':id', id),
  toSearchListingDetails: (id) => routes.searchListingDetail.replace(':id', id),
  toServices: () => routes.services,
  toServicesCategoryPartner: ({ categoryId, partnerId }) =>
    routes.servicesCategoryPartner.replace(/(:categoryId|:partnerId)/g, (str) => {
      if (str === ':categoryId') {
        return categoryId;
      }
      if (str === ':partnerId') {
        return partnerId;
      }
    }),
  toServicesCategoryPartnerQuote: ({ categoryId, partnerId }) =>
    routes.servicesCategoryPartnerRequestQuote.replace(/(:categoryId|:partnerId)/g, (str) => {
      if (str === ':categoryId') {
        return categoryId;
      }
      if (str === ':partnerId') {
        return partnerId;
      }
    }),
  toDashboardServiceDirectoryCategory: (id) =>
    routes.dashboardServiceDirectoryCategory.replace(':id', id),
  toDashboardServiceDirectoryPartner: (id) =>
    routes.dashboardServiceDirectoryPartner.replace(':id', id),
  toDashboardServiceDirectoryForm: (id) => routes.dashboardServiceDirectoryForm.replace(':id', id),
  toDashboardServiceDirectoryCategoryEdit: (id) =>
    routes.dashboardServiceDirectoryCategoryEdit.replace(':id', id),
  toDashboardServiceDirectoryPartnerEdit: (id) =>
    routes.dashboardServiceDirectoryPartnerEdit.replace(':id', id),
  toDashboardServiceDirectoryFormEdit: (id) =>
    routes.dashboardServiceDirectoryFormEdit.replace(':id', id),
  toDashboardServiceDirectoryFormBuilder: (id) =>
    routes.dashboardServiceDirectoryFormBuilder.replace(':id', id),
  toDashboardTimelineTemplate: (id) => routes.dashboardTimelineTemplate.replace(':id', id),
  toDashboardTaskTemplate: (id) => routes.dashboardTaskTemplate.replace(':id', id),
  toTransactionClose: (id) => routes.transactionClose.replace(':id', id),
  toTransactionCancellation: (id) => routes.transactionCancellation.replace(':id', id),
  toTransactionUnderContract: (id) => routes.transactionUnderContract.replace(':id', id),
  toTransactionOverview: (id) => routes.transactionOverview.replace(':id', id),
  toTransactionDetails: (id) => routes.transactionDetails.replace(':id', id),
  toTransactionFinancials: (id) => routes.transactionFinancials.replace(':id', id),
  toTransactionShowings: (transactionId, showingUUID) =>
    routes.transactionShowings
      .replace(':id', transactionId)
      .replace('/:uuid', `/${showingUUID || ''}`)
      .replace('?', ''),
  toTransactionOffers: (transactionId, offerUUID) =>
    routes.transactionOffers
      .replace(':id', transactionId)
      .replace('/:uuid', `/${offerUUID || ''}`)
      .replace('?', ''),
  toTransactionTasks: (id) => routes.transactionTasks.replace(':id', id),
  toTransactionMessages: (id) => routes.transactionMessages.replace(':id', id),
  toTransactionDocuments: (id) => routes.transactionDocuments.replace(':id', id),
  toTransactionActivity: (id) => routes.transactionActivity.replace(':id', id),
  toProjectClose: (id) => routes.projectClose.replace(':id', id),
  toProjectCancellation: (id) => routes.projectCancellation.replace(':id', id),
  toProjectOverview: (id) => routes.projectOverview.replace(':id', id),
  toProjectDetails: (id) => routes.projectDetails.replace(':id', id),
  toProjectTasks: (id) => routes.projectTasks.replace(':id', id),
  toProjectMessages: (id) => routes.projectMessages.replace(':id', id),
  toProjectDocuments: (id) => routes.projectDocuments.replace(':id', id),
  toProjectActivity: (id) => routes.projectActivity.replace(':id', id),
  workShopShowings: () => routes.workShopShowings,
  toDashboardServiceDirectoryFormsQuoteForm: (id) =>
    routes.dashboardServiceDirectoryFormsQuoteForm.replace(':versionId', id),
  toDashboardServiceDirectoryFormsSmartForm: (id) =>
    routes.dashboardServiceDirectoryFormsSmartForm.replace(':versionId', id),
  toDashboardServiceDirectoryFormsQuoteFormPreview: (id) =>
    routes.dashboardServiceDirectoryFormsQuoteFormPreview.replace(':versionId', id),
  toDashboardServiceDirectoryFormsSmartFormPreview: (id) =>
    routes.dashboardServiceDirectoryFormsSmartFormPreview.replace(':versionId', id),
  toScheduleShowingAppointment: (id) => routes.showing.replace(':Id', id),
  toScheduledShowingAppointment: (appointmentId) =>
    routes.appointment.replace(':appointmentId', appointmentId),
  toWorkshopFormProcessDocument: ({ formProcessPublicId, formDocumentPublicId }) =>
    routes.workshopFormProcessDocument.replace(
      ':formProcessPublicId/:formDocumentPublicId',
      `${formProcessPublicId}/${formDocumentPublicId}`,
    ),
  toWorkshopFormProcessRequestUpdate: ({ formProcessPublicId, formDocumentPublicId }) =>
    routes.workshopFormProcessRequestUpdate.replace(
      ':formProcessPublicId/:formDocumentPublicId',
      `${formProcessPublicId}/${formDocumentPublicId}`,
    ),
  toTransactionWithFlowBasicPlanCreate: (flow) =>
    routes.transactionWithFlowBasicPlanCreate.replace(':Flow', flow),
  toTransactionTemplate: (id) => routes.dashboardTransactionTemplateView.replace(':id', id),
  // START Dynamic Form
  toWorkshopDynamicFormDocument: ({ formProcessPublicId, formDocumentPublicId }) =>
    routes.workshopDynamicFormDocument.replace(
      ':formProcessPublicId/:formDocumentPublicId',
      `${formProcessPublicId}/${formDocumentPublicId}`,
    ),
  toWorkshopDynamicFormAnonDocument: ({ token, type }) =>
    routes.workshopDynamicFormDocumentAnon.replace(':token/:type', `${token}/${type}`),

  toWorkshopDynamicFormSuccess: routes.workshopDynamicFormSuccess,
  // END Dynamic Form
  toDashboardTransactionTemplateView: (id) =>
    routes.dashboardTransactionTemplateView.replace(':id', id),
  toDashboardTransactionTemplateEdit: (id) =>
    routes.dashboardTransactionTemplateEdit.replace(':id', id),
  toDashboardProjectTemplateView: (id) => routes.dashboardProjectTemplateView.replace(':id', id),
  toDashboardProjectTemplateEdit: (id) => routes.dashboardProjectTemplateEdit.replace(':id', id),
  toTransactionTemplateView: (id) => routes.templatesTransactionView.replace(':id', id),
  toTransactionTemplateEdit: (id) => routes.templatesTransactionEdit.replace(':id', id),
  toProjectTemplateView: (id) => routes.templatesProjectView.replace(':id', id),
  toProjectTemplateEdit: (id) => routes.templatesProjectEdit.replace(':id', id),
  toDashboardServiceDirectoryServiceTagEdit: (id) =>
    routes.dashboardServiceDirectoryServiceTagEdit.replace(':id', id),
  toAdminPanelTeamDetails: (id) => routes.teamDetais.replace(':id', id),

  // Dynamic Template
  toWorkshopDynamicTemplateForm: ({ templateId }) =>
    routes.workshopDynamicTemplateForm.replace(':templateId', templateId),

  toWorkshopFormTemplateRequest: ({ templateId }) =>
    routes.workshopFormTemplateRequest.replace(':templateId', templateId),

  // Bundle Navigation Routes

  /* Dynamic Form Bundle */
  toWorkshopDynamicFormBundle: ({ formProcessPublicId }) =>
    routes.workshopDynamicFormBundle.replace(':formProcessPublicId', `${formProcessPublicId}`),

  /* Edit Dynamic Form Template */
  toWorkshopFormBundleTemplateRequest: routes.workshopFormBundleTemplateRequest,

  /* Dynamic Template Bundle */
  toWorkshopDynamicTemplateBundle: routes.workshopDynamicTemplateBundle,

  //Team Templates
  toDashboardTeamTransactionTemplate: (teamId) =>
    routes.dashboardTeamTransactionTemplates.replace(':teamId', teamId),
  toDashboardTeamTransactionTemplateCreate: (teamId) =>
    routes.dashboardTeamTransactionTemplateCreate.replace(':teamId', teamId),
  toDashboardTeamTransactionTemplateView: (teamId, id) =>
    routes.dashboardTeamTransactionTemplateView.replace(':teamId/:id', `${teamId}/${id}`),
  toDashboardTeamTransactionTemplateEdit: (teamId, id) =>
    routes.dashboardTeamTransactionTemplateEdit.replace(':teamId/:id', `${teamId}/${id}`),
};
