import PropTypes from 'prop-types';

import { Footer } from 'components-antd';
import LeftNavigate from '../LeftNavigate';

import styles from './styles.module.scss';

// TODO: use PageWrapper from components
const PageWrapper = ({ children }) => (
  <div className={styles.wrapper}>
    <div testid="settings_page" className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.leftMenu}>
          <LeftNavigate />
        </div>
        <div className={styles.rightContent}>{children}</div>
      </div>
      <Footer />
    </div>
  </div>
);

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageWrapper;
