import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { updateQuoteQuestionEffect } from 'store/effects/quotes';
import { Text } from './FieldTypes';
import { transformJSXResponse } from 'utils';
import { FORM_QUESTION_TYPE } from 'app-constants';

import styles from './styles.module.scss';

const SummaryItem = (props) => {
  const { className, id, questionType, question, answer, editAnswerComponent, onEdit } = props;
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const onEditHandler = (event) => {
    if (typeof onEdit === 'function') {
      return onEdit(event);
    }
    setEditMode(true);
  };

  const onSaveHandler = (newAnswer) => {
    setIsPending(true);
    dispatch(
      updateQuoteQuestionEffect({ questionId: id, newAnswer }, { silent: true }, (err) => {
        if (!err) {
          setEditMode(false);
        }
        setIsPending(false);
      }),
    );
  };

  const onCancelHandler = (event) => {
    event.stopPropagation();
    setEditMode(false);
  };

  const getAnswer = () => {
    if (editAnswerComponent) return editAnswerComponent;
    if (editMode) {
      switch (questionType) {
        default: {
          return (
            <Text
              isPending={isPending}
              value={answer}
              onSave={onSaveHandler}
              onCancel={onCancelHandler}
            />
          );
        }
      }
    }

    if (questionType === FORM_QUESTION_TYPE.FileUpload && answer) {
      let file = JSON.parse(answer);
      return <div className={styles.answerHolder}>{file?.Filename}</div>;
    } else if (questionType === FORM_QUESTION_TYPE.Signature && answer) {
      let imgUrl = JSON.parse(answer);
      return <img className={styles.signature} src={imgUrl?.Url} alt="Signature" />;
    } else {
      return (
        <div className={styles.answerHolder}>
          {transformJSXResponse(
            answer,
            questionType === FORM_QUESTION_TYPE.Date ? ' - ' : undefined,
          )}
        </div>
      );
    }
  };

  return (
    <div className={classNames(styles.summaryItem, className)}>
      <div className={styles.left}>
        <div className={styles.question}>{`${question}`}</div>
        <div className={styles.answer}>{getAnswer()}</div>
      </div>
      <div className={styles.right}>
        {!editAnswerComponent && (
          <div onClick={onEditHandler} className={styles.edit}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 14H4.96997L13.3849 5.58507C13.7787 5.19123 14 4.65707 14 4.10009C14 3.54311 13.7787 3.00894 13.3849 2.6151C12.9911 2.22126 12.4569 2 11.8999 2C11.3429 2 10.8088 2.22126 10.4149 2.6151L2 11.03V14Z"
                stroke="#163C5A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.5 4L12 6.5"
                stroke="#163C5A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

SummaryItem.propTypes = {
  className: PropTypes.string,
  questionType: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  editAnswerComponent: PropTypes.node,
  onEdit: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SummaryItem.defaultProps = {
  className: '',
  questionType: '',
  question: '',
  answer: '',
  editAnswerComponent: undefined,
  onEdit: undefined,
  id: undefined,
};

export default SummaryItem;
