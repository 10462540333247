import { Dispatch, FC, Fragment, SetStateAction, useCallback, useEffect, useState } from 'react';

import { Button, Modal } from 'components-antd';
import { ProfileIcons } from '../Icons';
import { AddEditPreApprovalDocument } from 'components';

import { UploadIcon } from 'components/Icons/UploadIcon';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikProps, Form, FieldArray } from 'formik';
import { PreApprovalValues, preApprovalArraySchema, validationSchema } from './types';
import {
  addPreApprovalDocuments,
  getPreApprovalDocumentsWithManualEntries,
  editPreApprovalDocuments,
} from 'api/mySearches';
import { getUserId } from 'store/selectors/user';
import { PreApprovalItem } from 'components';

import styles from './styles.module.scss';
import { InitialValueTypes } from './types';
import { showSuccessMessage } from 'helpers';
import { PreApprovalListing } from 'components';

interface Props {
  clientId?: string;
  setShowApprovalsModal: Dispatch<SetStateAction<boolean>>;
  showApprovalsModal: boolean;
}

const initialValues: InitialValueTypes = {
  preApprovalsDocuments: [
    {
      Amount: '',
      ApprovedBy: '',
      ApprovalDate: null,
      ExpirationDate: null,
      PreApprovalDocuments: [],
    },
  ],
  isManualDataInput: false,
};

export const ClientPreApprovals: FC<Props> = ({
  clientId,
  showApprovalsModal,
  setShowApprovalsModal,
}) => {
  const [formattedDocuments, setFormattedDocuments] = useState<object[]>([]);
  const [documents, setDocuments] = useState<any[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editPreApprovalId, setEditPreApprovalId] = useState<number | null>(null);
  const [preApprovalInitialValues, setPreApprovalInitialValues] = useState({ ...initialValues });

  const userId = useSelector(getUserId);

  const handleUploadIconClick = (e) => {
    e.preventDefault();
    setShowApprovalsModal(true);
  };

  const getPreApprovalDocuments = async () => {
    const documents = await getPreApprovalDocumentsWithManualEntries({
      clientId,
      agentId: userId,
    });
    if (documents?.status === 200 && documents?.data?.success) {
      setDocuments(documents?.data?.result);
    }
  };

  useEffect(() => {
    getPreApprovalDocuments();
  }, []);

  useEffect(() => {
    const formatted: any[] = [];
    documents?.forEach((document) => {
      const currentDate = moment(new Date()).format('MMMM D, YYYY');
      let date = moment(new Date(document.CreatedDate)).format('MMMM D, YYYY');
      if (date === currentDate) date = 'Today';
      const index = formatted.findIndex((f) => f[date] !== undefined);

      if (index === -1) {
        formatted.push({ [date]: [document] });
      } else {
        formatted[index][date].push(document);
      }
    });
    setFormattedDocuments(formatted);
  }, [documents]);

  const handlePreApprovalFormSubmit = async (data: InitialValueTypes) => {
    if (data.isManualDataInput || currentStep === 1) {
      setIsLoading(true);

      if (editPreApprovalId) {
        const response = await editPreApprovalDocuments({
          ...data.preApprovalsDocuments[0],
          ClientId: clientId,
          preApprovalId: editPreApprovalId,
        });
        setIsLoading(false);
        if (response.status === 200) {
          getPreApprovalDocuments();
          setShowApprovalsModal(false);
          showSuccessMessage('Pre-Approval updated successfully');
        }
        return;
      }

      const response = await addPreApprovalDocuments({
        ...data,
        ClientId: clientId,
      });
      setIsLoading(false);
      if (response.status === 200) {
        getPreApprovalDocuments();
        setShowApprovalsModal(false);
        setPreApprovalInitialValues({ ...initialValues });
        showSuccessMessage('Pre-Approval created successfully');
      }
    } else if (!data.isManualDataInput || currentStep === 0) {
      setCurrentStep(1);
    }
  };
  const handleEditManualEntry = (id) => {
    const manualEntry = documents.find((document) => document.Id === id);
    if (!manualEntry) return;
    const editValues: InitialValueTypes = {
      isManualDataInput: true,
      preApprovalsDocuments: [
        {
          Amount: manualEntry.Amount ? manualEntry.Amount?.toString() : '',
          ApprovedBy: manualEntry?.ApprovedBy ?? '',
          ApprovalDate: manualEntry?.ApprovalDate ? new Date(manualEntry.ApprovalDate) : null,
          ExpirationDate: manualEntry?.ExpirationDate ? new Date(manualEntry.ExpirationDate) : null,
          PreApprovalDocuments: (manualEntry?.Documents as any) ?? [],
        },
      ],
    };
    setPreApprovalInitialValues({ ...editValues });
    setShowApprovalsModal(true);
    setEditPreApprovalId(id);
  };
  useEffect(() => {
    if (!showApprovalsModal) {
      setPreApprovalInitialValues({ ...initialValues });
      setEditPreApprovalId(null);
      setIsLoading(false);
    }
  }, [showApprovalsModal]);
  return (
    <div>
      <Modal
        open={showApprovalsModal}
        width={675}
        footer={null}
        onCancel={() => setShowApprovalsModal(false)}
        className={styles.preApprovalsModal}
        cancelButtonStyles={styles.crossButton}
        closeIconStyles={styles.crossButton}
        destroyOnClose={true}
      >
        <Formik
          enableReinitialize
          initialValues={preApprovalInitialValues as any}
          onSubmit={handlePreApprovalFormSubmit}
          validationSchema={preApprovalArraySchema}
        >
          <Form>
            <AddEditPreApprovalDocument
              editPreApprovalId={editPreApprovalId}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              isLoading={isLoading}
              initialValues={initialValues}
              setPreApprovalInitialValues={setPreApprovalInitialValues}
            />
          </Form>
        </Formik>
      </Modal>
      <div className={styles.expandedTransactionHeading}>
        <div className={styles.headingItemsWrapper}>
          <div className={styles.headingWrapper}>
            <ProfileIcons name={'File'} />
            <h3>Pre-Approvals({documents?.length})</h3>
          </div>
          <Button
            className={styles.iconButton}
            icon={<UploadIcon onClick={handleUploadIconClick} className="" />}
          />
        </div>
      </div>
      <PreApprovalListing
        formattedDocuments={formattedDocuments}
        clientId={clientId}
        showApprovalsModal={showApprovalsModal}
        setShowApprovalsModal={setShowApprovalsModal}
        handleEditManualEntry={handleEditManualEntry}
        getPreApprovalDocuments={getPreApprovalDocuments}
        initialValues={initialValues}
        setPreApprovalInitialValues={setPreApprovalInitialValues}
        setEditPreApprovalId={setEditPreApprovalId}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};
