import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Marker, InfoWindow } from '@react-google-maps/api';
import MarkerActive from 'components/Map/images/marker-active.svg';
import MarkerSeen from 'components/Map/images/marker-viewed.svg';

import { propertySeenOnMap, setPropertyToScrollInList } from 'store/actions/feed';
import { getAllSeenOnMapSelector, getFeedViewTypeSelector } from 'store/selectors/feed';

import { YelpLogo } from 'components/Icons';
import { YelpRating } from 'components';

import Image from '../Image';
import InfoSlider from '../InfoSlider';
import Content from '../Content';
import Price from '../Price';
import Info from '../Info';

import styles from './styles.module.scss';
import { preparePriceForMarkup } from 'store/selectors/feed';
import { MAP_VIEWS } from '../../../../settings/constants/mapView';
import { getSearchResultsViewTypeSelector } from '../../../../store/selectors/searchResults';

const MultiplePlacesMarkerComponent = (props) => {
  const dispatch = useDispatch();
  const seenProperties = useSelector(getAllSeenOnMapSelector);
  const feedViewType = useSelector(getFeedViewTypeSelector);
  const searchViewType = useSelector(getSearchResultsViewTypeSelector);
  const { className, imageClassName, onClickMarker, getPropertyLink, maxWidth } = props;
  const { properties, clusterer, longitude, latitude, id } = props;
  const [isHover, setIsHover] = useState(false);

  const [markerMap, setMarkerMap] = useState({});
  const [open, setOpen] = useState(false);
  const [isMapOnlyView, setIsMapOnlyView] = useState(
    feedViewType.view === MAP_VIEWS.MAP || searchViewType.view === MAP_VIEWS.MAP,
  );

  const anyInfo = properties.some(({ info, image, price }) => info || image || price);
  const highlight = properties.some((property) => !!property.highlight);
  const isHighlighted = open || isHover || highlight;

  useEffect(() => {
    setIsMapOnlyView(feedViewType.view === MAP_VIEWS.MAP || searchViewType.view === MAP_VIEWS.MAP);
  }, [feedViewType]);

  const onClickMarkerHandler = () => {
    if (anyInfo && isMapOnlyView) {
      setOpen(!open);
    }
    onClickMarker(id);
    dispatch(propertySeenOnMap({ id }));
    if (!isMapOnlyView) {
      dispatch(setPropertyToScrollInList({ id }));
    }
  };

  const markerLoadHandler = (marker) =>
    setMarkerMap((prevState) => ({ ...prevState, [id]: marker }));

  return (
    <div className={classNames(className)}>
      {open && anyInfo && (
        <InfoWindow
          anchor={markerMap[id]}
          position={{ lng: longitude, lat: latitude }}
          options={{ maxWidth }}
        >
          <InfoSlider
            slides={properties.map((property) => {
              const {
                title,
                distance,
                rating,
                yelpIcon,
                info,
                image,
                price,
                id: propertyId,
              } = property;

              const propertyLink = getPropertyLink ? getPropertyLink(propertyId) : null;

              const PropertyImage = () =>
                propertyLink ? (
                  <Link to={propertyLink}>
                    <Image
                      className={classNames(styles.image, imageClassName)}
                      src={image}
                      alt=""
                    />
                  </Link>
                ) : (
                  <Image className={classNames(styles.image, imageClassName)} src={image} alt="" />
                );

              return (
                <div key={propertyId} className={styles.propertyPreview}>
                  <PropertyImage />
                  <Content>
                    {title && (
                      <div className={styles.title}>
                        <b>{title}</b>
                        {distance && <span>{distance}</span>}
                      </div>
                    )}
                    {rating && (
                      <div className={styles.rating}>
                        <YelpRating rating={rating} />
                        {yelpIcon && <YelpLogo className={styles.yelpIcon} />}
                      </div>
                    )}
                    <Price value={price} />
                    <Info value={info} />
                  </Content>
                </div>
              );
            })}
          />
        </InfoWindow>
      )}
      <div onClick={onClickMarkerHandler}>
        <Marker
          position={{ lng: longitude, lat: latitude }}
          onClick={onClickMarkerHandler}
          onLoad={(marker) => markerLoadHandler(marker)}
          clusterer={clusterer}
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
          icon={{
            url: seenProperties.includes(id) ? MarkerSeen : MarkerActive,
            anchor: new window.google.maps.Point(20, 20),
            labelOrigin: new window.google.maps.Point(40, 20),
          }}
          label={{
            text: preparePriceForMarkup(properties[0].price),
            className: styles.customMapMarkerLabel,
          }}
          noClustererRedraw
        />
      </div>
    </div>
  );
};

MultiplePlacesMarkerComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  onClickMarker: PropTypes.func,
  getPropertyLink: PropTypes.func,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      geometry: PropTypes.shape({
        coordinates: PropTypes.shape({}),
      }),
    }),
  ),
  clusterer: PropTypes.shape({}).isRequired,
  maxWidth: PropTypes.number,
  longitude: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
};

MultiplePlacesMarkerComponent.defaultProps = {
  className: '',
  imageClassName: '',
  onClickMarker: () => {},
  getPropertyLink: () => {},
  properties: [],
  maxWidth: 156,
};

export default MultiplePlacesMarkerComponent;
