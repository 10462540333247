import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { searchMessagesClearDataAction } from 'store/actions/searchMessages';
import { isSearchMessagesLoading } from 'store/selectors/searchMessages';
import { requestGetSearchThreadMessagesByStringEffect } from 'store/effects/searchMessages';

import { SearchFieldWithDebounce } from 'components';

const MessagesDrawerSearchMessages = ({ className, classNameInput, placeholder }) => {
  const isLoading = useSelector(isSearchMessagesLoading);

  return (
    <SearchFieldWithDebounce
      isLoading={isLoading}
      sendAction={requestGetSearchThreadMessagesByStringEffect}
      resetAction={searchMessagesClearDataAction}
      placeholder={placeholder}
      className={className}
      classNameInput={classNameInput}
    />
  );
};

MessagesDrawerSearchMessages.propTypes = {
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  placeholder: PropTypes.string,
};

MessagesDrawerSearchMessages.defaultProps = {
  className: null,
  classNameInput: null,
  placeholder: 'Search messages',
};

export default MessagesDrawerSearchMessages;
