import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import RequestCategoriesWrapper from 'pages/Services/components/RequestCategoriesWrapper';
import CategoriesList from 'pages/Services/components/CategoriesList';
import { getConciergeSearchSelector } from 'store/selectors/concierge/conciergeSearch';
import { openLocationModalEffect } from 'store/effects/concierge';
import { Plus, Minus } from 'components/Icons';
import { Row } from 'components-antd';
import { getUserRoleSelector } from 'store/selectors/user';
import { AGENT } from 'settings/constants/roles';

import styles from './styles.module.scss';

interface ConciergeCategoriesListProps {
  setIsSearch: (arg0: boolean) => void;
}

export const ConciergeCategoriesList = ({ setIsSearch }: ConciergeCategoriesListProps) => {
  const dispatch = useDispatch();
  const list = useSelector(getAllServicesCategoriesList);
  const userRole = useSelector(getUserRoleSelector);

  const [showAllCategories, setShowAllCategories] = useState<boolean>(false);

  const { areasOfOperation } = useSelector(getConciergeSearchSelector);
  const openModal = () => {
    dispatch(openLocationModalEffect(true));
  };

  const handleCategoryClick = () => {
    setIsSearch(true);
    if (userRole === AGENT) return;

    if (!areasOfOperation.length) {
      openModal();
    }

    return;
  };

  const sortedList = list.sort((a: { Title: string }, b: { Title: string }) =>
    a.Title.localeCompare(b.Title),
  );

  const itemsToShow = showAllCategories ? sortedList : sortedList.slice(0, 9);

  return (
    <RequestCategoriesWrapper className={styles.wrapper}>
      <Row justify="start" className={styles.dividerWrapper}>
        Browse
      </Row>
      <CategoriesList items={itemsToShow} onClick={handleCategoryClick} />
      <Row justify="center" className={styles.showMoreButtonWrapper}>
        {sortedList.length > 9 && (
          <button
            className={styles.showMoreButton}
            onClick={() => setShowAllCategories((prev) => !prev)}
          >
            <div>
              <div>{showAllCategories ? <Minus /> : <Plus />}</div>
              <div>{showAllCategories ? 'Hide' : 'Show All Categories'}</div>
            </div>
          </button>
        )}
      </Row>
    </RequestCategoriesWrapper>
  );
};
