import { Document, Page } from 'react-pdf';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Spinner } from 'components';
import { useRef } from 'react';
import { useResize } from 'hooks';
import { MOBILE_WIDTH } from '../../../helper';

export const FormPageSection = (props) => {
  const { documentData, loading, pageNumber, docIndex, setOpenList } = props;
  const pageRefs = useRef({});

  if (!documentData || documentData.length === 0) return <></>;

  const {
    screen: { width },
  } = useResize();

  const onPageChange = (documentIndex, pageIndex) => {
    const key = `pdf-page-${documentIndex}-${pageIndex}`;
    const element = document.getElementById(key);

    element?.scrollIntoView({
      behavior: 'smooth',
    });

    width <= MOBILE_WIDTH && setOpenList(false);
  };

  return (
    <div className={styles.pageSection}>
      {documentData.map((document, index) => (
        <div key={`document-${index}`} className={styles.documentSection}>
          <div className={styles.pageSectionHeader}>
            <div className={styles.pagesTitle}>Pages</div>
            {!loading && (
              <div className={styles.pagesCount}>
                {index === +props.docIndex ? props.pageNumber + 1 : 1} of {document.totalPages}
              </div>
            )}
          </div>

          {loading ? (
            <Spinner loaderClassName={styles.loadingSpinner} />
          ) : (
            <div className={styles.pages}>
              <Document file={document.documentBuffer} className={classNames(styles.pdfDocument)}>
                {document.pdfDoc?.getPages().map((page, pageIndex) => (
                  <div
                    ref={(el) => {
                      pageRefs.current[`pdf-page-${index}-${pageIndex}`] = el;
                    }}
                    key={`page-${index}-${pageIndex}`}
                    id={`page-${index}-${pageIndex}`}
                  >
                    <Page
                      key={`pdf-page-${pageIndex}`}
                      className={classNames(styles.documentPage, {
                        [styles.activePage]: index === +docIndex && pageIndex === pageNumber,
                      })}
                      pageNumber={pageIndex + 1}
                      renderInteractiveForms={false}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      onClick={() => onPageChange(index, pageIndex)}
                    >
                      <div className={styles.pageNumber}>{pageIndex + 1}</div>
                    </Page>
                  </div>
                ))}
              </Document>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
