import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFormMetaEffect } from 'store/effects/formProcess';

export const useFetchFormMetaData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFormMetaEffect());
  }, []);
};
