export enum INDICATOR_TYPE {
  StudentDiversity = 'Student Diversity',
  TestScores = 'Test Scores',
  CollegeReadiness = 'College Readiness',
  EquityOverview = 'Equity Overview',
  TeachersAndStaff = 'Teachers and Staff',
  Academics = 'Academics',
  Teachers = 'Teachers',
  Diversity = 'Diversity',
  CollegePrep = 'College Prep',
}
