import classNames from 'classnames';

import styles from './styles.module.scss';

export const MilestoneModalHeader = () => {
  return (
    <div className={styles.taskModalHeader}>
      <div>
        <h1 className={styles.heading}>New Milestone</h1>
      </div>
    </div>
  );
};
