import React, { ReactElement } from 'react';

import styles from './styles.module.scss';
import classNames from 'classnames';

export const FormContainer: React.FC<{ children: any; classname?: string }> = ({
  children,
  classname,
}) => {
  return <div className={classNames(styles.formContainer, classname)}>{children}</div>;
};
