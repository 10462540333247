import React from 'react';

export const ListingIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M14.5 7C14.5 6.17157 15.1716 5.5 16 5.5H32C32.8284 5.5 33.5 6.17157 33.5 7V35.3148C33.5 35.9694 32.9694 36.5 32.3148 36.5H16C15.1716 36.5 14.5 35.8284 14.5 35V7Z"
        fill="#7370AB"
      />
      <path
        d="M5.5 17C5.5 16.1716 6.17157 15.5 7 15.5H19.5C20.3284 15.5 21 16.1716 21 17V36.5H7C6.17157 36.5 5.5 35.8284 5.5 35V17Z"
        fill="#51BFE1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 36.5H21V17C21 16.1716 20.3284 15.5 19.5 15.5H14.5V35C14.5 35.8284 15.1716 36.5 16 36.5Z"
        fill="#3D33B1"
      />
    </svg>
  );
};
