import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';

import { PhoneNumber, InputLabel, enforceFormat, formatToPhone } from 'components';
import { TransactionContact } from 'types';
import ContactAutoComplete from './ContactAutoComplete';
import {
  getTransactionContactsEffect,
  createTransactionContactEffect,
  updateTransactionContactEffect,
  getTransactionRolesEffect,
} from 'store/effects/transactions';
import { getAllTransactionContactsSelector } from 'store/selectors/transactions/read';
import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { ContactModalHeader } from './ContactModalHeader';
import { ContactField } from './ContactField';
import { Role } from './Role';
import { ValidationSchema } from './validation';

import styles from './styles.module.scss';
import { Checkbox } from 'antd';
import { Delete, Email } from 'components/Icons';
import { getRelatedContactsFormattedList } from 'store/selectors/drawers/addParticipants';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { requestGetTeamListEffect } from 'store/effects';
import {
  requestGetUserParticipantAndContactsListEffect,
  requestGetUserParticipantsListEffect,
} from 'store/effects/drawers/addParticipants';

interface ContactModalFormProps {
  isOpen?: boolean;
  isEditing?: boolean;
  editData?: TransactionContact;
  onCloseModal?: () => void;
  onUpdate?: () => void;
  onRemove?: () => void;
  className?: string;
}

export const ContactModalForm = ({
  className,
  onCloseModal,
  editData,
  isEditing,
}: ContactModalFormProps) => {
  const dispatch = useDispatch();
  const params: { id?: string } = useParams();
  const [isPending, setIsPending] = useState(false);
  const userId = useSelector(getUserId);
  let allContacts = useSelector(getRelatedContactsFormattedList) || [];

  const initialContactData: TransactionContact = {
    Name: '',
    CompanyName: '',
    Email: '',
    Phone: '',
    Role: '',
    CreatedBy: userId,
    IsPrivate: false,
  };

  useEffect(() => {
    if (editData) {
      formik.setValues(editData);
    } else {
      formik.setValues(initialContactData);
    }
  }, [editData]);

  const { isAgent } = useSelector(getUserRolesMapSelector);

  useEffect(() => {
    if (isAgent) {
      dispatch(
        requestGetUserParticipantAndContactsListEffect({
          includeAgents: true,
        }),
      );
    }
  }, []);

  useEffect(() => {
    dispatch(getTransactionRolesEffect());
  }, []);

  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);

  useEffect(() => {
    if (isTeamAgentActive) {
      dispatch(requestGetTeamListEffect());
    }
  }, []);

  useEffect(() => {
    dispatch(requestGetUserParticipantsListEffect({}, { silent: true }));
  }, []);

  const handleFormRequest = (values) => {
    setIsPending(true);

    const requestPayload = isEditing
      ? { id: params?.id, contactId: editData?.Id, contact: values }
      : { id: params?.id, contact: values };

    if (isEditing) {
      dispatch(
        updateTransactionContactEffect(requestPayload, (err) => {
          if (!err) {
            dispatch(getTransactionContactsEffect({ id: params?.id }));
            onCloseModal?.();
          }
          setIsPending(false);
        }),
      );
    } else {
      dispatch(
        createTransactionContactEffect(requestPayload, {}, (err) => {
          if (!err) {
            dispatch(getTransactionContactsEffect({ id: params?.id }));
            onCloseModal?.();
          }
          setIsPending(false);
        }),
      );
    }
  };

  const onSubmit = (values) => {
    const result = Object.keys(values)
      .filter((key) => !!values[key])
      .reduce((newValue, key) => ({ ...newValue, [key]: values[key] }), {});
    delete result['Id'];
    handleFormRequest({ ...result, IsPrivate: values?.IsPrivate });
    onCloseModal?.();
  };

  const formik = useFormik({
    initialValues: initialContactData,
    validationSchema: ValidationSchema,
    validateOnChange: true,
    onSubmit,
  });

  const insertRole = (val, roleId?) => {
    const inputValue = (val || '').trim();

    if (inputValue !== '') {
      formik.setFieldValue('Role', val);
    }
  };

  const onBlurRoleInput = (event) => {
    insertRole(event.target.value);
  };

  const onRoleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const customRoleValue = event.target.value.trim();
      insertRole(customRoleValue);
      formik.setFieldValue('Role', customRoleValue);
      event.target.blur();
    }
  };

  const onContactInputChange = (selectedContact, value) => {
    if (!selectedContact && value !== '') {
      formik.setValues({ ...formik.values, Name: value });
    } else if (!selectedContact && value === '') {
      formik.setValues(initialContactData);
    }
  };
  const onContactInputSelect = (selectedContact) => {
    formik.setValues(selectedContact);
  };
  return (
    <div className={styles.contactModalForm}>
      <ContactModalHeader mode={isEditing ? 'edit' : 'add'} />
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(styles.content, className)}>
          <ContactAutoComplete
            value={formik.values.Name}
            onChange={(selectedContact, value) => onContactInputChange(selectedContact, value)}
            onSelect={(selectedContact) => onContactInputSelect(selectedContact)}
            allContacts={allContacts}
            error={formik.touched.Name ? formik.errors.Name : ''}
          />
          <div className={styles.rolesWrapper}>
            <Role
              value={formik.values.Role}
              onChange={(val) => formik.setFieldValue('Role', val)}
              className={styles.roleField}
              error={formik.touched.Role ? formik.errors.Role : ''}
              onBlurRoleInput={onBlurRoleInput}
              onRoleKeyPress={onRoleKeyPress}
            />
          </div>
          <ContactField
            value={formik.values.CompanyName}
            label="Company"
            onChange={(val) => formik.setFieldValue('CompanyName', val)}
            error={formik.touched.CompanyName ? formik.errors.CompanyName : ''}
          />
          <ContactField
            value={formik.values.Email}
            label="Email"
            onChange={(val) => formik.setFieldValue('Email', val)}
            error={formik.touched.Email ? formik.errors.Email : ''}
          />
          <InputLabel label="Phone Number" className={styles.phoneLabel} />
          <PhoneNumber
            className={styles.phoneField}
            value={formik.values.Phone}
            onUpdate={(val) => formik.setFieldValue('Phone', val)}
            onKeyDown={enforceFormat}
            onKeyUp={formatToPhone}
            error={formik.touched.Phone ? formik.errors.Phone : ''}
          />
          <Checkbox
            onChange={(e) => formik.setFieldValue('IsPrivate', e.target.checked)}
            className={classNames(styles.privateCheckbox, 'mosaikCheckbox')}
            value={formik.values.IsPrivate}
            checked={formik.values.IsPrivate}
          >
            Private Contact
          </Checkbox>
        </div>
        <div
          className={classNames(styles.footerButton, {
            [styles.addFooterButton]: !isEditing,
          })}
        >
          <div
            className={classNames(styles.footerBtnDiv, {
              [styles.editBtnDiv]: isEditing,
            })}
          >
            <button
              className={classNames(styles.btn, styles.addBtn, {
                [styles.addBtnDisabled]: isPending,
              })}
              type="submit"
              disabled={isPending}
            >
              <span>Save</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
