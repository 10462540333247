/* eslint-disable no-unsafe-optional-chaining */
import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { useSetSearch } from 'hooks';

import { useDispatch, useSelector } from 'react-redux';
import { Input, Locations } from 'components';
import { SORT_ASC, SORT_DESC } from 'settings/constants/sort';
import { setSearchCriteriaEffect } from 'store/effects/search';
import {
  getSearchCriteriaSelector,
  getEditedSearchSelector,
  getSearchViewportCoordinatesSelector,
  getViewportSearchSelector,
  getSearchLocationsSelector,
} from 'store/selectors/search';
import { sortSearchResultsEffect } from 'store/effects/searchResults';
import {
  getSearchPageInfoSelector,
  getSearchResultsSelector,
  getSearchResultsSortSelector,
} from 'store/selectors/searchResults';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getSearchResultsFilterDrawerSelector } from 'store/selectors/searchResults';
import MoreFilters from './MoreFilters';
import SaveSearchButton from './SaveSearchButton';
import Sort from './Sort';
import { EDIT } from 'settings/constants/mode';
import { useNoMlsAccessHandler } from 'pages/Properties/Feed/Properties/hooks/useNoMlsAccessHandler';
import { getSearchResultsV2Effect } from 'store/effects/searchResults/searchV2';
import { getSearchDrawnPolygonSelector } from 'store/selectors/search';
import {
  cleanSearchViewportCoordinatesAction,
  requestGetSearchResultsAction,
  setSearchGoogleLocationAction,
} from 'store/actions/searchResults';
import Icon from 'pages/Properties/SearchResults/Icons';

import styles from './styles.module.scss';
import { getActivePropertiesSelector, getMultipleModSelector } from 'store/selectors/feed';
import { LocationType } from 'types';
import { resetFullDataComparePropertiesEffect, setMultipleModeEffect } from 'store/effects';
import Multiple from 'pages/Properties/Feed/Properties/PropsHeader/Actions/Multiple';

const LocalHeader = (props) => {
  const { className, shouldFilterStateLevelResults } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { mode, isClientSearch } = useSelector(getSearchResultsFilterDrawerSelector);
  const search = useSelector(getSearchCriteriaSelector);
  const editedSearch = useSelector(getEditedSearchSelector);
  const isEditMode = mode === EDIT;
  const sort = useSelector(getSearchResultsSortSelector);
  const { isPending } = useSelector(getSearchResultsSelector);
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  const currentPageInfo = useSelector(getSearchPageInfoSelector);

  const activePropsSelector = useSelector(getActivePropertiesSelector);

  // changed SellingPrice to ListPrice
  const { searchValue, updateSearchValue } = useSetSearch('search');

  const sortCases = useMemo(() => {
    if (isAgent) {
      return [
        { label: 'Client Match', value: 'ClientsMatched' },
        { label: 'Price', value: 'SellingPrice' },
        { label: 'Days on Market', value: 'DaysOnMarket' },
        { label: 'Beds', value: 'NumBeds' },
        { label: 'Baths', value: 'NumBathsTotal' },
        { label: 'Square Feet', value: 'SquareFeet' },
      ];
    }
    return [
      { label: 'Days on Market', value: 'DaysOnMarket' },
      { label: 'Match Score', value: 'MatchScore' },
      { label: 'Price', value: 'SellingPrice' },
      { label: 'Beds', value: 'NumBeds' },
      { label: 'Baths', value: 'NumBathsTotal' },
      { label: 'Square Feet', value: 'SquareFeet' },
    ];
  }, [isAgent]);

  useEffect(() => {
    if (!sort?.fields?.length) {
      if (isAgent) {
        dispatch(sortSearchResultsEffect({ order: SORT_DESC, fields: ['ClientsMatched'] }));
      } else if (isClient) {
        dispatch(sortSearchResultsEffect({ order: SORT_ASC, fields: ['DaysOnMarket'] }));
      }
    }
  }, [isAgent, isClient, dispatch, sort]);

  const { wrappedEffect: getSearchResultsEffectWrapped } =
    useNoMlsAccessHandler(getSearchResultsV2Effect);

  const onChangeSearchCriteria = useCallback(
    (val, propName) => {
      // dispatch(cleanSearchViewportCoordinatesAction());
      dispatch(setSearchCriteriaEffect({ [propName]: val }));
      val ? dispatch(getSearchResultsEffectWrapped()) : dispatch(requestGetSearchResultsAction({}));
    },
    [dispatch],
  );

  const customSearchPolygon = useSelector(getSearchDrawnPolygonSelector);
  const searchLocations = useSelector(getSearchLocationsSelector);
  const viewportCoordinates = useSelector(getSearchViewportCoordinatesSelector);
  const multiple = useSelector(getMultipleModSelector);

  const onLocationsChanged = useCallback(
    (result, preparedData) => {
      const locationsWithCustomShapes =
        searchLocations?.filter((location) => location.Type === LocationType.Polygon) ?? [];

      const locationsWithViewport =
        searchLocations?.filter((location) => location.Type === LocationType.ViewportCoordinates) ??
        [];

      const notEmpty =
        preparedData?.length || locationsWithCustomShapes?.length || locationsWithViewport?.length;

      const updatedSearch = notEmpty
        ? {
            ...JSON.parse(searchValue),
            Locations: [...locationsWithCustomShapes, ...locationsWithViewport, ...preparedData],
          }
        : '';

      updateSearchValue(updatedSearch);
      dispatch(setSearchGoogleLocationAction(preparedData.length ? preparedData : []));
      if (!notEmpty) return;

      dispatch(getSearchResultsEffectWrapped());
    },
    [setSearchGoogleLocationAction, searchLocations],
  );

  // only make API call on first render
  // useEffect(() => {
  //   if (
  //     (!currentPageInfo?.currentFEPageInfo?.fromListingDetailPage ||
  //       !activePropsSelector?.data?.length) &&
  //     !customSearchPolygon &&
  //     !viewportCoordinates
  //   ) {
  //     dispatch(getSearchResultsEffectWrapped());
  //   }
  // }, []);

  // only make API call when customSearchPolygon is changed and contains a value
  useEffect(() => {
    if (
      (!currentPageInfo?.currentFEPageInfo?.fromListingDetailPage ||
        !activePropsSelector?.data?.length) &&
      customSearchPolygon
    ) {
      const updatedSearch = {
        ...JSON.parse(searchValue),
        Locations: searchLocations,
      };
      updateSearchValue(updatedSearch);
      dispatch(getSearchResultsEffectWrapped());
    }
  }, [customSearchPolygon]);

  const getSearchValues = () => {
    return isEditMode ? editedSearch?.data?.criterias : search || {};
  };

  const onClickMultiple = () => {
    if (!multiple) {
      dispatch(setMultipleModeEffect(true));
    } else {
      dispatch(setMultipleModeEffect(false));
      dispatch(resetFullDataComparePropertiesEffect());
    }
  };

  return (
    <div testid="search_header" className={classNames(styles.localHeader, className)}>
      <div className={styles.searches}>
        <Icon variant={Icon.BACK} className={styles.backBtn} onClick={() => history.goBack()} />
        <div className={styles.locationContainer}>
          <Locations
            className={classNames(styles.locations, styles.field)}
            valuesWrapperClassName={styles.locationsValues}
            onResult={onLocationsChanged}
            value={(isEditMode
              ? editedSearch?.data?.criterias?.Locations
              : search?.Locations
            )?.filter(
              (loc) =>
                loc.Type !== LocationType.Polygon && loc.Type !== LocationType.ViewportCoordinates,
            )}
            disabled={isPending}
            testid="locations"
            variant={Locations.ROUND}
            activeInputIconClassName={styles.locationsIcon}
            dropdownClassName={styles.locationsDropdown}
            popupClassName={styles.locationsPopup}
            shouldFilterStateLevelResults={shouldFilterStateLevelResults}
            allowedSmartyAddress={true}
          />
        </div>
        {/* <Price className={styles.field} value={search?.PriceRange} disabled={isPending} /> */}
        {/* <BadAndBaths
          className={styles.field}
          value={{
            NumBedroomsRange: search?.NumBedroomsRange,
            NumBathroomsRange: search?.NumBathroomsRange,
          }}
          disabled={isPending}
        /> */}
        <MoreFilters
          search={getSearchValues()}
          className={styles.field}
          disabled={isPending}
          mode={mode}
          isClientSearch={isClientSearch}
        />
        <Sort className={styles.field} disabled={isPending} sortCases={sortCases} />
        <SaveSearchButton
          search={getSearchValues()}
          className={styles.field}
          mode={mode}
          disabled={isPending || searchLocations?.length < 1}
        />
        <Multiple className={classNames({ [styles.active]: multiple })} onClick={onClickMultiple} />
      </div>
      {/* <Options disabled={isPending} /> */}
    </div>
  );
};

LocalHeader.propTypes = {
  className: PropTypes.string,
};

LocalHeader.defaultProps = {
  className: '',
};

export default LocalHeader;
