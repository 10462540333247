import { handleActions } from 'redux-actions';
import { cloneDeep, get } from 'lodash-es';
import { resetEditedSearchAction } from 'store/actions/search';
import { setEditedSearchCriteriaAction } from 'store/actions/mySearches';
import { IDLE, READY } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  cleanSearchViewportCoordinatesAction,
  setSearchGoogleLocationAction,
  setSearchViewportCoordinatesAction,
} from 'store/actions/searchResults';
import { LocationType } from 'types';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [setEditedSearchCriteriaAction]: (state, { payload }) => ({
      ...state,
      state: READY,
      data: payload || {},
    }),
    [setSearchGoogleLocationAction]: (state, { payload }) => {
      const locations = state?.data?.criterias?.Locations;
      if (!locations) {
        return state;
      }
      const locationsWithViewportCoordinates = locations.filter(
        (location) => location.Type === LocationType.ViewportCoordinates,
      );

      const locationsWithCustomShape = locations.filter(
        (location) => location.Type === LocationType.Polygon,
      );

      let newLocationsList = [];
      if (payload.length) {
        newLocationsList = [...payload];
      } else {
        newLocationsList = [...locationsWithCustomShape, ...locationsWithViewportCoordinates];
      }

      return {
        ...state,
        data: {
          ...state.data,
          criterias: {
            ...state?.data?.criterias,
            Locations: newLocationsList,
          },
        },
      };
    },
    [setSearchViewportCoordinatesAction]: (state, { payload }) => {
      const viewPortCoordinatesLocationObject = {
        Type: LocationType.ViewportCoordinates,
        Coordinates: payload,
      };

      const locations = state?.data?.criterias?.Locations;
      if (!locations) {
        return state;
      }
      const locationsWithoutViewportCoordinates = locations.filter(
        (location) => location.Type !== LocationType.ViewportCoordinates,
      );

      return {
        ...state,
        data: {
          ...state.data,
          criterias: {
            ...state?.data?.criterias,
            Locations: [
              ...(locationsWithoutViewportCoordinates ? locationsWithoutViewportCoordinates : []),
              viewPortCoordinatesLocationObject,
            ],
          },
        },
      };
    },
    [cleanSearchViewportCoordinatesAction]: (state, { payload }) => {
      const locations = state?.data?.criterias?.Locations;
      if (!locations) {
        return state;
      }
      const locationsWithoutViewportCoordinates = locations.filter(
        (location) => location.Type !== LocationType.ViewportCoordinates,
      );

      return {
        ...state,
        data: {
          ...state.data,
          criterias: {
            ...state?.data?.criterias,
            Locations: locationsWithoutViewportCoordinates,
          },
        },
      };
    },
    [resetEditedSearchAction]: () => initialData,
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
