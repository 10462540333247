import React from 'react';
import classNames from 'classnames';

import { DrawerHeaderTitle, DrawerCancel } from 'components';
import { Icons } from '../../../Icons';

import styles from './styles.module.scss';

interface IHeader {
  className?: string;
  onClose: () => void;
  title: string;
}
export const Header: React.FC<IHeader> = ({ title, className, onClose }) => (
  <div className={classNames(styles.header, className)}>
    <div className={styles.titleWrapper}>
      <Icons variant="filter" className={styles.icon} />
      <DrawerHeaderTitle>{title}</DrawerHeaderTitle>
    </div>
    <DrawerCancel onClick={onClose} />
  </div>
);

Header.defaultProps = {
  className: '',
  onClose: () => {},
  title: 'Activity Filters',
};
