import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { preApprovalRoute } from 'settings/constants/preApproval';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';

const Exit = ({ className, onClick, text, testid }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const path = pathname.split('/');
  const isPreApproval = path[path.length - 1] === preApprovalRoute;

  const onExit = () => {
    if (typeof onClick === 'function') {
      return onClick();
    }

    history.push(isPreApproval ? routes.mySearches : routes.feed);
  };

  return (
    <div testid={testid} onClick={onExit} className={classNames(styles.exit, className)}>
      <div className={styles.close} />
      <span>{text || 'Exit'}</span>
    </div>
  );
};

Exit.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  testid: PropTypes.string,
};

Exit.defaultProps = {
  className: '',
  text: undefined,
  onClick: undefined,
  testid: undefined,
};

export default Exit;
