import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';

import { Avatar, UserMessage } from 'components';
import { ArrowRight } from 'components/Icons';

import styles from './styles.module.scss';

const ClientMessages = ({ data, unreadNumber, threads, archived, ...rest }) =>
  threads.length === 1 ? (
    <UserMessage user={data} thread={threads[0]} {...rest} />
  ) : (
    <div
      testid="thread_item"
      className={classNames(styles.item, { [styles.unread]: !!unreadNumber })}
      {...rest}
    >
      <div className={styles.itemImage}>
        <Avatar
          src={data.AvatarUrl}
          avatarClassName={styles.itemImageAvatar}
          placeholder={convertNameToAvatarPlaceholder(`${data.FirstName} ${data.LastName}`)}
        />
      </div>
      <div className={styles.itemContent}>
        <div className={styles.itemContentTitle}>
          <div testid="item_title" className={styles.itemContentTitleName}>
            {data?.ThirdParty?.BusinessName
              ? `${data.FirstName} ${data.LastName} (${data.ThirdParty.BusinessName})`
              : `${data.FirstName} ${data.LastName}`}
          </div>
        </div>
        <div testid="item_subtitle" className={styles.itemContentText}>
          <span>{`${threads.length}${archived ? ' archived' : ''} conversations`}</span>
          {!!unreadNumber && (
            <span className={styles.itemContentTextUnread}>{`, ${unreadNumber} unread`}</span>
          )}
        </div>
      </div>
      <div className={styles.itemRight}>
        <ArrowRight className={styles.itemRightIcon} />
      </div>
    </div>
  );

ClientMessages.propTypes = {
  data: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    AvatarUrl: PropTypes.string,
    ThirdParty: PropTypes.shape({
      LogoUrl: PropTypes.string,
      BusinessName: PropTypes.string,
    }),
  }).isRequired,
  threads: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  unreadNumber: PropTypes.number.isRequired,
  archived: PropTypes.bool,
};

ClientMessages.defaultProps = {
  archived: false,
};

export default ClientMessages;
