import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import {
  fetchTeamSubscriptionDetailsAction,
  requestGetTeamsDashboardAction,
  setSelectedTeamName,
} from 'store/actions/adminPanel';

const initialData = {
  state: IDLE,
  data: null,
  teamName: '',
  filters: {
    searchString: '',
  },
  meta: {},
  subscription: {
    state: IDLE,
    data: null,
    meta: {},
  },
};

export default handleActions(
  {
    [requestGetTeamsDashboardAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [setSelectedTeamName]: (state, { payload }) => ({
      ...state,
      teamName: payload,
    }),
    [fetchTeamSubscriptionDetailsAction.toString()]: (state, { payload }) => ({
      ...state,
      subscription: {
        ...state.subscription,
        state: get(payload, 'state'),
        data: get(payload, 'data'),
        meta: get(payload, 'meta'),
      },
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
