import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { flatMap } from 'lodash-es';

import {
  groupNotificationsTypes,
  NotificationTypesForGrouping,
} from 'settings/constants/notifications';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { setNotificationsDrawerParamsEffect } from 'store/effects/drawers/notifications';
import { markNotificationAsReadEffect } from 'store/effects/notifications';
import BaseNotification from '../BaseNotification';
import Notification from '../index';
import { getUserRolesMapSelector } from 'store/selectors/user';
import Title from '../../Title';
import { geNotificationsDrawerParamsSelector } from 'store/selectors/drawers/notifications';

import styles from './styles.module.scss';

const ByDate = (props) => {
  const { className, groupedNotificationsByDate } = props;
  const dispatch = useDispatch();
  const { isClient, isAgent } = useSelector(getUserRolesMapSelector);
  const drawerParams = useSelector(geNotificationsDrawerParamsSelector);

  const onClickHandler = (name, type) => {
    dispatch(setNotificationsDrawerParamsEffect({ currentNotificationsUser: name, type }));
  };

  const getSubtitle = (count, type) => {
    if (type === groupNotificationsTypes.Unread) {
      return `${count} new notifications`;
    }
    return `${count} notifications`;
  };

  const onMarkAllAsRead = (type, name) => {
    const notificationsIds = flatMap(groupedNotificationsByDate?.[type] || [], (notificData) =>
      (notificData?.notifications?.list || [])
        .filter((item) => item?.UserName === name)
        .map((notification) => notification?.Id),
    );
    dispatch(markNotificationAsReadEffect({ ids: notificationsIds }));
  };

  const getNotifications = (groupType) =>
    groupedNotificationsByDate[groupType].map((item) => {
      const { name, count, isUnread, avatarUrl, lastCreatedDate, notifications } = item;

      if (count === 1 && notifications?.list?.[0]) {
        const singleNotification = notifications?.list?.[0] || {};

        return (
          <Notification
            key={singleNotification?.Id}
            {...singleNotification}
            groupType={groupType}
            groupCount={count}
            {...(NotificationTypesForGrouping.NewListing.includes(singleNotification?.Type)
              ? { groupedNotifications: notifications?.list }
              : {})}
          />
        );
      }

      if ((isClient && count > 1) || (isAgent && drawerParams?.filter?.severityLevel)) {
        const types = [...new Set(notifications.list.map((li) => li.Type))];
        if (
          types.length === 1 &&
          Object.values(NotificationTypesForGrouping).flat(1).includes(types?.[0])
        ) {
          return (
            <Notification
              key={name}
              {...notifications?.list?.[0]}
              {...(NotificationTypesForGrouping.NewListing.includes(types?.[0])
                ? { groupedNotifications: notifications?.list }
                : {})}
              groupCount={count}
              groupIds={notifications?.list?.map((li) => li.Id) || []}
              groupType={groupType}
            />
          );
        }
      }

      return (
        <BaseNotification
          ids={notifications?.list?.map((li) => li.Id)}
          key={name}
          isRead={!isUnread}
          title={`${name}`}
          subtitle={getSubtitle(count, groupType)}
          date={lastCreatedDate}
          groupType={groupType}
          avatarPlaceholder={convertNameToAvatarPlaceholder(name)}
          avatarUrl={avatarUrl}
          onClick={() => onClickHandler(name, groupType)}
          markable={false}
          onMarkAllAsRead={() => onMarkAllAsRead(groupType, name)}
        />
      );
    });

  return (
    <div testid="by_date" className={styles.byDateWrapper}>
      <div className={classNames(styles.notificationsByDate, className)}>
        {!!groupedNotificationsByDate?.[groupNotificationsTypes.Unread] && (
          <div className={classNames(styles.part)}>
            <Title className={styles.title}>Unread</Title>
            {getNotifications(groupNotificationsTypes.Unread)}
          </div>
        )}
        {!!groupedNotificationsByDate?.[groupNotificationsTypes.Today] && (
          <div className={styles.part}>
            <Title className={styles.title}>Today</Title>
            {getNotifications(groupNotificationsTypes.Today)}
          </div>
        )}
        {!!groupedNotificationsByDate?.[groupNotificationsTypes.Earlier] && (
          <div className={styles.part}>
            <Title className={styles.title}>Earlier</Title>
            {getNotifications(groupNotificationsTypes.Earlier)}
          </div>
        )}
      </div>
    </div>
  );
};

ByDate.propTypes = {
  className: PropTypes.string,
  groupedNotificationsByDate: PropTypes.shape({
    notifications: PropTypes.shape({
      Unread: PropTypes.arrayOf(PropTypes.shape({})),
      Today: PropTypes.arrayOf(PropTypes.shape({})),
      Earlier: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

ByDate.defaultProps = {
  className: '',
};

export default ByDate;
