import { createAction } from 'redux-actions';

//offerAggregate
export const requestGetOfferAggregateByUserId = createAction(
  'REQUEST/GET_OFFER_AGGREGATE_BY_USER_ID',
);

export const resetOfferAggregateAction = createAction('RESET_OFFERS_AGGREGATE');
export const setOffersArchiveAction = createAction('SET_OFFERS_ARCHIVE');
export const setOffersArchiveIdAction = createAction('SET_OFFERS_ARCHIVE_ID');
export const setOffersFiltersAppliedAction = createAction('SET_OFFERS_FILTER_APPLIED');
