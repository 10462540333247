export const GET_FOLDER_LIST = '/mosaikform/getfolders';
export const ADD_FORM_FOLDER = '/mosaikform/addfolder';
export const EDIT_FORM_FOLDER = (folderId: number) => `/mosaikform/editfolder/${folderId}`;
export const DELETE_FORM_FOLDER = (folderId: number) => `/mosaikform/deletefolder/${folderId}`;

export const GET_FORMS_LIST = '/mosaikform/getforms';
export const GET_FORM_META = '/mosaikform/getformmeta';
export const GET_USER_FORM_META = '/user/admin/getallagents';
export const GET_FORM_VERSION = (formVersionId: number) =>
  `/mosaikform/getformquestions/${formVersionId}`;
export const ADD_QUESTION_ROLE = '/mosaikform/addnewquestionrole';
export const ADD_NEW_FORM = '/mosaikform/createnewform';
export const UPDATE_FORM_VERSION = (formId: number) => `/mosaikform/updatequoteform/${formId}`;
export const UPDATE_SMART_FORM_VERSION = (formId: number) =>
  `/mosaikform/updatesmartform/${formId}`;

export const DELETE_FORM_VERSION = (formVersionId: number) =>
  `/mosaikform/deleteform/${formVersionId}`;
export const UPDATE_FORM_VERSION_STATUS = (formId: number, formVersionId: number) =>
  `/mosaikform/updatestatus/${formId}/${formVersionId}`;
export const ADD_FORM_TO_FAVORITES = (formId: number) => `/mosaikform/favoriteform/${formId}`;
export const ADD_NEW_QUOTE_VERSION = (formId: number, versionId: number) =>
  `/mosaikform/addnewquoteformversion/${formId}/${versionId}`;
export const COPY_NEW_QUOTE_VERSION = `/mosaikform/copyquoteform`;
export const COPY_NEW_SMART_VERSION = `/mosaikform/copysmartform`;

export const ADD_TAG = '/mosaikform/addtag';

export const SMART_FORM_UPLOAD = `/quoteRequest/smartform/fileupload`;
export const GET_SMART_FORM_VERSION = (formVersionId: number) =>
  `/mosaikform/getSmartFormVersionQuestions/${formVersionId}`;
export const ADD_NEW_SMART_VERSION = (formId: number, versionId: number) =>
  `/mosaikform/addnewsmartformversion/${formId}/${versionId}`;

export const FILL_DOCUMENT_PREVIEW = 'fillDocument/forms/fill/preview';
