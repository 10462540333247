import moment from 'moment';
import classNames from 'classnames';
import { useEffect, useState, useRef } from 'react';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import { SmartFormIcons } from '../SmartFormIcons';
import { QuestionContainer } from 'pages/FormBuilder';
import { Button, ConfirmationModal, Popover, Progress, Row } from 'components-antd';
import { PDFViewer } from 'pages/FormBuilder/components/PDFViewer';
import { FORM_MODE, FORM_QUESTION_TYPE, PDF_FIELD_TYPE } from 'app-constants';
import {
  AddFormCommentType,
  CommentUsersType,
  DeferredUsers,
  DeferUserType,
  EditFormCommentType,
  FormCommentType,
  FormDocumentAnswersType,
  FormDocumentQuestionsType,
  FormDocumentQuestionType,
  FormEditRolesType,
  JumpType,
  ResponseType,
  FieldInfoType,
  StrikeThrough,
  FormCommentThread,
} from 'types';
import { FormPill } from 'pages/FormProcess/components';
import { EditFormDeferModal } from './EditFormDeferModal';
import { ColorPicker } from 'components';
import { StrokeDropDown } from './StrokeDropDown';

import styles from './styles.module.scss';

interface EditFormProps {
  questions?: FormDocumentQuestionsType;
  disabledQuestions?: FormDocumentQuestionsType;
  responses?: FormDocumentAnswersType;
  handleResponse: (data) => void;
  handleDone: () => void;
  link?: string;
  pdfName?: string;
  clientName?: string;
  address?: string;
  handleExit?: () => void;
  showRolesColumn?: boolean;
  updateEditRoles?: (roles) => void;
  deferQuestions?: string[];
  setDeferQuestions?: (val: string[]) => void;
  editRoles?: string[];
  isCreator?: boolean;
  isBuilder?: boolean;
  handleUnlock?: () => void;
  handleDeferQuestion?: (questionUUID, editor, loadContinue, cb) => void;
  handleUndoDeferQuestion?: (questionUUID, loadContinue, cb) => void;
  setLoadContinue?: (bool) => void;
  loadContinue?: boolean;
  deferredUsers?: DeferredUsers[];
  formEditRoles?: FormEditRolesType;
  allDeferrableUsers?: DeferUserType[];
  commentUsers?: CommentUsersType;
  formComment?: FormCommentType;
  setFieldInfo?: (fieldInfo: FieldInfoType) => void;
  handleGetFormComments?: () => void;
  handleAddFormComment?: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment?: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment?: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
  setCommentPanelOpen?: (commentPanelOpen: boolean) => void;
  allowWizardView?: boolean;
  allowStrikeThrough?: boolean;
  handlePointUpdate?: (page, points) => void;
  handleCleanAllPoints?: () => void;
  handleCancelPoints?: () => void;
  handleSavePoints?: () => void;
  strikeThrough?: StrikeThrough;
  formCommentFieldId?: string;
  formCommentThreads?: FormCommentThread[];
  selectedFieldInfo?: FieldInfoType;
}

const EDIT_FORM_CONFIG_KEY = 'EditFormConfig';

export const EditForm = ({
  questions,
  disabledQuestions,
  responses,
  handleResponse,
  handleDone,
  link,
  handleExit,
  pdfName,
  clientName,
  address,
  showRolesColumn,
  updateEditRoles,
  editRoles,
  isCreator,
  isBuilder = false,
  handleUnlock,
  handleDeferQuestion,
  handleUndoDeferQuestion,
  deferQuestions = [],
  setDeferQuestions,
  setLoadContinue,
  loadContinue,
  deferredUsers = [],
  formEditRoles,
  allDeferrableUsers = [],
  commentUsers,
  formComment,
  setFieldInfo,
  handleGetFormComments,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
  setCommentPanelOpen,
  allowWizardView,
  allowStrikeThrough,
  handlePointUpdate,
  strikeThrough,
  handleCleanAllPoints,
  handleCancelPoints,
  handleSavePoints,
  formCommentFieldId,
  formCommentThreads,
  selectedFieldInfo,
}: EditFormProps) => {
  const wrapper = useRef<HTMLDivElement>(null);

  const [previousDisabled, setPreviousDisabled] = useState(false);
  const [pdfView, setPDFView] = useState(true);
  const [disabledContinue, setDisabledContinue] = useState(true);

  const [unansweredRequiredQs, setUnansweredRequiredQs] = useState<number[]>([]);
  const [showRequiredQs, setShowRequiredQs] = useState(false);
  const [percent, setPercent] = useState(0);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

  const [finalQuestions, setFinalQuestions] = useState<FormDocumentQuestionsType>([]);

  const [deferQuestion, setDeferQuestion] = useState<string[] | undefined>();
  const [deferLoading, setDeferLoading] = useState(false);

  const [jLQs, setJLQs] = useState<FormDocumentQuestionsType>([]);
  const [jLIds, setJLIds] = useState<{}>();
  const [totalLength, setTotalLength] = useState(0);

  const [sticky, setSticky] = useState(false);

  const [strikeThroughMode, setStrikeThroughMode] = useState(false);
  const [deferModalPosition, setDeferModalPoston] = useState<{
    top: number | string;
    left: number | string;
  }>({
    top: 0,
    left: 0,
  });

  const [undoDefer, setUndoDefer] = useState<string[]>([]);
  const [undoLoading, setUndoLoading] = useState(false);

  const [color, setColor] = useState({
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  });
  const [strokeWidth, setStrokeWidth] = useState(2);
  const textAreaRef = useRef<HTMLDivElement>(null);
  const pdfInputRef = useRef<HTMLDivElement>(null);

  const shouldAllowStrikeThrough = pdfView && allowStrikeThrough;

  const question = finalQuestions[activeQuestionIndex];
  const response = responses?.[question?.UUID];

  const lastQuestionIndex = finalQuestions.length - 1;

  const handleFormResponse = (updatedResponse: Partial<ResponseType>) => {
    handleResponse({
      ...response,
      ...updatedResponse,
      FieldType: question?.FieldType,
      Fields: question?.Fields,
    });
  };

  useEffect(() => {
    if (!allowWizardView && !pdfView) {
      setPDFView(true);
    }
  }, [allowWizardView]);

  useEffect(() => {
    if (!pdfView && strikeThroughMode) {
      setStrikeThroughMode(false);
    }
  }, [pdfView]);

  useEffect(() => {
    const jumpLogicObject = questions
      ?.filter((question) => question.JumpLogic)
      .reduce((obj, question) => {
        obj[question.UUID] = Object.values(question.JumpLogic || {});
        return obj;
      }, {});

    setJLIds(jumpLogicObject);

    const allJumpLogicIds = Object.values(jumpLogicObject || {}).flat(1);

    const jLQs = questions?.filter((question) => allJumpLogicIds.includes(question.UUID)) || [];
    setJLQs(jLQs);

    const notJLQs = questions?.filter((question) => !allJumpLogicIds.includes(question.UUID)) || [];
    setFinalQuestions(notJLQs);

    setTotalLength(Object.keys(jumpLogicObject || {}).length + notJLQs.length);
  }, [questions?.length]);

  useEffect(() => {
    if (question?.JumpLogic) {
      const answer = JSON.parse(response?.Answer || '[]');

      if (answer?.length) {
        const responseValue =
          typeof answer?.[0] === 'string' ? answer[0] : answer[0]?.selectedOptions?.[0];
        if (responseValue) {
          const addQuestion = jLQs?.find(
            (jLQ) => jLQ.UUID === (question.JumpLogic as JumpType)[responseValue],
          );

          const currentQuestionJL = (jLIds as {})[question.UUID];

          const removeQuestion = finalQuestions.filter((question) =>
            currentQuestionJL?.includes(question?.UUID),
          );

          const removeIndex = finalQuestions.findIndex(
            (question) => removeQuestion.length && removeQuestion[0].UUID === question.UUID,
          );

          const updatedQuestions = finalQuestions.slice();
          if (removeIndex >= 0) {
            updatedQuestions.splice(removeIndex, 1);
          }
          if (addQuestion?.UUID) {
            const index = questions?.findIndex((question) => addQuestion.UUID === question.UUID);
            if (index) {
              const prevQs = questions?.[index - 1];
              const prevQsIndex = updatedQuestions.findIndex(
                (question) => prevQs?.UUID === question.UUID,
              );
              updatedQuestions.splice(prevQsIndex + 1, 0, addQuestion as FormDocumentQuestionType);
            }
          }
          setFinalQuestions(updatedQuestions);
        }
      } else {
        const currentQuestionJL = (jLIds as {})[question?.UUID];

        const removeQuestion = finalQuestions.filter((question) =>
          currentQuestionJL?.includes(question?.UUID),
        );

        const removeIndex = finalQuestions.findIndex(
          (question) => removeQuestion.length && removeQuestion[0].UUID === question?.UUID,
        );

        const updatedQuestions = finalQuestions.slice();
        if (removeIndex >= 0) {
          updatedQuestions.splice(removeIndex, 1);
        }
        setFinalQuestions(updatedQuestions);
      }
    }
  }, [JSON.stringify(responses)]);

  useEffect(() => {
    if (finalQuestions?.length && Object.keys(responses || {})?.length) {
      let check = false;
      const editQuestions = finalQuestions.filter(
        (el) => el.FieldType !== PDF_FIELD_TYPE.PDFSignature && el.Required === true,
      );
      const emptyIds: number[] = [];

      for (const ques of editQuestions) {
        const res = responses?.[ques.UUID];

        if (!res || ques.FieldType === PDF_FIELD_TYPE.PDFTextField) {
          if (!res?.Answer) {
            emptyIds.push(ques.Id);
            check = true;
          }
        } else {
          let result: any = [];
          try {
            result = JSON.parse(res.Answer);
          } catch (e) {
            //
          }
          if (ques.FieldType === PDF_FIELD_TYPE.PDFCheckBox) {
            if (!result?.length) {
              check = true;
              emptyIds.push(ques.Id);
            }
          } else if (ques.FieldType === PDF_FIELD_TYPE.PDFRadioGroup) {
            if (!result?.[0]?.selectedOptions?.length) {
              check = true;
              emptyIds.push(ques.Id);
            }
          }
        }
      }

      setUnansweredRequiredQs(emptyIds);
      if (showRequiredQs && !emptyIds.length) {
        setShowRequiredQs(false);
      }
      setDisabledContinue(check);
    }
  }, [finalQuestions]);

  useEffect(() => {
    const enableContinue = finalQuestions.every((q) => {
      if (q.Required && !responses?.[q.UUID]?.Answer) {
        return false;
      }

      return true;
    });

    setDisabledContinue(!enableContinue);
  }, [response]);

  const numberOfQuestions = finalQuestions.length - 1;
  const activeQuestionExists = activeQuestionIndex !== undefined;

  const handleNextQuestion = () => {
    let resp = response?.Answer;

    if (question?.Type === FORM_QUESTION_TYPE.ListBox) {
      resp = JSON.parse(response?.Answer || '[]')[0]?.selectedOptions;
    }

    if (question?.JumpLogic && resp?.length && question.JumpLogic[resp]) {
      const nextQsId =
        question.JumpLogic[JSON.parse(response?.Answer as string)[0].selectedOptions[0]];
      const nextQsIndex = finalQuestions.findIndex(
        (finalQuestion) => finalQuestion.UUID === nextQsId,
      );
      setActiveQuestionIndex(nextQsIndex);
    } else {
      if (activeQuestionExists && activeQuestionIndex < numberOfQuestions) {
        setActiveQuestionIndex(activeQuestionIndex + 1);
      }
    }
  };

  const handlePreviousQuestion = () => {
    if (activeQuestionExists && activeQuestionIndex > 0) {
      setActiveQuestionIndex(activeQuestionIndex - 1);
    }
  };

  const handleContinue = () => {
    questions?.forEach((question) => {
      if (!finalQuestions.some((qs) => qs.UUID === question.UUID)) {
        const resp = responses?.[question.UUID];
        handleFormResponse({ ...resp, Answer: '' });
      }
    });

    handleNextQuestion();
  };

  useEffect(() => {
    if (activeQuestionExists) {
      setPercent(((activeQuestionIndex + 1) / totalLength) * 100);
    }
  }, [activeQuestionIndex, totalLength]);

  useEffect(() => {
    if (question?.PopulateWith && isBuilder) {
      if (question.Type === FORM_QUESTION_TYPE.Date) {
        const currentDate = moment().format('MM/DD/YYYY');
        handleFormResponse({ DefaultAnswer: currentDate, Answer: currentDate });
      } else {
        handleFormResponse({ DefaultAnswer: 'Auto Populate', Answer: 'Auto Populate' });
      }
    }
  }, [question?.UUID]);

  const onFieldUpdate = (answer) => {
    handleFormResponse({
      Answer: answer,
    });
  };

  const updateRole = (role: string) => {
    if (editRoles && updateEditRoles) {
      if (editRoles?.includes(role)) {
        updateEditRoles(editRoles.filter((el) => el !== role));
      } else {
        updateEditRoles([...editRoles, role]);
      }
    }
  };

  const getOptions = (users?: { id: string; name: string }[]) =>
    users?.map((user) => (
      <span key={user.id} className={styles.userName}>
        {user.name}
      </span>
    ));

  const handleDeferClick = (defer) => {
    if (setDeferQuestions) {
      if (defer.questions.every((d) => deferQuestions?.includes(d))) {
        const updatedDeferQuestions = [...deferQuestions].filter(
          (q) => !defer.questions.includes(q),
        );
        setDeferQuestions(updatedDeferQuestions);
      } else {
        setDeferQuestions([...deferQuestions].concat(defer.questions));
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (wrapper.current !== null) {
        wrapper.current.offsetTop > 80 ? setSticky(true) : setSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const renderLeftHeader = () => {
    if (strikeThroughMode) {
      return (
        <div className={styles.strikeThroughLeftHeader}>
          <div className={styles.strokeSetting}>
            <StrokeDropDown setStrokeWidth={setStrokeWidth} strokeWidth={strokeWidth} />
            <ColorPicker
              defaultColor={color}
              sketchPadProps={{
                disableAlpha: true,
              }}
              onColorUpdate={(e) => setColor({ ...e.rgb })}
              colorBoxContainerClassName={classNames(
                styles.settingSwitch,
                styles.colorBoxContainer,
              )}
              colorBoxClassName={styles.colorBox}
            />
          </div>
          <Button
            onClick={handleCleanAllPoints}
            variant="tertiary"
            className={classNames(styles.cleanAllButton, styles.strikeActionButton)}
          >
            Clean All
          </Button>
        </div>
      );
    } else if (allowWizardView) {
      return (
        <div className={styles.sideControls}>
          <div className={styles.switchOptions}>
            <div
              onClick={() => setPDFView(true)}
              className={classNames(styles.switchPDF, styles.switchOption, {
                [styles.activeSwitch]: pdfView,
              })}
            >
              <SmartFormIcons variant="pdf" />
            </div>
            <div
              className={classNames(styles.switchList, styles.switchOption, {
                [styles.activeSwitch]: !pdfView,
              })}
              onClick={() => setPDFView(false)}
            >
              <SmartFormIcons variant="list" />
            </div>
          </div>
        </div>
      );
    } else {
      return <span className={styles.leftSideControl}></span>;
    }
  };

  const renderRightHeader = () => {
    if (strikeThroughMode) {
      return (
        <div>
          <Button
            onClick={(e) => toggleStrikeThroughMode(false, true)}
            variant="tertiary"
            className={classNames(styles.strikeActionButton, styles.strikeCancelButton)}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => toggleStrikeThroughMode(false, false, true)}
            variant="secondary"
            className={styles.strikeActionButton}
          >
            Save
          </Button>
        </div>
      );
    } else {
      return (
        <div className={classNames(styles.navigationIcons, styles.sideControls)}>
          <div
            onClick={() => handlePreviousQuestion()}
            className={classNames(styles.backNavigation, styles.navigationButton)}
          >
            <SmartFormIcons
              variant="back"
              className={classNames({ [styles.disabledBtn]: previousDisabled })}
            />
          </div>
          <div
            className={classNames(styles.nextNavigation, styles.navigationButton)}
            onClick={handleContinue}
          >
            <SmartFormIcons
              variant="next"
              className={classNames({
                [styles.disabledBtn]: activeQuestionIndex === lastQuestionIndex,
              })}
            />
          </div>
          <div
            className={classNames(styles.closeNavigation, styles.navigationButton)}
            onClick={handleExit}
          >
            <SmartFormIcons variant="cancel" />
          </div>
        </div>
      );
    }
  };

  const renderHeader = () => {
    return (
      <div className={styles.top}>
        {renderLeftHeader()}
        {!strikeThroughMode && (
          <FormPill
            formPillClass={styles.formPill}
            clientAddress={address}
            clientName={clientName}
            pdfName={question?.formName || pdfName}
          />
        )}
        {renderRightHeader()}
      </div>
    );
  };

  const EditRow = () => {
    return (
      <div className={classNames(styles.editRow, { [styles.sticky]: sticky })} ref={wrapper}>
        {renderHeader()}
        <div className={styles.bottom}>
          {handleUnlock ? (
            <div
              className={classNames(styles.editForm, styles.navigationButton)}
              onClick={handleUnlock}
            >
              <SmartFormIcons variant="lock" />
              Unlock
            </div>
          ) : (
            <></>
          )}

          {showRolesColumn && pdfView ? (
            <div className={styles.editRoles}>
              {formEditRoles?.Agent?.length || isBuilder ? (
                <div className={styles.userRow} onClick={() => updateRole('Agent')}>
                  <Checkbox
                    className={classNames(styles.checkbox, {
                      [styles.selectedCheckbox]: editRoles?.includes('Agent'),
                    })}
                    checked={editRoles?.includes('Agent')}
                  />
                  <span>
                    <span className={styles.userTitle}>Agent</span>
                    {getOptions(formEditRoles?.Agent)}
                  </span>
                </div>
              ) : (
                <></>
              )}
              {formEditRoles?.Client?.length || isBuilder ? (
                <div className={styles.userRow} onClick={() => updateRole('Client')}>
                  <Checkbox
                    className={classNames(styles.checkbox, {
                      [styles.selectedCheckbox]: editRoles?.includes('Client'),
                    })}
                    checked={editRoles?.includes('Client')}
                  />
                  <span>
                    <span className={styles.userTitle}>Client</span>
                    {getOptions(formEditRoles?.Client)}
                  </span>
                </div>
              ) : (
                <></>
              )}
              {!!deferredUsers?.length && (
                <>
                  <div className={styles.userRow}>
                    <span>
                      <span className={styles.userTitle}>Deferred Questions</span>
                    </span>
                  </div>
                  {deferredUsers.map((defer) => (
                    <div
                      className={classNames(styles.userRow, styles.deferredCheckbox)}
                      key={`defer-user-${defer.id}`}
                      onClick={() => handleDeferClick(defer)}
                    >
                      <Checkbox
                        className={classNames(styles.checkbox, {
                          [styles.selectedCheckbox]: defer.questions.every((d) =>
                            deferQuestions.includes(d),
                          ),
                        })}
                        checked={defer.questions.every((d) => deferQuestions.includes(d))}
                      />
                      <span>
                        <span className={styles.userTitle}>{defer.name}</span>
                      </span>
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const handleDeferAll = () => {
    if (finalQuestions?.length && responses) {
      const allUnansweredQuestions = finalQuestions
        .filter((fQ) => !responses[fQ.UUID]?.Answer && !Object.keys(fQ.JumpLogic || {}).length)
        .map((fQ) => fQ.UUID);
      if (allUnansweredQuestions.length) {
        setDeferQuestion(allUnansweredQuestions);
        if (setLoadContinue) setLoadContinue(true);
        handleDeferModalPosition(allUnansweredQuestions);
      }
    }
  };

  const showDeferAll = !!(
    finalQuestions &&
    responses &&
    finalQuestions.filter(
      (fQ) => !responses[fQ.UUID]?.Answer && !Object.keys(fQ.JumpLogic || {}).length,
    )
  );

  useEffect(() => {
    if (finalQuestions?.length) {
      const configData = window.sessionStorage.getItem(EDIT_FORM_CONFIG_KEY);
      if (configData) {
        const config = JSON.parse(configData);

        if (config.pdfView !== undefined) setPDFView(!!config.pdfView);
        if (config.focusQuestion) {
          const index = finalQuestions.findIndex(
            (question) => question.UUID === (config.focusQuestion as string),
          );
          if (index >= 0) {
            setActiveQuestionIndex(index);
          }
        }
        window.sessionStorage.removeItem(EDIT_FORM_CONFIG_KEY);
      }
    }
  }, [finalQuestions]);

  const handleDefer = async (questionUUID: string[], editor) => {
    if (handleDeferQuestion) {
      setDeferLoading(true);
      handleDeferQuestion(questionUUID, editor, !loadContinue, () => {
        setDeferLoading(false);
        setDeferQuestion(undefined);
        if (setLoadContinue) setLoadContinue(false);
        let focusQuestion = '';
        if (finalQuestions[activeQuestionIndex + 1]?.UUID) {
          focusQuestion = finalQuestions[activeQuestionIndex + 1]?.UUID;
        } else if (activeQuestionIndex > 0) {
          for (let i = activeQuestionIndex - 1; i >= 0; i--) {
            if (finalQuestions[i]?.UUID) {
              focusQuestion = finalQuestions[i]?.UUID;
              break;
            }
          }
        }
        window.sessionStorage.setItem(
          EDIT_FORM_CONFIG_KEY,
          JSON.stringify({
            pdfView,
            focusQuestion,
          }),
        );
      });
    }
  };

  const handleUndoDefer = async () => {
    if (handleUndoDeferQuestion && undoDefer?.length) {
      setUndoLoading(true);
      handleUndoDeferQuestion(undoDefer, !loadContinue, () => {
        setUndoLoading(false);
        setUndoDefer([]);

        if (setLoadContinue) setLoadContinue(false);
        let focusQuestion = undoDefer?.[0];

        window.sessionStorage.setItem(
          EDIT_FORM_CONFIG_KEY,
          JSON.stringify({
            pdfView,
            focusQuestion,
          }),
        );
      });
    }
  };

  const toggleStrikeThroughMode = (bool = true, isCancel = false, isSave = false) => {
    if (shouldAllowStrikeThrough) {
      setStrikeThroughMode(bool);
      if (handleCancelPoints && isCancel) handleCancelPoints();
      if (isSave && handleSavePoints) handleSavePoints();
    }
  };

  const handleDeferModalPosition = (uuid: string[]) => {
    if (uuid.length && uuid.length === 1) {
      let inputRect;
      if (pdfView) {
        if (pdfInputRef.current) {
          inputRect = pdfInputRef.current.getBoundingClientRect();
        }
      } else {
        if (textAreaRef.current) {
          inputRect = textAreaRef.current.getBoundingClientRect();
        }
      }
      setDeferModalPoston({
        top: inputRect.top + window.scrollY + (pdfView ? inputRect.height : 0),
        left: inputRect.left + window.scrollX,
      });
    } else {
      setDeferModalPoston({
        top: '45%',
        left: '40%',
      });
    }
  };

  const handleDeferModal = (uuid: string[]) => {
    setDeferQuestion(uuid);
    handleDeferModalPosition(uuid);
  };

  const selectRequiredQuestion = () => {
    if (pdfView && unansweredRequiredQs.length && questions?.length) {
      setShowRequiredQs(true);

      const activeQuestionId = questions[activeQuestionIndex].Id;
      if (!unansweredRequiredQs.includes(activeQuestionId)) {
        const newActiveIndex = questions?.findIndex((q) => q.Id === unansweredRequiredQs[0]);
        setActiveQuestionIndex(newActiveIndex);
      }
    }
  };

  return (
    <>
      <EditFormDeferModal
        handleClose={() => {
          if (setLoadContinue) setLoadContinue(false);
          setDeferQuestion(undefined);
        }}
        questions={
          deferQuestion?.length && questions?.length
            ? questions?.filter((q) => deferQuestion.includes(q.UUID))
            : undefined
        }
        isLoading={deferLoading}
        handleDefer={handleDefer}
        allDeferrableUsers={allDeferrableUsers}
        mask={false}
        position={deferModalPosition}
      />
      <ConfirmationModal
        confirmText={'Are you sure you want to remove deferred question?'}
        onOk={handleUndoDefer}
        onCancel={() => setUndoDefer([])}
        open={!!undoDefer?.length}
        confirmLoading={undoLoading}
      />
      <div
        className={classNames(styles.editFormContainer, {
          [styles.editFormContainerPDFMode]: pdfView,
        })}
      >
        {!pdfView && <Progress percent={percent} className={styles.progressBar} />}
        <EditRow />
        <div
          className={classNames({
            [styles.listContainer]: !pdfView,
            [styles.pdfContainer]: pdfView,
          })}
        >
          {pdfView ? (
            <PDFViewer
              Url={link}
              questions={finalQuestions}
              disabledQuestions={disabledQuestions}
              deferredUsers={deferredUsers}
              onFieldUpdate={onFieldUpdate}
              mode={strikeThroughMode ? 'StrikeThrough' : 'Edit'}
              responses={responses}
              activeIndex={activeQuestionIndex}
              handleDeferModal={handleDeferModal}
              showRequiredQs={showRequiredQs}
              unansweredRequiredQs={unansweredRequiredQs}
              setActiveIndex={setActiveQuestionIndex}
              isCreator={isCreator}
              commentUsers={commentUsers}
              formComment={formComment}
              setFieldInfo={setFieldInfo}
              setCommentPanelOpen={setCommentPanelOpen}
              formCommentThreads={formCommentThreads}
              handleGetFormComments={handleGetFormComments}
              handleAddFormComment={handleAddFormComment}
              handleEditFormComment={handleEditFormComment}
              handleDeleteFormComment={handleDeleteFormComment}
              handleToggleResolveThread={handleToggleResolveThread}
              toggleStrikeThroughMode={
                shouldAllowStrikeThrough && !strikeThroughMode ? toggleStrikeThroughMode : undefined
              }
              handlePointUpdate={strikeThroughMode ? handlePointUpdate : undefined}
              strikeThrough={strikeThrough}
              color={color}
              strokeWidth={strokeWidth}
              formCommentFieldId={formCommentFieldId}
              setUndoDefer={handleUndoDeferQuestion && setUndoDefer}
              pdfInputRef={pdfInputRef}
              selectedFieldInfo={selectedFieldInfo}
            />
          ) : (
            <QuestionContainer
              mode={FORM_MODE.View}
              formQuestion={question}
              formResponse={response}
              smartForm
              handleFormResponse={handleFormResponse}
              commentUsers={commentUsers}
              formComment={formComment}
              setFieldInfo={setFieldInfo}
              handleGetFormComments={handleGetFormComments}
              handleAddFormComment={handleAddFormComment}
              handleEditFormComment={handleEditFormComment}
              handleDeleteFormComment={handleDeleteFormComment}
              handleToggleResolveThread={handleToggleResolveThread}
              ref={textAreaRef}
            />
          )}
        </div>
      </div>
      <div className={styles.editFormFooter}>
        <Row
          className={classNames(styles.editFormFooterColumn, { [styles.editFormPDFView]: pdfView })}
        >
          {!pdfView ? (
            <Popover
              placement="topLeft"
              className={styles.popoverContent}
              getPopupContainer={(triggerNode) => triggerNode}
              content={
                <>
                  {!Object.keys(question?.JumpLogic || {}).length ? (
                    <>
                      {showDeferAll && (
                        <p className={styles.popoverOption} onClick={handleDeferAll}>
                          <SmartFormIcons variant="defer" />
                          <span className={styles.optionLabel}>Defer All</span>
                        </p>
                      )}
                      {!pdfView && (
                        <p
                          className={styles.popoverOption}
                          onClick={() => {
                            handleDeferModal([question.UUID]);
                          }}
                        >
                          <SmartFormIcons variant="defer" />
                          <span className={styles.optionLabel}>Defer</span>
                        </p>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              }
            >
              <Button
                variant="tertiary"
                className={classNames(styles.editFormFooterMessage, styles.editFormFooterButton)}
                disabled={strikeThroughMode}
              >
                <SmartFormIcons variant="help" className={styles.svgMessage} /> Options
              </Button>
            </Popover>
          ) : (
            <></>
          )}

          <Button
            variant="secondary"
            className={classNames(styles.editFormFooterContinue, styles.editFormFooterButton)}
            disabled={strikeThroughMode || pdfView ? false : disabledContinue}
            onClick={
              pdfView ? (disabledContinue ? selectRequiredQuestion : handleDone) : handleContinue
            }
          >
            Continue{' '}
            {!pdfView ? (
              <SmartFormIcons variant="continue" className={styles.svgContinue} />
            ) : (
              <></>
            )}
          </Button>
        </Row>
      </div>
    </>
  );
};
