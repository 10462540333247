export enum AgentStatus {
  All = 'All',
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum TeamAgentType {
  Owner = 'Owner',
  Admin = 'Admin',
  Member = 'Member',
}
