import classNames from 'classnames';
import { LISTED_STATUS, transactionStatuses } from 'settings/constants/transaction';

import styles from './styles.module.scss';

export const Status = ({ status }) => {
  function getStatusClassName() {
    switch (status) {
      case transactionStatuses.UnderContract:
        return styles.underContract;
      case transactionStatuses.ClearToClose:
        return styles.clearToClose;
      case transactionStatuses.Canceled:
        return styles.cancelled;
      case transactionStatuses.Closed:
        return styles.closed;
      case transactionStatuses.InProgress:
        return styles.inProgress;
      case transactionStatuses.Upcoming:
        return styles.upcoming;
      case transactionStatuses.OnHold:
        return styles.onHold;
      case transactionStatuses.AtRisk:
        return styles.atRisk;
      case LISTED_STATUS.Active:
      case LISTED_STATUS.ActiveListing:
      case LISTED_STATUS.ComingSoon:
      case LISTED_STATUS.OfficeExclusive:
      case LISTED_STATUS.Pending:
      case LISTED_STATUS.PreListing:
      case LISTED_STATUS.OnHold:
        return styles.listed;
      default:
        return '';
    }
  }

  return (
    <div className={classNames(styles.projectStatus, getStatusClassName())} testid="project_status">
      <span className={styles.statusText}>{status}</span>
    </div>
  );
};
