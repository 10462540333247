import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Add, Remove } from 'components/Icons';
import styles from './styles.module.scss';

const Counter = (props) => {
  const { className, onChange, limit, value, disabled, testid } = props;
  const { holderClassName, removeClassName, addClassName, RemoveComponent } = props;
  const { AddComponent, numberClassName, postfix } = props;
  const [count, setCount] = useState(value);

  useEffect(() => {
    setCount(value);
  }, [value]);

  const onAdd = () => {
    let newVal = count + 1;
    if (limit.max !== undefined && newVal > limit.max) {
      newVal = count;
    }
    setCount(newVal);
    onChange(newVal);
  };

  const onRemove = () => {
    let newVal = count - 1;
    if (limit.min !== undefined && newVal < limit.min) {
      newVal = count;
    }
    setCount(newVal);
    onChange(newVal);
  };

  const getRemove = () => {
    if (RemoveComponent) {
      return (
        <RemoveComponent
          className={classNames(styles.remove, removeClassName)}
          onClick={onRemove}
        />
      );
    }
    return (
      <Remove
        className={classNames(styles.remove, removeClassName)}
        onClick={onRemove}
        color={Remove.ORANGE_COLOR}
        testid="counter_remove"
      />
    );
  };

  const getAdd = () => {
    if (AddComponent) {
      return <AddComponent className={classNames(styles.add, addClassName)} onClick={onAdd} />;
    }

    return (
      <Add
        testid="counter_add"
        className={classNames(styles.add, addClassName)}
        onClick={onAdd}
        color={Add.ORANGE_COLOR}
      />
    );
  };

  return (
    <div className={classNames(styles.counter, { [styles.disabled]: disabled }, className)}>
      <div testid={testid} className={classNames(styles.holder, holderClassName)}>
        {getRemove()}
        <span testid="counter_number" className={classNames(styles.number, numberClassName)}>
          {`${count}${postfix}`}
        </span>
        {getAdd()}
      </div>
    </div>
  );
};

Counter.propTypes = {
  className: PropTypes.string,
  removeClassName: PropTypes.string,
  addClassName: PropTypes.string,
  numberClassName: PropTypes.string,
  limit: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  onChange: PropTypes.func,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  testid: PropTypes.string,
  holderClassName: PropTypes.string,
  RemoveComponent: PropTypes.func,
  AddComponent: PropTypes.func,
  postfix: PropTypes.string,
};

Counter.defaultProps = {
  className: '',
  holderClassName: '',
  addClassName: '',
  numberClassName: '',
  removeClassName: '',
  limit: {},
  onChange: () => {},
  value: 0,
  disabled: false,
  testid: undefined,
  RemoveComponent: undefined,
  AddComponent: undefined,
  postfix: '',
};

export default Counter;
