import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const FormSubTitle = ({ className, children }) => (
  <p className={classNames(styles.text, className)}>{children}</p>
);

FormSubTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
};

FormSubTitle.defaultProps = {
  className: '',
};

export default FormSubTitle;
