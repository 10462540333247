import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import { Input, Textarea, Phone, Button, TextareaAutosize } from 'components';
import { onBoardingSignUpEffect } from 'store/effects/onBoarding';

import { PENDING } from 'settings/constants/apiState';

import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';

import { getUserDataSelector, isPartnerSelector } from 'store/selectors/user';

import {
  ValidationSchemaClient,
  ValidationSchemaAgentThirdParty,
  ValidationSchemaThirdPartyPartner,
  ValidationSchemaThirdPartyNotPartner,
} from './validation';

import { OnBoardingContainer } from '../../OnBoardingContainer';
import { Question } from 'pages/RequestQuote/components';
import { Row, Col } from 'components-antd';

import styles from './styles.module.scss';
import { AnswersContainer } from '../../AnswersContainer';
import ArrowRightFull from 'components/Icons/ArrowRightFull';
import { getClientInstancesSelector } from 'store/selectors/clientInstances';
import { useMemo } from 'react';

const AboutForm = ({ onNext, stageIndex, Controls }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const { onBoarding, signUpRole } = useSelector(getOnBoardingData);
  const isPartner = useSelector(isPartnerSelector);
  const { searchInstances, transactionInstances, isLoading } = useSelector(
    getClientInstancesSelector,
  );
  const isClientBuyer = useMemo(() => !!searchInstances?.[0], [searchInstances]);
  const onSubmit = (values) => {
    dispatch(
      onBoardingSignUpEffect({ ...values, stageIndex }, { isCommonInfo: true }, (err) => {
        if (!err) {
          onNext();
        }
      }),
    );
  };

  const getValidationSchema = () => {
    if (signUpRole === CLIENT) {
      return ValidationSchemaClient();
    }
    if (signUpRole === AGENT) {
      return ValidationSchemaAgentThirdParty();
    }
    if (signUpRole === THIRD_PARTY && !isPartner) {
      return ValidationSchemaThirdPartyNotPartner();
    }
    if (signUpRole === THIRD_PARTY && isPartner) {
      return ValidationSchemaThirdPartyPartner();
    }
  };

  const formik = useFormik({
    initialValues: {
      ...AboutForm.initialValues,
      firstName: user?.FirstName || AboutForm.initialValues.firstName,
      lastName: user?.LastName || AboutForm.initialValues.lastName,
      phone: user?.Phones?.[0]?.PhoneNumber || AboutForm.initialValues.phone,
      bio: user?.[signUpRole]?.Bio || AboutForm.initialValues.bio,
      email: user?.Email || AboutForm.initialValues.email,
    },
    validationSchema: getValidationSchema(),
    validateOnChange: true,
    onSubmit,
  });

  const isPending = onBoarding.state === PENDING;

  return (
    <>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question className={classNames({ [styles.partnerQuestion]: isPartner })}>
          {isPartner ? (
            <span>Please confirm your contact information</span>
          ) : isClientBuyer ? (
            <span className={styles.buyerHeading}>
              Almost done! Please confirm your account information
            </span>
          ) : (
            "Let's set up your account"
          )}
        </Question>
        <AnswersContainer testid="about_yourself">
          <form onSubmit={formik.handleSubmit} className={styles.form}>
            <div className={styles.container}>
              {/* <Row
              className={classNames(styles.row, {
                [styles.partnerRow]: isPartner,
              })}
              gutter={[16, isPartner ? 12 : 0]}
            >
              <Col xs={24} md={isPartner ? 24 : 7} className={styles.textCol}>
                {(signUpRole === THIRD_PARTY || signUpRole === CLIENT) && (
                  <span
                    className={classNames(styles.text, {
                      [styles.partnerHeading]: isPartner,
                    })}
                  >
                    My name is
                  </span>
                )}
              </Col>
              <Col xs={24} md={isPartner ? 24 : 17}>
                <Row gutter={[16, 0]}>
                  <Col xs={24} md={12}> */}
              <Input
                className={classNames(styles.formInput, {
                  [styles.partnerFormInput]: isPartner,
                })}
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName ? formik.errors.firstName : ''}
                disabled={isPending}
                testid="first_name"
                variant={Input.LIGHT_ROUND}
                label="First Name"
              />
              {/* </Col>
                  <Col xs={24} md={12}> */}
              <Input
                className={classNames(styles.formInput, {
                  [styles.partnerFormInput]: isPartner,
                })}
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName ? formik.errors.lastName : ''}
                disabled={isPending}
                testid="last_name"
                variant={Input.LIGHT_ROUND}
                label="Last Name"
              />
              {/* </Col>
                </Row>
              </Col>
            </Row> */}
            </div>
            {signUpRole === THIRD_PARTY && isPartner && (
              <div className={styles.container}>
                {/* 
              // <Row
              //   className={classNames(styles.row, {
              //     [styles.partnerRow]: isPartner,
              //   })}
              //   gutter={[16, isPartner ? 12 : 0]}
              // >
              //   <Col xs={24} md={isPartner ? 24 : 11} className={styles.textCol}>
              //     {(signUpRole === THIRD_PARTY || signUpRole === CLIENT) && (
              //       <span
              //         className={classNames(styles.text, { [styles.partnerHeading]: isPartner })}
              //       >
              //         My email address is
              //       </span>
              //     )}
              //   </Col>
              //   <Col xs={24}>
              //     <div className={styles.emailRow}>  */}
                <Input
                  className={classNames(styles.formInput, {
                    [styles.partnerFormInput]: isPartner,
                  })}
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="Email Address*"
                  error={formik.touched.email ? formik.errors.email : ''}
                  disabled={isPending}
                  testid="email"
                  variant={Input.LIGHT_ROUND}
                  label="Email"
                />
                {/* //     </div>
              //   </Col>
              // </Row> */}
              </div>
            )}
            <div className={styles.container}>
              {/* <Row
              className={classNames(styles.row, {
                [styles.partnerRow]: isPartner,
              })}
              gutter={[16, isPartner ? 12 : 0]}
            >
              <Col xs={24} md={isPartner ? 24 : 11} className={styles.textCol}>
                {(signUpRole === THIRD_PARTY || signUpRole === CLIENT) && (
                  <span className={classNames(styles.text, { [styles.partnerHeading]: isPartner })}>
                    My phone number is
                  </span>
                )}
              </Col>
              <Col xs={24} md={isPartner ? 24 : 13}> */}
              <Phone
                className={classNames(styles.formInput, {
                  [styles.partnerFormInput]: isPartner,
                  [styles.partnerPhoneWrapper]: isPartner,
                })}
                inputClassName={styles.partnerPhoneWrapper}
                name="phone"
                onChange={(event, value, country) => {
                  formik.setFieldValue('phone', value);
                }}
                error={formik.errors.phone}
                disabled={isPending}
                variant={Phone.LIGHT_ROUND}
                value={formik.values.phone}
                testid="phone"
                placeholder="(---) --- ----"
                label="Phone Number"
              />
              {/* </Col>
            </Row> */}
            </div>
            {signUpRole === THIRD_PARTY && isPartner && (
              <Row>
                <div className={styles.sideNote}>
                  <span>
                    This is the contact information we will use to send you any notifications you
                    opt in to.
                  </span>
                </div>
              </Row>
            )}
            {(signUpRole === AGENT || (signUpRole === THIRD_PARTY && !isPartner)) && (
              <Row className={styles.row} gutter={[16, 0]}>
                <Col>
                  <TextareaAutosize
                    inputFieldClassName={styles.formInput}
                    name="bio"
                    placeholder="Professional Bio"
                    rows={8}
                    onChange={(event, value) => {
                      formik.setFieldValue('bio', value);
                    }}
                    error={formik.touched.bio ? formik.errors.bio : ''}
                    testid="bio"
                    variant={Textarea.LIGHT}
                    value={formik.values.bio}
                  />
                </Col>
              </Row>
            )}
            <div className={styles.buttonContainer}>
              <Button
                className={styles.button}
                titleClassName={styles.titleClassName}
                title="Continue"
                icon={<ArrowRightFull color={'#fff'} />}
                isPending={isPending}
                onClick={() => formik.submitForm()}
                disabled={!formik.isValid}
              />
            </div>
          </form>
        </AnswersContainer>
        {/* <ButtonsContainer
          buttonsContainerClassName={classNames({ [styles.partnerbtnContainer]: isPartner })}
        >
          <OnboardingContinueButton
            htmlType="submit"
            testid="next"
            isPending={isPending}
            className={classNames(styles.submit, {
              [styles.active]: formik.isValid,
            })}
            disabled={!formik.isValid}
            onClick={() => formik.submitForm()}
          />
        </ButtonsContainer> */}
      </OnBoardingContainer>
    </>
  );
};

AboutForm.initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  bio: '',
  email: '',
};

AboutForm.propTypes = {
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

AboutForm.defaultProps = {
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default AboutForm;
