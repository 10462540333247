import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from 'antd';
import moment from 'moment';

import { convertNameToAvatarPlaceholder, getTimeFormatMonthYear } from 'helpers/formatters';
import { getClientDetailsEffect } from 'store/effects/clientDetail';
import { getClientDetailsSelector } from 'store/selectors/clientDetail';
import { Avatar, Wrapper as PendingWrapper } from 'components';
import { Button } from 'components-antd';
import { ActiveTransactions } from './ActiveTransactions';
import { ActiveSearches } from './ActiveSearches';
import Contact from './Contact';
import Name from './Name';
import { ProfileIcons } from './Icons';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { PENDING } from 'settings/constants/apiState';
import { getAgentDetailsEffect } from 'store/effects';
import { TEAM_ADMIN } from 'settings/constants/roles';
import {
  openMessagesDrawerAction,
  changeMessagesDrawerTypeAction,
} from 'store/actions/drawers/messages';
import { appManageClientDrawerAction } from 'store/actions/app';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { AGENT_CONNECTION_STATUSES } from 'settings/constants/common';

import styles from './styles.module.scss';

const ProfileClient = ({ id, isAgent, className }) => {
  const details = useSelector(isAgent ? getAgentDetailsSelector : getClientDetailsSelector);
  const [isLoading, setIsLoading] = useState(false);
  const isPending = details.state === PENDING;
  const contact = details?.data || {};
  const dispatch = useDispatch();

  const getConnectionEffect = () => (isAgent ? getAgentDetailsEffect : getClientDetailsEffect);

  const getConnectionDetails = useCallback(() => {
    setIsLoading(true);
    dispatch(getConnectionEffect()({ id: id }, {}, (err) => setIsLoading(false)));
  }, [dispatch]);

  useEffect(() => {
    getConnectionDetails();
  }, []);

  const openMessageDrawer = () => {
    dispatch(openMessagesDrawerAction(true));
    dispatch(
      changeMessagesDrawerTypeAction({
        type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
        params: {
          threadId: null,
          participants: [
            {
              id: contact.Id,
              name: `${contact.FirstName} ${contact.LastName || ''}`,
              value: contact.Id,
              role: contact.Role,
              avatarUrl: contact.AvatarUrl,
              firstName: contact.FirstName,
              lastName: contact.LastName,
            },
          ],
        },
      }),
    );
    dispatch(appManageClientDrawerAction(false));
  };

  const getSubtitle = () => 'Client';

  return (
    <div testid="profile_connection" className={classNames(styles.profile, className)}>
      <PendingWrapper isPending={isPending}>
        <Avatar
          className={styles.profileAvatar}
          image={contact.AvatarUrl}
          placeholder={convertNameToAvatarPlaceholder(`${contact.FirstName} ${contact.LastName}`)}
        />
        <Name
          testid="client_name"
          className={styles.name}
          name={`${contact.FirstName} ${contact.LastName}`}
        />
        <div className={styles.subHeading}>
          {isAgent ? (
            <>
              <p>Agent</p>
              {contact?.TeamRole === TEAM_ADMIN ? (
                <>
                  <div className={styles.spacer}></div>
                  <div className={styles.tag}>Admin</div>
                </>
              ) : null}
            </>
          ) : (
            <p>{getSubtitle()}</p>
          )}
        </div>

        <Button variant={'default'} className={styles.messageButton} onClick={openMessageDrawer}>
          <ProfileIcons name={ProfileIcons.MESSAGE} color="#262626" />
          <span>Message</span>
        </Button>

        {isLoading ? (
          <div className={styles.clientLoading}>
            <Skeleton active />
            <Skeleton active />
          </div>
        ) : (
          <>
            <Contact isAgent={isAgent} />
            <ActiveTransactions
              transactions={
                isAgent ? contact?.TransactionInstances : contact?.Client?.TransactionInstances
              }
            />
            {!isAgent ? <ActiveSearches activeSearches={contact?.Client?.SearchInstances} /> : null}
          </>
        )}
      </PendingWrapper>
    </div>
  );
};

ProfileClient.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isAgent: PropTypes.bool,
};

ProfileClient.defaultProps = {
  className: '',
  isAgent: false,
};

export default ProfileClient;
