import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';

import { EditTransactionDocumentDrawer } from 'components';

import { useDispatch, useSelector } from 'react-redux';
import { AsideNavigation, Content, PageWrapper } from 'components/Transactions';
import {
  getTransactionDocumentsFilterEffect,
  getTransactionEffect,
  resetTransactionDocumentsEffect,
  getKeyDatesEffect,
} from 'store/effects/transactions';

import { DocumentsContent } from './components';

import styles from './styles.module.scss';
import { getTransactionDocumentsSelector } from 'store/selectors/transactionDocuments';

const TransactionDocuments = (props) => {
  const { className, match } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const config = { id: match.params.id };
    dispatch(
      getTransactionEffect(config, { silent: true }, (err) => {
        if (!err) {
          dispatch(
            getTransactionDocumentsFilterEffect({}, {}, () => {
              setLoading(false);
            }),
          );
        }
      }),
    );
    dispatch(getKeyDatesEffect({ transactionId: match.params.id }, { silent: true }));

    return () => dispatch(resetTransactionDocumentsEffect());
  }, []);

  return (
    <div className={classNames(styles.transactionDocuments, className)}>
      <PageWrapper>
        <AsideNavigation />
        <Content>
          <DocumentsContent loading={loading} />
        </Content>
      </PageWrapper>
      <EditTransactionDocumentDrawer />
    </div>
  );
};

TransactionDocuments.propTypes = {
  className: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,
};

TransactionDocuments.defaultProps = {
  className: '',
};

export default TransactionDocuments;
