import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { groupNotificationsTypes } from 'settings/constants/notifications';
import { markNotificationAsReadEffect } from 'store/effects/notifications';
import { getMessageTime } from 'helpers';
import { Avatar } from 'components';

import moment from 'moment';
import { ArrowRight } from 'components/Icons';
import ActionsList from 'components/Drawers/components/ActionsList';

import Title from '../../Title';
import Subtitle from '../../Subtitle';

import styles from './styles.module.scss';
import { Tag } from 'components-antd';
import BaseSummaryNotification from '../BaseSummaryNotification';

const SeverityLevel = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
};

const BaseNotification = (props) => {
  const {
    className,
    ids,
    avatarUrl,
    avatarPlaceholder,
    title,
    subtitle,
    date,
    isRead,
    severityLevel,
    notificationSummary,
  } = props;
  const { onClick, groupType, markable, onMarkAllAsRead } = props;

  const [actionPosition, setActionPosition] = useState(null);

  const dispatch = useDispatch();

  const onClickNotification = (event) => {
    if (!markable && isRead) {
      return onClick();
    }

    if (!actionPosition) {
      return setActionPosition({ x: event?.clientX, y: event?.clientY });
    }
  };

  const onMarkAsRead = () => {
    if (!isRead && markable) {
      dispatch(markNotificationAsReadEffect({ ids }));
    }

    if (!isRead && !markable) {
      onMarkAllAsRead();
    }

    setActionPosition(null);
  };

  const onOpen = () => {
    onClick();
    setActionPosition(null);

    if (markable) {
      onMarkAsRead();
    }
  };

  const getDate = () => {
    if (
      groupType === groupNotificationsTypes.Unread ||
      groupType === groupNotificationsTypes.Earlier
    ) {
      const notificationDate = moment(date);
      if (notificationDate.isSame(moment(), 'day')) {
        return getMessageTime(date);
      }
      return notificationDate.isSame(moment(), 'week')
        ? notificationDate.format('dddd')
        : getMessageTime(date);
    }
    return getMessageTime(date);
  };

  const getActions = () => {
    const list = [
      { title: markable ? 'Open' : 'View', onClick: onOpen, testid: 'open_notification' },
    ];

    if (!isRead && markable) {
      list.push({ title: 'Mark as read', onClick: onMarkAsRead, testid: 'mark_as_read' });
    }

    if (!isRead && !markable) {
      list.push({ title: 'Mark all as read', onClick: onMarkAsRead, testid: 'mark_all_as_read' });
    }

    return list;
  };

  const onCloseActionList = () => {
    setActionPosition(null);
  };

  const severityLevelTag = () => {
    if (!severityLevel || severityLevel === SeverityLevel.LOW) {
      return null;
    }

    const text = severityLevel === SeverityLevel.MEDIUM ? 'Alert' : 'Urgent';
    const style = severityLevel === SeverityLevel.MEDIUM ? styles.tagAlert : styles.tagUrgent;

    return <Tag className={classNames(styles.tag, style)}>{text}</Tag>;
  };

  return (
    <div
      onClick={onClickNotification}
      className={classNames(className, styles.baseNotification)}
      testid="base_notification"
    >
      {!isRead && <div className={styles.unreadDot} />}
      <Avatar
        className={styles.avatarWrapper}
        avatarClassName={styles.avatar}
        src={avatarUrl}
        placeholder={avatarPlaceholder}
      />
      <div className={styles.block}>
        <div className={styles.holder}>
          {!notificationSummary && (
            <div className={styles.inner}>
              <Title
                className={classNames(styles.title, {
                  [styles.isRead]: isRead,
                  [styles.markable]: markable,
                })}
              >
                {title}
              </Title>
            </div>
          )}
          {!notificationSummary && (
            <Subtitle className={classNames({ [styles.unread]: !isRead })} noMargin>
              <div>{subtitle}</div>
            </Subtitle>
          )}
          {notificationSummary &&
            notificationSummary.map((notification, index) => (
              <div className={styles.notificationSummaryGroup}>
                <Title
                  key={index}
                  className={classNames(styles.notificationSummaryTitle, {
                    [styles.isRead]: isRead,
                    [styles.markable]: markable,
                  })}
                >
                  <BaseSummaryNotification summary={notification} />
                </Title>
              </div>
            ))}
          <Subtitle className={classNames({ [styles.unread]: !isRead })}>
            {markable && getDate()}
          </Subtitle>
        </div>
      </div>
      {!markable && (
        <div className={classNames(styles.date, { [styles.unread]: !isRead })} testid="time">
          <ArrowRight className={styles.arrow} />
        </div>
      )}
      {/**
       * Remvoving tags from the FE for now, the BE functionality is still intact and can be used later in the future.
       */}
      {/* <div>{severityLevelTag()}</div> */}
      <ActionsList actions={getActions()} position={actionPosition} onClose={onCloseActionList} />
    </div>
  );
};

BaseNotification.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string,
  avatarUrl: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  date: PropTypes.string,
  isRead: PropTypes.bool.isRequired,
  avatarPlaceholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.any]),
  onClick: PropTypes.func,
  groupType: PropTypes.string,
  markable: PropTypes.bool,
  onMarkAllAsRead: PropTypes.func,
  severityLevel: PropTypes.oneOf([SeverityLevel.LOW, SeverityLevel.MEDIUM, SeverityLevel.HIGH]),
};

BaseNotification.defaultProps = {
  className: '',
  avatarUrl: null,
  subtitle: '',
  avatarPlaceholder: undefined,
  onClick: () => {},
  markable: true,
  groupType: null,
  date: null,
  onMarkAllAsRead: () => {},
};

export default BaseNotification;
