import { useState } from 'react';
import Icons from '../Icons';
import styles from './styles.module.scss';

type DestinationPropsType = {
  destination: {
    name: string;
    address: string;
    carTimes: string[];
    transitTimes: string[];
  };
  hideFields: boolean;
  countStarredFields: number;
  starredFieldsCountHandler: Function;
};

function Destination(props: DestinationPropsType) {
  const [starred, setStarred] = useState<boolean>(false);

  const starClickHandler = () => {
    if (!starred) {
      setStarred(true);
      props.starredFieldsCountHandler('inc');
    } else if (starred && props.hideFields && props.countStarredFields === 1) {
      setStarred(true);
    } else {
      setStarred(false);
      props.starredFieldsCountHandler('dec');
    }
  };

  if (!starred && props.hideFields) return <></>;

  return (
    <div className={styles.destination}>
      <div className={styles.place}>
        <div onClick={() => starClickHandler()}>
          <Icons iconName={starred ? 'yellowStar' : 'star'} />
        </div>
        <div className={styles.placeName}>{props.destination.name}</div>
        <div className={styles.placeAddress}>{'(' + props.destination.address + ')'}</div>
      </div>
      <table className={styles.table}>
        <tr className={styles.row}>
          <td className={styles.column + ' ' + styles.columnHeading}>By Car</td>
          {props.destination.carTimes.map((cItem) => (
            <td className={styles.column}>{cItem}</td>
          ))}
        </tr>
        <tr>
          <td className={styles.column + ' ' + styles.columnHeading}>By Transit</td>
          {props.destination.transitTimes.map((tItem) => (
            <td className={styles.column}>{tItem}</td>
          ))}
        </tr>
      </table>
    </div>
  );
}

export default Destination;
