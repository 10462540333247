import { Buffer } from 'buffer';
import { getPropertyAddress } from 'helpers';
import { previewDocumentEffect } from 'store/effects/transactions';

export const getBase64Image = (contentType, base64) => `data:${contentType};base64,${base64}`;

export const loadImageFromVault: (_, __) => Promise<string> = (documentVaultUUID, dispatch) => {
  return new Promise((res, rej) => {
    if (documentVaultUUID) {
      dispatch(
        previewDocumentEffect({ DocumentVaultUUID: documentVaultUUID }, {}, (err, response) => {
          if (err) {
            rej(err);
          }
          return res(
            getBase64Image(
              response.headers['content-type'],
              Buffer.from(response.data).toString('base64'),
            ),
          );
        }),
      );
    }
  });
};

export const getImageSrc = async (url, data, contentType, documentVaultUUID, dispatch) => {
  let imageSrc = '#';
  if (url) {
    imageSrc = url;
  } else if (data && contentType) {
    imageSrc = getBase64Image(contentType, data);
  } else if (documentVaultUUID) {
    imageSrc = await loadImageFromVault(documentVaultUUID, dispatch);
  }

  return imageSrc;
};

export const getMessageMetaData = (shareProperty, tags: string[] = []) => {
  return Array.isArray(shareProperty)
    ? shareProperty.map((property) => ({
        EntityId: property?.Id,
        EntityData: { PropertyAddress: property.Address },
        Tags: tags,
      }))
    : {
        EntityId: shareProperty?.Id,
        EntityData: { PropertyAddress: shareProperty.Address },
        Tags: tags,
      };
};

export const buildMessageTopicName = (shareProperty) => {
  return Array.isArray(shareProperty)
    ? shareProperty.map((property) => getPropertyAddress(property?.Address)).join(', ')
    : getPropertyAddress(shareProperty);
};
