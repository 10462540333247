import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDynamicFormEffect } from 'store/effects/formProcess';
import { getDynamicFormSelector } from 'store/selectors/requestFormProcess';

export const useDocumentScroll = (props) => {
  const {
    loading,
    allDocuments,
    pdfDocAndPageRefs,
    isBundle,
    setScrolledDocumentId,
    setCurrentDocIndex,
    setCurrentPage,
  } = props;

  const [allowScroll, setAllowScroll] = useState(true);

  const {
    dynamicScrolledDocumentId: scrolledDocumentId,
    dynamicScrolledDocumentIndex: newDocIndex,
  } = useSelector(getDynamicFormSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (newDocIndex !== undefined && scrolledDocumentId !== undefined && !loading) {
      setAllowScroll(false);

      setScrolledDocumentId(scrolledDocumentId);
      setCurrentDocIndex(newDocIndex);
      setCurrentPage(0);

      setTimeout(() => {
        const element = document.getElementById(`pdf-doc-${newDocIndex}`);

        element?.scrollIntoView({
          behavior: 'smooth',
        });

        setAllowScroll(true);
      }, 300);

      dispatch(
        updateDynamicFormEffect({
          dynamicScrolledDocumentId: undefined,
          dynamicScrolledDocumentIndex: undefined,
        }),
      );
    }
  }, [scrolledDocumentId, newDocIndex, loading]);

  useEffect(() => {
    const handleScroll = () => {
      if (allDocuments.length && allowScroll) {
        let currentDocId = -1;
        let docIndex = -1;
        let currentPageIndex = -1;

        Object.values(pdfDocAndPageRefs.current).forEach((docItem: any) => {
          const rect = docItem?.getBoundingClientRect();

          if (rect && rect.top <= 140 && rect.bottom >= 140) {
            const indexDetails = docItem.id.split('pdf-page-')[1];

            const [documentIndex, pageIndex] = indexDetails.split('-');

            currentDocId = allDocuments?.[documentIndex].Id || allDocuments[0].Id;
            docIndex = allDocuments?.[documentIndex].Id ? +documentIndex : 0;
            currentPageIndex = allDocuments?.[documentIndex].Id ? +pageIndex : 0;
          }
        });

        setCurrentDocIndex(docIndex === -1 ? 0 : docIndex);
        setCurrentPage(currentPageIndex === -1 ? 0 : currentPageIndex);
        setScrolledDocumentId(currentDocId === -1 ? allDocuments[0].Id : currentDocId);
      }
    };

    isBundle && window.addEventListener('scroll', handleScroll, true);

    return () => {
      isBundle && window.removeEventListener('scroll', handleScroll, true);
    };
  }, [allDocuments, allowScroll]);
};
