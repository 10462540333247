import React, { memo, useMemo } from 'react';

const Lock2 = memo(({ className, color = 'white' }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 9.915V12H5V9.915C4.6664 9.79705 4.38522 9.56497 4.20619 9.25976C4.02715 8.95455 3.96177 8.59588 4.0216 8.24713C4.08144 7.89839 4.26264 7.58202 4.53317 7.35395C4.80371 7.12588 5.14616 7.00079 5.5 7.00079C5.85385 7.00079 6.1963 7.12588 6.46683 7.35395C6.73737 7.58202 6.91856 7.89839 6.9784 8.24713C7.03824 8.59588 6.97286 8.95455 6.79382 9.25976C6.61478 9.56497 6.33361 9.79705 6 9.915ZM2.00822e-06 5.998C2.00822e-06 5.447 0.445002 5 0.996002 5H10.004C10.554 5 11 5.446 11 5.998V13.002C11 13.553 10.555 14 10.004 14H0.996002C0.865037 14 0.735358 13.9742 0.614387 13.924C0.493416 13.8738 0.383529 13.8003 0.291016 13.7076C0.198502 13.6149 0.125179 13.5048 0.0752432 13.3838C0.0253073 13.2627 -0.000260974 13.133 2.00822e-06 13.002V5.998Z"
            fill={color}
          />
          <path
            d="M4 5V4C4 3.86826 4.02603 3.73781 4.0766 3.61616C4.12716 3.49451 4.20127 3.38405 4.29466 3.29112C4.38805 3.1982 4.49888 3.12464 4.62078 3.07468C4.74268 3.02472 4.87326 2.99935 5.005 3H5.995C6.12682 2.99908 6.25751 3.02428 6.37953 3.07414C6.50155 3.12401 6.61249 3.19755 6.70593 3.29053C6.79937 3.38351 6.87347 3.49407 6.92394 3.61585C6.97442 3.73762 7.00027 3.86819 7 4V5H4ZM2 4V7H9V4C9 2.337 7.656 1 5.995 1H5.005C4.61061 0.999347 4.21997 1.07646 3.85541 1.22693C3.49086 1.3774 3.15955 1.59827 2.88045 1.87691C2.60134 2.15556 2.37992 2.48649 2.22884 2.8508C2.07776 3.2151 2 3.60562 2 4Z"
            fill={color}
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

export default Lock2;
