import React, { useState } from 'react';

import { login } from 'api/user';
import classNames from 'classnames';
import { Input } from 'components';
import { Button } from 'components-antd';
import { useDispatch, useSelector } from 'react-redux';
import { getUserEmail } from 'store/selectors/user';
import styles from '../DowngradeModal/styles.module.scss';

type Props = {
  handleNextStep: () => void;
  cancelSubscription: (callback: () => void) => void;
  closeModal: () => void;
};

export const StepFour: React.FC<Props> = ({ handleNextStep, cancelSubscription, closeModal }) => {
  const userEmail = useSelector(getUserEmail);
  const [password, setPassword] = useState<string>('');
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleVerifyPassword = async () => {
    setIsLoading(true);
    try {
      const res = await login({ user: userEmail, password }, {});
      if (res.status === 200) {
        cancelSubscription(() => handleNextStep());
      }
    } catch (error) {
      setError('Incorrect password');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.content}>
      <div className={styles.header}>To proceed with cancellation, please enter your password.</div>

      <div className={styles.reasonContainer}>
        <Input
          type="password"
          value={password}
          onChange={(event) => {
            setError(null);
            setPassword(event.target.value);
          }}
          className={classNames(styles.input, { [styles.error]: !!error })}
          placeholder={'Enter Password'}
          error={error}
          variant={Input.LIGHT_ROUND}
        />
      </div>

      <div className={styles.btnContainer}>
        <Button variant="secondary" className={styles.cancelBtn} onClick={closeModal}>
          Never Mind
        </Button>
        <Button
          variant="primary"
          className={classNames(styles.confirmBtn, styles.red)}
          onClick={handleVerifyPassword}
          loading={isLoading}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
};
