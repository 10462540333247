import { handleActions } from 'redux-actions';
import { ERROR, IDLE } from 'settings/constants/apiState';
import { cloneDeep, get } from 'lodash-es';

import { requestGetClientsListAction, setClientsListStatusAction } from 'store/actions/clientsList';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetClientsListAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', initialData.data) || state.data,
      meta: get(payload, 'meta', initialData.meta),
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
    [setClientsListStatusAction]: (state, { payload }) => ({
      ...state,
      meta: {
        ...state.meta,
        Status: payload,
      },
    }),
  },
  initialData,
);
