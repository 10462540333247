import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Question } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Continue } from '../../Continue';

import styles from './styles.module.scss';
import { Button } from 'antd';

export const EnableShowing = ({ enable, onChange, onNext }) => {
  return (
    <div testid="what_enable_showing" className={styles.container}>
      <Question>Do you want to enable showing management?</Question>
      <AnswersContainer>
        <Button
          type="text"
          className={classNames(styles.answerBtn, { [styles.active]: enable === 'yes' })}
          onClick={() => onChange('yes')}
        >
          Yes
        </Button>
        <Button
          type="text"
          className={classNames(styles.answerBtn, { [styles.active]: enable === 'no' })}
          onClick={() => onChange('no')}
        >
          No
        </Button>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onNext} disabled={!enable} />
      </ButtonsContainer>
    </div>
  );
};

EnableShowing.propTypes = {
  enable: PropTypes.string,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
};

EnableShowing.defaultProps = {
  enable: '',
  onChange: () => {},
  onNext: () => {},
};
