import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isToday } from 'helpers';

const ItemWrapper = (props) => {
  const { className, children, deadline, timelineItemClassName, activeClassName } = props;

  return (
    <div
      testid="timeline_wrapper"
      className={classNames(
        timelineItemClassName,
        isToday(deadline) ? activeClassName : '',
        className,
      )}
    >
      {children}
    </div>
  );
};

ItemWrapper.propTypes = {
  className: PropTypes.string,
  timelineItemClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  deadline: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

ItemWrapper.defaultProps = {
  className: '',
  timelineItemClassName: '',
  activeClassName: '',
  deadline: null,
};

export default ItemWrapper;
