import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components-antd';

import styles from './styles.module.scss';

const actions = {
  Reassign: 'Reassign',
  Share: 'Share',
};

export const LocalFooter = ({ multiSelect, count, setShowModal }) => {
  if (!multiSelect) return <></>;

  return (
    <div className={styles.clientsFooter}>
      <h4>{count} Clients Selected</h4>
      <div className={styles.actions}>
        <Button
          variant="secondary-inverted"
          className={classNames(styles.btn, styles.share)}
          disabled={!count}
          onClick={() => setShowModal(actions.Share)}
        >
          {actions.Share}
        </Button>
        <Button
          variant="secondary"
          className={styles.btn}
          disabled={!count}
          onClick={() => setShowModal(actions.Reassign)}
        >
          {actions.Reassign}
        </Button>
      </div>
    </div>
  );
};

LocalFooter.propTypes = {
  multiSelect: PropTypes.bool,
  count: PropTypes.number,
  setShowModal: PropTypes.func,
};

LocalFooter.defaultProps = {
  multiSelect: false,
  count: 0,
  setShowModal: () => {},
};
