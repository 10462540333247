import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PENDING } from 'settings/constants/apiState';
import { routes } from 'settings/navigation/routes';
import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';

import { Input } from 'components';
import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';

import styles from './styles.module.scss';

const ApproveTaxes = ({ onNext, Controls, stageIndex }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);

  const propName = OnBoardingWizard.propNames.approveDownPayment;
  const storedAnswer =
    getWizardStageValue({
      obj: onBoarding,
      stageIndex,
      propName,
      signUpRole,
      parentPropName: 'PreApproval',
    }) || '';
  const [stageValue, setStageValue] = useState(storedAnswer);

  const onSave = () => {
    const cfg = {
      stageIndex,
      PreApproval: {
        [propName]: +stageValue,
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, { withoutRequest: true }, (err) => {
        if (!err) {
          onNext();
        }
      }),
    );
  };

  const isPending = onBoarding.state === PENDING;

  return (
    <OnBoardingWrapper testid="approve_down_payment_stage" isPending={isPending}>
      <Controls
        onNext={() => onSave(stageValue)}
        isNextDisabled={!stageValue.length}
        onClose={() => history.push(routes.mySearches)}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question>How much do you have for a down payment?</Question>
        <AnswersContainer>
          <Input
            type="number"
            isNumberFormat
            className={styles.input}
            variant={Input.LIGHT}
            value={stageValue}
            onChange={(e, value) => setStageValue(value)}
            prefix="$  "
            testid="approve_down_payment_input"
          />
        </AnswersContainer>
        <ButtonsContainer>
          <OnboardingContinueButton
            testid="approve_down_payment_next"
            onClick={() => onSave(stageValue)}
            isPending={isPending}
            disabled={!stageValue.length}
            iconArrow
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

ApproveTaxes.propTypes = {
  onNext: PropTypes.func,
  Controls: PropTypes.elementType,
  stageIndex: PropTypes.number,
};

ApproveTaxes.defaultProps = {
  onNext: () => {},
  Controls: () => null,
  stageIndex: undefined,
};

export default ApproveTaxes;
