const CounterAdd = (props) => (
  <div {...props}>
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="transparent" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5 18C29.5 17.1716 28.8284 16.5 28 16.5C27.1716 16.5 26.5 17.1716 26.5 18V26.5H18C17.1716 26.5 16.5 27.1716 16.5 28C16.5 28.8284 17.1716 29.5 18 29.5H26.5V38C26.5 38.8284 27.1716 39.5 28 39.5C28.8284 39.5 29.5 38.8284 29.5 38V29.5H38C38.8284 29.5 39.5 28.8284 39.5 28C39.5 27.1716 38.8284 26.5 38 26.5H29.5V18Z"
        fill="#262626"
      />
    </svg>
  </div>
);

export default CounterAdd;
