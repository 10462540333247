import { uniqBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

import { sortLocations } from 'helpers';
import { Col, InputNumber, Row } from 'components-antd';
import { Locations } from 'components';
import DeleteIcon from 'pages/FormBuilder/Icons/DeleteIcon';
import AddCircle from 'pages/FormBuilder/Icons/AddCircle';
import { ErrorProps } from 'types';

import styles from './styles.module.scss';

export interface LocationProps {
  UUID: string;
  areasOfOperation?: any;
  miles?: number;
}

interface FormGeoLocationsProps {
  onChange: (location) => void;
  disabled?: boolean;
  multiple?: boolean;
  showMiles?: boolean;
  breakLine?: boolean;
  locations?: LocationProps[];
  errors?: ErrorProps[];
}

interface FormLocationRowProps {
  UUID: string;
  value?: any;
  defaultMiles?: number;
}

type ColType = 'Location' | 'Miles' | 'Delete';

export const FormGeoLocations = ({
  onChange,
  disabled,
  multiple,
  showMiles,
  breakLine = false,
  errors = [],
  locations: locationProp,
}: FormGeoLocationsProps) => {
  const [locations, setLocations] = useState<LocationProps[]>([
    {
      UUID: uuidv4(),
      miles: 0,
    },
  ]);

  useEffect(() => {
    if (locationProp?.length) setLocations([...locationProp]);
    else
      setLocations([
        {
          UUID: uuidv4(),
          miles: 0,
        },
      ]);
  }, []);

  useEffect(() => {
    if (onChange) {
      const filteredLocations = locations.filter((location) => location?.areasOfOperation?.length);
      onChange(filteredLocations);
    }
  }, [locations]);

  const updateMiles = (uuid, value) => {
    setLocations((prev) =>
      prev.map((el) => {
        if (el.UUID === uuid) {
          el.miles = Number(value);
          el.UUID = uuidv4();
        }
        return el;
      }),
    );
  };

  const getSpan = (type: ColType) => {
    let span = 24;
    switch (type) {
      case 'Location':
        if (breakLine && !multiple) {
          span = 24;
        } else if (breakLine) {
          span = 20;
        } else if (multiple && showMiles) {
          span = 15;
        } else if (multiple) {
          span = 21;
        } else if (showMiles) {
          span = 17;
        }
        break;

      case 'Miles':
        if (breakLine && !multiple) {
          span = 24;
        } else if (breakLine) {
          span = 20;
        } else if (multiple) {
          span = 6;
        } else {
          span = 7;
        }
        break;

      case 'Delete':
        if (breakLine) {
          span = 4;
        } else if (multiple) {
          span = 1;
        }
        break;
      default:
        break;
    }

    return span;
  };

  const getError = (UUID) => {
    const error = errors.filter((el) => el.Id === UUID);
    if (error?.length) return error[0].message;
  };

  const formLocationRow = ({ UUID, value, defaultMiles = 0 }: FormLocationRowProps) => (
    <Row key={UUID} className={styles.locationRow} justify="space-between">
      <Col span={getSpan('Location')}>
        <Locations
          menuTop
          disabled={disabled}
          multiple
          allowStates={true}
          getStates={true}
          variant={Locations.LIGHT}
          onResult={(result, prepareData) => updateLocation(UUID, result, prepareData)}
          value={value}
          error={getError ? getError(UUID) : undefined}
          placeholder="Enter state, city, or zip"
          valuesWrapperClassName={styles.location}
          activeInputClassName={classNames(styles.activeInputClassName)}
          showPrefixIcon={true}
        />
      </Col>
      {showMiles && (
        <Col span={getSpan('Miles')} className={styles.miles}>
          <InputNumber
            size={'large'}
            addonBefore={'+'}
            addonAfter={'miles'}
            disabled={disabled}
            min={0}
            placeholder={'0'}
            defaultValue={defaultMiles}
            onBlur={(e) => updateMiles(UUID, e.target.value)}
          />
        </Col>
      )}
      <Col span={getSpan('Delete')} className={styles.deleteCol}>
        {multiple && locations?.length > 1 && (
          <DeleteIcon
            className={classNames(styles.deleteIcon, 'show-cursor')}
            onClick={() =>
              disabled ? null : setLocations((prev) => [...prev.filter((el) => el.UUID !== UUID)])
            }
          />
        )}
      </Col>
    </Row>
  );

  const updateLocation = (uuid, result, prepareData) => {
    setLocations((prev) =>
      prev.map((el) => {
        if (el.UUID === uuid) {
          let areasOfOperation = prepareData?.length
            ? [...prepareData, ...(el.areasOfOperation || [])]
            : el.areasOfOperation || [];
          if (result?.deleteItemId) {
            areasOfOperation = areasOfOperation.filter(
              ({ ProviderPlaceId: itemId }) => itemId !== result.deleteItemId,
            );
          }
          areasOfOperation = uniqBy(areasOfOperation, 'ProviderPlaceId');
          areasOfOperation.sort(sortLocations);
          el.areasOfOperation = areasOfOperation;
          el.UUID = uuidv4();
        }

        return el;
      }),
    );
  };

  const addLocation = () => {
    const location: LocationProps = { UUID: uuidv4() };
    if (showMiles) location.miles = 0;

    setLocations((prev) => [...prev, location]);
  };

  return (
    <>
      {locations.map((el) => {
        return formLocationRow({
          UUID: el.UUID,
          value: el.areasOfOperation?.length ? el.areasOfOperation : undefined,
          defaultMiles: el.miles,
        });
      })}
      {multiple && (
        <Row justify="end">
          <Col onClick={() => (disabled ? null : addLocation())}>
            <span className={classNames(styles.addLocationRow, 'show-cursor')}>
              <AddCircle /> <span className={styles.addLocation}>Add location</span>
            </span>
          </Col>
        </Row>
      )}
    </>
  );
};
