import { createSelector } from 'reselect';
import { getSharePropertySelector } from 'store/selectors/feed';
import {
  getIsCommentsDrawerOpenSelector,
  getCommentsDrawerParamsSelector,
} from 'store/selectors/drawers/comments';
import { SOCKET_MESSAGE_TYPES, SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import {
  getCurrentMessagesInfoSelector,
  getCurrentMessagesThreadId,
} from 'store/selectors/sockets/messages';
import { getUserRolesMapSelector, getUserId } from 'store/selectors/user';
import { getUnarchivedThreadsList } from 'store/selectors/sockets/threads';
import { orderBy } from 'lodash-es';
import { getClientAgentsSelector } from 'store/selectors/agents';

export const commentsDrawerInfoSelector = createSelector(
  getCurrentMessagesInfoSelector,
  getUnarchivedThreadsList,
  getIsCommentsDrawerOpenSelector,
  getSharePropertySelector,
  getUserRolesMapSelector,
  getClientAgentsSelector,
  getCurrentMessagesThreadId,
  getCommentsDrawerParamsSelector,
  getUserId,
  (
    messages,
    threads,
    drawerOpened,
    shareProperty,
    userRoles,
    agents,
    currentThreadId,
    params,
    userId,
  ) => {
    const threadArray = Object.values(threads);
    const threadsWithPropertyComments = threadArray.filter((thread) => {
      return !!thread.PropertyComments?.length;
    });

    const threadWithPropertyComments = threadsWithPropertyComments.reduce((acc, thread) => {
      const propertyComments = thread.PropertyComments?.filter((comment) => {
        return Array.isArray(comment?.MessageMeta)
          ? comment.MessageMeta.some((meta) => meta?.EntityId === shareProperty?.Id)
          : comment?.MessageMeta?.EntityId === shareProperty?.Id;
      });

      if (propertyComments.length) {
        const orderedComments = orderBy(propertyComments, ['CreatedDate'], ['desc']);

        thread.LastMessage = orderedComments[0].Text;
        thread.LastMessageCreatedDate = orderedComments[0].CreatedDate;

        return [...acc, thread];
      }
      return [...acc];
    }, []);

    messages = messages?.filter((message) => {
      // Extract Entity IDs from meta data
      const entityIDs = Array.isArray(message?.MessageMeta)
        ? message?.MessageMeta.map((item) => item.EntityId)
        : [message?.MessageMeta?.EntityId];

      // Extract Share property IDs
      const sharePropertyIDs = Array.isArray(shareProperty)
        ? shareProperty?.map((property) => property.Id)
        : [shareProperty?.Id];

      // Check if currently selected share property is part of message metadata
      const isMetaDataContainsSharedProperty = entityIDs.some((entityID) =>
        sharePropertyIDs.includes(entityID),
      );

      return (
        message.MessageType === SOCKET_MESSAGE_TYPES.PROPERTY_COMMENT &&
        isMetaDataContainsSharedProperty
      );
    });

    const orderedThreads = orderBy(
      threadWithPropertyComments,
      ['IsUnread', 'LastMessageCreatedDate'],
      ['desc', 'desc'],
    );

    return {
      drawerOpened,
      currentThreadId,
      shareProperty,
      threads: orderedThreads,
      messages,
      ...(userRoles || {}),
      agents,
      params,
      userId,
    };
  },
);
