import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const StatItem = (props) => {
  const { className, title, number, variant, testid } = props;

  return (
    <div testid={testid} className={classNames(styles.statItem, styles[variant], className)}>
      <div testid="stat_number" className={styles.number}>
        {number}
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

StatItem.NEW = 'new';
StatItem.OVERDUE = 'overdue';
StatItem.UPCOMING = 'upcoming';

StatItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  testid: PropTypes.string,
  number: PropTypes.number,
  variant: PropTypes.string.isRequired,
};

StatItem.defaultProps = {
  className: '',
  title: '',
  number: 0,
  testid: undefined,
};

export default StatItem;
