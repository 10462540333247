import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFormMetaEffect, requestAddTag } from 'store/effects/formBuilder';
import { Modal, Select, Button, Form, Input, Row, TagSelector } from 'components-antd';
import DollarFile from 'pages/FormBuilder/Icons/DollarFile';
import { getFormMetaSelect } from 'store/selectors/formBuilder';
import { CheckBox } from 'components/Icons';
import { LocationProps, FormGeoLocations } from 'pages/FormBuilder/components/FormGeoLocations';
import { ErrorProps, FormTeamPermission, FormUserPermission } from 'types';
import { MosaikTag } from 'app-constants';

import styles from './styles.module.scss';
import { v4 as uuidv4 } from 'uuid';

interface PublishProps {
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: (values) => void;
  confirmationLoading?: boolean;
  disabled?: boolean;
  errors?: ErrorProps[];
  formName: string;
  selectedTags?: number[];
  categories?: number[];
  users?: FormUserPermission[];
  formLocations?: LocationProps[];
  smartForm?: boolean;
  formCategoryTags?: number[];
  teams?: FormTeamPermission[];
}

export const PublishModal = ({
  open,
  onCancel,
  onSubmit,
  confirmationLoading,
  disabled,
  errors = [],
  formName,
  selectedTags = [],
  categories = [],
  formLocations,
  users = [],
  smartForm = false,
  formCategoryTags = [],
  teams = [],
}: PublishProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [disablePublish, setDisablePublish] = useState(false);
  const [selectedTagIds, setSelectedTagIds] = useState(selectedTags);
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState(formCategoryTags);
  const [permissionError, setPermissionError] = useState('');

  const {
    tags: allTags,
    categories: allCategories,
    users: allUsers = [],
    teams: allTeams = [],
    smartFormCategories: allSmartFormCategories = [],
  } = useSelector(getFormMetaSelect);
  const [locations, setLocations] = useState<LocationProps[]>([]);

  const areas = useMemo(() => {
    const val = formLocations?.[0];
    if (!val?.areasOfOperation?.length) return [];
    const grouped = val.areasOfOperation.reduce((acc, cur) => {
      const radius = cur?.Radius || 0;

      if (!acc[radius]) acc[radius] = [cur];
      else acc[radius].push(cur);
      return acc;
    }, {});

    return Object.keys(grouped).map((key, index) => ({
      UUID: uuidv4(),
      miles: Number(key),
      areasOfOperation: grouped[key],
    }));
  }, [locations]);

  const submitForm = () => {
    if (onSubmit) {
      const data = {
        ...form.getFieldsValue(),
        locations: locations
          .filter((el) => el.areasOfOperation?.length)
          .map((areas) => {
            const { miles, areasOfOperation, UUID } = areas;
            return areasOfOperation.map((area) => ({ ...area, Radius: miles }));
          })
          .flat(),
        tags: selectedTagIds,
      };

      if (smartForm && !data?.users?.length && !data?.locations?.length && !data?.teams?.length) {
        setPermissionError('Either add People, Location or Team permissions');
        return;
      }
      setPermissionError('');
      onSubmit(data);
    }
  };

  const onModalClose = () => {
    setLocations([]);
    form.resetFields();
    if (onCancel) onCancel();
  };

  const postAddTagActions = (isError: boolean, cb: () => void) => {
    if (isError) {
      dispatch(getFormMetaEffect());
    }
    setDisablePublish(false);
    cb();
  };

  const addTag = (tag, cb) => {
    dispatch(
      requestAddTag({ ...tag, tagType: MosaikTag.Form }, (err) => {
        postAddTagActions(!err, cb);
      }),
    );
  };

  const addSmartFormTag = (tag, cb) => {
    dispatch(
      requestAddTag({ ...tag, tagType: MosaikTag.SmartForm }, (err) => {
        postAddTagActions(!err, cb);
      }),
    );
  };

  return (
    <Modal
      footer={null}
      width={675}
      open={open}
      onCancel={onModalClose}
      title={
        <span className={styles.headerTitle}>
          <DollarFile className={styles.headerIcon} />
          <span className={styles.headerText}>Publish {smartForm ? 'Smart' : 'Quote'} Form</span>
        </span>
      }
      className={styles.formModal}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={submitForm}
        initialValues={{
          ['formName']: formName,
          ['tags']: selectedTags,
          ['formCategoryTag']: formCategoryTags,
          ['categories']: categories,
          ['users']: users.map((el) => el.UserId),
          ['teams']: teams.map((el) => el.Id),
        }}
      >
        <span className={styles.form}>
          <Form.Item
            label="Form Name"
            name="formName"
            required={true}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input
              size={'large'}
              maxLength={64}
              className={styles.formName}
              disabled={confirmationLoading || disabled}
              placeholder="Form Name"
            />
          </Form.Item>
          <Form.Item label={'Tags'} name="tags">
            <TagSelector
              handleChangeTag={setSelectedTagIds}
              allTags={allTags}
              selectedTags={selectedTags}
              disabled={confirmationLoading || disabled || disablePublish}
              setDisabled={setDisablePublish}
              addTag={addTag}
            />
          </Form.Item>
          {smartForm ? (
            <Form.Item label="Category Tags" name="formCategoryTag" required={false}>
              <TagSelector
                handleChangeTag={setSelectedCategoriesIds}
                placeholder="Select Categories Tags"
                allTags={allSmartFormCategories}
                selectedTags={selectedCategoriesIds}
                disabled={confirmationLoading || disabled || disablePublish}
                setDisabled={setDisablePublish}
                addTag={addSmartFormTag}
              />
            </Form.Item>
          ) : (
            <></>
          )}
          <span className={styles.hr} />
          {permissionError && <span className={styles.errorText}>{permissionError}</span>}
          <span className={styles.permissionTitle}>Permissions</span>
          {smartForm ? (
            <Form.Item label="People" name="users">
              <Select
                large
                showArrow={true}
                placeholder="Select People"
                mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode}
                menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                disabled={confirmationLoading || disabled}
                options={allUsers.map((el) => ({
                  label: el.Name,
                  value: el.UserId,
                  key: `users-${el.UserId}`,
                }))}
                filterOption={(input, option) =>
                  String((option?.label as unknown as string) || '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          ) : (
            <Form.Item
              label="Category"
              name="categories"
              required={true}
              rules={[{ required: true, message: 'Required' }]}
            >
              <Select
                large
                showArrow={true}
                placeholder="Select Categories"
                mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode}
                menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                disabled={confirmationLoading || disabled}
                options={allCategories.map((el) => ({
                  label: el.Title,
                  value: el.CategoryId,
                  key: `category-${el.CategoryId}`,
                }))}
                filterOption={(input, option) =>
                  String((option?.label as unknown as string) || '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}

          {smartForm && (
            <Form.Item label="Teams" name="teams">
              <Select
                large
                showArrow={true}
                placeholder="Select Teams"
                mode="multiple"
                menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                disabled={confirmationLoading || disabled}
                options={allTeams.map((el) => ({
                  label: el.Name,
                  value: el.Id,
                  key: `Team-${el.Id}`,
                }))}
                filterOption={(input, option) =>
                  String((option?.label as unknown as string) || '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}
          <Form.Item label="Locations" name="locations">
            <FormGeoLocations
              showMiles={true}
              multiple={true}
              disabled={disabled || confirmationLoading}
              onChange={setLocations}
              errors={(errors || []).filter((el) => el.type === 'Locations')}
              locations={areas?.length ? areas : undefined}
            />
          </Form.Item>
        </span>
        <Row justify="center">
          <Button
            htmlType="submit"
            variant="secondary"
            className={styles.submitButton}
            loading={confirmationLoading}
            disabled={confirmationLoading || disabled || disablePublish}
          >
            Publish Form
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
