/**
 * Feature Flags
 *
 * Can be set either from env variable or from local storage
 */

const LOCAL_STORAGE_KEY = 'featureFlags';

function getFlagsFromLocalStorage() {
  let result = {};
  try {
    result = localStorage.getItem(LOCAL_STORAGE_KEY)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '')
      : {};
  } catch (e) {
    result = {};
  }

  return result;
}

function getFlagState(name: string) {
  return (
    // Check environment variables
    process.env[`REACT_APP_${name}`] === 'true' ||
    // Check local storage
    getFlagsFromLocalStorage()[name] ||
    false
  );
}

export const featureFlags = {
  agentTeams: getFlagState('FF_AGENT_TEAMS'),
  customSearchPolygonDrawing: getFlagState('FF_CUSTOM_SEARCH_POLYGON_DRAWING'),
  gpt: getFlagState('FF_GPT'),
  paywall: getFlagState('FF_PAYWALL'),
  mozzie: getFlagState('FF_MOZZIE'),
};

(window as any).featureFlags = featureFlags;
