import { Modal } from 'components-antd';
import { ContactModalForm } from '../ContactModalForm';
import { TransactionContact } from 'types';

import styles from './styles.module.scss';

interface ContactModalProps {
  onCloseModal?: () => void;
  isOpen?: boolean;
  editData?: TransactionContact;
  onUpdate?: () => void;
  onRemove?: () => void;
}

export const ContactModal = ({ isOpen, onCloseModal, editData }: ContactModalProps) => {
  const isEditing = !!editData;
  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onCloseModal}
      maskClosable={false}
      destroyOnClose
      className={styles.contactModal}
    >
      <ContactModalForm
        isOpen={isOpen}
        editData={editData}
        onCloseModal={onCloseModal}
        isEditing={isEditing}
      />
    </Modal>
  );
};
