import { handleActions } from 'redux-actions';

import { MAP_VIEWS } from 'settings/constants/mapView';
import { resetFeedMapViewAction, setFeedMapViewAction } from 'store/actions/feed';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';

const initialData = {
  view: MAP_VIEWS.SPLIT,
};

export default handleActions(
  {
    [setFeedMapViewAction]: (state, { payload }) => ({
      ...state,
      view: payload.view || initialData.view,
    }),
    [resetFeedMapViewAction]: () => cloneDeep(initialData),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
