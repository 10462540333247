import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useIsProjectRoute from 'hooks/use-is-project-route';
import { requestGetProjectCategories } from 'store/effects/projects';
import { FilterSections } from './FilterSections/FilterSections';
import { requestGetTransactionPersons } from 'store/effects';

export const Filter = ({ isArchive }) => {
  // Remove duplicate clients

  const dispatch = useDispatch();
  const isProject = useIsProjectRoute();

  // useEffect(() => {
  //   dispatch(requestGetTransactionsEffect({}, { silent: !isIdle }));
  // }, []);
  // commenting this as transactions are already loaded on page load
  // TODO: long term fix could be to have separate redux state for displaying in filters

  useEffect(() => {
    dispatch(requestGetTransactionPersons({ IsProject: isProject }, { silent: true }));
    if (isProject) {
      dispatch(requestGetProjectCategories({}, { silent: true }));
    }
  }, [isProject]);

  return (
    <>
      <FilterSections />
    </>
  );
};
