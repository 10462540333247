export const MapDraw = ({ size, color = '#262626', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size ?? '24'}
    height={size ?? '24'}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M11 13.0037H13.475L19.975 6.50319C20.3032 6.17498 20.4876 5.72985 20.4876 5.2657C20.4876 4.80155 20.3032 4.35641 19.975 4.02821C19.6468 3.70001 19.2016 3.51562 18.7375 3.51562C18.2733 3.51563 17.8282 3.70001 17.5 4.02821L11 10.5287V13.0037Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 5.5L18.5 7.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 13H6.5C5.67157 13 5 13.6716 5 14.5V14.5C5 15.3284 5.66965 16 6.49808 16C7.84871 16 9.86762 16 11.5011 16C12.3295 16 13 16.6716 13 17.5V17.5C13 18.3284 12.3284 19 11.5 19H8"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
