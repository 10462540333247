import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Answers from '../../Answers';
import { Continue } from '../../Continue';
import { isPropertyAddress } from 'helpers';
import { Space, Switch } from 'components-antd';
import { FadeInAnimationWrapper } from 'components/Animations';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import {
  Question,
  PropertyAddressInput,
} from 'pages/Workshop/Transactions/TransactionCreate/components';
import { ManualAddressInput } from '../../../ManualAddressInput';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';

import styles from './styles.module.scss';

interface PropertyAddressProps {
  className?: string;
  onNext?: Function;
  currentQuestionId?: string;
}

const PropertyAddress = ({ className, onNext, currentQuestionId }: PropertyAddressProps) => {
  const { preForm } = useSelector(getTransactionPreFormSelector);

  const [manualEntry, setManualEntry] = useState(false);

  const canContinue = useMemo(
    () => isPropertyAddress(preForm?.[transactionPreFormQuestionsIds.address]),
    [preForm],
  );

  const onNextHandler = (event) => {
    if (currentQuestionId === transactionPreFormQuestionsIds.address && canContinue) {
      onNext && onNext(event);
    }
  };

  return (
    <div testid="what_listing_address" className={classNames(styles.container, className)}>
      <Question>What is the property address?</Question>
      <AnswersContainer>
        <Answers className={styles.answer}>
          {manualEntry ? (
            <FadeInAnimationWrapper key="manualAddressEntry">
              <ManualAddressInput />
            </FadeInAnimationWrapper>
          ) : (
            <FadeInAnimationWrapper key="autoAddressEntry">
              <PropertyAddressInput
                valuesWrapperClassName={styles.valuesWrapper}
                searchWrapperClassName={styles.searchWrapper}
                unitClassName={styles.propertyUnit}
                placeholder=" "
                unitFieldPlaceholderText=" "
              />
            </FadeInAnimationWrapper>
          )}
          <Space className={styles.manualEntrySwitch}>
            <Switch checked={manualEntry} onClick={setManualEntry} />
            <span className={styles.manualEntryText}>{'Manual Entry'}</span>
          </Space>
        </Answers>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onNextHandler} className={styles.continue} disabled={!canContinue} />
      </ButtonsContainer>
    </div>
  );
};

export default PropertyAddress;
