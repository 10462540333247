import classNames from 'classnames';

import { InputLabel, Input } from 'components';
import { ClientRole } from 'components/Transactions';

import styles from './styles.module.scss';

interface RoleFieldProps {
  value: string;
  error?: string;
  onChange: (value: string) => void;
  onBlurRoleInput: (...args) => void;
  onRoleKeyPress: (...args) => void;
  className?: string;
}

export const Role = ({
  value,
  error,
  onChange,
  onBlurRoleInput,
  onRoleKeyPress,
  className,
}: RoleFieldProps) => {
  return (
    <div className={classNames(styles.role, className)}>
      <div className={styles.fieldWrapper}>
        <>
          <InputLabel label="Role" className={styles.label} />
          <ClientRole
            isSearchIcon={false}
            onChange={(e) => {
              const selectedValue = e.target.value?.value || '';
              onChange(selectedValue);
            }}
            value={{ tagRole: value }}
            onBlur={onBlurRoleInput}
            onKeyPress={onRoleKeyPress}
            variant={Input.LIGHT_ROUND}
            placeholder=" "
          />
        </>
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};
