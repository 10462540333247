import classnames from 'classnames';

import { Col, Select, Option } from 'components-antd';
import { CancelModal } from 'pages/Workshop/Forms/components/FormDetailModal/components/CancelModal';
import { Icons, ICON_VARIANT_TYPE } from 'pages/Workshop/Forms/Icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFormDetailsSelector } from 'store/selectors/requestFormProcess';

import styles from './styles.module.scss';
import { FORM_STATUS } from './helper';
import classNames from 'classnames';
import { FormsArchiveTableStatus, FormsTableStatus } from 'app-constants';

export type FormStatusType = 'completed' | 'pending' | 'canceled' | 'voided';

interface FormStatusProps {
  selectedFormStatus?: string;
}

export const FormStatus = ({ selectedFormStatus: status }: FormStatusProps) => {
  const [formStatus, setFormStatus] = useState<string>();

  const { data: formDetails } = useSelector(getFormDetailsSelector);

  useEffect(() => {
    if (formDetails) {
      if (formDetails.isCompleted) {
        setFormStatus('Completed');
      } else if (formDetails.isVoid) {
        setFormStatus('Voided');
      } else if (formDetails.isCancelled) {
        setFormStatus('Canceled');
      } else {
        setFormStatus(
          status ? (status === FormsTableStatus.Finalizing ? 'Pending' : status) : 'Pending',
        );
      }
    }
  }, [formDetails]);

  const getStatusClassName = () => {
    switch (formStatus) {
      case FormsTableStatus.WaitingOnYou:
        return styles.waitinOnYou;
      case FormsTableStatus.WaitingOnOthers:
        return styles.waitinOnOthers;
      case FormsTableStatus.Finalizing:
        return styles.finalizing;
      case FormsTableStatus.Draft:
        return styles.draft;
      case FormsArchiveTableStatus.Complete:
        return styles.complete;
      case FormsArchiveTableStatus.Voided:
        return styles.voided;
      case FormsArchiveTableStatus.Canceled:
        return styles.voided;

      default:
        return styles.pendingStatus;
    }
  };

  return (
    <>
      <div className={styles.statusContent}>
        <div className={classNames(styles.statusText, getStatusClassName())}>{formStatus}</div>
      </div>
    </>
  );
};
