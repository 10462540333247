import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Icons } from '../Icons';
import { CREATE } from 'settings/constants/mode';
import { routes } from 'settings/navigation/routes';
import { resetSearchCriteriaEffect } from 'store/effects/search';
import { openSearchResultsFilterDrawerEffect } from 'store/effects';
import { setPreApprovalModalAction } from 'store/actions/mySearches';

import styles from './styles.module.scss';

export const LocalHeader = ({ isClient }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectTo = () => {
    dispatch(resetSearchCriteriaEffect());
    dispatch(openSearchResultsFilterDrawerEffect({ mode: CREATE }));
    history.push(routes.search);
  };

  const openModal = () => {
    dispatch(setPreApprovalModalAction({ isModalOpen: true }));
  };

  return (
    <div className={styles.mySearchesHeader}>
      <div className={styles.btn} onClick={redirectTo}>
        <Icons className={styles.addIcon} variant={Icons.ADD} />
        New Search
      </div>
      {isClient ? (
        <div className={styles.btn} onClick={openModal}>
          Pre-Approval
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

LocalHeader.propTypes = {
  isClient: PropTypes.bool,
};

LocalHeader.defaultProps = {
  isClient: false,
};
