import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Input } from 'components';
import { Question } from 'pages/RequestQuote/components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { Select } from 'components';
import { TransactionFinancingType } from 'app-constants';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { Continue } from '../../Continue';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import styles from './styles.module.scss';
import Plus from 'components/Icons/Plus';
import { AnswerCard } from '../../../AnswerCard';
import { useState } from 'react';

interface FinancingTypeProps {
  className?: string;
  onNext?: (event) => void;
  currentQuestionId?: typeof transactionPreFormQuestionsIds[keyof typeof transactionPreFormQuestionsIds];
  setStage?: (event) => void;
}

const financingTypeOptions = Object.values(TransactionFinancingType).map((type) => ({
  value: type,
  name: type,
}));

interface TransactionEarnestMoney {
  FieldAnswer: number | '';
  DueDate: string | '';
  FieldName: string;
  Title: string;
}

const initialEarnestStates: TransactionEarnestMoney[] = [
  {
    Title: 'First Deposit Due',
    FieldName: 'Initial Earnest Money Deposit',
    FieldAnswer: '',
    DueDate: '',
  },
  {
    Title: 'Second Deposit Due',
    FieldName: 'Second Earnest Money Deposit',
    FieldAnswer: '',
    DueDate: '',
  },
  {
    Title: 'Third Deposit Due',
    FieldName: 'Third Earnest Money Deposit',
    FieldAnswer: '',
    DueDate: '',
  },
];

export const FinancingType = ({
  className,
  currentQuestionId,
  onNext,
  setStage,
}: FinancingTypeProps) => {
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const [transactionEarnestMoney, setTransactionEarnestMoney] = useState<TransactionEarnestMoney[]>(
    preForm?.[transactionPreFormQuestionsIds.transactionEarnestMoney] ?? [
      { ...initialEarnestStates[0] },
    ],
  );

  const valid = transactionEarnestMoney.every(
    (obj) =>
      (obj.FieldAnswer !== '' && obj.DueDate !== '') ||
      (obj.FieldAnswer === '' && obj.DueDate === ''),
  );

  const onAnswerHandler = (answer) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.financingType]: answer,
      }),
    );
  };

  const onNextHandler = (event) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.transactionEarnestMoney]: transactionEarnestMoney,
      }),
    );

    if (setStage) {
      setStage(transactionPreFormQuestionsIds.financingDetails);
    }

    if (currentQuestionId === transactionPreFormQuestionsIds.financingType && onNext) {
      onNext(event);
    }
  };

  const setEarnestValue = (val: string, index: number, key: string) => {
    setTransactionEarnestMoney((prev) => {
      let updated = [...prev];
      updated[index][key] = val;
      return updated;
    });
  };

  return (
    <div testid="what_financing_type" className={classNames(styles.container, className)}>
      <Question>{"Let's confirm some transaction details."}</Question>
      <AnswersContainer>
        <div className={styles.answerContainer}>
          <Select
            value={preForm?.[transactionPreFormQuestionsIds.financingType]}
            onSelect={(_, { value }) => onAnswerHandler(value)}
            options={financingTypeOptions}
            className={{
              wrapper: styles.financingType,
            }}
            arrowIconClassName={styles.arrowdownicon}
            placeholder={'Choose financing type'}
            isArrowIcon={true}
            variant={Select.LIGHT_ROUND}
            label={'What type of financing is being used?'}
          />
          {transactionEarnestMoney.map(({ FieldAnswer, DueDate, Title }, index) => (
            <AnswerCard
              key={index}
              remove={index > 0}
              onRemove={() => {
                setTransactionEarnestMoney((prev) => prev.filter((_, i) => index !== i));
              }}
            >
              <Input
                variant={Input.LIGHT_ROUNDED}
                placeholder="$0"
                isNumberFormat
                prefix="$"
                onChange={(e, val) => setEarnestValue(val, index, 'FieldAnswer')}
                value={FieldAnswer}
                label={`What is the ${Title.split(' ')[0].toLowerCase()} earnest money deposit?`}
              />
              <p className={classNames(styles.label, styles.marginTop)}>Due Date</p>
              <DatePicker
                variant={DatePicker.ROUNDED}
                onChange={(e) => setEarnestValue(e?.target?.value[0], index, 'DueDate')}
                value={DueDate}
                options={{ enableTime: false }}
              />
            </AnswerCard>
          ))}
          {transactionEarnestMoney.length < 3 && (
            <div className={styles.addContainer}>
              <Plus className={styles.plus} color={'#515151'} />
              <p
                className={styles.addText}
                onClick={() => {
                  setTransactionEarnestMoney((prev) => [
                    ...prev,
                    { ...initialEarnestStates[transactionEarnestMoney.length] },
                  ]);
                }}
              >
                Add Another
              </p>
            </div>
          )}
        </div>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onNextHandler} disabled={!valid} />
      </ButtonsContainer>
    </div>
  );
};
