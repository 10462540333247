import { createSelector } from 'reselect';

const localState = ({ loginGroup }) => loginGroup;

export const getForgotPasswordSelector = createSelector(
  localState,
  ({ forgotPassword }) => forgotPassword,
);

export const getResetPasswordSelector = createSelector(
  localState,
  ({ resetPassword }) => resetPassword,
);

export const getChangePasswordSelector = createSelector(
  localState,
  ({ changePassword }) => changePassword,
);

export const getSignUpRoleSelector = createSelector(localState, ({ signUpRole }) => signUpRole);
