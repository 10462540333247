import styles from './styles.module.scss';
import { ThirdPartyIcon } from 'components/Icons';

const ThirdPartyIntegrations = () => {
  return (
    <div className={styles.thirdPartyWrapper}>
      <h2 className={styles.title}>Third Party</h2>
      <div>
        <div className={styles.thirdPartyContainer}>
          <div className={styles.disclaimer}>
            <ThirdPartyIcon className={styles.icon} />
            <p>
              You have no active third party integrations at this time.
              <br />
              Please contact <a href="mailto:support@mosaik.io">support@mosaik.io</a> for assistance
              with integrations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdPartyIntegrations;
