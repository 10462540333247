import { createSelector } from 'reselect';
import { get } from 'lodash-es';

const localState = ({ drawers }) => get(drawers, 'quotes');

export const getIsQuotesDrawerOpenSelector = createSelector(localState, ({ open }) => !!open);

export const getIsQuotesDrawerTypeSelector = createSelector(localState, ({ type }) => type);

export const getQuotesDrawerParams = createSelector(localState, ({ params }) => params);
