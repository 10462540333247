import classnames from 'classnames';
import { Fragment, useEffect, useState } from 'react';

import { CustomPanel } from './CustomPanel';
import { AssignToInput } from 'components/Transactions';
import { Collapse, DeleteModal, Divider, Modal, Select } from 'components-antd';
import { tasksStatusesIds, tasksStatusesOptions } from 'settings/constants/transactionTasks';
import { DueDate } from '../../TransactionTasks/components/TaskModalForm/DueDate';
import { Avatar, CalendarSimple, Checkmark, Minus, Plus, TrashIcon } from 'components/Icons';

import styles from './styles.module.scss';
import { formatDate } from 'helpers';
export type UpdatedTaskEntities = {
  AssigneeIds?: number[];
  DueDate?: string | Date;
  Status?: string;
};

interface MultiTaskUpdateModalProps {
  showTaskUpdateModal: boolean;
  fullAccess?: boolean;
  isAggregatePage?: boolean;
  setShowTaskUpdateModal: (showModal: boolean) => void;
  handleUpdateTasks: (
    updatedTaskEntities: UpdatedTaskEntities,
    setLoading: (loading: boolean) => void,
  ) => void;
  handleDeleteTasks: (
    setLoading: (loading: boolean) => void,
    setShowModal: (open: boolean) => void,
  ) => void;
}

export const MultiTaskUpdateModal = ({
  showTaskUpdateModal,
  isAggregatePage = false,
  fullAccess = true,
  setShowTaskUpdateModal,
  handleUpdateTasks,
  handleDeleteTasks,
}: MultiTaskUpdateModalProps) => {
  const key1 = 'reassign';
  const key2 = 'dueDate';
  const key3 = 'status';

  const initialDueDateState = {
    DueDate: undefined,
    ControlOperatorOffset: undefined,
  };

  const [assignees, setAssignees] = useState([]);
  const [dueDate, setDueDate] =
    useState<{ DueDate?: Date; ControlOperatorOffset?: string }>(initialDueDateState);
  const [status, setStatus] = useState<string>();

  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);

  useEffect(() => {
    setAssignees([]);
    setDueDate(initialDueDateState);
    setStatus(undefined);
  }, [showTaskUpdateModal]);

  const onUpdate = () => {
    const updatedTaskEntities = {
      ...(assignees?.length
        ? { AssigneeIds: assignees.map((assignee: { id: number }) => assignee.id) }
        : undefined),
      ...(dueDate?.DueDate ? { DueDate: formatDate(dueDate.DueDate, 'YYYY-MM-DD') } : undefined),
      ...(status ? { Status: status } : undefined),
    };
    handleUpdateTasks(updatedTaskEntities, setUpdateLoading);
  };

  return (
    <>
      <DeleteModal
        open={deleteConfirmation}
        onCancel={() => setDeleteConfirmation(false)}
        entityName="these tasks"
        closable={false}
        maskClosable={false}
        confirmLoading={updateLoading}
        onOk={() => handleDeleteTasks(setUpdateLoading, setDeleteConfirmation)}
      />
      <Modal
        title="Update Tasks"
        open={showTaskUpdateModal}
        onCancel={() => setShowTaskUpdateModal(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={onUpdate}
        okButtonProps={{ style: { width: 152, height: 52 } }}
        okText="Save"
        className={styles.taskUpdateModal}
        confirmLoading={updateLoading}
        closable={!updateLoading}
        maskClosable={false}
      >
        <Collapse
          ghost
          expandIcon={(panelProps) =>
            panelProps.isActive ? (
              <Minus className={styles.btnWrapper} />
            ) : (
              <Plus className={styles.btnWrapper} />
            )
          }
          expandIconPosition="end"
        >
          {isAggregatePage ? (
            <></>
          ) : fullAccess ? (
            <CustomPanel
              key={key1}
              icon={
                <div className={styles.btnWrapper}>
                  <Avatar />
                </div>
              }
              header="Reassign"
              title="Reassign all selected tasks to:"
            >
              <AssignToInput
                disabled={updateLoading}
                placeholder="Select participant(s)"
                values={assignees}
                onChange={(e, val) => setAssignees(val)}
                wrapperClassName={styles.reassignInputWrapper}
                inputClassName={styles.reassignInput}
              />
            </CustomPanel>
          ) : (
            <></>
          )}

          {fullAccess && (
            <CustomPanel
              key={key2}
              icon={<CalendarSimple className={styles.btnWrapper} />}
              header="Due Date"
              title="Change the due date of all selected tasks to:"
            >
              <DueDate
                isViewMode={updateLoading}
                wrapperClassName={styles.dueDateInputWrapper}
                hideAsterisk={true}
                hideLabel={true}
                isTemplate={false}
                values={dueDate}
                onChangeManyFields={(dueDateObject) => setDueDate(dueDateObject)}
                multiTasks={isAggregatePage}
              />
            </CustomPanel>
          )}

          <CustomPanel
            key={key3}
            icon={<Checkmark className={styles.btnWrapper} />}
            header="Status"
            title="Change the status of all selected tasks to:"
          >
            <div className={styles.statusSelectWrapper}>
              <Select
                disabled={updateLoading}
                placeholder="Select status"
                size="large"
                bordered={false}
                className={classnames(styles.statusSelect, { [styles.disabled]: updateLoading })}
                options={tasksStatusesOptions?.filter(
                  (item) => item?.value !== tasksStatusesIds?.overdue,
                )}
                value={status}
                onChange={setStatus}
              />
            </div>
          </CustomPanel>
        </Collapse>

        {fullAccess && (
          <Fragment>
            <div
              className={classnames(styles.headerContainer, {
                [styles.disabled]: updateLoading,
              })}
            >
              <TrashIcon
                className={styles.trashBtnWrapper}
                stroke="#ec455e"
                width={20}
                height={20}
              />
              <div
                className={styles.warningText}
                onClick={() => !updateLoading && setDeleteConfirmation(true)}
              >
                Delete Tasks
              </div>
            </div>
            <Divider className={styles.divider} />
          </Fragment>
        )}
      </Modal>
    </>
  );
};
