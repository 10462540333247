import { Avatar } from 'components';
import { getInitials } from 'utils';
import { Icons } from 'pages/Vault/Icons';

import styles from './styles.module.scss';
import { formatDate } from 'helpers';

export const AvatarColumn = ({ name, imgSrc }) => {
  return (
    <div className={styles.avatarTableColumn}>
      <Avatar avatarClassName={styles.avatar} src={imgSrc} placeholder={getInitials(name)} />
      <span>{name || '-'}</span>
    </div>
  );
};

export const DocumentNameColumn = ({ name, docIconType }) => {
  return (
    <div className={styles.documentTableColumn}>
      <Icons variant={docIconType} />
      <span className={styles.name}>{name || '-'}</span>
    </div>
  );
};

export const DateTimeColumn = ({ date }) => {
  return (
    <div className={styles.dateTimeColumn}>
      <p>{formatDate(date, 'M/D/YYYY')}</p>
      <p className={styles.time}>{formatDate(date, 'hh:mm A')}</p>
    </div>
  );
};
