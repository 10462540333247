import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components';
import { FullArrowRight } from 'components/Icons';

import styles from './styles.module.scss';

const NextButton = ({ onClick, className, title, iconArrow, disabled, ...props }) => (
  <Button
    className={classNames(styles.button, className, {
      [styles.active]: !disabled,
    })}
    title={title || 'Continue'}
    icon={iconArrow ? <FullArrowRight /> : null}
    disabled={disabled}
    onClick={onClick}
    {...props}
    titleClassName={styles.buttonTitle}
  />
);

NextButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  iconArrow: PropTypes.bool,
  disabled: PropTypes.bool,
};

NextButton.defaultProps = {
  className: '',
  title: undefined,
  iconArrow: false,
  disabled: false,
};

export default NextButton;
