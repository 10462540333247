import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorMessage } from 'helpers';

import { setMlsAccessModalAction } from 'store/actions/mlsAccess';
import { setAgentSelectedMlsServiceAction } from 'store/actions/user';
import { isMlsAccessModalOpenedSelector } from 'store/selectors/mlsAccess';
import { Add } from 'components/Icons';
import { getListOfAgentMlsServices, IMlsService } from 'api/agent/mls';
import { MlsItem } from './MlsItem';

import styles from './styles.module.scss';

const MlsIntegration: React.FC<{}> = (props) => {
  const [AgentMlsServices, setAgentMlsServices] = useState<IMlsService[]>([]);
  const isMlsAccessModalOpen = useSelector(isMlsAccessModalOpenedSelector);

  const dispatch = useDispatch();
  const handleAddMlsClick = () => {
    dispatch(
      setMlsAccessModalAction({
        isModalOpened: true,
        showOnboardingText: false,
      }),
    );
  };

  const handleError = () => {
    showErrorMessage("Cannot load list of available agent MLS'. Try to reload the page");
  };

  const fetchListing = async () => {
    try {
      const response = await getListOfAgentMlsServices();
      if (response.data?.success) {
        setAgentMlsServices(response.data.result);
        dispatch(setAgentSelectedMlsServiceAction(response.data.result));
      } else {
        handleError();
      }
    } catch (err) {
      handleError();
    }
  };

  useEffect(() => {
    fetchListing();
  }, []);

  useEffect(() => {
    if (!isMlsAccessModalOpen) {
      fetchListing();
    }
  }, [isMlsAccessModalOpen]);

  return (
    <div className={styles.section}>
      <h2 className={styles.title}>MLS</h2>
      <div className={styles.mlsWrapper}>
        <div className={styles.mlsItemsWrapper}>
          {AgentMlsServices.map((mls) => (
            <MlsItem value={mls} setOnUpdate={fetchListing} />
          ))}
        </div>
        <div className={styles.addMls}>
          <Add className={styles.addMlsBtn} onClick={handleAddMlsClick} color={Add.ORANGE_CIRCLE} />
          <span>Add MLS</span>
        </div>
      </div>
    </div>
  );
};

export default MlsIntegration;
