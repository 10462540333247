import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Input, Locations } from 'components';
import { getLocationContext, getPlaceComponent } from 'helpers/locations';
import { LOCALITY, POSTAL_CODE, ROUTE, STATE, STREET_NUMBER } from 'settings/constants/locations';
import styles from './styles.module.scss';

const Organization = (props) => {
  const { className, formik, isPending } = props;

  const onAddressChange = useCallback(
    (result) => {
      const location = result?.result?.[0];
      const coordinates = result?.coordinates;

      if (location) {
        const updatedCity = getLocationContext(location, LOCALITY);
        const updatedState = getLocationContext(location, STATE);
        const updatedZip = getLocationContext(location, POSTAL_CODE);
        const street = getPlaceComponent(location, ROUTE);
        const streetNumber = getLocationContext(location, STREET_NUMBER);
        const geolocation = coordinates
          ? {
              Lat: coordinates.lat,
              Long: coordinates.lng,
            }
          : null;

        formik.setFieldValue('address', {
          City: updatedCity,
          State: updatedState,
          Zip: updatedZip,
          Line1: [streetNumber, street?.name].filter((i) => !!i).join(' '),
          ProviderPlaceId: location.place_id,
          PlaceName: result?.placeName,
          Geolocation: geolocation,
        });
      } else {
        formik.setFieldValue('address', null);
      }
    },
    [formik],
  );

  return (
    <div className={classNames(styles.organization, className)}>
      <Input
        name="organization"
        value={formik.values.organization}
        onChange={formik.handleChange}
        placeholder="Organization"
        error={formik.touched.organization ? formik.errors.organization : ''}
        disabled={isPending}
        testid="organization"
        variant={Input.LIGHT}
        className={styles.input}
      />
      <Input
        name="jobTitle"
        value={formik.values.jobTitle}
        onChange={formik.handleChange}
        placeholder="Job Title"
        error={formik.touched.jobTitle ? formik.errors.jobTitle : ''}
        disabled={isPending}
        testid="job_title"
        variant={Input.LIGHT}
        className={styles.input}
      />
      <Locations
        multiple={false}
        name="address"
        types={['address']}
        onResult={onAddressChange}
        placeholder="Office Address"
        error={formik.touched.address ? formik.errors.address : ''}
        disabled={isPending}
        value={formik.values.address ? [formik.values.address] : []}
        testid="address"
        variant={Locations.LIGHT}
        className={styles.input}
        valuesWrapperClassName={styles.locations}
        searchIcon={null}
      />
      <Input
        name="suiteUnit"
        value={formik.values.suiteUnit}
        onChange={formik.handleChange}
        placeholder="Suite / Unit"
        error={formik.touched.suiteUnit ? formik.errors.suiteUnit : ''}
        disabled={isPending}
        testid="suite_unit"
        variant={Input.LIGHT}
        className={styles.input}
      />
    </div>
  );
};

Organization.propTypes = {
  className: PropTypes.string,
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    values: PropTypes.shape({
      organization: PropTypes.string,
      jobTitle: PropTypes.string,
      address: PropTypes.shape({}),
      suiteUnit: PropTypes.string,
    }),
    errors: PropTypes.shape({
      organization: PropTypes.string,
      jobTitle: PropTypes.string,
      address: PropTypes.string,
      suiteUnit: PropTypes.string,
    }),
    touched: PropTypes.shape({
      organization: PropTypes.bool,
      jobTitle: PropTypes.bool,
      address: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
      suiteUnit: PropTypes.bool,
    }),
  }).isRequired,
  isPending: PropTypes.bool,
};

Organization.defaultProps = {
  className: '',
  isPending: false,
};

export default Organization;
