import { THIRD_PARTY } from './roles';

export const groupQuotesBy = {
  STATUS: 'Status',
  CATEGORY: 'Category',
  CLIENT: 'Client',
  PROPERTY: 'Property',
  ARCHIEVED: 'Archieved',
};

export const quoteRequestStatus = {
  Draft: 'Draft', // Quote request was initiated by client, however the request form wasn't yet filled
  New: 'New', // Request form is filled out and submitted to Partner
  PendingAnswer: 'PendingAnswer', // Partner have seen the request form, but didn't responded yet
  Responded: 'Responded', // Partner have responded to the quote request
  Canceled: 'Canceled', // Not used right now, but potentially a Partner can cancel the request
  Ignored: 'Ignored', // Not used right now. The quote request becomes "Ignored" when passes it's DueDate
};
export const quoteRequestStatusDisplayMap = {
  [quoteRequestStatus.Draft]: 'Draft',
  [quoteRequestStatus.New]: 'New',
  [quoteRequestStatus.PendingAnswer]: 'Pending',
  [quoteRequestStatus.Responded]: 'Responded',
  [quoteRequestStatus.Canceled]: 'Canceled',
  [quoteRequestStatus.Ignored]: 'Ignored',
};

export const quoteArchiveStatus = {
  NoResponse: 'NoResponse',
  Won: 'Won',
  Lost: 'Lost',
  Canceled: 'Canceled',
};

export const NO_ARCHIVE_STATUS = 'NoStatus';

export const quoteArchiveStatusOptions = [
  { name: 'No Status', value: NO_ARCHIVE_STATUS },
  { name: 'No Response', value: quoteArchiveStatus.NoResponse },
  { name: 'Won', value: quoteArchiveStatus.Won },
  { name: 'Lost', value: quoteArchiveStatus.Lost },
  { name: 'Canceled', value: quoteArchiveStatus.Canceled },
];

export const quotesStatusesMap = (userRole) => {
  switch (userRole) {
    case THIRD_PARTY: {
      return {
        [quoteRequestStatus.New]: 'New',
        [quoteRequestStatus.PendingAnswer]: 'Pending',
        [quoteRequestStatus.Responded]: 'Quoted',
      };
    }
    default: {
      return {
        [quoteRequestStatus.Draft]: 'Draft',
        [quoteRequestStatus.New]: 'Pending',
        [quoteRequestStatus.PendingAnswer]: 'Pending',
        [quoteRequestStatus.Responded]: 'Received',
      };
    }
  }
};

export const filterStatusMap = (userRole) => {
  switch (userRole) {
    case THIRD_PARTY: {
      return {
        Responded: 'Received',
        PendingAnswer: 'Pending',
        New: 'New',
      };
    }
    default: {
      return {
        Responded: 'Received',
        PendingAnswer: 'Pending',
        Draft: 'Drafts',
      };
    }
  }
};
