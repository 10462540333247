import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { getQuoteFormBuilderEffect, updateQuoteFormResponse } from 'store/effects/formBuilder';
import { ArrowContinue, ArrowDown, ArrowUp } from 'components/Icons';
import { getQuoteFormMetaSelect } from 'store/selectors/formBuilder';
import { FORM_MODE, FORM_QUESTION_TYPE } from 'app-constants';
import { JumpType, QuestionType, ResponseType } from 'types';
import { Button, Divider, Progress } from 'components-antd';
import { QuestionContainer } from 'pages/FormBuilder';
import { link } from 'settings/navigation/link';

import styles from './styles.module.scss';

export const QuoteFormPreview = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [nextDisabled, setNextDisabled] = useState(false);
  const [previousDisabled, setPreviousDisabled] = useState(false);
  const [percent, setPercent] = useState(0);

  const { versionId } = useParams<{ versionId: string }>();

  const { Id, questions, responses } = useSelector(getQuoteFormMetaSelect);

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

  const [finalQuestions, setFinalQuestions] = useState<QuestionType[]>([]);
  const question = finalQuestions[activeQuestionIndex];
  const response = responses?.find((response) => response?.UUID === question?.UUID);

  const [jLQs, setJLQs] = useState<QuestionType[]>([]);
  const [jLIds, setJLIds] = useState<{}>();

  const [totalLength, setTotalLength] = useState(0);

  const lastQuestionIndex = finalQuestions.length - 1;

  useEffect(() => {
    const jumpLogicObject = questions
      .filter((question) => question.JumpLogic)
      .reduce((obj, question) => {
        obj[question.UUID] = Object.values(question.JumpLogic || {});
        return obj;
      }, {});

    setJLIds(jumpLogicObject);

    const allJumpLogicIds = Object.values(jumpLogicObject).flat(1);

    const jLQs = questions.filter((question) => allJumpLogicIds.includes(question.UUID));
    setJLQs(jLQs);

    const notJLQs = questions.filter((question) => !allJumpLogicIds.includes(question.UUID));
    setFinalQuestions(notJLQs);

    setTotalLength(Object.keys(jumpLogicObject).length + notJLQs.length);
  }, [questions]);

  useEffect(() => {
    if (
      question?.JumpLogic &&
      (question.Type === FORM_QUESTION_TYPE.YesNo
        ? response?.Answer
        : JSON.parse(response?.Answer || '[]').length)
    ) {
      const addQuestion = jLQs?.find(
        (jLQ) =>
          jLQ.UUID ===
          (question.JumpLogic as JumpType)[
            question.Type === FORM_QUESTION_TYPE.YesNo
              ? response?.Answer
              : JSON.parse(response?.Answer as string)
          ],
      );

      const currentQuestionJL = (jLIds as {})[question.UUID];

      const removeQuestion = finalQuestions.filter((question) =>
        currentQuestionJL?.includes(question?.UUID),
      );

      const removeIndex = finalQuestions.findIndex(
        (question) => removeQuestion.length && removeQuestion[0].UUID === question.UUID,
      );

      const updatedQuestions = finalQuestions.slice();
      if (removeIndex >= 0) {
        updatedQuestions.splice(removeIndex, 1);
      }
      if (addQuestion?.UUID) {
        const index = questions.findIndex((question) => addQuestion.UUID === question.UUID);
        const prevQs = questions[index - 1];
        const prevQsIndex = updatedQuestions.findIndex(
          (question) => prevQs?.UUID === question.UUID,
        );

        updatedQuestions.splice(prevQsIndex + 1, 0, addQuestion as QuestionType);
      }
      setFinalQuestions(updatedQuestions);
    } else if (
      question?.JumpLogic &&
      (question.Type === FORM_QUESTION_TYPE.YesNo
        ? !response?.Answer
        : !JSON.parse(response?.Answer || '[]').length)
    ) {
      const currentQuestionJL = (jLIds as {})[question?.UUID];

      const removeQuestion = finalQuestions.filter((question) =>
        currentQuestionJL?.includes(question?.UUID),
      );

      const removeIndex = finalQuestions.findIndex(
        (question) => removeQuestion.length && removeQuestion[0].UUID === question?.UUID,
      );

      const updatedQuestions = finalQuestions.slice();
      if (removeIndex >= 0) {
        updatedQuestions.splice(removeIndex, 1);
      }
      setFinalQuestions(updatedQuestions);
    }
  }, [responses]);

  useEffect(() => {
    if (versionId) dispatch(getQuoteFormBuilderEffect({ versionId }));
  }, [versionId]);

  useEffect(() => {
    if (question?.Required && !response?.Answer) {
      setNextDisabled(true);
    } else if (activeQuestionIndex === lastQuestionIndex) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
    if (activeQuestionIndex === 0) {
      setPreviousDisabled(true);
    } else {
      setPreviousDisabled(false);
    }
  }, [activeQuestionIndex, lastQuestionIndex, question, response]);

  const numberOfQuestions = finalQuestions.length - 1;
  const activeQuestionExists = activeQuestionIndex !== undefined;

  const handleNextQuestion = () => {
    let formResponse = response?.Answer;

    if (question?.Type === FORM_QUESTION_TYPE.ListBox) {
      formResponse = JSON.parse(response?.Answer || '[]');
    }

    if (question?.JumpLogic && formResponse?.length && question.JumpLogic[formResponse]) {
      const nextQsId = question.JumpLogic[formResponse];
      const nextQsIndex = finalQuestions.findIndex(
        (finalQuestion) => finalQuestion.UUID === nextQsId,
      );
      setActiveQuestionIndex(nextQsIndex);
    } else {
      if (activeQuestionExists && activeQuestionIndex < numberOfQuestions) {
        setActiveQuestionIndex(activeQuestionIndex + 1);
      }
    }
  };

  const handlePreviousQuestion = () => {
    if (activeQuestionExists && activeQuestionIndex > 0) {
      setActiveQuestionIndex(activeQuestionIndex - 1);
    }
  };

  const handleExitTest = () => {
    if (Id) {
      history.push(link.toDashboardServiceDirectoryFormsQuoteForm(Id));
    }
  };

  const handleContinue = () => {
    questions?.forEach((question) => {
      if (!finalQuestions.some((qs) => qs.UUID === question.UUID)) {
        const resp = responses.find((resp) => resp.UUID === question.UUID);
        handleFormResponse({ ...resp, Answer: '' });
      }
    });

    handleNextQuestion();
  };

  useEffect(() => {
    if (activeQuestionExists) {
      setPercent(((activeQuestionIndex + 1) / totalLength) * 100);
    }
  }, [activeQuestionIndex, totalLength]);

  const handleFormResponse = (updatedResponse: Partial<ResponseType>) => {
    dispatch(updateQuoteFormResponse({ ...response, ...updatedResponse }));
  };

  return (
    <>
      <Progress percent={percent} />
      <div className={styles.previewContainer}>
        <QuestionContainer
          mode={FORM_MODE.View}
          formQuestion={question}
          formResponse={response}
          handleFormResponse={handleFormResponse}
        />
        <Button
          variant="secondary-inverted"
          className={classnames(styles.continueBtn, {
            [styles.disabledBtn]: nextDisabled && !(activeQuestionIndex === lastQuestionIndex),
          })}
          disabled={nextDisabled && !(activeQuestionIndex === lastQuestionIndex)}
          onClick={
            activeQuestionIndex === finalQuestions.length - 1 ? handleExitTest : handleContinue
          }
        >
          {activeQuestionIndex === finalQuestions.length - 1 ? 'Exit Test' : 'Continue'}
          <ArrowContinue />
        </Button>
      </div>

      <div className={styles.navigationFooter}>
        <div className={styles.formNavigation}>
          <div onClick={() => handlePreviousQuestion()} className={styles.navigationBtn}>
            <ArrowUp
              className={classnames(styles.arrow, { [styles.disabledBtn]: previousDisabled })}
            />
          </div>
          <Divider style={{ height: '100%', margin: '0px' }} type="vertical" />
          <div
            onClick={() => (nextDisabled ? undefined : handleContinue())}
            className={styles.navigationBtn}
          >
            <ArrowDown
              className={classnames(styles.arrow, {
                [styles.disabledBtn]: nextDisabled || activeQuestionIndex === lastQuestionIndex,
              })}
            />
          </div>
        </div>
      </div>
    </>
  );
};
