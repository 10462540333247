import { getWorkshopActivityLogs } from 'api/activityLog';
import {
  requestGetActivityLogsAction,
  resetActivityLogsItemsListAction,
} from 'store/actions/activityLogsWorkshop';
import Api from 'store/effects/core/api';

export const requestGetActivityLogsEffect = (cfg) => {
  const requestParams = {
    action: requestGetActivityLogsAction,
    method: getWorkshopActivityLogs,
  };
  let sendRequest = Api.execBase(requestParams);
  return sendRequest(cfg);
};

export const resetActivityLogsItemsListEffect = (cfg) => (dispatch) =>
  dispatch(resetActivityLogsItemsListAction(cfg));
