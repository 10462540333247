import { apiServer } from 'settings/web-services/api';

export function createUpdateShowing(cfg) {
  return apiServer.post('/showing', cfg);
}

export function getShowingParticipantsList(transactionId) {
  return apiServer.get(`/showing/participant-list/${transactionId}`);
}

export function getShowingSummary(showingId) {
  return apiServer.get(`/showing-appointment/summary/${showingId}`);
}

export function getFilteredShowings(query) {
  return apiServer.get(`/showing/filtered?${query}`);
}

export function deleteShowing(cfg) {
  return apiServer.delete(`/showing/${cfg.Id}`);
}

export function sendAnnouncement(cfg) {
  return apiServer.post(`/announcement`, cfg);
}
