import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getTransactionTemplateEffect } from 'store/effects/template';
import { getTransactionTemplateSelector } from 'store/selectors/template';

import { Wrapper } from 'components';
import { TemplateTabs } from '../components/TemplateTabs';
import { editTransactionTemplate } from 'api/templates';
import { Role } from 'app-constants';
import { routes } from 'settings/navigation/routes';
import { getUserRoleSelector } from 'store/selectors/user';
import { link } from 'settings/navigation/link';
import { requestGetProjectCategories } from 'store/effects/projects';

export const TransactionTemplateEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isProject = location.pathname.includes('project');

  const userRole = useSelector(getUserRoleSelector);

  const { id: templateId } = useParams<{ id: string }>();

  const template = useSelector(getTransactionTemplateSelector);

  useEffect(() => {
    dispatch(requestGetProjectCategories({}, { silent: true }));
    dispatch(getTransactionTemplateEffect({ id: templateId }));
  }, [templateId, dispatch]);

  useEffect(() => {
    editTransactionTemplate(+templateId, { IsEditing: true });
    return () => {
      editTransactionTemplate(+templateId, { IsEditing: false });
    };
  }, []);

  const handleGoBack = (transactionId?: number) => {
    if (userRole === Role.SuperUser) {
      history.push(
        isProject
          ? link.toDashboardProjectTemplateView(transactionId)
          : link.toDashboardTransactionTemplateView(transactionId),
      );
    } else if (userRole === Role.Agent) {
      history.push(
        isProject
          ? link.toProjectTemplateView(transactionId)
          : link.toTransactionTemplateView(transactionId),
      );
    }
  };

  const handleCancel = () => {
    if (userRole === Role.SuperUser) {
      history.push(
        isProject ? routes.dashboardProjectsTemplates : routes.dashboardTransactionsTemplates,
      );
    } else if (userRole === Role.Agent) {
      history.push(isProject ? routes.templatesProjects : routes.templatesTransactions);
    }
  };

  return (
    <Wrapper isPending={template.isPending}>
      <TemplateTabs
        isViewMode={false}
        handleGoBack={handleGoBack}
        handleCancel={handleCancel}
        transactionTemplate={template.data}
      />
    </Wrapper>
  );
};
