import { createAction } from 'redux-actions';

export const sortPartnersAction = createAction('DASHBOARD/SORT_PARTNERS');
export const searchPartnersAction = createAction('DASHBOARD/SEARCH_PARTNERS');
export const requestGetPartnersAction = createAction('REQUEST/GET_PARTNERS');
export const requestDeleteDeactivatePartnerAction = createAction(
  'REQUEST/DELETE_DEACTIVATE_PARTNER',
);
export const requestPutActivatePartnerAction = createAction('REQUEST/PUT_ACTIVATE_PARTNER');
export const requestGetPartnerByIdAction = createAction('REQUEST/GET_PARTNER_BY_ID');
export const requestUpdatePartnerAction = createAction('REQUEST/PUT_PARTNER');
export const requestPostPartnerAction = createAction('REQUEST/POST_PARTNER');
export const requestSendInviteAction = createAction('REQUEST/SEND_INVITE');
