import moment from 'moment';
import { groupNotificationsTypes } from 'settings/constants/notifications';
import { orderBy } from 'lodash-es';
import { link } from 'settings/navigation/link';

export const filterNotificationsByType = (arr, type) =>
  (arr || []).filter((item) => {
    const today = moment().startOf('day');
    const isToday = moment(item?.CreatedDate).isSame(today, 'd');
    if (type === groupNotificationsTypes.Unread) {
      return !item?.IsRead;
    }
    if (type === groupNotificationsTypes.Today) {
      return isToday;
    }

    if (type === groupNotificationsTypes.Earlier) {
      return item?.IsRead && !isToday;
    }

    return false;
  });

export const navigateToWorkShopShowings = (url = '') => {
  let navigationPath = link.workShopShowings();
  const uuidPatternRegix = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

  // Use the pattern to extract the UUID from the URL
  const matches = url.match(uuidPatternRegix);

  // Check if there is a match
  if (matches && matches.length > 0) {
    const extractedUuid = matches[0];
    navigationPath = `${navigationPath}?showing=${extractedUuid}&type=feedback`;
  }
  return navigationPath;
};

export const orderByDate = (arr, propName, order = ['desc']) =>
  orderBy(arr, [(item) => moment(item?.[propName]).valueOf()], order);

export const getNotificationSummaryInParts = (notification) => {
  const notificationArray = notification.split(' ');
  const isNumber = (str) => !isNaN(str);
  const getTransactionMessage = (inputArray, index) =>
    inputArray
      .slice(index)
      .filter((item) => item !== 'Transaction:')
      .join(' ');
  let obj = {
    text: '',
    tasks: '',
    transaction: '',
  };
  const orderedArray = [];
  for (let i = 0; i < notificationArray.length; i++) {
    const item = notificationArray[i];

    if (item === 'across' || item === 'Transaction:') {
      orderedArray.push('transaction');
      if (item === 'Transaction:') {
        obj.text = `${obj.text} ${item}`;
        i = i + 1;
      }

      obj.transaction = getTransactionMessage(notificationArray, i);
      break;
    }

    if (isNumber(item) && ['tasks', 'task', 'tasks.', 'task.'].includes(notificationArray[i + 1])) {
      orderedArray.push('task');
      obj.tasks = `${item} ${notificationArray[i + 1]}`;
      i++; // Skip the next item as it's already processed
    } else if (!['tasks', 'task', 'tasks.', 'task.'].includes(item)) {
      if (obj.text === '') {
        orderedArray.push('text');
        obj.text = `${item}`;
      } else {
        obj.text = `${obj.text} ${item}`;
      }
    }
  }

  return { summaryNotification: obj, orderedArray };
};
