/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Input } from 'components';
import { AddRounded } from 'components/Icons';

import styles from './styles.module.scss';
import { isValidInputTimeValue } from '@testing-library/user-event/dist/utils';

const InputList = ({ testid, list, formik, name, addBtnTitle, disabled, inputClass }) => {
  const [listValue, setListValue] = useState(list);
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    const inputItem = document.querySelector('#input0');
    inputItem.value.length > 0 ? setShowAdd(true) : setShowAdd(false);
  }, []);

  const onAdd = () => {
    setListValue((prevValue) => [...prevValue, '']);
  };

  const onChange = (value, index) => {
    const copyList = [...listValue];
    copyList.splice(index, 1, value);
    setListValue([...copyList]);
    formik.setFieldValue(name, [...copyList]);
    value.length > 0 ? setShowAdd(true) : setShowAdd(false);
  };

  const onRemove = (index) => {
    const copyList = [...listValue];
    copyList.splice(index, 1);
    setListValue(copyList);
    formik.setFieldValue(name, [...copyList]);
  };

  return (
    <div testid={testid}>
      {listValue.map((item, index) => (
        <div key={index}>
          <Input
            id={`input${index}`}
            className={inputClass}
            variant={Input.LIGHT}
            name={`${item}-${index}`}
            value={item}
            onChange={(e, value) => onChange(value, index)}
            removeHandler={listValue?.length > 1 ? () => onRemove(index) : undefined}
            error={formik.touched?.[name]?.[index] ? formik.errors?.[name]?.[index] : ''}
            disabled={disabled}
            testid="list_input"
          />
        </div>
      ))}
      {showAdd && (
        <button
          type="button"
          onClick={onAdd}
          className={classNames(styles.addLinkBtn, { [styles.first]: listValue.length === 0 })}
          testid="add_button"
        >
          <AddRounded className={styles.addLinkBtnIcon} />
          <span>{addBtnTitle}</span>
        </button>
      )}
    </div>
  );
};

InputList.propTypes = {
  disabled: PropTypes.bool,
  isPhone: PropTypes.bool,
  name: PropTypes.string.isRequired,
  addBtnTitle: PropTypes.string.isRequired,
  testid: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  formik: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
  }).isRequired,
};

InputList.defaultProps = {
  list: [],
  isPhone: false,
  disabled: false,
  testid: undefined,
};

export default InputList;
