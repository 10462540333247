import { createAction } from 'redux-actions';

export const openSearchResultsFilterDrawerAction = createAction(
  'SEARCH_RESULTS/OPEN_FILTER_DRAWER',
);
export const openAddEditSchoolDrawerAction = createAction('SEARCH_RESULTS/ADD_EDIT_SCHOOL_DRAWER');
export const saveAddEditSchoolDataAction = createAction('REQUEST/SAVE_SCHOOL');
export const saveAddEditSchoolRawDataAction = createAction('REQUEST/SAVE_SCHOOL_RAW_DATA');
export const showWishlistTabAction = createAction('REQUEST/SHOW_WISHLIST');
export const openSavedSearchesDrawerAction = createAction(
  'SEARCH_RESULTS/OPEN_SAVED_SEARCHES_DRAWER',
);
export const requestGetSearchResultsAction = createAction('REQUEST/GET_SEARCH_RESULTS');
export const requestSaveSearchAction = createAction('REQUEST/SAVE_SEARCH');
export const requestSaveSearchInstanceAction = createAction('REQUEST/SAVE_SEARCH_INSTANCE');
export const sortSearchResultsAction = createAction('SEARCH/SEARCH_RESULTS');
export const setSearchDrawerCommuteIdAction = createAction('SEARCH/SET_DRAWER_COMMUTE_ID');
export const setSearchResultsMapViewAction = createAction('SEARCH_RESULTS/SET_MAP_VIEW');
export const resetSearchResultsMapViewAction = createAction('SEARCH_RESULTS/RESET_MAP_VIEW');
export const setSearchPageInfoAction = createAction('SEARCH/SET_PAGE_INFO');

export const toggleSearchMapDrawingModeAction = createAction('SEARCH/TOGGLE_MAP_DRAWING_MODE');
export const cleanSearchDrawnPolygonAction = createAction('SEARCH/CLEAN_DRAWN_POLYGON');
export const setSearchDrawnPolygonAction = createAction('SEARCH/SET_DRAWN_POLYGON');

export const setSearchViewportCoordinatesAction = createAction('SEARCH/SET_VIEWPORT_COORDINATES');
export const cleanSearchViewportCoordinatesAction = createAction(
  'SEARCH/CLEAN_VIEWPORT_COORDINATES',
);

export const setSearchGoogleLocationAction = createAction('SEARCH/SET_GOOGLE_LOCATION');

export const setSearchCurrentPageInfoAction = createAction('FEED/SET_CURRENT_PAGE_INFO');
