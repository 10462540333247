import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { cloneDeep, isEqual } from 'lodash-es';
import { Popover } from 'antd';

import { saveSearchEffect, openSearchResultsFilterDrawerEffect } from 'store/effects';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'pages/Properties/SearchResults/Icons';
import { Button, SimpleModal, Input, Checkbox } from 'components';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getSearchCriteriaSelector } from 'store/selectors/search';
import { getSavedSearchesSelector, getSearchInstancesSelector } from 'store/selectors/mySearches';
import { sortStrings, sortLocations } from 'helpers';
import { getSavedSearchesEffect } from 'store/effects/search';
import { ValidationSchema } from './validation';
import { saveSearchInstanceEffect } from 'store/effects/searchResults';
import { getSearchInstancesEffect } from 'store/effects/mySearches';
import { getCurrentContextSelector } from 'store/selectors/context';
import { SEARCH_INSTANCE_STATUS } from 'app-constants';
import { CREATE, EDIT } from 'settings/constants/mode';
import { showSuccessMessage } from 'helpers/success';
import { orderBy } from 'lodash-es';
import { setEditedSearchCriteriaAction } from 'store/actions/mySearches';
import { getSearchCriteriaFormattedString } from 'helpers';
import { getSearchResultsFilterDrawerSelector } from 'store/selectors/searchResults';
import { getClientInstancesEffect } from 'store/effects/clientInstances';

import styles from './styles.module.scss';

const SaveSearchButton = ({ className, search, mode, disabled }) => {
  const dispatch = useDispatch();
  const { isClient } = useSelector(getUserRolesMapSelector);
  const savedSearches = useSelector(getSavedSearchesSelector);
  const searchInstances = useSelector(getSearchInstancesSelector);
  const currentSearch = useSelector(getSearchCriteriaSelector);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const currentContext = useSelector(getCurrentContextSelector);
  const { isClientSearch } = useSelector(getSearchResultsFilterDrawerSelector);
  const isEditMode = mode === EDIT;

  const [savedSearch, setSavedSearch] = useState(false);

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: ValidationSchema,
    onSubmit(values) {
      setIsPending(true);
      const cfg = {
        name: values.name,
        status: SEARCH_INSTANCE_STATUS.ACTIVE,
      };
      if (isClient) {
        cfg.contextKey = currentContext?.ContextKey;
        dispatch(
          saveSearchInstanceEffect(cfg, {}, (err, resp) => {
            if (!err) {
              dispatch(getClientInstancesEffect());
              dispatch(
                getSearchInstancesEffect(cfg, {}, () => {
                  setPopupOpen(false);
                  formik.resetForm();
                  setIsPending(false);
                }),
              );

              const query = orderBy(resp.data.result, ['Id'], ['desc'])[0];
              dispatch(
                setEditedSearchCriteriaAction({
                  id: query.Id,
                  clientId: query.ClientId,
                  agentId: query.AgentId,
                  name: query.Name,
                  agentName: query.AgentName,
                  status: query.Status,
                  info: getSearchCriteriaFormattedString(query.DefaultPropertySearchPreferences)
                    ?.info,
                  criterias: query.DefaultPropertySearchPreferences,
                  photos: query.PreviewPhotoUrls || [],
                  total: query.NumOfResults,
                }),
              );
              dispatch(
                openSearchResultsFilterDrawerEffect({ open: false, mode: EDIT, isClientSearch }),
              );
              showSuccessMessage('Search Saved Successfully');
              setSavedSearch(true);
            } else {
              setIsPending(false);
            }
          }),
        );
      } else {
        dispatch(
          saveSearchEffect(cfg, {}, (err) => {
            if (!err) {
              dispatch(
                getSavedSearchesEffect({}, {}, () => {
                  setPopupOpen(false);
                  formik.resetForm();
                  setIsPending(false);
                }),
              );
              showSuccessMessage('Search Saved Successfully');
              setSavedSearch(true);
            } else {
              setIsPending(false);
            }
          }),
        );
      }
    },
  });

  const onClickHandler = () => {
    if (!disabled) setPopupOpen(!popupOpen);
  };

  const onClose = () => {
    setPopupOpen(false);
    formik.resetForm();
  };

  const sortFn = (clonedSearch) => {
    if (clonedSearch?.HomeType?.length) {
      clonedSearch?.HomeType.sort(sortStrings);
    }

    if (clonedSearch?.Locations?.length) {
      clonedSearch?.Locations.sort(sortLocations);
    }

    if (clonedSearch?.Status?.length) {
      clonedSearch?.Status.sort(sortStrings);
    }
  };

  const isAlreadyExistSearchInstance = () => {
    const clonedCurrentSearchInstance = cloneDeep(currentSearch);
    sortFn(clonedCurrentSearchInstance);
    if (searchInstances) {
      return searchInstances?.data?.some(({ SearchQuery }) => {
        const clonedSearchInstance = cloneDeep(SearchQuery);
        sortFn(clonedSearchInstance);

        return isEqual(clonedSearchInstance, clonedCurrentSearchInstance);
      });
    }
  };

  const isAlreadyExistSearch = () => {
    const clonedCurrentSearch = cloneDeep(currentSearch);
    sortFn(clonedCurrentSearch);

    return savedSearches?.data?.some(({ SearchQuery }) => {
      const clonedSearch = cloneDeep(SearchQuery);
      sortFn(clonedSearch);

      return isEqual(clonedSearch, clonedCurrentSearch);
    });
  };

  if (isClient) {
    if (!Object.keys(search).length && isAlreadyExistSearchInstance()) return null;
  } else {
    if (!Object.keys(search).length && isAlreadyExistSearch()) return null;
  }

  const popoverContent = () => <span>Save{isEditMode || savedSearch ? 'd' : ''} Search</span>;

  return (
    <div className={classNames(styles.saveSearchButton, className)}>
      <Popover
        content={!disabled && popoverContent}
        placement="top"
        overlayClassName={classNames('mosaikTooltip')}
        getPopupContainer={(triggerNode) => triggerNode}
        arrow={true}
      >
        <div
          testid="save_search_button"
          className={classNames(
            { [styles.active]: isEditMode },
            { [styles.disabled]: disabled },
            styles.btn,
          )}
          onClick={onClickHandler}
        >
          <Icon
            className={classNames(styles.savedIcon, { [styles.savedSearch]: savedSearch })}
            variant={Icon.SAVED}
          />
        </div>
      </Popover>

      <SimpleModal isOpen={popupOpen} onClose={onClose} contentClassName={styles.popup}>
        <div className={styles.popupInner}>
          <form testid="save_search_form" onSubmit={formik.handleSubmit}>
            <div className={styles.header}>
              <Icon className={styles.icon} variant={Icon.BOOKMARK} />
              <span className={styles.title}>Save Search</span>
            </div>
            <Input
              label="Name"
              name="name"
              variant={Input.LIGHT_FULL}
              placeholder="e.g. Chicago - 3 BR / 2 BA"
              maxLength={20}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name ? formik.errors.name : ''}
              testid="search_name"
              className={styles.saveSearchInput}
            />
            <div className={styles.actions}>
              <Button
                onClick={onClose}
                className={classNames(styles.action, styles.cancel)}
                title="Cancel"
                testid="cancel"
                titleClassName={styles.buttonTitle}
              />
              <Button
                type="submit"
                title="Save"
                isPending={isPending}
                className={classNames(styles.action, styles.save)}
                loaderClassName={styles.loader}
                testid="save"
                titleClassName={styles.buttonTitle}
              />
            </div>
          </form>
        </div>
      </SimpleModal>
    </div>
  );
};

SaveSearchButton.propTypes = {
  className: PropTypes.string,
  search: PropTypes.shape({}).isRequired,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
};

SaveSearchButton.defaultProps = {
  className: '',
  mode: CREATE,
  disabled: false,
};

export default SaveSearchButton;
