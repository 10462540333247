import { DeleteFilled, EditFilled, EyeFilled } from '@ant-design/icons';
import { AntCard } from 'components-antd';

import React from 'react';

import './AreaGroupPreview.scss';
import { CircleButton } from './CircleButton';
import { VisibleShapeData } from 'store/reducers/adminPanel/areaClassifier/selectedAreaGroup';
import { areaTypeReadableNames } from '../utils';

interface AreaGroupPreviewProps {
  areaGroup: {
    id: number;
    name: string;
    areas: Array<{
      type: string;
      shape?: VisibleShapeData;
    }>;
  };
  onEyeClick: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

export const AreaGroupPreview: React.FC<AreaGroupPreviewProps> = ({
  areaGroup,
  onEditClick,
  onEyeClick,
  onDeleteClick,
}) => (
  <AntCard title={areaGroup.name}>
    <div className="cardContentWrapper">
      {areaGroup.areas.map((area) => (
        <div>
          {area.shape?.color && <span style={{ color: area.shape?.color }}>⏺ </span>}
          <span>{areaTypeReadableNames[area.type]}</span>
        </div>
      ))}

      <div className="buttonsWrapper">
        <CircleButton icon={<EyeFilled className="eyeIcon" />} onClick={onEyeClick} />
        <CircleButton icon={<EditFilled className="editIcon" />} onClick={onEditClick} />
        <CircleButton icon={<DeleteFilled className="deleteIcon" />} onClick={onDeleteClick} />
      </div>
    </div>
  </AntCard>
);
