import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { FullArrowRight } from 'components/Icons';
import styles from './styles.module.scss';

const PriceReduction = ({ oldPrice = 0, newPrice = 0 }) => {
  if (!oldPrice && !newPrice) {
    return <></>;
  }
  return (
    <div className={styles.container}>
      <div className={styles.price}>
        <p>Price Reduction:</p>
        <NumberFormat
          displayType="text"
          thousandSeparator
          value={oldPrice}
          prefix="$"
          className={styles.oldPrice}
        />
        <FullArrowRight color="white" className={styles.arrowRightIcon} />
        <NumberFormat displayType="text" thousandSeparator value={newPrice} prefix="$" />
      </div>
    </div>
  );
};

PriceReduction.propTypes = {
  oldPrice: PropTypes.number.required,
  newPrice: PropTypes.number.required,
};

PriceReduction.defaultProps = {
  oldPrice: 0,
  newPrice: 0,
};

export default PriceReduction;
