import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Map } from 'components';
import { getListingDetailMapData } from 'store/selectors/listingDetail';
import styles from './styles.module.scss';

const MapModeView = ({ data = [], geoLat, geoLong, listingId, price }) => {
  const { center } = useSelector(getListingDetailMapData);

  const listingMarker = useMemo(
    () => ({
      id: listingId,
      priceToDisplay: price,
      geometry: {
        coordinates: { lng: geoLong, lat: geoLat },
      },
      distance: '0',
      highlight: true,
    }),
    [geoLat, geoLong, listingId],
  );

  const markers = useMemo(
    () =>
      [...data, listingMarker].reduce((markersMap, marker) => {
        const {
          geometry: {
            coordinates: { lng, lat },
          },
        } = marker;

        if (typeof lat !== 'number' || typeof lng !== 'number') {
          return markersMap;
        }

        const key = `${lng} ${lat}`;

        return markersMap[key]?.length
          ? { ...markersMap, [key]: [...markersMap[key], marker] }
          : { ...markersMap, [key]: [marker] };
      }, {}),
    [data, listingMarker],
  );

  const bounds = useMemo(() => {
    if (!data?.length) return [];

    // TODO: check again how it works once we start using the real data instead of mock
    data.sort(({ distance: distA }, { distance: distB }) => parseFloat(distA) - parseFloat(distB));

    let closest = data.length > 2 ? data.slice(0, 2) : data;

    if (
      typeof listingMarker?.geometry?.coordinates?.lat === 'number' &&
      typeof listingMarker?.geometry?.coordinates?.lng === 'number'
    ) {
      closest = [listingMarker, ...closest];
    }

    return closest.reduce((boundingMarkers, marker) => [...boundingMarkers, [marker]], []);
  }, [data, listingMarker]);

  return (
    <div className={styles.mapWrapper}>
      <Map
        popupClassName={styles.popupClassName}
        markersMap={markers}
        className={styles.map}
        bounds={bounds}
        center={center}
        zoom={14}
        shouldFitBound={false}
        neighborhoodExploration={true}
        showPlaces={true}
        groupLabel={'Places'}
      />
    </div>
  );
};

MapModeView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  geoLat: PropTypes.number.isRequired,
  geoLong: PropTypes.number.isRequired,
  listingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

MapModeView.defaultProps = {
  data: undefined,
};

export default MapModeView;
