/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFormik } from 'formik';

import { Input, Button } from 'components';

import { PENDING } from 'settings/constants/apiState';
import { getChangePasswordSelector } from 'store/selectors/loginGroup';
import { changePasswordEffect } from 'store/effects/loginGroup';

import { ValidationSchema } from './validation';

import styles from './styles.module.scss';

const Form = () => {
  const [isEnable, setIsEnable] = useState(false);
  const changePassword = useSelector(getChangePasswordSelector);
  const dispatch = useDispatch();

  const isPending = changePassword.state === PENDING;

  useEffect(() => {
    (formik.values.oldPassword && formik.values.newPassword && formik.values.confirmPassword) != ''
      ? setIsEnable(true)
      : setIsEnable(false);
  });

  const formik = useFormik({
    initialValues: { oldPassword: '', newPassword: '', confirmPassword: '' },
    validationSchema: ValidationSchema,
    validateOnChange: true,
    onSubmit(values) {
      dispatch(
        changePasswordEffect(values, {}, (err) => {
          if (!err) {
            formik.resetForm();
          }
        }),
      );
    },
  });

  return (
    <form testid="password_form" onSubmit={formik.handleSubmit}>
      <div className={styles.inputBlock}>
        <label htmlFor="oldPassword" className={styles.editLabel}>
          Old password
        </label>
        <div className={styles.rightBlock}>
          <Input
            type="password"
            name="oldPassword"
            className={styles.input}
            inputClassName={styles.inputField}
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            error={formik.touched.oldPassword ? formik.errors.oldPassword : ''}
            disabled={isPending}
            testid="old_password"
          />
        </div>
      </div>
      <div className={styles.inputBlock}>
        <label htmlFor="newPassword" className={styles.editLabel}>
          New password
        </label>
        <div className={styles.rightBlock}>
          <Input
            type="password"
            name="newPassword"
            className={styles.input}
            inputClassName={styles.inputField}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={formik.touched.newPassword ? formik.errors.newPassword : ''}
            disabled={isPending}
            testid="new_password"
          />
        </div>
      </div>
      <div className={styles.inputBlock}>
        <label htmlFor="confirmPassword" className={styles.editLabel}>
          Confirm password
        </label>
        <div className={styles.rightBlock}>
          <Input
            type="password"
            name="confirmPassword"
            className={styles.input}
            inputClassName={styles.inputField}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.touched.confirmPassword ? formik.errors.confirmPassword : ''}
            disabled={isPending}
            testid="confirm_password"
          />
        </div>
      </div>
      <Button
        testid="update_password"
        type="submit"
        className={classNames(styles.saveBtn, { [styles.buttonDisabled]: !isEnable })}
        disabled={!isEnable}
        title="Update password"
        isPending={isPending}
      />
    </form>
  );
};

export default Form;
