import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { chunk } from 'lodash-es';
import { useScreen } from 'hooks';
import { pushOrRemoveStageOptionObj } from 'pages/OnBoardingWizard/helpers';
import { NEIGHBORHOOD_OPTIONS, prefsIds } from 'settings/constants/preferences';
import Icon from '../Icons';
import { appOpenModalEffect } from 'store/effects/app';

import styles from './styles.module.scss';
import { SchoolsModal } from './SchoolsModal';
import { useDispatch } from 'react-redux';
import Option from './Option';
import { Row, Col } from 'antd';

const Options = (props) => {
  const { className, onChange, value, disabled, testid } = props;
  const [actives, setActives] = useState(value);
  const { screen } = useScreen();
  const dispatch = useDispatch();

  useEffect(() => setActives(value), [value]);

  const openModal = useCallback(
    (id) => {
      dispatch(appOpenModalEffect({ id, open: true }));
    },
    [dispatch],
  );

  const onClick = useCallback(
    (type) => {
      const newActives = pushOrRemoveStageOptionObj({ obj: actives, key: type });

      setActives(newActives);
      onChange(newActives);

      if (Object.keys(newActives).includes(prefsIds.goodSchools) && type === prefsIds.goodSchools) {
        openModal(SchoolsModal.id);
      }
    },
    [onChange, actives],
  );

  const onEdit = (e, optionId) => {
    e.stopPropagation();
    switch (optionId) {
      case prefsIds.goodSchools:
        return openModal(SchoolsModal.id);
      default:
        return null;
    }
  };

  const getOptions = () => {
    if (screen.mobileSmallWidth) {
      return chunk(NEIGHBORHOOD_OPTIONS, 1);
    }

    if (screen.mobileWidth) {
      return chunk(NEIGHBORHOOD_OPTIONS, 2);
    }

    return chunk(NEIGHBORHOOD_OPTIONS, 3);
  };

  const renderOptions = () =>
    getOptions().map((option, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Col xs={12} md={24} key={index} className={styles.optionHolder}>
        <Option
          key={index} // eslint-disable-line
          option={option}
          actives={actives}
          onClick={onClick}
          onEdit={onEdit}
          testid={testid}
        />
      </Col>
    ));

  return (
    <div className={classNames(styles.wrapper, { [styles.disabled]: disabled }, className)}>
      <Row gutter={[20, 20]} className={styles.holder}>
        {renderOptions()}
      </Row>
    </div>
  );
};

Options.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.shape({}),
  disabled: PropTypes.bool,
  testid: PropTypes.string,
};

Options.defaultProps = {
  className: '',
  onChange: () => {},
  value: [],
  disabled: false,
  testid: undefined,
};

export default Options;
