import moment from 'moment';

export const isBeforeToday = (date) => moment(date).isBefore(new Date(), 'day');
export const isToday = (date) => moment(date).isSame(new Date(), 'day');
export const isYesterday = (date) => moment(date).isSame(moment().subtract(1, 'day'), 'day');
export const isTomorrow = (date) => moment(date).isSame(moment().add(1, 'day'), 'day');
export const isAfterToday = (date) => moment(date).isAfter(new Date(), 'day');
export const isThisWeek = (date) => {
  const startOfWeek = moment().startOf('week');
  const endOfWeek = moment().endOf('week');

  return moment(date).isBetween(startOfWeek, endOfWeek, null, '[]');
};
export const isAfterThisWeek = (date) => {
  const endOfWeek = moment().endOf('week');
  return moment(date).isAfter(endOfWeek, 'day');
};
export const formatDate = (date, format) => moment(date).format(format);
export const currentYear = new Date().getFullYear();

export const getTimeAgo = (datetime) => {
  // Calculate the difference in mins/hours/days from the current time
  var hoursDiff = moment().diff(datetime, 'hours');

  if (hoursDiff <= 0) {
    return moment().diff(datetime, 'minutes') + 'm ago';
  } else if (hoursDiff > 24) {
    return moment().diff(datetime, 'days') + 'd ago';
  } else {
    return hoursDiff + 'h ago';
  }
};

export const timeDifferenceFromNow = (receivedDate) => {
  const now = new Date();
  const date = new Date(receivedDate);

  const diffTime = Math.abs(now - date);

  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30.44;

  if (diffTime >= month) {
    const months = Math.floor(diffTime / month);
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  } else if (diffTime >= week) {
    const weeks = Math.floor(diffTime / week);
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
  } else if (diffTime >= day) {
    const days = Math.floor(diffTime / day);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  } else if (diffTime >= hour) {
    const hours = Math.floor(diffTime / hour);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else if (diffTime >= minute) {
    const minutes = Math.floor(diffTime / minute);
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else {
    const seconds = Math.floor(diffTime / second);
    return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  }
};
