import { apiServer } from 'settings/web-services/api';

export function getClientDashboardPropertyStatsApi(cfg) {
  const { clientId, contextId } = cfg;
  return apiServer.get(
    `/dashboard/client-properties-stats?clientId=${clientId}&contextId=${contextId}`,
  );
}

export function getClientDashboardNewMatchesApi(cfg) {
  const { clientId, contextId } = cfg;
  return apiServer.get(`/dashboard/client-new-matches?clientId=${clientId}&contextId=${contextId}`);
}

export function getClientDashboardAgentSuggestionsApi(cfg) {
  const { clientId, contextId } = cfg;
  return apiServer.get(
    `/dashboard/client-agent-suggestions?clientId=${clientId}&contextId=${contextId}`,
  );
}

export function getClientDashboardAgentCommentsApi(cfg) {
  const { clientId, contextId } = cfg;
  return apiServer.get(
    `/dashboard/client-agent-comments?clientId=${clientId}&contextId=${contextId}`,
  );
}

export function getClientDashboardOpenHousesApi(cfg) {
  const { clientId, contextId } = cfg;
  return apiServer.get(`/dashboard/client-open-houses?clientId=${clientId}&contextId=${contextId}`);
}

export function getClientDashboardPriceReductionsApi(cfg) {
  const { clientId, contextId } = cfg;
  return apiServer.get(
    `/dashboard/client-price-reductions?clientId=${clientId}&contextId=${contextId}`,
  );
}

export function getClientDashboardBackOnMarketApi(cfg) {
  const { clientId, contextId } = cfg;
  return apiServer.get(
    `/dashboard/client-back-markets?clientId=${clientId}&contextId=${contextId}`,
  );
}

export function getClientDashboardMyTransactionsApi(cfg) {
  const { clientId, contextId } = cfg;
  return apiServer.get(
    `/dashboard/client-transactions?clientId=${clientId}&contextId=${contextId}`,
  );
}

export function getClientDashboardUpcomingShowingsApi(cfg) {
  const { clientId, contextId } = cfg;
  return apiServer.get(
    `/dashboard/client-upcoming-showings?clientId=${clientId}&contextId=${contextId}`,
  );
}

export function getClientDashboardNeedsAttentionApi() {
  return apiServer.get(`/dashboard/needsAttention/client`);
}
