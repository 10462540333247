import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Icons = (props) => {
  const { className, variant, onClick, testid } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.PLUS: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 3V13"
              stroke="#163C5A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 8L13 8"
              stroke="#163C5A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.CLOSE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 16L16 8" stroke="#163C5A" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M16 16L8 8" stroke="#163C5A" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.DOCUMENT_LIGHT: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F5F6" />
            <path
              d="M17.7998 7.89844V11.4984C17.7998 11.7371 17.8946 11.9661 18.0634 12.1348C18.2322 12.3036 18.4611 12.3984 18.6998 12.3984H22.2998"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.5002 24.0984H11.5002C11.0228 24.0984 10.565 23.9088 10.2274 23.5712C9.88984 23.2337 9.7002 22.7758 9.7002 22.2984V9.69844C9.7002 9.22105 9.88984 8.76321 10.2274 8.42565C10.565 8.08808 11.0228 7.89844 11.5002 7.89844H17.8002L22.3002 12.3984V22.2984C22.3002 22.7758 22.1106 23.2337 21.773 23.5712C21.4354 23.9088 20.9776 24.0984 20.5002 24.0984Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M13.5 15.5H18.5" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M13.5 19.5H16.5" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.ENTER: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 4V9H2M2 9L5.5 5.5M2 9L5.5 12.5"
              stroke="#666666"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.FILE: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.666 4V9.33333C18.666 9.68695 18.8065 10.0261 19.0565 10.2761C19.3066 10.5262 19.6457 10.6667 19.9993 10.6667H25.3327"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.666 28H9.33268C8.62544 28 7.94716 27.719 7.44706 27.219C6.94697 26.7189 6.66602 26.0406 6.66602 25.3333V6.66667C6.66602 5.95942 6.94697 5.28115 7.44706 4.78105C7.94716 4.28095 8.62544 4 9.33268 4H18.666L25.3327 10.6667V25.3333C25.3327 26.0406 25.0517 26.7189 24.5516 27.219C24.0515 27.719 23.3733 28 22.666 28Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 12H13.3333"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 17.333H20"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 22.667H20"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.GOOGLE_DRIVE: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M11.8086 6H20.1523L27.9992 19.5281H19.6367L11.8086 6Z"
                fill="url(#paint0_linear)"
              />
              <path
                d="M27.9996 19.5283L23.8184 26.7564H8.16211L12.3527 19.5283H27.9996Z"
                fill="url(#paint1_linear)"
              />
              <path
                d="M8.1625 26.7563L4 19.5281L11.8094 6L16 13.2469L8.1625 26.7563Z"
                fill="url(#paint2_linear)"
              />
              <path
                opacity="0.1"
                d="M8.16211 26.7564L15.9527 19.5283H12.3527L8.16211 26.7564Z"
                fill="black"
              />
              <path
                opacity="0.1"
                d="M27.9992 19.5281H19.6461L17.8086 16.3594L27.9992 19.5281Z"
                fill="black"
              />
              <path
                opacity="0.1"
                d="M11.8086 6L14.218 16.3125L15.9992 13.2469L11.8086 6Z"
                fill="black"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="11.8086"
                y1="6"
                x2="25.1213"
                y2="21.9328"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F6C338" />
                <stop offset="0.52278" stopColor="#FFD351" />
                <stop offset="1" stopColor="#F6C338" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="27.9996"
                y1="19.5283"
                x2="23.3496"
                y2="32.2903"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#286EE6" />
                <stop offset="0.52105" stopColor="#4286FB" />
                <stop offset="1" stopColor="#286EE6" />
              </linearGradient>
              <linearGradient
                id="paint2_linear"
                x1="11.8347"
                y1="6"
                x2="2.748"
                y2="23.9333"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#069B5A" />
                <stop offset="0.53103" stopColor="#11AA62" />
                <stop offset="1" stopColor="#069B5A" />
              </linearGradient>
              <clipPath id="clip0">
                <rect width="24" height="20.8125" fill="white" transform="translate(4 6)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.DOCUSIGN: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.516 8.30859H18.4847V14.9296H21.793L16 21.5506L10.207 14.9296H13.5153L13.516 8.30859ZM4 24.0353H28V25.6876H4V24.0353Z"
              fill="#303030"
            />
          </svg>
        );
      }
      case Icons.DROPBOX: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path d="M9.99953 6L4 9.82228L9.99953 13.6447L16 9.82219L9.99953 6Z" fill="#0061FF" />
              <path d="M22 6L16 9.82266L22 13.6453L28 9.82266L22 6Z" fill="#0061FF" />
              <path
                d="M4 17.4678L9.99953 21.2902L16 17.4677L9.99953 13.6455L4 17.4678Z"
                fill="#0061FF"
              />
              <path d="M22 13.6455L16 17.4682L22 21.2907L28 17.4682L22 13.6455Z" fill="#0061FF" />
              <path
                d="M10 22.5723L16.0005 26.3947L22 22.5722L16.0005 18.75L10 22.5723Z"
                fill="#0061FF"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="24" height="20.4375" fill="white" transform="translate(4 6)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.FILE_SIMPLE: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.667 4V9.33333C18.667 9.68695 18.8075 10.0261 19.0575 10.2761C19.3076 10.5262 19.6467 10.6667 20.0003 10.6667H25.3337"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.667 28H9.33366C8.62641 28 7.94814 27.719 7.44804 27.219C6.94794 26.7189 6.66699 26.0406 6.66699 25.3333V6.66667C6.66699 5.95942 6.94794 5.28115 7.44804 4.78105C7.94814 4.28095 8.62641 4 9.33366 4H18.667L25.3337 10.6667V25.3333C25.3337 26.0406 25.0527 26.7189 24.5526 27.219C24.0525 27.719 23.3742 28 22.667 28Z"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 12H13.3333"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 17.333H20"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 22.667H20"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DELETE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 7H20"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 11V17"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 11V17"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.TIMELINE: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 6C6 4.89543 6.89543 4 8 4H24C25.1046 4 26 4.89543 26 6V26C26 27.1046 25.1046 28 24 28H8C6.89543 28 6 27.1046 6 26V6Z"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M16 10H21" stroke="#525252" strokeWidth="2" strokeLinecap="round" />
            <path d="M16 14H21" stroke="#525252" strokeWidth="2" strokeLinecap="round" />
            <path d="M16 18H21" stroke="#525252" strokeWidth="2" strokeLinecap="round" />
            <path d="M16 22H21" stroke="#525252" strokeWidth="2" strokeLinecap="round" />
            <circle cx="11.5" cy="14" r="1.5" fill="#525252" />
            <circle cx="11.5" cy="10" r="1.5" fill="#525252" />
            <circle cx="11.5" cy="18" r="1.5" fill="#525252" />
            <circle cx="11.5" cy="22" r="1.5" fill="#525252" />
          </svg>
        );
      }
      case Icons.INFO: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_11288_166159)">
              <path
                d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                stroke="#747475"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 8C8 9.0414 8 9.4586 8 10.5"
                stroke="#747475"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="8" cy="5" r="1" fill="#747475" />
            </g>
            <defs>
              <clipPath id="clip0_11288_166159">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.EDIT: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 19H8.65334L18.2434 9.40997C18.7278 8.92551 19 8.26844 19 7.5833C19 6.89817 18.7278 6.2411 18.2434 5.75663C17.7589 5.27217 17.1018 5 16.4167 5C15.7316 5 15.0745 5.27217 14.59 5.75663L5 15.3467V19Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.6758 6.67041L17.3291 10.3238"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.TASK_TEMPLATE: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F5F6" />
            <g clipPath="url(#clip0_11068_163343)">
              <path
                d="M8.91602 10.5833L10.166 11.8333L12.2493 9.75"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.91602 15.5833L10.166 16.8333L12.2493 14.75"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.91602 20.5833L10.166 21.8333L12.2493 19.75"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.166 11H22.666"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.166 16H22.666"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.166 21H22.666"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_11068_163343">
                <rect width="20" height="20" fill="white" transform="translate(6 6)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.ADD: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8Z"
              fill="#FF576D"
            />
          </svg>
        );
      }
      case Icons.ADDPLAIN: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path d="M4 8H12" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M8 12L8 4" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.MINUS: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM6 9C5.44772 9 5 9.4477 5 10C5 10.5523 5.44772 11 6 11H14C14.5523 11 15 10.5523 15 10C15 9.4477 14.5523 9 14 9H6Z"
              fill="#FF576D"
            />
          </svg>
        );
      }
      case Icons.CHECK_MARK: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_18185_174520)">
              <path
                d="M3.33203 8.00032L6.66536 11.3337L13.332 4.66699"
                stroke="#747475"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_18185_174520">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icon, className)}>
      {getIcons()}
    </div>
  );
};

Icons.PLUS = 'plus';
Icons.CLOSE = 'close';
Icons.ENTER = 'enter';
Icons.FILE = 'file';
Icons.FILE_SIMPLE = 'fileSimple';
Icons.DOCUSIGN = 'docusign';
Icons.GOOGLE_DRIVE = 'googleDrive';
Icons.DROPBOX = 'dropbox';
Icons.DELETE = 'delete';
Icons.TIMELINE = 'timeline';
Icons.INFO = 'info';
Icons.EDIT = 'edit';
Icons.TASK_TEMPLATE = 'taskTemplate';
Icons.ADD = 'add';
Icons.ADDPLAIN = 'addPlain';
Icons.MINUS = 'minus';
Icons.CHECK_MARK = 'checkMark';
Icons.DOCUMENT_LIGHT = 'documentLight';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  testid: PropTypes.string,
  onClick: PropTypes.func,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};

export default Icons;
