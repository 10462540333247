import moment from 'moment';
import styles from './style.module.scss';
import { Icons } from '../components';
import { Table } from 'antd';
import { Checkbox } from 'components-antd';
import React from 'react';

const { SELECTION_COLUMN } = Table;

export interface FormLibraryContent {
  formId: number;
  versionId: number;
  name: string;
  lastUpdatedDate: string;
  categories: string[];
  isCAR?: boolean;
  link?: string;
  DocumentLink: string;
}

interface ColumnProps {
  onPreview: (record) => void;
  showSelection: boolean;
  rowKeys: React.Key[];
  handleRowKeys: (Id, checked) => void;
}

export const recentUploadsColumns = ({
  onPreview,
  showSelection,
  rowKeys,
  handleRowKeys,
}: ColumnProps) => [
  {
    key: 'Name',
    title: 'Upload',
    dataIndex: 'Name',
    width: '55%',
    render: (name: string, record) => (
      <div className={styles.formLibraryName}>
        {showSelection && (
          <div className={styles.multiSelect}>
            <Checkbox
              className={styles.checkbox}
              checked={rowKeys.includes(record.Id)}
              onChange={(e: any) => {
                e.stopPropagation();
                if (handleRowKeys) {
                  handleRowKeys(record.Id, e.target.checked);
                }
              }}
            />
          </div>
        )}
        <span className={styles.previewAction}>
          <Icons
            variant={record.FormBundleRelation ? 'bundleDocument' : 'fileDocument'}
            className={styles.previewIcon}
          />
        </span>

        <span className={styles.fileName}>{name}</span>
      </div>
    ),
  },
  {
    key: 'UploadedBy',
    title: 'Uploaded By',
    dataIndex: 'UploadedBy',
    width: '20%',
    render: (uploadedBy) => (
      <div className={styles.uploadedBy}>
        {uploadedBy ? `${uploadedBy.FirstName} ${uploadedBy.LastName}` : '-'}
      </div>
    ),
  },
  {
    key: 'CreatedDate',
    title: 'Uploaded',
    dataIndex: 'CreatedDate',
    width: '15%',
    render: (createdDate: string) => (
      <div className={styles.formLibraryInfo}>{moment(createdDate).utc().format('MM/DD/YYYY')}</div>
    ),
  },
  {
    key: '',
    title: '',
    dataIndex: '',
    width: '4%',
    render: (_, record) => {
      return (
        <div className={styles.actionIcons}>
          <Icons
            variant={'filePreview'}
            onClick={(e) => {
              e.stopPropagation();
              onPreview(record);
            }}
          />
        </div>
      );
    },
  },
  ...(showSelection ? [SELECTION_COLUMN] : []),
];
