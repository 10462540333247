import classnames from 'classnames';
import { priceConverter } from 'helpers';
import { sampleData } from '../../../../../helper';
import { DateRangeFilter } from 'pages/ClarityRevamped/Components/Filters/DateRangeFilter';
import { TableViewFilter } from 'pages/ClarityRevamped/Components/Filters/TableViewFilter';
import { StatusFilter } from '../../Components/StatusFilter';
import { Wrapper as PendingWrapper } from 'components';
import { useState } from 'react';
import { AreaGraph } from '../../../../Graphs/AreaGraph';
import { ModalHeader } from '../../Components/ModalHeader/ModalHeader';
import { AggregateResultHeader } from '../../Components/ModalHeader';
import { BreakdownMenu } from '../../../../BreakdownMenu';
import { LISTED_STATUS, transactionStatuses } from 'settings/constants/transactionStatus';
import { DoubleLeftArrow } from 'pages/ClarityRevamped/Components/Icons/DoubleLeftArrow';
import TableView from '../../Components/TableView/TableView';
import CompareModal from '../../Components/CompareModals/CompareModal';

import styles from '../../styles.module.scss';

export const ActiveClients = () => {
  const [isGraphView, setIsGraphView] = useState<boolean>(true);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openCompareModal, setOpenCompareModal] = useState<boolean>(false);
  const data = sampleData;
  const latestStat = data[data.length - 1];
  const totalAmount = priceConverter(latestStat?.sum || 0, 2);
  const totalRequests = latestStat?.count || 0;
  const color = '#4673D1';

  const dropdownList = [
    LISTED_STATUS.PreListing,
    LISTED_STATUS.ComingSoon,
    LISTED_STATUS.ActiveListing,
    LISTED_STATUS.OnHold,
    transactionStatuses.ClearToClose,
  ];
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.rightContent}>
        <div className={styles.headerRows}>
          <ModalHeader title={'Active Clients'} />
          <div className={styles.infoRow}>
            <AggregateResultHeader
              totalAmount={totalAmount}
              totalRequests={totalRequests}
              textColor={{ color: color }}
            />
            <div className={classnames(styles.filtersContainer, { [styles.openMenu]: openMenu })}>
              <DateRangeFilter cb={() => {}} />
              <StatusFilter options={['All', ...dropdownList]} cb={() => {}} />
              <TableViewFilter
                setIsGraphView={setIsGraphView}
                openCompareModal={() => setOpenCompareModal(true)}
              />
            </div>
          </div>
        </div>

        <PendingWrapper
          isPending={false}
          className={classnames(
            styles.graphContainer,
            { [styles.openMenu]: openMenu },
            { [styles.tableContainer]: !isGraphView },
          )}
        >
          {isGraphView ? (
            <AreaGraph stats={sampleData} color={color} />
          ) : (
            <TableView open={openMenu} />
          )}
        </PendingWrapper>
      </div>
      <div
        className={classnames(styles.actionBtn, { [styles.openMenuBtn]: openMenu })}
        onClick={() => setOpenMenu(!openMenu)}
      >
        <DoubleLeftArrow />
      </div>
      <div className={classnames(styles.leftContent, { [styles.openMenu]: openMenu })}>
        <BreakdownMenu open={openMenu} />
      </div>
      <CompareModal open={openCompareModal} onClose={() => setOpenCompareModal(false)} />
    </div>
  );
};
