import { CircularClimateProgressbar } from 'components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const CircularProgressBarWithText = ({
  blockType,
  percentage,
  percentageText,
  title,
  description,
  showBorder,
  size,
}) => (
  <div
    testid={`${blockType}_block`}
    className={classNames(styles.climateCheckBlock, showBorder && styles.blockBorder)}
  >
    <div className={styles.title}>{title}</div>
    <div testid={`${blockType}_description`}> {description} </div>
    <CircularClimateProgressbar
      textClassName={styles.percent}
      percentage={percentage}
      percentageText={percentageText}
      size={size}
      showText
      lineWidth={15}
    />
  </div>
);

CircularProgressBarWithText.propTypes = {
  blockType: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  percentageText: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  showBorder: PropTypes.bool,
  size: PropTypes.string,
};

CircularProgressBarWithText.defaultProps = {
  blockType: '',
  percentage: 0,
  percentageText: '',
  title: '',
  description: '',
  showBorder: false,
  size: '80',
};

export default CircularProgressBarWithText;
