import { createSelector } from 'reselect';
import {
  getThreadsListSelector,
  getTransactionThreadsListSelector,
} from 'store/selectors/sockets/threads';
import {
  getCurrentTransactionTaskMessagesInfoSelector,
  getCurrentTransactionTaskMessagesThreadIdSelector,
  getCurrentTransactionTaskThreadSelector,
} from 'store/selectors/sockets/transactionTaskMessages';
import { ERROR, IDLE, PENDING } from 'settings/constants/apiState';
import { orderBy } from 'lodash-es';
import { getUserId } from '../user';
import { getTransactionSelector } from '../transaction';

const localState = ({ transactionMessages }) => transactionMessages;

export const getTransactionTaskMessagesStateSelector = createSelector(
  localState,
  (transactionMessages) => ({
    isIdle: transactionMessages?.state === IDLE,
    isPending: transactionMessages?.state === PENDING,
    isError: transactionMessages?.state === ERROR,
    isData: !!transactionMessages?.data,
    statePerTransaction: transactionMessages?.statePerTransaction,
  }),
);

export const getTransactionTaskMessagesLastThreadIdSelector = createSelector(
  getTransactionTaskMessagesStateSelector,
  getTransactionSelector,
  ({ statePerTransaction }, { transaction: currentTransaction }) =>
    statePerTransaction?.[currentTransaction?.Id]?.lastThreadId,
);

export const getTransactionTaskMessagesSelector = createSelector(
  getCurrentTransactionTaskMessagesThreadIdSelector,
  getCurrentTransactionTaskThreadSelector,
  getCurrentTransactionTaskMessagesInfoSelector,
  getThreadsListSelector,
  getUserId,
  (currentThreadId, currentThread, messages, threadsList, userId) => {
    const handledCurrentThread = {
      ...(currentThread || {}),
      Participants: orderBy(
        (currentThread?.Participants || []).filter((participant) => participant?.Id !== userId),
        ['FirstName'],
        ['asc'],
      ),
    };

    return {
      currentThreadId,
      currentThread: handledCurrentThread,
      messages,
      threadsList,
      userId,
    };
  },
);

export const getUnreadCountTransactionTaskMessagesSelector = createSelector(
  getTransactionThreadsListSelector,
  (transactionThreadsList) => {
    const count = transactionThreadsList.reduce((acc, cur) => {
      if (cur?.IsUnread) {
        return acc + 1;
      }
      return acc;
    }, 0);

    return {
      unreadMessagesCount: count,
    };
  },
);

export const getTransactionTaskMessagesParamsSelector = createSelector(
  localState,
  ({ isNewMessage, isAddParticipants }) => ({ isNewMessage, isAddParticipants }),
);
