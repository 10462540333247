import { createAction } from 'redux-actions';

export const sortTimelineTemplatesAction = createAction('DASHBOARD/SORT_TIMELINE_TEMPLATES');
export const searchTimelineTemplatesAction = createAction('DASHBOARD/SEARCH_TIMELINE_TEMPLATES');
export const requestGetTimelineTemplatesDashboardAction = createAction(
  'REQUEST/GET_TIMELINE_TEMPLATES_DASHBOARD',
);
export const requestGetTimelineTemplateDashboardAction = createAction(
  'REQUEST/GET_TIMELINE_TEMPLATE_DASHBOARD',
);
export const requestPutTimelineTemplateDashboardAction = createAction(
  'REQUEST/PUT_TIMELINE_TEMPLATE_DASHBOARD',
);
export const requestPostTimelineTemplateDashboardAction = createAction(
  'REQUEST/POST_TIMELINE_TEMPLATE_DASHBOARD',
);
export const requestDeleteTimelineTemplateDashboardAction = createAction(
  'REQUEST/DELETE_TIMELINE_TEMPLATE_DASHBOARD',
);
