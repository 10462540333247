import { cloneDeep, get } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  setTransactionActivitiesFilterAction,
  setTransactionActivitiesSortAction,
  getTransactionActivitiesSearchAction,
  resetTransactionActivitiesFiltersAction,
  resetTransactionActivitiesAction,
} from 'store/actions/transactions';
import { SORT_DESC } from 'settings/constants/sort';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  count: 0,
  countPerPage: 20,
  filter: {
    tags: null,
    parties: null,
  },
  sort: {
    fields: ['DateTime'],
    order: SORT_DESC,
  },
};

export default handleActions(
  {
    [getTransactionActivitiesSearchAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result.Activities', initialData.data),
      count: get(payload, 'data.result.Count', initialData.count),
    }),
    [setTransactionActivitiesFilterAction]: (state, { payload }) => ({
      ...state,
      filter: {
        ...state.filter,
        ...payload,
      },
    }),
    [setTransactionActivitiesSortAction]: (state, { payload }) => ({
      ...state,
      sort: {
        ...state.sort,
        ...payload,
      },
    }),
    [resetTransactionActivitiesFiltersAction]: (state) => ({
      ...state,
      filter: cloneDeep(initialData.filter),
      sort: cloneDeep(initialData.sort),
    }),
    [resetTransactionActivitiesAction]: () => cloneDeep(initialData),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
