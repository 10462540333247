import { useCallback } from 'react';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import { find, get } from 'lodash-es';
import classNames from 'classnames';

import { Button, Checkbox } from 'components';
import { Close, SearchIcon, ArrowDown } from 'components/Icons';

import styles from './syles.module.scss';

const SelectComponent = (props) => {
  const {
    id,
    testid,
    defaultValue,
    onSelect,
    options,
    label,
    labelClassName,
    className,
    closeOnSelect,
    allowClear,
    name,
    search,
    multiple,
    placeholder,
    value,
    disabled,
    error,
    variant,
    renderValueString,
    checkboxPostion,
    printOptions,
    altLabel,
    altLabelClassName,
    emptyMessage,
    autoComplete,
    renderOption,
    isSearchIcon,
    isArrowIcon,
    searchIconClassName,
    arrowIconClassName,
    onSearchInputChange,
    selectWrapperClassname,
    onKeyPress,
    customIcon,
    autoFocus,
    showAsterisk,
    inputWrapperRef,
    renderGroupHeader,
  } = props;

  const onChange = useCallback(
    (val) => {
      const valueObj = find(options, { value: val });
      const fakeEvent = { target: { value: valueObj, name } };

      if (Array.isArray(val)) {
        const values = val.map((v) => find(options, { value: v }));
        fakeEvent.target.value = values;
        return onSelect(fakeEvent, values);
      }

      onSelect(fakeEvent, valueObj);
    },
    [name, onSelect, options],
  );

  const handleClear = useCallback((e) => onSelect(e, { value: null }), [onSelect]);

  const renderValue = (valueProps) => (
    <div ref={inputWrapperRef}>
      <input
        {...valueProps}
        value={renderValueString ? renderValueString?.(valueProps.value) : valueProps.value}
        onChange={(event) => {
          valueProps.onChange(event);
          onSearchInputChange(event, event.target.value);
        }}
        onKeyPress={(event) => {
          valueProps.onKeyPress(event);
          onKeyPress(event);
        }}
        className={classNames(styles.input, className.input)}
        name={name}
        autoComplete="nope"
        autoFocus={autoFocus} // eslint-disable-line
        testid={testid}
      />
      {!!allowClear && (
        <Button className={styles.clearButton} title={<Close />} onClick={handleClear} />
      )}
    </div>
  );

  const renderOptionHandler = (optionProps, optionData, optionSnapshot) => {
    const optProps = { ...optionProps, value: optionData.value, disabled: optionData.disabled };

    if (typeof renderOption === 'function')
      return renderOption({ optionProps, optionData, optionSnapshot }, optProps);

    return (
      <button
        type="button"
        className={classNames(
          styles.option,
          styles[variant],
          { [styles['is-highlighted']]: optionSnapshot.highlighted },
          { [styles['is-selected']]: !multiple && optionSnapshot.selected },
        )}
        {...optProps}
      >
        {checkboxPostion === 'end' && (
          <span className={optionSnapshot.selected ? styles.selectedOptionHeading : ''}>
            {optionData.name}
          </span>
        )}
        {multiple && (
          <Checkbox
            labelClassName={classNames(
              styles.checkbox,
              {
                [styles.checkboxEnd]: checkboxPostion === 'end',
              },
              {
                [styles.checkboxStart]: checkboxPostion === 'start',
              },
            )}
            checked={optionSnapshot.selected}
            checkboxColor="#FFF"
          />
        )}
        {checkboxPostion === 'start' && (
          <span className={optionSnapshot.selected ? styles.selectedOptionHeading : ''}>
            {optionData.name}
          </span>
        )}
      </button>
    );
  };

  const getValue = () => {
    if (Array.isArray(value)) {
      const defVal = typeof value === 'object' ? '' : value;
      return value.map((val) => get(val, 'value', defVal));
    }

    const defVal = typeof value === 'object' ? '' : value;
    return get(value, 'value', defVal) || get(defaultValue, 'value', defaultValue);
  };

  return (
    <div
      testid="wrapper"
      className={classNames(
        selectWrapperClassname,
        styles.selectWrapper,
        styles[variant],
        className.wrapper,
      )}
    >
      {label && (
        <label htmlFor={id} className={labelClassName}>
          <span>
            {label}
            {showAsterisk ? <span className={styles.required}> *</span> : ''}
          </span>
          {altLabel && (
            <span className={classNames(styles.altLabel, altLabelClassName)}>{altLabel}</span>
          )}
        </label>
      )}
      <div className={classNames(styles.selectHolder, { [styles.isSearchIcon]: isSearchIcon })}>
        {isSearchIcon ? (
          <SearchIcon className={classNames(searchIconClassName)} />
        ) : (
          <div className={styles.customIcon}>{customIcon}</div>
        )}
        <SelectSearch
          id={id}
          printOptions={printOptions}
          closeOnSelect={closeOnSelect}
          disabled={disabled}
          search={search}
          options={options}
          renderValue={renderValue}
          renderOption={renderOptionHandler}
          multiple={multiple}
          onChange={onChange}
          placeholder={placeholder}
          className={(key) => classNames(styles[key], styles[variant], className[key])}
          value={getValue()}
          emptyMessage={emptyMessage}
          autoComplete={autoComplete}
          renderGroupHeader={renderGroupHeader}
        />
        {isArrowIcon ? (
          <ArrowDown className={classNames(styles.arrowIcon, arrowIconClassName)} />
        ) : null}
      </div>
      {error && (
        <div testid="validation" className={styles.error}>
          {error}
        </div>
      )}
    </div>
  );
};

SelectComponent.LIGHT = 'light';
SelectComponent.LIGHT_FULL = 'lightFull';
SelectComponent.FULL = 'full';
SelectComponent.DARK_HALF = 'darkHalf';
SelectComponent.LIGHT_ROUND = 'lightRound';

SelectComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  className: PropTypes.shape({
    wrapper: PropTypes.string,
    input: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.string,
  }),
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape({ name: PropTypes.string, value: PropTypes.string }),
    PropTypes.string,
  ]),
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  name: PropTypes.string,
  search: PropTypes.bool,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  renderOption: PropTypes.func,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  error: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  printOptions: PropTypes.string,
  variant: PropTypes.string,
  altLabel: PropTypes.string,
  altLabelClassName: PropTypes.string,
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  testid: PropTypes.string,
  autoComplete: PropTypes.string,
  isSearchIcon: PropTypes.bool,
  isArrowIcon: PropTypes.bool,
  searchIconClassName: PropTypes.string,
  arrowIconClassName: PropTypes.string,
  onSearchInputChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  customIcon: PropTypes.node,
  renderValueString: PropTypes.func,
  autoFocus: PropTypes.bool,
  inputWrapperRef: PropTypes.shape({}),
  renderGroupHeader: PropTypes.func,
  checkboxPostion: PropTypes.string,
  showAsterisk: PropTypes.bool,
  selectWrapperClassname: PropTypes.string,
};

SelectComponent.defaultProps = {
  id: undefined,
  className: {
    wrapper: '',
  },
  defaultValue: undefined,
  onSelect: () => {},
  name: null,
  search: false,
  allowClear: false,
  multiple: false,
  placeholder: 'Select',
  value: null,
  label: undefined,
  labelClassName: '',
  disabled: undefined,
  error: undefined,
  closeOnSelect: true,
  printOptions: 'on-focus',
  // printOptions: 'always',
  variant: SelectComponent.FULL,
  altLabel: undefined,
  altLabelClassName: '',
  emptyMessage: () => <div className={styles.emptyMessage}>No Results</div>,
  testid: undefined,
  autoComplete: 'on',
  renderOption: undefined,
  isSearchIcon: false,
  isArrowIcon: true,
  searchIconClassName: '',
  arrowIconClassName: '',
  onSearchInputChange: () => {},
  onKeyPress: () => {},
  customIcon: undefined,
  renderValueString: undefined,
  autoFocus: false,
  inputWrapperRef: null,
  renderGroupHeader: undefined,
  checkboxPostion: 'end',
  showAsterisk: false,
};

export default SelectComponent;
