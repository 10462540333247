import { useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getTransactionsSelector } from 'store/selectors/transactions';

import { SearchFieldWithDebounce } from 'components';

import styles from './styles.module.scss';
// import { TransactionAndProjectFilter } from '../Transactions/components/Actions/TransactionAndProjectFilter';
import { Actions } from '../components';
import { resetQuotesSearchEffect, searchQuotesEffect } from 'store/effects/quotes';

export const LocalHeader = () => {
  const { pathname } = useLocation();
  const { isPending } = useSelector(getTransactionsSelector);

  return (
    <div className={classNames(styles.workshopHeader)}>
      <Row className={styles.workshopActionsContainer} align="middle" justify="space-between">
        <Col className={styles.workshopHeaderLeft} xs={12} lg={12}>
          <div className={styles.searchWrapper}>
            <SearchFieldWithDebounce
              className={styles.inputWrap}
              classNameInput={styles.searchInput}
              iconClassName={styles.icon}
              resetAction={resetQuotesSearchEffect}
              sendAction={searchQuotesEffect}
              isLoading={isPending}
              placeholder="Search"
              testid="search"
              isTransactionAggregate={true}
              isExpandedVersion
            />
          </div>
          <div className="seperator" />

          <div>
            <Actions className={styles.quotesActions} isPending={isPending} />
          </div>
        </Col>
        <Col className={styles.workshopHeaderRight} xs={12} lg={12}>
          <div>
            <Actions
              className={styles.quotesActionsArchieve}
              isPending={isPending}
              isArchieveVisible={true}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
