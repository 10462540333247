import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { prefsIds } from 'settings/constants/preferences';
import { Edit } from 'components/Icons';

import Icon from '../../Icons';
import { Row, Col } from 'antd';
import styles from './styles.module.scss';

const Option = ({ actives, onClick, onEdit, option, className, testid }) => {
  const isEditable = (id) => {
    const editableOptions = [prefsIds.goodSchools];
    return editableOptions.includes(id) && id in actives;
  };

  return (
    <Row gutter={[20, 20]} className={classNames(styles.optionHolder, className)}>
      {option.map(({ id, label }) => {
        return (
          <Col xs={24} md={8}>
            <div
              key={id}
              onClick={() => onClick(id)}
              className={classNames(styles.option, { [styles.active]: id in actives })}
              testid={testid}
            >
              <div className={styles.optionInner}>
                {isEditable(id) && <Edit onClick={(e) => onEdit(e, id)} className={styles.edit} />}
                <Icon className={styles.icon} variant={id} color={id in actives ? '#FF576D' : ''} />
                <p className={classNames(styles.title, { [styles.labelActive]: id in actives })}>
                  {label}
                </p>
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

Option.propTypes = {
  className: PropTypes.string,
  actives: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  option: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  testid: PropTypes.string,
};

Option.defaultProps = {
  className: '',
  actives: {},
  testid: undefined,
};

export default Option;
