import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useRequest } from 'hooks';

import { getExploreDetailsByGeolocationAndCategories } from 'api/listingDetail';
import { formatYelpDataToShowIt } from 'store/selectors/listingDetail';

import { ApiRequestError, DropdownSelect, Wrapper } from 'components';
import Icons from './Icons';
import ListModeView from './ListModeView';
import MapModeView from './MapModeView';

import styles from './styles.module.scss';

const ExploreSection = ({ geoLat, geoLong, id, price }) => {
  const areaRadius = 5000;
  const [isMapMode, setMapMode] = useState(true);
  const [prevCategories, setPrevCategories] = useState([]);
  const [categories, setCategories] = useState(['Highlights']);
  const toggleExploreMode = useCallback(() => setMapMode((val) => !val), []);

  const [{ loading, error, data }, setRequestParams, sendRequest] = useRequest({
    request: getExploreDetailsByGeolocationAndCategories,
    format: formatYelpDataToShowIt,
    params: {
      geolocation: `${geoLat},${geoLong}`,
      radius: areaRadius,
      categories: ['Highlights'],
    },
  });

  const formattedData = useMemo(
    () =>
      (categories.length && data ? data : []).map((marker) => {
        const distance = parseFloat(marker.distance);
        const intDistance = parseInt(marker.distance, 10);
        const formattedMarker = { ...marker };

        if (!Number.isNaN(distance)) {
          const fractionDigits = intDistance < 2 ? 2 : 1;
          formattedMarker.distance = `${distance.toFixed(fractionDigits)} mi`;
        }

        return formattedMarker;
      }),
    [data],
  );

  const areArraysIdentical = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true; // Arrays are identical
  };

  useEffect(() => {
    if (categories && prevCategories && !areArraysIdentical(categories, prevCategories)) {
      setPrevCategories([...categories]);
      setRequestParams({
        geolocation: `${geoLat},${geoLong}`,
        radius: areaRadius,
        categories,
      });
    }
  }, [categories, geoLat, geoLong]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>Explore</div>
        <DropdownSelect
          multiple
          items={ExploreSection.exploreItems}
          onChangeValues={setCategories}
          initValues={ExploreSection.initItems}
        />
        <button onClick={toggleExploreMode} className={styles.changeModeBtn}>
          <Icons
            className={styles.changeModeBtnIcon}
            variant={isMapMode ? Icons.LIST : Icons.MAP}
          />
        </button>
      </div>
      <Wrapper isPending={loading}>
        {!!error && <ApiRequestError title={error?.message} onRetry={sendRequest} />}
        {!error && isMapMode && (
          <MapModeView
            listingId={id}
            price={price}
            geoLat={geoLat}
            geoLong={geoLong}
            data={formattedData}
          />
        )}
        {!error && !isMapMode && <ListModeView data={formattedData} />}
      </Wrapper>
    </div>
  );
};

ExploreSection.exploreItems = [
  { title: 'Highlights', value: 'Highlights' },
  { title: 'Restaurants', value: 'Restaurants' },
  { title: 'Coffee Shops', value: 'CoffeeShops' },
  { title: 'Bars', value: 'Bars' },
  { title: 'Night Life', value: 'NightLife' },
  { title: 'Groceries', value: 'Groceries' },
  { title: 'Fitness', value: 'Fitness' },
  { title: 'Parks & Nature', value: 'ParksAndNature' },
  { title: 'Beauty & Spas', value: 'BeautyAndSpas' },
  { title: 'Health & Medical', value: 'HealthAndMedical' },
];

ExploreSection.initItems = {
  Highlights: 'Highlights',
};

ExploreSection.propTypes = {
  geoLat: PropTypes.number.isRequired,
  geoLong: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ExploreSection;
