import PropTypes from 'prop-types';

const Lock = ({ width, height, stroke, className }) => (
  <div className={className}>
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11317_168494)">
        <path
          d="M14.166 9.1665H5.83268C4.91221 9.1665 4.16602 9.9127 4.16602 10.8332V15.8332C4.16602 16.7536 4.91221 17.4998 5.83268 17.4998H14.166C15.0865 17.4998 15.8327 16.7536 15.8327 15.8332V10.8332C15.8327 9.9127 15.0865 9.1665 14.166 9.1665Z"
          stroke={stroke || '#747475'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99935 14.1667C10.4596 14.1667 10.8327 13.7936 10.8327 13.3333C10.8327 12.8731 10.4596 12.5 9.99935 12.5C9.53911 12.5 9.16602 12.8731 9.16602 13.3333C9.16602 13.7936 9.53911 14.1667 9.99935 14.1667Z"
          stroke={stroke || '#747475'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66602 9.16667V5.83333C6.66602 4.94928 7.0172 4.10143 7.64233 3.47631C8.26745 2.85119 9.11529 2.5 9.99935 2.5C10.8834 2.5 11.7313 2.85119 12.3564 3.47631C12.9815 4.10143 13.3327 4.94928 13.3327 5.83333V9.16667"
          stroke={stroke || '#747475'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11317_168494">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

Lock.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};

Lock.defaultProps = {
  className: '',
};

export default Lock;
