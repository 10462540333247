import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from 'components';
import { useLocation } from 'react-router-dom';
import { LocationService } from 'services';
import { useSelector } from 'react-redux';
import { getAgentClientsSelector } from 'store/selectors/feed';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';

import styles from './styles.module.scss';
import { ArrowLeft } from 'components/Icons';
import { Button } from 'components-antd';

const locationService = new LocationService();

const UserInfo = (props) => {
  const history = useHistory();
  const { className } = props;
  const location = useLocation();
  const clientFavorites = useSelector(getAgentClientsSelector);

  const goBack = () => {
    history.push('feed?activeTab=5');
  };

  const renderInfo = () => {
    const query = locationService.setLocation(location).getQuery();
    const {
      AvatarUrl,
      FirstName = '',
      LastName = '',
      TotalFavorites,
    } = clientFavorites?.data?.find((user) => user?.Id === query?.clientId) || {};

    if (!FirstName && !LastName) {
      return null;
    }

    return (
      <div className={styles.infoWrapper}>
        <Button variant="default" onClick={goBack} className={styles.backButton}>
          <ArrowLeft className={styles.icon} />
        </Button>
        <div className={styles.clientInfo}>
          <Avatar
            src={AvatarUrl}
            placeholder={convertNameToAvatarPlaceholder(`${FirstName} ${LastName}`)}
          />
          <div className={styles.text}>
            <div>
              <p testid="buyer_name" className={styles.name}>{`${FirstName} ${LastName}`}</p>
              <p className={styles.description}>
                {TotalFavorites} FAVORITE{TotalFavorites === 1 ? '' : 'S'}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div className={classNames(styles.userInfo, className)}>{renderInfo()}</div>;
};

UserInfo.propTypes = {
  className: PropTypes.string,
};

UserInfo.defaultProps = {
  className: '',
};

export default UserInfo;
