import DOMPurify from 'dompurify';
import { Mention, MentionItem, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { EditedCommentType } from 'types';

import styles from './styles.module.scss';

interface FormCommentMentionProps {
  tagableUsers: SuggestionDataItem[];
  editedComment: EditedCommentType;
  setEditedComment: (editedComment: EditedCommentType) => void;
  placeholder?: string;
}

export const FormCommentMention = ({
  tagableUsers,
  editedComment,
  setEditedComment,
  placeholder,
}: FormCommentMentionProps) => {
  const handleChange = (comment: string, mentions: MentionItem[]) => {
    const mentionEmails = mentions.map((mention) => mention.id);
    const filteredMentionEmails = mentionEmails.filter(
      (element, index) => mentionEmails.indexOf(element) === index,
    );

    setEditedComment({
      comment: DOMPurify.sanitize(comment),
      mentions: filteredMentionEmails,
    });
  };

  return (
    <MentionsInput
      maxLength={255}
      placeholder={placeholder || 'Reply'}
      className={styles.mentionEdit}
      value={editedComment.comment}
      onChange={(event, _newValue, _newPlainTextValue, mentions) =>
        handleChange(event.target.value, mentions)
      }
    >
      <Mention trigger="@" data={tagableUsers} displayTransform={(_id, display) => `@${display}`} />
    </MentionsInput>
  );
};
