import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import { PENDING } from 'settings/constants/apiState';
import { convertPrefs, prefsIds, PREF_TYPE } from 'settings/constants/preferences';
import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';
import { getClientDefaultSearchSelector } from 'store/selectors/user';

import { PropertyType } from 'components';
import Options from './Options';
import LayoutModal from './LayoutModal';
import StylesModal from './StylesModal';
import FlooringModal from './FlooringModal';
import KitchenFeaturesModal from './KitchenFeaturesModal';
import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';
import { Question } from 'pages/RequestQuote/components';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

import styles from './styles.module.scss';

const StageInteriorPrefs = ({ onNext, stageIndex, Controls }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const isHomeTypeLand =
    defaultClientSearch?.HomeType?.length === 1 &&
    defaultClientSearch.HomeType[0] === PropertyType.LAND;

  const {
    interiorStylesPrefs,
    newConstructionPref,
    homePrefers,
    layoutPrefs,
    flooringPrefs,
    kitchenFeaturesPrefs,
    moveInReadyPref,
  } = OnBoardingWizard.propNames;
  const common = { obj: onBoarding, stageIndex, signUpRole };

  const { HomePrefs: homePrefersDefault, InteriorStylesPrefs: stylePrefsDefault } =
    defaultClientSearch;

  const [stageValue, setStageValue] = useState([]);
  const [layout, setLayout] = useState([]);
  const [style, setStyle] = useState([]);
  const [kitchenFeatures, setKitchenFeatures] = useState([]);
  const [flooring, setFlooring] = useState([]);

  useEffect(() => {
    const storedHomePreferences =
      getWizardStageValue({
        ...common,
        propName: homePrefers,
      }) || [];

    const storedInteriorStyles = getWizardStageValue({
      obj: onBoarding,
      stageIndex,
      propName: interiorStylesPrefs,
      signUpRole,
    });

    if (storedHomePreferences?.length || homePrefersDefault?.length) {
      const prefs = storedHomePreferences?.length ? storedHomePreferences : homePrefersDefault;
      const home = [];
      const layout = [];
      const kitchen = [];
      const flooring = [];

      prefs.forEach((e) => {
        const name = e.Preference;
        if (
          [
            prefsIds.openFloorPlan,
            prefsIds.laundryRoom,
            prefsIds.homeOffice,
            prefsIds.basementRoom,
          ].includes(name)
        ) {
          layout.push(name);
        } else if (name.length >= 7 && name.substring(0, 7) == 'Kitchen') {
          kitchen.push(name);
        } else if (name.length >= 8 && name.substring(0, 8) == 'Flooring') {
          flooring.push(name);
        } else {
          home.push(name);
        }
      });

      if (layout.length) {
        setLayout(layout);
        home.push(layoutPrefs);
      }

      if (kitchen.length) {
        setKitchenFeatures(kitchen);
        home.push(kitchenFeaturesPrefs);
      }

      if (flooring.length) {
        setFlooring(flooring);
        home.push(flooringPrefs);
      }

      setStageValue((prevState) => [...prevState, ...home]);
    }

    if (storedInteriorStyles?.length || stylePrefsDefault?.length) {
      const prefs = storedInteriorStyles?.length ? storedInteriorStyles : stylePrefsDefault;
      setStyle(prefs);
      setStageValue((prevState) => [...prevState, interiorStylesPrefs]);
    }
  }, []); // eslint-disable-line

  const getPref = useCallback(
    (stage, prpName) =>
      get(
        stage,
        `meta.Values.[${signUpRole}].SearchInstance.DefaultPropertySearchPreferences.[${prpName}]`,
      ),
    [signUpRole],
  );

  const getPrevPreferences = useCallback(() => {
    const prevWizardStage = cloneDeep(onBoarding.wizard[stageIndex - 1]);
    return cloneDeep(
      Object.values({ [stageIndex - 1]: prevWizardStage }).filter(
        (stage) =>
          !!getPref(stage, PREF_TYPE.amenitiesPrefs) ||
          !!getPref(stage, prefsIds.parking) ||
          !!getPref(stage, PREF_TYPE.homePrefs),
      ),
    );
  }, [onBoarding?.wizard, getPref, stageIndex]);

  const getExtractedPrevPrefs = useCallback(
    () =>
      cloneDeep(
        getPrevPreferences().map(
          (preference) =>
            get(
              preference,
              `meta.Values.[${signUpRole}].SearchInstance.DefaultPropertySearchPreferences`,
            ),
          [signUpRole, getPrevPreferences],
        ),
      ),
    [getPrevPreferences, signUpRole],
  );

  const flatPrevPrefs = getExtractedPrevPrefs().reduce((acc, prefs) => ({ ...acc, ...prefs }), {});

  const onSave = () => {
    const homePrefs = stageValue.filter(
      (o) => o === moveInReadyPref || o === newConstructionPref || o === interiorStylesPrefs,
    );

    const convertedHomePrefs = convertPrefs(
      homePrefs,
      OnBoardingWizard.importances.someWhat,
      'Preference',
      homePrefersDefault,
    );
    // TODO: get rid of style filter once rest of prefs are added to BE
    const interiorStyle = style.filter(
      (s) => s === 'Loft' || s === 'Modern' || s === 'Traditional',
    );
    const convertedLayoutPrefs = convertPrefs(
      layout,
      OnBoardingWizard.importances.someWhat,
      'Preference',
      homePrefersDefault,
    );
    const convertedKitchenPrefs = convertPrefs(
      kitchenFeatures,
      OnBoardingWizard.importances.someWhat,
      'Preference',
      homePrefersDefault,
    );
    const convertedFlooringPrefs = convertPrefs(
      flooring,
      OnBoardingWizard.importances.someWhat,
      'Preference',
      homePrefersDefault,
    );

    const cfg = {
      stageIndex,
      DefaultPropertySearchPreferences: {
        ...flatPrevPrefs,
        [interiorStylesPrefs]: undefined,
        [homePrefers]: [
          ...(flatPrevPrefs?.HomePrefs || []),
          ...convertedHomePrefs,
          ...convertedLayoutPrefs,
          ...convertedKitchenPrefs,
          ...convertedFlooringPrefs,
        ],
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, {}, (err) => {
        if (!err) {
          onNext();
        }
      }),
    );
  };

  const onSaveLayoutValue = (value) => {
    if (!value) {
      const newStageValue = stageValue.filter((o) => o !== layoutPrefs);
      setStageValue(newStageValue);
    }
    setLayout(value);
  };

  const onSaveStyleValue = (value) => {
    if (!value || (Array.isArray(value) && !value?.length)) {
      const newStageValue = stageValue.filter((o) => o !== interiorStylesPrefs);
      setStageValue(newStageValue);
    }
    setStyle(value);
  };

  const onSaveKitchenFeaturesValue = (value) => {
    if (!value) {
      const newStageValue = stageValue.filter((o) => o !== kitchenFeaturesPrefs);
      setStageValue(newStageValue);
    }
    setKitchenFeatures(value);
  };

  const onSaveFlooringValue = (value) => {
    if (!value) {
      const newStageValue = stageValue.filter((o) => o !== flooringPrefs);
      setStageValue(newStageValue);
    }
    setFlooring(value);
  };

  const onChangePrefs = (val) => {
    if (!val.includes(prefsIds.style)) {
      setStyle([]);
    }

    if (!val.includes(prefsIds.layoutPrefs)) {
      setLayout([]);
    }

    if (!val.includes(prefsIds.kitchenFeatures)) {
      setKitchenFeatures([]);
    }

    if (!val.includes(prefsIds.flooring)) {
      setFlooring([]);
    }

    setStageValue(val);
  };

  const isPending = onBoarding.state === PENDING;

  return (
    <OnBoardingWrapper testid="interior_features" isPending={isPending}>
      <Controls onNext={onSave} className={styles.controls} variant="lightFull" />
      <OnBoardingContainer>
        <Question>Which of these are important to you?</Question>
        <AnswersContainer className={styles.answersContainer}>
          <Options
            onChange={onChangePrefs}
            value={stageValue}
            disabled={isPending}
            isHomeTypeLand={isHomeTypeLand}
            layoutValues={layout}
            kitchenFeatureValues={kitchenFeatures}
            flooringValues={flooring}
          />
        </AnswersContainer>
        <ButtonsContainer>
          <OnboardingContinueButton onClick={onSave} isPending={isPending} />
        </ButtonsContainer>
      </OnBoardingContainer>
      <LayoutModal onNext={(val) => onSaveLayoutValue(val)} value={layout} />
      <StylesModal onNext={(val) => onSaveStyleValue(val)} value={style} />
      <KitchenFeaturesModal
        onNext={(val) => onSaveKitchenFeaturesValue(val)}
        value={kitchenFeatures}
      />
      <FlooringModal onNext={(val) => onSaveFlooringValue(val)} value={flooring} />
    </OnBoardingWrapper>
  );
};

StageInteriorPrefs.propTypes = {
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

StageInteriorPrefs.defaultProps = {
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default StageInteriorPrefs;
