import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DrawerHeaderTitle, DrawerCancel } from 'components';
import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const NewCommentHeader = ({ className, onClose }) => (
  <div className={classNames(styles.header, className)}>
    <div className={styles.titleWrapper}>
      <DrawerHeaderTitle>New Message</DrawerHeaderTitle>
    </div>
    <DrawerCancel onClick={onClose} />
  </div>
);

NewCommentHeader.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

NewCommentHeader.defaultProps = {
  className: '',
  onClose: () => {},
};

export default NewCommentHeader;
