import { CardActions, TourStatus } from 'app-constants/enums/tours';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Button } from 'components-antd';
import { Check, Cross } from 'components/Icons';

export const MultiSelectFooter = ({ tours, action, onCancel, onConfirm, onDiscard, status }) => {
  {
    return (
      <>
        {
          <div
            className={classNames(styles.footerActions, {
              [styles.active]: action === CardActions.Multiple,
            })}
          >
            <div className={styles.footerInner}>
              <div className={styles.faLeft}>
                <Button
                  className={classNames(styles.button, styles.buttonReject)}
                  variant="text-only"
                  onClick={onCancel}
                >
                  Exit
                </Button>
              </div>
              <div className={styles.faRight}>
                <Button
                  className={classNames(styles.button, styles.buttonReject)}
                  variant="green-outlined"
                  buttonIcon={<Check className={styles.icon} color="#00B152" />}
                  onClick={onConfirm}
                  disabled={status !== TourStatus.Approved && !tours.length}
                >
                  {status === TourStatus.Approved ? 'Edit' : 'Approve'}
                </Button>

                <Button
                  className={classNames(styles.button, styles.buttonReject)}
                  variant="red-outlined"
                  buttonIcon={<Cross className={styles.icon} color="#EC455E" />}
                  onClick={onDiscard}
                  disabled={status !== TourStatus.Approved && !tours.length}
                >
                  {status === TourStatus.Approved ? 'Cancel' : 'Discard'}
                </Button>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
};
