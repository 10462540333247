import { v4 as uuidv4 } from 'uuid';
import {
  PropertyAddress,
  PropertyType,
  YearBuilt,
  WhoAreYouPresenting,
  PurchasePrice,
  EffectiveDate,
  ClosingDate,
  ListingStatus,
  ListingEffectiveDate,
  ValidUntil,
  ListingPrice,
  TransactionTemplate,
  Timeline,
  UploadFiles,
  Participants,
  Complete,
  FinancingType,
  CreatorRole,
  ProjectCategory,
  ProjectName,
  FinancingDetails,
  LiveDate,
} from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions';
import { transactionRoles } from './roles';
import TransactionParticipants from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/TransactionParticipants';
import { LISTED_STATUS, transactionStatuses } from './transactionStatus';
export * from './transactionStatus';

export const transactionPreFormQuestionsIds = {
  whoAreYouRepresenting: 'whoAreYouRepresenting',
  whoIsRepresentingTheBuyer: 'whoIsRepresentingTheBuyer',
  address: 'address',
  propertyType: 'propertyType',
  yearBuilt: 'yearBuilt',
  inviteClient: 'inviteClient',
  purchasePrice: 'purchasePrice',
  listingPrice: 'listingPrice',
  effectiveDate: 'effectiveDate',
  closingDate: 'closingDate',
  isPropertyUnderContract: 'isPropertyUnderContract',
  listingEffectiveDate: 'listingEffectiveDate',
  listingExpireDate: 'listingExpireDate',
  validUntil: 'validUntil',
  liveDate: 'liveDate',
  transactionTemplate: 'transactionTemplate',
  timeline: 'timeline',
  dateControls: 'dateControls',
  coordinators: 'coordinators',
  files: 'files',
  purchaseAgreement: 'purchaseAgreement',
  participants: 'participants',
  agents: 'agents',
  clients: 'clients',
  assignParticipantsToRoles: 'assignParticipantsToRoles',
  tasks: 'tasks',
  enableShowing: 'enableShowing',
  showingModal: 'showingModal',
  enableOffer: 'enableOffer',
  offerModal: 'offerModal',
  complete: 'complete',
  reasonForCancellation: 'reasonForCancellation',
  additionalComments: 'additionalComments',
  moveToActiveListing: 'moveToActiveListing',
  cancellationComplete: 'cancellationComplete',
  financingType: 'financingType',
  listedStatus: 'listedStatus',
  creatorRole: 'creatorRole',
  projectCategory: 'projectCategory',
  projectName: 'projectName',
  triggeredTasks: 'triggeredTasks',
  templateId: 'templateId',
  transactionEarnestMoney: 'transactionEarnestMoney',
  financingDetails: 'financingDetails',
  leadSource: 'leadSource',
  totalCommission: 'totalCommission',
  buyerCommission: 'buyerCommission',
  sellerCommission: 'sellerCommission',
  referralFee: 'referralFee',
  otherFees: 'otherFees',
  buySideCommissionResponsibility: 'buySideCommissionResponsibility',
  buyerCommissionContribution: 'buyerCommissionContribution',
  sellerCommissionContribution: 'sellerCommissionContribution',
  saveDefaultCreatorRole: 'saveDefaultCreatorRole',
};

export const progressForTransactionCancellation = {
  [transactionPreFormQuestionsIds.additionalComments]: 40,
  [transactionPreFormQuestionsIds.triggeredTasks]: 55,
  [transactionPreFormQuestionsIds.files]: 75,
  [transactionPreFormQuestionsIds.moveToActiveListing]: 85,
  [transactionPreFormQuestionsIds.cancellationComplete]: 100,
};

export const taskEmpty = {
  Category: '',
  Name: '',
  Description: '',
  DueDate: null,
  Links: [],
  Documents: [],
  AssigneeRoles: [],
  CcRoles: [],
};

export const getEmptyMilestone = () => ({
  Title: '',
  DueDate: null,
  Initial: null,
  Operator: null,
  IsPrivate: false,
  PublicId: uuidv4(),
  ControlOperatorOffset: '',
});

export const timelineEmpty = {
  Name: '',
  Description: null,
  Milestones: [getEmptyMilestone()],
};

export const transactionEmpty = {
  Name: '',
  PropertyType: [],
  Financing: [],
  Description: '',
  People: [],
  Teams: [],
  Locations: [],
  TimelineTemplate: { ...timelineEmpty },
  TaskTemplates: [{ ...taskEmpty }],
  TransactionFormTemplate: [],
};

export const timelineDateControlsOptions = [
  { label: 'T', value: 'Today' },
  { label: 'A', value: 'Assignment Date' },
  { label: 'CD', value: 'Contract Date' },
  { label: 'CL', value: 'Closing Date' },
  { label: 'LE', value: 'Listing Expiry Date' },
  { label: 'TL', value: 'Target Live Date' },
  { label: 'O', value: 'Custom Date' },
];

export const timelineDateControls = {
  T: 'T',
  A: 'A',
  CD: 'CD',
  CL: 'CL',
  LE: 'LE',
  O: 'O',
  KO: 'KO',
  DL: 'DL',
  TL: 'TL',
};

export const participantEmpty = {
  name: '',
  value: '',
  role: '',
  avatarUrl: '',
  firstName: '',
  lastName: '',
  invite: true,
  Phones: [{ PhoneNumber: '', PhoneType: '' }],
  transactionAccess: false,
};

export const getCommonPropertyQuestions = () => [
  { id: transactionPreFormQuestionsIds.propertyType, Component: PropertyType },
];

export const getCommonStartQuestions = () => [
  { id: transactionPreFormQuestionsIds.inviteClient, Component: TransactionParticipants },
  { id: transactionPreFormQuestionsIds.creatorRole, Component: CreatorRole },
  { id: transactionPreFormQuestionsIds.agents, Component: TransactionParticipants },
  { id: transactionPreFormQuestionsIds.coordinators, Component: TransactionParticipants },
  { id: transactionPreFormQuestionsIds.address, Component: PropertyAddress },
];

export const getCommonEndQuestions = () => [
  { id: transactionPreFormQuestionsIds.transactionTemplate, Component: TransactionTemplate },
  { id: transactionPreFormQuestionsIds.timeline, Component: Timeline },
  { id: transactionPreFormQuestionsIds.assignParticipantsToRoles, Component: Participants },
  { id: transactionPreFormQuestionsIds.participants, Component: Participants },
  { id: transactionPreFormQuestionsIds.files, Component: UploadFiles },
  { id: transactionPreFormQuestionsIds.complete, Component: Complete },
];

export const getCreateTransactionOrProjectsQuestions = (roleFor) => {
  switch (roleFor) {
    case transactionRoles.BUYER: {
      return [
        ...getCommonStartQuestions(),
        {
          id: transactionPreFormQuestionsIds.purchasePrice,
          Component: PurchasePrice,
          role: transactionRoles.BUYER,
        },
        {
          id: transactionPreFormQuestionsIds.effectiveDate,
          Component: EffectiveDate,
          role: transactionRoles.BUYER,
        },
        {
          id: transactionPreFormQuestionsIds.closingDate,
          Component: ClosingDate,
          role: transactionRoles.BUYER,
          props: { validateWithEffective: true },
        },
        ...getCommonPropertyQuestions(),
        {
          id: transactionPreFormQuestionsIds.financingType,
          Component: FinancingType,
          role: transactionRoles.BUYER,
        },
        {
          id: transactionPreFormQuestionsIds.financingDetails,
          Component: FinancingDetails,
          role: transactionRoles.BUYER,
        },
        ...getCommonEndQuestions(),
      ];
    }
    case transactionRoles.SELLER: {
      return [
        ...getCommonStartQuestions(),
        ...getCommonPropertyQuestions(),
        {
          id: transactionPreFormQuestionsIds.isPropertyUnderContract,
          Component: ListingStatus,
          role: transactionRoles.SELLER,
        },
        {
          id: transactionPreFormQuestionsIds.purchasePrice,
          Component: PurchasePrice,
          role: transactionRoles.SELLER,
        },
        {
          id: transactionPreFormQuestionsIds.effectiveDate,
          Component: EffectiveDate,
          role: transactionRoles.SELLER,
        },
        {
          id: transactionPreFormQuestionsIds.closingDate,
          Component: ClosingDate,
          role: transactionRoles.SELLER,
        },
        {
          id: transactionPreFormQuestionsIds.financingType,
          Component: FinancingType,
          role: transactionRoles.SELLER,
        },
        {
          id: transactionPreFormQuestionsIds.listingPrice,
          Component: ListingPrice,
          role: transactionRoles.SELLER,
        },
        {
          id: transactionPreFormQuestionsIds.listingEffectiveDate,
          Component: ListingEffectiveDate,
          role: transactionRoles.SELLER,
        },
        {
          id: transactionPreFormQuestionsIds.validUntil,
          Component: ValidUntil,
          role: transactionRoles.SELLER,
        },
        {
          id: transactionPreFormQuestionsIds.liveDate,
          Component: LiveDate,
          role: transactionRoles.SELLER,
        },
        {
          id: transactionPreFormQuestionsIds.financingDetails,
          Component: FinancingDetails,
          role: transactionRoles.SELLER,
        },
        ...getCommonEndQuestions(),
      ];
    }
    case 'Project': {
      return [
        {
          id: transactionPreFormQuestionsIds.projectCategory,
          Component: ProjectCategory,
        },
        {
          id: transactionPreFormQuestionsIds.projectName,
          Component: ProjectName,
        },
        {
          id: transactionPreFormQuestionsIds.effectiveDate,
          Component: EffectiveDate,
          props: { heading: 'What is the project kickoff date?' },
        },
        {
          id: transactionPreFormQuestionsIds.closingDate,
          Component: ClosingDate,
          props: { heading: 'What is the deadline for this project?' },
        },
        {
          id: transactionPreFormQuestionsIds.transactionTemplate,
          Component: TransactionTemplate,
          props: { heading: 'Do you want to apply a template?' },
        },
        {
          id: transactionPreFormQuestionsIds.creatorRole,
          Component: CreatorRole,
          props: { heading: 'What is your role in the project? ' },
        },
        { id: transactionPreFormQuestionsIds.timeline, Component: Timeline },
        { id: transactionPreFormQuestionsIds.assignParticipantsToRoles, Component: Participants },
        {
          id: transactionPreFormQuestionsIds.participants,
          Component: Participants,
          props: { heading: 'Do you want to invite any collaborators?' },
        },
        { id: transactionPreFormQuestionsIds.files, Component: UploadFiles },
        {
          id: transactionPreFormQuestionsIds.complete,
          Component: Complete,
          props: { heading: 'Your project is ready!' },
        },
      ];
    }

    default:
      return [
        {
          id: transactionPreFormQuestionsIds.whoAreYouRepresenting,
          Component: WhoAreYouPresenting,
        },
      ];
  }
};

export const displayListedStatus = {
  [LISTED_STATUS.Active]: 'Active',
  [LISTED_STATUS.Pending]: 'Pending',
  [LISTED_STATUS.PreListing]: 'Pre-Listing',
  [LISTED_STATUS.OfficeExclusive]: 'Office Exclusive',
  [LISTED_STATUS.ComingSoon]: 'Coming Soon',
  [LISTED_STATUS.ActiveListing]: 'Active Listing',
  [LISTED_STATUS.OnHold]: 'On Hold',
  [LISTED_STATUS.Expired]: 'Expired',
};

export const displayTransactionStatuses = {
  [transactionStatuses.UnderContract]: 'Under Contract',
  [transactionStatuses.ClearToClose]: 'Clear to Close',
  [transactionStatuses.Canceled]: 'Cancelled',
  [transactionStatuses.InProgress]: 'In Progress',
  [transactionStatuses.Upcoming]: 'Upcoming',
  [transactionStatuses.AtRisk]: 'At Risk',
  [transactionStatuses.OnHold]: 'On Hold',
  [transactionStatuses.Closed]: 'Closed',
};

export const transactionStatusesOptions = [
  {
    name: displayListedStatus[LISTED_STATUS.PreListing],
    value: LISTED_STATUS.PreListing,
  },
  {
    name: displayListedStatus[LISTED_STATUS.OfficeExclusive],
    value: LISTED_STATUS.OfficeExclusive,
  },
  {
    name: displayListedStatus[LISTED_STATUS.ComingSoon],
    value: LISTED_STATUS.ComingSoon,
  },

  {
    name: displayListedStatus[LISTED_STATUS.ActiveListing],
    value: LISTED_STATUS.ActiveListing,
  },
  {
    name: displayListedStatus[LISTED_STATUS.OnHold],
    value: LISTED_STATUS.OnHold,
  },
  {
    name: displayListedStatus[LISTED_STATUS.Expired],
    value: LISTED_STATUS.Expired,
  },
  {
    name: displayTransactionStatuses[transactionStatuses.UnderContract],
    value: transactionStatuses.UnderContract,
  },
  {
    name: displayTransactionStatuses[transactionStatuses.ClearToClose],
    value: transactionStatuses.ClearToClose,
  },
  {
    name: displayTransactionStatuses[transactionStatuses.Closed],
    value: transactionStatuses.Closed,
  },
  {
    name: displayTransactionStatuses[transactionStatuses.Canceled],
    value: transactionStatuses.Canceled,
  },
];

export const projectStatusesOptions = [
  {
    name: displayTransactionStatuses[transactionStatuses.InProgress],
    value: transactionStatuses.InProgress,
  },
  {
    name: displayTransactionStatuses[transactionStatuses.Upcoming],
    value: transactionStatuses.Upcoming,
  },
  {
    name: displayTransactionStatuses[transactionStatuses.AtRisk],
    value: transactionStatuses.AtRisk,
  },
  {
    name: displayTransactionStatuses[transactionStatuses.OnHold],
    value: transactionStatuses.OnHold,
  },
  {
    name: displayTransactionStatuses[transactionStatuses.Canceled],
    value: transactionStatuses.Canceled,
  },
  {
    name: displayTransactionStatuses[transactionStatuses.Closed],
    value: transactionStatuses.Closed,
  },
  {
    name: displayListedStatus[LISTED_STATUS.Expired],
    value: LISTED_STATUS.Expired,
  },
];

export const getStatusValue = (status) =>
  LISTED_STATUS[status]
    ? displayListedStatus[status]
    : status
    ? displayTransactionStatuses[status]
    : '';

export const TransactionUserRole = {
  Buyer: 'Buyer',
  Seller: 'Seller',
  ListingAgent: 'ListingAgent',
  BuyerAgent: 'BuyerAgent',
  TransactionCoordinator: 'TransactionCoordinator',
  AdministrativeAssistant: 'AdministrativeAssistant',
  Lender: 'Lender',
  Attorney: 'Attorney',
  Escrow: 'Escrow',
  Inspector: 'Inspector',
  ListingCoordinator: 'ListingCoordinator',
  TCII: 'TC II',
  TCIII: 'TC III',
  LCII: 'LC II',
  LCIII: 'LC III',
  BuyerAttorney: "Buyer's Attorney",
  SellerAttorney: "Seller's Attorney",
  Operations: 'Operations',
  Brokerage: 'Brokerage',
  Marketing: 'Marketing',
  Compliance: 'Compliance',
  Finance: 'Finance',
  Title: 'Title',
};

export const FullAccessRolesSet = {
  ListingAgent: 'Listing Agent',
  BuyerAgent: "Buyer's Agent",
  TransactionCoordinator: 'Transaction Coordinator',
  AdministrativeAssistant: 'Administrative Assistant',
  ListingCoordinator: 'Listing Coordinator',
  TCII: 'TC II',
  TCIII: 'TC III',
  LCII: 'LC II',
  LCIII: 'LC III',
  Operations: 'Operations',
  Brokerage: 'Brokerage',
  Finance: 'Finance',
};

export const TransactionUserRoleMap = {
  [TransactionUserRole.Buyer]: 'Buyer',
  [TransactionUserRole.Seller]: 'Seller',
  [TransactionUserRole.ListingAgent]: 'Listing Agent',
  [TransactionUserRole.BuyerAgent]: "Buyer's Agent",
  [TransactionUserRole.TransactionCoordinator]: 'Transaction Coordinator',
  [TransactionUserRole.AdministrativeAssistant]: 'Administrative Assistant',
  [TransactionUserRole.Lender]: 'Lender',
  [TransactionUserRole.Attorney]: 'Attorney',
  [TransactionUserRole.Escrow]: 'Escrow',
  [TransactionUserRole.Inspector]: 'Inspector',
  [TransactionUserRole.ListingCoordinator]: 'Listing Coordinator',
  [TransactionUserRole.TCII]: 'TC II',
  [TransactionUserRole.TCIII]: 'TC III',
  [TransactionUserRole.LCII]: 'LC II',
  [TransactionUserRole.LCIII]: 'LC III',
  [TransactionUserRole.BuyerAttorney]: "Buyer's Attorney",
  [TransactionUserRole.SellerAttorney]: "Seller's Attorney",
  [TransactionUserRole.Operations]: 'Operations',
  [TransactionUserRole.Brokerage]: 'Brokerage',
  [TransactionUserRole.Marketing]: 'Marketing',
  [TransactionUserRole.Compliance]: 'Compliance',
  [TransactionUserRole.Finance]: 'Finance',
  [TransactionUserRole.Title]: 'Title',
};

export const TransactionAccessType = {
  Full: 'Full',
  Limited: 'Limited',
};

export const TransactionReasonsForCancellation = [
  'Buyer(s) changed their mind',
  'Seller(s) changed their mind',
  'Unable to secure financing',
  'Appraisal issues',
  'Inspection issues',
  'Title issues',
  'Survey issues',
  'Transaction created in error',
  'Test transaction',
];

export const FORM_CATEGORY = 'Forms';
