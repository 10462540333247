import { useEffect, useState } from 'react';
import { Checkbox, Collapse, Panel } from 'components-antd';
import { Add, Remove } from 'components/Icons';
import { TimelineItem } from '../../components/TimelineItem';
import EmptyToursCard from '../EmptyToursCard';
import { GroupedTimeline } from '../../components/GroupedTimeline';
import { CancelModal } from '../../components/CancelModal';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { CardActions, TourSort, TourStatus } from 'app-constants/enums/tours';
import { fetchTours } from '../../helper';
import { Tour } from 'types/tours';
import { AcceptMultiModal } from '../../components/AcceptMultiModal';
import { PanelHeader } from '../../components/PanelHeader';
import classNames from 'classnames';
import { MultiSelectFooter } from '../../components/MultiSelectFooter';

export const Upcoming = (props) => {
  const { onEdit, selectedSort, selectedTeamFilter } = props;

  const { tours: groupedTours } = useSelector(
    fetchTours(selectedSort, TourStatus.Approved, selectedTeamFilter),
  );
  const [selectedTours, setSelectedTours] = useState<Tour[]>([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [action, setAction] = useState<string>('');
  const [selectedKey, setSelectedKey] = useState();

  useEffect(() => {
    resetState();
  }, [selectedSort]);

  const resetState = () => {
    setSelectedTours([]);
    setSelectedKey(undefined);
    setAction('');
  };
  const onCheckboxChange = (checked: boolean, tour: Tour) => {
    if (!checked) {
      setSelectedTours(selectedTours.filter((e) => e.Id !== tour.Id));
      return;
    }
    setSelectedTours([...selectedTours, tour]);
  };
  const checkboxChecked = (tour) => {
    return selectedTours.some((e) => e.Id === tour.Id);
  };
  const onHeaderCheckboxChange = (checked: boolean, key) => {
    if (!checked) {
      setSelectedTours([]);
      return;
    }
    selectAll(key);
  };
  const selectAll = (key) => {
    setSelectedTours(groupedTours[key]);
  };
  const onCancelTour = (tour) => {
    setSelectedTours([tour]);
    setShowCancelModal(true);
  };
  const onEditTour = (tour) => {
    setSelectedTours([tour]);
    setShowEditModal(true);
  };

  const isMultiSelect = (key) => {
    return (
      selectedSort === TourSort.ClientName && action === CardActions.Multiple && key === selectedKey
    );
  };

  const onActionsClick = (type: CardActions, key) => {
    switch (type) {
      case CardActions.Multiple:
        setAction(CardActions.Multiple);
        setSelectedKey(key);
        break;

      default:
        break;
    }
  };
  return (
    <>
      {!Object.keys(groupedTours).length ? <EmptyToursCard text={'tours upcoming'} /> : <></>}
      {Object.keys(groupedTours)
        .sort()
        .map((key, i) => (
          <Collapse
            bordered={false}
            accordion
            expandIcon={({ isActive }) =>
              isMultiSelect(key) ? (
                <Checkbox
                  className={classNames(styles.selectAll, 'mosaikCheckbox')}
                  onChange={(e) => {
                    onHeaderCheckboxChange(e.target.checked, key);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              ) : isActive ? (
                <Remove color={Remove.COLLAPSE} />
              ) : (
                <Add color={Add.COLLAPSE} />
              )
            }
            expandIconPosition={'end'}
            className={classNames(styles.tourCards, {
              [styles.disabled]:
                selectedSort === TourSort.ClientName &&
                action === CardActions.Multiple &&
                key !== selectedKey,
            })}
            ghost={true}
            defaultActiveKey={i}
            key={i}
          >
            <Panel
              header={
                <PanelHeader
                  onActionsClick={onActionsClick}
                  sort={selectedSort}
                  groupedKey={key}
                  tours={groupedTours[key]}
                ></PanelHeader>
              }
              className={styles.tourCard}
              key={i}
            >
              {selectedSort === TourSort.Date ? (
                groupedTours[key].map((tour, index) => (
                  <TimelineItem
                    sort={TourSort.Date}
                    tour={tour}
                    onCancelTour={onCancelTour}
                    onEditTour={onEditTour}
                    key={index}
                  ></TimelineItem>
                ))
              ) : (
                <GroupedTimeline
                  sort={selectedSort}
                  onCancelTour={onCancelTour}
                  onEditTour={onEditTour}
                  action={action}
                  groupedKey={key}
                  selectedKey={selectedKey}
                  checkboxChecked={checkboxChecked}
                  onCheckboxChange={onCheckboxChange}
                  tours={groupedTours[key]}
                  key={i}
                  groupedTimelineClassName={styles.groupedTimeline}
                  timelineItemClassName={styles.timelineItem}
                ></GroupedTimeline>
              )}
            </Panel>
          </Collapse>
        ))}
      {selectedTours.length > 0 && showEditModal && (
        <AcceptMultiModal
          tours={selectedTours}
          acceptModal={showEditModal}
          onCancel={() => {
            setShowEditModal(false);
            resetState();
          }}
          onSubmit={() => {
            setShowEditModal(false);
            resetState();
          }}
          title="Edit Tour(s)"
          isEdit={true}
        />
      )}
      {selectedTours.length > 0 && showCancelModal && (
        <CancelModal
          showModal={showCancelModal}
          tours={selectedTours}
          onCancel={() => {
            setShowCancelModal(false);
            resetState();
          }}
          onSubmit={() => {
            setShowCancelModal(false);
            resetState();
          }}
        ></CancelModal>
      )}
      <MultiSelectFooter
        onDiscard={() => setShowCancelModal(true)}
        onConfirm={() => setShowEditModal(true)}
        onCancel={() => resetState()}
        action={action}
        tours={selectedTours}
        status={TourStatus.Approved}
      ></MultiSelectFooter>
    </>
  );
};
