import { createAction } from 'redux-actions';

export const socketsStoreNewTransactionNoteAction = createAction(
  'SOCKETS/STORE_NEWTRANSACTION_NOTE',
);
export const socketsStoreEditedTransactionNoteAction = createAction(
  'SOCKETS/STORE_EDITED_TRANSACTION_NOTE',
);
export const socketsRemoveDeletedTransactionNoteAction = createAction(
  'SOCKETS/REMOVE_DELETED_ TRANSACTION_NOTE',
);
export const socketsStoreTransactionNotesByThreadIdAction = createAction(
  'SOCKETS/STORETRANSACTION_NOTES_BY_THREAD_ID',
);
export const socketsResetTransactionNotesAction = createAction('SOCKETS/RESET_ TRANSACTION_NOTES');
export const socketSetEditTransactionNoteAction = createAction(
  'SOCKETS/SET_EDIT_  TRANSACTION_NOTE',
);
export const setNewTransactionNoteWithTempIdAction = createAction(
  'SET_NEW_TRANSACTION_NOTE_WITH_TEMP_ID',
);
export const replaceTempTransactionNoteAction = createAction('REPLACE_TEMP_TRANSACTION_NOTE');
export const socketsStoreTransactionNotesSetThreadIdAction = createAction(
  'SOCKETS/STORE_TRANSACTION_NOTES_SET_THREAD_ID',
);
