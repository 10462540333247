import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ className, stroke, onClick, strokeWidth }) => (
  <div testid="edit_icon" onClick={onClick} className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 20.0003H8L18.5 9.5003C19.0304 8.96987 19.3284 8.25045 19.3284 7.5003C19.3284 6.75016 19.0304 6.03074 18.5 5.5003C17.9696 4.96987 17.2501 4.67188 16.5 4.67188C15.7499 4.67187 15.0304 4.96987 14.5 5.5003L4 16.0003V20.0003Z"
        stroke={stroke || 'white'}
        strokeWidth={strokeWidth || '1.5'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 6.5L17.5 10.5"
        stroke={stroke || 'white'}
        strokeWidth={strokeWidth || '1.5'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

Edit.propTypes = {
  className: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
  onClick: PropTypes.func,
};

Edit.defaultProps = {
  className: '',
  strokeWidth: '1.5',
  onClick: () => {},
};

export default Edit;
