import { useEffect, useState } from 'react';

import {
  AddFormCommentType,
  CommentUsersType,
  CustomOptions,
  EditFormCommentType,
  FormCommentThread,
} from 'types';
import { Space } from 'components-antd';
import { selectAllOption } from 'app-constants';
import { Drawer, DrawerCancel } from 'components';
import { FormPanelComments } from '../FormPanelComments';

import styles from './styles.module.scss';

interface FormCommentPanelProps {
  commentPanelOpen: boolean;
  setCommentPanelOpen: (commentPanelOpen: boolean) => void;
  threads: FormCommentThread[];
  commentUsers: CommentUsersType;
  handleAddFormComment: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment?: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment?: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
  setFormCommentFieldId?: (fieldId: string) => void;
}

export const FormCommentPanel = ({
  commentPanelOpen,
  setCommentPanelOpen,
  threads,
  commentUsers,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
  setFormCommentFieldId,
}: FormCommentPanelProps) => {
  const [isSending, setSending] = useState<boolean>(false);

  const [permissionOptions, setPermissionOptions] = useState<CustomOptions>([]);
  const [notifyOptions, setNotifyOptions] = useState<CustomOptions>([]);
  const [permittedUsers, setPermittedUsers] = useState<string[]>([selectAllOption.value]);
  const [notifiedUsers, setNotifiedUsers] = useState<string[]>();

  useEffect(() => {
    setPermissionOptions(
      Object.entries(commentUsers)
        .filter(([_key, user]) => !user.self)
        .map(([_key, user]) => ({
          label: user.name || user.email,
          value: user.email,
          avatar: user.avatar,
        })),
    );
  }, [commentUsers]);

  useEffect(() => {
    if (permissionOptions?.length) {
      if (permittedUsers?.includes(selectAllOption.value)) {
        setNotifyOptions(permissionOptions);
      } else {
        setNotifyOptions(
          permissionOptions.filter((permissionOption) =>
            permittedUsers?.includes(permissionOption.value),
          ),
        );
      }
      if (permittedUsers?.length && !permittedUsers?.includes(selectAllOption.value)) {
        setNotifiedUsers(
          notifiedUsers?.filter((notifiedUser) => permittedUsers?.includes(notifiedUser)),
        );
      }
    }
  }, [permittedUsers, permissionOptions]);

  const getCommentPanelHeader = () => (
    <div className={styles.commentPanelHeader}>
      <span className={styles.commentPanelHeading}>Comments</span>
      <div className={styles.commentPanelActions}>
        <Space>
          <DrawerCancel
            onClick={() => {
              setCommentPanelOpen(false);
              setFormCommentFieldId && setFormCommentFieldId('');
            }}
            className={styles.formCommentDrawerCancel}
          />
        </Space>
      </div>
    </div>
  );

  return (
    <Drawer
      onClose={() => {
        setCommentPanelOpen(false);
        setFormCommentFieldId && setFormCommentFieldId('');
      }}
      header={getCommentPanelHeader()}
      isOpen={commentPanelOpen}
      contentStyleClassName={styles.formCommentsPanelContent}
    >
      <FormPanelComments
        threads={threads}
        commentUsers={commentUsers}
        notifyOptions={notifyOptions}
        permissionOptions={permissionOptions}
        permittedUsers={permittedUsers}
        setPermittedUsers={setPermittedUsers}
        notifiedUsers={notifiedUsers}
        setNotifiedUsers={setNotifiedUsers}
        isSending={isSending}
        setSending={setSending}
        handleAddFormComment={handleAddFormComment}
        handleEditFormComment={handleEditFormComment}
        handleDeleteFormComment={handleDeleteFormComment}
        handleToggleResolveThread={handleToggleResolveThread}
        setFormCommentFieldId={setFormCommentFieldId}
      />
    </Drawer>
  );
};
