import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { link } from 'settings/navigation/link';
import { getPropertyAddress } from 'helpers';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import BaseNotification from '../../../BaseNotification';

import styles from './styles.module.scss';

const MilestoneCompleted = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const { TransactionId } = Payload || {};

  const onClickHandler = () => {
    history.replace(link.toTransactionOverview(TransactionId));
    dispatch(openNotificationsDrawerEffect({ open: false }));
  };

  return (
    <div className={classNames(styles.notificationType, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        onClick={onClickHandler}
        groupType={groupType}
        severityLevel={SeverityLevel}
      />
    </div>
  );
};

MilestoneCompleted.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    TransactionId: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

MilestoneCompleted.defaultProps = {
  className: '',
};

export default MilestoneCompleted;
