import { getMinMaxAsStringValues } from 'components/MinMax/helpers';
import AllSubFeedForm from '../index';

const fieldsToStringify = ['PriceRange', 'SquareFeetRange', 'LotSizeRange', 'StoriesRange'];

export const mergeInitialValues = (values) => ({
  ...AllSubFeedForm.initialValues,
  ...values,
  HOARange: { Max: +values?.HOARange?.Max || '' },
  MaxDaysOnMarket: values?.MaxDaysOnMarket
    ? `${values?.MaxDaysOnMarket}`
    : AllSubFeedForm.initialValues.MaxDaysOnMarket,
  ...fieldsToStringify.reduce(
    (searchObj, fieldName) =>
      fieldName
        ? {
            ...searchObj,
            [fieldName]: getMinMaxAsStringValues(fieldName, values, AllSubFeedForm.initialValues),
          }
        : searchObj,
    {},
  ),
});
