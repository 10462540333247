import Api from 'store/effects/core/api';
import { requestGetSymphonyListAction } from 'store/actions/symphony';
import { getSymphonyList } from 'api/symphony';

export const requestGetSymphonyListEffect = (cfg, options = {}, cb) => {
  const requestParams = { action: requestGetSymphonyListAction, method: getSymphonyList };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};
