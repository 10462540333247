import { Icons } from '../Icons';
import styles from './style.module.scss';

export const FormHeader = ({ handlePrevious, heading }) => {
  return (
    <div className={styles.header}>
      {handlePrevious ? (
        <div className={styles.previousIcon} onClick={handlePrevious}>
          <Icons variant={'previous'} />
        </div>
      ) : (
        <></>
      )}
      <h1 className={styles.heading}>{heading}</h1>
    </div>
  );
};
