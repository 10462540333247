import { Dispatch, FC, Fragment, SetStateAction, useCallback, useEffect, useState } from 'react';

import { ClientPreApprovalsActionsMenu } from './ClientPreApprovalsActionsMenu';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from 'store/selectors/user';
import { PreApprovalItem } from 'components';

import styles from './styles.module.scss';
import { InitialValueTypes } from '../types';

interface Props {
  clientId?: string;
  setShowApprovalsModal: Dispatch<SetStateAction<boolean>>;
  showApprovalsModal: boolean;
  formattedDocuments: object[];
  handleEditManualEntry: (id) => void;
  getPreApprovalDocuments: () => Promise<void>;
  setPreApprovalInitialValues: Dispatch<SetStateAction<InitialValueTypes>>;
  initialValues: InitialValueTypes;
  setEditPreApprovalId: Dispatch<SetStateAction<number | null>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setPreApprovalCount?: Dispatch<SetStateAction<number>>;
}

export const PreApprovalListing: FC<Props> = ({
  clientId,
  showApprovalsModal,
  setShowApprovalsModal,
  formattedDocuments,
  handleEditManualEntry,
  getPreApprovalDocuments,
  setPreApprovalInitialValues,
  initialValues,
  setEditPreApprovalId,
  setIsLoading,
  setPreApprovalCount,
}) => {
  const userId = useSelector(getUserId);

  useEffect(() => {
    getPreApprovalDocuments();
  }, []);

  useEffect(() => {
    if (!showApprovalsModal) {
      setPreApprovalInitialValues({ ...initialValues });
      setEditPreApprovalId(null);
      setIsLoading(false);
    }
  }, [showApprovalsModal]);

  return (
    <div className={styles.preApprovalDocuments}>
      {formattedDocuments?.map((data) =>
        Object.keys(data).map((date) => (
          <>
            <p className={styles.uploadDate}>{date}</p>
            {data[date].map((document: any) => (
              <PreApprovalItem
                {...document}
                key={document.Id}
                menuItem={
                  <ClientPreApprovalsActionsMenu
                    setPreApprovalCount={setPreApprovalCount}
                    document={document}
                    clientId={clientId}
                    getPreApprovalDocuments={getPreApprovalDocuments}
                    handleEditManualEntry={() => handleEditManualEntry(document.Id)}
                    type={document.Documents ? 'document' : 'manualEntry'}
                  />
                }
              ></PreApprovalItem>
            ))}
          </>
        )),
      )}
    </div>
  );
};
