import moment from 'moment';

import styles from '../styles.module.scss';
import { EditStageInfo } from '../EditStageInfo';

export const EditLinkDuration = ({ data, onEdit }) => {
  const getInfo = () => {
    const {
      SelectedDuration: type,
      LinkActiveStartDate: startDate,
      LinkActiveEndDate: endDate,
      LinkActiveDays,
    } = data;

    if (type?.isIndefinite) {
      return 'Indefinitely';
    } else if (type?.isAddDays) {
      return `${moment(startDate).format('MM/DD')} + ${LinkActiveDays} days`;
    } else if (type?.isDateRange) {
      return `${moment(startDate).format('MM/DD')} to ${moment(endDate).format('MM/DD')}`;
    } else {
      return 'N/A';
    }
  };

  return (
    <div className={styles.editForm}>
      <EditStageInfo
        icon="time"
        title="How long should this link be active?"
        info={getInfo()}
        onClick={onEdit}
      />
    </div>
  );
};
