import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { Icons } from 'pages/Vault/Icons';

import styles from './styles.module.scss';

export const Archive = ({ docsCount }) => {
  const history = useHistory();

  return (
    <div className={styles.archiveContainer}>
      <div className={styles.content}>
        <div className={styles.wrapper} onClick={() => history.push(routes.vaultArchives)}>
          <Icons variant={Icons.ARCHIVE} />
          <div className={styles.textContainer}>
            <p className={styles.archiveText}>Archive</p>
            <p className={styles.countText}>
              {docsCount} Document{docsCount === 1 ? '' : 's'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
