import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const Photos = memo(({ className }) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="6" y="5" width="10" height="10" rx="2" stroke="#303030" strokeWidth="2" />
          <path
            d="M19 9V14.5C19 16.433 17.433 18 15.5 18H10"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

Photos.propTypes = {
  className: PropTypes.string,
};

Photos.defaultProps = {
  className: '',
};

export default Photos;
