import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { useSelector } from 'react-redux';
import Icon from 'pages/Properties/Feed/Icons';
import styles from './styles.module.scss';

const NewComment = ({ className, onClick }) => {
  const { isClient } = useSelector(getUserRolesMapSelector);

  return (
    <div className={classNames(styles.noComments, className)}>
      <div testid="new_comment" onClick={onClick} className={styles.newComment}>
        <Icon className={styles.editIcon} variant={Icon.EDIT_COLOR} />
        <p>New Comment</p>
      </div>
    </div>
  );
};

NewComment.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

NewComment.defaultProps = {
  className: '',
  onClick: () => {},
};

export default NewComment;
