import { createSelector } from 'reselect';

const localState = ({ context }) => context;

/**
 * @deprecated
 */
export const getSelectedContextIdSelector = createSelector(
  localState,
  (context) => context?.contextId,
);

export const getCurrentContextSelector = createSelector(localState, (state) => state.context);
