import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { getDrawerState } from 'store/selectors/drawers/threadContacts';
import {
  openThreadContactsDrawerAction,
  changeThreadContactsDrawerParamsAction,
} from 'store/actions/drawers/threadContacts';

import { DRAWER_THREAD_CONTACTS_TYPES } from 'settings/constants/drawers';

import { Drawer } from 'components/index';

import ActionsHeader from '../components/ActionsHeader';
import ContactContent from './ContactContent';
import ContactsListContent from './ContactsListContent';

import styles from './styles.module.scss';

const ThreadContactsDrawer = () => {
  const {
    open,
    type,
    params: { contactId, threadId, isTransactionMessages, isSingleContact } = {},
  } = useSelector(getDrawerState);
  const dispatch = useDispatch();

  const handleDrawerClose = useCallback(
    () => dispatch(openThreadContactsDrawerAction({ open: false })),
    [dispatch],
  );
  const handleBack = useCallback(() => {
    if (type === DRAWER_THREAD_CONTACTS_TYPES.CONTACT && !isSingleContact) {
      dispatch(
        changeThreadContactsDrawerParamsAction({
          type: DRAWER_THREAD_CONTACTS_TYPES.CONTACTS_LIST,
          params: { threadId, isTransactionMessages },
        }),
      );
    } else {
      dispatch(openThreadContactsDrawerAction({ open: false }));
    }
  }, [dispatch, type, threadId, isTransactionMessages, isSingleContact]);

  const label = useMemo(() => {
    if (type === DRAWER_THREAD_CONTACTS_TYPES.CONTACT) {
      if (isTransactionMessages && isSingleContact) {
        return 'Messages';
      }
      if (isTransactionMessages && !isSingleContact) {
        return 'Contacts';
      }
      return 'Message';
    }
    if (type === DRAWER_THREAD_CONTACTS_TYPES.DIRECT_CONTACT) {
      return 'Messages';
    }
    return 'Message';
  }, [type, isSingleContact, isTransactionMessages]);

  return (
    <Drawer
      shouldDisableScroll={false}
      zIndex={1000}
      header={<ActionsHeader label={label} onClick={handleBack} />}
      isOpen={open}
      onClose={handleDrawerClose}
      opacityLayerClassName={classNames({ [styles.background]: !isTransactionMessages })}
      testid="contacts_drawer"
    >
      {type === DRAWER_THREAD_CONTACTS_TYPES.CONTACTS_LIST ? (
        <ContactsListContent isTransactionMessages={isTransactionMessages} />
      ) : (
        <ContactContent contactId={contactId} isTransactionMessages={isTransactionMessages} />
      )}
    </Drawer>
  );
};

export default ThreadContactsDrawer;
