import { apiServer } from 'settings/web-services/api';

export function forgotPassword(cfg) {
  return apiServer.post('/accounts/password/sendResetPasswordEmail', cfg);
}

export function resetPassword(cfg) {
  return apiServer.post('/accounts/password/resetPassword', cfg);
}

export function changePassword(cfg) {
  return apiServer.post('/accounts/password/changePassword', cfg);
}
