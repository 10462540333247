import {
  FeedApiParams,
  FeedV2Query,
  getAllPropsV2,
  getBackOnMarketPropsV2,
  getNewPropsV2,
  getPriceReductionsPropsV2,
  getRecommendedPropsV2,
  getSavedSearchesPropsV2,
  getStatusChangesPropsV2,
  getSuggestionsPropsV2,
  getWatchlistPropsV2,
  SortOrder,
  getOpenHousesPropsV2,
  getSearchInstancePropsV2,
} from 'api/feed/feedV2';
import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { ActionFunctionAny } from 'redux-actions';
import { getState } from 'store';
import {
  requestGetAllPropsAction,
  requestGetBackOnMarketPropsAction,
  requestGetNewPropsAction,
  requestGetPriceReductionsPropsAction,
  requestGetRecommendedPropsAction,
  requestGetSavedSearchesPropsAction,
  requestGetStatusChangesPropsAction,
  requestGetSuggestionsPropsAction,
  requestGetWatchlistPropsAction,
  setFeedPageInfoAction,
  requestGetOpenHousesPropsAction,
  requestGetSearchInstancePropsAction,
} from 'store/actions/feed';
import Api from '../core/api';
import moment from 'moment';

const DEFAULT_PAGE_SIZE = 350;

export const makeFeedSearchEffect =
  (
    method: (payload: FeedApiParams) => Promise<AxiosResponse<any, any>>,
    action: ActionFunctionAny<Action<any>>,
  ) =>
  (body: any = null, options: { add?: boolean } = {}, cb) => {
    const { feed, context: contextState } = getState();
    const {
      activeTab,
      sort: { order, fields },
      pageInfo: { pageInfo },
      feedPrefs: { filters: feedCustomFilter },
      subFilterType,
      map,
    } = feed;
    const { context } = contextState;

    const queryParams: FeedV2Query = {
      sortField: fields[0],
      sortOrder: order as SortOrder,
      first: DEFAULT_PAGE_SIZE,
      endCursor: options.add ? pageInfo?.endCursor ?? null : null,
      contextId: (context as any)?.ContextKey ?? null,
      loadTotalCount: true,
      currentTimeUTC: moment().utc().format('HH:mm:ss'),
      searchInstanceId: typeof activeTab === 'number' && !isNaN(activeTab) ? activeTab : null,
      subFilterType: subFilterType.type,
    };

    const requestParams = {
      action,
      method,
      pending: options.add ? false : true,
      cfg: {
        queryParams,
        body: {
          searchQuery: feedCustomFilter ?? {},
        },
      },
      options,
      cb: (err, response, dispatch) => {
        if (!err) {
          dispatch(
            setFeedPageInfoAction({
              pageInfo: response.data.pageInfo,
              totalCount: response.data.totalCount,
            }),
          );
        }

        if (cb) {
          cb(err, response, dispatch);
        }
      },
    };

    return Api.execFunc(requestParams);
  };

export const getAllPropsV2Effect = makeFeedSearchEffect(getAllPropsV2, requestGetAllPropsAction);

export const getNewPropsV2Effect = makeFeedSearchEffect(getNewPropsV2, requestGetNewPropsAction);

export const getSavedSearchesPropsV2Effect = makeFeedSearchEffect(
  getSavedSearchesPropsV2,
  requestGetSavedSearchesPropsAction,
);

export const getPriceReductionsPropsV2Effect = makeFeedSearchEffect(
  getPriceReductionsPropsV2,
  requestGetPriceReductionsPropsAction,
);

export const getStatusChangesPropsV2Effect = makeFeedSearchEffect(
  getStatusChangesPropsV2,
  requestGetStatusChangesPropsAction,
);

export const getRecommendedPropsV2Effect = makeFeedSearchEffect(
  getRecommendedPropsV2,
  requestGetRecommendedPropsAction,
);

export const getWatchlistPropsV2Effect = makeFeedSearchEffect(
  getWatchlistPropsV2,
  requestGetWatchlistPropsAction,
);

export const getBackOnMarketPropsV2Effect = makeFeedSearchEffect(
  getBackOnMarketPropsV2,
  requestGetBackOnMarketPropsAction,
);

export const getSuggestionsPropsV2Effect = makeFeedSearchEffect(
  getSuggestionsPropsV2,
  requestGetSuggestionsPropsAction,
);

export const getOpenHousesPropsV2Effect = makeFeedSearchEffect(
  getOpenHousesPropsV2,
  requestGetOpenHousesPropsAction,
);

export const getSearchInstancePropsV2Effect = makeFeedSearchEffect(
  getSearchInstancePropsV2,
  requestGetSearchInstancePropsAction,
);
