import { routes } from 'settings/navigation/routes';
import { SignUp, SignUpRole } from 'pages';
import { SignupV2 } from 'pages/SignupV2';

export default [
  { path: routes.signUp, component: SignUpRole, exact: true },
  { path: routes.signUpWithRole, component: SignUp, exact: true },
  { path: routes.signUpV2Agent, component: SignupV2, exact: true },
  { path: routes.signUpV2AgentAlternative, component: SignupV2, exact: true },
];
