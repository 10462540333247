import { useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { showErrorMessage } from 'helpers';
import { validateControlOperatorOffset, convertToInitialOffset } from 'utils/templatesHelper';
import { readExcelFile } from 'utils/readFile';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { TransactionUserRoleMap } from 'settings/constants/transaction';

import styles from './styles.module.scss';

const UploadTasks = ({ handleUpload, isViewMode }) => {
  const { isSuperUser } = useSelector(getUserRolesMapSelector);

  const getNewTask = () => {
    return {
      Name: null,
      Description: null,
      AssigneeRoles: [],
      CcRoles: [],
      ControlOperatorOffset: null,
      Initial: null,
      Operator: null,
      DueDate: null,
    };
  };

  const showError = (index, field) => {
    showErrorMessage(`${field} on row ${index + 2} is incorrect!`);
  };

  const validateAssigneeRoles = (list) => {
    const roles = Object.values(TransactionUserRoleMap);
    return list?.split(/[,;/]+/).some((role) => roles.includes(role));
  };

  const getAssigneeRoles = (list) => {
    return list
      ?.split(/[/,;]+/)
      .map((role) =>
        Object.keys(TransactionUserRoleMap).find(
          (key) => TransactionUserRoleMap[key] === role.trim(),
        ),
      );
  };

  const validateDueDate = (dueDate) => {
    const ControlOperatorOffsetPattern = /^[A-Z]+[+-]\d+/;
    return (
      validateControlOperatorOffset(null, dueDate, () => {}) &&
      ControlOperatorOffsetPattern.test(dueDate)
    );
  };

  const getLinks = (links) => {
    return links.split('|').map((link) => {
      const split = link.split(/:(.*)/);
      if (split.length === 1) return { link: split[0].trim(), label: '' };
      else return { link: split[1].trim(), label: split[0].trim() };
    });
  };

  const getChecklist = (list) => {
    return list.split(/[,;]+/).map((check) => {
      return {
        Name: check.replace(/\(Required\)/g, '').trim(),
        Required: check.includes('(Required)'),
      };
    });
  };

  const formatTasks = (list) => {
    const taskList = [];
    let error = false;

    for (let index = 0; index < list.length; index++) {
      const task = list[index];
      const newTask = getNewTask();
      newTask.Name = task['Task Name'];
      newTask.Description = task.Description;
      if (task['Links']) newTask.Links = getLinks(task['Links']);
      if (task.Checklist) newTask.Checklists = getChecklist(task.Checklist);

      const dueDate = task['Due Date'];
      if (validateDueDate(dueDate)) {
        const split = dueDate.includes('+') ? dueDate.split('+') : dueDate.split('-');
        newTask.Initial = split[0];
        newTask.Operator = dueDate.includes('+') ? '+' : '-';
        newTask.DueDate = +split[1];
        newTask.ControlOperatorOffset = dueDate;
      } else {
        showError(index, 'Due Date');
        error = true;
        break;
      }

      if (validateAssigneeRoles(task.Assignee)) {
        newTask.AssigneeRoles = getAssigneeRoles(task.Assignee);
      } else {
        showError(index, 'Assignee');
        error = true;
        break;
      }

      if (task.CC)
        if (validateAssigneeRoles(task.CC)) {
          newTask.CcRoles = getAssigneeRoles(task.CC);
        } else {
          showError(index, 'CC');
          error = true;
          break;
        }

      taskList.push(newTask);
    }
    if (!error) handleUpload(taskList);
  };

  const downloadTemplate = (e) => {
    const url =
      'https://mosaik-assets.s3.amazonaws.com/referenceData/Mosaik_Tasks_Template_V2.xlsx';
    if (!isViewMode) window.open(url, '_blank');
  };

  return (
    <>
      {isSuperUser && (
        <div className={styles.uploadTasksContainer}>
          <button type="button" className={styles.actionBtn} onClick={(e) => downloadTemplate(e)}>
            <Icon variant={Icon.DOWNLOAD} />
          </button>
          <div>
            <label htmlFor="task-upload" className={styles.actionBtn}>
              <Icon variant={Icon.UPLOAD} className={styles.uploadIcon} />
              Upload
            </label>
            {!isViewMode && (
              <input
                id="task-upload"
                type="file"
                accept=".xls,.xlsx,.csv"
                onChange={(e) => readExcelFile(e, formatTasks)}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UploadTasks;
