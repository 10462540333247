export const getQuestionsByRole = (questions, editorConfig) => {
  const modifiedQuestions = {};

  const { formRoles } = editorConfig;

  formRoles.forEach(({ roleId }) => {
    Object.keys(questions).forEach((pageNumber) => {
      if (questions[pageNumber]?.[roleId]) {
        modifiedQuestions[pageNumber] = {
          ...modifiedQuestions[pageNumber],
          [roleId]: questions[pageNumber][roleId],
        };
      }
    });
  });

  return modifiedQuestions;
};
