import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const GroupTitle = (props) => {
  const { className, text } = props;

  return (
    <div className={classNames(styles.groupTitle, className)}>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

GroupTitle.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

GroupTitle.defaultProps = {
  className: '',
  text: '',
};

export default GroupTitle;
