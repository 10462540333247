import { createAction } from 'redux-actions';

export const requestGetListingDetailByIdAction = createAction('REQUEST/GET_LISTING_DETAIL_BY_ID');

export const requestToggleFavoriteListingAction = createAction('REQUEST/TOGGLE_FAVORITE_LISTING');
export const requestScheduleTourAction = createAction('REQUEST/SCHEDULE_TOUR');
export const getScheduleTourAction = createAction('REQUEST/GET_SCHEDULE_TOUR_BY_PPROPERTY_ID');

export const listingDetailToggleFavoriteListingAction = createAction(
  'LISTING_DETAIL/TOGGLE_FAVORITE_LISTING',
);
export const propertTimeSpentAuditLogAction = createAction('REQUEST/PROPERTY_TIME_SPENT_AUDIT_LOG');
