import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { routes } from 'settings/navigation/routes';
import { Accordion, Spinner } from 'components';
import {
  setSearchCriteriaEffect,
  resetSearchCriteriaEffect,
  getSavedSearchesEffect,
  setRawPrefDataEffect,
} from 'store/effects/search';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { openSearchResultsFilterDrawerEffect } from 'store/effects';
import {
  deactivateSearchInstanceEffect,
  activateSearchInstanceEffect,
  deleteSearchInstanceEffect,
  renameSearchInstanceEffect,
  renameSavedSearchEffect,
  deleteSavedSearchEffect,
  activateSavedSearchEffect,
  deactivateSavedSearchEffect,
} from 'store/effects/mySearches';
import { CREATE, EDIT } from 'settings/constants/mode';
import SearchCard from 'pages/Properties/Search/components/SearchCard';
import {
  setSearchInstanceStatusAction,
  deleteSearchInstanceAction,
  setRenameSearchInstanceAction,
  setEditedSearchCriteriaAction,
  setRenameSavedSearchAction,
  deleteSavedSearchAction,
  setSavedSearchStatusAction,
} from 'store/actions/mySearches';
import {
  setClientsRenameSearchInstanceAction,
  deleteClientsSearchInstanceAction,
  setClientsSearchInstanceStatusAction,
} from 'store/actions/clientSearches';
import { showSuccessMessage } from 'helpers/success';
import moment from 'moment';
import { Row, Col } from 'antd';
import { propertyStatus } from 'settings/constants/properties';
import { getClientInstancesEffect } from 'store/effects/clientInstances';

import styles from './styles.module.scss';
import { useSetSearch } from 'hooks';

const SavedSearchesBlock = ({
  activeSearches,
  inactiveSearches,
  isLoading,
  isClientSearch = false,
  clientId = null,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isClient } = useSelector(getUserRolesMapSelector);
  const { updateSearchValue } = useSetSearch('search');

  const getSearchHandler = useCallback(
    (criterias) => () => {
      dispatch(resetSearchCriteriaEffect());
      dispatch(setRawPrefDataEffect());
      dispatch(openSearchResultsFilterDrawerEffect({ open: false, mode: CREATE, isClientSearch }));
      const query = {
        ...criterias,
        ...(!criterias?.Status?.length ? { Status: [propertyStatus.active] } : {}),
      };
      dispatch(setSearchCriteriaEffect(query));
      updateSearchValue(query);
      history.push(routes.searchResults + history.location.search);
    },
    [dispatch],
  );

  const getDeleteHandler = useCallback(
    (id) => () => {
      if (isClient || isClientSearch) {
        dispatch(
          deleteSearchInstanceEffect({ id }, {}, (err) => {
            if (!err) {
              isClientSearch
                ? dispatch(deleteClientsSearchInstanceAction({ id, clientId }))
                : dispatch(deleteSearchInstanceAction({ id }));
              showSuccessMessage('Search Instance Deleted Successfully');
            }
          }),
        );
      } else {
        dispatch(
          deleteSavedSearchEffect({ id }, {}, (err) => {
            if (!err) {
              dispatch(deleteSavedSearchAction({ id }));
              showSuccessMessage('Saved Search Deleted Successfully');
            }
          }),
        );
      }
    },
    [dispatch],
  );

  const getEditHandler = useCallback(
    (search) => () => {
      dispatch(resetSearchCriteriaEffect());
      dispatch(setRawPrefDataEffect());
      dispatch(setEditedSearchCriteriaAction(search));
      dispatch(openSearchResultsFilterDrawerEffect({ open: true, mode: EDIT, isClientSearch }));
      history.push(
        routes.searchResults +
          (isClientSearch || isClient
            ? `?searchInstanceId=${search.id}`
            : `?searchId=${search.id}`),
      );
    },
    [dispatch],
  );

  const getRenameHandler = (id) => (name, onCloseRenameModal) => {
    if (isClient || isClientSearch) {
      dispatch(
        renameSearchInstanceEffect({ id, name }, {}, (err) => {
          if (!err) {
            isClientSearch
              ? dispatch(setClientsRenameSearchInstanceAction({ id, name, clientId }))
              : dispatch(setRenameSearchInstanceAction({ id, name }));
            showSuccessMessage('Search Instance Renamed Successfully');
          }
          onCloseRenameModal();
        }),
      );
    } else {
      dispatch(
        renameSavedSearchEffect({ id, name }, {}, (err) => {
          if (!err) {
            dispatch(setRenameSavedSearchAction({ id, name }));
            showSuccessMessage('Saved Search Renamed Successfully');
          }
          onCloseRenameModal();
        }),
      );
    }
  };

  const getInactiveHandler = useCallback(
    (id) => () => {
      if (isClient || isClientSearch) {
        dispatch(
          deactivateSearchInstanceEffect({ id }, {}, (err) => {
            if (!err) {
              isClient && dispatch(getClientInstancesEffect());
              isClientSearch
                ? dispatch(
                    setClientsSearchInstanceStatusAction({ id, status: 'Inactive', clientId }),
                  )
                : dispatch(setSearchInstanceStatusAction({ id, status: 'Inactive' }));
              showSuccessMessage('Search Instance Deactivated');
            }
          }),
        );
      } else {
        dispatch(
          deactivateSavedSearchEffect({ id }, {}, (err) => {
            if (!err) {
              dispatch(setSavedSearchStatusAction({ id, status: 'Inactive' }));
              dispatch(getSavedSearchesEffect());
              showSuccessMessage('Saved Search Deactivated');
            }
          }),
        );
      }
    },
    [dispatch],
  );

  const getActivateHandler = useCallback(
    (id) => () => {
      if (isClient || isClientSearch) {
        dispatch(
          activateSearchInstanceEffect({ id }, {}, (err) => {
            if (!err) {
              isClient && dispatch(getClientInstancesEffect());
              isClientSearch
                ? dispatch(setClientsSearchInstanceStatusAction({ id, status: 'Active', clientId }))
                : dispatch(setSearchInstanceStatusAction({ id, status: 'Active' }));
              showSuccessMessage('Search Instance Activated');
            }
          }),
        );
      } else {
        dispatch(
          activateSavedSearchEffect({ id }, {}, (err) => {
            if (!err) {
              dispatch(setSavedSearchStatusAction({ id, status: 'Active' }));
              showSuccessMessage('Saved Search Activated');
            }
          }),
        );
      }
    },
    [dispatch],
  );

  return (
    <>
      <div className={styles.ClientActiveSearchBlock}>
        <h3>Active Searches</h3>
        {isLoading ? (
          <Row gutter={[30, 30]} className={styles.CardsContainer}>
            <Spinner loaderClassName={styles.loader} />
          </Row>
        ) : activeSearches?.length ? (
          activeSearches.map((search) => (
            <Row gutter={[30, 30]} className={styles.CardsContainer}>
              <SearchCard
                testid="active_search_block"
                photos={search.photos}
                key={search.id}
                title={search.name}
                updatedDate={search.UpdatedDate}
                agentName={search.agentName}
                status={search.status}
                info={search?.info}
                fullInfo={search?.fullInfo}
                footer={`${search.total || 'no'} results`}
                onDelete={null} //getDeleteHandler(search.id)
                onEdit={getEditHandler(search)}
                onRename={getRenameHandler(search.id)}
                onSearch={getSearchHandler({
                  ...search.criterias,
                  ...(isClientSearch || isClient
                    ? { searchInstanceId: search.id }
                    : { saveSearchId: search.id }),
                })}
                onClick={getSearchHandler({
                  ...search.criterias,
                  ...(isClientSearch || isClient
                    ? { searchInstanceId: search.id }
                    : { saveSearchId: search.id }),
                })}
                onInactive={getInactiveHandler(search.id)}
              />
            </Row>
          ))
        ) : (
          <div testid="no_active_instances" className={styles.noSaved}>
            No active {isClient ? 'instances' : 'searches'}
          </div>
        )}
      </div>
      <div className={styles.ClientInactiveSearchBlock}>
        <Accordion
          testid="inactive_searches_block"
          className={styles.accordion}
          applyDefaultContainerStyles={false}
          title={
            <p style={{ margin: '0px', textAlign: 'center' }}>
              View Inactive Searches
              <span className={styles.numberOfSearches}>{` (${inactiveSearches.length})`}</span>
            </p>
          }
          titleClassName={styles.inactiveTitle}
          allowOverflowWhenOpen={true}
        >
          {isLoading ? (
            <Row gutter={[30, 30]} className={styles.CardsContainer}>
              <Spinner loaderClassName={styles.loader} />
            </Row>
          ) : inactiveSearches?.length ? (
            inactiveSearches.map((search) => (
              <Row gutter={[30, 30]} className={styles.CardsContainer}>
                <SearchCard
                  testid="inactive_search_block"
                  photos={search.photos}
                  key={search.id}
                  title={search.name}
                  agentName={search.agentName}
                  status={search.status}
                  updatedDate={search.UpdatedDate}
                  info={search?.info}
                  fullInfo={search?.fullInfo}
                  footer={`Deactivated on ${moment(search.deactivatedOn || new Date()).format(
                    'M/DD/YYYY',
                  )}`}
                  onDelete={null}
                  onEdit={getEditHandler(search)}
                  onRename={getRenameHandler(search.id)}
                  onSearch={getSearchHandler({
                    ...search.criterias,
                    ...(isClientSearch || isClient
                      ? { searchInstanceId: search.id }
                      : { saveSearchId: search.id }),
                  })}
                  onClick={getSearchHandler({
                    ...search.criterias,
                    ...(isClientSearch || isClient
                      ? { searchInstanceId: search.id }
                      : { saveSearchId: search.id }),
                  })}
                  onActivate={getActivateHandler(search.id)}
                />
              </Row>
            ))
          ) : (
            <Col xs={24}>
              <div testid="no_inactive_instances" className={styles.noSaved}>
                No inactive {isClient ? 'instances' : 'searches'}
              </div>
            </Col>
          )}
        </Accordion>
      </div>
    </>
  );
};

export default SavedSearchesBlock;
