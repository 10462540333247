import { useState } from 'react';

import { Modal } from 'components-antd';
import { CompareByYear } from './CompareByYear';
import { CompareByMonth } from './CompareByMonth';

import styles from './styles.module.scss';

const CompareModal = ({ open, onClose }) => {
  const [showMonthsSelection, setShowMonthsSelection] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);

  return (
    <Modal width={576} className={styles.compareModal} open={open} footer={null} onCancel={onClose}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalHeader}>Compare Data</h2>
      </div>

      {!showMonthsSelection ? (
        <CompareByYear
          selectedYears={selectedYears}
          setSelectedYears={setSelectedYears}
          showMonthsScreen={() => setShowMonthsSelection(true)}
        />
      ) : (
        <CompareByMonth selectedMonths={selectedMonths} setSelectedMonths={setSelectedMonths} />
      )}
    </Modal>
  );
};

export default CompareModal;
