import React from 'react';
import classNames from 'classnames';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import styles from './styles.module.scss';

interface IAntCard extends CardProps {
  title?: string;
}

export const AntCard: React.FC<IAntCard> = (props) => {
  const { title, children, className } = props;
  return (
    <Card className={classNames(styles.auditLogCardWrapper, className)} title={title || ''}>
      {children}
    </Card>
  );
};
