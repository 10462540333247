import { createSelector } from 'reselect';
import { get } from 'lodash-es';

const localState = ({ adminPanel }) => adminPanel;

const serviceDirectoryState = createSelector(
  localState,
  ({ serviceDirectory }) => serviceDirectory,
);

const serviceTagsState = createSelector(serviceDirectoryState, ({ serviceTags }) => serviceTags);

const serviceTagState = createSelector(serviceDirectoryState, ({ serviceTag }) => serviceTag);

export const getServiceTagsSortSelector = createSelector(serviceTagsState, ({ sort }) => sort);

export const getServiceTagsSelector = createSelector(serviceTagsState, (state) => state);

export const getServiceTagSelector = createSelector(serviceTagState, (state) => state);

export const getLoadingServiceTagData = createSelector(
  serviceTagState,
  ({ state, data, meta }) => ({
    state,
    dataId: `${get(data, 'Id')}`,
    metaId: `${get(meta, 'id')}`,
  }),
);

export const getServiceTagsSearchSelector = createSelector(
  serviceTagsState,
  ({ search }) => search,
);
