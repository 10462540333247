import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Placeholder = ({ className }) => (
  <span className={classNames(styles.placeholder, className)}>-</span>
);

Placeholder.propTypes = {
  className: PropTypes.string,
};

Placeholder.defaultProps = {
  className: '',
};

export default Placeholder;
