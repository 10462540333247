import { handleActions } from 'redux-actions';

import {
  openMessagesDrawerAction,
  changeMessagesDrawerTypeAction,
} from 'store/actions/drawers/messages';
import { appLogoutAction } from 'store/actions/app';

import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';

const initialData = {
  open: false,
  type: DRAWER_MESSAGES_TYPES.INIT,
  params: undefined,
};

export default handleActions(
  {
    [openMessagesDrawerAction]: (state, { payload }) => ({
      ...initialData,
      type: payload.type || initialData.type,
      open: !!payload,
      params: payload.params || initialData.params,
    }),
    [changeMessagesDrawerTypeAction]: (state, { payload }) => ({
      ...state,
      type: payload.type || state.type,
      params: payload.params,
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
