import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const CardTitle = (props) => {
  const { className, children } = props;

  return (
    <div testid="card_title" className={classNames(styles.cardTitle, className)}>
      {children}
    </div>
  );
};

CardTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CardTitle.defaultProps = {
  className: '',
};

export default CardTitle;
