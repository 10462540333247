import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { appOpenModalEffect } from 'store/effects/app';
import { getModalSelector } from 'store/selectors/app';
import { Modal, Answers } from 'components';
import { Switch } from 'components-antd';
import NextButton from 'pages/OnBoardingWizard/components/NextButton';
import { prefsIds } from 'settings/constants/preferences';

import styles from './styles.module.scss';

const OUTDOOR_SPACE_TYPES = [
  { id: prefsIds.outdoorYard, label: 'Backyard', value: 'OutdoorYard' },
  { id: prefsIds.balcony, label: 'Balcony', value: 'Balcony' },
  { id: prefsIds.deck, label: 'Deck', value: 'Deck' },
  { id: prefsIds.patio, label: 'Patio', value: 'Patio' },
  { id: prefsIds.roofDeck, label: 'Roof Deck', value: 'Roof Deck' },
  { id: prefsIds.terrace, label: 'Terrace', value: 'Terrace' },
  { id: prefsIds.pool, label: 'Pool', value: 'Pool' },
];

const OutdoorSpaceModal = ({ className, onNext, value }) => {
  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(value || []);
  const [fenced, setFenced] = useState(value.includes(prefsIds.fencedYard));
  const [answerCases, setAnswerCases] = useState(OUTDOOR_SPACE_TYPES);

  const onSwitchChange = (bool) => {
    const answers = [...answerCases];
    answers[0] = {
      id: bool ? prefsIds.fencedYard : prefsIds.outdoorYard,
      label: 'Backyard',
      value: bool ? 'FencedYard' : 'OutdoorYard',
    };
    setAnswerCases(answers);
    setFenced(bool);

    const selectedValues = [...selected];
    const index = selectedValues.findIndex(
      (option) => option === (bool ? prefsIds.outdoorYard : prefsIds.fencedYard),
    );
    selectedValues[index] = bool ? prefsIds.fencedYard : prefsIds.outdoorYard;
    setSelected(selectedValues);
  };

  useEffect(() => {
    if (value.includes(prefsIds.fencedYard)) onSwitchChange(true);
    setSelected(value);
  }, [value]);

  if (modal.id !== OutdoorSpaceModal.id) {
    return null;
  }

  const onClose = () => {
    onNext(selected);
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  const onNextHandler = () => {
    onClose();
  };

  const subOption = (id) => {
    if (
      [prefsIds.outdoorYard, prefsIds.fencedYard].includes(id) &&
      selected.some((val) => [prefsIds.outdoorYard, prefsIds.fencedYard].includes(val))
    ) {
      return (
        <div className={styles.backyardToggle}>
          <p>Must be fenced?</p>
          <Switch checked={fenced} onChange={onSwitchChange} />
        </div>
      );
    }

    return <></>;
  };

  return (
    <Modal
      className={classNames(className)}
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      closeClassName={styles.close}
      innerHolderClassName={styles.innerHolder}
    >
      <h2 className={styles.title}>What type of outdoor space are you looking for?</h2>
      <Answers
        multiple
        unselectable
        className={styles.answersWrapper}
        caseClassName={styles.option}
        cases={answerCases}
        onChange={(val) => setSelected(val)}
        value={selected}
        testid="exterior_answers"
        variant={Answers.CHECKBOX}
        subOption={subOption}
      />
      <NextButton onClick={onNextHandler} className={styles.next} />
    </Modal>
  );
};

OutdoorSpaceModal.id = 'exteriorOutdoorSpaceModal';

OutdoorSpaceModal.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  value: PropTypes.array,
};

OutdoorSpaceModal.defaultProps = {
  className: '',
  onNext: () => {},
  value: [],
};

export default OutdoorSpaceModal;
