import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';

import { PENDING } from 'settings/constants/apiState';

import { onBoardingSignUpEffect } from 'store/effects';
import { getUserDataSelector, isPartnerSelector } from 'store/selectors/user';

import { ValidationSchema } from './validation';

import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';

import { onAreasOfOperationChange } from 'utils';
import { OnboardingContinueButton } from '../../agent/components/OnboardingContinueButton';
import { AreasOfOperationFieldInput } from './AreasOfOperationFieldInput';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  onNext?: () => void;
  stageIndex?: number;
  Controls?: any;
};

export const AreasServedForm: React.FC<Props> = ({
  className = '',
  onNext = () => {},
  stageIndex,
  Controls = () => null,
}) => {
  const dispatch = useDispatch();
  const { onBoarding, signUpRole } = useSelector(getOnBoardingData);
  const isPending = onBoarding.state === PENDING;
  const user = useSelector(getUserDataSelector);
  const isPartner = useSelector(isPartnerSelector);

  const onSubmit = useCallback(
    (values) => {
      const areasOfOperation = values.locations
        .map((location) => ({ ...location.AreasOfOperation, Radius: location.Radius }))
        .flat();
      dispatch(
        onBoardingSignUpEffect(
          { areasOfOperation, stageIndex },
          { isCommonInfo: true, isPartner },
          (err) => {
            if (!err) {
              onNext();
            }
          },
        ),
      );
    },
    [stageIndex, onNext, dispatch],
  );

  const initialValues = useMemo(() => {
    const areas = user?.[signUpRole]?.Partner?.AreasOfOperation || [];
    // Return atleast 1 intial value to render
    if (!areas.length) return [{ AreasOfOperation: '', Radius: '' }];
    return areas.map((area) => {
      const { Radius, ...rest } = area;
      return { AreasOfOperation: rest, Radius: Radius };
    });
  }, [user]);
  return (
    <OnBoardingWrapper className={styles.stageWrap}>
      <Formik
        initialValues={
          {
            locations: initialValues,
          } as { locations: { AreasOfOperation: any; Radius: string }[] }
        }
        validationSchema={ValidationSchema}
        validateOnChange={true}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          values,
          touched,
          errors,
          handleChange,
          isValid,
          submitForm,
          setFieldValue,
        }) => (
          <>
            <Controls onNext={() => submitForm()} className={styles.controls} variant="lightFull" />
            <OnBoardingContainer>
              <Question>
                <p className={styles.title}>What area(s) do you work in/offer your services in?</p>
              </Question>
              <form onSubmit={handleSubmit}>
                <AnswersContainer>
                  <AreasOfOperationFieldInput
                    name="locations"
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    onAreasOfOperationChange={onAreasOfOperationChange}
                    isPending={isPending}
                  />
                </AnswersContainer>
                <ButtonsContainer>
                  <OnboardingContinueButton
                    htmlType="submit"
                    testid="next"
                    isPending={isPending}
                    className={styles.submitBtn}
                    disabled={!isValid}
                  />
                  {typeof errors?.locations === 'string' && (
                    <div className={styles.errorContainer}>{errors.locations}</div>
                  )}
                </ButtonsContainer>
              </form>
            </OnBoardingContainer>
          </>
        )}
      </Formik>
    </OnBoardingWrapper>
  );
};
