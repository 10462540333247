import { Row, Col, Select } from 'antd';
import classNames from 'classnames';
import { Option } from 'components-antd';
import { ArrowDown, ArrowRight } from 'components/Icons';
import styles from './styles.module.scss';
import {
  ClarityActivityType,
  ClarityCriterias,
  ClarityVisibilityDuration,
} from 'settings/constants/clarity';
import { ArrowRightOutlined } from '@ant-design/icons';
export const ViewSelector = () => {
  return (
    <Row className={classNames(styles.workshopHeader)} align="middle" justify="center">
      <Col xs={24} lg={24} className={styles.viewSelectorText} align="middle">
        <div>
          <p>I want to analyze</p>
          <Select
            className={styles.viewSelectorSelects}
            // defaultValue={ClarityActivityType['noReportsAvailable']}
            onChange={(val) => {
              //Handle change event
            }}
            bordered={false}
            suffixIcon={<ArrowDown />}
            //getPopupContainer={(triggerNode) => triggerNode}
            listHeight={364}
          >
            {Object.keys(ClarityActivityType).map((activity) => (
              <Option key={activity} value={activity}>
                {ClarityActivityType[activity]}
              </Option>
            ))}
          </Select>
        </div>
        {/*
        <div>
          <p>Show me </p>
          <Select
            defaultValue={ClarityCriterias['kitsPerformance']}
            className={styles.viewSelectorSelects}
            onChange={(val) => {
              //Handle change event
            }}
            bordered={false}
            suffixIcon={<ArrowDown />}
            //getPopupContainer={(triggerNode) => triggerNode}
            listHeight={364}
          >
            {Object.keys(ClarityCriterias).map((criteriaKey) => (
              <Option key={criteriaKey} value={criteriaKey}>
                {ClarityCriterias[criteriaKey]}
              </Option>
            ))}
          </Select>
        </div>
        */}
        {/*
        <div>
          <p>Over the last</p>
          <Select
            defaultValue={ClarityVisibilityDuration['sixtyDays']}
            className={styles.viewSelectorSelects}
            onChange={(val) => {
              //Handle change event
            }}
            bordered={false}
            suffixIcon={<ArrowDown />}
            //getPopupContainer={(triggerNode) => triggerNode}
            listHeight={364}
          >
            {Object.keys(ClarityVisibilityDuration).map((duration) => (
              <Option key={duration} value={duration}>
                {ClarityVisibilityDuration[duration]}
              </Option>
            ))}
          </Select>
          <div className={classNames(styles.iconContainer)}>
            <button testid="expand_collapse" className={styles.button} onClick={() => {}}>
              <ArrowRightOutlined />
            </button>
          </div>
        </div>
        */}
      </Col>
    </Row>
  );
};
