import React from 'react';
import classNames from 'classnames';

import BaseNotification from '../../../BaseNotification';
import PropertyIcon from '../../../PropertyIcon';

import styles from './styles.module.scss';

interface MegaphoneOffersNotificationProps {
  Ids: number[];
  className: string;
  title: string;
  IsRead: boolean;
  CreatedDate: string;
  groupType: string;
  SeverityLevel: string;
}

const MegaphoneOffersNotification = (props: MegaphoneOffersNotificationProps) => {
  const { className = '', Ids, IsRead, CreatedDate, groupType, title, SeverityLevel } = props;

  return (
    <div className={classNames(styles.notificationMegaphoneOffersNotification, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        groupType={groupType}
        avatarPlaceholder={<PropertyIcon />}
        severityLevel={SeverityLevel}
      />
    </div>
  );
};

export default MegaphoneOffersNotification;
