import { createSelector } from 'reselect';
import { ERROR, IDLE, PENDING } from 'settings/constants/apiState';
import { get, map } from 'lodash-es';
import { getUserId } from '../user';
import {
  getTransactionEffectiveDate,
  getTransactionClosingDate,
  getTransactionPrice,
} from 'utils/keyDateHelpers';
import { getDateOnly } from 'helpers';

const localState = ({ transaction }) => transaction;
const transactionsState = ({ transactions }) => transactions?.data;

export const getTransactionSelector = createSelector(
  localState,
  getUserId,
  (transactionState, userId) => {
    const transaction = transactionState?.data;

    const keyDatesPrices =
      get(transaction, 'PropertyTransactionKeyDatesPrices') ||
      get(transaction, 'ProjectKeyDates') ||
      get(transaction, 'data.PropertyTransactionKeyDatesPrices') ||
      get(transaction, 'data.ProjectKeyDates');
    return {
      isIdle: transactionState.state === IDLE,
      isPending: transactionState.state === PENDING,
      isError: transactionState.state === ERROR,
      isData: !!transaction,
      isUpdatePending: transactionState.updateState === PENDING,
      transaction: {
        ...(transaction || {}),
        Participants: (transaction?.Participants || []).filter(
          (participant) => participant?.Id !== userId && participant?.ParticipantId !== userId,
        ),
        User:
          (transaction?.Participants || []).filter(
            (participant) => participant?.Id === userId,
          )?.[0] || {},
        Notes: transactionState?.data?.Notes,
        userId,
        ClosePrice: keyDatesPrices?.ClosePrice,
        Price: getTransactionPrice(transaction?.Status, keyDatesPrices, false),
        EffectiveDate: getTransactionEffectiveDate(keyDatesPrices, transaction?.IsProject),
        ListingExpireDate: getDateOnly(keyDatesPrices?.ListingExpireDate),
        ClosingDate: getTransactionClosingDate(keyDatesPrices, transaction?.IsProject),
        PropertyType: keyDatesPrices?.PropertyType?.Name,
        CloseDate: keyDatesPrices?.CloseDate,
      },
      status: transactionState?.meta?.status,
      isCreator: userId === transaction?.Creator?.Id,
      address: transactionState?.address,
      allTransactionFormProcesses: transactionState.allTransactionFormProcesses,
      clientAddress: {
        ...transactionState.clientAddress,
        isPending: transactionState.clientAddress.state === PENDING,
      },
      contacts: transactionState.contacts,
      details: transactionState.details,
      stats: transactionState.stats,
    };
  },
);

export const getTasksForCancelledTransactionFrSelector = createSelector(
  localState,
  (transactionState, userId) => {
    const transactionCancellationTasks = transactionState?.cancellationTasks;

    return {
      tasks: transactionCancellationTasks,
    };
  },
);

export const getTransactionDetailsSelector = createSelector(localState, ({ details }) => details);
export const getKeyDatesSelector = createSelector(localState, ({ keyDates }) => keyDates);
export const getFinancialPricingSelector = createSelector(localState, ({ financialPricing }) => {
  return financialPricing;
});
export const getTransactionAccessSelector = createSelector(
  localState,
  getUserId,
  (transactionState, userId) => {
    const transaction = transactionState?.data;

    return {
      fullAccess:
        userId === transaction?.Creator?.Id ||
        (transaction?.Participants || []).find((p) => p?.Id === userId)?.TransactionAccess,
    };
  },
);

export const getTransactionAccessByTaskSelector = createSelector(
  localState,
  getUserId,
  transactionsState,
  (transactionState, userId, transactions) => {
    const currentTransaction = transactionState?.data;
    return {
      hasFullAccess: (transactionId) => {
        const transaction =
          currentTransaction?.Id === transactionId
            ? currentTransaction
            : transactions?.find((val) => val.Id === transactionId);

        let hasPermissions =
          userId === transaction?.Creator?.Id ||
          (transaction?.Participants || []).find((p) => p?.Id === userId)?.TransactionAccess;
        const isCc = transaction?.Tasks?.some((task) =>
          task?.CcList?.some((cc) => cc?.Id === userId),
        );
        if (isCc) {
          hasPermissions = false;
        }

        return hasPermissions;
      },
    };
  },
);

export const getTranslationAdminAccessSelector = createSelector(localState, (transactionState) => {
  const transaction = transactionState?.data;
  return {
    isTransactionAdminOrOwner: transaction?.IsTransactionAdminOrOwner
      ? transaction?.IsTransactionAdminOrOwner
      : false,
  };
});

export const getAdminOwnerAccessSelector = createSelector(localState, (transactionState) => {
  const transaction = transactionState?.data;
  return {
    isAdminOrOwner: transaction?.IsAdminOrOwner ? transaction?.IsAdminOrOwner : false,
  };
});

export const getTransactionParticipantsSelector = createSelector(
  getTransactionSelector,
  ({ transaction }) => ({
    transactionParticipants: transaction?.Participants || [],
    userParticipant: transaction?.User || {},
  }),
);

export const getAllTransactionParticipantsSelector = createSelector(
  localState,
  (transactionState) => transactionState?.data?.Participants || [],
);

export const getTransactionParticipantSelector = createSelector(
  localState,
  getUserId,
  (transactionState, userId) => {
    const transaction = transactionState?.data;

    return (transaction?.Participants || []).find((p) => p?.Id === userId);
  },
);

export const getTransactionParticipantsFormattedList = createSelector(
  getTransactionParticipantsSelector,
  ({ transactionParticipants }) =>
    map(transactionParticipants, (item = {}) => {
      const role = get(item, 'Role.Title');

      return {
        id: item?.Id,
        name: `${item?.FirstName} ${item?.LastName}$/$${item?.Email}`, // don't remove $/$ separator
        firstName: item?.FirstName,
        lastName: item?.LastName,
        role,
        avatarUrl: item?.AvatarUrl,
        email: item?.Email,
      };
    }),
);

export const getTransactionParticipantsContactsList = createSelector(
  getTransactionParticipantsSelector,
  ({ transactionParticipants }) =>
    map(transactionParticipants, (item = {}) => {
      const role = get(item, 'Role.Title');

      return {
        Type: 'Contact',
        Id: item?.Id,
        FirstName: item?.FirstName,
        LastName: item?.LastName,
        ContactUserId: item?.Id,
        Email: item?.Email,
        SearchableName: `${item?.FirstName} ${item?.LastName} ${item?.Email}`,
        Source: 'Transaction',
        SourceId: null,
        Role: role,
      };
    }).filter((entities) => !!entities.FirstName),
);

export const getTransactionDateControlsSelector = createSelector(localState, (transactionState) => {
  const transaction = transactionState?.data;
  const keyDatesPrices =
    transaction?.PropertyTransactionKeyDatesPrices || transaction?.ProjectKeyDates;
  return {
    ...(transaction?.DateControls || {}),
    T: new Date(),
    CD: getTransactionEffectiveDate(keyDatesPrices, transaction?.IsProject) || null,
    CL: getTransactionClosingDate(keyDatesPrices, transaction?.IsProject) || null,
    LE: keyDatesPrices?.ListingExpireDate || null,
    TL: transaction?.PropertyTransactionKeyDatesPrices?.TargetLiveDate || null,
  };
});

export const getTransactionContactsSelector = createSelector(
  localState,
  ({ contacts }) => contacts || [],
);
