import { useState } from 'react';

import { Select } from 'antd';
import { Tooltip, Checkbox } from 'components-antd';
import { EditLinkDuration } from '../components';
import { Navigation } from '../../components';
import { showErrorMessage } from 'helpers/errors';
import { Icons } from '../../Icons';

import styles from './styles.module.scss';
import { isEmail } from 'utils';

const { Option } = Select;

export const Parties = ({
  onNext,
  stageIndex,
  onPrev,
  onUpdate,
  onFinish,
  setCurrentStageIndex,
  data,
  transaction,
  fullAccess,
  participantList,
}) => {
  const { InvolveSellerAgent, InvolveSeller, FYIList, DelegateList } = data;
  const [state, setState] = useState({
    InvolveSellerAgent,
    InvolveSeller,
    FYI: !!FYIList.length,
    Delegates: !!DelegateList.length,
    FYIList: FYIList || [],
    DelegateList: DelegateList || [],
  });
  const [disabled, setDisabled] = useState(false);
  const [disclaimerFYI, setDisclaimerFYI] = useState(false);
  const [disclaimerDelegate, setDisclaimerDelegate] = useState(false);

  const creator = transaction?.Creator;
  const seller = transaction?.Participants?.find((p) => p?.Role?.Title === 'Seller');

  const renderList = (obj, filtered = false) => {
    const list: any = [];
    participantList?.forEach((p) => {
      if (filtered && (p.Id === creator?.Id || p.Id === seller?.Id)) return;

      const index = obj?.findIndex((f) => f?.UserId === p.Id);
      if (index === -1) {
        list.push(
          <Option key={p.Id} value={p.Email}>
            {p.FirstName} {p.LastName}
          </Option>,
        );
      }
    });

    return list;
  };

  const onChange = (data) => {
    setState({ ...state, ...data });
  };

  const onSubmit = () => {
    const body = {
      InvolveSellerAgent: state.InvolveSellerAgent,
      InvolveSeller: state.InvolveSeller,
      FYIList: state.FYI ? state.FYIList : [],
      DelegateList: state.Delegates ? state.DelegateList : [],
    };
    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(6);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const handleChange = (emails, key) => {
    setDisabled(false);
    setState({
      ...state,
      [key]: emails.map((e) => {
        if (isEmail(e) === null) {
          const participant = participantList.find((p) => `${p.FirstName} ${p.LastName}` === e);
          if (!participant) {
            setDisabled(true);
            showErrorMessage('Invalid Email');
          }
        }
        const participant = participantList.find(
          (p) => p.Email === e || `${p.FirstName} ${p.LastName}` === e,
        );
        return participant
          ? {
              UserId: participant.Id,
              Email: participant.Email,
              FirstName: participant.FirstName,
              LastName: participant.LastName,
            }
          : { Email: e };
      }),
    });
  };

  const getCurrentValues = (key) => {
    const values = state[key];
    return values?.map((v) => {
      const participant = participantList.find((p) => p.Id === v.UserId);
      return participant ? `${participant.FirstName} ${participant.LastName}` : v.Email;
    });
  };

  return (
    <div className={styles.parties}>
      <EditLinkDuration data={data} onEdit={setCurrentStageIndex} />

      <div className={styles.content}>
        <h4 className={styles.title}>Parties:</h4>
        {fullAccess ? (
          <div className={styles.container}>
            <Checkbox
              checked={state.InvolveSellerAgent}
              defaultChecked={state.InvolveSellerAgent}
              onChange={() => onChange({ InvolveSellerAgent: !state.InvolveSellerAgent })}
            >
              {creator?.FirstName} {creator?.LastName} (Agent)
            </Checkbox>
          </div>
        ) : null}
        {seller ? (
          <div className={styles.container}>
            <Checkbox
              checked={state.InvolveSeller}
              defaultChecked={state.InvolveSeller}
              onChange={() => onChange({ InvolveSeller: !state.InvolveSeller })}
            >
              {seller?.FirstName || seller?.Email} {seller?.LastName} (Seller)
            </Checkbox>
          </div>
        ) : null}
        <div className={styles.container}>
          <Checkbox
            checked={state.FYI}
            defaultChecked={state.FYI}
            onChange={() => onChange({ FYI: !state.FYI })}
          >
            FYI
          </Checkbox>
          <Tooltip
            visible={disclaimerFYI}
            title={
              <span>
                People in this role will receive notifications of confirmed showings, cancelled
                showings, etc., but won’t be able to approve, reschedule, cancel, or decline
              </span>
            }
            overlayInnerStyle={{ borderRadius: '1rem', padding: '1rem', minWidth: '17rem' }}
            color="#262626"
          >
            <div
              onMouseEnter={() => setDisclaimerFYI(true)}
              onMouseLeave={() => setDisclaimerFYI(false)}
              className={styles.infoIcon}
            >
              <Icons variant={Icons.INFO} />
            </div>
          </Tooltip>
          {state.FYI && (
            <div className={styles.selectorContainer}>
              <Select
                mode="tags"
                className={styles.selector}
                onChange={(v) => handleChange(v, 'FYIList')}
                value={getCurrentValues('FYIList')}
              >
                {renderList(state.FYIList)}
              </Select>
            </div>
          )}
        </div>
        <div className={styles.container}>
          <Checkbox
            checked={state.Delegates}
            defaultChecked={state.Delegates}
            onChange={() => onChange({ Delegates: !state.Delegates })}
          >
            Delegates
          </Checkbox>
          <Tooltip
            visible={disclaimerDelegate}
            title={
              <span>
                Someone other than the agent or seller who will need to approve/reject/cancel
                showings, such as the agent’s assistant or a renter/occupant
              </span>
            }
            overlayInnerStyle={{ borderRadius: '1rem', padding: '1rem', minWidth: '17rem' }}
            color="#262626"
          >
            <div
              onMouseEnter={() => setDisclaimerDelegate(true)}
              onMouseLeave={() => setDisclaimerDelegate(false)}
              className={styles.infoIcon}
            >
              <Icons variant={Icons.INFO} />
            </div>
          </Tooltip>
          {state.Delegates && (
            <div className={styles.selectorContainer}>
              <Select
                mode="tags"
                className={styles.selector}
                onChange={(v) => handleChange(v, 'DelegateList')}
                value={getCurrentValues('DelegateList')}
              >
                {renderList(state.DelegateList, true)}
              </Select>
            </div>
          )}
        </div>
      </div>
      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onPrev={onPrev}
        onFinish={onFinish}
        disabled={disabled}
        className={styles.footer}
      />
    </div>
  );
};
