export const initialErrorState = {
  BuyerNameError: '',
  Buyer2NameError: '',
  PurchasePriceError: '',
  ClosingDateError: '',
  FinancingTypeError: '',
  TermiteInspectionError: '',
  HomeWarrantyCostError: '',
  HomeWarrantyResponsibleError: '',
  OccupancyError: '',
};

const breakpoint1State = {
  Buyer: '',
  Buyer2: '',
  OfferIteration: {
    PurchasePrice: '',
    DownPayment: '',
    EarnestMoneyDeposit: '',
    ClosingDate: null,
    FinancingType: null,
  },
};

const breakpoint2State = {
  Contingencies: {
    Inspection: {
      days: null,
      selected: false,
    },
    Appraisal: {
      days: null,
      selected: false,
    },
    Financing: {
      days: null,
      selected: false,
    },
  },
};

const breakpoint3State = {
  TermiteInspection: false,
  TermiteInspectionResponsibility: null,
  HomeWarranty: false,
  HomeWarrantyCost: '',
  HomeWarrantyResponsibility: null,
  Appliances: '',
  Occupancy: false,
  OccupancyDays: null,
};

const breakpoint4State = {
  Notes: '',
};

const resetBreakpoint1 = (state) => {
  const { OfferIteration, ...rest } = breakpoint1State;
  return { ...rest, OfferIteration: { ...state.OfferIteration, ...OfferIteration } };
};

const resetBreakpoint2 = (state, showAll) => {
  const { OfferIteration: stateOfferIteration, ...restState } = state;
  if (!showAll) {
    return { ...restState, OfferIteration: { ...stateOfferIteration, ...breakpoint2State } };
  } else {
    const { OfferIteration: breakpoint1Iteration, ...rest } = breakpoint1State;
    return {
      ...rest,
      OfferIteration: { ...stateOfferIteration, ...breakpoint1Iteration, ...breakpoint2State },
    };
  }
};

const resetBreakpoint3 = (state, showAll) => {
  const { OfferIteration: stateOfferIteration, ...restState } = state;
  if (!showAll) {
    return { ...restState, OfferIteration: { ...stateOfferIteration, ...breakpoint3State } };
  } else {
    const { OfferIteration: breakpoint1Iteration, ...rest } = breakpoint1State;
    return {
      ...rest,
      OfferIteration: {
        ...stateOfferIteration,
        ...breakpoint1Iteration,
        ...breakpoint2State,
        ...breakpoint3State,
      },
    };
  }
};

const resetBreakpoint4 = (state, showAll) => {
  const { OfferIteration: stateOfferIteration, ...restState } = state;
  if (!showAll) {
    return { ...restState, OfferIteration: { ...stateOfferIteration, ...breakpoint4State } };
  } else {
    const { OfferIteration: breakpoint1Iteration, ...rest } = breakpoint1State;
    return {
      ...rest,
      OfferIteration: {
        ...stateOfferIteration,
        ...breakpoint1Iteration,
        ...breakpoint2State,
        ...breakpoint3State,
        ...breakpoint4State,
      },
    };
  }
};

export const getResetState = (initialState, breakpoint, showAll) => {
  const resetStateActions = {
    1: resetBreakpoint1,
    2: resetBreakpoint2,
    3: resetBreakpoint3,
    4: resetBreakpoint4,
  };

  return resetStateActions[breakpoint](initialState, showAll);
};

export const financingType = [
  {
    label: 'Conventional',
    value: 'Conventional',
  },
  {
    label: 'Nonconforming',
    value: 'NonConforming',
  },
  {
    label: 'FHA',
    value: 'FHA',
  },
  {
    label: 'VA',
    value: 'VA',
  },
  {
    label: 'Cash',
    value: 'Cash',
  },
];

export const responsibleParty = [
  {
    label: 'Buyer',
    value: 'Buyer',
  },
  {
    label: 'Seller',
    value: 'Seller',
  },
];

export const isValidAplhabets = (value: string): boolean => {
  const regex = /^[a-zA-Z ]*$/;
  return regex.test(value);
};
