import { useEffect, useRef, useState } from 'react';
import { Row, Col, Checkbox } from 'components-antd';

import { EditAvailability, EditNotify, EditDocuments, EditNotes } from '../components';
import { Navigation } from '../../components';

import styles from './styles.module.scss';
import { Icons } from '../../Icons';

export const Feedback = ({
  onNext,
  stageIndex,
  onPrev,
  onUpdate,
  onFinish,
  setCurrentStageIndex,
  data,
  transaction,
  stagesStep,
}) => {
  const { RequestFeedback, AutomaticallySendFeedback } = data;
  const [state, setState] = useState({
    RequestFeedback,
    AutomaticallySendFeedback,
  });

  const onChange = (data) => {
    setState({ ...state, ...data });
  };

  const onSubmit = () => {
    const body = { ...state };
    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(stagesStep.EnableLink);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const bottomRef: any = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [stageIndex]);

  return (
    <div className={styles.notes}>
      <div className={styles.contentWrap}>
        <EditAvailability
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.Availability)}
        />
        <EditNotify data={data} onEdit={() => setCurrentStageIndex(stagesStep.Notify)} />
        <EditDocuments data={data} onEdit={() => setCurrentStageIndex(stagesStep.Documents)} />
        <EditNotes data={data} onEdit={() => setCurrentStageIndex(stagesStep.Notes)} />

        <div ref={bottomRef} className={styles.content}>
          <h4 className={styles.title}>
            <Icons className={styles.feedbackIcon} variant="feedback" /> Feedback
          </h4>
          <div className={styles.checkBoxWrapper}>
            <Row className={styles.container}>
              <Col span={24} className={styles.checkBox}>
                <Checkbox
                  checked={state.RequestFeedback}
                  onChange={({ target: { checked } }) =>
                    onChange({
                      RequestFeedback: checked,
                      AutomaticallySendFeedback: false,
                    })
                  }
                  defaultChecked={state.RequestFeedback}
                  className={styles.checkboxSquare}
                >
                  Request Feedback
                </Checkbox>
              </Col>
            </Row>
            <Row className={styles.container}>
              <Col span={24} className={styles.checkBox}>
                <Checkbox
                  checked={state.AutomaticallySendFeedback}
                  onChange={(e) => onChange({ AutomaticallySendFeedback: e.target.checked })}
                  defaultChecked={state.AutomaticallySendFeedback}
                  className={styles.checkboxSquare}
                  disabled={!state.RequestFeedback}
                >
                  Send feedback to seller(s) automatically
                </Checkbox>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onPrev={onPrev}
        onFinish={onFinish}
        className={styles.footer}
        showBackButton={false}
      />
    </div>
  );
};
