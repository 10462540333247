import { useDrop } from 'react-dnd';
import { Page } from 'react-pdf';
import classNames from 'classnames';
import { useRef } from 'react';

import { PDFEditorFieldRenderer } from '..';
import { PDFEditorFieldType, PDFEditorPagesType } from 'types';
import { PDF_FIELD_TYPE } from 'app-constants';

import styles from './styles.module.scss';

interface PDFEditorPageProps {
  pageIndex: string;
  scale: number;
  pages: PDFEditorPagesType;
  allFields: PDFEditorFieldType[];
  handleFieldUpdate: (...props) => void;
  handleFieldAdd: ({ x, y, pageIndex, item }) => void;
  highlighted?: PDFEditorFieldType;
  setHighlighted: (field) => void;
  disabled: boolean;
}

export const PDFEditorPage = ({
  pageIndex,
  pages,
  scale,
  handleFieldUpdate,
  handleFieldAdd,
  highlighted,
  setHighlighted,
  allFields,
  disabled,
}: PDFEditorPageProps) => {
  const ref = useRef<HTMLSpanElement>(null);

  const [{ isOver }, dropRef] = useDrop({
    accept: [
      PDF_FIELD_TYPE.PDFTextField,
      PDF_FIELD_TYPE.PDFSignature,
      PDF_FIELD_TYPE.PDFRadioGroup,
      PDF_FIELD_TYPE.PDFCheckBox,
    ],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item, monitor) => {
      const offset = monitor.getSourceClientOffset();

      if (ref.current && offset) {
        const dropTargetXy = ref.current.getBoundingClientRect();

        const x = (offset.x - dropTargetXy.left) / scale;
        const y = (offset.y - dropTargetXy.top) / scale;

        handleFieldAdd({ x, y, pageIndex, item });
      }
    },
  });

  return (
    <Page
      pageNumber={parseInt(pageIndex) + 1}
      renderInteractiveForms={false}
      renderAnnotationLayer={false}
      scale={scale}
      renderTextLayer={false}
      height={pages[pageIndex].height}
      width={pages[pageIndex].width}
      className={classNames(styles.documentPage, { [styles.droppablePage]: isOver })}
    >
      <div
        ref={dropRef}
        className={styles.pageContainer}
        style={{
          height: pages[pageIndex].height * scale,
          width: pages[pageIndex].width * scale,
        }}
      >
        <span style={{ display: 'block', width: '100%', height: '100%' }} ref={ref}>
          {allFields?.length ? (
            allFields.map((f) =>
              !f.isDeleted ? (
                <PDFEditorFieldRenderer
                  scale={scale}
                  field={f}
                  key={`PDFEditor-field-${f.id}-${f.widgetId}`}
                  onFieldUpdate={handleFieldUpdate}
                  highlighted={highlighted}
                  setHighlighted={setHighlighted}
                  disabled={disabled}
                />
              ) : (
                <></>
              ),
            )
          ) : (
            <></>
          )}
        </span>
      </div>
    </Page>
  );
};
