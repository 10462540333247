import { useState } from 'react';
import classNames from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';
import { updateTransactionEffect } from 'store/effects/transactions';
import { TransactionFinancingType } from 'app-constants';

import { Edit } from 'components/Icons';
import { Select } from 'components';
import { Button } from 'components-antd';

import commonStyles from './../styles.module.scss';
import styles from './styles.module.scss';
import { listedStatusesArray } from 'settings/constants/transaction';

const financingTypeOptions = Object.values(TransactionFinancingType).map((type) => ({
  value: type,
  name: type,
}));

export const FinancingType = ({ isDisableField, setEditDetailId }) => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [isEdit, setIsEdit] = useState(false);
  const [hover, setHover] = useState(false);
  const [financingType, setFinancingType] = useState(transaction?.FinancingType);
  const editKey = 'financingType';

  const onEdit = () => {
    if (!fullAccess || isDisableField(editKey)) return null;
    setIsEdit(true);
    setEditDetailId(editKey);
  };

  const updateFinancingType = () => {
    setIsPending(true);
    dispatch(
      updateTransactionEffect(
        {
          id: transaction?.Id,
          FinancingType: financingType,
        },
        {},
        (err) => {
          if (!err) {
            setIsEdit(false);
          }
          setIsPending(false);
          setEditDetailId(undefined);
        },
      ),
    );
  };

  const onCancel = () => {
    setIsEdit(false);
    setEditDetailId(undefined);
    setFinancingType(transaction?.FinancingType);
  };

  return (
    <>
      {!listedStatusesArray.includes(transaction?.Status) && (
        <>
          {!isEdit ? (
            <div
              className={classNames(commonStyles.cardWrapper, {
                [commonStyles.disabledSection]: !!isDisableField(editKey),
              })}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onClick={onEdit}
            >
              <div className={commonStyles.fieldContainer}>
                <p className={commonStyles.label}>Financing Type</p>
                <span className={commonStyles.value}>{financingType ?? ''}</span>
              </div>
              {fullAccess && !isDisableField(editKey) && (
                <Edit
                  className={classNames(commonStyles.editIcon, { [commonStyles.hideIcon]: !hover })}
                  stroke="#262626"
                />
              )}
            </div>
          ) : (
            <div className={commonStyles.editWrapper}>
              <Select
                label={'Financing Type'}
                value={financingType}
                onSelect={(_, { value }) => setFinancingType(value)}
                options={financingTypeOptions}
                className={{
                  wrapper: styles.financingTypeWrapper,
                }}
                placeholder={'Choose financing type'}
                isArrowIcon={true}
                variant={Select.LIGHT_ROUND}
              />
              <div className={commonStyles.buttons}>
                <Button
                  variant="secondary"
                  size="large"
                  disabled={transaction?.FinancingType === financingType}
                  onClick={updateFinancingType}
                  loading={isPending}
                >
                  Update
                </Button>
                <Button variant="hollow-bordered" size="large" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
