import { Row, Col, Space } from 'components-antd';
import { UnreadMessages } from './components/UnreadMessages';
import { TasksWidget } from './components/TasksWidget';
import { QuickAccess } from './components/QuickAccess';

import styles from './styles.module.scss';
import { QuoteWidget } from './components/QuoteWidget';
import { QuoteRequestWidget } from './components/QuoteRequestWidget';

export const PartnerDashboard = () => {
  return (
    <div className={styles.mainContentWrapper}>
      <header className={styles.dashboardHeader}>
        <h1 className={styles.heading}>Dashboard</h1>
      </header>
      <div className={styles.dashboardCardArea}>
        <Space direction="vertical" size="middle" className={styles.quoteSpace}>
          <Row justify="space-around" align="top" gutter={24}>
            <Col xs={24} md={12} lg={8}>
              <Space direction="vertical" size="large" className={styles.quoteSpace}>
                {process.env.NODE_ENV === 'development' && <QuoteWidget />}
                <UnreadMessages />
              </Space>
            </Col>
            {process.env.NODE_ENV === 'development' && (
              <Col xs={24} md={12} lg={8}>
                <QuoteRequestWidget />
              </Col>
            )}
            <Col xs={24} md={12} lg={8}>
              <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                <TasksWidget />
                <QuickAccess />
              </Space>
            </Col>
          </Row>
        </Space>
      </div>
    </div>
  );
};
