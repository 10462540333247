import moment from 'moment';
import PropTypes from 'prop-types';
import { Dropdown, Menu } from 'antd';
import fileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';
import { previewDocumentEffect } from 'store/effects/transactions';

import { Icons } from 'pages/Workshop/Icons';
import { getLocaleDate, showErrorMessage } from 'helpers';

import styles from './styles.module.scss';

export const Document = ({ document }) => {
  const dispatch = useDispatch();
  const onDownload = () => {
    dispatch(
      previewDocumentEffect(
        { DocumentVaultUUID: document.DocumentVaultUUID },
        {},
        (err, response) => {
          if (err) {
            return showErrorMessage(err);
          }

          const fileName = response.headers['file-name'];
          const getFileExtension = () => {
            if (fileName.endsWith('.pdf')) {
              return '.pdf';
            } else {
              return '';
            }
          };

          const fileExtension = getFileExtension();
          const fileTitle = document?.documentName.endsWith(fileExtension)
            ? document?.documentName
            : `${document?.documentName}${fileExtension}`;
          fileDownload(response.data, fileTitle || response.headers['file-name']);
        },
      ),
    );
  };

  const menu = (
    <Menu className={styles.preApprovalMenu}>
      <Menu.Item className={styles.action} onClick={onDownload}>
        Download
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.document}>
      <Icons className={styles.docIcon} variant="document" />
      <div className={styles.info}>
        <h4>{document.documentName}</h4>
        <p>
          Uploaded on: {moment(getLocaleDate(document.CreatedDate)).format('MM/DD/YYYY')}
          <span className={styles.time}>
            {' '}
            at {moment(getLocaleDate(document.CreatedDate)).format('hh:mm A')}
          </span>
        </p>
      </div>
      <Dropdown overlay={menu} className={styles.dropdown} placement={'bottomRight'}>
        <a onClick={(e) => e.preventDefault()}>
          <Icons className={styles.moreIcon} variant="more" />
        </a>
      </Dropdown>
    </div>
  );
};

Document.propTypes = {
  document: PropTypes.objectOf(
    PropTypes.shape({
      Id: PropTypes.number,
      ShowingAppointmentId: PropTypes.number,
      filename: PropTypes.string,
      documentName: PropTypes.string,
      DocumentType: PropTypes.string,
      DocumentLink: PropTypes.string,
      CreatedDate: PropTypes.string,
      UpdatedDate: PropTypes.string,
      DocumentVaultUUID: PropTypes.string,
    }),
  ),
};

Document.defaultProps = {
  document: {},
};
