import { cloneDeep, get } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  setTransactionDocumentsFilterAction,
  setTransactionDocumentsSortAction,
  getTransactionDocumentsFilterAction,
  resetTransactionDocumentsFiltersAction,
  setTransactionDocumentsPageAction,
  resetTransactionDocumentsAction,
  setTransactionDocumentsFilterPendingAction,
} from 'store/actions/transactions';
import { SORT_DESC } from 'settings/constants/sort';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  count: 0,
  countPerPage: 20,
  filter: {
    name: null,
    categoryIds: [],
    uploaderIds: [],
    createdFrom: null,
    createdTo: null,
    editedFrom: null,
    editedTo: null,
  },
  sort: {
    fields: ['DateUploaded'],
    order: SORT_DESC,
  },
  page: 0,
};

export default handleActions(
  {
    [getTransactionDocumentsFilterAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result.Documents', initialData.data),
      count: get(payload, 'data.result.Total', initialData.count),
    }),
    [setTransactionDocumentsFilterPendingAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
    }),
    [setTransactionDocumentsFilterAction]: (state, { payload }) => ({
      ...state,
      filter: {
        ...state.filter,
        ...payload,
      },
    }),
    [setTransactionDocumentsSortAction]: (state, { payload }) => ({
      ...state,
      sort: {
        ...state.sort,
        ...payload,
      },
    }),
    [resetTransactionDocumentsFiltersAction]: (state) => ({
      ...state,
      filter: cloneDeep(initialData.filter),
      sort: cloneDeep(initialData.sort),
    }),
    [setTransactionDocumentsPageAction]: (state, { payload }) => ({
      ...state,
      page: get(payload, 'page', initialData.page),
    }),
    [resetTransactionDocumentsAction]: () => cloneDeep(initialData),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
