import AntDatePicker, { DatePickerProps } from 'antd/lib/date-picker';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import classNames from 'classnames';

import styles from './styles.module.scss';

const { RangePicker: AntRangePicker } = AntDatePicker;

export const DatePicker = ({ className, format, ...props }: DatePickerProps) => (
  <AntDatePicker
    {...props}
    className={classNames(styles.datePicker, className)}
    format={format || 'MM-DD-YYYY'}
  />
);

export const RangePicker = ({ className, ...props }: RangePickerProps<any>) => (
  <AntRangePicker
    format="MM-DD-YYYY"
    {...props}
    className={classNames(styles.rangePicker, className)}
  />
);
