import { showErrorMessage } from 'helpers';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentPlanLevelEffect } from 'store/effects/subscription';
import { userGetDataOnBackgroundEffect } from 'store/effects/user';

export const useRetrievePlan = (onNext, onError?, paymentCheck = false) => {
  const dispatch = useDispatch();
  const intervalIdRef = useRef<any>(null);
  const [pollingError, setPollingError] = useState<string>('');

  const retrievedStripeUpdatedPlanLevel = async () => {
    const planLevelResponse: any = await dispatch(
      getCurrentPlanLevelEffect({}, { showError: false }),
    );
    if (paymentCheck) {
      return planLevelResponse?.data?.result?.PaymentStatus === 'paid';
    }
    return planLevelResponse?.data?.result?.CurrentPlan === 'premium';
  };

  const startRetrievingPlan = () => {
    let attemptCounter = 0;
    intervalIdRef.current = setInterval(async () => {
      attemptCounter += 1;
      const conditionMet = await retrievedStripeUpdatedPlanLevel();
      if (conditionMet) {
        clearInterval(intervalIdRef.current);
        dispatch(userGetDataOnBackgroundEffect({}, { showError: false }));
        onNext?.();
      } else if (attemptCounter >= 5) {
        clearInterval(intervalIdRef.current);
        if (onError) {
          onError?.();
        } else {
          setPollingError(
            'Failed to retrieve updated plan status, Please try again after few mins',
          );
        }
      }
    }, 3000);
  };

  useEffect(() => {
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (pollingError) {
      showErrorMessage(pollingError);
    }
  }, [pollingError]);

  return { startRetrievingPlan };
};
