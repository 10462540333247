import Icon from '../../../Icons';
import styles from './styles.module.scss';
import { PlusOutlined } from '@ant-design/icons';

interface itemProps {
  onClick: () => void;
  title: string;
}

export const MoreOptionItem = ({ onClick, title }: itemProps) => {
  return (
    <li>
      <div className={styles.addOptionDiv} onClick={onClick}>
        <Icon variant={Icon.OPTIONS_PLUS} />
        <span>{title}</span>
      </div>
    </li>
  );
};
