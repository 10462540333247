import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { Popover } from 'components-antd';
import { Icons as Icon } from './Icons';
import { useOutsideClick } from 'hooks';
import { setDrawerClientProfileId } from 'store/effects';
import { getClientDetailsEffect } from 'store/effects/clientDetail';

import styles from './styles.module.scss';

export const Options = (props) => {
  const { className, optionDotsClassName, clientId, onChange, setShowModal } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLInputElement>(null);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onViewProfile = (e) => {
    e.stopPropagation();
    setOpen(false);
    dispatch(getClientDetailsEffect({ id: clientId }));
    dispatch(setDrawerClientProfileId({ id: clientId }));
  };

  const onModalOpen = (e, type) => {
    e.stopPropagation();
    setOpen(false);
    onChange(e, clientId, true);
    setShowModal(type);
  };

  return (
    <div className={classNames(styles.optionsWrapper, className)}>
      <div
        testid="options"
        ref={buttonRef}
        onClick={onClickOptions}
        className={classNames(styles.optionsDots, optionDotsClassName)}
      >
        <Icon variant={Icon.DOTS} />
      </div>
      <Popover
        open={open}
        overlayClassName={styles.teamClientOptionsPopover}
        content={
          <div ref={optionsRef} className={styles.options}>
            <ul>
              <li testid="view_profile_action" className={styles.item} onClick={onViewProfile}>
                <div className={styles.icon}>
                  <Icon variant={Icon.PROFILE} />
                </div>
                <span>View Profile</span>
              </li>
              <li
                testid="reassign_action"
                className={styles.item}
                onClick={(e) => onModalOpen(e, 'Reassign')}
              >
                <div className={styles.icon}>
                  <Icon variant={Icon.REASSIGN} />
                </div>
                <span>Reassign</span>
              </li>
              <li
                testid="share_action"
                className={styles.item}
                onClick={(e) => onModalOpen(e, 'Share')}
              >
                <div className={styles.icon}>
                  <Icon variant={Icon.SHARE} />
                </div>
                <span>Share</span>
              </li>
            </ul>
          </div>
        }
      ></Popover>
    </div>
  );
};

Options.propTypes = {
  className: PropTypes.string,
  optionDotsClassName: PropTypes.string,
  clientId: PropTypes.number,
  onChange: PropTypes.func,
  setShowModal: PropTypes.func,
};

Options.defaultProps = {
  className: '',
  optionDotsClassName: '',
  clientId: null,
  onChange: () => {},
  setShowModal: () => {},
};
