import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getFormRecentUploadsEffect } from 'store/effects/formProcess';
import { getFormRecentUploadsSelector } from 'store/selectors/requestFormProcess';

import { FormHeader, FormSearchFilter } from '../components';
import { FormLibraryContent, recentUploadsColumns } from './tableColumns';
import styles from './style.module.scss';
import { FormFilesTable } from '../components/FormFilesTable';
import { FormFilePreview } from '../components/FormFilePreview';
import Navigation from '../components/Footer';

import { routes } from 'settings/navigation/routes';
import { useHistory } from 'react-router-dom';
import { FormFieldsModal } from '../components/FormFieldsModal';
import { useFetchTeamList } from '../components/hooks/useFetchTeamList';

type templateRequestType = {
  Id?: number;
};

export const RecentUploads = ({ stagesStep, onUpdate, setCurrentStageIndex }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { teamMembers, loading: teamLoading } = useFetchTeamList();
  const [rowKeys, setRowKeys] = useState<React.Key[]>([]);
  const [showSelection, setShowSelection] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [previewFile, setPreviewFile] = useState<FormLibraryContent | string>('');
  const [showNameModal, setNameModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const { data: recentUploadFiles, isPending } = useSelector(getFormRecentUploadsSelector);

  const handlePrevious = () => {
    onUpdate(stagesStep.FormCategories);
    setCurrentStageIndex(stagesStep.FormCategories);
  };

  const dispatchGetFormRecentUploadsEffect = (filterPayload = {}) => {
    searchTerm && setSearchTerm('');
    const payload = {
      ...filterPayload,
    };
    dispatch(getFormRecentUploadsEffect(payload));
  };

  useEffect(() => {
    dispatchGetFormRecentUploadsEffect();
  }, []);

  const dataSource = useMemo(() => {
    if (searchTerm) {
      return recentUploadFiles?.documents?.filter((file) =>
        file.Name?.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    return recentUploadFiles?.documents || [];
  }, [searchTerm, recentUploadFiles]);

  const managePreview = (record) => {
    setPreviewFile(record || '');
  };

  const toggleNameModal = () => {
    setNameModal((prev) => !prev);
  };

  const moveToProcessRequest = (state = {}) => {
    history.push({
      pathname: routes.workshopFormProcessRequest,
      state,
    });
  };

  const handleFormRequest = ({ Id }: templateRequestType) => {
    let formName = '';

    const form = recentUploadFiles?.documents.find(({ Id: formId }) => Id === formId);

    if (form?.Name) {
      formName = form?.Name;
    }

    let state = {
      documentLink: form?.DocumentLink,
      customForm: true,
      name: formName,
      ...(form?.bundleId && { documentIds: form.documentIds, formBundle: true }),
    };
    moveToProcessRequest(state);
  };

  const onFieldsModalSubmit = async (setLoader, data) => {
    const { templateName, categories, saveAsTemplate } = data;
    const requestData = {
      saveAsTemplate: false,

      ...(saveAsTemplate && {
        name: templateName,
        categories,
        saveAsTemplate,
      }),

      ...(!saveAsTemplate && { name: templateName }),
    };

    const bundleForms = recentUploadFiles?.documents
      .filter(({ Id }) => rowKeys.includes(Id))
      .map(({ Id, Name, bundleId, documentIds, DocumentLink }) => ({
        Id,
        Name,
        DocumentLink,
        ...(bundleId && { documentIds: documentIds || [] }),
      }));

    let documentIds: number[] = [];

    bundleForms.forEach(({ Id, documentIds: BundleDocIds }) => {
      documentIds = [
        ...documentIds,
        ...((BundleDocIds && BundleDocIds?.length > 0 ? BundleDocIds : [Id]) as number[]),
      ];
    });

    let state = {
      ...requestData,
      customForm: true,
      formBundle: true,
      documentIds,
    };

    moveToProcessRequest(state);
  };

  const onNext = (uploadForm) => {
    if (rowKeys.length > 1) {
      toggleNameModal();
    } else {
      handleFormRequest({ Id: uploadForm?.Id || (rowKeys[0] as string) });
    }
  };

  const applyFilter = (filterValues) => {
    const { lastUpdatedFrom: startDate, lastUpdatedTo: endDate, uploadedBy } = filterValues;

    const filterPayload = {
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      uploadedByIds: uploadedBy,
    };

    dispatchGetFormRecentUploadsEffect(filterPayload);
  };

  const handleRowKeys = (id, checked) => {
    let keys = [...rowKeys];
    if (checked) {
      keys.push(id);
      setRowKeys(keys);
    } else {
      keys = keys.filter((item) => item !== id);
      setRowKeys(keys);
    }
  };

  return (
    <div className={styles.recentUploadsWrapper}>
      <FormHeader heading={'Recent Uploads'} handlePrevious={handlePrevious} />

      {previewFile && (
        <FormFilePreview record={previewFile} onClosePreview={() => managePreview('')} />
      )}

      <div className={styles.recentUploadsContent}>
        <div className={styles.uploadsContentWrapper}>
          <FormSearchFilter
            searchText={searchTerm}
            onSearch={setSearchTerm}
            showSelection={showSelection}
            onShowSelection={(selection) => {
              !selection && rowKeys.length > 0 && setRowKeys([]);
              setShowSelection(selection);
            }}
            hideSelection={false}
            filterProps={{ isRecentUploads: true, applyFilter, uploadedByOptions: teamMembers }}
          />

          <FormFilesTable
            className={styles.recentUploadsTable}
            loading={isPending || teamLoading}
            columns={recentUploadsColumns({
              onPreview: managePreview,
              showSelection,
              handleRowKeys,
              rowKeys,
            })}
            dataSource={dataSource}
            total={recentUploadFiles?.total}
            rowKeyId={'Id'}
            showSelection={showSelection}
            hidePagination={true}
            onRowClick={(record) => onNext(record)}
          />
        </div>

        <FormFieldsModal
          open={showNameModal}
          title={'New Form Package'}
          okText={'Create'}
          submit={onFieldsModalSubmit}
          onCancel={toggleNameModal}
          className={styles.formInputSelectModal}
          titleClassName={styles.formInputTitle}
          headerIcon="formBundle"
          checkboxFieldsRequired={true}
        />
      </div>

      {rowKeys.length ? (
        <Navigation
          onNext={onNext}
          loading={(rowKeys.length === 1 && loading) || teamLoading}
          size="large"
          className={styles.navigation}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
