import { ReactElement } from 'react';
import { Ratings } from 'components';
import styles from './styles.module.scss';
import haversineDistance from 'helpers/haversineDistance';
import { useSelector } from 'react-redux';
import { getListingDetailMapData } from 'store/selectors/listingDetail';

type LocationInfoWindowPropsType = {
  name: string;
  distance: string;
  rating: number;
  voteCount: number;
  category: string;
  lat: number;
  lng: number;
};

function LocationInfoWindow(props: LocationInfoWindowPropsType) {
  const { center } = useSelector(getListingDetailMapData);
  let distance = '';
  if (
    (center && center.lat && center.lng && props.lat,
    props.lng && (!props.distance || props.distance))
  ) {
    let dist = haversineDistance(center.lat, center.lng, props.lat, props.lng);
    distance = dist + ' mi';
  }

  let vCount = '';

  if (props.voteCount) {
    vCount = '(' + props.voteCount + ')';
  }

  return (
    <div className={styles.locationInfoWindow}>
      <div className={styles.locationInfoWindowHeader}>
        <p className={styles.name}>{props.name}</p>
        {distance ? <p className={styles.distance}>{distance}</p> : <></>}
      </div>
      <div className={styles.locationInfoWindowContent}>
        <p className={styles.rating}>{props.rating}</p>
        <Ratings rating={props.rating} stylesClassName={styles.stars} colorWithHash={'#FF576D'} />
        <p className={styles.count}>{vCount}</p>
      </div>
      <p className={styles.category}>{props.category}</p>
    </div>
  );
}

export default LocationInfoWindow;
