import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { link } from 'settings/navigation/link';
import { requestGetProjectCategories } from 'store/effects/projects';
import { TemplateTabs } from 'pages/Templates/Transactions/components/TemplateTabs';

export const TeamTransactionTemplateCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const teamId = +params.teamId;

  useEffect(() => {
    dispatch(requestGetProjectCategories({}, { silent: true }));
  }, []);

  const handleGoBack = () => history.push(link.toDashboardTeamTransactionTemplate(teamId));
  const handleCancel = () => history.push(link.toDashboardTeamTransactionTemplate(teamId));

  return (
    <TemplateTabs
      isViewMode={false}
      handleGoBack={handleGoBack}
      handleCancel={handleCancel}
      teamId={teamId}
    />
  );
};
