import {
  addFormCommentAnon,
  addFormCommentAuth,
  deleteFormCommentAnon,
  deleteFormCommentAuth,
  editFormCommentAnon,
  editFormCommentAuth,
  getFormCommentsAnon,
  getFormCommentsAuth,
  getFormCommentUsersAnon,
  getFormCommentUsersAuth,
  toggleResolveThreadAnon,
  toggleResolveThreadAuth,
} from 'api/formComment';
import {
  addFormCommentAction,
  deleteFormCommentAction,
  editFormCommentAction,
  getFormCommentsAction,
  getFormCommentUsersAction,
  resetFormCommentStateAction,
  toggleResolveThreadAction,
} from 'store/actions/formComment';
import Api from 'store/effects/core/api';
import {
  AddFormCommentType,
  AnonDataType,
  AuthDataType,
  EditFormCommentType,
  ToggleResolveThreadType,
} from 'types';

export const getFormCommentUsersEffect = ({
  authData,
  anonData,
}: {
  authData?: AuthDataType;
  anonData?: AnonDataType;
}) => {
  const sendRequest = Api.execBase({
    action: getFormCommentUsersAction,
    method: authData ? getFormCommentUsersAuth : getFormCommentUsersAnon,
  });
  return sendRequest({ ...authData, ...anonData });
};

export const getFormCommentsEffect = ({
  authData,
  anonData,
}: {
  authData?: AuthDataType;
  anonData?: AnonDataType;
}) => {
  const sendRequest = Api.execBase({
    action: getFormCommentsAction,
    method: authData ? getFormCommentsAuth : getFormCommentsAnon,
  });
  return sendRequest({ ...authData, ...anonData });
};

export const addFormCommentEffect = ({
  authData,
  anonData,
  cfg,
  cb,
}: {
  authData?: AuthDataType;
  anonData?: AnonDataType;
  cfg: AddFormCommentType;
  cb: (error: any) => void;
}) => {
  const sendRequest = Api.execBase({
    action: addFormCommentAction,
    method: authData ? addFormCommentAuth : addFormCommentAnon,
  });
  return sendRequest({ ...authData, ...anonData, ...cfg }, {}, cb);
};

export const editFormCommentEffect = ({
  authData,
  anonData,
  cfg,
  cb,
}: {
  authData?: AuthDataType;
  anonData?: AnonDataType;
  cfg: EditFormCommentType;
  cb: (error: any) => void;
}) => {
  const sendRequest = Api.execBase({
    action: editFormCommentAction,
    method: authData ? editFormCommentAuth : editFormCommentAnon,
  });
  return sendRequest({ ...authData, ...anonData, ...cfg }, {}, cb);
};

export const deleteFormCommentEffect = ({
  authData,
  anonData,
  cfg,
  cb,
}: {
  authData?: AuthDataType;
  anonData?: AnonDataType;
  cfg: { formCommentId: number };
  cb: (error: any) => void;
}) => {
  const sendRequest = Api.execBase({
    action: deleteFormCommentAction,
    method: authData ? deleteFormCommentAuth : deleteFormCommentAnon,
  });
  return sendRequest({ ...authData, ...anonData, ...cfg }, {}, cb);
};

export const toggleResolveThreadEffect = ({
  authData,
  anonData,
  cfg,
  cb,
}: {
  authData?: AuthDataType;
  anonData?: AnonDataType;
  cfg: ToggleResolveThreadType;
  cb: (error: any) => void;
}) => {
  const sendRequest = Api.execBase({
    action: toggleResolveThreadAction,
    method: authData ? toggleResolveThreadAuth : toggleResolveThreadAnon,
  });
  return sendRequest({ ...authData, ...anonData, ...cfg }, {}, cb);
};

export const resetFormCommentStateEffect = () => (dispatch) =>
  dispatch(resetFormCommentStateAction());
