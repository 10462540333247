import { ReactElement } from 'react';

import { Title, Modal, ModalProps } from 'components-antd';

import styles from './styles.module.scss';

type ConfirmVariant = 'Deactivate' | 'Activate' | 'Delete';

export interface ConfirmationModalProps extends ModalProps {
  confirmText?: ReactElement | string;
  variant?: ConfirmVariant;
}

export const ConfirmationModal = ({
  variant,
  className,
  onOk,
  open,
  onCancel,
  ...props
}: ConfirmationModalProps) => {
  const getTitle = () => {
    return `Are you sure you want to ${variant?.toLowerCase()} this search?`;
  };

  const getConfirmText = () => {
    if (variant === 'Deactivate') {
      return (
        <>
          <span className={styles.text}>
            You will no longer receive any notifications related to it and results of this search
            will not display in your feed.
          </span>
          <span className={styles.text}>You can reactivate it at any time.</span>
        </>
      );
    } else if (variant === 'Activate') {
      return (
        <>
          <span className={styles.text}>
            You may receive property notifications and results of this search will display in your
            feed.
          </span>
          <span className={styles.text}>
            You can also change your notification preferences at any time in Settings.
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className={styles.text}>This action would be irreversible.</span>
        </>
      );
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        data-testid="confirmation-modal"
        className={styles.confirmationModal}
        okText={variant}
        title={getTitle()}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        width={420}
        {...props}
      >
        <Title level={4}>{getConfirmText()}</Title>
      </Modal>
    </div>
  );
};
