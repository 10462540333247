import { createChatBotMessage } from 'react-chatbot-kit';

const botName = 'Mozzie';
// We can add custom components such as header and can have state to manage the messages
export const Config = {
  initialMessages: [createChatBotMessage(`Hi! I'm ${botName}. How may I help you?`, {})],
  botName: botName,
  state: {
    isFirstMessage: true,
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },
};
