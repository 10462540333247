import {
  requestClientsSearchInstancesAction,
  openPreApprovalLettersDrawerAction,
} from 'store/actions/clientSearches';

import Api from 'store/effects/core/api';
import { getClientsSearchInstances } from 'api/user';

export const getClientsSearchInstancesEffect = (cfg, options, cb) => {
  const requestParams = {
    action: requestClientsSearchInstancesAction,
    method: getClientsSearchInstances,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }
  return sendRequest(cfg, options, cb);
};

export const openPreApprovalLettersDrawerEffect = (cfg) => (dispatch) =>
  dispatch(openPreApprovalLettersDrawerAction(cfg));
