import { useState } from 'react';
import { useSelector } from 'react-redux';

import { FormattedPhone } from 'components';
import { EditCircle, Email, Sms } from 'components/Icons';
import { getUserInfo } from '../../selectors';
import { usePhoneVerification } from './usePhoneVerification';
import { VerifyPhoneNumberModal } from '../VerifyPhoneNumberModal/VerifyPhoneNumberModal';

import styles from './styles.module.scss';

export const NotificationsMediums = () => {
  const [verifyPhoneNumberModalOpened, setVerifyPhoneNumberModalOpened] = useState(false);

  const userInfo = useSelector(getUserInfo);

  const openModal = () => {
    setVerifyPhoneNumberModalOpened(true);
  };

  const { userPhones, userEmail } = userInfo;
  const primaryPhone = userPhones?.find((phone) => phone.IsPrimary && phone.IsVerified) ?? null;
  const phoneVerification = usePhoneVerification(userPhones, openModal);

  return (
    <div className={styles.notificationsMediumsWrap}>
      <div className={styles.generalBox}>
        <div className={styles.contentWrap}>
          <Sms className={styles.icon} />
          <div className={styles.content}>
            <h4>Text Notifications</h4>
            <h5>
              Notifications will be sent to
              <FormattedPhone testid="phone" className={styles.phone}>
                {primaryPhone?.PhoneNumber || ''}
              </FormattedPhone>
            </h5>
          </div>
        </div>
        <EditCircle
          className={styles.edit}
          onClick={() => {
            phoneVerification.requestPhoneVerification(() => {});
          }}
        />
      </div>
      <div className={styles.generalBox}>
        <div className={styles.contentWrap}>
          <Email className={styles.icon} />
          <div className={styles.content}>
            <h4>Email Notifications</h4>
            <h5>Notifications will be sent to {userEmail || ''}</h5>
          </div>
        </div>
        {/* TODO: <EditCircle className={styles.edit} /> */}
      </div>
      <VerifyPhoneNumberModal
        isVerifyPhoneNumberModalOpened={verifyPhoneNumberModalOpened}
        onCloseModal={() => {
          setVerifyPhoneNumberModalOpened(false);
        }}
      />
    </div>
  );
};
