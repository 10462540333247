import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import {
  getTransactionDocumentsFilterEffect,
  setTransactionDocumentsFilterEffect,
  setTransactionDocumentsPageEffect,
} from 'store/effects/transactions/documents';
import { SearchFieldWithDebounce } from 'components';

import { getTransactionDocumentsFilterSelector } from 'store/selectors/transactionDocuments';
import styles from './styles.module.scss';

const Search = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  const { filter } = useSelector(getTransactionDocumentsFilterSelector);

  const getSendAction = ({ search }) => {
    dispatch(setTransactionDocumentsPageEffect({ page: 0 }));
    dispatch(setTransactionDocumentsFilterEffect({ name: search }));
    return getTransactionDocumentsFilterEffect();
  };

  const getResetAction = () => {
    dispatch(setTransactionDocumentsFilterEffect({ name: undefined }));
    return getTransactionDocumentsFilterEffect();
  };

  return (
    <div testid="search_documents" className={classNames(styles.search, className)}>
      <SearchFieldWithDebounce
        className={styles.searchField}
        placeholder="Search Documents"
        sendAction={getSendAction}
        value={filter?.name}
        resetAction={getResetAction}
        resetByUnmount={false}
      />
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
};

Search.defaultProps = {
  className: '',
};

export default Search;
