import { useState, useEffect } from 'react';
import { orderBy } from 'lodash-es';

import { Row, Col, Switch, Popover } from 'components-antd';
import { Input, InputLabel } from 'components';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { TaskEditableFormValuesKeys, TaskEditableFormValuesType } from 'types/transactionTasks';

import styles from './styles.module.scss';

interface ChecklistsProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  className?: string | null;
  isViewMode?: boolean | null;
  showOptional?: boolean | null;
  editData?: any;
  isSuperUser?: boolean;
  addChecklists?: boolean;
}

interface Checklist {
  Name: string;
  Required: boolean;
}

const initChecklist = {
  Name: '',
  Required: false,
};

export const Checklists = ({
  values,
  editData,
  onChangeField,
  isViewMode,
  showOptional,
  isSuperUser = false,
  addChecklists,
}: ChecklistsProps) => {
  const [listValue, setListValue] = useState<Checklist[]>(values?.Checklists ?? []);

  const onAdd = () => {
    setListValue((prev) => [...prev, initChecklist]);
    onChangeField(listValue, 'Checklists');
  };

  const onChange = (value, field, index) => {
    const copyList = [...listValue];
    copyList.splice(index, 1, {
      ...listValue[index],
      [field]: value,
    });
    setListValue([...copyList]);
    onChangeField([...copyList], 'Checklists');
  };

  const onRemove = (index) => {
    const copyList = [...listValue];
    copyList.splice(index, 1);
    setListValue(copyList);
    onChangeField(copyList, 'Checklists');
  };

  useEffect(() => {
    if (addChecklists) {
      onAdd();
    }
  }, [addChecklists]);

  const getItem = ({ index, item }) => {
    return (
      <div className={styles.checklistItem}>
        <Input
          name={`${item.Name}-${index}`}
          value={item.Name}
          placeholder="Add checklist"
          className={styles.checklistsInput}
          inputClassName={styles.input}
          removeBtnClassName={styles.removeBtn}
          onChange={(e, value) => onChange(value, 'Name', index)}
          removeHandler={() => !isViewMode && onRemove(index)}
          disabled={isViewMode}
        />
        <Popover
          className={styles.checkListPopoverContent}
          content={<span>Required Checklist?</span>}
        >
          <Switch
            checked={item.Required}
            defaultChecked={item.Required}
            onChange={(v) => onChange(v, 'Required', index)}
            disabled={!!isViewMode}
          />
        </Popover>
      </div>
    );
  };

  useEffect(() => {
    const checklists = values?.Checklists || editData?.Checklists;
    if (checklists?.length) {
      setListValue(orderBy(checklists, 'Id', ['asc']));
    }
  }, []);

  if (isViewMode && !listValue?.length) return <></>;

  return (
    <div className={styles.checklists}>
      {addChecklists || listValue?.length ? (
        <div className={styles.spacing}>
          <Row align="middle">
            <Col xs={20} sm={20} md={22} lg={22} xl={22}>
              <InputLabel
                label="Checklists"
                altLabel={isViewMode ? ' (View only)' : ''}
                className={styles.label}
                showOptional={showOptional}
              />
            </Col>
            {!isViewMode ? (
              <Col xs={4} sm={4} md={2} lg={2} xl={2}>
                <Icon
                  variant={Icon.ADDLINK}
                  className={styles.addChecklistsIcon}
                  onClick={() => !isViewMode && onAdd()}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>

          {listValue.map((item, index) => (
            <div key={index}>{getItem({ index, item })}</div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
