import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LocationService } from 'services';
import { Breadcrumbs } from 'components';
import { routes } from 'settings/navigation/routes';
import { getQuotesGroupBySelector } from 'store/selectors/quotes';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import { Archive } from 'pages/Quotes/components/QuoteDetails/components';
import Icon from 'pages/Quotes/components/QuoteDetails/Icons';

import { getUserRolesMapSelector } from 'store/selectors/user';
import { archiveQuotesEffect } from 'store/effects/quotes';

import styles from './styles.module.scss';

const locationSrv = new LocationService();

const Subheader = (props) => {
  const { className } = props;
  const groupByPropName = useSelector(getQuotesGroupBySelector);
  const { quote } = useSelector(getQuoteDetailsSelector);
  const { isClient, isThirdParty } = useSelector(getUserRolesMapSelector);
  const location = useLocation();
  const dispatch = useDispatch();

  locationSrv.setLocation(location);

  const breadcrumbsList = useMemo(() => {
    const links = [
      ...(!isThirdParty ? [{ label: 'All Services', link: routes.services }] : []),
      {
        label: isThirdParty ? 'Quotes' : 'My Quotes',
        link: `${routes.servicesQuotes}?${locationSrv.prepareSearch({
          groupId: undefined,
          groupBy: groupByPropName,
        })}`,
      },
    ];

    if (quote?.Partner?.BusinessName) {
      links.push({ label: quote?.Partner?.BusinessName, link: location.pathname });
    }

    return links;
  }, [quote, groupByPropName, location.pathname, isThirdParty]);

  const onArchive = () => {
    dispatch(
      archiveQuotesEffect({
        ids: [quote?.Id],
        newArchivedState: !quote?.IsArchived,
      }),
    );
  };

  return (
    <div className={classNames(styles.head, className)}>
      <Breadcrumbs list={breadcrumbsList} />
      <div className={styles.meta}>
        {quote?.IsRecommendedByAgent && isClient && (
          <div className={styles.recommendedBy}>
            <Icon className={styles.icon} variant={Icon.STAR} />
            <span>Recommended by</span>
            <span className={styles.name}> Agent Name</span>
          </div>
        )}
        {!isThirdParty && (
          <Archive onClick={onArchive} label={quote?.IsArchived ? 'Unarchive' : 'Archive'} />
        )}
      </div>
    </div>
  );
};

Subheader.propTypes = {
  className: PropTypes.string,
};

Subheader.defaultProps = {
  className: '',
};

export default Subheader;
