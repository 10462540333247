import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
  locations: Yup.array()
    .of(
      Yup.object().shape({
        AreasOfOperation: Yup.object().required('Location is required'),
        Radius: Yup.string().trim().required('Radius is required'),
      }),
    )
    .min(1, 'At least one location is required')
    .required('At least one location is required'),
});
