import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { BackLink, PageNotFound, Spinner } from 'components';
import { Footer } from 'components-antd/Footer/Footer';
import { routes } from 'settings/navigation/routes';
import { FilterIcon, Download } from 'components/Icons';
import Switcher from './Components/Switcher';
import KitsTable from './Components/KitsTable';
import ClientTable from './Components/ClientTable';
import { ClientKitDataType, KitStateConfigurationUpdateType } from './DataTypes';
import {
  getKitsMasterControl,
  updateKitsMasterControl,
  getPublishedKits,
  getCumulativeStats,
} from 'api/kits';
import DetailsView from './Components/DetailsView';
import { useDispatch, useSelector } from 'react-redux';
import { TEAM_OWNER } from 'settings/constants/roles';
import {
  getAgentTeamRoleSelector,
  getAgentTeamIsActiveSelector,
} from 'store/selectors/agentTeamDetail';
import MasterControl from './Components/MasterControl';
import { requestGetTeamListEffect } from 'store/effects';

import styles from './styles.module.scss';
import { setOnKitConfiguredHandler } from 'services/sockets';

type targetKitDataType = {
  Id: number;
  UUID: string | undefined;
};

export function MyKits(props) {
  const kitsOptions: string[] = ['By KIT', 'By Client'];
  const [activeOption, setActiveOption] = useState<string>(kitsOptions[0]);
  const [detailsMode, setDetailsMode] = useState<boolean>(false);
  const [targetKit, setTargetKit] = useState<targetKitDataType>({
    Id: 0,
    UUID: undefined,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [masterControl, setMasterControl] = useState<boolean>(false);
  const [byKitLoaded, setByKitLoaded] = useState<boolean>(false);
  const [cumulativeStatsLoaded, setCumulativeStatsLoaded] = useState<boolean>(false);
  const [standardKitData, setStandardKitData] = useState<any[]>([]);
  const [cumulativeStatsData, setCumulativeStatsData] = useState<ClientKitDataType[]>([]);
  const [teamMembers, setTeamMembers] = useState<any>(null);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);
  const dispatch = useDispatch();

  const fetchPublishedKits = useCallback(async () => {
    const publishedKitsResponse = await getPublishedKits();
    if (publishedKitsResponse.status === 200) {
      setStandardKitData(publishedKitsResponse?.data?.result);
      setByKitLoaded(true);
    } else {
      setError(true);
    }
  }, []);

  const fetchStandardKits = async () => {
    setLoading(true);
    try {
      const response = await getKitsMasterControl();
      if (response.status === 200) {
        setMasterControl(response.data.MasterControl);
      } else {
        setError(true);
      }
      await fetchPublishedKits();
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const fetchCumulativeStats = async () => {
    setLoading(true);
    try {
      const response = await getCumulativeStats();
      if (response.status === 200) {
        setCumulativeStatsData(response?.data);
        setCumulativeStatsLoaded(true);
      } else {
        setError(true);
      }
      setLoading(false);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const updateKitsConfigurationState = (
    kitId: number,
    updates: KitStateConfigurationUpdateType,
  ) => {
    const { enabled, frequencyId } = updates;
    if (enabled !== undefined) {
      const kitsData = [...standardKitData];
      const targetKitIndex = kitsData.findIndex((kit) => kit.KitId === kitId);
      if (targetKitIndex !== -1) {
        const targetKit = { ...kitsData[targetKitIndex], Enabled: enabled };
        kitsData[targetKitIndex] = targetKit;
        setStandardKitData(kitsData);
      }
    }
    if (frequencyId !== undefined && !Number.isNaN(frequencyId) && frequencyId > 0) {
      const kitsData = [...standardKitData];
      const targetKitIndex = kitsData.findIndex((kit) => kit.KitId === kitId);
      if (targetKitIndex !== -1) {
        const targetFrequencyObj = kitsData[targetKitIndex]?.Kits?.EligibleFrequencies?.find(
          (freq) => freq.Id === frequencyId,
        );
        if (targetFrequencyObj) {
          const targetKit = {
            ...kitsData[targetKitIndex],
            FrequencyId: frequencyId,
            CurrentFrequency: targetFrequencyObj,
          };
          kitsData[targetKitIndex] = targetKit;
          setStandardKitData(kitsData);
          void fetchPublishedKits();
        }
      }
    }
  };

  const handleMasterControl = async (v: boolean) => {
    try {
      await updateKitsMasterControl({ MasterControl: v });
      setMasterControl(v);
    } catch (e) {
      setError(true);
    }
  };

  const kitClickHandler = (id: number, uuid: string) => {
    if (id) {
      setTargetKit({
        Id: id,
        UUID: uuid || undefined,
      });
      setDetailsMode(true);
    }
  };

  const getTargetKit = () => {
    const found = targetKit.UUID
      ? standardKitData.find(
          (obj) => obj.Kits.Id === targetKit.Id && obj.UUID && obj.UUID === targetKit.UUID,
        )
      : standardKitData.find((obj) => obj.Kits.Id === targetKit.Id);
    if (found)
      return {
        targetKit: found.Kits,
        Locations: found?.Locations ? found?.Locations : null,
      };
  };

  useEffect(() => {
    if (activeOption === kitsOptions[0] && !byKitLoaded) {
      fetchStandardKits();
    } else if (activeOption === kitsOptions[1] && !cumulativeStatsLoaded) {
      fetchCumulativeStats();
    }
  }, [activeOption]);

  const fetchTeamMembers = async () => {
    dispatch(
      requestGetTeamListEffect({}, {}, (err, resp) => {
        if (!err) setTeamMembers(resp.data ?? []);
      }),
    );
  };

  useEffect(() => {
    if (agentRole === TEAM_OWNER && isTeamAgentActive && teamMembers === null) fetchTeamMembers();
  }, [agentRole]);

  useEffect(() => {
    setOnKitConfiguredHandler(() => {
      void fetchPublishedKits();
    });
  }, []);

  if (error) {
    return (
      <div>
        <PageNotFound backUrl={routes.clarity} />
        <div className={styles.Footer}>
          <Footer />
        </div>
      </div>
    );
  } else
    return (
      <div className={styles.myKits}>
        {detailsMode && targetKit.Id ? (
          <DetailsView
            backHandler={setDetailsMode}
            data={getTargetKit()}
            teamMembers={teamMembers}
          />
        ) : (
          <>
            <div className={styles.myKitsHeader}>
              <div className={styles.left}>
                <BackLink testid="kits_back" className={styles.back} to={routes.clarity} text="" />
              </div>
              <h1 className={styles.heading}>My KITs</h1>
              <div className={styles.options}>
                {isTeamAgentActive ? (
                  agentRole === TEAM_OWNER ? (
                    <MasterControl
                      enabled={masterControl}
                      handleMasterControl={handleMasterControl}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <MasterControl
                    enabled={masterControl}
                    handleMasterControl={handleMasterControl}
                  />
                )}
                <Switcher
                  useKey={false}
                  options={kitsOptions}
                  default={kitsOptions[0]}
                  onchangeHandler={setActiveOption}
                />
                <FilterIcon className={styles.icon} color="#262626" />
                <Download className={styles.icon} color="#262626" />
              </div>
            </div>
            <div className={styles.myKitsContent}>
              {loading ? (
                <Spinner loaderClassName={classNames(styles.loader)} />
              ) : activeOption === kitsOptions[0] ? (
                <KitsTable
                  data={standardKitData}
                  masterControl={masterControl}
                  kitClickHandler={kitClickHandler}
                  updateKitsConfigurationState={updateKitsConfigurationState}
                />
              ) : (
                <ClientTable data={cumulativeStatsData} />
              )}
            </div>
            <div className={styles.myKitsFooter}>
              <Footer />
            </div>
          </>
        )}
      </div>
    );
}

MyKits.propTypes = {};

MyKits.defaultProps = {};
