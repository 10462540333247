import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TemplateTabs } from '../components/TemplateTabs';
import { routes } from 'settings/navigation/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionTemplateSelector } from 'store/selectors/template';
import { useEffect } from 'react';
import { getTransactionTemplateEffect } from 'store/effects/template';
import { Wrapper } from 'components';
import { Role } from 'app-constants';
import { getUserRoleSelector } from 'store/selectors/user';
import { requestGetProjectCategories } from 'store/effects/projects';

export const TransactionTemplateView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isProject = location.pathname.includes('project');

  const userRole = useSelector(getUserRoleSelector);

  const { id: templateId } = useParams<{ id: string }>();

  const template = useSelector(getTransactionTemplateSelector);

  useEffect(() => {
    dispatch(requestGetProjectCategories({}, { silent: true }));
    dispatch(getTransactionTemplateEffect({ id: templateId }));
  }, [templateId, dispatch]);

  const handleGoBack = () => {
    if (userRole === Role.SuperUser) {
      history.push(
        isProject ? routes.dashboardProjectsTemplates : routes.dashboardTransactionsTemplates,
      );
    } else if (userRole === Role.Agent) {
      history.push(isProject ? routes.templatesProjects : routes.templatesTransactions);
    }
  };

  return (
    <Wrapper isPending={template.isPending}>
      <TemplateTabs
        isViewMode={true}
        handleGoBack={handleGoBack}
        handleCancel={handleGoBack}
        transactionTemplate={template.data}
      />
    </Wrapper>
  );
};
