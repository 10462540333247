import { progressSmartFormValuesAnon, progressSmartFormValuesAuth } from 'api/formProcess';
import { requestProgressFormAnon, requestProgressFormAuth } from 'store/actions/formProcess';
import Api from 'store/effects/core/api';

export const progressSmartFormAuthEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestProgressFormAuth,
    method: progressSmartFormValuesAuth,
  });

  return sendRequest(cfg, {}, cb);
};

export const progressSmartFormAnonEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestProgressFormAnon,
    method: progressSmartFormValuesAnon,
  });

  return sendRequest(cfg, {}, cb);
};
