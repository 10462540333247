import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { deleteQuoteResponseEffect } from 'store/effects/quotes';
import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import { Popover } from 'antd';
import { PdfViewModal, Wrapper as PendingWrapper, MenuListItems } from 'components';
import { TrashIcon as Trash } from 'components/Icons';
import axios from 'axios';
import fileDownload from 'js-file-download';

import styles from './styles.module.scss';

const Actions = (props) => {
  const { className, deletable, file, quoteId } = props;
  const dispatch = useDispatch();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const onPreview = () => {
    setIsPreviewOpen(true);
  };

  const onDownload = () => {
    axios.get(file?.Url, { responseType: 'blob' }).then((response) => {
      fileDownload(response.data, file?.Filename);
    });
  };

  const onDelete = () => {
    setIsPending(true);
    dispatch(
      deleteQuoteResponseEffect({ quoteId, responseId: file?.Id }, {}, () => {
        setIsPending(false);
      }),
    );
  };

  const getMenuItems = () => {
    const menuItems = [
      {
        id: 0,
        name: 'Preview',
        icon: () => <Icon variant={Icon.PREVIEW} className={styles.previewIcon} />,
        onClick: onPreview,
      },
      {
        id: 1,
        name: 'Download',
        icon: () => <Icon variant={Icon.DOWNLOAD} className={styles.downloadIcon} />,
        onClick: onDownload,
      },
    ];

    if (deletable) {
      menuItems.push({
        id: 2,
        name: 'Delete',
        icon: Trash,
        onClick: onDelete,
      });
    }

    return menuItems;
  };

  return (
    <PendingWrapper
      isPending={isPending}
      spinnerClassname={styles.spinnerWrapper}
      loaderClassName={styles.loader}
    >
      <div className={classNames(styles.actions, className)}>
        <Popover
          content={<MenuListItems items={getMenuItems()} />}
          trigger="hover"
          placement="bottomRight"
          overlayClassName={styles.popoverOverlay}
        >
          <span className={styles.dotsImage}>
            <Icon variant={Icon.ACTIONS_DOTS} />
          </span>
        </Popover>
        {isPreviewOpen && (
          <PdfViewModal
            isOpen={isPreviewOpen}
            file={file}
            onClose={() => setIsPreviewOpen(false)}
          />
        )}
      </div>
    </PendingWrapper>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  deletable: PropTypes.bool,
  file: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ContentType: PropTypes.string,
    Url: PropTypes.string,
    Filename: PropTypes.string,
  }).isRequired,
  quoteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Actions.defaultProps = {
  className: '',
  deletable: false,
  quoteId: undefined,
};

export default Actions;
