import { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Popconfirm } from 'antd';

import { Modal } from 'components-antd';
import { Spinner } from 'components';
import { getFilteredShowings } from 'api/showings';
import { Icons } from 'pages/Workshop/Icons';
import { deleteShowingEffect } from 'store/effects/showings';

import styles from './styles.module.scss';

export const InactiveShowings = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showings, setShowings] = useState([]);
  const [loading, setLoading] = useState(false);

  const redirectTo = (transactionId) => {
    history.push(`/workshop/transactions/${transactionId}/showings?isOpen=true`);
  };

  const onDelete = (e, id) => {
    e.stopPropagation();
    dispatch(deleteShowingEffect({ Id: id }, null, fetchInactiveShowings));
  };

  const renderShowings = () => {
    return showings.map((s: any) => {
      const transactionId = s.PropertyTransaction?.Id;
      const Address = s?.PropertyTransaction?.Property?.Address;

      return (
        <div className={styles.showing} onClick={() => redirectTo(transactionId)} key={s.Id}>
          <Icons className={styles.condoIcon} variant="condo" />
          <div className={styles.details}>
            <p>{Address?.Line1}</p>
            <span>
              {Address?.City}, {Address?.State} {Address?.Zip}
            </span>
          </div>
          <Popconfirm
            title="Are you sure to delete this showing?"
            onConfirm={(e) => onDelete(e, s.Id)}
            onCancel={(e) => e?.stopPropagation()}
            okText="Yes"
            cancelText="No"
          >
            <Icons
              className={styles.deleteIcon}
              variant="delete"
              onClick={(e) => e.stopPropagation()}
            />
          </Popconfirm>
        </div>
      );
    });
  };

  const fetchInactiveShowings = useCallback(async () => {
    setLoading(true);
    const { data } = await getFilteredShowings('Status=InActive');
    setShowings(data.value);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (isOpen) fetchInactiveShowings();
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      className={styles.inactiveShowings}
    >
      <h1 className={styles.heading}>Inactive Showings</h1>
      {loading ? (
        <Spinner />
      ) : (
        <Row className={styles.content}>
          {showings.length ? (
            renderShowings()
          ) : (
            <p className={styles.disclaimer}>No inactive showings!</p>
          )}
        </Row>
      )}
    </Modal>
  );
};

InactiveShowings.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

InactiveShowings.defaultProps = {
  isOpen: false,
  onClose: () => {},
};
