import { useState } from 'react';

import { Form, DeleteModal } from 'components-antd';
import { TransactionFormTemplate, TransactionTemplate } from 'types';
import { getTemplateButtons } from '../TemplateButtons';
import { FormCard } from '../FormCard';
import { AddNew } from 'components/Icons';
import { getFormattedLocations } from 'helpers';
import { MyLibraryModal } from 'pages/Workshop/Forms/components/MyLibraryModal';
import { FormDocument } from 'components/Icons/FormDocument';
import { FormTaskModal } from 'pages/Workshop/Forms/components/NewFormModal/FormStages/components/FormTaskModal';
import { TimelineMilestonesInterface } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModalForm/AssignedToMilestone/types/TransactionMilestone';
import { FORM_TYPE } from 'app-constants';

import styles from './styles.module.scss';

interface FormsPaneProps {
  transactionTemplate?: TransactionTemplate;
  isViewMode: boolean;
  data: [];
}

export const FormsPane = ({ transactionTemplate, isViewMode, data }: FormsPaneProps) => {
  const [errors, setErrors] = useState<(string | undefined)[]>([]);

  const validateForms = async (_, forms: TransactionFormTemplate[]) => {
    let containsError = false;
    const errors: (string | undefined)[] = [];

    forms?.forEach((form) => {
      let error: string | undefined;
      if (!((form?.DueDate || form?.DueDate === 0) && form?.Initial && form?.Operator)) {
        error = 'Required';
        containsError = true;
      }
      errors.push(error);
    });

    if (!containsError) {
      setErrors([]);
      return Promise.resolve();
    } else {
      setErrors(errors);
      return Promise.reject(new Error(''));
    }
  };

  return (
    <div className={styles.tasksContainer}>
      <Form.Item
        name="TransactionFormTemplate"
        validateTrigger={'onSubmit'}
        rules={[
          {
            validator: validateForms,
          },
        ]}
      >
        <FormList
          errors={errors}
          transactionTemplate={transactionTemplate}
          isViewMode={isViewMode}
          data={data}
        />
      </Form.Item>
    </div>
  );
};

interface FormListProps {
  onChange?: (e) => void;
  value?: TransactionFormTemplate[];
  errors: (string | undefined)[];
  transactionTemplate?: TransactionTemplate;
  isViewMode: boolean;
  data: TimelineMilestonesInterface[];
}

const FormList = ({
  onChange,
  value,
  errors,
  transactionTemplate,
  isViewMode,
  data,
}: FormListProps) => {
  const [openFormLibraryModal, setOpenFormLibraryModal] = useState(false);
  const [formName, setFormName] = useState<string>('');
  const [selectedForm, setSelectedForm] = useState<any>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [editForm, setEditForm] = useState<number | undefined>();

  const onDeleteDeadline = (index) => {
    const result = [...(value || [])];

    if (result[index]) {
      result[index].DueDate = undefined;
      result[index].Initial = undefined;

      if (onChange) onChange(result);
    }
  };

  const onEditForm = (obj, index) => {
    const result = [...(value || [])];

    if (result[index]) {
      result[index] = { ...obj };
      if (onChange) onChange(result);
    }
  };

  const onAddForm = (form: any) => {
    const result = value || [];

    const sendData = {
      Name: form.Name,
      TemplateId: form?.bundleId ?? form.templateId ?? form.formId,
      ...(transactionTemplate?.Id && { TransactionTemplateId: transactionTemplate.Id }),
      IsRequired: !!form?.IsRequired,
      DueDate: form.DueDate,
      Initial: form.Initial,
      Operator: form.Operator,
      ControlOperatorOffset: form.ControlOperatorOffset,
      ...(form?.Description && { Description: form.Description }),
      AssigneeRoles: form.AssigneeRoles,
      ...(form?.CcRoles && { CcRoles: form.CcRoles }),
      IsBundle: !!form?.bundleId,
      FormType: form?.type || FORM_TYPE.Agent,
      ...(form?.MilestonePublicId && { MilestonePublicId: form.MilestonePublicId }),
    };

    if (onChange) onChange([...result, sendData]);
  };

  const onRemoveForm = (index) => {
    const result = [...(value || [])];
    result.splice(index, 1);

    if (onChange) onChange(result);
  };

  const onSubmit = (form: TransactionFormTemplate) => {
    editForm !== undefined ? onEditForm(form, editForm) : onAddForm(form);
    onClose();
  };

  const onClose = () => {
    setEditForm(undefined);
    setSelectedForm(undefined);
    setOpenFormLibraryModal(false);
  };

  const onCloseConfirmationModal = () => {
    setEditForm(undefined);
    setOpenConfirmationModal(false);
  };

  if (value) {
    return (
      <>
        <MyLibraryModal
          open={openFormLibraryModal}
          people={transactionTemplate?.People}
          teams={transactionTemplate?.Teams}
          locations={getFormattedLocations(transactionTemplate?.Locations)}
          onClose={() => {
            setOpenFormLibraryModal(false);
            setSelectedForm(undefined);
          }}
          setSelectedForm={(form) => setSelectedForm(form)}
        />

        <FormTaskModal
          isOpen={!!selectedForm}
          onCancelModal={() => {
            setEditForm(undefined);
            setSelectedForm(undefined);
          }}
          onSubmit={onSubmit}
          isTemplate={true}
          isViewMode={isViewMode}
          title={editForm !== undefined ? 'Edit Form' : 'New Form'}
          editData={selectedForm}
          isNew={editForm === undefined}
          data={data}
        />

        {value.length ? (
          <div className={styles.paneContainer}>
            {value
              .sort((a, b) => a.Id - b.Id)
              .map((form, index) => (
                <FormCard
                  key={`${form.TemplateId}-${index}-form-template`}
                  index={index}
                  form={form}
                  onRemoveDueDate={onDeleteDeadline}
                  onRemoveForm={() => {
                    setEditForm(index);
                    setOpenConfirmationModal(true);
                    setFormName(form.Name);
                  }}
                  onEditForm={() => {
                    setEditForm(index);
                    setSelectedForm(form);
                  }}
                  onFormCardEdit={onEditForm}
                  error={errors?.[index]}
                  isViewMode={isViewMode}
                />
              ))}
          </div>
        ) : (
          <div className={styles.emptyList}>
            <FormDocument />
            <p
              className={styles.emptyListDescription}
            >{`You don’t have any forms linked to this template.`}</p>
          </div>
        )}

        {!isViewMode &&
          getTemplateButtons([
            {
              name: 'Add Form',
              icon: <AddNew />,
              onClick: () => setOpenFormLibraryModal(true),
            },
          ])}

        <DeleteModal
          open={openConfirmationModal}
          entityName={formName}
          onOk={() => {
            onRemoveForm(editForm);
            onCloseConfirmationModal();
          }}
          onCancel={onCloseConfirmationModal}
          okText="Remove"
          titleText={`Are you sure you want to remove "${formName}" from the template?`}
        />
      </>
    );
  }

  return <></>;
};
