/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ItemsWrapper, ItemWrapper, Value } from '../components';

import styles from './styles.module.scss';

const Baths = (props) => {
  const { className, baths } = props;

  if (!baths) return null;

  return (
    <ItemsWrapper className={classNames(styles.baths, className)}>
      {baths.map((count, index) => (
        <ItemWrapper key={index}>
          <Value testid="baths" value={count} postfix="baths" />
        </ItemWrapper>
      ))}
    </ItemsWrapper>
  );
};

Baths.propTypes = {
  className: PropTypes.string,
  baths: PropTypes.arrayOf(PropTypes.number).isRequired,
};

Baths.defaultProps = {
  className: '',
};

export default Baths;
