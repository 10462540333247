import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UploadFile } from 'components';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import Option from '../Option';
import Label from '../Label';

import styles from './styles.module.scss';

const File = (props) => {
  const { className, onChange, multiple, onCloseModal, accept } = props;

  return (
    <Option testid="file_option" className={classNames(styles.file, className)}>
      <UploadFile
        multiple={multiple}
        icon={<Icon className={styles.icon} variant={Icon.FILE} />}
        className={styles.holder}
        innerClassName={styles.inner}
        id="transactionCreateUploadFile"
        label={<Label>File</Label>}
        onChange={onChange}
        onCloseModal={onCloseModal}
        accept={accept}
      />
    </Option>
  );
};

File.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
};

File.defaultProps = {
  className: '',
  onChange: () => {},
  multiple: true,
  onCloseModal: () => {},
};

export default File;
