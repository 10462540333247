import * as Yup from 'yup';
import 'yup-phone';

// eslint-disable-next-line
const websiteRegex =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const email = Yup.string().trim().email('Invalid email').required('Required');
const firstName = Yup.string().trim().required('Required');
const lastName = Yup.string().trim().required('Required');
const bio = Yup.string().trim().required('Required');

const address = Yup.array().required('Required');

const companyBusinessName = Yup.string().trim().required('Required');
const companyJobTitle = Yup.string().trim().required('Required');
const companyAddress = Yup.string().trim().required('Required');
const companySuiteUnit = Yup.string().trim().required('Required');

// TODO: change for difference regions
const phones = Yup.array().of(
  Yup.string()
    .phone('US', false, 'Must be a valid phone number for region US')
    .required('Required'),
);
const website = Yup.string()
  .matches(websiteRegex, 'Enter correct url!')
  .required('Please enter website');
const links = Yup.array().of(website);
const timezone = Yup.string();
export const AgentValidationSchema = Yup.object().shape({
  email,
  firstName,
  lastName,
  phones,
  links,
  timezone,
});

export const ThirdPartyValidationSchema = Yup.object().shape({
  email,
  firstName,
  lastName,
  bio,
  phones,
  website,
  companyBusinessName,
  companyJobTitle,
  //companyAddress,
  companySuiteUnit,
  timezone,
});

export const PartnerValidationSchema = Yup.object().shape({
  email,
  firstName,
  lastName,
  bio: Yup.string().trim(),
  phones,
  timezone,
});

export const ClientValidationSchema = Yup.object().shape({
  email,
  firstName,
  lastName,
  address,
  phones,
  timezone,
});

export const getValidationSchema = ({ isAgent, isThirdParty, isClient, isPartner }) => {
  if (isAgent) {
    return AgentValidationSchema;
  }
  if (isClient) {
    return ClientValidationSchema;
  }
  if (isPartner) {
    return PartnerValidationSchema;
  }
  if (isThirdParty) {
    return ThirdPartyValidationSchema;
  }
};
