import { Col, Row, Checkbox } from 'components-antd';
import styles from './styles.module.scss';

interface GroupCheckBoxesSectionProps {
  entityList: { name: string; value: any }[];
  entityValues: string[];
  title?: string;
  setEntity: (value: any) => void;
}

export const GroupCheckBoxesSection = ({
  entityList,
  entityValues,
  setEntity,
  title,
}: GroupCheckBoxesSectionProps) => {
  return (
    <>
      {title && <p className={styles.subheading}>{title}</p>}
      <Row>
        <Col span={12}>
          <div className={styles.statusField}>
            <Checkbox
              className={styles.checkbox}
              checked={entityValues.length === entityList.length}
              onChange={() => {
                if (entityValues.length === entityList.length) {
                  setEntity([]);
                } else {
                  setEntity(entityList.map(({ value }) => value));
                }
              }}
            />
            <span className={styles.statusLabel}>All</span>
          </div>
        </Col>
        {entityList.map(({ name, value }) => (
          <Col key={value} span={12}>
            <div className={styles.statusField}>
              <Checkbox
                className={styles.checkbox}
                checked={entityValues.includes(value)}
                onChange={(e) => {
                  const { checked } = e.target;
                  const statusesArr = checked
                    ? [...entityValues, value]
                    : entityValues.filter((i) => i !== value);
                  setEntity(statusesArr);
                }}
              />
              <span className={styles.statusLabel}>{name}</span>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};
