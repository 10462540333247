import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'pages/Workshop/Transactions/TransactionOverview/Icons';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';
import { Button } from 'components-antd';
import { Input, Select } from 'components';

import styles from './styles.module.scss';
import transactionViewStyles from './../TransactionView/styles.module.scss';
import { getProjectCategoriesDataArray } from 'store/selectors/projects';
import { updateTransactionEffect } from 'store/effects/transactions';

interface ProjectTitleProps {
  className?: string;
  isDisableField: (key: string) => boolean;
  setEditId: (id: string | null) => void;
}

const ProjectTitle = ({ className, isDisableField, setEditId }: ProjectTitleProps) => {
  const dispatch = useDispatch();
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [isEdit, setIsEdit] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [name, setName] = useState(transaction?.Name);
  const [category, setCategory] = useState(transaction?.TransactionCategory?.Id);
  const projectCategories = useSelector(getProjectCategoriesDataArray);
  const editKey = 'projectTitle';

  const updateTransaction = () => {
    setIsPending(true);
    dispatch(
      updateTransactionEffect(
        {
          id: transaction?.Id,
          name,
          category,
        },
        {},
        (err) => {
          if (!err) {
            setIsEdit(false);
            setEditId(null);
          }
          setIsPending(false);
        },
      ),
    );
  };

  const onEdit = () => {
    if (!fullAccess || isDisableField(editKey)) return null;
    setIsEdit(true);
    setEditId(editKey);
  };

  const isSameNameAndCategory = () =>
    name === transaction?.Name && category === transaction?.TransactionCategory?.Id;

  const onCancel = () => {
    setIsEdit(false);
    setName(transaction?.Name);
    setCategory(transaction?.TransactionCategory?.Id);
  };

  return (
    <div className={classNames(styles.projectTitle, className)}>
      {!isEdit ? (
        <>
          <div
            onClick={onEdit}
            className={classNames(
              styles.title,
              { [styles.fullAccess]: fullAccess },
              { [transactionViewStyles.disableField]: !!isDisableField(editKey) },
            )}
          >
            <span testid="project_name">{transaction?.Name}</span>
            {fullAccess && !isDisableField(editKey) && (
              <Icon testid="edit_icon" className={styles.editIcon} variant={Icon.EDIT} />
            )}
          </div>
          <div testid="project_category" className={styles.subtitle}>
            {transaction?.TransactionCategory?.Category}
          </div>
        </>
      ) : (
        <div className={transactionViewStyles.editWrapper}>
          <Input
            className={styles.field}
            placeholder="Name"
            variant="lightRound"
            value={name}
            onChange={(e, val) => setName(val)}
            disabled={isPending}
            testid="project_name_input"
          />
          <Select
            name="category"
            className={{ wrapper: classNames(styles.selectWrapper, styles.field) }}
            variant="lightRound"
            options={projectCategories?.map((pc) => ({ value: pc.Id, name: pc.Category }))}
            value={category}
            onSelect={(e, val) => setCategory(val)}
            disabled={isPending}
            testid="category_select"
          />
          <div className={transactionViewStyles.buttons}>
            <Button
              variant="secondary"
              size="large"
              disabled={isSameNameAndCategory()}
              onClick={updateTransaction}
              loading={isPending}
            >
              Update
            </Button>
            <Button variant="hollow-bordered" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectTitle;
