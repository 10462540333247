import { Fragment, useState } from 'react';
import { SplitItem, EditDynamicSplit } from '..';
import { ConfirmationDialog } from 'components';

import { deleteFinancialSplit, updateFinancialSplit } from 'api/financials';
import { showErrorMessage } from 'helpers';

import styles from './styles.module.scss';

export const DynamicSplitItem = ({
  item,
  autocompleteSplitNames,
  transactionId,
  isTransactionAdminOrOwner,
  isValidate,
  updateSplitValue,
  fetchFinancials,
  isDisabledField,
  setEditFieldKey,
}) => {
  const [isEditState, setIsEditState] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // Only Transaction Admin/Owner can view Restricted Dynamic Splits.
  if (!isTransactionAdminOrOwner && item?.SplitType?.IsRestricted) return null;

  const toggleRestrict = async () => {
    let _payload: any = {
      SplitValue: item?.defaultSplitValueType ? item?.SplitPercentage : item?.SplitValue,
      SplitTitle: item?.SplitType?.SplitTitle,
      IsSplitTypePercentage: item?.defaultSplitValueType,
      SplitSide: item?.SplitSide,
      IsRestricted: !item?.SplitType?.IsRestricted,
    };

    try {
      await updateFinancialSplit({
        transactionId: transactionId,
        id: item?.Id,
        data: _payload,
      });
      fetchFinancials();
    } catch (err) {
      showErrorMessage(err);
    }
  };

  const handleDeleteSplitItem = async () => {
    try {
      setConfirmationLoading(true);
      await deleteFinancialSplit({
        transactionId: transactionId,
        id: item?.Id,
      });
      fetchFinancials();
    } catch (err) {
      setConfirmationLoading(false);
      showErrorMessage(err);
    } finally {
      setConfirmationLoading(false);
      setShowConfirmationModal(false);
    }
  };

  return (
    <Fragment>
      {!isEditState ? (
        <SplitItem
          label={item?.SplitType?.SplitTitle}
          amount={item?.SplitValue}
          percentage={item?.SplitPercentage}
          setEditField={() => {
            setIsEditState(true);
            setEditFieldKey(item.Id);
          }}
          toggleRestrict={toggleRestrict}
          handleDelete={() => setShowConfirmationModal(true)}
          valueTypeEntered={item?.defaultSplitValueType} // Property tells which type of value(% or $) user added while adding Split.
          splitType={item?.SplitType?.SplitType}
          isTransactionAdminOrOwner={isTransactionAdminOrOwner}
          isDisabledField={isDisabledField}
          isRestricted={item?.SplitType?.IsRestricted}
          isDynamicSplit
        />
      ) : (
        <EditDynamicSplit
          splitItem={item}
          autocompleteSplitNames={autocompleteSplitNames}
          splitType={item?.SplitSide}
          updateSplitValue={updateSplitValue}
          isTransactionAdminOrOwner={isTransactionAdminOrOwner}
          transactionId={transactionId}
          fetchFinancials={fetchFinancials}
          isValidate={isValidate}
          onCancel={() => {
            setIsEditState(false);
            setEditFieldKey(null);
          }}
        />
      )}

      {/* Delete Confirmation Dialog */}

      <ConfirmationDialog
        onReject={() => setShowConfirmationModal(false)}
        onConfirm={handleDeleteSplitItem}
        isOpen={showConfirmationModal}
        confirmText="Delete"
        isPending={confirmationLoading}
        className={styles.deleteSplitDialog}
      >
        <div className={styles.content}>
          Are you sure you want to delete <b>{item?.SplitType?.SplitTitle}</b>?
        </div>
      </ConfirmationDialog>
    </Fragment>
  );
};
