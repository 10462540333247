import { useEffect, useCallback, useState } from 'react';
import { getOfferLinkDetailsByUUID } from 'api/offers';
import { OfferSubmission } from './OfferSubmission';

import styles from './styles.module.scss';
import moment from 'moment';

export const ExternalOffer = (props) => {
  const { match } = props;
  const UUID = match?.params?.uuid;
  const [isPending, setIsPending] = useState(true);
  const [offerLinkDetails, setOfferLinkDetails] = useState({});
  const [isError, setIsError] = useState({
    status: false,
    resObj: null,
    message: '',
    type: '',
  });

  const isInactiveLink = (data) => {
    const { LinkActiveStartDate } = data.value;
    return moment() < moment(LinkActiveStartDate);
  };

  const setOfferError = (type, message) => {
    setIsError({
      ...isError,
      resObj: null,
      status: true,
      message,
      type,
    });
  };

  const fetchOfferLinkDetails = useCallback(async (UUID) => {
    setIsPending(true);
    const { data } = await getOfferLinkDetailsByUUID({ UUID });
    if (data && data?.value) {
      if (isInactiveLink(data)) {
        setOfferError('InactiveLink', data.errorMessage);
      } else {
        setOfferLinkDetails(data.value);
      }
    } else if (data && !data?.value && data?.statusCode == 200) {
      setOfferError('LinkExpired', data.errorMessage);
    } else {
      setOfferError('NoRecord', 'Offer Link Not Found!');
    }

    setIsPending(false);
  }, []);
  useEffect(() => {
    if (UUID) fetchOfferLinkDetails(UUID);
  }, [UUID]);

  return (
    <div className={styles.externalOffers}>
      <OfferSubmission
        offerLinkDetails={offerLinkDetails}
        isPending={isPending}
        isError={isError}
      />
    </div>
  );
};
