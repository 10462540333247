import {
  PDFField,
  PDFForm,
  PDFTextField,
  PDFDocument,
  PDFPage,
  PDFString,
  PDFRadioGroup,
} from 'pdf-lib';
import { v4 as uuidv4 } from 'uuid';

import { PDF_FIELD_TYPE } from 'app-constants';
import { PDFEditorFieldType, PDFEditorPagesType, QuestionFieldType } from 'types';
import { allowedPDFFieldType, getPDFFieldType } from 'utils';

export const getPDFEditorField = (PDF: PDFDocument, form: PDFForm): PDFEditorFieldType[] => {
  const fields = form.getFields();
  const result: PDFEditorFieldType[] = [];
  const pages = PDF.getPages();

  for (const field of fields) {
    const type = getPDFFieldType(field);
    const isRadio = type === PDF_FIELD_TYPE.PDFRadioGroup;

    if (allowedPDFFieldType(type) && !field.isReadOnly() && field?.acroField?.getWidgets) {
      const widgets = field.acroField.getWidgets();
      const values = isRadio ? (field as PDFRadioGroup).getOptions() : [];

      if (widgets.length) {
        const groupName = uuidv4();
        for (let i = 0; i < widgets.length; i++) {
          const widget = widgets[i];

          const { height, width, x, y } = widget.getRectangle();

          let pageNumber = pages.findIndex((x) => x.ref === widget.P());

          if (pageNumber < 0) break;
          const page = pages[pageNumber];
          if (!page) break;

          const pageHeight = page.getHeight();

          result.push({
            id: uuidv4(),
            name: field.getName(),
            groupName,
            isNewGroup: false,
            isNew: false,
            isUpdated: false,
            page: String(pageNumber),
            type: getPDFFieldType(field),
            widgetId: i,
            value: isRadio ? values[i] : undefined,
            attributes: {
              height,
              width,
              x,
              y: pageHeight - y - height,
              updatedY: y,
              isMultiLine: (field as PDFTextField).isMultiline
                ? (field as PDFTextField).isMultiline()
                : false,
            },
          });
        }
      }
    }
  }

  return result;
};

const getFieldName = (form: PDFForm, editorField: PDFEditorFieldType, useGroupName?) => {
  let fieldName = editorField.name;

  if (!form.getFieldMaybe(fieldName)) {
    fieldName = useGroupName ? editorField.groupName : editorField.id;
  }
  return fieldName;
};

const handleDeleteRadioGroup = (form: PDFForm, deletedFields: PDFEditorFieldType[]) => {
  if (deletedFields.length) {
    const radioGroup = form.getRadioGroup(deletedFields[0].name);
    if (deletedFields.length === radioGroup.getOptions().length) {
      const field = form.getField(deletedFields[0].name);
      handlePDFEditorDeleteField(form, field);
    } else {
      const sortedFields = deletedFields.sort((a, b) => (a.widgetId > b.widgetId ? 1 : -1));
      let count = 0;

      for (const deletedField of sortedFields) {
        const widgetId = deletedField.widgetId - count;
        if (widgetId >= 0) {
          radioGroup.acroField.removeWidget(widgetId);
        }
        count++;
      }
    }
  }
};

const handleUpdateRadioGroup = (form: PDFForm, updatedFields: PDFEditorFieldType[]) => {
  if (updatedFields.length) {
    const groupName = getFieldName(form, updatedFields[0]);
    const field = form.getField(groupName);

    for (const editorField of updatedFields) {
      handlePDFEditorUpdateField(field, editorField);
    }
  }
};

const handleAddNewRadioGroup = (
  form: PDFForm,
  pages: PDFPage[],
  newRadioFields: PDFEditorFieldType[],
  isExistingGroup = false,
) => {
  if (newRadioFields.length) {
    let radioGroup: PDFRadioGroup;
    if (isExistingGroup) {
      radioGroup = form.getRadioGroup(newRadioFields[0].name);
    } else {
      const groupName = getFieldName(form, newRadioFields[0]);
      radioGroup = form.createRadioGroup(groupName);
    }

    for (const newField of newRadioFields) {
      const page = pages[parseInt(newField.page)];
      if (page && newField.value) {
        const attributes = newField.attributes;

        radioGroup.addOptionToPage(newField.value, page, {
          x: attributes.x,
          y: attributes.updatedY,
          height: attributes.height,
          width: attributes.width,
          borderWidth: 0,
        });
      }
    }
  }
};

const handleNewFreeTextField = (form: PDFForm, page: PDFPage, editorField: PDFEditorFieldType) => {
  const fieldName = getFieldName(form, editorField);

  const textField = form.createTextField(fieldName);
  const attributes = editorField.attributes;

  if (attributes.isMultiLine) {
    textField.enableMultiline();
  }
  textField.addToPage(page, {
    x: attributes.x,
    y: attributes.updatedY,
    borderWidth: 0,
    height: attributes.height,
    width: attributes.width,
  });
  textField.setFontSize(0);
};

const handleNewCheckBoxField = (form: PDFForm, page: PDFPage, editorField: PDFEditorFieldType) => {
  const fieldName = getFieldName(form, editorField);

  const checkBoxField = form.createCheckBox(fieldName);
  const attributes = editorField.attributes;

  checkBoxField.addToPage(page, {
    x: attributes.x,
    y: attributes.updatedY,
    borderWidth: 0,
    height: attributes.height,
    width: attributes.width,
  });
};

const handleNewSignatureField = (
  pdfDoc: PDFDocument,
  form: PDFForm,
  page: PDFPage,
  editorField: PDFEditorFieldType,
) => {
  const fieldName = getFieldName(form, editorField);

  const attributes = editorField.attributes;

  const signatureDict = pdfDoc.context.obj({
    Type: 'Sig',
    Filter: 'Adobe.PPKLite',
    SubFilter: 'adbe.pkcs7.detached',
    M: PDFString.fromDate(new Date()),
  });
  const signatureDictRef = pdfDoc.context.register(signatureDict);

  const widgetDict = pdfDoc.context.obj({
    Type: 'Annot',
    Subtype: 'Widget',
    FT: 'Sig',
    Rect: [
      attributes.x,
      attributes.updatedY,
      attributes.x + attributes.width,
      attributes.updatedY + attributes.height,
    ],
    V: signatureDictRef,
    T: PDFString.of(fieldName),
    F: 4,
    P: page.ref,
  });

  const widgetDictRef = pdfDoc.context.register(widgetDict);

  page.node.addAnnot(widgetDictRef);
  form.acroForm.addField(widgetDictRef);
};

export const getFieldDefaultSize = (fieldType: QuestionFieldType) => {
  if (fieldType === PDF_FIELD_TYPE.PDFTextField || fieldType === PDF_FIELD_TYPE.PDFSignature) {
    return { width: 238, height: 47 };
  } else {
    return { width: 25, height: 25 };
  }
};

const handlePDFEditorAddField = (
  pdfDoc: PDFDocument,
  form: PDFForm,
  page: PDFPage,
  editorField: PDFEditorFieldType,
) => {
  switch (editorField.type) {
    case PDF_FIELD_TYPE.PDFTextField:
      handleNewFreeTextField(form, page, editorField);
      break;
    case PDF_FIELD_TYPE.PDFCheckBox:
      handleNewCheckBoxField(form, page, editorField);
      break;
    case PDF_FIELD_TYPE.PDFSignature:
      handleNewSignatureField(pdfDoc, form, page, editorField);
      break;

    default:
      break;
  }
};

const handlePDFEditorDeleteField = (form: PDFForm, field: PDFField) => {
  while (field.acroField.getWidgets().length) {
    field.acroField.removeWidget(0);
  }
  form.removeField(field);
};

const handlePDFEditorUpdateField = (field: PDFField, editorField: PDFEditorFieldType) => {
  const attributes = editorField.attributes;

  if (field?.acroField?.getWidgets) {
    const widget = field.acroField.getWidgets()?.[editorField.widgetId];
    if (widget) {
      const updatedAttributes = {
        x: attributes.x,
        y: attributes.updatedY,
        width: attributes.width,
        height: attributes.height,
      };
      widget.setRectangle(updatedAttributes);
    }
  }
  if (getPDFFieldType(field) === PDF_FIELD_TYPE.PDFTextField) {
    if (attributes.isMultiLine) {
      (field as PDFTextField).enableMultiline();
    }
  }
};

interface PDFFieldGroup {
  [groupName: string]: {
    groupName: string;
    isNewGroup: boolean;
    fields: PDFEditorFieldType[];
  };
}

const handlePDFEditorRadioGroups = (
  form: PDFForm,
  pages: PDFPage[],
  radioGroups: PDFFieldGroup,
) => {
  for (const radioGroup of Object.values(radioGroups)) {
    if (radioGroup.isNewGroup) {
      const fields = radioGroup.fields.filter((f) => f.isNew && !f.isDeleted);
      if (fields.length) {
        handleAddNewRadioGroup(form, pages, fields);
      }
    } else {
      const deletedFields = radioGroup.fields.filter((f) => f.isDeleted);
      const newFields = radioGroup.fields.filter((f) => f.isNew && !f.isDeleted);
      const updatedFields = radioGroup.fields.filter(
        (f) => f.isUpdated && !f.isNew && !f.isDeleted,
      );

      if (deletedFields.length) {
        handleDeleteRadioGroup(form, deletedFields);
      }
      if (newFields.length) {
        handleAddNewRadioGroup(form, pages, newFields, true);
      }
      if (updatedFields.length) {
        handleUpdateRadioGroup(form, updatedFields);
      }
    }
  }
};

export const updatePDFEditorDocument = async (
  pdfDoc: PDFDocument,
  fields: PDFEditorFieldType[],
  fileName = 'modified-file.pdf',
  base64 = false,
) => {
  const form = pdfDoc.getForm();
  const pages = pdfDoc.getPages();

  const updatedFields = fields.filter((f) => f.type !== PDF_FIELD_TYPE.PDFRadioGroup);
  const radioFields = fields.filter((f) => f.type === PDF_FIELD_TYPE.PDFRadioGroup);

  for (const editorField of updatedFields) {
    if (editorField.isNew && !editorField.isDeleted) {
      const pageNumber = parseInt(editorField.page);
      handlePDFEditorAddField(pdfDoc, form, pages[pageNumber], editorField);
    } else {
      const field = form.getFieldMaybe(editorField.name);

      if (field) {
        if (editorField.isDeleted) {
          handlePDFEditorDeleteField(form, field);
        } else if (editorField.isUpdated) {
          handlePDFEditorUpdateField(field, editorField);
        }
      }
    }
  }
  const radioGroups: PDFFieldGroup = {};
  for (const radioEditorField of radioFields) {
    if (!radioGroups[radioEditorField.groupName])
      radioGroups[radioEditorField.groupName] = {
        groupName: radioEditorField.groupName,
        isNewGroup: !!radioEditorField.isNewGroup,
        fields: [],
      };

    radioGroups[radioEditorField.groupName].fields.push(radioEditorField);
  }

  handlePDFEditorRadioGroups(form, pages, radioGroups);

  if (base64) {
    return pdfDoc.saveAsBase64();
  } else {
    const pdfDocument = await pdfDoc.save();
    const blob = new Blob([pdfDocument], { type: 'application/pdf' });
    const file = new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type: 'application/pdf',
    });

    return file;
  }
};

export const getPDFEditorPagesAndFields = (
  pdfDoc: PDFDocument,
): [PDFEditorPagesType, PDFEditorFieldType[]] => {
  const pages = pdfDoc.getPages();

  const form = pdfDoc.getForm();
  const fields = getPDFEditorField(pdfDoc, form);

  const resultPages: PDFEditorPagesType = pages.reduce((p, c, i) => {
    p[String(i)] = {
      height: c.getHeight(),
      width: c.getWidth(),
      pageIndex: i,
    };
    return p;
  }, {});

  return [resultPages, fields];
};
