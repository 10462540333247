import { createSelector } from 'reselect';
import { get } from 'lodash-es';

const localState = ({ drawers }) => get(drawers, 'notifications');

export const getIsNotificationsDrawerOpenSelector = createSelector(
  localState,
  ({ open }) => !!open,
);

export const geNotificationsDrawerParamsSelector = createSelector(
  localState,
  ({ params }) => params,
);
