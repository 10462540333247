import PropTypes from 'prop-types';

import { Document } from './Document';

import styles from './styles.module.scss';

export const Documents = ({ iterationData, offerData }) => {
  const { OfferIterationDocument: documents } = iterationData;

  return (
    <div className={styles.documents}>
      <p className={styles.title}>Documents</p>
      {documents?.length ? (
        <>
          {documents.map((d) => (
            <Document key={d.Id} document={d} iterationData={iterationData} offerData={offerData} />
          ))}
        </>
      ) : (
        <p className={styles.disclaimer}>No documents uploaded!</p>
      )}
    </div>
  );
};

Documents.propTypes = {
  iterationData: PropTypes.object,
  offerData: PropTypes.object,
};

Documents.defaultProps = {
  iterationData: {},
  offerData: {},
};
