import React from 'react';

const Email = ({ color, ...props }: React.SVGAttributes<any>) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="4"
      width="16"
      height="12"
      rx="2"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M2 5L9.4 10.55C9.75556 10.8167 10.2444 10.8167 10.6 10.55L18 5"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export default Email;
