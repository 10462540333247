import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getThreadsListSelector } from 'store/selectors/sockets/threads';
import { THIRD_PARTY } from 'settings/constants/roles';

import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';
import { getUserId, getUserRolesSelector } from 'store/selectors/user';
import { getQuotesDataSelector } from 'store/selectors/quotes';
import { getIfExistThread } from 'helpers';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';

const Comment = ({ className, quotesIds }) => {
  const dispatch = useDispatch();
  const quotes = useSelector(getQuotesDataSelector);
  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRolesSelector);
  const threads = useSelector(getThreadsListSelector);

  const isUserThirdParty = () => userRole[0] === THIRD_PARTY;

  const onComment = useCallback(() => {
    let threadRecipient;

    const quote = quotes.filter((q) => q.Id === quotesIds[0])[0];
    if (!isUserThirdParty()) {
      threadRecipient = quote?.Partner;
      if (quote?.Partners) {
        threadRecipient = quote?.Partners[0];
      }
    } else {
      threadRecipient = quote?.Requestor;
    }

    const thread = getIfExistThread(
      threads,
      [threadRecipient?.Id, userId],
      ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT,
    );

    dispatch(openMessagesDrawerAction(true));

    if (!thread) {
      const firstName = isUserThirdParty()
        ? threadRecipient?.FirstName
        : threadRecipient?.ThirdParty?.User?.FirstName;
      const lastName = isUserThirdParty()
        ? threadRecipient?.LastName
        : threadRecipient?.ThirdParty?.User?.LastName;
      const name = isUserThirdParty()
        ? `${firstName} ${lastName || ''}`
        : `${firstName} ${lastName || ''} (${threadRecipient?.BusinessName || ''})`;

      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
          params: {
            threadId: thread?.Id || null,
            participants: [
              {
                id: threadRecipient?.Id,
                name,
                value: threadRecipient.Id,
                role: THIRD_PARTY,
                avatarUrl: threadRecipient?.LogoUrl || '',
                firstName,
                lastName,
              },
            ],
          },
        }),
      );
    } else {
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.CHAT,
          params: {
            threadId: thread.Id,
          },
        }),
      );
    }
  }, [dispatch, quotesIds, threads, userId]);

  return (
    <div
      onClick={onComment}
      className={classNames(styles.commentWrapper, { [styles.isUnread]: false }, className)}
    >
      <Icon className={styles.icon} testid="comment_icon" variant={Icon.COMMENT} />
    </div>
  );
};

Comment.propTypes = {
  className: PropTypes.string,
  quotesIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
    .isRequired,
  quotesIdsWithStatus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      type: PropTypes.string,
    }),
  ),
  params: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
  }),
};

Comment.defaultProps = {
  className: '',
  params: {},
};

export default Comment;
