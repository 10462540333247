import { cloneDeep, get } from 'lodash-es';
import { ERROR } from 'settings/constants/apiState';

export const handleDeactivateItem = (state, { payload }) => {
  const meta = get(payload, 'meta');
  const requestState = get(payload, 'state');
  const items = cloneDeep(state.data) || [];

  if (requestState === ERROR) {
    return { ...state };
  }

  if (meta?.id) {
    const itemIndexInList = items.findIndex((item) => item?.Id === meta?.id);
    if (itemIndexInList !== -1) {
      items[itemIndexInList].IsActive = false;
    }
  }

  return {
    ...state,
    data: items,
  };
};

export const handleActivateItem = (state, { payload }) => {
  const meta = get(payload, 'meta');
  const requestState = get(payload, 'state');
  const items = cloneDeep(state.data) || [];

  if (requestState === ERROR) {
    return { ...state };
  }

  if (meta?.id) {
    const itemIndexInList = items.findIndex((item) => item?.Id === meta?.id);
    if (itemIndexInList !== -1) {
      items[itemIndexInList].IsActive = true;
    }
  }

  return {
    ...state,
    data: items,
  };
};

export const handleGetListOfItems =
  (initialData) =>
  (state, { payload }) => ({
    ...state,
    state: payload.state,
    data:
      payload?.options && payload?.options?.add
        ? [...state.data, ...get(payload, 'data.result', [])]
        : get(payload, 'data.result', initialData.data),
    meta: get(payload, 'meta', initialData.meta),
  });

export const sortItems = (state, { payload }) => ({
  ...state,
  sort: {
    ...(state.sort || {}),
    order: payload.order || state.sort?.order,
    fields: payload.fields || state.sort?.fields,
  },
});

export const searchItems = (state, { payload }) => ({
  ...state,
  search: {
    ...(state.search || {}),
    ...(payload || {}),
  },
});
