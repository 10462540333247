import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ProfileIcons } from 'components/ClientsDrawer/ProfileClient/Icons';

import styles from './styles.module.scss';

const InfoItem = (props) => {
  const { className, label, value } = props;

  return (
    <div className={classNames(styles.infoItem, className)}>
      <div className={styles.iconLabel}>
        <ProfileIcons name={label} />
        <div className={styles.label}>{label}</div>
      </div>
      <div testid={label} className={styles.value}>
        {value}
      </div>
    </div>
  );
};

InfoItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.node,
  ]),
};

InfoItem.defaultProps = {
  className: '',
  label: '',
  value: null,
};

export default InfoItem;
