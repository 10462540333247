import { routes } from 'settings/navigation/routes';
import { CLIENT, AGENT, THIRD_PARTY } from 'settings/constants/roles';

import { getLinksByRoles } from './common';

export const propertiesLinks = () => ({
  [CLIENT]: [
    { link: routes.feed, title: 'Feed' },
    { link: routes.search, title: 'Search' },
    { link: routes.mySearches, title: 'My Searches' },
    { link: routes.clientTours, title: 'Tours' },
  ],
  [AGENT]: [
    { link: routes.feed, title: 'Feed' },
    { link: routes.search, title: 'Search' },
    { link: routes.mySearches, title: 'My Searches' },
    { link: routes.clientSearches, title: 'Client Searches' },
    { link: routes.clientTours, title: 'Tours' },
  ],
  [THIRD_PARTY]: [
    { link: routes.feed, title: 'Feed' },
    { link: routes.search, title: 'Search' },
    { link: routes.mySearches, title: 'My Searches' },
  ],
});

/**
 * @param  {} roles = []
 * @returns array of header links [{ link, title }]
 */
export const getPropertiesLinksByRoles = getLinksByRoles(propertiesLinks);
