import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import { PENDING } from 'settings/constants/apiState';
import { AGENT_TYPE } from 'settings/constants/drawers';

import { onBoardingSignUpEffect } from 'store/effects';
import { getUserDataSelector, getAgentTypeSelector } from 'store/selectors/user';

import { ValidationSchema } from './validation';

import { OnboardingContinueButton } from '../components/OnboardingContinueButton';
import { OnboardingSkipButton } from '../components/OnboardingSkipButton';

import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import AreaOperations from 'pages/OnBoarding/components/Forms/AboutForm/AreaOperations';
import { Question } from 'pages/RequestQuote/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';

import styles from './styles.module.scss';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_OWNER } from 'settings/constants/roles';

const AreasOfOperationForm = (props) => {
  const { className, onNext, stageIndex, Controls } = props;
  const dispatch = useDispatch();
  const { onBoarding, signUpRole } = useSelector(getOnBoardingData);
  const isPending = onBoarding.state === PENDING;
  const user = useSelector(getUserDataSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        onBoardingSignUpEffect({ ...values, stageIndex }, { isCommonInfo: true }, (err) => {
          if (!err) {
            onNext();
          }
        }),
      );
    },
    [stageIndex, onNext, dispatch],
  );

  const formik = useFormik({
    initialValues: {
      areasOfOperation: user?.[signUpRole]?.AreasOfOperation || [],
    },
    validationSchema: ValidationSchema,
    validateOnChange: true,
    onSubmit,
  });

  return (
    <OnBoardingWrapper className={styles.stageWrap}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question>What are your areas of operation?</Question>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <AnswersContainer>
            <AreaOperations formik={formik} isPending={isPending} />
          </AnswersContainer>
          <ButtonsContainer>
            {agentType === AGENT_TYPE.Team && agentRole !== TEAM_OWNER ? (
              <>
                <OnboardingContinueButton
                  isPending={isPending}
                  disabled={!formik.isValid}
                  testid="next"
                  htmlType="submit"
                  className={classNames(
                    {
                      [styles.active]: formik.values?.file,
                    },
                    styles.submit,
                  )}
                />
                <OnboardingSkipButton testid="area_skip" onClick={onNext} className={styles.skip} />
              </>
            ) : (
              <OnboardingContinueButton
                htmlType="submit"
                testid="next"
                isPending={isPending}
                className={styles.submitBtn}
                disabled={!formik.isValid}
              />
            )}
          </ButtonsContainer>
        </form>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

AreasOfOperationForm.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

AreasOfOperationForm.defaultProps = {
  className: '',
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default AreasOfOperationForm;
