import PropTypes from 'prop-types';

interface PropType {
  className?: string;
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}

const ArrowRightFull = ({ className, onClick }: PropType) => (
  <div className={className} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5 13H18M18 13L13 8M18 13L13 18"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

ArrowRightFull.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ArrowRightFull.defaultProps = {
  className: '',
};

export default ArrowRightFull;
