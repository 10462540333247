import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const Add = ({ className, onClick, color, testid }) => {
  const getIcon = useCallback(() => {
    switch (color) {
      case Add.ORANGE_COLOR:
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="42"
              height="42"
              rx="21"
              fill="#FF576D"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M22 15V29"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 22H29"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Add.LIGHT_BLUE_COLOR:
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="1" y="1" width="42" height="42" rx="21" fill="white" />
            <rect x="1" y="1" width="42" height="42" rx="21" fill="#163C5A" />
            <rect x="1" y="1" width="42" height="42" rx="21" stroke="white" strokeWidth="2" />
            <path
              d="M22 15V29"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 22H29"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Add.ORANGE_CIRCLE:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#FF576D" />
            <path d="M7 12H17" stroke="#FFF2F3" strokeWidth="2" strokeLinecap="round" />
            <path d="M12 17L12 7" stroke="#FFF2F3" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      case Add.COLLAPSE:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path
              d="M16 11V21"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M11 16H21" stroke="#252D44" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      default: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM9 6C9 5.44772 9.4477 5 10 5C10.5523 5 11 5.44772 11 6V9H14C14.5523 9 15 9.4477 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.4477 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.4477 5.44772 9 6 9H9V6Z"
              fill="#163C5A"
            />
          </svg>
        );
      }
    }
  }, [color]);

  return (
    <div testid={testid} className={className} onClick={onClick}>
      {getIcon()}
    </div>
  );
};

Add.BLUE_COLOR = 'blue';
Add.LIGHT_BLUE_COLOR = 'blue_light';
Add.ORANGE_COLOR = 'orange';
Add.ORANGE_CIRCLE = 'orange_circle';
Add.COLLAPSE = 'collapse';

Add.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  testid: PropTypes.string,
};

Add.defaultProps = {
  className: '',
  onClick: () => {},
  color: Add.BLUE_COLOR,
  testid: undefined,
};

export default Add;
