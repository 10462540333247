import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { useRequest } from 'hooks';
import { getCategories } from 'api/admin';
import { PENDING } from 'settings/constants/apiState';
import { Button, Select } from 'components';
import Input from 'components/Form/Input';
import { SearchIcon } from 'components/Icons';
import { searchPartnersEffect } from 'store/effects/adminPanel';
import { getPartnersSearchSelector, getPartnersSelector } from 'store/selectors/adminPanel';

import { getCategoriesOptions } from '../Partner/Form/helpers';
import styles from './styles.module.scss';

const Filters = () => {
  const dispatch = useDispatch();
  const { state } = useSelector(getPartnersSelector);
  const search = useSelector(getPartnersSearchSelector);
  const onSubmit = useCallback((values) => dispatch(searchPartnersEffect(values)), [dispatch]);
  const isPending = state === PENDING;

  const formik = useFormik({
    initialValues: search,
    enableReinitialize: true,
    onSubmit,
  });

  const [{ loading, data: categoriesOptions }] = useRequest({
    request: getCategories,
    format: getCategoriesOptions,
    params: {
      active: true,
    },
  });

  const onCategoriesChange = useCallback(
    (e, values) => formik.setFieldValue('categories', values),
    [formik],
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.filtersFormWrapper}>
        <div className={styles.filtersWrapper}>
          <div className={styles.filterWrapper}>
            <Input
              id="searchString"
              className={styles.input}
              type="text"
              name="searchString"
              placeholder="Search"
              error={formik.touched.searchString ? formik.errors.searchString : ''}
              value={formik.values.searchString}
              onChange={formik.handleChange}
              disabled={isPending}
              variant={Input.LIGHT_FULL}
              testid="search_input"
            />
          </div>
          <div className={styles.filterWrapper}>
            <Input
              id="name"
              className={styles.input}
              type="text"
              name="name"
              placeholder="Name"
              error={formik.touched.name ? formik.errors.name : ''}
              value={formik.values.name}
              onChange={formik.handleChange}
              disabled={isPending}
              variant={Input.LIGHT_FULL}
              testid="name_input"
            />
          </div>
          <div className={styles.filterWrapper}>
            <Select
              id="categories"
              name="categories"
              search
              multiple
              options={
                loading || !categoriesOptions
                  ? [
                      {
                        name: 'Loading...',
                        value: '',
                      },
                    ]
                  : categoriesOptions
              }
              value={formik.values.categories}
              placeholder="Categories"
              onSelect={onCategoriesChange}
              disabled={isPending}
              className={{ wrapper: styles.selectWrapper }}
              error={formik.touched.categories ? formik.errors.categories : ''}
              variant={Select.LIGHT_FULL}
              testid="categories_select"
            />
          </div>
        </div>
        {formik.dirty ? (
          <Button
            type="submit"
            className={styles.searchButton}
            isPending={isPending}
            title={<SearchIcon />}
            testid="search_button"
          />
        ) : (
          <Button
            type="button"
            className={styles.searchButton}
            title={<SearchIcon />}
            testid="search_button"
          />
        )}
      </div>
    </form>
  );
};

Filters.propTypes = {
  searchString: PropTypes.string,
  name: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};
Filters.defaultProps = {};

export default Filters;
