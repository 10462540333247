import { EditStageInfo } from '../EditStageInfo';

import styles from '../styles.module.scss';

export const EditNotes = ({ data, onEdit }) => {
  const getInfo = () => {
    const { DisplayBookingPageNotes, SendAppointmentConfirmationNotes } = data;

    let text = '';
    if (DisplayBookingPageNotes) {
      text += 'Display note on booking page';
    }

    if (SendAppointmentConfirmationNotes) {
      const info = 'Send note upon appointment confirmation';
      text += text ? `, ${info}` : info;
    }

    return text ? text : '-';
  };

  return (
    <div className={styles.editForm}>
      <EditStageInfo icon="notes" title="Notes" info={getInfo()} onClick={onEdit} />
    </div>
  );
};
