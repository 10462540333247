import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const Drafts = memo(({ className }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 4.00085L16 8.82939C16 9.13984 16.1045 9.43757 16.2905 9.65709C16.4765 9.87662 16.7287 9.99994 16.9917 9.99994L21 10.0009"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.7087 8.70884L16.3043 4.29386C16.1167 4.1058 15.8627 4.00085 15.5971 4.00085H10.4916C9.66644 4.00085 8.99636 4.66386 8.99248 5.48898C8.97873 8.42184 8.9534 14.9726 8.98175 18.5348C8.98825 19.3518 9.65393 20.0009 10.4709 20.0009H19.5C20.3284 20.0009 21 19.3293 21 18.5009L21 9.41436C21 9.14956 20.8957 8.8963 20.7087 8.70884Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.00004 4H5.5C4.67157 4 4 4.67157 4 5.5L4 18.5C4 19.3284 4.67157 20 5.5 20H9.00004"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

export default Drafts;
