import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPropertyAddress } from 'helpers';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { setTransactionTasksLastStateEffect } from 'store/effects/transactions';
import { link } from 'settings/navigation/link';
import BaseNotification from '../../../BaseNotification';

import styles from './styles.module.scss';
import PropertyIcon from '../../../PropertyIcon';

const UpcomingDueDate = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const { TaskId, TransactionId, Address } = Payload || {};
  const getSubtitle = () => getPropertyAddress(Address);

  const onClickHandler = () => {
    if (TransactionId) {
      dispatch(openNotificationsDrawerEffect({ open: false }));

      if (TaskId) {
        dispatch(
          setTransactionTasksLastStateEffect({ taskId: TaskId, transactionId: TransactionId }),
        );
      }
      history.replace(link.toTransactionTasks(TransactionId));
    }
  };

  return (
    <div className={classNames(styles.notificationType, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        subtitle={getSubtitle()}
        avatarPlaceholder={<PropertyIcon />}
        onClick={onClickHandler}
        groupType={groupType}
        severityLevel={SeverityLevel}
      />
    </div>
  );
};

UpcomingDueDate.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    TaskName: PropTypes.string,
    AssigneeFirstName: PropTypes.string,
    AssigneeLastName: PropTypes.string,
    TransactionId: PropTypes.number,
    TaskId: PropTypes.number,
    Address: PropTypes.shape({
      Line1: PropTypes.string,
      City: PropTypes.string,
      State: PropTypes.string,
    }),
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

UpcomingDueDate.defaultProps = {
  className: '',
};

export default UpcomingDueDate;
