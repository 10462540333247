import { Icons } from '../../../Icons';
import { IconText } from '../IconText';

import styles from '../styles.module.scss';

export const EditNotes = ({ data, onEdit }) => {
  const getInfo = () => {
    const { DisplaySubmissionPageNotes, SendOfferSubmissionNotes } = data;

    let text = '';
    if (DisplaySubmissionPageNotes) {
      text += 'Display a note on the submission page';
    }

    if (SendOfferSubmissionNotes) {
      const info = 'Send a note upon offer submission';
      text += text ? `, ${info}` : info;
    }

    return text ? text : '-';
  };

  return (
    <div className={styles.editForm}>
      <IconText
        text={'Notes'}
        infoText={getInfo()}
        variant={'notes-bg'}
        className={styles.editFormInfo}
      />
      <div className={styles.editIcon} onClick={onEdit}>
        <Icons variant={'edit'} />
      </div>
    </div>
  );
};
