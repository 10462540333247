export const templateMappedAccordingToIntents = {
  create_task: 'CREATE_TASK',
  create_milestone: 'CREATE_MILESTONE',
  delete_milestone: 'DELETE_MILESTONE',
  edit_task: 'EDIT_TASK',
  status_change: 'CHANGE_TRANSACTION_STATUS',
};

export const ERROR_TEMPLATES = {
  INCORRECT_INFORMATION_FIX: 'INCORRECT_INFORMATION_FIX',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
};

export const CREATE_TRANSACTION_TEMPLATES = {
  CREATE_TRANSACTION: 'CREATE_TRANSACTION_1',
  CREATE_TRANSACTION_1: 'CREATE_TRANSACTION_2',
  CREATE_TRANSACTION_2: 'CREATE_TRANSACTION_3',
};
