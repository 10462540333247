import { useState } from 'react';

import { Modal } from 'components-antd';
import { TemplateList } from './components/TemplateList';
import TemplatePreview from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/Timeline/TemplatePreview';

import styles from './styles.module.scss';

export const TemplatesModal = (props) => {
  const { isOpen, onCancel, onSelect } = props;

  const [previewTemplateId, setPreviewTemplateId] = useState<string>();
  const [isTransactionModal, setIsTransactionModal] = useState(false);

  const onPreviewTemplate = (id) => {
    setPreviewTemplateId(id);
    setIsTransactionModal(true);
  };

  const onTemplateBack = () => {
    setPreviewTemplateId('');
    setIsTransactionModal(false);
  };

  const getModalStep = () => {
    if (previewTemplateId) {
      return (
        <TemplatePreview
          templateId={previewTemplateId}
          isTransactionModal={isTransactionModal}
          onBack={onTemplateBack}
          onSelect={onSelect}
        />
      );
    } else {
      return <TemplateList PreviewTemplate={onPreviewTemplate} onSelect={onSelect} />;
    }
  };

  const modalClose = () => {
    setPreviewTemplateId('');
    onCancel();
  };

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={modalClose}
      maskClosable={false}
      destroyOnClose
      className={styles.TaskModal}
    >
      {getModalStep()}
    </Modal>
  );
};
