import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';

import { commuteTime } from 'settings/constants/commuteTime';
import { appOpenModalEffect } from 'store/effects/app';
import { getModalSelector } from 'store/selectors/app';
import { Modal } from 'components';

import Commutes from './Commutes';
import CommuteEditor from './CommuteEditor';

import styles from './styles.module.scss';

const CommutesModal = ({ className, onNext, value, title, keepModalOpen, edit, formOnly }) => {
  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();
  const [values, setValues] = useState(value || []);
  const [showCommute, setShowCommute] = useState(null);
  const [activeCommuteIndex, setActiveCommuteIndex] = useState(0);

  useEffect(() => {
    setValues(value);
    setShowCommute(value.length < 1);
  }, [value]);

  if (modal.id !== CommutesModal.id) {
    return null;
  }

  const onEditCommute = (index) => {
    setActiveCommuteIndex(index);
    setShowCommute(true);
  };

  const onAddCommute = () => {
    const clonedValue = cloneDeep(values);
    clonedValue.push({ MaxCommuteTimeInMinutes: commuteTime.FIFTEEN_MINUTES });
    setValues(clonedValue);
    setActiveCommuteIndex(clonedValue.length - 1);
    setShowCommute(true);
  };

  const onChange = (val, index) => {
    values[index ?? activeCommuteIndex] = {
      ...(values[index ?? activeCommuteIndex] || {}),
      ...val,
    };
    setValues([...values]);
  };

  const onDelete = (index) => {
    const clonedValue = cloneDeep(values);
    clonedValue.splice(index, 1);
    setActiveCommuteIndex(0);
    setValues(clonedValue);
    if (clonedValue.length < 1) {
      setShowCommute(true);
    }
  };
  const onClose = ({ save }) => {
    onNext(save ? values : value);
    setShowCommute(false);
    dispatch(
      appOpenModalEffect(
        keepModalOpen.length ? { id: keepModalOpen, open: true } : { id: null, open: false },
      ),
    );
  };

  return (
    <Modal
      className={classNames(className)}
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      closeClassName={styles.close}
      innerHolderClassName={styles.innerHolder}
      label={title}
    >
      {showCommute || formOnly ? (
        <CommuteEditor
          value={values[edit || activeCommuteIndex] || {}}
          onChange={onChange}
          onCommuteSave={() => {
            if (formOnly) {
              onClose({ save: true });
            } else setShowCommute(false);
          }}
          activeCommuteIndex={edit || activeCommuteIndex}
          onClose={onClose}
        />
      ) : (
        <Commutes
          values={values}
          onDelete={onDelete}
          onClose={onClose}
          onClick={(index) => (index >= 0 ? onEditCommute(index) : onAddCommute())}
        />
      )}
    </Modal>
  );
};

CommutesModal.id = 'commutesPrefsModal';

CommutesModal.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
  edit: PropTypes.any,
  keepModalOpen: PropTypes.string,
  formOnly: PropTypes.bool,
};

CommutesModal.defaultProps = {
  className: '',
  onNext: () => {},
  value: [],
  keepModalOpen: '',
  formOnly: false,
};

export default CommutesModal;
