import {
  CANCEL_FORM_DOCUMENT,
  DELETE_DRAFT_FORM,
  GET_DRAFT_FORMS,
  GET_FORM_DETAILS,
  GET_TEAM_AGENT_FORM_DETAILS,
  PRVEVIEW_COMBINED_FORMS,
  PRVEVIEW_EXTERNAL_COMBINED_FORMS,
  REMOVE_EDITOR,
  REPLACE_EDITOR,
  REPLACE_SIGNATORY,
  RESEND_EMAIL,
  SEND_GENERIC_MESSAGE,
  VOID_FORM_DOCUMENT,
} from 'app-constants';
import { apiServer } from 'settings/web-services/api';

export const sendMessage = (cfg) => apiServer.post(SEND_GENERIC_MESSAGE, cfg);

export const cancelFormDocument = ({ message, ...cfg }) =>
  apiServer.post(CANCEL_FORM_DOCUMENT(cfg), { message });

export const voidFormDocument = ({ reason, formProcessId }) =>
  apiServer.post(VOID_FORM_DOCUMENT(formProcessId), { reason });

export const getDraftForms = () => apiServer.get(GET_DRAFT_FORMS);

export const deleteDraftForm = (cfg) => apiServer.post(DELETE_DRAFT_FORM, cfg);

export const getformdetails = (cfg) => apiServer.get(GET_FORM_DETAILS(cfg));

export const resendEmail = (cfg) => apiServer.post(RESEND_EMAIL, cfg);

export const replaceEditor = (cfg) => apiServer.post(REPLACE_EDITOR, cfg);

export const replaceSignatory = (cfg) => apiServer.post(REPLACE_SIGNATORY, cfg);

export const removeEditor = (cfg) => apiServer.post(REMOVE_EDITOR, cfg);

export const previewCombinedForm = ({ formProcessId, ...cfg }) =>
  apiServer.get(PRVEVIEW_COMBINED_FORMS(formProcessId), cfg);

export const previewExternalCombinedForm = ({ token, ...cfg }) =>
  apiServer.get(PRVEVIEW_EXTERNAL_COMBINED_FORMS(token), cfg);

export const getTeamAgentFormDetails = ({ agentId, formProcessId }) =>
  apiServer.get(GET_TEAM_AGENT_FORM_DETAILS(agentId, formProcessId));
