import { handleActions } from 'redux-actions';

import { setFeedV3CurrentPageInfoAction, setFeedV3PageInfoAction } from 'store/actions/feedv3';
import { get } from 'lodash-es';

const initialData = {
  pageInfo: {
    endCursor: null,
    hasNextPage: false,
    itemsLoaded: 0,
  },
  totalCount: {},
  currentFEPageInfo: {
    pageNumber: 1,
    renderingIndex: -1,
    fromListingDetailPage: false,
  },
};

export default handleActions(
  {
    [setFeedV3PageInfoAction as any]: (state, { payload }) => ({
      ...state,
      pageInfo: {
        endCursor: payload?.pageInfo?.endCursor,
        hasNextPage: payload?.pageInfo?.hasNextPage,
        itemsLoaded: payload?.pageInfo?.itemsLoaded,
      },
      totalCount: get(payload, 'totalCount', initialData.totalCount),
    }),
    [setFeedV3CurrentPageInfoAction as any]: (state, { payload }) => ({
      ...state,
      currentFEPageInfo: payload?.currentFEPageInfo,
    }),
  },
  initialData,
);
