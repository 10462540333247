import { TaskTemplate } from 'types';

import { Popover } from 'components-antd';
import { DeleteTask, List, EditPencil, Menu, TaskCard as TaskCardIcon } from 'components/Icons';

import styles from './styles.module.scss';
import { TransactionUserRoleMap } from 'settings/constants/transaction';

interface TaskCardProps {
  task: TaskTemplate;
  taskNumber: number;
  setEditTask: (num) => void;
  handleRemove: (num) => void;
  isViewMode: boolean;
}

export const TaskCard = ({
  task,
  taskNumber,
  setEditTask,
  handleRemove,
  isViewMode,
}: TaskCardProps) => {
  const printSubsection = () => {
    const taskRoles = task.AssigneeRoles?.map((i) => TransactionUserRoleMap[i] || i) || [];

    const roles =
      taskRoles.length > 2
        ? `${taskRoles.slice(0, 2).join(', ')} + ${taskRoles.length - 2}`
        : taskRoles.join(', ');

    const dueDate = `${task.Initial}${task.Operator}${task.DueDate}`;

    return `${roles}, ${dueDate}`;
  };

  return (
    <div className={styles.taskCard}>
      <div className={styles.taskData}>
        <TaskCardIcon className={styles.taskIcon} />
        <div className={styles.taskInfo}>
          <span className={styles.taskName}>{task.Name}</span>
          <span className={styles.taskMeta}>{printSubsection()}</span>
        </div>
      </div>
      <div className={styles.taskOptions}>
        <Popover
          content={
            <div>
              {isViewMode ? (
                <div className={styles.entityOption} onClick={() => setEditTask(taskNumber)}>
                  <List className={styles.icon} />
                  View
                </div>
              ) : (
                <>
                  <div className={styles.entityOption} onClick={() => setEditTask(taskNumber)}>
                    <EditPencil className={styles.icon} />
                    Edit
                  </div>
                  <div className={styles.entityOption} onClick={() => handleRemove(taskNumber)}>
                    <DeleteTask className={styles.icon} />
                    Remove
                  </div>
                </>
              )}
            </div>
          }
          className={styles.antdCustomPopover}
          trigger="hover"
          placement="bottomRight"
          overlayClassName={styles.entityOptionsDropDown}
        >
          <div>
            <Menu className={styles.menuIcon} />
          </div>
        </Popover>
      </div>
    </div>
  );
};
