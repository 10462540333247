import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { Counter } from 'components';
import { CounterAdd, CounterRemove } from 'components/Icons';
import { Checkbox } from 'components-antd';
import { PARKING_GARAGE_TYPES } from 'settings/constants/preferences';

import styles from './styles.module.scss';

interface ParkingPrefConfig {
  GarageOnly?: boolean;
  GarageType?: string;
  ImportanceAndWeight?: {
    Importance: string;
  };
  NumOfParkingSpaces: number;
}
interface ParkingsSelectorProps {
  parkingPrefs: ParkingPrefConfig;
  onEditParkingPrefs: (newValue: ParkingPrefConfig) => void;
}

export const ParkingsSelector: React.FC<ParkingsSelectorProps> = ({
  parkingPrefs,
  onEditParkingPrefs,
}) => {
  const [count, setCount] = useState(parkingPrefs?.NumOfParkingSpaces || 0);
  const [garageOnly, setGarageOnly] = useState(parkingPrefs?.GarageOnly || false);
  const [attachedGarage, setAttachedGarage] = useState(
    parkingPrefs?.GarageType || PARKING_GARAGE_TYPES.Detached,
  );

  const checkGarage = (e) => {
    setGarageOnly(e.target.checked ? true : false);
    if (e.target.checked === true) {
      onEditParkingPrefs({
        ...parkingPrefs,
        GarageOnly: true,
      });
    } else {
      setAttachedGarage(PARKING_GARAGE_TYPES.Detached);
      onEditParkingPrefs({
        ...parkingPrefs,
        GarageOnly: false,
        GarageType: PARKING_GARAGE_TYPES.Detached,
      });
    }
  };

  const checkGarageType = (e) => {
    setAttachedGarage(
      e.target.checked ? PARKING_GARAGE_TYPES.Attached : PARKING_GARAGE_TYPES.Detached,
    );
    onEditParkingPrefs({
      ...parkingPrefs,
      GarageType: e.target.checked ? PARKING_GARAGE_TYPES.Attached : PARKING_GARAGE_TYPES.Detached,
    });
  };

  return (
    <div className={styles.parkingsSelector}>
      <Counter
        testid="parking_counter"
        limit={{ min: 0 }}
        className={styles.counter}
        holderClassName={styles.counterHolder}
        numberClassName={styles.number}
        addClassName={classNames(styles.action, styles.add)}
        removeClassName={classNames(styles.action, styles.remove)}
        onChange={(val) => {
          setCount(count);
          onEditParkingPrefs({ ...parkingPrefs, NumOfParkingSpaces: val });
        }}
        value={count}
        AddComponent={(props) => <CounterAdd {...props} />}
        RemoveComponent={(props) => <CounterRemove {...props} />}
      />
      <div className={styles.additionalOptions}>
        <Checkbox
          className={classNames(styles.checkbox, 'mosaikCheckbox')}
          checked={garageOnly}
          defaultChecked={garageOnly}
          onChange={(e) => checkGarage(e)}
        >
          Garage Only
        </Checkbox>
        {garageOnly && (
          <Checkbox
            className={classNames(styles.checkbox, 'mosaikCheckbox')}
            checked={attachedGarage === PARKING_GARAGE_TYPES.Attached}
            defaultChecked={attachedGarage === PARKING_GARAGE_TYPES.Attached}
            onChange={(e) => checkGarageType(e)}
          >
            Must be attached?
          </Checkbox>
        )}
      </div>
    </div>
  );
};
