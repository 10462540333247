import { useSelector } from 'react-redux';

import { getDynamicFormSignatureConfigSelector } from 'store/selectors/requestFormProcess';
import { UserRoles } from '../UserRoles';
import { DownloadFilesWidget } from './DownloadFilesWidget';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';

export const SignatureWidgets = ({ allDocuments, hideDownload = false }) => {
  const { showUserRoles } = useSelector(getDynamicFormSignatureConfigSelector);

  return (
    <>
      {showUserRoles && <UserRoles />}

      {!hideDownload && (
        <DownloadFilesWidget
          documents={allDocuments}
          formProcessPublicId={dynamicManager.getFormProcessPublicId()}
          anonymousUserDetails={dynamicManager.getAnonymousDetails()}
        />
      )}
    </>
  );
};
