import { getNotificationTemplates } from 'api/admin';
import { getState } from 'store';
import { AuthToken, LocalStorage, Security } from 'services';
import { push } from 'connected-react-router';
import Api from 'store/effects/core/api';
import { setAppcuesUser } from 'integration/appcues';
import { userGetDataEffect } from 'store/effects/user';
import { getClientInstancesEffect } from 'store/effects/clientInstances';
import {
  checkRouteRequiresCompletedProfile,
  checkRouteRequiresPremiumPlan,
  matchProtectedRoutes,
} from 'settings/navigation/config';
import {
  appLogoutAction,
  appInitAction,
  appModalOpenAction,
  appDrawerSetClientIdAction,
  appDrawerSetAgentIdAction,
  appChangeChatAdviceStatusAction,
  requestGetNotificationMessagesAction,
  appSetShowCriteriaModalInviteClientAction,
  appSetShowProfileExpandedSectionAction,
  appSetConnectionTypeAction,
} from 'store/actions/app';
import { logout } from 'api/user';
import { showErrorMessage } from 'helpers/errors';
import { setHeaders, clearHeaders } from 'settings/web-services/api';
import { CLIENT, AGENT, mapRoles } from 'settings/constants/roles';

import { socketsInitEffect, socketsCloseEffect } from 'store/effects/sockets';
import { link } from 'settings/navigation/link';
import { getAgentTeamDetailEffect } from '../agentTeamDetail';
import { SubscriptionPlanLevel } from 'api/subscription';
import { routes } from 'settings/navigation/routes';
import { getCurrentPlanLevelEffect } from '../subscription';
import { getClientContextsEffect } from '../context';

export function appLogoutEffect() {
  return async (dispatch) => {
    try {
      await logout();
    } catch (err) {
      showErrorMessage(err);
    } finally {
      LocalStorage.clearLocalStorage();

      clearHeaders();

      dispatch(appLogoutAction());
      dispatch(socketsCloseEffect());

      dispatch(push('/login'));
    }
  };
}

export function appGetNotificationMessagesEffect(cfg = {}, options = {}, cb) {
  const requestParams = {
    action: requestGetNotificationMessagesAction,
    method: getNotificationTemplates,
  };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
}

function isRoutePartiallyProtected(pathname) {
  const showingRouteRgx = /^\/showing\/[a-zA-Z0-9-]+(?:\?.*)?(?:#.*)?$/;
  return (
    pathname.includes('verify-email') || pathname.includes('tidy') || showingRouteRgx.test(pathname)
  );
}

export function appInitEffect() {
  return async (dispatch) => {
    AuthToken.setOnLogoutHandler(() => {
      clearHeaders();

      dispatch(appLogoutAction());
      dispatch(socketsCloseEffect());

      dispatch(push('/login'));
    });

    const state = getState();
    // TODO Improve
    const isProtectedRoute = matchProtectedRoutes(state.router.location.pathname);
    const { pathname, search } = state.router.location;
    let loginUrl = '/login';
    if (pathname) {
      loginUrl += `?redirectUrl=${pathname}${search || ''}`;
    }

    const isCompletedProfileRequired = checkRouteRequiresCompletedProfile(
      state.router.location.pathname,
    );

    try {
      const { accessToken, refreshToken } = LocalStorage.getTokens();

      if (!Security.allowRestoreUserSession()) {
        dispatch(appInitAction({ auth: false }));
        if (isProtectedRoute) {
          dispatch(push(loginUrl));
        }
        return;
      }

      if (isProtectedRoute || isRoutePartiallyProtected(state.router.location.pathname)) {
        setHeaders({ accessToken });

        const { data } = await dispatch(userGetDataEffect({}, { showError: false }));

        if (data) {
          const { result: user } = data;
          //Set Appcues User
          setAppcuesUser(user);
          if (isProtectedRoute && isCompletedProfileRequired && !user?.CompletedProfile) {
            dispatch(push(link.toOnBoarding(mapRoles[user?.Roles[0]])));
          }

          //Set TokenContext to ensure accessToken will always match the logged in user (LoginContext)
          LocalStorage.setTokenContextFromEmail(user.Email);
        }

        if (data) {
          await redirectToPaywallOrOnboardingIfNeeded(data?.result, dispatch, state);
        }

        if (data) {
          const chatAdviceStatus = LocalStorage.getChatAdviceStatus();
          dispatch(appInitAction({ auth: true, chatAdviceStatus }));
          dispatch(socketsInitEffect());
          dispatch(appGetNotificationMessagesEffect());

          const { result: user } = data;
          if (user?.Roles?.includes(CLIENT)) {
            dispatch(getClientInstancesEffect());
          } else if (user?.Roles?.includes(AGENT)) {
            dispatch(getAgentTeamDetailEffect());
          }
        } else {
          if (isProtectedRoute) {
            LocalStorage.clearLocalStorage();
            dispatch(push(loginUrl));
          }

          dispatch(appInitAction({ auth: false }));
        }
      } else {
        dispatch(appInitAction({ auth: false }));
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        if (isProtectedRoute) {
          dispatch(push(loginUrl));
        }
      }

      dispatch(appInitAction({ auth: false }));
    }
  };
}

export async function redirectToPaywallOrOnboardingIfNeeded(user, dispatch, state) {
  let selfSignup = false;
  let isRedirected = false;
  if (user?.Roles?.includes(AGENT)) {
    selfSignup = user?.Agent?.WizardFinished === false;
    const planLevelResponse = await dispatch(getCurrentPlanLevelEffect({}, { showError: false }));
    const planLevel = planLevelResponse?.data?.result?.CurrentPlan;
    // for the new users signed-up from winter12
    const internalPlanName = planLevelResponse?.data?.result?.InternalPlanName;

    const IsComplimentaryPlan = planLevelResponse?.data?.result?.IsComplimentaryPlan;

    const doesRequirePremiumPlan = checkRouteRequiresPremiumPlan(state.router.location.pathname);
    const isVerifyEmailRoute = state.router.location.pathname.includes('verify-email');
    if (!isVerifyEmailRoute) {
      if (selfSignup) {
        isRedirected = true;
        dispatch(push(link.toOnBoardingV2Agent(mapRoles['Agent'])));
      } else if (
        doesRequirePremiumPlan &&
        planLevel &&
        planLevel !== SubscriptionPlanLevel.Premium &&
        internalPlanName !== null &&
        !IsComplimentaryPlan
      ) {
        isRedirected = true;
        dispatch(push(routes.paywall));
      }
    }
  }

  if (user?.Roles?.includes(CLIENT)) {
    const clientContextsResponse = await dispatch(getClientContextsEffect());
    const contexts = clientContextsResponse?.data?.result;

    const hasAnyActiveContext = contexts.length > 0;

    const doesRequirePremiumPlan = checkRouteRequiresPremiumPlan(state.router.location.pathname);

    if (doesRequirePremiumPlan && !hasAnyActiveContext) {
      isRedirected = true;
      dispatch(push(routes.paywall));
    }
  }

  return isRedirected;
}

export function appOpenModalEffect({ id, open }) {
  return (dispatch) => {
    dispatch(appModalOpenAction({ id, open }));
  };
}

export function setDrawerClientProfileId({ id }) {
  return (dispatch) => {
    dispatch(appDrawerSetClientIdAction({ id }));
  };
}

export function setDrawerAgentProfileId({ id, open, showExpandedSection }) {
  return (dispatch) => {
    dispatch(appDrawerSetAgentIdAction({ id, open, showExpandedSection }));
  };
}

export function setCriteriaSectionInviteFlow(open) {
  return (dispatch) => {
    dispatch(appSetShowCriteriaModalInviteClientAction(open));
  };
}
export function setProfileExpandedSection(open) {
  return (dispatch) => {
    dispatch(appSetShowProfileExpandedSectionAction(open));
  };
}
export function appChangeChatAdviceStatusEffect(value) {
  return (dispatch) => {
    LocalStorage.setChatAdviceStatus(value);
    dispatch(appChangeChatAdviceStatusAction(value));
  };
}
export function appSetConnectionTypeEffect({ type }) {
  return (dispatch) => {
    dispatch(appSetConnectionTypeAction({ type }));
  };
}
