import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { Button } from 'components';
import Input from 'components/Form/Input';
import { SearchIcon } from 'components/Icons';
import {
  getTransactionRolesDashboardSelector,
  getTransactionRolesFiltersDashboardSelector,
} from 'store/selectors/adminPanel';
import { searchTransactionRolesDashboardEffect } from 'store/effects';

import styles from './styles.module.scss';

const Filters = () => {
  const dispatch = useDispatch();
  const { isPending } = useSelector(getTransactionRolesDashboardSelector);
  const search = useSelector(getTransactionRolesFiltersDashboardSelector);

  const onSubmit = useCallback(
    (values) => dispatch(searchTransactionRolesDashboardEffect(values)),
    [dispatch],
  );

  const formik = useFormik({
    initialValues: search,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.filtersFormWrapper}>
        <div className={styles.filtersWrapper}>
          <div className={styles.filterWrapper}>
            <Input
              id="searchString"
              className={styles.input}
              type="text"
              name="searchString"
              placeholder="Search"
              error={formik.touched.searchString ? formik.errors.searchString : ''}
              value={formik.values.searchString}
              onChange={formik.handleChange}
              disabled={isPending}
              variant={Input.LIGHT_FULL}
            />
          </div>
        </div>
        {formik.dirty ? (
          <Button
            type="submit"
            className={styles.searchButton}
            isPending={isPending}
            title={<SearchIcon />}
          />
        ) : (
          <Button type="button" className={styles.searchButton} title={<SearchIcon />} />
        )}
      </div>
    </form>
  );
};

Filters.propTypes = {};
Filters.defaultProps = {};

export default Filters;
