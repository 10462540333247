import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';

export const localState = ({ symphony }) => symphony;

export const getStateAndDataFlag = createSelector(localState, ({ state, data }) => ({
  state,
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!data,
}));

export const getSymphonyListDataArray = createSelector(localState, ({ data }) => data || []);
