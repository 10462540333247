import React from 'react';

import classNames from 'classnames';
import styles from './styles.module.scss';

type SubFilterStatusType = {
  className?: string;
  content: string | React.ReactNode;
};

export const SubFilterStatus = ({ className, content }: SubFilterStatusType) => {
  return (
    <div className={classNames(styles.subfilterStatus, className)}>
      <div className={styles.statusContent}>{content}</div>
    </div>
  );
};
