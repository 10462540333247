import { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { getTransactionSelector } from 'store/selectors/transaction';
import { getTransactionNotesSelector } from 'store/selectors/transactionNotes';
import { convertNameToAvatarPlaceholder, getPropertyAddress } from 'helpers';
import {
  editTransactionNoteEffect,
  sendNewTransactionNoteEffect,
  socketsGetTransactionNotesByThreadIdEffect,
  socketSetEditTransactionNoteEffect,
  deleteTransactionNoteEffect,
  resetTransactionNotesEffect,
} from 'store/effects/sockets/transactionNotes';
import { updateUnReadNotesForTransactionEffect } from 'store/effects/transactions';
import { createNewThreadEffect, sendMarkThreadAsReadEffect } from 'store/effects/sockets/threads';
import { getCurrentEditTransactionNoteSelector } from 'store/selectors/sockets/transactionNotes';
import { SOCKET_THREAD_TYPES, SOCKET_MESSAGE_TYPES } from 'settings/constants/sockets';
import { Comments } from '../../TransactionTasks/components/Task/View/components';
import { Wrapper as PendingWrapper, CommentsView } from 'components';

import styles from './styles.module.scss';

const Notes = (props) => {
  const { className, isModal, isTransactionDrawer } = props;
  const noteViewWrapperRef = useRef(null);
  const dispatch = useDispatch();
  const { transaction } = useSelector(getTransactionSelector);
  const { currentThreadId, messages, threadsList, userId } = useSelector(
    getTransactionNotesSelector,
  );
  const currentEditMessage = useSelector(getCurrentEditTransactionNoteSelector);
  const [isThreadCreating, setIsThreadCreating] = useState(false);

  const checkAndGetThread = () =>
    threadsList.find(
      (thread) =>
        thread?.EntityId === `${transaction?.Id}` &&
        thread?.Type === SOCKET_THREAD_TYPES.TRANSACTION,
    );

  useEffect(() => {
    const existThread = checkAndGetThread();
    if (existThread) {
      dispatch(socketsGetTransactionNotesByThreadIdEffect({ Id: existThread?.Id }));
    } else {
      dispatch(resetTransactionNotesEffect());
    }
  }, [transaction?.Id]);

  useEffect(() => {
    if (noteViewWrapperRef?.current && currentThreadId) {
      noteViewWrapperRef.current.scrollTop = noteViewWrapperRef?.current?.scrollHeight;
    }
  }, [messages, currentThreadId]);

  const onCancelEditHandler = () => {
    dispatch(socketSetEditTransactionNoteEffect({ editMessage: null }));
  };

  const onSendNote = (message, attachments, tagsValue, messageId, threadId, accessType) => {
    if (currentEditMessage) {
      onCancelEditHandler();
    }

    if (messageId) {
      return dispatch(
        editTransactionNoteEffect({
          MessageId: messageId,
          Text: message,
          Attachments: attachments,
          MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
          MessageMeta: { Tags: tagsValue },
          AccessType: accessType,
        }),
      );
    }

    return dispatch(
      sendNewTransactionNoteEffect({
        ThreadId: threadId || currentThreadId,
        Text: message,
        Attachments: attachments,
        MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
        MessageMeta: { Tags: tagsValue },
        AccessType: accessType,
      }),
    );
  };

  const onCreateThreadAndSendNewNote = (
    message,
    attachments,
    tagsValue,
    messageId,
    threadId,
    accessType,
  ) => {
    const existThread = checkAndGetThread();
    if (existThread) {
      // onSendNote(message, attachments, [], existThread?.Id);
      return dispatch(socketsGetTransactionNotesByThreadIdEffect({ Id: existThread?.Id }));
    }
    setIsThreadCreating(true);
    dispatch(
      createNewThreadEffect(
        {
          Type: SOCKET_THREAD_TYPES.TRANSACTION,
          EntityId: `${transaction?.Id}`,
          TopicName: getPropertyAddress(transaction?.Property?.Address),
          ParticipantIds: [
            ...(transaction?.Participants || []).map((participant) => participant?.Id),
            userId,
          ],
          FirstMessage: {
            Text: message,
            Attachments: attachments,
            MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
            MessageMeta: { Tags: tagsValue },
            AccessType: accessType,
          },
        },
        {},
        (err, data) => {
          dispatch(socketsGetTransactionNotesByThreadIdEffect({ Id: data?.Id }));
          setIsThreadCreating(false);
        },
      ),
    );
  };

  const onEditMessageHandler = useCallback(
    (messageId) => {
      const editMessage = messages.find((message) => message?.Id === messageId);
      dispatch(socketSetEditTransactionNoteEffect({ editMessage }));
    },
    [dispatch, messages],
  );

  const onDeleteMessage = (messageId) => {
    return dispatch(deleteTransactionNoteEffect({ MessageId: messageId }));
  };

  const getNotesContent = () => {
    if (isThreadCreating) {
      return (
        <PendingWrapper
          isPending={isThreadCreating}
          className={styles.pendingWrapper}
          loaderClassName={styles.loader}
        />
      );
    }

    if (currentThreadId) {
      return (
        <div
          className={
            isTransactionDrawer
              ? styles.CommentsViewWrapperDrawer
              : isModal
              ? styles.CommentsViewWrapper
              : ''
          }
          ref={noteViewWrapperRef}
        >
          <CommentsView
            isModal={isModal}
            threadId={currentThreadId}
            list={messages}
            currentEditMessage={currentEditMessage}
            onEditMessage={onEditMessageHandler}
            className={styles.messages}
            onDeleteMessage={onDeleteMessage}
            isTransactionDrawer={isTransactionDrawer}
          />
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: isTransactionDrawer ? '19rem' : '-webkit-fill-available',
        }}
      >
        <span testid="no_comments" className={styles.message}>
          No notes have been added to this transaction yet.
        </span>
      </div>
    );
  };

  return (
    <Comments
      isModal={isModal}
      isTransactionDrawer={isTransactionDrawer}
      commentAutoFocus={true}
      inputPlaceholder="Start typing ..."
      emptyLabel="No notes have been added to this transaction (yet)."
      onCreateThreadHandler={onCreateThreadAndSendNewNote}
      onSendHandler={onSendNote}
      customSend={true}
      getContentHandler={getNotesContent}
      getMessagesSelector={getTransactionNotesSelector}
      getEditMessageSelector={getCurrentEditTransactionNoteSelector}
      onCustomCancelEditSelector={socketSetEditTransactionNoteEffect}
    />
  );
};

Notes.propTypes = {
  className: PropTypes.string,
  handleCloseNotesModal: PropTypes.func,
  onCreateThreadAndSendNewNote: PropTypes.func,
  isModal: PropTypes.bool,
  isTransactionDrawer: PropTypes.bool,
};

Notes.defaultProps = {
  className: '',
  onCloseModal: () => {},
  isModal: false,
  isTransactionDrawer: false,
};

export default Notes;
