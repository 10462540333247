import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash-es';

import { Sidebar, DeleteModal, InputSelectModal } from 'components-antd';
import { FORM_TYPE } from 'app-constants';
import { CreateNewMenu, FolderListMenu } from 'pages/FormBuilder/components';
import { DeleteModalState, FormFolderModalState, FormFolderModalType } from 'types';
import {
  addFormFolderEffect,
  createNewFormEffect,
  deleteFormFolderEffect,
  editFormFolderEffect,
  getFolderListEffect,
} from 'store/effects/formBuilder';
import { getFolderListSelector } from 'store/selectors/formBuilder';
import { PENDING } from 'settings/constants/apiState';
import { link } from 'settings/navigation/link';

import styles from './styles.module.scss';

export const FolderListSidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formFolderModal, setFormFolderModal] = useState<FormFolderModalState>({
    open: false,
  });

  const [deleteModal, setDeleteModal] = useState<DeleteModalState>({
    open: false,
  });

  const [entityId, setEntityId] = useState<number>();
  const [entityName, setEntityName] = useState<string>();
  const [formType, setFormType] = useState<typeof FORM_TYPE[keyof typeof FORM_TYPE]>();

  const { folderList } = useSelector(getFolderListSelector);

  const clearFormFolderModal = () => {
    setFormFolderModal({ open: false });
    setEntityId(undefined);
    setEntityName('');
  };

  const handleCreateFolder = async (setLoading: (loading: boolean) => void, values) => {
    const { entityName } = values;
    if (entityName?.trim()) {
      setLoading(true);
      try {
        await dispatch(addFormFolderEffect({ name: entityName.trim() }));
        await dispatch(getFolderListEffect());
      } catch (error) {
        //error
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
    clearFormFolderModal();
  };

  const handleCreateForm = async (setLoading: (loading: boolean) => void, values) => {
    const { entityName, selectedItem } = values;

    if (entityName?.trim() && selectedItem && formType) {
      setLoading(true);
      dispatch(
        createNewFormEffect(
          {
            folderId: selectedItem,
            type: formType,
            formName: entityName.trim(),
          },
          (err, response) => {
            setLoading(false);
            clearFormFolderModal();
            setFormType(undefined);
            if (!err) {
              const versionId = get(response, 'data.value.formVersionId');
              if (versionId && formType === FORM_TYPE.Quote)
                history.push(link.toDashboardServiceDirectoryFormsQuoteForm(versionId));
            }
          },
        ),
      );
    } else {
      setLoading(false);
    }
  };

  const handleRenameFolder = async (setLoading: (loading: boolean) => void, values) => {
    const { entityName } = values;

    if (entityName && entityName !== formFolderModal.folderName) {
      setLoading(true);
      try {
        await dispatch(editFormFolderEffect({ folderId: entityId, name: entityName }));
        await dispatch(getFolderListEffect());
      } catch (error) {
        //error
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
    clearFormFolderModal();
  };

  const handleDeleteFolder = async (setLoading: (loading: boolean) => void) => {
    setLoading(true);
    try {
      await dispatch(deleteFormFolderEffect({ folderId: deleteModal.entityId }));
      await dispatch(getFolderListEffect());
    } catch (error) {
      //error
    }
    setLoading(false);
    setDeleteModal({ open: false, entityId: undefined, entityName: '' });
    setEntityId(undefined);
    setEntityName('');
  };

  const formFolderModalHandler = (type?: FormFolderModalType) => {
    switch (type) {
      case 'newFolder':
        return handleCreateFolder;
      case 'newForm':
        return handleCreateForm;
      case 'rename':
        return handleRenameFolder;
    }
  };

  useEffect(() => {
    dispatch(getFolderListEffect());
  }, []);

  return (
    <>
      <InputSelectModal
        open={formFolderModal.open}
        title={formFolderModal.title}
        okText={formFolderModal.okText}
        selectHeading="Folder"
        selectPlaceholder="Choose Folder"
        selectOptions={formFolderModal.selectOptions}
        submit={formFolderModalHandler(formFolderModal.type)!}
        onCancel={clearFormFolderModal}
        entityNameRequired={true}
        selectedItemRequired={true}
        entityName={entityName}
        entityNameMaxLength={64}
        destroyOnClose={true}
      />
      <DeleteModal
        open={deleteModal.open}
        entityName={deleteModal.entityName}
        onOk={handleDeleteFolder}
        onCancel={() => setDeleteModal({ open: false })}
      />

      {useMemo(
        () => (
          <Sidebar width={240} className={styles.folderListSidebar}>
            <CreateNewMenu
              folders={folderList.folders}
              setFormFolderModal={setFormFolderModal}
              setFormType={setFormType}
            />

            <FolderListMenu
              loading={folderList.state === PENDING}
              folders={folderList.folders}
              setEntityId={setEntityId}
              setEntityName={setEntityName}
              setDeleteModal={setDeleteModal}
              setFormFolderModal={setFormFolderModal}
            />
          </Sidebar>
        ),
        [folderList],
      )}
    </>
  );
};
