import React, { memo, useMemo } from 'react';

const List = memo(({ className }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_11010_158090)">
            <path
              d="M9.16602 5H16.666"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.16602 10H16.666"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.16602 15H16.666"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="4.5" cy="5" r="1" fill="#515151" stroke="#515151" />
            <circle cx="4.5" cy="10" r="1" fill="#515151" stroke="#515151" />
            <circle cx="4.5" cy="15" r="1" fill="#515151" stroke="#515151" />
          </g>
          <defs>
            <clipPath id="clip0_11010_158090">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    ),
    [className],
  ),
);

export default List;
