import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper as PendingWrapper } from 'components';

import Header from 'pages/Dashboard/components/Header';
import Grid from 'pages/Dashboard/components/Grid';
import { getAllMlsAgentsDashboardEffect } from 'store/effects/adminPanel/mlsAgents';
import {
  getMlsAgentsDashboardSelector,
  getMlsAgentsDataDashboardSelector,
} from 'store/selectors/adminPanel';
import { gridColumns } from './config';
import Filters from './Filters';
import { ApproveMlsAgentButton, RejectMlsAgentButton, InactivateMlsAgentButton } from './View';

import styles from './styles.module.scss';

const MlsAgentsOverview = (props) => {
  const dispatch = useDispatch();
  const agents = useSelector(getMlsAgentsDataDashboardSelector);
  const { isIdle, isPending } = useSelector(getMlsAgentsDashboardSelector);

  const { className } = props;

  const renderActions = useCallback(
    (Id, data) => (
      <>
        <ApproveMlsAgentButton status={data.Status} mlsId={data.MlsId} userId={data.AgentId} />
        <RejectMlsAgentButton status={data.Status} mlsId={data.MlsId} userId={data.AgentId} />
        <InactivateMlsAgentButton status={data.Status} mlsId={data.MlsId} userId={data.AgentId} />
      </>
    ),
    [],
  );

  useEffect(() => {
    if (isIdle) {
      dispatch(getAllMlsAgentsDashboardEffect({}));
    } else {
      dispatch(getAllMlsAgentsDashboardEffect({}, { silent: true }));
    }
  }, []); // eslint-disable-line

  return (
    <PendingWrapper className={classNames(styles.wrapper, className)} isPending={isPending}>
      <Header title="MLS Agents" />
      <Filters />
      <Grid items={agents || []} columns={gridColumns} keyProp="Id" renderActions={renderActions} />
    </PendingWrapper>
  );
};

MlsAgentsOverview.propTypes = {
  className: PropTypes.string,
};

MlsAgentsOverview.defaultProps = {
  className: '',
};

export default MlsAgentsOverview;
