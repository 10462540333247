/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import fileDownload from 'js-file-download';
import { Document, Page, pdfjs } from 'react-pdf';
import classNames from 'classnames';

import { previewDocumentEffect } from 'store/effects/transactions';
import { Icons } from 'pages/Quotes/Icons';
import { showErrorMessage } from 'helpers';

import styles from './styles.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfView = ({ className, file, UploadDate }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [previewFile, setPreviewFile] = useState(null);
  const dispatch = useDispatch();

  function onDocumentLoadSuccess({ numPages: value }) {
    setNumPages(value);
  }

  const getPreviewDocument = () => {
    if (file?.DocumentVaultUUID) {
      dispatch(
        previewDocumentEffect({ DocumentVaultUUID: file.DocumentVaultUUID }, {}, (err, resp) => {
          if (err) return showErrorMessage(err);
          setPreviewFile({
            DocumentBuffer: resp,
            Filename: resp.headers['file-name'],
          });
        }),
      );
    }
  };

  useEffect(() => {
    getPreviewDocument();
  }, []);

  const onDownload = () => {
    if (file.DocumentVaultUUID) {
      dispatch(
        previewDocumentEffect({ DocumentVaultUUID: file.DocumentVaultUUID }, {}, (err, resp) => {
          if (!err) fileDownload(resp.data, resp.headers['file-name']);
        }),
      );
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <div>
          <span className={styles.recentQuoteTitle}>Most Recent Quote</span>
          <br />
          <span className={styles.recentQuoteDate}>{UploadDate}</span>
        </div>
        <div onClick={onDownload} className={styles.commentWrapper}>
          <Icons className={styles.clickable} testid="comment_icon" variant={Icons.DOWNLOAD_BG} />
        </div>
      </div>
      {file ? (
        <div className={styles.wrapper}>
          <Document
            loading={
              <div className={styles.blackWrapper}>
                <div>Loading file...</div>
              </div>
            }
            file={{ data: file?.DocumentBuffer?.data || previewFile?.DocumentBuffer?.data }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {numPages && <Page pageNumber={pageNumber} height={541} width={420} />}
          </Document>
        </div>
      ) : (
        <div className={styles.disclaimerContainer}>
          <p className={styles.disclaimer}>Url Not Valid</p>
        </div>
      )}
    </div>
  );
};

PdfView.propTypes = {
  className: PropTypes.string,
};

PdfView.defaultProps = {
  className: null,
};

export default PdfView;
