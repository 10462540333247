import styles from './styles.module.scss';
import { useEffect, useState, useRef } from 'react';
import Icons from '../Icons';
import { getClientSearchInstancesByAgent } from '../../../../api/feed';
import { Spinner } from 'components';
import classNames from 'classnames';
import { ClientSearchInstanceDataType } from '../../DataTypes';

type SearchClientPropsType = {
  mainClientSearchChangeHandler: Function;
  addClientClicked: boolean;
  addClientClickedSetter: Function;
  loading: boolean;
  loadingSetter: Function;
  error: boolean;
  errorSetter: Function;
  showDropdown: boolean;
  showDropdownSetter: Function;
  selectedClient: ClientSearchInstanceDataType | null;
  selectedClientSetter: Function;
  clientSearchInstances: ClientSearchInstanceDataType[];
  clientSearchInstancesSetter: Function;
  filteredClientSearchInstances: ClientSearchInstanceDataType[];
  filteredClientSearchInstancesSetter: Function;
};

type DropDownItemPropsType = {
  client: ClientSearchInstanceDataType;
  onSelectHandler: Function;
};

function DropDownItem(props: DropDownItemPropsType) {
  return (
    <div className={styles.dropdownItem} onClick={(e) => props.onSelectHandler(e, props.client)}>
      {props.client.clientPhotoUrl ? (
        <img
          src={props.client.clientPhotoUrl}
          alt={props.client.clientFirstName}
          className={styles.dropdownItemImage}
        />
      ) : (
        <div className={styles.dropdownItemPlaceholder}>{props.client.clientInitials}</div>
      )}
      <div className={styles.dropdownItemName}>
        <div className={styles.dropdownItemNameClient}>{props.client.clientFullName}</div>
        <div className={styles.dropdownItemNameInstance}>{props.client.instanceName}</div>
      </div>
    </div>
  );
}

function SearchClient(props: SearchClientPropsType) {
  const textInputRef = useRef<HTMLInputElement>(null);

  const fetchPropertyListings = async () => {
    try {
      const response = await getClientSearchInstancesByAgent();
      if (response.status === 200) {
        structureData(response.data.result.result);
      } else {
        props.errorSetter(true);
      }
      props.loadingSetter(false);
    } catch (e) {
      props.errorSetter(true);
      props.loadingSetter(false);
    }
  };

  const structureData = (searchInstances) => {
    try {
      if (searchInstances && searchInstances.length) {
        let instancesArray: ClientSearchInstanceDataType[] = [];
        for (let i = 0; i < searchInstances.length; i++) {
          if (searchInstances[i]?.Client) {
            let instanceObj: ClientSearchInstanceDataType = {
              clientId: searchInstances[i].ClientId,
              instanceId: searchInstances[i].Id,
              agentId: searchInstances[i].AgentId,
              clientFirstName: searchInstances[i].Client.User.FirstName,
              clientLastName: searchInstances[i].Client.User.LastName,
              clientFullName:
                searchInstances[i].Client.User.FirstName +
                (searchInstances[i].Client.User.LastName
                  ? ' ' + searchInstances[i].Client.User.LastName
                  : ''),
              clientInitials:
                searchInstances[i].Client.User.FirstName.charAt(0) +
                (searchInstances[i].Client.User.LastName
                  ? searchInstances[i].Client.User.LastName.charAt(0)
                  : ''),
              clientPhotoUrl: searchInstances[i].Client.User.AvatarUrl,
              instanceName: searchInstances[i].Name,
              instanceStatus: searchInstances[i].Status,
            };
            instancesArray.push(instanceObj);
          }
        }
        props.clientSearchInstancesSetter(instancesArray);
      }
    } catch (e) {
      props.errorSetter(true);
      props.loadingSetter(false);
    }
  };

  const textInputCloseHandler = (e) => {
    if (textInputRef && textInputRef.current) {
      textInputRef.current.value = '';
    }
    props.showDropdownSetter(false);
  };

  const addClientClickHandler = (e) => {
    fetchPropertyListings();
    props.addClientClickedSetter(true);
  };

  const textInputChangeHandler = (e) => {
    if (e.target.value && props.clientSearchInstances?.length) {
      if (!props.showDropdown) props.showDropdownSetter(true);
      let filteredResults = props.clientSearchInstances.filter((item) => {
        if (item.clientFullName.toLowerCase().includes(e.target.value.toLowerCase())) return item;
      });
      props.filteredClientSearchInstancesSetter(filteredResults);
    }
  };

  const dropDownSelectHandler = (e, clientObj) => {
    if (textInputRef && textInputRef.current) {
      textInputRef.current.value = '';
    }
    props.selectedClientSetter(clientObj);
    props.showDropdownSetter(false);
    props.mainClientSearchChangeHandler(clientObj.instanceId);
  };

  const openDropDownHandler = (e) => {
    if (props.selectedClient) {
      let filteredResults = props.clientSearchInstances.filter((item) => {
        if (
          props.selectedClient &&
          item.clientFullName
            .toLowerCase()
            .includes(props.selectedClient.clientFullName.toLowerCase())
        )
          return item;
      });
      props.filteredClientSearchInstancesSetter(filteredResults);
    }
    props.showDropdownSetter(true);
  };

  useEffect(() => {
    if (textInputRef && textInputRef.current) {
      textInputRef.current.focus();
      if (props.showDropdown && props.selectedClient) {
        textInputRef.current.value = props.selectedClient.clientFullName;
      }
    }
  }, [props.showDropdown, props.selectedClient]);

  if (
    props.addClientClicked &&
    !props.loading &&
    !props.error &&
    props?.clientSearchInstances?.length
  )
    return (
      <div className={styles.clientSearchArea}>
        <div className={styles.clientSearch}>
          <div className={styles.clientSearchInput}>
            {props.selectedClient ? (
              props.selectedClient.clientPhotoUrl ? (
                <img
                  src={props.selectedClient.clientPhotoUrl}
                  alt={props.selectedClient.clientFirstName}
                  className={styles.selectedClientImage}
                />
              ) : (
                <div className={styles.selectedClientPlaceholder}>
                  {props.selectedClient.clientInitials}
                </div>
              )
            ) : (
              <Icons iconName={'person'} />
            )}
            {props.selectedClient && !props.showDropdown ? (
              <div className={styles.selectedClientName}>
                <div className={styles.selectedClientNameUser}>
                  {props.selectedClient.clientFullName}
                </div>
                <div className={styles.selectedClientNameInstance}>
                  {props.selectedClient.instanceName}
                </div>
              </div>
            ) : (
              <input
                className={styles.clientSearchInputText}
                type={'text'}
                ref={textInputRef}
                placeholder={"Enter client's name"}
                onChange={(e) => textInputChangeHandler(e)}
              />
            )}
            {props.selectedClient && !props.showDropdown ? (
              <div
                className={styles.clientSearchInputClose}
                onClick={(e) => openDropDownHandler(e)}
              >
                <Icons iconName={'down'} />
              </div>
            ) : null}
            {props.showDropdown ? (
              <div
                className={styles.clientSearchInputClose}
                onClick={(e) => textInputCloseHandler(e)}
              >
                <Icons iconName={'noMatch'} />
              </div>
            ) : null}
          </div>
          {props.showDropdown ? (
            <div className={styles.clientSearchResults}>
              {props.filteredClientSearchInstances?.length ? (
                <p className={styles.clientSearchResultsHeading}>Search Results</p>
              ) : (
                <p className={styles.clientSearchResultsHeading}>No Results</p>
              )}
              {props.filteredClientSearchInstances?.length ? (
                <div className={styles.dropdown}>
                  {props.filteredClientSearchInstances.map((item) => (
                    <DropDownItem client={item} onSelectHandler={dropDownSelectHandler} />
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  else if (props.loading && !props.error && props.addClientClicked)
    return (
      <div className={styles.spinner}>
        <Spinner loaderClassName={classNames(styles.loader)} />
      </div>
    );
  else if (!props.loading && props.error && props.addClientClicked)
    return (
      <div className={styles.error}>Oops! something went wrong...Please try refreshing page</div>
    );
  else if (
    props.addClientClicked &&
    !props.loading &&
    !props.error &&
    !props?.clientSearchInstances?.length
  )
    return (
      <div className={styles.add}>
        <div className={styles.addFooter}>
          <p className={styles.addFooterText}>No Clients found</p>
        </div>
      </div>
    );
  else
    return (
      <div className={styles.add}>
        <div className={styles.addHeader} onClick={(e) => addClientClickHandler(e)}>
          <Icons iconName={'add'} />
          <h1 className={styles.addHeaderText}>Add Client</h1>
        </div>
        <div className={styles.addFooter}>
          <p className={styles.addFooterText}>Add client to compare needs, wants and commutes</p>
        </div>
      </div>
    );
}

export default SearchClient;
