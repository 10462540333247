export const getMilestoneByName = (transaction, milestoneName) => {
  if (!transaction || !milestoneName) {
    return null;
  }

  const milestones = transaction.Milestones || [];

  for (const milestone of milestones) {
    if (milestone.Title.toLowerCase() === milestoneName.toLowerCase()) {
      return milestone;
    }
  }

  return null;
};

export const getTaskByName = (transaction, taskName) => {
  if (!transaction || !taskName) {
    return null;
  }

  const tasks = transaction.Tasks || [];

  for (const task of tasks) {
    if (task.Title.toLowerCase() === taskName.toLowerCase()) {
      return task;
    }
  }

  return null;
};
