import moment from 'moment';
import { useState } from 'react';
import classNames from 'classnames';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';

import styles from './styles.module.scss';

type Props = {
  cb: any;
  wrapperClassname?: string;
};
export const DateRangeFilter = ({ ...props }: Props) => {
  const currentYear = moment().year();
  const [dropdownFields, setDropdownFields] = useState([
    'This Week',
    'MTD',
    'QTD',
    'YTD',
    `${currentYear - 1}`,
    `${currentYear - 2}`,
    `${currentYear - 3}`,
  ]);
  const [value, setValue] = useState('YTD');

  const onChange = (e) => {
    setValue(e.target.value);
    props.cb(e.target.value);
  };

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => {
          return (
            <MenuItem key={idx}>
              <Radio value={item} key={idx}>
                <div className={styles.option}>
                  <span className={styles.optionValue}>{item}</span>
                </div>
                {value === item && <Check className={styles.checkIcon} />}
              </Radio>
              {idx === 3 && <div className={styles.customLine} />}
            </MenuItem>
          );
        })}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.filterWrap, props.wrapperClassname)}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.optionValue}>{value}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} size={16} />
        </a>
      </Dropdown>
    </div>
  );
};
