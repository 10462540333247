import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { isThisWeek, isToday, isAfterThisWeek, isBeforeToday, isAfterToday } from 'helpers/date';
import { subtractTimeZone } from 'helpers';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getTransactionEffect,
  setTransactionTaskParamsEffect,
  setTransactionTasksLastStateEffect,
} from 'store/effects/transactions';
import { getFilteredTaskAggregateSelector } from 'store/selectors/taskAggregate';
import {
  getIsTaskFiltersApplied,
  getTransactionTaskAccess,
} from 'store/selectors/transactionTasks';

import { FORM_CATEGORY } from 'settings/constants/transaction';
import { tasksStatusesIds } from 'settings/constants/transactionTasks';
import { routes } from 'settings/navigation/routes';
import { getPublishedFormEffect } from 'store/effects/formProcess';

import { Wrapper } from 'components';
import { FormDetails } from 'pages/Workshop/Forms/components/FormDetails';
import { CollapsibleTable, WorkshopTableWrapper } from 'pages/Workshop/common';
import { handleViewForm } from 'utils';

import styles from './styles.module.scss';
import { tableColumns } from './tableColumns';
import { TransactionDrawer } from 'pages/Workshop/Transactions/TransactionOverview/components/TransactionDrawer';
import { getUserId } from 'store/selectors/user';
import { getTransactionAccessByTaskSelector } from 'store/selectors/transaction';

const TasksList = (props) => {
  const {
    className,
    isAggregatedTasks,
    fetchAggregateTasks,
    showSelection,
    handleSelectTask,
    selectedTasks,
    showSelectAll,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [taskList, setTaskList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formProcessDetail, setFormProcessDetail] = useState();
  const [transactionDrawerProps, setTransactionDrawerProps] = useState({});
  const [openTransactionDrawer, setOpenTransactionDrawer] = useState(false);

  const {
    tasks,
    isPending: isTaskListLoading,
    isArchive,
  } = useSelector(getFilteredTaskAggregateSelector);

  const filtersApplied = useSelector(getIsTaskFiltersApplied);
  const userId = useSelector(getUserId);
  const { hasFullAccess } = useSelector(getTransactionAccessByTaskSelector);
  const { hasAccess } = useSelector(getTransactionTaskAccess);

  const isFilter = filtersApplied && !isArchive;

  useEffect(() => {
    setLoading(false);
  }, [taskList]);

  useEffect(() => {
    if (tasks && !isTaskListLoading) {
      const formattedTasks = tasks?.map((task) => {
        if (task.formProcessDetails) {
          const formProcessDetails = task.formProcessDetails;
          if (formProcessDetails.taskName) {
            task.Title = task.formProcessDetails.taskName;
          }
          if (formProcessDetails.participants?.length) {
            task.AssigneeList = formProcessDetails.participants;
          }
        }
        return task;
      });

      if (isArchive) {
        const archievedTasks = formattedTasks.filter((item) =>
          [tasksStatusesIds.done, tasksStatusesIds.na].includes(item.Status),
        );
        setTaskList(archievedTasks);
      } else if (!filtersApplied) {
        const activeTasks = formattedTasks.filter(
          (item) => ![tasksStatusesIds.done, tasksStatusesIds.na].includes(item.Status),
        );
        setTaskList(activeTasks);
      } else {
        setTaskList(formattedTasks);
      }
    }
  }, [isArchive, isTaskListLoading, tasks, filtersApplied]);

  useEffect(() => {
    setLoading(false);
  }, [taskList]);

  const handleNewFormTask = (EntityId, transactionId, taskId) => {
    dispatch(
      getPublishedFormEffect({ formId: EntityId }, (err, form) => {
        if (!err) {
          const { data } = form;
          history.push({
            pathname: routes.workshopFormProcessRequest,
            state: {
              formId: data?.value?.FormId,
              versionId: data?.value?.Id,
              propertyTransactionId: transactionId,
              taskId: taskId,
            },
          });
        }
      }),
    );
  };

  const handleFormTask = (formProcessDetails) => {
    if (formProcessDetails) {
      const { url, shouldOpenDetails, formProcessId } = formProcessDetails;
      if (shouldOpenDetails && formProcessId) {
        setFormProcessDetail(formProcessId);
      } else if (url) {
        handleViewForm(history, url);
      }
    }
  };

  const onClickTask = useCallback(
    (record) => {
      if (record) {
        const {
          TransactionId: transactionId,
          Id: taskId,
          Category,
          EntityId,
          Status,
          formProcessDetails,
        } = record;
        const category = Category?.Name;

        if (category === FORM_CATEGORY && Status === tasksStatusesIds.new) {
          handleNewFormTask(EntityId, transactionId, taskId);
        } else if (category === FORM_CATEGORY) {
          handleFormTask(formProcessDetails);
        } else {
          isAggregatedTasks && dispatch(getTransactionEffect({ id: transactionId }));
          dispatch(setTransactionTaskParamsEffect({ taskId, transactionId, isTaskDetail: true }));
          dispatch(setTransactionTasksLastStateEffect({ taskId }));
        }
      }
    },
    [dispatch, history, isAggregatedTasks],
  );

  function overDueTasks() {
    return taskList.filter((item) => isBeforeToday(subtractTimeZone(item.DueDate, 'M/DD/YYYY')));
  }

  function todayTasks() {
    return taskList.filter((item) => {
      let dueDate = item.DueDate ? subtractTimeZone(item.DueDate, 'M/DD/YYYY') : 'N/A';

      return isToday(dueDate);
    });
  }

  function thisWeekTasks() {
    return taskList.filter((item) => {
      let dueDate = item.DueDate ? subtractTimeZone(item.DueDate, 'M/DD/YYYY') : 'N/A';
      return isThisWeek(dueDate) && isAfterToday(dueDate);
    });
  }

  function upcomingTasks() {
    return taskList.filter((item) => {
      let dueDate = item.DueDate ? subtractTimeZone(item.DueDate, 'M/DD/YYYY') : 'N/A';

      return isAfterThisWeek(dueDate);
    });
  }

  function doneTasks() {
    return taskList.filter((item) => item.Status === tasksStatusesIds.done);
  }

  function naTasks() {
    return taskList.filter((item) => item.Status === tasksStatusesIds.na);
  }

  const handleTransactionDrawer = (transactionId, isProject) => {
    setTransactionDrawerProps({ transactionId, isProject });
    setOpenTransactionDrawer(true);
  };

  const getCollapsibleDataByType = (taskType) => {
    const collapsibleData = {
      overDue: () => overDueTasks(),
      today: () => todayTasks(),
      thisWeek: () => thisWeekTasks(),
      upcoming: () => upcomingTasks(),
      done: () => doneTasks(),
      na: () => naTasks(),
    };

    return collapsibleData[taskType]?.() || [];
  };

  const handleSelectAllTask = (taskIds, checked) => {
    handleSelectTask(taskIds, checked);
  };

  const tableProps = (dataKey = '') => {
    return {
      columns: tableColumns({
        isAggregatedTasks,
        showSelection,
        handleSelectTask,
        userId,
        hasAccess,
        hasFullAccess,
        openTransactionDrawer: handleTransactionDrawer,
        handleSelectAllTask,
        selectedTasks,
        showSelectAll,
        ...(showSelectAll && {
          tableDataSource: isFilter ? taskList : getCollapsibleDataByType(dataKey),
        }),
      }),
      onRow: (record) => {
        return {
          onClick: () => {
            onClickTask(record);
          },
        };
      },
      tableClassName: styles.taskTable,
    };
  };

  const isCollapseUpcomingTasks = useMemo(() => {
    // When upcoming is the only category, expand the table.

    const totalTasksCount =
      overDueTasks()?.length +
      todayTasks()?.length +
      thisWeekTasks()?.length +
      upcomingTasks()?.length;

    return totalTasksCount !== upcomingTasks()?.length;
  }, [taskList]);

  return (
    <Wrapper isPending={loading}>
      <div className={classNames(styles.tasksList, className)}>
        {isFilter ? (
          <WorkshopTableWrapper data={[taskList]} emptyText="No Tasks.">
            <CollapsibleTable
              title={`${taskList.length} Tasks`}
              dataSource={taskList}
              disableCollapse
              {...tableProps()}
            />
          </WorkshopTableWrapper>
        ) : isArchive ? (
          <WorkshopTableWrapper data={[doneTasks(), naTasks()]} emptyText="No Tasks.">
            <CollapsibleTable
              showCollapseIcon
              title="Done"
              defaultCollapse
              dataSource={doneTasks()}
              {...tableProps('done')}
            />
            <CollapsibleTable
              showCollapseIcon
              title="N/A"
              defaultCollapse
              dataSource={naTasks()}
              {...tableProps('na')}
            />
          </WorkshopTableWrapper>
        ) : (
          <WorkshopTableWrapper
            data={[overDueTasks(), todayTasks(), thisWeekTasks(), upcomingTasks()]}
            emptyText="No Tasks."
          >
            <CollapsibleTable
              showCollapseIcon
              title="Overdue"
              dataSource={overDueTasks()}
              {...tableProps('overDue')}
            />
            <CollapsibleTable
              showCollapseIcon
              title="Today"
              dataSource={todayTasks()}
              {...tableProps('today')}
            />
            <CollapsibleTable
              title="This Week"
              showCollapseIcon
              dataSource={thisWeekTasks()}
              {...tableProps('thisWeek')}
            />
            <CollapsibleTable
              title="Upcoming"
              defaultCollapse={isCollapseUpcomingTasks}
              showCollapseIcon
              dataSource={upcomingTasks()}
              {...tableProps('upcoming')}
            />
          </WorkshopTableWrapper>
        )}
      </div>

      {formProcessDetail && (
        <FormDetails
          selectedFormProcess={formProcessDetail}
          onClose={() => setFormProcessDetail(undefined)}
          onHandleViewForm={(url, type) => handleViewForm(history, url, type)}
          onCancelForm={fetchAggregateTasks}
        />
      )}
      {/* Transaction Drawer */}
      {openTransactionDrawer && (
        <TransactionDrawer
          openDrawer={openTransactionDrawer}
          setOpenDrawer={setOpenTransactionDrawer}
          {...transactionDrawerProps}
        />
      )}
    </Wrapper>
  );
};

TasksList.propTypes = {
  className: PropTypes.string,
  taskTableClassName: PropTypes.string,
  isAggregatedTasks: PropTypes.bool,
  fetchAggregateTasks: PropTypes.func,
  showSelection: PropTypes.bool,
  handleSelectTask: PropTypes.func,
  selectedTasks: PropTypes.arrayOf(PropTypes.number),
  showSelectAll: PropTypes.bool,
};

TasksList.defaultProps = {
  className: '',
  taskTableClassName: '',
  isAggregatedTasks: false,
  fetchAggregateTasks: () => {},
  selectedTasks: [],
  showSelectAll: false,
};

export default TasksList;
