import { apiServer } from 'settings/web-services/api';
import {
  GET_SHOWING_DETAILS,
  GET_SHOWING_AVAILABILITY,
  GET_ALL_SHOWING_APPOINTMENTS,
  GET_SHOWING_APPOINTMENT_BY_ID,
  SAVE_SHOWING_APPOINTMENT,
  UPDATE_SHOWING_APPOINTMENT,
  GET_ALL_SHOWING_APPOINTMENT_APPROVAL,
  GET_SHOWING_APPOINTMENT_APPROVAL_BY_USER_ID,
  UPDATE_SHOWING_APPOINTMENT_APPROVAL,
  GET_SHOWING_APPOINTMENT_APPROVAL_BY_UUID,
  DELETE_APPOINTMENT_DOCUMENT_BY_ID,
  SEND_VERIFICATION_LINK_FOR_APPOINTMENT_BY_UUID,
  SEND_SHOWING_APPOINTMENT_FEEDBACK,
} from 'app-constants';

// showing details api call
export const getShowingDetails = ({ Id, ...cfg }) => apiServer.get(GET_SHOWING_DETAILS(Id), cfg);

export const getShowingAvailability = ({ Id, ...cfg }) =>
  apiServer.get(GET_SHOWING_AVAILABILITY(Id), cfg);

// showing appointments api call
export const getAllShowingAppointments = (cfg) => apiServer.get(GET_ALL_SHOWING_APPOINTMENTS, cfg);

export const getShowingAppointmentById = ({ Id }, { externalToken }) =>
  apiServer.get(GET_SHOWING_APPOINTMENT_BY_ID(Id), {
    headers: {
      'X-External-Token': externalToken,
    },
  });

export const saveShowingAppointment = (cfg) => apiServer.post(SAVE_SHOWING_APPOINTMENT, cfg);

export const updateShowingAppointment = ({ UUID, ...cfg }, options) => {
  return apiServer.patch(
    UPDATE_SHOWING_APPOINTMENT(UUID),
    cfg,
    options.externalToken
      ? {
          headers: {
            'X-External-Token': options.externalToken,
          },
        }
      : {},
  );
};

export const sendShowingAppointmentFeedback = ({ UUID, ...cfg }) =>
  apiServer.patch(SEND_SHOWING_APPOINTMENT_FEEDBACK(UUID), cfg);

// showing appointments approval api call
export const getAllShowingAppointmentsApproval = (cfg) =>
  apiServer.get(GET_ALL_SHOWING_APPOINTMENT_APPROVAL, cfg);

export const getAllAppointmentApprovalByUserId = ({ userId, ...cfg }) =>
  apiServer.get(GET_SHOWING_APPOINTMENT_APPROVAL_BY_USER_ID(userId), cfg);

export const updateShowingAppointmentsApproval = ({ UUID, ...cfg }) =>
  apiServer.patch(UPDATE_SHOWING_APPOINTMENT_APPROVAL(UUID), cfg);

export const getShowingAppointmentApprovalByUUID = ({ UUID, ...cfg }) =>
  apiServer.get(GET_SHOWING_APPOINTMENT_APPROVAL_BY_UUID(UUID), cfg);

export const deleteAppointmentDocumentById = ({ Id, ...cfg }) =>
  apiServer.delete(DELETE_APPOINTMENT_DOCUMENT_BY_ID(Id), cfg);

export function sendShowingAppointmentExternalVerificationLink({ uuid }) {
  return apiServer.post(SEND_VERIFICATION_LINK_FOR_APPOINTMENT_BY_UUID(uuid));
}

export function getShowingAppointmentExternalVerificationData({ uuid }) {
  return apiServer.get(SEND_VERIFICATION_LINK_FOR_APPOINTMENT_BY_UUID(uuid));
}
