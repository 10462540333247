import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { Row, Col } from 'components-antd';
import { DatePicker, Input } from 'components';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import Point from './Point';
import Options from './Options';
import {
  validateControlOperatorOffset,
  convertToInitialOffset,
  convertToDateTime,
} from 'utils/templatesHelper';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';

const EditTimeline = (props) => {
  const {
    className,
    pointsClassName,
    listItems,
    onFieldsChange,
    dateControls,
    hideAdd,
    errors,
    allowFirstDelete,
    isViewMode,
    disableAddMilestone,
  } = props;
  const { onAdd, onRemove, onKeyPress, addText, isTemplate, inputPlaceholder, classNamesConfig } =
    props;
  const { startDate, endDate } = props;
  const isProject = useIsProjectRoute();
  const [invalidDateErrors, setInvalidDateErrors] = useState([]);

  const onPressEnter = (event) => {
    if (!isViewMode && !disableAddMilestone && event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onAdd();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onPressEnter, true);

    return () => document.removeEventListener('keydown', onPressEnter, true);
  }, [listItems]); // eslint-disable-line

  const onChangeControlOperatorOffset = (e, val, index) => {
    const dueDate = listItems[index]?.DueDate;
    !isTemplate && dueDate
      ? onFieldsChange(
          {
            DueDate: null,
            ControlOperatorOffset: '',
          },
          index,
        )
      : validateControlOperatorOffset(e, val, onFieldsChange, index, isTemplate, true);
  };

  const getControlOffsetValue = (milestone) =>
    !isTemplate && milestone?.DueDate
      ? moment(milestone.DueDate).format('MM/DD/YYYY')
      : milestone?.ControlOperatorOffset;

  const milestones = listItems || [];

  const dateInputValueChange = (event, index) => {
    isTemplate
      ? convertToInitialOffset(event.target.value, onFieldsChange, index)
      : convertToDateTime(
          event.target.value,
          dateControls,
          (data, index) => {
            onFieldsChange(data, index);
            // Handle showing invalid date Error text.
            const publicIdErrorField = milestones?.[index]?.PublicId;
            setInvalidDateErrors(invalidDateErrors?.filter((i) => i !== publicIdErrorField));
            if (!data.DueDate) setInvalidDateErrors([...invalidDateErrors, publicIdErrorField]);
          },
          index,
          true,
        );
  };

  return (
    <div onKeyPress={onKeyPress} className={classNames(styles.timelineComponent, className)}>
      <div className={styles.inputsWrapper}>
        {milestones.map((milestone, index) => (
          <div
            key={`milestone-${milestone?.Id}-${index}`}
            className={classNames(styles.timelineItem, classNamesConfig?.timelineItem, {
              [styles.afterLine]: milestones.length > 1 && index + 1 !== milestones.length,
            })}
            testid="timeline_item"
          >
            <Point
              key={milestone?.Id || `${milestone?.Id}-${index}`}
              isPrivate={milestone?.IsPrivate}
              className={classNames(styles.point, styles.addPointWrapper, pointsClassName)}
            >
              {index + 1}
            </Point>
            <div className={styles.inputsWrap}>
              <div className={styles.timelineName}>
                <Input
                  variant={Input.LIGHT_ROUNDED}
                  placeholder={inputPlaceholder}
                  onChange={(e, val) =>
                    onFieldsChange(
                      {
                        Title: val,
                      },
                      index,
                    )
                  }
                  value={milestone?.Title || milestone?.name}
                  inputHolderClassName={classNamesConfig?.inputHolder}
                  testid="timeline_name"
                  autofocus={listItems?.length - 1 === index}
                  className={classNames(styles.timeLineName, classNamesConfig?.timelineName)}
                  inputClassName={classNames(classNamesConfig?.timelineNameInput)}
                  maxLength={25}
                  error={errors?.[index]?.nameError}
                  disabled={isViewMode || milestone?.isDateControlMilestone}
                />
              </div>

              <div className={styles.datePickerWrap}>
                <DatePicker
                  onChange={(e, val) => {
                    if (isTemplate || isViewMode || milestone?.isDateControlMilestone) return;
                    onFieldsChange(
                      {
                        DueDate: val?.[0],
                        ControlOperatorOffset: '',
                      },
                      index,
                    );
                    const publicIdErrorField = milestones?.[index]?.PublicId;
                    setInvalidDateErrors(
                      invalidDateErrors?.filter((i) => i !== publicIdErrorField),
                    );
                    if (!val?.[0]) setInvalidDateErrors([...invalidDateErrors, publicIdErrorField]);
                  }}
                  value={milestone?.DueDate || milestone?.deadline}
                  daysFromToday={milestone?.Offset}
                  options={{ enableTime: false, minDate: startDate, maxDate: endDate }}
                  className={styles.timelineDateControlPicker}
                  testid="timeline_date"
                  error={errors?.[index]?.dateError}
                  datePickerInputClass={classNamesConfig?.datePickerInputClass}
                  datePickerErrorClass={classNamesConfig?.datePickerErrorClass}
                  disabled={isTemplate || isViewMode || milestone?.isDateControlMilestone}
                  popoverCalender={true}
                  scrollToCalendar={true}
                  placement="topLeft"
                />
                <Input
                  variant={Input.LIGHT_ROUNDED}
                  placeholder={
                    !isViewMode &&
                    `${isTemplate ? 'A' : 'T'}+7, ${isProject ? 'KO+2, DL-4' : 'CD+2, CL-4'}`
                  }
                  value={getControlOffsetValue(milestone)}
                  onChange={(e, val) => onChangeControlOperatorOffset(e, val, index)}
                  onBlur={(e) => dateInputValueChange(e, index)}
                  saveOnChange={false}
                  disabled={isViewMode || milestone?.isDateControlMilestone}
                  className={styles.datePickerInput}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      dateInputValueChange(e, index);
                    }
                  }}
                />
                {invalidDateErrors?.includes(milestone?.PublicId) && (
                  <div className={styles.errorMsg}>Invalid Date</div>
                )}
              </div>
            </div>
            {!isViewMode && !milestone?.isDateControlMilestone && (
              <Options
                className={styles.optionsWrap}
                index={index}
                isPrivate={milestone?.IsPrivate}
                onPrivate={(e) =>
                  !isViewMode && onFieldsChange({ IsPrivate: !milestone?.IsPrivate }, index)
                }
                onDelete={() => !isViewMode && onRemove(index)}
                allowFirstDelete={allowFirstDelete}
              />
            )}
          </div>
        ))}

        {!hideAdd && (
          <Row
            className={classNames(styles.timelineItem, styles.lastRow, {
              [styles.beforeLine]: milestones.length,
            })}
          >
            <Col className={styles.itemColumn}>
              <div
                testid="add_button"
                onClick={() => !isViewMode && !disableAddMilestone && onAdd()}
                className={classNames(styles.add, classNamesConfig?.add, {
                  [styles.addMilestoneDisabled]: disableAddMilestone,
                })}
              >
                <Point
                  key="add"
                  className={classNames(styles.point, styles.addPointWrapper)}
                  innerClassName={styles.addPoint}
                >
                  <Icon variant={Icon.PLUS} />
                </Point>
                {addText}
              </div>
            </Col>
            <Col
              className={classNames(styles.itemColumn, styles.enterToAddText, {
                [styles.enterToAddTextDisabled]: disableAddMilestone,
              })}
            >
              <div className={styles.tip}>
                or press
                <span className={styles.accent}>Enter</span>
                to add new milestone
                <Icon className={styles.enterIcon} variant={Icon.ENTER} />
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

EditTimeline.propTypes = {
  className: PropTypes.string,
  pointsClassName: PropTypes.string,
  classNamesConfig: PropTypes.shape({
    add: PropTypes.string,
    timelineItem: PropTypes.string,
    inputHolder: PropTypes.string,
    timelineName: PropTypes.string,
    deadline: PropTypes.string,
    icon: PropTypes.string,
    numberInputClass: PropTypes.string,
    timelineNameInput: PropTypes.string,
    datePickerInputClass: PropTypes.string,
    datePickerErrorClass: PropTypes.datePickerErrorClass,
  }),
  listItems: PropTypes.arrayOf(PropTypes.shape({})),
  onFieldsChange: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onKeyPress: PropTypes.func,
  addText: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  isTemplate: PropTypes.bool,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  dateControls: PropTypes.shape({
    T: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    C: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    O: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
  hideAdd: PropTypes.bool,
  errors: PropTypes.array,
  allowFirstDelete: PropTypes.bool,
  isViewMode: PropTypes.bool,
  disableAddMilestone: PropTypes.bool,
};

EditTimeline.defaultProps = {
  className: '',
  pointsClassName: '',
  listItems: [],
  onFieldsChange: () => {},
  onAdd: () => {},
  onRemove: () => {},
  onKeyPress: () => {},
  addText: 'Add',
  inputPlaceholder: 'Name',
  classNamesConfig: {},
  isTemplate: false,
  startDate: undefined,
  endDate: undefined,
  dateControls: {
    T: undefined,
    C: undefined,
    O: undefined,
  },
  hideAdd: false,
  errors: [],
  allowFirstDelete: false,
  isViewMode: false,
  disableAddMilestone: false,
};

export default EditTimeline;
