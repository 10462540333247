import { FeedV2Query } from 'api/feed/feedV2';
import { apiServer } from 'settings/web-services/api';

export function getSearchResultsV2({
  queryParams,
  body,
}: {
  queryParams: FeedV2Query;
  body?: object;
}) {
  return apiServer.post(`/listings/v2/search`, body, {
    params: queryParams,
  });
}
