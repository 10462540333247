import * as Yup from 'yup';

// eslint-disable-next-line
const websiteRegex =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const Email = Yup.string().trim().email('Invalid email').required('Required');
const Address = Yup.object().required('Required').nullable(true);
const SuiteUnit = Yup.string().trim().nullable(true);
const Website = Yup.string().matches(websiteRegex, 'Enter correct url!').nullable(true);

const getPhoneValidator = (country) => {
  const countryCode = country?.countryCode?.toUpperCase() || 'US';
  return Yup.string()
    .nullable(true) // Allow null values
    .test(
      'is-valid-phone',
      `Must be a valid phone number for region ${countryCode}`,
      function (value) {
        if (value === null) {
          return true; // Allow null values to pass validation
        }
        return Yup.string().phone(countryCode, false).isValidSync(value);
      },
    );
};

export const ValidationSchema = (country) =>
  Yup.object().shape({
    BusinessName: Yup.string().trim().required('Required'),
    AdditionalBusinessName: Yup.string().nullable(true),
    Email,
    PhoneNumber: getPhoneValidator(country),
    Address,
    SuiteUnit,
    Description: Yup.string().trim(),
    Website,
    locations: Yup.array()
      .of(
        Yup.object().shape({
          AreasOfOperation: Yup.object().required('Location is required'),
          Radius: Yup.string().trim().required('Radius is required'),
        }),
      )
      .min(1, 'At least one location is required'),
  });
