import { Input, InputLabel } from 'components';

import styles from './styles.module.scss';

interface ContactFieldProps {
  placeholder?: string;
  value: string;
  label: string;
  error?: string;
  onChange: (value: string) => void;
}

export const ContactField = ({ placeholder, value, label, error, onChange }: ContactFieldProps) => {
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    onChange(newValue);
  };

  return (
    <div className={styles.contactField}>
      <div className={styles.fieldWrapper}>
        <InputLabel label={label} />
        <Input
          className={styles.field}
          variant={Input.LIGHT_ROUND}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          maxLength={50}
        />
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </div>
  );
};
