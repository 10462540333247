// Component wraps the tables and
// display a placeholder when there is no table data.

import React from 'react';
import styles from './styles.module.scss';

interface WorkshopTableWrapperProps {
  children?: React.ReactNode;
  data: any[];
  emptyText?: string;
}

export const WorkshopTableWrapper = ({
  children,
  data,
  emptyText = 'No Table data',
}: WorkshopTableWrapperProps) => {
  const dataCount = data.reduce((total, item) => {
    if (Array.isArray(item)) {
      return total + item.length;
    } else {
      return total + 1;
    }
  }, 0);

  return (
    <React.Fragment>
      {dataCount === 0 ? <div className={styles.workshopTableWrapper}>{emptyText}</div> : children}
    </React.Fragment>
  );
};
