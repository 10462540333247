import React, { memo, useMemo } from 'react';

interface IArrowDown {
  className?: string;
  color?: string;
  size?: number;
}

const ArrowDown = memo(({ className, color = '#262626', size }: IArrowDown) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width={size ?? '17'}
          height={size ?? '8'}
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1L7 7L1 1"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className, color, size],
  ),
);

export default ArrowDown;
