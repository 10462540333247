import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getLink } from 'helpers';

import styles from './styles.module.scss';

const Links = (props) => {
  const { className, value } = props;

  return (
    <div testid="links" className={classNames(styles.links, className)}>
      {(value || []).map((linkObject, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={styles.link}>
          <a testid="link_item" target="_blank" rel="noreferrer" href={getLink(linkObject.link)}>
            {linkObject.label ? linkObject.label : linkObject.link}
          </a>
        </div>
      ))}
      {value?.length ? null : <p className={styles.notAvailable}>No links available</p>}
    </div>
  );
};

Links.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
};

Links.defaultProps = {
  className: '',
  value: [],
};

export default Links;
