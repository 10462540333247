export interface DataType {
  key: string;
  month: string;
  value: string;
  percentage: string;
}

export const sampleData: DataType[] = [
  {
    key: '1',
    month: 'June 2024',
    value: '$520,000',
    percentage: '+2%',
  },
  {
    key: '2',
    month: 'May 2024',
    value: '$510,000',
    percentage: '-2.7%',
  },
  {
    key: '3',
    month: 'April 2024',
    value: '$520,000',
    percentage: '+0.4%',
  },
  {
    key: '4',
    month: 'March 2024',
    value: '$500,000',
    percentage: '+2%',
  },
  {
    key: '5',
    month: 'February 2024',
    value: '$460,000',
    percentage: '-2.7%',
  },
  {
    key: '6',
    month: 'January 2024',
    value: '$450,000',
    percentage: '+0.4%',
  },
  {
    key: '7',
    month: 'December 2023',
    value: '$460,000',
    percentage: '+2%',
  },
  {
    key: '8',
    month: 'November 2023',
    value: '$420,000',
    percentage: '-2.7%',
  },
  {
    key: '9',
    month: 'October 2023',
    value: '$380,000',
    percentage: '+0.4%',
  },
  {
    key: '10',
    month: 'September 2023',
    value: '$350,000',
    percentage: '+2%',
  },
  {
    key: '11',
    month: 'August 2023',
    value: '$310,000',
    percentage: '-2.7%',
  },
  {
    key: '12',
    month: 'July 2023',
    value: '$300,000',
    percentage: '+0.4%',
  },
  {
    key: '13',
    month: 'June 2023',
    value: '$290,000',
    percentage: '+2.5%',
  },
  {
    key: '14',
    month: 'May 2023',
    value: '$250,000',
    percentage: '+2.5%',
  },
  {
    key: '15',
    month: 'April 2023',
    value: '$240,000',
    percentage: '+2.5%',
  },
  {
    key: '16',
    month: 'March 2023',
    value: '$330,000',
    percentage: '+2.5%',
  },
];
