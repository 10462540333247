import { useLocation } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import Transactions from './Transactions';
import { Showings } from './Showings';
import { Offers } from './Offers';
import { Forms } from './Forms';
import { Tasks } from './Tasks';
import { LocalHeader } from './LocalHeader';
import { Milestones } from './Milestones';

export const Workshop = () => {
  const { pathname } = useLocation();
  const showComponent = () => {
    switch (pathname) {
      case routes.transactions:
        return <Transactions />;
      case routes.projects:
        return <Transactions />;
      case routes.showings:
        return <Showings />;
      case routes.offers:
        return <Offers />;
      case routes.workshopForms:
        return <Forms />;
      case routes.tasks:
        return <Tasks />;
      case routes.milestones:
        return <Milestones />;
      case routes.transactionCancellation:
        return <Tasks />;
      default:
        return <Transactions />;
    }
  };

  return (
    <>
      <LocalHeader />
      {showComponent()}
    </>
  );
};
