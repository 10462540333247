import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icons from 'pages/Quotes/components/QuoteDetails/Icons';
import { Wrapper as PendingWrapper } from 'components';
import styles from './styles.module.scss';

const Upload = (props) => {
  const { id, className, iconClassName, onChange, value, label, isPending, isPDFOnly } = props;
  const fileInputRef = useRef();

  useEffect(() => {
    if (!value?.length && fileInputRef?.current) {
      fileInputRef.current.value = null;
    }
  }, [value?.length]);

  return (
    <div className={classNames(className)}>
      <label testid="upload" className={classNames(styles.fileInput, styles.upload)} htmlFor={id}>
        <PendingWrapper
          isPending={isPending}
          className={styles.pendingWrapper}
          loaderClassName={styles.loader}
        >
          <Icons
            testid="attachment_icon"
            className={classNames(styles.icon, iconClassName)}
            variant={Icons.UPLOAD}
          />
          <div>{label}</div>
          <input
            ref={fileInputRef}
            multiple
            type="file"
            {...(isPDFOnly && { accept: 'application/pdf' })}
            onChange={onChange}
            className={styles.nativeFileInput}
            id={id}
            testid="attachment_input"
          />
        </PendingWrapper>
      </label>
    </div>
  );
};

Upload.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  isPending: PropTypes.bool,
  isPDFOnly: PropTypes.bool,
};

Upload.defaultProps = {
  className: '',
  iconClassName: '',
  onChange: () => {},
  value: [],
  label: 'Upload',
  isPending: false,
  isPDFOnly: false,
};

export default Upload;
