import { apiServer } from 'settings/web-services/api';

export const getFinacials = async (cfg) => {
  const response = await apiServer.get(`/transaction/${cfg.transactionId}/financial`);
  return response?.data?.result;
};

export const getSplitNames = async () => {
  const response = await apiServer.get(`/transaction/split-names`);
  return response?.data?.result;
};

export const getFeeNames = async () => {
  const response = await apiServer.get(`/transaction/fee-names`);
  return response?.data?.result;
};

export const getLeadSourceNames = async () => {
  const response = await apiServer.get(`/transaction/lead-source-names`);
  return response?.data?.result;
};

export const updateFinancialCommission = async (cfg, data) => {
  const response = await apiServer.patch(
    `/transaction/${cfg.transactionId}/financial-commission`,
    cfg?.data,
  );
  return response?.data?.result;
};

export const updateFinancialSplits = async (cfg, data) => {
  const response = await apiServer.put(`/transaction/${cfg.transactionId}/fee-splits`, cfg?.data);
  return response?.data?.result;
};

export const addFinancialSplit = async (cfg, data) => {
  const response = await apiServer.post(`/transaction/${cfg.transactionId}/fee-split`, cfg?.data);
  return response?.data?.result;
};

export const updateFinancialSplit = async (cfg, data) => {
  const response = await apiServer.patch(
    `/transaction/${cfg.transactionId}/split-type/${cfg?.id}`,
    cfg?.data,
  );
  return response?.data?.result;
};

export const deleteFinancialSplit = async (cfg, data) => {
  const response = await apiServer.delete(
    `/transaction/${cfg.transactionId}/split-type/${cfg?.id}`,
    cfg?.data,
  );
  return response?.data?.result;
};

export const addFinancialFee = async (cfg, data) => {
  const response = await apiServer.post(
    `/transaction/${cfg.transactionId}/financial-fee`,
    cfg?.data,
  );
  return response?.data?.result;
};

export const updateFinancialFee = async (cfg, data) => {
  const response = await apiServer.patch(
    `/transaction/${cfg.transactionId}/financial-fee/${cfg?.id}`,
    cfg?.data,
  );
  return response?.data?.result;
};

export const deleteFinancialFee = async (cfg, data) => {
  const response = await apiServer.delete(
    `/transaction/${cfg.transactionId}/financial-fee/${cfg?.id}`,
    cfg?.data,
  );
  return response?.data?.result;
};

export const moveFinancialFee = async (cfg) => {
  const response = await apiServer.put(
    `/transaction/${cfg.transactionId}/change-fee-side/${cfg?.id}`,
  );
  return response?.data?.result;
};

export const updateLeadSource = async (cfg) => {
  const response = await apiServer.put(`/transaction/${cfg.transactionId}/lead-source`, cfg?.data);
  return response?.data?.result;
};
