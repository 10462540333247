import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { FormCommentType } from 'types';
import { IDLE } from 'settings/constants/apiState';
import {
  getFormCommentsAction,
  getFormCommentUsersAction,
  resetFormCommentStateAction,
} from 'store/actions/formComment';

const initialState: FormCommentType = {
  state: IDLE,
  fieldThreads: {
    state: IDLE,
    data: {},
  },
  commentUsers: {},
};

export default handleActions(
  {
    [getFormCommentUsersAction as any]: (state: FormCommentType, { payload }): FormCommentType => ({
      ...state,
      commentUsers: get(payload, 'data.response', state.commentUsers),
    }),
    [getFormCommentsAction as any]: (state: FormCommentType, { payload }): FormCommentType => ({
      ...state,
      fieldThreads: {
        state: payload.state,
        data: get(payload, 'data.response', state.fieldThreads.data),
      },
    }),
    [resetFormCommentStateAction as any]: (): FormCommentType => initialState,
  },
  initialState,
);
