import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { GreenCheckMark } from 'components/Icons';
import Icons from '../Icons';
import { Card } from '../Card';
import {
  getLoadingNeedsAttentionDataSelector,
  getAgentNeedsAttentionSelector,
  getTeamNeedsAttentionSelector,
} from 'store/selectors/agentDashboard/needsAttention';
import { Wrapper as PendingWrapper } from 'components';
import { TEAM_BASIC } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

import styles from './styles.module.scss';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { setTransactionsTasksFilterEffect } from 'store/effects';
import { taskFilters } from 'settings/constants/transactionTasks';

export const NeedsAttentionWidget = ({ teamStats }) => {
  const [stats, setStats] = useState<NeedsAttention | []>([]);

  const { isPending } = useSelector(getLoadingNeedsAttentionDataSelector);
  const agentData = useSelector(getAgentNeedsAttentionSelector);
  const teamData = useSelector(getTeamNeedsAttentionSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const [teamView, setTeamView] = useState(true);

  useEffect(() => {
    if (!isPending) {
      teamStats ? setStats(teamData) : setStats(agentData);
      setTeamView(agentRole !== TEAM_BASIC ? teamStats : true);
    }
  }, [isPending, teamStats]);

  const headerKeys = ['Tasks', 'Milestones', 'Offers', 'Showings', 'Buyers', 'Forms', 'Messages'];
  const headerUI = {
    Insights: {
      color: '#FB913A',
      bgColor: '#FB913A14',
      icon: <Icons className={styles.icon} variant="bulb" />,
    },
    Tasks: {
      color: '#4673D1',
      bgColor: '#4673D114',
      icon: <Icons className={styles.icon} variant="tasks" />,
      redirectTo: {
        overdue: () => {
          history.push(`/workshop/tasks?teamStats=${teamView}&status=Overdue`);
          dispatch(
            setTransactionsTasksFilterEffect(
              teamView ? taskFilters?.allTasks : taskFilters.myTasks,
            ),
          );
        },
        stuck: () => history.push(`/workshop/tasks?teamStats=${teamView}&status=Stuck`),
        dueToday: () => history.push(`/workshop/tasks?teamStats=${teamView}&dueToday=true`),
        newComments: () => history.push(`/workshop/tasks?teamStats=true`),
      },
    },
    Milestones: {
      color: '#928CDA',
      bgColor: '#928CDA14',
      icon: <Icons className={styles.icon} variant="list" stroke="#928CDA" />,
      redirectTo: {
        overdue: () => history.push(`/workshop/milestones?teamStats=${teamView}&status=Overdue`),
      },
    },
    Offers: {
      color: '#FB913A',
      bgColor: '#FB913A14',
      icon: <Icons className={styles.icon} variant="document" />,
      redirectTo: {
        new: () => history.push(`/workshop/offers?teamStats=${teamView}&show=New`),
        pending: () => history.push(`/workshop/offers?teamStats=${teamView}&status=Pending`),
        expiring: () =>
          history.push(
            `/workshop/offers?teamStats=${teamView}&status=Pending,CounterReceived,CounterSent&statuses=OfficeExclusive,ActiveListing,PreListing,UnderContract,ComingSoon,OnHold,ClearToClose&dueToday=true}`,
          ),
        withdrawn: () =>
          history.push(`/workshop/offers?teamStats=${teamView}&isArchive=true&status=Withdrawn`),
        edited: () => history.push(`/workshop/offers?teamStats=${teamView}`),
      },
    },
    Showings: {
      color: '#928CDA',
      bgColor: '#928CDA14',
      icon: <Icons className={styles.icon} variant="calendar" stroke="#928CDA" />,
      redirectTo: {
        pendingRequests: () =>
          history.push(`/workshop/showings?teamStats=${teamStats}&status=Pending`),
        lapsed: () => history.push(`/workshop/showings?teamStats=${teamStats}&isArchive=true`),
        canceled: () =>
          history.push(`/workshop/showings?teamStats=${teamStats}&isArchive=true&status=Canceled`),
        declined: () =>
          history.push(`/workshop/showings?teamStats=${teamStats}&isArchive=true&status=Declined`),
        newFeedback: () => history.push(`/workshop/showings`),
      },
    },
    Buyers: {
      color: '#51BFE1',
      bgColor: '#51BFE114',
      icon: <Icons className={styles.icon} variant="users" />,
      redirectTo: {
        tourRequests: () => history.push(`/properties/tours?teamStats=${teamStats}`),
        newFavorites: () => history.push(`/properties/feed?option=newFavorites`),
        newComments: () => history.push(`/properties/feed?option=newComments`),
        newSearches: () => history.push(`/properties/feed?option=newSearches`),
        updatedSearches: () => history.push(`/properties/feed?option=updatedSearches`),
        newMatches: () => history.push(`/properties/feed?option=newMatches`),
      },
    },
    Forms: {
      color: '#4673D1',
      bgColor: '#4673D114',
      icon: <Icons className={styles.icon} variant="text_document" />,
      redirectTo: {
        waitingOnYou: () => history.push(`/workshop/forms`),
        waitingOnOthers: () => history.push(`/workshop/forms`),
        recentlyCompleted: () => history.push(`/workshop/forms`),
        declined: () => history.push(`/workshop/forms`),
        newComments: () => history.push(`/workshop/forms`),
      },
    },
    Messages: {
      color: '#928CDA',
      bgColor: '#928CDA14',
      icon: <Icons className={styles.icon} variant="messages_2" />,
      redirectTo: {
        unread: () => openUnreadMessagesDrawer(),
      },
    },
  };

  const openUnreadMessagesDrawer = () => {
    dispatch(openMessagesDrawerAction(true));
    dispatch(
      changeMessagesDrawerTypeAction({
        type: DRAWER_MESSAGES_TYPES.UNREAD,
      }),
    );
  };

  const getContentStack = (trxList, index) => {
    let contentStack: any[] = [];
    const key = headerKeys[index];
    contentStack.push(
      <div className={styles.stackHeaderItem}>
        <div className={styles.stackIcon} style={{ backgroundColor: headerUI[key].bgColor }}>
          {headerUI[key].icon}
        </div>

        <span>{key}</span>
      </div>,
    );
    trxList.map((item) => {
      for (const [trxItem, trxCount] of Object.entries(item)) {
        contentStack.push(
          <div
            className={classNames(styles.stackItem, {
              [styles.clickable]: !!headerUI[key].redirectTo?.[trxItem],
            })}
            style={{ color: headerUI[key].color, backgroundColor: headerUI[key].bgColor }}
            onClick={headerUI[key].redirectTo?.[trxItem]}
          >
            <div className={styles.stackValue}>{trxCount}</div>
            <div className={styles.stackKey}>{NeedAttentionKeys[trxItem]}</div>
          </div>,
        );
      }
    });

    return contentStack;
  };

  const isStatsEmpty = () => stats?.some((item) => item.length);

  return (
    <Card
      cardTitle={'Needs Attention'}
      showHeader={true}
      cardWrapperClassName={classNames(styles.cardWrapper, {
        [styles.noDataWrapper]: !stats?.length,
      })}
      cardHeaderClassName={styles.cardHeader}
      cardBodyClassName={styles.cardBody}
    >
      <PendingWrapper
        className={styles.pendingWrapper}
        isPending={isPending}
        loaderClassName={styles.loader}
      >
        {isStatsEmpty() ? (
          <>
            <div className={styles.stackHeaderRow}></div>
            <div className={classNames(styles.needsAttentionContent)}>
              {stats.map((list, index) => {
                return list.length ? (
                  <div className={styles.stackColumn} key={index}>
                    {getContentStack(list, index)}
                  </div>
                ) : (
                  <></>
                );
              })}
            </div>
          </>
        ) : (
          <div
            className={classNames(styles.needsAttentionContent, {
              [styles.minHeight]: !stats?.length,
            })}
          >
            <div className={styles.flexCol}>
              <div className={styles.checkMark}>
                <GreenCheckMark width={20} height={20} />
              </div>
              <p>{`You're all set for now`}</p>
            </div>
          </div>
        )}
      </PendingWrapper>
    </Card>
  );
};

type NeedsAttention = [
  [{ overdue?: number }, { dueToday?: number }, { stuck?: number }, { newCommments?: number }],
  [{ overdue?: number }],
  [
    { new?: number },
    { pending?: number },
    { expiring?: number },
    { withdrawn?: number },
    { edited?: number },
  ],
  [
    { pendingRequests?: number },
    { lapsed?: number },
    { canceled?: number },
    { declined?: number },
    { newFeedback?: number },
  ],
  [
    { tourRequests?: number },
    { newFavorites?: number },
    { newComments?: number },
    { newSearches?: number },
    { updatedSearches?: number },
    { newMatches?: number },
    { watchlistActivity?: number },
  ],
  [
    { waitingOnYou?: number },
    { waitingOnOthers?: number },
    { recentlyCompleted?: number },
    { declined?: number },
    { newComments?: number },
  ],
  [{ unread?: number }, { unreadTags?: number }],
];

export enum NeedAttentionKeys {
  overdue = 'Overdue',
  dueToday = 'Due Today',
  stuck = 'Stuck',
  newComments = 'New Comments',
  new = 'New',
  pending = 'Pending',
  expiring = 'Expiring',
  withdrawn = 'Withdrawn',
  edited = 'Edited',
  pendingRequests = 'Pending Requests',
  lapsed = 'Lapsed',
  canceled = 'Canceled',
  declined = 'Declined',
  newFeedback = 'New Feedback',
  tourRequests = 'Tour Requests',
  newFavorites = 'New Favorites',
  newSearches = 'New Searches',
  updatedSearches = 'Updated Searches',
  newMatches = 'New Matches',
  watchlistActivity = 'Watchlist Activity',
  waitingOnYou = 'Waiting On You',
  waitingOnOthers = 'Waiting On Others',
  recentlyCompleted = 'Recently Completed',
  unread = 'Unread',
  unreadTags = 'Unread Tags',
  pendingResponse = 'Pending Response',
  oppotunities = 'Opportunities',
  risks = 'Risks',
}
