import classnames from 'classnames';
import { useState } from 'react';

import { AgentDetailsBox } from './AgentDetailsBox';
import { agentBreakdownData } from './helper';

import styles from './styles.module.scss';

export const BreakdownMenu = ({ open }) => {
  const [data, setData] = useState(agentBreakdownData);

  return (
    <div className={classnames(styles.breakdownWrapper, { [styles.openWrapper]: open })}>
      <div className={classnames(styles.breakdownContent, { [styles.openMenuContent]: open })}>
        <div className={styles.breakdownHeading}>Breakdown</div>
        {data.map((agent, index) => (
          <AgentDetailsBox key={index} agent={agent} />
        ))}
      </div>
    </div>
  );
};
