import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { Button } from 'components';
import { PropertyTypeMap } from 'types/property';

import styles from './styles.module.scss';

const Template = (props) => {
  const { className, name, description, propertyType, financing, onPreview, onSelect, templateId } =
    props;

  return (
    <div testid="template_line" className={classNames(styles.template, className)}>
      <div className={styles.templateInner}>
        <div className={styles.inner}>
          <Icon className={styles.icon} variant={Icon.TIMELINE} />
          <div className={styles.names}>
            <div testid="name" className={styles.name}>
              {name}
            </div>
            {description && (
              <p testid="description" className={styles.description}>
                {description.slice(0, 80)}
                {description.slice(0).length > 80 ? '...' : null}
              </p>
            )}
            {propertyType.length ? (
              <p testid="propertyType" className={styles.propertyType}>
                <span>Property Type: </span>
                {propertyType.map((val) => PropertyTypeMap[val]).join(', ')}
              </p>
            ) : null}
            {financing?.length ? (
              <p testid="financing" className={styles.financing}>
                <span>Financing: </span>
                {financing.join(', ')}
              </p>
            ) : null}
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            onClick={() => onPreview(templateId)}
            className={classNames(styles.btn, styles.preview)}
            title="Preview"
            testid="preview_button"
          />
          <Button
            testid="select_button"
            onClick={() => onSelect(templateId)}
            className={classNames(styles.btn)}
            title="Select"
          />
        </div>
      </div>
    </div>
  );
};

Template.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  propertyType: PropTypes.string,
  financing: PropTypes.string,
  onPreview: PropTypes.func,
  onSelect: PropTypes.func,
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

Template.defaultProps = {
  className: '',
  name: '',
  description: '',
  propertyType: '',
  financing: '',
  onPreview: () => {},
  onSelect: () => {},
};

export default Template;
