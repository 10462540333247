import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { map } from 'lodash-es';

import { getPropertyTwoLinesAddress, convertNameToAvatarPlaceholder } from 'helpers/formatters';
import { getThreadContactsOpenedContactInfo } from 'store/selectors/sockets/threads';
import { Avatar, FormattedPhone, PageNotFound } from 'components';
import { AGENT } from 'settings/constants/roles';
import { getHrefLink } from 'helpers';
import { getRelatedContactState } from 'store/selectors/relatedEntities';
import { getContactDetailsEffect } from 'store/effects/relatedEntities';

import styles from './styles.module.scss';

const ContactContent = ({ contactId }) => {
  const dispatch = useDispatch();
  let contact =
    useSelector(getThreadContactsOpenedContactInfo) ||
    useSelector(getRelatedContactState)[contactId];

  useEffect(() => {
    if (!contact && contactId) {
      dispatch(getContactDetailsEffect({ id: contactId }, {}, (err) => {}));
    }
  }, []);

  const role = contact?.Roles?.[0];
  const [line1, line2] = getPropertyTwoLinesAddress(contact?.Agent?.Brokerage?.Address, true);
  return (
    <div testid="contact_content" className={styles.wrapper}>
      {contact ? (
        <>
          <div className={styles.userTitle}>
            <Avatar
              avatarClassName={styles.userTitleAvatar}
              src={contact?.AvatarUrl}
              placeholder={convertNameToAvatarPlaceholder(
                `${contact?.FirstName} ${contact?.LastName}`,
              )}
            />
            <div
              testid="name"
              className={styles.userTitleName}
            >{`${contact?.FirstName} ${contact?.LastName}`}</div>
            <div testid="role" className={styles.userTitleRole}>
              {role}
            </div>
          </div>
          <div className={styles.contactInfo}>
            <div className={styles.contactInfoTitle}>Contact Info</div>
            <div className={styles.contactInfoBlock}>
              <div className={styles.contactInfoBlockLeft}>Email</div>
              <div testid="email" className={styles.contactInfoBlockRight}>
                {contact?.Email ? <a href={`mailto:${contact?.Email}`}>{contact?.Email}</a> : '-'}
              </div>
            </div>
            <div className={styles.contactInfoBlock}>
              <div className={styles.contactInfoBlockLeft}>Phone</div>
              <div testid="phone" className={styles.contactInfoBlockRight}>
                {contact?.Phones?.[0]?.PhoneNumber ? (
                  <FormattedPhone className={styles.contactInfoBlockPhone}>
                    {contact?.Phones?.[0]?.PhoneNumber}
                  </FormattedPhone>
                ) : (
                  '-'
                )}
              </div>
            </div>
            {role !== AGENT && (
              <div className={styles.contactInfoBlock}>
                <div className={styles.contactInfoBlockLeft}>Mailing Address</div>
                <div testid="address" className={styles.contactInfoBlockRight}>
                  {contact?.Address?.find((c) => c.isPrimary)?.address || '-'}
                </div>
              </div>
            )}
            {role === AGENT && (
              <>
                <div className={styles.contactInfoBlock}>
                  <div className={styles.contactInfoBlockLeft}>Address</div>
                  <div testid="address" className={styles.contactInfoBlockRight}>
                    <div>{line1 || line2 || '-'}</div>
                    {line1 && line2 && <div>{line2}</div>}
                  </div>
                </div>
                <div className={styles.contactInfoBlock}>
                  <div className={styles.contactInfoBlockLeft}>Brokerage</div>
                  <div testid="brokerage" className={styles.contactInfoBlockRight}>
                    {contact?.Agent?.Brokerage?.Name || '-'}
                  </div>
                </div>
                <div className={styles.contactInfoBlock}>
                  <div className={styles.contactInfoBlockLeft}>Areas of Operation</div>
                  <div testid="areas_of_operation" className={styles.contactInfoBlockRight}>
                    {contact?.Agent?.AreasOfOperation?.length
                      ? map(contact?.Agent?.AreasOfOperation, ({ ProviderPlaceId, PlaceName }) => (
                          <div key={ProviderPlaceId}>{PlaceName}</div>
                        ))
                      : '-'}
                  </div>
                </div>
                <div className={styles.contactInfoBlock}>
                  <div className={styles.contactInfoBlockLeft}>Links</div>
                  <div testid="websites" className={styles.contactInfoBlockRight}>
                    {contact?.Agent?.Websites?.length
                      ? map(contact?.Agent?.Websites, (link) => (
                          <div key={link}>
                            <a href={getHrefLink(link)} target="_blank" rel="noreferrer">
                              {link}
                            </a>
                          </div>
                        ))
                      : '-'}
                  </div>
                </div>
                <div className={styles.contactInfoBlockBio}>
                  <div className={styles.contactInfoTitle}>Bio</div>
                  <div testid="bio">{contact?.Agent?.Bio || '-'}</div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <PageNotFound info="This profile can't be found." showButton={false} />
      )}
    </div>
  );
};

ContactContent.propTypes = {
  contactId: PropTypes.number,
};

ContactContent.defaultProps = {
  contactId: undefined,
};

export default ContactContent;
