import { ClientAddress } from 'app-constants';

export const extractClientAddress = (
  clientAddress?: string | ClientAddress[],
  placeId?: string,
) => {
  let address = clientAddress;
  let suiteUnit: string | undefined;
  if (typeof clientAddress === 'string') {
    return { address, suiteUnit, placeId };
  } else {
    const primaryAddress = clientAddress?.find((address) => address.isPrimary);
    address = primaryAddress?.address;
    suiteUnit = primaryAddress?.suiteUnit;
    placeId = placeId || primaryAddress?.placeId;
    const addressParsed = {
      City: primaryAddress?.addressParsed?.City,
      State: primaryAddress?.addressParsed?.State,
      Zip: primaryAddress?.addressParsed?.Zip,
      Line1: primaryAddress?.addressParsed?.Line1,
      ProviderPlaceId: primaryAddress?.addressParsed?.ProviderPlaceId,
      PlaceName: primaryAddress?.addressParsed?.PlaceName,
      Street: primaryAddress?.addressParsed?.Street,
    };
    return { address, suiteUnit, placeId, addressParsed };
  }
};
