import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from 'components';

import styles from './styles.module.scss';
import ArrowRightFull from 'components/Icons/ArrowRightFull';

const Footer = ({
  testid,
  className,
  buttonTitle,
  isValid,
  buttonType,
  disabled,
  onClick,
  isPending,
}) => (
  <div testid={testid} className={classNames(className, styles.footer)}>
    <Button
      isPending={isPending}
      disabled={disabled || !isValid}
      type={buttonType}
      className={classNames({ [styles.button]: true, [styles.active]: isValid && !disabled })}
      titleClassName={styles.titleClassName}
      title={buttonTitle}
      testid="main_button"
      onClick={onClick}
      icon={<ArrowRightFull color="#fff" />}
    />
  </div>
);

Footer.propTypes = {
  className: PropTypes.string,
  buttonTitle: PropTypes.string,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isPending: PropTypes.bool,
  buttonType: PropTypes.string,
  testid: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
  disabled: false,
  isValid: false,
  buttonTitle: '',
  buttonType: '',
  testid: undefined,
  isPending: false,
  onClick: () => {},
};

export default Footer;
