import {
  prefsIds,
  HOME_PREFS_OPTIONS,
  AMENITIES_OPTIONS,
  INTERIOR_PREFS_OPTIONS,
  NEIGHBORHOOD_OPTIONS,
  OUTDOOR_PREFS_OPTIONS,
  convertPrefs,
} from 'settings/constants/preferences';
import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';

const getNonSpecificConfig = (allSchools) => {
  const nonSpecificSchools = allSchools.filter((val) => val.type === 'NonSpecificConfig');
  return nonSpecificSchools.map((data) => data.Id);
};
export const getFormattedData = (search, cfg) => {
  const getMustPrefs = (prefOptions) => {
    return cfg[SEARCH_CRITERIA_IMPORTANCE.MUST].filter(
      (prefItem) => !!prefOptions.find(({ id }) => id === prefItem),
    );
  };

  const getSomeWhatPrefs = (prefOptions) =>
    cfg[SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT].filter(
      (prefItem) => !!prefOptions.find(({ id }) => id === prefItem),
    );
  const getSchoolDataMust = () => {
    let schools = [];

    cfg[SEARCH_CRITERIA_IMPORTANCE.MUST].forEach((prefItem) => {
      if (typeof prefItem === 'object') {
        schools.push({
          Id: prefItem?.Id,
          Name: prefItem?.Name,
          type: prefItem?.type,
          ImportanceAndWeight: { Weight: 3, Importance: SEARCH_CRITERIA_IMPORTANCE.MUST },
        });
      }
    });
    cfg[SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT].forEach((prefItem) => {
      if (typeof prefItem === 'object') {
        schools.push({
          Id: prefItem?.Id,
          Name: prefItem?.Name,
          type: prefItem?.type,
          ImportanceAndWeight: { Weight: 3, Importance: SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT },
        });
      }
    });
    if (!schools?.length) {
      return null;
    }
    return {
      SpecificSchools: schools.filter((val) => val.type === 'SpecificSchools'),
      SpecificDistricts: schools.filter((val) => val.type === 'SpecificDistricts'),
      NonSpecificConfig: { SchoolTypes: getNonSpecificConfig(schools) },
    };
  };
  const joinPrefs = (must, somewhat) => {
    if (!must.length && !somewhat.length) return undefined;
    return [...must, ...somewhat];
  };

  const mustHomePrefs = convertPrefs(
    getMustPrefs(HOME_PREFS_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.MUST,
  );
  const someWhatHomePrefs = convertPrefs(
    getSomeWhatPrefs(HOME_PREFS_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
  );

  const mustInteriorPrefs = convertPrefs(
    getMustPrefs(INTERIOR_PREFS_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.MUST,
  );
  const somewhatInteriorPrefs = convertPrefs(
    getSomeWhatPrefs(INTERIOR_PREFS_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
  );

  const mustAmenitiesPrefs = convertPrefs(
    getMustPrefs(AMENITIES_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.MUST,
  );
  const someWhatAmenitiesPrefs = convertPrefs(
    getSomeWhatPrefs(AMENITIES_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
  );

  const mustNeighborHoodsPrefs = convertPrefs(
    getMustPrefs(NEIGHBORHOOD_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.MUST,
  );
  const someWhatNeighborHoodsPrefs = convertPrefs(
    getSomeWhatPrefs(NEIGHBORHOOD_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
  );

  const mustOutdoorPrefs = convertPrefs(
    getMustPrefs(OUTDOOR_PREFS_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.MUST,
  );
  const someWhatOutdoorPrefs = convertPrefs(
    getSomeWhatPrefs(OUTDOOR_PREFS_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
  );

  let mustParkingPref = convertPrefs(
    getMustPrefs([{ id: prefsIds.parking }]),
    SEARCH_CRITERIA_IMPORTANCE.MUST,
  )?.[0];

  let someWhatParkingPref = convertPrefs(
    getSomeWhatPrefs([{ id: prefsIds.parking }]),
    SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
  )?.[0];

  const parkingSpaces = search?.Parking?.NumOfParkingSpaces;
  const garageOnly = search?.Parking?.GarageOnly;
  const garageType = search?.Parking?.GarageType;
  if (mustParkingPref) {
    mustParkingPref = {
      ...mustParkingPref,
      NumOfParkingSpaces: parkingSpaces || 1,
      GarageOnly: garageOnly,
      GarageType: garageType,
    };
    delete mustParkingPref?.Preference;
  }

  if (someWhatParkingPref) {
    someWhatParkingPref = {
      ...someWhatParkingPref,
      NumOfParkingSpaces: parkingSpaces || 1,
      GarageOnly: garageOnly,
      GarageType: garageType,
    };
    delete someWhatParkingPref?.Preference;
  }

  return {
    ...search,
    HomePrefs: joinPrefs(
      [...mustHomePrefs, ...mustInteriorPrefs],
      [...someWhatHomePrefs, ...somewhatInteriorPrefs],
    ),
    AmenitiesPrefs: joinPrefs(mustAmenitiesPrefs, someWhatAmenitiesPrefs),
    NeighborhoodPrefs: joinPrefs(mustNeighborHoodsPrefs, someWhatNeighborHoodsPrefs),
    OutdoorPrefs: joinPrefs(mustOutdoorPrefs, someWhatOutdoorPrefs),
    Parking: mustParkingPref || someWhatParkingPref,
    Schools: getSchoolDataMust() ? getSchoolDataMust() : undefined,
  };
};
