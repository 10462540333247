import { useState, FC, useRef, Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import fileDownload from 'js-file-download';

import { Document } from 'pages/Properties/MySearches/components/Document';
import { ConfirmModal } from 'components';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { deletePreApprovalDocumentEffect, previewDocumentEffect } from 'store/effects';
import { getUserId } from 'store/selectors/user';
import { deletePreApprovalManualEntry } from 'api/mySearches';
import { useOutsideClick } from 'hooks';
import { showSuccessMessage } from 'helpers';
import { showErrorMessage } from 'helpers/errors';

import styles from './styles.module.scss';

interface Props {
  document: any;
  clientId?: string;
  type: string;
  handleEditManualEntry: (...args: any) => void;
  getPreApprovalDocuments: (...args: any) => void;
  setPreApprovalCount?: Dispatch<SetStateAction<number>>;
}
export const ClientPreApprovalsActionsMenu: FC<Props> = ({
  document,
  clientId,
  type,
  handleEditManualEntry,
  getPreApprovalDocuments,
  setPreApprovalCount,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);

  const buttonRef = useRef(null);
  const optionsRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [requestPending, setRequestPending] = useState(false);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const onDelete = (e) => {
    e.stopPropagation();
    setOpen(false);
    setOpenConfirmModal(true);
  };

  const handleDownloadDocument = () => {
    dispatch(
      previewDocumentEffect(
        { DocumentVaultUUID: document.Documents.DocumentVaultUUID },
        {},
        (err, response) => {
          if (!err) {
            fileDownload(response.data, response.headers['file-name']);
          }
        },
      ),
    );
  };
  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen((val) => !val);
  };
  const onCloseModal = (e) => {
    e.stopPropagation();
    setOpenConfirmModal(false);
  };

  const onDeleteDocument = async () => {
    setOpenConfirmModal(false);
    const response = await deletePreApprovalManualEntry({
      clientId,
      agentId: userId,
      id: document.Id,
      documentId: document.Documents.Id,
    });

    if (response?.status === 200 && response?.data?.success) {
      getPreApprovalDocuments();
      showSuccessMessage('Manual entry removed successfully');
      setPreApprovalCount?.((val) => val - 1);
    } else {
      showErrorMessage('Unable to delete manual entry');
    }
  };
  const onDeleteManualEntry = async () => {
    setOpenConfirmModal(false);
    const response = await deletePreApprovalManualEntry({
      clientId,
      agentId: userId,
      id: document.Id,
    });
    if (response?.status === 200 && response?.data?.success) {
      getPreApprovalDocuments();
      showSuccessMessage('Manual entry removed successfully');
    } else {
      showErrorMessage('Unable to delete manual entry');
    }
  };
  return (
    <div className={classNames(styles.documentOptionsWrapper, styles.actions)}>
      <div
        testid="options"
        ref={buttonRef}
        onClick={onClickOptions}
        className={classNames(styles.optionsDots, styles.optionDots)}
      >
        <Icon variant={Icon.DOTS} />
      </div>
      {open &&
        (type === 'document' ? (
          <div ref={optionsRef} className={styles.options}>
            <ul>
              <li testid="edit_action" className={styles.item} onClick={handleDownloadDocument}>
                <div className={styles.icon}>
                  <Icon variant={Icon.DOWNLOAD} />
                </div>
                <span>Download</span>
              </li>

              <li testid="delete_action" className={styles.item} onClick={onDelete}>
                <div className={styles.icon}>
                  <Icon variant={Icon.OPTIONS_DELETE} />
                </div>
                <span>Delete</span>
              </li>
            </ul>
          </div>
        ) : (
          <div ref={optionsRef} className={styles.options}>
            <ul>
              <li testid="edit_action" className={styles.item} onClick={handleEditManualEntry}>
                <div className={styles.icon}>
                  <Icon variant={Icon.OPTIONS_EDIT} />
                </div>
                <span>Edit</span>
              </li>

              <li testid="delete_action" className={styles.item} onClick={onDelete}>
                <div className={styles.icon}>
                  <Icon variant={Icon.OPTIONS_DELETE} />
                </div>
                <span>Delete</span>
              </li>
            </ul>
          </div>
        ))}
      <ConfirmModal
        title="Are you sure you want to delete the document?"
        isOpen={openConfirmModal}
        onClose={onCloseModal}
        onSubmit={() => {
          if (type === 'document') {
            onDeleteDocument();
          } else {
            onDeleteManualEntry();
          }
        }}
        isPending={requestPending}
        submitButtonTitle="Delete"
        cancelButtonTitle="Cancel"
        icon
      />
    </div>
  );
};
