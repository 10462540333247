import Api from 'store/effects/core/api';
import { getMatchedClientsWithScoresByMls, getAgentMatchScore } from 'api/mlsScores';
import {
  requestGetMatchedClientsWithScoresByMls,
  reqeustGetAgentMatchScoreWithCriteria,
} from 'store/actions/mlsScores';

export const requestGetMatchedClientsWithScoresByMlsEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetMatchedClientsWithScoresByMls,
    method: getMatchedClientsWithScoresByMls,
  });

  return sendRequest(cfg, options, cb);
};

export const requestGetAgentMatchScoreEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: reqeustGetAgentMatchScoreWithCriteria,
    method: getAgentMatchScore,
  });
  return sendRequest(cfg, options, cb);
};
