import React from 'react';

const DeleteIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.00146 6.99951H20.0015"
      stroke="#AAABAB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0015 11.0005V17.0005"
      stroke="#AAABAB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9985 11.0005V17.0005"
      stroke="#AAABAB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.99854 6.99951L5.99854 18.9995C5.99854 19.5299 6.20925 20.0387 6.58432 20.4137C6.95939 20.7888 7.4681 20.9995 7.99854 20.9995H15.9985C16.529 20.9995 17.0377 20.7888 17.4127 20.4137C17.7878 20.0387 17.9985 19.5299 17.9985 18.9995L18.9985 6.99951"
      stroke="#AAABAB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
      stroke="#AAABAB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DeleteIcon;
