import { FormMode, ResponseType } from 'types';
import { FORM_MODE } from 'app-constants';
import { PhoneNumber } from 'components';

import styles from './styles.module.scss';

interface PhoneNumberQuestionProps {
  mode: FormMode;
  formResponse?: ResponseType;
  handleFormResponse?: ({ Answer }: Partial<ResponseType>) => void;
}

export const PhoneNumberQuestion = ({
  mode,
  formResponse,
  handleFormResponse,
}: PhoneNumberQuestionProps) => {
  const editMode = mode === FORM_MODE.Edit;

  const handleUserInput = (value: string) => {
    if (formResponse?.UUID && handleFormResponse) handleFormResponse({ Answer: value });
  };

  return (
    <div className={styles.phoneInput}>
      <PhoneNumber
        disabled={editMode}
        defaultValue={formResponse?.Answer}
        onUpdate={handleUserInput}
        placeholder="Phone number"
        key={formResponse?.UUID}
        className={styles.responseInput}
      />
    </div>
  );
};
