export const getBaseSorter =
  (formatFn, getValueFn, order = 1) =>
  (a, b) => {
    const valueA = getValueFn ? getValueFn(a) : a;
    const valueB = getValueFn ? getValueFn(b) : b;

    const aFormatted = formatFn ? formatFn(valueA) : valueA;
    const bFormatted = formatFn ? formatFn(valueB) : valueB;

    if (aFormatted < bFormatted) return -1 * order;
    if (aFormatted > bFormatted) return 1 * order;
    return 0;
  };

const formatString = (val) => (typeof val === 'string' ? val.toLowerCase().trim() : val);

export const getStringsSorter = (getValueFn, order) =>
  getBaseSorter(formatString, getValueFn, order);
export const sortStrings = getStringsSorter();

export const sortStatus = getStringsSorter((item) => item?.value);
export const sortLocations = getStringsSorter((item) => item?.PlaceName);
