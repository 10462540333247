import classNames from 'classnames';
import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { StandardCheckoutForm } from './StandardCheckoutForm';
import StripeProvider from './StripeProvider/StripeProvider';
import { useSelector } from 'react-redux';
import { getActiveSubscriptionData, getUserDataSelector } from 'store/selectors/user';

type Props = {
  onNext: () => void;
};

export const StripeCheckout: React.FC<Props> = ({ onNext }) => {
  const user = useSelector(getUserDataSelector);
  const activeSubscriptionData = useSelector(getActiveSubscriptionData);

  const requestedSeatCount = useMemo(
    () => (user.SelectedPlan === 'Team' ? 3 : 2),
    [user.SelectedPlan],
  );

  if (activeSubscriptionData?.planLevel === 'premium') onNext();

  return (
    <StripeProvider>
      <div className={classNames(styles.container)}>
        <StandardCheckoutForm requestedSeatCount={requestedSeatCount} onNext={onNext} />
      </div>
    </StripeProvider>
  );
};
