import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ adminPanel }) => adminPanel;

const serviceDirectoryState = createSelector(
  localState,
  ({ serviceDirectory }) => serviceDirectory,
);

export const getFormsSelector = createSelector(serviceDirectoryState, ({ forms }) => forms);

export const getQuestionTypeListSelector = createSelector(
  getFormsSelector,
  ({ questions }) => questions,
);

export const getSelectedFormSelector = createSelector(
  getFormsSelector,
  ({ selectedForm }) => selectedForm,
);

export const getSelectedFormFieldSelector = createSelector(
  getFormsSelector,
  ({ selectedFormField }) => selectedFormField,
);
