import { createAction } from 'redux-actions';

export const requestGetVaultDocumentsAction = createAction('REQUEST/GET_VAULT_DOCUMENTS');

export const requestGetClientTransactionDocumentsAction = createAction(
  'REQUEST/GET_CLIENT_TRANSACTION_DOCUMENTS',
);

export const requestGetPreapprovalDocumentsAction = createAction(
  'REQUEST/GET_PREAPPROVAL_DOCUMENTS',
);

export const requestGetArchiveDocumentsAction = createAction('REQUEST/ARCHIVE_DOCUMENTS');

export const requestGetVaultDocumentsMetaAction = createAction('REQUEST/VAULT_DOCUMENTS_META');

export const requestUploadVaultDocumentAction = createAction('REQUEST/UPLOAD_VAULT_DOCUMENTS');

export const requestUpdateVaultDocumentAction = createAction('REQUEST/UPDATE_VAULT_DOCUMENTS');

export const deleteVaultDocumentAction = createAction('DELETE/VAULT_DOCUMENT');
