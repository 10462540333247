import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { get, pullAt } from 'lodash-es';

import { DeleteModal } from 'components-antd';
import Delete from 'pages/FormBuilder/Icons/Delete';
import {
  getSmartFormCurrentQuestionSelect,
  getSmartFormMetaSelect,
} from 'store/selectors/formBuilder';
import { updateSmartFormMetaEffect } from 'store/effects/formBuilder';

import styles from './styles.module.scss';

export const SmartDeleteQuestion = () => {
  const question = useSelector(getSmartFormCurrentQuestionSelect);
  const { activeQuestionIndex, questions, disassociatedFields } =
    useSelector(getSmartFormMetaSelect);

  const dispatch = useDispatch();

  const [showDelete, setShowDelete] = useState(false);

  const clearQuestionData = () => {
    if (activeQuestionIndex != undefined && questions?.length && questions[activeQuestionIndex]) {
      if (question?.FieldType) {
        const updatedFields = [...(disassociatedFields || []), ...get(question, 'Fields', [])];

        dispatch(
          updateSmartFormMetaEffect({
            disassociatedFields: updatedFields,
          }),
        );
      }

      const id = questions[activeQuestionIndex].UUID;
      const updatedQuestions = questions.map((el) => {
        const updatedQuestion = { ...el };
        if (updatedQuestion?.JumpLogic) {
          for (const key of Object.keys(updatedQuestion.JumpLogic)) {
            if (updatedQuestion.JumpLogic[key] === id) delete updatedQuestion.JumpLogic[key];
          }
          if (!Object.keys(updatedQuestion.JumpLogic).length) {
            updatedQuestion.JumpLogic = undefined;
          }
        }

        return updatedQuestion;
      });

      pullAt(updatedQuestions, activeQuestionIndex);

      return updatedQuestions;
    }
    return null;
  };

  const handleDeleteForm = () => {
    const updatedQuestions = clearQuestionData();
    if (updatedQuestions) {
      dispatch(
        updateSmartFormMetaEffect({
          questions: updatedQuestions,
          activeQuestionIndex: undefined,
        }),
      );
    }
  };

  return (
    <>
      {question && (
        <>
          <DeleteModal
            open={showDelete}
            entityName={question.Name}
            onOk={handleDeleteForm}
            onCancel={() => setShowDelete(false)}
            data-testid="smart-delete-modal"
          />
          <div
            data-testid="smart-delete-question"
            onClick={() => setShowDelete(true)}
            className={classNames('show-cursor ', 'prevent-user-select', styles.questionContainer)}
          >
            <Delete className={styles.questionIcon} />
            <span className={styles.questionText}>Remove Question</span>
          </div>
        </>
      )}
    </>
  );
};
