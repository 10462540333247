import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Modal, Button } from 'components-antd';
import { Icons } from '../../Icons';
import { routes } from 'settings/navigation/routes';
import { setPreApprovalModalAction } from 'store/actions/mySearches';
import { isPreApprovalModalOpenSelector } from 'store/selectors/mySearches';
import { getClientContextSelector } from 'store/selectors/agents';
import { getSelectedContextIdSelector } from 'store/selectors/context';
import { signUpRouteRoles } from 'settings/constants/roles';
import { UploadPreApprovalModal } from '../UploadPreApprovalModal/UploadPreApprovalModal';
import { PreApprovalDocuments } from '../PreApprovalDocuments/PreApprovalDocuments';
import { PreApprovalRequests } from '../PreApprovalRequests/PreApprovalRequests';

import styles from './styles.module.scss';

export const PreApprovalsModal = () => {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isModalOpen = useSelector(isPreApprovalModalOpenSelector);
  const { data: agents } = useSelector(getClientContextSelector);
  const agentId = useSelector(getSelectedContextIdSelector);
  const agentUser = agents?.filter((a) => a.Id === agentId)?.[0]?.User;

  const closeModal = () => {
    dispatch(setPreApprovalModalAction({ isModalOpen: false }));
  };

  const onOpenUploadModal = () => {
    setOpenUploadModal(true);
    dispatch(setPreApprovalModalAction({ isModalOpen: false }));
  };

  const onCloseUploadModal = () => {
    setOpenUploadModal(false);
    dispatch(setPreApprovalModalAction({ isModalOpen: true }));
  };

  const onRequest = () => {
    history.push(routes.preApprovalWizard.replace(':role', signUpRouteRoles.CLIENT));
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        width={675}
        footer={null}
        onCancel={closeModal}
        className={styles.preApprovalsModal}
        cancelButtonStyles={styles.crossButton}
        destroyOnClose={true}
      >
        <h2>Pre-Approval(s)</h2>
        {agentUser ? (
          <h4>
            Agent: {agentUser.FirstName} {agentUser.LastName}
          </h4>
        ) : null}

        <div className={styles.myUploads}>
          <div className={styles.container}>
            <h4>My Uploads</h4>
            <Button variant="secondary" className={styles.uploadBtn} onClick={onOpenUploadModal}>
              <Icons className={styles.uploadIcon} variant={Icons.UPLOAD} />
              Upload
            </Button>
          </div>
          <PreApprovalDocuments />
        </div>

        <div className={styles.container}>
          <h4>My Requests</h4>
          <Button variant="secondary" className={styles.requestBtn} onClick={onRequest}>
            Request
          </Button>
        </div>
        <PreApprovalRequests />
      </Modal>
      <UploadPreApprovalModal isOpen={openUploadModal} onClose={onCloseUploadModal} />
    </>
  );
};
