import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import styles from './style.module.scss';
import { InfoTooltip } from '../../InfoTooltip';
import { Dropdown, Menu, MenuItem, Popover, Radio } from 'components-antd';
import { AddForm } from '../../FormFilesList/AddForm';
import { useState } from 'react';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import classNames from 'classnames';

export const FileNameInfo = (props) => {
  const { documentName } = props;
  const [openList, setOpenList] = useState(false);

  const menu = (
    <Menu>
      <MenuItem>
        <AddForm className={styles.addForm} />
      </MenuItem>
    </Menu>
  );

  const renderFileName = () => {
    return (
      <div className={styles.fileNameWrap}>
        <Icons variant="File" />

        {documentName && documentName?.length > 50 ? (
          <InfoTooltip text={documentName} className={styles.documentNameTooltip}>
            <p className={styles.documentName}>{documentName}</p>
          </InfoTooltip>
        ) : (
          <p className={styles.documentName}>{documentName}</p>
        )}
      </div>
    );
  };

  if (!dynamicManager.canAddNewForm())
    return <div className={styles.fileNameWrapContainer}>{renderFileName()}</div>;

  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger={['click']}
      onOpenChange={(visible) => setOpenList(visible)}
      overlayClassName={styles.fileDropdown}
    >
      <div className={classNames(styles.fileNameWrapContainer, styles.addNewFileWrap)}>
        {renderFileName()}
        <div className={styles.arrowIconWrap}>
          {openList ? (
            <Icons variant={'UpArrow'} className={styles.icon} />
          ) : (
            <Icons variant={'DownArrow'} className={styles.icon} />
          )}
        </div>
      </div>
    </Dropdown>
  );
};
