import classNames from 'classnames';
import { Checkbox, InputNumber } from 'antd';

import styles from '../styles.module.scss';

export const Breakpoint2 = (props) => {
  const {
    state,
    onChange,
    contingenciesRef,
    currentBreakpoint,
    appraisalError,
    financingError,
    inspectionError,
    setAppraisalError,
    setFinancingError,
    setInspectionError,
  } = props;

  const renderContingenciesField = (fieldProps) => {
    const { name, checked, onChange, value, label, error, setError } = fieldProps;
    return (
      <Checkbox
        className={classNames(styles.checkbox, 'mosaikCheckbox')}
        checked={checked}
        onChange={(e) => {
          setError(false);
          onChange({
            ...state,
            OfferIteration: {
              ...state.OfferIteration,
              Contingencies: {
                ...state.OfferIteration.Contingencies,
                [name]: { selected: e.target.checked },
              },
            },
          });
        }}
      >
        <span className={styles.static}>{label}</span>
        {!state?.OfferIteration?.Contingencies?.[name]?.selected ? (
          <div className={styles.waived}>Waived</div>
        ) : (
          <>
            <InputNumber
              className={classNames(styles.daysInputHolder, {
                [styles.daysInputHolderError]: error,
              })}
              min={1}
              placeholder="#"
              onChange={(e) => {
                setError(false);
                onChange({
                  ...state,
                  OfferIteration: {
                    ...state.OfferIteration,
                    Contingencies: {
                      ...state.OfferIteration.Contingencies,
                      [name]: { days: e, selected: true },
                    },
                  },
                });
              }}
              value={value}
            />
            <span className={styles.static}>days</span>
          </>
        )}
      </Checkbox>
    );
  };

  return (
    <div className={styles.breakpoint2}>
      <div className={styles.inputBlock}>
        <div className={styles.label}>Contingencies</div>

        <div className={styles.contingencies} ref={contingenciesRef}>
          {renderContingenciesField({
            name: 'Inspection',
            checked: state?.OfferIteration?.Contingencies?.Inspection?.selected,
            onChange,
            value: state?.OfferIteration?.Contingencies?.Inspection?.days,
            label: 'Inspection',
            error: inspectionError,
            setError: setInspectionError,
          })}
        </div>

        <div className={styles.contingencies} ref={contingenciesRef}>
          {renderContingenciesField({
            name: 'Appraisal',
            checked: state?.OfferIteration?.Contingencies?.Appraisal?.selected,
            onChange,
            value: state?.OfferIteration?.Contingencies?.Appraisal?.days,
            label: 'Appraisal',
            error: appraisalError,
            setError: setAppraisalError,
          })}
        </div>

        <div className={styles.contingencies} ref={contingenciesRef}>
          {renderContingenciesField({
            name: 'Financing',
            checked: state?.OfferIteration?.Contingencies?.Financing?.selected,
            onChange,
            value: state?.OfferIteration?.Contingencies?.Financing?.days,
            label: 'Financing',
            error: financingError,
            setError: setFinancingError,
          })}
        </div>
      </div>

      {currentBreakpoint === 3 ? <div className={styles.fieldSeparator} /> : null}
    </div>
  );
};
