import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { Label, Description, Items } from '../components';

import styles from './styles.module.scss';

const Wants = (props) => {
  const { listItems, className, onChangePref } = props;

  if (!listItems || !listItems.length) return null;

  return (
    <div testid="wants_block" className={classNames(styles.wantsWrapper, className)}>
      <Label>Wants</Label>
      <Description>Features that you would prefer to have, but are not required.</Description>
      <Items
        list={listItems}
        onChangePref={onChangePref}
        currentImportance={SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT}
      />
    </div>
  );
};

Wants.propTypes = {
  className: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string),
  onChangePref: PropTypes.func.isRequired,
};

Wants.defaultProps = {
  className: '',
  listItems: undefined,
};

export default Wants;
