import { useEffect, useState, useCallback } from 'react';
import { showErrorMessage } from 'helpers/errors';

const useRequest = ({ request, params, format, autoSend = true, showError = true }) => {
  const [requestParams, setRequestParams] = useState(params);
  const [{ loading, error, data }, setRequestState] = useState({
    loading: false,
  });

  const sendRequest = useCallback(() => {
    (async () => {
      try {
        setRequestState((values) => ({
          ...values,
          loading: true,
        }));

        const res = await request(requestParams);
        setRequestState({
          loading: false,
          error: null,
          data: format ? format(res?.data?.result) : res?.data?.result,
        });
      } catch (err) {
        if (showError) {
          showErrorMessage(err);
        }
        setRequestState((values) => ({
          ...values,
          loading: false,
          error: err,
        }));
      }
    })();
  }, [request, requestParams, showError, format]);

  useEffect(() => {
    if (autoSend) {
      sendRequest();
    }
  }, [autoSend, sendRequest]);

  return [{ loading, error, data }, setRequestParams, sendRequest];
};

export default useRequest;
