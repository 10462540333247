import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { transactionStatuses } from 'settings/constants/transaction';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { getTeamMemberTransactions } from 'api/teamList';
import { ProfileIcons } from '../../Icons';
import { getSimpleDate } from 'helpers';
import { setProfileExpandedSection } from 'store/effects/app';
import { ArrowUp } from 'components/Icons';
import { LocationService } from 'services';
import { routes } from 'settings/navigation/routes';
import { getClientNameStringAndCount } from 'utils';

import styles from './styles.module.scss';

const locationSrv = new LocationService();

const initState = {
  Listed: [],
  UnderContract: [],
  ClearToClose: [],
  Closed: [],
};

export const Transactions = () => {
  const [transactionList, setTransactionsList]: any = useState(initState);
  const [collapse, setCollapse]: any = useState({
    Listed: true,
    UnderContract: true,
    ClearToClose: true,
    Closed: true,
  });
  const details = useSelector(getAgentDetailsSelector);
  const contact = details?.data || {};
  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();
  locationSrv.setLocation(location);

  useEffect(() => {
    const query = locationSrv.getQuery();
    if (query?.type) {
      setCollapse({
        ...collapse,
        [query.type]: false,
      });
      history.replace(routes.symphony);
    }
  }, [location, details]);

  const fetchTeamMemberTransactions = async () => {
    const transactions = await getTeamMemberTransactions(null, { agentId: contact?.Id });
    setTransactionsList(transactions?.data || initState);
  };

  useEffect(() => {
    fetchTeamMemberTransactions();
  }, []);

  const redirectTo = (id) => {
    dispatch(setProfileExpandedSection(false));
    history.push(`/workshop/transactions/${id}/overview`);
  };

  const renderTransactions = (status) => (
    <>
      {transactionList[status]?.map((transaction, index) => (
        <div className={styles.transaction} key={index}>
          <div className={styles.details}>
            <div className={styles.icon}>
              <ProfileIcons name={ProfileIcons.TRANSACTIONS} color="#515151" />
            </div>
            <div className={styles.titleClients} onClick={() => redirectTo(transaction.Id)}>
              <h4>{transaction.Property?.Address?.Line1}</h4>
              <p>
                Client
                {getClientNameStringAndCount(transaction?.Clients, transaction?.TransactionClients)
                  ?.totalClients > 1
                  ? 's'
                  : ''}
                :{' '}
                {getClientNameStringAndCount(transaction?.Clients, transaction?.TransactionClients)
                  ?.clients || 'N/A'}
              </p>
            </div>
          </div>
          <p className={styles.closing}>
            Closing:{' '}
            {transaction?.UnderContractCloseDate
              ? getSimpleDate(transaction.UnderContractCloseDate, 'M/D')
              : 'N/A'}
          </p>
        </div>
      ))}
    </>
  );

  const renderTitle = (title) => {
    if (title === 'UnderContract') {
      return transactionStatuses.UnderContract;
    } else if (title === 'ClearToClose') {
      return transactionStatuses.ClearToClose;
    } else return title;
  };

  const renderByStatus = (status, className?) => (
    <>
      <div
        className={classNames(styles.transactionStatus, className)}
        onClick={() =>
          setCollapse({
            ...collapse,
            [status]: !collapse[status],
          })
        }
      >
        <h4>
          {renderTitle(status)} <span>({transactionList[status]?.length || 0})</span>
        </h4>
        <ArrowUp className={classNames(styles.arrowIcon, { [styles.rotate]: collapse[status] })} />
      </div>
      {!collapse[status] ? renderTransactions(status) : null}
    </>
  );

  return (
    <div className={styles.transactions}>
      {renderByStatus('Listed', styles.firstChild)}
      {renderByStatus('UnderContract')}
      {renderByStatus('ClearToClose')}
      {renderByStatus('Closed')}
    </div>
  );
};

Transactions.propTypes = {};

Transactions.defaultProps = {};
