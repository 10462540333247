import { useState, useEffect, useCallback } from 'react';
import { AgentInvitationModal } from '../Modal/Variants/AgentInvitationModal';
import { useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { upperCase } from 'lodash-es';
import { getPendingInvites, updateInviteStatus } from 'api/invites';

export const AgentInvitation = () => {
  const [invites, setInvites] = useState<any[]>([]);
  const { isClient } = useSelector(getUserRolesMapSelector);

  const onSubmit = async (contextId, status) => {
    await updateInviteStatus({ contextId, status });
    const remaining = invites.filter((x) => x.id !== contextId);
    setInvites(remaining);
  };

  const fetchInvites = useCallback(async () => {
    const { data } = await getPendingInvites();
    setInvites(data.result);
  }, []);

  useEffect(() => {
    if (isClient) fetchInvites();
  }, [isClient]);

  const renderInviteModal = () => {
    if (!invites?.length) return;

    return (
      <>
        {invites.map((item, index) => {
          const avatar = item.avatarUrl;
          const name = `${item.firstName} ${item.lastName}`;
          const placeholder = upperCase(`${item.firstName?.[0] || ''}${item.lastName?.[0] || ''}`);

          return (
            <AgentInvitationModal
              key={index}
              open={true}
              avatarProps={{ avatar, name, placeholder, minimizeAvatar: true, size: 56 }}
              agentId={item.id}
              agentName={name}
              searchInstance={item.searchInstance}
              transactionInstance={item.transactionInstance}
              footer={null}
              onSubmit={onSubmit}
            />
          );
        })}
      </>
    );
  };

  return <>{isClient ? renderInviteModal() : null}</>;
};
