import { Row, Col } from 'components-antd';
import { CommissionField } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/FinancingDetails/components';
import { SplitTypes } from '../../../../TransactionFinancials';

import styles from './styles.module.scss';

export const EditSplitItem = ({ item, updateSplitValue, isTransactionAdminOrOwner }) => {
  const splitType = item?.SplitType?.SplitType;

  if (
    (!isTransactionAdminOrOwner &&
      (splitType === SplitTypes?.TEAM || splitType === SplitTypes?.BROKERAGE)) ||
    splitType === SplitTypes?.CLIENT
  )
    return null;
  return (
    <Row gutter={12}>
      <Col lg={24}>
        <p className={styles.labelSplit}>{item?.SplitType?.SplitTitle}</p>
      </Col>
      <Col lg={24}>
        <CommissionField
          IsFeeTypePercentage={item?.IsSplitTypePercentage}
          update={(object) => {
            const updatedObject = {
              SplitValue: object?.Value === '' || null ? null : parseFloat(object?.Value),
              IsSplitTypePercentage: object?.IsFeeTypePercentage,
            };
            updateSplitValue(item.Id, updatedObject);
          }}
          label=""
          value={item?.IsSplitTypePercentage ? item?.SplitPercentage || '' : item?.SplitValue || ''}
        />
      </Col>
    </Row>
  );
};
