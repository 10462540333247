import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { Space } from 'components-antd';
import { Input, SingleLocation } from 'components';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';

type AddressType = {
  Line1: string;
  Line2: string;
  City: string;
  State: string;
  Zip: string;
  ProviderPlaceId: string;
  PlaceName: string;
};

type LocationDataType = Omit<AddressType, 'Line1' | 'Line2' | 'Zip'> & { Zipcode: string };

export const ManualAddressInput = () => {
  const dispatch = useDispatch();

  const [address, setAddress] = useState<AddressType>({
    Line1: '',
    Line2: '',
    City: '',
    State: '',
    Zip: '',
    ProviderPlaceId: '',
    PlaceName: '',
  });

  const onChangeAddressLine1 = (line1: string): void => {
    setAddress((address) => ({ ...address, Line1: line1 || '' }));
  };

  const onChangeAddressLine2 = (line2: string): void => {
    setAddress((address) => ({ ...address, Line2: line2 || '' }));
  };

  const onChangeAddress = (locationData: LocationDataType): void => {
    setAddress((address) => ({
      ...address,
      City: locationData.City || '',
      State: locationData.State || '',
      Zip: locationData.Zipcode || '',
      ProviderPlaceId: locationData.ProviderPlaceId || '',
      PlaceName: locationData.PlaceName || '',
    }));
  };

  const onClearAddress = (): void => {
    setAddress((address) => ({
      ...address,
      City: '',
      State: '',
      Zip: '',
      ProviderPlaceId: '',
      PlaceName: '',
    }));
  };

  useEffect(() => {
    if (address.Line1 && address.City && address.State) {
      dispatch(
        setTransactionPreFormQuestionsEffect({
          [transactionPreFormQuestionsIds.address]: {
            ...address,
            PlaceName: `${address.Line1}, ${address.PlaceName}`,
          },
        }),
      );
    } else {
      dispatch(
        setTransactionPreFormQuestionsEffect({
          [transactionPreFormQuestionsIds.address]: null,
        }),
      );
    }
  }, [address]);

  const isAllowedManualInput = (key) => {
    const fieldsToCheck = ['City', 'State', 'Zip'].filter((item) => item !== key);

    return address?.[fieldsToCheck?.[0]] != '' && address?.[fieldsToCheck?.[0]] != '';
  };

  const onChangeManualAddress = (key, value) => {
    value === '' ? onClearAddress() : setAddress({ ...address, [key]: value });
  };

  return (
    <div className={styles.manualAddressContainer}>
      <div className={styles.addressLineWrapper}>
        <Input
          value={address?.Line1}
          onChange={(e) => onChangeAddressLine1(e.target.value)}
          variant={Input.LIGHT_ROUNDED}
          label="Address Line 1"
          className={styles.inputSpacing}
        />
        <Input
          value={address?.Line2}
          onChange={(e) => onChangeAddressLine2(e.target.value)}
          variant={Input.LIGHT_ROUNDED}
          label="Address Line 2"
          className={styles.inputSpacing}
        />
      </div>
      <SingleLocation
        placeholder=" "
        inputLabel="City"
        types={['(regions)']}
        variant={Input.LIGHT_ROUND}
        onResult={(_value, preparedData) => onChangeAddress(preparedData[0])}
        value={{ PlaceName: address?.City }}
        onClear={onClearAddress}
        className={styles.searchWrapperExternal}
        searchIcon={false}
        allowedManualInput={isAllowedManualInput('City')}
        onChange={(value) => onChangeManualAddress('City', value)}
      />
      <Space size="large" direction="horizontal" className={styles.autoCompleteInputs}>
        <SingleLocation
          placeholder=" "
          inputLabel="State"
          allowStates={true}
          types={['(regions)']}
          variant={Input.LIGHT_ROUND}
          onResult={(_value, preparedData) => onChangeAddress(preparedData[0])}
          value={{ PlaceName: address?.State }}
          onClear={onClearAddress}
          className={styles.searchWrapperExternal}
          searchIcon={false}
          allowedManualInput={isAllowedManualInput('State')}
          onChange={(value) => onChangeManualAddress('State', value)}
        />
        <SingleLocation
          placeholder=" "
          inputLabel="ZIP"
          types={['(regions)']}
          variant={Input.LIGHT_ROUND}
          onResult={(_value, preparedData) => onChangeAddress(preparedData[0])}
          value={{ PlaceName: address?.Zip }}
          onClear={onClearAddress}
          className={styles.searchWrapperExternal}
          searchIcon={false}
          allowedManualInput={isAllowedManualInput('Zip')}
          onChange={(value) => onChangeManualAddress('Zip', value)}
        />
      </Space>
    </div>
  );
};
