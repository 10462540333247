import { routes } from 'settings/navigation/routes';
import { Templates } from 'pages';
import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';

export default [
  {
    path: routes.templates,
    component: Templates,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.templatesTasks,
    component: Templates,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.templatesTimelines,
    component: Templates,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.templatesTransactions,
    component: Templates,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.templatesTransactionCreate,
    component: Templates,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.templatesTransactionView,
    component: Templates,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.templatesTransactionEdit,
    component: Templates,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.templatesDetails,
    component: Templates,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.templatesProjects,
    component: Templates,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.templatesProjectCreate,
    component: Templates,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.templatesProjectView,
    component: Templates,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.templatesProjectEdit,
    component: Templates,
    exact: true,
    roles: [AGENT],
  },
];
