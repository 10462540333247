import { useEffect, useState } from 'react';
import { useScreen } from 'hooks';

const useWrapperSize = ({ wrapperRef, list }) => {
  const { screen } = useScreen();
  const [wrapperSize, setWrapperSize] = useState({});

  useEffect(() => {
    if (wrapperRef) {
      setTimeout(() => {
        const { width, height } = wrapperRef?.current?.getBoundingClientRect() || {};
        if (height !== wrapperSize.height || width !== wrapperSize.width) {
          setWrapperSize({ width, height });
        }
      }, 50);
    }
  }, [
    screen.width,
    screen.height,
    wrapperSize?.width,
    wrapperSize?.height,
    list?.length,
    wrapperRef,
  ]);

  return { wrapperSize };
};

export default useWrapperSize;
