import feed from './feed';
import search from './search';
import mySearches from './mySearches';
import clientSearches from './clientSearches';
import clientTours from './tours';

export default {
  properties: '/properties',
  ...feed,
  ...search,
  ...mySearches,
  ...clientSearches,
  ...clientTours,
};
