import styles from './styles.module.scss';
import { PhotoCompareSectionDataType } from '../../DataTypes';
import classNames from 'classnames';

type PhotosSectionPropsType = {
  photoData: PhotoCompareSectionDataType[];
  showTopLabels: boolean;
};

function PhotosSection(props: PhotosSectionPropsType) {
  let countClass = '';
  switch (props.photoData.length) {
    case 2:
      countClass = 'Two';
      break;
    case 3:
      countClass = 'Three';
      break;
    case 4:
      countClass = 'Four';
      break;
    case 5:
      countClass = 'Five';
      break;
    default:
      countClass = '';
  }

  return (
    <div className={classNames(styles.photoSection, [styles['container' + countClass]])}>
      <table>
        <tr>
          <td className={styles.cell}></td>
        </tr>
      </table>
      {props.photoData.map((outerItem) => (
        <div className={styles.photoSectionCol}>
          {outerItem.photoData.map((innerItem) => (
            <div className={styles.photoSectionColBox}>
              {innerItem.url === 'empty' ? (
                <div className={classNames(styles.photo, styles['photo' + countClass])}></div>
              ) : (
                <>
                  <img
                    className={classNames(styles.photo, styles['photo' + countClass])}
                    src={innerItem.url}
                    alt="Photo"
                  />
                  {props.showTopLabels && (
                    <>
                      {innerItem.labels.map((la) => (
                        <label key={la}>{`${la}`}</label>
                      ))}
                      <hr />
                      {innerItem.topLabels.map((la) => (
                        <label key={la}>{`${la}`}</label>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default PhotosSection;
