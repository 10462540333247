import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Checkbox, InputLabel } from 'components';

import styles from './styles.module.scss';

const RadioOptions = ({
  className,
  options,
  name,
  onChange,
  titleLabel,
  disabled,
  testid,
  error,
  labelTextClassName,
  optionClassName,
  checkedClassName,
  value,
  direction,
  isPending,
  variant,
}) => {
  const [optionValue, setOptionValue] = useState(value);

  useEffect(() => setOptionValue(value), [value]);

  return (
    <>
      <div
        testid="wrapper"
        className={classNames(styles.wrapper, { [styles.disabled]: disabled }, className)}
      >
        <InputLabel label={titleLabel} />
        {options.map(({ id, value: optValue, label }) => (
          <Checkbox
            key={id}
            type="radio"
            name={name}
            label={label}
            direction={direction}
            className={classNames(styles.option, optionClassName, {
              [styles[variant]]: !!variant,
              [styles.checked]: optionValue === id,
              [checkedClassName]: optionValue === id,
            })}
            labelClassName={styles.label}
            labelTextClassName={classNames(styles.labelText, labelTextClassName)}
            onChange={() => onChange(optValue)}
            checkboxClassName={classNames({ [styles.checkbox]: optionValue !== id })}
            checked={optionValue === id}
            value={optValue}
            testid={testid}
            isPending={isPending && value === id}
            hasOuterClick={true}
            checkboxColor="#fff"
          />
        ))}
      </div>
      {/* {error && !disabled && (
        <div testid="validation" className={styles.error}>
          {error}
        </div>
      )} */}
    </>
  );
};

RadioOptions.LIGHT = 'light';
RadioOptions.FULL = 'full';

RadioOptions.propTypes = {
  className: PropTypes.string,
  labelTextClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
    }),
  ).isRequired,
  checkedClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func,
  titleLabel: PropTypes.string,
  disabled: PropTypes.bool,
  testid: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  direction: PropTypes.string,
  isPending: PropTypes.bool,
  variant: PropTypes.string,
};

RadioOptions.defaultProps = {
  className: '',
  labelTextClassName: '',
  optionClassName: '',
  checkedClassName: '',
  error: '',
  onChange: () => {},
  titleLabel: '',
  disabled: false,
  value: null,
  testid: null,
  direction: Checkbox.DIRECTION_RIGHT,
  isPending: false,
  variant: RadioOptions.LIGHT,
};

export default RadioOptions;
