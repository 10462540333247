import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from './styles.module.scss';
import { formatUsd } from 'helpers';

const Price = (props) => {
  const { propertyInfo, className } = props;

  return (
    <div className={classNames(styles.price, className)}>
      {Number(propertyInfo?.SellingPrice) > 0 ? (
        <NumberFormat
          thousandSeparator
          displayType="text"
          value={formatUsd(propertyInfo?.SellingPrice || 0, { maximumFractionDigits: 0 })}
          prefix="$"
          renderText={(val) => (
            <span testid="property_price" className={styles.value}>
              {val}
              {propertyInfo?.IsAuction && <span className={styles.auction}>(auction)</span>}
            </span>
          )}
        />
      ) : (
        <div className={styles.value}>Price Unavailable</div>
      )}
    </div>
  );
};

Price.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    SellingPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    IsAuction: PropTypes.bool,
  }).isRequired,
};

Price.defaultProps = {
  className: '',
};

export default Price;
