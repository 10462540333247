import PropTypes from 'prop-types';
import React from 'react';

export const Icons = ({ testid, className, variant, onClick, color }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.ADD: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 2.5V13.5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.5 8H13.5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ARCHIVE: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.65811 3.69216L2.05132 8.51255C2.01733 8.61452 2 8.7213 2 8.82878V11.8332C2 12.6616 2.67157 13.3332 3.5 13.3332H12.5C13.3284 13.3332 14 12.6616 14 11.8332V8.82878C14 8.7213 13.9827 8.61452 13.9487 8.51255L12.3419 3.69216C12.1377 3.07965 11.5645 2.6665 10.9189 2.6665H5.08114C4.43549 2.6665 3.86228 3.07965 3.65811 3.69216Z"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33203 6.6665H5.66536V6.99984C5.66536 8.2885 6.71003 9.33317 7.9987 9.33317V9.33317C9.28736 9.33317 10.332 8.2885 10.332 6.99984V6.6665H12.6654"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.PROFILE: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 5C11 6.65685 9.65685 8 8 8C6.34315 8 5 6.65685 5 5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5Z"
              stroke={color}
              strokeWidth="2"
            />
            <path
              d="M3 13.9985C3.5 12 5 11 7 11L9 11C11 11 12.5 12 13 13.9985"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ATTACHMENT: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_9662_70538)">
              <path
                d="M10.1827 4.36388L5.45539 9.09115C5.16607 9.38048 5.00353 9.77289 5.00353 10.1821C5.00353 10.5912 5.16607 10.9836 5.45539 11.273C5.74472 11.5623 6.13713 11.7248 6.5463 11.7248C6.95547 11.7248 7.34789 11.5623 7.63721 11.273L12.3645 6.5457C12.9431 5.96704 13.2682 5.18222 13.2682 4.36388C13.2682 3.54554 12.9431 2.76071 12.3645 2.18206C11.7858 1.6034 11.001 1.27832 10.1827 1.27832C9.36433 1.27832 8.5795 1.6034 8.00085 2.18206L3.27358 6.90933C2.4056 7.77731 1.91797 8.95455 1.91797 10.1821C1.91797 11.4096 2.4056 12.5868 3.27358 13.4548C4.14156 14.3228 5.31879 14.8104 6.5463 14.8104C7.77381 14.8104 8.95105 14.3228 9.81903 13.4548L14.5463 8.72751"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_9662_70538">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.ACTIVITY: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 5.3335V8.00016L9.33333 9.3335"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.03516 7.33338C2.18455 5.86675 2.86867 4.50645 3.95698 3.51202C5.04529 2.51759 6.46162 1.95864 7.93574 1.9418C9.40986 1.92496 10.8386 2.45142 11.9493 3.42074C13.0601 4.39005 13.7751 5.73436 13.9579 7.1972C14.1408 8.66003 13.7787 10.139 12.9407 11.3519C12.1028 12.5648 10.8476 13.4267 9.41468 13.7733C7.98177 14.1198 6.47143 13.9267 5.17182 13.2308C3.87221 12.5348 2.8743 11.3848 2.36849 10M2.03516 13.3334V10H5.36849"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className}>
      {renderIcon()}
    </div>
  );
};

Icons.ADD = 'add';
Icons.ARCHIVE = 'archive';
Icons.PROFILE = 'profile';
Icons.ATTACHMENT = 'attachment';
Icons.ACTIVITY = 'activity';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  color: '',
  testid: undefined,
  onClick: () => {},
};
