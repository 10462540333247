import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { debounce } from 'lodash-es';

import { Spinner } from 'components';
import { Input } from 'components-antd';
import { Cross } from 'components/Icons';
import SearchGlass from 'components/Icons/SearchGlass';

import styles from './styles.module.scss';

const SearchFieldWithDebounce = (props) => {
  const {
    className,
    classNameInput,
    iconClassName,
    placeholder,
    isLoading,
    resetAction,
    sendAction,
    value: initValue,
    isTransactionAggregate,
    isExpandedVersion = false,
    doNotDispatch = false,
    otherSearchParams = {},
  } = props;
  const { resetByUnmount } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(initValue || '');
  const [expanded, setExpanded] = useState(isExpandedVersion);

  useEffect(() => setValue(initValue || ''), [initValue]);

  useEffect(
    () => () => {
      if (typeof resetAction === 'function' && resetByUnmount) {
        doNotDispatch ? resetAction() : dispatch(resetAction());
      }
    },
    [],
  );

  const onSend = useCallback(
    debounce(
      (search) => (doNotDispatch ? sendAction(search) : dispatch(sendAction({ search }))),
      300,
    ),
    [dispatch, otherSearchParams],
  );

  const onClear = useCallback(() => {
    setValue('');
    if (!isExpandedVersion) setExpanded(false);

    if (typeof resetAction === 'function') {
      doNotDispatch ? resetAction() : dispatch(resetAction());
    }
  }, [dispatch, resetAction, otherSearchParams]);

  const onChange = useCallback(
    ({ target }) => {
      const { value: searchVal } = target;
      const trimmedValue = searchVal?.trim();

      if (trimmedValue !== '') {
        setValue(searchVal);
        onSend(trimmedValue);
      } else {
        onClear();
        onSend(trimmedValue);
      }
    },
    [onSend, onClear],
  );

  const isShowCross = () => {
    if (!isExpandedVersion) return expanded;
    if (isExpandedVersion) return value.length > 0;
  };

  return (
    <div
      className={classNames(
        styles.wrapper,
        isTransactionAggregate && expanded ? styles.expanded : '',
        className,
      )}
    >
      <div
        className={classNames(styles.searchIcon, iconClassName)}
        onClick={() => setExpanded(true)}
      >
        <SearchGlass color="#747475" />
      </div>
      {
        <Input
          testid="search"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={classNames(
            styles.input,
            // isTransactionAggregate && expanded ? styles.inputExpanded : '',
            styles.inputExpanded,
            classNameInput,
          )}
          autoFocus
        />
      }
      {isShowCross() ? (
        <button onClick={onClear} className={styles.clearInput}>
          <Cross color="#515151" className={styles.closeIcon} />
        </button>
      ) : (
        <></>
      )}
      {isLoading && <Spinner className={styles.spinner} loaderClassName={styles.spinnerLoader} />}
    </div>
  );
};

SearchFieldWithDebounce.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  classNameInput: PropTypes.string,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  resetAction: PropTypes.func,
  sendAction: PropTypes.func.isRequired,
  value: PropTypes.string,
  resetByUnmount: PropTypes.bool,
  isTransactionAggregate: PropTypes.bool,
  isExpandedVersion: PropTypes.bool,
  testid: PropTypes.string,
  otherSearchParams: PropTypes.any,
};

SearchFieldWithDebounce.defaultProps = {
  className: null,
  classNameInput: null,
  placeholder: 'Search messages',
  resetAction: undefined,
  isLoading: false,
  value: '',
  resetByUnmount: true,
  doNotDispatch: false,
  otherSearchParams: {},
};

export default SearchFieldWithDebounce;
