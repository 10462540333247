import { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash-es';

import { DetailsContent } from 'pages/Templates/Details/components/DetailsContent/DetailsContent';
import { Tabs, Form, useForm } from 'components-antd';
import {
  TransactionTabsFooter,
  DetailPane,
  TimelinePane,
  TasksPane,
  FormsPane,
  RulesPane,
} from './components';
import { FieldsData, TransactionTemplate } from 'types';
import { extractTemplateData } from './components/helper';
import {
  postTimelineTemplateEffect,
  postTransactionTemplateEffect,
  putTimelineTemplateEffect,
  putTransactionTemplateEffect,
} from 'store/effects/template';
import { showSuccessMessage, showErrorMessage } from 'helpers';
import { getUserRoleSelector } from 'store/selectors/user';
import { SUPER_USER } from 'settings/constants/roles';

import styles from './styles.module.scss';
import { DetailsCardContent } from './components/DetailsCard/DetailsCardContent';

interface TemplateTabsProps {
  handleGoBack?: (transactionToRedirectToId?: number) => void;
  handleCancel?: () => void;
  transactionTemplate?: TransactionTemplate;
  isViewMode: boolean;
  teamId?: number;
}

enum TransactionTemplatePanes {
  Detail = 'Overview',
  Timeline = 'Timeline',
  Tasks = 'Tasks',
  Forms = 'Forms',
  Rules = 'Rules',
  Details = 'Details',
}

export const TemplateTabs = ({
  handleGoBack,
  handleCancel,
  transactionTemplate,
  isViewMode,
  teamId,
}: TemplateTabsProps) => {
  const [tabKey, setTabKey] = useState<string>(TransactionTemplatePanes.Detail);
  const [errorPanes, setErrorPanes] = useState<TransactionTemplatePanes[]>([]);
  const [locations, setLocations] = useState(transactionTemplate?.Locations || []);
  const [saveDetailsTemplate, setSaveDetailsTemplate] = useState<boolean>(false);
  const [detailsTemplateFields, setDetailsTemplateFields] = useState<FieldsData[]>([]);
  const userRole = useSelector(getUserRoleSelector);

  const isProject = location.pathname.includes('project');

  const [form] = useForm<TransactionTemplate>();

  const dispatch = useDispatch();

  useEffect(() => {
    form.setFields(extractTemplateData(transactionTemplate));
    if (teamId) form.setFieldValue('Teams', teamId);
    setDetailsTemplateFields(transactionTemplate?.DetailFields || []);
  }, []);

  const hasErrors = () => {
    return form.getFieldsError().some((item) => item.errors.length > 0);
  };

  const getTemplateData = () => {
    return {
      ...(transactionTemplate
        ? { Id: transactionTemplate.Id, UpdatedDate: transactionTemplate.UpdatedDate }
        : {}),
      ...form.getFieldsValue(true),
      Locations: (locations as any[])
        ?.filter((el) => el.areasOfOperation?.length)
        ?.map((areas) => {
          const { miles, areasOfOperation, UUID } = areas;
          return areasOfOperation.map((area) => ({ ...area, Radius: miles }));
        })
        .flat(),
      DetailFields: detailsTemplateFields,
    };
  };

  const handleSave = () => {
    if (!hasErrors()) {
      setSaveDetailsTemplate(true);
      if (transactionTemplate?.Id) {
        updateTransactionTemplate();
      } else {
        createTransactionTemplate();
      }
    }
  };

  const handleExit = (transactionToRedirectToId?: number) => {
    if (handleGoBack) {
      form.resetFields();
      handleGoBack(transactionToRedirectToId);
    }
  };

  const createTransactionTemplate = () => {
    const data = getTemplateData();

    if (!data?.Id) {
      dispatch(
        postTransactionTemplateEffect({ ...data, IsProject: isProject }, {}, (err, response) => {
          if (!err) {
            showSuccessMessage('Transaction Template created successfully');
            handleExit(response.data.result.Id);
          }
        }),
      );
    }
  };

  const updateTransaction = () => {
    return new Promise((resolve, reject) => {
      const data = cloneDeep(getTemplateData());

      delete data.TimelineTemplate;

      dispatch(
        putTransactionTemplateEffect(
          { ...data, IsProject: isProject },
          { id: data.Id },
          (err, resp) => {
            if (err) {
              reject(err);
            } else {
              resolve(resp);
            }
          },
        ),
      );
    });
  };

  const updateTimeline = () => {
    return new Promise((resolve, reject) => {
      const data = getTemplateData();

      const template = data?.TimelineTemplate;

      if (template && Object.keys(template).length) {
        if (template?.Id) {
          dispatch(
            putTimelineTemplateEffect(
              {
                title: template.Name,
                description: template.Description,
                milestones: template.Milestones,
                Teams: data.Teams,
              },
              { id: template.Id },
              (err, resp) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(resp);
                }
              },
            ),
          );
        } else {
          dispatch(
            postTimelineTemplateEffect(
              {
                title: template.Name || ' ',
                milestones: template.Milestones,
                description: template.Description,
                transactionTemplateId: data.Id,
              },
              { id: template.Id },
              (err, resp) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(resp);
                }
              },
            ),
          );
        }
      } else {
        resolve('');
      }
    });
  };

  const updateTransactionTemplate = async () => {
    try {
      await Promise.all([updateTransaction(), updateTimeline()]);
      showSuccessMessage('Transaction Template updated successfully');
    } catch (error) {
      showErrorMessage('Transaction template was not successfully updated');
    }
  };

  const handleOnFinishFailure = () => {
    let errors: TransactionTemplatePanes[] = [];

    if (form.getFieldError('Name')?.length) {
      errors.push(TransactionTemplatePanes.Detail);
    }
    if (form.getFieldError('TimelineTemplate')?.length) {
      errors.push(TransactionTemplatePanes.Timeline);
    }
    if (form.getFieldError('TransactionFormTemplate')?.length) {
      errors.push(TransactionTemplatePanes.Forms);
    }

    setErrorPanes(errors);
  };

  const getPaneName = (tab: TransactionTemplatePanes) => (
    <div className={styles.paneName}>
      {tab}
      {errorPanes.includes(tab) ? (
        <ExclamationCircleOutlined className={styles.errorIcon} />
      ) : (
        <span className={styles.emptySpanIcon}></span>
      )}
    </div>
  );

  const onTabChange = (key) => {
    if (errorPanes.includes(key)) {
      setErrorPanes(errorPanes.filter((tab) => tab !== key));
    }
  };

  const tabItems = [
    {
      key: TransactionTemplatePanes.Detail,
      label: getPaneName(TransactionTemplatePanes.Detail),
      children: (
        <DetailPane
          locations={locations}
          setLocations={setLocations}
          isViewMode={isViewMode}
          isProject={isProject}
          teamId={teamId}
        />
      ),
    },
    {
      key: TransactionTemplatePanes.Timeline,
      label: getPaneName(TransactionTemplatePanes.Timeline),
      children: <TimelinePane isViewMode={isViewMode} />,
    },
    {
      key: TransactionTemplatePanes.Tasks,
      label: getPaneName(TransactionTemplatePanes.Tasks),
      children: (
        <TasksPane isViewMode={isViewMode} data={getTemplateData()?.TimelineTemplate?.Milestones} />
      ),
    },
    {
      key: TransactionTemplatePanes.Forms,
      label: getPaneName(TransactionTemplatePanes.Forms),
      children: (
        <FormsPane
          transactionTemplate={form.getFieldsValue()}
          isViewMode={isViewMode}
          data={getTemplateData()?.TimelineTemplate?.Milestones}
        />
      ),
    },
    // TODO: Work on rules tab
    // {
    //   key: TransactionTemplatePanes.Rules,
    //   label: getPaneName(TransactionTemplatePanes.Rules),
    //   children: <RulesPane />,
    // },
  ];

  const getTabItems = () => {
    if (userRole === SUPER_USER) {
      tabItems.push({
        key: TransactionTemplatePanes.Details,
        label: getPaneName(TransactionTemplatePanes.Details),
        children: (
          <DetailsCardContent
            fieldsData={detailsTemplateFields}
            setDetailsTemplateFields={setDetailsTemplateFields}
            viewMode={isViewMode}
            isProject={isProject}
            transactionTemplateId={transactionTemplate?.Id}
          />
        ),
      });
    }
    return tabItems;
  };

  return (
    <div className={styles.transactionContainer}>
      <Form
        form={form}
        onFinish={handleSave}
        onReset={handleCancel}
        onFinishFailed={handleOnFinishFailure}
      >
        <Tabs
          activeKey={tabKey}
          onTabClick={(key) => setTabKey(key)}
          defaultActiveKey={TransactionTemplatePanes.Detail}
          className={styles.transactionPanes}
          onChange={onTabChange}
          items={getTabItems()}
        />
        <TransactionTabsFooter isViewMode={isViewMode} />
      </Form>
    </div>
  );
};
