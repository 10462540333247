import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { PENDING } from 'settings/constants/apiState';
import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';

import { Answers } from 'components';
import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';

import { getSignUpRoleSelector } from 'store/selectors/loginGroup';
import { Title, Back, Wrapper, Skip } from '../..';

import styles from './styles.module.scss';

const StageStories = ({ onNext, onPrev, stageIndex }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);

  const propName = OnBoardingWizard.propNames.stories;
  const storedStoriesInHome = getWizardStageValue({
    obj: onBoarding,
    stageIndex,
    propName,
    signUpRole,
  });

  const convertData = (data) => {
    if (data?.StoriesRange?.Min >= 1) {
      return [StageStories.answerIds.multiple];
    }

    if (data?.StoriesRange?.Max === 1) {
      return [StageStories.answerIds.one];
    }

    if (data?.StoriesRange === null) {
      return [StageStories.answerIds.notImportant];
    }
  };

  const [stageValue, setStageValue] = useState(convertData(storedStoriesInHome));

  const getValue = (val) => {
    if (val === StageStories.answerIds.one) {
      return { Max: 1 };
    }

    if (val === StageStories.answerIds.multiple) {
      return { Min: 1 };
    }

    return null;
  };

  const onSave = (val) => {
    const answer = getValue(val[0]);

    const cfg = {
      stageIndex,
      DefaultPropertySearchPreferences: {
        [propName]: answer
          ? {
              StoriesRange: answer,
              ImportanceAndWeight: {
                Importance: SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
              },
            }
          : undefined,
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, {}, (err) => {
        if (!err) {
          onNext();
        }
      }),
    );
  };

  const onAnswer = (val) => {
    setStageValue(val);
    onSave(val);
  };

  const isPending = onBoarding.state === PENDING;

  return (
    <Wrapper testid="stories_stage">
      <Back testid="stories_back" onBack={onPrev} text="Back" />
      <Title className={styles.title}>How many stories do you want in your home?</Title>
      <Answers
        cases={StageStories.answerCases}
        onChange={onAnswer}
        value={stageValue}
        pending={isPending}
        testid="stories_answers"
      />
      <Skip testid="stories_skip" onClick={onNext} />
    </Wrapper>
  );
};

StageStories.answerIds = {
  one: 'One',
  multiple: 'Multiple',
  notImportant: 'NotImportant',
};

StageStories.answerCases = [
  { id: StageStories.answerIds.one, label: 'Single-Story' },
  { id: StageStories.answerIds.multiple, label: 'Multi-Story' },
  { id: StageStories.answerIds.notImportant, label: 'Not Important' },
];

StageStories.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  stageIndex: PropTypes.number,
};

StageStories.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  stageIndex: undefined,
};

export default StageStories;
