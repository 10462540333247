import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ALL_PREFERENCES, prefsIds } from 'settings/constants/preferences';
import Item from './Item';

import styles from './styles.module.scss';

const Items = (props) => {
  const { list, onChangePref, currentImportance, className } = props;

  return (
    <div className={classNames(styles.items, className)}>
      {list.map((itemId) => {
        if (itemId !== prefsIds.kitchenFeatures && itemId !== prefsIds.flooring) {
          const prefOption = ALL_PREFERENCES.find((option) => option.id === itemId) || {};
          return (
            <Item
              key={itemId}
              onChange={(newImportance) => onChangePref(itemId, currentImportance, newImportance)}
              currentImportance={currentImportance}
              {...prefOption}
            />
          );
        }
      })}
    </div>
  );
};

Items.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangePref: PropTypes.func.isRequired,
  currentImportance: PropTypes.string.isRequired,
};

Items.defaultProps = {
  className: '',
};

export default Items;
