import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import { getIsSearchesDrawerOpenSelector } from 'store/selectors/searchResults';
import { getBuyersClientsListFormattedSelector } from 'store/selectors/search';
import BuyersComponent from 'pages/Properties/Search/components/SearchAgentsBlock/Buyers';
import {
  DrawerPaddingWrapper,
  ScrollDrawerWrapper,
} from 'pages/Properties/SearchResults/components';
import { Wrapper as PendingWrapper } from 'components';
import { requestGetClientsListEffect } from 'store/effects/clientsList';

import { resetSearchCriteriaEffect, setSearchCriteriaEffect } from 'store/effects/search';

import styles from './styles.module.scss';
import { getSearchResultsV2Effect } from 'store/effects/searchResults/searchV2';

const Buyers = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  const open = useSelector(getIsSearchesDrawerOpenSelector);
  const { isLoading, formattedData } = useSelector(getBuyersClientsListFormattedSelector);

  useEffect(() => {
    if (open) {
      dispatch(requestGetClientsListEffect());
    }
  }, [open]); // eslint-disable-line

  const onBuyerClick = (searchQuery) => {
    dispatch(resetSearchCriteriaEffect());
    dispatch(setSearchCriteriaEffect(searchQuery));
    dispatch(getSearchResultsV2Effect());
  };

  return (
    <ScrollDrawerWrapper className={classNames(styles.searchesScroll, className)}>
      <PendingWrapper isPending={isLoading} className={styles.pendingWrapper}>
        <DrawerPaddingWrapper className={styles.paddingWrapper}>
          <BuyersComponent
            buyerLineClassName={styles.buyerLine}
            onClick={onBuyerClick}
            list={formattedData}
          />
        </DrawerPaddingWrapper>
      </PendingWrapper>
    </ScrollDrawerWrapper>
  );
};

Buyers.propTypes = {
  className: PropTypes.string,
};

Buyers.defaultProps = {
  className: '',
};

export default Buyers;
