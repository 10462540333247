import { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

import { ArrowRight2, GreenCheckMark } from 'components/Icons';
import FormsModal from './Modals/Forms/FormsModal';
import TasksModal from './Modals/Tasks';
import DocumentsModal from './Modals/Documents';
import ShowingsModal from './Modals/Showings';
import OffersModal from './Modals/Offers';

const NeedsAttentionCard = ({ count = 2, text = 'Forms to Sign', onClick = () => {} }) => {
  return (
    <div className={classNames(styles.needsAttentionCardCardWrapper)} onClick={onClick}>
      <div className={classNames(styles.detailsWrapper)}>
        <div className={classNames(styles.details)}>
          <div
            className={classNames({
              [styles.countBadgeYellow]: count <= 10,
              [styles.countBadgeRed]: count > 10,
            })}
          >
            {count}
          </div>
          <div className={classNames(styles.detailsInner)}>
            <div className={classNames(styles.main)}>{text}</div>
          </div>
        </div>
      </div>
      <div>
        <ArrowRight2 className={classNames(styles.rightIcon)} />
      </div>
    </div>
  );
};

export const NeedsAttention = ({ title = 'Needs Your Attention:', needsAttentionData }) => {
  const [formDetails, setFormDetails] = useState<undefined | [{ [key: string]: any }]>();

  const [tasksDetails, setTasksDetails] = useState<undefined | [{ [key: string]: any }]>();
  const [documentsDetails, setDocumentsDetails] = useState<undefined | [{ [key: string]: any }]>();
  const [showingsDetails, setShowingsDetails] = useState<undefined | [{ [key: string]: any }]>();
  const [offersDetails, setOffersDetails] = useState<undefined | [{ [key: string]: any }]>();
  const [isPendingOffers, setIsPendingOffers] = useState(false);
  const needsAttentionModalKeys = ['Forms', 'Tasks', 'Documents', 'Offers', 'Showings'];

  const setNeedsAttentionModalData = (key, data, type) => {
    switch (key) {
      case 'Forms':
        setFormDetails(data);
        break;
      case 'Tasks':
        setTasksDetails(data);
        break;
      case 'Documents':
        setDocumentsDetails(data);
        break;
      case 'Offers':
        if (type === 'pendingOffers') {
          setIsPendingOffers(true);
        }
        setOffersDetails(data);
        break;
      case 'Showings':
        setShowingsDetails(data);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (tasksDetails && tasksDetails?.length > 0) {
      let data = needsAttentionData?.[1]?.[0]?.['tasksToComplete'];
      if (data) {
        setTasksDetails(data);
      }
    } else if (showingsDetails && showingsDetails?.length > 0) {
      let data = needsAttentionData?.[4]?.[0]?.['pendingApproval'];
      if (data) {
        setShowingsDetails(data);
      }
    } else if (offersDetails && offersDetails?.length > 0) {
      let data;
      if (isPendingOffers) {
        data = needsAttentionData?.[3]?.[0]?.['pendingOffers'];
      } else {
        data = needsAttentionData?.[3]?.[0]?.['newOffers'];
      }
      if (data) {
        setOffersDetails(data);
      }
    }
  }, [needsAttentionData]);

  const isEmptyStats = (data) => {
    return data
      ?.flatMap((arr) => arr)
      ?.flatMap((obj) => Object.values(obj))
      ?.every((arr) => arr.length === 0);
  };
  return (
    <div className={classNames(styles.mainWrapper)}>
      <div className={classNames(styles.title)}>{title}</div>

      {isEmptyStats(needsAttentionData) ? (
        <>
          <div className={styles.flexCol}>
            <div className={styles.checkMark}>
              <GreenCheckMark width={20} height={20} />
            </div>
            <p>{`You're all set for now`}</p>
          </div>
        </>
      ) : (
        <>
          <div className={classNames(styles.list)}>
            {needsAttentionData?.map((list, index) =>
              list?.map((item, i) => {
                return (
                  <>
                    {Object.keys(item).map((key) => {
                      const data = item?.[key];
                      const value = data?.length;
                      return (
                        <>
                          {value > 0 && (
                            <NeedsAttentionCard
                              count={value}
                              text={NeedAttentionKeys[key]}
                              onClick={() =>
                                setNeedsAttentionModalData(
                                  needsAttentionModalKeys[index],
                                  data,
                                  key,
                                )
                              }
                            />
                          )}
                        </>
                      );
                    })}
                  </>
                );
              }),
            )}
          </div>
        </>
      )}

      {formDetails && formDetails?.length > 0 && (
        <FormsModal
          isOpen={true}
          onCloseModal={() => setFormDetails(undefined)}
          formDetails={formDetails}
        />
      )}
      {tasksDetails && tasksDetails?.length > 0 && (
        <TasksModal
          isOpen={true}
          onCloseModal={() => setTasksDetails(undefined)}
          tasksDetails={tasksDetails}
        />
      )}
      {documentsDetails && documentsDetails?.length > 0 && (
        <DocumentsModal
          isOpen={true}
          onCloseModal={() => setDocumentsDetails(undefined)}
          documentsDetails={documentsDetails}
        />
      )}
      {showingsDetails && showingsDetails?.length > 0 && (
        <ShowingsModal
          isOpen={true}
          onCloseModal={() => setShowingsDetails(undefined)}
          showingsDetails={showingsDetails}
        />
      )}
      {offersDetails && offersDetails?.length > 0 && (
        <OffersModal
          isOpen={true}
          onCloseModal={() => {
            setOffersDetails(undefined);
            setIsPendingOffers(false);
          }}
          offersDetails={offersDetails}
        />
      )}
    </div>
  );
};

export default NeedsAttention;

export enum NeedAttentionKeys {
  tasksToComplete = 'Tasks to Complete',
  newDocuments = 'New Documents',
  pendingApproval = 'Showings to Approve',
  newOffers = 'New Offers',
  pendingOffers = 'Pending Offers',
  waitingOnYou = 'Forms to Sign',
}
