import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { cloneDeep, get } from 'lodash-es';

import { appLogoutAction } from 'store/actions/app';
import {
  requestGetProjectCategoriesAction,
  requestGetProjectOwnersAction,
} from 'store/actions/projects';
import { requestGetAllProjectsAction } from 'store/actions/transactions';

const initialData = {
  state: IDLE,
  projects: [],
  projectCategories: [],
  projectOwners: [],
  meta: {},
};

export default handleActions(
  {
    [requestGetProjectCategoriesAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      projectCategories:
        get(payload, 'data', initialData.projectCategories) || state.projectCategories,
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestGetAllProjectsAction]: (state, { payload }) => {
      const result = get(payload, 'data.result', initialData.projects);
      return {
        ...state,
        state: payload.state,
        projects: result,
        meta: get(payload, 'meta', initialData.meta),
      };
    },

    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
