import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { NoResults } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadsPerPropertyIdSelector } from 'store/selectors/sockets/threads';
import Property from '../../Properties/Property';
import { getFeedViewTypeSelector, getPropertyToScrollToSelector } from 'store/selectors/feed';
import { MAP_VIEWS } from 'settings/constants/mapView';

import styles from './styles.module.scss';
import { NoMlsAccessAgentFiller } from 'features/mlsAccess/propertyListFiller/NoMlsAccessAgentFiller';
import { NoMlsAccessClientFiller } from 'features/mlsAccess/propertyListFiller/NoMlsAccessClientFiller';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getSearchPageInfoSelector } from 'store/selectors/searchResults';
import { getLastPropertyHeightSelector } from 'store/selectors/app';
import Pagination from '../../../../../components/Pagination';
import { setFeedCurrentPageInfoAction } from '../../../../../store/actions/feed';

const FE_PAGE_SIZE = 40;
const MAX_PAGES = 9;

const PropertiesList = (props) => {
  const dispatch = useDispatch();

  const currentPageInfo = useSelector(getSearchPageInfoSelector);
  const propertyToScrollTo = useSelector(getPropertyToScrollToSelector);

  const { list, wrapperSize } = props;
  const propertyListRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(currentPageInfo?.currentFEPageInfo?.pageNumber || 1);
  const [renderingIndex, setRenderingIndex] = useState(
    currentPageInfo?.currentFEPageInfo?.renderingIndex || -1,
  );
  const [forceRerenderPagination, setForceRerenderPagination] = useState(-1);

  // TODO: Why do we have this declared if it's not passed anywhere?
  const { isClient: isPropsClient } = props;
  const threadsPerPropertyId = useSelector(getThreadsPerPropertyIdSelector);
  const viewType = useSelector(getFeedViewTypeSelector);
  const isListView = viewType.view === MAP_VIEWS.LIST;
  const { isClient } = useSelector(getUserRolesMapSelector);

  useEffect(() => {
    // reset the pagination state for feed after setting component state
    if (currentPageInfo?.currentFEPageInfo?.fromListingDetailPage) {
      dispatch(
        setFeedCurrentPageInfoAction({
          currentFEPageInfo: {
            pageNumber: 1,
            renderingIndex: -1,
            fromListingDetailPage: false,
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (propertyToScrollTo.propertyId && list) {
      for (let i = 1; i <= MAX_PAGES; i++) {
        const currentListings = list.slice((i - 1) * FE_PAGE_SIZE, i * FE_PAGE_SIZE);
        const selectedPropertyIndex = currentListings.findIndex(
          (property) => property?.Id === propertyToScrollTo.propertyId,
        );
        if (selectedPropertyIndex !== -1) {
          setPageNumber(i);
          setRenderingIndex(selectedPropertyIndex);
          setForceRerenderPagination(Math.ceil(Math.random() * 10000));
        }
      }
    }
  }, [propertyToScrollTo.propertyId]);

  const onPageChange = (pageNumber) => {
    setPageNumber(pageNumber + 1);
    propertyListRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const shouldScrollToProperty = (index) => {
    const shouldScroll = renderingIndex === index;
    setRenderingIndex(-1);
    return shouldScroll;
  };

  if (!props.hasMlsAccess) {
    return isClient ? <NoMlsAccessClientFiller /> : <NoMlsAccessAgentFiller />;
  }

  if (!list || !list.length) return <NoResults />;
  if (!wrapperSize.width || !wrapperSize.height) return null;

  const currentListings = list.slice((pageNumber - 1) * FE_PAGE_SIZE, pageNumber * FE_PAGE_SIZE);

  return (
    <div key={forceRerenderPagination} ref={propertyListRef} className={styles.propertyListWrapper}>
      <div
        className={classNames(styles.propertyContainer, {
          [styles.rowContainer]: isListView,
        })}
      >
        {currentListings?.map((item, index) => (
          <Property
            key={item.Id}
            {...item}
            isClient={isPropsClient}
            threadsPerPropertyId={threadsPerPropertyId}
            renderingIndex={index}
            getPageNumber={() => {
              return pageNumber;
            }}
            shouldScrollIntoView={() => shouldScrollToProperty(index)}
          />
        ))}
      </div>
      <Pagination
        limit={FE_PAGE_SIZE}
        onPageChange={onPageChange}
        items={list}
        pagesLimit={9}
        pageAutoHandle={false}
        // this component makes pages from 0 index
        defaultPageNumber={pageNumber - 1}
      />
    </div>
  );
};

PropertiesList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  wrapperSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  getNextPageIsPending: PropTypes.func,
  rowHeight: PropTypes.number,
  isClient: PropTypes.bool,
  hasMlsAccess: PropTypes.bool,
  loadDataEffect: PropTypes.func,
};

PropertiesList.defaultProps = {
  className: '',
  getNextPageIsPending: () => {},
  rowHeight: 386,
  list: null,
  isClient: undefined,
  hasMlsAccess: true,
};

export default PropertiesList;
