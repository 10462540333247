import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useRequest } from 'hooks';

import { getTransportationScoreDescriptions } from 'helpers/scores';

import { CircularProgressBar, Wrapper, ApiRequestError } from 'components';
import { getWalkscoreByGeolocationAndLocation } from 'api/listingDetail';

import styles from './styles.module.scss';

const NeighborhoodTransportation = ({
  walkScore,
  bikeScore,
  transitScore,
  geoLat,
  geoLong,
  location,
}) => {
  const [{ loading, error, data }, , sendRequest] = useRequest({
    request: getWalkscoreByGeolocationAndLocation,
    autoSend: false,
    params: {
      geolocation: `${geoLat},${geoLong}`,
      location,
    },
  });

  const { walk, transit, bike, walkDesc, bikeDesc, transitDesc } = useMemo(() => {
    const scores = {
      walk: data?.walkscore || walkScore,
      bike: data?.bikescore || bikeScore,
      transit: data?.transitscore || transitScore,
    };

    return {
      ...scores,
      ...getTransportationScoreDescriptions(scores),
    };
  }, [data, walkScore, transitScore, bikeScore]);

  useEffect(() => {
    if (transitScore && walkScore && bikeScore) return;
    sendRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper isPending={loading}>
      <div testid="transportation_block" className={styles.transportation}>
        {error ? (
          <ApiRequestError title={error?.message} />
        ) : (
          <>
            <div testid="walk_block" className={styles.transportationBlock}>
              <CircularProgressBar
                textClassName={styles.percent}
                percentage={walk}
                size="80"
                showText
                lineWidth={11}
              />
              <div className={styles.title}>Walk Score®</div>
              <div testid="walk_description">{walkDesc}</div>
            </div>
            <div testid="transit_block" className={styles.transportationBlock}>
              <CircularProgressBar
                textClassName={styles.percent}
                percentage={transit}
                size="80"
                showText
                lineWidth={11}
              />
              <div className={styles.title}>Transit Score®</div>
              <div testid="transit_description">{transitDesc}</div>
            </div>
            <div testid="bike_block" className={styles.transportationBlock}>
              <CircularProgressBar
                textClassName={styles.percent}
                percentage={bike}
                size="80"
                showText
                lineWidth={11}
              />
              <div className={styles.title}>Bike Score®</div>
              <div testid="bike_description">{bikeDesc}</div>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

NeighborhoodTransportation.propTypes = {
  walkScore: PropTypes.number,
  bikeScore: PropTypes.number,
  transitScore: PropTypes.number,
  location: PropTypes.string,
  geoLat: PropTypes.number.isRequired,
  geoLong: PropTypes.number.isRequired,
};

NeighborhoodTransportation.defaultProps = {
  walkScore: null,
  bikeScore: null,
  transitScore: null,
  location: '',
};

export default NeighborhoodTransportation;
