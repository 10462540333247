import { getAllTaskTemplates } from 'api/templates';
import { requestGetTaskTemplatesAction } from 'store/actions/templates';
import Api from 'store/effects/core/api';

export const getAllTaskTemplatesEffect = (cfg, options, cb) => {
  const requestParams = {
    action: requestGetTaskTemplatesAction,
    method: getAllTaskTemplates,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};
