import { useParams } from 'react-router-dom';

import { NewSmartForm } from './NewSmartForm';
import { EditSmartForm } from './EditSmartForm';

export const SmartForm = () => {
  const { versionId } = useParams<{ versionId: string }>();

  const renderSmartForm = () => {
    if (versionId === 'new') {
      return <NewSmartForm />;
    } else if (versionId) {
      return <EditSmartForm versionId={versionId} />;
    }

    return <></>;
  };

  return renderSmartForm();
};
