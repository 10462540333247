import { Button, Modal } from 'components-antd';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface ExternalVerificationWallModalProps {
  isModalOpen: boolean;
  sendVerificationLink: () => Promise<ExternalVerificationData>;
  getExternalVerificationInfo: () => Promise<ExternalVerificationData>;
}

export const ExternalVerificationWallModal: React.FC<ExternalVerificationWallModalProps> = (
  props,
) => {
  const [emailVerificationData, setEmailVerificationData] = useState<
    undefined | null | ExternalVerificationData
  >(undefined);

  // Load info about existing verification code request once modal is open
  useEffect(() => {
    const runEffect = async () => {
      let data = await props.getExternalVerificationInfo();
      if (!data) {
        // Email verification is not requested yet
        data = await props.sendVerificationLink();
      }

      setEmailVerificationData(data);
    };

    if (props.isModalOpen) {
      void runEffect();
    }
  }, [props.isModalOpen]);

  const [remainingSecondsToResendLink, setRemainingSecondsToResendLink] = useState<number | null>(
    null,
  );

  // Update timer with remaining seconds for next attempt
  useEffect(() => {
    const interval = setInterval(() => {
      const remainingSeconds = moment(emailVerificationData?.AllowLinkResendTimestamp).diff(
        moment(),
        'seconds',
      );
      setRemainingSecondsToResendLink(remainingSeconds);

      if (remainingSeconds < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [emailVerificationData]);

  const handleResendClick = async () => {
    try {
      const data = await props.sendVerificationLink();
      setEmailVerificationData(data);
      showSuccessMessage('Verification link sent');
    } catch (err) {
      showErrorMessage('Error occured while sending verification link');
    }
  };

  const getResendText = () => {
    if (remainingSecondsToResendLink === null) {
      return null;
    }

    if (remainingSecondsToResendLink > 0) {
      return <span>Resend it in {remainingSecondsToResendLink} seconds </span>;
    }

    if (remainingSecondsToResendLink <= 0) {
      return (
        <span>
          <a
            onClick={() => {
              handleResendClick();
            }}
            href="#"
          >
            Resend
          </a>
        </span>
      );
    }
  };

  return (
    <>
      {props.isModalOpen && (
        <Modal
          open={props.isModalOpen}
          width={675}
          footer={null}
          closable={false}
          maskClosable={false}
          destroyOnClose={true}
          title=" "
        >
          <div className={styles.layout}>
            <h2 className={styles.stepHeader}>Please verify your device.</h2>
            <div className={styles.text}>
              Sorry for the inconvenience, but we need to verify your device before you can access
              this page. We will send you a verification link to your email address
            </div>
            <div>
              {emailVerificationData &&
                (!emailVerificationData.IsBlocked ? (
                  <p>Didn’t receive it? {getResendText()}</p>
                ) : (
                  <p>
                    Didn’t receive it? Please contact{' '}
                    <a href="emailto:support@mosaik.io">support@mosaik.io</a> for further
                    assistance.
                  </p>
                ))}
            </div>
            <Button disabled={true} variant={'secondary'} className={styles.continueButton}>
              Continue
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

interface ExternalVerificationData {
  AllowLinkResendTimestamp: Date;
  IsBlocked: boolean;
}
