import styles from './styles.module.scss';
import { FC } from 'react';
import { Collapse, Panel } from 'components-antd';
import Icons from '../Icons';
import classNames from 'classnames';
import { IHomePrefsListItemProps } from '../types';

const HomePrefsListItem: FC<IHomePrefsListItemProps> = ({
  color,
  variant,
  label,
  keyValue,
  value,
  setExpanded,
  component,
  active,
  subtitle,
}) => {
  const handleChange = () => {
    setExpanded(keyValue === value ? '' : keyValue);
  };

  return (
    <Collapse
      ghost
      expandIconPosition="end"
      className={classNames(styles.container, { [styles[color]]: active })}
      expandIcon={() => null}
      activeKey={value}
      onChange={handleChange}
    >
      <Panel
        key={keyValue}
        header={
          <div className={styles.header}>
            <div className={classNames(styles.icon, styles[color])}>
              <Icons variant={variant} className={styles.svg} />
            </div>
            <span>
              <p className={styles.label}>{label}</p>
              <p className={styles.subtitle}>{subtitle}</p>
            </span>
          </div>
        }
      >
        {component()}
      </Panel>
    </Collapse>
  );
};

export default HomePrefsListItem;
