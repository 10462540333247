import React from 'react';

export const ConfettiRedIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M36 33.5C36.5523 33.5 37.0031 33.0519 36.9706 32.5006C36.8592 30.6098 36.4326 28.7485 35.706 26.9944C34.8516 24.9318 33.5994 23.0578 32.0208 21.4792C30.4422 19.9006 28.5682 18.6484 26.5056 17.794C24.4431 16.9397 22.2325 16.5 20 16.5C17.7675 16.5 15.5569 16.9397 13.4944 17.794C11.4318 18.6484 9.55778 19.9006 7.97918 21.4792C6.40059 23.0578 5.14838 24.9318 4.29405 26.9944C3.56745 28.7486 3.14075 30.6098 3.0294 32.5006C2.99693 33.0519 3.44772 33.5 4 33.5L20 33.5H36Z"
        fill="#928CDA"
      />
      <rect
        x="10.2422"
        y="13.7578"
        width="14.1414"
        height="14.1414"
        rx="1"
        transform="rotate(-45 10.2422 13.7578)"
        fill="#FF576D"
      />
      <path
        d="M26.2934 17.7074L20.9506 23.0502C20.5601 23.4407 19.9269 23.4407 19.5364 23.0502L14.0586 17.5724C15.958 16.8638 17.9704 16.5 20.0009 16.5C22.1566 16.5 24.292 16.91 26.2934 17.7074Z"
        fill="#92305D"
      />
    </svg>
  );
};
