import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import BaseNotification from '../../../BaseNotification';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { link } from 'settings/navigation/link';

import styles from './styles.module.scss';

const DocumentUploaded = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const { UploaderFirstName, UploaderLastName, TransactionId } = Payload || {};

  const history = useHistory();
  const dispatch = useDispatch();

  const Name = `${UploaderFirstName} ${UploaderLastName}`;

  const clickHandler = () => {
    dispatch(openNotificationsDrawerEffect({ open: false }));
    history.push(link.toTransactionDocuments(TransactionId));
  };

  return (
    <div className={classNames(styles.notificationType, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        groupType={groupType}
        avatarPlaceholder={convertNameToAvatarPlaceholder(Name)}
        severityLevel={SeverityLevel}
        onClick={clickHandler}
      />
    </div>
  );
};

DocumentUploaded.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

DocumentUploaded.defaultProps = {
  className: '',
};

export default DocumentUploaded;
