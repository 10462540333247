import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Footer = ({ className, children, testid }) => (
  <div testid={testid} className={classNames(styles.footer, className)}>
    {children}
  </div>
);

Footer.propTypes = {
  testid: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

Footer.defaultProps = {
  className: '',
  children: undefined,
  testid: undefined,
};

export default Footer;
