import {
  ACCEPT_TERMS_AND_CONDITIONS_ANON,
  ACCEPT_TERMS_AND_CONDITIONS_AUTH,
  DECLINE_SIGNATURE_ANON,
  DECLINE_SIGNATURE_AUTH,
  DEFER_QUESTION_ANON,
  DEFER_QUESTION_AUTH,
  DYNAMIC_UNLOCK_FORM_DOCUMENT_AUTH,
  FILL_FORM_DOCUMENT_ANON,
  FILL_FORM_DOCUMENT_AUTH,
  FORM_PROCESS_SIGNATURE,
  FORM_PROCESS_SIGNATURE_ANON,
  LINK_TO_FORM_PROCESS,
  UNDO_DEFER_QUESTION_ANON,
  UNDO_DEFER_QUESTION_AUTH,
  UNLINK_FORM_PROCESS,
  UNLOCK_FORM_DOCUMENT_ANON,
  UNLOCK_FORM_DOCUMENT_AUTH,
  UPDATE_SIGNATURE_SEQUENCE,
  UPDATE_STRIKE_THROUGH_ANON,
  UPDATE_STRIKE_THROUGH_AUTH,
  UPDATE_STRIKE_THROUGH_TEMPLATE,
} from 'app-constants';
import { apiServer } from 'settings/web-services/api';
import { FormProcessDataType } from 'types';

export const fillSmartFormValuesAuth = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.patch(FILL_FORM_DOCUMENT_AUTH({ formProcessPublicId, formDocumentPublicId }), cfg);

export const fillSmartFormValuesAnon = ({ type, ...cfg }) =>
  apiServer.patch(FILL_FORM_DOCUMENT_ANON(type), cfg);

export const acceptTermsAndConditionsAuth = (formProcessIds: Omit<FormProcessDataType, 'url'>) =>
  apiServer.post(ACCEPT_TERMS_AND_CONDITIONS_AUTH(formProcessIds));

export const acceptTermsAndConditionsAnon = ({ type, ...cfg }) =>
  apiServer.post(ACCEPT_TERMS_AND_CONDITIONS_ANON(type), cfg);

export const declineSignatureAuth = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.post(DECLINE_SIGNATURE_AUTH({ formProcessPublicId, formDocumentPublicId }), cfg);

export const declineSignatureAnon = ({ type, token, ...cfg }) =>
  apiServer.post(DECLINE_SIGNATURE_ANON(type, token), cfg);

export const unlockFormDocumentAuth = (formProcessIds: Omit<FormProcessDataType, 'url'>) =>
  apiServer.post(UNLOCK_FORM_DOCUMENT_AUTH(formProcessIds));

export const unlockDynamicFormDocumentAuth = (formProcessIds) =>
  apiServer.post(DYNAMIC_UNLOCK_FORM_DOCUMENT_AUTH(formProcessIds));

export const unlockFormDocumentAnon = ({ type, ...cfg }) =>
  apiServer.post(UNLOCK_FORM_DOCUMENT_ANON(type), cfg);

export const getSignatureAuth = (cfg) => apiServer.get(FORM_PROCESS_SIGNATURE, cfg);

export const saveSignatureAuth = (cfg) => apiServer.post(FORM_PROCESS_SIGNATURE, cfg);

export const saveSignatureAnon = (cfg) => apiServer.post(FORM_PROCESS_SIGNATURE_ANON(), cfg);

export const getSignatureAnon = ({ token, ...cfg }) =>
  apiServer.get(FORM_PROCESS_SIGNATURE_ANON(token), cfg);

export const deferQuestionAuth = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.post(DEFER_QUESTION_AUTH({ formProcessPublicId, formDocumentPublicId }), cfg);

export const undoDeferQuestionAuth = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.post(UNDO_DEFER_QUESTION_AUTH({ formProcessPublicId, formDocumentPublicId }), cfg);

export const deferQuestionAnon = ({ type, ...cfg }) =>
  apiServer.post(DEFER_QUESTION_ANON({ type }), cfg);

export const undoDeferQuestionAnon = ({ type, ...cfg }) =>
  apiServer.post(UNDO_DEFER_QUESTION_ANON({ type }), cfg);

export const getDeferredQuestions = ({ formProcessPublicId, formDocumentPublicId }) =>
  apiServer.get(DEFER_QUESTION_AUTH({ formProcessPublicId, formDocumentPublicId }));

export const deleteDeferredQuestion = ({ deferQuestionId }) =>
  apiServer.delete(DEFER_QUESTION_AUTH({ deferQuestionId }));

export const updateSignatureSequence = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.patch(UPDATE_SIGNATURE_SEQUENCE({ formProcessPublicId, formDocumentPublicId }), cfg);

export const updateStrikeThroughAuth = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.patch(UPDATE_STRIKE_THROUGH_AUTH({ formProcessPublicId, formDocumentPublicId }), cfg);

export const updateStrikeThroughAnon = ({ type, token, ...cfg }) =>
  apiServer.patch(UPDATE_STRIKE_THROUGH_ANON(type, token), cfg);

export const updateStrikeThroughTemplate = ({ templateId, ...cfg }) =>
  apiServer.patch(UPDATE_STRIKE_THROUGH_TEMPLATE({ templateId }), cfg);

export const linkToFormProcess = (cfg) => apiServer.patch(LINK_TO_FORM_PROCESS, cfg);

export const unlinkFormProcess = (cfg) => {
  return apiServer.patch(UNLINK_FORM_PROCESS, cfg);
};
