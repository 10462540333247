import { memo, useCallback } from 'react';
import { IIconsProps, IconsVariant } from '../types';

const Icons = memo(({ className, variant }: IIconsProps) => {
  const getIcon = useCallback(() => {
    switch (variant) {
      case IconsVariant.LAYOUT:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M6.49976 29H2.99976V3H28.9998V29H12.4998"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.9998 25.5V28.5"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.9998 16V19.5"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25.5 16H29"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.99976 16H19.4998"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case IconsVariant.PROPERTY_CONDITION:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <path
              d="M6.98474 16.5161V28.3532C6.98474 28.657 7.23098 28.9032 7.53474 28.9032H26.0476C26.3514 28.9032 26.5976 28.657 26.5976 28.3532V16.5161"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.6941 28.9036V21.7116C13.6941 21.4079 13.9403 21.1616 14.2441 21.1616H19.3376C19.6414 21.1616 19.8876 21.4079 19.8876 21.7116V28.9036"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.92017 18.0643L16.4409 8.54718C16.6442 8.37922 16.9381 8.37922 17.1414 8.54718L28.6621 18.0643"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.5974 1.03223C25.7372 4.47309 24.877 5.3333 21.4361 6.19352C24.877 7.05373 25.7372 7.91395 26.5974 11.3548C27.4576 7.91395 28.3178 7.05373 31.7587 6.19352C28.3178 5.3333 27.4576 4.47309 26.5974 1.03223Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95197 3.09668C5.12616 5.987 4.71326 6.39991 1.82294 7.22571C4.71326 8.05152 5.12616 8.46442 5.95197 11.3547C6.77778 8.46442 7.19068 8.05152 10.081 7.22571C7.19068 6.39991 6.77778 5.987 5.95197 3.09668Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case IconsVariant.OUTDOOR_SPACES:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <path d="M13 26V27" stroke="#FB913A" strokeWidth="2" strokeLinecap="round" />
            <path d="M13 3V4" stroke="#FB913A" strokeWidth="2" strokeLinecap="round" />
            <path
              d="M0.999756 15L1.99976 15"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M4.50024 6.49957L5.20735 7.20662"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M5.22205 22.7783L4.51494 23.4854"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M21.4998 6.49951L20.7926 7.20662"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <mask id="path-7-inside-1_44133_19985" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.2613 16.5575C17.7722 14.2804 19.8062 12.5791 22.2374 12.5791C24.3841 12.5791 26.221 13.9054 26.9733 15.7832C27.2189 15.7398 27.4716 15.7172 27.7296 15.7172C30.1129 15.7172 32.0449 17.6492 32.0449 20.0324C32.0449 22.2834 30.3215 24.1318 28.1222 24.3301V24.3479H17.9443L17.9225 24.348C15.7559 24.348 13.9995 22.5916 13.9995 20.425C13.9995 18.4837 15.4095 16.8718 17.2613 16.5575Z"
              />
            </mask>
            <path
              d="M17.2613 16.5575L17.596 18.5293L18.9189 18.3047L19.2127 16.9954L17.2613 16.5575ZM26.9733 15.7832L25.1168 16.5271L25.7212 18.0356L27.3215 17.7527L26.9733 15.7832ZM28.1222 24.3301L27.9426 22.3382L26.1222 22.5023V24.3301H28.1222ZM28.1222 24.3479V26.3479H30.1222V24.3479H28.1222ZM17.9443 24.3479V22.3479L17.9388 22.3479L17.9443 24.3479ZM17.9225 24.348V26.348L17.9279 26.348L17.9225 24.348ZM19.2127 16.9954C19.5232 15.6117 20.7621 14.5791 22.2374 14.5791V10.5791C18.8502 10.5791 16.0212 12.9492 15.3098 16.1196L19.2127 16.9954ZM22.2374 14.5791C23.5391 14.5791 24.658 15.382 25.1168 16.5271L28.8299 15.0394C27.7839 12.4287 25.2291 10.5791 22.2374 10.5791V14.5791ZM27.7296 13.7172C27.3544 13.7172 26.9851 13.7501 26.6252 13.8138L27.3215 17.7527C27.4527 17.7295 27.5889 17.7172 27.7296 17.7172V13.7172ZM34.0449 20.0324C34.0449 16.5446 31.2175 13.7172 27.7296 13.7172V17.7172C29.0083 17.7172 30.0449 18.7537 30.0449 20.0324H34.0449ZM28.3018 26.322C31.5225 26.0316 34.0449 23.3277 34.0449 20.0324H30.0449C30.0449 21.239 29.1204 22.232 27.9426 22.3382L28.3018 26.322ZM30.1222 24.3479V24.3301H26.1222V24.3479H30.1222ZM17.9443 26.3479H28.1222V22.3479H17.9443V26.3479ZM17.9279 26.348L17.9497 26.3479L17.9388 22.3479L17.9171 22.348L17.9279 26.348ZM11.9995 20.425C11.9995 23.6962 14.6513 26.348 17.9225 26.348V22.348C16.8605 22.348 15.9995 21.487 15.9995 20.425H11.9995ZM16.9266 14.5857C14.1293 15.0605 11.9995 17.492 11.9995 20.425H15.9995C15.9995 19.4754 16.6898 18.6831 17.596 18.5293L16.9266 14.5857Z"
              fill="#FB913A"
              mask="url(#path-7-inside-1_44133_19985)"
            />
            <path
              d="M19.8782 13.6986C19.6166 12.3162 18.9446 11.0447 17.9497 10.0499C16.9548 9.05516 15.6832 8.38327 14.3008 8.12187C12.9184 7.86048 11.4892 8.02173 10.1998 8.58458C8.9104 9.14743 7.8205 10.0858 7.07227 11.2772C6.32403 12.4687 5.95218 13.8579 6.00522 15.2639C6.05825 16.6698 6.53371 18.0271 7.36958 19.1588C8.20544 20.2905 9.36294 21.1441 10.6911 21.6082C12.0193 22.0723 13.4565 22.1254 14.8153 21.7606"
              stroke="#FB913A"
              strokeWidth="2"
            />
          </svg>
        );

      case IconsVariant.PARKING:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <path
              d="M27.9913 15.9993C27.9913 22.1849 22.9768 27.1993 16.7912 27.1993C10.6057 27.1993 5.59125 22.1849 5.59125 15.9993C5.59125 9.81373 10.6057 4.79932 16.7912 4.79932C22.9768 4.79932 27.9913 9.81373 27.9913 15.9993Z"
              stroke="#8079DB"
              strokeWidth="2"
            />
            <path
              d="M13.5912 22.3994V16.7994M13.5912 16.7994V10.3994H18.3912C20.1586 10.3994 21.5912 11.8321 21.5912 13.5994C21.5912 15.3667 20.1586 16.7994 18.3912 16.7994H13.5912Z"
              stroke="#8079DB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case IconsVariant.KITCHEN_FEATURES:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M4.00012 14H28.0001M4.00012 14V18V31M4.00012 14V31M4.00012 31H28.0001M28.0001 14V18V31M28.0001 14V31"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.00024 27V20H24.0002V27H8.00024Z"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.4999 0.999512V2.99953L6.99988 5.99951V7.99951H24.9999V5.99951L18.4999 2.99953V0.999512"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9998 17H12.9998"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.0002 17H20.0002"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case IconsVariant.FLOORING:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <rect
              x="2.79108"
              y="2"
              width="28"
              height="28"
              rx="1.375"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path d="M2.79108 13L13.7911 2" stroke="#4673D1" strokeWidth="2" />
            <path d="M3.00256 24.1318L24.9229 2.21153" stroke="#4673D1" strokeWidth="2" />
            <path d="M8.659 29.7886L30.5793 7.86826" stroke="#4673D1" strokeWidth="2" />
            <path d="M19.6194 30.1421L30.9331 18.8284" stroke="#4673D1" strokeWidth="2" />
            <path d="M8.30585 7.51465L14.2912 13.4999" stroke="#4673D1" strokeWidth="2" />
            <path d="M20.791 6L26.791 12" stroke="#4673D1" strokeWidth="2" />
            <path d="M6.7912 20L12.7912 26" stroke="#4673D1" strokeWidth="2" />
            <path d="M19.7913 19L25.2765 24.4853" stroke="#4673D1" strokeWidth="2" />
          </svg>
        );

      case IconsVariant.INTERIOR_FEATURES:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="19"
            viewBox="0 0 30 19"
            fill="none"
          >
            <rect
              x="4.75"
              y="1"
              width="19.75"
              height="12.25"
              rx="4.25"
              fill="#FFF2F3"
              stroke="#FF576D"
              strokeWidth="2"
            />
            <mask id="path-2-inside-1_44133_20050" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5 11.25C7.5 11.6402 7.78982 12 8.17999 12H21.07C21.4602 12 21.75 11.6402 21.75 11.25C21.75 9.17893 23.4289 7.5 25.5 7.5C27.5711 7.5 29.25 9.17893 29.25 11.25C29.25 12.6696 28.4612 13.9049 27.2981 14.5416C26.3897 15.0389 25.5 15.8395 25.5 16.875C25.5 17.9105 24.6605 18.75 23.625 18.75H5.625C4.58947 18.75 3.75 17.9105 3.75 16.875C3.75 15.8395 2.86027 15.0389 1.95193 14.5416C0.788764 13.9049 0 12.6696 0 11.25C0 9.17893 1.67893 7.5 3.75 7.5C5.82107 7.5 7.5 9.17893 7.5 11.25Z"
              />
            </mask>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.5 11.25C7.5 11.6402 7.78982 12 8.17999 12H21.07C21.4602 12 21.75 11.6402 21.75 11.25C21.75 9.17893 23.4289 7.5 25.5 7.5C27.5711 7.5 29.25 9.17893 29.25 11.25C29.25 12.6696 28.4612 13.9049 27.2981 14.5416C26.3897 15.0389 25.5 15.8395 25.5 16.875C25.5 17.9105 24.6605 18.75 23.625 18.75H5.625C4.58947 18.75 3.75 17.9105 3.75 16.875C3.75 15.8395 2.86027 15.0389 1.95193 14.5416C0.788764 13.9049 0 12.6696 0 11.25C0 9.17893 1.67893 7.5 3.75 7.5C5.82107 7.5 7.5 9.17893 7.5 11.25Z"
              fill="#FFF2F3"
            />
            <path
              d="M27.2981 14.5416L26.3377 12.7873L27.2981 14.5416ZM21.07 10H8.17999V14H21.07V10ZM23.75 11.25C23.75 10.2835 24.5335 9.5 25.5 9.5V5.5C22.3244 5.5 19.75 8.07436 19.75 11.25H23.75ZM25.5 9.5C26.4665 9.5 27.25 10.2835 27.25 11.25H31.25C31.25 8.07436 28.6756 5.5 25.5 5.5V9.5ZM27.25 11.25C27.25 11.9098 26.8857 12.4873 26.3377 12.7873L28.2584 16.296C30.0368 15.3224 31.25 13.4293 31.25 11.25H27.25ZM5.625 20.75H23.625V16.75H5.625V20.75ZM-2 11.25C-2 13.4293 -0.786804 15.3224 0.99157 16.296L2.91229 12.7873C2.36433 12.4873 2 11.9098 2 11.25H-2ZM3.75 5.5C0.574363 5.5 -2 8.07436 -2 11.25H2C2 10.2835 2.7835 9.5 3.75 9.5V5.5ZM9.5 11.25C9.5 8.07436 6.92564 5.5 3.75 5.5V9.5C4.7165 9.5 5.5 10.2835 5.5 11.25H9.5ZM5.75 16.875C5.75 15.7488 5.2593 14.8452 4.70974 14.2044C4.1689 13.5738 3.50253 13.1104 2.91229 12.7873L0.99157 16.296C1.30967 16.4701 1.54233 16.6556 1.67344 16.8085C1.79584 16.9512 1.75 16.9657 1.75 16.875H5.75ZM5.625 16.75C5.69404 16.75 5.75 16.806 5.75 16.875H1.75C1.75 19.0151 3.4849 20.75 5.625 20.75V16.75ZM26.3377 12.7873C25.7475 13.1104 25.0811 13.5738 24.5403 14.2044C23.9907 14.8452 23.5 15.7488 23.5 16.875H27.5C27.5 16.9657 27.4542 16.9512 27.5766 16.8085C27.7077 16.6556 27.9403 16.4701 28.2584 16.296L26.3377 12.7873ZM23.5 16.875C23.5 16.806 23.556 16.75 23.625 16.75V20.75C25.7651 20.75 27.5 19.0151 27.5 16.875H23.5ZM21.07 14C22.8096 14 23.75 12.4829 23.75 11.25H19.75C19.75 10.9608 19.8532 10.6808 20.0503 10.4553C20.258 10.2176 20.6148 10 21.07 10V14ZM5.5 11.25C5.5 12.4829 6.44043 14 8.17999 14V10C8.63516 10 8.99201 10.2176 9.1997 10.4553C9.39677 10.6808 9.5 10.9608 9.5 11.25H5.5Z"
              fill="#FF576D"
              mask="url(#path-2-inside-1_44133_20050)"
            />
          </svg>
        );

      case IconsVariant.VIEW:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <path
              d="M3.79077 22L5.95744 20.6387C7.31277 19.7871 8.93544 19.7871 10.2908 20.6387C11.6461 21.4902 13.2688 21.4902 14.6241 20.6387C15.9794 19.7871 17.6021 19.7871 18.9574 20.6387C20.3128 21.4902 21.9354 21.4902 23.2908 20.6387C24.6461 19.7871 26.2688 19.7871 27.6241 20.6387L29.7908 22"
              stroke="#8079DB"
              strokeWidth="2"
            />
            <path
              d="M23.627 19.9803C23.8525 18.8999 23.8453 17.7797 23.606 16.7026C23.3667 15.6255 22.9014 14.6188 22.2443 13.7568C21.5873 12.8949 20.7553 12.1997 19.8098 11.7226C18.8643 11.2454 17.8294 10.9985 16.7814 11C15.7334 11.0015 14.6992 11.2514 13.7549 11.7313C12.8106 12.2112 11.9804 12.9087 11.3256 13.7725C10.6708 14.6364 10.2081 15.6444 9.97156 16.7222C9.73504 17.8 9.73078 18.9202 9.95908 20"
              stroke="#8079DB"
              strokeWidth="2"
            />
            <path
              d="M3.79077 26.625V5.375C3.79077 4.61561 4.40638 4 5.16577 4H28.4158C29.1752 4 29.7908 4.61561 29.7908 5.375V26.625C29.7908 27.3844 29.1752 28 28.4158 28H5.16577C4.40638 28 3.79077 27.3844 3.79077 26.625Z"
              stroke="#8079DB"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
        );

      case IconsVariant.AMENITIES:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M2.28589 23.4287C5.02875 23.4287 5.02875 25.143 7.7716 25.143C10.5145 25.143 10.5145 23.4287 13.2573 23.4287C16.0002 23.4287 16.0002 25.143 18.743 25.143C21.4859 25.143 21.4859 23.4287 24.2287 23.4287C26.9716 23.4287 26.9716 25.143 29.7145 25.143"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M2.28589 19.4287C5.02875 19.4287 5.02875 21.143 7.7716 21.143C10.5145 21.143 10.5145 19.4287 13.2573 19.4287C16.0002 19.4287 16.0002 21.143 18.743 21.143C21.4859 21.143 21.4859 19.4287 24.2287 19.4287C26.9716 19.4287 26.9716 21.143 29.7145 21.143"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <circle cx="24.0001" cy="12.1627" r="2.57143" stroke="#FB913A" strokeWidth="2" />
            <path
              d="M20.0002 14.2449L20.5716 17.2517C20.2668 17.483 19.6573 17.7143 18.743 17.7143C16.4573 17.7143 16.2287 15.8639 13.2573 15.8639C10.5144 15.8639 9.60016 17.7143 8.22873 17.7143L14.8573 13.0884L13.2573 11.0068L8.67116 11.8993C7.92674 12.0441 7.20878 11.5485 7.08033 10.8011C6.95621 10.0789 7.42996 9.38926 8.14859 9.24603L13.5213 8.17516C14.066 8.06659 14.6276 8.25364 14.9984 8.66716L20.0002 14.2449Z"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case IconsVariant.MECHANICAL:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <path
              d="M23.4571 13.334H19.4571V9.33395L24.1238 4.66729C22.631 3.95432 20.9538 3.72169 19.3233 4.00145C17.6927 4.2812 16.189 5.05958 15.0192 6.22938C13.8494 7.39919 13.071 8.90289 12.7913 10.5334C12.5115 12.164 12.7442 13.8411 13.4571 15.334L5.45713 23.334C4.92669 23.8644 4.6287 24.5838 4.6287 25.334C4.6287 26.0841 4.92669 26.8035 5.45713 27.3339C5.98756 27.8644 6.70698 28.1624 7.45713 28.1624C8.20727 28.1624 8.92669 27.8644 9.45713 27.3339L17.4571 19.334C18.95 20.0469 20.6271 20.2795 22.2577 19.9998C23.8882 19.72 25.3919 18.9417 26.5617 17.7719C27.7315 16.602 28.5099 15.0983 28.7896 13.4678C29.0694 11.8373 28.8368 10.1601 28.1238 8.66729L23.4571 13.334Z"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      default:
        return null;
    }
  }, [variant]);

  return <div className={className}>{getIcon()}</div>;
});

export default Icons;
