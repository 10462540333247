import { apiServer } from 'settings/web-services/api';

export function postRecommendPartnerByAgent(cfg) {
  return apiServer.post(`/partner/partners/${cfg?.partnerId}/recommendation`);
}

export function deleteUnrecommendPartnerByAgent(cfg) {
  return apiServer.delete(`/partner/partners/${cfg?.partnerId}/recommendation`);
}

export function getRecommendedPartnerByAgentList(cfg) {
  return apiServer.post(`/partner/agent/partners/recommended`, cfg);
}
