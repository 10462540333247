import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PageWrapper, Row, Button } from 'components-antd';
import { routes } from 'settings/navigation/routes';
import { ConciergeCategoriesList } from './components/ConciergeCategoriesList';
import { LocationModal } from './components/LocationModal';
import { requestGetQuotesEffect } from 'store/effects/quotes';
import { getQuotesDataSelector, getQuotesSelector } from 'store/selectors/quotes';
import { Search } from './components/Search';
import { quoteRequestStatus } from 'settings/constants/quotes';
import ServicesCategory from '../ServicesCategory';
import {
  updateConciergeCategoryConfiguration,
  updateConciergeLocationConfiguration,
  updateConciergeServicesConfiguration,
} from 'store/actions/concierge';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { resetRequestQuoteAction } from 'store/actions/quotes';
import { LocationService } from 'services';

import styles from './styles.module.scss';

const locationSrv = new LocationService();

export const Services = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isPending, isIdle } = useSelector(getQuotesSelector);
  const quotesData = useSelector(getQuotesDataSelector);
  const { category } = useSelector(getConciergeSearchSelector);
  const [isSearch, setIsSearch] = useState<boolean>(!!category);

  locationSrv.setLocation(location);
  const { categoryid } = locationSrv.getQuery();

  useEffect(() => {
    if (isIdle) {
      dispatch(requestGetQuotesEffect());
    } else {
      dispatch(requestGetQuotesEffect({}, { silent: true }));
    }
  }, [isIdle]);

  useEffect(() => {
    if (categoryid) {
      dispatch(updateConciergeCategoryConfiguration(categoryid.toString()));
      setIsSearch(true);
    }

    return () => {
      setIsSearch(false);
      dispatch(updateConciergeCategoryConfiguration(null));
      dispatch(updateConciergeLocationConfiguration([]));
      dispatch(updateConciergeServicesConfiguration([]));
      dispatch(resetRequestQuoteAction());
    };
  }, []);

  const gotoMyQuotes = () => history.push(routes.servicesQuotes);

  const newQuotesCount = useMemo<number>(() => {
    return (
      quotesData?.filter(
        (quote) =>
          (quote.Status === quoteRequestStatus.PendingAnswer ||
            quote.Status === quoteRequestStatus.New) &&
          !quote.IsArchived,
      )?.length || 0
    );
  }, [quotesData]);

  const goBack = () => {
    setIsSearch(false);
    dispatch(updateConciergeCategoryConfiguration(null));
    dispatch(updateConciergeServicesConfiguration([]));
    dispatch(resetRequestQuoteAction());
  };

  return (
    <PageWrapper version={2} mainPageContentStyle={styles.mainPageContent}>
      <LocationModal />
      <div
        className={classNames(styles.headerRow, {
          [styles.headerRowWithoutBackBtn]: !isSearch,
        })}
      >
        {isSearch && (
          <div className={styles.headerLeft}>
            <Button variant={'go-back'} onClick={goBack} className={styles.goBackButton} />
          </div>
        )}
        <div className={styles.headerTitle}>Concierge</div>
        <div className={styles.headerRight}>
          <div className={classNames(styles.myQuotes, 'show-cursor')} onClick={gotoMyQuotes}>
            <div>My Quotes</div>
            {!isPending && newQuotesCount > 0 && (
              <div className={styles.myQuotesCountWrapper}>
                <div>{newQuotesCount}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Row justify="center">
        <Search isSearch={isSearch} setIsSearch={setIsSearch} />
      </Row>
      {isSearch ? (
        <ServicesCategory />
      ) : (
        <Row className={styles.resultRow}>
          <ConciergeCategoriesList setIsSearch={setIsSearch} />
        </Row>
      )}
    </PageWrapper>
  );
};
