import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { forgotPasswordAction } from 'store/actions/loginGroup';
import { getDataFor } from 'store/helpers';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [forgotPasswordAction]: (state, { payload }) => ({
      state: payload.state,
      data: getDataFor('data', payload, initialData),
      meta: getDataFor('meta', payload, initialData),
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
