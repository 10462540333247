import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getGroupedQuotesSelector, getIsArchiveQuotesSelector } from 'store/selectors/quotes';
import { getUserRoleSelector } from 'store/selectors/user';

import { THIRD_PARTY } from 'settings/constants/roles';
import styles from './styles.module.scss';

import GroupBy from './GroupBy';
import Archive from './Archive';
import { groupQuotesBy } from 'settings/constants/quotes';
import { Filter } from '../Filter';

const Actions = (props) => {
  const { className, isPending, isArchieveVisible } = props;
  const { groupId } = useSelector(getGroupedQuotesSelector);
  const isArchive = useSelector(getIsArchiveQuotesSelector);
  const userRole = useSelector(getUserRoleSelector);

  if (isPending) return null;

  const getActions = () => {
    if (isArchieveVisible) {
      return (
        <>
          <Filter />
          <div className="seperator" />
          <Archive />
        </>
      );
    }

    if (!(groupId || isArchive)) {
      return <GroupBy />;
    }
  };

  return <div className={classNames(styles.actions, className)}>{getActions()}</div>;
};

Actions.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  isArchieveVisible: PropTypes.bool,
};

Actions.defaultProps = {
  className: '',
  isPending: false,
  isArchieveVisible: false,
};

export default Actions;
