import React from 'react';

export const ConfettiBlueIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <circle cx="17.5" cy="16.25" r="13.75" fill="#4673D1" />
    <path
      d="M39.375 23.9175C40.2083 24.3986 40.2083 25.6014 39.375 26.0825L18.75 37.9904C17.9167 38.4715 16.875 37.8701 16.875 36.9079L16.875 13.0922C16.875 12.1299 17.9167 11.5285 18.75 12.0096L39.375 23.9175Z"
      fill="#00B152"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.875 29.9866V13.0927C16.875 12.1304 17.9167 11.529 18.75 12.0102L30.9625 19.0611C29.6652 25.307 24.1307 30.0005 17.5 30.0005C17.2905 30.0005 17.0822 29.9958 16.875 29.9866Z"
      fill="#035055"
    />
  </svg>
);
