import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const AssignTitle = (props) => {
  const { className, children } = props;

  return <div className={classNames(styles.assignTitle, className)}>{children}</div>;
};

AssignTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AssignTitle.defaultProps = {
  className: '',
};

export default AssignTitle;
