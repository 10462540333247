import { Icons } from 'pages/FormProcess/Icons';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getDynamicFormSelector } from 'store/selectors/requestFormProcess';
import { InfoTooltip } from '../../InfoTooltip';

export const DocumentNameInfo = ({ bundleName, openList, setOpenList, hideArrow = true }) => {
  const { dynamicFormDocuments } = useSelector(getDynamicFormSelector);

  const hasMultipleDocuments = dynamicFormDocuments && dynamicFormDocuments.length > 1;

  const renderName = () => <p className={styles.documentName}>{bundleName}</p>;
  const renderCount = () =>
    dynamicFormDocuments ? (
      <span className={styles.documentsCount}>({dynamicFormDocuments?.length})</span>
    ) : (
      <></>
    );

  return (
    <div
      className={classNames(styles.fileNameWrapContainer, {
        [styles.openedWrapContainer]: openList,
      })}
      onClick={() => setOpenList(!openList)}
    >
      <div className={styles.fileNameWrap}>
        <div
          className={classNames(styles.fileIconBundle, {
            [styles.singleFileIcon]: !hasMultipleDocuments,
          })}
        >
          <Icons variant={hasMultipleDocuments ? 'FileBundle' : 'File'} />
        </div>

        {bundleName && bundleName?.length > 40 ? (
          <div className={styles.bundleNameCount}>
            <InfoTooltip text={bundleName} className={styles.bundleNameTooltip}>
              {renderName()}
            </InfoTooltip>
            {renderCount()}
          </div>
        ) : (
          <p className={styles.documentName}>
            {bundleName}
            {renderCount()}
          </p>
        )}
      </div>

      <div className={styles.arrowIconWrap}>
        {openList ? (
          <Icons variant={'UpArrow'} className={styles.icon} />
        ) : (
          <Icons variant={'DownArrow'} className={styles.icon} />
        )}
      </div>
    </div>
  );
};
