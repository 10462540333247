import Api from 'store/effects/core/api';
import { getServiceLocation, updateServiceLocation } from 'api/concierge';
import {
  openConciergeLocationModal,
  requestConciergeLocationConfiguration,
  requestUpdateConciergeLocationConfiguration,
  updateConciergeLocationConfiguration,
} from 'store/actions/concierge';

export const getConciergeLocationEffect = (cfg?, options: any = {}, cb?) => {
  const requestParams = {
    action: requestConciergeLocationConfiguration,
    method: getServiceLocation,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const updateConciergeLocationEffect = (cfg, options: any = {}, cb?) => {
  const requestParams = {
    action: requestUpdateConciergeLocationConfiguration,
    method: updateServiceLocation,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const updateConciergeLocationLocalEffect = (cfg?) => (dispatch) => {
  dispatch(updateConciergeLocationConfiguration(cfg));
};

export const openLocationModalEffect = (bool: boolean) => (dispatch) => {
  dispatch(openConciergeLocationModal(bool));
};
