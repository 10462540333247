import { get } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import {
  openConciergeLocationModal,
  requestConciergeLocationConfiguration,
  updateConciergeCategoryConfiguration,
  updateConciergeLocationConfiguration,
  updateConciergeServicesConfiguration,
  updateConciergeSelectedTab,
} from 'store/actions/concierge';
import { ConciergeConfigurationState } from 'types';
import { cloneDeep } from 'lodash-es';
import { initialData } from '../initialState';

export const conciergeSearchReducer = {
  [requestConciergeLocationConfiguration as any]: (
    state: ConciergeConfigurationState,
    { payload },
  ): ConciergeConfigurationState => ({
    ...state,
    data: {
      ...state.data,
      areasOfOperation: get(payload, 'data.value.AreasOfOperation', []),
    },
  }),
  [updateConciergeLocationConfiguration as any]: (
    state: ConciergeConfigurationState,
    { payload },
  ): ConciergeConfigurationState => ({
    ...state,
    data: {
      ...state.data,
      areasOfOperation: payload,
    },
  }),
  [openConciergeLocationModal as any]: (
    state: ConciergeConfigurationState,
    { payload },
  ): ConciergeConfigurationState => ({
    ...state,
    data: {
      ...state.data,
      openLocationModal: payload,
    },
  }),
  [updateConciergeCategoryConfiguration as any]: (
    state: ConciergeConfigurationState,
    { payload },
  ): ConciergeConfigurationState => ({
    ...state,
    data: {
      ...state.data,
      category: payload,
    },
  }),
  [updateConciergeServicesConfiguration as any]: (
    state: ConciergeConfigurationState,
    { payload },
  ): ConciergeConfigurationState => ({
    ...state,
    data: {
      ...state.data,
      services: payload,
    },
  }),
  [updateConciergeSelectedTab as any]: (
    state: ConciergeConfigurationState,
    { payload },
  ): ConciergeConfigurationState => ({
    ...state,
    data: {
      ...state.data,
      selectedTab: payload,
    },
  }),

  [appLogoutAction as any]: () => cloneDeep(initialData),
};
