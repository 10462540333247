import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { prefsIds } from 'settings/constants/preferences';
import { Edit } from 'components/Icons';

import Label from '../Label';
import Icon from '../../Icons';

import { Row, Col } from 'antd';

import styles from './styles.module.scss';

const editableOptions = [
  prefsIds.outdoorPrefs,
  prefsIds.amenities,
  prefsIds.parking,
  prefsIds.commutes,
  prefsIds.proximityToImportantLocations,
];

const Option = ({
  actives,
  onClick,
  onEdit,
  option,
  outdoorSpaceValue,
  amenitiesValue,
  parkingSpacesValue,
  commutesValue,
  importantLocationsValue,
  className,
  testid,
}) => {
  const isEditable = (id) => editableOptions.includes(id) && isActive(id);

  const isActive = (id) => {
    if (
      (id === prefsIds.parking && !parkingSpacesValue) ||
      (id === prefsIds.outdoorPrefs && !outdoorSpaceValue?.length) ||
      (id === prefsIds.commutes && !commutesValue?.length) ||
      (id === prefsIds.proximityToImportantLocations && !importantLocationsValue?.length)
    ) {
      return false;
    }

    return actives.includes(id);
  };

  return (
    <Row gutter={[16, 16]} className={classNames(styles.optionHolder, className)}>
      {option.map(({ id, label, displayInWizard }) => {
        if (displayInWizard) {
          return (
            <Col xs={12} md={8}>
              <div
                key={id}
                onClick={editableOptions.includes(id) ? (e) => onEdit(e, id) : () => onClick(id)}
                className={classNames(styles.option, { [styles.active]: isActive(id) })}
                testid={testid}
              >
                <div className={styles.optionInner}>
                  {isEditable(id) && (
                    <Edit onClick={(e) => onEdit(e, id)} className={styles.edit} />
                  )}
                  <Icon
                    className={styles.icon}
                    variant={id}
                    color={isActive(id) ? '#FF576D' : ''}
                  />
                  <Label
                    id={id}
                    label={label}
                    actives={actives}
                    outdoorSpaceValue={outdoorSpaceValue}
                    amenitiesValue={amenitiesValue}
                    parkingSpacesValue={parkingSpacesValue}
                    commutesValue={commutesValue}
                    importantLocationsValue={importantLocationsValue}
                    className={classNames(styles.label, isActive(id) ? styles.labelActive : '')}
                  />
                </div>
              </div>
            </Col>
          );
        }
      })}
    </Row>
  );
};

Option.propTypes = {
  className: PropTypes.string,
  actives: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  option: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  amenitiesValue: PropTypes.shape({}).isRequired,
  parkingSpacesValue: PropTypes.number.isRequired,
  commutesValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  importantLocationsValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  testid: PropTypes.string,
};

Option.defaultProps = {
  className: '',
  actives: [],
  testid: undefined,
};

export default Option;
