import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import { useDispatch } from 'react-redux';
import { requestFetchActiveSubscriptionDataEffect } from 'store/effects/subscription';
import { LocationService } from 'services';

const locationSrv = new LocationService();

export const usePaymentCallbackHandler = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  locationSrv.setLocation(location);

  useEffect(() => {
    const query = locationSrv.getQuery();

    if (query.canceled) {
      showErrorMessage('Subscription was unsuccessful');
    }
    if (query.success && query.session_id) {
      dispatch(
        requestFetchActiveSubscriptionDataEffect(() => {
          history.replace(locationSrv.setQuery({}));
        }),
      );

      showSuccessMessage('Subscription was successful');
    }
  }, []);
};
