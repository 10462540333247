import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { DeleteModal, Table } from 'components-antd';
import {
  getFormListSelector,
  getFormReloadSelect,
  getFormListSearchSelect,
  getDeleteFormVersionSelect,
} from 'store/selectors/formBuilder';
import {
  deleteFormVersionEffect,
  deleteFormVersionModalEffect,
  getFormListEffect,
  selectReloadFormListEffect,
  updateFormListSearchEffect,
} from 'store/effects/formBuilder';
import {
  FormListType,
  FormLocation,
  FormStatus,
  FormUpdateStatusVariants,
  FormVersionType,
} from 'types';
import { formListColumns, formListNestedColumns } from './config';
import { FormUpdateModal, StatusDropdown } from 'pages/FormBuilder/components';
import { FORM_STATUS_TYPE, FORM_TYPE, FORM_UPDATE_STATUS } from 'app-constants';
import { PENDING } from 'settings/constants/apiState';

import styles from './styles.module.scss';

interface FormStatusUpdate {
  smartForm: boolean;
  variant: FormUpdateStatusVariants;
  formId: number;
  versionId: number;
  formName: string;
  selectedTags: number[];
  categories: number[];
  formLocations: FormLocation[];
}

export const FormTable = () => {
  const dispatch = useDispatch();

  const { formList } = useSelector(getFormListSelector);
  const refresh = useSelector(getFormReloadSelect);
  const search = useSelector(getFormListSearchSelect);
  const deleteForm = useSelector(getDeleteFormVersionSelect);

  const [formStatusUpdate, setFormStatusUpdate] = useState<FormStatusUpdate>();

  useEffect(() => {
    if (refresh) getForms();
  }, [refresh]);

  useEffect(() => {
    if (formStatusUpdate?.variant) {
      const formData = formList?.forms.find((el) => el.Id === formStatusUpdate.formId);
      if (formData) {
        setFormStatusUpdate({
          ...formStatusUpdate,
          categories: formData.FormCategoryPermission.map((el) => el.CategoryId),
          formLocations: formData?.FormLocationPermission?.locationsData
            ? [...formData.FormLocationPermission.locationsData]
            : [],
          selectedTags: formData.FormTag.map((el) => el.TagId),
        });
      }
    }
  }, [formList?.forms]);

  const getForms = () => {
    dispatch(getFormListEffect(search));
  };

  const handleStatusUpdate = (
    formId: number,
    versionId: number,
    formName: string,
    status: FormStatus,
    currentStatus: FormStatus,
  ) => {
    if (!formList.forms || status === currentStatus) return;
    const formData = formList?.forms.find((el) => el.Id === formId);

    let updateStatus: FormUpdateStatusVariants | undefined;

    switch (status) {
      case FORM_STATUS_TYPE.Active:
        updateStatus = FORM_UPDATE_STATUS.Publish;
        break;
      case FORM_STATUS_TYPE.InActive:
        updateStatus = FORM_UPDATE_STATUS.Unpublish;
        break;

      default:
        break;
    }

    if (formData && updateStatus) {
      const type = formData.Type;

      setFormStatusUpdate({
        smartForm: type === FORM_TYPE.Smart,
        categories: formData.FormCategoryPermission.map((el) => el.CategoryId),
        formId,
        formLocations: formData?.FormLocationPermission?.locationsData
          ? [...formData.FormLocationPermission.locationsData]
          : [],
        formName,
        selectedTags: formData.FormTag.map((el) => el.TagId),
        variant: updateStatus,
        versionId,
      });
    }
  };

  const getColumns = () => {
    const formListColum = [...formListColumns];
    formListColum[3] = {
      width: 100,
      title: 'Status',
      className: 'formListStatus',
      key: 'status',
      dataIndex: 'CurrentVersion',
      render: (form: FormVersionType, row: FormListType, index) => (
        <StatusDropdown
          status={form.Status}
          index={index}
          formId={form.FormId}
          versionId={form.Id}
          formName={form.Name}
          disabled={formList.state === PENDING}
          onValueChange={async (status) =>
            handleStatusUpdate(form.FormId, form.Id, form.Name, status, form.Status)
          }
        />
      ),
    };

    return formListColum;
  };

  const getNestedColumns = () => {
    const formListNestedColumn = [...formListNestedColumns];

    formListNestedColumn[1] = {
      width: 324,
      title: 'Status',
      className: 'formListStatus',
      key: 'status',
      dataIndex: 'Status',
      render: (status: FormStatus, row: FormVersionType, index) => (
        <StatusDropdown
          status={status}
          index={index}
          formId={row.FormId}
          versionId={row.Id}
          formName={row.Name}
          disabled={formList.state === PENDING}
          onValueChange={async (updatedStatus) =>
            handleStatusUpdate(row.FormId, row.Id, row.Name, updatedStatus, status)
          }
        />
      ),
    };

    return formListNestedColumn;
  };

  const onPageChange = (page: number) => dispatch(updateFormListSearchEffect({ ...search, page }));

  const expandedRowRender = (record: FormListType) => (
    <Table
      showHeader={false}
      rowKey={'Id'}
      className={classNames(styles.formTable, styles.formTableNested)}
      columns={getNestedColumns()}
      dataSource={record.FormVersions.map((el) => ({
        ...el,
        Type: record.Type,
        currentId: record.Id,
      }))}
      pagination={false}
    />
  );

  const handleDeleteForm = () => {
    dispatch(
      deleteFormVersionEffect({ ...deleteForm }, () => {
        closeDeleteModal();
        getForms();
      }),
    );
  };

  const closeDeleteModal = () => {
    dispatch(deleteFormVersionModalEffect());
  };

  const handleFormUpdate = (res, err, customError) => {
    {
      if (formStatusUpdate?.variant === FORM_UPDATE_STATUS.Publish) {
        if (err) {
          setFormStatusUpdate(undefined);
        } else if (customError) {
          dispatch(selectReloadFormListEffect());
        } else {
          setFormStatusUpdate(undefined);
          dispatch(selectReloadFormListEffect());
        }
      } else {
        setFormStatusUpdate(undefined);
        dispatch(selectReloadFormListEffect());
      }
    }
  };

  return (
    <>
      <FormUpdateModal
        open={!!formStatusUpdate}
        {...(formStatusUpdate || {})}
        hideModal={() => setFormStatusUpdate(undefined)}
        callBack={handleFormUpdate}
      />
      {deleteForm && (
        <DeleteModal
          open={true}
          entityName={deleteForm.formName}
          onOk={handleDeleteForm}
          onCancel={() => closeDeleteModal()}
        />
      )}
      <Table
        rowKey={'Id'}
        className={classNames(styles.formTable, styles.formTablePrimary)}
        loading={formList.state === PENDING}
        dataSource={formList?.forms}
        columns={getColumns()}
        expandable={{
          expandedRowRender: expandedRowRender,
          rowExpandable: (record: FormListType) => !!record.FormVersions.length,
        }}
        pagination={{
          pageSize: formList.search.pageSize || 10,
          current: formList.search.page || 1,
          total: formList?.total,
          onChange: onPageChange,
        }}
      />
    </>
  );
};
