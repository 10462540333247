/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import { ItemsWrapper, ItemWrapper, Value } from '../components';

import styles from './styles.module.scss';

const SquareFeet = (props) => {
  const { className, squareFeets } = props;

  if (!squareFeets) return null;

  return (
    <ItemsWrapper className={classNames(styles.squareFeets, className)}>
      {squareFeets.map((count, index) => (
        <ItemWrapper key={index}>
          <NumberFormat
            displayType="text"
            thousandSeparator
            value={count}
            renderText={(val) => <Value testid="square_feet" value={val} postfix="sqft" />}
          />
        </ItemWrapper>
      ))}
    </ItemsWrapper>
  );
};

SquareFeet.propTypes = {
  className: PropTypes.string,
  squareFeets: PropTypes.arrayOf(PropTypes.number).isRequired,
};

SquareFeet.defaultProps = {
  className: '',
};

export default SquareFeet;
