import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Drawer } from 'components';
import { Header } from './Header';
import { openPreApprovalLettersDrawerEffect } from 'store/effects/clientSearches';
import { getIsPreApprovalLettersDrawerOpenSelector } from 'store/selectors/clientSearches';
import { PreApprovalDocuments } from './../PreApprovalDocuments/PreApprovalDocuments';

export const PreApprovalDrawer = ({ clientId }) => {
  const dispatch = useDispatch();
  const open = useSelector(getIsPreApprovalLettersDrawerOpenSelector);

  const getHeader = () => {
    return (
      <Header
        title="Pre-Approval Letters"
        onClose={() => dispatch(openPreApprovalLettersDrawerEffect({ open: false }))}
      />
    );
  };

  return (
    <Drawer header={getHeader()} isOpen={open}>
      <PreApprovalDocuments clientId={clientId} />
    </Drawer>
  );
};

PreApprovalDrawer.propTypes = {
  clientId: PropTypes.number,
};

PreApprovalDrawer.defaultProps = {
  clientId: null,
};
