import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { isEqual } from 'lodash-es';

import { Modal } from 'components-antd';
import { updateTransactionEffect } from 'store/effects/transactions';
import { Button, ConfirmModal, Drawer } from 'components';
import { isPropertyAddress } from 'pages/Workshop/Transactions/TransactionCreate/helpers';
import { transactionStatuses } from 'settings/constants/transaction';
import Content from './Content';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { requestGetProjectCategories } from 'store/effects/projects';

const EditModal = (props) => {
  const { className, open, transaction, onClose } = props;
  const [isPending, setIsPending] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [values, setValues] = useState({});
  const dispatch = useDispatch();
  const isProject = useIsProjectRoute();
  const updateTransaction = () => {
    setIsPending(true);
    dispatch(
      updateTransactionEffect(values, {}, (err) => {
        if (!err) {
          onCancel();
        }
        setIsPending(false);
      }),
    );
  };

  const onSave = () => {
    if (values?.status?.value !== transaction?.status?.value) {
      setOpenConfirmModal(true);
    } else {
      updateTransaction();
    }
  };

  const getModalTitle = () => (
    <div testid="modal_title">
      <div>Are you sure you want to change the status of this transaction to:</div>
      <div>{`${values?.status?.name}?`}</div>
    </div>
  );

  const onCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const isCantBeUpdated = () => {
    const { price, address, name } = values || {};
    if (isProject) {
      return !name;
    }
    return !price || !isPropertyAddress(address);
  };

  const isTheSameAddress = (initialAddress, currentAddress) =>
    initialAddress?.City === currentAddress?.City &&
    initialAddress?.State === currentAddress?.State &&
    initialAddress?.Line1 === currentAddress?.Line1 &&
    initialAddress?.Line2 === currentAddress?.Line2 &&
    initialAddress?.PlaceName === currentAddress?.PlaceName &&
    initialAddress?.ProviderPlaceId === currentAddress?.ProviderPlaceId;

  const nothingChanged = () => {
    if (isProject) {
      return (
        values?.name === transaction?.name &&
        values?.category?.value === transaction?.category?.value &&
        moment(values?.closingDate).isSame(
          moment(transaction?.closingDate).startOf('day'),
          'day',
        ) &&
        moment(values?.effectiveDate).isSame(
          moment(transaction?.effectiveDate).startOf('day'),
          'day',
        )
      );
    }
    return (
      isTheSameAddress(values?.address, transaction?.address) &&
      Number(values?.price) === Number(transaction?.price) &&
      values?.status?.value === transaction?.status?.value &&
      moment(values?.closingDate).isSame(moment(transaction?.closingDate).startOf('day'), 'day') &&
      isEqual(values?.transactionClients, transaction?.transactionClients) &&
      values?.PropertyType === transaction?.PropertyType &&
      values?.YearBuilt === transaction?.YearBuilt &&
      Number(values?.FinancingType) === Number(transaction?.FinancingType)
    );
  };

  const getSubmitModalVariant = () => {
    const archivedStatuses = [transactionStatuses.Canceled, transactionStatuses.Closed];

    if (archivedStatuses.includes(values?.status?.value)) {
      return undefined;
    }

    return 'primary';
  };

  const onCancel = () => {
    setOpenConfirmModal();
    onClose();
  };

  useEffect(() => {
    if (transaction?.id) setValues({ ...transaction });
  }, [transaction]); // eslint-disable-line

  useEffect(() => {
    if (isProject) {
      dispatch(requestGetProjectCategories({}, { silent: true }));
    }
  }, []);

  const getEditModalTitle = () => {
    if (isProject) return 'Edit Project';
    return 'Edit Transaction';
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className={classNames(styles.editTransactionModal, className)}
      footer={null}
    >
      <h2 className={styles.heading}>{getEditModalTitle()}</h2>
      <Content isPending={isPending} values={values} setValues={setValues} />
      <Button
        className={styles.submitButton}
        loaderClassName={styles.submitButtonLoader}
        title="Save"
        onClick={onSave}
        testid="save_button"
        isPending={isPending}
        disabled={nothingChanged() || isCantBeUpdated()}
      />
      <ConfirmModal
        title={getModalTitle()}
        isOpen={openConfirmModal}
        onClose={onCloseConfirmModal}
        onSubmit={updateTransaction}
        isPending={isPending}
        submitVariant={getSubmitModalVariant()}
      />
    </Modal>
  );
};

EditModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  transaction: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.object,
    status: PropTypes.string,
    price: PropTypes.number,
    closingDate: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

EditModal.defaultProps = {
  className: '',
  open: false,
  transaction: {},
  onClose: () => {},
};

export default EditModal;
