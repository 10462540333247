import isUrl from 'is-url';
import { get, isEmpty } from 'lodash-es';

import Api from 'store/effects/core/api';

export const preparedAttachment = (attachment) => ({
  ContentType: attachment?.ContentType,
  Filename: attachment?.Filename,
  Data: !isUrl(attachment?.Url) ? btoa(attachment?.Url) : undefined,
  Url: isUrl(attachment?.Url) ? attachment?.Url : undefined,
  Size: attachment?.Size,
});

export const getPartnerSendData = (cfg, isUpdate) => {
  let address = get(cfg, 'Address');
  if (!address || !Object.keys(address).length) {
    address = null;
  }
  let website = get(cfg, 'Website');
  if (!website) {
    website = null;
  }
  let phoneNumber = get(cfg, 'PhoneNumber');
  if (!phoneNumber) {
    phoneNumber = null;
  }
  const geolocation =
    address?.Geolocation?.Lat && address?.Geolocation?.Long
      ? {
          Geolocation: {
            Lat: get(address, 'Geolocation.Lat'),
            Long: get(address, 'Geolocation.Long'),
          },
        }
      : {};
  const logo = get(cfg, 'LogoUrl');
  const logoAttachment = logo && typeof logo === 'object' ? preparedAttachment(logo) : null;
  const locations =
    get(cfg, 'locations')?.filter((loc) => loc.Radius !== '' || loc.AreasOfOperation !== '') ||
    undefined;
  const RecommendedByAgentsId = get(cfg, 'RecommendedByAgentsId', null);

  return {
    BusinessName: get(cfg, 'BusinessName'),
    AdditionalBusinessName: get(cfg, 'AdditionalBusinessName') || null,
    Email: get(cfg, 'Email'),
    PhoneNumber: phoneNumber,
    PartnerServices: get(cfg, 'PartnerServices', []),
    AreasOfOperation: locations?.length
      ? locations.map((areas) => {
          const { Radius, AreasOfOperation: location } = areas;
          return {
            Type: get(location, 'Type') || undefined,
            Box: get(location, 'Box') || undefined,
            ProviderPlaceId: get(location, 'ProviderPlaceId') || undefined,
            NeighborhoodName: get(location, 'NeighborhoodName') || undefined,
            LocationId: get(location, 'NeighborhoodId') || get(location, 'LocationId') || undefined,
            PlaceName: get(location, 'PlaceName') || undefined,
            City: get(location, 'City') || undefined,
            State: get(location, 'State') || undefined,
            Street: get(location, 'Street') || undefined,
            Zipcode: get(location, 'Zipcode') || undefined,
            Radius: Radius,
          };
        })
      : undefined,
    Address: address
      ? {
          Line1: get(cfg, 'Address.Line1') || undefined,
          Line2: get(cfg, 'Address.Line2') || undefined,
          City: get(cfg, 'Address.City') || undefined,
          State: get(cfg, 'Address.State') || undefined,
          Zip: get(cfg, 'Address.Zip') || undefined,
          ...geolocation,
        }
      : null,
    SuiteUnit: get(cfg, 'SuiteUnit') || null,
    Description: get(cfg, 'Description') || null,
    Website: website || null,
    IsActive: get(cfg, 'IsActive'),
    IsRecommendedByMosaik: get(cfg, 'IsRecommendedByMosaik'),
    PartnerCategoryIds: get(cfg, 'PartnerCategoryIds', []).map(({ value }) => value),
    ...(isUpdate
      ? {
          ContactUser: {
            Email: get(cfg, 'ContactUser.Email'),
          },
        }
      : {
          ContactUser: {
            FirstName: get(cfg, 'ContactUser.FirstName', ''),
            LastName: get(cfg, 'ContactUser.LastName', ''),
            Email: get(cfg, 'ContactUser.Email', ''),
            Phones: get(cfg, 'ContactUser.Phones', []),
          },
        }),
    ...(logoAttachment ? { LogoUrl: logoAttachment } : {}),
    ...(!isUpdate && { InviteStatus: get(cfg, 'IsInviteLaterOrNow') }),
    ...(Array.isArray(RecommendedByAgentsId) && { RecommendedByAgentsId }),
    DirectoryPreference: get(cfg, 'DirectoryPreference'),
  };
};

export const getAgentPartnerSendData = (cfg) => {
  return {
    BusinessName: get(cfg, 'BusinessName'),
    Email: get(cfg, 'Email'),
    PartnerCategoryIds: get(cfg, 'PartnerCategoryIds', []).map(({ value }) => value),
  };
};

export const getCategorySendData = (cfg, isUpdate) => {
  const icon = get(cfg, 'Icon');
  const iconAttachment = icon && typeof icon === 'object' ? preparedAttachment(icon) : null;

  return {
    Title: get(cfg, 'Title', ''),
    Type: get(cfg, 'Type', ''),
    QuoteFormId: get(cfg, 'QuoteFormId') || undefined,
    ...(isUpdate ? { IsActive: get(cfg, 'IsActive') } : {}),
    ...(iconAttachment ? { Icon: iconAttachment } : {}),
    Tags: get(cfg, 'Tags', []),
  };
};

export const getAllFormsList = (action, method, cfg, options, cb) => {
  const sendRequest = Api.execBase({ action, method });
  return sendRequest(cfg, options, cb);
};

export const getAllItems = (action, method, options, cb, sort, pageOption, search) => {
  const requestParams = { action, method };
  let sendRequest = Api.execBase(requestParams);
  const { fields, order } = sort;

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }
  const config = {
    sortField: fields[0],
    sortOrder: order?.toLowerCase(),
  };

  if (search && !isEmpty(search)) {
    Object.entries(search).forEach(([prop, value]) => {
      if (value !== '' && value !== null && value !== undefined) config[prop] = value;
    });
  }

  return sendRequest(config, options, cb);
};

export const getItemById = (action, method, cfg, options, cb) => {
  const sendRequest = Api.execBase({ action, method });
  return sendRequest(cfg, options, cb);
};

export const getServiceTagSendData = (cfg, isUpdate) => {
  return {
    Name: get(cfg, 'Name', ''),
    Type: get(cfg, 'Type', ''),
  };
};
