import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import SearchLine from '../../SearchLine';

const Searches = (props) => {
  const { className, popupPosition, list, onDelete, onEdit, onSearch } = props;

  return (
    <div className={classNames(styles.searches, className)}>
      {list?.length ? (
        list.map(({ id, title, info, criterias, photos }) => (
          <SearchLine
            photos={photos}
            key={id}
            title={title}
            info={info}
            popupPosition={popupPosition}
            onDelete={() => onDelete(id)}
            onEdit={() => onEdit(id)}
            onSearch={() => onSearch(criterias)}
          />
        ))
      ) : (
        <div testid="no_saved_searches" className={styles.noSaved}>
          No saved searches
        </div>
      )}
    </div>
  );
};

Searches.propTypes = {
  className: PropTypes.string,
  popupPosition: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onSearch: PropTypes.func,
};

Searches.defaultProps = {
  className: '',
  popupPosition: undefined,
  list: [],
  onDelete: () => {},
  onEdit: () => {},
  onSearch: () => {},
};

export default Searches;
