import { Button, Input, Modal } from 'components-antd';
import styles from './styles.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { getFormattedDateTime } from 'pages/Workshop/Tours/helper';
import { requestCancelScheduleTourEffect, requestScheduleToursEffect } from 'store/effects/tour';
import { Tour } from 'types/tours';
import { getTeamTransactionsSelector } from 'store/selectors/transactions';
import { TourStatus } from 'app-constants/enums/tours';
import { useState } from 'react';

export const CancelModal = (props) => {
  const {
    showModal,
    onCancel,
    onSubmit,
    tour,
  }: { showModal: boolean; onCancel: any; onSubmit: any; tour: Tour } = props;
  const [notes, setNotes] = useState('');
  const dispatch = useDispatch();
  const teamTransactions = useSelector(getTeamTransactionsSelector);

  const onSubmitCancelTours = () => {
    dispatch(requestCancelScheduleTourEffect({ UUID: tour.UUID, ClientNotes: notes || undefined }));
    dispatch(requestScheduleToursEffect({ EntireTeam: teamTransactions }));
    onSubmit();
  };

  return (
    <Modal
      open={showModal}
      width={360}
      footer={null}
      className={styles.cancelToursModal}
      closable={false}
      destroyOnClose={true}
    >
      <div className={styles.modalBody}>
        <h3 className={styles.title}>
          Are you sure you want to {tour.Status === TourStatus.Requested ? 'discard' : 'cancel'}{' '}
          this tour?
        </h3>
        <p className={styles.tourDetail}>
          {tour.PropertyAddress.Line2
            ? `${tour.PropertyAddress.Line1} ${tour.PropertyAddress.Line2},`
            : `${tour.PropertyAddress.Line1},`}
          <span>
            {' '}
            {getFormattedDateTime(
              tour?.ScheduleDateTime || tour.CreatedDate,
              'MM/DD/YYYY, hh:mm A',
            )}
          </span>
        </p>
        <div className={styles.fieldWrap}>
          <label>Note</label>
          <Input
            className={styles.input}
            type="text"
            placeholder="Write a reason for declining..."
            value={notes}
            onChange={(e) => setNotes(e.target.value.slice(0, 250))}
          />
        </div>
        <div className={styles.modalActions}>
          <Button
            variant="primary"
            className={classNames(styles.button, styles.submit)}
            onClick={onSubmitCancelTours}
          >
            Yes
          </Button>
          <Button
            variant="default"
            className={classNames(styles.button, styles.reject)}
            onClick={onCancel}
          >
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};
