import { groupBy } from 'lodash-es';
import { quotesStatusesMap } from 'settings/constants/quotes';

export const getDetailedQuotesByStatus = (groupedQuotes, groupId, userRole) => {
  if (!groupedQuotes) return [];

  const categoryData = groupedQuotes.find((item) => item?.name === groupId);
  const categoryQuotes = categoryData?.quotes || [];
  return groupBy(categoryQuotes, (item) => quotesStatusesMap(userRole)[item?.Status]);
};
