import { Icons } from '../../../Icons';
import { EditStageInfo } from '../EditStageInfo';

import styles from '../styles.module.scss';

export const EditFeedback = ({ data, onEdit }) => {
  const getInfo = () => {
    const { RequestFeedback, AutomaticallySendFeedback } = data;

    let text = '';
    if (RequestFeedback) {
      text += 'Request Feedback';
    }

    if (AutomaticallySendFeedback) {
      const info = 'Send feedback to sellers automatically';
      text += text ? `, ${info}` : info;
    }

    return text ? text : '-';
  };

  return (
    <div className={styles.editForm}>
      <EditStageInfo icon="feedback" title="Feedback" info={getInfo()} onClick={onEdit} />
    </div>
  );
};
