import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep } from 'lodash-es';

import { Wrapper } from 'components';
import {
  getAllTransactionTemplatesEffect,
  getAllTimelineTemplatesEffect,
} from 'store/effects/templates';
import {
  getTransactionTemplatesDataSelector,
  getTransactionTemplatesSelector,
  getTimelineTemplatesDataSelector,
  getTimelineTemplatesSelector,
} from 'store/selectors/templates';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import NoTemplatesFound from '../NoTemplates';
import Template from './Template';

import styles from './styles.module.scss';
import { Input } from 'components-antd';
import SearchGlass from 'components/Icons/SearchGlass';

const Templates = (props) => {
  const isProject = location.pathname.includes('project');

  const { className, onPreview, onSelect, isTransactionTemplate, listRef } = props;
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const templateValues =
    cloneDeep(
      preForm?.[
        isTransactionTemplate
          ? transactionPreFormQuestionsIds.transactionTemplate
          : transactionPreFormQuestionsIds.timeline
      ],
    ) || [];
  const dispatch = useDispatch();

  const templates = useSelector(
    isTransactionTemplate ? getTransactionTemplatesDataSelector : getTimelineTemplatesDataSelector,
  );
  const { isPending, isIdle } = useSelector(
    isTransactionTemplate ? getTransactionTemplatesSelector : getTimelineTemplatesSelector,
  );

  const [searchTerm, setSearchTerm] = useState('');

  const getTemplateEffect = () =>
    isTransactionTemplate ? getAllTransactionTemplatesEffect : getAllTimelineTemplatesEffect;

  useEffect(() => {
    if (isIdle) {
      dispatch(getTemplateEffect()({ isProject }));
    } else {
      dispatch(getTemplateEffect()({ isProject }, { silent: true }));
    }
  }, []); //eslint-disable-line

  const templatesData = useMemo(() => {
    if (searchTerm) {
      return templates?.filter((templateItem) =>
        templateItem.Name?.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    return templates || [];
  }, [searchTerm, templates]);

  return (
    <>
      <div className={styles.searchWrapper}>
        <Input
          size="large"
          value={searchTerm}
          onChange={(event) => {
            const { value } = event.target;
            if ((!searchTerm && value.trim().length) || searchTerm) {
              setSearchTerm(value);
            }
          }}
          className={styles.inputWrap}
          placeholder="Search"
          prefix={<SearchGlass color="#515151" className={styles.icon} />}
        />
      </div>

      <Wrapper
        isPending={isPending}
        className={classNames(styles.templates, className)}
        ref={listRef}
      >
        {templatesData?.length ? (
          templatesData.map((template, index) => (
            <Template
              key={template?.Id || index}
              name={template?.Name}
              description={template?.Description}
              propertyType={template?.PropertyType}
              financing={template?.Financing}
              selected={templateValues}
              onPreview={(previewId) => onPreview(previewId, listRef)}
              onSelect={onSelect}
              templateId={template?.Id}
              className={isTransactionTemplate ? styles.template : ''}
            />
          ))
        ) : (
          <NoTemplatesFound
            className={styles.noTemplates}
            templatesName={isProject ? 'project' : 'transaction'}
          />
        )}
      </Wrapper>
    </>
  );
};

Templates.propTypes = {
  className: PropTypes.string,
  onPreview: PropTypes.func,
  onSelect: PropTypes.func,
  isTransactionTemplate: PropTypes.bool,
};

Templates.defaultProps = {
  className: '',
  onPreview: () => {},
  onSelect: () => {},
  isTransactionTemplate: false,
};

export default Templates;
