import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PENDING } from 'settings/constants/apiState';
import { onBoardingSignUpEffect } from 'store/effects';
import { getUserDataSelector, isPartnerSelector } from 'store/selectors/user';
import { Question } from 'pages/RequestQuote/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { OnboardingContinueButton } from '../../agent/components/OnboardingContinueButton';
import { Content } from './Content';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  onNext?: () => void;
  stageIndex?: number;
  Controls?: any;
};

type serviceList = {
  label: string;
  value: number;
  isChecked: boolean;
  isDeletable: boolean;
};

export const ServiceForm: React.FC<Props> = ({
  onNext = () => {},
  stageIndex,
  Controls = () => null,
}) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const user = useSelector(getUserDataSelector);
  const isPartner = useSelector(isPartnerSelector);

  const getInitCategoryTags = () => {
    let tags: serviceList[] = [];
    const categoryTags = user.ThirdParty?.Partner?.PartnerCategories?.map(({ Tags }) => Tags) || [];
    const partnerTags = user.ThirdParty?.Partner?.PartnerTag || [];

    categoryTags?.forEach((tagList) => {
      if (tagList.length) {
        tagList.map(({ Name, Id }) => {
          let existing = partnerTags.some((service) => service.Id === Id);
          existing
            ? tags.push({ label: Name, value: Id, isChecked: true, isDeletable: false })
            : tags.push({ label: Name, value: Id, isChecked: false, isDeletable: false });
        });
      }
    });

    if (partnerTags.length) {
      partnerTags.map(({ Name, Id }) => {
        let existing = tags.some((service) => service.value === Id);
        if (!existing) tags.push({ label: Name, value: Id, isChecked: true, isDeletable: true });
      });
    }

    return tags;
  };

  const [serviceList, setServiceList] = useState<serviceList[]>(getInitCategoryTags());

  const handleSubmit = (e) => {
    const PartnerServices = serviceList
      .filter(({ isChecked }) => isChecked)
      .map(({ value, isChecked }) => {
        if (isChecked) {
          return value;
        }
      });

    dispatch(
      onBoardingSignUpEffect(
        { PartnerServices, stageIndex },
        { isPartner, isCommonInfo: true },
        (err) => {
          if (!err) {
            onNext();
          }
        },
      ),
    );
  };

  const isPending = onBoarding.state === PENDING;

  return (
    <OnBoardingWrapper className={styles.stageWrap}>
      <Controls onNext={handleSubmit} className={styles.controls} variant="lightFull" />
      <OnBoardingContainer>
        <Question>What services do you offer?</Question>
        <form onSubmit={handleSubmit}>
          <AnswersContainer>
            <Content serviceList={serviceList} setServiceList={setServiceList} />
          </AnswersContainer>
          <ButtonsContainer>
            <OnboardingContinueButton
              htmlType="submit"
              testid="next"
              title="Continue"
              isPending={isPending}
              className={styles.submitBtn}
              onClick={handleSubmit}
            />
          </ButtonsContainer>
        </form>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};
