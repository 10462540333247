import React from 'react';

import { Avatar, Checkbox } from 'components';
import { getInitials } from 'utils';
import styles from './../styles.module.scss';

type ClientItemType = {
  client: any;
  selected: Number[];
  onChange: Function;
  clientsAssignedTo?: string;
};

export const ClientItem = ({ client, selected, onChange, clientsAssignedTo }: ClientItemType) => {
  return (
    <div className={styles.clientItem}>
      <div className={styles.leftSection}>
        <Avatar
          avatarClassName={styles.avatar}
          src={client?.AvatarUrl}
          placeholder={
            <div className={styles.avatarPlaceholder}>
              {getInitials(`${client.FirstName} ${client.LastName}`)}
            </div>
          }
        />
        <div>
          <p className={styles.clientName}>
            {client?.FirstName} {client?.LastName}
          </p>
          {clientsAssignedTo && (
            <p className={styles.assignedTo}>Assigned to {clientsAssignedTo}</p>
          )}
        </div>
      </div>
      <Checkbox
        checked={selected.includes(client?.Id)}
        className={styles.checkbox}
        onChange={(e) => onChange(e, client?.Id)}
      />
    </div>
  );
};
