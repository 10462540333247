import { useState, useRef } from 'react';
import classNames from 'classnames';

import { Lock, Shared, ArrowDown } from 'components/Icons';
import { useOutsideClick } from 'hooks';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import styles from './styles.module.scss';

export const PrivacyOptions = ({ className, isPrivate, onChange }) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const optionsRef = useRef(null);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleOptionClick = (e, value) => {
    e.stopPropagation();
    onChange(value);
    setOpen(false);
  };

  return (
    <div className={classNames(styles.optionsWrapper, className)}>
      <div testid="options" ref={buttonRef} onClick={onClickOptions} className={styles.optionsDots}>
        <Icon variant={Icon.DOTS} />
      </div>
      {open && (
        <div ref={optionsRef} className={styles.options}>
          <ul>
            <li
              testid="private_action"
              className={styles.item}
              onClick={(e) => handleOptionClick(e, true)}
            >
              <div className={styles.icon}>
                <Lock stroke="#FF576D" width="18" height="18" />
              </div>
              <span>Private</span>
            </li>
            <li
              testid="private_action"
              className={styles.item}
              onClick={(e) => handleOptionClick(e, false)}
            >
              <div className={styles.icon}>
                <Shared stroke={'#747475'} />
              </div>
              <span>Shared</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
