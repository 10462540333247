import { Space, Dropdown, Menu } from 'antd';
import { Delete } from 'components/Icons';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';

import styles from './styles.module.scss';
interface OptionsProps {
  isTransactionTask?: boolean | null;
  onRemove: (...args: any[]) => any;
}

export const Options = (props: OptionsProps) => {
  const { isTransactionTask, onRemove } = props;
  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <div className={styles.dropdownDelete} onClick={onRemove}>
              <Delete className={styles.icon} />
              <span>Delete</span>
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <>
      {isTransactionTask && (
        <div id="dropdown" className={styles.dropdownWrap}>
          <Dropdown
            overlay={menu}
            trigger={['click']}
            overlayClassName={styles.taskMoreOptions}
            getPopupContainer={() => document.getElementById('dropdown')!}
            placement="bottomRight"
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Icon variant={Icon.DOTS} className={styles.dropdownTrigger} />
              </Space>
            </a>
          </Dropdown>
        </div>
      )}
    </>
  );
};
