import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Photo from './Photo';
import Price from './Price';
import Info from './Info';
import AddressComponent from './Address';
import Status from './Status';
import AgentBlock from './AgentBlock';
import BuyerBlock from './BuyerBlock';
import { getUserRolesMapSelector } from 'store/selectors/user';

import { link } from 'settings/navigation/link';
import { openNewTab } from 'services/newTab';

import styles from './styles.module.scss';
import {
  getAllProperyImageProcessesSelector,
  getComparesSelector,
  getMultipleModSelector,
} from 'store/selectors/feed';
import { initiateImageAnalysisEffect, setComparesEffect } from 'store/effects';
import { initialImageAnalysisAction } from 'store/actions/feed';

const Property = (props) => {
  const {
    renderingIndex,
    shouldScrollIntoView,
    getPageNumber,
    className,
    threadsPerPropertyId,
    ...propertyInfo
  } = props;

  const { Id } = propertyInfo || {};
  const dispatch = useDispatch();
  const compares = useSelector(getComparesSelector);
  const multiple = useSelector(getMultipleModSelector);
  const imageProcesses = useSelector(getAllProperyImageProcessesSelector);

  const isChecked = compares?.data?.findIndex((property) => property?.Id === Id) !== -1;
  const { isAgent } = useSelector(getUserRolesMapSelector);

  const [shouldShowBorder, setShouldShowBorder] = useState(false);

  const propertyRef = useRef(null);

  const [nearestOpenHouse] = propertyInfo?.OpenHouses
    ? propertyInfo.OpenHouses.length
      ? propertyInfo.OpenHouses
      : [undefined]
    : [undefined];

  const onCheckHandler = () => {
    const propertyId = Id;
    if (!isChecked) {
      dispatch(setComparesEffect([...compares.data, propertyInfo]));
      if (!Object.keys(imageProcesses).includes(propertyId)) {
        dispatch(initialImageAnalysisAction({ data: { [Id]: false } })); //start with default false
        dispatch(initiateImageAnalysisEffect({ Id }));
      }
    } else {
      const newCompares = compares.data.filter((property) => property.Id !== propertyId);
      dispatch(setComparesEffect(newCompares));
    }
  };

  const redirectToDetails = (e) => {
    e.preventDefault();
    if (multiple && !(compares.data.length === 5 && !isChecked)) onCheckHandler();
    else if (!multiple) {
      let path = link.toSearchListingDetails(propertyInfo.Id);
      if (renderingIndex) {
        path += path.includes('?')
          ? `&renderingIndex=${renderingIndex}`
          : `?renderingIndex=${renderingIndex}`;
      }
      if (getPageNumber && getPageNumber()) {
        path += path.includes('?')
          ? `&pageNumber=${getPageNumber()}`
          : `?pageNumber=${getPageNumber()}`;
      }
      openNewTab(path);
    }
  };

  useEffect(() => {
    if (shouldScrollIntoView && shouldScrollIntoView()) {
      propertyRef?.current?.scrollIntoView({ behavior: 'smooth' });
      setShouldShowBorder(true);
    }
  }, []);

  return (
    <div
      className={classNames(styles.propertyWrap, {
        [styles.focusedProperty]: shouldShowBorder,
      })}
    >
      <div
        ref={propertyRef}
        testid="search_property"
        className={classNames(
          styles.property,
          {
            [styles.checked]: isChecked,
          },
          {
            [styles.pointerEventsNone]: compares.data.length === 5 && !isChecked,
          },
          className,
        )}
        onClick={redirectToDetails}
      >
        <Status
          className={styles.status}
          status={
            nearestOpenHouse
              ? `Open ${
                  nearestOpenHouse?.date !== 'Today'
                    ? nearestOpenHouse?.date?.slice(0, 3)
                    : nearestOpenHouse?.date
                }, ${nearestOpenHouse?.time?.replaceAll(':00', '')}`
              : propertyInfo?.ActivityStatus
          }
        />
        <Photo className={styles.photo} propertyInfo={propertyInfo} />
        <div className={styles.infoBlock}>
          <Price propertyInfo={propertyInfo} />
          <Info propertyInfo={propertyInfo} />
          <AddressComponent propertyInfo={propertyInfo} />

          <div className={styles.bottomBlock}>
            {isAgent ? (
              <AgentBlock
                threadsPerPropertyId={threadsPerPropertyId}
                propertyInfo={propertyInfo}
                Id={Id}
                isChecked={isChecked}
              />
            ) : (
              <BuyerBlock
                threadsPerPropertyId={threadsPerPropertyId}
                propertyInfo={propertyInfo}
                Id={Id}
                isChecked={isChecked}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Property.propTypes = {
  className: PropTypes.string,
  threadsPerPropertyId: PropTypes.shape({}).isRequired,
  renderingIndex: PropTypes.number,
  getPageNumber: PropTypes.func,
  shouldScrollIntoView: PropTypes.func,
};

Property.defaultProps = {
  className: '',
  renderingIndex: 0,
  getPageNumber: () => 0,
  shouldScrollIntoView: () => false,
};

export default Property;
