import { useEffect } from 'react';
import { Button } from 'components-antd';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Icon from 'pages/Properties/Feed/Icons';
import { routes } from 'settings/navigation/routes';
import { propertiesTabsIds, propertiesTabs } from 'settings/constants/properties';
import { CLIENT } from 'settings/constants/roles';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getSearchInstancesFormattedSelector } from 'store/selectors/mySearches';
import { getSearchInstancesEffect } from 'store/effects/mySearches';
import { resetSearchCriteriaEffect } from 'store/effects/search';
import { setEditedSearchCriteriaAction } from 'store/actions/mySearches';
import { openSearchResultsFilterDrawerEffect } from 'store/effects';
import { EDIT } from 'settings/constants/mode';

import styles from './styles.module.scss';

export const UpdateWishList = () => {
  const { isClient } = useSelector(getUserRolesMapSelector);
  const { data, isLoading, isData, hasSoftCriterias } = useSelector(
    getSearchInstancesFormattedSelector,
  );
  const searchContext = useSelector(getCurrentContextSelector);
  const cfg = { contextKey: searchContext?.ContextKey };
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isClient && searchContext) dispatch(getSearchInstancesEffect(cfg));
  }, [searchContext]);

  const editWishlistHandler = () => {
    const search = data?.find((instance) => instance.status === 'Active') || data?.[0];
    if (search) {
      dispatch(resetSearchCriteriaEffect());
      dispatch(setEditedSearchCriteriaAction(search));
      dispatch(openSearchResultsFilterDrawerEffect({ open: true, mode: EDIT }));
      history.push(routes.searchResults + `?searchInstanceId=${search.id}`);
    }
  };

  const getHeading = () => {
    if (hasSoftCriterias) {
      return 'No recommended properties';
    }

    return `${isData ? 'Update' : 'Add'} your preferences`;
  };

  const getSubHeading = () => {
    if (hasSoftCriterias) {
      return 'There are no properties with a match of 50% or greater based on your current wish list.';
    }

    return isData
      ? 'To see recommended properties, please update your wish list.'
      : 'No searches are active needed to recommend. Please visit "My Searches" page.';
  };

  return (
    <div className={styles.updatePrefsBlock}>
      <h2 className={styles.updatePrefsHeading}>{getHeading()}</h2>
      <span className={styles.updatePrefsText}>{getSubHeading()}</span>
      {isData ? (
        <Button
          variant="primary"
          className={styles.criteriaBtn}
          onClick={editWishlistHandler}
          disabled={isLoading || !data?.length}
        >
          <Icon variant={Icon.FILTER} className={styles.filterIcon} />
          <span className={styles.btnText}>Edit your wish list</span>
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};
