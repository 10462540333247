import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'components-antd';
import { dynamicManager } from '../DynamicManager';
import { getDynamicFormSelector } from 'store/selectors/requestFormProcess';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { FORM_PROCESS_SCREEN } from 'app-constants';
import { DeclineButton } from './DeclineButton';

export const DynamicFooter = () => {
  const { hideFooter, isLoading, screen, allowRegress } = useSelector(getDynamicFormSelector);

  const isSignScreen = screen === FORM_PROCESS_SCREEN.Sign;

  const [disabled, setDisabled] = useState(false);

  const showDecline = !allowRegress && isSignScreen;

  const isBundleForm = dynamicManager.isBundleForm();
  const isBundleTemplate = dynamicManager.isBundleTemplate();

  const handleClick = () => {
    dynamicManager.handleContinue(setDisabled);
  };

  const isDisabled = disabled || isLoading;

  return (
    <>
      {!hideFooter && (
        <div
          className={classNames(styles.footerContainer, {
            [styles.declineDynamicFooterContainer]: showDecline,
          })}
        >
          {showDecline && <DeclineButton />}

          <Button
            variant="secondary"
            className={classNames(styles.button, { [styles.disabledButton]: isDisabled })}
            disabled={isDisabled}
            onClick={isLoading ? undefined : handleClick}
            loading={(isBundleForm && isSignScreen && disabled) || (isBundleTemplate && disabled)}
          >
            {dynamicManager.isTemplateForm() ? 'Save' : 'Continue'}
          </Button>
        </div>
      )}
    </>
  );
};
