import {
  deleteTimelineTemplate,
  getTimelineTemplateById,
  postTimelineTemplate,
  putTimelineTemplate,
} from 'api/templates';
import { get, orderBy } from 'lodash-es';
import {
  requestGetTimelineTemplateAction,
  requestPostTimelineTemplateAction,
  requestPutTimelineTemplateAction,
  setTimelineTemplateDrawerOpenedAction,
  requestDeleteTimelineTemplateAction,
} from 'store/actions/template';
import Api from 'store/effects/core/api';
import { getAllTimelineTemplatesEffect } from 'store/effects/templates';
import { v4 as uuidv4 } from 'uuid';

export const getTimelineTemplateEffect = ({ id }, options, cb) => {
  const requestParams = {
    action: requestGetTimelineTemplateAction,
    method: getTimelineTemplateById,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest({ id }, options, cb);
};

export const setTimelineTemplateDrawerOpenedEffect = (isOpened, params) => (dispatch) => {
  dispatch(
    setTimelineTemplateDrawerOpenedAction({
      isDrawerOpened: isOpened,
      drawerParams: params || {},
    }),
  );
};

const getTimelineTemplateSendData = ({
  title,
  milestones,
  description,
  transactionTemplateId,
  People,
  Teams,
  Locations,
}) => ({
  Name: title || undefined,
  Description: description || undefined,
  Milestones: milestones?.length
    ? milestones.map((m) => ({
        Title: get(m, 'Title') || undefined,
        Offset: get(m, 'DueDate') || m?.DueDate == 0 ? +m.DueDate : get(m, 'Offset'),
        Initial: get(m, 'Initial'),
        Operator: get(m, 'Operator'),
        ControlOperatorOffset: get(m, 'ControlOperatorOffset') || undefined,
        IsPrivate: get(m, 'IsPrivate') || undefined,
        PublicId: get(m, 'PublicId', uuidv4()),
      }))
    : [],
  People,
  Teams,
  Locations: Locations,
  ...(transactionTemplateId ? { TransactionTemplateId: transactionTemplateId } : {}),
});

export const postTimelineTemplateEffect = (data, options, cb) => {
  const requestParams = { action: requestPostTimelineTemplateAction, method: postTimelineTemplate };
  const sendRequest = Api.execBase(requestParams);

  const sendData = getTimelineTemplateSendData(data);

  return sendRequest(sendData, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllTimelineTemplatesEffect({}, { silent: true }));
    }
    if (cb) cb(err, response, dispatch);
  });
};

export const putTimelineTemplateEffect = (data, options, cb) => {
  const requestParams = { action: requestPutTimelineTemplateAction, method: putTimelineTemplate };
  const sendRequest = Api.execBase(requestParams);

  const sendData = getTimelineTemplateSendData(data);

  return sendRequest(sendData, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllTimelineTemplatesEffect({}, { silent: true }));
    }
    if (cb) cb(err, response, dispatch);
  });
};

export const deleteTimelineTemplateEffect = ({ id }, options, cb) => {
  const requestParams = {
    action: requestDeleteTimelineTemplateAction,
    method: deleteTimelineTemplate,
  };
  const sendRequest = Api.execBase(requestParams);

  return sendRequest({ id }, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllTimelineTemplatesEffect({}, { silent: true }));
    }
    if (cb) cb(err, response, dispatch);
  });
};
