import classNames from 'classnames';
import { useState } from 'react';
import { SketchPicker } from 'react-color';

import { Tooltip } from 'components-antd';

import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { updateDynamicFormQuestionEffect } from 'store/effects/formProcess';

interface ColorPickerProps {
  colorPickerClass?: string;
  containerClass?: string;
  sketchPadProps?: any; // Sketch Pad has no type definition of props
  defaultColor?: any;
  onColorUpdate?: (color) => void;
  field?: any;
  fieldActionProps?: any;
  selectedFieldIndex?: number;
}

export const StrokeColorPopover = ({
  colorPickerClass,
  defaultColor,
  field,
  selectedFieldIndex,
  fieldActionProps,
}: ColorPickerProps) => {
  const dispatch = useDispatch();
  const [color, setColor] = useState(defaultColor);

  const handleColorChange = () => {
    if (color.rgb) {
      const newField = { ...field, strokeColor: color.rgb };

      dispatch(
        updateDynamicFormQuestionEffect({
          ...newField,
          index: selectedFieldIndex,
          ...fieldActionProps,
        }),
      );
    }
  };

  return (
    <div className={styles.strokePopoverWrapper} onClick={(e) => e.stopPropagation()}>
      <Tooltip
        title={
          <SketchPicker
            className={classNames(styles.sketchPad, colorPickerClass)}
            onChangeComplete={(e) => setColor(e)}
            color={color}
          />
        }
        onOpenChange={(open) => {
          if (!open) handleColorChange();
        }}
        color="white"
        overlayClassName={styles.strokeToolTipContainer}
        trigger={['click']}
      >
        <div className={styles.colorBoxContainer} onClick={(e) => e.stopPropagation()}>
          <div
            className={classNames(styles.colorBox)}
            style={{
              background: color?.hex
                ? color.hex
                : `rgb(${color?.r || 0},${color?.g || 0},${color?.b || 0})`,
            }}
          />
        </div>
      </Tooltip>
    </div>
  );
};
