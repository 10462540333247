import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { PropertySlider } from './PropertySlider';
import { TimelineCard } from './TimelineCard';
import { ITimelineDay, TimelineType } from './TimelineCard/types';
import { useSelector, useDispatch } from 'react-redux';

import styles from './styles.module.scss';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getUserId } from 'store/selectors/user';
import {
  getClientDashboardNewMatchesEffect,
  getClientDashboardAgentSuggestionsEffect,
  getClientDashboardAgentCommentsEffect,
  getClientDashboardOpenHousesEffect,
  getClientDashboardPriceReductionsEffect,
  getClientDashboardBackOnMarketEffect,
} from 'store/effects/clientDashboard';
import {
  getClientDashboardNewMatchesSelector,
  getClientDashboardAgentSuggestionsSelector,
  getClientDashboardAgentCommentsSelector,
  getClientDashboardBackOnMarketsSelector,
  getClientDashboardPriceReductionsSelector,
  getClientDashboardOpenHousesSelector,
} from 'store/selectors/clientDashboard';
import { requestScheduleToursEffect } from 'store/effects/tour';
import { getScheduledTours } from 'store/selectors/tour';
import { TourStatus } from 'app-constants/enums/tours';
import moment from 'moment';
import { openMessagesDrawerAction } from 'store/actions/drawers/messages';
import { sortObjectByDateKeys } from 'helpers';

const subFilterTypes = {
  ALL: 'All',
  NEW: 'New',
  RECOMMENDED: 'Recommended',
  OPEN_HOUSES: 'OpenHouses',
  PRICE_REDUCTIONS: 'PriceReductions',
  BACK_ON_THE_MARKET: 'BackOnTheMarket',
  STATUS_CHANGES: 'StatusChanges',
  SUGGESTIONS: 'Suggestions',
};

const newMatchesMeta = {
  title: 'New Matches',
  viewAllRoute: `/properties/feed?subFilter=${subFilterTypes.NEW}`,
  properties: [],
};

const agentHighlightsMeta = {
  title: 'Agent Highlights',
  viewAllRoute: `/properties/feed?subFilter=${subFilterTypes.RECOMMENDED}`,
  properties: [],
};

const openHousesMeta = {
  title: 'Open Houses',
  viewAllRoute: `/properties/feed?subFilter=${subFilterTypes.OPEN_HOUSES}`,
  properties: [],
};

const priceReductionMeta = {
  title: 'Price Reductions',
  viewAllRoute: `/properties/feed?subFilter=${subFilterTypes.PRICE_REDUCTIONS}`,
  properties: [],
};

const backOnMarketMeta = {
  title: 'Back on the market',
  viewAllRoute: `/properties/feed?subFilter=${subFilterTypes.BACK_ON_THE_MARKET}`,
  properties: [],
};

const newComments = {
  title: 'New Comments',
  viewAllRoute: '#',
  properties: [],
};

export const ClientSearchDashboard: React.FC<{ dataLoaded: MutableRefObject<boolean> }> = ({
  dataLoaded,
}) => {
  const dispatch = useDispatch();
  const selectedContext = useSelector(getCurrentContextSelector);
  const clientId = useSelector(getUserId);

  const newMatches = useSelector(getClientDashboardNewMatchesSelector);
  const newMatchesLoading = newMatches?.state === 'pending';

  const agentSuggestions = useSelector(getClientDashboardAgentSuggestionsSelector);
  const agentSuggestionsLoading = agentSuggestions?.state === 'pending';

  const agentComments = useSelector(getClientDashboardAgentCommentsSelector);
  const agentCommentsLoading = agentComments?.state === 'pending';

  const backOnMarket = useSelector(getClientDashboardBackOnMarketsSelector);
  const backOnMarketLoading = backOnMarket?.state === 'pending';

  const priceReductions = useSelector(getClientDashboardPriceReductionsSelector);
  const priceReductionsLoading = priceReductions?.state === 'pending';

  const openHouses = useSelector(getClientDashboardOpenHousesSelector);
  const openHousesLoading = openHouses?.state === 'pending';

  const { tours } = useSelector(getScheduledTours);

  useEffect(() => {
    if (clientId && selectedContext.ContextKey) {
      dispatch(
        getClientDashboardNewMatchesEffect(
          {
            clientId,
            contextId: selectedContext.ContextKey,
          },
          {
            silent: dataLoaded.current,
          },
        ),
      );
      dispatch(
        getClientDashboardAgentSuggestionsEffect(
          {
            clientId,
            contextId: selectedContext.ContextKey,
          },
          {
            silent: dataLoaded.current,
          },
        ),
      );
      dispatch(
        getClientDashboardAgentCommentsEffect(
          {
            clientId,
            contextId: selectedContext.ContextKey,
          },
          {
            silent: dataLoaded.current,
          },
        ),
      );
      dispatch(
        getClientDashboardOpenHousesEffect(
          {
            clientId,
            contextId: selectedContext.ContextKey,
          },
          {
            silent: dataLoaded.current,
          },
        ),
      );
      dispatch(
        getClientDashboardPriceReductionsEffect(
          {
            clientId,
            contextId: selectedContext.ContextKey,
          },
          {
            silent: dataLoaded.current,
          },
        ),
      );
      dispatch(
        getClientDashboardBackOnMarketEffect(
          {
            clientId,
            contextId: selectedContext.ContextKey,
          },
          {
            silent: dataLoaded.current,
          },
        ),
      );
      dataLoaded.current = true;
    }
    dispatch(requestScheduleToursEffect());
  }, [clientId, selectedContext.ContextKey]);

  const upcomingTours = useMemo(
    () =>
      tours.filter(
        (tour) => tour.Status === TourStatus.Approved && moment(tour.ScheduleDateTime) > moment(),
      ),
    [tours],
  );

  const propertiesNewMatchesDataMeta = useMemo(() => {
    const transformedPropertiesMeta = {
      newMatchesMeta,
    };
    if (newMatches.data) {
      if (newMatches?.data?.length) {
        transformedPropertiesMeta.newMatchesMeta.properties = newMatches?.data?.map((prop) => ({
          id: prop?.propertyId,
          image: prop?.propertyPhotoUrls?.[0],
          address: prop?.propertyAddress?.Line1,
          subAddress: `${prop?.propertyAddress?.State} ${prop?.propertyAddress?.Zip} ${prop?.propertyAddress?.City}`,
          matchPercentage: `${prop?.matchScore?.MatchScore}%`,
          matchedCriteria: prop?.matchScore?.MatchedCriteria,
        }));
      }
    }
    return transformedPropertiesMeta;
  }, [newMatches]);

  const agentSuggestionsDataMeta = useMemo(() => {
    const transformedPropertiesMeta = {
      agentHighlightsMeta,
    };
    if (agentSuggestions?.data) {
      if (agentSuggestions?.data?.length) {
        transformedPropertiesMeta.agentHighlightsMeta.properties = agentSuggestions?.data.map(
          (prop) => ({
            id: prop?.propertyId,
            image: prop?.propertyPhotoUrls?.[0],
            address: prop?.propertyAddress?.Line1,
            subAddress: `${prop?.propertyAddress?.State} ${prop?.propertyAddress?.Zip} ${prop?.propertyAddress?.City}`,
            agentHighlights: `Highlighted by ${prop?.highlightedBy?.FirstName} ${prop?.highlightedBy?.LastName}`,
          }),
        );
      }
    }
    return transformedPropertiesMeta;
  }, [agentSuggestions]);

  const agentCommentsDataMeta = useMemo(() => {
    const transformedPropertiesMeta = {
      newComments,
    };
    if (agentComments?.data) {
      if (agentComments?.data?.length) {
        transformedPropertiesMeta.newComments.properties = agentComments?.data?.map((prop) => ({
          id: prop?.propertyId,
          image: prop?.propertyPhotoUrls?.[0],
          address: prop?.propertyAddress?.Line1,
          subAddress: `${prop?.propertyAddress?.State} ${prop?.propertyAddress?.Zip} ${prop?.propertyAddress?.City}`,
          commentsCount: prop?.messages?.length,
        }));
      }
    }
    return transformedPropertiesMeta;
  }, [agentComments]);

  const backOnMarketDataMeta = useMemo(() => {
    const transformedPropertiesMeta = {
      backOnMarketMeta,
    };
    if (backOnMarket.data) {
      if (backOnMarket?.data?.items?.length) {
        transformedPropertiesMeta.backOnMarketMeta.properties = backOnMarket?.data?.items.map(
          (prop) => ({
            id: prop?.Id,
            image: prop?.PhotoUrls?.[0],
            address: prop?.Address?.Line1,
            subAddress: `${prop?.Address?.State} ${prop?.Address?.Zip} ${prop?.Address?.City}`,
            ...(prop?.matchScore?.MatchScore &&
              prop?.matchScore?.MatchedCriteria && {
                matchPercentage: `${prop?.matchScore?.MatchScore}%`,
                matchedCriteria: prop?.matchScore?.MatchedCriteria,
              }),
          }),
        );
      }
    }
    return transformedPropertiesMeta;
  }, [backOnMarket]);

  const priceReductionsDataMeta = useMemo(() => {
    const transformedPropertiesMeta = {
      priceReductionMeta,
    };
    if (priceReductions?.data) {
      if (priceReductions?.data?.items?.length) {
        transformedPropertiesMeta.priceReductionMeta.properties = priceReductions?.data?.items.map(
          (prop) => ({
            id: prop?.Id,
            image: prop?.PhotoUrls?.[0],
            address: prop?.Address?.Line1,
            subAddress: `${prop?.Address?.State} ${prop?.Address?.Zip} ${prop?.Address?.City}`,
            priceReduction: {
              from: prop?.PreviousListPrice,
              to: prop?.ListPrice,
            },
          }),
        );
      }
    }
    return transformedPropertiesMeta;
  }, [priceReductions]);

  const openHousesDataMeta = useMemo(() => {
    const transformedPropertiesMeta = {
      openHousesMeta,
    };
    if (openHouses.data) {
      if (openHouses?.data?.items?.length) {
        transformedPropertiesMeta.openHousesMeta.properties = openHouses?.data?.items.map(
          (prop) => {
            const nearestOpenHouse = prop?.OpenHouses?.[0];
            const date = nearestOpenHouse?.Date;
            const startTime = nearestOpenHouse?.StartTime;
            const endTime = nearestOpenHouse?.EndTime;
            return {
              id: prop?.Id,
              image: prop?.PhotoUrls?.[0],
              address: prop?.Address?.Line1,
              subAddress: `${prop?.Address?.State} ${prop?.Address?.Zip} ${prop?.Address?.City}`,
              ...(nearestOpenHouse && {
                openHouses: date
                  ? `Open ${moment(date).format('MM/DD')} ${moment(startTime, 'HH:mm').format(
                      'h A',
                    )} - ${moment(endTime, 'HH:mm').format('h A')}`
                  : '',
              }),
            };
          },
        );
      }
    }
    return transformedPropertiesMeta;
  }, [openHouses]);

  const toursTimeLineData = useMemo(() => {
    const groupedTours = upcomingTours.reduce((acc, tour) => {
      const scheduleDateTime = moment(tour?.ScheduleDateTime);
      const today = moment();
      const scheduleDate = scheduleDateTime.isSame(today, 'day')
        ? `Today, ${scheduleDateTime.format('MMMM DD, YYYY')}`
        : scheduleDateTime.format('dddd, MMMM DD, YYYY');

      const scheduleTime = scheduleDateTime.format('h:mm A');
      const address = `${tour?.PropertyAddress?.Line1}`.trim();
      const event = {
        top: '',
        main: scheduleTime,
        sub: address,
        type: 'Tour',
      };

      if (!acc[scheduleDate]) {
        acc[scheduleDate] = [];
      }
      acc[scheduleDate].push(event);
      const sortedData = sortObjectByDateKeys(acc);
      return sortedData;
    }, {});

    return Object.entries(groupedTours).map(([key, value]) => ({
      heading: key,
      data: value,
    })) as ITimelineDay[];
  }, [upcomingTours]);

  const handleMessagesOpen = useCallback(() => {
    dispatch(openMessagesDrawerAction(true));
  }, [dispatch]);

  return (
    <div className={styles.mainContentWrapper}>
      <div className={styles.dashboardContainer}>
        <div className={styles.sliderColumn}>
          <div className={styles.sliderColumnInner}>
            <PropertySlider
              properties={propertiesNewMatchesDataMeta?.newMatchesMeta?.properties}
              title={propertiesNewMatchesDataMeta.newMatchesMeta.title}
              viewAllRoute={propertiesNewMatchesDataMeta.newMatchesMeta.viewAllRoute}
              loading={newMatchesLoading}
            />
            <PropertySlider
              properties={agentSuggestionsDataMeta?.agentHighlightsMeta?.properties}
              title={agentSuggestionsDataMeta.agentHighlightsMeta.title}
              viewAllRoute={agentSuggestionsDataMeta.agentHighlightsMeta.viewAllRoute}
              loading={agentSuggestionsLoading}
            />
            <PropertySlider
              properties={agentCommentsDataMeta?.newComments?.properties}
              title={agentCommentsDataMeta.newComments.title}
              viewAllRouteHandler={handleMessagesOpen}
              loading={agentCommentsLoading}
            />
            <PropertySlider
              properties={openHousesDataMeta?.openHousesMeta?.properties}
              title={openHousesDataMeta?.openHousesMeta.title}
              viewAllRoute={openHousesDataMeta?.openHousesMeta?.viewAllRoute}
              loading={openHousesLoading}
            />
            <PropertySlider
              properties={priceReductionsDataMeta?.priceReductionMeta?.properties}
              title={priceReductionsDataMeta?.priceReductionMeta?.title}
              viewAllRoute={priceReductionsDataMeta?.priceReductionMeta?.viewAllRoute}
              loading={priceReductionsLoading}
            />
            <PropertySlider
              properties={backOnMarketDataMeta?.backOnMarketMeta?.properties}
              title={backOnMarketDataMeta?.backOnMarketMeta.title}
              viewAllRoute={backOnMarketDataMeta?.backOnMarketMeta.viewAllRoute}
              loading={backOnMarketLoading}
            />
          </div>
        </div>
        <div className={styles.timelineColumn}>
          <TimelineCard
            timelineData={toursTimeLineData}
            viewAllRoute="/properties/tours"
            title="Upcoming Tours"
          />
        </div>
      </div>
    </div>
  );
};

export default ClientSearchDashboard;
