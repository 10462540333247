import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getThreadsPerPropertyIdSelector } from 'store/selectors/sockets/threads';
import {
  getFeedViewTypeSelector,
  getActiveTabSelector,
  getFeedPageInfoSelector,
  getPropertyToScrollToSelector,
} from 'store/selectors/feed';
import Property from 'pages/Properties/Feed/Properties/Property';
import { CreateSearch } from '../CreateSearch';
import { UpdateWishList } from '../UpdateWishList';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { MAP_VIEWS } from 'settings/constants/mapView';

import { NoMlsAccessAgentFiller } from 'features/mlsAccess/propertyListFiller/NoMlsAccessAgentFiller';
import { NoMlsAccessClientFiller } from 'features/mlsAccess/propertyListFiller/NoMlsAccessClientFiller';
import NoListings from '../NoListings';
import { addressToSentenceCase } from 'helpers';
import { propertiesTabsIds } from 'settings/constants/properties';

import styles from './styles.module.scss';
import Pagination from '../../../../../components/Pagination';
import { setFeedCurrentPageInfoAction } from '../../../../../store/actions/feed';

const FE_PAGE_SIZE = 40;
const MAX_PAGES = 9;

const PropertiesList = (props) => {
  const history = useHistory();
  const location = useLocation();

  const currentPageInfo = useSelector(getFeedPageInfoSelector);
  const propertyToScrollTo = useSelector(getPropertyToScrollToSelector);

  const {
    wrapperSize,
    list,
    propertyContainerClassName = '',
    propertyListWrapperClassName = '',
  } = props;
  const propertyListRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(currentPageInfo?.currentFEPageInfo?.pageNumber || 1);
  const [renderingIndex, setRenderingIndex] = useState(
    currentPageInfo?.currentFEPageInfo?.renderingIndex || -1,
  );
  const [forceRerenderPagination, setForceRerenderPagination] = useState(-1);

  const dispatch = useDispatch();
  // TODO: Why do we have this declared if it's not passed anywhere?
  const { isClient: isPropsClient } = props;
  const viewType = useSelector(getFeedViewTypeSelector);
  const isListView = viewType.view === MAP_VIEWS.LIST;
  const threadsPerPropertyId = useSelector(getThreadsPerPropertyIdSelector);
  const activeTab = useSelector(getActiveTabSelector);
  const { isClient } = useSelector(getUserRolesMapSelector);

  useEffect(() => {
    // reset the pagination state for feed after setting component state
    if (currentPageInfo?.currentFEPageInfo?.fromListingDetailPage) {
      dispatch(
        setFeedCurrentPageInfoAction({
          currentFEPageInfo: {
            pageNumber: 1,
            renderingIndex: -1,
            fromListingDetailPage: false,
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (propertyToScrollTo.propertyId && list) {
      for (let i = 1; i <= MAX_PAGES; i++) {
        const currentListings = list.slice((i - 1) * FE_PAGE_SIZE, i * FE_PAGE_SIZE);
        const selectedPropertyIndex = currentListings.findIndex(
          (property) => property?.Id === propertyToScrollTo.propertyId,
        );
        if (selectedPropertyIndex !== -1) {
          setPageNumber(i);
          setRenderingIndex(selectedPropertyIndex);
          setForceRerenderPagination(Math.ceil(Math.random() * 10000));
        }
      }
    }
  }, [propertyToScrollTo.propertyId]);

  const onPageChange = (pageNumber) => {
    setPageNumber(pageNumber + 1);
    propertyListRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const shouldScrollToProperty = (index) => {
    const shouldScroll = renderingIndex === index;
    setRenderingIndex(-1);
    return shouldScroll;
  };

  if (!props.hasMlsAccess) {
    return isClient ? <NoMlsAccessClientFiller /> : <NoMlsAccessAgentFiller />;
  }

  if (!list || !wrapperSize.width || !wrapperSize.height) return null;

  if (!list.length) {
    if (activeTab === propertiesTabsIds.all) return <CreateSearch />;
    else if (isClient && activeTab === propertiesTabsIds.recommended) return <UpdateWishList />;
    else return <NoListings activeTab={activeTab} />;
  }

  let currentListings = list.slice((pageNumber - 1) * FE_PAGE_SIZE, pageNumber * FE_PAGE_SIZE);

  const getSentenceCaseAddressForBright = (listings) =>
    listings?.map((listing) => {
      listing.Address.Line1 = addressToSentenceCase(listing.Address.Line1);
      listing.Address.City = addressToSentenceCase(listing.Address.City);
      return listing;
    });
  currentListings = getSentenceCaseAddressForBright(currentListings);

  return (
    <div
      key={forceRerenderPagination}
      ref={propertyListRef}
      className={classNames(propertyListWrapperClassName, styles.propertyListWrapper)}
    >
      <div
        className={classNames(
          propertyContainerClassName,
          styles.propertyContainer,
          {
            [styles.rowContainer]: isListView,
          },
          {
            [styles.listOnly]: isListView,
          },
        )}
      >
        {currentListings.map((item, index) => (
          <Property
            className={isListView ? styles.listView : ''}
            key={item.Id}
            {...item}
            isClient={isPropsClient}
            threadsPerPropertyId={threadsPerPropertyId}
            renderingIndex={index}
            getPageNumber={() => {
              return pageNumber;
            }}
            shouldScrollIntoView={() => shouldScrollToProperty(index)}
          />
        ))}
      </div>
      <Pagination
        limit={FE_PAGE_SIZE}
        onPageChange={onPageChange}
        items={list}
        pagesLimit={MAX_PAGES}
        pageAutoHandle={false}
        // this component makes pages from 0 index
        defaultPageNumber={pageNumber - 1}
      />
    </div>
  );
};

PropertiesList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  wrapperSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  getNextPageIsPending: PropTypes.func,
  rowHeight: PropTypes.number,
  isClient: PropTypes.bool,
  hasMlsAccess: PropTypes.bool,
  loadDataEffect: PropTypes.func,
};

PropertiesList.defaultProps = {
  className: '',
  getNextPageIsPending: () => {},
  rowHeight: 174,
  list: null,
  isClient: undefined,
  hasMlsAccess: true,
  loadDataEffect: () => {},
  scrollableContainerRef: null,
};

export default PropertiesList;
