import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { useOutsideClick } from 'hooks';
import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const Actions = (props) => {
  const { currentImportance, onChange, className } = props;
  const [open, setOpen] = useState(false);
  const actionsRef = useRef();
  const popupRef = useRef();

  useOutsideClick([actionsRef, popupRef], () => setOpen(false));

  const onClickOption = (e, id) => {
    e.stopPropagation();
    onChange(id);
  };

  return (
    <div
      ref={actionsRef}
      onClick={() => setOpen(!open)}
      className={classNames(styles.actions, className)}
    >
      <Icon testid="edit_icon" className={styles.edit} variant={Icon.EDIT} />
      {open && (
        <div ref={popupRef} className={styles.popup}>
          {Actions.options.map(({ id, label, testid }) => (
            <div
              key={id}
              onClick={(e) => onClickOption(e, id)}
              className={classNames(styles.option)}
              testid={testid}
            >
              <span>{label}</span>
              {currentImportance === id && (
                <Icon className={styles.icon} variant={Icon.CHECKMARK} />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Actions.options = [
  { id: SEARCH_CRITERIA_IMPORTANCE.MUST, label: 'Need', testid: 'need' },
  { id: SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT, label: 'Want', testid: 'want' },
  { id: SEARCH_CRITERIA_IMPORTANCE.UNDEFINED, label: 'No preference', testid: 'no_preference' },
];

Actions.propTypes = {
  className: PropTypes.string,
  currentImportance: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  className: '',
};

export default Actions;
