import { get } from 'lodash-es';
import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ adminPanel }) => adminPanel;

const teamDetailsState = createSelector(localState, ({ teamDetails }) => teamDetails);

export const getTeamDetailsDashboardSelector = createSelector(
  teamDetailsState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTeamDetailsDataDashboardSelector = createSelector(teamDetailsState, (state) =>
  get(state, 'data'),
);

export const getTeamAndOwnerDetailsSelector = createSelector(
  teamDetailsState,
  ({ onboarding }) => onboarding,
);

export const getTeamMembersSelector = createSelector(teamDetailsState, ({ members }) => members);

export const getTeamSeatsLogSelector = createSelector(teamDetailsState, ({ seatLogs }) => seatLogs);

export const getTeamMembersSearchSelector = createSelector(
  teamDetailsState,
  ({ membersSearch }) => membersSearch,
);

const getSearchMembers = (members, search) => {
  let result = (members || []).filter((data) => {
    if (search) {
      const formattedSearch = search.toLowerCase();
      let memberName = `${data?.MemberFirstName} ${data?.MemberLastName}`;
      let memberEmail = data?.MemberEmail;
      let role = data?.MemberRole;
      let title = data?.Title;
      let memberPhone = data?.MemberPhones?.find((phone) => phone?.IsPrimary)?.PhoneNumber;

      return (
        memberName?.toLowerCase()?.includes(formattedSearch) ||
        memberEmail?.toLowerCase()?.includes(formattedSearch) ||
        role?.toLowerCase()?.includes(formattedSearch) ||
        title?.toLowerCase()?.includes(formattedSearch) ||
        memberPhone?.toLowerCase()?.includes(formattedSearch)
      );
    }
    return true;
  });

  return result;
};

export const getMembersSelector = createSelector(
  getTeamMembersSelector,
  getTeamMembersSearchSelector,
  (initMembers, search) => {
    const members = getSearchMembers(initMembers, search);
    return members;
  },
);

export const getLoginLogsSelector = createSelector(teamDetailsState, ({ loginLogs }) => {
  return loginLogs.results;
});
