import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import moment from 'moment';
import styles from './styles.module.scss';

const DateTime = (props) => {
  const { className, value } = props;

  const getDateTime = () => {
    const momentDate = moment(value);
    const formattedDate = momentDate.format('M/D/YYYY');
    const formattedTime = momentDate.format('h:mm A');

    return (
      <>
        <h3>{formattedDate}</h3>
        <p>{formattedTime}</p>
      </>
    );
  };

  return (
    <div testid="doc_date" className={classNames(styles.dateTime, className)}>
      {getDateTime()}
    </div>
  );
};

DateTime.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

DateTime.defaultProps = {
  className: '',
  value: null,
};

export default DateTime;
