/* eslint-disable no-unsafe-optional-chaining */
import { get, uniqBy } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetActivityLogsAction,
  resetActivityLogsItemsListAction,
} from 'store/actions/activityLogsWorkshop';

const initialData = {
  state: IDLE,
  data: { items: [], total: 0 },
};
export default handleActions(
  {
    [requestGetActivityLogsAction]: (state, { payload }) => {
      const existingActivities = state?.data?.items;
      const result = get(payload, 'data.result', initialData.data);
      if (existingActivities?.length) {
        const records = uniqBy([...existingActivities, ...result?.items], 'Id');
        records.sort((a, b) => new Date(b.ActionTimestamp) - new Date(a.ActionTimestamp));

        return {
          ...state,
          state: payload.state,
          data: result ? { items: records, total: result?.total } : { items: [], total: 0 },
        };
      } else {
        return {
          ...state,
          state: payload.state,
          data: result ? { items: result?.items, total: result?.total } : { items: [], total: 0 },
        };
      }
    },
    [resetActivityLogsItemsListAction]: (state, { payload }) => {
      return {
        ...state,
        data: { items: [], total: 0 },
      };
    },
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
