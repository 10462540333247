import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, FormattedPhone, Wrapper } from 'components';
import { getHrefLink } from 'helpers';
import { getLoadingPartnerData, getPartnerViewSelector } from 'store/selectors/adminPanel';
import { PENDING } from 'settings/constants/apiState';
import { requestGetPartnerByIdEffect } from 'store/effects/adminPanel';
import { getLogoPlaceholder } from '../../helpers';

import styles from './styles.module.scss';

const ViewPartner = ({ id }) => {
  const dispatch = useDispatch();
  const partnerData = useSelector(getPartnerViewSelector);
  const { state } = useSelector(getLoadingPartnerData);
  const isPending = state === PENDING;

  useEffect(() => {
    if (id) {
      dispatch(requestGetPartnerByIdEffect({ id }));
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    BusinessName,
    AdditionalBusinessName,
    Description,
    Email,
    LogoUrl,
    PhoneNumber,
    Address,
    PartnerCategories,
    Website,
    IsActive,
    IsRecommendedByMosaik,
    ContactUser,
    RecommendingAgents,
    AreasOfOperation,
    PartnerTags,
  } = partnerData;

  const getContactUserView = () => (
    <div className={styles.contactUser}>
      <h3 className={styles.name}>Contact User</h3>
      {!!ContactUser.FirstName && (
        <div className={styles.inputBlock}>
          <span className={styles.label}>Name</span>
          <span className={styles.infoBlockForOneLine}>
            {ContactUser.FirstName}
            &nbsp;
            {ContactUser.LastName || ''}
          </span>
        </div>
      )}
      {!!ContactUser.Email && (
        <div className={styles.inputBlock}>
          <span className={styles.label}>Email</span>
          <span className={styles.infoBlockForOneLine}>{ContactUser.Email}</span>
        </div>
      )}
    </div>
  );

  return (
    <Wrapper isPending={isPending} className={styles.wrapper}>
      <div className={styles.leftBlock}>
        <Avatar
          src={LogoUrl}
          placeholder={getLogoPlaceholder(BusinessName)}
          className={styles.avatar}
        />
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.partner}>
          <div className={styles.flexWrapper}>
            <p className={styles.name}>
              {BusinessName}
              {!!AdditionalBusinessName && ` (${AdditionalBusinessName})`}
            </p>
            <div className={classNames(styles.flexWrapper, styles.markers)}>
              <div
                className={classNames(styles.marker, styles.isActiveMarker, {
                  [styles.deactivated]: !IsActive,
                })}
              >
                {IsActive ? 'Active' : 'Deactivated'}
              </div>
              {!!IsRecommendedByMosaik && (
                <div className={classNames(styles.marker, styles.isRecommendedMarker)}>
                  Recommended
                </div>
              )}
            </div>
          </div>
          {!!Description && <p className={styles.description}>{Description}</p>}
          {!!PartnerCategories?.length && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>
                {PartnerCategories.length === 1 ? 'Category' : 'Categories'}
              </span>
              <span className={styles.infoBlockForMultipleLines}>
                {PartnerCategories.join(', ')}
              </span>
            </div>
          )}
          {!!PartnerTags?.length && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>
                {PartnerTags.length === 1 ? 'Service' : 'Services'}
              </span>
              <span className={styles.infoBlockForMultipleLines}>
                {PartnerTags.map((tag) => tag?.Name).join(', ')}
              </span>
            </div>
          )}
          {!!Email && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Email</span>
              <span className={styles.infoBlockForOneLine}>{Email}</span>
            </div>
          )}
          {!!PhoneNumber && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Phone</span>
              <FormattedPhone className={styles.infoBlockForOneLine}>{PhoneNumber}</FormattedPhone>
            </div>
          )}
          {!!Address && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Address</span>
              <span className={styles.infoBlockForOneLine}>{Address}</span>
            </div>
          )}
          {!!AreasOfOperation?.length && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Geagraphic Areas Served</span>
              <span className={styles.infoBlockForMultipleLines}>
                {AreasOfOperation.join('; ')}
              </span>
            </div>
          )}
          {!!Website && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Website</span>
              <span className={styles.infoBlockForOneLine}>
                <a href={getHrefLink(Website)} target="_blank" rel="noreferrer">
                  {Website}
                </a>
              </span>
            </div>
          )}
          {!!RecommendingAgents?.length && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Recommends</span>
              <span className={styles.infoBlockForMultipleLines}>
                {RecommendingAgents.join(', ')}
              </span>
            </div>
          )}
        </div>
        {!!ContactUser && getContactUserView()}
      </div>
    </Wrapper>
  );
};

ViewPartner.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

ViewPartner.defaultProps = {};

export default ViewPartner;
