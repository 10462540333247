import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep } from 'lodash-es';
import { UploadDocuments as CommonUploadDocuments } from 'components/Transactions';
import { Button } from 'components';
import ItemWithCategory from './ItemWithCategory';
import ItemWithCategoryAndPermissions from './ItemWithCategoryAndPermissions';
import Item from './Item';
import ItemVault from './ItemVault';

import styles from './styles.module.scss';

const Uploaded = (props) => {
  const {
    className,
    files,
    onSave,
    isPending,
    onCloseModal,
    withCategory,
    isCategoryOptional,
    vaultFile,
    multiple,
    isPreapprovals,
  } = props;
  const { withPermissions, docCategoryName, dropzone, fileNameRequired, filesRequired } = props;
  const [filesValues, setFilesValues] = useState(fileNameRequired ? [] : files);

  useEffect(() => {
    if (files.length) {
      setFilesValues([
        ...files.map((file) => ({
          ...file,
          ...(file?.documentName ? {} : { documentName: file.filename }),
        })),
      ]);
    }
  }, [files]);

  const isOfferLink = () => {
    const linkMatch = window.location.href.match(/\/offer\/form\/([^/]+)/);
    return linkMatch;
  };
  const onDelete = (index) => {
    const clonedFiles = cloneDeep(filesValues);

    clonedFiles.splice(index, 1);
    setFilesValues(clonedFiles);

    if (!clonedFiles?.length && !vaultFile) {
      onCloseModal();
    }
  };

  const onChangeField = (val, index, fieldName, clearFieldName) => {
    if (
      !Object.prototype.hasOwnProperty.call(filesValues[index], 'documentName') &&
      fieldName === 'documentName' &&
      val
    ) {
      filesValues[index].documentName = val;
    }
    const clonedFiles = cloneDeep(filesValues);
    clonedFiles[index] = {
      ...(clonedFiles?.[index] || {}),
      [fieldName]: val,
      ...(clearFieldName ? { [clearFieldName]: undefined } : {}),
    };
    setFilesValues(clonedFiles);
  };

  const onChangeDocumentName = (val, index) => {
    onChangeField(val, index, 'documentName');
  };

  const onChangeType = (val, index) => {
    onChangeField(val, index, 'type');
  };

  const onChangeCustomTag = (val, index) => {
    if (val?.trim()) {
      onChangeField(val, index, 'customTag', 'category');
    }
  };

  const onChangeCategory = (val, index) => {
    onChangeField(val, index, 'category', 'customTag');
  };

  const onChangeReviewers = (val, index) => {
    onChangeField(val, index, 'reviewers');
  };

  const onSaveHandler = () => {
    onSave(filesValues);
  };

  const canContinue = () => {
    return filesValues.every((file) => {
      let result = !!file?.documentName;

      if (withCategory && !isCategoryOptional) {
        result = !!file?.documentName && (file?.customTag || file?.category);
      }

      if (vaultFile) {
        result =
          !!file?.documentName && !!file.type && (isPreapprovals || !!file.TransactionOrFolder);
      }

      return result;
    });
  };

  const addMoreFile = (data) => {
    if (multiple) {
      let cloneFiles = [...filesValues];
      data.map((element) => {
        cloneFiles.push({
          ...element,
          documentName: element.filename || '',
        });
      });
      setFilesValues(cloneFiles);
    } else {
      if (data?.length) {
        let cloneFiles = [{ ...data?.[0], documentName: data[0]?.filename || '' }];
        setFilesValues(cloneFiles);
      }
    }
  };

  const getItem = (file, index) => {
    if (vaultFile) {
      return (
        <ItemVault
          key={file?.id || index}
          {...file}
          onDelete={() => onDelete(index)}
          onChangeDocumentName={(e, val) => onChangeDocumentName(val, index)}
          onChangeType={(e, val) => onChangeType(val, index)}
          onChangeTransactionOrFolder={(e, val, key) =>
            onChangeField(val, index, 'TransactionOrFolder')
          }
          isPreapprovals={isPreapprovals}
        />
      );
    }
    if (withCategory && withPermissions && !isOfferLink()) {
      return (
        <ItemWithCategoryAndPermissions
          key={file?.id || index}
          {...file}
          onChangeDocumentName={(e, val) => onChangeDocumentName(val, index)}
          onDelete={() => onDelete(index)}
          onChangeTag={(val) => onChangeCategory(val, index)}
          onBlurTag={(val) => onChangeCustomTag(val, index)}
          onChangeReviewers={(val) => onChangeReviewers(val, index)}
        />
      );
    }
    if (withCategory && !isOfferLink()) {
      return (
        <ItemWithCategory
          key={file?.id || index}
          {...file}
          onChangeDocumentName={(e, val) => onChangeDocumentName(val, index)}
          onDelete={() => onDelete(index)}
          onChangeTag={(val) => onChangeCategory(val, index)}
          onBlurTag={(val) => onChangeCustomTag(val, index)}
        />
      );
    }
    return (
      <Item
        key={file?.id || index}
        {...file}
        docCategoryName={docCategoryName}
        onChange={(e, val) => onChangeDocumentName(val, index)}
        onDelete={() => onDelete(index)}
      />
    );
  };

  return (
    <div testid="uploaded" className={classNames(styles.uploaded, className)}>
      <div className={classNames(styles.inner, { [styles.withCategory]: withCategory })}>
        {(filesValues || []).map((file, index) => getItem(file, index))}
        <CommonUploadDocuments
          onSave={onSave}
          addMoreFile={addMoreFile}
          uploadButton={<></>}
          dropzone={dropzone}
          addMore={true}
          withCategory
          withPermissions
          isCategoryOptional
          multiple={multiple}
        />
      </div>

      <div className={styles.buttonHolder}>
        <Button
          key={filesValues.length}
          isPending={isPending}
          testid="save_button"
          onClick={onSaveHandler}
          title="Save"
          className={styles.saveButton}
          disabled={(fileNameRequired && !canContinue()) || (filesRequired && !filesValues.length)}
        />
      </div>
    </div>
  );
};

Uploaded.propTypes = {
  className: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  onCloseModal: PropTypes.func,
  onSave: PropTypes.func,
  isPending: PropTypes.bool,
  withCategory: PropTypes.bool,
  withPermissions: PropTypes.bool,
  isCategoryOptional: PropTypes.bool,
  docCategoryName: PropTypes.string,
  dropzone: PropTypes.bool,
  fileNameRequired: PropTypes.bool,
  filesRequired: PropTypes.bool,
  multiple: PropTypes.bool,
  vaultFile: PropTypes.bool,
  isPreapprovals: PropTypes.bool,
};

Uploaded.defaultProps = {
  className: '',
  files: [],
  onChange: () => {},
  onCloseModal: () => {},
  onSave: () => {},
  isPending: false,
  withCategory: true,
  multiple: true,
  withPermissions: false,
  isCategoryOptional: false,
  docCategoryName: '',
  dropzone: false,
  fileNameRequired: false,
  filesRequired: false,
  vaultFile: false,
  isPreapprovals: false,
};

export default Uploaded;
