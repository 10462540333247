import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { PENDING } from 'settings/constants/apiState';
import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';

import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';
import { Answers } from 'components';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';
import { Title, NextButton, Wrapper } from '../..';

import styles from './styles.module.scss';

const StageApproved = ({ onNext, Controls, stageIndex }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);

  const propName = OnBoardingWizard.propNames.isWantToBeApproved;
  const storedAnswer =
    getWizardStageValue({ obj: onBoarding, stageIndex, propName, signUpRole }) || [];
  const [stageValue, setStageValue] = useState(storedAnswer);

  const onNextCriteria = () => {
    if (stageValue.includes('no') || !stageValue.length) {
      return onNext('stageComplete');
    }

    return onNext();
  };

  const onSave = () => {
    const cfg = {
      stageIndex,
      DefaultPropertySearchPreferences: {
        [propName]: stageValue,
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, { withoutRequest: true }, (err) => {
        if (!err) {
          onNextCriteria();
        }
      }),
    );
  };

  const onAnswer = (val) => {
    setStageValue(val);
  };

  const isPending = onBoarding.state === PENDING;

  return (
    <Wrapper testid="approve_stage" className={styles.wrapper}>
      <div className={styles.inner}>
        <Title className={styles.title}>Would you like to get pre-approved now?</Title>
        <Answers
          cases={StageApproved.answerCases}
          onChange={onAnswer}
          value={stageValue}
          pending={isPending}
          testid="approve_answers"
          caseClassName={styles.answer}
          activeCaseClassName={styles.answerActive}
          labelClassName={styles.label}
          className={styles.answersWrapper}
        />
        <div className={styles.bottom}>
          <NextButton
            testid="must_have_next"
            onClick={onSave}
            isPending={isPending}
            disabled={!stageValue.length}
            iconArrow
          />
        </div>
      </div>
      <Controls
        onNext={onSave}
        isNextDisabled={!stageValue.length}
        className={styles.controls}
        variant="lightFull"
      />
    </Wrapper>
  );
};

StageApproved.answerCases = [
  { id: 'yes', label: 'Yes' },
  { id: 'no', label: 'No, take me to my feed' },
];

StageApproved.propTypes = {
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

StageApproved.defaultProps = {
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default StageApproved;
