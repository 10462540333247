import React, { SVGProps } from 'react';

type variantTypes = 'user' | 'search' | 'none';
type clearIconVariantType = 'clear' | 'none';
interface IIcons extends SVGProps<SVGSVGElement> {
  variant: variantTypes | clearIconVariantType;
}

export const Icons: React.FC<IIcons> = ({ variant, ...props }: IIcons) => {
  const renderIcon = () => {
    switch (variant) {
      case 'search': {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10.4444 15.8889C13.4513 15.8889 15.8889 13.4513 15.8889 10.4444C15.8889 7.43756 13.4513 5 10.4444 5C7.43756 5 5 7.43756 5 10.4444C5 13.4513 7.43756 15.8889 10.4444 15.8889Z"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9997 18.9997L14.333 14.333"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'user': {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8Z"
              stroke="#747475"
              strokeWidth="2"
            />
            <path
              d="M6 19.5C6.37394 17.6303 8.09315 16 9.99988 16H14C15.9067 16 17.6261 17.6303 18 19.5"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'clear': {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.5 14.5L14.5 5.5" stroke="#747475" strokeWidth="2" strokeLinecap="round" />
            <path d="M14.5 14.5L5.5 5.5" stroke="#747475" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      default:
        return <></>;
    }
  };
  return renderIcon();
};
