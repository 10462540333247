import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DrawerHeaderTitle, DrawerCancel } from 'components';
import { Icons } from '../../Icons';

import styles from './styles.module.scss';

export const Header = ({ title, className, onClose }) => (
  <div className={classNames(styles.header, className)}>
    <div className={styles.titleWrapper}>
      <Icons variant={'document'} />
      <DrawerHeaderTitle>{title}</DrawerHeaderTitle>
    </div>
    <DrawerCancel onClick={onClose} />
  </div>
);

Header.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

Header.defaultProps = {
  className: '',
  onClose: () => {},
  title: 'Pre-Approval Letters',
};
