import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const DownloadPdf = ({ className }) => (
  <div className={classNames(styles.download, className)}>
    <Icon testid="pdf" className={styles.icon} variant={Icon.PDF} />
  </div>
);

DownloadPdf.propTypes = {
  className: PropTypes.string,
};

DownloadPdf.defaultProps = {
  className: '',
};

export default DownloadPdf;
