export enum TemplateActionType {
  Task_Assign_Tasks = 'Task_Assign_Tasks',
  Task_Remove_Tasks = 'Task_Remove_Tasks',

  Form_Assign_Forms = 'Form_Assign_Forms',
  Form_Remove_Forms = 'Form_Remove_Forms',

  Milestone_Add_Milestones = 'Milestone_Add_Milestones',
  Milestone_Remove_Milestones = 'Milestone_Remove_Milestones',
  Milestone_Mark_Milestone_Complete = 'Milestone_Mark_Milestone_Complete',
}

export interface TemplateAction {
  Id?: number;

  TemplateRuleId?: number;
  Type: TemplateActionType;
  EntityIds?: string[];
  Order?: number;
}
