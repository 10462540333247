import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const DrawerHeaderTitle = ({ className, children }) => (
  <h3 testid="drawer_title" className={classNames(styles.title, className)}>
    {children}
  </h3>
);

DrawerHeaderTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

DrawerHeaderTitle.defaultProps = {
  className: '',
};

export default DrawerHeaderTitle;
