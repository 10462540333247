import { HTMLAttributes } from 'react';
import classNames from 'classnames';

interface MosaikLogoMobileProps extends HTMLAttributes<HTMLDivElement> {
  primary?: boolean;
}

const MosaikLogoMobile = ({ primary = true, className, ...props }: MosaikLogoMobileProps) => (
  <div {...props} className={classNames(className, 'prevent-user-select')}>
    <svg width="52" height="23" viewBox="0 0 52 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.1128 5.61745V22.0175H30.4686V6.33051C30.4686 5.15608 29.7839 4.44306 27.6443 4.44306C26.2321 4.44306 25.0767 5.19806 23.9213 6.16275V22.0175H15.1915V6.33051C15.1915 5.15608 14.5496 4.44306 12.41 4.44306C11.0834 4.44306 9.88519 5.11417 8.64419 6.12082V22.0175H0V0.542244H8.64419V4.48496C10.4843 2.09416 13.0947 0.122803 17.16 0.122803C20.7974 0.122803 23.2366 1.63278 23.7929 4.98829C25.5047 2.47166 28.329 0.122803 32.3515 0.122803C36.6736 0.122803 39.1128 2.01028 39.1128 5.61745Z"
        fill="#252D44"
      />
      <path
        d="M47.4051 13.0149L42.8125 17.5164L47.4051 22.0178L51.9977 17.5164L47.4051 13.0149Z"
        fill="#FF576D"
      />
    </svg>
  </div>
);

export default MosaikLogoMobile;
