import React, { memo, useMemo } from 'react';

const NewFolder = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div {...props}>
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83333 4.66672H10.5L14 8.16672H22.1667C22.7855 8.16672 23.379 8.41255 23.8166 8.85013C24.2542 9.28772 24.5 9.88121 24.5 10.5001V19.8334C24.5 20.4522 24.2542 21.0457 23.8166 21.4833C23.379 21.9209 22.7855 22.1667 22.1667 22.1667H5.83333C5.21449 22.1667 4.621 21.9209 4.18342 21.4833C3.74583 21.0457 3.5 20.4522 3.5 19.8334V7.00005C3.5 6.38121 3.74583 5.78772 4.18342 5.35013C4.621 4.91255 5.21449 4.66672 5.83333 4.66672"
            stroke="#2F80ED"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M14 11V19" stroke="#2F80ED" strokeWidth="2.5" />
          <path d="M10 14.9997H18" stroke="#2F80ED" strokeWidth="2.5" />
        </svg>
      </div>
    ),
    [props.className],
  );

export default memo(NewFolder);
