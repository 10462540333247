import classNames from 'classnames';
import { Dropdown, Menu, MenuItem, Popover, Radio } from 'components-antd';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';

import styles from './styles.module.scss';

export const MoreQuestionPopover = ({
  DragItem,
  questions,
  selectedRole,
  dragItemClass,
  dragContentClass,
}) => {
  const menu = (
    <Radio.Group className={styles.optionsItems}>
      <Menu>
        <div className={styles.insetText}>Insert</div>
        {questions.map((item, idx) => (
          <MenuItem key={idx}>
            <Radio value={item.roleId} key={idx}>
              <DragItem
                item={item}
                index={idx}
                color={selectedRole.color?.border}
                dragItemClass={dragItemClass}
                dragContentClass={dragContentClass}
              />
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.moreDropdownWrapper)}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        trigger={['click']}
        overlayClassName={styles.morePopover}
      >
        <div className={styles.moreQuestionsInformation}>
          <div className={styles.itemContent}>
            <Icons variant={ICON_VARIANT_TYPE.Dots} />
            <div className={styles.title}>More</div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
