import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { openFeedFilterDrawerEffect, setFeedDrawerCommuteIdEffect } from 'store/effects';
import {
  getFeedDrawerCommuteIdSelector,
  getIsFeedFilterDrawerOpenSelector,
} from 'store/selectors/feed';
import { Drawer } from 'components';
import Header from './Header';
import CommuteHeader from './CommuteHeader';
import AllSubFeedForm from './AllSubFeedForm';
import Commutes from './Tabs/Commutes';
import PrimaryCriteria from './Tabs/PrimaryCriteria';
import ImportantFeatures from './Tabs/ImportantFeatures';

import styles from './styles.module.scss';

const FilterDrawer = ({ className }) => {
  const dispatch = useDispatch();
  const open = useSelector(getIsFeedFilterDrawerOpenSelector);
  const commuteId = useSelector(getFeedDrawerCommuteIdSelector);

  const getHeader = () => {
    if (commuteId !== null) {
      return <CommuteHeader onClose={() => dispatch(setFeedDrawerCommuteIdEffect(null))} />;
    }

    return (
      <Header
        title="Feed Filters"
        onClose={() => dispatch(openFeedFilterDrawerEffect({ open: false }))}
      />
    );
  };

  const getContent = () => {
    return <AllSubFeedForm className={styles.allSubFeedDrawer} />;
  };

  return (
    <Drawer
      onClose={() => dispatch(openFeedFilterDrawerEffect({ open: false }))}
      className={classNames(styles.filterDrawer, className)}
      isOpen={open}
      header={getHeader()}
      testid="filter_drawer"
    >
      <div className={styles.drawerInner}>{getContent()}</div>
    </Drawer>
  );
};

FilterDrawer.tabs = [
  {
    id: 'search',
    label: 'Primary Criteria',
    Component: PrimaryCriteria,
    testid: 'primary_criteria',
  },
  {
    id: 'features',
    label: 'Wish List',
    Component: ImportantFeatures,
    testid: 'important_features',
  },
  { id: 'commutes', label: 'Commutes', Component: Commutes, testid: 'commutes' },
];

FilterDrawer.propTypes = {
  className: PropTypes.string,
};

FilterDrawer.defaultProps = {
  className: '',
};

export default FilterDrawer;
