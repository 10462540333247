import { useLocation } from 'react-router';

import DashboardRouter from 'components/Admin/DashboardRouter';
import Sidebar from './components/Sidebar';

import styles from './styles.module.scss';

const Dashboard = () => {
  const { pathname } = useLocation();
  const isFormBuilder = pathname.includes('forms') || pathname.includes('formBuilder');

  return (
    <div className={styles.dashboard}>
      <div className={styles.container}>
        {!isFormBuilder && (
          <div className={`${styles.sidebar} ${isFormBuilder ? styles.formSlider : ''}`}>
            <Sidebar isFormBuilder={isFormBuilder} />
          </div>
        )}
        <div className={isFormBuilder ? styles.formContentContainer : styles.contentContainer}>
          <DashboardRouter />
        </div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
