import AutoComplete from 'antd/lib/auto-complete';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FORM_USER_ROLE, incorrectEmail } from 'app-constants';
import {
  DraggableCombineMenuList,
  DraggableCombineMenuListItem,
  Select,
  Text,
} from 'components-antd';
import { caseInsensitiveFilter } from 'helpers';
import { sortOptions, validationToast } from 'pages/FormProcess/helper';
import { updateFormRoleEffect, updatePreFormResponseEffect } from 'store/effects/formProcess';
import { getPreFormSelector, getSmartFormDataSelector } from 'store/selectors/requestFormProcess';
import { FormRoleType, FormTemplateWizardType, SignatoriesType, SignatoryType } from 'types';
import { isEmail } from 'utils';
import { Navigation } from 'pages/FormProcess/components/PreForm/components';

import classNames from 'classnames';
import { RemoveFile, Plus } from 'components/Icons';
import styles from './styles.module.scss';

import { getSmartFormMetaSelect } from 'store/selectors/formBuilder';
import { filter } from 'lodash-es';

export const TemplateSignForm = ({
  customForm = false,
  editMode = false,
  templateState = {} as FormTemplateWizardType,
  onContinue,
  loading = false,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(getSmartFormMetaSelect);
  const { formRoles } = useSelector(getSmartFormDataSelector);

  const { signatories: allSignatories, isSequentialSignature } = useSelector(getPreFormSelector);

  const [templateFormRoles, setTemplateFormRoles] = useState<number[]>(
    editMode ? templateState?.formRoleIds || [] : [],
  );

  const rolesForTemplate = data?.questionRoles;
  const allRoles = (rolesForTemplate || []).map((role) => ({
    key: role.Id,
    label: role.Name,
    value: role.Name,
  }));

  const updateFormRoles = (updatedFormRoles: FormRoleType[]) => {
    dispatch(updateFormRoleEffect({ formRoles: updatedFormRoles }));
  };

  const [signatories, setSignatoriesState] = useState<SignatoryType[]>([]);
  const signatoriesDataCount = signatories.reduce((count, sig) => {
    if (sig.Email || sig.Name) return count + 1;
    return count;
  }, 0);

  useEffect(() => {
    const updatedSignatories: SignatoriesType = {};

    const availableSignatories = signatories.filter(
      (signatory) => signatory.UserId || signatory.Email,
    );

    availableSignatories.forEach((signatory) => {
      if (signatory.FormRole) {
        updatedSignatories[signatory.FormRole] = {
          ...signatory,
          SignatureSequence: signatory.SignatureSequence || 1,
        };
      }
    });

    dispatch(
      updatePreFormResponseEffect({
        signatories: updatedSignatories,
      }),
    );
  }, [signatories, isSequentialSignature]);

  const handleOnBlur = (signatoryIndex: number) => {
    const signatory = signatories?.[signatoryIndex];

    if (signatory) {
      let updatedSignatories = [...signatories];

      if (signatory.Email && !isEmail(signatory.Email)) {
        updatedSignatories[signatoryIndex].Email = '';
        setSignatoriesState(updatedSignatories);
        validationToast(incorrectEmail);
      }
    }
  };

  const modifySignatories = () => {
    let result: SignatoryType[] = [];
    const availableSignatories = allSignatories || {};

    if (formRoles?.length) {
      formRoles.forEach((role) => {
        const roleId = role.Id;
        if (availableSignatories[roleId]) {
          result.push(availableSignatories[roleId]);
        }
      });

      formRoles.forEach((role) => {
        const roleId = role.Id;
        if (!availableSignatories[roleId]) {
          result.push({
            FormRole: roleId,
            SignatureSequence: result.length + 1,
          });
        }
      });
    }

    setSignatoriesState(
      result.sort((a, b) => (a.SignatureSequence > b.SignatureSequence ? 1 : -1)),
    );
  };

  useEffect(() => {
    modifySignatories();

    if (customForm && !formRoles?.length) {
      addEmptyRole();
    }
  }, [formRoles]);

  const getInitialItems = () =>
    signatories.map((signer) => ({
      id: signer.FormRole,
      sequence: signer.SignatureSequence,
    })) as DraggableCombineMenuListItem[];

  const onSequenceUpdate = (updatedSigners: DraggableCombineMenuListItem[]) => {
    if (signatories?.length) {
      setSignatoriesState(
        signatories.map((signer) => {
          const updated = { ...signer };
          const item = updatedSigners.find((item) => item.id === signer.FormRole);
          if (item?.id) {
            updated.SignatureSequence = item.sequence;
          }

          return updated;
        }),
      );
    }
  };

  const handleRoleDelete = (roleId, signatoryIndex = -1, userId = -1) => {
    updateFormRoles(formRoles.filter((role) => role.Id !== roleId));

    if (signatoryIndex >= 0) {
      setTemplateFormRoles(templateFormRoles.filter((_, index) => index !== signatoryIndex));
    }
  };

  const handleTemplateRoleChange = (value: string, index, option) => {
    let roles = [...templateFormRoles];

    if (!value && roles[index]) {
      delete roles[index];
      setTemplateFormRoles([...roles]);
    } else {
      roles[index] = option.key;
      setTemplateFormRoles(roles);
    }
  };

  const renderFormSignatory = (item: DraggableCombineMenuListItem) => {
    const signatoryIndex = signatories?.findIndex((signatory) => signatory.FormRole === item?.id);
    const signatory = signatories?.[signatoryIndex];

    const roleIndex = formRoles?.findIndex((role) => role.Id === signatory?.FormRole);
    const role = roleIndex >= 0 ? formRoles?.[roleIndex] : undefined;

    if (!signatory || !role) {
      return <></>;
    }

    const getNumberTag = (num: number) => {
      if (!isSequentialSignature) return;
      return <div className={styles.numberTag}>{num}</div>;
    };

    const getRoleName = (roleId: number | undefined) => {
      if (roleId) {
        return allRoles.find((role) => role.key === roleId)?.label;
      }
    };

    const getRoleOptions = () => {
      let roles = templateFormRoles?.length
        ? allRoles.filter(({ key }) => !templateFormRoles.includes(key))
        : allRoles;

      return sortOptions(roles, 'label');
    };

    let autoCompleteValue = getRoleName(templateFormRoles[signatoryIndex]);

    return (
      <div className={classNames(styles.checkboxRow)} key={item.id}>
        {getNumberTag(item.sequence)}

        <div className={classNames(styles.templateFieldWrapper)}>
          <div
            className={classNames(styles.field, {
              [styles.signFormField]: !autoCompleteValue,
            })}
          >
            {role.Name ? <div className={styles.label}>{role.Name}</div> : <></>}

            <AutoComplete
              placeholder={'Select Role...'}
              bordered={false}
              className={styles.autoCompleteInput}
              value={autoCompleteValue}
              options={getRoleOptions()}
              filterOption={(inputValue, option) =>
                caseInsensitiveFilter(inputValue, option?.label as string)
              }
              onBlur={() => (signatory?.Name ? undefined : handleOnBlur(signatoryIndex))}
              onSelect={(value, option) => handleTemplateRoleChange(value, signatoryIndex, option)}
              onChange={(email) => handleTemplateRoleChange(email, signatoryIndex, {})}
              allowClear
              getPopupContainer={(node) => node}
              popupClassName={styles.autoCompleteDropdown}
            />
          </div>

          {item.sequence > 1 && (
            <div className={styles.removeButton}>
              <RemoveFile
                onClick={() => {
                  handleRoleDelete(role.Id, signatoryIndex);
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const addEmptyRole = () => {
    const max = formRoles.reduce((max, formRole) => (formRole.Id > max ? formRole.Id : max), 0);
    const newRoleId = max + 1;

    updateFormRoles([
      ...formRoles,
      {
        Id: newRoleId,
        Name: '',
        RoleId: newRoleId,
        UserType: FORM_USER_ROLE.Agent,
      },
    ]);
  };

  const isDisabled = () => {
    const filteredRoles = templateFormRoles?.filter((role) => role);

    return !filteredRoles.length;
  };

  const handleContinue = () => {
    let params = {
      templateRoleIds: filter(rolesForTemplate, (role) => templateFormRoles.includes(role.Id)).map(
        ({ Id }) => ({ Id }),
      ),
    };
    onContinue(params);
  };

  const items = getInitialItems();

  return formRoles?.length && rolesForTemplate?.length ? (
    <>
      <Text className={styles.questionHeader}>Who needs to sign?</Text>

      <div className={styles.pageContent}>
        <DraggableCombineMenuList
          initialItems={items}
          onItemUpdate={onSequenceUpdate}
          renderFunction={renderFormSignatory}
          isDisabled={!isSequentialSignature}
        />

        <div className={styles.add} onClick={addEmptyRole}>
          <Plus color="#515151" className={styles.addIcon} />
          <div className={styles.addText}>Add Another</div>
        </div>

        <Navigation
          onContinue={handleContinue}
          disabled={isDisabled()}
          className={signatoriesDataCount < 2 ? styles.navigationButton : ''}
          loading={loading}
        />
      </div>
    </>
  ) : (
    <Text className={styles.questionHeader}>No Form Roles Available for this form</Text>
  );
};
