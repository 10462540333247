import moment from 'moment';
import { Area, AreaConfig } from '@ant-design/plots';

import { Row, Col } from 'components-antd';
import { useEffect, useState } from 'react';
import { AreaConfigData } from 'pages/ClarityRevamped/Components/Graphs/AreaGraphTemplate/helper';

export const AreaGraph = ({ stats, color }) => {
  const [data, setData] = useState<AreaConfigData[]>([] as AreaConfigData[]);

  useEffect(() => {
    const currentStats =
      stats.map(({ date, sum }) => ({
        day: moment(date.slice(0, 10)).format('DD MMM YYYY'),
        value: sum,
      })) || [];
    if (currentStats.length === 1) setData([currentStats, currentStats]);
    else setData(currentStats);
  }, []);

  const config = {
    data,
    xField: 'day',
    yField: 'value',
    color,
    responsive: true,
    autoFit: true,
    height: 400,
    smooth: true,
    areaStyle: {
      fill: `l(270) 0:${color}00 1:${color}`,
    },
    xAxis: {
      label: {
        formatter(text) {
          return `${moment(text).format('MMM')}`;
        },
      },
    },
    yAxis: false,
    tooltip: false,
  } as AreaConfig;

  return (
    <Row align="middle" justify="end">
      <Col xs={24} sm={24}>
        <Area {...config}></Area>
      </Col>
    </Row>
  );
};
