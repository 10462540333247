import React, { FC } from 'react';
import moment from 'moment';
import { PreApprovalTypes } from 'types';

import styles from './styles.module.scss';
import { TickFileIcon } from 'components/Icons';
import classNames from 'classnames';

interface Props extends PreApprovalTypes {
  menuItem: React.ReactNode;
}

export const PreApprovalItem: FC<Props> = ({
  menuItem,
  Documents: documents,
  Id,
  CreatedByUser,
  Amount,
  ExpirationDate,
  ApprovedBy,
  ApprovalDate,
  ...rest
}) => {
  const getFormattedAmount = (amount) => new Intl.NumberFormat('en-US').format(amount);

  const getItemByApprovalType = () => {
    return (
      <div className={styles.infoMenuWrapper}>
        <div className={styles.infoIconWrapper}>
          <TickFileIcon />
          <div className={styles.info}>
            {documents ? (
              <>
                <h4 className={styles.mainHeading}>{documents?.Filename}</h4>
              </>
            ) : (
              <span className={styles.headingLabelWrapper}>
                <h4 className={styles.mainHeading}>Pre-Approval</h4>
                <p className={styles.sideLabel}>(Manual Entry)</p>
              </span>
            )}
            {Amount || ExpirationDate ? (
              <p className={styles.headingLabelWrapper}>
                {Amount ? (
                  <>
                    Amount: <h4>${getFormattedAmount(Amount)} </h4>
                  </>
                ) : null}
                {ExpirationDate ? (
                  <>
                    - Expires: <h4>{moment(ExpirationDate).format('MM/DD/YYYY')}</h4>
                  </>
                ) : null}
              </p>
            ) : null}
            {ApprovedBy || ApprovalDate ? (
              <p className={classNames(styles.headingLabelWrapper, styles.displayInline)}>
                Approved{' '}
                {ApprovedBy ? (
                  <>
                    By: <h4 className={styles.displayInline}>{ApprovedBy} </h4>
                  </>
                ) : null}
                {ApprovalDate ? (
                  <p className={styles.displayInline}>
                    on{' '}
                    <h4 className={styles.displayInline}>
                      {moment(ApprovalDate).format('MM/DD/YYYY')}
                    </h4>
                  </p>
                ) : null}
              </p>
            ) : null}
            {documents ? (
              <p>
                Uploaded on: {moment(documents?.UploadDate).format('MM/DD/YYYY')} at{' '}
                {moment(documents?.UploadDate).format('hh:mm A')}
              </p>
            ) : CreatedByUser ? (
              <p className={styles.headingLabelWrapper}>
                Manually entered by{' '}
                <h4>{`${CreatedByUser.FirstName} ${CreatedByUser.LastName}`}</h4> on
                <h4>
                  {rest?.CreatedDate ? moment(rest?.CreatedDate).format('MM/DD/YYYY') : 'N/A'}
                </h4>
              </p>
            ) : null}
          </div>
        </div>
        {menuItem ? menuItem : null}
      </div>
    );
  };
  return <div className={styles.document}>{getItemByApprovalType()}</div>;
};
