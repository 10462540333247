import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { getPhoneVerificationCodeInfo, requestPhoneVerificationCode } from 'api/user';
import { Modal } from 'components-antd';
import { showErrorMessage } from 'helpers';
import { updateUserProfileWithVerfiedPhoneNumberAction } from 'store/actions/user';
import { PhoneVerificationBlockedStep } from './PhoneVerificationBlockedStep';
import { SetPhoneNumberStep } from './SetPhoneNumberStep';
import { VerifyCodeStep } from './VerifyCodeStep';
import { PhoneVerificationModalSteps } from 'app-constants';

interface VerifyPhoneNumberModalProps {
  isVerifyPhoneNumberModalOpened: boolean;
  onCloseModal: () => void;
}

export const VerifyPhoneNumberModal: React.FC<VerifyPhoneNumberModalProps> = (props) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState<PhoneVerificationModalSteps>(
    PhoneVerificationModalSteps.EnterPhone,
  );

  const [verificationCodeData, setVerificationCodeData] = useState<
    undefined | null | VerificationCodeData
  >(undefined);

  const [remainingSecondsToResendCode, setRemainingSecondsToResendCode] = useState<number | null>(
    null,
  );

  // Load info about existing verification code request once modal is open
  useEffect(() => {
    const runEffect = async () => {
      const response = await getPhoneVerificationCodeInfo();
      setVerificationCodeData(response.data);
      if (response.data) {
        if (!response.data.IsBlocked) {
          setStep(PhoneVerificationModalSteps.VerifyCode);
        } else {
          setStep(PhoneVerificationModalSteps.PhoneVerificationBlocked);
        }
      }
    };

    if (props.isVerifyPhoneNumberModalOpened) {
      void runEffect();
    }
  }, [props.isVerifyPhoneNumberModalOpened]);

  // Update timer with remaining seconds for next attempt
  useEffect(() => {
    const interval = setInterval(() => {
      const remainingSeconds = moment(verificationCodeData?.AllowCodeResendTimestamp).diff(
        moment(),
        'seconds',
      );
      setRemainingSecondsToResendCode(remainingSeconds);

      if (remainingSeconds < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [verificationCodeData]);

  return (
    <Modal
      open={props.isVerifyPhoneNumberModalOpened}
      width={675}
      footer={null}
      onCancel={() => {
        props.onCloseModal();
      }}
      // className={styles.inviteModal}
      // cancelButtonStyles={styles.crossButton}
      maskClosable={false}
      destroyOnClose={true}
      title=" "
    >
      {step === PhoneVerificationModalSteps.EnterPhone && (
        <SetPhoneNumberStep
          onSubmitPhone={async (phoneNumber: string) => {
            const response = await getPhoneVerificationCodeInfo();
            let fetchedVerificationCodeData = response.data;

            try {
              if (
                !fetchedVerificationCodeData ||
                fetchedVerificationCodeData.PhoneNumber !== phoneNumber
              ) {
                const requestCodeResponse = await requestPhoneVerificationCode({
                  phoneNumber,
                });

                fetchedVerificationCodeData = requestCodeResponse.data;
              }

              setVerificationCodeData(fetchedVerificationCodeData);
              setStep(PhoneVerificationModalSteps.VerifyCode);
            } catch (err: any) {
              showErrorMessage(err);
            }
          }}
        ></SetPhoneNumberStep>
      )}
      {step === PhoneVerificationModalSteps.VerifyCode && (
        <VerifyCodeStep
          phoneNumber={verificationCodeData?.PhoneNumber}
          onCodeVerfied={async (codeVerifiedResponse) => {
            dispatch(updateUserProfileWithVerfiedPhoneNumberAction(codeVerifiedResponse));
            props.onCloseModal();
          }}
          onResendCodeClick={async () => {
            try {
              const response = await requestPhoneVerificationCode({});
              setVerificationCodeData(response.data);
            } catch (err: any) {
              if (err.response.data.code === 'MAX_ATTEMPTS_TO_SEND') {
                setStep(PhoneVerificationModalSteps.PhoneVerificationBlocked);
              }
            }
          }}
          onEditPhoneNumberClick={() => {
            setStep(PhoneVerificationModalSteps.EnterPhone);
          }}
          remainingSecondsToResendCode={remainingSecondsToResendCode}
        ></VerifyCodeStep>
      )}
      {step === PhoneVerificationModalSteps.PhoneVerificationBlocked && (
        <PhoneVerificationBlockedStep />
      )}
    </Modal>
  );
};

interface VerificationCodeData {
  AllowCodeResendTimestamp: Date;
  ExpirationSeconds: number;
  PhoneNumber: string;
}
