import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { openShareDrawerEffect, setSharePropertyEffect } from 'store/effects';
import Icon from 'pages/Properties/Feed/Icons';
import { extractOrderedPhotos } from 'helpers';

import styles from './styles.module.scss';

const Share = ({ className, propertyInfo }) => {
  const dispatch = useDispatch();
  const { PhotoUrls, Media, PhotoCount } = propertyInfo;
  const OrderedPhotos = extractOrderedPhotos(Media, PhotoCount);
  const ImagesToSend = !isEmpty(OrderedPhotos) ? OrderedPhotos : PhotoUrls;
  const [info] = useState({
    Id: propertyInfo.Id ? propertyInfo.Id : propertyInfo.id,
    ActivityStatus: propertyInfo.ActivityStatus,
    Address: propertyInfo?.address || propertyInfo?.Address,
    Neighborhood: propertyInfo?.Neighborhood,
    Geolocation: {
      Lat: propertyInfo?.Geolocation?.Lat || propertyInfo?.geometry?.coordinates?.lat,
      Long: propertyInfo?.Geolocation?.Long || propertyInfo?.geometry?.coordinates?.lng,
    },
    NumBathsTotal: propertyInfo?.NumBathsTotal,
    NumBeds: propertyInfo?.NumBeds,
    HomeType: propertyInfo?.HomeType,
    YearBuilt: propertyInfo?.YearBuilt,
    ExteriorAndLotDetails: propertyInfo?.ExteriorAndLotDetails,
    AssociationInfo: propertyInfo?.AssociationInfo,
    Parking: propertyInfo?.Parking,
    ListingDate: propertyInfo?.ListingDate,
    ListingInfo: propertyInfo?.ListingInfo,
    BuildingInfo: propertyInfo?.BuildingInfo,
    PhotoUrls: ImagesToSend ? ImagesToSend : [propertyInfo?.image],
    SellingPrice: propertyInfo?.SellingPrice || propertyInfo?.price,
    SquareFeet: propertyInfo?.SquareFeet,
    AddedToFavoritesBy: propertyInfo?.AddedToFavoritesBy,
    CustomKitchenFeatures: propertyInfo?.CustomKitchenFeatures,
    MatchedClientsCount: propertyInfo?.MatchedClientsCount,
    MatchedCriterias: propertyInfo?.MatchedCriterias,
    OpenHouses: propertyInfo?.OpenHouses,
    MatchScore: propertyInfo?.MatchScore,
    PropertyHistory: propertyInfo?.PropertyHistory || {},
  });

  const onShare = (e) => {
    e.stopPropagation();
    dispatch(setSharePropertyEffect(info));
    dispatch(openShareDrawerEffect({ open: true }));
  };

  return (
    <div onClick={onShare} className={classNames(styles.shareWrapper, className)}>
      <Icon testid="share_icon" variant={Icon.SHARE} />
    </div>
  );
};

Share.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({}).isRequired,
};

Share.defaultProps = {
  className: '',
};

export default Share;
