import moment from 'moment';

import {
  SignFormFields,
  SIGNATURE_MAPPING,
  PDF_FIELD_TYPE,
  FORM_USER_ROLE,
  FORM_USER_ROLE_COLORS,
  FORM_QUESTION_TYPE,
} from 'app-constants';
import {
  PDFFields,
  FormDocumentQuestionType,
  FormDocumentQuestionsType,
  FormDocumentAnswersType,
  UserSignature,
  RenderFieldsType,
} from 'types';
import { SignatureResult, PDFSignResponse } from '../PDFSign';

export const getFieldKey = (field) => {
  let fieldKey = SignFormFields.Signature;

  if (field.customTag === SIGNATURE_MAPPING.FullName) {
    fieldKey = SignFormFields.Name;
  } else if (field.customTag === SIGNATURE_MAPPING.Initials) {
    fieldKey = SignFormFields.Initials;
  } else if (field.customTag === SIGNATURE_MAPPING.SignatureDate) {
    fieldKey = SignFormFields.Date;
  }

  return fieldKey;
};

export const getRenderFieldType = (
  allQuestions: FormDocumentQuestionsType,
  page: number,
  responses,
  mode,
) => {
  const pageQuestions = allQuestions.filter((q) =>
    q.Fields.map((f) => f.widgets)
      .flat()
      .map((w) => w?.pageNumber)
      .includes(page),
  );

  let fields: RenderFieldsType[] =
    pageQuestions
      .map((question) => [
        ...(question.Fields
          ? question.Fields.map((field, fieldIndex) => ({
              ...field,
              questionIndex: allQuestions.findIndex((q) => q.UUID === question.UUID) || 0,
              response: responses[question.UUID],
              questionType: question.Type,
              disabled: question.disabled,
              questionUUID: question.UUID,
              isLastField: fieldIndex === question.Fields.length - 1,
            }))
          : []),
      ])
      .filter((el) => el)
      .flat() || [];

  if (mode === 'Edit') {
    fields = fields.filter((field) => field.questionType !== FORM_QUESTION_TYPE.Signature);
  } else if (mode === 'Preview') {
    fields = fields.filter((field) => !!field.response?.Answer);
  }

  return fields;
};

export const getSignDefaultResult = (signResult: SignatureResult, field) => {
  let defaultResponse: PDFSignResponse | undefined = undefined;

  if (signResult) {
    if (field.customTag === SIGNATURE_MAPPING.Signature) {
      if (signResult.signature) {
        defaultResponse = {
          type: 'image',
          data: signResult.signature,
          fieldKey: SignFormFields.Signature,
        };
      } else if (signResult.fullNameImage) {
        defaultResponse = {
          type: 'image',
          data: signResult.fullNameImage,
          fieldKey: SignFormFields.Name,
        };
      }
    } else if (field.customTag === SIGNATURE_MAPPING.FullName && signResult.fullName) {
      defaultResponse = {
        type: 'text',
        data: signResult.fullName,
        font: signResult.font,
        fieldKey: SignFormFields.Name,
      };
    } else if (field.customTag === SIGNATURE_MAPPING.Initials && signResult.initialsImage) {
      defaultResponse = {
        type: 'image',
        data: signResult.initialsImage,
        fieldKey: SignFormFields.Initials,
      };
    } else if (field.customTag === SIGNATURE_MAPPING.SignatureDate && signResult.signatureDate) {
      defaultResponse = {
        type: 'text',
        data: signResult.signatureDate,
        fieldKey: SignFormFields.Date,
      };
    }
  }

  return defaultResponse;
};

export const handleSignClick = (
  questions: FormDocumentQuestionsType,
  responses: FormDocumentAnswersType,
  questionIndex: number,
  fieldKey: SignFormFields,
  setSignModalFieldsProps,
  currentUserSignature?,
) => {
  if (questions?.length && questions?.[questionIndex]?.Fields?.length) {
    const response = getSignResponse(questions, responses, questionIndex, currentUserSignature);

    setSignModalFieldsProps({
      index: questionIndex,
      fields: questions[questionIndex].Fields || [],
      response,
      fieldKey,
    });
  }
};

export const getSignatureRoleIndex = (signatureFields: number[], roleId: number) => {
  if (roleId >= 0) {
    if (signatureFields?.length && signatureFields.findIndex((f) => f === roleId) !== -1) {
      return signatureFields.findIndex((f) => f === roleId);
    }
  }

  return 0;
};

export const getFieldProps = (field: RenderFieldsType, widgetIndex: number) => {
  if (field.fieldType === PDF_FIELD_TYPE.PDFTextField && !field.isMultiLine) {
    return {
      type: 'text',
      defaultValue: field.response?.Answer,
      id: `field-${field.id}-${widgetIndex}`,
    };
  } else if (field.fieldType === PDF_FIELD_TYPE.PDFTextField && field.isMultiLine) {
    return { type: 'textarea', resize: 'none', defaultValue: field.response?.Answer };
  } else {
    let defaultChecked: boolean | undefined = undefined;
    let value: string | undefined = undefined;

    if (field.response) {
      let answer: any = {};
      try {
        answer = JSON.parse(field.response?.Answer);
      } catch (e) {
        answer = {};
      }

      if (answer && answer.includes) {
        if (field.fieldType === PDF_FIELD_TYPE.PDFCheckBox) {
          if (answer.includes(field.id)) {
            defaultChecked = true;
          } else {
            defaultChecked = undefined;
          }
        } else if (field.fieldType === PDF_FIELD_TYPE.PDFRadioGroup) {
          defaultChecked = undefined;
          if (answer && answer?.[0]?.selectedOptions?.length) {
            answer = answer[0].selectedOptions;

            const radioOptions = (field as PDFFields).radioOptions || [];

            if (radioOptions?.length && radioOptions?.[widgetIndex]) {
              const radioValue = radioOptions[widgetIndex].value;

              if (answer.includes(radioValue)) {
                defaultChecked = true;
              }
            }
          }
        }
      }
    }

    let type = '';

    if (field.fieldType === PDF_FIELD_TYPE.PDFCheckBox) {
      type = 'checkbox';
      value = field.id;
    } else if (field.fieldType === PDF_FIELD_TYPE.PDFRadioGroup) {
      type = 'radio';
      const radioOptions = (field as PDFFields).radioOptions || [];

      if (radioOptions?.length && radioOptions?.[widgetIndex]) {
        value = radioOptions[widgetIndex].value;
      }
    }

    return {
      type,
      defaultChecked,
      defaultValue: value,
    };
  }
};
export const getBackgroundColor = (question?: FormDocumentQuestionType) => {
  if (!question) return undefined;

  const roles: string[] = question.RolePermissions || [];

  if (roles.includes(FORM_USER_ROLE.Agent)) {
    return FORM_USER_ROLE_COLORS.Agent;
  } else if (roles.includes(FORM_USER_ROLE.Client)) {
    return FORM_USER_ROLE_COLORS.Client;
  } else return FORM_USER_ROLE_COLORS.ClientAgent;
};

export const getSignResponse = (
  questions: FormDocumentQuestionsType,
  responses: FormDocumentAnswersType,
  questionIndex: number,
  currentUserSignature?,
) => {
  let response: any = undefined;
  try {
    response = responses[questions[questionIndex].UUID];
    if (response?.Answer) {
      response = JSON.parse(response?.Answer);
    } else if (currentUserSignature) {
      response = currentUserSignature;
    }
  } catch (e) {
    //
  }
  return response as SignatureResult | undefined;
};

export const handleFillSignature = (
  questions: FormDocumentQuestionsType,
  responses: FormDocumentAnswersType,
  questionIndex: number,
  onSignUpdate,
  currentUserSignature?: UserSignature,
) => {
  const uuid = questions[questionIndex].UUID;
  const response = responses[uuid];

  if (currentUserSignature) {
    const {
      Font: font,
      FullName: fullName,
      Initials: initials,
      FullNameImage: fullNameImage,
      InitialsImage: initialsImage,
      Signature: signature,
    } = currentUserSignature;

    const result = {
      ...response,
      font,
      fullName,
      initials,
      fullNameImage,
      initialsImage,
      signature,
      signatureDate: moment().format('MM/DD/YYYY'),
    };
    if (onSignUpdate) {
      onSignUpdate(uuid, result);
    } else {
      return result;
    }
  }
};

export const hasFilledAnswer = (answers, questions) => {
  let hasAnswer = false;
  for (let i = 0; i < questions.length; i++) {
    const { UUID } = questions[i];
    if (answers[UUID] && answers[UUID].Answer) {
      hasAnswer = true;
      break;
    }
  }

  return hasAnswer;
};
