import { useSelector, useDispatch } from 'react-redux';
import { getUserId } from 'store/selectors/user';
import {
  getShowingAggregateByUserIdEffect,
  setShowingsFiltersAppliedEffect,
  setShowingsIsArchiveEffect,
} from 'store/effects/showingAggregate';
import { FilterSections } from './FilterSections';

export const Filter = ({ setShowingFilters }) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);

  const doneHandler = (p) => {
    setShowingFilters({ ...p });
    dispatch(setShowingsFiltersAppliedEffect(true));
    dispatch(setShowingsIsArchiveEffect(false));
    dispatch(
      getShowingAggregateByUserIdEffect({
        id: userId,
        filters: {
          ...p,
        },
      }),
    );
  };

  const resetHandler = () => {
    setShowingFilters({});
    dispatch(setShowingsFiltersAppliedEffect(false));
    dispatch(
      getShowingAggregateByUserIdEffect({
        id: userId,
        filters: { Status: ['Pending', 'Upcoming'] },
      }),
    );
  };

  return <FilterSections onDone={doneHandler} onReset={resetHandler} />;
};
