import {
  rgb,
  PDFButton,
  PDFCheckBox,
  PDFDropdown,
  PDFField,
  PDFForm,
  PDFOptionList,
  PDFRadioGroup,
  PDFSignature,
  PDFTextField,
  PDFDocument,
  findLastMatch,
} from 'pdf-lib';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import {
  FORM_USER_ROLE,
  ALLOWED_PDF_FIELDS,
  PDF_FIELD_TYPE,
  SMART_FORM_QUESTION_TYPE,
  SIGNATURE_MAPPING,
  SIGNATURE_FIELD_COLORS,
  FORM_FONT_FAMILY,
} from 'app-constants';
import {
  FormProcessTypes,
  PDFFields,
  PDFFieldsSize,
  PDFWidget,
  QuestionFieldType,
  QuestionType,
  ResponseType,
} from 'types';
import { link } from 'settings/navigation/link';

export const isGroupedField = (fieldType: QuestionFieldType) => {
  if (fieldType === PDF_FIELD_TYPE.PDFCheckBox) {
    return true;
  }

  return false;
};

export const getQuestionTypeForPDFType = (type: string) => {
  if (type === PDF_FIELD_TYPE.PDFSignature) {
    return SMART_FORM_QUESTION_TYPE.Signature;
  } else if (type === PDF_FIELD_TYPE.PDFCheckBox) {
    return SMART_FORM_QUESTION_TYPE.ListBox;
  } else if (type === PDF_FIELD_TYPE.PDFRadioGroup) {
    return SMART_FORM_QUESTION_TYPE.ListBox;
  } else {
    return SMART_FORM_QUESTION_TYPE.FreeText;
  }
};

export const getPDFFieldType = (field: PDFField) => {
  if (field instanceof PDFTextField) {
    return PDF_FIELD_TYPE.PDFTextField;
  } else if (field instanceof PDFCheckBox) {
    return PDF_FIELD_TYPE.PDFCheckBox;
  } else if (field instanceof PDFDropdown) {
    return PDF_FIELD_TYPE.PDFDropdown;
  } else if (field instanceof PDFOptionList) {
    return PDF_FIELD_TYPE.PDFOptionList;
  } else if (field instanceof PDFRadioGroup) {
    return PDF_FIELD_TYPE.PDFRadioGroup;
  } else if (field instanceof PDFSignature) {
    return PDF_FIELD_TYPE.PDFSignature;
  } else if (field instanceof PDFButton) {
    return PDF_FIELD_TYPE.PDFButton;
  }
  return PDF_FIELD_TYPE.PDFTextField;
};

export const allowedPDFFieldType = (field) => {
  return ALLOWED_PDF_FIELDS.includes(field);
};

// eslint-disable-next-line no-useless-escape
const tfRegex = /\/([^\0\t\n\f\r\ ]+)[\0\t\n\f\r\ ]+(\d*\.\d+|\d+)[\0\t\n\f\r\ ]+Tf/;

const getFontSizeAndFamily = (field: PDFField) => {
  const da = field.acroField.getDefaultAppearance() ?? '';
  const daMatch = findLastMatch(da, tfRegex)?.match ?? [];
  const defaultFontSize = Number(daMatch[2]);

  const fontSize = isFinite(defaultFontSize) ? defaultFontSize : 0;

  return {
    fontSize,
    fontFamily: FORM_FONT_FAMILY.Arial,
  };
};

export const handleViewForm = (history, url: string, type: FormProcessTypes) => {
  if (url) {
    const Ids = url.split('/');
    const locationData = {
      formProcessPublicId: Ids[0],
      formDocumentPublicId: Ids[1],
    };

    if (type === FormProcessTypes.DynamicForm) {
      history.push(link.toWorkshopDynamicFormDocument(locationData));
    } else {
      history.push(link.toWorkshopFormProcessDocument(locationData));
    }
  }
};

export const getFieldProps = (type: QuestionFieldType, f: PDFField) => {
  switch (type) {
    case PDF_FIELD_TYPE.PDFTextField:
      return {
        isMultiLine: (f as PDFTextField).isMultiline(),
        isRichText: (f as PDFTextField).isRichFormatted(),
        isFileSelector: (f as PDFTextField).isFileSelector(),
        maxLength: (f as PDFTextField).getMaxLength(),
        ...getFontSizeAndFamily(f),
      };
    case PDF_FIELD_TYPE.PDFRadioGroup:
      return {
        isMutuallyExclusive: (f as PDFRadioGroup).isMutuallyExclusive(),
        isOffToggleable: (f as PDFRadioGroup).isOffToggleable(),
        radioOptions: (f as PDFRadioGroup)
          .getOptions()
          .map((option) => ({ key: option, value: option })),
      };
    default:
      return {};
  }
};

export const getFieldWidgets = (PDF: PDFDocument, formField: PDFField) => {
  let fieldWidgets: PDFWidget[] = [];

  if (formField?.acroField?.getWidgets) {
    const widgets = formField.acroField.getWidgets();

    if (widgets.length) {
      for (let i = 0; i < widgets.length; i++) {
        const widget = widgets[i];
        const { height, width, x, y } = widget.getRectangle();

        let pageNumber = PDF.getPages().findIndex((x) => x.ref === widget.P());

        if (pageNumber < 0) pageNumber = 0;

        fieldWidgets.push({
          x,
          y,
          height,
          width,
          pageNumber,
        });
      }
    }
  }

  return fieldWidgets;
};

export const getPDFQuestions = (pdfDoc: PDFDocument) => {
  const form = pdfDoc.getForm();
  const fields = form.getFields();
  const result = {} as any;

  fields.map((f) => {
    const type = getPDFFieldType(f);
    const fieldName = f.getName();

    if (allowedPDFFieldType(type) && !f.isReadOnly()) {
      if (!result[type]) result[type] = [];
      const data = {
        id: fieldName,
        fieldType: type,
        fieldName: fieldName,
        isRequired: f.isRequired(),
        isReadOnly: f.isReadOnly(),
        isExported: f.isExported(),
        ...getFieldProps(type, f),
        widgets: getFieldWidgets(pdfDoc, f),
      } as PDFFields;

      result[type].push(data);
    }
  });

  if (result && Object.keys(result)?.length) {
    const questions: QuestionType[] = [];
    let counter = {};

    Object.keys(result).map((f) => {
      if (f === PDF_FIELD_TYPE.PDFCheckBox) {
        if (!counter[PDF_FIELD_TYPE.PDFCheckBox]) {
          counter[PDF_FIELD_TYPE.PDFCheckBox] = 0;
        }
        counter[PDF_FIELD_TYPE.PDFCheckBox]++;
        questions.push({
          Order: questions.length,
          UUID: uuidv4(),
          Title: `${PDF_FIELD_TYPE.PDFCheckBox}-${counter[PDF_FIELD_TYPE.PDFCheckBox]}`,
          FieldType: f,
          Name: `${PDF_FIELD_TYPE.PDFCheckBox}-${counter[PDF_FIELD_TYPE.PDFCheckBox]}`,
          Fields: result[f],
          Type: getQuestionTypeForPDFType(f),
          Required: false,
          Meta: {
            MultiSelect: true,
          },
          RolePermissions: [FORM_USER_ROLE.Agent],
        });
      } else {
        result[f].map((field: PDFFields) => {
          if (!counter[field.fieldType]) {
            counter[field.fieldType] = 0;
          }
          counter[field.fieldType]++;
          questions.push({
            Order: questions.length,
            UUID: uuidv4(),
            Title: field.fieldName || f,
            FieldType: f as QuestionFieldType,
            Name: `${field.fieldType}-${counter[field.fieldType]}`,
            Fields: getField(pdfDoc, field),
            Type: getQuestionTypeForPDFType(f),
            Required: field.isRequired || false,
            RolePermissions: f === PDF_FIELD_TYPE.PDFSignature ? [] : [FORM_USER_ROLE.Agent],
            ...(f === PDF_FIELD_TYPE.PDFRadioGroup
              ? {
                  Meta: {
                    MultiSelect: false,
                  },
                }
              : {}),
          });
        });
      }
    });

    return questions;
  } else {
    return [];
  }
};

const getField = (pdfDoc: PDFDocument, field: PDFFields) => {
  if (!field) return [];

  const defaultField = { ...field, ...getSignatureSize(pdfDoc, field.id) };
  switch (field.fieldType) {
    case PDF_FIELD_TYPE.PDFSignature:
      return [{ ...defaultField, customTag: SIGNATURE_MAPPING.Signature }];

    default:
      return [defaultField];
  }
};

export const getSignatureSize = (pdfDoc: PDFDocument, fieldId: string) => {
  const form = pdfDoc.getForm();
  const field = form.getField(fieldId);
  if (field?.acroField?.getWidgets) {
    const widgets = field.acroField.getWidgets();
    if (widgets.length && widgets[0]) {
      const widget = widgets[0];
      const { height, width } = widget.getRectangle();

      return {
        size: {
          width,
          height,
        } as PDFFieldsSize,
      };
    }
  }
  return {};
};

export const getPDFFieldRGBColor = (fieldType: QuestionFieldType) => {
  switch (fieldType) {
    case PDF_FIELD_TYPE.PDFTextField:
      return rgb(0.57, 0.55, 0.85);
    case PDF_FIELD_TYPE.PDFCheckBox:
      return rgb(0.98, 0.57, 0.23);
    case PDF_FIELD_TYPE.PDFDropdown:
      return rgb(0.32, 0.75, 0.88);
    case PDF_FIELD_TYPE.PDFRadioGroup:
      return rgb(0.57, 0.55, 0.85);
    case PDF_FIELD_TYPE.PDFOptionList:
      return rgb(0, 0.69, 0.32);
    case PDF_FIELD_TYPE.PDFSignature:
      return rgb(0.27, 0.45, 0.82);
    default:
      return rgb(0.57, 0.55, 0.85);
  }
};

export const getPDFFieldHexColor = (fieldType?: QuestionFieldType) => {
  switch (fieldType) {
    case PDF_FIELD_TYPE.PDFTextField:
      return '#928CDA';
    case PDF_FIELD_TYPE.PDFCheckBox:
      return '#FB913A';
    case PDF_FIELD_TYPE.PDFDropdown:
      return '#51BFE1';
    case PDF_FIELD_TYPE.PDFRadioGroup:
      return '#928CDA';
    case PDF_FIELD_TYPE.PDFOptionList:
      return '#00B152';
    case PDF_FIELD_TYPE.PDFSignature:
      return '#4673D1';
    default:
      return '#928CDA';
  }
};

export const generatePDF = async (link: string, responses: ResponseType[]) => {
  const data = await axios.get(link, {
    responseType: 'arraybuffer',
  });
  const pdfDoc = await PDFDocument.load(data.data);
  const form = pdfDoc.getForm();

  await Promise.all(
    responses.map(async (response) => {
      switch (response.FieldType) {
        case PDF_FIELD_TYPE.PDFTextField:
          await handleFreeText(form, response);
          break;
        case PDF_FIELD_TYPE.PDFSignature:
          await handleSignature(pdfDoc, response);
          break;
        case PDF_FIELD_TYPE.PDFCheckBox:
          await handleCheckBox(form, response);
          break;
        case PDF_FIELD_TYPE.PDFRadioGroup:
          await handleRadioGroup(form, response);
          break;
        default:
          break;
      }
      return null;
    }),
  );
  const pdfBytes = await pdfDoc.save();
  const bytes = new Uint8Array(pdfBytes);
  const blob = new Blob([bytes], { type: 'application/pdf' });
  const docUrl = URL.createObjectURL(blob);

  return docUrl;
};

const handleFreeText = (form: PDFForm, response: ResponseType) => {
  for (const field of response.Fields || []) {
    const pdfField = form.getTextField(field.id);
    pdfField.setText(response.Answer);
  }
};

const handleSignature = async (PDF: PDFDocument, response: ResponseType) => {
  if (!response?.Answer) {
    return;
  }
  const form = PDF.getForm();
  const responseData = JSON.parse(response.Answer);
  const imageData = await axios.get(responseData.Url, {
    responseType: 'arraybuffer',
  });
  const image = await PDF.embedPng(imageData.data);
  for (const field of response.Fields || []) {
    const formField = form.getFieldMaybe(field.id);
    if (formField?.acroField?.getWidgets) {
      const widgets = formField.acroField.getWidgets();
      if (widgets.length && widgets[0]) {
        const widget = widgets[0];
        const { height, width, x, y } = widget.getRectangle();
        const page = PDF.findPageForAnnotationRef(formField.ref);
        if (page) {
          page.drawImage(image, {
            height,
            width,
            x,
            y,
          });
        }
      }
    }
  }
};

const handleCheckBox = (form: PDFForm, response: ResponseType) => {
  if (!response?.Answer) {
    return;
  }
  const fields = JSON.parse(response.Answer);
  for (const field of fields || []) {
    const pdfField = form.getCheckBox(field);
    pdfField.check();
  }
};

const handleRadioGroup = (form: PDFForm, response: ResponseType) => {
  if (!response?.Answer) {
    return;
  }
  const data = JSON.parse(response.Answer)?.[0];
  if (data?.fieldId && data?.selectedOptions?.length) {
    const radio = form.getRadioGroup(data.fieldId);
    if (!radio) return;
    for (const field of data.selectedOptions || []) {
      radio.select(field);
    }
  }
};

export const getSignatureFieldColor = (index: number): typeof SIGNATURE_FIELD_COLORS[number] => {
  const colorIndex = index % SIGNATURE_FIELD_COLORS.length;

  if (SIGNATURE_FIELD_COLORS[colorIndex]) {
    return SIGNATURE_FIELD_COLORS[colorIndex];
  } else {
    return SIGNATURE_FIELD_COLORS[0];
  }
};
