import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useDispatch } from 'react-redux';
import { Toggle, FormattedPhone } from 'components';
import { mapItemTitles } from 'settings/constants/notifications';
import { updateNotificationsSettingsEffect } from 'store/effects';

import Icon from '../../Icons';

import styles from './styles.module.scss';
import { usePhoneVerification } from './usePhoneVerification';

const Item = (props) => {
  const {
    className,
    groupType,
    type,
    value,
    variant,
    userInfo,
    testid,
    onRequirePhoneVerification,
  } = props;
  const dispatch = useDispatch();

  const [itemValue, setItemValue] = useState(value);
  const [isPending, setIsPending] = useState(false);

  const { userEmail, userPhones } = userInfo || {};

  // Currently we allow setting only single phone number, thought data interface allows array
  const mainUserPhoneNumber = userPhones?.[0]?.PhoneNumber;
  const phoneVerification = usePhoneVerification(userPhones, onRequirePhoneVerification);

  useEffect(() => setItemValue(value), [value]);

  const setValueForCheckbox = (val) => {
    setIsPending(true);
    dispatch(
      updateNotificationsSettingsEffect({ groupType, type, variant, value: val }, {}, (err) => {
        if (!err) {
          setItemValue(val);
        }
        setIsPending(false);
      }),
    );
  };

  const { Title, Subtitle } =
    mapItemTitles?.[groupType]?.[variant]?.({
      phoneNumber: (
        <FormattedPhone testid="phone" className={styles.phone}>
          {mainUserPhoneNumber}
        </FormattedPhone>
      ),
      email: userEmail,
    }) || {};

  const onChangeHandler = (e, val) => {
    if (val && variant === 'Sms' && !phoneVerification.isPhoneNumberVerified) {
      phoneVerification.requestPhoneVerification(() => {
        setValueForCheckbox(true);
      });
      return;
    }

    setValueForCheckbox(val);
  };

  return (
    <div testid={testid} className={classNames(styles.item, className)}>
      <Icon className={styles.icon} variant={variant} />
      <div className={styles.titles}>
        {Title && (
          <div testid="title" className={styles.title}>
            {Title}
          </div>
        )}
        {Subtitle && (
          <div testid="subtitle" className={styles.subtitle}>
            {Subtitle}
          </div>
        )}
      </div>
      <Toggle
        className={styles.toggle}
        labelClassName={styles.toggleWrapper}
        onChange={onChangeHandler}
        checked={itemValue}
        label={itemValue ? 'On' : 'Off'}
        isPending={isPending}
        topControl
        testid="toggle"
      />
    </div>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  groupType: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  type: PropTypes.string,
  testid: PropTypes.string,
  userInfo: PropTypes.shape({
    userEmail: PropTypes.string,
    userPhones: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onRequirePhoneVerification: PropTypes.func.isRequired,
};

Item.defaultProps = {
  className: '',
  type: undefined,
  userInfo: undefined,
  testid: undefined,
};

export default Item;
