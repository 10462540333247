import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MosaikLogo, MosaikLogoMobile } from 'components/Icons';

import styles from './styles.module.scss';

const LeftSide = ({ className, isLogo, variant }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState('');

  useEffect(() => {
    const resizer = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      setIsMobile(newWidth < 768);
    };
    resizer();
    window.addEventListener('resize', resizer);
    return () => {
      window.removeEventListener('resize', resizer);
    };
  }, []);

  return (
    <div className={classNames(styles.aside, styles[variant], className)}>
      {isLogo &&
        (isMobile ? (
          <MosaikLogoMobile className={styles.logo} />
        ) : (
          <MosaikLogo primary={variant === LeftSide.LIGHT} className={styles.logo} />
        ))}
    </div>
  );
};

LeftSide.LIGHT = 'light';

LeftSide.propTypes = {
  className: PropTypes.string,
  isLogo: PropTypes.bool,
  variant: PropTypes.string,
};

LeftSide.defaultProps = {
  className: '',
  isLogo: true,
  variant: undefined,
};

export default LeftSide;
