import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const Dots = memo(({ className }) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="16" fill="#F4F5F6" />
          <circle cx="9" cy="16" r="2" fill="#515151" />
          <circle cx="16" cy="16" r="2" fill="#515151" />
          <circle cx="23" cy="16" r="2" fill="#515151" />
        </svg>
      </div>
    ),
    [className],
  ),
);

Dots.propTypes = {
  className: PropTypes.string,
};

Dots.defaultProps = {
  className: '',
};

export default Dots;
