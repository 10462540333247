export const DEFAULT_PAGE_SIZE = 10;

export enum SortingOrder {
  Asc = 'asc',
  Desc = 'desc',
}

// You can add filter types(date picker, multiple/single select etc.)
export enum FilterType {
  Search = 'Search',
}
