import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Wrapper as PendingWrapper } from 'components';
import { getSearchResultsSelector } from 'store/selectors/searchResults';
import { PropertiesWrapper, PropertiesList } from 'pages/Properties/SearchResults/components';
import { useNoMlsAccessHandler } from 'pages/Properties/Feed/Properties/hooks/useNoMlsAccessHandler';
import { getSearchResultsV2Effect } from 'store/effects/searchResults/searchV2';
import { LocationService } from 'services';
import { ListSkeleton } from 'components-antd';

import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';

const locationSrv = new LocationService();

const Properties = (props) => {
  const { id, wrapperClassName } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const { isPending, properties, totalCount } = useSelector(getSearchResultsSelector);
  // Info: This is for new listing email button "edit Search Criteria"
  locationSrv.setLocation(location);
  const { hasMlsAccess } = useNoMlsAccessHandler(getSearchResultsV2Effect);

  // Already being called on first render at src/pages/Properties/SearchResults/LocalHeader/index.js
  // useEffect(() => {
  //   if (searchInstanceId || search) {
  //     // INFO: run only if there is searchInstanceId in url as query string that is set from BE while sending new listing notifications
  //     if (isSearchQuerySet) {
  //       dispatch(getSearchResultsEffectWrapped());
  //     }
  //   }
  // }, [isSearchQuerySet]);

  return (
    <PropertiesWrapper id={id} count={totalCount?.value} countType={totalCount?.type}>
      {(wrapperSize, getNextPageIsPending) => (
        <PendingWrapper
          isPending={isPending}
          className={styles.pendingWrapper}
          customLoader={<ListSkeleton />}
        >
          <PropertiesList
            list={properties}
            wrapperSize={wrapperSize}
            getNextPageIsPending={getNextPageIsPending}
            hasMlsAccess={hasMlsAccess}
            wrapperClassName={wrapperClassName}
          />
        </PendingWrapper>
      )}
    </PropertiesWrapper>
  );
};

Properties.propTypes = {
  id: PropTypes.string,
};

Properties.defaultProps = {
  id: '',
};

export default Properties;
