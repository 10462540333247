import { handleActions } from 'redux-actions';

import {
  openThreadContactsDrawerAction,
  changeThreadContactsDrawerParamsAction,
} from 'store/actions/drawers/threadContacts';
import { appLogoutAction } from 'store/actions/app';
import { DRAWER_THREAD_CONTACTS_TYPES } from 'settings/constants/drawers';

const initialData = {
  open: false,
  params: undefined,
  type: DRAWER_THREAD_CONTACTS_TYPES.CONTACTS_LIST,
};

export default handleActions(
  {
    [openThreadContactsDrawerAction]: (state, { payload }) => ({
      ...initialData,
      open: !!payload.open,
      type: payload.type,
      params: payload.params,
    }),
    [changeThreadContactsDrawerParamsAction]: (state, { payload }) => ({
      ...state,
      type: payload.type,
      params: payload.params,
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
