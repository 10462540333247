/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { chunk } from 'lodash-es';
import { useSelector } from 'react-redux';

import { useScreen } from 'hooks';
import { pushOrRemoveStageOptionObj } from 'pages/OnBoardingWizard/helpers';
import { AMENITIES_OPTIONS, prefsIds } from 'settings/constants/preferences';
import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { PropertyType } from 'components';
import Icon from '../Icons';

import { Row, Col } from 'antd';

import styles from './styles.module.scss';

const Options = (props) => {
  const { className, onChange, value, disabled, testid } = props;
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const [actives, setActives] = useState(value);
  const { screen } = useScreen();
  const isHomeTypeCondo =
    defaultClientSearch?.HomeType?.length &&
    defaultClientSearch.HomeType.includes(PropertyType.CONDO);

  const onClick = useCallback(
    (type) => {
      const newActives = pushOrRemoveStageOptionObj({ obj: actives, key: type });

      setActives(newActives);
      onChange(newActives);
    },
    [onChange, actives],
  );

  const getOptions = () => {
    const amenitiesOptions = isHomeTypeCondo
      ? AMENITIES_OPTIONS
      : AMENITIES_OPTIONS.filter(
          (opt) => ![prefsIds.elevator, prefsIds.rooftop, prefsIds.dogRun].includes(opt.id),
        );

    if (screen.width <= 576) {
      return chunk(amenitiesOptions, 1);
    }
    return chunk(amenitiesOptions, 1);
  };

  const renderOptions = () =>
    getOptions().map((option, index) =>
      option.map(({ id, label }) => (
        <Col xs={24} sm={12} md={8}>
          <div
            key={id}
            onClick={() => onClick(id)}
            className={classNames(styles.option, { [styles.active]: id in actives })}
            testid={testid}
          >
            <div className={styles.optionInner}>
              <Icon className={styles.icon} variant={id} />
              <p className={classNames(styles.title, { [styles.labelActive]: id in actives })}>
                {label}
              </p>
            </div>
          </div>
        </Col>
      )),
    );

  return (
    <div className={classNames(styles.wrapper, { [styles.disabled]: disabled }, className)}>
      <Row gutter={[24, 24]} className={styles.optionHolder}>
        {renderOptions()}
      </Row>
    </div>
  );
};

Options.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.shape({}),
  disabled: PropTypes.bool,
  testid: PropTypes.string,
};

Options.defaultProps = {
  className: '',
  onChange: () => {},
  value: {},
  disabled: false,
  testid: undefined,
};

export default Options;
