import { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { deleteTimelineTemplateEffect } from 'store/effects/template';
import { getAllTimelineTemplatesEffect } from 'store/effects/templates';
import {
  getTimelineTemplatesDataSelector,
  getTimelineTemplatesSelector,
} from 'store/selectors/templates';
import { getUserId, getUserRoleSelector } from 'store/selectors/user';
import { Wrapper, ConfirmationDialog } from 'components';
import { NoTemplatesFound } from '../components/NoTemplates';
import { TemplateHeader } from '../components/TemplateHeader';
import { TimelinesModal } from './components/TimelinesModal';
import { TemplateCard } from '../components/TemplateCard';
import { Role } from 'app-constants';

import styles from './styles.module.scss';

export const Timelines = (props) => {
  const { className } = props;
  const templates = useSelector(getTimelineTemplatesDataSelector);
  const dispatch = useDispatch();
  const [templateToDelete, setTemplateToDelete] = useState<any>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isNewModal, setIsNewModal] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [query, setQuery] = useState('');
  const userRole = useSelector(getUserRoleSelector);
  const userId = useSelector(getUserId);

  const [showAgentTemplates, setShowAgentTemplates] = useState<boolean>(false);

  const { isIdle: isTimelineTemplatesIdle, isPending: isTimelineTemplatesIsPending } = useSelector(
    getTimelineTemplatesSelector,
  );

  useEffect(() => {
    dispatch(
      getAllTimelineTemplatesEffect(
        {
          ...(showAgentTemplates && { agentTemplates: true }),
        },
        isTimelineTemplatesIdle ? null : { silent: true },
      ),
    );
  }, [showAgentTemplates]); // eslint-disable-line

  const onTemplateView = (id) => onTemplateEdit(id, null, true);

  const onTemplateEdit = (id, template, viewMode = false) => {
    setIsViewMode(viewMode);
    setShowModal(true);
    setIsNewModal(false);
    setTemplateId(id);
  };

  const onTemplateDelete = useCallback(
    (template) => {
      setTemplateToDelete(template);
    },
    [setTemplateToDelete],
  );

  const onConfirmDeleteItem = useCallback(() => {
    if (!templateToDelete) return;

    setIsDeleting(true);

    dispatch(
      deleteTimelineTemplateEffect({ id: templateToDelete.Id }, {}, (err) => {
        if (!err) {
          setTemplateToDelete(null);
        }
        setIsDeleting(false);
      }),
    );
  }, [dispatch, templateToDelete, setTemplateToDelete]);

  const onCloseDeleteItemModal = useCallback(
    () => setTemplateToDelete(null),
    [setTemplateToDelete],
  );

  const deleteTemplateContent = useMemo(
    () =>
      templateToDelete ? (
        <div testid="modal_title">
          Are you sure that you want to <b>delete</b> the <b>{templateToDelete.Name}</b> timeline
          template?
        </div>
      ) : null,
    [templateToDelete],
  );
  const onToggleAgentTemplateView = () => setShowAgentTemplates((prev) => !prev);
  const filteredTemplates = useMemo(() => {
    if (showAgentTemplates && userRole === Role.SuperUser) {
      return templates?.filter((template) => template.CreatorId !== userId);
    } else if (!showAgentTemplates && userRole === Role.SuperUser) {
      return templates?.filter((template) => template.CreatorId === userId);
    }
    return templates;
  }, [templates, showAgentTemplates]);
  return (
    <Wrapper isPending={isTimelineTemplatesIsPending}>
      <TemplateHeader
        label="Timeline Templates"
        onNew={() => {
          setIsNewModal(true);
          setShowModal(true);
          setIsViewMode(false);
        }}
        onSearch={(val) => setQuery(val)}
        onClear={() => setQuery('')}
        value={query}
        onToggleAgentTemplateView={onToggleAgentTemplateView}
        showAgentTemplates={showAgentTemplates}
      />
      <div className={classNames(styles.wrapper, className)}>
        {filteredTemplates?.length ? (
          filteredTemplates
            .filter((temp) => temp.Name.toLowerCase().includes(query) || temp.Name.includes(query))
            .map((template) => (
              <TemplateCard
                key={template?.Id}
                onDelete={onTemplateDelete}
                template={template}
                onEdit={onTemplateEdit}
                onView={onTemplateView}
                className="templateCard"
              />
            ))
        ) : (
          <NoTemplatesFound className={styles.noTemplates} templatesName="timeline" />
        )}
        <ConfirmationDialog
          onReject={onCloseDeleteItemModal}
          onConfirm={onConfirmDeleteItem}
          isOpen={!!templateToDelete}
          isPending={isDeleting}
        >
          {deleteTemplateContent}
        </ConfirmationDialog>
        <TimelinesModal
          isOpen={showModal}
          isNew={isNewModal}
          isViewMode={isViewMode}
          templateId={templateId}
          setOpenTaskModal={() => {
            setShowModal(false);
            setTemplateId(null);
          }}
        />
      </div>
    </Wrapper>
  );
};

Timelines.propTypes = {
  className: PropTypes.string,
};

Timelines.defaultProps = {
  className: '',
};
