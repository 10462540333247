import { useEffect, useState } from 'react';

import { Table } from 'components-antd';
import styles from './styles.module.scss';
import { SpinProps } from 'antd';

interface CollapsibleTableProps {
  title: string;
  defaultCollapse?: Boolean;
  loading?: boolean | SpinProps | undefined;
  dataSource: any;
  onRow?: any;
  columns: any[];
  tableClassName?: string;
  disableCollapse?: boolean;
  showCollapseIcon?: boolean;
  rowClassName?: any;
}

export const CollapsibleTable = ({
  title,
  defaultCollapse = false,
  loading = false,
  dataSource,
  onRow = null,
  columns,
  tableClassName,
  disableCollapse = false,
  showCollapseIcon = false,
  rowClassName = '',
}: CollapsibleTableProps) => {
  const [collapsed, setIsCollapsed] = useState(defaultCollapse);

  useEffect(() => {
    setIsCollapsed(defaultCollapse);
  }, [defaultCollapse]);

  if (dataSource?.length === 0) return null;

  function onClick() {
    if (disableCollapse) return;
    setIsCollapsed(!collapsed);
  }

  return (
    <div>
      <p className={styles.tableTitle} onClick={onClick}>
        {title} {collapsed && <span className={styles.countText}>({dataSource?.length})</span>}
      </p>
      {!collapsed && (
        <Table
          className={tableClassName}
          loading={loading}
          bordered={false}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowKey="id"
          onRow={onRow}
          rowClassName={rowClassName}
        />
      )}
    </div>
  );
};
