import React from 'react';

const Drag = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0H0V2H2V0ZM2 4H0V6H2V4ZM0 8H2V10H0V8ZM6 0H4V2H6V0ZM4 4H6V6H4V4ZM6 8H4V10H6V8Z"
        fill="#AAABAB"
      />
    </svg>
  </div>
);

export default Drag;
