import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash-es';

import styles from './styles.module.scss';

const PriceHistory = ({ items }) => (
  <div className={styles.priceHistory}>
    <div className={styles.priceHistoryHeader}>
      <div>Date</div>
      <div>Event</div>
      <div>Price</div>
    </div>
    {map(items, ({ date, event, price }, index) => (
      <div key={index} className={styles.priceHistoryRow}>
        <div>{date || '-'}</div>
        <div>{event || '-'}</div>
        <div>{price || '-'}</div>
      </div>
    ))}
  </div>
);

PriceHistory.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      event: PropTypes.string,
      price: PropTypes.string,
    }),
  ).isRequired,
};

export default PriceHistory;
