export default {
  workshopForms: '/workshop/forms',
  workshopFormProcessRequest: '/workshop/form/request',
  workshopFormProcessRequestUpdate:
    '/workshop/form/request/update/:formProcessPublicId/:formDocumentPublicId',
  workshopFormProcessDocument:
    '/workshop/form/process/auth/:formProcessPublicId/:formDocumentPublicId',
  workshopFormNotAvailable: '/workshop/form/process/notavailable',
  workshopFormProcessDocumentAnon: '/workshop/form/process/anon/:token/:type',
  workshopFormSendDocument: 'workshop/form/send',
  workshopFormProcessDocumentWebView:
    '/workshop/form/process/auth/webView/:formProcessPublicId/:formDocumentPublicId',
  // Dynamic forms
  workshopDynamicFormDocument:
    '/workshop/dynamicForm/auth/:formProcessPublicId/:formDocumentPublicId',
  //Dynamic Template
  workshopDynamicTemplateForm: '/workshop/dynamicTemplateForm/auth/:templateId',
  workshopDynamicFormDocumentAnon: '/workshop/dynamicForm/anon/:token/:type',
  workshopFormsSignatoriesRequest: '/workshop/forms/signatories/request',
  workshopFormTemplateRequest: '/workshop/templateFormRequest/auth/:templateId',
  workshopFormBundleTemplateRequest: '/workshop/templateFormRequest/auth',
  workshopTemplateFormsSuccess: '/workshop/forms/template/success',
  workshopDynamicFormSuccess: '/workshop/dynamicForm/anon/success',

  //Dynamic Bundle Form
  workshopDynamicFormBundle: '/workshop/dynamicBundleForm/auth/:formProcessPublicId',

  //Dynamic Bundle Template
  workshopDynamicTemplateBundle: '/workshop/dynamicBundleTemplate/auth',
};
