import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import Details from './Details';
import MetaInfo from './MetaInfo';

const UserQuote = (props) => {
  const { className, detailsClassName, metaClassName } = props;

  return (
    <div className={classNames(styles.userQuote, className)}>
      <Details className={detailsClassName} />
      <MetaInfo className={metaClassName} />
    </div>
  );
};

UserQuote.propTypes = {
  className: PropTypes.string,
  detailsClassName: PropTypes.string,
  metaClassName: PropTypes.string,
};

UserQuote.defaultProps = {
  className: '',
  detailsClassName: '',
  metaClassName: '',
};

export default UserQuote;
