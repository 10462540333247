import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { addNewFormInDynamicViewEffect, updateDynamicFormEffect } from 'store/effects/formProcess';
import { getDynamicFormSelector } from 'store/selectors/requestFormProcess';
import { FormProcessDataType } from 'types';
import { getMergedDetails, prepareFileFormData } from '../../helper';
import { sortDocuments } from 'pages/FormProcess/DynamicForm';

export const useAddFormsInBundle = (props) => {
  const [adding, setAdding] = useState(false);
  const { formProcessPublicId } = useParams<FormProcessDataType>();
  const {
    bundleDocumentsDetails = [],
    dynamicFormDocuments = [],
    ...rest
  } = useSelector(getDynamicFormSelector);

  const { rowKeyRecords, onClose } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const mangeLibraryFormsForDynamicView = (libraryForm?) => {
    let documentLinks: string[] = [];

    let formName = libraryForm?.name || rowKeyRecords[0].name;

    if (libraryForm) {
      documentLinks = [
        ...(libraryForm.link ? [libraryForm.link] : libraryForm.templateLinks || []),
      ];
    } else {
      rowKeyRecords.forEach((item) => {
        documentLinks = [
          ...documentLinks,
          ...(item.link ? [item.link] : []),
          ...(item.templateLinks ? item.templateLinks : []),
        ];
      });
    }

    addFormsInDynamicBundle({ formName, documents: documentLinks });
  };

  const updateDocumentsInState = (documents) => {
    history.replace(location.pathname, {
      ...(history.location.state || {}),
      ...{ allDocuments: documents },
    });
  };

  const prepareRequest = (formDetails) => {
    let requestBody: any = {};

    if (formDetails.files) {
      let formData = new FormData();
      formData.append('formProcessPublicId', formProcessPublicId || '');

      formData = prepareFileFormData(formDetails.files, formData);
      requestBody.formData = formData;
    } else {
      requestBody.formDetails = { ...formDetails, formProcessPublicId };
    }

    return requestBody;
  };

  const addFormsInDynamicBundle = async (formDetails) => {
    setAdding(true);

    const fetchDocumentCB = () => {
      dynamicManager.getDocument(null, () => {
        let oldBundleDetails: any = [...bundleDocumentsDetails];
        const newBundleDetails = dynamicManager.getBundleDocumentsDetails();

        let oldDynamicDocuments: any = [...dynamicFormDocuments];
        const newDynamicFormDocuments = dynamicManager.getDynamicDocuments();

        if (!oldBundleDetails.length) {
          const { editorConfig, meta, screen, strikeThrough } = rest;

          oldBundleDetails = [
            {
              documents: newBundleDetails[0].documents,
              formRoles: newBundleDetails[0].formRoles,
              isDynamicForm: true,
              meta,
              screen,
              strikeThrough,
              questions: editorConfig?.questions || {},
              PublicId: dynamicManager.getAuthDocumentPublicId(),
            },
          ];
        }

        const preparedBundles = getMergedDetails(oldBundleDetails, newBundleDetails);
        const preparedDynamicFormDocuments = getMergedDetails(
          oldDynamicDocuments,
          newDynamicFormDocuments,
        );

        dispatch(
          updateDynamicFormEffect({
            bundleDocumentsDetails: preparedBundles,
            dynamicFormDocuments: preparedDynamicFormDocuments,
          }),
        );

        updateDocumentsInState(newBundleDetails[0].documents);

        onClose?.();
        setAdding(false);
      });
    };

    try {
      const requestBody = prepareRequest(formDetails);
      const response: any = await dispatch(addNewFormInDynamicViewEffect(requestBody));

      if (dynamicManager.hasBundleData()) {
        const { value: documents } = response.data;

        dispatch(
          updateDynamicFormEffect({ dynamicFormDocuments: sortDocuments(documents, 'Order') }),
        );

        fetchDocumentCB();
      } else {
        fetchDocumentCB();
      }
    } catch (error) {
      setAdding(false);
    }
  };

  return { addFormsInDynamicBundle, adding, mangeLibraryFormsForDynamicView };
};
