import React, { memo, useMemo } from 'react';

const QuoteForm = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div {...props}>
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.333 3.5V8.16667C16.333 8.47609 16.4559 8.77283 16.6747 8.99162C16.8935 9.21042 17.1903 9.33333 17.4997 9.33333H22.1663"
            stroke="#B14C99"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.833 24.5H8.16634C7.5475 24.5 6.95401 24.2542 6.51643 23.8166C6.07884 23.379 5.83301 22.7855 5.83301 22.1667V5.83333C5.83301 5.21449 6.07884 4.621 6.51643 4.18342C6.95401 3.74583 7.5475 3.5 8.16634 3.5H16.333L22.1663 9.33333V22.1667C22.1663 22.7855 21.9205 23.379 21.4829 23.8166C21.0453 24.2542 20.4518 24.5 19.833 24.5Z"
            stroke="#B14C99"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.6946 21.0909V10.1818H14.3935V21.0909H13.6946ZM15.3778 13.8892C15.3494 13.5767 15.223 13.3338 14.9986 13.1605C14.777 12.9844 14.4602 12.8963 14.0483 12.8963C13.7756 12.8963 13.5483 12.9318 13.3665 13.0028C13.1847 13.0739 13.0483 13.1719 12.9574 13.2969C12.8665 13.419 12.8196 13.5597 12.8168 13.7188C12.8111 13.8494 12.8366 13.9645 12.8935 14.0639C12.9531 14.1634 13.0384 14.2514 13.1491 14.3281C13.2628 14.402 13.3991 14.4673 13.5582 14.5241C13.7173 14.581 13.8963 14.6307 14.0952 14.6733L14.8452 14.8438C15.277 14.9375 15.6577 15.0625 15.9872 15.2188C16.3196 15.375 16.598 15.5611 16.8224 15.777C17.0497 15.9929 17.2216 16.2415 17.3381 16.5227C17.4545 16.804 17.5142 17.1193 17.517 17.4688C17.5142 18.0199 17.375 18.4929 17.0994 18.8878C16.8239 19.2827 16.4276 19.5852 15.9105 19.7955C15.3963 20.0057 14.7756 20.1108 14.0483 20.1108C13.3182 20.1108 12.6818 20.0014 12.1392 19.7827C11.5966 19.5639 11.1747 19.2315 10.8736 18.7855C10.5724 18.3395 10.4176 17.7756 10.4091 17.0938H12.429C12.446 17.375 12.5213 17.6094 12.6548 17.7969C12.7884 17.9844 12.9716 18.1264 13.2045 18.223C13.4403 18.3196 13.7131 18.3679 14.0227 18.3679C14.3068 18.3679 14.5483 18.3295 14.7472 18.2528C14.9489 18.1761 15.1037 18.0696 15.2116 17.9332C15.3196 17.7969 15.375 17.6406 15.3778 17.4645C15.375 17.2997 15.3239 17.1591 15.2244 17.0426C15.125 16.9233 14.9716 16.821 14.7642 16.7358C14.5597 16.6477 14.2983 16.5668 13.9801 16.4929L13.0682 16.2798C12.3125 16.1065 11.7173 15.8267 11.2827 15.4403C10.848 15.0511 10.6321 14.5256 10.6349 13.8636C10.6321 13.3239 10.777 12.8509 11.0696 12.4446C11.3622 12.0384 11.767 11.7216 12.2841 11.4943C12.8011 11.267 13.3906 11.1534 14.0526 11.1534C14.7287 11.1534 15.3153 11.2685 15.8125 11.4986C16.3125 11.7259 16.7003 12.0455 16.9759 12.4574C17.2514 12.8693 17.392 13.3466 17.3977 13.8892H15.3778Z"
            fill="#B14C99"
          />
          <path d="M14 10.5V20.5" stroke="#B14C99" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </div>
    ),
    [props.className],
  );

export default memo(QuoteForm);
