import classNames from 'classnames';
import { OfferStatus } from 'types';

import styles from './styles.module.scss';

export const Status = ({ status }) => {
  function getStatusClassName() {
    switch (status) {
      case OfferStatus.Pending:
        return styles.pending;
      case OfferStatus.Accepted:
        return styles.accepted;
      case OfferStatus.Rejected:
        return styles.rejected;
      case OfferStatus.CounterReceived:
        return styles.counterRecieved;
      case OfferStatus.CounterSent:
        return styles.counterSent;
      case OfferStatus.Expired:
        return styles.expired;
      case OfferStatus.Withdrawn:
        return styles.withdrawn;
      case OfferStatus.Cancelled:
        return styles.withdrawn;
      case 'New':
        return styles.new;
      default:
        return '';
    }
  }

  return (
    <div className={classNames(styles.offerStatus, getStatusClassName())} testid="task_status">
      <span className={styles.statusText}>{status.replace(/([A-Z])/g, ' $1')}</span>
    </div>
  );
};
