import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Select as CustomSelect } from 'components';
import { Select } from 'components-antd';
import SearchGlass from 'components/Icons/SearchGlass';
import { CLIENT } from 'settings/constants/roles';
import { openLocationModalEffect } from 'store/effects/concierge';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import { getUserRoleSelector } from 'store/selectors/user';
import { LocationModal } from '../LocationModal';
import {
  updateConciergeCategoryConfiguration,
  updateConciergeServicesConfiguration,
} from 'store/actions/concierge';

import styles from './styles.module.scss';

type Props = {
  isSearch: boolean;
  setIsSearch: (arg0: boolean) => void;
};

export const Search: React.FC<Props> = ({ isSearch: isResult, setIsSearch }) => {
  const dispatch = useDispatch();

  const getServiceOptions = (id) => {
    if (id) {
      const category = allCategories.filter((category) => category.Id === Number(id))[0];

      return [...((category ?? {}).Tags?.map(({ Id, Name }) => ({ name: Name, value: Id })) || [])];
    }

    return [];
  };

  const allCategories = useSelector(getAllServicesCategoriesList);
  const {
    areasOfOperation,
    category: selectedCategoryId,
    services: selectedServices,
  } = useSelector(getConciergeSearchSelector);
  const [serviceOptions, setServiceOptions] = useState<any[]>(
    getServiceOptions(selectedCategoryId),
  );

  const userRole = useSelector(getUserRoleSelector);
  const [isCategoryError, setIsCategoryError] = useState<boolean | undefined>();
  const [isLocationError, setIsLocationError] = useState<boolean | undefined>();

  useEffect(() => {
    if (typeof isCategoryError === 'boolean') {
      selectedCategoryId ? setIsCategoryError(false) : setIsCategoryError(true);
    }
    selectedCategoryId
      ? setServiceOptions(getServiceOptions(selectedCategoryId))
      : setServiceOptions([]);
  }, [isCategoryError, selectedCategoryId]);

  useEffect(() => {
    if (typeof isLocationError === 'boolean') {
      !isEmpty(areasOfOperation) ? setIsLocationError(false) : setIsLocationError(true);
    }
  }, [isLocationError, areasOfOperation]);

  const openModal = () => {
    dispatch(openLocationModalEffect(true));
  };

  const allCategoriesOptions = useMemo(() => {
    return allCategories.map((category) => ({ label: category.Title, value: category.Id }));
  }, [allCategories]);

  const handleCategoryChange = (value) => {
    dispatch(updateConciergeCategoryConfiguration(value.toString()));
    dispatch(updateConciergeServicesConfiguration([]));
    setServiceOptions(getServiceOptions(value));
  };

  const handleServicesChange = (values) => {
    dispatch(updateConciergeServicesConfiguration(values));
  };

  const handleSearch = () => {
    if (!selectedCategoryId) {
      setIsCategoryError(true);
      return;
    }
    if (isEmpty(areasOfOperation) && userRole === CLIENT) {
      setIsLocationError(true);
      return;
    }

    setIsSearch(true);
  };

  return (
    <>
      <div
        className={classNames(styles.searchContainer, {
          [styles.isResult]: isResult,
        })}
      >
        <div
          className={classNames(styles.searchItems, {
            [styles.isResult]: isResult,
          })}
        >
          <div className={styles.searchItemContainer}>
            <div
              className={classNames(styles.heading, {
                [styles.isResult]: isResult,
                [styles.error]: typeof isCategoryError === 'boolean' && isCategoryError === true,
              })}
            >
              Category
            </div>
            <div>
              <Select
                options={allCategoriesOptions}
                className="mosaikConceirgeSelect"
                placeholder="Select category..."
                placement="bottomLeft"
                dropdownMatchSelectWidth={false}
                popupClassName="mosaikConceirgeSelectDropdown"
                showSearch
                optionFilterProp="children"
                value={selectedCategoryId ? +selectedCategoryId : undefined}
                filterOption={(input, option) =>
                  ((option?.label as string).toLowerCase() || '').includes(input.toLowerCase())
                }
                onChange={handleCategoryChange}
              />
            </div>
          </div>
          <div className={styles.searchItemContainer} onClick={openModal}>
            <div
              className={classNames(styles.heading, {
                [styles.isResult]: isResult,
                [styles.error]: typeof isLocationError === 'boolean' && isLocationError === true,
              })}
            >
              Location
            </div>
            {areasOfOperation?.length > 0 ? (
              <div className={styles.locationItems}>
                {areasOfOperation.map((location, index) => (
                  <div key={`search-location--${index}`}>
                    {location.City}, {location.State}
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.placeholder}>Select location...</div>
            )}
          </div>
          <div className={styles.searchItemContainer}>
            <div
              className={classNames(styles.heading, {
                [styles.isResult]: isResult,
              })}
            >
              Services
            </div>
            <div>
              <CustomSelect
                multiple
                className={{
                  wrapper: classNames(styles.serviceField, {
                    [styles.isResult]: isResult,
                  }),
                }}
                placeholder="Select services..."
                options={serviceOptions}
                onSelect={(val) => handleServicesChange(val.target.value.map(({ value }) => value))}
                closeOnSelect={false}
                isArrowIcon={false}
              />
            </div>
          </div>
        </div>
        <div className={styles.searchBtnWrapper} onClick={handleSearch}>
          <div
            className={classNames(styles.searchBtn, {
              [styles.isResult]: isResult,
              [styles.inActive]:
                !selectedCategoryId || userRole === CLIENT ? isEmpty(areasOfOperation) : false,
            })}
          >
            <SearchGlass
              color="#FFFFFF"
              strokeWidth="2"
              width={isResult ? '20' : '24'}
              height={isResult ? '20' : '24'}
            />
          </div>
        </div>
        {isCategoryError && (
          <div className={styles.errorMessage}>*Please select a category to search partners</div>
        )}
        {isLocationError && (
          <div className={styles.errorMessage}>*Please select a location to search partners</div>
        )}
      </div>
      <LocationModal />
    </>
  );
};
