import {
  ADD_FORM_FOLDER,
  DELETE_FORM_FOLDER,
  EDIT_FORM_FOLDER,
  GET_FOLDER_LIST,
} from 'app-constants';
import { apiServer } from 'settings/web-services/api';

export const getFolderList = () => apiServer.get(GET_FOLDER_LIST);

export const addFormFolder = ({ name }: { name: string }) =>
  apiServer.post(ADD_FORM_FOLDER, {
    name,
  });

export const editFormFolder = ({ folderId, name }: { folderId: number; name: string }) =>
  apiServer.patch(EDIT_FORM_FOLDER(folderId), {
    name,
  });

export const deleteFormFolder = ({ folderId }: { folderId: number }) =>
  apiServer.delete(DELETE_FORM_FOLDER(folderId));
