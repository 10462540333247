import { handleActions } from 'redux-actions';
import { cloneDeep } from 'lodash-es';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetCategoriesAction,
  sortCategoriesAction,
  searchCategoriesAction,
  requestDeleteDeactivateCategoryAction,
  requestPutActivateCategoryAction,
} from 'store/actions/adminPanel';

import {
  handleActivateItem,
  handleDeactivateItem,
  handleGetListOfItems,
  searchItems,
  sortItems,
} from '../helpers';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  sort: {
    order: 'asc',
    fields: ['Title'],
  },
  search: {
    searchString: '',
    type: '',
  },
};

export default handleActions(
  {
    [requestGetCategoriesAction]: handleGetListOfItems(initialData),
    [requestDeleteDeactivateCategoryAction]: handleDeactivateItem,
    [requestPutActivateCategoryAction]: handleActivateItem,
    [sortCategoriesAction]: sortItems,
    [searchCategoriesAction]: searchItems,
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
