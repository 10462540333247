import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { LocationService } from 'services';
import { getClientDashboardEligibility } from 'api/dashboard';
import { Spinner } from 'components';
import { DrawerType } from 'types/drawers';
import {
  ClientSearchDashboard,
  ClientTransactionsDashboard,
  ClientMyHomeDashboard,
} from './dashboards';
import { useSelector, useDispatch } from 'react-redux';
import { openMessagesDrawerAction } from 'store/actions/drawers/messages';
import { getIsMessagesDrawerOpenSelector } from 'store/selectors/drawers/messages';
import styles from './styles.module.scss';
import { routes } from 'settings/navigation/routes';
import { ClientAddress } from 'app-constants';
import { getUserFirstName, getUserId } from 'store/selectors/user';
import { SearchGlass, ArrowLeftRight, ChainIcon } from 'components/Icons';
import { useScreen } from 'hooks';
import { ClientDashboardTabs } from 'pages/Main/ClientDashboard/components/ClientDashboardTabs';
import { ClientMyPortfolioDashboard } from './dashboards/ClientMyPortfolioDashboard';
import { getCurrentContextSelector } from 'store/selectors/context';

const locationService = new LocationService();
const defaultDashboardTypes: { value: string; title: string }[] = [];
const defaultDashboardEligibility: ClientDashboardEligibility = {
  eligibleForMyHome: {
    eligibility: false,
    eligibleProperties: [],
  },
  eligibleForMySearch: false,
  eligibleForMyTransaction: false,
};

export const ClientDashboard = () => {
  const { screen } = useScreen();

  const isMessagesDrawerOpenSelector = useSelector(getIsMessagesDrawerOpenSelector);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { drawer } = locationService.setLocation(location).getQuery();
  const name = useSelector(getUserFirstName);
  const searchDataLoaded = useRef(false);
  const history = useHistory();
  const selectedContext = useSelector(getCurrentContextSelector);
  const clientId = useSelector(getUserId);
  const [activeTab, setActiveTab] = useState<string>(DashboardType.MyTransactions);
  const clientSearchLoaded = useRef(false);
  const [dashboardEligibility, setDashboardEligibility] = useState<ClientDashboardEligibility>(
    defaultDashboardEligibility,
  );
  const [isLoading, setIsLoading] = useState(false);

  const dashboardTypes = useMemo(() => {
    const types: { value: string; title: string; icon: any }[] = [];

    if (dashboardEligibility?.eligibleForMySearch) {
      types.push({
        value: DashboardType.MySearch,
        title: screen.mobileWidth ? 'Search' : 'My Search',
        icon: SearchGlass,
      });
    }
    if (dashboardEligibility?.eligibleForMyTransaction) {
      types.push({
        value: DashboardType.MyTransactions,
        title: screen.mobileWidth ? 'Transactions' : 'My Transactions',
        icon: ArrowLeftRight,
      });
    }
    // if (dashboardEligibility?.eligibleForMyHome?.eligibility) {
    //   types.push({
    //     value: DashboardType.MyHome,
    //     title: screen.mobileWidth ? 'Portfolio' : 'My Portfolio',
    //     icon: ChainIcon,
    //   });
    // }

    return types;
  }, [dashboardEligibility, screen.mobileWidth]);

  const fetchEligibleDashboards = async () => {
    try {
      setIsLoading(true);
      const stats = await getClientDashboardEligibility({
        clientId,
        contextId: selectedContext.ContextKey,
      });

      setDashboardEligibility(stats?.data || defaultDashboardEligibility);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedContext?.ContextKey) {
      fetchEligibleDashboards();
    }
  }, [selectedContext?.ContextKey]);

  useEffect(() => {
    if (drawer === DrawerType.message && !isMessagesDrawerOpenSelector) {
      dispatch(openMessagesDrawerAction(true));
    }
  }, []);

  useEffect(() => {
    if (
      !dashboardEligibility.eligibleForMyTransaction &&
      dashboardEligibility?.eligibleForMySearch
    ) {
      setActiveTab(DashboardType.MySearch);
    }
  }, [dashboardEligibility]);

  const shouldShowAnyDashboard = () => {
    return (
      dashboardEligibility.eligibleForMyHome?.eligibility ||
      dashboardEligibility.eligibleForMySearch ||
      dashboardEligibility.eligibleForMyTransaction
    );
  };

  const showDashboard = () => {
    // if (pathname === routes.index) {
    //   history.replace(routes.clientDashboard);
    //   return;
    // }

    if (
      activeTab === DashboardType.MyTransactions &&
      dashboardEligibility.eligibleForMyTransaction
    ) {
      return <ClientTransactionsDashboard />;
    }

    if (activeTab === DashboardType.MySearch && dashboardEligibility.eligibleForMySearch) {
      return <ClientSearchDashboard dataLoaded={clientSearchLoaded} />;
    }

    if (activeTab === DashboardType.MyHome && dashboardEligibility.eligibleForMyHome?.eligibility) {
      return (
        <ClientMyPortfolioDashboard
          addresses={dashboardEligibility?.eligibleForMyHome?.eligibleProperties}
          setDashboardEligibility={setDashboardEligibility}
        />
      );
    }

    return;
  };

  const isOnlyTransactionVisible = useMemo(() => {
    return (
      dashboardEligibility?.eligibleForMyTransaction && !dashboardEligibility?.eligibleForMySearch
    );
  }, [dashboardEligibility]);

  const isOnlySearchVisible = useMemo(() => {
    return (
      !dashboardEligibility?.eligibleForMyTransaction && dashboardEligibility?.eligibleForMySearch
    );
  }, [dashboardEligibility]);

  const header = () => (
    <header className={styles.dashboardHeader}>
      <div className={styles.headerInner}>
        <div className={styles.headerNameWrapper}>
          <span>Hey, {name}! 👋</span>
        </div>
        {shouldShowAnyDashboard() && (
          <ClientDashboardTabs
            mode="horizontal"
            activeKey={activeTab}
            links={dashboardTypes}
            setActiveTab={setActiveTab}
            className={
              isOnlyTransactionVisible
                ? styles.marginLeft
                : isOnlySearchVisible
                ? styles.marginRight
                : ''
            }
          />
        )}
      </div>
    </header>
  );

  return shouldShowAnyDashboard() ? (
    <div className={styles.mainContentWrapper}>
      {header()}
      {showDashboard()}
    </div>
  ) : isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.mainContentWrapper}>
      {header()}
      <div className={styles.emptyContainer}>{`You're all set for now.`}</div>
    </div>
  );
};

export type ClientDashboardEligibility = {
  eligibleForMyTransaction: boolean;
  eligibleForMySearch: boolean;
  eligibleForMyHome: {
    eligibility: boolean;
    eligibleProperties: ClientAddress[];
  };
};

const DashboardType = {
  MyHome: routes.clientDashboardMyHome,
  MyTransactions: routes.clientDashboardMyTransactions,
  MySearch: routes.clientDashboardMySearch,
};
