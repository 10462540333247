import Api from 'store/effects/core/api';
import {
  requestGetAgentGraphsAction,
  requestGetTeamGraphsAction,
  requestGetAgentScheduleAction,
  requestGetTeamScheduleAction,
  requestGetAgentNeedsAttentionAction,
  requestGetTeamNeedsAttentionAction,
} from 'store/actions/agentDashboard';
import { getAgentDashboardGraphs, getNeedsAttention, getScheduleWidgetStats } from 'api/dashboard';

export const requestGetAgentDashboardGraphsEffect = (cfg, options = {}, cb) => {
  const requestParams = {
    action: cfg.teamStats ? requestGetTeamGraphsAction : requestGetAgentGraphsAction,
    method: getAgentDashboardGraphs,
  };
  const sendRequest = options.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const requestGetAgentDashboardNeedsAttentionEffect = (cfg, options = {}, cb) => {
  const requestParams = {
    action: cfg.teamStats
      ? requestGetTeamNeedsAttentionAction
      : requestGetAgentNeedsAttentionAction,
    method: getNeedsAttention,
  };

  const sendRequest = options.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const requestGetAgentDashboardScheduleEffect = (cfg, options = {}, cb) => {
  const requestParams = {
    action: cfg.teamStats ? requestGetTeamScheduleAction : requestGetAgentScheduleAction,
    method: getScheduleWidgetStats,
  };

  const sendRequest = options.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};
