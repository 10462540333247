import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import { useSelector } from 'react-redux';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';

import styles from './styles.module.scss';

const Actions = (props) => {
  const { className, onDownload, onExpand } = props;
  const { requestQuoteFile } = useSelector(getQuoteDetailsSelector);

  if (!requestQuoteFile?.Url) return null;

  return (
    <div className={classNames(styles.actions, className)}>
      <Icon onClick={onExpand} className={styles.action} variant={Icon.EXPAND} />
      <Icon onClick={onDownload} className={styles.action} variant={Icon.DOWNLOAD} />
    </div>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  onDownload: PropTypes.func,
  onExpand: PropTypes.func,
};

Actions.defaultProps = {
  className: '',
  onDownload: () => {},
  onExpand: () => {},
};

export default Actions;
