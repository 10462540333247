import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useOutsideClick } from 'hooks';

import { getClientAssignedToList } from 'api/agentTeamDetail';
import { reassignShareClientsEffect } from 'store/effects';
import { showSuccessMessage } from 'helpers/success';
import { Popover } from 'components-antd';
import { Icons as Icon } from 'components/ClientsDrawer/ProfileClient/ExpandedSection/Clients/components/ClientsList/Client/Options/Icons';
import { Dots } from 'components/Icons';
import { SingleMultiAvatar } from 'components/SingleMultiAvatar';
import { ReassignShareModal } from 'components/ClientsDrawer/ProfileClient/ExpandedSection/Clients/components/ReassignShareModal';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { getStateAndDataFlag } from 'store/selectors/teamList';
import { requestGetTeamListEffect } from 'store/effects';

import styles from './styles.module.scss';

export const AssignedTo = (props) => {
  const { wrapperClassName, clientInfo } = props;

  const [assignedTo, setAssignedTo] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLInputElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [loading, setLoading] = useState(false);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);
  const { isIdle: isTeamListIdle } = useSelector(getStateAndDataFlag);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isTeamListIdle && isTeamAgentActive) {
      dispatch(requestGetTeamListEffect());
    }
  }, []);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const fetchClientAssignedToList = async () => {
    const stats = await getClientAssignedToList({ id: clientInfo.Id });
    setAssignedTo(stats?.data || []);
  };

  useEffect(() => {
    fetchClientAssignedToList();
  }, []);

  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onReassignShare = (agentIds) => {
    setLoading(true);
    dispatch(
      reassignShareClientsEffect(
        {
          clientIds: [clientInfo.Id],
          agentIds,
        },
        { type: modalType.toLowerCase() },
        (err) => {
          if (!err) {
            showSuccessMessage(`Client(s) ${modalType.toLowerCase()} successful.`);
            onCloseModal();
          }
          setLoading(false);
        },
      ),
    );
  };

  const onModalOpen = (e, type) => {
    e.stopPropagation();
    setOpen(false);
    setShowModal(true);
    setModalType(type);
  };

  const onCloseModal = () => {
    fetchClientAssignedToList();
    setShowModal(false);
  };

  return (
    <>
      <div className={classNames(styles.assignedTo, wrapperClassName)}>
        <SingleMultiAvatar avatar={assignedTo} />
        <div className={styles.content}>
          <h4 className={styles.title}>
            {assignedTo?.map((agent, idx, agentArray) => {
              return (
                <span key={idx}>
                  {agent?.FirstName} {agent?.LastName}
                  {idx + 1 !== agentArray.length ? ', ' : null}
                </span>
              );
            })}
          </h4>
        </div>

        <div className={styles.optionsWrapper}>
          <div ref={buttonRef} onClick={onClickOptions} className={styles.optionsDots}>
            <Dots />
          </div>
          <Popover
            open={open}
            overlayClassName={styles.assignedToOptionsPopover}
            content={
              <div ref={optionsRef} className={styles.options}>
                <ul className={styles.dropdown}>
                  <li
                    testid="reassign_action"
                    className={styles.item}
                    onClick={(e) => onModalOpen(e, 'Reassign')}
                  >
                    <div className={styles.icon}>
                      <Icon variant={Icon.REASSIGN} />
                    </div>
                    <span>Reassign</span>
                  </li>
                  <li
                    testid="share_action"
                    className={styles.item}
                    onClick={(e) => onModalOpen(e, 'Share')}
                  >
                    <div className={styles.icon}>
                      <Icon variant={Icon.SHARE} />
                    </div>
                    <span>Share</span>
                  </li>
                </ul>
              </div>
            }
          ></Popover>
        </div>
      </div>
      <ReassignShareModal
        isOpen={showModal}
        modalType={modalType}
        onReassignShare={onReassignShare}
        onClose={onCloseModal}
        clients={[
          {
            Id: clientInfo.Id,
            FirstName: clientInfo.FirstName,
            LastName: clientInfo.LastName,
            AvatarUrl: clientInfo.AvatarUrl,
            AssignedAgentIds: assignedTo.map((item) => item.Id),
          },
        ]}
        loading={loading}
      />
    </>
  );
};
