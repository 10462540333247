import React, { useEffect, useState } from 'react';
import { Button } from 'components-antd';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { MosaikLogo } from 'components/Icons';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { FormGroup, FormTitle, FormWrapper } from 'components';
import { getUserDataSelector } from 'store/selectors/user';
import { useSelector } from 'react-redux';
import { getEmailVerificationInfo, sendVerificationEmail } from 'api/user';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import moment from 'moment';
import { OnboardingContinueButton } from '../agent/components/OnboardingContinueButton';
import { OnboardingSkipButton } from '../agent/components/OnboardingSkipButton';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

interface VerifyEmailStepProps {
  onNext: () => {};
  stageIndex: number;
  className: string;
}

export const VerifyEmailStep: React.FC<VerifyEmailStepProps> = (props) => {
  const user = useSelector(getUserDataSelector);

  const [emailVerificationData, setEmailVerificationData] = useState<
    undefined | null | EmailVerificationData
  >(undefined);

  const handleResendClick = async () => {
    try {
      const response = await sendVerificationEmail({
        email: user.Email,
      });
      setEmailVerificationData(response.data);
      showSuccessMessage('Email verification link sent');
    } catch (err) {
      showErrorMessage('Error occured while sending email verification link');
    }
  };

  useEffect(() => {
    if (user) {
      if (user.IsEmailVerified) {
        window.localStorage.removeItem('afterVerificationState');
        props.onNext();
      } else {
        window.localStorage.setItem(
          'afterVerificationState',
          JSON.stringify({
            url: location.pathname,
            stageIndex: props.stageIndex + 1,
          }),
        );
      }
    }
  }, [user]);

  // Load info about existing verification code request once screen is shown
  useEffect(() => {
    const runEffect = async () => {
      const response = await getEmailVerificationInfo();
      setEmailVerificationData(response.data);
    };

    void runEffect();
  }, []);

  const [remainingSecondsToResendLink, setRemainingSecondsToResendLink] = useState<number | null>(
    null,
  );

  // Update timer with remaining seconds for next attempt
  useEffect(() => {
    const interval = setInterval(() => {
      const remainingSeconds = moment(emailVerificationData?.AllowLinkResendTimestamp).diff(
        moment(),
        'seconds',
      );
      setRemainingSecondsToResendLink(remainingSeconds);

      if (remainingSeconds < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [emailVerificationData]);

  const getResendText = () => {
    if (remainingSecondsToResendLink === null) {
      return null;
    }

    if (remainingSecondsToResendLink > 0) {
      return <span>Resend it in {remainingSecondsToResendLink} seconds </span>;
    }

    if (remainingSecondsToResendLink <= 0) {
      return (
        <span>
          <a
            onClick={() => {
              handleResendClick();
            }}
            href="#"
            className={styles.resend}
          >
            Resend
          </a>
        </span>
      );
    }
  };

  return (
    <div className={styles.verifyEmail}>
      <Question>Please validate your email.</Question>
      <AnswersContainer>
        <p className={styles.text}>
          We just sent a validation link via email to{' '}
          <span className={styles.email}>{user.Email}</span>. If you can please take a few seconds
          to click that link, it will speed things up later.
        </p>
        {emailVerificationData &&
          (!emailVerificationData.IsBlocked ? (
            <p className={styles.text}>Didn&#8217;t receive it? {getResendText()}</p>
          ) : (
            <p className={styles.text}>
              Didn&#8217;t receive it? Please contact{' '}
              <a href="emailto:support@mosaik.io" className={styles.link}>
                support@mosaik.io
              </a>{' '}
              for further assistance.
            </p>
          ))}
      </AnswersContainer>

      <ButtonsContainer>
        <OnboardingContinueButton className={styles.continueButton} disabled />
        <OnboardingSkipButton
          onClick={() => {
            window.localStorage.removeItem('afterVerificationState');
            props.onNext();
          }}
          className={styles.skipButton}
        />
      </ButtonsContainer>
    </div>
  );
};

export interface EmailVerificationData {
  AllowLinkResendTimestamp: Date;
  IsBlocked: boolean;
  Email: string;
}
