import { cloneDeep } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { ViewModes } from 'app-constants';
import { appLogoutAction } from 'store/actions/app';
import { updateAggregatePageTypeAction, updateViewModeAction } from 'store/actions/workshop';
import { AggregatedPageTypes } from 'types/aggregatedPageTypes';

const initialData = {
  viewMode: ViewModes.List,
  aggregatePageType: AggregatedPageTypes.All,
};

export default handleActions(
  {
    [updateViewModeAction as any]: (state, { payload }) => ({
      ...state,
      viewMode: payload as any,
    }),
    [updateAggregatePageTypeAction as any]: (state, { payload }) => ({
      ...state,
      aggregatePageType: payload as any,
    }),
    [appLogoutAction as any]: () => cloneDeep(initialData),
  },
  initialData,
);
