import classNames from 'classnames';
import React from 'react';
import styles from '../DowngradeModal/styles.module.scss';
import { reasonsList } from '.';

const CheckmarkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_53754_385170)">
        <path
          d="M4.80029 12.0012L9.60029 16.8012L19.2003 7.20117"
          stroke="#04A451"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_53754_385170">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

type Props = {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedReason: (index: number) => void;
  selectedReason: number;
};

export const StepThree: React.FC<Props> = ({
  setCurrentStep,
  setSelectedReason,
  selectedReason,
}) => {
  return (
    <div className={classNames(styles.content, styles.list)}>
      <div className={styles.header}>Select a Reason</div>
      {reasonsList.map((reason, index) => (
        <div
          className={styles.reason}
          key={`reason-${index}`}
          onClick={() => {
            setSelectedReason(index);
            setCurrentStep(2);
          }}
        >
          <div>{reason.title}</div>
          {selectedReason === index ? (
            <div>
              <CheckmarkIcon />
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};
