import moment from 'moment';
import { useState, useEffect, Fragment } from 'react';

import { formatPhoneNumber } from 'helpers';
import { InfoTooltip } from '../InfoTooltip';

import styles from './styles.module.scss';
import NumberFormat from 'react-number-format';

export const Header = ({
  sellerAgentName,
  sellerAgentPhone,
  sellerAgentEmail,
  buyingAgent,
  address,
  transactionAddress,
  price,
  deadline,
}) => {
  const [formattedDeadline, setFormattedDeadline] = useState('');
  useEffect(() => {
    deadline && setFormattedDeadline(moment(deadline).format('L'));
  }, [deadline]);

  return (
    <div className={styles.headerArea}>
      <div className={styles.headWrapper}>
        <div className={styles.heading}>Hi, {buyingAgent}! Here are the details on your offer:</div>

        {address ? (
          <div className={styles.addressTitle}>
            {address}
            <InfoTooltip
              text={
                <Fragment>
                  <ul className={styles.content}>
                    <li>{transactionAddress}</li>
                    <li className={styles.listingPrice}>
                      <span>Listing Price:&nbsp;</span>
                      <NumberFormat prefix="$" displayType="text" thousandSeparator value={price} />
                    </li>
                    {formattedDeadline ? (
                      <li>
                        <span>Offer Deadline:</span> {formattedDeadline}
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </Fragment>
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </div>
        ) : null}

        {sellerAgentName ? (
          <div className={styles.listedByDescripion}>
            Listed by {sellerAgentName}
            <InfoTooltip
              text={
                <Fragment>
                  <div className={styles.agentInfo}>
                    <h6>Listing Agent:</h6>
                    <ul className={styles.content}>
                      <li>{sellerAgentName}</li>
                      <li>{sellerAgentEmail}</li>
                      <li>{formatPhoneNumber(sellerAgentPhone)}</li>
                    </ul>
                  </div>
                </Fragment>
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
