import moment from 'moment';
import { useState } from 'react';
import Spin from 'antd/lib/spin';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { link } from 'settings/navigation/link';
import { Icons, ICON_VARIANT_TYPE } from 'pages/Workshop/Forms/Icons';
import { getDraftFormsSelector } from 'store/selectors/requestFormProcess';
import { Modal, ModalProps, Divider, ConfirmationModal, Col, Row } from 'components-antd';
import { FormProcessTypes } from 'types';

import styles from './styles.module.scss';

export type FormProcessDataType = {
  formName?: string;
  formProcessId?: number;
};

interface DraftFormModalProps extends ModalProps {
  deleteDraftForm: (
    formProcessId: number,
    setShowConfirmationModal: (open: boolean) => void,
    setFormProcess: ({ formName, formProcessId }: FormProcessDataType) => void,
  ) => void;
  onCloseModal?(): void;
}

export const DraftFormModal = ({ deleteDraftForm, onCloseModal }: DraftFormModalProps) => {
  const history = useHistory();

  const { drafts: draftForms, isPending } = useSelector(getDraftFormsSelector);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formProcess, setFormProcess] = useState<FormProcessDataType | undefined>();

  const handleFormClick = (type: FormProcessTypes, url: string) => {
    const Ids = url.split('/');

    if (type === FormProcessTypes.FormBuilder) {
      return history.push(
        link.toWorkshopFormProcessDocument({
          formProcessPublicId: Ids[0],
          formDocumentPublicId: Ids[1],
        }),
      );
    } else if (type === FormProcessTypes.DynamicForm) {
      return history.push(
        link.toWorkshopDynamicFormDocument({
          formProcessPublicId: Ids[0],
          formDocumentPublicId: Ids[1],
        }),
      );
    }
  };

  const handleDeleteDraftForm = (formName: string, formProcessId: number) => {
    setShowConfirmationModal(true);
    setFormProcess({ formName, formProcessId });
  };

  return (
    <>
      <Modal
        open={true}
        width={675}
        footer={null}
        title="Drafts"
        onCancel={() => (onCloseModal ? onCloseModal() : '')}
        className={styles.draftFormModal}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Divider />
        {!isPending ? (
          <div
            className={classnames(
              { [styles.emptyDraft]: !draftForms?.length },
              styles.draftFormBody,
            )}
          >
            {draftForms?.length ? (
              draftForms.map((val, index) => (
                <span key={`${val.id}`}>
                  <Row className={styles.formsRow}>
                    <Col xs={4} sm={4} md={2} lg={2} xl={2}>
                      <Icons variant={ICON_VARIANT_TYPE.DOCUMENT} className={styles.IconStyles} />
                    </Col>
                    <Col
                      xs={17}
                      sm={17}
                      md={20}
                      lg={20}
                      xl={20}
                      className={styles.draftFormInfo}
                      onClick={() => handleFormClick(val.type, val.url)}
                    >
                      <p>{val.name}</p>
                      {val.client ? <p>Client:&nbsp;{val.client}</p> : ''}
                      {val.address ? <p>Transaction:&nbsp;{val.address}</p> : ''}
                      {val.lastEdited ? (
                        <p>Last Edited:&nbsp;{moment(val.lastEdited).fromNow()}</p>
                      ) : (
                        ''
                      )}
                    </Col>
                    <Col xs={3} sm={3} md={2} lg={2} xl={2}>
                      <Icons
                        variant={ICON_VARIANT_TYPE.TRASH}
                        className={styles.IconStyles}
                        onClick={() => handleDeleteDraftForm(val.name, val.id)}
                      />
                    </Col>
                  </Row>
                  {index < draftForms.length - 1 ? <Divider className={styles.NoSpacing} /> : ''}
                </span>
              ))
            ) : (
              <h4 className={styles.noForms}>No forms found</h4>
            )}
          </div>
        ) : (
          <Spin className={styles.spinner} />
        )}
      </Modal>
      <ConfirmationModal
        open={showConfirmationModal}
        confirmText={`Are you sure you want to delete ${formProcess?.formName}?`}
        variant="Warning"
        onCancel={() => setShowConfirmationModal(false)}
        onOk={() =>
          formProcess?.formProcessId &&
          deleteDraftForm(formProcess.formProcessId, setShowConfirmationModal, setFormProcess)
        }
        confirmLoading={isPending}
      />
    </>
  );
};
