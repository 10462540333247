import { routes } from 'settings/navigation/routes';
import { Workshop } from 'pages';
import { AGENT, CLIENT } from 'settings/constants/roles';
import { CompareOffer } from 'pages/Workshop/Offers/components/CompareOffers';

export default [
  {
    path: routes.offers,
    component: Workshop,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.compareOffers,
    component: CompareOffer,
    exact: true,
    roles: [AGENT, CLIENT],
  },
];
