import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Modal, MultiSelect, Option, Button } from 'components-antd';
import { Avatar, ConfirmationDialog } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { getTeamListDataArray } from 'store/selectors/teamList';

import styles from './styles.module.scss';

export const ReassignShareModal = ({
  isOpen,
  modalType,
  onReassignShare,
  onClose,
  clients,
  loading,
}) => {
  const [assignedTo, setAssignedTo]: any = useState([]);
  const [reassignShareTo, setReassignShareTo]: any = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const teamList = useSelector(getTeamListDataArray);

  const getAssignedToAgents = () => {
    const assigned = {};
    clients.map((c) => c?.AssignedAgentIds.forEach((agentId) => (assigned[agentId] = agentId)));

    setAssignedTo(
      Object.values(assigned).map((Id) => {
        const agent = teamList.find((t) => t.Id === Id);

        return {
          Id,
          FirstName: agent?.FirstName,
          LastName: agent?.LastName,
          AvatarUrl: agent?.AvatarUrl,
        };
      }),
    );
  };

  useEffect(() => {
    getAssignedToAgents();
  }, [clients]);

  const renderUser = (user) => {
    const name = `${user?.FirstName} ${user?.LastName}`;

    return (
      <div className={styles.user} key={user.Id}>
        <Avatar
          src={user?.AvatarUrl}
          placeholder={
            <div className={styles.avatarPlaceholder}>{convertNameToAvatarPlaceholder(name)}</div>
          }
          avatarClassName={styles.avatar}
        />
        <div className={styles.label}>{name}</div>
      </div>
    );
  };

  const sortByName = (a, b) => {
    const nameA = a.FirstName.toUpperCase();
    const nameB = b.FirstName.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const renderTeamsList = () => {
    const list: any = [];
    // filter out the agents that are already assigned to the client and sort them by name and add to list the remaining agents
    teamList?.sort(sortByName)?.forEach((member) => {
      if (!assignedTo.find((assignee) => assignee?.Id === member?.Id)) {
        list.push(
          <Option key={member.Id} value={member.Id}>
            {member.FirstName} {member.LastName}
          </Option>,
        );
      }
    });

    return list;
  };

  const onSubmitConfirmModal = () => {
    onReassignShare(reassignShareTo);
    setOpenConfirmModal(false);
  };

  const joinAgentNames = (agents) => agents.map((a) => `${a.FirstName} ${a.LastName}`).join(', ');

  const getSelectedNames = (reassignShareTo) => {
    const agents = teamList.filter((a) => reassignShareTo.includes(a.Id));
    return joinAgentNames(agents);
  };

  const reassignShareClientsContent = useMemo(
    () => (
      <div className={styles.reassignShareConfirmText}>
        <h4>Are you sure that you want to {modalType.toLowerCase()} the client(s)?</h4>
        <p>
          The client(s) will be {modalType === 'Reassign' ? 'reassigned to' : 'shared with'}{' '}
          {getSelectedNames(reassignShareTo)}{' '}
          {modalType === 'Reassign'
            ? `and ${joinAgentNames(assignedTo)} will no longer have access`
            : ''}
          .
        </p>
      </div>
    ),
    [modalType, assignedTo, reassignShareTo],
  );

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={() => {
        setReassignShareTo([]);
        onClose();
      }}
      maskClosable={false}
      destroyOnClose
      className={styles.reassignShareModal}
    >
      <h1 className={styles.heading}>{modalType}</h1>
      <div className={styles.content}>
        <h5>Client</h5>
        {clients.map((c) => renderUser(c))}
        <h5 className={styles.subHeading}>Currently Assigned To:</h5>
        {assignedTo.map((c) => renderUser(c))}
        <h5 className={styles.subHeading}>
          {modalType} {modalType === 'Reassign' ? 'To' : 'With'}:
        </h5>
        <MultiSelect
          size="large"
          placeholderText="Select agent(s)"
          variant="none"
          onHandleChange={(values) => setReassignShareTo(values)}
          propClass={styles.multiSelect}
          optionFilterProp="children"
          wrapperClass={styles.multiSelectWrapper}
          values={reassignShareTo}
        >
          {renderTeamsList()}
        </MultiSelect>
        <Button
          variant="secondary"
          className={styles.submitBtn}
          disabled={loading || !reassignShareTo.length}
          onClick={() => setOpenConfirmModal(true)}
        >
          {modalType}
        </Button>
        <ConfirmationDialog
          onReject={() => setOpenConfirmModal(false)}
          onConfirm={onSubmitConfirmModal}
          isOpen={openConfirmModal}
          confirmText={modalType}
        >
          {reassignShareClientsContent}
        </ConfirmationDialog>
      </div>
    </Modal>
  );
};

ReassignShareModal.propTypes = {
  isOpen: PropTypes.bool,
  modalType: PropTypes.string,
  onReassignShare: PropTypes.func,
  onClose: PropTypes.func,
  clients: PropTypes.array,
  loading: PropTypes.bool,
};

ReassignShareModal.defaultProps = {
  isOpen: false,
  modalType: 'Reassign',
  onReassignShare: () => {},
  onClose: () => {},
  clients: [],
  loading: false,
};
