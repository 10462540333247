import { Popover } from 'components-antd';
import styles from './style.module.scss';
import { useState } from 'react';

import { Icons } from '../../../components/Icons';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { useSelector } from 'react-redux';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { useHistory } from 'react-router-dom';
import { link } from 'settings/navigation/link';

export const EditMenu = ({ onDelete, record }) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const agentRole = useSelector(getAgentTeamRoleSelector);
  const history = useHistory();

  const isAdminOrOwner = [TEAM_ADMIN, TEAM_OWNER].includes(agentRole);

  const onEditTemplate = () => {
    const data = record;

    history.push({
      pathname: data?.templateId
        ? link.toWorkshopFormTemplateRequest({
            templateId: data?.templateId,
          })
        : link.toWorkshopFormBundleTemplateRequest,
      state: {
        editMode: true,
        templateBundle: !!data?.bundleId,
        bundleId: data?.bundleId,
        isLibraryTemplate: true,
      },
    });
  };

  const getEditOptions = () => {
    return (
      <div className={styles.editMenu}>
        {[
          {
            label: 'Edit',
            onClick: onEditTemplate,
            params: [],
            icon: 'redPen',
          },
          ...(isAdminOrOwner
            ? [
                {
                  label: 'Delete',
                  onClick: onDelete,
                  icon: 'redTrash',
                },
              ]
            : []),
        ].map((item, index) => (
          <div
            key={index}
            className={styles.menuLink}
            onClick={(event) => {
              event.stopPropagation();

              (item?.onClick as any)();
              setPopupOpen(false);
            }}
          >
            <Icons variant={item.icon} className={styles.menuIcon} />
            <span className={styles.text}>{item.label}</span>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className={styles.editMenuWrapper}>
      <Popover
        content={getEditOptions()}
        getPopupContainer={(triggerNode) => triggerNode}
        trigger="click"
        placement="bottomRight"
        className={styles.popover}
        onOpenChange={(v) => setPopupOpen(v)}
        open={popupOpen}
      >
        <Icons
          onClick={(e) => {
            e.stopPropagation();
            setPopupOpen((prev) => !prev);
          }}
          variant={popupOpen ? 'menuDotsBg' : 'menuDots'}
          className={styles.editIcon}
        />
      </Popover>
    </div>
  );
};
