import { CSSProperties } from 'react';
import classNames from 'classnames';

import { Avatar, UserOutlinedIcon, Tooltip, TooltipProps } from 'components-antd';
import { getInitials } from 'utils';

import styles from './styles.module.scss';

interface InitialsWithIconAvatarProps {
  userName?: string;
  containerStyles?: CSSProperties;
  avatarTooltipClassName?: string | string[];
  containerClassName?: string | string[];
  tooltipProps?: TooltipProps;
  avatarSize?: number;
  subIcon?: AvatarIcons;
  absoluteStyles?: boolean;
}

export enum AvatarIcons {
  Defer = 'defer',
}

export const InitialsWithIconAvatar = ({
  userName,
  containerStyles,
  tooltipProps,
  avatarSize = 28,
  avatarTooltipClassName,
  containerClassName,
  subIcon,
  absoluteStyles = true,
}: InitialsWithIconAvatarProps) => (
  <div className={styles.InitialsWithIconAvatar}>
    <div
      style={containerStyles}
      className={classNames({ [styles.commentIndicatorDefer]: absoluteStyles }, containerClassName)}
    >
      <Tooltip
        overlayClassName={classNames(styles.avatarNameTooltip, avatarTooltipClassName)}
        {...tooltipProps}
      >
        <Avatar
          size={avatarSize}
          className={styles.avatar}
          icon={userName?.includes('@') ? <UserOutlinedIcon /> : undefined}
        >
          <p className={styles.initials}>{getInitials(userName)}</p>
        </Avatar>
      </Tooltip>
      {subIcon && getSVGIcons(subIcon)}
    </div>
  </div>
);

function getSVGIcons(type: AvatarIcons) {
  switch (type) {
    case AvatarIcons.Defer:
      return (
        <svg
          className={styles.commentIcon}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 15V12C4 10.3431 5.34315 9 7 9H16M16 9L11.5 4.5M16 9L11.5 13.5"
            stroke="#51BFE1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    default:
      return null;
  }
}
