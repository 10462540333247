import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, get } from 'lodash-es';
import classNames from 'classnames';

import { PENDING } from 'settings/constants/apiState';
import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';

import { useScreen } from 'hooks';
import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';

import { commuteTime } from 'settings/constants/commuteTime';
import { Add } from 'components/Icons';
import { Button, CommuteForm } from 'components';
import { appOpenModalEffect } from 'store/effects/app';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';
import Commutes from './Commutes';
import { Title, Back, NextButton, Wrapper } from '../..';
import { prefsIds, PREF_TYPE } from 'settings/constants/preferences';

import AddCommuteModal from './AddCommuteModal';

import styles from './styles.module.scss';

const StageCommute = ({ onNext, onPrev, stageIndex }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);
  const { screen } = useScreen();

  const propName = OnBoardingWizard.propNames.commutePrefs;
  const commutePrefs = getWizardStageValue({
    obj: onBoarding,
    stageIndex,
    propName,
    signUpRole,
  }) || [{ MaxCommuteTimeInMinutes: commuteTime.FIFTEEN_MINUTES }];
  const [stageValue, setStageValue] = useState(commutePrefs);

  const [currentCommuteIndex, setCurrentCommuteIndex] = useState(0);
  const [creatableCommuteIndex, setCreatableCommuteIndex] = useState(0);

  const getPref = useCallback(
    (stage, prpName) =>
      get(
        stage,
        `meta.Values.[${signUpRole}].SearchInstance.DefaultPropertySearchPreferences.[${prpName}]`,
      ),
    [signUpRole],
  );

  const getPrevPreferences = useCallback(() => {
    const prevWizardStage = cloneDeep(onBoarding.wizard[stageIndex - 1]);
    return cloneDeep(
      Object.values({ [stageIndex - 1]: prevWizardStage }).filter(
        (stage) =>
          !!getPref(stage, PREF_TYPE.neighborhoodPrefs) ||
          !!getPref(stage, PREF_TYPE.amenitiesPrefs) ||
          !!getPref(stage, prefsIds.parking) ||
          !!getPref(stage, PREF_TYPE.homePrefs),
      ),
    );
  }, [onBoarding?.wizard, getPref, stageIndex]);

  const getExtractedPrevPrefs = useCallback(
    () =>
      cloneDeep(
        getPrevPreferences().map(
          (preference) =>
            get(
              preference,
              `meta.Values.[${signUpRole}].SearchInstance.DefaultPropertySearchPreferences`,
            ),
          [signUpRole, getPrevPreferences],
        ),
      ),
    [getPrevPreferences, signUpRole],
  );

  const flatPrevPrefs = getExtractedPrevPrefs().reduce((acc, prefs) => ({ ...acc, ...prefs }), {});

  const onSave = () => {
    const cfg = {
      stageIndex,
      DefaultPropertySearchPreferences: {
        ...flatPrevPrefs,
        [propName]: (stageValue || []).map((val) => ({
          ...val,
          MaxCommuteTimeInMinutes: +val.MaxCommuteTimeInMinutes,
        })),
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, {}, (err) => {
        if (!err) {
          onNext();
        }
      }),
    );
  };

  const onChange = (value, index) => {
    stageValue[index ?? currentCommuteIndex] = {
      ...(stageValue[index ?? currentCommuteIndex] || {}),
      ...value,
    };

    setStageValue([...stageValue]);
  };

  const onAddAnotherCommute = () => {
    const clonedValue = cloneDeep(stageValue);
    clonedValue.push({ MaxCommuteTimeInMinutes: commuteTime.FIFTEEN_MINUTES });
    setStageValue(clonedValue);
    setCreatableCommuteIndex(clonedValue.length - 1);

    dispatch(appOpenModalEffect({ id: AddCommuteModal.id, open: true }));
  };

  const onDeleteCommute = (index) => {
    const clonedValue = cloneDeep(stageValue);
    clonedValue.splice(index, 1);
    setCurrentCommuteIndex(0);
    setStageValue(clonedValue);
  };

  const onSelectCommute = (index) => {
    setCurrentCommuteIndex(index);
  };

  const onAnotherSave = () => {
    const filteredValue = stageValue.filter(
      ({ DestinationAddress: address, Name: name, TransportationModePreference: transport }) =>
        address && name && Object.keys(transport || {}).length,
    );
    setStageValue(filteredValue);
  };

  const isPending = onBoarding.state === PENDING;
  const firstCommute = stageValue?.[0];
  const disabled =
    !firstCommute?.Name ||
    !firstCommute?.DestinationAddress ||
    !Object.keys(firstCommute?.TransportationModePreference || {}).length;

  return (
    <Wrapper testid="commute_stage" className={styles.wrapper}>
      <Back testid="commute_back" onBack={onPrev} />
      <div className={styles.innerHolder}>
        {screen.width > 1100 && (
          <Commutes
            className={styles.commutes}
            values={stageValue}
            onDelete={onDeleteCommute}
            onClick={onSelectCommute}
          />
        )}
        <div className={styles.holder}>
          <Title>Where is your commute to?</Title>
          {screen.width <= 1100 && (
            <Commutes
              className={styles.commutes}
              values={stageValue}
              onDelete={onDeleteCommute}
              onClick={onSelectCommute}
            />
          )}
          <CommuteForm
            onChange={onChange}
            value={stageValue[currentCommuteIndex]}
            isPending={isPending}
          />
          <Button
            icon={<Add className={styles.addIcon} />}
            className={classNames(styles.addAnotherBtn, styles.btn)}
            onClick={onAddAnotherCommute}
            title="Add Another"
            titleClassName={styles.addAnotherTitle}
            testid="add_another"
          />
          <NextButton
            className={styles.btn}
            onClick={onSave}
            isPending={isPending}
            disabled={disabled}
            testid="commute_continue"
            iconArrow
          />
        </div>
        <AddCommuteModal
          onChange={onChange}
          onAnotherSave={onAnotherSave}
          value={cloneDeep(stageValue[creatableCommuteIndex])}
          creatableCommuteIndex={creatableCommuteIndex}
          isPending={isPending}
          onDelete={onDeleteCommute}
        />
      </div>
    </Wrapper>
  );
};

StageCommute.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  stageIndex: PropTypes.number,
};

StageCommute.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  stageIndex: undefined,
};

export default StageCommute;
