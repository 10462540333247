import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Checkbox, RadioOptions } from 'components';

import { updateNotificationsSettingsEffect } from 'store/effects';
import styles from './styles.module.scss';

const optionsIds = {
  all: false,
  mine: true,
};

const OnlyMyTasks = (props) => {
  const { className, labelClassName, groupType, type, variant, value } = props;

  const dispatch = useDispatch();

  const [tasksOptionsValue, setTasksOptionsValue] = useState(value);
  const [isPending, setIsPending] = useState(false);

  const getOptions = () => [
    { id: optionsIds.all, value: optionsIds.all, label: 'All' },
    { id: optionsIds.mine, value: optionsIds.mine, label: 'Just Mine' },
  ];

  const getValue = (val) => val !== optionsIds.all;

  const onChangeHandler = (val) => {
    setIsPending(true);
    const config = { groupType, type, variant, value: getValue(val) };
    dispatch(
      updateNotificationsSettingsEffect(config, {}, (err) => {
        if (!err) {
          setTasksOptionsValue(val);
        }
        setIsPending(false);
      }),
    );
  };

  return (
    <div className={classNames(styles.tasks, className)}>
      <div className={labelClassName}>Which tasks should we notify you about?</div>
      <div className={styles.values}>
        <RadioOptions
          labelTextClassName={styles.labelText}
          direction={Checkbox.DIRECTION_LEFT}
          className={styles.radioOptions}
          optionClassName={styles.radioOption}
          options={getOptions()}
          name={type}
          onChange={onChangeHandler}
          value={tasksOptionsValue}
          isPending={isPending}
          testid="task_option"
        />
      </div>
    </div>
  );
};

OnlyMyTasks.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  groupType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

OnlyMyTasks.defaultProps = {
  className: '',
  labelClassName: '',
};

export default OnlyMyTasks;
