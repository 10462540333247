import styles from './styles.module.scss';
import classNames from 'classnames';

interface ClientNamesProps {
  clients?: string[];
  className?: string;
}

export const ClientNames = ({ clients, className }: ClientNamesProps) => {
  const combinedString = clients?.join(', ');
  return clients?.length ? (
    <span className={classNames(styles.clientNames, className)}>
      <span className={styles.label}>Client{clients?.length > 1 ? 's' : ''}:</span>
      <span className={styles.names}>{combinedString}</span>
    </span>
  ) : (
    <></>
  );
};
