import styles from './styles.module.scss';

export const AggregateResultHeader = ({ totalAmount, totalRequests, textColor }) => (
  <div className={styles.countContainer}>
    <span className={styles.textLarge} style={textColor}>
      {totalAmount}
    </span>
    {+totalRequests !== 0 && (
      <span className={styles.textSmall} style={textColor}>{`(${totalRequests})`}</span>
    )}
  </div>
);
