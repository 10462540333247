import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DrawerHeaderTitle, DrawerCancel } from 'components';
import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const Header = ({ className, onClose, onNewComment, title }) => (
  <div className={classNames(styles.header, className)}>
    <div className={styles.titleWrapper}>
      <DrawerHeaderTitle>{title}</DrawerHeaderTitle>
    </div>
    <div className={styles.actions}>
      {onNewComment && (
        <Icon
          testid="edit_icon"
          onClick={onNewComment}
          className={styles.editIcon}
          variant={Icon.EDIT_COLOR}
        />
      )}
      <DrawerCancel onClick={onClose} />
    </div>
  </div>
);

Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onNewComment: PropTypes.func,
};

Header.defaultProps = {
  className: '',
  title: 'Property Comments',
  onClose: () => {},
  onNewComment: undefined,
};

export default Header;
