import { TitleValueTable } from 'components';
import moment from 'moment';
import { formatPrice, getLocaleDate, limitTextLength, seperateWords } from 'helpers';

import styles from './styles.module.scss';
import NumberFormat from 'react-number-format';

export const Highlights = ({ data }) => {
  const {
    PurchasePrice,
    DownPayment,
    EarnestMoneyDeposit,
    ClosingDate,
    FinancingType,
    Contingencies,
    Notes,
    OfferExpiryEndDate,
    OfferExpiryIndefinitely,
    TermiteInspection,
    TermiteInspectionResponsibility,
    HomeWarranty,
    HomeWarrantyCost,
    HomeWarrantyResponsibility,
    Occupancy,
    OccupancyDays,
    Appliances,
  } = data;

  const highlightValues: any = [
    {
      title: 'Offer Price',
      value: PurchasePrice ? `$${formatPrice(PurchasePrice)}` : '-',
    },
    {
      title: 'Down Payment',
      value: DownPayment ? `$${formatPrice(DownPayment)}` : '-',
    },
    {
      title: 'Earnest Money Deposit',
      value: EarnestMoneyDeposit ? `$${formatPrice(EarnestMoneyDeposit)}` : '-',
    },
    {
      title: 'Closing Date',
      value: ClosingDate ? moment(getLocaleDate(ClosingDate)).format('MM/DD/YYYY') : '-',
    },
    {
      title: 'Financing Type',
      value: FinancingType ? seperateWords(FinancingType) : '-',
    },
    {
      title: 'Contingencies',
      value: Contingencies ? (
        <>
          {Contingencies?.Appraisal?.days ? (
            <p>Appraisal in {Contingencies?.Appraisal?.days} days</p>
          ) : (
            ''
          )}
          {Contingencies?.Financing?.days ? (
            <p>Financing in {Contingencies?.Financing?.days} days </p>
          ) : (
            ''
          )}
          {Contingencies?.Inspection?.days ? (
            <p>Inspection in {Contingencies?.Inspection?.days} days </p>
          ) : (
            ''
          )}
        </>
      ) : (
        '-'
      ),
    },
    {
      title: 'Termite Inspection',
      value:
        TermiteInspection && TermiteInspectionResponsibility
          ? 'Yes (Responsible Party: ' + TermiteInspectionResponsibility + ')'
          : 'No',
    },
    {
      title: 'Home Warranty',
      value:
        HomeWarranty && HomeWarrantyCost !== null
          ? 'Yes ($' +
            HomeWarrantyCost +
            ' / Responsible Party: ' +
            HomeWarrantyResponsibility +
            ')'
          : 'No',
    },
    {
      title: 'Appliances',
      value: Appliances ? Appliances : '-',
    },
    {
      title: 'Post-Closing Occupancy/SIP',
      value: Occupancy && OccupancyDays !== null ? 'Yes (' + OccupancyDays + ' Days)' : 'No',
    },
    {
      title: 'Offer Expiration',
      value: OfferExpiryIndefinitely
        ? 'None'
        : OfferExpiryEndDate
        ? moment(getLocaleDate(OfferExpiryEndDate)).format('MM/DD/YYYY')
        : '-',
    },
    {
      title: 'Notes',
      value: Notes ?? '-',
    },
  ];

  return (
    <div className={styles.highlights}>
      <p className={styles.title}>Highlights</p>
      <div className={styles.content}>
        <TitleValueTable items={highlightValues} itemsClassName={styles.valueItemWidth} />
      </div>
    </div>
  );
};
