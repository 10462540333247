import { SVGProps } from 'react';

export const StreetViewImage = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M8.1731 16.1034C8.22523 16.5204 8.57973 16.8333 9 16.8333H11C11.4203 16.8333 11.7748 16.5204 11.8269 16.1034L12.2358 12.8319C13.1242 12.7957 13.8333 12.064 13.8333 11.1667V7.83333C13.8333 6.91286 13.0871 6.16667 12.1667 6.16667H7.83333C6.91286 6.16667 6.16667 6.91286 6.16667 7.83333V11.1667C6.16667 12.064 6.87577 12.7957 7.76417 12.8319L8.1731 16.1034Z"
      stroke="#262626"
      strokeWidth="1.66667"
      strokeLinejoin="round"
    />
    <circle cx="10" cy="4" r="2.16667" stroke="#262626" strokeWidth="1.66667" />
    <path
      d="M7 15C4.63505 15.3372 3 16.0607 3 16.8989C3 18.0593 6.13401 19 10 19C13.866 19 17 18.0593 17 16.8989C17 16.0607 15.3649 15.3372 13 15"
      stroke="#262626"
      strokeWidth="1.66667"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
