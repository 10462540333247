import moment from 'moment';
import { getFormattedTime, getLocaleDate } from 'helpers';

import styles from './styles.module.scss';

export const DateAndTime = (props) => {
  const { startTime, endTime } = props;

  return (
    <div>
      <div className={styles.detailsArea}>
        <p className={styles.title}>Date &#38; Time:</p>
        {startTime && endTime ? (
          <>
            <p className={styles.subTitle}>
              {moment(getLocaleDate(startTime)).format('dddd, MMMM Do, YYYY')}
            </p>
            <p className={styles.subTitle}>
              {getFormattedTime(getLocaleDate(startTime))}
              {' - '}
              {getFormattedTime(getLocaleDate(endTime), true)}
            </p>
          </>
        ) : (
          <p>-</p>
        )}
      </div>
    </div>
  );
};
