import React, { memo, useMemo } from 'react';

const CalendarRounded = ({ color = '#51BFE1' }: { color?: string }) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.08" cx="18" cy="18" r="18" fill={color} />
      <path
        d="M22.8605 11.5215H13.1405C12.2458 11.5215 11.5205 12.2468 11.5205 13.1415V22.8615C11.5205 23.7562 12.2458 24.4815 13.1405 24.4815H22.8605C23.7552 24.4815 24.4805 23.7562 24.4805 22.8615V13.1415C24.4805 12.2468 23.7552 11.5215 22.8605 11.5215Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2402 10.4375V12.5975"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7598 10.4375V12.5975"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5205 15.8379H24.4805"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarRounded;
