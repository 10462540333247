import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { map } from 'lodash-es';
import classNames from 'classnames';

import { routes } from 'settings/navigation/routes';
import { getUserRolesSelector, getUserDataSelector } from 'store/selectors/user';
import { getPropertiesLinksByRoles } from 'settings/navigation/linksByRoles';

import { Select } from 'antd';
import { ArrowDown } from 'components/Icons';

import styles from './styles.module.scss';

const { Option } = Select;

export const Switcher = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const roles = useSelector(getUserRolesSelector);
  const user = useSelector(getUserDataSelector);
  const links = useMemo(() => getPropertiesLinksByRoles(roles, { user }), [roles, user]);

  const handleChange = (route) => {
    history.push(route);
  };

  const getDefaultValue = () => {
    if (pathname.includes('/search')) return routes.search;
    else if (pathname.includes('/feed')) return routes.feed;
    else if (pathname.includes('/my-searches')) return routes.mySearches;
    else if (pathname.includes('/client-searches')) return routes.clientSearches;
    else if (pathname.includes('/tours')) return routes.clientTours;
  };

  return (
    <Select
      defaultValue={getDefaultValue()}
      onChange={handleChange}
      bordered={false}
      className={classNames('mosaikPageSwitcher')}
      popupClassName={classNames('mosaikPageSwitcherDropdown')}
      getPopupContainer={(triggerNode) => triggerNode}
      suffixIcon={<ArrowDown />}
    >
      {map(links, ({ link, title }) => (
        <Option key={link} value={link}>
          {title}
        </Option>
      ))}
    </Select>
  );
};
