import { createSelector } from 'reselect';

import { PENDING, IDLE } from 'settings/constants/apiState';

const localState = ({ clarity }) => clarity;

export const getClarityLoaderSelector = createSelector(localState, ({ state }) => ({
  state,
  isIdle: state === IDLE,
  isPending: state === PENDING,
}));

export const getAgentTeamStatsSelector = createSelector(localState, ({ teamStats }) => teamStats);

export const getIsGraphSelected = createSelector(
  localState,
  ({ isGraphSelected }) => isGraphSelected,
);

export const getGraphNameSelector = createSelector(localState, ({ graphName }) => graphName);
