import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';
import { taskFilters } from 'settings/constants/transactionTasks';
import { setTransactionsTasksFilterAction } from 'store/actions/transactions';
import { getFilteredTaskAggregateSelector } from 'store/selectors/taskAggregate';
import { TEAM_OWNER, TEAM_ADMIN } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { LocationService } from 'services';
import { setTransactionsTasksFilterEffect } from 'store/effects';

import styles from './styles.module.scss';

export const TasksFilter = (props) => {
  const [value, setValue] = useState(taskFilters.allTasks);
  const dispatch = useDispatch();
  const [isFiltersApplied, setIsFilterApplied] = useState(false);
  const { isArchive } = useSelector(getFilteredTaskAggregateSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  const locationSrv = new LocationService();
  const location = useLocation();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  useEffect(() => {
    setValue(taskFilters.allTasks);
    setIsFilterApplied(false);
    dispatch(setTransactionsTasksFilterAction(taskFilters.allTasks));
  }, [isArchive]);

  useEffect(() => {
    // Handle dashboard redirect
    if ('teamStats' in query) {
      const filterStatus = query.teamStats ? taskFilters.allTasks : taskFilters.myTasks;
      setIsFilterApplied(!query.teamStats);
      setValue(filterStatus);
    }
  }, []);

  const onChange = (e) => {
    const val = e.target.value;
    const isFiltersApplied = val !== taskFilters.allTasks;

    setIsFilterApplied(isFiltersApplied);
    dispatch(setTransactionsTasksFilterAction(val));
    setValue(val);
  };

  // Hide Team Tasks option for Team members.
  const teamAdminAndOwnerOption = [TEAM_OWNER, TEAM_ADMIN].includes(agentRole)
    ? [taskFilters.teamTasks]
    : [];

  const dropdownFields = [
    taskFilters.myTasks,
    taskFilters.tasksIAssigned,
    ...teamAdminAndOwnerOption,
    taskFilters.clientTasks,
    taskFilters.collaboratorTasks,
    taskFilters.allTasks,
  ];

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => (
          <MenuItem key={idx}>
            <Radio value={item} key={idx}>
              <div className={styles.option}>
                <span className={styles.optionValue}>{item}</span>
              </div>
              {value === item && <Check className={styles.checkIcon} />}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div
      className={classNames(
        styles.filterWrap,
        {
          [styles.filtersAppliedWrapper]: isFiltersApplied,
        },
        props.className,
      )}
    >
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.tasksFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.selectedValue}>{value}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
        </a>
      </Dropdown>
    </div>
  );
};
