import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const Icons = (props) => {
  const { className, variant, onClick, testid } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.PROFILE: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3334 4.66665C11.3334 6.5076 9.84103 7.99998 8.00008 7.99998C6.15913 7.99998 4.66675 6.5076 4.66675 4.66665C4.66675 2.8257 6.15913 1.33331 8.00008 1.33331C9.84103 1.33331 11.3334 2.8257 11.3334 4.66665Z"
              stroke="#FF576D"
              strokeWidth="2"
            />
            <path
              d="M2.66675 14C2.99914 12.2194 4.52733 10.6667 6.2222 10.6667H9.77786C11.4727 10.6667 13.001 12.2194 13.3334 14"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.REASSIGN: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.45508 9.9688C2.95299 11.3319 3.9353 12.464 5.21461 13.149C6.49391 13.8341 7.98065 14.0242 9.39117 13.6831C10.8017 13.3419 12.0373 12.4935 12.8621 11.2995C13.687 10.1056 14.0434 8.64972 13.8634 7.20974C13.6834 5.76977 12.9796 4.44645 11.8862 3.49229C10.7928 2.53812 9.38643 2.01988 7.93534 2.03646C6.48425 2.05303 5.09005 2.60325 4.01875 3.58215C3.22427 4.30809 2.64861 5.23211 2.34418 6.25M2.12695 13.25V9.9688H5.4082"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.SHARE: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_17220_164323)">
              <path
                d="M2.66724 11.3334V12.6668C2.66724 13.0204 2.80771 13.3595 3.05776 13.6096C3.30781 13.8596 3.64695 14.0001 4.00057 14.0001H12.0006C12.3542 14.0001 12.6933 13.8596 12.9434 13.6096C13.1934 13.3595 13.3339 13.0204 13.3339 12.6668V11.3334"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.66724 5.99994L8.00057 2.66661L11.3339 5.99994"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 10.6671V2.66705"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_17220_164323">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.DOTS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
              fill="#303030"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
              fill="#303030"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
              fill="#303030"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icons, className)}>
      {getIcons()}
    </div>
  );
};

Icons.PROFILE = 'profile';
Icons.REASSIGN = 'reassign';
Icons.SHARE = 'share';
Icons.DOTS = 'dots';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  testid: PropTypes.string,
  onClick: PropTypes.func,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};
