import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ConfirmationWithReasonModal, Modal } from 'components-antd';
import styles from './styles.module.scss';
import { ModalTable } from 'pages/Main/ClientDashboard/components/ModalTable';
import { tableColumns } from './tableColumns';
import { getUserId } from 'store/selectors/user';
import { dispatch } from 'store';

import { ApprovalAction, ApprovalStatus } from 'types';
import { requestUpdateShowingAppointmentApprovalEffect } from 'store/effects/showingAppointment';
import { groupBy } from 'lodash-es';
import { showSuccessMessage } from 'helpers';
import { getClientDashboardNeedsAttionEffect } from 'store/effects/clientDashboard';

const ShowingsModal = (props) => {
  const { className, isOpen, onCloseModal, showingsDetails, title = 'Showing Requests' } = props;
  const userId = useSelector(getUserId);
  const [data, setData] = useState({ UUID: 0 });
  const [showModal, setShowModal] = useState(false);
  const [actionConfig, setActionConfig] = useState({
    action: '',
  });

  const onSuccess = (status) => {
    showSuccessMessage(`Showing ${status} Successfully`);
    dispatch(
      getClientDashboardNeedsAttionEffect(
        {},
        {
          silent: true,
        },
      ),
    );
  };

  const getPendingApproverNames = (data) => {
    let names = [];
    const approverGroupByStatus = groupBy(data.ShowingAppointmentApproval, 'ApprovalStatus');
    approverGroupByStatus?.Pending?.forEach((Approval) => {
      const { ApproverName, ApproverUserEmail, ApproverUserId } = Approval;
      const name =
        userId && userId === ApproverUserId
          ? 'You'
          : `${ApproverName?.FirstName} ${ApproverName?.LastName}`;
      names.push(ApproverName ? name : ApproverUserEmail);
    });

    return names;
  };

  const validateApprover = (type, data) => {
    const { ShowingAppointmentApproval } = data;
    const approverGroupByStatus = groupBy(ShowingAppointmentApproval, 'ApprovalStatus');
    const approvers = approverGroupByStatus[type] || [];
    return approvers.find((a) => a.ApproverUserId === userId);
  };

  const onSubmit = (approvalStatus, approverStatus) => {
    const approver = validateApprover(approverStatus, data);
    if (approver) {
      dispatch(
        requestUpdateShowingAppointmentApprovalEffect(
          { UUID: approver.UUID, ApprovalStatus: approvalStatus },
          {},
          (err) => {
            if (!err) {
              onSuccess(approvalStatus);
            }
          },
        ),
      );
    }
  };

  const processAction = (action) => {
    setShowModal(true);
    setActionConfig({ ...actionConfig, action });
  };

  const onConfirmationCancel = (e) => {
    e.stopPropagation();
    setShowModal(false);
  };

  const onConfirm = () => {
    switch (actionConfig?.action) {
      case ApprovalAction.Approve:
        onSubmit(ApprovalStatus.Approved, ApprovalStatus.Pending);
        break;
      case ApprovalAction.Decline:
        onSubmit(ApprovalStatus.Rejected, ApprovalStatus.Pending);
        break;
      default:
        break;
    }
    setShowModal(false);
  };

  const tableProps = {
    columns: tableColumns(processAction, setData, getPendingApproverNames),
    onRow: (record) => {
      return {
        onClick: () => {},
      };
    },
    tableClassName: styles.taskTable,
  };
  return (
    <>
      <Modal
        open={isOpen}
        width={1200}
        footer={null}
        onCancel={() => {
          onCloseModal();
        }}
        maskClosable={false}
        destroyOnClose
        className={styles.taskViewModal}
      >
        <div testid="showings_modal" className={styles.formModal}>
          <p className={styles.title}>{title}</p>
          <ModalTable dataSource={showingsDetails} {...tableProps} />
        </div>
      </Modal>
      <ConfirmationWithReasonModal
        open={showModal}
        confirmText={<span>Are you sure you want to {actionConfig?.action} this showing?</span>}
        placeholder={`Write a reason for ${
          actionConfig?.action === ApprovalAction.Decline ? 'declining' : 'cancelling'
        }...`}
        okText={actionConfig?.action === ApprovalAction.Cancel ? 'Confirm' : actionConfig?.action}
        showReason={!(actionConfig?.action === ApprovalAction.Approve)}
        onOk={onConfirm}
        onCancel={onConfirmationCancel}
      />
    </>
  );
};

ShowingsModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  showingsDetails: PropTypes.any,
};

ShowingsModal.defaultProps = {
  className: '',
  isOpen: false,
  onCloseModal: () => {},
};

export default ShowingsModal;
