import React, { useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ProfileIcons } from '../Icons';
import TransactionBlockActions from './TransactionBlockActions';
import { appManageClientDrawerAction } from 'store/actions/app';
import { getTimeFormatMonthName } from 'helpers/formatters';
import { setProfileExpandedSection } from 'store/effects/app';
import Status from '../Status';
import { Select, Option } from 'components-antd';
import styles from './styles.module.scss';
import { listedStatusesArray, transactionStatuses } from 'settings/constants/transaction';

interface ActiveTransactionsProps {
  transactions: any;
  view: string;
}

enum filterType {
  Active = 'Active',
  Inactive = 'Inactive',
  All = 'All',
}

const EXPANDED_VIEW = 'expanded';

export const ActiveTransactions = ({ transactions, view }: ActiveTransactionsProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(filterType.Active);

  const getFilteredTransactions = () => {
    if (filter === filterType.Active) {
      return (transactions || []).filter((item) =>
        [
          ...listedStatusesArray,
          transactionStatuses.UnderContract,
          transactionStatuses.ClearToClose,
        ].includes(item.Status),
      );
    } else if (filter === filterType.Inactive) {
      return (transactions || []).filter((item) =>
        [transactionStatuses.Canceled, transactionStatuses.Closed].includes(item.Status),
      );
    } else {
      return transactions;
    }
  };

  const onRun = (transaction) => {
    if (transaction?.Id) {
      if (view === EXPANDED_VIEW) dispatch(setProfileExpandedSection(false));
      dispatch(appManageClientDrawerAction(false));
      history.push(`/workshop/transactions/${transaction.Id}/overview`);
    }
  };

  const renderPropertyAddress = (instance) => {
    const address = instance?.Property?.Address;
    if (!address) return <h3>-</h3>;

    return (
      <>
        <h3>{address?.Line1}</h3>
        <h3>{address?.City + ', ' + address?.State + ' ' + address?.Zip}</h3>
      </>
    );
  };
  const handleStatusChange = (status: filterType) => {
    setFilter(status);
  };

  const renderFilter = () => {
    if (view === EXPANDED_VIEW) {
      return (
        <div className={styles.expandedTransaction}>
          <div className={styles.expandedTransactionHeading}>
            <ProfileIcons name={'Transactions'} />
            <h3>Transactions</h3>
          </div>
          <Status
            options={ActiveTransactions.options}
            handleStatusChange={handleStatusChange}
            filter={filter}
          />
        </div>
      );
    } else if (getFilteredTransactions()?.length) {
      return <h3>Active Transactions</h3>;
    }
  };

  const renderTransactions = () => {
    const count = getFilteredTransactions()?.length;

    if (count) {
      return getFilteredTransactions().map((item) => (
        <div className={styles.transaction} key={item.Id} onClick={() => onRun(item)}>
          <div className={styles.iconName}>
            <ProfileIcons name={ProfileIcons.ACTIVE_TRANSACTIONS} />
            <div>
              {renderPropertyAddress(item)}
              <div>
                <span className={styles.tasks}>{item.TasksCount || 0} Tasks</span>
                {item?.PropertyTransactionKeyDatesPrices?.UnderContractCloseDate && (
                  <span className={styles.tasks}>
                    Closing on{' '}
                    {getTimeFormatMonthName(
                      item?.PropertyTransactionKeyDatesPrices?.UnderContractCloseDate,
                    ) || ''}
                  </span>
                )}
              </div>
            </div>
          </div>

          <TransactionBlockActions onSearch={() => onRun(item)} />
        </div>
      ));
    } else if (view === EXPANDED_VIEW) {
      return <div className={styles.noResults}>No Results</div>;
    }
  };

  return (
    <div className={styles.activeTransactions}>
      {renderFilter()}
      {renderTransactions()}
    </div>
  );
};

ActiveTransactions.options = [
  { id: 1, label: filterType.Active, value: filterType.Active },
  { id: 2, label: filterType.Inactive, value: filterType.Inactive },
  { id: 3, label: filterType.All, value: filterType.All },
];
