import { find } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from 'settings/navigation/routes';
import { useHistory } from 'react-router-dom';

import { setMultipleRequestQuotesEffect } from 'store/effects/quotes';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';
import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import { getUserRolesSelector } from 'store/selectors/user';
import { showRequestQuoteBtnSelector } from 'store/selectors/partners';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import RequestCategoriesWrapper from '../Services/components/RequestCategoriesWrapper';
import { Button } from 'components';
import PartnersList from './components/PartnersList';
import RequestPartnersWrapper from './components/RequestPartnersWrapper';
import SelectedPartners from './components/SelectedPartners';
import { InviteVendorIcon } from './components/icons';
import { AddVendorModal } from './components/AddVendorModal';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { Role } from 'settings/constants/roles';

import styles from './styles.module.scss';

// TODO: add request partners list
const ServicesCategory = () => {
  const dispatch = useDispatch();
  const categories = useSelector(getAllServicesCategoriesList);
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);
  const userRoles = useSelector(getUserRolesSelector);
  const showRequestQuoteBtn = useSelector(showRequestQuoteBtnSelector);
  const { areasOfOperation, category: categoryId } = useSelector(getConciergeSearchSelector);
  const [isMobile, setIsMobile] = useState(false);
  const [isAddVendorModalOpen, setIsAddVendorModalOpen] = useState<boolean>(false);

  const isAgent = useMemo(() => userRoles.includes(Role.Agent), [userRoles]);
  const isClient = useMemo(() => userRoles.includes(Role.Client), [userRoles]);

  const history = useHistory();

  // Redirect to services page if user is client and has no areas of operation
  useEffect(() => {
    if (isClient && areasOfOperation?.length === 0) {
      history.push(routes.services);
    }
  }, [areasOfOperation, isClient]);

  const onRequestQuote = () => {
    dispatch(setMultipleRequestQuotesEffect({ modeEnable: !multiple?.modeEnable }));
  };
  const onRequestQuoteOrRequireEmailVerification = useEmailVerificationWall(
    ActionToInterrupt.REQUEST_QUOTE,
    onRequestQuote,
    {
      categoryId: categoryId,
    },
  );

  const categoryById = useMemo(() => {
    return find(categories, ({ Id }) => Id === Number(categoryId));
  }, [categories, categoryId]);

  useEffect(() => {
    function handleWindowSizeChange() {
      if (window.innerWidth <= 575) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    if (document.readyState === 'complete') {
      handleWindowSizeChange();
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.addEventListener('load', handleWindowSizeChange);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <div className={styles.contentWrapper}>
        <div className={styles.col1}></div>
        <div className={styles.col2}>
          <RequestCategoriesWrapper className={styles.wrapper}>
            <RequestPartnersWrapper>
              <PartnersList category={categoryById} />
            </RequestPartnersWrapper>
          </RequestCategoriesWrapper>
        </div>
        <div className={styles.col3}>
          {isAgent && !multiple?.modeEnable ? (
            <div className={styles.btnWrapper}>
              <Button
                onClick={() => setIsAddVendorModalOpen(true)}
                className={styles.addVendorBtn}
                titleClassName={styles.addVendorBtnTitle}
                title="Add Vendor"
                testid="add_vendor"
                iconLeft={<InviteVendorIcon className={styles.inviteVendorIcon} />}
              />
              {showRequestQuoteBtn && (
                <Button
                  onClick={onRequestQuoteOrRequireEmailVerification}
                  className={styles.requestQuoteBtn}
                  title="Request Quote"
                  testid="request_quote"
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
      {categoryById?.Id && (
        <SelectedPartners categoryId={categoryById?.Id} categoryName={categoryById?.Title} />
      )}
      {isAddVendorModalOpen && (
        <AddVendorModal
          open={isAddVendorModalOpen}
          handleClose={() => setIsAddVendorModalOpen(false)}
        />
      )}
    </>
  );
};

export default ServicesCategory;
