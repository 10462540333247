import { useSelector } from 'react-redux';

import { FilterPopover } from './FilterPopover';

import { getFormMetaSelector } from 'store/selectors/requestFormProcess';

import styles from './styles.module.scss';
import { Icons } from '../../Icons';
import { useState } from 'react';
import classNames from 'classnames';

interface FilterProps {
  applyFilter?: (values?: any) => void;
  isRecentUploads?: boolean;
  uploadedByOptions?: any[];
  publishedByOptions?: any[];
}

export const Filter = (props: FilterProps) => {
  const { applyFilter, isRecentUploads, uploadedByOptions, publishedByOptions } = props;

  const [filterApplied, setFilterApplied] = useState(false);

  const [open, setOpen] = useState(false);

  const { tags } = useSelector(getFormMetaSelector);

  const handleFilters = ({ filters }, shouldApplyFilter = false) => {
    const payload = {
      page: 1,
      pageSize: 5,
      ...filters,
    };

    setFilterApplied(shouldApplyFilter);
    applyFilter && applyFilter(payload);
  };

  const renderFilterIcon = (
    <div
      className={classNames(styles.filterWrapper, {
        [styles.openedFilterWrapper]: open && !filterApplied,
        [styles.appliedFilterWrapper]: filterApplied,
      })}
    >
      <Icons variant={'filter'} className={styles.icon} />
      <span>All Filters</span>
    </div>
  );

  return (
    <FilterPopover
      filterIcon={renderFilterIcon}
      onDone={(details) => handleFilters(details, true)}
      onReset={handleFilters}
      options={uploadedByOptions || tags}
      publishedByOptions={publishedByOptions}
      open={open}
      setOpen={setOpen}
      isRecentUploads={isRecentUploads}
    />
  );
};
