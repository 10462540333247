import moment from 'moment';
import { getFormattedTime } from 'helpers';
import { Icons, ICON_VARIANT_TYPE } from '../../Icons';

import styles from './styles.module.scss';
import { CalendarEvent } from '../CalendarEvent';

export const DateAndTime = (props) => {
  const { selectedDateTime, appointmentFormData, ShowingDuration } = props;
  return (
    <div>
      <div className={styles.detailsArea}>
        <p className={styles.title}>Date &#38; Time:</p>
        <p className={styles.subTitle}>
          {moment(selectedDateTime?.date).format('dddd, MMMM Do, YYYY')}
        </p>
        <p className={styles.subTitle}>
          {getFormattedTime(selectedDateTime?.startTime)}
          {' - '}
          {getFormattedTime(selectedDateTime?.endTime)} ({selectedDateTime?.timeZone})
        </p>
        <CalendarEvent appointmentFormData={appointmentFormData} />
      </div>
    </div>
  );
};
