import React from 'react';
import PropTypes from 'prop-types';

const Favorite = ({ className, active }) => (
  <div className={className}>
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0009 16.75L4.82889 19.995L6.00789 13.122L1.01489 8.255L7.91489 7.253L11.0009 1L14.0869 7.253L20.9869 8.255L15.9939 13.122L17.1729 19.995L11.0009 16.75Z"
        fill={active ? '#FF576D' : 'none'}
        stroke="#FF576D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

Favorite.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
};

Favorite.defaultProps = {
  className: '',
  active: true,
};

export default Favorite;
