import styles from '../styles.module.scss';
import { Input } from 'antd';
const { TextArea } = Input;

export const Breakpoint4 = (props) => {
  const { state, onChange } = props;

  return (
    <div className={styles.breakpoint3}>
      <div className={styles.inputBlock}>
        <div className={styles.label}>Do you want to add a note for the listing agent?</div>
        <TextArea
          name={'Notes'}
          rows={3}
          maxLength={250}
          className={styles.textArea}
          placeholder="Add note..."
          value={state?.OfferIteration?.Notes}
          onChange={(e) =>
            onChange({
              ...state,
              OfferIteration: {
                ...state.OfferIteration,
                Notes: e.target.value,
              },
            })
          }
          id="Notes"
          testid="submission_notes"
        />
      </div>
    </div>
  );
};
