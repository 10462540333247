import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const PartTitle = (props) => {
  const { className, children } = props;

  return <div className={classNames(styles.partTitle, className)}>{children}</div>;
};

PartTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PartTitle.defaultProps = {
  className: '',
};

export default PartTitle;
