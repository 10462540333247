import Spin from 'antd/lib/spin';
import { useSelector } from 'react-redux';
import { Document, Page } from 'react-pdf';
import { useEffect, useRef, useState, RefObject, Ref } from 'react';
import { useParams } from 'react-router-dom';

import {
  AddFormCommentType,
  CommentUsersType,
  EditFormCommentType,
  FormCommentType,
  FormDocumentAnswersType,
  FormDocumentQuestionsType,
  PDFFields,
  PDFViewerMode,
  FieldInfoType,
  StrikeThrough,
  LineColor,
  DeferredUsers,
  FormCommentThread,
} from 'types';
import { RenderComments } from './RenderComments';
import { RenderPageFields } from './RenderPageFields';
import { SignatureResult, SignModal } from '../PDFSign';
import { AGENT, CLIENT } from 'settings/constants/roles';
import { getUserRoleSelector } from 'store/selectors/user';
import { RenderDisabledPageFields } from './RenderDisabledFields';
import { AllowedAnonEnum, FORM_QUESTION_TYPE, FORM_TOKEN, SignFormFields } from 'app-constants';
import { RenderStrikeThrough } from './StrikeThroughLayer';
import { StrikeThroughIcon } from 'components/Icons';
import { SignWizard } from './SignWizard';
import { PdfCache } from 'components';

import styles from './styles.module.scss';
import Icons from 'pages/FormBuilder/Icons';
import classNames from 'classnames';
import { Badge } from 'components-antd';

export type PageSizeType = {
  width: number;
  height: number;
};

interface PDFViewProps {
  Url?: string | string[];
  highlightedFields?: string[];
  deferredUsers?: DeferredUsers[];
  isLoading?: boolean;
  questions?: FormDocumentQuestionsType;
  disabledQuestions?: FormDocumentQuestionsType;
  responses?: FormDocumentAnswersType;
  mode?: PDFViewerMode;
  onFieldClick?: (field) => void;
  onFieldUpdate?: (field) => void;
  onSignUpdate?: (UUUID, result) => void;
  activeIndex?: number;
  isMock?: boolean;
  handleSaveSignature?: (signature: SignatureResult) => void;
  setActiveIndex?: (index: number) => void;
  unansweredRequiredQs?: number[];
  showRequiredQs?: boolean;
  handleDeferModal?: (uuuid: string[]) => void;
  isCreator?: boolean;
  commentUsers?: CommentUsersType;
  formComment?: FormCommentType;
  setFieldInfo?: (fieldInfo: FieldInfoType) => void;
  handleGetFormComments?: () => void;
  handleAddFormComment?: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment?: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment?: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
  toggleStrikeThroughMode?: () => void;
  strikeThrough?: StrikeThrough;
  handlePointUpdate?: (page, points) => void;
  color?: LineColor;
  strokeWidth?: number;
  formCommentFieldId?: string;
  containerRef?: RefObject<HTMLDivElement>;
  setUndoDefer?: (questionUUID: string[]) => void;
  pdfInputRef?: Ref<HTMLDivElement>;
  setCommentPanelOpen?: (commentPanelOpen: boolean) => void;
  formCommentThreads?: FormCommentThread[];
  selectedFieldInfo?: FieldInfoType;
  pdfDocumentsData?: any[];
}

interface SignModalProps {
  fields: PDFFields[];
  index: number;
  response?: SignatureResult;
  fieldKey: SignFormFields;
}

const MAX_ZOOM_SCALE = 3;
const SCALE_STEP = 0.25;

export const PDFViewer = ({ Url, ...props }: PDFViewProps) => {
  const { token, type } = useParams<{ token: string; type: AllowedAnonEnum }>();
  const [loading, setDocumentLoading] = useState(false);
  const [documentsData, setDocumentsData] = useState();

  const fetchDocument = async () => {
    setDocumentLoading(true);

    const anonymousDetails =
      token && type
        ? {
            token,
            type: FORM_TOKEN[type],
          }
        : undefined;

    if (Array.isArray(Url)) {
      const documents: any = await Promise.all(
        Url.map(async (url: string) => {
          await PdfCache.loadDocuments(url, anonymousDetails);
          return PdfCache.getDocument(url);
        }),
      );

      setDocumentsData(documents);
      setDocumentLoading(false);
    } else {
      await PdfCache.loadDocuments(Url, anonymousDetails);
      setTimeout(() => {
        setDocumentLoading(false);
      }, 0);
    }
  };

  useEffect(() => {
    if (Url) {
      fetchDocument();
    }
  }, [Url]);

  if (loading) {
    return <Spin spinning={true} className={styles.loader} size={'large'} />;
  }

  return <PDFInstance Url={Url} pdfDocumentsData={documentsData} {...props} />;
};

export const PDFInstance = ({
  isLoading = true,
  questions = [],
  deferredUsers = [],
  mode = 'View',
  highlightedFields = [],
  activeIndex = 0,
  onFieldClick,
  onFieldUpdate,
  onSignUpdate,
  isMock,
  responses = {},
  Url = '',
  handleSaveSignature,
  setActiveIndex,
  unansweredRequiredQs,
  showRequiredQs,
  disabledQuestions,
  handleDeferModal,
  isCreator,
  commentUsers,
  formComment,
  setFieldInfo,
  handleGetFormComments,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
  toggleStrikeThroughMode,
  strikeThrough = {},
  handlePointUpdate,
  color,
  strokeWidth,
  formCommentFieldId,
  containerRef,
  setUndoDefer,
  pdfInputRef,
  setCommentPanelOpen,
  formCommentThreads,
  selectedFieldInfo,
  pdfDocumentsData,
}: PDFViewProps) => {
  const userRole = useSelector(getUserRoleSelector);

  const [loading, setLoading] = useState(isLoading);

  const [scale, setScale] = useState(1.5);

  const [signModalFieldsProps, setSignModalFieldsProps] = useState<undefined | SignModalProps>(
    undefined,
  );
  const [signatureFields, setSignatureFields] = useState<number[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const pdfDocuments = pdfDocumentsData || [PdfCache.getDocument(Url)] || [];
  // const { documentBuffer: documentVaultBuffer, pdfDoc: PDF } = PdfCache.getDocument(Url) || {};

  useEffect(() => {
    if (questions?.length) {
      const formRoles = questions
        .filter((el) => el.Type === FORM_QUESTION_TYPE.Signature)
        .map((el) => el?.FormRole?.[0])
        .filter((el) => el >= 0)
        .sort((a, b) => (a > b ? 1 : -1));

      const signatureFieldIndex = Array.from(new Set(formRoles));
      setSignatureFields(signatureFieldIndex);
    } else if (signatureFields.length) {
      setSignatureFields([]);
    }
  }, [questions]);

  useEffect(() => {
    if (activeIndex >= 0) scrollToActive();
  }, [activeIndex]);

  useEffect(() => {
    setTimeout(scrollToActive, 500);
  }, []);

  useEffect(() => {
    if (highlightedFields?.length && highlightedFields[0] && ref?.current) {
      const element = (ref?.current as HTMLDivElement).querySelector(
        `[itemid="${highlightedFields[0]}-pdfField"]`,
      );

      if (element && element.scrollIntoView) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [highlightedFields]);

  useEffect(() => {
    if (formCommentFieldId && ref?.current) {
      const element = (ref?.current as HTMLDivElement).querySelector(
        `[itemid="${formCommentFieldId}-pdfField"]`,
      );
      if (element && element.scrollIntoView) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [formCommentFieldId]);

  const handleSignatureResult = (result) => {
    if (signModalFieldsProps && onSignUpdate && questions?.[signModalFieldsProps.index]) {
      onSignUpdate(questions?.[signModalFieldsProps.index]?.UUID, result);
      setSignModalFieldsProps(undefined);
    }

    if (result && handleSaveSignature && (userRole === AGENT || userRole === CLIENT)) {
      const { UUID, Question, Answer, DefaultAnswer, ...signatureResult } = result;
      handleSaveSignature(signatureResult);
    }
  };
  const scrollToActive = () => {
    if (ref?.current && mode === 'Edit' && questions?.length && questions[activeIndex]) {
      const fields = questions[activeIndex].Fields || [];
      if (fields?.length && fields[0] && fields[0].id) {
        const element = (ref?.current as HTMLDivElement).querySelector(
          `[itemid="${fields[0].id}-pdfField"]`,
        );

        if (element && element.scrollIntoView) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  };

  const onDocumentLoadSuccess = () => {
    setLoading(false);
  };

  const getPageSize = (page: number, PDF): PageSizeType => {
    if (PDF) {
      const pageInfo = PDF.getPage(page);
      const size = pageInfo.getSize();

      return {
        width: size.width * scale,
        height: size.height * scale,
      };
    }
    return {
      width: 0,
      height: 0,
    };
  };

  const updateScale = (newScale) => {
    if (newScale <= 0 || newScale > MAX_ZOOM_SCALE) {
      return;
    }
    setScale(newScale);
  };

  const handleStrikeThroughToggle = (e) => {
    e.preventDefault();
    toggleStrikeThroughMode?.();
  };

  const getUnresolvedComments = () => {
    if (!formCommentThreads) return 0;

    return formCommentThreads.filter((item) => !item.Resolved).length;
  };

  const PDFWidgets = () => {
    return (
      <div className={styles.zoomWidgets}>
        {setCommentPanelOpen ? (
          <div className={styles.commentsIcon} onClick={() => setCommentPanelOpen(true)}>
            <Badge
              count={getUnresolvedComments()}
              overflowCount={5}
              className={styles.commentsBadge}
            >
              <Icons variant="commentDrawer" />
            </Badge>
          </div>
        ) : (
          <></>
        )}

        {toggleStrikeThroughMode && (
          <div className={styles.strikeThroughIcon} onClick={handleStrikeThroughToggle}>
            <StrikeThroughIcon />
          </div>
        )}

        <div
          className={classNames(styles.zoomWidget, styles.upperZoomWidget)}
          onClick={() => updateScale(scale + SCALE_STEP)}
        >
          <Icons variant="zoomPlus" />
        </div>
        <div
          className={classNames(styles.zoomWidget, styles.lowerZoomWidget)}
          onClick={() => updateScale(scale - SCALE_STEP)}
        >
          <Icons variant="zoomMinus" />
        </div>
      </div>
    );
  };

  const hasPDF =
    pdfDocuments.length > 0 && pdfDocuments[0].documentBuffer && pdfDocuments[0].pdfDoc;

  return (
    <div className={styles.pdfContainer} ref={ref}>
      {loading && <Spin spinning={true} className={styles.loader} size={'large'} />}

      {hasPDF ? (
        <>
          {!!signModalFieldsProps && (
            <SignModal
              open={!!signModalFieldsProps}
              fields={signModalFieldsProps?.fields || []}
              onCancel={() => setSignModalFieldsProps(undefined)}
              onResult={handleSignatureResult}
              response={signModalFieldsProps?.response}
              fieldKey={signModalFieldsProps?.fieldKey}
            />
          )}

          {pdfDocuments.map((document, index) => {
            const pages = document.pdfDoc?.getPages() || [];
            return (
              <Document
                file={document.documentBuffer}
                onLoadSuccess={onDocumentLoadSuccess}
                className={styles.pdfDocument}
                key={index}
              >
                {mode === 'Sign' && !loading && (
                  <SignWizard
                    allQuestions={questions}
                    responses={responses}
                    containerRef={containerRef}
                  />
                )}

                {pages.map((_, pageIndex) => (
                  <Page
                    key={`pdf-page-${pageIndex}`}
                    pageNumber={pageIndex + 1}
                    className={styles.documentPage}
                    renderInteractiveForms={true}
                    renderAnnotationLayer={false}
                    {...getPageSize(pageIndex, document.pdfDoc)}
                  >
                    <div
                      className={styles.renderFieldsContainer}
                      style={{ ...getPageSize(pageIndex, document.pdfDoc) }}
                      key={`page-field-container-${pageIndex}`}
                    >
                      {questions?.length ? (
                        <RenderPageFields
                          key={`page-render-fields-${pageIndex}`}
                          page={pageIndex}
                          questions={questions}
                          responses={responses}
                          mode={mode}
                          highlightedFields={highlightedFields}
                          unansweredRequiredQs={unansweredRequiredQs || []}
                          scale={scale}
                          showRequiredQs={!!showRequiredQs}
                          onFieldClick={handlePointUpdate ? undefined : onFieldClick}
                          onFieldUpdate={onFieldUpdate}
                          onSignUpdate={onSignUpdate}
                          setSignModalFieldsProps={setSignModalFieldsProps}
                          signatureFields={signatureFields}
                          activeIndex={activeIndex}
                          setActiveIndex={setActiveIndex}
                          isMock={isMock}
                          handleDeferModal={handleDeferModal}
                          setFieldInfo={setFieldInfo}
                          ref={pdfInputRef}
                          handleAddFormComment={handleAddFormComment}
                          selectedFieldInfo={selectedFieldInfo}
                        />
                      ) : (
                        <></>
                      )}

                      {disabledQuestions?.length ? (
                        <RenderDisabledPageFields
                          page={pageIndex}
                          questions={disabledQuestions}
                          deferredUsers={deferredUsers}
                          mode={mode}
                          scale={scale}
                          responses={responses}
                          key={`page-render-disabled-fields-${pageIndex}`}
                          isCreator={isCreator}
                          setUndoDefer={setUndoDefer}
                        />
                      ) : (
                        <></>
                      )}

                      {formComment &&
                      handleGetFormComments &&
                      handleAddFormComment &&
                      handleEditFormComment &&
                      handleDeleteFormComment &&
                      !handlePointUpdate ? (
                        <RenderComments
                          key={`page-render-comments-${pageIndex}`}
                          disabledQuestions={disabledQuestions}
                          page={pageIndex}
                          mode={mode}
                          scale={scale}
                          commentUsers={commentUsers}
                          formComment={formComment}
                          handleGetFormComments={handleGetFormComments}
                          handleAddFormComment={handleAddFormComment}
                          handleEditFormComment={handleEditFormComment}
                          handleDeleteFormComment={handleDeleteFormComment}
                          handleToggleResolveThread={handleToggleResolveThread}
                          getPageSize={(page) => getPageSize(page, document.pdfDoc)}
                        />
                      ) : (
                        <></>
                      )}

                      <RenderStrikeThrough
                        key={`page-render-strike-through-${pageIndex}`}
                        page={pageIndex}
                        pageSize={getPageSize(pageIndex, document.pdfDoc)}
                        scale={scale}
                        strikeThroughLines={strikeThrough?.[pageIndex] || []}
                        color={color}
                        strokeWidth={strokeWidth}
                        updatePoints={
                          handlePointUpdate
                            ? (points) => handlePointUpdate(pageIndex, points)
                            : undefined
                        }
                      />
                    </div>
                  </Page>
                ))}
              </Document>
            );
          })}

          {!loading && <PDFWidgets />}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
