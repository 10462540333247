import {
  ADD_FORM_TO_FAVORITES,
  ADD_NEW_FORM,
  CREATE_FORM_TEMPLATE,
  DELETE_FORM_VERSION,
  GET_FORMS_LIST,
  GET_FORM_META,
  GET_USER_FORM_META,
  SMART_FORM_UPLOAD,
  UPDATE_BUNDLE_TEMPLATE,
  UPDATE_FORM_TEMPLATE,
  UPDATE_FORM_VERSION_STATUS,
} from 'app-constants';
import { apiServer } from 'settings/web-services/api';

export function getFormList(cfg) {
  return apiServer.post(GET_FORMS_LIST, cfg);
}

export function addFormToFavorites({ formId, ...cfg }) {
  return apiServer.patch(ADD_FORM_TO_FAVORITES(formId), cfg);
}

export function updateFormVersionStatus({ formId, formVersionId, ...cfg }) {
  return apiServer.patch(UPDATE_FORM_VERSION_STATUS(formId, formVersionId), cfg);
}

export function getFormMeta() {
  return apiServer.get(GET_FORM_META);
}

export function getUserFormMeta() {
  return apiServer.get(GET_USER_FORM_META);
}

export function deleteFormVersion({ formVersionId, ...cfg }) {
  return apiServer.delete(DELETE_FORM_VERSION(formVersionId), cfg);
}

export function createNewForm(cfg) {
  return apiServer.post(ADD_NEW_FORM, cfg);
}

export function createNewTemplate(cfg) {
  return apiServer.post(CREATE_FORM_TEMPLATE, cfg);
}

export function updateFormTemplate({ templateId, ...cfg }) {
  return apiServer.put(UPDATE_FORM_TEMPLATE(templateId), cfg);
}

export function updateBundleTemplate({ templateBundleId, ...cfg }) {
  return apiServer.patch(UPDATE_BUNDLE_TEMPLATE(templateBundleId), cfg);
}

export function uploadSmartForm(cfg) {
  const { formData } = cfg;
  return apiServer.post(SMART_FORM_UPLOAD, formData);
}
