import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { FormIdsType, FormProcessDataType } from 'types';
import { Spinner } from 'components';
import { PreForm } from '../../components';
import { link } from 'settings/navigation/link';
import { getFormPayload } from '../../helper';
import { routes } from 'settings/navigation/routes';
import {
  getFormMetaEffect,
  getPostFormDataEffect,
  getSmartFormDataEffect,
  resetPreFormEffect,
  updateFormEffect,
} from 'store/effects/formProcess';
import { getRequestFormProcessSelector } from 'store/selectors/requestFormProcess';

import styles from '../styles.module.scss';

export const UpdateRequest = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { state } = useLocation<{ selectedForm: FormIdsType; index: number }>();
  const { formProcessPublicId, formDocumentPublicId } = useParams<FormProcessDataType>();

  const { isPending, meta, formRoles, formName, preForm } = useSelector(
    getRequestFormProcessSelector,
  );

  const { clientName, clientAddress } = preForm;

  useEffect(
    () => () => {
      dispatch(resetPreFormEffect());
    },
    [],
  );

  useEffect(() => {
    dispatch(getPostFormDataEffect({ formProcessPublicId, formDocumentPublicId }));
  }, []);

  useEffect(() => {
    if (!Object.keys(meta).length) {
      dispatch(getFormMetaEffect());
    }

    dispatch(getSmartFormDataEffect({ ...state?.selectedForm }));
  }, []);

  const handleExit = () => {
    history.replace(routes.workshopForms);
  };

  const handlePreFormUpdate = () => {
    if (formProcessPublicId && formDocumentPublicId) {
      dispatch(
        updateFormEffect(
          getFormPayload(preForm, {
            formProcessIds: { formProcessPublicId, formDocumentPublicId },
          }),
          (err) => {
            if (!err) {
              history.push(
                link.toWorkshopFormProcessDocument({
                  formProcessPublicId,
                  formDocumentPublicId,
                }),
              );
            }
          },
        ),
      );
    }
  };

  return (
    <div className={styles.container}>
      {isPending ? (
        <Spinner />
      ) : (
        <PreForm
          // TODO: Update Pre form to work with edit
          // minIndex={1}
          // preFormIndex={state?.index}
          pdfName={formName}
          clientAddress={clientAddress}
          clientName={clientName}
          handleDone={handlePreFormUpdate}
          handleExit={handleExit}
        />
      )}
    </div>
  );
};
