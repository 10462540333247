import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import {
  requestGetQuoteDetailsEffect,
  resetQuoteDetailsEffect,
  respondAndUploadDocumentEffect,
} from 'store/effects/quotes';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import { Skeleton } from 'antd';
import { Modal, Title } from 'components-antd';
import { Content, QuoteHeader, Upload } from './components';

import styles from './styles.module.scss';

const QuoteDetails = (props) => {
  const { quoteId, open, onCancel } = props;
  const dispatch = useDispatch();
  const { quote, isPending } = useSelector(getQuoteDetailsSelector);

  const [uploadPending, setUploadPending] = useState(false);

  useLayoutEffect(() => {
    if (open && quoteId) {
      dispatch(requestGetQuoteDetailsEffect({ id: quoteId }));
    }

    return () => dispatch(resetQuoteDetailsEffect());
  }, [open, quoteId]); // eslint-disable-line

  const onUpload = (event) => {
    setUploadPending(true);
    dispatch(
      respondAndUploadDocumentEffect({ id: quote?.Id, files: event.target.files }, {}, () => {
        setUploadPending(false);
      }),
    );
  };

  const getTitle = () => (
    <div className={styles.quoteDetailsTitle}>
      <Title level={4}>Quote Request</Title>
    </div>
  );

  return (
    <Modal
      title={getTitle()}
      footer={null}
      className={styles.baseQuoteDetails}
      open={open}
      {...props}
    >
      {isPending ? (
        <>
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <>
          <div className={styles.quoteDetailsInner}>
            <QuoteHeader onCancel={onCancel} />
            <Content />
          </div>
          <div className={styles.quoteDetailsBottom}>
            <Upload
              onChange={onUpload}
              id="uploadQuote"
              isPending={uploadPending}
              className={styles.quoteDetailsUpload}
              iconClassName={styles.quoteDetailsUploadIcon}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

QuoteDetails.propTypes = {
  quoteId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
};

QuoteDetails.defaultProps = {
  isGroupedByClient: false,
  open: true,
  onCancel: () => {},
};

export default QuoteDetails;
