export interface AppointmentBookingForm {
  BuyingAgentName?: string;
  BuyingAgentBrokerage?: string;
  BuyingAgentPhone?: string;
  BuyingAgentEmail?: string;
  AppointmentDate?: string;
  AppointmentTime?: string;
  AppointmentStatus?: string;
  ApprovedDate?: Date;
  CancellationDate?: Date;
  ArchivedDate?: Date;
}

interface Property {
  Property: PropertyAddress;
}
interface PropertyAddress {
  City: string;
  State: string;
  Zip: string;
  Line1: string;
  ProviderPlaceId: string;
  PlaceName: string;
}

interface PropertyAgent {
  Participant: Participant;
}

export interface Participant {
  FirstName: string;
  LastName: string;
  Email: string;
  Company: string;
  Phones: Phone[];
}

interface Phone {
  PhoneNumber: string;
  PhoneType: string;
  IsPrimary: boolean;
}

export interface ShowingDetails {
  showingId?: number;
  Status?: string;
  ShowingDuration?: string;
  AddBufferBetweenShowings?: boolean;
  BufferTime?: number;
  LinkActiveDays?: number;
  LinkActiveEndDate?: Date;
  ShowingLink?: string;
  DisplayBookingPageNotes?: boolean;
  BookingPageNotes?: string;
  SendAppointmentConfirmationNotes?: boolean;
  AppointConfirmationNotes?: string;
  RequireDocumentPreApproval?: boolean;
  OtherRequiredDocuments?: JSON[];
  PropertyTransaction?: Property;
  PropertyAgent?: PropertyAgent;
  CreatedBy: string;
  CreatedDate: Date;
}
export interface ShowingAppointmentState {
  state: string;
  data: ShowingDetails;
}
export interface ShowingAvailabilityState {
  state: string;
  data: ShowingAvailability[];
}
export interface ShowingAvailability {
  DaysOfWeek?: JSON[];
  StartTime?: string;
  EndTime?: string;
  AutomaticApproval?: boolean;
  SequentialApproval?: boolean;
  ApproversList?: Approver[];
}
export interface Approver {
  UserId: number;
  Role: string;
  SellerAgent: string;
  RequireApproval: boolean;
  Sequence: number;
}

export interface DateTime {
  date?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  timeZone?: string | null;
}

export interface AppointmentForm {
  Id?: number;
  ShowingId?: number;
  AvailabilityId?: number;
  BuyingAgentName?: string;
  BuyingAgentBrokerage?: string;
  BuyingAgentPhone?: string;
  BuyingAgentEmail?: string;
  IsEmailNotification?: boolean;
  IsSMSNotification?: boolean;
  AppointmentDate?: string;
  AppointmentStartTime?: string;
  AppointmentEndTime?: string;
  AppointmentStatus?: string;
  IsRescheduled?: boolean;
  IsNewFeedback?: boolean;
  IsElapsed?: boolean;
  PerformedBy?: JSON;
  Reason: string;
  RequireDocumentPreApproval?: boolean;
  AppointmentDocuments?: JSON[];
}

export interface AgentDetailsUpdate {
  FirstName: string;
  LastName: string;
  Email: string;
  Company: string;
  Phone: string;
}

export enum AppointmentStatus {
  Pending = 'Pending',
  Upcoming = 'Upcoming',
  NewFeedback = 'NewFeedback',
  Past = 'Past',
  Declined = 'Declined',
  Canceled = 'Canceled',
  Rescheduled = 'Rescheduled',
}

export enum ApprovalStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
}

export enum ApprovalAction {
  Approve = 'approve',
  Decline = 'decline',
  Cancel = 'cancel',
  Rescheduled = 'rescheduled',
}
