import { createSelector } from 'reselect';
import {
  getThreadsListSelector,
  getTransactionThreadsListSelector,
} from 'store/selectors/sockets/threads';
import {
  getCurrentTransactionMessagesInfoSelector,
  getCurrentTransactionMessagesThreadIdSelector,
  getCurrentTransactionThreadSelector,
} from 'store/selectors/sockets/transactionMessages';
import { ERROR, IDLE, PENDING } from 'settings/constants/apiState';
import { orderBy } from 'lodash-es';
import { getUserId } from '../user';
import { getTransactionSelector } from '../transaction';

const localState = ({ transactionMessages }) => transactionMessages;

export const getTransactionMessagesStateSelector = createSelector(
  localState,
  (transactionMessages) => ({
    isIdle: transactionMessages?.state === IDLE,
    isPending: transactionMessages?.state === PENDING,
    isError: transactionMessages?.state === ERROR,
    isData: !!transactionMessages?.data,
    statePerTransaction: transactionMessages?.statePerTransaction,
  }),
);

export const getTransactionMessagesLastThreadIdSelector = createSelector(
  getTransactionMessagesStateSelector,
  getTransactionSelector,
  ({ statePerTransaction }, { transaction: currentTransaction }) =>
    statePerTransaction?.[currentTransaction?.Id]?.lastThreadId,
);

export const getTransactionMessagesSelector = createSelector(
  getCurrentTransactionMessagesThreadIdSelector,
  getCurrentTransactionThreadSelector,
  getCurrentTransactionMessagesInfoSelector,
  getThreadsListSelector,
  getUserId,
  (currentThreadId, currentThread, messages, threadsList, userId) => {
    const handledCurrentThread = {
      ...(currentThread || {}),
      Participants: orderBy(
        (currentThread?.Participants || []).filter((participant) => participant?.Id !== userId),
        ['FirstName'],
        ['asc'],
      ),
    };

    return {
      currentThreadId,
      currentThread: handledCurrentThread,
      messages,
      threadsList,
      userId,
    };
  },
);

export const getTransactionMessagesParamsSelector = createSelector(
  localState,
  ({ isNewMessage, isAddParticipants, isViewContacts, participants }) => ({
    isNewMessage,
    isAddParticipants,
    isViewContacts,
    participants,
  }),
);
