import React from 'react';

import { LeftSide, RightSide, Container } from 'components/LoginGroup';

import Form from './Form';

const ForgotPassword = () => (
  <Container>
    <LeftSide variant={LeftSide.LIGHT} />
    <Form />
    <RightSide />
  </Container>
);

export default ForgotPassword;
