import classNames from 'classnames';
import { InputLabel } from 'components';
import { ClientRole, AssignToInput } from 'components/Transactions';
import { TaskEditableFormValuesType, TaskEditableFormValuesKeys } from 'types/transactionTasks';

import styles from './styles.module.scss';

interface CcToProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  isTemplate: boolean;
  className?: string | null;
  isAssignTask?: boolean | null;
  isViewMode?: boolean | null;
  showOptional?: boolean | null;
}

export const CcTo = ({
  isTemplate,
  values,
  onChangeField,
  isAssignTask,
  className,
  isViewMode,
  showOptional,
}: CcToProps) => {
  return (
    <div className={classNames(styles.ccTo, className)}>
      <div className={styles.fieldWrapper}>
        {isTemplate ? (
          <>
            <InputLabel label="CC" className={styles.label} showOptional={showOptional} />
            <ClientRole
              isSearchIcon={false}
              multiselect={true}
              onChange={(e) => {
                onChangeField(
                  e.target.value.map((v) => v.value),
                  'CcRoles',
                );
              }}
              value={values?.CcRoles}
              closeOnSelect={false}
              isViewMode={isViewMode}
            />
          </>
        ) : (
          <AssignToInput
            className={styles.ccToInputLabel}
            wrapperClassName={styles.ccToWrapper}
            label="CC"
            placeholder="Select participant(s)"
            showIcon={false}
            values={values?.CcList}
            onChange={(e, val) => onChangeField(val, 'CcList')}
            isAssignTask={isAssignTask}
            isArrowIcon
          />
        )}
      </div>
    </div>
  );
};
