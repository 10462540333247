import Api from '../core/api';
import {
  requestGetShowingAggregateByUserId,
  resetShowingAggregateAction,
  setShowingsArchiveAction,
  setShowingsArchiveIdAction,
  setShowingsFiltersAppliedAction,
  setShowingsFilterAction,
  setListingsFilterAction,
} from 'store/actions/showingAggregate';
import { getShowingsAggregateByUserId } from 'api/showingAggregate';

export const getShowingAggregateByUserIdEffect = (cfg, options = {}, cb = () => {}) => {
  const sendRequest = Api.execBase({
    action: requestGetShowingAggregateByUserId,
    method: getShowingsAggregateByUserId,
  });
  return sendRequest(cfg, options, cb);
};

export const setShowingsIsArchiveEffect = (cfg) => (dispatch) => {
  dispatch(setShowingsArchiveAction(cfg));
};

export const setShowingsArchiveIdEffect = (cfg) => (dispatch) => {
  dispatch(setShowingsArchiveIdAction(cfg));
};

export const setShowingsFiltersAppliedEffect = (cfg) => (dispatch) => {
  dispatch(setShowingsFiltersAppliedAction(cfg));
};

export const resetShowingAggregateEffect = () => (dispatch) => {
  dispatch(resetShowingAggregateAction());
};

export const setShowingsFilterEffect = (cfg) => (dispatch) => {
  dispatch(setShowingsFilterAction(cfg));
};

export const setListingsFilterEffect = (cfg) => (dispatch) => {
  dispatch(setListingsFilterAction(cfg));
};

export const searchShowingAggregateByUserIdEffect =
  ({ search, userId, filters = {} }) =>
  (dispatch) => {
    dispatch(
      getShowingAggregateByUserIdEffect({
        id: userId,
        filters: {
          searchString: search,
          ...filters,
        },
      }),
    );
  };

export const resetShowingAggregateByUserIdSearchEffect =
  (userId, filters = {}) =>
  (dispatch) => {
    dispatch(searchShowingAggregateByUserIdEffect({ search: '', userId, filters }));
  };
