import React, { useCallback } from 'react';

interface ProfileIconsProps extends React.SVGProps<SVGSVGElement> {
  name: string;
}

export const ProfileIcons = ({ name, color = '#AAABAB', ...props }: ProfileIconsProps) => {
  const getIcon = useCallback(() => {
    switch (name) {
      case ProfileIcons.File:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 13.5L11 15.5L15 11.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.PHONE:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M18.3322 14.0994V16.5994C18.3332 16.8315 18.2856 17.0612 18.1926 17.2739C18.0997 17.4865 17.9633 17.6774 17.7923 17.8343C17.6213 17.9912 17.4194 18.1107 17.1995 18.185C16.9797 18.2594 16.7467 18.287 16.5156 18.2661C13.9512 17.9875 11.4881 17.1112 9.32389 15.7078C7.31041 14.4283 5.60333 12.7212 4.32389 10.7078C2.91553 8.53377 2.03909 6.05859 1.76555 3.48276C1.74473 3.25232 1.77212 3.02006 1.84597 2.80078C1.91982 2.5815 2.03853 2.38 2.19452 2.20911C2.35052 2.03822 2.54039 1.90169 2.75205 1.8082C2.9637 1.71471 3.1925 1.66631 3.42389 1.6661H5.92389C6.32831 1.66212 6.72038 1.80533 7.02702 2.06904C7.33366 2.33275 7.53395 2.69897 7.59055 3.09943C7.69607 3.89948 7.89176 4.68504 8.17389 5.4411C8.28601 5.73937 8.31027 6.06352 8.24381 6.37516C8.17734 6.6868 8.02294 6.97286 7.79889 7.19943L6.74055 8.25776C7.92685 10.3441 9.65426 12.0715 11.7406 13.2578L12.7989 12.1994C13.0255 11.9754 13.3115 11.821 13.6232 11.7545C13.9348 11.688 14.2589 11.7123 14.5572 11.8244C15.3133 12.1066 16.0988 12.3022 16.8989 12.4078C17.3037 12.4649 17.6734 12.6688 17.9377 12.9807C18.2019 13.2926 18.3424 13.6907 18.3322 14.0994Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.EMAIL:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <rect
              x="2"
              y="4"
              width="16"
              height="12"
              rx="2"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path
              d="M2 5L9.4 10.55C9.75556 10.8167 10.2444 10.8167 10.6 10.55L18 5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.ADDRESS:
      case ProfileIcons.MAILING_ADDRESS:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <ellipse cx="10" cy="9.16699" rx="2.5" ry="2.5" stroke="#AAABAB" strokeWidth="2" />
            <path
              d="M11.0901 17.9162L10.4575 17.1417L11.0901 17.9162ZM15.6666 9.16667C15.6666 10.7072 14.8937 12.2948 13.7899 13.7531C12.6988 15.1945 11.3713 16.3953 10.4575 17.1417L11.7227 18.6907C12.6993 17.893 14.16 16.578 15.3846 14.9602C16.5964 13.3592 17.6666 11.3441 17.6666 9.16667H15.6666ZM9.54233 17.1417C8.62857 16.3953 7.30101 15.1945 6.20995 13.7531C5.10614 12.2948 4.33325 10.7072 4.33325 9.16667H2.33325C2.33325 11.3441 3.40343 13.3592 4.61527 14.9602C5.83986 16.578 7.30054 17.893 8.27713 18.6907L9.54233 17.1417ZM4.33325 9.16667C4.33325 6.03705 6.8703 3.5 9.99992 3.5V1.5C5.76573 1.5 2.33325 4.93248 2.33325 9.16667H4.33325ZM9.99992 3.5C13.1295 3.5 15.6666 6.03705 15.6666 9.16667H17.6666C17.6666 4.93248 14.2341 1.5 9.99992 1.5V3.5ZM10.4575 17.1417C10.1842 17.365 9.81565 17.365 9.54233 17.1417L8.27713 18.6907C9.28672 19.5153 10.7131 19.5153 11.7227 18.6907L10.4575 17.1417Z"
              fill="#AAABAB"
            />
          </svg>
        );
      case ProfileIcons.MESSAGE:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M5.18229 16.0581L6.10236 16.4498L6.30022 15.9851L6.04365 15.5501L5.18229 16.0581ZM3.54135 19.912L2.62128 19.5203H2.62128L3.54135 19.912ZM4.18134 20.5744L4.54126 21.5074H4.54126L4.18134 20.5744ZM8.22693 19.0137L8.70902 18.1376L8.30125 17.9132L7.86701 18.0807L8.22693 19.0137ZM19.0781 12C19.0781 15.866 15.9441 19 12.0781 19V21C17.0487 21 21.0781 16.9706 21.0781 12H19.0781ZM12.0781 5C15.9441 5 19.0781 8.13401 19.0781 12H21.0781C21.0781 7.02944 17.0487 3 12.0781 3V5ZM5.07811 12C5.07811 8.13401 8.21212 5 12.0781 5V3C7.10755 3 3.07811 7.02944 3.07811 12H5.07811ZM6.04365 15.5501C5.43029 14.51 5.07811 13.2977 5.07811 12H3.07811C3.07811 13.6647 3.53101 15.2267 4.32093 16.5661L6.04365 15.5501ZM4.46142 20.3038L6.10236 16.4498L4.26222 15.6663L2.62128 19.5203L4.46142 20.3038ZM3.82142 19.6414C4.23373 19.4824 4.63454 19.8972 4.46142 20.3038L2.62128 19.5203C2.10191 20.7401 3.30435 21.9846 4.54126 21.5074L3.82142 19.6414ZM7.86701 18.0807L3.82142 19.6414L4.54126 21.5074L8.58685 19.9467L7.86701 18.0807ZM12.0781 19C10.8548 19 9.70754 18.687 8.70902 18.1376L7.74483 19.8898C9.03123 20.5977 10.5092 21 12.0781 21V19Z"
              fill={color}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.55859 12C9.55859 12.8284 8.88702 13.5 8.05859 13.5C7.23017 13.5 6.55859 12.8284 6.55859 12C6.55859 11.1716 7.23017 10.5 8.05859 10.5C8.88702 10.5 9.55859 11.1716 9.55859 12ZM13.5586 12C13.5586 12.8284 12.887 13.5 12.0586 13.5C11.2302 13.5 10.5586 12.8284 10.5586 12C10.5586 11.1716 11.2302 10.5 12.0586 10.5C12.887 10.5 13.5586 11.1716 13.5586 12ZM16.0586 13.5C16.887 13.5 17.5586 12.8284 17.5586 12C17.5586 11.1716 16.887 10.5 16.0586 10.5C15.2302 10.5 14.5586 11.1716 14.5586 12C14.5586 12.8284 15.2302 13.5 16.0586 13.5Z"
              fill={color}
            />
          </svg>
        );
      case ProfileIcons.ACTIVE_TRANSACTIONS:
        return (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="56" height="56" rx="8" fill="#F4F5F6" />
            <path
              d="M17 24V40H39V24"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 25L28 15L33.9062 19.5432M41 25L38.6335 23.1796M33.9062 19.5432V15H38.6335V23.1796M33.9062 19.5432L38.6335 23.1796"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23 27H33M33 27L30.5 24.5M33 27L30.5 29.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M33 33H23M23 33L25.5 30.5M23 33L25.5 35.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.MENU:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="white" />
            <path
              d="M9.00024 17C9.55253 17 10.0002 16.5523 10.0002 16C10.0002 15.4477 9.55253 15 9.00024 15C8.44796 15 8.00024 15.4477 8.00024 16C8.00024 16.5523 8.44796 17 9.00024 17Z"
              fill="#747475"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.9998 17C16.552 17 16.9998 16.5523 16.9998 16C16.9998 15.4477 16.552 15 15.9998 15C15.4475 15 14.9998 15.4477 14.9998 16C14.9998 16.5523 15.4475 17 15.9998 17Z"
              fill="#747475"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23 17C23.5523 17 24 16.5523 24 16C24 15.4477 23.5523 15 23 15C22.4477 15 22 15.4477 22 16C22 16.5523 22.4477 17 23 17Z"
              fill="#747475"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.PROFILE:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.375 7.625C16.375 10.0412 14.4162 12 12 12C9.58375 12 7.625 10.0412 7.625 7.625C7.625 5.20875 9.58375 3.25 12 3.25C14.4162 3.25 16.375 5.20875 16.375 7.625Z"
              stroke={color}
              strokeWidth="2"
            />
            <path
              d="M5 19.875C5.43626 17.5379 7.44201 15.5 9.66652 15.5H14.3333C16.5578 15.5 18.5637 17.5379 19 19.875"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.SEARCH:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.4444 15.8889C13.4513 15.8889 15.8889 13.4513 15.8889 10.4444C15.8889 7.43756 13.4513 5 10.4444 5C7.43756 5 5 7.43756 5 10.4444C5 13.4513 7.43756 15.8889 10.4444 15.8889Z"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 18.9997L14.3334 14.333"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.DOCUMENTS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M13.2857 18H4.71429C4.25963 18 3.82359 17.8127 3.5021 17.4793C3.18061 17.1459 3 16.6937 3 16.2222V3.77778C3 3.30628 3.18061 2.8541 3.5021 2.5207C3.82359 2.1873 4.25963 2 4.71429 2H10L15 7V16.2222C15 16.6937 14.8194 17.1459 14.4979 17.4793C14.1764 17.8127 13.7404 18 13.2857 18Z"
              fill="white"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.2857 21H9.71429C9.25963 21 8.82359 20.8127 8.5021 20.4793C8.18061 20.1459 8 19.6937 8 19.2222V6.77778C8 6.30628 8.18061 5.8541 8.5021 5.5207C8.82359 5.1873 9.25963 5 9.71429 5H15L20 10V19.2222C20 19.6937 19.8194 20.1459 19.4979 20.4793C19.1764 20.8127 18.7404 21 18.2857 21Z"
              fill="white"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 5V9C15 9.26522 15.1054 9.51957 15.2929 9.70711C15.4804 9.89464 15.7348 10 16 10H20"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.ACTIVITY:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 12H17L14 20L10 4L7 12H3"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.TRANSACTIONS:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.75 9.75V21.75H20.25V9.75"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.25 10.5L12 3L16.4297 6.40743M21.75 10.5L19.9751 9.1347M16.4297 6.40743V3H19.9751V9.1347M16.4297 6.40743L19.9751 9.1347"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.25 12H15.75M15.75 12L13.875 10.125M15.75 12L13.875 13.875"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.75 16.5H8.25M8.25 16.5L10.125 14.625M8.25 16.5L10.125 18.375"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.SEARCHES:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.4444 15.8889C13.4513 15.8889 15.8889 13.4513 15.8889 10.4444C15.8889 7.43756 13.4513 5 10.4444 5C7.43756 5 5 7.43756 5 10.4444C5 13.4513 7.43756 15.8889 10.4444 15.8889Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 18.9997L14.3333 14.333"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.EXPANDED:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M25 25L19.0001 19.0001M19.0001 19.0001L19 26M19.0001 19.0001H26"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 7L12.9999 12.9999M12.9999 12.9999L13 6M12.9999 12.9999H6"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.STATUS:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_17268_164971)">
              <path
                d="M17.75 10C17.75 14.2802 14.2802 17.75 10 17.75C5.71979 17.75 2.25 14.2802 2.25 10C2.25 5.71979 5.71979 2.25 10 2.25C14.2802 2.25 17.75 5.71979 17.75 10Z"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 10C10 11.3017 10 11.8233 10 13.125"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="10" cy="6.25" r="1.25" fill="#AAABAB" />
            </g>
            <defs>
              <clipPath id="clip0_17268_164971">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case ProfileIcons.JOINED_MOSAIK:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 7C10.5 8.933 8.933 10.5 7 10.5C5.067 10.5 3.5 8.933 3.5 7C3.5 5.067 5.067 3.5 7 3.5C8.933 3.5 10.5 5.067 10.5 7Z"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <path
              d="M16 8.5L16 13.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 11L18.5 11"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.5 17C1.84278 15.3974 3.41872 14 5.16655 14H8.83333C10.5812 14 12.1572 15.3974 12.5 17"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.LAST_ACTIVE:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_19621_175660)">
              <path
                d="M17.5 9.99967H14.1667L11.6667 16.6663L8.33333 3.33301L5.83333 9.99967H2.5"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_19621_175660">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case ProfileIcons.CLIENTS:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 7.5C13.5 9.98528 11.4853 12 9 12C6.51472 12 4.5 9.98528 4.5 7.5C4.5 5.01472 6.51472 3 9 3C11.4853 3 13.5 5.01472 13.5 7.5Z"
              stroke={color}
              strokeWidth="2.5"
            />
            <path
              d="M15.75 3.06219C17.8783 3.41925 19.5 5.27023 19.5 7.49998C19.5 9.72972 17.8783 11.5807 15.75 11.9378"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M2.25 20.625C2.67068 18.2211 4.6048 16.125 6.74986 16.125H11.25C13.3951 16.125 15.3293 18.2211 15.75 20.625"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.25 16.125C19.3951 16.125 21.3293 18.2211 21.75 20.625"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.TASKS:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.5 5.5L5 7L7.5 4.5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.5 11.5L5 13L7.5 10.5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.5 17.5L5 19L7.5 16.5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 6H20"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 12H20"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 18H20"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.EDIT:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 14H5.13144L13.3515 5.77998C13.7667 5.36472 14 4.80152 14 4.21426C14 3.627 13.7667 3.0638 13.3515 2.64854C12.9362 2.23329 12.373 2 11.7857 2C11.1985 2 10.6353 2.23329 10.22 2.64854L2 10.8686V14Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.43652 3.43192L12.568 6.56335"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.TASK:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_17129_164118)">
              <path
                d="M9 4H7C6.46957 4 5.96086 4.21071 5.58579 4.58579C5.21071 4.96086 5 5.46957 5 6V18C5 18.5304 5.21071 19.0391 5.58579 19.4142C5.96086 19.7893 6.46957 20 7 20H17C17.5304 20 18.0391 19.7893 18.4142 19.4142C18.7893 19.0391 19 18.5304 19 18V6C19 5.46957 18.7893 4.96086 18.4142 4.58579C18.0391 4.21071 17.5304 4 17 4H15"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13 2H11C9.89543 2 9 2.89543 9 4C9 5.10457 9.89543 6 11 6H13C14.1046 6 15 5.10457 15 4C15 2.89543 14.1046 2 13 2Z"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 13L11 15L15 11"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_17129_164118">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case ProfileIcons.UnderRetention:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 8H20.375C21.0712 8 21.7389 8.27656 22.2312 8.76884C22.7234 9.26113 23 9.92881 23 10.625V22L19.5 18.5H11.625C10.9288 18.5 10.2611 18.2234 9.76884 17.7312C9.27656 17.2389 9 16.5712 9 15.875V13.25"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 17V4.8125C1 4.06658 1.29632 3.35121 1.82376 2.82376C2.35121 2.29632 3.06658 2 3.8125 2H13.1875C13.9334 2 14.6488 2.29632 15.1762 2.82376C15.7037 3.35121 16 4.06658 16 4.8125V10.4375C16 11.1834 15.7037 11.8988 15.1762 12.4262C14.6488 12.9537 13.9334 13.25 13.1875 13.25H4.75L1 17Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.WEBSITE:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_111_2065)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM16.9297 14C16.1887 15.2811 15.1026 16.3375 13.7986 17.0424C14.354 16.2618 14.9304 15.2476 15.354 14H16.9297ZM15.8458 12C15.9442 11.3752 16 10.7085 16 10C16 9.2915 15.9442 8.6248 15.8458 8H17.748C17.9125 8.6392 18 9.3094 18 10C18 10.6906 17.9125 11.3608 17.748 12H15.8458ZM13.8392 12C13.9417 11.3732 14 10.7063 14 10C14 9.2937 13.9417 8.6268 13.8392 8H11V12H13.8392ZM11 14H13.3321C12.7154 15.7728 11.7891 17.0922 11.0695 17.9291C11.0463 17.9322 11.0232 17.9352 11 17.9381V14ZM9 12V8H6.16083C6.0583 8.6268 6 9.2937 6 10C6 10.7063 6.0583 11.3732 6.16083 12H9ZM6.66794 14H9V17.9381C8.9768 17.9352 8.9537 17.9322 8.9305 17.9291C8.2109 17.0922 7.28456 15.7728 6.66794 14ZM4.15415 12C4.05577 11.3752 4 10.7085 4 10C4 9.2915 4.05577 8.6248 4.15415 8H2.25203C2.08751 8.6392 2 9.3094 2 10C2 10.6906 2.08751 11.3608 2.25204 12H4.15415ZM3.07026 14H4.64603C5.06963 15.2476 5.64599 16.2618 6.20142 17.0424C4.89738 16.3375 3.81131 15.2811 3.07026 14ZM16.9297 6H15.354C14.9304 4.75238 14.354 3.73825 13.7986 2.9576C15.1026 3.66246 16.1887 4.71895 16.9297 6ZM11 6H13.3321C12.7154 4.22723 11.7891 2.90784 11.0695 2.07087C11.0463 2.06778 11.0232 2.06479 11 2.06189V6ZM9 2.06189V6H6.66795C7.28456 4.22723 8.2109 2.90784 8.9305 2.07087C8.9537 2.06778 8.9768 2.06479 9 2.06189ZM6.20141 2.9576C5.64598 3.73825 5.06962 4.75238 4.64603 6H3.07026C3.8113 4.71895 4.89738 3.66246 6.20141 2.9576Z"
                fill="#AAABAB"
              />
            </g>
            <defs>
              <clipPath id="clip0_111_2065">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case ProfileIcons.AREAS_OF_OPERATION:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_111_2052)">
              <path
                d="M2.5 5.83594L7.5 3.33594L12.5 5.83594L17.5 3.33594V14.1693L12.5 16.6693L7.5 14.1693L2.5 16.6693V5.83594Z"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 3.33594V14.1693"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.5 5.83594V16.6693"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_111_2052">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case ProfileIcons.BROKERAGE:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_111_2058)">
              <path
                d="M15.8333 5.83594H4.16667C3.24619 5.83594 2.5 6.58213 2.5 7.5026V15.0026C2.5 15.9231 3.24619 16.6693 4.16667 16.6693H15.8333C16.7538 16.6693 17.5 15.9231 17.5 15.0026V7.5026C17.5 6.58213 16.7538 5.83594 15.8333 5.83594Z"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.66699 5.83333V4.16667C6.66699 3.72464 6.84259 3.30072 7.15515 2.98816C7.46771 2.67559 7.89163 2.5 8.33366 2.5H11.667C12.109 2.5 12.5329 2.67559 12.8455 2.98816C13.1581 3.30072 13.3337 3.72464 13.3337 4.16667V5.83333"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 10V10.0083"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.5 10.8359C4.82632 12.0082 7.39502 12.6188 10 12.6188C12.605 12.6188 15.1737 12.0082 17.5 10.8359"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_111_2058">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case ProfileIcons.ADD_PARTICIPANTS:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 8C14 10.2091 12.2091 12 10 12C7.79086 12 6 10.2091 6 8C6 5.79086 7.79086 4 10 4C12.2091 4 14 5.79086 14 8Z"
              stroke="#515151"
              strokeWidth="2"
            />
            <path
              d="M20 9V15"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 12L23 12"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 19.5C4.37394 17.6303 6.09315 16 7.99988 16H12C13.9067 16 15.6261 17.6303 16 19.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ProfileIcons.PLUS_CIRCLE:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path d="M11 16H21" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M16 21L16 11" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      case ProfileIcons.MINUS_CIRCLE:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path d="M11 16H21" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      case ProfileIcons.USER_2:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#F4F5F6" />
            <path
              d="M13 9C13 10.6569 11.6569 12 10 12C8.34315 12 7 10.6569 7 9C7 7.34315 8.34315 6 10 6C11.6569 6 13 7.34315 13 9Z"
              stroke="#515151"
              strokeWidth="2"
            />
            <path
              d="M14.5 6.04102C15.9189 6.27905 17 7.51304 17 8.99954C17 10.486 15.9189 11.72 14.5 11.9581"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M5.5 17.75C5.78045 16.1474 7.06987 14.75 8.49991 14.75H11.5C12.93 14.75 14.2195 16.1474 14.5 17.75"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.5 14.75C16.93 14.75 18.2195 16.1474 18.5 17.75"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      default: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="white" />
            <path
              d="M9.00024 17C9.55253 17 10.0002 16.5523 10.0002 16C10.0002 15.4477 9.55253 15 9.00024 15C8.44796 15 8.00024 15.4477 8.00024 16C8.00024 16.5523 8.44796 17 9.00024 17Z"
              fill="#747475"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.9998 17C16.552 17 16.9998 16.5523 16.9998 16C16.9998 15.4477 16.552 15 15.9998 15C15.4475 15 14.9998 15.4477 14.9998 16C14.9998 16.5523 15.4475 17 15.9998 17Z"
              fill="#747475"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23 17C23.5523 17 24 16.5523 24 16C24 15.4477 23.5523 15 23 15C22.4477 15 22 15.4477 22 16C22 16.5523 22.4477 17 23 17Z"
              fill="#747475"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
    }
  }, [name, color]);

  return getIcon();
};

ProfileIcons.PHONE = 'Phone';
ProfileIcons.EMAIL = 'Email';
ProfileIcons.ADDRESS = 'Address';
ProfileIcons.MAILING_ADDRESS = 'Mailing Address';
ProfileIcons.MESSAGE = 'Message';
ProfileIcons.ACTIVE_TRANSACTIONS = 'ActiveTransactions';
ProfileIcons.MENU = 'Menu';
ProfileIcons.PROFILE = 'profile';
ProfileIcons.SEARCH = 'search';
ProfileIcons.ACTIVITY = 'Activity';
ProfileIcons.DOCUMENTS = 'Documents';
ProfileIcons.TRANSACTIONS = 'Transactions';
ProfileIcons.SEARCHES = 'Searches';
ProfileIcons.EXPANDED = 'Expanded';
ProfileIcons.STATUS = 'Status';
ProfileIcons.JOINED_MOSAIK = 'Joined Mosaik';
ProfileIcons.LAST_ACTIVE = 'Last Active';
ProfileIcons.CLIENTS = 'Clients';
ProfileIcons.TASKS = 'Tasks';
ProfileIcons.EDIT = 'Edit';
ProfileIcons.TASK = 'Task';
ProfileIcons.UnderRetention = 'Under Retention';
ProfileIcons.File = 'File';
ProfileIcons.WEBSITE = 'Website';
ProfileIcons.AREAS_OF_OPERATION = 'Areas of Operation';
ProfileIcons.BROKERAGE = 'Brokerage';
ProfileIcons.ADD_PARTICIPANTS = 'addParticipants';
ProfileIcons.PLUS_CIRCLE = 'plusCircle';
ProfileIcons.MINUS_CIRCLE = 'minusCircle';
ProfileIcons.USER_2 = 'user2';
