import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';

import Icons from '../icons';

import styles from './styles.module.scss';

interface FieldProps {
  serialNumber?: number;
  fieldName?: string;
  isPrivate?: boolean;
  fieldEditMode?: boolean;
  viewMode?: boolean;
  disabled: boolean;
  nameChangeEventHandler?: Function;
  privacyChangeEventHandler?: Function;
  editModeChangeHandler?: Function;
  deleteFieldHandler?: Function;
}

export default function Field({
  serialNumber = 0,
  fieldName = '',
  isPrivate = false,
  fieldEditMode = false,
  viewMode = false,
  disabled,
  nameChangeEventHandler,
  privacyChangeEventHandler,
  editModeChangeHandler,
  deleteFieldHandler,
}: FieldProps) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLInputElement>(null);
  const menuIconRef = useRef<HTMLDivElement>(null);

  const setFieldFocus = () => {
    if (textRef.current) {
      textRef.current.focus();
      textRef.current.value = fieldName;
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setFieldFocus();
  }, [fieldEditMode]);

  const handleOutsideClick = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      menuIconRef.current &&
      !menuIconRef.current.contains(event.target)
    ) {
      setShowMenu(false);
    }
  };

  function isSpecialKey(event) {
    const specialKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Delete',
      'Tab',
    ];
    return specialKeys.includes(event.key);
  }

  return (
    <div className={styles.field}>
      <div className={styles.fieldLabel}>
        <label className={styles.label} htmlFor="details_field_input">
          {'Field ' + (serialNumber + 1)}
        </label>
        {isPrivate ? <Icons iconName="lock" className={styles.lockIcon} /> : <></>}
      </div>
      {fieldEditMode ? (
        <input
          disabled={disabled}
          id="details_field_input"
          placeholder="Enter field name..."
          type="text"
          ref={textRef}
          name={'Field' + (serialNumber + 1)}
          onKeyDown={(e) => {
            const allowedCharactersRegex = /^[a-zA-Z0-9\-–\s]$/;
            const key = e.key;
            if (!allowedCharactersRegex.test(key) && !isSpecialKey(e)) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            nameChangeEventHandler &&
              nameChangeEventHandler(serialNumber, e.target.value.toString());
          }}
          maxLength={255}
          className={styles.fieldInput}
          required
        />
      ) : (
        <p className={classNames(styles.fieldInput, viewMode ? styles.viewMode : '')}>
          {fieldName}
        </p>
      )}
      {!(disabled || viewMode) ? (
        <div
          tabIndex={serialNumber}
          ref={menuIconRef}
          onClick={(e) => {
            setShowMenu(!showMenu);
          }}
        >
          <Icons iconName="menu" className={styles.fieldMenuIcon} />
        </div>
      ) : (
        <></>
      )}
      {showMenu ? (
        <div className={styles.menu} ref={menuRef}>
          <div
            className={styles.menuItem}
            onClick={(e) => {
              editModeChangeHandler && editModeChangeHandler(serialNumber);
              setShowMenu(!showMenu);
              setFieldFocus();
            }}
          >
            <Icons iconName="edit" />
            <p className={styles.menuItemText}>Edit Name</p>
          </div>
          <div
            className={styles.menuItem}
            onClick={(e) => {
              privacyChangeEventHandler && privacyChangeEventHandler(serialNumber, !isPrivate);
              setShowMenu(!showMenu);
            }}
          >
            <Icons iconName={isPrivate ? 'redUnlocked' : 'redLocked'} />
            <p className={styles.menuItemText}>{'Mark as ' + (isPrivate ? 'Shared' : 'Private')}</p>
          </div>
          <div
            className={styles.menuItem}
            onClick={(e) => {
              deleteFieldHandler && deleteFieldHandler(serialNumber);
              setShowMenu(!showMenu);
            }}
          >
            <Icons iconName="delete" />
            <p className={styles.menuItemText}>Delete</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
