import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components';

import styles from './styles.module.scss';

const Footer = (props) => {
  const { className, onSubmit } = props;

  return (
    <div className={classNames(styles.footer, className)}>
      <Button
        onClick={onSubmit}
        className={styles.submitButton}
        title="Done"
        testid="done_button"
      />
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};

Footer.defaultProps = {
  className: '',
  onSubmit: () => {},
};

export default Footer;
