import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';

interface CompareOfferFooterProps {
  totalOffersSelected?: number;
  onCompareClick?: Function;
  transactionId?: any;
  onCloseModal?: any;
}

export const CompareOfferFooter = ({
  totalOffersSelected = 0,
  onCompareClick,
  transactionId,
  onCloseModal,
}: CompareOfferFooterProps) => {
  const history = useHistory();
  return (
    <div key={+totalOffersSelected} className={styles.compareOfferFooter}>
      <p>{totalOffersSelected} Offers Selected</p>
      <div className={styles.buttonWrapper}>
        <Button
          type="primary"
          disabled={!(totalOffersSelected > 1)}
          onClick={() => {
            onCloseModal();
            history.push(`/workshop/compare-offers/${transactionId}`);
          }}
        >
          Compare
        </Button>
      </div>
    </div>
  );
};
