import validator from 'validator';

import {
  CommentPermissionType,
  CommentUsersType,
  FormIdsType,
  FormProcessDataType,
  PreFormRecipientType,
  PreFormType,
} from 'types';
import { Message } from 'components-antd';
import { FORM_RECIPIENTS, FORM_USER_ROLE } from 'app-constants';
import { Dispatch } from 'react';

import {
  getAnonFormProcessDocumentEffect,
  getFormProcessDocumentEffect,
} from 'store/effects/formProcess';
import { routes } from 'settings/navigation/routes';
import { AllowedAnonEnum } from 'app-constants';
import { link } from 'settings/navigation/link';
import { isArray } from 'lodash-es';

type EitherIdsType =
  | { formIds: FormIdsType; formProcessIds?: never }
  | { formIds?: never; formProcessIds: FormProcessDataType };

const getRecipients = (allRecipients: PreFormRecipientType[] = [], type: FORM_RECIPIENTS) =>
  allRecipients
    .filter((rec) => rec.type === type)
    .map((rec) => rec.id)
    .map((recipient) => ({
      ...(validator.isEmail(recipient) ? { Email: recipient } : { UserId: Number(recipient) }),
    }));

export const getFormPayload = (
  {
    transactionId,
    clientIds,
    editors,
    isSequentialSignature,
    signatories,
    copyRecipients,
    clientEditorRole,
    address,
    formName,
    customFormRoles,
    documentLink,
    templateId,
    saveAsTemplateFormRolesIds,
    saveAsTemplate,
    documentIds,
    bundleId,
    templateIds,
    formProcessId,
    isLibraryTemplate,
  }: PreFormType,
  { formIds, formProcessIds }: EitherIdsType,
  dynamicForms?: boolean,
) => {
  const shouldSendFormRole = templateId || saveAsTemplate || isLibraryTemplate || !dynamicForms;
  const updatedEditors = dynamicForms
    ? []
    : [
        ...(editors?.agent?.map((agent) => ({
          ...agent,
          ...(agent.UserId ? { UserId: Number(agent.UserId) } : undefined),
        })) || []),
        ...(clientEditorRole
          ? []
          : editors?.client?.map((client) => ({
              ...client,
              ...(client.UserId ? { UserId: Number(client.UserId) } : undefined),
            })) || []),
      ];

  let updatedSignatories = signatories
    ? Object.values(signatories)
        .map((signatory) => {
          let roleName;

          if (dynamicForms) {
            roleName = signatory.customName
              ? signatory.Name
              : customFormRoles?.find((role) => role.Id === signatory.FormRole)?.Name;

            if (!roleName) {
              const name = signatory.Name?.replace(' (Me)', '');
              roleName = name ?? signatory.Email;
            }
          } else if (signatory.customName) {
            roleName = signatory.Name;
          }

          delete signatory.Name;
          delete signatory.customName;

          if (!shouldSendFormRole) {
            delete signatory.FormRole;
          }

          const { SignatureSequence } = signatory;

          return {
            ...signatory,

            ...(saveAsTemplate &&
              saveAsTemplateFormRolesIds?.[+SignatureSequence - 1] && {
                FormRole: saveAsTemplateFormRolesIds?.[+SignatureSequence - 1],
              }),

            ...(isSequentialSignature ? { SignatureSequence } : { SignatureSequence: 1 }),
            roleName,
          };
        })
        .filter((s) => s)
    : [];

  if (dynamicForms) {
    updatedSignatories = updatedSignatories.filter((s) => s?.roleName);
  }

  if (clientEditorRole) {
    const clientSignatory = updatedSignatories.find(
      (signatory) => signatory?.FormRole === clientEditorRole,
    );
    if (clientSignatory) {
      updatedEditors.push({
        FormRole: FORM_USER_ROLE.Client,
        UserId: clientSignatory.UserId,
        Email: clientSignatory.Email,
      });
    }
  }

  const allRecipients = copyRecipients?.filter((rec) => rec.id) || [];

  const updatedCopyRecipients = getRecipients(allRecipients, FORM_RECIPIENTS.SEND_COPY);

  const updatedAllUpdatesRecipients = getRecipients(
    allRecipients,
    FORM_RECIPIENTS.SEND_ALL_UPDATES,
  );

  return {
    FormId: formIds?.formId,
    VersionId: formIds?.versionId,
    TaskId: formIds?.taskId,
    formProcessPublicId: formProcessIds?.formProcessPublicId,
    formDocumentPublicId: formProcessIds?.formDocumentPublicId,
    TransactionId: transactionId,
    clientIds: clientIds,
    Editors: updatedEditors,
    IsSequentialSignature: isSequentialSignature,
    Signatories: updatedSignatories,
    CopyRecipients: updatedCopyRecipients,
    AllUpdatesRecipients: updatedAllUpdatesRecipients,
    taskId: formIds?.taskId,
    address,
    formName,
    documentLink,
    templateId,
    documentIds,
    bundleId,
    templateIds,
    formProcessId,
    ...(documentLink &&
      isArray(documentLink) &&
      documentLink.length > 1 && { documentLinks: documentLink }),
  };
};

export const validationToast = (message: string, config = {}) => {
  Message.config({
    top: 170,
    ...config,
  });
  Message.warning(message);
};

export const getMappedCommentUsers = (
  commentUsers?: CommentUsersType,
  users?: string[],
): CommentPermissionType[] =>
  Object.values(commentUsers || [])
    .filter((commentUser) => users?.includes(commentUser.email))
    .map((commentUser) => ({ userId: commentUser.userId, userEmail: commentUser.email }));

export const handleGetFormDocumentError =
  (params, history, setLoader, anonymous = false) =>
  (err, res) => {
    if (err) {
      if (err?.response?.data?.message === 'Token has expired') {
        history.replace(routes.workshopFormNotAvailable);
      }
    } else if (
      res?.data?.value &&
      ((anonymous && res.data.value?.[0]?.isDynamicForm) || res.data.value.isDynamicForm)
    ) {
      if (params.formProcessPublicId && params.formDocumentPublicId) {
        history.replace(link.toWorkshopDynamicFormDocument(params));
      } else if (params.token && params.type) {
        history.replace(link.toWorkshopDynamicFormAnonDocument(params));
      }
    }
    setTimeout(() => {
      setLoader(false);
    }, 0);
  };

export const getAuthFormDocument = (
  dispatch: Dispatch<any>,
  history,
  setLoader,
  formProcessPublicId,
  formDocumentPublicId,
) => {
  const params = {
    formProcessPublicId,
    formDocumentPublicId,
  };
  dispatch(
    getFormProcessDocumentEffect(params, handleGetFormDocumentError(params, history, setLoader)),
  );
};

export const getAnonFormDocument = (
  dispatch: Dispatch<any>,
  history,
  setLoader,
  token,
  type: AllowedAnonEnum,
) => {
  const params = {
    token,
    type,
  };
  dispatch(
    getAnonFormProcessDocumentEffect(
      params,
      handleGetFormDocumentError(params, history, setLoader, true),
    ),
  );
};

export const sortOptions = (details, key) => {
  if (!details?.length) return [];

  return details.sort((a, b) => a[key].localeCompare(b[key]));
};

export const sortDetails = (details, key) => {
  if (!details?.length) return [];

  return details.sort((a, b) => (a[key] > b[key] ? 1 : -1));
};

export const getDocumentOptions = (documents) => {
  return documents?.map((item) => ({ label: item.Name, value: item.Id }));
};
