import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { toggleFavoriteListingDetailEffect } from 'store/effects/listingDetail';
import { useSelector, useDispatch } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getCurrentContextSelector } from 'store/selectors/context';
import Icon from 'pages/Properties/Feed/Icons';

import { Wrapper as PendingWrapper } from 'components';

import styles from './styles.module.scss';

const Favorite = (props) => {
  const { className, propertyInfo, isClient: propsIsClient } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  const context = useSelector(getCurrentContextSelector);

  const isHint = propertyInfo?.AddedToFavoritesBy?.length && isAgent ? { 'data-rh': true } : {};

  const onClickHandler = (e) => {
    e.stopPropagation();
    if (isClient || propsIsClient) {
      const newActiveValue = !propertyInfo?.IsFavorite;
      const cfg = {
        isFavorite: newActiveValue,
        id: propertyInfo?.Id ? propertyInfo?.Id : propertyInfo?.id,
        contextKey: context.ContextKey,
      };
      setIsLoading(true);
      if (!isLoading) {
        dispatch(
          toggleFavoriteListingDetailEffect(cfg, { IsFavorite: newActiveValue }, () => {
            setIsLoading(false);
          }),
        );
      }
    }
  };

  return (
    <div
      {...isHint}
      data-addedtofavoritesby={JSON.stringify(propertyInfo?.AddedToFavoritesBy)}
      onClick={onClickHandler}
      className={classNames(
        styles.favWrapper,
        { [styles.buyer]: isClient || propsIsClient },
        className,
      )}
      testid="favorites"
    >
      <PendingWrapper
        className={styles.pendingWrapper}
        isPending={isLoading}
        loaderClassName={styles.loader}
      >
        <Icon
          testid="favorites_icon"
          variant={Icon.FAVORITE}
          active={!!propertyInfo?.IsFavorite}
          className={styles.icon}
        />
        {isAgent && !propsIsClient && (
          <span className={styles.count}>{propertyInfo?.AddedToFavoritesBy?.length || 0}</span>
        )}
      </PendingWrapper>
    </div>
  );
};

Favorite.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    IsFavorite: PropTypes.bool,
    AddedToFavoritesBy: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    ),
  }).isRequired,
  isClient: PropTypes.bool,
};

Favorite.defaultProps = {
  className: '',
  isClient: undefined,
};

export default Favorite;
