import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Sidebar, Skeleton, TabPane, Tabs } from 'components-antd';
import {
  QuoteSettingPane,
  QuoteDeleteQuestion,
  QuoteJumpLogicPane,
} from 'pages/FormBuilder/components';
import {
  getQuoteFormCurrentQuestionSelect,
  getQuoteFormMetaSelect,
} from 'store/selectors/formBuilder';
import { PENDING } from 'settings/constants/apiState';
import { FORM_QUESTION_TYPE } from 'app-constants';
import { ListBoxType } from 'types';

import styles from './styles.module.scss';

export const QuoteRightSidebar = () => {
  const { state, disabled } = useSelector(getQuoteFormMetaSelect);
  const question = useSelector(getQuoteFormCurrentQuestionSelect);

  const [hidden, setHidden] = useState<boolean>();

  const [hideLogic, setHideLogic] = useState(false);

  useEffect(() => {
    if (question) {
      const { Type, Meta } = question;
      if (Type) {
        setHidden(false);
      }
      if (
        (Type === FORM_QUESTION_TYPE.ListBox && !(Meta as ListBoxType)?.MultiSelect) ||
        Type === FORM_QUESTION_TYPE.YesNo
      ) {
        setHideLogic(false);
      } else if ((Meta as ListBoxType)?.MultiSelect) {
        setHideLogic(true);
      } else {
        setHideLogic(true);
      }
    } else {
      setHidden(true);
    }
  }, [question]);

  return (
    <Sidebar width={300} className={styles.quoteRightSidebar}>
      <Skeleton showSkeleton={state === PENDING} rowCount={10}>
        {question ? (
          <div className={styles.sidebarContainer} data-testid="quote-right-sidebar">
            {!hidden && (
              <Tabs defaultActiveKey="1">
                <TabPane tab="Settings" key="1" data-testid="quote-setting-pane">
                  <QuoteSettingPane />
                </TabPane>
                {!hideLogic && (
                  <TabPane tab="Logic" key="2" data-testid="quote-logic-pane">
                    <QuoteJumpLogicPane />
                  </TabPane>
                )}
              </Tabs>
            )}
          </div>
        ) : (
          <div className={styles.noQuestionSelected}>Select a question to modify its settings</div>
        )}
        {!hidden && !disabled && <QuoteDeleteQuestion />}
      </Skeleton>
    </Sidebar>
  );
};
