import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { DateRangePicker } from 'components/Form/DateRangePicker';
import moment, { Moment } from 'moment';
import { FilterDrawer, FilterSection } from 'pages/Workshop/common';
import { GroupCheckBoxesSection } from 'pages/Workshop/common/GroupCheckBoxesSection';
import { MultiSelectSection } from 'pages/Workshop/common/MultiSelectSection';
import { LocationService } from 'services';
import { TransactionActivityLogsActionsFilterTypes } from 'utils/activityLogsActionsTypes';
import { Checkbox } from 'components';

let defaultFilters = {};

export const FilterSections = ({
  onDone,
  onReset,
  taskCategories,
  relatedEntities,
  appliedFilters,
}) => {
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const usersData = relatedEntities?.filter((entity) => entity.Type === 'Contact');
  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
  const [from, setFrom] = useState<Moment>();
  const [to, setTo] = useState<Moment>();
  const [userIds, setUserIds] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [activityTypes, setActivityTypes] = useState<string[]>([]);
  const [showOverdueTask, setShowOverdueTask] = useState<boolean>(false);
  const [previousFilters, setPreviousFilters] = useState({});

  const getFilters = () => ({
    userIds,
    tags,
    activityTypes,
    from,
    to,
    showOverdueTask,
  });

  const setFilters = (filters: any) => {
    const { userIds, tags, activityTypes, from, to, showOverdueTask } = filters as any;
    if (userIds) setUserIds(userIds);
    if (tags) setTags(tags);
    if (activityTypes) setActivityTypes(activityTypes);
    if (from) setFrom(moment(from));
    if (to) setTo(moment(to));
    if (showOverdueTask) setShowOverdueTask(showOverdueTask);
  };
  useEffect(() => {
    defaultFilters = getFilters();
    setPreviousFilters(appliedFilters);
    setFilters(appliedFilters);
  }, []);

  useEffect(() => {
    setIsFiltersApplied(
      Boolean(
        userIds.length || tags.length || activityTypes.length || from || to || showOverdueTask,
      ),
    );
  }, [userIds, tags, activityTypes, from, to, showOverdueTask]);

  const submitHandler = () => {
    const formDate = from ? moment(from).format('YYYY-MM-DD') : undefined;
    const toDate = to ? moment(to).format('YYYY-MM-DD') : undefined;
    onDone({
      userIds,
      tags,
      activityTypes,
      from: formDate,
      to: toDate,
      showOverdueTask,
    });
    setPreviousFilters(getFilters());
  };

  const resetHandler = () => {
    onReset();
    setPreviousFilters(defaultFilters);
  };

  const discardHandler = () => {
    if (JSON.stringify(previousFilters) === JSON.stringify(getFilters())) {
      return;
    }
    setFilters(previousFilters);
  };

  return (
    <FilterDrawer
      className={styles.filterBtn}
      isFiltersApplied={isFiltersApplied}
      onDone={submitHandler}
      onReset={resetHandler}
      onDiscard={discardHandler}
    >
      <div className={styles.filterSections}>
        <FilterSection title="Users" count={userIds?.length}>
          <MultiSelectSection
            placeholder={'Search by Clients...'}
            entityList={usersData?.map(({ ContactUserId, FirstName, LastName }) => ({
              label: `${FirstName} ${LastName}`,
              value: ContactUserId,
            }))}
            entity={userIds}
            setEntity={setUserIds}
            avatarPrefix
          />
        </FilterSection>
        <FilterSection title="Tags" count={tags?.length}>
          <GroupCheckBoxesSection
            entityList={taskCategories?.map(({ Id, Name }) => ({ name: Name, value: Id }))}
            entityValues={tags}
            setEntity={setTags}
          />
        </FilterSection>

        <FilterSection title="Activity Types" count={activityTypes?.length}>
          <MultiSelectSection
            placeholder={'Search by Activity Type...'}
            entityList={TransactionActivityLogsActionsFilterTypes.map(({ value, label }) => ({
              label: label,
              value,
            }))}
            entity={activityTypes}
            setEntity={setActivityTypes}
          />
        </FilterSection>
        <FilterSection title="Date Range">
          <div className={styles.datePickerContainer}>
            <DateRangePicker
              span={13}
              title={''}
              onDatesChange={(from, to) => {
                setFrom(from);
                setTo(to);
              }}
              fromDateValue={from}
              toDateValue={to}
              dateRangePickerWrapperClass={styles.dateRangePicker}
              futureDisabled={false}
            />
          </div>
        </FilterSection>
        <FilterSection title="Overdue">
          <div className={styles.checboxContainer}>
            <Checkbox
              direction="right"
              label="Show Overdue Tasks"
              checked={showOverdueTask}
              onChange={(event) => setShowOverdueTask(event?.target?.checked)}
            />
          </div>
        </FilterSection>
      </div>
    </FilterDrawer>
  );
};
