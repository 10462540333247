import React from 'react';
import PropTypes from 'prop-types';
import Carousel from '../../../Carousel';

const InfoSliderComponent = (props) => {
  const { slides } = props;
  return <Carousel slides={slides} />;
};

InfoSliderComponent.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
};
InfoSliderComponent.defaultProps = {};

export default InfoSliderComponent;
