import React from 'react';
import PropTypes from 'prop-types';

const Compare = ({ className, onClick }) => (
  <div testid="compare_icon" onClick={onClick} className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 12L11.5 14.5L19.5 6.5"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 4.5H7C5.61929 4.5 4.5 5.61929 4.5 7V17C4.5 18.3807 5.61929 19.5 7 19.5H17C18.3807 19.5 19.5 18.3807 19.5 17V12"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

Compare.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Compare.defaultProps = {
  className: '',
  onClick: () => {},
};

export default Compare;
