import { memo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import { get } from 'lodash-es';
import moment from 'moment';

import { updateFormListSearchEffect } from 'store/effects/formBuilder';
import { Button, Row, Col, Form, Input, Select, Option, RangePicker } from 'components-antd';
import { getFormListSearch, getFormMetaSelect } from 'store/selectors/formBuilder';
import { CardSelectBox } from 'components';
import { CheckBox } from 'components/Icons';
import { FormStatus, FormType } from './config';
import { FormGeoLocations, LocationProps } from '../FormGeoLocations/FormGeoLocations';
import { FormLocation } from 'types';

import styles from './styles.module.scss';

export const SearchFilter = memo(() => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [status, setStatus] = useState();
  const [locations, setLocations] = useState<LocationProps | undefined>(undefined);
  const { tags, categories, state, users } = useSelector(getFormMetaSelect);
  const { locations: formLocations, errors: formErrors } = useSelector(getFormListSearch);

  const reset = () => {
    setStatus(undefined);
    form.resetFields();
    setLocations(undefined);
    dispatch(
      updateFormListSearchEffect({
        ...form.getFieldsValue(),
        status: undefined,
        locations: undefined,
      }),
    );
  };

  const submitForm = (values) => {
    const params = {
      ...values,
      status,
      createdDate: values.createdDate
        ? {
            startDate: get(values, 'createdDate.0')
              ? moment(get(values, 'createdDate.0')).startOf('day').toDate()
              : undefined,
            endDate: get(values, 'createdDate.1')
              ? moment(get(values, 'createdDate.1')).endOf('day').toDate()
              : undefined,
          }
        : undefined,
      updatedDate: values.updatedDate
        ? {
            startDate: get(values, 'updatedDate.0')
              ? moment(get(values, 'updatedDate.0')).startOf('day').toDate()
              : undefined,
            endDate: get(values, 'updatedDate.1')
              ? moment(get(values, 'updatedDate.1')).endOf('day').toDate()
              : undefined,
          }
        : undefined,
    };

    if (locations?.areasOfOperation?.length) {
      params.locations = {
        ...locations,
        miles: Number(locations.miles) || 0,
      };
    } else params.locations = undefined;

    dispatch(updateFormListSearchEffect({ ...params }));
  };

  return (
    <div className={styles.searchFilterBody}>
      {state === 'pending' ? (
        <Skeleton />
      ) : (
        <>
          <Row align="middle" justify="space-between" className={styles.header}>
            <Col>
              <span className={styles.filterFormHeading}>Filter Forms</span>
            </Col>
            <Col>
              <span
                className={classNames(styles.filterFormReset, 'show-cursor', 'prevent-user-select')}
                onClick={(e) => reset()}
              >
                Reset
              </span>
            </Col>
          </Row>
          <div className={styles.formFilter}>
            <CardSelectBox items={FormStatus} value={status} onSelect={setStatus} />
            <Form layout="vertical" form={form} onFinish={submitForm}>
              <Form.Item label="Form Type" name="type">
                <Select
                  large
                  showArrow={true}
                  mode="multiple"
                  getPopupContainer={(triggerNode) => triggerNode}
                  menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                  placeholder="Select Form Type"
                >
                  {FormType.map((el) => (
                    <Option value={el.value} key={`form-type-${el.value}`}>
                      {el.key}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Filter By Category" name="categories">
                <Select
                  large
                  showArrow={true}
                  placeholder="Select Category"
                  mode="multiple"
                  getPopupContainer={(triggerNode) => triggerNode}
                  menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {categories.map((el) => (
                    <Option value={el.CategoryId} key={`category-${el.CategoryId}`}>
                      {el.Title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Filter By People" name="users">
                <Select
                  large
                  showArrow={true}
                  placeholder="Select People"
                  mode="multiple"
                  getPopupContainer={(triggerNode) => triggerNode}
                  menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {(users || []).map((el) => (
                    <Option value={el.UserId} key={`user-${el.UserId}`}>
                      {el.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Filter By Tag" name="tags">
                <Select
                  large
                  showArrow={true}
                  mode="multiple"
                  getPopupContainer={(triggerNode) => triggerNode}
                  menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                  placeholder="Select Tag"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {tags.map((el, i) => (
                    <Option value={el.TagId} key={`tags-${el.TagId}`}>
                      {el.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Date Created" name="createdDate">
                <RangePicker />
              </Form.Item>
              <Form.Item label="Date Last Edited" name="updatedDate">
                <RangePicker />
              </Form.Item>
              <Form.Item label="Created By" name="createdBy">
                <Input placeholder="Enter Name" maxLength={100} />
              </Form.Item>
              <Form.Item label="Locations" name="locations">
                <FormGeoLocations
                  showMiles={true}
                  multiple={false}
                  breakLine={true}
                  onChange={(location: FormLocation[]) =>
                    setLocations(location?.length ? location[0] : undefined)
                  }
                  locations={formLocations ? [formLocations] : undefined}
                  errors={(formErrors || []).filter((el) => el.type === 'Locations')}
                />
              </Form.Item>
              <Form.Item className={styles.formButtonRow}>
                <Button variant="secondary" className={styles.applyFiltersButton} htmlType="submit">
                  Apply Filters
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </div>
  );
});
