import { getDateTime } from 'helpers/formatters';

import styles from './styles.module.scss';

export const Notes = (props) => {
  const { BookingPageNotes, CreatedDate } = props;
  return (
    <div>
      <div className={styles.noteDetail}>
        <p className={styles.note}>{BookingPageNotes}</p>
        <p className={styles.noteDateTime}>{getDateTime(CreatedDate, 'at', 'MMM D')}</p>
      </div>
    </div>
  );
};
