import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Area, AreaConfig } from '@ant-design/plots';

import { Checkbox, Col, Popover, Row } from 'components-antd';
import { ArrowDown, ArrowUp } from 'components/Icons';
import { Card } from '../../Card';
import {
  getLoadingGraphsSelector,
  getFilteredGraphsSelector,
} from 'store/selectors/agentDashboard';
import { Wrapper as PendingWrapper } from 'components';
import { AreaConfigData, clientStats } from '../helper';

import styles from './styles.module.scss';

export const ClientWidget = ({ teamStats }) => {
  const filterValues = ['Active', 'Retention'];
  const [filter, setFilter] = useState<string>('Active');
  const [open, setOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [data, setData] = useState<AreaConfigData[]>([] as AreaConfigData[]);
  const [currentStats, setCurrentStats] = useState<clientStats[]>();

  const { isPending } = useSelector(getLoadingGraphsSelector);
  const stats = useSelector(getFilteredGraphsSelector('client', teamStats));

  useEffect(() => {
    !isPending && setCurrentStats(stats);
  }, [isPending, teamStats]);

  useEffect(() => {
    if (currentStats) {
      const stats =
        currentStats.map(({ date, Active, Retention }) => ({
          day: moment(date.slice(0, 10)).format('DD MMM YYYY'),
          value: filter === 'Active' ? Active : Retention,
        })) || [];

      setData(stats);
      const lastStatPoint = currentStats[currentStats.length - 1];
      setTotalAmount(lastStatPoint?.[filter] || 0);
    }
  }, [currentStats]);

  const handleChange = (checkedValues) => {
    setOpen(false);
    const currentValue = checkedValues.target.value;
    setFilter(currentValue);
    const stats =
      currentStats?.map(({ date, Active, Retention }) => ({
        day: moment(date.slice(0, 10)).format('DD MMM YYYY'),
        value: currentValue === 'Active' ? Active : Retention,
      })) || [];

    setData(stats);
    const lastStatPoint = currentStats?.[currentStats.length - 1];
    setTotalAmount(lastStatPoint?.[currentValue] || 0);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const popoverContent = () => (
    <ul className={styles.filterOptions}>
      {filterValues.map((value, index) => {
        return (
          <li key={index}>
            <Checkbox
              className={styles.sortCheckbox}
              onChange={handleChange}
              checked={value == filter}
              value={value}
            >
              <div className={styles.checkboxHeading}>{value}</div>
            </Checkbox>
          </li>
        );
      })}
    </ul>
  );

  const config = {
    data,
    xField: 'day',
    yField: 'value',
    color: '#FB913A',
    responsive: true,
    autoFit: true,
    height: 80,
    padding: [20, 0],
    smooth: true,
    areaStyle: {
      fill: 'l(270) 0:#FB913A00 1:#FB913A',
    },
    xAxis: false,
    yAxis: false,
    tooltip: false,
  } as AreaConfig;

  return (
    <Card
      cardTitle={'Clients'}
      showHeader={false}
      cardWrapperClassName={styles.cardWrapper}
      cardHeaderClassName={styles.cardHeader}
      cardBodyClassName={styles.cardBody}
    >
      <PendingWrapper
        className={styles.pendingWrapper}
        spinnerClassname={styles.centerSpinner}
        isPending={isPending}
        loaderClassName={styles.loader}
      >
        <header className={styles.widgetHeader}>
          <div>
            <h3>{'Clients'}</h3>
          </div>
          <div>
            <Popover
              content={popoverContent}
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
              overlayClassName={styles.filterDropdown}
              placement="bottomRight"
              className={styles.dropdown}
            >
              <span className={styles.switchButton} onClick={(e) => e.preventDefault()}>
                {filter}
              </span>
              {open ? (
                <ArrowUp className={styles.icon} />
              ) : (
                <ArrowDown className={styles.icon} color={'#747475'} />
              )}
            </Popover>
          </div>
        </header>

        <Row className={styles.countContainer}>
          <span className={styles.totalAmount}>{totalAmount}</span>
        </Row>

        <Row align="middle">
          <Col xs={24} sm={24}>
            <Area {...config}></Area>
          </Col>
        </Row>
      </PendingWrapper>
    </Card>
  );
};
