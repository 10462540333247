import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Close as CloseIcon } from 'components/Icons';
import { Icons } from '../Icons/Icons';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getTasksForCancelledTransactionFrSelector } from 'store/selectors/transaction';
export const Controls = ({ stage, setStage, onClose }) => {
  const { tasks } = useSelector(getTasksForCancelledTransactionFrSelector);

  const onPrev = () => {
    if (isPrevDisabled()) return;
    switch (stage) {
      case transactionPreFormQuestionsIds.files:
        tasks.length
          ? setStage(transactionPreFormQuestionsIds.triggeredTasks)
          : setStage(transactionPreFormQuestionsIds.additionalComments);
        break;
      case transactionPreFormQuestionsIds.additionalComments:
        setStage(transactionPreFormQuestionsIds.reasonForCancellation);
        break;
      case transactionPreFormQuestionsIds.triggeredTasks:
        setStage(transactionPreFormQuestionsIds.additionalComments);
        break;
      case transactionPreFormQuestionsIds.moveToActiveListing:
        setStage(transactionPreFormQuestionsIds.files);
        break;
    }
  };

  const onNext = () => {
    if (isNextDisabled()) return;

    switch (stage) {
      case transactionPreFormQuestionsIds.reasonForCancellation:
        setStage(transactionPreFormQuestionsIds.additionalComments);
        break;
      case transactionPreFormQuestionsIds.additionalComments:
        setStage(transactionPreFormQuestionsIds.triggeredTasks);
        break;
      case transactionPreFormQuestionsIds.triggeredTasks:
        setStage(transactionPreFormQuestionsIds.files);
        break;
    }
  };

  const isPrevDisabled = () => {
    return stage === transactionPreFormQuestionsIds.reasonForCancellation;
  };

  const isNextDisabled = () => {
    if (stage === transactionPreFormQuestionsIds.files) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.controls}>
      <Icons
        variant="arrowLeft"
        className={classnames(styles.iconBtn, styles.arrowLeft, {
          [styles.disabled]: isPrevDisabled(),
        })}
        onClick={onPrev}
      />
      {/* <Icons
            variant="arrowRight"
            className={classnames(styles.iconBtn, styles.arrowRight, {
              [styles.disabled]: isNextDisabled(),
            })}
            onClick={onNext}
          /> */}
      <CloseIcon onClick={onClose} className={styles.iconBtn} />
    </div>
  );
};

Controls.prototype = {
  onClose: PropTypes.func,
  stage: PropTypes.number,
  setStage: PropTypes.func,
};

Controls.defaultProps = {
  onClose: () => {},
  stage: 0,
  setStage: () => {},
};
