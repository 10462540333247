export const initialUploadErrorState = {
  templateNameError: '',
  categoriesError: '',
};

export const initialTemplateFieldState = {
  templateName: '',
  categories: [],
  saveAsTemplate: false,
  publishToTeam: false,
};

export const prepareSignFormDetails = (formRoles) => {
  const modifiedFormRoles = formRoles.map((item, index) => ({
    RoleId: item.roleId,
    Id: item.roleId,
    UserType: 'Agent',
    Name: '',
  }));

  return {
    formRoles: modifiedFormRoles,
  };
};
