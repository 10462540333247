import React, { forwardRef, FC } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SimpleModal, Button } from 'components';

import styles from './styles.module.scss';

const ConfirmModal: FC<any> = forwardRef((props, ref) => {
  const {
    className,
    isOpen,
    onClose,
    title,
    icon,
    cancelButtonTitle,
    submitButtonTitle,
    onSubmit,
  } = props;
  const { isPending, submitVariant, customIcon } = props;

  const getIcon = () => (
    <div className={styles.defaultIcon}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.33203 9.33398H26.6654"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.332 14.666V22.666"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.668 14.666V22.666"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66797 9.33398L8.0013 25.334C8.0013 26.0412 8.28225 26.7195 8.78235 27.2196C9.28245 27.7197 9.96072 28.0007 10.668 28.0007H21.3346C22.0419 28.0007 22.7202 27.7197 23.2203 27.2196C23.7204 26.7195 24.0013 26.0412 24.0013 25.334L25.3346 9.33398"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 9.33333V5.33333C12 4.97971 12.1405 4.64057 12.3905 4.39052C12.6406 4.14048 12.9797 4 13.3333 4H18.6667C19.0203 4 19.3594 4.14048 19.6095 4.39052C19.8595 4.64057 20 4.97971 20 5.33333V9.33333"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );

  return (
    <div ref={ref as any}>
      <SimpleModal
        isOpen={isOpen}
        onClose={onClose}
        className={classNames(styles.confirmModal, className)}
        contentClassName={styles.content}
        testid="confirm_modal"
      >
        {icon && <div>{customIcon || getIcon()}</div>}
        <div testid="modal_title" className={styles.title}>
          {title}
        </div>
        <div className={styles.actions}>
          <Button
            className={classNames(styles.btn, styles.cancel)}
            title={cancelButtonTitle}
            onClick={onClose}
            testid="cancel"
          />
          <Button
            className={classNames(styles.btn, styles.submit, styles[submitVariant])}
            title={submitButtonTitle}
            onClick={onSubmit}
            loaderClassName={styles.submitLoader}
            isPending={isPending}
            testid="confirm"
          />
        </div>
      </SimpleModal>
    </div>
  );
});

ConfirmModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  icon: PropTypes.bool,
  customIcon: PropTypes.node,
  cancelButtonTitle: PropTypes.string,
  submitButtonTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  isPending: PropTypes.bool,
  submitVariant: PropTypes.string,
};

ConfirmModal.defaultProps = {
  className: '',
  isOpen: false,
  onClose: () => {},
  icon: false,
  cancelButtonTitle: 'No',
  submitButtonTitle: 'Yes',
  onSubmit: () => {},
  isPending: false,
  submitVariant: 'error',
  customIcon: undefined,
  title: '',
};

export default ConfirmModal;
