import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Modal, Wrapper as PendingWrapper, ConfirmationDialog, Button } from 'components';
import { link } from 'settings/navigation/link';
import { routes } from 'settings/navigation/routes';
import { Edit, Write } from 'components/Icons';
import Header from 'pages/Dashboard/components/Header';
import Grid from 'pages/Dashboard/components/Grid';
import Icons from 'pages/Dashboard/components/Icons';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { getPartnersSelector } from 'store/selectors/adminPanel';
import {
  activatePartnerEffect,
  deactivatePartnerEffect,
  getPartnersEffect,
  sendInviteEffect,
} from 'store/effects/adminPanel';

import { ViewPartner } from './Partner';
import { gridColumns } from './config';
import Filters from './FilterForm';
import { showSuccessMessage, showErrorMessage } from 'helpers';
import UploadPartners from './UploadPartners';

import styles from './styles.module.scss';

const Partners = () => {
  const dispatch = useDispatch();
  const partners = useSelector(getPartnersSelector);
  const [sendingInvite, setSendingInvite] = useState({});

  useEffect(() => {
    if (partners.state === IDLE) {
      dispatch(getPartnersEffect());
    }
  }, []); // eslint-disable-line

  const isPending = partners.state === PENDING;
  const [itemToChangeStatus, setItemToChangeStatus] = useState(null);
  const [previewPartnerId, setPreviewPartnerId] = useState(null);

  const onRowClick = useCallback((e, { Id }) => setPreviewPartnerId(Id), [setPreviewPartnerId]);
  const onPreviewModalClose = useCallback(() => setPreviewPartnerId(null), [setPreviewPartnerId]);

  const onConfirmChangeItemStatus = useCallback(() => {
    if (!itemToChangeStatus) return;

    const effect = itemToChangeStatus.IsActive ? deactivatePartnerEffect : activatePartnerEffect;

    dispatch(
      effect({ id: itemToChangeStatus.Id }, {}, (err) => {
        if (!err) {
          setItemToChangeStatus(null);
        }
      }),
    );
  }, [dispatch, itemToChangeStatus, setItemToChangeStatus]);

  const onCloseChangeStatusItemModal = useCallback(
    () => setItemToChangeStatus(null),
    [setItemToChangeStatus],
  );

  const renderActions = useCallback(
    (Id, data) => {
      return (
        <div className={styles.flexInner}>
          <Link
            className={styles.editButton}
            to={link.toDashboardServiceDirectoryPartnerEdit(Id)}
            testid="edit_link"
          >
            <Edit className={styles.buttonIcon} />
          </Link>
          <div
            className={data.IsActive ? styles.deactivateButton : styles.activateButton}
            onClick={(e) => {
              e.stopPropagation();
              setItemToChangeStatus(data);
            }}
            testid="activate_button"
          >
            <Icons
              className={styles.buttonIcon}
              variant={data.IsActive ? Icons.DEACTIVATE : Icons.ACTIVATE}
            />
          </div>
          <Button
            testid="login"
            disabled={data.ThirdParty.User?.CompletedProfile}
            onClick={(e) => {
              e.stopPropagation();
              sendPartnerInvite(Id);
            }}
            className={styles.button}
            title="Send Invite"
            isPending={sendingInvite[Id] || false}
            loaderClassName={styles.loader}
          />
        </div>
      );
    },
    [sendingInvite],
  );

  const sendPartnerInvite = useCallback((id) => {
    const effect = sendInviteEffect;
    setSendingInvite((oldState) => ({ ...oldState, [id]: true }));

    dispatch(
      effect({ id: id }, {}, (err) => {
        setSendingInvite((oldState) => ({ ...oldState, [id]: false }));
        if (!err) {
          showSuccessMessage(
            'The email containing the link to complete profile has been sent successfully.',
          );
        } else {
          showErrorMessage(err);
        }
      }),
    );
  }, []);
  const changeItemStatusContent = useMemo(
    () =>
      itemToChangeStatus ? (
        <div>
          Are you sure that you want to&nbsp;
          <b>{itemToChangeStatus.IsActive ? 'deactivate' : 'activate'}</b>
          &nbsp;partner&nbsp;
          <b>{itemToChangeStatus.BusinessName}</b>?
        </div>
      ) : null,
    [itemToChangeStatus],
  );

  return (
    <PendingWrapper testid="partners_view" isPending={isPending} className={styles.pageWrapper}>
      <Header
        title="Partners"
        buttonText="New Partner"
        buttonIcon={<Write />}
        buttonLink={routes.dashboardServiceDirectoryPartnerCreate}
      >
        <UploadPartners />
      </Header>
      <Filters />
      <Grid
        items={partners.data || []}
        columns={gridColumns}
        keyProp="Id"
        renderActions={renderActions}
        onRowClick={onRowClick}
      />
      <ConfirmationDialog
        onReject={onCloseChangeStatusItemModal}
        onConfirm={onConfirmChangeItemStatus}
        isOpen={!!itemToChangeStatus}
      >
        {changeItemStatusContent}
      </ConfirmationDialog>
      <Modal
        isOpen={!!previewPartnerId}
        onClose={onPreviewModalClose}
        innerHolderClassName={styles.modalInnerHolder}
        contentClassName={styles.modalContent}
      >
        {!!previewPartnerId && <ViewPartner id={previewPartnerId} />}
      </Modal>
    </PendingWrapper>
  );
};

export default Partners;
