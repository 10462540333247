import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getPartnerSettingsViewData } from 'store/selectors/user';
import { FormattedPhone, Avatar } from 'components';
import { TitleWithButtons } from 'components/SettingsGroup';
import { Edit } from 'components/Icons';
import { getHrefLink, getStringsSorter } from 'helpers';
import { getLogoPlaceholder } from '../helpers';
import styles from '../styles.module.scss';

const SettingsPartnerViewMode = ({ changeEditMode, serviceTags }) => {
  const partnerData = useSelector(getPartnerSettingsViewData);

  let services = '';
  if (serviceTags) {
    for (let i = 0; i < serviceTags.length; i++) {
      services += serviceTags[i].Name;
      if (i !== serviceTags.length - 1) {
        services += ', ';
      }
    }
  } else services = '-';

  const citiesWithStates = useMemo(() => {
    if (!partnerData?.AreasOfOperation) return;
    const citiesOnly = partnerData?.AreasOfOperation?.filter(
      ({ Type: type }) => type === 'City' || type === 'Zipcode',
    );
    citiesOnly?.sort(getStringsSorter(({ City }) => City));
    return citiesOnly;
  }, [partnerData?.AreasOfOperation]);

  const neighborhoods = useMemo(() => {
    if (!partnerData?.AreasOfOperation) return;
    const neighborhoodsOnly = partnerData?.AreasOfOperation?.filter(
      ({ Type: type }) => type !== 'City' && type !== 'Zipcode',
    );
    neighborhoodsOnly?.sort(getStringsSorter(({ PlaceName }) => PlaceName));
    return neighborhoodsOnly;
  }, [partnerData?.AreasOfOperation]);

  return (
    <div testid="partner_view">
      <TitleWithButtons className={styles.title} title="Organization Settings">
        <button testid="edit_button" onClick={changeEditMode} className={styles.editBtn}>
          <Edit />
        </button>
      </TitleWithButtons>
      <div className={styles.profileWrapper}>
        <div className={styles.leftBlock}>
          <Avatar
            src={partnerData?.LogoUrl}
            placeholder={getLogoPlaceholder(partnerData?.BusinessName)}
            className={styles.avatar}
          />
        </div>
        <div className={styles.rightBlock}>
          <h3 className={styles.subTitle}>Directory Profile</h3>
          <div className={styles.inputBlock}>
            <span className={styles.label}>Company Name</span>
            <span testid="business_name" className={styles.infoBlockForOneLine}>
              {partnerData?.BusinessName}
            </span>
          </div>
          {!!partnerData?.AdditionalBusinessName && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Alias</span>
              <span testid="additional_name" className={styles.infoBlockForOneLine}>
                {partnerData?.AdditionalBusinessName}
              </span>
            </div>
          )}
          {!!partnerData?.Email && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Email</span>
              <span testid="email" className={styles.infoBlockForOneLine}>
                {partnerData?.Email}
              </span>
            </div>
          )}
          {!!partnerData?.PhoneNumber && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Phone</span>
              <span className={styles.infoBlockForOneLine}>
                <FormattedPhone testid="phone">{partnerData?.PhoneNumber}</FormattedPhone>
              </span>
            </div>
          )}
          {!!partnerData?.AddressLine && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Office Address</span>
              <span testid="office_address" className={styles.infoBlockForOneLine}>
                {partnerData?.AddressLine}
              </span>
            </div>
          )}
          {!!partnerData?.SuiteUnit && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Unit / Suite</span>
              <span testid="unit_suite" className={styles.infoBlockForOneLine}>
                {partnerData?.SuiteUnit}
              </span>
            </div>
          )}
          {!!partnerData?.Description && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Description</span>
              <span testid="description" className={styles.infoBlockForMultipleLines}>
                {partnerData?.Description}
              </span>
            </div>
          )}
          {!!partnerData?.Website && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Website</span>
              <span className={styles.infoBlockForOneLine}>
                <a testid="website" href={getHrefLink(partnerData?.Website)}>
                  {partnerData?.Website}
                </a>
              </span>
            </div>
          )}
          {!!partnerData?.Description && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Services</span>
              <span testid="description" className={styles.infoBlockForMultipleLines}>
                {services}
              </span>
            </div>
          )}
          {(citiesWithStates || neighborhoods) && (
            <div className={styles.areasOfOperationWrapper}>
              <h3 className={styles.sectionTitle}>Areas of Operation</h3>
              {!!citiesWithStates && (
                <div className={styles.inputBlock}>
                  <span className={styles.label}>Cities</span>
                  <span testid="view_cities_name" className={styles.infoBlockForOneLine}>
                    {citiesWithStates
                      ?.map((item) => {
                        if (item?.Type === 'Zipcode') {
                          return item?.PlaceName;
                        } else {
                          return `${item?.City}${item.State ? `, ${item.State}` : ''}`;
                        }
                      })
                      ?.join(', ')}
                  </span>
                </div>
              )}
              {!!neighborhoods && (
                <div className={styles.inputBlock}>
                  <span className={styles.label}>Neighborhoods</span>
                  <span
                    testid="view_brokerage_office_address"
                    className={styles.infoBlockForOneLine}
                  >
                    {neighborhoods?.map((item) => item.PlaceName)?.join(', ')}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SettingsPartnerViewMode.propTypes = {
  changeEditMode: PropTypes.func.isRequired,
  serviceTags: PropTypes.array,
};

export default SettingsPartnerViewMode;
