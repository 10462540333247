import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getPreApprovalDocumentsEffect, deletePreApprovalDocumentEffect } from 'store/effects';
import { getUserId } from 'store/selectors/user';
import { getPreApprovalDocumentsSelector } from 'store/selectors/mySearches';
import { Spinner } from 'components';
import { PENDING } from 'settings/constants/apiState';
import { Document } from 'pages/Properties/MySearches/components/Document';
import { showSuccessMessage } from 'helpers/success';

import styles from './styles.module.scss';

export const PreApprovalDocuments = ({ clientId }) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const { state: isLoading, data: documents } = useSelector(getPreApprovalDocumentsSelector);

  const formatted: any = [];
  documents?.forEach((document) => {
    const currentDate = moment(new Date()).format('MMMM D, YYYY');
    let date = moment(new Date(document.UploadDate)).format('MMMM D, YYYY');
    if (date === currentDate) date = 'Today';
    const index = formatted.findIndex((f) => f[date] !== undefined);

    if (index === -1) {
      formatted.push({ [date]: [document] });
    } else {
      formatted[index][date].push(document);
    }
  });

  const deleteDocument = (id) => {
    dispatch(
      deletePreApprovalDocumentEffect(
        {
          id,
          clientId: clientId,
          agentId: userId,
        },
        {},
        (err) => {
          if (!err) {
            showSuccessMessage('Pre-approval Document Deleted Successfully');
          }
        },
      ),
    );
  };

  const fetchDocuments = useCallback(() => {
    dispatch(
      getPreApprovalDocumentsEffect({
        clientId: clientId,
        agentId: userId,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <>
      {isLoading === PENDING ? (
        <Spinner loaderClassName={styles.loader} />
      ) : (
        <div className={styles.preApprovalDocuments}>
          {formatted?.map((data) =>
            Object.keys(data).map((date) => (
              <>
                <p className={styles.uploadDate}>{date}</p>
                {data[date].map((document) => (
                  <Document document={document} key={document.Id} onDelete={deleteDocument} />
                ))}
              </>
            )),
          )}
        </div>
      )}
    </>
  );
};

PreApprovalDocuments.propTypes = {
  clientId: PropTypes.number,
};

PreApprovalDocuments.defaultProps = {
  clientId: null,
};
