import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import {
  requestGetShowingAggregateByUserId,
  setShowingsArchiveAction,
  setShowingsArchiveIdAction,
  setShowingsFiltersAppliedAction,
  resetShowingAggregateAction,
  setShowingsFilterAction,
  setListingsFilterAction,
} from 'store/actions/showingAggregate';
import { appLogoutAction } from 'store/actions/app';
import { showingsFilters, listingsFilters } from 'settings/constants/showings';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  isArchive: false,
  isFiltersApplied: false,
  showingId: null,
  showingsFilter: showingsFilters.allShowings,
  listingsFilter: listingsFilters.allListings,
};

export default handleActions(
  {
    [requestGetShowingAggregateByUserId as any]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data.value', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [setShowingsArchiveAction as any]: (state, { payload }) => ({
      ...state,
      isArchive: !!payload,
    }),
    [setShowingsFiltersAppliedAction as any]: (state, { payload }) => ({
      ...state,
      isFiltersApplied: !!payload,
    }),
    [setShowingsArchiveIdAction as any]: (state, { payload }) => ({
      ...state,
      showingId: payload?.showingId || null,
    }),
    [setShowingsFilterAction as any]: (state, { payload }) => ({
      ...state,
      showingsFilter: payload as any,
    }),
    [setListingsFilterAction as any]: (state, { payload }) => ({
      ...state,
      listingsFilter: payload as any,
    }),
    [resetShowingAggregateAction as any]: () => ({ ...initialData }),
    [appLogoutAction as any]: () => initialData,
  },
  initialData,
);
