import { createAction } from 'redux-actions';

export const requestClientsSearchInstancesAction = createAction('REQUEST/CLIENTS_SEARCH_INSTANCES');
export const setClientsRenameSearchInstanceAction = createAction(
  'CLIENT_SEARCHES/RENAME_SEARCH_INSTANCE',
);
export const deleteClientsSearchInstanceAction = createAction(
  'CLIENT_SEARCHES/DELETE_SEARCH_INSTANCE',
);
export const setClientsSearchInstanceStatusAction = createAction(
  'CLIENT_SEARCHES/SET_SEARCH_INSTANCE_STATUS',
);
export const requestUpdateClientsSearchInstanceAction = createAction(
  'REQUEST/UPDATE_CLIENTS_SEARCH_INSTANCE',
);
export const openPreApprovalLettersDrawerAction = createAction(
  'CLIENT_SEARCHES/OPEN_PRE_APPROVAL_LETTERS_DRAWER',
);
