import { TableColumnsType } from '../components/Table';
import { FilterType } from '../components/Table/configs';

export type LoginLogsDataSource = {
  key: string;
  LoginName: string;
  AttemptStatus: string;
  IPAddress: string;
  ScreenHeight: string;
  ScreenWidth: string;
  UserAgentPlatform: string;
  UserAgentBrowser: string;
  UserAgentVersion: string;
  CreatedDate: string;
  Name: string;
};

export const loginLogsColumns: TableColumnsType<LoginLogsDataSource> = [
  {
    title: 'Name',
    dataIndex: 'Name',
    withSort: false,
  },
  {
    title: 'Login Name',
    dataIndex: 'LoginName',
    filterType: FilterType.Search,
    withSort: true,
  },
  {
    title: 'Team Name',
    dataIndex: 'TeamName',
    withSort: false,
  },
  {
    title: 'Attempt Status',
    dataIndex: 'AttemptStatus',
    withSort: true,
  },
  {
    title: 'Error Reason',
    dataIndex: 'ErrorReason',
    withSort: false,
  },
  {
    title: 'IP Address',
    dataIndex: 'IPAddress',
    withSort: true,
  },
  {
    title: 'Screen Height',
    dataIndex: 'ScreenHeight',
    withSort: true,
  },
  {
    title: 'Screen Width',
    dataIndex: 'ScreenWidth',
    withSort: true,
  },
  {
    title: 'User-Agent Platform',
    dataIndex: 'UserAgentPlatform',
    withSort: true,
  },
  {
    title: 'User-Agent Browser',
    dataIndex: 'UserAgentBrowser',
    withSort: true,
  },
  {
    title: 'User-Agent Version',
    dataIndex: 'UserAgentVersion',
    withSort: true,
  },
  {
    title: 'Login Date',
    dataIndex: 'CreatedDate',
    withSort: true,
  },
];

export const LOGIN_LOGS_LIMIT = 10;
