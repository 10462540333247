import React from 'react';

import {
  AdminFriendlySubscriptionPlan,
  GetAvailableSubscriptionPlansResponse,
} from 'api/subscription';
import classNames from 'classnames';
import { Radio } from 'components-antd';
import {
  mapPriceIntervalToSubtext,
  mapPriceIntervalToTitle,
  sortAndFilterPlans,
} from 'pages/Paywall/helpers';
import CheckRadioButton from '../check-radio/radio-button';
import styles from '../styles.module.scss';
import { SoloIcon } from 'pages/Paywall/icons/soloIcon';
import { TeamIcon } from 'pages/Paywall/icons/teamIcon';

interface PaywallModalStep1Props {
  availablePlan: GetAvailableSubscriptionPlansResponse | null;
  selectedPlan: string | null;
  onSelectedPlanChange: (plan: string) => void;
  setActivationCoupon?: (coupon: string) => void;
  activationCoupon?: string | null;
  isResubscribing: boolean;
}

export const PaywallModalStep1: React.FC<PaywallModalStep1Props> = (props) => {
  const legacyPaidPlanStep1 = (): React.ReactElement => {
    return (
      <>
        <h2 className={styles.stepHeader}>Welcome to Mosaik!</h2>
        <div>
          Your implementation is complete!
          <br />
          Please activate your plan.
        </div>
        <Radio.Group className={styles.radioGroup}>
          {sortAndFilterPlans(props.availablePlan!.Prices).map((plan) => (
            <CheckRadioButton
              key={plan.PriceKey}
              value={plan.PriceKey}
              className={classNames(
                'mosaik-checkbox',
                styles.checkboxRounded,
                styles.radioLabel,
                props.selectedPlan === plan.PriceKey ? styles.radioLabel_selected : '',
              )}
              checked={props.selectedPlan === plan.PriceKey}
              onChange={(v) => {
                props.onSelectedPlanChange(v);
              }}
            >
              <div className={styles.radioText}>
                <span className={styles.radioTextMain}>
                  {mapPriceIntervalToTitle(plan.Period.Interval)}
                </span>
                <span className={styles.radioTextSubtext}>
                  {mapPriceIntervalToSubtext(plan.Period.Interval)}
                </span>
              </div>
            </CheckRadioButton>
          ))}
        </Radio.Group>
      </>
    );
  };

  const standardPlanStep1 = (): React.ReactElement => {
    const planDetails = props.availablePlan?.Prices?.[0];
    const { isResubscribing } = props;
    const unitAmount = planDetails?.allTiers?.[2]?.unit_amount;

    return planDetails ? (
      isResubscribing ? (
        <>
          <h2 className={styles.stepHeader}>Welcome to Mosaik!</h2>
          <div className={styles.stepSubheading}>
            Your plan is currently inactive. Please re-subscribe to activate your plan.
          </div>
        </>
      ) : (
        <>
          <h2 className={styles.stepHeader}>Welcome to Mosaik!</h2>
          <div className={styles.stepSubheading}>
            Your plan is currently inactive. Which plan would <br />
            you like to move forward with?
          </div>
          <Radio.Group className={styles.radioGroup}>
            <CheckRadioButton
              value="solo"
              className={classNames(
                'mosaik-checkbox',
                styles.checkboxRounded,
                styles.radioLabel2,
                props.selectedPlan === 'solo' ? styles.radioLabel_selected : '',
              )}
              innerClassName={styles.standardIcon}
              checked={props.selectedPlan === 'solo'}
              onChange={() => {
                props.onSelectedPlanChange('solo');
              }}
              checkedSvg={<SoloIcon />}
              uncheckedSvg={<SoloIcon />}
            >
              <div className={styles.radioTextFlexColumn}>
                <span className={styles.radioTextMain}>Solo</span>
              </div>
            </CheckRadioButton>
            <CheckRadioButton
              value="team"
              checked={props.selectedPlan === 'team'}
              onChange={() => {
                props.onSelectedPlanChange('team');
              }}
              className={classNames(
                'mosaik-checkbox',
                styles.checkboxRounded,
                styles.radioLabel2,
                props.selectedPlan === 'team' ? styles.radioLabel_selected : '',
              )}
              innerClassName={styles.standardIcon}
              checkedSvg={<TeamIcon />}
              uncheckedSvg={<TeamIcon />}
            >
              <div className={styles.radioTextFlexColumn}>
                <span className={styles.radioTextMain}>Team</span>
              </div>
            </CheckRadioButton>
          </Radio.Group>
        </>
      )
    ) : (
      <></>
    );
  };

  return props.availablePlan?.Plan === AdminFriendlySubscriptionPlan.Paid ? (
    legacyPaidPlanStep1()
  ) : props.availablePlan?.Plan === AdminFriendlySubscriptionPlan.Standard ? (
    standardPlanStep1()
  ) : (
    <></>
  );
};
