import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Switch } from 'components-antd';
import { Select as CustomSelect } from 'components';
import { prefsIds } from 'settings/constants/preferences';

import styles from './styles.module.scss';

const OUTDOOR_SPACE_TYPES = [
  { id: 1, name: 'Backyard', value: prefsIds.outdoorYard },
  { id: 3, name: prefsIds.balcony, value: prefsIds.balcony },
  { id: 4, name: prefsIds.deck, value: prefsIds.deck },
  { id: 5, name: prefsIds.patio, value: prefsIds.patio },
  { id: 5, name: 'Roof Deck', value: prefsIds.roofDeck },
  { id: 5, name: prefsIds.terrace, value: prefsIds.terrace },
  { id: 5, name: prefsIds.pool, value: prefsIds.pool },
];

export const OutdoorSpaceTypes = ({ title, softCriteria, type, onClick }) => {
  const [answerCases, setAnswerCases] = useState(OUTDOOR_SPACE_TYPES);
  const [fenced, setFenced] = useState(false);
  const selected = softCriteria[prefsIds.outdoorPrefs]?.data || [];

  const onSwitchChange = (bool) => {
    const answers = [...answerCases];
    answers[0] = {
      ...answers[0],
      value: bool ? prefsIds.fencedYard : prefsIds.outdoorYard,
    };
    setAnswerCases(answers);
    setFenced(bool);

    const selectedValues = [...selected];
    const index = selectedValues.findIndex((option) => option.id === answers[0].id);
    selectedValues[index] = answers[0];
    onClick(title, softCriteria[title].value, type, selectedValues);
  };

  useEffect(() => {
    if (
      title === prefsIds.outdoorPrefs &&
      selected.find((obj) => obj.value === prefsIds.fencedYard)
    )
      onSwitchChange(true);
  }, []);

  return (
    <>
      {title === prefsIds.outdoorPrefs &&
        softCriteria[title] &&
        softCriteria[title]?.value !== 'N/A' && (
          <div className={styles.flooringOptions}>
            <h4>What type of outdoor space are you looking for? </h4>
            <CustomSelect
              name={prefsIds.outdoorPrefs}
              multiple
              className={{ wrapper: styles.wrapper, input: styles.selectInput }}
              variant={CustomSelect.DARK_HALF}
              options={answerCases}
              value={softCriteria[title].data}
              onSelect={(event, values) => onClick(title, softCriteria[title].value, type, values)}
              placeholder="Outdoor space"
              checkboxPostion="start"
              closeOnSelect={false}
            />
            <div className={styles.backyardToggle}>
              <p>Backyard must be fenced?</p>
              <Switch checked={fenced} onChange={onSwitchChange} />
            </div>
          </div>
        )}
    </>
  );
};

OutdoorSpaceTypes.propTypes = {
  title: PropTypes.string,
  softCriteria: PropTypes.shape({}),
  type: PropTypes.string,
  onClick: PropTypes.func,
};

OutdoorSpaceTypes.defaultProps = {
  title: '',
  softCriteria: {},
  type: '',
  onClick: () => {},
};
