import { Stages } from 'components';
import { useSignupRole } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentPlanLevelEffect } from 'store/effects/subscription';
import { getActiveSubscriptionData, getUserDataSelector } from 'store/selectors/user';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Brokerage, Message, MLS, StripeCheckout, Team, VerifyEmail } from './ProAndGrowFlow';
import styles from './styles.module.scss';

enum EnterpriseStepsEnum {
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  CHOOSE_PLAN = 'CHOOSE_PLAN',
}

enum ProAndGrowStepsEnum {
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  CHOOSE_PLAN = 'CHOOSE_PLAN',
  CREATE_PASSWORD = 'CREATE_PASSWORD',
  TEAM = 'TEAM',
  BROKERAGE = 'BROKERAGE',
  MLS = 'MLS',
  STRIPE_CHECKOUT = 'STRIPE_CHECKOUT',
  MESSAGE = 'MESSAGE',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
}

type EnterpriseSteps = {
  id: EnterpriseStepsEnum;
  Component: any;
};

type ProAndGrowSteps = {
  id: ProAndGrowStepsEnum;
  Component: any;
};

const stages: ProAndGrowSteps[] = [
  {
    id: ProAndGrowStepsEnum.STRIPE_CHECKOUT,
    Component: StripeCheckout,
  },
  {
    id: ProAndGrowStepsEnum.VERIFY_EMAIL,
    Component: VerifyEmail,
  },
  {
    id: ProAndGrowStepsEnum.TEAM,
    Component: Team,
  },
  {
    id: ProAndGrowStepsEnum.BROKERAGE,
    Component: Brokerage,
  },
  {
    id: ProAndGrowStepsEnum.MLS,
    Component: MLS,
  },
  {
    id: ProAndGrowStepsEnum.MESSAGE,
    Component: Message,
  },
];

export const OnboardingAgentV2: React.FC = () => {
  useSignupRole();
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);

  useEffect(() => {
    dispatch(getCurrentPlanLevelEffect({}, { showError: false }));
  }, [dispatch]);

  const filteredStages = useMemo(() => {
    if (user?.SelectedPlan === 'Solo') {
      return stages.filter((stage) => stage.id !== ProAndGrowStepsEnum.TEAM);
    }
    return stages;
  }, [user?.SelectedPlan]);

  return (
    <div className={styles.wrapper}>
      <Header />
      <Stages
        classNameWrapper={styles.contentContainer}
        showProgressBar={false}
        stages={filteredStages}
      />
    </div>
  );
};
