import { getPhoneValidator } from 'pages/Dashboard/ServiceDirectory/Partners/Partner/Form/validation';
import * as Yup from 'yup';
import 'yup-phone';

export const ValidationSchema = Yup.object().shape({});

export const ValidationPartnerSchema = Yup.object().shape({
  businessName: Yup.string().trim().required('Required'),
  partnerEmail: Yup.string().email().required('Required'),
  address: Yup.object().nullable(true),
  description: Yup.string().trim().required('Required'),
  website: Yup.string().nullable(true),
  partnerPhone: getPhoneValidator(),
});
