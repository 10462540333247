import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map } from 'lodash-es';

import styles from './styles.module.scss';

const TitleValueTable = ({ items, className, itemsClassName }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {map(items, ({ title, value }) => (
        <div
          testid="row_item"
          className={classNames(styles.item, itemsClassName)}
          key={title + value}
        >
          <div testid="row_title" className={styles.bold}>
            {title}
          </div>
          <div testid="row_value" className={styles.value}>
            {Array.isArray(value)
              ? map(value, (val, index) => <div key={index}>{val}</div>)
              : value}
          </div>
        </div>
      ))}
    </div>
  );
};

TitleValueTable.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    }),
  ).isRequired,
  itemsClassName: PropTypes.string,
};

TitleValueTable.defaultProps = {
  className: '',
  itemsClassName: '',
};

export default TitleValueTable;
