import { useLocation } from 'react-router-dom';
import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { link } from 'settings/navigation/link';
import {
  getSearchPageInfoSelector,
  getSearchResultsFilterDrawerSelector,
  getSearchResultsMarkersSelector,
  getSearchResultsSelector,
} from 'store/selectors/searchResults';
import {
  getEditedSearchSelector,
  getSearchCriteriaSelector,
  getSearchDrawnPolygonSelector,
  getSearchIsMapDrawingModeSelector,
} from 'store/selectors/search';
import { Map as CommonMap } from 'components';
import { MapWithDrawingManager } from 'components/MapWithDrawingManager/MapWithDrawingManager';
import { featureFlags } from 'utils/featureFlags';
import {
  setSearchDrawnPolygonAction,
  toggleSearchMapDrawingModeAction,
  setSearchViewportCoordinatesAction,
  cleanSearchDrawnPolygonAction,
} from 'store/actions/searchResults';
import { useNoMlsAccessHandler } from 'pages/Properties/Feed/Properties/hooks/useNoMlsAccessHandler';
import { getSearchResultsV2Effect } from 'store/effects/searchResults/searchV2';

const Map = ({ id }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const markers = useSelector(getSearchResultsMarkersSelector);
  const { properties, totalCount } = useSelector(getSearchResultsSelector);
  const currentSearch = useSelector(getSearchCriteriaSelector);
  const editSearch = useSelector(getEditedSearchSelector);
  const currentPageInfo = useSelector(getSearchPageInfoSelector);
  const getPropertyLink = useCallback((id) => link.toSearchListingDetails(id), []);

  const { wrappedEffect: getSearchResultsEffectWrapped } =
    useNoMlsAccessHandler(getSearchResultsV2Effect);

  const onBoundChangeHandlerMap = (viewportCoordinates, isInitialViewportCoordinate) => {
    dispatch(setSearchViewportCoordinatesAction(viewportCoordinates));
    if (currentPageInfo?.currentFEPageInfo?.fromListingDetailPage || !isInitialViewportCoordinate) {
      dispatch(getSearchResultsEffectWrapped());
    }
  };

  const getListingCountTextForMap = () => {
    if (properties?.length && properties?.length !== totalCount?.value) {
      const countText = totalCount?.type === 'Exact' ? totalCount?.value : `${totalCount?.value}+`;
      return `${properties.length} of ${totalCount?.value > 1000 ? '1000+' : countText} listings`;
    }
    return '';
  };

  const getSelectedLocations = () => {
    let selectedLocations = [];
    const placeIds = currentSearch?.Locations?.filter((location) => location.ProviderPlaceId)?.map(
      (location) => location.ProviderPlaceId,
    );

    const editPlaceIds = editSearch?.data?.criterias?.Locations?.filter(
      (location) => location.ProviderPlaceId,
    )?.map((location) => location.ProviderPlaceId);

    if (placeIds && placeIds.length) {
      selectedLocations = [...placeIds];
    }

    if (editPlaceIds && editPlaceIds.length) {
      selectedLocations = [...selectedLocations, ...editPlaceIds];
    }

    return selectedLocations;
  };

  if (featureFlags.customSearchPolygonDrawing) {
    return (
      <MapWithDrawingManager
        markersMap={markers}
        id={id}
        getPropertyLink={getPropertyLink}
        getDrawnPolygonSelectorFn={getSearchDrawnPolygonSelector}
        getIsMapDrawingModeSelectorFn={getSearchIsMapDrawingModeSelector}
        setDrawnPolygonDispatchFn={setSearchDrawnPolygonAction}
        toggleMapDrawingModeDispatchFn={toggleSearchMapDrawingModeAction}
        onBoundsChangedHandlerFn={onBoundChangeHandlerMap}
        listingCountTextForMap={getListingCountTextForMap()}
        isMapEnhancementEnabled={true}
        selectedLocations={getSelectedLocations()}
      />
    );
  }

  return (
    <CommonMap
      markersMap={markers}
      id={id}
      getPropertyLink={getPropertyLink}
      onBoundsChangedHandlerFn={onBoundChangeHandlerMap}
      listingCountTextForMap={getListingCountTextForMap()}
      isMapEnhancementEnabled={true}
      selectedLocations={getSelectedLocations()}
    />
  );
};

Map.propTypes = {
  id: PropTypes.string,
};

Map.defaultProps = {
  id: '',
};

export default Map;
