import {
  CLIENT_AGENT_CHILDREN,
  PARTNER_CHILDREN,
  PROPERTY_LISTING_CHILDREN,
  SYSTEM_OPTION_CHILDREN,
} from 'app-constants';
import { ChildrenOption, FormQuestionType } from 'types';

const filterOptions = (children: ChildrenOption[], type: FormQuestionType) => {
  const result: ChildrenOption[] = [];
  for (const child of children) {
    if (child.type === type) result.push(child);
    else if (child.children?.length) {
      const children = filterOptions(child.children, type);
      if (children?.length) {
        result.push({ ...child, children });
      }
    }
  }
  return result;
};

export const getPopulateWithOptions = (type: FormQuestionType) => {
  const clientAgentOptions = filterOptions(CLIENT_AGENT_CHILDREN, type);
  const partnerOptions = filterOptions(PARTNER_CHILDREN, type);
  const propertyListingOptions = filterOptions(PROPERTY_LISTING_CHILDREN, type);
  const systemOptions = filterOptions(SYSTEM_OPTION_CHILDREN, type);

  const options: ChildrenOption[] = [
    ...(clientAgentOptions?.length
      ? [
          {
            value: 'client',
            label: 'Client',
            children: clientAgentOptions,
          },
          {
            value: 'agent',
            label: 'Agent',
            children: clientAgentOptions,
          },
        ]
      : []),
    ...(partnerOptions?.length
      ? [
          {
            value: 'partner',
            label: 'Partner',
            children: partnerOptions,
          },
        ]
      : []),
    ...(propertyListingOptions?.length
      ? [
          {
            value: 'propertyListing',
            label: 'Property listing',
            children: propertyListingOptions,
          },
        ]
      : []),
    ...(systemOptions?.length
      ? [
          {
            value: 'systemOptions',
            label: 'Miscellaneous',
            children: systemOptions,
          },
        ]
      : []),
  ];

  return options;
};
