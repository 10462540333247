import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Input as InputType, Wrapper as PendingWrapper } from 'components';
import { Geotag } from 'components/Icons';

import styles from './styles.module.scss';

const Input = (props) => {
  const {
    className,
    iconClassName,
    getInputProps,
    placeholder,
    loading,
    variant,
    showPrefixIcon,
    onKeyDown,
    loaderClassName,
  } = props;

  return (
    <div
      className={classNames(styles.inputWrapper, styles[variant], className, {
        [styles.hasIcon]: showPrefixIcon,
      })}
    >
      {variant === InputType.LIGHT_ROUND || showPrefixIcon ? (
        <Geotag className={classNames(styles.geoTagIcon, iconClassName)} color="#515151" />
      ) : null}
      <input
        {...getInputProps({
          placeholder,
          className: styles.input,
          autoFocus: true,
        })}
        onKeyDownCapture={onKeyDown}
      />
      <PendingWrapper
        loaderClassName={classNames(styles.loader, loaderClassName)}
        isPending={loading}
        className={variant === 'round' ? styles.spinner : undefined}
      />
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  getInputProps: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  showPrefixIcon: PropTypes.bool,
  loaderClassName: PropTypes.string,
};

Input.defaultProps = {
  className: '',
  iconClassName: '',
  getInputProps: () => {},
  onKeyDown: () => {},
  placeholder: undefined,
  loading: false,
  showPrefixIcon: false,
  loaderClassName: '',
};

export default Input;
