import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const AttachmentDateTitle = (props) => {
  const { className, children } = props;

  return <div className={classNames(styles.attachmentDateTitle, className)}>{children}</div>;
};

AttachmentDateTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AttachmentDateTitle.defaultProps = {
  className: '',
};

export default AttachmentDateTitle;
