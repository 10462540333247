import { FeedV2Query, SortOrder } from 'api/feed/feedV2';
import { getSearchResultsV2 } from 'api/searchResults/searchV2';
import { EDIT } from 'settings/constants/mode';
import { getState } from 'store';
import {
  requestGetSearchResultsAction,
  setSearchPageInfoAction,
} from 'store/actions/searchResults';
import Api from '../core/api';
import moment from 'moment';

const DEFAULT_PAGE_SIZE = 350;

export const getSearchResultsV2Effect = (
  cfg: any = {},
  options: { add?: boolean } = {},
  cb: Function | undefined = undefined,
) => {
  const {
    searchResults,
    context: contextState,
    search: { search, edited },
  } = getState();
  const {
    sort: { order, fields },
    filterDrawer: { mode },
    pageInfo,
  } = searchResults;
  const { context } = contextState;

  let config = cfg;

  if (!config || !Object.keys(config).length) {
    config =
      mode === EDIT
        ? (edited?.data as any)?.criterias || (edited?.data as any)?.SearchQuery
        : search;
  }

  const queryParams: FeedV2Query = {
    sortField: fields[0],
    sortOrder: order as SortOrder,
    first: DEFAULT_PAGE_SIZE,
    endCursor: options.add ? pageInfo?.endCursor ?? null : null,
    contextId: (context as any)?.ContextKey ?? null,
    loadTotalCount: true,
    currentTimeUTC: moment().utc().format('HH:mm:ss'),
    searchInstanceId: null,
  };

  const requestParams = {
    action: requestGetSearchResultsAction,
    method: getSearchResultsV2,
    pending: options.add ? false : true,
    cfg: {
      queryParams,
      body: {
        searchQuery: config,
      },
    },
    options,
    cb: (err, response, dispatch) => {
      if (!err) {
        dispatch(setSearchPageInfoAction(response.data.pageInfo));
      }

      if (cb) {
        cb(err, response, dispatch);
      }
    },
  };

  return Api.execFunc(requestParams);
};
