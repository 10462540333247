export const LOCAL_STORAGE_KEY = 'afterVerificationState';

export enum ActionToInterrupt {
  INVITE_CLIENT = 'inviteClient',
  INVITE_TEAM_MEMBER = 'inviteTeamMember',
  CREATE_TRANSACTION = 'createTransaction',
  CLOSE_TRANSACTION = 'closeTransaction',
  CREATE_SHOWING = 'createShowing',
  CREATE_FORM = 'createForm',
  REQUEST_QUOTE = 'requestQuote',
  MESSAGE_TO_PARTNER = 'messageToPartner',
}
