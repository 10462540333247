import { get } from 'lodash-es';
import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ adminPanel }) => adminPanel;

const taskTemplatesState = createSelector(localState, ({ taskTemplates }) => taskTemplates);

const taskTemplateState = createSelector(localState, ({ taskTemplate }) => taskTemplate);

export const getTaskTemplatesDashboardSelector = createSelector(
  taskTemplatesState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTaskTemplatesDataDashboardSelector = createSelector(taskTemplatesState, (state) =>
  get(state, 'data'),
);

export const getTaskTemplateDashboardSelector = createSelector(
  taskTemplateState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTaskTemplateDataDashboardSelector = createSelector(
  taskTemplateState,
  (state) => state.data,
);

export const getTaskTemplateEditDataDashboardSelector = createSelector(
  taskTemplateState,
  (state) => ({
    ...(state?.data || {}),
    Category: get(state, 'data.Category')
      ? {
          name: get(state, 'data.Category.Name'),
          value: get(state, 'data.Category.Id'),
        }
      : null,
  }),
);

export const getTaskTemplatesSearchSelector = createSelector(
  taskTemplatesState,
  ({ search }) => search,
);

export const getTaskTemplatesSortSelector = createSelector(taskTemplatesState, ({ sort }) => sort);
