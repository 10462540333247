import AntSkeleton, { SkeletonProps as AntSkeletonProps } from 'antd/lib/skeleton';
import styles from './styles.module.scss';

export const PropertySliderSkeleton = () => (
  <div className={styles.skeletonCard}>
    <div className={styles.image}>
      <AntSkeleton.Button active block shape={'square'} />
    </div>
    <div className={styles.dataContainer}>
      <AntSkeleton.Button active block shape={'default'} className={styles.data1} />
      <AntSkeleton.Button active block shape={'default'} className={styles.data2} />
    </div>
  </div>
);
