import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetLoginLogsAction,
  requestGetTeamAndOwnerDetailsAction,
  requestGetTeamDetailsByIdAction,
  requestGetTeamMembersAction,
  requestGetTeamSeatsLogByTeamIdAction,
  setMembersSearchAction,
} from 'store/actions/adminPanel';

const initialData = {
  state: IDLE,
  data: null,
  filters: {
    searchString: '',
  },
  meta: {},
  onboarding: null,
  members: [],
  seatLogs: [],
  membersSearch: '',
  loginLogs: [],
};

export default handleActions(
  {
    [requestGetTeamDetailsByIdAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestGetTeamAndOwnerDetailsAction.toString()]: (state, { payload }) => ({
      ...state,
      onboarding: get(payload, 'data', initialData.onboarding),
    }),
    [requestGetTeamMembersAction]: (state, { payload }) => ({
      ...state,
      members: get(payload, 'data', initialData.members),
    }),
    [requestGetTeamSeatsLogByTeamIdAction]: (state, { payload }) => ({
      ...state,
      seatLogs: get(payload, 'data', initialData.seatLogs),
    }),
    [setMembersSearchAction]: (state, { payload }) => ({
      ...state,
      membersSearch: payload || '',
    }),
    [requestGetLoginLogsAction]: (state, { payload }) => ({
      ...state,
      loginLogs: get(payload, 'data', initialData.loginLogs),
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
