import { createMilestone, editMilestone } from './Milestones';
import { createTask, editTask } from './Tasks';

/* eslint-disable no-case-declarations */
export const handler = async (intent: string, payload: any) => {
  switch (intent) {
    case 'CREATE_TASK':
      return await createTask(payload);
    case 'EDIT_TASK':
      return await editTask(payload);
    case 'CREATE_MILESTONE':
      return await createMilestone(payload);
    case 'EDIT_MILESTONE':
      return await editMilestone(payload);
    // case 'CHANGE_TRANSACTION_STATUS':
    //   return await createAtask(payload);
    default:
      break;
  }
};
