export enum TimelineType {
  Tour = 'Tour',
  Milestone = 'Milestone',
  Showing = 'Showing',
}

export interface ITimelineItem {
  top?: string;
  main: string;
  sub: string;
  type: TimelineType;
}

export interface ITimelineDay {
  heading: string;
  data: ITimelineItem[];
}

export interface ITimelineCardProps {
  title: string;
  viewAllRoute: string;
  timelineData: ITimelineDay[];
}
