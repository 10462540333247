import React from 'react';
import PropTypes from 'prop-types';

const SmallSearchIcon = ({ className }) => (
  <div className={className}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.66667 11.3333C9.244 11.3333 11.3333 9.244 11.3333 6.66667C11.3333 4.08934 9.244 2 6.66667 2C4.08934 2 2 4.08934 2 6.66667C2 9.244 4.08934 11.3333 6.66667 11.3333Z"
        stroke="#FF576D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14L10 10"
        stroke="#FF576D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

SmallSearchIcon.propTypes = {
  className: PropTypes.string,
};

SmallSearchIcon.defaultProps = {
  className: '',
};

export default SmallSearchIcon;
