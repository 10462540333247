export default {
  dashboardServiceDirectoryPartners: '/dashboard/service-directory/partners',
  dashboardServiceDirectoryPartnerCreate: '/dashboard/service-directory/partners/create',
  dashboardServiceDirectoryPartner: '/dashboard/service-directory/partners/:id',
  dashboardServiceDirectoryPartnerEdit: '/dashboard/service-directory/partners/:id/edit',
  dashboardServiceDirectoryCategories: '/dashboard/service-directory/categories',
  dashboardServiceDirectoryCategoryCreate: '/dashboard/service-directory/categories/create',
  dashboardServiceDirectoryCategory: '/dashboard/service-directory/categories/:id',
  dashboardServiceDirectoryCategoryEdit: '/dashboard/service-directory/categories/:id/edit',
  dashboardServiceDirectoryForms: '/dashboard/service-directory/forms',
  dashboardServiceDirectoryFormsQuoteForm:
    '/dashboard/service-directory/forms/quoteform/:versionId',
  dashboardServiceDirectoryFormsQuoteFormPreview:
    '/dashboard/service-directory/forms/quoteform/:versionId/preview',
  dashboardServiceDirectoryFormsSmartFormPreview:
    '/dashboard/service-directory/forms/smartform/:versionId/preview',
  dashboardServiceDirectoryFormsSmartForm:
    '/dashboard/service-directory/forms/smartform/:versionId',
  dashboardServiceDirectoryFormBuilder: '/dashboard/service-directory/formBuilder/:id',
  dashboardServiceDirectoryFormCreate: '/dashboard/service-directory/forms/create',
  dashboardServiceDirectoryForm: '/dashboard/service-directory/forms/:id',
  dashboardServiceDirectoryFormEdit: '/dashboard/service-directory/forms/:id/edit',
  dashboardServiceDirectoryServiceTags: '/dashboard/service-directory/services',
  dashboardServiceDirectoryServiceTag: '/dashboard/service-directory/services/:id',
  dashboardServiceDirectoryServiceTagCreate: '/dashboard/service-directory/services/create',
  dashboardServiceDirectoryServiceTagEdit: '/dashboard/service-directory/services/:id/edit',
};
