import { useSelector } from 'react-redux';
import moment from 'moment';

import { Button } from 'components-antd';
import { getAggregateMilestoneTransactionsSelector } from 'store/selectors/transactions';

import styles from './styles.module.scss';

export interface FooterProps {
  setNewFormValues: (val) => void;
  onCancelModal: () => void;
  onSubmit: () => void;
  isPending: boolean;
  values: any;
}

export const Footer = (props: FooterProps) => {
  const { values, setNewFormValues, onCancelModal, onSubmit, isPending } = props;
  const transactions = useSelector(getAggregateMilestoneTransactionsSelector);

  const isDisabled = () =>
    !(
      values?.MilestoneName?.length &&
      values?.TransactionId &&
      values?.TransactionId > 0 &&
      values?.DueDate
    ) || isPending;

  return (
    <div className={styles.componentFooter}>
      <Button
        className={styles.submitButton}
        variant="secondary"
        onClick={onSubmit}
        disabled={isDisabled()}
      >
        Create
      </Button>
    </div>
  );
};
