import { createSelector } from 'reselect';
import { ERROR, IDLE, PENDING } from 'settings/constants/apiState';
import { getUserId } from '../user';

const localState = ({ offer }) => offer;

export const getCreatedOfferDetails = createSelector(localState, (currentState) => {
  const offer = currentState?.data;
  return {
    ...offer,
    isIdle: currentState?.state === IDLE,
    isPending: currentState?.state === PENDING,
    isData: !!currentState?.offer,
  };
});

export const getofferSelector = createSelector(localState, getUserId, (offer, userId) => {
  const Offer = offer?.data;
  return {
    isIdle: offer.state === IDLE,
    isPending: offer.state === PENDING,
    isError: offer.state === ERROR,
    isData: !!offer,
    isUpdatePending: offer.updateState === PENDING,
    Offer: {
      ...(Offer || {}),
      Participants: Offer?.Offer || [],
      User: Offer?.SellerAgent || [],
    },
    status: offer?.data?.status,
    isCreator: userId === Offer?.CreatorBy,
    BuyingAgent: Offer?.BuyingAgentName,
    CreatedDate: Offer?.CreatedDate,
    TransactionId: offer?.meta?.transactionId,
  };
});

export const getSelectedOffersForComparison = createSelector(localState, (currentState) => ({
  selectedOffers: currentState.selectedOffers,
}));
