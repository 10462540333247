import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { dropConfetti } from 'utils';

import styles from './styles.module.scss';
import { Button } from 'components-antd';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import moment from 'moment';
import { Question } from 'pages/RequestQuote/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { useSelector } from 'react-redux';
import { getUserDataSelector } from 'store/selectors/user';

const Success = (props) => {
  const history = useHistory();
  const user = useSelector(getUserDataSelector);

  const autoRedirectTimestamp = useRef(moment().add(5, 'seconds'));

  const [secondsTillNextStep, setSecondsTillNextStep] = useState(
    autoRedirectTimestamp.current.diff(moment(), 'seconds'),
  );

  useEffect(() => {
    dropConfetti();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingSeconds = autoRedirectTimestamp.current.diff(moment(), 'seconds');
      setSecondsTillNextStep(remainingSeconds);
    });

    return () => {
      clearInterval(interval);
    };
  }, []);

  const goToNextStep = () => {
    const requiresContinuingOnboarding = localStorage.getItem('afterVerificationState');
    const isWizardFinished = user?.Agent?.WizardFinished;
    if (isWizardFinished === false) {
      history.push(routes.onBoardingV2Agent);
    } else if (requiresContinuingOnboarding) {
      const parsedValue = JSON.parse(requiresContinuingOnboarding);
      history.push(parsedValue.url);
    } else {
      history.push(routes.index);
    }
  };

  useEffect(() => {
    if (secondsTillNextStep <= 0) {
      goToNextStep();
    }
  }, [secondsTillNextStep]);

  return (
    <div className={styles.wrapper}>
      <Question className={styles.question}>Success! Your email was verified.</Question>
      <AnswersContainer>
        {secondsTillNextStep > 0 && (
          <p className={styles.redirectText}>
            We&#8217;ll automatically redirect you to the next step in {secondsTillNextStep}{' '}
            seconds...
          </p>
        )}
      </AnswersContainer>
      <ButtonsContainer>
        <Button
          onClick={() => {
            goToNextStep();
          }}
          variant="secondary"
          className={styles.submit}
        >
          Continue
        </Button>
      </ButtonsContainer>
    </div>
  );
};

Success.propTypes = {
  className: PropTypes.string,
};

Success.defaultProps = {
  className: '',
};

export default Success;
