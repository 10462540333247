import { Menu, SubMenu, Paragraph, TooltipParagraph, Skeleton } from 'components-antd';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { updateFormListSearchEffect } from 'store/effects/formBuilder';
import TrippleDot from 'pages/FormBuilder/Icons/TrippleDot';
import Favorites from 'pages/FormBuilder/Icons/Favorites';
import Folder from 'pages/FormBuilder/Icons/Folder';
import Trash from 'pages/FormBuilder/Icons/Trash';
import Edit from 'pages/FormBuilder/Icons/Edit';
import { DeleteModalState, FormFolderModalState, FolderType } from 'types';

import styles from '../../styles.module.scss';

interface FolderListMenuProps {
  loading: boolean;
  folders?: FolderType[];
  setEntityId: (entityId: number) => void;
  setEntityName: (entityName: string) => void;
  setDeleteModal: (deleteModal: DeleteModalState) => void;
  setFormFolderModal: (formFolderModal: FormFolderModalState) => void;
}

export const FolderListMenu = ({
  loading,
  folders,
  setEntityId,
  setEntityName,
  setDeleteModal,
  setFormFolderModal,
}: FolderListMenuProps) => {
  const dispatch = useDispatch();
  const selectFolder = (folderId?) => {
    const newSelectedFolder = folderId ? `${folderId}-folder` : 'allForms';
    if (newSelectedFolder != selectedFolder) {
      setSelectedFolder(folderId ? `${folderId}-folder` : 'allForms');
      dispatch(updateFormListSearchEffect({ folderId }));
    }
  };

  const [isFavorite, setIsFavorite] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState('allForms');

  useEffect(() => {
    let params = { isFavorite: isFavorite ? isFavorite : undefined };
    dispatch(updateFormListSearchEffect({ ...params }));
  }, [isFavorite]);

  const sortedFolders = folders?.sort((a, b) => a.Id - b.Id);

  return (
    <Menu className={styles.scrollableMenuItems} theme="light" selectedKeys={[selectedFolder]}>
      <Menu.Item key="allForms" onClick={() => selectFolder()}>
        <div className={styles.menuItemCollection}>
          <Paragraph className={styles.plainMenuItem}>All Forms</Paragraph>
        </div>
      </Menu.Item>

      <Menu.Item key="favorites" onClick={() => setIsFavorite(!isFavorite)}>
        <div className={styles.menuItemCollection}>
          <Favorites favorite={isFavorite} stroke="#E66F3E" className={styles.menuItemIcon} />
          <Paragraph className={styles.menuItemContent}>Favorites</Paragraph>
        </div>
      </Menu.Item>

      <Skeleton
        showSkeleton={loading}
        rowCount={folders?.length || 10}
        avatar={{ shape: 'square' }}
      >
        <Menu theme="light" selectedKeys={[selectedFolder]}>
          {sortedFolders?.map((folder) => (
            <Menu.Item key={`${folder.Id}-folder`}>
              <div className={styles.menuItemCollection}>
                <Folder staticIcon stroke="#666666" className={styles.menuItemIcon} />

                <TooltipParagraph
                  ellipsis
                  className={styles.menuItemContent}
                  onClick={() => selectFolder(folder.Id)}
                >
                  {folder.Name}
                </TooltipParagraph>

                <Menu mode="vertical" className={styles.menuItemOptions}>
                  <SubMenu
                    key={`${folder.Id}-folder-options`}
                    popupClassName={styles.menuPopup}
                    popupOffset={[-175, 35]}
                    title={
                      <TrippleDot
                        data-testid={`${folder.Id}-folder-options`}
                        className={styles.folderSubMenuTitle}
                      />
                    }
                    className={styles.folderSubMenu}
                  >
                    <Menu.Item
                      key={`${folder.Id}-rename-folder`}
                      data-testid={`${folder.Id}-rename-folder`}
                      className={styles.folderSubMenuItem}
                      onClick={() => {
                        setEntityId(folder.Id);
                        setEntityName(folder.Name);
                        setFormFolderModal({
                          open: true,
                          title: 'Rename Folder',
                          okText: 'Save',
                          folderName: folder.Name,
                          type: 'rename',
                        });
                      }}
                    >
                      <div className={styles.menuItemCollection}>
                        <Edit className={styles.menuItemIcon} />
                        <Paragraph className={styles.menuItemContent}>Rename Folder</Paragraph>
                      </div>
                    </Menu.Item>

                    <Menu.Item
                      key={`${folder.Id}-delete-folder`}
                      data-testid={`${folder.Id}-delete-folder`}
                      className={styles.folderSubMenuItem}
                      onClick={() =>
                        setDeleteModal({
                          open: true,
                          entityId: folder.Id,
                          entityName: folder.Name,
                        })
                      }
                    >
                      <div className={styles.menuItemCollection}>
                        <Trash className={styles.menuItemIcon} />
                        <Paragraph className={styles.menuItemContent}>Delete</Paragraph>
                      </div>
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      </Skeleton>
    </Menu>
  );
};
