import classnames from 'classnames';

import { Button } from 'components-antd';

import styles from './styles.module.scss';

interface OfferSelectionFooterProps {
  className?: string;
  selectedOffers: number;
  handleContinueOfferUpdates: () => void;
}

export const OfferSelectionFooter = ({
  className,
  selectedOffers,
  handleContinueOfferUpdates,
}: OfferSelectionFooterProps) => (
  <div className={classnames(styles.offerSelectionFooterContainer, className)}>
    <div className={classnames(styles.content, 'container')}>
      <div>
        {selectedOffers} Offer{selectedOffers === 1 ? '' : 's'} Selected
      </div>
      <Button
        disabled={selectedOffers <= 1}
        className={classnames(styles.continueBtn, { [styles.disabledBtn]: !selectedOffers })}
        variant="secondary"
        onClick={handleContinueOfferUpdates}
      >
        Compare
      </Button>
    </div>
  </div>
);
