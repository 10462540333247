import classNames from 'classnames';

import Sider, { SiderProps as AntSiderProps } from 'antd/lib/layout/Sider';

import styles from './styles.module.scss';

export const Sidebar = ({ className, children, ...props }: AntSiderProps) => (
  <Sider className={classNames(styles.commonSidebar, className)} {...props}>
    {children}
  </Sider>
);
