export const DashboardIcon = ({ className }) => (
  <div className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" fill="white" />
      <rect x="3" y="3" width="7" height="7" rx="1" stroke="#AAABAB" strokeWidth="2" />
      <path
        d="M3 15C3 14.4477 3.44772 14 4 14C7.31371 14 10 16.6863 10 20C10 20.5523 9.55228 21 9 21H4C3.44772 21 3 20.5523 3 20V15Z"
        stroke="#AAABAB"
        strokeWidth="2"
      />
      <rect
        x="12.9142"
        y="6.5"
        width="6.5"
        height="6.5"
        rx="1"
        transform="rotate(-45 12.9142 6.5)"
        stroke="#AAABAB"
        strokeWidth="2"
      />
      <rect x="14" y="14" width="7" height="7" rx="1" stroke="#AAABAB" strokeWidth="2" />
    </svg>
  </div>
);

export const MessagesIcon = ({ className }) => (
  <div className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M23 22V10.625C23 9.92881 22.7234 9.26113 22.2312 8.76884C21.7389 8.27656 21.0712 8 20.375 8H11.625C10.9288 8 10.2611 8.27656 9.76884 8.76884C9.27656 9.26113 9 9.92881 9 10.625V15.875C9 16.5712 9.27656 17.2389 9.76884 17.7312C10.2611 18.2234 10.9288 18.5 11.625 18.5H19.5L23 22Z"
        fill="white"
        stroke="#AAABAB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 17V4.8125C1 4.06658 1.29632 3.35121 1.82376 2.82376C2.35121 2.29632 3.06658 2 3.8125 2H13.1875C13.9334 2 14.6488 2.29632 15.1762 2.82376C15.7037 3.35121 16 4.06658 16 4.8125V10.4375C16 11.1834 15.7037 11.8988 15.1762 12.4262C14.6488 12.9537 13.9334 13.25 13.1875 13.25H4.75L1 17Z"
        fill="white"
        stroke="#AAABAB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
