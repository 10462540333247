import React from 'react';
import classNames from 'classnames';

import { Tabs } from 'components';
import { House, DollarsIcon, Geotag } from 'components/Icons';
import FinancesTab from './FinancesTab';
import NeighborhoodTab from './NeighborhoodTab';
import PropertyTab from './PropertyTab';

import styles from './styles.module.scss';

const ListingDetailTabs = () => (
  <div>
    <Tabs
      tabs={ListingDetailTabs.TABS}
      tabClassName={styles.listingItemTab}
      tabsClassName={styles.listingItemTabs}
      activeTabClassName={styles.activeListingItemTab}
    />
  </div>
);

ListingDetailTabs.TABS = [
  {
    label: 'Property',
    Component: PropertyTab,
    Icon: <House className={styles.tabIcon} />,
    testid: 'property_tab',
  },
  {
    label: 'Finances',
    Component: FinancesTab,
    Icon: <DollarsIcon className={classNames(styles.tabIcon, styles.financesIcon)} />,
    testid: 'finances_tab',
  },
  {
    label: 'Neighborhood',
    Component: NeighborhoodTab,
    Icon: <Geotag className={styles.tabIcon} />,
    testid: 'neighborhood_tab',
  },
];

export default ListingDetailTabs;
