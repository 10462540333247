import { Text } from 'components-antd';
import styles from './styles.module.scss';

import { Navigation } from 'pages/FormProcess/components/PreForm/components';

export const TemplateSuccess = (props) => {
  const { editMode, onContinue, isBundle } = props;
  return (
    <>
      <Text className={styles.questionHeader}>
        Your template {isBundle ? 'bundle' : ''} has been {editMode ? 'updated' : 'created'}.
      </Text>
      <div className={styles.pageContent}>
        <Navigation onContinue={onContinue} buttonText="Done" />
      </div>
    </>
  );
};
