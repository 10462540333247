import { handleActions } from 'redux-actions';
import {
  cleanSearchDrawnPolygonAction,
  setSearchDrawnPolygonAction,
  toggleSearchMapDrawingModeAction,
} from 'store/actions/searchResults';

const initialData = {
  isEnabled: false,
  polygon: null,
};

export default handleActions(
  {
    [toggleSearchMapDrawingModeAction as any]: (state, { payload }) => ({
      ...state,
      isEnabled: payload !== undefined ? (payload as unknown as boolean) : !state.isEnabled,
    }),
    [cleanSearchDrawnPolygonAction as any]: (state, { payload }) => ({
      ...state,
      isEnabled: false,
      polygon: null,
    }),
    [setSearchDrawnPolygonAction as any]: (state, { payload }) => ({
      ...state,
      isEnabled: false,
      polygon: payload as any,
    }),
  },
  initialData,
);
