import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Input, Checkbox } from 'antd';
import { useDispatch } from 'react-redux';

import { Button, Divider, Modal } from 'components-antd';
import { announcementType } from 'settings/constants/offer';
import { sendAnnouncementEffect } from 'store/effects/offers';
import { showSuccessMessage } from 'helpers';
import { OfferStatus } from 'types/offers';

import styles from './styles.module.scss';

const { TextArea } = Input;

const initState = {
  message: undefined,
  externalOffer: false,
  all: false,
};
const checkBoxInitialStates = {
  all: false,
  pending: false,
  rejected: false,
  withdrawn: false,
  counter: false,
};

interface MegaPhoneProps {
  isOpen: boolean;
  onClose: () => void;
  type?: string;
  offerId?: number;
  offerLinkId?: number;
  offerData?: any;
}

export const MegaPhoneModal = ({
  isOpen,
  onClose,
  type,
  offerId,
  offerLinkId,
  offerData,
}: MegaPhoneProps) => {
  const dispatch = useDispatch();
  const [state, setState]: any = useState(initState);
  const [checkBoxState, setCheckBoxState] = useState(checkBoxInitialStates);
  const [loading, setLoading] = useState(false);

  const onChange = (obj) => {
    const key = Object.keys(obj)[0];
    const value = obj[key];
    const newCheckboxState = { ...checkBoxState, [key]: value };
    if (key === 'all') {
      Object.assign(
        newCheckboxState,
        ...Object.keys(newCheckboxState).map((key) => ({ [key]: value })),
      );
      setCheckBoxState(newCheckboxState);
      setState((prev) => ({ ...prev, ...newCheckboxState }));
      return;
    }
    if (key === 'message') {
      setState({ ...state, message: value });
    }

    // If all other checkboxes are checked, set "All" checkbox to true
    if (
      Object.keys(newCheckboxState)
        .filter((checkboxKey) => checkboxKey !== 'all')
        .every((checkboxKey) => newCheckboxState[checkboxKey])
    ) {
      newCheckboxState.all = true;
    } else {
      newCheckboxState.all = false;
    }
    setCheckBoxState(newCheckboxState);

    setState((prev) => ({ ...prev, ...newCheckboxState }));
  };

  const onSubmit = () => {
    const body: any = {
      Message: state.message,
      DisplayOnBooking: state.externalOffer,
      Type: type,
      SendTo: [],
    };

    if (type === announcementType.PUBLIC) {
      body.OfferLinkId = Number(offerLinkId);
      if (state.all || state.pending) body.SendTo.push(OfferStatus.Pending);
      if (state.all || state.counter)
        body.SendTo.push(OfferStatus.CounterSent, OfferStatus.CounterReceived);
      if (state.all || state.rejected) body.SendTo.push(OfferStatus.Rejected);
      if (state.all || state.Withdrawn) body.SendTo.push(OfferStatus.Withdrawn);
    } else {
      body.OfferId = Number(offerId);
      body.SendTo.push('BuyingAgentName');
    }

    setLoading(true);
    dispatch(
      sendAnnouncementEffect(body, {}, (err) => {
        setLoading(false);
        if (!err) {
          showSuccessMessage('Message Sent Successfully');
          onClose();
        }
      }),
    );
  };

  const isDisabled = () => {
    if (type === announcementType.PUBLIC) {
      return (
        !state.message ||
        (!state.all && !state.pending && !state.counter && !state.rejected && !state.withdrawn)
      );
    } else {
      return !state.message;
    }
  };

  useEffect(() => {
    if (type === announcementType.PUBLIC) {
      setState({
        ...state,
        pending: false,
        counter: false,
        rejected: false,
        withdrawn: false,
      });
    }
  }, [type]);

  useEffect(() => {
    if (!isOpen) {
      setState(initState);
      setCheckBoxState(checkBoxInitialStates);
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      className={styles.megaPhoneModal}
    >
      <h1 className={styles.heading}>Message</h1>
      {type === announcementType.PUBLIC ? (
        <p className={styles.info}>
          This message will be sent to all selected agents via their preferred communication
          method(s).
        </p>
      ) : null}
      {type === announcementType.ITERATION && (
        <>
          <h3>To:</h3>
          <span>{offerData?.BuyingAgentName}</span>
          <Divider className={styles.divider} />
          <h3>Message:</h3>
        </>
      )}
      <TextArea
        rows={5}
        maxLength={500}
        placeholder="Enter message..."
        className={styles.textArea}
        onChange={(e) => setState((prev) => ({ ...prev, message: e.target.value }))}
        defaultValue={state.message}
      />
      {type === announcementType.PUBLIC ? (
        <>
          <div className={styles.additionalInfo}>
            <Checkbox
              checked={state.externalOffer}
              defaultChecked={state.externalOffer}
              onChange={(e) => setState((prev) => ({ ...prev, externalOffer: e.target.checked }))}
              className={classNames(styles.checkbox, 'mosaikCheckbox')}
            >
              Display on external offer submission page
            </Checkbox>
            <div className={styles.divider}></div>
          </div>
          <p className={styles.subHeading}>{'Send to agents who have'}:</p>
          <div className={styles.checkboxWrap}>
            <Checkbox
              checked={checkBoxState.all}
              defaultChecked={state.all}
              onChange={(e) => onChange({ all: e.target.checked })}
              className={classNames(styles.checkbox, 'mosaikCheckbox')}
            >
              All
            </Checkbox>
          </div>
          <div className={styles.checkboxWrap}>
            <Checkbox
              checked={checkBoxState.pending}
              defaultChecked={state.pending}
              onChange={(e) => onChange({ pending: e.target.checked })}
              className={classNames(
                styles.checkbox,
                checkBoxState.all ? styles.greyCheckBox : '',
                'mosaikCheckbox',
              )}
            >
              Pending Offers
            </Checkbox>
          </div>
          <div className={styles.checkboxWrap}>
            <Checkbox
              checked={checkBoxState.counter}
              defaultChecked={state.counter}
              onChange={(e) => onChange({ counter: e.target.checked })}
              className={classNames(
                styles.checkbox,
                checkBoxState.all ? styles.greyCheckBox : '',
                'mosaikCheckbox',
              )}
            >
              Counter Offers
            </Checkbox>
          </div>
          <div className={styles.checkboxWrap}>
            <Checkbox
              checked={checkBoxState.rejected}
              defaultChecked={state.rejected}
              onChange={(e) => onChange({ rejected: e.target.checked })}
              className={classNames(
                styles.checkbox,
                checkBoxState.all ? styles.greyCheckBox : '',
                'mosaikCheckbox',
              )}
            >
              Rejected Offers
            </Checkbox>
          </div>
          <div className={styles.checkboxWrap}>
            <Checkbox
              checked={checkBoxState.withdrawn}
              defaultChecked={state.withdrawn}
              onChange={(e) => onChange({ withdrawn: e.target.checked })}
              className={classNames(
                styles.checkbox,
                checkBoxState.all ? styles.greyCheckBox : '',
                'mosaikCheckbox',
              )}
            >
              Withdrawn Offers
            </Checkbox>
          </div>
          <div className={styles.divider}></div>
        </>
      ) : null}

      <div className={styles.actionsContainer}>
        <Button
          variant="secondary"
          className={styles.action}
          onClick={onSubmit}
          disabled={isDisabled()}
          loading={loading}
        >
          Send
        </Button>
      </div>
    </Modal>
  );
};
