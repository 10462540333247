/* eslint-disable react/no-unescaped-entities */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import useIsDirty from 'hooks/use-is-dirty';
import { cloneDeep } from 'lodash-es';

import { getFormattedData } from './helper';

import {
  SEARCH_CRITERIA_IMPORTANCE,
  SEARCH_CRITERIA_ACTIONS,
} from 'settings/constants/searchCriterias';
import { Button } from 'components';
import {
  ScrollDrawerWrapper,
  DrawerPaddingWrapper,
  Footer,
} from 'pages/Properties/SearchResults/components';
import {
  getSearchImportantFeaturesSelector,
  getSearchRawPrefsSelector,
} from 'store/selectors/search';
import { getSchoolDataSelector, getSchoolRawDataSelector } from 'store/selectors/searchResults';
import { sortStrings } from 'helpers';
import MustItems from './Must';
import WantsItems from './Wants';
import NoPrefsItems from './NoPrefs';
import { getSearchResultsFilterDrawerModeSelector } from 'store/selectors/searchResults';
import { EDIT } from 'settings/constants/mode';
import {
  saveAddEditSchoolDataEffect,
  saveAddEditSchoolRawDataEffect,
  setRawPrefDataEffect,
} from 'store/effects';
import { getSearchDrawerCommuteIdSelector } from 'store/selectors/searchResults';

import styles from './styles.module.scss';
import { prefsIds } from 'settings/constants/preferences';

const ImportantFeatures = ({ className, onSubmit, isPending, search }) => {
  const dispatch = useDispatch();

  const schoolData = useSelector(getSchoolDataSelector);
  const features = useSelector(getSearchImportantFeaturesSelector);
  const savedPrefs = useSelector(getSearchRawPrefsSelector);

  const getInitialDataForPrefs = () => {
    const {
      [SEARCH_CRITERIA_IMPORTANCE.MUST]: must,
      [SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT]: somewhat,
      [SEARCH_CRITERIA_IMPORTANCE.UNDEFINED]: noPreference,
    } = savedPrefs;
    if (must?.length || somewhat?.length || noPreference?.length) {
      return savedPrefs;
    }
    return features;
  };
  const mode = useSelector(getSearchResultsFilterDrawerModeSelector);
  const commuteId = useSelector(getSearchDrawerCommuteIdSelector);
  const { schoolTypes: schoolTypesRawData, specificItems: specificItemsRawData } =
    useSelector(getSchoolRawDataSelector);
  const isEditMode = mode === EDIT;
  const [prefs, setPrefs] = useState(getInitialDataForPrefs());
  const [updatableSearch, setUpdatableSearchPrefs] = useState(search);
  const [isDirtyPrefs] = useIsDirty(updatableSearch);
  const [isDirtyImportances] = useIsDirty(prefs);

  const removeSchoolFromStore = (data) => {
    const type = data.type;
    const schools = { ...schoolData };
    let updatedData;
    if (type === 'NonSpecificConfig') {
      updatedData = schools[data.type].filter((val) => val != data.Id);
      dispatch(
        saveAddEditSchoolRawDataEffect({
          schoolTypes: schoolTypesRawData.filter((val) => val != data.Id),
          specificItems: specificItemsRawData,
        }),
      );
    } else {
      const filteredItem = specificItemsRawData.filter((schoolData) => {
        const value = JSON.parse(schoolData.value);
        return value.Id !== data.Id;
      });
      dispatch(
        saveAddEditSchoolRawDataEffect({
          schoolTypes: schoolTypesRawData,
          specificItems: filteredItem,
        }),
      );
      updatedData = schools[data.type].filter((val) => val.Id != data.Id);
    }

    dispatch(
      saveAddEditSchoolDataEffect({
        schoolData: { ...schoolData, [data.type]: updatedData },
      }),
    );
  };

  const onChangePref = (id, prevImportance, currentImportance, updatedSearchDiff) => {
    if (prevImportance === currentImportance && updatedSearchDiff?.OutdoorPrefs?.length) {
      const outdoorPrefs = updatedSearchDiff?.OutdoorPrefs.find(
        (val) => val?.Preference === 'OutdoorYard' || val?.Preference === 'FencedYard',
      );
      if (outdoorPrefs) {
        const updatedPrefs = prefs[currentImportance].filter(
          (val) => val !== 'OutdoorYard' && val !== 'FencedYard',
        );
        setUpdatableSearchPrefs({
          ...updatableSearch,
          ...updatedSearchDiff,
        });
        const newPrefs = {
          ...prefs,
          [currentImportance]: [...updatedPrefs, outdoorPrefs.Preference].sort(sortStrings),
        };
        setPrefs(newPrefs);
        return;
      }
    }

    if (currentImportance === SEARCH_CRITERIA_ACTIONS.DELETE) {
      removeSchoolFromStore(id);

      const previousPref = [...prefs[prevImportance]];
      const itemIndex = prefs[prevImportance].indexOf(id);
      previousPref.splice(itemIndex, 1);
      const newPrefs = {
        ...prefs,
        [prevImportance]: previousPref,
      };
      setPrefs(newPrefs);
      return;
    }
    if (updatedSearchDiff) {
      setUpdatableSearchPrefs({
        ...updatableSearch,
        ...updatedSearchDiff,
      });
    }

    if (!id) return null;

    if (prefs[currentImportance].includes(id)) return null;

    const newPrefs = {
      ...prefs,
      [prevImportance]: prefs[prevImportance].filter((item) => item !== id).sort(sortStrings),
      [currentImportance]: [...prefs[currentImportance], id].sort(sortStrings),
    };
    setPrefs(newPrefs);
  };

  const updatePrefs = (newPrefItems) => {
    const prefItems = cloneDeep(prefs);
    const itemToReplaceWith = newPrefItems?.find(
      (item) => item.Preference === prefsIds.fencedYard || item.Preference === prefsIds.outdoorYard,
    );

    const replaceValueInArrays = (obj, oldValue, newValue) => {
      Object.keys(obj)?.forEach((key) => {
        obj[key] = obj[key]?.map((item) => (item === oldValue ? newValue : item));
      });
    };

    replaceValueInArrays(prefItems, prefsIds.outdoorYard, itemToReplaceWith.Preference);
    setPrefs(prefItems);
  };

  const submitHandler = () => {
    dispatch(setRawPrefDataEffect(prefs));
    onSubmit(getFormattedData(updatableSearch, prefs));
  };

  const setPreferenceForSchools = (schoolsData) => {
    const prefData = { ...prefs };
    const data = {
      [SEARCH_CRITERIA_IMPORTANCE.MUST]:
        prefData[SEARCH_CRITERIA_IMPORTANCE.MUST]?.filter((val) => typeof val !== 'object') || [],
      [SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT]:
        prefData[SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT]?.filter((val) => typeof val !== 'object') ||
        [],
      [SEARCH_CRITERIA_IMPORTANCE.UNDEFINED]: [
        ...(prefData[SEARCH_CRITERIA_IMPORTANCE.UNDEFINED]?.filter(
          (val) => typeof val !== 'object',
        ) || []),
        ...schoolsData,
      ],
    };
    setPrefs(data);
    dispatch(setRawPrefDataEffect(data));
  };

  if (commuteId) {
    return null;
  }
  return (
    <div>
      <ScrollDrawerWrapper className={styles.scrollWrapper}>
        <DrawerPaddingWrapper className={classNames(className)}>
          <MustItems
            className={styles.block}
            listItems={prefs[SEARCH_CRITERIA_IMPORTANCE.MUST]}
            onChangePref={onChangePref}
            onUpdatePrefs={updatePrefs}
            rawSearchPrefs={updatableSearch}
          />
          <WantsItems
            className={styles.block}
            listItems={prefs[SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT]}
            onChangePref={onChangePref}
            onUpdatePrefs={updatePrefs}
            rawSearchPrefs={updatableSearch}
          />
          <NoPrefsItems
            className={styles.block}
            listItems={prefs[SEARCH_CRITERIA_IMPORTANCE.UNDEFINED]}
            setPreferenceForSchools={setPreferenceForSchools}
            onChangePref={onChangePref}
            rawSearchPrefs={updatableSearch}
          />
        </DrawerPaddingWrapper>
      </ScrollDrawerWrapper>
      <Footer className={styles.footer}>
        <Button
          onClick={submitHandler}
          className={styles.submitButton}
          title={isEditMode ? 'Save' : 'Search'}
          isPending={isPending}
          testid="done"
          disabled={!isDirtyPrefs && !isDirtyImportances}
        />
      </Footer>
    </div>
  );
};

ImportantFeatures.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  onSubmit: PropTypes.func,
};

ImportantFeatures.defaultProps = {
  className: '',
  sPending: false,
  onSubmit: () => {},
};

export default ImportantFeatures;
