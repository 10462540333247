import PropTypes from 'prop-types';

import { ClientActivityLogSection } from './components/ClientActivityLogSection';

export const ConnectionActivity = ({ isAgent }) => {
  return <ClientActivityLogSection isAgent={isAgent} />;
};

ConnectionActivity.propTypes = {
  isAgent: PropTypes.bool,
};

ConnectionActivity.defaultProps = {
  isAgent: false,
};
