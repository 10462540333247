import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from 'pages/Dashboard/components/Grid';
import Header from 'pages/Dashboard/components/Header';
import Icons from 'pages/Dashboard/components/Icons';
import { Wrapper as PendingWrapper, ConfirmationDialog } from 'components';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { getServiceTagsSelector } from 'store/selectors/adminPanel';
import { getServiceTagsEffect, deleteServiceTagEffect } from 'store/effects/adminPanel';
import { gridColumns } from './config';
import { Edit, Write } from 'components/Icons';
import { link } from 'settings/navigation/link';
import { routes } from 'settings/navigation/routes';
import Filters from './FilterForm';

import styles from './styles.module.scss';

const ServiceTags = () => {
  const dispatch = useDispatch();
  const serviceTags = useSelector(getServiceTagsSelector);

  useEffect(() => {
    if (serviceTags.state === IDLE) {
      dispatch(getServiceTagsEffect());
    }
  }, []);

  const isPending = serviceTags.state === PENDING;

  const [itemToChangeStatus, setItemToChangeStatus] = useState(null);

  const onCloseChangeStatusItemModal = useCallback(
    () => setItemToChangeStatus(null),
    [setItemToChangeStatus],
  );
  const onConfirmChangeItemStatus = useCallback(() => {
    if (!itemToChangeStatus) return;

    dispatch(
      deleteServiceTagEffect({ id: itemToChangeStatus.Id }, (err) => {
        if (!err) {
          setItemToChangeStatus(null);
        }
      }),
    );
  }, [dispatch, itemToChangeStatus, setItemToChangeStatus]);

  const renderActions = useCallback(
    (Id, data) => (
      <>
        <Link
          className={styles.editButton}
          to={link.toDashboardServiceDirectoryServiceTagEdit(Id)}
          testid="edit_link"
        >
          <Edit className={styles.buttonIcon} />
        </Link>
        <div
          className={styles.deactivateButton}
          onClick={(e) => {
            e.stopPropagation();
            setItemToChangeStatus(data);
          }}
          testid="delete_button"
        >
          <Icons className={styles.buttonIcon} variant={Icons.DEACTIVATE} />
        </div>
      </>
    ),
    [],
  );

  const changeItemStatusContent = useMemo(
    () =>
      itemToChangeStatus ? (
        <div>
          Are you sure that you want to delete service&nbsp;
          <b>{itemToChangeStatus.Title}</b>?
        </div>
      ) : null,
    [itemToChangeStatus],
  );

  return (
    <PendingWrapper testid="serviceTags_view" isPending={isPending} className={styles.pageWrapper}>
      <Header
        title="Services"
        buttonText="New Service"
        buttonIcon={<Write />}
        buttonLink={routes.dashboardServiceDirectoryServiceTagCreate}
      />
      <Filters />
      <Grid
        items={serviceTags.data || []}
        columns={gridColumns}
        keyProp="Id"
        renderActions={renderActions}
      />
      <ConfirmationDialog
        onReject={onCloseChangeStatusItemModal}
        onConfirm={onConfirmChangeItemStatus}
        isOpen={!!itemToChangeStatus}
      >
        {changeItemStatusContent}
      </ConfirmationDialog>
    </PendingWrapper>
  );
};

export default ServiceTags;
