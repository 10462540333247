import React from 'react';
import PropTypes from 'prop-types';

const ApiRequestErrorIcon = ({ className }) => (
  <div className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 18.5C8.85748 19.5612 10.1434 20 12 20C16.0796 20 19.446 16.9463 19.9381 13H21.1315C21.9302 13 22.4066 12.1099 21.9635 11.4453L19.832 8.24804C19.4362 7.6543 18.5638 7.6543 18.1679 8.24804L16.0365 11.4453C15.5934 12.1099 16.0698 13 16.8685 13H17.917C17.441 15.8377 14.973 18 12 18C10.9067 18 9.88172 17.7076 8.99897 17.1967L7.5 18.5Z"
        fill="#FF576D"
      />
      <path
        d="M15.0014 6.80326C14.1186 6.2924 13.0937 6 12.0004 6C9.02736 6 6.55939 8.16229 6.08331 11H7.13184C7.93053 11 8.40693 11.8901 7.96389 12.5547L5.83241 15.7519C5.43658 16.3457 4.56413 16.3457 4.16831 15.7519L2.03682 12.5547C1.59379 11.8901 2.07018 11 2.86887 11H4.06225C4.55435 7.05369 7.92074 4 12.0004 4C13.857 4 15.1429 4.43881 16.5004 5.5L15.0014 6.80326Z"
        fill="#FF576D"
      />
    </svg>
  </div>
);

ApiRequestErrorIcon.propTypes = {
  className: PropTypes.string,
};

ApiRequestErrorIcon.defaultProps = {
  className: '',
};

export default ApiRequestErrorIcon;
