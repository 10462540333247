import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { calculatePercentageRatio } from 'helpers';

import useAdditionIndex from './useAdditionIndex';

import styles from './styles.module.scss';

const Progress = (props) => {
  const {
    currentStageIndex,
    stages,
    additionalStages,
    startQuestionForAdditionsIds,
    ...classNameProps
  } = props;
  const { className } = classNameProps;
  const additionStagesIds = additionalStages.map(({ id }) => id);
  const currentStageId = stages[currentStageIndex].id;
  const lastAdditionalStage = additionalStages[additionalStages.length - 1];
  const lastAdditionalStageIndex = additionalStages.findIndex(
    ({ id }) => id === lastAdditionalStage.id,
  );

  const [indexAdditional] = useAdditionIndex({
    currentStageIndex,
    additionStagesIds,
    currentStageId,
    lastAdditionalStage,
    startQuestionForAdditionsIds,
  });

  const getProgress = () => {
    const countStages = stages.length - additionalStages.length;
    let progress = calculatePercentageRatio(currentStageIndex + 1, countStages);

    if (additionStagesIds.includes(currentStageId)) {
      const stagePartWidth = calculatePercentageRatio(countStages, 100);

      const additionalPercent = stagePartWidth / additionalStages.length;

      progress = calculatePercentageRatio(currentStageIndex + 1 - indexAdditional, countStages);

      progress += additionalPercent * indexAdditional;
    } else if (currentStageIndex > lastAdditionalStageIndex) {
      progress = calculatePercentageRatio(
        currentStageIndex + 1 - additionalStages.length,
        countStages,
      );
    }

    return progress <= 100 ? progress : 100;
  };

  return (
    <div className={className}>
      <div className={styles.progressBackground} />
      <div className={classNames(styles.progress)} style={{ width: `${getProgress()}%` }} />
    </div>
  );
};

Progress.propTypes = {
  className: PropTypes.string,
  currentStageIndex: PropTypes.number.isRequired,
  stages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])).isRequired,
  additionalStages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
  startQuestionForAdditionsIds: PropTypes.arrayOf(PropTypes.string),
};

Progress.defaultProps = {
  className: '',
  additionalStages: [],
  startQuestionForAdditionsIds: [],
};

export default Progress;
