import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const RightSide = ({ className, variant }) => (
  <div className={classNames(styles.aside, styles[variant], className)} />
);

RightSide.LIGHT = 'light';

RightSide.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
};

RightSide.defaultProps = {
  className: '',
  variant: undefined,
};

export default RightSide;
