import React, { useCallback, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { AddPhoto, FormGroup } from 'components';
import { getUserDataSelector, getUserRoleSelector, isPartnerSelector } from 'store/selectors/user';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { onBoardingSignUpEffect } from 'store/effects';
import { PENDING } from 'settings/constants/apiState';

import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

import styles from './styles.module.scss';
import { useExitOnboarding } from 'pages/OnBoarding/hooks/useExitOnboarding';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { OnboardingContinueButton } from '../../agent/components/OnboardingContinueButton';
import { OnboardingSkipButton } from '../../agent/components/OnboardingSkipButton';
import { getImagesPreview } from 'helpers';

type Props = {
  className: string;
  onNext: () => {};
  stageIndex?: number;
  Controls: React.ElementType;
};

const PartnerProfilePhotoForm: React.FC<Props> = (props) => {
  const { className, onNext, stageIndex, Controls } = props;
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const userRole = useSelector(getUserRoleSelector);
  const { onBoarding } = useSelector(getOnBoardingData);
  const isPartner = useSelector(isPartnerSelector);
  const { isPending: isExitOnboardingPending, handleExit: exitOnboarding } = useExitOnboarding();

  const isPending = onBoarding.state === PENDING;

  const skipVendorPreference = useMemo(
    () => (user?.ThirdParty && !user?.ThirdParty?.Partner ? true : false),
    [user?.ThirdParty?.Partner],
  );

  const onSkip = () => (skipVendorPreference ? exitOnboarding() : onNext());

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        onBoardingSignUpEffect({ ...values, stageIndex }, { isCommonInfo: true }, (err) => {
          if (!err) onSkip();
        }),
      );
    },
    [(onSkip, stageIndex, dispatch)],
  );

  const getLogoValue = () => {
    if (isPartner) {
      return user?.AvatarUrl;
    }
    return null;
  };

  // Used to show the correct !binary format image after user select
  // Couldn't find a better way to do this yet
  const [tempImage, setTempImage] = useState(null);

  const formik = useFormik({
    initialValues: {
      file: getLogoValue(),
    },
    onSubmit,
  });
  const getValue = () => {
    const img = formik.values?.file;
    if (typeof img === 'string') {
      return img;
    }
    return tempImage;
  };

  return (
    <OnBoardingWrapper isPending={isPending} className={styles.wrapper}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />

      <OnBoardingContainer>
        <Question className={styles.title}>
          Do you want to add a photo for your individual profile?
        </Question>
        <AnswersContainer className={styles.profilePhotoWrapper}>
          <FormGroup className={styles.formGroup}>
            <form onSubmit={formik.handleSubmit}>
              <AddPhoto
                variant={AddPhoto.LIGHT}
                value={getValue()}
                onChange={(image, files) => {
                  setTempImage(image);
                  formik.setFieldValue('file', files[0]);
                }}
              />
            </form>
          </FormGroup>
        </AnswersContainer>

        <ButtonsContainer>
          <OnboardingContinueButton
            isPending={isPending}
            disabled={!formik.values?.file}
            testid="next"
            htmlType="submit"
            className={classNames(styles.submitBtn, {
              [styles.active]: formik.values?.file,
            })}
            onClick={() => formik.submitForm()}
          />
          <OnboardingSkipButton
            isPending={isExitOnboardingPending}
            testid="skip"
            onClick={onSkip}
            className={styles.skip}
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

export { PartnerProfilePhotoForm };
