import { Fragment, useEffect, useRef, useState } from 'react';
import { Switch } from 'antd';
import { Icons } from '../../Icons';

import {
  EditLinkDuration,
  EditParties,
  EditAvailability,
  EditNotify,
  EditDuration,
  EditNotes,
  EditFeedback,
  EditDocuments,
} from '../components';
import { Spinner } from 'components';
import { Navigation } from '../../components';
import { showSuccessMessage } from 'helpers/success';

import styles from './styles.module.scss';

export const EnableLink = ({
  stageIndex,
  onPrev,
  onClose,
  onFinish,
  setCurrentStageIndex,
  data,
  transaction,
  loading,
  stagesStep,
}) => {
  const { EnableShowingLink, ShowingLink } = data;
  const [state, setState] = useState({
    EnableShowingLink,
  });

  const copyLink = () => {
    navigator.clipboard.writeText(ShowingLink);
    showSuccessMessage('Link Copied');
  };

  const bottomRef: any = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [stageIndex, state]);

  return (
    <div className={styles.enableLink}>
      <div className={styles.contentWrap}>
        <EditAvailability
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.Availability)}
        />
        <EditNotify data={data} onEdit={() => setCurrentStageIndex(stagesStep.Notify)} />
        <EditDocuments data={data} onEdit={() => setCurrentStageIndex(stagesStep.Documents)} />
        <EditNotes data={data} onEdit={() => setCurrentStageIndex(stagesStep.Notes)} />
        <EditFeedback data={data} onEdit={() => setCurrentStageIndex(stagesStep.Feedback)} />
        <EditLinkDuration
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.LinkDuration)}
        />

        <div ref={bottomRef} className={styles.content}>
          <div className={styles.titleWrapper}>
            <h4 className={styles.title}>
              <Icons className={styles.linkIcon} variant="link" />
              Enable showing link now?
            </h4>
            <Switch
              disabled={loading}
              className={styles.switch}
              checked={state.EnableShowingLink}
              defaultChecked={state.EnableShowingLink}
              onChange={(v) => {
                setState({ EnableShowingLink: v });
                onFinish({ ...data, EnableShowingLink: v });
              }}
            />
          </div>

          {loading ? (
            <Spinner loaderClassName={styles.loadingSpinner} />
          ) : !state.EnableShowingLink ? (
            <h5 className={styles.subTitle}>
              <Icons variant="info" />
              If you&apos;re not ready to enable it, we can save your configuration for later.
            </h5>
          ) : state.EnableShowingLink ? (
            <Fragment>
              <div className={styles.linkContainer}>
                <a>{ShowingLink}</a>
                <div className={styles.copyBtn} onClick={copyLink}>
                  <Icons variant={'copy'} className={styles.copyIcon} />
                  <span>Copy</span>
                </div>
              </div>
              <h5 className={styles.subTitle}>
                <Icons variant="info" />
                This link will be available on your transaction page if you want to copy it later
              </h5>
            </Fragment>
          ) : null}
        </div>
      </div>
      <Navigation
        data={data}
        stageIndex={stageIndex}
        onPrev={onPrev}
        onClose={onClose}
        onFinish={onFinish}
        className={styles.footer}
        disabled={loading}
        showBackButton={false}
      />
    </div>
  );
};
