import styles from './styles.module.scss';

export const PageHeader = () => (
  <>
    <header className={styles.formPageHeader}>All Forms</header>
    <p className={styles.formPageText}>
      All forms are displayed here. Create folders to organize your forms.
    </p>
  </>
);
