import { Button, Col, Row } from 'components-antd';
import { Icons } from 'pages/Workshop/Offers/icons';
import { OfferStatus } from 'types/offers';

import styles from './styles.module.scss';
import classNames from 'classnames';

type ActionButtonsType = {
  IterationStatus: string;
  handleActionClick: (item: string) => void;
  className?: string;
};

const ActionButtons = ({ IterationStatus, handleActionClick, className }: ActionButtonsType) => {
  return (
    <Row>
      <Col span={24}>
        <div className={classNames(styles.actionBtn, className)}>
          {IterationStatus === OfferStatus.Expired ? (
            <Button variant="default" onClick={() => handleActionClick('edit')}>
              <div className={styles.actionIcon}>
                <Icons variant="edit" />
                <span>Edit</span>
              </div>
            </Button>
          ) : IterationStatus === OfferStatus.Inbound ? (
            <>
              <Button variant="default" onClick={() => handleActionClick('accept')}>
                <div className={styles.actionIcon}>
                  <Icons variant="accept" />
                  <span>Accept</span>
                </div>
              </Button>
              <Button variant="default" onClick={() => handleActionClick('counter')}>
                <div className={styles.actionIcon}>
                  <Icons variant="counter" />
                  <span>Counter</span>
                </div>
              </Button>
              <Button variant="default" onClick={() => handleActionClick('reject')}>
                <div className={styles.actionIcon}>
                  <Icons variant="reject" />
                  <span>Reject</span>
                </div>
              </Button>
            </>
          ) : (
            <>
              <Button variant="default" onClick={() => handleActionClick('withdraw')}>
                <div className={styles.actionIcon}>
                  <Icons variant="reject" />
                  <span>Withdraw</span>
                </div>
              </Button>
              <Button variant="default" onClick={() => handleActionClick('edit')}>
                <div className={styles.actionIcon}>
                  <Icons variant="edit" />
                  <span>Edit</span>
                </div>
              </Button>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ActionButtons;
