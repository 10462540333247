import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { setNotificationsDrawerParamsEffect } from 'store/effects/drawers/notifications';
import { DrawerCancel } from 'components';
import { ArrowLeft } from 'components/Icons';

import styles from './styles.module.scss';

const UserNotificationsHeader = ({ className, onClose }) => {
  const dispatch = useDispatch();

  const onBack = () => {
    dispatch(setNotificationsDrawerParamsEffect({}));
  };

  return (
    <div className={classNames(styles.header, className)}>
      <div className={styles.titleWrapper}>
        <button testid="header_button" className={styles.allNotifications} onClick={onBack}>
          <ArrowLeft className={styles.arrow} />
          <span>All notifications</span>
        </button>
      </div>
      <DrawerCancel onClick={onClose} />
    </div>
  );
};

UserNotificationsHeader.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

UserNotificationsHeader.defaultProps = {
  className: '',
  onClose: () => {},
};

export default UserNotificationsHeader;
