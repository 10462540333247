import classNames from 'classnames';
import { useEffect, useState } from 'react';

import Icons from '../../../../../Details/components/icons';
import Field from '../../../../../Details/components/Field';
import styles from './styles.module.scss';
import { FieldsData } from 'types';
import { getDetailsTemplate } from 'api/templates';
import { useSelector } from 'react-redux';
import { getAgentTeamDetailSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_BASIC } from 'settings/constants/roles';

interface DetailsProps {
  fieldsData: FieldsData[];
  setDetailsTemplateFields: (fieldsData: FieldsData[]) => void;
  transactionTemplateId: number | undefined;
  viewMode?: boolean;
  isProject?: boolean;
}

export function DetailsCardContent({
  fieldsData,
  setDetailsTemplateFields,
  transactionTemplateId,
  viewMode = false,
  isProject = false,
}: DetailsProps) {
  const fieldsLimit = 100;
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  // setDetailsTemplateFields(fieldsData);

  const { data } = useSelector(getAgentTeamDetailSelector);

  useEffect(() => {
    if (!data || data.TeamRole !== TEAM_BASIC) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [data]);

  useEffect(() => {
    if (loading && !transactionTemplateId) fetchDetailsTemplate();
  }, [loading]);

  const fetchDetailsTemplate = async () => {
    try {
      const params = isProject ? { isProject: true } : {};
      const response = await getDetailsTemplate(params);
      const {
        status,
        data: { result },
      } = response || {};

      if (status === 200) {
        if (result?.Fields?.length) {
          let fields: FieldsData[] = [];
          for (let i = 0; i < result?.Fields?.length; i++) {
            fields.push({
              Name: result?.Fields[i].Name as string,
              IsPrivate: result?.Fields[i].IsPrivate as boolean,
              EditMode: false,
            });
          }
          setDetailsTemplateFields(fields);
        }
      } else {
        // TODO: Handle error
      }
    } catch (e) {
      // TODO: Handle error
    }
    setLoading(false);
  };

  const handleFieldNameChange = (index: number, value: string) => {
    let updatedFields = [...fieldsData];
    if (updatedFields.length) {
      updatedFields[index].Name = value;
      setDetailsTemplateFields(updatedFields);
    }
  };

  const handleFieldPrivacyChange = (index: number, value: boolean) => {
    let updatedFields = Array.from(fieldsData);
    if (updatedFields.length) {
      updatedFields[index].IsPrivate = value;
      setDetailsTemplateFields(updatedFields);
    }
  };

  const handleEditModeChange = (index: number) => {
    let updatedFields = [...fieldsData];
    if (updatedFields.length) {
      updatedFields[index].EditMode = true;
      setDetailsTemplateFields(updatedFields);
    }
  };

  const deleteField = (index: number) => {
    let updatedFields = [...fieldsData];
    if (updatedFields.length) {
      updatedFields.splice(index, 1);
      setDetailsTemplateFields(updatedFields);
    }
  };

  const addField = () => {
    let updatedFields = [...fieldsData];
    updatedFields.push({
      Name: '',
      IsPrivate: false,
      EditMode: true,
    });
    setDetailsTemplateFields(updatedFields);
  };

  return (
    <div className={styles.detailTemplate}>
      <div className={styles.content}>
        {fieldsData?.map((item: any, index) => (
          <Field
            key={index}
            serialNumber={index}
            fieldName={item.Name}
            isPrivate={item.IsPrivate}
            fieldEditMode={item.EditMode}
            viewMode={viewMode}
            disabled={disabled}
            nameChangeEventHandler={handleFieldNameChange}
            privacyChangeEventHandler={handleFieldPrivacyChange}
            editModeChangeHandler={handleEditModeChange}
            deleteFieldHandler={deleteField}
          />
        ))}
      </div>
      <div className={styles.footer}>
        {!(disabled || viewMode) ? (
          <button
            type="button"
            disabled={fieldsData?.length === fieldsLimit}
            className={classNames(
              styles.add,
              fieldsData?.length === fieldsLimit ? styles.btnDisabled : '',
            )}
            onClick={(e) => {
              addField();
            }}
          >
            <Icons iconName="add" />
            <span>Add Field</span>
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
