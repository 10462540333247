import styles from './styles.module.scss';

export const LocationDetails = (props) => {
  const { PropertyTransaction } = props;
  const Property = PropertyTransaction?.Property;

  return (
    <div>
      <div className={styles.locationDetails}>
        <p className={styles.locationName}>{Property?.Address?.Line1}</p>
        <p className={styles.locationAddress}>
          {Property?.Address?.City + ', ' + Property?.Address?.State + ' ' + Property?.Address?.Zip}
        </p>
        {/* <a
          className={styles.locationMap}
          href={`http://maps.google.com/?q=${Property?.Address?.PlaceName}`}
          target="_blank"
          rel="noreferrer"
        >
          View on map
        </a> */}
      </div>
    </div>
  );
};
