import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep, trim } from 'lodash-es';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';
import { setMultipleRequestQuotesEffect } from 'store/effects/quotes';
import { Link } from 'react-router-dom';
import { link } from 'settings/navigation/link';
import styles from './styles.module.scss';

const SelectedPartners = (props) => {
  const { className, categoryId, categoryName } = props;
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);
  const dispatch = useDispatch();

  const onRemove = (partnerId) => {
    const newPartnersList = cloneDeep(multiple?.partners) || [];
    const removedPartnerIndex = newPartnersList.findIndex((partner) => partner?.Id === partnerId);

    if (removedPartnerIndex !== -1) {
      newPartnersList.splice(removedPartnerIndex, 1);
      dispatch(setMultipleRequestQuotesEffect({ partners: newPartnersList }));
    }
  };

  const getPartnerIds = () => {
    const partnersIds = (multiple?.partners || []).map((partner) => partner?.Id);
    return partnersIds.join(',');
  };

  const numOfSelectedPartners = useMemo(() => multiple?.partners?.length, [multiple?.partners]);
  const title = useMemo(() => {
    if (numOfSelectedPartners > 0) {
      if (categoryName.charAt(categoryName.length - 1) === 's' && numOfSelectedPartners === 1) {
        return `${numOfSelectedPartners} ${categoryName.slice(0, -1)} selected`;
      }

      return `${numOfSelectedPartners} ${categoryName} selected`;
    }
    return '';
  }, [numOfSelectedPartners, categoryName]);

  return (
    <div
      className={classNames(
        styles.selectedPartners,
        { [styles.active]: !!multiple?.partners?.length },
        className,
      )}
    >
      <div testid="selected_partners" className={styles.inner}>
        {numOfSelectedPartners > 0 && <div className={styles.numSelected}>{title}</div>}
        <Button
          component={Link}
          to={link.toServicesCategoryPartnerQuote({ categoryId, partnerId: getPartnerIds() })}
          className={styles.continueBtn}
          title="Continue"
          testid="continue_button"
          titleClassName={styles.title}
        />
      </div>
    </div>
  );
};

SelectedPartners.propTypes = {
  className: PropTypes.string,
  categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  categoryName: PropTypes.string,
};

SelectedPartners.defaultProps = {
  className: '',
  categoryName: '',
};

export default SelectedPartners;
