import PropTypes from 'prop-types';

const Shared = ({ className, stroke }) => (
  <div className={className}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1964_265899)">
        <path
          d="M11.75 7.25H4.25C3.42157 7.25 2.75 7.92157 2.75 8.75V13.25C2.75 14.0784 3.42157 14.75 4.25 14.75H11.75C12.5784 14.75 13.25 14.0784 13.25 13.25V8.75C13.25 7.92157 12.5784 7.25 11.75 7.25Z"
          stroke={stroke || '#FF576D'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 11.75C8.41421 11.75 8.75 11.4142 8.75 11C8.75 10.5858 8.41421 10.25 8 10.25C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75Z"
          stroke={stroke || '#FF576D'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 7.25V4.25C5 3.45435 5.31607 2.69129 5.87868 2.12868C6.44129 1.56607 7.20435 1.25 8 1.25C8.79565 1.25 9.55871 1.56607 10.1213 2.12868C10.6247 2.63206 10.9307 3.29591 10.9896 4"
          stroke={stroke || '#FF576D'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1964_265899">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

Shared.propTypes = {
  className: PropTypes.string,
  stroke: PropTypes.string,
};

Shared.defaultProps = {
  className: '',
  stroke: null,
};

export default Shared;
