import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { getSearchDrawerCommuteIdSelector } from 'store/selectors/searchResults';

import {
  Beds,
  Baths,
  Locations,
  MinMax,
  YearMinMax,
  PropertyType,
  Input,
  Button,
  Wrapper as PendingWrapper,
  Select,
  LotSizeMinMax,
} from 'components';
import {
  ScrollDrawerWrapper,
  DrawerPaddingWrapper,
  Footer,
  ParkingSpaces,
} from 'pages/Properties/SearchResults/components';
import { GarageOnly } from 'pages/Properties/Feed/components';
import { sortStrings, sortLocations, sortStatus } from 'helpers';
import { propertyStatusOptions } from 'settings/constants/properties';
import { ValidationSchema } from './validation';
import { getSearchResultsFilterDrawerModeSelector } from 'store/selectors/searchResults';
import { EDIT } from 'settings/constants/mode';
import { mergeInitialValues } from './helpers';

import styles from './styles.module.scss';
import { LocationType } from 'types';

const PrimaryCriteria = ({ className, onSubmit, isPending, search }) => {
  const mode = useSelector(getSearchResultsFilterDrawerModeSelector);
  const commuteId = useSelector(getSearchDrawerCommuteIdSelector);

  const isEditMode = mode === EDIT;

  const formik = useFormik({
    initialValues: mergeInitialValues(search),
    validationSchema: ValidationSchema,
    onSubmit(values) {
      onSubmit(values);
    },
  });

  const garageOnly = (
    <GarageOnly
      value={formik.values?.GarageOnly}
      onChange={(value) => formik.setFieldValue('GarageOnly', value)}
      disabled={formik.values?.MinParkingSpaces == 0}
    />
  );

  const flatValue = (propName) => formik?.values?.[propName];

  if (commuteId) {
    return null;
  }

  return (
    <div>
      <PendingWrapper className={styles.pendingWrapper} isPending={false}>
        <ScrollDrawerWrapper className={styles.wrapper}>
          <DrawerPaddingWrapper className={className}>
            <form id="primaryCriteria" onSubmit={formik.handleSubmit}>
              <Select
                name="Status"
                label="Status"
                multiple
                className={{ wrapper: classNames(styles.field, styles.statusField) }}
                variant={Select.LIGHT_FULL}
                options={propertyStatusOptions}
                value={formik.values.Status}
                onSelect={(event, val) => {
                  if (val?.length) val.sort(sortStatus);
                  formik.setFieldValue('Status', val);
                }}
                disabled={isPending}
                testid="status_select"
              />
              <Locations
                className={styles.field}
                valuesWrapperClassName={styles.locationsValues}
                name="Locations"
                label="Locations"
                onResult={(result, preparedData) => {
                  if (preparedData?.length) preparedData.sort(sortLocations);
                  formik.setFieldValue('Locations', preparedData);
                }}
                error={formik.touched.Locations ? formik.errors.Locations : ''}
                disabled={isPending}
                value={formik.values.Locations?.filter(
                  (loc) =>
                    loc.Type !== LocationType.Polygon &&
                    loc.Type !== LocationType.ViewportCoordinates,
                )}
                testid="locations"
                variant={Locations.LIGHT}
                showPrefixIcon
              />
              <MinMax
                className={styles.field}
                label="Price"
                nameMin="PriceRange.Min"
                nameMax="PriceRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.PriceRange?.Min}
                valueMax={formik.values?.PriceRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                prefix="$"
                disabled={isPending}
                testidMin="price_min"
                testidMax="price_max"
              />
              <MinMax
                className={styles.field}
                label="Beds"
                nameMin="NumBedroomsRange.Min"
                nameMax="NumBedroomsRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.NumBedroomsRange?.Min}
                valueMax={formik.values?.NumBedroomsRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
              />
              <MinMax
                className={styles.field}
                label="Baths"
                nameMin="NumBathroomsRange.Min"
                nameMax="NumBathroomsRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.NumBathroomsRange?.Min}
                valueMax={formik.values?.NumBathroomsRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
              />
              <PropertyType
                className={classNames(styles.field, styles.propertyWrapper)}
                label="Property Type"
                onChange={(type) => {
                  if (type?.length) type.sort(sortStrings);
                  formik.setFieldValue('HomeType', type);
                }}
                disabled={isPending}
                error={formik.touched.HomeType ? formik.errors.HomeType : ''}
                value={formik.values?.HomeType}
                propertyTypeWrapperClassName={styles.filterPropertyWrapper}
                itemClassName={styles.filterItem}
              />
              <MinMax
                className={styles.field}
                label="Square Feet"
                nameMin="SquareFeetRange.Min"
                nameMax="SquareFeetRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.SquareFeetRange?.Min}
                valueMax={formik.values?.SquareFeetRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                disabled={isPending}
                testidMin="square_min"
                testidMax="square_max"
              />
              <LotSizeMinMax
                isReset
                className={styles.field}
                label="Lot Size"
                nameMin="LotSizeRange.Min"
                nameMax="LotSizeRange.Max"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(val) =>
                  formik.setFieldValue('LotSizeRange', {
                    ...flatValue('LotSizeRange'),
                    Min: val,
                  })
                }
                onChangeMax={(val) =>
                  formik.setFieldValue('LotSizeRange', {
                    ...flatValue('LotSizeRange'),
                    Max: val,
                  })
                }
                valueMin={formik.values?.LotSizeRange?.Min}
                valueMax={formik.values?.LotSizeRange?.Max}
                disabled={isPending}
                testidMin="lot_size_min"
                testidMax="lot_size_max"
              />
              <YearMinMax
                isReset
                className={styles.field}
                label="Year Build"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(val) =>
                  formik.setFieldValue('YearBuiltRange', {
                    ...flatValue('YearBuiltRange'),
                    Min: val,
                  })
                }
                onChangeMax={(val) =>
                  formik.setFieldValue('YearBuiltRange', {
                    ...flatValue('YearBuiltRange'),
                    Max: val,
                  })
                }
                valueMin={formik.values?.YearBuiltRange?.Min}
                valueMax={formik.values?.YearBuiltRange?.Max}
                disabled={isPending}
                testidMin="year_min"
                testidMax="year_max"
              />
              <div className={classNames(styles.pseudo, styles.field)}>
                <Input
                  name="HOARange.Max"
                  className={classNames(styles.field, styles.hoa)}
                  variant={Input.LIGHT_FULL}
                  placeholder="$"
                  isNumericString
                  isNumberFormat
                  label="Maximum HOA"
                  prefix="$"
                  onChange={formik.handleChange}
                  value={formik.values.HOARange.Max}
                  disabled={isPending}
                  testid="max_hoa"
                />
                <p className={styles.pseudoPlaceholder}>Per month</p>
              </div>
              <ParkingSpaces
                className={classNames(styles.parkingSpaces)}
                onChange={(val) => formik.setFieldValue('MinParkingSpaces', val)}
                value={formik.values.MinParkingSpaces}
                disabled={isPending}
              >
                {garageOnly}
              </ParkingSpaces>
              <MinMax
                className={styles.field}
                thousandSeparator={false}
                label="Stories"
                nameMin="StoriesRange.Min"
                nameMax="StoriesRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.StoriesRange?.Min}
                valueMax={formik.values?.StoriesRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                disabled={isPending}
                testidMin="stories_min"
                testidMax="stories_max"
              />
              <Input
                name="MaxDaysOnMarket"
                className={styles.field}
                variant={Input.LIGHT_FULL}
                placeholder="No max"
                isNumericString
                isNumberFormat
                label="Days on Market"
                onChange={formik.handleChange}
                value={formik.values.MaxDaysOnMarket}
                disabled={isPending}
                testid="time_on_market"
              />
            </form>
          </DrawerPaddingWrapper>
        </ScrollDrawerWrapper>
        <Footer className={styles.footer}>
          <Button
            testid="save_button"
            isPending={isPending}
            form="primaryCriteria"
            className={styles.submitButton}
            type="submit"
            disabled={!search?.Locations?.length}
            title={isEditMode ? 'Save' : 'Search'}
          />
        </Footer>
      </PendingWrapper>
    </div>
  );
};

PrimaryCriteria.initialValues = {
  Locations: [],
  MinParkingSpaces: 0,
  GarageOnly: false,
  Status: [],
  MaxDaysOnMarket: '',
  HomeType: [],
  HOARange: { Max: '' },
  PriceRange: {
    Min: '',
    Max: '',
  },
  NumBedroomsRange: {
    Min: '',
    Max: '',
  },
  NumBathroomsRange: {
    Min: '',
    Max: '',
  },
  SquareFeetRange: {
    Min: '',
    Max: '',
  },
  LotSizeRange: {
    Min: '',
    Max: '',
  },
  YearBuiltRange: {
    Min: '',
    Max: '',
  },
  StoriesRange: {
    Min: '',
    Max: '',
  },
};

PrimaryCriteria.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  onSubmit: PropTypes.func,
};

PrimaryCriteria.defaultProps = {
  className: '',
  isPending: false,
  onSubmit: () => {},
};

export default PrimaryCriteria;
