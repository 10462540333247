import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { usePrevious } from 'hooks';
import { preFormQuestionsIds } from 'settings/constants/preForm';

import { getUserRolesMapSelector } from 'store/selectors/user';
import { getPreFormSelector, getRequestQuoteSummarySelector } from 'store/selectors/requestQuote';
import { setPreFormQuestionsEffect } from 'store/effects/quotes';

import { Button } from 'components';
import EditClient from './EditClient';
import SummaryItem from './SummaryItem';
import { Question, ContentWrapper } from 'pages/RequestQuote/components';
import EditPropertyAddress from './EditPropertyAddress';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import Controls from '../PreForm/Controls';
import { Close as CloseIcon } from 'components/Icons';
import EditQuestions from './EditQuestions';

import styles from './styles.module.scss';

const Summary = (props) => {
  const { className, onGood, onStartOver, filteredQuestions } = props;
  const dispatch = useDispatch();
  const summary = useSelector(getRequestQuoteSummarySelector);
  const [editLocation, setEditLocation] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [editQuestionIndex, setEditQuestionIndex] = useState(null);
  const preForm = useSelector(getPreFormSelector);

  const prevPreForm = usePrevious(preForm);
  const { isClient } = useSelector(getUserRolesMapSelector);

  const onCancelEdit = (propName) => {
    dispatch(
      setPreFormQuestionsEffect({
        [propName]: prevPreForm?.[propName],
      }),
    );
  };

  const editQuestionHandler = (index) => {
    setEditQuestionIndex(index);
  };

  const handleSaveClick = () => {
    setEditQuestionIndex(null);
  };

  return (
    <div className={styles.summaryPage}>
      <div className={styles.head}>
        <Controls className={styles.controls} isClient={isClient} onPrev={onStartOver} />
        <CloseIcon onClick={onGood} className={styles.closeIcon} />
      </div>
      <ContentWrapper className={classNames(styles.summary, className)}>
        <div className={styles.inner}>
          <Question>Does everything look right?</Question>
          <AnswersContainer>
            {summary?.location && (
              <SummaryItem
                className={{
                  [styles.disable]: !editLocation && (editClient || editQuestionIndex !== null),
                }}
                onEdit={() => setEditLocation(true)}
                question="Location:"
                answer={summary?.location}
                editAnswerComponent={
                  editLocation ? (
                    <EditPropertyAddress
                      onCancel={() => {
                        onCancelEdit(preFormQuestionsIds.property);
                        setEditLocation(false);
                      }}
                      onSave={() => setEditLocation(false)}
                    />
                  ) : undefined
                }
              />
            )}
            {summary?.client && (
              <SummaryItem
                className={{
                  [styles.disable]: !editClient && (editLocation || editQuestionIndex !== null),
                }}
                onEdit={() => setEditClient(true)}
                question="Client:"
                answer={summary?.client?.name}
                editAnswerComponent={
                  editClient ? (
                    <EditClient
                      onCancel={() => {
                        onCancelEdit(preFormQuestionsIds.client);
                        setEditClient(false);
                      }}
                      onSave={() => setEditClient(false)}
                    />
                  ) : undefined
                }
              />
            )}
            {(filteredQuestions || []).map((question, index) => (
              <SummaryItem
                className={{
                  [styles.disable]:
                    editClient ||
                    editLocation ||
                    (editQuestionIndex !== null && editQuestionIndex !== index),
                }}
                onEdit={() => editQuestionHandler(index)}
                id={question?.UUID}
                key={question?.UUID || index}
                questionType={question.Type}
                question={question?.Title}
                answer={
                  summary?.responses?.find((response) => response?.UUID === question?.UUID)?.Answer
                }
                editAnswerComponent={
                  editQuestionIndex === index ? (
                    <EditQuestions
                      questionIndex={editQuestionIndex}
                      formQuestions={filteredQuestions}
                      key={question?.UUID || index}
                      onCancel={() => setEditQuestionIndex(null)}
                      onSave={() => handleSaveClick()}
                    />
                  ) : null
                }
              />
            ))}
          </AnswersContainer>
        </div>
        <ButtonsContainer buttonsContainerClassName={styles.buttonsContainer}>
          <Button
            testid="next"
            type="button"
            className={classNames(styles.submit, styles.active, styles.button)}
            titleClassName={styles.buttonTitle}
            title="Looks Good!"
            onClick={onGood}
            disabled={editClient || editLocation || editQuestionIndex !== null}
          />
          <Button
            testid="startover"
            type="button"
            className={classNames(styles.startOver, styles.button)}
            titleClassName={styles.buttonTitle}
            title="Start Over"
            onClick={onStartOver}
            disabled={editClient || editLocation || editQuestionIndex !== null}
          />
        </ButtonsContainer>
      </ContentWrapper>
    </div>
  );
};

Summary.propTypes = {
  className: PropTypes.string,
  onGood: PropTypes.func,
  onStartOver: PropTypes.func,
  onSummaryEdit: PropTypes.func,
  filteredQuestions: PropTypes.array,
};

Summary.defaultProps = {
  className: '',
  onGood: () => {},
  onStartOver: () => {},
  onSummaryEdit: () => {},
  filteredQuestions: [],
};

export default Summary;
