import React, { useState, useRef, useLayoutEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOutsideClick, useScreen } from 'hooks';

import styles from './styles.module.scss';

const MENU_ITEM_HEIGHT = 44;
const MODAL_WIDTH = 196;

const ActionsList = ({ className, actions, position, onClose }) => {
  const [open, setOpen] = useState(false);
  const { screen } = useScreen();
  const listRef = useRef();

  useLayoutEffect(() => {
    setOpen(!!position);
  }, [position]);

  useOutsideClick([listRef], () => {
    setOpen(false);
    onClose();
  });

  const style = useMemo(() => {
    if (!position) {
      return null;
    }
    const blockHeight = MENU_ITEM_HEIGHT * actions?.length;

    const top =
      position?.y + blockHeight > screen.height ? screen.height - blockHeight - 5 : position?.y;

    const left =
      position?.x + MODAL_WIDTH > screen.width
        ? screen.width - MODAL_WIDTH - 5
        : position?.x - MODAL_WIDTH / 2;

    return { left, top };
  }, [position, screen.width, screen.height, actions?.length]);

  return (
    <div
      ref={listRef}
      className={classNames(styles.listHolder, className, { [styles.open]: open })}
      style={style}
    >
      <ul className={styles.list}>
        {actions.map(({ id, title, onClick, testid }) => (
          <li
            testid={testid}
            className={styles.item}
            key={title}
            onClick={(event) => onClick(event, id)}
          >
            {title}
          </li>
        ))}
      </ul>
    </div>
  );
};

ActionsList.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  className: PropTypes.string,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  onClose: PropTypes.func,
};

ActionsList.defaultProps = {
  className: '',
  actions: [],
  position: null,
  onClose: () => {},
};

export default ActionsList;
