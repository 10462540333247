import { useEffect, useState } from 'react';

import { Avatar, InputLabel } from 'components';
import { Option } from 'components-antd';
import { AutoComplete } from './AutoComplete';
import { caseInsensitiveFilter, convertNameToAvatarPlaceholder } from 'helpers';

import styles from './styles.module.scss';

export type ContactObject = {
  Id?: number;
  Name?: string;
  Role?: string;
  Email?: string;
  Phone?: string | null;
  CreatedBy?: string;
  CompanyName?: string;
};

interface ContactAutoCompleteProps {
  allContacts: ContactObject[];
  value?: string;
  onChange?: (...args) => void;
  onSelect?: (...args) => void;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
}

const ContactAutoComplete = ({
  allContacts = [],
  value,
  onChange,
  onSelect,
  disabled = false,
  error,
  placeholder,
}: ContactAutoCompleteProps) => {
  const [isSearch, setIsSearch] = useState(false);
  const [name, setName] = useState(value || '');

  useEffect(() => {
    if (value) {
      setName(value);
    }
  }, [value]);

  const addMissingAttributes = (selectedCount) => {
    const requiredAttributes = ['CompanyName', 'CreatedBy', 'Email', 'Id', 'Name', 'Phone', 'Role'];

    for (const attr of requiredAttributes) {
      if (!(attr in selectedCount)) {
        selectedCount[attr] = '';
      }
    }
  };

  const getSelectedContact = (contactName?: string) =>
    allContacts.find((contact) => contact.Name === contactName);

  const handleSelect = (value) => {
    const selectedContact = getSelectedContact(value);
    addMissingAttributes(selectedContact);
    onSelect && onSelect(selectedContact);
    setIsSearch(false);
  };

  const handleChange = (value) => {
    setName(value);
    const selectedContact = getSelectedContact(value);
    onChange && onChange(selectedContact, value);
  };

  const handleBlur = () => {
    setIsSearch(false);
  };

  return (
    <div className={styles.autoCompleteDropdown}>
      <div className={styles.fieldWrapper}>
        <InputLabel label={'Name'} className={styles.label} />
      </div>
      <AutoComplete
        disabled={disabled}
        value={name}
        onChange={handleChange}
        backfill
        open={isSearch}
        popupClassName={styles.autoCompleteDropdown}
        placeholder={placeholder}
        filterOption={(inputValue, option) =>
          caseInsensitiveFilter(inputValue, option?.label as string)
        }
        onSearch={(val) => setIsSearch(!!val.trim())}
        onSelect={handleSelect}
        onBlur={handleBlur}
        className={styles.contactAutoComplete}
      >
        {allContacts?.map((contact, idx) => (
          <Option key={idx} label={contact.Name} value={contact.Name}>
            <div className={styles.autoCompleteOption}>
              <Avatar
                avatarClassName={styles.avatar}
                placeholder={
                  <div className={styles.avatarPlaceholder}>
                    {convertNameToAvatarPlaceholder(`${contact.Name}`)}
                  </div>
                }
              />
              <div className={styles.autoCompleteOption}>
                <div>
                  <div className={styles.name}>{`${contact.Name}`}</div>
                  <div className={styles.email}>{contact.Email}</div>
                </div>
              </div>
            </div>
          </Option>
        ))}
      </AutoComplete>
      {error && <div style={{ color: '#ec455e', fontSize: '0.875rem' }}>{error}</div>}
    </div>
  );
};

export default ContactAutoComplete;
