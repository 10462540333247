import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ adminPanel }) => adminPanel;
const templatesState = createSelector(
  localState,
  ({ notificationTemplates }) => notificationTemplates,
);
const templateState = createSelector(
  localState,
  ({ notificationTemplate }) => notificationTemplate,
);

export const getNotificationTemplatesDashboardSelector = createSelector(
  templatesState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getNotificationTemplatesDataDashboardSelector = createSelector(
  templatesState,
  (state) => get(state, 'data'),
);

export const getNotificationTemplateDashboardSelector = createSelector(
  templateState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getNotificationTemplateDataDashboardSelector = createSelector(templateState, (state) =>
  get(state, 'data'),
);
