import { useMemo, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash-es';

import { Breadcrumbs } from 'components';
import { routes } from 'settings/navigation/routes';
import {
  PropertyAddress,
  PurchasePrice,
  Participants,
  EnableShowing,
  CreatorRole,
} from '../TransactionCreate/components/PreForm/Questions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import {
  createTransactionEffect,
  getTransactionRolesEffect,
  resetTransactionCreateEffect,
  setTransactionPreFormQuestionsEffect,
} from 'store/effects/transactions';
import { ShowingModal } from 'pages/Workshop/Transactions/TransactionShowings/ShowingModal';
import { getTransactionSelector, getTransactionAccessSelector } from 'store/selectors/transaction';
import { resetTransactionAction } from 'store/actions/transactions';
import { Controls } from './Controls';
import { EnableOffer } from '../TransactionCreate/components/PreForm/Questions/EnableOffer';
import { OfferModal } from '../TransactionOffers/OfferModal';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { useBackPage } from 'hooks';

import styles from './styles.module.scss';

export const BasicPlanCreate = (props) => {
  const { match } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [stage, setStage] = useState(transactionPreFormQuestionsIds.address);
  const [enableShowing, setEnableShowing] = useState(null);
  const [enableOffer, setEnableOffer] = useState(null);
  const [showingModal, setShowingModal] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [onBack] = useBackPage({ defaultBackUrl: routes.transactions });

  const stayOnPageOrOpenEmailVerificationModal = useEmailVerificationWall(
    ActionToInterrupt.CREATE_SHOWING,
    () => {},
  );

  const { Flow } = match.params;

  useEffect(() => {
    stayOnPageOrOpenEmailVerificationModal();
  });

  const breadcrumbsList = useMemo(
    () => [
      { label: 'Transactions', link: routes.transactions },
      { label: 'New seller transaction', link: location.pathname },
    ],
    [location.pathname],
  );

  const onSubmit = () => {
    dispatch(
      createTransactionEffect({}, {}, (err) => {
        if (!err) setStage(transactionPreFormQuestionsIds[Flow]);
      }),
    );
  };

  const onClose = (redirect = true) => {
    dispatch(resetTransactionCreateEffect());
    if (redirect) onBack();
  };

  const isShowingEnabled = () => {
    if (enableShowing === 'yes') {
      setStage(transactionPreFormQuestionsIds.showingModal);
      setShowingModal(true);
    } else {
      onClose();
    }
  };

  const isOfferEnabled = () => {
    if (enableOffer === 'yes') {
      setStage(transactionPreFormQuestionsIds.offerModal);
      setOfferModal(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    dispatch(resetTransactionAction());
    dispatch(getTransactionRolesEffect());
    dispatch(
      setTransactionPreFormQuestionsEffect({
        whoAreYouRepresenting: {
          Seller: true,
        },
      }),
    );
    return () => onClose(false);
  }, []);

  const renderStage = () => {
    switch (stage) {
      case transactionPreFormQuestionsIds.purchasePrice:
        return (
          <PurchasePrice
            currentQuestionId={transactionPreFormQuestionsIds.purchasePrice}
            onNext={() => setStage(transactionPreFormQuestionsIds.creatorRole)}
          />
        );
      case transactionPreFormQuestionsIds.creatorRole:
        return (
          <CreatorRole
            className={''}
            currentQuestionId={transactionPreFormQuestionsIds.creatorRole}
            onNext={() => setStage(transactionPreFormQuestionsIds.participants)}
          />
        );
      case transactionPreFormQuestionsIds.participants:
        return <Participants onPreFormSubmit={onSubmit} isBasicPlan={true} />;

      case transactionPreFormQuestionsIds.enableShowing:
        return (
          <EnableShowing
            enable={enableShowing}
            onChange={setEnableShowing}
            onNext={isShowingEnabled}
          />
        );
      case transactionPreFormQuestionsIds.showingModal:
        return (
          <ShowingModal
            isOpen={showingModal}
            onClose={() => {
              setShowingModal(false);
              onClose();
            }}
            transaction={transaction}
            fullAccess={fullAccess}
          />
        );
      case transactionPreFormQuestionsIds.enableOffer:
        return (
          <EnableOffer enable={enableOffer} onChange={setEnableOffer} onNext={isOfferEnabled} />
        );
      case transactionPreFormQuestionsIds.offerModal:
        return (
          <OfferModal
            isOpen={offerModal}
            onClose={() => {
              setOfferModal(false);
              onClose();
            }}
            transaction={transaction}
            fullAccess={fullAccess}
          />
        );
      default:
        return (
          <PropertyAddress
            currentQuestionId={transactionPreFormQuestionsIds.address}
            onNext={() => setStage(transactionPreFormQuestionsIds.purchasePrice)}
          />
        );
    }
  };

  return (
    <div className={styles.basicPlanCreate}>
      <div className={styles.head}>
        <Breadcrumbs list={breadcrumbsList} />
        <div className={styles.actions}>
          <Controls
            onClose={onClose}
            stage={stage}
            setStage={setStage}
            transaction={transaction}
            enableShowing={enableShowing}
            setShowingModal={setShowingModal}
            enableOffer={enableOffer}
            setOfferModal={setOfferModal}
          />
        </div>
      </div>
      <div className={styles.questionContainer}>{renderStage()}</div>
    </div>
  );
};
