export default {
  transactions: '/workshop/transactions',
  transactionCreate: '/workshop/transactions/create',
  transactionClose: '/workshop/transactions/:id/close',
  transactionPostCreate: '/workshop/transactions/post-create',
  transactionOverview: '/workshop/transactions/:id/overview',
  transactionDetails: '/workshop/transactions/:id/details',
  transactionFinancials: '/workshop/transactions/:id/financials',
  transactionShowings: '/workshop/transactions/:id/showings/:uuid?',
  transactionOffers: '/workshop/transactions/:id/offers/:uuid?',
  transactionTasks: '/workshop/transactions/:id/tasks',
  transactionMessages: '/workshop/transactions/:id/messages',
  transactionDocuments: '/workshop/transactions/:id/documents',
  transactionActivity: '/workshop/transactions/:id/activity',
  transactionBasicPlanCreate: '/workshop/transactions/basic/create',
  transactionWithFlowBasicPlanCreate: '/workshop/transactions/basic/create/:Flow',
  transactionCancellation: '/workshop/transactions/:id/cancellation',
  transactionUnderContract: '/workshop/transactions/:id/undercontract',
  workShopShowings: '/workshop/showings',
};
