import { Title, Modal, ModalProps, Button } from 'components-antd';
import List from 'antd/lib/list';
import { WarningFilled } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';

import styles from './styles.module.scss';

export interface ErrorModalTextProps {
  errorText?: string;
  errorPoints?: string[];
}

interface ErrorModalProps extends ModalProps {
  error?: ErrorModalTextProps;
}

export const ErrorModal = ({ error, onCancel, ...props }: ErrorModalProps) => (
  <Modal
    data-testid="error-modal"
    className={styles.errorModal}
    title={
      <span className={styles.headerTitle}>
        <WarningFilled className={styles.errorIcon} />
        <span className={styles.headerText}>Error</span>
      </span>
    }
    footer={
      <>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
      </>
    }
    onCancel={onCancel}
    {...props}
  >
    {error?.errorText ? <Title level={4}>{error?.errorText}</Title> : null}
    {error?.errorPoints?.length ? (
      <List
        dataSource={error?.errorPoints}
        renderItem={(item) => (
          <List.Item>
            <Text>{item}</Text>
          </List.Item>
        )}
        className={styles.listItems}
      />
    ) : null}
  </Modal>
);
