import { Tooltip } from 'components-antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getUserSelector } from 'store/selectors/user';
import { useSelector } from 'react-redux';

interface CompareofferTooltipProps {
  Buyer: any;
}
export const CompareOfferTooltip = ({ Buyer }: CompareofferTooltipProps) => {
  const loggedInUser = useSelector(getUserSelector);
  const renderAgentNames = (agents) => {
    if (agents?.length > 0) {
      return agents.map((item: string) => {
        return ' ' + item + ' ';
      });
    }
  };

  return (
    <Tooltip
      placement="top"
      title={
        <>
          <div>Buyer:{renderAgentNames(Buyer)}</div>
          <div>Seller: {loggedInUser?.data?.FirstName}</div>
        </>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );
};
