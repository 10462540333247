import PropTypes from 'prop-types';

import { Modal } from 'components-antd';
import { FormTask } from './FormTask';

import styles from './styles.module.scss';

export const FormTaskModal = (props) => {
  const {
    isOpen,
    onCancelModal,
    onClose,
    isNew,
    showSubheading,
    isTemplate,
    editData,
    templateId,
    isAssignTask,
    assignTask,
    isAggregatedTasks,
    isViewMode,
    title,
    data,
    ...taskProps
  } = props;

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onCancelModal}
      maskClosable={false}
      destroyOnClose
      className={styles.taskModal}
    >
      <FormTask
        isOpen={isOpen}
        isNew={isNew}
        showSubheading={showSubheading}
        isTemplate={isTemplate}
        editData={editData}
        templateId={templateId}
        isAssignTask={isAssignTask}
        assignTask={assignTask}
        onClose={onClose}
        isAggregatedTasks={isAggregatedTasks}
        isViewMode={isViewMode}
        title={title}
        data={data}
        {...taskProps}
      />
    </Modal>
  );
};

FormTaskModal.propTypes = {
  onClose: PropTypes.func,
  onCancelModal: PropTypes.func,
  isOpen: PropTypes.bool,
  isNew: PropTypes.bool,
  isViewMode: PropTypes.bool,
  isTemplate: PropTypes.bool,
  templateId: PropTypes.number,
  editData: PropTypes.object,
  isAggregatedTasks: PropTypes.bool,
  isTransactionTask: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  taskLength: PropTypes.number,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  data: PropTypes.array,
};

FormTaskModal.defaultProps = {
  onClose: () => {},
  onCancelModal: () => {},
  isOpen: false,
  isNew: false,
  isViewMode: false,
  isTemplate: false,
  templateId: null,
  editData: null,
  isAggregatedTasks: false,
  onSubmit: () => {},
  title: 'Add Form',
  data: [],
};
