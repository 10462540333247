import React from 'react';
import { useSelector } from 'react-redux';
import Expand from '../../../../../components/Icons/Expand';
import { getListingDetailMapData } from 'store/selectors/listingDetail';
import { Map } from 'components';
import styles from './styles.module.scss';

const ListingDetailMap = ({ openModal, setTab }) => {
  const { zoom, center, markers } = useSelector(getListingDetailMapData);

  return (
    <div className={styles.mapContainer}>
      <Map
        markersMap={markers}
        className={styles.map}
        controlsClassName={styles.mapControls}
        zoom={zoom}
        center={center}
        controls={{
          zoom: false,
        }}
      />
      <Expand
        className={styles.mapContainerExpand}
        onClick={() => {
          openModal();
          setTab('Map');
        }}
      />
    </div>
  );
};

export default ListingDetailMap;
