import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers/formatters';

import styles from './styles.module.scss';
import moment from 'moment';
import { AuditLogItemContent } from 'components-antd/AuditLogCard/AuditLogItemContent';
import classNames from 'classnames';

export const Activity = ({ data, className, auditItemClass }) => {
  const FirstName = data?.Payload?.SubmittedByName
    ? data?.Payload?.SubmittedByName
    : data?.UserFirstName
    ? data?.UserFirstName
    : data.Payload?.SentBy?.FirstName ||
      data.Payload?.SentBy?.BuyerName ||
      data.Payload?.BuyerName ||
      data.Payload?.SubmittedByName ||
      '';

  const LastName = data?.Payload?.SubmittedByName
    ? data?.Payload?.SubmittedByName
    : data?.UserLastName
    ? data?.UserLastName
    : data.Payload?.SentBy?.LastName || '';

  return (
    <div className={classNames(styles.card, className)}>
      <Avatar
        src={data?.AvatarUrl}
        placeholder={convertNameToAvatarPlaceholder(`${FirstName} ${LastName} `)}
      />
      <AuditLogItemContent
        itemContent={data}
        className={auditItemClass}
        expandableWrapperClass={styles.expandableWrapperLog}
      />

      <p className={styles.timestamp}>
        {moment(data.ActionTimestamp).format('MM/DD')} at{' '}
        {moment(data.ActionTimestamp).format('hh:mm A')}
      </p>
    </div>
  );
};

Activity.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  auditItemClass: PropTypes.string,
};

Activity.defaultProps = {
  data: {},
  className: '',
  auditItemClass: '',
};
