import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ templates: { timeline } }) => timeline;

export const getTimelineTemplatesSelector = createSelector(
  localState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTimelineTemplatesDataSelector = createSelector(localState, (state) =>
  get(state, 'data'),
);
