import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import Close from './Close';

const Modal = (props) => {
  const {
    isOpen,
    children,
    className,
    opacityLayerClassName,
    testid,
    closeClassName,
    label,
    headerClassName,
  } = props;
  const { contentClassName, innerHolderClassName, onClose } = props;

  return createPortal(
    <div
      testid={testid}
      className={classNames(styles.modal, { [styles.isOpen]: isOpen }, className)}
    >
      <div onClick={onClose} className={classNames(styles.opacityLayer, opacityLayerClassName)} />
      <div className={classNames(styles.contentWrapper)}>
        <div className={classNames(styles.content, contentClassName)}>
          <Close
            onClose={onClose}
            className={closeClassName}
            label={label}
            headerClassName={headerClassName}
          />
          <div className={classNames(styles.innerHolder, innerHolderClassName)}>{children}</div>
        </div>
      </div>
    </div>,
    document.body,
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  innerHolderClassName: PropTypes.string,
  opacityLayerClassName: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onClose: PropTypes.func,
  testid: PropTypes.string,
  label: PropTypes.string,
  headerClassName: PropTypes.string,
};

Modal.defaultProps = {
  headerClassName: '',
  className: '',
  closeClassName: '',
  contentClassName: '',
  innerHolderClassName: '',
  opacityLayerClassName: '',
  onClose: () => {},
  testid: undefined,
  label: '',
};

export default Modal;
