import React, { memo, useMemo } from 'react';

interface IArrowDown {
  className?: string;
  color?: string;
  size?: number;
}

const ArrowDown = memo(({ className, color = '#262626', size }: IArrowDown) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 6L8 10.5L12.5 6"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className, color, size],
  ),
);

export default ArrowDown;
