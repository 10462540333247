import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { FORM_MODE, FORM_QUESTION_TYPE } from 'app-constants';
import { DateType, QuestionType, ResponseType } from 'types';
import { getQuoteFormSelector } from 'store/selectors/requestQuote';
import { saveQuoteFormResponseEffect, updateQuoteFormResponseEffect } from 'store/effects/quotes';

import {
  DateQuestion,
  EmailQuestion,
  FreeTextQuestion,
  ListBoxQuestion,
  NumberQuestion,
  PhoneNumberQuestion,
  SignatureQuestion,
  UploadQuestion,
  YesNoQuestion,
} from 'pages/FormBuilder/components';
import EditActions from '../EditActions';

import styles from './styles.module.scss';
import { getQuoteFormMetaSelect } from 'store/selectors/formBuilder';

interface EditQuestionsProps {
  className: string;
  questionIndex: number;
  formQuestions: QuestionType;
  onCancel: () => void;
  onSave: () => void;
}

const EditQuestions = ({
  className,
  questionIndex,
  formQuestions,
  onCancel,
  onSave,
}: EditQuestionsProps) => {
  const dispatch = useDispatch();

  const { disabled } = useSelector(getQuoteFormMetaSelect);
  const quoteForm = useSelector(getQuoteFormSelector);
  const formQuestion = formQuestions[questionIndex];
  const FormResponses = quoteForm?.FormResponses || [];
  const formResponse = FormResponses?.find(
    (formResponse: ResponseType) => formResponse?.UUID === formQuestion?.UUID,
  );

  const [isPending, setIsPending] = useState(false);
  const onCancelHandler = (event) => {
    event.stopPropagation();
    onCancel();
  };

  const handleFormResponse = (response: Partial<ResponseType>) => {
    dispatch(updateQuoteFormResponseEffect({ ...formResponse, ...response }));
  };

  const onSaveHandler = () => {
    setIsPending(true);
    if (quoteForm?.quoteRequestDraftId && FormResponses?.length) {
      dispatch(
        saveQuoteFormResponseEffect(
          {
            id: quoteForm.quoteRequestDraftId,
            responseData: FormResponses,
          },
          {},
          (err) => {
            if (!err) {
              onSave();
            }
            setIsPending(false);
          },
        ),
      );
    }
  };

  const getQuestionContainer = {
    [FORM_QUESTION_TYPE.FreeText]: (
      <FreeTextQuestion
        mode={FORM_MODE.View}
        formResponse={formResponse}
        handleFormResponse={handleFormResponse}
      />
    ),
    [FORM_QUESTION_TYPE.YesNo]: (
      <YesNoQuestion
        mode={FORM_MODE.View}
        formResponse={formResponse}
        handleFormResponse={handleFormResponse}
      />
    ),
    [FORM_QUESTION_TYPE.Currency]: (
      <NumberQuestion
        mode={FORM_MODE.View}
        formResponse={formResponse}
        handleFormResponse={handleFormResponse}
        prefix="$"
      />
    ),
    [FORM_QUESTION_TYPE.Date]: (
      <DateQuestion
        mode={FORM_MODE.View}
        rangePicker={(formQuestion?.Meta as DateType)?.DateRange}
        formResponse={formResponse}
        handleFormResponse={handleFormResponse}
      />
    ),
    [FORM_QUESTION_TYPE.Email]: (
      <EmailQuestion
        mode={FORM_MODE.View}
        formResponse={formResponse}
        handleFormResponse={handleFormResponse}
      />
    ),
    [FORM_QUESTION_TYPE.Number]: (
      <NumberQuestion
        mode={FORM_MODE.View}
        formResponse={formResponse}
        handleFormResponse={handleFormResponse}
      />
    ),
    [FORM_QUESTION_TYPE.PhoneNumber]: (
      <PhoneNumberQuestion
        mode={FORM_MODE.View}
        formResponse={formResponse}
        handleFormResponse={handleFormResponse}
      />
    ),
    [FORM_QUESTION_TYPE.FileUpload]: (
      <UploadQuestion
        mode={FORM_MODE.View}
        formResponse={formResponse}
        handleFormResponse={handleFormResponse}
        responseMode={true}
      />
    ),
    [FORM_QUESTION_TYPE.ListBox]: (
      <ListBoxQuestion
        mode={FORM_MODE.View}
        disabled={disabled}
        smartForm={false}
        formQuestion={formQuestion}
        formResponse={formResponse}
        handleFormResponse={handleFormResponse}
      />
    ),
    [FORM_QUESTION_TYPE.Signature]: (
      <SignatureQuestion
        formQuestion={formQuestion}
        formResponse={formResponse}
        handleFormResponse={handleFormResponse}
        formWidth={400}
        formHeight={400}
      />
    ),
  };

  return (
    <div className={classNames(styles.editQuestions, className)}>
      {getQuestionContainer[formQuestion?.Type]}
      <EditActions onSave={onSaveHandler} onCancel={onCancelHandler} isPending={isPending} />
    </div>
  );
};

export default EditQuestions;
