import React, { Fragment, useRef, useState } from 'react';
import { Input } from '../../../../../../components';

import { Navigation } from '../../components/Footer';
import { EditContactInformation } from '../components';
import { ErrorMessage } from '../../components/ErrorMessages';

import styles from './styles.module.scss';
import { CardBox } from 'pages/ExternalOffer/OfferSubmission/components/CardBox';
import { IconText } from '../components/IconText';
import { Icons } from '../../../components/Icons';
import { ScrollToTop } from 'pages/ExternalOffer/OfferSubmission/components/ScrollToTop';
import { getResetState, initialErrorState, isValidAplhabets } from './helper';
import { Breakpoint1 } from './Breakpoint1';
import { Breakpoint2 } from './Breakpoint2';
import { Breakpoint3 } from './Breakpoint3';
import { Breakpoint4 } from './Breakpoint4';

export const Highlights = ({
  onNext,
  stageIndex,
  onUpdate,
  onFinish,
  setCurrentStageIndex,
  data,
  loading,
  editModal,
  stagesStep,
  lastStageIndex,
}) => {
  const contingenciesRef = useRef<HTMLDivElement | null>(null);

  const {
    Buyer,
    Buyer2,
    breakpoint,
    OfferIteration: {
      Contingencies,
      TermiteInspection,
      HomeWarranty,
      Occupancy,
      ...restOfferIteration
    },
  } = data;

  const [state, setState] = useState({
    Buyer,
    Buyer2,
    OfferIteration: {
      ...restOfferIteration,
      TermiteInspection: TermiteInspection || false,
      HomeWarranty: HomeWarranty || false,
      Occupancy: Occupancy || false,
      Contingencies: {
        Inspection: {
          days: Contingencies?.Inspection?.days,
          selected: Contingencies?.Inspection?.selected,
        },
        Appraisal: {
          days: Contingencies?.Appraisal?.days,
          selected: Contingencies?.Appraisal?.selected,
        },
        Financing: {
          days: Contingencies?.Financing?.days,
          selected: Contingencies?.Financing?.selected,
        },
      },
    },
  });

  const [showAll, setShowAll] = useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] = useState(editModal ? 4 : breakpoint || 1);

  const [newBuyer, setNewBuyer] = useState(Boolean(Buyer2) || false);

  // Appraisal, Financing, Inspection
  const [appraisalError, setAppraisalError] = useState(false);
  const [financingError, setFinancingError] = useState(false);
  const [inspectionError, setInspectionError] = useState(false);

  const onChange = (data) => {
    setState({ ...state, ...data });
  };

  const [validationErrorMessages, setValidationErrors] = useState(initialErrorState);

  const toggleNewBuyer = () => {
    setNewBuyer((prev) => !prev);
  };

  const onSubmit = () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    if (currentBreakpoint === 4) {
      const {
        OfferIteration: { HomeWarranty, DownPayment, EarnestMoneyDeposit },
      } = state;

      if (!HomeWarranty) {
        state.OfferIteration.HomeWarrantyCost = null;
      }

      state.OfferIteration.DownPayment = !DownPayment ? null : DownPayment;
      state.OfferIteration.EarnestMoneyDeposit = !EarnestMoneyDeposit ? null : EarnestMoneyDeposit;

      const body = {
        ...state,
        breakpoint: currentBreakpoint,
        showAll,
      };

      if (data.Id) {
        onUpdate(body);
        onFinish(body);
      } else {
        onUpdate(body);
        onNext();
      }
    } else {
      const body = {
        ...state,
        breakpoint: currentBreakpoint + 1,
        showAll: false,
      };
      setShowAll(false);
      setCurrentBreakpoint((prev) => prev + 1);

      onUpdate(body);
    }
  };

  const validateForm = () => {
    let valid = true;
    let errorMessages = { ...validationErrorMessages };
    const { OfferIteration } = state;

    if (currentBreakpoint >= 1) {
      if (!state.Buyer || !state.Buyer[0] || !state.Buyer[0].trim()) {
        errorMessages.BuyerNameError = '*Buyer name is required';
        valid = false;
      }
      if (valid && !isValidAplhabets(state.Buyer[0])) {
        errorMessages.BuyerNameError = '*Buyer name must contain only alphabets!';
        valid = false;
      }
      if (newBuyer && (!state.Buyer2 || !state.Buyer2[0] || !state.Buyer2[0].trim())) {
        errorMessages.Buyer2NameError = '*Buyer 2 name is required!';
        valid = false;
      }
      if (
        newBuyer &&
        state.Buyer2 &&
        state.Buyer2[0] &&
        state.Buyer2[0].trim() &&
        !isValidAplhabets(state.Buyer2[0])
      ) {
        errorMessages.Buyer2NameError = '*Buyer 2 name must contain only alphabets!';
        valid = false;
      }
      if (!OfferIteration.PurchasePrice) {
        errorMessages.PurchasePriceError = '*Purchase price is required!';
        valid = false;
      }
      if (!OfferIteration.ClosingDate) {
        errorMessages.ClosingDateError = '*Closing date is required!';
        valid = false;
      }
      if (!OfferIteration.FinancingType) {
        errorMessages.FinancingTypeError = '*Financing type is required!';
        valid = false;
      }

      if (currentBreakpoint === 1) {
        !valid && setValidationErrors(errorMessages);

        return valid;
      }
    }

    if (currentBreakpoint >= 2) {
      let isContigenciesValid = validateContigencies();
      valid = valid && Boolean(isContigenciesValid);

      if (currentBreakpoint === 2) {
        !valid && setValidationErrors(errorMessages);
        return valid;
      }
    }

    if (currentBreakpoint >= 3) {
      if (OfferIteration.TermiteInspection && !OfferIteration.TermiteInspectionResponsibility) {
        errorMessages.TermiteInspectionError = '*Select responsible party!';
        valid = false;
      }

      if (OfferIteration.HomeWarranty && !OfferIteration.HomeWarrantyCost) {
        errorMessages.HomeWarrantyCostError = '*Enter cost!';
        valid = false;
      }
      if (OfferIteration.HomeWarranty && !OfferIteration.HomeWarrantyResponsibility) {
        errorMessages.HomeWarrantyResponsibleError = '*Select responsible party!';
        valid = false;
      }

      if (OfferIteration.Occupancy && !OfferIteration.OccupancyDays) {
        errorMessages.OccupancyError = '*Enter days!';
        valid = false;
      }

      if (currentBreakpoint === 3) {
        !valid && setValidationErrors(errorMessages);

        return valid;
      }
    }

    !valid && setValidationErrors(errorMessages);

    return valid;
  };

  const validateContigencies = () => {
    let isContigenciesValid = true;

    const { Appraisal, Financing, Inspection } = state?.OfferIteration?.Contingencies || {};
    if (Inspection?.selected && !Inspection?.days) {
      setInspectionError(true);
      isContigenciesValid = false;
      if (contingenciesRef.current) {
        contingenciesRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return;
      }
    }

    if (Appraisal?.selected && !Appraisal?.days) {
      setAppraisalError(true);
      isContigenciesValid = false;
      if (contingenciesRef.current) {
        contingenciesRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return;
      }
    }

    if (Financing?.selected && !Financing?.days) {
      setFinancingError(true);
      isContigenciesValid = false;
      if (contingenciesRef.current) {
        contingenciesRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return;
      }
    }

    return isContigenciesValid;
  };

  const removeBuyer = () => {
    toggleNewBuyer();
    setState({ ...state, Buyer2: '' });
    setValidationErrors((prev) => ({ ...prev, Buyer2NameError: '' }));
  };

  const onReset = () => {
    const resetState = getResetState(state, currentBreakpoint, showAll);
    setState(resetState);
    setValidationErrors(initialErrorState);
  };

  const renderField = (fieldProps) => {
    const {
      name,
      value,
      placeholder,
      errorKey,
      id,
      testId,
      onChange,
      customOnChange,
      ...otherProps
    } = fieldProps;

    return (
      <Fragment>
        <Input
          className={styles.input}
          placeholder={placeholder}
          inputHolderClassName={styles.inputHolder}
          variant={Input.LIGHT_ROUND}
          value={value}
          maxLength={250}
          onChange={(e) => {
            if (customOnChange) {
              customOnChange(e.target.value);
            } else {
              setValidationErrors({
                ...validationErrorMessages,
                [errorKey]: '',
              });
              onChange({ ...state, [name]: [e.target.value] });
            }
          }}
          name={name}
          id={name}
          testid={testId}
          {...otherProps}
        />
        {errorKey ? <ErrorMessage id={id} message={validationErrorMessages[errorKey]} /> : null}
      </Fragment>
    );
  };

  const getShowAllButton = () => {
    const fieldCount = {
      2: 6 + Number(newBuyer),
      3: 7 + Number(newBuyer),
      4: 11 + Number(newBuyer),
    };
    const fieldsCount = fieldCount[currentBreakpoint] || 0;

    if (!fieldsCount || showAll) return null;

    return (
      <div className={styles.showAllContainer} onClick={() => setShowAll(true)}>
        <Icons variant={'plus'} className={styles.showAllIcon} />
        <div className={styles.showAllText}>
          Show All <span className={styles.fieldsCount}>{` (${fieldsCount})`}</span>
        </div>
      </div>
    );
  };

  return (
    <ScrollToTop>
      <div className={styles.highlights}>
        <EditContactInformation
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.ContactInformation)}
        />

        <CardBox className={styles.highlightsBox}>
          <IconText variant={'highlights'} text={'Highlights'} className={styles.headerIcon} />

          <div className={styles.content}>
            {showAll || currentBreakpoint === 1 ? (
              <Breakpoint1
                state={state}
                newBuyer={newBuyer}
                toggleNewBuyer={toggleNewBuyer}
                setErrors={setValidationErrors}
                errorMessages={validationErrorMessages}
                onChange={onChange}
                removeBuyer={removeBuyer}
                renderField={renderField}
                showAll={showAll}
              />
            ) : currentBreakpoint === 2 ? (
              getShowAllButton()
            ) : null}

            {/** Contingencies*/}

            {currentBreakpoint === 2 || (currentBreakpoint > 2 && showAll) ? (
              <Breakpoint2
                state={state}
                onChange={onChange}
                contingenciesRef={contingenciesRef}
                currentBreakpoint={currentBreakpoint}
                appraisalError={appraisalError}
                financingError={financingError}
                inspectionError={inspectionError}
                setAppraisalError={setAppraisalError}
                setFinancingError={setFinancingError}
                setInspectionError={setInspectionError}
              />
            ) : currentBreakpoint === 3 ? (
              getShowAllButton()
            ) : null}

            {/** Termite Inspection*/}

            {currentBreakpoint === 3 || (currentBreakpoint > 3 && showAll) ? (
              <Breakpoint3
                state={state}
                setErrors={setValidationErrors}
                errorMessages={validationErrorMessages}
                onChange={onChange}
                setState={setState}
                renderField={renderField}
                currentBreakpoint={currentBreakpoint}
              />
            ) : currentBreakpoint === 4 ? (
              getShowAllButton()
            ) : null}

            {currentBreakpoint === 4 ? <Breakpoint4 state={state} onChange={onChange} /> : null}
          </div>

          <Navigation
            data={data}
            onNext={onSubmit}
            edit={editModal}
            isLast={lastStageIndex === stageIndex}
            onFinish={onSubmit}
            disabled={false}
            isPending={loading}
            onReset={onReset}
          />
        </CardBox>
      </div>
    </ScrollToTop>
  );
};
