import { Button, DeleteModal } from 'components-antd';

import { ICON_VARIANT_TYPE, Icons } from 'pages/Workshop/Forms/Icons';
import styles from './styles.module.scss';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDraftFormEffect, updateAllFormProcessEffect } from 'store/effects/formProcess';
import { getAllFormProcessesSelector } from 'store/selectors/requestFormProcess';

export const DeleteDraftForm = (props) => {
  const { formName, formProcessId, onDeleteFormSuccess } = props;
  // const [loading, setLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { draftForms, ...restAllFormProcess } = useSelector(getAllFormProcessesSelector);

  const dispatch = useDispatch();

  const onRemove = (setLoading) => {
    if (formProcessId) {
      setLoading(true);
      dispatch(
        deleteDraftFormEffect({ formProcessId }, () => {
          const updatedDraftList = draftForms.filter(
            (item) => item.formProcessId !== formProcessId,
          );

          const details = {
            data: {
              value: {
                ...restAllFormProcess,
                draftForms: updatedDraftList,
              },
            },
          };

          dispatch(updateAllFormProcessEffect(details));
          setConfirmationOpen(false);
          onDeleteFormSuccess();
        }),
      );
    }
  };

  return (
    <Fragment>
      <DeleteModal
        open={confirmationOpen}
        onCancel={() => {
          setConfirmationOpen(false);
        }}
        titleText={
          <div>
            Are you sure you want to delete
            <br />
            {`"${formName}"`}
          </div>
        }
        closable={false}
        maskClosable={false}
        onOk={onRemove}
        width={450}
      />

      <Button
        variant="default"
        className={styles.deleteButton}
        onClick={() => setConfirmationOpen(true)}
      >
        <div className={styles.deleteButtonContainer}>
          <Icons variant={ICON_VARIANT_TYPE.DELETE_TRASH} className={styles.editIcon} />
          <div>Delete</div>
        </div>
      </Button>
    </Fragment>
  );
};
