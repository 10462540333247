import React from 'react';
import styles from './styles.module.scss';
import { Delete, Book, Messages, Share, Heart } from 'components/Icons';
import { routes } from 'settings/navigation/routes';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { Spinner } from 'components';
import classNames from 'classnames';
import Icons from '../Icons';

type MenuPropsType = {
  propertyId: string;
  menuRef: any;
  setShowMenu: Function;
  removePropertyHandler: Function;
  currentPropertyCount: number;
  commentOnProperty: Function;
  shareProperty: Function;
  setFavoriteStatus: Function;
  favoriteStatus: boolean;
  favoriteFetchStatus: string;
};

function Menu(props: MenuPropsType) {
  const history = useHistory();
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  let listingDetailsRoute = routes.feedListingDetail.replace(':id', `${props.propertyId}`);

  if (isClient && props.favoriteFetchStatus != 'ready') {
    return (
      <div
        ref={props.menuRef}
        className={classNames(styles.propertyMenu, styles.propertyMenuLoader)}
      >
        <Spinner loaderClassName={classNames(styles.loader)} />
      </div>
    );
  } else
    return (
      <div ref={props.menuRef} className={styles.propertyMenu}>
        {props.currentPropertyCount > 2 ? (
          <div
            className={styles.menuItem}
            onClick={(e) => {
              props.removePropertyHandler(props.propertyId);
              props.setShowMenu(false);
            }}
          >
            <div className={styles.icon}>
              <Icons iconName="delete" />
            </div>
            <p className={styles.menuItemText}>Remove</p>
          </div>
        ) : (
          <></>
        )}
        <div
          className={styles.menuItem}
          onClick={(e) => {
            props.setShowMenu(false);
            props.commentOnProperty(props.propertyId);
          }}
        >
          <div className={styles.icon}>
            <Icons iconName="comment" />
          </div>
          <p className={styles.menuItemText}>Comment</p>
        </div>
        {isAgent ? (
          <div
            className={styles.menuItem}
            onClick={(e) => {
              props.setShowMenu(false);
              props.shareProperty(props.propertyId);
            }}
          >
            <div className={styles.icon}>
              <Icons iconName="star-red" />
            </div>
            <p className={styles.menuItemText}>Highlight for Client(s)</p>
          </div>
        ) : (
          <></>
        )}
        {isClient ? (
          <div
            className={styles.menuItem}
            onClick={(e) => {
              props.setShowMenu(false);
              props.setFavoriteStatus(props.propertyId, !props.favoriteStatus);
            }}
          >
            <Heart
              color={'#FF576D'}
              className={classNames(styles.icon, props.favoriteStatus ? styles.favorite : '')}
              variant={Heart.HOLLOW}
            />
            <p
              className={styles.menuItemText}
              style={{ width: props.favoriteStatus ? '160px' : '110px' }}
            >
              {props.favoriteStatus ? 'Remove from Favorites' : 'Add to Favorites'}
            </p>
          </div>
        ) : (
          <></>
        )}
        <div
          className={styles.menuItem}
          onClick={(e) => {
            props.setShowMenu(false);
            history.push({
              pathname: listingDetailsRoute,
              state: {
                backUrl: `${location.pathname}${location.search}`,
              },
            });
          }}
        >
          <div className={styles.icon}>
            <Icons iconName="book" />
          </div>
          <p className={styles.menuItemText}>Go to Listing</p>
        </div>
      </div>
    );
}

export default Menu;
