import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Info = ({ text, className }) => (
  <div className={classNames(styles.infoWrapper, className)}>{text}</div>
);

Info.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Info.defaultProps = {
  className: '',
  text: '',
};

export default Info;
