import React, { memo, useMemo } from 'react';

const CreateNew = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div {...props}>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7 12L9 10L11 12V18H7V12Z" fill="#163C5A" />
          <path d="M6 7L8 9L6 11L-1.74846e-07 11L0 7L6 7Z" fill="#219653" />
          <path d="M7 6L9 8L11 6V0H7V6Z" fill="#2F80ED" />
          <path d="M12 7L10 9L12 11L18 11L18 7L12 7Z" fill="#E66F3E" />
        </svg>
      </div>
    ),
    [props.className],
  );

export default memo(CreateNew);
