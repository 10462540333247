import { createSelector } from 'reselect';
import { ERROR, IDLE, PENDING } from 'settings/constants/apiState';
import { getUserId } from '../user';
import moment from 'moment';
import { TeamFilter, TourStatus } from 'app-constants/enums/tours';
import { getTeamTransactionsSelector } from '../transactions/read';
import { TourState } from 'types/tours';
import { orderBy } from 'lodash';

const localState: any = ({ tour }) => tour;

export const getScheduledTours = createSelector(localState, (currentState: TourState) => {
  const tours = currentState?.data;
  return {
    tours,
    isIdle: currentState?.state === IDLE,
    isPending: currentState?.state === PENDING,
    isData: !!tours?.length,
  };
});
export const getGroupedToursByClient = (status: TourStatus) =>
  createSelector(localState, (currentState: TourState) => {
    let tours = currentState?.data;
    tours = getFilteredTours(status, tours);
    const groupedTours = orderBy(
      tours,
      [(obj) => new Date(obj.ScheduleDateTime || '')],
      ['asc'],
    ).reduce((rv, x) => {
      (rv[x.CreatedBy] = rv[x.CreatedBy] || []).push(x);
      return rv;
    }, {});

    return {
      tours: groupedTours,
      isIdle: currentState?.state === IDLE,
      isPending: currentState?.state === PENDING,
      isData: !!tours?.length,
    };
  });
export const getGroupedToursByDate = (status: TourStatus) =>
  createSelector(localState, (currentState: TourState) => {
    let tours = currentState?.data;
    tours = getFilteredTours(status, tours);
    tours = tours.map((tour) => ({
      ...tour,
      ScheduleDate: moment(tour.ScheduleDateTime).format('MM/DD/YYYY'),
    }));

    const groupedTours = tours.reduce((rv, x) => {
      (rv[x.ScheduleDate] = rv[x.ScheduleDate] || []).push(x);
      return rv;
    }, {});

    return {
      tours: groupedTours,
      isIdle: currentState?.state === IDLE,
      isPending: currentState?.state === PENDING,
      isData: !!tours?.length,
    };
  });

export const getGroupedToursByAgent = (status: TourStatus, teamFilter: TeamFilter) =>
  createSelector(
    localState,
    getUserId,
    getTeamTransactionsSelector,
    (currentState: TourState, currentUserId, isTeamView) => {
      let tours = currentState?.data;
      tours = orderBy(getFilteredTours(status, tours), ['CreatedDate', ['desc']]);
      let agentTours: any = [];
      tours.forEach((tour) => {
        (tour.TourAssignee || []).forEach((assignee) => {
          agentTours.push({
            ...tour,
            AgentId: assignee.AssigneeId,
          });
        });
      });
      if (teamFilter == TeamFilter.JustMe) {
        agentTours = agentTours.filter((u) => u.AgentId === currentUserId);
      }
      const groupedTours = agentTours.reduce((rv, x) => {
        (rv[x['AgentId']] = rv[x['AgentId']] || []).push(x);
        return rv;
      }, {});

      return {
        tours: groupedTours,
        isIdle: currentState?.state === IDLE,
        isPending: currentState?.state === PENDING,
        isData: !!tours?.length,
      };
    },
  );
const getFilteredTours = (status: TourStatus, tours: any) => {
  if (status === TourStatus.Approved) {
    tours = tours.filter((t) => moment(t.ScheduleDateTime) > moment());
  }
  if (status === TourStatus.Past) {
    tours = tours.filter((t) => moment(t.ScheduleDateTime) < moment());
  } else {
    tours = tours.filter((t) => t.Status === status);
  }
  return tours;
};
