import { createSelector } from 'reselect';

import { FormBuilderType } from 'types';

const localState = ({ formBuilder }) => formBuilder.data;

export const getQuoteFormMetaSelect = createSelector(
  localState,
  ({
    quoteBuilder: {
      disabled,
      folderName,
      tags,
      state,
      isFavorite,
      formName,
      status,
      formId,
      activeQuestionIndex,
      questions,
      responses,
      Id,
      lastSaved,
      lastUpdated,
      categories,
      locations,
      teams,
    },
  }: FormBuilderType) => {
    return {
      disabled,
      folderName,
      tags,
      state,
      isFavorite,
      formName,
      status,
      formId,
      activeQuestionIndex,
      questions,
      responses,
      Id,
      lastSaved,
      lastUpdated,
      categories,
      locations,
      teams,
    };
  },
);

export const getQuoteFormCurrentQuestionSelect = createSelector(
  localState,
  ({ quoteBuilder: { questions, activeQuestionIndex } }: FormBuilderType) => {
    return activeQuestionIndex !== undefined ? questions[activeQuestionIndex] : undefined;
  },
);
