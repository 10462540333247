import { get, cloneDeep, find, filter } from 'lodash-es';

export const getWizardStageValue = ({
  obj,
  stageIndex,
  propName,
  signUpRole,
  parentPropName = 'DefaultPropertySearchPreferences',
}) =>
  get(
    obj,
    `wizard.[${stageIndex}].meta.Values.[${signUpRole}].SearchInstance.[${parentPropName}].[${propName}]`,
  );

export const pushOrRemoveStageOptionArr = ({ arr, value, multiple = false }) => {
  const exist = find(arr, (item) => item === value);
  return exist ? filter(arr, (item) => item !== value) : [...(multiple ? arr : []), value];
};

export const pushOrRemoveStageOptionObj = ({ obj, key, value = true, multiple = true }) => {
  if (!multiple) {
    return { [key]: value };
  }

  const newObj = cloneDeep(obj);

  if (key in newObj) {
    delete newObj[key];
    return newObj;
  }

  if (key) {
    newObj[key] = value;
  }

  return newObj;
};

export const convertValuesToObj = (values) => {
  if (!values) return undefined;

  return (values || []).reduce((acc, val) => ({ ...acc, [val]: true }), {});
};

export function convertValuesToArr(values) {
  if (!values) return undefined;

  return Object.keys(values).map((val) => val);
}
