import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { Button, Select } from 'components';
import Input from 'components/Form/Input';

import { SearchIcon } from 'components/Icons';
import { MlsAccessStatusEnum } from '../constants';
import { searchMlsAgentsEffect } from 'store/effects/adminPanel/mlsAgents';
import {
  getMlsAgentsDashboardSelector,
  getMlsAgentsFiltersSelector,
} from 'store/selectors/adminPanel';
import styles from './styles.module.scss';

const statusFilterOptions = [
  {
    value: '',
    name: 'All',
  },
  {
    value: MlsAccessStatusEnum.REQUESTED,
    name: 'Requested',
  },
  {
    value: MlsAccessStatusEnum.APPROVED,
    name: 'Approved',
  },
  {
    value: MlsAccessStatusEnum.REJECTED,
    name: 'Rejected',
  },
  {
    value: MlsAccessStatusEnum.INACTIVE,
    name: 'Inactive',
  },
];

const Filters = () => {
  const dispatch = useDispatch();
  const { isPending } = useSelector(getMlsAgentsDashboardSelector);
  const filters = useSelector(getMlsAgentsFiltersSelector);
  const onSubmit = useCallback((values) => dispatch(searchMlsAgentsEffect(values)), [dispatch]);

  const formik = useFormik({
    initialValues: filters,
    enableReinitialize: true,
    onSubmit,
  });

  const onStatusFilterChanged = useCallback(
    (e, values) => {
      formik.setFieldValue('status', values.value);
    },
    [formik],
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.filtersFormWrapper}>
        <div className={styles.filtersWrapper}>
          <div className={styles.filterWrapper}>
            <Input
              id="searchString"
              className={styles.input}
              type="text"
              name="searchString"
              placeholder="Search"
              error={formik.touched.searchString ? formik.errors.searchString : ''}
              value={formik.values.searchString}
              onChange={formik.handleChange}
              disabled={isPending}
              variant={Input.LIGHT_FULL}
              data-testid="search_input"
            />
          </div>
          <div className={styles.filterWrapper}>
            <Select
              id="status"
              name="status"
              search
              options={statusFilterOptions}
              value={formik.values.status}
              placeholder="Status"
              onSelect={onStatusFilterChanged}
              disabled={isPending}
              className={{ wrapper: styles.selectWrapper }}
              variant={Select.LIGHT_FULL}
              data-testid="status_select"
            />
          </div>
        </div>
        {formik.dirty ? (
          <Button
            type="submit"
            className={styles.searchButton}
            isPending={isPending}
            title={<SearchIcon />}
            testid="search_button"
          />
        ) : (
          <Button
            type="button"
            className={styles.searchButton}
            title={<SearchIcon />}
            testid="search_button"
          />
        )}
      </div>
    </form>
  );
};

Filters.propTypes = {};
Filters.defaultProps = {};

export default Filters;
