import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { ArrowLeft } from 'components/Icons';

import { getSearchDrawerCommuteIdSelector } from 'store/selectors/searchResults';
import { getSearchCommutesSelector } from 'store/selectors/search';
import { commuteType } from 'settings/constants/commuteType';

import styles from './styles.module.scss';

const CommuteHeader = (props) => {
  const { className, subHeaderClassName, onClose } = props;
  const commuteId = useSelector(getSearchDrawerCommuteIdSelector);
  const commutesList = useSelector(getSearchCommutesSelector);

  const getTitle = () => {
    if (commutesList?.[commuteId]) {
      return commutesList?.[commuteId]?.Name;
    } else if (commuteId === commuteType.NEW_PROXIMITY) {
      return 'New Important Location';
    } else {
      return 'New Commute';
    }
  };

  return (
    <div className={styles.headerWrap}>
      <div className={classNames(styles.header, className)}>
        <div className={styles.goBack} onClick={onClose}>
          <ArrowLeft className={styles.icon} />
          <span className={styles.text}>Commutes</span>
        </div>
      </div>
      <p className={classNames(styles.subHeader, subHeaderClassName)}>{getTitle()}</p>
    </div>
  );
};

CommuteHeader.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

CommuteHeader.defaultProps = {
  className: '',
  onClose: () => {},
};

export default CommuteHeader;
