import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const Commute = memo(({ className }) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 11V6M5 17H3V11L5 6H14L18 11H19C19.5304 11 20.0391 11.2107 20.4142 11.5858C20.7893 11.9609 21 12.4696 21 13V17H19H5ZM15 17H9H15ZM3 11H18H3Z"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 19C8.10457 19 9 18.1046 9 17C9 15.8954 8.10457 15 7 15C5.89543 15 5 15.8954 5 17C5 18.1046 5.89543 19 7 19Z"
            fill="white"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 19C18.1046 19 19 18.1046 19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17C15 18.1046 15.8954 19 17 19Z"
            fill="white"
            stroke="#303030"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

Commute.propTypes = {
  className: PropTypes.string,
};

Commute.defaultProps = {
  className: '',
};

export default Commute;
