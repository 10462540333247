import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import { sortGroupedQuoteDocuments } from 'pages/Quotes/components/QuoteDetails/helpers';
import { Attachment, AttachmentDateTitle } from '../../..';

import styles from './styles.module.scss';

const MetaInfo = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  const { quote, groupedByDateQuoteDocuments } = useSelector(getQuoteDetailsSelector);

  const getSortedDocuments = () =>
    Object.entries(groupedByDateQuoteDocuments).sort(sortGroupedQuoteDocuments);

  return (
    <div testid="meta_info" className={classNames(styles.metaInfo, className)}>
      <p className={styles.metaInfoTitle}>Quotes</p>
      <div className={styles.uploads}>
        {getSortedDocuments().length > 0 ? (
          getSortedDocuments().map(([formattedDate, documents]) => (
            <div key={formattedDate}>
              <AttachmentDateTitle className={styles.groupDate}>
                {formattedDate}
              </AttachmentDateTitle>
              {documents.map((document, index) => (
                <div key={document?.Id || index}>
                  <Attachment
                    truncateLength={20}
                    variant={Attachment.SUBMITTED}
                    file={document}
                    fullDate={false}
                    deletable
                    quoteId={quote?.Id}
                    className={styles.attachment}
                    fileNameClassName={styles.attachmentFile}
                    dateClassName={styles.attachmentDate}
                  />
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className={styles.noQuotesContainer}>
            <p className={styles.noQuotes}>No quotes</p>
          </div>
        )}
      </div>
    </div>
  );
};

MetaInfo.propTypes = {
  className: PropTypes.string,
};

MetaInfo.defaultProps = {
  className: '',
};

export default MetaInfo;
