import React from 'react';
import PropTypes from 'prop-types';

const Task = ({ className }) => (
  <div className={className}>
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5001 9.16675H12.8334C11.861 9.16675 10.9283 9.55306 10.2407 10.2407C9.55306 10.9283 9.16675 11.861 9.16675 12.8334V34.8334C9.16675 35.8059 9.55306 36.7385 10.2407 37.4261C10.9283 38.1138 11.861 38.5001 12.8334 38.5001H31.1667C32.1392 38.5001 33.0718 38.1138 33.7595 37.4261C34.4471 36.7385 34.8334 35.8059 34.8334 34.8334V12.8334C34.8334 11.861 34.4471 10.9283 33.7595 10.2407C33.0718 9.55306 32.1392 9.16675 31.1667 9.16675H27.5001"
        stroke="black"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8333 5.5H20.1667C18.1416 5.5 16.5 7.14162 16.5 9.16667C16.5 11.1917 18.1416 12.8333 20.1667 12.8333H23.8333C25.8584 12.8333 27.5 11.1917 27.5 9.16667C27.5 7.14162 25.8584 5.5 23.8333 5.5Z"
        stroke="black"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 25.6667L20.1667 29.3333L27.5 22"
        stroke="black"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

Task.propTypes = {
  className: PropTypes.string,
};

Task.defaultProps = {
  className: '',
};

export default Task;
