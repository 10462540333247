import { useState, useRef } from 'react';
import classNames from 'classnames';

import { Popover } from 'components-antd';
import { useOutsideClick } from 'hooks';

import styles from './styles.module.scss';

export const LinkOptions = ({ className = '', title, options }) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLInputElement>(null);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const PopoverContent = () => (
    <div ref={optionsRef} className={styles.options}>
      <ul>
        {options.map(({ label, icon, onClick }, idx) => (
          <li
            key={idx}
            className={styles.item}
            onClick={() => {
              setOpen(false);
              onClick();
            }}
          >
            <div className={styles.icon}>{icon}</div>
            <span>{label}</span>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className={classNames(styles.linkOptionsWrapper, className)}>
      <div testid="options" ref={buttonRef} onClick={onClickOptions}>
        <span className={styles.title}>{title}</span>
      </div>
      <Popover
        open={open}
        overlayClassName={styles.linkOptionsPopover}
        content={<PopoverContent />}
      ></Popover>
    </div>
  );
};
