import isUrl from 'is-url';

export const preparedAttachments = (attachments) =>
  (attachments || []).map((attachment) => ({
    ContentType: attachment?.ContentType,
    Filename: attachment?.Filename,
    Data: !isUrl(attachment?.Url) ? btoa(attachment?.Url) : undefined,
    Url: isUrl(attachment?.Url) ? attachment?.Url : undefined,
    Size: attachment?.Size,
    DocumentVaultUUID: attachment?.DocumentVaultUUID,
  }));
