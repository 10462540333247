import classnames from 'classnames';
import { cloneDeep } from 'lodash-es';
import { useSelector } from 'react-redux';

import { Icons } from '../../Icons/Icons';
import { Close as CloseIcon } from 'components/Icons';
import { validateParticipantValues } from '../../TransactionCreate/helpers';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';

import styles from './styles.module.scss';

interface ControlsProps {
  stage: string;
  setStage: (stage: string) => void;
  onClose: () => void;
}

export const Controls = ({ stage, setStage, onClose }: ControlsProps) => {
  const { preForm } = useSelector(getTransactionPreFormSelector);

  const isPrevDisabled = () => {
    return stage === transactionPreFormQuestionsIds.whoIsRepresentingTheBuyer;
  };

  const isNextDisabled = () => {
    if (
      stage === transactionPreFormQuestionsIds.purchasePrice &&
      !preForm[transactionPreFormQuestionsIds.purchasePrice]
    ) {
      return true;
    } else if (
      stage === transactionPreFormQuestionsIds.effectiveDate &&
      !preForm[transactionPreFormQuestionsIds.effectiveDate]
    ) {
      return true;
    } else if (
      stage === transactionPreFormQuestionsIds.closingDate &&
      !preForm[transactionPreFormQuestionsIds.closingDate]
    ) {
      return true;
    } else if (
      stage === transactionPreFormQuestionsIds.transactionTemplate &&
      !preForm[transactionPreFormQuestionsIds.transactionTemplate]
    ) {
      return true;
    } else if (stage === transactionPreFormQuestionsIds.timeline) {
      const timelineValues = cloneDeep(preForm?.[transactionPreFormQuestionsIds.timeline]) || [];
      let flag = true;
      for (let index in timelineValues) {
        const timeline = timelineValues[index];
        if (!timeline.Title || !timeline.DueDate) {
          flag = false;
          break;
        }
      }
      return !flag;
    } else if (stage === transactionPreFormQuestionsIds.assignParticipantsToRoles) {
      return !validateParticipantValues(
        preForm[transactionPreFormQuestionsIds.assignParticipantsToRoles],
      );
    } else if (stage === transactionPreFormQuestionsIds.participants) {
      const participants = preForm[transactionPreFormQuestionsIds.participants];
      return participants?.length
        ? !validateParticipantValues(preForm[transactionPreFormQuestionsIds.participants])
        : false;
    } else {
      return false;
    }
  };

  const onPrev = () => {
    if (isPrevDisabled()) return;
    if (stage === transactionPreFormQuestionsIds.purchasePrice) {
      setStage(transactionPreFormQuestionsIds.whoIsRepresentingTheBuyer);
    } else if (stage === transactionPreFormQuestionsIds.effectiveDate) {
      setStage(transactionPreFormQuestionsIds.purchasePrice);
    } else if (stage === transactionPreFormQuestionsIds.closingDate) {
      setStage(transactionPreFormQuestionsIds.effectiveDate);
    } else if (stage === transactionPreFormQuestionsIds.financingType) {
      setStage(transactionPreFormQuestionsIds.closingDate);
    } else if (stage === transactionPreFormQuestionsIds.financingDetails) {
      setStage(transactionPreFormQuestionsIds.financingType);
    } else if (stage === transactionPreFormQuestionsIds.purchaseAgreement) {
      setStage(transactionPreFormQuestionsIds.financingDetails);
    } else if (stage === transactionPreFormQuestionsIds.transactionTemplate) {
      setStage(transactionPreFormQuestionsIds.purchaseAgreement);
    } else if (stage === transactionPreFormQuestionsIds.timeline) {
      setStage(transactionPreFormQuestionsIds.transactionTemplate);
    } else if (stage === transactionPreFormQuestionsIds.assignParticipantsToRoles) {
      setStage(transactionPreFormQuestionsIds.timeline);
    } else if (stage === transactionPreFormQuestionsIds.participants) {
      setStage(transactionPreFormQuestionsIds.assignParticipantsToRoles);
    } else if (stage === transactionPreFormQuestionsIds.files) {
      setStage(transactionPreFormQuestionsIds.participants);
    }
  };

  const onNext = () => {
    if (isNextDisabled()) return;
    if (stage === transactionPreFormQuestionsIds.purchasePrice) {
      setStage(transactionPreFormQuestionsIds.effectiveDate);
    } else if (stage === transactionPreFormQuestionsIds.effectiveDate) {
      setStage(transactionPreFormQuestionsIds.closingDate);
    } else if (stage === transactionPreFormQuestionsIds.closingDate) {
      setStage(transactionPreFormQuestionsIds.transactionTemplate);
    } else if (stage === transactionPreFormQuestionsIds.transactionTemplate) {
      setStage(transactionPreFormQuestionsIds.timeline);
    } else if (stage === transactionPreFormQuestionsIds.timeline) {
      setStage(transactionPreFormQuestionsIds.assignParticipantsToRoles);
    } else if (stage === transactionPreFormQuestionsIds.assignParticipantsToRoles) {
      setStage(transactionPreFormQuestionsIds.participants);
    } else if (stage === transactionPreFormQuestionsIds.participants) {
      setStage(transactionPreFormQuestionsIds.files);
    }
  };

  return (
    <div className={styles.controls}>
      <Icons
        variant="arrowLeft"
        className={classnames(styles.iconBtn, styles.arrowLeft, {
          [styles.disabled]: isPrevDisabled(),
        })}
        onClick={onPrev}
      />
      {/* <Icons
        variant="arrowRight"
        className={classnames(styles.iconBtn, styles.arrowRight, {
          [styles.disabled]: isNextDisabled(),
        })}
        onClick={onNext}
      /> */}
      <CloseIcon onClick={onClose} className={styles.iconBtn} />
    </div>
  );
};
