import { BundleDocumentsType, FormBundleDocument } from 'types';

export const prepareBundleForms = (forms) => {
  return forms?.map(({ FormName, Name, Id, PublicId, DocumentLink, FormProcessId, Order }) => ({
    id: Id,
    sequence: Order,

    Id,
    Title: FormName || Name,
    DocumentLink,
    PublicId,
    FormProcessId,
  }));
};

export const getOrderedBundleDetails = (
  orderedDocuments,
  bundleDocuments,
  formDocuments,
  isTemplate,
) => {
  const orderedBundleDetails: BundleDocumentsType[] = [];
  const dynamicDocuments: FormBundleDocument[] = [];

  orderedDocuments.forEach(({ PublicId, Id }) => {
    const bundleItem = bundleDocuments.find((item) =>
      isTemplate ? item.templateId === Id : item.PublicId === PublicId,
    );

    const docItem = formDocuments.find((item) =>
      isTemplate ? item.Id === Id : item.PublicId === PublicId,
    );

    docItem && dynamicDocuments.push(docItem);
    bundleItem && orderedBundleDetails.push(bundleItem);
  });

  return { bundleDetails: orderedBundleDetails, dynamicDocuments };
};
