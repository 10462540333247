import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Properties/Feed/Icons';
import styles from './styles.module.scss';

const RecommendedBy = (props) => {
  const { className, recommendedBy } = props;

  if (!recommendedBy) return null;

  return (
    <div className={classNames(styles.recommendedBy, className)}>
      <Icon className={styles.icon} variant={Icon.LIKE} />
      <span>Suggested by</span>
      <span
        className={styles.name}
      >{` ${recommendedBy?.FirstName} ${recommendedBy.LastName}`}</span>
    </div>
  );
};

RecommendedBy.propTypes = {
  className: PropTypes.string,
  recommendedBy: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
  }),
};

RecommendedBy.defaultProps = {
  className: '',
  recommendedBy: undefined,
};

export default RecommendedBy;
