import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Name from './Name';
import Actions from './Actions';

import styles from './styles.module.scss';

const PropsHeader = ({ className, hideActions, name }) => (
  <div className={classNames(styles.propsHeaderWrapper, className)}>
    <Name>{name}</Name>
    {!hideActions && <Actions />}
  </div>
);

PropsHeader.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  hideActions: PropTypes.bool,
};

PropsHeader.defaultProps = {
  name: '',
  className: '',
  hideActions: false,
};

export default PropsHeader;
