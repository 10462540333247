import React, { memo, useMemo } from 'react';

const Download = memo(({ className, color }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.33398 16.1641V17.8307C5.33398 18.2728 5.50958 18.6967 5.82214 19.0092C6.1347 19.3218 6.55862 19.4974 7.00065 19.4974H17.0006C17.4427 19.4974 17.8666 19.3218 18.1792 19.0092C18.4917 18.6967 18.6673 18.2728 18.6673 17.8307V16.1641"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.83398 11L12.0007 15.1667L16.1673 11"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 5V15"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className, color],
  ),
);

export default Download;
