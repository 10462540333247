import { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import { Modal } from 'components-antd';
import { getShowingAppointmentById } from 'api/showingAppointment';
import { Spinner } from 'components';
import { Header } from './components/Header';
import { Details } from './components/Details';
import { Documents } from './components/Documents';
import { History } from './components/History';
import { Icons } from 'pages/Workshop/Icons';
import { AppointmentStatus, ApprovalAction } from 'types/showingAppointment';

import styles from './styles.module.scss';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getUserRolesSelector } from 'store/selectors/user';
import { Role } from 'settings/constants/roles';
import { announcementType } from 'settings/constants/showings';
import { MegaPhoneModal } from 'pages/Workshop/Transactions/TransactionShowings/MegaPhoneModal';

export const AppointmentDetailsModal = ({
  isOpen,
  onClose,
  UUID,
  isApprovalRequired,
  processAction,
  pendingApprovers,
  isFeedback,
  processData,
}) => {
  const [appointment, setAppointment]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [megPhoneModal, setMegaPhoneModal] = useState(false);
  const userRoles = useSelector(getUserRolesSelector);

  const status = appointment?.AppointmentStatus;

  const fetchAppointmentDetails = useCallback(async (UUID) => {
    setIsLoading(true);
    const { data } = await getShowingAppointmentById({ Id: UUID }, { externalToken: '' });
    if (data?.value) {
      setAppointment(data.value);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (appointment) {
      processData(appointment);
    }
  }, [appointment]);

  useEffect(() => {
    if (isOpen && UUID) fetchAppointmentDetails(UUID);
  }, [isOpen, UUID]);

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      className={styles.appointmentDetailsModal}
    >
      <div className={styles.modalHeader}>
        <h1 className={styles.heading}>Showing Details</h1>
        <div className={styles.actions}>
          <div
            className={classNames(
              styles.status,
              {
                [styles.pending]: status === AppointmentStatus.Pending,
              },
              {
                [styles.upcoming]: status === AppointmentStatus.Upcoming,
              },
            )}
          >
            <span>{isFeedback ? 'New Feedback' : status}</span>
          </div>
          {userRoles.includes(Role.Agent) && (
            <Icons
              className={styles.megaPhoneIcon}
              variant={Icons.MEGA_PHONE}
              onClick={() => setMegaPhoneModal(true)}
            />
          )}
        </div>
      </div>
      {userRoles.includes(Role.Agent) && (
        <MegaPhoneModal
          showingId={Number(appointment?.ShowingDetails?.Id)}
          appointmentId={Number(appointment?.Id)}
          isOpen={megPhoneModal}
          onClose={() => setMegaPhoneModal(false)}
          type={announcementType.APPOINTMENT}
        />
      )}
      {!isLoading ? (
        <div className={styles.content}>
          <Header data={appointment} />
          <Details
            data={appointment}
            getIsAprovalRequired={isApprovalRequired}
            pendingApprovers={pendingApprovers}
            processAction={processAction}
            isFeedback={isFeedback}
          />
          <Documents data={appointment} />
          <History data={appointment} />
          {appointment?.AppointmentStatus === AppointmentStatus.Upcoming && fullAccess ? (
            <div className={styles.action}>
              <Button
                type="text"
                className={styles.cancelBtn}
                onClick={() => processAction(ApprovalAction.Cancel)}
              >
                <Icons variant={Icons.CLOSE} className={styles.icon} />
                <span>Cancel Showing</span>
              </Button>
            </div>
          ) : null}
        </div>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};

AppointmentDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  UUID: PropTypes.string,
  isApprovalRequired: PropTypes.func,
  processAction: PropTypes.func,
  pendingApprovers: PropTypes.arrayOf(PropTypes.string),
  isFeedback: PropTypes.bool,
};

AppointmentDetailsModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  UUID: null,
  isApprovalRequired: () => {},
  processAction: () => {},
  pendingApprovers: [],
  isFeedback: false,
};
