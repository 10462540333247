import React from 'react';
import moment from 'moment';

import { formatDate, subtractTimeZone } from 'helpers';

import styles from './styles.module.scss';

export function tableColumns(isAdded) {
  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'Agent',
      width: 300,
      sorter: (a, b) => {
        let aName = `${a?.Agent?.User?.FirstName} ${a?.Agent?.User?.LastName}`;
        let bName = `${b?.Agent?.User?.FirstName} ${b?.Agent?.User?.LastName}`;
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      },
      render: (_, { Agent }) => {
        const name = `${Agent?.User?.FirstName} ${Agent?.User?.LastName}`;
        return <span className={styles.tableText}>{name}</span>;
      },
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'Agent',
      width: 300,
      sorter: (a, b) => {
        let aEmail = `${a?.Agent?.User?.Email}`;
        let bEmail = `${b?.Agent?.User?.Email}`;
        if (aEmail < bEmail) return -1;
        if (aEmail > bEmail) return 1;
        return 0;
      },
      render: (_, { Agent }) => {
        const email = `${Agent?.User?.Email}`;
        return <span className={styles.tableText}>{email}</span>;
      },
    },
    {
      title: `${isAdded ? 'Added By' : 'Removed By'}`,
      key: 'createdByName',
      dataIndex: 'CreatedByUser',
      width: 300,
      sorter: (a, b) => {
        let aName = `${a?.CreatedByUser?.FirstName} ${a?.CreatedByUser?.LastName}`;
        let bName = `${b?.CreatedByUser?.FirstName} ${b?.CreatedByUser?.LastName}`;
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      },
      render: (_, { CreatedByUser }) => {
        const name = `${CreatedByUser?.FirstName} ${CreatedByUser?.LastName}`;
        return <span className={styles.tableText}>{name}</span>;
      },
    },
    {
      title: 'Seats Count',
      key: 'seatsCount',
      dataIndex: 'Seats',
      width: 300,
      sorter: (a, b) => {
        if (a?.Seats < b?.Seats) return -1;
        if (a?.Seats > b?.Seats) return 1;
        return 0;
      },
      render: (_, { Seats }) => {
        return <span className={styles.tableText}>{Seats}</span>;
      },
    },
    {
      title: 'Date/Time',
      key: 'dueDate',
      dataIndex: 'CreatedDate',
      width: 300,
      sorter: (a, b) => moment(a.CreatedDate).unix() - moment(b.CreatedDate).unix(),
      render: (_, { CreatedDate }) => {
        let createdDate = CreatedDate ? formatDate(CreatedDate, 'MM/DD/YYYY hh:mm A') : 'N/A';
        return <span className={styles.tableText}>{createdDate}</span>;
      },
    },
  ];

  return columns;
}
