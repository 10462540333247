export const getTransportationScoreDescriptions = ({ walk, bike, transit }) => {
  let walkDesc = 'No data';
  let bikeDesc = 'No data';
  let transitDesc = 'No data';

  if (walk || walk === 0) {
    if (walk <= 49) walkDesc = 'Car-Dependent';
    if (walk >= 50) walkDesc = 'Somewhat Walkable';
    if (walk >= 70) walkDesc = 'Very Walkable';
    if (walk >= 90) walkDesc = 'Walker’s Paradise';
  }

  if (bike || bike === 0) {
    if (bike <= 49) bikeDesc = 'Somewhat Bikeable';
    if (bike >= 50) bikeDesc = 'Bikeable';
    if (bike >= 70) bikeDesc = 'Very Bikeable';
    if (bike >= 90) bikeDesc = 'Biker’s Paradise';
  }

  if (transit || transit === 0) {
    if (transit <= 24) transitDesc = 'Minimal Transit';
    if (transit >= 25) transitDesc = 'Some Transit';
    if (transit >= 50) transitDesc = 'Good Transit';
    if (transit >= 70) transitDesc = 'Excellent Transit';
    if (transit >= 90) transitDesc = 'Rider’s Paradise';
  }

  return { walkDesc, bikeDesc, transitDesc };
};
