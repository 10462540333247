import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Space, Dropdown, Menu } from 'components-antd';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { List, Edit, Delete } from 'components/Icons';
import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { getAgentTeamRoleSelector, getAgentTeamIdSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_OWNER, TEAM_ADMIN } from 'settings/constants/roles';

import styles from './styles.module.scss';
import moment from 'moment';
import { getFormMetaSelect } from 'store/selectors/formBuilder';
import { getAllTeamsEffect } from 'store/effects';

export const TemplateCard = (props) => {
  const { className, template, onDelete, onEdit, onView } = props;
  const userId = useSelector(getUserId);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const teamId = useSelector(getAgentTeamIdSelector);
  const { isSuperUser } = useSelector(getUserRolesMapSelector);

  const { teams: allTeams = [] } = useSelector(getFormMetaSelect);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuperUser && !allTeams?.length) dispatch(getAllTeamsEffect());
  }, []);

  const templateActions = [
    {
      key: '1',
      label: (
        <div className={styles.dropdownItem} onClick={() => onView(template.Id, template)}>
          <List className={classNames(styles.icon)} />
          <span>View</span>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className={styles.dropdownItem} onClick={() => onEdit(template.Id, template)}>
          <Edit className={classNames(styles.icon)} />
          <span>Edit</span>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div className={styles.dropdownItem} onClick={() => onDelete(template)}>
          <Delete className={styles.icon} />
          <span>Delete</span>
        </div>
      ),
    },
  ];

  const getTemplateActions = () => {
    if (
      !isSuperUser &&
      template.IsGlobal &&
      !(template.TeamId === teamId || (template.Teams || []).includes(teamId))
    )
      return [templateActions[0]];
    else if (
      userId === template.CreatorId ||
      isSuperUser ||
      ([TEAM_OWNER, TEAM_ADMIN].includes(agentRole) &&
        (template.TeamId === teamId || (template.Teams || []).includes(teamId)))
    ) {
      return templateActions;
    }

    return [templateActions[0]];
  };

  return (
    <div testid="template_item" className={classNames(styles.templateCard, className)}>
      <div testid="template_name" className="templateName">
        {template?.Name}
      </div>
      {isSuperUser && template?.Creator && userId !== template.CreatorId ? (
        <div className={styles.metaInfo}>
          <div>
            <strong>Agent Name:</strong> {template.Creator?.FirstName} {template.Creator?.LastName}
          </div>
          <div>
            <strong>Team Name:</strong>{' '}
            {allTeams.find((team) => String(team.Id) === String(template.TeamId))?.Name}
          </div>
          <div>
            <strong>Created At:</strong>{' '}
            {moment(template.CreatedDate).format('MM/DD/YY [at] hh:mm A')}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={styles.tcRight}>
        <div id="dropdown" className={styles.dropdownWrap}>
          <Dropdown
            overlay={<Menu items={getTemplateActions()} />}
            trigger={['click']}
            overlayClassName={styles.transactionMoreOptions}
            getPopupContainer={() => document.getElementById('dropdown')!}
            placement="bottomRight"
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Icon variant={Icon.DOTS} className={styles.dropdownTrigger} />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

TemplateCard.propTypes = {
  className: PropTypes.string,
  template: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

TemplateCard.defaultProps = {
  className: '',
};
