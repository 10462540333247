import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import { LocationService } from 'services';
import BaseNotification from '../../../BaseNotification';

import styles from './styles.module.scss';
import { notificationTypes } from 'settings/constants/notifications';
import { navigateToWorkShopShowings } from 'components/Drawers/NotificationsDrawer/helpers';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { useDispatch } from 'react-redux';
import { link } from 'settings/navigation/link';

const locationSrv = new LocationService();

const DeclinedShowingAppointment = (props) => {
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel, Type } =
    props;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  locationSrv.setLocation(location);

  const {
    DeclinerFirstName,
    DeclinerLastName,
    BuyingAgentName,
    BuyerAgentName,
    SubmitterName,
    ClientAvatarUrl,
    Link,
    RebookLink,
    TransactionId,
    ShowingUUID,
  } = Payload || {};

  const Name =
    BuyingAgentName ||
    SubmitterName ||
    BuyerAgentName ||
    `${DeclinerFirstName} ${DeclinerLastName}`;

  const onClickHandler = () => {
    if (
      Type === notificationTypes.ShowingFeedbackSubmittedNotification ||
      Type === notificationTypes.RescheduledShowingAppointmentNotification
    ) {
      dispatch(openNotificationsDrawerEffect({ open: false }));
      const path = link.toTransactionShowings(TransactionId, ShowingUUID);
      history.push(path);
    } else {
      window.location.replace(RebookLink || Link);
    }
  };

  return (
    <div className={classNames(styles.notificationClientPrefsUpdated, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        groupType={groupType}
        avatarUrl={ClientAvatarUrl}
        avatarPlaceholder={convertNameToAvatarPlaceholder(Name)}
        severityLevel={SeverityLevel}
        onClick={onClickHandler}
      />
    </div>
  );
};

DeclinedShowingAppointment.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    RequesterFirstName: PropTypes.string,
    RequesterSecondName: PropTypes.string,
    ClientAvatarUrl: PropTypes.string,
    Link: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

DeclinedShowingAppointment.defaultProps = {
  className: '',
};

export default DeclinedShowingAppointment;
