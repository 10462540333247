import { combineReducers } from 'redux';

import messages from './messages';
import threadContacts from './threadContacts';
import comments from './comments';
import addParticipants from './addParticipants';
import notifications from './notifications';
import quotes from './quotes';
import transactionParticipant from './transactionParticipant';
import transactionDocument from './transactionDocument';
import activityFilters from './activityFilters';
import messageAttachments from './messageAttachments';
import viewQuoteActivity from './viewQuoteActivity';
export default combineReducers({
  messages,
  comments,
  threadContacts,
  addParticipants,
  notifications,
  quotes,
  transactionParticipant,
  transactionDocument,
  activityFilters,
  messageAttachments,
  viewQuoteActivity,
});
