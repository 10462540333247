import { cloneDeep, get } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  createTransactionTaskAction,
  updateTransactionTaskAction,
  getTransactionAction,
  deleteTransactionTaskAction,
  resetTransactionTasksAction,
  setTransactionTasksLastStateAction,
  setTaskFiltersAppliedAction,
  setShowCommentIconForTransactionAction,
  updateUnReadCommentsForTransactionAction,
  updateTransactionTaskChecklistAction,
  setTransactionsTasksFilterAction,
  setTaskFiltersAction,
} from 'store/actions/transactions';
import { taskFilters } from 'settings/constants/transactionTasks';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  filters: {},
  lastStatePerTransaction: {},
  transactionsTasksFilter: taskFilters.allTasks,
};

export default handleActions(
  {
    [setTaskFiltersAction]: (state, { payload }) => ({
      ...state,
      filters: payload,
    }),
    [getTransactionAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result.Tasks', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [createTransactionTaskAction]: (state, { payload }) => ({
      ...state,
      state: get(payload, 'state', state.state),
      data: get(payload, 'data.result.Tasks', state.data),
      meta: get(payload, 'meta', state.meta),
    }),
    [updateTransactionTaskAction]: (state, { payload }) => ({
      ...state,
      state: get(payload, 'state', state.state),
      data: get(payload, 'data.result.Tasks', state.data),
      meta: get(payload, 'meta', state.meta),
    }),
    [deleteTransactionTaskAction]: (state, { payload }) => ({
      ...state,
      state: get(payload, 'state', state.state),
      data: get(payload, 'data.result.Tasks', state.data),
      meta: get(payload, 'meta', state.meta),
    }),
    [setTaskFiltersAppliedAction]: (state, { payload }) => ({
      ...state,
      isTaskFiltersApplied: payload,
    }),
    [setTransactionTasksLastStateAction]: (state, { payload }) => ({
      ...state,
      lastStatePerTransaction: {
        ...state.lastStatePerTransaction,
        [payload.transactionId]: {
          taskId: payload.taskId,
        },
      },
    }),
    [resetTransactionTasksAction]: (state) => ({
      ...cloneDeep(initialData),
      lastStatePerTransaction: state.lastStatePerTransaction,
    }),
    [updateUnReadCommentsForTransactionAction]: (state, { payload }) => {
      const { taskId, userId } = payload;
      const updatedData = [...(state.data || [])];

      const taskToUpdate = updatedData.find((task) => task.Id === taskId);

      if (taskToUpdate) {
        taskToUpdate.Comments = (taskToUpdate.Comments || []).map((comment) => {
          comment.MessageReceipts = (comment.MessageReceipts || []).map((messageReceipt) => {
            if (messageReceipt.UserId === userId) {
              messageReceipt.IsRead = true;
            }
            return messageReceipt;
          });
          return comment;
        });
      }

      return { ...state, data: updatedData };
    },
    [setShowCommentIconForTransactionAction]: (state, { payload }) => {
      const tasks = state.data || [];
      const { taskId } = payload;
      const taskIndex = tasks.findIndex((task) => task.Id === taskId);

      if (taskIndex !== -1) {
        const updatedTasks = [...tasks];
        updatedTasks[taskIndex] = {
          ...updatedTasks[taskIndex],
          showCommentIcon: true,
        };

        return {
          ...state,
          data: updatedTasks,
        };
      }
      return state;
    },
    [updateTransactionTaskChecklistAction]: (state, { payload }) => {
      const task = get(payload, 'data.result');
      const tasks = cloneDeep(state.data);

      if (task && tasks?.length) {
        const index = tasks.findIndex((item) => item.Id === task.Id);
        if (index !== -1) {
          tasks[index] = task;
        }
      }

      return {
        ...state,
        data: tasks,
      };
    },
    [setTransactionsTasksFilterAction]: (state, { payload }) => ({
      ...state,
      transactionsTasksFilter: payload,
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
