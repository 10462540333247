import { routes } from 'settings/navigation/routes';
import { CLIENT, AGENT, THIRD_PARTY } from 'settings/constants/roles';

import { getLinksByRoles } from './common';

export const workshopLinks = () => ({
  [CLIENT]: [
    { link: routes.transactions, title: 'Transactions' },
    { link: routes.projects, title: 'Projects' },
    { link: routes.tasks, title: 'Tasks' },
    { link: routes.workshopForms, title: 'Forms' },
  ],
  [AGENT]: [
    { link: routes.transactions, title: 'Transactions' },
    { link: routes.projects, title: 'Projects' },
    { link: routes.tasks, title: 'Tasks' },
    { link: routes.offers, title: 'Offers' },
    { link: routes.showings, title: 'Showings' },
    { link: routes.workshopForms, title: 'Forms' },
    { link: routes.milestones, title: 'Milestones' },
  ],
  [THIRD_PARTY]: [
    { link: routes.transactions, title: 'Transactions' },
    { link: routes.projects, title: 'Projects' },
    { link: routes.tasks, title: 'Tasks' },
  ],
});

/**
 * @param  {} roles = []
 * @returns array of header links [{ link, title }]
 */
export const getWorkshopLinksByRoles = getLinksByRoles(workshopLinks);
