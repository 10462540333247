import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BottomAdviceBlock from 'components/Drawers/components/BottomAdviceBlock';

import NewComment from './NewComment';
import ThreadsList from './ThreadsList';

import styles from './styles.module.scss';
import ScrollWrapper from '../ScrollWrapper';

const Threads = ({ threads, className, onNewComment }) => (
  <div className={classNames(styles.threadsList, className)}>
    {
      <ScrollWrapper>
        <ThreadsList list={threads} />
        <NewComment onClick={onNewComment} />
      </ScrollWrapper>
    }
    {!!threads.length && <BottomAdviceBlock />}
  </div>
);

Threads.propTypes = {
  className: PropTypes.string,
  threads: PropTypes.arrayOf(PropTypes.shape({})),
  onNewComment: PropTypes.func,
};

Threads.defaultProps = {
  className: '',
  threads: [],
  onNewComment: () => {},
};

export default Threads;
