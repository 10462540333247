import { SVGProps } from 'react';

export const ZoomOut = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="14"
      height="2"
      viewBox="0 0 14 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="14" height="2" rx="1" fill="#262626" />
    </svg>
  );
};
