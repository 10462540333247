import { apiServer } from 'settings/web-services/api';

export function getListOfServiceTags(cfg) {
  return apiServer.get(`/partner/services`, { params: cfg });
}

export function deleteServiceTag({ id }) {
  return apiServer.delete(`/partner/services/${id}`);
}

export function getServiceTagById({ id }) {
  return apiServer.get(`/partner/services/${id}`);
}

export function postServiceTagData(cfg) {
  return apiServer.post('/partner/services', cfg);
}

export function putServiceTagData(cfg, { id }) {
  return apiServer.put(`/partner/services/${id}`, cfg);
}
