import Api from 'store/effects/core/api';
import { isEmpty } from 'lodash';
import {
  requestGetClientFavoritePropsAction,
  requestAgentClientsAction,
  sortFeedAction,
  setMultipleModAction,
  setComparesAction,
  openFeedFilterDrawerAction,
  setFeedDrawerCommuteIdAction,
  openCompareModalAction,
  deleteCompareItemAction,
  openShareDrawerAction,
  requestGetClientsAction,
  searchClientsAction,
  setRecipientsAction,
  setActiveTabAction,
  requestUpdateSearchCriteriaAction,
  setSharePropertyAction,
  requestSendSharePropsToRecipientsAction,
  requestGetComparePropertiesAction,
  resetComparePropertiesStateAction,
  resetClientFavoritePropsAction,
  resetPropertiesWholeTabsAction,
  resetFullDataComparePropertiesAction,
  requestGetPriceReductionsPropsAction,
  initialImageAnalysisAction,
} from 'store/actions/feed';
import { extractOrderedPhotos } from 'helpers';
import {
  getClientFavorites,
  getClientAllFavorites,
  getAgentClientssWithFavoritesCount,
  sendShareProps,
  getCompareProperties,
  getOpenHouses,
  initiateImageAnalysisMethod,
} from 'api/feed';
import { onBoardingUser } from 'api/onBoarding';
import { getState } from 'store';
import { AGENT, CLIENT, mapRoles } from 'settings/constants/roles';
import { get } from 'lodash-es';
import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { propertiesTabsIds } from 'settings/constants/properties';
import { noRanges } from 'settings/constants/common';
import {
  getAllPropsV2Effect,
  getBackOnMarketPropsV2Effect,
  getNewPropsV2Effect,
  getPriceReductionsPropsV2Effect,
  getRecommendedPropsV2Effect,
  getSavedSearchesPropsV2Effect,
  getStatusChangesPropsV2Effect,
  getSuggestionsPropsV2Effect,
  getWatchlistPropsV2Effect,
  getOpenHousesPropsV2Effect,
} from './feedV2';
import { getAgentClientsList } from 'api/clientsList';

/**
 * This is to be fixed when we implement pagination on UI
 */
const DEFAULT_PAGE_SIZE = 1000;

export const getClientFavoritesPropsEffect = (cfg = {}, options = {}, cb) => {
  const requestParams = { action: requestGetClientFavoritePropsAction, method: getClientFavorites };
  let sendRequest = Api.execBase(requestParams);
  const { feed, context: contextState } = getState();
  const {
    sort: { order, fields },
  } = feed;
  const { context } = contextState;

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const config = {
    userId: cfg?.userId,
    sortField: fields[0],
    sortOrder: order,
    pageNum: cfg.pageNum || 1,
    pageSize: cfg.pageSize || DEFAULT_PAGE_SIZE,
    contextId: context?.ContextKey,
  };

  return sendRequest(config, options, cb);
};

export const getClientFavoritesAllPropsEffect = (cfg = {}, options = {}, cb) => {
  const requestParams = {
    action: requestGetClientFavoritePropsAction,
    method: getClientAllFavorites,
  };
  let sendRequest = Api.execBase(requestParams);
  const { feed, context: contextState } = getState();
  const {
    sort: { order, fields },
  } = feed;
  const { context } = contextState;

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const config = {
    sortField: fields[0],
    sortOrder: order,
    pageNum: cfg.pageNum || 1,
    pageSize: cfg.pageSize || DEFAULT_PAGE_SIZE,
    contextId: context?.ContextKey,
  };

  return sendRequest(config, options, cb);
};

export const resetClientFavoritesPropsEffect = () => (dispatch) => {
  dispatch(resetClientFavoritePropsAction());
};

export const getAgentClientsWithFavoritesCountEffect = (cfg = {}, options = {}, cb) => {
  const requestParams = {
    action: requestAgentClientsAction,
    method: getAgentClientssWithFavoritesCount,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const getClientsEffect = (cfg = {}, options = {}, cb) => {
  const requestParams = { action: requestGetClientsAction, method: getAgentClientsList };
  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const getComparePropertiesEffect = (cfg, options = {}, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetComparePropertiesAction,
    method: getCompareProperties,
  });
  const {
    feed: { compares },
  } = getState();
  const ids = compares.data.map(({ Id }) => Id);

  return sendRequest({ ids }, options, cb);
};

export const sendSharePropsToRecipientsEffect = (cfg = {}, options = {}, cb) => {
  const requestParams = { action: requestSendSharePropsToRecipientsAction, method: sendShareProps };
  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const config = {
    MLSIds: cfg?.shares?.map(({ Id }) => Id),
    ClientIds: cfg?.recipients?.map(({ Id }) => Id),
  };

  return sendRequest(config, options, cb);
};

export const updateSearchCriteriaEffect = (cfg = {}, options, cb) => {
  const sendRequest = Api.execResult({
    action: requestUpdateSearchCriteriaAction,
    method: onBoardingUser,
  });
  const { user, clientInstances } = getState();
  const userRole = user?.data?.Roles?.[0];

  const defaultUserPropertySearchPreferences =
    (userRole === AGENT
      ? get(user, `data.Agent.FeedPrefs`)
      : get(clientInstances, `data.searchInstances[0].DefaultPropertySearchPreferences`)) || {};

  const getRoleSpecificUpdatePayload = (searchPrefs) => {
    if (userRole === AGENT) {
      return {
        [userRole]: {
          FeedPrefs: searchPrefs,
        },
      };
    } else {
      // Otherwise Client
      // For Clients will pick update the default search instance. Should be updated afterwards
      const defaultSearchInstanceId = clientInstances.data.searchInstances[0].Id;

      return {
        [userRole]: {
          SearchInstance: {
            Id: defaultSearchInstanceId,
            DefaultPropertySearchPreferences: searchPrefs,
          },
        },
      };
    }
  };

  const getParkingSpaces = () => {
    if (userRole === AGENT) {
      return { MinParkingSpaces: +cfg.MinParkingSpaces || undefined };
    }

    // T3-1: BUYER replaced with CLIENT
    if (userRole === CLIENT) {
      return {
        Parking: cfg.MinParkingSpaces
          ? {
              NumOfParkingSpaces: +cfg.MinParkingSpaces,
              ImportanceAndWeight: {
                Importance:
                  defaultUserPropertySearchPreferences?.Parking?.ImportanceAndWeight?.Importance ||
                  SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
              },
            }
          : undefined,
      };
    }
  };

  const config = {
    id: user?.data?.Id,
    role: mapRoles[userRole],
    Values: {
      Email: user?.data?.Email,

      ...getRoleSpecificUpdatePayload({
        ...defaultUserPropertySearchPreferences,
        ...(cfg?.Locations?.length ? { Locations: cfg?.Locations } : { Locations: undefined }),
        HomeType: cfg?.HomeType && cfg.HomeType.length ? cfg.HomeType : undefined,
        HOARange: +cfg?.HOARange?.Max || undefined,
        ...getParkingSpaces(),
        Status:
          cfg?.Status && cfg?.Status.length ? cfg?.Status.map(({ value }) => value) : undefined,
        MaxDaysOnMarket: +cfg.MaxDaysOnMarket || undefined,
        ...(cfg.PriceRange?.Min || cfg?.PriceRange?.Max
          ? {
              PriceRange: {
                Min: +cfg.PriceRange?.Min || undefined,
                Max: +cfg.PriceRange?.Max || undefined,
              },
            }
          : { PriceRange: undefined }),
        ...(cfg.NumBedroomsRange?.Min
          ? {
              NumBedroomsRange: {
                Min: cfg.NumBedroomsRange?.Min,
              },
            }
          : { NumBedroomsRange: undefined }),
        ...(cfg.NumBathroomsRange?.Min
          ? {
              NumBathroomsRange: {
                Min: cfg.NumBathroomsRange?.Min,
              },
            }
          : { NumBathroomsRange: undefined }),
        ...(cfg.SquareFeetRange?.Min || cfg.SquareFeetRange?.Max
          ? {
              SquareFeetRange: {
                Min: +cfg.SquareFeetRange?.Min || undefined,
                Max: +cfg.SquareFeetRange?.Max || undefined,
              },
            }
          : { SquareFeetRange: undefined }),
        ...(cfg.LotSizeRange?.Min || cfg.LotSizeRange?.Max
          ? {
              LotSizeRange: {
                Min: +cfg.LotSizeRange?.Min || undefined,
                Max: +cfg.LotSizeRange?.Max || undefined,
              },
            }
          : { LotSizeRange: undefined }),
        ...((cfg.YearBuiltRange?.Min !== noRanges.noMin && cfg.YearBuiltRange?.Min) ||
        (cfg.YearBuiltRange?.Max !== noRanges.noMax && cfg.YearBuiltRange?.Max)
          ? {
              YearBuiltRange: {
                Min:
                  cfg.YearBuiltRange?.Min !== noRanges.noMin && cfg.YearBuiltRange?.Min
                    ? +cfg.YearBuiltRange?.Min
                    : undefined,
                Max:
                  cfg.YearBuiltRange?.Max !== noRanges.noMax && cfg.YearBuiltRange?.Max
                    ? +cfg.YearBuiltRange?.Max
                    : undefined,
              },
            }
          : { YearBuiltRange: undefined }),
        ...(cfg.StoriesRange?.Min || cfg.StoriesRange?.Max
          ? {
              StoriesRange: {
                Min: +cfg.StoriesRange.Min || undefined,
                Max: +cfg.StoriesRange.Max || undefined,
              },
            }
          : { StoriesRange: undefined }),
        ...(cfg.Stories
          ? {
              Stories: {
                ...(cfg?.Stories || {}),
                StoriesRange: {
                  Min: +cfg?.Stories?.StoriesRange?.Min || undefined,
                  Max: +cfg?.Stories?.StoriesRange?.Max || undefined,
                },
              },
            }
          : { Stories: undefined }),
      }),
    },
  };

  return sendRequest(config, options, cb);
};

export const resetPropertiesWholeTabsEffect =
  (cfg = {}, options, cb) =>
  (dispatch) => {
    const { feed } = getState();
    const { activeTab } = feed || {};
    const getUpdateEffect = () => {
      switch (activeTab) {
        case propertiesTabsIds.all:
          return getAllPropsV2Effect;
        case propertiesTabsIds.new:
          return getNewPropsV2Effect;
        case propertiesTabsIds.savedSearches:
          return getSavedSearchesPropsV2Effect;
        case propertiesTabsIds.statusChanges:
          return getStatusChangesPropsV2Effect;
        case propertiesTabsIds.recommended:
          return getRecommendedPropsV2Effect;
        case propertiesTabsIds.clientFavorites:
          return getClientFavoritesPropsEffect;
        case propertiesTabsIds.openHouses:
          return getOpenHousesPropsV2Effect;
        case propertiesTabsIds.priceReductions:
          return getPriceReductionsPropsV2Effect;
        case propertiesTabsIds.watchlist:
          return getWatchlistPropsV2Effect;
        case propertiesTabsIds.backOnMarket:
          return getBackOnMarketPropsV2Effect;
        case propertiesTabsIds.suggestions:
          return getSuggestionsPropsV2Effect;
        default:
          return null;
      }
    };

    const effect = getUpdateEffect();
    if (effect) {
      dispatch(resetPropertiesWholeTabsAction());
      dispatch(effect(cfg, options, cb));
    }
  };

export const sortFeedEffect = (cfg) => (dispatch) => dispatch(sortFeedAction(cfg));

export const setMultipleModeEffect = (cfg) => (dispatch) => dispatch(setMultipleModAction(cfg));

export const setComparesEffect = (cfg) => (dispatch) => {
  const config = cfg.map((property) => {
    const { Media, PhotoCount, PhotoUrls } = property;
    const OrderedPhotos = extractOrderedPhotos(Media, PhotoCount);
    const ImagesToSend = !isEmpty(OrderedPhotos) ? OrderedPhotos : PhotoUrls;
    return {
      Id: property.Id,
      PhotoUrls: ImagesToSend,
      Address: property.Address,
      SellingPrice: property.SellingPrice,
      NumBeds: property.NumBeds,
      NumBathsTotal: property.NumBathsTotal,
      SquareFeet: property.SquareFeet,
      ListingKey: property.ListingKey,
    };
  });

  return dispatch(setComparesAction({ data: config }));
};

export const openFeedFilterDrawerEffect = (cfg) => (dispatch) =>
  dispatch(openFeedFilterDrawerAction(cfg));

export const openShareDrawerEffect = (cfg) => (dispatch) => dispatch(openShareDrawerAction(cfg));

export const setFeedDrawerCommuteIdEffect = (cfg) => (dispatch) =>
  dispatch(setFeedDrawerCommuteIdAction(cfg));

export const openCompareModalEffect = (cfg) => (dispatch) => dispatch(openCompareModalAction(cfg));

export const deleteCompareItemEffect = (cfg) => (dispatch) =>
  dispatch(deleteCompareItemAction(cfg));

export const shareDrawerSearchClientsEffect = (cfg) => (dispatch) =>
  dispatch(searchClientsAction(cfg));

export const setRecipientsEffect = (cfg) => (dispatch) => dispatch(setRecipientsAction(cfg));

export const setActiveTabEffect = (cfg) => (dispatch) => dispatch(setActiveTabAction(cfg));

export const setSharePropertyEffect = (cfg) => (dispatch) => dispatch(setSharePropertyAction(cfg));

export const resetComparePropertiesStateEffect = (cfg) => (dispatch) =>
  dispatch(resetComparePropertiesStateAction(cfg));

export const resetFullDataComparePropertiesEffect = (cfg) => (dispatch) =>
  dispatch(resetFullDataComparePropertiesAction(cfg));

export const initiateImageAnalysisEffect = (cfg, options, cb) => {
  const requestParams = {
    action: initialImageAnalysisAction,
    method: initiateImageAnalysisMethod,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }
  return sendRequest(cfg, options, cb);
};
