import PropTypes from 'prop-types';

import styles from './styles.module.scss';
import { Icons } from '../Icons';

export const InactiveLink = ({ info }) => {
  return (
    <div>
      <div className={styles.wrapper}>
        <Icons variant={Icons.CONCIERGE_ATTORNEY_GREY} className={styles.icon} />
        <div className={styles.inactiveText}>{info}</div>
      </div>
    </div>
  );
};

InactiveLink.propTypes = {
  info: PropTypes.string,
  title: PropTypes.string,
};

InactiveLink.defaultProps = {
  info: 'This link is not active.',
};
