import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { AgentDetails, Spinner } from 'components';
import { link } from 'settings/navigation/link';
import { LocationDetails } from './LocationDetails';
import { DateAndTime } from './DateAndTime';
import { Notes } from './Notes';
import { Footer } from './Footer';
import { requestUpdateShowingAppointmentEffect } from 'store/effects/showingAppointment';
import {
  getShowingAppointmentDetails,
  getShowingDetails,
} from 'store/selectors/showingAppointment';
import { AppointmentForm, AppointmentStatus } from 'types';
import { showSuccessMessage } from 'helpers/success';
import { requestFillShowingAppointment } from 'store/actions/showingAppointment';
import { ConfirmationWithReasonModal } from 'components-antd';
import { ApprovalAction } from 'types/showingAppointment';
import { useExternalVerificationRestriction } from 'features/externalVerification/useExtermalVerificationRestriction';
import { getUserId, getUserRolesSelector } from 'store/selectors/user';
import { Role } from 'app-constants';

import styles from './styles.module.scss';
import { appGetNotificationMessagesEffect, userGetDataEffect } from 'store/effects';
import { socketsInitEffect } from 'store/effects/sockets';
export const Confirmation = (props) => {
  const {
    setIsLoading,
    selectedDateTime,
    setAppointmentFormData,
    appointmentFormData,
    setAppointmentCreated,
  } = props;
  const {
    isPending,
    PropertyTransaction,
    SellerAgent,
    AgentBrokerage,
    DisplayBookingPageNotes,
    BookingPageNotes,
  } = useSelector(getShowingDetails);
  const appointmentStatus = appointmentFormData?.AppointmentStatus;
  const appointmentData: AppointmentForm = useSelector(getShowingAppointmentDetails);
  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRolesSelector);

  const history = useHistory();
  const dispatch = useDispatch();

  const { token: externalToken } = useExternalVerificationRestriction();

  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState(null);
  const [actionConfig, setActionConfig] = useState({
    action: '',
  });

  useEffect(() => {
    if (userId) {
      dispatch(userGetDataEffect({}, { showError: false }));
      dispatch(socketsInitEffect());
      dispatch(appGetNotificationMessagesEffect());
    }
  }, []);

  const onConfirmationCancel = () => {
    setShowModal(false);
    setReason(null);
  };

  const processAction = (action) => {
    setShowModal(true);
    setActionConfig({ ...actionConfig, action });
  };

  const onConfirm = () => {
    switch (actionConfig?.action) {
      case ApprovalAction.Rescheduled:
        onReschedule();
        break;
      case ApprovalAction.Cancel:
        onCancel();
        break;
      default:
        break;
    }
    setShowModal(false);
  };

  const onCancel = () => {
    const data = { AppointmentStatus: AppointmentStatus.Canceled, Reason: reason };
    setIsLoading(true);
    dispatch(
      requestUpdateShowingAppointmentEffect(
        { UUID: appointmentFormData.UUID, ...data },
        {
          externalToken,
        },
        (err) => {
          setIsLoading(false);
          if (!err) {
            showSuccessMessage('Showing Cancelled Successfully');
            setAppointmentFormData({
              ...appointmentFormData,
              AppointmentStatus: AppointmentStatus.Canceled,
            });
            setReason(null);
          }
        },
      ),
    );
  };

  const onReschedule = () => {
    appointmentData.IsRescheduled = true;
    dispatch(requestFillShowingAppointment({ data: appointmentData }));
    setAppointmentFormData({ ...appointmentFormData, IsRescheduled: true });
    const UUID = appointmentFormData?.ShowingDetails?.UUID;
    UUID ? history.push(link.toScheduleShowingAppointment(UUID)) : setAppointmentCreated(false);
  };

  const onCreateNew = () => {
    dispatch(requestFillShowingAppointment({ data: {} }));
    const UUID = appointmentFormData?.ShowingDetails?.UUID;
    UUID ? history.push(link.toScheduleShowingAppointment(UUID)) : location.reload();
  };

  const getHeader = () => {
    switch (appointmentStatus) {
      case AppointmentStatus.Pending:
        return <PendingApproval />;
      case AppointmentStatus.Canceled:
        return <ShowingCanceled />;
      case AppointmentStatus.Declined:
        return <ShowingDeclined />;
      case AppointmentStatus.Upcoming:
        return <ShowingConfirmed />;
      default:
        return <></>;
    }
  };

  const showAgentDetails = () => {
    const isAgent =
      appointmentFormData?.ShowingDetails?.AgentId === userId && userRole[0] === Role.Agent;
    return (
      !isAgent && (
        <div className={styles.detailArea}>
          <h4>Listing Agent:</h4>
          <AgentDetails
            SellerAgent={SellerAgent}
            AgentBrokerage={AgentBrokerage}
            isMessages={true}
            agentNameClass={styles.agentName}
            agentPhoneClass={styles.agentPhone}
            emailClass={styles.agentEmail}
          />
        </div>
      )
    );
  };

  return (
    <div className={styles.confirmation}>
      <div className={styles.headWrapper}>{getHeader()}</div>
      {isPending ? (
        <Spinner loaderClassName={classNames(styles.loader)} />
      ) : (
        <>
          <div className={styles.detailArea}>
            <LocationDetails PropertyTransaction={PropertyTransaction} />
          </div>
          <div className={styles.detailArea}>
            <DateAndTime
              selectedDateTime={selectedDateTime}
              appointmentFormData={appointmentFormData}
            />
          </div>
          {showAgentDetails}
          {DisplayBookingPageNotes ? (
            <div className={styles.detailArea}>
              <Notes
                DisplayBookingPageNotes={DisplayBookingPageNotes}
                BookingPageNotes={BookingPageNotes}
              />
            </div>
          ) : null}
          {appointmentFormData?.Id && (
            <div className={styles.footerArea}>
              <Footer
                processAction={processAction}
                onCreateNew={onCreateNew}
                appointmentStatus={appointmentStatus}
              />
            </div>
          )}
        </>
      )}
      <ConfirmationWithReasonModal
        open={showModal}
        confirmText={
          <span>
            Are you sure you want to{' '}
            {actionConfig?.action === ApprovalAction.Rescheduled
              ? 'reschedule'
              : actionConfig?.action}{' '}
            this showing?
          </span>
        }
        placeholder={`Write a reason for ${
          actionConfig?.action === ApprovalAction.Decline ? 'declining' : 'cancelling'
        }...`}
        okText="Confirm"
        showReason={!(actionConfig?.action === ApprovalAction.Rescheduled)}
        setReason={setReason}
        onOk={onConfirm}
        onCancel={onConfirmationCancel}
      />
    </div>
  );
};

function ShowingConfirmed() {
  return (
    <div>
      <div className={styles.headTitle}>Showing Confirmed</div>
      <div className={styles.headTitleDescription}>
        Your showing has been scheduled and the confirmation has been sent to your email address.
      </div>
    </div>
  );
}

function PendingApproval() {
  return (
    <div>
      <div className={styles.headTitle}>Pending Approval</div>
      <div className={styles.headTitleDescription}>
        Your showing request has been submitted for approval. We&apos;ll send you an email as soon
        as it is approved or declined.
      </div>
    </div>
  );
}

function ShowingCanceled() {
  return (
    <div>
      <div className={styles.headTitle}>Showing Canceled</div>
      <div className={styles.headTitleDescription}>
        Your showing with following details has been canceled.
      </div>
    </div>
  );
}

function ShowingDeclined() {
  return (
    <div>
      <div className={styles.headTitle}>Showing Declined</div>
      <div className={styles.headTitleDescription}>This showing has been declined.</div>
    </div>
  );
}
