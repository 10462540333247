import classNames from 'classnames';

import { Input, SingleLocation } from 'components';
import { getLocationContext, getPlaceComponent } from 'helpers/locations';
import {
  LOCALITY,
  POSTAL_CODE,
  ROUTE,
  STATE,
  STREET_NUMBER,
  SUB_PREMISE,
} from 'settings/constants/locations';
import { PropertyLocation } from 'types';

import styles from './styles.module.scss';

interface PropertyAddressProps {
  className?: string;
  unitClassName?: string;
  location?: PropertyLocation;
  valuesWrapperClassName?: string;
  searchWrapperClassName?: string;
  dropdownClassName?: string;
  address?: PropertyLocation;
  showUnit?: boolean;
  variant?: string;
  updateLocation: (location) => void;
  placeholder?: string;
  unitFieldPlaceholderText?: string;
  disabled?: boolean;
}

export const PropertyAddress = ({
  showUnit = true,
  className,
  updateLocation,
  unitClassName,
  address,
  dropdownClassName,
  searchWrapperClassName,
  valuesWrapperClassName,
  location,
  variant,
  placeholder,
  unitFieldPlaceholderText,
  disabled,
}: PropertyAddressProps) => {
  const onAddressChange = (result) => {
    const location = result?.result?.[0];

    if (location) {
      const updatedCity = getLocationContext(location, LOCALITY);
      const updatedState = getLocationContext(location, STATE);
      const updatedZip = getLocationContext(location, POSTAL_CODE);
      const street = getPlaceComponent(location, ROUTE);
      const streetNumber = getLocationContext(location, STREET_NUMBER);
      const subPremise = getLocationContext(location, SUB_PREMISE);

      const updatedAddress: PropertyLocation = {
        ...(address ?? {}),
        City: updatedCity,
        State: updatedState,
        Zip: updatedZip,
        Line1: [streetNumber, street?.shortName ?? street?.name, subPremise]
          .filter((i) => !!i)
          .join(' '),
        ProviderPlaceId: location.place_id,
        PlaceName: result?.placeName,
      };

      updateLocation(updatedAddress);
    }
  };

  const onClearAddress = () => {
    updateLocation(undefined);
  };

  const onUnitAptChange = (_, val) => {
    const updatedAddress: PropertyLocation = {
      ...(address ?? {}),
      Line2: val ?? undefined,
    };

    updateLocation(updatedAddress);
  };

  return (
    <div className={classNames(styles.propertyAddressInput, className)}>
      <SingleLocation
        placeholder={placeholder || 'Enter address...'}
        inputLabel={'Address'}
        types={['address']}
        variant={variant || Input.LIGHT_FULL}
        onResult={onAddressChange}
        testid="address"
        value={location}
        valuesWrapperClassName={classNames(styles.locationValues, valuesWrapperClassName)}
        searchWrapperClassName={classNames(styles.locationsSearch, searchWrapperClassName)}
        dropdownClassName={classNames(styles.locationsDropdown, dropdownClassName)}
        onClear={onClearAddress}
        searchIcon={false}
        disabled={disabled}
      />
      {showUnit && (
        <Input
          className={classNames(styles.unit, unitClassName)}
          placeholder={unitFieldPlaceholderText || '#000'}
          label="Unit/Apt"
          altLabel="(Optional)"
          classAltLabel={styles.altLabel}
          variant={variant || Input.LIGHT_ROUNDED}
          onChange={onUnitAptChange}
          value={address?.Line2 || undefined}
          testid="unit_apt"
        />
      )}
    </div>
  );
};
