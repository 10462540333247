import moment from 'moment';
import { TableColumnsType } from '../components/Table';

export type SalesLeadDataSource = {
  Email: string;
  FirstName: string;
  LastName: string;
};

export const salesLeadColumns: TableColumnsType<SalesLeadDataSource> = [
  {
    title: 'Email',
    dataIndex: 'Email',
    withSort: false,
  },
  {
    title: 'First Name',
    dataIndex: 'FirstName',
    withSort: false,
    render: (v) => v || '---',
  },
  {
    title: 'Last Name',
    dataIndex: 'LastName',
    withSort: false,
    render: (v) => <div style={{ minWidth: '200px' }}>{v || '---'}</div>,
  },
  {
    title: 'Lead Source',
    dataIndex: 'LeadSource',
    withSort: false,
    render: (v) => <div style={{ minWidth: '200px' }}>{v || '---'}</div>,
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    withSort: false,
    render: (v) => <div style={{ minWidth: '200px' }}>{v || '---'}</div>,
  },
  {
    title: 'Created Date',
    dataIndex: 'CreatedDate',
    withSort: true,
    render: (v) => (
      <div style={{ minWidth: '200px' }}>
        {moment(v)?.tz('America/New_York')?.format('YYYY-MM-DD HH:mm A') || '---'}
      </div>
    ),
  },
];

export const salesLeadColumnsRestricted: TableColumnsType<SalesLeadDataSource> = [
  ...[...salesLeadColumns].splice(0, salesLeadColumns.length - 1),
  {
    title: 'State(s)',
    dataIndex: 'LeadInformation',
    withSort: false,
    key: 'LeadInformation',
    render: (v) => {
      return (
        <div style={{ minWidth: '200px' }}>
          {v?.AreasOfOperation?.map((item) => item.PlaceName).join(', ') || '---'}
        </div>
      );
    },
  },
  salesLeadColumns[salesLeadColumns.length - 1],
];

export const salesLeadColumnsSignUpUsers: TableColumnsType<SalesLeadDataSource> = [
  {
    title: 'Team Name',
    dataIndex: 'TeamName',
    withSort: false,
    render: (v) => <div style={{ minWidth: '200px' }}>{v || '---'}</div>,
  },
  {
    title: 'Email',
    dataIndex: ['User', 'Email'],
    withSort: false,
  },
  {
    title: 'First Name',
    dataIndex: ['User', 'FirstName'],
    withSort: false,
    render: (v) => v || '---',
  },
  {
    title: 'Last Name',
    dataIndex: ['User', 'LastName'],
    withSort: false,
    render: (v) => <div style={{ minWidth: '200px' }}>{v || '---'}</div>,
  },
  {
    title: 'Onboarding Finished',
    dataIndex: 'WizardFinished',
    withSort: false,
    render: (v) => <div style={{ minWidth: '200px' }}>{v ? 'Yes' : 'No'}</div>,
  },
  {
    title: 'Created Date',
    dataIndex: ['User', 'CreatedDate'],
    withSort: true,
    render: (v) => (
      <div style={{ minWidth: '200px' }}>
        {moment(v)?.tz('America/New_York')?.format('YYYY-MM-DD HH:mm A') || '---'}
      </div>
    ),
  },
];
