import React, { useMemo } from 'react';
import { Collapse, Panel } from 'components-antd';
import NumberFormat from 'react-number-format';
import { orderBy } from 'lodash-es';

import { Card, Separator } from './..';
import { Icons } from 'pages/Workshop/Icons';
import { getKeyDatesSelector } from 'store/selectors/transaction';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { formatDate, subtractTimeZone } from 'helpers';

type PriceContainerType = {
  label: string;
  price?: number | null;
  hasHistory?: boolean;
};

const PriceContainer = ({ label, price = null, hasHistory = false }: PriceContainerType) => {
  return (
    <div className={styles.priceContainer}>
      <p className={styles.subSectionTitle}>{label}</p>
      {price ? (
        <NumberFormat
          thousandSeparator
          displayType="text"
          value={price}
          prefix="$"
          renderText={(val) => (
            <span className={hasHistory ? styles.historyPrice : styles.price}>{val}</span>
          )}
        />
      ) : (
        <p className={styles.notAvailable}>Not Available</p>
      )}
    </div>
  );
};

const PriceHistoryItem = ({ date, price, listLength, index }) => {
  return (
    <div className={styles.priceHistoryContainer}>
      <div className={styles.date}>{formatDate(date, 'MMM D, YYYY')}</div>
      <div className={styles.point}>
        {listLength - 1 !== index && <div className={styles.line} />}
      </div>
      <NumberFormat
        thousandSeparator
        displayType="text"
        value={price}
        prefix="$"
        renderText={(val) => <span className={styles.price}>{val}</span>}
      />
    </div>
  );
};

const renderPriceHistory = (priceHistory, key, format) => {
  const filteredHistory = priceHistory && priceHistory.filter((data) => data?.Key === key);

  if (filteredHistory && filteredHistory.length === 0) return null;

  return (
    <Collapse
      className={styles.priceHistoryPanel}
      bordered={false}
      expandIcon={({ isActive }) => (
        <Icons variant={!isActive ? Icons.ARROW_DOWN : Icons.ARROW_UP} />
      )}
      expandIconPosition="end"
    >
      <Panel
        key={key}
        header={<p className={styles.subSectionTitle}>Price History</p>}
        className={styles.panel}
      >
        {filteredHistory &&
          orderBy(filteredHistory, 'DateChange', ['desc']).map((data, ind) => (
            <PriceHistoryItem
              key={data?.Id}
              date={subtractTimeZone(data?.DateChange, format)}
              price={data?.Price}
              listLength={filteredHistory?.length}
              index={ind}
            />
          ))}
      </Panel>
    </Collapse>
  );
};

export const ListPrice = ({ priceHistory, isBuyerTransaction }) => {
  const keyDates = useSelector(getKeyDatesSelector);
  const hasListingHistory = useMemo(
    () => priceHistory.some((data) => data?.Key === 'Listing'),
    [priceHistory],
  );
  const hasPurchaseHistory = useMemo(
    () => priceHistory.some((data) => data?.Key === 'Purchase'),
    [priceHistory],
  );
  const hasCloseHistory = useMemo(
    () => priceHistory.some((data) => data?.Key === 'Close'),
    [priceHistory],
  );

  return (
    <Card>
      <div className={styles.listPriceCollapse}>
        {!isBuyerTransaction && (
          <>
            <PriceContainer
              label="List Price"
              price={keyDates?.ListingPrice}
              hasHistory={hasListingHistory}
            />
            {renderPriceHistory(priceHistory, 'Listing', 'MMMM D YYYY')}
            <Separator className={styles.marginTop} />
          </>
        )}
        <PriceContainer
          label="Purchase Price"
          price={keyDates?.UnderContractPrice}
          hasHistory={hasPurchaseHistory}
        />
        {renderPriceHistory(priceHistory, 'Purchase', 'MMMM D YYYY')}
        <Separator />
        <PriceContainer
          label="Closed Price"
          price={keyDates?.ClosePrice}
          hasHistory={hasCloseHistory}
        />
        {renderPriceHistory(priceHistory, 'Close', 'MMMM D YYYY')}
      </div>
    </Card>
  );
};
