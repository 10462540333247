import { createAction } from 'redux-actions';

export const socketsStoreNewTransactionTaskMessageAction = createAction(
  'SOCKETS/STORE_NEW_TRANSACTION_TASK_MESSAGE',
);
export const socketsStoreEditedTransactionTaskMessageAction = createAction(
  'SOCKETS/STORE_EDITED_ TRANSACTION_TASK_MESSAGE',
);
export const socketsRemoveDeletedTransactionTaskMessageAction = createAction(
  'SOCKETS/REMOVE_DELETED_ TRANSACTION_TASK_MESSAGE',
);
export const socketsStoreTransactionTaskMessagesByThreadIdAction = createAction(
  'SOCKETS/STORE_TRANSACTION_TASK_MESSAGES_BY_THREAD_ID',
);
export const socketsResetTransactionTaskMessagesAction = createAction(
  'SOCKETS/RESET_TRANSACTION_TASK_MESSAGES',
);
export const socketSetEditTransactionTaskMessageAction = createAction(
  'SOCKETS/SET_EDIT_  TRANSACTION_TASK_MESSAGE',
);
export const setNewTransactionTaskMessageWithTempIdAction = createAction(
  'SET_NEW_TRANSACTION_TASK_MESSAGE_WITH_TEMP_ID',
);
export const replaceTempTransactionTaskMessageAction = createAction(
  'REPLACE_TEMP_TRANSACTION_TASK_MESSAGE',
);
export const socketsStoreTransactionTaskMessagesSetThreadIdAction = createAction(
  'SOCKETS/STORE_TRANSACTION_TASK_MESSAGES_SET_THREAD_ID',
);
