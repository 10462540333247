import {
  openNotificationsDrawerAction,
  setNotificationsDrawerParamsAction,
} from 'store/actions/drawers/notifications';

export const setNotificationsDrawerParamsEffect = (cfg) => (dispatch) =>
  dispatch(setNotificationsDrawerParamsAction(cfg));

export const openNotificationsDrawerEffect =
  (cfg, options = {}) =>
  (dispatch) => {
    if (!options.notClearParams) {
      setTimeout(() => dispatch(setNotificationsDrawerParamsEffect({})), 300);
    }
    dispatch(openNotificationsDrawerAction(cfg));
  };
