import classNames from 'classnames';

import { DocImage } from 'components/Icons/DocImage';
import { Actions } from '../Actions';
import { useSelector } from 'react-redux';
import { getUserId } from 'store/selectors/user';

import styles from './styles.module.scss';

export const FormBox = ({ form, taskId, transactionId }) => {
  const userId = useSelector(getUserId);
  return (
    <div className={styles.formBox}>
      <div className={styles.leftContent}>
        <DocImage className={styles.icon} width={20} height={24} />
        <div className={styles.titleContainer}>
          <div className={styles.title}>{form.formName}</div>
          <div className={styles.subtitle}>{`Client: ${form.clientName}`}</div>
        </div>
      </div>
      <div className={styles.rightContent}>
        <div
          className={styles.formStatus}
          style={{ color: form.color, backgroundColor: form.bgColor }}
        >
          <span>{form.status}</span>
        </div>
        <div className={classNames({ [styles.hide]: form.creatorId !== userId })}>
          <Actions data={form} taskId={taskId} transactionId={transactionId} />
        </div>
      </div>
    </div>
  );
};
