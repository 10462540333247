import { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionContactsEffect } from 'store/effects';
import Notes from '../../../../../../../pages/Workshop/Transactions/components/Notes/Notes';

export const TransactionNotes = ({ participants, transactionId }) => {
  const dispatch = useDispatch();
  const [showNotes, setShowNotes] = useState(false);

  useEffect(() => {
    dispatch(
      getTransactionContactsEffect({
        id: transactionId,
      }),
    );
  }, [transactionId]);

  return (
    <>
      <Notes isTransactionDrawer={true} handleCloseNotesModal={() => setShowNotes(false)} />
    </>
  );
};
