import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { map } from 'lodash-es';
import classNames from 'classnames';

import { routes } from 'settings/navigation/routes';
import { getUserRolesSelector, getUserDataSelector } from 'store/selectors/user';
import { getWorkshopLinksByRoles } from 'settings/navigation/linksByRoles';

import { Select } from 'antd';
import { ArrowDown } from 'components/Icons';

const { Option } = Select;

export const Switcher = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const roles = useSelector(getUserRolesSelector);
  const user = useSelector(getUserDataSelector);
  const links = useMemo(() => getWorkshopLinksByRoles(roles, { user }), [roles, user]);

  const handleChange = (route) => {
    history.push(route);
  };

  const getDefaultValue = () => {
    if (pathname.includes('/transactions')) return routes.transactions;
    else if (pathname.includes('/projects')) return routes.projects;
    else if (pathname.includes('/showings')) return routes.showings;
    else if (pathname.includes('/offers')) return routes.offers;
    else if (pathname.includes('/forms')) return routes.workshopForms;
    else if (pathname.includes('/tasks')) return routes.tasks;
    else if (pathname.includes('/milestones')) return routes.milestones;
  };

  return (
    <Select
      defaultValue={getDefaultValue()}
      className={classNames('mosaikPageSwitcher')}
      popupClassName={classNames('mosaikPageSwitcherDropdown')}
      onChange={handleChange}
      bordered={false}
      suffixIcon={<ArrowDown />}
      getPopupContainer={(triggerNode) => triggerNode}
      listHeight={364}
    >
      {map(links, ({ link, title }) => (
        <Option key={link} value={link}>
          {title}
        </Option>
      ))}
    </Select>
  );
};
