import PropTypes from 'prop-types';
import classNames from 'classnames';

import AddressInfo from '../../AddressInfo';
import { TransactionAccess } from '../TransactionAccess';

import styles from './styles.module.scss';

const Client = (props) => {
  const { className, info } = props;
  const { Address } = info || {};

  return (
    <div className={classNames(styles.seller, className)}>
      <AddressInfo value={Address} />
      <TransactionAccess />
      {/* TODO: Check how it's actually used, because backend didn't return these values */}
      {/* {ShowingNotes && <InfoItem label="Showing Notes" value={ShowingNotes} />}
      {AgentNotes && <InfoItem label="Agent Notes" value={AgentNotes} />} */}
    </div>
  );
};

Client.propTypes = {
  className: PropTypes.string,
  info: PropTypes.shape({
    Address: PropTypes.string,
  }),
};

Client.defaultProps = {
  className: '',
  info: {},
};

export default Client;
