export const getCreateTaskPayload = (payload, assigneeIds) => {
  let tempLinks = payload?.links?.split(',');
  let links = tempLinks?.map((link) => ({ link: link, label: link })) || [];
  return {
    Title: payload?.taskName || payload?.task_name,
    Category: payload?.tags,
    DueDate: payload?.dueDate || payload?.due_date,
    AssigneeIds: assigneeIds,
    Description: payload?.description,
    Links: links,
    CcIds: [],
    SaveAsTemplate: false,
  };
};

export const getEditTaskPayload = (payload, assigneeIds) => {
  let response = {};

  if (assigneeIds && assigneeIds.length > 0) {
    response['AssigneeIds'] = assigneeIds;
  }
  if (payload?.taskName || payload?.task_name) {
    response['Title'] = payload?.taskName || payload?.task_name;
  }

  if (payload?.tags) {
    response['Category'] = payload.tags;
  }

  if (payload?.dueDate || payload?.due_date) {
    response['DueDate'] = payload?.dueDate || payload?.due_date;
  }

  if (payload?.description) {
    response['Description'] = payload.description;
  }

  if (payload?.links) {
    let tempLinks = payload?.links?.split(',');
    let links = tempLinks?.map((link) => ({ link: link, label: link })) || [];

    response['Links'] = links;
  }

  return response;
};

export const getCreateMilestonePayload = (payload) => {
  return {
    Title: payload?.milestone,
    DueDate: payload?.due_date,
    IsPrivate: payload?.private,
  };
};

export const getEditMileastonePayload = (payload) => {
  const response = {};
  if (payload?.milestone) {
    response['Title'] = payload?.milestone;
  }
  if (payload?.due_date) {
    response['DueDate'] = payload?.due_date;
  }
  if (payload?.private) {
    response['IsPrivate'] = payload?.private || payload?.private === 'yes' ? true : false;
  }
  return response;
};
