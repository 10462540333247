import React from 'react';
import moment from 'moment';

import { subtractTimeZone } from 'helpers';

import styles from './styles.module.scss';

export function tableColumns() {
  const columns = [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'CreatedDate',
      width: 250,
      sorter: (a, b) => {
        if (a.CreatedDate < b.CreatedDate) return -1;
        if (a.CreatedDate > b.CreatedDate) return 1;
        return 0;
      },
      render: (_, { CreatedDate }) => {
        let date = moment(CreatedDate).format('DD/MM/YYYY');
        return <span className={styles.tableText}>{CreatedDate ? `${date}` : '-'}</span>;
      },
    },
    {
      title: 'Login At',
      key: 'loginAt',
      dataIndex: 'CreatedDate',
      width: 250,
      sorter: (a, b) => {
        if (a.CreatedDate < b.CreatedDate) return -1;
        if (a.CreatedDate > b.CreatedDate) return 1;
        return 0;
      },
      render: (_, { CreatedDate }) => {
        let time = moment(CreatedDate).format('hh:mm A');
        return <span className={styles.tableText}>{CreatedDate ? `${time}` : '-'}</span>;
      },
    },
    {
      title: 'IP Address',
      key: 'iPAddress',
      dataIndex: 'IPAddress',
      width: 150,
      sorter: (a, b) => {
        if (a.IPAddress < b.IPAddress) return -1;
        if (a.IPAddress > b.IPAddress) return 1;
        return 0;
      },
      render: (_, { IPAddress }) => {
        return <span className={styles.tableText}>{IPAddress ? IPAddress : '-'}</span>;
      },
    },
  ];

  return columns;
}
