import React from 'react';

const Delete = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.41748 7.14233H20.4175"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4175 11.1433V17.1433"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4146 11.1433V17.1433"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.41455 7.14233L6.41455 19.1423C6.41455 19.6728 6.62526 20.1815 7.00034 20.5565C7.37541 20.9316 7.88412 21.1423 8.41455 21.1423H16.4146C16.945 21.1423 17.4537 20.9316 17.8288 20.5565C18.2038 20.1815 18.4146 19.6728 18.4146 19.1423L19.4146 7.14233"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.41602 7.14282V4.14282C9.41602 3.87761 9.52137 3.62325 9.70891 3.43572C9.89645 3.24818 10.1508 3.14282 10.416 3.14282H14.416C14.6812 3.14282 14.9356 3.24818 15.1231 3.43572C15.3107 3.62325 15.416 3.87761 15.416 4.14282V7.14282"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
export default Delete;
