import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NumberFormat from 'react-number-format';
import styles from './styles.module.scss';
import { getPropertyAddress } from '../../../../../helpers';

const AddressAndPrice = (props) => {
  const { className, address, price } = props;

  return (
    <div className={classNames(styles.addressAndPrice, className)}>
      <span testid="address">{`${getPropertyAddress(address)}`}</span>
      <span>{price ? ', ' : ''}</span>
      <NumberFormat
        thousandSeparator
        displayType="text"
        value={price}
        prefix="$"
        renderText={(val) => (
          <span testid="property_price" className={styles.price}>
            {val}
          </span>
        )}
      />
    </div>
  );
};

AddressAndPrice.propTypes = {
  className: PropTypes.string,
  address: PropTypes.shape({
    Line1: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
  }),
  price: PropTypes.number,
};

AddressAndPrice.defaultProps = {
  className: '',
  price: null,
  address: {},
};

export default AddressAndPrice;
