import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Header = (props) => {
  const { className, onClose } = props;

  return (
    <div className={classNames(styles.header, className)}>
      <div />
      <div onClick={onClose} className={styles.close}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 16L16 8" stroke="#303030" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M16 16L8 8" stroke="#303030" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      </div>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

Header.defaultProps = {
  className: '',
  onClose: () => {},
};

export default Header;
