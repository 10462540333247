import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TransactionLeadSource } from 'app-constants';
import { getTransactionEffect } from 'store/effects';
import { getLeadSourceNames, updateLeadSource } from 'api/financials';

import { Card, NameAutocomplete } from './..';
import { Select } from 'components';
import { Button } from 'components-antd';
import { Icons } from 'pages/Workshop/Icons';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { getUserId } from 'store/selectors/user';
import { getTransactionAccessSelector } from 'store/selectors/transaction';

const leadSourceOptions = Object.values(TransactionLeadSource).map((type) => ({
  value: type,
  name: type,
}));

export const LeadSource = ({
  leadSource,
  transactionId,
  transactionCreator,
  isTransactionAdminOrOwner,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [hover, setHover] = useState(false);
  const [inputField, setInputField] = useState<any>(leadSource ?? '');
  const [isPending, setIsPending] = useState(false);
  const loggedInUserId = useSelector(getUserId);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [autocompleteLeadSourceNames, setAutocompleteLeadSourceNames] = useState<any>(null);

  const fetchAutocompleteLeadSourceNames = async () => {
    let autocompleteLeadSourceNames = await getLeadSourceNames();
    setAutocompleteLeadSourceNames(autocompleteLeadSourceNames?.LeadSourceTitles);
  };

  useEffect(() => {
    fetchAutocompleteLeadSourceNames();
  }, []);

  useEffect(() => {
    setInputField(leadSource);
  }, [leadSource]);

  const onCancel = () => {
    setIsEdit(false);
    setInputField(leadSource);
  };

  const isTransctionCreator = useMemo(
    () => loggedInUserId === transactionCreator?.Id,
    [transactionCreator],
  );

  const handleUpdateLeadSource = async () => {
    try {
      setIsPending(true);
      await updateLeadSource({
        transactionId: transactionId,
        data: { LeadSource: inputField },
      });
      dispatch(getTransactionEffect({ id: transactionId }, { silent: true }));
      setIsPending(false);
    } catch (err) {
      setIsPending(false);
      setIsEdit(false);
    } finally {
      setIsEdit(false);
      setIsPending(false);
    }
  };

  if (!fullAccess && !isTransactionAdminOrOwner && !isTransctionCreator) return null;

  return (
    <Card>
      <div className={styles.leadSourceContainer}>
        <p className={styles.title}>Lead Source</p>
        {!isEdit ? (
          <div
            className={styles.optionSelectedContainer}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div className={styles.optionSelected}>
              <span className={styles.text}>{leadSource || 'None'}</span>
            </div>
            {(isTransactionAdminOrOwner || isTransctionCreator) && (
              <Icons
                variant={Icons.EDIT_ROUND}
                className={classNames(styles.editIcon, { [styles.hideIcon]: !hover })}
                onClick={() => setIsEdit(true)}
              />
            )}
          </div>
        ) : (
          <>
            <NameAutocomplete
              label=""
              allNames={autocompleteLeadSourceNames?.map((item, idx) => ({
                label: item,
                value: idx,
              }))}
              value={inputField}
              onChange={(value) => setInputField(value)}
              onSelect={(value) => setInputField(value)}
            />
            <div className={styles.buttons}>
              <Button
                variant="secondary"
                size="large"
                onClick={handleUpdateLeadSource}
                loading={isPending}
              >
                Update
              </Button>
              <Button variant="hollow-bordered" size="large" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};
