import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import Title from '../Title';
import DownloadPdf from '../../DownloadPdf';

const Header = ({ className }) => (
  <div className={classNames(styles.header, className)}>
    <Title>Compare Listings</Title>
    <DownloadPdf />
  </div>
);

Header.propTypes = {
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};

export default Header;
