import { get } from 'lodash-es';
import { validate as uuidValidate } from 'uuid';

import { ResponseType } from 'types';
import { Document } from 'pages/Quotes/components/QuoteDetails/components/Content/ThirdPartyQuote/Details/Document';

export const transformFormResponse = (responses: ResponseType[] = []) => {
  const result = responses.map((el) => {
    try {
      let transformedAnswer = JSON.parse(el?.Answer);

      if (Array.isArray(transformedAnswer) && get(transformedAnswer, '0.Url', '')) {
        transformedAnswer = transformedAnswer
          .map((el) => {
            return el?.Url;
          })
          .filter((el) => el);
      }

      if (Array.isArray(transformedAnswer)) {
        return {
          ...el,
          Answer: transformedAnswer.join(', '),
        };
      }
    } catch (e) {
      return el;
    }

    return el;
  });

  return result;
};

export const transformJSXResponse = (
  answer: string,
  separator = ', ',
  className?: string,
  listClassName?: string,
  hyperLinkClassName?: string,
) => {
  try {
    const transformedAnswer = JSON.parse(answer);
    if (Array.isArray(transformedAnswer) && get(transformedAnswer, '0.Url', '')) {
      return (
        <div className={hyperLinkClassName}>
          {transformedAnswer.map((answer) => (
            <a target="_blank" rel="noreferrer" href={answer?.Url}>
              {answer?.name || answer?.Url}
            </a>
          ))}
        </div>
      );
    } else if (Array.isArray(transformedAnswer)) {
      return <div className={listClassName}>{transformedAnswer.join(separator)}</div>;
    } else if (!Array.isArray(transformedAnswer)) {
      const keys = Object.keys(transformedAnswer);
      if (keys?.length) {
        const urlValue: string = transformedAnswer['Url'] || transformedAnswer['DocumentVaultUUID'];

        if (urlValue) {
          if (uuidValidate(urlValue)) {
            return <Document answer={transformedAnswer} />;
          } else {
            return <img style={{ maxWidth: 100 }} src={urlValue} alt="Signature" />;
          }
        }
      }
    }
  } catch (err) {
    //
  }
  return className ? <div className={className}>{answer}</div> : <>{answer}</>;
};
