import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Wrapper as PendingWrapper } from 'components';
import styles from './styles.module.scss';

const Content = (props) => {
  const { className, children, isPending } = props;

  return (
    <div className={classNames(styles.content, className)}>
      <PendingWrapper
        isPending={isPending}
        className={classNames(styles.pendingWrapper, { [styles.pending]: isPending })}
      >
        {children}
      </PendingWrapper>
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isPending: PropTypes.bool,
};

Content.defaultProps = {
  className: '',
  isPending: false,
};

export default Content;
