import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { Close } from '../../Icons';

const FlatValues = (props) => {
  const { className, participants, onDelete, testid } = props;

  if (!participants || !participants?.length) return null;

  return (
    <div testid={testid} className={classNames(styles.values, className)}>
      {participants.map((participant) => (
        <span key={participant.id} className="flatValue">
          <span testid="flat_value" className={styles.value}>
            {participant.name}
            <Close onClick={() => onDelete(participant.id)} className={styles.icon} />
          </span>
        </span>
      ))}
    </div>
  );
};

FlatValues.propTypes = {
  className: PropTypes.string,
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  onDelete: PropTypes.func,
  testid: PropTypes.string,
};

FlatValues.defaultProps = {
  className: '',
  testid: undefined,
  onDelete: () => {},
};

export default FlatValues;
