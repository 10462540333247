import { createAction } from 'redux-actions';

export const socketsStoreInitThreadsAction = createAction('SOCKETS/STORE_INIT_THREADS');
export const socketsStoreNewThreadAction = createAction('SOCKETS/STORE_NEW_THREAD');
export const socketsStoreRemovedFromThreadAction = createAction('SOCKETS/STORE_REMOVE_FROM_THREAD');
export const socketsStoreUpdatedThreadAction = createAction('SOCKETS/STORE_UPDATED_THREAD');
export const socketsStoreParticipantOnlineStatusAction = createAction(
  'SOCKETS/STORE_PARTICIPANT_ONLINE_STATUS',
);
export const socketsMarkThreadAsReadAction = createAction('SOCKETS/MARK_THREAD_AS_READ');
export const socketsChangeThreadArchiveStatusAction = createAction(
  'SOCKETS/CHANGE_THREAD_ARCHIVE_STATUS',
);
export const socketsStoreMessagesSetThreadIdAction = createAction(
  'SOCKETS/STORE_MESSAGES_SET_THREAD_ID',
);
