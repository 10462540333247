import { orderBy } from 'lodash-es';
import moment from 'moment';
import { createSelector } from 'reselect';
import { PENDING } from 'settings/constants/apiState';

const localState = ({ milestoneAggregate }) => milestoneAggregate;

export const getMilestoneDataSelector = createSelector(localState, ({ data }) => data);

export const getMilestoneStateSelector = createSelector(localState, ({ state, ...rest }) => ({
  isPending: state === PENDING,
  ...rest,
}));

export const getSortedMilestones = createSelector(getMilestoneDataSelector, (data) => {
  if (!data) return null;
  return orderBy(data, (item) => moment(item.DueDate).valueOf(), ['asc']);
});

export const getMilestoneArchiveStateSelector = createSelector(
  localState,
  ({ isArchive }) => isArchive,
);
