export const PreApproveVerified = () => {
  return (
    <>
      <svg
        style={{ margin: '0px 5px' }}
        width="3"
        height="3"
        viewBox="0 0 3 3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="1.5" cy="1.5" r="1.5" fill="#D9D9D9" />
      </svg>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ margin: '0px 5px' }}
      >
        <path
          d="M6.32993 1.20253C7.16861 0.0964583 8.83139 0.0964586 9.67007 1.20253C10.1277 1.80604 10.8753 2.11571 11.6256 2.01254C13.0008 1.82347 14.1765 2.99923 13.9875 4.37438C13.8843 5.1247 14.194 5.87232 14.7975 6.32993C15.9035 7.16861 15.9035 8.83139 14.7975 9.67007C14.194 10.1277 13.8843 10.8753 13.9875 11.6256C14.1765 13.0008 13.0008 14.1765 11.6256 13.9875C10.8753 13.8843 10.1277 14.194 9.67007 14.7975C8.83139 15.9035 7.16861 15.9035 6.32993 14.7975C5.87232 14.194 5.1247 13.8843 4.37438 13.9875C2.99923 14.1765 1.82347 13.0008 2.01254 11.6256C2.11571 10.8753 1.80604 10.1277 1.20253 9.67007C0.0964584 8.83139 0.0964583 7.16861 1.20253 6.32993C1.80604 5.87232 2.11571 5.1247 2.01254 4.37438C1.82347 2.99923 2.99923 1.82347 4.37438 2.01254C5.1247 2.11571 5.87232 1.80604 6.32993 1.20253Z"
          fill="#AAABAB"
        />
        <path
          d="M5 8L7 10L11 6"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
