import { propertiesTabsDescriptions, propertiesTabsIds } from 'settings/constants/properties';

import styles from './styles.module.scss';

const NoListings = ({ activeTab }) => {
  const tab =
    typeof activeTab === 'number' && !isNaN(activeTab)
      ? propertiesTabsIds.searchInstance
      : activeTab;

  const title = propertiesTabsDescriptions[tab].title;
  const subtitle = propertiesTabsDescriptions[tab].subtitle;

  return (
    <div className={styles.noListingsBlock}>
      <div>
        <h2 className={styles.noListingHeading}>{title}</h2>
        <span className={styles.noListingText}>{subtitle}</span>
      </div>
    </div>
  );
};

export default NoListings;
