import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { AddPhoto, FormGroup } from 'components';
import { getUserDataSelector, getUserRoleSelector, isPartnerSelector } from 'store/selectors/user';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { onBoardingSignUpEffect } from 'store/effects';
import { PENDING } from 'settings/constants/apiState';
import { OnboardingSkipButton } from '../agent/components/OnboardingSkipButton';
import { OnboardingContinueButton } from '../agent/components/OnboardingContinueButton';

import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { OnBoardingWrapper } from '../../OnBoardingWrapper';
import { OnBoardingContainer } from '../../OnBoardingContainer';

import styles from './styles.module.scss';
import { useExitOnboarding } from 'pages/OnBoarding/hooks/useExitOnboarding';

const ProfilePhotoForm = (props) => {
  const { className, onNext, stageIndex, Controls } = props;
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const userRole = useSelector(getUserRoleSelector);
  const { onBoarding } = useSelector(getOnBoardingData);
  const isPartner = useSelector(isPartnerSelector);
  const { isPending: isExitOnboardingPending, handleExit: exitOnboarding } = useExitOnboarding();

  const isPending = onBoarding.state === PENDING;

  const skipVendorPreference = useMemo(
    () => (user?.ThirdParty && !user?.ThirdParty?.Partner ? true : false),
    [user?.ThirdParty?.Partner],
  );

  const onSkip = () => (skipVendorPreference ? exitOnboarding() : onNext());

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        onBoardingSignUpEffect({ ...values, stageIndex }, { isCommonInfo: true }, (err) => {
          if (!err) onSkip();
        }),
      );
    },
    [(onSkip, stageIndex, dispatch)],
  );

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    onSubmit,
  });

  const getLogoValue = () => {
    if (isPartner) {
      return user?.[userRole]?.Partner?.LogoUrl;
    }
    return null;
  };

  return (
    <OnBoardingWrapper isPending={isPending}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />

      <OnBoardingContainer>
        <Question className={styles.title}>Do you want to add a profile photo?</Question>
        <AnswersContainer className={styles.profilePhotoWrapper}>
          <FormGroup className={styles.formGroup}>
            <form onSubmit={formik.handleSubmit}>
              <AddPhoto
                variant={AddPhoto.LIGHT}
                value={getLogoValue()}
                onChange={(image, files) => formik.setFieldValue('file', files[0])}
              />
            </form>
          </FormGroup>
        </AnswersContainer>

        <ButtonsContainer>
          <OnboardingContinueButton
            isPending={isPending}
            disabled={!formik.values?.file}
            testid="next"
            htmlType="submit"
            className={classNames(styles.submitBtn, {
              [styles.active]: formik.values?.file,
            })}
            onClick={() => formik.submitForm()}
          />
          <OnboardingSkipButton
            isPending={isExitOnboardingPending}
            testid="skip"
            onClick={onSkip}
            className={styles.skip}
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

ProfilePhotoForm.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

ProfilePhotoForm.defaultProps = {
  className: '',
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default ProfilePhotoForm;
