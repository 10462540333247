import React, { useCallback } from 'react';
import * as Yup from 'yup';

import { Locations } from 'components';
import { useFormik } from 'formik';
import { sortLocations } from 'helpers';
import { uniqBy } from 'lodash-es';
import { FormContainer, FormFooter, FormHeader } from 'pages/OnboardingV2Agent/components';
import { Values } from 'pages/SignupV2/SignupV2';
import styles from './styles.module.scss';

enum FormFields {
  AreasOfOperation = 'areasOfOperation',
}

type FormValues = {
  [key in FormFields]: any[];
};

const schema = Yup.object().shape({
  [FormFields.AreasOfOperation]: Yup.array()
    .required('Required')
    .nullable(true)
    .test('hasState', 'Select a state', (locations) =>
      (locations || []).some(({ Type }) => Type === 'State'),
    ),
});

type Props = {
  onNext: (values: Partial<Values>) => void;
  values: Values;
};

export const SearchStates: React.FC<Props> = ({ onNext, values }) => {
  const onSubmit = (values: FormValues) => {
    onNext(values);
  };

  const formik = useFormik({
    initialValues: {
      [FormFields.AreasOfOperation]: values[FormFields.AreasOfOperation] || [],
    } as FormValues,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit,
  });

  const onLocationsChange = useCallback(
    (result, prepareData) => {
      let value = prepareData?.length
        ? [...prepareData, ...(formik.values?.[FormFields.AreasOfOperation] || [])]
        : formik.values?.[FormFields.AreasOfOperation] || [];

      if (result?.deleteItemId) {
        value = value.filter(({ ProviderPlaceId: itemId }) => itemId !== result.deleteItemId);
      }

      value = uniqBy(value, 'ProviderPlaceId');
      value.sort(sortLocations);
      formik.setFieldValue(FormFields.AreasOfOperation, value);
    },
    [formik],
  );
  return (
    <FormContainer>
      <div className={styles.wrapper}>
        <FormHeader title="Which state(s) do you do business in?" />
        <div className={styles.info}>
          This will help us confirm if subscriptions are still available in your market.
          <br />
          <br />
          Mosaik offers a limited number of subscriptions per market to preserve your competitive
          advantage and ensure the platform is a powerful differentiator for your business.
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <Locations
              multiple
              allowStates={true}
              getStates={true}
              variant="light"
              onResult={onLocationsChange}
              value={
                formik.values?.[FormFields.AreasOfOperation]?.filter(
                  ({ Type: type }) => type === 'State',
                ) || []
              }
              types={['administrative_area_level_1']}
              error={(formik.errors?.[FormFields.AreasOfOperation] as string) || ''}
              placeholder={'Select State(s)'}
              activeInputClassName={styles.activeInputClassName}
              valuesWrapperClassName={styles.valuesWrapperClassName}
              searchIcon={false}
              valueClassName={styles.valueServiceLocation}
              placeholderClassName={styles.placeholderClassName}
              dropdownClassName={styles.dropdownClassName}
              disabled={false}
              valuesContainerClassName={styles.valuesContainer}
              loaderClassName={styles.loader}
              displayedValuesLimit={4}
            />
          </div>
          <div className={styles.footerWrapper}>
            <FormFooter isDisabled={formik.values?.[FormFields.AreasOfOperation]?.length === 0} />
          </div>
        </form>
      </div>
    </FormContainer>
  );
};
