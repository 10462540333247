import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from 'components';

import styles from './styles.module.scss';

const Hint = ({ className, name, avatar, placeholder }) => (
  <div className={classNames(styles.wrapper, className)}>
    <Avatar avatarClassName={styles.avatar} src={avatar} placeholder={placeholder} />
    <div>{`Recommended by ${name || 'No name'}`}</div>
  </div>
);

Hint.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  avatar: PropTypes.string,
  placeholder: PropTypes.string,
};

Hint.defaultProps = {
  className: '',
  avatar: '',
  placeholder: '',
  name: '',
};

export default Hint;
