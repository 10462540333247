import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Wrapper } from 'components';
import { TemplateTabs } from 'pages/Templates/Transactions/components/TemplateTabs';
import { editTransactionTemplate } from 'api/templates';
import { link } from 'settings/navigation/link';
import { requestGetProjectCategories } from 'store/effects/projects';
import { getTransactionTemplateEffect } from 'store/effects/template';
import { getTransactionTemplateSelector } from 'store/selectors/template';

export const TeamTransactionTemplateEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const teamId = +params.teamId;

  const { id: templateId } = useParams<{ id: string }>();

  const template = useSelector(getTransactionTemplateSelector);

  useEffect(() => {
    dispatch(requestGetProjectCategories({}, { silent: true }));
    dispatch(getTransactionTemplateEffect({ id: templateId }));
  }, [templateId, dispatch]);

  useEffect(() => {
    editTransactionTemplate(+templateId, { IsEditing: true });
    return () => {
      editTransactionTemplate(+templateId, { IsEditing: false });
    };
  }, []);

  const handleGoBack = (transactionId?: number) =>
    history.push(link.toDashboardTeamTransactionTemplateView(teamId, transactionId));

  const handleCancel = () => history.push(link.toDashboardTeamTransactionTemplate(teamId));

  return (
    <Wrapper isPending={template.isPending}>
      <TemplateTabs
        isViewMode={false}
        handleGoBack={handleGoBack}
        handleCancel={handleCancel}
        transactionTemplate={template.data}
      />
    </Wrapper>
  );
};
