import { handleActions } from 'redux-actions';

import { setCurrentContextAction, setSelectedContextIdAction } from 'store/actions/context';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';

const initialData = {
  contextId: null,
  context: null,
};

export default handleActions(
  {
    [setSelectedContextIdAction]: (state, { payload }) => ({
      ...state,
      contextId: payload,
    }),
    [setCurrentContextAction]: (state, { payload }) => ({
      ...state,
      context: payload,
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
