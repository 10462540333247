import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Icons = (props) => {
  const { variant, className } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.ARROW_LEFT: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.694 18.6943C16.102 18.2867 16.102 17.6259 15.694 17.2184L10.4699 12L15.694 6.78165C16.102 6.37408 16.102 5.71326 15.694 5.30568C15.2859 4.89811 14.6244 4.8981 14.2164 5.30568L8.30602 11.2096C8.08861 11.4267 7.98704 11.7158 8.00132 12.0002C7.98714 12.2844 8.08871 12.5733 8.30604 12.7904L14.2164 18.6943C14.6244 19.1019 15.286 19.1019 15.694 18.6943Z"
              fill="white"
            />
          </svg>
        );
      }
      case Icons.OVERVIEW: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.8125 3.75C4.67341 3.75 3.75 4.67341 3.75 5.8125V8.90625C3.75 10.0454 4.67341 10.9688 5.8125 10.9688H8.90625C10.0454 10.9688 10.9688 10.0454 10.9688 8.90625V5.8125C10.9688 4.67341 10.0454 3.75 8.90625 3.75H5.8125Z"
              fill="#99A3AA"
            />
            <path
              d="M15.2812 3.75C14.1421 3.75 13.2188 4.67341 13.2188 5.8125V8.90625C13.2188 10.0454 14.1421 10.9688 15.2812 10.9688H18.375C19.5141 10.9688 20.4375 10.0454 20.4375 8.90625V5.8125C20.4375 4.67341 19.5141 3.75 18.375 3.75H15.2812Z"
              fill="#99A3AA"
            />
            <path
              d="M3.75 15.2812C3.75 14.1421 4.67341 13.2188 5.8125 13.2188H8.90625C10.0454 13.2188 10.9688 14.1421 10.9688 15.2812V18.375C10.9688 19.5141 10.0454 20.4375 8.90625 20.4375H5.8125C4.67341 20.4375 3.75 19.5141 3.75 18.375V15.2812Z"
              fill="#99A3AA"
            />
            <path
              d="M15.2812 13.2188C14.1421 13.2188 13.2188 14.1421 13.2188 15.2812V18.375C13.2188 19.5141 14.1421 20.4375 15.2812 20.4375H18.375C19.5141 20.4375 20.4375 19.5141 20.4375 18.375V15.2812C20.4375 14.1421 19.5141 13.2188 18.375 13.2188H15.2812Z"
              fill="#99A3AA"
            />
          </svg>
        );
      }
      case Icons.CALENDAR: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.25 6H6.75C5.7835 6 5 6.7835 5 7.75V18.25C5 19.2165 5.7835 20 6.75 20H17.25C18.2165 20 19 19.2165 19 18.25V7.75C19 6.7835 18.2165 6 17.25 6Z"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.5 4.5V7.5"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.5 4.5V7.5"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 11H19"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.TASKS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.5 5.5L5 7L7.5 4.5"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.5 11.5L5 13L7.5 10.5"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.5 17.5L5 19L7.5 16.5"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 6H20"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 12H20"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 18H20"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.TASKS_BG: {
        return (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="28" cy="28" r="28" fill="#F4F6FB" />
            <path
              d="M16.6667 19.3333L18.6667 21.3333L22.0001 18"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.6667 27.3333L18.6667 29.3333L22.0001 26"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.6667 35.3333L18.6667 37.3333L22.0001 34"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.6667 20H38.6667"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.6667 28H38.6667"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.6667 36H38.6667"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.MESSAGES: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 19.9996L4.3 16.0996C3.17644 14.4379 2.76999 12.47 3.15622 10.5619C3.54244 8.65378 4.69506 6.93526 6.39977 5.72586C8.10447 4.51647 10.2453 3.89849 12.4241 3.98683C14.6029 4.07517 16.6715 4.86383 18.2453 6.20615C19.819 7.54848 20.7909 9.35313 20.9801 11.2846C21.1693 13.216 20.563 15.1428 19.2739 16.7067C17.9848 18.2706 16.1007 19.3652 13.9718 19.7869C11.8429 20.2087 9.6142 19.9289 7.7 18.9996L3 19.9996"
              stroke="#99A3AA"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 12V12.01"
              stroke="#99A3AA"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 12V12.01"
              stroke="#99A3AA"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 12V12.01"
              stroke="#99A3AA"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DOCUMENTS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
              stroke="#99A3AA"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
              stroke="#99A3AA"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 9H10"
              stroke="#99A3AA"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 13H15"
              stroke="#99A3AA"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 17H15"
              stroke="#99A3AA"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DOCUMENT: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <defs>
              <clipPath id="clip0_12664_586">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.ACTIVITY: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 8V12L14 14"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.05078 11.0001C3.27487 8.80013 4.30104 6.75968 5.93351 5.26803C7.56598 3.77639 9.69048 2.93795 11.9017 2.9127C14.1128 2.88744 16.2559 3.67713 17.922 5.1311C19.5882 6.58507 20.6607 8.60155 20.935 10.7958C21.2092 12.99 20.6661 15.2085 19.4091 17.0278C18.1522 18.8472 16.2694 20.1401 14.1201 20.6599C11.9707 21.1797 9.70519 20.8901 7.75578 19.8461C5.80636 18.8022 4.3095 17.0772 3.55078 15.0001M3.05078 20.0001V15.0001H8.05078"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.NOTES: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.6">
              <path
                d="M18 11V17.8333C18 18.2754 17.8244 18.6993 17.5118 19.0118C17.1993 19.3244 16.7754 19.5 16.3333 19.5H7.16667C6.72464 19.5 6.30072 19.3244 5.98816 19.0118C5.67559 18.6993 5.5 18.2754 5.5 17.8333V6.16667C5.5 5.72464 5.67559 5.30072 5.98816 4.98816C6.30072 4.67559 6.72464 4.5 7.16667 4.5H14L15.6667 6.16667"
                stroke="#F4F5F6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 15H13.1314L21.3515 6.77998C21.7667 6.36472 22 5.80152 22 5.21426C22 4.627 21.7667 4.0638 21.3515 3.64854C20.9362 3.23329 20.373 3 19.7857 3C19.1985 3 18.6353 3.23329 18.22 3.64854L10 11.8686V15Z"
                stroke="#F4F5F6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.4375 4.43164L20.5689 7.56308"
                stroke="#F4F5F6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        );
      }
      case Icons.EXCLAMATION: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12Z"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 12C12 13.5621 12 14.1879 12 15.75"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="12" cy="8.25" r="1.25" fill="#99A3AA" />
            <defs>
              <clipPath id="clip0_6486_62805">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.CONTACTS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.6">
              <path
                d="M12.5 8C12.5 10.2091 10.7091 12 8.5 12C6.29086 12 4.5 10.2091 4.5 8C4.5 5.79086 6.29086 4 8.5 4C10.7091 4 12.5 5.79086 12.5 8Z"
                stroke="#F4F5F6"
                strokeWidth="2"
              />
              <path
                d="M15.5 12C17.7091 12 19.5 10.2091 19.5 8C19.5 5.79086 17.7091 4 15.5 4"
                stroke="#F4F5F6"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M2.5 19.5C2.87394 17.6303 4.59315 16 6.49988 16H10.5C12.4067 16 14.1261 17.6303 14.5 19.5"
                stroke="#F4F5F6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.5 16C19.4067 16 21.1261 17.6303 21.5 19.5"
                stroke="#F4F5F6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        );
      }
      case Icons.ME: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clipPath="url(#clip0_34548_3444)">
              <path
                d="M11.332 7H4.66536C3.92898 7 3.33203 7.59695 3.33203 8.33333V12.6666C3.33203 13.403 3.92898 14 4.66536 14H11.332C12.0684 14 12.6654 13.403 12.6654 12.6666V8.33333C12.6654 7.59695 12.0684 7 11.332 7Z"
                stroke="#FFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.9987 11.3333C8.36689 11.3333 8.66536 11.0349 8.66536 10.6667C8.66536 10.2985 8.36689 10 7.9987 10C7.63051 10 7.33203 10.2985 7.33203 10.6667C7.33203 11.0349 7.63051 11.3333 7.9987 11.3333Z"
                stroke="#FFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.33203 7V4.66667C5.33203 3.95942 5.61298 3.28115 6.11308 2.78105C6.61318 2.28095 7.29145 2 7.9987 2C8.70594 2 9.38422 2.28095 9.88432 2.78105C10.3844 3.28115 10.6654 3.95942 10.6654 4.66667V7"
                stroke="#FFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_34548_3444">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }

      case Icons.FINANCIALS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="9" stroke="#99A3AA" strokeWidth="2" />
            <path
              d="M14.7 8.625C14.7 8.625 13.8 7.5 12 7.5C10.2 7.5 9.3 8.625 9.3 9.75C9.3 12.5625 15 11.1 15 14.25C15 15.6 14 16.5 12 16.5C10 16.5 9 15.15 9 15.15"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 6V18"
              stroke="#99A3AA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      default:
        return null;
    }
  };

  return <div className={classNames(styles.icons, className)}>{getIcons()}</div>;
};

Icons.ARROW_LEFT = 'arrowLeft';
Icons.OVERVIEW = 'overview';
Icons.CALENDAR = 'calendar';
Icons.TASKS = 'tasks';
Icons.MESSAGES = 'messages';
Icons.DOCUMENTS = 'documents';
Icons.DOCUMENT = 'document';
Icons.ACTIVITY = 'activity';
Icons.NOTES = 'notes';
Icons.EXCLAMATION = 'exclamation';
Icons.CONTACTS = 'contacts';
Icons.FINANCIALS = 'financials';
Icons.TASKS_BG = 'tasksBg';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string.isRequired,
};

Icons.defaultProps = {
  className: '',
};

export default Icons;
