import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import Icon from 'components/CommuteForm/Icons';
import Actions from './Actions';

import { setSearchDrawerCommuteIdEffect } from 'store/effects';
import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';

import styles from './styles.module.scss';

const Commute = (props) => {
  const { className, index, name, type, prefType, onDelete, onChange, isCommute } = props;

  const dispatch = useDispatch();

  const onEditHandler = () => {
    dispatch(setSearchDrawerCommuteIdEffect(index));
  };

  const onDeleteHandler = () => {
    onDelete(index);
  };

  const getPrefType = () => {
    switch (prefType) {
      case SEARCH_CRITERIA_IMPORTANCE.MUST:
        return 'Need';
      case SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT:
        return 'Want';
      case SEARCH_CRITERIA_IMPORTANCE.UNDEFINED:
        return 'N/A';
      default:
        return 'N/A';
    }
  };

  return (
    <div testid="commute_item" className={classNames(styles.commute, className)}>
      <Icon className={styles.icon} variant={type} />
      <div className={styles.info}>
        <p testid="name" className={styles.name}>
          {name} {isCommute ? 'Commute' : 'Proximity'}
        </p>
        <p className={styles.prefType}>{getPrefType()}</p>
      </div>
      <Actions
        className={styles.action}
        onEdit={onEditHandler}
        onDelete={onDeleteHandler}
        onChangePref={onChange}
        index={index}
        prefType={prefType}
      />
    </div>
  );
};

Commute.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  commuteTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  address: PropTypes.string.isRequired,
  className: PropTypes.string,
  onDelete: PropTypes.func,
};

Commute.defaultProps = {
  className: '',
  onDelete: () => {},
};

export default Commute;
