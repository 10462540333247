import { Icons } from '../../../Icons';
import styles from '../styles.module.scss';

export const EditStageInfo = ({ icon, info, onClick, title }) => {
  return (
    <>
      <div className={styles.infoWrap}>
        <Icons className={styles.calendarIcon} variant={icon} color="#515151" />
        <div>
          <h4 className={styles.title}>{title}</h4>
          <h4 className={styles.info}>{info}</h4>
        </div>
      </div>
      <div className={styles.editIcon} onClick={onClick}>
        <Icons variant={'edit'} color="#515151" />
      </div>
    </>
  );
};
