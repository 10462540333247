import * as Yup from 'yup';
import 'yup-phone';

export const ValidationSchema = Yup.object().shape({
  areasOfOperation: Yup.array()
    .required('Required')
    .nullable(true)
    .test('hasCity', 'Required', (locations) =>
      (locations || []).some(({ Type }) => Type === 'City' || Type === 'Zipcode'),
    ),
});
