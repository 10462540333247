import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button } from 'components-antd';
import { ArrowLeftFull } from 'components/Icons';

import styles from './styles.module.scss';

const Navigation = ({
  data,
  onPrev,
  onNext,
  onClose,
  onFinish,
  stageIndex,
  disabled,
  className,
  showBackButton,
}) => {
  return (
    <div className={classnames(styles.footer, className)}>
      {stageIndex > 0 && !data?.Id && showBackButton && (
        <ArrowLeftFull className={styles.arrowIcon} onClick={onPrev} />
      )}
      {stageIndex == 6 ? (
        <Button
          className={classnames(styles.nextBtn, styles.submitBtn)}
          onClick={() => {
            onFinish();
            onClose();
          }}
          disabled={disabled}
        >
          Return to Transaction
        </Button>
      ) : (
        <Button className={styles.nextBtn} onClick={onNext} disabled={disabled}>
          {data?.Id ? 'Save' : 'Next'}
        </Button>
      )}
    </div>
  );
};

Navigation.propTypes = {
  data: PropTypes.object,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
  stageIndex: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  showBackButton: PropTypes.bool,
};

Navigation.defaultProps = {
  data: {},
  onPrev: () => {},
  onNext: () => {},
  onClose: () => {},
  onFinish: () => {},
  stageIndex: 0,
  disabled: false,
  className: '',
  showBackButton: true,
};

export default Navigation;
