import React, { forwardRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Wrapper as PendingWrapper } from 'components';
import useWrapperSize from './useWrapperSize';

import styles from './styles.module.scss';

const PropertiesWrapper = forwardRef(({ id, children, count, countType }, ref) => {
  const wrapperRef = useRef();
  const { wrapperSize } = useWrapperSize({ wrapperRef });
  const [nextPageIsPending, setNexPageIsPending] = useState(false);

  const getNextPageIsPending = (isPending) => {
    setNexPageIsPending(isPending);
  };

  const countText = countType === 'Exact' ? count : `${count}+`;

  return (
    <div ref={ref || wrapperRef} id={id} className={classNames(styles.propertiesWrapper)}>
      <p className={styles.count}>
        {count > 1000 ? '1000+' : countText} {'Propert' + (count > 1 ? 'ies' : 'y')}
      </p>
      {typeof children === 'function'
        ? children({ ...wrapperSize, height: wrapperSize.height - 54 }, getNextPageIsPending)
        : children}
      {nextPageIsPending && (
        <PendingWrapper
          className={classNames(styles.loadingPage)}
          loaderClassName={styles.loader}
          isPending
        />
      )}
    </div>
  );
});

PropertiesWrapper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  count: PropTypes.number,
  countType: PropTypes.string,
};

PropertiesWrapper.defaultProps = {
  id: '',
  count: 0,
};

export default PropertiesWrapper;
