import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SendingTime from './SendingTime';
import Matches from './Matches';
import OnlyMyTasks from './OnlyMyTasks';

import styles from './styles.module.scss';

const FeatureItem = (props) => {
  const { className, groupType, type, variant, value, testid } = props;

  const getItem = () => {
    const commonProps = { labelClassName: styles.label, groupType, type, variant, value };

    if (variant === 'SendingTime') {
      return <SendingTime {...commonProps} />;
    }

    if (variant === 'Matches') {
      return <Matches {...commonProps} />;
    }

    if (variant === 'OnlyMyTasks') {
      return <OnlyMyTasks {...commonProps} />;
    }

    return null;
  };

  return (
    <div testid={testid} className={classNames(styles.featureItem, className)}>
      {getItem()}
    </div>
  );
};

FeatureItem.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  testid: PropTypes.string,
  groupType: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.number]).isRequired,
  variant: PropTypes.string.isRequired,
};

FeatureItem.defaultProps = {
  className: '',
  type: '',
  testid: undefined,
};

export default FeatureItem;
