import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List } from 'react-virtualized';

import Property from 'pages/Properties/Feed/Properties/Property';
// import { useNextPage } from 'hooks';

import { NoResults } from 'components';

import styles from './styles.module.scss';

const PropertiesList = (props) => {
  const { list, wrapperSize, className, rowHeight /* , getNextPageIsPending , effect */ } = props;
  const { isClient: isPropsClient } = props;
  // const [scrollEvent, setScrollEvent] = useState({});
  // const { nextPageIsPending } = useNextPage({ scrollEvent, effect });

  // useEffect(() => getNextPageIsPending(nextPageIsPending), [nextPageIsPending]); // eslint-disable-line

  const rowRenderer = ({ key, index, style }) => (
    <Property key={key} {...list[index]} style={style} isClient={isPropsClient} />
  );

  if (!list || !wrapperSize.width || !wrapperSize.height) return null;
  if (!list.length) return <NoResults />;

  return (
    <List
      // onScroll={setScrollEvent}
      className={classNames(styles.list, className)}
      width={wrapperSize.width}
      height={wrapperSize.height}
      rowCount={list.length}
      rowHeight={rowHeight}
      rowRenderer={rowRenderer}
    />
  );
};

PropertiesList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  wrapperSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  getNextPageIsPending: PropTypes.func,
  rowHeight: PropTypes.number,
  effect: PropTypes.func.isRequired,
  isClient: PropTypes.bool,
};

PropertiesList.defaultProps = {
  className: '',
  getNextPageIsPending: () => {},
  rowHeight: 174,
  list: null,
  isClient: undefined,
};

export default PropertiesList;
