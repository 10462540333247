export function prepareData(values, stageIndex, defaultClientSearch = {}) {
  return {
    stageIndex,
    DefaultPropertySearchPreferences: {
      ...defaultClientSearch,
      HOAType: values?.hoaType,
      ...(values.hoaMin || values.hoaMax
        ? {
            HOARange: {
              Min: values.hoaMin ? +values.hoaMin : undefined,
              Max: values.hoaMax ? +values.hoaMax : undefined,
            },
          }
        : { HOARange: undefined }),
    },
  };
}
