import { apiServer } from 'settings/web-services/api';

export function getAllTaskTemplates(cfg) {
  return apiServer.get('/transaction/templates/task-templates', { params: cfg });
}

export function getTaskTemplateById({ id }) {
  return apiServer.get(`/transaction/templates/task-templates/${id}`);
}

export function deleteTaskTemplate({ id }) {
  return apiServer.delete(`/transaction/templates/task-templates/${id}`);
}

export function postTaskTemplate(cfg) {
  return apiServer.post('/transaction/templates/task-templates', cfg);
}

export function putTaskTemplate(cfg, { id }) {
  return apiServer.put(`/transaction/templates/task-templates/${id}`, cfg);
}
