import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import { LocationService } from 'services';
import BaseNotification from '../../../BaseNotification';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { link } from 'settings/navigation/link';
import styles from './styles.module.scss';

const locationSrv = new LocationService();
import { useHistory } from 'react-router-dom';
import { notificationTypes } from 'settings/constants/notifications';

const ShowingAppointmentApprovalSelf = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const location = useLocation();

  locationSrv.setLocation(location);
  const {
    RequesterFirstName,
    RequesterSecondName,
    BuyingAgentName,
    BuyerAgentName,
    RequesterName,
    ListingAgentName,
    ClientAvatarUrl,
    TransactionId,
    ShowingUUID,
    Link,
  } = Payload || {};

  const Name =
    BuyingAgentName ||
    RequesterName ||
    BuyerAgentName ||
    ListingAgentName ||
    `${RequesterFirstName} ${RequesterSecondName}`;

  const onClickHandler = () => {
    if (
      (Payload.NotificationType === 'ShowingAppointmentApprovalReminderOther' ||
        Payload.NotificationType === 'UpcomingShowingAppointmentReminderNotification' ||
        Payload.NotificationType === notificationTypes.ShowingAppointmentApprovalSelf ||
        Payload.NotificationType === notificationTypes.ShowingAppointmentApprovalReminderSelf) &&
      TransactionId &&
      ShowingUUID
    ) {
      const path = link.toTransactionShowings(TransactionId, ShowingUUID);
      history.push(path);
    } else {
      const parsedUrl = new URL(Link);
      const path = parsedUrl.pathname;
      history.push(path);
    }

    dispatch(openNotificationsDrawerEffect({ open: false }));
  };

  return (
    <div className={classNames(styles.notificationClientPrefsUpdated, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        groupType={groupType}
        avatarUrl={ClientAvatarUrl}
        avatarPlaceholder={convertNameToAvatarPlaceholder(Name)}
        severityLevel={SeverityLevel}
        onClick={onClickHandler}
      />
    </div>
  );
};

ShowingAppointmentApprovalSelf.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    RequesterFirstName: PropTypes.string,
    RequesterSecondName: PropTypes.string,
    ClientAvatarUrl: PropTypes.string,
    Link: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

ShowingAppointmentApprovalSelf.defaultProps = {
  className: '',
};

export default ShowingAppointmentApprovalSelf;
