import { Icons } from '../../../Icons';
import { IconText } from '../IconText';

import styles from '../styles.module.scss';

export const EditNotify = ({ data, onEdit }) => {
  const getInfo = () => {
    const { AutomaticallySendOffersToSeller } = data;
    let text = '';
    if (AutomaticallySendOffersToSeller) {
      text += 'Yes';
    } else {
      text += 'No';
    }
    return text;
  };

  return (
    <div className={styles.editForm}>
      <IconText
        text={'Automatically send offers to seller?'}
        infoText={getInfo()}
        variant={'notify-bg'}
        className={styles.editFormInfo}
      />
      <div className={styles.editIcon} onClick={onEdit}>
        <Icons variant={'edit'} />
      </div>
    </div>
  );
};
