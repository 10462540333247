import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const ArchivedLabel = (props) => {
  const { className, currentThread } = props;

  if (!currentThread?.IsArchived) return null;

  return (
    <div testid="archived_label" className={classNames(styles.archived, className)}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.54415 4.69957L2.55132 10.6781C2.51733 10.78 2.5 10.8868 2.5 10.9943V14.6654C2.5 15.7699 3.39543 16.6654 4.5 16.6654H15.5C16.6046 16.6654 17.5 15.7699 17.5 14.6654V10.9943C17.5 10.8868 17.4827 10.78 17.4487 10.6781L15.4558 4.69958C15.1836 3.88289 14.4193 3.33203 13.5585 3.33203H6.44152C5.58066 3.33203 4.81638 3.88289 4.54415 4.69957Z"
          stroke="#163C5A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16797 8.33203H7.08464V8.7487C7.08464 10.3595 8.39047 11.6654 10.0013 11.6654V11.6654C11.6121 11.6654 12.918 10.3595 12.918 8.7487V8.33203H15.8346"
          stroke="#163C5A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className={styles.text}>Archived Message</span>
    </div>
  );
};

ArchivedLabel.propTypes = {
  className: PropTypes.string,
  currentThread: PropTypes.shape({
    IsArchived: PropTypes.bool,
  }),
};

ArchivedLabel.defaultProps = {
  className: '',
  currentThread: undefined,
};

export default ArchivedLabel;
