/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import { Match as CommonMatch } from 'components';

const Match = ({ propertyInfo, className, isPriceReduction }) => (
  <CommonMatch
    className={className}
    propertyInfo={propertyInfo}
    isPriceReduction={isPriceReduction}
  />
);

Match.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    MatchScore: PropTypes.number,
    MatchedCriterias: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  }).isRequired,
};

Match.defaultProps = {
  className: '',
};

export default Match;
