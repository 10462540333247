import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components';
import { DropzoneUpload } from 'components/Icons';

import styles from './styles.module.scss';

const Inner = (props) => {
  const { className, onChooseFile } = props;

  return (
    <div className={classNames(styles.inner, className)}>
      <DropzoneUpload className={styles.icon} />
      <div className={classNames(styles.text, styles.main)}>Drag and drop files here</div>
      <div className={classNames(styles.text)}>or</div>
      <Button onClick={onChooseFile} className={styles.chooseFileBtn} title="Choose File" />
    </div>
  );
};

Inner.propTypes = {
  className: PropTypes.string,
  onChooseFile: PropTypes.func,
};

Inner.defaultProps = {
  className: '',
  onChooseFile: () => {},
};

export default Inner;
