const Expand = ({ className, onClick }) => (
  <div onClick={onClick} className={className}>
    <svg width="24" height="24" viewBox="16 8 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="16.25"
        y="8.25"
        width="31.5"
        height="31.5"
        rx="5.75"
        stroke="#EEF0EF"
        strokeidth="0.5"
      />
      <line
        x1="34"
        y1="21.5858"
        x2="40.5858"
        y2="15"
        stroke="#303030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34 15H41V21.6667"
        stroke="#303030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="30"
        y1="26.4142"
        x2="23.4142"
        y2="33"
        stroke="#303030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 33L23 33L23 26.3333"
        stroke="#303030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default Expand;
