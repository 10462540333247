import { FC } from 'react';
import classNames from 'classnames';

import { ALL_PREFERENCES, prefsIds, PREF_TYPE } from 'settings/constants/preferences';
import { TextIconButton } from 'components';
import Item from './Item';

import styles from './styles.module.scss';

interface Props {
  showAddMoreOption?: boolean;
  className?: string;
  rawSearchPrefs?: { [key: string]: any };
  onClickAddItem?: () => void;
  list: any[];
  title?: string;
  onChangePref: (...props) => any;
  onUpdatePrefs?: (...props) => any;
  currentImportance: string;
}
const Items: FC<Props> = (props) => {
  const {
    list,
    title,
    onChangePref,
    onUpdatePrefs,
    currentImportance,
    className = '',
    rawSearchPrefs,
    showAddMoreOption = false,
    onClickAddItem,
  } = props;
  const schoolDataFactory = (data) => ({
    id: data.Id,
    iconType: 'school',
    label: data.displayName,
    type: data.type,
    data,
  });
  const getSchoolsData = () => {
    return list.map((data) => schoolDataFactory(data));
  };

  const getSchoolItem = (data) => (
    <Item
      isSchool={typeof data === 'object'}
      key={data.id}
      onChange={(newImportance, updatedSearchPrefDiff) => {
        onChangePref(data.data, currentImportance, newImportance, updatedSearchPrefDiff);
      }}
      currentImportance={currentImportance}
      rawSearchPrefs={rawSearchPrefs}
      {...data}
    />
  );
  const getOtherItems = (itemId, prefOption) => {
    /* TODO: Remove this check once schools is implemented in client invitation and onboarding */
    if (!Object.keys(prefOption || {})?.length) {
      return null;
    }
    return (
      <Item
        key={itemId}
        onChange={(newImportance, updatedSearchPrefDiff) => {
          onChangePref(itemId, currentImportance, newImportance, updatedSearchPrefDiff);
        }}
        currentImportance={currentImportance}
        rawSearchPrefs={rawSearchPrefs}
        {...prefOption}
        onUpdatePrefs={(newPrefs) => onUpdatePrefs?.(newPrefs)}
      />
    );
  };
  const getItems = () => {
    if (title?.toLowerCase() === 'schools') {
      return getSchoolsData()?.map((data) => getSchoolItem(data));
    } else {
      return list?.map((itemId) => {
        if (typeof itemId !== 'string') {
          return getSchoolItem(schoolDataFactory(itemId));
        }
        if (itemId !== prefsIds.kitchenFeatures && itemId !== prefsIds.flooring) {
          const prefOption = ALL_PREFERENCES.find((option) => option.id === itemId) || {};
          return getOtherItems(itemId, prefOption);
        }
      });
    }
  };
  return (
    <div className={className}>
      {getItems()}
      {showAddMoreOption && (
        <div testid="feature_item" className={classNames(styles.item)}>
          <TextIconButton
            text={` ${list?.length ? 'Add Another' : 'Add'}`}
            onClick={onClickAddItem}
          />
        </div>
      )}
    </div>
  );
};

export default Items;
