import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import PartTitle from '../Content/PartTitle';

const BioInfo = (props) => {
  const { className, value } = props;

  if (!value) return null;

  return (
    <div className={classNames(styles.bioInfo, className)}>
      <PartTitle className={styles.infoPart}>Bio</PartTitle>
      <div className={styles.description}>{value}</div>
    </div>
  );
};

BioInfo.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
};

BioInfo.defaultProps = {
  className: '',
  value: '',
};

export default BioInfo;
