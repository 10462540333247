import { apiServer } from 'settings/web-services/api';

export function getAllKits() {
  return apiServer.get('/kits');
}

export function createNewKitByAdmin(cfg) {
  return apiServer.post('/kits', cfg);
}

export function getKitsCategories() {
  return apiServer.get('/kits/categories');
}

export function getKitsFrequencies() {
  return apiServer.get('/kits/frequencies');
}

export function getKitsPropertyTypes() {
  return apiServer.get('/kits/propertyTypes');
}

export function getKitById(id) {
  return apiServer.get(`/kits/${id}`);
}

export function updateKitById(id, cfg) {
  return apiServer.put(`/kits/${id}`, cfg);
}

export function publishKitById(id, cfg) {
  return apiServer.post(`/kits/${id}/publish`, cfg);
}

export function getKitsMasterControl() {
  return apiServer.get(`/kits/masterControl`);
}

export function updateKitsMasterControl(cfg) {
  return apiServer.put(`/kits/masterControl`, cfg);
}

export function getPublishedKits() {
  return apiServer.get('/kits/publishedKits');
}

export function configureKit(id, cfg) {
  return apiServer.put(`/kits/publishedKits/${id}`, cfg);
}

export function blacklistClientByKitId(id, cfg) {
  return apiServer.post(`/kits/${id}/blacklist`, cfg);
}

export function getKitEligibilityStats(id, cfg) {
  return apiServer.get(`/kits/publishedKits/${id}/eligibilityStats`, { params: cfg });
}

export function getEmailTemplateByKit(id) {
  return apiServer.get(`/kits/emailTemplate/${id}`);
}

export function getCtaData(uuid) {
  return apiServer.get(`/kits/cta/${uuid}`);
}

export function getCumulativeStats() {
  return apiServer.get('/kits/cumulativeStats');
}

export function disableKitsByClient(clientId, cfg) {
  return apiServer.post(`/kits/disable/${clientId}`, cfg);
}

export function unsubscribeClient(uuid) {
  return apiServer.put(`/kits/unsubscribe/${uuid}`);
}
