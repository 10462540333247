import { useState } from 'react';
import { Row, Col, Space, RadioChangeEvent } from 'antd';

import {
  EditLinkDuration,
  EditNotes,
  EditDocuments,
  EditListingAgent,
  EditNotifications,
  EditOfferDeadline,
} from '../components';
import { Navigation } from '../../components';
import { Radio } from '../../../../../../../components-antd/Radio';

import styles from './styles.module.scss';
import { IconText } from '../components/IconText';
import { useScrollableRef } from '../../hooks/useScrollableRef';

export const Notify = ({
  onNext,
  stageIndex,
  lastStageIndex,
  onUpdate,
  onFinish,
  setCurrentStageIndex,
  data,
  stagesStep,
}) => {
  const { AutomaticallySendOffersToSeller } = data;

  const [state, setState] = useState(AutomaticallySendOffersToSeller ?? undefined);
  const { scrollableDivRef } = useScrollableRef();

  const onChange = (e: RadioChangeEvent) => {
    setState(e.target.value);
  };

  const onSubmit = () => {
    const body = {
      AutomaticallySendOffersToSeller: state,
    };
    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(lastStageIndex);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  return (
    <div className={styles.parties}>
      <div className={styles.contentWrap}>
        <EditLinkDuration
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.LinkDuration)}
        />

        <EditOfferDeadline
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.OfferDeadline)}
        />

        <EditListingAgent
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.ListingAgent)}
        />

        <EditNotifications
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.Notifications)}
        />

        <EditDocuments data={data} onEdit={() => setCurrentStageIndex(stagesStep.Documents)} />
        <EditNotes data={data} onEdit={() => setCurrentStageIndex(stagesStep.Notes)} />

        <IconText
          variant={'notify-bg'}
          text={'Automatically send offers to seller?'}
          className={styles.iconText}
        />
        <div className={styles.content} ref={scrollableDivRef}>
          <Row className={styles.radioButtons}>
            <Col span={24} className={styles.container}>
              <Radio.Group onChange={onChange} value={state}>
                <Space direction="vertical" size={24}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Space>
              </Radio.Group>
            </Col>
          </Row>
        </div>
      </div>
      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onFinish={onFinish}
        disabled={state === undefined}
        className={styles.footer}
      />
    </div>
  );
};
