import moment from 'moment';
import { useState } from 'react';
import classNames from 'classnames';

import { CommentUserType, FormComment } from 'types';
import { SmartFormIcons, SmartFormIconVariants } from 'components/SmartForm';
import { primaryColor, textGrayColor, whiteColor } from 'app-constants';
import { Avatar, Popover, Space, Spin, UserOutlinedIcon } from 'components-antd';

import styles from './styles.module.scss';
import { getCommentDate } from './helper';

interface FormCommentBoxHeaderProps {
  isHeader?: boolean;
  isResolved?: boolean;
  threadId?: number;
  userInfo: CommentUserType;
  comment: FormComment;
  setEditingMode: (editingMode: boolean) => void;
  handleDeleteFormComment?: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
}

export const FormCommentBoxHeader = ({
  isHeader = false,
  isResolved = false,
  threadId,
  userInfo,
  comment,
  setEditingMode,
  handleDeleteFormComment,
  handleToggleResolveThread,
}: FormCommentBoxHeaderProps) => {
  const [resolving, setResolving] = useState(false);

  return (
    <div className={styles.commentHeader}>
      <div className={styles.commentData}>
        <span>
          <Avatar
            size={32}
            src={userInfo?.avatar}
            icon={<UserOutlinedIcon />}
            className={styles.commentBoxAvatar}
          />
        </span>

        <span className={styles.commentInfo}>
          <span className={styles.commentName}>{userInfo?.name || userInfo?.email}</span>
          <span className={styles.commentDate}>
            <span>{getCommentDate(comment.CreatedDate)}</span>
          </span>
        </span>
      </div>

      <div className={styles.commentAction}>
        <Space>
          {isHeader &&
            threadId &&
            handleToggleResolveThread &&
            (resolving ? (
              <Spin size="small" className={styles.resolvingLoader} />
            ) : (
              <div
                className={classNames(styles.resolveIcon, {
                  [styles.unresolved]: !isResolved,
                  [styles.resolved]: isResolved,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  setResolving(true);
                  handleToggleResolveThread(threadId, setResolving);
                }}
              >
                <SmartFormIcons
                  variant={SmartFormIconVariants.Tick}
                  stroke={isResolved ? whiteColor : textGrayColor}
                />
              </div>
            ))}

          {userInfo?.self && (
            <Popover
              content={
                <span className={styles.actionCollection}>
                  <span
                    className={styles.action}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingMode(true);
                    }}
                  >
                    <SmartFormIcons variant={SmartFormIconVariants.Pencil} stroke={primaryColor} />
                    <span className={styles.actionName}>Edit</span>
                  </span>
                  <span
                    className={styles.action}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteFormComment && handleDeleteFormComment(comment.Id);
                    }}
                  >
                    <SmartFormIcons variant={SmartFormIconVariants.CommentDelete} />
                    <span className={styles.actionName}>Delete</span>
                  </span>
                </span>
              }
              trigger="focus"
              placement="bottomRight"
              overlayClassName={styles.editActions}
            >
              <span
                tabIndex={0}
                className={styles.editActionsBtn}
                onClick={(e) => e.stopPropagation()}
              >
                <SmartFormIcons variant={SmartFormIconVariants.DotMenu} />
              </span>
            </Popover>
          )}
        </Space>
      </div>
    </div>
  );
};
