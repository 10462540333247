import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Properties/Feed/Icons';
import { getPrefLabelOptionById } from 'settings/constants/preferences';

import styles from './styles.module.scss';

const Amenity = (props) => {
  const { className, value } = props;

  return (
    <div className={classNames(styles.amenity, className)}>
      <Icon className={styles.icon} variant={Icon.GREEN_LIGHT_CHECKMARK} />
      <span className={styles.value}>{getPrefLabelOptionById(value)}</span>
    </div>
  );
};

Amenity.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
};

Amenity.defaultProps = {
  className: '',
  value: '',
};

export default Amenity;
