import styles from './styles.module.scss';
import { LeftSideBar } from './Components/LeftSideBar';
import RightSideBar from './Components/RightSideBar';
import { Header } from './Components/Header';
import SubHeader from './Components/SubHeader';
import { WorkShopActivity } from 'pages/Workshop/Activity';
import PageWrapper from './Components/PageWrapper';
import { ViewSelector } from './Components/ViewSelector';
import { useLocation } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { Home } from './Home';
import { MyKits } from './MyKits';
const Clarity = () => {
  const { pathname } = useLocation();
  switch (pathname) {
    case routes.clarity:
      return <Home />;
    case routes.myKits:
      return <MyKits />;
  }
};
export default Clarity;
