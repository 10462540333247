import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationService } from 'services';

import { quoteRequestStatus } from 'settings/constants/quotes';
import { DRAWER_QUOTES_VIEW_TYPES } from 'settings/constants/drawers';

import { getGroupedQuotesSelector } from 'store/selectors/quotes';
import { getUserRoleSelector } from 'store/selectors/user';

import { Row, Col } from 'components-antd';
import { getDetailedQuotesByStatus } from 'pages/Quotes/helpers';
import { Card, ViewButton, CountInfo } from '../../..';
import { ByStatus } from '..';
import { NoQuotes } from 'pages/Quotes/components/NoQuotes';

import styles from './styles.module.scss';
import { tableColumns } from 'pages/Quotes/components/Tables/groupByCommonTableColumns/tableColumns';
import { GroupByTable } from 'pages/Quotes/components/Tables/GroupByTable';
import GroupByDetailTable from 'pages/Quotes/components/Tables/GroupByDetailTable';

const locationSrv = new LocationService();
const ByClient = (props) => {
  const { className, onArchive } = props;
  const history = useHistory();
  const location = useLocation();
  locationSrv.setLocation(location);
  const { byClient, groupId } = useSelector(getGroupedQuotesSelector);
  const userRole = useSelector(getUserRoleSelector);

  const filteredQuotesData = byClient.filter(
    (quote) => quote?.pendingCount || quote?.receivedCount,
  );
  const getDetailTables = () => {
    const groupByStatus = getDetailedQuotesByStatus(byClient, groupId, userRole);
    return (
      <GroupByDetailTable onArchive={onArchive} quotesList={groupByStatus} groupedBy={'ByClient'} />
    );
  };
  const tableProps = {
    columns: tableColumns(userRole, 'Client'),
    onRow: (record) => {
      return {
        onClick: (event) => {
          history.push(locationSrv.setQuery({ groupId: record?.name }));
        },
      };
    },
  };

  return (
    <div testid="by_client" className={classNames(styles.byClient, className)}>
      {byClient.length ? (
        groupId ? (
          getDetailTables()
        ) : (
          <GroupByTable dataSource={byClient} {...tableProps} />
        )
      ) : (
        <NoQuotes />
      )}
    </div>
  );
};

ByClient.propTypes = {
  className: PropTypes.string,
  onArchive: PropTypes.func,
};

ByClient.defaultProps = {
  className: '',
  onArchive: () => {},
};

export default ByClient;
