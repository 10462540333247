import { MosaikTag } from 'app-constants';
import { get } from 'lodash-es';

import {
  requestAddTagAction,
  requestFormMetaAction,
  requestUserFormMetaAction,
} from 'store/actions/formBuilder';
import { requestGetAllTeamsAction } from 'store/actions/teamList';
import { FormBuilderState } from 'types';

export const formBuilderMetaReducer = {
  [requestFormMetaAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      meta: {
        ...state.data.meta,
        state: payload.state,
        tags: get(payload, 'data.value.tags', []),
        categories: get(payload, 'data.value.categories', []),
        smartFormCategories: get(payload, 'data.value.smartFormCategories', []),
      },
    },
  }),
  [requestUserFormMetaAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      meta: {
        ...state.data.meta,
        state: payload.state,
        users: get(payload, 'data.result', []),
      },
    },
  }),
  [requestGetAllTeamsAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      meta: {
        ...state.data.meta,
        state: payload.state,
        teams: get(payload, 'data.result', []),
      },
    },
  }),
  [requestAddTagAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => {
    const tag = get(payload, 'data.value');
    let tags;
    if (tag?.Type === MosaikTag.Form) {
      tags = { tags: [...state.data.meta.tags, tag].filter((el) => el) };
    } else if (tag?.Type === MosaikTag.SmartForm) {
      tags = {
        smartFormCategories: [...state.data.meta.smartFormCategories, tag].filter((el) => el),
      };
    }

    return {
      ...state,
      data: {
        ...state.data,
        meta: {
          ...state.data.meta,
          state: payload.state,
          ...(tag && tags),
        },
      },
    };
  },
};
