import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const ListingHome = memo(({ className }) =>
  useMemo(
    () => (
      <div testid="listing_home" className={classNames(styles.icon, className)}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 27V5H5V27H20ZM20 27H27.9976V12L19.9976 10"
            stroke="#525252"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 27V22H15V27"
            stroke="#525252"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 9.5C9 9.22386 9.22386 9 9.5 9H10.5C10.7761 9 11 9.22386 11 9.5V10.5C11 10.7761 10.7761 11 10.5 11H9.5C9.22386 11 9 10.7761 9 10.5V9.5Z"
            fill="#525252"
          />
          <path
            d="M9 13.5C9 13.2239 9.22386 13 9.5 13H10.5C10.7761 13 11 13.2239 11 13.5V14.5C11 14.7761 10.7761 15 10.5 15H9.5C9.22386 15 9 14.7761 9 14.5V13.5Z"
            fill="#525252"
          />
          <path
            d="M9 17.5C9 17.2239 9.22386 17 9.5 17H10.5C10.7761 17 11 17.2239 11 17.5V18.5C11 18.7761 10.7761 19 10.5 19H9.5C9.22386 19 9 18.7761 9 18.5V17.5Z"
            fill="#525252"
          />
          <path
            d="M14 9.5C14 9.22386 14.2239 9 14.5 9H15.5C15.7761 9 16 9.22386 16 9.5V10.5C16 10.7761 15.7761 11 15.5 11H14.5C14.2239 11 14 10.7761 14 10.5V9.5Z"
            fill="#525252"
          />
          <path
            d="M14 13.5C14 13.2239 14.2239 13 14.5 13H15.5C15.7761 13 16 13.2239 16 13.5V14.5C16 14.7761 15.7761 15 15.5 15H14.5C14.2239 15 14 14.7761 14 14.5V13.5Z"
            fill="#525252"
          />
          <path
            d="M14 17.5C14 17.2239 14.2239 17 14.5 17H15.5C15.7761 17 16 17.2239 16 17.5V18.5C16 18.7761 15.7761 19 15.5 19H14.5C14.2239 19 14 18.7761 14 18.5V17.5Z"
            fill="#525252"
          />
          <path
            d="M23 14.5C23 14.2239 23.2239 14 23.5 14H24.5C24.7761 14 25 14.2239 25 14.5V15.5C25 15.7761 24.7761 16 24.5 16H23.5C23.2239 16 23 15.7761 23 15.5V14.5Z"
            fill="#525252"
          />
          <path
            d="M23 18.5C23 18.2239 23.2239 18 23.5 18H24.5C24.7761 18 25 18.2239 25 18.5V19.5C25 19.7761 24.7761 20 24.5 20H23.5C23.2239 20 23 19.7761 23 19.5V18.5Z"
            fill="#525252"
          />
          <path
            d="M23 22.5C23 22.2239 23.2239 22 23.5 22H24.5C24.7761 22 25 22.2239 25 22.5V23.5C25 23.7761 24.7761 24 24.5 24H23.5C23.2239 24 23 23.7761 23 23.5V22.5Z"
            fill="#525252"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

ListingHome.propTypes = {
  className: PropTypes.string,
};

ListingHome.defaultProps = {
  className: '',
};

export default ListingHome;
