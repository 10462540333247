import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const Remove = (props) => {
  const { className, onClick } = props;

  return (
    <button type="button" onClick={onClick} className={classNames(styles.removeWrapper, className)}>
      <Icon testid="remove_icon" variant={Icon.MINUS} />
    </button>
  );
};

Remove.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Remove.defaultProps = {
  className: '',
  onClick: () => {},
};

export default Remove;
