import { useEffect, useState } from 'react';
import { AddFormCommentType, CommentUsersType, CustomOptions, FieldInfoType } from 'types';
import { FormCommentActionBar } from '../FormCommentActionBar';
import { selectAllOption } from 'app-constants';
import { useSelector } from 'react-redux';
import { formCommentSelector } from 'store/selectors/formComment';

type FormFieldCommentProps = {
  fieldInfo?: FieldInfoType;
  handleAddFormComment: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
};
export const FormFieldComment = ({ fieldInfo, handleAddFormComment }: FormFieldCommentProps) => {
  const [isSending, setSending] = useState<boolean>(false);

  const [permissionOptions, setPermissionOptions] = useState<CustomOptions>([]);
  const [notifyOptions, setNotifyOptions] = useState<CustomOptions>([]);
  const [permittedUsers, setPermittedUsers] = useState<string[]>([selectAllOption.value]);
  const [notifiedUsers, setNotifiedUsers] = useState<string[]>();
  const formComment = useSelector(formCommentSelector);

  useEffect(() => {
    setPermissionOptions(
      Object.entries(formComment.commentUsers || {})
        .filter(([_key, user]) => !user.self)
        .map(([_key, user]) => ({
          label: user.name || user.email,
          value: user.email,
          avatar: user.avatar,
          role: user.role,
        })),
    );
  }, [formComment.commentUsers]);

  useEffect(() => {
    if (permissionOptions?.length) {
      if (permittedUsers?.includes(selectAllOption.value)) {
        setNotifyOptions(permissionOptions);
      } else {
        setNotifyOptions(
          permissionOptions.filter((permissionOption) =>
            permittedUsers?.includes(permissionOption.value),
          ),
        );
      }
      if (permittedUsers?.length && !permittedUsers?.includes(selectAllOption.value)) {
        setNotifiedUsers(
          notifiedUsers?.filter((notifiedUser) => permittedUsers?.includes(notifiedUser)),
        );
      }
    }
  }, [permittedUsers, permissionOptions]);

  return (
    <FormCommentActionBar
      isNewThread={true}
      isSending={isSending}
      setSending={setSending}
      notifyOptions={notifyOptions}
      permissionOptions={permissionOptions}
      notifiedUsers={notifiedUsers}
      setNotifiedUsers={setNotifiedUsers}
      permittedUsers={permittedUsers}
      setPermittedUsers={setPermittedUsers}
      handleAddFormComment={handleAddFormComment}
      fieldInfo={fieldInfo}
      commentUsers={formComment.commentUsers || {}}
    />
  );
};
