import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { Label, Description, Items } from '../components';

import styles from './styles.module.scss';

const NoPrefs = (props) => {
  const { listItems, className, onChangePref } = props;

  if (!listItems || !listItems.length) return null;

  return (
    <div testid="no_prefs_block" className={classNames(styles.noPrefsWrapper, className)}>
      <Label>No Preference</Label>
      <Description>
        Features that are not important or for which you have no preference.
      </Description>
      <Items
        list={listItems}
        onChangePref={onChangePref}
        currentImportance={SEARCH_CRITERIA_IMPORTANCE.UNDEFINED}
      />
    </div>
  );
};

NoPrefs.propTypes = {
  className: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string),
  onChangePref: PropTypes.func.isRequired,
};

NoPrefs.defaultProps = {
  className: '',
  listItems: undefined,
};

export default NoPrefs;
