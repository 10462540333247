import { formatDate } from 'helpers';
import { DocumentOptions, PdfThumbnail } from './../';

import styles from './styles.module.scss';

export const PropertyDocumentCard = ({
  file,
  Property = {},
  isPreapprovals = false,
  isArchives = false,
  refetch,
  viewType,
}) => {
  return (
    <div className={styles.propertyDocumentCard}>
      <div className={styles.pdfCard}>
        <PdfThumbnail
          documentVaultUUID={file?.DocumentVaultUUID || file?.DocumentLink}
          size={file?.OriginalContentLength}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <p className={styles.docName}>{file?.Title || file?.Filename || file?.FormName}</p>
          <p className={styles.clientName}>
            {file?.UploaderFirstName} {file?.UploaderLastName}
          </p>
          <p className={styles.date}>{formatDate(file?.CreatedDate, 'M/D/YYYY')}</p>
        </div>
        <div className={styles.right}>
          <DocumentOptions
            file={file}
            Property={Property}
            isPreapprovals={isPreapprovals}
            isArchives={isArchives}
            refetch={refetch}
          />
        </div>
      </div>
    </div>
  );
};
