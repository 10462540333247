import { createAction } from 'redux-actions';

export const requestGetAllQuotesAction = createAction('REQUEST/GET_ALL_QUOTES');
export const requestGetQuotesAuditLogsAction = createAction('REQUEST/GET_QUOTES_AUDIT_LOGS_ACTION');
export const requestGetQuoteDetailsAction = createAction('REQUEST/GET_QUOTE_DETAILS');
export const setQuotesGroupByAction = createAction('SET_QUOTES_GROUP_BY');
export const setQuotesArchiveAction = createAction('SET_QUOTES_ARCHIVE');
export const resetQuoteDetailsAction = createAction('RESET_QUOTE_DETAILS');
export const resetRequestQuoteAction = createAction('RESET_REQUEST_QUOTE');
export const requestPostArchiveQuotesAction = createAction('REQUEST/POST_ARCHIVE_QUOTES');
export const respondAndUploadDocumentAction = createAction(
  'REQUEST/POST_RESPOND_AND_UPLOAD_DOCUMENT',
);
export const uploadDocumentAction = createAction('REQUEST/POST_UPLOAD_DOCUMENT');
export const setPreFormQuestionsAction = createAction('SET_PRE_FORM_QUESTIONS');
export const requestPostCreateQuoteDraftAction = createAction('REQUEST/POST_CREATE_QUOTE_DRAFT');
export const requestFormResultsAction = createAction('REQUEST/FORM_RESULTS');
export const setRequestQuotePartnersAction = createAction('SET_REQUEST_QUOTE_PARTNERS');
export const requestPatchQuoteDraftAction = createAction('REQUEST/PATCH_QUOTE_DRAFT');
export const requestPostSubmitDraftQuoteAction = createAction('REQUEST/POST_SUBMIT_DRAFT_QUOTE');
export const updateQuoteQuestionAction = createAction('UPDATE_QUOTE_QUESTION');
export const resumeDraftQuoteAction = createAction('RESUME_DRAFT_QUOTE');
export const setMultipleRequestQuotesAction = createAction('SET_MULTIPLE_REQUEST_QUOTES');
export const deleteQuoteResponseAction = createAction('DELETE_QUOTE_RESPONSE');

export const requestGetQuoteFormAction = createAction('REQUEST/GET_QUOTE_FORM');
export const updateQuoteFormResponseAction = createAction('UPDATE_QUOTE_FORM_RESPONSE');
export const requestUpdateQuoteFormResponseAction = createAction(
  'REQUEST/UPDATE_QUOTE_FORM_RESPONSE',
);
export const setQuotesSearchAction = createAction('SET_QUOTES_SEARCH');
