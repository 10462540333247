import { useEffect, useState } from 'react';
import { usePrevious } from '../../../../hooks';

const useAdditionIndex = (props) => {
  const { currentStageIndex, additionStagesIds, currentStageId } = props;
  const { lastAdditionalStage, startQuestionForAdditionsIds } = props;
  const [indexAdditional, setIndexAdditional] = useState(0);

  const prevCurrentStageIndex = usePrevious(currentStageIndex);

  useEffect(() => {
    if (additionStagesIds.includes(currentStageId)) {
      if (currentStageIndex > prevCurrentStageIndex) {
        setIndexAdditional(indexAdditional + 1);
      } else if (currentStageId !== lastAdditionalStage.id) {
        setIndexAdditional(indexAdditional - 1);
      }
    }

    if (startQuestionForAdditionsIds.includes(currentStageId)) {
      setIndexAdditional(0);
    }
  }, [currentStageIndex]); // eslint-disable-line

  return [indexAdditional, setIndexAdditional];
};

export default useAdditionIndex;
