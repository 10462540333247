import { Checkbox, Col, Popover, Row } from 'components-antd';
import { Card } from '../Card';
import styles from './styles.module.scss';
import { Area, AreaConfig } from '@ant-design/plots';
import { useEffect, useState } from 'react';
import { ArrowDown, ArrowUp } from 'components/Icons';
import moment from 'moment';
import { getQuoteRequests } from 'api/dashboard/PartnerQuoteRequestWidget';

type AreaConfigData = { month: string; value: number };
export type PartnerQuotesSerialized = {
  serializedQuotes: {
    numberOfQuotesInMonth: number;
    respectiveMonthDate: Date;
  }[];
  totalQuotes: number;
};

export const QuoteRequestWidget = () => {
  const filterValues = [3, 6, 12, 18, 24];
  const [dayFilter, setDayFilter] = useState<number>(3);
  const [open, setOpen] = useState(false);
  const [totalRequests, setTotalRequests] = useState<number>(0);
  const [data, setData] = useState<AreaConfigData[]>([] as AreaConfigData[]);

  const fetchQuoteRequestStats = async () => {
    const stats = await getQuoteRequests({ duration: dayFilter });
    if (stats.serializedQuotes.length > 0) {
      let _data = stats.serializedQuotes.map((i) => {
        return {
          month: moment(i.respectiveMonthDate).format('MMM YYYY'),
          value: i.numberOfQuotesInMonth,
        };
      });
      setData(_data);
    } else {
      const _data = Array.from({ length: dayFilter }, (_, index) => {
        return { month: moment().subtract(index, 'months').format('MMM YYYY'), value: 0 };
      });
      setData(_data);
    }

    setTotalRequests(stats.totalQuotes);
  };
  useEffect(() => {
    fetchQuoteRequestStats();
  }, [dayFilter]);

  const handleChange = (checkedValues) => {
    setOpen(false);
    setDayFilter(checkedValues.target.value);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const tooltipCustomContent = (title, items) => {
    return (
      <div className={styles.chartTooltipContainer}>
        <p className={styles.chartTooltipYear}>{title}</p>
        {items.map((item, index) => (
          <p key={index} className={styles.chartTooltipValue}>
            {item.value} {item.name}
          </p>
        ))}
      </div>
    );
  };
  useEffect(() => {
    // Calculate total based on the selected time filter
    const filteredData = data.slice(0, dayFilter);
    const total = filteredData.reduce((sum, item) => sum + item.value, 0);
    setTotalRequests(total);
  }, [dayFilter]);

  const popoverContent = () => (
    <ul className={styles.filterOptions}>
      {filterValues.map((value, index) => {
        return (
          <li>
            <Checkbox
              className={styles.sortCheckbox}
              key={index}
              onChange={handleChange}
              checked={value == dayFilter}
              value={value}
            >
              <div className={styles.checkboxHeading}>{value}m</div>
            </Checkbox>
          </li>
        );
      })}
    </ul>
  );

  const config = {
    data,
    xField: 'month',
    yField: 'value',
    color: '#928CDA',
    responsive: true,
    autoFit: true,
    height: 400,
    smooth: false,
    areaStyle: {
      fill: 'l(270) 0:#928CDA00 1:#928CDA',
    },
    xAxis: {
      label: {
        formatter(text) {
          return `${moment(text).format('MMM')}`;
        },
      },
    },
    tooltip: {
      domStyles: {
        'g2-tooltip': {
          borderRadius: '16px',
          backgroundColor: '#262626',
          height: '80px',
          width: '120px',
        },
      },
      fields: ['month', 'value'],
      showTitle: false,
      formatter: (datum) => ({ name: 'Request', value: datum.value }),
      customContent: tooltipCustomContent,
    },
  } as AreaConfig;

  return (
    <Card cardTitle={'Quote Requests'} showHeader={false}>
      <>
        <header className={styles.cardheader}>
          <div>
            <h3>Quote Requests</h3>
          </div>
          <div>
            <Popover
              content={popoverContent}
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
              overlayClassName={styles.filterDropdown}
              placement="bottomRight"
              className={styles.dropdown}
            >
              <span className={styles.switchButton} onClick={(e) => e.preventDefault()}>
                {dayFilter}m
              </span>
              {open ? <ArrowUp className={styles.icon} /> : <ArrowDown className={styles.icon} />}
            </Popover>
          </div>
        </header>

        <Row className={styles.count}>
          <p>Total {totalRequests}</p>
        </Row>

        <Row align="middle">
          <Col xs={24} sm={24}>
            <Area {...config}></Area>
          </Col>
        </Row>
      </>
    </Card>
  );
};
