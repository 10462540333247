import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';

import { Edit } from 'components/Icons';
import { Button } from 'components-antd';
import { Row, Col } from 'components-antd';
import { Input, Select } from 'components';

import commonStyles from './../styles.module.scss';
import styles from './styles.module.scss';

import NumberFormat from 'react-number-format';
import { TransactionLeadSource } from 'app-constants';
import {
  CommissionField,
  OtherFees,
} from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/FinancingDetails/components';
import { updateTransactionEffect } from 'store/effects';

interface FinancialFieldType {
  label: string;
  valueKey: string;
  isArray?: boolean;
  isPercentage?: boolean | null;
  isFeeOrPercentage?: boolean;
  isDisableField: Function;
  setEditDetailId: Function;
  alwaysShow?: boolean;
}

const leadSourceOptions = Object.values(TransactionLeadSource).map((type) => ({
  value: type,
  name: type,
}));

export const FinancialField = ({
  label,
  valueKey,
  isPercentage = null,
  isArray = false,
  isFeeOrPercentage = false,
  isDisableField,
  setEditDetailId,
  alwaysShow = false,
}: FinancialFieldType) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [hover, setHover] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const value = transaction?.[valueKey] ?? '';
  const [inputField, setInputField] = useState<any>(value ?? '');
  const [showFieldError, setShowFieldError] = useState(false);

  const emptyText = <span className={commonStyles.noneText}>None</span>;

  if ((!alwaysShow && !value) || (isArray && !value?.length)) return null;

  useEffect(() => {
    if (inputField?.Value && inputField?.Value > 0) setShowFieldError(false);
  }, [inputField]);

  const onEdit = () => {
    if (!fullAccess || isDisableField(valueKey)) return null;
    setIsEdit(true);
    setEditDetailId(valueKey);
  };

  const updateFinancialField = () => {
    if (inputField?.Value === 0) {
      setShowFieldError(true);
      return;
    }
    setIsPending(true);
    dispatch(
      updateTransactionEffect(
        {
          id: transaction?.Id,
          [valueKey]: inputField,
        },
        {},
        (err) => {
          if (!err) {
            setIsEdit(false);
          }
          setIsPending(false);
          setEditDetailId(undefined);
        },
      ),
    );
  };

  const setOtherValues = (val: any, index: number, key: string) => {
    setInputField((prev) => {
      let updated = [...prev];
      updated[index][key] = val;
      return updated;
    });
  };

  const renderValue = () => {
    const showPercentage = isFeeOrPercentage ? inputField?.IsFeeTypePercentage : isPercentage;

    if (showPercentage === null) return value;
    else if (showPercentage) return `${inputField?.Value || value}%`;
    else
      return (
        <NumberFormat
          prefix="$"
          displayType="text"
          thousandSeparator
          value={inputField?.Value || value}
        />
      );
  };

  const onCancel = () => {
    setIsEdit(false);
    setEditDetailId(undefined);
    setInputField(value);
  };

  const renderArrayFields = () => {
    if (!value?.length) return emptyText;
    return (
      <>
        {value?.length &&
          value?.map((item, idx) => (
            <Row key={idx}>
              <Col lg={6} className={commonStyles.label}>
                {item?.Description}:
              </Col>
              <Col lg={12}>
                {item?.IsFeeTypePercentage ? (
                  `${item?.Value}%`
                ) : (
                  <NumberFormat
                    prefix="$"
                    displayType="text"
                    thousandSeparator
                    value={item?.Value}
                  />
                )}
              </Col>
            </Row>
          ))}
      </>
    );
  };

  return (
    <>
      {!isEdit ? (
        <div
          className={classNames(commonStyles.cardWrapper, styles.fullWidth, {
            [commonStyles.disabledSection]: !!isDisableField(valueKey),
          })}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={onEdit}
        >
          <div className={commonStyles.fieldContainer}>
            <p className={commonStyles.label}>{label}</p>
            {isArray ? (
              renderArrayFields()
            ) : transaction?.[valueKey] ? (
              <span className={commonStyles.value}>{renderValue()}</span>
            ) : (
              emptyText
            )}
          </div>
          {fullAccess && !isDisableField(valueKey) && (
            <Edit
              className={classNames(commonStyles.editIcon, { [commonStyles.hideIcon]: !hover })}
              stroke="#262626"
            />
          )}
        </div>
      ) : (
        <div className={commonStyles.editWrapper}>
          {isArray ? (
            <div className={styles.financialInputField}>
              <p className={commonStyles.label}>{label}</p>
              <OtherFees
                otherFeeData={inputField}
                setOtherFeeData={setInputField}
                setOtherValues={setOtherValues}
              />
            </div>
          ) : valueKey === 'LeadSource' ? (
            <Select
              value={inputField}
              onSelect={(_, { value }) => setInputField(value)}
              options={leadSourceOptions}
              className={{
                wrapper: styles.financialInputField,
              }}
              placeholder={' '}
              isArrowIcon={true}
              arrowIconClassName={styles.arrowdownicon}
              variant={Select.LIGHT_ROUND}
              label={label}
            />
          ) : isFeeOrPercentage ? (
            <CommissionField
              label={label}
              value={inputField?.Value}
              IsFeeTypePercentage={inputField?.IsFeeTypePercentage}
              update={(object) => {
                const updatedObject = {
                  ...object,
                  Value: object?.Value === '' ? null : parseInt(object?.Value),
                };
                setInputField(updatedObject);
              }}
              className={styles.financialInputField}
            />
          ) : (
            <Input
              variant={Input.LIGHT_ROUNDED}
              placeholder="0%"
              isNumberFormat
              suffix="%"
              onChange={(e) => setInputField(e.target.value)}
              value={inputField}
              label={label}
              minNumber={0}
              maxNumber={100}
              className={styles.financialInputField}
            />
          )}
          {showFieldError && (
            <span className={styles.financialErrorText}>Number should be greater than 0</span>
          )}
          <div className={commonStyles.buttons}>
            <Button
              variant="secondary"
              size="large"
              onClick={updateFinancialField}
              loading={isPending}
              disabled={isFeeOrPercentage && inputField?.Value === null}
            >
              Update
            </Button>
            <Button variant="hollow-bordered" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
