import React from 'react';
import classNames from 'classnames';

import { ActivityFiltersDrawer } from 'pages/Clarity/Components/Activity/components/ActivityFiltersDrawer';
import { Row, Col } from 'components-antd';

import styles from './styles.module.scss';
import { WorkshopActivityLogSection } from 'pages/Clarity/Components/Activity/components/WorkshopActivityLogSection';
export const WorkShopActivity: React.FC<any> = () => {
  return (
    <div className={styles.activityPage}>
      <Row justify="center" align="middle" className={classNames(styles.wrapper)}>
        <Col xs={24} lg={24} xxl={24}>
          <WorkshopActivityLogSection />
        </Col>
        <ActivityFiltersDrawer />
      </Row>
    </div>
  );
};
