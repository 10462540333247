import { routes } from 'settings/navigation/routes';
import { SUPER_USER } from 'settings/constants/roles';
import Dashboard from 'pages/Dashboard';

export default [
  {
    path: routes.dashboard,
    component: Dashboard,
    exact: false,
    roles: [SUPER_USER],
  },
];
