import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { createDraftQuoteId, showSuccessMessage } from 'helpers';

import {
  requestGetAllQuotesAction,
  setQuotesGroupByAction,
  setQuotesArchiveAction,
  requestPostArchiveQuotesAction,
  requestGetQuotesAuditLogsAction,
  setQuotesSearchAction,
  respondAndUploadDocumentAction,
} from 'store/actions/quotes';
import { groupQuotesBy, quoteRequestStatus } from 'settings/constants/quotes';
import { cloneDeep, get } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  groupBy: groupQuotesBy.STATUS,
  isArchive: false,
  userQuoteAuditLogs: [],
  search: '',
};

export default handleActions(
  {
    [requestGetAllQuotesAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestGetQuotesAuditLogsAction]: (state, { payload }) => {
      return {
        ...state,
        userQuoteAuditLogs: get(payload, 'data.result', initialData.userQuoteAuditLogs),
      };
    },
    [setQuotesGroupByAction]: (state, { payload }) => ({
      ...state,
      groupBy: payload,
    }),
    [setQuotesArchiveAction]: (state, { payload }) => ({
      ...state,
      isArchive: payload,
    }),
    [requestPostArchiveQuotesAction]: (state, { payload }) => {
      const archivedQuotes = get(payload, 'data.result', []);
      const stateData = cloneDeep(state.data) || [];
      let isShowSuccessMessage = false;
      let isUnarchived = false;

      archivedQuotes.forEach((archQuote) => {
        const storedQuote = stateData.find((quote) => {
          if (!archQuote?.Status || archQuote?.Status === quoteRequestStatus.Draft) {
            return quote?.Id === createDraftQuoteId(archQuote?.Id);
          }
          return quote?.Id === archQuote?.Id;
        });
        if (storedQuote) {
          if (storedQuote.IsArchived != archQuote?.IsArchived) {
            isShowSuccessMessage = true;
            if (!archQuote?.IsArchived) {
              isUnarchived = true;
            }
          }
          storedQuote.IsArchived = archQuote?.IsArchived;
        }
      });
      if (isShowSuccessMessage) {
        if (isUnarchived) {
          showSuccessMessage('Quote Unarchived Successfully');
        } else {
          showSuccessMessage('Quote Archived Successfully');
        }
      }
      return {
        ...state,
        data: stateData,
      };
    },
    [respondAndUploadDocumentAction]: (state, { payload }) => {
      const uploadedQuote = get(payload, 'data.result', []);
      const stateData = cloneDeep(state.data) || [];
      const storedQuote = stateData.find((quote) => {
        return quote?.Id === uploadedQuote?.Id;
      });
      if (storedQuote) {
        storedQuote.Status = uploadedQuote?.Status;
      }
      return {
        ...state,
        data: stateData,
      };
    },
    [setQuotesSearchAction]: (state, { payload }) => ({
      ...state,
      data: state?.data ? [...state.data] : initialData.data,
      search: payload || '',
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
