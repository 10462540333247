import TableRow from './TableRow';
import styles from './styles.module.scss';

type TablePropsType = {
  rowsHeadings: string[];
  dataObjectKeys: string[];
  columnsData: any[];
  arrayBasedData: boolean;
  hideFields: boolean;
  countStarredFields: number;
  starredFieldsCountHandler: Function;
  childRow?: {
    index: number;
    heading: string;
    key: string;
  };
};

function ComparisonTable(props: TablePropsType) {
  return (
    <table className={styles.propsCompareTable}>
      {props.rowsHeadings.map((itemRow, indexRow) => (
        <TableRow
          indexRow={indexRow}
          rowHeading={itemRow}
          childRow={props.childRow}
          dataObjectKeys={props.dataObjectKeys}
          columnsData={props.columnsData}
          arrayBasedData={props.arrayBasedData}
          hideFields={props.hideFields}
          countStarredFields={props.countStarredFields}
          starredFieldsCountHandler={props.starredFieldsCountHandler}
        />
      ))}
    </table>
  );
}

export default ComparisonTable;
