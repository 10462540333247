import { SVGProps } from 'react';

export const VirtualTourImage = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M8.99921 17C4.49951 17 -0.00056681 15.5 1.49924 11.5"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 17C16 17 18.5 16 18.5 13.5"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 15L11 17L9 19"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 10.1071L13.3288 9.16276C13.0231 9.05628 12.6846 9.10445 12.4207 9.29202C12.1568 9.47959 12 9.78335 12 10.1071H13ZM16.4463 11.3072L16.7752 10.3628L16.7752 10.3628L16.4463 11.3072ZM16.4463 5.1928L16.7752 6.13718L16.4463 5.1928ZM13 6.39286H12C12 6.71665 12.1568 7.02041 12.4207 7.20798C12.6846 7.39555 13.0231 7.44372 13.3288 7.33724L13 6.39286ZM5 5.66667C5 5.29848 5.29848 5 5.66667 5V3C4.19391 3 3 4.19391 3 5.66667H5ZM5 10.8333V5.66667H3V10.8333H5ZM5.66667 11.5C5.29848 11.5 5 11.2015 5 10.8333H3C3 12.3061 4.19391 13.5 5.66667 13.5V11.5ZM11.3333 11.5H5.66667V13.5H11.3333V11.5ZM12 10.8333C12 11.2015 11.7015 11.5 11.3333 11.5V13.5C12.8061 13.5 14 12.3061 14 10.8333H12ZM12 10.1071V10.8333H14V10.1071H12ZM16.7752 10.3628L13.3288 9.16276L12.6712 11.0515L16.1175 12.2516L16.7752 10.3628ZM16 10.9137C16 10.5123 16.3961 10.2308 16.7752 10.3628L16.1175 12.2516C17.0382 12.5722 18 11.8886 18 10.9137H16ZM16 5.58629V10.9137H18V5.58629H16ZM16.7752 6.13718C16.3961 6.2692 16 5.98773 16 5.58629H18C18 4.61138 17.0382 3.92782 16.1175 4.24842L16.7752 6.13718ZM13.3288 7.33724L16.7752 6.13718L16.1175 4.24842L12.6712 5.44847L13.3288 7.33724ZM12 5.66667V6.39286H14V5.66667H12ZM11.3333 5C11.7015 5 12 5.29848 12 5.66667H14C14 4.19391 12.8061 3 11.3333 3V5ZM5.66667 5H11.3333V3H5.66667V5Z"
      fill="#262626"
    />
  </svg>
);
