import { useSelector } from 'react-redux';

import { Row, Col } from 'components-antd';
import { AreaGraphTemplate } from '../../Graphs/AreaGraphTemplate';
import { sampleData, sampleFeesData } from '../../../helper';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { getAgentTeamStatsSelector } from 'store/selectors/clarity';
import { TEAM_BASIC } from 'settings/constants/roles';

import styles from '../styles.module.scss';

export const Financials = () => {
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  return (
    <div className={styles.mainContentWrapper}>
      <Row gutter={[24, 24]}>
        {/* <Col
          span={8}
          xs={{ span: 24, order: 3 }}
          sm={{ span: 12, order: 3 }}
          lg={{ span: 8, order: 3 }}
          className="gutter-row"
        >
          <AreaGraphTemplate title={'Expenses'} color={'#FF576D'} stats={sampleData} />
        </Col> */}

        {teamStats !== 'Team' || agentRole === TEAM_BASIC ? (
          <>
            <Col
              span={8}
              xs={{ span: 24, order: 1 }}
              sm={{ span: 12, order: 1 }}
              lg={{ span: 8, order: 1 }}
              className="gutter-row"
            >
              <AreaGraphTemplate title={'GCI'} color={'#FB913A'} stats={sampleData} />
            </Col>
            <Col
              span={8}
              xs={{ span: 24, order: 4 }}
              sm={{ span: 12, order: 4 }}
              lg={{ span: 8, order: 4 }}
              className="gutter-row"
            >
              <AreaGraphTemplate title={'Agent Split'} color={'#928CDA'} stats={sampleData} />
            </Col>
            <Col
              span={8}
              xs={{ span: 24, order: 4 }}
              sm={{ span: 12, order: 4 }}
              lg={{ span: 8, order: 4 }}
              className="gutter-row"
            >
              <AreaGraphTemplate title={'Agent Net Income'} color={'#928CDA'} stats={sampleData} />
            </Col>
          </>
        ) : (
          <>
            <Col
              span={8}
              xs={{ span: 24, order: 1 }}
              sm={{ span: 12, order: 1 }}
              lg={{ span: 8, order: 1 }}
              className="gutter-row"
            >
              <AreaGraphTemplate title={'GCI'} color={'#FB913A'} stats={sampleData} />
            </Col>
            <Col
              span={8}
              xs={{ span: 24, order: 2 }}
              sm={{ span: 12, order: 2 }}
              lg={{ span: 8, order: 2 }}
              className="gutter-row"
            >
              <AreaGraphTemplate
                title={'Fees'}
                color={'#4673D1'}
                stats={agentRole === TEAM_BASIC ? sampleData : sampleFeesData}
                options={['Team', 'Brokerage']}
                defaultOption={'Team'}
              />
            </Col>
            <Col
              span={8}
              xs={{ span: 24, order: 5 }}
              sm={{ span: 12, order: 5 }}
              lg={{ span: 8, order: 5 }}
              className="gutter-row"
            >
              <AreaGraphTemplate title={'Team Split'} color={'#04A451'} stats={sampleData} />
            </Col>
            <Col
              span={8}
              xs={{ span: 24, order: 6 }}
              sm={{ span: 12, order: 6 }}
              lg={{ span: 8, order: 6 }}
              className="gutter-row"
            >
              <AreaGraphTemplate title={'Brokerage Split'} color={'#FB913A'} stats={sampleData} />
            </Col>
            <Col
              span={8}
              xs={{ span: 24, order: 8 }}
              sm={{ span: 12, order: 8 }}
              lg={{ span: 8, order: 8 }}
              className="gutter-row"
            >
              <AreaGraphTemplate
                title={'Team Net Income'}
                color={'#FF576D'}
                showRequests={false}
                stats={sampleData}
              />
            </Col>
            <Col
              span={8}
              xs={{ span: 24, order: 9 }}
              sm={{ span: 12, order: 9 }}
              lg={{ span: 8, order: 9 }}
              className="gutter-row"
            >
              <AreaGraphTemplate
                title={'Brokerage Net Income'}
                color={'#928CDA'}
                showRequests={false}
                stats={sampleData}
              />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
