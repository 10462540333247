import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getFormattedListingDetailData } from 'store/selectors/listingDetail';
import { getCurrentContextSelector } from 'store/selectors/context';
import { requestScheduleTourEffect, getScheduleTourEffect } from 'store/effects/listingDetail';
import { showSuccessMessage } from 'helpers/success';
import { showErrorMessage } from 'helpers/errors';
import moment from 'moment';
import PhotosViewModal from '../PhotosViewModal';

import HomeActions from '../HomeActions';
import HeadInfo from '../HeadInfo';
import Match from '../Match';
import ListingDetailTabs from '../ListingDetailTabs';
import ListingDetailPhotosBlock from '../PhotosBlock';
import ListingDetailMap from '../ListingDetailMap';
import ListingOpenHouse from '../ListingOpenHouse';
import { getHomeInfoSelector } from '../HomeActions/selectors';

import styles from './styles.module.scss';
import PriceReduction from '../PriceReduction';
import { isValidUrl, fixURL } from 'helpers';

const ListingDetailContent = ({ propertyInfo }) => {
  const {
    activityStatus,
    address,
    price,
    baths,
    beds,
    addressLine2,
    addressLine1,
    photos,
    virtualTour,
    square,
    match,
    propertyHistory,
    sourceSection,
    mlsLastUpdate,
    mlsLastChecked,
    virtualTourBranded,
    virtualTourUnBranded,
    closingPrice,
    recommendedTo,
  } = useSelector(getFormattedListingDetailData);
  const [isTourScheduled, setIsTourScheduled] = useState(true);
  const [isLoadingTourStatus, setIsLoadingTourStatus] = useState(false);

  const dispatch = useDispatch();

  const { isClient } = useSelector(getHomeInfoSelector);
  const context = useSelector(getCurrentContextSelector);

  const { oldPrice = 0, newPrice = 0 } = propertyHistory || {};

  const [showFormLibraryModal, setShowFormLibraryModal] = useState(false);

  const [modalOpenImage, setModalOpenImage] = useState(null);
  const [tabValue, setTabValue] = useState('');
  const [virtualTourValue, setVirtualTourValue] = useState(
    virtualTour && isValidUrl(virtualTour)
      ? fixURL(virtualTour)
      : virtualTourBranded && isValidUrl(virtualTourBranded)
      ? fixURL(virtualTourBranded)
      : isValidUrl(virtualTourUnBranded)
      ? fixURL(virtualTourUnBranded)
      : undefined,
  );

  useEffect(() => {
    if (isClient) {
      setIsLoadingTourStatus(true);
      dispatch(
        getScheduleTourEffect({ propertyId: propertyInfo.Id }, null, (err, { data }) => {
          setIsLoadingTourStatus(false);
          const tour = data.value;
          if (!tour) {
            setIsTourScheduled(false);
          }
          if (moment(tour.ScheduleDateTime) < moment()) {
            setIsTourScheduled(false);
            return;
          }
          if (tour.Status === 'Canceled') {
            setIsTourScheduled(false);
          }
        }),
      );
    }
  }, []);

  const virtualTours = [
    ...(virtualTour && isValidUrl(virtualTour)
      ? [{ item: 'Virtual Tour', itemValue: virtualTour }]
      : []),
    ...(virtualTourBranded && isValidUrl(virtualTourBranded)
      ? [{ item: 'Virtual Tour (Branded)', itemValue: virtualTourBranded }]
      : []),
    ...(virtualTourUnBranded &&
    isValidUrl(virtualTourUnBranded) &&
    !virtualTourUnBranded.includes('.pdf')
      ? [{ item: 'Virtual Tour (UnBranded)', itemValue: virtualTourUnBranded }]
      : []),
  ];
  const virtualTourVisibility = virtualTours?.length > 0;

  const openModal = useCallback((value = 0) => {
    setModalOpenImage(value);
    document.body.style.overflow = 'hidden';
  }, []);

  const closeModal = useCallback(() => {
    setModalOpenImage(null);
    document.body.style.overflow = 'auto';
  }, []);

  const setTab = useCallback((value) => {
    setTabValue(value);
  }, []);

  const onScheduleTour = () => {
    let agentId = context.Agents.map((agent) => agent.Id);
    dispatch(
      requestScheduleTourEffect({ propertyInfo, agentId }, null, (error, response) => {
        if (response?.status === 200) {
          setIsTourScheduled(true);
          showSuccessMessage('Tour scheduled successfully');
        } else {
          showErrorMessage('Tour not scheduled successfully');
        }
      }),
    );
  };

  const handleOnTourSchedule = useCallback(() => setIsTourScheduled(true), []);

  return (
    <>
      <PhotosViewModal
        isTourScheduled={isTourScheduled}
        onScheduleTour={onScheduleTour}
        closeModal={closeModal}
        recommendedTo={recommendedTo}
        setVirtualTourValue={setVirtualTourValue}
        virtualTourValue={virtualTourValue}
        virtualTours={virtualTours}
        tabValue={tabValue}
        setTab={setTab}
        photos={photos}
        modalOpenImage={modalOpenImage}
        isOpen={modalOpenImage !== null}
        onClose={closeModal}
        setShowFormLibraryModal={setShowFormLibraryModal}
        propertyInfo={propertyInfo}
        virtualTourVisibility={virtualTourVisibility}
        photosTab={photos.length > 0}
      />
      <div testid="property_content" className={styles.container}>
        <ListingDetailPhotosBlock
          photos={photos}
          openModal={openModal}
          setTab={setTab}
          virtualTour={virtualTourVisibility}
        />
        <div className={styles.contentWrapper}>
          <div className={styles.contentBlock}>
            <HeadInfo
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              price={price}
              baths={baths}
              beds={beds}
              square={square}
              sourceSection={sourceSection}
              mlsLastUpdate={mlsLastUpdate}
              mlsLastChecked={mlsLastChecked}
              activityStatus={activityStatus}
              closingPrice={closingPrice}
            />
            <Match />
            <ListingDetailTabs />
          </div>
          <div className={styles.sidebarBlock}>
            <ListingOpenHouse
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              photo={photos?.length ? photos[0] : null}
            />
            <PriceReduction oldPrice={oldPrice} newPrice={newPrice} />
            <ListingDetailMap openModal={openModal} setTab={setTab} />
            <HomeActions
              recommendedTo={recommendedTo}
              virtualTour={virtualTour}
              virtualTourBranded={virtualTourBranded}
              virtualTourUnBranded={virtualTourUnBranded}
              price={price}
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              showFormLibraryModal={showFormLibraryModal}
              setShowFormLibraryModal={setShowFormLibraryModal}
              isTourScheduled={isTourScheduled}
              onTourSchedule={handleOnTourSchedule}
              address={address}
              isFetchingTourStatus={isLoadingTourStatus}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingDetailContent;
