import { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { convertNameToAvatarPlaceholder, getPropertyAddress } from 'helpers';
import { getUserDataSelector } from 'store/selectors/user';
import { SendMessageFormView, Avatar, Wrapper as PendingWrapper, CommentsView } from 'components';

import {
  editOfferMessageEffect,
  deleteOfferMessageEffect,
  sendNewOfferMessageEffect,
  socketsGetOfferMessagesByThreadIdEffect,
  socketSetEditOfferMessageEffect,
} from 'store/effects/sockets/offerMessages';
import { createNewThreadEffect, sendMarkThreadAsReadEffect } from 'store/effects/sockets/threads';
import { SOCKET_THREAD_TYPES, SOCKET_MESSAGE_TYPES } from 'settings/constants/sockets';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getOfferMessagesSelector } from 'store/selectors/offerMessages';
import { getCurrentEditOfferMessageSelector } from 'store/selectors/sockets/offerMessages';
import { setShowCommentIconEffect } from 'store/effects/taskAggregate';
import { setShowCommentIconForTransactionEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';
import { socketsStoreEditedOfferMessageAction } from 'store/actions/sockets/offerMessages';

const Comments = (props) => {
  const { className, commentAutoFocus, readOnly } = props;
  const commentViewWrapperRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const { currentThreadId, messages, threadsList, userId } = useSelector(getOfferMessagesSelector);
  const currentEditMessage = useSelector(getCurrentEditOfferMessageSelector);
  const [isThreadCreating, setIsThreadCreating] = useState(false);
  const checkAndGetThread = () =>
    threadsList.find(
      (thread) =>
        thread?.EntityId === `${props.offerData?.OfferId}` &&
        thread?.Type === SOCKET_THREAD_TYPES.OFFER,
    );

  useEffect(() => {
    if (currentThreadId) {
      dispatch(sendMarkThreadAsReadEffect({ threadId: currentThreadId }));
    }
  }, [dispatch, currentThreadId]);

  const onCancelEditHandler = () => {
    dispatch(socketSetEditOfferMessageEffect({ editMessage: null }));
  };

  const onSendMessage = (message, attachments, tagsValue, messageId, threadId, accessType) => {
    if (currentEditMessage) {
      onCancelEditHandler();
    }

    if (messageId) {
      const payload = messages.find((message) => message.Id === messageId);
      dispatch(
        socketsStoreEditedOfferMessageAction({
          data: {
            ...payload,
            Text: message,
            Attachments: attachments,
            MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
            MessageMeta: { Tags: tagsValue },
            AccessType: accessType,
          },
        }),
      );
      return dispatch(
        editOfferMessageEffect({
          MessageId: messageId,
          Text: message,
          Attachments: attachments,
          MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
          MessageMeta: { Tags: tagsValue },
          AccessType: accessType,
        }),
      );
    }

    return dispatch(
      sendNewOfferMessageEffect({
        ThreadId: threadId || currentThreadId,
        Text: message,
        Attachments: attachments,
        MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
        MessageMeta: { Tags: tagsValue },
        AccessType: accessType,
      }),
    );
  };

  const onDeleteMessageHandler = (messageId) => {
    return dispatch(deleteOfferMessageEffect({ MessageId: messageId }));
  };

  const onCreateThreadAndSendNewMessage = (
    message,
    attachments,
    tagsValue,
    messageId,
    threadId,
    accessType,
  ) => {
    const existThread = checkAndGetThread();

    if (existThread) {
      onSendMessage(message, attachments, null, existThread?.Id, null, accessType);
      return dispatch(socketsGetOfferMessagesByThreadIdEffect({ Id: existThread?.Id }));
    }

    setIsThreadCreating(true);
    dispatch(
      createNewThreadEffect(
        {
          Type: SOCKET_THREAD_TYPES.OFFER,
          EntityId: `${props.offerData?.OfferId}`,
          TopicName: getPropertyAddress(transaction?.Property?.Address),
          ParticipantIds: [
            ...(transaction?.Participants || []).map((participant) => participant?.Id),
            userId,
          ],

          FirstMessage: {
            Text: message,
            Attachments: attachments,
            MessageType: SOCKET_MESSAGE_TYPES.CHAT_MESSAGE,
            MessageMeta: { Tags: tagsValue },
            AccessType: accessType,
          },
        },
        {},
        (err, data) => {
          dispatch(socketsGetOfferMessagesByThreadIdEffect({ Id: data?.Id }));
          setIsThreadCreating(false);
          if (props.offerData?.OfferId)
            dispatch(setShowCommentIconEffect({ taskId: props.offerData?.OfferId }));
          if (props.offerData?.OfferId && props.offerData?.TransactionId) {
            dispatch(
              setShowCommentIconForTransactionEffect({
                taskId: props.offerData?.OfferId,
              }),
            );
          }
        },
      ),
    );
  };

  const getUserName = () => `${user?.FirstName} ${user?.LastName}`;

  const onEditMessageHandler = useCallback(
    (messageId) => {
      const editMessage = messages.find((message) => message?.Id === messageId);
      dispatch(socketSetEditOfferMessageEffect({ editMessage }));
    },
    [dispatch, messages],
  );

  useEffect(() => {
    if (commentViewWrapperRef?.current && currentThreadId) {
      commentViewWrapperRef.current.scrollTop = commentViewWrapperRef?.current?.scrollHeight;
    }
  }, [messages, currentThreadId]);

  const getMessagesContent = () => {
    if (isThreadCreating) {
      return (
        <PendingWrapper
          isPending={isThreadCreating}
          className={styles.pendingWrapper}
          loaderClassName={styles.loader}
        />
      );
    }

    if (currentThreadId) {
      return (
        <div className={styles.CommentsViewWrapper} ref={commentViewWrapperRef}>
          <CommentsView
            threadId={currentThreadId}
            list={messages}
            currentEditMessage={currentEditMessage}
            onEditMessage={onEditMessageHandler}
            className={styles.messages}
            onDeleteMessage={onDeleteMessageHandler}
          />
        </div>
      );
    }

    return (
      <div className={styles.empty}>
        <span testid="no_comments" className={styles.message}>
          No Comments
        </span>
      </div>
    );
  };

  return (
    <div className={classNames(styles.offerComments, className)}>
      <div className={styles.holder}>
        <SendMessageFormView
          showDropdown
          id="offerComments"
          onSend={currentThreadId ? onSendMessage : onCreateThreadAndSendNewMessage}
          subheader={null}
          content={getMessagesContent()}
          autoFocus={commentAutoFocus}
          footerClassName={styles.footer}
          footerInnerClassName={styles.footerInner}
          inputGroupClassName={styles.inputGroup}
          attachments={false}
          emoji={true}
          autocomplete={true}
          inputPlaceholder="Start typing..."
          sendButtonClassName={styles.sendButton}
          onCancelEdit={onCancelEditHandler}
          currentEditMessage={currentEditMessage}
          placeholder="Type to search users"
          placeholderClassName={styles.commentPlaceholder}
          isTaskComments
          className={styles.commentsForm}
          avatar={true}
          avatarPlaceHolder={
            <div className={styles.avatarPlaceholder}>
              {convertNameToAvatarPlaceholder(getUserName())}
            </div>
          }
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

Comments.propTypes = {
  className: PropTypes.string,
  commentAutoFocus: PropTypes.bool,
  offerData: PropTypes.any,
  readOnly: PropTypes.bool,
};

Comments.defaultProps = {
  className: '',
  commentAutoFocus: false,
  readOnly: false,
};

export default Comments;
