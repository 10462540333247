import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SORT_ASC, SORT_DESC } from 'settings/constants/sort';

import { FullArrowDown, FullArrowUp } from 'components/Icons';

import styles from './styles.module.scss';

const Sort = (props) => {
  const { selector, effect, field } = props;
  const { order, fields } = useSelector(selector);
  const dispatch = useDispatch();
  const isUsed = fields?.includes(field);
  const isDesc = order === SORT_DESC;

  const toggleOrder = useCallback(() => {
    const newOrder = isDesc ? SORT_ASC : SORT_DESC;
    if (effect) dispatch(effect({ order: newOrder, fields: [field] }));
  }, [dispatch, effect, field, isDesc]);

  return (
    <div className={classNames(styles.sort, { [styles.active]: isUsed })} onClick={toggleOrder}>
      <span className={styles.iconsGroup}>
        {isDesc || !isUsed ? (
          <FullArrowDown className={styles.sortIcon} />
        ) : (
          <FullArrowUp className={styles.sortIcon} />
        )}
      </span>
    </div>
  );
};

Sort.propTypes = {
  selector: PropTypes.func.isRequired,
  effect: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
};
Sort.defaultProps = {};

export default Sort;
