import classNames from 'classnames';

import { Tooltip } from 'components-antd';
import { GreenCheckMark } from 'components/Icons';
import { Allowed_Stroke_Sizes } from 'app-constants';

import styles from './styles.module.scss';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import { useDispatch } from 'react-redux';
import { updateDynamicFormQuestionEffect } from 'store/effects/formProcess';

export const StrokePopover = ({ field, selectedFieldIndex, fieldActionProps }) => {
  const dispatch = useDispatch();

  const onFieldUpdate = (strokeWidth) => {
    const newField = { ...field, strokeWidth };

    dispatch(
      updateDynamicFormQuestionEffect({
        ...newField,
        index: selectedFieldIndex,
        ...fieldActionProps,
      }),
    );
  };

  const strikeLabel = (size: number) => {
    return (
      <div
        className={styles.strikeLabel}
        onClick={(e) => onFieldUpdate(size)}
        key={`strike-${size}`}
      >
        <div className={styles.strikeLabelColumn}>
          <div
            className={styles.stroke}
            style={{
              height: `${size}px`,
            }}
          >
            &nbsp;
          </div>
          <div className={styles.strokeNumber}>{size}px</div>
        </div>
        {size === field.strokeWidth && <GreenCheckMark />}
      </div>
    );
  };

  const strokeOptions = (
    <div className={styles.strokeListContainer}>
      {Allowed_Stroke_Sizes.map((size) => strikeLabel(size))}
    </div>
  );

  return (
    <div className={styles.strokePopoverWrapper}>
      <Tooltip
        title={strokeOptions}
        color="white"
        overlayClassName={styles.strokeToolTipContainer}
        placement="bottomRight"
        trigger={['click']}
      >
        <div className={classNames(styles.lineStrokeContainer, styles.strokeSelect)}>
          <Icons variant={ICON_VARIANT_TYPE.LineThickness} color={'#fff'} />
        </div>
      </Tooltip>
    </div>
  );
};
