import React, { SVGProps } from 'react';

type variantTypes =
  | 'markets'
  | 'listings'
  | 'transactions'
  | 'teams'
  | 'partners'
  | 'clients'
  | 'kits'
  | 'none';
interface IIcons extends SVGProps<SVGSVGElement> {
  variant: variantTypes;
}

export const Icons = ({ variant = 'none', ...props }: IIcons) => {
  const renderIcon = () => {
    switch (variant) {
      case 'markets': {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M27.8303 11.6988C28.2486 11.9769 28.5 12.4458 28.5 12.9481L28.5 26.5C28.5 27.3284 27.8284 28 27 28L7 28C6.17157 28 5.5 27.3284 5.5 26.5L5.5 12.9481C5.5 12.4458 5.75138 11.9769 6.16966 11.6988L16.1697 5.05192C16.6727 4.71754 17.3273 4.71754 17.8303 5.05192L27.8303 11.6988Z"
              fill="#51BFE1"
            />
            <circle cx="25.5" cy="25.5" r="10" fill="#928CDA" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.5 15.9578L28.5 26.5C28.5 27.3284 27.8284 28 27 28L15.815 28C15.6094 27.201 15.5 26.3632 15.5 25.5C15.5 19.9772 19.9772 15.5 25.5 15.5C26.5452 15.5 27.553 15.6604 28.5 15.9578Z"
              fill="#2E69C0"
            />
          </svg>
        );
      }
      case 'partners': {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M4.10493 18.5256C3.32185 18.1236 3.35857 16.9591 4.16191 16.5993C9.56906 14.1771 12.545 11.3409 15.5359 5.11851C15.9163 4.32718 17.0769 4.32928 17.456 5.12123C20.0248 10.488 22.8937 13.6677 28.9916 16.594C29.7658 16.9655 29.7932 18.0902 29.0337 18.4907C23.369 21.4772 20.4002 24.3697 17.4495 30.0706C17.0587 30.8256 15.9499 30.8209 15.565 30.0629C12.6042 24.2329 9.63773 21.3663 4.10493 18.5256Z"
              fill="#FB913A"
            />
            <path
              d="M16.102 23.5332C15.3222 23.1248 15.3577 21.9581 16.1573 21.5902C20.551 19.5686 23.0516 17.1674 25.5288 12.1149C25.9153 11.3266 27.0777 11.3285 27.4638 12.1171C29.6047 16.4907 32.0338 19.1532 36.9963 21.5868C37.7673 21.9649 37.7926 23.0913 37.0358 23.4971C32.4131 25.9756 29.908 28.4218 27.456 33.0737C27.0595 33.8258 25.9495 33.8218 25.5589 33.0667C23.1025 28.3172 20.6054 25.8911 16.102 23.5332Z"
              fill="#FF576D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.528 14.0078C25.802 14.9193 27.2679 15.7669 28.9919 16.5942C29.766 16.9657 29.7935 18.0904 29.0339 18.4909C24.9265 20.6564 22.2365 22.7724 19.943 25.9455C18.8313 25.0817 17.5739 24.3039 16.1022 23.5334C15.3224 23.125 15.3579 21.9584 16.1576 21.5904C19.9628 19.8395 22.3481 17.8039 24.528 14.0078Z"
              fill="#E4364D"
            />
          </svg>
        );
      }
      case 'listings': {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M14.5 7C14.5 6.17157 15.1716 5.5 16 5.5H32C32.8284 5.5 33.5 6.17157 33.5 7V35.3148C33.5 35.9694 32.9694 36.5 32.3148 36.5H16C15.1716 36.5 14.5 35.8284 14.5 35V7Z"
              fill="#4673D1"
            />
            <path
              d="M5.5 17C5.5 16.1716 6.17157 15.5 7 15.5H19.5C20.3284 15.5 21 16.1716 21 17V36.5H7C6.17157 36.5 5.5 35.8284 5.5 35V17Z"
              fill="#51BFE1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 36.5H21V17C21 16.1716 20.3284 15.5 19.5 15.5H14.5V35C14.5 35.8284 15.1716 36.5 16 36.5Z"
              fill="#1656B8"
            />
          </svg>
        );
      }
      case 'transactions': {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M14.7467 6.66535C15.0252 6.24955 15.4927 6 15.9931 6H35.5C36.3284 6 37 6.67157 37 7.5V21.5C37 22.3284 36.3284 23 35.5 23H15.9931C15.4927 23 15.0252 22.7504 14.7467 22.3347L10.059 15.3347C9.72074 14.8296 9.72074 14.1704 10.059 13.6653L14.7467 6.66535Z"
              fill="#928CDA"
            />
            <path
              d="M25.2533 17.6653C24.9748 17.2496 24.5073 17 24.0069 17H4.5C3.67157 17 3 17.6716 3 18.5V32.5C3 33.3284 3.67157 34 4.5 34H24.0069C24.5073 34 24.9748 33.7504 25.2533 33.3347L29.941 26.3347C30.2793 25.8296 30.2793 25.1704 29.941 24.6653L25.2533 17.6653Z"
              fill="#FB9C4E"
            />
            <path
              d="M28.8254 23H15.9927C15.4923 23 15.0248 22.7504 14.7464 22.3347L11.1738 17H24.0065C24.507 17 24.9744 17.2496 25.2529 17.6653L28.8254 23Z"
              fill="#A05730"
            />
          </svg>
        );
      }
      case 'teams': {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="16.25" cy="16.25" r="10.75" fill="#928CDA" />
            <circle cx="23.75" cy="23.75" r="10.75" fill="#FF576D" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.25 27C22.1871 27 27 22.1871 27 16.25C27 15.2582 26.8657 14.2977 26.6142 13.3858C25.7023 13.1343 24.7418 13 23.75 13C17.8129 13 13 17.8129 13 23.75C13 24.7418 13.1343 25.7023 13.3858 26.6142C14.2977 26.8657 15.2582 27 16.25 27Z"
              fill="#92305D"
            />
          </svg>
        );
      }
      case 'clients': {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="20" cy="13.5" r="9" fill="#51BFE1" />
            <path
              d="M34.6405 33.5C35.423 33.5 36.0639 32.8667 35.9949 32.0901C35.8513 30.4722 35.461 28.8827 34.8351 27.3771C34.0281 25.4359 32.8453 23.672 31.3543 22.1863C29.8632 20.7006 28.0931 19.522 26.1449 18.7179C24.1967 17.9139 22.1087 17.5 20 17.5C17.8913 17.5 15.8033 17.9139 13.8551 18.7179C11.9069 19.522 10.1368 20.7006 8.64572 22.1863C7.15465 23.672 5.97188 25.4359 5.16492 27.3771C4.53903 28.8827 4.14873 30.4722 4.00509 32.0901C3.93614 32.8667 4.57696 33.5 5.35945 33.5L34.6405 33.5Z"
              fill="#928CDA"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.0328 19.1163C25.3836 21.1788 22.846 22.5 19.9998 22.5C17.1536 22.5 14.616 21.1788 12.9668 19.1163C13.2583 18.9748 13.5545 18.8419 13.8549 18.7179C15.8031 17.9139 17.8911 17.5 19.9998 17.5C22.1085 17.5 24.1965 17.9139 26.1447 18.7179C26.4451 18.8419 26.7412 18.9748 27.0328 19.1163Z"
              fill="#2E69C0"
            />
          </svg>
        );
      }
      case 'kits': {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.5 8.5V19C25.5 20.1046 24.6046 21 23.5 21H12.8333C12.617 21 12.4064 21.0702 12.2333 21.2L7.3 24.9C6.97038 25.1472 6.5 24.912 6.5 24.5V8.5C6.5 7.39543 7.39543 6.5 8.5 6.5H23.5C24.6046 6.5 25.5 7.39543 25.5 8.5Z"
              fill="#FB913A"
            />
            <path
              d="M14.5 16.5V27C14.5 28.1046 15.3954 29 16.5 29H27.1667C27.383 29 27.5936 29.0702 27.7667 29.2L32.7 32.9C33.0296 33.1472 33.5 32.912 33.5 32.5V16.5C33.5 15.3954 32.6046 14.5 31.5 14.5H16.5C15.3954 14.5 14.5 15.3954 14.5 16.5Z"
              fill="#FF576D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.5 14.5V19C25.5 20.1046 24.6046 21 23.5 21H14.5V16.5C14.5 15.3954 15.3954 14.5 16.5 14.5H25.5Z"
              fill="#E4364D"
            />
          </svg>
        );
      }
      default:
        return <></>;
    }
  };
  return renderIcon();
};
