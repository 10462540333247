/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ButtonGroup } from 'components';
import { Select, Option } from 'components-antd';

import styles from './styles.module.scss';

const Baths = (props) => {
  const {
    className,
    disabled,
    label,
    onChange,
    testid,
    buttonClassName,
    buttonGroupClassName,
    value,
    defaultValue,
    type,
    dropdownClassName,
  } = props;
  const btnClassName = useMemo(
    () => ({
      ...(buttonClassName || {}),
      button: classNames(buttonClassName?.button || '', styles.option),
    }),
    [buttonClassName],
  );
  return (
    <div className={classNames(type != 'dropdown' && styles.bathsWrapper, className)}>
      {type === 'dropdown' ? (
        <>
          {label && <h4 className={styles.dropdownLabel}>{label}</h4>}
          <Select
            placeholder="No min"
            style={{ width: '100%' }}
            onChange={onChange}
            value={value}
            popupClassName={dropdownClassName}
            bordered={false}
          >
            {Baths.dropdownOptions.map((bath) => (
              <Option key={bath.id} value={bath.value}>
                {bath.label}
              </Option>
            ))}
          </Select>
        </>
      ) : (
        <ButtonGroup
          label={label}
          buttons={Baths.options}
          defaultValue={defaultValue}
          onChange={(val) => onChange(val[0])}
          disabled={disabled}
          multiple={false}
          testid={testid}
          buttonClassName={btnClassName}
          buttonGroupClassName={buttonGroupClassName}
          value={value}
        />
      )}
    </div>
  );
};

Baths.options = [
  { id: 'any', label: 'Any' },
  { id: 1, label: '1+' },
  { id: 2, label: '2+' },
  { id: 3, label: '3+' },
  { id: 4, label: '4+' },
  { id: 5, label: '5+' },
];
Baths.dropdownOptions = [
  { id: 'any', label: 'Any', value: 'Any' },
  { id: 1, label: '1+', value: '1' },
  { id: 2, label: '2+', value: '2' },
  { id: 3, label: '3+', value: '3' },
  { id: 4, label: '4+', value: '4' },
  { id: 5, label: '5+', value: '5' },
];
Baths.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  defaultValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  buttonGroupClassName: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  testid: PropTypes.string,
  buttonClassName: PropTypes.shape({
    button: PropTypes.string,
  }),
  dropdownClassName: PropTypes.string,
};

Baths.defaultProps = {
  className: '',
  buttonGroupClassName: '',
  disabled: false,
  label: 'Baths',
  onChange: () => {},
  testid: 'baths',
  buttonClassName: {
    button: '',
  },
  value: null,
  defaultValue: [Baths.options[0].id],
};

export default Baths;
