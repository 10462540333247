import { useEffect, useState } from 'react';

import { isEmail } from 'utils';
import { FORM_MODE } from 'app-constants';
import { Form, Input } from 'components-antd';
import { FormMode, ResponseType } from 'types';

import styles from './styles.module.scss';

type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus'];

interface EmailQuestionProps {
  mode: FormMode;
  formResponse?: ResponseType;
  handleFormResponse?: ({ Answer }: Partial<ResponseType>) => void;
}

export const EmailQuestion = ({ mode, formResponse, handleFormResponse }: EmailQuestionProps) => {
  const editMode = mode === FORM_MODE.Edit;

  const [inputState, setInputState] = useState('');
  const [validation, setValidation] = useState<{
    validateStatus: ValidateStatus;
    message: string;
  }>();

  useEffect(() => {
    setInputState('');
    setValidation(undefined);
    if (formResponse?.Answer) {
      setInputState(formResponse?.Answer);
    }
  }, [formResponse?.UUID]);

  const handleUserInput = (value: string) => {
    setInputState(value);

    if (isEmail(value)) {
      setValidation(undefined);
      if (formResponse?.UUID && handleFormResponse) handleFormResponse({ Answer: value });
    } else {
      setValidation({ validateStatus: 'error', message: `${value} is not a valid email` });
      if (formResponse?.UUID && handleFormResponse) handleFormResponse({ Answer: '' });
    }
  };

  return (
    <Form>
      <Form.Item
        className={styles.item}
        validateStatus={validation?.validateStatus}
        help={validation?.message}
      >
        <Input
          disabled={editMode}
          placeholder="Email"
          className={styles.responseInput}
          size="large"
          value={inputState}
          bordered={false}
          onChange={(e) => handleUserInput(e.target.value)}
        />
      </Form.Item>
    </Form>
  );
};
