import { GoogleMap, StreetViewPanorama } from '@react-google-maps/api';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getListingDetailMapData } from 'store/selectors/listingDetail';
import { useEffect, useState } from 'react';
import { Marker } from 'components/Icons';

const StreetView = () => {
  const { zoom, center } = useSelector(getListingDetailMapData);
  const [streetViewAvailable, setStreetViewAvailable] = useState(true);
  const [loading, setLoading] = useState(true);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  useEffect(() => {
    const checkStreetViewAvailability = async () => {
      const streetViewService = new window.google.maps.StreetViewService();
      streetViewService.getPanorama({ location: center }, (_, status) => {
        setStreetViewAvailable(status === 'OK');
        setLoading(false);
      });
    };

    checkStreetViewAvailability();
  }, [center]);

  return (
    <div className={styles.mapModal}>
      {!loading && (
        <div className={styles.bottomBar}>
          {streetViewAvailable ? (
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
              <StreetViewPanorama
                position={center}
                visible={true}
                options={{ addressControl: false }}
              />
            </GoogleMap>
          ) : (
            <div className={styles.notFound}>
              <Marker size={30} />
              <p>Sorry, Street View is not available for this home.</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StreetView;
