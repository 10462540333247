import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { chunk } from 'lodash-es';

import { useScreen } from 'hooks';
import { pushOrRemoveStageOptionObj } from 'pages/OnBoardingWizard/helpers';
import Icon from '../Icon';
import { Row, Col } from 'antd';

import styles from './styles.module.scss';

const Options = (props) => {
  const { className, onChange, value, disabled, testid } = props;
  const [actives, setActives] = useState(value);
  const { screen } = useScreen();

  const onClick = useCallback(
    (type) => {
      const newActives = pushOrRemoveStageOptionObj({ obj: actives, key: type, multiple: true });

      setActives(newActives);
      onChange(newActives);
    },
    [onChange, actives],
  );

  const getOptions = () => {
    if (screen.mobileSmallWidth) {
      return chunk(Options.options, 1);
    }
    if (screen.mobileWidth) {
      return chunk(Options.options, 2);
    }
    return chunk(Options.options, 3);
  };
  const renderOptions = () =>
    getOptions().map((option, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Col xs={12} sm={24}>
        <Row gutter={[20, 20]} key={index} className={styles.optionHolder}>
          {option.map(({ id, label }) => (
            <Col xs={24} sm={12} md={8}>
              <div
                testid={testid}
                key={id}
                onClick={() => onClick(id)}
                className={classNames(styles.option, { [styles.active]: id in actives })}
              >
                <div className={styles.optionInner}>
                  <Icon
                    className={styles.icon}
                    variant={id}
                    color={id in actives ? '#FF576D' : '#666666'}
                  />
                  <p className={classNames(styles.label, { [styles.labelActive]: id in actives })}>
                    {label}
                  </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    ));

  return (
    <div className={classNames(styles.wrapper, { [styles.disabled]: disabled }, className)}>
      <Row gutter={[20, 20]} className={styles.holder}>
        {renderOptions()}
      </Row>
    </div>
  );
};

Options.SINGLE_FAMILY = 'Single';
Options.CONDOMINIUM = 'Condo';
Options.TOWNHOME = 'Townhouse';
Options.MULTI_FAMILY = 'MultiFamily';
Options.LAND = 'Land';
Options.NEW = 'NewConstruction';

Options.options = [
  { id: Options.SINGLE_FAMILY, label: 'Single Family Home' },
  { id: Options.CONDOMINIUM, label: 'Condo' },
  { id: Options.TOWNHOME, label: 'Townhouse' },
  { id: Options.MULTI_FAMILY, label: 'Multi-Family' },
  { id: Options.LAND, label: 'Land' },
  { id: Options.NEW, label: 'New Construction' },
];

Options.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.shape({}),
  disabled: PropTypes.bool,
  testid: PropTypes.string,
};

Options.defaultProps = {
  className: '',
  onChange: () => {},
  value: {},
  disabled: false,
  testid: undefined,
};

export default Options;
