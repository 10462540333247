import React, { memo, useMemo } from 'react';

const Trash = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div {...props}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.33325 9.33325H26.6666"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.3333 14.6667V22.6667"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.6667 14.6667V22.6667"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66675 9.33325L8.00008 25.3333C8.00008 26.0405 8.28103 26.7188 8.78113 27.2189C9.28123 27.719 9.9595 27.9999 10.6667 27.9999H21.3334C22.0407 27.9999 22.7189 27.719 23.219 27.2189C23.7191 26.7188 24.0001 26.0405 24.0001 25.3333L25.3334 9.33325"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 9.33333V5.33333C12 4.97971 12.1405 4.64057 12.3905 4.39052C12.6406 4.14048 12.9797 4 13.3333 4H18.6667C19.0203 4 19.3594 4.14048 19.6095 4.39052C19.8595 4.64057 20 4.97971 20 5.33333V9.33333"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [props.className],
  );

export default memo(Trash);
