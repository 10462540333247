import classNames from 'classnames';
import styles from './styles.module.scss';
import { Icons } from 'pages/FormProcess/Icons';
import { FormItemMenu } from '../FormItemMenu';
import { InfoTooltip } from '../../InfoTooltip';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';

export const FormItem = (props) => {
  const { selected, onSelect, formItem, isLast, onRemoveDocument, loading } = props;

  const { Id, Title, PublicId } = formItem;
  const authPublicDocumentId = dynamicManager.getAuthDocumentPublicId();

  return (
    <div
      className={classNames(styles.formItem, {
        [styles.selectedForm]: selected,
        [styles.disabledFormItem]: loading,
      })}
      onClick={() => !loading && onSelect(Id)}
    >
      <div className={styles.itemContent}>
        <div className={styles.iconsContainer}>
          {!isLast && <Icons variant="DragDots" className={styles.dragDotsIcon} />}
          <Icons variant="GrayFile" />
        </div>

        <InfoTooltip text={Title}>
          <div className={styles.formTitle}>{Title}</div>
        </InfoTooltip>
      </div>

      <div>
        <FormItemMenu
          document={formItem}
          hideDelete={isLast || PublicId === authPublicDocumentId}
          onRemove={(setPopupLoading, setPopupOpen) => {
            onRemoveDocument(Id, setPopupLoading, setPopupOpen);
          }}
        />
      </div>
    </div>
  );
};
