/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { TitleWithButtons } from 'components/SettingsGroup';
import { Button, PageNotFound, Select, Wrapper } from 'components';
import Input from 'components/Form/Input';
import { ERROR, PENDING } from 'settings/constants/apiState';
import { getServiceTagSelector, getLoadingServiceTagData } from 'store/selectors/adminPanel';
import {
  createServiceTagEffect,
  requestGetServiceTagByIdEffect,
  updateServiceTagEffect,
} from 'store/effects/adminPanel';
import { useBackPage } from 'hooks';
import { routes } from 'settings/navigation/routes';
import { typesOptions } from '../../config';
import { ValidationSchema } from './validation';

import styles from './styles.module.scss';

const ServiceTagForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const serviceTag = useSelector(getServiceTagSelector);
  const { state } = useSelector(getLoadingServiceTagData);
  const [onBack] = useBackPage({ defaultBackUrl: routes.dashboardServiceDirectoryServiceTags });

  useEffect(() => {
    if (id) {
      dispatch(requestGetServiceTagByIdEffect({ id }));
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const isPending = state === PENDING;
  const isNotFound = state === ERROR && serviceTag?.meta?.status === 404;
  const onSubmit = useCallback(
    (values) => {
      if (id) {
        dispatch(
          updateServiceTagEffect({ values, id }, (err) => {
            if (!err) {
              history.push(routes.dashboardServiceDirectoryServiceTags);
            }
          }),
        );
      } else {
        dispatch(
          createServiceTagEffect(values, (err) => {
            if (!err) {
              history.push(routes.dashboardServiceDirectoryServiceTags);
            }
          }),
        );
      }
    },
    [dispatch, id, history],
  );

  const initServiceTagData = (serviceTag) => {
    if (!serviceTag?.data) return;
    return {
      ...serviceTag.data,
    };
  };

  const formik = useFormik({
    initialValues: id
      ? initServiceTagData(serviceTag) || ServiceTagForm.initialValues
      : ServiceTagForm.initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit,
  });

  const onTypeChange = useCallback((e, { value }) => formik.setFieldValue('Type', value), [formik]);

  return (
    <>
      {isNotFound ? (
        <PageNotFound btnLabel="Back to Services" onClick={onBack} />
      ) : (
        <Wrapper isPending={isPending}>
          <form testid="serviceTag_edit" onSubmit={formik.handleSubmit}>
            <TitleWithButtons className={styles.title} title="Service">
              {formik.dirty ? (
                <Button
                  testid="save_button"
                  type="submit"
                  className={styles.saveBtn}
                  title="Save"
                  isPending={isPending}
                />
              ) : (
                <Button
                  testid="save_button"
                  type="button"
                  className={styles.saveBtn}
                  title="Save"
                />
              )}
            </TitleWithButtons>
            <div className={styles.wrapper}>
              <div className={styles.rightBlock}>
                <div className={styles.inputBlock}>
                  <label htmlFor="Name" className={styles.editLabel}>
                    Name
                  </label>
                  <div className={styles.rightBlock}>
                    <Input
                      id="Name"
                      className={styles.input}
                      type="text"
                      name="Name"
                      error={formik.touched.Name ? formik.errors.Title : ''}
                      value={formik.values.Name}
                      onChange={formik.handleChange}
                      disabled={isPending}
                      testid="name_input"
                    />
                  </div>
                </div>
                <div className={styles.inputBlock}>
                  <label htmlFor="Type" className={styles.editLabel}>
                    Type
                  </label>
                  <div className={styles.rightBlock}>
                    <Select
                      id="Type"
                      name="Type"
                      options={typesOptions}
                      value={formik.values.Type}
                      placeholder="Type"
                      onSelect={onTypeChange}
                      disabled={isPending}
                      className={{ wrapper: styles.selectWrapper }}
                      error={formik.touched.Type ? formik.errors.Type : ''}
                      testid="type_select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Wrapper>
      )}
    </>
  );
};

ServiceTagForm.initialValues = {
  Name: '',
  Type: '',
};

export default ServiceTagForm;
