import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components-antd';

import styles from './styles.module.scss';

const Header = ({ title, onButtonClick }) => (
  <div testid="header" className={styles.header}>
    <div className={styles.headerLeft}>
      <Button variant={'go-back'} onClick={() => onButtonClick()} className={styles.goBackButton} />
    </div>
    <div>{title}</div>
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
};

Header.defaultProps = {
  title: '',
  onButtonClick: null,
};

export default Header;
