import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, 'Password must have at least 1 number')
    .matches(/[A-Z]/, 'Password must have at least 1 uppercase letter')
    .matches(/[a-z]/, 'Password must have at least 1 lowercase letter')
    .required('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});
