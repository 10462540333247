import { useState, ReactNode, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export interface DraggableCombineMenuListItem {
  id: number;
  sequence: number;
}

interface DraggableCombineMenuListProps {
  renderFunction: (item, isDraggingOver, handleDecouple) => ReactNode;
  onItemUpdate: (items: DraggableCombineMenuListItem[]) => void;
  initialItems: DraggableCombineMenuListItem[];
  isDisabled?: boolean;
  isCombinedEnabled?: boolean;
  droppableClassName?: string;
  draggableClassName?: string;
}

export const DraggableCombineMenuList = ({
  renderFunction,
  onItemUpdate,
  initialItems = [],
  isDisabled = false,
  isCombinedEnabled = true,
  droppableClassName = '',
  draggableClassName = '',
}: DraggableCombineMenuListProps) => {
  const [items, setItems] = useState<DraggableCombineMenuListItem[]>([]);
  const combinedItems: any = [];

  useEffect(() => {
    if (initialItems?.length) {
      setItems(initialItems);
    }
  }, [initialItems]);

  const [dragOverId, setDragOverId] = useState('');

  items.forEach((item) => {
    const index = item.sequence - 1;
    if (!combinedItems[index]) {
      combinedItems[index] = [item];
    } else {
      combinedItems[index].push(item);
    }
  });

  const handleDragEnd = (data, uncouple) => {
    setDragOverId('');
    let updatedCombined = [...combinedItems];

    const destinationItemId = Number(data?.combine?.draggableId);

    let isCombine = !Number.isNaN(destinationItemId);

    const source = data?.source?.index || 0;

    const isExistingCombine = !!(updatedCombined[source]?.length > 1);

    let destination = isCombine
      ? updatedCombined.findIndex((itemData) =>
          itemData?.some((item) => item?.id === destinationItemId),
        )
      : data.destination?.index >= 0
      ? data.destination?.index
      : isExistingCombine
      ? -1
      : 0;

    if (destination < 0) {
      return;
    }

    const itemId = Number(data?.draggableId);

    let lastItem = source < destination;

    if (source === destination) {
      if (isExistingCombine && uncouple === true) {
        destination = source + 1;
        isCombine = false;
        lastItem = false;
      } else {
        return;
      }
    }
    const itemToMove = items.find((item) => item?.id === itemId);

    if (isExistingCombine) {
      updatedCombined[source] = updatedCombined[source].filter((item) => item?.id !== itemId);
    } else {
      updatedCombined[source] = undefined;
    }

    if (isCombine) {
      if (updatedCombined[destination]) {
        updatedCombined[destination].push(itemToMove);
      } else {
        updatedCombined[destination] = [itemToMove];
      }
    } else {
      const newIndex = lastItem ? destination + 1 : destination;
      updatedCombined.splice(newIndex, 0, [itemToMove]);
    }
    updatedCombined = updatedCombined.filter((item) => item);

    const updatedItems = [
      ...items.map((item) => {
        let newSequence = 1;

        updatedCombined.forEach((itemData, index) => {
          if (itemData?.some((orderItem) => orderItem?.id === item?.id)) {
            newSequence = index + 1;
          }
        });

        return { ...item, sequence: newSequence };
      }),
    ];
    if (onItemUpdate) {
      onItemUpdate(updatedItems);
    } else {
      setItems(updatedItems);
    }
  };

  const handleDragUpdate = (data) => {
    setDragOverId(data?.combine?.draggableId ?? '');
  };

  const handleDecouple = (combinedItemData, item, index) => {
    if (combinedItemData?.length > 1) {
      handleDragEnd(
        {
          source: {
            index,
          },
          destination: {
            index: index,
          },
          draggableId: String(item.id),
        },
        true,
      );
    }
  };

  const dragOverItemSequence =
    dragOverId && items.find((signer) => String(signer?.id) === dragOverId)?.sequence;

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
      <Droppable droppableId="droppable" isCombineEnabled={isCombinedEnabled}>
        {(droppableProvided) => (
          <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            className={droppableClassName || ''}
          >
            {combinedItems.map((combinedItemData, index) =>
              combinedItemData?.map(
                (item) =>
                  item && (
                    <Draggable
                      key={String(item.id)}
                      draggableId={String(item.id)}
                      index={index}
                      isDragDisabled={isDisabled}
                    >
                      {(draggableProvided) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          className={draggableClassName || ''}
                        >
                          {item &&
                            renderFunction(item, dragOverItemSequence === item.sequence, () =>
                              handleDecouple(combinedItemData, item, index),
                            )}
                        </div>
                      )}
                    </Draggable>
                  ),
              ),
            )}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
