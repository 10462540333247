import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { openMessageAttachmentsDrawerAction } from 'store/actions/drawers/messageAttachments';
import { openMessagesDrawerAction } from 'store/actions/drawers/messages';
import { link } from 'settings/navigation/link';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const PropertyCommentsList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onPropertyClick = (id) => {
    dispatch(openMessageAttachmentsDrawerAction({ open: false, params: {} }));
    dispatch(openMessagesDrawerAction(false));

    history.push(link.toFeedListingDetails(id));
  };

  return props.propertyComments.map(
    ({ PropertyName, PropertyId, SendDate, PropertyComment, messageId }) => (
      <div
        key={messageId}
        className={classNames(styles.item)}
        onClick={() => {}}
        testid="property_comment_item"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.propertyIcon}
        >
          <rect width="40" height="40" rx="8" fill="#F4F5F6" />
          <path
            d="M24 31V9H9V31H24ZM24 31H31.9976V16L23.9976 14"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 31V26H19V31"
            stroke="#515151"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13 13.5C13 13.2239 13.2239 13 13.5 13H14.5C14.7761 13 15 13.2239 15 13.5V14.5C15 14.7761 14.7761 15 14.5 15H13.5C13.2239 15 13 14.7761 13 14.5V13.5Z"
            fill="#515151"
          />
          <path
            d="M13 17.5C13 17.2239 13.2239 17 13.5 17H14.5C14.7761 17 15 17.2239 15 17.5V18.5C15 18.7761 14.7761 19 14.5 19H13.5C13.2239 19 13 18.7761 13 18.5V17.5Z"
            fill="#515151"
          />
          <path
            d="M13 21.5C13 21.2239 13.2239 21 13.5 21H14.5C14.7761 21 15 21.2239 15 21.5V22.5C15 22.7761 14.7761 23 14.5 23H13.5C13.2239 23 13 22.7761 13 22.5V21.5Z"
            fill="#515151"
          />
          <path
            d="M18 13.5C18 13.2239 18.2239 13 18.5 13H19.5C19.7761 13 20 13.2239 20 13.5V14.5C20 14.7761 19.7761 15 19.5 15H18.5C18.2239 15 18 14.7761 18 14.5V13.5Z"
            fill="#515151"
          />
          <path
            d="M18 17.5C18 17.2239 18.2239 17 18.5 17H19.5C19.7761 17 20 17.2239 20 17.5V18.5C20 18.7761 19.7761 19 19.5 19H18.5C18.2239 19 18 18.7761 18 18.5V17.5Z"
            fill="#515151"
          />
          <path
            d="M18 21.5C18 21.2239 18.2239 21 18.5 21H19.5C19.7761 21 20 21.2239 20 21.5V22.5C20 22.7761 19.7761 23 19.5 23H18.5C18.2239 23 18 22.7761 18 22.5V21.5Z"
            fill="#515151"
          />
          <path
            d="M27 18.5C27 18.2239 27.2239 18 27.5 18H28.5C28.7761 18 29 18.2239 29 18.5V19.5C29 19.7761 28.7761 20 28.5 20H27.5C27.2239 20 27 19.7761 27 19.5V18.5Z"
            fill="#515151"
          />
          <path
            d="M27 22.5C27 22.2239 27.2239 22 27.5 22H28.5C28.7761 22 29 22.2239 29 22.5V23.5C29 23.7761 28.7761 24 28.5 24H27.5C27.2239 24 27 23.7761 27 23.5V22.5Z"
            fill="#515151"
          />
          <path
            d="M27 26.5C27 26.2239 27.2239 26 27.5 26H28.5C28.7761 26 29 26.2239 29 26.5V27.5C29 27.7761 28.7761 28 28.5 28H27.5C27.2239 28 27 27.7761 27 27.5V26.5Z"
            fill="#515151"
          />
        </svg>

        <div onClick={() => onPropertyClick(PropertyId)} className={styles.itemContent}>
          <div className={styles.itemContentTitle}>
            <div className={styles.itemContentTitleName}>
              <span testid="property_name">{PropertyName}</span>
            </div>
            <div testid="send_date" className={styles.itemContentTitleTime}>
              {SendDate}
            </div>
          </div>
          <div testid="property_comment" className={styles.itemContentText}>
            {PropertyComment}
          </div>
        </div>
      </div>
    ),
  );
};

PropertyCommentsList.propTypes = {
  propertyComments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

PropertyCommentsList.defaultProps = {
  propertyComments: [],
};

export default PropertyCommentsList;
