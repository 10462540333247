export enum Role {
  Client = 'Client',
  Agent = 'Agent',
  ThirdParty = 'ThirdParty',
  BrokerageAdmin = 'BrokerageAdmin',
  SuperUser = 'SuperUser',
}

export enum PhoneVerificationModalSteps {
  EnterPhone = 'EnterPhone',
  VerifyCode = 'VerifyCode',
  PhoneVerificationBlocked = 'PhoneVerificationBlocked',
}
