import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Realtime from './Realtime';
import DailySummary from './DailySummary';

import styles from './styles.module.scss';

const SendingTime = (props) => {
  const { className, labelClassName, groupType, type, variant, value } = props;

  const commonProps = { groupType, type, variant };

  return (
    <div className={classNames(styles.sendingTime, className)}>
      <div className={labelClassName}>Frequency</div>
      <div className={styles.values}>
        <Realtime
          {...commonProps}
          className={styles.value}
          labelTextClassName={styles.label}
          labelClassName={styles.checkboxWrapper}
          value={value?.Realtime}
        />
        <DailySummary
          {...commonProps}
          className={styles.value}
          labelTextClassName={styles.label}
          labelClassName={styles.checkboxWrapper}
          value={value?.DailySummary}
        />
      </div>
    </div>
  );
};

SendingTime.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  groupType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  value: PropTypes.shape({
    Realtime: PropTypes.bool,
    DailySummary: PropTypes.bool,
  }).isRequired,
};

SendingTime.defaultProps = {
  className: '',
  labelClassName: '',
};

export default SendingTime;
