import { useState } from 'react';
import classNames from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';
import { updateTransactionEffect } from 'store/effects/transactions';

import { Edit } from 'components/Icons';
import { PropertyType, Select } from 'components';
import { Button } from 'components-antd';

import commonStyles from './../styles.module.scss';
import { propertyTypes } from 'settings/constants/properties';

export const TransactionPropertyType = ({ isDisableField, setEditDetailId }) => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [isEdit, setIsEdit] = useState(false);
  const [hover, setHover] = useState(false);
  const [propertyType, setPropertyType] = useState(transaction?.PropertyType);
  const editKey = 'propertyType';

  const onEdit = () => {
    if (!fullAccess || isDisableField(editKey)) return null;
    setIsEdit(true);
    setEditDetailId(editKey);
  };

  const updatePropertyType = () => {
    setIsPending(true);
    dispatch(
      updateTransactionEffect(
        {
          id: transaction?.Id,
          PropertyType: propertyType,
        },
        {},
        (err) => {
          if (!err) {
            setIsEdit(false);
          }
          setIsPending(false);
          setEditDetailId(undefined);
        },
      ),
    );
  };

  const onCancel = () => {
    setIsEdit(false);
    setEditDetailId(undefined);
    setPropertyType(transaction?.PropertyType);
  };

  return (
    <>
      {!isEdit ? (
        <div
          className={classNames(commonStyles.cardWrapper, {
            [commonStyles.disabledSection]: !!isDisableField(editKey),
          })}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={onEdit}
        >
          <div className={commonStyles.fieldContainer}>
            <p className={commonStyles.label}>Property Type</p>
            <span className={commonStyles.value}>{propertyTypes[propertyType] ?? ''}</span>
          </div>
          {fullAccess && !isDisableField(editKey) && (
            <Edit
              className={classNames(commonStyles.editIcon, { [commonStyles.hideIcon]: !hover })}
              stroke="#262626"
              strokeWidth="1.5"
            />
          )}
        </div>
      ) : (
        <div className={commonStyles.editWrapper}>
          <PropertyType
            className={commonStyles.dropdownWrapper}
            multiple={false}
            onChange={(value) => setPropertyType(value)}
            value={propertyType}
            isTransactionCreation={true}
            type="dropdown"
            label={'Property Type'}
          />
          <div className={commonStyles.buttons}>
            <Button
              variant="secondary"
              size="large"
              disabled={transaction?.PropertyType === propertyType}
              onClick={updatePropertyType}
              loading={isPending}
            >
              Update
            </Button>
            <Button variant="hollow-bordered" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
