import { YesNoOptions } from 'types/inviteClient';
import * as Yup from 'yup';

export const fileSchema = Yup.object().shape({
  contentType: Yup.string(),
  data: Yup.string(),
  filename: Yup.string(),
  size: Yup.number(),
});

export const validationSchema = Yup.object().shape({
  isPreApproved: Yup.string()
    .nullable()
    .oneOf([...Object.values(YesNoOptions), null]),
  isManualDataInput: Yup.boolean(),
  Amount: Yup.string().when(['isPreApproved', 'isManualDataInput'], {
    is: (isPreApproved, isManualDataInput) =>
      isPreApproved === YesNoOptions.Yes && isManualDataInput,
    then: Yup.string().required(),
  }),
  ApprovedBy: Yup.string(),
  ApprovalDate: Yup.array(Yup.date()),
  ExpirationDate: Yup.array(Yup.date()),
  PreApprovalDocuments: Yup.array(fileSchema).when(['isPreApproved', 'isManualDataInput'], {
    is: (isPreApproved, isManualDataInput) =>
      isPreApproved === YesNoOptions.Yes && !isManualDataInput,
    then: Yup.array(fileSchema).min(1).required(),
  }),
});

export interface PreApprovalValues {
  isPreApproved: YesNoOptions | null;
  isManualDataInput: boolean;
  Amount: string;
  ApprovedBy: string;
  ApprovalDate: Date[];
  ExpirationDate: Date[];
  PreApprovalDocuments: Yup.InferType<typeof fileSchema>[];
}
