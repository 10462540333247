import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import FilePreview from 'react-file-preview-latest';
import { Title } from 'components-antd';

import styles from './styles.module.scss';

export const FilePreviewer = ({ fileURL, fileName, setFile }) => {
  const [isOpen, setFilePreviewerVisibility] = useState(false);

  useEffect(() => {
    if (fileURL) {
      setFilePreviewerVisibility(true);
    }
  }, [fileURL]);

  const onError = (err) => console.log('Error:', err);
  return (
    <Modal
      open={isOpen}
      width={700}
      footer={null}
      onCancel={() => {
        setFile(null);
        setFilePreviewerVisibility(false);
      }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.filePreviewer}>
        <Title level={4}>{fileName}</Title>
        <FilePreview style={{ width: '100%' }} type={'url'} url={fileURL} onError={onError} />
      </div>
    </Modal>
  );
};
