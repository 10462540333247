/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Header from './components/Header';
import Images from './Images';
import Addresses from './Addresses';
import Prices from './Prices';
import Beds from './Beds';
import Baths from './Baths';
import SquareFeets from './SquareFeets';
import HoaFees from './HoaFees';
import Amenities from './Amenities';
import Commutes from './Commutes';
import Neighborhoods from './Neighborhoods';

import styles from './styles.module.scss';

const CompareContent = (props) => {
  const { className, compares, isPending } = props;

  return (
    <div className={classNames(styles.compareContent, className)}>
      <Header className={styles.header} />
      <div className={styles.inner}>
        <Images images={compares.photos} />
        <Addresses addresses={compares.addresses} />
        <Prices prices={compares.prices} />
        <Neighborhoods neighborhoods={compares.neighborhoods} />
        <Beds beds={compares.beds} />
        <Baths baths={compares.baths} />
        <SquareFeets squareFeets={compares.squareFeets} />
        <HoaFees isPending={isPending} hoaFees={compares.hoaFees} />
        <Commutes isPending={isPending} commutes={compares.commutes} />
        <Amenities isPending={isPending} amenities={compares.amenities} />
      </div>
    </div>
  );
};

CompareContent.propTypes = {
  className: PropTypes.string,
  compares: PropTypes.shape({
    photos: PropTypes.arrayOf(PropTypes.string),
    addresses: PropTypes.arrayOf(PropTypes.shape({})),
    prices: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    beds: PropTypes.arrayOf(PropTypes.number),
    baths: PropTypes.arrayOf(PropTypes.number),
    squareFeets: PropTypes.arrayOf(PropTypes.number),
    hoaFees: PropTypes.arrayOf(PropTypes.string),
    amenities: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    commutes: PropTypes.shape({}),
    neighborhoods: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  isPending: PropTypes.bool,
};

CompareContent.defaultProps = {
  className: '',
  isPending: false,
};

export default CompareContent;
