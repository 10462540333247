import React from 'react';

import { Avatar, FormattedPhone } from 'components';
import { getHrefLink } from 'helpers';
import { getPartnersSortSelector } from 'store/selectors/adminPanel';
import { sortPartnersEffect } from 'store/effects/adminPanel';
import Sort from 'pages/Dashboard/components/Sort';

import { getLogoPlaceholder } from './helpers';
import styles from './styles.module.scss';

export const gridColumns = [
  {
    title: 'Logo',
    propKey: 'LogoUrl',
    render: (logo, { BusinessName }) => (
      <Avatar
        src={logo}
        placeholder={getLogoPlaceholder(BusinessName)}
        className={styles.partnerLogoWrapper}
        avatarClassName={styles.partnerLogo}
      />
    ),
  },
  {
    title: 'Business Name',
    propKey: 'BusinessName',
    render: (name, { AdditionalBusinessName }) =>
      `${name} ${AdditionalBusinessName ? `(${AdditionalBusinessName})` : ''}`,
    children: (
      <Sort selector={getPartnersSortSelector} effect={sortPartnersEffect} field="BusinessName" />
    ),
  },
  {
    title: 'Email',
    propKey: 'Email',
  },
  {
    title: 'Phone',
    propKey: 'PhoneNumber',
    render: (phone) =>
      phone ? <FormattedPhone className={styles.phoneNumber}>{phone}</FormattedPhone> : null,
  },
  {
    title: 'Address',
    propKey: 'Address',
    render: (address, { SuiteUnit: unit }) => {
      const { Line1, City, State, Zip } = address || {};

      if (Line1) {
        return (
          <span className={styles.multilineText}>
            {Line1}
            {!!City && `, ${City}`}
            {!!State && `, ${State}`}
            {!!Zip && `, ${Zip}`}
            {!!unit && `, Unit ${unit}`}
          </span>
        );
      }
      return null;
    },
  },
  {
    title: 'Website',
    propKey: 'Website',
    render: (url) => (
      <div className={styles.link}>
        <a href={getHrefLink(url)} target="_blank" rel="noreferrer">
          {url}
        </a>
      </div>
    ),
  },
  {
    title: 'Recommended',
    propKey: 'IsRecommendedByMosaik',
    render: (isRecommended) => (isRecommended ? 'yes' : 'no'),
  },
];
