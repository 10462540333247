import { createSelector } from 'reselect';
import {
  getThreadsListSelector,
  getTransactionThreadsListSelector,
} from 'store/selectors/sockets/threads';
import {
  getCurrentTransactionNotesInfoSelector,
  getCurrentTransactionNotesThreadIdSelector,
  getCurrentTransactionThreadSelector,
} from 'store/selectors/sockets/transactionNotes';
import { ERROR, IDLE, PENDING } from 'settings/constants/apiState';
import { orderBy } from 'lodash-es';
import { getUserId } from '../user';
import { getTransactionSelector } from '../transaction';

const localState = ({ transactionNotes }) => transactionNotes;

export const getTransactionNotesStateSelector = createSelector(localState, (transactionNotes) => ({
  isIdle: transactionNotes?.state === IDLE,
  isPending: transactionNotes?.state === PENDING,
  isError: transactionNotes?.state === ERROR,
  isData: !!transactionNotes?.data,
  statePerTransaction: transactionNotes?.statePerTransaction,
}));

export const getTransactionNotesLastThreadIdSelector = createSelector(
  getTransactionNotesStateSelector,
  getTransactionSelector,
  ({ statePerTransaction }, { transaction: currentTransaction }) =>
    statePerTransaction?.[currentTransaction?.Id]?.lastThreadId,
);

export const getTransactionNotesSelector = createSelector(
  getCurrentTransactionNotesThreadIdSelector,
  getCurrentTransactionThreadSelector,
  getCurrentTransactionNotesInfoSelector,
  getThreadsListSelector,
  getUserId,
  (currentThreadId, currentThread, messages, threadsList, userId) => {
    const handledCurrentThread = {
      ...(currentThread || {}),
      Participants: orderBy(
        (currentThread?.Participants || []).filter((participant) => participant?.Id !== userId),
        ['FirstName'],
        ['asc'],
      ),
    };

    return {
      currentThreadId,
      currentThread: handledCurrentThread,
      messages,
      threadsList,
      userId,
    };
  },
);

export const getTransactionNotesParamsSelector = createSelector(
  localState,
  ({ isNewMessage, isAddParticipants, isViewContacts, participants }) => ({
    isNewMessage,
    isAddParticipants,
    isViewContacts,
    participants,
  }),
);
