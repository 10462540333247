import { ReactNode } from 'react';

export interface IHomePrefsListProps {
  className?: string;
}

export interface IMenuItems {
  value: string;
  label: string;
}

export interface IHomePrefsListItemProps {
  color: string;
  variant: IconsVariant;
  label: string;
  onClick: () => void;
  active: boolean;
  subtitle?: string;
}

export enum IconsVariant {
  LAYOUT = 'layout',
  PROPERTY_CONDITION = 'propertyCondition',
  OUTDOOR_SPACES = 'outdoorSpaces',
  PARKING = 'parking',
  KITCHEN_FEATURES = 'kitchenFeatures',
  FLOORING = 'flooring',
  INTERIOR_FEATURES = 'interiorFeatures',
  VIEW = 'view',
  AMENITIES = 'amenities',
  MECHANICAL = 'mechanical',
  COMMUTE_Time = 'commuteTime',
  SCHOOLS = 'schools',
  PUBLIC_TRANSIT = 'publicTransit',
  PARK_NATURE = 'parkNature',
  COFFEE_SHOPS = 'coffeeShops',
  NIGHT_LIFE = 'nightLife',
  PROXIMITY_LOCATION = 'Proximity to Important Locations',
  WALK = 'Walkability',
  ARTS = 'Arts & Entertainment',
  FITNESS = 'Fitness',
  RESTAURANT = 'Restaurants',
  NIGHT_LIFE = 'nightLife',
}

export interface IIconsProps {
  className?: string;
  variant: IconsVariant;
}

export interface IParkingComponent {
  color: string;
}

export interface IMechanicalComponent {
  color: string;
}

export interface ISelectComponentProps {
  color: string;
  items?: IMenuItems[];
}

export interface ICabinetComponentProps {
  color: string;
}

export interface IHomePrefsProps {
  onNext: any;
  Controls: any;
}
