import PropTypes from 'prop-types';

import { Modal } from 'components-antd';

import styles from './styles.module.scss';
import { MlsAgents } from './MlsAgent';

export const MlsAgentsModal = (props) => {
  const { isOpen, onCloseModal, agentId, agentName } = props;

  return (
    <Modal
      open={isOpen}
      width={1100}
      footer={null}
      onCancel={onCloseModal}
      maskClosable={false}
      destroyOnClose
      className={styles.taskModal}
    >
      <MlsAgents agentId={agentId} agentName={agentName} />
    </Modal>
  );
};

MlsAgentsModal.propTypes = {
  onCloseModal: PropTypes.func,
  isOpen: PropTypes.bool,
  agentId: PropTypes.number,
  agentName: PropTypes.string,
};

MlsAgentsModal.defaultProps = {
  onCloseModal: () => {},
  isOpen: false,
  agentId: 0,
  agentName: '',
};
