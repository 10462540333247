import { useState } from 'react';
import { Row, Col, DatePicker } from 'antd';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';

import { Navigation } from '../../components';
import { TimeInput } from '../../../../../../../components-antd/Input/Variants/TimeInput';
import { TimeZone } from '../../../../../../ShowingAppointment/BookingCalendar/TimeZone';
import { getMergedDateTime, getTimeZone } from '../../../../../../../helpers';

import styles from './styles.module.scss';
import { IconText } from '../components/IconText';
import { InformationText } from '../components/InformationText';

import { EditLinkDuration } from '../components';
import { Radio } from 'components-antd';
import { useScrollableRef } from '../../hooks/useScrollableRef';

export const OfferDeadline = ({
  onNext,
  stageIndex,
  lastStageIndex,
  onUpdate,
  onFinish,
  data,
  setCurrentStageIndex,
  stagesStep,
}) => {
  const { DeadlineDate, DisplayDeadlineOnPortal } = data;

  const [deadline, setDeadline]: any = useState(DeadlineDate);
  const [displayDeadline, setDisplayDeadline]: any = useState(DisplayDeadlineOnPortal);
  const { scrollableDivRef } = useScrollableRef();

  const isDisabled = () => {
    const disabled = displayDeadline && !deadline;
    return disabled;
  };

  const setDeadlineDateTime = (date) => {
    const deadlineDateTime = getMergedDateTime(deadline, cloneDeep(date));
    setDeadline(deadlineDateTime);
  };

  const onSubmit = () => {
    const body = {
      DisplayDeadlineOnPortal: displayDeadline,
      DeadlineDate: displayDeadline ? deadline : null,
    };

    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(lastStageIndex);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const disabledDate = (current) => {
    let today = moment(new Date()).format('YYYY-MM-DD');
    return current && current < moment(today, 'YYYY-MM-DD');
  };

  return (
    <div className={styles.offerLink}>
      <div className={styles.contentWrap}>
        <EditLinkDuration
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.LinkDuration)}
        />

        <IconText
          variant={'deadline-bg'}
          text={'Is there a deadline to submit offers?'}
          className={styles.iconText}
        />

        <Row className={styles.content}>
          <Row className={styles.radioButtons}>
            <Col span={24} className={styles.container}>
              <Radio.Group
                onChange={(e) => setDisplayDeadline(e.target.value)}
                value={displayDeadline}
              >
                <Radio value={true}>Yes</Radio>
                {displayDeadline ? (
                  <div className={styles.deadlineDate}>
                    <div>
                      <DatePicker
                        className={styles.picker}
                        format={'MM/DD/YYYY'}
                        placeholder={'Deadline'}
                        onChange={(e: any) => {
                          setDeadline(e);
                        }}
                        defaultValue={deadline && moment(new Date(deadline))}
                        disabledDate={disabledDate}
                        getPopupContainer={(trigger) => {
                          return trigger;
                        }}
                      />
                      <span className={styles.static}>at</span>
                      <TimeInput
                        key={deadline || ''}
                        value={deadline || null}
                        onChange={(e: any) => {
                          setDeadlineDateTime(e);
                        }}
                        className={styles.inputTime}
                        wrapperClassName={styles.timeInput}
                        selectClassName={styles.timeSelect}
                        popupClassName={styles.timeDropdown}
                        getPopupContainer={(node) => node}
                        disabled={!deadline}
                      />
                    </div>
                    <TimeZone className={styles.offerTimeZone} timeZone={getTimeZone()} />
                  </div>
                ) : null}

                <Radio value={false} className={styles.notRadio}>
                  No
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Row>

        <div ref={scrollableDivRef}>
          <InformationText
            text={`This deadline will be displayed on the offer portal for informational purposes. 
              The portal and associated link will remain active until you deactivate the link.`}
          />
        </div>
      </div>

      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onFinish={onFinish}
        disabled={isDisabled()}
      />
    </div>
  );
};
