import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TransactionPropertyPlaceholder from 'images/property-image-placeholder-dark.png';

import { Row, Col } from 'antd';
import NumberFormat from 'react-number-format';

import { getStatusValue } from 'settings/constants/transaction';
import { useSelector } from 'react-redux';
import { getTransactionSelector, getKeyDatesSelector } from 'store/selectors/transaction';

import styles from './styles.module.scss';
import { NamesContainer } from 'pages/Workshop/Transactions/components/NamesContainer';

const DetailField = ({ label, value }) => (
  <>
    <Col lg={8} className={styles.label}>
      {label}:
    </Col>
    <Col lg={16} className={styles.value}>
      {value}
    </Col>
  </>
);

const Property = (props) => {
  const { className } = props;
  const { address, isPending, transaction } = useSelector(getTransactionSelector);
  const keyDates = useSelector(getKeyDatesSelector);
  const { Zip, Line1, Line2, State, City } = address || {};
  const priceValue = useMemo(
    () => keyDates?.ClosePrice || keyDates?.UnderContractPrice || keyDates?.ListingPrice,
    [keyDates],
  );

  return (
    <>
      <div className={styles.propertyContainer}>
        <div className={classNames(styles.property, className)}>
          <div>
            <img className={styles.photo} src={TransactionPropertyPlaceholder} alt="" />
          </div>
          {!isPending && (
            <div>
              <div testid="address" className={styles.address}>
                <div>{Zip ? `${Line1}${Line2 ? ',' : ''}` : ''}</div>
                <div className={styles.secondLabel}>
                  {City || ''}, {State || ''} {Zip || ''}
                </div>
              </div>
            </div>
          )}
        </div>
        {!isPending && (
          <Row className={styles.transactionDetails}>
            {[
              { label: 'Listing', value: getStatusValue(transaction?.Status) },
              {
                label: 'Price',
                value: (
                  <NumberFormat
                    prefix="$"
                    displayType="text"
                    thousandSeparator
                    value={priceValue}
                  />
                ),
              },
              {
                label: 'Agent(s)',
                value: <NamesContainer names={transaction?.AgentsOrOwners} maxDisplayCount={2} />,
              },
            ].map((item, idx) => (
              <DetailField key={idx} {...item} />
            ))}
          </Row>
        )}
      </div>
      <div className={styles.divider} />
    </>
  );
};

Property.propTypes = {
  className: PropTypes.string,
};

Property.defaultProps = {
  className: '',
};

export default Property;
