import { useSelector } from 'react-redux';

import { Row, Col, Button } from 'components-antd';
import { getTransactionTemplateSelector } from 'store/selectors/template';

import styles from './styles.module.scss';

export const TransactionTabsFooter = ({ isViewMode }) => {
  const { isPending } = useSelector(getTransactionTemplateSelector);

  return (
    <div className={styles.footerContainer}>
      <Row>
        <div className={styles.separator}></div>
      </Row>
      <Row justify="space-between">
        <Col>
          <Button variant="hollow-bordered" className={styles.footerButton} htmlType="reset">
            Cancel
          </Button>
        </Col>
        <Col>
          {!isViewMode ? (
            <Button
              variant="secondary"
              className={styles.footerButton}
              htmlType="submit"
              disabled={isViewMode}
              loading={isPending}
            >
              Save
            </Button>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </div>
  );
};
