import { Button } from 'components-antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setMlsAccessModalAction } from 'store/actions/mlsAccess';

import styles from './styles.module.scss';

export const NoMlsAccessAgentFiller: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const handleSelectMlsClick = () => {
    dispatch(
      setMlsAccessModalAction({
        isModalOpened: true,
        showOnboardingText: false,
      }),
    );
  };

  return (
    <div className={styles.noMlsAccessWrapper}>
      <div className={styles.noMlsText}>
        <p>
          We are currently in the process of validating your MLS membership(s) and setting up your
          connection(s). If you already submitted your MLS access requests, we are working on it and
          should have your connection up and running shortly.
        </p>
        <p>
          If you did not submit a request - you can do it now using the button below. If you have
          any questions, please contact us via chat or email{' '}
          <a href="mailto:support@mosaik.io">support@mosaik.io</a>.
        </p>
      </div>
      <Button variant="secondary" className={styles.selectButton} onClick={handleSelectMlsClick}>
        Select MLS
      </Button>
    </div>
  );
};
