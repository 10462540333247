import { useState, useEffect } from 'react';
import { Row, Col, Checkbox } from 'antd';

import { CompareOfferTooltip } from '../CompareOfferTooltip';

import styles from './styles.module.scss';
import NumberFormat from 'react-number-format';

export const CounterOffers = ({ offer, onOfferSelection, totalOffersSelected }) => {
  const [showCounterOffers, setCounterOffer] = useState(false);
  const [OfferIteration, setofferIterations] = useState(
    offer.OfferIteration.slice(1, offer.OfferIteration.length),
  );
  useEffect(() => {
    OfferIteration.forEach((element) => {
      if (!element.selected) {
        element.selected = false;
      }
      element.BuyingAgentName = offer.BuyingAgentName;
      element.Buyer = offer.Buyer;
      element.OfferCount = offer.OfferCount;
    });
  }, []);

  return (
    <div className={styles.counterOffers}>
      {showCounterOffers &&
        OfferIteration.length > 0 &&
        OfferIteration.map((counterOffer, index) => (
          <Row className={styles.DocumentDiv} key={counterOffer.UUID}>
            <Col xs={3} sm={3} md={2} lg={2} xl={2}>
              <div
                className={
                  index === 0 ? styles.offerTreeViewHeightFirstNode : styles.offerTreeViewHeight
                }
              ></div>
            </Col>
            <Col xs={18} sm={18} md={20} lg={20} xl={20}>
              <p className={styles.offereName}>{`${
                offer.OfferIteration.length > 1 && counterOffer.IterationCount > 0
                  ? `Counter-Offer#${offer.OfferCount}-${counterOffer.IterationCount}`
                  : `Offer#${offer.OfferCount}`
              }`}</p>
              <p className={styles.offerReceivedAndPrice}>
                <span>
                  Received:&nbsp;
                  {new Date(counterOffer.CreatedDate).toLocaleDateString()} - Offer Price:&nbsp;
                  <NumberFormat
                    thousandSeparator
                    displayType="text"
                    value={counterOffer.PurchasePrice || 0}
                    prefix="$"
                    renderText={(val) => <span testid="property_price">{val}</span>}
                  />
                </span>
                &nbsp;
                <CompareOfferTooltip Buyer={offer.Buyer} />
              </p>
            </Col>
            <Col xs={3} sm={3} md={2} lg={2} xl={2}>
              <div className={styles.counterOfferChecbox}>
                <Checkbox
                  checked={counterOffer.selected}
                  onChange={(e) => {
                    onOfferSelection(e, OfferIteration, setofferIterations, index);
                  }}
                  className={styles.checkbox}
                />
              </div>
            </Col>
          </Row>
        ))}
      {OfferIteration.length > 0 && (
        <a className={styles.counterOfferView} onClick={() => setCounterOffer(!showCounterOffers)}>
          {showCounterOffers ? 'Hide' : 'View All'}
        </a>
      )}
    </div>
  );
};
