import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { NoThreads } from 'components';
import { useLongPress } from 'hooks';
import { changeMessagesDrawerTypeAction } from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import ActionsList from 'components/Drawers/components/ActionsList';

import {
  getArchiveThreadOption,
  getAddParticipantThreadOption,
  getArchiveThreadsListOption,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import ThreadMessages from './ThreadMessages';
import { sendThreadOpenedEffect, sendUserOfflineEffect } from 'store/effects/sockets/threads';
import { getIsMessagesDrawerOpenSelector } from 'store/selectors/drawers/messages';

const GroupedThreadsRecipientWise = (props) => {
  const { list, openGroupLabel, groupDrawerType, archived } = props;
  const dispatch = useDispatch();
  const [actionsPosition, setActionPosition] = useState(null);
  const [actions, setActions] = useState([]);
  const isOpen = useSelector(getIsMessagesDrawerOpenSelector);

  const openHandler = useCallback(
    (user, threads) =>
      dispatch(
        changeMessagesDrawerTypeAction({
          type: Array.isArray(threads) ? DRAWER_MESSAGES_TYPES.INIT : DRAWER_MESSAGES_TYPES.CHAT,
          params: {
            threadId: Array.isArray(threads) ? undefined : threads?.Id,
            isPartnerGroup: Array.isArray(threads) ? true : false,
            threads: Array.isArray(threads) ? threads : undefined,
          },
        }),
      ),
    [dispatch, groupDrawerType, archived],
  );

  useEffect(() => {
    list.forEach(({ thread }) => {
      if (thread && thread.Id) {
        dispatch(sendThreadOpenedEffect({ threadId: thread.Id }));
      }
    });
    return () => {
      list.forEach(({ thread }) => {
        if (thread && thread.Id) {
          dispatch(sendUserOfflineEffect({ threadId: thread.Id }));
        }
      });
    };
  }, [isOpen]);

  const onLongPress = (event, [user, thread]) => {
    setActionPosition({ x: event?.clientX, y: event?.clientY });
    setActions([...getArchiveThreadOption(dispatch, thread, () => setActionPosition(null))]);
  };
  const onPress = (event, [user, thread]) => {
    openHandler(user, thread);
  };

  const longPressEvent = useLongPress(onLongPress, onPress);

  return (
    <>
      {list?.length ? (
        list.map(({ topicName, thread, unreadNumber, IsThirdPartyThread, ServiceCategory }) => (
          <ThreadMessages
            key={Array.isArray(thread) ? ServiceCategory : thread?.Id}
            unreadNumber={unreadNumber}
            thread={thread}
            topicName={topicName}
            isThirdPartyThread={IsThirdPartyThread}
            thirdPartyCategory={ServiceCategory}
            {...longPressEvent(null, thread)}
          />
        ))
      ) : (
        <NoThreads>You have no messages</NoThreads>
      )}
      <ActionsList actions={actions} position={actionsPosition} />
    </>
  );
};

GroupedThreadsRecipientWise.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openGroupLabel: PropTypes.string,
  groupDrawerType: PropTypes.string,
  archived: PropTypes.bool,
};

GroupedThreadsRecipientWise.defaultProps = {
  openGroupLabel: 'Open transaction',
  groupDrawerType: DRAWER_MESSAGES_TYPES.CHAT,
  archived: false,
};

export default GroupedThreadsRecipientWise;
