import React, { CSSProperties, useEffect, useState } from 'react';

import { FormPopoverComments } from '../FormPopoverComments';
import { selectAllOption } from 'app-constants';
import { FormCommentActionBar } from '../FormCommentActionBar';
import { SmartFormIcons, SmartFormIconVariants } from 'components/SmartForm';
import { getInitials } from 'utils';
import {
  Avatar,
  AvatarGroup,
  Popover,
  Tooltip,
  UserOutlinedIcon,
  InitialsWithIconAvatar,
} from 'components-antd';
import {
  AddFormCommentType,
  CommentUsersType,
  CustomOptions,
  EditFormCommentType,
  FormCommentThread,
  FieldInfoType,
} from 'types';

import styles from './styles.module.scss';

interface FormCommentPopoverProps {
  isNewThread: boolean;
  zIndex?: number;
  tooltipStyles?: CSSProperties;
  commentUsers?: CommentUsersType;
  fieldInfo?: FieldInfoType;
  threads?: FormCommentThread[];
  handleGetFormComments: () => void;
  handleAddFormComment: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment?: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment?: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
}

export const FormCommentPopover = ({
  isNewThread,
  zIndex,
  tooltipStyles,
  commentUsers = {},
  fieldInfo,
  threads = [],
  handleGetFormComments,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
}: FormCommentPopoverProps) => {
  const [activeKey, setActiveKey] = useState<string>();
  const [isSending, setSending] = useState<boolean>(false);

  const [permissionOptions, setPermissionOptions] = useState<CustomOptions>([]);
  const [notifyOptions, setNotifyOptions] = useState<CustomOptions>([]);
  const [permittedUsers, setPermittedUsers] = useState<string[]>([selectAllOption.value]);
  const [notifiedUsers, setNotifiedUsers] = useState<string[]>();

  useEffect(() => {
    setPermissionOptions(
      Object.entries(commentUsers)
        .filter(([_key, user]) => !user.self)
        .map(([_key, user]) => ({
          label: user.name || user.email,
          value: user.email,
          avatar: user.avatar,
          role: user.role,
        })),
    );
  }, [commentUsers]);

  useEffect(() => {
    if (permissionOptions?.length) {
      if (permittedUsers?.includes(selectAllOption.value)) {
        setNotifyOptions(permissionOptions);
      } else {
        setNotifyOptions(
          permissionOptions.filter((permissionOption) =>
            permittedUsers?.includes(permissionOption.value),
          ),
        );
      }
      if (permittedUsers?.length && !permittedUsers?.includes(selectAllOption.value)) {
        setNotifiedUsers(
          notifiedUsers?.filter((notifiedUser) => permittedUsers?.includes(notifiedUser)),
        );
      }
    }
  }, [permittedUsers, permissionOptions]);

  const scrollRef = React.useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef?.current?.scrollIntoView();
    }
  }, [scrollRef.current]);

  const prepareUniqueThreadAvatar = () => {
    const uniqueThreads: any = {};

    threads.forEach((item) => {
      if (!uniqueThreads[item.CreatorEmail]) {
        uniqueThreads[item.CreatorEmail] = item;
      }
    });

    return Object.values(uniqueThreads) as FormCommentThread[];
  };

  const uniqueThreads = prepareUniqueThreadAvatar();

  const getSmartFormIcon = (lengthOfThreads, currentIndex) => {
    if (lengthOfThreads > 2 && currentIndex === 2)
      return (
        <div className={styles.commentIcon}>
          <SmartFormIcons variant={SmartFormIconVariants.CommentRed} />
        </div>
      );
    else if (currentIndex === lengthOfThreads - 1) {
      return (
        <div className={styles.commentIcon}>
          <SmartFormIcons variant={SmartFormIconVariants.CommentRed} />
        </div>
      );
    }
  };

  const getAvatar = (thread, name, i) => (
    <Tooltip
      key={`avatar-${thread.Id}`}
      title={name || thread.CreatorEmail}
      overlayClassName={styles.avatarNameTooltip}
    >
      <Avatar
        size={30}
        icon={!name ? <UserOutlinedIcon /> : undefined}
        className={styles.avatar}
        src={commentUsers[thread.CreatorId]?.avatar}
      >
        {getInitials(name ?? '')}
      </Avatar>
      {getSmartFormIcon(uniqueThreads.length, i)}
    </Tooltip>
  );

  return isNewThread ? (
    <FormCommentActionBar
      isNewThread={true}
      isSending={isSending}
      setSending={setSending}
      commentUsers={commentUsers}
      notifyOptions={notifyOptions}
      permissionOptions={permissionOptions}
      notifiedUsers={notifiedUsers}
      setNotifiedUsers={setNotifiedUsers}
      permittedUsers={permittedUsers}
      setPermittedUsers={setPermittedUsers}
      handleAddFormComment={handleAddFormComment}
      fieldInfo={fieldInfo}
    />
  ) : threads.length ? (
    <Popover
      key={`${threads[0].Id}-pdfField-thread`}
      onOpenChange={(open: boolean) => {
        if (open) {
          handleGetFormComments();
        } else {
          setActiveKey('');
        }
      }}
      destroyTooltipOnHide
      placement="bottom"
      overlayClassName={styles.popoverContent}
      zIndex={zIndex}
      content={
        <FormPopoverComments
          threads={threads}
          commentUsers={commentUsers}
          notifyOptions={notifyOptions}
          permissionOptions={permissionOptions}
          permittedUsers={permittedUsers}
          setPermittedUsers={setPermittedUsers}
          notifiedUsers={notifiedUsers}
          setNotifiedUsers={setNotifiedUsers}
          isSending={isSending}
          setSending={setSending}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          handleAddFormComment={handleAddFormComment}
          handleEditFormComment={handleEditFormComment}
          handleDeleteFormComment={handleDeleteFormComment}
          handleToggleResolveThread={handleToggleResolveThread}
        />
      }
      getPopupContainer={(e) => e}
      trigger="click"
    >
      <div style={tooltipStyles} className={styles.commentIndicator}>
        <AvatarGroup maxCount={3}>
          {uniqueThreads.map((thread, i) => {
            const name = thread.CreatorId
              ? commentUsers[thread.CreatorId]?.name
              : commentUsers[thread.CreatorEmail]?.name;
            return getAvatar(thread, name, i);
          })}
        </AvatarGroup>
      </div>
    </Popover>
  ) : (
    <></>
  );
};
