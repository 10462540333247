import {
  getNotificationTemplateById,
  getNotificationTemplates,
  putNotificationTemplate,
} from 'api/admin';
import {
  requestGetNotificationTemplateDashboardAction,
  requestGetNotificationTemplatesDashboardAction,
  requestPutNotificationTemplateDashboardAction,
} from 'store/actions/adminPanel/notificationTemplates';
import { getItemById } from 'store/effects/adminPanel/helpers';
import Api from 'store/effects/core/api';

export const getNotificationTemplatesEffect = (cfg = {}, options = {}, cb) => {
  const requestParams = {
    action: requestGetNotificationTemplatesDashboardAction,
    method: getNotificationTemplates,
  };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetNotificationTemplateByIdEffect = (cfg, cb) =>
  getItemById(
    requestGetNotificationTemplateDashboardAction,
    getNotificationTemplateById,
    cfg,
    {},
    cb,
  );

export const updateNotificationTemplateEffect = ({ values, id }, cb) => {
  const sendRequest = Api.execBase({
    action: requestPutNotificationTemplateDashboardAction,
    method: putNotificationTemplate,
  });
  const sendValues = {
    Template: values.Template,
  };

  return sendRequest(sendValues, { id }, (err, response, dispatch) => {
    if (!err) {
      dispatch(requestGetNotificationTemplateByIdEffect({ id }));
      dispatch(getNotificationTemplatesEffect());
    }
    cb(err, response, dispatch);
  });
};
