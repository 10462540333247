import { useEffect, useMemo } from 'react';
import { Button } from 'components-antd';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { WorkshopType } from 'app-constants';

interface CloseTransactionEndProps {
  onClose: () => void;
  onSubmit: () => void;
}

export const CloseTransactionEnd = ({ onClose, onSubmit }: CloseTransactionEndProps) => {
  const isProject = useIsProjectRoute();

  useEffect(() => {
    onSubmit();
  }, []);

  const closeType = useMemo(() => {
    if (isProject) return WorkshopType.Project.toLowerCase();
    return WorkshopType.Transaction.toLowerCase();
  }, [isProject]);

  return (
    <div className={styles.closeTransactionEnd}>
      <div className={styles.closingHeader}>This {closeType} is closed.</div>
      <div className={styles.closingSubHeader}>
        You can revisit the {closeType} room anytime via your archive.
      </div>
      <Button className={styles.submitBtn} variant="secondary" onClick={onClose}>
        Done
      </Button>
    </div>
  );
};
