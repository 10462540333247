import moment from 'moment';

export const getCommentDate = (createdDate) => {
  const isToday = moment(createdDate).format('MM/DD/YYYY') === moment().format('MM/DD/YYYY');
  const isYesterday =
    moment(createdDate).format('MM/DD/YYYY') === moment().subtract(1, 'day').format('MM/DD/YYYY');

  if (isToday) {
    return moment(createdDate).format('hh:mm A');
  } else if (isYesterday) {
    return `Yesterday at ${moment(createdDate).format('hh:mm A')}`;
  } else {
    return moment(createdDate).format('MM/DD/YYYY [at] hh:mm A');
  }
};
