import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { prefsIds } from 'settings/constants/preferences';
import { Edit } from 'components/Icons';

import Label from '../Label';
import Icon from '../../Icons';
import { Row, Col } from 'antd';
import styles from './styles.module.scss';

const Option = ({
  actives,
  onClick,
  onEdit,
  option,
  layoutValues,
  kitchenFeatureValues,
  flooringValues,
  className,
  testid,
}) => {
  const isEditable = (id) => {
    const editableOptions = [prefsIds.layoutPrefs, prefsIds.kitchenFeatures, prefsIds.flooring]; // , prefsIds.style Styles selection is disabled temporarily
    return editableOptions.includes(id) && actives.includes(id);
  };

  const isActive = (id) => {
    if (
      (id === prefsIds.layoutPrefs && !layoutValues?.length) ||
      (id === prefsIds.kitchenFeatures && !kitchenFeatureValues?.length) ||
      (id === prefsIds.flooring && !flooringValues?.length)
    ) {
      return false;
    }

    return actives.includes(id);
  };

  return (
    <Col xs={12} md={24} className={classNames(styles.optionHolder, className)}>
      <Row gutter={[20, 20]} className={styles.stagePrefsRow}>
        {option.map(({ id, label }) => (
          <Col xs={24} sm={12} md={8}>
            <div
              key={id}
              onClick={isEditable(id) ? (e) => onEdit(e, id) : () => onClick(id)}
              className={classNames(styles.option, { [styles.active]: isActive(id) })}
              testid={testid}
            >
              <div className={styles.optionInner}>
                {isEditable(id) && isActive(id) && (
                  <Edit onClick={(e) => onEdit(e, id)} className={styles.edit} />
                )}
                <Icon className={styles.icon} variant={id} color={isActive(id) ? '#FF576D' : ''} />
                <Label
                  id={id}
                  label={label}
                  actives={actives}
                  className={classNames(styles.label, isActive(id) ? styles.labelActive : '')}
                />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

Option.propTypes = {
  className: PropTypes.string,
  actives: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  option: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  layoutValues: PropTypes.arrayOf(PropTypes.string),
  kitchenFeatureValues: PropTypes.arrayOf(PropTypes.string),
  flooringValues: PropTypes.arrayOf(PropTypes.string),
  testid: PropTypes.string,
};

Option.defaultProps = {
  className: '',
  actives: [],
  testid: undefined,
};

export default Option;
