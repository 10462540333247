import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { getUnreadThreadsCount } from 'store/selectors/sockets/threads';

import { Card } from '../Card';
import SocketConnectionWrapper from 'components/Drawers/components/SocketConnectionWrapper';
import MessagesDrawerUnreadContent from 'components/Drawers/MessagesDrawer/components/Content/UnreadContent';

import styles from './styles.module.scss';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';

export const UnreadMessages = () => {
  const dispatch = useDispatch();

  const unreadThreadsCount = useSelector(getUnreadThreadsCount);
  const handleUnreadMessages = () => {
    unreadThreadsCount > 0 && dispatch(openMessagesDrawerAction(true));
    dispatch(
      changeMessagesDrawerTypeAction({
        type: DRAWER_MESSAGES_TYPES.UNREAD,
      }),
    );
  };

  return (
    <Card
      cardTitle={
        <Fragment>
          Unread Messages <span>({unreadThreadsCount})</span>
        </Fragment>
      }
      onClickHandler={handleUnreadMessages}
      isClickable={unreadThreadsCount > 0 ? true : false}
      showFooter={true}
      showHeader={true}
    >
      <SocketConnectionWrapper>
        <MessagesDrawerUnreadContent
          messageClassName={styles.widgetUnreadMessage}
          isWidget={true}
        />
      </SocketConnectionWrapper>
    </Card>
  );
};
