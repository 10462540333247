import styles from './styles.module.scss';

type EmptyKitPropsType = {
  text: string;
};

function EmptyKit(props: EmptyKitPropsType) {
  return <div className={styles.emptyKit}>{props.text}</div>;
}

export default EmptyKit;
