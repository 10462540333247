import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { lowerCase } from 'lodash-es';
import { Popover } from 'antd';

import Icon from 'pages/Properties/Feed/Icons';
import { useLocation } from 'react-router-dom';
import { LocationService } from 'services';
import { propertiesTabsIds } from 'settings/constants/properties';
import { PENDING, READY } from 'settings/constants/apiState';
import {
  getAllPropsSelector,
  getRecommendedPropsSelector,
  getActiveTabSelector,
  getFeedSortSelector,
  getSubFilterTypeSelector,
  getActivePropertiesSelector,
  getFeedDrawnPolygonSelector,
  getFeedViewportCoordinatesSelector,
  getFeedPageInfoSelector,
} from 'store/selectors/feed';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getClientFavoritesPropsEffect, sortFeedEffect } from 'store/effects';
import { useOutsideClick } from 'hooks';
import { OptionsPopup } from 'components';
import Title from './Title';
import AscDesc from './AscDesc';
import Fields from './Fields';
import styles from './styles.module.scss';
import { useNoMlsAccessHandler } from '../../../hooks/useNoMlsAccessHandler';
import {
  getAllPropsV2Effect,
  getBackOnMarketPropsV2Effect,
  getNewPropsV2Effect,
  getPriceReductionsPropsV2Effect,
  getRecommendedPropsV2Effect,
  getSavedSearchesPropsV2Effect,
  getStatusChangesPropsV2Effect,
  getSuggestionsPropsV2Effect,
  getWatchlistPropsV2Effect,
  getOpenHousesPropsV2Effect,
  getSearchInstancePropsV2Effect,
} from 'store/effects/feed/feedV2';
import { getUserRolesMapSelector } from 'store/selectors/user';

const fieldsPriority = [
  'MatchScore',
  'ClientsMatched',
  'SellingPrice',
  'DaysOnMarket',
  'NumBeds',
  'NumBathsTotal',
  'SquareFeet',
].reduce((acc, field, index) => ({ ...acc, [field]: index }), {});

const locationService = new LocationService();

const Sort = ({
  className,
  sortCases,
  alphabeticalSort,
  customleft,
  customright,
  offsetLeft,
  isFavortiesSectionVisible,
}) => {
  const dispatch = useDispatch();
  const sort = useSelector(getFeedSortSelector);
  const activeTab = useSelector(getActiveTabSelector);
  const searchContext = useSelector(getCurrentContextSelector);
  const location = useLocation();
  const allProps = useSelector(getAllPropsSelector);
  const recommendedProps = useSelector(getRecommendedPropsSelector);
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const activePropsSelector = useSelector(getActivePropertiesSelector);
  const { isClient } = useSelector(getUserRolesMapSelector);
  const shiftedToDaysOnMarket = useRef(false);

  const query = locationService.setLocation(location).getQuery();

  const [open, setOpen] = useState(false);

  const sortButtonRef = useRef();
  const popupRef = useRef();

  useOutsideClick([popupRef, sortButtonRef], () => setOpen(false));

  const onClickHandler = () => {
    setOpen(!open);
  };

  const { wrappedEffect: getAllPropsEffectWrapped } = useNoMlsAccessHandler(getAllPropsV2Effect);
  const { wrappedEffect: getNewPropsEffectWrapped } = useNoMlsAccessHandler(getNewPropsV2Effect);
  const { wrappedEffect: getSavedSearchesPropsEffectWrapped } = useNoMlsAccessHandler(
    getSavedSearchesPropsV2Effect,
  );
  const { wrappedEffect: getStatusChangesPropsEffectWrapped } = useNoMlsAccessHandler(
    getStatusChangesPropsV2Effect,
  );
  const { wrappedEffect: getRecommendedPropsEffectWrapped } = useNoMlsAccessHandler(
    getRecommendedPropsV2Effect,
  );
  const { wrappedEffect: getClientFavoritesPropsEffectWrapped } = useNoMlsAccessHandler(
    getClientFavoritesPropsEffect,
  );
  const { wrappedEffect: getClientFavoritesPropsEffectForClientIdWrapped } = useNoMlsAccessHandler(
    (cfg, options, cb) =>
      getClientFavoritesPropsEffect(cfg, { ...options, userId: query?.clientId }, cb),
  );
  const { wrappedEffect: getPriceReductionsPropsEffectWrapped } = useNoMlsAccessHandler(
    getPriceReductionsPropsV2Effect,
  );
  const { wrappedEffect: getWatchlistPropsEffectWrapped } =
    useNoMlsAccessHandler(getWatchlistPropsV2Effect);

  const { wrappedEffect: getBackOnMarketPropsV2EffectWrapped } = useNoMlsAccessHandler(
    getBackOnMarketPropsV2Effect,
  );

  const { wrappedEffect: getOpenHousesPropsV2EffectWrapped } = useNoMlsAccessHandler(
    getOpenHousesPropsV2Effect,
  );
  const { wrappedEffect: getSuggestionsPropsV2EffectWrapped } = useNoMlsAccessHandler(
    getSuggestionsPropsV2Effect,
  );
  const { wrappedEffect: getSearchInstancePropsEffectWrapped } = useNoMlsAccessHandler(
    getSearchInstancePropsV2Effect,
  );

  const getEffect = () => {
    if (query?.clientId) {
      return getClientFavoritesPropsEffectForClientIdWrapped;
    }
    if (allProps.state !== PENDING && recommendedProps.state !== PENDING) {
      switch (activeTab) {
        case propertiesTabsIds.all:
          return getAllPropsEffectWrapped;
        case propertiesTabsIds.new:
          return getNewPropsEffectWrapped;
        case propertiesTabsIds.savedSearches:
          return getSavedSearchesPropsEffectWrapped;
        case propertiesTabsIds.statusChanges:
          return getStatusChangesPropsEffectWrapped;
        case propertiesTabsIds.recommended:
          return getRecommendedPropsEffectWrapped;
        case propertiesTabsIds.clientFavorites:
          return getClientFavoritesPropsEffectWrapped;
        case propertiesTabsIds.openHouses:
          return getOpenHousesPropsV2EffectWrapped;
        case propertiesTabsIds.priceReductions:
          return getPriceReductionsPropsEffectWrapped;
        case propertiesTabsIds.watchlist:
          return getWatchlistPropsEffectWrapped;
        case propertiesTabsIds.backOnMarket:
          return getBackOnMarketPropsV2EffectWrapped;
        case propertiesTabsIds.suggestions:
          return getSuggestionsPropsV2EffectWrapped;
        default:
          return typeof activeTab === 'number' && !isNaN(activeTab)
            ? getSearchInstancePropsEffectWrapped
            : null;
      }
    }
  };

  const fetchSelectedFeed = () => {
    if (alphabeticalSort != true) {
      const effect = getEffect();

      if (effect) {
        dispatch(effect({ new: true }));
      }
    }
  };

  const onChangeSort = (orderValue) => {
    if (alphabeticalSort != true) {
      dispatch(sortFeedEffect({ order: orderValue, fields: sort?.fields }));
      fetchSelectedFeed();
    }
  };

  const onChangeFields = (fieldNames) => {
    const sortedFields = fieldNames.sort((o1, o2) => fieldsPriority[o1] - fieldsPriority[o2]);
    dispatch(sortFeedEffect({ order: sort?.order, fields: sortedFields }));
    fetchSelectedFeed();
  };

  const customSearchPolygon = useSelector(getFeedDrawnPolygonSelector);
  const viewportCoordinateSearch = useSelector(getFeedViewportCoordinatesSelector);
  const currentPageInfo = useSelector(getFeedPageInfoSelector);

  useEffect(() => {
    if (
      (((isClient && searchContext) || !isClient) &&
        (!currentPageInfo?.currentFEPageInfo?.fromListingDetailPage ||
          !activePropsSelector?.data?.length)) ||
      (((isClient && searchContext) || !isClient) && customSearchPolygon)
    ) {
      fetchSelectedFeed();
    }
  }, [isClient, searchContext, activeTab, subFilterType, customSearchPolygon]);

  useEffect(() => {
    if (
      (!currentPageInfo?.currentFEPageInfo?.fromListingDetailPage ||
        !activePropsSelector?.data?.length) &&
      viewportCoordinateSearch
    ) {
      fetchSelectedFeed();
    }
  }, [viewportCoordinateSearch]);

  useEffect(() => {
    if (
      recommendedProps.state === READY &&
      !recommendedProps.data.length &&
      sort?.fields.includes('MatchScore') &&
      !shiftedToDaysOnMarket.current
    ) {
      shiftedToDaysOnMarket.current = true;
      dispatch(sortFeedEffect({ order: 'Asc', fields: ['DaysOnMarket'] }));
      fetchSelectedFeed();
    }
  }, [recommendedProps]);

  const activeFields = useMemo(() => {
    if (sort?.fields?.length) return sort?.fields;

    return [isClient ? (isFavortiesSectionVisible ? '' : 'MatchScore') : 'DaysOnMarket'];
  }, [sort?.fields, isFavortiesSectionVisible]);

  const popoverContent = () => <span>Sort By</span>;

  return (
    <div>
      <Popover
        content={popoverContent}
        placement="top"
        overlayClassName={classNames('mosaikTooltip')}
        getPopupContainer={(triggerNode) => triggerNode}
        arrow={true}
      >
        <div
          ref={sortButtonRef}
          onClick={onClickHandler}
          className={classNames(styles.sort, className)}
        >
          <Icon
            testid="sort_icon"
            className={classNames(styles.icon, styles[lowerCase(sort?.order)])}
            variant={Icon.SORT}
          />
        </div>
      </Popover>
      <OptionsPopup
        className={styles.popup}
        ref={popupRef}
        offsetTop={5}
        parentRef={sortButtonRef}
        open={open}
        customleft={customleft}
        customright={customright}
        offsetLeft={offsetLeft}
      >
        <div className={styles.popupInner}>
          <Title>Sort by</Title>
          {!alphabeticalSort && <AscDesc onChange={onChangeSort} activeOrder={sort?.order} />}
        </div>
        <Fields onChange={onChangeFields} list={sortCases} activeFields={activeFields} />
      </OptionsPopup>
    </div>
  );
};

Sort.propTypes = {
  className: PropTypes.string,
  sortCases: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  alphabeticalSort: PropTypes.bool,
  customleft: PropTypes.number,
  customright: PropTypes.number,
  offsetLeft: PropTypes.number,
  isFavortiesSectionVisible: PropTypes.bool,
};

Sort.defaultProps = {
  isFavortiesSectionVisible: false,
  offsetLeft: 0,
  className: '',
  sortCases: [
    { label: 'Match Score', value: 'MatchScore' },
    { label: 'Price', value: 'SellingPrice' },
    { label: 'Days on Market', value: 'DaysOnMarket' },
    { label: 'Beds', value: 'NumBeds' },
    { label: 'Baths', value: 'NumBathsTotal' },
    { label: 'Square Feet', value: 'SquareFeet' },
  ],
};

export default Sort;
