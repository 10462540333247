import { Icons, ICON_VARIANT_TYPE } from '../../Icons';

import styles from './styles.module.scss';

export const LocationDetails = (props) => {
  const { PropertyTransaction } = props;
  const Property = PropertyTransaction?.Property;

  const includeZip = (placeName, zipCode) => {
    if (!placeName) {
      return 'N/A';
    }
    let parts = placeName.split(',');
    if (parts.length >= 3) {
      parts[2] = parts[2].trim() + ` ${zipCode}`;
      return parts.slice(0, -1).join(',');
    } else {
      return placeName;
    }
  };

  return (
    <div>
      <div className={styles.detailsArea}>
        <p className={styles.title}>Location</p>
        <p className={styles.subTitle}>
          {includeZip(Property?.Address?.PlaceName, Property?.Address?.Zip)}
        </p>
        <a
          className={styles.link}
          href={`http://maps.google.com/?q=${Property?.Address?.PlaceName}`}
          target="_blank"
          rel="noreferrer"
        >
          Show on Map
        </a>
      </div>
    </div>
  );
};
