import { Avatar } from 'components-antd';
import { UserOutlined } from '@ant-design/icons';
import { AgentDetailsUpdate } from 'types';

import styles from './styles.module.scss';
import { FormattedPhone } from 'components';

export const AgentDetails = (props) => {
  const { SellerAgent, AgentBrokerage } = props;

  const agentData: AgentDetailsUpdate = {
    ...SellerAgent,
    Phone: SellerAgent?.Phones?.find((item) => item.IsPrimary)?.PhoneNumber,
  };

  return (
    <div>
      <div className={styles.agentDetails}>
        <p className={styles.agentName}>{agentData?.FirstName + ' ' + agentData?.LastName}</p>
        <p className={styles.agentCompany}>{AgentBrokerage?.Name}</p>
        <div className={styles.agentPhone}>
          <FormattedPhone testid="view_phone">{agentData?.Phone}</FormattedPhone>
        </div>
        <p className={styles.agentEmail}>
          <a>{agentData?.Email}</a>
        </p>
      </div>
    </div>
  );
};
