import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { socketSetEditMessageEffect } from 'store/effects/sockets/messages';

import {
  getCurrentEditMessageSelector,
  getCurrentThreadSelector,
} from 'store/selectors/sockets/messages';
import MessagesView from './MessagesView';

const Messages = (props) => {
  const { className, list, threadId, onDelete } = props;
  const currentEditMessage = useSelector(getCurrentEditMessageSelector);
  const currentThread = useSelector(getCurrentThreadSelector);
  const dispatch = useDispatch();

  const onEditMessageHandler = useCallback(
    (messageId) => {
      const editMessage = list.find((message) => message?.Id === messageId);
      dispatch(socketSetEditMessageEffect({ editMessage }));
    },
    [dispatch, list],
  );

  return (
    <MessagesView
      {...props}
      onEditMessage={onEditMessageHandler}
      onDeleteMessage={onDelete}
      currentEditMessage={currentEditMessage}
      className={className}
      threadId={threadId}
      currentThread={currentThread}
    />
  );
};

Messages.propTypes = {
  className: PropTypes.string,
  threadId: PropTypes.number.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onDelete: PropTypes.func,
};

Messages.defaultProps = {
  className: '',
  list: null,
  onDelete: () => {},
};

export default Messages;
