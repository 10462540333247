import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons/lib/icons';
import classNames from 'classnames';
import { Button, Col, Row, Text } from 'components-antd';

import styles from './styles.module.scss';

interface PDFEditorTopBarProps {
  handleExit: () => void;
  scale: number;
  setScale: (number) => void;
  updateDocument: (bool) => void;
  disabled: boolean;
  setDisabled: (bool) => void;
}

const MAX_ZOOM_SCALE = 3;

const SCALE_STEP = 0.5;

export const PDFEditorTopBar = ({
  handleExit,
  scale,
  setScale,
  updateDocument,
  disabled,
  setDisabled,
}: PDFEditorTopBarProps) => {
  const handleButtonClick = (func, data?) => {
    setDisabled(true);
    setTimeout(() => {
      func(data);
      setDisabled(false);
    }, 0);
  };

  const updateScale = (newScale) => {
    if (newScale <= 0 || newScale > MAX_ZOOM_SCALE) {
      return;
    }
    setScale(newScale);
  };

  return (
    <Row justify="space-between" className={styles.toolBar}>
      <Col>
        <Button
          disabled={disabled}
          variant="secondary-inverted"
          onClick={() => handleButtonClick(handleExit)}
          className={styles.toolBarButton}
        >
          Exit
        </Button>
      </Col>

      <Col className={styles.zoomIcon}>
        <Text className={styles.mr5}>{100 * scale}%</Text>
        <ZoomOutOutlined className={styles.mr5} onClick={() => updateScale(scale - SCALE_STEP)} />
        <ZoomInOutlined onClick={() => updateScale(scale + SCALE_STEP)} />
      </Col>

      <Col>
        <Button
          variant="secondary-inverted"
          className={classNames(styles.mr5, styles.toolBarButton)}
          onClick={() => handleButtonClick(updateDocument, true)}
          disabled={disabled}
        >
          Download
        </Button>
        <Button
          variant="primary"
          onClick={() => handleButtonClick(updateDocument, false)}
          className={styles.toolBarButton}
          disabled={disabled}
        >
          Save
        </Button>
      </Col>
    </Row>
  );
};
