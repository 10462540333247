import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Archive = (props) => {
  const { className, onArchive } = props;

  return (
    <div
      testid="archive"
      onClick={onArchive}
      className={classNames(styles.archiveWrapper, className)}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.54415 5.36754L3.05132 12.8461C3.01733 12.948 3 13.0548 3 13.1623V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18V13.1623C21 13.0548 20.9827 12.948 20.9487 12.8461L18.4558 5.36754C18.1836 4.55086 17.4193 4 16.5585 4H7.44152C6.58066 4 5.81638 4.55086 5.54415 5.36754Z"
          stroke="#303030"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 10H8.5V10.5C8.5 12.433 10.067 14 12 14V14C13.933 14 15.5 12.433 15.5 10.5V10H19"
          stroke="#303030"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {/* {!!countThreads && <span className={styles.count}>{countThreads}</span>} */}
    </div>
  );
};

Archive.propTypes = {
  className: PropTypes.string,
  onArchive: PropTypes.func,
};

Archive.defaultProps = {
  className: '',
  onArchive: () => {},
};

export default Archive;
