import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  setTransactionMessagesParamsEffect,
  setTransactionMessagesStatePerTransactionEffect,
} from 'store/effects/transactions/messages';
import { openTransactionParticipantDrawerEffect } from 'store/effects/drawers/transactionParticipant';
import { Messages } from 'components/Icons';

import { link } from 'settings/navigation/link';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getIfExistThread } from 'helpers';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { getTransactionMessagesSelector } from 'store/selectors/transactionMessages';
import { socketsGetTransactionMessagesByThreadIdEffect } from 'store/effects/sockets/transactionMessages';
import styles from './styles.module.scss';
import { openMessagesDrawerAction } from 'store/actions/drawers/messages';
import { openMessagesDrawerEffect } from 'store/effects/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';

const NewMessage = (props) => {
  const { className, participant } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { transaction } = useSelector(getTransactionSelector);
  const { threadsList, userId } = useSelector(getTransactionMessagesSelector);

  const checkAndGetThread = () =>
    getIfExistThread(
      threadsList,
      [participant, userId],
      (thread) =>
        (thread?.EntityId === `${transaction?.Id}` &&
          thread?.Type === SOCKET_THREAD_TYPES.TRANSACTION) ||
        SOCKET_THREAD_TYPES.CHAT,
    );

  const onClick = () => {
    if (participant && transaction?.Id) {
      dispatch(openTransactionParticipantDrawerEffect({ open: false }));

      const existThread = checkAndGetThread();

      if (existThread) {
        dispatch(socketsGetTransactionMessagesByThreadIdEffect({ Id: existThread?.Id }));
        dispatch(
          openMessagesDrawerEffect({
            open: true,
            type: DRAWER_MESSAGES_TYPES.CHAT,
            params: {
              threadId: existThread?.Id,
            },
          }),
        );
      } else {
        dispatch(
          openMessagesDrawerEffect({
            open: true,
            type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
            params: {
              participants: [
                {
                  id: participant?.Id,
                  value: participant?.Id,
                  avatarUrl: participant?.AvatarUrl,
                  email: participant?.Email,
                  firstName: participant?.FirstName,
                  lastName: participant?.LastName,
                  name: `${participant.FirstName} ${participant?.LastName} / ${participant?.Email}`,
                  role: participant?.TransactionRole?.Title,
                },
              ],
            },
          }),
        );
      }
    }
  };

  return (
    <div
      onClick={onClick}
      testid="new_message"
      className={classNames(styles.newMessage, className)}
    >
      <Messages variant="hollow" className={styles.icon} />
      <div className={styles.text}>Message</div>
    </div>
  );
};

NewMessage.propTypes = {
  className: PropTypes.string,
  participant: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    TransactionRole: PropTypes.shape({
      Title: PropTypes.string,
    }),
    AvatarUrl: PropTypes.string,
    Email: PropTypes.string,
  }),
};

NewMessage.defaultProps = {
  className: '',
  participant: null,
};

export default NewMessage;
