import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get, cloneDeep } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetClientContextsAction,
  requestGetInActiveClientContextsAction,
} from 'store/actions/context';

const initialData = {
  state: IDLE,
  data: [],
  meta: {},
  inActiveContexts: {},
};

export default handleActions(
  {
    [requestGetClientContextsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestGetInActiveClientContextsAction]: (state, { payload }) => ({
      ...state,
      inActiveContexts: {
        ...state.inActiveContexts,
        state: payload.state,
        data: get(payload, ['data', 'result']),
        meta: get(payload, 'meta', initialData.meta),
      },
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
