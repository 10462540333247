import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { resetTransactionCreateEffect } from 'store/effects/transactions';
import { PreForm } from './components';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';

import styles from './styles.module.scss';

const TransactionCreate = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  const stayOnPageOrOpenEmailVerificationModal = useEmailVerificationWall(
    ActionToInterrupt.CREATE_TRANSACTION,
    () => {},
  );

  useEffect(
    () => () => {
      dispatch(resetTransactionCreateEffect());
    },
    [],
  );

  useEffect(() => {
    stayOnPageOrOpenEmailVerificationModal();
  });

  return <PreForm />;
};

TransactionCreate.propTypes = {
  className: PropTypes.string,
};

TransactionCreate.defaultProps = {
  className: '',
};

export default TransactionCreate;
