import { LocalStorage } from './localStorage';
import { SessionStorage } from './sessionStorage';

/*
 * Authentication is verified using two factors which are both generated via
 * simple hashing of a user's email address.
 *    TokenContext is generated and stored in LocalStorage along with AccessToken
 *    LoginContext is generated when a user logs in and is stored in SessionStorage
 *
 * SessionStorage persists between browser refreshed.  To confirm and continue
 * authorization, we check to confirm that LoginContext always matches TokenContext.
 * This ensures that the user who is logged into a browser session matches the user
 * that the token was generated for.
 */
export class Security {
  /*
   * This is the only function that should be used to check to see if a user is currently
   * logged in
   */
  static isLoggedIn() {
    if (
      LocalStorage.getAccessToken() &&
      SessionStorage.getLoginContext() === LocalStorage.getTokenContext()
    )
      return true;
    else return false;
  }

  /*
   * This is function determines if the browser's credentials are
   * suitable to allow the user session to be restored.  The required
   * criteria are that an Access Token Exists and a user has not yet
   * logged into this browser session (i.e. it is fresh reload) or
   * logged with matched TokenContext.
   */
  static allowRestoreUserSession() {
    if ((!SessionStorage.getLoginContext() && LocalStorage.getTokenContext()) || this.isLoggedIn())
      return true;
    else return false;
  }
}
