/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Wrapper } from 'components';
import Input from 'components/Form/Input';
import {
  getTransactionRoleDashboardSelector,
  getTransactionRoleDataDashboardSelector,
} from 'store/selectors/adminPanel';
import {
  createTransactionRoleEffect,
  getTransactionRoleDashboardEffect,
  updateTransactionRoleEffect,
} from 'store/effects/adminPanel';

import { ValidationSchema } from './validation';
import styles from './styles.module.scss';

const TransactionRoleForm = ({ id, onSubmissionComplete }) => {
  const dispatch = useDispatch();
  const category = useSelector(getTransactionRoleDataDashboardSelector);
  const { isPending, isError, meta } = useSelector(getTransactionRoleDashboardSelector);

  useEffect(() => {
    if (id) {
      dispatch(getTransactionRoleDashboardEffect({ id }));
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
  const isNotFound = isError && meta?.status === 404;

  const onSubmit = useCallback(
    (values) => {
      if (id) {
        dispatch(
          updateTransactionRoleEffect({ values, id }, (err) => {
            if (!err) {
              onSubmissionComplete();
            }
          }),
        );
      } else {
        dispatch(
          createTransactionRoleEffect(values, (err) => {
            if (!err) {
              onSubmissionComplete();
            }
          }),
        );
      }
    },
    [dispatch, id, onSubmissionComplete],
  );

  const formik = useFormik({
    initialValues: id
      ? category || TransactionRoleForm.initialValues
      : TransactionRoleForm.initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit,
  });

  return (
    <>
      {isNotFound ? (
        <div>Ups, this category is not found...</div>
      ) : (
        <Wrapper isPending={isPending}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.wrapper}>
              <div className={styles.inputBlock}>
                <label htmlFor="Title" className={styles.editLabel}>
                  Title
                </label>
                <div className={styles.rightBlock}>
                  <Input
                    id="Title"
                    className={styles.input}
                    type="text"
                    name="Title"
                    error={formik.touched.Title ? formik.errors.Title : ''}
                    value={formik.values.Title}
                    onChange={formik.handleChange}
                    disabled={isPending}
                  />
                </div>
              </div>
            </div>
            <div className={styles.buttonsWrapper}>
              {formik.dirty ? (
                <Button
                  type="submit"
                  className={styles.saveBtn}
                  title="Save"
                  isPending={isPending}
                />
              ) : (
                <Button type="button" className={styles.saveBtn} title="Save" />
              )}
            </div>
          </form>
        </Wrapper>
      )}
    </>
  );
};

TransactionRoleForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSubmissionComplete: PropTypes.func.isRequired,
};

TransactionRoleForm.defaultProps = {
  id: null,
};

TransactionRoleForm.initialValues = {
  Title: '',
};

export default TransactionRoleForm;
