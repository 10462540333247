import { useState } from 'react';
import classNames from 'classnames';

import { Add } from 'components/Icons';
import { Attachment } from 'pages/Quotes/components/QuoteDetails/components';

import styles from './styles.module.scss';

interface RequestQuoteDocumentProps {
  sortedDocuments: [string, [any, ...any[]]][];
  multi: boolean;
}

export const RequestQuoteDocument = ({ sortedDocuments, multi }: RequestQuoteDocumentProps) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className={styles.requestQuoteContainer}>
      <div className={styles.requestQuote}>
        <div className={styles.attachments}>
          {sortedDocuments.map(([formattedDate, documents], index) => (
            <div className={styles.block} key={formattedDate}>
              {documents.map((document, i) =>
                showMore || (index === 0 && i === 0) ? (
                  <div key={document?.Url}>
                    <Attachment
                      file={document}
                      noText={true}
                      className={styles.quoteAttachment}
                      fileNameClassName={styles.fileName}
                      dateClassName={styles.quoteDate}
                      isActions={false}
                      isActionIcons={true}
                    />
                  </div>
                ) : (
                  <></>
                ),
              )}
            </div>
          ))}
        </div>
        {!showMore && multi && (
          <div
            className={classNames(styles.showMore, 'show-cursor')}
            onClick={() => setShowMore(true)}
          >
            <Add className={styles.showMoreIcon} color={Add.COLLAPSE} />
            <span className={styles.showAllText}>Show All</span>
          </div>
        )}
      </div>
    </div>
  );
};
