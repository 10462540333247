import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Avatar } from 'components';
import { AutoComplete, Option } from 'components-antd';
import { caseInsensitiveFilter, convertNameToAvatarPlaceholder } from 'helpers';

import styles from './styles.module.scss';

export type ValueObject = {
  id?: number;
  name?: string;
  role?: string;
  avatarUrl?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  customName?: string;
  customEmail?: string;
  error?: string;
  invite?: boolean;
  roleId?: number;
  tagRole?: string;
  dropdownContainer?: string;
  transactionAccess?: boolean;
};

export type ParticipantObject = {
  id: number;
  email: string;
  avatarUrl: string;
  name: string;
  role: string;
  phone: string | null;
};

interface ParticipantInputViewProps {
  participants: ParticipantObject[];
  className?: string;
  value: ValueObject;
  onChange?: (...args) => void;
  onBlur?: (...args) => void;
  disabled?: boolean;
  dropdownContainer?: string;
  placeholder?: string;
}

const ParticipantInputView = ({
  participants = [],
  className,
  dropdownContainer,
  value,
  onChange,
  onBlur,
  disabled = false,
  placeholder = 'Enter name',
}: ParticipantInputViewProps) => {
  const [isSearch, setIsSearch] = useState(false);
  const [name, setName] = useState('');

  const getSelectedParticipant = (participantId?: number) =>
    participants.find((participant) => participant.id === participantId);

  useEffect(() => {
    if (value?.id) {
      setName(value?.name || '');
    } else {
      setName(value?.customName || '');
    }
  }, [value]);

  const handleSelect = (value) => {
    const selectedParticipant = getSelectedParticipant(value);
    onChange && onChange({ ...selectedParticipant, value });
    setIsSearch(false);
  };

  const handleBlur = () => {
    const selectedParticipant = getSelectedParticipant(value?.id);
    if (name !== selectedParticipant?.name) {
      onBlur && onBlur(name);
    }
    setIsSearch(false);
  };

  return (
    <div className={className}>
      <AutoComplete
        disabled={disabled}
        value={name}
        onChange={(val) => setName(val)}
        open={isSearch}
        popupClassName={classNames(styles.autoCompleteDropdown, dropdownContainer)}
        placeholder={placeholder}
        filterOption={(inputValue, option) =>
          caseInsensitiveFilter(inputValue, option?.label as string)
        }
        onSearch={(val) => setIsSearch(!!val.trim())}
        onSelect={handleSelect}
        onBlur={handleBlur}
      >
        {participants?.map((participant) => (
          <Option key={participant.id} label={participant.name} value={participant.id}>
            <div className={classNames(styles.autoCompleteOption)}>
              <Avatar
                avatarClassName={styles.avatar}
                src={participant.avatarUrl}
                placeholder={
                  <div className={styles.avatarPlaceholder}>
                    {convertNameToAvatarPlaceholder(`${participant.name}`)}
                  </div>
                }
              />
              <div className={classNames(styles.userContainer)}>
                <div className={classNames(styles.name)}>{participant.name}</div>
                <div className={classNames(styles.email)}>{participant.email}</div>
              </div>
            </div>
          </Option>
        ))}
      </AutoComplete>
    </div>
  );
};

export default ParticipantInputView;
