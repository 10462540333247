import classNames from 'classnames';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
import { isEqual } from 'lodash-es';

import { Tooltip } from 'components-antd';
import DropdownIcon from 'pages/FormBuilder/Icons/Dropdown';

import styles from './styles.module.scss';

interface ColorPickerProps {
  colorPickerClass?: string;
  containerClass?: string;
  sketchPadProps?: any; // Sketch Pad has no type definition of props
  defaultColor?: any;
  colorBoxClassName?: string;
  colorBoxContainerClassName?: string;
  onColorUpdate?: (color) => void;
}

export const ColorPicker = ({
  colorPickerClass,
  containerClass,
  sketchPadProps = {},
  defaultColor,
  colorBoxClassName,
  colorBoxContainerClassName,
  onColorUpdate,
}: ColorPickerProps) => {
  const [color, setColor] = useState(defaultColor);

  const handleColorChange = () => {
    if (onColorUpdate && !isEqual(defaultColor, color)) onColorUpdate(color);
  };

  return (
    <div className={classNames(styles.sketchContainer, containerClass)}>
      <Tooltip
        title={
          <SketchPicker
            className={classNames(styles.sketchPad, colorPickerClass)}
            onChangeComplete={(e) => setColor(e)}
            color={color}
            {...sketchPadProps}
          />
        }
        onOpenChange={(open) => {
          if (!open) handleColorChange();
        }}
        color="white"
        overlayClassName={styles.toolTipContainer}
      >
        <div className={classNames(styles.colorBoxContainer, colorBoxContainerClassName)}>
          <div
            className={classNames(styles.colorBox, colorBoxClassName)}
            style={{
              background: color?.hex
                ? color.hex
                : `rgb(${color?.r || 0},${color?.g || 0},${color?.b || 0})`,
            }}
          />
          <DropdownIcon className={classNames(styles.dropDownIcon)} />
        </div>
      </Tooltip>
    </div>
  );
};
