import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { signupRoleEffect } from 'store/effects/loginGroup';
import { Container, LeftSide, RightSide } from 'components/LoginGroup';
import { mapRoles, AGENT, CLIENT } from 'settings/constants/roles';
import { FormWrapper, FormGroup, FormTitle, Button, RadioOptions } from 'components';
import { link } from 'settings/navigation/link';
import { MosaikLogo } from 'components/Icons';

import { ValidationSchema } from './validation';

import styles from './styles.module.scss';

const SignUpRole = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: { role: '' },
    validationSchema: ValidationSchema,
    onSubmit(values) {
      dispatch(signupRoleEffect(values, false));
      history.push(link.toSignUp(mapRoles[values.role]));
    },
  });

  return (
    <Container className={styles.container}>
      <LeftSide variant={LeftSide.LIGHT} />
      <FormWrapper
        className={classNames(styles.formWrapper, styles.signupWrapper)}
        alignCenter={false}
      >
        <FormGroup testid="choose_account_type" className={styles.formGroup}>
          <FormTitle className={styles.title}>Choose Account Type</FormTitle>
          <form onSubmit={formik.handleSubmit}>
            <RadioOptions
              options={SignUpRole.roles}
              name="role"
              value={formik.values.role}
              onChange={(role) => formik.setFieldValue('role', role)}
              testid="role_option"
              variant={RadioOptions.FULL}
            />
            {formik.errors.role && (
              <div testid="validation_error" className={styles.error}>
                {formik.errors.role}
              </div>
            )}
            {formik.values.role === 'Client' ? (
              <div testid="validation_error" className={styles.error}>
                If you’re a buyer, seller, or homeowner that would like to use Mosaik’s platform,
                please contact your agent for an invitation.
              </div>
            ) : (
              <></>
            )}
            <Button
              testid="continue"
              type="submit"
              className={styles.continue}
              title="Continue"
              disabled={formik.values.role === 'Client'}
            />
          </form>
        </FormGroup>
      </FormWrapper>
      <RightSide />
    </Container>
  );
};

SignUpRole.roles = [
  { id: AGENT, value: AGENT, label: 'Agent' },
  { id: CLIENT, value: CLIENT, label: 'Client' },
];

export default SignUpRole;
