import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { Icons } from '../../../../Icons';

import styles from './styles.module.scss';

export const ApprovalMembers = ({
  data,
  userId,
  name,
  email = '',
  memberType = 'Delegate',
  memberState,
  onChange,
}) => {
  const [state, setState] = useState({ ...memberState });
  useEffect(() => {
    setState({ ...memberState });
  }, [memberState]);

  const onUpdate = () => {
    if (data.AutomaticApproval) return;

    const selected = !state.selected;
    setState({
      ...state,
      selected: selected,
    });
    const obj: any = {
      Role: memberType === 'Agent' ? 'SellerAgent' : memberType,
      RequireApproval: true,
      Sequence: data.ApprovalUsers.length + 1,
    };

    if (userId) {
      obj.UserId = userId;
      obj.Email = email || null;
      obj.Name = name;
    } else {
      obj.Email = email;
    }

    onChange(obj, selected);
  };

  return (
    <div className={styles.memberContainer}>
      {state.selected}
      <div
        className={classNames(
          styles.selectBox,
          { [styles.selected]: state.selected && !data.AutomaticApproval },
          { [styles.disabledBox]: data.AutomaticApproval },
        )}
        onClick={onUpdate}
      >
        {data.SequentialApproval ? <span>{memberState.sequence}</span> : null}
        {!data.AutomaticApproval && !data.SequentialApproval ? <Icons variant="checkmark" /> : null}
      </div>
      <div className={classNames(styles.details, { [styles.disabled]: data.AutomaticApproval })}>
        <h4>{name}</h4>
        <p>{memberType}</p>
      </div>
    </div>
  );
};
