import React, { SVGProps } from 'react';

type variantTypes = 'filter' | 'filterSecondary' | 'sortBy' | 'reset' | 'calendar' | 'none';
interface IIcons extends SVGProps<SVGSVGElement> {
  variant: variantTypes;
}

export const Icons = ({ variant = 'none', ...props }: IIcons) => {
  const renderIcon = () => {
    switch (variant) {
      case 'filter': {
        return (
          <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M1.96746 3.10567C1.59029 2.67462 1.89641 2 2.46918 2H23.5308C24.1036 2 24.4097 2.67461 24.0325 3.10567L15.6667 12.6667V20.2546C15.6667 20.5072 15.524 20.738 15.2981 20.8509L11.2981 22.8509C10.8549 23.0726 10.3333 22.7502 10.3333 22.2546V12.6667L1.96746 3.10567Z"
              stroke="#FF576D"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'filterSecondary': {
        return (
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M1.72559 1.82925C1.44271 1.50596 1.6723 1 2.10188 1H17.8981C18.3277 1 18.5573 1.50596 18.2744 1.82925L12 9V14.691C12 14.8804 11.893 15.0535 11.7236 15.1382L8.72361 16.6382C8.39116 16.8044 8 16.5627 8 16.191V9L1.72559 1.82925Z"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'sortBy': {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M2 15H6"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 10H8.5"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 5L11 5"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 4V17M16 17L13 14M16 17L19 14"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'reset':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M4 19V15H8"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20 5V9H16"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.80965 15.507C5.56908 17.064 6.81344 18.3325 8.35564 19.1217C9.89785 19.9109 11.6546 20.1781 13.3617 19.8833C15.0688 19.5884 16.6341 18.7473 17.8222 17.4865C19.0103 16.2258 19.757 14.6134 19.9501 12.8918"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.1904 8.49303C18.4309 6.93596 17.1866 5.66748 15.6444 4.8783C14.1022 4.08911 12.3454 3.82187 10.6383 4.11674C8.9312 4.41161 7.36593 5.25266 6.17782 6.51345C4.98971 7.77425 4.24297 9.38665 4.04986 11.1082"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'calendar':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M17.25 6H6.75C5.7835 6 5 6.7835 5 7.75V18.25C5 19.2165 5.7835 20 6.75 20H17.25C18.2165 20 19 19.2165 19 18.25V7.75C19 6.7835 18.2165 6 17.25 6Z"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.5 4.5V7.5"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.5 4.5V7.5"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 11H19"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      default:
        return <></>;
    }
  };
  return renderIcon();
};
