import classNames from 'classnames';
import { useState } from 'react';

import { Col, Input } from 'components-antd';
import { SmartFormIcons } from 'components';

import styles from './styles.module.scss';

interface FormPillInterface {
  pdfName?: string | string[];
  clientName?: string | string[];
  clientAddress?: string | string[];
  formPillClass?: string | string[];
  pdfInfoClass?: string;
  pdfNameClass?: string;
  editablePdfClass?: string;
  showBundleIcon?: boolean;
  updateFormName?: (newName: string) => void;
  isBundle?: boolean;
  disableEditName?: boolean;
}

export const FormPill = ({
  pdfName,
  clientName,
  clientAddress,
  formPillClass,
  updateFormName,
  pdfInfoClass,
  pdfNameClass,
  showBundleIcon,
  editablePdfClass,
  isBundle,
  disableEditName,
}: FormPillInterface) => {
  const [showEditName, setShowEditName] = useState(false);

  const allowEdit = !!updateFormName && !disableEditName;

  const clientInfo = `${clientName || ''}${clientName && clientAddress ? ' - ' : ''}${
    clientAddress || ''
  }`;

  const handleInputBlur = (e) => {
    const newName = e.target.value?.trim();
    if (newName && newName !== pdfName && updateFormName) {
      updateFormName(newName);
    }
    setShowEditName(false);
  };

  return pdfName || clientInfo ? (
    <Col className={classNames(formPillClass)}>
      <div
        className={classNames(styles.pdfInfo, pdfInfoClass, showEditName ? editablePdfClass : '')}
      >
        <span className={classNames({ [styles.editNameContainer]: !disableEditName })}>
          {showBundleIcon && (
            <SmartFormIcons
              className={`${styles.icon} ${allowEdit ? styles.fileIcon : ''}`}
              variant={isBundle ? 'pdf-bundle' : 'file'}
            />
          )}

          {showEditName ? (
            <>
              <Input
                className={styles.nameInput}
                defaultValue={pdfName}
                autoFocus
                onBlur={handleInputBlur}
                hideOutline
                maxLength={255}
                allowClear
              />
            </>
          ) : (
            <span className={classNames(styles.pdfName, styles.pdfInformation, pdfNameClass)}>
              {pdfName}
            </span>
          )}

          {allowEdit && !showEditName && (
            <SmartFormIcons
              className={styles.pillIcon}
              variant="pencil"
              onClick={() => setShowEditName(true)}
              stroke="#747475"
            />
          )}
        </span>

        <span className={classNames(styles.clientInfo, styles.pdfInformation)}>
          <span>{clientName}</span>
          {clientName && clientAddress ? ' - ' : ''}
          <span>{clientAddress}</span>
        </span>
      </div>
    </Col>
  ) : (
    <></>
  );
};
