import React, { useState, useEffect, useCallback } from 'react';
import { Switch } from 'components-antd';
import { prefsIds } from 'settings/constants/preferences';

import styles from './styles.module.scss';

interface BackyardPrefConfig {
  OutdoorPrefs: any;
  find(arg0: (item: any) => boolean): any;
  map(arg0: (obj: any) => any): unknown;
  Preference?: string;
}
interface BackyardSelectorProps {
  backyardPrefs: BackyardPrefConfig;
  onEditBackyardPrefs: (newValue: Array<{}>) => void;
  onUpdatePrefs: (newValue: any) => void;
  currentImportance: string;
}

export const BackyardSelector: React.FC<BackyardSelectorProps> = ({
  backyardPrefs,
  onEditBackyardPrefs,
  onUpdatePrefs,
  currentImportance,
}) => {
  const [isFenced, setIsFenced] = useState(
    !!backyardPrefs?.OutdoorPrefs?.find((item) => item.Preference === prefsIds.fencedYard),
  );

  const onSwitchChange = (bool) => {
    setIsFenced(bool);

    const newObj = {
      Preference: bool === true ? prefsIds.fencedYard : prefsIds.outdoorYard,
      ImportanceAndWeight: {
        Importance: currentImportance,
      },
    };

    const updatedArray = backyardPrefs?.OutdoorPrefs?.map((obj) => {
      if (obj.Preference === prefsIds.outdoorYard || obj.Preference === prefsIds.fencedYard) {
        return newObj;
      }
      return obj;
    }) as any;

    onEditBackyardPrefs(updatedArray);
  };

  return (
    <div className={styles.backyardSelector}>
      <p>Backyard must be fenced?</p>
      <Switch checked={isFenced} onChange={onSwitchChange} />
    </div>
  );
};
