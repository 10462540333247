import { apiServer } from 'settings/web-services/api';

export function getClientAddressesValuation({ placeIds, monthFilter }) {
  return apiServer.get(`/dashboard/client-addresses-valuations`, {
    params: {
      placeId: placeIds,
      monthFilter,
    },
  });
}
