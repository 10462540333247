import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface AnswersContainerProps {
  className?: any;
  children: React.ReactNode;
}

export const AnswersContainer = ({ className, children }: AnswersContainerProps) => {
  return <div className={classNames(styles.answersContainer, className)}>{children}</div>;
};
