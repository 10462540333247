import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOutsideClick } from 'hooks';
import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const Actions = (props) => {
  const { className, onEdit, onDelete } = props;
  const [actionsOpen, setActionsOpen] = useState(false);

  const buttonRef = useRef();
  const popupRef = useRef();

  useOutsideClick([buttonRef, popupRef], () => setActionsOpen(false));

  const onDeleteHandler = () => {
    onDelete();
    setActionsOpen(false);
  };

  return (
    <div className={classNames(styles.actions, className)}>
      <button
        ref={buttonRef}
        className={styles.actionButton}
        onClick={() => setActionsOpen(!actionsOpen)}
        type="button"
        testid="dots_action"
      >
        <span className={styles.dots}>...</span>
      </button>
      {actionsOpen && (
        <div ref={popupRef} className={styles.popup}>
          <ul className={styles.list}>
            <li testid="edit_commute" onClick={onEdit} className={styles.item}>
              <Icon variant={Icon.EDIT} />
              <span className={styles.text}>Edit</span>
            </li>
            <li testid="delete_commute" onClick={onDeleteHandler} className={styles.item}>
              <Icon variant={Icon.TRASH} />
              <span className={styles.text}>Delete</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

Actions.defaultProps = {
  className: '',
  onEdit: () => {},
  onDelete: () => {},
};

export default Actions;
