import React, { forwardRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Wrapper as PendingWrapper } from 'components';
import { getComparesSelector, getMultipleModSelector } from 'store/selectors/feed';
import useWrapperSize from './useWrapperSize';

import styles from './styles.module.scss';

const PropertiesWrapper = forwardRef(({ className, children }, ref) => {
  const wrapperRef = useRef();
  const compares = useSelector(getComparesSelector);
  const isMultipleMode = useSelector(getMultipleModSelector);
  const { wrapperSize } = useWrapperSize({ wrapperRef, list: compares?.data });
  const [nextPageIsPending, setNexPageIsPending] = useState(false);

  const getWrapperSize = () => {
    if (compares?.data?.length > 1 && isMultipleMode) {
      return { ...wrapperSize, height: wrapperSize.height - 80 };
    }

    return wrapperSize;
  };

  const getNextPageIsPending = (isPending) => {
    setNexPageIsPending(isPending);
  };

  return (
    <div ref={ref || wrapperRef} className={classNames(styles.propertiesWrapper, className)}>
      {typeof children === 'function' ? children(getWrapperSize(), getNextPageIsPending) : children}
      {nextPageIsPending && (
        <PendingWrapper
          className={classNames(styles.loadingPage, {
            [styles.isCompares]: compares.data.length > 1 && isMultipleMode,
          })}
          loaderClassName={styles.loader}
          isPending
        />
      )}
    </div>
  );
});

PropertiesWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

PropertiesWrapper.defaultProps = {
  className: '',
};

export default PropertiesWrapper;
