import { handleActions } from 'redux-actions';
import {
  setTransactionPreFormQuestionsAction,
  createTransactionAction,
  resetTransactionCreateAction,
  resetTransactionCreateTemplateAction,
} from 'store/actions/transactions';
import { IDLE } from 'settings/constants/apiState';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { get, isEmpty } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  pristineData: null,
};

export default handleActions(
  {
    [setTransactionPreFormQuestionsAction]: (state, { payload }) => {
      const shouldUpdatePristineData = isEmpty(state.pristineData) && payload?.templateId;
      return {
        ...state,
        data: {
          ...(state.data || {}),
          ...payload,
        },
        pristineData: shouldUpdatePristineData ? { ...(state.data || {}) } : state.pristineData,
      };
    },
    [createTransactionAction]: (state, { payload }) => ({
      ...state,
      state: get(payload, 'state', initialData.state),
    }),
    [resetTransactionCreateAction]: () => initialData,
    [resetTransactionCreateTemplateAction]: (state) => {
      return {
        ...state,
        data: {
          ...state?.data,
          [transactionPreFormQuestionsIds.transactionTemplate]: undefined,
          [transactionPreFormQuestionsIds.assignParticipantsToRoles]: undefined,
          [transactionPreFormQuestionsIds.tasks]: undefined,
          [transactionPreFormQuestionsIds.timeline]: undefined,
          [transactionPreFormQuestionsIds.templateId]: undefined,
          ...(state.pristineData?.propertyType && {
            propertyType: state.pristineData?.propertyType,
          }),
        },
        pristineData: null,
      };
    },
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
