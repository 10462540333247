import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Form from './Form';
import styles from './styles.module.scss';
import { Toggle, FormattedPhone } from 'components';
import { getUserInfo } from '../OldNotifications/selectors';
import { getUserIsMfaEnabled } from 'store/selectors/user';
import { usePhoneVerification } from '../Notifications/components/NotificationsMediums/usePhoneVerification';
import { VerifyPhoneNumberModal } from '../Notifications/components/VerifyPhoneNumberModal/VerifyPhoneNumberModal';
import { updateIsMfaEnabledEffect } from 'store/effects';

const Password = () => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const [isVerifyPhoneNumberModalOpened, setVerifyPhoneNumberModalOpened] = useState(false);

  const openModal = () => {
    setVerifyPhoneNumberModalOpened(true);
  };

  const userInfo = useSelector(getUserInfo);
  const { userEmail, userPhones } = userInfo || {};
  const mainUserPhoneNumber = userPhones?.[0]?.PhoneNumber;
  const phoneVerification = usePhoneVerification(userPhones, openModal);

  let isMfaEnabled = useSelector(getUserIsMfaEnabled);

  const setValueForCheckbox = (val) => {
    setIsPending(true);
    dispatch(updateIsMfaEnabledEffect(val));
    setIsPending(false);
  };

  const onToggle2FA = (e, val) => {
    if (!phoneVerification.isPhoneNumberVerified) {
      phoneVerification.requestPhoneVerification(() => {
        setValueForCheckbox(true);
      });
      return;
    }
    setValueForCheckbox(val);
  };

  return (
    <div>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>Password</h2>
      </div>
      <Form />
      <div className={styles.itemWrapper}>
        <h2 className={styles.title}>Two Factor Authentication</h2>
      </div>
      <div className={styles.item}>
        <div className={styles.titles}>
          <div className={styles.title}>
            One-time passcodes for unrecognized browsers will be sent to:{' '}
            <FormattedPhone testid="phone" className={styles.phone}>
              {mainUserPhoneNumber}
            </FormattedPhone>
          </div>
        </div>
        <Toggle
          className={styles.toggle}
          labelClassName={styles.toggleWrapper}
          onChange={onToggle2FA}
          checked={isMfaEnabled}
          label={isMfaEnabled ? 'On' : 'Off'}
          isPending={isPending}
          topControl
        />
      </div>
      <VerifyPhoneNumberModal
        isVerifyPhoneNumberModalOpened={isVerifyPhoneNumberModalOpened}
        onCloseModal={() => {
          setVerifyPhoneNumberModalOpened(false);
        }}
      />
    </div>
  );
};

export default Password;
