import PropTypes from 'prop-types';

const Icons = ({ testid, className, variant, onClick }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.CLOSE:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_45177_13572)">
              <path
                d="M12 4L4 12"
                stroke="#515151"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 4L12 12"
                stroke="#515151"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_45177_13572">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case Icons.ADD:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="10" r="10" fill="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.7529 5.99951C10.7529 5.5853 10.4171 5.24951 10.0029 5.24951C9.58872 5.24951 9.25293 5.5853 9.25293 5.99951V9.24951H6.00293C5.58872 9.24951 5.25293 9.5853 5.25293 9.99951C5.25293 10.4137 5.58872 10.7495 6.00293 10.7495H9.25293V13.9995C9.25293 14.4137 9.58872 14.7495 10.0029 14.7495C10.4171 14.7495 10.7529 14.4137 10.7529 13.9995V10.7495H14.0029C14.4171 10.7495 14.7529 10.4137 14.7529 9.99951C14.7529 9.5853 14.4171 9.24951 14.0029 9.24951H10.7529V5.99951Z"
              fill="#4673D1"
            />
          </svg>
        );
      case Icons.CHECKMARK:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_10454_159766)">
              <path
                d="M4 10L8 14L16 6"
                stroke="#04A451"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_10454_159766">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className} style={{ display: 'flex' }}>
      {renderIcon()}
    </div>
  );
};

Icons.CLOSE = 'Close';
Icons.ADD = 'Add';
Icons.CHECKMARK = 'Checkmark';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};

export default Icons;
