import classnames from 'classnames';
import { useMemo, useState, useEffect, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { Controls } from './Controls';
import { link } from 'settings/navigation/link';
import { routes } from 'settings/navigation/routes';
import { PendingTasks } from './components/PendingTasks';
import { TransactionCloseComponentIds, TransactionStagePercentage } from 'app-constants';
import { IncompleteForms } from './components/IncompleteForms';
import UploadFiles from './components/UploadFiles/UploadFiles';
import { getTransactionSelector } from 'store/selectors/transaction';
import { transactionStatuses } from 'settings/constants/transaction';
import { CloseTransactionEnd } from './components/CloseTransactionEnd';
import { ClientProfileAddAddress } from './components/ClientProfileAddAddress';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { ActiveSearchInstances } from './components/ActiveSearchInstances';
import { getAllTransactionFormProcessEffect } from 'store/effects/formProcess';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import {
  getTransactionClientAddressEffect,
  getTransactionEffect,
  updateCloseDatePriceEffect,
  updateTransactionStatusEffect,
} from 'store/effects/transactions';
import { Task } from 'pages/Workshop/Transactions/TransactionTasks/components';
import { ClientProfileRemoveAddress } from './components/ClientProfileRemoveAddress';
import { KitsActivation } from './components/KitsActivation';
import styles from './styles.module.scss';
import { Progress } from 'components-antd';
import { ContentWrapper } from '../TransactionCreate/components';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { ClosePrice } from './components/ClosePrice';
import CloseDate from './components/CloseDate/CloseDate';
import { ClosingTasks } from './components/ClosingTasks';
import { requestCreateUpdateOfferEffect } from 'store/effects';
import { requestCreateUpdateShowingEffect } from 'store/effects/showings';

type LatestClientAddressDataType = {
  ClientId: number;
  Address: string;
};

export const TransactionClose = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params: { id?: string } = useParams();
  const isProject = useIsProjectRoute();

  const [stage, setStage] = useState<TransactionCloseComponentIds>(
    TransactionCloseComponentIds.CloseDate,
  );
  const [previousStage, setPreviousStage] = useState<TransactionCloseComponentIds | undefined>();
  const [percent, setPercent] = useState(0);
  const [closingDate, setClosingDate] = useState<string>('');
  const [closingPrice, setClosingPrice] = useState<string>('');
  const [latestClientAddress, setLatestClientAddress] = useState<LatestClientAddressDataType[]>([]);
  const { transaction } = useSelector(getTransactionSelector);

  const setStageHandler = (nextStage: TransactionCloseComponentIds) => {
    setPreviousStage(stage);
    setStage(nextStage);
  };

  const stayOnPageOrOpenEmailVerificationModal = useEmailVerificationWall(
    ActionToInterrupt.CLOSE_TRANSACTION,
    () => {},
  );

  useEffect(() => {
    stayOnPageOrOpenEmailVerificationModal();
  });

  useEffect(() => {
    if (!transaction.Id) {
      dispatch(getTransactionEffect({ id: params?.id }));
    }
    if (params?.id) {
      dispatch(getAllTransactionFormProcessEffect({ transactionId: params?.id }));
      dispatch(getTransactionClientAddressEffect({ id: params?.id }));
    }
  }, [params?.id]);

  const getUpdatedTransactionFormData = () =>
    dispatch(getAllTransactionFormProcessEffect({ transactionId: params?.id }));

  const breadcrumbsList = useMemo(
    () => [
      { label: 'Transactions', link: routes.transactions },
      { label: 'Close Transaction', link: location.pathname },
    ],
    [location.pathname],
  );

  const disableOfferLink = () => {
    if (transaction.OfferLink?.EnableLink) {
      const offerLinkAPIParams = {
        ...transaction.OfferLink,
        EnableLink: false,
        Id: +transaction.OfferLink.Id,
      };

      dispatch(requestCreateUpdateOfferEffect(offerLinkAPIParams, {}, undefined));
    }
  };

  const disableShowingLink = () => {
    if (transaction.Showing?.EnableShowingLink) {
      const showingLinkAPIParams = {
        ...transaction.Showing,
        EnableShowingLink: false,
        Id: +transaction.Showing.Id,
      };

      dispatch(requestCreateUpdateShowingEffect(showingLinkAPIParams, {}, undefined));
    }
  };

  const onSubmit = () => {
    dispatch(
      updateTransactionStatusEffect({
        id: params?.id,
        status: transactionStatuses.Closed,
      }),
    );
    dispatch(
      updateCloseDatePriceEffect({
        id: transaction.Id,
        [TransactionCloseComponentIds.ClosePrice]: Number(closingPrice),
        [TransactionCloseComponentIds.CloseDate]: closingDate,
      }),
    );

    disableOfferLink();
    disableShowingLink();
  };

  const onClose = () => {
    setPercent(100);
    if (isProject) history.push(link.toProjectOverview(String(params?.id)));
    else history.push(link.toTransactionOverview(String(params?.id)));
  };

  const addLatestClientAddress = useCallback((id: number, address: string) => {
    setLatestClientAddress((prevArray) => [...prevArray, { ClientId: id, Address: address }]);
  }, []);

  const renderStage = () => {
    switch (stage) {
      case TransactionCloseComponentIds.UploadFiles:
        return <UploadFiles setStage={setStageHandler} />;
      case TransactionCloseComponentIds.IncompleteForms:
        return (
          <IncompleteForms
            getUpdatedTransactionFormData={getUpdatedTransactionFormData}
            previousStage={previousStage}
            setStage={setStageHandler}
          />
        );
      case TransactionCloseComponentIds.PendingTasks:
        return (
          <Fragment>
            <Task />
            <ClosingTasks previousStage={previousStage} setStage={setStageHandler} />;
          </Fragment>
        );
      case TransactionCloseComponentIds.CloseDate:
        return (
          <CloseDate
            setClosingDate={setClosingDate}
            setStage={setStageHandler}
            nextStage={
              isProject
                ? TransactionCloseComponentIds.UploadFiles
                : TransactionCloseComponentIds.ClosePrice
            }
          />
        );
      case TransactionCloseComponentIds.ClosePrice:
        return <ClosePrice setClosingPrice={setClosingPrice} setStage={setStageHandler} />;
      case TransactionCloseComponentIds.ClientProfileAddAddress:
        return (
          <ClientProfileAddAddress
            previousStage={previousStage}
            setStage={setStageHandler}
            latestClientAddressAdder={addLatestClientAddress}
          />
        );
      case TransactionCloseComponentIds.ClientProfileRemoveAddress: {
        return (
          <ClientProfileRemoveAddress previousStage={previousStage} setStage={setStageHandler} />
        );
      }
      case TransactionCloseComponentIds.ActiveSearchInstances:
        return <ActiveSearchInstances previousStage={previousStage} setStage={setStageHandler} />;
      case TransactionCloseComponentIds.KitsActivation:
        return (
          <KitsActivation
            previousStage={previousStage}
            setStage={setStageHandler}
            clientAddresses={latestClientAddress}
          />
        );
      case TransactionCloseComponentIds.CloseConfirmation:
        return <CloseTransactionEnd onClose={onClose} onSubmit={onSubmit} />;
      default:
        return <UploadFiles setStage={setStageHandler} />;
    }
  };

  useEffect(() => {
    setPercent(TransactionStagePercentage[stage]);
  }, [stage]);

  return (
    <>
      <Progress percent={percent} />
      <div className={styles.transactionClose}>
        <div className={styles.head}>
          {stage !== TransactionCloseComponentIds.CloseConfirmation ? (
            <Controls onClose={onClose} stage={stage} setStage={setStageHandler} />
          ) : null}
        </div>
        <ContentWrapper
          className={classnames({
            [styles.questionContainer]:
              stage !== TransactionCloseComponentIds.ActiveSearchInstances,
            [styles.activeSearchInstanceContainer]:
              stage === TransactionCloseComponentIds.ActiveSearchInstances,
          })}
        >
          {renderStage()}
        </ContentWrapper>
      </div>
    </>
  );
};
