import Header from 'pages/Dashboard/components/Header';
import NotificationTemplateForm from 'pages/Dashboard/NotificationTemplates/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Modal, Wrapper as PendingWrapper } from 'components';

import Grid from 'pages/Dashboard/components/Grid';
import { getNotificationTemplatesEffect } from 'store/effects/adminPanel/notificationTemplates';
import {
  getNotificationTemplatesDashboardSelector,
  getNotificationTemplatesDataDashboardSelector,
} from 'store/selectors/adminPanel/notificationTemplates';

import { gridColumns } from './config';
import styles from './styles.module.scss';

const NotificationTemplates = (props) => {
  const dispatch = useDispatch();
  const templates = useSelector(getNotificationTemplatesDataDashboardSelector);
  const { isIdle, isPending } = useSelector(getNotificationTemplatesDashboardSelector);
  const { className } = props;

  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const onPreviewModalClose = useCallback(
    () => setSelectedTemplateId(null),
    [setSelectedTemplateId],
  );
  const onRowClick = useCallback((e, { Id }) => setSelectedTemplateId(Id), [setSelectedTemplateId]);

  useEffect(() => {
    if (isIdle) {
      dispatch(getNotificationTemplatesEffect({}));
    } else {
      dispatch(getNotificationTemplatesEffect({}, { silent: true }));
    }
  }, []); // eslint-disable-line

  return (
    <PendingWrapper className={classNames(styles.wrapper, className)} isPending={isPending}>
      <Header title="Notification Templates" />
      <Grid items={templates || []} columns={gridColumns} keyProp="Id" onRowClick={onRowClick} />
      <Modal
        isOpen={!!selectedTemplateId}
        onClose={onPreviewModalClose}
        innerHolderClassName={styles.modalInnerHolder}
        contentClassName={styles.modalContent}
      >
        {!!selectedTemplateId && (
          <NotificationTemplateForm
            id={selectedTemplateId}
            onSubmissionComplete={() => {
              setSelectedTemplateId(null);
            }}
          />
        )}
      </Modal>
    </PendingWrapper>
  );
};

NotificationTemplates.propTypes = {
  className: PropTypes.string,
};

NotificationTemplates.defaultProps = {
  className: '',
};

export default NotificationTemplates;
