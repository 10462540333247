import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { Icons, ICON_VARIANT_TYPE } from 'pages/Workshop/Forms/Icons';
import Actions from './Actions';
import styles from './styles.module.scss';
import { SUPER_USER } from 'settings/constants/roles';

const Attachment = (props) => {
  const {
    file,
    className,
    truncateLength,
    isActions,
    deletable,
    taskId,
    uploadedBy,
    onDelete,
    DocumentVaultUUID,
    isCompleted,
  } = props;
  const { Title, Meta, DateUploaded, documentName, Uploader } = file || {};
  const { Filename } = Meta || {};

  const getDate = () => {
    const momentDate = moment(DateUploaded);
    const formattedDate = momentDate.format('MMM DD, YYYY');
    const formattedTime = momentDate.format('h:mm A');

    const { FirstName, LastName } = uploadedBy || {};
    const name = `${FirstName} ${LastName}`;

    return isCompleted
      ? `Completed: ${formattedDate} at ${formattedTime}`
      : `Uploaded by ${name} on ${formattedDate} at ${formattedTime}`;
  };

  const getFilename = () => {
    const truncate = (name) => {
      const truncatedFileName = name.split('').slice(-truncateLength).join('');
      if (name?.length > truncateLength) {
        return `...${truncatedFileName}`;
      }
      return truncatedFileName;
    };

    if (truncateLength) {
      if (
        typeof Title === 'string' ||
        typeof Filename === 'string' ||
        typeof documentName === 'string'
      ) {
        return truncate(Title || documentName || Filename);
      }
      return '';
    }

    return Title || documentName || Filename;
  };

  const isSuperUser = Uploader?.Roles?.includes(SUPER_USER);

  return (
    <div testid="document_item" className={classNames(styles.attachment, className)}>
      {isCompleted ? (
        <Icons className={styles.icon} variant={ICON_VARIANT_TYPE.COMPLETE_DOCUMENT} />
      ) : (
        <Icons className={styles.icon} variant={ICON_VARIANT_TYPE.INCOMPLETE_DOCUMENT} />
      )}
      <div className={styles.info}>
        <p testid="file_name" className={isSuperUser ? styles.justFileName : styles.filename}>
          {getFilename()}
        </p>
        <p testid="file_date" className={styles.date}>
          {isSuperUser ? <></> : getDate()}
        </p>
      </div>
      {isActions && (
        <Actions
          file={file}
          deletable={deletable}
          taskId={taskId}
          onDelete={onDelete}
          DocumentVaultUUID={DocumentVaultUUID}
        />
      )}
    </div>
  );
};

Attachment.propTypes = {
  className: PropTypes.string,
  file: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ContentType: PropTypes.string,
    Url: PropTypes.string,
    UploadDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    Filename: PropTypes.string,
  }).isRequired,
  truncateLength: PropTypes.number,
  isActions: PropTypes.bool,
  deletable: PropTypes.bool,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  DocumentVaultUUID: PropTypes.number,
  uploadedBy: PropTypes.shape({
    FirstName: '',
    LastName: '',
  }),
  onDelete: PropTypes.func,
};

Attachment.defaultProps = {
  className: '',
  truncateLength: undefined,
  isActions: true,
  deletable: false,
  taskId: undefined,
  uploadedBy: {},
  DocumentVaultUUID: undefined,
  onDelete: () => {},
};

export default Attachment;
