import { handleActions } from 'redux-actions';

import { setKitchenAvgSizeAction, resetKitchenAvgSizeAction } from 'store/actions/propertyFeatures';

import { appLogoutAction } from 'store/actions/app';

const initialData = {
  kitchenAvgSize: null,
};

export default handleActions(
  {
    [setKitchenAvgSizeAction as any]: (state, { payload }) => ({
      ...state,
      kitchenAvgSize: payload.kitchenAvgSize,
    }),
    [resetKitchenAvgSizeAction as any]: (state) => ({
      ...state,
      kitchenAvgSize: null,
    }),
    [appLogoutAction as any]: () => initialData,
  },
  initialData,
);
