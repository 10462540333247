import { createSelector } from 'reselect';

const appState = ({ app }) => app;
const feedState = ({ feed }) => feed;
const searchResultsState = ({ searchResults }) => searchResults;
const drawersState = ({ drawers }) => drawers;

export const anyDriverOpenSelector = createSelector(
  appState,
  feedState,
  searchResultsState,
  drawersState,
  (app, feed, searchResults, drawers) =>
    app?.clientsDrawer?.open ||
    feed?.filterDrawer?.open ||
    feed?.shareDrawer?.open ||
    searchResults?.filterDrawer?.open ||
    drawers?.messages?.open ||
    drawers?.comments?.open ||
    drawers?.threadContacts?.open ||
    drawers?.addParticipants?.open ||
    drawers?.notifications?.open,
);
