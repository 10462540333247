import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';

import styles from './styles.module.scss';
import Card from './Components/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTeamDetailsDashboardSelector,
  getTeamDetailsDataDashboardSelector,
} from 'store/selectors/adminPanel/teamDetails';
import { getTeamDetailsByIdEffect } from 'store/effects';
import classNames from 'classnames';
import { Wrapper as PendingWrapper } from 'components';

const Details = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { isPending } = useSelector(getTeamDetailsDashboardSelector);
  const data = useSelector(getTeamDetailsDataDashboardSelector);
  const teamId = params.id;

  useEffect(() => {
    dispatch(getTeamDetailsByIdEffect({ teamId }));
  }, []); // eslint-disable-line

  const getAddress = (brokerageAddress) => {
    const addressLine1 = brokerageAddress?.Line1 || '';
    const city = brokerageAddress?.City || '';
    const state = brokerageAddress?.State || '';
    const zip = brokerageAddress?.Zip || '';
    const statePart = `${state} ${zip}`.trim();

    const sanitizedAddress = [addressLine1, city, statePart].filter((i) => !!i).join(', ');
    return sanitizedAddress;
  };

  const getCardsData = (key) => {
    const tempData = data[key];
    if (key === 'Owner') {
      let labels = ['Owner Name', 'Contact', 'Address'];
      let name = `${tempData?.FirstName} ${tempData?.LastName}`;
      let contact = tempData?.Contact ?? '-';
      let address = tempData?.Address ? getAddress(tempData?.Address) ?? '-' : '-';
      const values = [name, contact, address];
      return {
        labels,
        values,
      };
    }
    if (key === 'Brokerage') {
      let labels = ['Brokerage Name', 'Address'];
      let name = `${tempData?.Name ?? '-'}`;
      let address = tempData?.Address ? getAddress(tempData?.Address) ?? '-' : '-';
      const values = [name, address];
      return {
        labels,
        values,
      };
    }
    if (key === 'Plan') {
      let labels = ['Plan', 'Available Seats', 'Filled Seats'];
      let name = `${tempData?.PlanName ?? '-'}`;
      let availableSeats = tempData?.BilledSeatsCount || 0;
      let filledSeats = tempData?.BilledSeatsCount - tempData?.UnusedSeatsCount || 0;
      const values = [name, availableSeats, filledSeats];
      return {
        labels,
        values,
      };
    }

    return {
      labels: [],
      values: [],
    };
  };
  return (
    <div testid="team-details-page" className={styles.details}>
      <PendingWrapper
        isPending={isPending}
        className={classNames({ [styles.pendingWrapper]: isPending })}
      >
        <div className={styles.cardRow}>
          {data &&
            Object.keys(data).map((key) => {
              const { labels, values } = getCardsData(key);
              return (
                <>
                  {labels?.length > 0 && values?.length > 0 && (
                    <Card labels={labels} values={values} cardWidth={400} />
                  )}
                </>
              );
            })}
        </div>
      </PendingWrapper>
    </div>
  );
};

export default Details;
