import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';
import {
  setTransactionFiltersAppliedEffect,
  setProjectsFilterEffect,
} from 'store/effects/transactions/all';
import { getTransactionsIsArchiveSelector } from 'store/selectors/transactions';
import { formsFilters } from 'settings/constants/roles';
import { TEAM_OWNER, TEAM_ADMIN } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

import styles from './styles.module.scss';

export const FormSelectFilter = (props) => {
  const [value, setValue] = useState(formsFilters.allForms);
  const dispatch = useDispatch();
  const isArchive = useSelector(getTransactionsIsArchiveSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  useEffect(() => {
    // Reset Filters whenever archive button is clicked.
    setValue(formsFilters.allForms);
    dispatch(setProjectsFilterEffect(formsFilters.allForms));
    dispatch(setTransactionFiltersAppliedEffect(false));
  }, [isArchive]);

  const onChange = (e) => {
    const val = e.target.value;
    dispatch(setProjectsFilterEffect(val));
    setValue(val);
  };

  // Hide Team Forms option for Team members.
  const teamAdminAndOwnerOption = [TEAM_OWNER, TEAM_ADMIN].includes(agentRole)
    ? [formsFilters.teamForms]
    : [];

  const dropdownFields = [
    formsFilters.myForms,
    ...teamAdminAndOwnerOption,
    formsFilters.externalForms,
    formsFilters.allForms,
  ];

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => (
          <MenuItem key={idx}>
            <Radio value={item} key={idx}>
              <div className={styles.option}>
                <span className={styles.optionValue}>{item}</span>
              </div>
              {value === item && <Check className={styles.checkIcon} />}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.filterWrap, props.className)}>
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.selectedValue}>{value}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
        </a>
      </Dropdown>
    </div>
  );
};
