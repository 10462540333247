import moment from 'moment';
// offer Link Details
export const GET_OFFER_LINK_DETAILS_BY_UUID = (UUID: number) => `/offer/link/${UUID}`;

// Offer Iteration Details
export const GET_OFFER_ITERATION_DETAILS_BY_ID = (Id: number) => `offer/iteration/${Id}`;

export const GET_OFFER_DETAILS_BY_UUID = (UUID: string) => `offer/${UUID}`;

export const SEND_VERIFICATION_LINK_FOR_OFFER_PORTAL = (uuid: string) =>
  `/offer/${uuid}/externalVerification`;
