import {
  resetSmartFormAction,
  updateSmartFormAction,
  updateSmartFormQuestionAction,
  updateSmartFormMetaAction,
  requestSmartFormAction,
  updateSmartFormPreviewResponseAction,
  requestUpdateSmartFormAction,
  requestCopySmartFormVersionAction,
  requestNewSmartFormVersionAction,
  requestAddQuestionRoleAction,
  requestFillDocumentPreviewAction,
} from 'store/actions/formBuilder';
import { QuestionType, SmartBuilderType } from 'types';
import Api from 'store/effects/core/api';
import {
  addQuestionRole,
  copySmartFormVersion,
  fillDocumentPreview,
  getSmartForm,
  newSmartFormVersion,
  updateSmartForm,
} from 'api/formBuilder';

export const resetSmartFormBuilderEffect = () => (dispatch) => {
  dispatch(resetSmartFormAction());
};

export const updateSmartFormEffect = (cfg: Partial<SmartBuilderType>) => (dispatch) => {
  dispatch(updateSmartFormAction(cfg));
};

export const updateSmartFormQuestionEffect = (cfg: QuestionType) => (dispatch) => {
  dispatch(updateSmartFormQuestionAction(cfg));
};

export const updateSmartFormMetaEffect = (cfg: Partial<SmartBuilderType>) => (dispatch) => {
  dispatch(updateSmartFormMetaAction(cfg));
};

export const getSmartFormBuilderEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestSmartFormAction,
    method: getSmartForm,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateSmartFormResponseEffect = (cfg) => (dispatch) => {
  dispatch(updateSmartFormPreviewResponseAction(cfg));
};

export const previewDocumentFillEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestFillDocumentPreviewAction,
    method: fillDocumentPreview,
  });

  return sendRequest(cfg, {}, cb);
};

export const requestUpdateSmartFormEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestUpdateSmartFormAction,
    method: updateSmartForm,
  });

  return sendRequest(cfg, {}, cb);
};

export const requestNewQuestionRoleEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestAddQuestionRoleAction,
    method: addQuestionRole,
  });

  return sendRequest(cfg, {}, cb);
};

export const requestCopySmartFormVersionEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestCopySmartFormVersionAction,
    method: copySmartFormVersion,
  });

  return sendRequest(cfg, {}, cb);
};

export const requestNewSmartFormVersionEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestNewSmartFormVersionAction,
    method: newSmartFormVersion,
  });

  return sendRequest(cfg, {}, cb);
};
