import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getShortedChatParticipantsNames } from 'helpers/formatters';

import styles from './styles.module.scss';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';

const Info = (props) => {
  const { className, names, info, chatType } = props;

  return (
    <div testid="info" className={classNames(styles.info, className)}>
      <div testid="participants_names" className={styles.names}>
        {getShortedChatParticipantsNames(names)}
      </div>
      <div className={styles.subheader}>
        {info && (
          <div testid="property_address" className={styles.address}>
            {info}
          </div>
        )}
        {chatType && (
          <span
            testid="chat_type"
            className={classNames(styles.chatType, {
              [styles.offer]: chatType === SOCKET_THREAD_TYPES.OFFER,
            })}
          >
            {chatType}
          </span>
        )}
      </div>
    </div>
  );
};

Info.propTypes = {
  className: PropTypes.string,
  names: PropTypes.arrayOf(PropTypes.string),
  info: PropTypes.string,
  chatType: PropTypes.string,
};

Info.defaultProps = {
  className: '',
  names: [],
  info: {},
  chatType: '',
};

export default Info;
