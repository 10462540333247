import { propertyStatusOptions } from 'settings/constants/properties';
import { getMinMaxAsStringValues } from 'components/MinMax/helpers';
import { sortLocations, sortStatus, sortStrings } from 'helpers';

import FilterForm from '../index';

const fieldsToStringify = ['PriceRange', 'SquareFeetRange', 'LotSizeRange', 'StoriesRange'];

export const mergeInitialValues = (search = {}, searchName) => {
  if (search.HomeType?.length) search.HomeType.sort(sortStrings);
  if (search.Locations?.length) search.Locations.sort(sortLocations);

  return {
    ...FilterForm.initialValues,
    ...search,
    ...(searchName ? { Name: searchName } : {}),
    HOARange: { Max: +search?.HOARange?.Max || '' },
    MaxDaysOnMarket: search?.MaxDaysOnMarket
      ? `${search?.MaxDaysOnMarket}`
      : FilterForm.initialValues.MaxDaysOnMarket,
    ...fieldsToStringify.reduce(
      (searchObj, fieldName) =>
        fieldName
          ? {
              ...searchObj,
              [fieldName]: getMinMaxAsStringValues(fieldName, search, FilterForm.initialValues),
            }
          : searchObj,
      {},
    ),
    Status: propertyStatusOptions
      .filter((option) => search.Status?.includes(option.value))
      .sort(sortStatus),
  };
};
