import React from 'react';
import type { ColumnsType } from 'antd/es/table';

import styles from './styles.module.scss';
import { Avatar } from 'components';
import { formatNameToAvatarLetters, subtractTimeZone } from 'helpers';
import moment from 'moment';
import { Button } from 'components-antd';
import { handleViewForm } from 'utils';
import { FormProcessTypes } from 'types';
import { Icons } from 'pages/Workshop/Forms/Icons';

interface DataType {
  formName: string;
  client: string;
  formProcessId: number;
  formDocumentId: number;
  formProcessPublicId: string;
  url: string;
  isSign: boolean;
  isEdit: boolean;
  date: Date;
  waitingOnYou: boolean;
  IsSequential: boolean;
  isFinalizing: boolean;
  type: FormProcessTypes;
  isOwner: boolean;
  isEditor: boolean;
  isSignatory: boolean;
}

export function tableColumns(history): ColumnsType<DataType> {
  const columns: ColumnsType<DataType> = [
    {
      title: 'Form',
      key: 'formName',
      dataIndex: 'formName',
      width: 450,
      render: (_, row) => {
        return (
          <div className={styles.name}>
            <Icons variant="file" />
            <div className={styles.info}>
              <span className={styles.line1}>{row?.formName}</span>
            </div>
          </div>
        );
      },
      sorter: (a, b) => a.formName?.localeCompare(b.formName),
    },
    {
      title: 'Requested',
      key: 'requested',
      dataIndex: 'date',
      width: 150,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (_, { date }) => {
        let formattedDate = date ? subtractTimeZone(date, 'M/DD/YYYY') : 'N/A';
        return (
          <div className={styles.dateWrapper}>
            <span className={styles.contentFirstRow}>{formattedDate}</span>
          </div>
        );
      },
    },
    {
      title: '',
      key: '',
      dataIndex: '',
      width: 75,
      render: (_, row) => {
        return (
          <div testid="sign_form" onClick={() => {}} className={styles.archive}>
            <Button
              variant="secondary-rounded"
              className={styles.signBtn}
              onClick={() => handleViewForm(history, row?.url, row?.type)}
            >
              <span>Sign</span>
            </Button>
          </div>
        );
      },
    },
  ];

  return columns;
}
