import { useEffect, useState } from 'react';
import { LocalStorage } from 'services';

export const useExternalVerificationRestriction = () => {
  const [token, setToken] = useState<string | null>(null);

  const [isTokenLoaded, setTokenLoaded] = useState<boolean | null>(null);

  useEffect(() => {
    const token = LocalStorage.getExternalToken();
    if (!token) {
      setTokenLoaded(true);
    } else {
      setToken(token);
      setTokenLoaded(true);
    }
  }, []);

  return {
    isTokenLoaded,
    token,
  };
};
