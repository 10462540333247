import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get, cloneDeep, orderBy } from 'lodash-es';
import classNames from 'classnames';
import { Checkbox } from 'antd';

import { Wrapper, Button } from 'components';
import { ArrowRight } from 'components/Icons';
import {
  getFilteredGroupedTaskTemplatesSelector,
  getTaskTemplatesSelector,
} from 'store/selectors/templates';
import { getAllTaskTemplatesEffect } from 'store/effects/templates';
import { setTransactionTaskParamsEffect } from 'store/effects/transactions';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { getUserDataSelector } from 'store/selectors/user';
import { getTransactionSelector } from 'store/selectors/transaction';
import NoTemplatesFound from './NoTemplates';
import { TaskFilter } from './TaskFilter';
import { getAssignees } from 'pages/Workshop/Transactions/TransactionCreate/helpers';
import { taskTemplateDateInitials } from 'app-constants/enums/taskTemplateDateInitials';
import styles from './styles.module.scss';
import { getDateOnly } from 'helpers';

const TaskTemplates = (props) => {
  const {
    className,
    isPreForm,
    onSubmit,
    isTransactionTask,
    allowMultiSelect,
    selectedTemplates,
    setSelectedTemplates,
  } = props;
  const templates = useSelector(getFilteredGroupedTaskTemplatesSelector);
  const { isPending, isIdle } = useSelector(getTaskTemplatesSelector);
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const {
    transaction: {
      Participants,
      EffectiveDate: transactionContractDate,
      ClosingDate: transactionClosingDate,
      ListingExpireDate: transactionExpiryDate,
    },
  } = useSelector(getTransactionSelector);
  const participants = isPreForm ? preForm?.participants || [] : Participants || [];
  const user = useSelector(getUserDataSelector);
  const users = [...participants, user];
  const participantOptions = users.filter((p) => p?.Id || p?.id);
  const [selected, setSelected] = useState({});
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();

  const [multiSelect, setMultiSelect] = useState(false);

  const selectedLength = Object.keys(selected)?.length;

  useEffect(() => {
    dispatch(getAllTaskTemplatesEffect({}, isIdle ? { silent: true } : null));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (
      allowMultiSelect &&
      !multiSelect &&
      selectedTemplates &&
      Object.keys(selectedTemplates)?.length &&
      !selectedLength
    ) {
      setSelected(cloneDeep(selectedTemplates));
      setMultiSelect(true);
    }
  }, [selectedTemplates]);

  useEffect(() => {
    if (!multiSelect && selectedLength) {
      updatedSelected({});
    }
  }, [multiSelect]);

  const isSelected = (templateId) => !!selected[templateId];

  const toggleTemplate = (template, data) => {
    const items = cloneDeep(data);
    if (items[template.Id]) delete items[template.Id];
    else items[template.Id] = template;

    updatedSelected({ ...items });
  };

  const updatedSelected = (items) => {
    setSelected({ ...items });

    if (setSelectedTemplates) {
      setSelectedTemplates({ ...items });
    }
  };

  const multiSelectMode = !!(allowMultiSelect && multiSelect);

  const getTemplateDueDate = (dueDate, operator, initial) => {
    if (dueDate && operator && initial) {
      if (initial === taskTemplateDateInitials.ClosingDate && transactionClosingDate) {
        if (operator === '+') {
          return moment(transactionClosingDate).add(dueDate, 'days').toDate();
        } else {
          return moment(transactionClosingDate).subtract(dueDate, 'days').toDate();
        }
      } else if (initial === taskTemplateDateInitials.ContractDate && transactionContractDate) {
        if (operator === '+') {
          return moment(transactionContractDate).add(dueDate, 'days').toDate();
        } else {
          return moment(transactionContractDate).subtract(dueDate, 'days').toDate();
        }
      } else if (
        initial === taskTemplateDateInitials.ListingAgreementExpiryDate &&
        transactionExpiryDate
      ) {
        if (operator === '+') {
          return moment(transactionExpiryDate).add(dueDate, 'days').toDate();
        } else {
          return moment(transactionExpiryDate).subtract(dueDate, 'days').toDate();
        }
      } else if (
        initial === taskTemplateDateInitials.AssigningDate ||
        initial === taskTemplateDateInitials.Today
      ) {
        if (operator === '+') {
          return moment().add(dueDate, 'days').toDate();
        } else {
          return moment().subtract(dueDate, 'days').toDate();
        }
      } else {
        return moment().toDate();
      }
    } else if (multiSelectMode) {
      return moment().add(2, 'days').toDate();
    }

    return null;
  };

  const formattedTask = (template) => {
    const dueDate = get(template, 'DueDate');
    const checklists = (get(template, 'Checklists') || []).map(({ Name, Required, Id }) => ({
      Name,
      Required,
      Id,
    }));

    if (isTransactionTask) {
      return {
        Name: get(template, 'Name') || '',
        DueDate: dueDate,
        Initial: get(template, 'Initial'),
        Operator: get(template, 'Operator'),
        ControlOperatorOffset: get(template, 'ControlOperatorOffset'),
        Category: get(template, 'Category.Name') || '',
        Description: get(template, 'Description') || '',
        Checklists: orderBy(checklists, ['Id'], ['asc']),
        Links: get(template, 'Links') || [],
        Documents: get(template, 'Documents') || [],
        AssigneeRoles: get(template, 'AssigneeRoles'),
        CcRoles: get(template, 'CcRoles'),
        People: get(template, 'People'),
        Teams: get(template, 'Teams'),
        Locations: get(template, 'Locations'),
      };
    } else {
      return {
        Title: get(template, 'Name') || '',
        DueDate: getDateOnly(
          getTemplateDueDate(dueDate, get(template, 'Operator'), get(template, 'Initial')),
        ),
        Category: get(template, 'Category.Name') || '',
        Description: get(template, 'Description') || '',
        Checklists: orderBy(checklists, ['Id'], ['asc']),
        Links: get(template, 'Links') || [],
        Documents: get(template, 'Documents') || [],
        AssigneeList: getAssignees(template, 'AssigneeRoles', participantOptions),
        CcList: getAssignees(template, 'CcRoles', participantOptions),
        TaskTemplateInitial: get(template, 'Initial'),
        TaskTemplateOperator: get(template, 'Operator'),
        TaskTemplateDateOffset: dueDate,
        TaskTemplateInitialOperatorOffset:
          get(template, 'Initial') + get(template, 'Operator') + dueDate,
      };
    }
  };

  const onTemplateSelect = useCallback(
    (template, data) => {
      if (isPreForm || multiSelectMode) toggleTemplate(template, data);
      else {
        dispatch(
          setTransactionTaskParamsEffect({
            formValues: formattedTask(template),
            isTaskForm: true,
          }),
        );
        onSubmit();
      }
    },
    [dispatch, multiSelectMode],
  );

  const handleMultiTaskSubmit = () => {
    const formValues = Object.values(selected).map((item) => formattedTask(item));
    if (Object.values(selected)?.length && onSubmit) {
      onSubmit({
        formValues,
        assignTo: true,
      });
    }
  };

  return (
    <Wrapper isPending={isPending} className={classNames(styles.wrapper, className)}>
      {(isTransactionTask || allowMultiSelect) && (
        <TaskFilter
          {...(allowMultiSelect
            ? {
                allowMultiSelect,
                updateMultiSelect: () => {
                  setMultiSelect(!multiSelect);
                },
                multiSelectActive: multiSelect,
              }
            : {})}
        />
      )}
      {Object.keys(templates).length ? (
        <>
          <div className={styles.templatesContainer}>
            {Object.keys(templates).map((groupName) =>
              templates?.[groupName]?.length ? (
                <div testid="template_group" className={styles.group} key={groupName}>
                  <h3 testid="group_name" className={styles.category}>
                    {groupName}
                  </h3>
                  {templates?.[groupName]
                    ?.filter(
                      (temp) =>
                        temp.Name.toLowerCase().includes(query) || temp.Name.includes(query),
                    )
                    .map((template) => (
                      <div
                        key={template?.Id}
                        className={styles.template}
                        onClick={() => onTemplateSelect(template, selected)}
                        testid="template_item"
                      >
                        <div>
                          {isPreForm ? (
                            <Checkbox
                              className={styles.checkbox}
                              checked={isSelected(template.Id)}
                              onClick={() => onTemplateSelect(template, selected)}
                            />
                          ) : (
                            <Icon variant={Icon.TASK_TEMPLATE} className={styles.icon} />
                          )}

                          <span testid="template_name">{template?.Name}</span>
                        </div>
                        {!isPreForm && !multiSelectMode && (
                          <ArrowRight className={styles.arrowIcon} />
                        )}
                        {multiSelectMode && (
                          <Checkbox
                            className={classNames(styles.checkbox, styles.multiSelectCheckbox)}
                            checked={isSelected(template.Id)}
                          />
                        )}
                      </div>
                    ))}
                </div>
              ) : null,
            )}
          </div>
          {isPreForm ? (
            <Button
              title={`Add Tasks`}
              onClick={() => onSubmit(Object.values(selected).map((item) => formattedTask(item)))}
              className={styles.submitBtn}
              titleClassName={styles.buttonTitle}
              disabled={!Object.keys(selected).length}
            />
          ) : null}
          {multiSelectMode ? (
            <Button
              title={`Add ${selectedLength ? ' ' + selectedLength + ' ' : ' '}${
                selectedLength > 1 ? 'Tasks' : 'Task'
              }`}
              onClick={handleMultiTaskSubmit}
              className={classNames(styles.submitBtn, styles.multiBtn)}
              titleClassName={styles.buttonTitle}
              disabled={!Object.keys(selected).length}
            />
          ) : null}
        </>
      ) : (
        <NoTemplatesFound className={styles.noTemplates} templatesName="task" />
      )}
    </Wrapper>
  );
};

TaskTemplates.propTypes = {
  className: PropTypes.string,
  isPreForm: PropTypes.bool,
  isTransactionTask: PropTypes.bool,
  onSubmit: PropTypes.func,
  allowMultiSelect: PropTypes.bool,
  selectedTemplates: PropTypes.object,
  setSelectedTemplates: PropTypes.func,
};

TaskTemplates.defaultProps = {
  className: '',
  isPreForm: false,
  isTransactionTask: false,
  onSubmit: () => {},
  allowMultiSelect: false,
  selectedTemplates: {},
  setSelectedTemplates: undefined,
};

export default TaskTemplates;
