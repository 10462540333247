import styles from './styles.module.scss';
import { Avatar, Switch } from 'components-antd';
import { useState } from 'react';
import moment from 'moment';
import { blacklistClientByKitId } from 'api/kits';

type EligibilityDataRowPropsType = {
  item;
  kitId: number;
  mode: 'Active' | 'Eligible' | 'Not Eligible';
  kitCreatedDate: Date;
};

function EligibilityDataRow(props: EligibilityDataRowPropsType) {
  const { item, mode, kitId } = props;
  const [eligibleEnabled, setEligibleEnabled] = useState<boolean>(false);
  const [activeEnabled, setActiveEnabled] = useState<boolean>(true);

  const getAddress = (address: string) => {
    if (address) {
      let firstCommaIndex = address.indexOf(',');
      let firstPart = address.slice(0, firstCommaIndex);
      let secondPart = address.slice(firstCommaIndex + 1);
      return (
        <div className={styles.addressBox}>
          <div className={styles.address}>{firstPart}</div>
          {secondPart ? <div className={styles.addressText}>{secondPart}</div> : <></>}
        </div>
      );
    }
    return '-';
  };

  if (mode === 'Active')
    return (
      <tr className={styles.rowData}>
        <td>
          <div className={styles.avatar}>
            <Avatar
              className={''}
              size={'default'}
              src={item.AvatarUrl}
              alt={item.FirstName}
              shape={'circle'}
            >
              {item.FirstName[0] + (item.LastName ? item.LastName[0] : '')}
            </Avatar>
            <div className={styles.avatarName}>
              {item.FirstName + (item.LastName ? ' ' + item.LastName : '')}
            </div>
          </div>
        </td>
        <td>
          <div className={styles.text}>{getAddress(item.Address?.address)}</div>
        </td>
        <td>
          <div className={styles.text}>{moment(props.kitCreatedDate).format('M/D/YYYY')}</div>
        </td>
        <td>
          <div className={styles.text}>{item.Sent}</div>
        </td>
        <td>
          <div className={styles.text}>{item.Opened}</div>
        </td>
        <td>
          <div className={styles.text}>{item.Clicked}</div>
        </td>
        <td>
          <div className={styles.text}>
            <Switch
              className={styles.switch}
              checked={activeEnabled}
              defaultChecked={activeEnabled}
              onChange={(v) => {
                setActiveEnabled(v);
                blacklistClientByKitId(kitId, {
                  ClientId: item.ClientId,
                  Blacklist: v ? false : true,
                });
              }}
            />
          </div>
        </td>
      </tr>
    );
  else if (mode === 'Eligible')
    return (
      <tr className={styles.rowData}>
        <td>
          <div className={styles.avatar}>
            <Avatar
              className={''}
              size={'default'}
              src={item.AvatarUrl}
              alt={item.FirstName}
              shape={'circle'}
            >
              {item.FirstName[0] + (item.LastName ? item.LastName[0] : '')}
            </Avatar>
            <div className={styles.avatarName}>
              {item.FirstName + (item.LastName ? ' ' + item.LastName : '')}
            </div>
          </div>
        </td>
        <td>
          <div className={styles.text}>{getAddress(item.Address?.address)}</div>
        </td>
        <td>
          <div className={styles.text}>{moment(props.kitCreatedDate).format('M/D/YYYY')}</div>
        </td>
        <td>
          <div className={styles.text}>{moment(item.DeactivatedDate).format('M/D/YYYY')}</div>
        </td>
        <td>
          <div className={styles.text}>{item.Sent}</div>
        </td>
        <td>
          <div className={styles.text}>{item.Opened}</div>
        </td>
        <td>
          <div className={styles.text}>
            <Switch
              className={styles.switch}
              checked={eligibleEnabled}
              defaultChecked={eligibleEnabled}
              onChange={(v) => {
                setEligibleEnabled(v);
                blacklistClientByKitId(props.kitId, {
                  ClientId: item.ClientId,
                  Blacklist: v ? false : true,
                });
              }}
            />
          </div>
        </td>
      </tr>
    );
  else if (mode === 'Not Eligible')
    return (
      <tr className={styles.rowData}>
        <td>
          <div className={styles.avatar}>
            <Avatar
              className={''}
              size={'default'}
              src={item.AvatarUrl}
              alt={item.FirstName}
              shape={'circle'}
            >
              {item.FirstName[0] + (item.LastName ? item.LastName[0] : '')}
            </Avatar>
            <div className={styles.avatarName}>
              {item.FirstName + (item.LastName ? ' ' + item.LastName : '')}
            </div>
          </div>
        </td>
        <td>
          <div className={styles.text}>{getAddress(item.Address?.address)}</div>
        </td>
        <td>
          <div className={styles.text}>{item.Notes}</div>
        </td>
      </tr>
    );
  else return <></>;
}

export default EligibilityDataRow;
