import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { cloneDeep, get } from 'lodash-es';

import { requestGetSymphonyListAction } from 'store/actions/symphony';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetSymphonyListAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data', initialData.data) || state.data,
      meta: get(payload, 'meta', initialData.meta),
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
