import React from 'react';

const AddCircle = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="12" fill="#FF576D" />
    <path d="M7 12H17" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path d="M12 17L12 7" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
export default AddCircle;
