import { createSelector } from 'reselect';
import { get } from 'lodash-es';

import { ViewModes } from 'app-constants';
import { AggregatedPageTypes } from 'types/aggregatedPageTypes';

const localState = ({ workshop }) => workshop;

export const getViewModeSelector = createSelector(localState, (state) =>
  get(state, 'viewMode', ViewModes.List),
);

export const getAggregatePageTypeSelector = createSelector(localState, (state) =>
  get(state, 'aggregatePageType', AggregatedPageTypes.All),
);
