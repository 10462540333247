import { useState } from 'react';
import { useSelector } from 'react-redux';

import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { TermsAndConditionsModal } from 'pages/FormProcess/components';
import { getDynamicFormSignatureConfigSelector } from 'store/selectors/requestFormProcess';

export const FormWidget = () => {
  const { showTermsAndConditions } = useSelector(getDynamicFormSignatureConfigSelector);

  const [loading, setLoading] = useState(false);

  const handleAgree = () => {
    dynamicManager.handleAgree(setLoading);
  };

  return (
    <>
      {showTermsAndConditions && (
        <TermsAndConditionsModal loading={loading} handleAgree={handleAgree} />
      )}
    </>
  );
};
