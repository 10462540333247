import React from 'react';

import classNames from 'classnames';

import { Icons } from 'pages/Workshop/Icons';
import { Icons as TransactionsIcons } from 'pages/Workshop/Transactions/Icons';
import { Popover } from 'components-antd';

import styles from './styles.module.scss';
import { Role } from 'settings/constants/roles';
import { transactionStatuses } from 'settings/constants/transaction';

const alertsPopover = (transactionData) => {
  const overdueTasksCount = transactionData?.OverdueTasksCount;
  const overdueMilestonesCount = transactionData?.OverdueMilestonesCount;
  const newOffersCount = transactionData?.NewOffersCount;

  const tasksClosingInfo =
    overdueTasksCount > 1
      ? `${overdueTasksCount} tasks overdue`
      : `${overdueTasksCount} task overdue`;
  const milestonesClosingInfo =
    overdueMilestonesCount > 1
      ? `${overdueMilestonesCount} milestones overdue`
      : `${overdueMilestonesCount} milestone overdue`;
  const newOffersInfo =
    newOffersCount > 1 ? `${newOffersCount} new offers` : `${newOffersCount} new offer`;

  return (
    <div className={styles.notifications_popover}>
      {newOffersCount > 0 && (
        <div className={styles.newOffersInfo}>
          <TransactionsIcons
            variant={TransactionsIcons.DOCUMENT}
            className={classNames(styles.iconPopOver)}
          />
          <span>{newOffersInfo}</span>
        </div>
      )}
      {overdueTasksCount > 0 && (
        <div className={styles.closingInfo}>
          <Icons variant={Icons.ALERT} className={styles.iconPopOver} />
          <span>{tasksClosingInfo}</span>
        </div>
      )}
      {overdueMilestonesCount > 0 && (
        <div className={styles.closingInfo}>
          <Icons variant={Icons.ALERT} className={styles.iconPopOver} />
          <span>{milestonesClosingInfo}</span>
        </div>
      )}
    </div>
  );
};

const OffersEnabledNotification = () => (
  <div>
    <Icons variant={Icons.GREEN_LIGHT_CHECKMARK} className={styles.iconPopOver} />
    <span>Offers Enabled</span>
  </div>
);

const ShowingsEnabledNotification = () => (
  <div>
    <Icons variant={Icons.GREEN_LIGHT_CHECKMARK} className={styles.iconPopOver} />
    <span>Showings Enabled</span>
  </div>
);

const offersEnabledPopover = () => (
  <div className={styles.notifications_popover}>
    <OffersEnabledNotification />
  </div>
);

const showingsEnabledPopover = () => (
  <div className={styles.notifications_popover}>
    <ShowingsEnabledNotification />
  </div>
);

const offersAndShowingsEnabledPopover = () => (
  <div className={styles.notifications_popover}>
    <OffersEnabledNotification />
    <ShowingsEnabledNotification />
  </div>
);

const Notifications = ({ transactionData, userRole }) => {
  const offersEnabled = transactionData?.OfferLink?.EnableLink;
  const showingsEnabled = transactionData?.Showing?.EnableShowingLink;
  const overdueAlerts =
    transactionData?.OverdueTasksCount +
    transactionData?.OverdueMilestonesCount +
    transactionData?.NewOffersCount;

  const { Closed, Canceled } = transactionStatuses;

  const shouldHideOverdue =
    transactionData?.Status === Closed || transactionData?.Status === Canceled;

  return (
    <div testid="notifications" className={classNames(styles.notifications)}>
      {(() => {
        if (userRole === Role.Client) return null;
        if (offersEnabled && showingsEnabled) {
          return (
            <Popover
              content={offersAndShowingsEnabledPopover}
              placement="top"
              overlayClassName={classNames('mosaikTooltip')}
              getPopupContainer={(triggerNode) => triggerNode}
            >
              <div>
                <Icons variant={Icons.GREEN_LIGHT_DOUBLE_CHECKMARK} className={styles.icon} />
              </div>
            </Popover>
          );
        } else if (offersEnabled) {
          return (
            <Popover
              content={offersEnabledPopover}
              placement="top"
              overlayClassName={classNames('mosaikTooltip')}
              getPopupContainer={(triggerNode) => triggerNode}
            >
              <div>
                <Icons variant={Icons.GREEN_LIGHT_CHECKMARK_BG} className={styles.icon} />
              </div>
            </Popover>
          );
        } else if (showingsEnabled) {
          return (
            <Popover
              content={showingsEnabledPopover}
              placement="top"
              overlayClassName={classNames('mosaikTooltip')}
              getPopupContainer={(triggerNode) => triggerNode}
            >
              <div>
                <Icons variant={Icons.GREEN_LIGHT_CHECKMARK_BG} className={styles.icon} />
              </div>
            </Popover>
          );
        }
      })()}
      {!!overdueAlerts && !shouldHideOverdue && (
        <Popover
          content={alertsPopover(transactionData)}
          placement="top"
          overlayClassName={classNames('mosaikTooltip')}
          getPopupContainer={(triggerNode) => triggerNode}
        >
          <div testid="alerts" className={styles.alert}>
            <Icons variant="alertBg" className={styles.icon} />
            <div className={styles.alertLabelContainer}>
              <span className={styles.alertLabel}>{overdueAlerts}</span>
            </div>
          </div>
        </Popover>
      )}
      {(overdueAlerts === 0 || shouldHideOverdue) && <div className={styles.emptyContainer} />}
    </div>
  );
};

export default Notifications;
