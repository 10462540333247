import { FC } from 'react';

import { Add } from 'components/Icons';

import styles from './styles.module.scss';

interface Props {
  text: string;
  onClick?: () => void;
}

export const TextIconButton: FC<Props> = ({ text, onClick }) => {
  return (
    <div className={styles.addAnother} onClick={onClick}>
      <Add className={styles.addIcon} color={Add.ORANGE_CIRCLE} />
      {text ? <span>{text}</span> : null}
    </div>
  );
};
