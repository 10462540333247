import PropTypes from 'prop-types';

const Timelines = ({ className }) => (
  <div className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_29815_26242)">
        <path
          d="M11 6H20"
          stroke="#AAABAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 12H20"
          stroke="#AAABAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 18H20"
          stroke="#AAABAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="5.5" cy="6" r="1.5" stroke="#AAABAB" strokeWidth="2" />
        <circle cx="5.5" cy="12" r="1.5" stroke="#AAABAB" strokeWidth="2" />
        <circle cx="5.5" cy="18" r="1.5" stroke="#AAABAB" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_29815_26242">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

Timelines.propTypes = {
  className: PropTypes.string,
};

Timelines.defaultProps = {
  className: '',
};

export default Timelines;
