import { createSelector } from 'reselect';

const localState = ({ mlsAccess }) => mlsAccess;

export const isMlsAccessModalOpenedSelector = createSelector(
  localState,
  (localState) => localState.isModalOpened as boolean,
);

export const showMlsAccessModalOnboardingTextSelector = createSelector(
  localState,
  (localState) => localState.showOnboardingText as boolean,
);

export const hasMlsAccessSelector = createSelector(
  localState,
  (localState) => localState.hasMlsAccess as boolean,
);
