import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getFormMetaSelector } from 'store/selectors/requestFormProcess';

import { caseInsensitiveFilter, showSuccessMessage } from 'helpers';

import { Select, Button, Tooltip } from 'components-antd';
import { ConfirmationDialog } from 'components';

import { LinkOptions } from './LinkOptions';
import { Icons } from './LinkOptions/Icons';
import { Avatar } from 'components/Icons';
import { Transaction } from 'components/Icons/Trancation';

import styles from './styles.module.scss';
import { FieldContainer } from 'pages/FormProcess/components/PreForm/ClientOrTransaction/components/FieldContainer';
import {
  getClientNamesFromClients,
  getClientOptions,
  getTransactionOptions,
} from 'pages/FormProcess/components/PreForm/ClientOrTransaction';
import { linkToFormProcessEffect, unlinkFormProcessEffect } from 'store/effects/formProcess';

export const FormLink = ({
  formProcessId,
  formDetails,
  refetchFormDetails,
  setDisableSections,
  viewOnly = false,
}) => {
  const dispatch = useDispatch();
  const { clients, transactions } = useSelector(getFormMetaSelector);
  const [editTransactionField, setEditTransactionField] = useState(false);
  const [editClientField, setEditClientField] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [clientIds, setClientIds] = useState<any>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationProps, setConfirmationProps] = useState({
    title: '',
    message: <></>,
    type: '',
  });
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  useEffect(() => {
    if (formDetails?.transactionId) setTransactionId(formDetails?.transactionId);
    if (formDetails?.client) {
      setClientIds({
        clientIds: formDetails?.clientDetails?.map((item) => item?.userId),
        clientName: formDetails?.clientDetails?.map((item) => item?.userName),
      });
    }
  }, [formDetails]);

  const LINK_OPTIONS = {
    transaction: {
      label: 'Transaction',
      icon: <Icons variant={Icons.TRANSACTION} />,
      onClick: () => {
        setEditTransactionField(true);
        setDisableSections(true);
      },
    },
    client: {
      label: 'Client',
      icon: <Icons variant={Icons.CLIENT} />,
      onClick: () => {
        setEditClientField(true);
        setDisableSections(true);
      },
    },
  };

  const onUpdate = async () => {
    setUpdateLoading(true);
    dispatch(
      linkToFormProcessEffect(
        {
          formProcessIds: [formProcessId],
          ...(transactionId ? { transactionId } : {}),
          clientIds: clientIds?.clientIds ?? [],
        },
        (err) => {
          if (!err) {
            refetchFormDetails();
            setEditTransactionField(false);
            setEditClientField(false);
            setDisableSections(false);
            showSuccessMessage('Form linked successfully.');
          }
          setUpdateLoading(false);
        },
      ),
    );
  };

  const unlinkForm = async () => {
    setConfirmationLoading(true);
    dispatch(
      unlinkFormProcessEffect(
        {
          formProcessId: formProcessId,
          ...(confirmationProps?.type === 'transaction' ? { transactionId } : {}),
          ...(confirmationProps?.type === 'client'
            ? { clientIds: clientIds?.clientIds } ?? []
            : {}),
        },
        (err) => {
          if (!err) {
            refetchFormDetails();
            setShowConfirmationModal(false);
            showSuccessMessage('Form unlinked successfully.');
          }
          setConfirmationLoading(false);
        },
      ),
    );
  };

  const linkOptions = [
    !formDetails?.transactionId ? LINK_OPTIONS.transaction : null,
    !formDetails?.client ? LINK_OPTIONS.client : null,
  ].filter(Boolean);

  const TRANSACTION_OPTIONS = [
    {
      label: 'Edit',
      icon: <Icons variant={Icons.EDIT} />,
      onClick: () => {
        setEditTransactionField(true);
        setDisableSections(true);
      },
    },
    {
      label: 'Delete',
      icon: <Icons variant={Icons.DELETE} />,
      onClick: () => {
        setConfirmationProps({
          title: 'Unlink',
          message: <>Are you sure you want to unlink transaction from this Form?</>,
          type: 'transaction',
        });
        setShowConfirmationModal(true);
      },
    },
  ];

  const CLIENT_OPTIONS = [
    {
      label: 'Edit',
      icon: <Icons variant={Icons.EDIT} />,
      onClick: () => {
        setEditClientField(true);
        setDisableSections(true);
      },
    },
    {
      label: 'Delete',
      icon: <Icons variant={Icons.DELETE} />,
      onClick: () => {
        setConfirmationProps({
          title: 'Unlink',
          message: <>Are you sure you want to unlink client(s) from this Form?</>,
          type: 'client',
        });
        setShowConfirmationModal(true);
      },
    },
  ];

  return (
    <div className={styles.formLinkContainer}>
      {formDetails?.transactionId && !editTransactionField && (
        <div className={styles.fieldContainerWrapper}>
          <div className={styles.content}>
            <Transaction />
            <FieldContainer label="Transaction" inputClass={styles.multipleSelectInput}>
              <p>{formDetails?.address}</p>
            </FieldContainer>
          </div>
          {!viewOnly && (
            <LinkOptions
              title={<Icons variant={Icons.DOTS} />}
              options={TRANSACTION_OPTIONS}
              className={styles.options}
            />
          )}
        </div>
      )}

      {editTransactionField && (
        <div className={styles.fieldContainerWrapper}>
          <Transaction />
          <FieldContainer label="Transaction" inputClass={styles.multipleSelectInput}>
            <Select
              value={transactionId}
              labelInValue
              onChange={(option) => setTransactionId(option.value)}
              showSearch
              size="large"
              className={classNames(styles.listBoxInput, styles.transactionListInput)}
              options={getTransactionOptions(transactions)}
              filterOption={(inputValue, option) =>
                caseInsensitiveFilter(inputValue, option?.props?.label)
              }
              placeholder="Select Transaction"
            />
            <div className={styles.buttonsContainer}>
              <Button
                variant="secondary"
                size="large"
                onClick={onUpdate}
                loading={updateLoading}
                disabled={updateLoading}
              >
                {formDetails?.transactionId ? 'Update' : 'Add'}
              </Button>
              <Button
                variant="hollow-bordered"
                size="large"
                onClick={() => {
                  setEditTransactionField(false);
                  setDisableSections(false);
                  setTransactionId(formDetails?.transactionId);
                }}
              >
                Cancel
              </Button>
            </div>
          </FieldContainer>
        </div>
      )}
      {formDetails?.client && !editClientField && (
        <div className={styles.fieldContainerWrapper}>
          <div className={styles.content}>
            <Avatar width={32} height={32} />
            <FieldContainer label="Client" inputClass={styles.multipleSelectInput}>
              <p>{formDetails?.client}</p>
            </FieldContainer>
          </div>

          {!viewOnly && (
            <LinkOptions
              title={<Icons variant={Icons.DOTS} />}
              options={CLIENT_OPTIONS}
              className={styles.options}
            />
          )}
        </div>
      )}
      {editClientField && (
        <div className={styles.fieldContainerWrapper}>
          <Avatar width={32} height={32} />
          <FieldContainer label="Client" inputClass={styles.multipleSelectInput}>
            <Select
              mode="multiple"
              value={clientIds?.clientIds}
              onChange={(option) => {
                setClientIds({
                  clientIds: option,
                  clientName: getClientNamesFromClients(option, clients),
                });
              }}
              showSearch
              size="large"
              className={styles.listBoxInput}
              options={getClientOptions(clients)}
              filterOption={(inputValue, option) =>
                caseInsensitiveFilter(inputValue, option?.props?.label)
              }
              maxTagCount={'responsive'}
              maxTagPlaceholder={(omittedValues) => (
                <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                  <span>{`+${omittedValues.length}`}</span>
                </Tooltip>
              )}
              placeholder="Select Client(s)"
            />
            <div className={styles.buttonsContainer}>
              <Button
                variant="secondary"
                size="large"
                onClick={onUpdate}
                loading={updateLoading}
                disabled={updateLoading}
              >
                {formDetails?.client ? 'Update' : 'Add'}
              </Button>
              <Button
                variant="hollow-bordered"
                size="large"
                onClick={() => {
                  setEditClientField(false);
                  setClientIds({
                    clientIds: formDetails?.clientDetails?.map((item) => item?.userId),
                    clientName: formDetails?.clientDetails?.map((item) => item?.userName),
                  });
                  setDisableSections(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </FieldContainer>
        </div>
      )}

      {(!formDetails?.transactionId || !formDetails?.client) && !viewOnly && (
        <LinkOptions title="+ Link" options={linkOptions} className={styles.marginTop} />
      )}
      <ConfirmationDialog
        onReject={() => setShowConfirmationModal(false)}
        onConfirm={() => unlinkForm()}
        isOpen={showConfirmationModal}
        confirmText={confirmationProps?.title}
        isPending={confirmationLoading}
        className={styles.unlinkFormDialog}
      >
        <div className={styles.content}>{confirmationProps?.message}</div>
      </ConfirmationDialog>
    </div>
  );
};
