import { FormView } from 'app-constants';
import { createSelector } from 'reselect';

import { FormBuilderType } from 'types';

const localState = ({ formBuilder }) => formBuilder.data;

export const getSmartFormMetaSelect = createSelector(
  localState,
  ({
    smartBuilder: {
      disabled,
      folderName,
      tags,
      state,
      isFavorite,
      formName,
      status,
      formId,
      activeQuestionIndex,
      questions,
      answers,
      Id,
      lastSaved,
      lastUpdated,
      locations,
      disassociatedFields,
      highlightedFields,
      link,
      users,
      formCategoryTags,
      questionRoles,
      views,
      teams,
      isCAR,
    },
  }: FormBuilderType) => {
    return {
      disabled,
      folderName,
      tags,
      state,
      isFavorite,
      formName,
      status,
      formId,
      activeQuestionIndex,
      questions,
      answers,
      Id,
      lastSaved,
      lastUpdated,
      locations,
      disassociatedFields,
      highlightedFields,
      link,
      users,
      formCategoryTags,
      questionRoles,
      views,
      wizardViewEnabled: views?.includes(FormView.Wizard),
      teams,
      isCAR,
    };
  },
);

export const getSmartFormCurrentQuestionSelect = createSelector(
  localState,
  ({ smartBuilder: { questions, activeQuestionIndex } }: FormBuilderType) => {
    return activeQuestionIndex !== undefined ? questions[activeQuestionIndex] : undefined;
  },
);

export const getSmartFormSimilarQuestionsTypeSelect = createSelector(
  localState,
  ({ smartBuilder: { questions, activeQuestionIndex } }: FormBuilderType) => {
    return activeQuestionIndex !== undefined
      ? questions.filter((q) => q.FieldType === questions[activeQuestionIndex].FieldType)
      : [];
  },
);
