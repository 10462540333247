import styles from './styles.module.scss';
import { MenuDots } from 'components/Icons';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function PropertyAvatar(props) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuIconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      menuIconRef.current &&
      !menuIconRef.current.contains(event.target)
    ) {
      setShowMenu(false);
    }
  };

  const sentenceCase = (sentence) => {
    const words = sentence.split(' ');
    const capitalizedWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return firstLetter + restOfWord;
    });

    return capitalizedWords.join(' ');
  };

  if (!props.empty)
    return (
      <div className={styles.avatar + ' ' + styles[props.countClass]}>
        <div className={styles.photoDiv}>
          {props.photo ? (
            <img
              className={styles.photo + ' ' + styles['photo' + props.countClass]}
              src={props.photo}
              alt={props.address}
            />
          ) : (
            <div
              className={
                styles.photo + ' ' + styles['photo' + props.countClass] + ' ' + styles.noPhoto
              }
            >
              <div className={styles.photoDivWrap}>
                <p className={styles.noPhotoText}>No&nbsp;</p>
                <p className={styles.noPhotoText}>Photos</p>
              </div>
            </div>
          )}
        </div>
        <div>
          <p className={styles.address + ' ' + styles['address' + props.countClass]}>
            {sentenceCase(props.address)}
          </p>
          <p className={styles.zip + ' ' + styles['zip' + props.countClass]}>
            {sentenceCase(props.city) + ', ' + props.state + ' ' + props.zip}
          </p>
        </div>
      </div>
    );
  else
    return (
      <div className={styles.avatar}>
        <div className={styles.photoDiv}>
          <div
            className={
              styles.photo +
              ' ' +
              styles['photo' + props.countClass] +
              ' ' +
              styles.noPhoto +
              ' ' +
              styles.empty
            }
          ></div>
        </div>
      </div>
    );
}

PropertyAvatar.propTypes = {
  index: PropTypes.number,
  propertyId: PropTypes.string,
  photo: PropTypes.string,
  countClass: PropTypes.string,
  address: PropTypes.string,
  photoCount: PropTypes.number,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  removePropertyHandler: PropTypes.any,
  currentPropertyCount: PropTypes.number,
  commentOnProperty: PropTypes.any,
  shareProperty: PropTypes.any,
  setFavoriteStatus: PropTypes.any,
  favoriteStatus: PropTypes.bool,
  favoriteFetchStatus: PropTypes.string,
  empty: PropTypes.bool,
};

PropertyAvatar.defaultProps = {
  index: 0,
  propertyId: '',
  photo: '',
  countClass: '',
  address: '',
  photoCount: 0,
  city: '',
  state: '',
  zip: '',
  removePropertyHandler: () => {},
  currentPropertyCount: 0,
  commentOnProperty: () => {},
  shareProperty: () => {},
  setFavoriteStatus: () => {},
  favoriteStatus: false,
  favoriteFetchStatus: '',
  empty: false,
};

export default PropertyAvatar;
