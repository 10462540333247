import { Icons } from 'pages/Workshop/Icons';
import { Navigation } from '../../../../../Workshop/Transactions/TransactionOffers/OfferModal/components';

import styles from './styles.module.scss';
import { Col, Row } from 'antd';

export const SubmitOffer = ({ onNext }) => {
  const onSubmit = () => {
    onNext();
  };

  return (
    <div className={styles.wrapper}>
      <Icons variant={Icons.CONCIERGE_ATTORNEY} className={styles.icon} />
      <button className={styles.btn} onClick={onSubmit} title="Submit Offer" testid="submit_offer">
        <span className={styles.btnTxt}>Submit Offer</span>
      </button>
    </div>
  );
};
