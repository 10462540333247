import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import { getAgentClientsSelector } from 'store/selectors/feed';
import { Avatar } from 'components';

import styles from './styles.module.scss';

const UserInfo = (props) => {
  const { className, clientId } = props;
  const clientFavorites = useSelector(getAgentClientsSelector);

  const renderInfo = () => {
    const {
      AvatarUrl,
      FirstName = '',
      LastName = '',
      TotalFavorites,
    } = clientFavorites?.data?.find((user) => user?.Id === clientId) || {};

    if (!FirstName && !LastName) {
      return null;
    }

    return (
      <div className={styles.infoWrapper}>
        <Avatar
          src={AvatarUrl}
          className={styles.avatar}
          placeholder={convertNameToAvatarPlaceholder(`${FirstName} ${LastName}`)}
        />
        <div className={styles.text}>
          <p testid="buyer_name" className={styles.name}>{`${FirstName} ${LastName}`}</p>
          <p
            testid="buyer_favorites"
            className={styles.description}
          >{`${TotalFavorites} favorites`}</p>
        </div>
      </div>
    );
  };

  return <div className={classNames(styles.userInfo, className)}>{renderInfo()}</div>;
};

UserInfo.propTypes = {
  className: PropTypes.string,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

UserInfo.defaultProps = {
  className: '',
};

export default UserInfo;
