import { handleActions } from 'redux-actions';
import { socketsResetAction } from 'store/actions/sockets';
import {
  socketsStoreInitNotificationsAction,
  socketsStoreNewNotificationAction,
} from 'store/actions/sockets/notifications';
import { requestMarkNotificationAsReadAction } from 'store/actions/notifications';
import { cloneDeep, get } from 'lodash-es';

const initialData = [];

export default handleActions(
  {
    [socketsStoreInitNotificationsAction]: (state, { payload }) => get(payload, 'data'),
    [socketsStoreNewNotificationAction]: (state, { payload }) => {
      const newNotification = get(payload, 'data', {});
      return [...state, newNotification];
    },
    [requestMarkNotificationAsReadAction]: (state, { payload }) => {
      const data = get(payload, 'data');
      const clonedNotifications = cloneDeep(state);

      data?.forEach((notific) => {
        const updatedNotificationIndex = (state || []).findIndex(
          (notification) => notification?.Id === notific?.Id,
        );

        if (updatedNotificationIndex !== -1) {
          clonedNotifications[updatedNotificationIndex] = notific;
        }
      });

      return clonedNotifications;
    },
    [socketsResetAction]: () => initialData,
  },
  initialData,
);
