import React from 'react';

const PlanShield = ({ ...props }: React.SVGAttributes<any>) => (
  <svg
    className={props.className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 12L11 14L15 10"
      stroke="#FF576D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 3C14.3392 5.06658 17.3922 6.14257 20.5122 6C20.9664 7.54302 21.1054 9.16147 20.9209 10.7592C20.7364 12.3569 20.2321 13.9013 19.438 15.3005C18.644 16.6998 17.5764 17.9254 16.2986 18.9045C15.0208 19.8836 13.559 20.5962 12 21C10.441 20.5962 8.97916 19.8836 7.7014 18.9045C6.42364 17.9254 5.35602 16.6998 4.56197 15.3005C3.76792 13.9013 3.26365 12.3569 3.07913 10.7592C2.89461 9.16147 3.03359 7.54302 3.48784 6C6.60782 6.14257 9.66083 5.06658 12 3"
      stroke="#FF576D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlanShield;
