import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NumberFormat from 'react-number-format';
import styles from './styles.module.scss';

const Info = (props) => {
  const { propertyInfo, className } = props;

  const getFeature = (num, postfix, testid) => (
    <>
      <span testid={testid} className={classNames(styles.accent, styles.amount)}>
        {num || 0}
      </span>
      <span>{postfix}</span>
      <span className={styles.separator} />
    </>
  );

  const getFeatureInfoText = () => {
    const {
      NumBeds,
      NumBathsTotal,
      SquareFeet,
      HomeType,
      LotSizeAcres,
      LotSizeCalculatedFromRange,
      ExteriorAndLotDetails,
    } = propertyInfo;

    const showAcreage = HomeType === 'Land';
    const acerageDisplay =
      LotSizeCalculatedFromRange != true && LotSizeAcres
        ? `${LotSizeAcres} Acres`
        : ExteriorAndLotDetails.LotSizeInfo
        ? ExteriorAndLotDetails.LotSizeInfo
        : '';

    if (showAcreage) {
      return (
        <>
          <span testid="property_acres" className={classNames(styles.accent, styles.amount)}>
            {acerageDisplay}
          </span>
        </>
      );
    } else {
      return (
        <div testid="property_info" className={classNames(styles.featureWrapper)}>
          {getFeature(NumBeds, 'beds', 'property_beds')}
          {getFeature(NumBathsTotal, 'baths', 'property_baths')}
          <>
            <NumberFormat
              displayType="text"
              thousandSeparator
              value={SquareFeet}
              renderText={(val) => (
                <span testid="property_feet" className={classNames(styles.accent, styles.amount)}>
                  {val || 0}
                </span>
              )}
            />
            <span>sqft</span>
          </>
        </div>
      );
    }
  };

  return <div className={classNames(styles.info, className)}>{getFeatureInfoText()}</div>;
};

Info.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    NumBeds: PropTypes.number,
    NumBathsTotal: PropTypes.number,
    SquareFeet: PropTypes.number,
  }).isRequired,
};

Info.defaultProps = {
  className: '',
};

export default Info;
