import { get } from 'lodash-es';

import {
  requestQuoteFormAction,
  requestUpdateQuoteFormAction,
  resetQuoteFormAction,
  updateQuoteFormMetaAction,
  updateQuoteFormPreviewResponse,
  updateQuoteFormQuestionAction,
} from 'store/actions/formBuilder';
import { FormBuilderState, QuestionType, QuoteBuilderType } from 'types';
import { formBuilderInitialData } from '..';
import { FORM_STATUS_TYPE } from 'app-constants';

export const quoteBuilderReducer = {
  [requestQuoteFormAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => {
    const form = get(payload, 'data.value.form');

    const quoteBuilder = form
      ? ({
          Id: form.Id,
          state: payload.state,
          formName: form.Name,
          folderName: get(form, 'FormFolder.Name', ''),
          disabled: form.Status !== FORM_STATUS_TYPE.Draft,
          questions: get(form, 'FormQuestion', []),
          responses: get(form, 'FormQuestion', []).map((question: QuestionType) => ({
            UUID: question.UUID,
            Question: question.Title,
            Answer: '',
          })),
          status: form.Status,
          tags: get(form, 'FormTag', []).map((el) => el.TagId),
          isFavorite: form.isFavorite,
          formId: form.FormId,
          activeQuestionIndex: get(form, 'FormQuestion.0') ? 0 : undefined,
          categories: get(form, 'FormCategoryPermission', []).map((el) => el.CategoryId),
          locations: get(form, 'FormLocationPermission', []).map((el) => ({
            UUID: el.UUID,
            miles: el.Radius,
            areasOfOperation: el.AreasOfOperation,
          })),
        } as QuoteBuilderType)
      : formBuilderInitialData.quoteBuilder;

    return {
      ...state,
      data: {
        ...state.data,
        quoteBuilder: {
          ...state.data.quoteBuilder,
          ...quoteBuilder,
          lastUpdated: undefined,
          lastSaved: undefined,
        },
      },
    };
  },
  [resetQuoteFormAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      quoteBuilder: formBuilderInitialData.quoteBuilder,
    },
  }),
  [updateQuoteFormMetaAction as any]: (state: FormBuilderState, { payload }): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      quoteBuilder: {
        ...state.data.quoteBuilder,
        ...payload,
        lastUpdated:
          payload?.activeQuestionIndex !== undefined
            ? state.data.quoteBuilder.lastUpdated
            : new Date(),
      },
    },
  }),

  [updateQuoteFormQuestionAction as any]: (
    state: FormBuilderState,
    { payload },
  ): FormBuilderState => {
    const updatedQuestions = [...state.data.quoteBuilder.questions];
    const activeIndex = state.data.quoteBuilder.activeQuestionIndex;
    if (activeIndex !== undefined) {
      updatedQuestions[activeIndex] = payload;
    }

    return {
      ...state,
      data: {
        ...state.data,
        quoteBuilder: {
          ...state.data.quoteBuilder,
          questions: updatedQuestions,
          lastUpdated: new Date(),
        },
      },
    };
  },
  [requestUpdateQuoteFormAction as any]: (
    state: FormBuilderState,
    { payload },
  ): FormBuilderState => ({
    ...state,
    data: {
      ...state.data,
      quoteBuilder: {
        ...state.data.quoteBuilder,
        lastSaved: new Date(),
      },
    },
  }),
  [updateQuoteFormPreviewResponse as any]: (
    state: FormBuilderState,
    { payload },
  ): FormBuilderState => {
    const updatedResponses = (state.data.quoteBuilder.responses || []).map((element) => {
      if (element.UUID === payload.UUID) {
        return {
          ...element,
          Answer: payload.Answer,
        };
      }

      return element;
    });

    return {
      ...state,
      data: {
        ...state.data,
        quoteBuilder: {
          ...state.data.quoteBuilder,
          responses: updatedResponses,
        },
      },
    };
  },
};
