import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get, orderBy } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetTimelineTemplateAction,
  requestPostTimelineTemplateAction,
  requestPutTimelineTemplateAction,
  resetTimelineTemplateDataAction,
  setTimelineTemplateDrawerOpenedAction,
} from 'store/actions/template';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  isDrawerOpened: false,
  drawerParams: {},
};

export default handleActions(
  {
    [requestGetTimelineTemplateAction]: (state, { payload }) => {
      let data = get(payload, 'data.result', initialData.data);

      if (data?.Milestones?.length) {
        data = {
          ...data,
          Milestones: orderBy(data.Milestones, 'Offset', ['asc']),
        };
      }

      return {
        ...state,
        state: payload.state,
        data,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestPutTimelineTemplateAction]: (state, { payload }) => {
      const result = get(payload, ['data', 'result']);
      return {
        ...state,
        state: payload.state,
        data: result || state.data,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestPostTimelineTemplateAction]: (state, { payload }) => {
      const result = get(payload, ['data', 'result']);
      return {
        ...state,
        state: payload.state,
        data: result || state.data,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [setTimelineTemplateDrawerOpenedAction]: (state, { payload }) => ({
      ...state,
      isDrawerOpened: get(payload, 'isDrawerOpened', initialData.isDrawerOpened),
      drawerParams: get(payload, 'drawerParams', initialData.drawerParams),
    }),
    [appLogoutAction]: () => initialData,
    [resetTimelineTemplateDataAction]: (state) => ({
      ...state,
      data: null,
      meta: {},
      state: IDLE,
    }),
  },
  initialData,
);
