import PropTypes from 'prop-types';
import classNames from 'classnames';

import { cloneDeep } from 'lodash-es';
import { Client } from './Client';
import { ProfileIcons } from 'components/ClientsDrawer/ProfileClient/Icons';
import { Button } from 'components-antd';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';

import styles from './styles.module.scss';

export const ClientsList = (props) => {
  const {
    className,
    clients,
    selected,
    onChange,
    setShowModal,
    multiSelect,
    contact,
    openAssignClientsModal,
  } = props;
  const allClientIds = clients?.map((client) => client?.Id);

  const onChangeHandler = (e, id, checked) => {
    e.stopPropagation();
    if (checked) {
      const newValue = [...(selected || []), id];
      onChange(e, newValue);
    } else {
      if (!selected) {
        const clonedAllClientIds = cloneDeep(allClientIds);
        const uncheckedClientIndex = clonedAllClientIds.findIndex((clientId) => clientId === id);
        clonedAllClientIds.splice(uncheckedClientIndex, 1);
        return onChange(e, clonedAllClientIds);
      }

      const clonedValue = cloneDeep(selected);
      const removedIndex = (selected || []).findIndex((val) => val === id);

      if (removedIndex !== -1 && clonedValue) {
        clonedValue.splice(removedIndex, 1);
        onChange(e, clonedValue);
      }
    }
  };

  return (
    <div className={classNames(styles.clientsList, className)}>
      {clients?.length > 0 ? (
        clients?.map((client) => (
          <Client
            key={client?.Id}
            selected={selected}
            value={client}
            onChange={(e, val, checked) => onChangeHandler(e, client?.Id, checked)}
            setShowModal={setShowModal}
            multiSelect={multiSelect}
          />
        ))
      ) : (
        <div className={styles.emptyState}>
          <div className={styles.container}>
            <p>
              {contact?.FirstName} {contact?.LastName} doesn&apos;t have any clients yet
            </p>
            {(contact?.TeamRole === TEAM_OWNER || contact?.TeamRole === TEAM_ADMIN) && (
              <Button
                variant="hollow-bordered"
                className={styles.assignButton}
                size="large"
                onClick={openAssignClientsModal}
              >
                <ProfileIcons name="addParticipants" />
                Assign
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ClientsList.propTypes = {
  className: PropTypes.string,
  clients: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.shape({
        Id: PropTypes.number,
        FirstName: PropTypes.string,
        LastName: PropTypes.string,
        AvatarUrl: PropTypes.string,
      }),
    ),
  ),
  selected: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  setShowModal: PropTypes.func,
  multiSelect: PropTypes.bool,
  contact: PropTypes.any,
  openAssignClientsModal: PropTypes.func,
};

ClientsList.defaultProps = {
  className: '',
  clients: [],
  selected: undefined,
  onChange: () => {},
  setShowModal: () => {},
  multiSelect: false,
  contact: {},
  openAssignClientsModal: () => {},
};
