import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { getCleanedText } from 'utils/pinpointHelpers';
import { getFirstImage } from 'helpers';

const ComparePhotos = ({ compares, className }) => {
  const overflowCount = compares.length - 3;
  const showOverflowCount = compares.length > 3;

  const getLineOne = () => {
    if (compares?.length > 1) return `${compares?.length} listings selected`;
    return compares?.[0]?.Address?.Line1 || 'Private Address';
  };

  const getLineTwo = () => {
    const address = getCleanedText(
      compares?.[0]?.Address?.City,
      compares?.[0]?.Address?.State,
      compares?.[0]?.Address?.Zip,
    );
    if (compares?.length > 1) return address + ` +${compares?.length - 2} more`;
    return address;
  };

  return (
    <div className={classNames(styles.photos, className)}>
      <div className={styles.inner}>
        {compares.slice(0, 3).map(({ Id, PhotoUrls, Media }) => {
          const firstImage = getFirstImage(Media);
          const imageToSend = firstImage ? firstImage : PhotoUrls?.[0];
          return (
            <div
              testid="photo_image"
              className={classNames(styles.imageWrapper, { [styles.overflow]: showOverflowCount })}
              key={Id}
            >
              <img className={styles.photo} src={imageToSend} alt="" />
              {showOverflowCount && (
                <span className={styles.overflowCount}>{`+${overflowCount}`}</span>
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.propWrapper}>
        {compares?.length && (
          <>
            <p testid="prop_address_1" className={styles.address1}>
              {getLineOne()}
            </p>
            <p testid="prop_address_2" className={styles.address2}>
              {getLineTwo()}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

ComparePhotos.propTypes = {
  className: PropTypes.string,
  compares: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      PhotoUrls: PropTypes.arrayOf(PropTypes.string),
      SellingPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      Address: PropTypes.shape({
        Line1: PropTypes.string,
      }),
    }),
  ).isRequired,
};

ComparePhotos.defaultProps = {
  className: '',
};

export default ComparePhotos;
