import { getLocationContext, getPlaceComponent, sortLocations } from 'helpers';
import { uniqBy } from 'lodash-es';
import { LOCALITY, POSTAL_CODE, ROUTE, STATE, STREET_NUMBER } from 'settings/constants/locations';

export const onAreasOfOperationChange = (
  index: number,
  values: any,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  result: any,
  prepareData: any,
) => {
  let value = values.locations || [];
  if (prepareData?.length) {
    value[index] = {
      AreasOfOperation: prepareData[0],
      Radius: value?.[index]?.Radius || '0',
    };
  }

  if (result?.deleteItemId) {
    value = value.filter(
      ({ AreasOfOperation: { ProviderPlaceId: itemId } }) => itemId !== result.deleteItemId,
    );
  }
  value = uniqBy(value, (x: any) => x.AreasOfOperation.ProviderPlaceId);
  value.sort(sortLocations);

  if (value.length > 1) {
    value = value.filter((item: any) => item.AreasOfOperation !== '');
  }
  setFieldValue(`locations`, value);
};

export const onPartnerTagSelect = (value, values, setFieldValue, partnerTags) => {
  const tags = [...(values.PartnerServices || [])];

  if (tags.includes(value)) {
    return;
  } else if (typeof value === 'string') {
    const newValue = value.trim();
    if (!newValue) return;
    const existing = partnerTags.find((el) => el.name === newValue);

    if (existing) {
      if (!tags.includes(existing.value)) {
        tags.push(existing.value);
      }
    } else {
      tags.push(newValue);
    }
  } else {
    tags.push(value);
  }

  setFieldValue('PartnerServices', tags);
};

export const onPartnerTagDeselect = (value, values, setFieldValue) => {
  const tags = (values.PartnerServices || []).filter((el) => el !== value);

  setFieldValue('PartnerServices', tags);
};

export const onRecommenderSelect = (e, selected, setFieldValue, values) => {
  setFieldValue('RecommendedByAgentsId', [...values, selected.value]);
};

export const onRecommenderDeselect = (e, selected, setFieldValue, values) => {
  const agent = values.filter((agentId) => agentId !== selected.value);
  setFieldValue('RecommendedByAgentsId', agent);
};

export const onLogoChange = (image, files, setFieldValue) => {
  setFieldValue('LogoUrl', files[0]);
};

export const onAddressChange = (result, setFieldValue) => {
  const location = result?.result?.[0];
  const coordinates = result?.coordinates;

  if (location) {
    const updatedCity = getLocationContext(location, LOCALITY);
    const updatedState = getLocationContext(location, STATE);
    const updatedZip = getLocationContext(location, POSTAL_CODE);
    const street = getPlaceComponent(location, ROUTE);
    const streetNumber = getLocationContext(location, STREET_NUMBER);
    const geolocation = coordinates
      ? {
          Lat: coordinates.lat,
          Long: coordinates.lng,
        }
      : null;

    setFieldValue('Address', {
      City: updatedCity,
      State: updatedState,
      Zip: updatedZip,
      Line1: [streetNumber, street?.name].filter((i) => !!i).join(' '),
      ProviderPlaceId: location.place_id,
      PlaceName: result?.placeName,
      Geolocation: geolocation,
    });
  } else {
    setFieldValue('Address', null);
  }
};

export const onCategoriesChange = (e, values, setFieldValue) =>
  setFieldValue('PartnerCategoryIds', values);
