import { handleActions } from 'redux-actions';
import { cloneDeep, get } from 'lodash-es';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetCategoryByIdAction,
  requestPostCategoryAction,
  requestUpdateCategoryAction,
} from 'store/actions/adminPanel';
import { getDataFor } from 'store/helpers';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetCategoryByIdAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', state.data),
      meta: getDataFor('meta', payload, initialData),
    }),
    [requestUpdateCategoryAction]: (state, { payload }) => {
      const result = get(payload, ['data', 'result']);
      return {
        ...state,
        state: payload.state,
        data: result || state.data,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestPostCategoryAction]: (state, { payload }) => {
      const result = get(payload, ['data', 'result']);
      return {
        ...state,
        state: payload.state,
        data: result || state.data,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
