import { useEffect, useState } from 'react';
import { Modal } from 'components-antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { initState } from './initialState';
import { OfferStatus } from 'types/offers';
import { StatusWiseOffers } from './components/offers';
import { CompareOfferFooter } from './components/CompareOfferFooter';

import Spinner from 'components/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedOffersForComparison } from 'store/effects';
import { getSelectedOffersForComparison } from 'store/selectors/offers';

import styles from './styles.module.scss';

interface CompareOfferList {
  Id: string;
  UUID: string;
  OfferLinkId: 1;
  BuyingAgentName: string;
  OfferStatus: OfferStatus;
  CreatedDate: Date;
  OfferIteration: Array<OfferIterations>;
  selected: boolean;
}

interface OfferIterations {
  Id: string;
  UUID: string;
  IterationStatus: OfferStatus;
  CreatedDate: Date;
  PurchasePrice: Number;
  DownPayment: Number;
  selected: boolean;
}

interface CompareOfferContent {
  pending?: Array<CompareOfferList>;
  withdrawn?: Array<CompareOfferList>;
  rejected: Array<CompareOfferList>;
}

export const CompareOffersModal = (compareOfferData: any) => {
  const dispatch = useDispatch();
  const { data, onCloseModal, transactionId, open } = compareOfferData;
  const [isLoading, setIsLoading] = useState(false);
  const [offers, setOffers] = useState(initState);
  const selectedOffersForComparison = useSelector(getSelectedOffersForComparison);
  const [selectedOffers, setSelectedOffers] = useState<any>([]);
  const [totalOffersSelected, setTotalOffersSelected] = useState(selectedOffers.length);
  useEffect(() => {
    if (
      selectedOffersForComparison &&
      Object.entries(selectedOffersForComparison.selectedOffers).length > 0
    ) {
      let sOffers: any = [];
      Object.entries(selectedOffersForComparison.selectedOffers).forEach((entry) => {
        const [key, value] = entry;
        sOffers.push(value);
      });
      setSelectedOffers(sOffers);
      setTotalOffersSelected(sOffers.length);
    }
    setOffers(data);
  }, [data, transactionId]);

  const onOfferSelection = (
    event: CheckboxChangeEvent,
    offer: CompareOfferList | any,
    setStatus: Function,
    offerIndex: -1,
  ) => {
    let {
      target: { checked: isSelected },
    } = event;
    let index = selectedOffers.findIndex(
      (so) => so?.Id === (offerIndex > -1 ? offer[offerIndex].Id : offer.Id),
    );
    let sOffers = selectedOffers;
    offerIndex > -1 ? (offer[offerIndex].selected = isSelected) : (offer.selected = isSelected);
    if (index < 0 && isSelected) {
      sOffers.push(offerIndex > -1 ? offer[offerIndex] : offer);
    } else if (index > -1 && !isSelected) {
      sOffers.splice(index, 1);
    } else {
      event.target.value = false;
      event.target.checked = false;
      offerIndex > -1
        ? ((offer[offerIndex].selected = false), setStatus([...offer]))
        : ((offer.selected = false), setStatus({ ...offer }));
      return;
    }
    offerIndex > -1 ? setStatus([...offer]) : setStatus({ ...offer });
    setSelectedOffers(sOffers);
    dispatch(setSelectedOffersForComparison(sOffers));
    setTotalOffersSelected(sOffers.length);
  };

  return (
    <>
      <Modal
        open={open}
        width={675}
        footer={
          <CompareOfferFooter
            totalOffersSelected={totalOffersSelected}
            transactionId={transactionId}
            onCloseModal={onCloseModal}
          />
        }
        title={'Compare Offers'}
        onCancel={() => (onCloseModal ? onCloseModal() : '')}
        className={styles.compareOffersModal}
        maskClosable={false}
        destroyOnClose={true}
      >
        {!isLoading && offers ? (
          <div className={styles.compareOffersModalBody}>
            {Object.keys(offers).map((key, i) => (
              <>
                <StatusWiseOffers
                  key={i}
                  panelHeading={key}
                  panelKey={key}
                  offers={offers[key]}
                  onOfferSelection={onOfferSelection}
                  totalOffersSelected={totalOffersSelected}
                  setTotalOffersSelected={setTotalOffersSelected}
                  selectedOffers={selectedOffers}
                  setSelectedOffers={setSelectedOffers}
                />
              </>
            ))}
          </div>
        ) : (
          <Spinner />
        )}
      </Modal>
    </>
  );
};
