import React from 'react';

const DollarFile = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.667 4V9.33333C18.667 9.68695 18.8075 10.0261 19.0575 10.2761C19.3076 10.5262 19.6467 10.6667 20.0003 10.6667H25.3337"
      stroke="#FF576D"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.667 28H9.33366C8.62641 28 7.94814 27.719 7.44804 27.219C6.94794 26.7189 6.66699 26.0406 6.66699 25.3333V6.66667C6.66699 5.95942 6.94794 5.28115 7.44804 4.78105C7.94814 4.28095 8.62641 4 9.33366 4H18.667L25.3337 10.6667V25.3333C25.3337 26.0406 25.0527 26.7189 24.5526 27.219C24.0525 27.719 23.3742 28 22.667 28Z"
      stroke="#FF576D"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5751 15.8737C17.5426 15.5166 17.3981 15.239 17.1416 15.0409C16.8884 14.8396 16.5264 14.739 16.0556 14.739C15.7439 14.739 15.4841 14.7795 15.2764 14.8607C15.0686 14.9419 14.9127 15.0539 14.8088 15.1967C14.7049 15.3364 14.6514 15.4971 14.6481 15.6789C14.6416 15.8282 14.6708 15.9597 14.7358 16.0734C14.804 16.187 14.9014 16.2877 15.028 16.3753C15.1578 16.4597 15.3137 16.5344 15.4955 16.5993C15.6773 16.6643 15.8819 16.7211 16.1091 16.7698L16.9663 16.9646C17.4598 17.0717 17.8949 17.2146 18.2715 17.3932C18.6514 17.5717 18.9695 17.7844 19.226 18.0312C19.4858 18.2779 19.6822 18.562 19.8153 18.8834C19.9484 19.2049 20.0166 19.5653 20.0199 19.9646C20.0166 20.5945 19.8575 21.1351 19.5426 21.5864C19.2277 22.0377 18.7747 22.3834 18.1838 22.6237C17.5962 22.864 16.8867 22.9841 16.0556 22.9841C15.2212 22.9841 14.4939 22.8591 13.8738 22.6091C13.2536 22.3591 12.7715 21.9792 12.4273 21.4695C12.0832 20.9597 11.9062 20.3153 11.8965 19.536H14.2049C14.2244 19.8575 14.3104 20.1253 14.463 20.3396C14.6156 20.5539 14.8251 20.7162 15.0913 20.8266C15.3608 20.937 15.6725 20.9922 16.0264 20.9922C16.351 20.9922 16.627 20.9484 16.8543 20.8607C17.0848 20.773 17.2617 20.6513 17.3851 20.4954C17.5085 20.3396 17.5718 20.161 17.5751 19.9597C17.5718 19.7714 17.5134 19.6107 17.3997 19.4776C17.2861 19.3412 17.1108 19.2243 16.8738 19.1269C16.64 19.0263 16.3413 18.9338 15.9777 18.8493L14.9354 18.6058C14.0718 18.4078 13.3916 18.088 12.8949 17.6464C12.3981 17.2016 12.1514 16.601 12.1546 15.8445C12.1514 15.2276 12.3169 14.687 12.6514 14.2227C12.9858 13.7584 13.4484 13.3964 14.0393 13.1367C14.6303 12.8769 15.304 12.7471 16.0604 12.7471C16.8332 12.7471 17.5036 12.8786 18.0718 13.1416C18.6432 13.4013 19.0864 13.7666 19.4014 14.2373C19.7163 14.7081 19.877 15.2536 19.8835 15.8737H17.5751Z"
      fill="#FF576D"
    />
    <path d="M16 12V23.4286" stroke="#FF576D" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
export default DollarFile;
