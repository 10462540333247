export function prepareData(values, stageIndex, defaultClientSearch = {}) {
  return {
    stageIndex,
    DefaultPropertySearchPreferences: {
      ...defaultClientSearch,
      ...(values.priceMin || values.priceMax
        ? {
            PriceRange: {
              Min: values.priceMin ? +values.priceMin : undefined,
              Max: values.priceMax ? +values.priceMax : undefined,
            },
          }
        : { PriceRange: undefined }),
    },
  };
}
