import { FormattedPhone } from 'components';

import styles from './styles.module.scss';

export const Agents = (props) => {
  const { SellerAgent, Brokerage, ListingAgentName, ListingAgentEmail } = props;

  return (
    <div className={styles.agentDetails}>
      <div className={styles.headTitle}>Agent</div>
      <div className={styles.agentName}>
        {ListingAgentName ? ListingAgentName : `${SellerAgent?.FirstName} ${SellerAgent?.LastName}`}
      </div>
      <div className={styles.subTitle}>{Brokerage?.Name}</div>
      <div className={styles.subTitle}>
        <FormattedPhone className={styles.phoneInput} testid="view_phone">
          {SellerAgent?.Phones?.[0]?.PhoneNumber}
        </FormattedPhone>
      </div>
      <div className={styles.email}>
        <a>{ListingAgentEmail ? ListingAgentEmail : SellerAgent?.Email}</a>
      </div>
    </div>
  );
};
