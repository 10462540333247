import { useLocation, useParams } from 'react-router-dom';

import { BundleDataType, FormProcessDataType } from 'types';
import { DynamicFormManager } from '../DynamicFormManager';
import { useMemo } from 'react';
import { sortDocuments } from '../helper';

export const BundleDynamicForm = () => {
  const { state: formState } = useLocation<BundleDataType>();

  const state = useMemo(() => formState, []);
  const { formProcessPublicId } = useParams<FormProcessDataType>();

  if (state?.allDocuments) {
    state.allDocuments = sortDocuments(state?.allDocuments, 'Order');
  }

  return formProcessPublicId ? (
    <DynamicFormManager
      bundleData={{
        ...state,
        formProcessPublicId,
        formDocumentPublicId: state?.allDocuments[0].PublicId,
      }}
    />
  ) : (
    <></>
  );
};
