import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Checkbox } from 'components';
import { updateNotificationsSettingsEffect } from 'store/effects';

const Realtime = (props) => {
  const { className, labelClassName, labelTextClassName, value } = props;
  const { groupType, type, variant } = props;

  const dispatch = useDispatch();

  const [realtimeCheckedValue, setRealtimeCheckedValue] = useState(value);
  const [isPending, setIsPending] = useState(false);

  const onChangeRealtimeValue = (e, val, isChecked) => {
    setIsPending(true);
    const config = { groupType, type, variant, value: { Realtime: isChecked } };
    dispatch(
      updateNotificationsSettingsEffect(config, {}, (err) => {
        if (!err) {
          setRealtimeCheckedValue(isChecked);
        }
        setIsPending(false);
      }),
    );
  };

  return (
    <Checkbox
      className={className}
      label="Real-time"
      labelClassName={labelClassName}
      labelTextClassName={labelTextClassName}
      checked={realtimeCheckedValue}
      onChange={onChangeRealtimeValue}
      isPending={isPending}
      testid="real_time"
    />
  );
};

Realtime.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  labelTextClassName: PropTypes.string,
  value: PropTypes.bool,
  groupType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};

Realtime.defaultProps = {
  className: '',
  labelClassName: '',
  labelTextClassName: '',
  value: false,
};

export default Realtime;
