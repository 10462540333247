import classNames from 'classnames';

import { Add, Remove } from 'components/Icons';
import { Panel, Collapse } from 'components-antd';
import { SoftCriteriaType } from '../SoftCriteriaType';
import { prefsIds, PREF_TYPE } from 'settings/constants/preferences';
import Icon from '../Icons';

import styles from '../styles.module.scss';

export const PropertyPrefs = ({ formik, softCriteria, addSoftCriteria }) => {
  const renderBasicPropertyPrefs = () => (
    <div className={classNames(styles.propertyPrefs, styles.softCriteriaCollapse)}>
      <div className={styles.softCollapse}>
        {PropertyPrefs.PROPERTY_OPTIONS.map((pref, i) => (
          <SoftCriteriaType
            key={i}
            title={pref.title}
            icon={pref.icon}
            softCriteria={softCriteria}
            onClick={addSoftCriteria}
            showTitle={pref.showTitle}
            type={pref.type}
          />
        ))}
      </div>
    </div>
  );

  return (
    <>
      {renderBasicPropertyPrefs()}
      <Collapse
        bordered={false}
        accordion
        expandIcon={({ isActive }) =>
          isActive ? <Remove color={Remove.COLLAPSE} /> : <Add color={Add.COLLAPSE} />
        }
        expandIconPosition={'end'}
        className={styles.softCriteriaCollapse}
        ghost={true}
      >
        {PropertyPrefs.COLLAPSIBLE_OPTIONS.category.map((item, i) => {
          const valuesSoftCriteria = formik.values.softCriteria || {};
          const softCriteriaSelected = item.criteria.filter((c) => {
            if (Object.keys(valuesSoftCriteria).length) {
              return Object.keys(valuesSoftCriteria).includes(c.title);
            }
          });

          return (
            <Panel
              header={
                <>
                  <p className={styles.softCriteriaTitle}>{item.title}</p>
                  {softCriteriaSelected.length > 0 && (
                    <p className={styles.softCriteriaSelected}>
                      {`(${softCriteriaSelected.length})`}
                    </p>
                  )}
                </>
              }
              key={item.key}
              className={styles.softCollapse}
            >
              {item.criteria.map((c, i) => {
                return (
                  <SoftCriteriaType
                    title={c.title}
                    showTitle={c.showTitle}
                    icon={c.icon}
                    onClick={addSoftCriteria}
                    softCriteria={softCriteria}
                    key={i}
                    type={c.type}
                  />
                );
              })}
            </Panel>
          );
        })}
      </Collapse>
    </>
  );
};

PropertyPrefs.PROPERTY_OPTIONS = [
  {
    title: prefsIds.outdoorPrefs,
    showTitle: 'Outdoor Space',
    icon: Icon.OUTDOOR_SPACE,
  },
  {
    title: prefsIds.parking,
    showTitle: prefsIds.parking,
    icon: Icon.PARKING,
    type: prefsIds.parking,
  },
  {
    title: prefsIds.view,
    showTitle: prefsIds.view,
    icon: Icon.VIEW,
    type: PREF_TYPE.homePrefs,
  },
  {
    title: prefsIds.newConstruction,
    showTitle: 'New Construction',
    icon: Icon.NEW_CONSTRUCTION,
    type: PREF_TYPE.homePrefs,
  },
  {
    title: prefsIds.moveInReady,
    showTitle: 'Move-In Ready',
    icon: Icon.MOVE_IN_READY,
    type: PREF_TYPE.homePrefs,
  },
  // Hidden until ImageAI work is complete
  // {
  //   title: prefsIds.style,
  //   showTitle: 'Style',
  //   icon: 'style',
  //   type: PREF_TYPE.homePrefs,
  // },
  {
    title: prefsIds.flooring,
    showTitle: prefsIds.flooring,
    icon: Icon.FLOORING_OTHER,
    type: PREF_TYPE.homePrefs,
  },
];

PropertyPrefs.COLLAPSIBLE_OPTIONS = {
  category: [
    {
      key: '1',
      title: 'Layout',
      criteria: [
        {
          title: prefsIds.openFloorPlan,
          showTitle: 'Open Floor Plan',
          icon: Icon.OPEN_FLOOR_PLAN,
          type: PREF_TYPE.homePrefs,
        },
        // {
        //   title: prefsIds.firstFloorPrimarySuite,
        //   showTitle: 'First Floor Primary Suite',
        //   icon: 'openFloorPlan',
        //   type: PREF_TYPE.homePrefs,
        // },
        {
          title: prefsIds.laundryRoom,
          showTitle: 'Laundry Room',
          icon: Icon.DRY_CLEANING,
          type: PREF_TYPE.homePrefs,
        },
        {
          title: prefsIds.homeOffice,
          showTitle: 'Home Office',
          icon: Icon.HOME_OFFICE,
          type: PREF_TYPE.homePrefs,
        },
        {
          title: prefsIds.basementRoom,
          showTitle: 'Basement',
          icon: Icon.BASEMENT,
          type: PREF_TYPE.homePrefs,
        },
        // {
        //   title: prefsIds.guestHouse,
        //   showTitle: 'Guest House (Accessory Dwelling Unit)',
        //   icon: 'guestHouse',
        //   type: PREF_TYPE.homePrefs,
        // },
      ],
    },
    {
      key: '2',
      title: 'Kitchen Features',
      criteria: [
        {
          title: prefsIds.kitchenFeaturesLargerThanAverage,
          showTitle: 'Larger Than Average',
          icon: Icon.LARGE_KITCHEN,
          type: PREF_TYPE.homePrefs,
        },
        // {
        //   title: prefsIds.kitchenFeaturesEatIn,
        //   showTitle: 'Eat-In',
        //   icon: Icon.KITCHEN_FEATURES_EAT_IN,
        //   type: PREF_TYPE.homePrefs,
        // },
        {
          title: prefsIds.kitchenFeaturesIsland,
          showTitle: 'Island',
          icon: Icon.KITCHEN_FEATURES_ISLAND,
          type: PREF_TYPE.homePrefs,
        },
        {
          title: prefsIds.kitchenFeaturesStainlessSteelAppliances,
          showTitle: 'Stainless Steel Appliances',
          icon: Icon.KITCHEN_FEATURES_STAINLESS_STEEL_APPLIANCES,
          type: PREF_TYPE.homePrefs,
        },
        // {
        //   title: prefsIds.kitchenGasCooktop,
        //   showTitle: 'Gas Cooktop',
        //   icon: Icon.KITCHEN_GAS_COOKTOP,
        //   type: PREF_TYPE.homePrefs,
        // },
        // {
        //   title: prefsIds.kitchenElectricCooktop,
        //   showTitle: 'Electric Cooktop',
        //   icon: Icon.KITCHEN_ELECTRIC_COOKTOP,
        //   type: PREF_TYPE.homePrefs,
        // },
      ],
    },
    {
      key: '3',
      title: 'Amenities',
      criteria: [
        {
          title: prefsIds.elevator,
          showTitle: prefsIds.elevator,
          icon: Icon.ELEVATOR,
          type: PREF_TYPE.amenitiesPrefs,
        },
        {
          title: prefsIds.gym,
          showTitle: prefsIds.gym,
          icon: Icon.GYM,
          type: PREF_TYPE.amenitiesPrefs,
        },
        {
          title: prefsIds.packageReceiving,
          showTitle: 'Package Receiving',
          icon: Icon.PACKAGE_RECEIVING,
          type: PREF_TYPE.amenitiesPrefs,
        },
        {
          title: prefsIds.rooftop,
          showTitle: prefsIds.rooftop,
          icon: Icon.ROOFTOP,
          type: PREF_TYPE.amenitiesPrefs,
        },
        {
          title: prefsIds.doorman,
          showTitle: 'Doorman/Security',
          icon: Icon.DOORMAN,
          type: PREF_TYPE.amenitiesPrefs,
        },
        {
          title: prefsIds.onSiteManagement,
          showTitle: 'On-Site Management',
          icon: Icon.ON_SITE_MANAGEMENT,
          type: PREF_TYPE.amenitiesPrefs,
        },
        {
          title: prefsIds.dogRun,
          showTitle: 'Dog-Run',
          icon: Icon.DOG_RUN,
          type: PREF_TYPE.amenitiesPrefs,
        },
        {
          title: prefsIds.dryCleaning,
          showTitle: 'Dry Cleaning',
          icon: Icon.DRY_CLEANING,
          type: PREF_TYPE.amenitiesPrefs,
        },
        {
          title: prefsIds.gatedCommunity,
          showTitle: 'Gated Community',
          icon: Icon.GATED_COMMUNITY,
          type: PREF_TYPE.amenitiesPrefs,
        },
        {
          title: prefsIds.communityPool,
          showTitle: 'Community Pool',
          icon: Icon.COMMUNITY_POOL,
          type: PREF_TYPE.amenitiesPrefs,
        },
        {
          title: prefsIds.playground,
          showTitle: prefsIds.playground,
          icon: Icon.PLAYGROUND,
          type: PREF_TYPE.amenitiesPrefs,
        },
      ],
    },
  ],
};
