import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Buffer } from 'buffer';

import { PDF_FIELD_TYPE } from 'app-constants';
import { FormRolesType, ResponseType } from 'types';
import { SignForm } from 'components/SmartForm/SignForm';
import { link as navigationLink } from 'settings/navigation/link';
import { EditForm } from 'components/SmartForm/EditForm/EditForm';
import { getSmartFormMetaSelect } from 'store/selectors/formBuilder';
import { PreviewForm } from 'components/SmartForm/PreviewForm/PreviewForm';
import {
  getSmartFormBuilderEffect,
  previewDocumentFillEffect,
  updateSmartFormResponseEffect,
} from 'store/effects/formBuilder';
import { Spinner } from 'components';

enum SmartFormPreviewModes {
  Edit,
  PreviewEdit,
  Sign,
  PreviewSign,
  Complete,
}

const DEFAULT_SCREENS = [
  SmartFormPreviewModes.Edit,
  SmartFormPreviewModes.PreviewEdit,
  SmartFormPreviewModes.Sign,
  SmartFormPreviewModes.PreviewSign,
  SmartFormPreviewModes.Complete,
];

export const SmartFormPreview = () => {
  const dispatch = useDispatch();

  const [allScreens, setAllScreens] = useState<SmartFormPreviewModes[]>([]);
  const [index, setIndex] = useState(0);

  const { versionId } = useParams<{ versionId: string }>();
  const history = useHistory();
  const [editRoles, setEditRoles] = useState(['Agent', 'Client']);

  const [allRoles, setAllRoles] = useState<FormRolesType>([]);

  const { questions, answers, link, Id, questionRoles, wizardViewEnabled } =
    useSelector(getSmartFormMetaSelect);

  const [finalForm, setFinalForm] = useState('');

  const filterScreens = () => {
    let screens = [...DEFAULT_SCREENS];

    if (!questions.filter((q) => q.FieldType === PDF_FIELD_TYPE.PDFSignature).length) {
      screens = screens.filter(
        (s) => s !== SmartFormPreviewModes.Sign && s !== SmartFormPreviewModes.PreviewSign,
      );
    }
    if (!questions.filter((q) => q.FieldType !== PDF_FIELD_TYPE.PDFSignature).length) {
      screens = screens.filter(
        (s) => s !== SmartFormPreviewModes.Edit && s !== SmartFormPreviewModes.PreviewEdit,
      );
    }
    setAllScreens(screens);
  };

  useEffect(() => {
    if (questions?.length && !allScreens?.length) {
      filterScreens();
    }
  }, [questions]);

  useEffect(() => {
    if (versionId) {
      dispatch(getSmartFormBuilderEffect({ versionId }));
    }
  }, [versionId]);

  useEffect(() => {
    if (allScreens[index] === SmartFormPreviewModes.Complete && !finalForm) {
      handleFillDocumentPreview();
    }
  }, [index]);

  const handleResponse = (updatedResponse: Partial<ResponseType>) => {
    dispatch(updateSmartFormResponseEffect(updatedResponse));
  };

  const handleProgress = () => {
    if (index + 1 < allScreens.length) setIndex(index + 1);
  };
  const handleBack = () => {
    if (index > 0) setIndex(index - 1);
  };

  const handleExit = () => {
    history.push(navigationLink.toDashboardServiceDirectoryFormsSmartForm(Id));
  };

  const getEditQuestions = () =>
    questions
      ?.filter((ques) => ques.FieldType !== PDF_FIELD_TYPE.PDFSignature)
      .filter((ques) => ques.RolePermissions?.some((role) => editRoles.includes(role)));

  const getSignQuestions = () =>
    questions?.filter(
      (ques) => ques.FieldType === PDF_FIELD_TYPE.PDFSignature && ques.FormRole?.length,
    );

  const updateEditRoles = (roles) => setEditRoles(roles);

  const handlePreviewEditDone = () => {
    if (questionRoles?.length) {
      const allRoles = questionRoles.filter((role) =>
        questions?.some(
          (ques) =>
            ques.FieldType === PDF_FIELD_TYPE.PDFSignature && ques.FormRole?.[0] === role.Id,
        ),
      );
      setAllRoles([...allRoles]);
    }
    handleProgress();
  };

  const handleFillDocumentPreview = () => {
    dispatch(
      previewDocumentFillEffect(
        {
          versionId,
          responses: answers,
        },
        async (err, response) => {
          if (!err && response?.data?.result?.data) {
            const base64 = `data:application/pdf;base64,${Buffer.from(
              response.data.result,
            ).toString('base64')}`;
            setFinalForm(base64);
          }
        },
      ),
    );
  };

  const renderScreen = () => {
    switch (allScreens[index]) {
      case SmartFormPreviewModes.Edit:
        return (
          <EditForm
            handleResponse={handleResponse}
            questions={getEditQuestions() as any}
            responses={answers}
            handleDone={handleProgress}
            link={link}
            handleExit={handleExit}
            showRolesColumn={true}
            updateEditRoles={updateEditRoles}
            editRoles={editRoles}
            isBuilder={true}
            allowWizardView={wizardViewEnabled}
          />
        );
      case SmartFormPreviewModes.PreviewEdit:
        return (
          <PreviewForm
            questions={getEditQuestions() as any}
            responses={answers}
            handleDone={handlePreviewEditDone}
            link={link}
            handleExit={handleExit}
          />
        );
      case SmartFormPreviewModes.Sign:
        return (
          <SignForm
            handleResponse={handleResponse}
            questions={getSignQuestions() as any}
            responses={answers}
            handleDone={handleProgress}
            link={link}
            handleExit={handleExit}
            handleUnlock={handleBack}
            showRolesColumn={true}
            allRoles={allRoles}
            handleDecline={() => undefined}
            isMock={true}
          />
        );
      case SmartFormPreviewModes.PreviewSign:
        return (
          <PreviewForm
            questions={questions as any}
            responses={answers}
            handleDone={handleProgress}
            link={link}
            handleExit={handleExit}
          />
        );
      case SmartFormPreviewModes.Complete:
        return <Spinner />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {finalForm ? (
        <PreviewForm
          completed={true}
          handleDone={handleExit}
          link={finalForm}
          handleExit={handleExit}
          smartFormPreview={true}
        />
      ) : (
        renderScreen()
      )}
    </>
  );
};
