import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { FORM_MODE } from 'app-constants';

import { getPreFormSelector, getRequestQuoteSelector } from 'store/selectors/requestQuote';
import { setPreFormQuestionsEffect, updateQuoteDraftEffect } from 'store/effects/quotes';
import { preFormQuestionsIds } from 'settings/constants/preForm';

import { YesNoQuestion } from 'pages/FormBuilder/components';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';

import styles from './styles.module.scss';

interface IsPropertyProps {
  className: string;
  isClient: boolean;
  onAnswer: (answer: boolean) => {};
  onContinue: () => void;
}

const IsProperty = ({ className, isClient, onAnswer, onContinue }: IsPropertyProps) => {
  const dispatch = useDispatch();
  const preForm = useSelector(getPreFormSelector);
  const { isDraft } = useSelector(getRequestQuoteSelector);

  const isProperty = preForm?.[preFormQuestionsIds.isProperty];
  const [answer, setAnswer] = useState<boolean | undefined>(isProperty);

  const onAnswerHandler = () => {
    if (typeof answer === 'undefined') return;
    dispatch(
      setPreFormQuestionsEffect({
        [preFormQuestionsIds.isProperty]: answer,
        ...(!answer ? { [preFormQuestionsIds.property]: undefined } : {}),
      }),
    );

    if (isDraft) {
      dispatch(
        updateQuoteDraftEffect({}, { silent: true, isNegativeAnswer: !answer }, (err) => {
          if (!err) {
            onAnswer(answer);
          }
        }),
      );
    } else {
      onAnswer(answer);
    }
  };

  return (
    <>
      <div testid="is_property" className={classNames(styles.isProperty, className)}>
        <Question>Is this quote for a specific property?</Question>
        <AnswersContainer>
          <YesNoQuestion mode={FORM_MODE.View} inputState={answer} setInputState={setAnswer} />
        </AnswersContainer>
        <ButtonsContainer>
          <Continue onClick={onAnswerHandler} disabled={typeof answer === 'undefined'}></Continue>
        </ButtonsContainer>
      </div>
    </>
  );
};

export default IsProperty;
