import Api from 'store/effects/core/api';
import {
  changePasswordAction,
  forgotPasswordAction,
  resetPasswordAction,
  signupRoleAction,
} from 'store/actions/loginGroup';
import { forgotPassword, resetPassword, changePassword } from 'api/loginGroup';
import { mapRouteRoles } from 'settings/constants/roles';

export const signupRoleEffect =
  ({ role }, parse = true) =>
  (dispatch) => {
    dispatch(signupRoleAction({ role: parse ? mapRouteRoles[role] : role }));
  };

export const forgotPasswordEffect = (cfg, option, cb) => {
  const sendRequest = Api.execBase({ action: forgotPasswordAction, method: forgotPassword });

  return sendRequest(cfg, option, cb);
};

export const resetPasswordEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({ action: resetPasswordAction, method: resetPassword });

  const config = {
    newPassword: cfg.password,
    token: cfg.token,
  };
  return sendRequest(config, options, cb);
};

export const changePasswordEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({ action: changePasswordAction, method: changePassword });

  const config = {
    oldPassword: cfg.oldPassword,
    newPassword: cfg.newPassword,
  };
  return sendRequest(config, options, cb);
};
