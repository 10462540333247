import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { routes } from 'settings/navigation/routes';
import { MosaikLogo } from 'components/Icons';
import Point from './Point';
import { getUserEmail } from 'store/selectors/user';

import styles from './styles.module.scss';

const Sidebar = () => {
  const userEmail = useSelector(getUserEmail);
  const masterTemplateAdmins = ['sheila@mosaik.io', 'maddie@mosaik.io'];
  const [isViewMasterTemplateAdmin, setIsViewMasterTemplateAdmin] = useState(false);

  useEffect(() => {
    masterTemplateAdmins.includes(userEmail) && setIsViewMasterTemplateAdmin(true);
  }, []);

  const points = [
    {
      title: 'Service Directory',
      Icon: () => '',
      links: [
        {
          link: routes.dashboardServiceDirectoryPartners,
          title: 'Partners',
        },
        {
          link: routes.dashboardServiceDirectoryCategories,
          title: 'Categories',
        },
        {
          link: routes.dashboardServiceDirectoryServiceTags,
          title: 'Services',
        },
      ],
    },
    {
      Icon: () => '',
      title: 'Forms',
      links: [
        {
          link: routes.dashboardServiceDirectoryForms,
        },
      ],
    },
    {
      Icon: () => '',
      title: 'KITs',
      links: [
        {
          link: routes.kits,
        },
      ],
    },
    {
      title: 'Templates',
      Icon: () => '',
      links: isViewMasterTemplateAdmin
        ? [
            {
              link: routes.dashboardMasterTemplates,
              title: 'Master Transactions',
            },
            {
              link: routes.dashboardTransactionsTemplates,
              title: 'Transaction',
            },
            {
              link: routes.dashboardTimelineTemplates,
              title: 'Timeline',
            },
            {
              link: routes.dashboardTaskTemplates,
              title: 'Task',
            },
            {
              link: routes.dashboardProjectsTemplates,
              title: 'Projects',
            },
          ]
        : [
            {
              link: routes.dashboardTransactionsTemplates,
              title: 'Transaction',
            },
            {
              link: routes.dashboardTimelineTemplates,
              title: 'Timeline',
            },
            {
              link: routes.dashboardTaskTemplates,
              title: 'Task',
            },
            {
              link: routes.dashboardProjectsTemplates,
              title: 'Projects',
            },
          ],
    },
    {
      title: 'Transactions',
      Icon: () => '',
      links: [
        {
          link: routes.dashboardTransactionRoles,
          title: 'Roles',
        },
        {
          link: routes.dashboardTransactionTaskCategories,
          title: 'Task Categories',
        },
      ],
    },
    {
      title: 'Users',
      Icon: () => '',
      links: [
        {
          link: routes.dashboardUsers,
        },
      ],
    },
    {
      title: 'MLS Agents',
      Icon: () => '',
      links: [
        {
          link: routes.dashboardMlsAgents,
        },
      ],
    },
    {
      title: 'Notification Templates',
      Icon: () => '',
      links: [
        {
          link: routes.dashboardNotificationTemplates,
        },
      ],
    },
    {
      title: 'Login Logs',
      Icon: () => '',
      links: [
        {
          link: routes.dashboardLoginLogs,
        },
      ],
    },
    {
      title: 'PDF Form Builder Demo',
      Icon: () => '',
      links: [
        {
          link: routes.dashboardPdfEditor,
        },
      ],
    },
    {
      title: 'Area classifier',
      Icon: () => '',
      links: [
        {
          link: routes.areaClassifier,
        },
      ],
    },
    {
      title: 'Audit Events',
      Icon: () => '',
      links: [
        {
          link: routes.events,
        },
      ],
    },
    {
      title: 'Sales Lead',
      Icon: () => '',
      links: [
        {
          link: routes.salesLead,
        },
      ],
    },
    {
      title: 'Teams',
      Icon: () => '',
      links: [
        {
          link: routes.teams,
        },
      ],
    },
  ];

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarHead}>
        <Link to={routes.index}>
          <MosaikLogo className={styles.logo} />
        </Link>
      </div>
      <ul className={styles.points}>
        {points.map(({ title, Icon, links, target }) => (
          <li key={title}>
            <Point title={title} Icon={Icon} links={links} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
