import Api from '../core/api';

import {
  requestGetMilestoneAggregate,
  requestUpdateMilestoneAggregate,
  requestAddNewMilestone,
  setMilestonesFiltersAction,
  setMilestonesArchiveAction,
} from 'store/actions/milestoneAggregate';
import { getMilestoneAggregate, addTransactionMilestone } from 'api/milestoneAggregate';

export const getMilestoneAggregateEffect = (cfg = {}, options = {}, cb = () => {}) => {
  const sendRequest = Api.execBase({
    action: requestGetMilestoneAggregate,
    method: getMilestoneAggregate,
  });
  return sendRequest(cfg, options, cb);
};

export const updatedMilestoneStatusEffect = (cfg) => (dispatch) => {
  return dispatch(requestUpdateMilestoneAggregate(cfg));
};

export const setMilestoneFilterEffect = (cfg) => (dispatch) => {
  return dispatch(setMilestonesFiltersAction(cfg));
};

export const setMilestoneArchiveEffect = (cfg) => (dispatch) => {
  return dispatch(setMilestonesArchiveAction(cfg));
};

export const addNewMilestoneEffect = (cfg, options, cb) => {
  const requestParams = {
    action: requestAddNewMilestone, //  create new action for new reducer
    method: addTransactionMilestone,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  const config = {
    transactionId: cfg?.transactionId,
    Milestone: {
      Title: cfg?.milestoneName,
      DueDate: cfg?.dueDate,
      IsPrivate: cfg?.isPrivate,
    },
  };
  return sendRequest(config, options, cb);
};
