import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { TextareaAutosize, Input } from 'components';
import { Select } from 'components-antd';
import { getFormMetaSelect } from 'store/selectors/formBuilder';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getUserFormMetaEffect } from 'store/effects/formBuilder';
import { getAllTeamsEffect } from 'store/effects';
import { getFormattedLocations } from 'helpers';
import { FormGeoLocations } from 'pages/FormBuilder/components/FormGeoLocations';
import { v4 as uuidv4 } from 'uuid';

import styles from '../styles.module.scss';

export const StepOne = (props) => {
  const {
    timelineTitle,
    onTitleChange,
    timelineDescription,
    onDescriptionChange,
    onButtonClick,
    permissions,
    onPermissionsChange,
    loading,
    isViewMode,
  } = props;
  const { users: allUsers = [], teams: allTeams = [] } = useSelector(getFormMetaSelect);
  const { isSuperUser } = useSelector(getUserRolesMapSelector);
  const dispatch = useDispatch();

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    timelineTitle?.length ? setIsDisable(false) : setIsDisable(true);
  }, [timelineTitle]);

  useEffect(() => {
    if (isSuperUser && !allUsers?.length) dispatch(getUserFormMetaEffect());
    if (isSuperUser && !allTeams?.length) dispatch(getAllTeamsEffect());
  }, []);

  const areas = useMemo(() => {
    const val = getFormattedLocations(permissions.Locations)?.[0];
    if (!val?.areasOfOperation?.length) return [];
    const grouped = val.areasOfOperation.reduce((acc, cur) => {
      const radius = cur?.Radius || 0;

      if (!acc[radius]) acc[radius] = [cur];
      else acc[radius].push(cur);
      return acc;
    }, {});

    return Object.keys(grouped).map((key, index) => ({
      UUID: uuidv4(),
      miles: Number(key),
      areasOfOperation: grouped[key],
    }));
  }, [permissions.Locations]);

  return (
    <div className={styles.stepOne}>
      <div className={classNames(styles.inputBlock, styles.timelineName)}>
        <div className={styles.label}>Timeline name</div>
        <Input
          className={styles.input}
          inputHolderClassName={styles.inputHolder}
          variant={Input.LIGHT}
          placeholder="Enter timeline name"
          value={timelineTitle}
          onChange={onTitleChange}
          testid="timeline_name"
          maxLength={50}
          disabled={isViewMode}
        />
      </div>
      <div className={classNames(styles.inputBlock, styles.description)}>
        <div className={styles.label}>Description</div>
        <TextareaAutosize
          className={classNames(styles.descriptionInput)}
          inputHolderClassName={styles.inputHolder}
          placeholder="Enter description"
          variant={Input.LIGHT}
          value={timelineDescription}
          onChange={onDescriptionChange}
          rows={5}
          testid="description"
          maxLength={250}
          disabled={isViewMode}
        />
      </div>
      {isSuperUser && (
        <>
          <div className={classNames(styles.item, styles.people)}>
            <label>People</label>
            <Select
              placeholder="Select People"
              mode="multiple"
              className={styles.select}
              getPopupContainer={(triggerNode) => triggerNode}
              options={allUsers.map((el) => ({
                label: el.Name,
                value: el.UserId,
                key: `users-${el.UserId}`,
              }))}
              onChange={(val) => onPermissionsChange('People', val)}
              value={permissions.People}
              filterOption={(input, option) =>
                String(option?.label || '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              disabled={isViewMode}
            />
          </div>
          <div className={styles.item}>
            <label>Teams</label>
            <Select
              placeholder="Select Teams"
              mode="multiple"
              className={styles.select}
              getPopupContainer={(triggerNode) => triggerNode}
              options={allTeams.map((el) => ({
                label: el.Name,
                value: el.Id,
                key: `Team-${el.Id}`,
              }))}
              onChange={(val) => onPermissionsChange('Teams', val)}
              value={permissions.Teams ?? []}
              filterOption={(input, option) =>
                String(option?.label || '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              disabled={isViewMode}
            />
          </div>
          {!loading ? (
            <div className={styles.itemPlain}>
              <label>Locations</label>
              <FormGeoLocations
                showMiles={true}
                multiple={true}
                onChange={(val) => onPermissionsChange('Locations', val)}
                locations={areas || []}
                disabled={true}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      <div className={styles.modalFooter}>
        <div className={styles.stepOneBtn}>
          <button
            className={classNames(styles.saveBtn, { [styles.disabled]: isDisable })}
            disabled={isDisable}
            onClick={onButtonClick}
          >
            <span>Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};
