import { AreaTypeRefined } from 'store/reducers/adminPanel/areaClassifier/selectedAreaGroup';

export const areaTypeReadableNames = {
  [AreaTypeRefined.LargeCity]: 'Large City',
  [AreaTypeRefined.SmallCity]: 'Small City',
  [AreaTypeRefined.MediumCity]: 'Medium City',
  [AreaTypeRefined.LargeTown]: 'Large Town',
  [AreaTypeRefined.SmallTown]: 'Small Town',
  [AreaTypeRefined.Rural]: 'Rural',
};
