import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { getClientContextsStateSelector } from 'store/selectors/contexts';
import { getListingDetailFavoriteData } from 'store/selectors/listingDetail';
import { getCurrentContextSelector, getSelectedContextIdSelector } from 'store/selectors/context';
import { toggleFavoriteListingDetailEffect } from 'store/effects/listingDetail';
import { READY, IDLE } from 'settings/constants/apiState';
import { Heart } from 'components/Icons';
import { Button } from 'components';
import { listingDetailToggleFavoriteListingAction } from 'store/actions/listingDetail';
import { getClientContextsEffect } from 'store/effects/context';
import { setCurrentContextAction, setSelectedContextIdAction } from 'store/actions/context';
import styles from './styles.module.scss';
import IconButton from '../PhotosViewModal/IconButton';

const HomeActionsAddToFavorites = ({ variant }) => {
  const { id, isFavorite, isLoading } = useSelector(getListingDetailFavoriteData);
  const context = useSelector(getCurrentContextSelector);
  const contextsState = useSelector(getClientContextsStateSelector);
  const agentId = useSelector(getSelectedContextIdSelector);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      toggleFavoriteListingDetailEffect(
        { id, isFavorite: !isFavorite, contextKey: context.ContextKey },
        {},
        (err) => {
          if (!err) {
            dispatch(listingDetailToggleFavoriteListingAction({ flag: !isFavorite }));
          }
        },
      ),
    );
  };

  useEffect(() => {
    if (!context && contextsState.state === READY) {
      const contexts = contextsState.data || [];
      const selectedContext = contexts[0];

      // TODO: we won't depend on specific agent
      const firstAgent = selectedContext?.Agents[0];
      if (!agentId && firstAgent) {
        dispatch(setCurrentContextAction(selectedContext));
      }
    }
  }, [contextsState.state]);

  const fetchContexts = useCallback(() => dispatch(getClientContextsEffect()), [dispatch]);

  useEffect(() => {
    if (contextsState.state === IDLE) fetchContexts();
  }, []);

  if (variant === 'modal')
    return (
      <IconButton
        Icon={Heart}
        onClick={onClick}
        variant={isFavorite ? undefined : 'hollow'}
        color={isFavorite ? '#FF576D' : '#515151'}
        isPending={isLoading}
      />
    );
  else
    return (
      <Button
        className={classNames(styles.homeActionsButton)}
        onClick={onClick}
        title={isFavorite ? 'Favorited' : 'Favorites'}
        titleClassName={styles.buttonTitle}
        icon={
          <Heart
            className={styles.buttonIcon}
            variant={isFavorite ? undefined : 'hollow'}
            color={isFavorite ? '#FF576D' : '#515151'}
          />
        }
        isPending={isLoading}
        inverseColor
        testid="favorites"
      />
    );
};

export default HomeActionsAddToFavorites;
