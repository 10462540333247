import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getUserRolesMapSelector } from 'store/selectors/user';
import { useSelector } from 'react-redux';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import UserHeader from './UserHeader';
import ThirdPartyHeader from './ThirdPartyHeader';

import styles from './styles.module.scss';

const QuoteHeader = (props) => {
  const { className, onCancel } = props;
  const { isPending } = useSelector(getQuoteDetailsSelector);
  const { isThirdParty } = useSelector(getUserRolesMapSelector);

  if (isPending) return null;

  return (
    <div testid="quote_details" className={classNames(styles.quoteHeader, className)}>
      {isThirdParty ? <ThirdPartyHeader onCancel={onCancel} /> : <UserHeader />}
    </div>
  );
};

QuoteHeader.propTypes = {
  className: PropTypes.string,
};

QuoteHeader.defaultProps = {
  className: '',
};

export default QuoteHeader;
