import PropTypes from 'prop-types';
import classNames from 'classnames';

import Invite from './Invite';
import Participant from './Participant';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { Wrapper } from 'components';

const Participants = (props) => {
  const { className, participants, isPending } = props;
  const isProject = useIsProjectRoute();

  return (
    <div className={classNames(styles.participants, className)}>
      <div className={styles.title}>
        <Invite />
      </div>
      <Wrapper isPending={isPending} spinnerClassname={styles.spinnerMarginTop}>
        <div className={styles.participantContainer}>
          <p className={styles.text}>Invited to {isProject ? 'Project' : 'Transaction'}</p>
          {participants?.length === 0 ? (
            <div className={styles.emptyContainer}>No Invitees</div>
          ) : (
            participants?.map((participant, idx) => (
              <div key={participant?.Id}>
                <Participant {...participant} />
                {idx !== participants?.length - 1 && <div className={styles.seperator} />}
              </div>
            ))
          )}
        </div>
      </Wrapper>
    </div>
  );
};

Participants.propTypes = {
  className: PropTypes.string,
  participants: PropTypes.arrayOf(PropTypes.shape({})),
  isPending: PropTypes.bool,
};

Participants.defaultProps = {
  className: '',
  participants: [],
  isPending: false,
};

export default Participants;
