import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Wrapper as PendingWrapper } from 'components';
import { getFeedPageInfoSelector, getSearchInstancePropsSelector } from 'store/selectors/feed';
import { PENDING } from 'settings/constants/apiState';
import { PropertiesWrapper, PropertiesList } from 'pages/Properties/Feed/components';
import { useNoMlsAccessHandler } from '../../hooks/useNoMlsAccessHandler';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getSearchInstancePropsV2Effect } from 'store/effects/feed/feedV2';
import { ListSkeleton } from 'components-antd';

import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';

const SearchInstance = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const searchInstanceProps = useSelector(getSearchInstancePropsSelector);
  const currentPageInfo = useSelector(getFeedPageInfoSelector);

  const { hasMlsAccess, wrappedEffect: getSearchInstancePropsEffectWrapped } =
    useNoMlsAccessHandler(getSearchInstancePropsV2Effect);

  useEffect(() => {
    if (
      isAgent &&
      (!currentPageInfo?.currentFEPageInfo?.fromListingDetailPage ||
        !searchInstanceProps?.data?.length)
    )
      dispatch(getSearchInstancePropsV2Effect());
  }, []);

  const isPending = searchInstanceProps.state === PENDING;

  return (
    <PropertiesWrapper>
      {(wrapperSize, getNextPageIsPending) => (
        <PendingWrapper
          isPending={isPending}
          className={styles.pendingWrapper}
          customLoader={<ListSkeleton />}
        >
          <PropertiesList
            list={searchInstanceProps?.data}
            wrapperSize={wrapperSize}
            getNextPageIsPending={getNextPageIsPending}
            hasMlsAccess={hasMlsAccess}
            loadDataEffect={getSearchInstancePropsEffectWrapped}
          />
        </PendingWrapper>
      )}
    </PropertiesWrapper>
  );
};

export default SearchInstance;
