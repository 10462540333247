import ThirdPartyIntegrations from './ThirdPartyIntegrations';
import MlsIntegration from './MlsIntegration';

import styles from './styles.module.scss';

const Integrations = () => {
  return (
    <div>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>Integrations</h2>
      </div>
      <div>
        <div>
          <MlsIntegration />
        </div>
        <div>
          <ThirdPartyIntegrations />
        </div>
      </div>
    </div>
  );
};

export default Integrations;
