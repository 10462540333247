import { useState, useEffect } from 'react';
import { Card, Separator } from './..';
import { Row, Col } from 'components-antd';
import styles from './styles.module.scss';
import { CommissionUnit } from './components';

const HeaderText = ({ text }) => <span className={styles.headerText}>{text}</span>;

export const Commission = ({
  financials,
  fetchFinancials,
  isDualTransaction,
  isSellerTransaction,
}) => {
  const [editFieldKey, setEditFieldKey] = useState(null);
  const [transactionPrice, setTransactionPrice] = useState(0);
  const isDisableField = (editKey) => editFieldKey !== null && editFieldKey !== editKey;

  let _buyerSideComission = financials && financials?.TransactionCommission?.BuyerCommissionFees;
  let _sellerSideComission = financials && financials?.TransactionCommission?.SellerCommissionFees;

  useEffect(() => {
    let price = financials && financials?.PropertyTransactionKeyDatesPrices;
    const selectedPrice =
      price?.ClosePrice ?? price?.UnderContractPrice ?? price?.ListingPrice ?? 0;
    setTransactionPrice(selectedPrice);
  }, [financials]);

  const calculateGCI = (rate) => {
    return (rate / 100) * transactionPrice;
  };

  const calculateRate = (GCI) => {
    return (GCI * 100) / transactionPrice;
  };

  const getRate = (_comission) => {
    if (_comission?.IsFeeTypePercentage) {
      return _comission?.Value;
    } else {
      return calculateRate(_comission?.Value);
    }
  };

  const getGCI = (_comission) => {
    if (!_comission?.IsFeeTypePercentage) {
      return _comission?.Value;
    } else {
      return calculateGCI(_comission?.Value);
    }
  };

  const getTotal = (buyerValue, sellerValue) => {
    if (buyerValue === null && sellerValue === null) {
      return null;
    } else if (buyerValue === null && sellerValue !== null) {
      return sellerValue;
    } else if (buyerValue !== null && sellerValue === null) {
      return buyerValue;
    } else {
      return sellerValue + buyerValue;
    }
  };

  let _rateBuyer = getRate(_buyerSideComission);
  let _gciBuyer = getGCI(_buyerSideComission);
  let _rateSeller = getRate(_sellerSideComission);
  let _gciSeller = getGCI(_sellerSideComission);
  let _gciTotal = getTotal(_gciBuyer, _gciSeller);

  return (
    <Card>
      <div className={styles.commissionContainer}>
        <p className={styles.title}>Commission</p>
        <Row gutter={8}>
          <Col lg={14}>
            <HeaderText text="Commission" />
          </Col>
          <Col lg={4}>
            <HeaderText text="Rate" />
          </Col>
          <Col lg={6}>
            <HeaderText text="GCI" />
          </Col>
        </Row>
        <Separator className={styles.seperatorMargin} />
        <CommissionUnit
          label="Buy Side"
          rate={_rateBuyer}
          gci={_gciBuyer}
          gciOther={_gciSeller}
          transactionPrice={transactionPrice}
          fieldKey="buySide"
          setEditField={setEditFieldKey}
          isDisableField={isDisableField}
          calculateGCI={calculateGCI}
          calculateRate={calculateRate}
          fetchFinancials={fetchFinancials}
          isFeeTypePercentage={_buyerSideComission?.IsFeeTypePercentage}
        />
        <Separator />
        {(isSellerTransaction || isDualTransaction) && (
          <CommissionUnit
            label="List Side"
            rate={_rateSeller}
            gci={_gciSeller}
            gciOther={_gciBuyer}
            fieldKey="listSide"
            transactionPrice={transactionPrice}
            setEditField={setEditFieldKey}
            isDisableField={isDisableField}
            calculateGCI={calculateGCI}
            calculateRate={calculateRate}
            fetchFinancials={fetchFinancials}
            isFeeTypePercentage={_sellerSideComission?.IsFeeTypePercentage}
          />
        )}
        {(isSellerTransaction || isDualTransaction) && <Separator />}
        {(isSellerTransaction || isDualTransaction) && (
          <CommissionUnit
            label="Total"
            rate={getTotal(_rateBuyer, _rateSeller)}
            gci={_gciTotal}
            notEditable
            isDisableField={isDisableField}
            hidePriceHighlighter
          />
        )}
      </div>
    </Card>
  );
};
