import { forwardRef } from 'react';
import AntButton, { ButtonProps as AntButtonProps } from 'antd/lib/button';
import classNames from 'classnames';

import styles from './styles.module.scss';

type variants =
  | 'primary'
  | 'secondary'
  | 'secondary-inverted'
  | 'tertiary'
  | 'default'
  | 'link'
  | 'go-back';

export interface ButtonProps extends AntButtonProps {
  variant?: variants;
  title?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'primary', className, title, ...props }, ref) => (
    <AntButton
      type={variant === 'link' ? 'link' : undefined}
      className={classNames(styles.button, styles[variant], className)}
      ref={ref}
      icon={
        <>
          {variant === 'go-back' && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.goBackIcon}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.694 18.6943C16.102 18.2867 16.102 17.6259 15.694 17.2184L10.4699 12L15.694 6.78165C16.102 6.37408 16.102 5.71326 15.694 5.30568C15.2859 4.89811 14.6244 4.8981 14.2164 5.30568L8.30602 11.2096C8.08861 11.4267 7.98704 11.7158 8.00132 12.0002C7.98714 12.2844 8.08871 12.5733 8.30604 12.7904L14.2164 18.6943C14.6244 19.1019 15.286 19.1019 15.694 18.6943Z"
                fill="#515151"
              />
            </svg>
          )}
        </>
      }
      {...props}
    >
      {title && title}
    </AntButton>
  ),
);
