import { Row, Col, Table } from 'components-antd';
import { PropertyDocumentCard } from 'pages/Vault/components';
import { DocsContentView } from 'pages/Vault/constants';

import styles from './styles.module.scss';

export const DocumentsContent = ({
  title,
  totalCount,
  columns,
  data,
  Property = {},
  viewType,
  isPreapprovals = false,
  isArchives = false,
  refetch,
}) => {
  // Dont show content if there are no documents data
  if (!data?.length) return null;

  return (
    <div className={styles.vaultDocContent}>
      <div className={styles.title}>
        <span>{title}</span>
        <span className={styles.counter}>({totalCount})</span>
      </div>
      {viewType === DocsContentView.GRID_VIEW ? (
        <Row gutter={24}>
          {data?.map((item, idx) => (
            <Col key={idx} span={6}>
              <PropertyDocumentCard
                file={item}
                Property={Property}
                isPreapprovals={isPreapprovals}
                isArchives={isArchives}
                viewType={viewType}
                refetch={refetch}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <Table
          columns={columns}
          dataSource={data.map((item, key) => ({ ...item, key }))} // Insert unique 'key' in every row
          pagination={false}
        />
      )}
    </div>
  );
};
