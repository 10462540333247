import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getDynamicFormSignatureConfigSelector } from 'store/selectors/requestFormProcess';
import { SignatureFormRole } from 'types';
import { updateHideQuestionRoleEffect } from 'store/effects/formProcess';
import { getSignatoryColor } from 'app-constants';

import styles from './styles.module.scss';

export const UserRoles = () => {
  const dispatch = useDispatch();

  const { allFormRoles, hideQuestionRoles = [] } = useSelector(
    getDynamicFormSignatureConfigSelector,
  );

  const handleRoleClick = (role: SignatureFormRole) => {
    let updatedRoles = [...hideQuestionRoles];
    let checked = updatedRoles.includes(role.roleId);

    if (checked) {
      updatedRoles = updatedRoles.filter((r) => r !== role.roleId);
    } else {
      updatedRoles.push(role.roleId);
    }

    dispatch(
      updateHideQuestionRoleEffect({
        hideQuestions: updatedRoles,
      }),
    );
  };

  return (
    <div className={styles.stickyBottom}>
      <div className={styles.userRoleContainer}>
        {allFormRoles.map((role, index) => (
          <div className={styles.userRow} onClick={() => handleRoleClick(role)} key={role.roleId}>
            <input
              type="checkbox"
              className={classNames(styles.checkbox)}
              checked={!hideQuestionRoles?.includes(role.roleId)}
              style={{
                accentColor: getSignatoryColor(index).border,
              }}
            />
            <span className={styles.userTitle}>{role.roleName}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
