import { useState } from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Counter } from 'components';
import { Row, Col } from 'components-antd';
import { prefsIds, PARKING_GARAGE_TYPES, PREF_TYPE } from 'settings/constants/preferences';

import styles from './styles.module.scss';

export const ParkingSpaces = ({
  id,
  softCriteria,
  type,
  variant,
  addSoftCriteria,
  selectedImportance,
}) => {
  const parkingData = softCriteria[id]?.data;
  const [garageOnly, setGarageOnly] = useState(parkingData?.garageOnly || false);
  const [attachedGarage, setAttachedGarage] = useState(
    parkingData?.garageType === PARKING_GARAGE_TYPES.Attached,
  );
  const rvParking = prefsIds.rvParking in softCriteria;

  const formatData = (count, garageFlag, attachedGarageFlag) => ({
    count,
    garageOnly: !!garageFlag,
    ...(garageFlag && attachedGarageFlag
      ? { garageType: PARKING_GARAGE_TYPES.Attached }
      : garageFlag
      ? { garageType: PARKING_GARAGE_TYPES.Detached }
      : {}),
  });

  const onSubmit = ({ count, garageOnly, attachedGarage }) => {
    addSoftCriteria(type, id, selectedImportance, formatData(count, garageOnly, attachedGarage));
  };

  return (
    <>
      <div className={styles.parkingSpaces}>
        <h4>How many parking spaces do you need? </h4>
        <div className={styles.counterWrapper}>
          <p>Room for</p>
          <Counter
            limit={{ min: 0 }}
            addClassName={styles.add}
            removeClassName={styles.remove}
            numberClassName={styles.number}
            onChange={(val) => {
              onSubmit({
                count: val,
                garageOnly,
                attachedGarage,
              });
            }}
            value={parkingData?.count || 0}
            AddComponent={(props) => (
              <svg
                width="28"
                height="28"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
              >
                <circle cx="28" cy="28" r="28" fill="white" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M29.5 18C29.5 17.1716 28.8284 16.5 28 16.5C27.1716 16.5 26.5 17.1716 26.5 18V26.5H18C17.1716 26.5 16.5 27.1716 16.5 28C16.5 28.8284 17.1716 29.5 18 29.5H26.5V38C26.5 38.8284 27.1716 39.5 28 39.5C28.8284 39.5 29.5 38.8284 29.5 38V29.5H38C38.8284 29.5 39.5 28.8284 39.5 28C39.5 27.1716 38.8284 26.5 38 26.5H29.5V18Z"
                  fill="#ADADAD"
                />
              </svg>
            )}
            RemoveComponent={(props) => (
              <svg
                width="28"
                height="28"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
              >
                <circle cx="28" cy="28" r="28" fill="white" />
                <path
                  d="M18 28L38 28"
                  stroke="#ADADAD"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          />
          <p>Vehicles</p>
        </div>
      </div>
      <div className={styles.parkingSpaces}>
        <Row gutter={12} className={styles.row}>
          <Col lg={12}>
            <Checkbox
              label="Garage Parking Preferred"
              direction={Checkbox.DIRECTION_RIGHT}
              checked={garageOnly}
              value={garageOnly}
              onChange={(e) => {
                const bool = e.target.checked;
                setGarageOnly(bool);
                onSubmit({
                  count: parkingData?.count || 0,
                  garageOnly: bool,
                  attachedGarage,
                });
              }}
              variant={Checkbox.CONFETTI_BLUE}
            />
          </Col>
          <Col lg={12}>
            <Checkbox
              label="RV Parking"
              direction={Checkbox.DIRECTION_RIGHT}
              checked={rvParking}
              onChange={(e) => {
                const { checked } = e.target;
                addSoftCriteria(
                  PREF_TYPE.outdoorPrefs,
                  checked ? prefsIds.rvParking : null,
                  selectedImportance,
                  checked ? [{ id: prefsIds.rvParking }] : null,
                  checked ? null : [prefsIds.rvParking],
                );
              }}
              variant={Checkbox.CONFETTI_BLUE}
            />
          </Col>
          {garageOnly && (
            <Col lg={12}>
              <Checkbox
                label="Attached Garage Preferred"
                direction={Checkbox.DIRECTION_RIGHT}
                checked={attachedGarage}
                onChange={(e) => {
                  const bool = e.target.checked;
                  setAttachedGarage(bool);
                  onSubmit({
                    count: parkingData?.count || 0,
                    garageOnly,
                    attachedGarage: bool,
                  });
                }}
                variant={Checkbox.CONFETTI_BLUE}
              />
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

ParkingSpaces.propTypes = {
  softCriteria: PropTypes.shape({}),
  type: PropTypes.string,
  variant: PropTypes.string,
  addSoftCriteria: PropTypes.any,
};

ParkingSpaces.defaultProps = {
  softCriteria: {},
  type: '',
  variant: '',
  addSoftCriteria: () => {},
};
