import { handleActions } from 'redux-actions';
import { cloneDeep, get } from 'lodash-es';

import { openTransactionParticipantDrawerAction } from 'store/actions/drawers/transactionParticipant';
import { getTransactionParticipantAction } from 'store/actions/transactions';
import { appLogoutAction } from 'store/actions/app';
import { IDLE } from 'settings/constants/apiState';

const initialData = {
  open: false,
  params: {},
  participantInfo: {
    state: IDLE,
    data: null,
    meta: {},
  },
};

export default handleActions(
  {
    [openTransactionParticipantDrawerAction]: (state, { payload }) => ({
      ...state,
      open: payload?.open,
      params: payload?.params || {},
      participantInfo: payload?.open ? state.participantInfo : initialData.participantInfo,
    }),
    [getTransactionParticipantAction]: (state, { payload }) => ({
      ...state,
      participantInfo: {
        state: payload.state,
        data: get(payload, 'data', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      },
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
