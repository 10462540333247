import { AgentDetailsUpdate } from 'types';
import FormattedPhone from 'components/FormattedPhone';

import styles from './styles.module.scss';
import classNames from 'classnames';

const AgentDetails = (props) => {
  const { SellerAgent, Brokerage, agentNameClass, subTitleClass, agentPhoneClass, emailClass } =
    props;

  const agentData: AgentDetailsUpdate = {
    ...SellerAgent,
    Phone: SellerAgent?.Phones?.find((item) => item.IsPrimary)?.PhoneNumber,
  };
  return (
    <div>
      {SellerAgent?.FirstName ||
        (SellerAgent?.LastName && (
          <p
            className={classNames(styles.agentName, agentNameClass)}
          >{`${SellerAgent?.FirstName} ${SellerAgent?.LastName}`}</p>
        ))}
      {Brokerage?.Name && (
        <p className={classNames(styles.subTitle, subTitleClass)}>{Brokerage?.Name}</p>
      )}
      {agentData?.Phone && (
        <p className={classNames(styles.agentPhone, agentPhoneClass)}>
          <FormattedPhone testid="view_phone">{agentData?.Phone}</FormattedPhone>
        </p>
      )}
      {agentData?.Email && (
        <a className={classNames(styles.subTitle, emailClass)}>{agentData?.Email}</a>
      )}
    </div>
  );
};

export default AgentDetails;
