import { useState } from 'react';
import Tooltip from 'antd/lib/tooltip';

import { Paragraph } from 'components-antd';

import styles from './styles.module.scss';

export const TooltipParagraph = ({ children, ellipsis, ...props }) => {
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip
      destroyTooltipOnHide
      overlayClassName={styles.tooltip}
      title={truncated ? children : undefined}
    >
      <Paragraph {...props} ellipsis={{ ...ellipsis, onEllipsis: setTruncated }}>
        <>{children}</>
      </Paragraph>
    </Tooltip>
  );
};
