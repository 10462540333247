import { Circle, DrawingManager, Polygon, Rectangle } from '@react-google-maps/api';
import Map from 'components/Map';
import React, { useEffect, useRef, useState } from 'react';
import { Polygon as GeojsonPolygon, Feature } from 'geojson';
import {
  getVisibleShapesSelector,
  isEditableShapeSelectedSelector,
} from 'store/selectors/adminPanel/areaClassifier';
import { useDispatch, useSelector } from 'react-redux';
import {
  ShapeType,
  VisibleShapeData,
} from 'store/reducers/adminPanel/areaClassifier/selectedAreaGroup';
import { newShapeDrawnAction } from 'store/actions/adminPanel';

import './DrawingMap.scss';

const polygonOptions: google.maps.PolygonOptions = {
  fillOpacity: 0.08,
};

const mapDefaultCenter = {
  lat: 41.8781136,
  lng: -87.6297982,
}; // Chicago

interface DrawingMapProps {
  id: string;
}

export const DrawingMap: React.FC<DrawingMapProps> = (props) => {
  const visibleShapes = useSelector(getVisibleShapesSelector);
  const isEditMode = useSelector(isEditableShapeSelectedSelector);
  const dispatch = useDispatch();

  const drawingManager = useRef<google.maps.drawing.DrawingManager>();

  const [shapes, setShapes] = useState<AllowedGmapsShape[]>([]);

  useEffect(() => {
    shapes?.forEach((shape) => shape.setMap(null));
    const newShapes = visibleShapes.map((shape) => {
      const gmapsShape = createGmapsObjectFromVisibleShape(shape);
      return gmapsShape;
    });

    setShapes(newShapes);
  }, [visibleShapes]);

  const handlerLoadDrawingManager = (drawingManagerInstance) => {
    drawingManager.current = drawingManagerInstance;
  };

  useEffect(() => {
    if (isEditMode) {
      shapes?.forEach((shape) => shape.setMap(null));

      setShapes([]);
    }
  }, [isEditMode]);

  const handlePolygonComplete = async (polygon: google.maps.Polygon) => {
    // Update redux with the new polygon geojson
    const geojsonPolygon = await gmapsPolygonToGeoJson(polygon);
    polygon.setMap(null);
    dispatch(
      newShapeDrawnAction({
        shapeData: {
          type: ShapeType.POLYGON,
          geoJson: geojsonPolygon,
        },
      }),
    );
  };

  const renderShapes = () => {
    return shapes.map((shape, index) => {
      const relevantVisibleShape = visibleShapes[index];
      const shapeOptions: google.maps.PolygonOptions | google.maps.CircleOptions = {
        ...polygonOptions,
        ...(relevantVisibleShape
          ? {
              strokeColor: relevantVisibleShape.color,
              fillColor: relevantVisibleShape.color,
            }
          : {}),
      };

      if (shape instanceof google.maps.Polygon) {
        return <Polygon paths={shape.getPath()} options={shapeOptions} />;
      } else if (shape instanceof google.maps.Circle) {
        return (
          <Circle center={shape.getCenter()!} radius={shape.getRadius()} options={shapeOptions} />
        );
      } else if (shape instanceof google.maps.Rectangle) {
        return <Rectangle bounds={shape.getBounds()!} options={shapeOptions} />;
      }
    });
  };

  return (
    <Map id={props.id} mapContainerClassName={'drawingMap'} center={mapDefaultCenter}>
      <DrawingManager
        onLoad={handlerLoadDrawingManager}
        drawingMode={isEditMode ? window.google.maps.drawing.OverlayType.POLYGON : null}
        onPolygonComplete={handlePolygonComplete}
        options={{
          polygonOptions,
          drawingControl: false,
          drawingControlOptions: {
            drawingModes: [
              window.google.maps.drawing.OverlayType.POLYGON,
              window.google.maps.drawing.OverlayType.CIRCLE,
              window.google.maps.drawing.OverlayType.RECTANGLE,
            ],
          },
        }}
      />
      {renderShapes()}
    </Map>
  );
};

async function gmapsPolygonToGeoJson(
  polygon: google.maps.Polygon,
): Promise<Feature<GeojsonPolygon>> {
  var dataLayer = new window.google.maps.Data();
  dataLayer.add(
    new window.google.maps.Data.Feature({
      geometry: new window.google.maps.Data.Polygon([polygon.getPath().getArray()]),
    }),
  );

  return new Promise((resolve) => {
    dataLayer.toGeoJson((geoJsonValue) => {
      const firstFeature = (geoJsonValue as any).features[0];
      resolve(firstFeature);
    });
  });
}

function createGmapsObjectFromVisibleShape(shape: VisibleShapeData): AllowedGmapsShape {
  if (shape.type === ShapeType.POLYGON) {
    const gmapsPolygon = new google.maps.Polygon();
    const coordinates = shape.geoJson.geometry.coordinates[0]
      .slice(0, -1) // remove the last point which is the same as the first in geojson
      .map(([lng, lat]) => new google.maps.LatLng(lat, lng));
    gmapsPolygon.setPaths(coordinates);
    return gmapsPolygon;
  } else if (shape.type === ShapeType.CIRCLE) {
    const gmapsCircle = new google.maps.Circle({
      center: new google.maps.LatLng(
        shape.geoJson.geometry.coordinates[1],
        shape.geoJson.geometry.coordinates[0],
      ),
      radius: shape.geoJson.properties.radius,
    });
    return gmapsCircle;
  } else if (shape.type === ShapeType.RECTANGLE) {
    const gmapsRectangle = new google.maps.Rectangle({
      bounds: {
        north: shape.geoJson.geometry.coordinates[1][1][1],
        south: shape.geoJson.geometry.coordinates[0][1][1],
        east: shape.geoJson.geometry.coordinates[1][0][0],
        west: shape.geoJson.geometry.coordinates[0][0][0],
      },
    });
    return gmapsRectangle;
  }

  throw new Error('Unknown shape type');
}

export type AllowedGmapsShape = google.maps.Polygon | google.maps.Circle | google.maps.Rectangle;
