import { CLIENT } from 'settings/constants/roles';
import StageInteriorPrefs from 'pages/OnBoardingWizard/components/StageParts/StageInteriorPrefs';
import {
  StagePrefs,
  StageNeighbors,
  StageCommute,
  StageMustHave,
  StagePreApproved,
  StageApproved,
  StageDocument,
  StageComplete,
  ApproveStart,
  ApproveBuyingProcess,
  ApproveKind,
  ApproveWhat,
  ApproveMilitary,
  ApproveEmployment,
  ApproveTaxes,
  ApprovePriceRange,
  ApproveDownPayment,
  ApproveEstimateCredit,
  ApproveWasSale,
  ApproveBorrower,
  ApproveTrustedLenders,
  ApproveComplete,
} from '../components';
import { PRE_APPROVAL } from 'settings/constants/preApproval';

export const additionalQuestions = [
  { id: 'approveStart', Component: ApproveStart },
  { id: 'approveBuyingProcess', Component: ApproveBuyingProcess },
  { id: 'approveKind', Component: ApproveKind },
  { id: 'approveWhat', Component: ApproveWhat },
  { id: 'approveMilitary', Component: ApproveMilitary },
  { id: 'approveEmployment', Component: ApproveEmployment },
  { id: 'approveTaxes', Component: ApproveTaxes },
  { id: 'approvePriceRange', Component: ApprovePriceRange },
  { id: 'approvePayment', Component: ApproveDownPayment },
  { id: 'approveCredit', Component: ApproveEstimateCredit },
  { id: 'approveWasSale', Component: ApproveWasSale },
  { id: 'approveBorrower', Component: ApproveBorrower },
  { id: 'approveTrustLenders', Component: ApproveTrustedLenders },
  { id: 'approveComplete', Component: ApproveComplete },
];

export const stageDocument = [{ id: 'stageDocument', Component: StageDocument }];

export const stageApproved = [{ id: 'stageApproved', Component: StageApproved }];

export const clientStages = [
  { id: 'stageKind', Component: StagePrefs },
  { id: 'stageInteriorPrefs', Component: StageInteriorPrefs },
  { id: 'stageNeighbors', Component: StageNeighbors },
  { id: 'stageCommute', Component: StageCommute },
  { id: 'stagePreApproved', Component: StagePreApproved },
  // Pre-approval flow to be revamped
  // ...stageApproved,
  // ...additionalQuestions,
  { id: 'stageComplete', Component: StageComplete },
];

export const clientPreApprovalStages = [
  ...additionalQuestions,
  { id: 'stageComplete', Component: StageComplete },
];

export const stagesMap = {
  [CLIENT]: clientStages,
  [PRE_APPROVAL]: clientPreApprovalStages,
};
