/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getLocationData } from 'helpers/locations';

import Label from './Label';
import Wrapper from './Wrapper';
import { SearchIcon } from '../../Icons';
import GeoCoderComponent from './Geocoder';

import styles from './styles.module.scss';

const SingleLocation = (props) => {
  const {
    testid,
    name,
    className,
    language,
    error,
    label,
    labelClassName,
    inputLabel,
    allowedManualInput,
  } = props;
  const {
    blockClassName,
    errorClassName,
    altLabel,
    altLabelClassName,
    dropdownClassName,
    searchIcon,
  } = props;
  const { onResult, onChange, placeholder, variant, value, trackProximity, disabled } = props;
  const { countries, types, allowStates, valuesWrapperClassName, popupClassName, autoFocus } =
    props;
  const { searchWrapperClassName, searchIconClassName, onClear } = props;

  const valuesWrapperRef = useRef();
  const geoCoderRef = useRef();

  const onResultHandler = useCallback(
    (result) => {
      const preparedData = getLocationData(result, allowStates);
      if (!preparedData) return null;

      onResult(result, [preparedData]);
    },
    [value],
  ); // eslint-disable-line

  return (
    <Wrapper disabled={disabled} className={classNames(styles.searchWrapper, className)}>
      <Label
        label={label}
        className={labelClassName}
        altLabel={altLabel}
        altLabelClassName={altLabelClassName}
      />
      <div className={classNames(styles.block, blockClassName)}>
        <GeoCoderComponent
          ref={geoCoderRef}
          name={name}
          onResult={onResultHandler}
          language={language}
          placeholder={placeholder}
          trackProximity={trackProximity}
          countries={countries}
          value={value}
          types={types}
          onChange={onChange}
          variant={variant}
          className={popupClassName}
          dropdownClassName={dropdownClassName}
          searchWrapperClassName={classNames(styles[variant], searchWrapperClassName)}
          onClear={onClear}
          autoFocus={autoFocus}
          inputLabel={inputLabel}
          allowedManualInput={allowedManualInput}
        />

        {searchIcon && (
          <div
            ref={valuesWrapperRef}
            className={classNames(styles.valuesWrapper, styles[variant], valuesWrapperClassName)}
            testid={testid}
          >
            <SearchIcon className={classNames(styles.searchIcon, searchIconClassName)} />
          </div>
        )}
      </div>
      {error && (
        <div testid="validation" className={classNames(styles.error, errorClassName)}>
          {error}
        </div>
      )}
    </Wrapper>
  );
};

SingleLocation.LIGHT = 'light';
SingleLocation.LIGHT_FULL = 'lightFull';
SingleLocation.FULL = 'full';

SingleLocation.propTypes = {
  name: PropTypes.string,
  searchIcon: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  blockClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  valuesWrapperClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  popupClassName: PropTypes.string,
  language: PropTypes.string,
  label: PropTypes.string,
  inputLabel: PropTypes.string,
  error: PropTypes.string,
  altLabel: PropTypes.string,
  altLabelClassName: PropTypes.string,
  onResult: PropTypes.func,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.shape({
    ProviderPlaceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    PlaceName: PropTypes.string,
  }),
  trackProximity: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  testid: PropTypes.string,
  countries: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  allowStates: PropTypes.bool,
  searchWrapperClassName: PropTypes.string,
  searchIconClassName: PropTypes.string,
  onClear: PropTypes.func,
  autoFocus: PropTypes.bool,
  allowedManualInput: PropTypes.bool,
};

SingleLocation.defaultProps = {
  testid: undefined,
  searchIcon: true,
  name: undefined,
  className: '',
  labelClassName: '',
  blockClassName: '',
  errorClassName: '',
  valuesWrapperClassName: '',
  dropdownClassName: '',
  popupClassName: '',
  language: undefined,
  error: undefined,
  label: '',
  inputLabel: '',
  altLabel: '',
  altLabelClassName: '',
  onResult: () => {},
  onChange: () => {},
  placeholder: 'Enter address or location name',
  variant: 'lightFull',
  value: {},
  trackProximity: true,
  disabled: false,
  countries: undefined,
  types: undefined,
  allowStates: false,
  searchWrapperClassName: '',
  searchIconClassName: '',
  onClear: () => {},
  autoFocus: false,
  allowedManualInput: false,
};

export default SingleLocation;
