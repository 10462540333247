import { useEffect, useState } from 'react';

import { Button, Col, Modal, ModalProps, Row } from 'components-antd';
import { Rule, RuleList } from '..';
import { TransactionTemplate, RuleType } from 'types';
import {
  addNewTransactionTemplateRule,
  deleteTransactionTemplateRule,
  getAllTransactionTemplateRules,
} from 'api/rules';

import styles from './styles.module.scss';

interface RulesModalProps extends ModalProps {
  rulesModalVisible: boolean;
  transactionTemplate?: TransactionTemplate;
}

export const RulesModal = ({
  rulesModalVisible,
  transactionTemplate,
  ...props
}: RulesModalProps) => {
  const [rules, setRules] = useState<RuleType[]>();
  const [showAdd, setShowAdd] = useState(false);

  const addNewRule = async (rule: RuleType) => {
    if (transactionTemplate?.Id) {
      await addNewTransactionTemplateRule({
        ...rule,
        transactionTemplateId: transactionTemplate?.Id,
      });

      getAllRules();
      setShowAdd(false);
    }
  };

  const onDeleteRule = async (templateRuleId: number) => {
    await deleteTransactionTemplateRule({
      templateRuleId,
    });

    getAllRules();
  };

  const getAllRules = async () => {
    if (transactionTemplate?.Id) {
      const response = await getAllTransactionTemplateRules({
        transactionTemplateId: transactionTemplate?.Id,
      });

      const rules = [] as RuleType[];
      if (response?.data?.result?.length) {
        response.data.result.forEach((rule) => {
          rules.push({
            ...rule,
            actions: rule.Actions,
            triggers: rule.Triggers,
          });
        });
      }
      setRules(rules);
    }
  };

  useEffect(() => {
    if (transactionTemplate) {
      setShowAdd(false);
      setRules(undefined);
      getAllRules();
    }
  }, [transactionTemplate]);

  return (
    <Modal
      open={rulesModalVisible}
      maskClosable={false}
      footer={<></>}
      title="Rules"
      className={styles.modal}
      {...props}
    >
      {showAdd && transactionTemplate ? (
        <Rule
          transactionTemplate={transactionTemplate}
          isNew={true}
          goBack={() => setShowAdd(false)}
          onAdd={addNewRule}
        />
      ) : (
        <>
          <Row justify="end">
            <Col>
              <Button onClick={() => setShowAdd(true)}>Add</Button>
            </Col>
          </Row>
          {rules?.length && transactionTemplate ? (
            <RuleList
              onDeleteRule={onDeleteRule}
              rules={rules}
              transactionTemplate={transactionTemplate}
            />
          ) : (
            <h4>No Rules available</h4>
          )}
        </>
      )}
    </Modal>
  );
};
