import { createSelector } from 'reselect';

import { FormCommentType } from 'types/formComment';
import { PENDING } from 'settings/constants/apiState';

const localState = ({ formComment }) => formComment;

export const formCommentSelector = createSelector(localState, (formComment: FormCommentType) => ({
  ...formComment,
  fieldThreads: {
    ...formComment.fieldThreads,
    isPending: formComment.fieldThreads.state === PENDING,
  },
  commentUsers: formComment.commentUsers,
}));
