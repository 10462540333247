import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  requestGetProjectsEffect,
  requestGetTransactionsEffect,
} from 'store/effects/transactions/all';

import { FilterSections } from './FilterSections/FilterSections';

export const Filter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestGetTransactionsEffect({}));
    dispatch(requestGetProjectsEffect({ filters: { isProject: true } }));
  }, []);

  return <FilterSections />;
};
