/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  requestGetNotificationTemplateByIdEffect,
  updateNotificationTemplateEffect,
} from 'store/effects/adminPanel/notificationTemplates';
import { Button, TextareaAutosize, Wrapper } from 'components';
import {
  getNotificationTemplateDashboardSelector,
  getNotificationTemplateDataDashboardSelector,
} from 'store/selectors/adminPanel/notificationTemplates';

import { ValidationSchema } from './validation';
import styles from './styles.module.scss';

const NotificationTemplateForm = (props) => {
  const { className, id, onSubmissionComplete } = props;
  const dispatch = useDispatch();
  const { isPending } = useSelector(getNotificationTemplateDashboardSelector);
  const template = useSelector(getNotificationTemplateDataDashboardSelector);

  const onSubmit = useCallback(
    (values) => {
      if (id) {
        dispatch(
          updateNotificationTemplateEffect({ values, id }, (err) => {
            if (!err) {
              onSubmissionComplete();
            }
          }),
        );
      }
    },
    [dispatch, id, onSubmissionComplete],
  );

  useEffect(() => {
    if (id) {
      dispatch(requestGetNotificationTemplateByIdEffect({ id }));
    }
  }, [id]); //eslint-disable-line

  const formik = useFormik({
    initialValues: template || NotificationTemplateForm.initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit,
  });

  return (
    <Wrapper isPending={isPending} className={classNames(styles.wrapper, className)}>
      <div className={styles.title}>{template?.Type}</div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputBlock}>
          <label htmlFor="Template" className={styles.editLabel}>
            Template
          </label>
          <div className={styles.rightBlock}>
            <TextareaAutosize
              id="Template"
              className={styles.input}
              name="Template"
              error={formik.touched.Template ? formik.errors.Template : ''}
              value={formik.values.Template}
              onChange={formik.handleChange}
              disabled={isPending}
              rows={3}
            />
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          {formik.dirty ? (
            <Button type="submit" className={styles.saveBtn} title="Save" isPending={isPending} />
          ) : (
            <Button type="button" className={styles.saveBtn} title="Save" />
          )}
        </div>
      </form>
    </Wrapper>
  );
};

NotificationTemplateForm.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSubmissionComplete: PropTypes.func.isRequired,
};

NotificationTemplateForm.defaultProps = {
  className: '',
};

NotificationTemplateForm.initialValues = {
  Template: '',
};

export default NotificationTemplateForm;
