export enum PropertyType {
  Other = 'Other',
  Single = 'Single',
  Townhouse = 'Townhouse',
  Condo = 'Condo',
  MultiFamily = 'MultiFamily',
  NewConstruction = 'NewConstruction',
  Land = 'Land',
}

export const PropertyTypeMap = {
  Other: 'Other',
  Single: 'Single Family Home',
  Townhouse: 'Townhouse',
  Condo: 'Condo',
  MultiFamily: 'Multi-Family',
  NewConstruction: 'New Construction',
  Land: 'Land',
};
