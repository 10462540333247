import { useDispatch } from 'react-redux';

import { Close } from 'components/Icons';
import { setSelectedGraphAction } from 'store/actions/clarity';

import styles from './styles.module.scss';

export const ModalHeader = ({ title }) => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setSelectedGraphAction({ isGraphSelected: false, graphName: '' }));
  };

  return (
    <div className={styles.modalHeader}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.closeButton} onClick={onClose}>
        <Close />
      </div>
    </div>
  );
};
