import { Select } from 'antd';
import { ArrowDown } from 'components/Icons';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const DropdownValues = {
  Overview: 'Overview',
  Details: 'Details',
};

const { Option } = Select;

export const Switcher = ({ dropdownValue, setDropdownValue }) => {
  return (
    <div className={styles.switcherContainer}>
      <Select
        defaultValue={DropdownValues.Overview}
        className={classNames('mosaikPageSwitcher')}
        popupClassName={classNames('mosaikPageSwitcherDropdown')}
        onChange={setDropdownValue}
        bordered={false}
        suffixIcon={<ArrowDown />}
        getPopupContainer={(triggerNode) => triggerNode}
        listHeight={364}
        value={dropdownValue}
      >
        <Option value={DropdownValues.Overview}>{DropdownValues.Overview}</Option>
        <Option value={DropdownValues.Details}>{DropdownValues.Details}</Option>
      </Select>
    </div>
  );
};
