import React, { memo, useMemo } from 'react';

const Checkmark = memo(
  ({ className, color, strokeWidth, onClick }: React.HTMLAttributes<HTMLDivElement>) =>
    useMemo(
      () => (
        <div onClick={onClick} className={className}>
          <svg
            width="13"
            height="10"
            viewBox="0 0 13 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.33203 5.0013L4.66536 8.33463L11.332 1.66797"
              stroke={color || '#000'}
              strokeWidth={strokeWidth || '1.5'}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      [className, color, onClick],
    ),
);

export default Checkmark;
