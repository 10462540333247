import FormTitle from 'components/FormTitle';
import { InviteHeader } from 'components/ClientsDrawer/Headers';
import Footer from 'components/ClientsDrawer/Footer';

import { SearchCriteriaSummary } from './SearchCriteriaSummary';

import styles from './styles.module.scss';
import { useCallback } from 'react';
import { inviteClientSteps } from 'types/inviteClient';

export const InviteClientSearchSummary = ({ onNext, stageIndex, onPrev }) => {
  const handleContinue = useCallback(() => {
    onNext(inviteClientSteps.ClientPreApproval);
  }, [onNext]);

  return (
    <>
      <InviteHeader stageIndex={stageIndex} onPrev={onPrev} />
      <div className={styles.container}>
        <FormTitle className={styles.title}>Does this look right?</FormTitle>
        <SearchCriteriaSummary onPrev={onPrev} />
      </div>
      <Footer
        buttonType="button"
        buttonTitle="Continue"
        isValid={true}
        onClick={handleContinue}
        testid="invite_buyer_footer"
      />
    </>
  );
};
