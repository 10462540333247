import AntProgress, { ProgressProps } from 'antd/lib/progress';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const Progress = ({ type = 'line', className, ...props }: ProgressProps) => (
  <AntProgress
    className={classNames(
      {
        [styles.progressLine]: type === 'line',
      },
      className,
    )}
    type={type}
    showInfo={false}
    {...props}
  />
);
