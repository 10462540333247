import PropTypes from 'prop-types';

const Undo = ({ className, onClick }) => (
  <div testid="archive_icon" onClick={onClick} className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M14.1694 13.6694C13.4427 14.3962 12.5479 14.9325 11.5643 15.2309C10.5807 15.5292 9.53879 15.5804 8.53072 15.3799C7.52266 15.1794 6.5796 14.7334 5.78509 14.0813C4.99058 13.4293 4.36914 12.5914 3.97581 11.6418C3.58248 10.6922 3.42941 9.66028 3.53015 8.63741C3.63089 7.61455 3.98233 6.63231 4.55335 5.77771C5.12437 4.92311 5.89734 4.22253 6.80379 3.73801C7.71024 3.2535 8.72218 3.00001 9.75 3C12.5681 3 14.2764 4.87847 16 6.81944"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 4.0415V6.81928H13.2222"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

Undo.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Undo.defaultProps = {
  className: '',
  onClick: () => {},
};

export default Undo;
