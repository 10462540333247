import {
  acceptTermsAndConditionsAnon,
  acceptTermsAndConditionsAuth,
  declineSignatureAnon,
  declineSignatureAuth,
  fillSmartFormValuesAnon,
  fillSmartFormValuesAuth,
  getSignatureAnon,
  getSignatureAuth,
  linkToFormProcess,
  saveSignatureAnon,
  saveSignatureAuth,
  unlinkFormProcess,
  unlockDynamicFormDocumentAuth,
  unlockFormDocumentAnon,
  unlockFormDocumentAuth,
  updateStrikeThroughAnon,
  updateStrikeThroughAuth,
  updateStrikeThroughTemplate,
} from 'api/formProcess';
import {
  agreeToTermsAnon,
  agreeToTermsAuth,
  declineSignatureAnonAction,
  declineSignatureAuthAction,
  linkToFormProcessAction,
  requestFillFormAnon,
  requestFillFormAuth,
  requestSignatureAction,
  saveSignatureAction,
  unlinkFormProcessAction,
  unlockFormDocumentAnonAction,
  unlockFormDocumentAuthAction,
  updateDynamicRequiredQuestionAction,
  updateHideQuestionRoleAction,
  updateStrikeThroughAction,
} from 'store/actions/formProcess';
import Api from 'store/effects/core/api';
import { AnonDataType, AuthDataType, StrikeThrough } from 'types';

export const fillSmartFormAuthEffect = (cfg) => {
  const sendRequest = Api.execBase({
    action: requestFillFormAuth,
    method: fillSmartFormValuesAuth,
  });

  return sendRequest(cfg);
};

export const saveSignatureAuthEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: saveSignatureAction,
    method: saveSignatureAuth,
  });

  return sendRequest(cfg, {}, cb);
};

export const getSignatureAuthEffect = (cb?) => {
  const sendRequest = Api.execBase({
    action: requestSignatureAction,
    method: getSignatureAuth,
  });

  return sendRequest({}, {}, cb);
};

export const saveSignatureAnonEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: saveSignatureAction,
    method: saveSignatureAnon,
  });

  return sendRequest(cfg, {}, cb);
};

export const getSignatureAnonEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestSignatureAction,
    method: getSignatureAnon,
  });

  return sendRequest(cfg, {}, cb);
};

export const fillSmartFormAnonEffect = (cfg) => {
  const sendRequest = Api.execBase({
    action: requestFillFormAnon,
    method: fillSmartFormValuesAnon,
  });

  return sendRequest(cfg);
};

export const acceptTermsAndConditionsAuthEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: agreeToTermsAuth,
    method: acceptTermsAndConditionsAuth,
  });

  return sendRequest(cfg, {}, cb);
};

export const acceptTermsAndConditionsAnonEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: agreeToTermsAnon,
    method: acceptTermsAndConditionsAnon,
  });

  return sendRequest(cfg, {}, cb);
};

export const declineSignatureAuthEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: declineSignatureAuthAction,
    method: declineSignatureAuth,
  });

  return sendRequest(cfg, {}, cb);
};

export const declineSignatureAnonEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: declineSignatureAnonAction,
    method: declineSignatureAnon,
  });

  return sendRequest(cfg, {}, cb);
};

export const unlockFormDocumentAuthEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: unlockFormDocumentAuthAction,
    method: unlockFormDocumentAuth,
  });

  return sendRequest(cfg, {}, cb);
};

export const unlockDynamicFormDocumentAuthEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: unlockFormDocumentAuthAction,
    method: unlockDynamicFormDocumentAuth,
  });

  return sendRequest(cfg, {}, cb);
};

export const unlockFormDocumentAnonEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: unlockFormDocumentAnonAction,
    method: unlockFormDocumentAnon,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateStrikeThroughAuthEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: updateStrikeThroughAction,
    method: updateStrikeThroughAuth,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateTemplateStrikeThroughEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: updateStrikeThroughAction,
    method: updateStrikeThroughTemplate,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateStrikeThroughAnonEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: updateStrikeThroughAction,
    method: updateStrikeThroughAnon,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateDynamicRequiredQuestionEffect = (cfg) => (dispatch) => {
  dispatch(updateDynamicRequiredQuestionAction(cfg));
};

export const updateHideQuestionRoleEffect = (cfg) => (dispatch) => {
  dispatch(updateHideQuestionRoleAction(cfg));
};

export const linkToFormProcessEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: linkToFormProcessAction,
    method: linkToFormProcess,
  });

  return sendRequest(cfg, {}, cb);
};

export const unlinkFormProcessEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: unlinkFormProcessAction,
    method: unlinkFormProcess,
  });

  return sendRequest(cfg, {}, cb);
};
