import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getUserDataSelector,
  getUserRoleSelector,
  getUserRolesMapSelector,
} from 'store/selectors/user';
import { isSearchActive } from 'store/selectors/searchMessages';
import { getIsMessagesDrawerOpenSelector } from 'store/selectors/drawers/messages';
import { getUnArchivedChatThreadsListRecipientWise } from 'store/selectors/sockets/threads';

import { Tabs } from 'components';
import BottomAdviceBlock from 'components/Drawers/components/BottomAdviceBlock';

import { DRAWER_MESSAGES_TABS_IDS } from 'settings/constants/drawers';
import ClientsTab from '../Tabs/ClientsTab';
import TransactionsTab from '../Tabs/TransactionsTab';
import ListingAndOffersTab from '../Tabs/ListingAndOffersTab';
import QuotesTab from '../Tabs/QuotesTab';
import ServicesTab from '../Tabs/ServicesTab';
import GroupedThreadsRecipientWise from '../GroupedThreadsRecipientWise';

import MyAgent from '../MyAgent';
import MessagesDrawerSearchedContent from '../SearchedContent';

import styles from './styles.module.scss';

const MessagesDrawerInitContent = ({ getActiveTab, activeTab, isPartnerGroup, threads }) => {
  const isOpen = useSelector(getIsMessagesDrawerOpenSelector);
  const { isAgent, isClient, isThirdParty } = useSelector(getUserRolesMapSelector);
  const user = useSelector(getUserDataSelector);
  const userRole = useSelector(getUserRoleSelector);
  const isSearch = useSelector(isSearchActive);
  const recipientWiseThreads = useSelector(getUnArchivedChatThreadsListRecipientWise);
  recipientWiseThreads.sort((a, b) => {
    const aDate = new Date(a.lastUnreadDate);
    const bDate = new Date(b.lastUnreadDate);
    return bDate - aDate;
  });

  return (
    <div testid="init_content" className={styles.wrapper}>
      {isSearch ? (
        <MessagesDrawerSearchedContent className={styles.search} />
      ) : (
        <>
          {isClient && user?.[userRole]?.InvitedBy && <MyAgent />}

          <GroupedThreadsRecipientWise list={isPartnerGroup ? threads : recipientWiseThreads} />
        </>
      )}
      {isOpen && <BottomAdviceBlock />}
    </div>
  );
};

MessagesDrawerInitContent.propTypes = {
  getActiveTab: PropTypes.func,
  activeTab: PropTypes.shape({
    index: PropTypes.number,
  }),
  isPartnerGroup: PropTypes.bool,
  threads: PropTypes.array,
};

MessagesDrawerInitContent.defaultProps = {
  getActiveTab: () => {},
  activeTab: null,
  isPartnerGroup: false,
  threads: [],
};

export default MessagesDrawerInitContent;
