import Api from 'store/effects/core/api';
import { requestGetCollaboratorsListAction } from 'store/actions/collaboratorsList';
import {
  configureAdminAccess,
  assignClientsToMember,
  getCollaboratorsList,
  getTeamPermittedList,
  getTeamTitles,
} from 'api/collaboratorsList';

export const requestGetCollaboratorsListEffect = (cfg, options = {}, cb) => {
  const requestParams = { action: requestGetCollaboratorsListAction, method: getCollaboratorsList };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, {}, cb);
};
