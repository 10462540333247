import { useState } from 'react';

import { TransactionFormTemplate } from 'types';
import { Popover, Switch, Paragraph, Tooltip } from 'components-antd';
import { Input, DatePicker } from 'components';
import { DeleteTask, EditPencil, List, Menu, TaskCard } from 'components/Icons';
import { validateControlOperatorOffset, convertToInitialOffset } from 'utils/templatesHelper';

import styles from './styles.module.scss';
interface TaskCardProps {
  form: TransactionFormTemplate;
  index: number;
  onRemoveDueDate: (index) => void;
  onRemoveForm: (index) => void;
  onEditForm: (form, index) => void;
  onFormCardEdit: (form, index) => void;
  error?: string;
  isViewMode: boolean;
}

export const FormCard = ({
  form,
  onRemoveDueDate,
  onRemoveForm,
  onEditForm,
  onFormCardEdit,
  index,
  error,
  isViewMode,
}: TaskCardProps) => {
  const [ellipsis, setEllipsis] = useState(false);
  const onFieldsChange = (data) => {
    const formTemplate = {
      ...form,
      ...data,
    };
    onFormCardEdit(formTemplate, index);
  };

  return (
    <div className={styles.formCard}>
      <div className={styles.formData}>
        <TaskCard className={styles.formIcon} />
        <div className={styles.formInfo}>
          <Tooltip
            title={ellipsis ? form.Name || form?.Name : ''}
            placement="top"
            overlayClassName={styles.toolTip}
          >
            <Paragraph ellipsis={{ onEllipsis: setEllipsis }} className={styles.formName}>
              {form.Name || form?.Name}
            </Paragraph>
          </Tooltip>
        </div>
      </div>
      <div className={styles.formOptions}>
        <div className={styles.formDueDate}>
          <DatePicker
            options={{ enableTime: false, minDate: null, maxDate: null }}
            className={styles.templateControlPicker}
            datePickerInputClass={styles.datePickerInputClass}
            disabled={true}
          />
          <Input
            variant={Input.LIGHT}
            placeholder="A+7, CL-4"
            value={form?.ControlOperatorOffset}
            onChange={(e, val) => validateControlOperatorOffset(e, val, onFieldsChange)}
            onBlur={(e) => convertToInitialOffset(e.target.value, onFieldsChange)}
            saveOnChange={false}
            disabled={isViewMode}
          />
          {error && <span className={styles.formDueDateError}>{error}</span>}
        </div>
        <div className={styles.formSwitch}>
          Required
          <Switch
            checked={form.IsRequired}
            onChange={(checked) => onFieldsChange({ IsRequired: checked })}
            disabled={isViewMode}
          />
        </div>
        <Popover
          content={
            <div>
              {isViewMode ? (
                <div className={styles.entityOption} onClick={() => onEditForm(form, index)}>
                  <List className={styles.icon} />
                  View
                </div>
              ) : (
                <>
                  <div className={styles.entityOption} onClick={() => onEditForm(form, index)}>
                    <EditPencil className={styles.icon} />
                    Edit
                  </div>
                  <div
                    className={styles.entityOption}
                    onClick={() => !isViewMode && onRemoveForm(index)}
                  >
                    <DeleteTask className={styles.icon} />
                    Remove Form
                  </div>
                </>
              )}
            </div>
          }
          className={styles.antdCustomPopover}
          trigger="hover"
          placement="bottomRight"
          overlayClassName={styles.entityOptionsDropDown}
        >
          <div>
            <Menu className={styles.menuIcon} />
          </div>
        </Popover>
      </div>
    </div>
  );
};
