import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { getThreadsPerEntityId } from 'store/selectors/sockets/threads';
import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { setSharePropertyEffect } from 'store/effects';
import Icon from 'pages/Properties/Feed/Icons';
import { extractOrderedPhotos } from 'helpers';
import { Badge } from 'components-antd';
import { uniqBy } from 'lodash-es';

import styles from './styles.module.scss';
import { getAgentPropertiesList } from 'store/selectors/feedv3';

const Comment = ({ className, propertyInfo, onlyReadMsg }) => {
  const threads = useSelector(getThreadsPerEntityId);
  const { PhotoUrls, Media, PhotoCount } = propertyInfo;
  const dispatch = useDispatch();
  const OrderedPhotos = extractOrderedPhotos(Media, PhotoCount);
  const ImagesToSend = !isEmpty(OrderedPhotos) ? OrderedPhotos : PhotoUrls;
  const { properties } = useSelector(getAgentPropertiesList);
  const [info] = useState({
    Id: propertyInfo.Id ? propertyInfo.Id : propertyInfo.id,
    ActivityStatus: propertyInfo.ActivityStatus,
    Address: propertyInfo?.address || propertyInfo?.Address,
    Neighborhood: propertyInfo?.Neighborhood,
    Geolocation: {
      Lat: propertyInfo?.Geolocation?.Lat || propertyInfo?.geometry?.coordinates?.lat,
      Long: propertyInfo?.Geolocation?.Long || propertyInfo?.geometry?.coordinates?.lng,
    },
    NumBathsTotal: propertyInfo?.NumBathsTotal,
    NumBeds: propertyInfo?.NumBeds,
    HomeType: propertyInfo?.HomeType,
    YearBuilt: propertyInfo?.YearBuilt,
    ExteriorAndLotDetails: propertyInfo?.ExteriorAndLotDetails,
    AssociationInfo: propertyInfo?.AssociationInfo,
    Parking: propertyInfo?.Parking,
    ListingDate: propertyInfo?.ListingDate,
    ListingInfo: propertyInfo?.ListingInfo,
    BuildingInfo: propertyInfo?.BuildingInfo,
    PhotoUrls: ImagesToSend ? ImagesToSend : [propertyInfo?.image],
    SellingPrice: propertyInfo?.SellingPrice || propertyInfo?.price,
    SquareFeet: propertyInfo?.SquareFeet,
    AddedToFavoritesBy: propertyInfo?.AddedToFavoritesBy,
    CustomKitchenFeatures: propertyInfo?.CustomKitchenFeatures,
    MatchedClientsCount: propertyInfo?.MatchedClientsCount,
    MatchedCriterias: propertyInfo?.MatchedCriterias,
    OpenHouses: propertyInfo?.OpenHouses,
    MatchScore: propertyInfo?.MatchScore,
    PropertyHistory: propertyInfo?.PropertyHistory || {},
  });

  const onComment = (e) => {
    e.stopPropagation();
    dispatch(setSharePropertyEffect(info));
    dispatch(openCommentsDrawerEffect({ open: true, params: { onlyReadMsg } }));
  };

  const propertyThreads = threads?.[propertyInfo?.Id] || [];
  /**
   * Note:
   * Previously we were only considering read flag on thread level. Currently we are increasing the granularity level
   * to the message/property level. we are only marking messages as read in the redux rather than marking whole thread as read
   * AgentProperties in V3 feed contains comments of specific property with read flag returned by feed API. We are determining
   * if the comments of the property read by the user or not baesd on that `IsRead` flag. When the user open thread we change the
   * flag of comments in the redux along with BE.
   */
  const isUnreadThread = properties
    ?.find(({ Id }) => Id === propertyInfo?.Id)
    ?.Comments?.some((comment) => comment.IsRead === false);
  const countThreads = uniqBy(propertyThreads, 'Id').length;

  return (
    <div onClick={onComment} className={classNames(styles.commentWrapper, className)}>
      <Badge dot={isUnreadThread}>
        <Icon className={styles.icon} testid="comment_icon" variant={Icon.COMMENT} />
      </Badge>
      {!!countThreads && <span className={styles.count}>{countThreads}</span>}
    </div>
  );
};

Comment.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  onlyReadMsg: PropTypes.bool,
};

Comment.defaultProps = {
  className: '',
  onlyReadMsg: false,
};

export default Comment;
