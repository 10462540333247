import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash-es';

import { Wrapper } from 'components';
import { Modal } from 'components-antd';
import { getEmptyMilestone } from 'settings/constants/transaction';
import { getTimelineTemplateSelector } from 'store/selectors/template';
import { getTimelineTemplateEffect } from 'store/effects';
import { resetTimelineTemplateDataAction } from 'store/actions/template';

import { postTimelineTemplateEffect, putTimelineTemplateEffect } from 'store/effects/template';

import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';

import styles from './styles.module.scss';

export const TimelinesModal = (props) => {
  const { isOpen, setOpenTaskModal, isNew, isViewMode, templateId, onSubmit } = props;
  const { isIdle, isPending } = useSelector(getTimelineTemplateSelector);
  const dispatch = useDispatch();

  const [stepOne, setStepOne] = useState(true);
  const [timelineTitle, setTimelineTitle] = useState('');
  const [timelineDescription, setTimelineDescription] = useState('');
  const [timeline, setTimeline] = useState([getEmptyMilestone()]);
  const [isSaving, setIsSaving] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(false);

  const onCloseModal = () => {
    resetFields();
  };

  const onTitleChange = (e, val) => {
    setTimelineTitle(val);
  };

  const onDescriptionChange = (e, val) => {
    setTimelineDescription(val);
  };

  const updateTimeline = (timelineValues) => {
    setTimeline(timelineValues);
  };

  const onPermissionsChange = (key, val) => {
    setPermissions({
      ...permissions,
      [key]: val,
    });
  };

  const updateMilestoneProperty = (prop, val, index) => {
    const updatedItem = {
      ...(timeline?.[index] || getEmptyMilestone()),
      [prop]: val,
    };
    const updatedTimeline = timeline.slice();
    updatedTimeline[index] = updatedItem;
    updateTimeline(updatedTimeline);
  };

  const onNameChange = (e, val, index) => {
    updateMilestoneProperty('Title', val, index);
  };

  const onFieldsChange = (data, index) => {
    const updatedTimeline = cloneDeep(timeline);
    updatedTimeline[index] = {
      ...updatedTimeline[index],
      ...data,
    };
    updateTimeline(updatedTimeline);
  };

  const onAdd = () => {
    updateTimeline([...timeline, getEmptyMilestone()]);
  };

  const onRemove = (index) => {
    const updatedTimeline = timeline.slice();
    updatedTimeline.splice(index, 1);
    updateTimeline(updatedTimeline);
  };

  const resetFields = () => {
    setTimelineTitle('');
    setTimelineDescription('');
    setTimeline([getEmptyMilestone()]);
    setPermissions({});
    setOpenTaskModal(false);
    setStepOne(true);
  };

  useEffect(() => {
    if (templateId) {
      setLoading(true);
      if (isIdle || !isPending) {
        dispatch(
          getTimelineTemplateEffect({ id: templateId }, {}, (err, resp) => {
            const data = resp?.data?.result || {};
            setTimelineTitle(data.Name);
            setTimelineDescription(data.Description);
            setTimeline(
              (data.Milestones || []).map((item) => ({
                Title: item.Title,
                Initial: item.Initial,
                DueDate: item.Offset,
                Operator: item.Operator,
                ControlOperatorOffset: item.ControlOperatorOffset,
                IsPrivate: !!item.IsPrivate,
                PublicId: item.PublicId,
              })),
            );
            setPermissions({
              People: data.People ?? [],
              Teams: data.Teams ?? [],
              Locations: data.Locations ?? [],
            });
            setLoading(false);
          }),
        );
      }
    } else {
      dispatch(resetTimelineTemplateDataAction());
    }
  }, [templateId, dispatch]); //eslint-disable-line

  const onSaveTemplate = () => {
    setIsSaving(true);
    const body = {
      title: timelineTitle,
      description: timelineDescription,
      milestones: timeline,
      ...permissions,
      ...(permissions?.Locations && {
        Locations: permissions.Locations?.filter((el) => el.areasOfOperation?.length)
          .map((areas) => {
            const { miles, areasOfOperation, UUID } = areas;
            return areasOfOperation.map((area) => ({ ...area, Radius: miles }));
          })
          .flat(),
      }),
    };
    const cb = (err) => {
      setIsSaving(false);
      if (!err) {
        resetFields();
        onSubmit();
      }
    };
    dispatch(
      !templateId
        ? postTimelineTemplateEffect(body, {}, cb)
        : putTimelineTemplateEffect(body, { id: templateId }, cb),
    );
  };

  const getStep = () => {
    if (stepOne) {
      return (
        <StepOne
          timelineTitle={timelineTitle}
          timelineDescription={timelineDescription}
          onTitleChange={onTitleChange}
          onDescriptionChange={onDescriptionChange}
          onButtonClick={() => setStepOne(!stepOne)}
          permissions={permissions}
          onPermissionsChange={onPermissionsChange}
          loading={loading}
          isViewMode={isViewMode}
        />
      );
    } else {
      return (
        <StepTwo
          timelineTitle={timelineTitle}
          onEditClick={() => setStepOne(!stepOne)}
          timeline={timeline}
          templateId={templateId}
          onAdd={onAdd}
          onRemove={onRemove}
          onNameChange={onNameChange}
          onFieldsChange={onFieldsChange}
          onSaveTemplate={onSaveTemplate}
          isViewMode={isViewMode}
        />
      );
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        width={675}
        footer={null}
        onCancel={() => onCloseModal()}
        maskClosable={false}
        destroyOnClose
        className={styles.TaskModal}
      >
        <h1 className={styles.heading}>{isNew ? 'New' : isViewMode ? 'View' : 'Edit'} Template</h1>
        <p className={styles.subHeading}>Timeline</p>
        <Wrapper isPending={isPending && !isSaving} className={styles.contentWrapper}>
          {getStep()}
        </Wrapper>
      </Modal>
    </div>
  );
};

TimelinesModal.propTypes = {
  className: PropTypes.string,
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  isNew: PropTypes.bool,
  isViewMode: PropTypes.bool,
  setOpenTaskModal: PropTypes.func,
  onSubmit: PropTypes.func,
};

TimelinesModal.defaultProps = {
  className: '',
  templateId: undefined,
  isNew: true,
  isViewMode: false,
  isOpen: false,
  setOpenTaskModal: () => {},
  onSubmit: () => {},
};
