import React, { memo, useMemo } from 'react';

const ArrowRight = memo(({ className, color = '#666666' }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div testid="arrow_right_icon" className={className}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 4L10 8L6 12"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className, color],
  ),
);

export default ArrowRight;
