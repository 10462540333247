import { SVGProps } from 'react';

export const FormDocument = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="24x24/Forms">
      <path
        id="Rectangle 1369"
        d="M10.667 10.6654C10.667 7.71984 13.0548 5.33203 16.0003 5.33203H37.9893C38.7069 5.33203 39.3943 5.62127 39.896 6.13438L52.5737 19.1002C53.0609 19.5985 53.3337 20.2677 53.3337 20.9645V53.332C53.3337 56.2775 50.9458 58.6654 48.0003 58.6654H16.0003C13.0548 58.6654 10.667 56.2776 10.667 53.332V10.6654Z"
        stroke="#D6D6D7"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 859"
        d="M37.333 5.33203V15.9987C37.333 18.9442 39.7208 21.332 42.6663 21.332H53.333"
        stroke="#D6D6D7"
        strokeWidth="3"
      />
      <path
        id="Vector 960"
        d="M21.333 32L42.6663 32"
        stroke="#D6D6D7"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 962"
        d="M21.333 42.668L34.6663 42.668"
        stroke="#D6D6D7"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
