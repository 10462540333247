import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPreApprovalDocumentsEffect, deletePreApprovalDocumentEffect } from 'store/effects';
import { getUserId } from 'store/selectors/user';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getPreApprovalDocumentsSelector } from 'store/selectors/mySearches';
import { Spinner } from 'components';
import { PENDING } from 'settings/constants/apiState';
import { Document } from '../Document';
import { showSuccessMessage } from 'helpers/success';

import styles from './styles.module.scss';

export const PreApprovalDocuments = () => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const context = useSelector(getCurrentContextSelector);
  const { state: isLoading, data: documents } = useSelector(getPreApprovalDocumentsSelector);

  const deleteDocument = (id) => {
    dispatch(
      deletePreApprovalDocumentEffect(
        {
          id,
          clientId: userId,
          contextKey: context.ContextKey,
        },
        {},
        (err) => {
          if (!err) {
            showSuccessMessage('Pre-approval Document Deleted Successfully');
          }
        },
      ),
    );
  };

  const fetchDocuments = useCallback(() => {
    dispatch(
      getPreApprovalDocumentsEffect({
        clientId: userId,
        contextKey: context.ContextKey,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <>
      {isLoading === PENDING ? (
        <Spinner loaderClassName={styles.loader} />
      ) : (
        <div className={styles.preApprovalDocuments}>
          {documents?.map((document) => (
            <Document document={document} key={document.Id} onDelete={deleteDocument} />
          ))}
        </div>
      )}
    </>
  );
};
