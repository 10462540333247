const Clipboard = ({ className }) => (
  <div className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 5.00022H6.94444C6.42875 5.00022 5.93417 5.20508 5.56951 5.56973C5.20486 5.93439 5 6.42896 5 6.94466V18.0558C5 18.5715 5.20486 19.066 5.56951 19.4307C5.93417 19.7954 6.42875 20.0002 6.94444 20.0002L17.0556 20C17.5713 20 18.0658 19.7951 18.4305 19.4305C18.7951 19.0658 19 18.5713 19 18.0556V6.94444C19 6.42875 18.7951 5.93417 18.4305 5.56951C18.0658 5.20486 17.5713 5 17.0556 5H16"
        stroke="#AAABAB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 3H10.5C9.39543 3 8.5 3.89543 8.5 5C8.5 6.10457 9.39543 7 10.5 7H13.5C14.6046 7 15.5 6.10457 15.5 5C15.5 3.89543 14.6046 3 13.5 3Z"
        stroke="#AAABAB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 11H14.5"
        stroke="#AAABAB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 15H14.5"
        stroke="#AAABAB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default Clipboard;
