import React from 'react';
import classNames from 'classnames';

import PropertyImagePlaceholder from 'images/property-image-placeholder.png';
import styles from './styles.module.scss';

export enum DocumentCardTypes {
  ORANGE = 'orange',
  GREEN = 'green',
  PURPLE = 'purple',
  WHITE = 'white',
}

interface DocumentCardType {
  title: string;
  docsCount: number;
  bgColor: string;
  cardIcon?: React.ReactNode;
  onClickHandler: () => void;
}

export const DocumentCard = ({
  title,
  docsCount,
  bgColor,
  cardIcon,
  onClickHandler,
}: DocumentCardType) => {
  return (
    <div className={classNames(styles.documentCard, styles[bgColor])} onClick={onClickHandler}>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          {cardIcon || <img src={PropertyImagePlaceholder} alt="" />}
        </div>
        <p className={styles.title}>{title}</p>
        <p className={styles.count}>
          {docsCount} Document{docsCount === 1 ? '' : 's'}
        </p>
      </div>
    </div>
  );
};
