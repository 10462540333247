import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { appOpenModalEffect } from 'store/effects/app';
import { getModalSelector } from 'store/selectors/app';
import { Modal, Counter } from 'components';
import { Button, Switch } from 'components-antd';

import styles from './styles.module.scss';

const ParkingModal = ({ className, onNext, value }) => {
  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();
  const [count, setCount] = useState(value.parkingSpaces || 0);
  const [garageOnly, setGarageOnly] = useState(value.garageOnly);
  const [attachedGarage, setAttachedGarage] = useState(value.attachedGarage);

  useEffect(() => {
    setCount(value.parkingSpaces);
    setGarageOnly(value.garageOnly);
    setAttachedGarage(value.attachedGarage);
  }, [value]);

  if (modal.id !== ParkingModal.id) {
    return null;
  }

  const onClose = () => {
    onNext({
      count,
      garageOnly,
      attachedGarage,
    });
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  return (
    <Modal
      testid="parking_modal"
      className={classNames(styles.modalWrapper, className)}
      innerHolderClassName={styles.innerHolder}
      opacityLayerClassName={styles.opacityLayer}
      isOpen={modal.open}
      onClose={onClose}
      contentClassName={styles.modal}
      closeClassName={styles.close}
    >
      <h2 className={styles.title}>How many parking spaces do you want?</h2>
      <Counter
        testid="parking_counter"
        limit={{ min: 0 }}
        className={styles.counter}
        addClassName={styles.add}
        removeClassName={styles.remove}
        onChange={setCount}
        value={count}
        AddComponent={(props) => (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="28" cy="28" r="28" fill="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.5 18C29.5 17.1716 28.8284 16.5 28 16.5C27.1716 16.5 26.5 17.1716 26.5 18V26.5H18C17.1716 26.5 16.5 27.1716 16.5 28C16.5 28.8284 17.1716 29.5 18 29.5H26.5V38C26.5 38.8284 27.1716 39.5 28 39.5C28.8284 39.5 29.5 38.8284 29.5 38V29.5H38C38.8284 29.5 39.5 28.8284 39.5 28C39.5 27.1716 38.8284 26.5 38 26.5H29.5V18Z"
              fill="#ADADAD"
            />
          </svg>
        )}
        RemoveComponent={(props) => (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="28" cy="28" r="28" fill="white" />
            <path
              d="M18 28L38 28"
              stroke="#ADADAD"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      />
      <div className={styles.garageSwitch}>
        <p>Garage only?</p>
        <Switch checked={garageOnly} onChange={setGarageOnly} />
      </div>
      {garageOnly ? (
        <div className={styles.garageSwitch}>
          <p>Must be attached?</p>
          <Switch checked={attachedGarage} onChange={setAttachedGarage} />
        </div>
      ) : (
        <></>
      )}
      <div className={styles.bottom}>
        <Button testid="parking_continue" onClick={onClose} className={styles.saveButton}>
          Continue
        </Button>
      </div>
    </Modal>
  );
};

ParkingModal.id = 'parkingModal';

ParkingModal.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  value: {
    parkingSpaces: PropTypes.number,
    garageOnly: PropTypes.bool,
    attachedGarage: PropTypes.bool,
  },
};

ParkingModal.defaultProps = {
  className: '',
  onNext: () => {},
  value: {
    parkingSpaces: 0,
    garageOnly: false,
    attachedGarage: false,
  },
};

export default ParkingModal;
