import Api from 'store/effects/core/api';
import { getContactDetail, getRelatedEntities } from 'api/relatedEntity';
import {
  requestGetContactDataAction,
  requestGetRelatedEntitiesCompleteListDataAction,
  requestGetRelatedEntitiesDataAction,
} from 'store/actions/relatedEntities';

export const relatedEntitiesGetDataEffect = (options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetRelatedEntitiesDataAction,
    method: getRelatedEntities,
  });

  return sendRequest({}, options, cb);
};

export const relatedEntitiesCompleteListGetDataEffect = (options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetRelatedEntitiesCompleteListDataAction,
    method: getRelatedEntities,
  });

  return sendRequest(
    {
      showCompleteList: true,
    },
    options,
    cb,
  );
};

export const getContactDetailsEffect = (cfg, options, cb) => {
  const requestParams = { action: requestGetContactDataAction, method: getContactDetail };
  const sendRequest = Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};
