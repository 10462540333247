import React from 'react';

import styles from './styles.module.scss';
import { FormHeader, FormContainer } from 'pages/OnboardingV2Agent/components';

export const Message: React.FC = () => {
  return (
    <FormContainer>
      <div className={styles.wrapper}>
        <FormHeader title="Thank you for your interest!" />
        <div className={styles.message}>
          Enterprise pricing is customized based on the unique needs of your business. A member of
          our team will be in touch shortly to discuss next steps.
        </div>
        <div className={styles.btnContainer}>
          <a href="https://www.mosaik.io/" target="_blank" rel="noreferrer">
            Return to Website
          </a>
        </div>
      </div>
    </FormContainer>
  );
};

export const RestrictedMessage: React.FC = () => {
  return (
    <FormContainer>
      <div className={styles.wrapper}>
        <FormHeader title="Thank you for your interest!" />
        <div className={styles.message}>
          Unfortunately, there are no available subscriptions in your market at this time.
          We&apos;ve made note of your interest and a member of our team will be in touch regarding
          next steps.
        </div>
        <div className={styles.btnContainer}>
          <a href="https://www.mosaik.io/" target="_blank" rel="noreferrer">
            Return to Website
          </a>
        </div>
      </div>
    </FormContainer>
  );
};
