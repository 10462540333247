import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { PENDING } from 'settings/constants/apiState';
import { THIRD_PARTY } from 'settings/constants/roles';
import { onBoardingSignUpEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getUserDataSelector, isPartnerSelector } from 'store/selectors/user';

import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from '../../agent/components/OnboardingContinueButton';
import { ValidationSchema } from './validation';

import classNames from 'classnames';
import { Input } from 'components';
import { Col, Row } from 'components-antd';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';

const initialValues = {
  businessName: '',
  additionalBusinessName: '',
};

type Props = {
  onNext: () => {};
  stageIndex?: number;
  Controls: React.ElementType;
};

export const BusinessProfileForm: React.FC<Props> = ({ stageIndex, onNext, Controls }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const user = useSelector(getUserDataSelector);
  const isPartner = useSelector(isPartnerSelector);

  const getSchema = () => {
    return ValidationSchema;
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      businessName:
        (user?.[THIRD_PARTY]?.Partner?.BusinessName as string) || initialValues.businessName,
      additionalBusinessName:
        (user?.[THIRD_PARTY]?.Partner?.AdditionalBusinessName as string) ||
        initialValues.additionalBusinessName,
    },
    validationSchema: getSchema(),
    onSubmit(values) {
      dispatch(
        onBoardingSignUpEffect(
          { ...values, stageIndex },
          { isPartner, isCommonInfo: true },
          (err) => {
            if (!err) {
              onNext();
            }
          },
        ),
      );
    },
  });

  const isPending = onBoarding.state === PENDING;

  const getTitle = () => {
    return "Let's set up your business profile.";
  };

  return (
    <OnBoardingWrapper isPending={isPending} className={styles.wrapper}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question className={{ [styles.partnerTitle]: isPartner }}>{getTitle()}</Question>
        <p className={styles.partnerSubheader}>
          The information in your business profile is what will be displayed to agents and their
          clients.
        </p>
        <AnswersContainer>
          <form onSubmit={formik.handleSubmit}>
            <Row className={classNames(styles.row, styles.partnerRow)} gutter={[16, 20]}>
              <Col xs={24} md={24}>
                <div className={classNames(styles.text, styles.partnerHeading)}>Company Name</div>
                <Input
                  className={classNames(styles.formInput, styles.partnerFormInput)}
                  name="businessName"
                  value={formik.values.businessName}
                  onChange={formik.handleChange}
                  error={formik.touched.businessName ? formik.errors.businessName : ''}
                  disabled={isPending}
                  testid="company_name"
                  variant={Input.LIGHT}
                />
              </Col>
              <Col xs={24} md={24}>
                <div className={classNames(styles.text, styles.partnerHeading)}>
                  Additional Name <span>(Optional)</span>
                </div>

                <Input
                  name="additionalBusinessName"
                  value={formik.values.additionalBusinessName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.additionalBusinessName
                      ? formik.errors.additionalBusinessName
                      : ''
                  }
                  disabled={isPending}
                  className={classNames(styles.formInput, styles.partnerFormInput)}
                  testid="additional_name"
                  variant={Input.LIGHT}
                />
              </Col>
            </Row>
            <ButtonsContainer>
              <OnboardingContinueButton
                testid="done"
                title="Continue"
                className={styles.submit}
                isPending={isPending}
                disabled={!formik.isValid}
                onClick={formik.submitForm}
              />
            </ButtonsContainer>
          </form>
        </AnswersContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};
