import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { readFiles, showErrorMessage } from 'helpers';

import { uploadFilePopupModes } from 'settings/constants/common';

import { Dropzone } from '../../index';
import DropzoneWithFileInput from 'components/DropzoneWithFileInput';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import DocumentsModal from './DocumentsModal';

import styles from './styles.module.scss';

const UploadDocuments = (props) => {
  const {
    className,
    uploadButton,
    onSave,
    uploadIconClassName,
    dropzone,
    pendingNeeded,
    addMoreFile,
    addMore,
    fileCode,
    index,
    isOfferDocument,
    isShowingDocument,
    isTransactionOffer,
    isViewMode,
  } = props;
  const {
    withCategory,
    isOnlyUploading,
    onFileChange,
    multiple,
    withPermissions,
    isCategoryOptional,
    addDragInModal,
    docCategoryName,
    fileNameRequired,
    filesRequired,
    fillDocumentName,
  } = props;
  const [modal, setModal] = useState({ open: false, mode: uploadFilePopupModes.select });
  const [isPending, setIsPending] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const onClick = (event) => {
    event.preventDefault();
    setModal({ open: true, mode: uploadFilePopupModes.uploading });
  };

  const onCloseModal = () => {
    setUploadedFiles([]);
    setModal({ open: false });
  };

  const getFilteredFiles = (files) => {
    const acceptedExtensions = ['.doc', '.pdf', '.zip'];

    const filteredFiles = Array.from(files).filter((file) => {
      const extension = file.name.split('.').pop().toLowerCase();
      return acceptedExtensions.includes('.' + extension);
    });

    if (filteredFiles.length > 0) {
      return filteredFiles;
    } else {
      showErrorMessage('Only accepts .pdf, .zip, and .doc files');

      return [];
    }
  };

  const getFilledDocumentName = (files) => {
    return Array.from(files).map((fileItem) => ({
      ...fileItem,
      documentName: fileItem.filename || '',
    }));
  };

  const onOptionFileChange = (event) => {
    const filteredFiles = getFilteredFiles(event.target.files);

    if (filteredFiles.length > 0) {
      readFiles(filteredFiles).then((values) => {
        const fileValues = fillDocumentName ? getFilledDocumentName(values) : values;

        if (isOnlyUploading) {
          onFileChange(fileValues);
          setModal({ open: false });
        } else {
          setUploadedFiles(fileValues);
          setModal({ open: true, mode: uploadFilePopupModes.uploaded });
        }
      });
    }
  };

  const onSaveHandler = (newValues) => {
    if (pendingNeeded) {
      setIsPending(true);
    }
    const callback = (err) => {
      if (!err) {
        setModal({ open: false });
        setUploadedFiles([]);
      }
      setIsPending(false);
    };

    if (isShowingDocument || isTransactionOffer) {
      onSave(newValues, fileCode, callback);
    } else if (isOfferDocument) {
      onSave(newValues, fileCode, index, callback);
    } else {
      onSave(newValues, callback);
    }
  };

  const onDrop = (acceptedFiles) => {
    const filteredFiles = getFilteredFiles(acceptedFiles);
    if (filteredFiles.length > 0) {
      readFiles(acceptedFiles).then((values) => {
        if (values.length === 0) return;

        const fileValues = fillDocumentName ? getFilledDocumentName(values) : values;

        setUploadedFiles(fileValues);
        setModal({ open: true, mode: uploadFilePopupModes.uploaded });
      });
    }
  };

  return (
    <div className={classNames(styles.uploadDocuments, className)}>
      <div className={styles.dropzoneWrapper} onClick={(e) => !isViewMode && onClick(e)}>
        {uploadButton !== undefined ? (
          uploadButton
        ) : (
          <Icon
            className={classNames(styles.uploadIcon, uploadIconClassName)}
            variant={Icon.UPLOAD}
          />
        )}
        {dropzone && (
          <Dropzone
            onDrop={onDrop}
            onChooseFile={onClick}
            accept={['.doc', '.pdf', '.zip']}
            multiple={multiple}
          />
        )}
      </div>
      <DocumentsModal
        multiple={multiple}
        modal={modal}
        addMore={addMore}
        addMoreFile={addMoreFile}
        onCloseModal={onCloseModal}
        onOptionFileChange={onOptionFileChange}
        onSave={onSaveHandler}
        files={uploadedFiles}
        isPending={isPending}
        withCategory={withCategory}
        withPermissions={withPermissions}
        isOnlyUploading={isOnlyUploading}
        isCategoryOptional={isCategoryOptional}
        docCategoryName={docCategoryName}
        accept=".docs, .pdf, .zip"
        addDragInModal={
          addDragInModal ? (
            <DropzoneWithFileInput onDrop={onDrop} onChange={onOptionFileChange} />
          ) : undefined
        }
        fileNameRequired={fileNameRequired}
        filesRequired={filesRequired}
      />
    </div>
  );
};

UploadDocuments.propTypes = {
  className: PropTypes.string,
  uploadIconClassName: PropTypes.string,
  uploadButton: PropTypes.node,
  onSave: PropTypes.func,
  dropzone: PropTypes.bool,
  pendingNeeded: PropTypes.bool,
  withCategory: PropTypes.bool,
  withPermissions: PropTypes.bool,
  multiple: PropTypes.bool,
  isOnlyUploading: PropTypes.bool,
  onFileChange: PropTypes.func,
  isCategoryOptional: PropTypes.bool,
  index: PropTypes.number,
  isOfferDocument: PropTypes.bool,
  isShowingDocument: PropTypes.bool,
  isTransactionOffer: PropTypes.bool,
  addDragInModal: PropTypes.bool,
  isViewMode: PropTypes.bool,
  docCategoryName: PropTypes.string,
  fileNameRequired: PropTypes.bool,
  filesRequired: PropTypes.bool,
  fileCode: PropTypes.string,
  fillDocumentName: PropTypes.bool,
};

UploadDocuments.defaultProps = {
  className: '',
  uploadIconClassName: '',
  addMoreFile: () => {},
  uploadButton: undefined,
  onSave: () => {},
  dropzone: false,
  addMore: false,
  pendingNeeded: true,
  multiple: true,
  withCategory: true,
  withPermissions: false,
  isOnlyUploading: false,
  onFileChange: () => {},
  index: undefined,
  isCategoryOptional: false,
  isOfferDocument: false,
  isShowingDocument: false,
  isTransactionOffer: false,
  addDragInModal: false,
  isViewMode: false,
  docCategoryName: '',
  fileNameRequired: false,
  filesRequired: false,
  fillDocumentName: false,
  fileCode: '',
};

export default UploadDocuments;
