import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import Option from '../Option';
import Label from '../Label';

import styles from './styles.module.scss';

const Dropbox = (props) => {
  const { className } = props;

  return (
    <Option testid="dropbox_option" className={classNames(styles.dropbox, className)}>
      <Icon className={styles.icon} variant={Icon.DROPBOX} />
      <Label>Dropbox</Label>
    </Option>
  );
};

Dropbox.propTypes = {
  className: PropTypes.string,
};

Dropbox.defaultProps = {
  className: '',
};

export default Dropbox;
