import { IDLE } from 'settings/constants/apiState';
import { ConciergeConfigurationType, ConciergeConfigurationState } from 'types';

export const conciergeSearch: ConciergeConfigurationType = {
  areasOfOperation: [],
  openLocationModal: false,
  category: null,
  services: [],
  selectedTab: 0,
};

export const initialData: ConciergeConfigurationState = {
  state: IDLE,
  data: conciergeSearch,
};
