import { handleActions } from 'redux-actions';

import { setPaymentUpdateModalAction } from 'store/actions/subscription';

const initialData = {
  isModalOpened: false,
};

export default handleActions(
  {
    [setPaymentUpdateModalAction.toString()]: (state, { payload }) => ({
      ...state,
      isModalOpened: payload.isModalOpened,
    }),
  },
  initialData,
);
