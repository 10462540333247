import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Applicants } from 'components';
import Favorite from '../Favorite';
import Comment from '../Comment';
import Share from '../Share';
import { Checkbox } from 'components';
import styles from './styles.module.scss';
import { getComparesSelector, getMultipleModSelector } from 'store/selectors/feed';
import { useSelector } from 'react-redux';

const AgentBlock = (props) => {
  const { propertyInfo, className, threadsPerPropertyId, Id, isChecked } = props;
  const multiple = useSelector(getMultipleModSelector);
  const compares = useSelector(getComparesSelector);

  return (
    <div className={classNames(styles.agentBlock, className)}>
      <div className={styles.blockPart}>
        <Applicants
          propertyInfo={propertyInfo}
          className={classNames(styles.applicantWrapper)}
          applicantsClassName={styles.applicants}
        />
        <Favorite propertyInfo={propertyInfo} className={styles.favorite} />
      </div>
      {multiple ? (
        <Checkbox
          checkboxClassName={styles.checkbox}
          value={Id}
          checked={isChecked}
          hasOuterClick={true}
          className={compares?.data?.length === 5 && !isChecked ? styles.pointerEventsNone : ''}
        />
      ) : (
        <div className={styles.blockPart}>
          <Comment threadsPerPropertyId={threadsPerPropertyId} propertyInfo={propertyInfo} />
          <Share propertyInfo={propertyInfo} />
        </div>
      )}
    </div>
  );
};

AgentBlock.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({}).isRequired,
  threadsPerPropertyId: PropTypes.shape({}).isRequired,
};

AgentBlock.defaultProps = {
  className: '',
};

export default AgentBlock;
