import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Icons = (props) => {
  const { className, variant, onClick, testid } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.ARROW_BOTTOM: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 6L8 10L4 6"
              stroke={props.stroke || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ADD: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM9 6C9 5.44772 9.4477 5 10 5C10.5523 5 11 5.44772 11 6V9H14C14.5523 9 15 9.4477 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.4477 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.4477 5.44772 9 6 9H9V6Z"
              fill="#FF576D"
            />
          </svg>
        );
      }
      case Icons.PLUS: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="16" height="16" fill="none" />
            <path
              d="M8 3.33398V12.6673"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33203 8H12.6654"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DOTS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
              fill="#303030"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
              fill="#303030"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
              fill="#303030"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.UPLOAD: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66797 11.334V12.6673C2.66797 13.0209 2.80844 13.3601 3.05849 13.6101C3.30854 13.8602 3.64768 14.0007 4.0013 14.0007H12.0013C12.3549 14.0007 12.6941 13.8602 12.9441 13.6101C13.1942 13.3601 13.3346 13.0209 13.3346 12.6673V11.334"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.66797 5.99935L8.0013 2.66602L11.3346 5.99935"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 2.66602V10.666"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.EDIT: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.33398 26.6664H10.6673L24.6673 12.6664C25.3746 11.9592 25.7719 10.9999 25.7719 9.99975C25.7719 8.99956 25.3746 8.04033 24.6673 7.33309C23.9601 6.62584 23.0008 6.22852 22.0007 6.22852C21.0005 6.22852 20.0412 6.62584 19.334 7.33309L5.33398 21.3331V26.6664Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 8.66602L23.3333 13.9994"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.EDIT_DARK: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.33203 16.6663H6.66536L15.4154 7.91627C15.8574 7.47424 16.1057 6.87472 16.1057 6.2496C16.1057 5.62448 15.8574 5.02496 15.4154 4.58293C14.9733 4.14091 14.3738 3.89258 13.7487 3.89258C13.1236 3.89258 12.5241 4.14091 12.082 4.58293L3.33203 13.3329V16.6663Z"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.25 5.41797L14.5833 8.7513"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.CLOUD_UPLOAD: {
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_9840_144895)">
              <path
                d="M12.835 32.9999C10.5475 32.9999 8.35367 32.1307 6.73616 30.5836C5.11866 29.0364 4.20996 26.938 4.20996 24.7499C4.20996 22.5619 5.11866 20.4635 6.73616 18.9163C8.35367 17.3691 10.5475 16.4999 12.835 16.4999V16.4999C13.3752 14.0931 14.9557 11.978 17.2287 10.6199C18.3542 9.94748 19.6158 9.48112 20.9415 9.24749C22.2673 9.01386 23.6312 9.01753 24.9553 9.25828C26.2795 9.49904 27.538 9.97217 28.659 10.6507C29.78 11.3292 30.7415 12.1997 31.4887 13.2127C32.2359 14.2256 32.754 15.361 33.0136 16.5542C33.2732 17.7474 33.2691 18.9749 33.0016 20.1666H34.835C36.5368 20.1666 38.1689 20.8427 39.3722 22.046C40.5756 23.2494 41.2516 24.8815 41.2516 26.5833C41.2516 28.2851 40.5756 29.9172 39.3722 31.1205C38.1689 32.3239 36.5368 32.9999 34.835 32.9999H33.0016"
                stroke="#AAABAB"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.5 27.5L22 22L27.5 27.5"
                stroke="#AAABAB"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 22V38.5"
                stroke="#AAABAB"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_9840_144895">
                <rect width="44" height="44" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.ADDLINK: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path
              d="M13 12H12C10.8954 12 10 12.8954 10 14V20C10 21.1046 10.8954 22 12 22H18C19.1046 22 20 21.1046 20 20V19"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M20 8.5V15.5"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 12L23.5 12"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.LIST: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.66602 7.33333L6.66602 9.33333L9.99935 6"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.66602 15.3333L6.66602 17.3333L9.99935 14"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.66602 23.3333L6.66602 25.3333L9.99935 22"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.666 8H26.666"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.666 16H26.666"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.666 24H26.666"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.CALENDER: {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="44" height="44" rx="22" fill="#F4F5F6" />
            <path
              d="M27.25 16H16.75C15.7835 16 15 16.7835 15 17.75V28.25C15 29.2165 15.7835 30 16.75 30H27.25C28.2165 30 29 29.2165 29 28.25V17.75C29 16.7835 28.2165 16 27.25 16Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25.5 14.5V17.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.5 14.5V17.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 21H29"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.OPTIONS_EDIT: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_11240_162731)">
              <path
                d="M2.66797 13.3332H5.33464L12.3346 6.33321C12.6883 5.97959 12.8869 5.49997 12.8869 4.99988C12.8869 4.49978 12.6883 4.02016 12.3346 3.66654C11.981 3.31292 11.5014 3.11426 11.0013 3.11426C10.5012 3.11426 10.0216 3.31292 9.66797 3.66654L2.66797 10.6665V13.3332Z"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 4.33301L11.6667 6.99967"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_11240_162731">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.OPTIONS_DELETE: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_11240_162721)">
              <path
                d="M2.66797 4.66602H13.3346"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.66797 7.33398V11.334"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.33203 7.33398V11.334"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.33203 4.66602L3.9987 12.666C3.9987 13.0196 4.13917 13.3588 4.38922 13.6088C4.63927 13.8589 4.97841 13.9993 5.33203 13.9993H10.6654C11.019 13.9993 11.3581 13.8589 11.6082 13.6088C11.8582 13.3588 11.9987 13.0196 11.9987 12.666L12.6654 4.66602"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_11240_162721">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.DOWNLOAD: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_29335_8546)">
              <path
                d="M2.66797 11.3335V12.6668C2.66797 13.0205 2.80844 13.3596 3.05849 13.6096C3.30854 13.8597 3.64768 14.0002 4.0013 14.0002H12.0013C12.3549 14.0002 12.6941 13.8597 12.9441 13.6096C13.1942 13.3596 13.3346 13.0205 13.3346 12.6668V11.3335"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.66797 7.3335L8.0013 10.6668L11.3346 7.3335"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 2.6665V10.6665"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_29335_8546">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.DUE_DATE: {
        return (
          <svg
            className={styles.offsetIcon}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#EEF0EF" />
            <path
              d="M20.9997 10.168H10.9997C10.0792 10.168 9.33301 10.9142 9.33301 11.8346V21.8346C9.33301 22.7551 10.0792 23.5013 10.9997 23.5013H20.9997C21.9201 23.5013 22.6663 22.7551 22.6663 21.8346V11.8346C22.6663 10.9142 21.9201 10.168 20.9997 10.168Z"
              stroke="#525252"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.333 8.5V11.8333"
              stroke="#525252"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.667 8.5V11.8333"
              stroke="#525252"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.33301 15.168H22.6663"
              stroke="#525252"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.OPTIONS_PLUS: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3 8H13" stroke="#FF576D" strokeWidth="2" strokeLinecap="round" />
            <path d="M8 13L8 3" stroke="#FF576D" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icons, className)}>
      {getIcons()}
    </div>
  );
};

Icons.ARROW_BOTTOM = 'arrowBottom';
Icons.PLUS = 'plus';
Icons.ADD = 'add';
Icons.DOTS = 'dots';
Icons.UPLOAD = 'upload';
Icons.CLOUD_UPLOAD = 'cloudUpload';
Icons.EDIT = 'edit';
Icons.EDIT_DARK = 'editDark';
Icons.LIST = 'list';
Icons.ADDLINK = 'addlink';
Icons.CALENDER = 'calender';
Icons.OPTIONS_EDIT = 'optionsEdit';
Icons.OPTIONS_DELETE = 'optionsDelete';
Icons.DUE_DATE = 'dueDate';
Icons.DOWNLOAD = 'download';
Icons.OPTIONS_PLUS = 'optionsPlus';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  testid: PropTypes.string,
  onClick: PropTypes.func,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};

export default Icons;
