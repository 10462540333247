function Square({ colorWithHash }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.9">
        <path
          d="M1 32C0.447715 32 0 31.5523 0 31V1C0 0.447715 0.447715 0 1 0H31C31.5523 0 32 0.447715 32 1V31C32 31.5523 31.5523 32 31 32H1Z"
          fill={colorWithHash}
        />
      </g>
    </svg>
  );
}

export default Square;
