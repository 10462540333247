import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getLoadingPartnersData } from 'store/selectors/partners';
import {
  requestGetPartnersListByCategoryIdEffect,
  requestGetRecommendedPartnersByAgentListEffect,
} from 'store/effects/partners';

import { Wrapper } from 'components';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { getUserRoleSelector } from 'store/selectors/user';
import { AGENT } from 'settings/constants/roles';

const RequestCategoriesWrapper = ({ children, className }) => {
  const dispatch = useDispatch();
  const { metaId, isPending, isData, isError } = useSelector(getLoadingPartnersData);
  const {
    areasOfOperation,
    category: categoryId,
    services,
  } = useSelector(getConciergeSearchSelector);
  const userRole = useSelector(getUserRoleSelector);

  useEffect(() => {
    dispatch(requestGetPartnersListByCategoryIdEffect());
  }, [areasOfOperation, services]);

  useEffect(() => {
    if (metaId !== categoryId && !isPending) {
      dispatch(requestGetPartnersListByCategoryIdEffect());
    }
  }, [metaId, isPending, categoryId, areasOfOperation]);

  useEffect(() => {
    if (userRole === AGENT) {
      dispatch(requestGetRecommendedPartnersByAgentListEffect());
    }
  }, [dispatch, categoryId, userRole, areasOfOperation, services]);

  const isLoading = useMemo(() => {
    if (metaId !== categoryId) {
      return true;
    }
    if (isError) {
      return false;
    }
    if (isPending) {
      return true;
    }
    if (!isData) {
      return true;
    }
    return false;
  }, [isPending, isError, isData, categoryId]);

  return (
    <Wrapper className={className} isPending={isLoading}>
      {children}
    </Wrapper>
  );
};

RequestCategoriesWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

RequestCategoriesWrapper.defaultProps = {
  className: '',
};

export default RequestCategoriesWrapper;
