import React from 'react';
import PropTypes from 'prop-types';

import { Icons } from '../Icons/Icons';
import styles from './styles.module.scss';

export const ArchiveOption = ({ optionText }) => (
  <div className={styles.threadOption}>
    <Icons color="#FF576D" variant={Icons.ARCHIVE} className={styles.threadOptionIcon} />
    <span>{optionText}</span>
  </div>
);

ArchiveOption.propTypes = {
  optionText: PropTypes.string,
};

ArchiveOption.defaultProps = {
  optionText: '',
};
