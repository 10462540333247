import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { getSimpleDate, getDateOnly, subtractTimeZone } from 'helpers';

import { useSelector, useDispatch } from 'react-redux';
import {
  getTransactionAccessSelector,
  getTransactionSelector,
  getKeyDatesSelector,
} from 'store/selectors/transaction';
import { updateKeyDatesEffect, getTransactionEffect } from 'store/effects/transactions';

import Icon from 'pages/Workshop/Transactions/TransactionOverview/Icons';
import { Button } from 'components-antd';
import { DatePicker } from 'components';

import styles from './styles.module.scss';
import transactionViewStyles from './../TransactionView/styles.module.scss';

const ClosingDate = (props) => {
  const { value, label, itemToUpdateKey, setEditId, isDisableField } = props;
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [isEdit, setIsEdit] = useState(false);
  const [date, setDate] = useState(value ? moment(getDateOnly(value)).toDate() : null);
  const payloadKey = transaction?.IsProject ? 'ProjectKeyDates' : 'TransactionKeyDatePrices';
  useEffect(() => {
    if (value) setDate(moment(getDateOnly(value)).toDate());
  }, [value]);

  const onEdit = () => {
    if (!fullAccess || isDisableField(itemToUpdateKey)) return null;
    setIsEdit(true);
    setEditId(itemToUpdateKey);
  };

  const updateDate = () => {
    setIsPending(true);
    dispatch(
      updateKeyDatesEffect(
        {
          transactionId: transaction?.Id,
          [payloadKey]: {
            [itemToUpdateKey]: subtractTimeZone(new Date(date), 'YYYY-MM-DD'),
          },
        },
        {},
        (err) => {
          if (!err) {
            setIsEdit(false);
            setEditId(null);
            dispatch(getTransactionEffect({ id: transaction?.Id }, { silent: true }));
          }
          setIsPending(false);
        },
      ),
    );
  };

  const onCancel = () => {
    setIsEdit(false);
    setDate(value ? new Date(value) : null);
    setEditId(null);
  };

  const isSameDate = () => moment(value).isSame(date);

  return (
    <>
      {!isEdit ? (
        <div
          className={classNames(
            styles.closingDate,
            { [transactionViewStyles.fullAccess]: fullAccess },
            { [transactionViewStyles.disableField]: !!isDisableField(itemToUpdateKey) },
          )}
          onClick={onEdit}
        >
          <div>
            <div>{label}:</div>
            <div testid="closing_date" className={styles.date}>
              {date ? getDateOnly(date) : undefined}
            </div>
          </div>
          {fullAccess && !isDisableField(itemToUpdateKey) && (
            <Icon testid="edit_icon" className={styles.editIcon} variant={Icon.EDIT} />
          )}
        </div>
      ) : (
        <div className={transactionViewStyles.editWrapper}>
          <div>
            <label>{label}:</label>
            <DatePicker
              value={getDateOnly(date)}
              options={{
                enableTime: false,
              }}
              variant="rounded"
              onChange={(e, val) => setDate(val?.[0])}
              disabled={isPending}
              testid="closing_date_picker"
              className={transactionViewStyles.marginTop}
            />
          </div>
          <div className={transactionViewStyles.buttons}>
            <Button
              variant="secondary"
              size="large"
              disabled={isSameDate() || !date}
              onClick={updateDate}
              loading={isPending}
            >
              Update
            </Button>
            <Button variant="hollow-bordered" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

ClosingDate.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  isProject: PropTypes.bool,
};

ClosingDate.defaultProps = {
  className: '',
  value: '',
  isProject: false,
};

export default ClosingDate;
