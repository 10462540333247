/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import { Match as CommonMatch } from 'components';

const Match = ({ className, propertyInfo }) => (
  <CommonMatch className={className} propertyInfo={propertyInfo} />
);

Match.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    MatchScore: PropTypes.number,
  }).isRequired,
};

Match.defaultProps = {
  className: '',
};

export default Match;
