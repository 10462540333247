import { ReactElement } from 'react';
import { MemoryRouter, MemoryRouterProps } from 'react-router-dom';
import { render } from '@testing-library/react';
import { Provider } from 'react-redux';
import configureStore from 'redux-mock-store';
import thunk from 'redux-thunk';

import { RootState } from 'store';

const mockStore = configureStore([thunk]);

export const rootInitialState: Partial<RootState> = {};

export const renderWithRedux = (
  ui: ReactElement,
  initialState: Partial<RootState> = rootInitialState,
  memoryRouterProps?: MemoryRouterProps,
) => {
  const store = mockStore(initialState);

  return {
    ...render(
      <Provider store={store}>
        <MemoryRouter {...memoryRouterProps}>{ui}</MemoryRouter>
      </Provider>,
    ),
    mockStore: store,
  };
};
