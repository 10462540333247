import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const Write = memo(({ className }) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4999 12.8333H10.9999C10.0275 12.8333 9.09483 13.2196 8.40719 13.9072C7.71956 14.5948 7.33325 15.5275 7.33325 16.4999V32.9999C7.33325 33.9724 7.71956 34.905 8.40719 35.5926C9.09483 36.2803 10.0275 36.6666 10.9999 36.6666H27.4999C28.4724 36.6666 29.405 36.2803 30.0926 35.5926C30.7803 34.905 31.1666 33.9724 31.1666 32.9999V27.4999"
            stroke="black"
            strokeWidth="2.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 27.5H22L37.5833 11.9167C38.3127 11.1873 38.7224 10.1981 38.7224 9.16668C38.7224 8.13522 38.3127 7.14602 37.5833 6.41668C36.854 5.68733 35.8648 5.27759 34.8333 5.27759C33.8019 5.27759 32.8127 5.68733 32.0833 6.41668L16.5 22V27.5Z"
            stroke="black"
            strokeWidth="2.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.3333 9.16675L34.8333 14.6667"
            stroke="black"
            strokeWidth="2.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

Write.propTypes = {
  className: PropTypes.string,
};

Write.defaultProps = {
  className: '',
};

export default Write;
