import PropTypes from 'prop-types';
import moment from 'moment';

import { getTimeZone, limitTextLength } from 'helpers/index';
import { FormattedPhone } from 'components';

import styles from './styles.module.scss';
import { OfferStatus } from 'types';
import { renderOfferExpiry } from 'pages/OfferPortal/components/InboundOutboundContainer/helper';

interface DetailsProps {
  data: {
    CreatedDate: Date;
    OfferExpiryEndDate?: Date;
    OfferExpiryDays?: number;
  };
  offerData: any;
}

export const Details = ({ data, offerData }: DetailsProps) => {
  const CreatedDate = data?.CreatedDate;
  const { BuyingAgentName, BuyingAgentBrokerage, BuyingAgentPhone, BuyingAgentEmail } = offerData;

  const renderSubmittedOn = (iteration) => {
    let date;
    let heading;
    switch (iteration.IterationStatus) {
      case OfferStatus.Withdrawn:
        date = iteration.WithdrawnOn;
        heading = 'Withdrawal Date';
        break;
      case OfferStatus.Rejected:
        date = iteration.RejectedOn;
        heading = 'Rejection Date';
        break;
      case OfferStatus.Cancelled:
        date = iteration.UpdatedDate;
        heading = 'Cancellation Date';
        break;
      default:
        date = CreatedDate;
        heading = 'Submitted Date';
        break;
    }
    return (
      <>
        <h3>{heading}</h3>
        <p>{moment(date).format('MM/DD/YYYY')}</p>
        <p>{`${moment(date).format('LT')} (${getTimeZone()})`}</p>
      </>
    );
  };

  return (
    <div className={styles.details}>
      <h3 className={styles.detailsHd}>Details</h3>
      <div className={styles.offerInfo}>
        <div className={styles.dateTime}>{renderSubmittedOn(data)}</div>
        <div className={styles.requestor}>
          <h3>Submitted By:</h3>
          {BuyingAgentName && <p>{limitTextLength(BuyingAgentName, 15)}</p>}
          {BuyingAgentBrokerage && <p>{limitTextLength(BuyingAgentBrokerage, 15)}</p>}
          {BuyingAgentPhone && (
            <FormattedPhone className={styles.phone}>{BuyingAgentPhone}</FormattedPhone>
          )}
          {BuyingAgentEmail ? <span>{BuyingAgentEmail}</span> : null}
        </div>
      </div>
      {(data?.OfferExpiryEndDate || data?.OfferExpiryDays) && (
        <div>
          <h3>Expires:</h3>
          <span className={styles.expiryText}>
            {renderOfferExpiry(data, {
              dayText: 'd',
              hrText: 'hr',
              minText: 'min',
              seperator: ', ',
            })}
          </span>
        </div>
      )}
    </div>
  );
};
