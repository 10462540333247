import classNames from 'classnames';
import { Input } from 'components';

import styles from '../styles.module.scss';

interface ParticipantEmailProps {
  className?: string;
  inputHolderClassName?: string;
  customEmail?: string;
  placeholder?: string;
  error?: string;
  onUpdate: (...arg) => void;
  onBlurInput?: (...arg) => void;
  variant?: string;
}

export const ParticipantEmail = ({
  className,
  inputHolderClassName,
  customEmail,
  placeholder,
  error,
  onUpdate,
  onBlurInput,
  variant = Input.LIGHT,
}: ParticipantEmailProps) => (
  <Input
    inputHolderClassName={classNames(styles.emailInput, inputHolderClassName)}
    className={className}
    variant={variant}
    placeholder={placeholder || 'Enter email'}
    onChange={(e, val) => onUpdate(val)}
    onBlur={(e, val) => onBlurInput && onBlurInput(val)}
    value={customEmail}
    error={error}
  />
);
