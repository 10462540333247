import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from 'components-antd';

import { map } from 'lodash-es';

import SearchBlockActions from '../SearchBlockActions';
import { SEARCH_INSTANCE_STATUS } from 'app-constants';
import { Col } from 'antd';

import styles from './styles.module.scss';
import { Info } from 'components/Icons';
import moment from 'moment';

const SearchCard = ({
  title,
  updatedDate,
  status,
  agentName,
  info,
  fullInfo,
  footer,
  withActions,
  infoClassName,
  className,
  onClick,
  onEdit,
  onSearch,
  onRename,
  onDelete,
  onInactive,
  onActivate,
  photos,
  testid,
}) => {
  const editHandler = useCallback(() => {
    onEdit();
  }, [onEdit]);

  const searchHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onSearch();
    },
    [onSearch],
  );

  const inactiveHandler = useCallback(() => {
    onInactive();
  }, [onInactive]);

  const activateHandler = useCallback(() => {
    onActivate();
  }, [onActivate]);

  return (
    <>
      <Col
        xs={24}
        sm={16}
        md={16}
        lg={16}
        xxl={16}
        onClick={onClick}
        className={classNames({ [styles.clickable]: !!onClick })}
        flex={1}
      >
        <div style={{ display: 'flex', gap: '18px' }}>
          <div testid={testid} className={classNames(styles.container, className)}>
            <div
              className={classNames(styles.images, {
                [styles.blurred]: status === SEARCH_INSTANCE_STATUS.INACTIVE,
              })}
            >
              {photos?.length ? (
                map(photos, (link, index) =>
                  index < 4 ? <img key={index} alt="Search images" src={link} /> : '',
                )
              ) : (
                <div className={styles.noImages}>No images</div>
              )}
            </div>
          </div>
          <div
            className={classNames(styles.infoBlock, infoClassName, {
              [styles.deactivated]: status === SEARCH_INSTANCE_STATUS.INACTIVE,
            })}
          >
            <div className={styles.infoBlockWrap}>
              <div
                className={classNames(
                  styles.infoBlockLeft,
                  status === SEARCH_INSTANCE_STATUS.INACTIVE
                    ? styles.infoBlockLeftInactive
                    : styles.infoBlockLeftActive,
                )}
              >
                {title ? (
                  <div testid="search_title" className={styles.descTitle}>
                    {title}
                    {/* 
                    Disabling temporarily for the demo.
                    {isNotProd && (
                      <span className={styles.smallDate}>
                        {` ${moment(updatedDate).format('YYYYMMDD:HH:mm:ss')}`}
                      </span>
                    )} */}
                  </div>
                ) : (
                  <a>&nbsp;</a>
                )}

                <div className={styles.descInfoWrapper}>
                  {info ? (
                    <p
                      testid="search_info"
                      className={classNames(styles.descInfoWrapped, styles.descInfo)}
                    >
                      {info}
                    </p>
                  ) : (
                    <div className={styles.emptyDescInfo}></div>
                  )}
                  {fullInfo && (
                    <Tooltip
                      placement="top"
                      getPopupContainer={(trigger) => trigger}
                      overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
                      title={
                        <p testid="search_info" className={styles.descInfo}>
                          {fullInfo}
                        </p>
                      }
                    >
                      <div>
                        <Info size={'16'} />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>

            {/* <div className={styles.descFooter}>
            <div testid="search_footer" className={styles.footerInfo}>
              {footer || '-'}
            </div>
          </div> */}
          </div>
        </div>
      </Col>
      <Col xs={24} sm={4} md={4} lg={4} xxl={4}>
        <div className={styles.descFooter}>
          {withActions && title !== 'My Primary Search' ? (
            <SearchBlockActions
              popupPosition={'bottom'}
              onDelete={onDelete}
              onSearch={searchHandler}
              onRename={onRename}
              searchName={title}
              onEdit={editHandler}
              onActivate={status === SEARCH_INSTANCE_STATUS.INACTIVE ? activateHandler : null}
              onInactive={status === SEARCH_INSTANCE_STATUS.ACTIVE ? inactiveHandler : null}
            />
          ) : null}
        </div>
      </Col>
    </>
  );
};

SearchCard.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  status: PropTypes.string,
  agentName: PropTypes.string,
  info: PropTypes.string,
  footer: PropTypes.string,
  className: PropTypes.string,
  infoClassName: PropTypes.string,
  testid: PropTypes.string,
  withActions: PropTypes.bool,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onSearch: PropTypes.func,
  onRename: PropTypes.func,
  searchName: PropTypes.string,
  onDelete: PropTypes.func,
  onInactive: PropTypes.func,
  onActivate: PropTypes.func,
};

SearchCard.defaultProps = {
  photos: null,
  withActions: true,
  className: '',
  infoClassName: '',
  testid: undefined,
  title: '',
  agentName: '',
  status: '',
  info: '',
  footer: '',
  onClick: null,
  searchName: '',
  onEdit: () => {},
  onSearch: () => {},
  onRename: () => {},
  onDelete: () => {},
  onActivate: () => {},
  onInactive: () => {},
  isInactive: false,
};

export default SearchCard;
