import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get, cloneDeep } from 'lodash-es';

import {
  requestGetContactDataAction,
  requestGetRelatedEntitiesCompleteListDataAction,
  requestGetRelatedEntitiesDataAction,
} from 'store/actions/relatedEntities';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  entities: [],
  entitiesCompleteList: [],
  contacts: [],
};

export default handleActions(
  {
    [requestGetRelatedEntitiesDataAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      entities: get(payload, ['data', 'result']),
      contacts: initialData.contacts,
    }),
    [requestGetRelatedEntitiesCompleteListDataAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      entitiesCompleteList: get(payload, ['data', 'result']),
    }),
    [requestGetContactDataAction]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        contacts: {
          ...initialData.contacts,
          [get(payload, 'data')?.Id]: get(payload, 'data'),
        },
      };
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
