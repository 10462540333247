import classNames from 'classnames';
import { Input } from 'components';
import Plus from 'components/Icons/Plus';
import { Remove } from 'components/Icons';

import { initialState } from '../../FinancingDetails';

import styles from './../../styles.module.scss';

export const OtherFees = ({ otherFeeData, setOtherFeeData, setOtherValues }) => {
  return (
    <div className={styles.otherFeesContainer}>
      {otherFeeData?.map(({ Description, IsFeeTypePercentage, Value }, index) => (
        <div className={styles.form} key={index}>
          <Input
            variant={Input.LIGHT_ROUND}
            label="Fee"
            onChange={(e, val) => {
              setOtherValues(val, index, 'Description');
            }}
            value={Description}
          />
          <Input
            label="Amount"
            numberInputClassName={styles.amountInput}
            labelRightIcon={
              <>
                {index != 0 && (
                  <Remove
                    className={styles.icon}
                    color={'#FF576D'}
                    onClick={() => {
                      setOtherFeeData((prev) => prev.filter((_, i) => index !== i));
                    }}
                  />
                )}
              </>
            }
            variant={Input.LIGHT_ROUND}
            placeholder={IsFeeTypePercentage ? '0%' : '$0'}
            isNumberFormat
            icon={
              <div className={styles.valueTypeContainer}>
                {['%', '$'].map((symbol, i) => {
                  const active =
                    (IsFeeTypePercentage && symbol === '%') ||
                    (!IsFeeTypePercentage && symbol === '$');
                  return (
                    <div
                      key={symbol}
                      onClick={() => {
                        if (!active) {
                          setOtherValues(symbol === '%', index, 'IsFeeTypePercentage');
                        }
                        if (symbol === '%') {
                          setOtherValues('', index, 'Value');
                        }
                      }}
                      className={classNames(
                        styles.button,
                        {
                          [styles.active]: active,
                        },
                        {
                          [styles.border]: i === 0,
                        },
                      )}
                    >
                      {symbol}
                    </div>
                  );
                })}
              </div>
            }
            // suffix={IsFeeTypePercentage ? '%' : undefined}
            // prefix={!IsFeeTypePercentage ? '$' : undefined}
            onChange={(e, val) => {
              setOtherValues(Number(val), index, 'Value');
            }}
            value={Value}
            minNumber={IsFeeTypePercentage ? 0 : undefined}
            maxNumber={IsFeeTypePercentage ? 100 : undefined}
          />
        </div>
      ))}
      <div className={styles.addContainer}>
        <Plus className={styles.plus} color={'#515151'} />
        <p
          className={styles.addText}
          onClick={() => {
            setOtherFeeData((prev) => [...prev, { ...initialState }]);
          }}
        >
          Add Another
        </p>
      </div>
    </div>
  );
};
