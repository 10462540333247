import { llm01ApiServer, llm02ApiServer } from 'settings/web-services/Mozzie/api';

export function getIntent(data) {
  return llm01ApiServer.post(`/find_intent`, data);
}

export function sendMessageToLLM02(data) {
  return llm02ApiServer.post(`/history_aware_chat`, data);
}

export function loadTemplate(data) {
  return llm02ApiServer.post('/load_template', data);
}
