import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BaseNotification from '../../../BaseNotification';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { convertNameToAvatarPlaceholder } from 'helpers';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';
import { link } from 'settings/navigation/link';

const ConfirmedShowingAppointmentNotification = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const { ClientAvatarUrl, BuyingAgentName, TransactionId, ShowingUUID } = Payload || {};

  const onClickHandler = () => {
    if (TransactionId && ShowingUUID) {
      const path = link.toTransactionShowings(TransactionId, ShowingUUID);
      history.push(path);
      dispatch(openNotificationsDrawerEffect({ open: false }));
    }
  };

  return (
    <div className={classNames(styles.notificationClientPrefsUpdated, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        date={CreatedDate}
        groupType={groupType}
        avatarUrl={ClientAvatarUrl}
        avatarPlaceholder={convertNameToAvatarPlaceholder(BuyingAgentName)}
        severityLevel={SeverityLevel}
        onClick={onClickHandler}
      />
    </div>
  );
};

ConfirmedShowingAppointmentNotification.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    RequesterFirstName: PropTypes.string,
    RequesterSecondName: PropTypes.string,
    ClientAvatarUrl: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

ConfirmedShowingAppointmentNotification.defaultProps = {
  className: '',
};

export default ConfirmedShowingAppointmentNotification;
