export const getFilesName = (documents) => {
  let fileName: string[] = [];

  documents.forEach(({ FormName, Name, documentName, formName }, index) => {
    let name = index < 6 ? FormName || Name || documentName || formName : '..';

    fileName.push(name);
  });

  return fileName.join(', ');
};
