import { useState } from 'react';

import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import PartnersPreviewModal from './PartnersPreviewModal';
import { readExcelFile } from 'utils/readFile';

import styles from './styles.module.scss';

const UploadPartners = () => {
  const [partnersList, setPartnersList] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);

  const handleUpload = (e) => {
    readExcelFile(e, setPartnersList);
    setPreviewModal(true);
  };

  const downloadTemplate = () => {
    const url =
      'https://mosaik-assets.s3.amazonaws.com/referenceData/Mosaik_Partners_Upload_Template_V3.xlsx';
    window.open(url, '_blank');
  };

  return (
    <div className={styles.uploadPartnersContainer}>
      <button className={styles.downloadButton} onClick={downloadTemplate}>
        <Icon variant={Icon.DOWNLOAD} />
      </button>
      <div>
        <label htmlFor="file-upload" className={styles.uploadButton}>
          <Icon variant={Icon.UPLOAD} />
          Upload
        </label>
        <input id="file-upload" type="file" accept=".xls,.xlsx,.csv" onChange={handleUpload} />
      </div>
      <PartnersPreviewModal
        open={previewModal}
        partnersList={partnersList}
        setPreviewModal={setPreviewModal}
      />
    </div>
  );
};

export default UploadPartners;
