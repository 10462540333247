/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ItemsWrapper, ItemWrapper, Value } from '../components';

import styles from './styles.module.scss';

const HoaFees = (props) => {
  const { className, hoaFees, isPending } = props;

  if (!hoaFees) return null;

  return (
    <ItemsWrapper title="HOA fees" className={classNames(styles.hoaFees, className)}>
      {hoaFees.map((hoaFee, index) => (
        <ItemWrapper key={index} isPending={isPending}>
          <Value testid="hoa_fees" value={hoaFee} />
        </ItemWrapper>
      ))}
    </ItemsWrapper>
  );
};

HoaFees.propTypes = {
  className: PropTypes.string,
  hoaFees: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPending: PropTypes.bool,
};

HoaFees.defaultProps = {
  className: '',
  isPending: false,
};

export default HoaFees;
