import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { Close as CloseIcon } from 'components/Icons';
import { Icons } from 'pages/Workshop/Transactions/Icons';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { isPropertyAddress } from 'store/effects/quotes/helpers';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { transactionRoles } from 'settings/constants/roles';
import { routes } from 'settings/navigation/routes';
import { resetTransactionCreateEffect } from 'store/effects/transactions';
import { validateParticipantValues } from '../../../helpers';

import styles from './styles.module.scss';
import classNames from 'classnames';
import useIsProjectRoute from 'hooks/use-is-project-route';

export const Controls = ({
  onPrev,
  onNext,
  onSubmit,
  onAnswer,
  currentQuestionId,
  controlsClassName,
}) => {
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const isProjectRoute = useIsProjectRoute();

  const onNextHandler = (event) => {
    if (isNextDisabled()) return;

    if (currentQuestionId === transactionPreFormQuestionsIds.files) {
      onSubmit();
    } else if (currentQuestionId === transactionPreFormQuestionsIds.propertyType) {
      onAnswer(preForm?.[transactionPreFormQuestionsIds.propertyType]);
    } else if (currentQuestionId === transactionPreFormQuestionsIds.isPropertyUnderContract) {
      onAnswer(preForm?.[transactionPreFormQuestionsIds.isPropertyUnderContract]);
    } else {
      onNext(event);
    }
  };

  const onPrevHandler = (event) => {
    if (isPrevDisabled()) return;
    onPrev(event);
  };

  const onClose = () => {
    dispatch(resetTransactionCreateEffect());
    history.push(isProjectRoute ? routes.projects : routes.transactions);
  };

  const getRepresentingValueFor = (role) =>
    preForm?.[transactionPreFormQuestionsIds.whoAreYouRepresenting]?.[role];

  const validateWhoAreYouRepresenting = () =>
    currentQuestionId === transactionPreFormQuestionsIds.whoAreYouRepresenting &&
    !getRepresentingValueFor(transactionRoles.BUYER) &&
    !getRepresentingValueFor(transactionRoles.SELLER);

  const validateParticipants = () => {
    const participants = [
      ...(preForm?.[transactionPreFormQuestionsIds.clients] || []),
      ...(preForm?.[transactionPreFormQuestionsIds.participants] || []),
    ];
    if (
      (currentQuestionId === transactionPreFormQuestionsIds.inviteBuyer ||
        currentQuestionId === transactionPreFormQuestionsIds.inviteSeller ||
        currentQuestionId === transactionPreFormQuestionsIds.participants) &&
      participants?.length
    ) {
      return !validateParticipantValues(participants);
    } else {
      return false;
    }
  };

  const validateAssignedParticipants = () => {
    const participants = [
      ...(preForm?.[transactionPreFormQuestionsIds.assignParticipantsToRoles] || []),
    ];
    if (
      currentQuestionId === transactionPreFormQuestionsIds.assignParticipantsToRoles &&
      participants?.length
    ) {
      return !validateParticipantValues(participants);
    } else {
      return false;
    }
  };

  const validateAddress = () =>
    currentQuestionId === transactionPreFormQuestionsIds.address &&
    !isPropertyAddress({ address: preForm?.[transactionPreFormQuestionsIds.address] });

  const validatePropertyUnderContract = () =>
    currentQuestionId === transactionPreFormQuestionsIds.isPropertyUnderContract &&
    preForm?.[transactionPreFormQuestionsIds.isPropertyUnderContract] === undefined;

  const validatePurchasePrice = () =>
    currentQuestionId === transactionPreFormQuestionsIds.purchasePrice &&
    !preForm?.[transactionPreFormQuestionsIds.purchasePrice];

  const validateListingPrice = () =>
    currentQuestionId === transactionPreFormQuestionsIds.listingPrice &&
    !preForm?.[transactionPreFormQuestionsIds.listingPrice];

  const validateEffectiveDate = () =>
    currentQuestionId === transactionPreFormQuestionsIds.effectiveDate &&
    !preForm?.[transactionPreFormQuestionsIds.effectiveDate];

  const validateClosingDate = () =>
    currentQuestionId === transactionPreFormQuestionsIds.closingDate &&
    !preForm?.[transactionPreFormQuestionsIds.closingDate];

  const validateValidUntil = () =>
    currentQuestionId === transactionPreFormQuestionsIds.validUntil &&
    !preForm?.[transactionPreFormQuestionsIds.validUntil];

  const validateLiveDate = () =>
    currentQuestionId === transactionPreFormQuestionsIds.liveDate &&
    !preForm?.[transactionPreFormQuestionsIds.liveDate];

  const validateTransactionTemplate = () =>
    currentQuestionId === transactionPreFormQuestionsIds.transactionTemplate &&
    preForm?.[transactionPreFormQuestionsIds.transactionTemplate] === undefined;

  const validateTimeline = () => {
    let flag = false;
    if (currentQuestionId === transactionPreFormQuestionsIds.timeline) {
      const timelineValues = preForm?.[transactionPreFormQuestionsIds.timeline] || [];
      for (let index in timelineValues) {
        const timeline = timelineValues[index];
        if (!timeline.Title || !timeline.DueDate) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  };

  const validateCreatorRole = () =>
    currentQuestionId === transactionPreFormQuestionsIds.creatorRole &&
    !preForm?.[transactionPreFormQuestionsIds.creatorRole];

  const validateComplete = () => currentQuestionId === transactionPreFormQuestionsIds.complete;

  const isNextDisabled = () =>
    validateWhoAreYouRepresenting() ||
    validateParticipants() ||
    validateAddress() ||
    validatePropertyUnderContract() ||
    validatePurchasePrice() ||
    validateListingPrice() ||
    validateEffectiveDate() ||
    validateClosingDate() ||
    validateValidUntil() ||
    validateLiveDate() ||
    validateTransactionTemplate() ||
    validateTimeline() ||
    validateAssignedParticipants() ||
    validateCreatorRole() ||
    validateComplete();

  const isPrevDisabled = () => {
    return (
      currentQuestionId === transactionPreFormQuestionsIds.whoAreYouRepresenting ||
      currentQuestionId === transactionPreFormQuestionsIds.enableShowing ||
      currentQuestionId === transactionPreFormQuestionsIds.complete
    );
  };

  return (
    <div className={classNames(styles.controls, controlsClassName)}>
      {currentQuestionId !== transactionPreFormQuestionsIds.whoAreYouRepresenting ? (
        <Icons
          variant="arrowLeft"
          className={classnames(styles.iconBtn, styles.arrowLeft, {
            [styles.disabled]: isPrevDisabled(),
          })}
          onClick={onPrevHandler}
        />
      ) : (
        <></>
      )}
      <CloseIcon onClick={onClose} className={classNames(styles.iconBtn, styles.closeBtn)} />
    </div>
  );
};

Controls.prototype = {
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onSubmit: PropTypes.func,
  onAnswer: PropTypes.func,
  currentQuestionId: PropTypes.string.isRequired,
  controlsClassName: PropTypes.string,
};

Controls.defaultProps = {
  onPrev: () => {},
  onNext: () => {},
  onSubmit: () => {},
  onAnswer: () => {},
  currentQuestionId: '',
  controlsClassName: '',
};
