import { FormikProps, useFormikContext } from 'formik';
import { Dispatch, FC, useRef, SetStateAction, MutableRefObject, useEffect, useState } from 'react';

import { FormTitle, Toggle } from 'components';
import Footer from 'components/ClientsDrawer/Footer';
import { AddDocumentsSection, AddManualEntrySection } from '.';
import { InitialValueTypes } from '../types';
import Attachment from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/UploadFiles/Attachment';
import { FieldArray } from 'formik';
import { UploadIcon } from 'components/Icons/UploadIcon';

import styles from './styles.module.scss';
import { ArrowLeftFull } from 'components/Icons';
import { Button } from 'components-antd';
import { readFiles } from 'helpers';

interface Props {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  isLoading?: boolean;
  editPreApprovalId: number | null;
  initialValues: InitialValueTypes;
  setPreApprovalInitialValues: Dispatch<SetStateAction<InitialValueTypes>>;
  handleBackPress?: () => void;
  showPreApprovalsListings?: boolean;
}
export const AddEditPreApprovalDocument: FC<Props> = ({
  currentStep,
  setCurrentStep,
  isLoading,
  editPreApprovalId,
  initialValues,
  setPreApprovalInitialValues,
  handleBackPress,
  showPreApprovalsListings,
}) => {
  const { values, setFieldValue, handleChange, resetForm }: FormikProps<InitialValueTypes> =
    useFormikContext();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const fileInputRef: MutableRefObject<any> = useRef(null);
  useEffect(() => {
    setIsSubmitted(false);
    setCurrentStep(0);
    if (!editPreApprovalId) {
      setPreApprovalInitialValues({
        ...initialValues,
        isManualDataInput: values.isManualDataInput,
      });
    }
  }, [values.isManualDataInput]);
  useEffect(() => {
    if (!showPreApprovalsListings) {
      resetForm();
    }
  }, [showPreApprovalsListings]);
  const handleRemoveAttachment = (i, remove) => {
    if (values.preApprovalsDocuments.length > 1) {
      remove(i);
    } else {
      setPreApprovalInitialValues({ ...initialValues });
      setCurrentStep(0);
    }
  };

  const handleArrowBackClick = () => {
    resetForm();
    setCurrentStep(0);
  };
  const handleBackButtonClick = () => {
    resetForm();
    handleBackPress?.();
  };
  const getPreApprovalHeading = () => {
    if (!values.isManualDataInput && !currentStep) {
      return (
        <p className={styles.arrowBackWrapper}>
          {handleBackPress && (
            <ArrowLeftFull className={styles.backArrow} onClick={handleBackButtonClick} />
          )}
          <span className={styles.arrowHeadingWrapper}>
            <p>Upload Pre-Approval</p>
          </span>
        </p>
      );
    } else if (!values.isManualDataInput && currentStep) {
      return (
        <p className={styles.arrowBackWrapper}>
          {currentStep ? (
            <ArrowLeftFull className={styles.backArrow} onClick={handleArrowBackClick} />
          ) : null}
          <span className={styles.arrowHeadingWrapper}>
            <p>Uploaded File</p>
          </span>
        </p>
      );
    } else
      return (
        <p className={styles.arrowBackWrapper}>
          {handleBackPress && (
            <ArrowLeftFull className={styles.backArrow} onClick={handleBackButtonClick} />
          )}
          <span className={styles.arrowHeadingWrapper}>
            <p>Enter Pre-Approval Information</p>
          </span>
        </p>
      );
  };
  const handleNewFileUpload = (event) => {
    const acceptedFiles = event.target.files;
    readFiles(acceptedFiles).then((files) => {
      const { contentType: ContentType, data, filename: Filename, size: Size } = files[0];
      const Data = btoa(data);
      const preApprovals = [...values.preApprovalsDocuments];
      const newPreApproval = { ...initialValues.preApprovalsDocuments[0] };
      newPreApproval.PreApprovalDocuments = [{ ContentType, Data, Filename, Size, ...files[0] }];
      preApprovals.push(newPreApproval);
      setFieldValue('preApprovalsDocuments', [...preApprovals]);
    });
  };
  const handleUploadIconClick = () => {
    if (fileInputRef?.current) {
      fileInputRef.current?.click();
    }
  };

  return (
    <>
      <div className={styles.title}>{getPreApprovalHeading()}</div>
      <div className={styles.isPreApprovedForm}>
        <div className={styles.formContainer}>
          <>
            <FieldArray name={'preApprovalsDocuments'}>
              {({ remove }) =>
                values.preApprovalsDocuments.map((data, i) => {
                  return values.isManualDataInput || currentStep === 1 ? (
                    <div className={currentStep === 1 ? styles.manualEntryWrapper : ''}>
                      {data.PreApprovalDocuments.map((file, index) => {
                        return (
                          <Attachment
                            key={file?.name + index || index}
                            onRemove={() => handleRemoveAttachment(i, remove)}
                            className={styles.attachments}
                            {...file}
                          />
                        );
                      })}
                      <AddManualEntrySection
                        isSubmitted={isSubmitted}
                        index={i}
                        isDocumentArray={true}
                      />
                    </div>
                  ) : (
                    <AddDocumentsSection isSubmitted={isSubmitted} index={i} />
                  );
                })
              }
            </FieldArray>
            {currentStep == 1 ? (
              <div className={styles.addAnotherWrapper} onClick={handleUploadIconClick}>
                <Button className={styles.iconButton} icon={<UploadIcon className="" />} />
                <p>Add Another</p>
              </div>
            ) : null}
          </>

          {!currentStep && (
            <Toggle
              name="isManualDataInput"
              checked={values.isManualDataInput}
              onChange={handleChange}
              label="Enter data manually"
              labelClassName={styles.toggle}
              labelTextClassName={styles.toggleText}
              disabled={!!editPreApprovalId}
            />
          )}
        </div>
        <Footer
          buttonType="submit"
          isPending={isLoading}
          onClick={(e) => {
            setIsSubmitted(true);
          }}
          isValid={true}
          buttonTitle={values.isManualDataInput ? 'Submit' : 'Continue'}
          data-testid="invite_buyer_footer"
        />
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleNewFileUpload}
          className={styles.hiddenInputField}
        />
      </div>
    </>
  );
};
