import React from 'react';
import { useSignupRole } from 'hooks';

import { FormWrapper } from 'components';
import { LeftSide, RightSide, Container } from 'components/LoginGroup';
import Form from './Form';

import styles from './styles.module.scss';

const SignUp = () => {
  useSignupRole();

  return (
    <Container>
      <LeftSide variant={LeftSide.LIGHT} />
      <FormWrapper className={styles.signupWrapper} alignCenter={false}>
        <Form />
      </FormWrapper>
      <RightSide />
    </Container>
  );
};

export default SignUp;
