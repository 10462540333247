import React, { useEffect, useState } from 'react';
import {
  delay,
  extractJsonFromText,
  formatJsonToText,
  getIntentFromLLM,
  handleLoadErrorTemplate,
  loadLlmTemplate,
  loadTransactionTemplateWithRepresentation,
  sendMessageToLLM,
} from '../helpers';
import { handler } from '../Integrations';
import { CREATE_TRANSACTION_TEMPLATES, ERROR_TEMPLATES } from 'settings/constants/mozzie';
import { useDispatch } from 'react-redux';
import { setisCheckMessageEffect, setNegativeCheckResponseEffect } from 'store/effects/mozzie';

const Action = ({
  createChatBotMessage,
  setState,
  children,
  state,
  isFirstMessage,
  setIsFirstMessage,
  socket,
  intent,
  intentPayload,
  setIntent,
  setIntentPayload,
  sessionId,
}) => {
  const dispatch = useDispatch();
  const onIsCheckMessage = (payload) => {
    dispatch(setisCheckMessageEffect(payload));
  };
  const onNegativeCheckResponse = (payload) => {
    dispatch(setNegativeCheckResponseEffect(payload));
  };
  let lastMessageStarted = false;
  let jsonResp = {};
  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        let data = event.data;
        const botMessage = createChatBotMessage(data);
        let isUpdateCheckMessage = false;
        setState((prev) => {
          const existingMessages = [...prev.messages];
          const lastMessageIndex = existingMessages.length - 1;
          const lastMessage = existingMessages[lastMessageIndex];
          if (data.includes('<END>')) {
            lastMessageStarted = true;
            data = data.replace('<END>', '');
          }
          if (data.includes('<|eot_id|>')) {
            let content = data.replace('<|eot_id|>', '');
            if (lastMessageStarted) {
              if (lastMessage['jsonMessage']) {
                existingMessages[lastMessageIndex] = {
                  ...lastMessage,
                  loading: false,
                  jsonMessage: `${lastMessage.jsonMessage} ${content}`,
                };
              } else {
                existingMessages[lastMessageIndex] = {
                  ...lastMessage,
                  loading: false,
                  jsonMessage: `${content}`,
                };
              }
              const jsonPayload = extractJsonFromText(
                existingMessages[lastMessageIndex].jsonMessage,
              );
              jsonResp = jsonPayload[0];
              const checkMessage = `${formatJsonToText(
                jsonPayload?.[0],
              )} <span>Type (yes/y) if everything is corrent else (no/n)</span>`;
              existingMessages[lastMessageIndex] = {
                ...lastMessage,
                loading: false,
                message: <span dangerouslySetInnerHTML={{ __html: checkMessage }} />,
              };
              isUpdateCheckMessage = true;
            } else {
              existingMessages[lastMessageIndex] = {
                ...lastMessage,
                loading: false,
                message: `${lastMessage.message} ${content}`,
              };
            }
          } else {
            if (lastMessageStarted) {
              if (Object.prototype.hasOwnProperty.call(lastMessage, 'jsonMessage')) {
                existingMessages[lastMessageIndex] = {
                  ...lastMessage,
                  loading: false,
                  jsonMessage: `${lastMessage.jsonMessage} ${data}`,
                };
              } else {
                existingMessages[lastMessageIndex] = {
                  ...lastMessage,
                  loading: false,
                  jsonMessage: `${data}`,
                };
              }
            } else {
              existingMessages[lastMessageIndex] = {
                ...lastMessage,
                loading: false,
                message: `${lastMessage.message} ${data}`,
              };
            }
          }
          return {
            ...prev,
            isFirstMessage: false,
            messages: [...existingMessages],
          };
        });
        if (isUpdateCheckMessage) {
          onIsCheckMessage(true);
          setIntentPayload({ ...intentPayload, ...jsonResp });
        }
      };
    }
  }, [socket, setState, createChatBotMessage]);

  const handleInputMessage = async (message) => {
    // Helper function to handle subsequent messages
    const handleSubsequentMessages = async (message) => {
      const botMessage = createChatBotMessage('');
      setState((prev) => ({
        ...prev,
        isFirstMessage: false,
        messages: [...prev.messages, botMessage],
      }));
      await delay(500);
      const messageResp = await sendMessageToLLM(sessionId, message);
    };

    if (isFirstMessage) {
      // Process the first message
      const tempIntent = await getIntentFromLLM(sessionId, message);
      if (!tempIntent) {
        const botMessage = createChatBotMessage(
          'Sorry! I could not understand can you please try again.',
        );
        setState((prev) => ({
          ...prev,
          isFirstMessage: false,
          messages: [...prev.messages, botMessage],
        }));
        return;
      }
      let intent = tempIntent;
      if (Object.prototype.hasOwnProperty.call(CREATE_TRANSACTION_TEMPLATES, tempIntent)) {
        intent = CREATE_TRANSACTION_TEMPLATES[`${tempIntent}`];
      }
      // Load a template based on the intent
      const template = await loadLlmTemplate(sessionId, intent);
      if (!template) {
        const botMessage = createChatBotMessage(
          'Sorry! I could not understand can you please try again.',
        );
        setState((prev) => ({
          ...prev,
          isFirstMessage: false,
          messages: [...prev.messages, botMessage],
        }));
        return;
      }
      setIsFirstMessage(false);
      setIntent(intent);
      const botMessage = createChatBotMessage(template);
      setState((prev) => ({
        ...prev,
        isFirstMessage: false,
        messages: [...prev.messages, botMessage],
      }));
    } else {
      handleSubsequentMessages(message);
    }
  };

  const handlePositiveCheck = async (message) => {
    if (Object.prototype.hasOwnProperty.call(CREATE_TRANSACTION_TEMPLATES, intent)) {
      let tempIntent = CREATE_TRANSACTION_TEMPLATES[`${intent}`];
      if (tempIntent === 'CREATE_TRANSACTION_2') {
        const rep = intentPayload?.representation;
        await loadTransactionTemplateWithRepresentation(sessionId, tempIntent, rep);
      } else {
        await loadLlmTemplate(sessionId, tempIntent);
      }
      const botMessage = createChatBotMessage('');
      setState((prev) => ({
        ...prev,
        isFirstMessage: false,
        messages: [...prev.messages, botMessage],
      }));
      await delay(500);
      return;
    }
    // Load a template based on the intent
    const botMessage = createChatBotMessage('The bot is performing the operation please wait');
    setState((prev) => ({
      ...prev,
      isFirstMessage: false,
      messages: [...prev.messages, botMessage],
    }));
    await handler(intent, intentPayload);
  };

  const handleNegativeCheck = async () => {
    onIsCheckMessage(false);
    onNegativeCheckResponse(true);
    const botMessage = createChatBotMessage('Please tell what you want to edit');
    setState((prev) => ({
      ...prev,
      isFirstMessage: false,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleNegativeCheckResponse = async (message) => {
    onNegativeCheckResponse(false);
    const botMessage = createChatBotMessage('');
    setState((prev) => ({
      ...prev,
      isFirstMessage: false,
      messages: [...prev.messages, botMessage],
    }));
    await delay(500);

    await handleLoadErrorTemplate(sessionId, ERROR_TEMPLATES.INCORRECT_INFORMATION_FIX, [message]);
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleInputMessage,
            handlePositiveCheck,
            handleNegativeCheck,
            handleNegativeCheckResponse,
          },
        });
      })}
    </div>
  );
};

export default Action;
