import { Fragment, useState } from 'react';
import styles from './styles.module.scss';
import { DownloadFilesModal } from 'pages/Workshop/Forms/components/FormDetailModal/components';

export const DownloadButton = (props) => {
  const [filesModalOpen, setFilesModalOpen] = useState(false);

  return (
    <Fragment>
      <DownloadFilesModal
        isOpen={filesModalOpen}
        onClose={() => setFilesModalOpen(false)}
        auditTrailDocuments={[]}
        {...props}
      />

      <button testid="download" onClick={() => setFilesModalOpen(true)} className={styles.download}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 6.66797V25.3346"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 17.332L16 25.332"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 17.332L16 25.332"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </Fragment>
  );
};
