import { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getTransactionEffect, updateTransactionTaskEffect } from 'store/effects/transactions';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { useOutsideClick } from 'hooks';
import {
  mapTasksStatuses,
  tasksStatusesIds,
  tasksStatusesOptions,
} from 'settings/constants/transactionTasks';
import { getTransactionTaskSelector } from 'store/selectors/transactionTask';
import { getTransactionSelector } from 'store/selectors/transaction';
import { FORM_CATEGORY } from 'settings/constants/transaction';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';
import { updateTaskStatusForCancelledTransactionFr } from 'api/transactions';
import { getTaskAggregateEffect } from 'store/effects/taskAggregate';
import { getTaskFilters, getTransactionTaskAccess } from 'store/selectors/transactionTasks';
import { showErrorMessage, subtractTimeZone, isBeforeToday } from 'helpers';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { getClientDashboardNeedsAttionEffect } from 'store/effects/clientDashboard';
import { getUserRolesMapSelector } from 'store/selectors/user';

const Status = (props) => {
  const {
    className,
    taskId,
    transactionId,
    status,
    category,
    fullAccess,
    transactionActionId,
    dueDate,
    hideDropdown,
    ccList,
  } = props;
  const dispatch = useDispatch();
  const { task, taskStatusOption } = useSelector(getTransactionTaskSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const { hasAccess } = useSelector(getTransactionTaskAccess);
  const filters = useSelector(getTaskFilters);
  const { isClient } = useSelector(getUserRolesMapSelector);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState('below');
  const buttonRef = useRef();
  const optionsRef = useRef();
  const { pathname } = useLocation();
  const isProject = useIsProjectRoute();

  const { CcList } = task || {};
  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  useEffect(() => {
    const parentRect = buttonRef?.current?.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    const spaceBelow = windowHeight - parentRect.bottom;

    if (spaceBelow <= optionsRef?.current?.offsetHeight) {
      setDropdownPosition('above');
    } else {
      setDropdownPosition('below');
    }
  }, [open]);

  const getStatusClassName = useCallback((val) => {
    switch (val) {
      case tasksStatusesIds.new:
        return styles.new;
      case tasksStatusesIds.inProgress:
        return styles.inProgress;
      case tasksStatusesIds.stuck:
        return styles.stuck;
      case tasksStatusesIds.done:
        return styles.done;
      case tasksStatusesIds.overdue:
        return styles.overdue;
      case tasksStatusesIds.na:
        return styles.na;
      default:
        return '';
    }
  }, []);

  const onClickOptions = (e) => {
    if (hideDropdown) return;

    e.stopPropagation();
    if (!hasAccess(task?.Id || taskId) && !fullAccess) {
      showErrorMessage("You don't have access to update this task");
      return;
    }
    category !== FORM_CATEGORY && (hasAccess(task?.Id || taskId) || fullAccess) && setOpen(!open);
  };

  const onSubmitConfirm = (e, option) => {
    e.stopPropagation();

    if (!hasAccess(task?.Id || taskId) && !fullAccess) {
      showErrorMessage("You don't have access to change the status of this task.");
      return;
    }

    setSelectedValue(option?.value);
    setOpen(false);
    if (transactionActionId) {
      updateTaskStatusForCancelledTransactionFr({
        transactionActionId: transactionActionId,
        Status: option?.value,
      });
    } else {
      dispatch(
        updateTransactionTaskEffect(
          { Id: taskId || task?.Id, transactionId: transactionId, Status: option?.value },
          {},
          () => {
            /// Refetch list silently in case of rules task addition
            const id = transactionId || transaction?.Id;
            if (pathname === routes.clientDashboard || (pathname === routes.index && isClient)) {
              dispatch(
                getClientDashboardNeedsAttionEffect(
                  {},
                  {
                    silent: true,
                  },
                ),
              );
            } else if (pathname !== routes.tasks && id) {
              const filtersWithTransactionRoomId = { ...filters, transactionRoomId: id };
              dispatch(getTransactionEffect({ id }, { silent: true }));
              dispatch(
                getTaskAggregateEffect(
                  {
                    filters: filtersWithTransactionRoomId,
                  },
                  { silent: true },
                ),
              );
            } else if (pathname === routes.tasks) {
              dispatch(getTaskAggregateEffect({ filters }, { silent: true }));
            }
            setSelectedValue(null);
          },
        ),
      );
    }
  };

  const getTaskStatusOptions = () => {
    if (isBeforeToday(subtractTimeZone(dueDate, 'M/DD/YYYY')))
      return [
        { label: mapTasksStatuses[tasksStatusesIds.done], value: tasksStatusesIds.done },
        { label: mapTasksStatuses[tasksStatusesIds.na], value: tasksStatusesIds.na },
      ];

    return tasksStatusesOptions.filter((option) => option?.value !== tasksStatusesIds.overdue);
  };

  return (
    <div className={classNames(styles.status, className)}>
      <div
        ref={buttonRef}
        onClick={onClickOptions}
        className={classNames(
          styles.value,
          getStatusClassName(selectedValue || status || taskStatusOption?.value),
          {
            [styles.statusOnly]: taskId || task?.Id || transactionActionId,
            [styles.hideDropdownOptions]: hideDropdown,
          },
        )}
        testid="task_status"
      >
        <span className={styles.statusText}>
          {selectedValue || status
            ? mapTasksStatuses[selectedValue || status]
            : taskStatusOption?.label}
        </span>
        {!hideDropdown &&
        ((hasAccess(task?.Id || taskId) && !fullAccess) ||
          !((CcList || ccList) && (CcList?.length || ccList?.length))) ? (
          <Icon className={styles.icon} stroke="#fff" variant={Icon.ARROW_BOTTOM} />
        ) : (
          <></>
        )}
      </div>
      {open && (
        <div
          ref={optionsRef}
          className={classNames(styles.options, {
            [styles.optionsScroll]: dropdownPosition === 'above',
          })}
        >
          <ul testid="statuses_list">
            {getTaskStatusOptions().map((option) => (
              <li
                key={option.value}
                className={styles.item}
                onClick={(e) => onSubmitConfirm(e, option)}
                testid={option.value}
              >
                <span
                  className={classNames(styles.optionStatus, getStatusClassName(option?.value))}
                />
                <span>{option?.label}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

Status.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  taskId: PropTypes.number,
  transactionActionId: PropTypes.number,
  transactionId: PropTypes.number,
  category: PropTypes.string,
  fullAccess: PropTypes.bool,
  hideDropdown: PropTypes.bool,
};

Status.defaultProps = {
  className: '',
  status: '',
  taskId: null,
  transactionId: null,
  fullAccess: false,
  hideDropdown: false,
};

export default Status;
