import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useOutsideClick } from 'hooks';
import { ArrowRight } from 'components/Icons';

import styles from './styles.module.scss';
import { MlsAccessStatusEnum } from '../../constants';

const GroupBy = (props) => {
  const { className, mlsGroupBy, setMlsGroupBy } = props;
  const optionsRef = useRef();

  const [open, setOpen] = useState(false);

  useOutsideClick([optionsRef], () => setOpen(false));

  const options = [
    {
      value: '',
      label: 'All',
    },
    {
      value: MlsAccessStatusEnum.REQUESTED,
      label: 'Requested',
    },
    {
      value: MlsAccessStatusEnum.APPROVED,
      label: 'Approved',
    },
    {
      value: MlsAccessStatusEnum.REJECTED,
      label: 'Rejected',
    },
    {
      value: MlsAccessStatusEnum.INACTIVE,
      label: 'Inactive',
    },
  ];

  const getGroupByLabel = () => {
    const optionObj = options.find((option) => option.value === mlsGroupBy);
    return optionObj?.label || options?.[0]?.label;
  };

  const onClickHandler = () => {
    setOpen(!open);
  };

  const onGroupBy = (val) => {
    setMlsGroupBy(val);
  };

  return (
    <div
      testid="group_by"
      ref={optionsRef}
      onClick={onClickHandler}
      className={classNames(styles.groupBy, className)}
    >
      <div testid="group_by_button" className={styles.value}>
        <span>{getGroupByLabel()}</span>
        <ArrowRight className={classNames(styles.arrow)} />
      </div>
      {open && (
        <div className={styles.options}>
          <ul>
            {options.map((option) => (
              <li
                onClick={() => onGroupBy(option?.value)}
                key={option?.value}
                className={styles.option}
                testid={option?.value}
              >
                {option?.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

GroupBy.propTypes = {
  className: PropTypes.string,
  mlsGroupBy: PropTypes.string,
  setMlsGroupBy: PropTypes.func,
};

GroupBy.defaultProps = {
  className: '',
  mlsGroupBy: '',
  setMlsGroupBy: () => {},
};

export default GroupBy;
