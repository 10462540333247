import { Checkbox } from 'antd';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const ButtonCheckbox = (props) => {
  const { options, onChange, className, value } = props;
  return (
    <Checkbox.Group
      className={classNames(styles.buttonCheckbox, className)}
      onChange={onChange}
      options={options}
      value={value}
    />
  );
};
