import { ActionToInterrupt } from './constants';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { appSetShowInviteClientAction, appSetShowInviteTeamMemberAction } from 'store/actions/app';
import { routes } from 'settings/navigation/routes';
import { setFormLibraryModalVisibleAction } from 'store/actions/formProcess';
import { link } from 'settings/navigation/link';

export type InterruptedActionHandler = (
  history: ReturnType<typeof useHistory>,
  dispatch: ReturnType<typeof useDispatch>,
  payload?: any,
) => void;

export const interruptedActionHandlers: { [key in ActionToInterrupt]: InterruptedActionHandler } = {
  [ActionToInterrupt.INVITE_CLIENT]: (history, dispatch) => {
    history.push(routes.index);
    dispatch(appSetShowInviteClientAction(true));
  },
  [ActionToInterrupt.INVITE_TEAM_MEMBER]: (history, dispatch) => {
    history.push(routes.index);
    dispatch(appSetShowInviteTeamMemberAction(true));
  },
  [ActionToInterrupt.CREATE_TRANSACTION]: (history) => {
    history.push(routes.transactionCreate);
  },
  [ActionToInterrupt.CLOSE_TRANSACTION]: (
    history,
    dispatch,
    payload: { transactionId: number },
  ) => {
    history.push(link.toTransactionClose(String(payload.transactionId)));
  },
  [ActionToInterrupt.CREATE_SHOWING]: (history) => {
    history.push(routes.transactionBasicPlanCreate);
  },
  [ActionToInterrupt.CREATE_FORM]: (history, dispatch) => {
    history.push(routes.workshopForms);
    dispatch(setFormLibraryModalVisibleAction(true));
  },
  [ActionToInterrupt.REQUEST_QUOTE]: (history, dispatch, payload: { categoryId: string }) => {
    history.push(routes.services);
  },
  [ActionToInterrupt.MESSAGE_TO_PARTNER]: (history, dispatch, payload: { categoryId: string }) => {
    history.push(routes.services);
  },
};

export const undoInterruptedActionHandlers: {
  [key in ActionToInterrupt]: InterruptedActionHandler;
} = {
  [ActionToInterrupt.INVITE_CLIENT]: () => {},
  [ActionToInterrupt.INVITE_TEAM_MEMBER]: () => {},
  [ActionToInterrupt.CREATE_TRANSACTION]: (history) => {
    history.push(routes.transactions);
  },
  [ActionToInterrupt.CLOSE_TRANSACTION]: (
    history,
    dispatch,
    payload: { transactionId: number },
  ) => {
    history.push(link.toTransactionOverview(String(payload.transactionId)));
  },
  [ActionToInterrupt.CREATE_SHOWING]: (history) => {
    history.push(routes.showings);
  },
  [ActionToInterrupt.CREATE_FORM]: () => {},
  [ActionToInterrupt.REQUEST_QUOTE]: () => {},
  [ActionToInterrupt.MESSAGE_TO_PARTNER]: () => {},
};
