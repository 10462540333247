import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AGENT } from 'settings/constants/roles';
import { routes } from 'settings/navigation/routes';
import {
  createBundleFormTemplateEffect,
  updatePreFormResponseEffect,
} from 'store/effects/formProcess';
import { getUniqueForms } from '../helper';
import { updateBundleTemplateEffect, updateFormTemplateEffect } from 'store/effects/formBuilder';

export const useHandleLibraryActions = (props) => {
  const {
    forms,
    rowKeyRecords,
    rowKeys,
    onSelectForm,
    transactionId,
    propertyInfo,
    isProject,
    setLoading,
    setSelectedForm,
    getPublishedForms,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const handleFormRequest = (formId) => {
    const form = forms.find(
      ({ versionId, bundleId }) => versionId === formId || bundleId === formId,
    );

    if (form) {
      const isTemplate = form.type === AGENT;
      const isBundleForm = !!form.bundleId;

      const dataToSend = {
        type: form.type,
        ...(!isBundleForm && {
          formId: form.formId,
          versionId: form.versionId,
          templateId: form.templateId,
        }),

        ...(isBundleForm && {
          bundleId: form.bundleId,
          formBundle: true,
          bundleTemplateName: form.name,
        }),

        ...(onSelectForm
          ? {
              name: form.name,
              dueDate: 7,
              FormId: form.formId,
            }
          : {}),

        ...(transactionId && { transactionId }),
        ...(propertyInfo && {
          propertyAddress: propertyInfo.address,
        }),
        isTemplate,
        isLibraryTemplate: true,
        name: form.name,
      };

      if (onSelectForm) {
        onSelectForm(dataToSend);
      } else if (setSelectedForm) {
        setSelectedForm(dataToSend);
      } else {
        history.push({ pathname: routes.workshopFormProcessRequest, state: { ...dataToSend } });
      }

      if (transactionId) {
        dispatch(
          updatePreFormResponseEffect({
            transactionId,
            isProject,
            formName: form.name,
          }),
        );
      }
    }
  };

  const createBundleForm = (bundleName: string) => {
    return new Promise((res) => {
      const libraryForms =
        rowKeyRecords?.length > 0 ? getUniqueForms([...rowKeyRecords, ...forms]) : forms;

      const details = libraryForms.filter(({ versionId, bundleId }) =>
        bundleId ? rowKeys.includes(bundleId) : rowKeys.includes(versionId),
      );

      let templateIds: number[] = [];

      details.forEach(({ templateId, templateIds: formTemplateIds }) => {
        const Ids = [
          ...(templateId ? [+templateId] : []),
          ...(formTemplateIds ? formTemplateIds : []),
        ];

        templateIds = [...templateIds, ...Ids];
      });

      const data = {
        templateIds,
        bundleName,
      };

      dispatch(
        createBundleFormTemplateEffect(data, (err, resp) => {
          setLoading(false);

          if (!err) {
            res({
              bundleId: resp.data.value.response.Id,
              publishToTeam: resp.data.value.response.publishToTeam,
            });
          }

          res(null);
        }),
      );
    });
  };

  const updateFormName = async (record) => {
    const { name, bundleId, templateId, publishToTeam } = record;
    try {
      setLoading(true);
      if (bundleId) {
        const requestPayload = {
          name,
          publishToTeam,
          templateBundleId: bundleId,
        };

        await dispatch(updateBundleTemplateEffect(requestPayload));
        setLoading(false);
        getPublishedForms();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return { handleFormRequest, createBundleForm, updateFormName };
};
