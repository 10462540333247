import { createSelector } from 'reselect';
import {
  get,
  values,
  filter,
  reduce,
  forEach,
  find,
  trim,
  flow,
  orderBy,
  groupBy,
  includes,
} from 'lodash-es';
import moment from 'moment';

import {
  getShortedChatParticipantsNames,
  convertNameToAvatarPlaceholder,
} from 'helpers/formatters';
import { getTransactionSelector } from 'store/selectors/transaction';

import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { CLIENT, THIRD_PARTY } from 'settings/constants/roles';

import { getClientThreadAgent, getUserId } from 'store/selectors/user';
import { getMessagesDrawerParams } from 'store/selectors/drawers/messages';
import { getThreadContactsDrawerParams } from 'store/selectors/drawers/threadContacts';
import { getQuotesDrawerParams } from 'store/selectors/drawers/quotes';
import { getMessageAttachmentsDrawerParams } from '../drawers/messageAttachments';

export const localState = ({ sockets }) => get(sockets, 'threads');

export const getThreadsListSelector = createSelector(localState, values);

export const getTransactionThreadsListSelector = createSelector(
  localState,
  getTransactionSelector,
  (threads, { transaction }) => {
    const threadsList = values(threads).filter(
      (thread) =>
        thread?.EntityId === `${transaction?.Id}` &&
        thread?.Type === SOCKET_THREAD_TYPES.TRANSACTION,
    );

    const extendedList = (threadsList || []).map((thread) => ({
      ...thread,
      Participants: orderBy(thread?.Participants || [], ['FirstName'], ['asc']),
    }));

    return orderBy(extendedList, (item) => moment(item?.LastMessageCreatedDate).valueOf(), 'desc');
  },
);

export const getUnarchivedThreadsList = createSelector(getThreadsListSelector, (threads) =>
  filter(threads, ({ IsArchived }) => !IsArchived),
);

export const getArchivedThreadsList = createSelector(getThreadsListSelector, (threads) =>
  filter(threads, ({ IsArchived }) => !!IsArchived),
);

export const getUnreadThreads = createSelector(getThreadsListSelector, (threads) =>
  filter(
    threads,
    ({ IsUnread, Type }) =>
      !!IsUnread &&
      ![
        SOCKET_THREAD_TYPES.TRANSACTION_TASK,
        SOCKET_THREAD_TYPES.OFFER,
        SOCKET_THREAD_TYPES.TRANSACTION,
      ].includes(Type),
  ),
);

export const getUnreadThreadsCount = createSelector(
  getUnreadThreads,
  (unreadThreads) => unreadThreads.length,
);

export const getSortedUnreadThreads = createSelector(getUnreadThreads, (unreadThreads) =>
  orderBy(unreadThreads, ['LastMessageCreatedDate'], ['desc']),
);

export const getSortedArchivedThreads = createSelector(getArchivedThreadsList, (unreadThreads) =>
  orderBy(unreadThreads, ['IsUnread', 'LastMessageCreatedDate'], ['desc', 'desc']),
);

export const getListingsAndOffersThreads = createSelector(getUnarchivedThreadsList, (threads) =>
  filter(
    threads,
    ({ Type }) => Type === SOCKET_THREAD_TYPES.LISTING || Type === SOCKET_THREAD_TYPES.OFFER,
  ),
);

export const getUnArchivedChatThreads = createSelector(getUnarchivedThreadsList, (threads) =>
  filter(threads, ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT),
);

export const getArchivedChatThreads = createSelector(getArchivedThreadsList, (threads) =>
  filter(threads, ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT),
);

export const getGroupedListingAndOffersThreadsMap = createSelector(
  getListingsAndOffersThreads,
  (threads) =>
    reduce(
      threads,
      (acc, thread) => {
        if (acc[thread?.EntityId]) {
          acc[thread?.EntityId].threads.push(thread);

          if (thread?.IsUnread) {
            acc[thread?.EntityId].unreadNumber++;
            acc[thread?.EntityId].unread = true;
            acc[thread?.EntityId].multiple = true;
          }

          if (thread?.TopicName) {
            acc[thread?.EntityId].name = thread?.TopicName;
          }

          if (moment(thread.LastMessageCreatedDate).isAfter(acc[thread?.EntityId].lastUnreadDate)) {
            acc[thread?.EntityId].lastUnreadDate = thread.LastMessageCreatedDate;
          }
        } else {
          acc[thread.EntityId] = {
            id: thread.EntityId,
            name: thread.TopicName,
            threads: [thread],
            withOffer: false,
            lastUnreadDate: thread.LastMessageCreatedDate,
            unreadNumber: thread?.IsUnread ? 1 : 0,
            unread: !!thread?.IsUnread,
            multiple: false,
          };
        }

        if (thread.Type === SOCKET_THREAD_TYPES.OFFER) {
          acc[thread.EntityId].withOffer = true;
        }

        return acc;
      },
      {},
    ),
);

export const getListingAndOffersGroupedAndSortedThreadsList = createSelector(
  getGroupedListingAndOffersThreadsMap,
  flow(values, (threads) =>
    orderBy(
      threads,
      ['withOffer', 'multiple', 'unread', 'lastUnreadDate'],
      ['asc', 'desc', 'desc', 'desc'],
    ),
  ),
);

export const getListingsGroupedThreadsForOpenedListing = createSelector(
  getGroupedListingAndOffersThreadsMap,
  getMessagesDrawerParams,
  flow(
    (listingsMap, { listingId }) => get(listingsMap, listingId),
    (groupedThreads) => ({
      ...groupedThreads,
      threads: orderBy(
        groupedThreads.threads,
        ['IsUnread', 'LastMessageCreatedDate'],
        ['desc', 'desc'],
      ),
    }),
  ),
);

const formatGroupedThreadsMap = (threads, userId) => {
  let groupedByThreads = groupBy(threads, (thread) => {
    const isGroupChat = thread?.Participants?.length > 2;
    const isThreadWithThirdParty = thread?.Participants?.find(
      (p) => p?.Id !== userId && p?.ThirdParty,
    );

    if (!isGroupChat && isThreadWithThirdParty && isThreadWithThirdParty?.ThirdParty?.length) {
      return isThreadWithThirdParty?.ThirdParty?.CategoryTitles[0];
    }

    return 'NormalChat';
  });

  let gThreads = [];
  for (const property in groupedByThreads) {
    if (property === 'NormalChat') {
      gThreads = [...gThreads, ...groupedByThreads[property]];
      continue;
    }
    gThreads = [...gThreads, groupedByThreads[property]];
  }

  const orderedThreads = orderBy(
    gThreads.map((thread) => {
      const threadItem = {};

      if (Array.isArray(thread)) {
        const thirdParty = thread[0]?.Participants?.find((p) => p?.Id !== userId && p?.ThirdParty);
        threadItem.IsThirdPartyThread = true;
        threadItem.ServiceCategory = thirdParty?.ThirdParty?.CategoryTitles[0];
        threadItem.unread = thread.some((t) => t.IsUnread);
        threadItem.lastUnreadDate = moment(
          orderBy(thread, ['LastMessageCreatedDate'], ['desc'])[0].LastMessageCreatedDate,
        ).valueOf();
        threadItem.multiple = true;
        threadItem.topicName = '';
        threadItem.unreadNumber = thread.filter((t) => t.IsUnread).length;
        threadItem.thread = thread.map((t) => ({
          thread: {
            ...t,
            Participants: (t?.Participants || []).filter((p) => p?.Id !== userId),
          },
          multiple: false,
          lastUnreadDate: moment(t.LastMessageCreatedDate).valueOf(),
          topicName: t.TopicName,
          unread: t.IsUnread,
          unreadNumber: t.IsUnread ? 1 : 0,
        }));
      } else {
        threadItem.unread = thread.IsUnread;
        threadItem.lastUnreadDate = moment(thread.LastMessageCreatedDate).valueOf();
        threadItem.multiple = false;
        threadItem.topicName = thread.topicName;
        threadItem.unreadNumber = thread.IsUnread ? 1 : 0;
        threadItem.thread = {
          ...thread,
          Participants: (thread?.Participants || []).filter(
            (participant) => participant?.Id !== userId,
          ),
        };
      }

      return threadItem;
    }),
    ['IsUnread', 'LastMessageCreatedDate'],
    ['desc', 'desc'],
  );

  return orderedThreads;
};

export const getUnArchivedChatThreadsListRecipientWise = createSelector(
  getUnArchivedChatThreads,
  getUserId,
  formatGroupedThreadsMap,
);

export const getArchivedChatThreadsListRecipientWise = createSelector(
  getArchivedChatThreads,
  getUserId,
  formatGroupedThreadsMap,
);

const formatClientsGroupedThreadsMap = (roles) => (threads, userId) => {
  const formatted = reduce(
    threads,
    (acc, thread) => {
      forEach(thread?.Participants, (participant) => {
        if (participant?.Id !== userId) {
          if (acc[participant?.Id]) {
            acc[participant?.Id].threads.push(thread);
            acc[participant?.Id].multiple = true;

            if (thread?.IsUnread) {
              acc[participant?.Id].unreadNumber++;
              acc[participant?.Id].unread = true;
            }

            if (
              moment(thread.LastMessageCreatedDate).isAfter(acc[participant?.Id].lastUnreadDate)
            ) {
              acc[participant?.Id].lastUnreadDate = thread.LastMessageCreatedDate;
            }
          } else if (!roles || roles.includes(participant?.Roles?.[0])) {
            acc[participant?.Id] = {
              userId,
              user: participant,
              threads: [thread],
              unreadNumber: thread?.IsUnread ? 1 : 0,
              lastUnreadDate: thread.LastMessageCreatedDate,
              personal: [],
              group: [],
              listingOffers: [],
              unread: !!thread?.IsUnread,
              multiple: false,
              quotes: [],
              unreadQuotes: 0,
              unreadPersonal: 0,
              unreadGroup: 0,
              unreadListingOffers: 0,
              notQuotes: [],
              unreadNotQuotes: 0,
            };
          }

          if (acc[participant?.Id]) {
            if (thread.Type === SOCKET_THREAD_TYPES.CHAT && thread.Participants?.length === 2) {
              acc[participant?.Id].personal.push(thread);
              if (thread?.IsUnread) {
                acc[participant?.Id].unreadPersonal++;
              }
            } else if (thread.Type === SOCKET_THREAD_TYPES.CHAT) {
              acc[participant?.Id].group.push(thread);
              if (thread?.IsUnread) {
                acc[participant?.Id].unreadGroup++;
              }
            }

            if (thread.Type === SOCKET_THREAD_TYPES.QUOTE) {
              acc[participant?.Id].quotes.push(thread);
              if (thread?.IsUnread) {
                acc[participant?.Id].unreadQuotes++;
              }
            } else {
              acc[participant?.Id].notQuotes.push(thread);
              if (thread?.IsUnread) {
                acc[participant?.Id].unreadNotQuotes++;
              }
            }

            if (
              thread.Type === SOCKET_THREAD_TYPES.LISTING ||
              thread.Type === SOCKET_THREAD_TYPES.OFFER
            ) {
              acc[participant?.Id].listingOffers.push(thread);
              if (thread?.IsUnread) {
                acc[participant?.Id].unreadListingOffers++;
              }
            }
          }
        }
      });

      return acc;
    },
    {},
  );
  return formatted;
};

const formatTransactionsGroupedThreadsMap = (threads, userId) => {
  const groupedByTransaction = groupBy(threads, 'TopicName');
  return Object.values(groupedByTransaction).map((transactionThreads) => {
    const orderedThreads = orderBy(
      transactionThreads,
      (thread) => moment(thread?.LastMessageCreatedDate).valueOf(),
      'desc',
    );

    return {
      threads: orderBy(
        transactionThreads.map((thread) => ({
          ...thread,
          Participants: (thread?.Participants || []).filter(
            (participant) => participant?.Id !== userId,
          ),
        })),
        ['IsUnread', 'LastMessageCreatedDate'],
        ['desc', 'desc'],
      ),
      multiple: true,
      lastUnreadDate: moment(orderedThreads?.[0]?.LastMessageCreatedDate).valueOf(),
      topicName: transactionThreads?.[0]?.TopicName,
      transactionId: transactionThreads?.[0]?.EntityId,
      unread: transactionThreads.some((thread) => thread?.IsUnread),
      unreadNumber: transactionThreads.reduce((acc, thread) => {
        if (thread?.IsUnread) {
          return acc + 1;
        }
        return acc;
      }, 0),
    };
  });
};

const formatClientsGroupedThreadsForOpenedClient = flow(
  (clientsMap, { clientId }) => get(clientsMap, clientId),
  (groupedThreads) => ({
    ...groupedThreads,
    personal: orderBy(
      groupedThreads?.personal,
      ['IsUnread', 'LastMessageCreatedDate'],
      ['desc', 'desc'],
    ),
    group: orderBy(groupedThreads?.group, ['IsUnread', 'LastMessageCreatedDate'], ['desc', 'desc']),
    listingOffers: orderBy(
      groupedThreads?.listingOffers,
      ['IsUnread', 'LastMessageCreatedDate'],
      ['desc', 'desc'],
    ),
  }),
);

const formatPartnersGroupedThreadsForOpenedPartner = flow(
  (partnersMap, { partnerId }) => {
    const val = get(partnersMap, partnerId);
    return val;
  },
  (groupedThreads) => ({
    ...groupedThreads,
    personal: orderBy(
      groupedThreads?.personal,
      ['IsUnread', 'LastMessageCreatedDate'],
      ['desc', 'desc'],
    ),
    group: orderBy(groupedThreads?.group, ['IsUnread', 'LastMessageCreatedDate'], ['desc', 'desc']),
    quotes: orderBy(
      groupedThreads?.quotes,
      ['IsUnread', 'LastMessageCreatedDate'],
      ['desc', 'desc'],
    ).filter(({ LastMessage }) => !!LastMessage),
  }),
);

const formatQuotesGroupedThreadsForOpenedUser = flow(
  (usersMap, { clientId }) => get(usersMap, clientId),
  (groupedThreads) => ({
    ...groupedThreads,
    quotes: orderBy(
      groupedThreads?.quotes,
      ['IsUnread', 'LastMessageCreatedDate'],
      ['desc', 'desc'],
    ).filter(({ LastMessage }) => !!LastMessage),
    personal: orderBy(
      groupedThreads?.personal,
      ['IsUnread', 'LastMessageCreatedDate'],
      ['desc', 'desc'],
    ),
    group: orderBy(groupedThreads?.group, ['IsUnread', 'LastMessageCreatedDate'], ['desc', 'desc']),
  }),
);

export const getClientsUnarchivedThreadsList = createSelector(
  getUnarchivedThreadsList,
  (threads) => {
    const filtered = filter(threads, ({ Type, Participants }) =>
      Participants.find(({ Roles }) => [CLIENT].includes(Roles?.[0])),
    );
    return filtered;
  },
);

export const getTransactionsUnarchivedThreadsList = createSelector(
  getUnarchivedThreadsList,
  (threads) => filter(threads, ({ Type }) => Type === SOCKET_THREAD_TYPES.TRANSACTION),
);

export const getTransactionsArchivedThreadsList = createSelector(
  getArchivedThreadsList,
  (threads) => filter(threads, ({ Type }) => Type === SOCKET_THREAD_TYPES.TRANSACTION),
);

export const getClientsArchivedThreadsList = createSelector(getArchivedThreadsList, (threads) => {
  const filtered = filter(
    threads,
    ({ Type, Participants }) =>
      (Type === SOCKET_THREAD_TYPES.CHAT ||
        Type === SOCKET_THREAD_TYPES.LISTING ||
        Type === SOCKET_THREAD_TYPES.OFFER) &&
      Participants.find(({ Roles }) => [CLIENT].includes(Roles?.[0])),
  );
  return filtered;
});

export const getClientsGroupedUnarchivedThreadsMap = createSelector(
  getClientsUnarchivedThreadsList,
  getUserId,
  formatClientsGroupedThreadsMap([CLIENT]),
);

export const getTransactionsGroupedUnarchivedThreadsMap = createSelector(
  getTransactionsUnarchivedThreadsList,
  getUserId,
  formatTransactionsGroupedThreadsMap,
);

export const getClientsGroupedAndSortedUnarchivedThreadsList = createSelector(
  getClientsGroupedUnarchivedThreadsMap,
  flow(values, (threads) =>
    orderBy(threads, ['multiple', 'unread', 'lastUnreadDate'], ['desc', 'desc', 'desc']),
  ),
);

export const getTransactionsGroupedAndSortedUnarchivedThreadsList = createSelector(
  getTransactionsGroupedUnarchivedThreadsMap,
  flow(values, (threads) =>
    orderBy(threads, ['multiple', 'unread', 'lastUnreadDate'], ['desc', 'desc', 'desc']),
  ),
);

export const getClientsGroupedArchivedThreadsMap = createSelector(
  getClientsArchivedThreadsList,
  getUserId,
  formatClientsGroupedThreadsMap([CLIENT]),
);

export const getTransactionsGroupedArchivedThreadsMap = createSelector(
  getTransactionsArchivedThreadsList,
  getUserId,
  formatTransactionsGroupedThreadsMap,
);

export const getClientsGroupedAndSortedArchivedThreadsList = createSelector(
  getClientsGroupedArchivedThreadsMap,
  flow(values, (threads) =>
    orderBy(threads, ['multiple', 'unread', 'lastUnreadDate'], ['desc', 'desc', 'desc']),
  ),
);

export const getTransactionsGroupedAndSortedArchivedThreadsList = createSelector(
  getTransactionsGroupedArchivedThreadsMap,
  flow(values, (threads) => orderBy(threads, ['multiple', 'unreadNumber'], ['desc', 'desc'])),
);

export const getClientsGroupedUnarchivedThreadsForOpenedClient = createSelector(
  getClientsGroupedUnarchivedThreadsMap,
  getMessagesDrawerParams,
  formatClientsGroupedThreadsForOpenedClient,
);

export const getClientsGroupedArchivedThreadsForOpenedClient = createSelector(
  getClientsGroupedArchivedThreadsMap,
  getMessagesDrawerParams,
  formatClientsGroupedThreadsForOpenedClient,
);

export const getTransactionsGroupedArchivedThreadsForOpenedClient = createSelector(
  getTransactionsGroupedArchivedThreadsMap,
  getMessagesDrawerParams,
  (transactionsMap) => transactionsMap,
);

export const getPersonalUnarchivedSortedThreads = createSelector(
  getUnArchivedChatThreads,
  (threads) => orderBy(threads, ['IsUnread', 'LastMessageCreatedDate'], ['desc', 'desc']),
);

export const getPersonalAgentUnarchivedSortedThreads = createSelector(
  getClientThreadAgent,
  getUnArchivedChatThreads,
  ({ Id: agentId }, threads) =>
    orderBy(
      threads.filter(({ Participants }) => !!Participants.find((p) => p?.Id === agentId)),
      ['IsUnread', 'LastMessageCreatedDate'],
      ['desc', 'desc'],
    ),
);

export const getOpenedChatThread = createSelector(
  localState,
  getMessagesDrawerParams,
  (threads, thread) => {
    return threads && thread && 'threadId' in thread ? get(threads, thread?.threadId) : null;
  },
);

export const getOpenedPropertyThread = createSelector(
  localState,
  getMessageAttachmentsDrawerParams,
  (threads, thread) => {
    return threads && thread && 'threadId' in thread ? get(threads, thread?.threadId) : null;
  },
);

export const getOpenedQuoteThread = createSelector(
  localState,
  getQuotesDrawerParams,
  (threads, thread) => {
    return threads && thread && 'threadId' in thread ? get(threads, thread?.threadId) : null;
  },
);

const prepareOpenedChatInfo = (thread, userId) => {
  const type = thread?.Type;
  let names = [];
  const avatars = [];
  const thirdParties = [];
  const thirdPartyCategory = [];
  forEach(
    thread?.Participants,
    ({ Id, FirstName, LastName, AvatarUrl, ThirdParty, LogoUrl }) => {
      if (Id !== userId) {
        const name = trim(`${FirstName} ${LastName}`);
        avatars.push({
          url: AvatarUrl,
          placeholder: convertNameToAvatarPlaceholder(name),
        });
        names.push(name);
        if (ThirdParty) {
          thirdParties.push(ThirdParty.BusinessName);
          thirdPartyCategory.push(ThirdParty.CategoryTitles?.[0]);
        }
      }
    },
    '',
  );

  let recipientRole = '';
  let recipientLastOnline = '';
  if (thread?.Participants?.length === 2) {
    let participant = thread?.Participants?.filter((p) => p.Id !== userId)[0];
    recipientRole = participant?.Roles[0];
    recipientLastOnline = participant?.LastOnlineTime;
  }

  let subTitle;
  if (thread?.Participants?.length === 2 && thirdParties.length) {
    subTitle = thirdPartyCategory[0];
  } else if (thread?.Participants?.length > 2) {
    subTitle = thread?.TopicName;
  }

  return {
    thread,
    names: getShortedChatParticipantsNames(names),
    isOffer: type === SOCKET_THREAD_TYPES.OFFER,
    subTitle,
    type,
    avatars,
    recipientRole,
    recipientLastOnline,
    isGroupChat: thread?.Participants?.length > 2,
    BusinessName: thirdParties[0],
  };
};

export const getOpenedChatThreadInfo = createSelector(
  getOpenedChatThread,
  getUserId,
  prepareOpenedChatInfo,
);

export const getOpenedPropertyThreadInfo = createSelector(
  getOpenedPropertyThread,
  getUserId,
  prepareOpenedChatInfo,
);

export const getOpenedQuoteThreadInfo = createSelector(
  getOpenedQuoteThread,
  getUserId,
  prepareOpenedChatInfo,
);

export const getThreadsSelector = createSelector(localState, (threads) => threads);

export const getCountThreadsByPropertyId = createSelector(
  getUnarchivedThreadsList,
  (_, { propertyId }) => propertyId,
  (threads, propertyId) => {
    const threadArray = Object.values(threads);
    const threadsWithPropertyComments = threadArray.filter((thread) => {
      return !!thread.PropertyComments?.length;
    });

    const threadWithPropertyComments = threadsWithPropertyComments.reduce((acc, thread) => {
      const propertyComments = thread.PropertyComments?.filter((comment) => {
        return Array.isArray(comment.MessageMeta)
          ? comment.MessageMeta.some((meta) => meta.EntityId === propertyId)
          : comment.MessageMeta.EntityId === propertyId;
      });

      if (propertyComments.length) {
        const orderedComments = orderBy(propertyComments, ['CreatedDate'], ['desc']);

        thread.LastMessage = orderedComments[0].Text;
        thread.LastMessageCreatedDate = orderedComments[0].CreatedDate;

        return [...acc, thread];
      }
      return [...acc];
    }, []);

    return { count: threadWithPropertyComments.length, threads: threadWithPropertyComments };
  },
);

export const getThreadById = (threadId) =>
  createSelector(localState, (threads) => get(threads, threadId));

export const getThreadContactsParticipantsListAndOwnerId = createSelector(
  localState,
  getThreadContactsDrawerParams,
  getUserId,
  (threadMap, { threadId } = {}, userId) => ({
    ownerId: get(threadMap, [threadId, 'CreatorUserId']),
    contacts: filter(get(threadMap, [threadId, 'Participants']) || [], ({ Id }) => Id !== userId),
    threadId,
  }),
);

export const getThreadContactsOpenedContactInfo = createSelector(
  localState,
  getThreadContactsDrawerParams,
  (threadMap, { threadId, contactId } = {}) =>
    find(get(threadMap, [threadId, 'Participants']) || [], ({ Id }) => Id === contactId),
);

export const getThreadsPerPropertyIdSelector = createSelector(
  getThreadsListSelector,
  (threadsList) => groupBy(threadsList, 'EntityId'),
);

export const getThreadsPerEntityId = createSelector(getThreadsListSelector, (threadsList) => {
  const groupedThreads = {};

  threadsList
    .filter(({ IsArchived }) => !IsArchived)
    .forEach((thread) => {
      // Check if PropertyComments exists and is not empty
      if (thread?.PropertyComments && thread?.PropertyComments.length > 0) {
        // Iterate over each property comment in the thread
        thread.PropertyComments.forEach((comment) => {
          // Check if MessageMeta exists and has EntityId
          if (comment?.MessageMeta) {
            const messageMeta = Array.isArray(comment.MessageMeta)
              ? comment.MessageMeta
              : [comment.MessageMeta];
            messageMeta.forEach((meta) => {
              const entityId = meta?.EntityId;

              // Initialize the group if it doesn't exist
              if (!groupedThreads[entityId]) {
                groupedThreads[entityId] = [];
              }

              // Add the current thread to the group
              groupedThreads[entityId].push(thread);
            });
          }
        });
      }
    });

  return groupedThreads;
});

// Quotes
export const getQuotesUnarchivedThreads = createSelector(getUnarchivedThreadsList, (threads) =>
  filter(
    threads,
    ({ Type }) => Type === SOCKET_THREAD_TYPES.QUOTE || Type === SOCKET_THREAD_TYPES.PERSONAL,
  ),
);

export const getQuotesArchivedThreads = createSelector(getArchivedThreadsList, (threads) =>
  filter(
    threads,
    ({ Type }) => Type === SOCKET_THREAD_TYPES.QUOTE || Type === SOCKET_THREAD_TYPES.PERSONAL,
  ),
);

export const getQuotesAllThreads = createSelector(getThreadsListSelector, (threads) =>
  filter(threads, ({ Type }) => Type === SOCKET_THREAD_TYPES.QUOTE),
);

export const getOpenedQuotesListThreads = createSelector(
  getQuotesUnarchivedThreads,
  getQuotesDrawerParams,
  (threads, { quotesIds, quotesIdsWithStatus }) => {
    const result = {};
    forEach(threads, (thread) => {
      if (includes(quotesIds, Number(thread?.EntityId))) {
        const { status } = find(
          quotesIdsWithStatus || [],
          ({ id }) => id === Number(thread?.EntityId),
        );
        if (!result[status]) {
          result[status] = [];
        }
        result[status].push(thread);
      }
    });

    return result;
  },
);

export const getQuotesGroupedUnarchivedThreadsMap = createSelector(
  getQuotesUnarchivedThreads,
  getUserId,
  formatClientsGroupedThreadsMap(),
);

export const getQuotesGroupedArchivedThreadsMap = createSelector(
  getQuotesArchivedThreads,
  getUserId,
  formatClientsGroupedThreadsMap(),
);

export const getQuotesGroupedAndSortedUnarchivedThreadsList = createSelector(
  getQuotesGroupedUnarchivedThreadsMap,
  flow(values, (threads) => {
    const orderedThreads = orderBy(
      threads,
      ['multiple', 'unread', 'lastUnreadDate'],
      ['desc', 'desc', 'desc'],
    );

    return (orderedThreads || []).map((item) => ({
      ...item,
      quotes: (item?.quotes || []).filter((quote) => !!quote?.LastMessage),
    }));
  }),
);

export const getQuotesEntryIdThreadIdMap = createSelector(getQuotesAllThreads, (threads) =>
  reduce(
    threads,
    (acc, { Id, EntityId }) => {
      if (EntityId) {
        acc[EntityId] = Id;
      }
      return acc;
    },
    {},
  ),
);

export const getPartnersUnarchivedThreads = createSelector(getUnarchivedThreadsList, (threads) => {
  const filtered = filter(
    threads,
    ({ Type, Participants }) =>
      (Type === SOCKET_THREAD_TYPES.PERSONAL || Type === SOCKET_THREAD_TYPES.QUOTE) &&
      Participants.find(({ Roles }) => Roles.includes(THIRD_PARTY)),
  );
  return filtered;
});

export const getPartnersArchivedThreads = createSelector(getArchivedThreadsList, (threads) => {
  const filtered = filter(
    threads,
    ({ Type, Participants }) =>
      (Type === SOCKET_THREAD_TYPES.PERSONAL || Type === SOCKET_THREAD_TYPES.QUOTE) &&
      Participants.find(({ Roles }) => Roles.includes(THIRD_PARTY)),
  );
  return filtered;
});

export const getPartnersGroupedArchivedThreadsMap = createSelector(
  getPartnersArchivedThreads,
  getUserId,
  formatClientsGroupedThreadsMap([THIRD_PARTY]),
);

export const getPartnersGroupedUnarchivedThreadsMap = createSelector(
  getPartnersUnarchivedThreads,
  getUserId,
  formatClientsGroupedThreadsMap([THIRD_PARTY]),
);

export const getPartnersGroupedAndSortedUnarchivedThreadsList = createSelector(
  getPartnersGroupedUnarchivedThreadsMap,
  flow(values, (threads) => {
    const orderedThreads = orderBy(
      threads,
      ['multiple', 'unread', 'lastUnreadDate'],
      ['desc', 'desc', 'desc'],
    );

    return (orderedThreads || []).map((item) => ({
      ...item,
      quotes: (item?.quotes || []).filter((quote) => !!quote?.LastMessage),
    }));
  }),
);

export const getPartnersGroupedAndSortedArchivedThreadsList = createSelector(
  getPartnersGroupedArchivedThreadsMap,
  flow(values, (threads) => {
    const orderedThreads = orderBy(
      threads,
      ['multiple', 'unread', 'lastUnreadDate'],
      ['desc', 'desc', 'desc'],
    );

    return (orderedThreads || []).map((item) => ({
      ...item,
      quotes: (item?.quotes || []).filter((quote) => !!quote?.LastMessage),
    }));
  }),
);

export const getPartnersGroupedUnarchivedThreadsForOpenedClient = createSelector(
  getPartnersGroupedUnarchivedThreadsMap,
  getMessagesDrawerParams,
  formatPartnersGroupedThreadsForOpenedPartner,
);

export const getPartnersGroupedArchivedThreadsForOpenedClient = createSelector(
  getPartnersGroupedArchivedThreadsMap,
  getMessagesDrawerParams,
  formatPartnersGroupedThreadsForOpenedPartner,
);

export const getQuotesGroupedUnarchivedThreadsForOpenedUser = createSelector(
  getQuotesGroupedUnarchivedThreadsMap,
  getMessagesDrawerParams,
  formatQuotesGroupedThreadsForOpenedUser,
);

export const getQuotesGroupedArchivedThreadsForOpenedUser = createSelector(
  getQuotesGroupedArchivedThreadsMap,
  getMessagesDrawerParams,
  formatQuotesGroupedThreadsForOpenedUser,
);
