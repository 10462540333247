import React, { useCallback } from 'react';

import { AgentDocumentTypes } from 'types';

interface DocumentIconsProps extends React.SVGProps<SVGSVGElement> {
  type: AgentDocumentTypes;
}

export const DocumentIcons = ({ type, color = '#AAABAB', ...props }: DocumentIconsProps) => {
  const getIcon = useCallback(() => {
    switch (type) {
      case AgentDocumentTypes.Drafts:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M11.2773 3V6.77778C11.2773 7.02826 11.3768 7.26848 11.554 7.4456C11.7311 7.62272 11.9713 7.72222 12.2218 7.72222H15.9996"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.49957 20H4.66623C4.16527 20 3.68482 19.801 3.33059 19.4468C2.97635 19.0925 2.77734 18.6121 2.77734 18.1111V4.88889C2.77734 4.38792 2.97635 3.90748 3.33059 3.55324C3.68482 3.19901 4.16527 3 4.66623 3H11.2773L15.9996 7.72222V9"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.55469 8.66699L7.99915 8.66699"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.55469 12.4445H10.9991"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.55469 16.2227L7.99915 16.2227"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 22H13.1314L21.3515 13.78C21.7667 13.3647 22 12.8015 22 12.2143C22 11.627 21.7667 11.0638 21.3515 10.6485C20.9362 10.2333 20.373 10 19.7857 10C19.1985 10 18.6353 10.2333 18.22 10.6485L10 18.8686V22Z"
              fill="white"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.4365 11.4319L20.568 14.5633"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case AgentDocumentTypes.PendingForms:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M9.6 12C9.6 9.59998 6 9.59998 6 3.59998H18C18 9.59998 14.4 9.59998 14.4 12C14.4 14.4 18 14.4 18 20.4H6C6 14.4 9.6 14.4 9.6 12Z"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.7998 3.59998H19.1998"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.7998 20.4H19.1998"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9996 14.4C11.3996 14.4 9.59961 16.2 9.59961 18H14.3996C14.3996 16.2 12.5996 14.4 11.9996 14.4Z"
              fill={color}
            />
            <path
              d="M11.9998 9.59998C11.6998 9.59998 10.7998 8.99998 10.7998 8.39998H13.1998C13.1998 8.99998 12.2998 9.59998 11.9998 9.59998Z"
              fill={color}
            />
          </svg>
        );
      case AgentDocumentTypes.PreApprovals:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 9H10"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 13H15"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 17H15"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case AgentDocumentTypes.MessageAttachments:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M14.9997 7.00045L8.4997 13.5005C8.10188 13.8983 7.87838 14.4378 7.87838 15.0005C7.87838 15.5631 8.10188 16.1026 8.4997 16.5005C8.89753 16.8983 9.43709 17.1218 9.9997 17.1218C10.5623 17.1218 11.1019 16.8983 11.4997 16.5005L17.9997 10.0005C18.7954 9.2048 19.2423 8.12567 19.2423 7.00045C19.2423 5.87523 18.7954 4.7961 17.9997 4.00045C17.2041 3.2048 16.1249 2.75781 14.9997 2.75781C13.8745 2.75781 12.7954 3.2048 11.9997 4.00045L5.4997 10.5005C4.30623 11.6939 3.63574 13.3126 3.63574 15.0005C3.63574 16.6883 4.30623 18.307 5.4997 19.5005C6.69318 20.6939 8.31188 21.3644 9.9997 21.3644C11.6875 21.3644 13.3062 20.6939 14.4997 19.5005L20.9997 13.0005"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case AgentDocumentTypes.Transactions:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M3.75 9.75V21.75H20.25V9.75"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.25 10.5L12 3L16.4297 6.40743M21.75 10.5L19.9751 9.1347M16.4297 6.40743V3H19.9751V9.1347M16.4297 6.40743L19.9751 9.1347"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.25 12H15.75M15.75 12L13.875 10.125M15.75 12L13.875 13.875"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.75 16.5H8.25M8.25 16.5L10.125 14.625M8.25 16.5L10.125 18.375"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case AgentDocumentTypes.Ungrouped:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      default: {
        return <></>;
      }
    }
  }, [type, color]);

  return getIcon();
};
