import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Hint = (props) => {
  const { className, addedToFavoritesBy } = props;

  return (
    <ul className={classNames(styles.list, className)}>
      {(addedToFavoritesBy || []).map(({ Id, FirstName, LastName }) => (
        <li testid="favorite_item" key={Id}>
          <span>{`${FirstName} ${LastName}`}</span>
        </li>
      ))}
    </ul>
  );
};

Hint.propTypes = {
  className: PropTypes.string,
  addedToFavoritesBy: PropTypes.arrayOf(PropTypes.shape({})),
};

Hint.defaultProps = {
  className: '',
  addedToFavoritesBy: null,
};

export default Hint;
