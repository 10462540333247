import PropTypes from 'prop-types';

const Plus = ({ className, color }) => (
  <div className={className}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 3V13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 8H13" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  </div>
);

Plus.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

Plus.defaultProps = {
  className: '',
  color: '#163C5A',
};

export default Plus;
