import { createAction } from 'redux-actions';

export const requestGetTimelineTemplateAction = createAction('REQUEST/GET_TIMELINE_TEMPLATE');
export const requestPutTimelineTemplateAction = createAction('REQUEST/PUT_TIMELINE_TEMPLATE');
export const requestPostTimelineTemplateAction = createAction('REQUEST/POST_TIMELINE_TEMPLATE');
export const requestDeleteTimelineTemplateAction = createAction('REQUEST/DELETE_TIMELINE_TEMPLATE');
export const setTimelineTemplateDrawerOpenedAction = createAction(
  'SET_TIMELINE_TEMPLATE_DRAWER_OPENED',
);
export const resetTimelineTemplateDataAction = createAction('RESET_TIMELINE_TEMPLATE_DATA_OPENED');
