import { useState } from 'react';
import { FormFileUploader, TemplateCheckboxFields, TemplateFields } from '../components';
import { FormHeader } from '../components/FormHeader';
import styles from './style.module.scss';
import { handleFileUpload, initialFieldState, initialUploadErrorState } from '../../helper';
import Navigation from '../components/Footer';
import { UploadFile } from 'antd';
import { uploadDocumentEffect } from 'store/effects/quotes';
import { useDispatch } from 'react-redux';
import { createDynamicFormEffect } from 'store/effects/formProcess';
import { createNewFormEffect, createNewTemplateEffect } from 'store/effects/formBuilder';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';

export const NewTemplate = ({ stagesStep, setCurrentStageIndex }) => {
  const [templateFieldState, setTemplateState] = useState({
    ...initialFieldState,
    saveAsTemplate: true,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const [templateErrors, setTemplateErrors] = useState(initialUploadErrorState);
  const [files, setFiles] = useState<UploadFile<any>[]>([]);

  const [loading, setLoading] = useState(false);

  const handlePrevious = () => {
    setCurrentStageIndex(stagesStep.FormCategories);
  };

  const onChangeTemplateField = (data) => {
    setTemplateState((prev) => ({ ...prev, ...data }));
  };

  const isValid = () => {
    let valid = true;
    const errors = { ...templateErrors };
    const { templateName, categories } = templateFieldState;

    if (!templateName.trim().length) {
      errors.templateNameError = 'Required!';
      valid = false;
    }
    if (!categories.length) {
      errors.categoriesError = 'Required!';
      valid = false;
    }

    !valid && setTemplateErrors(errors);
    return valid;
  };

  const onNext = async () => {
    if (isValid()) {
      setLoading(true);
      const isMultipleFiles = files.length > 1;

      const multipleFilesResult =
        isMultipleFiles &&
        (await handleFileUpload({ fileName: templateFieldState.templateName, files }));

      dispatch(
        uploadDocumentEffect({ files: files }, undefined, (err, res) => {
          if (res?.data?.result?.DocumentVaultUUID) {
            const result = res.data.result;
            dispatch(
              createNewFormEffect(
                {
                  formName: templateFieldState.templateName,
                  link: result.DocumentVaultUUID,
                  type: 'Agent',
                  formCategoryTag: templateFieldState.categories,
                },
                (err, res) => {
                  if (res) {
                    dispatch(
                      createNewTemplateEffect(
                        {
                          link: res.data.value.link,
                          formId: res.data.value.formId,
                          templateName: templateFieldState.templateName,
                          formVersionId: res.data.value.formVersionId,
                          publishToTeam: templateFieldState.publishToTeam,
                          saveAsTemplate: true,
                        },
                        (err, res) => {
                          history.push({
                            pathname: routes.workshopFormsSignatoriesRequest,
                            state: {
                              formId: res.data?.value?.formTemplate?.FormId,
                              templateName: templateFieldState.templateName,
                              templateId: res.data?.value?.formTemplate?.TemplateId,
                              formVersionId: res.data?.value?.formTemplate?.FormVersionId,
                              createNewTemplate: true,
                              ...(multipleFilesResult && {
                                documentLinks: multipleFilesResult.documentLink,
                                categories: templateFieldState.categories,
                                publishToTeam: templateFieldState.publishToTeam,
                              }),
                            },
                          });
                        },
                      ),
                    );
                  } else {
                    setLoading(false);
                  }
                },
              ),
            );
          } else {
            setLoading(false);
          }
        }),
      );
    }
  };

  return (
    <div className={styles.newTemplateWrapper}>
      <FormHeader heading={'Create a Template'} handlePrevious={handlePrevious} />
      <div className={styles.newTemplateContent}>
        <TemplateFields
          fieldState={templateFieldState}
          onChange={onChangeTemplateField}
          errors={templateErrors}
          setErrors={setTemplateErrors}
          saveAsTemplate={templateFieldState.saveAsTemplate}
          className={styles.templateFieldsList}
          fileNameLabel="Name"
          fileNameRequired={true}
          isNewTemplate={true}
        />

        <TemplateCheckboxFields
          values={templateFieldState}
          onChange={onChangeTemplateField}
          setErrors={setTemplateErrors}
          isNewTemplate={true}
        />

        <div className={styles.formFileUploader}>
          <div className={styles.label}>Upload Files</div>
          <FormFileUploader updateFiles={setFiles} files={files} />
        </div>
      </div>

      <Navigation
        onNext={onNext}
        loading={loading}
        disabled={!files.length || loading}
        size="large"
      />
    </div>
  );
};
