import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { formatOpenHouses, openHouseSorter, preparePriceForMarkup } from 'store/selectors/feed';
import { getMatchedCriteriasList, getMatchedCriteriasFilteredList } from 'settings/constants/match';
import {
  formatNumber,
  getFirstImage,
  extractOrderedPhotosForFiltering,
  filterImagesOnly,
  filterImagesOnlyFromPhotoUrls,
} from 'helpers';
import { isEmpty } from 'lodash-es';
import { filterProperties } from 'pages/Properties/ClientFeed/components/ClientProperties/helpers';

const localState = ({ feedv3 }) => feedv3;

export const getFeedMetaSelectorV3 = createSelector(localState, (feed) => feed?.meta);

export const getFeedCriteriaSelectorV3 = createSelector(localState, (feed) => feed?.criteria);

export const getSavedSearchesFilteredPropertiesList = createSelector(
  localState,
  ({ savedSearchesProperties, criteria: { filter } }) => {
    return {
      isIdle: savedSearchesProperties?.state === IDLE,
      isPending: savedSearchesProperties?.state === PENDING,
      properties: filterProperties(savedSearchesProperties?.data?.items || [], filter)?.map(
        (prop) => ({
          ...prop,
          MatchedCriterias: getMatchedCriteriasFilteredList({
            ...prop,
            MatchedCriterias: {
              ...prop?.MatchedCriterias,
              ...(prop?.MatchedCriterias?.Commutes
                ? {
                    Commutes: prop?.MatchedCriterias?.Commutes.map((commute) => ({
                      Feature: `Commute to ${commute.Name}`,
                      ...commute,
                    })),
                  }
                : []),
              Commuting: [],
            },
          }),
          OpenHouses:
            prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
              .filter((openHouse) => openHouse.isActual)
              .sort(function (a, b) {
                return openHouseSorter(a, b);
              }) || [],
        }),
      ),
      totalCount: savedSearchesProperties?.data?.totalCount,
    };
  },
);

export const getSavedSearchesPropertiesList = createSelector(
  localState,
  ({ savedSearchesProperties, criteria: { filter } }) => {
    return {
      isIdle: savedSearchesProperties?.state === IDLE,
      isPending: savedSearchesProperties?.state === PENDING,
      properties: filterProperties(savedSearchesProperties?.data?.items || [], filter)?.map(
        (prop) => ({
          ...prop,
          MatchedCriterias: getMatchedCriteriasList({
            ...prop,
            MatchedCriterias: {
              ...prop?.MatchedCriterias,
              ...(prop?.MatchedCriterias?.Commutes
                ? {
                    Commutes: prop?.MatchedCriterias?.Commutes.map((commute) => ({
                      Feature: `Commute to ${commute.Name}`,
                      ...commute,
                    })),
                  }
                : []),
              Commuting: [],
            },
          }),
          OpenHouses:
            prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
              .filter((openHouse) => openHouse.isActual)
              .sort(function (a, b) {
                return openHouseSorter(a, b);
              }) || [],
        }),
      ),
      totalCount: savedSearchesProperties?.data?.totalCount,
    };
  },
);

export const getSavedSearchesPropertiesMarkersSelector = createSelector(
  localState,
  ({ savedSearchesProperties, criteria: { filter } }) =>
    filterProperties(savedSearchesProperties?.data?.items || [], filter)
      .map((property) => {
        const {
          Id,
          Geolocation,
          PhotoUrls,
          SellingPrice,
          NumBathsTotal,
          NumBeds,
          SquareFeet,
          Address,
          IsFavorite,
          Media,
          Market,
        } = property;
        const beds = NumBeds ? `${NumBeds} bd` : '';
        const baths = NumBathsTotal ? `${NumBathsTotal} ba` : '';
        const feet = SquareFeet ? `${formatNumber(SquareFeet)} ft` : '';
        const photos = !isEmpty(Media)
          ? filterImagesOnly(extractOrderedPhotosForFiltering(Media, Media.length), Market)
          : filterImagesOnlyFromPhotoUrls(PhotoUrls, Market);
        const imageToSend = photos[0];

        return {
          id: Id,
          IsFavorite: IsFavorite,
          image: imageToSend,
          price: SellingPrice,
          priceToDisplay: preparePriceForMarkup(SellingPrice),
          address: Address,
          NumBeds,
          NumBathsTotal,
          SquareFeet,
          geometry: {
            coordinates: { lng: Geolocation?.Long, lat: Geolocation?.Lat },
          },
          info: [beds, baths, feet].filter((val) => !!val).join(', '),
        };
      })
      .reduce((markersMap, marker) => {
        const {
          geometry: {
            coordinates: { lng, lat },
          },
        } = marker;

        if (typeof lng !== 'number' || typeof lat !== 'number') {
          return markersMap;
        }

        const key = `${lng} ${lat}`;

        return markersMap[key]?.length
          ? { ...markersMap, [key]: [...markersMap[key], marker] }
          : { ...markersMap, [key]: [marker] };
      }, {}),
);

export const getFavoritesPropertiesList = createSelector(
  localState,
  ({ favorites, criteria: { filter } }) => {
    return {
      isIdle: favorites?.state === IDLE,
      isPending: favorites?.state === PENDING,
      properties: filterProperties(favorites?.data?.items || [], filter)?.map((prop) => ({
        ...prop,
        MatchedCriterias: getMatchedCriteriasList(prop),
        OpenHouses:
          prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
            .filter((openHouse) => openHouse.isActual)
            .sort(function (a, b) {
              return openHouseSorter(a, b);
            }) || [],
        IsFavorite: true,
      })),
      totalCount: favorites?.data?.totalCount,
    };
  },
);

export const getFavoritesPropertiesMarkersSelector = createSelector(
  localState,
  ({ favorites, criteria: { filter } }) =>
    filterProperties(favorites?.data?.items || [], filter)
      .map((property) => {
        const {
          Id,
          Geolocation,
          PhotoUrls,
          SellingPrice,
          NumBathsTotal,
          NumBeds,
          SquareFeet,
          Address,
          IsFavorite,
          Media,
          Market,
        } = property;
        const beds = NumBeds ? `${NumBeds} bd` : '';
        const baths = NumBathsTotal ? `${NumBathsTotal} ba` : '';
        const feet = SquareFeet ? `${formatNumber(SquareFeet)} ft` : '';
        const photos = !isEmpty(Media)
          ? filterImagesOnly(extractOrderedPhotosForFiltering(Media, Media.length), Market)
          : filterImagesOnlyFromPhotoUrls(PhotoUrls, Market);
        const imageToSend = photos[0];
        return {
          id: Id,
          IsFavorite: IsFavorite,
          image: imageToSend,
          price: SellingPrice,
          priceToDisplay: preparePriceForMarkup(SellingPrice),
          address: Address,
          NumBeds,
          NumBathsTotal,
          SquareFeet,
          geometry: {
            coordinates: { lng: Geolocation?.Long, lat: Geolocation?.Lat },
          },
          info: [beds, baths, feet].filter((val) => !!val).join(', '),
        };
      })
      .reduce((markersMap, marker) => {
        const {
          geometry: {
            coordinates: { lng, lat },
          },
        } = marker;

        if (typeof lng !== 'number' || typeof lat !== 'number') {
          return markersMap;
        }

        const key = `${lng} ${lat}`;

        return markersMap[key]?.length
          ? { ...markersMap, [key]: [...markersMap[key], marker] }
          : { ...markersMap, [key]: [marker] };
      }, {}),
);

export const getFeedPageInfoSelector = createSelector(localState, (feed) => {
  return feed?.pageInfo;
});

// AGENT SIDE

export const getAgentPropertiesList = createSelector(
  localState,
  ({ agentProperties, criteria: { filter } }) => {
    return {
      isIdle: agentProperties?.state === IDLE,
      isPending: agentProperties?.state === PENDING,
      properties: filterProperties(agentProperties?.data?.items || [], filter)?.map((prop) => ({
        ...prop,
        MatchedCriterias: getMatchedCriteriasFilteredList({
          ...prop,
          MatchedCriterias: {
            ...prop?.MatchedCriterias,
            ...(prop?.MatchedCriterias?.Commutes
              ? {
                  Commutes: prop?.MatchedCriterias?.Commutes.map((commute) => ({
                    Feature: `Commute to ${commute.Name}`,
                    ...commute,
                  })),
                }
              : []),
            Commuting: [],
          },
        }),
        OpenHouses:
          prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
            .filter((openHouse) => openHouse.isActual)
            .sort(function (a, b) {
              return openHouseSorter(a, b);
            }) || [],
      })),
      totalCount: agentProperties?.data?.totalCount,
    };
  },
);

export const getAgentPropertiesMarkersSelector = createSelector(
  localState,
  ({ agentProperties, criteria: { filter } }) =>
    filterProperties(agentProperties?.data?.items || [], filter)
      .map((property) => {
        const {
          Id,
          Geolocation,
          PhotoUrls,
          SellingPrice,
          NumBathsTotal,
          NumBeds,
          SquareFeet,
          Address,
          IsFavorite,
        } = property;
        const beds = NumBeds ? `${NumBeds} bd` : '';
        const baths = NumBathsTotal ? `${NumBathsTotal} ba` : '';
        const feet = SquareFeet ? `${formatNumber(SquareFeet)} ft` : '';

        return {
          id: Id,
          IsFavorite: IsFavorite,
          image: PhotoUrls?.[0],
          price: SellingPrice,
          priceToDisplay: preparePriceForMarkup(SellingPrice),
          address: Address,
          NumBeds,
          NumBathsTotal,
          SquareFeet,
          geometry: {
            coordinates: { lng: Geolocation?.Long, lat: Geolocation?.Lat },
          },
          info: [beds, baths, feet].filter((val) => !!val).join(', '),
        };
      })
      .reduce((markersMap, marker) => {
        const {
          geometry: {
            coordinates: { lng, lat },
          },
        } = marker;

        if (typeof lng !== 'number' || typeof lat !== 'number') {
          return markersMap;
        }

        const key = `${lng} ${lat}`;

        return markersMap[key]?.length
          ? { ...markersMap, [key]: [...markersMap[key], marker] }
          : { ...markersMap, [key]: [marker] };
      }, {}),
);

export const getMatchedCriteriasAndScoreByPropertyId = createSelector(
  localState,
  ({ savedSearchesProperties }) =>
    savedSearchesProperties?.data?.items?.reduce(
      (acc, item) => ({
        ...acc,
        [item.Id]: { MatchedCriterias: item.MatchedCriterias, MatchScore: item.MatchScore },
      }),
      {},
    ),
);
