import classNames from 'classnames';
import styles from './style.module.scss';

import { Icons } from '../components/Icons';
import { Col, Row } from 'components-antd';

export const FormCategories = ({ stagesStep, onUpdate, setCurrentStageIndex, isDynamicForm }) => {
  const categories = [
    {
      label: 'Upload a File',
      iconVariant: 'upload',
      styleClass: styles.upload,
      stepIterator: stagesStep.UploadFile,
    },
    {
      label: 'My Library',
      iconVariant: 'library',
      styleClass: styles.library,
      stepIterator: stagesStep.Library,
    },
    ...(!isDynamicForm
      ? [
          {
            label: 'Recent Uploads',
            iconVariant: 'recentUploads',
            styleClass: styles.recentUploads,
            stepIterator: stagesStep.RecentUploads,
          },
          {
            label: 'Create a Template',
            iconVariant: 'template',
            styleClass: styles.template,
            stepIterator: stagesStep.NewTemplate,
          },
        ]
      : []),
  ];

  const onCategoryClick = (stepIterator) => {
    onUpdate(stepIterator);
    setCurrentStageIndex(stepIterator);
  };

  return (
    <div className={styles.formCategoriesWrapper}>
      <h1 className={styles.heading}>{isDynamicForm ? 'Add Form' : 'New Form'}</h1>

      <Row gutter={[24, 24]}>
        {categories.map((item) => {
          const { label, styleClass, stepIterator, iconVariant } = item;
          return (
            <Col md={12} xs={24} sm={12} key={label}>
              <div
                className={classNames(styles.categoryCard, styleClass)}
                onClick={() => onCategoryClick(stepIterator)}
              >
                <Icons variant={iconVariant} className={styles.categoryIcon} />
                <div className={styles.categoryLabel}>{item.label}</div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
