import { any } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { appSetShowEmailVerificationPopupAction } from 'store/actions/app';
import { getUserDataSelector } from 'store/selectors/user';
import { LOCAL_STORAGE_KEY } from './constants';

type AnyFunctionCallback = (...args: any[]) => void;

export function useEmailVerificationWall<
  CallbackType extends AnyFunctionCallback = AnyFunctionCallback,
>(
  actionName: string,
  whenVerifiedAction: CallbackType,
  callbackPayload?: object,
): (...args: Parameters<CallbackType>) => void {
  const user = useSelector(getUserDataSelector);
  const dispatch = useDispatch();

  function doActionOrShowModal(...args: Parameters<CallbackType>) {
    if (user.IsEmailVerified) {
      whenVerifiedAction(...args);
    } else {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({
          action: actionName,
          showSuccessModal: true,
          callbackPayload,
        }),
      );
      dispatch(appSetShowEmailVerificationPopupAction(true));
    }
  }

  return doActionOrShowModal;
}
