export const SoloIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M6.10739 21.0218C5.32255 20.6232 5.35768 19.4603 6.16303 19.105C12.3338 16.3824 15.6632 13.2243 19.0394 6.12171C19.4163 5.32873 20.5759 5.32858 20.9511 6.12238C23.8411 12.2371 27.0359 15.8025 33.9889 19.098C34.7648 19.4658 34.7915 20.5906 34.0304 20.9882C27.5837 24.3554 24.2707 27.579 20.9465 34.0676C20.5589 34.8243 19.4499 34.8199 19.0681 34.0603C15.7294 27.4179 12.4142 24.2247 6.10739 21.0218Z"
        fill="url(#paint0_linear_53754_390703)"
      />
      <path
        d="M6.10739 21.0218C5.32255 20.6232 5.35768 19.4603 6.16303 19.105C12.3338 16.3824 15.6632 13.2243 19.0394 6.12171C19.4163 5.32873 20.5759 5.32858 20.9511 6.12238C23.8411 12.2371 27.0359 15.8025 33.9889 19.098C34.7648 19.4658 34.7915 20.5906 34.0304 20.9882C27.5837 24.3554 24.2707 27.579 20.9465 34.0676C20.5589 34.8243 19.4499 34.8199 19.0681 34.0603C15.7294 27.4179 12.4142 24.2247 6.10739 21.0218Z"
        fill="url(#paint1_linear_53754_390703)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_53754_390703"
          x1="15.5"
          y1="13.5"
          x2="28.5"
          y2="38.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00B152" />
          <stop offset="1" stopColor="#389563" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_53754_390703"
          x1="15.5"
          y1="13.5"
          x2="28.5"
          y2="38.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4673D1" />
          <stop offset="1" stopColor="#0F3FC3" />
        </linearGradient>
      </defs>
    </svg>
  );
};
