import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MatchPartial = memo(({ className }) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 12L10 16L18 8"
          stroke="#FB913A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
});

MatchPartial.propTypes = {
  className: PropTypes.string,
};

MatchPartial.defaultProps = {
  className: '',
};

export default MatchPartial;
