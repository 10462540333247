import KitsDataRow from './KitsDataRow';
import styles from './styles.module.scss';

type KitsTablePropsType = {
  data: any[];
  kitDisableHandler: Function;
};

function KitsTable(props: KitsTablePropsType) {
  return (
    <div className={styles.KitsTable}>
      <table className={styles.table}>
        <tr className={styles.kitsRowHeading}>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>KIT</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Category</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Frequency</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Enable</div>
          </td>
        </tr>
        {props.data.length ? (
          props.data.map((item) => (
            <KitsDataRow data={item} kitDisableHandler={props.kitDisableHandler} />
          ))
        ) : (
          <tr>
            <td colSpan={4}>
              <div className={styles.emptyKit}>No KITs to Display</div>
            </td>
          </tr>
        )}
      </table>
    </div>
  );
}

export default KitsTable;
