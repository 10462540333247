import { ReactElement } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface TemplateButtonProps {
  name: string;
  icon: ReactElement;
  onClick?: () => void;
  className?: string;
}

export const getTemplateButtons = (icons: TemplateButtonProps[]) => {
  return (
    <div className={styles.buttonRow}>
      {icons.map((props, index) => (
        <TemplateButton
          {...props}
          className={classNames({ [styles.buttonMargin]: index + 1 !== icons.length })}
          key={`template-button-${props.name}-${index}`}
        />
      ))}
    </div>
  );
};

const TemplateButton = ({ icon, name, onClick, className }: TemplateButtonProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.templateButton, className, 'prevent-user-select')}
    >
      {icon} <span className={styles.name}>{name}</span>
    </div>
  );
};
