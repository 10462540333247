import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SimpleModal, Select, Button } from 'components';
import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import { quoteArchiveStatusOptions, NO_ARCHIVE_STATUS } from 'settings/constants/quotes';

import styles from './styles.module.scss';

const ArchiveModal = (props) => {
  const { className, isOpen, onClose, onSubmit, isPending, archivedStatus } = props;
  const [status, setStatus] = useState(archivedStatus || NO_ARCHIVE_STATUS);

  const onChangeStatusHandler = (e, val) => {
    setStatus(val.value);
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={onClose}
      className={classNames(styles.archiveModal, className)}
      innerHolderClassName={styles.inner}
      contentClassName={styles.content}
      testid="archive_modal"
    >
      <Icon className={styles.icon} variant={Icon.ARCHIVE} />
      <h1 testid="title" className={styles.title}>
        Are you sure you want to move this quote to the archive?
      </h1>
      <Select
        value={status}
        onSelect={onChangeStatusHandler}
        variant={Select.LIGHT_FULL}
        options={quoteArchiveStatusOptions}
        label="Quote Status"
        testid="quote_status"
      />
      <div className={styles.actions}>
        <Button
          testid="cancel"
          onClick={onClose}
          className={classNames(styles.btn, styles.cancel)}
          title="Cancel"
        />
        <Button
          isPending={isPending}
          onClick={() => onSubmit(status !== NO_ARCHIVE_STATUS ? status : undefined)}
          className={classNames(styles.btn, styles.yes)}
          title="Yes"
          loaderClassName={styles.loader}
          testid="yes"
        />
      </div>
    </SimpleModal>
  );
};

ArchiveModal.propTypes = {
  className: PropTypes.string,
  archivedStatus: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isPending: PropTypes.bool,
};

ArchiveModal.defaultProps = {
  className: '',
  archivedStatus: '',
  isOpen: false,
  onClose: () => {},
  onSubmit: () => {},
  isPending: false,
};

export default ArchiveModal;
