import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get, cloneDeep } from 'lodash-es';

import {
  requestGetRecommendedPropsAction,
  resetPropertiesWholeTabsAction,
} from 'store/actions/feed';
import { requestToggleFavoriteListingAction } from 'store/actions/listingDetail';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetRecommendedPropsAction]: (state, { payload }) => ({
      state: payload.state,
      data:
        payload?.options && payload?.options?.add
          ? [...state.data, ...get(payload, 'data.items', [])]
          : get(payload, 'data.items', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestToggleFavoriteListingAction]: (state, { payload }) => {
      const id = get(payload, 'meta.id');
      const isFavorite = get(payload, 'options.IsFavorite');
      const newData = cloneDeep(state?.data) || [];
      const favoriteIndex = newData.findIndex((item) => item?.Property?.Id === id);

      if (favoriteIndex !== -1) {
        newData[favoriteIndex].Property.IsFavorite = isFavorite;
      }

      return {
        ...state,
        data: newData,
      };
    },
    [resetPropertiesWholeTabsAction]: () => cloneDeep(initialData),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
