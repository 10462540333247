import React from 'react';
import { Button } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import useTriggerMockEvent from '../hooks/use-trigger-mock-event';

export const TriggerMockTestEvent = ({ topic, subEvent, eventName }) => {
  const { isLoading, triggerEvent } = useTriggerMockEvent();
  return (
    <Button
      type="primary"
      shape="circle"
      icon={<PlayCircleOutlined />}
      loading={isLoading}
      onClick={() => {
        triggerEvent({ topic, subEvent, eventName });
      }}
    />
  );
};
