import { DELETE_TRANSACTION_TEMPLATE_RULE, TRANSACTION_TEMPLATE_RULE } from 'app-constants';
import { apiServer } from 'settings/web-services/api';

export const addNewTransactionTemplateRule = ({ transactionTemplateId, ...cfg }) =>
  apiServer.post(TRANSACTION_TEMPLATE_RULE(transactionTemplateId), cfg);

export const getAllTransactionTemplateRules = ({ transactionTemplateId, ...cfg }) =>
  apiServer.get(TRANSACTION_TEMPLATE_RULE(transactionTemplateId), cfg);

export const deleteTransactionTemplateRule = ({ templateRuleId, ...cfg }) =>
  apiServer.delete(DELETE_TRANSACTION_TEMPLATE_RULE(templateRuleId), cfg);
