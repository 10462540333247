import { get } from 'lodash-es';

import { postRefreshToken } from 'api/user';

import { LocalStorage } from 'services';
import { showErrorMessage } from 'helpers/errors';
import { setHeaders, apiServer } from 'settings/web-services/api';
import { SessionStorage } from './sessionStorage';

export class AuthToken {
  static onLogout = () => {};

  static setOnLogoutHandler(callback) {
    this.onLogout = callback;
  }

  static initRest() {
    apiServer.interceptors.response.use(null, (error) => {
      if (error.config && error.response?.status === 401 && !error.config.__isRetry) {
        return new Promise((resolve, reject) => {
          AuthToken.tryToRefreshRestToken(error.config)
            .then((result) => {
              resolve(result);
            })
            .catch((err) => {
              reject(err);
              this.onLogout();
            });
        });
      }
      return Promise.reject(error);
    });

    this.initAuthRequestInterceptor();
  }

  static initAuthRequestInterceptor() {
    apiServer.interceptors.request.use((req) => {
      //console.log(`Axios Request Interceptor: Current ${SessionStorage.getLoginContext()} vs Stored ${LocalStorage.getTokenContext()}`, req,);
      //Clear authorization if the browser session has a login that does not match the token context
      if (
        SessionStorage.getLoginContext() &&
        SessionStorage.getLoginContext() != LocalStorage.getTokenContext()
      ) {
        //console.log(`Removing Auth Credential:  Login and Token Context do not match: ${SessionStorage.getLoginContext()} vs ${LocalStorage.getTokenContext()}`);
        //Remove authorization from current request header and global default
        req.headers['Authorization'] = '';
        delete apiServer.defaults.headers.common['Authorization'];
      }
      return req;
    });
  }

  static tryToRefreshRestToken(config) {
    return new Promise((resolve, reject) => {
      const tokens = LocalStorage.getTokens();

      postRefreshToken(tokens)
        .then((res) => {
          const { accessToken, refreshToken } = get(res, ['data', 'tokens'], {});
          if (accessToken && refreshToken) {
            LocalStorage.setTokens({ accessToken, refreshToken });
            setHeaders({ accessToken });

            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = accessToken;

            apiServer
              .request({ ...config, __isRetry: true }) // Repeat the initial request
              .then((result) => resolve(result))
              .catch((err) => reject(err));
          } else {
            throw new Error('wrong postRefreshToken result');
          }
        })
        .catch((err) => {
          showErrorMessage(err);
          this.onLogout();
        });
    });
  }
}
