/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Wrapper as PendingWrapper } from 'components';

import styles from './styles.module.scss';

const ItemWrapper = ({ className, children, isPending, testid }) => (
  <div className={classNames(styles.itemWrapper, className)}>
    <PendingWrapper
      testid={testid}
      className={styles.pendingWrapper}
      loaderClassName={styles.loader}
      isPending={isPending}
    >
      {children}
    </PendingWrapper>
  </div>
);

ItemWrapper.propTypes = {
  className: PropTypes.string,
  testid: PropTypes.string,
  children: PropTypes.node.isRequired,
  isPending: PropTypes.bool,
};

ItemWrapper.defaultProps = {
  className: '',
  isPending: false,
  testid: undefined,
};

export default ItemWrapper;
