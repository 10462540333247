import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DocumentVaultImage, Wrapper as PendingWrapper } from 'components';

import styles from './styles.module.scss';

const ImagesCollage = (props) => {
  const { className, images, isMessageSavedOnServer, onClick } = props;

  const previewCount = images?.length > 4 ? 4 : images.length;
  const firstImages = images.slice(0, previewCount);
  const overflowCount = images.length - previewCount;
  const showOverflowCount = images.length > 4;

  return (
    <div className={className}>
      <div
        onClick={onClick}
        className={classNames(styles.imagesCollage, { [styles.three]: previewCount === 3 })}
        testid="attachment_preview"
      >
        {(firstImages || []).map((img, index) => {
          return (
            <div
              key={img?.Url || index}
              className={classNames(
                styles.imageHolder,
                { [styles.two]: previewCount === 2 },
                { [styles.three]: previewCount === 3 },
                { [styles.four]: previewCount === 4 },
              )}
            >
              <DocumentVaultImage
                url={img?.Url}
                data={img?.Data}
                contentType={img?.ContentType}
                documentVaultUUID={img?.DocumentVaultUUID}
              />
              {showOverflowCount && index === firstImages?.length - 1 && (
                <div className={styles.overflowCount}>{`+${overflowCount}`}</div>
              )}
            </div>
          );
        })}
        <PendingWrapper
          className={styles.imagePendingWrapper}
          loaderClassName={styles.imageLoader}
          isPending={!isMessageSavedOnServer}
        />
      </div>
    </div>
  );
};

ImagesCollage.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMessageSavedOnServer: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

ImagesCollage.defaultProps = {
  className: '',
  onClick: () => {},
};

export default ImagesCollage;
