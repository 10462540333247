import moment from 'moment';
import PropTypes from 'prop-types';
import { Dropdown, Menu } from 'antd';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { Icons } from 'pages/Workshop/Icons';
import { getLocaleDate, limitTextLength } from 'helpers/index';
import { getUserFirstName, getUserLastName } from 'store/selectors/user';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

export const Document = ({ document, offerIterationData, onDeleteDocument, BuyingAgentName }) => {
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const getAuthorName = () => {
    let authorName;
    if (userFirstName && userFirstName !== 'Unknown') {
      authorName = userFirstName + ' ' + userLastName;
    } else {
      authorName = BuyingAgentName;
    }
    return authorName;
  };

  const onDelete = (data) => {
    onDeleteDocument(data);
  };
  const menu = (
    <Menu className={styles.preApprovalMenu}>
      <Menu.Item
        className={styles.action}
        onClick={() => {
          onDelete(document);
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.document}>
      <Icons className={styles.docIcon} variant="document" />
      <div className={styles.info}>
        <h4>{limitTextLength(document.documentName, 20)}</h4>
        <p>
          {limitTextLength(getAuthorName(), 15)} {'-'}{' '}
          {moment(getLocaleDate(document.CreatedDate)).format('MMM DD')}
          <span className={styles.time}>
            {' '}
            at {moment(getLocaleDate(document.CreatedDate)).format('hh:mm A')}
          </span>
        </p>
      </div>
      <Dropdown overlay={menu} className={styles.dropdown} placement={'bottomRight'}>
        <a onClick={(e) => e.preventDefault()}>
          <Icons className={styles.moreIcon} variant="more" />
        </a>
      </Dropdown>
    </div>
  );
};

Document.propTypes = {
  document: PropTypes.objectOf(
    PropTypes.shape({
      Id: PropTypes.number,
      filename: PropTypes.string,
      documentName: PropTypes.string,
      DocumentType: PropTypes.string,
      DocumentLink: PropTypes.string,
      CreatedDate: PropTypes.string,
      UpdatedDate: PropTypes.string,
    }),
  ),
  offerData: PropTypes.object,
};

Document.defaultProps = {
  document: {},
  offerData: {},
};
