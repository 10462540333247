import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { getDataFor } from 'store/helpers';
import { get } from 'lodash-es';

import { requestGetInvoiceDataOverviewAction } from 'store/actions/subscription';

const initialData = {
  state: IDLE,
  data: {},
};

export default handleActions(
  {
    [requestGetInvoiceDataOverviewAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
      meta: getDataFor('meta', payload, initialData),
    }),
  },
  initialData,
);
