import { getServiceTagsSortSelector } from 'store/selectors/adminPanel';
import { sortServiceTagsEffect } from 'store/effects/adminPanel';
import Sort from 'pages/Dashboard/components/Sort';
import { MosaikTag } from 'app-constants';

export const gridColumns = [
  {
    title: 'Name',
    propKey: 'Name',
    children: (
      <Sort selector={getServiceTagsSortSelector} effect={sortServiceTagsEffect} field="Name" />
    ),
  },
  {
    title: 'Type',
    propKey: 'Type',
    children: (
      <Sort selector={getServiceTagsSortSelector} effect={sortServiceTagsEffect} field="Type" />
    ),
    render: (type) => type?.replace(/([A-Z])/g, ' $1').trim(),
  },
];

export const typesOptions = Object.values(MosaikTag).map((el) => ({
  name: el,
  value: el,
}));

export const emptyValue = { name: '', value: null };
