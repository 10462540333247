import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from 'components-antd';
import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import MatchItemsList from '../../MatchItemsList';
import { Icons } from 'pages/Properties/common/Icons';

import styles from './styles.module.scss';

const Hint = (props) => {
  const { className, avatar, name, score, mustHave, wants, searchInstance } = props;

  return (
    <div className={classNames(className)}>
      <div className={styles.hintHeader}>
        <div className={styles.hintAvatar}>
          <Avatar className={styles.avatar} size={32} src={avatar}>
            {avatar ? avatar : convertNameToAvatarPlaceholder(`${name}`)}
          </Avatar>
        </div>
        <div className={styles.hintNameMatch}>
          <div className={styles.hintName}>{name}</div>
          <div className={styles.hintSearchInstance}>
            <Icons variant={Icons.BOOKMARK} className={styles.searchInstanceIcon} />
            <span className={styles.searchInstanceName}>{searchInstance}</span>
          </div>
        </div>
        <div className={styles.hintScore}>
          <div
            className={classNames(styles.hintScorePill, {
              [styles.greenProgress]: score >= 70,
              [styles.orangeProgress]: score >= 50 && score < 70,
              [styles.grayProgress]: score < 50,
            })}
          >
            <b>{score}% Match</b>
          </div>
        </div>
      </div>
      {!!mustHave?.length && (
        <div className={styles.blocksContainer}>
          <p className={styles.matchItemsTitle}>Needs</p>
          <MatchItemsList
            iconClassName={styles.matchIcon}
            titleClassName={styles.matchTitle}
            items={mustHave}
          />
        </div>
      )}
      {!!wants?.length && (
        <div className={styles.blocksContainer}>
          <p className={styles.matchItemsTitle}>Wants</p>
          <MatchItemsList
            iconClassName={styles.matchIcon}
            titleClassName={styles.matchTitle}
            items={wants}
          />
        </div>
      )}
    </div>
  );
};

Hint.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  score: PropTypes.number,
  mustHave: PropTypes.arrayOf(PropTypes.shape({})),
  wants: PropTypes.arrayOf(PropTypes.shape({})),
};

Hint.defaultProps = {
  className: '',
  avatar: null,
  name: '',
  score: null,
  mustHave: [],
  wants: [],
};

export default Hint;
