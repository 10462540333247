import PropTypes from 'prop-types';

import { Select as CustomSelect } from 'components';
import { prefsIds } from 'settings/constants/preferences';

import styles from './styles.module.scss';

export const FlooringOptions = ({ title, softCriteria, type, onClick }) => {
  return (
    <>
      {title === prefsIds.flooring && softCriteria[title] && softCriteria[title]?.value !== 'N/A' && (
        <div className={styles.flooringOptions}>
          <h4>Which flooring types do you prefer? </h4>
          <CustomSelect
            name={prefsIds.flooring}
            multiple
            className={{ wrapper: styles.wrapper, input: styles.selectInput }}
            variant={CustomSelect.DARK_HALF}
            options={FlooringOptions.FLOORING_OPTIONS}
            value={softCriteria[title].data}
            onSelect={(event, values) => onClick(title, softCriteria[title].value, type, values)}
            placeholder={prefsIds.flooring}
            checkboxPostion="start"
            closeOnSelect={false}
          />
        </div>
      )}
    </>
  );
};

FlooringOptions.FLOORING_OPTIONS = [
  { id: 1, name: 'Hardwood', value: 'Hardwood' },
  { id: 2, name: 'Carpet', value: 'Carpet' },
  { id: 3, name: 'Tile', value: 'Tile' },
  { id: 4, name: 'Stone', value: 'Stone' },
  { id: 5, name: 'Other', value: 'Other' },
];

FlooringOptions.propTypes = {
  title: PropTypes.string,
  softCriteria: PropTypes.shape({}),
  type: PropTypes.string,
  onClick: PropTypes.func,
};

FlooringOptions.defaultProps = {
  title: '',
  softCriteria: {},
  type: '',
  onClick: () => {},
};
