import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { PropertyType, Input } from 'components';
import { Question } from 'pages/RequestQuote/components';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { Continue } from '../../Continue';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';

import styles from './styles.module.scss';

const PropertyTypeQuestion = (props) => {
  const { className, onNext } = props;
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const propertyType = preForm?.[transactionPreFormQuestionsIds.propertyType];
  const yearBuilt = preForm?.[transactionPreFormQuestionsIds.yearBuilt];
  const dispatch = useDispatch();

  const onPropertyTypeChange = (type) => {
    if (type === 'Land') {
      dispatch(
        setTransactionPreFormQuestionsEffect({
          [transactionPreFormQuestionsIds.propertyType]: type,
          [transactionPreFormQuestionsIds.yearBuilt]: null,
        }),
      );
    } else {
      dispatch(
        setTransactionPreFormQuestionsEffect({
          [transactionPreFormQuestionsIds.propertyType]: type,
        }),
      );
    }
  };

  const onAnswerHandler = (year) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.yearBuilt]: year,
      }),
    );
  };

  return (
    <div testid="what_property_type" className={classNames(styles.container, className)}>
      <Question>{"Let's confirm some property details."}</Question>
      <AnswersContainer>
        <PropertyType
          className={styles.propertyType}
          label="What is the property type?"
          propertySelectClassName={styles.propertyInput}
          itemClassName={styles.optionItem}
          multiple={false}
          onChange={onPropertyTypeChange}
          value={propertyType}
          isTransactionCreation={true}
          type="dropdown"
        />
        {propertyType !== 'Land' && (
          <Input
            isNumberFormat
            thousandSeparator={false}
            variant={Input.LIGHT_ROUNDED}
            placeholder="Year (i.e. 2010)"
            label="What was the year built?"
            onChange={(e, val) => onAnswerHandler(val)}
            value={yearBuilt}
            testid="year_input"
            minNumber={0}
            maxNumber={new Date().getFullYear()}
            className={styles.input}
          />
        )}
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={() => onNext?.()} />
      </ButtonsContainer>
    </div>
  );
};

PropertyTypeQuestion.propTypes = {
  className: PropTypes.string,
  onAnswer: PropTypes.func,
};

PropertyTypeQuestion.defaultProps = {
  className: '',
  onAnswer: () => {},
};

export default PropertyTypeQuestion;
