export enum SearchCriteriaFilterTypes {
  HomeType = 'Property Type',
  LotSizeRange = 'Lot Size',
  MaxDaysOnMarket = 'Time on Market',
  MaximumHOA = 'Maximum HOA',
  MinParkingSpaces = 'Parking Spaces',
  PriceRange = 'Price',
  SquareFeetRange = 'Square Feet',
  Status = 'Status',
  StoriesRange = 'Stories',
  YearBuiltRange = 'Year Build',
  NumBathroomsRange = 'Baths',
  NumBedroomsRange = 'Beds',
  Locations = 'Location',
}
