import { useState, useEffect } from 'react';

import { Row, Col } from 'components-antd';
import { Input, InputLabel } from 'components';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { TaskEditableFormValuesKeys, TaskEditableFormValuesType } from 'types/transactionTasks';

import styles from './styles.module.scss';

interface LinksProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  className?: string | null;
  isViewMode?: boolean | null;
  showOptional?: boolean | null;
  editData?: any;
  addLink?: boolean;
}

interface Link {
  label: string;
  link: string;
}

export const Links = ({
  values,
  editData,
  onChangeField,
  isViewMode,
  showOptional,
  addLink,
}: LinksProps) => {
  const initialLinkState = { label: '', link: '' };
  const [listValue, setListValue] = useState<Link[]>(values?.Links ?? []);

  const onAdd = () => {
    setListValue((prev) => [...prev, initialLinkState]);
    onChangeField(listValue, 'Links');
  };

  const onChangeLabel = (value: string, index: number) => {
    const copyList = [...listValue];
    copyList.splice(index, 1, { link: listValue[index].link, label: value });
    setListValue([...copyList]);
    onChangeField([...copyList], 'Links');
  };

  const onChangeLink = (value: string, index: number) => {
    const copyList = [...listValue];
    copyList.splice(index, 1, { link: value, label: listValue[index].label });
    setListValue([...copyList]);
    onChangeField([...copyList], 'Links');
  };

  const onRemove = (index) => {
    const copyList = [...listValue];
    copyList.splice(index, 1);
    setListValue(copyList);
    onChangeField(copyList, 'Links');
  };

  useEffect(() => {
    if (addLink) {
      onAdd();
    }
  }, [addLink]);

  const getItem = ({ index, item }) => {
    return (
      <div className={styles.linkWrapper}>
        <Input
          name={`${index}-link`}
          value={item.link}
          placeholder="Add link address"
          className={styles.linkInput}
          inputClassName={styles.input}
          inputHolderClassName={styles.inputHolder}
          removeBtnClassName={styles.removeBtn}
          onChange={(e, value) => onChangeLink(value, index)}
          removeHandler={() => !isViewMode && onRemove(index)}
          disabled={isViewMode}
        />
        <Input
          name={`${index}-label`}
          value={item.label}
          placeholder="Add label"
          className={styles.labelInput}
          inputClassName={styles.input}
          inputHolderClassName={styles.inputHolder}
          onChange={(e, value) => onChangeLabel(value, index)}
          disabled={isViewMode}
        />
      </div>
    );
  };

  useEffect(() => {
    const links = values?.Links || editData?.Links;
    if (links?.length) {
      setListValue(links);
    }
  }, []);

  return (
    <div className={styles.links}>
      {addLink || listValue?.length ? (
        <div className={styles.spacing}>
          <Row align="middle">
            <Col xs={20} sm={20} md={22} lg={22} xl={22}>
              <InputLabel label="Links" className={styles.label} showOptional={showOptional} />
            </Col>
            <Col xs={4} sm={4} md={2} lg={2} xl={2}>
              <Icon
                variant={Icon.ADDLINK}
                className={styles.addLinkIcon}
                onClick={() => !isViewMode && onAdd()}
              />
            </Col>
          </Row>

          {listValue.map((item, index) => (
            <div key={index}>{getItem({ index, item })}</div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
