import classNames from 'classnames';
import React, { useEffect } from 'react';

import {
  AddFormCommentType,
  CommentUsersType,
  CustomOptions,
  EditFormCommentType,
  FormComment,
  FormCommentThread,
} from 'types';
import { primaryColor } from 'app-constants';
import { FormCommentBox } from '../FormCommentBox';
import { Badge, Collapse, Panel, Space } from 'components-antd';
import { FormCommentActionBar } from '../FormCommentActionBar';

import styles from './styles.module.scss';

interface FormPopoverCommentsProps {
  threads: FormCommentThread[];
  commentUsers: CommentUsersType;
  notifyOptions: CustomOptions;
  permissionOptions: CustomOptions;
  permittedUsers: string[];
  setPermittedUsers: (permittedUsers: string[]) => void;
  notifiedUsers?: string[];
  setNotifiedUsers: (notifiedUsers?: string[]) => void;
  isSending: boolean;
  setSending: (isSending: boolean) => void;
  activeKey?: string;
  setActiveKey: (activeKey: string) => void;
  handleAddFormComment: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment?: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment?: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
}

export const FormPopoverComments = ({
  threads,
  commentUsers,
  notifyOptions,
  permissionOptions,
  permittedUsers,
  setPermittedUsers,
  notifiedUsers,
  setNotifiedUsers,
  isSending,
  setSending,
  activeKey,
  setActiveKey,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
}: FormPopoverCommentsProps) => {
  const scrollRef = React.useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef?.current?.scrollIntoView();
    }
  }, [scrollRef.current]);

  const renderCommentBox = (
    comment: FormComment,
    isHeader?: boolean,
    isResolved?: boolean,
    threadId?: number,
    threadKey?: number,
  ) => {
    const userInfo = commentUsers[comment.CreatorId || comment.CreatorEmail] || {
      email: comment.CreatorEmail,
    };

    return (
      <FormCommentBox
        key={`comment-box-${comment.Id}`}
        isHeader={isHeader}
        isResolved={isResolved}
        threadId={threadId}
        isSending={isSending}
        setSending={setSending}
        userInfo={userInfo}
        comment={comment}
        commentUsers={commentUsers}
        notifyOptions={notifyOptions}
        handleEditFormComment={handleEditFormComment}
        handleDeleteFormComment={handleDeleteFormComment}
        handleToggleResolveThread={handleToggleResolveThread}
        commentBoxClass={
          isHeader && activeKey === threadKey?.toString() ? styles.popoverHeaderCommentBox : ''
        }
      />
    );
  };

  return (
    <>
      {threads?.map((thread, i) => (
        <div
          key={`thread-${thread.Id}-${i}`}
          onClick={() => {
            scrollRef?.current?.scrollIntoView({
              behavior: 'smooth',
              inline: 'nearest',
            });
          }}
          className={styles.commentBoxSpacing}
        >
          <Badge
            color={primaryColor}
            count={
              activeKey === `${i + 1}`
                ? undefined
                : thread.Comments.length <= 1
                ? 0
                : thread.Comments.length
            }
            overflowCount={thread.Comments.length === 0 ? undefined : thread.Comments.length - 1}
          >
            <Collapse
              accordion
              activeKey={activeKey}
              onChange={(value) => setActiveKey(value as string)}
              destroyInactivePanel
              className={styles.threadWindow}
            >
              <Panel
                key={i + 1}
                showArrow={false}
                collapsible="header"
                header={renderCommentBox(
                  thread.Comments[0],
                  true,
                  thread.Resolved,
                  thread.Id,
                  i + 1,
                )}
              >
                <div
                  className={classNames(styles.commentsWrapper, {
                    [styles.commentBoxPadding]: thread.Comments.length > 1,
                  })}
                >
                  {thread.Comments.length > 1 ? (
                    <Space direction="vertical" size="large">
                      {thread.Comments.slice(1).map((comment) => renderCommentBox(comment))}
                    </Space>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={styles.replyBox}>
                  <FormCommentActionBar
                    isNewThread={false}
                    isCommentPopover={true}
                    isSending={isSending}
                    setSending={setSending}
                    commentUsers={commentUsers}
                    notifyOptions={notifyOptions}
                    permissionOptions={permissionOptions}
                    notifiedUsers={notifiedUsers}
                    setNotifiedUsers={setNotifiedUsers}
                    permittedUsers={permittedUsers}
                    setPermittedUsers={setPermittedUsers}
                    handleAddFormComment={handleAddFormComment}
                    threadPermissions={thread.Permissions}
                    threadId={thread.Id}
                    placeholder="Reply"
                    actionBarClass={styles.popoverCommentActionBar}
                  />
                </div>
              </Panel>
            </Collapse>
          </Badge>
        </div>
      ))}
    </>
  );
};
