import { useState, useEffect } from 'react';

import { Popover } from 'components-antd';
import Icon from '../../../Feed/Icons/index';

import styles from './styles.module.scss';

export const ViewSwitcher = ({ getView }) => {
  const [iconVariant, setIconVariant] = useState('');
  const [viewType, setViewType] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    function handleWindowSizeChange() {
      if (window.innerWidth <= 992) {
        setIsMobile(true);
        setIconVariant(Icon.LIST_VIEW);
        setViewType('List Only');
        getView('List Only');
      } else {
        setIsMobile(false);
        setIconVariant(Icon.SPLIT_VIEW);
        setViewType('Split View');
        getView('Split View');
      }
    }
    if (document.readyState === 'complete') {
      handleWindowSizeChange();
    }
    window.addEventListener('resize', handleWindowSizeChange);
    window.addEventListener('load', handleWindowSizeChange);
    return () => {
      window.removeEventListener('load', handleWindowSizeChange);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const onSplitView = () => {
    setIconVariant(Icon.SPLIT_VIEW);
    setViewType('Split View');
    getView('Split View');
    setOpen(false);
  };
  const onMapView = () => {
    setIconVariant(Icon.MAP_VIEW);
    setViewType('Map Only');
    getView('Map Only');
    setOpen(false);
  };
  const onListView = () => {
    setIconVariant(Icon.LIST_VIEW);
    setViewType('List Only');
    getView('List Only');
    setOpen(false);
  };

  const options = (
    <div className={styles.optionsList}>
      {!isMobile && (
        <div className={styles.option} onClick={onSplitView}>
          <Icon className={styles.icon} variant={Icon.SPLIT_VIEW} />
          <span>Split View</span>
        </div>
      )}
      <div className={styles.option} onClick={onMapView}>
        <Icon className={styles.icon} variant={Icon.MAP_VIEW} />
        <span>Map Only</span>
      </div>
      <div className={styles.option} onClick={onListView}>
        <Icon className={styles.icon} variant={Icon.LIST_VIEW} />
        <span>List Only</span>
      </div>
    </div>
  );

  return (
    <Popover
      content={options}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      getPopupContainer={(triggerNode) => triggerNode}
      overlayClassName={styles.viewSwitcherDropdown}
    >
      <a className={styles.viewSwitcher} onClick={(e) => e.preventDefault()}>
        <Icon className={styles.icon} variant={iconVariant} />
        <span>{viewType}</span>
      </a>
    </Popover>
  );
};
