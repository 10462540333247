export const initState = {
  PurchasePrice: null,
  DownPayment: null,
  EarnestMoneyDeposit: null,
  ClosingDate: null,
  FinancingType: null,
  Contingencies: JSON,
  Notes: null,
  OfferIterationDocument: [],
  OfferExpiryIndefinitely: false,
  OfferExpiryDays: null,
  OfferExpiryEndDate: null,
};
