import {
  AdminFriendlySubscriptionPlan,
  GetAvailableSubscriptionPlansResponse,
  initSubscriptionCheckout,
} from 'api/subscription';
import { Button, Modal } from 'components-antd';
import { showErrorMessage } from 'helpers';
import React, { useCallback, useEffect } from 'react';

import { PaywallModalStep1 } from './paywallSteps/PaywallModalStep1';
import { PaywallModalStep2 } from './paywallSteps/PaywallModalStep2';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { CurrentActiveSubscriptionPlan } from 'pages/Paywall/types';

const PRICING_PAGE_URL = 'https://www.mosaik.io/pricing-and-plans';

interface PaywallModalProps {
  availablePlan: GetAvailableSubscriptionPlansResponse | null;
  currentActivePlan: CurrentActiveSubscriptionPlan;
}

export const PaywallModal: React.FC<PaywallModalProps> = (props) => {
  const { currentActivePlan } = props;
  const [step, setStep] = React.useState<1 | 2>(1);
  const [selectedPlan, setSelectedPlan] = React.useState<string | null>(null);
  const [activationCoupon, setActivationCoupon] = React.useState<string | null>(null);
  const [requestedSeatsCount, setRequestedSeatsCount] = React.useState<2 | 3>(2);
  const isStandard = props?.availablePlan?.Plan === AdminFriendlySubscriptionPlan.Standard;
  const isResubscribing = currentActivePlan?.SubscriptionStatus === 'canceled';

  useEffect(() => {
    if (selectedPlan === 'solo') {
      setRequestedSeatsCount(2);
    }
    if (selectedPlan === 'team') {
      setRequestedSeatsCount(3);
    }
  }, [selectedPlan]);

  useEffect(() => {
    const activeMembersCount = currentActivePlan?.ActualSeatsCount;
    if (isResubscribing && isStandard) {
      // no need to show first step as solo plan is only selectable for max count 2
      if (activeMembersCount > 2) {
        setSelectedPlan('team');
      } else {
        setSelectedPlan('solo');
      }
    }
  }, [currentActivePlan, isStandard]);

  // disabled stripe checkout
  // const handleSubscribeClick = useCallback(async () => {
  //   if (!props.availablePlan || !selectedPlan) {
  //     return null;
  //   }

  //   // Adding 1 to the requested seats count as the user is included in the count
  //   const totalRequestedSeatsCount = (requestedSeatsCount ?? 0) + 1;
  //   const isStandardPlan = props?.availablePlan?.Plan;

  //   const response = await initSubscriptionCheckout({
  //     planId: props.availablePlan.ExternalId,
  //     sourcePage: 'paywall',
  //     priceKey: selectedPlan,
  //     requestedSeatsCount: totalRequestedSeatsCount ?? undefined,
  //     activationCoupon: activationCoupon ?? undefined,
  //   });

  //   if (!response.data.success || !response.data.result) {
  //     showErrorMessage('Error occurred');
  //   } else {
  //     window.location.href = response.data.result.RedirectUrl;
  //   }
  // }, [props.availablePlan, selectedPlan]);

  const handleNextStepClick = useCallback(() => {
    setStep(2);
  }, [step]);

  return (
    <Modal
      getContainer={'#paywallModalContainer'}
      open={true}
      width={675}
      footer={null}
      closable={false}
      maskClosable={false}
      mask={false}
    >
      <div
        className={classNames({
          ...(step === 1 ? { [styles.modalLayout]: true } : { [styles.modalLayoutCheckout]: true }),
        })}
      >
        {step === 1 ? (
          <>
            <PaywallModalStep1
              availablePlan={props.availablePlan}
              selectedPlan={selectedPlan}
              onSelectedPlanChange={setSelectedPlan}
              setActivationCoupon={setActivationCoupon}
              activationCoupon={activationCoupon}
              isResubscribing={isResubscribing}
            />
            {isStandard ? (
              <div>
                You can view additional details about each plan{' '}
                <a target="_blank" href={PRICING_PAGE_URL} rel="noreferrer">
                  here.
                </a>{' '}
              </div>
            ) : (
              <div>
                You can view additional details on our{' '}
                <a target="_blank" href={PRICING_PAGE_URL} rel="noreferrer">
                  pricing page.
                </a>{' '}
              </div>
            )}

            <Button
              onClick={handleNextStepClick}
              variant="secondary"
              className={classNames(styles.continueButton, styles.button)}
              disabled={!selectedPlan}
            >
              Activate
            </Button>
          </>
        ) : (
          <>
            {props?.availablePlan?.ExternalId && (
              <PaywallModalStep2
                planId={props?.availablePlan?.ExternalId}
                selectedPlan={selectedPlan}
                requestedSeatsCount={requestedSeatsCount}
                isStandard={isStandard}
                currentActivePlan={currentActivePlan}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
