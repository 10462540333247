import { propertyStatusOptions, defaultPropertyStatusToFetch } from 'settings/constants/properties';
import { getMinMaxAsStringValues } from 'components/MinMax/helpers';
import { sortStatus } from 'helpers';
import PrimaryCriteria from '../index';
import { PREF_TYPE, prefsIds } from 'settings/constants/preferences';

const fieldsToStringify = ['PriceRange', 'SquareFeetRange', 'LotSizeRange', 'StoriesRange'];

export const softCriteriaPrefill = (values) => {
  const map = {
    Must: 'Need',
    Somewhat: 'Want',
  };
  const parsedCriteria = {};
  Object.entries(values).forEach(([key, value]) => {
    if (
      [
        PREF_TYPE.homePrefs,
        PREF_TYPE.outdoorPrefs,
        PREF_TYPE.interiorStylesPrefs,
        PREF_TYPE.ViewPrefs,
        PREF_TYPE.neighborhoodPrefs,
        PREF_TYPE.amenitiesPrefs,
      ].includes(key)
    ) {
      value.forEach(({ Preference, ImportanceAndWeight: { Importance } }) => {
        parsedCriteria[Preference] = { value: map[Importance], type: key };
      });
    } else if (key === PREF_TYPE.parking) {
      const {
        ImportanceAndWeight: { Importance },
        NumOfParkingSpaces,
        GarageOnly,
        GarageType,
      } = value;
      parsedCriteria[key] = {
        value: map[Importance],
        data: {
          count: NumOfParkingSpaces,
          garageOnly: GarageOnly,
          garageType: GarageType,
        },
        type: key,
      };
    } else if (key === PREF_TYPE.commutePrefs) {
      parsedCriteria[prefsIds.commutes] = value.filter(
        ({ isImportantLocation }) => !isImportantLocation,
      );
      parsedCriteria[prefsIds.proximityToImportantLocations] = value.filter(
        ({ isImportantLocation }) => isImportantLocation,
      );
    } else if ([PREF_TYPE.school, PREF_TYPE.keywordPrefs].includes(key)) {
      parsedCriteria[key] = value;
    }
  });
  return parsedCriteria;
};

export const mergeInitialValues = (search, subfilter = false) => {
  const searchPrefsValue = {
    ...search,
    StoriesRange: search?.StoriesRange ? search?.StoriesRange : search?.Stories?.StoriesRange,
  };

  return {
    ...PrimaryCriteria.initialValues,
    ...searchPrefsValue,
    MaxDaysOnMarket: searchPrefsValue?.MaxDaysOnMarket
      ? `${searchPrefsValue?.MaxDaysOnMarket}`
      : PrimaryCriteria.initialValues.MaxDaysOnMarket,
    ...fieldsToStringify.reduce(
      (searchObj, fieldName) =>
        fieldName
          ? {
              ...searchObj,
              [fieldName]: getMinMaxAsStringValues(
                fieldName,
                searchPrefsValue,
                PrimaryCriteria.initialValues,
              ),
            }
          : searchObj,
      {},
    ),
    MinParkingSpaces: searchPrefsValue?.MinParkingSpaces ?? 0,
    Status: searchPrefsValue?.Status?.length
      ? propertyStatusOptions
          .filter((option) => searchPrefsValue?.Status?.includes(option.value))
          .sort(sortStatus)
      : subfilter
      ? []
      : defaultPropertyStatusToFetch,
  };
};
