import { handleActions } from 'redux-actions';

import { openSavedSearchesDrawerAction } from 'store/actions/searchResults';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';

const initialData = {
  open: false,
  type: null,
};

export default handleActions(
  {
    [openSavedSearchesDrawerAction]: (state, { payload }) => ({
      ...state,
      open: payload.open || initialData.open,
      type: payload.type || initialData.type,
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
