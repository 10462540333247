import { handleActions } from 'redux-actions';

import {
  openNotificationsDrawerAction,
  setNotificationsDrawerParamsAction,
} from 'store/actions/drawers/notifications';
import { cloneDeep, get } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  open: false,
  params: {
    currentNotificationsUser: null,
    type: null,
    filter: null,
  },
};

export default handleActions(
  {
    [openNotificationsDrawerAction]: (state, { payload }) => ({
      ...state,
      open: get(payload, 'open', initialData.open),
      params: get(payload, 'params', state.params),
    }),
    [setNotificationsDrawerParamsAction]: (state, { payload }) => ({
      ...state,
      params: payload || initialData.params,
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
