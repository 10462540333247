import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Wrapper } from 'components';
import { transactionsFilters } from 'settings/constants/roles';
import { milestoneStatus } from 'settings/constants/milestones';
import {
  getMilestoneDataSelector,
  getMilestoneStateSelector,
} from 'store/selectors/milestoneAggregate';
import { tableColumns } from './tableColumns';
import { CollapsibleTable, WorkshopTableWrapper } from 'pages/Workshop/common';
import {
  isThisWeek,
  isToday,
  isAfterThisWeek,
  subtractTimeZone,
  isBeforeToday,
  getDateOnly,
} from 'helpers';
import { getTransactionsSelector } from 'store/selectors/transactions';
import { LocationService } from 'services';

import styles from './styles.module.scss';

const TableView = () => {
  let milestonesData: any = useSelector(getMilestoneDataSelector);
  const { isPending, isFiltersApplied, isArchive } = useSelector(getMilestoneStateSelector);
  const { transactionFilter } = useSelector(getTransactionsSelector);

  const locationSrv = new LocationService();
  const location = useLocation();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  const data: any = {
    overdueMilestones: [],
    todayMilestones: [],
    thisWeekMilestones: [],
    upcomingMilestones: [],
    completedMilestones: [],
    allMilestones: [],
  };

  milestonesData = milestonesData.map((m) => {
    return {
      ...m,
      DueDate: getDateOnly(m.DueDate, 'YYYY-MM-DD'),
    };
  });

  for (const milestone of milestonesData) {
    let keepItem = true;

    switch (transactionFilter) {
      case transactionsFilters.myTransactions:
        keepItem = milestone.Transaction.IsMyTransaction;
        break;

      case transactionsFilters.teamTransactions:
        keepItem =
          milestone.Transaction.IsTeamTransaction && !milestone.Transaction.IsMyTransaction;
        break;

      case transactionsFilters.externalTransactions:
        keepItem =
          !milestone.Transaction.IsTeamTransaction && !milestone.Transaction.IsMyTransaction;
        break;
    }

    if (!keepItem) continue;

    if (isFiltersApplied) {
      data.allMilestones.push(milestone);
      continue;
    }

    const dueDate = getDateOnly(milestone.DueDate, 'YYYY-MM-DD');

    const addOverdue =
      isBeforeToday(subtractTimeZone(dueDate)) && milestone.Status === milestoneStatus.INCOMPLETE;

    if (addOverdue) {
      data.overdueMilestones.push(milestone);
    }

    const addToday = isToday(subtractTimeZone(milestone.DueDate));

    if (addToday) {
      data.todayMilestones.push(milestone);
    }

    const addThisWeek =
      isThisWeek(subtractTimeZone(milestone.DueDate)) &&
      !isToday(subtractTimeZone(milestone.DueDate));

    if (addThisWeek && !addOverdue) {
      data.thisWeekMilestones.push(milestone);
    }

    const addUpcoming = isAfterThisWeek(subtractTimeZone(milestone.DueDate));

    if (addUpcoming) {
      data.upcomingMilestones.push(milestone);
    }

    const addCompleted = milestone.Status === milestoneStatus.COMPLETE;

    if (addCompleted) {
      data.completedMilestones.push(milestone);
    }
  }

  const tableProps = {
    columns: tableColumns(),
    rowClassName: (record, index) =>
      record.Status === milestoneStatus.COMPLETE ? styles.rowChecked : '',
  };

  return (
    <Wrapper isPending={isPending}>
      <div testid="tableView" className={classNames(styles.tableView)}>
        {isFiltersApplied ? (
          <WorkshopTableWrapper data={[data.allMilestones]} emptyText="No Milestones">
            <CollapsibleTable
              title={`${data.allMilestones.length} Milestones`}
              dataSource={data.allMilestones}
              {...tableProps}
              disableCollapse
            />
          </WorkshopTableWrapper>
        ) : isArchive ? (
          <WorkshopTableWrapper data={[data.completedMilestones]} emptyText="No Milestones.">
            <CollapsibleTable
              title="Completed"
              dataSource={data.completedMilestones}
              {...tableProps}
            />
          </WorkshopTableWrapper>
        ) : (
          <WorkshopTableWrapper
            data={[
              data.overdueMilestones,
              data.todayMilestones,
              data.thisWeekMilestones,
              data.upcomingMilestones,
            ]}
            emptyText="No Milestones."
          >
            <CollapsibleTable title="Overdue" dataSource={data.overdueMilestones} {...tableProps} />
            {query.status !== 'Overdue' ? (
              <>
                <CollapsibleTable title="Today" dataSource={data.todayMilestones} {...tableProps} />
                <CollapsibleTable
                  title="This Week"
                  dataSource={data.thisWeekMilestones}
                  defaultCollapse
                  {...tableProps}
                />
                <CollapsibleTable
                  title="Upcoming"
                  dataSource={data.upcomingMilestones}
                  defaultCollapse
                  {...tableProps}
                />
              </>
            ) : (
              <></>
            )}
          </WorkshopTableWrapper>
        )}
      </div>
    </Wrapper>
  );
};

export default TableView;
