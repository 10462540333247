import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';

import { saveQuoteFormResponseEffect, updateQuoteFormResponseEffect } from 'store/effects/quotes';
import { getQuoteFormSelector } from 'store/selectors/requestQuote';
import { FORM_MODE, FORM_QUESTION_TYPE } from 'app-constants';
import { JumpType, QuestionType, ResponseType } from 'types';
import { routes } from 'settings/navigation/routes';

import { QuestionContainer } from 'pages/FormBuilder/QuestionContainer/QuestionContainer';
import { ArrowUp, Close as CloseIcon } from 'components/Icons';
import { ContentWrapper } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';

import styles from './styles.module.scss';

interface FormQuestionsProps {
  questionIndex: number;
  filteredQuestions: QuestionType[];
  setQuestionIndex: (index: number) => void;
  handleSubmit: () => void;
  setTotalQuestions: (total: number) => void;
  setIsPreForm: (isPreForm: boolean) => void;
  setFilteredQuestions: (questions: QuestionType[]) => void;
}

const FormQuestions = ({
  questionIndex,
  filteredQuestions,
  setQuestionIndex,
  handleSubmit,
  setTotalQuestions,
  setIsPreForm,
  setFilteredQuestions,
}: FormQuestionsProps) => {
  const dispatch = useDispatch();

  const didMountRef = useRef(false);
  const history = useHistory();

  const [nextDisabled, setNextDisabled] = useState(false);

  const quoteForm = useSelector(getQuoteFormSelector);
  const FormQuestions = quoteForm?.FormQuestions || [];
  const FormResponses = quoteForm?.FormResponses || [];

  const formQuestionExists = Boolean(FormQuestions?.length);

  const formQuestion = filteredQuestions[questionIndex];
  const formResponse = FormResponses?.find(
    (formResponse: ResponseType) => formResponse?.UUID === formQuestion?.UUID,
  );

  const lastQuestionIndex = filteredQuestions.length - 1;

  const [jLQs, setJLQs] = useState<QuestionType[]>([]);
  const [jLIds, setJLIds] = useState<{}>();

  const handleFormResponse = (response: Partial<ResponseType>) => {
    dispatch(updateQuoteFormResponseEffect({ ...formResponse, ...response }));
  };

  const handleNextQuestion = () => {
    let response = formResponse?.Answer;

    if (formQuestion?.Type === FORM_QUESTION_TYPE.ListBox) {
      response = JSON.parse(formResponse?.Answer || '[]');
    }

    if (formQuestion?.JumpLogic && response?.length && formQuestion?.JumpLogic[response]) {
      const nextQsIndex = filteredQuestions?.findIndex(
        (finalQuestion) => finalQuestion.UUID === (formQuestion.JumpLogic as JumpType)[response],
      );
      setQuestionIndex(nextQsIndex);
    } else {
      if (formQuestionExists && questionIndex < lastQuestionIndex && !nextDisabled) {
        setQuestionIndex(questionIndex + 1);
      }
    }
  };

  const handlePreviousQuestion = () => {
    if (formQuestionExists && questionIndex > 0) {
      setQuestionIndex(questionIndex - 1);
    } else if (formQuestionExists && questionIndex === 0) {
      setIsPreForm(true);
    }
  };

  const handleContinue = () => {
    FormQuestions?.forEach((question) => {
      if (!filteredQuestions.some((qs) => qs?.UUID === question?.UUID)) {
        handleFormResponse({
          Answer:
            FormResponses.find((response) => response?.UUID === question?.UUID)?.DefaultAnswer ||
            formResponse?.Answer,
        });
      }

      if (!filteredQuestions.includes(question)) {
        dispatch(updateQuoteFormResponseEffect({ ...question, Answer: '' }));
      }
    });

    if (quoteForm?.quoteRequestDraftId && FormResponses?.length) {
      dispatch(
        saveQuoteFormResponseEffect({
          id: quoteForm.quoteRequestDraftId,
          responseData: FormResponses,
        }),
      );
    }

    handleNextQuestion();
  };

  const getFilteredQuestions = () => {
    const jumpLogicObject = FormQuestions.filter((question) => question.JumpLogic).reduce(
      (obj, question) => {
        obj[question?.UUID] = Object.values(question.JumpLogic || {});
        return obj;
      },
      {},
    );

    setJLIds(jumpLogicObject);

    const allJumpLogicIds = Object.values(jumpLogicObject).flat(1);

    const jLQs = FormQuestions.filter((question) => allJumpLogicIds.includes(question?.UUID));
    setJLQs(jLQs);

    const notJLQs = FormQuestions.filter((question) => !allJumpLogicIds.includes(question?.UUID));

    const finalQs: QuestionType[] = notJLQs.slice();
    notJLQs.forEach((question) => {
      const questionResponse = FormResponses.find((response) => response.UUID === question.UUID);

      if (
        question.JumpLogic &&
        (question.Type === FORM_QUESTION_TYPE.YesNo
          ? questionResponse?.Answer
          : JSON.parse(questionResponse?.Answer || '[]').length)
      ) {
        const response =
          question.Type === FORM_QUESTION_TYPE.YesNo
            ? questionResponse?.Answer
            : JSON.parse(questionResponse?.Answer);

        const index = FormQuestions.findIndex(
          (formQuestion) => formQuestion.UUID === question.JumpLogic[response],
        );
        const prevQsIndex = finalQs.findIndex(
          (finalQ) => finalQ?.UUID === FormQuestions[index - 1]?.UUID,
        );
        const jLQ = jLQs.find((formQuestion) => formQuestion.UUID === question.JumpLogic[response]);
        finalQs.splice(prevQsIndex + 1, 0, jLQ as QuestionType);
      }
    });

    setFilteredQuestions(finalQs.filter((finalQ) => finalQ));

    setTotalQuestions(Object.keys(jumpLogicObject).length + notJLQs.length);

    return jumpLogicObject;
  };

  useEffect(() => {
    getFilteredQuestions();
  }, [FormQuestions]);

  useEffect(() => {
    if (didMountRef.current) {
      let jumpLogicObjectIds = jLIds;
      if (!jLIds) {
        jumpLogicObjectIds = getFilteredQuestions();
      }

      if (
        formQuestion?.JumpLogic &&
        (formQuestion.Type === FORM_QUESTION_TYPE.YesNo
          ? formResponse?.Answer
          : JSON.parse(formResponse?.Answer || '[]').length)
      ) {
        const addQuestion = jLQs?.find(
          (jLQ) =>
            jLQ.UUID ===
            (formQuestion.JumpLogic as JumpType)[
              formQuestion.Type === FORM_QUESTION_TYPE.YesNo
                ? formResponse.Answer
                : JSON.parse(formResponse?.Answer)
            ],
        );

        const currentQuestionJL = (jumpLogicObjectIds as {})[formQuestion?.UUID];

        const removeQuestion = filteredQuestions.filter((question) =>
          currentQuestionJL?.includes(question?.UUID),
        );

        const removeIndex = filteredQuestions.findIndex(
          (question) => removeQuestion.length && removeQuestion[0].UUID === question?.UUID,
        );

        const updatedQuestions = filteredQuestions.slice();
        if (removeIndex >= 0) {
          updatedQuestions.splice(removeIndex, 1);
        }
        if (addQuestion?.UUID) {
          const index = FormQuestions.findIndex((question) => addQuestion.UUID === question.UUID);
          const prevQsIndex = updatedQuestions.findIndex(
            (question) => FormQuestions[index - 1]?.UUID === question.UUID,
          );
          updatedQuestions.splice(prevQsIndex + 1, 0, addQuestion as QuestionType);
        }
        setFilteredQuestions(updatedQuestions.filter((updatedQuestion) => updatedQuestion));
      } else if (
        formQuestion?.JumpLogic &&
        (formQuestion.Type === FORM_QUESTION_TYPE.YesNo
          ? !formResponse?.Answer
          : !JSON.parse(formResponse?.Answer || '[]').length)
      ) {
        const currentQuestionJL = (jumpLogicObjectIds as {})[formQuestion?.UUID];
        const removeQuestion = filteredQuestions.filter((question) =>
          currentQuestionJL?.includes(question?.UUID),
        );
        const removeIndex = filteredQuestions.findIndex(
          (question) => removeQuestion.length && removeQuestion[0].UUID === question?.UUID,
        );
        const updatedQuestions = filteredQuestions.slice();
        if (removeIndex >= 0) {
          updatedQuestions.splice(removeIndex, 1);
        }
        setFilteredQuestions(updatedQuestions.filter((updatedQuestion) => updatedQuestion));
      }
    }
    didMountRef.current = true;
  }, [FormResponses]);

  useEffect(() => {
    if (formQuestion?.Required && !formResponse?.Answer) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [questionIndex, lastQuestionIndex, formQuestion, formResponse]);

  useEffect(() => () => setQuestionIndex(0), []);

  const onClose = () => {
    history.replace(routes.services);
  };

  return (
    <>
      <div testid="controls" className={styles.controls}>
        <div onClick={() => handlePreviousQuestion()} className={styles.prev}>
          <ArrowUp className={styles.arrow} />
        </div>
        <CloseIcon onClick={onClose} className={styles.closeIcon} />
      </div>
      {formQuestionExists && (
        <ContentWrapper>
          <QuestionContainer
            mode={FORM_MODE.View}
            formQuestion={formQuestion}
            formResponse={formResponse}
            handleFormResponse={handleFormResponse}
            responseMode={true}
          />
          <ButtonsContainer>
            <Continue
              disabled={nextDisabled}
              onClick={
                questionIndex === lastQuestionIndex
                  ? () => {
                      handleContinue();
                      handleSubmit();
                    }
                  : handleContinue
              }
            ></Continue>
          </ButtonsContainer>
        </ContentWrapper>
      )}
    </>
  );
};

export default FormQuestions;
