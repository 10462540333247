import { memo, useMemo } from 'react';
import classNames from 'classnames';

import { Col, Row } from 'components-antd';

import styles from './styles.module.scss';

interface CardSelectBoxItem {
  key: string;
  value?: string;
}

interface CardSelectBoxProps {
  items: CardSelectBoxItem[];
  value?: string;
  onSelect: (string) => void;
}

export const CardSelectBox = memo(({ items, onSelect, value }: CardSelectBoxProps) => {
  return useMemo(
    () => (
      <Row
        className={styles.itemContainer}
        align="middle"
        justify="start"
        style={{ minWidth: `${(items.length || 1) * 77}px` }}
      >
        {items.map((item) => {
          return (
            <Col span={''} key={item.key}>
              <span
                onClick={(e) => onSelect(item.value)}
                className={classNames(
                  styles.item,
                  { selectedItem: value == item.value },
                  'prevent-user-select',
                  'show-cursor',
                )}
              >
                {item.key}
              </span>
            </Col>
          );
        })}
      </Row>
    ),
    [value],
  );
});
