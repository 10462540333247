import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { routes } from 'settings/navigation/routes';

import { getCurrentContextSelector } from 'store/selectors/context';
import { getClientContextsStateSelector } from 'store/selectors/contexts';

import { READY, PENDING } from 'settings/constants/apiState';

import { MyDocuments } from './MyDocuments';
import { VaultPropertyPage } from './VaultPropertyPage';
import { PreApprovals } from './PreApprovals';
import { getVaultDocumentsMetaEffect } from 'store/effects';

export const Vault = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const searchContext = useSelector(getCurrentContextSelector);
  const contextsState = useSelector(getClientContextsStateSelector);
  const selectedAgentId = searchContext?.Agents?.[0]?.Id;

  useEffect(() => {
    if (contextsState.state === READY && selectedAgentId) {
      dispatch(
        getVaultDocumentsMetaEffect({
          id: selectedAgentId,
        }),
      );
    }
  }, [contextsState.state, selectedAgentId]);

  const showComponent = () => {
    switch (pathname) {
      case routes.vault:
        return <MyDocuments />;
      case routes.vaultPropertyPage:
        return <VaultPropertyPage />;
      case routes.vaultPreApprovals:
        return <PreApprovals />;
      default:
        return <MyDocuments />;
    }
  };

  return <>{showComponent()}</>;
};
