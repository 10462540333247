import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

import { getGroupedQuotesSelector } from 'store/selectors/quotes';
import { getUserRoleSelector } from 'store/selectors/user';

import GroupByDetailTable from 'pages/Quotes/components/Tables/GroupByDetailTable';

const ByArchieve = (props) => {
  const { className, onArchive } = props;
  const { byArchieve } = useSelector(getGroupedQuotesSelector);
  const userRole = useSelector(getUserRoleSelector);
  const getDetailTables = () => {
    return (
      <GroupByDetailTable quotesList={byArchieve} groupedBy={'ByArchieve'} onArchive={onArchive} />
    );
  };

  return (
    <div testid="by_archieve" className={classNames(styles.byArchieve, className)}>
      {getDetailTables()}
    </div>
  );
};

ByArchieve.propTypes = {
  className: PropTypes.string,
  onArchive: PropTypes.func,
};

ByArchieve.defaultProps = {
  className: '',
  onArchive: () => {},
};

export default ByArchieve;
