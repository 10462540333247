export default {
  projects: '/workshop/projects',
  projectCreate: '/workshop/projects/create',
  projectClose: '/workshop/projects/:id/close',
  projectOverview: '/workshop/projects/:id/overview',
  projectDetails: '/workshop/projects/:id/details',
  projectTasks: '/workshop/projects/:id/tasks',
  projectDocuments: '/workshop/projects/:id/documents',
  projectActivity: '/workshop/projects/:id/activity',
  projectCancellation: '/workshop/projects/:id/cancellation',
};
