import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useOutsideClick } from 'hooks';
import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import { FILE_TYPES } from 'settings/constants/common';
import {
  deleteTransactionTaskDocumentEffect,
  previewDocumentEffect,
} from 'store/effects/transactions';
import { PdfViewModal, Wrapper as PendingWrapper, OptionsPopup } from 'components';
import fileDownload from 'js-file-download';
import styles from './styles.module.scss';
import { showErrorMessage } from 'helpers/errors';

const Actions = (props) => {
  const { className, deletable, file, taskId, onDelete, DocumentVaultUUID } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [document, setDocument] = useState(null);
  const actionsRef = useRef();
  const buttonRef = useRef();
  const popupRef = useRef();

  useOutsideClick([actionsRef, buttonRef, popupRef], () => setOpen(false));

  const onClick = () => {
    setOpen(!open);
  };

  const onPreview = () => {
    setIsPreviewOpen(true);
    setOpen(false);
  };

  const onDownload = () => {
    dispatch(
      previewDocumentEffect(
        { taskId, documentId: file?.Id, DocumentVaultUUID },
        {},
        (err, response) => {
          if (err) {
            return showErrorMessage(err);
          }
          fileDownload(response.data, response.headers['file-name']);
          setIsPending(false);
        },
      ),
    );

    setOpen(false);
  };

  const onDeleteHandler = (event) => {
    if (taskId) {
      setIsPending(true);
      dispatch(
        deleteTransactionTaskDocumentEffect({ taskId, documentId: file?.Id }, {}, () => {
          setIsPending(false);
        }),
      );
      setOpen(false);
    } else {
      onDelete(event);
    }
  };

  const onDocumentPreview = (event, Title) => {
    setIsPending(true);
    dispatch(
      previewDocumentEffect(
        { taskId, documentId: file?.Id, DocumentVaultUUID },
        {},
        (err, response) => {
          if (err) {
            return showErrorMessage(err);
          }
          onPreview();
          setDocument({
            DocumentBuffer: response,
            Filename: Title || response.headers['file-name'],
          });
          setIsPending(false);
        },
      ),
    );
    setOpen(false);
  };

  return (
    <PendingWrapper
      isPending={isPending}
      spinnerClassname={styles.spinnerWrapper}
      loaderClassName={styles.loader}
    >
      <div testid="doc_actions" ref={actionsRef} className={classNames(styles.actions, className)}>
        <Icon ref={buttonRef} onClick={onClick} variant={Icon.ACTIONS_DOTS} />
        <OptionsPopup
          className={classNames(styles.attachmentOptions, styles.options)}
          ref={popupRef}
          offsetLeft={-150}
          offsetTop={8}
          parentRef={buttonRef}
          open={open}
        >
          <div>
            <ul className={styles.list}>
              {file?.Meta?.MimeType === FILE_TYPES.PDF &&
                (file?.Meta?.DocumentVaultUUID || file?.url) && (
                  <li
                    testid="preview"
                    onClick={(e) => onDocumentPreview(e, file?.Title)}
                    className={styles.item}
                  >
                    <div className={styles.icon}>
                      <Icon variant={Icon.PREVIEW} />
                    </div>
                    Preview
                  </li>
                )}
              <li testid="download" onClick={onDownload} className={styles.item}>
                <div className={styles.icon}>
                  <Icon variant={Icon.OPTIONS_DOWNLOAD} />
                </div>
                Download
              </li>
              {deletable && (
                <li testid="delete" onClick={onDeleteHandler} className={styles.item}>
                  <div className={styles.icon}>
                    <Icon variant={Icon.OPTIONS_DELETE} />
                  </div>
                  Delete
                </li>
              )}
            </ul>
          </div>
        </OptionsPopup>
        {isPreviewOpen && document?.DocumentBuffer && (
          <PdfViewModal
            isOpen={isPreviewOpen}
            file={document}
            onClose={() => setIsPreviewOpen(false)}
          />
        )}
      </div>
    </PendingWrapper>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  deletable: PropTypes.bool,
  file: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    Meta: PropTypes.shape({
      Filename: PropTypes.string,
      Url: PropTypes.string,
      ContentType: PropTypes.string,
    }),
    Title: PropTypes.string,
    data: PropTypes.string,
    filename: PropTypes.string,
    documentName: PropTypes.string,
    contentType: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  DocumentVaultUUID: PropTypes.number,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDelete: PropTypes.func,
};

Actions.defaultProps = {
  className: '',
  deletable: false,
  taskId: undefined,
  DocumentVaultUUID: undefined,
  onDelete: () => {},
};

export default Actions;
