import { useParams } from 'react-router-dom';

import { AllowedAnonEnum } from 'app-constants';
import { FormStateManager } from 'pages/FormProcess';

export const AnonFormProcess = () => {
  const { token, type } = useParams<{ token: string; type: AllowedAnonEnum }>();

  return token && type ? <FormStateManager anonData={{ token, type }} /> : <></>;
};
