import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Checkbox, Input } from 'components';

import styles from './styles.module.scss';

const Custom = (props) => {
  const { className, checked, onChangeValue, onChangeOption, value, textValue, disabled, testid } =
    props;

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => setIsChecked(checked), [checked]);

  const getCustomLabel = () => {
    if (!checked) {
      return <span className={styles.customText}>Custom</span>;
    }

    return (
      <div className={styles.customOption}>
        <Input
          onChange={(e, val) => onChangeValue(val)}
          className={styles.customInput}
          variant={Input.LIGHT_FULL}
          type="number"
          value={textValue}
          placeholder="90"
          autofocus
          testid="custom_input"
        />
        <span className={styles.minutes}>minutes</span>
      </div>
    );
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      <Checkbox
        type="radio"
        className={classNames(styles.customCheckbox)}
        name="MaxCommuteTimeInMinutes"
        label={getCustomLabel()}
        direction={Checkbox.DIRECTION_RIGHT}
        onChange={onChangeOption}
        checked={isChecked}
        value={value}
        disabled={disabled}
        testid={testid}
      />
    </div>
  );
};

Custom.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onChangeOption: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  textValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  testid: PropTypes.string,
};

Custom.defaultProps = {
  className: '',
  disabled: false,
  testid: undefined,
};

export default Custom;
