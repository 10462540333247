import PropTypes from 'prop-types';
import { get } from 'lodash-es';

import { Icons } from 'pages/Workshop/Icons';

import styles from './styles.module.scss';
import { ShowingDetails } from '../ShowingDetails';
import moment from 'moment';
import { getLocaleDate } from 'helpers';
import { AppointmentStatus } from 'types/showingAppointment';

export const Header = ({ data }) => {
  const address = get(data, 'ShowingDetails.PropertyTransaction.Property.Address') || {};
  const { AppointmentDate, AppointmentStartTime, AppointmentEndTime } = data;
  const status = data?.AppointmentStatus;

  return (
    <div className={styles.detailsHeader}>
      <ShowingDetails
        label="Location:"
        title={address?.Line1}
        icon={Icons.LOCATION}
        description={`${address?.City}, ${address?.State} ${address?.Zip}`}
      />
      <ShowingDetails
        label={status === AppointmentStatus.Pending ? 'Request:' : 'Showing Information:'}
        title={`${moment(getLocaleDate(AppointmentStartTime)).format('hh:mm A')} - 
        ${moment(getLocaleDate(AppointmentEndTime)).format('hh:mm A (z)')}`}
        icon={Icons.CALENDAR}
        description={`${moment(getLocaleDate(AppointmentDate)).format('dddd, MMMM Do, YYYY')}`}
      />
    </div>
  );
};

Header.propTypes = {
  data: PropTypes.object,
};

Header.defaultProps = {
  data: {},
};
