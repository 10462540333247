import { FORM_TYPE } from 'app-constants';
import { PublishedFormsType } from 'types';

export const excludeSmartForms = (dataSource) => {
  return dataSource?.filter(({ type }) => type !== FORM_TYPE.Smart);
};

export const getUniqueForms = (forms) => {
  const keys: number[] = [];
  const uniqueForms: PublishedFormsType = [];

  forms.forEach((item) => {
    if (!keys.includes(item.bundleId || item.versionId)) {
      keys.push(item.bundleId || item.versionId);
      uniqueForms.push(item);
    }
  });

  return uniqueForms;
};
