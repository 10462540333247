import { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';

import { Calendar } from 'components-antd';
import { TimeZone } from './TimeZone';
import { Time } from './Time';
import { Spinner } from 'components';
import {
  getDayFromDate,
  getDateDifferenceFromToday,
  getTimeZone,
  showErrorMessage,
  getMergedDateTime,
  validateMinimumNotice,
  getFormattedTime,
} from 'helpers';
import { getShowingAvailability, getShowingDetails } from 'store/selectors/showingAppointment';
import { AppointmentStatus as Status } from 'types';
import { Row, Col } from 'antd';
import styles from './styles.module.scss';

export const BookingCalendar = (props) => {
  const {
    setDateTime,
    selectedDateTime,
    appointmentFormData,
    setAppointmentFormData,
    changeEditMode,
  } = props;

  const { isPending, dayTimeAvailable, dayTimeBooked } = useSelector(getShowingAvailability);
  const {
    ShowingDuration,
    AddBufferBetweenShowings,
    BufferTime,
    RequireDocumentPreApproval,
    LinkActiveEndDate,
    PropertyTransaction,
  } = useSelector(getShowingDetails);

  const [timeList, setTimeListUpdate] = useState<any[]>();

  useEffect(() => {
    if (selectedDateTime?.date) {
      getTimeListForSelectedDate(selectedDateTime.date);
    }
  }, [selectedDateTime?.date]);

  const getTimeListForSelectedDate = (date) => {
    let selectedDay = getDayFromDate(date);
    const availabilities = dayTimeAvailable
      .filter((item) => item.Day === selectedDay)
      .map((availability) => {
        const now = moment(date);
        return {
          ...availability,
          StartTime: moment(availability.StartTime).set({
            date: now.date(),
            month: now.month(),
            year: now.year(),
          }),
          EndTime: moment(availability.EndTime).set({
            date: now.date(),
            month: now.month(),
            year: now.year(),
          }),
        };
      });
    let allTimes: any[] = [];
    availabilities.forEach((availability) => {
      const times = generateTimeForSelectedDate(availability);
      allTimes = [...times, ...allTimes];
    });
    setTimeListUpdate(allTimes);
  };

  const isDateTimeBooked = (selectedDate, selectedTime) => {
    const booked = dayTimeBooked.find((item) => {
      if (
        moment(item.BookedDate).startOf('day').isSame(moment(selectedDate).startOf('day')) &&
        moment(item.BookedStartTime).format('HH:mm') == selectedTime.format('HH:mm')
      ) {
        return true;
      }
    });
    return booked;
  };

  const generateTimeForSelectedDate = (availability: any) => {
    let list: any[] = [];
    if (availability) {
      let time = moment(availability.StartTime);
      const endTime = moment(availability.EndTime);
      const duration = AddBufferBetweenShowings ? ShowingDuration + BufferTime : ShowingDuration;

      while (time.isSameOrBefore(endTime)) {
        const isBooked = isDateTimeBooked(selectedDateTime.date, time);
        if (!isBooked && moment().add(availability.MinimumNotice, 'hour').isBefore(time)) {
          const obj = {
            time: cloneDeep(time),
            minimumNotice: availability.MinimumNotice,
            selected:
              moment(time).format('HH:mm') === moment(selectedDateTime.startTime).format('HH:mm'),
          };
          list.push(obj);
        }
        time = time.add(duration, 'minutes');
      }

      const lastTime = cloneDeep(list[list.length - 1]?.time);
      if (lastTime && lastTime.add(ShowingDuration, 'minutes').isAfter(endTime)) {
        list.pop();
      }

      const AvailabilityId = availability.AvailabilityId;
      const ShowingId = availability.ShowingId;
      const AppointmentStatus = availability.AutomaticApproval ? Status.Upcoming : Status.Pending;
      setAppointmentFormData({
        ...appointmentFormData,
        AvailabilityId,
        ShowingId,
        RequireDocumentPreApproval,
        AppointmentStatus,
      });
    }
    return list;
  };

  const SelectDate = (date) => {
    if (getDateDifferenceFromToday(date) > 0) {
      showErrorMessage('Selected date is not available');
      return;
    }
    getTimeListForSelectedDate(date);
    setDateTime({ ...selectedDateTime, date, startTime: null, endTime: null });
    setAppointmentFormData({
      ...appointmentFormData,
      AppointmentDate: date.toString(),
      AppointmentStartTime: null,
      AppointmentEndTime: null,
    });
  };

  const SelectTime = (time, minimumNotice) => {
    if (minimumNotice && validateMinimumNotice(selectedDateTime.date, time, minimumNotice)) {
      showErrorMessage(`${minimumNotice} hours difference required for selected time`);
      return;
    }
    const startTime = getMergedDateTime(selectedDateTime?.date, cloneDeep(time));
    const addedTime = cloneDeep(time.add(ShowingDuration, 'minutes'));
    const endTime = getMergedDateTime(selectedDateTime?.date, addedTime);
    setDateTime({ ...selectedDateTime, startTime, endTime });
    setAppointmentFormData({
      ...appointmentFormData,
      AppointmentStartTime: startTime,
      AppointmentEndTime: endTime,
    });
    changeEditMode(true, false);
  };

  const disabledDate = (current) => {
    let customDate = moment(new Date(), 'YYYY-MM-DD').subtract(1, 'day');

    const daysAvailable = dayTimeAvailable.map((dayTimeObject) => dayTimeObject.Day);

    const day = moment(current).format('dddd');
    return (
      !daysAvailable.includes(day) ||
      current < moment(customDate, 'YYYY-MM-DD') ||
      current > moment(LinkActiveEndDate).endOf('day') ||
      current >
        moment(PropertyTransaction?.PropertyTransactionKeyDatesPrices?.ListingExpireDate).endOf(
          'day',
        )
    );
  };

  const selectedDate = () => {
    return selectedDateTime?.date || null;
  };

  return (
    <>
      {isPending ? (
        <Spinner loaderClassName={classNames(styles.loader)} />
      ) : (
        <div className={styles.calendarArea}>
          <div className={styles.bookingCalendarWrapper}>
            <div>
              <Calendar
                onSelect={SelectDate}
                disabledDate={disabledDate}
                selectedDate={selectedDate()}
                className={classNames(
                  styles.showingsCalendar,
                  {
                    [styles.maxWidth400]: selectedDate(),
                    [styles.maxWidthContent]: !selectedDate(),
                  },
                  'mosaikCalendarFull',
                  selectedDate() ? 'selectedDateWithSlotWrapper' : 'selectedDateWrapper',
                )}
              />
              <TimeZone timeZone={getTimeZone()} className={styles.timeZone} />
            </div>
            {selectedDate() && (
              <div className={styles.timeListArea}>
                <Time
                  timeSelect={SelectTime}
                  timeList={timeList}
                  selectedDate={selectedDate().date()}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
