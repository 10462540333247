import classNames from 'classnames';

import { Checkbox } from 'components';
import { Close } from 'components/Icons';
import { Paragraph, Tooltip } from 'components-antd';

import styles from './styles.module.scss';
import { useState } from 'react';

export const ServiceBox = ({
  label,
  isChecked,
  isDeletable,
  handleDelete,
  handleCheckBoxChange,
}) => {
  const [ellipsis, setEllipsis] = useState(false);
  return (
    <div
      className={classNames(styles.ServiceBoxContainer, {
        [styles.isChecked]: isChecked,
      })}
    >
      <div className={styles.leftBlock}>
        <Checkbox
          className={styles.serviceCheckbox}
          checked={isChecked}
          onChange={handleCheckBoxChange}
        />
        <Tooltip
          title={ellipsis ? label : ''}
          placement="top"
          overlayClassName={styles.serviceBoxToolTip}
        >
          <Paragraph
            ellipsis={{ onEllipsis: setEllipsis }}
            className={classNames(styles.serviceTitle, {
              [styles.isChecked]: isChecked,
            })}
          >
            {label}
          </Paragraph>
        </Tooltip>
      </div>
      {isDeletable && (
        <Close className={styles.closeIcon} color={'#676767'} onClick={handleDelete} />
      )}
    </div>
  );
};
