import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ArrowLeft } from 'components/Icons';

import styles from './styles.module.scss';

const BackLink = ({ className, to, text, onClick, testid }) => (
  <Link onClick={onClick} testid={testid} className={classNames(styles.back, className)} to={to}>
    <ArrowLeft className={styles.arrow} />
    <span>{text}</span>
  </Link>
);

BackLink.propTypes = {
  className: PropTypes.string,
  testid: PropTypes.string,
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

BackLink.defaultProps = {
  className: '',
  text: 'Back',
  testid: null,
  onClick: () => {},
};

export default BackLink;
