import React, { useCallback } from 'react';

export const ServiceIconTypes = {
  location: 'location',
  search: 'search',
  check: 'check',
  filledLocation: 'filledLocation',
} as const;

interface ServiceIconProps extends React.SVGProps<SVGSVGElement> {
  icon: typeof ServiceIconTypes[keyof typeof ServiceIconTypes];
}

export const ServiceIcons = ({ icon, ...props }: ServiceIconProps) => {
  const getIcon = useCallback(() => {
    switch (icon) {
      case ServiceIconTypes.location:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="12" cy="11" r="3" stroke="#515151" strokeWidth="2" />
            <path
              d="M13.0931 21.6734L13.7182 22.454L13.0931 21.6734ZM10.9069 21.6734L10.2818 22.454L10.9069 21.6734ZM19 11C19 12.9761 17.9778 14.9886 16.5776 16.7938C15.1921 18.5801 13.5321 20.0407 12.468 20.8929L13.7182 22.454C14.844 21.5524 16.6375 19.9798 18.1579 18.0196C19.6637 16.0784 21 13.6311 21 11H19ZM11.532 20.8929C10.4678 20.0407 8.8079 18.5801 7.42238 16.7938C6.02217 14.9886 5 12.9761 5 11H3C3 13.6311 4.3363 16.0784 5.84205 18.0196C7.36249 19.9798 9.15598 21.5524 10.2818 22.454L11.532 20.8929ZM5 11C5 7.13401 8.13401 4 12 4V2C7.02944 2 3 6.02944 3 11H5ZM12 4C15.866 4 19 7.13401 19 11H21C21 6.02944 16.9706 2 12 2V4ZM12.468 20.8929C12.1869 21.118 11.8131 21.118 11.532 20.8929L10.2818 22.454C11.2936 23.2643 12.7064 23.2643 13.7182 22.454L12.468 20.8929Z"
              fill="#515151"
            />
          </svg>
        );
      case ServiceIconTypes.search:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M10.4444 15.8889C13.4513 15.8889 15.8889 13.4513 15.8889 10.4444C15.8889 7.43756 13.4513 5 10.4444 5C7.43756 5 5 7.43756 5 10.4444C5 13.4513 7.43756 15.8889 10.4444 15.8889Z"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9997 18.9987L14.333 14.332"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ServiceIconTypes.check:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M4 10L8 14L16 6"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ServiceIconTypes.filledLocation:
        return (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="28" cy="28" r="28" fill="#FFF2F3" />
            <ellipse cx="28" cy="26.668" rx="4" ry="4" stroke="#FF576D" strokeWidth="2" />
            <path
              d="M29.098 41.1823L29.7134 41.9706L29.098 41.1823ZM37.6668 26.6667C37.6668 29.5315 36.1241 32.4069 34.123 34.9057C32.1394 37.3825 29.8142 39.3547 28.4827 40.394L29.7134 41.9706C31.1049 40.8843 33.564 38.8032 35.6841 36.1558C37.7866 33.5305 39.6668 30.2108 39.6668 26.6667H37.6668ZM27.5176 40.394C26.1861 39.3547 23.8609 37.3825 21.8773 34.9057C19.8762 32.4069 18.3335 29.5315 18.3335 26.6667H16.3335C16.3335 30.2108 18.2137 33.5305 20.3162 36.1558C22.4363 38.8032 24.8954 40.8843 26.287 41.9705L27.5176 40.394ZM18.3335 26.6667C18.3335 21.3279 22.6614 17 28.0002 17V15C21.5568 15 16.3335 20.2233 16.3335 26.6667H18.3335ZM28.0002 17C33.3389 17 37.6668 21.3279 37.6668 26.6667H39.6668C39.6668 20.2233 34.4435 15 28.0002 15V17ZM28.4827 40.394C28.1915 40.6213 27.8088 40.6213 27.5176 40.394L26.287 41.9705C27.3015 42.7625 28.6988 42.7625 29.7134 41.9706L28.4827 40.394Z"
              fill="#FF576D"
            />
          </svg>
        );

      default:
        return <></>;
    }
  }, [icon]);

  return getIcon();
};
