import moment from 'moment';
import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserId } from 'store/selectors/user';
import { FormCommentBox } from '../FormCommentBox';
import { CommentHistoryActions } from 'app-constants';
import { FormCommentActionBar } from '../FormCommentActionBar';
import { Avatar, Collapse, Panel, Space, UserOutlinedIcon } from 'components-antd';
import CurvedArrow from 'pages/FormBuilder/Icons/CurvedArrow';
import {
  AddFormCommentType,
  CommentUsersType,
  CustomOptions,
  EditFormCommentType,
  FormComment,
  FormCommentHistory,
  FormCommentThread,
} from 'types';

import styles from './styles.module.scss';

export interface CommentProps {
  commentUsers: CommentUsersType;
  notifyOptions: CustomOptions;
  permissionOptions: CustomOptions;
  permittedUsers: string[];
  setPermittedUsers: (permittedUsers: string[]) => void;
  notifiedUsers?: string[];
  setNotifiedUsers: (notifiedUsers?: string[]) => void;
  isSending: boolean;
  setSending: (isSending: boolean) => void;
  handleAddFormComment: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment?: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment?: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
  setFormCommentFieldId?: (fieldId: string) => void;
}

interface CommentThreadProps extends CommentProps {
  thread: FormCommentThread;
  index: number;
}

export const CommentThread = ({
  thread,
  index,
  commentUsers,
  notifyOptions,
  permissionOptions,
  permittedUsers,
  setPermittedUsers,
  notifiedUsers,
  setNotifiedUsers,
  isSending,
  setSending,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
  setFormCommentFieldId,
}: CommentThreadProps) => {
  const key = index + 1;

  const [isReplying, setIsReplying] = useState(false);
  const [activeKey, setActiveKey] = useState<string[]>([key.toString()]);

  const currentUserId = useSelector(getUserId);

  const renderCommentBox = (
    comment: FormComment,
    isHeader?: boolean,
    isResolved?: boolean,
    threadId?: number,
  ) => {
    const userInfo = commentUsers[comment.CreatorId || comment.CreatorEmail] || {
      email: comment.CreatorEmail,
    };
    return (
      <div>
        {!isHeader ? <div className={styles.offerTreeLine} /> : <></>}
        <FormCommentBox
          isPanelComment
          key={`comment-box-${comment.Id}`}
          isHeader={isHeader}
          isResolved={isResolved}
          threadId={threadId}
          isSending={isSending}
          setSending={setSending}
          userInfo={userInfo}
          comment={comment}
          commentUsers={commentUsers}
          notifyOptions={notifyOptions}
          handleEditFormComment={handleEditFormComment}
          handleDeleteFormComment={handleDeleteFormComment}
          handleToggleResolveThread={handleToggleResolveThread}
        />
      </div>
    );
  };

  interface ReplyBtnProps {
    onClick: () => void;
  }

  const ReplyBtn = ({ onClick }: ReplyBtnProps) => (
    <button className={styles.replyBtn} onClick={onClick}>
      <CurvedArrow /> Reply
    </button>
  );

  return (
    <div key={`thread-${thread.Id}-${index}`} className={styles.commentBoxSpacing}>
      <Collapse
        bordered={false}
        activeKey={activeKey}
        onChange={(value) => {
          setActiveKey(value as string[]);
          setFormCommentFieldId && setFormCommentFieldId(thread?.FieldId);
          if (!value.includes(key.toString())) {
            setIsReplying(false);
          }
        }}
        destroyInactivePanel
        className={styles.threadWindow}
      >
        <Panel
          key={key}
          showArrow={false}
          collapsible="header"
          header={
            <>
              {renderCommentBox(thread.Comments[0], true, thread.Resolved, thread.Id)}
              {getThreadResolvedState(thread.CommentHistory, commentUsers)}
            </>
          }
        >
          <div
            className={classNames({
              [styles.commentBoxPadding]: thread.Comments.length > 1,
            })}
          >
            {thread.Comments.length > 1 ? (
              <Space
                direction="vertical"
                size="large"
                className={classNames(styles.threadSpacing, {
                  [styles.resolvedThreads]: thread.Resolved,
                })}
              >
                {thread.Comments.slice(1).map((comment, index) => renderCommentBox(comment))}
              </Space>
            ) : (
              <></>
            )}
          </div>

          {isReplying && (
            <div className={styles.replyBox}>
              <div className={styles.replyBoxAvatar}>
                <Avatar
                  src={commentUsers[`${currentUserId}`]?.avatar}
                  size={32}
                  icon={<UserOutlinedIcon />}
                />
              </div>

              <FormCommentActionBar
                isNewThread={false}
                isSending={isSending}
                setSending={setSending}
                commentUsers={commentUsers}
                notifyOptions={notifyOptions}
                permissionOptions={permissionOptions}
                notifiedUsers={notifiedUsers}
                setNotifiedUsers={setNotifiedUsers}
                permittedUsers={permittedUsers}
                setPermittedUsers={setPermittedUsers}
                handleAddFormComment={handleAddFormComment}
                threadPermissions={thread.Permissions}
                threadId={thread.Id}
                onSend={() => {
                  setIsReplying(false);
                }}
                permissionSelectorClass={styles.formPanelCommentPermissionSelector}
                placeholder="Reply"
              />
            </div>
          )}
        </Panel>
      </Collapse>

      {!isReplying && !thread.Resolved && (
        <div className={styles.replyBtnWrapper}>
          <ReplyBtn
            onClick={() => {
              setActiveKey([key.toString()]);
              setIsReplying(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

const getThreadResolvedState = (
  commentHistory: FormCommentHistory[],
  commentUsers: CommentUsersType,
) => {
  const resolvedAction = [...commentHistory]
    .reverse()
    .find(
      (history) =>
        history.Action === CommentHistoryActions.Reopened ||
        history.Action === CommentHistoryActions.Resolved,
    );

  let resolverName;
  if (resolvedAction?.CreatorId) {
    resolverName = commentUsers[`${resolvedAction?.CreatorId}`]?.name;
  } else {
    resolverName = resolvedAction?.CreatorEmail;
  }

  return resolvedAction ? (
    <div className={styles.resolvedStatus}>
      <span>
        {resolvedAction.Action} by {resolverName}
      </span>
      <span>{moment(resolvedAction.CreatedDate).format('MM/DD/YYYY [at] hh:mm A')}</span>
    </div>
  ) : (
    <></>
  );
};
