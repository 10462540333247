import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash-es';

import styles from './styles.module.scss';

const ListingDetailPhotoCollage = ({ photos, onClickImage, setTab }) => {
  const [firstImage, ...otherImages] = photos;
  const onClick = useCallback(
    (id) => () => {
      onClickImage(id);
      setTab('Photos');
      document.body.style.overflow = 'hidden';
    },
    [onClickImage],
  );

  const subImagesClassName = useMemo(() => {
    if (otherImages.length === 1) {
      return styles.bigImage;
    }
    if (otherImages.length === 2) {
      return styles.middleImage;
    }
    return styles.image;
  }, [otherImages.length]);

  return (
    <div className={styles.wrapper}>
      <div onClick={onClick(0)} className={styles.bigImage}>
        <img src={`${firstImage}?width=500`} alt="home" />
      </div>
      <div className={styles.smallImages}>
        {map(otherImages, (img, index) => {
          if (index > 3) {
            return null;
          }
          return (
            <div onClick={onClick(index + 1)} key={img} className={subImagesClassName}>
              <img src={`${img}?width=250`} alt="home" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

ListingDetailPhotoCollage.propTypes = {
  onClickImage: PropTypes.func.isRequired,
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ListingDetailPhotoCollage;
