import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Icons = ({ testid, className, variant, onClick }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.SMILE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
              stroke="#676767"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 10H9.01"
              stroke="#676767"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 10H15.01"
              stroke="#676767"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.5 15C9.82588 15.3326 10.2148 15.5968 10.6441 15.7772C11.0734 15.9576 11.5344 16.0505 12 16.0505C12.4656 16.0505 12.9266 15.9576 13.3559 15.7772C13.7852 15.5968 14.1741 15.3326 14.5 15"
              stroke="#676767"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ATTACHMENT: {
        return (
          <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.0007 6.00045L6.50068 12.5005C6.10285 12.8983 5.87936 13.4378 5.87936 14.0005C5.87936 14.5631 6.10285 15.1026 6.50068 15.5005C6.8985 15.8983 7.43807 16.1218 8.00068 16.1218C8.56329 16.1218 9.10285 15.8983 9.50068 15.5005L16.0007 9.00045C16.7963 8.2048 17.2433 7.12567 17.2433 6.00045C17.2433 4.87523 16.7963 3.7961 16.0007 3.00045C15.205 2.2048 14.1259 1.75781 13.0007 1.75781C11.8755 1.75781 10.7963 2.2048 10.0007 3.00045L3.50068 9.50045C2.30721 10.6939 1.63672 12.3126 1.63672 14.0005C1.63672 15.6883 2.30721 17.307 3.50068 18.5005C4.69415 19.6939 6.31285 20.3644 8.00068 20.3644C9.68851 20.3644 11.3072 19.6939 12.5007 18.5005L19.0007 12.0005"
              stroke="#676767"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.FILE: {
        return (
          <svg
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 3C1 1.89543 1.89543 1 3 1H11.2459C11.515 1 11.7727 1.10847 11.9609 1.30088L16.715 6.16307C16.8977 6.34992 17 6.60087 17 6.86219V19C17 20.1046 16.1046 21 15 21H3C1.89543 21 1 20.1046 1 19V3Z"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M11 1V5C11 6.10457 11.8954 7 13 7H17" stroke="#525252" strokeWidth="2" />
          </svg>
        );
      }
      case Icons.CLOSE: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="18"
              height="18"
              rx="9"
              fill="#163C5A"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M13 7L7 13"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 7L13 13"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.AUTOCOMPLETE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_9662_70977)">
              <path
                d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                stroke="#676767"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 11.9998V13.4998C16 14.1629 16.2634 14.7988 16.7322 15.2676C17.2011 15.7365 17.837 15.9998 18.5 15.9998C19.163 15.9998 19.7989 15.7365 20.2678 15.2676C20.7366 14.7988 21 14.1629 21 13.4998V11.9998C21.0025 10.066 20.382 8.18269 19.2304 6.62904C18.0788 5.07539 16.4574 3.934 14.6064 3.37395C12.7554 2.81389 10.7732 2.86497 8.95343 3.51961C7.13371 4.17425 5.57324 5.39763 4.50321 7.00852C3.43317 8.61942 2.91047 10.5321 3.01255 12.4633C3.11463 14.3945 3.83605 16.2415 5.06995 17.7306C6.30385 19.2197 7.98459 20.2718 9.86319 20.7309C11.7418 21.1901 13.7183 21.0319 15.5 20.2799"
                stroke="#676767"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_9662_70977">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }

      case Icons.SEARCH: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.4444 15.8889C13.4513 15.8889 15.8889 13.4513 15.8889 10.4444C15.8889 7.43756 13.4513 5 10.4444 5C7.43756 5 5 7.43756 5 10.4444C5 13.4513 7.43756 15.8889 10.4444 15.8889Z"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9987 19.0002L14.332 14.3335"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.EVERYBODY: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <circle cx="7.99967" cy="8.00065" r="5.66667" stroke="#AAABAB" strokeWidth="2" />
            <path
              d="M7.33333 11C7.06667 10.7333 7 9.33331 7 8.66664C5.33333 8 3.94323 6.55317 3 4.66666C4.46856 1.72954 7.70208 1.70984 10.3333 2.66666C10.3833 2.68483 9.93333 4.13331 9.66667 4.66664C9.33333 5.33331 9 5.66666 8.33333 5.99999C7.66667 6.33332 6.66667 6.33331 7 7.33331C7.33333 8.33331 8.66667 7.99999 9.66667 8.33332C10.4667 8.59999 10.6667 9.2222 10.3333 9.66664C11.3333 9.66664 12.4 10.0667 12.6667 10.3333C13 10.6667 12 12.3333 11.6667 12.6666C11.4 12.9333 9.44444 13.7778 8.66667 14C8.17354 13.0137 8.16685 11.8335 7.33333 11Z"
              fill="#AAABAB"
            />
          </svg>
        );
      }
      case Icons.INTERNAL: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.8002 5.20039C8.8002 6.74679 7.54659 8.00039 6.0002 8.00039C4.4538 8.00039 3.2002 6.74679 3.2002 5.20039C3.2002 3.65399 4.4538 2.40039 6.0002 2.40039C7.54659 2.40039 8.8002 3.65399 8.8002 5.20039Z"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <path
              d="M11 8.00039C12.5464 8.00039 13.8 6.74679 13.8 5.20039C13.8 3.65399 12.5464 2.40039 11 2.40039"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.7998 13.2498C2.06156 11.941 3.26501 10.7998 4.59972 10.7998H7.39981C8.73451 10.7998 9.93805 11.941 10.1998 13.2498"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.1995 10.7998C13.5342 10.7998 14.7378 11.941 14.9995 13.2498"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ME: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clipPath="url(#clip0_34548_3444)">
              <path
                d="M11.332 7H4.66536C3.92898 7 3.33203 7.59695 3.33203 8.33333V12.6666C3.33203 13.403 3.92898 14 4.66536 14H11.332C12.0684 14 12.6654 13.403 12.6654 12.6666V8.33333C12.6654 7.59695 12.0684 7 11.332 7Z"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.9987 11.3333C8.36689 11.3333 8.66536 11.0349 8.66536 10.6667C8.66536 10.2985 8.36689 10 7.9987 10C7.63051 10 7.33203 10.2985 7.33203 10.6667C7.33203 11.0349 7.63051 11.3333 7.9987 11.3333Z"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.33203 7V4.66667C5.33203 3.95942 5.61298 3.28115 6.11308 2.78105C6.61318 2.28095 7.29145 2 7.9987 2C8.70594 2 9.38422 2.28095 9.88432 2.78105C10.3844 3.28115 10.6654 3.95942 10.6654 4.66667V7"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_34548_3444">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icon, className)}>
      {renderIcon()}
    </div>
  );
};

Icons.SMILE = 'smile';
Icons.ATTACHMENT = 'attachment';
Icons.FILE = 'file';
Icons.CLOSE = 'close';
Icons.AUTOCOMPLETE = 'autocomplete';
Icons.SEARCH = 'search';
Icons.EVERYBODY = 'everybody';
Icons.INTERNAL = 'internal';
Icons.ME = 'me';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};

export default Icons;
