import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, Option, Row, Col, Switch, ConfirmationModal, Cascader } from 'components-antd';
import { DEFAULT_QUESTION_META, FORM_QUESTION_TYPE } from 'app-constants';
import { SearchIcon } from 'components/Icons';
import QuestionIcon from 'pages/FormBuilder/Icons/QuestionIcon';
import { updateQuoteFormQuestionEffect } from 'store/effects/formBuilder';
import {
  getQuoteFormCurrentQuestionSelect,
  getQuoteFormMetaSelect,
} from 'store/selectors/formBuilder';
import { DateType, FormQuestionType, ListBoxType } from 'types';
import { getPopulateWithOptions } from 'utils';

import styles from './styles.module.scss';

export const QuoteSettingPane = () => {
  const dispatch = useDispatch();

  const { disabled } = useSelector(getQuoteFormMetaSelect);
  const question = useSelector(getQuoteFormCurrentQuestionSelect);

  const [required, setRequired] = useState<boolean>();
  const [dateRange, setDateRange] = useState<boolean>();
  const [multiSelect, setMultiSelect] = useState<boolean>();
  const [type, setType] = useState<FormQuestionType>();

  const [showWarning, setShowWaring] = useState(false);
  const [updatedType, setUpdatedType] = useState<FormQuestionType>();
  const [populateWith, setPopulateWith] = useState<string[] | null | undefined>(
    question?.PopulateWith,
  );

  const populateWithOptions = useCallback(
    () => (question?.Type ? getPopulateWithOptions(question?.Type) || [] : []),
    [question?.Type],
  );

  useEffect(() => {
    setType(question?.Type);
    setPopulateWith(question?.PopulateWith);
    setRequired(question?.Required);

    if (question?.Type === FORM_QUESTION_TYPE.Date)
      setDateRange((question?.Meta as DateType)?.DateRange);

    if (question?.Type === FORM_QUESTION_TYPE.ListBox)
      setMultiSelect((question?.Meta as ListBoxType)?.MultiSelect);
  }, [question]);

  const updateRequired = (checked: boolean) => {
    if (question) {
      const updatedQuestion = {
        ...question,
        Required: checked,
      };
      dispatch(updateQuoteFormQuestionEffect(updatedQuestion));
      setRequired(checked);
    }
  };

  const updateDateType = (checked: boolean) => {
    if (question) {
      const updatedQuestion = {
        ...question,
        Meta: {
          DateRange: checked,
        },
      };
      dispatch(updateQuoteFormQuestionEffect(updatedQuestion));
      setDateRange(checked);
    }
  };

  const updateMultiSelect = (checked: boolean) => {
    if (question) {
      const updatedQuestion = {
        ...question,
        Meta: {
          MultiSelect: checked,
          ListBoxOptions: (question?.Meta as ListBoxType)?.ListBoxOptions,
        },
        JumpLogic: checked ? null : question.JumpLogic,
      };
      dispatch(updateQuoteFormQuestionEffect(updatedQuestion));
      setMultiSelect(checked);
    }
  };

  const confirmQuestionTypeUpdate = (e) => {
    setUpdatedType(e);
    setShowWaring(true);
  };

  const questionTypeUpdate = () => {
    if (updatedType && question) {
      const updatedQuestion = {
        ...question,
        Type: updatedType,
        JumpLogic: undefined,
        PopulateWith: null,
        Meta: DEFAULT_QUESTION_META[updatedType],
        Title: question.Title || '',
      };

      dispatch(updateQuoteFormQuestionEffect(updatedQuestion));
      setType(updatedType);
      setPopulateWith([]);
    }
    clearWarningModal();
  };

  const questionPopulateWithUpdate = (options: string[]) => {
    if (question) {
      setPopulateWith(options);
      dispatch(updateQuoteFormQuestionEffect({ ...question, PopulateWith: options || null }));
    }
  };

  const clearWarningModal = () => {
    setUpdatedType(undefined);
    setShowWaring(false);
  };

  return (
    <div className={styles.settingPane} data-testid="quote-setting-page">
      <ConfirmationModal
        variant="Warning"
        open={showWarning}
        confirmText={
          <span>
            Are you sure you want to update question type to &quot;{updatedType}&quot;? <br />
            <br />
            <i>All question related changes will be lost.</i>
          </span>
        }
        onOk={questionTypeUpdate}
        onCancel={clearWarningModal}
      />
      <Row>
        <Col span={24}>
          <label className={styles.label}>Question Type</label>
        </Col>
        <Col span={24}>
          <Select
            disabled={disabled}
            large
            showArrow
            getPopupContainer={(triggerNode) => triggerNode}
            value={type}
            className={styles.selectBox}
            onChange={confirmQuestionTypeUpdate}
          >
            {Object.values(FORM_QUESTION_TYPE).map((key) => (
              <Option value={key} key={`question-type-${key}`}>
                <span className={styles.questionTypeOption}>
                  <QuestionIcon variant={key} className={styles.questionLogo} />
                  {key}
                </span>
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row className={styles.formRow}>
        <Col span={24}>
          <label className={styles.label}>Populate With</label>
        </Col>
        <Col span={24}>
          <Cascader
            large
            allowClear
            showSearch
            disabled={disabled}
            suffixIcon={<SearchIcon />}
            placeholder="Search sources"
            value={populateWith as any}
            options={populateWithOptions()}
            onChange={(value: any) => questionPopulateWithUpdate(value)}
          />
        </Col>
      </Row>

      <Row justify="space-between" className={styles.toggleRow}>
        <Col>
          <label className={styles.label}>Required</label>
        </Col>
        <Col>
          <Switch
            disabled={disabled}
            checked={required}
            onChange={updateRequired}
            data-testid="quote-setting-required"
          />
        </Col>
      </Row>

      {question?.Type === FORM_QUESTION_TYPE.Date && (
        <Row justify="space-between" className={styles.toggleRow}>
          <Col>
            <label className={styles.label}>Date Range</label>
          </Col>
          <Col>
            <Switch disabled={disabled} checked={dateRange} onChange={updateDateType} />
          </Col>
        </Row>
      )}

      {question?.Type === FORM_QUESTION_TYPE.ListBox && (
        <Row justify="space-between" className={styles.toggleRow}>
          <Col>
            <label className={styles.label}>Multi-select</label>
          </Col>
          <Col>
            <Switch disabled={disabled} checked={multiSelect} onChange={updateMultiSelect} />
          </Col>
        </Row>
      )}
    </div>
  );
};
