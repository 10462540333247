import { Modal } from 'components-antd';
import { MilestoneModalForm } from '../MilestoneModalForm';

import styles from './styles.module.scss';

interface MilestoneModalProps {
  onCloseModal: () => void;
  isOpen: boolean;
}

export const MilestoneModal = (props: MilestoneModalProps) => {
  const { isOpen, onCloseModal } = props;

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onCloseModal}
      maskClosable={false}
      destroyOnClose
      className={styles.taskModal}
    >
      <MilestoneModalForm onCancelModal={onCloseModal} />
    </Modal>
  );
};
