import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';
import { Icons } from 'pages/Workshop/Icons';
import { setTeamStatsAction } from 'store/actions/clarity';
import { AgentSelectorModal } from '../../Modals/AgentSelectorModal';

import styles from './styles.module.scss';

export const TeamFilter = () => {
  const dispatch = useDispatch();
  const [openAgentSelectorModal, setOpenAgentSelectorModal] = useState(false);
  const [dropdownFields, setDropdownFields] = useState([
    { itemValue: 'Just Me', icon: Icons.USER, iconColor: '#04a451' },
    { itemValue: 'Team', icon: Icons.TEAM_USER, iconColor: '#04a451' },
    // { itemValue: 'Agent', icon: Icons.USER, iconColor: '#04a451' },
    // { itemValue: 'Groups', icon: Icons.TEAM_USER, iconColor: '#04a451' },
  ]);
  const [value, setValue] = useState(dropdownFields[1]);

  const onChange = (e) => {
    const value = e.target.value.itemValue;
    dispatch(setTeamStatsAction({ value }));
    setValue(e.target.value);
    if (value === 'Agent') setOpenAgentSelectorModal(true);
  };

  const onSubmit = (option, selected) => {
    let dropdownOption;
    switch (option) {
      case 'Agent':
        dropdownOption = {
          itemValue: `${selected.FirstName} ${selected.LastName}`,
          icon: Icons.USER,
          iconColor: '#04a451',
          type: 'Agent',
          key: selected.Id,
        };
        break;
      default:
        return;
    }
    setValue(dropdownOption);
    setDropdownFields([...dropdownFields]);
  };

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => {
          return (
            <MenuItem key={idx}>
              <Radio value={{ itemValue: item.itemValue, icon: item.icon }} key={idx}>
                <div className={styles.option}>
                  <Icons variant={item.icon} className={styles.dropdownOptionIcon} />
                  <span className={styles.optionValue}>{item.itemValue}</span>
                </div>
                {value.itemValue === item.itemValue && <Check className={styles.checkIcon} />}
              </Radio>
            </MenuItem>
          );
        })}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.filterWrap)}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <Icons variant={value.icon} className={styles.optionIcon} />
          <span className={styles.optionValue}>{value.itemValue}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} size={16} />
        </a>
      </Dropdown>
      {value.itemValue === 'Agent' && (
        <AgentSelectorModal
          open={openAgentSelectorModal}
          onClose={() => setOpenAgentSelectorModal(false)}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};
