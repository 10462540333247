import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';

import {
  resetRequestQuoteAction,
  setPreFormQuestionsAction,
  requestPostCreateQuoteDraftAction,
  requestFormResultsAction,
  setRequestQuotePartnersAction,
  requestPatchQuoteDraftAction,
  updateQuoteQuestionAction,
  resumeDraftQuoteAction,
  setMultipleRequestQuotesAction,
  requestGetQuoteFormAction,
  updateQuoteFormResponseAction,
} from 'store/actions/quotes';
import { cloneDeep, get } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import { quoteRequestStatus } from 'settings/constants/quotes';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  preForm: {},
  quoteForm: {
    state: IDLE,
  },
  multiple: {
    modeEnable: false,
    partners: [],
  },
};

export default handleActions(
  {
    [requestPostCreateQuoteDraftAction]: (state, { payload }) => {
      const data = get(payload, 'data.result', initialData.data);
      if (data) {
        data.Status = quoteRequestStatus.Draft;
      }
      return {
        ...state,
        state: payload.state,
        data,
        quoteForm: {
          ...state.quoteForm,
          FormResponses: data?.FormResponses || [],
          quoteRequestDraftId: data?.Id,
        },
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [resumeDraftQuoteAction]: (state, { payload }) => {
      const data = get(payload, 'data.result', initialData.data);
      if (data) {
        data.Status = quoteRequestStatus.Draft;
      }
      return {
        ...state,
        state: payload.state,
        data,
        quoteForm: {
          ...state.quoteForm,
          FormResponses: data?.FormResponses || [],
          quoteRequestDraftId: data?.Id,
        },
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestPatchQuoteDraftAction]: (state, { payload }) => {
      const data = get(payload, 'data.result', initialData.data);
      if (data) {
        data.Status = quoteRequestStatus.Draft;
      }
      return {
        ...state,
        state: payload.state,
        data,
        quoteForm: {
          ...state.quoteForm,
          FormResponses: data?.FormResponses || [],
          quoteRequestDraftId: data?.Id,
        },
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [updateQuoteQuestionAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [setPreFormQuestionsAction]: (state, { payload }) => ({
      ...state,
      preForm: {
        ...state.preForm,
        ...payload,
      },
    }),
    [requestFormResultsAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result', state.data),
    }),
    [setRequestQuotePartnersAction]: (state, { payload }) => ({
      ...state,
      data: {
        ...(state.data || {}),
        Partners: payload.partners,
      },
    }),
    [setMultipleRequestQuotesAction]: (state, { payload }) => ({
      ...state,
      multiple: {
        ...state.multiple,
        ...payload,
      },
    }),
    [resetRequestQuoteAction]: () => cloneDeep(initialData),
    [appLogoutAction]: () => initialData,
    [requestGetQuoteFormAction]: (state, { payload }) => {
      const quoteForm = get(payload, 'data.result');

      return {
        ...state,
        quoteForm: {
          ...state.quoteForm,
          state: payload.state,
          Id: quoteForm?.Id,
          FormId: quoteForm?.FormId,
          FormQuestions: quoteForm?.FormQuestion,
          FormResponses: quoteForm?.FormResponses,
        },
      };
    },
    [updateQuoteFormResponseAction]: (state, { payload }) => {
      const updatedResponses = (state.quoteForm.FormResponses || []).map((element) => {
        if (element.UUID === payload.UUID) {
          return {
            ...element,
            Answer: payload.Answer,
          };
        }

        return element;
      });

      return {
        ...state,
        quoteForm: {
          ...state.quoteForm,
          FormResponses: updatedResponses,
        },
      };
    },
  },
  initialData,
);
