import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const SectionNameItem = ({ letter }) => (
  <div className={styles.sectionContainer}>
    <p className={styles.letter}>{letter}</p>
  </div>
);

SectionNameItem.propTypes = {
  letter: PropTypes.string,
};

SectionNameItem.defaultProps = {
  letter: '',
};

export default SectionNameItem;
