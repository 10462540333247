import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { getClientDefaultSearchSelector } from 'store/selectors/user';

import styles from './styles.module.scss';
import SummaryListItem from '../SummaryListItem';
import PriceRangeForm from '../../PriceRangeForm';
import LocationsForm from '../../LocationsForm';
import PropertyTypesForm from '../../PropertyTypesForm';
import BedsForm from '../../BedsForm';
import BathsForm from '../../BathsForm';

import { buyerSignupSteps } from 'pages/OnBoarding/components/Middle';
import LotSizeForm from '../../LotSizeForm';
import PropertySizeForm from '../../PropertySizeForm';
import HOAForm from '../../HOAForm';
import { lotSizeRangeOptions } from 'components/LotSizeMinMax';
import { LocationType } from 'types';

const SummaryList = (props) => {
  const { className, variant } = props;
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);

  const getMinMaxFormattedValue = useCallback(
    (propKey, prefix, suffix) => {
      const { Min, Max } = defaultClientSearch?.[propKey] || {};

      if (Max && !Min) {
        return (
          <div className={styles.values}>
            <span>Max:&nbsp;</span>
            &nbsp;
            <NumberFormat
              value={Max}
              prefix={prefix}
              suffix={suffix}
              thousandSeparator=","
              displayType="text"
            />
          </div>
        );
      }

      if (Min && !Max) {
        return (
          <div className={styles.values}>
            <span>Min:&nbsp;</span>
            &nbsp;
            <NumberFormat
              value={Min}
              prefix={prefix}
              suffix={suffix}
              thousandSeparator=","
              displayType="text"
            />
          </div>
        );
      }

      if (Max && Min) {
        return (
          <div className={styles.values}>
            &nbsp;
            <NumberFormat
              value={Min}
              prefix={prefix}
              suffix={suffix}
              thousandSeparator=","
              displayType="text"
            />
            &nbsp;
            <span>to</span>
            &nbsp;
            <NumberFormat
              value={Max}
              prefix={prefix}
              suffix={suffix}
              thousandSeparator=","
              displayType="text"
            />
          </div>
        );
      }

      return null;
    },
    [defaultClientSearch],
  );

  const getMinMaxLotSizeValue = useCallback(
    (propKey) => {
      const { Min, Max } = defaultClientSearch?.[propKey] || {};

      if (Min && !Max) {
        return (
          <div className={styles.values}>{`+${
            lotSizeRangeOptions.find(({ value }) => value === Min)?.name
          }`}</div>
        );
      }
      if (Max && !Min) {
        return (
          <div className={styles.values}>{`Up to ${
            lotSizeRangeOptions.find(({ value }) => value === Max)?.name
          }`}</div>
        );
      }
      if (Min && Max) {
        return (
          <div className={styles.values}>{`${
            lotSizeRangeOptions.find(({ value }) => value === Min)?.name
          } to ${lotSizeRangeOptions.find(({ value }) => value === Max)?.name}`}</div>
        );
      }

      return null;
    },
    [defaultClientSearch],
  );

  const getMinMaxValue = useCallback(
    (propKey) => {
      const { Min, Max } = defaultClientSearch?.[propKey] || {};

      if (Min && !Max) {
        return <div className={styles.values}>{`Min: ${Min}`}</div>;
      }
      if (Max && !Min) {
        return <div className={styles.values}>{`Max: ${Max}`}</div>;
      }
      if (Min && Max) {
        return <div className={styles.values}>{`${Min} to ${Max}`}</div>;
      }

      return null;
    },
    [defaultClientSearch],
  );

  const getMinMaxHoaValue = useCallback(() => {
    const { Min, Max } = defaultClientSearch?.['HOARange'] || {};
    const type = defaultClientSearch?.['HOAType'];

    if (type === 'None') return <div className={styles.values}>No HOA</div>;

    if (Min && !Max) {
      return (
        <div className={styles.values}>
          <NumberFormat value={Min} prefix={'$'} thousandSeparator="," displayType="text" />
        </div>
      );
    }
    if (Max && !Min) {
      return (
        <div className={styles.values}>
          {`Up to`}
          <NumberFormat value={Max} prefix={'$'} thousandSeparator="," displayType="text" />
        </div>
      );
    }
    if (Min && Max) {
      return (
        <div className={styles.values}>
          <NumberFormat value={Min} prefix={'$'} thousandSeparator="," displayType="text" />
          {` to `}
          <NumberFormat value={Max} prefix={'$'} thousandSeparator="," displayType="text" />
        </div>
      );
    }

    return null;
  }, [defaultClientSearch]);

  const hoa = useMemo(() => getMinMaxHoaValue(), [getMinMaxLotSizeValue]);

  const beds = useMemo(() => getMinMaxValue('NumBedroomsRange'), [getMinMaxValue]);

  const size = useMemo(
    () => getMinMaxFormattedValue('SquareFeetRange', '', ' sqft'),
    [getMinMaxFormattedValue],
  );

  const lotsize = useMemo(() => getMinMaxLotSizeValue('LotSizeRange'), [getMinMaxLotSizeValue]);

  const baths = useMemo(() => getMinMaxValue('NumBathroomsRange'), [getMinMaxValue]);

  const price = useMemo(
    () => getMinMaxFormattedValue('PriceRange', '$'),
    [getMinMaxFormattedValue],
  );

  const locations = useMemo(
    () =>
      defaultClientSearch?.Locations?.length ? (
        <div className={styles.chipsContainer}>
          {(defaultClientSearch?.Locations || []).map(({ PlaceName, Type }, idx) => (
            <div key={idx} className={styles.chip}>
              {Type === LocationType.Polygon ? 'Custom' : PlaceName}
            </div>
          ))}
        </div>
      ) : null,
    [defaultClientSearch],
  );

  const propertyTypes = useMemo(
    () =>
      defaultClientSearch?.HomeType?.length ? (
        <div className={styles.values}>{defaultClientSearch?.HomeType?.join(', ')}</div>
      ) : null,
    [defaultClientSearch],
  );

  return (
    <div className={classNames(styles.wrapper, styles[variant], className)}>
      <SummaryListItem
        label="Locations"
        placeholder="No Locations Selected"
        value={locations}
        stageIndex={buyerSignupSteps.SEARCH_LOCATIONS}
        component={LocationsForm}
      />
      <div className={styles.row}>
        <SummaryListItem
          label="Price"
          placeholder="No Min - No Max"
          value={price}
          component={PriceRangeForm}
          stageIndex={buyerSignupSteps.SEARCH_PRICE_RANGE}
        />
        <SummaryListItem
          label="Property Type"
          placeholder="No Min - No Max"
          value={propertyTypes}
          component={PropertyTypesForm}
          stageIndex={buyerSignupSteps.SEARCH_PROPERTY_TYPE}
        />
      </div>
      <div className={styles.row}>
        <SummaryListItem
          label="Beds"
          placeholder="No Min - No Max"
          value={beds}
          component={BedsForm}
          stageIndex={buyerSignupSteps.SEARCH_BEDS_AND_BATHS}
        />
        <SummaryListItem
          label="Baths"
          placeholder="No Min - No Max"
          value={baths}
          component={BathsForm}
          stageIndex={buyerSignupSteps.SEARCH_BEDS_AND_BATHS}
        />
      </div>
      <div className={styles.row}>
        <SummaryListItem
          label="Property Size"
          placeholder="No Min - No Max"
          value={size}
          component={PropertySizeForm}
          stageIndex={buyerSignupSteps.SEARCH_STORIES}
        />
        <SummaryListItem
          label="Lot Size"
          placeholder="No Min - No Max"
          value={lotsize}
          component={LotSizeForm}
          stageIndex={buyerSignupSteps.SEARCH_LOT_SIZE}
        />
      </div>
      <div className={styles.row}>
        <SummaryListItem
          label="HOA"
          placeholder="No Min - No Max"
          value={hoa}
          component={HOAForm}
          stageIndex={buyerSignupSteps.SEARCH_HOA}
        />
      </div>
    </div>
  );
};

export default SummaryList;
