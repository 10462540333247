import { createSelector } from 'reselect';
import { find, get } from 'lodash-es';

import { PENDING, IDLE } from 'settings/constants/apiState';
import { getUserId, getUserRolesMapSelector } from './user';

const localState = ({ singlePartner }) => singlePartner;

export const getLoadingPartnerData = createSelector(localState, ({ state, data, meta }) => ({
  isData: !!data,
  isPending: state === PENDING,
  isIdle: state === IDLE,
  metaId: get(meta, 'partnerId'),
}));

export const getPartnerObject = createSelector(
  localState,
  getUserRolesMapSelector,
  getUserId,
  ({ data }, { isAgent }, userId) => {
    if (isAgent && data) {
      return {
        ...data,
        isMyRecommended: !!find(data?.RecommendingAgents, ({ Id }) => Id === userId),
      };
    }
    return data;
  },
);

export const getPartnerBusinessNameAndId = createSelector(getPartnerObject, (data) => ({
  id: get(data, 'Id'),
  name: get(data, 'BusinessName'),
}));
