import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Properties/Feed/Icons';
import { Actions } from '../..';

import styles from './styles.module.scss';

const Item = (props) => {
  const { id, label, currentImportance, onChange, className } = props;

  return (
    <div testid="feature_item" className={classNames(styles.item, className)}>
      <div className={styles.inner}>
        <Icon className={classNames(styles.icon, styles[id])} variant={id} />
        <p className={styles.label}>{label}</p>
      </div>
      <Actions onChange={onChange} currentImportance={currentImportance} />
    </div>
  );
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  currentImportance: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Item.defaultProps = {
  className: '',
};

export default Item;
