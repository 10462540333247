import React from 'react';
import PropTypes from 'prop-types';

import { Applicants } from 'components';

const ApplicantsComponent = (props) => {
  const { className, propertyInfo } = props;

  return <Applicants propertyInfo={propertyInfo} className={className} />;
};

ApplicantsComponent.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    MatchedClientsCount: PropTypes.number,
  }).isRequired,
};

ApplicantsComponent.defaultProps = {
  className: '',
};

export default ApplicantsComponent;
