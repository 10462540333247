import { handleActions } from 'redux-actions';

import {
  openAddEditSchoolDrawerAction,
  saveAddEditSchoolDataAction,
  showWishlistTabAction,
  saveAddEditSchoolRawDataAction,
} from 'store/actions/searchResults';

const initialData = {
  open: false,
  mode: '',
  schoolData: {
    SpecificSchools: [],
    SpecificDistricts: [],
    NonSpecificConfig: [],
  },
  showWishlistTab: false,
  schoolTypes: [],
  specificItems: [],
};

export default handleActions(
  {
    [openAddEditSchoolDrawerAction as any]: (state, { payload }) => {
      return {
        ...state,
        open: payload.open || initialData.open,
        mode: payload.mode || initialData.mode,
      };
    },
    [saveAddEditSchoolDataAction as any]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        open: false,
        mode: '',
      };
    },
    [showWishlistTabAction as any]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [saveAddEditSchoolRawDataAction as any]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  initialData,
);
