import { createAction } from 'redux-actions';

export const setTransactionActivitiesFilterAction = createAction(
  'SET_TRANSACTION_ACTIVITIES_FILTER',
);
export const setTransactionActivitiesSortAction = createAction('SET_TRANSACTION_ACTIVITIES_SORT');
export const resetTransactionActivitiesFiltersAction = createAction(
  'RESET_TRANSACTION_ACTIVITIES_FILTERS',
);
export const resetTransactionActivitiesAction = createAction('RESET_TRANSACTION_ACTIVITIES');
