import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getDashboardPartnerPropertiesStats } from 'api/dashboard';

import { Card } from '../Card';
import { Transaction } from './Transaction';

import styles from './styles.module.scss';

export const QuickAccess = () => {
  const history = useHistory();
  const [properties, setProperties] = useState<any>([]);

  const fetchPartnerPropertiesStats = async () => {
    const stats = await getDashboardPartnerPropertiesStats();
    setProperties(stats?.data || []);
  };

  useEffect(() => {
    fetchPartnerPropertiesStats();
  }, []);

  const handleQuickAccess = () => {
    history.push(`/workshop/transactions`);
  };

  return (
    <Card
      cardTitle="Quick Access"
      onClickHandler={handleQuickAccess}
      isClickable={properties.length > 0 ? true : false}
      showFooter={true}
      showHeader={true}
    >
      {properties &&
        properties?.slice(0, 3).map((item, idx) => {
          return (
            <Transaction
              key={idx}
              transactionId={item?.Id}
              address={item?.Property?.Address}
              closingDate={item?.KeyDatesPrices?.UnderContractCloseDate}
            />
          );
        })}
    </Card>
  );
};
