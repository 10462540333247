import { SAVE_SEARCH_INSTANCES } from 'app-constants';
import { apiServer } from 'settings/web-services/api';

export function getSearchResults(cfg, options) {
  const { sortField, sortOrder, pageNum, pageSize, contextId } = options;
  const sort = `${sortField ? `sortField=${sortField}` : ''}${
    sortOrder && sortField ? `&sortOrder=${sortOrder}&` : ''
  }`;
  return apiServer.post(
    `/listings/search?${sort}pageNum=${pageNum}&pageSize=${pageSize}${
      contextId ? `&contextId=${contextId}` : ''
    }`,
    cfg,
  );
}

export function saveSearch(cfg) {
  return apiServer.post('/user/profile/savedSearches', cfg);
}

export function saveSearchInstance(cfg) {
  return apiServer.post(SAVE_SEARCH_INSTANCES, cfg);
}
