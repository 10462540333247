import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Wrapper } from 'components/index';
import {
  getIsAuthLoadingSelector,
  isNotificationMessagesLoadingSelector,
} from 'store/selectors/app';

import styles from './styles.module.scss';
import { useLoadGoogleMaps } from 'hooks/useLoadGoogleMaps';

const AppLoading = ({ children }) => {
  const loading = useSelector(getIsAuthLoadingSelector);
  const notificationMessagesLoading = useSelector(isNotificationMessagesLoadingSelector);
  const googleMapsScriptLoaded = useLoadGoogleMaps();

  if (loading || !googleMapsScriptLoaded) {
    return (
      <Wrapper
        className={styles.wrapper}
        isPending={loading || !googleMapsScriptLoaded || notificationMessagesLoading}
      />
    );
  }

  return children;
};

AppLoading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLoading;
