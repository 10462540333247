import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { preFormQuestionsIds } from 'settings/constants/preForm';
import { setPreFormQuestionsEffect } from 'store/effects/quotes';
import { getPreFormSelector } from 'store/selectors/requestQuote';
import { Question, ClientInput } from 'pages/RequestQuote/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

import styles from './styles.module.scss';

interface ClientNameProps {
  className: string;
  onPreFormSubmit: () => {};
}

const ClientName = ({ className, onPreFormSubmit }: ClientNameProps) => {
  const dispatch = useDispatch();

  const preForm = useSelector(getPreFormSelector);

  const onContinue = () => {
    if (preForm?.[preFormQuestionsIds.client]) {
      dispatch(
        setPreFormQuestionsEffect({
          client: { id: preForm.client.id || preForm.client.Id, ...preForm?.client },
          [preFormQuestionsIds.isClient]: true,
        }),
      );

      onPreFormSubmit();
    }
  };

  return (
    <div testid="client_name" className={classNames(styles.clientName, className)}>
      <Question>What’s the client’s name?</Question>
      <AnswersContainer>
        <ClientInput />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue disabled={!preForm?.client?.id} onClick={onContinue} />
      </ButtonsContainer>
    </div>
  );
};

export default ClientName;
