import PropTypes from 'prop-types';

import { Agents } from './Agents';
import linkExpired from './linkExpired.png';
import styles from './styles.module.scss';
import classNames from 'classnames';

const LinkExpired = ({ title, info, SellerAgent, className }) => (
  <div className={classNames(styles.container, className)}>
    <div className={styles.image}>
      <img src={linkExpired} alt="not found" />
    </div>
    <div className={styles.title}>{title}</div>
    <div className={styles.info}>{info}</div>
    {SellerAgent && (
      <div className={styles.detailArea}>
        <Agents SellerAgent={SellerAgent} />
      </div>
    )}
  </div>
);

LinkExpired.propTypes = {
  info: PropTypes.string,
  title: PropTypes.string,
  SellerAgent: PropTypes.JSON,
  className: PropTypes.string,
};

LinkExpired.defaultProps = {
  title: 'Link Expired',
  info: 'This link to schedule showings is no longer available.',
  SellerAgent: null,
  className: '',
};

export default LinkExpired;
