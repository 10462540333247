import axios from 'axios';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import fileDownload from 'js-file-download';

import { transformJSXResponse } from 'utils';
import { TitleValueTable } from 'components';
import { Add, Remove } from 'components/Icons';
import { Panel, Collapse } from 'components-antd';
import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import { getPropertyAddress, getPropertyTwoLinesAddress } from 'helpers';
import { getQuoteDetailsForPartnerSelector } from 'store/selectors/quoteDetails';

import styles from './styles.module.scss';

const Details = (props) => {
  const { className } = props;
  const { quote, formResponses, requestQuoteFile } = useSelector(getQuoteDetailsForPartnerSelector);

  const onDownload = () => {
    axios.get(requestQuoteFile?.Url, { responseType: 'blob' }).then((response) => {
      fileDownload(response.data, `${getPropertyAddress(quote?.Property?.Address)}.pdf`);
    });
  };

  const requestDetails = [
    {
      title: 'Property:',
      value: quote?.Property?.Address ? getPropertyTwoLinesAddress(quote.Property.Address) : '-',
    },
    ...formResponses.map((r) => ({
      title: `${r?.Question}:`,
      value: transformJSXResponse(r?.Answer || '-'),
    })),
  ];

  return (
    <div testid="details" className={classNames(styles.details, className)}>
      <Collapse
        bordered={false}
        accordion
        expandIcon={({ isActive }) =>
          isActive ? <Remove color={Remove.COLLAPSE} /> : <Add color={Add.COLLAPSE} />
        }
        expandIconPosition={'end'}
        className={styles.detailsCollapse}
        ghost={true}
        defaultActiveKey={1}
      >
        <Panel
          header={<p className={styles.detailsTitle}>Request Details</p>}
          key={1}
          className={styles.detailsPanel}
        >
          <TitleValueTable items={requestDetails} />
        </Panel>
      </Collapse>
      <Icon
        className={styles.icon}
        testid="download_icon"
        variant={Icon.DOWNLOAD}
        onClick={onDownload}
      />
    </div>
  );
};

Details.propTypes = {
  className: PropTypes.string,
};

Details.defaultProps = {
  className: '',
};

export default Details;
