import React, { useEffect } from 'react';

import { Input, Phone } from 'components';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { requestGetTeamAndOwnerDetailsEffect } from 'store/effects/adminPanel/teamDetails';
import styles from './styles.module.scss';
import { getTeamAndOwnerDetailsSelector } from 'store/selectors/adminPanel/teamDetails';
import classNames from 'classnames';

export const Onboarding: React.FC = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const teamId = params.id;

  const teamAndOwnerDetails = useSelector(getTeamAndOwnerDetailsSelector);

  const getAddress = (brokerageAddress) => {
    const addressLine1 = brokerageAddress?.Line1 || '';
    const city = brokerageAddress?.City || '';
    const state = brokerageAddress?.State || '';
    const zip = brokerageAddress?.Zip || '';
    const statePart = `${state} ${zip}`.trim();

    const sanitizedAddress = [addressLine1, city, statePart].filter((i) => !!i).join(', ');
    return sanitizedAddress;
  };

  useEffect(() => {
    dispatch(requestGetTeamAndOwnerDetailsEffect({ teamId }));
  }, []);

  if (!teamAndOwnerDetails) return null;

  let teamAddress = getAddress(teamAndOwnerDetails?.teamDetails?.Address);
  let brokerageAddress = getAddress(teamAndOwnerDetails?.teamDetails?.Brokerage?.Address);
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.heading}>Contact Details</div>
        <div className={styles.inputContainer}>
          <div className={styles.inputWrapper}>
            <Input
              className={styles.formInput}
              name="firstName"
              type="text"
              value={teamAndOwnerDetails.owner.FirstName}
              // value={formik.values.firstName}
              // onChange={formik.handleChange}
              // error={formik.touched.firstName ? formik.errors.firstName : ''}
              // disabled={isPending}
              variant={Input.LIGHT_ROUND}
              label="First Name"
              disabled
            />
            <Input
              className={styles.formInput}
              name="lastName"
              type="text"
              value={teamAndOwnerDetails.owner.LastName}
              // value={formik.values.firstName}
              // onChange={formik.handleChange}
              // error={formik.touched.firstName ? formik.errors.firstName : ''}
              // disabled={isPending}
              variant={Input.LIGHT_ROUND}
              label="Last Name"
              disabled
            />
          </div>
          <div className={styles.inputWrapper}>
            <Input
              className={styles.formInput}
              name="email"
              type="email"
              value={teamAndOwnerDetails.owner.Email}
              // value={formik.values.firstName}
              // onChange={formik.handleChange}
              // error={formik.touched.firstName ? formik.errors.firstName : ''}
              // disabled={isPending}
              variant={Input.LIGHT_ROUND}
              label="Email Address"
              disabled
            />
            <Phone
              className={styles.formInput}
              inputClassName={styles.partnerPhoneWrapper}
              name="phone"
              //   onChange={(event, value, country) => {
              //     formik.setFieldValue('phone', value);
              //   }}
              //   error={formik.errors.phone}
              //   disabled={isPending}
              //   variant={Phone.LIGHT_ROUND}
              //   value={formik.values.phone}
              value={teamAndOwnerDetails.owner.Phones?.[0]?.PhoneNumber}
              testid="phone"
              placeholder="(---) --- ----"
              label="Phone Number"
              variant={Phone.LIGHT_ROUND}
              disabled
            />
          </div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.section}>
        <div className={styles.heading}>Team & Brokerage</div>
        <div className={styles.inputContainer}>
          <div className={styles.inputWrapper}>
            <Input
              className={styles.formInput}
              name="teamName"
              value={teamAndOwnerDetails?.teamDetails?.Team?.Name}
              // value={formik.values.firstName}
              // onChange={formik.handleChange}
              // error={formik.touched.firstName ? formik.errors.firstName : ''}
              // disabled={isPending}
              variant={Input.LIGHT_ROUND}
              label="Team Name"
              disabled
            />
            <Input
              className={styles.formInput}
              name="teamAddress"
              value={teamAddress}
              // value={formik.values.firstName}
              // onChange={formik.handleChange}
              // error={formik.touched.firstName ? formik.errors.firstName : ''}
              // disabled={isPending}
              variant={Input.LIGHT_ROUND}
              label="Team Address"
              disabled
            />
          </div>
          <div className={styles.inputWrapper}>
            <Input
              className={styles.formInput}
              name="brokerageName"
              value={teamAndOwnerDetails?.teamDetails?.Brokerage?.Name}
              // value={formik.values.firstName}
              // onChange={formik.handleChange}
              // error={formik.touched.firstName ? formik.errors.firstName : ''}
              // disabled={isPending}
              testid="first_name"
              variant={Input.LIGHT_ROUND}
              label="Brokerage"
              disabled
            />
            <Input
              className={styles.formInput}
              name="brokerageAddress"
              value={brokerageAddress}
              // value={formik.values.firstName}
              // onChange={formik.handleChange}
              // error={formik.touched.firstName ? formik.errors.firstName : ''}
              // disabled={isPending}
              variant={Input.LIGHT_ROUND}
              label="Brokerage Address"
              disabled
            />
          </div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.section}>
        <div className={styles.heading}>MLS</div>
        <div className={classNames(styles.inputContainer, styles.grid)}>
          {teamAndOwnerDetails?.owner.Agent?.SignupDetails?.mls?.map((mlsItem) => (
            <MLSCard
              title={mlsItem?.name}
              brokerEmail={mlsItem?.brokerageEmail}
              brokerage={mlsItem?.brokerageName}
              managingBroker={mlsItem?.managingBroker}
              mlsId={mlsItem?.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

type MLSCardProps = {
  mlsId: string;
  brokerage: string;
  brokerEmail: string;
  managingBroker: string;
  title: string;
};

const MLSCard: React.FC<MLSCardProps> = ({
  mlsId,
  brokerage,
  brokerEmail,
  managingBroker,
  title,
}) => {
  return (
    <div className={styles.mlsWrapper}>
      <div className={styles.heading}>{title}</div>
      <div className={styles.rowWrapper}>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldsContainer}>
            <div className={styles.mlsheading}>MLS ID</div>
            <div className={styles.info}>{mlsId}</div>
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.mlsheading}>Brokerage on Record With MLS</div>
            <div className={styles.info}>{brokerage}</div>
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldsContainer}>
            <div className={styles.mlsheading}>Broker Email</div>
            <div className={styles.info}>{brokerEmail}</div>
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.mlsheading}>Manging Broker</div>
            <div className={styles.info}>{managingBroker}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
