import React, { useEffect, useState } from 'react';
import { uniqBy } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Locations } from 'components';
import { sortLocations } from 'helpers';
import { ServiceIcons, ServiceIconTypes } from 'pages/Services/icons';
import { Location } from 'types';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { getUserRoleSelector } from 'store/selectors/user';
import { updateConciergeLocationLocalEffect } from 'store/effects/concierge';

import styles from './styles.module.scss';
import { AGENT } from 'settings/constants/roles';

interface ServiceLocationProps {
  className?: string;
}

export const ServiceLocation: React.FC<ServiceLocationProps> = ({ className }) => {
  const dispatch = useDispatch();
  const [location, setLocation] = useState<Location[]>();
  const [formErrors, setFormErrors] = useState<string>();

  const { areasOfOperation } = useSelector(getConciergeSearchSelector);
  const userRole = useSelector(getUserRoleSelector);

  const updateConciergeLocation = (updatedData) => {
    if (updatedData) dispatch(updateConciergeLocationLocalEffect(updatedData));
    return;
  };

  const updateLocation = (result, prepareData) => {
    let updatedData = location?.length ? [...location] : undefined;

    let areasOfOperation = prepareData?.length
      ? (userRole === AGENT ? prepareData : [...prepareData, ...(updatedData || [])]) || []
      : updatedData || [];
    if (result?.deleteItemId) {
      areasOfOperation = areasOfOperation.filter(
        ({ ProviderPlaceId: itemId }) => itemId !== result.deleteItemId,
      );
    }
    areasOfOperation = uniqBy(areasOfOperation, 'ProviderPlaceId');
    areasOfOperation.sort(sortLocations);
    updatedData = [...areasOfOperation];

    if (updatedData) updateConciergeLocation(updatedData);
  };

  useEffect(() => {
    setLocation(areasOfOperation);
  }, [areasOfOperation]);

  return (
    <span className={classNames(styles.locationWrapper, className)}>
      <Locations
        multiple
        allowStates={true}
        getStates={true}
        onResult={(result, prepareData) => updateLocation(result, prepareData)}
        value={location?.length ? location : undefined}
        error={formErrors}
        placeholderIcon={<ServiceIcons icon={ServiceIconTypes.location} />}
        placeholder={'Enter city, ZIP, or neighborhood'}
        activeInputClassName={styles.activeInputClassName}
        valuesWrapperClassName={styles.valuesWrapperClassName}
        placeholderClassName={styles.placeholderClass}
        valuesContainerClassName={styles.valuesContainer}
        searchIcon={!!location?.length}
        variant={Locations.ROUND}
      />
    </span>
  );
};
