import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { upperCase } from 'lodash-es';
import classNames from 'classnames';

import { priceConverter } from 'helpers';
import { useOutsideClick } from 'hooks';
import { AGENT, TEAM_ADMIN } from 'settings/constants/roles';
import {
  appSetShowProfileExpandedSectionAction,
  appManageClientDrawerAction,
  appDrawerSetAgentIdAction,
  appSetConnectionTypeAction,
} from 'store/actions/app';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';
import { getUserId } from 'store/selectors/user';
import { getAgentDetailsEffect } from 'store/effects';
import { routes } from 'settings/navigation/routes';

import { ArrowDown, Check } from 'components/Icons';
import { Row, Avatar, Table, Radio } from 'components-antd';
import type { RadioChangeEvent } from 'antd';

import styles from './styles.module.scss';

enum SymphonyClosedVolume {
  MTD = 'MTD',
  QTD = 'QTD',
  YTD = 'YTD',
}

const filterFields = [
  { itemValue: SymphonyClosedVolume.MTD, iconColor: '#04a451' },
  { itemValue: SymphonyClosedVolume.QTD, iconColor: '#04a451' },
  { itemValue: SymphonyClosedVolume.YTD, iconColor: '#04a451' },
];

export const Content = (props) => {
  const { data } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const [tableData, setTableData] = useState();
  const [tableFilter, setTableFilter] = useState(SymphonyClosedVolume.MTD);
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const buttonRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const onViewDetails = (agentId, tab, type = '') => {
    onClickAgent(agentId);
    addQueryParams(tab, type);
  };

  const onClickAgent = (agentId) => {
    dispatch(appSetConnectionTypeAction({ type: AGENT_CONNECTIONS.Team }));
    dispatch(
      appDrawerSetAgentIdAction({
        id: agentId,
        open: false,
        showExpandedSection: true,
      }),
    );
  };

  const addQueryParams = (tab, type = '') => {
    history.replace(`${routes.symphony}?tab=${tab}&type=${type}`);
  };

  useOutsideClick([buttonRef, optionsRef], () => {
    setIsFilterDropdownOpen(false);
  });

  const onFilterChange = (e: RadioChangeEvent) => {
    const filter = e.target.value;
    setTableFilter(filter);
  };

  const renderFilterDropdown = (e) => {
    e.stopPropagation();
    setIsFilterDropdownOpen(!isFilterDropdownOpen);
  };

  const columns = [
    {
      title: 'Team',
      key: 'team',
      dataIndex: 'FirstName',
      sorter: (a, b) => {
        let name = `${a?.FirstName} ${a?.LastName}`;
        let secondName = `${b?.FirstName} ${b?.LastName}`;
        return name.localeCompare(secondName);
      },
      render: (_, { Id, FirstName, LastName, Title, AvatarUrl, TeamRole, IsActive }) => {
        const avatar = AvatarUrl;
        const placeholder = upperCase(`${FirstName?.[0] || ''}${LastName?.[0] || ''}`);
        return (
          <div
            className={classNames(styles.teamField, { [styles.inactive]: !IsActive })}
            onClick={() => onClickAgent(Id)}
          >
            <Avatar className={styles.avatar} size={40} src={avatar}>
              {placeholder}
            </Avatar>
            <div className={styles.details}>
              <div className={styles.name}>
                <p>
                  {FirstName} {LastName}
                  {Id === userId ? ' (Me)' : ''}
                </p>
                {TeamRole === TEAM_ADMIN ? (
                  <div className={styles.admin}>
                    <span>Admin</span>
                  </div>
                ) : null}
              </div>
              <span className={styles.title}>
                {Title?.Title || AGENT} {!IsActive ? '(Inactive)' : ''}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Under Contract',
      key: 'underContractVolume',
      dataIndex: 'UnderContractVolume',
      width: 180,
      sorter: (a, b) => {
        if (a?.UnderContractVolume < b?.UnderContractVolume) return -1;
        if (a?.UnderContractVolume > b?.UnderContractVolume) return 1;
        return 0;
      },
      render: (_, { Id, UnderContractVolume = 0, UnderContractCount = 0, IsActive }) => (
        <p
          className={classNames(styles.price, { [styles.inactive]: !IsActive })}
          onClick={() => onViewDetails(Id, 3, 'UnderContract')}
        >
          {priceConverter(UnderContractVolume)} <span>({UnderContractCount})</span>
        </p>
      ),
    },
    {
      title: 'Listed',
      key: 'listedVolume',
      dataIndex: 'ListedVolume',
      width: 180,
      sorter: (a, b) => {
        if (a?.ListedVolume < b?.ListedVolume) return -1;
        if (a?.ListedVolume > b?.ListedVolume) return 1;
        return 0;
      },
      render: (_, { Id, ListedVolume = 0, ListedCount = 0, IsActive }) => (
        <p
          className={classNames(styles.price, { [styles.inactive]: !IsActive })}
          onClick={() => onViewDetails(Id, 3, 'Listed')}
        >
          {priceConverter(ListedVolume)} <span>({ListedCount})</span>
        </p>
      ),
    },
    {
      title: 'Closed',
      key: 'closedVolume',
      dataIndex: 'ClosedVolume',
      width: 180,
      filtered: true,
      filterIcon: () => {
        return (
          <div
            className={classNames(styles.filterButton, {
              [styles.filterOpen]: isFilterDropdownOpen,
            })}
            onClick={renderFilterDropdown}
            ref={buttonRef}
          >
            <span className={styles.filterText}>{tableFilter}</span>
            <ArrowDown className={styles.filterArrow} />
          </div>
        );
      },
      filterDropdown: () => {
        return (
          <div className={styles.underContractFilter} ref={optionsRef}>
            <Radio.Group
              onChange={onFilterChange}
              value={tableFilter}
              className={styles.filterItems}
            >
              {filterFields.map((item, idx) => {
                return (
                  <Radio
                    value={item.itemValue}
                    key={idx}
                    onClick={() => setIsFilterDropdownOpen(false)}
                  >
                    {item.itemValue} <Check className={styles.icon} color={item.iconColor} />
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
        );
      },
      filterDropdownOpen: isFilterDropdownOpen,
      render: (
        _,
        { Id, ClosedVolumeMTD = 0, ClosedVolumeQTD = 0, ClosedVolumeYTD = 0, IsActive },
      ) => (
        <p
          className={classNames(styles.price, { [styles.inactive]: !IsActive })}
          onClick={() => onViewDetails(Id, 3, 'Closed')}
        >
          {priceConverter(
            tableFilter === SymphonyClosedVolume.MTD
              ? ClosedVolumeMTD
              : tableFilter === SymphonyClosedVolume.QTD
              ? ClosedVolumeQTD
              : ClosedVolumeYTD,
          )}
        </p>
      ),
    },
    {
      title: 'Active Buyers',
      key: 'activeBuyersCount',
      dataIndex: 'ActiveBuyersCount',
      width: 160,
      render: (_, { Id, ActiveBuyersCount = 0, IsActive }) => (
        <p
          className={classNames(styles.count, { [styles.inactive]: !IsActive })}
          onClick={() => onViewDetails(Id, 1)}
        >
          {ActiveBuyersCount}
        </p>
      ),
    },
    {
      title: 'Tasks',
      key: 'tasks',
      dataIndex: 'Tasks',
      width: 160,
      render: (_, { Id, TasksCount = 0, OverdueCount = 0, IsActive }) => (
        <div
          className={classNames(styles.tasks, { [styles.inactive]: !IsActive })}
          onClick={() => onViewDetails(Id, 2)}
        >
          <p className={styles.count}>{TasksCount}</p>
          {OverdueCount ? (
            <div className={styles.overdue}>
              <span>{OverdueCount} Overdue</span>
            </div>
          ) : null}
        </div>
      ),
    },
    {
      title: 'Alerts',
      key: 'alertsCount',
      dataIndex: 'AlertsCount',
      width: 160,
      render: (_, { AlertsCount = 0, IsActive }) => (
        <p className={classNames(styles.count, { [styles.inactive]: !IsActive })}>{AlertsCount}</p>
      ),
    },
  ];

  return (
    <div className={styles.symphonyPage}>
      <div className={styles.content}>
        <Table
          loading={false}
          className={styles.symphonyTable}
          bordered
          columns={columns}
          dataSource={tableData}
          pagination={false}
        />
      </div>
    </div>
  );
};
