import Api from 'store/effects/core/api';
import {
  requestGetAgentTeamDetailAction,
  reassignShareClientsAction,
  getAgentDocumentsAction,
  deleteAgentDocumentAction,
} from 'store/actions/agentTeamDetail';
import {
  getAgentTeamDetail,
  getAllAgentDocuments,
  reassignShareClients,
  deleteAgentDocument,
} from 'api/agentTeamDetail';

export const getAgentTeamDetailEffect = (cfg, options, cb) => {
  const requestParams = { action: requestGetAgentTeamDetailAction, method: getAgentTeamDetail };
  const sendRequest = Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const reassignShareClientsEffect = (cfg, options, cb) => {
  const requestParams = { action: reassignShareClientsAction, method: reassignShareClients };
  const sendRequest = Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getAgentDocumentsEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: getAgentDocumentsAction,
    method: getAllAgentDocuments,
  });

  return sendRequest(cfg, {}, cb);
};

export const deleteAgentDocumentEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: deleteAgentDocumentAction,
    method: deleteAgentDocument,
  });

  return sendRequest(cfg, {}, cb);
};
