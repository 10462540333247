import { apiServer } from 'settings/web-services/api';

export function putTransactionRoleData(cfg, { id }) {
  return apiServer.put(`/transaction/participants/roles/${id}`, cfg);
}

export function deleteDeactivateTransactionRole({ id }) {
  return apiServer.delete(`/transaction/participants/roles/${id}`);
}

export function postTransactionRoleData(cfg) {
  return apiServer.post('/transaction/participants/roles', cfg);
}

export function putActivateTransactionRole({ id }) {
  return apiServer.put(`/transaction/participants/roles/${id}`, {
    IsActive: true,
  });
}
