import { handleActions } from 'redux-actions';

import { propertySeenOnMap } from 'store/actions/feed';

const initialData = [];

export default handleActions(
  {
    [propertySeenOnMap]: (state, { payload }) => [...state, payload.id],
  },
  initialData,
);
