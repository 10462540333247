import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from 'components';
import { Plus } from 'components/Icons';
import PartnersModal from './PartnersModal';
import Name from '../Name';

import styles from './styles.module.scss';

const AddPartner = (props) => {
  const { className } = props;
  const [openModal, setOpenModal] = useState(false);

  const onAddPartner = () => {
    setOpenModal(true);
  };

  return (
    <div className={classNames(styles.addPartner, className)}>
      <div testid="add_partner" onClick={onAddPartner} className={styles.inner}>
        <Avatar
          className={styles.addWrapper}
          avatarClassName={styles.add}
          src={null}
          placeholder={<Plus className={styles.plusIcon} />}
        />
        <Name>Add</Name>
      </div>
      <PartnersModal isOpen={openModal} onClose={() => setOpenModal(false)} />
    </div>
  );
};

AddPartner.propTypes = {
  className: PropTypes.string,
};

AddPartner.defaultProps = {
  className: '',
};

export default AddPartner;
