import { getAllUsers, getAnyUserById, updateUser } from 'api/admin/users';
import { activateClient, deactivateClient } from 'api/clientsList';
import Api from 'store/effects/core/api';
import {
  requestGetUserDashboardAction,
  requestGetUsersDashboardAction,
  requestPostActivateUserAction,
  requestPostDeactivateUserAction,
  searchUsersDashboardAction,
  sortUsersDashboardAction,
  requestPutUserDashboardAction,
} from 'store/actions/adminPanel';
import { getState } from 'store/index';

export const getAllUsersDashboardEffect = (cfg, options, cb) => {
  const {
    adminPanel: {
      users: { filters, sort },
    },
  } = getState();
  const requestParams = {
    action: requestGetUsersDashboardAction,
    method: getAllUsers,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(
    {
      ...filters,
      roles: filters?.roles?.length ? filters.roles.map(({ value }) => value) : undefined,
      sortField: sort?.fields?.[0] || undefined,
      sortOrder: sort?.order?.toLowerCase() || undefined,
    },
    options,
    cb,
  );
};

export const getUserDashboardEffect = ({ id }, options, cb) => {
  const requestParams = { action: requestGetUserDashboardAction, method: getAnyUserById };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest({ id }, options, cb);
};

export const searchUsersDashboardEffect = (cfg) => (dispatch) => {
  dispatch(searchUsersDashboardAction(cfg));
  return dispatch(getAllUsersDashboardEffect());
};

export const sortUsersEffect = (cfg) => (dispatch) => {
  dispatch(sortUsersDashboardAction(cfg));
  return dispatch(getAllUsersDashboardEffect());
};

export const deactivateUserEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestPostDeactivateUserAction,
    method: deactivateClient,
  });

  return sendRequest(cfg, options, (err, response, dispatch) => cb(err, response, dispatch));
};

export const activateUserEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestPostActivateUserAction,
    method: activateClient,
  });

  return sendRequest(cfg, options, (err, response, dispatch) => cb(err, response, dispatch));
};

export const updateUserEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestPutUserDashboardAction,
    method: updateUser,
  });

  return sendRequest(cfg, options, (err, response, dispatch) => cb(err, response, dispatch));
};
