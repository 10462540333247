import { Calendar as CalendarIcon, Close as CancelIcon } from 'components/Icons';
import { AppointmentStatus, ApprovalAction } from 'types';

import styles from './styles.module.scss';

export const Footer = (props) => {
  let { processAction, onCreateNew, appointmentStatus } = props;

  const canView = {
    cancelBtn: false,
    rescheduleBtn: false,
    createNew: false,
  };

  if (appointmentStatus) {
    switch (appointmentStatus) {
      case AppointmentStatus.Pending:
        canView.cancelBtn = true;
        canView.rescheduleBtn = true;
        break;
      case AppointmentStatus.Canceled:
        canView.createNew = true;
        break;
      case AppointmentStatus.Upcoming:
        canView.cancelBtn = true;
        canView.rescheduleBtn = true;
        break;
      default:
        canView;
        break;
    }
  }

  return (
    <div>
      <div className={styles.footer}>
        {canView.rescheduleBtn && (
          <div
            testid="reschedule_button"
            className={styles.reschedule}
            onClick={() => {
              processAction(ApprovalAction.Rescheduled);
            }}
          >
            <CalendarIcon className={styles.rescheduleIcon} />
            <span className={styles.text}>Reschedule</span>
          </div>
        )}
        {canView.createNew && (
          <div testid="createNew_button" className={styles.reschedule} onClick={onCreateNew}>
            <CalendarIcon className={styles.rescheduleIcon} />
            <span>Create New</span>
          </div>
        )}
        {canView.cancelBtn && (
          <div
            testid="cancel_button"
            className={styles.cancel}
            onClick={() => {
              processAction(ApprovalAction.Cancel);
            }}
          >
            <CancelIcon className={styles.cancelIcon} />
            <span className={styles.text}>Cancel</span>
          </div>
        )}
      </div>
    </div>
  );
};
