import classNames from 'classnames';

import { ActiveTransactions } from './components/ActiveTransactions';
import { AssignedTo } from './components/AssignedTo';

import styles from './styles.module.scss';

export const DrawerInfoCard = (props) => {
  const { sectionTitle, isActiveTransaction, isAssignedTo, data, clientInfo, className } = props;

  return (
    <div className={classNames(styles.transactionsWrap, className)}>
      <h3 className={styles.sectionTitle}>{sectionTitle}</h3>
      <div className={styles.infoCardWrap}>
        {isActiveTransaction && (
          <ActiveTransactions wrapperClassName={styles.activeTransactionsWrap} data={data} />
        )}
        {isAssignedTo && <AssignedTo clientInfo={clientInfo} />}
      </div>
    </div>
  );
};
