import { useSelector } from 'react-redux';

import { Checkbox } from 'components-antd';
import TaskStatus from 'pages/Workshop/Transactions/TransactionTasks/components/Task/View/components/Actions/Status';
import { getUserId } from 'store/selectors/user';
import { getTransactionAccessByTaskSelector } from 'store/selectors/transaction';
import styles from './styles.module.scss';

export function tableColumns(handleSelection, selectedTaskIds, isAllSelected, cancellationTasks) {
  const userId = useSelector(getUserId);
  const { hasFullAccess } = useSelector(getTransactionAccessByTaskSelector);
  const me = 'Me';

  const columns = [
    {
      title: 'Task',
      key: 'task',
      dataIndex: 'Title',
      width: 300,
      sorter: (a, b) => {
        if (a.Title < b.Title) return -1;
        if (a.Title > b.Title) return 1;
        return 0;
      },
      render: (text, row) => {
        return (
          <div className={styles.taskWrap}>
            <div className={styles.transactionTask}>
              <div className={styles.info}>
                <span className={styles.line1}>{text}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'Status',
      width: 150,
      sorter: (a, b) => {
        if (a.Status < b.Status) return -1;
        if (a.Status > b.Status) return 1;
        return 0;
      },
      render: (_, { Id, TransactionId, Status, Category, transactionActionId }) => {
        const taskStatusProps = {
          ...(transactionActionId && {
            transactionActionId,
          }),
        };

        return (
          <TaskStatus
            taskId={Id}
            transactionId={TransactionId}
            status={Status}
            category={Category?.Name}
            fullAccess={hasFullAccess(TransactionId)}
            hideDropdown={cancellationTasks}
            {...taskStatusProps}
          />
        );
      },
    },
    {
      title: 'Assigned To',
      key: 'assignedTo',
      dataIndex: 'AssigneeList',
      width: 180,
      sorter: (a, b) => {
        const aFirstName = a.AssigneeList?.[0]?.FirstName || a.AssigneeList?.[0]?.Email;
        const bFirstName = b.AssigneeList?.[0]?.FirstName || b.AssigneeList?.[0]?.Email;
        if (aFirstName < bFirstName) return -1;
        if (aFirstName > bFirstName) return 1;
        return 0;
      },
      render: (_, { Id, AssigneeList }) => {
        const assignee = AssigneeList?.find((obj) => obj?.Id === userId) || AssigneeList?.[0] || {};
        const name =
          assignee?.Id === userId
            ? me
            : assignee?.FirstName
            ? `${assignee?.FirstName} ${assignee?.LastName}`
            : assignee?.Email;
        return (
          <div className={styles.tagContainer}>
            <span className={styles.assigneeName}>
              {name} {AssigneeList?.length > 1 ? ` + ${AssigneeList.length - 1}` : ''}
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <div className={styles.actionSelectContainer}>
          <div className={styles.multiSelect}>
            <Checkbox
              className={styles.checkbox}
              checked={isAllSelected}
              onClick={(e) => {
                e.stopPropagation();
                handleSelection('all', e.target.checked);
              }}
            />
          </div>
        </div>
      ),
      key: 'action',
      render: (item) => {
        return (
          <div className={styles.actionSelectContainer}>
            <div className={styles.multiSelect}>
              <Checkbox
                className={styles.checkbox}
                checked={selectedTaskIds.includes(item.Id)}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelection(item.Id, e.target.checked);
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return columns;
}
