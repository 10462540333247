import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Close } from 'components/Icons';

import styles from './styles.module.scss';

const Cancel = ({ className, onClick }) => (
  <Close testid="cancel" onClick={onClick} className={classNames(styles.cancel, className)} />
);

Cancel.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Cancel.defaultProps = {
  className: '',
  onClick: () => {},
};

export default Cancel;
