import { createAction } from 'redux-actions';

export const requestGetTaskCategoriesDashboardAction = createAction(
  'REQUEST/GET_TASK_CATEGORIES_DASHBOARD',
);
export const requestGetTaskCategoryDashboardAction = createAction(
  'REQUEST/GET_TASK_CATEGORY_DASHBOARD',
);
export const requestPutTaskCategoryDashboardAction = createAction(
  'REQUEST/PUT_TASK_CATEGORY_DASHBOARD',
);
export const requestPostTaskCategoryDashboardAction = createAction(
  'REQUEST/POST_TASK_CATEGORY_DASHBOARD',
);
export const requestDeleteTaskCategoryDashboardAction = createAction(
  'REQUEST/DELETE_TASK_CATEGORY_DASHBOARD',
);
export const requestPutActivateTaskCategoryDashboardAction = createAction(
  'REQUEST/PUT_ACTIVATE_TASK_CATEGORY_DASHBOARD',
);
export const searchTaskCategoriesDashboardAction = createAction('DASHBOARD/SEARCH_TASK_CATEGORIES');
