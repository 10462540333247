import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getMessageTime, convertNameToAvatarPlaceholder } from 'helpers/formatters';

import { Avatar } from 'components';

import styles from './styles.module.scss';

const UserMessage = ({ user, thread, className, openHandler, ...rest }) => {
  const dateTime = useMemo(
    () => getMessageTime(thread?.LastMessageCreatedDate),
    [thread?.LastMessageCreatedDate],
  );

  const lastMessage = useMemo(() => {
    if (thread?.LastMessage === null || thread?.LastMessage === undefined) {
      return 'No messages...';
    }

    if (thread?.LastMessage === '') {
      return 'Attachment';
    }

    return thread?.LastMessage;
  }, [thread?.LastMessage]);

  return (
    <div
      className={classNames(styles.item, className, { [styles.unread]: thread?.IsUnread })}
      onClick={openHandler}
      testid="thread_item"
      {...rest}
    >
      <div className={styles.itemImage}>
        <Avatar
          src={user?.AvatarUrl}
          avatarClassName={styles.itemImageAvatar}
          placeholder={convertNameToAvatarPlaceholder(`${user?.FirstName} ${user?.LastName}`)}
        />
      </div>
      <div className={styles.itemContent}>
        <div className={styles.itemContentTitle}>
          <div testid="item_title" className={styles.itemContentTitleName}>
            {user?.ThirdParty
              ? `${user?.FirstName || 'No name'} ${user?.LastName || ''} (${
                  user.ThirdParty.BusinessName || ''
                })`
              : `${user?.FirstName || 'No name'} ${user?.LastName || ''}`}
          </div>
          <div testid="item_date" className={styles.itemContentTitleTime}>
            {dateTime}
          </div>
        </div>
        <div testid="item_message" className={styles.itemContentText}>
          {lastMessage}
        </div>
      </div>
    </div>
  );
};

UserMessage.propTypes = {
  thread: PropTypes.shape({
    LastMessage: PropTypes.string,
    IsUnread: PropTypes.bool,
    LastMessageCreatedDate: PropTypes.string,
  }),
  user: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    AvatarUrl: PropTypes.string,
    ThirdParty: PropTypes.shape({
      LogoUrl: PropTypes.string,
      BusinessName: PropTypes.string,
    }),
  }),
  className: PropTypes.string,
  openHandler: PropTypes.func,
};

UserMessage.defaultProps = {
  className: null,
  thread: null,
  user: null,
  openHandler: null,
};

export default UserMessage;
