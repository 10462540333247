import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SORT_ASC, SORT_DESC } from 'settings/constants/sort';

import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';

const AscDesc = (props) => {
  const { activeOrder, className, onChange } = props;
  const [active, setActive] = useState(activeOrder);

  useEffect(() => setActive(activeOrder), [activeOrder]);

  const onClickHandler = (order) => {
    setActive(order);
    onChange(order);
  };

  return (
    <div className={classNames(styles.ascDesc, className, 'ascDesc')}>
      <button
        testid="sort_desc"
        id={SORT_DESC}
        onClick={() => onClickHandler(SORT_DESC)}
        className={classNames(styles.button, { [styles.active]: active === SORT_DESC })}
        type="button"
      >
        <Icon variant={Icon.SORT_DESC} />
        <span className={styles.text}>High to Low</span>
      </button>
      <button
        testid="sort_asc"
        id={SORT_ASC}
        onClick={() => onClickHandler(SORT_ASC)}
        className={classNames(styles.button, { [styles.active]: active === SORT_ASC })}
        type="button"
      >
        <Icon variant={Icon.SORT_ASC} />
        <span className={styles.text}>Low to High</span>
      </button>
    </div>
  );
};

AscDesc.propTypes = {
  className: PropTypes.string,
  activeOrder: PropTypes.string,
  onChange: PropTypes.func,
};

AscDesc.defaultProps = {
  className: '',
  activeOrder: undefined,
  onChange: () => {},
};

export default AscDesc;
