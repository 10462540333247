import classnames from 'classnames';

import { Icons } from '../../Icons/Icons';
import { Close as CloseIcon } from 'components/Icons';
import { TransactionCloseComponentIds } from 'app-constants';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';

interface ControlsProps {
  stage: TransactionCloseComponentIds;
  setStage: (stage: TransactionCloseComponentIds) => void;
  onClose: () => void;
}

export const Controls = ({ stage, setStage, onClose }: ControlsProps) => {
  const isProject = useIsProjectRoute();
  const onPrev = () => {
    if (isPrevDisabled()) return;
    if (stage === TransactionCloseComponentIds.IncompleteForms) {
      setStage(TransactionCloseComponentIds.UploadFiles);
    } else if (stage === TransactionCloseComponentIds.PendingTasks) {
      setStage(TransactionCloseComponentIds.IncompleteForms);
    } else if (stage === TransactionCloseComponentIds.ActiveSearchInstances) {
      setStage(TransactionCloseComponentIds.PendingTasks);
    } else if (stage === TransactionCloseComponentIds.ClientProfileRemoveAddress) {
      setStage(TransactionCloseComponentIds.ActiveSearchInstances);
    } else if (stage === TransactionCloseComponentIds.ClientProfileAddAddress) {
      setStage(TransactionCloseComponentIds.ClientProfileRemoveAddress);
    } else if (stage === TransactionCloseComponentIds.KitsActivation) {
      setStage(TransactionCloseComponentIds.ClientProfileAddAddress);
    } else if (stage === TransactionCloseComponentIds.ClosePrice) {
      setStage(TransactionCloseComponentIds.CloseDate);
    } else if (stage === TransactionCloseComponentIds.CloseConfirmation) {
      setStage(TransactionCloseComponentIds.KitsActivation);
    } else if (stage === TransactionCloseComponentIds.UploadFiles) {
      setStage(
        isProject
          ? TransactionCloseComponentIds.CloseDate
          : TransactionCloseComponentIds.ClosePrice,
      );
    }
  };

  const onNext = () => {
    if (isNextDisabled()) return;
    if (stage === TransactionCloseComponentIds.UploadFiles) {
      setStage(TransactionCloseComponentIds.IncompleteForms);
    } else if (stage === TransactionCloseComponentIds.IncompleteForms) {
      setStage(TransactionCloseComponentIds.PendingTasks);
    } else if (stage === TransactionCloseComponentIds.PendingTasks) {
      setStage(TransactionCloseComponentIds.ActiveSearchInstances);
    } else if (stage === TransactionCloseComponentIds.ActiveSearchInstances) {
      setStage(TransactionCloseComponentIds.ClientProfileRemoveAddress);
    } else if (stage === TransactionCloseComponentIds.ClientProfileRemoveAddress) {
      setStage(TransactionCloseComponentIds.ClientProfileAddAddress);
    } else if (stage === TransactionCloseComponentIds.ClientProfileAddAddress) {
      setStage(TransactionCloseComponentIds.KitsActivation);
    } else if (stage === TransactionCloseComponentIds.KitsActivation) {
      setStage(TransactionCloseComponentIds.CloseConfirmation);
    }
  };

  const isPrevDisabled = () => {
    return stage === TransactionCloseComponentIds.CloseDate;
  };

  const isNextDisabled = () => {
    return stage === TransactionCloseComponentIds.CloseConfirmation;
  };

  return (
    <div className={styles.controls}>
      <Icons
        variant="arrowLeft"
        className={classnames(styles.iconBtn, styles.arrowLeft, {
          [styles.disabled]: isPrevDisabled(),
        })}
        onClick={onPrev}
      />
      {/* <Icons
        variant="arrowRight"
        className={classnames(styles.iconBtn, styles.arrowRight, {
          [styles.disabled]: isNextDisabled(),
        })}
        onClick={onNext}
      /> */}
      <CloseIcon onClick={onClose} className={styles.iconBtn} />
    </div>
  );
};
