import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditForm } from 'components/SmartForm';
import { FormStateManagerProps } from 'pages/FormProcess/FormBuilder/FormStateManager';
import {
  AddFormCommentType,
  CommentUsersType,
  EditFormCommentType,
  FormCommentType,
  FormDocumentType,
  ResponseType,
  FieldInfoType,
  StrikeThrough,
  FormCommentThread,
} from 'types';
import {
  getFormMetaEffect,
  updateFormProcessDocumentResponseEffect,
} from 'store/effects/formProcess';
import {
  fillSmartFormAnonEffect,
  fillSmartFormAuthEffect,
} from 'store/effects/formProcess/editForm';
import { getFormMetaSelector } from 'store/selectors/requestFormProcess';
import { FormView, PDF_FIELD_TYPE } from 'app-constants';
import { getUserRolesMapSelector } from 'store/selectors/user';

interface EditFormManagerProps extends FormStateManagerProps {
  formDocument: FormDocumentType;
  isCreator?: boolean;
  handleEditFormFinish: () => void;
  handleUnlock?: () => void;
  handleExit?: () => void;
  handleDeferQuestion?: (questions, editor, reload, handleResponse) => Promise<void>;
  handleUndoDeferQuestions?: (questions, reload, handleResponse) => Promise<void>;
  commentUsers?: CommentUsersType;
  formComment?: FormCommentType;
  setFieldInfo: (fieldInfo: FieldInfoType) => void;
  handleGetFormComments: () => void;
  handleAddFormComment: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
  setCommentPanelOpen: (commentPanelOpen: boolean) => void;
  updateStrikeThrough?: (strikeThrough: StrikeThrough) => void;
  formCommentFieldId?: string;
  formComments: FormCommentThread[];
  selectedFieldInfo?: FieldInfoType;
}

export const EditFormManager = ({
  formDocument,
  authData,
  anonData,
  isCreator,
  handleEditFormFinish,
  handleUnlock,
  handleExit,
  handleDeferQuestion,
  handleUndoDeferQuestions,
  setFieldInfo,
  commentUsers,
  formComment,
  handleGetFormComments,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
  setCommentPanelOpen,
  updateStrikeThrough,
  formCommentFieldId,
  formComments,
  selectedFieldInfo,
}: EditFormManagerProps) => {
  const dispatch = useDispatch();

  const [loadContinue, setLoadContinue] = useState(false);

  const { isAgent } = useSelector(getUserRolesMapSelector);
  const meta = useSelector(getFormMetaSelector);

  useEffect(() => {
    if (!Object.keys(meta).length) {
      if (authData && isAgent) dispatch(getFormMetaEffect());
    }
  }, []);

  const {
    questions = [],
    link,
    documentName,
    clientName,
    answers,
    address,
    deferredUsers = [],
    allDeferrableUsers = [],
    formEditRoles,
    allowStrikeThrough,
    strikeThrough = {},
    views = [],
  } = formDocument;

  const [editRoles, setEditRoles] = useState(['Agent', 'Client']);
  const [localStrikeThrough, setLocalStrikeThrough] = useState<StrikeThrough>(strikeThrough);

  const handlePointUpdate = (page, points) => {
    const newPoints = { ...localStrikeThrough };
    newPoints[page] = [...points];
    setLocalStrikeThrough(newPoints);
  };

  const handleCleanAllPoints = () => {
    setLocalStrikeThrough({});
  };

  const handleCancelPoints = () => {
    setLocalStrikeThrough(strikeThrough);
  };

  const handleSavePoints = () => {
    if (updateStrikeThrough) updateStrikeThrough({ ...localStrikeThrough });
  };

  const [deferQuestions, setDeferQuestions] = useState<string[]>(
    deferredUsers.map((d) => d.questions).flat(),
  );

  const getEditQuestions = (showDisabledOnly = false) =>
    questions.filter(
      (ques) =>
        ques.FieldType !== PDF_FIELD_TYPE.PDFSignature &&
        (ques.RolePermissions?.some((role) => editRoles.includes(role)) ||
          deferQuestions.includes(ques.UUID)) &&
        (showDisabledOnly ? ques.disabled : !ques.disabled),
    );

  const updateEditRoles = (roles) => setEditRoles(roles);

  const handleResponse = (updatedResponse: Partial<ResponseType>) => {
    dispatch(updateFormProcessDocumentResponseEffect(updatedResponse));

    const response = {
      UUID: updatedResponse.UUID,
      answer: updatedResponse.Answer,
    };

    if (authData) {
      dispatch(
        fillSmartFormAuthEffect({
          formProcessPublicId: authData.formProcessPublicId,
          formDocumentPublicId: authData.formDocumentPublicId,
          ...response,
        }),
      );
    } else if (anonData) {
      dispatch(
        fillSmartFormAnonEffect({
          Token: anonData.token,
          type: anonData.type,
          ...response,
        }),
      );
    }
  };

  return (
    <>
      <EditForm
        handleResponse={handleResponse}
        questions={getEditQuestions()}
        disabledQuestions={getEditQuestions(true)}
        responses={answers}
        handleDone={handleEditFormFinish}
        link={link}
        editRoles={editRoles}
        pdfName={documentName}
        clientName={clientName}
        address={address}
        {...(isCreator
          ? { showRolesColumn: true, updateEditRoles, deferQuestions, setDeferQuestions }
          : {})}
        handleUnlock={handleUnlock}
        handleExit={handleExit}
        isCreator={isCreator}
        setLoadContinue={setLoadContinue}
        loadContinue={loadContinue}
        handleDeferQuestion={handleDeferQuestion}
        handleUndoDeferQuestion={handleUndoDeferQuestions}
        allDeferrableUsers={allDeferrableUsers}
        formEditRoles={formEditRoles}
        deferredUsers={deferredUsers}
        commentUsers={commentUsers}
        formComment={formComment}
        setFieldInfo={setFieldInfo}
        handleGetFormComments={handleGetFormComments}
        handleAddFormComment={handleAddFormComment}
        handleEditFormComment={handleEditFormComment}
        handleDeleteFormComment={handleDeleteFormComment}
        handleToggleResolveThread={handleToggleResolveThread}
        setCommentPanelOpen={setCommentPanelOpen}
        allowWizardView={views.includes(FormView.Wizard)}
        strikeThrough={localStrikeThrough}
        formCommentFieldId={formCommentFieldId}
        {...(allowStrikeThrough
          ? {
              allowStrikeThrough: true,
              handlePointUpdate: handlePointUpdate,
              handleCleanAllPoints: handleCleanAllPoints,
              handleCancelPoints: handleCancelPoints,
              handleSavePoints: handleSavePoints,
            }
          : {})}
        formCommentThreads={formComments}
        selectedFieldInfo={selectedFieldInfo}
      />
    </>
  );
};
