import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';

import styles from './styles.module.scss';

const TypeForm = (props) => {
  const { id, className, onSubmit, onReady, onQuestionChanged, hideFooter, options } = props;
  const container = useRef();

  useEffect(() => {
    createWidget(id, {
      container: container.current,
      onSubmit,
      onReady,
      onQuestionChanged,
      hideFooter,
      ...options,
    });
  }, []); // eslint-disable-line

  return (
    <div testid="typeform" ref={container} className={classNames(styles.typeForm, className)} />
  );
};

TypeForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  options: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  onReady: PropTypes.func,
  onQuestionChanged: PropTypes.func,
  holderRef: PropTypes.shape({
    current: PropTypes.shape({
      offsetWidth: PropTypes.number,
      offsetHeight: PropTypes.number,
    }),
  }),
  hideFooter: PropTypes.bool,
};

TypeForm.defaultProps = {
  id: null,
  className: '',
  options: {},
  onSubmit: () => {},
  onReady: () => {},
  onQuestionChanged: () => {},
  holderRef: undefined,
  hideFooter: false,
};

export default TypeForm;
