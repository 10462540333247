import PropTypes from 'prop-types';

import {
  Input,
  PropertyType,
  MinMax,
  YearMinMax,
  Checkbox,
  Select as CustomSelect,
  LotSizeMinMax,
  Select,
} from 'components';
import BuyerPreferences from '../BuyerPreferences';
import { NUMBER_OF_STORIES, HOA_TYPE } from 'settings/constants/preferences';

import styles from '../styles.module.scss';

export const SearchCriteria = ({ formik, disabled }) => (
  <div className={styles.searchCriteria}>
    <PropertyType
      label="Property Type"
      value={formik.values?.propertyType || []}
      onChange={(type) => formik.setFieldValue('propertyType', type)}
      error={formik.touched.propertyType ? formik.errors.propertyType : ''}
      type={'dropdown'}
      variant={CustomSelect.LIGHT_ROUND}
    />
    <MinMax
      label="Beds"
      nameMin="bedsMin"
      nameMax="bedsMax"
      onChangeMin={formik.handleChange}
      onChangeMax={formik.handleChange}
      valueMin={formik.values.bedsMin}
      valueMax={formik.values.bedsMax}
      placeholderMin="No min"
      placeholderMax="No max"
      disabled={disabled}
      testidMin="beds_min"
      testidMax="beds_max"
      errorMin={formik.touched.bedsMin ? formik.errors.bedsMin : ''}
      errorMax={formik.touched.bedsMax ? formik.errors.bedsMax : ''}
      variant={Input.LIGHT_ROUND}
      className={styles.field}
    />
    <MinMax
      className={styles.field}
      label="Baths"
      nameMin="bathsMin"
      nameMax="bathsMax"
      onChangeMin={formik.handleChange}
      onChangeMax={formik.handleChange}
      valueMin={formik.values.bathsMin}
      valueMax={formik.values.bathsMax}
      placeholderMin="No min"
      placeholderMax="No max"
      disabled={disabled}
      testidMin="baths_min"
      testidMax="baths_max"
      errorMin={formik.touched.bathsMin ? formik.errors.bathsMin : ''}
      errorMax={formik.touched.bathsMax ? formik.errors.bathsMax : ''}
      variant={Input.LIGHT_ROUND}
    />
    <MinMax
      label="Square Feet"
      nameMin="squareFeetMin"
      nameMax="squareFeetMax"
      onChangeMin={formik.handleChange}
      onChangeMax={formik.handleChange}
      valueMin={formik.values.squareFeetMin}
      valueMax={formik.values.squareFeetMax}
      placeholderMin="No min"
      placeholderMax="No max"
      disabled={disabled}
      testidMin="square_min"
      testidMax="square_max"
      errorMin={formik.touched.squareFeetMin ? formik.errors.squareFeetMin : ''}
      errorMax={formik.touched.squareFeetMax ? formik.errors.squareFeetMax : ''}
      variant={Input.LIGHT_ROUND}
      className={styles.field}
      icon={'sqft'}
    />
    <LotSizeMinMax
      className={styles.field}
      placeholderMin="No min"
      placeholderMax="No max"
      onChangeMin={(value) => {
        formik.setFieldValue('lotSizeMin', value);
      }}
      onChangeMax={(value) => {
        formik.setFieldValue('lotSizeMax', value);
      }}
      nameMin="lotSizeMin"
      nameMax="lotSizeMax"
      valueMin={formik.values.lotSizeMin}
      valueMax={formik.values.lotSizeMax}
      variant={Select.LIGHT_ROUND}
      errorMin={formik.touched.lotSizeMin ? formik.errors.lotSizeMin : ''}
      errorMax={formik.touched.lotSizeMax ? formik.errors.lotSizeMax : ''}
      disabled={disabled}
      label="Lot Size"
      selectClassName={styles.selectWrapper}
    />
    {/* <CustomSelect
      name="Stories"
      className={{ wrapper: styles.field }}
      options={SearchCriteria.dropdownOptionsStories}
      value={formik.values?.stories}
      onSelect={(event, values) => formik.setFieldValue('stories', values.value)}
      placeholder="Doesn't Matter"
      checkboxPostion="start"
      label="Stories"
      variant={CustomSelect.LIGHT_ROUND}
    /> */}
    <div className={styles.hoaContainer}>
      {(formik.values.hoaType || HOA_TYPE.Flexible) === HOA_TYPE.Flexible && (
        <MinMax
          className={styles.field}
          label="HOA Cost Range"
          nameMin="hoaMin"
          nameMax="hoaMax"
          onChangeMin={formik.handleChange}
          onChangeMax={formik.handleChange}
          valueMin={formik.values.hoaMin}
          valueMax={formik.values.hoaMax}
          placeholderMin="No min"
          placeholderMax="No max"
          disabled={disabled}
          testidMin="hoa_min"
          testidMax="hoa_max"
          errorMin={formik.touched.hoaMin ? formik.errors.hoaMin : ''}
          errorMax={formik.touched.hoaMax ? formik.errors.hoaMax : ''}
          variant={Input.LIGHT_ROUND}
        />
      )}
      <div className={styles.checkboxContainer}>
        <Checkbox
          checked={(formik.values.hoaType || HOA_TYPE.Flexible) === HOA_TYPE.None}
          onChange={(e) => {
            const checked = e.target.checked;
            if (checked) {
              formik.setFieldValue('hoaType', HOA_TYPE.None);
            } else {
              formik.setFieldValue('hoaType', HOA_TYPE.Flexible);
            }
          }}
          label="No HOA"
          direction="right"
          labelTextClassName={styles.label}
        />
      </div>
    </div>
  </div>
);

SearchCriteria.dropdownOptionsStories = [
  { id: 1, value: NUMBER_OF_STORIES.NotImportant, name: "Doesn't matter" },
  { id: 2, value: NUMBER_OF_STORIES.One, name: 'Only single story' },
  { id: 3, value: NUMBER_OF_STORIES.OnePreferred, name: 'Single story preferred' },
  { id: 4, value: NUMBER_OF_STORIES.Multiple, name: 'Multi-story preferred' },
];

SearchCriteria.dropdownOptionsHOA = [
  { id: 1, value: HOA_TYPE.None, name: 'No HOA' },
  { id: 2, value: HOA_TYPE.Mandatory, name: 'Must have HOA' },
  { id: 3, value: HOA_TYPE.Flexible, name: "I'm flexible" },
];

SearchCriteria.propTypes = {
  formik: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  changeStep: PropTypes.func,
};

BuyerPreferences.defaultProps = {
  disabled: false,
};
