import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { DateRangePicker } from 'components/Form/DateRangePicker';
import useIsProjectRoute from 'hooks/use-is-project-route';
import moment, { Moment } from 'moment';
import { FilterDrawer, FilterSection } from 'pages/Workshop/common';
import { GroupCheckBoxesSection } from 'pages/Workshop/common/GroupCheckBoxesSection';
import { MultiSelectSection } from 'pages/Workshop/common/MultiSelectSection';
import { LocationService } from 'services';
import { getAllTransactionsSelector, getTransactionsSelector } from 'store/selectors/transactions';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { projectStatusesOptions, transactionStatusesOptions } from 'settings/constants/transaction';
import { showingsStatus } from 'settings/constants/showings';
import { isEqual } from 'lodash-es';
import {
  getShowingAddressesSelector,
  getShowingClientsSelector,
  getShowingIsArchiveSelector,
  getShowingRequestersSelector,
} from 'store/selectors/showingAggregate';
import { Input } from 'components';
import { setShowingsFiltersAppliedEffect } from 'store/effects/showingAggregate';
import { AppointmentStatus } from 'types';

let defaultFilters = {};

export const FilterSections = ({ onDone, onReset }) => {
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();
  const requesters = useSelector(getShowingRequestersSelector);
  const addresses = useSelector(getShowingAddressesSelector);
  const clientsData = useSelector(getShowingClientsSelector);
  const isProject = useIsProjectRoute();
  const transactionsData = useSelector(getAllTransactionsSelector);
  const statusesData = isProject ? [...projectStatusesOptions] : [...transactionStatusesOptions];
  const [initialCache] = useState(transactionsData);
  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
  const [dueFrom, setDueFrom] = useState<Moment | undefined>(
    query.dueToday ? moment() : query.dueWeek ? moment().startOf('week') : undefined,
  );
  const [dueTo, setDueTo] = useState<Moment | undefined>(
    query.dueToday ? moment() : query.dueWeek ? moment().endOf('week') : undefined,
  );
  const [requestedDueFrom, setRequestedDueFrom] = useState<Moment>();
  const [requestedDueTo, setRequestedDueTo] = useState<Moment>();
  const [cachedRequestorsState, setCachedRequestorsState] = useState<any[]>([]);
  const [cachedAddressesState, setCachedAddressesState] = useState<any[]>([]);
  const [cachedClientsData, setCachedClientsData] = useState<any[]>([]);
  const [clients, setClients] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>(
    query.statuses ? query.statuses.split(',') : [],
  );

  const [resetRequestedDates, setResetRequestedDates] = useState(false);
  const [resetDates, setResetDates] = useState(false);

  const [showingStatuses, setShowingStatuses] = useState<string[]>(
    query.status ? query.status.split(',') : ['Pending', 'Upcoming'],
  );
  const [requestorsState, setRequestorsState] = useState<string[]>([]);
  const [addressesState, setAddressesState] = useState<string[]>([]);
  const [previousFilters, setPreviousFilters] = useState({});

  const { isArchive } = useSelector(getShowingIsArchiveSelector);
  const dispatch = useDispatch();
  const [queryRender, setQueryRender] = useState<boolean>(true);
  const [ArchiveFilters, setArchiveFilters] = useState([
    AppointmentStatus.Declined,
    AppointmentStatus.Canceled,
    AppointmentStatus.Past,
  ]);

  useEffect(() => {
    if (!queryRender && isArchive) {
      setDueFrom(undefined);
      setDueTo(undefined);
      setRequestedDueFrom(undefined);
      setRequestedDueTo(undefined);
      setStatuses([]);
      setShowingStatuses(['Pending', 'Upcoming']);
      setAddressesState([]);
      setRequestorsState([]);
      setResetDates(true);
      setResetRequestedDates(true);
      setClients([]);
      setPreviousFilters(defaultFilters);
      dispatch(setShowingsFiltersAppliedEffect(false));
    }
    queryRender && ArchiveFilters.includes(query.status) && !isArchive
      ? setQueryRender(true)
      : setQueryRender(false);
  }, [isArchive]);

  const getFilters = () => ({
    dueFrom,
    dueTo,
    requestedDueFrom,
    requestedDueTo,
    addressesState,
    clients,
    statuses,
    requestorsState,
    showingStatuses,
  });

  useEffect(() => {
    defaultFilters = getFilters();
    setPreviousFilters(defaultFilters);
    // if (query.status) submitHandler();
  }, []);

  useEffect(() => {
    if (!cachedClientsData.length && clientsData?.length && !isEqual(clientsData, initialCache)) {
      setCachedClientsData(clientsData);
    }
    if (!cachedRequestorsState.length && requesters?.length && !isEqual(requesters, initialCache)) {
      setCachedRequestorsState(requesters);
    }
    if (!cachedAddressesState.length && addresses?.length) {
      setCachedAddressesState(addresses);
    }
  }, [requesters, addresses, clientsData]);

  useEffect(() => {
    setIsFiltersApplied(
      Boolean(
        requestedDueFrom ||
          requestedDueTo ||
          dueFrom ||
          dueTo ||
          addressesState.length ||
          requestorsState.length ||
          statuses.length ||
          clients.length ||
          !isEqual(showingStatuses, ['Pending', 'Upcoming']),
      ),
    );
  }, [
    dueFrom,
    dueTo,
    requestedDueFrom,
    requestedDueTo,
    addressesState,
    clients,
    statuses,
    requestorsState,
    showingStatuses,
  ]);

  const submitHandler = () => {
    onDone({
      Listings: addressesState,
      Requester: requestorsState,
      Status: showingStatuses,
      listingStatus: statuses,
      DueTo: dueTo,
      DueFrom: dueFrom,
      Client: clients,
      RequestedDueFrom: requestedDueFrom,
      RequestedDueTo: requestedDueTo,
    });
    setPreviousFilters(getFilters());
  };

  const resetHandler = () => {
    setDueFrom(undefined);
    setDueTo(undefined);
    setRequestedDueFrom(undefined);
    setRequestedDueTo(undefined);
    setStatuses([]);
    setShowingStatuses(['Pending', 'Upcoming']);
    setAddressesState([]);
    setRequestorsState([]);
    setResetDates(true);
    setResetRequestedDates(true);
    setClients([]);
    onReset();
    setPreviousFilters(defaultFilters);
  };

  const discardHandler = () => {
    if (JSON.stringify(previousFilters) === JSON.stringify(getFilters())) {
      return;
    }

    const {
      dueFrom,
      dueTo,
      requestedDueFrom,
      requestedDueTo,
      addressesState,
      clients,
      statuses,
      requestorsState,
      showingStatuses,
    } = previousFilters as any;

    if (dueFrom) setDueFrom(dueFrom);
    if (dueTo) setDueTo(dueTo);
    if (requestedDueFrom) setRequestedDueFrom(requestedDueFrom);
    if (requestedDueTo) setRequestedDueTo(requestedDueTo);
    if (addressesState) setAddressesState(addressesState);
    if (clients) setClients(clients);
    if (statuses) setStatuses(statuses);
    if (requestorsState) setRequestorsState(requestorsState);
    if (showingStatuses) setShowingStatuses(showingStatuses);
  };

  return (
    <FilterDrawer
      className={styles.filterBtn}
      isFiltersApplied={isFiltersApplied}
      onDone={submitHandler}
      onReset={resetHandler}
      onDiscard={discardHandler}
    >
      <div className={styles.filterSections}>
        <FilterSection title="Showing Status" count={showingStatuses?.length}>
          <GroupCheckBoxesSection
            title={''}
            entityList={Object.values(showingsStatus).map((val) => ({ name: val, value: val }))}
            entityValues={showingStatuses}
            setEntity={setShowingStatuses}
          />
        </FilterSection>
        <FilterSection title="Listings" count={addressesState?.length + statuses?.length}>
          <MultiSelectSection
            placeholder={`Search by Listings...`}
            entityList={cachedAddressesState?.map(({ ProviderPlaceId, Line1 }) => ({
              value: ProviderPlaceId,
              label: Line1,
            }))}
            entity={addressesState}
            setEntity={setAddressesState}
            locationPrefix
          />
          <GroupCheckBoxesSection
            title={'Statuses'}
            entityList={statusesData}
            entityValues={statuses}
            setEntity={setStatuses}
          />
        </FilterSection>
        {/* <FilterSection title="Client">
          <MultiSelectSection
            placeholder={`Search by Name...`}
            entityList={cachedClientsData?.map((name) => ({
              value: name,
              label: name,
            }))}
            entity={clients}
            setEntity={setClients}
            avatarPrefix
          />
        </FilterSection> */}
        <FilterSection title="Date">
          <div className={styles.datePickerContainer}>
            <DateRangePicker
              span={13}
              title={''}
              onDatesChange={(from, to) => {
                resetDates && setResetDates(false);
                setDueFrom(from);
                setDueTo(to);
              }}
              fromDateValue={dueFrom}
              toDateValue={dueTo}
              dateRangePickerWrapperClass={styles.dateRangePicker}
              resetDates={resetDates}
              futureDisabled={false}
            />
          </div>
        </FilterSection>
        <FilterSection title="Requested By" count={requestorsState?.length}>
          <MultiSelectSection
            placeholder={`Search by Name...`}
            entityList={(isArchive ? requesters : cachedRequestorsState)?.map((value) => ({
              value,
              label: value,
            }))}
            entity={requestorsState}
            setEntity={setRequestorsState}
            avatarPrefix
          />
        </FilterSection>
        <FilterSection title="Date Requested">
          <div className={styles.datePickerContainer}>
            <DateRangePicker
              span={13}
              title={''}
              onDatesChange={(from, to) => {
                resetRequestedDates && setResetRequestedDates(false);
                setRequestedDueFrom(from);
                setRequestedDueTo(to);
              }}
              fromDateValue={requestedDueFrom}
              toDateValue={requestedDueTo}
              dateRangePickerWrapperClass={styles.dateRangePicker}
              resetDates={resetDates}
              futureDisabled={false}
            />
          </div>
        </FilterSection>
      </div>
    </FilterDrawer>
  );
};
