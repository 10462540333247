import { LocationCollapsibleOptions } from './LocationCollapsibleOptions';

import styles from './styles.module.scss';
import { CriteriaType, PropertyAttributes } from './../';
import Icons from './../../Icons/Icons';
import { FormAttribute } from './FormAttribute';
import { useEffect, useState } from 'react';
import CommuteEditor from 'pages/OnBoardingWizard/components/StageParts/StagePrefs/CommutesModal/CommuteEditor';
import { SchoolsModal } from 'pages/OnBoardingWizard/components/StageParts/StageNeighbors/Options/SchoolsModal';
import { SchoolPrefConfig } from 'pages/Properties/SearchResults/Drawers/FilterDrawer/Tabs/ImportantFeatures/components/Items/Item/SchoolsSelector';
import { PREF_TYPE, prefsIds } from 'settings/constants/preferences';
import CommutesModal from 'pages/OnBoardingWizard/components/StageParts/StagePrefs/CommutesModal';
import { appOpenModalEffect } from 'store/effects';
import { useDispatch, useSelector } from 'react-redux';
import CriteriaModal from 'pages/Properties/CriteriaModal';
import { getModalSelector } from 'store/selectors/app';

export const LocationAttributes = ({
  softCriteria,
  addSoftCriteria,
  variant = LocationAttributes.DEFAULT,
  className = '',
  hideKeywords = false,
}) => {
  const modal = useSelector(getModalSelector);
  const [commute, setCommute] = useState<any[]>([]);
  const [proximity, setProximity] = useState<any[]>([]);
  const [isProximity, setIsProximity] = useState<boolean>(false);
  const [school, setSchool] = useState<SchoolPrefConfig>({});
  const [activeCommuteIndex, setActiveCommuteIndex] = useState<number>(0);
  const [activeProximityIndex, setActiveProximityIndex] = useState<number>(0);

  useEffect(() => {
    setCommute(softCriteria?.[prefsIds.commutes] || []);
    setProximity(softCriteria?.[prefsIds.proximityToImportantLocations] || []);
    setSchool(softCriteria?.[prefsIds.school] || {});
  }, [softCriteria]);

  const onSaveCommuteLocationsValues = (values, isImportantLocation) => {
    const locations = values?.map((value) => ({
      ...value,
      ...(isImportantLocation ? { isImportantLocation } : {}),
      ImportanceAndWeight: {
        Importance: 'Somewhat',
      },
    }));
    if (isImportantLocation) {
      setProximity(locations);
      addSoftCriteria(prefsIds.proximityToImportantLocations, undefined, undefined, locations);
    } else {
      setCommute(locations);
      addSoftCriteria(prefsIds.commutes, undefined, undefined, locations);
    }
  };

  const dispatch = useDispatch();

  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  return (
    <div className={className}>
      {variant === LocationAttributes.COLLAPSIBLE && (
        <PropertyAttributes
          softCriteria={softCriteria}
          addSoftCriteria={addSoftCriteria}
          className={styles.propertyAttributes}
          data={[
            {
              key: 'location-pref',
              title: 'Location Preferences',
              icon: Icons.LOCATION_PREF,
              criteria: LocationCollapsibleOptions,
            },
          ]}
          hideKeywords={hideKeywords}
          showCount
          scrollToTop
        />
      )}
      <CommutesModal
        onNext={(val) => onSaveCommuteLocationsValues(val, isProximity)}
        value={isProximity ? proximity : commute}
        title={isProximity ? 'Add Important Location' : 'Add Commute'}
        keepModalOpen={variant === LocationAttributes.COLLAPSIBLE ? CriteriaModal.id : undefined}
        edit={isProximity ? activeProximityIndex : activeCommuteIndex}
        formOnly={true}
      />
      <SchoolsModal
        onNext={(value: any) => {
          const valid = Object.values(value).length > 0;
          const newSchool = valid
            ? {
                ...value,
                ImportanceAndWeight: {
                  Importance: 'Somewhat',
                },
              }
            : {};
          setSchool(newSchool);
          addSoftCriteria(prefsIds.school, undefined, undefined, newSchool);
        }}
        value={school}
        keepModalOpen={variant === LocationAttributes.COLLAPSIBLE ? CriteriaModal.id : undefined}
      />

      <FormAttribute
        icon={Icons.COMMUTE}
        label={'Commute Time'}
        list={commute.map(
          ({
            MaxCommuteTimeInMinutes,
            Name,
            TransportationModePreference,
            ImportanceAndWeight,
          }) => ({
            duration: `${MaxCommuteTimeInMinutes} minutes by ${TransportationModePreference?.[0]
              ?.toLowerCase()
              ?.replace('publictransit', 'public transit')}`,
            title: Name,
            importance: ImportanceAndWeight?.Importance || 'Somewhat',
          }),
        )}
        importanceChange={(value, idx) => {
          const newCommute = [...commute];
          newCommute[idx] = {
            ...newCommute[idx],
            ImportanceAndWeight: {
              Importance: value,
            },
          };
          setCommute(newCommute);
          addSoftCriteria(prefsIds.commutes, undefined, undefined, newCommute);
        }}
        onRmoveItem={(idx) => {
          const newCommute = [...commute];
          newCommute.splice(idx, 1);
          setCommute(newCommute);
          addSoftCriteria(prefsIds.commutes, undefined, undefined, newCommute);
        }}
        onOpen={(id) => {
          setActiveCommuteIndex(id);
          setIsProximity(false);
          openModal(CommutesModal.id);
        }}
      />
      <FormAttribute
        icon={Icons.PROXIMITY}
        label={'Proximity to Important Locations'}
        list={proximity.map(
          ({
            MaxCommuteTimeInMinutes,
            Name,
            TransportationModePreference,
            ImportanceAndWeight,
          }) => ({
            duration: `${MaxCommuteTimeInMinutes} minutes by ${TransportationModePreference?.[0]
              ?.toLowerCase()
              ?.replace('publictransit', 'public transit')}`,
            title: Name,
            importance: ImportanceAndWeight?.Importance || 'Somewhat',
          }),
        )}
        importanceChange={(value, idx) => {
          const newProximity = [...proximity];
          newProximity[idx] = {
            ...newProximity[idx],
            ImportanceAndWeight: {
              Importance: value,
            },
          };
          setProximity(newProximity);
          addSoftCriteria(
            prefsIds.proximityToImportantLocations,
            undefined,
            undefined,
            newProximity,
          );
        }}
        onRmoveItem={(idx) => {
          const newProximity = [...proximity];
          newProximity.splice(idx, 1);
          setProximity(newProximity);
          addSoftCriteria(
            prefsIds.proximityToImportantLocations,
            undefined,
            undefined,
            newProximity,
          );
        }}
        onOpen={(id) => {
          setActiveProximityIndex(id);
          setIsProximity(true);
          openModal(CommutesModal.id);
        }}
      />
      <FormAttribute
        disableAddButton={Object.keys(school).length > 0}
        icon={Icons.SCHOOLS}
        label={'Schools'}
        importanceChange={(value) => {
          const newSchool = { ...school };
          newSchool['ImportanceAndWeight'] = {
            Importance: value,
          };
          setSchool(newSchool);
          addSoftCriteria(prefsIds.school, undefined, undefined, newSchool);
        }}
        list={[
          ...('NonSpecificConfig' in school
            ? [
                {
                  duration: '',
                  title: school?.NonSpecificConfig?.SchoolTypes?.join(', ') || '',
                  importance: school?.ImportanceAndWeight?.Importance || 'Somewhat',
                },
              ]
            : []),
          ...('SpecificDistricts' in school
            ? [
                {
                  duration: '',
                  title: school?.SpecificDistricts?.map(({ Name }) => Name)?.join(', ') || '',
                  importance: school?.ImportanceAndWeight?.Importance || 'Somewhat',
                },
              ]
            : []),
        ]}
        onRmoveItem={(idx) => {
          setSchool({});
          addSoftCriteria(prefsIds.school, undefined, undefined, {});
        }}
        onOpen={(id) => {
          setActiveCommuteIndex(id);
          openModal((SchoolsModal as any).id);
        }}
      />

      {variant !== LocationAttributes.COLLAPSIBLE && (
        <>
          {LocationCollapsibleOptions?.map((criteria, idx) => (
            <CriteriaType
              key={`criteria-${idx}`}
              title={criteria?.showTitle}
              id={criteria?.id}
              type={criteria?.type}
              icon={criteria?.icon}
              softCriteria={softCriteria}
              addSoftCriteria={addSoftCriteria}
            />
          ))}
        </>
      )}
    </div>
  );
};

LocationAttributes.COLLAPSIBLE = 'collapsible';
LocationAttributes.DEFAULT = 'default';
