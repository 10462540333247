import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getCurrentParticipants } from 'store/selectors/drawers/addParticipants';
import { useSelector } from 'react-redux';

import { Avatars } from 'components';
import Info from './Info';

import styles from './styles.module.scss';

const MessagesSubheader = (props) => {
  const { className, shareProperty } = props;
  const { isData, participantsAvatarUrls, names, recipientRole, isGroupChat } =
    useSelector(getCurrentParticipants);

  return (
    <div testid="messages_subheader" className={classNames(styles.messagesSubheader, className)}>
      {isData && (
        <>
          <Avatars
            className={styles.avatars}
            avatarWrapperClassName={styles.avatarWrapper}
            avatarClassName={styles.avatar}
            isNotSingleClassName={styles.isNotSingle}
            avatars={participantsAvatarUrls}
            overflowClassName={styles.overflow}
          />
          <Info names={names} shareProperty={shareProperty} />
          {!isGroupChat && <span className={styles.chatHeaderUserRole}>{recipientRole}</span>}
        </>
      )}
    </div>
  );
};

MessagesSubheader.propTypes = {
  className: PropTypes.string,
  shareProperty: PropTypes.shape({}),
};

MessagesSubheader.defaultProps = {
  className: '',
  shareProperty: {},
};

export default MessagesSubheader;
