import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { MilestoneModalHeader } from './MilestoneModalHeader';
import { Transaction } from './Transaction';
import { MilestoneName } from './MilestoneName';
import { DueDate } from './DueDate';
import { Private } from './Private';
import { Footer } from './Footer';
import {
  addNewMilestoneEffect,
  getMilestoneAggregateEffect,
} from 'store/effects/milestoneAggregate';

import styles from './styles.module.scss';

export interface MilestoneModalFormProps {
  onCancelModal: () => void;
  className?: string;
}

export const MilestoneModalForm = (props: MilestoneModalFormProps) => {
  const { onCancelModal, className } = props;
  const [values, setNewFormValues] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const onFormValueChange = (values) => {
    setNewFormValues(values);
  };

  const onChangeField = (val, fieldName) => {
    onFormValueChange({ ...(values || {}), [fieldName]: val });
  };

  const addNewMilestone = () => {
    setIsPending(true);
    const formVal: any = values;
    const config = {
      transactionId: formVal.TransactionId,
      milestoneName: formVal.MilestoneName,
      dueDate: formVal.DueDate,
      isPrivate: formVal.IsPrivate,
    };
    dispatch(
      addNewMilestoneEffect(config, {}, (err) => {
        if (!err) {
          dispatch(getMilestoneAggregateEffect());
          onCancelModal();
        }
        setIsPending(false);
      }),
    );
  };

  return (
    <div className={styles.taskModalForm}>
      <MilestoneModalHeader />
      <div className={classNames(styles.content, className)}>
        <div className={styles.inlineRowContent}>
          <MilestoneName values={values} onChangeField={onChangeField} />
          <DueDate className={styles.dueDate} values={values} onChangeField={onChangeField} />
        </div>
        <Transaction onChangeField={onChangeField} values={values} />
        <Private values={values} onChangeField={onChangeField} />
      </div>
      <Footer
        values={values}
        setNewFormValues={setNewFormValues}
        onCancelModal={onCancelModal}
        onSubmit={addNewMilestone}
        isPending={isPending}
      />
    </div>
  );
};
