import { createSelector } from 'reselect';
import { ERROR, IDLE, PENDING } from 'settings/constants/apiState';
import { getUserId } from '../user';
import { getTransactionSelector } from '../transaction';

const localState = ({ transactionTaskCategories }) => transactionTaskCategories;

export const getTransactionTaskCategoriesSelector = createSelector(
  localState,
  getTransactionSelector,
  getUserId,
  (transactionTaskCategories, { transaction }, userId) => ({
    isIdle: transactionTaskCategories.state === IDLE,
    isPending: transactionTaskCategories.state === PENDING,
    isError: transactionTaskCategories.state === ERROR,
    isData: !!transactionTaskCategories?.data,
    status: transactionTaskCategories?.meta?.status,
    isCreator: userId === transaction?.Creator?.Id,
    categories: transactionTaskCategories?.data,
  }),
);
