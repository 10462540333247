import { handleActions } from 'redux-actions';
import { get } from 'lodash-es';
import {
  appLogoutAction,
  appInitAction,
  appLoginAction,
  appModalOpenAction,
  appManageClientDrawerAction,
  appSetClientDrawerActiveTab,
  appSetInviteClientValuesAction,
  appSetActiveClientTransactionInstanceAction,
  appSetActiveClientSearchInstanceAction,
  appDrawerSetClientIdAction,
  appDrawerSetAgentIdAction,
  appDrawerSetClientIdAndInstancesAction,
  appSetClientsSearchValueAction,
  requestPostSendInvite,
  appSetShowInviteClientAction,
  appResetClientDriverAction,
  appSetInvitedClientDataAction,
  appChangeChatAdviceStatusAction,
  requestGetNotificationMessagesAction,
  appSetShowCriteriaModalInviteClientAction,
  appSetShowProfileExpandedSectionAction,
  appSetShowEmailVerificationPopupAction,
  appSetConnectionTypeAction,
  appSetShowInviteTeamMemberAction,
  appSetInviteTeamMemberValuesAction,
  appSetShowAssignConnectionAction,
  appSetInviteClientAddressesAction,
  appResetInviteClientAddressesAction,
  appSetLastPropertyHeight,
  appSetActiveClientPreApproval,
  setShowInviteCustomLocationAction,
} from 'store/actions/app';
import { signUpAction } from 'store/actions/user';
import { IDLE } from 'settings/constants/apiState';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';
import { CLIENT } from 'settings/constants/roles';
import { ClientCategory } from 'types';

const initialData = {
  auth: null,
  modal: {
    id: null,
    open: false,
  },
  chatAdviceStatus: true,
  notificationMessages: {
    notificationMessagesMap: {},
    notificationPluralMessagesMap: {},
    state: IDLE,
  },
  clientsDrawer: {
    open: false,
    activeTab: 0,
    showInviteClient: false,
    showInviteCustomLocation: false,
    showInviteTeamMember: false,
    inviteClientValues: {},
    inviteTeamMemberValues: {},
    activeClientTransactionInstance: {},
    clientId: null,
    agentId: null,
    searchInstances: [],
    transactionInstances: [],
    searchValue: '',
    sendingInvite: {
      state: IDLE,
      data: null,
      meta: {},
    },
    showCriteriaSection: false,
    showExpandedSection: false,
    connectionType: AGENT_CONNECTIONS.Clients,
    connectionAssign: {
      showAssignConnection: false,
      connectionAssignType: CLIENT,
      isNewlyInvited: false,
    },
    inviteClientAddresses: [],
  },
  lastPropertyHeight: 202,
  invitedClientData: null,
};

export default handleActions(
  {
    [appInitAction]: (state, { payload }) => ({
      ...state,
      auth: payload?.auth,
      chatAdviceStatus: payload?.chatAdviceStatus,
    }),
    [appSetLastPropertyHeight]: (state, { payload }) => ({
      ...state,
      lastPropertyHeight: payload?.propertyHeight,
    }),
    [appChangeChatAdviceStatusAction]: (state, { payload }) => ({
      ...state,
      chatAdviceStatus: !!payload,
    }),
    [appLogoutAction]: () => ({
      ...initialData,
      auth: false,
    }),
    [appLoginAction]: (state, { payload }) => ({
      ...state,
      // TODO Consider another logic to set auth true
      auth: !!get(payload, ['data', 'loginResult', 'user', 'Roles']),
    }),
    [signUpAction]: (state, { payload }) => ({
      ...state,
      auth: !!get(payload, ['data', 'loginResult', 'user', 'Roles']),
    }),
    [appModalOpenAction]: (state, { payload }) => ({
      ...state,
      modal: {
        id: payload.id,
        open: payload.open,
      },
    }),
    [appSetShowCriteriaModalInviteClientAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        showCriteriaSection: payload,
      },
    }),
    [appSetShowProfileExpandedSectionAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        showExpandedSection: payload,
      },
    }),
    [appManageClientDrawerAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        open: payload,
      },
    }),
    [appSetClientDrawerActiveTab]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        activeTab: payload,
      },
    }),
    [appSetInviteClientValuesAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        inviteClientValues: payload,
      },
    }),
    [appSetInviteTeamMemberValuesAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        inviteTeamMemberValues: payload,
      },
    }),
    [appSetActiveClientTransactionInstanceAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        activeClientTransactionInstance: payload,
      },
    }),
    [appSetActiveClientSearchInstanceAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        activeClientSearchInstance: payload,
      },
    }),
    [appSetActiveClientPreApproval]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        activeClientPreApproval: payload,
      },
    }),
    [appDrawerSetClientIdAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        agentId: null,
        clientId: payload.id,
      },
    }),
    [appDrawerSetAgentIdAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        clientId: null,
        agentId: payload.id,
        open: !!payload.open,
        showExpandedSection: !!payload.showExpandedSection,
      },
    }),
    [appDrawerSetClientIdAndInstancesAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        agentId: null,
        clientId: payload.id,
        searchInstances: [...(payload.searchInstances || [])],
        transactionInstances: [...(payload.transactionInstances || [])],
        open: !!payload.open,
        showExpandedSection: !!payload.showExpandedSection,
      },
    }),
    [appSetClientsSearchValueAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        searchValue: payload,
      },
    }),
    [appSetShowInviteClientAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        showInviteClient: payload,
      },
    }),
    [setShowInviteCustomLocationAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        showInviteCustomLocation: payload,
      },
    }),
    [appSetShowInviteTeamMemberAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        showInviteTeamMember: payload,
      },
    }),
    [appSetShowAssignConnectionAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        showInviteTeamMember: false,
        connectionAssign: {
          showAssignConnection: !!payload.open,
          connectionAssignType: payload.assignType || CLIENT,
          isNewlyInvited: !!payload.newInvite,
        },
      },
    }),
    [appResetClientDriverAction]: (state, { payload }) => {
      const clientType = state.clientsDrawer?.inviteClientValues?.clientType;
      return {
        ...state,
        clientsDrawer: {
          ...initialData.clientsDrawer,
          ...payload,
        },
        invitedClientData:
          clientType === ClientCategory.Seller || clientType === ClientCategory.Both
            ? {
                ...state?.clientsDrawer?.inviteClientValues,
                ...state?.clientsDrawer?.activeClientTransactionInstance,
              }
            : null,
      };
    },
    [appSetInvitedClientDataAction]: (state, { payload }) => {
      return {
        ...state,
        invitedClientData: payload,
      };
    },

    [requestPostSendInvite]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        sendingInvite: {
          state: payload.state,
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      },
    }),
    [requestGetNotificationMessagesAction]: (state, { payload }) => {
      const notificationMessagesMap = {};
      const notificationPluralMessagesMap = {};
      payload?.data?.result?.forEach(({ Type, Template, TemplatePlural }) => {
        notificationMessagesMap[Type] = Template;
        notificationPluralMessagesMap[Type] = TemplatePlural;
      });

      return {
        ...state,
        notificationMessages: {
          notificationMessagesMap,
          notificationPluralMessagesMap,
          state: payload.state,
        },
      };
    },
    [appSetShowEmailVerificationPopupAction]: (state, { payload }) => ({
      ...state,
      showEmailVerificationPopup: payload,
    }),
    [appSetConnectionTypeAction]: (state, { payload }) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        connectionType: payload.type || AGENT_CONNECTIONS.Clients,
      },
    }),
    [appSetInviteClientAddressesAction]: (state, { payload }) => {
      const addressFound = state?.clientsDrawer?.inviteClientAddresses?.find(
        (invite) =>
          invite?.address?.placeId === payload?.address?.placeId &&
          invite?.address?.suiteUnit === payload?.address?.suiteUnit,
      );
      if (addressFound) return state;
      return {
        ...state,
        clientsDrawer: {
          ...state.clientsDrawer,
          inviteClientAddresses: [...(state.clientsDrawer.inviteClientAddresses || []), payload],
        },
      };
    },
    [appResetInviteClientAddressesAction]: (state) => ({
      ...state,
      clientsDrawer: {
        ...state.clientsDrawer,
        inviteClientAddresses: [],
      },
    }),
  },
  initialData,
);
