import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { requestGetSearchResultsAction } from 'store/actions/searchResults';
import { cloneDeep, get } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import { requestToggleFavoriteListingAction } from 'store/actions/listingDetail';
import { updatePropertyFavorite } from 'store/helpers';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetSearchResultsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: {
        Properties:
          payload?.options && payload?.options?.add
            ? [...(state.data?.Properties ?? []), ...get(payload, 'data.items', [])]
            : get(payload, 'data.items', initialData.data?.Properties),
        IsAlreadySavedSearch: get(
          payload,
          'data.isAlreadySavedSearch',
          initialData.data?.IsAlreadySavedSearch,
        ),
        TotalCount: get(payload, 'data.totalCount'),
      },

      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestToggleFavoriteListingAction]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        Properties: updatePropertyFavorite({ data: state?.data?.Properties }, payload),
      },
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
