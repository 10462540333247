import { handleActions } from 'redux-actions';
import { cloneDeep } from 'lodash-es';

import { openQuotesDrawerAction, setQuotesDrawerParamsAction } from 'store/actions/drawers/quotes';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  open: false,
  params: {},
};

export default handleActions(
  {
    [openQuotesDrawerAction]: (state, { payload }) => ({
      ...state,
      open: payload?.open,
      params: payload?.params || {},
    }),
    [setQuotesDrawerParamsAction]: (state, { payload }) => ({
      ...state,
      params: payload || {},
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
