import { createSelector } from 'reselect';
import { PENDING, READY } from 'settings/constants/apiState';
import { getSearchCriteriaFormattedString } from 'helpers';

const localState = ({ clientSearches }) => clientSearches;

export const getClientsSearchInstancesSelector = createSelector(
  localState,
  ({ clientSearches }) => {
    const { data, state } = clientSearches;
    return {
      data:
        data?.map((client) => ({
          ...client,
          SearchQueries: client.SearchQueries?.map((query) => ({
            id: query.Id,
            clientId: query.ClientId,
            agentId: query.AgentId,
            name: query.Name,
            updatedOn: query.UpdatedDate,
            agentName: query.AgentName,
            status: query.Status,
            info: getSearchCriteriaFormattedString(query.DefaultPropertySearchPreferences)?.info,
            fullInfo: getSearchCriteriaFormattedString(query.DefaultPropertySearchPreferences)
              ?.fullInfo,
            criterias: query.DefaultPropertySearchPreferences,
            photos: query.PreviewPhotoUrls || [],
            total: query.NumOfResults,
          })),
        })) || [],
      isLoading: state === PENDING && !data,
      loaded: state === READY,
    };
  },
);

export const getIsPreApprovalLettersDrawerOpenSelector = createSelector(
  localState,
  ({ preApprovalDrawer }) => preApprovalDrawer?.open,
);
