import React from 'react';
import notification from 'antd/lib/notification';
import type { ArgsProps } from 'antd/es/notification';

import styles from './styles.module.scss';

export const openNotification = (props: ArgsProps) => {
  notification.open({
    ...props,
    className: props.className || styles.notificationPopUp,
  });
};

export const closeNotification = (key: string) => {
  notification.close(key);
};

export const successNotification = (props: ArgsProps) => {
  notification.success(props);
};

export const WarnNotification = (props: ArgsProps) => {
  notification.warning(props);
};
