import styles from './styles.module.scss';
import classNames from 'classnames';
import { Square, Rhombus, Slice, Magnifier } from '../Icons';

function AnalyzingAnimation() {
  return (
    <div className={styles.analyzingAnimation}>
      <div className={classNames(styles.quadrant, styles.quadrant1)} id="quadrant1">
        <Square colorWithHash={'#4673D1'} />
      </div>
      <div className={classNames(styles.quadrant, styles.quadrant2)} id="quadrant2">
        <Rhombus colorWithHash={'#FF576D'} />
      </div>
      <div className={classNames(styles.quadrant, styles.quadrant3)} id="quadrant3">
        <Slice colorWithHash={'#FB913A'} iconClassName={styles.rotate} />
      </div>
      <div className={classNames(styles.quadrant, styles.quadrant4)} id="quadrant4">
        <Magnifier colorWithHash={'#51BFE1'} />
      </div>
    </div>
  );
}

export default AnalyzingAnimation;
