import {
  deleteDeactivateCategory,
  getCategories,
  getCategoryById,
  postCategoryData,
  putActivateCategory,
  putCategoryData,
} from 'api/admin';
import {
  requestDeleteDeactivateCategoryAction,
  requestGetCategoriesAction,
  requestGetCategoryByIdAction,
  requestPostCategoryAction,
  requestPutActivateCategoryAction,
  requestUpdateCategoryAction,
  searchCategoriesAction,
  sortCategoriesAction,
} from 'store/actions/adminPanel';
import { getAllItems, getCategorySendData, getItemById } from 'store/effects/adminPanel/helpers';
import Api from 'store/effects/core/api';
import { getState } from 'store/index';

export const getCategoriesEffect = (cfg = {}, options = {}, cb) => {
  const {
    adminPanel: {
      serviceDirectory: { categories },
    },
  } = getState();
  const { sort, search } = categories;

  return getAllItems(requestGetCategoriesAction, getCategories, options, cb, sort, cfg, search);
};

export const requestGetCategoryByIdEffect = (cfg, cb) =>
  getItemById(requestGetCategoryByIdAction, getCategoryById, cfg, {}, cb);

export const searchCategoriesEffect = (cfg) => (dispatch) => {
  dispatch(searchCategoriesAction(cfg));
  return dispatch(getCategoriesEffect());
};

export const sortCategoriesEffect = (cfg) => (dispatch) => {
  dispatch(sortCategoriesAction(cfg));
  return dispatch(getCategoriesEffect());
};

export const updateCategoryEffect = ({ values, id }, cb) => {
  const sendRequest = Api.execBase({
    action: requestUpdateCategoryAction,
    method: putCategoryData,
  });
  const sendValues = getCategorySendData(values, true);

  return sendRequest(sendValues, { id }, (err, response, dispatch) => {
    if (!err) {
      dispatch(requestGetCategoryByIdEffect({ id }));
      dispatch(getCategoriesEffect());
    }
    cb(err, response, dispatch);
  });
};

export const createCategoryEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({ action: requestPostCategoryAction, method: postCategoryData });
  const sendValues = getCategorySendData(cfg);

  return sendRequest(sendValues, {}, (err, response, dispatch) => {
    if (!err) {
      dispatch(getCategoriesEffect());
    }
    cb(err, response, dispatch);
  });
};

export const deactivateCategoryEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestDeleteDeactivateCategoryAction,
    method: deleteDeactivateCategory,
  });

  return sendRequest(cfg, options, (err, response, dispatch) => cb(err, response, dispatch));
};

export const activateCategoryEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestPutActivateCategoryAction,
    method: putActivateCategory,
  });

  return sendRequest(cfg, options, (err, response, dispatch) => cb(err, response, dispatch));
};
