import { orderBy } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'components-antd';

import { updateTransactionTaskChecklistEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';
import { getTransactionTaskAccess } from 'store/selectors/transactionTasks';
import { showErrorMessage } from 'helpers';
import classNames from 'classnames';

type Checklist = {
  Id: number;
  Name: string;
  Required: boolean;
  IsChecked: boolean;
};

interface ChecklistsProps {
  checklists: Checklist[];
  taskId: number;
  transactionId: number;
  fullAccess?: boolean;
  className?: string;
}

export const Checklists = ({
  checklists,
  taskId,
  transactionId,
  fullAccess,
  className,
}: ChecklistsProps) => {
  const dispatch = useDispatch();
  const { hasAccess } = useSelector(getTransactionTaskAccess);

  const onMarkChange = (checked, checklistId) => {
    if (!hasAccess(taskId) && !fullAccess) {
      showErrorMessage("You don't have access to update this task.");
      return;
    }
    dispatch(
      updateTransactionTaskChecklistEffect(
        {
          taskId,
          transactionId,
          checklistId,
          checked,
        },
        null,
      ),
    );
  };

  return (
    <div className={classNames(styles.taskChecklists, className)}>
      {orderBy(checklists, 'Id').map((checklist, index) => (
        <div className={styles.checklist} key={checklist.Id}>
          <div className={styles.checkContainer}>
            <Checkbox
              checked={checklist.IsChecked}
              defaultChecked={checklist.IsChecked}
              onChange={(e) => onMarkChange(e.target.checked, checklist.Id)}
            />
            <h3>{checklist.Name}</h3>
          </div>
          <p>{checklist.Required ? 'Required' : 'Optional'}</p>
        </div>
      ))}
    </div>
  );
};
