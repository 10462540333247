import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Area, AreaConfig } from '@ant-design/plots';
import { useDispatch } from 'react-redux';
import { toLower, trim } from 'lodash-es';

import { Checkbox, Col, Popover, Row } from 'components-antd';
import { ArrowDown, ArrowUp } from 'components/Icons';
import { Card } from '../../../../Main/Pulse/components/Card';
import { Wrapper as PendingWrapper } from 'components';
import { AreaConfigData, setStats, statPoint } from './helper';
import { getAgentTeamStatsSelector } from 'store/selectors/clarity';
import { setSelectedGraphAction } from 'store/actions/clarity';

import styles from './styles.module.scss';

type AreaGraphTemplateProps = {
  title: string;
  color: string;
  stats: any;
  options?: string[];
  defaultOption?: string;
  showAmount?: boolean;
  showRequests?: boolean;
  modalExists?: boolean;
};

export const AreaGraphTemplate: React.FC<AreaGraphTemplateProps> = ({
  title,
  color,
  stats,
  options = [],
  defaultOption = '',
  showAmount = true,
  showRequests = true,
  modalExists = false,
}) => {
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [filter, setFilter] = useState(defaultOption);
  const [totalRequests, setTotalRequests] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<string>('0');
  const [data, setData] = useState<AreaConfigData[]>([] as AreaConfigData[]);
  const [currentStats, setCurrentStats] = useState<statPoint[]>();

  const teamStats = useSelector(getAgentTeamStatsSelector);
  // const { isPending } = useSelector(getLoadingGraphsSelector);
  const textColor = { color: color };

  useEffect(() => {
    setCurrentStats(stats);
    setStats(stats, filter, setData, setTotalAmount, setTotalRequests, showAmount, showRequests);
  }, []);

  const handleChange = (checkedValues) => {
    setOpenDropdown(false);
    const currentValue = checkedValues.target.value;
    setFilter(currentValue);
    setStats(
      currentStats,
      currentValue,
      setData,
      setTotalAmount,
      setTotalRequests,
      showAmount,
      showRequests,
    );
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpenDropdown(newOpen);
  };

  const popoverContent = () => (
    <ul className={styles.filterOptions}>
      {options.map((value, index) => {
        return (
          <li>
            <Checkbox
              className={styles.sortCheckbox}
              key={index}
              onChange={handleChange}
              checked={value == filter}
              value={value}
            >
              <div className={styles.checkboxHeading}>{value}</div>
            </Checkbox>
          </li>
        );
      })}
    </ul>
  );

  const handleGraphClick = () => {
    dispatch(setSelectedGraphAction({ isGraphSelected: true, graphName: toLower(title) }));
  };
  const config = {
    data,
    xField: 'day',
    yField: 'value',
    color,
    responsive: true,
    autoFit: true,
    height: 80,
    padding: [20, 0],
    smooth: true,
    areaStyle: {
      fill: `l(270) 0:${color}00 1:${color}`,
    },
    xAxis: false,
    yAxis: false,
    tooltip: false,
  } as AreaConfig;

  const renderAggregateStats = () => {
    switch (true) {
      case showAmount && showRequests:
        return (
          <Row className={styles.countContainer}>
            {showAmount && (
              <span className={styles.textLarge} style={textColor}>
                {totalAmount}
              </span>
            )}
            {showRequests && +totalRequests !== 0 && (
              <span className={styles.textSmall} style={textColor}>{`(${totalRequests})`}</span>
            )}
          </Row>
        );
      case showAmount:
        return (
          <Row className={styles.countContainer}>
            <span className={styles.textLarge} style={textColor}>
              {totalAmount}
            </span>
          </Row>
        );
      case showRequests:
        return (
          <Row className={styles.countContainer}>
            {showRequests && +totalRequests !== 0 && (
              <span className={styles.textLarge} style={textColor}>
                {totalRequests}
              </span>
            )}
          </Row>
        );
    }
  };
  return (
    <>
      <Card
        cardTitle={title}
        showHeader={false}
        cardWrapperClassName={styles.cardWrapper}
        cardHeaderClassName={styles.cardHeader}
        cardBodyClassName={styles.cardBody}
      >
        <PendingWrapper
          className={styles.pendingWrapper}
          spinnerClassname={styles.centerSpinner}
          isPending={false}
          loaderClassName={styles.loader}
        >
          <header className={styles.widgetHeader}>
            <div>
              <h3>{title}</h3>
            </div>
            {options.length ? (
              <div>
                <Popover
                  content={popoverContent}
                  trigger="click"
                  open={openDropdown}
                  onOpenChange={handleOpenChange}
                  overlayClassName={styles.filterDropdown}
                  placement="bottomRight"
                  className={styles.dropdown}
                >
                  <span className={styles.switchButton} onClick={(e) => e.preventDefault()}>
                    {filter}
                  </span>
                  {openDropdown ? (
                    <ArrowUp className={styles.icon} />
                  ) : (
                    <ArrowDown className={styles.icon} color={'#747475'} />
                  )}
                </Popover>
              </div>
            ) : (
              <></>
            )}
          </header>

          <div onClick={handleGraphClick} className={styles.graphWrapper}>
            {renderAggregateStats()}

            <Row align="middle">
              <Col xs={24} sm={24}>
                <Area className={styles.areaGraphPadding} {...config}></Area>
              </Col>
            </Row>
          </div>
        </PendingWrapper>
      </Card>
    </>
  );
};
