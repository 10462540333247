import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

const Icon = memo(({ className, variant, light, color }) => {
  const getIcon = useCallback(() => {
    switch (variant) {
      case Icon.HOUSE:
        return light ? (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 25V47.3C12 47.6866 12.3134 48 12.7 48H43.3C43.6866 48 44 47.6866 44 47.3V25"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5 48V36.7C22.5 36.3134 22.8134 36 23.2 36H32.8C33.1866 36 33.5 36.3134 33.5 36.7V48"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.40039 27.75L27.5615 12.3523C27.8176 12.1466 28.1824 12.1466 28.4385 12.3523L47.6004 27.75"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M37 19.2222V12.7C37 12.3134 37.3134 12 37.7 12H43.3C43.6866 12 44 12.3134 44 12.7V25"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 16V29H25V16"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.9961 29V22H18.9961V29"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 17L16 8L20.9976 12.0889M27 17L24.9976 15.3616M20.9976 12.0889V8H24.9976V15.3616M20.9976 12.0889L24.9976 15.3616"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.CONDO:
        return light ? (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.2069 48V10.7C34.2069 10.3134 33.8935 10 33.5069 10L8.7 10C8.3134 10 8 10.3134 8 10.7V47.3C8 47.6866 8.3134 48 8.7 48H34.2069ZM34.2069 48H47.3C47.6866 48 48 47.6866 48 47.3V22.9827C48 22.6616 47.7815 22.3817 47.4701 22.3037L34.2069 18.9818"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 48V38H25.5V48"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 16.875C15 16.3918 15.3838 16 15.8571 16H17.5714C18.0448 16 18.4286 16.3918 18.4286 16.875V18.625C18.4286 19.1082 18.0448 19.5 17.5714 19.5H15.8571C15.3838 19.5 15 19.1082 15 18.625V16.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M15 23.875C15 23.3918 15.3838 23 15.8571 23H17.5714C18.0448 23 18.4286 23.3918 18.4286 23.875V25.625C18.4286 26.1082 18.0448 26.5 17.5714 26.5H15.8571C15.3838 26.5 15 26.1082 15 25.625V23.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M15 30.875C15 30.3918 15.3838 30 15.8571 30H17.5714C18.0448 30 18.4286 30.3918 18.4286 30.875V32.625C18.4286 33.1082 18.0448 33.5 17.5714 33.5H15.8571C15.3838 33.5 15 33.1082 15 32.625V30.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M23.5714 16.875C23.5714 16.3918 23.9552 16 24.4286 16H26.1429C26.6162 16 27 16.3918 27 16.875V18.625C27 19.1082 26.6162 19.5 26.1429 19.5H24.4286C23.9552 19.5 23.5714 19.1082 23.5714 18.625V16.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M23.5714 23.875C23.5714 23.3918 23.9552 23 24.4286 23H26.1429C26.6162 23 27 23.3918 27 23.875V25.625C27 26.1082 26.6162 26.5 26.1429 26.5H24.4286C23.9552 26.5 23.5714 26.1082 23.5714 25.625V23.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M23.5714 30.875C23.5714 30.3918 23.9552 30 24.4286 30H26.1429C26.6162 30 27 30.3918 27 30.875V32.625C27 33.1082 26.6162 33.5 26.1429 33.5H24.4286C23.9552 33.5 23.5714 33.1082 23.5714 32.625V30.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M39.5 26.875C39.5 26.3918 39.8918 26 40.375 26H42.125C42.6082 26 43 26.3918 43 26.875V28.625C43 29.1082 42.6082 29.5 42.125 29.5H40.375C39.8918 29.5 39.5 29.1082 39.5 28.625V26.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M39.5 33.875C39.5 33.3918 39.8918 33 40.375 33H42.125C42.6082 33 43 33.3918 43 33.875V35.625C43 36.1082 42.6082 36.5 42.125 36.5H40.375C39.8918 36.5 39.5 36.1082 39.5 35.625V33.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M39.5 40.875C39.5 40.3918 39.8918 40 40.375 40H42.125C42.6082 40 43 40.3918 43 40.875V42.625C43 43.1082 42.6082 43.5 42.125 43.5H40.375C39.8918 43.5 39.5 43.1082 39.5 42.625V40.875Z"
              fill={color || '#515151'}
            />
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 29V7H5V29H20ZM20 29H27.9976V14L19.9976 12"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 29V24H15V29"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 11.5C9 11.2239 9.22386 11 9.5 11H10.5C10.7761 11 11 11.2239 11 11.5V12.5C11 12.7761 10.7761 13 10.5 13H9.5C9.22386 13 9 12.7761 9 12.5V11.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M9 15.5C9 15.2239 9.22386 15 9.5 15H10.5C10.7761 15 11 15.2239 11 15.5V16.5C11 16.7761 10.7761 17 10.5 17H9.5C9.22386 17 9 16.7761 9 16.5V15.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M9 19.5C9 19.2239 9.22386 19 9.5 19H10.5C10.7761 19 11 19.2239 11 19.5V20.5C11 20.7761 10.7761 21 10.5 21H9.5C9.22386 21 9 20.7761 9 20.5V19.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M14 11.5C14 11.2239 14.2239 11 14.5 11H15.5C15.7761 11 16 11.2239 16 11.5V12.5C16 12.7761 15.7761 13 15.5 13H14.5C14.2239 13 14 12.7761 14 12.5V11.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M14 15.5C14 15.2239 14.2239 15 14.5 15H15.5C15.7761 15 16 15.2239 16 15.5V16.5C16 16.7761 15.7761 17 15.5 17H14.5C14.2239 17 14 16.7761 14 16.5V15.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M14 19.5C14 19.2239 14.2239 19 14.5 19H15.5C15.7761 19 16 19.2239 16 19.5V20.5C16 20.7761 15.7761 21 15.5 21H14.5C14.2239 21 14 20.7761 14 20.5V19.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M23 16.5C23 16.2239 23.2239 16 23.5 16H24.5C24.7761 16 25 16.2239 25 16.5V17.5C25 17.7761 24.7761 18 24.5 18H23.5C23.2239 18 23 17.7761 23 17.5V16.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M23 20.5C23 20.2239 23.2239 20 23.5 20H24.5C24.7761 20 25 20.2239 25 20.5V21.5C25 21.7761 24.7761 22 24.5 22H23.5C23.2239 22 23 21.7761 23 21.5V20.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M23 24.5C23 24.2239 23.2239 24 23.5 24H24.5C24.7761 24 25 24.2239 25 24.5V25.5C25 25.7761 24.7761 26 24.5 26H23.5C23.2239 26 23 25.7761 23 25.5V24.5Z"
              fill={color || '#303030'}
            />
          </svg>
        );
      case Icon.TOWN_HOME:
        return light ? (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.0043 25V48M7 25V47.3C7 47.6866 7.3134 48 7.7 48H28.0043M49 25V47.3C49 47.6866 48.6866 48 48.3 48H28.0043"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 27L17.5004 14L28 24.5L38.5004 14L51 27"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 48V38.7C14 38.3134 14.3134 38 14.7 38L20.3 38C20.6866 38 21 38.3134 21 38.7V48"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M35 48V38.7C35 38.3134 35.3134 38 35.7 38L41.3 38C41.6866 38 42 38.3134 42 38.7V48"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.25 28.875C19.25 28.3918 19.6418 28 20.125 28H21.875C22.3582 28 22.75 28.3918 22.75 28.875V30.625C22.75 31.1082 22.3582 31.5 21.875 31.5H20.125C19.6418 31.5 19.25 31.1082 19.25 30.625V28.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M12.25 28.875C12.25 28.3918 12.6418 28 13.125 28H14.875C15.3582 28 15.75 28.3918 15.75 28.875V30.625C15.75 31.1082 15.3582 31.5 14.875 31.5H13.125C12.6418 31.5 12.25 31.1082 12.25 30.625V28.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M40.25 28.875C40.25 28.3918 40.6418 28 41.125 28H42.875C43.3582 28 43.75 28.3918 43.75 28.875V30.625C43.75 31.1082 43.3582 31.5 42.875 31.5H41.125C40.6418 31.5 40.25 31.1082 40.25 30.625V28.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M33.25 28.875C33.25 28.3918 33.6418 28 34.125 28H35.875C36.3582 28 36.75 28.3918 36.75 28.875V30.625C36.75 31.1082 36.3582 31.5 35.875 31.5H34.125C33.6418 31.5 33.25 31.1082 33.25 30.625V28.875Z"
              fill={color || '#515151'}
            />
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 15L10 9L3.99756 15.4026M16 15L22 9L27.9976 15.3974M16 15V29M3.99756 15.4026L2.5 17M3.99756 15.4026V29H16M27.9976 15.3974L29.5 17M27.9976 15.3974V29H16"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.99609 29V23H11.9961V29"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.9961 29V23H23.9961V29"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 17.5C11 17.2239 11.2239 17 11.5 17H12.5C12.7761 17 13 17.2239 13 17.5V18.5C13 18.7761 12.7761 19 12.5 19H11.5C11.2239 19 11 18.7761 11 18.5V17.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M7 17.5C7 17.2239 7.22386 17 7.5 17H8.5C8.77614 17 9 17.2239 9 17.5V18.5C9 18.7761 8.77614 19 8.5 19H7.5C7.22386 19 7 18.7761 7 18.5V17.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M23 17.5C23 17.2239 23.2239 17 23.5 17H24.5C24.7761 17 25 17.2239 25 17.5V18.5C25 18.7761 24.7761 19 24.5 19H23.5C23.2239 19 23 18.7761 23 18.5V17.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M19 17.5C19 17.2239 19.2239 17 19.5 17H20.5C20.7761 17 21 17.2239 21 17.5V18.5C21 18.7761 20.7761 19 20.5 19H19.5C19.2239 19 19 18.7761 19 18.5V17.5Z"
              fill={color || '#303030'}
            />
          </svg>
        );
      case Icon.MULTI_FAMILY:
        return light ? (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35 17V12H41V17"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 26H9V17H46.9992V26H46M10 26V47.9992H46V26M10 26H46"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 47.4V39H24V47.4"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32 47.4V39H39V47.4"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.75 31.875C22.75 31.3918 23.1418 31 23.625 31H25.375C25.8582 31 26.25 31.3918 26.25 31.875V33.625C26.25 34.1082 25.8582 34.5 25.375 34.5H23.625C23.1418 34.5 22.75 34.1082 22.75 33.625V31.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M36.75 31.875C36.75 31.3918 37.1418 31 37.625 31H39.375C39.8582 31 40.25 31.3918 40.25 31.875V33.625C40.25 34.1082 39.8582 34.5 39.375 34.5H37.625C37.1418 34.5 36.75 34.1082 36.75 33.625V31.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M15.75 31.875C15.75 31.3918 16.1418 31 16.625 31H18.375C18.8582 31 19.25 31.3918 19.25 31.875V33.625C19.25 34.1082 18.8582 34.5 18.375 34.5H16.625C16.1418 34.5 15.75 34.1082 15.75 33.625V31.875Z"
              fill={color || '#515151'}
            />
            <path
              d="M29.75 31.875C29.75 31.3918 30.1418 31 30.625 31H32.375C32.8582 31 33.25 31.3918 33.25 31.875V33.625C33.25 34.1082 32.8582 34.5 32.375 34.5H30.625C30.1418 34.5 29.75 34.1082 29.75 33.625V31.875Z"
              fill={color || '#515151'}
            />
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 9V5H24V9"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.99756 14H5V9H27V14H25.9976M5.99756 14V27H25.9976V14M5.99756 14H25.9976"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 27V22H14V27"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 27V22H22V27"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 17.5C13 17.2239 13.2239 17 13.5 17H14.5C14.7761 17 15 17.2239 15 17.5V18.5C15 18.7761 14.7761 19 14.5 19H13.5C13.2239 19 13 18.7761 13 18.5V17.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M21 17.5C21 17.2239 21.2239 17 21.5 17H22.5C22.7761 17 23 17.2239 23 17.5V18.5C23 18.7761 22.7761 19 22.5 19H21.5C21.2239 19 21 18.7761 21 18.5V17.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M9 17.5C9 17.2239 9.22386 17 9.5 17H10.5C10.7761 17 11 17.2239 11 17.5V18.5C11 18.7761 10.7761 19 10.5 19H9.5C9.22386 19 9 18.7761 9 18.5V17.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M17 17.5C17 17.2239 17.2239 17 17.5 17H18.5C18.7761 17 19 17.2239 19 17.5V18.5C19 18.7761 18.7761 19 18.5 19H17.5C17.2239 19 17 18.7761 17 18.5V17.5Z"
              fill={color || '#303030'}
            />
          </svg>
        );
      case Icon.LAND:
        return light ? (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33.1248 37.7385C34.2733 38.234 35.5433 38.5092 36.8788 38.5092C42.0272 38.5092 46.2008 34.4207 46.2008 29.3773C46.2008 26.8626 45.1632 24.5853 43.4844 22.9339C43.5711 22.4856 43.6164 22.023 43.6164 21.5501C43.6164 17.4674 40.2378 14.1577 36.0701 14.1577C35.2571 14.1577 34.4741 14.2836 33.7404 14.5166C31.9633 12.1428 29.0954 10.6016 25.86 10.6016C21.3103 10.6016 17.4873 13.6493 16.4024 17.7744C12.4996 19.5877 9.80078 23.4828 9.80078 27.9955C9.80078 34.2397 14.9681 39.3016 21.3423 39.3016C22.3719 39.3016 23.3701 39.1695 24.32 38.9217"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29 31L24 27M29 31L32 28M29 31V35M29 48V35M29 35L25.5 32.5"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.9545 21.4828C22.1932 22 25 19.3632 25 16.7454C25 15.4402 24.4584 14.2582 23.5821 13.401C23.6274 13.1683 23.651 12.9282 23.651 12.6828C23.651 10.5636 21.8875 8.84577 19.712 8.84577C19.2876 8.84577 18.8789 8.91113 18.496 9.0321C17.5684 7.79996 16.0714 7 14.3826 7C12.0077 7 10.0122 8.58192 9.4459 10.723C7.40873 11.6642 6 13.6859 6 16.0282C6 19.2692 9.15009 22 12.4773 22"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 18.5L13.5 16M16 18.5L18 17M16 18.5V27"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      default:
        return light ? (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.4961 46V39C24.4961 37.067 26.0631 35.5 27.9961 35.5C29.9291 35.5 31.4961 37.067 31.4961 39V46"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.75 29.375C22.75 28.8918 23.1418 28.5 23.625 28.5H25.375C25.8582 28.5 26.25 28.8918 26.25 29.375V31.125C26.25 31.6082 25.8582 32 25.375 32H23.625C23.1418 32 22.75 31.6082 22.75 31.125V29.375Z"
              fill={color || '#515151'}
            />
            <path
              d="M29.75 29.375C29.75 28.8918 30.1418 28.5 30.625 28.5H32.375C32.8582 28.5 33.25 28.8918 33.25 29.375V31.125C33.25 31.6082 32.8582 32 32.375 32H30.625C30.1418 32 29.75 31.6082 29.75 31.125V29.375Z"
              fill={color || '#515151'}
            />
            <path
              d="M17 22.9999H15.5V15.0007H20.6504V17.9999H25V15.0007L31 15.0002V17.9994L35.3504 17.9999V15.0007H40.5V22.9999H39M17 22.9999H39M17 22.9999V33.9999M17 45.9999H39M17 45.9999V33.9999M17 45.9999H6.99609V31.9999H12V33.9999H17M39 45.9999V33.9999M39 45.9999H48.9961V31.9999H44V33.9999H39M39 22.9999V33.9999"
              stroke={color || '#515151'}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.9961 28V24C13.9961 22.8954 14.8915 22 15.9961 22C17.1007 22 17.9961 22.8954 17.9961 24V28"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 18.5C13 18.2239 13.2239 18 13.5 18H14.5C14.7761 18 15 18.2239 15 18.5V19.5C15 19.7761 14.7761 20 14.5 20H13.5C13.2239 20 13 19.7761 13 19.5V18.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M17 18.5C17 18.2239 17.2239 18 17.5 18H18.5C18.7761 18 19 18.2239 19 18.5V19.5C19 19.7761 18.7761 20 18.5 20H17.5C17.2239 20 17 19.7761 17 19.5V18.5Z"
              fill={color || '#303030'}
            />
            <path
              d="M9.99609 15H8.99854V10H11.7985V12H14.5985V10H17.3985V12H20.1985V10H22.9985V15H21.9961M9.99609 15H21.9961M9.99609 15V21M9.99609 28H21.9961M9.99609 28V21M9.99609 28H3.99609V20H6.99609V21H9.99609M21.9961 28V21M21.9961 28H27.9961V20H24.9961V21H21.9961M21.9961 15V21"
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
    }
  }, [variant, color, light]);

  return <div className={className}>{getIcon()}</div>;
});

Icon.HOUSE = 'Single';
Icon.CONDO = 'Condo';
Icon.TOWN_HOME = 'Townhouse';
Icon.MULTI_FAMILY = 'MultiFamily';
Icon.LAND = 'Land';
Icon.OTHER = 'Other';

Icon.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  light: PropTypes.bool,
};

Icon.defaultProps = {
  className: '',
  color: '',
  variant: undefined,
  light: false,
};

export default Icon;
