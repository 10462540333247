import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const Icons = (props) => {
  const { className, variant, onClick, testid } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.DOTS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
              fill="#303030"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
              fill="#303030"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
              fill="#303030"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.CLOSE: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.27934 5.27934C4.90689 5.65179 4.90689 6.25566 5.27934 6.6281L8.6513 9.99999L5.27935 13.372C4.9069 13.7444 4.9069 14.3483 5.27935 14.7207C5.6518 15.0931 6.25567 15.0931 6.62811 14.7207L10 11.3487L13.3719 14.7207C13.7444 15.0931 14.3483 15.0931 14.7207 14.7207C15.0931 14.3482 15.0931 13.7444 14.7207 13.372L11.3488 9.99999L14.7207 6.6281C15.0931 6.25566 15.0931 5.65179 14.7207 5.27934C14.3483 4.9069 13.7444 4.9069 13.3719 5.27934L10 8.65124L6.6281 5.27934C6.25566 4.90689 5.65179 4.90689 5.27934 5.27934Z"
              fill="#303030"
            />
          </svg>
        );
      }
      case Icons.CHECKMARK: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.33203 8.0013L6.66536 11.3346L13.332 4.66797"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icons, className)}>
      {getIcons()}
    </div>
  );
};

Icons.DOTS = 'dots';
Icons.CHECKMARK = 'checkmark';
Icons.CLOSE = 'close';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  testid: PropTypes.string,
  onClick: PropTypes.func,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};
