import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

const Icon = memo(({ className, variant, color, textColor, isRatingAlphabat, ...props }) => {
  const getIcon = useCallback(() => {
    switch (variant) {
      case Icon.REPORT:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            {...props}
          >
            <g clipPath="url(#clip0_46830_49312)">
              <path
                d="M3.33203 17.5013V6.66797C3.33203 6.00493 3.59542 5.36904 4.06426 4.9002C4.53311 4.43136 5.16899 4.16797 5.83203 4.16797H14.1654C14.8284 4.16797 15.4643 4.43136 15.9331 4.9002C16.402 5.36904 16.6654 6.00493 16.6654 6.66797V11.668C16.6654 12.331 16.402 12.9669 15.9331 13.4357C15.4643 13.9046 14.8284 14.168 14.1654 14.168H6.66536L3.33203 17.5013Z"
                stroke="#252D44"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 11.5V9.5"
                stroke="#252D44"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle
                cx="9.99109"
                cy="7.00884"
                r="1"
                transform="rotate(0.508496 9.99109 7.00884)"
                fill="#252D44"
              />
            </g>
            <defs>
              <clipPath id="clip0_46830_49312">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case Icon.MAPCOLLAPSE:
        return (
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <rect
              x="0.25"
              y="0.25"
              width="27.5"
              height="27.5"
              rx="5.75"
              fill="white"
              stroke="#EEF0EF"
              strokeWidth="0.5"
            />
            <line
              x1="22.125"
              y1="7.06066"
              x2="16.1857"
              y2="13"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.125 13H16V7.16667"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1="6.125"
              y1="21.6893"
              x2="12.0643"
              y2="15.75"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.125 15.75L12.25 15.75L12.25 21.5833"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.MAPEXPAND:
        return (
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <rect
              x="0.25"
              y="0.25"
              width="27.5"
              height="27.5"
              rx="5.75"
              fill="white"
              stroke="#EEF0EF"
              strokeWidth="0.5"
            />
            <line
              x1="15.75"
              y1="12.0643"
              x2="21.6893"
              y2="6.125"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.75 6.125L21.875 6.125V11.9583"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1="12.25"
              y1="15.9357"
              x2="6.31066"
              y2="21.875"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.25 21.875L6.125 21.875L6.125 16.0417"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.MAP:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5 19L19.5 17V5L14.5 7M14.5 19L9.5 17M14.5 19V7M9.5 17L4.5 19V7L9.5 5M9.5 17V5M9.5 5L14.5 7"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.LIST:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M18 7H9" stroke="#303030" strokeWidth="2" strokeLinecap="round" />
            <path d="M18 12H9" stroke="#303030" strokeWidth="2" strokeLinecap="round" />
            <path d="M18 17H9" stroke="#303030" strokeWidth="2" strokeLinecap="round" />
            <circle cx="5" cy="7" r="1" fill="#303030" />
            <circle cx="5" cy="12" r="1" fill="#303030" />
            <circle cx="5" cy="17" r="1" fill="#303030" />
          </svg>
        );
      case Icon.NICHE:
        return (
          <svg
            width="70"
            height="13"
            viewBox="0 0 90 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_46646_33400)">
              <path
                d="M14.838 1.34827C14.5062 1.34827 14.2373 1.55009 14.2373 2.05771V11.6419C14.2373 11.9733 14.5062 12.2422 14.838 12.2422C15.1696 12.2422 15.4386 11.9733 15.4386 11.6419V2.07198C15.4386 1.57846 15.1696 1.34827 14.838 1.34827Z"
                fill="#003024"
              />
              <path
                d="M9.93687 1.34827C9.60508 1.34827 9.33615 1.55009 9.33615 2.05771V9.90238L2.11275 1.58933C2.06519 1.5331 2.00878 1.4891 1.94728 1.45682C1.84926 1.38462 1.73 1.34827 1.60072 1.34827C1.26893 1.34827 1 1.55009 1 2.05771V11.6419C1 11.9733 1.26893 12.2422 1.60072 12.2422C1.93234 12.2422 2.20126 11.9733 2.20126 11.6419V3.42411L9.46204 12.0328C9.54749 12.1342 9.66148 12.1962 9.7821 12.2196C9.83171 12.2329 9.88302 12.2422 9.93687 12.2422C10.2685 12.2422 10.5374 11.9733 10.5374 11.6419V2.07198C10.5374 1.57846 10.2685 1.34827 9.93687 1.34827Z"
                fill="#003024"
              />
              <path
                d="M40.8383 1.34827C40.5069 1.34827 40.238 1.55009 40.238 2.05771V6.2099H33.1029V2.07198C33.1029 1.57846 32.8343 1.34827 32.5025 1.34827C32.4998 1.34827 32.4974 1.34877 32.4949 1.34877C32.4925 1.34877 32.4903 1.34827 32.4881 1.34827C32.1566 1.34827 31.8877 1.55009 31.8877 2.05771V11.6419C31.8877 11.9733 32.1566 12.2422 32.4881 12.2422C32.4906 12.2422 32.4927 12.2416 32.4952 12.2416C32.4979 12.2416 32.5001 12.2422 32.5025 12.2422C32.8343 12.2422 33.1029 11.9733 33.1029 11.6419V7.22141H40.238V11.6419C40.238 11.9733 40.5069 12.2422 40.8383 12.2422C41.1703 12.2422 41.4389 11.9733 41.4389 11.6419V2.07198C41.4389 1.57846 41.1703 1.34827 40.8383 1.34827Z"
                fill="#003024"
              />
              <path
                d="M22.229 2.67247C23.9455 1.81268 25.9753 2.30331 27.286 3.64184C27.5371 3.89786 27.9333 3.95172 28.1886 3.7188C28.4 3.52564 28.455 3.16039 28.1808 2.88075C26.5659 1.23133 24.1616 0.782995 22.019 1.55887C20.1433 2.23773 18.7645 4.03343 18.5027 5.99324C18.2335 8.00775 19.1214 10.0216 20.7651 11.2091C22.5664 12.5104 25.0269 12.6164 26.9532 11.5227C27.3511 11.2968 27.717 11.0156 28.0486 10.7018C28.3495 10.4164 28.1935 10.0167 27.9452 9.83828C27.7107 9.6706 27.3993 9.70764 27.1533 9.94055C25.7841 11.2378 23.8208 11.5732 22.1252 10.7241C20.5838 9.95227 19.6968 8.29282 19.7172 6.60024C19.7373 4.93213 20.753 3.41165 22.229 2.67247Z"
                fill="#003024"
              />
              <path
                d="M53.2008 2.36004C53.6164 2.36004 53.8106 2.13358 53.8106 1.85411C53.8106 1.57482 53.6405 1.34802 53.2127 1.34802H45.8619C45.8607 1.34802 45.8594 1.34836 45.8584 1.34853C45.8568 1.34836 45.8556 1.34802 45.8543 1.34802C45.5228 1.34802 45.2539 1.54985 45.2539 2.05747V11.6416C45.2539 11.9731 45.5228 12.242 45.8543 12.242C45.8568 12.242 45.8589 12.2413 45.8616 12.2413C45.8641 12.2413 45.8662 12.242 45.8687 12.242C45.8753 12.242 45.8815 12.2403 45.8881 12.2399H53.2008C53.6164 12.2399 53.8106 12.0135 53.8106 11.7342C53.8106 11.4547 53.6405 11.2283 53.2127 11.2283H46.4691V7.221H51.914C52.3295 7.221 52.5233 6.9947 52.5233 6.71541C52.5233 6.43578 52.3536 6.20932 51.9262 6.20932H46.4691V2.3597H53.2008V2.36004Z"
                fill="#003024"
              />
              <path
                d="M56.529 3.64367C55.8735 3.64367 55.3398 3.11005 55.3398 2.45446C55.3398 1.79887 55.8735 1.26526 56.529 1.26526C57.1846 1.26526 57.7183 1.79887 57.7183 2.45446C57.7183 3.11005 57.1846 3.64367 56.529 3.64367ZM56.529 1.41748C55.9669 1.41748 55.5097 1.89248 55.5097 2.45446C55.5097 3.01645 55.9669 3.50198 56.529 3.50198C57.0912 3.50198 57.5484 3.01645 57.5484 2.45446C57.5484 1.89248 57.0912 1.41748 56.529 1.41748Z"
                fill="#003024"
              />
              <path
                d="M56.685 2.52025C56.9038 2.4985 57.0168 2.33116 57.0168 2.14361C57.0168 1.9355 56.8568 1.77376 56.6551 1.76051V1.75916H56.1886C56.1524 1.75916 56.123 1.78855 56.123 1.82473C56.123 1.82881 56.1235 1.83272 56.1242 1.83645C56.1235 1.84189 56.123 1.84733 56.123 1.85361V3.0559C56.123 3.14169 56.1429 3.16565 56.2005 3.16565C56.2476 3.16565 56.2761 3.13625 56.2761 3.05522C56.2761 2.9504 56.2761 2.52245 56.2761 2.52245H56.5287L56.7973 3.05726C56.7973 3.05726 56.8551 3.18671 56.9256 3.15154C56.9988 3.11485 56.9281 2.99185 56.9281 2.99185L56.685 2.52025ZM56.2759 1.89065H56.6346V1.89184C56.7613 1.89541 56.8637 2.01432 56.8637 2.14395C56.8637 2.27136 56.7647 2.38485 56.6409 2.39181H56.2759V1.89065Z"
                fill="#003024"
              />
              <path
                d="M72.1387 3.34828C72.4275 3.56914 72.7673 3.78999 73.209 4.01084C74.1604 4.48652 75.2137 4.70737 76.2669 4.6734C77.3712 4.62243 78.3905 4.24868 79.3249 3.87493C80.0724 3.56914 80.9728 3.24635 81.9242 3.24635C82.0091 3.24635 82.094 3.24635 82.196 3.24635C82.9605 3.29732 83.7759 3.55215 84.6254 4.01084C84.9991 4.2147 85.3049 4.41857 85.5597 4.65641C85.6617 4.74135 85.7806 4.79232 85.8995 4.79232C86.0524 4.79232 86.2053 4.69039 86.2563 4.57146C86.3072 4.41857 86.3072 4.28266 86.2223 4.16374C86.1373 4.04482 86.0864 3.99385 86.0354 3.95987L86.0184 3.94289C85.6787 3.65408 85.2879 3.45021 84.9991 3.28033C84.0817 2.77067 83.0454 2.49885 82.0091 2.46487C80.9218 2.4309 79.9195 2.77067 78.9851 3.14442L78.9681 3.16141C78.0508 3.51817 77.1164 3.90891 76.0801 3.9259H76.0121C74.8059 3.9259 73.5148 3.45021 72.6484 2.66874C72.5634 2.58379 72.4445 2.54982 72.3086 2.56681C72.1897 2.58379 72.0877 2.65175 72.0198 2.75368C71.9179 2.92357 71.9688 3.12743 72.0198 3.19539C72.0198 3.22936 72.0708 3.29732 72.1387 3.34828Z"
                fill="#003024"
              />
              <path
                d="M72.1387 6.69508C72.4275 6.91594 72.7673 7.13679 73.209 7.35764C74.1604 7.83332 75.2137 8.05418 76.2669 8.0202C77.3712 7.96923 78.3905 7.59548 79.3249 7.22173C80.0724 6.91594 80.9558 6.59315 81.9242 6.59315C82.0091 6.59315 82.094 6.59315 82.196 6.59315C82.9605 6.64412 83.7759 6.89895 84.6254 7.35764C84.9991 7.5615 85.3049 7.76537 85.5597 8.00321C85.6617 8.08815 85.7806 8.13912 85.8995 8.13912C86.0524 8.13912 86.2053 8.03719 86.2563 7.91827C86.3242 7.76537 86.3072 7.62946 86.2223 7.51054C86.1373 7.39162 86.0864 7.34065 86.0354 7.30668L86.0184 7.28969C85.6787 7.00088 85.2879 6.79702 84.9991 6.62713C84.0817 6.11747 83.0454 5.84565 82.0091 5.81168C80.9218 5.7777 79.9195 6.11747 78.9851 6.49122L78.9681 6.50821C78.0508 6.86497 77.1164 7.25571 76.0801 7.2727H76.0121C74.8059 7.2727 73.5148 6.79702 72.6484 6.01554C72.5634 5.9306 72.4275 5.89662 72.3086 5.91361C72.1897 5.9306 72.0877 5.99855 72.0198 6.10048C71.9179 6.27037 71.9688 6.47423 72.0198 6.54219C72.0198 6.57616 72.0708 6.62713 72.1387 6.69508Z"
                fill="#003024"
              />
              <path
                d="M86.0016 10.6365C85.6449 10.3477 85.2541 10.1269 84.9653 9.95696C84.0479 9.44731 83.0116 9.17549 81.9753 9.14151C80.888 9.10753 79.8857 9.44731 78.9513 9.82106L78.9174 9.83804C78 10.1948 77.0656 10.5855 76.0293 10.6025H75.9613C74.7551 10.6025 73.464 10.1269 72.5976 9.34537C72.5126 9.26043 72.3937 9.22645 72.2578 9.24344C72.1389 9.26043 72.037 9.32838 71.969 9.43032C71.8671 9.6002 71.918 9.80407 71.969 9.87202C72.003 9.92299 72.037 9.97395 72.1049 10.0419C72.3937 10.2628 72.7335 10.4836 73.1752 10.7045C74.1266 11.1801 75.1799 11.401 76.2332 11.367C77.3374 11.3161 78.3567 10.9423 79.2911 10.5686C80.0386 10.2628 80.939 9.93998 81.8904 9.93998C81.9753 9.93998 82.0603 9.93998 82.1622 9.93998C82.9267 9.99094 83.7421 10.2458 84.5916 10.7045C84.9653 10.9083 85.2711 11.1122 85.5259 11.35C85.6279 11.435 85.7468 11.4859 85.8657 11.4859C86.0186 11.4859 86.1715 11.384 86.2225 11.2651C86.2734 11.1122 86.2734 10.9763 86.1885 10.8574C86.1205 10.7384 86.0696 10.6875 86.0016 10.6365Z"
                fill="#003024"
              />
              <path
                d="M68.7072 1.76839C67.1952 0.528223 65.1226 0.0355525 63.1689 0.44328C61.3001 0.834019 59.6522 2.07419 58.7518 3.77305C57.8175 5.50589 57.7495 7.62947 58.548 9.43027C59.3295 11.1801 60.8414 12.5052 62.7272 13.0658C63.3048 13.2357 63.9164 13.3207 64.511 13.3207C65.8531 13.3207 67.2122 12.9129 68.3504 12.1145C70.0493 10.9423 71.1026 8.90362 71.1026 6.81402C71.0856 4.86033 70.2192 3.02555 68.7072 1.76839ZM70.3041 6.81402C70.2871 8.66578 69.3867 10.4326 67.8917 11.5369C66.4307 12.6071 64.4091 12.896 62.6252 12.2844C60.9604 11.7067 59.5843 10.2457 59.0406 8.49589C58.497 6.6951 58.8708 4.72442 60.043 3.21243C61.1133 1.81936 62.7951 1.02089 64.5959 1.02089C64.6809 1.02089 64.7658 1.02089 64.8508 1.02089C66.6516 1.10584 68.3504 2.07419 69.3867 3.65413C69.9643 4.57152 70.2871 5.65879 70.3041 6.81402Z"
                fill="#003024"
              />
              <path
                d="M67.2121 3.31426C66.9742 3.31426 66.7874 3.50113 66.7874 3.73897V8.7846L62.2854 3.45017C62.2004 3.34824 62.0475 3.28028 61.9286 3.31426C61.6908 3.31426 61.5039 3.50113 61.5039 3.73897V9.99079C61.5039 10.2286 61.6908 10.4155 61.9286 10.4155C62.1665 10.4155 62.3533 10.2286 62.3533 9.99079V4.8942L66.8723 10.2456C66.9233 10.2966 66.9742 10.3476 67.0422 10.3645C67.0932 10.3985 67.1611 10.3985 67.2121 10.3985C67.4499 10.3985 67.6368 10.2116 67.6368 9.9738V3.73897C67.6198 3.50113 67.4329 3.31426 67.2121 3.31426Z"
                fill="#003024"
              />
            </g>
            <defs>
              <clipPath id="clip0_46646_33400">
                <rect width="90" height="13" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case Icon.GREATSCHOOLS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="20"
            viewBox="0 0 46 20"
            fill="none"
          >
            <path
              d="M0.3125 17.4031L1.09689 16.4788C1.53912 16.8112 2.07972 16.9901 2.63507 16.9879C2.90054 16.9879 3.01416 16.9193 3.01416 16.8015V16.789C3.01416 16.6647 2.87541 16.5971 2.40347 16.5042C1.41807 16.3058 0.550656 16.0263 0.550656 15.1085V15.0966C0.550656 14.2717 1.20504 13.6328 2.41603 13.6328C3.26488 13.6328 3.89195 13.8312 4.40103 14.2285L3.68493 15.2079C3.30014 14.9349 2.83978 14.7856 2.36633 14.7804C2.14674 14.7804 2.04514 14.8544 2.04514 14.9598V14.9723C2.04514 15.0907 2.16531 15.1647 2.62906 15.2512C3.75539 15.4566 4.50864 15.7782 4.50864 16.6528V16.6652C4.50864 17.5766 3.74938 18.135 2.56679 18.135C1.6748 18.135 0.871294 17.8863 0.3125 17.4031Z"
              fill="#2BADE3"
            />
            <path
              d="M4.65332 15.8979V15.8854C4.65332 14.5892 5.67805 13.6216 6.99009 13.6216C7.95692 13.6216 8.61131 14.0865 8.98165 14.7503L7.76902 15.4514C7.60515 15.1411 7.37355 14.9238 6.97152 14.9238C6.47445 14.9238 6.14781 15.3519 6.14781 15.873V15.8854C6.14781 16.4498 6.48756 16.8465 6.97152 16.8465C7.38556 16.8465 7.61771 16.6227 7.79961 16.3006L9.01278 16.9762C8.64244 17.6211 8.0252 18.1487 6.93219 18.1487C5.71519 18.1487 4.65332 17.2433 4.65332 15.8979Z"
              fill="#2BADE3"
            />
            <path
              d="M9.20459 13.7137H10.6744V15.2456H12.018V13.7137H13.4878V18.0543H12.018V16.4978H10.6744V18.0543H9.20459V13.7137Z"
              fill="#2BADE3"
            />
            <path
              d="M17.1138 15.8979V15.8854C17.1138 15.3703 16.756 14.9054 16.1884 14.9054C15.6297 14.9054 15.2779 15.3643 15.2779 15.873V15.8854C15.2779 16.4 15.6357 16.8649 16.201 16.8649C16.7598 16.8649 17.1138 16.406 17.1138 15.8973V15.8979ZM13.7954 15.8979V15.8854C13.7954 14.626 14.8453 13.6216 16.201 13.6216C17.5589 13.6216 18.5962 14.6141 18.5962 15.873V15.8854C18.5962 17.1443 17.5464 18.1487 16.1884 18.1487C14.8327 18.1487 13.7954 17.1562 13.7954 15.8979Z"
              fill="#2BADE3"
            />
            <path
              d="M22.1252 15.8979V15.8854C22.1252 15.3703 21.7675 14.9054 21.2021 14.9054C20.6411 14.9054 20.291 15.3643 20.291 15.873V15.8854C20.291 16.4 20.6471 16.8649 21.2141 16.8649C21.7735 16.8649 22.1252 16.406 22.1252 15.8973V15.8979ZM18.8069 15.8979V15.8854C18.8069 14.626 19.8562 13.6216 21.2147 13.6216C22.5704 13.6216 23.6072 14.6141 23.6072 15.873V15.8854C23.6072 17.1443 22.5584 18.1487 21.2021 18.1487C19.8442 18.1487 18.8069 17.1562 18.8069 15.8979Z"
              fill="#2BADE3"
            />
            <path
              d="M23.9148 13.7137H25.3846V16.8141H27.4507V18.0543H23.9148V13.7137Z"
              fill="#2BADE3"
            />
            <path
              d="M27.8181 17.4031L28.6025 16.4788C29.0454 16.8115 29.5868 16.9905 30.1429 16.9879C30.4056 16.9879 30.5198 16.9193 30.5198 16.8015V16.789C30.5198 16.6647 30.381 16.5971 29.9107 16.5042C28.9231 16.3058 28.0557 16.0263 28.0557 15.1085V15.0966C28.0557 14.2717 28.7101 13.6328 29.9233 13.6328C30.7699 13.6328 31.3992 13.8312 31.9088 14.2285L31.19 15.2079C30.8054 14.9346 30.3449 14.7853 29.8714 14.7804C29.6524 14.7804 29.5529 14.8544 29.5529 14.9598V14.9723C29.5529 15.0907 29.6709 15.1647 30.1363 15.2512C31.2605 15.4566 32.0143 15.7782 32.0143 16.6528V16.6652C32.0143 17.5766 31.2545 18.135 30.0724 18.135C29.1799 18.135 28.3769 17.8863 27.8181 17.4031Z"
              fill="#2BADE3"
            />
            <path
              d="M36.2292 16.3134V16.3009C36.2292 15.9539 35.9681 15.6772 35.6414 15.6772C35.3099 15.6772 35.0679 15.9539 35.0679 16.288V16.3009C35.0679 16.6485 35.3285 16.9247 35.6556 16.9247C35.9872 16.9247 36.2292 16.6485 36.2292 16.3139V16.3134ZM33.6477 16.3134V16.3009C33.6477 15.3101 34.4818 14.4615 35.6556 14.4615C36.8213 14.4615 37.6494 15.2977 37.6494 16.2885V16.3009C37.6494 17.2918 36.8153 18.1404 35.6414 18.1404C34.4758 18.1404 33.6477 17.3047 33.6477 16.3134Z"
              fill="#2BADE3"
            />
            <path
              d="M38.1194 14.5446H39.5975V15.2527C39.8007 14.7765 40.1257 14.4673 40.7118 14.4938V16.0435H40.5561C39.9312 16.0435 39.5975 16.384 39.5975 17.1624V18.057H38.1194V14.5446Z"
              fill="#2BADE3"
            />
            <path
              d="M43.5532 16.1015V16.0885C43.5532 15.7864 43.3189 15.542 42.9589 15.542C42.5946 15.542 42.3668 15.7928 42.3668 16.1015V16.1145C42.3668 16.4166 42.6006 16.655 42.9589 16.655C43.3249 16.655 43.5527 16.4107 43.5527 16.1015H43.5532ZM40.9587 18.6615L41.4164 17.7804C41.814 17.9858 42.2112 18.115 42.7 18.115C43.2528 18.115 43.5467 17.8318 43.5467 17.3431V17.2404C43.3435 17.4647 43.0125 17.7226 42.516 17.7226C41.5983 17.7226 40.9319 17.0793 40.9319 16.108V16.095C40.9319 15.1112 41.6108 14.4615 42.4329 14.4615C42.9524 14.4615 43.2588 14.6539 43.5199 14.9182V14.5453H45.0002V17.1696C45.0002 17.8188 44.8637 18.2626 44.5359 18.5842C44.2175 18.8993 43.7286 19.0988 42.9338 19.0988C42.1451 19.0988 41.5322 18.9447 40.9587 18.6615Z"
              fill="#2BADE3"
            />
            <path
              d="M33.4228 17.3116C33.4228 17.6441 33.1497 17.9143 32.8122 17.9143C32.7295 17.9186 32.6468 17.9061 32.5691 17.8777C32.4915 17.8494 32.4205 17.8056 32.3604 17.7492C32.3004 17.6928 32.2526 17.6249 32.2199 17.5496C32.1873 17.4744 32.1704 17.3933 32.1704 17.3114C32.1704 17.2294 32.1873 17.1484 32.2199 17.0731C32.2526 16.9978 32.3004 16.9299 32.3604 16.8735C32.4205 16.8171 32.4915 16.7734 32.5691 16.745C32.6468 16.7166 32.7295 16.7041 32.8122 16.7084C33.1492 16.7084 33.4228 16.9787 33.4228 17.3111V17.3116Z"
              fill="#2BADE3"
            />
            <path
              d="M0 6.40503C0 3.37908 2.38156 0.901245 5.63928 0.901245C7.57676 0.901245 8.7446 1.418 9.86547 2.3607L8.37644 4.13908C7.54617 3.4553 6.80712 3.06016 5.56336 3.06016C3.84164 3.06016 2.47388 4.56503 2.47388 6.37422V6.40503C2.47388 8.35097 3.82744 9.78016 5.7316 9.78016C6.59355 9.78016 7.36046 9.56719 7.96131 9.14179V7.62124H5.57974V5.59962H10.25V10.2212C9.1439 11.1488 7.62155 11.9088 5.65567 11.9088C2.30564 11.9088 0 9.58233 0 6.4353V6.40503Z"
              fill="#4E575E"
            />
            <path
              d="M14.2428 5.43937C14.7142 4.36478 15.4658 3.65234 16.791 3.65234C16.8462 3.65234 16.8997 3.65342 16.9549 3.65613V6.05937H16.8292C15.2261 6.05937 14.2423 6.98099 14.2423 8.91559V11.908H11.8296V3.80694H14.2423V5.43937H14.2428Z"
              fill="#4E575E"
            />
            <path
              d="M17.3467 7.77991C17.3467 5.51613 19.0613 3.65234 21.5177 3.65234C24.3341 3.65234 25.6253 5.70856 25.6253 7.95775C25.6253 8.13451 25.6106 8.34207 25.5937 8.5491H19.7228C19.9588 9.57018 20.7147 10.1026 21.7848 10.1026C22.5878 10.1026 23.1689 9.86586 23.8304 9.2891L25.1998 10.428C24.4138 11.3453 23.2809 11.908 21.7531 11.908C19.2192 11.908 17.3467 10.2356 17.3467 7.80964V7.77991ZM21.5177 5.45721C20.5252 5.45721 19.8806 6.12315 19.6911 7.1437H23.2957C23.1542 6.13775 22.5244 5.45721 21.5177 5.45721Z"
              fill="#4E575E"
            />
            <path
              d="M26.7163 4.29559C27.6132 3.90694 28.4937 3.65234 29.8762 3.65234C31.1369 3.65234 32.0497 3.98207 32.6248 4.55018C33.2328 5.14856 33.5075 6.03126 33.5075 7.10802V11.7588H31.2729V10.8918C30.7119 11.5048 29.9368 11.908 28.8127 11.908C27.2778 11.908 26.0171 11.041 26.0171 9.45559V9.42586C26.0171 7.67613 27.369 6.86856 29.2988 6.86856C30.1198 6.86856 30.7119 7.00261 31.2893 7.19721V7.06315C31.2893 6.12153 30.6956 5.59829 29.5425 5.59829C28.6603 5.59829 28.0381 5.76207 27.2936 6.03126L26.7163 4.29613V4.29559ZM28.2495 9.36586C28.2495 9.97937 28.7685 10.3383 29.5119 10.3383C30.5896 10.3383 31.3199 9.75451 31.3199 8.93234V8.52856C30.925 8.3491 30.4088 8.2291 29.8451 8.2291C28.8575 8.2291 28.2495 8.61829 28.2495 9.33613V9.3664V9.36586Z"
              fill="#4E575E"
            />
            <path
              d="M35.2551 1.68805H37.5335V3.74102H39.4212V5.68913H37.5335V9.12156C37.5335 9.64589 37.7585 9.90102 38.2676 9.90102C38.6871 9.90102 39.0624 9.79562 39.3912 9.61562V11.4443C38.9116 11.7291 38.3583 11.9091 37.5941 11.9091C36.1996 11.9091 35.2551 11.3545 35.2551 9.49616V5.68913H34.2954V3.74102H35.2546V1.68805H35.2551Z"
              fill="#4E575E"
            />
            <path
              d="M43.8371 1.68859C42.9866 1.70481 42.4562 2.08318 42.4753 2.95886L42.1236 7.94427C42.1367 8.55832 42.2874 8.73994 42.8102 8.73021C43.168 8.72372 43.328 8.53616 43.511 7.88264L45.1863 2.96481C45.6173 2.20913 45.0989 1.68805 43.9136 1.68805L43.8371 1.68859ZM42.4224 9.64427L42.4797 9.64372C43.1914 9.64372 43.5367 9.89886 43.5476 10.4221C43.5558 10.8216 43.4684 11.3145 43.1488 11.6124C42.9402 11.8167 42.731 11.8972 42.1257 11.9086C41.3927 11.9226 41.0114 11.6837 41.0005 11.1459C40.9918 10.7464 41.0939 10.2529 41.3993 9.95508C41.6079 9.75129 41.8335 9.65508 42.4218 9.64427H42.4224Z"
              fill="#2BADE3"
            />
          </svg>
        );
      case Icon.RATING:
        if (isRatingAlphabat) {
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <circle cx="16" cy="16" r="16" fill={color} />
              <text
                x="16"
                y="21.5"
                textAnchor="middle"
                fill={textColor ? textColor : 'white'}
                fontSize="15"
                fontWeight="400"
              >
                {props.children}
              </text>
            </svg>
          );
        } else {
          const childrenText = props.children || '';
          let _firstPart;
          let _secondPart;
          if (childrenText.includes('/') && !childrenText.includes('10/10')) {
            const [firstPart, secondPart] = childrenText.split('/');
            _firstPart = firstPart;
            _secondPart = secondPart;
          } else if (childrenText.includes('10/10')) {
            const [firstPart, secondPart] = childrenText.split('/');
            _firstPart = firstPart;
            _secondPart = '';
          } else {
            _firstPart = props.children;
            _secondPart = '';
          }
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <circle cx="16" cy="16" r="16" fill={color} />
              {_secondPart === '' ? (
                <text
                  x="16"
                  y="21.5"
                  textAnchor="middle"
                  fill={textColor ? textColor : 'white'}
                  fontSize="15"
                  fontWeight="400"
                >
                  {_firstPart}
                </text>
              ) : (
                <>
                  <text
                    x="9"
                    y="21.5"
                    textAnchor="middle"
                    fill={textColor ? textColor : 'white'}
                    fontSize="15"
                    fontWeight="400"
                  >
                    {_firstPart}
                  </text>
                  <text
                    x="21"
                    y="21.5"
                    textAnchor="middle"
                    fill={textColor ? textColor : 'white'}
                    fontSize="9.5"
                    fontWeight="200"
                  >
                    /{_secondPart}
                  </text>
                </>
              )}
            </svg>
          );
        }
      default:
        return null;
    }
  }, [variant]);

  return <div className={className}>{getIcon()}</div>;
});

Icon.MAP = 'Map';
Icon.LIST = 'List';
Icon.RATING = 'Rating';
Icon.NICHE = 'Niche';
Icon.GREATSCHOOLS = 'GreatSchools';
Icon.MAPEXPAND = 'MapExpand';
Icon.MAPCOLLAPSE = 'MapCollapse';
Icon.REPORT = 'Report';

Icon.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  isRatingAlphabat: PropTypes.bool,
};

Icon.defaultProps = {
  className: '',
  variant: undefined,
  color: '',
  textColor: '',
};

export default Icon;
