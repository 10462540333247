export function setAppcuesUser(user) {
  let acUser = user.Email;
  let env = 'LOCAL';
  const href = window.location.href.toLocaleLowerCase();
  if (href.indexOf('app.mosaik.io') > -1) {
    env = 'PRODUCTION';
  } else if (href.indexOf('stg.mosaik.io') > -1) {
    acUser = `${user.Email} (s)`;
    env = 'STAGING';
  } else if (href.indexOf('qa.mosaik') > -1) {
    acUser = `${user.Email} (q)`;
    env = 'QA';
  } else if (href.indexOf('dev.mosaik') > -1) {
    acUser = `${user.Email} (d)`;
    env = 'DEVELOPEMENT';
  } else {
    acUser = `${user.Email} (l)`;
    env = 'LOCAL';
  }
  try {
    window.Appcues.identify(acUser, {
      role: user.Roles.toString(),
      email: user.Email,
      accountId: user.Id,
      firstName: user.FirstName,
      version: env,
    });
  } catch (error) {
    if (env === 'PRODUCTION') {
      let alerted = localStorage.getItem('IntegrationAlert') || '';
      if (alerted != 'yes') {
        sessionStorage.setItem('alerted', 'yes'); //Setting here also to prevent double alerts
        alert(
          'Mosaik App functionality may be impacted by your Ad Blocking Browser Settings. Please contact support at support@mosaik.io.',
        );
      }
      localStorage.setItem('IntegrationAlert', 'yes');
    } else {
      console.error('Mosaik Appcues Integration Issue: ', error);
    }
  }
}
