import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const ChangeQuestion = (props) => {
  const { children, show, className } = props;

  return (
    <div className={classNames(styles.changeQuestion, { [styles.show]: show }, className)}>
      {children}
    </div>
  );
};

ChangeQuestion.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
};

ChangeQuestion.defaultProps = {
  className: '',
  show: false,
};

export default ChangeQuestion;
