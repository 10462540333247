import Api from 'store/effects/core/api';

import { getState } from 'store';

import { get } from 'lodash-es';
import {
  deleteTransactionFolder,
  editTransactionFolder,
  moveTransactionDocument,
} from 'api/transactions/folders/update';
import {
  deleteTransactionFolderAction,
  editTransactionFolderAction,
  moveTransactionDocumentAction,
} from 'store/actions/transactions/folder/update';

export const deleteTransactionFolderEffect = (cfg, options, cb) => {
  const transactionData = get(getState(), 'transaction.data');
  const sendRequest = Api.execResult({
    action: deleteTransactionFolderAction,
    method: deleteTransactionFolder,
  });

  const config = {
    transactionId: transactionData?.Id,
    folderId: cfg?.id,
  };

  return sendRequest(config, options, cb);
};

export const moveTransactionDocumentEffect = (cfg, options, cb) => {
  const transactionData = get(getState(), 'transaction.data');
  const sendRequest = Api.execResult({
    action: moveTransactionDocumentAction,
    method: moveTransactionDocument,
  });

  const config = {
    transactionId: transactionData?.Id,
    folderId: cfg?.folderId,
    documentIds: cfg?.documentId,
  };

  return sendRequest(config, options, cb);
};

export const editTransactionFolderEffect = (cfg, options, cb) => {
  const transactionData = get(getState(), 'transaction.data');
  const sendRequest = Api.execResult({
    action: editTransactionFolderAction,
    method: editTransactionFolder,
  });

  const config = {
    transactionId: transactionData?.Id,
    title: cfg?.title,
    folderId: cfg?.folderId,
  };

  return sendRequest(config, options, cb);
};
