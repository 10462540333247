import { useState } from 'react';
import { PdfParser } from './PdfParser';
import { Button } from 'antd';

export const PdfEditor = () => {
  const [document, setDocument] = useState<ArrayBuffer | null>(null);

  const onClick = async () => {
    const response = await fetch('/test_pdf/Purchase Contract Residential Jan 2020.pdf');
    setDocument(await response.arrayBuffer());
  };

  return (
    <div>
      <Button onClick={onClick}>Open test PDF</Button>
      {document && <PdfParser document={document} />}
    </div>
  );
};
