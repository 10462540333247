import { SVGProps } from 'react';

export const PaymentFailed = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="88"
    height="88"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.08" cx="44" cy="44" r="44" fill="#EC455E" />
    <g clipPath="url(#clip0_35594_24224)">
      <path
        d="M62.5 44C62.5 54.2173 54.2173 62.5 44 62.5C33.7827 62.5 25.5 54.2173 25.5 44C25.5 33.7827 33.7827 25.5 44 25.5C54.2173 25.5 62.5 33.7827 62.5 44Z"
        stroke="#EC455E"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44 44C44 47.1242 44 48.3758 44 51.5"
        stroke="#EC455E"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="44" cy="35" r="3" fill="#EC455E" />
    </g>
    <defs>
      <clipPath id="clip0_35594_24224">
        <rect width="48" height="48" fill="white" transform="translate(20 20)" />
      </clipPath>
    </defs>
  </svg>
);
