import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getModalSelector } from 'store/selectors/app';
import { appOpenModalEffect } from 'store/effects/app';
import { Modal, Button } from 'components';
import Title from '../../../Title';
import CheckmarkIcon from './CheckmarkIcon';
import { preApprovalRoute } from 'settings/constants/preApproval';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';

const RequestModal = ({ className }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const path = pathname.split('/');
  const isPreApproval = path[path.length - 1] === preApprovalRoute;
  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();

  if (modal.id !== RequestModal.id) {
    return null;
  }

  const onRedirect = () => {
    history.push(isPreApproval ? routes.mySearches : routes.feed);
  };

  const onClickHandler = () => {
    dispatch(appOpenModalEffect({ id: RequestModal.id, open: false }));
    onRedirect();
  };

  return (
    <Modal
      isOpen={modal.open}
      onClose={onClickHandler}
      className={classNames(className)}
      innerHolderClassName={styles.modalInnerHolder}
      testid="request_modal"
    >
      <CheckmarkIcon className={styles.checkmark} />
      <Title className={styles.title}>
        Your pre-approval request has been sent to the lenders you selected. They will contact you
        shortly.
      </Title>
      {!isPreApproval ? (
        <Title className={styles.title}>
          In the meantime, we have some great houses for you to check out in your feed.
        </Title>
      ) : null}
      <Button
        testid="take_to_feed"
        onClick={onClickHandler}
        className={styles.btn}
        title={`Take Me To My ${isPreApproval ? 'Searches' : 'Feed'}`}
      />
    </Modal>
  );
};

RequestModal.id = 'requestLenderModal';

RequestModal.propTypes = {
  className: PropTypes.string,
};

RequestModal.defaultProps = {
  className: '',
};

export default RequestModal;
