import {
  FORM_STATUS_TYPE,
  FORM_TYPE,
  FORM_QUESTION_TYPE,
  FORM_MODE,
  FORM_UPDATE_STATUS,
  PDF_FIELD_TYPE,
  SMART_FORM_QUESTION_TYPE,
  FORM_USER_ROLE,
  SIGNATURE_MAPPING,
  DYNAMIC_QUESTION_TYPE,
} from 'app-constants';
import { MadeByType } from 'types/utils';

export type FormStatus = typeof FORM_STATUS_TYPE[keyof typeof FORM_STATUS_TYPE];

export type FormUpdateStatusVariants = typeof FORM_UPDATE_STATUS[keyof typeof FORM_UPDATE_STATUS];

export const GroupedQuestions = 'GroupedQuestions';

export type FormQuestionType =
  | typeof FORM_QUESTION_TYPE[keyof typeof FORM_QUESTION_TYPE]
  | typeof GroupedQuestions
  | typeof SMART_FORM_QUESTION_TYPE[keyof typeof SMART_FORM_QUESTION_TYPE]
  | DYNAMIC_QUESTION_TYPE;

export type FormType = typeof FORM_TYPE[keyof typeof FORM_TYPE];

export type FormMode = typeof FORM_MODE[keyof typeof FORM_MODE];

export type QuestionFieldType = typeof PDF_FIELD_TYPE[keyof typeof PDF_FIELD_TYPE];

export type FormUserRoleType = typeof FORM_USER_ROLE[keyof typeof FORM_USER_ROLE];

export type SignatureMappingType = typeof SIGNATURE_MAPPING[keyof typeof SIGNATURE_MAPPING];

export interface FormLocationPermissionType {
  AreasOfOperation: Location[];
  FormId: number;
}

export interface FormTagsType {
  TagId: number;
  Name: string;
}

export interface FormUserPermission {
  UserId: string;
  Name: string;
}

export interface FormTeamPermission {
  Id: string;
  Name: string;
}

export interface FormCategoryPermissionType {
  CategoryId: number;
  Title: string;
}

export interface QuestionRole {
  Id: number;
  RoleId: number;
  Name: string;
  UserType: FormUserRoleType;
}

export interface FormVersionType {
  Id: number;
  FormId: number;
  Version: number;
  Name: string;
  Status: FormStatus;
  FormVersionCreatedBy: MadeByType;
  FormVersionUpdatedBy: MadeByType;
  CreatedDate: string;
  UpdatedDate: string;
  Link?: string;
}
