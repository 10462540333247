import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'antd';
import { isEqual, lowerCase } from 'lodash';
import classNames from 'classnames';

import { Icons } from '../Icons';
import {
  openActivityFiltersDrawerEffect,
  sortActivityListByOrderEffect,
} from 'store/effects/drawers/activityFilters';
import { Badge, Popover } from 'components-antd';

import {
  getActivityFiltersDrawerFieldsSelector,
  getActivityFiltersDrawerInitialFieldsSelector,
} from 'store/selectors/drawers/activityFilters';
import { getActivityLogsWorkshopSortOrderSelector } from 'store/selectors/drawers/activityFilters';
import {
  requestGetActivityLogsEffect,
  resetActivityLogsItemsListEffect,
} from 'store/effects/activityLogsWorkshop';
import { getFormattedFilterObject } from 'utils/activityLogsHelper';
import { activityLogsPageSize } from 'app-constants/activityLogs';

import styles from './styles.module.scss';

const { useBreakpoint } = Grid;
export const LocalHeader = () => {
  const [sortOrder, setSortOrder] = useState();
  const dispatch = useDispatch();
  const fieldsPayload = useSelector(getActivityFiltersDrawerFieldsSelector);
  const initialFieldsPayload = useSelector(getActivityFiltersDrawerInitialFieldsSelector);
  const sortByOrder = useSelector(getActivityLogsWorkshopSortOrderSelector);
  const { xs } = useBreakpoint();

  const openDrawer = () => {
    dispatch(openActivityFiltersDrawerEffect({ open: true }));
  };
  const sortByPopoverContent = () => 'Sort By';
  useEffect(() => {
    if (sortOrder) {
      const filterPayload = {
        ...getFormattedFilterObject(fieldsPayload, 1, activityLogsPageSize),
        Order: sortByOrder,
      };
      dispatch(resetActivityLogsItemsListEffect());
      dispatch(requestGetActivityLogsEffect(filterPayload));
    }
    setSortOrder(sortByOrder);
  }, [sortByOrder]);
  const renderSortByIcon = (
    <Popover content={sortByPopoverContent} placement="topLeft">
      <div
        className={styles.btn}
        onClick={() => {
          dispatch(sortActivityListByOrderEffect());
        }}
      >
        <Icons
          variant={'sortBy'}
          className={classNames(styles.icon, styles[lowerCase(sortByOrder)])}
        />
      </div>
    </Popover>
  );
  const filterPopoverContent = () => 'Activity Filters';

  const renderFilterIcon = (
    <Popover content={filterPopoverContent} placement="topLeft">
      <div className={styles.btn} onClick={openDrawer}>
        <Badge dot={!isEqual(fieldsPayload, initialFieldsPayload)}>
          <Icons
            variant={'filterSecondary'}
            className={classNames(styles.icon, styles.filterIcon)}
          />
        </Badge>
      </div>
    </Popover>
  );

  return (
    <div
      className={styles.activityFiltersIndexHeader}
      style={xs ? { justifyContent: 'center', marginRight: '0px' } : {}}
    >
      {renderFilterIcon}
      {renderSortByIcon}
    </div>
  );
};
