export function prepareData(values, stageIndex, defaultClientSearch = {}) {
  const { NumBathroomsRange } = values;
  const formatRange = ({ Min, Max }) =>
    Min || Max
      ? {
          Min: +Min || undefined,
          Max: +Max || undefined,
        }
      : undefined;

  return {
    stageIndex,
    DefaultPropertySearchPreferences: {
      ...defaultClientSearch,
      NumBathroomsRange: formatRange(NumBathroomsRange),
    },
  };
}
