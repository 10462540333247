import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const StatusTitle = (props) => {
  const { className, children, count } = props;

  return (
    <div testid="status_title" className={classNames(styles.statusTitle, className)}>
      <span>{children}</span>
      <span className={styles.count}>{`(${count})`}</span>
    </div>
  );
};

StatusTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  count: PropTypes.number.isRequired,
};

StatusTitle.defaultProps = {
  className: '',
};

export default StatusTitle;
