import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep, compact, isEmpty } from 'lodash';

import { Modal, Popover, Select } from 'components-antd';
import Button from 'components/Button';
import Content from './Content';
import {
  getCategories,
  postBulkPartners,
  getExistingPartnerEmails,
  getAllPartnerTags,
} from 'api/admin';
import { getAllAgents } from 'api/admin/users';
import { getPartnersEffect } from 'store/effects';
import { showErrorMessage } from 'helpers';
import AddEditPartnerModal from './AddEditPartnerModal';
import { getPartnerSendData } from 'store/effects/adminPanel/helpers';
import { formatPhoneNumber } from 'helpers/formatters';

import styles from './styles.module.scss';

const PartnersPreviewModal = ({ open, partnersList, setPreviewModal }) => {
  const [partners, setPartners] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const [existingEmails, setExistingEmails] = useState([]);
  const [existingServices, setExistingServices] = useState([]);
  const [newServices, setNewServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addEditModal, setAddEditModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [recommendedByAgentsId, setRecommendedByAgentsId] = useState([]);
  const dispatch = useDispatch();

  const getCategoryId = (categoryList) =>
    categoryList?.map((category) =>
      categoryOptions.find((option) => option.name === category.trim()),
    ) || [];

  const formatToPartnerFields = (list = []) =>
    list.map((item) => ({
      Category: item?.Category?.split(/[/,;]+/).map((category) => category.trim()),
      PartnerCategoryIds: getCategoryId(item?.Category?.split(/[/,;]+/)),
      BusinessName: item?.Company || null,
      Services: item?.Specialties?.split(/[/,;]+/),
      PartnerServices: getServiceTags(item?.Specialties?.split(/[,;]+/)),
      Email: item?.Email || null,
      PhoneNumber: formatPhoneNumber(item?.['Phone Number']) || null,
      Website: item?.Website || null,
      ContactUser: {
        FirstName: item?.['Point of Contact/ Name']?.split(' ')[0] || null,
        LastName: item?.['Point of Contact/ Name']?.split(' ')[1] || null,
        Email: item?.Email || null,
      },
      InviteStatus: false,
      SuiteUnit: null,
      RecommendedByAgentsId: [],
      IsInviteLaterOrNow: false,
      locations: [{ AreasOfOperation: '', Radius: '' }],
    }));

  const getServiceTags = (inputTags) => {
    if (!inputTags?.length) return [];
    return inputTags.map((tag) => {
      const serviceTag = tag.trim();
      const existing = existingServices.find((el) => el.Name === serviceTag);
      return existing ? existing.Id : serviceTag;
    });
  };

  const fetchCategories = async () => {
    const { data, status } = await getCategories({
      params: { active: true },
    });
    if (status === 200) {
      const categories = (data.result || []).map(({ Title, Id }) => ({ value: Id, name: Title }));
      if (categories.length) {
        setCategoryOptions(categories);
      }
    }
  };

  const fetchPartnerServices = async () => {
    const { data, status } = await getAllPartnerTags({
      params: { active: true },
    });
    if (status === 200) {
      setExistingServices(data.result);
    }
  };

  const fetchAgents = async () => {
    const { data, status } = await getAllAgents();
    if (status === 200) {
      let agentOptions = data.result.map((agent) => ({
        value: agent.UserId,
        name: agent.Name,
        label: agent.Name,
      }));
      if (agentOptions.length) {
        agentOptions = Array.from(new Set(agentOptions.map(JSON.stringify))).map(JSON.parse);
        setAgentOptions(agentOptions);
      }
    }
  };

  const partnerEmails = async () => {
    const emailList = compact(partners?.map((partner) => partner.Email));
    if (emailList.length) {
      const { data, status } = await getExistingPartnerEmails(emailList);
      if (status === 200) {
        setExistingEmails(data.result);
      }
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchAgents();
    fetchPartnerServices();
  }, []);

  useEffect(() => {
    setPartners(formatToPartnerFields(partnersList));
  }, [partnersList]);

  useEffect(() => {
    if (partners.length) {
      partnerEmails();
      getNewServicesList(partners);
    }
  }, [partners]);

  const deletePartner = (id) => setPartners(partners.filter((partner, index) => index !== id));

  const isDisabled = () =>
    partners?.some(
      (partner) =>
        !partner.Category ||
        !(partner.BusinessName || partner.ContactUser.FirstName) ||
        !partner.Email ||
        existingEmails.includes(partner.Email),
    );

  const formatPartners = (list) => {
    const partners = cloneDeep(list);
    return partners.map((partner) => {
      delete partner.Category;
      delete partner.Services;
      if (recommendedByAgentsId && isEmpty(partner.RecommendedByAgentsId)) {
        partner.RecommendedByAgentsId = recommendedByAgentsId;
      }

      return getPartnerSendData(partner, false);
    });
  };

  const onClose = () => {
    setPreviewModal(false);
    setPartners([]);
    setRecommendedByAgentsId([]);
    setNewServices([]);
  };

  const onSave = async () => {
    setLoading(true);
    try {
      const result = await postBulkPartners(formatPartners(partners));
      if (result?.status === 200) {
        dispatch(getPartnersEffect());
        onClose();
      }
    } catch (err) {
      showErrorMessage(err);
    }
    setLoading(false);
  };

  const getNewServicesList = (partnersList) => {
    let newServicesList = [];
    partnersList.forEach(({ Services }) => {
      Services?.forEach((tag) => {
        const existing = existingServices.find((el) => el.Name === tag);
        if (!existing && !newServicesList.includes(tag)) newServicesList.push(tag);
      });
    });
    setNewServices(newServicesList);
  };

  const onSelectedSave = (partnerData) => {
    const partnersList = cloneDeep(partners);
    partnerData.Category = partnerData.PartnerCategoryIds.map((category) => category?.name);
    partnerData.Services = partnerData.PartnerServices.map((service) => {
      if (Number.isInteger(service)) {
        const existing = existingServices.find((el) => el.Id === service);
        return existing.Name;
      } else {
        return service;
      }
    });
    partnersList[selectedPartner] = partnerData;
    setPartners(partnersList);
    setAddEditModal(false);
    setSelectedPartner(null);
  };

  const newServicesContent = (
    <div className={styles.newServicesContentPopover}>
      {newServices.map((service) => (
        <p>{service}</p>
      ))}
    </div>
  );

  return (
    <>
      <Modal open={open} onCancel={onClose} footer={null} width={1050} maskClosable={false}>
        <div className={styles.previewModalHeader}>
          <h2 className={styles.modalTitle}>Upload Partner List</h2>
        </div>

        <Content
          partners={partners}
          setSelectedPartner={setSelectedPartner}
          setAddEditModal={setAddEditModal}
          deletePartner={deletePartner}
          categoryOptions={categoryOptions}
          existingEmails={existingEmails}
          agentOptions={agentOptions}
        />

        <div className={styles.previewModalFooter}>
          <div>
            <Select
              large
              showArrow={true}
              mode="tags"
              showSearch
              placeholder="Select Recommended By"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              onSelect={(e, selected) =>
                setRecommendedByAgentsId((prev) => [...prev, selected.value])
              }
              onDeselect={(e, selected) =>
                setRecommendedByAgentsId((prev) => prev.filter((id) => id !== selected.value))
              }
              value={recommendedByAgentsId}
              id="RecommendedByAgentsId"
              name="RecommendedByAgentsId"
              className={styles.selectAgent}
              options={agentOptions}
            />
            {newServices.length ? (
              <Popover content={newServicesContent} trigger="hover">
                <span className={styles.newServicesSpan}>New Specialties</span>
              </Popover>
            ) : (
              <></>
            )}
          </div>
          <Button
            title="Save"
            disabled={loading || isDisabled()}
            isPending={loading}
            onClick={onSave}
            className={styles.submitButton}
          />
        </div>
      </Modal>
      <AddEditPartnerModal
        open={addEditModal}
        onClose={() => {
          setAddEditModal(false);
          setSelectedPartner(null);
        }}
        selectedPartner={partners[selectedPartner]}
        onEdit={onSelectedSave}
      />
    </>
  );
};
export default PartnersPreviewModal;
