import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationService } from 'services';

import { getGroupedQuotesSelector } from 'store/selectors/quotes';
import { getUserRoleSelector } from 'store/selectors/user';

import { getDetailedQuotesByStatus } from 'pages/Quotes/helpers';
import { NoQuotes } from 'pages/Quotes/components/NoQuotes';

import styles from './styles.module.scss';
import { tableColumns } from 'pages/Quotes/components/Tables/groupByCommonTableColumns/tableColumns';
import { GroupByTable } from 'pages/Quotes/components/Tables/GroupByTable';
import GroupByDetailTable from 'pages/Quotes/components/Tables/GroupByDetailTable';

const locationSrv = new LocationService();
const ByProperty = (props) => {
  const { className, onArchive } = props;
  const history = useHistory();
  const location = useLocation();
  locationSrv.setLocation(location);
  const { byProperty, groupId } = useSelector(getGroupedQuotesSelector);
  const userRole = useSelector(getUserRoleSelector);
  const getDetailTables = () => {
    const groupByStatus = getDetailedQuotesByStatus(byProperty, groupId, userRole);
    return (
      <GroupByDetailTable
        onArchive={onArchive}
        quotesList={groupByStatus}
        groupedBy={'ByProperty'}
      />
    );
  };

  const tableProps = {
    columns: tableColumns(userRole, 'Property'),
    onRow: (record) => {
      return {
        onClick: (event) => {
          history.push(locationSrv.setQuery({ groupId: record?.name }));
        },
      };
    },
  };

  return (
    <div testid="by_property" className={classNames(styles.byProperty, className)}>
      {byProperty.length ? (
        groupId ? (
          getDetailTables()
        ) : (
          <GroupByTable dataSource={byProperty} {...tableProps} />
        )
      ) : (
        <NoQuotes />
      )}
    </div>
  );
};

ByProperty.propTypes = {
  className: PropTypes.string,
  onArchive: PropTypes.func,
};

ByProperty.defaultProps = {
  className: '',
  onArchive: () => {},
};

export default ByProperty;
