import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { transactionPreFormQuestionsIds, LISTED_STATUS } from 'settings/constants/transaction';
import { Question } from 'pages/RequestQuote/components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Continue } from '../../Continue';

import styles from './styles.module.scss';

const ListingStatus = (props) => {
  const { className, onAnswer } = props;
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);

  const onAnswerHandler = (status) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.isPropertyUnderContract]: false,
        [transactionPreFormQuestionsIds.listedStatus]: status,
      }),
    );
  };

  const onUnderContractHandler = () => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.listedStatus]: undefined,
        [transactionPreFormQuestionsIds.isPropertyUnderContract]: true,
      }),
    );
  };

  return (
    <div testid="is_under_contract" className={classNames(styles.container, className)}>
      <Question>What is the status of this listing?</Question>
      <AnswersContainer>
        <Button
          type="text"
          className={classNames(styles.answerBtn, {
            [styles.active]:
              preForm?.[transactionPreFormQuestionsIds.listedStatus] === LISTED_STATUS.PreListing,
          })}
          onClick={() => onAnswerHandler(LISTED_STATUS.PreListing)}
        >
          Pre-Listing
        </Button>
        <Button
          type="text"
          className={classNames(styles.answerBtn, {
            [styles.active]:
              preForm?.[transactionPreFormQuestionsIds.listedStatus] ===
              LISTED_STATUS.OfficeExclusive,
          })}
          onClick={() => onAnswerHandler(LISTED_STATUS.OfficeExclusive)}
        >
          Office Exclusive
        </Button>
        <Button
          type="text"
          className={classNames(styles.answerBtn, {
            [styles.active]:
              preForm?.[transactionPreFormQuestionsIds.listedStatus] === LISTED_STATUS.ComingSoon,
          })}
          onClick={() => onAnswerHandler(LISTED_STATUS.ComingSoon)}
        >
          Coming Soon
        </Button>
        <Button
          type="text"
          className={classNames(styles.answerBtn, {
            [styles.active]:
              preForm?.[transactionPreFormQuestionsIds.listedStatus] ===
              LISTED_STATUS.ActiveListing,
          })}
          onClick={() => onAnswerHandler(LISTED_STATUS.ActiveListing)}
        >
          Active Listing
        </Button>
        <Button
          type="text"
          className={classNames(styles.answerBtn, {
            [styles.active]:
              preForm?.[transactionPreFormQuestionsIds.listedStatus] === LISTED_STATUS.OnHold,
          })}
          onClick={() => onAnswerHandler(LISTED_STATUS.OnHold)}
        >
          On Hold
        </Button>
        <Button
          type="text"
          className={classNames(styles.answerBtn, {
            [styles.active]: preForm?.[transactionPreFormQuestionsIds.isPropertyUnderContract],
          })}
          onClick={onUnderContractHandler}
        >
          Under Contract
        </Button>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue
          onClick={() =>
            onAnswer(preForm?.[transactionPreFormQuestionsIds.isPropertyUnderContract])
          }
          disabled={
            !preForm?.[transactionPreFormQuestionsIds.isPropertyUnderContract] &&
            !preForm?.[transactionPreFormQuestionsIds.listedStatus]
          }
        />
      </ButtonsContainer>
    </div>
  );
};

ListingStatus.propTypes = {
  className: PropTypes.string,
  onAnswer: PropTypes.func,
};

ListingStatus.defaultProps = {
  className: '',
  onAnswer: () => {},
};

export default ListingStatus;
