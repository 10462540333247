import PropTypes from 'prop-types';

import { Modal } from 'components-antd';

import styles from './styles.module.scss';
import { LoginLogs } from './LoginLogs';

export const LoginLogsModal = (props) => {
  const { isOpen, onCloseModal, agentEmail } = props;

  return (
    <Modal
      open={isOpen}
      width={660}
      footer={null}
      onCancel={onCloseModal}
      maskClosable={false}
      destroyOnClose
      className={styles.taskModal}
    >
      <LoginLogs agentEmail={agentEmail} />
    </Modal>
  );
};

LoginLogsModal.propTypes = {
  onCloseModal: PropTypes.func,
  isOpen: PropTypes.bool,
  agentEmail: PropTypes.string,
};

LoginLogsModal.defaultProps = {
  onCloseModal: () => {},
  isOpen: false,
  agentEmail: '',
};
