/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { Input, InputLabel } from 'components';
import { Select } from 'components';
import { noRanges } from 'settings/constants/common';

import styles from './styles.module.scss';

const YearMinMax = (props) => {
  const { className, variant, valueMin, valueMax, nameMin, nameMax, selectClassName } = props;
  const { onChangeMin, onChangeMax, disabled, range } = props;
  const { placeholderMin, placeholderMax, label, isReset } = props;
  const { testidMin, testidMax } = props;

  const generateYears = useCallback(() => {
    const currentYear = moment().format('YYYY');
    return Array.from({ length: range }).map((_, i) => {
      const yearOption = currentYear - i;
      return { name: yearOption.toString(), value: yearOption };
    });
  }, [range]);

  const findYearIndex = (years, yearValue) => years.findIndex((year) => year.value === yearValue);

  const getYearsOptions = useCallback(() => {
    const years = generateYears();

    let minYears = [...years];
    let maxYears = [...years];

    if (valueMin) {
      const yearIndex = findYearIndex(years, valueMin);

      if (yearIndex !== -1) {
        maxYears = years.slice(0, yearIndex + 1);
      }
    }

    if (valueMax) {
      const yearIndex = findYearIndex(years, valueMax);

      if (yearIndex !== -1) {
        minYears = years.slice(yearIndex);
      }
    }

    return {
      min: minYears,
      max: maxYears,
    };
  }, [valueMin, valueMax, generateYears]);

  const [yearOptionsMin, setYearOptionsMin] = useState(getYearsOptions().min);
  const [yearOptionsMax, setYearOptionsMax] = useState(getYearsOptions().max);

  useEffect(() => {
    setYearOptionsMin(getYearsOptions().min);
    setYearOptionsMax(getYearsOptions().max);
  }, [valueMin, valueMax, getYearsOptions]);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <InputLabel label={label} className={styles.yearInputLabel} />
      <div className={classNames(styles.horizontalOptions)}>
        <Select
          name={nameMin}
          options={[
            ...(isReset ? [{ name: 'No min', value: noRanges.noMin }] : []),
            ...yearOptionsMin,
          ]}
          placeholder={placeholderMin}
          value={valueMin}
          onSelect={(target, val) => onChangeMin(val.value)}
          disabled={disabled}
          className={{ wrapper: classNames(styles.selectWrapper, selectClassName) }}
          variant={variant}
          testid={testidMin}
          style={{ width: '100%' }}
        />
        <span className={styles.separator}>to</span>
        <Select
          name={nameMax}
          options={[
            ...(isReset ? [{ name: 'No max', value: noRanges.noMax }] : []),
            ...yearOptionsMax,
          ]}
          placeholder={placeholderMax}
          value={valueMax}
          onSelect={(target, val) => onChangeMax(val.value)}
          disabled={disabled}
          className={{ wrapper: classNames(styles.selectWrapper, selectClassName) }}
          variant={variant}
          testid={testidMax}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
};

YearMinMax.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  valueMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nameMin: PropTypes.string,
  nameMax: PropTypes.string,
  onChangeMin: PropTypes.func,
  onChangeMax: PropTypes.func,
  disabled: PropTypes.bool,
  placeholderMin: PropTypes.string,
  placeholderMax: PropTypes.string,
  label: PropTypes.string,
  testidMin: PropTypes.string,
  testidMax: PropTypes.string,
  range: PropTypes.number,
  isReset: PropTypes.bool,
  selectClassName: PropTypes.string,
};

YearMinMax.defaultProps = {
  className: '',
  variant: Input.LIGHT_FULL,
  valueMin: '',
  valueMax: '',
  nameMin: 'min',
  nameMax: 'max',
  onChangeMin: () => {},
  onChangeMax: () => {},
  disabled: false,
  placeholderMin: '',
  placeholderMax: '',
  label: '',
  testidMin: undefined,
  testidMax: undefined,
  range: 200, // years
  isReset: false,
  selectClassName: '',
};

export default YearMinMax;
