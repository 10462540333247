import React from 'react';

export const InviteVendorIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M12.0212 6.73958C12.0212 8.58053 10.5288 10.0729 8.68783 10.0729C6.84688 10.0729 5.35449 8.58053 5.35449 6.73958C5.35449 4.89863 6.84688 3.40625 8.68783 3.40625C10.5288 3.40625 12.0212 4.89863 12.0212 6.73958Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M17.021 7.57031V12.5703"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.521 10.0703L19.521 10.0703"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.68799 16.3229C3.9996 14.7648 5.43228 13.4062 7.02122 13.4062H10.3547C11.9436 13.4062 13.3764 14.7648 13.688 16.3229"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
