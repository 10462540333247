import Api from 'store/effects/core/api';
import { get } from 'lodash-es';
import {
  requestGetAllQuotesAction,
  setQuotesArchiveAction,
  setQuotesGroupByAction,
  requestGetQuoteDetailsAction,
  resetQuoteDetailsAction,
  resetRequestQuoteAction,
  requestPostArchiveQuotesAction,
  respondAndUploadDocumentAction,
  setPreFormQuestionsAction,
  requestPostCreateQuoteDraftAction,
  requestFormResultsAction,
  setRequestQuotePartnersAction,
  requestPatchQuoteDraftAction,
  requestPostSubmitDraftQuoteAction,
  updateQuoteQuestionAction,
  resumeDraftQuoteAction,
  setMultipleRequestQuotesAction,
  deleteQuoteResponseAction,
  requestGetQuoteFormAction,
  updateQuoteFormResponseAction,
  requestUpdateQuoteFormResponseAction,
  uploadDocumentAction,
  requestGetQuotesAuditLogsAction,
  setQuotesSearchAction,
} from 'store/actions/quotes';
import {
  getQuoteDetails,
  archiveQuotes,
  respondAndUploadDocument,
  createQuoteDraft,
  getAllQuotes,
  requestFormResults,
  updateQuoteDraft,
  submitDraftQuote,
  updateQuoteQuestion,
  resumeDraftQuote,
  archiveDraftQuotes,
  getQuotes,
  deleteQuoteResponse,
  getQuoteForm,
  saveQuoteForm,
  uploadQuoteDocument,
  getQuoteAuditLogs,
} from 'api/quotes';
import { getState } from 'store';
import { THIRD_PARTY } from 'settings/constants/roles';
import { isPropertyAddress } from './helpers';
import { extractDraftQuoteId } from '../../../helpers';

export const requestGetQuotesEffect = (cfg, options, cb) => {
  const state = getState();
  const { user } = state;
  const userRole = get(user, 'data.Roles.0');

  const requestParams = {
    action: requestGetAllQuotesAction,
    method: userRole === THIRD_PARTY ? getQuotes : getAllQuotes,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetQuotesAuditLogsEffect = (cfg, options, cb) => {
  const requestParams = {
    action: requestGetQuotesAuditLogsAction,
    method: getQuoteAuditLogs,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetQuoteDetailsEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetQuoteDetailsAction,
    method: getQuoteDetails,
  });

  const config = {
    id: Number(cfg?.id),
  };
  return sendRequest(config, options, cb);
};

export const resetQuoteDetailsEffect = () => (dispatch) => {
  dispatch(resetQuoteDetailsAction());
};

export const setQuotesGroupByEffect = (cfg) => (dispatch) => {
  dispatch(setQuotesGroupByAction(cfg));
};

export const setQuotesArchiveEffect = (cfg) => (dispatch) => {
  dispatch(setQuotesArchiveAction(cfg));
};

export const resetRequestQuoteEffect = (cfg) => (dispatch) => {
  dispatch(resetRequestQuoteAction(cfg));
};

export const setPreFormQuestionsEffect = (cfg) => (dispatch) => {
  dispatch(setPreFormQuestionsAction(cfg));
};

export const setRequestQuotePartnersEffect = (cfg) => (dispatch) => {
  dispatch(setRequestQuotePartnersAction(cfg));
};

export const setMultipleRequestQuotesEffect = (cfg) => (dispatch) => {
  dispatch(setMultipleRequestQuotesAction(cfg));
};

export const archiveQuotesEffect = (cfg, options, cb) => {
  const { quotes } = getState() || {};
  const requestParams = {
    action: requestPostArchiveQuotesAction,
    method: options?.isDraft ? archiveDraftQuotes : archiveQuotes,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const archivedQuotes = (quotes.data || []).filter((quote) => cfg?.ids?.includes(quote?.Id));
  const isArchived = archivedQuotes.some((quote) => quote?.IsArchived);

  const extractDraftIds = () => (cfg?.ids || []).map((id) => extractDraftQuoteId(id));

  const config = {
    QuoteIds: options?.isDraft ? extractDraftIds() : cfg?.ids,
    NewArchivedState: cfg?.newArchivedState !== undefined ? cfg?.newArchivedState : !isArchived,
    ...(cfg?.status ? { ArchivedStatus: cfg?.status } : { ArchivedStatus: undefined }),
  };

  return sendRequest(config, options, cb);
};

export const respondAndUploadDocumentEffect = (cfg, options = { silent: true }, cb) => {
  const requestParams = {
    action: respondAndUploadDocumentAction,
    method: respondAndUploadDocument,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const formData = new FormData();

  if (cfg?.files) {
    const arrayFiles = Array.from(cfg?.files);
    arrayFiles.forEach((file) => {
      formData.append('files', file);
    });
  }

  const config = {
    id: cfg?.id,
    formData,
  };

  return sendRequest(config, options, cb);
};

export const createQuoteDraftEffect = (cfg, options, cb) => {
  const state = getState();
  const {
    requestQuote: { preForm },
  } = state;
  const requestParams = { action: requestPostCreateQuoteDraftAction, method: createQuoteDraft };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const { City, State, Line1, Line2, Zip } = get(preForm, 'property.address', {});

  const config = {
    PartnerIds: (cfg?.partnerIds || []).map((id) => Number(id)),
    CategoryId: Number(cfg?.categoryId),
    formVersionId: cfg?.formVersionId,
    ...(preForm?.isProperty
      ? { Property: { Address: { City, State, Line1: Line1 || null, Line2: Line2 || null, Zip } } }
      : { Property: undefined }),
    ClientId: preForm?.client?.id || preForm?.client?.Id,
  };

  return sendRequest(config, options, cb);
};

export const requestFormResultsEffect = (cfg, options, cb) => {
  const state = getState();
  const { requestQuote } = state;
  const requestParams = { action: requestFormResultsAction, method: requestFormResults };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const config = {
    id: requestQuote?.data?.Id,
    FormAnswersId: cfg?.responseId,
  };

  return sendRequest(config, options, cb);
};

export const updateQuoteDraftEffect = (cfg, options, cb) => {
  const state = getState();
  const { requestQuote } = state;
  const requestParams = { action: requestPatchQuoteDraftAction, method: updateQuoteDraft };

  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const config = {
    id: requestQuote?.data?.Id,
    PartnerIds: (requestQuote?.data?.Partners || []).map((partner) => partner?.Id),
    ClientId: requestQuote?.preForm?.client?.id || null,
    ...(!options?.isNegativeAnswer && isPropertyAddress(requestQuote?.preForm?.property)
      ? {
          Property: {
            Address: {
              City: requestQuote?.preForm?.property?.address?.City,
              State: requestQuote?.preForm?.property?.address?.State,
              Zip: requestQuote?.preForm?.property?.address?.Zip,
              Line1: requestQuote?.preForm?.property?.address?.Line1,
              Line2: requestQuote?.preForm?.property?.address?.Line2 || null,
            },
          },
        }
      : { Property: null }),
  };

  return sendRequest(config, options, cb);
};

export const submitDraftQuoteEffect = (cfg, options, cb) => {
  const state = getState();
  const { requestQuote } = state;
  const requestParams = { action: requestPostSubmitDraftQuoteAction, method: submitDraftQuote };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const config = {
    id: requestQuote?.data?.Id,
  };

  return sendRequest(config, options, cb);
};

export const updateQuoteQuestionEffect = (cfg, options, cb) => {
  const state = getState();
  const { requestQuote } = state;
  const requestParams = { action: updateQuoteQuestionAction, method: updateQuoteQuestion };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const config = {
    quoteId: requestQuote?.data?.Id,
    questionId: cfg?.questionId,
    Answer: cfg?.newAnswer,
  };

  return sendRequest(config, options, cb);
};

export const resumeDraftQuoteEffect = (cfg, options, cb) => {
  const requestParams = { action: resumeDraftQuoteAction, method: resumeDraftQuote };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const deleteQuoteResponseEffect = (cfg, options, cb) => {
  const requestParams = { action: deleteQuoteResponseAction, method: deleteQuoteResponse };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const getQuoteFormEffect = (cfg = {}, options = {}, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetQuoteFormAction,
    method: getQuoteForm,
  });
  return sendRequest(cfg, options, cb);
};

export const updateQuoteFormResponseEffect = (cfg) => (dispatch) => {
  dispatch(updateQuoteFormResponseAction(cfg));
};

export const saveQuoteFormResponseEffect = (cfg = {}, options = {}, cb) => {
  const sendRequest = Api.execBase({
    action: requestUpdateQuoteFormResponseAction,
    method: saveQuoteForm,
  });
  return sendRequest(cfg, options, cb);
};

export const uploadDocumentEffect = (cfg, options = { silent: true }, cb) => {
  const requestParams = {
    action: uploadDocumentAction,
    method: uploadQuoteDocument,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const formData = new FormData();

  if (cfg?.files?.length) {
    const files = cfg.files;

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i].originFileObj);
    }
  }

  const config = {
    formData,
  };

  return sendRequest(config, options, cb);
};

export const setQuoteSearchEffect = (cfg) => (dispatch) => {
  dispatch(setQuotesSearchAction(cfg));
};

export const searchQuotesEffect =
  ({ search }) =>
  (dispatch) => {
    dispatch(setQuoteSearchEffect(search));
  };

export const resetQuotesSearchEffect = () => (dispatch) => {
  dispatch(searchQuotesEffect({ search: '' }));
};
