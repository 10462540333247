import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getLoadingServicesCategoriesData } from 'store/selectors/servicesCategories';
import { requestGetServicesCategoriesEffect } from 'store/effects/servicesCategories';

import { Wrapper } from 'components';

const RequestPartnersWrapper = ({ children, className }) => {
  const dispatch = useDispatch();
  const { isInit, isLoading } = useSelector(getLoadingServicesCategoriesData);

  useEffect(() => {
    if (isInit) {
      dispatch(requestGetServicesCategoriesEffect());
    }
  }, [isInit, dispatch]);

  return (
    <Wrapper className={className} isPending={isLoading}>
      {children}
    </Wrapper>
  );
};

RequestPartnersWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

RequestPartnersWrapper.defaultProps = {
  className: '',
};

export default RequestPartnersWrapper;
