import moment from 'moment';

import { Icons } from '../../../Icons';

import styles from '../styles.module.scss';
import { IconText } from '../IconText';

export const EditLinkDuration = ({ data, onEdit }) => {
  const getInfo = () => {
    const {
      SelectedDuration: type,
      LinkActiveStartDate: startDate,
      LinkActiveEndDate: endDate,
      LinkActiveDays,
    } = data;

    if (type?.isIndefinite) {
      return 'Indefinitely';
    } else if (type?.isAddDays) {
      return `${moment(startDate).format('MM/DD/YYYY')} + ${LinkActiveDays} days`;
    } else if (type?.isDateRange) {
      return `${moment(startDate).format('MM/DD/YYYY')} to ${moment(endDate).format('MM/DD/YYYY')}`;
    } else {
      return 'N/A';
    }
  };

  return (
    <div className={styles.editForm}>
      <IconText
        text={'How long should this link be active?'}
        infoText={getInfo()}
        variant={'calendar-bg'}
        className={styles.editFormInfo}
      />
      <div className={styles.editIcon} onClick={onEdit}>
        <Icons variant={'edit'} />
      </div>
    </div>
  );
};
