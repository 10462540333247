import { FieldArray } from 'formik';
import React from 'react';

import styles from './styles.module.scss';
import { Locations } from 'components';
import classNames from 'classnames';
import { Minus, Plus } from 'components/Icons';
import Input from 'components/Form/Input';

type Props = {
  name: string;
  values: any;
  touched: any;
  errors: any;
  setFieldValue: any;
  onAreasOfOperationChange: any;
  isPending: boolean;
  showLabels?: boolean;
};

export const AreasOfOperationFieldInput: React.FC<Props> = ({
  name,
  values,
  setFieldValue,
  onAreasOfOperationChange,
  touched,
  errors,
  isPending,
  showLabels = true,
}) => {
  return (
    <FieldArray name={name}>
      {({ insert, remove, push }) => (
        <>
          <div className={styles.inputBlockWrapper}>
            {values.locations.map((location, index) => (
              <div className={styles.groupedInputsContainer}>
                <div className={styles.groupedInputs}>
                  <div className={styles.inputContainer}>
                    {index < 1 && showLabels && <label>Location</label>}
                    <Locations
                      allowStates
                      types={['(regions)']}
                      variant={Locations.LIGHT}
                      onResult={(result, prepareData) =>
                        onAreasOfOperationChange(index, values, setFieldValue, result, prepareData)
                      }
                      value={
                        values.locations[index]?.AreasOfOperation
                          ? [values.locations[index]?.AreasOfOperation]
                          : []
                      }
                      className={classNames(styles.inputBlock, styles.locationsBlock)}
                      labelClassName={styles.editLabel}
                      blockClassName={styles.rightBlock}
                      valuesWrapperClassName={styles.valuesWrapper}
                      activeInputClassName={styles.activeInput}
                      activeInputIconClassName={styles.searchIcon}
                      errorClassName={styles.locationError}
                      placeholderClassName={styles.placeholder}
                      searchIconClassName={styles.searchIcon}
                      menuTop
                      testid="area_served"
                      showPrefixIcon={true}
                    />
                    {touched.locations?.[index]?.AreasOfOperation && (
                      <div className={styles.errorContainer}>
                        {errors.locations?.[index]?.AreasOfOperation}
                      </div>
                    )}
                  </div>
                  <div className={classNames(styles.inputBlock, styles.radiusBlock)}>
                    {index < 1 && showLabels && <label>Radius</label>}
                    <div className={styles.rightBlock}>
                      <Input
                        id="Radius"
                        className={classNames(styles.input, styles.radius)}
                        type="text"
                        placeholder="+10 mi"
                        name="Radius"
                        value={values.locations?.[index]?.Radius}
                        onChange={(e, inputValue) =>
                          setFieldValue(`locations.${index}.Radius`, inputValue)
                        }
                        disabled={isPending}
                        prefix="+"
                        suffix=" mi"
                        isNumberFormat
                      />
                      {touched.locations?.[index]?.Radius && (
                        <div className={styles.errorContainer}>
                          {errors.locations?.[index]?.Radius}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.removeLocationGroupInput}>
                  {index > 0 && (
                    <button
                      type="button"
                      className={styles.removeBtn}
                      onClick={() => remove(index)}
                    >
                      <div>
                        <Minus />
                      </div>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          <button
            type="button"
            className={classNames(styles.addBtn)}
            onClick={() => {
              push({ AreasOfOperation: '', Radius: '' });
            }}
          >
            <div>
              <Plus />
            </div>
            <span>Add Another</span>
          </button>
        </>
      )}
    </FieldArray>
  );
};
