import { createAction } from 'redux-actions';

export const requestGetAgentGraphsAction = createAction('REQUEST/GET_AGENT_GRAPHS_STATS');

export const requestGetTeamGraphsAction = createAction('REQUEST/GET_TEAM_GRAPHS_STATS');

export const requestGetAgentScheduleAction = createAction('REQUEST/GET_AGENT_SCHEDULE_STATS');

export const requestGetTeamScheduleAction = createAction('REQUEST/GET_TEAM_SCHEDULE_STATS');

export const requestGetAgentNeedsAttentionAction = createAction(
  'REQUEST/GET_AGENT_NEEDS_ATTENTION_STATS',
);
export const requestGetTeamNeedsAttentionAction = createAction(
  'REQUEST/GET_TEAM_NEEDS_ATTENTION_STATS',
);
export const requestUpdateClientStateFlag = createAction('REQUEST/GET_UPDATE_CLIENT_STATE_FLAG');
