/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ItemsWrapper, ItemWrapper, Value } from '../components';

import styles from './styles.module.scss';

const Beds = (props) => {
  const { className, beds } = props;

  if (!beds) return null;

  return (
    <ItemsWrapper className={classNames(styles.beds, className)}>
      {beds.map((count, index) => (
        <ItemWrapper key={index}>
          <Value testid="beds" className={styles.filterBeds} value={count} postfix="beds" />
        </ItemWrapper>
      ))}
    </ItemsWrapper>
  );
};

Beds.propTypes = {
  className: PropTypes.string,
  beds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

Beds.defaultProps = {
  className: '',
};

export default Beds;
