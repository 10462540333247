import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Input, InputLabel, DatePicker } from 'components';
import {
  validateControlOperatorOffset,
  convertToInitialOffset,
  convertToDateTime,
} from 'utils/templatesHelper';
import { getTransactionDateControlsSelector } from 'store/selectors/transaction';
import { TaskEditableFormValuesType } from 'types/transactionTasks';
import { getDateOnly, subtractTimeZone } from 'helpers';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';

interface DueDateProps {
  values: TaskEditableFormValuesType;
  onChangeManyFields: (data: TaskEditableFormValuesType) => void;
  isTemplate: boolean;
  isViewMode?: boolean | null;
  hideAsterisk?: boolean | null;
  hideLabel?: boolean;
  wrapperClassName?: string;
  multiTasks?: boolean;
  scrollToBottom?: boolean;
  customDueDate?: Date | null;
  inputWrapperClassName?: string;
}

export const DueDate = ({
  isTemplate,
  values,
  onChangeManyFields,
  isViewMode,
  hideAsterisk,
  hideLabel = false,
  wrapperClassName,
  multiTasks = false,
  scrollToBottom = false,
  customDueDate = null,
  inputWrapperClassName,
}: DueDateProps) => {
  const [showErrMsg, setShowErrMsg] = useState(false);
  const dateControls = useSelector(getTransactionDateControlsSelector);

  useEffect(() => {
    if (customDueDate) {
      dateControls.MD = customDueDate;
      handleDateChange({
        target: {
          value: getControlOffsetValue(),
        },
      });
    } else {
      delete dateControls?.MD;
    }
  }, [customDueDate]);

  const isProject = useIsProjectRoute();
  const onChangeControlOperatorOffset = (e, val) => {
    const dueDate = values?.DueDate;
    !isTemplate && dueDate
      ? onChangeManyFields({
          DueDate: null,
          ControlOperatorOffset: '',
        })
      : validateControlOperatorOffset(
          e,
          val,
          onChangeManyFields,
          null,
          isTemplate,
          true,
          multiTasks,
        );
  };

  const getControlOffsetValue = () =>
    !isTemplate && values?.DueDate
      ? getDateOnly(values.DueDate, 'MMMM D, Y')
      : values?.ControlOperatorOffset;

  const handleDateChange = (e) =>
    isTemplate
      ? convertToInitialOffset(e.target.value, onChangeManyFields)
      : convertToDateTime(
          e.target.value,
          dateControls,
          (data) => {
            onChangeManyFields(data);
            setShowErrMsg(!data.DueDate);
          },
          null,
          true,
        );

  const getPlaceholderText = () => {
    if (multiTasks) return 'M/D/YY';
    return `${isTemplate ? 'A' : 'T'}+7, ${isProject ? 'KO+2, DL-4' : 'CD+2, CL-4, MD+2'}`;
  };

  return (
    <div className={styles.dueDate}>
      <div className={classnames(styles.fieldWrapper, wrapperClassName)}>
        {hideLabel ? (
          <></>
        ) : (
          <InputLabel label="Due Date" className={styles.label} showAsterisk={!hideAsterisk} />
        )}
        <div className={classnames(styles.datePicker, inputWrapperClassName)}>
          <DatePicker
            className={styles.templateControlPicker}
            variant="light"
            options={{ enableTime: false }}
            value={getControlOffsetValue()}
            onChange={(e, val) => {
              onChangeManyFields({
                DueDate: val?.[0],
                ControlOperatorOffset: '',
              });
              setShowErrMsg(!val?.[0]);
            }}
            daysFromToday={values?.Offset}
            testid="due_date"
            disabled={isTemplate || isViewMode}
            popoverCalender={true}
            placement="top"
            getPopupContainer={(node) => node}
            scrollToBottom={scrollToBottom}
          />
          <Input
            variant={Input.LIGHT}
            placeholder={getPlaceholderText()}
            value={getControlOffsetValue()}
            onChange={(e, val) => onChangeControlOperatorOffset(e, val)}
            maxLength={10}
            onBlur={(e) => handleDateChange(e)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleDateChange(e);
              }
            }}
            saveOnChange={false}
            disabled={isViewMode}
            className={styles.datePickerInput}
          />
          {showErrMsg && <div className={styles.errorMsg}>Invalid Date</div>}
        </div>
      </div>
    </div>
  );
};
