import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Input } from 'components-antd';
import { updateSmartFormQuestionEffect } from 'store/effects/formBuilder';
import {
  getSmartFormCurrentQuestionSelect,
  getSmartFormMetaSelect,
} from 'store/selectors/formBuilder';
import { QuestionType } from 'types';
import { PDF_FIELD_TYPE } from 'app-constants';

import styles from './styles.module.scss';

export const SmartFieldPane = () => {
  const dispatch = useDispatch();

  const question = useSelector(getSmartFormCurrentQuestionSelect);
  const { disabled } = useSelector(getSmartFormMetaSelect);

  const handleBlur = (id, value) => {
    if (question && question.Fields?.length) {
      const updatedQuestion: QuestionType = {
        ...question,
        Fields: question.Fields.map((f) => {
          if (f.id === id) {
            f.fieldName = value;
          }
          return f;
        }),
      };
      dispatch(updateSmartFormQuestionEffect(updatedQuestion));
    }
  };

  const handleRadioOptionBlur = (id, value) => {
    if (question && question.Fields?.length && question?.Fields?.[0]?.radioOptions?.length) {
      const updatedQuestion: QuestionType = {
        ...question,
        Fields: question.Fields.map((f) => {
          return {
            ...f,
            radioOptions: f.radioOptions?.map((option) => ({
              ...option,
              key: option.value === id ? value : option.key,
            })),
          };
        }),
      };
      dispatch(updateSmartFormQuestionEffect(updatedQuestion));
    }
  };

  return (
    <div className={styles.settingPane} data-testid="smart-field-page">
      {question?.Fields?.length ? (
        <>
          {question.Fields.map((field) => (
            <Row key={field.id}>
              <Col span={24}>
                <label className={styles.label}>{`"${field.id}" Label`}</label>
              </Col>
              <Col span={24}>
                <Input
                  defaultValue={field.fieldName}
                  onBlur={(e) => handleBlur(field.id, e.target.value)}
                  disabled={disabled}
                />
              </Col>
            </Row>
          ))}
          {question?.FieldType === PDF_FIELD_TYPE.PDFRadioGroup &&
            question?.Fields?.length &&
            question?.Fields?.[0]?.radioOptions?.length && (
              <>
                <hr className={styles.marginTop} />

                <h3 className={styles.radioHeader}>Radio Options: </h3>

                {question?.Fields[0]?.radioOptions.map((option) => (
                  <Row key={`${question?.Fields?.[0]?.id}-${option.value}-radio`}>
                    <Col span={24}>
                      <label className={styles.label}>{`"${option.value}" Option Label`}</label>
                    </Col>
                    <Col span={24}>
                      <Input
                        defaultValue={option.key}
                        onBlur={(e) => handleRadioOptionBlur(option.value, e.target.value)}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                ))}
              </>
            )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
