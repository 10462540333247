import confetti from 'canvas-confetti';

const randomInRange = (min: number, max: number) => Math.random() * (max - min) + min;
const between = (val: number, low: number, high: number) => val > low && val < high;
const colors = (function* () {
  const values = ['#26ccff', '#a25afd', '#ff5e7e', '#88ff5a', '#fcff42', '#ffa62d', '#ff36ff'];

  for (let i = values.length; true; i = i - 1 || values.length) {
    yield values[i - 1];
  }
})();

const randomConfetti = (color: string, x: any) =>
  confetti({
    particleCount: 1,
    startVelocity: 0,
    origin: { x: x, y: 0 },
    colors: [color],
    gravity: randomInRange(2.5, 3),
    scalar: randomInRange(0.6, 1.4),
    drift: randomInRange(-0.7, 0.7),
  });

export const dropConfetti = () => {
  const duration = 2 * 1000;
  const animationEnd = Date.now() + duration;

  const dropConfetti = ({ count, minX, maxX }) => {
    while (count--) {
      randomConfetti(colors.next().value, randomInRange(minX, maxX));
    }
  };

  const frame = () => {
    const timeLeft = animationEnd - Date.now();
    const progress = 1 - timeLeft / duration;

    switch (true) {
      case between(progress, 0, 0.2):
        dropConfetti({ count: 4, minX: 0.1, maxX: 0.9 });
        break;
      case between(progress, 0.2, 0.5):
        dropConfetti({ count: 10, minX: 0, maxX: 1 });
        break;
      case between(progress, 0.5, 0.7):
        dropConfetti({ count: 8, minX: 0.1, maxX: 0.9 });
        break;
      case between(progress, 0.7, 0.85):
        dropConfetti({ count: 2, minX: 0.25, maxX: 0.75 });
        break;
      case between(progress, 0.85, 2):
        dropConfetti({ count: 1, minX: 0.4, maxX: 0.6 });
        break;
    }

    if (timeLeft > 0) {
      setTimeout(() => frame(), 100);
    }
  };

  frame();
};
