import moment from 'moment';
import { ReactNode, useState } from 'react';
import classNames from 'classnames';

import {
  DeclineSignatureLogValue,
  DeferredFormLogValue,
  HistoryLogsType,
  HistoryLogType,
  UndoDeferFormLogValue,
  UpdatedFormLogValue,
} from 'types';
import { Avatar } from 'components/Icons';
import { Avatar as AntAvatar } from 'components-antd';
import { DYNAMIC_QUESTION_TYPE, FormProcessLogAction, historyActionSentences } from 'app-constants';
import { Row, Col, Collapse, Panel } from 'components-antd';
import { Icons, ICON_VARIANT_TYPE } from 'pages/Workshop/Forms/Icons';
import { transformJSXResponse } from 'utils';

import styles from './styles.module.scss';

interface HistoryProps {
  history: HistoryLogsType;
}

export const History = ({ history }: HistoryProps) => {
  const renderSimpleRow = (val: HistoryLogType) => {
    const ActionItem = (
      <span
        className={classNames({
          [styles.marginLeft]: historyActionSentences[val.Action]?.hideUser,
        })}
      >
        {historyActionSentences[val.Action]?.text}
      </span>
    );

    return (
      <Row className={styles.contentRowSimple} key={`${val.Id}`}>
        <Col xs={17} sm={17} md={17} lg={17} xl={17} className={styles.user}>
          <div className={styles.userAvatar}>
            {historyActionSentences[val.Action]?.hideUser ? (
              <></>
            ) : val.avatar ? (
              <AntAvatar src={val.avatar} size={32} />
            ) : (
              <Avatar visibility={val.name ? 'visible' : 'hidden'} width={32} height={32} />
            )}
          </div>
          <div className={styles.simpleHeading}>
            {historyActionSentences[val.Action]?.hideUser ? (
              <></>
            ) : (
              <b>{val.isYou ? 'You ' : val.name ? `${val.name} ` : 'Mosaik '}</b>
            )}

            {historyActionSentences[val.Action]?.textBold ? <b>{ActionItem}</b> : ActionItem}
          </div>
        </Col>

        <Col xs={7} sm={7} md={7} lg={7} xl={7} className={styles.time}>
          {moment(val.CreatedDate).format('MM/DD [at] hh:mm A')}
        </Col>
      </Row>
    );
  };

  return <div className={styles.historyContent}>{history.map((val) => renderSimpleRow(val))}</div>;
};
