import React, { useEffect, useRef, useState } from 'react';

import { Navigation } from '../../components/Footer';

import moment from 'moment';
import { cloneDeep } from 'lodash-es';
import classNames from 'classnames';
import { Checkbox, DatePicker, InputNumber } from 'antd';
import { TimeInput } from 'components-antd';

import { getMergedDateTime, getTimeZone } from 'helpers';

import { EditContactInformation, EditHighlights, EditDocuments } from '../components';

import styles from './styles.module.scss';

import { ScrollToTop } from 'pages/ExternalOffer/OfferSubmission/components/ScrollToTop';
import { CardBox } from 'pages/ExternalOffer/OfferSubmission/components/CardBox';

import { IconText } from '../components/IconText';

import { TimeZone } from 'pages/ShowingAppointment/BookingCalendar/TimeZone';
import { useScrollableRef } from 'pages/ExternalOffer/OfferSubmission/hooks/useScrollableRef';

export const OfferExpiration = ({
  onUpdate,
  onFinish,
  setCurrentStageIndex,
  data,
  loading,
  editModal,
  stagesStep,
}) => {
  const { OfferIteration } = data;
  const [state, setState] = useState({ OfferIteration });
  const [indefinitely, setIndefinitely] = useState(OfferIteration?.OfferExpiryIndefinitely);
  const [expireDays, setExpireDays] = useState(false);
  const [expireEndDate, setExpireEndDate] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const { scrollableDivRef } = useScrollableRef();

  useEffect(() => {
    if (state.OfferIteration.OfferExpiryDays) {
      setExpireDays(true);
      setExpireEndDate(false);
      setIndefinitely(false);
      setState({
        ...state,
        OfferIteration: {
          ...state.OfferIteration,
          OfferExpiryDays: state.OfferIteration.OfferExpiryDays,
          OfferExpiryIndefinitely: false,
          OfferExpiryEndDate: null,
        },
      });
    } else if (state.OfferIteration.OfferExpiryEndDate) {
      setExpireDays(false);
      setExpireEndDate(true);
      setIndefinitely(false);
      setState({
        ...state,
        OfferIteration: {
          ...state.OfferIteration,
          OfferExpiryDays: null,
          OfferExpiryIndefinitely: false,
          OfferExpiryEndDate: state.OfferIteration.OfferExpiryEndDate,
        },
      });
    } else if (state.OfferIteration.OfferExpiryIndefinitely) {
      setExpireDays(false);
      setExpireEndDate(false);
      setIndefinitely(true);
      setState({
        ...state,
        OfferIteration: {
          ...state.OfferIteration,
          OfferExpiryDays: null,
          OfferExpiryIndefinitely: true,
          OfferExpiryEndDate: null,
        },
      });
    }
  }, []);

  const onChange = (data) => {
    if (data.isIndefinite) {
      setState({
        ...state,
        OfferIteration: {
          ...state.OfferIteration,
          OfferExpiryDays: null,
          OfferExpiryIndefinitely: true,
          OfferExpiryEndDate: null,
        },
      });
      setIndefinitely(true);
      setExpireEndDate(false);
      setExpireDays(false);
    } else if (data.isAddDays) {
      setState({
        ...state,
        OfferIteration: {
          ...state.OfferIteration,
          OfferExpiryIndefinitely: false,
          OfferExpiryEndDate: null,
        },
      });
      setIndefinitely(false);
      setExpireEndDate(false);
      setExpireDays(true);
    } else if (data.isExpirationDateTime) {
      setState({
        ...state,
        OfferIteration: {
          ...state.OfferIteration,
          OfferExpiryIndefinitely: false,
          OfferExpiryDays: null,
        },
      });
      setIndefinitely(false);
      setExpireEndDate(true);
      setExpireDays(false);
    }
  };

  const onSubmit = () => {
    if (state?.OfferIteration?.OfferExpiryDays) {
      const expiryEndDate = new Date();
      expiryEndDate.setDate(expiryEndDate.getDate() + state?.OfferIteration?.OfferExpiryDays);
      state.OfferIteration.OfferExpiryEndDate = expiryEndDate;
    }

    const body = {
      ...data,
      ...state,
    };

    onUpdate(body);
    onFinish(body);
  };

  const isDisabled = () => {
    const disabled =
      !indefinitely &&
      (!expireDays || (expireDays && !state.OfferIteration.OfferExpiryDays)) &&
      (!expireEndDate || (expireEndDate && !state.OfferIteration.OfferExpiryEndDate));

    return disabled;
  };

  const disabledDate = (current) => {
    let today = moment(new Date()).format('YYYY-MM-DD');
    return current && current < moment(today, 'YYYY-MM-DD');
  };

  const setDeadlineDateTime = (date) => {
    setState({
      ...state,
      OfferIteration: {
        ...state.OfferIteration,
        OfferExpiryEndDate: getMergedDateTime(
          state?.OfferIteration?.OfferExpiryEndDate,
          cloneDeep(date),
        ),
      },
    });
  };

  const setDeadline = (days, date) => {
    const data = days ? moment().add(days, 'd') : date ? moment(date) : null;
    setState({
      ...state,
      OfferIteration: {
        ...state.OfferIteration,
        OfferExpiryDays: days,
        OfferExpiryEndDate: !days ? data : null,
      },
    });
  };

  const onResetExpiration = () => {
    setIndefinitely(false);
    setExpireDays(false);
    setExpireEndDate(false);

    setState({
      ...state,
      OfferIteration: {
        ...state.OfferIteration,
        OfferExpiryIndefinitely: false,
        OfferExpiryDays: null,
        OfferExpiryEndDate: null,
      },
    });
  };

  return (
    <ScrollToTop scrollRef={scrollRef}>
      <div className={styles.offerExpiration}>
        <EditContactInformation
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.ContactInformation)}
        />
        <EditHighlights data={data} onEdit={() => setCurrentStageIndex(stagesStep.Highlights)} />
        <EditDocuments data={data} onEdit={() => setCurrentStageIndex(stagesStep.Documents)} />

        <CardBox className={styles.offerExpirationBox}>
          <IconText
            variant={'offerExpiration'}
            text={'Offer Expiration'}
            className={styles.headerIcon}
          />

          <div className={styles.offerExpirationWrapper} ref={scrollRef}>
            <div className={styles.inputBlock}>
              <div className={styles.offerExpiration}>
                <Checkbox
                  className={classNames('mosaikCheckbox', styles.checkbox)}
                  checked={indefinitely}
                  onClick={(e: any) => {
                    onChange({ isIndefinite: true });
                  }}
                >
                  <span className={styles.static}>None</span>
                </Checkbox>

                <Checkbox
                  className={classNames('mosaikCheckbox', styles.checkbox)}
                  checked={expireDays}
                  onChange={(e: any) => {
                    onChange({ isAddDays: true });
                  }}
                >
                  <span className={styles.static}>In</span>
                  <InputNumber
                    min={1}
                    placeholder="#"
                    value={state?.OfferIteration?.OfferExpiryDays}
                    className={styles.daysInputHolder}
                    onChange={(val) => setDeadline(val, null)}
                    onClick={() => {
                      onChange({ isAddDays: true });
                    }}
                  />
                  <span className={styles.static}>days</span>
                </Checkbox>

                <div
                  className={styles.specificDayContainer}
                  ref={editModal ? scrollableDivRef : null}
                >
                  <Checkbox
                    className={classNames(
                      'mosaikCheckbox',
                      styles.checkbox,
                      styles.datePickerCheckbox,
                    )}
                    checked={expireEndDate}
                    onChange={() => {
                      onChange({ isExpirationDateTime: true });
                    }}
                  >
                    On a specific Day
                  </Checkbox>

                  {expireEndDate ? (
                    <div className={styles.fieldsContainer}>
                      <div className={styles.datePickerHolder}>
                        <DatePicker
                          format={'MM/DD/YYYY'}
                          placeholder={'MM/DD/YYYY'}
                          value={
                            state?.OfferIteration?.OfferExpiryEndDate
                              ? moment(state?.OfferIteration?.OfferExpiryEndDate)
                              : null
                          }
                          disabledDate={disabledDate}
                          onChange={(e: any) => {
                            setDeadline(null, e);
                          }}
                          getPopupContainer={(node) => node}
                          popupClassName={classNames('mosaikCalendar', styles.popupCalender)}
                        />

                        <span className={styles.preposition}>at</span>

                        <TimeInput
                          key={
                            state?.OfferIteration?.OfferExpiryEndDate
                              ? state?.OfferIteration?.OfferExpiryEndDate
                              : ''
                          }
                          className={styles.timeInputField}
                          wrapperClassName={styles.timeInputWrapper}
                          selectClassName={styles.timeSelect}
                          popupClassName={styles.timeDropdown}
                          getPopupContainer={(node) => node}
                          onChange={(e: any) => {
                            setDeadlineDateTime(e);
                          }}
                          value={
                            state?.OfferIteration?.OfferExpiryEndDate
                              ? moment(state?.OfferIteration?.OfferExpiryEndDate)
                              : ''
                          }
                          disabled={!state?.OfferIteration?.OfferExpiryEndDate}
                        />
                      </div>
                      <TimeZone
                        timeZone={getTimeZone()}
                        zoneAreaClass={styles.expiryTimeZoneArea}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <Navigation
            data={data}
            onNext={onSubmit}
            isLast={true}
            edit={editModal}
            onFinish={onSubmit}
            disabled={isDisabled()}
            isPending={loading}
            onReset={onResetExpiration}
          />
        </CardBox>
      </div>
    </ScrollToTop>
  );
};
