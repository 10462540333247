import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { cloneDeep, get } from 'lodash-es';

import {
  openAddParticipantsDrawerAction,
  requestGetUserParticipantsListAction,
  requestGetUserParticipantsAndContactsListAction,
} from 'store/actions/drawers/addParticipants';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  open: false,
  info: null,
  type: null,
  state: IDLE,
  data: null,
  meta: {},
  userParticipantsData: [],
};

export default handleActions(
  {
    [openAddParticipantsDrawerAction]: (state, { payload }) => ({
      ...state,
      open: get(payload, 'open', initialData.open),
      info: get(payload, 'info', initialData.info),
      type: get(payload, 'type', initialData.type),
    }),
    [requestGetUserParticipantsListAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
      meta: payload?.meta || state.meta,
    }),
    [requestGetUserParticipantsAndContactsListAction]: (state, { payload }) => ({
      ...state,
      userParticipantsData: get(payload, ['data', 'result']),
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
