import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icons';

import styles from './styles.module.scss';

const Option = ({
  title,
  onClick,
  active,
  iconVariant,
  className,
  testid,
  light,
  isTransactionCreation,
}) => (
  <div
    testid={testid}
    onClick={onClick}
    className={classNames(
      styles.option,
      {
        [styles.active]: active,
        [styles.light]: light,
        [styles.transactionCreation]: isTransactionCreation,
      },
      className,
    )}
  >
    <div className={styles.optionInner}>
      <Icon
        className={styles.icon}
        variant={iconVariant}
        light={light}
        color={active ? '#FF576D' : ''}
      />
      <p className={styles.title}>{title}</p>
    </div>
  </div>
);

Option.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  iconVariant: PropTypes.string,
  testid: PropTypes.string,
  light: PropTypes.bool,
};

Option.defaultProps = {
  className: '',
  iconVariant: undefined,
  testid: undefined,
  light: false,
};

export default Option;
