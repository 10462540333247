import { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import { QuestionType, ResponseType } from 'types';
import { OnboardingSkipButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingSkipButton';

import styles from './styles.module.scss';

interface SignatureQuestionProps {
  formQuestion?: QuestionType;
  formResponse?: ResponseType;
  formWidth?: number;
  formHeight?: number;
  handleFormResponse?: ({ Answer }: Partial<ResponseType>) => void;
}

export const SignatureQuestion = ({
  formQuestion,
  formResponse,
  handleFormResponse,
  formWidth = 600,
  formHeight = 600,
}: SignatureQuestionProps) => {
  const loadWidthAndHeight = () => {
    const fields = formQuestion?.Fields;
    if (fields?.length && fields[0]?.size) {
      const meta = fields[0].size;
      if (meta?.width && meta?.height) {
        const { width, height } = meta;

        const wRatio = width < formWidth ? formWidth / width : width / formWidth;
        const hRatio = height < formHeight ? formHeight / height : height / formHeight;

        const ratio = Math.min(wRatio, hRatio);

        setSize({
          width: width * ratio,
          height: height * ratio,
        });
        return;
      }
    }
    setSize({
      width: formWidth,
      height: formHeight,
    });
  };

  useEffect(() => {
    loadWidthAndHeight();
  }, [formResponse?.UUID]);

  let signCanvas = useRef<any>({});

  const [size, setSize] = useState({
    width: formWidth,
    height: formHeight,
  });

  useEffect(() => {
    if (formResponse?.Answer) {
      const ans = JSON.parse(formResponse.Answer);
      signCanvas.current.clear();
      signCanvas.current.fromDataURL(ans.Url);
    } else {
      signCanvas.current.clear();
    }
  }, [size]);

  const handleUserInput = () => {
    if (formResponse?.UUID && handleFormResponse)
      handleFormResponse({
        Answer: JSON.stringify({
          Url: signCanvas.current.getCanvas().toDataURL(),
          date: new Date(),
        }),
      });
  };

  const clearResponse = () => {
    if (formResponse?.UUID && handleFormResponse) {
      signCanvas.current.clear();
      handleFormResponse({
        Answer: '',
        FieldType: formResponse?.FieldType,
        Fields: formResponse?.Fields,
      });
    }
  };

  return (
    <div className={styles.signatureBox}>
      <SignatureCanvas
        ref={signCanvas}
        onEnd={handleUserInput}
        canvasProps={{
          width: size.width,
          height: size.height,
          className: 'signCanvas',
        }}
      />
      <OnboardingSkipButton className={styles.skipButton} title="Reset" onClick={clearResponse} />
    </div>
  );
};
