import classNames from 'classnames';

import { Question, PropertyAddressInput } from 'pages/RequestQuote/components';
import Answers from '../../Answers';
import Actions from './Actions';

import styles from './styles.module.scss';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { useSelector } from 'react-redux';
import { getPreFormSelector } from 'store/selectors/requestQuote';
import { preFormQuestionsIds } from 'settings/constants/preForm';
import { useEffect } from 'react';

interface ProperAddressProps {
  className: string;
  onNext: () => {};
  onPreFormSubmit: () => {};
}

const PropertyAddress = ({ className, onNext, onPreFormSubmit }: ProperAddressProps) => {
  return (
    <div testid="property_address" className={classNames(styles.propertyAddress, className)}>
      <Question>What is the address of the property?</Question>
      <AnswersContainer className={styles.answersContainer}>
        <PropertyAddressInput
          unitClassName={styles.unit}
          wrapperClassName={styles.searchWrapper}
          valuesWrapperClassName={styles.valuesWrapper}
          searchWrapperClassName={styles.autocompleteWrapper}
          popupClassName={styles.popup}
        />
      </AnswersContainer>
      <ButtonsContainer>
        <Actions onNext={onNext} onPreFormSubmit={onPreFormSubmit} />
      </ButtonsContainer>
    </div>
  );
};

export default PropertyAddress;
