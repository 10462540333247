import Api from 'store/effects/core/api';
import { getListingDetailById, getListingDetailByIdV3 } from 'api/listingDetail';
import { addListingToFavorites, removeListingFromFavorites } from 'api/user';
import { requestScheduleTour, getScheduleTour } from 'api/tour';
import {
  requestGetListingDetailByIdAction,
  requestToggleFavoriteListingAction,
  requestScheduleTourAction,
  getScheduleTourAction,
  propertTimeSpentAuditLogAction,
} from 'store/actions/listingDetail';
import { propertyTimeSpentAuditLog } from 'api/listingDetail/propertyTimeSpentAuditLog';
import { isArray } from 'lodash-es';

export const requestGetListingDetailByIdEffect = (cfg, options, cb) => {
  const requestParams = { action: requestGetListingDetailByIdAction, method: getListingDetailById };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const requestGetListingDetailByIdV3Effect = (cfg, options, cb) => {
  const requestParams = {
    action: requestGetListingDetailByIdAction,
    method: getListingDetailByIdV3,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const toggleFavoriteListingDetailEffect = (cfg, options, cb) => {
  const { isFavorite, id, contextKey } = cfg;

  if (!isFavorite) {
    const sendRequest = Api.execBase({
      action: requestToggleFavoriteListingAction,
      method: removeListingFromFavorites,
    });
    return sendRequest({ id, contextKey }, options, cb);
  }

  const sendRequest = Api.execBase({
    action: requestToggleFavoriteListingAction,
    method: addListingToFavorites,
  });

  return sendRequest({ id, contextKey }, options, cb);
};

export const requestScheduleTourEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestScheduleTourAction,
    method: requestScheduleTour,
  });

  const { propertyInfo, agentId } = cfg;

  let config;

  if (isArray(propertyInfo)) {
    config = propertyInfo.map((prop) => ({
      PropertyId: prop.Id,
      PropertyAddress: prop.Address,
      AgentId: agentId,
      ListingKey: prop.ListingKey || prop.ListingId,
    }));
  } else {
    config = {
      PropertyId: propertyInfo.Id,
      PropertyAddress: propertyInfo.Address,
      AgentId: agentId,
      ListingKey: propertyInfo?.ListingKey || propertyInfo?.ListingId,
    };
  }

  return sendRequest(config, options, cb);
};

export const getScheduleTourEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: getScheduleTourAction,
    method: getScheduleTour,
  });

  return sendRequest(cfg, options, cb);
};
export const propertTimeSpentAuditLogEffect = (cfg) => {
  const sendRequest = Api.execBase({
    action: propertTimeSpentAuditLogAction,
    method: propertyTimeSpentAuditLog,
  });
  return sendRequest(cfg);
};
