import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Item from '../../../Item';
import { ProfileIcons } from '../../../Icons';
import { AGENT_CONNECTION_STATUSES } from 'settings/constants/common';
import { Phone as PhoneInput } from 'components';
import { showErrorMessage } from 'helpers/errors';
import { updateClientDataEffect } from 'store/effects';
import { showSuccessMessage } from 'helpers';
import FormattedPhone from 'components/FormattedPhone';

import styles from './styles.module.scss';

export const Phone = ({ contact, isExpanded }) => {
  const [phoneEdit, setPhoneEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const primaryPhone = contact.Phones?.find((phone) => phone.IsPrimary)?.PhoneNumber || '';
  const [phone, setPhone] = useState(primaryPhone);
  const isPendingClient = contact?.Client?.InvitationStatus === AGENT_CONNECTION_STATUSES.PENDING;
  const dispatch = useDispatch();

  const onSubmit = async () => {
    if (primaryPhone !== phone) {
      const phoneSchema = Yup.string()
        .phone('US', false, 'Must be a valid phone number for region US')
        .required();
      if (await phoneSchema.isValid(phone)) {
        setLoading(true);
        dispatch(
          updateClientDataEffect({ clientId: contact.Id, phone }, (err) => {
            if (!err) {
              showSuccessMessage('Client primary phone updated');
            }
            setLoading(false);
            setPhoneEdit(false);
          }),
        );
      } else {
        showErrorMessage('Must be a valid phone number for region US');
      }
    }
  };

  const getPhoneValue = () => {
    const phoneValue = primaryPhone ? <FormattedPhone>{primaryPhone}</FormattedPhone> : '-';

    if (phoneEdit) {
      return (
        <div className={styles.editPhoneContainer}>
          <PhoneInput
            name="phone"
            value={phone}
            className={styles.editPhone}
            inputClassName={styles.editPhoneInput}
            onChange={(e, val) => setPhone(val)}
            onBlur={onSubmit}
            disabled={loading}
            testid="phone"
          />
          <div className={styles.close} onClick={() => setPhoneEdit(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M17 17L7 7" stroke="#747475" strokeWidth="2" strokeLinecap="round" />
              <path d="M7 17L17 7" stroke="#747475" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </div>
        </div>
      );
    }

    return isPendingClient ? (
      <div className={styles.phoneContainer}>
        <div className={styles.editIcon} onClick={() => setPhoneEdit(true)}>
          <ProfileIcons name={ProfileIcons.EDIT} />
        </div>
        {phoneValue}
      </div>
    ) : (
      phoneValue
    );
  };

  return (
    <Item
      className={classNames(styles.phoneItem, { [styles.expanded]: isExpanded })}
      valueClassName={styles.phone}
      testid="phone"
      label="Phone"
      value={getPhoneValue()}
    />
  );
};
