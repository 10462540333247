import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorMessage } from 'helpers';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { CreatePaymentMethodCardData } from '@stripe/stripe-js';
import { setPaymentUpdateModalAction } from 'store/actions/subscription';
import { Modal, Spin } from 'components-antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Lock2, Stripe } from 'components/Icons';
import StripeProvider from 'pages/OnboardingV2Agent/ProAndGrowFlow/StripeCheckout/StripeProvider/StripeProvider';
import { requestPaymentUpdateEffect } from 'store/effects/subscription';
import { getUpdatePaymentModal } from 'store/selectors/subscription';
import styles from './styles.module.scss';
import { useRetrievePlan } from 'pages/Paywall/hooks/useRetrievePlan';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#000',
      fontFamily: 'Inter, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#AAABAB',
      },
      ':focus': {},
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '-0.18px',
      padding: '16px 14px',
      border: '1px solid #E8E9EA',
      borderRadius: '4px',
      backgroundColor: 'transparent',
    },
    invalid: {
      color: '#ff3333',
      iconColor: '#ff3333',
    },
  },
};

const PaymentUpdateModalInner: React.FC<{}> = (props) => {
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getUpdatePaymentModal);
  const stripe = useStripe();
  const elements = useElements();
  const { startRetrievingPlan } = useRetrievePlan(
    () => {
      dispatch(setPaymentUpdateModalAction(false));
      return true;
    },
    () => {
      showErrorMessage('failed to pay the invoices, Please try again!');
      setProcessing(false);
    },
    true,
  );

  const updateAndPay = useCallback(async (paymentMethodId: string) => {
    return dispatch(
      requestPaymentUpdateEffect(
        {
          paymentMethodId,
          payInvoices: true,
        },
        (err) => {
          if (!err) {
            startRetrievingPlan();
          }
        },
      ),
    );
  }, []);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!stripe || !elements) {
        console.log('Stripe.js has not yet loaded.');
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      if (!cardElement || !cardExpiryElement || !cardCvcElement) {
        console.error('some fields are empty!');
        return;
      }
      setProcessing(true);

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      } as CreatePaymentMethodCardData);

      if (result.error) {
        throw new Error(result?.error?.message);
      } else {
        const paymentMethodId = result.paymentMethod.id;
        await updateAndPay(paymentMethodId);
      }
    } catch (err) {
      console.error(err);
      setProcessing(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          open={!!isModalOpen}
          width={675}
          footer={null}
          closable={true}
          maskClosable={false}
          maskStyle={{
            background: 'rgba(255, 255, 255, 0.60)',
            backdropFilter: ' blur(12.5px)',
          }}
          bodyStyle={{}}
          onCancel={() => {
            dispatch(setPaymentUpdateModalAction(false));
          }}
          destroyOnClose={true}
          title=" "
        >
          <div className={styles.layout}>
            <div className={styles.heading}>Update Your Payment Method</div>
            <form onSubmit={handleSubmit} className={styles.formWrapper}>
              <div className={styles.inputWrapper}>
                <label className={styles.formLabel}>Name on Card</label>
                <input
                  type="text"
                  placeholder="Jane Smith"
                  required
                  className={styles.cardElement}
                />
              </div>
              <div className={styles.inputWrapper}>
                <label className={styles.formLabel}>Card Number</label>

                <CardNumberElement
                  className={styles.cardElement}
                  options={{
                    ...CARD_ELEMENT_OPTIONS,
                    showIcon: true,
                    iconStyle: 'solid',
                    preferredNetwork: ['visa', 'mastercard', 'amex', 'jcb'],
                  }}
                />
              </div>

              <div className={styles.flexRow}>
                <div className={styles.inputWrapper}>
                  <label className={styles.formLabel}>Expiry Date</label>
                  <CardExpiryElement
                    className={styles.cardElement}
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>

                <div className={styles.inputWrapper}>
                  <label className={styles.formLabel}>CVC</label>
                  <CardCvcElement className={styles.cardElement} options={CARD_ELEMENT_OPTIONS} />
                </div>
              </div>

              <div className={styles.divider}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="589"
                  height="2"
                  viewBox="0 0 589 2"
                  fill="none"
                >
                  <path d="M0 1H589" stroke="white" strokeOpacity="0.1" />
                </svg>
              </div>

              <div className={styles.footer}>
                <div className={styles.stripeSecure}>
                  <Lock2 />
                  Secured by
                  <Stripe className={styles.stripeIcon} color="#262626" />
                </div>

                <button type="submit" className={styles.payBtn} disabled={processing}>
                  {processing ? (
                    <>
                      <Spin
                        className={styles.loader}
                        indicator={
                          <LoadingOutlined
                            color="antiquewhite"
                            width={24}
                            height={24}
                            className={styles.spinner}
                            spin
                          />
                        }
                      />
                    </>
                  ) : (
                    <>Save</>
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};

export const PaymentUpdateModel: React.FC = ({ children }) => {
  return (
    <StripeProvider>
      <PaymentUpdateModalInner />
    </StripeProvider>
  );
};
